import { I18n } from 'react-redux-i18n';
import React from 'react';
import ReportUtils from '../base/utils';
import { Localize } from 'react-redux-i18n';

export const fields = [
  {
    key: 'customerName',
    label: 'report.result.paxReferralHistory.customerName',
    summary: 'Customer name',
    borderRight: true,
    rowSpan: 2,
    fixed: true
  },
  {
    key: 'phoneNumber',
    label: 'report.result.paxReferralHistory.phoneNumber',
    mutate: (value, doc) => {
      // When customer login with social account, then phone number is blank
      if (doc.phoneNumber !== doc.customerId) {
        return (
          <div>
            <span>
              {doc.phoneNumber}
            </span>
          </div>
        );
      } else {
        return ('')
      }
    }
  },
  {
    key: 'referralCode',
    label: 'report.result.paxReferralHistory.referralCode',
  },
  {
    key: 'referredPax',
    label: 'report.result.paxReferralHistory.referredPax',
    toolTip: 'report.result.paxReferralHistory.referredPaxTooltip',
    summary: 'referredPax'
  },
  {
    key: 'activePax',
    label: 'report.result.paxReferralHistory.activePax',
    toolTip: 'report.result.paxReferralHistory.activePaxTooltip',
    summary: 'activePax'
  },
  {
    key: 'commission',
    label: 'report.result.paxReferralHistory.commission',
    sub: (list, obj) => {
      const comissionCurrencies = [];
      list.forEach(item => {
        (item.commission || []).forEach(cms => {
          if (comissionCurrencies.indexOf(cms.currencyISO) < 0) comissionCurrencies.push(cms.currencyISO);
        });
        if (!item.commission.length) {
          (obj || []).forEach(cms => {
            // comissionCurrencies.push(cms.iso);
            if (comissionCurrencies.indexOf(cms.iso) < 0) comissionCurrencies.push(cms.iso);
          })
        };
      });
      return comissionCurrencies.map(currency => (
        {
          label: currency,
          mutate: (value, doc) => {
            const commissionOfCurrency = (doc.commission || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(commissionOfCurrency ? commissionOfCurrency.value : 0, 2, 2)
          },
          summary: 'commission',
          summaryMutate: (value) => {
            const commissionOfCurrency = (value || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(commissionOfCurrency ? commissionOfCurrency.value : 0, 2, 2)
          }
        }
      ))
    }
  },
  {
    label: 'report.result.paxReferralHistory.detail',
    key: 'refererId',
    link: {
      label: 'report.result.paxReferralHistory.showDetail',
      newTab: true,
      href: '/reports/Passenger_refers_Passengers/pax',
      name: 'goToListPassenger',
      params: ['refererId', 'customerName'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'List all passengers which referred by this customer'
    }
  }
];
