import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import {
  Row,
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc,
  makeRandomStringAndNumber,
} from '../../../../utils/commonFunctions';
import { connect } from 'react-redux';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { thirdPartyIntegration } from '../../../../constants/commondata';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { socketConfigs } from '../../../../constants/socketConfigs';
import { socketDispatchApi } from '../../../../utils/socketUtils';
import './index.scss';
import Corporate from '../components/corporate';

const Hoppa = ({ ...props }, context) => {
  // const { notification } = useContext('context')
  const [validInfo, setValidInfo] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [isDisableCredentials, setDisableCredentials] = useState(false);
  const [originCredentials, setOriginCredential] = useState({});
  const [credentials, setCredential] = useState({
    clientKey: '',
    secretKey: '',
    isAutoAccept: false,
  });
  const [webhook, setWebhook] = useState({
    clientId: '',
    clientSecret: '',
    webhook: 'https://dispatch.goodjourney.io/api/webhook/booking-connect',
  });
  const [corporateVal, setCorporateVal] = useState('');

  useEffect(() => {
    getInfoDetail();
  }, []);

  const handleChangeCorporate = (e) => {
    setCorporateVal(e.target.value);
  };

  const getInfoDetail = () => {
    props.settingActions
      .get3rdPartyIntegration({
        fleetId: props.auth.selectedFleet.fleetId,
        thirdParty: thirdPartyIntegration.hoppa,
      })
      .then((response) => {
        const data = response.res || {};
        if (data.credential && data.integration) {
          convertResponse(data.credential, data.integration);
          setOriginCredential(data.integration);
          setCorporateVal(data.credential.corporate);
        }
      });
  };

  const onSocketDispatch = (data) => {
    socketDispatchApi.remove(socketConfigs.receive.booking.bookingSynced);
    socketDispatchApi.on(
      socketConfigs.receive.booking.bookingSynced,
      (data) => {
        context.notification('success', I18n.t('cue.syncingCompleted'));
        socketDispatchApi.remove(socketConfigs.receive.booking.bookingSynced);
      }
    );
  };

  const handleSaveCredential = async () => {
    // case press button edit credential
    if (isDisableCredentials) {
      setDisableCredentials(false);
      return;
    }

    setIsSubmited(true);
    try {
      let isCreateOrUpdateNewKey = false;
      if (!CCLiteCommonFunc.isFormValid(validInfo)) {
        return;
      }

      props.loadingBarActions.showLoadingSpiner();
      const response = await props.settingActions.checkCredentialIntegration({
        fleetId: props.auth.selectedFleet.fleetId,
        username: credentials.clientKey,
        password: credentials.secretKey,
        thirdParty: thirdPartyIntegration.hoppa,
      });

      if (response.res && response.res.status === 200 && response.res.data) {
        let params = {
          fleetId: props.auth.selectedFleet.fleetId,
          bookingClientKey: credentials.clientKey,
          bookingSecretKey: credentials.secretKey,
          isAutoAccept: credentials.isAutoAccept || false,
          thirdParty: thirdPartyIntegration.hoppa,
          corporate: corporateVal,
        };

        if (
          originCredentials &&
          originCredentials.clientKey === credentials.clientKey &&
          originCredentials.secretKey === credentials.secretKey
        ) {
          params.clientKey = webhook.clientId;
          params.secretKey = webhook.clientSecret;
        } else {
          isCreateOrUpdateNewKey = true;
          params.clientKey = makeRandomStringAndNumber(32);
          params.secretKey = makeRandomStringAndNumber(64);
        }

        // props.settingActions.checkCredentialIntegration(params).then((response) => {
        props.settingActions.add3rdPartyIntegration(params).then((response) => {
          const data = response.res || {};
          if (data && data.credential && data.integration) {
            convertResponse(data.credential, data.integration);
            setOriginCredential(data.integration);
            context.notification(
              'success',
              'Integrate has been saved successfully.'
            );
            if (isCreateOrUpdateNewKey) {
              props.settingActions
                .syncThirdPartyBookingSetKey({
                  fleetId: props.auth.selectedFleet.fleetId,
                  thirdParty: 'Hoppa',
                })
                .then(() => {
                  onSocketDispatch();
                });
            }
          } else {
            context.notification(
              'error',
              'The errors occurred while saved Integrate.'
            );
          }
          props.loadingBarActions.hideLoadingSpiner();
        });
      } else {
        props.loadingBarActions.hideLoadingSpiner();
        if (response.error?.errorCode === 31111) {
          context.notification(
            'error',
            'To proceed with the Hoppa API integration, you must first configure the vehicle types in our system. Ensure that the vehicle classifications match those used by Hoppa, such as Standard, Executive, People Carrier, Mini Bus, etc.'
          );
          return;
        }
        context.notification(
          'error',
          'Your Client key or Secret key is incorrect. Please help to check again'
        );
      }
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
      setIsSubmited(false);
      context.notification(
        'error',
        'Something went wrong. Please try again later.'
      );
    }
  };

  const convertResponse = (credential, integration) => {
    setCredential({
      clientKey: integration.clientKey,
      secretKey: integration.secretKey,
      isAutoAccept: integration.isAutoAccept,
    });
    setWebhook({
      ...webhook,
      clientId: credential.clientId,
      clientSecret: credential.clientSecret,
    });
    setCorporateVal(integration.corporate);
    setDisableCredentials(true);
  };

  const handleCredentialChange = (e, key) => {
    setCredential({
      ...credentials,
      [key]: e.target.value,
    });
  };

  const handleIsAutoAcceptChange = (e) => {
    setCredential({
      ...credentials,
      isAutoAccept: e.target.checked,
    });
  };
  const renderCredentials = () => (
    <>
      <h4>{I18n.t('Sidebar.Settings.titleHoppa')}</h4>
      <p>{I18n.t('Sidebar.Settings.noteHoppa')}</p>
      <FormGroup
        className={
          !isSubmited ? null : validInfo.clientKey === false ? 'error' : null
        }
      >
        <Form.Label>
          <Translate value={`Sidebar.Settings.clientKey`} />
          <span className="require"> *</span>
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          value={credentials.clientKey}
          onChange={(e) => handleCredentialChange(e, 'clientKey')}
          placeholder={I18n.t(`Sidebar.Settings.placeHolderClientKey`)}
          disabled={isDisableCredentials}
        />
        <Validator id={'clientKey'} callback={validatorCallback}>
          <ValidCase
            valid={!Validation.isStringEmpty(credentials.clientKey)}
            message={I18n.t('messages.commonMessages.Required_field')}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>
      <FormGroup
        className={
          !isSubmited ? null : validInfo.secretKey === false ? 'error' : null
        }
      >
        <Form.Label>
          <Translate value={`Sidebar.Settings.secretKey`} />
          <span className="require"> *</span>
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          value={credentials.secretKey}
          onChange={(e) => handleCredentialChange(e, 'secretKey')}
          placeholder={I18n.t(`Sidebar.Settings.placeHolderSecretKey`)}
          disabled={isDisableCredentials}
        />
        <Validator id={'secretKey'} callback={validatorCallback}>
          <ValidCase
            valid={!Validation.isStringEmpty(credentials.secretKey)}
            message={I18n.t('messages.commonMessages.Required_field')}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>

      <Corporate
        handleChangeCorporate={handleChangeCorporate}
        corporateVal={corporateVal}
        thirdParty="hoppa"
        isDisable={isDisableCredentials}
      />
      {/* <FormGroup>
        <CcCheckbox
          disabled={isDisableCredentials}
          checked={credentials.isAutoAccept}
          onChange={handleIsAutoAcceptChange}
          text={'Auto accept booking'}
        />
      </FormGroup> */}
      <div>
        <Button className="btn-save mr-md" onClick={handleSaveCredential}>
          {isDisableCredentials
            ? I18n.t('bookingdetail.Edit')
            : I18n.t('bookingdetail.Save')}
        </Button>
        {isDisableCredentials ? (
          <Button
            className="btn-cancel"
            onClick={() => setShowModal(true)}
            disabled={!isDisableCredentials}
          >
            {I18n.t('bookingdetail.Remove')}
          </Button>
        ) : (
          <Button className="btn-cancel" onClick={() => getInfoDetail()}>
            {I18n.t('bookingdetail.Cancel')}
          </Button>
        )}
      </div>
    </>
  );

  const renderWebhook = () => {
    return (
      <>
        <h4 className="titleWebhook">
          {I18n.t('Sidebar.Settings.titleHoppaWebhook')}
        </h4>
        <p>{I18n.t('Sidebar.Settings.noteHoppaWebhook')}</p>
        {renderTextFieldCopy('webhook')}
        {renderTextFieldCopy('clientId')}
        {renderTextFieldCopy('clientSecret')}
      </>
    );
  };

  const handleCopyClick = (key) => {
    copy(webhook[key]);
  };

  const renderTextFieldCopy = (key) => {
    const [isCopied, setIsCopied] = useState(false);

    return (
      <FormGroup className="qup-input-group">
        <Form.Label>
          <Translate value={`Sidebar.Settings.${key}`} />
        </Form.Label>
        <InputGroup className="single-addon-right">
          <FormControl
            className="form-custom"
            type="text"
            onChange={() => {}}
            value={webhook[key]}
            disabled={true}
          />
          <OverlayTrigger
            overlay={
              isCopied ? (
                <Tooltip id="Update_info">
                  <Translate value="bookingdetail.Copied" />
                </Tooltip>
              ) : (
                <span></span>
              )
            }
            show={true}
            placement="top"
            delayShow={300}
            delayHide={150}
          >
            <InputGroup.Append>
              <InputGroup.Text>
                <i
                  class="fa fa-copy"
                  onClick={() => {
                    handleCopyClick(key);
                    setIsCopied(true);
                  }}
                  onMouseLeave={() => {
                    setIsCopied(false);
                  }}
                />
              </InputGroup.Text>
            </InputGroup.Append>
          </OverlayTrigger>
        </InputGroup>
      </FormGroup>
    );
  };

  const validatorCallback = (id, valid) => {
    if (validInfo[id] !== valid) {
      setValidInfo({
        ...validInfo,
        [id]: valid,
      });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const renderModalDeleteConfirm = () => {
    return (
      <Modal
        show={showModal}
        backdrop={true}
        className={'confirm'}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>3rd party integration/ Hoppa</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to de-integrate with Hoppa ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={'btn-save mr-md'}
            onClick={confirmRemoveCredentials}
          >
            <Translate value="carSetting.Yes" />
          </Button>
          <Button className={'btn-cancel'} onClick={() => setShowModal(false)}>
            <Translate value="carSetting.No" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const confirmRemoveCredentials = async () => {
    props.loadingBarActions.showLoadingSpiner();
    props.settingActions
      .delete3rdPartyIntegration({
        fleetId: props.auth.selectedFleet.fleetId,
        thirdParty: thirdPartyIntegration.hoppa,
      })
      .then((response) => {
        props.loadingBarActions.hideLoadingSpiner();
        if (response && response.res && response.res.isDeleted) {
          context.notification(
            'success',
            'Integrate has been deleted successfully.'
          );
          props.settingActions
            .syncThirdPartyBookingRemoveKey({
              fleetId: props.auth.selectedFleet.fleetId,
              thirdParty: 'Hoppa',
            })
            .then(() => {});
          setShowModal(false);
          setCredential({
            clientKey: '',
            secretKey: '',
          });
          setWebhook({
            clientId: '',
            clientSecret: '',
            webhook:
              'https://dispatch.goodjourney.io/api/webhook/booking-connect',
          });
          setDisableCredentials(false);
          setOriginCredential({});
          setCorporateVal('');
        } else {
          context.notification(
            'error',
            'The errors occurred while remove Integrate'
          );
        }
      });
  };

  return (
    <div className="partyIntegration">
      <Row>
        <Col md={6} xs={12}>
          <div className="group-general">
            {renderCredentials()}
            {renderWebhook()}
            {renderModalDeleteConfirm()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

Hoppa.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Hoppa);
