import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	Row,
	Col,
	FormGroup,
	Form,
	FormControl
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { CCLiteCommonFunc } from '../../../../utils/commonFunctions';

const specificType = {
	corpList: 'corporateList',
	permittedList: 'permittedList',
}

export default function AddCorpForm({ 
	schedules, 
	closeCorpForm, 
	onChange, 
	disable,
	getCorporateList,
	fleetId,
	corporateListDefault = [],
	corporateList = [],
	...props  
}) {

	const [corpListDefault, setCorpListDefault] = useState([]);
	const [corpListTranspered, setCorpListTranspered] = useState([])
	const [filterStr, setFilterStr] = useState({
		corpList: '',
		permittedList: ''
	});
	const [corpSelecting, setCorpSelecting] = useState([]);
	const [permittedSelecting, setPermittedSelecting] = useState([]);

	useEffect(() => {
		setCorpListDefault(corporateListDefault)
		setCorpListTranspered(corporateList)
  }, [corporateListDefault]);

	const handleListFilterChange = (type, e) => {
		if(type === specificType.corpList) {
			setFilterStr({
				...filterStr,
				corpList: e.target.value
			})
		}
		if(type === specificType.permittedList) {
			setFilterStr({
				...filterStr,
				permittedList: e.target.value
			})
		}
	};
	
	const selectChanggHandle = (type, e) => {
		if(type === specificType.corpList)
			setCorpSelecting(CCLiteCommonFunc.getSelectedOptons(e))
		if(type === specificType.permittedList)
			setPermittedSelecting(CCLiteCommonFunc.getSelectedOptons(e))
	};
	
	const checkExistInList = (data) => {
    return corpListTranspered.includes(data._id);
	};
	
	const filterCheck = (type, item) => {
		const name = _.get(item, 'companyInfo.name')
    switch (type) {
      case specificType.corpList: {
        if (
          name
            .toLowerCase()
            .includes(filterStr.corpList.toLowerCase()) &&
          item.isActive
        ) {
					return true;
				}
        break;
      }
      case specificType.permittedList: {
          if (
            name
              .toLowerCase()
              .includes(filterStr.permittedList.toLowerCase())
          ) {
						return true;
					}
          break;
        }
			default:
				return false;
    }
	};
	
	const addRemoveSelecting = type => {
    switch (type) {
      case specificType.corpList: {
        if (corpSelecting.length > 0) {
					const newListTranspered = corpListTranspered.concat(corpSelecting);
					setCorpListTranspered(newListTranspered)
        } else {
          // let itemSelected = null;
          // this.state.mDispatcherList.every(item => {
          //   if (
          //     !this.checkExistInList(specificType.mDispatcher, item) &&
          //     this.filterCheck(specificType.mDispatcher, item)
          //   ) {
          //     itemSelected = item;
          //     return false;
          //   }
          //   return true;
          // });
          // if (itemSelected) {
          //   this.state.dialogData.mDispatcherIds.push(itemSelected._id);
          // }
        }
        setCorpSelecting([]);
        break;
      }
      case specificType.permittedList: {
        if (permittedSelecting.length > 0) {
          let result = [];
          corpListTranspered.map(id => {
            if (!permittedSelecting.includes(id)) {
              result.push(id);
            }
					});
					setCorpListTranspered(result)
        } else if (corpListTranspered.length > 0) {
          // this.state.dialogData.mDispatcherIds = this.state.dialogData.mDispatcherIds.slice(
          //   1
          // );
        }
        setPermittedSelecting([]);
        break;
      }
      default:
        break;
    }
	};
	
	const saveCorporateList = () => {
		props.saveCorporateList(corpListTranspered)
		closeCorpForm()
	}

	return (
		<Modal
			show={true}
			backdrop={true}
			onHide={closeCorpForm}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<Translate value="promotionSettings.Corporates" />
				</Modal.Title>
				<button
					type="button"
					className="close"
					aria-label="Close"
					onClick={closeCorpForm}
				>
					<span aria-hidden="true">×</span>
				</button>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col xs={6} md={6} className="assign-container">
						<FormGroup className="assign-select">
							<Form.Label>
								<Translate value="promotionSettings.Corporate_list" />
							</Form.Label>
							<FormControl
								type="text"
								className="form-custom"
								onChange={e => {
									handleListFilterChange(
										specificType.corpList,
										e
									);
								}}
								value={filterStr.corpList}
								placeholder={I18n.t('queuingArea.Enter_to_search')}
							/>
							<FormControl
								as="select"
								className="mr-t-5 form-custom"
								multiple
								onChange={e => {
									selectChanggHandle(specificType.corpList, e);
								}}
							>
								{corpListDefault.map(item => {
									if (
										!checkExistInList(item) 
										&& filterCheck(specificType.corpList, item)
									) {
										return (
											<option value={item._id} key={item._id}>
												{item.companyInfo && item.companyInfo.name}
											</option>
										);
									}
								})}
							</FormControl>
						</FormGroup>
						<div className="assign-ctr-contaner compile">
							<table className="assign-control">
								<tbody>
									<tr>
										<td>
											<div>
												<i
													className="fa fa-arrow-circle-right add"
													aria-hidden="true"
													onClick={() => {
														addRemoveSelecting(
															specificType.corpList
														);
													}}
												/>
											</div>
											<div>
												<i
													className="fa fa-arrow-circle-left remove"
													aria-hidden="true"
													onClick={() => {
														addRemoveSelecting(
															specificType.permittedList
														);
													}}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
					<Col xs={6} md={6}>
						<FormGroup className="mb0">
							<Form.Label>
								<Translate value="promotionSettings.permittedCorporate" />
							</Form.Label>
							<FormControl
								type="text"
								className="form-custom"
								onChange={e => {
									handleListFilterChange(
										specificType.permittedList,
										e
									);
								}}
								value={filterStr.permittedList}
								placeholder={I18n.t('queuingArea.Enter_to_search')}
							/>
						</FormGroup>
						<FormGroup>
							<FormControl
								as="select"
								className="mr-t-5 form-custom"
								multiple
								onChange={e => {
									selectChanggHandle(
										specificType.permittedList,
										e
									);
								}}
							>
								{corpListDefault.map(item => {
									if (
										checkExistInList(item) 
										&& filterCheck(specificType.permittedList, item)
									) {
										return (
											<option value={item._id} key={item._id}>
												{item.companyInfo && item.companyInfo.name}
											</option>
										);
									}
								})}
							</FormControl>
						</FormGroup>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				{!props.permissions || props.permissions.actions ? (
					<Button
						className={'btn-save mr-md'}
						type="button"
						onClick={saveCorporateList}
					>
						<Translate value="generalSetting.Save" />
					</Button>
				) : null}
				<Button 
					className={'btn-cancel'} 
					onClick={closeCorpForm}
				>
					<Translate value="generalSetting.Cancel" />
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
