import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Row,
  Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import currencyFormatter from 'currency-formatter';

class TransactionFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionFeeDefault: {},
    };
  }

  componentDidMount() {
    const curencyISO = _.get(this.props.selectedFleet, 'currencies[0].iso', '');
    this.setState({
      transactionFeeDefault: {
        currencyISO: curencyISO,
        creditCardAmount: 0,
        creditCardPercent: 0,
        directBillingAmount: 0,
        directBillingPercent: 0,
        externalCardAmount: 0,
        externalCardPercent: 0,
      },
    });
  }

  handleFieldChange = (e, key) => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      let newFee = _.get(
        this.props.fleet,
        'creditCardTransactionFee.feeByCurrencies[0]',
        this.state.transactionFeeDefault
      );
      this.props.handleUpdateFleet('creditCardTransactionFee', {
        feeByCurrencies: [{ ...newFee, [key]: e.target.value }],
      });
    }
  };

  renderField = (keyField, type) => {
    let {
      fleet: { creditCardTransactionFee = {} },
      valid,
      editable,
      ValidatorCallback,
      isSubmited,
    } = this.props;
    const feeData = _.get(
      creditCardTransactionFee,
      'feeByCurrencies[0]',
      this.state.transactionFeeDefault
    );
    const infoCurrency =
      currencyFormatter.findCurrency(feeData.currencyISO) || {};
    return (
      <FormGroup
        className={
          !isSubmited ? null : valid[keyField] === false ? 'error' : null
        }
      >
        <FormGroup className="qup-input-group">
          <InputGroup
            className={`single-addon-right ${editable ? 'disabled' : ''}`}
          >
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => this.handleFieldChange(e, keyField)}
              onBlur={(e) => {
                textboxNumberHelper.onBlurHandle(e, () =>
                  this.handleFieldChange(e, keyField)
                );
              }}
              onFocus={(e) => {
                textboxNumberHelper.onfocusHandle(e, () =>
                  this.handleFieldChange(e, keyField)
                );
              }}
              value={_.isNil(feeData[keyField]) ? 0 : feeData[keyField]}
              // placeholder={'Limit pickup-radius'}
              disabled={editable}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                {type === 'amount' ? infoCurrency.symbol : '%'}
              </InputGroup.Text>
            </InputGroup.Append>{' '}
          </InputGroup>
        </FormGroup>
        <Validator id={keyField} callback={ValidatorCallback}>
          <ValidCase
            hide={!isSubmited}
            valid={Validation.isNumber(feeData[keyField])}
            message={I18n.t('messages.commonMessages.must_be_number')}
          />
        </Validator>
      </FormGroup>
    );
  };

  render() {
    return (
      <div className="group-general">
        <FormGroupTitle>
          <Translate value="generalSetting.TransactionFee" />
        </FormGroupTitle>
        <Row>
          <Col xs={12}>
            <Form.Label>
              <Translate
                className="mr-b-10"
                value={`generalSetting.creditCardPercent`}
              />
            </Form.Label>
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('creditCardPercent', 'percent')}
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('creditCardAmount', 'amount')}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Label className="mb-10">
              <Translate
                className="mr-b-10"
                value={`generalSetting.directBilling`}
              />
            </Form.Label>
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('directBillingPercent', 'percent')}
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('directBillingAmount', 'amount')}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Label>
              <Translate
                className="mr-b-10"
                value={`generalSetting.externalCard`}
              />
            </Form.Label>
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('externalCardPercent', 'percent')}
          </Col>
          <Col md={6} xs={12}>
            {this.renderField('externalCardAmount', 'amount')}
          </Col>
        </Row>
        <div></div>
      </div>
    );
  }
}

export default TransactionFee;
