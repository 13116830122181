import React, { useState, useCallback, useEffect, useRef } from 'react'
import {
  Modal,
  Button,
  FormGroup,
  Form,
  Row,
  Col,
  FormControl,
} from "react-bootstrap";
import addTraveler from '../../../assets/images/icons/addTraveler.svg'
import backIcon from '../../../assets/images/icons/backIcon.svg'
import { Translate, I18n } from "react-redux-i18n";
import Select from "../../select/Select";
import IntlTelInputApp from '../../intlTelInputCustome/IntlTelInputApp';
import { Validator, ValidCase } from '../../validator';
import { trimPhoneNumber, Validation } from "../../../utils/commonFunctions";
import { debounce } from 'lodash';
import _ from 'lodash'
import './RoutePreview.scss'

const AddTravelers = ({
  isShowAddTraveler,
  setShowAddTraveler,
  fleetId,
  autocompleteCustomer,
  setShowSelectPoint,
  setCustomerSelected,
  auth,
  newbookingActions,
  customerActions,
  setIsShowRoutePreview,
  passengersNotShow,
  traveler_type,
  corporateId
}) => {

  const [statusRoute, setStatusRoute] = useState('preview') // [preview, edit]
  const [receiverList, setReceiverList] = useState([])
  const [isScreenAddNew, setScreenAddNew] = useState(false)
  const [newPhoneValue, setNewPhoneValue] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [phoneValidation, setPhoneValidation] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [valid, setValid] = useState(false)
  const [validFirstName, setValidFirstName] = useState(false)
  const [phoneExistValidation, setPhoneExistValidation] = useState(false)

  const handleLoadOptionDebounce = (input, callback) => {
    debounceQuery(input, callback, passengersNotShow, traveler_type, corporateId)
  }
  const debounceQuery = useCallback(
    debounce((input, callback, passengersNotShow, traveler_type, corporateId) => 
      handleLoadOption(input, callback, passengersNotShow, traveler_type, corporateId), 400), []
  );

  const handleLoadOption = (input, callback, passengersNotShow = [], traveler_type, corporateId) => {
    if (input) {
      let ids = receiverList.map(item => item.id);
      let filterOptions = {
        fleetId: fleetId,
        str: input,
        status: 'active',
        travelerType: traveler_type == 1 ? 'corporation' : 'individual',
      }
      if(traveler_type == 1 && corporateId) {
        filterOptions.corporateId = corporateId 
      }
      let trimPhone = trimPhoneNumber(input, "no plus");

      if (ids.length) filterOptions.ids = ids;

      if (!Validation.validateSearchingPhone(trimPhone)) {
        filterOptions.name = 1;
      } else {
        filterOptions.str = trimPhone;
      }
      autocompleteCustomer(filterOptions).then(data => {
        if (data.ok && data.res && data.res.list.length > 0) {
          let passengers = data.res.list
          passengers = _.differenceWith(passengers, passengersNotShow, 
            (a,b) => { 
              return a.userId == b.userId
          })
          let result = { complete: true };
          result.options = passengers.map(item => {
            let phone = item.phone !== item.userId ? item.phone : '';
            item.value =
              item.firstName + " " + item.lastName + " - " + phone;
            item.id = item.userId;
            return item;
          });
          callback(null, result);
        } else {
          callback(null, null);
        }
      })
    }
  }

  // useEffect(() => {
  //   delayedQuery();
 
  //   // Cancel the debounce on useEffect cleanup.
  //   return delayedQuery.cancel;
  // }, [dataQuery, delayedQuery]);

  const handleChangeFirstName = (e) => {
    if(e.target) setFirstName(e.target.value)
  }
  
  const handleChangeLastName = (e) => {
    if(e.target) setLastName(e.target.value)
  }

  const ValidatorCallback = (id, valid, messages) => {
    if(id == 'phoneNumber') {
      setValid(valid)
    }

    if(id == 'firstName') {
      setValidFirstName(valid)
    }
  }

  const handleAddNew = () => {
    setScreenAddNew(true)
  }

  const handleCustomizedChange = (e) => {
    setReceiverList(e)
  }

  const handleBackAddNew = () => {
    setScreenAddNew(false)
    setNewPhoneValue('')
    setPhoneValidation(false)
    setValidFirstName(false)
    setIsSubmitted(false)
    setFirstName('')
    setLastName('')
  }

  const handleBackAddTraveler = () => {
    closeModal()
    setIsShowRoutePreview(true)
  }

  const handleAssign = () => {
    if(isScreenAddNew) {
      setIsSubmitted(true)
      if(valid && validFirstName) {
        handleAddNewCustomer()
      }
    } else {
      if(receiverList.length > 0) {
        let customerList = receiverList.map((customer) => ({
          userId: customer.userId,
          fullName: `${customer.firstName} ${customer.lastName}`,
          phone: customer.phone
        }))
        setCustomerSelected(customerList)
        setReceiverList([])
        setShowSelectPoint(true)
        setShowAddTraveler(false)
      }
    }
  }

  const handlePhoneChange = (status, value, countryData, number, id, isBlur) => {
    if (isBlur) {
        if (!status) return;
        let newPhone;
        if (
            countryData &&
            '+' + countryData.dialCode == trimPhoneNumber(number)
        ) {
            newPhone = '';
        } else {
            newPhone = trimPhoneNumber(number);
        }
    } else {
        setNewPhoneValue(number)
        setPhoneValidation(status)
        setPhoneExistValidation(false)
    }
  };
  
  const closeModal = () => {
    setStatusRoute('preview')
    setCustomerSelected([])
    setReceiverList([])
    setShowAddTraveler(false)
  }

  const handleAddNewCustomer = async () => {
    if(!trimPhoneNumber(newPhoneValue)) return
    let phoneStrimed = trimPhoneNumber(newPhoneValue)
    customerActions.checkExistCustomerPhone({
      fleetId: fleetId,
      phone: phoneStrimed
    }).then(data => {
      if (data.res && data.res.existed) {
        setPhoneExistValidation(true)
      } else {
        const params = {
          phone: phoneStrimed,
          firstName: firstName,
          lastName: lastName,
          fleetId: auth.selectedFleet.fleetId,
          tips: auth.selectedFleet.fleetFareId ? auth.selectedFleet.fleetFareId.tips : 0
        };
    
    
        newbookingActions.checkCustomerBooking(params).then(data => {
          if (data.ok && data.res && data.res.customer) {
            let newCustomer = data.res.customer
            setCustomerSelected([{
              userId: newCustomer.userId,
              fullName: `${newCustomer.firstName} ${newCustomer.lastName}`,
              phone: newCustomer.phone
            }])
            setPhoneValidation(false)
            setValidFirstName(false)
            setIsSubmitted(false)
            setNewPhoneValue('')
            setFirstName('')
            setLastName('')
            setReceiverList([])
            setShowSelectPoint(true)
            setShowAddTraveler(false)
            setScreenAddNew(false)
          }
        });
      }
    });
  };
  
  return (
    <Modal
        show={isShowAddTraveler}
        backdrop="static"
        key="modal-form"
        dialogClassName="confirm-dialog routePreview"
        className="confirm pointModal"
        onHide={closeModal}
      >
        <Modal.Header>
          {
            isScreenAddNew ? (
              <div className="headerPre addNew">
                <span className="backBtn">
                  <img 
                    src={backIcon} 
                    onClick={handleBackAddNew}
                  />
                </span>
                <span className="titlePre">
                  New Traveler
                </span>
              </div>
            ) : (
            <div className="headerPre">
              <span className="backBtn">
                <img 
                  src={backIcon} 
                  onClick={handleBackAddTraveler}
                />
              </span>
              <span className="titlePre">
                Add Travelers
              </span>
              <span 
                className="actionPre"
                onClick={handleAddNew}
              >
                <img src={addTraveler} />
              </span>
            </div>
            )
          }
        </Modal.Header>
        <Modal.Body>
          {
            isScreenAddNew ? (
              <div className="addNewFormTraveler">
                <Row>
                  <Col md={12}>
                    <FormGroup
                      className={
                          !isSubmitted
                              ? null
                              : valid === false
                                  ? 'error'
                                  : null
                      }
                    >
                      <Form.Label>
                          <Translate value="merchant.user.Phone_Number" />
                          <span className="require"> *</span>
                      </Form.Label>
                      <IntlTelInputApp
                          css={['intl-tel-input', 'form-control']}
                          utilsScript={'libphonenumber.js'}
                          value={newPhoneValue}
                          onPhoneNumberBlur={(status, value, countryData, number, id) => {
                            handlePhoneChange(
                                status,
                                value,
                                countryData,
                                number,
                                id,
                                true
                            );
                          }}
                          onPhoneNumberChange={(
                            status,
                            value,
                            countryData,
                            number,
                            id
                          ) => {
                            handlePhoneChange(
                                status,
                                value,
                                countryData,
                                number,
                                id,
                                false
                            );
                          }}
                      />
                      <Validator id="phoneNumber" callback={ValidatorCallback}>
                          <ValidCase
                            hide={!isSubmitted}
                            valid={!Validation.isStringEmpty(newPhoneValue)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                          />
                          <ValidCase
                            hide={!isSubmitted}
                            valid={phoneValidation}
                            message={I18n.t('merchant.user.ERROR_INPUT_VALIDPHONE')}
                          />
                          <ValidCase
                            hide={!isSubmitted}
                            valid={!phoneExistValidation}
                            message={I18n.t('merchant.user.ERROR_EXISTED_PHONE')}
                          />
                      </Validator>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup
                        className={
                            !isSubmitted
                                ? null
                                : validFirstName === false
                                    ? 'error'
                                    : null
                        }
                      >
                      <Form.Label>
                        <Translate value="newbooking.First_name" />
                        <span className="require"> *</span>
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={firstName}
                        onChange={handleChangeFirstName}
                        className="form-custom"
                        placeholder={`${I18n.t('newbooking.First_name')} *`}
                      />
                      <Validator id="firstName" callback={ValidatorCallback}>
                        <ValidCase
                          hide={!isSubmitted}
                          valid={!Validation.isStringEmpty(firstName)}
                          message={I18n.t('messages.commonMessages.Required_field')}
                        />
                      </Validator>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Form.Label>
                        <Translate value="newbooking.Last_name" />
                      </Form.Label>
                      <FormControl
                        type="text"
                        value={lastName}
                        onChange={handleChangeLastName}
                        className="form-custom"
                        placeholder={`${I18n.t('newbooking.Last_name')}`}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div className="topBody">
                </div>
                <div className="searchTraveler">
                  <Select.Async
                    multi={true}
                    valueKey="id"
                    labelKey="value"
                    cache={false}
                    placeholder=""
                    searchPromptText={
                      I18n.t( "message.Search_customer_name_customer_phone_number")
                    }
                    loadingPlaceholder={I18n.t("message.Searching")}
                    noResultsText={I18n.t("message.No_results")}
                    className="receiver-list custom-select-control"
                    value={receiverList}
                    loadOptions={handleLoadOptionDebounce}
                    onChange={handleCustomizedChange}
                    autoload={false}
                  />
                </div>
              </>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleAssign}
            className="btn-save"
            // disabled={isScreenAddNew && }
          >
            <Translate value="newbooking.Assign" />
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

AddTravelers.defaultProps = {
  puPoints: [],
  doPoints: [],
  recentPlaces: {},
  fleetId: '',
  limitPoint: {},
  isBookDetails: false,
  jobType: '',
  setNewPuPoint: () => {},
  setNewDoPoint: () => {},
  isPickupEditable: () => {},
  isDestinationEditable: () => {},
  checkPointInZoneSupported: () => {}
}

export default AddTravelers