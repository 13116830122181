import { getTripApi, getTripDetailsApi, cueFindApi, cueCompletedFindApi, carTypeFetchByCompanyApi } from '../constants/ApiConfigs';
import { callApi, UrlBuilder } from '../utils/apiUtils';

export const TRIP_REQUEST = "TRIP_REQUEST";
export const TRIP_SUCCESS = "TRIP_SUCCESS";
export const TRIP_FAILURE = "TRIP_FAILURE";
export const UPDATE_TRIP_FILTER_REQUEST = "UPDATE_TRIP_FILTER_REQUEST";
export const TRIP_UPDATE_SUCCESS = "TRIP_UPDATE_SUCCESS";
export const TRIPS_FILTER_PARAMS = "TRIPS_FILTER_PARAMS";
export const TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST = 'TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST';
export const TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS = 'TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS';
export const TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE = 'TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE';
export const UPDATE_TRIP_COMMON_DATA = "UPDATE_TRIP_COMMON_DATA";
export const CLOSE_TRIP_DETAILS_FORM = "CLOSE_TRIP_DETAILS_FORM";

function tripRequest() {
  return {
    type: TRIP_REQUEST
  }
};

function tripSuccess(data) {
  return {
    type: TRIP_SUCCESS,
    payload: data.res
  }
};

function tripFailure(error) {
  return {
    type: TRIP_FAILURE,
    payload: error
  }
};

function updateTripFilterRequest() {
  return {
    type: UPDATE_TRIP_FILTER_REQUEST
  }
};

function fetchCarTypeByCompanyRequest(data) {
  return {
    type: TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST,
    payload: data
  }
};

function fetchCarTypeByCompanySuccess(data) {
  return {
    type: TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS,
    payload: data.res
  }
};

function fetchCarTypeByCompanyFailure(error) {
  return {
    type: TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE,
    payload: error
  }
};

function getTrip(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getTripApi, config, tripRequest(), tripSuccess, tripFailure, true);
};

function updateTripFillter(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getTripApi, config, updateTripFilterRequest(), tripSuccess, tripFailure, true);
};

function updateTrip(data) {
  return {
    type: TRIP_UPDATE_SUCCESS,
    payload: data
  }
};

function updateTripFilterParams(data) {
  return {
    type: TRIPS_FILTER_PARAMS,
    payload: data
  }
};

function getTripDetails(options) {
  options = Object.assign({}, options);
  const config = {
    method: "get"
  };
  return callApi(
    UrlBuilder(getTripDetailsApi, options),
    config,
    null,
    null,
    null,
    true);
};


function getListBookingsByTrip(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    cueFindApi,
    config,
    null,
    null,
    null,
    true);
};


function getListCompletedBookingsByTrip(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    cueCompletedFindApi,
    config,
    null,
    null,
    null,
    true);
};

function tripFetchCarTypeByCompany(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    carTypeFetchByCompanyApi,
    config,
    fetchCarTypeByCompanyRequest(options),
    fetchCarTypeByCompanySuccess,
    fetchCarTypeByCompanyFailure,
    true
  );
};

function updateTripCommonData(data) {
  return {
    type: UPDATE_TRIP_COMMON_DATA,
    payload: data
  };
};

function closeTripDetailsForm() {
  return { type: CLOSE_TRIP_DETAILS_FORM };
}

export {
  getTrip,
  updateTripFillter,
  updateTrip,
  updateTripFilterParams,
  getTripDetails,
  getListBookingsByTrip,
  getListCompletedBookingsByTrip,
  tripFetchCarTypeByCompany,
  updateTripCommonData,
  closeTripDetailsForm
};
