import ReportUtils from '../base/utils';
import { Translate, I18n } from 'react-redux-i18n';

const writeOffDebtReasons = ['Pay from app', 'Pay from Pax'];

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.imcompletePayment.bookingId',
    rowSpan: 2,
    isAlwayShow: true,
  },
  {
    key: 'customerName',
    label: 'report.result.imcompletePayment.customerName',
    rowSpan: 2,
    isAlwayShow: true,
  },
  {
    key: 'customerPhone',
    label: 'report.result.imcompletePayment.customerPhone',
    rowSpan: 2,
    isAlwayShow: true,
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'bookingTime',
    label: 'report.result.imcompletePayment.bookingTime',
    rowSpan: 2,
    mutate: ReportUtils.formatTime,
    isAlwayShow: true,
  },
  {
    key: 'completedTime',
    label: 'report.result.imcompletePayment.completedTime',
    rowSpan: 2,
    mutate: ReportUtils.formatTime,
    isAlwayShow: true,
  },
  {
    key: 'updatedDateTime',
    label: 'report.result.imcompletePayment.updatedDateTime',
    rowSpan: 2,
    mutate: ReportUtils.formatTime,
    isAlwayShow: true,
  },
  {
    key: 'amountCollected',
    label: 'report.result.imcompletePayment.amountCollected',
    rowSpan: 2,
    isAlwayShow: true,
    toolTip:
      'Outstanding amount collected from customer app or operator wrote off debt from CC',
    mutate: (value) => (value && value.toFixed(2)) || 0,
    summary: 'amountCollected',
    summaryMutate: (value) => (value && value.toFixed(2)) || 0,
  },
  {
    key: 'currentPaymentStatus',
    label: 'report.result.imcompletePayment.currentPaymentStatus',
    rowSpan: 2,
    mutate: ReportUtils.mappingPaymentStatus,
  },
  {
    key: 'totalAmt',
    label: 'report.result.imcompletePayment.totalAmt',
    rowSpan: 2,
    summary: 'totalAmt',
    toolTip: 'Total fare of booking',
  },
  {
    key: 'partialPaymentAmount',
    label: 'report.result.imcompletePayment.partialPaymentAmount',
    rowSpan: 2,
    summary: 'partialPaymentAmount',
    toolTip: 'Partial amount paid when drop off',
  },
  {
    key: 'originalOutstandingAmount',
    label: 'report.result.imcompletePayment.originalOutstandingAmount',
    rowSpan: 2,
    summary: 'originalOutstandingAmount',
  },
  {
    key: 'currentOutstandingAmount',
    label: 'report.result.imcompletePayment.currentOutstandingAmount',
    rowSpan: 2,
    summary: 'currentOutstandingAmount',
  },
  {
    key: 'originalPaymentStatus',
    label: 'report.result.imcompletePayment.originalPaymentStatus',
    rowSpan: 2,
    mutate: ReportUtils.mappingPaymentStatus,
  },
  {
    key: 'paymentMethod',
    label: 'report.result.imcompletePayment.requestedPaymentMethod',
    rowSpan: 2,
  },
  {
    key: 'bookFrom',
    label: 'report.result.imcompletePayment.bookFrom',
    rowSpan: 2,
  },
  {
    key: 'writeOffDebtMethod',
    label: 'report.result.imcompletePayment.writeOffDebtMethod',
    rowSpan: 2,
    mutate: ReportUtils.mappingPaymentMethod,
  },
  {
    key: 'writeOffDebtReason',
    label: 'report.result.imcompletePayment.reason',
    rowSpan: 2,
    mutate: (value, doc) => {
      if (!value) return '';
      if (value.indexOf('Charged by') === 0) { // Trường hợp pre/post Paid
        return value;
      }
      if (writeOffDebtReasons.some((str) => value.indexOf(str) === 0)) {
        return value;
      }
      return `${I18n.t('report.query.completeFromCC')}: ${value}`;
    },
    width: 400,
  },
];

export const floatFields = [
  'originalOutstandingAmount',
  'currentOutstandingAmount',
  'totalAmt',
];

// export const fareFields = ['originalOutstandingAmount', 'currentOutstandingAmount', 'totalAmt'];
export const fareFields = [
  'totalAmt',
  'originalOutstandingAmount',
  'currentOutstandingAmount',
  'partialPaymentAmount'
];
