export const Columns = [
  {
    key: "name",
    label: "regularSetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "latestUpdate",
    label: "regularSetting.Last_Update",
    width: 200
  },
  {
    key: "regularStatus",
    label: "regularSetting.Status",
    width: 100
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const HourlyPackageColumns = [
  {
    key: "name",
    label: "regularSetting.Package_name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "packageDuration",
    label: "regularSetting.Package_duration",
  },
  {
    key: "baseRate",
    label: "regularSetting.Base_rate",
  },
  {
    key: "distanceCoverage",
    label: "regularSetting.Distance_coverage",
  },
  {
    key: "extraDuration",
    label: "regularSetting.Extra_duration"
  },
  {
    key: "extraDistance",
    label: "regularSetting.Extra_distance",
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];