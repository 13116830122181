import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import * as customerActions from '../../actions/customerAction';
import * as corporateActions from '../../actions/corporateAction';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import './invoice.scss';
import _, { result } from 'lodash';
import Select from '../../components/select/Select';

const SHOW_TYPE = [
  { value: 'all', title: 'All' },
  { value: 'selected', title: 'Selected' },
  { value: 'unselected', title: 'Unselected' },
];
class EmailPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      showEmailInvoiceModal: false,
      emailInvoiceInfo: {
        to: [],
        cc: [],
        content: '',
      },
    };
  }

  componentDidMount() {
    if(this.props.billingEmail) {
      this.setState({
        emailInvoiceInfo: {
          ...this.state.emailInvoiceInfo,
          to: [{
            value: this.props.billingEmail,
            id: this.props.billingEmail
          }]
        }
      })
    }
  }

  handleCloseEmailInvoiceModal = () => {
    this.props.closePopup();
  };

  handleLoadOptionDebounce = (input, callback) => {
    const reEmail =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!reEmail.test(input))
      return callback({
        complete: false,
        options: [],
      });
    const result = {
      complete: true,
      options: [
        {
          value: input,
          id: input,
        },
      ],
    };
    callback(null, result);
  };

  handleSaveConfirmed = () => {
    this.props.handleSaveConfirmed(this.state.emailInvoiceInfo)
  };

  render() {
    const { emailInvoiceInfo } = this.state;
    return (
      <Modal
        show={true}
        bsSize={'xs'}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="ChargeCustome emailInvoice"
        onHide={this.handleCloseEmailInvoiceModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Email Invoice</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.handleCloseEmailInvoiceModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>
              <Translate value={`invoice.To`} />
              <span className="require"> *</span>
            </Form.Label>
            <Select.Async
              multi={true}
              valueKey="id"
              labelKey="value"
              cache={false}
              placeholder=""
              searchPromptText={I18n.t('invoice.addRecipients')}
              loadingPlaceholder={I18n.t('message.Searching')}
              noResultsText={I18n.t('message.No_results')}
              className="receiver-list custom-select-control"
              value={emailInvoiceInfo.to}
              loadOptions={this.handleLoadOptionDebounce}
              onChange={(email) => {
                this.setState({
                  emailInvoiceInfo: { ...emailInvoiceInfo, to: email },
                });
              }}
              // disabled={!this.state.driverStatus.length}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <Translate value={`invoice.CC`} />
            </Form.Label>
            <Select.Async
              multi={true}
              valueKey="id"
              labelKey="value"
              cache={false}
              placeholder=""
              searchPromptText={I18n.t('invoice.addRecipients')}
              loadingPlaceholder={I18n.t('message.Searching')}
              noResultsText={I18n.t('message.No_results')}
              className="receiver-list custom-select-control"
              value={emailInvoiceInfo.cc}
              loadOptions={this.handleLoadOptionDebounce}
              onChange={(email) => {
                this.setState({
                  emailInvoiceInfo: { ...emailInvoiceInfo, cc: email },
                });
              }}
              // disabled={!this.state.driverStatus.length}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <Translate value={`invoice.Content`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              as="textarea"
              className={'form-custom textareaEmail'}
              value={emailInvoiceInfo.content}
              onChange={(e) => {
                this.setState({
                  emailInvoiceInfo: { ...emailInvoiceInfo, content: e.target.value },
                });
              }}
              placeholder={I18n.t('invoice.Content')}
              rows={5}
              maxLength={1000}
            />
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-header text-add-header"
              onClick={this.handleCloseEmailInvoiceModal}
            >
              <Translate value="invoice.Close" />
            </Button>
            <Button
              className="btn-save view-button"
              onClick={this.handleSaveConfirmed}
              disabled={
                !emailInvoiceInfo?.to?.length > 0 || !emailInvoiceInfo.content
              }
            >
              <Translate value="invoice.sendEmail" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

EmailPopup.contextTypes = {
  notification: PropTypes.func,
};

EmailPopup.defaultProps = {
  bookingList: [],
  selectedBookings: [],
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailPopup);
