import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { forEach } from 'lodash';

import {
  FormGroup,
  Button,
  ButtonToolbar
} from 'react-bootstrap';

import TableActions from '../../../components/table/tableAction/TableActions';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import AddEditCategoryModal from './components/AddEditCategoryModal';
import ConfirmDelete from './components/ConfirmDeleteLocationForm';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { CategoryColumns } from './tableHeaderData';
import '../settings.scss';
import { BsSearch } from 'react-icons/bs';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 0,
      numItemsPerPage: 20,
      dialogData: {},
      dataList: {
        list: [],
        page: 0,
        limit: 20,
        total: 0
      },
      showDialog: false,
      tableHeight: 500,
      rowHeight: 50,
      textSearch: ''
    };
  }

  componentDidMount() {
    this.updateCategoryList();
  }

  updateCategoryList = () => {
    const { auth, settingActions } = this.props;
    const { dataList, numItemsPerPage, activePage, textSearch } = this.state;
    const { fleetId } = auth.selectedFleet;
    const limit = numItemsPerPage;
    const page = activePage || activePage === 0 ? activePage : dataList.page;
    settingActions
      .findCategory({
        textSearch,
        fleetId,
        limit,
        page
      })
      .then(data => {
        if (data.ok && data.res) {
          this.setState({
            dataList: data.res
          });
        }
      });
  };

  searchStrChange = e => {
    this.setState({ textSearch: e.target.value });
  };

  searchKeyPressHandle = e => {
    if (e.key == 'Enter') {
      this.updateCategoryList(0);
    }
  };

  handleAddButtonClick = () => {
    this.setState({
      dialogData: {},
      showDialog: true
    });
  };

  openFormDialog = () => {
    this.setState({
      showDialog: true
    });
  };

  closeFormModal = () => {
    this.setState({ showDialog: false });
  };

  handlePaginationSelect = value => {
    this.setState(
      {
        activePage: value
      },
      this.updateCategoryList
    );
  };

  handlePaginationChose = e => {
    const value = parseInt(e.target.value);
    this.setState(
      {
        activePage: value
      },
      this.updateCategoryList
    );
  };

  handleNumItemsPerPageChange = e => {
    const value = parseInt(e);
    this.setState(
      {
        numItemsPerPage: value,
        activePage: 0
      },
      this.updateCategoryList
    );
  };

  handleEditButtonClick = (_id, name) => {
    const dialogData = {
      _id,
      name
    };

    this.setState({
      dialogData,
      showDialog: true
    });
  };

  handleDeleteButtonClick = _id => {
    this.setState({ showConfirmDialog: true, deleteId: _id });
  };

  handleMenuClick = (key, obj) => {
    switch (key) {
    case 'Edit': {
      this.handleEditButtonClick(obj._id, obj.name);
      break;
    }
    case 'Delete': {
      this.handleDeleteButtonClick(obj._id);
      break;
    }
    default:
      break;
    }
  };

  getTableColumns = () => {
    const tableColumns = Object.assign([], CategoryColumns);
    // custom data by column
    forEach(tableColumns, (col, index) => {
      switch (col.key) {
      case 'actions':
        col.customCell = (obj, rowIndex) => {
          if (!this.props.permissions || this.props.permissions.actions) {
            const actionItems = [
              {
                label: 'promotionSettings.Edit',
                eventKey: 'Edit'
              },
              {
                label: 'promotionSettings.Delete',
                eventKey: 'Delete'
              }
            ];

            return (
              <TableActions
                rowIndex={rowIndex}
                rowData={obj}
                onSelect={eventKey => {
                  this.handleMenuClick(eventKey, obj);
                }}
                totalRow={this.state.dataList.list.length}
                rowHeight={this.state.rowHeight}
                menuItems={actionItems}
                tableHeight={this.state.tableHeight}
              />
            );
          }
          return (
            <a
              onClick={e => {
                this.handleMenuClick('Edit', obj);
              }}
              href="javascript:void(0)"
              className="text-active"
            >
              <Translate value="mDispatcher.View" />
            </a>
          );
        };
        break;
      default:
        break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  closeConfirmModal = () => {
    this.setState({ showConfirmDialog: false, deleteId: null });
  };

  handleConfirmDelete = () => {
    const { deleteId: id } = this.state;

    const { auth, settingActions, loadingBarActions } = this.props;
    const { fleetId } = auth.selectedFleet;
    settingActions
      .deleteCategory({
        categoryId: id,
        fleetId
      })
      .then(data => {
        if (data.res) {
          this.closeConfirmModal();
          this.updateCategoryList();
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('thirdPartySettings.Delete_category_fail')
          );
        }
      });
  };

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    return (
      <div className="content">
        {this.state.showDialog ? (
          <AddEditCategoryModal
            dialogData={this.state.dialogData}
            updateCategoryList={this.updateCategoryList}
            show={this.state.showDialog}
            closeFormModal={this.closeFormModal}
            currentPosition={this.state.currentPosition}
            {...this.props}
          />
        ) : null}

        {this.state.showConfirmDialog ? (
          <ConfirmDelete
            closeConfirmModal={this.closeConfirmModal}
            onConfirmDelete={this.handleConfirmDelete}
            caseOfDelete="Delete_Category"
          />
        ) : null}

        <ButtonToolbar
          className="text-center header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <div className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.textSearch}
                onKeyPress={this.searchKeyPressHandle}
                onChange={this.searchStrChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {canUpdate && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="thirdPartySettings.Add" />
              </Button>
            )}
          </div>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.dataList}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

Category.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Category);
