import ReportUtils from '../base/utils';

const link = {
  name: 'showCarActivityLogs',
  params: ['date', 'vehicleId'],
  excludeParams: ['txtSearch', 'pickUpZone'],
  description: 'Link for show car activity logs in this day'
};

export const fields = [
  {
    key: 'plateNumber',
    label: 'report.result.carActivity.plateNumber',
    summary: 'activeCars',
    summaryText: 'report.result.carActivity.activeCars'
  },
  { key: 'vehicleType', label: 'report.result.carActivity.vehicleType' },
  { key: 'companyName', label: 'report.result.carActivity.companyName' },
  {
    key: 'day1st',
    label: 'report.result.carActivity.day1st',
    link: { ...link, key: 1 }
  },
  {
    key: 'day2nd',
    label: 'report.result.carActivity.day2nd',
    link: { ...link, key: 2 }
  },
  {
    key: 'day3rd',
    label: 'report.result.carActivity.day3rd',
    link: { ...link, key: 3 }
  },
  {
    key: 'day4th',
    label: 'report.result.carActivity.day4th',
    link: { ...link, key: 4 }
  },
  {
    key: 'day5th',
    label: 'report.result.carActivity.day5th',
    link: { ...link, key: 5 }
  },
  {
    key: 'day6th',
    label: 'report.result.carActivity.day6th',
    link: { ...link, key: 6 }
  },
  {
    key: 'day7th',
    label: 'report.result.carActivity.day7th',
    link: { ...link, key: 7 }
  },
  {
    key: 'day8th',
    label: 'report.result.carActivity.day8th',
    link: { ...link, key: 8 }
  },
  {
    key: 'day9th',
    label: 'report.result.carActivity.day9th',
    link: { ...link, key: 9 }
  },
  {
    key: 'day10th',
    label: 'report.result.carActivity.day10th',
    link: { ...link, key: 10 }
  },
  {
    key: 'day11th',
    label: 'report.result.carActivity.day11th',
    link: { ...link, key: 11 }
  },
  {
    key: 'day12th',
    label: 'report.result.carActivity.day12th',
    link: { ...link, key: 12 }
  },
  {
    key: 'day13th',
    label: 'report.result.carActivity.day13th',
    link: { ...link, key: 13 }
  },
  {
    key: 'day14th',
    label: 'report.result.carActivity.day14th',
    link: { ...link, key: 14 }
  },
  {
    key: 'day15th',
    label: 'report.result.carActivity.day15th',
    link: { ...link, key: 15 }
  },
  {
    key: 'day16th',
    label: 'report.result.carActivity.day16th',
    link: { ...link, key: 16 }
  },
  {
    key: 'day17th',
    label: 'report.result.carActivity.day17th',
    link: { ...link, key: 17 }
  },
  {
    key: 'day18th',
    label: 'report.result.carActivity.day18th',
    link: { ...link, key: 18 }
  },
  {
    key: 'day19th',
    label: 'report.result.carActivity.day19th',
    link: { ...link, key: 19 }
  },
  {
    key: 'day20th',
    label: 'report.result.carActivity.day20th',
    link: { ...link, key: 20 }
  },
  {
    key: 'day21st',
    label: 'report.result.carActivity.day21st',
    link: { ...link, key: 21 }
  },
  {
    key: 'day22nd',
    label: 'report.result.carActivity.day22nd',
    link: { ...link, key: 22 }
  },
  {
    key: 'day23rd',
    label: 'report.result.carActivity.day23rd',
    link: { ...link, key: 23 }
  },
  {
    key: 'day24th',
    label: 'report.result.carActivity.day24th',
    link: { ...link, key: 24 }
  },
  {
    key: 'day25th',
    label: 'report.result.carActivity.day25th',
    link: { ...link, key: 25 }
  },
  {
    key: 'day26th',
    label: 'report.result.carActivity.day26th',
    link: { ...link, key: 26 }
  },
  {
    key: 'day27th',
    label: 'report.result.carActivity.day27th',
    link: { ...link, key: 27 }
  },
  {
    key: 'day28th',
    label: 'report.result.carActivity.day28th',
    link: { ...link, key: 28 }
  },
  {
    key: 'day29th',
    label: 'report.result.carActivity.day29th',
    link: {
      ...link,
      key: 29
    },
    isNotShow: data => data.days < 29
  },
  {
    key: 'day30th',
    label: 'report.result.carActivity.day30th',
    link: {
      ...link,
      key: 30
    },
    isNotShow: data => data.days < 30
  },
  {
    key: 'day31st',
    label: 'report.result.carActivity.day31st',
    link: {
      ...link,
      key: 31
    },
    isNotShow: data => data.days < 31
  },
  {
    key: 'operationDays',
    label: 'report.result.carActivity.operationDays'
  }
];

export const logFields = [
  { key: 'licensePlate', label: 'report.result.carActivityLogs.licensePlate' },
  { key: 'companyName', label: 'report.result.carActivityLogs.companyName' },
  { key: 'bookingId', label: 'report.result.carActivityLogs.bookingId' },
  { key: 'driverName', label: 'report.result.carActivityLogs.driverName' },
  {
    key: 'driverIDNumber',
    label: 'report.result.carActivityLogs.driverIDNumber'
  },
  {
    key: 'date',
    label: 'report.result.carActivityLogs.date',
    mutate: ReportUtils.formatTime
  },
  { key: 'bookFrom', label: 'report.result.carActivityLogs.bookFrom' }
];
