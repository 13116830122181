export const QueuingAreaColumns = [
  {
    key: "queuingArea",
    label: "queuingArea.Queuing_area",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "location.address",
    label: "queuingArea.Google_Address",
    textEllipsis: true
  },
  {
    key: "minDrivers",
    label: "queuingArea.Min_drivers_required"
  },
  {
    key: "mDispatcher",
    label: "queuingArea.mDispatcher",
    textEllipsis: true
  },
  {
    key: "queuingStatus",
    label: "queuingArea.Status",
    width: 100
  },
  {
    key: "actions",
    label: "queuingArea.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
