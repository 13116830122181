import React from 'react';
import {
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    Modal,
    Tab,
    Tabs,
    ButtonToolbar,
    Row,
    Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { Validator } from '../../../../components/validator';
import ValidCase from '../../../../components/validator/ValidCase';
import {
    Validation,
    ObjectId,
    parStart,
    CCLiteCommonFunc,
} from '../../../../utils/commonFunctions';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
const hoursOfDay = _.range(0, 24);
const minutesOfHour = _.range(0, 60);

class AddEditScheduleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schedule: {
                times: [
                    {
                        _id: ObjectId(),
                        startTime: {
                            hour: 0,
                            minute: 0,
                        },
                        endTime: {
                            hour: 0,
                            minute: 0,
                        }
                    },
                ],
                dayOfWeek: 0,
            },
            daysNoSetting: [],
            applyOtherDays: {},
            valid: {},
        };
    }

    componentDidMount() {
        let { schedule } = this.state;
        const { schedules } = this.props;
        const daysUsed = _.map(schedules, 'dayOfWeek');
        let daysNoSetting = [];
        if (this.props.schedule._id) {
            schedule = _.cloneDeep(this.props.schedule);
            daysNoSetting = daysOfWeek.filter(
                (d) => !daysUsed.includes(d) || d === schedule.dayOfWeek
            );
        } else {
            daysNoSetting = daysOfWeek.filter(
                (d) => !daysUsed.includes(d)
            );
            schedule.dayOfWeek = daysNoSetting[0];
        }
        this.setState({ daysNoSetting, schedule: { ...schedule } });
    }

    validatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: { ...this.state.valid } });
        }
    };

    handleAddTime = () => {
        const { schedule } = this.state;
        schedule.times.push(
            {
                _id: ObjectId(),
                startTime: {
                    hour: 0,
                    minute: 0,
                },
                endTime: {
                    hour: 0,
                    minute: 0,
                }
            },
        );
        this.setState({
            schedule: {
                ...schedule,
            },
        });
    };

    handleRemoveTime = (time, index) => {
        const { schedule } = this.state;
        this.setState({
            schedule: {
                ...schedule,
                times: schedule.times.filter((t) => t._id !== time._id),
            },
        });
    };

    handleChangeDayOfWeek = (e) => {
        const { schedule } = this.state;
        this.setState({
            schedule: {
                ...schedule,
                dayOfWeek: parseInt(e.target.value),
            },
            applyOtherDays: {},
        });
    };

    handleChangeTimeHour = (key, e, index) => {
        const { schedule } = this.state;
        _.set(schedule, ['times', index, key, 'hour'], parseInt(e.target.value));
        this.setState({
            schedule: {
                ...schedule,
            },
            valid: {}
        });
    };

    handleChangeTimeMinute = (key, e, index) => {
        const { schedule } = this.state;
        _.set(schedule, ['times', index, key, 'minute'], parseInt(e.target.value));
        this.setState({
            schedule: {
                ...schedule,
            },
            valid: {}
        });
    };

    handelChangeApplyOtherDays = (e, day) => {
        const { applyOtherDays } = this.state;
        _.set(applyOtherDays, day, e.target.checked);
        this.setState({
            applyOtherDays: {
                ...applyOtherDays,
            },
        });
    };

    isValidScheduleTimesOverlap = () => {
        const {
            schedule: { times },
        } = this.state;
        const timesOverlap = times.filter(time => time.startTime.hour >= time.endTime.hour && time.startTime.minute >= time.endTime.minute)
        return timesOverlap.length === 0;
    };

    isValidScheduleTimes = () => {
        const {
            schedule: { times },
        } = this.state;
        const timesInvalid = times.filter(t => {
            var valueStart = new Date(2021, 3, 1, t.startTime.hour, t.startTime.minute).getTime();
            var valueEnd = new Date(2021, 3, 1, t.endTime.hour, t.endTime.minute).getTime();
            return times.find(f => {
                if (t._id !== f._id) {
                    var checkStart = new Date(2021, 3, 1, f.startTime.hour, f.startTime.minute).getTime();
                    var checkEnd = new Date(2021, 3, 1, f.endTime.hour, f.endTime.minute).getTime();
                    if (valueStart >= checkStart && valueStart <= checkEnd) {
                        return f
                    } else if (valueEnd >= checkStart && valueEnd <= checkEnd) {
                        return f
                    } else {
                        return
                    }
                }
            })
        });
        return timesInvalid.length === 0;
    };

    isValidScheduleUniqueTimes = () => {
        const {
            schedule: { times },
        } = this.state;
        const uniqueTimes = [...new Set(times.map((a) => `${a.startTime.hour}_${a.startTime.minute} - ${a.endTime.hour}_${a.endTime.minute}`))];
        return times.length === uniqueTimes.length;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ isSubmitted: true });
        if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
            const { schedule, applyOtherDays } = this.state;
            const { onSubmit } = this.props;
            schedule._id = schedule._id || ObjectId();
            onSubmit(
                schedule,
                _.keys(applyOtherDays).filter((d) => applyOtherDays[d])
            );
        }
    };

    renderStartTimeGroup = (time, index) => {
        const { schedule } = this.state;
        return (
            <div className="scheduleTimes" key={index}>
                <div className="scheduleTimes__time">
                    <FormControl
                        as="select"
                        className={'form-control form-custom time-select'}
                        onChange={(e) => this.handleChangeTimeHour('startTime', e, index)}
                        value={time.startTime.hour}
                    // disabled={!this.state.editable}
                    >
                        {hoursOfDay.map((hour) => {
                            return (
                                <option value={hour} key={hour}>
                                    {parStart(hour)}
                                </option>
                            );
                        })}
                    </FormControl>
                    <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                    <FormControl
                        as="select"
                        className={'form-control form-custom time-select'}
                        onChange={(e) => this.handleChangeTimeMinute('startTime', e, index)}
                        value={time.startTime.minute}
                    // disabled={!this.state.editable}
                    >
                        {minutesOfHour.map((minute) => {
                            return (
                                <option value={minute} key={minute}>
                                    {parStart(minute)}
                                </option>
                            );
                        })}
                    </FormControl>
                </div>
            </div>

        );
    };


    renderEndTimeGroup = (time, index) => {
        const { schedule } = this.state;
        return (
            <div className="scheduleTimes" key={index}>
                <div className="scheduleTimes__time">
                    <FormControl
                        as="select"
                        className={'form-control form-custom time-select'}
                        onChange={(e) => this.handleChangeTimeHour('endTime', e, index)}
                        value={time.endTime.hour}
                    // disabled={!this.state.editable}
                    >
                        {hoursOfDay.map((hour) => {
                            return (
                                <option value={hour} key={hour}>
                                    {parStart(hour)}
                                </option>
                            );
                        })}
                    </FormControl>
                    <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                    <FormControl
                        as="select"
                        className={'form-control form-custom time-select'}
                        onChange={(e) => this.handleChangeTimeMinute('endTime', e, index)}
                        value={time.endTime.minute}
                    // disabled={!this.state.editable}
                    >
                        {minutesOfHour.map((minute) => {
                            return (
                                <option value={minute} key={minute}>
                                    {parStart(minute)}
                                </option>
                            );
                        })}
                    </FormControl>
                </div>
                <div>
                    {schedule.times.length > 1 ? (
                        <a
                            onClick={() => {
                                this.handleRemoveTime(time, index);
                            }}
                        >
                            <i className="fa fa-trash fa-2x text-warning m" />
                        </a>
                    ) : null}
                </div>
            </div>

        );
    };

    render() {
        const {
            schedule,
            daysNoSetting,
            applyOtherDays,
            isSubmitted,
            valid,
        } = this.state;
        const otherDays = _.filter(
            daysNoSetting,
            (d) =>
                _.isNil(schedule.dayOfWeek) ||
                d.toString() !== schedule.dayOfWeek.toString()
        );
        return (
            <Modal
                show={true}
                backdrop={true}
                onHide={this.props.onCloseDialog}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {schedule && schedule._id ? (
                                <Translate value="promotionSettings.editSchedule" />
                            ) : (
                                <Translate value="promotionSettings.addSchedule" />
                            )}
                        </Modal.Title>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={this.props.onCloseDialog}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup
                            className={
                                isSubmitted
                                    ? valid.dayOfWeek === false
                                        ? 'error'
                                        : null
                                    : null
                            }
                        >
                            <Form.Label>
                                <Translate value="promotionSettings.date" />{' '}
                                <span className="require"> *</span>
                            </Form.Label>
                            <FormControl
                                as="select"
                                value={schedule.dayOfWeek}
                                onChange={this.handleChangeDayOfWeek}
                                className="form-custom"
                                disabled={schedule._id}
                            >
                                {daysNoSetting.map((d) => {
                                    return (
                                        <option value={d} key={d}>
                                            {I18n.t(`dayOfWeek.${d}`)}
                                        </option>
                                    );
                                })}
                            </FormControl>
                        </FormGroup>

                        <FormGroup
                            className={
                                isSubmitted ? (valid.times === false ? 'error' : null) : null
                            }
                        >
                            <Form.Label>
                                <Translate value="promotionSettings.Time_range" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <Row className="sub-controls time-range">
                                <Col md={6}>
                                    <FormGroup>
                                        <Form.Label className={'mr-md'}>
                                            <Translate value="promotionSettings.startTime" />
                                        </Form.Label>
                                        <div className="intercityRoute__timeList">
                                            {schedule.times.map((time, index) => {
                                                return this.renderStartTimeGroup(time, index);
                                            })}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Form.Label className={'mr-md'}>
                                            <Translate value="promotionSettings.endTime" />
                                        </Form.Label>
                                        <div className="intercityRoute__timeList">
                                            {schedule.times.map((time, index) => {
                                                return this.renderEndTimeGroup(time, index);
                                            })}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Validator id="times" callback={this.validatorCallback}>
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={this.isValidScheduleTimesOverlap()}
                                    message={I18n.t('promotionSettings.schedule_startDate_greater_than_endDate')}
                                />
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={this.isValidScheduleTimes()}
                                    message={I18n.t('promotionSettings.scheduleTimeOverlap')}
                                />
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={this.isValidScheduleUniqueTimes()}
                                    message={I18n.t('promotionSettings.scheduleTimeDuplicate')}
                                />
                            </Validator>
                        </FormGroup>

                        <ButtonToolbar className="text-center mv-md">
                            <Button className={'btn-save'} onClick={this.handleAddTime}>
                                <Translate value="generalSetting.Add" />
                            </Button>
                        </ButtonToolbar>

                        {otherDays.length && !schedule._id ? (
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="promotionSettings.applyForOtherDays" />{' '}
                                </Form.Label>
                                <Row>
                                    {otherDays.map((d) => {
                                        return (
                                            <Col key={d} xs={6}>
                                                <CcCheckbox
                                                    checked={applyOtherDays[d]}
                                                    onChange={(e) => {
                                                        this.handelChangeApplyOtherDays(e, d);
                                                    }}
                                                    text={I18n.t(`dayOfWeek.${d}`)}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </FormGroup>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        {!this.props.permissions || this.props.permissions.actions ? (
                            <Button
                                className={'btn-save mr-md'}
                                type="button"
                                onClick={this.handleSubmit}
                            >
                                <Translate value="generalSetting.Save" />
                            </Button>
                        ) : null}
                        <Button className={'btn-cancel'} onClick={this.props.onCloseDialog}>
                            <Translate value="generalSetting.Cancel" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default AddEditScheduleModal;
