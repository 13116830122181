import {
  notificationGetAllApi,
  notificationMarkedAllAsViewedApi,
  notificationMarkedItemAsReadApi,
  notificationDeleteApi,
  sosReadApi,
  sosDeleteApi,
  sosGetAllApi,
  sosMardedAllAsViewedApi,

  signUpNotificationGetAllApi,
  signUpNotificationMarkedAllAsViewedApi,
  signUpNotificationMarkedItemAsReadApi,
  signUpNotificationDeleteApi
  
} from '../constants/ApiConfigs';

import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

export const LOAD_ALL_NOTIFICATION = 'LOAD_ALL_NOTIFICATION'
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const NEW_SOS_NOTIFICATION = 'NEW_SOS_NOTIFICATION';
// function notificationgetAllRequest(){
//     return{
//         type:FLEET_INFO_REQUEST
//     }
// }

function notificationgetAllSuccess(data) {
  return {
    type: LOAD_ALL_NOTIFICATION,
    data: data
  }
}

export function notificationHasNew(data) {
  return {
    type: NEW_NOTIFICATION,
    data: data
  }
}

export function sosNotificationHasNew(data) {
  return {
    type: NEW_SOS_NOTIFICATION,
    data: data
  }
}

// function notificationgetAllFailure(error){
//     return{
//         type:FLEET_INFO_FAILURE,
//         error
//     }
// }
export function notificationgetAll(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationGetAllApi,
    config,
    null,
    notificationgetAllSuccess,
    null,
    isLoading
  );
}
export function notificationmarkAllAsViewed(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationMarkedAllAsViewedApi,
    config,
    null,
    null,
    null,
    false
  );
}

export function notificationMarkItemAsRead(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationMarkedItemAsReadApi,
    config,
    null,
    null,
    null,
    false
  );
}
export function notificationDeleteItem(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationDeleteApi,
    config,
    null,
    null,
    null,
    false
  );
}

export function sosMarkItemAsRead(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "get"
  };

  return callApi(
    UrlBuilder(sosReadApi, options),
    config,
    null,
    null,
    null,
    false
  );
}

export function sosGetAll(options = {}, isLoading = true) {
  const config = {
    method: "get"
  };

  return callApi(
    UrlBuilder(sosGetAllApi, options),
    config,
    null,
    null,
    null,
    isLoading
  );
}

export function sosDelete(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "delete",
    body: JSON.stringify(options)
  };

  return callApi(
    sosDeleteApi,
    config,
    null,
    null,
    null,
    isLoading
  );
}

export function sosMarkAllAsViewed(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    sosMardedAllAsViewedApi,
    config,
    null,
    null,
    null,
    false
  );
}


export function signUpNotificationGetAll(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    signUpNotificationGetAllApi,
    config,
    null,
    null,
    null,
    isLoading
  );
};

export function signUpNotificationMarkAllAsViewed(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    signUpNotificationMarkedAllAsViewedApi,
    config,
    null,
    null,
    null,
    false
  );
}

export function signUpNotificationMarkItemAsRead(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    signUpNotificationMarkedItemAsReadApi,
    config,
    null,
    null,
    null,
    false
  );
}

export function signUpNotificationDelete(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    signUpNotificationDeleteApi,
    config,
    null,
    null,
    null,
    isLoading
  );
}