import React, { Component } from "react";
import PropTypes from "prop-types";
import "./reservationList.scss";
import _ from "lodash";
import { Popover, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as bookingDetailActions from "../../actions/bookingDetailAction";
import StickyTable from "../../components/table/stickyTable/StickyTable";

export const DayOfWeek = [
  {
    numberOfDay: 0,
    label: "General.Mon",
    key: "monday",
    USDay: 1
  },
  {
    numberOfDay: 1,
    label: "General.Tue",
    key: "tuesday",
    USDay: 2
  },
  {
    numberOfDay: 2,
    label: "General.Wed",
    key: "wednesday",
    USDay: 3
  },
  {
    numberOfDay: 3,
    label: "General.Thu",
    key: "thursday",
    USDay: 4
  },
  {
    numberOfDay: 4,
    label: "General.Fri",
    key: "friday",
    USDay: 5
  },
  {
    numberOfDay: 5,
    label: "General.Sat",
    key: "saturday",
    USDay: 6
  },
  {
    numberOfDay: 6,
    label: "General.Sun",
    key: "sunday",
    USDay: 0
  }
];

class ReservationList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleShowBookingDetail = booking => {
    if (booking) {
      if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
        if (!_.get(this.props, 'auth.user.roles.supplierPermission.booking', false)) {
          return
        }
        return this.props.router.push({pathname:`/supplier-booking-detail/${booking.bookId}`, state: {selectedTab: 0}})
      }

      this.props.bookingDetailActions
        .getBookingDetail(booking.bookId, false)
        .then(data => {
          if (!data.res || data.error) {
            this.context.notification(
              "error",
              I18n.t("cue.Error_load_booking")
            );
          }
        });
    }
  };

  getParentSize = () => {
    return {
      width: this.props.width,
      height: this.props.height
    };
  };

  renderDayList(popoverClick, reservations) {
    let timeTitle = [];
    for (let i = 0; i < 24; i++) {
      if (i > 12) {
        timeTitle.push(
          <React.Fragment>
            <span className="time">{i - 12}</span>
            <span className="time-extension">{"PM"}</span>
          </React.Fragment>
        );
      } else if (i === 12) {
        timeTitle.push(
          <React.Fragment>
            <span className="time">{i}</span>
            <span className="time-extension">{"PM"}</span>
          </React.Fragment>
        );
      } else {
        timeTitle.push(
          <React.Fragment>
            <span className="time">{i}</span>
            <span className="time-extension">{"AM"}</span>
          </React.Fragment>
        );
      }
    }

    let timeColumnWidth = 0,
      expectTimeWidth = 80,
      timeWidth;
    let expectColumnWidth = 24 * expectTimeWidth;
    let realColumnWidth = this.props.width - 300; // 300: driver column width;
    if (expectColumnWidth < realColumnWidth) {
      timeColumnWidth = realColumnWidth;
      timeWidth = realColumnWidth / 24;
    } else {
      timeColumnWidth = expectColumnWidth;
      timeWidth = expectTimeWidth;
    }

    let tableColumns = [
      {
        key: "driver",
        label: "",
        width: 300,
        fixed: true,
        customCell: (reservation, rowIndex) => {
          let stripeBottom = this.getBottomDistance(
            rowIndex,
            reservations.length,
            70
          );
          return (
            <div className="driver-part">
              <div className="driver-name day-driver-name">
                <OverlayTrigger
                  placement="top"
                  delayShow={300}
                  overlay={
                    <Tooltip id="fullname-tooltip">
                      <p>
                        <span>{reservation.drvInfo.fullName}</span>
                        <span> / {reservation.drvInfo.plateNumber}</span>
                      </p>
                    </Tooltip>
                  }
                >
                  <p>
                    <span>{reservation.drvInfo.fullName}</span>
                    <span> / {reservation.drvInfo.plateNumber}</span>
                  </p>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  delayShow={300}
                  overlay={
                    <Tooltip id="type-company-tooltip">
                      <p>
                        <span>{reservation.drvInfo.vehicleType}</span>
                        <span> / {reservation.drvInfo.companyName}</span>
                      </p>
                    </Tooltip>
                  }
                >
                  <p>
                    <span>{reservation.drvInfo.vehicleType}</span>
                    <span> / {reservation.drvInfo.companyName}</span>
                  </p>
                </OverlayTrigger>
              </div>
              <div className="reservation-count">
                (
                {
                  _.filter(reservation.drawingList, item => {
                    return !item.isOverLap;
                  }).length
                }
                )
              </div>
              {stripeBottom ? (
                <div
                  className="driver-last-row"
                  style={{ height: `${stripeBottom}px` }}
                />
              ) : null}
            </div>
          );
        }
      },
      {
        key: "timeTitle",
        label: "timeTitle",
        width: timeColumnWidth + timeWidth / 2,
        customHeader: columnKey => {
          return (
            <div className="title-item">
              {_.map(timeTitle, (item, index) => {
                return (
                  <div
                    key={index}
                    className="title text-center"
                    style={{ width: timeWidth }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          );
        },
        customCell: (reservation, rowIndex) => {
          let stripeBottom = this.getBottomDistance(
            rowIndex,
            reservations.length,
            70
          );
          // 70: rowHeight
          return (
            <div
              className="drawing-item"
              style={{ width: `${timeColumnWidth}px` }}
            >
              <div
                className="stripe"
                style={{
                  background: `repeating-linear-gradient(90deg, rgba(233, 233, 233, 0) 0px, rgba(233, 233, 233, 0) ${timeColumnWidth /
                    24 -
                    1}px,  rgba(0, 0, 0, 0.19) ${timeColumnWidth / 24 -
                    2}px,  #252830 ${timeColumnWidth / 24}px )`,
                  bottom: `-${stripeBottom}px`
                }}
              />
              {_.map(reservation.drawingList, (drawingItem, itemIndex) => {
                let blockClass =
                  "reservation-block" +
                  (drawingItem.isOverLap ? " overlapBooking" : "") +
                  (drawingItem.hasStartPoint ? " hasStartPoint" : "");
                return (
                  <OverlayTrigger
                    trigger="click"
                    placement={ reservations.length > 8 ? ((reservations.length - rowIndex) <= 3 ? "top": "bottom" ): "bottom" }
                    overlay={popoverClick(drawingItem.bookings)}
                    rootClose
                    key={itemIndex}
                  >
                    <div
                      className={blockClass}
                      style={{
                        left: `${drawingItem.startingPosition *
                          timeColumnWidth +
                          timeWidth / 2}px`,
                        width:
                          drawingItem.isOverLap && drawingItem.duration === 0
                            ? "10px"
                            : `${drawingItem.duration * timeColumnWidth}px`,
                        zIndex: drawingItem.isOverLap
                          ? `${drawingItem.bookings.length + 1}`
                          : ""
                      }}
                    >
                      {!drawingItem.hasEndPoint && (
                        <div className="continue">
                          <i className="fa fa-angle-double-right arrow" />
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>
                );
              })}
            </div>
          );
        }
      }
    ];
    return (
      <StickyTable
        bodyData={reservations}
        columns={tableColumns}
        footerData={this.props.footerData}
        handleNumItemsPerPageChange={this.props.handleNumItemsPerPageChange}
        handlePaginationSelect={this.props.handlePaginationSelect}
        rowHeight={70}
        useParentSize={true}
        parentSize={this.getParentSize()}
        className="reservations-by-day"
      // descreaseWidth={window.innerHeight < 850 ? 10 : 0}
      />
    );
  }

  getDate = addDate => {
    let startDate = _.cloneDeep(this.props.startDate);
    return moment(startDate)
      .add(parseInt(addDate), "day")
      .date();
  };

  renderWeekList(popoverClick, reservations) {
    let tableColumns = Object.assign([], DayOfWeek);
    let newReservations = _.cloneDeep(reservations);
    newReservations[0].startDate = this.props.startDate.toDateString();
    //Limit number of reservation books which is showed on table
    //If we have more than 3, it'll show ...
    const showingDataLimit = 3;
    let today = moment().date();
    let expectTimeWidth = 200,
      timeWidth;
    let expectColumnWidth = 7 * expectTimeWidth;
    let realColumnWidth = this.props.width - 300; // 300: driver column width;
    if (expectColumnWidth < realColumnWidth) {
      timeWidth = realColumnWidth / 7;
    } else {
      timeWidth = expectTimeWidth;
    }
    //console.log({ tableColumns, reservations, newReservations });
    _.forEach(tableColumns, col => {
      let date = this.getDate(col.numberOfDay);
      col.width = timeWidth;
      col.customHeader = columnKey => {
        return (
          <div className="day-of-week">
            <Translate value={col.label} className="day-title" />
            <div className={"date-number" + (date === today ? " today" : "")}>
              <span>{date}</span>
            </div>
          </div>
        );
      };
      col.customCell = (reservation, rowIndex) => {
        let stripeBottom = this.getBottomDistance(
          rowIndex,
          reservations.length,
          100
        );
        return (
          <React.Fragment>
            {!_.isEmpty(reservation.weekData[col.numberOfDay]) ? (
              <OverlayTrigger
                trigger="click"
                placement={ reservations.length > 8 ? ((reservations.length - rowIndex) <= 3 ? "top": "bottom" ): "bottom" }
                rootClose
                overlay={popoverClick(reservation.weekData[col.numberOfDay])}
              >
                <ul className="cursor-pointer">
                  {_.map(
                    reservation.weekData[col.numberOfDay],
                    (booking, index) => {
                      if (index < showingDataLimit) {
                        return (
                          <li key={booking.bookId}>
                            {
                              moment(booking.request.pickUpTime)
                                .tz(this.props.fleetTimeZone)
                                .format("hh:mm A")
                            }
                          </li>
                        );
                      } else {
                        if (index === showingDataLimit) {
                          return <li key={booking.bookId}>...</li>;
                        }
                        return;
                      }
                    }
                  )}
                </ul>
              </OverlayTrigger>
            ) : null}
            <div
              className="stripe"
              style={{
                bottom: `-${stripeBottom}px`
              }}
            />
          </React.Fragment>
        );
      };
    });

    tableColumns.push({
      key: "driver",
      label: "",
      width: 300,
      fixed: true,
      customCell: (reservation, rowIndex) => {
        let stripeBottom = this.getBottomDistance(
          rowIndex,
          reservations.length,
          100
        );
        return (
          <div className="driver-part">
            <div className="driver-name day-driver-name">
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="fullname-tooltip">
                    <p>
                      <span>{reservation.drvInfo.fullName}</span>
                      <span> / {reservation.drvInfo.plateNumber}</span>
                    </p>
                  </Tooltip>
                }
              >
                <p className="text-ellipsis">
                  <span>{reservation.drvInfo.fullName}</span>
                  <span> / {reservation.drvInfo.plateNumber}</span>
                </p>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="type-company-tooltip">
                    <p>
                      <span>{reservation.drvInfo.vehicleType}</span>
                      <span> / {reservation.drvInfo.companyName}</span>
                    </p>
                  </Tooltip>
                }
              >
                <p>
                  <span>{reservation.drvInfo.vehicleType}</span>
                  <span> / {reservation.drvInfo.companyName}</span>
                </p>
              </OverlayTrigger>
            </div>
            <div className="reservation-count">
              ({reservation.bookings ? reservation.bookings.length : 0})
            </div>
            {stripeBottom ? (
              <div
                className="driver-last-row"
                style={{ height: `${stripeBottom}px` }}
              />
            ) : null}
          </div>
        );
      }
    });

    return (
      <StickyTable
        bodyData={newReservations}
        columns={tableColumns}
        footerData={this.props.footerData}
        handleNumItemsPerPageChange={this.props.handleNumItemsPerPageChange}
        handlePaginationSelect={this.props.handlePaginationSelect}
        rowHeight={100}
        useParentSize={true}
        parentSize={this.getParentSize()}
        className="reservations-by-week"
      // descreaseWidth={window.innerHeight < 850 ? 10 : 0}
      />
    );
  }

  getBottomDistance = (rowIndex, totalRow, rowHeight) => {
    let stripeBottom = 0;
    let isLast = rowIndex === totalRow - 1;
    if (isLast) {
      let expectHeight = totalRow * rowHeight;
      if (expectHeight < this.props.height) {
        stripeBottom = this.props.height - expectHeight - 100;
      }
    }
    return stripeBottom;
  };

  render() {

    const popoverClick = bookings => {
      return (
      <Popover
        id="popover-trigger-click-root-close"
        className="reservation-detail"
      >
        <Popover.Title as="h3">{`${I18n.t("reservation.bookingDetails")} (${bookings.length})`}</Popover.Title>
        <div className="booking-list">
          {_.map(bookings, (booking, index) => {
            const { bookId, request } = booking || {};
            const { pickUpTime, pickup } = request || {};
            return (
              <div
                key={bookId}
                className={(_.get(this.props, 'auth.user.roles.isSupplier') && !_.get(this.props, 'auth.user.roles.supplierPermission.booking', false)) ? "booking-item disabled" : "booking-item"}
                onClick={() => {
                  this.handleShowBookingDetail(booking);
                }}
              >
                <div className="info">
                  <Translate value="reservation.bookingID" />: {bookId}
                </div>
                {booking.tripId ? (
                  <div className="info">
                    <Translate value="reservation.tripId" />: {booking.tripId}
                  </div>
                ) : null}
                <div className="info">
                  <Translate value="reservation.pickupTime" />:{" "}
                  {
                    moment(pickUpTime)
                      .tz(pickup.timezone)
                      .format("MM/DD/YYYY hh:mm A")
                  }
                </div>
                <div className="info location-container">
                  <div className="location-title">
                    <Translate value="reservation.pickupLocation" />
                    :&nbsp;
                  </div>
                  <div className="location-content">
                    {pickup.address}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    )};

    const { reservations } = this.props;
    const { time } = this.context;
    return (
      <div className="reservation-list-container">
        {!_.isEmpty(this.props.reservations) ? (
          this.props.onTab === time.DAY ? (
            this.renderDayList(popoverClick, reservations)
          ) : (
              this.renderWeekList(popoverClick, reservations)
            )
        ) : (
            <div className="no-data">
              <Translate value="reservation.noData" />
            </div>
          )}
      </div>
    );
  }
}

ReservationList.propTypes = {
  reservations: PropTypes.array
};

ReservationList.defaultProps = {
  reservations: []
};

ReservationList.contextTypes = {
  time: PropTypes.object
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationList);
