import React, { useEffect, useState } from 'react';
import { FormControl, Table, InputGroup, FormGroup } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import SurchargeDistanceWarning from './SurchargeDistanceWarning';

import { textboxNumberHelper } from '../../../../utils/commonFunctions';
import { SURCHARGE_DEFAULT } from '../../../../constants/commondata';

export default function SurchargeDistanceTable(props) {
  const [distanceRange, setDistanceRange] = useState([]);
  const [unitDistance, setUnitDistance] = useState('');
  const [currency, setCurrency] = useState({});
  const [surchargeType, setSurchargeType] = useState('');
  const [dataInit, setDataInit] = useState({});

  useEffect(() => {
    if (props.data) {
      let dataDistanceRange = props.data.distanceRange || [];
      if (dataDistanceRange.length === 0) {
        dataDistanceRange.push({
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      }
      setDistanceRange(dataDistanceRange);
    }
    if (props.selectedFleet) {
      setUnitDistance(props.selectedFleet.unitDistance);
    }
    if (props.surchargeType) {
      setSurchargeType(props.surchargeType);
    }
    if (props.currency) {
      setCurrency(props.currency);
    }
    if (
      props.surchargeByCurrenciesInitial &&
      props.surchargeByCurrenciesInitial.length > 0
    ) {
      if (props.surchargeType === 'amount') {
        setDataInit(
          props.surchargeByCurrenciesInitial.find(
            (e) => e.currencyISO === props.currency.iso
          )
        );
      } else {
        setDataInit(props.surchargeByCurrenciesInitial[0]);
      }
    }
  }, [props]);
  const { bookType = '', hasCarHailingActive = false, shuttle = {} } = props;
  return (
    <>
      <Table className="table table-no-pagination cc-table-striped">
        <thead>
          <tr>
            <th>
              <Translate value="generalSetting.from" />
            </th>
            <th>
              <Translate value="generalSetting.to" />
            </th>
            {/* <th><Translate value="generalSetting.surchargeValue" /></th> */}
            {SURCHARGE_DEFAULT.surchargeByServices &&
              SURCHARGE_DEFAULT.surchargeByServices.map((service) => {
                if (
                  bookType === 'now' &&
                  (service.typeRate === 'shuttle' ||
                    (service.typeRate === 'carHailing' && !hasCarHailingActive))
                )
                  return null;
                if (
                  bookType === 'reservation' &&
                  (service.typeRate === 'carHailing' ||
                    (service.typeRate === 'shuttle' && !shuttle.enable))
                )
                  return null;

                return (
                  <th key={service.typeRate} key={service.typeRate}>
                    <Translate value={`generalSetting.${service.typeRate}`} />
                  </th>
                );
              })}
            <th>
              <Translate value="generalSetting.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {distanceRange.map((item, index) => {
            let valueTo =
              item.to === Number.MAX_SAFE_INTEGER ? Infinity : item.to || 0;
            return (
              <tr key={index}>
                <td>
                  <FormGroup
                    className={'qup-input-group surcharge-distance-value'}
                  >
                    <InputGroup
                      className={
                        'single-addon-right ' + (true ? 'disabled' : '')
                      }
                    >
                      <FormControl
                        type="text"
                        className="form-custom"
                        value={item.from || 0}
                        placeholder={I18n.t('generalSetting.from')}
                        disabled={true}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>{unitDistance}</InputGroup.Text>
                      </InputGroup.Append>{' '}
                    </InputGroup>
                  </FormGroup>
                </td>
                <td>
                  <FormGroup
                    className={'qup-input-group surcharge-distance-value'}
                  >
                    <InputGroup
                      className={
                        'single-addon-right ' + (true ? 'disabled' : '')
                      }
                    >
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={(e) => props.handleToInputChange(index, e)}
                        value={valueTo}
                        placeholder={I18n.t('generalSetting.to')}
                        disabled={props.editable}
                      />
                      <InputGroup.Prepend>
                        <InputGroup.Text>{unitDistance}</InputGroup.Text>
                      </InputGroup.Prepend>{' '}
                    </InputGroup>
                  </FormGroup>
                </td>
                {item.surchargeByServices &&
                  item.surchargeByServices.map((service) => {
                    if (
                      bookType === 'now' &&
                      (service.typeRate === 'shuttle' ||
                        (service.typeRate === 'carHailing' &&
                          !hasCarHailingActive))
                    )
                      return null;
                    if (
                      bookType === 'reservation' &&
                      (service.typeRate === 'carHailing' ||
                        (service.typeRate === 'shuttle' && !shuttle.enable))
                    )
                      return null;
                    return (
                      <td key={service.typeRate}>
                        <FormGroup
                          className={'qup-input-group surcharge-distance-value'}
                        >
                          <InputGroup
                            className={'single-addon-left disabled'}
                          >
                            {surchargeType === 'amount' ? (
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {surchargeType === 'amount'
                                    ? currency.symbol
                                    : '%'}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            ) : (
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {surchargeType === 'amount'
                                    ? currency.symbol
                                    : '%'}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            )}
                            <FormControl
                              type="text"
                              className="form-custom"
                              onChange={(e) =>
                                props.handleChangeSurchargeValue(
                                  index,
                                  e,
                                  service.typeRate
                                )
                              }
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e);
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e);
                              }}
                              value={service.value || 0}
                              placeholder={I18n.t(
                                'generalSetting.surchargeValue'
                              )}
                              disabled={props.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </td>
                    );
                  })}
                <td>
                  <div>
                    {item.to < 9007199254740991 ? (
                      <i
                        className="fa fa-trash fa-2x"
                        onClick={(e) => {
                          props.handleRemoveItemClick(item, index);
                        }}
                        disabled={props.editable}
                      />
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <SurchargeDistanceWarning
        data={props.data}
        dataInit={dataInit}
        range={20}
      />
    </>
  );
}
