import React, { useMemo, useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import pickupNew from '../../../assets/images/icons/pickupNew.svg';
import destinationNew from '../../../assets/images/icons/destinationNew.svg';
import '../dispatchlogs.scss';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';
import { QQMap } from '../../qqMap';

const MapGps = (props) => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);

  // Cleanup markers when component unmounts or data changes
  useEffect(() => {
    return () => {
      markers.forEach(marker => marker.map = null);
    };
  }, [markers]);

  const renderMarkers = (map, dataBooking) => {
    if (!map) return;

    // Clear existing markers
    markers.forEach(marker => marker.map = null);
    const newMarkers = [];

    let puPoints = JSON.parse(JSON.stringify(!props.typeDelivery ?
      (dataBooking?.puPointsGps || dataBooking?.puPoints) :
      (dataBooking?.deliveryInfo?.merchants?.length > 0 ?
        dataBooking?.deliveryInfo?.merchants :
        [dataBooking?.deliveryInfo?.pickup?.address])))

    let doPoints = JSON.parse(JSON.stringify(!props.typeDelivery ?
      (dataBooking?.doPointsGps || dataBooking?.doPoints) :
      dataBooking?.deliveryInfo?.recipients))

    Array.isArray(doPoints) && doPoints?.pop()
    Array.isArray(doPoints) && doPoints?.push(props.lastPoint)

    // Add pickup markers
    puPoints?.forEach((item, index) => {
      let geoData = item?.geo || item?.address?.geo;
      if (geoData?.length > 0) {
        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          { lat: geoData[1], lng: geoData[0] },
          pickupNew,
          (index + 1).toString()
          , 100, 32, 42);
        marker.map = map;
        newMarkers.push(marker);
      }
    });

    // Add destination markers
    doPoints?.forEach((item, index) => {
      let geoData = item?.geo || item?.address?.geo;
      if (geoData?.length > 0) {
        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          { lat: geoData[1], lng: geoData[0] },
          destinationNew,
          (puPoints.length + index + 1).toString(), 100, 32, 42
        );
        marker.map = map;
        newMarkers.push(marker);
      }
    });

    setMarkers(newMarkers);
  };

  const MapComponent = useMemo(() => {
    const customMapStyle = [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ];

    const onMapLoad = (mapInstance) => {
      setMap(mapInstance);
      // Render markers when map loads
      renderMarkers(mapInstance, props.dataBooking);

      if (props.handleUpdateMap) {
        props.handleUpdateMap(mapInstance)
      }
    };

    return (
      <div className="fill">
        <GoogleMap
          onLoad={onMapLoad}
          options={{
            fullscreenControl: false,
            gestureHandling: 'greedy',
            styles: customMapStyle,
            mapId: process.env.REACT_APP_MAP_ID,
            zoom: 15
          }}
          center={props.panToLocation || { lat: 16.059959, lng: 108.224258 }}
          mapContainerClassName="mapqup fill"
        />
      </div>
    );
  }, [props.dataBooking, props.logsDataList, props.lastPoint]);

  // Update markers when data changes
  useEffect(() => {
    renderMarkers(map, props.dataBooking);
  }, [map, props.dataBooking, props.lastPoint]);

  const MemoizedQQMap = useMemo(
    () => (
      <QQMap
        className="fill"
        onLoad={props.handleUpdateMap}
        options={{ center: props.panToLocation }}
      />
    ),
    [props.logsDataList, props.dataBooking]
  );

  return props.commonData.location && props.commonData.location.isChina ? (
    <>{MemoizedQQMap}</>
  ) : (
    <>{MapComponent}</>
  );
};

export default MapGps;
