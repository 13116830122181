import React, { Component } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import "./QuestionCircleTooltip.scss";
import PropTypes from "prop-types";

class QuestionCircleTooltip extends Component {
  render() {
    const { text, className, id, customeContent } = this.props;
    return (
      <OverlayTrigger
        placement={this.props.placement}
        delayShow={500}
        overlay={
          <Tooltip className="question" html={true} id={id || Math.random()}>
            {
              customeContent
              ? customeContent
              : text
            }
          </Tooltip>
        }
      >
        <i className={"fa fa-question-circle mr-l-5 " + (className || "")} />
      </OverlayTrigger>
    );
  }
}

QuestionCircleTooltip.propTypes = {
  placement: PropTypes.string
};

QuestionCircleTooltip.defaultProps = {
  placement: "right"
};

export default QuestionCircleTooltip;
