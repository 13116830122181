import {
  getCustomerApi,
  deleteCustomerApi,
  activateCustomerApi,
  deactivateCustomerApi,
  editCustomerApi,
  addCustomerApi,
  findOneCustomerApi,
  exportCustomerToExcelApi,
  checkExistCustomerPhoneApi,
  importCustomersApi,
  countCustomerApi,
  getPendingPaymentCustomerApi,
  getEmergencyContactCustomerApi,
  getTotalBalanceCustomerApi,
  writeOffDebtCustomerApi,
  topupPaxWalletCustomerApi,
  updatePointCustomerApi
} from '../constants/ApiConfigs';
import { callApi, UrlBuilder } from '../utils/apiUtils';

function deleteCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'delete',
    body: JSON.stringify(options)
  };
  return callApi(deleteCustomerApi, config, null, null, null, true);
}

function editCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(editCustomerApi, config, null, null, null, true);
}

function addCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(addCustomerApi, config, null, null, null, true);
}

function activateCustomer(options = {}, isActivate) {
  options = Object.assign({}, options);
  const config = {
    method: 'put',
    body: JSON.stringify(options)
  };
  return callApi(
    isActivate ? deactivateCustomerApi : activateCustomerApi,
    config,
    null,
    null,
    null,
    true
  );
}

function getCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getCustomerApi, config, null, null, null, true);
}

function countCustomers(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(countCustomerApi, config, null, null, null, true);
}

function findOneCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(findOneCustomerApi, options), config, null, null, null, true);
}

function getPendingPaymentCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(getPendingPaymentCustomerApi, options), config, null, null, null, true);
}

function getEmergencyContactCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(
    UrlBuilder(getEmergencyContactCustomerApi, options),
    config,
    null,
    null,
    null,
    true
  );
}

function getTotalBalanceCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(getTotalBalanceCustomerApi, options), config, null, null, null, true);
}

function writeOffDebtCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(writeOffDebtCustomerApi, config, null, null, null, false);
};

function topupPaxWalletCustomer(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(topupPaxWalletCustomerApi, config, null, null, null, false);
};

function updatePointCustomer (options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(updatePointCustomerApi, config, null, null, null, false);
}
function exportCustomerToExcel(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(exportCustomerToExcelApi, config, null, null, null, false);
}

function checkExistCustomerPhone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(checkExistCustomerPhoneApi, config, null, null, null, false);
}

function importCustomers(data) {
  const config = {
    method: 'POST',
    body: data,
    isFormData: true,
    timeout: 60000
  };
  return callApi(importCustomersApi, config, null, null, null, true);
}

export {
  deleteCustomer,
  activateCustomer,
  getCustomer,
  editCustomer,
  addCustomer,
  findOneCustomer,
  exportCustomerToExcel,
  checkExistCustomerPhone,
  importCustomers,
  countCustomers,
  getPendingPaymentCustomer,
  getEmergencyContactCustomer,
  getTotalBalanceCustomer,
  writeOffDebtCustomer,
  topupPaxWalletCustomer,
  updatePointCustomer
};
