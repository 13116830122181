import React from 'react';
import ReportUtils from '../base/utils';
import { Translate } from 'react-redux-i18n';

export const fields = [
  {
    key: 'store',
    label: 'report.result.merchant.store',
  },
  {
    key: 'companyName',
    label: 'report.result.merchant.companyName',
  },
  {
    key: 'name',
    label: 'report.result.merchant.name',
  },
  {
    key: 'phone',
    label: 'report.result.merchant.phoneNumber',
  },
  {
    key: 'bookingId',
    label: 'report.result.merchant.bookingId',
    link: {
      label: 'report.result.financial.showBookingList',
      newTab: true,
      href: '/reports/Booking_details',
      name: 'goToBookingDetails',
      params: ['merchantId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Show list booking details filtered by merchantId',
    },
  },
  {
    key: 'totalOrders',
    label: 'report.result.merchant.totalOrders',
    summary: 'totalOrders',
  },
  {
    key: 'completedOrders',
    label: 'report.result.merchant.completedOrders',
    summary: 'completedOrders',
  },
  {
    key: 'canceledOrders',
    label: 'report.result.merchant.canceledOrders',
    summary: 'canceledOrders',
  },
  {
    key: 'incidentOrders',
    label: 'report.result.merchant.incidentOrders',
    summary: 'incidentOrders',
  },
  {
    key: 'revenue',
    label: 'report.result.merchant.revenue',
    summary: 'revenue',
  },
  {
    key: 'commission',
    label: 'report.result.merchant.commission',
    summary: 'commission',
  },
  {
    key: 'earning',
    label: 'report.result.merchant.earning',
    summary: 'earning',
  },
];

export const fareFields = ['revenue', 'commission', 'earning'];
