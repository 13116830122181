import PropTypes from 'prop-types';
import React from 'react';
import IconvsTextBtn from './IconvsTextBtn';
import openLinkGreenIcon from '../../../../../assets/images/icons/openLinkGreen.svg';
import { Button } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import { I18n } from 'react-redux-i18n';

const CopyNewTabBtn = ({ text, icon, link }, context) => {

  const handleCopyClick = () => {
    copy(link);
    context.notification(
      'success',
      I18n.t('bookingdetail.copied')
    )
  };

  return (
    <div 
      className='linksBook'
    >
      <Button
        variant="link" 
        onClick={handleCopyClick}
        className='copylink'
      >
        <IconvsTextBtn
          text={text}
          icon={icon}
        />
      </Button>
      <Button
        variant="link"
        href={link}
        target="_blank"
        className='newTabB'
      >
        <IconvsTextBtn
          text={''}
          heightIcon={'12px'}
          icon={openLinkGreenIcon}
        />
      </Button>
    </div>
  );
};

CopyNewTabBtn.contextTypes = {
  notification: PropTypes.func,
}

CopyNewTabBtn.defaultProps = {
  text: '',
  icon: '',
  link: ''
};

CopyNewTabBtn.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CopyNewTabBtn;
