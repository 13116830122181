import React, { Component } from 'react';
import { FormGroup, Image } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';
import { userType } from '../../../constants/commondata';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import RadioButton from '../../radioButton/radio';
import timeIcon from '../../../assets/images/icons/time.svg';

const currencyFormatter = require('currency-formatter');

class DispatchInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this);
  }

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name;
  }

  handleDriverCalendarClick = (suggestion) => {
    this.props.handleDriverCalendarClick(suggestion);
  };

  renderDriverSuggestion(suggestion, { query }) {
    let { status } = suggestion;
    const { data } = this.props;
    if (!suggestion.online) {
      status = 'offline';
    } else if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status;
    }
    let pickUpTime = data.time;
    const isBookingDetail = !!data.bookId;

    if (isBookingDetail) {
      pickUpTime = data.request.pickUpTime;
    }

    return (
      <div className={`suggestion-content driver-assign ${status}`}>
        <div className="status" />
        <div className="info">
          <span className="">
            {`${suggestion.firstName} ${suggestion.lastName}${
              suggestion.company ? ` / ${suggestion.company.companyName}` : ''
            }`}
          </span>
          <span>{`${suggestion.vehicle.plateNumber} / ${suggestion.phone}`}</span>
        </div>
        {pickUpTime != 'Now' ? (
          <div
            className="calendar"
            onClick={(e) => {
              // e.preventDefault();
              // e.stopPropagation();
              this.handleDriverCalendarClick(suggestion);
            }}
          >
            <Image src={timeIcon} />
            {/* <span className='calendar' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleDriverCalendarClick(suggestion)
                            }
                            }>{I18n.t('newbooking.Calendar')}</span> */}
          </div>
        ) : null}
      </div>
    );
  }

  render() {
    const {
      user,
      data,
      handleChangeDispatchType,
      newBooking,
      isSubmited,
      driversuggestions,
      onDriverSuggestionsFetchRequested,
      onDriverSuggestionsClearRequested,
      driverAutocompleteSlectedHandle,
      handleDriverChanged,
      cartypeSelected,
      driver,
      bookingCurrency,
      selectedFleet,
      handleDispatch3rdOnchange,
      ValidatorCallback,
      valid,
      isEditable,
      isDispatchAble,
    } = this.props;

    const isBookingDetail = !!data.bookId;

    let dispatchType = data.dispatch_type;
    let driverName = data.driver;

    let isDispatchDisable = false;
    let autoSuggestDisabled =
      isDispatchDisable ||
      data.dispatch_type == 0 ||
      cartypeSelected === null ||
      (newBooking.locationService && newBooking.locationService.crossZone);

    if (isBookingDetail) {
      dispatchType = data.dispatchType;
      driverName = data.drvInfo ? data.drvInfo.fullName || '' : '';

      if (
        data.pricingType === 1 &&
        data.fleetId != data.request.psgFleetId &&
        (data.status === 'accepted' ||
          data.status === 'confirmed' ||
          data.status === 'action')
      ) {
        /**
         * Booking farmIn -> able assign to driver
         */
        isDispatchDisable = false;
        autoSuggestDisabled = data.dispatchType == 0 || isDispatchDisable;
      } else {
        isDispatchDisable =
          (!isEditable(data) && !isDispatchAble(data)) || data.pricingType;
        autoSuggestDisabled =
          data.dispatchType == 0 ||
          cartypeSelected == null ||
          isDispatchDisable;
      }
    }

    return (
      <div>
        <FormGroup>
          <FormGroupTitle>
            <Translate value="newbooking.DISPATCH" />
          </FormGroupTitle>
          {user.userType == userType.FleetAdmin ||
          user.userType == userType.FleetUser ? (
            <FormGroup>
              <FormGroup className="radio-button-group">
                <RadioButton
                  text={I18n.t('newbooking.Auto_dispatch')}
                  value={0}
                  onChange={handleChangeDispatchType}
                  checked={dispatchType == 0 || dispatchType == 2}
                  name="Auto_dispatch"
                  disabled={isDispatchDisable}
                />
                <RadioButton
                  text={I18n.t('newbooking.Assign_driver')}
                  value={1}
                  name="Auto_dispatch"
                  onChange={handleChangeDispatchType}
                  checked={dispatchType == 1}
                  disabled={isDispatchDisable}
                />
              </FormGroup>
              <FormGroup
                className={`assign-driver-container ${
                  !isSubmited
                    ? null
                    : valid.dispatch_driver === false
                    ? 'has-error'
                    : null
                }`}
              >
                <Autosuggest
                  suggestions={driversuggestions}
                  onSuggestionsFetchRequested={
                    onDriverSuggestionsFetchRequested
                  }
                  onSuggestionsClearRequested={
                    onDriverSuggestionsClearRequested
                  }
                  getSuggestionValue={this.getDriverSuggestionValue}
                  renderSuggestion={this.renderDriverSuggestion}
                  onSuggestionSelected={driverAutocompleteSlectedHandle}
                  shouldRenderSuggestions={() => true}
                  inputProps={{
                    className: 'form-control form-custom',
                    value: driverName,
                    placeholder: I18n.t('newbooking.Search_here'),
                    onChange: handleDriverChanged,
                    disabled: autoSuggestDisabled,
                  }}
                />
              </FormGroup>
              {selectedFleet.driverDeposit &&
              data.paymentMethod == 1 &&
              data.dispatch_type == 1 &&
              !!data.driver &&
              selectedFleet.warningAmount &&
              driver &&
              driver.driverId &&
              driver.driverId.driverInfo &&
              driver.driverId.driverInfo.creditBalances &&
              CCLiteCommonFunc.getValueOfMultiCurrencies(
                driver.driverId.driverInfo.creditBalances,
                bookingCurrency.iso,
                'value'
              ) <=
                CCLiteCommonFunc.getValueOfMultiCurrencies(
                  selectedFleet.warningAmount,
                  bookingCurrency.iso,
                  'value'
                ) ? (
                <Translate
                  value="newbooking.Driver_credit_limited_balance_warning"
                  className="text-warning"
                />
              ) : null}
              {/* <Validator callback={ValidatorCallback} id='dispatch_driver' disabled={data.dispatch_type == 0}>
                                <ValidCase valid={!Validation.isStringEmpty(data.driver) && driver} hide={true} />
                            </Validator> */}
            </FormGroup>
          ) : null}

          {
            // if new booking
            !isBookingDetail && selectedFleet.partyDispatching ? (
              <CcCheckbox
                disabled={
                  user.userType == userType.CorporateAdmin ||
                  user.userType == userType.CorporateUser
                }
                checked={data.dispatch3rd}
                onChange={handleDispatch3rdOnchange}
                text={I18n.t('newbooking.dispatch_3rd')}
              />
            ) : null
          }

          {
            // if booking detail check if old book is not dispatch 3rd
            isBookingDetail &&
            selectedFleet.partyDispatching &&
            data.pricingType == 0 ? (
              <CcCheckbox
                checked={data.dispatch3rd}
                onChange={handleDispatch3rdOnchange}
                disabled={
                  isBookingDetail &&
                  (!isEditable(data) ||
                    data.psgInfo.phone == 'No Phone' ||
                    user.userType == userType.CorporateAdmin ||
                    user.userType == userType.CorporateUser)
                }
                text={I18n.t('newbooking.dispatch_3rd')}
              />
            ) : null
          }
        </FormGroup>
      </div>
    );
  }
}

export default DispatchInfo;
