import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as settingActions from '../../../../actions/settingActions';

import Select from '../../../../components/select/Select';
import { Validator, ValidCase } from '../../../../components/validator';

const hourRange = _.range(0, 24);
const minsRange = _.range(0, 60);

class ThirdPartyCategoryInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearchCategory: ''
    };
    this.searchCategoriesDebounce = _.debounce(this.searchCategories, 250);
  }

  componentDidMount() {
    const { phone = '' } = this.props || {};
    const e = {
      target: {
        value: phone
      }
    };
    this.props.handleChangePhoneNumber(e);
  }

  handleLoadOption = (input, callback) => {
    if (!input) return callback();

    const { settingActions, auth } = this.props;

    this.searchCategoriesDebounce(
      auth.selectedFleet.fleetId,
      settingActions.findCategory,
      input,
      callback
    );
  };

  searchCategories = (fleetId, findCategoryAction, input, callback) => {
    const params = {
      textSearch: input,
      fleetId,
      limit: 100,
      page: 0
    };

    findCategoryAction(params)
      .then(data => {
        if (data.ok && data.res && data.res.list.length > 0) {
          const result = { complete: true };
          result.options = (data.res.list || []).map(cat => ({
            name: cat.name,
            _id: cat._id
          }));
          callback(null, result);
        } else callback(null, null);
      })
      .catch(() => callback(null, null));
  };

  handleInputChange = (value) => {
    this.setState({ textSearchCategory: value });
  }

  handleInputBlur = () => {
    this.setState({ textSearchCategory: '' });
  }

  onCreateNewCategory = () => {
    const { textSearchCategory } = this.state;
    const { auth } = this.props;
    const body = {
      fleetId: auth.selectedFleet.fleetId,
      name: textSearchCategory
    };
    this.props.settingActions.createCategory(body).then(({ res }) => {
      if (res) {
        const { handleCategoriesChange, categories } = this.props;
        handleCategoriesChange((categories || []).concat([res]));
        this.categoryRef.select.blurInput();
      }
    });
  };

  renderCategoryNoResult = () => {
    const { textSearchCategory } = this.state;
    if (!textSearchCategory) return null;
    return (
      <span>
        {I18n.t('message.No_results')}
        <button className="btn-link" onClick={this.onCreateNewCategory}>
        Click here to add new
        </button>
          {textSearchCategory}
      </span>
    );
  };

  render() {
    const {
      availableTime = {
        from: '00:00',
        to: '00:00'
      }
    } = this.props || {};

    const { from = '00:00', to = '00:00' } = availableTime || {};

    const hourFrom = parseInt(from.split(':')[0] || '0');
    const minsFrom = parseInt(from.split(':')[1] || '0');
    const hourTo = parseInt(to.split(':')[0] || '0');
    const minsTo = parseInt(to.split(':')[1] || '0');

    return (
      <div>
        <FormGroup
          className={
            !this.props.isSubmited
              ? null
              : this.props.valid.categories === true
              ? null
              : 'error'
          }
        >
          <Form.Label>
            <Translate value="thirdPartySettings.category" />{' '}
            <span className="require"> *</span>
          </Form.Label>
          <Select.Async
            multi
            valueKey="_id"
            labelKey="name"
            cache={false}
            ref={ref => this.categoryRef = ref}
            placeholder={I18n.t('message.Search_category_name')}
            searchPromptText={''}
            loadingPlaceholder={I18n.t('message.Searching')}
            noResultsText={this.renderCategoryNoResult()}
            className="receiver-list form-custom custom-select-control none-bg-arrow"
            value={this.props.categories}
            loadOptions={this.handleLoadOption}
            onChange={this.props.handleCategoriesChange}
            disabled={this.props.disabled}
            ignoreAccents={false}
            ignoreCase={false}
            onInputChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
          />
          <Validator id="categories" callback={this.props.ValidatorCallback}>
            <ValidCase
              hide={!this.props.isSubmited}
              valid={this.props.categories && this.props.categories.length > 0}
              message={I18n.t(
                'thirdPartySettings.Please_choose_at_least_one_category'
              )}
            />
          </Validator>
        </FormGroup>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup className="no-marginBottom">
              <Form.Label
                className="text-trim-ellipsis"
                title={I18n.t('driver.Phone_number')}
                alt={I18n.t('driver.Phone_number')}
              >
                {I18n.t('driver.Phone_number')}
              </Form.Label>

              <FormGroup
                className={
                  !this.props.isSubmited
                    ? null
                    : this.props.valid.phone
                    ? null
                    : 'error'
                }
              >
                <FormControl
                  type="text"
                  className="form-custom no-marginBottom"
                  onChange={this.props.handleChangePhoneNumber}
                  value={this.props.phone}
                  disabled={this.props.disabled}
                />
                <Validator id="phone" callback={this.props.ValidatorCallback}>
                  <ValidCase
                    hide={!this.props.isSubmited}
                    valid={!this.props.phone || this.props.phone.length <= 50}
                    message={I18n.t('messages.commonMessages.Max_length').format(50)}
                  />
                </Validator>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup 
              className={`no-marginBottom ${
                !this.props.isSubmited
                  ? null
                  : this.props.valid.website
                  ? null
                  : 'error'
              }`}
            >
              <Form.Label
                className="text-trim-ellipsis"
                title={I18n.t('thirdPartySettings.website')}
                alt={I18n.t('thirdPartySettings.website')}
              >
                {I18n.t('thirdPartySettings.website')}
              </Form.Label>

              <FormControl
                className="form-custom no-marginBottom"
                type="text"
                onChange={e => {
                  this.props.onChangeInput('website', e);
                }}
                value={this.props.website}
                disabled={this.props.disabled}
              />
              <Validator id="website" callback={this.props.ValidatorCallback}>
                <ValidCase
                  hide={!this.props.isSubmited}
                  valid={!this.props.website || this.props.website.length <= 100}
                  message={I18n.t('messages.commonMessages.Max_length').format(100)}
                />
              </Validator>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <Form.Label>
                <Translate value="thirdPartySettings.availableTime" />
              </Form.Label>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup>
                <Form.Label className={'mr-md'}>
                  <Translate value="shiftSettings.From" />{' '}
                </Form.Label>
                <FormControl
                  as="select"
                  className={'form-control form-custom time-select'}
                  onChange={e => this.props.handleAvailableTime('hourFrom', e)}
                  value={hourFrom}
                  disabled={this.props.disabled}
                >
                  {hourRange.map(hour => {
                    return (
                      <option value={hour} key={hour}>{`${
                        hour < 10 ? '0' : ''
                      }${hour}`}</option>
                    );
                  })}
                </FormControl>
                <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                <FormControl
                  as="select"
                  className={'form-control form-custom time-select'}
                  onChange={e => this.props.handleAvailableTime('minsFrom', e)}
                  value={minsFrom}
                  disabled={this.props.disabled}
                >
                  {minsRange.map(min => {
                    return (
                      <option value={min} key={min}>{`${
                        min < 10 ? '0' : ''
                      }${min}`}</option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={12} md={6}>
            <Form inline>
              <FormGroup>
                <Form.Label className={'mr-md'}>
                  <Translate value="shiftSettings.To" />{' '}
                </Form.Label>
                <FormControl
                  as="select"
                  className={'form-control form-custom time-select'}
                  onChange={e => this.props.handleAvailableTime('hourTo', e)}
                  value={hourTo}
                  disabled={this.props.disabled}
                >
                  {hourRange.map(hour => {
                    return (
                      <option value={hour} key={hour}>{`${
                        hour < 10 ? '0' : ''
                      }${hour}`}</option>
                    );
                  })}
                </FormControl>
                <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                <FormControl
                  as="select"
                  className={'form-control form-custom time-select'}
                  onChange={e => this.props.handleAvailableTime('minsTo', e)}
                  value={minsTo}
                  disabled={this.props.disabled}
                >
                  {minsRange.map(min => {
                    return (
                      <option value={min} key={min}>{`${
                        min < 10 ? '0' : ''
                      }${min}`}</option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyCategoryInfo);
