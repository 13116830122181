import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import {
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { forEach, cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import momentTZ from 'moment-timezone';
import moment from 'moment';

import StickyTable from '../../components/table/stickyTable/StickyTable';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import TableActions from '../../components/table/tableAction/TableActions';
import CCDropDown from '../../components/dropdown/ccDropDown';
import Confirm from '../../components/confirm/Confirm';

import {
  CCLiteCommonFunc,
  Validation,
  trimPhoneNumber,
  removeEmojis,
} from '../../utils/commonFunctions';

import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import * as merchantActions from '../../actions/merchantActions';
import * as uploadActions from '../../actions/uploadActions';
import { fetchRelativeInfo } from '../../actions/commonDataAction';

import { Columns } from './tableColumns';

import AddEditMerchantUser from './components/AddEditMerchantUser';
import './merchant.scss';
import { BsSearch } from 'react-icons/bs';

const numItemsPerPage = 20;
let phone;
const CONFIRM_ACTIVATE_ID = 1;
const CONFIRM_DEACTIVATE_ID = 2;
const CONFIRM_RESET_PASSWORD = 3;
const CONFIRM_REMOVE_BANK_INFO = 4;
class Merchant extends Component {
  constructor() {
    super();
    this.state = {
      dialogData: {},
      merchantData: {
        data: [],
        available: true,
        q: '',
        total: 0,
        page: 1,
        per_page: 20,
        hasMore: true,
      },
      merchantUsers: {
        data: [],
        total: 0,
        page: 1,
        per_page: 20,
      },
      valid: {},
      isSubmitted: false,
      isShowCashWalletBalance: false,
      isShowCreditWalletBalance: false,
      showDialog: false,
      sortType: -1,
      editable: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('mDispatcher.mDispatchers'),
      },
      footerLoading: true,
      phoneExistValidation: true,
      phoneValidation: true,
      imageUpload: {},
      bankRequirement: {},
    };
    this.getMerchantList = this.getMerchantList.bind(this);
    this.getListMerchantUsers = this.getListMerchantUsers.bind(this);
    this.getBankRequirement = this.getBankRequirement.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.searchMerchantDebounce = _.debounce(this.handleSearchMerchant, 300);
    this.isChangeZipcode = false;
  }

  componentDidMount() {
    const { auth, fetchRelativeInfo } = this.props;
    const fleetId = auth.selectedFleet.fleetId;

    this.getMerchantList({ page: 1 });
    this.getListMerchantUsers(0, null, true);
    this.getBankRequirement();
    fetchRelativeInfo({ fleetId });
  }

  getMerchantList(params, loadMore = false, callback = null) {
    const { merchantData } = this.state;
    const { auth, merchantActions } = this.props;
    let bodyRequest = {
      per_page: merchantData ? merchantData.per_page : numItemsPerPage,
      page: params.page ? params.page : merchantData.page,
      fleetId: auth.selectedFleet.fleetId,
    };

    if (params.q) {
      bodyRequest.q = params.q.trim();
    }

    const companyId = _.get(auth, 'user.roles.companyId', '');
    if (companyId) {
      bodyRequest.companyId = companyId
    }

    merchantActions.getMerchantList(bodyRequest).then((response) => {
      if (response.ok && response.res) {
        this.setState({
          merchantData: {
            ...merchantData,
            ...response.res,
            hasMore: response.res.data.length === merchantData.per_page,
            data: loadMore
              ? merchantData.data.concat(response.res.data)
              : response.res.data,
          },
        });
      }
      if (callback) callback();
    });
  }

  getListMerchantUsers(activePage = 0, limit, countPartner) {
    let param = {
      per_page: limit
        ? limit
        : this.state.merchantUsers
        ? this.state.merchantUsers.per_page
        : numItemsPerPage,
      page: activePage + 1,
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    if (this.state.str) {
      param.q = this.state.str.trim();
      if (!activePage) {
        param.page = 1;
      }
    }

    if (this.state.merchantId) {
      param.merchantId = this.state.merchantId;
      if (!activePage) {
        param.page = 1;
      }
    } else {
      const { auth } = this.props;
      const companyId = _.get(auth, 'user.roles.companyId', '');
      if (companyId) {
        param.companyId = companyId
      }
    }

    this.setState({
      isLoading: true,
      footerLoading: countPartner,
    });
    this.props.merchantActions.find(param).then((data) => {
      if (data.ok && data.res) {
        let { page, total, per_page } = data.res;
        this.setState({
          merchantUsers: data.res,
          footerData: {
            ...this.state.footerData,
            limit: per_page,
            page: page - 1,
            total,
          },
          footerLoading: false,
          isLoading: false,
        });
      }
    });
  }

  getBankRequirement() {
    const { auth, merchantActions } = this.props;
    merchantActions
      .getBankRequirement({ fleetId: auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.ok) {
          this.setState({
            bankRequirement:
              data.res && data.res.response ? data.res.response : undefined,
          });
        }
      });
  }

  getMerchantUserDetails = (userId, editable) => {
    const { auth, merchantActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      userId,
    };
    loadingBarActions.showLoadingSpiner();
    merchantActions.details(body).then((response) => {
      loadingBarActions.hideLoadingSpiner();
      if (response.ok && !response.error) {
        let data = response.res.data;
        let merchantCashWallet = response.res.merchantCashWallet;
        let merchantCreditWallet = response.res.merchantCreditWallet;
        data.phoneNumber = response.res.data.phone.full || '';
        phone = trimPhoneNumber(response.res.data.phone.full);
        let bankInfo = response.res.merchantInfo.bankInfo || {};
        let dialogData = { ...data, ...bankInfo };
        dialogData.merchantCreditWallet = merchantCreditWallet;
        dialogData.merchantCashWallet = merchantCashWallet;
        this.setState(
          {
            dialogData,
            editable,
          },
          () => {
            this.openFormDialog();
          }
        );
      } else {
        if (response.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + response.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('merchant.Load_merchant_user_fail')
          );
        }
      }
    });
  };

  handleUploadFile = (file) => {
    const {
      uploadService,
      auth: { selectedFleet },
    } = this.props;

    if (file) {
      const form = new FormData();
      form.append('photo', file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('module', 'merchant');
      return uploadService.uploadPhoto(form).then((res) => {
        if (!res.error) {
          return Promise.resolve(_.get(res, 'res.Location'));
        }
        this.context.notification('error', I18n.t('errors.upload_photo_fail'));
        return Promise.resolve({ error: res.error });
      });
    }
    return Promise.resolve();
  };

  saveDialogForm = () => {
    const { auth, loadingBarActions } = this.props;
    const { valid, imageUpload, bankRequirement } = this.state;
    let promises = [];
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return;
    }

    let fleetId = auth.selectedFleet.fleetId;
    let dialogData = _.cloneDeep(this.state.dialogData);
    const phoneNumber = parsePhoneNumber(dialogData.phoneNumber);
    const birthDay = dialogData.birthDay
      ? moment(dialogData.birthDay).format('MM/DD/YYYY')
      : '';
    let bodyRequest = {
      ...dialogData,
      fleetId,
      phone: {
        country:
          phoneNumber && phoneNumber.country
            ? phoneNumber.country
            : dialogData.phone.country,
        number:
          phoneNumber && phoneNumber.nationalNumber
            ? phoneNumber.nationalNumber
            : dialogData.phone.number,
      },
    };
    bodyRequest.birthDay = birthDay;
    loadingBarActions.showLoadingSpiner();

    if (imageUpload.avatar) {
      promises.push(
        this.handleUploadFile(imageUpload.avatar).then((dataImageUpload) => {
          if (dataImageUpload && dataImageUpload.error) {
            loadingBarActions.hideLoadingSpiner();
            return;
          }
          bodyRequest.avatar = dataImageUpload;
        })
      );
    }
    if (imageUpload.verificationDocumentFront) {
      promises.push(
        this.handleUploadFile(imageUpload.verificationDocumentFront).then(
          (dataImageUpload) => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            }
            bodyRequest.verificationDocumentFront = dataImageUpload;
          }
        )
      );
    }
    if (imageUpload.verificationDocumentBack) {
      promises.push(
        this.handleUploadFile(imageUpload.verificationDocumentBack).then(
          (dataImageUpload) => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            }
            bodyRequest.verificationDocumentBack = dataImageUpload;
          }
        )
      );
    }
    if (imageUpload.additionalDocumentFront) {
      promises.push(
        this.handleUploadFile(imageUpload.additionalDocumentFront).then(
          (dataImageUpload) => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            }
            bodyRequest.additionalDocumentFront = dataImageUpload;
          }
        )
      );
    }
    if (imageUpload.additionalDocumentBack) {
      promises.push(
        this.handleUploadFile(imageUpload.additionalDocumentBack).then(
          (dataImageUpload) => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            }
            bodyRequest.additionalDocumentBack = dataImageUpload;
          }
        )
      );
    }

    Promise.all(promises).then(() => {
      if (
        bankRequirement.legalToken &&
        bankRequirement.gateway === 'Stripe' &&
        bodyRequest.birthDay &&
        window.Stripe &&
        this.props.commonData.relativeInfo.stripe &&
        this.props.commonData.relativeInfo.stripe.publicKey
      ) {
        // Upload document to Stripe;
        let verificationFrontFile = null;
        let verificationBackFile = null;
        let additionalFrontFile = null;
        let additionalBackFile = null;

        if (imageUpload.verificationDocumentFront) {
          verificationFrontFile = this.uploadStripeVerificationDocument(
            imageUpload.verificationDocumentFront
          );
        } else {
          verificationFrontFile = dialogData.frontFileId;
        }
        if (imageUpload.verificationDocumentBack) {
          verificationBackFile = this.uploadStripeVerificationDocument(
            imageUpload.verificationDocumentBack
          );
        } else {
          verificationBackFile = dialogData.backFileId;
        }
        if (imageUpload.additionalDocumentFront) {
          additionalFrontFile = this.uploadStripeAdditionalDocument(
            imageUpload.additionalDocumentFront
          );
        } else {
          additionalFrontFile = dialogData.additionalFrontFileId;
        }
        if (imageUpload.additionalDocumentBack) {
          additionalBackFile = this.uploadStripeAdditionalDocument(
            imageUpload.additionalDocumentBack
          );
        } else {
          additionalBackFile = dialogData.additionalBackFileId;
        }

        Promise.all([
          verificationFrontFile,
          verificationBackFile,
          additionalFrontFile,
          additionalBackFile,
        ]).then((files) => {
          const [
            frontFileId,
            backFileId,
            additionalFrontFileId,
            additionalBackFileId,
          ] = files;
          bodyRequest.frontFileId = frontFileId;
          bodyRequest.backFileId = backFileId;
          bodyRequest.additionalFrontFileId = additionalFrontFileId;
          bodyRequest.additionalBackFileId = additionalBackFileId;
          // Get Stripe token before submit form
          if (!this.legalToken || this.isChangeZipcode) {
            this.createStripeToken(files).then((result) => {
              if (result) {
                this.legalToken = result;
                bodyRequest.legalToken = result;
                this.handleSave(bodyRequest);
              } else {
                loadingBarActions.hideLoadingSpiner();
                return;
              }
            });
          } else {
            bodyRequest.legalToken = this.legalToken;
            this.handleSave(bodyRequest);
          }
        });
      } else {
        this.handleSave(bodyRequest);
      }
    });
  };

  handleSave = (bodyRequest) => {
    const {
      dialogData,
      merchantUsers,
      merchantUsers: { data },
    } = this.state;
    const { merchantActions, loadingBarActions } = this.props;

    if (dialogData.id) {
      bodyRequest.userId = dialogData.id;
      bodyRequest.merchantId = dialogData.merchantId.id;
      merchantActions
        .update(bodyRequest)
        .then((response) => {
          loadingBarActions.hideLoadingSpiner();
          if (response.ok && !response.error && response.res.data.id) {
            this.closeFormModal();
            this.context.notification(
              'success',
              I18n.t('merchant.Update_merchant_user_success')
            );
            const listItem = cloneDeep(data);
            const index = listItem.findIndex(
              (o) => o.id === response.res.data.id
            );
            listItem[index] = response.res.data;
            this.setState({
              isSubmitted: false,
              merchantUsers: {
                ...merchantUsers,
                data: listItem,
              },
              dialogData: {},
              imageUpload: {},
            });
          } else {
            if (response.error) {
              if (response.error.message && response.error.message.length > 0) {
                this.context.notification('error', `${response.error.message}`);
                return;
              }
              this.context.notification(
                'error',
                I18n.t('errors.' + response.error.errorCode)
              );
            } else if (
              response.res.data.message === 'This phone number is already exist'
            ) {
              this.context.notification('error', I18n.t('errors.29002'));
            } else {
              this.context.notification(
                'error',
                I18n.t('merchant.Update_merchant_user_fail')
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      bodyRequest.password = '12345678'; //SL-24215  Change password default to "12345678"
      bodyRequest.merchantId = dialogData.merchantId;
      merchantActions
        .create(bodyRequest)
        .then((response) => {
          loadingBarActions.hideLoadingSpiner();
          if (response.ok && !response.error && response.res.data.id) {
            this.closeFormModal();
            this.context.notification(
              'success',
              I18n.t('merchant.Create_merchant_user_success')
            );
            this.setState({
              isSubmitted: false,
              merchantUsers: {
                ...merchantUsers,
                data: [response.res.data, ...merchantUsers.data],
              },
              dialogData: {},
              imageUpload: {},
            });
          } else {
            if (response.error) {
              if (response.error.message && response.error.message.length > 0) {
                this.context.notification('error', `${response.error.message}`);
                return;
              }
              this.context.notification(
                'error',
                I18n.t('errors.' + response.error.errorCode)
              );
            } else if (
              response.res.data.message === 'This phone number is already exist'
            ) {
              this.context.notification('error', I18n.t('errors.29002'));
            } else {
              this.context.notification(
                'error',
                I18n.t('merchant.Create_merchant_user_fail')
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  // Doc: https://stripe.com/docs/file-upload
  uploadStripeVerificationDocument = (fileData) => {
    const stripe = window.Stripe(
      this.props.commonData.relativeInfo.stripe.publicKey
    );
    let formData = new FormData();
    formData.append('file', fileData);
    formData.append('purpose', 'identity_document');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then((response) => {
      return response.json().then((o) => o.id);
    });
  };

  uploadStripeAdditionalDocument = (fileData) => {
    const stripe = window.Stripe(
      this.props.commonData.relativeInfo.stripe.publicKey
    );
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('purpose', 'additional_verification');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then((response) => {
      return response.json().then((o) => o.id);
    });
  };

  createStripeToken = (files) => {
    const { commonData } = this.props;
    const { dialogData } = this.state;
    const stripePublicKey = _.get(
      commonData,
      'relativeInfo.stripe.publicKey',
      ''
    );
    const stripe = window.Stripe(stripePublicKey);
    const [
      frontFileId,
      backFileId,
      additionalFrontFileId,
      additionalBackFileId,
    ] = files;
    let form = {
      business_type: 'individual',
      individual: {
        first_name: dialogData.firstName || '',
        last_name: dialogData.lastName || '',
        phone: dialogData.phoneNumber || '',
        email: dialogData.email || '',
        address: {
          line1: dialogData.address || '',
          city: dialogData.city || '',
          state: dialogData.state || '',
          postal_code: dialogData.postalCode || '',
        },
      },
      tos_shown_and_accepted: true,
    };
    let dob = _.clone(dialogData.birthDay);
    dob = momentTZ(dob, 'YYYY/MM/DD');
    form.individual.dob =
      typeof dialogData.birthDay == 'string'
        ? this.getDayMonthYear(dialogData.birthDay)
        : {
            day: dob.format('D'),
            month: dob.format('M'),
            year: dob.format('YYYY'),
          };

    /* Split firstname, lastname by accountName */
    if (dialogData.accountHolder) {
      const arrName = dialogData.accountHolder.split(' ');
      form.individual.first_name = arrName[0];
      form.individual.last_name = dialogData.accountHolder
        .substring(form.individual.first_name.length)
        .trim();
    }

    if (frontFileId || backFileId) {
      form.individual.verification = {
        document: {
          front: frontFileId,
          back: backFileId,
        },
      };
    }

    if (additionalFrontFileId || additionalBackFileId) {
      form.individual.verification = form.individual.verification || {};
      form.individual.verification.additional_document = {
        front: additionalFrontFileId,
        back: additionalBackFileId,
      };
    }

    return stripe
      .createToken('account', form)
      .then((data) => {
        if (data.error) {
          this.context.notification('error', data.error.message);
          return null;
        }
        return data.token.id;
      })
      .catch((err) => {
        this.context.notification('error', err.message);
        return null;
      });
  };

  getDayMonthYear = (birthDay) => {
    var d = new Date(birthDay),
      month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear();
    return {
      day: day,
      month: month,
      year: year,
    };
  };

  handleSendMessageClick = () => {
    this.props.router.push({
      pathname: '/merchant/message/',
    });
  };

  handleResetPassword = () => {
    const { auth, merchantActions, loadingBarActions } = this.props;
    const { dialogData } = this.state;
    let fleetId = auth.selectedFleet.fleetId;
    loadingBarActions.showLoadingSpiner();
    merchantActions
      .resetPassword({
        fleetId,
        userId: dialogData.id || '',
      })
      .then((response) => {
        loadingBarActions.hideLoadingSpiner();
        if (response.ok && !response.error) {
          this.context.notification(
            'success',
            I18n.t('merchant.Reset_password_merchant_user_success')
          );
        } else {
          if (response.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + response.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('merchant.Reset_password_merchant_user_fail')
            );
          }
          // this.setState({ isChanged: true });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleUpdateStatusClick = (userId, status) => {
    const { auth, merchantActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      userId,
      isActive: status === 2 ? false : true,
    };
    merchantActions.active(body).then((response) => {
      if (response.ok && !response.error) {
        this.context.notification(
          'success',
          I18n.t('merchant.Update_merchant_user_status_success')
        );
        const {
          merchantUsers,
          merchantUsers: { data },
        } = this.state;
        const listItem = cloneDeep(data);
        const index = listItem.findIndex((o) => o.id === response.res.data.id);
        listItem[index] = {
          ...listItem[index],
          status: response.res.data.status,
        };
        this.setState({
          isSubmitted: false,
          merchantUsers: {
            ...merchantUsers,
            data: listItem,
          },
        });
      } else {
        if (response.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + response.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('merchant.Update_merchant_user_status_fail')
          );
        }
      }
    });
  };

  handleUpdateMultipleStatusClick = (ids, phones, status) => {
    const { auth, merchantActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      ids,
      phones,
      isActive: status,
    };
    merchantActions.multiActive(body).then((response) => {
      if (response.ok && !response.error) {
        this.context.notification(
          'success',
          I18n.t('merchant.Update_merchant_user_status_success')
        );
        const { page, per_page } = this.state.merchantUsers;
        this.getListMerchantUsers(page - 1, per_page, true);
      } else {
        if (response.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + response.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('merchant.Update_merchant_user_status_fail')
          );
        }
      }
    });
  };

  handleSelectAllClick = (e) => {
    const { target } = e;
    const listCust = _.cloneDeep(this.state.merchantUsers.data);
    _.forEach(listCust, (user) => {
      user.checked = target.checked;
    });
    this.setState({
      merchantUsers: { ...this.state.merchantUsers, data: listCust },
    });
  };

  handleSelectRowClick(checked, id) {
    const listUser = _.cloneDeep(this.state.merchantUsers.data);
    _.forEach(listUser, (user) => {
      if (id === user.id) {
        user.checked = checked;
        return false;
      }
    });
    this.setState({
      merchantUsers: { ...this.state.merchantUsers, data: listUser },
    });
  }

  // Toolbar
  handleSearchChange = (str) => this.setState({ str });
  handleSearchKeyPress = (e) => {
    if (this.state.isLoading) return;
    if (e.key === 'Enter') {
      this.getListMerchantUsers(0, null, true);
    }
  };

  handleMerchantChange = (merchantId) => {
    if (!merchantId) {
      this.setState(
        {
          merchantData: {
            data: [],
            available: true,
            q: '',
            total: 0,
            page: 1,
            per_page: 20,
            hasMore: true,
          },
          merchantId: '',
        },
        () => {
          this.getMerchantList({ page: 1 });
        }
      );
    } else {
      this.setState({ merchantId });
    }
  };
  handleSearchMerchant = (inputValue, callback, forceUpdate = false) => {
    const { merchantData } = this.state;
    const params = _.pick(merchantData, ['q', 'page', 'per_page']);
    if (inputValue !== params.q || forceUpdate) {
      params.page = 1;
      params.q = inputValue;
      this.setState(
        {
          merchantData: {
            ...merchantData,
            q: inputValue,
          },
        },
        () => {
          this.getMerchantList(params, false, callback);
        }
      );
    } else {
      return callback ? callback() : null;
    }
  };
  handleLoadMoreMerchantOption = () => {
    const { merchantData } = this.state;
    const params = _.pick(merchantData, ['q', 'page', 'per_page']);
    params.page++;
    if (!merchantData.hasMore) return;
    this.getMerchantList(params, true);
  };

  handleApplyFilter = () => {
    this.getListMerchantUsers(0, null, true);
  };

  handleClearFilter = () => {
    this.setState(
      {
        merchantId: null,
        str: '',
      },
      () => {
        this.getListMerchantUsers(0, null, true);
      }
    );
  };

  handleAddButtonClick() {
    const { auth } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    this.setState(
      {
        isSubmitted: false,
        dialogData: {
          fleetId,
          merchantId: '',
          phone: {
            country: '',
            number: '',
          },
          phoneNumber: '',
          firstName: '',
          lastName: '',
          status: 2,
          avatar: '',
          email: '',
          isBankAccountOwner: true,
        },
        editable: true,
      },
      () => {
        this.openFormDialog();
      }
    );
  }

  activeMultipleMerchantUser = (status) => {
    if (
      this.state.merchantUsers.data.filter((user) => user.checked).length === 0
    ) {
      this.setState({
        confirm: {
          title: !status
            ? I18n.t('merchant.user.Deactivate')
            : I18n.t('merchant.user.Activate'),
          body: !status
            ? I18n.t('merchant.user.ALERT_SELECT_DEACTIVATE')
            : I18n.t('merchant.user.ALERT_SELECT_ACTIVATE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: !status ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
          title: !status
            ? I18n.t('merchant.user.Deactivate')
            : I18n.t('merchant.user.Activate'),
          body: !status
            ? I18n.t('merchant.user.CONFIRM_DEACTIVATE_MULTI')
            : I18n.t('merchant.user.CONFIRM_ACTIVATE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  };

  handleClickResetPasswordButton = () => {
    this.setState({
      confirm: {
        id: CONFIRM_RESET_PASSWORD,
        title: I18n.t('merchant.user.RESET_PASSWORD'),
        body: I18n.t('merchant.user.CONFIRM_RESET_PASSWORD'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      },
    });
  };
  handleClickCashWalletChange = () => {
    this.setState({
      isShowCashWalletBalance: true,
    });
  };
  handleClickCashCancelWallet = () => {
    this.setState({
      isShowCashWalletBalance: false,
    });
  };
  handleClickCreditWalletChange = () => {
    this.setState({
      isShowCreditWalletBalance: true,
    });
  };
  handleClickCreditCancelWallet = () => {
    this.setState({
      isShowCreditWalletBalance: false,
    });
  };
  handleWalletBalanceSuccess = (response) => {
    const { merchantActions, loadingBarActions } = this.props;
    this.setState({
      isShowCreditWalletBalance: false,
      isShowCashWalletBalance: false,
    });
    loadingBarActions.showLoadingSpiner();
    merchantActions
      .details({
        fleetId: this.state.dialogData.fleetId,
        userId: this.state.dialogData.id,
      })
      .then((response) => {
        loadingBarActions.hideLoadingSpiner();
        if (response.ok && !response.error) {
          let data = response.res.data;
          let merchantCashWallet = response.res.merchantCashWallet;
          let merchantCreditWallet = response.res.merchantCreditWallet;
          data.phoneNumber = response.res.data.phone.full || '';
          phone = trimPhoneNumber(response.res.data.phone.full);
          let bankInfo = response.res.merchantInfo.bankInfo || {};
          let dialogData = { ...data, ...bankInfo };
          dialogData.merchantCreditWallet = merchantCreditWallet;
          dialogData.merchantCashWallet = merchantCashWallet;
          this.setState(
            {
              dialogData,
            },
            () => {
              this.openFormDialog();
              const { page, per_page } = this.state.merchantUsers;
              this.getListMerchantUsers(page - 1, per_page, true);
            }
          );
        } else {
          if (response.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + response.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('merchant.Load_merchant_user_fail')
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleConfirmButtonClick = (id) => {
    const { merchantUsers } = this.state;
    let ids = [];
    let phones = [];
    if (merchantUsers.data) {
      ids = merchantUsers.data
        .filter((obj) => obj.checked)
        .map((obj) => obj.id);
      phones = merchantUsers.data
        .filter((obj) => obj.checked)
        .map((obj) => obj.phone.full);
    }
    switch (id) {
      case CONFIRM_ACTIVATE_ID: {
        this.handleUpdateMultipleStatusClick(ids, phones, true);
        break;
      }
      case CONFIRM_DEACTIVATE_ID: {
        this.handleUpdateMultipleStatusClick(ids, phones, false);
        break;
      }
      case CONFIRM_RESET_PASSWORD: {
        this.handleResetPassword();
        break;
      }
      case CONFIRM_REMOVE_BANK_INFO: {
        this.handleDeleteBankInfo();
        break;
      }
    }
    this.handleConfirmCloseClick();
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  handleMenuClick = (key, userId, obj) => {
    switch (key) {
      case 'View':
        this.getMerchantUserDetails(userId, false);
        break;
      case 'Edit':
        this.getMerchantUserDetails(userId, true);
        break;
      case 'UpdateStatus':
        this.handleUpdateStatusClick(userId, obj.status);
        break;
      default:
        break;
    }
  };

  renderSelectBoxColumn = () => {
    let isCheckedAll = false;
    if (
      this.state.merchantUsers &&
      this.state.merchantUsers.data &&
      this.state.merchantUsers.data.length > 0
    ) {
      isCheckedAll =
        _.filter(this.state.merchantUsers.data, (item) => item.checked)
          .length === this.state.merchantUsers.data.length;
    }
    return {
      key: 'table-selectbox',
      label: '',
      width: 60,
      fixed: true,
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      customHeader: (columnKey) => (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={isCheckedAll}
          onChange={this.handleSelectAllClick}
        />
      ),
      customCell: (item) => (
        <CcCheckbox
          className="table-selectbox"
          checked={item.checked}
          onChange={(e) => {
            this.handleSelectRowClick(e.target.checked, item.id);
          }}
        />
      ),
    };
  };

  getTableColumns = () => {
    const { auth } = this.props;
    let tableColumns = Object.assign([], Columns);
    const merchantCashWalletEnalble = _.get(
      auth.selectedFleet,
      'merchantCashWallet.enable',
      false
    );
    const merchantCreditWalletEnalble = _.get(
      auth.selectedFleet,
      'merchantCreditWallet.enable',
      false
    );
    const removeColumns = [];
    if (!merchantCreditWalletEnalble) removeColumns.push('wallet.creditWallet');
    if (!merchantCashWalletEnalble) removeColumns.push('wallet.cashWallet');
    _.remove(tableColumns, col => removeColumns.indexOf(col.key) !== -1);
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'fullName':
          col.customCell = (obj) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{obj.fullName}</p>
                </Tooltip>
              }
            >
              <a
                onClick={(e) => {
                  this.handleMenuClick('View', obj.id);
                }}
                className="text-base cursor-pointer"
              >
                {obj.fullName}
              </a>
            </OverlayTrigger>
          );
          break;
        case 'status':
          col.customCell = (obj) => {
            return (
              <div className={obj.status === 2 ? 'Active' : 'Inactive'}>
                {canUpdate ? (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleUpdateStatusClick(obj.id, obj.status);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {obj.status === 2 ? (
                      <Translate value="mDispatcher.Active" />
                    ) : (
                      <Translate value="mDispatcher.Inactive" />
                    )}
                  </a>
                ) : obj.status === 2 ? (
                  <Translate value="mDispatcher.Active" />
                ) : (
                  <Translate value="mDispatcher.Inactive" />
                )}
              </div>
            );
          };
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            if (canUpdate) {
              let actionItems = [
                {
                  label: 'merchant.Edit',
                  eventKey: 'Edit',
                },
                {
                  label:
                    obj.status === 2
                      ? 'merchant.Deactivate'
                      : 'merchant.Activate',
                  eventKey: 'UpdateStatus',
                },
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={obj}
                  onSelect={(eventKey) =>
                    this.handleMenuClick(eventKey, obj.id, obj)
                  }
                  totalRow={
                    this.state.merchantUsers
                      ? this.state.merchantUsers.data.length
                      : 0
                  }
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', obj.id);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="mDispatcher.View" />
                </a>
              );
            }
          };
          break;
        case 'wallet.creditWallet':
          col.customCell = (obj) => {
            let value = _.get(obj, 'wallet.creditWallet.value', 0);
            let currencyISO = _.get(obj, 'wallet.creditWallet.currencyISO', '');
            return (
              <div>
                {currencyFormatter.format(value, { code: currencyISO })}
              </div>
            );
          };
          break;
        case 'wallet.cashWallet':
          col.customCell = (obj) => {
            let value = _.get(obj, 'wallet.cashWallet.currentBalance', 0);
            let currencyISO = _.get(obj, 'wallet.cashWallet.currencyISO', '');
            return (
              <div>
                {currencyFormatter.format(value, { code: currencyISO })}
              </div>
            );
          };
          break;
        default:
          break;
      }
    });

    const checkBoxCol = this.renderSelectBoxColumn();
    tableColumns.unshift(checkBoxCol);
    return tableColumns;
  };

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const filterHeight = 60;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight =
      verticalPadding + toolbarMarginBottom + toolbarheight + filterHeight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  handleNumItemsPerPageChange = (limit) => this.getListMerchantUsers(0, limit);
  handlePaginationSelect = (page) => this.getListMerchantUsers(parseInt(page));

  openFormDialog = () => {
    this.setState({
      showDialog: true,
      valid: {},
      isSubmitted: false,
    });
  };

  closeFormModal = () => {
    this.setState(
      {
        dialogData: {},
        showDialog: false,
        editable: false,
        valid: {},
        isSubmitted: false,
        merchantData: {
          ...this.state.merchantData,
          q: '',
        },
      },
      () => {
        this.getMerchantList({ page: 1 });
      }
    );
  };

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleInputChange = (e, key) => {
    const { dialogData } = this.state;
    let value;
    switch (key) {
      case 'bankCode':
        this.setState({
          dialogData: {
            ...dialogData,
            bankCode: e,
          },
        });
        break;
      case 'isBankAccountOwner':
        value = e.target.value === 'true';
        this.setState({
          dialogData: {
            ...dialogData,
            [key]: value,
          },
        });
        break;
      case 'birthDay':
        if (!e._d) {
          value = '';
        } else {
          value = e._d;
        }
        this.setState({
          dialogData: {
            ...dialogData,
            // birthDay: value
            birthDay: moment(value).format('MM/DD/YYYY'),
          },
        });
        break;
      default:
        value = removeEmojis(e.target.value);
        if (key === 'postalCode') this.isChangeZipcode = true;
        this.setState({
          dialogData: {
            ...dialogData,
            [key]: value,
          },
        });
        break;
    }
  };

  handlePhoneChange = (status, value, countryData, number, id, isBlur) => {
    if (isBlur) {
      if (!status) return;
      let newPhone;
      if (
        countryData &&
        '+' + countryData.dialCode == trimPhoneNumber(number)
      ) {
        newPhone = '';
      } else {
        newPhone = trimPhoneNumber(number);
      }
      if (newPhone !== phone) {
        //   this.props.mDispatcherActions
        //     .checkExistmDispatcherPhone({
        //       fleetId: this.props.auth.selectedFleet.fleetId,
        //       phone: newPhone
        //     })
        //     .then(data => {
        //       if (data.ok) {
        //         this.setState({ phoneExistValidation: !data.res.existed });
        //       }
        //     });
      }
    } else {
      this.setState({
        dialogData: {
          ...this.state.dialogData,
          phoneNumber: number,
        },
        phoneExistValidation: true,
        phoneValidation: status,
      });
    }
  };

  handleImageChange = (changeFile, error, key) => {
    const { dialogData, imageUpload } = this.state;
    const data = changeFile || {};
    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification(
        'error',
        I18n.t('upload_document.Limit_size_upload').format(8)
      );
      return;
    }
    if (key === 'avatar') {
      dialogData.avatar = data.result;
    } else {
      dialogData[key] =
        data && data.file && data.file.name ? data.file.name : null;
    }
    this.setState({
      dialogData,
      imageUpload: {
        ...imageUpload,
        [key]: data.file || null,
      },
    });
  };

  handleRemoveBankDocumentChange = (key) => {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        [key]: '',
      },
      imageUpload: {
        [key]: '',
      },
    });
  };

  handleClickRemoveBankInfo = () => {
    this.setState({
      confirm: {
        id: CONFIRM_REMOVE_BANK_INFO,
        title: I18n.t('merchant.user.REMOVE_BANK_INFO'),
        body: I18n.t('merchant.user.CONFIRM_REMOVE_BANK_INFO'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      },
    });
  };

  handleDeleteBankInfo = () => {
    const { auth, merchantActions, loadingBarActions } = this.props;
    const { dialogData } = this.state;
    let fleetId = auth.selectedFleet.fleetId;
    const bodyRequest = {
      fleetId,
      merchantId: dialogData.merchantId.id,
      name: dialogData.merchantId.name,
    };
    loadingBarActions.showLoadingSpiner();
    merchantActions
      .deleteBankInfoMerchant(bodyRequest)
      .then((response) => {
        loadingBarActions.hideLoadingSpiner();
        this.getMerchantUserDetails(dialogData.id, true);
        if (response.ok && !response.error) {
          this.context.notification(
            'success',
            I18n.t('merchant.Deleted_merchant_bank_info_success')
          );
        } else {
          if (response.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + response.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('merchant.Deleted_merchant_bank_info_fail')
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleStoreChange = (e) => {
    const { dialogData, merchantData } = this.state;
    if (!e) {
      this.setState(
        {
          merchantData: {
            data: [],
            available: true,
            q: '',
            total: 0,
            page: 1,
            per_page: 20,
            hasMore: true,
          },
          dialogData: {
            ...dialogData,
            merchantId: '',
            merchantName: '',
          },
        },
        () => {
          this.getMerchantList({ page: 1 });
        }
      );
    } else {
      let merchant = merchantData.data.find((store) => store.id === e);
      this.setState({
        dialogData: {
          ...dialogData,
          merchantId: e,
          merchantName: merchant && merchant.name ? merchant.name : '',
        },
      });
    }
  };

  render() {
    const {
      dialogData,
      merchantData,
      editable,
      showDialog,
      isSubmitted,
      valid,
      isShowCreditWalletBalance,
      isShowCashWalletBalance,
      phoneExistValidation,
      phoneValidation,
      bankRequirement,
    } = this.state;
    const {
      permissions = null,
      auth: { user, selectedFleet } = {},
      language,
    } = this.props || {};
    const { actions = false } = permissions || {};
    const { auth } = this.props;
    const selectedCurrencyObj = _.get(auth, 'selectedFleet.currencies[0]', []);
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    const bodyData =
      this.state.merchantUsers && this.state.merchantUsers.data
        ? this.state.merchantUsers.data
        : [];
    const isShowInbox = user.isAdmin
      ? true
      : permissions.sendinbox || permissions.sendsms
      ? true
      : false;
    return (
      <div className="content">
        <div className="mb">
          <ButtonToolbar
            className="text-center header-button-group mb0"
            ref={(node) => (this.toobarContainer = node)}
          >
            <div className="group-left btn-group">
              <FormGroup className="search-format">
                <FormControl
                  className="search-form"
                  type="text"
                  value={this.state.str || ''}
                  placeholder={I18n.t('General.search')}
                  onChange={(e) => this.handleSearchChange(e.target.value)}
                  onKeyPress={this.handleSearchKeyPress}
                />
                <BsSearch className="search-icon" />
              </FormGroup>
              {canUpdate && (
                <React.Fragment>
                  <Button
                    className="btn-header text-add-header"
                    onClick={this.handleAddButtonClick}
                  >
                    <Translate value="mDispatcher.Add" />
                  </Button>
                  {/* <Button
                                        className="btn-header text-delete-header"
                                        disabled={false}
                                        onClick={this.openConfirmDeleteDialog}
                                    >
                                        <Translate value="promotionSettings.Delete" />
                                    </Button> */}
                  <Button
                    className="btn-header text-active-header"
                    onClick={() => {
                      this.activeMultipleMerchantUser(true);
                    }}
                  >
                    <Translate value="mDispatcher.Activate" />
                  </Button>
                  <Button
                    className="btn-header text-deactive-header"
                    onClick={() => {
                      this.activeMultipleMerchantUser(false);
                    }}
                  >
                    <Translate value="mDispatcher.Deactivate" />
                  </Button>
                </React.Fragment>
              )}
            </div>
            <ButtonGroup className="group-right">
              {isShowInbox ? (
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleSendMessageClick}
                >
                  <Translate value="driver.Send_Message" />
                </Button>
              ) : null}
            </ButtonGroup>
          </ButtonToolbar>

          <div className="merchant-toolbar-extend">
            <div className="toolbarExtend--leftContent">
              <CCDropDown
                id="merchant-dropdown"
                items={merchantData.data}
                title={I18n.t('merchant.user.storeName')}
                selectedItems={this.state.merchantId || []}
                valueKey="id"
                labelKey="name"
                onSelect={this.handleMerchantChange}
                onSearchChange={this.searchMerchantDebounce}
                onScrollBottom={this.handleLoadMoreMerchantOption}
                noTranslateLabel
                searchable
                async
                enableClearAll
              />
              <Button
                className="btn-header text-add-header"
                onClick={this.handleApplyFilter}
              >
                <Translate value="customer.Apply" />
              </Button>
              <Button
                className="btn-header text-deactive-header"
                onClick={this.handleClearFilter}
              >
                <Translate value="customer.Clear" />
              </Button>
            </div>
          </div>

          <div className="gridViewTable">
            <StickyTable
              columns={this.getTableColumns()}
              bodyData={bodyData}
              footerData={this.state.footerData}
              rowHeight={this.state.rowHeight}
              getTableHeight={this.getTableHeight}
              handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
              handlePaginationSelect={this.handlePaginationSelect}
              // sortHandle={this.handleSortmDispatcherClick}
              // sortType={this.state.sortType}
              // sortName={this.state.sortName}
              footerLoading={this.state.footerLoading}
              isLoading={this.state.isLoading}
            />
          </div>
          {showDialog && (
            <AddEditMerchantUser
              isShowCreditWalletBalance={isShowCreditWalletBalance}
              isShowCashWalletBalance={isShowCashWalletBalance}
              dialogData={dialogData}
              selectedCurrencyObj={selectedCurrencyObj}
              merchantList={merchantData.data}
              bankRequirement={bankRequirement}
              selectedFleet={selectedFleet}
              language={language}
              editable={editable}
              canUpdate={canUpdate}
              showDialog={showDialog}
              isSubmitted={isSubmitted}
              valid={valid}
              phoneExistValidation={phoneExistValidation}
              phoneValidation={phoneValidation}
              ValidatorCallback={this.ValidatorCallback}
              handleInputChange={this.handleInputChange}
              handlePhoneChange={this.handlePhoneChange}
              handleImageChange={this.handleImageChange}
              handleRemoveBankDocumentChange={
                this.handleRemoveBankDocumentChange
              }
              handleRemoveBankInfo={this.handleClickRemoveBankInfo}
              handleStoreChange={this.handleStoreChange}
              onSearchChange={this.searchMerchantDebounce}
              onScrollBottom={this.handleLoadMoreMerchantOption}
              closeFormModal={this.closeFormModal}
              saveDialogForm={this.saveDialogForm}
              permissions={permissions}
              handleClickEditButton={this.handleMenuClick}
              handleClickResetPasswordButton={
                this.handleClickResetPasswordButton
              }
              handleClickCashWalletChange={this.handleClickCashWalletChange}
              handleClickCreditWalletChange={this.handleClickCreditWalletChange}
              handleClickCashCancelWallet={this.handleClickCashCancelWallet}
              handleClickCreditCancelWallet={this.handleClickCreditCancelWallet}
              handleWalletBalanceSuccess={this.handleWalletBalanceSuccess}
            />
          )}
        </div>

        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Merchant.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    merchantActions: bindActionCreators(merchantActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
    fetchRelativeInfo: (options) => {
      return dispatch(fetchRelativeInfo(options));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
