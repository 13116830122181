import React from 'react';
import {Col} from 'react-bootstrap';
import {HorizontalBar} from "react-chartjs-2";
import {I18n} from 'react-redux-i18n';
import {renderHintLabel} from '../../utils/chartUtils';

const BookingRequest = ({bookFromChartData}) => {
  return (
    <Col sm={12} md={6} className='mr-t-20'>
      <div className='db-body chart-container'>
        <div className='db-chart-title'>
                  <span className='title'>
                    {renderHintLabel(
                      I18n.t('dashboard.Booking_requests'),
                      I18n.t('dashboard.BookingRequestHint'),
                    )}
                  </span>
        </div>
        <div className='db-chart'>
          <HorizontalBar
            data={bookFromChartData}
            options={{
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#e6e6e6',
                      fontSize: 12,
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#e6e6e6',
                      fontSize: 12,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    </Col>
  )
};

export default BookingRequest;
