import '../styles.scss';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

export default class ConversionModal extends React.Component {
  render() {
    const {
      show,
      title,
      bookId,
      closeModal,
      currencyISO,
      original,
      conversion,
    } = this.props;

    let header = '';
    if (title) {
      header = I18n.t(title);
    }
    if (bookId) {
      header = header ? `${header} #${bookId}` : `#${bookId}`;
    }

    return (
      <Modal
        show={show}
        backdrop="static"
        dialogClassName="confirm-dialog logs-modal"
        onHide={closeModal}
      >
        {header && (
          <Modal.Header closeButton>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body style={{ width: 450 }}>
          <div className="modal-report-table-container">
            <table className="cc-table cc-table-striped">
              <tbody>
                <tr>
                  <td
                    className="modal-report-table-row-content"
                    style={{ padding: 20, width: '100%' }}
                  >
                    Original ({currencyISO})
                  </td>
                  <td
                    className="modal-report-table-row-content"
                    style={{ padding: 20, width: '100%' }}
                  >
                    {(original || 0).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td
                    className="modal-report-table-row-content"
                    style={{ padding: 20 }}
                  >
                    Conversion (USD)
                  </td>
                  <td
                    className="modal-report-table-row-content"
                    style={{ padding: 20 }}
                  >
                    {(conversion || 0).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
