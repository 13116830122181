import _ from 'lodash';
import {
  activeStatus,
  userType,
  TRAVELER_TYPE,
} from '../../../constants/commondata';

function isPaymentMethodEditable(booking, is3rdBooking) {
  let status = activeStatus.filter((data) => {
    return data.code == booking.status;
  })[0];
  if (is3rdBooking) return false;
  if (!booking.pricingType) {
    return status && status.paymentMethodEditable;
  } else if (!booking.farmOut) {
    return status && status.paymentMethodEditable;
  }
  return false;
}

const calulatePreAuthoried = (data) => {
  const authAmount = data?.preAuthorized?.authAmount || 0;
  const additionalPreauths = (
    data?.preAuthorized?.additionalPreauths || []
  ).reduce(
    (accumulator, currentValue) => accumulator + currentValue.addlAmount,
    0
  );
  return authAmount + additionalPreauths;
};

const checkShowGoogleApplePay = (data = {}) => {
  const paymentType = data?.request?.paymentType;
  if (
    data?.preAuthorized?.authAmount > 0 &&
    (paymentType !== 9 || paymentType !== 23)
  )
    return true;
  return false;
};

const checkRemoveGoogleOrApplePay = (
  paymentType,
  isAllwayShowGoogleApplePay
) => {
  if (![9, 23].includes(paymentType) && !isAllwayShowGoogleApplePay) {
    // xóa cả 2 khi paymentType đang chọn khác google và apple và preAuthorized amount < 0
    return ['ApplePay', 'GooglePay'];
  } else {
    // show only google pay or apple pay
    if (paymentType === 23) {
      return ['ApplePay'];
    }

    if (paymentType === 9) {
      return ['GooglePay'];
    }

    return [];
  }
};

export const convertPaymentTypeForCompleteWithoutService = (paymentTypeBooking) => {
  let paymentTypeToComplete = paymentTypeBooking;
  switch (paymentTypeBooking) {
    case 1:
      paymentTypeToComplete = 0;
      break;
    case 2:
      paymentTypeToComplete = 1;
      break;
    case 3:
      paymentTypeToComplete = 5;
      break;
    case 4:
      paymentTypeToComplete = 7;
      break;
    case 5:
      paymentTypeToComplete = 6;
      break;
    case 6:
      paymentTypeToComplete = 3;
      break;
    case 8:
      paymentTypeToComplete = 9;
      break;
    case 9:
      paymentTypeToComplete = 10;
      break;
    case 7:
      paymentTypeToComplete = 8;
      break;
  }
  return paymentTypeToComplete;
};

export const checkPaymentShow = (params) => {
  let {
    auth,
    companySelected,
    paymentMethodIndividuals = [],
    paymentMethodCorporates = [],
    corporateSelected,
    traveler_type,
    isDelivery,
    isBookingDetail,
    requestPaymentType
  } = params;
  let newPaymentMethodCorporates = [...paymentMethodCorporates];
  // check show by company
  if (companySelected?.paymentMethodsApply?.length > 0 && !(auth?.selectedFleet?.process?.broadcastBooking || true)) {
    const paymentEnableByCompany = companySelected.paymentMethodsApply;
    paymentMethodIndividuals = paymentMethodIndividuals.filter((pm) =>
      paymentEnableByCompany.includes(pm.key)
    );
    newPaymentMethodCorporates = newPaymentMethodCorporates.filter((pm) =>
      paymentEnableByCompany.includes(pm.key)
    );
    // if is delivery and company has support cash then add more cashBySender and cashByRecipient
    if (isDelivery && paymentEnableByCompany.includes('cash')) {
      const pmCashDelivery = [
        {
          key: 'cashBySender',
          name: 'Cash by sender',
          type: 'cash',
          value: 16,
        },
        {
          key: 'cashByRecipient',
          name: 'Cash by recipient',
          type: 'cash',
          value: 17,
        },
      ];
      paymentMethodIndividuals = [
        ...paymentMethodIndividuals,
        ...pmCashDelivery,
      ];
      newPaymentMethodCorporates = [
        ...newPaymentMethodCorporates,
        ...pmCashDelivery,
      ];
    }
  }

  // check show by corporate
  // But only check when operator is corporate
  let isDisableSelectMethodCorp = false;
  if (
    (auth?.user?.userType === userType.CorporateAdmin ||
      auth?.user?.userType === userType.CorporateUser) &&
    traveler_type == TRAVELER_TYPE.Corporate &&
    corporateSelected
  ) {
    newPaymentMethodCorporates = newPaymentMethodCorporates.filter((pm) => {
      if(pm.key === 'credit') return true;
      return (corporateSelected.paymentMethods || []).some(
        (ob) => ob.type === pm.key && ob.isActive
      )
    }
    );
    // Trường hơp có add ít nhất 1 card => turn on: Corporate card
    if (corporateSelected?.credits?.length > 0) {
      const corpCreditEnable = paymentMethodCorporates.find((pm) => pm.key === 'corpCredit')
      if(corpCreditEnable) {
        newPaymentMethodCorporates.push(corpCreditEnable);
      }
    }

    // book delivery nếu bật cash => + cashBysender and cashByRecipient
    if (
      isDelivery &&
      corporateSelected.paymentMethods.some(
        (ob) => ob.type === 'cash' && ob.isActive
      )
    ) {
      if (paymentMethodCorporates.find((pm) => pm.key === 'cashBySender')) {
        newPaymentMethodCorporates.push(
          paymentMethodCorporates.find((pm) => pm.key === 'cashBySender')
        );
      }
      if (paymentMethodCorporates.find((pm) => pm.key === 'cashByRecipient')) {
        newPaymentMethodCorporates.push(
          paymentMethodCorporates.find((pm) => pm.key === 'cashByRecipient')
        );
      }
    }

    if (isBookingDetail) {
      // only Booking Detail
      // case khong co bat ki payment nao duoc support  => show pm khi tao booking và disable
      if (
        !newPaymentMethodCorporates ||
        newPaymentMethodCorporates.length === 0
      ) {
        isDisableSelectMethodCorp = true;
        // case operator admin assign 1 payment nhưng ở operator corp không duoc support
      } else if (
        !newPaymentMethodCorporates?.some(
          (pm) => pm?.value === requestPaymentType
        )
      ) {
        const obPaymentRequest = paymentMethodCorporates.find(
          (pm) => pm?.value === requestPaymentType
        );
        if (obPaymentRequest?.value) {
          newPaymentMethodCorporates.push(obPaymentRequest);
        }
      }
    }
  }

  return {
    paymentMethodIndividuals: paymentMethodIndividuals,
    paymentMethodCorporates: newPaymentMethodCorporates,
    isDisableSelectMethodCorp,
  };
};

export {
  isPaymentMethodEditable,
  checkShowGoogleApplePay,
  checkRemoveGoogleOrApplePay,
  calulatePreAuthoried,
};
