export const cueOfferRemoveKey = [
    'driver',
    'passenger',
    'status',
    'request.note',
    'airportPickup',
    'pickupDropOffLocalTime',
    'spotTime',
    'spareTime',
    'eta',
]

export const cueActiveKey = [
    'bookId',
    'bookingType',
    'status',
    'pickupDropOff',
    'pickupDropOffLocalTime',
    'carType',
    'serviceType',
    'driver',
    'vehicle',
    'eta',
    'spotTime',
    'spareTime',
    'passenger',
    'request.paxNumber',
    'request.luggageNumber',
    'airportPickup',
    'request.note',
    'supplierPayout',
    'location',
]

export const cueFinishedKey = [
    'bookId',
    'status',
    'pickupDropOff',
    'pickupDropOffLocalTime',
    'carType',
    'driver',
    'vehicle',
    'passenger',
]