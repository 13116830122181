import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Image
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import * as intercityBookingActions from '../../../../actions/intercityBookingActions';
import { additionalServiceType } from '../../../../constants/commondata';
import { RootCloseWrapper } from 'react-overlays';
import destinationIcon from '../../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../../assets/images/icons/pickup2.svg';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

function SecondStep({ 
  pickups, 
  destinations, 
  bookInfo, 
  selectedFleet, 
  paymentComplete, 
  commonData, 
  paymentMethod, 
  fareSettings, 
  completedMarkFailed = false,
  ...props 
}) {
  const [showExtraServices, setShowExtraServices] = useState(false);
  const {
    request: {
      estimate: { fare },
    },
  } = bookInfo;
  let {
    request: {
      paymentType,
    },
  } = bookInfo; 

  if([9, 23].includes(paymentType)) {
    paymentType = 1
  }

  return (
    <div>
      {pickups.length > 0 && 
        pickups.map((pickup) => (
          <FormGroup className="location-info">
            <Image src={pickupIcon} />
            <span>{pickup.address && pickup.address.address}</span>
          </FormGroup>
        ))
      }
      {destinations.length > 0 && 
        destinations.map((destination) => (
          <FormGroup className="location-info">
            <Image src={destinationIcon} />
            <span>{destination.address && destination.address.address}</span>
          </FormGroup>
        ))
      }
      <FormGroup>
        <Form.Label style={{ position: 'relative' }}>
          <Translate value="bookingdetail.Subtotal" />
          {'\u00A0'}
          {fare.min && (
            <Translate value="bookingdetail.Minimum" className="minimum" />
          )}
        </Form.Label>
        <span className="amount">
          {currencyFormatter.format(fare.subTotal, {
            code: bookInfo.currencyISO,
          })}
        </span>
      </FormGroup>
      {fareSettings.fare.tollFeeActive || fareSettings.item ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Toll_fees" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                      {bookInfo.currencySymbol}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.tollFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}

      {fareSettings.fare.parkingFeeActive || fareSettings.item ? (
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.parkingFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                      {bookInfo.currencySymbol}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.parkingFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}

      {fareSettings.fare.gasFeeActive || fareSettings.item ? (
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.gasFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                      {bookInfo.currencySymbol}
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.gasFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}

      {selectedFleet.techFeeActive ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Tech_fees" />
          </Form.Label>
          <span className="amount">
            {currencyFormatter.format(fare ? fare.techFee : 0, {
              code: bookInfo.currencyISO
            })}
          </span>
        </FormGroup>
      ) : null}

      {fare && fare.creditTransactionFee > 0 ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.creditTransactionFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  min={0}
                  value={currencyFormatter.format(fare.creditTransactionFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                  disabled
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : null}

      {fareSettings.fare.taxActive ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Tax" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  min={0}
                  value={currencyFormatter.format(fare.tax, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                  disabled
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Promo_code" />
        </Form.Label>
        <FormGroup>
          <div className="add-on-input-group">
            <FormControl
              type="text"
              value={(bookInfo.request.promo || '').toUpperCase()}
              placeholder={I18n.t('newbooking.Promo_code')}
              disabled
              className="form-custom"
            />
          </div>
        </FormGroup>
      </FormGroup>
     
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Promo" />
        </Form.Label>
        <span className="amount">
          {currencyFormatter.format(
            fare.promoAmount || 0,
            {
              code: bookInfo.currencyISO,
            }
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Total" />
        </Form.Label>
        <span className="amount total">
          {currencyFormatter.format(
            fare.etaFare,
            {
              code: bookInfo.currencyISO,
            }
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Payment_Type" />
        </Form.Label>
        <FormControl
          type="text"
          className="form-custom"
          value={I18n.t(`paymentMethod.paymentMethod_${paymentType}`)}
          disabled
        >
        </FormControl>
      </FormGroup>
      { (paymentType === 1 || paymentType == 16 || paymentType == 17)
        && !completedMarkFailed ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.pending_payment" />
          </Form.Label>
          <CcCheckbox
            checked={paymentComplete.isPending}
            onChange={props.handleCompletePaymentIsPendingChange}
            className="paymentModal__pendingPayment"
          />
        </FormGroup>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    bookInfo: state.bookingDetail.data,
    commonData: state.commonData,
    paymentMethod: state.paymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
