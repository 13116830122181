import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportPassengerWalletApi } from '../../../constants/ApiConfigs';
import { I18n } from 'react-redux-i18n';
import { getChannelPaymentXendit } from '../../../utils/commonFunctions';
import { paymentMethod } from '../../../constants/commondata';

function ReportPassengerWallet({ selectedFleet, user }) {
  let transactionTypes = [
    { value: 'all', label: 'report.query.transactionTypeItem.all' },
    { value: 'TnG', label: 'report.query.transactionTypeItem.TnG' },
    /* { value: 'gateway', label: 'report.query.transactionTypeItem.gateway' }, */
    { value: 'credit', label: 'report.query.transactionTypeItem.credit' },
    { value: 'ride', label: 'report.query.transactionTypeItem.ride' },
    {
      value: 'tipAfterCompleted',
      label: 'report.query.transactionTypeItem.tipAfterCompleted',
    },
    {
      value: 'debtWriteOff',
      label: 'report.query.transactionTypeItem.debtWriteOff',
    },
    { value: 'refund', label: 'report.query.transactionTypeItem.refund' },
    {
      value: 'cashExcess',
      label: 'report.query.transactionTypeItem.excessOffsetAmount',
    },
    { value: 'itemValue', label: 'report.query.transactionTypeItem.itemValue' },
    {
      value: 'editBalance',
      label: 'report.query.transactionTypeItem.editBalance',
    },
    {
      value: 'redeemVoucher',
      label: 'report.query.transactionTypeItem.redeemVoucher',
    },
    {
      value: 'topUpReferrer',
      label: 'report.query.transactionTypeItem.topUpReferrer',
    },
    {
      value: 'topUpMOLPay',
      label: 'report.query.transactionTypeItem.topUpMOLPay',
    },
  ];

  if(selectedFleet.googlePayConfig?.isActive) {
    transactionTypes.push({
      value: 'googlePay',
      label: 'report.query.transactionTypeItem.googlePay',
    })
  }
  if(selectedFleet.applePayConfig?.isActive) {
    transactionTypes.push({
      value: 'applePay',
      label: 'report.query.transactionTypeItem.applePay',
    })
  }

  const hideTypes = [];
  const paxWalletConfig = _.get(selectedFleet, 'paxWalletConfig', []);
  var gatewayMOLPay = paxWalletConfig.some(
    (walletGateway) => walletGateway.gateway == 'MOLPay'
  );
  if (!gatewayMOLPay) {
    hideTypes.push('topUpMOLPay');
  }
  if (paxWalletConfig && paxWalletConfig.length > 0) {
    paxWalletConfig.map((wallet) => {
      if (
        (wallet.paxApp && wallet.gateway && !['MOLPay', 'Xendit'].includes(wallet.gateway)) ||
        wallet.gateway == 'Kushok'
      ) {
        transactionTypes.push({
          value: wallet.gateway,
          label: `Top up by ${I18n.t(
            `report.query.paymentMethodItem.${wallet.gateway}`
          )}`,
        });
      }
    });
  }
  const partialPayment = _.get(selectedFleet, 'partialPayment', {});
  if (
    partialPayment.returnChangeInCash ||
    partialPayment.returnChangeToWallet
  ) {
    transactionTypes.push({
      value: 'returnCashByWallet',
      label: 'Return cash received',
    });
  }
  if (_.get(selectedFleet, 'xenditWalletConfig.isActive', false)) {
    transactionTypes = [
      ...transactionTypes,
      ...(getChannelPaymentXendit({fleetData: selectedFleet, listPaymentMethods: paymentMethod}) || []).map(pm => {
        return {
          value: pm.value,
          label: `Top up by ${I18n.t(pm.label)}`
        }
      })
    ]
  }

  var gatewayTnG = paxWalletConfig.some((w) => w.gateway == 'MOLPay');
  if (!gatewayTnG) {
    hideTypes.push('TnG');
  }

  if (!selectedFleet.creditConfig.enable) {
    hideTypes.push('credit');
  }
  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules, { name: 'Report' });
  let checkExport = _.findIndex(report.actions, {
    name: 'Passenger wallet',
    Export: true,
  });
  let checkOwner =
    !user.isAdmin && typeof user.roles.fleetId !== 'undefined'
      ? user.roles.fleetId.ownerId === user._id
        ? true
        : false
      : true;
  checkExport =
    user.isAdmin || checkOwner ? true : checkExport === -1 ? false : true;
  if (!user.isAdmin && selectedFleet.fleetId === 'applecabssouth') {
    checkExport = false;
  }
  return (
    <div className="content">
      <BaseReport
        tittle="Passenger wallet"
        fieldMappings={fieldMappings}
        apiurl={reportPassengerWalletApi}
        dateRange
        currency
        customer
        transactionType={transactionTypes.filter(
          (o) => hideTypes.indexOf(o.value) === -1
        )}
        search="report.query.search.passengerWallet"
        multiLineSummary="currencies"
        multiCurrencies
        noexport={!checkExport}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps, () => {})(ReportPassengerWallet);
