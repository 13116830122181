import React, { Component } from "react";
import {
  Modal,
  Button
} from "react-bootstrap";
import moment from "moment";
import { I18n } from "react-redux-i18n";
import Calendar from 'rc-year-calendar';
import { 
  checkOldNewRecurringDays,
  enumerateDaysBetweenDates
} from '../../utils/commonFunctions'
import { 
  finishedStatusList,
  bookingStatusMapping
} from '../../constants/commondata'
import DateTextRecurringUI from "./DateTextRecurringUI";
import './yearCalendarModal.scss'
import _ from "lodash";
import { array } from "prop-types";

const CURRENT_YEAR = new Date().getFullYear()
class YearCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isShowWarning: true ,
      dateValueSelected: [],
      oldDays: [],
      isShowConfirmApply: false,
      infoChange: {},
      isBookDetails: false,
      mindays: '',
      bookingsDisable: []
    };
  }

  componentDidMount() {
    if(this.props.recurringDaysInit) {
      this.setState({
        dateValueSelected: this.props.recurringDaysInit,
        oldDays: this.props.oldDays,
        mindays: this.getMinDay()
      })
    }
  }

  handleDayClick = (e) => {
    let dateNew = [...this.state.dateValueSelected]
    if(_.some(dateNew, (dateObj) => moment(dateObj).isSame(e.date))) {
      _.remove(dateNew, dateObj => moment(dateObj).isSame(e.date))
    } else {
      dateNew.push(e.date)
    }
    this.setState({
      dateValueSelected: [
        ...dateNew
      ] 
    })
  }

  convertToDataSoureCalendar = (data = []) => {
    const dataconvert = data.map(obj => ({
      startDate: new Date(obj),
      endDate: new Date(obj),
      color: '#04BE76'
    })
    )
    return dataconvert
  }


  getDisabledDays = () => {
    const { 
      recurringBookingList, 
      recurringDaysInit 
    } = this.props
    let bookingsDisable = []
    let bookingRemoved = []
    if(recurringBookingList && recurringBookingList.length > 0) {
      // filter những booking có status completed hoặc pickup Time nhỏ hơn ngày hiện tại hoặc đã start
      bookingsDisable = _.filter(recurringBookingList, (bk) => {
        return finishedStatusList.some((i) => i.value === bk.status) ||
          moment(bk.recurring.date) < moment(Date.now() - ( 3600 * 1000 * 24)) ||
          !bookingStatusMapping.canEdit[bk.status]
      })
      if(bookingsDisable && bookingsDisable.length > 0) {
        bookingsDisable = bookingsDisable.map(ob => {
          return moment(ob.recurring.date)._d
        })
      }
      // lấy những booking đã complete qua bảng Bookingcompleted
      const currentDayText = recurringBookingList.map(day => day.recurring.date)
      bookingRemoved = _.differenceWith(recurringDaysInit, currentDayText, (dayInit, currentDay) => {
        return dayInit === currentDay
      })
      if(bookingsDisable.length > 0 || bookingRemoved.length> 0) {
        return [...bookingsDisable, ...bookingRemoved].map(day => moment(day)._d)
      }
    }
    return []
  }

  handleOnRange = (e) => {
    const dateRangeSplit = enumerateDaysBetweenDates(e.startDate, e.endDate)
    if(dateRangeSplit && dateRangeSplit.length === 1) return
    const newDaysConcat = _.unionWith(
      dateRangeSplit, 
      this.state.dateValueSelected, 
      (a, b) => {
        return moment(a).isSame(b)
      }
    )
    if(newDaysConcat && newDaysConcat.length > 0) {
      this.setState({
        dateValueSelected: newDaysConcat
      })
    }
  }

  saveYearCalendar = () => {
    const numberBookingChange = checkOldNewRecurringDays(this.props.oldDays, this.state.dateValueSelected, true)    
    if(
      !this.props.isBookDetails ||
      (numberBookingChange.cancel === 0 && numberBookingChange.add === 0)
    ) {
      this.props.closeYearCalendarModal()
      this.props.setRecurringDays(this.state.dateValueSelected)
    } else {
      this.setState({
        isShowConfirmApply: true,
        infoChange: numberBookingChange
      })

    }
  }

  hideConfirmApply = () => {
    this.setState({
      isShowConfirmApply: false
    })
  }

  handleConfirmApplyClick = () => {
    this.setState({
      isShowConfirmApply: false
    })
    this.props.closeYearCalendarModal()
    this.props.setRecurringDays(this.state.dateValueSelected)
  }

  getMinDay = () => {
    const { isBookDetails, oldDays } = this.props
    if(isBookDetails && oldDays.length > 0) {
      let dayMoments = oldDays.map(d => moment(d))
      const mindays = moment.min(dayMoments)
      if(mindays._isValid &&
        moment(mindays) < moment()
      ) {
        return mindays._d
      }
    }
    return new Date(Date.now() - ( 3600 * 1000 * 24))
  }

  render() {
    const {
      isShow,
      closeYearCalendarModal,
      oldDays,
      isBookDetails
    } = this.props;
    const { 
      dateValueSelected, 
      isShowConfirmApply, 
      infoChange,
      mindays 
    } = this.state
    if(!isShow) return null
    return (
      <>
        <Modal
          show={isShow}
          backdrop={false}
          dialogClassName="confirm-dialog"
          bsSize="large"
          className="calendarRecurring"
          onHide={closeYearCalendarModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className="recurringHeader">Customized days</Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={closeYearCalendarModal}
              >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="cldContainer">
              <Calendar
                onDayClick={this.handleDayClick}
                year={CURRENT_YEAR}
                style={'background'} 
                minDate={mindays}
                dataSource={this.convertToDataSoureCalendar(dateValueSelected)}
                displayDisabledDataSource={true}
                disabledDays={this.getDisabledDays()}
                enableRangeSelection={true}
                onRangeSelected={this.handleOnRange}
                // customDataSourceRenderer={renderDataSourceCustome}
                // customDayRenderer={customDayRendererCustom}
              />
              <div className="rightContent">
                <div className="ctTop">
                  <ul>
                    <li>Click on the letter of the day: Select the days of the month.</li>
                  </ul>
                </div>
                <div className="ctBottom">
                  <p className="titleBt">Dates</p>
                  <div className="contentBt">
                    <DateTextRecurringUI 
                      newDays={dateValueSelected}
                      isBookDetails={isBookDetails}
                      oldDays={oldDays}
                      className="yearMd"
                    />
                    {
                      isBookDetails &&
                      <div>
                        <p><span className='exist iconList'></span> Existing bookings</p>
                        <p style={{color: '#12B76A'}}><span className='new iconList'></span> New bookings will be created</p>
                        <p style={{color: '#F97066'}}><span className='remove iconList'></span> Bookings will be cancelled </p>
                      </div>
                    }
                  </div>
                </div>
                <div className="btnGroup">
                  <Button
                    className="btn-save mr-md"
                    onClick={(e) => this.saveYearCalendar(e)}
                  >
                    {I18n.t('bookingdetail.Apply')}
                  </Button>
                  <Button className="btn-cancel" onClick={closeYearCalendarModal}>
                    {I18n.t('bookingdetail.No')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Confirm Update Recuring with booking detail */}
        <Modal
          show={isShowConfirmApply}
          backdrop
          size="sm"
          dialogClassName="confirm-dialog update-book-detail-modal"
          onHide={this.hideConfirmApply}
          className={'confirmRecurring'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Update recurring order
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.hideConfirmApply}
              >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to update this recurring sequence?
            <ul>
              <li>{`${infoChange.add} new booking(s) will be created`}</li>
              <li>{`${infoChange.cancel} existing booking(s) will be cancelled`}</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              className="btn-edit-only"
              onClick={this.handleConfirmApplyClick}
            >
              Update
            </Button>
            <Button 
              className="btn-edit-all"
              onClick={this.hideConfirmApply}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

YearCalendar.defaultProps = {
  isShow: false, // state to show/hide calendar modal
  closeYearCalendarModal: () => {}, // handle close/hide calendar modal
  setRecurringDays: () => {} // send yearCalendar valu to parent component
}

export default YearCalendar;
