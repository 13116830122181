import React, { Component } from 'react';
import {
    Button,
    Modal,
    FormGroup,
    FormControl
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { isAllDeliveryBookType } from '../../../utils/commonFunctions';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import { showNotifyCancelEmailCheckbox } from '../bookFunction/bookingInfo';

class CancelConfirmationModal extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const {
            showConfirmCancel,
            cancelBookingButtonClick,
            cancelBookingOkClick,
            msgChargeCancel,
            hasNotifyCancelEmail,
            selectedFleet,
            handleNotifyCancelEmailChange,
            bookInfo,
            msgWarningCancel
        } = this.props


        return <Modal show={showConfirmCancel} backdrop={true} dialogClassName='confirm-dialog'
            onHide={cancelBookingButtonClick}
            className={'confirm'}
        >
            <Modal.Header closeButton>
                <Modal.Title><Translate value='bookingdetail.CONFIRM_CANCEL' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {msgChargeCancel ? msgChargeCancel : I18n.t('bookingdetail.CONFIRM_CANCEL_MESSAGE')}
                </p>
                {showNotifyCancelEmailCheckbox({
                    auth: this.props.auth,
                    bookInfo,
                }) && (
                    <div style={{ display: 'block', marginTop: '1rem' }}>
                        <CcCheckbox
                            checked={hasNotifyCancelEmail}
                            onChange={handleNotifyCancelEmailChange}
                            text={I18n.t('newbooking.notify_via_email')}
                            style={{ marginBottom: '0px' }}
                            className="mr-b-0 mr-t-20"
                        />
                    </div>
                )}
                <i style={{ color: '#FAC940', display: 'block' }} >
                    {msgWarningCancel && I18n.t('bookingdetail.warnningCancelCorporate')}
                </i>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-save" onClick={cancelBookingOkClick}><Translate value='bookingdetail.Ok' /></Button>
                <Button className="btn-cancel" onClick={cancelBookingButtonClick}><Translate value='bookingdetail.No' /></Button>
            </Modal.Footer>
        </Modal>
    }
}

export default CancelConfirmationModal