import { callApi, UrlBuilder } from '../utils/apiUtils';
import {
  getOperationZonesApi,
  getDynamicSurchargeByZoneApi,
  getOpertaionDetailApi,
  createDynamicSurchargeApi,
  updateDynamicSurchargeApi,
  deleteDynamicSurchargeApi,
  activeDynamicSurchargeApi,
  deactiveDynamicSurchargeApi,
} from "../constants/ApiConfigs";

export const Types = {
  CREATE_DYNAMIC_SURCHARGE: 'CREATE_DYNAMIC_SURCHARGE',
  SUCCESS_CREATE_DYNAMIC_SURCHARGE: 'SUCCESS_CREATE_DYNAMIC_SURCHARGE',
  FAILED_CREATE_DYNAMIC_SURCHARGE: 'FAILED_CREATE_DYNAMIC_SURCHARGE',
  UPDATE_DYNAMIC_SURCHARGE: 'UPDATE_DYNAMIC_SURCHARGE',
  SUCCESS_UPDATE_DYNAMIC_SURCHARGE: 'SUCCESS_UPDATE_DYNAMIC_SURCHARGE',
  FAILED_UPDATE_DYNAMIC_SURCHARGE: 'FAILED_UPDATE_DYNAMIC_SURCHARGE',
  DELETE_DYNAMIC_SURCHARGE: 'DELETE_DYNAMIC_SURCHARGE',
  SUCCESS_DELETE_DYNAMIC_SURCHARGE: 'SUCCESS_DELETE_DYNAMIC_SURCHARGE',
  FAILED_DELETE_DYNAMIC_SURCHARGE: 'FAILED_DELETE_DYNAMIC_SURCHARGE',
  ACTIVE_DYNAMIC_SURCHARGE: 'ACTIVE_DYNAMIC_SURCHARGE',
  SUCCESS_ACTIVE_DYNAMIC_SURCHARGE: 'SUCCESS_ACTIVE_DYNAMIC_SURCHARGE',
  FAILED_ACTIVE_DYNAMIC_SURCHARGE: 'FAILED_ACTIVE_DYNAMIC_SURCHARGE',
  DEACTIVE_DYNAMIC_SURCHARGE: 'DEACTIVE_DYNAMIC_SURCHARGE',
  SUCCESS_DEACTIVE_DYNAMIC_SURCHARGE: 'SUCCESS_DEACTIVE_DYNAMIC_SURCHARGE',
  FAILED_DEACTIVE_DYNAMIC_SURCHARGE: 'FAILED_DEACTIVE_DYNAMIC_SURCHARGE',
}

export const getOperationZone = (options = {}) => {
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    getOperationZonesApi,
    config,
    null,
    null,
    null,
    true
  );
}

export const getSurcharges = (options = {}) => {
  const config = {method: "GET"  };

  return callApi(
    UrlBuilder(getDynamicSurchargeByZoneApi, options),
    config,
    null,
    null,
    null,
    true
  );
}

export const getZoneDetail = (options = {}) => {
  const config = {method: "GET"  };

  return callApi(
    UrlBuilder(getOpertaionDetailApi, options),
    config,
    null,
    null,
    null,
    true
  );
}

const requestCreateDynamicSurcharge = () => ({
  type: Types.CREATE_DYNAMIC_SURCHARGE
})

const successCreateSurcharge = () => ({
  type: Types.SUCCESS_CREATE_DYNAMIC_SURCHARGE
})

const failedCreateSurcharge = () => ({
  type: Types.FAILED_CREATE_DYNAMIC_SURCHARGE
})

export const createDynamicSurcharge = (options = {}) => {
  const config = { method: "POST", body: JSON.stringify(options) };

  return callApi(
    createDynamicSurchargeApi,
    config,
    requestCreateDynamicSurcharge(),
    successCreateSurcharge,
    failedCreateSurcharge,
    true
  );
}

const requestUpdateDynamicSurcharge = () => ({
  type: Types.UPDATE_DYNAMIC_SURCHARGE
})

const successUpdateSurcharge = () => ({
  type: Types.SUCCESS_UPDATE_DYNAMIC_SURCHARGE
})

const failedUpdateSurcharge = () => ({
  type: Types.FAILED_UPDATE_DYNAMIC_SURCHARGE
})

export const updateDynamicSurcharge = (options = {}) => {
  const config = { method: "POST", body: JSON.stringify(options) };

  return callApi(
    updateDynamicSurchargeApi,
    config,
    requestUpdateDynamicSurcharge(),
    successUpdateSurcharge,
    failedUpdateSurcharge,
    true
  );
}

const requestDeleteDynamicSurcharge = () => ({
  type: Types.DELETE_DYNAMIC_SURCHARGE
})

const successDeleteSurcharge = () => ({
  type: Types.SUCCESS_DELETE_DYNAMIC_SURCHARGE
})

const failedDeleteSurcharge = () => ({
  type: Types.FAILED_DELETE_DYNAMIC_SURCHARGE
})

export const deleteDynamicSurcharge = (options = {}) => {
  const config = { method: "POST", body: JSON.stringify(options) };

  return callApi(
    deleteDynamicSurchargeApi,
    config,
    requestDeleteDynamicSurcharge(),
    successDeleteSurcharge,
    failedDeleteSurcharge,
    true
  );
}

const requestActiveDynamicSurcharge = () => ({
  type: Types.ACTIVE_DYNAMIC_SURCHARGE
})

const successActiveSurcharge = () => ({
  type: Types.SUCCESS_ACTIVE_DYNAMIC_SURCHARGE
})

const failedActiveSurcharge = () => ({
  type: Types.FAILED_ACTIVE_DYNAMIC_SURCHARGE
})

export const activeDynamicSurcharge = (options = {}) => {
  const config = { method: "POST", body: JSON.stringify(options) };

  return callApi(
    activeDynamicSurchargeApi,
    config,
    requestActiveDynamicSurcharge(),
    successActiveSurcharge,
    failedActiveSurcharge,
    true
  );
}

const requestDeactiveDynamicSurcharge = () => ({
  type: Types.DEACTIVE_DYNAMIC_SURCHARGE
})

const successDeactiveSurcharge = () => ({
  type: Types.SUCCESS_DEACTIVE_DYNAMIC_SURCHARGE
})

const failedDeactiveSurcharge = () => ({
  type: Types.FAILED_DEACTIVE_DYNAMIC_SURCHARGE
})

export const deactiveDynamicSurcharge = (options = {}) => {
  const config = { method: "POST", body: JSON.stringify(options) };

  return callApi(
    deactiveDynamicSurchargeApi,
    config,
    requestDeactiveDynamicSurcharge(),
    successDeactiveSurcharge,
    failedDeactiveSurcharge,
    true
  );
}
