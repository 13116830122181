import React, { Fragment } from 'react'
import { Translate, I18n } from 'react-redux-i18n';
import formatCurrency from 'currency-formatter';

import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import DisplayPhoto from './DisplayPhoto'
import DisplayItemize from './DisplayItemize'
import {
  FormGroup,
  Form,
  FormControl,
  Row,
  Col
} from 'react-bootstrap';

class PackageInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listMenuData: []
    }
  }

  componentDidMount() {
    const { bookInfo, bookInfo: { deliveryInfo }, bookInfo: { currencyISO } } = this.props
    let infoPackage = []
    if (
      bookInfo.jobType === "food" ||
      bookInfo.jobType === "mart"
    ) {
      infoPackage = deliveryInfo && deliveryInfo.merchants
    }
    infoPackage.map((recipient, id) => {
      let selectedItems = recipient.menuData && recipient.menuData.selectedItems || []
      if(selectedItems.length > 0) {
        this.props.cueActions
          .getMenuItemsOutputData(
            {
              currency: currencyISO,
              items: selectedItems
            }
          )
          .then(result => {
            if (result.res && result.res.code == 200) {
              this.setState({ listMenuData: result?.res?.res?.data });
            };
          });
      }
    })
  }

  render() {
    const { bookInfo } = this.props;
    const { listMenuData } = this.state;
    let itemValue = bookInfo.request.estimate && bookInfo.request.estimate.fare && bookInfo.request.estimate.fare.itemValue ? bookInfo.request.estimate.fare.itemValue : 0;
    let totalItem = formatCurrency.format(itemValue, { code: bookInfo.currencyISO });
    return (
      <div className="bookingDelivery">
        <FormGroupTitle>
          <Translate value="bookingdetail.Order_summary" />
        </FormGroupTitle>
        {bookInfo.request && bookInfo.request.notes &&
          <FormGroup>
            <Form.Label>{I18n.t('bookingdetail.Notes')}:</Form.Label>
            <div
              className="notes-order"
            >
              {bookInfo.request.notes.split("\n").map((i, key) => {
                return <div key={key}>{i}</div>;
              })}
            </div>
          </FormGroup>
        }
        <FormGroup className="table-item-order">
          {listMenuData?.map((item, index) => {
            let price = item.price && item.price.value ? item.price.value : 0;
            return (
              <Fragment key={index}>
                <FormGroup
                  className="item-order orderItemPackage"
                >
                  <Row>
                    <Col md={1}>
                      <div className='item-order-count'>
                        <span>{item.count}x</span>
                      </div>
                    </Col>
                    <Col md={7}>
                      <div className='item-order-title'>
                        <span>{item.title}</span>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className='item-order-price'>
                        <span>{formatCurrency.format(price, { code: bookInfo.currencyISO })}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className='item-order-note'>
                      <span>
                        {item.note.split("\n").map((i, key) => {
                          return <div key={key}>{i}</div>;
                        })}
                      </span>
                    </div>
                  </Row>
                </FormGroup>
              </Fragment>
            )
          })}
        </FormGroup>

        <FormGroup
          className="order-total orderItemPackage"
        >
          <Row>
            <Col md={2}>
              <div className='order-total-title'>
                <span>{I18n.t('bookingdetail.Total')}</span>
              </div>
            </Col>
            <Col md={10}>
              <div className='order-total-price'>
                <span>{totalItem}</span>
              </div>
            </Col>
          </Row>
        </FormGroup>
      </div>
    )
  }
}

PackageInformation.defaultProps = {
  bookInfo: {}
}

export default PackageInformation