import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import IconvsTextBtn from './components/IconvsTextBtn';
import SendMessageIcon from '../../../../assets/images/icons/SendMessage.svg';
import PropTypes from 'prop-types';
import { userType } from '../../../../constants/commondata';

const MessageBtn = ({ bookInfo, auth, hasPermission, sendSMSBooking }) => {
  const checkShow = () => {
    return (
      [userType.FleetAdmin, userType.FleetUser].includes(
        auth.user?.userType
      ) &&
      hasPermission &&
      (bookInfo?.drvInfo?.userId?.length > 0 ||
        bookInfo?.psgInfo?.userId?.length > 0)
    );
  };

  const show = useMemo(() => checkShow(), []);

  if (!show) return null;
  return (
    <Button variant="link" onClick={() => sendSMSBooking()}>
      <IconvsTextBtn
        text={I18n.t('bookingdetail.Message')}
        icon={SendMessageIcon}
      />
    </Button>
  );
};

MessageBtn.defaultProps = {
  bookInfo: {},
  selectedFleet: {},
  hasPermission: false,
  auth: {},
  sendSMSBooking: () => {}
};

MessageBtn.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  sendSMSBooking: PropTypes.func.isRequired,
  hasPermission: PropTypes.bool,
};

export default MessageBtn;
