import React, { Component } from 'react';
import _ from 'lodash';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import CurrencyFormatter from 'currency-formatter';
import {
    FormGroup,
    FormControl,
    Form,
    Row,
    Col
} from "react-bootstrap";


class BookingItem extends Component {

    render() {
        const { data, order } = this.props;
        const addlsArray = (_.filter(data.request.services, function (o) {
            return o.active;
        })).map(a => a.name);

        let paymentName = '';
        let isPaymentOnline = false;
        const { flightNumber, type } = _.get(data, 'request.moreInfo.flightInfo', {})
        const { type: bookType = 0 } = data.request || {}
        switch (data.request.paymentType) {
            case 1:
                paymentName = 'paymentMethod.cash';
                isPaymentOnline = false;
                break;
            case 2:
                paymentName = 'paymentMethod.personalCard';
                isPaymentOnline = true;
                break;
            case 13:
                paymentName = 'paymentMethod.wallet';
                isPaymentOnline = true;
                break;
            case 14:
                paymentName = 'paymentMethod.tnGeWalletlet';
                isPaymentOnline = true;
                break;
            case 20:
                paymentName = 'paymentMethod.vippseWallet';
                isPaymentOnline = true;
                break;
            default:
                paymentName = 'paymentMethod.cash';
                break;
        };

        return (
            <div className="trip-details">
                <div className="trip-details-title">
                    <Row>
                        <Col xs={12}>
                            <div className="bookTitle">
                                <span className="bookTitle__text">{I18n.t("Sidebar.Booking")} {order + 1}</span>
                                <span className="bookTitle__status">{I18n.t(`statusDisplay.${data.status}`)}</span>
                                <span className="bookTitle__bookId">#{data.bookId}</span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="trip-details-rows">
                    <Row >
                        <Col xs={2} md={2} className="booking-content">
                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.customerName')}:</span>
                            </div>

                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.customerPhone')}:</span>
                            </div>
                        </Col>

                        <Col xs={10} md={2} className="booking-content">
                            <div className="booking-content__item">
                                <span>{data.psgInfo.fullName}</span>
                            </div>

                            <div className="booking-content__item">
                                <span>{data.psgInfo.phone}</span>
                            </div>
                        </Col>

                        <Col xs={2} md={1} className="booking-content">
                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.customerNumber')}:</span>
                            </div>

                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.customerLuggageNumber')}:</span>
                            </div>
                        </Col>

                        <Col xs={10} md={7} className="booking-content">
                            <div className="booking-content__item">
                                <span>{data.request.paxNumber}</span>
                            </div>
                            <div className="booking-content__item">
                                <span>{data.request.luggageNumber}</span>
                            </div>
                        </Col>

                    </Row>

                    <Row >
                        <Col xs={2} className="booking-content">
                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.paymentBooking')}:</span>
                            </div>
                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.note')}:</span>
                            </div>
                        </Col>

                        <Col xs={10} className="booking-content">
                            <div className="booking-content__item">
                                <span>{CurrencyFormatter.format(data.request.estimate.fare.etaFare, {
                                    code: data.currencyISO,
                                })}
                                </span>{' '}
                                <span className="tripDetails-tag">
                                    {data.request.estimate.fare.promoAmount > 0 ? <span className="promoApplied">Promo</span> : null}
                                    <span className={`paymentInfo__${isPaymentOnline}`}>{I18n.t(paymentName)}</span>
                                </span>
                            </div>
                            <div className="booking-content__item">
                                <span>{data.request.notes ? data.request.notes : " "}</span>
                            </div>
                        </Col>
                    </Row>
                    {(bookType == 1 || bookType == 2) && flightNumber && (
                        <Row>
                            <Col xs={2} className="booking-content">
                                <div className="booking-content__item booking-title">
                                    <span>Flight number:</span>
                                </div>
                            </Col>
                            <Col xs={10} className="booking-content">
                                <div className="booking-content__item">
                                    <span>{flightNumber}</span>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={2} className="booking-content">
                            <div className="booking-content__item booking-title">
                                <span>{I18n.t('tripDetails.additionalService')}:</span>
                            </div>
                        </Col>
                        <Col xs={10} className="booking-content">
                            <div className="booking-content__item">
                                <span>{addlsArray.toString()}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="booking-content">
                            <div className="booking-content__item">
                                <span className="tripDetails-location location__pickup"></span>
                                <span>{data.request.pickup.address}</span>
                            </div>
                            <div className="booking-content__item">
                                <span className="tripDetails-location location__dropoff"></span>
                                <span>{data.request.destination.address}</span>
                            </div>
                        </Col>

                    </Row>
                </div>

            </div>
        )
    }
}

export default BookingItem;