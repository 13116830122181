import React, { Component, Fragment } from 'react'
import { FormGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';

function DetailInformation({title, name, phone, address}) {
  return (
    <>
      <FormGroupTitle>
        <Translate value={title} />
      </FormGroupTitle>
      {name && <FormGroup>
        <FormControl
          type="text"
          value={name}
          className="form-custom"
          name="name"
          disabled={true}
        />
      </FormGroup>}
      {phone && <FormGroup>
        <FormControl
          type="text"
          value={phone}
          className="form-custom"
          name="phone"
          disabled={true}
        />
      </FormGroup>}
      {address && <FormGroup>
        <FormControl
          type="text"
          value={address}
          className="form-custom"
          name="address"
          disabled={true}
        />
      </FormGroup>}
    </>
  )
}

class AddressInformation extends Component {
  render () {
    const { deliveryInfo, request } = this.props.bookInfo

    let pickupTimeText = request.pickUpTime == 'Now'
    ? I18n.t("General.Now")
    : moment.tz(request.pickUpTime, request.pickup.timezone).format("MM/DD/YYYY hh:mm A");

    return (
      <>
        { 
          deliveryInfo.merchants && 
          deliveryInfo.merchants.length > 0 && 
          deliveryInfo.merchants.map(function(merchant, id) {
            return (
              <Fragment key={id}>
                <DetailInformation 
                  title={I18n.t('cue.About_Merchant')}
                  name={merchant.name}
                  phone={merchant.phone}
                  address={merchant.address && merchant.address.address}
                />
              </Fragment>
            )
          })
        }
        { 
          deliveryInfo.recipients && 
          deliveryInfo.recipients.length > 0 && 
          deliveryInfo.recipients.map(function(recipient, id) {
            const indexRecipient = deliveryInfo.recipients.length > 1 ? ++id : ''
            return (
              <Fragment key={id}>
                <DetailInformation 
                  title={`${I18n.t('cue.About_Recipient')} ${indexRecipient}`}
                  name={recipient.name}
                  phone={recipient.phone}
                  address={recipient.address && recipient.address.address}
                  status={recipient.status || {}}
                />
              </Fragment>
            )
          })
        }
        <FormGroupTitle>
          <Translate value={I18n.t("newbooking.Pickup_time")} />
        </FormGroupTitle>
        <FormGroup>
          <FormControl
            type="text"
            value={pickupTimeText}
            className="form-custom"
            disabled={true}
          />
        </FormGroup>
      </>
    )
  }
}

AddressInformation.defaultProps = {
  deliveryInfo: {},
}

export default AddressInformation