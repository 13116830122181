import React, { useState } from 'react';
import RadioButton from '../../../../../components/radioButton/radio';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, Col, Form, Button } from 'react-bootstrap';
import DefaultForm from '../form/defaultForm';
import CustomizedForm from '../form/customizedForm';
import HtmlForm from '../form/htmlForm';
import RenderUploadFile from '../renderUploadFile';
import ModalShowImage from '../modalShowImage';

const Layout = (props) => {
  const {
    data,
    appType,
    handleChangeForm,
    handleCheckEnablePhoneForm,
    handleChangeInputForm,
    handleImageChange,
    filesUpload,
    filesUploadResult,
    handleHtmlChange,
    hanldeSaveSocialForm,
    permissions,
  } = props;

  const [valid, setValid] = useState({})
  const [isSubmited, setIsSubmited] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [urlImage, setUrlImage] = useState({})
  const toggleModal = () => setShowModal(!showModal)
  const [validSize, setValidSize] = useState({
    logoDriverAndroid: true,
    logoDriverIOS: true
  })
  const [loading, setLoading] = useState(false)
  
  const ValidatorCallback = (id, validHandle) => {
    let defaultValid = {...valid}
    if (defaultValid && defaultValid[id] != validHandle) {
      defaultValid[id] = validHandle;
      setValid(defaultValid)
    }
  }

  const SelectForm = (value) => {
    switch (value) {
      case 'default':
        return <DefaultForm />;
      case 'customized':
        return (
          <CustomizedForm
            data={data}
            appType={appType}
            handleCheckEnablePhoneForm={handleCheckEnablePhoneForm}
            handleChangeInputForm={handleChangeInputForm}
            disabled={!permissions?.actions}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
          />
        );
      case 'html':
        return (
          <HtmlForm
            item={'htmlUrl'}
            handleHtmlChange={handleHtmlChange}
            filesHtml={data}
            appType={appType}
            disabled={!permissions?.actions}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
          />
        );
    }
  };

  const handleOnShowImage = (url, item) => {
    let value = {
      title: item === 'logoDriverAndroid' ? 'Logo - Android' : 'Logo - IOS',
      url: url
    }
    setUrlImage(value)
    toggleModal()
  }
  const handleValidImage = (key, data) => {
    setLoading(true)
    let validSizeDefault = {...validSize}
    if(data) {
      var _URL = window.URL || window.webkitURL;

      const img = new Image();
      img.onload = () => {
        if (img.width <= 300 && img.height <= 300) {
          validSizeDefault[key] = true
        } else {
          validSizeDefault[key] = false
        }
      };
    img.src = _URL.createObjectURL(data?.file);
    } else {
      validSizeDefault[key] = true
    }
    setValidSize(validSizeDefault)
    handleImageChange(key, data)
    setTimeout(() => {
      setLoading(false)
    }, 700);
  }

  return (
    <Col
      style={{
        borderRadius: '5px',
        background: '#2d3039',
        padding: '10px',
        marginTop: '15px',
        height: '100%',
      }}
      md={12}
      xs={12}
      className={'pr0'}
    >
      <Col
        md={12}
        xs={12}
        className={'pl0'}
        style={{
          marginBottom: '20px',
        }}
      >
        <FormGroup className="radio-button-group">
          <Col md={4} xs={12} className={'pl0'}>
            <Form.Label>
              <Translate value="social.about" />
            </Form.Label>
          </Col>

          <Col md={6} xs={12} className={'pl0'}>
            <RadioButton
              text={I18n.t('social.default')}
              value={'default'}
              onChange={() => {
                setIsSubmited(false)
                handleChangeForm(appType, 'default')
              }}
              checked={data?.contentFrom === 'default' && true}
              name="Select Form Social"
              disabled={!permissions?.actions}
            />
            <RadioButton
              text={I18n.t('social.customized')}
              value={'customized'}
              name="Select Form Social"
              onChange={() => {
                setIsSubmited(false)
                handleChangeForm(appType, 'customized')
              }}
              checked={data?.contentFrom === 'customized' && true}
              disabled={!permissions?.actions}
            />
            <RadioButton
              text={I18n.t('social.html')}
              value={'html'}
              name="Select Form Social"
              onChange={() => {
                setIsSubmited(false)
                handleChangeForm(appType, 'html')
              }}
              checked={data?.contentFrom === 'html' && true}
              disabled={!permissions?.actions}
            />
          </Col>
        </FormGroup>
      </Col>
      {SelectForm(data?.contentFrom)}

      {appType === 'driver' &&
        ['logoDriverAndroid', 'logoDriverIOS'].map((item, index) => (
          <Col
            md={12}
            xs={12}
            className={'pl0'}
            style={{
              marginBottom: '20px',
            }}
            key={index}
          >
            <RenderUploadFile
              item={item}
              handleImageChange={handleValidImage}
              filesUpload={filesUploadResult}
              disabled={!permissions?.actions}
              handleOnShowImage={handleOnShowImage}
              valid={valid}
              isSubmited={isSubmited}
              ValidatorCallback={ValidatorCallback}
              validSize={validSize}
            />
          </Col>
        ))}

      {permissions?.actions === true && <Button disabled={loading} className={'btn-save mr-md'} onClick={async () => {
        setIsSubmited(true)
        hanldeSaveSocialForm(appType, valid)

      }}>
        <Translate value="city.Save" />
      </Button>}
      <ModalShowImage show={showModal} title={urlImage?.title} url={urlImage?.url} handleShow={toggleModal}/>
    </Col>
  );
};

export default Layout;
