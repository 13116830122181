import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Row} from 'react-bootstrap';
import {Chart} from 'react-chartjs-2';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import AnalyticItem from '../../components/analytics/AnalyticItem';
import BookingRequest from "../../components/analytics/BookingRequest";
import Calender from "../../components/analytics/Calender";
import FinishedBooking from "../../components/analytics/FinishedBooking";
import ProfitAndFinishedBook from "../../components/analytics/ProfitAndFinishedBook";
import Rating from "../../components/analytics/Rating";
import {CHART_COLORS, MM_DD_YYYY, MM_YYYY, VIEW_MODES} from '../../constants/commondata';
import {renderHintLabel} from '../../utils/chartUtils';
import * as dashboardActions from './../../actions/dashboardActions';
import './analytics.scss';
import {
  CCLiteCommonFunc as common,
  checkEnable3rdPartyIntegration,
  checkEnableHolidayTaxis,
  checkEnableHoppa,
  checkEnableKarhoo,
} from './../../utils/commonFunctions';

Chart.defaults.global.defaultFontColor = 'black';
Chart.defaults.global.defaultFontSize = 16;

class Analytics extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: true,
      rawData: null,
      criteria: {
        viewMode: VIEW_MODES.day,
        date: new Date(),
        currency: props.auth.selectedFleet.currencies && props.auth.selectedFleet.currencies[0],
      },

      bookFromChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
            hoverBackgroundColor: '#04BE76',
            hoverBorderColor: '#04BE76',
            data: [],
          },
        ],
      },
      bookStatusChartData: null,
      ratingChartData: null,
      revenueChartData: {
        labels: [],
        datasets: [
          {
            label: I18n.t('dashboard.Profit'),
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#04BE76',
            borderColor: '#04BE76',
            borderWidth: 0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#04BE76',
            pointBackgroundColor: '#04BE76',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#04BE76',
            pointHoverBorderColor: '#04BE76',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [],
            yAxisID: 'y-axis-1',
          },
          {
            label: I18n.t('dashboard.Finished_booking'),
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#E5793B',
            borderColor: '#E5793B',
            borderWidth: 0,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#E5793B',
            pointBackgroundColor: '#E5793B',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#E5793B',
            pointHoverBorderColor: '#E5793B',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 5,
            data: [],
            yAxisID: 'y-axis-2',
          },
        ],
      },
      overall: {
        profit: {
          total: 0,
          revenue: 0,
          dailyChanged: 0,
          dailyPercentChange: 0,
        },
        booking: {
          total: 0,
          acceptancePercent: 0,
          dailyChanged: 0,
          dailyPercentChange: 0,
        },
        completed: {
          total: 0,
          satisfationPercent: 0,
          dailyChanged: 0,
          dailyPercentChange: 0,
        },
        customer: {
          total: 0,
          newCustomers: 0,
          dailyChanged: 0,
          dailyPercentChange: 0,
        },
      },
    };
    this.createDefaultTimeLine = this.createDefaultTimeLine.bind(this);
  }

  componentDidMount() {
    this.updateDashboardData();
  }

  createDefaultTimeLine() {
    let timezone = this.props.auth.selectedFleet.timezone;
    let viewMode = this.state.criteria.viewMode;
    let date = this.state.criteria.date;
    switch (viewMode) {
      case VIEW_MODES.day:
        return common.getHourTimeLine(date, timezone);
      case VIEW_MODES.week:
        return common.getDateOfWeekTimeLine(date, timezone);
      case VIEW_MODES.month:
        return common.getDayOfMonthTimeLine(date, timezone);
    }
  }

  updateDashboardData = () => {
    let timezone = this.props.auth.selectedFleet.timezone;
    let ob = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      criteria: {
        date: moment(this.state.criteria.date).tz(timezone).format('YYYY-MM-DD HH:mm'),
        currencyISO: this.state.criteria.currency.iso,
        viewMode: this.state.criteria.viewMode,
        timezone,
      },
    };

    let timeline = this.createDefaultTimeLine();
    if (!this.state.isLoading) {
      this.setState({isLoading: true});
    }
    this.props.dashboardActions.getBookingStatistic(ob).then((data) => {
      //update calendar style when hover date in view mode weekly
      if (this.state.criteria.viewMode === VIEW_MODES.week) {
        this.drawWeekActive();
      } else {
        this.removeWeekCss();
      }

      if (data && data.ok && data.res) {
        this.props.dashboardActions
          .getOtherInfo({fleetId: this.props.auth.selectedFleet.fleetId})
          .then((othersData) => {
            this.state.isLoading = false;
            //build revenue chart
            this.state.rawData = data.res;
            this.state.revenueChartData.labels = [];
            this.state.revenueChartData.datasets[0].data = [];
            this.state.revenueChartData.datasets[1].data = [];
            if (data.res.profitAndBooking) {
              timeline.map((item) => {
                let label = item;
                if (label === '24') label = '23:59';
                if (label.length === 2) label = label + ':00';
                this.state.revenueChartData.labels.push(label);
                let findValue = data.res.profitAndBooking.find((obj) => obj.time === item);

                this.state.revenueChartData.datasets[0].data.push(
                  findValue ? findValue.profit.toFixed(2) : 0,
                );
                this.state.revenueChartData.datasets[1].data.push(
                  findValue ? findValue.booking : 0,
                );
              });
            }

            //Requested from chart data
            this.state.bookFromChartData.labels = [];
            this.state.bookFromChartData.datasets[0].data = [];
            this.state.bookFromChartData.datasets[0].backgroundColor = [];
            this.state.bookFromChartData.datasets[0].borderColor = [];
            this.state.bookFromChartData.datasets[0].hoverBackgroundColor = [];

            if (
              !checkEnable3rdPartyIntegration(this.props.auth.selectedFleet) &&
              !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested['booking.com'];
            }

            if (
              !checkEnableHolidayTaxis(this.props.auth.selectedFleet) &&
              !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested['HolidayTaxis'];
            }

            if (
              !checkEnableKarhoo(this.props.auth.selectedFleet) &&
              !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested['Karhoo'];
            }

            if (
              !checkEnableHoppa(this.props.auth.selectedFleet) &&
              !_.isEmpty(data.res.requested)
            ) {
              delete data.res.requested['Hoppa'];
            }

            Object.keys(data.res.requested).map((key) => {
              if (key === 'booking.com') {
                this.state.bookFromChartData.labels.push(I18n.t('dashboard.' + 'booking_com'));
              } else {
                this.state.bookFromChartData.labels.push(I18n.t('dashboard.' + key));
              }
              this.state.bookFromChartData.datasets[0].data.push(data.res.requested[key]);
              this.state.bookFromChartData.datasets[0].backgroundColor.push(
                CHART_COLORS.requestChartColors[key],
              );
              this.state.bookFromChartData.datasets[0].borderColor.push(
                CHART_COLORS.requestChartColors[key],
              );
            });

            //book status chart
            this.state.bookStatusChartData = {
              labels: [],
              datasets: [
                {
                  data: [],
                  backgroundColor: [],
                  hoverBackgroundColor: [],
                },
              ],
            };
            let finishedRes = _.get(data.res, 'finished', {});
            if (!_.get(this.props.auth, 'selectedFleet.delivery.enable', false)) {
              delete finishedRes['failed'];
              delete finishedRes['partialCompleted'];
            }
            Object.keys(finishedRes).map((key) => {
              this.state.bookStatusChartData.labels.push(key);
              this.state.bookStatusChartData.datasets[0].data.push(data.res.finished[key]);
              this.state.bookStatusChartData.datasets[0].backgroundColor.push(
                CHART_COLORS.finished[key],
              );
              this.state.bookStatusChartData.datasets[0].hoverBackgroundColor.push(
                CHART_COLORS.finished[key],
              );
            });
            // rating status chart
            this.state.ratingChartData = {
              labels: [],
              datasets: [
                {
                  data: [],
                  backgroundColor: [],
                  hoverBackgroundColor: [],
                },
              ],
            };
            Object.keys(data.res.rating.stars).map((key) => {
              if (key !== 'total') {
                this.state.ratingChartData.labels.push(key);
                this.state.ratingChartData.datasets[0].data.push(data.res.rating.stars[key]);
                this.state.ratingChartData.datasets[0].backgroundColor.push(
                  CHART_COLORS.rating[key],
                );
                this.state.ratingChartData.datasets[0].hoverBackgroundColor.push(
                  CHART_COLORS.rating[key],
                );
              }
            });

            //overall
            let profitChange = this.percentCalculate(
              data.res.total.profit,
              data.res.lastTime.profit,
            );
            // make sure profit change is negative if today smaller than last time
            if (data.res.total.profit < data.res.lastTime.profit) {
              if (profitChange > 0) {
                profitChange = -profitChange;
              }
            }
            // make sure profit change is greater than 0 if today greater than last time
            if (data.res.total.profit > data.res.lastTime.profit) {
              if (profitChange < 0) {
                profitChange = -profitChange;
              }
            }
            this.state.overall = {
              profit: {
                total: data.res.total.profit,
                revenue: data.res.total.revenue,
                dailyChanged: data.res.total.profit - data.res.lastTime.profit,
                dailyPercentChange: profitChange,
              },
              booking: {
                total: data.res.total.requested,
                acceptancePercent: data.res.total.requested
                  ? ((data.res.total.accepted * 100) / data.res.total.requested).toFixed(2)
                  : 0,
                dailyChanged: data.res.total.accepted - data.res.lastTime.accepted,
                dailyPercentChange: this.percentCalculate(
                  data.res.total.requested,
                  data.res.lastTime.requested,
                ),
              },
              completed: {
                total: data.res.total.completed,
                satisfationPercent: data.res.rating.stars.total
                  ? (
                    ((data.res.rating.stars.rate4 + data.res.rating.stars.rate5) * 100) /
                    data.res.rating.stars.total
                  ).toFixed(2)
                  : 0,
                dailyChanged: data.res.total.completed - data.res.lastTime.completed,
                dailyPercentChange: this.percentCalculate(
                  data.res.total.completed,
                  data.res.lastTime.completed,
                ),
              },
              customer: {
                newCustomers: data.res.customer.totalRegisters,
                dailyChanged: data.res.customer.totalRegisters - data.res.lastTime.totalRegisters,
                dailyPercentChange: this.percentCalculate(
                  data.res.customer.totalRegisters,
                  data.res.lastTime.totalRegisters,
                ),
                paxApp: 0,
                web: 0,
                cc: 0,
                other: 0,
                total: othersData ? othersData.res : 0,
              },
            };

            this.setState(this.state);
          });
      } else {
        this.setState({isLoading: false});
      }
    });
  };

  viewModeChangeHandle = (key) => {
    this.state.criteria.viewMode = key;
    this.setState({criteria: this.state.criteria}, this.updateDashboardData);
  };

  percentCalculate(current, last) {
    if (last === 0) return current === 0 ? 0 : 100;
    return (((current - last) * 100) / last).toFixed(2);
  }

  currencyChangeHandle = (cr) => {
    this.state.criteria.currency = cr;
    this.setState({criteria: this.state.criteria}, this.updateDashboardData);
  };

  dateChangeHandle = (e) => {
    let viewMode = this.state.criteria.viewMode;
    let date = e;
    if (viewMode === VIEW_MODES.month && _.isObject(date)) {
      date = date.add('days', 1);
    }
    this.state.criteria.date = date;
    this.setState(
      {
        criteria: {
          ...this.state.criteria,
        },
      },
      this.updateDashboardData,
    );
  };

  removeWeekCss() {
    $('tr:has(td.rdtDay)').css({'background-color': ''});
    $('tr:has(td.rdtDay.rdtActive)').css({
      'background-color': '',
    });
    $('tr:has(td.rdtDay.rdtToday)').css({
      'background-color': '',
    });
  }

  drawWeekActive = () => {
    $('tr:has(td.rdtDay)').css({'background-color': '#fff0'});
    if ($('tr:has(td.rdtDay.rdtActive)').length) {
      $('tr:has(td.rdtDay.rdtToday)').css({
        'background-color': '',
      });
      $('tr:has(td.rdtDay.rdtActive)').css({
        'background-color': '#428bca',
      });
    } else {
      $('tr:has(td.rdtDay.rdtToday)').css({
        'background-color': '#428bca',
      });
    }
  };

  getDateValue = (localeCode) => {
    let timezone = this.props.auth.selectedFleet.timezone;
    let date = moment(this.state.criteria.date).tz(timezone);
    const {language: {locale: language = 'en-US'} = {}} = this.props;
    switch (this.state.criteria.viewMode) {
      case VIEW_MODES.day:
        return moment(date).locale(language).format(MM_DD_YYYY);
      case VIEW_MODES.week:
        let startDate = moment(date)
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .locale(language);
        let endDate = moment(date).locale(localeCode).endOf('week').endOf('day').locale(language);
        return `${startDate.format(MM_DD_YYYY)} - ${endDate.format(MM_DD_YYYY)}`;
      case VIEW_MODES.month:
        return moment(date).locale(language).format(MM_YYYY);
    }
    return moment(date).locale(language).format(MM_YYYY);
  };

  render() {
    const {isLoading} = this.state;
    const {language: {locale: language = 'en-US'} = {}} = this.props;

    return (
      <div className='fill dashboard-content'>
        {/*Nav bar*/}
        <div
          className={
            this.state.criteria.viewMode === VIEW_MODES.week
              ? 'db-header pd-20 calendar nav-dashboard'
              : 'db-header pd-20 nav-dashboard'
          }
        >
          <ButtonGroup>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(VIEW_MODES.day);
              }}
              className={this.state.criteria.viewMode == VIEW_MODES.day ? 'active' : ''}
              disabled={isLoading}
            >
              <Translate value='dashboard.Daily'/>
            </Button>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(VIEW_MODES.week);
              }}
              className={this.state.criteria.viewMode == VIEW_MODES.week ? 'active' : ''}
              disabled={isLoading}
            >
              <Translate value='dashboard.Weekly'/>
            </Button>
            <Button
              onClick={() => {
                this.viewModeChangeHandle(VIEW_MODES.month);
              }}
              className={this.state.criteria.viewMode == VIEW_MODES.month ? 'active' : ''}
              disabled={isLoading}
            >
              <Translate value='dashboard.Monthly'/>
            </Button>
          </ButtonGroup>
        </div>

        {/*Calendar*/}
        <Calender auth={this.props.auth} language={language} criteria={this.state.criteria} isLoading={isLoading}
                  dateChangeHandle={this.dateChangeHandle} getDateValue={this.getDateValue}/>

        <div className='db-body db-main-contain pd-20'>
          <Row className='db-body-overal'>
            {/*Analytic item: Profit, Booking Request, Completed bookings, New Customers*/}
            <AnalyticItem type='profit' overall={this.state.overall} criteria={this.state.criteria}/>
            <AnalyticItem type='bookingRequests' overall={this.state.overall} criteria={this.state.criteria}/>
            <AnalyticItem type='completedBookings' overall={this.state.overall} criteria={this.state.criteria}/>
            <AnalyticItem type='newCustomers' overall={this.state.overall} criteria={this.state.criteria}/>

            <ProfitAndFinishedBook revenueChartData={this.state.revenueChartData}/> {/*Profit & Finished Booking*/}
            <BookingRequest bookFromChartData={this.state.bookFromChartData}/> {/*Booking Requests*/}

            {/*Finished booking*/}
            <Col sm={12} md={6} className='mr-t-20'>
              <div className='db-body chart-container'>
                <div className='db-chart-title'>
                  <span className='title'>
                    {renderHintLabel(
                      I18n.t('dashboard.Finished_booking'),
                      I18n.t('dashboard.FinishedBookingChartsHint'),
                    )}
                  </span>
                </div>
                <Row>
                  <FinishedBooking auth={this.props.auth} bookStatusChartData={this.state.bookStatusChartData}/>
                  <Rating auth={this.props.auth} ratingChartData={this.state.ratingChartData}/>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {auth, i18n} = state;
  return {
    auth: auth,
    language: i18n,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
