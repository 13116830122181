export const bannerColumns = [
  {
    key: 'Name',
    label: 'appBanner.Name',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
    fixed: true
  },
  {
    key: 'viewType',
    label: 'appBanner.viewType'
  },
  {
    key: 'activate',
    label: 'appBanner.status'
  },
  {
    key: 'actions',
    label: 'appBanner.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions'
  },
  // {
  //   key: 'priority',
  //   label: 'appBanner.Order',
  //   width: 10,
  //   align: 'right',
  //   cellClass: 'table-actions'
  // }
];
