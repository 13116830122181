import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../../settings.scss";
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    Button,
    Modal
} from "react-bootstrap";
import FormGroupTitle from "../../../../components/formGroupTitile/FormGroupTitle";
import * as deliveryRateActions from "../../../../actions/deliveryRateActions";
import * as sharingRateActions from "../../../../actions/sharingRateActions";
import * as loadingBarActions from "../../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase, WarningFeeInput } from "../../../../components/validator";
import {
    CCLiteCommonFunc,
    Validation,
    textboxNumberHelper
} from "../../../../utils/commonFunctions";
import {
    DefaultCurenciesNumberInputField,
} from "../../../../constants/commondata";
import CcCheckbox from "../../../../components/ccCheckbox/CcCheckbox";
import QuestionCircleTooltip from "../../../../components/questionCircleTooltip/QuestionCircleTooltip";
import _ from "lodash";
import InputViaCurrency from './Components/InputViaCurrency';
import FeePerPax from './Components/FeePerPax';


var currencyFormatter = require("currency-formatter");

class AddEditDelivery extends PureComponent {
    state = {
        valid: {},
        editable: this.props.editable,
        isSubmmitted: false,
        dialogChanged: false,
        rateInfo: {
            fleetId: this.props.auth.selectedFleet.fleetId,
            name: "",
            feesByCurrencies: this.props.auth.selectedFleet.currencies.map(data => {
                return {
                    feePerMinute: 0,
                    startingFee: 0,
                    minimumFee: 0,
                    currencyISO: data.iso,
                    feePerPax: [{
                        numOfPax: 1,
                        value: 0
                    }]
                };
            }),
            isActive: true,
        }
    };

    componentDidMount() {
        const { rateInfo } = this.props;
        if (rateInfo && rateInfo._id) {
            this.props.sharingRateActions
                .getDetailSharingRate({
                    _id: rateInfo._id,
                    fleetId: this.props.auth.selectedFleet.fleetId
                })
                .then(res => {
                    if (res.ok) {
                        this.setState({ rateInfo: res.res });
                    } else {
                        if (res.error) {
                            this.context.notification(
                                "error",
                                I18n.t("errors." + res.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                "error",
                                I18n.t("sharingSetting.Loading_streetSharing_rate_fail")
                            );
                        }
                    }
                });
        }
    };

    handleEdit = () => {
        this.setState({ editable: true })
    }

    handleInputChange = (key, e) => {
        const { rateInfo } = this.state;
        rateInfo[key] = e.target.value;
        this.setState({ rateInfo: { ...rateInfo }, dialogChanged: true });
    };


    handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
        const { rateInfo } = this.state;
        let valueInput = null;
        if(group === 'feePerPax') {
            valueInput = e
        } else {
            valueInput = e.target.value;
            valueInput = valueInput.replace(/[^0-9. ]/g, "");
            valueInput = valueInput.replace(/\s/g, "");
        }
        let cur = {
            currencyISO: currency,
            value: valueInput
        };
        let isAdd = true;
        rateInfo.feesByCurrencies =
            rateInfo.feesByCurrencies ||
            this.props.auth.selectedFleet.currencies.map(data => {
                return {
                    value: DefaultCurenciesNumberInputField,
                    currencyISO: data.iso
                };
            });
        rateInfo.feesByCurrencies = rateInfo.feesByCurrencies.map(
            c => {
                if (c.currencyISO == currency) {
                    isAdd = false;
                    c[group] = cur.value;
                    return c;
                }
                return c;
            }
        );

        if (isAdd) {
            let ob = {
                currencyISO: currency,
                startingFee: DefaultCurenciesNumberInputField,
                feePerMinute: DefaultCurenciesNumberInputField,
                minimumFee: DefaultCurenciesNumberInputField,
                feePerPax: []
            };
            ob[group] = valueInput;
            rateInfo.feesByCurrencies.push(ob);
        }
        this.setState({ rateInfo: { ...rateInfo }, dialogChanged: true });
    };

    validatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        };
        // console.log("this.state.valid ", this.state.valid);
    };

    pushValid = (data) => {
        const { valid } = this.state;
        const newValid = { ...valid, ...data };
        this.setState({ valid: newValid });
    };

    saveDialogForm = () => {
        const { rateInfo } = this.state;
        const body = _.cloneDeep(rateInfo);
        body.fleetId = this.props.auth.selectedFleet.fleetId;
        this.setState({ isSubmmitted: true });
        if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
            this.props.loadingBarActions.showLoadingSpiner();
            if (body._id) {
                this.props.sharingRateActions
                    .updateSharingRate(body)
                    .then(data => {
                        this.props.loadingBarActions.hideLoadingSpiner();
                        if (!data.ok) {
                            if (data.error) {
                                this.context.notification(
                                    "error",
                                    I18n.t("errors." + data.error.errorCode)
                                );
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("regularSetting.Update_streetSharing_rate_fail")
                                );
                            }
                        } else {
                            this.context.notification(
                                "success",
                                I18n.t("sharingSetting.Update_streetSharing_rate_success")
                            );
                            this.props.onSuccess();
                        }
                    });
            } else {
                this.props.sharingRateActions
                    .createSharingRate(body)
                    .then(data => {
                        this.props.loadingBarActions.hideLoadingSpiner();
                        if (data.ok && data.res) {
                            this.context.notification(
                                "success",
                                I18n.t("sharingSetting.Created_streetSharing_rate_success")
                            );
                            this.setState({ rateInfo: data.res, isSubmmitted: false, editable: false });
                            this.props.onSuccess();
                        } else {
                            if (data.error) {
                                this.context.notification(
                                    "error",
                                    I18n.t("errors." + data.error.errorCode)
                                );
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("sharingSetting.Created_streetSharing_rate_fail")
                                );
                            }
                        }
                    });
            }
        }
    };

    closeDialogForm = () => {
        this.setState({
            rateInfo: {}
        })
        this.props.closeDialogForm()
    }

    render() {
        const { rateInfo, valid, isSubmmitted, editable } = this.state;
        const hasPermission =
            !this.props.permissions || this.props.permissions.actions;

        return (
            <Modal
                show={true}
                backdrop={true}
                onHide={this.closeDialogForm}
                dialogClassName="deliveryRateModal"
            >
                <Modal.Header className={"pd-r-l-60"}>
                    <Modal.Title>
                        {rateInfo._id ? (
                            editable ? (
                                <Translate value="sharingSetting.EDIT_RATE" />
                            ) : (
                                    <Translate value="sharingSetting.VIEW_RATE" />
                                )
                        ) : (
                                <Translate value="sharingSetting.ADD_RATE" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body className={"pd-r-l-60"}>
                    <Row>
                        <Col
                            xs={6}
                        >
                            <FormGroup
                                className={
                                    isSubmmitted
                                        ? valid.name === false
                                            ? "error"
                                            : null
                                        : null
                                }
                            >
                                <Form.Label>
                                    <Translate value="sharingSetting.Name" />{" "}
                                    <span className="require">*</span>
                                </Form.Label>
                                <Col xs={12} md={12}>
                                    <FormControl
                                        type="text"
                                        className={"form-custom"}
                                        onChange={e => {
                                            this.handleInputChange("name", e);
                                        }}
                                        value={
                                            rateInfo ? rateInfo.name : ""
                                        }
                                        placeholder={I18n.t("sharingSetting.Name")}
                                        disabled={!editable}
                                    />
                                </Col>
                                <Validator
                                    id="name"
                                    callback={this.validatorCallback}
                                    disabled={!editable}
                                >
                                    <ValidCase
                                        valid={rateInfo.name.trim().length > 0}
                                        message={I18n.t(
                                            "messages.commonMessages.Required_field"
                                        )}
                                        hide={!isSubmmitted}
                                    />
                                </Validator>
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="sharingSetting.startingFee" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='startingFee'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='startingFee'
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="sharingSetting.minimumFee" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='minimumFee'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='minimumFee'
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_per_minute" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feePerMinute'
                                        unitDistance={<Translate value="regularSetting.minute" />}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}

                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feePerMinute'
                                            unitDistance={I18n.t("regularSetting.minute")}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>

                            {rateInfo._id ? null : (
                                <div>
                                    <FormGroup>
                                        <Form.Label>
                                            <Translate value="regularSetting.Assign_car_type" />
                                        </Form.Label>
                                        <Col xs={12} md={12}>
                                            <select
                                                className="form-control form-custom"
                                                onChange={e => {
                                                    this.handleInputChange("vehicleTypeId", e);
                                                }}
                                            >
                                                <option value="">
                                                    {I18n.t("regularSetting.Select_car_type")}
                                                </option>
                                                {this.props.commonData.carTypeStreetSharing.map(item => {
                                                    return (
                                                        <option value={item._id} key={item._id}>
                                                            {item.vehicleType}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </div>
                            )}

                            <Row className="ml0">
                                {rateInfo._id ? (
                                    <Form.Label className="mr-r-20">
                                        (<span className="require">*</span>): <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
                                    </Form.Label>
                                ) : null}
                            </Row>
                            <Row className={"ml0"}>
                                <Form.Label>
                                    (<span className="require">*</span>): <Translate value="regularSetting.Required_fields" />
                                </Form.Label>
                            </Row>

                        </Col>
                        <Col xs={6} >
                            <FeePerPax 
                                feesByCurrencies={rateInfo && rateInfo.feesByCurrencies || []}
                                selectedFleet={this.props.auth.selectedFleet}
                                editable={editable}
                                isSubmmitted={isSubmmitted}
                                fieldType='feePerPax'
                                handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer className={"pd-r-l-60"}>
                    {hasPermission ? (
                        editable ? (
                            <Button
                                className={"btn-save mr-md"}
                                onClick={this.saveDialogForm}
                                disabled={!this.state.dialogChanged}
                            >
                                <Translate value="regularSetting.Save" />
                            </Button>
                        ) : (
                                <Button
                                    className={"btn-save mr-md"}
                                    onClick={this.handleEdit}
                                >
                                    <Translate value="regularSetting.Edit" />
                                </Button>
                            )
                    ) : (
                            ""
                        )}
                    <Button className={"btn-cancel"} onClick={this.props.closeDialogForm}>
                        <Translate value="regularSetting.Cancel" />
                    </Button>
                </Modal.Footer>

            </Modal>
        )
    };
};


AddEditDelivery.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deliveryRateActions: bindActionCreators(deliveryRateActions, dispatch),
        sharingRateActions: bindActionCreators(sharingRateActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditDelivery);