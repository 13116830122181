import React, { Fragment } from 'react';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    InputGroup,
    Button,
    Form,
} from "react-bootstrap";
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Validation,
    textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import {
    Validator,
    ValidCase,
    WarningCase,
    WarningFeeInput,
    WarningSelect
} from '../../../../components/validator';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
class IntercityConfirmEmailFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        checkStartTrip:true,
        checkTimeRange:true
    };
  }

  handleIntercityConfirmEmailBeforeChange = (e) => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      this.props.handleUpdateFleet('intercityEmailConfirmBefore', e.target.value);
    }
  }

  handleIntercityConfirmEmailBeforeUnitChange = (e) => {
    const { fleet } = this.props;
    if (
        fleet.intercityEmailConfirmBeforeUnit !=
        e.target.value
    ) {
      const unit = e.target.value;
      let value = fleet.intercityEmailConfirmBefore
      if (unit == 'hour') {
        value = fleet.intercityEmailConfirmBefore / 60;
      } else {
        value = fleet.intercityEmailConfirmBefore * 60;
      }
      this.props.handleUpdateFleet('intercityEmailConfirmBeforeUnit', unit);
      this.props.handleUpdateFleet('intercityEmailConfirmBefore', value);
    }
  }
  handleShowRequestTabOnDriverAppChange = e => {
    this.props.handleUpdateFleet('generalSetting.intercityShowRequestTabOnDriverApp', e.target.checked);
  };
  handleShowPreferredTabOnDriverAppChange = e => {
    this.props.handleUpdateFleet('generalSetting.intercityShowPreferredTabOnDriverApp', e.target.checked);
  };
  handleTimeRangeFromHourChange= e => {
    this.props.handleUpdateFleet('generalSetting.workingTimeFromHour', e.target.value);
  }

  handleTimeRangeFromMinChange= e => {
    this.props.handleUpdateFleet('generalSetting.workingTimeFromMin', e.target.value);
  }

  handleTimeRangeToHourChange= e => {
    this.props.handleUpdateFleet('generalSetting.workingTimeToHour', e.target.value);
  }

  handleTimeRangeToMinChange= e => {
    this.props.handleUpdateFleet('generalSetting.workingTimeToMin', e.target.value);
  }
  changeIntercityTripTimeRange = e =>{
      const { fleet } = this.props;
      this.props.handleUpdateFleet('generalSetting.intercityTripTimeRange', e.target.value);
      var a = _.toNumber(e.target.value);
      var b = _.toNumber(fleet.generalSetting.driverStartTripBeforeSetTime);
      var checkTimeRange = a >= b ;
      this.setState({checkTimeRange:checkTimeRange,checkStartTrip:true});
  }

  changeDriverStartTripBeforeSetTime = e =>{
      const { fleet } = this.props;
      this.props.handleUpdateFleet('generalSetting.driverStartTripBeforeSetTime', e.target.value);
      var a = _.toNumber(e.target.value);
      var b = _.toNumber(fleet.generalSetting.intercityTripTimeRange);
      var checkStartTrip = a <= b ;
      this.setState({checkStartTrip:checkStartTrip,checkTimeRange:true});
  }
    render() {
    const {
        fleet,
        ValidatorCallback,
        editable,
        valid,
        isSubmited,
        selectedFleet,
        hasPermission,
        } = this.props;
    if (!_.get(selectedFleet, 'intercity.enable', false)) {
      return null
    }
    return (
        <div className="group-general">
          <FormGroupTitle className={'title'}>
            <Translate value="generalSetting.Intercity" />
          </FormGroupTitle>
          <FormGroup  className={!isSubmited ? null: valid.intercityEmailConfirmBefore === false? 'error': null}>
            <Form.Label>
              <Translate value="generalSetting.Confirm_driver_info_via_email_before" />
              <span className="require">*</span>
              <QuestionCircleTooltip
                  text={<Translate value="generalSetting.Confirm_driver_info_via_email_before_hint" />}
                  />
            </Form.Label>
            <FormGroup className={'qup-input-group'}  >
              <InputGroup
                  className={'single-addon-right ' + (editable ? 'disabled' : '')}
                  >
                <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={this.handleIntercityConfirmEmailBeforeChange}
                    onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(
                    e,
                    this.handleIntercityConfirmEmailBeforeChange
                  );
                }}
                    onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(
                    e,
                    this.handleIntercityConfirmEmailBeforeChange
                  );
                }}
                    value={fleet ? fleet.intercityEmailConfirmBefore : ''}
                    disabled={editable || !hasPermission}
                    />
                <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={
                  fleet
                    ? fleet.intercityEmailConfirmBeforeUnit || 0
                    : 'hour'
                }
                    onChange={this.handleIntercityConfirmEmailBeforeUnitChange}
                    disabled={editable || !hasPermission}
                    >
                  <option value={'hour'}>{I18n.t('General.hrs')}</option>
                  <option value={'minute'}>{I18n.t('General.mins')}</option>
                </FormControl>
              </InputGroup>
              </FormGroup>
              <Validator id="intercityEmailConfirmBefore" callback={ValidatorCallback}>
                  <ValidCase
                      valid={!Validation.isStringEmpty(fleet.intercityEmailConfirmBefore)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!isSubmited}
                      />
                  {fleet.intercityEmailConfirmBeforeUnit == 'hour' && (
                      <ValidCase
                          valid={Validation.isDecimal(fleet.intercityEmailConfirmBefore)}
                          message={I18n.t('messages.commonMessages.must_be_number')}
                          hide={!isSubmited}
                          />
                  )}
                  {fleet.intercityEmailConfirmBeforeUnit == 'minute' && (
                      <ValidCase
                          hide={!isSubmited}
                          valid={Validation.isInteger(fleet.intercityEmailConfirmBefore)}
                          message={I18n.t('messages.commonMessages.must_be_integer')}
                          />
                  )}

                  <ValidCase
                      valid={Validation.isGreaterThan(
                        fleet.intercityEmailConfirmBefore,
                        0
                      )}
                      message={I18n.t('messages.commonMessages.greater_than_0')}
                      hide={!isSubmited}
                      />

                  <ValidCase
                      hide={!isSubmited}
                      valid={Validation.isLessOrEqual(
                    fleet.intercityEmailConfirmBefore,
                    9999
                  )}
                      message={I18n.t('messages.commonMessages.less_or_equal').format(
                '9999'
              )}
                      />
              </Validator>
          </FormGroup>
          <FormGroup >
              <Form.Label>
                  <Translate value="generalSetting.schedule_trip" />
              </Form.Label>
              <FormGroup >
                <FormGroup>
                <Form.Label>
                  <Translate value="generalSetting.driver_starts_trip_before_set_time" />
                    <QuestionCircleTooltip
                        text={<Translate value="generalSetting.indicate_pickup_time_in_advance_to_customer" />}
                        />
                </Form.Label>
                <select
                    className="form-control form-custom"
                    value={fleet.generalSetting.driverStartTripBeforeSetTime}
                    onChange={this.changeDriverStartTripBeforeSetTime}
                    disabled={!hasPermission}
                    >
                  <option value={'15'}>15 {I18n.t('generalSetting.minutes')}</option>
                  <option value={'30'}>30 {I18n.t('generalSetting.minutes')}</option>
                  <option value={'45'}>45 {I18n.t('generalSetting.minutes')}</option>
                  <option value={'60'}>1 {I18n.t('generalSetting.hour')}</option>
                </select>
                </FormGroup>
                  <WarningSelect
                      message={I18n.t('generalSetting.intercity_schedule_trip_time_range')}
                      className="text-soft-warning"
                      disabled={this.state.checkStartTrip}
                      value={fleet.generalSetting.driverStartTripBeforeSetTime }
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                      />
              </FormGroup>

          </FormGroup>
          <FormGroup>
            <Form.Label>
                  <Translate value="generalSetting.request_trip" />
              </Form.Label>
              <FormGroup>
              <CcCheckbox
                  disabled={false}
                  checked={fleet.generalSetting.intercityShowRequestTabOnDriverApp || false}
                  onChange={this.handleShowRequestTabOnDriverAppChange}
                  text={I18n.t('generalSetting.Show_request_tab_on_driver_app')}
                  />
            </FormGroup>
              <Form.Label>
                  <Translate value="generalSetting.preferred_trip" />
              </Form.Label>
            <FormGroup>
              <CcCheckbox
                  disabled={false}
                  checked={fleet.generalSetting.intercityShowPreferredTabOnDriverApp || false}
                  onChange={this.handleShowPreferredTabOnDriverAppChange}
                  text={I18n.t('generalSetting.Show_preferred_tab_on_driver_app')}
                  />
            </FormGroup>
          </FormGroup>
          <FormGroup >
                <FormGroup>
                <Form.Label>
                    <Translate value="generalSetting.time_range" />
                    <QuestionCircleTooltip
                        text={<Translate value="generalSetting.customers_can_select_start_time_and_request_intercity_trip" />}
                        />
                </Form.Label>
                <select
                    className="form-control form-custom"
                    value={fleet.generalSetting.intercityTripTimeRange}
                    onChange={this.changeIntercityTripTimeRange}
                    disabled={!hasPermission}
                    >
                    <option value={'15'}>15 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'30'}>30 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'45'}>45 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'60'}>1 {I18n.t('generalSetting.hour')}</option>
                    <option value={'75'}>1 {I18n.t('generalSetting.hour')} 15 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'90'}>1 {I18n.t('generalSetting.hour')} 30 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'105'}>1 {I18n.t('generalSetting.hour')} 45 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'120'}>2 {I18n.t('generalSetting.hours')}</option>
                    <option value={'135'}>2 {I18n.t('generalSetting.hour')} 15 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'150'}>2 {I18n.t('generalSetting.hour')} 30 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'165'}>2 {I18n.t('generalSetting.hour')} 45 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'180'}>3 {I18n.t('generalSetting.hours')}</option>
                    <option value={'195'}>3 {I18n.t('generalSetting.hour')} 15 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'210'}>3 {I18n.t('generalSetting.hour')} 30 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'125'}>3 {I18n.t('generalSetting.hour')} 45 {I18n.t('generalSetting.minutes')}</option>
                    <option value={'240'}>4 {I18n.t('generalSetting.hours')}</option>
                </select>
                </FormGroup>
                  <WarningSelect
                      message={I18n.t('generalSetting.intercity_schedule_trip_time_range')}
                      className="text-soft-warning"
                      disabled={this.state.checkTimeRange}
                      value={fleet.generalSetting.intercityTripTimeRange }
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                      />
            </FormGroup>
            <FormGroup>
            <Form.Label>
                <Translate value="generalSetting.working_time" />
                <QuestionCircleTooltip
                    text={<Translate value="generalSetting.this_setting_limits_customers_to_create_request_trip_within_working_hours_only"/> }
                    />
            </Form.Label>
            <Row>
              <Col md={6}>
                <Form inline>
                  <FormGroup>
                    <Form.Label className={"mr-md"}>
                      <Translate value="shiftSettings.From" />{" "}
                    </Form.Label>
                    <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeFromHourChange}
                        value={
                            fleet.generalSetting.workingTimeFrom ? fleet.generalSetting.workingTimeFrom.split(":")[0] : "05"
                        }
                        >
                      <option value={"00"}>00</option>
                      <option value={"01"}>01</option>
                      <option value={"02"}>02</option>
                      <option value={"03"}>03</option>
                      <option value={"04"}>04</option>
                      <option value={"05"}>05</option>
                      <option value={"06"}>06</option>
                      <option value={"07"}>07</option>
                      <option value={"08"}>08</option>
                      <option value={"09"}>09</option>
                      <option value={"10"}>10</option>
                      <option value={"11"}>11</option>
                      <option value={"12"}>12</option>
                      <option value={"13"}>13</option>
                      <option value={"14"}>14</option>
                      <option value={"15"}>15</option>
                      <option value={"16"}>16</option>
                      <option value={"17"}>17</option>
                      <option value={"18"}>18</option>
                      <option value={"19"}>19</option>
                      <option value={"20"}>20</option>
                      <option value={"21"}>21</option>
                      <option value={"22"}>22</option>
                      <option value={"23"}>23</option>
                    </FormControl>
                    <Form.Label className={"mr-sm ml-sm"}>:</Form.Label>
                    <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeFromMinChange}
                        value={
                           fleet.generalSetting.workingTimeFrom ? fleet.generalSetting.workingTimeFrom.split(":")[1] : "00"
                        }
                        >
                      <option value={"00"}>00</option>
                      <option value={"01"}>01</option>
                      <option value={"02"}>02</option>
                      <option value={"03"}>03</option>
                      <option value={"04"}>04</option>
                      <option value={"05"}>05</option>
                      <option value={"06"}>06</option>
                      <option value={"07"}>07</option>
                      <option value={"08"}>08</option>
                      <option value={"09"}>09</option>
                      <option value={"10"}>10</option>
                      <option value={"11"}>11</option>
                      <option value={"12"}>12</option>
                      <option value={"13"}>13</option>
                      <option value={"14"}>14</option>
                      <option value={"15"}>15</option>
                      <option value={"16"}>16</option>
                      <option value={"17"}>17</option>
                      <option value={"18"}>18</option>
                      <option value={"19"}>19</option>
                      <option value={"20"}>20</option>
                      <option value={"21"}>21</option>
                      <option value={"22"}>22</option>
                      <option value={"23"}>23</option>
                      <option value={"24"}>24</option>
                      <option value={"25"}>25</option>
                      <option value={"26"}>26</option>
                      <option value={"27"}>27</option>
                      <option value={"28"}>28</option>
                      <option value={"29"}>29</option>
                      <option value={"30"}>30</option>
                      <option value={"31"}>31</option>
                      <option value={"32"}>32</option>
                      <option value={"33"}>33</option>
                      <option value={"34"}>34</option>
                      <option value={"35"}>35</option>
                      <option value={"36"}>36</option>
                      <option value={"37"}>37</option>
                      <option value={"38"}>38</option>
                      <option value={"39"}>39</option>
                      <option value={"40"}>40</option>
                      <option value={"41"}>41</option>
                      <option value={"42"}>42</option>
                      <option value={"43"}>43</option>
                      <option value={"44"}>44</option>
                      <option value={"45"}>45</option>
                      <option value={"46"}>46</option>
                      <option value={"47"}>47</option>
                      <option value={"48"}>48</option>
                      <option value={"49"}>49</option>
                      <option value={"50"}>50</option>
                      <option value={"51"}>51</option>
                      <option value={"52"}>52</option>
                      <option value={"53"}>53</option>
                      <option value={"54"}>54</option>
                      <option value={"55"}>55</option>
                      <option value={"56"}>56</option>
                      <option value={"57"}>57</option>
                      <option value={"58"}>58</option>
                      <option value={"59"}>59</option>
                    </FormControl>
                  </FormGroup>
                </Form>
              </Col>
              <Col md={6}>
                <Form inline>
                  <FormGroup>
                    <Form.Label className={"mr-md"}>
                      <Translate value="shiftSettings.To" />{" "}
                    </Form.Label>
                    <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeToHourChange}
                        value={  fleet.generalSetting.workingTimeTo ? fleet.generalSetting.workingTimeTo.split(":")[0] : "22"
                        }
                        >
                      <option value={"00"}>00</option>
                      <option value={"01"}>01</option>
                      <option value={"02"}>02</option>
                      <option value={"03"}>03</option>
                      <option value={"04"}>04</option>
                      <option value={"05"}>05</option>
                      <option value={"06"}>06</option>
                      <option value={"07"}>07</option>
                      <option value={"08"}>08</option>
                      <option value={"09"}>09</option>
                      <option value={"10"}>10</option>
                      <option value={"11"}>11</option>
                      <option value={"12"}>12</option>
                      <option value={"13"}>13</option>
                      <option value={"14"}>14</option>
                      <option value={"15"}>15</option>
                      <option value={"16"}>16</option>
                      <option value={"17"}>17</option>
                      <option value={"18"}>18</option>
                      <option value={"19"}>19</option>
                      <option value={"20"}>20</option>
                      <option value={"21"}>21</option>
                      <option value={"22"}>22</option>
                      <option value={"23"}>23</option>
                    </FormControl>
                    <Form.Label className={"mr-sm ml-sm"}>:</Form.Label>
                    <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeToMinChange}
                        value={  fleet.generalSetting.workingTimeTo ? fleet.generalSetting.workingTimeTo.split(":")[1] : "00"
                        }
                        >
                      <option value={"00"}>00</option>
                      <option value={"01"}>01</option>
                      <option value={"02"}>02</option>
                      <option value={"03"}>03</option>
                      <option value={"04"}>04</option>
                      <option value={"05"}>05</option>
                      <option value={"06"}>06</option>
                      <option value={"07"}>07</option>
                      <option value={"08"}>08</option>
                      <option value={"09"}>09</option>
                      <option value={"10"}>10</option>
                      <option value={"11"}>11</option>
                      <option value={"12"}>12</option>
                      <option value={"13"}>13</option>
                      <option value={"14"}>14</option>
                      <option value={"15"}>15</option>
                      <option value={"16"}>16</option>
                      <option value={"17"}>17</option>
                      <option value={"18"}>18</option>
                      <option value={"19"}>19</option>
                      <option value={"20"}>20</option>
                      <option value={"21"}>21</option>
                      <option value={"22"}>22</option>
                      <option value={"23"}>23</option>
                      <option value={"24"}>24</option>
                      <option value={"25"}>25</option>
                      <option value={"26"}>26</option>
                      <option value={"27"}>27</option>
                      <option value={"28"}>28</option>
                      <option value={"29"}>29</option>
                      <option value={"30"}>30</option>
                      <option value={"31"}>31</option>
                      <option value={"32"}>32</option>
                      <option value={"33"}>33</option>
                      <option value={"34"}>34</option>
                      <option value={"35"}>35</option>
                      <option value={"36"}>36</option>
                      <option value={"37"}>37</option>
                      <option value={"38"}>38</option>
                      <option value={"39"}>39</option>
                      <option value={"40"}>40</option>
                      <option value={"41"}>41</option>
                      <option value={"42"}>42</option>
                      <option value={"43"}>43</option>
                      <option value={"44"}>44</option>
                      <option value={"45"}>45</option>
                      <option value={"46"}>46</option>
                      <option value={"47"}>47</option>
                      <option value={"48"}>48</option>
                      <option value={"49"}>49</option>
                      <option value={"50"}>50</option>
                      <option value={"51"}>51</option>
                      <option value={"52"}>52</option>
                      <option value={"53"}>53</option>
                      <option value={"54"}>54</option>
                      <option value={"55"}>55</option>
                      <option value={"56"}>56</option>
                      <option value={"57"}>57</option>
                      <option value={"58"}>58</option>
                      <option value={"59"}>59</option>
                    </FormControl>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            </FormGroup>
        </div>
    );
  }
}
IntercityConfirmEmailFormGroup.contextTypes = {
    notification: PropTypes.func
};
export default IntercityConfirmEmailFormGroup;
