import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  FormGroup,
  FormControl,
  InputGroup,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import _ from 'lodash';

import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { QuestsListColumns as Columns } from './tableHeaderColumn';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

import {
  EventType,
  MetricType,
  EVENT_DEEPLINK,
} from '../../../../constants/commondata';

function QuestsModal(props) {
  const {
    data,
    currency,
    selectedFleet,
    disable,
    showDialogModal,
    closeFormModal,
    handleUpdateQuests,
    handleAlertNotification,
  } = props;
  const [quests, setQuests] = useState([]);
  const [userType, setUserType] = useState('');
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    if (data.userType) {
      setUserType(data.userType);
    }
    if (data.quests) {
      initMetricTypes(data.userType, data.quests);
    } else {
      initMetricTypes(data.userType, []);
    }
    if (data.type) {
      setEventType(data.type);
    }
  }, [data]);

  const initMetricTypes = (userType, quests) => {
    let metricTypes = [];
    if (quests.length > 0) {
      quests.forEach((e) => {
        e.selected = true;
        metricTypes.push(e);
      });
    }
    if (userType) {
      MetricType[userType].forEach((e) => {
        if (
          _.findIndex(metricTypes, (o) => o.metricType === e.metricType) === -1
        ) {
          metricTypes.push({
            metricType: e.metricType,
            points: 0,
            task: '',
            // deepLink: '',
            value: 0,
            selected: false,
          });
        }
      });
    }
    setQuests(metricTypes);
  };

  const handleItemSelectedChange = (id, e) => {
    let listItem = _.cloneDeep(quests);
    if (id != null) {
      let index = listItem.findIndex((obj) => id === obj.metricType);
      if (index >= 0) listItem[index].selected = e.target.checked;
    } else {
      let value = e.target.checked;
      listItem = listItem.map((obj) => {
        return {
          ...obj,
          selected: value,
        };
      });
    }
    setQuests(listItem);
  };

  const handleInputChange = (key, data, e) => {
    let listItem = _.cloneDeep(quests);
    let index = listItem.findIndex((obj) => data.metricType === obj.metricType);
    // if (key === "task") {
    //     if (index >= 0) listItem[index].task = e.target.value;
    // };
    // if (key === "value") {
    //     if (index >= 0) listItem[index].value = parseInt(e.target.value);
    // };
    // if (key === "points") {
    //     if (index >= 0) listItem[index].points = parseFloat(e.target.value);
    // };
    // if (key === "deepLink") {
    //     if (index >= 0) listItem[index].deepLink = e.target.value;
    // };

    switch (key) {
      case 'task':
        if (index >= 0) listItem[index].task = e.target.value;
        break;
      case 'value':
        if (index >= 0) listItem[index].value = parseInt(e.target.value);
        break;
      case 'points':
        if (index >= 0) listItem[index].points = e.target.value;
        break;
      case 'deepLink':
        if (index >= 0) listItem[index].deepLink = e.target.value;
        break;
      default:
        break;
    }
    setQuests(listItem);
  };

  const handleSelectedSave = () => {
    let listItem = _.cloneDeep(quests);
    let questsData = [];
    listItem.forEach((e) => {
      if (e.selected) {
        questsData.push({
          metricType: e.metricType,
          points: e.points,
          task: e.task,
          value: e.value,
          deepLink: e.deepLink,
        });
      }
    });
    let items = [];
    items = questsData.filter((e) => {
      console.log('EEEEE ', e);
      if (e.task === '') {
        questsData.map((c) => {
          if (c.metricType === e.metricType) {
            c.errorMsg = 'task_is_empty';
          }
        });
        return true;
      }
      if (e.task.length > 50) {
        questsData.map((c) => {
          if (c.metricType === e.metricType) {
            c.errorMsg = 'task_limit_50_characters';
          }
        });
        return true;
      }
      if (eventType === EventType.largest_will_win) {
        if (!e.points) {
          questsData.map((c) => {
            if (c.metricType === e.metricType) {
              c.errorMsg = 'please_input_points';
            }
          });
          return true;
        }
      } else {
        if (!e.value) {
          questsData.map((c) => {
            if (c.metricType === e.metricType) {
              c.errorMsg = 'please_input_value';
            }
          });
          return true;
        }
      }
      return false;
    });
    if (items.length > 0) {
      handleAlertNotification('quests', items);
    } else {
      handleUpdateQuests(questsData);
    }
  };

  const getTableColumns = () => {
    const unitDistance = selectedFleet.unitDistance;
    let tableColumns = Object.assign([], Columns);
    if (eventType === EventType.first_will_win) {
      tableColumns = tableColumns.filter((e) => e.key !== 'points');
    }

    if (eventType === EventType.largest_will_win) {
      tableColumns = tableColumns.filter((e) => e.key !== 'value');
    }

    _.forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'table-selectbox':
          col.customHeader = (columnKey) => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={
                  quests
                    ? quests.length == 0
                      ? false
                      : quests.filter((data) => {
                          return !data.selected;
                        }).length == 0
                    : false
                }
                onChange={(e) => {
                  handleItemSelectedChange(null, e);
                }}
              />
            );
          };
          col.customCell = (data) => {
            return (
              <CcCheckbox
                className="table-selectbox"
                checked={data.selected}
                onChange={(e) => {
                  handleItemSelectedChange(data.metricType, e);
                }}
              />
            );
          };
          break;
        case 'metricType':
          col.customCell = (data) => {
            return (
              <div>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={'tooltip'}>
                      {I18n.t(
                        `quest_event.${
                          userType === 'passenger'
                            ? 'passengerMetric'
                            : 'driverMetric'
                        }.${data.metricType}`
                      )}
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <span className="dropdown-title">
                    {I18n.t(
                      `quest_event.${
                        userType === 'passenger'
                          ? 'passengerMetric'
                          : 'driverMetric'
                      }.${data.metricType}`
                    )}
                  </span>
                </OverlayTrigger>
              </div>
            );
          };
          break;
        case 'task':
          col.customCell = (data) => {
            return (
              <FormControl
                type="text"
                className="form-custom"
                onChange={(e) => handleInputChange('task', data, e)}
                value={data.task ? data.task : ''}
                placeholder={I18n.t('quest_event.task')}
                // disabled={disable}
              />
            );
          };
          break;
        case 'value':
          col.customCell = (data) => {
            let unit = MetricType[userType].find(
              (e) => e.metricType === data.metricType
            ).unit;
            if (unit === 'currency') {
              unit = currency[0].symbol;
            }
            if (unit === 'meter') {
              unit = unitDistance;
            }
            return (
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (!disable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={(e) => handleInputChange('value', data, e)}
                    value={data.value ? data.value : ''}
                    placeholder={I18n.t('quest_event.value')}
                    // disabled={disable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{unit}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
            );
          };
          break;
        case 'points':
          col.customCell = (data) => {
            let unit = MetricType[userType].find(
              (e) => e.metricType === data.metricType
            ).unit;
            if (unit === 'currency') {
              unit = currency[0].symbol;
            }
            if (unit === 'meter') {
              unit = unitDistance;
            }
            return (
              <FormControl
                type="text"
                className="form-custom"
                onChange={(e) => handleInputChange('points', data, e)}
                value={data.points || ''}
                placeholder={I18n.t('quest_event.points_per_some_think', {
                  unit,
                })}
              />
            );
          };
          break;
        case 'deepLink':
          col.customCell = (data) => {
            return (
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (!disable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={(e) => handleInputChange('deepLink', data, e)}
                    value={data.deepLink ? data.deepLink : ''}
                    placeholder={I18n.t('quest_event.deepLink')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {
                        <select
                          className="input-group-addon-custom form-custom"
                          onChange={(e) => {
                            e.persist();
                            console.log(e.target.value);
                            handleInputChange('deepLink', data, e);
                          }}
                        >
                          <option key={-1} value={''}>
                            Template
                          </option>
                          {EVENT_DEEPLINK[userType].map((item) => {
                            return (
                              <option
                                value={`${
                                  selectedFleet.isGOJOMember
                                    ? 'gojosg'
                                    : selectedFleet.fleetId
                                }${item.link}`}
                                key={item.name}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColumns;
  };

  return (
    <Modal
      onHide={closeFormModal}
      show={showDialogModal}
      dialogClassName="custom-modal quests-list table-header"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value="quest_event.quests_list" />
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeFormModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="gridViewTable">
          <StickyTable
            columns={getTableColumns()}
            bodyData={quests}
            rowHeight={50}
            getTableHeight={() => 500}
            noPagination={true}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="btn-cancel" onClick={closeFormModal}>
          <Translate value="quest_event.Cancel" />
        </Button>
        <Button className="btn-save mr-md" onClick={handleSelectedSave}>
          <Translate value="quest_event.ok" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestsModal;
