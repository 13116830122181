import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../components/validator';
import { Validation } from '../../utils/commonFunctions';

import {
  Modal,
  FormGroup,
  Form,
  Button,
  FormControl,
  InputGroup,
} from 'react-bootstrap';

class Commission extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      valid: {},
      type: 'percent',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isShow) this.setState({ value: 0 });
  }

  closeDialog = () => {
    this.props.showFormCommission(false);
    this.setState({ value: 0 });
  };

  handlePercentValueChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleAmountValueChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value, value: 0 });
  };

  handleModalHide = (e) => {
    this.setState({
      value: 0,
      valid: {},
      type: 'percent',
    });
  };

  onChangeCurrency = (e) => {
    this.props.onChangeCurrencyCommission(e.target.value);
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleApplyCommission = () => {
    let state = this.state;
    let commission = Math.round(state.value * 100) / 100;

    let dataCommission = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      // commission: parseInt(state.value),
      commission: commission,
      currencyISO: this.props.currencyISO,
      commissionType: state.type,
    };
    if (state.type == 'amount' && state.valid.commissionAmount) {
      this.props.handleApplyCommission(dataCommission);
    } else if (state.type == 'percent' && state.valid.commissionPercent) {
      this.props.handleApplyCommission(dataCommission);
    }
  };

  render() {
    if (this.props.isShow) {
      return (
        <Modal
          show={true}
          backdrop="static"
          onHide={this.handleModalHide}
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="card-dialog"
          className="confirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="driver.Title_Commission" />
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeDialog}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body className="clearfix text">
            <FormGroup>
              <Form.Label>
                <Translate value="driver.Type" />{' '}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                as="select"
                value={this.state.type}
                onChange={this.handleTypeChange}
                className="form-custom"
              >
                <option key={0} value="percent">
                  {I18n.t('driver.Percentage')}
                </option>
                <option key={1} value="amount">
                  {I18n.t('driver.Amount')}
                </option>
              </FormControl>
            </FormGroup>
            {this.state.type === 'amount' && (
              <FormGroup>
                <Form.Label>
                  <Translate value="driver.Currency" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="select"
                  value={this.state.currencyISO}
                  onChange={this.onChangeCurrency}
                  className="form-custom"
                >
                  {this.props.auth.selectedFleet.currencies.map((c) => {
                    return (
                      <option value={c.iso} key={c.iso}>
                        {c.iso}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            )}
            {this.state.type === 'percent' ? (
              <FormGroup
                className={`qup-input-group ${
                  this.state.valid.commissionPercent ? null : 'error'
                }`}
              >
                <Form.Label>
                  <Translate value="promotionSettings.Value" />
                </Form.Label>
                <div className="input-with-validator">
                  <InputGroup className={'single-addon-right'}>
                    <FormControl
                      type="number"
                      step={0}
                      className="form-custom number-no-spin"
                      min={0}
                      max={100}
                      onChange={this.handlePercentValueChange}
                      value={this.state.value}
                      onWheel={(e) => e.preventDefault()}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <WarningFeeInput
                    message={I18n.t('message.warningInputChangeOver')}
                    className="text-soft-warning"
                    value={this.state.value}
                    timeDelay={500}
                    typeOfCheck="higherOfLower"
                  />
                </div>
                <Validator
                  id="commissionPercent"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    valid={!Validation.isStringEmpty(this.state.value)}
                    message={I18n.t('promotionSettings.Please_input_value')}
                  />
                  <ValidCase
                    valid={Validation.isNumber(this.state.value)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!this.state.isSubmited}
                  />
                  <ValidCase
                    valid={Validation.isInRange(this.state.value, 0, 100)}
                    message={I18n.t(
                      'messages.commonMessages.Integer_from_range'
                    ).format(0, 100)}
                  />
                </Validator>
              </FormGroup>
            ) : null}
            {this.state.type === 'amount' ? (
              <FormGroup
                className={`qup-input-group ${
                  this.state.valid.commissionAmount ? null : 'error'
                }`}
              >
                <Form.Label>
                  <Translate value="promotionSettings.Value" />{' '}
                  <span className="require">*</span>
                </Form.Label>
                <div className="input-with-validator">
                  <InputGroup className={'single-addon-left'}>
                    <FormControl
                      type="number"
                      step={0}
                      min={0}
                      className="form-custom number-no-spin"
                      onChange={this.handleAmountValueChange}
                      value={this.state.value}
                      onWheel={(e) => e.preventDefault()}
                    />
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {this.props.auth.selectedFleet.currencies &&
                        this.props.auth.selectedFleet.currencies.find(
                          (obj) => obj.iso == this.props.currencyISO
                        )
                          ? this.props.auth.selectedFleet.currencies.find(
                              (obj) => obj.iso == this.props.currencyISO
                            ).symbol
                          : ''}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  <WarningFeeInput
                    message={I18n.t('message.warningInputChangeOver')}
                    className="text-soft-warning"
                    value={this.state.value}
                    timeDelay={500}
                    typeOfCheck="higherOfLower"
                  />
                </div>
                <Validator
                  id="commissionAmount"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    valid={!Validation.isStringEmpty(this.state.value)}
                    message={I18n.t('promotionSettings.Please_input_value')}
                  />
                  <ValidCase
                    valid={Validation.isNumber(this.state.value)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                  />
                  <ValidCase
                    valid={Validation.isGreaterOrEqual(this.state.value, 0)}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(0)}
                  />
                </Validator>
              </FormGroup>
            ) : (
              ''
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-save mr-md"
              onClick={this.handleApplyCommission}
            >
              <Translate value="driver.Apply" />
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  }
}
export default Commission;
