import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import closeGps from '../../../assets/images/icons/close-gps.svg';
import styles from './modalDetailLogs.module.scss';
import { keysConvert } from '../groupItem';
const ModalDetailLogs = (paramProps) => {
  const { keyItem, data } = paramProps;
  const [showModalInfoDetail, setShowModalInfoDetail] = useState(false);
  const handleShowModalInfoDetail = () => {
    setShowModalInfoDetail(!showModalInfoDetail);
  };

  const renderValue = (item, value) => {
    const resultValueString = (itemProps, valueProps) => {
      let newVal = (valueProps || '').charAt(0).toUpperCase() + (valueProps || '').slice(1)
      if (itemProps.order && newVal) {
        return I18n.t('bookingDetailLogs.recipient') + ` ${itemProps.order}: ` + newVal
      }

      return newVal;
      
    }

    return item.datatype === 'array' ? (
      <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        {value.map((val, inx) => {
          if (value.length > 1) {
            return (
              <p style={{ marginBottom: 0 }} key={inx}>
                {I18n.t('bookingDetailLogs.location') + ` ${inx + 1}: ` + val}
              </p>
            );
          }
          return <p key={inx}>{val}</p>;
        })}
      </div>
    ) : (
      resultValueString(item, value)
    );
  };

  return (
    <>
      <div
        style={{
          padding: 20,
          color: keyItem === 'action' ? '#4a8cc3' : '#FFFFFF',
          cursor: keyItem === 'action' ? 'pointer' : '',
        }}
        onClick={() => {
          if (keyItem === 'action') {
            handleShowModalInfoDetail();
          }
        }}
      >
        {paramProps?.children}
      </div>
      <Modal
        show={showModalInfoDetail}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        bsSize={'lg'}
        onHide={handleShowModalInfoDetail}
      >
        <Modal.Body style={{ padding: 0 }}>
          <div className={styles['modalDetailLogs']}>
            <div className={styles['layoutHeader']}>
              <div className={styles['header']}>
                <Translate value="bookingDetailLogs.updatedInfo" />
                <div>
                  <img
                    onClick={handleShowModalInfoDetail}
                    className={styles['close']}
                    src={closeGps}
                    alt="closeIcon"
                  />
                </div>
              </div>
            </div>
            <div className={styles['tableContainer']}>
              <table className={styles['tableCustom']}>
                <tr>
                  <th>{I18n.t('bookingDetailLogs.updatedField')}</th>
                  <th>{I18n.t('bookingDetailLogs.valueBefore')}</th>
                  <th>{I18n.t('bookingDetailLogs.valueAfter')}</th>
                </tr>
                {data?.changes?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{I18n.t(`bookingDetailLogs.${keysConvert[item.key]}`)}</td>
                      <td>{renderValue(item, item.oldValue)}</td>
                      <td>{renderValue(item, item.newValue)}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDetailLogs;
