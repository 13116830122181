import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../../settings.scss";
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    Button,
    ButtonToolbar,
    Modal
} from "react-bootstrap";
import * as settingActions from "../../../../actions/settingActions";
import * as loadingBarActions from "../../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { CarColorColumns } from "./../tableHeaderData";
import TableActions from "../../../../components/table/tableAction/TableActions";
import StickyTable from "../../../../components/table/stickyTable/StickyTable";
import _ from "lodash";

import AddEditCarColor from './AddEditCarColor';
import ErrorPage from "../../../../containers/misc/ErrorPage";

class CarColor extends Component {
    constructor() {
        super();
        this.state = {
            dataList: null,
            detailItem: null,
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            sortType: -1,
            editable: false,
            valid: {},
            isSubmited: false,
            tableHeight: 500,
            rowHeight: 50,
            footerData: {
                limit: 20,
                total: 0,
                page: 0
            },
            modelFooterData: {
                limit: 20,
                total: 0,
                page: 0
            }
        };
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
            this
        );
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateDataList = this.updateDataList.bind(this);
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.closeDialogForm = this.closeDialogForm.bind(this);
        this.saveDialogForm = this.saveDialogForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    }
    componentDidMount() {
        this.updateDataList();
    }

    updateDataList(activePage, limit) {
        let params = {
            limit: limit || this.state.footerData.limit,
            page:
                activePage || activePage === 0
                    ? activePage
                    : this.state.footerData.page,
            query: { fleetId: this.props.auth.selectedFleet.fleetId }
        };
        this.props.settingActions.getAllCarColor(params)
            .then(data => {
                if (data.ok && data.res) {
                    let { page, total, limit } = data.res;
                    this.setState({
                        dataList: data.res,
                        footerData: { page, total, limit }
                    });
                }
            });
    }

    saveDialogForm() {
        this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
        this.props.loadingBarActions.showLoadingSpiner();
        if (this.state.dialogData._id) {
            let objectData = {
                fleetId: this.props.auth.selectedFleet.fleetId,
                name: this.state.dialogData.name,
                id: this.state.dialogData._id
            };
            this.props.settingActions.updateCarColor(objectData).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification(
                            "error",
                            I18n.t("errors." + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            "error",
                            I18n.t("carSetting.Update_car_color_fail")
                        );
                    }
                } else {
                    this.context.notification(
                        "success",
                        I18n.t("carSetting.Update_car_color_success")
                    );
                    this.closeDialogForm();
                    this.setState({
                        dataList: {
                            ...this.state.dataList,
                            list: []
                        }
                    }, () => {
                        this.updateDataList();
                    })
                }
            });
        } else {
            let objectData = {
                fleetId: this.props.auth.selectedFleet.fleetId,
                name: this.state.dialogData.name,
            };
            this.props.settingActions.createCarColor(objectData)
                .then(data => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    if (!data.ok && !data.res) {
                        if (data.error) {
                            this.context.notification(
                                "error",
                                I18n.t("errors." + data.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                "error",
                                I18n.t("carSetting.Create_car_color_fail")
                            );
                        }
                    } else {
                        this.context.notification(
                            "success",
                            I18n.t("carSetting.Create_car_color_success")
                        );
                        this.closeDialogForm();
                        this.updateDataList();
                    }
                });
        }
    }

    handleInputChange(key, e) {
        this.state.dialogData[key] = e.target.value;
        this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
    }

    handleAddButtonClick() {
        let object = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            name: ""
        };
        this.setState({
            showDialog: true,
            dialogData: object,
            editable: true
        });
    }


    closeDialogForm() {
        this.setState({
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false
        });
    }

    handleMenuClick(key, data) {
        switch (key) {
            case "View": {
                this.setState({
                    showDialog: true,
                    dialogData: data,
                    editable: false,
                    dialogChanged: false,
                });
                break;
            }
            case "Edit": {
                this.setState({
                    showDialog: true,
                    dialogData: data,
                    editable: true,
                    dialogChanged: false,
                });
                break;
            }
            case "Status": {
                if (data.isActive) {
                    this.props.settingActions
                        .deactiveRateHourly({
                            fleetId: this.props.auth.selectedFleet.fleetId,
                            _id: data._id
                        })
                        .then(data => {
                            if (data.ok) {
                                this.updateDataList();
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("carSetting.Update_status_error")
                                );
                            }
                        });
                } else {
                    this.props.settingActions
                        .activeRateHourly({
                            fleetId: this.props.auth.selectedFleet.fleetId,
                            _id: data._id
                        })
                        .then(data => {
                            if (data.ok) {
                                this.updateDataList();
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("carSetting.Update_status_error")
                                );
                            }
                        });
                }

                break;
            }
            case "changeStatus": {
                this.setState({ editable: true });
                break;
            }
            case "Delete": {
                this.setState({ showConfirm: true, dialogData: data });
                break;
            }
        }
    }

    handlePaginationSelect(eventKey) {
        this.updateDataList(eventKey);
    }

    handleNumItemsPerPageChange(e) {
        let numItemsPerPage = parseInt(e);
        this.updateDataList(0, numItemsPerPage);
    }

    confirmDeleteUser() {
        this.props.settingActions
            .deleteCarColor({
                fleetId: this.props.auth.selectedFleet.fleetId,
                id: this.state.dialogData._id
            })
            .then(data => {
                if (data.ok) {
                    this.closeDialogForm();
                    this.updateDataList();
                    this.context.notification(
                        "success",
                        I18n.t("carSetting.Delete_car_color_success")
                    );
                } else {
                    if (data.error) {
                        this.context.notification(
                            "error",
                            I18n.t("errors." + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            "error",
                            I18n.t("carSetting.Delete_car_color_fail")
                        );
                    }
                }
            });
    }

    getTableColumns = () => {
        let tableColumns = Object.assign([], CarColorColumns);
        // custom data by column
        _.forEach(tableColumns, (col, index) => {
            switch (col.key) {
                case "Name":
                    col.customCell = data => {
                        return (
                            <a
                                className="clickable"
                                onClick={() => {
                                    this.handleMenuClick("View", data);
                                }}
                            >
                                {data.name}
                            </a>
                        );
                    };
                    break;

                case "actions":
                    col.customCell = (data, rowIndex) => {
                        if (!this.props.permissions || this.props.permissions.actions) {
                            let actionItems = [
                                {
                                    label: "carSetting.Edit",
                                    eventKey: "Edit"
                                }
                            ];

                            if (!data.inUse) {
                                actionItems.push({
                                    label: "carSetting.Delete",
                                    eventKey: "Delete"
                                });
                            }

                            return (
                                <TableActions
                                    rowIndex={rowIndex}
                                    rowData={data}
                                    onSelect={eventKey => {
                                        this.handleMenuClick(eventKey, data);
                                    }}
                                    totalRow={this.state.dataList.list.length}
                                    rowHeight={this.state.rowHeight}
                                    menuItems={actionItems}
                                    tableHeight={this.state.tableHeight}
                                />
                            );
                        } else {
                            return (
                                <a
                                    onClick={e => {
                                        this.handleMenuClick("View", data);
                                    }}
                                    href="javascript:void(0)"
                                >
                                    <Translate value="carSetting.View" />
                                </a>
                            );
                        }
                    };
                    break;
                default:
                    break;
            }
        });

        return tableColumns;
    };

    getTableHeight = () => {
        let verticalPadding = 10,
            toolbarMarginBottom = 10,
            toolbarheight = this.toobarContainer
                ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
                : 0,
            parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
        let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
        this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        return this.state.tableHeight;
    };

    render() {
        const bodyData =
            this.state.dataList && this.state.dataList.list.length > 0
                ? this.state.dataList.list
                : [];
        const allowView = this.props.permissions === undefined ? false : true;

        return (
            allowView ? (
                <div className="content">
                    {!this.props.permissions || this.props.permissions.actions ? (
                        <ButtonToolbar
                            className="text-center header-button-group"
                            ref={node => (this.toobarContainer = node)}
                        >
                            <Button
                                className="btn-header text-add-header ml0"
                                onClick={this.handleAddButtonClick}
                            >
                                {" "}

                                <Translate value="carSetting.Add" />
                            </Button>
                        </ButtonToolbar>
                    ) : (
                            ""
                        )}
                    <div className="gridViewTable">
                        <StickyTable
                            columns={this.getTableColumns()}
                            bodyData={bodyData}
                            footerData={this.state.footerData}
                            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                            handlePaginationSelect={this.handlePaginationSelect}
                            rowHeight={this.state.rowHeight}
                            getTableHeight={this.getTableHeight}
                        />
                    </div>

                    {this.state.showDialog && this.state.dialogData ? (
                        <AddEditCarColor
                            dialogData={this.state.dialogData}
                            editable={this.state.editable}
                            dialogChanged={this.state.dialogChanged}
                            permissions={this.props.permissions}
                            closeDialogForm={this.closeDialogForm}
                            handleInputChange={this.handleInputChange}
                            saveDialogForm={this.saveDialogForm}
                            handleMenuClick={this.handleMenuClick}
                        />
                    ) : null}

                    {this.state.showConfirm ? (
                        <Modal show={true} backdrop={true} className={"confirm"} onHide={this.closeDialogForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Translate value="carSetting.DELETE_CAR_COLOR" />
                                </Modal.Title>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={this.closeDialogForm}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <Translate value="carSetting.DELETE_CONFIRM_CAR_COLOR" />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    className={"btn-save mr-md"}
                                    onClick={this.confirmDeleteUser}
                                >
                                    <Translate value="carSetting.Yes" />
                                </Button>
                                <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                                    <Translate value="carSetting.No" />
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    ) : null}
                </div>
            ) : <ErrorPage />
        );
    }
}

CarColor.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        users: state.users,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CarColor);
