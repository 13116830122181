import {fetchCorporateApi,getCorporateByIdApi, fetchAirlineApi} from '../constants/ApiConfigs'
import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

export const CORPORATE_COMPANY_REQUEST = "CORPORATE_COMPANY_REQUEST";
export const CORPORATE_COMPANY_SUCCESS = "CORPORATE_COMPANY_SUCCESS";
export const CORPORATE_COMPANY_FAILURE = "CORPORATE_COMPANY_FAILURE";

export const CORPORATE_BY_ID_REQUEST = "CORPORATE_BY_ID_REQUEST";
export const CORPORATE_BY_ID_SUCCESS = "CORPORATE_BY_ID_SUCCESS";
export const CORPORATE_BY_ID_FAILURE = "CORPORATE_BY_ID_FAILURE";


function corporateCompanyRequest(){
    return{
        type:CORPORATE_COMPANY_REQUEST
    }
}

function corporateCompanySuccess(data){
    return{
        type:CORPORATE_COMPANY_SUCCESS,
        data: data.res
    }
}

function AirlineCompanySuccess(data){
  return{
      type:CORPORATE_COMPANY_SUCCESS,
      data: data.res.list
  }
}

function corporateCompanyFailure(error){
    return{
        type:CORPORATE_COMPANY_FAILURE,
        error
    }
}
export  function corporateCompany(fleetId, isAirline = false){
    const config = {
      method: "get",
      }
  let url = fetchCorporateApi + fleetId 
  //   fetchAirlineApi + fleetId + `&isAirline=true`
  // ) : (
  //   fetchCorporateApi + fleetId + `&isAirline=false`
  // )
  return callApi(
    url,
    config,
    corporateCompanyRequest(),
    isAirline ? AirlineCompanySuccess : corporateCompanySuccess,
    corporateCompanyFailure
  );
}

export  function getCorporateCompany(fleetId, isAirline = false){
    const config = {
      method: "get",
      }
  let url = isAirline ? (
    fetchAirlineApi + fleetId + `&isAirline=true`
  ) : (
    fetchCorporateApi + fleetId + `&isAirline=false`
  )
  return callApi(
    url,
    config,
    null,
    null,
    null,
    false
  );
}

function getCorporateByIdRequest() {
    return {
        type: CORPORATE_BY_ID_REQUEST
    }
}

function getCorporateByIdSuccess(data) {
    return {
        type: CORPORATE_BY_ID_SUCCESS,
        data: data.res
    }
}

function getCorporateByIdFailure(error) {
    return {
        type: CORPORATE_BY_ID_FAILURE,
        error
    }
}

export function getCorporateById(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body:JSON.stringify(options)
    };
    return callApi(
        getCorporateByIdApi,
        config
    );
}