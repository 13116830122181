import React, { Component } from "react";
import "./reservationSearch.scss";
import {
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import _ from "lodash";
import Datetime from './../../components/dateTime/DateTime';
import moment from "moment";
import PropTypes from "prop-types";
import * as commonDataActions from "../../actions/commonDataAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { showDriversInReservation } from "./../../constants/commondata";
import CCDropDown from "../../components/dropdown/ccDropDown";

const moveActions = {
  BACK: "back",
  NEXT: "next"
};

class ReservationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.defaultData.companyId,
      vehicleType: this.props.defaultData.vehicleType,
      vehicleId: this.props.defaultData.vehicleId,
      driverId: this.props.defaultData.driverId,
      searchText: this.props.defaultData.searchText,
      showType: this.props.defaultData.showType,
      timeRange: {
        today: true,
        timeType: this.props.defaultData.tab,
        from: this.props.defaultData.dateFrom,
        to: this.props.defaultData.dateTo
      },
      isClosePicker: true,
      driverQuery: {
        list: [],
        limit: 20,
        page: 0,
        total: 0,
        search: "",
        hasMore: true,
      },
      vehicleQuery: {
        list: [],
        limit: 20,
        page: 0,
        total: 0,
        search: ""
      },
      fleetTimeZone: this.props.fleetTimeZone
    };
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.handleChangeToday = this.handleChangeToday.bind(this);
    this.handleOnChangeTimeToggle = this.handleOnChangeTimeToggle.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.handleClickMoveTime = this.handleClickMoveTime.bind(this);
  }

  componentDidMount() {
    this.handleSearchDriver("", null, true);
    this.handleSearchLicensePlate("", null, true);
    let timeRange = this.state.timeRange;
    timeRange = this.setStartAndEndDay(timeRange, timeRange.to);
    this.setState(
      {
        timeRange
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  }

  handleChangeCompany = value => {
    this.setState(
      {
        companyId: value
      },
      () => {
        this.handleSearchDriver("", null, true);
        this.handleSearchLicensePlate("", null, true);
        this.props.onSearch(this.state);
      }
    );
  };

  handleChangeCarType = value => {
    this.setState(
      {
        vehicleType: value
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  };

  handleChangeShowAllDrivers = value => {
    this.setState(
      {
        showType: value
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  };

  handleChangeSearchText(e) {
    this.setState({
      searchText: e.target.value
    });
  }

  handlePressEnterSearch(e) {
    if (e.charCode === 13) {
      this.props.onSearch(this.state);
    }
  }

  handleChangeToday(e) {
    if (!this.state.timeRange.today) {
      let timeRange = this.state.timeRange;
      timeRange.today = true;
      timeRange = this.setStartAndEndDay(timeRange, new Date());
      this.setState(
        {
          timeRange
        },
        () => {
          this.props.onSearch(this.state);
        }
      );
    }
  }

  handleOnChangeTimeToggle(e) {
    let timeRange = this.state.timeRange;
    const { from } = timeRange;
    timeRange.timeType = e.target.value;
    /*==========================================================
    **Use from day add 1 minute to set from for new time range
    **Ex: from day: 10/11 23:59 + 1 min = 10/12 00:00
    ==========================================================*/
    timeRange = this.setStartAndEndDay(timeRange, moment(from).add(1, "minutes"));
    this.setState(
      {
        timeRange
      },
      () => {
        this.props.timeRangeChangeCallback();
        this.props.onSearch(this.state);
      }
    );
  }

  handleSelectDate(value) {
    // value is moment object
    let timeRange = this.state.timeRange;
    timeRange = this.setStartAndEndDay(timeRange, value._d);
    this.setState(
      {
        isClosePicker: true,
        timeRange
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  }

  handleClickMoveTime(action) {
    let timeRange = this.state.timeRange;
    let timeMomentObject;
    const { time } = this.context;
    if (action === moveActions.BACK) {
      if (this.state.timeRange.timeType === time.DAY) {
        timeMomentObject = moment(this.state.timeRange.to).add(-1, "days");
      } else if (this.state.timeRange.timeType === time.WEEK) {
        timeMomentObject = moment(this.state.timeRange.to).add(-1, "weeks");
      }
    } else {
      if (this.state.timeRange.timeType === time.DAY) {
        timeMomentObject = moment(this.state.timeRange.to).add(1, "days");
      } else if (this.state.timeRange.timeType === time.WEEK) {
        timeMomentObject = moment(this.state.timeRange.to).add(1, "weeks");
      }
    }
    timeRange = this.setStartAndEndDay(timeRange, timeMomentObject._d);
    this.setState(
      {
        timeRange
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  }

  /*
   ** Name: setStartAndEndDay
   ** Description: calculating date from and date to, and check selected day is today or not
   ** Parameters: object(timeRange state), date(selected day)
   ** Return: object
   ** Reference: https://momentjs.com/docs/#/query/is-between/
   */
  setStartAndEndDay(timeRange, day) {
    const { time } = this.context;
    if (this.state.timeRange.timeType === time.DAY) {
      timeRange.from = moment(day).subtract(1, "days").hour(23)
        .minute(59)
        .second(59)._d;
      timeRange.to = moment(day).hour(23)
        .minute(59)
        .second(59)._d;
      timeRange.today = moment(new Date()).isSame(day, "day");
    } else if (this.state.timeRange.timeType === time.WEEK) {
      timeRange.from = moment(day).startOf("week")._d;
      timeRange.to = moment(day).endOf("week")._d;
      timeRange.today = moment(new Date()).isBetween(
        timeRange.from,
        timeRange.to,
        "day"
      );
    }
    return timeRange;
  }

  // search, load more driver

  getDriverDropDownTitle = () => {
    const { driverId } = this.state;
    let selectTitle = "";
    if (driverId === "all") {
      selectTitle = I18n.t("reservation.all");
    } else {
      let selectedDriver = _.find(
        this.state.driverQuery.list,
        item => item._id === driverId
      );
      if (selectedDriver) selectTitle = selectedDriver.fullName;
    }
    return (
      <Translate
        value="reservation.driver"
        name={selectTitle}
        className="dropdown-title"
      />
    );
  };

  getDriverList = (params, loadMore = false, callback = null) => {
    this.props.commonDataActions
      .loadMoreDrivers({
        fleetId: this.props.fleetId,
        isActive: true,
        companyId: this.props?.auth?.user?.roles?.isSupplier ? this.props?.auth?.user?.roles?.supplierId : this.state.companyId,
        ...params
      })
      .then(data => {
        if (data.ok && data.res && data.res.list) {
          this.setState({
            driverQuery: {
              ...this.state.driverQuery,
              ...data.res,
              search: params.search || "",
              hasMore: data.res.list.length === params.limit,
              list: loadMore
                ? this.state.driverQuery.list.concat(data.res.list)
                : data.res.list
            }
          });
        }
        if (callback) callback();
      });
  };

  handleSearchDriver = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.driverQuery, ["search", "page", "limit"]);
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      this.getDriverList(params, false, callback);
    } else {
      return callback();
    }
  };

  handleLoadMoreDriver = () => {
    let params = _.pick(this.state.driverQuery, ["search", "page", "limit"]);
    params.page++;
    if (!this.state.driverQuery.hasMore) return;
    this.getDriverList(params, "loadMore");
  };

  handleChangeDriver = value => {
    let driverId = value ? value : "all";
    this.setState(
      {
        driverId
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
    if (!value) {
      this.handleSearchDriver("", null, true);
    }
  };

  getLicensePlateDropDownTitle = () => {
    const { vehicleId } = this.state;
    let selectTitle = "";
    if (vehicleId === "all") {
      selectTitle = I18n.t("reservation.all");
    } else {
      let selectedItem = _.find(
        this.state.vehicleQuery.list,
        item => item._id === vehicleId
      );
      if (selectedItem) selectTitle = selectedItem.plateNumber;
    }
    return (
      <Translate
        value="reservation.licensePlate"
        name={selectTitle}
        className="dropdown-title"
      />
    );
  };

  getLicensePlateList = (params, loadMore = false, callback = null) => {
    this.props.commonDataActions
      .loadMoreVehicles({
        fleetId: this.props.fleetId,
        isActive: true,
        companyId: this.props?.auth?.user?.roles?.isSupplier ? this.props?.auth?.user?.roles?.supplierId : this.state.companyId,
        ...params
      })
      .then(data => {
        if (data.ok && data.res && data.res.list) {
          this.setState({
            vehicleQuery: {
              ...this.state.vehicleQuery,
              ...data.res,
              search: params.search || "",
              list: loadMore
                ? this.state.vehicleQuery.list.concat(data.res.list)
                : data.res.list
            }
          });
        }
        if (callback) callback();
      });
  };

  handleSearchLicensePlate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.vehicleQuery, ["search", "page", "limit"]);
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      this.getLicensePlateList(params, false, callback);
    } else {
      return callback();
    }
  };

  handleLoadMoreLicensePlate = () => {
    let params = _.pick(this.state.vehicleQuery, ["search", "page", "limit"]);
    params.page++;
    if (params.page * params.limit >= this.state.vehicleQuery.total) return;
    this.getLicensePlateList(params, "loadMore");
  };

  handleChangeLicensePlate = value => {
    let vehicleId = value ? value : "all";
    this.setState(
      {
        vehicleId
      },
      () => {
        this.props.onSearch(this.state);
      }
    );
  };

  render() {
    const { time, drawingColor } = this.context;

    const { companies, carType, suppliers } = this.props.commonData;

    let newCompanies = Object.assign([], [...companies, ...suppliers]);
    newCompanies.unshift({ _id: "all", name: "reservation.all" });

    let newCarType = Object.assign([], carType);
    newCarType.unshift({
      vehicleType: "all",
      custom_option_label: "All"
    });

    _.forEach(newCarType, item => {
      if (!item.custom_option_label) {
        item.custom_option_label = item.vehicleType;
      }
    });

    let showAllDrivers = _.map(showDriversInReservation, (value, key) => {
      return { value: value, label: "reservation." + value };
    });

    const licencePlateDropdownTitle = this.getLicensePlateDropDownTitle();
    const driverDropDownTitle = this.getDriverDropDownTitle();

    return (
      <div className="calendar-filter-wrapper">
        <div className="top-container">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={this.state.timeRange.timeType}
            onChange={() => { }}
            className="time-type"
          >
            <ToggleButton
              value={time.DAY}
              onChange={this.handleOnChangeTimeToggle}
              className="switchButton"
            >
              <Translate value="reservation.day" />
            </ToggleButton>
            <ToggleButton
              value={time.WEEK}
              onChange={this.handleOnChangeTimeToggle}
              className="switchButton"
            >
              <Translate value="reservation.week" />
            </ToggleButton>
          </ToggleButtonGroup>
          <div className="calendar-datepicker">
            <div
              onClick={() => this.handleClickMoveTime(moveActions.BACK)}
              className="cursor-pointer date-nav"
            >
              <i className="fa fa-caret-left" />
            </div>
            <div className="date-time-component">
              <Datetime
                onChange={this.handleSelectDate}
                closeOnSelect={this.state.isClosePicker}
                value={moment(this.state.timeRange.to).locale(this.props.locale)}
              />
              <div className="showing-calendar">
                <div className="calendar-content">
                  {this.state.timeRange.timeType === time.WEEK
                    ? `${moment(this.state.timeRange.from).format(
                      "MMM DD, YYYY"
                    )} - ${moment(this.state.timeRange.to).format(
                      "MMM DD, YYYY"
                    )}`
                    : moment(this.state.timeRange.to).format("MMM DD, YYYY")}
                </div>
              </div>
            </div>
            <div
              onClick={() => this.handleClickMoveTime(moveActions.NEXT)}
              className="cursor-pointer date-nav"
            >
              <i className="fa fa-caret-right" />
            </div>
          </div>
          <OverlayTrigger
            placement="top"
            delayShow={300}
            overlay={
              <Tooltip id="tooltip">
                <p>{I18n.t("reservation.search_holder")}</p>
              </Tooltip>
            }
          >
            <FormControl
              type="text"
              value={this.state.searchText}
              placeholder={I18n.t("reservation.search_holder")}
              onChange={this.handleChangeSearchText}
              onKeyPress={this.handlePressEnterSearch}
              className="form-custom search-form mr-md"
            />
          </OverlayTrigger>
          {this.state.timeRange.timeType === time.DAY && (
            <div className="reservation-status">
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <Translate value="reservation.reservedHint" />
                  </Tooltip>
                }
              >
                <div className="reserved">
                  <div className="color" />
                  <div className="text">
                    <Translate value="reservation.reserved" />
                  </div>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <Translate value="reservation.overlappedHint" />
                  </Tooltip>
                }
              >
                <div className="overlapped">
                  <div className="color" />
                  <div className="text">
                    <Translate value="reservation.overlapped" />
                  </div>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <Translate value="reservation.availableHint" />
                  </Tooltip>
                }
              >
                <div className="available">
                  <div className="color" />
                  <div className="text">
                    <Translate value="reservation.available" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className="bottom-container">
          {!this.props.auth?.user?.roles?.isSupplier && <CCDropDown
            id="company-dropdown"
            items={newCompanies}
            title={I18n.t("reservation.company")}
            selectedItems={[this.state.companyId]}
            valueKey="_id"
            labelKey="name"
            onSelect={this.handleChangeCompany}
          />}
          <CCDropDown
            id="carType-dropdown"
            items={newCarType}
            title={I18n.t("reservation.carType")}
            selectedItems={[this.state.vehicleType]}
            noTranslateLabel
            valueKey="vehicleType"
            labelKey="custom_option_label"
            onSelect={this.handleChangeCarType}
          />
          <CCDropDown
            id="licensePlate-dropdown"
            items={this.state.vehicleQuery.list}
            title={"reservation.licensePlate"}
            selectedItems={
              !this.state.vehicleId || this.state.vehicleId === "all"
                ? []
                : [this.state.vehicleId]
            }
            valueKey="_id"
            labelKey="plateNumber"
            onSelect={this.handleChangeLicensePlate}
            onSearchChange={_.debounce(this.handleSearchLicensePlate, 400)}
            onScrollBottom={this.handleLoadMoreLicensePlate}
            customTitle={
              <OverlayTrigger
                overlay={
                  <Tooltip id={"tooltip"}>{licencePlateDropdownTitle}</Tooltip>
                }
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                <span className="dropdown-title">
                  {licencePlateDropdownTitle}
                </span>
              </OverlayTrigger>
            }
            searchable
            async
            enableClearAll
            noTranslateLabel
          />
          <CCDropDown
            id="driver-dropdown"
            items={this.state.driverQuery.list}
            title={"reservation.driver"}
            selectedItems={
              !this.state.driverId || this.state.driverId === "all"
                ? []
                : [this.state.driverId]
            }
            valueKey="_id"
            labelKey="fullName"
            onSelect={this.handleChangeDriver}
            onSearchChange={_.debounce(this.handleSearchDriver, 400)}
            onScrollBottom={this.handleLoadMoreDriver}
            customTitle={
              <OverlayTrigger
                overlay={
                  <Tooltip id={"tooltip"}>{driverDropDownTitle}</Tooltip>
                }
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                {driverDropDownTitle}
              </OverlayTrigger>
            }
            searchable
            async
            enableClearAll
            noTranslateLabel
          />
          <CCDropDown
            id="showAllDrivers-dropdown"
            items={showAllDrivers}
            title={I18n.t("reservation.showAllDrivers")}
            selectedItems={[this.state.showType]}
            valueKey="value"
            labelKey="label"
            onSelect={this.handleChangeShowAllDrivers}
          />
        </div>
      </div>
    );
  }
}

ReservationSearch.propTypes = {
  defaultData: PropTypes.object,
  onSearch: PropTypes.func,
  companies: PropTypes.array,
  carType: PropTypes.array
};

ReservationSearch.defaultProps = {
  companies: [],
  carType: []
};

ReservationSearch.contextTypes = {
  drawingColor: PropTypes.object,
  time: PropTypes.object
};

function commonData(state) {
  return {
    commonData: state.commonData,
    locale: state.i18n && state.i18n.locale ? state.i18n.locale : "en-US"
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}

export default connect(
  commonData,
  mapDispatchToProps
)(ReservationSearch);
