import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Button, Modal } from 'react-bootstrap';

export default function ConfirmDelete({ closeConfirmModal, onConfirmDelete, caseOfDelete = 'Delete_location' }) {
  return (
    <Modal onHide={closeConfirmModal} show backdrop className="confirm">
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value={`thirdPartySettings.${caseOfDelete}`} />
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeConfirmModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Translate value="shiftSettings.Delete_confirm_shift_template" />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-save mr-md" onClick={onConfirmDelete}>
          <Translate value="shiftSettings.Yes" />
        </Button>
        <Button className="btn-cancel" onClick={closeConfirmModal}>
          <Translate value="shiftSettings.No" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
