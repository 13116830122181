import { STATUS_FLEET_SERVICE } from "../../../constants/commondata";

function initExtraService(data, bookInfo) {
    let selected = [];
    if(STATUS_FLEET_SERVICE.includes(bookInfo?.status)) return
    let extraServices = []
    if (data && data.res) {
        const bookingCurrency = this.props.newBooking && this.props.newBooking.locationService
            && this.props.newBooking.locationService.currency ? this.props.newBooking.locationService.currency : this.props.auth.selectedFleet.currency;
        let extraServiceResponse = []
        let fleetInfo = data.res && data.res.fleetInfo || {}
        let isNewSettingAdditionFee = !!fleetInfo.additionalFees || false
        if(fleetInfo.additionalFees === 1) {
            extraServiceResponse = data.res.fleetServices || []
        } else {
            extraServiceResponse = data.res.extraServices || []
        }
        extraServices = extraServiceResponse.map(service => {
            let fee = 0;
            let active = false
            let serviceCurrency = service.serviceFeeByCurrencies.find(c => {
                return c.currencyISO == bookingCurrency.iso
            })
            if (serviceCurrency) {
                fee = serviceCurrency.value
            }

            if (this.state.data && this.state.data.request.services) {
                let selected = this.state.data.request.services.find(sv => {
                    return sv.active && sv.serviceId == service._id
                })
                active = !!(selected && selected.active)
                if (selected) {
                    fee = selected.fee
                }
            }
            return {
                fee,
                active,
                name: service.serviceName,
                type: service.serviceType,
                serviceId: service._id,
                infoService: service,
                applyTax: service.applyTax,
                serviceType: service.serviceType,
                serviceName: service.serviceName,
                serviceType: service.serviceType,
                serviceFeeType: service.serviceFeeType,
                serviceFeePercent: service.serviceFeePercent,
                serviceFeeByCurrencies: service.serviceFeeByCurrencies
            }
        })

        //push removed additional
        if (this.state.data && this.state.data.request.services && extraServices.length > 0) {
            let removedList = []
            this.state.data.request.services.map(selected => {
                let missing = extraServices.find(master => master.serviceId == selected.serviceId)
                if (!missing) {
                    removedList.push(selected)
                }
            })
        }

        this.setState({
            data: {
                ...this.state.data,
                extraServices,
                isNewSettingAdditionFee
            }
        })
    }
}

export const calculationAdditionalService = (itemId, data) => {
    let services = data.extraServices,
        itemIndex = services.findIndex(obj => itemId == obj.serviceId)
    if (itemIndex >= 0) {
        services[itemIndex].active = !services[itemIndex].active
    }
    return services
}

function onSelectAdditionalService(itemId) {
    this.setState({
        data: {
            ...this.state.data,
            extraServices: (calculationAdditionalService(itemId, this.state.data) || []).slice()
        },
        isFormDirty: true
    }, ()=> { 
        this.etaFareCalculator()
    })
}

export {
    initExtraService,
    onSelectAdditionalService
}
