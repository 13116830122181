import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import { Validator, ValidCase } from "../../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../../utils/commonFunctions";
import { updatePointCustomer } from "../../../actions/customerAction";
import * as loadingBarActions from "./../../../actions/loadingBarActions";
import {
  FormGroup,
  Alert,
  InputGroup,
  Form,
  Modal,
  FormControl,
  Button,
} from "react-bootstrap";

class PointModal extends React.Component {
  constructor() {
    super();
    this.state = {
			currentPoint: "",
      operator: "true",
      pointValue: "",
      reason: "",
      valid: {},
      isSubmitted: false
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
    this.handlePointChange = this.handlePointChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentPoint !== "" &&
      nextProps.userId
    ) {
      this.setState({
        isShow: nextProps.isShow,
        currentPoint: nextProps.currentPoint,
        userId: nextProps.userId
      })
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleOperatorChange(e) {
    this.setState({ operator: e.target.value === "true" });
  }

  handlePointChange(e) {
    this.setState({ pointValue: e.target.value });
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value });
  }

  handleSaveClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    let pointValue = parseFloat(this.state.pointValue);
    if (!this.state.operator) {
      pointValue = 0 - pointValue;
    };

    this.props
      .updatePointCustomer({
        fleetId: this.props.fleetId,
        userId: this.state.userId,
        pointValue: pointValue,
        reason: this.state.reason
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res.returnCode == 200) {
          this.props.handlePointSuccess(data.res);
          this.setState({
            currentPoint: "",
            operator: "true",
            pointValue: "",
            reason: "",
            valid: {},
            isSubmitted: false
          });
          this.context.notification(
            "success",
            I18n.t("customer.INFO_UPDATE_POINT_SUCCESS")
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("messages.credits." + data.res.returnCode)
          );
        }
      });
  }

  handleCancelClick() {
    this.props.handleConfirmCloseClick();
    this.setState({
      currentPoint: "",
      operator: "true",
      pointValue: "",
      reason: "",
      valid: {},
      isSubmitted: false
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    const { isShow, currentPoint } = this.props;
    const { valid, isSubmitted } = this.state;
    if (isShow) {
      if (currentPoint !== "") {
        const formatBalance = `${currentPoint}`;
        let operatorValue = null;
        let pointValue = Validation.isNumber(this.state.pointValue)
          ? parseInt(this.state.pointValue)
          : 0;
				if (this.state.operator) {
					operatorValue = pointValue + currentPoint;
				} else {
					operatorValue = currentPoint - pointValue;
				}
        return (
          <Modal
            show={true}
            onHide={this.props.closeDialog}
            backdrop="static"
            bsSize="sm"
            aria-labelledby="contained-modal-title-sm"
            dialogClassName="change-balance-dialog"
            className="confirm"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-sm">
                <Translate value="pointConfig.point" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCancelClick}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className="clearfix">
              <FormGroup>
                <Form.Label>
                  <Translate value="pointConfig.point" />{" "}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={formatBalance}
                  disabled={true}
                />

              </FormGroup>
              <FormGroup
                className={
                  isSubmitted ? (valid.amount === false ? "error" : null) : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Adjust_value" />{" "}
                  <span className="require"> *</span>
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup className="single-addon-left">
                    <FormControl
                      style={{ width: "85%" }}
                      type="text"
                      value={this.state.pointValue}
                      onChange={this.handlePointChange}
                    />
                    <FormControl
                      as="select"
                      value={this.state.operator}
                      style={{ width: "15%" }}
                      onChange={e => {
                        this.handleOperatorChange(e);
                      }}
                      className="form-custom input-group-addon-custom"
                    >
                      <option key={0} value={"true"}>
                        +
                      </option>
                      <option key={1} value={"false"}>
                        -
                      </option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator id="pointValue" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.pointValue)}
                    message={I18n.t("driver.ERROR_INPUT_AMOUNT")}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      Validation.isNumber(this.state.pointValue) &&
                      parseInt(this.state.pointValue) > 0
                    }
                    message={I18n.t("messages.commonMessages.greater_than_0")}
                  />
                </Validator>
              </FormGroup>
              {operatorValue ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="driver.New_point" />
                  </Form.Label>
                  <FormGroup>
                    <span className="text-active">
										{operatorValue}
                    </span>
                  </FormGroup>
                </FormGroup>
              ) : null}
              <FormGroup
                className={`mb0 ${
                  isSubmitted ? (valid.reason === false ? "error" : null) : null
                }`}
              >
                <Form.Label>
                  <Translate value="driver.Enter_reason" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="textarea"
                  value={this.state.reason}
                  onChange={this.handleReasonChange}
                  className="form-custom"
                />
                <Validator id="reason" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.reason)}
                    message={I18n.t("driver.ERROR_INPUT_REASON")}
                  />
                </Validator>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <div className="fill text-center">
                <Button
                  className="btn-save mr-md"
                  onClick={this.handleSaveClick}
                >
                  <Translate value="driver.Save" />
                </Button>
                <Button className="btn-cancel" onClick={this.handleCancelClick}>
                  <Translate value="driver.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          </Modal >
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
PointModal.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    fleetId: state.auth.selectedFleet.fleetId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updatePointCustomer: options => {
      return dispatch(updatePointCustomer(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PointModal);
