import * as _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import currencyFormatter from 'currency-formatter'

export default function transformDeliveryFromExcelData(booking, index) {
  const convertPaymentType = (textValue = '') => {
    switch (textValue.toLowerCase()) {
      case 'cash':
        return 1
      case 'direct invoicing':
        return 5
      case 'cash by sender':
        return 16
      case 'cash by recipient':
        return 17
      default:
        return 0
    }
  }

  let status = 'Valid'
  let errorCode = ''
  let errorData = {}

  let sender = {}
  let recipients = []
  const senderKeys = [
    'sender.mobileNumber',
    'sender.email',
    'sender.name',
    'sender.address',
    'sender.pickupDate',
  ]
  const bookingKeys = ['vehicleTypeRequest', 'rideSharing', 'paymentType']
  const getSenderInfo = (doc) => ({
    phone: doc['sender.mobileNumber'],
    email: doc['sender.email'],
    firstName: doc['sender.name'],
    fullName: doc['sender.name'],
    address: doc['sender.address'],
  })
  const getRecipientInfo = (doc) => {
    const recipient = {
      externalId: doc.externalId,
      order: _.isNaN(parseInt(doc.multiStopSequenceNumber))
        ? doc.multiStopSequenceNumber
        : parseInt(doc.multiStopSequenceNumber),
      name: doc['recipient.name'],
      phone: doc['recipient.mobileNumber'],
      address: {
        address: doc['recipient.address'],
        addressDetails: {},
        locationDetails: doc['recipient.locationDetails'],
      },
      menuData: {
        menuId: 'bfc-menuid',
        selectedItems: [
          {
            componentType: 'text-area-row',
            parentItemId: 'bfc-text-area-row-parrent-item',
            fieldKey: 'packageInfo',
            fieldType: 'String',
            fieldValue: doc['details.packageContent'] || '',
          },
          {
            componentType: 'notes-row',
            parentItemId: 'bfc-notes-row-parrent-item',
            fieldKey: 'request.notes',
            fieldType: 'String',
            fieldValue: doc['details.instructionsForDriver'] || '',
          },
          {
            componentType: 'notes-row',
            parentItemId: 'bfc-notes-row-parrent-item',
            fieldKey: 'request.orderId',
            fieldType: 'String',
            fieldValue: doc['details.orderNumber'] || '',
          },
        ],
      },
    }

    if (parseFloat(doc['details.codItemValue'] || '0') > 0) {
      recipient.menuData.selectedItems.push({
        componentType: 'checkbox-amount-row',
        parentItemId: 'bfc-checkbox-amount-row-parrent-item',
        fieldKey: 'cashOnDelivery',
        fieldType: 'PriceCurrency',
        fieldValue: {
          iso: this.props.auth.selectedFleet.currencyISOValidation,
          symbol: currencyFormatter.findCurrency(
            this.props.auth.selectedFleet.currencyISOValidation
          ).symbol,
          value: parseFloat(doc['details.codItemValue'] || '0') || 0,
        },
      })
    }
    recipient.isValid = !!(
      recipient.name &&
      recipient.phone &&
      recipient.address.address
    )
    if (!recipient.isValid) {
      status = 'Invalid'
      errorCode = 'invalidRecipientInformation'
    }
    return recipient
  }
  let timeStr
  let order = booking
  if (Array.isArray(booking)) {
    const orders = booking
    order = booking[0]
    timeStr = order['sender.pickupDate']
    let foundSenderInfo = orders.find((i) => !!i['sender.mobileNumber'])
    let isValidSender = orders.every((i) => {
      const isNoSender = senderKeys.every((key) => !i[key])
      const isSameSender = senderKeys.every(
        (key) => i[key] === foundSenderInfo[key]
      )
      return isNoSender || isSameSender
    })
    if (isValidSender) {
      sender = getSenderInfo(foundSenderInfo)
      recipients = _.sortBy(orders.map(getRecipientInfo), ['order'])
      const { selectedFleet } = this.props.auth
      const maxRecipients = _.get(
        selectedFleet,
        'delivery.maxRecipientsOperatorInput',
        3
      )
      if (_.uniqBy(recipients, 'externalId').length > 1) {
        status = 'Invalid'
        errorCode = 'invalidBookingReference'
      }
      if (recipients.length > 1) {
        recipients = recipients.map((recipient, i) => {
          const orderNumber = parseInt(recipient.order)
          if (!/^\d+$/.test(recipient.order) || orderNumber !== i + 1) {
            status = 'Invalid'
            errorCode = 'invalidSequenceNumber'
            recipient.isValid = false
            return recipient
          }
          if (i + 1 > maxRecipients) {
            status = 'Invalid'
            errorCode = 'invalidNumberOfRecipients'
            errorData = { maxRecipients }
            recipient.isValid = false
            return recipient
          }
          return recipient
        })
      }
    } else {
      status = 'Invalid'
      errorCode = 'invalidSenderInformation'
    }
    const isSameOperationInfo =
      _.uniq(
        orders.map((order) => {
          return bookingKeys.map((k) => order[k]).join('-')
        })
      ).length === 1
    if (!isSameOperationInfo) {
      status = 'Invalid'
      errorCode = 'invalidSenderInformation'
    }
    const isCashByRecipient =
      (order.paymentType || '').toLowerCase() === 'cash by recipient'
    if (isCashByRecipient && orders.length > 1) {
      status = 'Invalid'
      errorCode = 'invalidPaymentType'
    }
  } else {
    sender = getSenderInfo(order)
    recipients = [getRecipientInfo(order)]
    timeStr = order['sender.pickupDate']
  }
  let time = moment.tz(timeStr, 'UTC')
  if (time.isValid()) {
    time = time.format('YYYY-MM-DD HH:mm')
  } else {
    time = timeStr
    status = 'Invalid'
    errorCode = 'invalidSenderInformation'
  }

  const rq = {
    status,
    no: index + 1,
    travelerType: this.state.corporateId ? 1 : 0,
    bookFrom: 'CC',
    fleetId: this.props.auth.selectedFleet.fleetId,
    recurring: {
      mode: 'single',
      pickupTime: time,
    },
    psgInfo: _.omit(sender, ['address']),
    deliveryInfo: {
      pickup: {
        phone: sender.phone,
        email: sender.email,
        firstName: sender.firstName,
        name: sender.fullName,
        address: {
          address: sender.address,
          locationDetails: order['sender.locationDetails'],
        },
      },
      recipients: recipients,
    },
    corporateInfo: {
      corporateId: this.state.corporateId,
      name: '',
      clientCaseMatter: order.tripDescription,
      chargeCode: order.tripCode,
    },
    request: {
      fleetId: this.props.auth.selectedFleet.fleetId,
      psgFleetId: this.props.auth.selectedFleet.fleetId,
      pickUpTime: time,
      paymentType: convertPaymentType(order.paymentType + ''),
      pickup: { address: sender.address },
      notes: order.notes,
      note: order.note,
      operatorNote: order.internalNotes,
      rideSharing: _.toLower(order.rideSharing) === 'yes',
      vehicleTypeRequest: order.vehicleTypeRequest,
      estimate: {},
    },
    externalInfo: {
      bookingReference: order.externalId || '',
    },
  }
  if (this.state.corporateId) {
    const corporate = this.props.corporateCompany.find(
      (i) => i._id === this.state.corporateId
    )
    if (corporate) {
      rq.corporateInfo.name = corporate.companyInfo.name
    }
  }

  if (rq.request.paymentType === 0) {
    rq.status = 'Invalid'
    errorCode = 'invalidSenderInformation'
  }

  if (errorCode) {
    _.set(rq, ['_meta_', 'errorCode'], errorCode)
    _.set(rq, ['_meta_', 'errorData'], errorData)
    this.props.importBookingsAction.showHideValidationPopup({
      errorCode,
      errorData,
      showValidationErrorModal: true,
    })
  }
  return rq
}
