import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../settings.scss";
import {
    Tab,
    Tabs
} from "react-bootstrap";
import IntlTelInputApp from "../../../components/intlTelInputCustome/IntlTelInputApp";
import { Translate, I18n } from "react-redux-i18n";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";

import TwilioSettings from './TwilioSettings';
import VoipSettings from './VoipSettings';
import BookingSMSTemplate from "./BookingSMSTemplate";

class VoiceSMSSettings extends Component {
    constructor() {
        super();
        this.state = {
        };

    };

    render() {
        const { voip } = this.props.auth.selectedFleet.generalSetting;
        const isActiveVoip = voip ? (voip.forApp || voip.forCC) : false;

        return (
            <div ref={ref => (this.refTabs = ref)} className="content custom-form overflow-auto general-setting">
                <Tabs
                    id="tab"
                    defaultActiveKey={2}
                    animation={false}
                    className="cue-tabs"
                    unmountOnExit
                >
                    {/* <Tab eventKey={0} title={I18n.t('voiceSMSSetting.voiceSMS')}>
                        <TwilioSettings />
                    </Tab> */}
                    {isActiveVoip ? (
                        <Tab eventKey={1} title={I18n.t('voiceSMSSetting.voip')}>
                            <VoipSettings />
                        </Tab>
                    ) : null}
                    <Tab eventKey={2} title={I18n.t('voiceSMSSetting.bookingSMS')}>
                        <BookingSMSTemplate parentRef={this.refTabs}/>
                    </Tab>

                </Tabs>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoiceSMSSettings);
