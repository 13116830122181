import React from 'react';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import styles from '../styles/formDocument.module.scss';
export default class DriverEmergencyContacts extends React.PureComponent {
  state = {
    contacts: [],
  };

  componentDidMount() {
    const { getEmergencyContactDriver, userId, selectedFleet } = this.props;
    getEmergencyContactDriver({ userId, fleetId: selectedFleet.fleetId }).then(
      ({ res }) => {
        this.setState({ contacts: res });
      }
    );
  }

  render() {
    const { contacts } = this.state;
    const { selectedFleet } = this.props;
    if (!selectedFleet.SOS || !selectedFleet.SOS.driver) {
      return null;
    }

    return (
      <div className={styles['tableContainer']}>
        <table className={styles['tableCustom']}>
          <tr>
            <th>
              <Translate value="customer.Name" />
            </th>
            <th>
              <Translate value="customer.Phone_Number" />
            </th>
          </tr>
          {!contacts || !contacts.length ? (
            <tr>
              <td>
                <Translate value="reservation.noData" />
              </td>
            </tr>
          ) : (
            ''
          )}

          {contacts?.length ?
            contacts?.map((contact) => (
              <tr>
                <td>{contact.name}</td>
                <td>{contact.phone.full}</td>
              </tr>
            )): ''}
        </table>
      </div>
      // <div className="driverEmergencyContact col-xs-12 mb-md">
      //   <table className="table table-no-pagination cc-table-striped">
      //     <thead>
      //       <tr>
      //         <th>
      //           <Translate value="customer.Name" />
      //         </th>
      //         <th>
      //           <Translate value="customer.Phone_Number" />
      //         </th>
      //       </tr>
      //     </thead>
      //   </table>
      //   <div className="driverEmergencyContact__contactList">
      //     <table className="table table-no-pagination cc-table-striped">
      //       <thead>
      //         <tr>
      //           <th />
      //           <th />
      //         </tr>
      //       </thead>
      //       <tbody>
      //         {!contacts || !contacts.length ? (
      //           <tr>
      //             <td className="text-center" colSpan={2}>
      //               <Translate value="reservation.noData" />
      //             </td>
      //           </tr>
      //         ) : null}
      //         {contacts.map(contact => (
      //           <tr>
      //             <td>{contact.name}</td>
      //             <td>{contact.phone.full}</td>
      //           </tr>
      //         ))}
      //       </tbody>
      //     </table>
      //   </div>
      // </div>
    );
  }
}
