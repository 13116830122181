import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    Form
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../../components/validator';
import {
    Validation,
} from '../../../utils/commonFunctions';

function InputField({
    itemKey,
    valueData = "",
    placeholderCustom = "",
    require = true,
    editable,
    validField = true,
    isSubmitted,
    ValidatorCallback,
    handleInputChange
}) {
    return (
        <FormGroup
            className={
                !isSubmitted
                    ? null
                    : !validField && require
                        ? 'error'
                        : null
            }
        >
            <Form.Label>
                <Translate value={`merchant.user.${itemKey}`} />
                {require && <span className="require"> *</span>}
            </Form.Label>
            <FormControl
                className="form-custom"
                type="text"
                placeholder={placeholderCustom ? I18n.t(`merchant.user.${placeholderCustom}`) : I18n.t(`merchant.user.${itemKey}`)}
                value={valueData ? valueData : ''}
                onChange={e => handleInputChange(e, itemKey)}
                disabled={!editable}
            />
            {require &&
                <Validator id={itemKey} callback={ValidatorCallback}>
                    <ValidCase
                        hide={!isSubmitted}
                        valid={!Validation.isStringEmpty(valueData)}
                        message={I18n.t('messages.commonMessages.Required_field')}
                    />
                </Validator>
            }
        </FormGroup>
    )
};

InputField.propTypes = {
    itemKey: PropTypes.string,
    valueData: PropTypes.string,
    placeholderCustom: PropTypes.string,
    require: PropTypes.bool,
    editable: PropTypes.bool,
    validField: PropTypes.bool,
    isSubmitted: PropTypes.bool,
    ValidatorCallback: PropTypes.func,
    handleInputChange: PropTypes.func
};

export default React.memo(InputField)