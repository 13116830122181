import React, { Component } from 'react';
import { FormGroup, Image } from 'react-bootstrap';
import recent_icon from './../../../assets/images/icons/recent.svg';
import PlacesAutocomplete3rdGG from './../../placesAutocomplete/PlacesAutocomplete3rdGG';
import { I18n } from 'react-redux-i18n';
import OverlayCC from '../../OverlayCC';

class LocationInput3rdGG extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false
    };
  }

  renderRecentItem = (p, index) => {
    const { onSelectRecent, thirdParty } = this.props;

    return (
      <li className="place-item" key={index}>
        <a
          onClick={() => {
            this.setState(
              {
                showOverlay: false
              },
              () => {
                thirdParty
                  ? onSelectRecent(p.address, {
                      location: {
                        address: p.address,
                        coordinates: p.geo,
                        city: p.city,
                        country: p.country,
                      },
                    })
                  : onSelectRecent({
                      address: p.address,
                      coordinates: p.geo,
                      city: p.city,
                      country: p.country,
                      addressDetails: p.addressDetails,
                    });
              }
            );
          }}
        >
          <i className="fa fa-map-marker" />
          <span>{p.address}</span>
        </a>
      </li>
    );
  };

  setShowOverlay = (showOverlay) => {
    this.setState({ showOverlay });
  }

  render() {
    const {
      recents,
      address,
      onChange,
      onRemove,
      onSelect,
      disabled,
      autoFocus,
      thirdParty,
      isChina,
      options,
      className,
      pickUp,
      onSelectParty,
      hasPartyLocation,
      changePlaceOrder = false,
    } = this.props;

    return (
      <FormGroup
        className={
          'place-container' +
          (address ? ' has-address' : '') +
          (recents && recents.length ? ' has-recent-input' : '') +
          (className ? ' ' + className : '')
        }
      >
        {!disabled && recents && recents.length ? (
          <React.Fragment>
            <OverlayCC
              childrenAction={
                <div className={'recent-container'}>
                  <Image
                    className="recent-btn"
                    src={recent_icon}
                  />
                </div>
              }
              childrenOverlay={
                <div className={'recent-places-container chauffeurBK'}>
                  <ul className="list-unstyled">
                    {recents.map((item, index) =>
                      this.renderRecentItem(item, index)
                    )}
                  </ul>
                </div>
              }
              setShowOverlay={this.setShowOverlay}
	            showOverlay={this.state.showOverlay}
            />
          </React.Fragment>
        ) : (
          ''
        )}
        <PlacesAutocomplete3rdGG
          value={address}
          onSelect={onSelect}
          onChange={onChange}
          placeholder={
            changePlaceOrder
              ? I18n.t('newbooking.Enter_a_location_delivery')
              : I18n.t('newbooking.Enter_a_location')
          }
          onEnterKeyDown={onSelect}
          handleAddressRemoved={onRemove}
          autoFocus={autoFocus}
          autoComplete="off"
          classNames={recents && recents.length > 0 ? 'has-recent' : ''}
          disabled={disabled}
          isChina={isChina}
          onSelectParty={onSelectParty}
          styles={{}}
          pickUp={pickUp}
          options={options}
          hasPartyLocation={hasPartyLocation}
          data={this.props.data}
          auth={this.props.auth}
        />
      </FormGroup>
    );
  }
}

export default LocationInput3rdGG;
