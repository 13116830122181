import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import MultipleSelect from '../../../components/multipleselect/MultipleSelect';
import Confirm from '../../../components/confirm/Confirm';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import {
  getOperationCompany,
  getOperationShift,
  assginOperation,
  unassginOperation,
  fetchOperation
} from '../../../actions/settingActions';
import { Col, Row, Button, Form, Modal } from 'react-bootstrap';
import DriverList from './components/DriverList';
import './style.scss';

var emptyLicensePlate = [
  {
    _id: '',
    plateNumber: '',
    type: {
      typeId: ''
    }
  }
];

class Operation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      dialogData: {},
      operations: []
    };
  }

  componentDidMount() {
    if (!this.state.companies) {
      this.props
        .getOperationCompany({ fleetId: this.props.auth.selectedFleet.fleetId })
        .then(data => {
          if (data.res) {
            this.setState({ companies: data.res });
          }
        });
    }

    if (!this.state.shifts) {
      this.props
        .getOperationShift({
          fleetId: this.props.auth.selectedFleet.fleetId,
          isActive: true
        })
        .then(data => {
          if (data.res) {
            this.setState({ shifts: data.res });
          }
        });
    }

    if (!this.state.zones && this.props.mapZone) {
      this.setState({ zones: _.filter(this.props.mapZone,{'display':true}) });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.zones && nextProps.mapZone) {
      this.setState({ zones: _.filter(nextProps.mapZone,{'display':true}) });
    }
  }

  handleDriverChange = e => {
    if (e.length === 1) {
      let existOperation = _.filter(
        this.state.operations,
        item => item.driverId === e[0]
      );

      if (existOperation[0]) {
        this.handleOperationItemClick(existOperation[0]);
      } else {
        // get operation bye driverId when no operation in list
        const { fetchOperation, loadingBarActions, auth } = this.props;

        loadingBarActions.showLoadingSpiner();
        fetchOperation({
          fleetId: auth.selectedFleet.fleetId,
          driverId: e[0]
        })
          .then(data => {
            loadingBarActions.hideLoadingSpiner();

            if (data.ok && data.res && data.res.length) {
              this.state.operations = this.state.operations.concat(data.res);
              this.handleOperationItemClick(data.res[0]);
            } else {
              let shifts = this.state.shifts;

              if (shifts[0]._id !== '') {
                shifts.unshift({
                  _id: '',
                  shiftName: ''
                });
              }

              let vehicles = this.state.vehicles;

              if (!_.isUndefined(vehicles) && vehicles[0]._id !== '') {
                vehicles.unshift({
                  _id: '',
                  plateNumber: ''
                });
              }

              this.setState(
                {
                  zone: [''],
                  shift: [''],
                  plateNumber: [''],
                  shifts: shifts,
                  vehicles: vehicles
                },
                () => {
                  if (this.state.companies[0]) {
                    this.handleCompanyChange([this.state.companies[0]._id]);
                  }
                }
              );
            }
          })
          .catch(error => {
            loadingBarActions.hideLoadingSpiner();
          });
      }
    }

    this.setState({
      driver: e
    });
  }

  handleCompanyChange = e => {
    if (!this.state.companies) return;
    let company = this.state.companies.filter(function (company) {
      if (company._id === e[0]) {
        return company;
      }
    });
    let plateNumber = this.state.plateNumber ? this.state.plateNumber[0] : '';
    let plateNumberName = this.state.plateNumberName;
    let vehicleTypeId = this.state.vehicleTypeId;
    if (
      !this.state.plateNumber ||
      !company[0].vehicles.some(e => {
        return e._id === this.state.plateNumber[0];
      })
    ) {
      plateNumber = company[0].vehicles[0]._id;
      plateNumberName = company[0].vehicles[0].plateNumber;
      vehicleTypeId = company[0].vehicles[0].type
        ? company[0].vehicles[0].type.typeId
        : null;
    }
    this.setState({
      plateNumber: [undefined],
      plateNumberName: plateNumberName,
      vehicleTypeId: vehicleTypeId,
      company: [company[0]._id],
      companyName: company[0].name,
      vehicles: emptyLicensePlate.concat(company[0].vehicles)
    });
  };

  handleLicensePlateChange = e => {
    if (!this.state.vehicles) return;
    let vehicle = this.state.vehicles.filter(function (vehicle) {
      if (vehicle._id === e[0]) {
        return vehicle;
      }
    });
    let vehicleType = this.props.carType.filter(function (type) {
      if (vehicle[0].type && type._id === vehicle[0].type.typeId) {
        return type;
      }
    });
    if (vehicleType[0]) {
      this.setState({
        vehicleType: vehicleType[0].vehicleType,
        vehicleTypeId: vehicleType[0].vehicleTypeId,
        zones: _.filter(vehicleType[0].zoneId,{'display':true}),
        plateNumber: [vehicle[0]._id],
        plateNumberName: vehicle[0].plateNumber
      });
    } else {
      this.setState({
        zones: [],
        plateNumber: [vehicle[0]._id],
        plateNumberName: vehicle[0].plateNumber
      });
    }
  };

  handleZoneChange = e => {
    let zoneName = '';
    this.state.zones.forEach(item => {
      e.forEach(selectItem => {
        if (item._id === selectItem) {
          zoneName += item.zoneName;
        }
      });
    });
    this.setState({
      zone: e,
      zoneName: zoneName
    });
  }

  handleShiftChange = e => {
    let shiftSelect = this.state.shifts.filter(item => {
      if (item._id === e[0]) {
        return item;
      }
    });
    this.setState({
      shift: e,
      shiftName: shiftSelect[0].name
    });
  }

  handleConfirmButtonClick = () => {
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .unassginOperation({
        assign: false,
        fleetId: this.props.auth.selectedFleet.fleetId,
        driverIds: this.state.driver
      })
      .then(response => {
        if (response.ok) {
          let successSize = response.res.filter(re => {
            return re.status === 200;
          });

          _.remove(this.state.operations, o => !!_.find(successSize, d => d.driverId === o.driverId));

          if (successSize.length) {
            this.handleDriverChange([successSize[0].driverId]);
            this.context.notification(
              'success',
              I18n.t('operationSetting.Unassign_success')
            );
          } else {
            let errorDrivers = response.res.filter(re => {
              return re.status !== 200;
            });

            if (errorDrivers[0]) {
              this.context.notification(
                'error',
                I18n.t('errors.' + errorDrivers[0].errorCode)
              );
            }
          }
        }

        this.props.loadingBarActions.hideLoadingSpiner();
      });
    this.handleConfirmCloseClick();
  };

  handleConfirmCloseClick = () => {
    this.setState({
      confirm: null
    });
  }

  handleOperationItemClick = data => {
    const { companies, shifts } = this.state;
    const { carType } = this.props;

    let company = data.company
      ? _.filter(companies, company => company._id === data.company._id)
      : [];
    let vehicle = {};
    let vehicles = [];

    if (company[0] && data.vehicle) {
      vehicles = company[0].vehicles;

      if (vehicles[0]._id === '') {
        vehicles.shift();
      }

      _.forEach(vehicles, valueItem => {
        if (valueItem._id === data.vehicle.vehicleId) {
          vehicle = data.vehicle;
          vehicle.vehicleTypeId = valueItem.type ? valueItem.type.typeId : null;
        }
      });
    }

    let vehicleType = _.filter(
      carType,
      type => type._id === vehicle.vehicleTypeId
    );
    let shift = {};
    let newShifts = Object.assign([], shifts);

    if (data.shift && newShifts) {
      newShifts.forEach(valueItem => {
        if (valueItem._id === data.shift._id) {
          shift = data.shift;
        }
      });

      if (newShifts[0]._id === '') {
        newShifts.shift();
      }
    }
    this.setState({
      company: [data.company && data.company._id],
      companyName: company[0] ? company[0].name : '',
      plateNumber: [vehicle.vehicleId],
      plateNumberName: vehicle.plateNumber,
      vehicleType: vehicle.vehicleType,
      vehicleTypeId: vehicle.vehicleTypeId,
      zone: data.zoneId,
      shift: [shift._id],
      shiftName: shift.name,
      vehicles: emptyLicensePlate.concat(vehicles),
      zones: vehicleType[0] ? _.filter(vehicleType[0].zoneId,{'display':true}) : [],
      shifts: newShifts,
      driver: [data.driverId]
    });
  };

  handleUnAssignClick = () => {
    if (!this.state.driver || this.state.driver.length === 0) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_choose_driver')
      );
      return;
    }
    this.setState({
      confirm: {
        title: I18n.t('operationSetting.Unassign'),
        body: I18n.t('operationSetting.CONFIRM_UNASSIGN'),
        buttonTitle: "Yes",
        closeButtonText: "No"
      }
    });
  }

  handleSaveClick = () => {
    if (!this.state.driver || this.state.driver.length === 0) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_choose_driver')
      );
      return;
    }
    if (!this.state.company || this.state.company.length === 0) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_choose_company')
      );
      return;
    }
    if (
      this.props.auth.selectedFleet &&
      !this.props.auth.selectedFleet.licensePlate &&
      (!this.state.plateNumber ||
        this.state.plateNumber.length === 0 ||
        !this.state.plateNumber[0])
    ) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_license_plate')
      );
      return;
    }
    if (
      !this.state.zone ||
      this.state.zone.length === 0 ||
      !this.state.zone[0]
    ) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_choose_zone')
      );
      return;
    }
    if (
      !this.state.shift ||
      this.state.shift.length === 0 ||
      !this.state.shift[0]
    ) {
      this.context.notification(
        'error',
        I18n.t('operationSetting.Please_choose_shift')
      );
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .assginOperation({
        assign: true,
        fleetId: this.props.auth.selectedFleet.fleetId,
        driverIds: this.state.driver,
        vehicleId: this.state.plateNumber[0],
        zoneIds: this.state.zone,
        companyId: this.state.company[0],
        shiftTemplateId: this.state.shift[0]
      })
      .then(data => {
        if (data.ok && data.res) {
          const {
            companies,
            vehicles,
            shifts,
            company
          } = this.state;
          const companyObj = _.find(companies, o => o._id === company[0]);
          const vhcObj = _.find(vehicles, o => o._id === this.state.plateNumber[0])
          const operationObj = {
            company: {
              _id: companyObj._id,
              companyName: companyObj.name
            },
            shift: _.find(shifts, o => o._id === this.state.shift[0]),
            vehicle: {
              plateNumber: vhcObj.plateNumber,
              vehicleId: vhcObj._id,
              vehicleTypeId: vhcObj.type && vhcObj.type.typeId,
            },
            zoneId: this.state.zone
          };

          const successSize = _.filter(data.res, o => o.status === 200);

          _.forEach(successSize, item => {
            const index = _.findIndex(this.state.operations, o => o.driverId === item.driverId);
            const op = Object.assign({}, operationObj, {
              driverId: item.driverId,
              driver: { name: item.driverName }
            });

            if (index > -1) {
              this.state.operations[index] = op;
            } else {
              this.state.operations.unshift(op);
            }
          });

          this.context.notification(
            'success',
            I18n.t('operationSetting.Assign_success')
          );
        } else if (data.error) {
          if (data.error) {
            this.setState({ showDialog: true, dialogData: data.error });
          }
        }
        this.props.loadingBarActions.hideLoadingSpiner();
      });
  }

  closeErrorListDialog = () => this.setState({ showDialog: false });

  renderErrorDataList() {
    return this.state.dialogData &&
      this.state.dialogData.list &&
      this.state.dialogData.list.length > 0
      ? this.state.dialogData.list.map(item => {
        return (
          <tr>
            <td>
              <p>{item.name}</p>
            </td>
            <td>
              <p className="mr-l-20 red">
                {I18n.t('errors.' + this.state.dialogData.errorCode)}
              </p>
            </td>
          </tr>
        );
      })
      : null;
  }

  render() {
    return (
      <div className="content operation">
        {this.state.showDialog ? (
          <Modal onHide={this.closeErrorListDialog} show={true} backdrop={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="generalSetting.Operator_assign_error" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeErrorListDialog}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-no-pagination">
                <tbody>{this.renderErrorDataList()}</tbody>
              </table>
            </Modal.Body>
          </Modal>
        ) : null}
        <Row className="form-row">
          <Col xs={12} md={3}>
            <DriverList onChange={this.handleDriverChange} />
          </Col>
          <Col xs={12} md={3} className="company-select">
            <MultipleSelect
              heightSelect={100}
              value={this.state.company}
              data={this.state.companies}
              name="name"
              title={I18n.t('operationSetting.Company')}
              isRequire={true}
              handleItemChange={this.handleCompanyChange}
              selectCustomClassName={'none-bg-arrow'}
            />
          </Col>
          <Col xs={12} md={3} className="license-select">
            <MultipleSelect
              heightSelect={85}
              value={this.state.plateNumber}
              data={this.state.vehicles}
              isSearchAble={true}
              name="plateNumber"
              searchPlaceHolder={I18n.t(
                'operationSetting.Select_license_plate'
              )}
              title={I18n.t('operationSetting.License_plate')}
              isRequire={
                this.props.auth.selectedFleet &&
                !this.props.auth.selectedFleet.licensePlate
              }
              handleItemChange={this.handleLicensePlateChange}
              handleItemClick={this.handleLicensePlateChange}
              inputCustomClassName={'mb-lg'}
              selectCustomClassName={'none-bg-arrow'}
            />
          </Col>
          <Col xs={12} md={3} className="operation-zone-shift">
            <Row>
              <Col xs={12} md={12}>
                <MultipleSelect
                  className="small"
                  value={this.state.zone}
                  data={this.state.zones}
                  isMultiple={true}
                  name="zoneName"
                  title={I18n.t('operationSetting.Zone')}
                  isRequire={true}
                  handleItemChange={this.handleZoneChange}
                  selectCustomClassName={'none-bg-arrow'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <MultipleSelect
                  className="small"
                  heightSelect={100}
                  value={this.state.shift}
                  data={this.state.shifts}
                  name="name"
                  title={I18n.t('operationSetting.Shift')}
                  isRequire={true}
                  handleItemChange={this.handleShiftChange}
                  selectCustomClassName={'none-bg-arrow'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="text-right">
            <Form.Label>
              (<span className="require">*</span>):{' '}
              <Translate value="operationSetting.Required_fields" />
            </Form.Label>
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className={'btn-save ml-md'}
                onClick={this.handleSaveClick}
              >
                <Translate value="operationSetting.Save" />
              </Button>
            ) : null}
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className={'btn-cancel ml-md'}
                onClick={this.handleUnAssignClick}
              >
                <Translate value="operationSetting.Unassign" />
              </Button>
            ) : null}
          </Col>
        </Row>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Operation.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    mapZone: state.commonData.mapZone,
    drivers: state.commonData.drivers,
    carType: state.commonData.carType,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    assginOperation: options => {
      return dispatch(assginOperation(options));
    },
    unassginOperation: options => {
      return dispatch(unassginOperation(options));
    },
    getOperationCompany: options => {
      return dispatch(getOperationCompany(options));
    },
    getOperationShift: options => {
      return dispatch(getOperationShift(options));
    },
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    fetchOperation: bindActionCreators(fetchOperation, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Operation);
