import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../settings.scss";
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  
  Modal
} from "react-bootstrap";
import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase } from "../../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../../utils/commonFunctions";
import { PartnerTypeColumns } from "./tableHeaderData";
import TableActions from "../../../components/table/tableAction/TableActions";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import _ from "lodash";

class MDispatcherType extends Component {
  constructor() {
    super();
    this.state = {
      MDispatcherTypes: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      editable: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateMDispatcherTypeList = this.updateMDispatcherTypeList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.confirmDeleteMDispatcherType = this.confirmDeleteMDispatcherType.bind(
      this
    );
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
  }
  componentDidMount() {
    this.updateMDispatcherTypeList();
  }

  updateMDispatcherTypeList(activePage, limit) {
    let params = {
        limit: limit || this.state.footerData.limit,
        page: activePage || activePage === 0 ? activePage : this.state.footerData.page,
        fleetId: this.props.auth.selectedFleet.fleetId
      };
    this.props.settingActions
      .findMDispatcherTypes(params)
      .then(data => {
        if (data.ok && data.res) {
            let { page, total, limit } = data.res;
            this.setState({
                MDispatcherTypes: data.res,
              footerData: { page, total, limit }
            });
        }
      });
  }

  saveDialogForm() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    if (!this.state.dialogData.name.trim()) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.dialogData._id) {
      let objectMDispatcherType = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        id: this.state.dialogData._id
      };
      this.props.settingActions
        .updateMDispatcherType(objectMDispatcherType)
        .then(data => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + data.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("mDispatcherType.Update_mDispatcherType_fail")
              );
            }
          } else {
            this.context.notification(
              "success",
              I18n.t("mDispatcherType.Update_mDispatcherType_success")
            );
            this.closeDialogForm();
            this.updateMDispatcherTypeList();
            this.setState({ isSubmited: false });
          }
        });
    } else {
      this.props.settingActions
        .createMDispatcherType(this.state.dialogData)
        .then(data => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + data.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("mDispatcherType.Create_mDispatcherType_fail")
              );
            }
          } else {
            this.context.notification(
              "success",
              I18n.t("mDispatcherType.Create_mDispatcherType_success")
            );
            this.closeDialogForm();
            this.updateMDispatcherTypeList();
            this.setState({ isSubmited: false });
          }
        });
    }
  }
  handleNameChange(e) {
    this.state.dialogData.name = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }

  handleAddButtonClick() {
    let objectComany = {
      name: ""
    };
    this.setState({
      showDialog: true,
      dialogData: objectComany,
      isSubmited: false,
      editable: true
    });
  }
  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false
    });
  }
  handleMenuClick(key, mDispatcherType) {
    switch (key) {
      case "Edit": {
        this.props.settingActions
          .detailsMDispatcherType({
            id: mDispatcherType._id,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: true
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("mDispatcherType.Load_mDispatcherType_fail")
                );
              }
            }
          });
        break;
      }
      case "View": {
        this.props.settingActions
          .detailsMDispatcherType({
            id: mDispatcherType._id,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: false
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("mDispatcherType.Load_mDispatcherType_fail")
                );
              }
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: mDispatcherType });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateMDispatcherTypeList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e);
    this.updateMDispatcherTypeList(0, numItemsPerPage);
  }

  confirmDeleteMDispatcherType() {
    this.props.settingActions
      .deleteMDispatcherType({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.dialogData._id
      })
      .then(data => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateMDispatcherTypeList();
          this.context.notification(
            "success",
            I18n.t("mDispatcherType.Delete_mDispatcherType_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("mDispatcherType.Delete_mDispatcherType_fail")
            );
          }
        }
      });
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], PartnerTypeColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "Name":
          col.customCell = mDispatcherType => {
            return (
                <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", mDispatcherType);
                }}
              >
                {mDispatcherType.name}
              </a>
            );
          };
          break;
        case "actions":
          col.customCell = (mDispatcherType, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "mDispatcherType.Edit",
                  eventKey: "Edit"
                },
                {
                    label: "mDispatcherType.Delete",
                    eventKey: "Delete"
                  }
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={mDispatcherType}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, mDispatcherType);
                  }}
                  totalRow={this.state.MDispatcherTypes.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", mDispatcherType);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="mDispatcherType.View" />
                </a>
              );
            }
          };
          break;
        default:
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };


  render() {
      const bodyData = this.state.MDispatcherTypes &&
      this.state.MDispatcherTypes.list.length > 0 ? this.state.MDispatcherTypes.list : [];
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="mDispatcherType.DELETE_MDISPATCHER_TYPE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="mDispatcherType.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteMDispatcherType}
              >
                <Translate value="mDispatcherType.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="mDispatcherType.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        {this.state.showDialog ? (
          <Modal show={true} backdrop={true} dialogClassName={"modal-max-600"} onHide={this.closeDialogForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="mDispatcherType.EDIT_MDISPATCHER_TYPE" />
                  ) : (
                    <Translate value="mDispatcherType.DETAIL_MDISPATCHER_TYPE" />
                  )
                ) : (
                  <Translate value="mDispatcherType.ADD_MDISPATCHER_TYPE" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormGroup
                className={
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.name
                      ? null
                      : "error"
                }
              >
                <Form.Label>
                  <Translate value="mDispatcherType.Name" />{" "}<span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  onChange={this.handleNameChange}
                  value={
                    this.state.dialogData ? this.state.dialogData.name : ""
                  }
                  placeholder={I18n.t("mDispatcherType.Name")}
                  disabled={!this.state.editable}
                />
                <Validator id="name" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={
                      !Validation.isStringEmpty(this.state.dialogData.name)
                    }
                    message={I18n.t("messages.commonMessages.Required_field")}
                  />
                </Validator>
              </FormGroup>
              <Form.Label>
                (<span className="require">*</span>
                ): <Translate value="mDispatcherType.Required_fields" />
              </Form.Label>
            </Modal.Body>
            <Modal.Footer>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={this.saveDialogForm}
                    disabled={!CCLiteCommonFunc.isFormValid(this.state.valid)}
                  >
                    <Translate value="mDispatcherType.Save" />
                  </Button>
                ) : (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={e => {
                      this.handleMenuClick("changeStatus", e);
                    }}
                  >
                    <Translate value="carSetting.Edit" />
                  </Button>
                )
              ) : null}
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="mDispatcherType.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              className="btn-header text-add-header ml0"
              onClick={this.handleAddButtonClick}
            >
              {" "}
              
              <Translate value="mDispatcherType.Add" />
            </Button>
          </ButtonToolbar>
        ) : (
          ""
        )}

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

MDispatcherType.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MDispatcherType);
