import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';

export const fields = [
  {
    label: 'report.result.companySettlementDetails.bookId',
    key: 'bookId',
  },
  {
    label: 'report.result.companySettlementDetails.dateTime',
    key: 'dateTime',
  },
  {
    label: 'report.result.companySettlementDetails.originalPaymentStatus',
    key: 'originalPaymentStatus',
    mutate: (value) => I18n.t(`report.query.paymentStatusItem.${value}Payment`),
  },
  {
    label: 'report.result.companySettlementDetails.subTotal',
    key: 'subTotal',
  },
  {
    label: 'report.result.companySettlementDetails.fleetAmount',
    key: 'fleetAmount',
  },
  {
    label: 'report.result.companySettlementDetails.driverAmount',
    key: 'driverAmount',
  },
  {
    label: 'report.result.companySettlementDetails.companyAmount',
    key: 'companyAmount',
    negativable: true
  },
  {
    label: 'report.result.companySettlementDetails.companyOweFleet',
    key: 'companyOweFleet',
    summary: 'companyOweFleet',
    negativable: true

  },
  {
    label: 'report.result.companySettlementDetails.fleetOweCompany',
    key: 'fleetOweCompany',
    summary: 'fleetOweCompany',
  },
  {
    label: 'report.result.companySettlementDetails.notes',
    key: 'notes',
  },
];

export const fareFields = ['subTotal', 'fleetAmount', 'driverAmount', 'companyAmount', 'companyOweFleet', 'fleetOweCompany'];
