import {
  getAutocompleteAPI,
  getDistanceMatrixAPI,
  getDirectionsAPI,
  getPlaceDetailAPI,
  getReverseGeoCodingAPI,
  getTimezoneAPI,
  getGeoCodingAPI,
  getDirectionFromJupiterAPI,
} from '../constants/ApiConfigs';

import { callApi, callApiWithoutDispatch, UrlBuilder } from '../utils/apiUtils';

export function getAutocompleted(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getAutocompleteAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}

export function getDistanceMatrixAction(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getDistanceMatrixAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}

export function getPlaceDetail(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getPlaceDetailAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}

export function getDirections(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getDirectionsAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}


export function getDirectionFromJupiter(options) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApiWithoutDispatch(getDirectionFromJupiterAPI, config)
}

export function getReverseGeoCoding(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getReverseGeoCodingAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}

export function getGeoCoding(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getGeoCodingAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}

export function getTimezone(options) {
  const config = {
    method: 'GET',
  };
  return callApiWithoutDispatch(
    UrlBuilder(getTimezoneAPI, options),
    config,
    null,
    null,
    null,
    true
  );
}
