import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import {
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
  Image,
} from 'react-bootstrap';
import { UploadIcon } from '@icons/material';
import PropTypes from 'prop-types';
import { filter, map, cloneDeep, forEach, remove } from 'lodash';
import { MoonLoader, RingLoader, BounceLoader } from 'react-spinners';
import SyncRing from '../../assets/images/icons/SyncRing.svg';
import CustomMenu from './CustomMenu';
import CcCheckbox from '../ccCheckbox/CcCheckbox';
import { socketConfigs } from '../../constants/socketConfigs';
import {
  thirdPartyAction,
  thirdPartyIntegration,
} from '../../constants/commondata';
import { socketDispatchApi } from '../../utils/socketUtils';
import QuestionCircleTooltip from '../questionCircleTooltip/QuestionCircleTooltip';
import RearrangeColumn from './RearrangeColumn';
import close_icon from '../../assets/images/icons/close_icon.svg';
import './style.scss';
import moment from 'moment';
import { getSuppilerPermission } from '../../utils/commonFunctions';
import { isMobile } from 'react-device-detect';

const SYNC_STATUS = {
  none: 'none',
  synced: 'synced',
  syncing: 'syncing',
};
class ccDropDownCueAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pullRight: false,
      sync: false,
      thirdBookingComInfo: {
        latestUpdate: '',
        syncStatus: SYNC_STATUS.none,
        clientKey: '',
      },
      thirdHolidayTaxisInfo: {
        latestUpdate: '',
        syncStatus: SYNC_STATUS.none,
      },
    };
  }

  getTitle = () => {
    const {
      selectedItems,
      title,
      items,
      valueKey,
      labelKey,
      hasAll,
      multiSelect,
      onlyShowAllText,
      noTranslateLabel,
      hasSelectInitial,
    } = this.props;
    if (!selectedItems || !selectedItems.length) return I18n.t(title);
    if (
      multiSelect &&
      selectedItems.length === items.length &&
      onlyShowAllText &&
      !hasAll
    ) {
      return title
        ? I18n.t(title) + ': ' + I18n.t('General.ALL')
        : I18n.t('General.ALL');
    }
    let selected = filter(items, (item) => {
      if (Array.isArray(selectedItems)) {
        return selectedItems.indexOf(item[valueKey]) !== -1;
      } else {
        return selectedItems === item[valueKey];
      }
    });
    let text = map(selected, (item) =>
      !noTranslateLabel && item[labelKey]
        ? I18n.t(item[labelKey]) || item[labelKey]
        : item[labelKey] || ''
    ).join(', ');
    if (!multiSelect && title) text = I18n.t(title) + ': ' + text;
    if (hasSelectInitial) return `${I18n.t(title)}: ${text}`;
    return text;
  };

  componentWillUnmount = () => {
    socketDispatchApi.remove(socketConfigs.receive?.booking?.bookingSynced);
  };

  // handle click on menuitem
  // this function callback onSelect function with list of selected eventKey
  handleSelectItem = (eventKey, event, clearAll) => {
    const {
      selectedItems,
      isNumber,
      onSelect,
      multiSelect,
      returnOnSelect,
      items,
      valueKey,
      enableAllItemSelect,
    } = this.props;

    if (clearAll) return onSelect(eventKey);

    let value = isNumber ? parseInt(eventKey) : eventKey;

    if (!multiSelect) {
      if (selectedItems[0] === value) return true;
      return onSelect(value);
    }

    // tra ve option vua click (co the duoc chon hoac khong)
    if (returnOnSelect) return onSelect(value);

    let tmpSelected = cloneDeep(selectedItems);
    let isExist = selectedItems.indexOf(value) !== -1;

    if (enableAllItemSelect && value === 'all_item_auto_render') {
      if (selectedItems.length === items.length) {
        // bo chon tat ca
        let keys = [],
          disableSelectItems = filter(items, (item) => item.disableSelect);
        if (disableSelectItems.length) {
          forEach(disableSelectItems, (item) => {
            if (selectedItems.includes(item[valueKey])) {
              keys.push(item[valueKey]);
            }
          });
        }
        return onSelect(keys);
      } else {
        // chon tat ca
        let allKeys = map(items, (item) => item[valueKey]);
        return onSelect(allKeys);
      }
    }

    if (isExist) {
      remove(tmpSelected, (item) => item === value);
    } else {
      tmpSelected.push(value);
    }

    onSelect(tmpSelected);
  };

  getPartyBookingComIntegration = (type) => {
    this.props.settingActions
      .get3rdPartyIntegration({
        fleetId: this.props.fleetId,
        thirdParty: type,
      })
      .then((response) => {
        const data = response.res || {};
        if (data.integration) {
          if (type === thirdPartyIntegration.bookingAPI) {
            this.setState({
              thirdBookingComInfo: {
                latestUpdate: data.integration?.latestUpdate || '',
                syncStatus: data.integration?.syncStatus || 'none',
                clientKey: data.integration?.clientKey || '',
              },
            });
          }
          if (type === thirdPartyIntegration.holidaytaxis) {
            this.setState({
              thirdHolidayTaxisInfo: {
                latestUpdate: data.integration?.latestUpdate || '',
                syncStatus: data.integration?.syncStatus || 'none',
              },
            });
          }
        }
      });
  };

  handleToggleClick = (open, event, source) => {
    if (this.props.hasStopPropagation) {
      event.stopPropagation();
    }
    if (open && this.props.isEnable3rdBookingCom) {
      this.getPartyBookingComIntegration(thirdPartyIntegration.bookingAPI);
    }
    if (open && this.props.isEnable3rdHolidayTaxis) {
      this.getPartyBookingComIntegration(thirdPartyIntegration.holidaytaxis);
    }
    if (this.props.open) return;
    if (source && source.source === 'clearAllItem') {
      return this.setState({ open: false });
    } else {
      if (
        this.props.multiSelect &&
        source.source !== 'rootClose' &&
        this.state.open
      ) {
        return true;
      }
      if (!this.state.open) {
        let windowWidth = window.innerWidth;
        let domRect = event.currentTarget.getBoundingClientRect();
        let pullRight = false;
        if (windowWidth - domRect.left < 265) pullRight = true;
        this.setState({ open: true, pullRight });
      } else {
        this.setState({ open: false });
      }
    }
  };

  onSocketDispatch = () => {
    const sk = socketConfigs.receive?.booking?.bookingSynced;
    socketDispatchApi.remove(sk);
    socketDispatchApi.on(sk, (data) => {
      this.props.context.notification(
        'success',
        I18n.t('cue.syncingCompleted')
      );
      this.getPartyBookingComIntegration(data.thirdParty);
    });
  };

  syncBooking = (type) => {
    this.onSocketDispatch();
    this.setState(
      {
        thirdBookingComInfo: {
          ...this.state.thirdBookingComInfo,
          syncStatus:
            type === thirdPartyIntegration.bookingAPI
              ? SYNC_STATUS.syncing
              : this.state.thirdBookingComInfo.syncStatus,
        },
        thirdHolidayTaxisInfo: {
          ...this.state.thirdHolidayTaxisInfo,
          syncStatus:
            type === thirdPartyIntegration.holidaytaxis
              ? SYNC_STATUS.syncing
              : this.state.thirdHolidayTaxisInfo.syncStatus,
        },
      },
      () => {
        this.props.cueActions
          .syncThirdPartyBooking({
            fleetId: this.props.fleetId,
            thirdParty: type,
          })
          .then((data) => {
            if (data?.returnCode === 200) {
              return;
            } else {
              if (data?.returnCode === 400) {
                this.props.context.notification('error', I18n.t(`Syncing`));
                return;
              }
              if (data?.code === 'NOT_FOUND' && data?.message) {
                this.props.context.notification(
                  'error',
                  I18n.t(`API key not found`)
                );
              } else {
                this.props.context.notification(
                  'error',
                  I18n.t(`Something went wrong`)
                );
              }
              this.setState({
                thirdBookingComInfo: {
                  ...this.state.thirdBookingComInfo,
                  syncing:
                    type === thirdPartyIntegration.bookingAPI
                      ? SYNC_STATUS.none
                      : this.state.thirdBookingComInfo.syncStatus,
                },
                thirdHolidayTaxisInfo: {
                  ...this.state.thirdHolidayTaxisInfo,
                  syncStatus:
                    type === thirdPartyIntegration.holidaytaxis
                      ? SYNC_STATUS.none
                      : this.state.thirdHolidayTaxisInfo.syncStatus,
                },
              });
            }
          });
      }
    );
  };

  renderToolTip = (nameThird) => {
    let syncTxt = '',
      manualTxt = '',
      dailyTxt = '';
    if (nameThird === thirdPartyIntegration.bookingAPI) {
      syncTxt = (
        <p>
          <span className="tooltipSync">Weekly Sync:</span>{' '}
          {`Our system syncs with Booking.com weekly to keep all booking information up-to-date.`}
        </p>
      );
      manualTxt = (
        <p>
          <span className="tooltipSync">Manual Sync:</span> Click refresh button
          fetches the most recent bookings from the current moment until the end
          of the day. Useful for instant updates!
        </p>
      );
    }
    if (nameThird === thirdPartyIntegration.holidaytaxis) {
      syncTxt = (
        <p>
          <span className="tooltipSync">Hourly Sync:</span>{' '}
          {`Our system syncs with HolidayTaxis hourly to keep all booking information up-to-date for the upcoming week.`}
        </p>
      );
      manualTxt = (
        <p>
          <span className="tooltipSync">Manual Sync:</span> Clicking the refresh
          button fetches the most recent bookings from the current moment until
          the end of the day for the next week. This feature is useful for
          instant updates!
        </p>
      );
      dailyTxt = (
        <p>
          <span className="tooltipSync">Daily Sync:</span> Similarly, we perform
          a daily sync to retrieve one month's worth of bookings, ensuring
          comprehensive data accuracy and coverage.
        </p>
      );
    }
    return (
      <div>
        <p>
          <span className="tooltipSync">
            Understanding Our Syncing Process:
          </span>
        </p>
        {manualTxt}
        {syncTxt}
        {dailyTxt}
      </div>
    );
  };

  renderCueAction = () => {
    const {
      isEnable3rdBookingCom,
      isEnable3rdHolidayTaxis,
      hasShowUploadClick,
      handleUploadClick,
      disabled,
      selectedFleet,
      auth,
    } = this.props;
    const { thirdBookingComInfo, thirdHolidayTaxisInfo } = this.state;
    const isPermissionCompany = getSuppilerPermission(auth);
    const disableUpdateBookings =
      disabled ||
      thirdBookingComInfo.syncStatus === SYNC_STATUS.syncing ||
      thirdHolidayTaxisInfo.syncStatus === SYNC_STATUS.syncing;
    if (!hasShowUploadClick && !isEnable3rdBookingCom) return null;
    return (
      <div className="cueAction">
        <h3>{I18n.t('cue.moreActions')}</h3>
        {isEnable3rdBookingCom &&
          thirdBookingComInfo.clientKey &&
          !isPermissionCompany && (
            <div className="syncAction childCueAction">
              <div className="syncStatus">
                <span onClick={() => this.setState({ open: false })}>
                  Booking.com
                  <QuestionCircleTooltip
                    text={this.renderToolTip(thirdPartyIntegration.bookingAPI)}
                    delayShow={300}
                    delayHide={150}
                    placement={'bottom'}
                  />
                  {/* <i className={'fa fa-question-circle mr-l-5 '} />
                </QuestionCircleTooltip> */}
                </span>
                <span className={`${thirdBookingComInfo.syncStatus}`}>
                  {thirdBookingComInfo.syncStatus === SYNC_STATUS.synced &&
                    `Last sync: ${moment
                      .tz(
                        thirdBookingComInfo.latestUpdate || '',
                        selectedFleet.timezone
                      )
                      .format('MMM DD, YYYY hh:mm A')}`}
                  {thirdBookingComInfo.syncStatus === SYNC_STATUS.syncing &&
                    'Sync in progress'}
                </span>
              </div>
              <div className="syncButton">
                <Button
                  className="btn-upload-bookings"
                  onClick={() =>
                    this.syncBooking(thirdPartyIntegration.bookingAPI)
                  }
                  disabled={
                    thirdBookingComInfo.syncStatus === SYNC_STATUS.syncing
                  }
                >
                  {thirdBookingComInfo.syncStatus === SYNC_STATUS.syncing ? (
                    <BounceLoader
                      loading={true}
                      color={'#04BE76'}
                      size={35}
                      cssOverride={{
                        // position: 'absolute',
                        top: '0px',
                      }}
                    />
                  ) : (
                    <img src={SyncRing} />
                  )}
                </Button>
              </div>
            </div>
          )}
        {isEnable3rdHolidayTaxis && !isPermissionCompany && (
          <div className="syncAction childCueAction">
            <div className="syncStatus">
              <span onClick={() => this.setState({ open: false })}>
                HolidayTaxis
                <QuestionCircleTooltip
                  text={this.renderToolTip(thirdPartyIntegration.holidaytaxis)}
                  delayShow={300}
                  delayHide={150}
                  placement={'bottom'}
                />
              </span>
              <span className={`${thirdHolidayTaxisInfo.syncStatus}`}>
                {thirdHolidayTaxisInfo.syncStatus === SYNC_STATUS.synced &&
                  `Last sync: ${moment
                    .tz(
                      thirdHolidayTaxisInfo.latestUpdate || '',
                      selectedFleet.timezone
                    )
                    .format('MMM DD, YYYY hh:mm A')}`}
                {thirdHolidayTaxisInfo.syncStatus === SYNC_STATUS.syncing &&
                  'Sync in progress'}
              </span>
            </div>
            <div className="syncButton">
              <Button
                className="btn-upload-bookings"
                onClick={() =>
                  this.syncBooking(thirdPartyIntegration.holidaytaxis)
                }
                disabled={
                  thirdHolidayTaxisInfo.syncStatus === SYNC_STATUS.syncing
                }
              >
                {thirdHolidayTaxisInfo.syncStatus === SYNC_STATUS.syncing ? (
                  <BounceLoader
                    loading={true}
                    color={'#04BE76'}
                    size={35}
                    cssOverride={{
                      top: '0px',
                    }}
                  />
                ) : (
                  <img src={SyncRing} />
                )}
              </Button>
            </div>
          </div>
        )}
        {hasShowUploadClick && !isMobile && (
          <div className="childCueAction">
            <Button
              className={
                disableUpdateBookings
                  ? 'disabled btn-upload-bookings'
                  : 'btn-upload-bookings'
              }
              onClick={handleUploadClick}
              disabled={disableUpdateBookings}
            >
              <UploadIcon />
              <Translate
                value="cue.uploadBookings"
                className="collapse-filter-title"
              />
            </Button>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      id,
      items,
      multiSelect,
      labelKey,
      valueKey,
      enableClearAll,
      selectedItems,
      searchable,
      pullRight,
      customTitle,
      className,
      enableAllItemSelect,
      async,
      onScrollBottom,
      asyncDelay,
      onSearchChange,
      disabled,
      noTranslateLabel,
      applyButton,
      footer,
      customLabel,
      hasActionBtn,
      actionBtnFunc,
      textActionBtn,
      isEnable3rdBookingCom,
      isEnable3rdHolidayTaxis,
    } = this.props;
    let menuItems = Object.assign([], items);
    if (enableAllItemSelect && multiSelect)
      menuItems.unshift({
        [valueKey]: 'all_item_auto_render',
        [labelKey]: 'cue.All',
      });

    if (isMobile && !isEnable3rdBookingCom && !isEnable3rdHolidayTaxis) {
      return null;
    }

    return (
      <Dropdown
        id={id}
        rootCloseEvent={'click'}
        className={
          'cc-drop-down cueActionDropDown ' + (className ? className : '')
        }
        show={this.props.open ? true : this.state.open}
        onToggle={(open, event, source) =>
          this.handleToggleClick(open, event, source)
        }
        disabled={disabled}
        pullRight={pullRight || this.state.pullRight}
      >
        <Dropdown.Toggle>
          {customTitle ? (
            customTitle
          ) : (
            <OverlayTrigger
              overlay={<Tooltip id={'tooltip'}>{this.getTitle()}</Tooltip>}
              placement="top"
              delayShow={300}
              delayHide={150}
            >
              <span className="dropdown-title">{this.getTitle()}</span>
            </OverlayTrigger>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
        // flip={false}
        >
          <CustomMenu
            multiSelect={multiSelect}
            enableClearAll={enableClearAll}
            totalSelect={selectedItems.length}
            items={menuItems}
            labelKey={labelKey}
            valueKey={valueKey}
            searchable={searchable}
            onSelect={this.handleSelectItem}
            async={async}
            onScrollBottom={onScrollBottom}
            asyncDelay={asyncDelay}
            onSearchChange={onSearchChange}
            applyButton={applyButton}
            footer={footer}
            actionBtnFunc={actionBtnFunc}
          >
            <Image
              className="remove-icon"
              src={close_icon}
              onClick={() => {
                this.setState({ open: false });
              }}
            />
            {this.renderCueAction()}
            {!isMobile && (
              <div className="sortCueAction">
                <p className="titleSort">{I18n.t('cue.DragAndDrop')}</p>
              </div>
            )}
            {!isMobile && enableAllItemSelect && multiSelect && (
              <Dropdown.Item
                eventKey={'all_item_auto_render'}
                className="all-item-auto-added"
              >
                <CcCheckbox
                  checked={selectedItems.length === items.length}
                  value="all_item_auto_render"
                  text={I18n.t('cue.All')}
                  onChange={() => {}}
                  className="dropdown-selectbox selectbox-all"
                  labelClassName="text"
                />
              </Dropdown.Item>
            )}
            {!isMobile && (
              <RearrangeColumn
                column={items}
                valueKey={valueKey}
                labelKey={labelKey}
                onSelect={this.handleSelectItem}
                customLabel={customLabel}
                noTranslateLabel={noTranslateLabel}
                selectedItems={selectedItems}
                multiSelect={multiSelect}
                handleSaveRearrangeColumn={this.props.handleSaveRearrangeColumn}
              />
            )}
          </CustomMenu>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

ccDropDownCueAction.propTypes = {
  items: PropTypes.array.isRequired,
  multiSelect: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  hasAll: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  searchable: PropTypes.bool,
  isNumber: PropTypes.bool,
  onSelect: PropTypes.func,
  enableClearAll: PropTypes.bool,
  returnOnSelect: PropTypes.bool,
  pullRight: PropTypes.bool,
  customTitle: PropTypes.any,
  className: PropTypes.string,
  enableAllItemSelect: PropTypes.bool, // tu dong them item All,
  onlyShowAllText: PropTypes.bool,
  async: PropTypes.bool,
  onScrollBottom: PropTypes.func,
  asyncDelay: PropTypes.number,
  onSearchChange: PropTypes.func,
  disabled: PropTypes.bool,
  noTranslateLabel: PropTypes.bool,
  applyButton: PropTypes.func,
  footer: PropTypes.bool,
  customLabel: PropTypes.func, // custom label for item
};

export default ccDropDownCueAction;
