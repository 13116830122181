import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  InputGroup,
  Button,
  Radio,
  ButtonToolbar,
  DropdownButton,
  
  Modal,
  Form
} from "react-bootstrap";
import { Validator, ValidCase } from "../../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../../utils/commonFunctions";
import * as settingActions from "./../../../actions/settingActions";
import * as loadingBarActions from "./../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import TableHeader from "../../../components/table/TableHeader";
import { ShiftColumnsColumns } from "./tableHeaderData";
import TableActions from "../../../components/table/tableAction/TableActions";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import _ from "lodash";

class ShiftSetting extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      datalist: [],
      shiftSettings: {
        limit: 20,
        list: [],
        page: 0,
        total: 1
      },
      showDialogModal: false,
      isSubmited: false,
      dialogData: {},
      editable: false,
      showConfirm: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.openFormModal = this.openFormModal.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.updateShiftSettingsList = this.updateShiftSettingsList.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTimeRangeFromHourChange = this.handleTimeRangeFromHourChange.bind(
      this
    );
    this.handleTimeRangeFromMinChange = this.handleTimeRangeFromMinChange.bind(
      this
    );
    this.handleTimeRangeToHourChange = this.handleTimeRangeToHourChange.bind(
      this
    );
    this.handleTimeRangeToMinChange = this.handleTimeRangeToMinChange.bind(
      this
    );
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);

    this.confirmDeleteShiftSetting = this.confirmDeleteShiftSetting.bind(this);
  }

  componentDidMount() {
    this.updateShiftSettingsList();
  }

  updateShiftSettingsList(activePage, limit) {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId
    };
    this.props.settingActions.findShiftSettings(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          shiftSettings: data.res,
          footerData: { page, total, limit }
        });
      }
    });
  }

  handleMenuClick(key, obj) {
    let id = obj._id;
    switch (key) {
      case "Edit": {
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions
          .detailsShiftSettings({
            fleetId,
            id
          })
          .then(data => {
            if (data.ok) {
              let timeRangeHourFrom = "00";
              let timeRangeMinFrom = "00";
              let timeRangeHourTo = "00";
              let timeRangeMinTo = "00";
              let result = data.res;
              let timeRangeFromSplit = result.timeRangeFrom.split(":");
              let timeRangToSplit = result.timeRangeTo.split(":");
              if (timeRangeFromSplit && timeRangeFromSplit.length > 1) {
                timeRangeHourFrom = timeRangeFromSplit[0];
                timeRangeMinFrom = timeRangeFromSplit[1];
              }
              if (timeRangToSplit && timeRangToSplit.length > 1) {
                timeRangeHourTo = timeRangToSplit[0];
                timeRangeMinTo = timeRangToSplit[1];
              }
              this.setState({
                dialogData: {
                  _id: id,
                  name: result.name,
                  timeRangeHourFrom,
                  timeRangeMinFrom,
                  timeRangeHourTo,
                  timeRangeMinTo
                },
                editable: true
              });
              this.openFormModal();
            }
          });
        break;
      }
      case "View": {
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions
          .detailsShiftSettings({
            fleetId,
            id
          })
          .then(data => {
            if (data.ok) {
              let timeRangeHourFrom = "00";
              let timeRangeMinFrom = "00";
              let timeRangeHourTo = "00";
              let timeRangeMinTo = "00";
              let result = data.res;
              let timeRangeFromSplit = result.timeRangeFrom.split(":");
              let timeRangToSplit = result.timeRangeTo.split(":");
              if (timeRangeFromSplit && timeRangeFromSplit.length > 1) {
                timeRangeHourFrom = timeRangeFromSplit[0];
                timeRangeMinFrom = timeRangeFromSplit[1];
              }
              if (timeRangToSplit && timeRangToSplit.length > 1) {
                timeRangeHourTo = timeRangToSplit[0];
                timeRangeMinTo = timeRangToSplit[1];
              }
              this.setState({
                dialogData: {
                  _id: id,
                  name: result.name,
                  timeRangeHourFrom,
                  timeRangeMinFrom,
                  timeRangeHourTo,
                  timeRangeMinTo
                },
                editable: false
              });
              this.openFormModal();
            }
          });
        break;
      }

      case "Delete": {
        this.setState({ showConfirm: true, dialogData: obj });
        break;
      }

      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateShiftSettingsList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e);
    this.updateShiftSettingsList(0, numItemsPerPage);
  }

  openFormModal() {
    this.setState({ showDialogModal: true });
  }

  closeFormModal() {
    this.setState({
      showDialogModal: false,
      editable: false,
      showConfirm: false
    });
  }

  handleAddButtonClick() {
    this.openFormModal();
    this.setState({
      isSubmited: false,
      editable: true,
      dialogData: {
        timeRangeHourFrom: "00",
        timeRangeMinFrom: "00",
        timeRangeHourTo: "00",
        timeRangeMinTo: "00"
      }
    });
  }

  saveDialogForm() {
    const { dialogData, valid } = this.state;
    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) return;

    const { auth, settingActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let name = dialogData.name;
    let timeRangeFrom =
      dialogData.timeRangeHourFrom + ":" + dialogData.timeRangeMinFrom;
    let timeRangeTo =
      dialogData.timeRangeHourTo + ":" + dialogData.timeRangeMinTo;
    loadingBarActions.showLoadingSpiner();
    if (dialogData._id && dialogData._id.length > 0) {
      settingActions
        .updateShiftSettings({
          fleetId,
          name,
          timeRangeFrom,
          timeRangeTo,
          id: dialogData._id
        })
        .then(data => {
          loadingBarActions.hideLoadingSpiner();
          if (!data.error && data.ok) {
            this.updateShiftSettingsList();
            this.setState({ isSubmited: false });
            this.context.notification(
              "success",
              I18n.t("shiftSettings.Update_shift_settings_success")
            );
            this.closeFormModal();
          } else {
            if (data.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + data.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("shiftSettings.Update_shift_settings_fail")
              );
            }
          }
        });
    } else {
      settingActions
        .createShiftSettings({ fleetId, name, timeRangeFrom, timeRangeTo })
        .then(data => {
          loadingBarActions.hideLoadingSpiner();
          if (!data.error && data.ok) {
            this.updateShiftSettingsList();
            this.setState({ isSubmited: false });
            this.context.notification(
              "success",
              I18n.t("shiftSettings.Create_shift_settings_success")
            );
            this.closeFormModal();
          } else {
            if (data.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + data.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("shiftSettings.Create_shift_settings_fail")
              );
            }
          }
        });
    }
  }

  handleNameChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        name: e.target.value
      }
    });
  }

  handleTimeRangeFromHourChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        timeRangeHourFrom: e.target.value
      }
    });
  }

  handleTimeRangeFromMinChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        timeRangeMinFrom: e.target.value
      }
    });
  }

  handleTimeRangeToHourChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        timeRangeHourTo: e.target.value
      }
    });
  }

  handleTimeRangeToMinChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        timeRangeMinTo: e.target.value
      }
    });
  }

  // handleDeleteShiftSettingsClick(obj) {
  //     this.setState({ showConfirm: true, dialogData: obj });
  // }

  confirmDeleteShiftSetting() {
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let id = this.state.dialogData._id;
    settingActions
      .deleteShiftSettings({
        fleetId,
        id
      })
      .then(data => {
        if (!data.error && data.ok) {
          this.closeFormModal();
          this.updateShiftSettingsList();
          this.context.notification(
            "success",
            I18n.t("shiftSettings.Delete_shift_settings_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("shiftSettings.Delete_shift_settings_fail")
            );
          }
        }
      });
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], ShiftColumnsColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "Name":
          col.customCell = shiftSettings => {
            return (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", shiftSettings);
                }}
              >
                {shiftSettings.name}
              </a>
            );
          };
          break;
        case "Time_Range":
          col.customCell = shiftSettings => {
            return (
              <span>
                {shiftSettings.timeRangeFrom +
                  " - " +
                  shiftSettings.timeRangeTo}
              </span>
            );
          };
          break;

        case "actions":
          col.customCell = (shiftSettings, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "shiftSettings.Edit",
                  eventKey: "Edit"
                }
              ];

              if (!shiftSettings.isInUse) {
                actionItems.push({
                  label: "shiftSettings.Delete",
                  eventKey: "Delete"
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={shiftSettings}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, shiftSettings);
                  }}
                  totalRow={this.state.shiftSettings.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", shiftSettings);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="shiftSettings.View" />
                </a>
              );
            }
          };
          break;
        default:
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    const bodyData =
      this.state.shiftSettings && this.state.shiftSettings.list.length > 0
        ? this.state.shiftSettings.list
        : [];
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeFormModal} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="shiftSettings.DELETE_SHIFT_SETTING" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeFormModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="shiftSettings.Delete_confirm_shift_setting" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteShiftSetting}
              >
                <Translate value="shiftSettings.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeFormModal}>
                <Translate value="shiftSettings.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        <Modal
          onHide={this.closeFormModal}
          show={this.state.showDialogModal}
          dialogClassName={"custom-modal modal-max-600"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.dialogData._id ? (
                this.state.editable ? (
                  <Translate value="shiftSettings.EDIT_SHIFT_SETTING" />
                ) : (
                  <Translate value="shiftSettings.DETAIL_SHIFT_SETTING" />
                )
              ) : (
                <Translate value="shiftSettings.ADD_SHIFT_SETTING" />
              )}
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeFormModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.name
                    ? null
                    : "error"
              }
            >
              <Form.Label>
                <Translate value="shiftSettings.Name" />{" "}<span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                onChange={this.handleNameChange}
                value={this.state.dialogData ? this.state.dialogData.name : ""}
                placeholder={I18n.t("shiftSettings.Name")}
                disabled={!this.state.editable}
              />
              <Validator id="name" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                  message={I18n.t("messages.commonMessages.Required_field")}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="shiftSettings.Time_Range" />{" "}<span className="require">*</span>
              </Form.Label>
              <Row>
                <Col md={6}>
                  <Form inline>
                    <FormGroup>
                      <Form.Label className={"mr-md"}>
                        <Translate value="shiftSettings.From" />{" "}
                      </Form.Label>
                      <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeFromHourChange}
                        value={
                          this.state.dialogData.timeRangeHourFrom
                            ? this.state.dialogData.timeRangeHourFrom
                            : "00"
                        }
                        disabled={!this.state.editable}
                      >
                        <option value={"00"}>00</option>
                        <option value={"01"}>01</option>
                        <option value={"02"}>02</option>
                        <option value={"03"}>03</option>
                        <option value={"04"}>04</option>
                        <option value={"05"}>05</option>
                        <option value={"06"}>06</option>
                        <option value={"07"}>07</option>
                        <option value={"08"}>08</option>
                        <option value={"09"}>09</option>
                        <option value={"10"}>10</option>
                        <option value={"11"}>11</option>
                        <option value={"12"}>12</option>
                        <option value={"13"}>13</option>
                        <option value={"14"}>14</option>
                        <option value={"15"}>15</option>
                        <option value={"16"}>16</option>
                        <option value={"17"}>17</option>
                        <option value={"18"}>18</option>
                        <option value={"19"}>19</option>
                        <option value={"20"}>20</option>
                        <option value={"21"}>21</option>
                        <option value={"22"}>22</option>
                        <option value={"23"}>23</option>
                      </FormControl>
                      <Form.Label className={"mr-sm ml-sm"}>:</Form.Label>
                      <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeFromMinChange}
                        value={
                          this.state.dialogData.timeRangeMinFrom
                            ? this.state.dialogData.timeRangeMinFrom
                            : "00"
                        }
                        disabled={!this.state.editable}
                      >
                        <option value={"00"}>00</option>
                        <option value={"01"}>01</option>
                        <option value={"02"}>02</option>
                        <option value={"03"}>03</option>
                        <option value={"04"}>04</option>
                        <option value={"05"}>05</option>
                        <option value={"06"}>06</option>
                        <option value={"07"}>07</option>
                        <option value={"08"}>08</option>
                        <option value={"09"}>09</option>
                        <option value={"10"}>10</option>
                        <option value={"11"}>11</option>
                        <option value={"12"}>12</option>
                        <option value={"13"}>13</option>
                        <option value={"14"}>14</option>
                        <option value={"15"}>15</option>
                        <option value={"16"}>16</option>
                        <option value={"17"}>17</option>
                        <option value={"18"}>18</option>
                        <option value={"19"}>19</option>
                        <option value={"20"}>20</option>
                        <option value={"21"}>21</option>
                        <option value={"22"}>22</option>
                        <option value={"23"}>23</option>
                        <option value={"24"}>24</option>
                        <option value={"25"}>25</option>
                        <option value={"26"}>26</option>
                        <option value={"27"}>27</option>
                        <option value={"28"}>28</option>
                        <option value={"29"}>29</option>
                        <option value={"30"}>30</option>
                        <option value={"31"}>31</option>
                        <option value={"32"}>32</option>
                        <option value={"33"}>33</option>
                        <option value={"34"}>34</option>
                        <option value={"35"}>35</option>
                        <option value={"36"}>36</option>
                        <option value={"37"}>37</option>
                        <option value={"38"}>38</option>
                        <option value={"39"}>39</option>
                        <option value={"40"}>40</option>
                        <option value={"41"}>41</option>
                        <option value={"42"}>42</option>
                        <option value={"43"}>43</option>
                        <option value={"44"}>44</option>
                        <option value={"45"}>45</option>
                        <option value={"46"}>46</option>
                        <option value={"47"}>47</option>
                        <option value={"48"}>48</option>
                        <option value={"49"}>49</option>
                        <option value={"50"}>50</option>
                        <option value={"51"}>51</option>
                        <option value={"52"}>52</option>
                        <option value={"53"}>53</option>
                        <option value={"54"}>54</option>
                        <option value={"55"}>55</option>
                        <option value={"56"}>56</option>
                        <option value={"57"}>57</option>
                        <option value={"58"}>58</option>
                        <option value={"59"}>59</option>
                      </FormControl>
                    </FormGroup>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form inline>
                    <FormGroup>
                      <Form.Label className={"mr-md"}>
                        <Translate value="shiftSettings.To" />{" "}
                      </Form.Label>
                      <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeToHourChange}
                        value={
                          this.state.dialogData.timeRangeHourTo
                            ? this.state.dialogData.timeRangeHourTo
                            : "00"
                        }
                        disabled={!this.state.editable}
                      >
                        <option value={"00"}>00</option>
                        <option value={"01"}>01</option>
                        <option value={"02"}>02</option>
                        <option value={"03"}>03</option>
                        <option value={"04"}>04</option>
                        <option value={"05"}>05</option>
                        <option value={"06"}>06</option>
                        <option value={"07"}>07</option>
                        <option value={"08"}>08</option>
                        <option value={"09"}>09</option>
                        <option value={"10"}>10</option>
                        <option value={"11"}>11</option>
                        <option value={"12"}>12</option>
                        <option value={"13"}>13</option>
                        <option value={"14"}>14</option>
                        <option value={"15"}>15</option>
                        <option value={"16"}>16</option>
                        <option value={"17"}>17</option>
                        <option value={"18"}>18</option>
                        <option value={"19"}>19</option>
                        <option value={"20"}>20</option>
                        <option value={"21"}>21</option>
                        <option value={"22"}>22</option>
                        <option value={"23"}>23</option>
                      </FormControl>
                      <Form.Label className={"mr-sm ml-sm"}>:</Form.Label>
                      <FormControl
                        as="select"
                        className={"form-control form-custom time-select"}
                        onChange={this.handleTimeRangeToMinChange}
                        value={
                          this.state.dialogData.timeRangeMinTo
                            ? this.state.dialogData.timeRangeMinTo
                            : "00"
                        }
                        disabled={!this.state.editable}
                      >
                        <option value={"00"}>00</option>
                        <option value={"01"}>01</option>
                        <option value={"02"}>02</option>
                        <option value={"03"}>03</option>
                        <option value={"04"}>04</option>
                        <option value={"05"}>05</option>
                        <option value={"06"}>06</option>
                        <option value={"07"}>07</option>
                        <option value={"08"}>08</option>
                        <option value={"09"}>09</option>
                        <option value={"10"}>10</option>
                        <option value={"11"}>11</option>
                        <option value={"12"}>12</option>
                        <option value={"13"}>13</option>
                        <option value={"14"}>14</option>
                        <option value={"15"}>15</option>
                        <option value={"16"}>16</option>
                        <option value={"17"}>17</option>
                        <option value={"18"}>18</option>
                        <option value={"19"}>19</option>
                        <option value={"20"}>20</option>
                        <option value={"21"}>21</option>
                        <option value={"22"}>22</option>
                        <option value={"23"}>23</option>
                        <option value={"24"}>24</option>
                        <option value={"25"}>25</option>
                        <option value={"26"}>26</option>
                        <option value={"27"}>27</option>
                        <option value={"28"}>28</option>
                        <option value={"29"}>29</option>
                        <option value={"30"}>30</option>
                        <option value={"31"}>31</option>
                        <option value={"32"}>32</option>
                        <option value={"33"}>33</option>
                        <option value={"34"}>34</option>
                        <option value={"35"}>35</option>
                        <option value={"36"}>36</option>
                        <option value={"37"}>37</option>
                        <option value={"38"}>38</option>
                        <option value={"39"}>39</option>
                        <option value={"40"}>40</option>
                        <option value={"41"}>41</option>
                        <option value={"42"}>42</option>
                        <option value={"43"}>43</option>
                        <option value={"44"}>44</option>
                        <option value={"45"}>45</option>
                        <option value={"46"}>46</option>
                        <option value={"47"}>47</option>
                        <option value={"48"}>48</option>
                        <option value={"49"}>49</option>
                        <option value={"50"}>50</option>
                        <option value={"51"}>51</option>
                        <option value={"52"}>52</option>
                        <option value={"53"}>53</option>
                        <option value={"54"}>54</option>
                        <option value={"55"}>55</option>
                        <option value={"56"}>56</option>
                        <option value={"57"}>57</option>
                        <option value={"58"}>58</option>
                        <option value={"59"}>59</option>
                      </FormControl>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </FormGroup>
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="shiftSettings.Required_fields" />
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            {!this.props.permissions || this.props.permissions.actions ? (
              this.state.editable ? (
                <Button className={"btn-save mr-md"} onClick={this.saveDialogForm}>
                  <Translate value="shiftSettings.Save" />
                </Button>
              ) : (
                <Button
                  className={"btn-save mr-md"}
                  onClick={e => {
                    this.handleMenuClick("changeStatus", e);
                  }}
                >
                  <Translate value="shiftSettings.Edit" />
                </Button>
              )
            ) : null}
            <Button className={"btn-cancel"} onClick={this.closeFormModal}>
              <Translate value="shiftSettings.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          onHide={this.closeDialogForm}
          show={this.state.showConfirmModal}
          dialogClassName="custom-modal"
          className={"confirm"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="shiftSettings.DELETE_SHIFT_SETTING" />
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeDialogForm}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Translate value="shiftSettings.DELETE_CONFIRM" />
          </Modal.Body>
          <Modal.Footer>
            <Button className={"btn-save mr-md"} onClick={this.confirmDeleteUser}>
              <Translate value="shiftSettings.Yes" />
            </Button>
            <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
              <Translate value="shiftSettings.No" />
            </Button>
          </Modal.Footer>
        </Modal>
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              className="btn-header text-add-header ml0"
              onClick={this.handleAddButtonClick}
            >
              {" "}
              
              <Translate value="shiftSettings.Add" />
            </Button>
          </ButtonToolbar>
        ) : null}

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

ShiftSetting.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftSetting);
