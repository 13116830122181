import React, { PureComponent } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

class ConfirmEditRecurringModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showConfirmRecurring,
      showHideConfirmRecurringModal,
      dataConfirmRecurring
    } = this.props;

    return (
      <Modal
        show={showConfirmRecurring}
        backdrop
        size="sm"
        dialogClassName="confirm-dialog update-book-detail-modal"
        onHide={showHideConfirmRecurringModal}
        className={'confirmRecurring'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {dataConfirmRecurring.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Translate value="bookingdetail.CONFIRM_UPDATE_MESSAGE" /> */}
          {/* Do you only want to update this order or update this and all future orders? */}
          {dataConfirmRecurring.content}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            className="btn-edit-only"
            onClick={() => dataConfirmRecurring.funcHandleConfirmRecurring(true, false)}
            >
            Only This Order
          </Button>
          <Button 
            className="btn-edit-all"
            onClick={() => dataConfirmRecurring.funcHandleConfirmRecurring(true, true)}
          >
            All Future Orders
          </Button>
          <Button 
            className="btn-edit-all"
            onClick={showHideConfirmRecurringModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmEditRecurringModal;
