import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n';
import downIcon from '../../../assets/images/icons/downIcon.svg'
import upIcon from '../../../assets/images/icons/upIcon.svg'
import TravelerRow from './TravelerRow'
import { POINT_TYPE_TEXT } from '../../../constants/commondata'
import _ from 'lodash'

const PointTravelerInfo = ({
  pointTraveler,
  pointType,
  statusRoute,
  addCustomerMove,
  removeCustomerMove,
  customersSelected
}) => {

  const [isShowPassenger, setIsShowPassenger] = useState(true)

  let listPassengers = pointTraveler.passengers || []
  if(listPassengers.length > 0) {
    listPassengers = listPassengers.sort(function(a, b) {
      var nameA = a.fullName.toUpperCase();
      var nameB = b.fullName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  const isPointPickup = pointType === POINT_TYPE_TEXT.pu ? true : false
  let classContainer = isPointPickup ? "pointTraveler" : "pointTraveler dropOff"
  return (
    <div className={classContainer} >
      <div className="ptTop">
        <span className="ptOrder">
          {pointTraveler.order}
        </span>
        <span className="ptCenter">
          <span>{pointTraveler.address && pointTraveler.address.address}</span>
          <span>{`${listPassengers.length} Passenger(s)`}</span>
        </span>
        <span onClick={() => { setIsShowPassenger(!isShowPassenger) }}>
          <img 
            src={isShowPassenger ? downIcon : upIcon}
          />
        </span>
      </div>
      {
        isShowPassenger &&
        listPassengers.map((passenger) => (
          <TravelerRow 
            isPointPickup={isPointPickup}
            passenger={passenger}
            addCustomerMove={addCustomerMove}
            removeCustomerMove={removeCustomerMove}
            hasSelect={isPointPickup && statusRoute === 'edit'}
            key={passenger.userId}
            customersSelected={customersSelected}
          />
        ))
      }
    </div>
  )
}

PointTravelerInfo.defaultProps = {
  pointTraveler: {}
}

export default PointTravelerInfo