import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';
import currencyFormatter from 'currency-formatter';

export const fields = [
  {
    key: 'dateTime',
    label: 'report.result.passengerWallet.dateTime',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'customerName',
    label: 'report.result.passengerWallet.customerName',
  },
  {
    key: 'phoneNumber',
    label: 'report.result.passengerWallet.phoneNumber',
  },
  {
    key: 'type',
    label: 'report.result.passengerWallet.type',
    mutate: (value, doc) => {
      switch (doc.transactionType) {
        case 'ride':
          return 'Ride';
        case 'tipAfterCompleted':
          return 'Tip after ride';
        case 'debtWriteOff':
          return 'Debt write-off';
        case 'refund':
          return 'Refund';
        case 'editBalance':
          return 'Edit balance';
        case 'cashExcess':
          return 'Excess Cash Credit';
        case 'driverTopUpForPax':
          return 'Excess Cash Credit';
        case 'itemValue':
          return 'Item values';
        case 'redeemVoucher':
          return 'Redeem code #';
        case 'topUpReferrer':
          return 'Referral reward';
        case 'returnCashByWallet':
          return 'Return cash received';
        case 'applePay':
          return 'Top up by Apple Pay';
        case 'googlePay':
          return 'Top up by Google Pay';
        default:
          return 'Top up';
      }
    },
  },
  {
    key: 'bookId',
    label: 'report.result.passengerWallet.bookingId',
  },
  {
    key: 'transactionId',
    label: 'report.result.passengerWallet.transactionId',
  },
  {
    key: 'changedAmount',
    label: 'report.result.passengerWallet.changedAmount',
    negativable: true,
    mutate: (value, doc) => {
      switch (doc.transactionType) {
        case 'ride':
          return value > 0 ? -1 * value : value;
        case 'tipAfterCompleted':
          return value > 0 ? -1 * value : value;
        case 'debtWriteOff':
          return value > 0 ? -1 * value : value;
        case 'itemValue':
          return value > 0 ? -1 * value : value;
        default:
          return value;
      }
    },
    summary: 'changedAmount',
    summaryUnit: 'currencyISO',
  },
  {
    key: 'newBalance',
    label: 'report.result.passengerWallet.newBalance',
  },
  {
    key: 'currency',
    label: 'report.result.passengerWallet.currency',
  },
  {
    key: 'reason',
    label: 'report.result.passengerWallet.Note',
    mutate: (value, doc) => {
      switch (doc.transactionType) {
        case 'credit':
          return I18n.t('report.result.passengerWallet.credit').format(
            doc.cardNumber
          );
        case 'TnG':
          return I18n.t('report.result.passengerWallet.TnG').format(
            doc.transactionId
          );
        case 'cashExcess':
          return I18n.t('report.result.passengerWallet.cashExcess').format(
            currencyFormatter.format(doc.bookingFare, {
              code: doc.currency,
            }),
            currencyFormatter.format(doc.givenAmount, {
              code: doc.currency,
            })
          );
        case 'editBalance':
          return value;
        case 'topUpMOLPay':
          return I18n.t('report.result.passengerWallet.MOLPay').format(
            doc.transactionId
          );
        case 'topUpWallet':
          return I18n.t('report.result.passengerWallet.gatewayName').format(
            doc.walletName
              ? I18n.t(`report.query.paymentMethodItem.${doc.walletName}`)
              : 'External Wallet',
            doc.transactionId
          );
        case 'driverTopUpForPax':
          if (doc.bookId) {
            return I18n.t('report.result.passengerWallet.cashExcess').format(
              currencyFormatter.format(doc.bookingFare, {
                code: doc.currency,
              }),
              currencyFormatter.format(doc.givenAmount, {
                code: doc.currency,
              })
            );
          }
          return doc.reason;
        case 'ride':
          if (doc.givenAmount > 0) {
            return I18n.t('report.result.passengerWallet.cashExcess').format(
              currencyFormatter.format(doc.bookingFare, {
                code: doc.currency,
              }),
              currencyFormatter.format(doc.givenAmount, {
                code: doc.currency,
              })
            );
          }
          return doc.reason;
        case 'topUpReferee':
          return I18n.t('report.result.passengerWallet.topUpReferee').format(
            doc.referralCode ? doc.referralCode : ''
          );
        case 'topUpReferrer':
          return I18n.t('report.result.passengerWallet.topUpReferrer').format(
            doc.bookId ? doc.bookId : '',
            doc.refereeName ? doc.refereeName : '',
            doc.refereePhone ? doc.refereePhone : ''
          );
        default:
          return '';
      }
    },
    width: 450,
  },
];

export const floatFields = ['changedAmount', 'newBalance'];
