import React, { Fragment, Component } from 'react';
import { Col, FormGroup, FormControl, Form, Button, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _, { assign, isEmpty } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import { bindActionCreators } from 'redux';
import markerIcon from '../../../../assets/images/icons/marker.svg';
import markerActiveIcon from '../../../../assets/images/icons/marker_active.svg';
import { Validator, ValidCase } from '../../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../../utils/commonFunctions';
import { QQMap, QQMarker, QQUltis } from '../../../../components/qqMap';
import { geoPlaceDetailMapProvider, getPositionInfomation } from '../../../../utils/mapUtils';
import PlacesAutocomplete from '../../../../components/placesAutocomplete/PlacesAutocomplete';
import ListOfLocationsForm from './ListOfLocationsForm';
import ThirdPartyCategoryInfo from './ThirdPartyCategoryInfo';
import * as commonActions from '../../../../actions/commonDataAction';
import { connect } from 'react-redux';
import AutocompleteWith3rd from '../../../../components/qqMap/AutocompleteWith3rd';
import { getLocationWhenDrapEnd } from '../../../../components/bookingDetail/bookFunction/bookingInfo';

class AdvancedMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: null
    };
  }

  componentDidMount() {
    this.createMarker();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      this.updateMarker();
    }
    if (prevProps.map !== this.props.map && this.props.map && this.state.marker) {
      this.state.marker.map = this.props.map;
    }
  }

  componentWillUnmount() {
    if (this.state.marker) {
      this.state.marker.map = null;
    }
  }

  createMarker() {
    const { position, icon, label, onDblClick, zIndex, map } = this.props;

    const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
      position, icon, label?.text || '', zIndex || 1, 32, 42, 14, 12,
      this.props.draggable,
      this.props.draggable
    );

    if (onDblClick) {
      marker.addListener('dblclick', () => onDblClick(this.props._id));
    }

    if (this.props.onDragEnd) {
      marker.addListener('dragend', this.props.onDragEnd);
    }

    if (map) {
      marker.map = map;
    }

    this.setState({ marker });
  }

  updateMarker() {
    if (this.state.marker) {
      this.state.marker.position = this.props.position;
    }
  }

  render() {
    return null;
  }
}

const AutocompleteItem = ({ suggestion }) => (
  <div>
    <i className="fa fa-map-marker" />
    {suggestion}
  </div>
);

const myStyles = {
  input: { width: '100%' },
  autocompleteContainer: { padding: '5px 12px' },
  autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
  autocompleteItemActive: { color: '#04BE76' }
};

const ObjectId = (rnd = r16 => Math.floor(r16).toString(16)) => rnd(Date.now() / 1000)
  + ' '.repeat(16).replace(/./g, () => rnd(Math.random() * 16));

class AddEditThirdPartyLocationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogData: {},
      valid: {},
      isSubmitted: false,
      center: undefined,
      marker: undefined,
      validCheckLatlng: {},
      isChecklatlngClicked: false,
      parentZones: [],
      otherMarkers: [],
      map: null
    };
    this.checkGoogleAddressByLatlng = _.debounce(this.checkGoogleAddressByLatlng, 500);
  }

  componentDidMount() {
    this.initMapZone();
    const { dialogData } = this.props;
    this.setState(
      {
        dialogData: this.props.dialogData,
        marker: this.props.dialogData,
        center: this.props.dialogData
      },
      () => {
        if (dialogData.locationType === 'multiple') {
          this.handleChangeTabLocation();
        }
      }
    );
  };

  componentWillUnmount() {
    this.setState({
      parentZones: [],
      dialogData: {},
      center: undefined,
      marker: undefined
    });
  };

  initMapZone = async () => {
    const { commonData, commonActions, auth, loadingBarActions } = this.props;
    const parentZones = [];

    let finalZones = commonData?.mapZone || []; // Thêm default value là array rỗng

    if (!commonData?.isZoneLoadFullGeo) {
      loadingBarActions.showLoadingSpiner();
      const result = await commonActions.loadMapZoneFullGeo(auth.selectedFleet.fleetId);
      loadingBarActions.hideLoadingSpiner();
      if (result?.res?.length > 0) {
        finalZones = result.res;
      }
    }

    _.forEach(finalZones, operationZone => {
      // Thêm kiểm tra null check cho operationZone và geo
      if (operationZone?.isActive &&
        operationZone?.display &&
        operationZone?.geo?.coordinates?.[0]) {
        parentZones.push({
          _id: operationZone._id,
          name: operationZone.zoneName,
          isParentZone: true,
          isActive: true,
          path: operationZone.geo.coordinates[0].map(pos => ({
            lat: pos[1],
            lng: pos[0]
          }))
        });
      }
    });

    this.setState({ parentZones });
  };

  onDragEnd = e => {
    getLocationWhenDrapEnd({
      results: e,
      callback: (result, posInfo) => {
        this.updateLocationAfterPickup({
          ...posInfo,
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        });
      },
      isTencent: this.props.commonData?.location?.isChina,
      auth: this.props.auth,
      language: this.props?.language
    });
  };

  onChangeInput = (name, e) => {
    const dialog = this.state.dialogData;
    dialog[name] = e.target.value;
    this.setState(
      {
        dialogData: {
          ...dialog
        }
      }
    );
  };

  onChangeLocationType = e => {
    let { dialogData, valid } = this.state;
    let { locations } = dialogData;
    const locationId = ObjectId();
    let position = null;

    // hanlde change location Tyle
    if (e.target.value === 'multiple') {
      locations = locations && locations.length ? locations : [
        {
          _id: locationId,
          name: '',
          lng: dialogData.lng,
          lat: dialogData.lat,
          city: dialogData.city,
          countryCode: dialogData.countryCode,
          addressDetails: dialogData.addressDetails,
          country: dialogData.country,
          sort: 1
        }
      ];
      dialogData = _.omit(dialogData, ['lat', 'lng']);
    } else if (locations && locations.length) {
      _.forEach(locations, o => {
        valid[o._id] = true;
      });
      dialogData.lng = _.get(locations[0], 'lng');
      dialogData.lat = _.get(locations[0], 'lat');
      dialogData.city = _.get(locations[0], 'city');
      dialogData.country = _.get(locations[0], 'country');
      dialogData.countryCode = _.get(locations[0], 'countryCode');
      dialogData.addressDetails = _.get(locations[0], 'addressDetails');
      dialogData.locations = [];
    }

    if (dialogData.lng && dialogData.lat) {
      position = {
        lng: parseFloat(dialogData.lng),
        lat: parseFloat(dialogData.lat),
        sort: 1
      };
    }

    this.setState(
      {
        dialogData: {
          ...dialogData,
          locationType: e.target.value,
          locations
        },
        marker: position,
        center: position,
        otherMarkers: [],
        isSubmitted: false,
        valid
      },
      this.handleChangeTabLocation
    );
  };

  handleAddMoreLocation = () => {
    const { locations } = this.state.dialogData;
    const locationId = ObjectId();
    if (locations && locations.length >= 10) {
      this.context.notification(
        'error',
        I18n.t('thirdPartySettings.Not_over_limit_subs')
      );
      return;
    }
    locations.push({
      ..._.pick(locations[0], ['lng', 'lat', 'city', 'country', 'countryCode', 'addressDetails']),
      sort: locations.length + 1,
      _id: locationId,
      name: '',
      instructionLink: '',
      placeType: 'all'
    });
    this.setState(
      {
        dialogData: {
          ...this.state.dialogData,
          locations
        }
      },
      () => {
        this.handleChangeTabLocation(locationId);
      }
    );
  };

  hanldeRemoveLocation = locationId => {
    const {
      dialogData: { locations },
      valid
    } = this.state;
    if (locations.length === 1) {
      return;
    }
    this.setState(
      {
        dialogData: {
          ...this.state.dialogData,
          locations: locations.filter(o => o._id !== locationId)
        },
        valid: _.omit(valid, [locationId])
      },
      this.handleChangeTabLocation
    );
  };

  handleChangeTabLocation = tabKey => {
    const {
      dialogData
    } = this.state;
    let { dialogData: { locations } } = this.state;

    if (dialogData.locationType !== 'multiple') return;

    const locationId = tabKey || locations.length && locations[0]._id;
    locations = _.map(locations, (o, i) => ({ ...o, sort: i + 1 }));
    const otherLocations = _.filter(
      locations,
      o => o._id !== locationId && o.lat && o.lng
    );

    const otherMarkers = _.map(otherLocations, o => ({
      ...o,
      position: {
        lat: parseFloat(o.lat),
        lng: parseFloat(o.lng)
      }
    }));
    const currentLocation = _.find(
      locations,
      o => o._id === locationId && o.lat && o.lng
    );
    this.setState({
      marker: currentLocation && _.pick(currentLocation, ['lat', 'lng', 'sort']),
      center: currentLocation && _.pick(currentLocation, ['lat', 'lng']),
      activeLocation: locationId,
      otherMarkers
    });
  };

  handleChangeLocationForm = (id, key, value) => {
    const { locations } = this.state.dialogData;
    const index = _.findIndex(locations, o => o._id === id);
    _.set(locations[index], key, value);
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        locations: [...locations]
      }
    });
  };

  onMapLoad = (mapInstance) => {
    this.setState({ map: mapInstance });
  };

  renderMap = () => {
    const { parentZones, otherMarkers, center, marker, map } = this.state;

    return (
      <GoogleMap
        onLoad={this.onMapLoad}
        center={center || { lat: 16.059959, lng: 108.224258 }}
        zoom={16}
        options={{
          gestureHandling: 'greedy',
          mapId: process.env.REACT_APP_MAP_ID
        }}
        mapContainerStyle={{ height: '100%' }}
      >
        {marker && (
          <AdvancedMarker
            position={marker}
            draggable
            onDragEnd={this.onDragEnd}
            icon={markerActiveIcon}
            zIndex={3}
            map={map}
            label={this.state.dialogData.locationType === 'multiple' ? (marker.sort || '1').toString() : ''}
          />
        )}

        {parentZones.map(zone => (
          <Polygon
            key={zone._id}
            paths={zone.path}
            options={{
              strokeColor: '#e89110',
              strokeOpacity: 1,
              strokeWeight: 1,
              fillColor: '#d3d3d3',
              fillOpacity: 0.4,
              zIndex: 1
            }}
          />
        ))}

        {otherMarkers.map(point => (
          <AdvancedMarker
            key={point._id}
            position={point.position}
            icon={markerIcon}
            map={map}
            onDblClick={() => this.handleChangeTabLocation(point._id)}
            label={(point.sort || '').toString()}
            zIndex={2}
          />
        ))}
      </GoogleMap>
    );
  };

  handleSelectPickUp = (addressPickUp, placeId, pointOfInterest, sessionToken) => {
    if (addressPickUp && addressPickUp != '') {
      this.setState({
        addressPickUp
      });
      const callback = (err, result, results) => {
        if (!err) {
          this.updateLocationAfterPickup(result);
        }
      };
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        isTencent: this.props?.commonData?.location?.isChina,
        language: this.props.language
      });
    }
  };

  updateLocationAfterPickup = result => {
    const { activeLocation, dialogData, marker } = this.state;
    if (dialogData.locationType !== 'multiple') {
      this.setState({
        center: result,
        marker: { ...marker, ...result },
        dialogData: {
          ...dialogData,
          lat: result.lat,
          lng: result.lng,
          city: result.city,
          country: result.country,
          countryCode: result.countryCode,
          addressDetails: result.addressDetails
        }
      });
    } else {
      const { locations } = dialogData;
      const index = _.findIndex(locations, o => o._id === activeLocation);
      _.set(locations[index], 'lng', result.lng);
      _.set(locations[index], 'lat', result.lat);
      _.set(locations[index], 'city', result.city);
      _.set(locations[index], 'country', result.country);
      _.set(locations[index], 'countryCode', result.countryCode);
      _.set(locations[index], 'addressDetails', result.addressDetails);
      this.setState({
        center: result,
        marker: { ...marker, ...result },
        dialogData: {
          ...dialogData,
          locations: [...locations]
        }
      });
    }
  };

  getCityFromPlaces = place => {
    const address = place.address_components;
    let cityCountry = '';
    let zipcode = '';
    let locality = '';
    let adminarealv1 = '';
    let country = '';
    if (address && address.length > 0) {
      for (let i = 0; i < address.length; i++) {
        if (address[i].types[0] === 'postal_code') {
          zipcode = address[i].short_name;
        }
        if (address[i].types[0] === 'locality') {
          locality = address[i].long_name;
        }
        if (address[i].types[0] === 'country') {
          country = address[i].long_name;
        }
        if (address[i].types[0] === 'administrative_area_level_1') {
          adminarealv1 = address[i].long_name;
        }
      }
      cityCountry = `${zipcode}_${locality}_${adminarealv1}_${country}`;
    }
    return cityCountry;
  };

  checkGoogleAddressByLatlng = (params, index) => {
    this.setState({ isSubmitted: false, isChecklatlngClicked: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.validCheckLatlng)) return;
    const isChina = this.props.commonData.location && this.props.commonData.location.isChina;
    const { dialogData, marker } = this.state;
    let position = params;
    if (dialogData.locationType !== 'multiple') {
      position = dialogData;
    }

    const latlng = isChina
      ? new window.qq.maps.LatLng(
        parseFloat(position.lat),
        parseFloat(position.lng)
      )
      : {
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng)
      };
    const callback = (result, posInfo) => {
      if (!result) {
        this.context.notification(
          'error',
          I18n.t('thirdPartySettings.Location_Not_Found')
        );
        return;
      }
      const { locations } = dialogData;
      const pos = isChina ? latlng : posInfo;

      if (_.isNumber(index)) {
        locations[index] = {
          ...locations[index],
          city: isChina ? '_Beijing_Beijing Shi_China' : posInfo.city,
          country: isChina ? 'China' : posInfo.country,
          countryCode: isChina ? 'CN' : posInfo.countryCode
        };
        this.setState({
          isChecklatlngClicked: true,
          center: pos,
          marker: { ...marker, ...pos },
          addressPickUp: result.formatted_address,
          dialogData: {
            ...dialogData,
            locations: [...locations]
          }
        });
      } else {
        this.setState({
          isChecklatlngClicked: true,
          center: pos,
          marker: { ...marker, ...pos },
          addressPickUp: result.formatted_address,
          dialogData: {
            ...dialogData,
            city: isChina ? '_Beijing_Beijing Shi_China' : posInfo.city,
            country: isChina ? 'China' : posInfo.country,
            countryCode: isChina ? 'CN' : posInfo.countryCode
          }
        });
      }
    };
    if (
      this.props.commonData.location
      && this.props.commonData.location.isChina
    ) {
      QQUltis.reserverGeoCodeAdress(latlng, callback);
    } else {
      getPositionInfomation({
        latLng: latlng,
        callback,
        language: this.props?.language,
        auth: this.props.auth
      });
    }
  };

  handleChangePickUp = addressPickUp => {
    this.setState({ addressPickUp });
  };

  handleAddressRemovedPickUp = () => {
    this.setState({ addressPickUp: '' });
  };

  handleSaveButtonClick = () => {
    const { dialogData, valid } = this.state;
    const { auth } = this.props;
    this.setState({ isChecklatlngClicked: false, isSubmitted: true });
    if (dialogData.locationType === 'multiple') {
      valid.lat = true;
      valid.lng = true;
    }
    if (!CCLiteCommonFunc.isFormValid(valid)) return;

    const { fleetId } = auth.selectedFleet;
    const username = auth.user.userName;
    const isChina = this.props.commonData.location && this.props.commonData.location.isChina;

    let body = {
      alias: dialogData.alias,
      address: dialogData.address,
      lat: dialogData.lat,
      lng: dialogData.lng,
      city: dialogData.city || _.get(dialogData, 'locations[0].city', ''),
      country: dialogData.country || '',
      countryCode: dialogData.countryCode || '',
      addressDetails: dialogData.addressDetails || {},
      locationType: dialogData.locationType || 'single',
      locations: dialogData.locations || [],
      categories: dialogData.categories,
      instructionLink: dialogData.instructionLink,
      availableTime: (dialogData.availableTime && (dialogData.availableTime.from || dialogData.availableTime.to)) ? dialogData.availableTime : {
        from: '00:00',
        to: '00:00'
      },
      metadata: dialogData.metadata || '',
      phone: dialogData.phone || '',
      website: dialogData.website || '',
      fleetId,
      username
    };

    if (dialogData && isEmpty(body.city) && !isChina) {
      const position = body.locationType === 'multiple' ? dialogData.locations[0] : dialogData;
      const latlng = {
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng)
      };
      getPositionInfomation({
        latLng: latlng,
        language: this.props?.language,
        auth: this.props.auth,
        callback: (result, posInfo) => {
          if (result) {
            body = assign(body, _.pick(posInfo, ['country', 'countryCode', 'city']));
          } else {
            body.city = '';
          }
          this.sendThirdPartyRequest(body);
        }
      });

    } else {
      this.sendThirdPartyRequest(body);
    }
  };

  preprareParams = (body) => {
    const { locations, locationType } = body;
    if (locationType === 'multiple') {
      return {
        ...body,
        locations: locations.map(loc => ({
          ...loc,
          city: loc.city || body.city,
          country: loc.country || body.country,
          countryCode: loc.countryCode || body.countryCode,
          addressDetails: loc.addressDetails || body.addressDetails
        }))
      };
    }
    return body;
  };

  sendThirdPartyRequest = body => {
    const { dialogData } = this.state;
    const { loadingBarActions } = this.props;
    loadingBarActions.showLoadingSpiner();
    if (dialogData._id) {
      body._id = dialogData._id;
      const requestData = this.preprareParams(body);
      this.props.settingActions.updateThirdPartyLocation(requestData).then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('thirdPartySettings.Update_third_party_success')
          );
          this.props.closeFormModal();
          this.props.updateThirdPartyList();
          this.setState({ isSubmitted: false });
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('thirdPartySettings.Update_third_party_fail')
          );
        }
      });
    } else {
      const requestData = this.preprareParams(body);
      this.props.settingActions.createThirdPartyLocation(requestData).then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('thirdPartySettings.Create_third_party_success')
          );
          this.props.closeFormModal();
          this.props.updateThirdPartyList();
          this.setState({ isSubmitted: false });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('thirdPartySettings.Create_third_party_fail')
            );
          }
          this.props.updateThirdPartyList();
        }
      });
    }
  };

  handleCategoriesChange = (categories) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        categories
      }
    });
  };

  handleChangePhoneNumber = (e) => {
    const { value } = e.target || {};

    this.setState({
      dialogData: {
        ...this.state.dialogData,
        phone: value
      }
    });
  };

  handleAvailableTime = (timeKey, e) => {
    const { value = 0 } = e.target || {};
    const { dialogData = {} } = this.state || {};
    const {
      availableTime = {
        from: '00:00',
        to: '00:00'
      }
    } = dialogData || {};

    const {
      from = '00:00',
      to = '00:00'
    } = availableTime || {};

    let hourFrom = parseInt(from.split(':')[0] || '0');
    let minsFrom = parseInt(from.split(':')[1] || '0');
    let hourTo = parseInt(to.split(':')[0] || '0');
    let minsTo = parseInt(to.split(':')[1] || '0');

    switch (timeKey) {
      case 'hourFrom':
        hourFrom = value;
        break;
      case 'minsFrom':
        minsFrom = value;
        break;
      case 'hourTo':
        hourTo = value;
        break;
      case 'minsTo':
        minsTo = value;
        break;
      default:
        break;
    }

    const newAvailableTime = {
      from: `${hourFrom < 10 ? '0' : ''}${hourFrom}:${minsFrom < 10 ? '0' : ''}${minsFrom}`,
      to: `${hourTo < 10 ? '0' : ''}${hourTo}:${minsTo < 10 ? '0' : ''}${minsTo}`
    };

    this.setState({
      dialogData: {
        ...dialogData,
        availableTime: newAvailableTime
      }
    });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  ValidatorCheckLatlngCallback = (id, valid, messages) => {
    if (this.state.validCheckLatlng[id] != valid) {
      this.state.validCheckLatlng[id] = valid;
      this.setState({ validCheckLatlng: this.state.validCheckLatlng });
    }
  };

  render() {
    const canUpdate = !this.props.permissions || this.props.permissions.actions;
    const { parentZones } = this.state;

    return (
      <Modal
        onHide={this.props.closeFormModal}
        show={this.props.show}
        dialogClassName="fit-content-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.dialogData._id ? (
              <Translate value="thirdPartySettings.Edit_Third_Party_location" />
            ) : (
              <Translate value="thirdPartySettings.Add_Third_Party_location" />
            )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeFormModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={5} style={{ paddingLeft: 0 }}>
              <FormGroup>
                <Form.Label>
                  <Translate value="thirdPartySettings.locationType" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  value={this.state.dialogData.locationType}
                  onChange={this.onChangeLocationType}
                  disabled={!canUpdate}
                >
                  <option value="single">
                    {I18n.t('thirdPartySettings.single')}
                  </option>
                  <option value="multiple">
                    {I18n.t('thirdPartySettings.multiple')}
                  </option>
                </FormControl>
              </FormGroup>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.alias === true
                      ? null
                      : 'error'
                }
              >
                <Form.Label>
                  <Translate value="thirdPartySettings.Alias" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  defaultValue={this.state.dialogData.alias}
                  onChange={e => this.onChangeInput('alias', e)}
                  placeholder={I18n.t('thirdPartySettings.Alias')}
                  disabled={!canUpdate}
                />
                <Validator id="alias" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      !Validation.isStringEmpty(this.state.dialogData.alias)
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isMaxlength(this.state.dialogData.alias, 150)}
                    message={I18n.t('messages.commonMessages.Max_length').format(150)}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.address === true
                      ? null
                      : 'error'
                }
              >
                <Form.Label>
                  <Translate value="thirdPartySettings.Address" />
                  {' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={e => this.onChangeInput('address', e)}
                  defaultValue={this.props.dialogData.address}
                  value={this.state.dialogData.address ? this.state.dialogData.address : ''}
                  placeholder={I18n.t('thirdPartySettings.Address')}
                  disabled={!canUpdate}
                />
                <Validator id="address" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      !Validation.isStringEmpty(this.state.dialogData.address)
                    }
                    message={I18n.t('thirdPartySettings.Please_input_address')}
                  />
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isMaxlength(this.state.dialogData.address, 150)}
                    message={I18n.t('messages.commonMessages.Max_length').format(150)}
                  />
                </Validator>
              </FormGroup>

              <ThirdPartyCategoryInfo
                isSubmited={this.state.isSubmitted}
                ValidatorCallback={this.ValidatorCallback}
                categories={this.state.dialogData ? this.state.dialogData.categories : null}
                phone={this.state.dialogData && this.state.dialogData.phone ? this.state.dialogData.phone : ''}
                website={this.state.dialogData && this.state.dialogData.website ? this.state.dialogData.website : ''}
                availableTime={this.state.dialogData && this.state.dialogData.availableTime ? this.state.dialogData.availableTime : null}
                disabled={!canUpdate}
                handleCategoriesChange={this.handleCategoriesChange}
                handleChangePhoneNumber={this.handleChangePhoneNumber}
                handleAvailableTime={this.handleAvailableTime}
                onChangeInput={this.onChangeInput}
                valid={this.state.valid}
              />

              {this.state.dialogData.locationType === 'multiple' ? (
                <ListOfLocationsForm
                  canUpdate={canUpdate}
                  locations={this.state.dialogData.locations}
                  handleAddMoreLocation={this.handleAddMoreLocation}
                  onRemoveLocation={this.hanldeRemoveLocation}
                  activeLocation={this.state.activeLocation}
                  onChangeTabLocation={this.handleChangeTabLocation}
                  handleChangeLocationForm={this.handleChangeLocationForm}
                  isSubmitted={this.state.isSubmitted}
                  validatorCallback={this.ValidatorCallback}
                  checkGoogleAddressByLatlng={this.checkGoogleAddressByLatlng}
                />
              ) : (
                <Fragment>
                  <FormGroup
                    className={
                      (!this.state.isSubmitted
                        ? null
                        : this.state.valid.lat === true
                          ? null
                          : 'error')
                      || (!this.state.isChecklatlngClicked
                        ? null
                        : this.state.validCheckLatlng.lat === true
                          ? null
                          : 'error')
                    }
                  >
                    <Form.Label>
                      <Translate value="thirdPartySettings.Latitude" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={e => this.onChangeInput('lat', e)}
                      value={
                        this.state.dialogData ? this.state.dialogData.lat : ''
                      }
                      placeholder={I18n.t('thirdPartySettings.Latitude')}
                      disabled={!canUpdate}
                    />
                    <Validator id="lat" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.lat)
                        }
                        message={I18n.t(
                          'thirdPartySettings.Please_input_latitude'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          parseFloat(this.state.dialogData.lat) <= 90
                          && parseFloat(this.state.dialogData.lat) >= -90
                        }
                        message={I18n.t(
                          'thirdPartySettings.Error_message_lat_in_range'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={!Validation.isMaxlength(this.state.dialogData.lat, 50)}
                        message={I18n.t('messages.commonMessages.Max_length').format(50)}
                      />
                    </Validator>
                    <Validator
                      id="lat"
                      callback={this.ValidatorCheckLatlngCallback}
                    >
                      <ValidCase
                        hide={!this.state.isChecklatlngClicked}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.lat)
                        }
                        message={I18n.t(
                          'thirdPartySettings.Please_input_latitude'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isChecklatlngClicked}
                        valid={
                          parseFloat(this.state.dialogData.lat) <= 90
                          && parseFloat(this.state.dialogData.lat) >= -90
                        }
                        message={I18n.t(
                          'thirdPartySettings.Error_message_lat_in_range'
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup
                    className={
                      (!this.state.isSubmitted
                        ? null
                        : this.state.valid.lng === true
                          ? null
                          : 'error')
                      || (!this.state.isChecklatlngClicked
                        ? null
                        : this.state.validCheckLatlng.lng === true
                          ? null
                          : 'error')
                    }
                  >
                    <Form.Label>
                      <Translate value="thirdPartySettings.Longitude" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={e => this.onChangeInput('lng', e)}
                      value={
                        this.state.dialogData ? this.state.dialogData.lng : ''
                      }
                      placeholder={I18n.t('thirdPartySettings.Longitude')}
                      disabled={!canUpdate}
                    />
                    <Validator id="lng" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.lng)
                        }
                        message={I18n.t(
                          'thirdPartySettings.Please_input_longitude'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !parseFloat(this.state.dialogData.lng) <= 180
                          && parseFloat(this.state.dialogData.lng) >= -180
                        }
                        message={I18n.t(
                          'thirdPartySettings.Error_message_lng_in_range'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={!Validation.isMaxlength(this.state.dialogData.lng, 50)}
                        message={I18n.t('messages.commonMessages.Max_length').format(50)}
                      />
                    </Validator>
                    <Validator
                      id="lng"
                      callback={this.ValidatorCheckLatlngCallback}
                    >
                      <ValidCase
                        hide={!this.state.isChecklatlngClicked}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.lng)
                        }
                        message={I18n.t(
                          'thirdPartySettings.Please_input_longitude'
                        )}
                      />
                      <ValidCase
                        hide={!this.state.isChecklatlngClicked}
                        valid={
                          !parseFloat(this.state.dialogData.lng) <= 180
                          && parseFloat(this.state.dialogData.lng) >= -180
                        }
                        message={I18n.t(
                          'thirdPartySettings.Error_message_lng_in_range'
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup>
                    <Form.Label title={I18n.t('thirdPartySettings.instructionLinkTooltip')}>
                      <Translate value="thirdPartySettings.instructionLink" />
                    </Form.Label>
                    <FormControl
                      type="text"
                      className="form-custom"
                      value={this.state.dialogData ? this.state.dialogData.instructionLink : ''}
                      onChange={e => this.onChangeInput('instructionLink', e)}
                      placeholder={I18n.t('thirdPartySettings.instructionLinkPlaceholder')}
                      disabled={!canUpdate}
                      title={I18n.t('thirdPartySettings.instructionLinkTooltip')}
                    />
                  </FormGroup>
                  {canUpdate ? (
                    <FormGroup>
                      <Button
                        className="btn-save w-100"
                        onClick={this.checkGoogleAddressByLatlng}
                      >
                        <Translate value="thirdPartySettings.Check_Google_Address" />
                      </Button>
                    </FormGroup>
                  ) : null}
                </Fragment>
              )}
              <Form.Label>
                <div>
                  <Translate value="thirdPartySettings.Note_guide" />
                </div>
                (
                <span className="require mt">*</span>
                ):
                {' '}
                <Translate value="thirdPartySettings.Required_fields" />
              </Form.Label>
            </Col>
            <Col md={7} className="" style={{ paddingRight: 0 }}>
              {this.props.commonData.location
              && this.props.commonData.location.isChina ? (
                <QQMap
                  className="fill"
                  onLoad={this.handleMapLoad}
                  options={{ center: this.state.center }}
                >
                  {this.state.marker && (
                    <QQMarker
                      mapkey="1"
                      options={{
                        position: this.state.marker,
                        draggable: true,
                        icon: markerActiveIcon,
                        zIndex: 3,
                        title: (this.state.marker.sort || 1).toString()
                      }}
                      events={{ dragend: this.onDragEnd }}
                    />
                  )}
                  {(this.state.otherMarkers || []).map(point => (
                    <QQMarker
                      key={point._id}
                      mapkey={point._id}
                      options={{
                        position: point.position,
                        draggable: false,
                        icon: markerIcon,
                        zIndex: 2,
                        title: point.sort
                      }}
                      events={{ dragend: this.onDragEnd }}
                    />
                  ))}
                </QQMap>
              ) : (
                parentZones.length > 0 && (
                  <div className="fill">
                    {this.renderMap()}
                  </div>
                )
              )}

              <div className="zone-location">
                <FormGroup>
                  {this.props.commonData.location
                  && this.props.commonData.location.isChina ? (
                    <AutocompleteWith3rd
                      className="location-select form-custom form-control"
                      value={this.state.addressPickUp ? this.state.addressPickUp : ''}
                      onChange={this.handleChangePickUp}
                      onSelect={this.handleSelectPickUp}
                    />
                  ) : (
                    <PlacesAutocomplete
                      value={this.state.addressPickUp}
                      onSelect={this.handleSelectPickUp}
                      onChange={this.handleChangePickUp}
                      autocompleteItem={AutocompleteItem}
                      className="location-select"
                      styles={myStyles}
                      placeholder={I18n.t('map.Enter_a_location')}
                      onEnterKeyDown={this.handleSelectPickUp}
                      handleAddressRemoved={this.handleAddressRemovedPickUp}
                    />
                  )}
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {canUpdate ? (
            <Button
              className="btn-save mr-md"
              onClick={this.handleSaveButtonClick}
            >
              <Translate value="thirdPartySettings.Save" />
            </Button>
          ) : null}

          <Button className="btn-cancel" onClick={this.props.closeFormModal}>
            <Translate value="thirdPartySettings.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddEditThirdPartyLocationModal.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditThirdPartyLocationModal);

