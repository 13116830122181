import React from 'react';
import {  Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { ZoneActionStatus } from '../../../../../constants/commondata';

const isDropup = index => {
  const containerHeight = window.innerHeight - 200; // height of zone list container

  if (containerHeight - 200 - index * 40 < 80) {
    return true;
  }

  return false;
};

export default props => (
  <div className="zoneNoShowContainer">
    <table className="table table-no-pagination cc-table-striped">
      <thead>
        <tr>
          <th>
            <Translate value="zoneSetting.Name" />
          </th>
          <th>
            <Translate value="generalSetting.DurationRegularBooking" />
          </th>
          <th>
            <Translate value="generalSetting.maxWaitTime" />
          </th>
          <th>
            <Translate value="cue.Status" />
          </th>
          <th />
        </tr>
      </thead>
    </table>
    <div className="zoneNoShow__table">
      <table className="table table-no-pagination cc-table-striped">
        <thead>
          <tr>
            <th />
            <th />
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {_.map(props.zoneNoShows, (item, index) => (
            <tr key={item._id} onClick={() => props.handleZoneRowClick(item)}>
              <td>
                <div>{item.name}</div>
              </td>
              <td>
                <div>
                  {item.duration}
                  &nbsp;mins
                </div>
              </td>
              <td>
                <div>
                  {item.maxWaitTime}
                  &nbsp;mins
                </div>
              </td>
              <td className={item.isActive ? 'Active' : 'Inactive'}>
                {props.hasPermission ? (
                  <a onClick={() => props.handleActivateFare(item)} className="clickable">
                    {item.isActive ? (
                      <Translate value="zoneSetting.Active" />
                    ) : (
                      <Translate value="zoneSetting.Inactive" />
                    )}
                  </a>
                ) : item.isActive ? (
                  <Translate value="zoneSetting.Active" />
                ) : (
                  <Translate value="zoneSetting.Inactive" />
                )}
              </td>
              <td>
                {props.hasPermission ? (
                  <Dropdown
                    id="bg-nested-dropdown"
                    className="table-actions-dropdown"
                    onSelect={(eventKey, event) => {
                      event.stopPropagation();
                      props.handleMenuItemClick(eventKey, item);
                    }}
                    onToggle={(isOpen, event) => event.stopPropagation()}
                    dropup={isDropup(index)}
                    pullRight
                  >
                    <Dropdown.Toggle>
                      <i className="fa fa-reorder" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="actions">
                      <Dropdown.Item eventKey={ZoneActionStatus.Edit}>
                        <Translate value="zoneSetting.Edit" />
                      </Dropdown.Item>
                      {item.inUse && item.isActive ? null : (
                        <Dropdown.Item eventKey={ZoneActionStatus.Status}>
                          {item.isActive ? (
                            <Translate value="zoneSetting.Deactivate" />
                          ) : (
                            <Translate value="zoneSetting.Activate" />
                          )}
                        </Dropdown.Item>
                      )}
                      {item.isActive || item.inUse ? null : (
                        <Dropdown.Item eventKey={ZoneActionStatus.Delete}>
                          <Translate value="zoneSetting.Delete" />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
          {!props.zoneNoShows.length && (
            <tr>
              <td colSpan={4}>
                <div className="pv text-remove text-center">
                  <Translate value="reservation.noData" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);
