import {
  getDriverApi,
  deleteDriverApi,
  activateDriverApi,
  deactivateDriverApi,
  activateMultiDriverApi,
  deactivateMultiDriverApi,
  editDriverApi,
  addDriverApi,
  findOneDriverApi,
  findOneVehicleApi,
  checkExistDriverPhoneApi,
  vehicleFetchApi,
  createDriverApi,
  resestPasswordApi,
  creditBalanceApi,
  updateCashBalanceApi,
  exportDriverToExcelApi,
  getDriverAccountRequireFieldApi,
  countDriverApi,
  driverTotalBalanceApi,
  removeBankInfoApi,
  driverEmergencyContactApi,
  setFleetCommissionDriverApi,
  setDocumentsMissingApi,
  addVehicleApi,
  removeVehicleApi,
  switchVehicleApi,
  updateDriverDocumentApi,
  operationFindByIdApi
} from '../constants/ApiConfigs';
import { callApi, UrlBuilder } from '../utils/apiUtils';

function exportDriverToExcel(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(exportDriverToExcelApi, config, null, null, null, false);
}

function deleteDriver(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'delete',
    body: JSON.stringify(options)
  };
  return callApi(deleteDriverApi, config, null, null, null, true);
}

function activateDriver(options = {}, isActivate) {
  options = Object.assign({}, options);
  const config = {
    method: 'put',
    body: JSON.stringify(options)
  };
  return callApi(
    isActivate ? deactivateDriverApi : activateDriverApi,
    config,
    null,
    null,
    null,
    true
  );
}

function activateMultiDriver(options = {}, isActivate) {
  options = Object.assign({}, options);
  const config = {
    method: 'put',
    body: JSON.stringify(options)
  };
  return callApi(
    isActivate ? deactivateMultiDriverApi : activateMultiDriverApi,
    config,
    null,
    null,
    null,
    true
  );
}

function getDriver(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getDriverApi, config, null, null, null, true);
}

function operationFindById(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(operationFindByIdApi, config, null, null, null, true);
}

function setFleetCommissionDriver(data) {
  const config = {
    method: 'post',
    body: JSON.stringify(data)
  };
  return callApi(setFleetCommissionDriverApi, config, null, null, null, true)
}

function countDriver(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(countDriverApi, config, null, null, null, true);
}

function findOneDriver(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(findOneDriverApi, options), config, null, null, null, true);
}

function findOneVehicle(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(findOneVehicleApi, options), config, null, null, null, false);
}

function checkExistDriverPhone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(checkExistDriverPhoneApi, config, null, null, null, false);
}

function fetchVehicleByCompany(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(vehicleFetchApi, options), config, null, null, null, true);
}

function operateDriver(data, isEdit) {
  const config = {
    method: 'POST',
    body: data,
    isFormData: true
  };
  return callApi(isEdit ? editDriverApi : createDriverApi, config, null, null, null, true);
}

function updateDriverDocument(data) {
  const config = {
    method: 'POST',
    body: data,
    isFormData: true
  };
  return callApi(updateDriverDocumentApi, config, null, null, null, true);
}

function addVehicle(data) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(addVehicleApi, config, null, null, null, true);
}

function removeVehicle(data) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(removeVehicleApi, config, null, null, null, true);
}

function switchVehicle(data) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  return callApi(switchVehicleApi, config, null, null, null, true);
}

function resestPassword(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(resestPasswordApi, config, null, null, null, true);
}

function creditBalance(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(creditBalanceApi, config, null, null, null, true);
}

function updateCashBalance(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(updateCashBalanceApi, config, null, null, null, true);
}

function getBankAccountRequireField(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getDriverAccountRequireFieldApi, config, null, null, null, true);
}

function removeBankInfo(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(removeBankInfoApi, config, null, null, null, true);
}

function getDriverTotalBalance(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(driverTotalBalanceApi, options), config, null, null, null, true);
}

function getEmergencyContactDriver(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "get"
  };
  return callApi(
    UrlBuilder(driverEmergencyContactApi, options),
    config,
    null,
    null,
    null,
    true
  );
}

function setDocumentsMissing(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(setDocumentsMissingApi, config, null, null, null, true);
}


export {
  deleteDriver,
  activateDriver,
  activateMultiDriver,
  getDriver,
  resestPassword,
  findOneDriver,
  findOneVehicle,
  checkExistDriverPhone,
  fetchVehicleByCompany,
  operateDriver,
  updateDriverDocument,
  creditBalance,
  updateCashBalance,
  exportDriverToExcel,
  getBankAccountRequireField,
  removeBankInfo,
  countDriver,
  getDriverTotalBalance,
  getEmergencyContactDriver,
  setFleetCommissionDriver,
  setDocumentsMissing,
  removeVehicle,
  addVehicle,
  switchVehicle,
  operationFindById
};
