import React from "react";
import BaseReport from "./../base";
import * as fieldMappings from "./fields";
import { reportSettlementHistoryApi } from "../../../constants/ApiConfigs";
import { connect } from "react-redux";
let bookingService = [
  {
    value: "localBooking",
    label: "report.query.bookingServiceItem.localBooking"
  },
  {
    value: "provideService",
    label: "report.query.bookingServiceItem.provideService"
  }
];

class ReportSettlementHistory extends React.Component {

  render() {
    const { permissions = null, auth: { user } = {} } = this.props || {};
    const exportPermission = user.isAdmin ? true : permissions.export;
    return (
      permissions && exportPermission ? (<div className="content">
        <BaseReport
          tittle="Settlement history"
          fieldMappings={fieldMappings}
          apiurl={reportSettlementHistoryApi}
          dateRange
          company
          driver
          bookingService={bookingService}
          currency
        />
      </div>)
        : (
          <div className="content">
            <BaseReport
              tittle="Settlement history"
              fieldMappings={fieldMappings}
              apiurl={reportSettlementHistoryApi}
              dateRange
              company
              driver
              bookingService={bookingService}
              currency
              noexport
            />
          </div>
        )
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

export default connect(
  mapStateToProps
)(ReportSettlementHistory);
