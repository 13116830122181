
import React, { Component } from 'react';
import {
    Modal,
    Image
} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import {
    userType,
    paymentMethodNumber,
    travelerType
} from '../../../constants/commondata'
import _ from 'lodash'
import destinationIcon from './../../../assets/images/icons/destination2.svg'
import pickupIcon from './../../../assets/images/icons/pickup2.svg'
import moment from 'moment'

class DriverCalendarInfo extends Component {
    constructor(props) {
        super(props)
    }

    render() {

        const {
            isShowDriverCalendar,
            driverReservationBookings,
            driverReservationDatePriview,
            handleDriverReservationBookingDateChange,
            data,
            onClose,
            driverCalendarViewing,
            fieldKey
        } = this.props

        const time = _.get(data, fieldKey, '') || moment();

        return <Modal
            show={isShowDriverCalendar}
            className={'driver-calendar-modal'}
            onHide={onClose}
        >
            <Modal.Header>
                <div className='cal-control'>
                    {
                        driverReservationDatePriview == -1 ? null :
                            <i className='fa fa-caret-left'
                                onClick={(e) => {
                                    handleDriverReservationBookingDateChange(-1)
                                }}
                            />
                    }
                    <span className='cal-date'>{
                        moment(time).add(driverReservationDatePriview, 'days').format('MMMM DD, YYYY')
                    }
                    </span>
                    {
                        driverReservationDatePriview == 1 ? null :
                            <i className='fa fa-caret-right'
                                onClick={(e) => {
                                    handleDriverReservationBookingDateChange(1)
                                }}
                            />
                    }
                </div>
                <div className="driver-name">
                    {`${driverCalendarViewing && driverCalendarViewing.firstName} ${driverCalendarViewing && driverCalendarViewing.lastName}`}
                </div>
                <button type="button" className="close" aria-label="Close" onClick={onClose}><span aria-hidden="true">×</span></button>
            </Modal.Header>
            <Modal.Body>
                {
                    driverReservationBookings.filter(s => {
                        return moment(s.request.pickUpTime).format('MMM DD, YYYY')
                            == moment(time).add(driverReservationDatePriview, 'days').format('MMM DD, YYYY')
                    }).length > 0 ?
                        driverReservationBookings.map((schedule, index) => {
                            if (schedule &&
                                moment(schedule.request.pickUpTime).format('MMM DD, YYYY')
                                == moment(time).add(driverReservationDatePriview, 'days').format('MMM DD, YYYY')) {
                                return <div className={'cal-schedule-item' + (index == 0 ? " first-item" : "")}>
                                    <div className={'info'}>
                                        <div className={"passenger"}>
                                            {schedule.tripId && schedule.tripId !== '' ?
                                                <div className="book-id">
                                                    <span>{`#${schedule.tripId}`}</span>
                                                </div> :
                                                <div className="book-id">
                                                    <span>{`#${schedule.bookId}`}</span>
                                                </div>
                                            }
                                            <div className="pickup-time">
                                                <span>{`${moment(schedule.request.pickUpTime).format("hh:mm A")}`}</span>
                                            </div>
                                            <div className="passenger-info">
                                                <span>{`${schedule.psgInfo ? `${schedule.psgInfo.fullName}` : ""}`}</span>
                                            </div>
                                        </div>
                                        <div className={'location'}>
                                            <Image
                                                src={pickupIcon}
                                            />
                                            <div className="address-container text-ellipsis">
                                                <span>{schedule.request.pickup.address}</span>
                                            </div>
                                        </div>
                                        {
                                            schedule.request.destination.address && <div className={'location destination'}>
                                                <Image
                                                    src={destinationIcon}
                                                />
                                                <div className="address-container text-ellipsis">
                                                    <span>{schedule.request.destination.address}</span>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                </div>
                            }

                        }) : <div className="job-not-found" colSpan={3}>
                            <Translate value={'cue.Driver_No_Job_found'} />
                        </div>

                }

            </Modal.Body>
        </Modal>

    }
}

export default DriverCalendarInfo