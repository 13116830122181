import moment from 'moment';
import ReportUtils from '../reports/base/utils';
import { convertFormat24h, formatNumber } from '../../utils/commonFunctions';

export const bookingColumn = [
  {
    key: 'table-selectbox',
    label: '',
    width: 60,
    fixed: true,
    align: 'center',
  },
  {
    key: 'bookId',
    label: 'cue.Bookingid',
    width: 150,
    fixed: true,
    textEllipsis: true,
  },
  {
    key: 'refId',
    label: 'invoice.refId',
    width: 100,
  },
  {
    key: 'status',
    label: 'invoice.Status',
    width: 190,
    customCell: (data) => {
      return ReportUtils.renderBookingStatus(data.status);
    },
    textEllipsis: true,
  },
  {
    key: 'pickupTime',
    label: 'invoice.pickupTime',
    width: 190,
    customCell: (data, index, fleetSetting) => {
      return data?.time?.pickUpTime
        ? moment(data?.time?.pickUpTime).format(
            `YYYY-MM-DD, ${convertFormat24h(fleetSetting?.format24Hour)}`
          )
        : '';
    },
    textEllipsis: true,
    sortName: 'time.pickUpTime',
  },
  {
    key: 'completedTime',
    label: 'invoice.completedTime',
    width: 190,
    customCell: (data, index, fleetSetting) => {
      return data?.finishedAt
        ? moment(data?.finishedAt).format(
            `YYYY-MM-DD, ${convertFormat24h(fleetSetting?.format24Hour)}`
          )
        : '';
    },
    textEllipsis: true,
    sortName: 'finishedAt',
  },
  {
    key: 'request.pickup.address',
    label: 'invoice.pickup',
    width: 190,
    textEllipsis: true,
  },
  {
    key: 'request.destination.address',
    label: 'invoice.destination',
    width: 190,
    textEllipsis: true,
  },
  {
    key: 'customerName',
    label: 'invoice.customerName',
    width: 190,
    textEllipsis: true,
  },
  {
    key: 'completedInfo.total',
    label: 'invoice.totalAmount',
    width: 180,
    textEllipsis: true,
    sortName: 'completedInfo.total',
    customCell: (data) => {
      return formatNumber(data.completedInfo?.total || data.request.estimate?.fare.etaFare || 0);
    },
  },
  {
    key: 'amountDue',
    label: 'invoice.amountDue',
    customCell: (data) => {
      return formatNumber(
        (data?.completedInfo?.total || data.request?.estimate?.fare?.etaFare || 0) - (data?.paidAmount || 0)
      );
    },
    width: 180,
    textEllipsis: true,
    sortName: 'amountDue',
  },
];
