import { carTypeFetchByCompanyApi, etaFareApi, carTypeBaseLocationApi, etaFareIntercityApi } from '../constants/ApiConfigs';
import { callApi, callApiBase, callApiWithoutDispatch } from '../utils/apiUtils';
import moment from 'moment';
import _ from 'lodash';
import { SERVICE_TYPE } from '../constants/commondata';
import uuidv4 from 'uuid/v4';

export const NEW_INTERCITY_BOOKING_FROM_EXISTED =
  'NEW_INTERCITY_BOOKING_FROM_EXISTED';
export const RESET_ROUTE =
  'RESET_ROUTE';
export const NEW_INTERCITY_BOOKING_FORM_REQUEST =
  'NEW_INTERCITY_BOOKING_FORM_REQUEST';
export const CLOSE_INTERCITY_BOOKING_FORM = 'CLOSE_INTERCITY_BOOKING_FORM';
export const UPDATE_COMMON_DATA = 'UPDATE_COMMON_DATA';
export const ETA_INTERCITY_BOOKING = 'ETA_INTERCITY_BOOKING';
export const ETA_INTERCITY_BOOKING_FAILED = 'ETA_INTERCITY_BOOKING_FAILED';
export const ETA_INTERCITY_BOOKING_SUCCEEDED =
  'ETA_INTERCITY_BOOKING_SUCCEEDED';
export const FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST = 'FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST';
export const FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS = 'FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS';
export const FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE = 'FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE';

export function newIntercityBookingRequest(bookType) {
  return { type: NEW_INTERCITY_BOOKING_FORM_REQUEST, bookType };
}
export function closeIntercityBookingForm() {
  return { type: CLOSE_INTERCITY_BOOKING_FORM };
}

export function updateCommonData(data) {
  return { type: UPDATE_COMMON_DATA, data };
}

export function newBookingFromBooking(data) {
  return {
    type: NEW_INTERCITY_BOOKING_FROM_EXISTED,
    data: data,
  };
}

export function resetRoute(data) {
  return {
    type: RESET_ROUTE,
    data: {},
  };
}

function getEtaFareRequest() {
  return {
    type: ETA_INTERCITY_BOOKING,
  };
}

function getEtaFareSuccess(data) {
  return {
    type: ETA_INTERCITY_BOOKING_SUCCEEDED,
    data,
  };
}

function getEtaFareFailure(error) {
  return {
    type: ETA_INTERCITY_BOOKING_FAILED,
    error,
  };
}

function getCompulsoryServices({ additionalServices, carType }) {
  const validItems = _.filter(additionalServices, service => service.vehicleType.findIndex(o => o.vehicleType === carType.vehicleType) !== -1 && service.serviceType === SERVICE_TYPE.Compulsory)
  return _.map(validItems, '_id')
}

export function etaFare() {
  return async (dispatch, getState) => {
    dispatch({ type: ETA_INTERCITY_BOOKING });
    const state = getState();
    const {
      intercityBooking: { common },
      auth: { selectedFleet },
      bookingDetail
    } = state;
    if (!common.routeSelected || !common.carType || !common.pickupTime || !common.pickupDate || !common.destinationLocation || !common.pickupLocation) {
      return dispatch(getEtaFareFailure('Invalid request data'));
    }
    if (common.etaFare && common.etaFare.markupValue) {
      return ;
    }
    const options = {
      fleetId: selectedFleet.fleetId,
      editFare: common.editFare,
      vehicleTypeId: common.carType.vehicleType,
      pickup: [common.pickupLocation.lng, common.pickupLocation.lat],
      destination: [common.destinationLocation.lng, common.destinationLocation.lat],
      bookFrom: "CC",
      pickupTime: moment(common.pickupDate).hour(common.pickupTime.hour).minute(common.pickupTime.minute).format("YYYY-MM-DD HH:mm"),
      userId: "",
      city: common.pickupLocation.city,
      pricingType: 0,
      typeRate: 3,
      intercityRouteId: common.routeSelected._id,
      routeNumber: common.routeSelected.routeNumber,
      seat: common.paxNumber || 1,
      corporateId: common.corporateInfo._id,
      luggage: common.luggageNumber || 0,
      services: _.uniq(_.keys(common.additionalService).filter(k => common.additionalService[k]).concat(getCompulsoryServices(common))),
      promoCode: _.get(common.promo, 'promoCode', ''),
      tripType: common.tripType || '',
      paymentMethod: common.paymentMethod,
      serviceType: 'intercity',
      bookId: _.get(bookingDetail, 'data.bookId', '')
    };
    options.sessionToken = uuidv4()
    const config = {
      method: 'post',
      body: JSON.stringify(options),
    };
    try {
      const res = await callApiWithoutDispatch(etaFareIntercityApi, config)
      dispatch(getEtaFareSuccess({
        ...res,
        sessionToken: options.sessionToken
      }));
    } catch (error) {
      console.log("etaFare -> error", error)
      dispatch(getEtaFareFailure(error));
    }
  };
}

export function getService(param) {
  const config = {
    method: 'post',
    body: JSON.stringify(param),
  };
  return callApi(
    carTypeBaseLocationApi,
    config,
    null,
    null,
    null,
    false
  );
}

function fetchCarTypeByCompanyRequest(data) {
  return {
    type: FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST,
    data
  }
}

function fetchCarTypeByCompanySuccess(data) {
  return {
    type: FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS,
    data: data.res
  }
}

function fetchCarTypeByCompanyFailure(error) {
  return {
    type: FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE,
    error
  }
}
export function fetchCarTypeByCompany(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    carTypeFetchByCompanyApi,
    config,
    fetchCarTypeByCompanyRequest(options),
    fetchCarTypeByCompanySuccess,
    fetchCarTypeByCompanyFailure
  );
}
