import { Translate } from 'react-redux-i18n';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'passengerName',
    label: 'report.result.referralHistory.detailFields.passengerName',
  },
  {
    key: 'firstBookingDate',
    label: 'report.result.referralHistory.detailFields.firstBookingDate',
    mutate: ReportUtils.formatTime
  }/* ,
  {
    key: 'firstBookingDateGMT',
    label: 'report.result.referralHistory.detailFields.firstBookingDateGMT',
    mutate: ReportUtils.formatTime
  } */
];