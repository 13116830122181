import React, { useMemo, useState } from 'react';
import styles from '../../styles/detail.module.scss';
import FormDriver from '../formDriver';
import HeaderGr from '../headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import FormVehicle from '../../../vehicle/components/formVehicle';
import * as driverActions from '../../../../actions/driverAction';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

const DriverAddNewVehicle = (props, context) => {
  const breadcrumbs = useMemo(() => ([
    {
      path: '/driver',
      name: I18n.t('driver.Driver'),
    },
    {
      path: `/driver/view/${props.driverId}/info`,
      name: I18n.t('driver.detailDriver'),
    },
    {
      path: `/driver/new_vehicle/${props.driverId}`,
      name: I18n.t('driver.detailDriver'),
    },
  ]), []);

  const callBackAddNewVehicle = async (data) => {
    try {
      const respone = await props.driverActions.addVehicle({
        vehicleId: data._id,
        vehicleType: data?.type?.name,
        fleetId: props.auth?.selectedFleet?.fleetId,
        userId: props?.driverId,
      });
      if (respone?.res?.status === 200) {
        props.updateVehicleIds(respone?.res?.vehicleIds || [], data);
        closeAddVehiclePage();
        context.notification(
          'success',
          I18n.t('carSetting.Create_car_success')
        );
      } else {
        context.notification(
          'error',
          I18n.t('errors.' + respone.error.errorCode)
        );
      }
    } catch (error) {
      console.error('Error adding new vehicle:', error);
    }
  };

  const closeAddVehiclePage = () => {
    props.navigate.push(`/driver/view/${props.driverId}/vehicle`);
  }

  return (
    <div className={styles['layout']}>
      <HeaderGr
        breadcrumbs={breadcrumbs}
        navigate={props.navigate}
        title={I18n.t('driver.addNewVehicle')}
      />
      <FormVehicle
        action="add"
        navigate={props.navigate}
        addNewFromDriverModule
        callBackAddNewVehicle={callBackAddNewVehicle}
        companyIdFromDriverModule={props?.companyId}
        closeAddVehiclePage={closeAddVehiclePage}
      />
    </div>
  );
};

DriverAddNewVehicle.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    driverActions: bindActionCreators(driverActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DriverAddNewVehicle);
