export const PartnerTypeColumns = [
    {
      key: "Name",
      label: "mDispatcherType.Name",
      cellClass: "cell-align-toolbar",
      headerClass: "header-align-toolbar",
      fixed: true
    },
    {
      key: "actions",
      label: "mDispatcherType.Actions",
      align: "right",
      cellClass: "table-actions"
    }
  ];
