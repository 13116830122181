import React from 'react'
import _ from 'lodash';
import moment from 'moment';
import tz from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form, Col } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CurrencyFormatter from 'currency-formatter';

import { parStart } from '../../../../utils/commonFunctions';
import * as intercityBookingActions from '../../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../../components/validator';
import DriverSelect from './DriverSelect';

function DriverInfo(props) {

  const { auth, auth: { selectedFleet: { format24Hour, timezone, unitDistance } }, language: { locale: language }, tripInfo, onChangeDrvInfo, isSubmited, validatorCallback, valid, drvInfo, isViewOnly } = props;
  const timeFormat = format24Hour ? 'HH:mm' : 'hh:mm A';

  let isDispatchDisable = isViewOnly ? isViewOnly : false;

  return (
    <FormGroup>

      <Col xs={12} >
        <FormGroup>
          <Form.Label>
            <Translate value="tripDetails.nameDriver" />
          </Form.Label>

          <FormControl
            type="text"
            className={"form-custom"}
            // onChange={e => {
            //   this.handleInputChange("name", e);
            // }}
            value={
              tripInfo && tripInfo.drvInfo ? (tripInfo.drvInfo.fullName + ' - ' + tripInfo.drvInfo.phone) : ""
            }
            placeholder={I18n.t("tripDetails.nameDriver")}
            disabled={props.disabled}
          />
        </FormGroup>
      </Col>

      <Col xs={12} >
        <FormGroup>
          <Form.Label>
            <Translate value="tripDetails.vehicleType" />
          </Form.Label>

          <FormControl
            type="text"
            className={"form-custom"}
            // onChange={e => {
            //   this.handleInputChange("name", e);
            // }}
            value={
              tripInfo && tripInfo.drvInfo ? (tripInfo.drvInfo.vehicleType + ` (${tripInfo.drvInfo.plateNumber})`) : ""
            }
            placeholder={I18n.t("tripDetails.vehicleType")}
            disabled={props.disabled}
          />
        </FormGroup>
      </Col>

      <Col xs={12} >
        <FormGroup>
          <Form.Label>
            <Translate value="tripDetails.assignDriver" />
          </Form.Label>

          <DriverSelect
            tripInfo={tripInfo}
            drvInfo={drvInfo}
            onChangeDrvInfo={onChangeDrvInfo}
            isSubmited={isSubmited}
            validatorCallback={validatorCallback}
            valid={valid}
            isDispatchDisable={isDispatchDisable}
          />
        </FormGroup>
      </Col>

    </FormGroup>
  )
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    language: state.i18n,
    routeSelected: state.intercityBooking.common.routeSelected,
    schedules: state.intercityBooking.common.schedules,
    pickupDate: state.intercityBooking.common.pickupDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DriverInfo);