import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import * as settingActions from '../../../actions/settingActions';
import { reportAirlineApi } from '../../../constants/ApiConfigs';

const bookingService = [
  {
    value: 'localBooking',
    label: 'report.query.bookingServiceItem.localBooking'
  },
  { value: 'homeFleet', label: 'report.query.bookingServiceItem.homeFleet' }
];

class ReportCorporate extends React.Component {
  constructor() {
    super();
    this.state = {
      corporates: []
    };
  }

  UNSAFE_componentWillMount() {
    if ((this.props.user || {}).corporateId) {
      this.setState({ corporates: false });
    } else {
      this.props.actions
        .fetchAllCorporateForSelection(this.props.selectedFleet.fleetId,true)
        .then(resp => {
          const corporates = resp.res.list.map(doc => ({
            value: doc._id,
            label: doc.name
          }));
          corporates.unshift({
            value: 'all',
            label: I18n.t('report.query.common.all')
          });
          this.setState({ corporates });
        });
    }
  }

  render() {
    return (
      <div className="content">
        <BaseReport
          tittle="Airline"
          fieldMappings={fieldMappings}
          apiurl={reportAirlineApi}
          dateRange
          airline={this.state.corporates}
          bookingService={bookingService}
          currency
          company
          paymentMethod
          search="report.query.search.corporate"
          settings
          favoriteFilter
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCorporate);
