import React from "react";

class RadioButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="radio-inner">
        <input
          type="radio"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <label className="radio-text" for={this.props.id}>
          {this.props.value}
        </label>
        <div className="radio-check" />
      </div>
    );
  }
}

export default RadioButton;