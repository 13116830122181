import React, { Component } from 'react';
import { GoogleMap, Polyline } from '@react-google-maps/api';
import pickupIcon from './../../../assets/images/icons/pickup.svg';
import destinationIcon from './../../../assets/images/icons/destination.svg';
import puPointsIcon from './../../../assets/images/icons/puPoints.svg';
import doPointsIcon from './../../../assets/images/icons/doPoints.svg';
import { locationType } from '../../../constants/commondata';
import { QQMap, QQMarker, QQPolyline, QQUltis } from '../../../components/qqMap';
import { CCLiteCommonFunc, decodePolyline } from '../../../utils/commonFunctions';

class BookingMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      markers: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Update markers when map or points change
    if (this.state.map !== prevState.map ||
      prevProps.puPoints !== this.props.puPoints ||
      prevProps.doPoints !== this.props.doPoints) {
      this.renderMarkers();
    }

    // Update bounds when points or center changes
    if (this.state.map &&
      (prevProps.puPoints !== this.props.puPoints ||
        prevProps.doPoints !== this.props.doPoints ||
        prevProps.center !== this.props.center)) {
      this.updateBounds();
    }
  }

  componentWillUnmount() {
    this.state.markers.forEach(marker => marker.map = null);
  }

  updateBounds = (mapInstance) => {
    let { map } = this.state || {};
    map = map || mapInstance;
    const { puPoints, doPoints, center } = this.props;
    if (!map || (!puPoints.length && !doPoints.length)) return;

    const bounds = new window.google.maps.LatLngBounds();
    let numberBounds = 0;

    if (center) {
      bounds.extend(center);
    }

    const points = [...puPoints, ...doPoints];
    points.forEach(point => {
      if (point.address && point.address.geo[1]) {
        numberBounds += 1;
        bounds.extend(new window.google.maps.LatLng(
          point.address.geo[1],
          point.address.geo[0]
        ));
      }
    });

    if (numberBounds > 1) {
      map.fitBounds(bounds);
    }
  };

  renderMarkers = () => {
    const { map } = this.state;
    if (!map) return;
    const isPickupCanDrag = this.props.isPickupCanDrag !== false;
    const isDestinationCanDrag = this.props.isDestinationCanDrag !== false;

    // Clear existing markers
    this.state.markers.forEach(marker => marker.map = null);
    const newMarkers = [];

    // Add pickup point markers
    this.props.puPoints.forEach((point) => {
      if (point.address && point.address.geo[1]) {
        const position = {
          lat: point.address.geo[1],
          lng: point.address.geo[0]
        };

        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          position,
          puPointsIcon,
          point.order.toString(),
          100, 28, 28, 16, 5,
          point.from !== locationType.thirdParty && isPickupCanDrag,
          point.from !== locationType.thirdParty && isPickupCanDrag
        );

        if (point.from !== locationType.thirdParty && isPickupCanDrag) {
          marker.addListener('dragend', (event) => {
            this.props.handlePickupDrapEnd(event, point.id);
          });
        }
        marker.map = map;
        newMarkers.push(marker);
      }
    });

    // Add delivery point markers
    this.props.doPoints.forEach((point) => {
      if (point.address && point.address.geo[1]) {
        const position = {
          lat: point.address.geo[1],
          lng: point.address.geo[0]
        };
        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          position,
          doPointsIcon,
          point.order.toString(),
          100, 28, 28, 16, 5,
          point.from !== locationType.thirdParty && isDestinationCanDrag,
          point.from !== locationType.thirdParty && isDestinationCanDrag
        );
        if (point.from !== locationType.thirdParty && isDestinationCanDrag) {
          marker.addListener('dragend', (event) => {
            this.props.handleDestinationDrapEnd(event, point.id);
          });
        }
        marker.map = map;
        newMarkers.push(marker);
      }
    });
    this.setState({ markers: newMarkers });
  };

  hasAnyLocation = (arrPoint) => {
    return arrPoint.some(point => point.hasLocation === true);
  };

  onMapLoad = (mapInstance) => {
    this.setState({ map: mapInstance });
    this.updateBounds(mapInstance);
  };

  renderGoogleMap = () => {
    const { center, puPoints, doPoints, showNavigationPUDO, directions } = this.props;
    return (
      <GoogleMap
        onLoad={this.onMapLoad}
        center={center}
        zoom={14}
        options={{
          gestureHandling: 'greedy',
          mapId: process.env.REACT_APP_MAP_ID
        }}
        mapContainerClassName="fill"
      >
        {this.hasAnyLocation(puPoints) && this.hasAnyLocation(doPoints) &&
          showNavigationPUDO && directions?.points && (
            <Polyline
              path={decodePolyline(directions.points)}
              options={{
                strokeColor: '#419AF2',
                strokeOpacity: 0.9,
                strokeWeight: 6,
                fillColor: '#419AF2',
                fillOpacity: 0.9,
                zIndex: 2
              }}
            />
          )}
      </GoogleMap>
    );
  };

  renderQQMap = () => {
    const {
      center, handleMapLoad, locationPickUp, handlePickupDrapEnd,
      locationDestination, handleDestinationDrapEnd, directions, showNavigationPUDO
    } = this.props;

    let qqCenter = null;
    let drNew;

    if (directions?.points) {
      drNew = QQUltis.PolylineDecompression(decodePolyline(directions?.points));
    }

    if (center) {
      qqCenter = new window.qq.maps.LatLng(center.lat, center.lng);
    }

    return (
      <QQMap
        className="fill"
        onLoad={handleMapLoad}
        options={{ center: qqCenter }}
        locationPickUp={locationPickUp}
        locationDestination={locationDestination}
      >
        {locationDestination && (
          <QQMarker
            mapkey="dest"
            options={{
              icon: destinationIcon,
              draggable: locationDestination.from !== locationType.thirdParty,
              position: locationDestination
            }}
            events={{ dragend: handleDestinationDrapEnd }}
          />
        )}
        {locationPickUp && (
          <QQMarker
            mapkey="pickup"
            options={{
              icon: pickupIcon,
              draggable: locationPickUp.from !== locationType.thirdParty,
              position: locationPickUp
            }}
            events={{ dragend: handlePickupDrapEnd }}
          />
        )}
        {showNavigationPUDO && drNew && (
          <QQPolyline path={drNew} mapkey={'1'} />
        )}
      </QQMap>
    );
  };

  render() {
    return this.props.isChina ? this.renderQQMap() : this.renderGoogleMap();
  }
}

export default BookingMap;
