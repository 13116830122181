import ReportUtils from './../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.prepaidTopUp.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'topUpId',
    label: 'report.result.prepaidTopUp.topUpId',
    summary: 'topUpId',
  },
  {
    key: 'transactionId',
    label: 'report.result.prepaidTopUp.transactionId',
    summary: 'transactionId',
  },
  {
    key: 'name',
    label: 'report.result.prepaidTopUp.name',
    summary: 'name',
  },
  {
    key: 'company',
    label: 'report.result.prepaidTopUp.company',
    summary: 'company',
  },
  {
    key: 'prepaidType',
    label: 'report.result.prepaidTopUp.transactionType',
    mutate: ReportUtils.convertPrepaidTransaction,
  },
  {
    key: 'operatorName',
    label: 'report.result.prepaidTopUp.Operator',
  },
  {
    key: 'reason',
    label: 'report.result.prepaidTopUp.Reason',
  },
  {
    key: 'amount',
    label: 'report.result.prepaidTopUp.amount',
    summary: 'amount',
  },
  {
    key: 'newAmount',
    label: 'report.result.prepaidTopUp.newAmount',
    summary: 'newAmount',
  },
  {
    key: 'paidBy',
    label: 'report.result.prepaidTopUp.paidBy',
    summary: 'paidBy',
    referenceKey: 'cardMask',
    mutate: (value, doc, params = {}) => {
      if (doc.prepaidType === 'editBalance') return '';
      return value;
    },
  },
];

export const fareFields = ['amount', 'newAmount'];
