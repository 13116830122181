import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportReferralRefersDriverRefereeApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
 function DriverRefersReferee({selectedFleet,user}) {
   const modules = _.get(selectedFleet, 'modules', []);
   const report = _.find(modules, {"name": 'Report'});
   let checkExport = _.findIndex(report.actions, {"name": "Driver refers drivers (Referee)", "Export": true});
   let checkOwner = !user.isAdmin && typeof user.roles.fleetId !== 'undefined' ? user.roles.fleetId.ownerId === user._id ? true : false : true ;
   checkExport = user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
   return (
    <div className="content">
      <BaseReport
        tittle="Driver refers drivers - Referee"
        fieldMappings={fieldMappings}
        apiurl={reportReferralRefersDriverRefereeApi}
        company
        driverReferrerId
        refereeId
        dateRange
        statusDriver
        noexport={!checkExport}
      />
    </div>
  );
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(DriverRefersReferee);