import React from 'react';
import CurrencyFormater from 'currency-formatter';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.cashWallet.dateAndTime',
    mutate: ReportUtils.formatTime,
    fixed: true,
  },
  {
    key: 'driverName',
    label: 'report.result.cashWallet.driverName',
    fixed: true,
  },
  {
    key: 'phoneNumber',
    label: 'report.result.cashWallet.phoneNumber',
  },
  {
    key: 'company',
    label: 'report.result.cashWallet.company',
  },
  {
    key: 'type',
    label: 'report.result.cashWallet.type',
    mutate: (value, doc) => {
      if (doc && doc.destination === "WingBank") {
        return I18n.t("report.query.transactionTypeItem.WingBank").format(value);
      }
      return I18n.t(`report.query.transactionTypeItem.${value}`)
    }
  },
  {
    key: 'bookId',
    label: 'report.result.cashWallet.bookingId',
  },
  {
    key: 'operatorName',
    label: 'report.result.cashWallet.operatorName',
  },
  {
    key: 'reason',
    label: 'report.result.cashWallet.reason',
    width: 300,
    mutate: (value, doc, options) => {
      let symbol = doc.currencyISO;
      if (doc.currencyISO) {
        const currency = CurrencyFormater.findCurrency(doc.currencyISO);
        if (currency) {
          symbol = currency.symbol;
        }
      }
      switch (doc.type) {
        case 'payout':
          return I18n.t('report.result.cashWallet.payoutReason').format(
            doc.reason,
            symbol,
            doc.changedAmount
          );
        case 'payoutDriver':
          return I18n.t('report.result.cashWallet.payoutReason').format(
            doc.reason,
            symbol,
            doc.changedAmount
          );
        // case 'EWALLET':
        //   return I18n.t("withdrawal.transferToEWallet").format(doc.reason);
        case 'tipAfterCompleted':
          if (_.get(options, 'fleet.drvPayout.gateway') !== 'StripeConnect') {
            return I18n.t(
              'report.result.bookingDetails.tipAfterCompletedDetails'
            ).format(symbol, doc.changedAmount, doc.tipTransactionFee);
          }
          return value;
        default:
          return value;
      }
    },
  },
  {
    key: 'changedAmount',
    label: 'report.result.cashWallet.amount',
    negativable: true,
    summary: 'changedAmount',
    mutate: (value, doc) => {
      switch (doc.type) {
        case 'bankAccount':
        case 'creditWallet':
        case 'eWallet':
        case 'payout':
          return ReportUtils.numberFormat(value * -1, 2, 2);
        case 'payoutDriver':
          return ReportUtils.numberFormat(value * -1, 2, 2);
        default:
          return ReportUtils.numberFormat(value, 2, 2);
      }
    },
  },
  {
    key: 'newBalance',
    label: 'report.result.cashWallet.newAmount',
    mutate: (value) => ReportUtils.numberFormat(value, 2, 2),
  },
  {
    key: 'currencyISO',
    label: 'report.result.cashWallet.currency',
  },
  {
    key: 'sendPayoutButton',
    label: 'report.result.cashWallet.sendPayout',
    mutate: (value, doc) => {
      return doc.type === 'payout' || doc.type === 'payoutDriver' ? (
        <span className="glyphicon glyphicon-envelope qup-color send-receipt-button" />
      ) : null;
    },
    link: {
      notMergeParams: true,
      name: 'showSendPayout',
    },
    width: 120,
    isAlwayShow: true,
  },
];

export const fareFields = ['changedAmount'];
