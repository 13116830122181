
export const NEW_SHUTTLE_REQUEST = "NEW_SHUTTLE_REQUEST";
export const CLOSE_SHUTTLE_FORM = 'CLOSE_SHUTTLE_FORM';
export const SHUTTLE_REQUEST = "SHUTTLE_REQUEST";
export const SHUTTLE_SUCCESS = "SHUTTLE_SUCCESS";
export const SHUTTLE_FAILURE = "SHUTTLE_FAILURE";

export function newShuttleBookingRequest() {
    return { type: NEW_SHUTTLE_REQUEST };
}

export function closeShuttleBookingForm() {
    return { type: CLOSE_SHUTTLE_FORM };
}


function shuttleRequest() {
    return {
      type: SHUTTLE_REQUEST
    }
  };
  
  function shuttleSuccess(data) {
    return {
      type: SHUTTLE_SUCCESS,
      payload: data.res
    }
  };
  
  function shuttleFailure(error) {
    return {
      type: SHUTTLE_FAILURE,
      payload: error
    }
  };