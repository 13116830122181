import ReportUtils from './../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.mDispatcher.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.mDispatcher.bookingId',
    summary: 'totalBookings',
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    key: 'mDispatcherName',
    label: 'report.result.mDispatcher.mDispatcherName',
    summary: 'mDispatcherName',
  },
  {
    key: 'mDispatcherType',
    label: 'report.result.mDispatcher.mDispatcherType',
    summary: 'mDispatcherType',
  },
  {
    key: 'subtotal',
    label: 'report.result.mDispatcher.subtotal',
    summary: 'subtotal',
  },
  {
    key: 'commission',
    label: 'report.result.mDispatcher.commission',
    summary: 'commission',
  },
  {
    key: 'paidBy',
    label: 'report.result.mDispatcher.paidBy',
    summary: 'paidBy',
    mutate: (value, doc) => value && value.replace('mDispatcher', 'Partner'),
  },
];

export const fareFields = ['subtotal', 'commission'];
