import {
    mDispatcherCreateApi,
    mDispatcherDeleteApi,
    mDispatcherDetailsApi,
    mDispatcherFindApi,
    mDispatcherUpdateApi,
    mDispatcherActiveApi,
    mDispatcherMultipleActiveApi,
    mDispatcherMultipleDeleteApi,
    mDispatcherResetPasswordApi,
    checkExistmDispatcherPhoneApi,
    mDispatcherCountApi
} from '../constants/ApiConfigs'
import {
    callApi,
} from "../utils/apiUtils";

export function create(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function update(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function details(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function find(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherFindApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function count(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherCountApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteMdispatchers(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherMultipleDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteMdispatcher(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function active(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function multiActive(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherMultipleActiveApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function resetPassword(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        mDispatcherResetPasswordApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function checkExistmDispatcherPhone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        checkExistmDispatcherPhoneApi,
        config,
        null,
        null,
        null,
        false
    );
}



