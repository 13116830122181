import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import "./settings.scss";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

class FleetInfoSetting extends Component {
  constructor() {
    super();
    this.state = {
      formData: {}
    };
  }
  componentDidMount() {
    this.props.settingActions
      .fleetInfo({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then(data => {
        if (data.fare) {
          this.setState({ formData: data.fare });
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("fleetInfo.Load_fleet_info_fail")
            );
          }
        }
      });
  }
  render() {
    return (
      <div className="content vertical-flow-auto">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Fleet_name" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.name
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Phone_number" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.phone
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Email_address" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.email
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Country" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.country
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Time_zone" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.timezone
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Address" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.address
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Website" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.website
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Currency" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.currencies.map(c => {
                        return c.iso;
                      })
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="fleetInfo.Distance_unit" />
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                value={
                  this.props.settings.fleet
                    ? this.props.settings.fleet.unitDistance
                    : ""
                }
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FleetInfoSetting);
