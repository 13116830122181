import React from 'react';
import BaseReport from '../base';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as fieldMappings from './fields';
import { reportPassengerRefersPaxApi, reportReferralRefersPaxExportApi } from '../../../constants/ApiConfigs';

 function PassengerRefersPassengers(props) {
  const modules = _.get(props.selectedFleet, 'modules', []);
  const report = _.find(modules, {"name": 'Report'});
  let checkExport = _.findIndex(report.actions, {"name": "Passenger refers Passengers", "Export": true});
  let checkOwner = !props.user.isAdmin &&  typeof props.user.roles.fleetId !== 'undefined' ? props.user.roles.fleetId.ownerId === props.user._id ? true : false : true ;
  checkExport = props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
     if(!props.user.isAdmin && props.selectedFleet.fleetId === "applecabssouth"){
         checkExport = false;
     }
  return (
    <div className="content">
      <BaseReport
        tittle="Passenger refers Passengers"
        fieldMappings={fieldMappings}
        apiurl={reportPassengerRefersPaxApi}
        customer
        dateRange
        search="report.query.search.passengerRefersPassengers"
        noexport={!checkExport}
      />
    </div>
  );
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(PassengerRefersPassengers);