import moment from 'moment'

export const fields = [{
  key: "date",
  label: "report.result.totalAdjustment.date",
  summary: "date",
  mutate: value => {
    if (!value) return value
    else {
      return moment(value).format('YYYY-MM-DD HH:mm A')
    }
  }
}, {
  key: "bookingId",
  label: "report.result.totalAdjustment.bookingId",
  summary: "bookingId"
}, {
  key: "type",
  label: "report.result.totalAdjustment.type",
  summary: "type"
}, {
  key: "username",
  label: "report.result.totalAdjustment.username",
  summary: "username"
}, {
  key: "oldAmount",
  label: "report.result.totalAdjustment.oldAmount",
  summary: "oldAmount"
}, {
  key: "differenceAmount",
  label: "report.result.totalAdjustment.markupDifference",
  summary: "differenceAmount"
}, {
  key: "newAmount",
  label: "report.result.totalAdjustment.newAmount",
  summary: "newAmount"
}, {
  key: "reason",
  label: "report.result.totalAdjustment.reason",
  summary: "reason",
  textEllipsis: true
}]

export const fareFields = [
  "oldAmount",
  "newAmount"
]