import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

export default function SurchargeDistanceWarning(props) {
    const { data, dataInit, range } = props;
    const [distanceRange, setDistanceRange] = useState([]);
    const [initDistanceRange, setInitDistanceRange] = useState([]);
    const [showWarning, setShowWarning] = useState({});

    useEffect(() => {
        if (data && data.distanceRange) {
            setDistanceRange(data.distanceRange)
        };
    }, [data]);

    useEffect(() => {
        if (dataInit && dataInit.distanceRange) {
            setInitDistanceRange(dataInit.distanceRange)
        };
    }, [dataInit]);

    useEffect(() => {
        let datachange = [];
        distanceRange.forEach((item, index) => {
            if (initDistanceRange[index]) {
                let initialValue = parseFloat(initDistanceRange[index].surchargeValue) || 0;
                let onChangeValue = parseFloat(item.surchargeValue) || 0;
                let percentDifference = (onChangeValue * 100) / initialValue;
                if (initialValue < onChangeValue) {
                    if ((percentDifference - range) > 100) {
                        datachange.push({ initialValue, onChangeValue })
                    };
                };
                if (initialValue > onChangeValue) {
                    if ((percentDifference + range) < 100) {
                        datachange.push({ initialValue, onChangeValue })
                    };
                };
            };
        });
        setShowWarning(datachange[0] ? datachange[0] : {});
    }, [distanceRange, initDistanceRange]);

    return (
        <div>
            {showWarning && showWarning.initialValue && showWarning.onChangeValue ?
                <span style={{ color: "#F2D45F" }}>
                    {I18n.t('message.warningInputChangeOver')}
                </span>
                : null}
        </div>
    )
}
