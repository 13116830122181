import React, { PureComponent } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../../components/validator';

class InputFromToDistance extends PureComponent {
  state = {
    valid: {},
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
    this.props.onChangeValid(this.state.valid);
  };

  render() {
    const {
      isSubmmitted,
      editable,
      rateInfo,
      selectedFleet,
      handleInputChange,
      from,
      to,
    } = this.props;
    const { valid } = this.state;

    const isFirstFrom =
      from === 'secondDistanceFrom' || from === 'thirdDistanceFrom'
        ? false
        : true;
    return (
      <FormGroup className="direction-row">
        <Col xs={12} md={6}>
          <FormGroup
            className={`direction-row from-to qup-input-group ${
              isSubmmitted ? (valid[from] === false ? 'error' : null) : null
            }`}
          >
            <Form.Label>
              <Translate value="regularSetting.From" />
            </Form.Label>
            <InputGroup
              className={'single-addon-right' + (!editable ? ' disabled' : '')}
            >
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={(e) => {
                  handleInputChange(from, e);
                }}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, (e) => {
                    handleInputChange(from, e);
                  });
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, (e) => {
                    handleInputChange(from, e);
                  });
                }}
                value={rateInfo ? rateInfo[from] : ''}
                placeholder={I18n.t('regularSetting.From')}
                disabled={!editable || !isFirstFrom}
              />
              <InputGroup.Append>
                <InputGroup.Text>{selectedFleet.unitDistance}</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Validator
              id={from}
              callback={this.validatorCallback}
              disabled={!editable}
            >
              <ValidCase
                valid={rateInfo[from] == '' || !isNaN(rateInfo[from])}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>
        </Col>

        <Col xs={12} md={6}>
          <FormGroup
            className={`direction-row from-to qup-input-group ${
              isSubmmitted ? (valid[to] === false ? 'error' : null) : null
            }`}
          >
            <Form.Label>
              <Translate value="regularSetting.To" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <InputGroup
              className={'single-addon-right' + (!editable ? ' disabled' : '')}
            >
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={(e) => {
                  handleInputChange(to, e);
                }}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, (e) => {
                    handleInputChange(to, e);
                  });
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, (e) => {
                    handleInputChange(to, e);
                  });
                }}
                value={rateInfo ? rateInfo[to] : ''}
                placeholder={I18n.t('regularSetting.To')}
                disabled={!editable}
              />
              <InputGroup.Append>
                <InputGroup.Text>{selectedFleet.unitDistance}</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Validator
              id={to}
              callback={this.validatorCallback}
              disabled={!editable}
            >
              <ValidCase
                valid={!Validation.isStringEmpty(rateInfo[to])}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={rateInfo[to] == '' || !isNaN(rateInfo[to])}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={parseFloat(rateInfo[to]) >= parseFloat(rateInfo[from])}
                message={I18n.t(
                  'messages.commonMessages.distance_from_equa_or_smaller'
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>
        </Col>
      </FormGroup>
    );
  }
}

export default InputFromToDistance;
