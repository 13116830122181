/* global google */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import {
  FormGroup,
  Button,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
  Pagination,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import _, { forEach } from 'lodash';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

import { ThirdPartyColumns } from './tableHeaderData';
import TableActions from '../../../components/table/tableAction/TableActions';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import AddEditThirdPartyLocationModal from './components/AddEditThirdPartyLocationModal';
import ConfirmDelete from './components/ConfirmDeleteLocationForm';
import Filter from '../../reports/base/components/Filter';
import { BsSearch } from 'react-icons/bs';

const TextEllipsisOverlay = ({ content }) => (
  <OverlayTrigger
    placement="top"
    delayShow={300}
    overlay={
      <Tooltip id="tooltip">
        <span>{content}</span>
      </Tooltip>
    }
  >
    <span className="text-ellipsis cursor-pointer">{content}</span>
  </OverlayTrigger>
);

class ThirdParty extends Component {
  constructor() {
    super();
    this.state = {
      activePage: 0,
      numItemsPerPage: 20,
      dialogData: {},
      dataList: {
        list: [],
        page: 0,
        limit: 20,
        total: 0
      },
      categories: {
        list: [],
        numItemsPerPage: 20,
        activePage: 0,
        selectedCategory: '',
        page: 0,
        limit: 20,
        total: 0
      },
      showDialog: false,
      tableHeight: 500,
      rowHeight: 50,
      textSearch: ''
    };
    this.renderPaginationTag = this.renderPaginationTag.bind(this);
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handlePaginationChose = this.handlePaginationChose.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.openFormDialog = this.openFormDialog.bind(this);
    this.updateThirdPartyList = this.updateThirdPartyList.bind(this);
    this.renderDataItemList = this.renderDataItemList.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.onSearchChangeFilterDebounce = _.debounce(this.onSearchChangeFilter, 250);
  }

  componentDidMount() {
    this.getLocation();
    this.updateCategoryList();
    this.updateThirdPartyList();
  }

  updateCategoryList = (params, loadMore = false, callback = null) => {
    const { auth, settingActions } = this.props;
    const { categories } = this.state;
    const { search = '', limit = 20 } = params || {}
    const { list, page } = categories || {}
    const { fleetId } = auth.selectedFleet;

    settingActions
      .findCategory({
        search,
        fleetId,
        limit,
        page: loadMore ? page + 1 : page
      })
      .then(data => {
        if (data.ok && data.res) {
          const {
            list: listCategory = [],
            total: totalCategory = 0,
            page: pageCategory = 0,
            limit: limitCategory = 20
          } = data.res || {};
          this.setState({
            categories: {
              ...this.state.categories,
              list: loadMore ? list.concat(listCategory) : listCategory,
              total: totalCategory,
              page: pageCategory,
              limit: limitCategory
            }
          });
        }
      });

    if (callback) callback();
  }

  getLocation = () => {
    const currentPosition = { lat: 16.059959, lng: 108.224258 };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        currentPosition.lat = coords.latitude;
        currentPosition.lng = coords.longitude;
      });
    }
    this.setState({
      currentPosition
    });
  }

  updateThirdPartyList() {
    const { auth, settingActions } = this.props;
    const { dataList, numItemsPerPage, activePage, textSearch, categories = {} } = this.state;
    const { selectedCategory: categoryId = '' } = categories || {};
    const { fleetId } = auth.selectedFleet;
    const limit = numItemsPerPage;
    const page = activePage || activePage === 0 ? activePage : dataList.page;
    settingActions
      .findThirdPartyLocation({
        textSearch,
        fleetId,
        limit,
        page,
        categoryId
      })
      .then(data => {
        if (data.ok && data.res) {
          this.setState({
            dataList: data.res
          });
        }
      });
  }

  handleAddButtonClick() {
    const { currentPosition } = this.state;
    const { auth } = this.props;
    this.setState(
      {
        dialogData: {
          countryCode: auth?.selectedFleet?.countryCode || '',
          ...currentPosition
        }
      },
      this.openFormDialog
    );
  }

  openFormDialog() {
    this.setState({
      showDialog: true
    });
  }

  closeFormModal() {
    this.setState({ showDialog: false });
  }

  handlePaginationSelect(value) {
    this.setState(
      {
        activePage: value
      },
      this.updateThirdPartyList
    );
  }

  handlePaginationChose(e) {
    const value = parseInt(e.target.value);
    this.setState(
      {
        activePage: value
      },
      this.updateThirdPartyList
    );
  }

  handleNumItemsPerPageChange(e) {
    const value = parseInt(e);
    this.setState(
      {
        numItemsPerPage: value,
        activePage: 0
      },
      this.updateThirdPartyList
    );
  }

  handleEditButtonClick(id) {
    const { auth, settingActions, loadingBarActions } = this.props;
    const { fleetId } = auth.selectedFleet;
    settingActions
      .detailsThirdPartyLocation({
        _id: id,
        fleetId
      })
      .then(data => {
        if (data.res) {
          const pos =
            this.props.commonData.location &&
              this.props.commonData.location.isChina
              ? new window.qq.maps.LatLng(
                parseFloat(data.res.lat),
                parseFloat(data.res.lng)
              )
              : {
                lat: parseFloat(data.res.lat),
                lng: parseFloat(data.res.lng)
              };
          this.setState(
            {
              dialogData: data.res
            },
            this.openFormDialog
          );
        }
      });
  }

  doDeleteLocation = (id) => {
    const { auth, settingActions, loadingBarActions } = this.props;
    const { fleetId } = auth.selectedFleet;
    settingActions
      .deleteThirdPartyLocation({
        _id: id,
        fleetId
      })
      .then(data => {
        if (data.res) {
          this.closeConfirmModal();
          this.updateThirdPartyList();
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('thirdPartySettings.Delete_third_party_fail')
          );
        }
      });
  }

  handleDeleteButtonClick(id) {
    this.setState({ showConfirmDialog: true, deleteId: id })
  }

  closeConfirmModal = () => {
    this.setState({ showConfirmDialog: false, deleteId: null })
  }

  handleConfirmDelete = () => {
    const { deleteId: id } = this.state;
    this.doDeleteLocation(id);
  }

  renderPaginationTag(key) {
    if (this.state.dataList) {
      const items = Math.ceil(
        this.state.dataList.total / this.state.dataList.limit
      );
      const indents = [];
      for (let i = 1; i <= items; i++) {
        indents.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      let startNum = this.state.dataList.page * this.state.numItemsPerPage;
      startNum = this.state.dataList.total == 0 ? 0 : startNum + 1;
      return (
        <div className={`clearfix cue-table-control ${key}`}>
          <div className="left-controls">
            <div className="control-group form-inline">
              <label>
                <Translate value="thirdPartySettings.Show_per_page" />
              </label>
              <select
                className="form-control"
                value={this.state.numItemsPerPage}
                onChange={this.handleNumItemsPerPageChange}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={70}>70</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className="control-group form-inline">
              <label>
                <Translate value="thirdPartySettings.Page" />
              </label>
              <select
                className="form-control"
                value={this.state.dataList.page + 1}
                onChange={this.handlePaginationChose}
              >
                {indents}
              </select>
              /
              {Math.floor(
                this.state.dataList.total / this.state.dataList.limit
              ) + 1}
            </div>
          </div>
          <div className="right-controls clearfix">
            <div className="control-group form-inline">
              <Pagination
                prev
                next
                first
                last
                ellipsis={false}
                boundaryLinks={false}
                items={items}
                maxButtons={5}
                activePage={this.state.dataList.page + 1}
                onSelect={this.handlePaginationSelect}
              />
            </div>
            <div className="control-group form-inline paging-lage">
              <label>
                <span>
                  {I18n.t('thirdPartySettings.show_pages').format(
                    startNum,
                    (this.state.dataList.page + 1) *
                      this.state.numItemsPerPage >=
                      this.state.dataList.total
                      ? this.state.dataList.total
                      : (this.state.dataList.page + 1) *
                      this.state.numItemsPerPage,
                    this.state.dataList.total
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>
      );
    }
  }

  renderDataItemList() {
    const { dataList } = this.state;
    if (dataList.list && dataList.list.length > 0) {
      return dataList.list.map(obj => (
        <tr key={obj._id} className="p-no-margin">
          <td scope="row">
            <p>{obj.alias}</p>
          </td>
          <td scope="row">
            <p>{obj.location.address}</p>
          </td>
          <td scope="row">
            <p>{obj.location.longitude}</p>
          </td>
          <td scope="row">
            <p>{obj.location.latitude}</p>
          </td>
          <td scope="row">
            <p>{moment(obj.latestUpdate).format('MM/DD/YYYY')}</p>
          </td>
          <td scope="row">
            <p>{obj.userName}</p>
          </td>

          <td>
            <DropdownButton
              title={<i className="fa fa-bars" />}
              id="bg-nested-dropdown"
              onSelect={() => { }}
              pullRight
            >
              <Dropdown.Item
                eventKey="Edit"
                onClick={e => {
                  this.handleEditButtonClick(obj._id);
                }}
              >
                <Translate value="promotionSettings.Edit" />
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="Delete"
                onClick={e => {
                  this.handleDeleteButtonClick(obj._id);
                }}
              >
                <Translate value="promotionSettings.Delete" />
              </Dropdown.Item>
            </DropdownButton>
          </td>
        </tr>
      ));
    }
    return (
      <tr>
        <td className="item-no-found" colSpan={7}>
          <Translate value="messages.item_no_found" />
        </td>
      </tr>
    );
  }

  getPostion = (item) => {
    if (item.locationType === 'multiple') {
      return _.get(item, 'locations[0]', {});
    }
    return _.get(item, 'location', {});
  }
  getTableColumns = () => {
    const tableColumns = Object.assign([], ThirdPartyColumns);
    // custom data by column
    forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'Alias':
          col.customCell = obj => <TextEllipsisOverlay content={obj.alias} />;
          break;
        case 'Address':
          col.customCell = obj => (
            <TextEllipsisOverlay content={obj.location.address} />
          );
          break;
        case 'Longitude':
          col.customCell = obj => <span>{this.getPostion(obj).longitude}</span>;
          break;
        case 'Latitude':
          col.customCell = obj => <span>{this.getPostion(obj).latitude}</span>;
          break;
        case 'Last_update':
          col.customCell = obj => (
            <span>{moment(obj.latestUpdate).format('MM/DD/YYYY')}</span>
          );
          break;
        case 'Username':
          col.customCell = obj => <span>{obj.userName}</span>;
          break;
        case 'locationType':
          col.customCell = obj => (
            <Translate value={`thirdPartySettings.${obj.locationType}`} />
          );
          break;
        case 'categories':
          col.customCell = obj => {
            const categories = (obj.categories || []).map(category => category.name);
            return <TextEllipsisOverlay content={!categories || !categories.length ? '' : categories.join(', ')} />;
          }
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              const actionItems = [
                {
                  label: 'promotionSettings.Edit',
                  eventKey: 'Edit'
                },
                {
                  label: 'promotionSettings.Delete',
                  eventKey: 'Delete'
                }
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={obj}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, obj);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            }
            return (
              <a
                onClick={e => {
                  this.handleMenuClick('Edit', obj);
                }}
                href="javascript:void(0)"
                className="text-active"
              >
                <Translate value="mDispatcher.View" />
              </a>
            );
          };
          break;
      }
    });

    return tableColumns;
  };

  handleMenuClick = (key, obj) => {
    switch (key) {
      case 'Edit': {
        this.handleEditButtonClick(obj._id);
        break;
      }
      case 'Delete': {
        this.handleDeleteButtonClick(obj._id);
        break;
      }
      default:
        break;
    }
  };

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  searchStrChange = e => {
    this.setState({ textSearch: e.target.value });
  };

  searchKeyPressHandle = e => {
    if (e.key == 'Enter') {
      this.updateThirdPartyList(0);
    }
  };

  handleChangeCombobox = (obj, selectedCategory, key) => {
    this.setState({
      categories: {
        ...this.state.categories,
        selectedCategory
      }
    }, () => this.updateThirdPartyList()
    );
  }

  onScrollBottomFilter = () => {
    this.updateCategoryList({}, true);
  }

  onSearchChangeFilter = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.categories, ['search', 'page', 'limit']);
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      this.updateCategoryList(params, false, callback);
    }
    else {
      return callback ? callback() : null;
    }
  }

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const canUpdate = !this.props.permissions || this.props.permissions.actions;

    const { categories = {} } = this.state || {};
    const { list = [], selectedCategory = '' } = categories || {};

    return (
      <div className="content">
        {this.state.showDialog ? (
          <AddEditThirdPartyLocationModal
            dialogData={this.state.dialogData}
            updateThirdPartyList={this.updateThirdPartyList}
            show={this.state.showDialog}
            closeFormModal={this.closeFormModal}
            currentPosition={this.state.currentPosition}
            {...this.props}
          />
        ) : null}
        {this.state.showConfirmDialog ? (
          <ConfirmDelete
            closeConfirmModal={this.closeConfirmModal}
            onConfirmDelete={this.handleConfirmDelete}
          />
        ) : null}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <div className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.textSearch}
                onKeyPress={this.searchKeyPressHandle}
                onChange={this.searchStrChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>

            <Filter.Category
              items={[{
                label: I18n.t('thirdPartySettings.All_Category'),
                value: ''
              }].concat((list || []).map(category => {
                const { _id, name } = category || {};
                return {
                  value: _id,
                  label: name
                }
              }))}
              selectedItems={[selectedCategory]}
              onScrollBottom={this.onScrollBottomFilter}
              onSearchChange={this.onSearchChangeFilterDebounce}
              onSelect={value => this.handleChangeCombobox({}, value, 'categoryId')}
            />

            {canUpdate && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="thirdPartySettings.Add" />
              </Button>
            )}
          </div>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.dataList}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

ThirdParty.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdParty);
