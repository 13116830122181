import React, { useState, useEffect  } from 'react'
import { Translate } from 'react-redux-i18n';
import {
  Modal,
  Button,
  FormGroup
} from "react-bootstrap";
import backIcon from '../../../assets/images/icons/backIcon.svg'
import PointTraveler from './PointTraveler'
import { POINT_TYPE_TEXT } from '../../../constants/commondata'
import './RoutePreview.scss'

const RoutePreview = ({
  isShowRoutePreview,
  setIsShowRoutePreview,
  puPoints, 
  doPoints,
  setShowAddTraveler,
  setCustomerSelected,
  customersSelected,
  handleMovePoints,
  handleRemovePoints,
  handleSaveUpdate,
  foreEdit,
  setForeEdit,
  backCloseModal,
  data
}) => {

  useEffect(() => {
    if(foreEdit) {
      setStatusRoute('edit')
    }
  }, [foreEdit])

  const [statusRoute, setStatusRoute] = useState('preview') // [preview, edit]
  // const [passengersMove, setPassengersMove] = useState([])

  const handleEditOrSave = () => {
    if(statusRoute === 'preview') setStatusRoute('edit')
    if(statusRoute === 'edit') {
      setStatusRoute('preview')
      setIsShowRoutePreview(false)
      handleSaveUpdate()
      setForeEdit(false)
    }
  }

  const handleBackClick = () => {
    if(statusRoute == 'preview') {
      setCustomerSelected([])
      backCloseModal()
      closeModal()
    } else {
      backCloseModal()
      setStatusRoute('preview')
      setForeEdit(false)
      setCustomerSelected([])
    }
  }

  const handleAddTravaler = () => {
    setShowAddTraveler(true)
    setStatusRoute('edit')
    setForeEdit(false)
    closeModal()
  }

  const closeModal = () => {
    setStatusRoute('preview')
    setIsShowRoutePreview(false)
    setForeEdit(false)
    setCustomerSelected([])
  }

  const addCustomerMove = (customer) => {
    if(customer.userId) {
      let currentCustomers = [...customersSelected]
      currentCustomers.push(customer)
      setCustomerSelected([...currentCustomers])
    }
  }
  
  const removeCustomerMove = (customer) => {
    if(customer.userId) {
      let currentCustomers = [...customersSelected]
      let newCustomer = currentCustomers.filter((pass) => pass.userId != customer.userId)
      setCustomerSelected(newCustomer)
    }
  }

  const isPreview = statusRoute === 'preview' ? true : false  
  let totalPassenger = 0
  puPoints.forEach((point) => {
    let passengers = point.passengers || []
    if(passengers.length > 0) totalPassenger += passengers.length
  })

  return (
    <Modal
        show={isShowRoutePreview}
        backdrop="static"
        key="modal-form"
        dialogClassName="confirm-dialog routePreview"
        className="confirm pointModal"
        onHide={closeModal}
      >
        <Modal.Header>
          <div className="headerPre">
            <span className="backBtn">
              <img 
                src={backIcon} 
                onClick={handleBackClick}
              />
            </span>
            <span className="titlePre">
              {
                isPreview ? "Route Preview" : "Edit Route"              
              }
            </span>
            <span 
              className="actionPre"
              onClick={handleEditOrSave}
            >
              {
                isPreview ? "Edit" : "Save"              
              }
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="topBody">
            <span>
              {`${totalPassenger} passenger(s)`}
            </span>
            <span onClick={handleAddTravaler}>
              Add Traveler
            </span>
          </div>

          {
            puPoints.length > 0 && puPoints.map((point) => {
              return <PointTraveler 
                pointTraveler={point}
                pointType={POINT_TYPE_TEXT.pu}
                key={point.id}
                statusRoute={statusRoute}
                data={data}
                addCustomerMove={addCustomerMove}
                removeCustomerMove={removeCustomerMove}
                customersSelected={customersSelected}
              />
            })
          }

          {
            doPoints.length > 0 && doPoints.map((point) => {
              return <PointTraveler 
                pointTraveler={point}
                pointType={POINT_TYPE_TEXT.do}
                key={point.id}
                data={data}
                customersSelected={customersSelected}
              />
            })
          }
          
        </Modal.Body>
        {
          !isPreview &&
          <Modal.Footer>
            <Button
              onClick={handleMovePoints}
              className="btn-save btn-traveler"
            >
              <Translate value="newbooking.Move" />
            </Button>
            <Button
              onClick={handleRemovePoints}
              className="btn-cancel btn-traveler"
            >
              <Translate value="newbooking.Remove" />
            </Button>
          </Modal.Footer>
        }
      </Modal>
  )
}

RoutePreview.defaultProps = {
  puPoints: [],
  doPoints: [],
  recentPlaces: {},
  fleetId: '',
  limitPoint: {},
  isBookDetails: false,
  jobType: '',
  setNewPuPoint: () => {},
  setNewDoPoint: () => {},
  isPickupEditable: () => {},
  isDestinationEditable: () => {},
  checkPointInZoneSupported: () => {}
}

export default RoutePreview