import React, { Fragment } from 'react';
import {
  FormGroup,
  Form,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import RadioButton from '../../../../../components/radioButton/radio';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../../components/validator';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';

export default class FormFee extends React.Component {
  state = {
    currentFocus: {},
  };

  handleKeyFeeCheckChange = (e) => {
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData(`${this.props.keyFee}Active`, e.target.checked);
  };

  handlePaytoChange = (e) => {
    const value = parseInt(e.target.value || 0);
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData(`${this.props.keyFee}PayTo`, value);
  };

  handeLimitDriverInputActiveChange = (e) => {
    const value = e.target.value == 'true';
    const { handleUpdateFormData, ValidatorCallback } = this.props;
    if (!value) {
      ValidatorCallback(`${this.props.keyFee}DriverCanInput`, true);
    }
    handleUpdateFormData(`${this.props.keyFee}LimitDriverInputActive`, value);
  };

  handleCancelInputMultiCurrenciesChange = (currency, e, keyName) => {
    const { handleUpdateFormData, formData } = this.props;
    let currentFieldValue = _.get(formData, keyName);
    let isAdd = true;
    const valueParse = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    const cur = {
      currencyISO: currency,
      value: valueParse,
    };
    currentFieldValue =
      currentFieldValue ||
      this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
      }));
    currentFieldValue = currentFieldValue.map((c) => {
      if (c.currencyISO === currency) {
        isAdd = false;
        return { ...c, value: valueParse };
      }
      return c;
    });
    if (isAdd) {
      currentFieldValue.push(cur);
    }
    handleUpdateFormData(keyName, currentFieldValue);
  };

  getCurrencyValue = (currenciesAmount, currencyISO, keyName) => {
    const currencyCurrent = _.find(
      currenciesAmount || [],
      (o) => o.currencyISO === currencyISO
    );
    if (currencyCurrent) {
      return this.parseInputValue(currencyCurrent.value, currencyISO, keyName);
    }
    return 0;
  };

  setFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = true;
    this.setState({
      currentFocus,
    });
  };

  setUnFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = false;
    this.setState({
      currentFocus,
    });
  };

  parseInputValue = (value, currencyISO, keyName) => {
    const { currentFocus } = this.state;
    const isFocues = currentFocus[keyName];

    if (isFocues) {
      return (value || '').toString().replace(/[^.0-9]+/, '') || 0;
    }

    return currencyFormatter.format(value || 0, {
      code: currencyISO,
      format: '%v',
    });
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  renderMultiCurrency = (key) => {
    const { formData, editable, auth } = this.props;
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        defaultActiveKey={auth.selectedFleet.currencies[0].iso}
        animation={false}
        onSelect={this.tabSelectHandle}
      >
        {auth.selectedFleet.currencies.map((c) => {
          const currentValue = formData[`${this.props.keyFee}DriverCanInput`]
            ? formData[`${this.props.keyFee}DriverCanInput`].filter(
                (d) => d.currencyISO === c.iso
              )[0]
            : null;
          return (
            <Tab
              className="currencies-tab-item"
              eventKey={c.iso}
              title={c.iso}
              key={c.iso}
            >
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-left ${editable ? 'disabled' : ''}`}
                >
                  <InputGroup.Prepend>
                    <InputGroup.Text>{c.symbol}</InputGroup.Text>
                  </InputGroup.Prepend>{' '}
                  <FormControl
                    type="text"
                    className="fomt-custom"
                    onChange={(e) => {
                      this.handleCancelInputMultiCurrenciesChange(
                        c.iso,
                        e,
                        key
                      );
                    }}
                    onBlur={(e) => {
                      this.setUnFocus(key);
                      textboxNumberHelper.onBlurHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    onFocus={(e) => {
                      this.setFocus(key);
                      textboxNumberHelper.onfocusHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    value={
                      currentValue
                        ? currentValue.value
                        : DefaultCurenciesNumberInputField
                    }
                    disabled={editable}
                  />
                </InputGroup>
              </FormGroup>
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  renderInputCurrency = (keyName) => {
    const { formData, editable, auth, currency } = this.props;
    const currencyCurrent = currency || auth.selectedFleet.currencies[0];
    const currentField = _.get(formData, keyName);
    return (
      <FormGroup className="qup-input-group">
        <InputGroup
          className={`single-addon-left ${editable ? 'disabled' : ''}`}
        >
          <FormControl
            type="text"
            className="form-custom no-marginBottom"
            onChange={(e) => {
              this.handleCancelInputMultiCurrenciesChange(
                currencyCurrent.iso,
                e,
                keyName
              );
            }}
            onBlur={(e) => {
              this.setUnFocus(keyName);
              textboxNumberHelper.onBlurHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            onFocus={(e) => {
              this.setFocus(keyName);
              textboxNumberHelper.onfocusHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            value={this.getCurrencyValue(
              currentField,
              currencyCurrent.iso,
              keyName
            )}
            disabled={editable}
          />
          <InputGroup.Append>
            <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
          </InputGroup.Append>{' '}
        </InputGroup>
      </FormGroup>
    );
  };

  renderLimitDriverCanInput = () => {
    const { formData, auth, currency } = this.props;
    if (formData[`${this.props.keyFee}LimitDriverInputActive`]) {
      return auth.selectedFleet.multiCurrencies && !currency
        ? this.renderMultiCurrency(`${this.props.keyFee}DriverCanInput`)
        : this.renderInputCurrency(`${this.props.keyFee}DriverCanInput`);
    }
    return null;
  };

  render() {
    const { editable, formData, ValidatorCallback, isSubmited, valid, auth } =
      this.props;
    const keyFeeDriverCanInput = `${this.props.keyFee}DriverCanInput`;
    let formDataFeeDriverCanInput = formData[keyFeeDriverCanInput];
    if (!formDataFeeDriverCanInput || !formDataFeeDriverCanInput.length) {
      formDataFeeDriverCanInput = [auth.selectedFleet.currencies[0]];
    }

    return (
      <Fragment>
        <CcCheckbox
          disabled={editable}
          checked={formData ? formData[`${this.props.keyFee}Active`] : false}
          onChange={this.handleKeyFeeCheckChange}
          text={I18n.t(`generalSetting.${this.props.keyFee}`)}
          labelClassName="title"
          className="form-group-title"
        />
        {formData[`${this.props.keyFee}Active`] ? (
          <FormGroup className="radio-group">
            <Form.Label className="mr-r-50 mb0">
              <Translate value="generalSetting.Pay_to" />
            </Form.Label>
            <RadioButton
              text={I18n.t('newbooking.Fleet_Company')}
              inline
              name={this.props.keyFee}
              value={1}
              checked={formData[`${this.props.keyFee}PayTo`] === 1}
              onChange={this.handlePaytoChange}
              disabled={editable}
            />
            <RadioButton
              text={I18n.t('newbooking.Driver_Supplier')}
              inline
              name={this.props.keyFee}
              value={0}
              checked={formData[`${this.props.keyFee}PayTo`] === 0}
              onChange={this.handlePaytoChange}
              disabled={editable}
            />
          </FormGroup>
        ) : null}
        {formData[`${this.props.keyFee}Active`] ? (
          <Fragment>
            <FormGroup className="sub-controls">
              <Form.Label className="mr-r-50">
                <Translate value={`generalSetting.tollFeeDriverCanInput`} />
              </Form.Label>
              <RadioButton
                text={I18n.t('generalSetting.unlimited')}
                inline
                name={`${this.props.keyFee}DriverCanInput`}
                value="false"
                checked={
                  !formData[`${this.props.keyFee}LimitDriverInputActive`]
                }
                onChange={this.handeLimitDriverInputActiveChange}
                disabled={editable}
              />
              <RadioButton
                text={I18n.t('generalSetting.limited')}
                inline
                name={`${this.props.keyFee}DriverCanInput`}
                value="true"
                checked={formData[`${this.props.keyFee}LimitDriverInputActive`]}
                onChange={this.handeLimitDriverInputActiveChange}
                disabled={editable}
              />
            </FormGroup>
            {formData[`${this.props.keyFee}LimitDriverInputActive`] ? (
              <FormGroup
                className={`sub-controls ${
                  !isSubmited
                    ? null
                    : valid[`${this.props.keyFee}DriverCanInput`] === false
                    ? 'error'
                    : null
                }`}
              >
                {this.renderLimitDriverCanInput()}
                <Validator
                  id={`${this.props.keyFee}DriverCanInput`}
                  callback={ValidatorCallback}
                >
                  <ValidCase
                    valid={
                      formDataFeeDriverCanInput.filter(
                        (d) =>
                          !Validation.isStringEmpty(d.value) && _.isNaN(d.value)
                      ).length === 0
                    }
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!isSubmited}
                  />
                  <ValidCase
                    valid={
                      formDataFeeDriverCanInput.filter(
                        (d) => !Validation.isGreaterThan(d.value, 0)
                      ).length === 0
                    }
                    message={I18n.t(
                      'messages.commonMessages.greater_than'
                    ).format(0)}
                    hide={!isSubmited}
                  />
                  <ValidCase
                    valid={
                      formDataFeeDriverCanInput.filter(
                        (d) => !Validation.isLessOrEqual(d.value, 9999999999)
                      ).length === 0
                    }
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(this.formatCurrency(9999999999))}
                    hide={!isSubmited}
                  />
                </Validator>
                <WarningCase
                  validator={valid[`${this.props.keyFee}DriverCanInput`]}
                  message={I18n.t('message.warningInputChangeOver')}
                  initialValue={formDataFeeDriverCanInput.map((ob) => ob.value)}
                  onChangeValue={formDataFeeDriverCanInput.map(
                    (ob) => ob.value
                  )}
                  range={20}
                  typePercent={false}
                />
              </FormGroup>
            ) : null}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
