import React from 'react';
import {
    FormGroup,
    Button,
    FormControl,
    Modal,
    Form
} from "react-bootstrap";
import { Translate } from "react-redux-i18n";

export default function AddFavoriteFilterModal(props) {
    const {
        name,
        handleClose,
        handleSave,
        handleChangeName
    } = props;

    return (
        <Modal
            show
            size="sm"
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Translate value="cue.add_favorite_filter" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <Form.Label>
                        <Translate value="cue.favorite_filter_name" />
                        <span className="require"> *</span>
                    </Form.Label>
                    <FormControl
                        className="form-custom"
                        type="text"
                        value={name ? name : ''}
                        onChange={e => handleChangeName(e)}
                        autoFocus
                        maxLength={50}
                    />
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn-save mr-md"
                    onClick={handleSave}
                >
                    <Translate value="customer.Save" />
                </Button>
                <Button
                    className="btn-cancel ml0"
                    onClick={handleClose}
                >
                    <Translate value="customer.Cancel" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
