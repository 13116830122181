import ReportUtils from '../base/utils';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

export const fields = [
  {
    key: 'date',
    label: 'report.result.corporate.date',
    summary: 'totalBookings',
    mutate: ReportUtils.formatTime
  },
  {
    key: 'bookingId',
    label: 'report.result.corporate.bookingId'
  },
  {
    key: 'corporateName',
    label: 'report.result.corporate.corporateName',
    summary: 'corporateName'
  },
  {
    key: 'flightNumber',
    label: 'report.result.corporate.flightNumber'
  },
  {
    key: 'managerName',
    label: 'report.result.corporate.managerName',
    summary: 'managerName',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo
  },
  {
    key: 'managerEmail',
    label: 'report.result.corporate.managerEmail',
    summary: 'managerEmail',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo
  },
  {
    key: 'title',
    label: 'report.result.corporate.title',
    summary: 'title',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo
  },
  {
    key: 'department',
    label: 'report.result.corporate.department',
    summary: 'department',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo
  },

  {
    key: 'clientCaseMatter',
    label: 'report.result.corporate.clientCaseMatter',
    summary: 'clientCaseMatter'
  },
  {
    key: 'chargeCode',
    label: 'report.result.corporate.chargeCode',
    summary: 'chargeCode'
  },
  {
    key: 'driverName',
    label: 'report.result.corporate.driverName',
    summary: 'driverName'
  },
  {
    key: 'pickupLocation',
    label: 'report.result.corporate.pickupLocation',
    summary: 'pickupLocation'
  },
  {
    key: 'destination',
    label: 'report.result.corporate.destination',
    summary: 'destination'
  },
  {
    key: 'total',
    label: 'report.result.corporate.total',
    summary: 'total'
  },
  {
    key: 'subtotal',
    label: 'report.result.corporate.subtotal',
    summary: 'subtotal'
  },
  {
    key: 'paidBy',
    label: 'report.result.corporate.paidBy',
    summary: 'paidBy'
  },
  {
    key: 'paymentStatus',
    label: 'report.query.paymentStatus',
    summary: 'paymentStatus',
    mutate: value => I18n.t(`report.query.paymentStatusItem.${value}Payment`)
  },
  {
    key: 'statusDisplay',
    label: 'report.result.corporate.statusDisplay',
    summary: 'statusDisplay'
  }
];

export const fareFields = ['total', 'subtotal', 'subTotalFF', 'commission'];
