import React from 'react';
import IntlTelInputApp from '../../../../components/intlTelInputCustome/IntlTelInputApp';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const Infobip = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.appKey" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.appKey || ''}
          onChange={(e) => {
            props.handleInputChange('appKey', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.sender" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.sender || ''}
          onChange={(e) => {
            props.handleInputChange('sender', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.username" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.username || ''}
          onChange={(e) => {
            props.handleInputChange('username', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.password" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.password || ''}
          onChange={(e) => {
            props.handleInputChange('password', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.maskedPhone" />
        </Form.Label>
        <FormGroup>
          <IntlTelInputApp
            css={['intl-tel-input', 'form-control form-custom']}
            utilsScript={'libphonenumber.js'}
            value={props?.formData?.maskedPhone || ''}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              props.handlePhoneChange(
                'maskedPhone',
                status,
                value,
                countryData,
                number,
                id
              );
            }}
            disabled={props.onLoad}
          />
        </FormGroup>
      </FormGroup>
    </div>
  );
};

export default Infobip;
