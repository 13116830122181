import React from "react";
import Promise from "bluebird";
import BaseReport from "./../base";
import * as fieldMappings from "./fields";
import { reportVoucherApi } from "../../../constants/ApiConfigs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "./../../../actions/settingActions";
import _ from 'lodash';
class ReportVoucher extends React.Component {
    constructor() {
        super();
    }
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, {"name": 'Report'});
    let checkExport = _.findIndex(report.actions, {"name": "Code #", "Export": true});
    let checkOwner = !this.props.user.isAdmin &&  typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
    checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
      if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
          checkExport = false;
      }
    return (
        <div className="content">
          <BaseReport
              tittle="Redeemed"
              fieldMappings={fieldMappings}
              apiurl={reportVoucherApi}
              dateRange
              currency
              campaign
              voucherCodes
              noexport={!checkExport}
              />
        </div>
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(settingActions, dispatch)
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(ReportVoucher);