import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import Moment from "react-moment";
import moment from 'moment-timezone';

import { getMessage, deleteMessages, exportReceivers, editMessage } from "../../actions/messageAction";
import NewMessage from "./NewMessage";
import MessageDetail from "./MessageDetail";
import {
  Button,
  ButtonToolbar,
  ButtonGroup,
  FormGroup
} from "react-bootstrap";
import _ from "lodash";
import { BsSearch } from "react-icons/bs"
import "./message.scss";
import Confirm from "../../components/confirm/Confirm";
import StickyTable from "../table/stickyTable/StickyTable";
import { MessageColumns } from "./tableColumns";
import CcCheckbox from "../ccCheckbox/CcCheckbox";
import TableActions from "../../components/table/tableAction/TableActions";

const CONFIRM_DELETE_ID = 1;
const CONFIRM_DELETE_LINK_ID = 2;
let deleteMessageId;
class Message extends React.Component {
  constructor() {
    super();
    this.state = {
      list: [],
      appType: "passenger",
      isShow: false,
      rowHeight: 50,
      tableHeight: 500,
      showDialog: false,
      editable: false,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };
  }

  componentDidMount() {
    if (
      this.props.routes[this.props.routes.length - 1].path === "/driver/message"
    ) {
      this.setState({ appType: "driver" }, () => {
        this.getListMessages();
      });
    } else if (
      this.props.routes[this.props.routes.length - 1].path === "/merchant/message"
    ) {
      this.setState({ appType: "merchant" }, () => {
        this.getListMessages();
      });
    } else if (
      this.props.routes[this.props.routes.length - 1].path ===
      "/driver/message/add"
    ) {
      this.setState({ appType: "driver", isShow: true }, () => {
        this.getListMessages();
      });
    } else if (
      this.props.routes[this.props.routes.length - 1].path ===
      "/customer/message/add"
    ) {
      this.getListMessages();
      this.setState({ isShow: true });
    } else if (
      this.props.routes[this.props.routes.length - 1].path === "/merchant/message/add"
    ) {
      this.setState({ appType: "merchant", isShow: true }, () => {
        this.getListMessages();
      });
    } else {
      this.getListMessages();
    }
  }

  getListMessages = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId
      }
    };
    if (this.state.search) {
      params.query.search = this.state.search;
    }
    params.query.appType = this.state.appType;
    this.props.getMessage(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit, list } = data.res;
        this.setState({ list: list, footerData: { page, total, limit } });
      }
    });
  };

  handleAddClick = () => {
    this.props.router.push(
      this.state.appType == "driver"
        ? "/driver/message/add" : this.state.appType == "passenger" ?
          "/customer/message/add" : "/merchant/message/add"
    );
    this.setState({ isShow: true });
  };

  handleDeleteClick = () => {
    if (this.state.list.filter(messages => messages.checked).length == 0) {
      this.setState({
        confirm: {
          title: I18n.t("message.Delete"),
          body: I18n.t("message.ALERT_SELECT_DELETE")
        }
      });
    } else {
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_ID,
          title: I18n.t("message.Delete"),
          body: I18n.t("message.CONFIRM_DELETE_MULTI"),
          buttonTitle: "Yes",
          closeButtonText: "No"
        }
      });
    }
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  handleConfirmButtonClick = id => {
    switch (id) {
      case CONFIRM_DELETE_ID:
        {
          if (this.state.list.length) {
            let listMessage = this.state.list
              .filter(messages => messages.checked)
              .map(messages => messages.inboxContentId);
            if (listMessage && listMessage.length > 0) {
              this.props
                .deleteMessages({
                  ids: listMessage,
                  fleetId: this.props.auth.selectedFleet.fleetId,
                  appType: this.state.appType
                })
                .then(data => {
                  if (data.ok) {
                    this.context.notification(
                      "warning",
                      I18n.t("message.ALERT_PROCESSING_DELETE")
                    );
                    this.getListMessages();
                  } else {
                  }
                });
            }
          }
        }
        break;
      case CONFIRM_DELETE_LINK_ID:
        {
          this.props
            .deleteMessages({
              ids: [deleteMessageId],
              fleetId: this.props.auth.selectedFleet.fleetId,
              appType: this.state.appType
            })
            .then(data => {
              if (data.ok) {
                this.context.notification(
                  "warning",
                  I18n.t("message.ALERT_PROCESSING_DELETE")
                );
                this.getListMessages();
              } else {
              }
            });
        }
        break;
    }
    this.handleConfirmCloseClick();
  };

  handleAddNewMessageSuccess = (total) => {
    this.setState({
      confirm: {
        title: I18n.t("message.Succeeded"),
        body: (total ?
          (<>
            <Translate
              value={total.appType == "driver" ?
                "message.import_fail_success_driver" : total.appType == "passenger" ? "message.import_fail_success_passenger" : "message.import_fail_success_merchant"
              }
              success={total.totalSuccess}
              fail={total.totalFails}
              className="totalMessage"
            />
            <Translate value="message.CONFIRM_SEND_SUCCESS" />
          </>
          )
          : I18n.t("message.CONFIRM_SEND_SUCCESS")
        )
      }
    });
    setTimeout(this.getListMessages, 10000);
  };

  handleCloseDialog = () => {
    this.props.router.push(
      this.state.appType == "driver" ? "/driver/message" : this.state.appType == "passenger" ? "/customer/message" : "/merchant/message"
    );
    this.setState({ isShow: false, detailItem: null, showDialog: false });
  };

  handleSelectAllClick = e => {
    let target = e.target;
    let listMessage = _.cloneDeep(this.state.list);
    _.forEach(listMessage, message => {
      message.checked = target.checked;
    });
    this.setState({ list: listMessage });
  };

  handleDetailItemClick = (detailItem, editable = false) => {
    this.setState({ detailItem: detailItem, showDialog: true, editable });
  };

  handleDeleteMessageClick = (detailItem, event) => {
    event.preventDefault();
    event.stopPropagation();
    deleteMessageId = detailItem.inboxContentId;
    this.setState({
      confirm: {
        id: CONFIRM_DELETE_LINK_ID,
        title: I18n.t("message.Delete"),
        body: I18n.t("message.CONFIRM_DELETE"),
        buttonTitle: "Yes",
        closeButtonText: "No"
      }
    });
  };

  handleEditMessageClick = (params) => {
    this.props
      .editMessage(params)
      .then(data => {
        if (data.ok) {
          this.context.notification(
            "warning",
            I18n.t("message.ALERT_PROCESSING_DELETE")
          );
          this.getListMessages();
        } else {
        }
        this.handleCloseDialog();
      });
  };

  handleMenuClick(key, message) {
    switch (key) {
      case "Edit": {
        this.handleDetailItemClick(message, true);
        break;
      }
      case "Delete": {
        deleteMessageId = message.inboxContentId;
        this.setState({
          confirm: {
            id: CONFIRM_DELETE_LINK_ID,
            title: I18n.t("message.Delete"),
            body: I18n.t("message.CONFIRM_DELETE"),
            buttonTitle: "Yes",
            closeButtonText: "No"
          }
        });
        break;
      }
      case "View": {
        this.handleDetailItemClick(message);
        break;
      }
    }
  }

  handleSelectRowClick = (isChecked, Id) => {
    let listMessage = _.cloneDeep(this.state.list);
    _.forEach(listMessage, message => {
      if (Id === message.inboxContentId) {
        message.checked = isChecked;
        return false;
      }
    });
    this.setState({ list: listMessage });
  };

  handleSearchKeyPress = e => {
    if (e.key === "Enter") {
      this.getListMessages(0);
    }
  };

  handleSearchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  handlePaginationSelect = page => this.getListMessages(parseInt(page));

  handleNumItemsPerPageChange = limit => {
    this.getListMessages(0, limit);
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], MessageColumns);
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    const { localeCode, timezone } = this.props.auth.selectedFleet || {};

    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "table-selectbox":
          let length = this.state.list ? this.state.list.length : 0;
          let isCheckedAll =
            length &&
            _.filter(this.state.list, item => item.checked).length === length;

          col.customHeader = columnKey => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={isCheckedAll}
                onChange={this.handleSelectAllClick}
              />
            );
          };
          col.customCell = data => {
            return (
              <CcCheckbox
                className="table-selectbox"
                checked={data.checked}
                onChange={e =>
                  this.handleSelectRowClick(e.target.checked, data.inboxContentId)
                }
              />
            );
          };
          break;
        case "createdDate":
          col.customCell = data => (
            <Moment
              tz={this.props.auth.selectedFleet.timezone}
              format="MMM DD, YYYY hh:mm A"
              date={data.createdDate}
              className="cursor-pointer"
              onClick={() => this.handleDetailItemClick(data)}
            />
          );
          break;
        case "subject":
          col.customCell = data => {
            return (
              <div
                className="text-ellipsis cursor-pointer"
                onClick={() => this.handleDetailItemClick(data)}
              >
                {data.languages && data.languages.length > 0 ? data.languages.map(item => item.subject).join('; ') : data.subject}
              </div>
            )
          };
          break;
        case "to":
          col.customCell = data => (
            <div
              className="text-ellipsis cursor-pointer"
              onClick={() => this.handleDetailItemClick(data)}
            >
              {
                this.state.appType == "driver"
                  ? (data.sendTo == 1 ? I18n.t("message.Customized_driver") : I18n.t("message.All_drivers"))
                  : this.state.appType == "passenger" ? (data.sendTo == 1 ? I18n.t("message.Customized_customer") : I18n.t("message.All_customers"))
                  :  (data.sendTo == 1 ? I18n.t("message.Customized_merchant") : I18n.t("message.All_merchants"))
              }
            </div>
          );
          break;
        case "type":
          col.customCell = data => (
            <div
              className="text-ellipsis cursor-pointer"
              onClick={() => this.handleDetailItemClick(data)}
            >
              {data.type}
            </div>
          );
          break;
        case "expiryDate":
          col.customCell = data => {
            return (
              <div
                className="text-ellipsis cursor-pointer"
                onClick={() => this.handleDetailItemClick(data)}
              >
                {data.expiryDate ? moment(data.expiryDate).tz(timezone).locale(localeCode).format('L') : I18n.t("message.NA")}
              </div>
            )
          };
          break;
        // case "result":
        //   col.customCell = data => {
        //     let result = I18n.t("message.Failed");
        //     if (!data.inboxStatus && data.type == "All") {
        //       result = I18n.t("message.SMS_failed_Inbox_succeeded");
        //     } else if (data.inboxStatus) {
        //       result = I18n.t("message.Succeeded");
        //     }
        //     return (
        //       <div
        //         className="text-ellipsis cursor-pointer"
        //         onClick={()=>this.handleDetailItemClick(data)}
        //       >
        //         {result}
        //       </div>
        //     );
        //   };
        //   break;
        case "actions":
          // if (!permissions || actions)
          //   col.customCell = data => (
          //     <a
          //       onClick={e => {
          //         this.handleDeleteMessageClick(data, e);
          //       }}
          //       className="cursor-pointer text-delete-header"
          //     >
          //       <Translate value="message.Delete" />
          //     </a>
          //   );
          // else tableColumns.splice(index, 1);

          col.customCell = (data, rowIndex) => {
            if (!permissions || actions) {
              let actionItems = [];
              if (!data.editable) {
                actionItems.push({
                  label: "message.Edit",
                  eventKey: "Edit"
                });
              }
              if (!data.inUse) {
                actionItems.push({
                  label: "message.Delete",
                  eventKey: "Delete"
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={data}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, data);
                  }}
                  totalRow={this.state.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", data);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="message.View" />
                </a>
              );
            }
          };
          break;
        default:
          break;
      }
    });
    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  export = (options) => {
    return this.props.exportExcel(options)
  }

  render() {
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    const isShowMessages = this.props.auth.user.isAdmin
      ? true
      : (permissions.sendinbox || permissions.sendsms) ? true : false;

    return (
      isShowMessages && <div className="content">
        <ButtonToolbar
          className="text-center header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t("message.Search_here")}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {
              (!permissions || actions) && <React.Fragment>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleAddClick}
                >
                  {" "}

                  <Translate value="message.Add" />
                </Button>
                <Button
                  className="btn-header text-delete-header"
                  onClick={this.handleDeleteClick}
                >
                  {" "}

                  <Translate value="message.Delete" />
                </Button>
              </React.Fragment>
            }
          </ButtonGroup>
        </ButtonToolbar>

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={this.state.list}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={50}
            getTableHeight={this.getTableHeight}
          />
        </div>
        (!permissions || actions) && <NewMessage
          data={{
            appType: this.state.appType,
            isShow: this.state.isShow,
            permissions: permissions
          }}
          onSuccess={this.handleAddNewMessageSuccess}
          closeDialog={this.handleCloseDialog}
        />
        {this.state.showDialog ? (
          <MessageDetail
            detailItem={this.state.detailItem}
            closeDialog={this.handleCloseDialog}
            handleEditMessage={this.handleEditMessageClick}
            export={this.export}
            fleet={this.props.auth.selectedFleet}
            user={this.props.auth.user}
            editable={this.state.editable}
            permissions={permissions}
            auth={this.props.auth}
          />
        ) : null}

        (!permissions || actions) && <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Message.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getMessage: options => {
      return dispatch(getMessage(options));
    },
    deleteMessages: options => {
      return dispatch(deleteMessages(options));
    },
    exportExcel: options => {
      return dispatch(exportReceivers(options))
    },
    editMessage: options => {
      return dispatch(editMessage(options));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);
