export const socketConfigs = {
  socketDispatchServer:
    process.env.REACT_APP_SOCKET_SERVER || 'http://192.168.2.91:8000/api/',
  socketLocalServer:
    process.env.REACT_APP_SOCKET_LOCAL || 'http://192.168.2.91:8000/api/',
  socketDebug: process.env.REACT_APP_SOCKET_DEBUG || true,
  apis: {
    getCrosszoneRateApi:
      process.env.REACT_APP_SOCKET_SERVER + '/api/v2/distance/geolocations',
  },
  send: {
    newBooking: 'rq',
    validateBooking: 'validateJob',
    importBooking: 'importJob',
    ccLiteLogin: 'ccLiteLogin',
    ccRequestLogin: 'CCRequestLogin',
    subscribe: 'subscribe',
    unSubscribe: 'unSubscribe',
    booking: {
      complete: 'completeCC',
      completeWithoutPay: 'incident',
      cancel: 'cancelBookingCC',
      update: 'editBookingCC',
      reBooking: 'reBooking',
      incident: 'incident',
      retryCompleteBooking: 'retryCompleteBooking',
      changePaymentType: 'changePaymentType',
      rejectBooking: 'rejectBooking',
    },
    trip: {
      cancel: 'operatorCancelTrip',
      assignDriver: 'assignTrip',
    },
  },
  receive: {
    newBooking: 'rq',
    validateBooking: 'validateJob',
    importBooking: 'importJob',
    notification: 'notification',
    notifyReservation: 'reservation',
    notifyNoStart: 'notStart',
    notifyNewFarmInJob: 'newFarmInJob',
    notifyNewFarmInJobFrom3rd: 'newFarmInJobFrom3rd',
    notifyCanceledJobBy3rd: 'canceledJobBy3rd',
    notifyUpdatedJobBy3rd: 'updatedJobBy3rd',
    notifyMdispatch: 'rqDrvQueue',
    notifyDMCCanceled: 'dmcCanceled',
    sos: 'sos',
    signUpNotify: 'newDriverSignedUp',
    registeredCard: 'registeredCard',
    topupPrepaid: 'topupPrepaid',
    stripeConnectStatus: 'stripeConnectStatus',
    ccLogin: 'CCLogin',
    driver: {
      update_info: 'd',
      update_location: 'f1',
      new_driver: 'nd',
      set_bookIn: 'bookIn',
      set_bookOFF: 'bookOff',
      set_offline: 'offline',
      endShift: 'endShift'
    },
    booking: {
      update_booking: 'b',
      complete: 'completeCC',
      completeWithoutPay: 'incident',
      cancel: 'cancelBookingCC',
      update: 'editBookingCC',
      reBooking: 'reBooking',
      edit: 'editBooking',
      remove: 'rb',
      removeHydra: 'removeJob',
      new_booking: 'nb',
      incident: 'incident',
      retryCompleteBooking: 'retryCompleteBooking',
      changePaymentType: 'changePaymentType',
      completed2CC: 'completed2CC',
      rejectBooking: 'rejectBooking',
      preauthResult: 'preauth_result',
      chargeResult: 'chargeResult',
      add_card_senangpay_result: 'add_card_senangpay',
      chargeIntercityBooking_result: 'chargeIntercityBooking_result',
      bookingSynced: 'bookingSynced',
    },
    ivr: {
      newbooking: 'ivrNewBooking',
      existedBooking: 'ivrBookingExists'
    },
    report: {
      downloadProgress: 'downloadProgress'
    },
    corporate: {
      topup: 'topupSuccessful'
    },
    trip: {
      updateTrip: 'updateTrip',
      newTrip: 'newTrip',
      cancel: 'operatorCancelTrip',
      assignDriver: 'assignTrip'
    },
    payout: {
      dnbResult: 'dnbResult'
    }
  }
};
