const fields = [
  {
    key: 'fullName',
    label: 'supplier.supplier',
    fixed: true,
    textEllipsis: true
  },
  {
    key: 'phone',
    label: 'supplier.mobile',
  },
  {
    key: 'email',
    label: 'supplier.email',
  },
  {
    key: 'supplierPermission',
    label: 'supplier.Role',
    textEllipsis: true,
    customCellAndEllipis: true,
  },
  {
    key: 'status',
    label: 'supplier.status',
  },
  {
    key: 'actions',
    label: 'customer.Action',
  },
];

export default fields;
