export const Columns = [
  {
    key: "fullName",
    label: "merchant.user.fullName",
    width: 200,
    fixed: true,
    textEllipsis: true,
  },
  {
    key: "phone.full",
    label: "merchant.user.Phone_Number",
    width: 200,
  },
  {
    key: "email",
    label: "merchant.user.Email",
    width: 250,
  },
  {
    key: "companyId.name",
    label: "merchant.Company",
    width: 200,
  },
  {
    key: "merchantId.name",
    label: "merchant.user.storeName",
    width: 200,
  },
  {
    key: "wallet.creditWallet",
    label: "merchant.user.Credit_Balance",
    width: 200,
  },
  {
    key: "wallet.cashWallet",
    label: "merchant.user.Cash_Balance",
    width: 200,
  },
  {
    key: "status",
    label: "merchant.user.status",
    width: 120,
  },
  {
    key: "actions",
    label: "merchant.user.actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
]