import io from 'socket.io-client';
import { socketConfigs } from '../constants/socketConfigs';
import { loadUserProfile } from './apiUtils';

let socketDispatch = null;
let socketLocal = null;

export const socketDispatchApi = {
  socketLogin() {
    if (!socketDispatch) {
      socketDispatch = io(socketConfigs.socketDispatchServer, {
        transports: ['websocket'],
        secure: true,
        query: {
          platform: 'web - newcc',
        },
        path: '/socket.io-v4/'
      });
      socketDispatch.on('connect', () => {
        const user = loadUserProfile();
        socketDispatch.emit(socketConfigs.ccLiteLogin, {
          token: user.token,
          fleetId: '',
        });
      });
    }
  },
  on(event, callback) {
    if (socketDispatch) {
      socketDispatch.on(event, (data) => {
        callback(data);
      });
    }
  },

  emit(event, data, callback) {
    if (socketDispatch) {
      socketDispatch.emit(event, data, (data) => {
        callback(data);
      });
    }
  },
  remove(event) {
    if (socketDispatch) {
      socketDispatch.removeListener(event);
    }
  },
  close() {
    if (socketDispatch) {
      socketDispatch.disconnect();
    }
  },
  socketDispatch:
    socketDispatch
    || io(socketConfigs.socketDispatchServer, {
      query: {
        platform: 'web - newcc',
      },
      transports: ['websocket'],
    }),
};

export function socketDispatchAuth(
  fleetid,
  connectedCallback,
  disconnectCallback,
  reconnect_attemptCallback
) {
  if (!socketDispatch) {
    socketDispatch = io(socketConfigs.socketDispatchServer, {
      query: {
        platform: 'web - newcc',
      },
      transports: ['websocket'],
      path: '/socket.io-v4/'
    });
    socketDispatch.on('connect', () => {
      const user = loadUserProfile();
      socketDispatch.emit(socketConfigs.send.ccLiteLogin, {
        token: user.token,
        fleetId: fleetid,
      });
    });
    socketDispatch.on(socketConfigs.send.ccLiteLogin, (payload) => {
      connectedCallback(payload);
    });
    socketDispatch.on('disconnect', (attempt) => {
      if (socketConfigs.socketDebug) console.log('SocketDispatch.disconnect', attempt);
      disconnectCallback();
    });
    socketDispatch.on('reconnect_attempt', (attempt) => {
      if (socketConfigs.socketDebug) console.log('SocketDispatch.reconnect_attempt', attempt);
      reconnect_attemptCallback();
    });

    if (socketConfigs.socketDebug) {
      socketDispatch.on('connect_error', (error) => {
        console.log('SocketDispatch.connect_error', error);
      });
      socketDispatch.on('connect_timeout', (error) => {
        console.log('SocketDispatch.connect_timeout', error);
      });
      socketDispatch.on('reconnect', (attempt) => {
        console.log('SocketDispatch.reconnect', attempt);
      });
      socketDispatch.on('reconnect_error', (error) => {
        console.log('SocketDispatch.reconnect_error', error);
      });
      socketDispatch.on('reconnect_failed', (error) => {
        console.log('SocketDispatch.reconnect_failed', error);
      });
    }
  }
}