import React, { Component } from "react";
import "./loadingSpiner.scss";
export default class LoadingSpiner extends Component {
  render() {
    if (this.props.show) {
      return (
        // <div className="loading-spiner">Loading&#8230;</div>
        <div className="cc-loading-spiner">
          <div className="ball-spin-fade-loader">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
