import {
    NEW_DELIVERY_FROM_EXISTED,
    NEW_DELIVERY_REQUEST,
    CLOSE_DELIVERY_FORM,
    DELIVERY_REQUEST,
    DELIVERY_SUCCESS,
    DELIVERY_FAILURE
} from "../actions/deliveryAction";


const initialState = {
    isShow: false,
    loading: false,
    error: '',
    data: {},
    common: {
        carTypes: [],
        carType: {},
        companyVehicleTypes: []
    }
};

const delivery = (state = initialState, action) => {
    switch (action.type) {
        case NEW_DELIVERY_FROM_EXISTED:
            return { ...state, bookingData: action.data, isShow: true };;
        case NEW_DELIVERY_REQUEST:
            return {
                ...state,
                isShow: true
            };
        case CLOSE_DELIVERY_FORM:
            return initialState;
        case DELIVERY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            };
        case DELIVERY_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload
            };
        default:
            return state
    }
}

export default delivery