import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { FormGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CurrencyFormatter from 'currency-formatter';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as newbookingActions from '../../../actions/newbookingAction';
import moment from 'moment';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';
import EditFare from '../../../components/bookingDetail/bookComponent/EditFareComponent'
import { BOOK_TYPE_FEE, NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD } from '../../../constants/commondata';
import FareDeeplink from '../../../components/bookingDetail/bookComponent/FareDeeplink';

class TripEstimate extends PureComponent {
  state = {
    allowEditFare: false,
    allowMarkupPrice: false
  };

  componentDidMount() {
    if (this.props.bookingPermission) {
      const editFare = this.props.bookingPermission.find(item => item.name === "EditFare") || {};
      if (!this.props.bookInfo || !this.props.bookInfo.drvInfo || !this.props.bookInfo.drvInfo.userId) {
        this.setState({
          allowEditFare: this.props.selectedFleet.generalSetting.editBookingFare && editFare.isActive,
          allowMarkupPrice: this.props.selectedFleet.generalSetting.markupPrice && editFare.isActive &&
            (this.props.request && !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.request.paymentType)))
        });
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.bookingPermission) {
      const editFare = this.props.bookingPermission.find(item => item.name === "EditFare") || {};
      if (!this.props.bookInfo || !this.props.bookInfo.drvInfo || !this.props.bookInfo.drvInfo.userId) {
        this.setState({
          allowEditFare: this.props.selectedFleet.generalSetting.editBookingFare && editFare.isActive,
          allowMarkupPrice: this.props.selectedFleet.generalSetting.markupPrice && editFare.isActive &&
            (this.props.request && !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(parseInt(this.props.request.paymentType)))
        });
      }
    };
  };

  render() {
    const { etaFare, routeSelected, selectedFleet, bookInfo, prevEtaFare, promo, request, isFareEditedBefore } = this.props;
    let prevTotalFare = null;

    if ((prevEtaFare && prevEtaFare.isFareEdited && prevEtaFare.originFare && prevEtaFare.originFare.etaFare)
      || (prevEtaFare && prevEtaFare.etaFare && prevEtaFare.etaFare.markupDifference)) {
      prevTotalFare = prevEtaFare.originFare.etaFare;
    }

    if (!etaFare || _.isEmpty(routeSelected)) {
      return null;
    }
    if (bookInfo && CCLiteCommonFunc.isBookingStatusCompleted(bookInfo.status)) {
      return null;
    }

    let promoInfo = {};
    if (promo && promo.type) {
      promoInfo = { value: promo.value, type: promo.type, maximumValue: promo.maximumValue }
    };

    let showEditFare = true;
    if (bookInfo && request.paymentType === 2 && !isFareEditedBefore) {
      showEditFare = false
    };

    return (
      <div className="trip-estimate-container">
        <div className="estimate-header">
          <div className="estimate-title">
            {`${routeSelected.firstLocation.name} - ${routeSelected.secondLocation.name}`}
            <FareDeeplink fareInfo={etaFare} auth={this.props.auth}/>
          </div>
          <div className="estimate-route text-ellipsis">
            <div className="text-ellipsis">
              <Translate value="newbooking.TRIP_ESTIMATE" />
            </div>
          </div>
        </div>

        <div className="estimateInfo">
          <div className="item">
            <Translate value="newbooking.Distance" />
            <span>{routeSelected.routeSetting.distance}&nbsp;{selectedFleet.unitDistance}</span>
          </div>
          <div className="item">
            <Translate value="newbooking.Duration" />
            <span>{routeSelected.routeSetting.duration}&nbsp;{routeSelected.routeSetting.durationUnit}</span>
            <span></span>
          </div>
          {(this.state.allowEditFare || this.state.allowMarkupPrice) && showEditFare && !_.isEmpty(etaFare) ?
            <EditFare
              etaFare={etaFare}
              markupValue={etaFare.markupValue}
              markupType={etaFare.markupType}
              reasonMarkup={etaFare.reasonMarkup}
              promoInfo={promoInfo}
              allowEditFare={this.state.allowEditFare}
              allowMarkupPrice={this.state.allowMarkupPrice}
              data={bookInfo}
              prevEtaFare={this.props.prevEtaFare}
              isFareEditedBefore={isFareEditedBefore}
              selectedFleet={selectedFleet}
              handleChangeETAFare={this.props.handleChangeETAFare}
              handleChangeMarkupPrice={this.props.handleChangeMarkupPrice}
              bookType={BOOK_TYPE_FEE.intercity}
            />
            :
            <div className="item">
              <Translate value="newbooking.Fare" />
              <span>
                {CurrencyFormatter.format(etaFare.etaFare + (etaFare.markupDifference || 0), {
                  code: etaFare.currencyISO,
                })}
              </span>
              {prevTotalFare &&
                <div className="item">
                  <span style={{ textDecoration: "line-through" }}>{CurrencyFormatter.format(prevTotalFare, { code: etaFare.currencyISO })}</span>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

TripEstimate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    auth: state.auth,
    etaFare: state.intercityBooking.common.etaFare,
    markupType: state.intercityBooking.common.markupType,
    markupValue: state.intercityBooking.common.markupValue,
    reasonMarkup: state.intercityBooking.common.reasonMarkup,
    promo: state.intercityBooking.common.promo,
    routeSelected: state.intercityBooking.common.routeSelected,
    bookInfo: state.bookingDetail.data,
    bookingPermission: state.menuHandle.bookingPermission,
    isFareEditedBefore: state.intercityBooking.common.isFareEditedBefore
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TripEstimate);
