import React, { Fragment } from 'react';
import {
  FormGroup,
  Col,
  InputGroup,
  FormControl,
  Row,
  ButtonToolbar,
  Button,
  Form,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import FormGroupTitle from '../../../../../components/formGroupTitile/FormGroupTitle';
import {
  textboxNumberHelper,
  filterCommissionServiceActive,
} from '../../../../../utils/commonFunctions';
import { defaultCommissionFleet } from '../../../../../constants/commondata';

class ServiceCommission extends React.Component {
  constructor(props) {
    super(props);
  }

  /* handleTollFeeCheckChange = e => {
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('tollFeeActive', e.target.checked);
  };
 */
  // need to add parkingFee and gasFee
  handleCommissionTypeChangeAndValue(e, serviceType = '', key) {
    let regrex = /^(\d*\.?\d{0,2})$/;
    if (key == 'value' && !regrex.test(e.target.value)) return;
    if (key == 'insurance' && !regrex.test(e.target.value)) return;
    const {
      handleUpdateFormData,
      valueCommissions,
      defaultFleetCommissionType,
    } = this.props;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = valueCommissions.map((commission) => {
        if (commission.serviceType === serviceType) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        handleUpdateFormData(defaultFleetCommissionType, newValueCommissions);
      }
    }
  }

  render() {
    const {
      editable,
      zone,
      auth = {},
      editFleetCommission = false,
      valueCommissions = [],
      isCommissionZone,
    } = this.props;
    const commissionServiceActive = filterCommissionServiceActive(
      valueCommissions,
      auth.selectedFleet
    );
    const driverInsurance = auth.selectedFleet.generalSetting.driverInsurance;

    return (
      <>
        {zone && zone.zoneName && (
          // <div className="nameCommissionZone">
          //   {zone.zoneName}
          // </div>
          <div className="serviceFeesForm__header_2">{zone.zoneName}</div>
        )}
        {commissionServiceActive &&
          commissionServiceActive.map((commission) => {
            let symbol =
              commission.type === 'percent'
                ? '%'
                : auth.selectedFleet.currencies[0].symbol;
            return (
              <Fragment>
                <div className="group-general">
                  <div key={commission.serviceType}>
                    <Row>
                      <Col xs={12}>
                        <FormGroupTitle>
                          <Translate
                            value={`driver.${commission.serviceType}`}
                          />
                        </FormGroupTitle>
                      </Col>
                      <Col md={6} xs={12}>
                        <FormGroup>
                          <FormControl
                            as="select"
                            value={commission.type}
                            onChange={(e) => {
                              this.handleCommissionTypeChangeAndValue(
                                e,
                                commission.serviceType,
                                'type'
                              );
                            }}
                            disabled={editable}
                            className="form-custom"
                          >
                            <option value="percent">
                              {I18n.t('driver.Percentage')}
                            </option>
                            <option value="amount">
                              {I18n.t('driver.Amount')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup
                          className="qup-input-group"
                        >
                          <div className="input-with-validator">
                            <InputGroup
                              className={editFleetCommission ? 'disabled' : ''}
                              >
                              <InputGroup.Prepend>
                                <InputGroup.Text>{symbol}</InputGroup.Text>
                              </InputGroup.Prepend>{' '}
                              <FormControl
                                type="text"
                                onChange={(e) => {
                                  this.handleCommissionTypeChangeAndValue(
                                    e,
                                    commission.serviceType,
                                    'value'
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCommissionTypeChangeAndValue(
                                      e,
                                      commission.serviceType,
                                      'value'
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCommissionTypeChangeAndValue(
                                      e,
                                      commission.serviceType,
                                      'value'
                                    );
                                  });
                                }}
                                value={commission.value}
                                disabled={editable}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  /<Translate value="driver.transaction" />
                                </InputGroup.Text>
                              </InputGroup.Append>{' '}
                            </InputGroup>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    {driverInsurance && (
                      <Row>
                        <Col xs={12}>
                          <Form.Label className="mb titleService">
                            <Translate value={`driver.driverInsurance`} />
                          </Form.Label>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="qup-input-group">
                            <div className="input-with-validator">
                              <InputGroup
                                className={`single-addon-left ${
                                  editFleetCommission ? 'disabled' : ''
                                }`}
                              >
                                <FormControl
                                  type="text"
                                  onChange={(e) => {
                                    this.handleCommissionTypeChangeAndValue(
                                      e,
                                      commission.serviceType,
                                      'insurance'
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleCommissionTypeChangeAndValue(
                                        e,
                                        commission.serviceType,
                                        'insurance'
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleCommissionTypeChangeAndValue(
                                          e,
                                          commission.serviceType,
                                          'insurance'
                                        );
                                      }
                                    );
                                  }}
                                  value={commission.insurance}
                                  disabled={editable}
                                />
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {auth.selectedFleet.currencies[0].symbol}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>{' '}
                              </InputGroup>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              </Fragment>
            );
          })}
        {isCommissionZone && (
          <ButtonToolbar className="text-center mv-md">
            {!editable ? (
              <Button className="btn-save" onClick={this.props.handleSaveClick}>
                <Translate value="generalSetting.Save" />
              </Button>
            ) : (
              ''
            )}
          </ButtonToolbar>
        )}
      </>
    );
  }
}

ServiceCommission.defaultProps = {
  valueCommissions: defaultCommissionFleet,
  zone: {},
  isCommissionZone: false,
};

export default ServiceCommission;
