import React from 'react';
import _ from 'lodash';
import {
  Modal, FormGroup, FormControl, Button
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportNotificationActions from '../../../actions/reportNotificationActions';
import * as bookingDetailActions from "../../../actions/bookingDetailAction";
import * as notificationActions from "../../../actions/notificationActions";
import * as loadingActions from "../../../actions/loadingBarActions";
import BaseNotifications from '../base/BaseNotifications';
import { signUpHistory } from "../../../constants/ApiConfigs"
import * as commonData from '../../../constants/commondata';
import PropTypes from "prop-types";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteNotiItem = (data, index) => {
    this.props.notificationActions.signUpNotificationDelete({
      fleetId: this.props.selectedFleet.fleetId,
      id: data.id,
      index: index
    })
  }

  itemclick = (noti, index) => {
    console.log("itemclick ", noti, index);
    if (noti.action != 'DebtWriteOff') {
      // this.props.bookingDetailActions
      //   .getBookingDetail(noti.bookId * 1, false)
      //   .then(data => {
      //     if (!data.res || data.error) {
      //       this.props.bookingDetailActions
      //         .getBookingDetail(noti.bookId * 1, true)
      //         .then(data => {
      //           if (!data.res || data.error) {
      //             this.context.notification(
      //               "error",
      //               I18n.t("messages.booking.Booking_detail_not_found")
      //             );
      //           }
      //         });
      //     }
      //   });
      this.props.notificationActions.signUpNotificationMarkItemAsRead({
        fleetId: this.props.selectedFleet.fleetId,
        id: noti.id,
        index: index
      })
    }
  }

  render() {
    return (
      <BaseNotifications
        apiurl={signUpHistory}
        itemclick={this.itemclick}
        deleteNotiItem={this.deleteNotiItem}
        placeholderSearch={I18n.t("notifications.searchProviderName")}
        notificationTypes={commonData.signUpNotificationsStatus}
        isSignUpNotification
        noexport
      />
    );
  }
}

Notifications.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportNotificationActions: bindActionCreators(reportNotificationActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
