import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import '../../settings.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../components/validator';
import {
  additionalServiceType,
  DefaultCurenciesNumberInputField,
  repeatType,
  SURCHARGE_DEFAULT,
  APPLY_SERVICE_TYPE,
  FEE_TYPE,
} from '../../../../constants/commondata';
import {
  ModalConfirmDeleteSurcharge,
  ModalAddSurcharge,
  ModalConfirmChangeApplyType,
  ModalFleetService,
} from '../modals';
import CustomZoneList from './CustomZoneList';
import ServiceFeesForm from './ServiceFeesForm';
import TollFee from './FormItem/TollFee';
import OtherFee from './FormItem/OtherFee';
import TechFee from './FormItem/TechFee';
import FleetService from './FleetserviceForm';
import FormFee from './components/formFee';

const surchargeType = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
};

const surchargeInitial = { ...SURCHARGE_DEFAULT };

class ServiceFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData,
      fleet: props.fleet,
      showDialog: false,
      dialogData: {},
      showConfirmDelete: false,
      showConfirmDeleteFleetService: false,
      valid: {},
      isSubmited: false,
      showFleetServiceDialog: false,
      isFleetServiceSubmitted: false,
      rushHourIndex: -1,
      surchargeByCurrenciesInitial: [],
    };
  }

  handleUpdateFormData = (key, value) => {
    const { formData } = this.state;
    _.set(formData, key, value);
    this.setState({ formData });
  };

  handleRushHourCheckChange = (e) => {
    this.state.formData.rushHourActive = e.target.checked;
    this.setState({ formData: this.state.formData });
  };

  handleHeavyTrafficCheckChange = (e) => {
    this.state.formData.heavyTrafficActive = e.target.checked;
    this.setState({ formData: this.state.formData });
  };

  handleTaxCheckChange = (e) => {
    this.state.formData.taxActive = e.target.checked;
    if (!e.target.checked) {
      this.state.formData.tax = '0';
    }
    this.setState({ formData: this.state.formData, valid: {} });
  };

  handleTaxSurchargeChange = (e) => {
    this.state.formData.tax = e.target.value;
    this.setState({ formData: this.state.formData });
  };

  onFleetServiceChange = (e) => {
    this.state.formData.fleetServiceActive = e.target.checked;
    this.setState({ formData: this.state.formData });
  };

  fleetServiceAddItemClick = () => {
    const dialogData = {
      serviceName: '',
      serviceType: additionalServiceType.Optional,
      serviceFeeByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        }
      ),
      serviceFeeType: FEE_TYPE.Amount,
      serviceFeePercent: 0,
      vehicleType: [],
      isActive: true,
      fleetCommission: {
        commissionType: 'percent',
        commissionByCurrencies: [
          {
            value: 0,
            currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
          },
        ],
      },
    };
    this.state.dialogData.service = dialogData;
    this.setState({
      showFleetServiceDialog: true,
      dialogData: this.state.dialogData,
    });
  };

  fleetServiceEditItemClick = (item, index) => {
    this.props.settingActions
      .getFleetService({
        fleetId: this.props.auth.selectedFleet.fleetId,
        fleetServiceId: item._id,
      })
      .then((data) => {
        if (data.res) {
          this.state.dialogData.service = data.res;
          this.setState({
            dialogData: this.state.dialogData,
            showFleetServiceDialog: true,
          });
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('generalSetting.Get_fleet_service_fail')
            );
          }
        }
      });
  };

  fleetServiceRemoveItemClick = (item, index) => {
    this.options = {
      fleetServiceId: item.serviceId,
      fleetId: this.props.auth.selectedFleet.fleetId,
    };
    this.itemConfirm = 'fleetService';
    this.setState({
      showConfirmDelete: true,
      itemConfirm: 'fleetService',
    });
  };

  fleetServiceAddItemSaveClick = (service) => {
    this.props.loadingBarActions.showLoadingSpiner();
    if (service && service._id) {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        fleetServiceId: service._id,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        applyTax: service.applyTax || false,
        serviceFeeType: service.serviceFeeType,
        vehicleType: service.vehicleType.map((item) => item.value),
        fleetCommission: service.fleetCommission,
        bookingType: service.bookingType || 'all',
        isActive: true,
      };
      if (service.serviceFeeType === FEE_TYPE.Percent) {
        serviceData.serviceFeePercent = service.serviceFeePercent;
      } else {
        serviceData.serviceFeeByCurrencies = service.serviceFeeByCurrencies;
      }
      this.props.settingActions.updateFleetService(serviceData).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res && data.res.fleetServices) {
          this.setState({
            formData: {
              ...this.state.formData,
              fleetServices: data.res.fleetServices,
            },
            isFleetServiceSubmitted: false,
          });
          this.context.notification(
            'success',
            'The fleet service has been updated successfully'
          );
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification('error', 'Failed to updated service');
          }
        }
      });
    } else {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        serviceFeeByCurrencies: service.serviceFeeByCurrencies,
        serviceFeeType: service.serviceFeeType,
        serviceFeePercent: service.serviceFeePercent,
        vehicleType: service.vehicleType.map((item) => item.value),
        fleetCommission: service.fleetCommission,
        applyTax: service.applyTax || false,
        bookingType: service.bookingType || 'all',
        isActive: true,
      };
      if (service.serviceFeeType === FEE_TYPE.Percent) {
        serviceData.serviceFeePercent = service.serviceFeePercent;
      } else {
        serviceData.serviceFeeByCurrencies = service.serviceFeeByCurrencies;
      }
      this.props.settingActions.addFleetService(serviceData).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res) {
          this.setState({
            formData: {
              ...this.state.formData,
              fleetServices: data.res,
            },
            isFleetServiceSubmitted: false,
          });
          this.context.notification(
            'success',
            'New fleet service has been created successfully'
          );
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('Failed to add fleet service ')
            );
          }
        }
      });
    }
    service = {};
    this.setState({
      showFleetServiceDialog: false,
      dialogData: this.state.dialogData,
    });
  };

  fleetServiceAddItemCloseClick = () => {
    this.state.dialogData.service = {};
    this.setState({
      showFleetServiceDialog: false,
      dialogData: this.state.dialogData,
      isFleetServiceSubmitted: false,
    });
  };

  closeDeleteServiceDialog = () => {
    this.setState({ showConfirmDelete: false });
  };

  handlePaytoChange = (e, key) => {
    const value = parseInt(e.target.value || 0);
    switch (key) {
      case 'meetDriver': {
        this.state.formData.meetDriver.payTo = value;
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'airport': {
        this.state.formData.airport.payTo = value;
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'tollfee': {
        this.state.formData.tollFeePayTo = value;
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'gasFee': {
        this.state.formData.gasFeePayto = value;
        this.setState({ formData: this.state.formData });
        break;
      }
      case 'parkingFee': {
        this.state.formData.parkingFeePayto = value;
        this.setState({ formData: this.state.formData });
        break;
      }
      default:
        break;
    }
  };

  handleApplyTypeChange = (e) => {
    this.setState({
      showConfirmChangeApplyType: true,
      applyType: e.target.value,
    });
  };

  rushHourRemoveItemClick = (item, index) => {
    this.options = {
      rushHourId: item.rushHourId,
      fleetId: this.props.auth.selectedFleet.fleetId,
    };
    this.itemConfirm = 'surcharge';
    this.setState({
      showConfirmDelete: true,
      itemConfirm: 'surcharge',
    });
  };

  addDistanceDefault = (surcharge = [], distanceDiversity = true) => {
    if (!distanceDiversity) {
      return [
        {
          ...surcharge[0],
          distanceRange: SURCHARGE_DEFAULT.distanceRange,
        },
      ];
    }
    const distanceRange = (surcharge[0] && surcharge[0].distanceRange) || [];
    return [
      {
        ...surcharge[0],
        distanceRange:
          distanceRange.length > 0
            ? distanceRange
            : SURCHARGE_DEFAULT.distanceRange,
      },
    ];
  };

  rushHourEditItemClick = (item, index) => {
    this.props.settingActions
      .getSurchargeDetail({
        fleetId: this.props.auth.selectedFleet.fleetId,
        rushHourId: item.rushHourId,
      })
      .then((data) => {
        if (data.ok && data.res) {
          const dialogData = {
            name: data.res.name,
            date: {
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
              Sat: false,
              Sun: false,
            },
            From: data.res.start.split(':'),
            To: data.res.end.split(':'),
            surcharge: data.res.surcharge,
            timeCal: data.res.timeCal,
            surchargeByCurrencies: data.res.surchargeByCurrencies,
            surchargeNow: this.addDistanceDefault(
              data.res.surchargeNow,
              data.res.distanceDiversity
            ),
            surchargeReservation: this.addDistanceDefault(
              data.res.surchargeReservation,
              data.res.distanceDiversity
            ),
            surchargeType: data.res.surchargeType
              ? data.res.surchargeType
              : surchargeType.AMOUNT,
            percentSurcharge:
              data.res.surchargeType === surchargeType.PERCENT
                ? data.res.surchargeByCurrencies
                : [{ currencyISO: '', value: 0 }],
            amountSurcharge:
              data.res.surchargeType === surchargeType.AMOUNT
                ? data.res.surchargeByCurrencies
                : this.props.auth.selectedFleet.currencies.map((data) => ({
                    value: 0,
                    currencyISO: data.iso,
                  })),
            repeatType: data.res.repeat,
            distanceDiversity: data.res.distanceDiversity || false,
          };
          if (data.res.repeat === repeatType.DayOfWeek) {
            data.res.date.map((d) => {
              dialogData.date[d] = true;
            });
          } else {
            dialogData.dateSurcharge = moment(data.res.startDate).format(
              'MM/DD/YYYY'
            );
          }
          this.setState({
            showDialog: true,
            dialogData,
            rushHourIndex: index,
            surchargeByCurrenciesInitial: data.res.surchargeByCurrencies,
            surchargeNowInitial: data.res.surchargeNow,
            surchargeReservationInitial: data.res.surchargeReservation,
          });
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Get_surcharge_detail_fail')
          );
        }
      });
  };

  rushHourAddItemClick = () => {
    const dialogDataInitial = {
      name: '',
      date: {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      },
      From: ['00', '00'],
      To: ['23', '59'],
      surcharge: 0,
      timeCal: 0,
      surchargeByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => ({
          value: 0,
          currencyISO: data.iso,
          distanceRange: [
            {
              from: 0,
              to: Number.MAX_SAFE_INTEGER,
              surchargeValue: 0,
            },
          ],
        })
      ),
      surchargeNow: this.props.auth.selectedFleet.currencies.map((data) => ({
        ...surchargeInitial,
        currencyISO: data.iso,
      })),
      surchargeReservation: this.props.auth.selectedFleet.currencies.map(
        (data) => ({
          ...surchargeInitial,
          currencyISO: data.iso,
        })
      ),
      surchargeType: surchargeType.AMOUNT,
      percentSurcharge: [
        {
          currencyISO: '',
          value: 0,
          distanceRange: [
            {
              from: 0,
              to: Number.MAX_SAFE_INTEGER,
              surchargeValue: 0,
            },
          ],
        },
      ],
      amountSurcharge: this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
        distanceRange: [
          {
            from: 0,
            to: Number.MAX_SAFE_INTEGER,
            surchargeValue: 0,
          },
        ],
      })),
      repeatType: repeatType.DayOfWeek,
      distanceDiversity: false,
    };
    this.setState({
      showDialog: true,
      dialogData: dialogDataInitial,
      rushHourIndex: -1,
      surchargeByCurrenciesInitial: [],
    });
  };

  rushHourAddItemSaveClick = (rushHour) => {
    const { rushHourIndex } = this.state;
    if (rushHourIndex === -1) {
      this.props.settingActions.addSurcharge(rushHour).then((data) => {
        if (data.ok && data.res) {
          this.state.formData.rushHours = data.res;
          this.setState({
            isRushHourSubmitted: false,
            showDialog: false,
            formData: this.state.formData,
            dialogData: {},
          });
          this.context.notification(
            'success',
            I18n.t('generalSetting.Create_surcharge_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        }
      });
    } else {
      rushHour.rushHourId =
        this.state.formData.rushHours[this.state.rushHourIndex].rushHourId;
      this.props.settingActions.editSurcharge(rushHour).then((data) => {
        if (data.ok && data.res) {
          this.state.formData.rushHours = data.res;
          this.setState({
            showDialog: false,
            formData: this.state.formData,
            dialogData: {},
          });
          this.context.notification(
            'success',
            I18n.t('generalSetting.Update_surcharge_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        }
      });
    }
  };

  handleAlertNotification = (params) => {
    if (Array.isArray(params)) {
      params.forEach((e) => {
        if (e.distance.from > e.distance.to) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_distance_range_valid', {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        } else if (!e.distance.to) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_distance_to')
          );
        } else if (!e.distance.surchargeValue) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_surchargeValue', {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        }

        if (e.errorMsg) {
          this.context.notification(
            'error',
            I18n.t(`generalSetting.${e.errorMsg}`, {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        }
      });
    }
  };

  closeDeleteServiceDialog = () => {
    this.setState({ showConfirmDelete: false });
  };

  confirmDelete = (e) => {
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.itemConfirm === 'fleetService') {
      this.props.settingActions
        .deleteFleetService(this.options)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.res) {
            this.closeDeleteServiceDialog();
            this.setState({
              formData: {
                ...this.state.formData,
                fleetServices: data.res,
              },
            });
            // this.context.notification('success', I18n.t('generalSetting.Delete_fleet_service_success'));
            this.context.notification(
              'success',
              'The fleet service has been deleted successfully'
            );
          } else if (data.error) {
            this.context.notification(
              'error',
              'Failed to create fleet service'
            );
          }
        });
    } else {
      this.props.settingActions.deleteSurcharge(this.options).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok && data.res) {
          this.closeDeleteServiceDialog();
          this.state.formData.rushHours = data.res;
          this.setState({ formData: this.state.formData });
          this.context.notification(
            'success',
            I18n.t('generalSetting.Delete_surcharge_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Delete_surcharge_fail')
          );
        }
      });
    }
  };

  /*
   ** Name: checkDayIsOverlaps
   ** Description: with the surcharge type is single/yearly, check startDate is overlaped, it's different with day of week
   ** Parameters: object(selected Surcharge)
   ** Return: boolean(overlapped or not)
   */
  checkDayIsOverlaps = (selectedSurcharge) => {
    const surchargeList = this.state.formData.rushHours.slice();
    let isValid = true;
    const startDateOfSelectedSurcharge = moment(selectedSurcharge.startDate);
    _.map(surchargeList, (surcharge) => {
      if (
        selectedSurcharge.repeat === surcharge.repeat &&
        (!selectedSurcharge.rushHourId ||
          selectedSurcharge.rushHourId !== surcharge.rushHourId)
      ) {
        const startDateOfSurcharge = moment(surcharge.startDate);
        if (selectedSurcharge.repeat === repeatType.SingleDay) {
          if (
            startDateOfSelectedSurcharge.isSame(surcharge.startDate, 'day') &&
            Validation.checkTimeOverlapInDate(
              selectedSurcharge.start,
              selectedSurcharge.end,
              surcharge.start,
              surcharge.end
            )
          ) {
            isValid = false;
            return false;
          }
        } else if (selectedSurcharge.repeat === repeatType.Yearly) {
          if (
            startDateOfSelectedSurcharge.date() ===
              startDateOfSurcharge.date() &&
            startDateOfSelectedSurcharge.month() ===
              startDateOfSurcharge.month() &&
            Validation.checkTimeOverlapInDate(
              selectedSurcharge.start,
              selectedSurcharge.end,
              surcharge.start,
              surcharge.end
            )
          ) {
            isValid = false;
            return false;
          }
        }
      }
    });
    return isValid;
  };

  rushHourAddItemCloseClick = () => {
    this.setState({
      showDialog: false,
      dialogData: {},
      isRushHourSubmitted: false,
      surchargeByCurrenciesInitial: [],
    });
  };

  handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
    const cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    switch (group) {
      case 'heavyTrafficByCurrencies': {
        this.state.formData.heavyTrafficByCurrencies =
          this.state.formData.heavyTrafficByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          }));
        this.state.formData.heavyTrafficByCurrencies =
          this.state.formData.heavyTrafficByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.formData.heavyTrafficByCurrencies.push(cur);
        }
        this.setState({ formData: this.state.formData });
        break;
      }
      default:
        break;
    }
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleSaveClick = () => {
    let { formData = {} } = this.state;
    let currencies =
      (this.props.auth &&
        this.props.auth.selectedFleet &&
        this.props.auth.selectedFleet.currencies) ||
      [];
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    const {
      fleet: { generalSetting },
    } = this.state;

    if (
      formData.techFeeActive &&
      (!formData.techFeeByCurrencies ||
        (formData.techFeeByCurrencies &&
          formData.techFeeByCurrencies.length === 0))
    ) {
      formData.techFeeByCurrencies = currencies.map((currency) => {
        return {
          currencyISO: currency.iso,
          commandCenter: 0,
          paxApp: 0,
          webBooking: 0,
          mDispatcher: 0,
          partner: 0,
          carHailing: 0,
          kiosk: 0,
          api: 0,
        };
      });
    }

    const data = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      cancellationPolicy: {
        onDemand: this.state.formData.cancellationPolicy.onDemand,
        duration: this.state.formData.cancellationPolicy.duration,
        times: this.state.formData.cancellationPolicy.times,
        inAdvance: this.state.formData.cancellationPolicy.inAdvance,
        durationIn: this.state.formData.cancellationPolicy.durationIn,
        durationInUnit: this.state.formData.cancellationPolicy.durationInUnit,
        intercity: this.state.formData.cancellationPolicy.intercity,
        intercityDuration:
          this.state.formData.cancellationPolicy.intercityDuration,
        intercityDurationUnit:
          this.state.formData.cancellationPolicy.intercityDurationUnit,
      },
      noShow: {
        isActive: this.state.formData.noShow.isActive,
        applyCountDown: this.state.formData.noShow.applyCountDown,
        countdownDuration: this.state.formData.noShow.applyCountDown
          ? this.state.formData.noShow.countdownDuration
          : 0,
        isLimitRadius: this.state.formData.noShow.isLimitRadius,
        limitRadiusValue: this.state.formData.noShow.limitRadiusValue,
      },
      airport: {
        isCustomized: this.state.formData.airport.isCustomized,
        fromAirportActive: this.state.formData.airport.fromAirportActive,
        fromAirport: this.state.formData.airport.fromAirport,
        toAirportActive: this.state.formData.airport.toAirportActive,
        toAirport: this.state.formData.airport.toAirport,
        payTo: this.state.formData.airport.payTo,
        fromAirportByCurrencies:
          this.state.formData.airport.fromAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
        toAirportByCurrencies:
          this.state.formData.airport.toAirportByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
      },
      meetDriver: {
        onCurb: this.state.formData.meetDriver.onCurb,
        meetDrv: this.state.formData.meetDriver.meetDrv,
        payTo: this.state.formData.meetDriver.payTo,
        meetDrvByCurrencies:
          this.state.formData.meetDriver.meetDrvByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
        onCurbByCurrencies:
          this.state.formData.meetDriver.onCurbByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
      },
      additionalServicesActive: this.state.formData.additionalServicesActive,
      fleetServiceActive: this.state.formData.fleetServiceActive,
      rushHourActive: this.state.formData.rushHourActive,
      otherFeeActive: this.state.formData.otherFeeActive,
      otherFee: {
        value: this.state.formData.otherFee.value,
        isEdit: this.state.formData.otherFee.isEdit,
        addNote: this.state.formData.otherFee.addNote,
        limitDriverInputActive:
          this.state.formData.otherFee.limitDriverInputActive,
        otherFeeDriverCanInput:
          this.state.formData.otherFee.otherFeeDriverCanInput ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
        valueByCurrencies:
          this.state.formData.otherFee.valueByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          })),
      },
      taxActive: this.state.formData.taxActive,
      tax: this.state.formData.tax,
      tipActive: this.state.formData.tipActive,
      tips: this.state.formData.tips,
      tipPaymentMethod: this.state.formData.tipPaymentMethod,
      tipDefaultValues: this.state.formData.tipDefaultValues,
      tollFeeActive: this.state.formData.tollFeeActive,
      tollFeePayTo: this.state.formData.tollFeePayTo,
      tollFeeDriverCanInput: this.state.formData.tollFeeDriverCanInput,
      tollFeeLimitDriverInputActive:
        this.state.formData.tollFeeLimitDriverInputActive,
      gasFeeActive: this.state.formData.gasFeeActive,
      gasFeePayTo: this.state.formData.gasFeePayTo,
      gasFeeDriverCanInput: this.state.formData.gasFeeDriverCanInput,
      gasFeeLimitDriverInputActive:
        this.state.formData.gasFeeLimitDriverInputActive,
      parkingFeeActive: this.state.formData.parkingFeeActive,
      parkingFeePayTo: this.state.formData.parkingFeePayTo,
      parkingFeeDriverCanInput: this.state.formData.parkingFeeDriverCanInput,
      parkingFeeLimitDriverInputActive:
        this.state.formData.parkingFeeLimitDriverInputActive,
      techFeeActive: this.state.formData.techFeeActive,
      techFeeByCurrencies: this.state.formData.techFeeByCurrencies,
      heavyTrafficActive: this.state.formData.heavyTrafficActive,
      heavyTrafficSurcharge: this.state.formData.heavyTrafficSurcharge,
      heavyTrafficByCurrencies:
        this.state.formData.heavyTrafficByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => ({
          value: 0,
          currencyISO: data.iso,
        })),
      driverAppAlert: this.state.fleet.driverAppAlert,
      driverAppAlertUnit: this.state.fleet.driverAppAlertUnit,
      paxAppAlert: this.state.fleet.paxAppAlert,
      paxAppAlertUnit: this.state.fleet.paxAppAlertUnit,
      intercityEmailConfirmBefore: this.state.fleet.intercityEmailConfirmBefore,
      intercityEmailConfirmBeforeUnit:
        this.state.fleet.intercityEmailConfirmBeforeUnit,
      generalSetting: {
        advanceInfoCorp: generalSetting.advanceInfoCorp,
        soundNotify: generalSetting.soundNotify,
        regAsCorpTraveler: generalSetting.regAsCorpTraveler,
        notAllowMakeBookingWithoutAssignFare:
          generalSetting.notAllowMakeBookingWithoutAssignFare,
        disableInvoicingReceipt: generalSetting.disableInvoicingReceipt,
        hideProviderFromCorp: !!generalSetting.hideProviderFromCorp,
        optionalEmailPaxApp: !!generalSetting.optionalEmailPaxApp,
        disableHourlyRate: !!generalSetting.disableHourlyRate,
        genderRequireInPaxApp: !!generalSetting.genderRequireInPaxApp,
        compulsoryAddressInPaxApp:
          generalSetting.compulsoryAddressInPaxApp || false,
        compulsoryIdNationalIcInPaxApp:
          generalSetting.compulsoryIdNationalIcInPaxApp || false,
        allowPassengerToUploadDocument:
          generalSetting.allowPassengerToUploadDocument || false,
        compulsoryNationalIcDocumentInPaxApp:
          (generalSetting.allowPassengerToUploadDocument &&
            generalSetting.compulsoryNationalIcDocumentInPaxApp) ||
          false,
        showTotalFareWithoutPromotionOnDriverApp:
          generalSetting.showTotalFareWithoutPromotionOnDriverApp || false,
        dontShowSecondStageImmediately:
          generalSetting.dontShowSecondStageImmediately || false,
        hideFareOnPaxAppForAllCorporateBookings:
          generalSetting.hideFareOnPaxAppForAllCorporateBookings || false,
        hidePaxNameAvatarOnDispatchScreen:
          generalSetting.hidePaxNameAvatarOnDispatchScreen || true,
        showFullCustomerNameOnDriverApp:
          generalSetting.showFullCustomerNameOnDriverApp || false,
        hideDriverLogoutBtn: generalSetting.hideDriverLogoutBtn || false,
        cancelTimeout: generalSetting.cancelTimeout || false,
        allowStripePaymentLink: generalSetting.allowStripePaymentLink || false,
      },
      statusOnMap: this.state.fleet.statusOnMap,
      SOSNumber: {
        defaultNumber: this.state.fleet.SOSNumber.defaultNumber,
        zoneNumber: this.state.fleet.SOSNumber.zoneNumber.map((zp) => ({
          zoneId: zp.zoneId,
          phone: zp.phone,
        })),
      },
      heatMapSetting: this.state.fleet.heatMapSetting,
    };
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions.saveGeneralSetting(data).then((res) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      this.setState({ isSubmited: false });

      if (res.ok && res.res) {
        this.context.notification(
          'success',
          I18n.t('generalSetting.Update_service_fee_success')
        );
        setTimeout(() => {
          window.location.reload();
        }, 1 * 1000);
      } else if (res.error) {
        this.context.notification(
          'error',
          I18n.t(`errors.${data.error.errorCode}`)
        );
      } else {
        this.context.notification(
          'error',
          I18n.t('generalSetting.Update_service_fee_fail')
        );
      }
    });
  };

  handleGeneralSettingChange = (e) => {
    const key = e.target.id;
    const value = e.target.checked;
    this.setState({
      fleet: {
        ...this.state.fleet,
        generalSetting: {
          ...this.state.fleet.generalSetting,
          [key]: value,
        },
      },
    });
  };

  handleEditServiceFees = (serviceFees, zoneEditing) => {
    this.setState({
      serviceFees,
      zoneEditing,
      isEditServiceFees: true,
      isChangeFormServiceFees: false,
    });
  };

  onChangeFormServiceFees = (submited) => {
    this.setState({ isChangeFormServiceFees: !submited });
  };

  confirmChangeApplyType = () => {
    const { fleetId } = this.props.auth.selectedFleet;
    const { applyType, formData } = this.state;
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions
      .updateServiceFeeApplyType({ fleetId, applyType })
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok && data.res) {
          formData.applyType = applyType;
          this.setState({
            formData,
            showConfirmChangeApplyType: false,
            isEditServiceFees: false,
            serviceFees: null,
            isChangeFormServiceFees: false,
            zoneEditing: null,
          });
          this.context.notification(
            'success',
            I18n.t('generalSetting.Update_service_fee_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Update_service_fee_fail')
          );
        }
      });
  };

  closeConfirmDialog = () => {
    this.setState({ showConfirmChangeApplyType: false });
  };

  render() {
    const editable = this.props.permissions && !this.props.permissions.actions;
    const { allowCongfigSettingForEachZone } = _.get(
      this.props.auth,
      'selectedFleet.generalSetting',
      {}
    );
    const fleetType = _.get(this.props.auth, 'selectedFleet.fleetType', '');
    const enableFleetService =
      _.get(this.props.auth, 'selectedFleet.additionalFees', '') == 1;
    const { dialogData } = this.state;
    const { service } = dialogData;
    const differentSurchargeForDifferentService = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.differentSurchargeForDifferentService',
      false
    );
    return this.state.formData ? (
      <div className="content custom-form overflow-auto general-setting pl0">
        {this.state.showConfirmDelete && !editable ? (
          <ModalConfirmDeleteSurcharge
            closeDeleteServiceDialog={this.closeDeleteServiceDialog}
            confirmDelete={this.confirmDelete}
            itemConfirm={this.state.itemConfirm}
          />
        ) : null}
        {this.state.showConfirmChangeApplyType && !editable ? (
          <ModalConfirmChangeApplyType
            closeConfirmDialog={this.closeConfirmDialog}
            confirmChangeType={this.confirmChangeApplyType}
            applyType={this.state.applyType}
          />
        ) : null}
        {this.state.showDialog && !editable ? (
          <ModalAddSurcharge
            auth={this.props.auth}
            rushHourAddItemSaveClick={this.rushHourAddItemSaveClick}
            rushHourAddItemCloseClick={this.rushHourAddItemCloseClick}
            dialogData={this.state.dialogData}
            surchargeByCurrenciesInitial={
              this.state.surchargeByCurrenciesInitial
            }
            handleAlertNotification={this.handleAlertNotification}
          />
        ) : (
          ''
        )}
        {this.state.showFleetServiceDialog ? (
          <ModalFleetService
            fleetServiceAddItemCloseClick={this.fleetServiceAddItemCloseClick}
            fleetServiceAddItemSaveClick={this.fleetServiceAddItemSaveClick}
            service={service}
            auth={this.props.auth}
            permissions={this.props.permissions}
            editable={editable}
            settingActions={this.props.settingActions}
          />
        ) : null}
        {allowCongfigSettingForEachZone && (
          <Row>
            <Col md={6} xs={12}>
              <FormGroup className="mt-md">
                <Form.Label>
                  <Translate value="generalSetting.apply" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-control form-custom"
                  value={this.state.formData.applyType}
                  onChange={this.handleApplyTypeChange}
                  disabled={editable}
                >
                  <option value={APPLY_SERVICE_TYPE.ALL}>
                    {I18n.t('generalSetting.apply_all')}
                  </option>
                  <option value={APPLY_SERVICE_TYPE.CUSTOM_ZONE}>
                    {I18n.t('generalSetting.apply_custom_zone')}
                  </option>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6} xs={12}>
            {this.state.formData.applyType === APPLY_SERVICE_TYPE.CUSTOM_ZONE &&
            allowCongfigSettingForEachZone ? (
              <CustomZoneList
                onEditServiceFees={this.handleEditServiceFees}
                serviceFees={this.state.serviceFees}
                isChangeFormServiceFees={this.state.isChangeFormServiceFees}
              />
            ) : null}
            {this.state.formData.applyType === APPLY_SERVICE_TYPE.ALL ||
            !allowCongfigSettingForEachZone ? (
              <Fragment>
                <div className="group-general">
                  <CcCheckbox
                    disabled={editable}
                    checked={
                      this.state.formData
                        ? this.state.formData.rushHourActive
                        : false
                    }
                    onChange={this.handleRushHourCheckChange}
                    text={I18n.t('generalSetting.RUSH_HOUR')}
                    labelClassName="title"
                    className="form-group-title no-marginBottom"
                  />
                  {this.state.formData && this.state.formData.rushHourActive ? (
                    <Row className="sub-controls">
                      <Col md={12}>
                        <div
                          className="additional-service-table-container"
                          id="table-surchage-wrapper"
                        >
                          <div id="table-surchage-scroll">
                            <table className="table table-no-pagination">
                              <tbody>
                                {this.state.formData.rushHours.map(
                                  (rh, index) => (
                                    <tr key={index}>
                                      <td>
                                        <p>{rh.name}</p>
                                      </td>
                                      <td>
                                        <p>
                                          {rh.repeat ===
                                            repeatType.DayOfWeek && (
                                            <Translate value="generalSetting.DayOfWeek" />
                                          )}
                                          {rh.repeat ===
                                            repeatType.SingleDay && (
                                            <Translate value="generalSetting.SingleDay" />
                                          )}
                                          {rh.repeat === repeatType.Yearly && (
                                            <Translate value="generalSetting.Yearly" />
                                          )}
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {rh.start} -{rh.end}{' '}
                                        </p>
                                        <p>
                                          {rh.repeat === repeatType.DayOfWeek
                                            ? rh.date
                                                .map((d) =>
                                                  I18n.t(`generalSetting.${d}`)
                                                )
                                                .join(', ')
                                            : moment(rh.startDate).format(
                                                'MM/DD/YYYY'
                                              )}
                                        </p>
                                      </td>
                                      <td>
                                        <p>{rh.timeCal} mins</p>
                                        {rh.distanceDiversity ? (
                                          I18n.t(
                                            'generalSetting.distance_diversity'
                                          )
                                        ) : rh.surchargeType ===
                                          surchargeType.AMOUNT ? (
                                          <p>
                                            {rh.surchargeByCurrencies &&
                                            !differentSurchargeForDifferentService
                                              ? rh.surchargeByCurrencies
                                                  .map(
                                                    (d) =>
                                                      `${d.currencyISO} ${d.value}`
                                                  )
                                                  .join(', ')
                                              : ''}
                                          </p>
                                        ) : (
                                          <p>
                                            {rh.surchargeByCurrencies[0] &&
                                            !differentSurchargeForDifferentService
                                              ? `${rh.surchargeByCurrencies[0].value} %`
                                              : ''}
                                          </p>
                                        )}
                                      </td>
                                      <td className="actions">
                                        {!this.props.permissions ||
                                        this.props.permissions.actions ? (
                                          <div>
                                            <a
                                              onClick={() => {
                                                this.rushHourRemoveItemClick(
                                                  rh,
                                                  index
                                                );
                                              }}
                                            >
                                              <i className="fa fa-trash fa-2x" />
                                            </a>
                                            <a
                                              onClick={() => {
                                                this.rushHourEditItemClick(
                                                  rh,
                                                  index
                                                );
                                              }}
                                            >
                                              <i className="fa fa-edit fa-2x" />
                                            </a>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {(!this.props.permissions ||
                          this.props.permissions.actions) && (
                          <ButtonToolbar className="text-center mv-md">
                            <Button
                              className="btn-save"
                              onClick={this.rushHourAddItemClick}
                            >
                              <Translate value="generalSetting.Add" />
                            </Button>
                          </ButtonToolbar>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </div>
                <div className="group-general">
                  <CcCheckbox
                    checked={
                      this.state.formData
                        ? this.state.formData.heavyTrafficActive
                        : false
                    }
                    onChange={this.handleHeavyTrafficCheckChange}
                    text={I18n.t('generalSetting.HEAVY_TRAFFIC')}
                    labelClassName="title"
                    className="form-group-title"
                    disabled={editable}
                  />
                  {this.state.formData &&
                  this.state.formData.heavyTrafficActive ? (
                    <FormGroup
                      className={
                        `sub-controls ${
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.heavyTrafficByCurrencies === false
                          ? 'error'
                          : null
                      }`}
                    >
                      <Form.Label>
                        <Translate value="generalSetting.Heavy_traffic_surcharge" />
                      </Form.Label>
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <Tabs
                          id="currencies-tabs-Ondemand"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                          >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            const currentValue = this.state.formData
                              .heavyTrafficByCurrencies
                              ? this.state.formData.heavyTrafficByCurrencies.filter(
                                  (d) => d.currencyISO == c.iso
                                )[0]
                              : null;
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                key={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className="qup-input-group">
                                  <InputGroup
                                    className={`single-addon-left ${
                                      editable ? 'disabled' : ''
                                    }`}
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className="form-custom"
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'heavyTrafficByCurrencies',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'heavyTrafficByCurrencies',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'heavyTrafficByCurrencies',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={editable}
                                    />
                                    {' '}
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>
                      ) : (
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={`single-addon-left ${
                              editable ? 'disabled' : ''
                            }`}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className="form-custom"
                              placeholder={I18n.t(
                                'generalSetting.Heavy_traffic_surcharge'
                              )}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'heavyTrafficByCurrencies',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'heavyTrafficByCurrencies',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'heavyTrafficByCurrencies',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.formData.heavyTrafficByCurrencies &&
                                this.state.formData.heavyTrafficByCurrencies
                                  .length > 0
                                  ? this.state.formData.heavyTrafficByCurrencies.filter(
                                      (d) =>
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                    )[0]
                                    ? this.state.formData.heavyTrafficByCurrencies.filter(
                                        (d) =>
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              disabled={editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      )}
                      <Validator
                        id="heavyTrafficByCurrencies"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          valid={
                            this.state.formData.heavyTrafficByCurrencies.filter(
                              (d) =>
                                !Validation.isStringEmpty(d.value) &&
                                isNaN(d.value)
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          valid={
                            this.state.formData.heavyTrafficByCurrencies.filter(
                              (d) => !Validation.isGreaterOrEqual(d.value, 0)
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                          hide={!this.state.isSubmited}
                        />
                      </Validator>
                      <WarningCase
                        validator={this.state.valid.heavyTrafficByCurrencies}
                        message={I18n.t('message.warningInputChangeOver')}
                        initialValue={this.state.formData.heavyTrafficByCurrencies.map(
                          (ob) => ob.value
                        )}
                        onChangeValue={this.state.formData.heavyTrafficByCurrencies.map(
                          (ob) => ob.value
                        )}
                        range={20}
                        typePercent={false}
                      />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  <TollFee
                    formData={this.state.formData}
                    editable={editable}
                    handleUpdateFormData={this.handleUpdateFormData}
                    auth={this.props.auth}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    ValidatorCallback={this.ValidatorCallback}
                  />

                  <FormFee
                    keyFee={'parkingFee'}
                    formData={this.state.formData}
                    editable={editable}
                    handleUpdateFormData={this.handleUpdateFormData}
                    auth={this.props.auth}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    ValidatorCallback={this.ValidatorCallback}
                  />

                  <FormFee
                    keyFee={'gasFee'}
                    formData={this.state.formData}
                    editable={editable}
                    handleUpdateFormData={this.handleUpdateFormData}
                    auth={this.props.auth}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    ValidatorCallback={this.ValidatorCallback}
                  />

                  <CcCheckbox
                    disabled={editable}
                    checked={
                      this.state.formData
                        ? this.state.formData.taxActive
                        : false
                    }
                    onChange={this.handleTaxCheckChange}
                    text={I18n.t('generalSetting.TAX')}
                    labelClassName="title"
                    className="form-group-title"
                  />
                  {this.state.formData && this.state.formData.taxActive ? (
                    <FormGroup
                      className={`sub-controls ${
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.tax === false
                          ? 'error'
                          : null
                      }`}
                    >
                      <Form.Label>
                        <Translate value="generalSetting.Tax_surcharge" />
                        <span className="require">*</span>
                      </Form.Label>
                      <FormGroup className="qup-input-group">
                        <InputGroup
                          className={`single-addon-right ${
                            editable ? 'disabled' : ''
                          }`}
                        >
                          <FormControl
                            type="text"
                            className="form-custom"
                            onChange={this.handleTaxSurchargeChange}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(
                                e,
                                this.handleTaxSurchargeChange
                              );
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(
                                e,
                                this.handleTaxSurchargeChange
                              );
                            }}
                            value={
                              this.state.formData ? this.state.formData.tax : ''
                            }
                            placeholder={I18n.t('generalSetting.Tax_surcharge')}
                            disabled={editable}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>%</InputGroup.Text>
                          </InputGroup.Append>{' '}
                        </InputGroup>
                      </FormGroup>
                      <Validator id="tax" callback={this.ValidatorCallback}>
                        <ValidCase
                          valid={
                            !Validation.isStringEmpty(this.state.formData.tax)
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          valid={Validation.isNumber(this.state.formData.tax)}
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          valid={Validation.isGreaterOrEqual(
                            this.state.formData.tax,
                            0
                          )}
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                          hide={!this.state.isSubmited}
                        />
                      </Validator>
                      <WarningCase
                        validator={this.state.valid.tax}
                        message={I18n.t('message.warningInputChangeOver')}
                        initialValue={this.state.formData.tax}
                        onChangeValue={this.state.formData.tax}
                        range={20}
                        typePercent={true}
                      />
                    </FormGroup>
                  ) : (
                    ''
                  )}
                  <OtherFee
                    formData={this.state.formData}
                    editable={editable}
                    handleUpdateFormData={this.handleUpdateFormData}
                    auth={this.props.auth}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    ValidatorCallback={this.ValidatorCallback}
                  />
                  {fleetType === 'Limo' && (
                    <TechFee
                      formData={this.state.formData}
                      editable={editable}
                      handleUpdateFormData={this.handleUpdateFormData}
                      auth={this.props.auth}
                      isSubmited={this.state.isSubmited}
                      valid={this.state.valid}
                      ValidatorCallback={this.ValidatorCallback}
                    />
                  )}
                  <FleetService
                    editable={editable}
                    enableFleetService={enableFleetService}
                    formData={this.state.formData}
                    permissions={this.props.permissions}
                    onFleetServiceChange={this.onFleetServiceChange}
                    fleetServiceRemoveItemClick={
                      this.fleetServiceRemoveItemClick
                    }
                    fleetServiceEditItemClick={this.fleetServiceEditItemClick}
                    fleetServiceAddItemClick={this.fleetServiceAddItemClick}
                  />
                </div>
                <ButtonToolbar className="text-center mv-md">
                  {!this.props.permissions || this.props.permissions.actions ? (
                    <Button className="btn-save" onClick={this.handleSaveClick}>
                      <Translate value="generalSetting.Save" />
                    </Button>
                  ) : (
                    ''
                  )}
                </ButtonToolbar>
              </Fragment>
            ) : null}
          </Col>
          <Col md={6} xs={12}>
            {this.state.isEditServiceFees && (
              <ServiceFeesForm
                serviceFees={this.state.serviceFees}
                enableFleetService={enableFleetService}
                fleet={this.state.fleet}
                fleetType={fleetType}
                zone={this.state.zoneEditing}
                onChangeFormServiceFees={this.onChangeFormServiceFees}
                disabled={editable}
              />
            )}
          </Col>
        </Row>
      </div>
    ) : null;
  }
}
ServiceFee.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceFee);
