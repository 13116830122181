import 'isomorphic-fetch';
import _ from 'lodash';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import FileSaver from 'file-saver';
import { logout } from '../actions/auth';
import { loadingIncrease, loadingDecrease } from '../actions/loadingBarActions';

const uuidv4 = require('uuid/v4');

export function checkStatus(response, config = {}) {
  if (!response.ok && response.status != 400) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  if (config.fileName) {
    return response.blob();
  }
  return response;
}

export function parseJSON(response) {
  return response.json();
}
/**
 * A utility to call a restful service.
 *
 * @param url The restful service end point.
 * @param config The config object of the call. Can be null.
 * @param request The request action.
 * @param onRequestSuccess The callback function to create request success action.
 *                 The function expects response json payload as its argument.
 * @param onRequestFailure The callback function to create request failure action.
 *                 The function expects error as its argument.
 */
export function callApi(
  url,
  config,
  request,
  onRequestSuccess,
  onRequestFailure,
  showLoading,
  removeCurrentFleetId
) {
  const user = loadUserProfile();
  const fleet = loadCurrentFleet();
  const myHeaders = new Headers();
  try {
    
    if (config && config.body && fleet && !config.isFormData) {
      try {
        const body = JSON.parse(config.body);
        if(!removeCurrentFleetId) {
          body._currentFleetId = fleet.fleetId;
        }
        config.body = JSON.stringify(body);
      } catch (e) {
        console.log(e)
      }
    }
    myHeaders.append('x-request-id', uuidv4({ msecs: new Date().getTime() }));
    if (config.isFormData) {
      myHeaders.append('Accept', 'application/json');
    } else {
      myHeaders.append('Accept', 'application/json');
      myHeaders.append('Content-Type', 'application/json');
    }
    if (user) {
      myHeaders.append('Authorization', user.token);
    }
    config.headers = myHeaders;
    return (dispatch) => {
      if (request) {
        dispatch(request);
      }
      if (showLoading) {
        dispatch(loadingIncrease());
      }
      return fetch(url, config)
        .then((response) => checkStatus(response, config))
        .then((response) => {
          if (config.fileName) {
            return FileSaver.saveAs(response, config.fileName);
          }
          return parseJSON(response);
        })
        .then((json) => {
          if (json.error) {
            if (onRequestFailure) {
              dispatch(onRequestFailure(json.error));
            }
          } else {
            json.ok = true;
            if (onRequestSuccess) {
              dispatch(onRequestSuccess(json));
            }
          }
          if (showLoading) {
            dispatch(loadingDecrease());
          }
          return json;
        })
        .catch((error) => {
          const response = error.response;
          if (response === undefined) {
            if (onRequestFailure) {
              dispatch(onRequestFailure(error));
            }
          } else {
            error.status = response.status;
            error.statusText = response.statusText;
            return response.json().then((json) => {
              error.message = json;
              if (error.status == 401) {
                dispatch(logout());
                NotificationManager.error(
                  'Your session was ended or your have logged in on other device!',
                  '',
                  5000,
                  null,
                  true
                );
                setTimeout(() => {
                  window.location.reload();
                }, 10000);
              } else if (error.status == 403) {
                if (error.message.errorCode === 400003) {
                  // NotificationManager.error('Permission denied!', '', 5000, null, true);
                } else {
                  NotificationManager.error(
                    'Your fleet has been deactivated. Please contact our administrator!',
                    '',
                    5000,
                    null,
                    true
                  );
                }
              }
              if (onRequestFailure) {
                dispatch(onRequestFailure(error));
              }
              if (showLoading) {
                dispatch(loadingDecrease());
              }
              return error;
            });
          }
          error.ok = false;
          if (showLoading) {
            dispatch(loadingDecrease());
          }
          return error;
        });
    };
  } catch (error) {
    console.log('sdfsdf')
  }
}
export function callApiBase(
  url,
  config,
  request,
  onRequestSuccess,
  onRequestFailure,
  showLoading
) {
  return (dispatch) => {
    if (request) {
      dispatch(request);
    }
    if (showLoading) {
      dispatch(loadingIncrease());
    }
    return fetch(url, config)
      .then((response) => checkStatus(response, config))
      .then((response) => parseJSON(response))
      .then((json) => {
        if (json.error) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(json.error));
          }
        } else {
          json.ok = true;
          if (onRequestSuccess) {
            dispatch(onRequestSuccess(json));
          }
        }
        if (showLoading) {
          dispatch(loadingDecrease());
        }
        return json;
      })
      .catch((error) => {
        const response = error.response;
        if (response === undefined) {
          if (onRequestFailure) {
            dispatch(onRequestFailure(error));
          }
        } else {
          error.status = response.status;
          error.statusText = response.statusText;
          response.json().then((json) => {
            error.message = json;
            if (onRequestFailure) {
              dispatch(onRequestFailure(error));
            }
          });
        }
        error.ok = false;
        if (showLoading) {
          dispatch(loadingDecrease());
        }
        return error;
      });
  };
}

export function callApiWithoutDispatch(url, config = {}, request) {
  const user = loadUserProfile();
  const myHeaders = new Headers();
  myHeaders.append('x-request-id', uuidv4({ msecs: new Date().getTime() }));
  if (config.isFormData) {
    myHeaders.append('Accept', 'application/json');
  } else {
    myHeaders.append('Accept', 'application/json');
    myHeaders.append('Content-Type', 'application/json');
  }
  if (user) {
    myHeaders.append('Authorization', user.token);
  }
  config.headers = myHeaders;
  return fetch(url, config)
    .then((response) => checkStatus(response, config))
    .then((response) => parseJSON(response))
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

export function saveUserToLocal(data) {
  data = JSON.stringify(data);
  localStorage.setItem('user', data);
}
export function loadCurrentFleet() {
  let fleet = localStorage.getItem('fleet');
  if (!fleet) return null;
  fleet = JSON.parse(fleet);
  return fleet;
}
export function loadUserProfile() {
  let user = localStorage.getItem('user');
  if (!user) return null;
  user = JSON.parse(user);
  return user;
}
export function removeUserProfile() {
  localStorage.removeItem('user');
  localStorage.removeItem('fleet');
}

export function UrlBuilder(url, params, isPathId = false) {
  if(isPathId) return `${url}/${params}`
  const encodeURL = new URL(url);

  if (params != null)
    Object.keys(params).forEach((key) =>
      encodeURL.searchParams.append(key, params[key])
    );

  return encodeURL;
  // let esc = encodeURIComponent;
  // let query = Object.keys(options)
  //   .map(k => esc(k) + "=" + esc(options[k]))
  //   .join("&");
  // url += "?" + query;
  // return url;
}

export function UrlBuilderWithParams(url, params) {
  let newUrl = url;
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      let search = `{${key}}`;
      newUrl = newUrl.replace(new RegExp(search, 'g'), params[key]);
    }
  }

  return newUrl;
}

export function saveUserVoip(data) {
  if (data != undefined && data.voipToken != undefined) {
    localStorage.setItem('voipToken', data.voipToken);
    localStorage.setItem('qd', data.qd);
    localStorage.setItem('webvoipdomain', data.webvoipdomain);
  } else {
    localStorage.setItem('voipToken', '');
    localStorage.setItem('qd', '');
    localStorage.setItem('webvoipdomain', '');
  }
}
