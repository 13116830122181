import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingBarActions from './../../actions/loadingBarActions';
import { Translate, I18n } from 'react-redux-i18n';
import {
  getCarTypeCorporate,
  updatePricingCorporate,
} from '../../actions/corporateAction';
import { Validator, ValidCase } from '../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../utils/commonFunctions';
import {
  FormGroup,
  InputGroup,
  FormControl,
  ButtonToolbar,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import AssignRate from './AssignRate';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Radio from '../../components/radioButton/radio';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import { CorpPricingColumns } from './tableHeaderColums';

class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmitted: false,
      pricing: {},
      rowHeight: 50,
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handlePricingDefaultChose = this.handlePricingDefaultChose.bind(this);
    this.handlePricingDiscountChose =
      this.handlePricingDiscountChose.bind(this);
    this.handlePricingDifferentRateChose =
      this.handlePricingDifferentRateChose.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleSavePricingClick = this.handleSavePricingClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleAssignRateClick = this.handleAssignRateClick.bind(this);
    this.handleAssignRateSuccess = this.handleAssignRateSuccess.bind(this);
    this.handleCancelAssignRateClick =
      this.handleCancelAssignRateClick.bind(this);
  }
  componentDidMount() {
    if (this.props.pricing) {
      this.setState({ pricing: this.props.pricing });
    }
    this.props
      .getCarTypeCorporate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        corpId: this.props.corporateId,
      })
      .then((data) => {
        if (data.ok) {
          this.setState({ carType: data.res });
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pricing) {
      this.setState({
        pricing: nextProps.pricing,
        shouldRenderTable:
          nextProps.contentHeight &&
          nextProps.contentWidth &&
          nextProps.contentHeight !== this.props.contentHeight &&
          nextProps.contentWidth !== this.props.contentWidth,
      });
    }
  }

  handleValueChange(e) {
    this.setState({
      pricing: { ...this.state.pricing, value: e.target.value },
    });
  }

  handleMarkupPriceChange = (e) => {
    this.setState({
      pricing: { ...this.state.pricing, markUpValue: e.target.value },
    });
  };

  handlePricingDefaultChose(e) {
    this.setState({
      pricing: {
        defaultPricing: true,
        discountByPercentage: false,
        differentRate: false,
        markUpByPercentage: false,
        value: this.state.pricing.value,
      },
    });
  }

  handleMarkUpByPercentageChose = (e) => {
    this.setState({
      pricing: {
        defaultPricing: false,
        discountByPercentage: false,
        differentRate: false,
        markUpByPercentage: true,
        value: this.state.pricing.value,
      },
    });
  };

  handlePricingDiscountChose(e) {
    this.setState({
      pricing: {
        defaultPricing: false,
        discountByPercentage: true,
        differentRate: false,
        markUpByPercentage: false,
        value: this.state.pricing.value,
      },
    });
  }

  handlePricingDifferentRateChose(e) {
    this.setState({
      pricing: {
        defaultPricing: false,
        discountByPercentage: false,
        differentRate: true,
        markUpByPercentage: false,
        value: this.state.pricing.value,
      },
    });
  }

  handleSavePricingClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .updatePricingCorporate({
        _id: this.props.corporateId,
        fleetId: this.props.auth.selectedFleet.fleetId,
        defaultPricing: this.state.pricing.defaultPricing,
        discountByPercentage: this.state.pricing.discountByPercentage,
        differentRate: this.state.pricing.differentRate,
        markUpByPercentage: this.state.pricing.markUpByPercentage
          ? this.state.pricing.markUpByPercentage
          : false,
        markUpValue: this.state.pricing.markUpValue || 0,
        value: this.state.pricing.value,
      })
      .then((data) => {
        this.setState({ isSubmitted: false });
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          this.context.notification(
            'success',
            I18n.t('corporate.INFO_UPDATE_PRICING_SUCCESS')
          );
          this.setState({
            pricing: data.res,
          });
          this.props.handleReloadData();
        } else {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        }
      });
  }

  handleCancelClick() {
    this.props.router.push({
      pathname: '/corporate',
    });
  }

  handleAssignRateSuccess(carType) {
    let newCarTypes = this.state.carType.map(function (data) {
      if (data._id === carType._id) {
        return carType;
      }
      return data;
    });
    this.setState({ carType: newCarTypes, assignRateDetail: null });
  }

  handleCancelAssignRateClick() {
    this.setState({ assignRateDetail: null });
  }

  handleAssignRateClick(detailItem) {
    this.setState({ assignRateDetail: detailItem });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], CorpPricingColumns);
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'regularFare':
          col.customCell = (data) => {
            let regularFare = '';
            if (data.corpRates.length > 0) {
              let regularRates = data.corpRates.filter((rate) => {
                if (rate.rateType === 'Regular') {
                  return rate;
                }
              });
              regularRates.forEach((fare, index) => {
                if (fare.zoneId) {
                  regularFare +=
                    fare.zoneId.zoneName + ' - ' + fare.rateId.name;
                  if (index !== regularRates.length - 1) {
                    regularFare += ', ';
                  }
                }
              });
            }
            return (
              <OverlayTrigger
                placement="top"
                delay={300}
                overlay={
                  <Tooltip id="tooltip">
                    <span>{regularFare}</span>
                  </Tooltip>
                }
              >
                <div className="cursor-pointer text-ellipsis">
                  {regularFare}
                </div>
              </OverlayTrigger>
            );
          };
          break;

        case 'flatFare':
          col.customCell = (data) => {
            let flatFare = '';
            if (data.corpRates.length > 0) {
              let flatRates = data.corpRates.filter((rate) => {
                if (rate.rateType === 'Flat') {
                  return rate;
                }
              });
              if (flatRates[0] && flatRates[0].rateId) {
                flatFare = flatRates[0].rateId.name;
              }
            }
            return (
              <OverlayTrigger
                placement="top"
                delay={300}
                overlay={
                  <Tooltip id="tooltip">
                    <span>{flatFare}</span>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">{flatFare}</span>
              </OverlayTrigger>
            );
          };
          break;

        case 'intercityFare':
          col.customCell = (data) => {
            let intercityFare = '';
            if (data.corpRates.length > 0) {
              console.log('intercityRates', data.corpRates)
              let intercityRates = data.corpRates.filter((rate) => {
                if (rate.rateType === 'Intercity') {
                  return rate;
                }
              });
              console.log('intercityRates', intercityRates)
              if (intercityRates[0] && intercityRates[0].rateId) {
                console.log('intercityRates', intercityRates)
                intercityFare = intercityRates[0].rateId.name;
              }
            }
            return (
              <OverlayTrigger
                placement="top"
                delay={300}
                overlay={
                  <Tooltip id="tooltip">
                    <span>{intercityFare}</span>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">{intercityFare}</span>
              </OverlayTrigger>
            );
          };
          break;

        case 'hourlyFare':
          col.customCell = (data) => {
            let hourlyFare = '';
            if (data.corpRates.length > 0) {
              let hourlyRates = data.corpRates.filter((rate) => {
                if (rate.rateType === 'Hourly') {
                  return rate;
                }
              });
              hourlyRates.forEach((fare, index) => {
                if (fare.zoneId) {
                  hourlyFare += fare.zoneId.zoneName + ' - ' + fare.rateId.name;
                  if (index !== hourlyRates.length - 1) {
                    hourlyFare += ', ';
                  }
                }
              });
            }
            return (
              <OverlayTrigger
                placement="top"
                delay={300}
                overlay={
                  <Tooltip id="tooltip">
                    <span>{hourlyFare}</span>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">{hourlyFare}</span>
              </OverlayTrigger>
            );
          };
          break;
        case 'deliveryFare':
          col.customCell = (data) => {
            let deliveryFare = '';
            if (data.corpRates.length > 0) {
              let deliveryRates = data.corpRates.filter((rate) => {
                if (rate.rateType === 'Delivery') {
                  return rate;
                }
              });
              deliveryRates.forEach((fare, index) => {
                if (fare.zoneId) {
                  deliveryFare +=
                    fare.zoneId.zoneName + ' - ' + fare.rateId.name;
                  if (index !== deliveryRates.length - 1) {
                    deliveryFare += ', ';
                  }
                }
              });
            }
            return (
              <OverlayTrigger
                placement="top"
                delay={300}
                overlay={
                  <Tooltip id="tooltip">
                    <span>{deliveryFare}</span>
                  </Tooltip>
                }
              >
                <span className="cursor-pointer">{deliveryFare}</span>
              </OverlayTrigger>
            );
          };
          break;
        case 'actions':
          if (hasPermission)
            col.customCell = (data) => (
              <a
                onClick={() => {
                  this.handleAssignRateClick(data);
                }}
                href="javascript:void(0)"
                className="text-active"
              >
                <Translate value="corporate.Assign_rate" />
              </a>
            );
          else tableColumns.splice(index, 1);
          break;
        default:
          break;
      }
    });
    return tableColumns;
  };

  getTableHeight = () => {
    if (!this.state.carType) {
      return 50;
    } else {
      let radioGroupMarginBottom = 15,
        contentVerticalPadding = 40,
        tableMarginBottom = 15,
        bottomButtonHeight = 40,
        contentHeight =
          this.props.getContentHeight() || this.props.contentHeight,
        radioGroupHeight =
          this.radioSelectGroup && this.radioSelectGroup.clientHeight
            ? this.radioSelectGroup.clientHeight
            : 100;
      let expectHeight = contentHeight
        ? contentHeight -
          (radioGroupHeight +
            radioGroupMarginBottom +
            contentVerticalPadding +
            tableMarginBottom +
            bottomButtonHeight)
        : 0;
      // 50: table header
      // 20: table horizontal scroll
      let realTableHeight =
        50 + 20 + this.state.carType.length * this.state.rowHeight;
      if (expectHeight && realTableHeight < expectHeight) {
        return realTableHeight;
      } else {
        if (window.innerHeight < 850 && expectHeight < 350) return 350;
        return expectHeight;
      }
    }
  };

  render() {
    if (this.state) {
      const bodyData =
        this.props.contentHeight < 1 || this.props.contentWidth < 1
          ? []
          : this.state.carType
          ? this.state.carType
          : [];
      const hasPermission =
        this.props.permissions && this.props.permissions.actions;
      return (
        <div className="corp-pricing-wrapper content">
          <div
            className="radio-select-group"
            ref={(node) => (this.radioSelectGroup = node)}
          >
            <FormGroup>
              <Radio
                name="radioGroup"
                checked={this.state.pricing.defaultPricing}
                onChange={this.handlePricingDefaultChose}
                text={I18n.t('corporate.Pricing_Default')}
                disabled={!hasPermission}
              />
            </FormGroup>
            <FormGroup>
              <Radio
                name="radioGroup"
                checked={this.state.pricing.discountByPercentage}
                onChange={this.handlePricingDiscountChose}
                text={I18n.t('corporate.Pricing_Discount')}
                disabled={!hasPermission}
              />
            </FormGroup>
            {this.state.pricing.discountByPercentage ? (
              <FormGroup
                className={`qup-input-group single ${
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.value === false
                    ? 'error'
                    : null
                }`}
              >
                <InputGroup
                  style={{ maxWidth: '300px' }}
                  className="single-addon-right"
                >
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={this.handleValueChange}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(e, (e) => {
                        this.handleValueChange(e);
                      });
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(e, (e) => {
                        this.handleValueChange(e);
                      });
                    }}
                    value={this.state.pricing.value}
                    disabled={!hasPermission}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Validator id="value" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={Validation.isNumber(this.state.pricing.value)}
                    message={I18n.t('corporate.ERROR_INPUT_VALID_NUMBER')}
                  />
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={Validation.isInRange(
                      this.state.pricing.value,
                      0,
                      100
                    )}
                    message={I18n.t(
                      'corporate.ERROR_INPUT_VALID_RANGE_AMOUNT'
                    ).format(0, 100)}
                  />
                </Validator>
              </FormGroup>
            ) : null}

            <FormGroup>
              <Radio
                name="radioGroup"
                checked={this.state.pricing.markUpByPercentage}
                onChange={this.handleMarkUpByPercentageChose}
                text={I18n.t('corporate.Markup_By_Percent')}
                disabled={!hasPermission}
              />
            </FormGroup>
            {this.state.pricing.markUpByPercentage ? (
              <FormGroup
                className={`qup-input-group single ${
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.markUpValue === false
                    ? 'error'
                    : null
                }`}
              >
                <InputGroup
                  style={{ maxWidth: '300px' }}
                  className="single-addon-right"
                >
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={this.handleMarkupPriceChange}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(e, (e) => {
                        this.handleMarkupPriceChange(e);
                      });
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(e, (e) => {
                        this.handleMarkupPriceChange(e);
                      });
                    }}
                    value={this.state.pricing.markUpValue || 0}
                    disabled={!hasPermission}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Validator id="markUpValue" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={Validation.isNumber(this.state.pricing.markUpValue)}
                    message={I18n.t('corporate.ERROR_INPUT_VALID_NUMBER')}
                  />
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={Validation.isInRange(
                      this.state.pricing.markUpValue,
                      0,
                      100
                    )}
                    message={I18n.t(
                      'corporate.ERROR_INPUT_VALID_RANGE_AMOUNT'
                    ).format(0, 100)}
                  />
                </Validator>
              </FormGroup>
            ) : null}
            <FormGroup>
              <Radio
                name="radioGroup"
                checked={this.state.pricing.differentRate}
                onChange={this.handlePricingDifferentRateChose}
                text={I18n.t('corporate.Pricing_Add_different_rates')}
                disabled={!hasPermission}
              />
            </FormGroup>
          </div>
          {this.state.pricing.differentRate &&
          (!this.state.assignRateDetail || !hasPermission) ? (
            <div className="gridViewTable mb-lg">
              <StickyTable
                columns={this.getTableColumns()}
                bodyData={bodyData}
                rowHeight={this.state.rowHeight}
                getTableHeight={this.getTableHeight}
                getParentWidthManual={() => this.props.contentWidth - 40}
                noPagination={true}
                shouldRender={this.state.shouldRenderTable}
                descreaseWidth={window.innerHeight < 850 ? 10 : 0}
              />
            </div>
          ) : null}
          {this.state.pricing.differentRate &&
          this.state.assignRateDetail &&
          hasPermission ? (
            <AssignRate
              data={this.state.assignRateDetail}
              handleAssignRateSuccess={this.handleAssignRateSuccess}
              handleCancelAssignRateClick={this.handleCancelAssignRateClick}
              corporateId={this.props.corporateId}
            />
          ) : (
            <ButtonToolbar className="text-center">
              {hasPermission ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.handleSavePricingClick}
                >
                  <Translate value="corporate.Save" />
                </Button>
              ) : null}
              <Button className="btn-cancel" onClick={this.handleCancelClick}>
                <Translate value="corporate.Cancel" />
              </Button>
            </ButtonToolbar>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}
Pricing.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getCarTypeCorporate: (options) => {
      return dispatch(getCarTypeCorporate(options));
    },
    updatePricingCorporate: (options) => {
      return dispatch(updatePricingCorporate(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pricing);
