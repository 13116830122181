import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import {
  textboxNumberHelper,
  removeDuplicateArray,
} from '../../../../utils/commonFunctions';

function ConvertTopup({
  topupAmount,
  selectedFleet,
  hasPermission,
  paymentMethod,
  handleChangeData,
}) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [topupData, setTopupData] = useState([]);

  useEffect(() => {
    let paymentMethods = paymentMethod.passengerWallet || [];
    paymentMethods = paymentMethods.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setPaymentMethods(paymentMethods);
  }, [paymentMethod]);

  useEffect(() => {
    if (paymentMethods.length > 0) setTopupData(initTopupAmountPayment());
  }, [paymentMethods]);

  useEffect(() => {
    if (topupAmount.length > 0) {
      let checkData = removeDuplicateArray(topupAmount, 'type');
      const map = topupData.map((ele) => {
        let data = checkData.find((item) => item.type === ele.type);
        if (data) {
          ele.value = data.value;
        }
        return ele;
      });
      setTopupData(map);
    }
  }, [topupAmount]);

  const initTopupAmountPayment = () => {
    let topupData = [];
    paymentMethods.forEach((element) => {
      topupData.push({
        type: element.type,
        name: element.name,
        value: 0,
      });
    });
    return topupData;
  };

  const handleChangeValue = (type, e) => {
    let data = topupData || [];
    data = data.map((item) => {
      if (item.type === type) {
        item.value = e.target.value;
      }
      return item;
    });
    handleChangeData('ConvertTopup', data);
  };

  return (
    <div className="form-group-info">
      <FormGroupTitle className="default-font-size">
        <Translate value="pointConfig.Convert_topup_amount_to_points" />
      </FormGroupTitle>
      {topupData.length > 0 &&
        topupData.map((item) => {
          return (
            <FormGroup className="qup-input-group" key={item.type}>
              <Row>
                <Col xs={2}>
                  <Form.Label>
                    {I18n.t(`paymentMethod.paymentMethod_${item.type}`)}
                  </Form.Label>
                </Col>
                <Col xs={5}>
                  <InputGroup className="single-addon-left">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {selectedFleet.currencies[0].symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className="form-custom"
                      type="number"
                      onChange={(e) => handleChangeValue(item.type, e)}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(e, (e) => {
                          handleChangeValue(item.type, e);
                        });
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(e, (e) => {
                          handleChangeValue(item.type, e);
                        });
                      }}
                      value={item.value}
                      disabled={!hasPermission}
                    />
                  </InputGroup>
                </Col>
                <Col xs={5}>
                  <InputGroup className="single-addon-right">
                    <FormControl
                      className="form-custom"
                      type="number"
                      min={0}
                      // onChange={e => this.onInputChange(e)}
                      value={'1'}
                      disabled={true}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="pointConfig.point" />
                      </InputGroup.Text>
                    </InputGroup.Append>{' '}
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
          );
        })}
    </div>
  );
}

export default ConvertTopup;
