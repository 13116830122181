import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'react-bootstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as reportActions from '../../actions/reportActions';
import * as mapActions from '../../actions/mapActions';
import * as loadingActions from '../../actions/loadingBarActions';
import * as commonActions from '../../actions/commonDataAction';

import './dispatchlogs.scss';

import FormModalLogGps from './formModalLogGps';

const DispatchLogs = ({
  commonData,
  map,
  auth,
  data,
  selectedFleet,
  reportActions,
  loadingActions,
  showModal,
  setShowModal
}) => {
  const props = {
    commonData,
    map,
    auth,
    data,
    selectedFleet,
    reportActions,
    loadingActions,
  };

  return (
    <div className="booking-dispatch-logs">
      <FormModalLogGps
        {...(props || {})}
        showModal={showModal}
        setShowModal={setShowModal}
        type={'cue'}
      />
    </div>
  );
};

DispatchLogs.contextTypes = {
  notification: PropTypes.func,
  reloadCueAnhMap: PropTypes.func,
};

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
    auth: state.auth,
    map: state.map,
    commonData: state.commonData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    mapActions: bindActionCreators(mapActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchLogs);
