import { callApi } from '../utils/apiUtils';
import {
  fetchZoneNoShowApi,
  createZoneNoShowApi,
  updateZoneNoShowApi,
  deleteZoneNoShowApi,
  activeZoneNoShowApi,
  deactiveZoneNoShowApi
} from '../constants/ApiConfigs';

export const Types = {
  CREATE_ZONE_NO_SHOW: 'CREATE_ZONE_NO_SHOW',
  SUCCESS_CREATE_ZONE_NO_SHOW: 'SUCCESS_CREATE_ZONE_NO_SHOW',
  FAILED_CREATE_ZONE_NO_SHOW: 'FAILED_CREATE_ZONE_NO_SHOW',
  UPDATE_ZONE_NO_SHOW: 'UPDATE_ZONE_NO_SHOW',
  SUCCESS_UPDATE_ZONE_NO_SHOW: 'SUCCESS_UPDATE_ZONE_NO_SHOW',
  FAILED_UPDATE_ZONE_NO_SHOW: 'FAILED_UPDATE_ZONE_NO_SHOW',
  DELETE_ZONE_NO_SHOW: 'DELETE_ZONE_NO_SHOW',
  SUCCESS_DELETE_ZONE_NO_SHOW: 'SUCCESS_DELETE_ZONE_NO_SHOW',
  FAILED_DELETE_ZONE_NO_SHOW: 'FAILED_DELETE_ZONE_NO_SHOW',
  ACTIVE_ZONE_NO_SHOW: 'ACTIVE_ZONE_NO_SHOW',
  SUCCESS_ACTIVE_ZONE_NO_SHOW: 'SUCCESS_ACTIVE_ZONE_NO_SHOW',
  FAILED_ACTIVE_ZONE_NO_SHOW: 'FAILED_ACTIVE_ZONE_NO_SHOW',
  DEACTIVE_ZONE_NO_SHOW: 'DEACTIVE_ZONE_NO_SHOW',
  SUCCESS_DEACTIVE_ZONE_NO_SHOW: 'SUCCESS_DEACTIVE_ZONE_NO_SHOW',
  FAILED_DEACTIVE_ZONE_NO_SHOW: 'FAILED_DEACTIVE_ZONE_NO_SHOW'
};

export const fetchZoneNoShow = (options = {}) => {
  const config = {
    method: 'POST',
    body: JSON.stringify(options)
  };

  return callApi(fetchZoneNoShowApi, config, null, null, null, true);
};

const requestCreateZoneNoShow = () => ({
  type: Types.CREATE_ZONE_NO_SHOW
});

const successCreateZoneNoShow = () => ({
  type: Types.SUCCESS_CREATE_ZONE_NO_SHOW
});

const failedCreateZoneNoShow = () => ({
  type: Types.FAILED_CREATE_ZONE_NO_SHOW
});

export const createZoneNoShow = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    createZoneNoShowApi,
    config,
    requestCreateZoneNoShow(),
    successCreateZoneNoShow,
    failedCreateZoneNoShow,
    true
  );
};

const requestUpdateZoneNoShow = () => ({
  type: Types.UPDATE_ZONE_NO_SHOW
});

const successUpdateZoneNoShow = () => ({
  type: Types.SUCCESS_UPDATE_ZONE_NO_SHOW
});

const failedUpdateZoneNoShow = () => ({
  type: Types.FAILED_UPDATE_ZONE_NO_SHOW
});

export const updateZoneNoShow = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    updateZoneNoShowApi,
    config,
    requestUpdateZoneNoShow(),
    successUpdateZoneNoShow,
    failedUpdateZoneNoShow,
    true
  );
};

const requestDeleteZoneNoShow = () => ({
  type: Types.DELETE_ZONE_NO_SHOW
});

const successDeleteZoneNoShow = () => ({
  type: Types.SUCCESS_DELETE_ZONE_NO_SHOW
});

const failedDeleteZoneNoShow = () => ({
  type: Types.FAILED_DELETE_ZONE_NO_SHOW
});

export const deleteZoneNoShow = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    deleteZoneNoShowApi,
    config,
    requestDeleteZoneNoShow(),
    successDeleteZoneNoShow,
    failedDeleteZoneNoShow,
    true
  );
};

const requestActiveZoneNoShow = () => ({
  type: Types.ACTIVE_ZONE_NO_SHOW
});

const successActiveZoneNoShow = () => ({
  type: Types.SUCCESS_ACTIVE_ZONE_NO_SHOW
});

const failedActiveZoneNoShow = () => ({
  type: Types.FAILED_ACTIVE_ZONE_NO_SHOW
});

export const activeZoneNoShow = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    activeZoneNoShowApi,
    config,
    requestActiveZoneNoShow(),
    successActiveZoneNoShow,
    failedActiveZoneNoShow,
    true
  );
};

const requestDeactiveZoneNoShow = () => ({
  type: Types.DEACTIVE_ZONE_NO_SHOW
});

const successDeactiveZoneNoShow = () => ({
  type: Types.SUCCESS_DEACTIVE_ZONE_NO_SHOW
});

const failedDeactiveZoneNoShow = () => ({
  type: Types.FAILED_DEACTIVE_ZONE_NO_SHOW
});

export const deactiveZoneNoShow = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    deactiveZoneNoShowApi,
    config,
    requestDeactiveZoneNoShow(),
    successDeactiveZoneNoShow,
    failedDeactiveZoneNoShow,
    true
  );
};
