import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import * as commonData from '../../../constants/commondata';
import { reportFinancialDriverApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import ErrorPage from '../../../containers/misc/ErrorPage';

class ReportFinancialDriver extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: null,
      fleetServices: [],
      showFleetService: false,
    };
  }

  showFleetServices = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      row: data.row,
      fleetServices: row.fleetServices || [],
      bookingId: row.bookingId,
      showFleetService: true,
    });
  };
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Financial (Driver)',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    let checkActive = _.findIndex(report.actions, {
      name: 'Financial (Driver)',
      isActive: true,
    });
    checkActive =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkActive === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }

    const fleetServiceModal = (
      <Modal
        show={this.state.showFleetService}
        backdrop="static"
        bsSize="lg"
        dialogClassName="additional-service-modal"
        onHide={() => this.setState({ showFleetService: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center text-bold">
            <Translate value="report.result.bookingDetails.fleetServiceFee" /> #
            {this.state.bookingId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.fleetServices.length === 0 ? (
            <div className="no-item-was-found">
              <Translate value="report.result.noResultFound" />
            </div>
          ) : (
            <div className="modal-report-table-container">
              <table className="table table-no-pagination cc-table-striped">
                <tbody>
                  {this.state.fleetServices.map((doc) => (
                    <tr>
                      {fieldMappings.fleetServiceFields.map((item) => (
                        <td
                          className="modal-report-table-row-content-service"
                          style={{ padding: 20 }}
                        >
                          {item.mutate
                            ? item.mutate(doc[item.key], this.state.row)
                            : doc[item.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );

    return checkActive ? (
      <div className="content">
        {fleetServiceModal}
        <BaseReport
          tittle="Financial Driver"
          fieldMappings={fieldMappings}
          apiurl={reportFinancialDriverApi}
          dateRangeConfigName='financial'
          dateRange
          finishedStatus
          company
          driver
          bookingService={commonData.bookingServices.filter(
            (i) =>
              i.value !== 'all' &&
              i.value !== 'homeFleet' &&
              i.value !== 'roaming'
          )}
          currency
          paymentMethod
          shortTrip
          search="report.query.search.financialDriver"
          settings
          actions={{
            showFleetServices: this.showFleetServices,
          }}
          noexport={!checkExport}
        />
      </div>
    ) : (
      <ErrorPage />
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReportFinancialDriver);
