export const checkRequestLocationApi = process.env.REACT_APP_LOCATION_CHECK;
// APIs for CC server
export const serverBase = process.env.REACT_APP_API_SERVER;
export const serverInvoice = process.env.REACT_APP_INVOICE_SERVER;
export const jupiterBase = process.env.REACT_APP_SOCKET_SERVER;
export const userLoginApi = serverBase + 'user/login';
export const userLogoutApi = serverBase + 'user/logout';
export const changePasswordApi = serverBase + 'user/changepassword';
export const resetPasswordApi = serverBase + 'user/updatepassword';
export const forgotPasswordApi = serverBase + 'user/forgotpassword';
export const editProfileApi = serverBase + 'user/editprofile';
export const checkResetPasswordTokenApi = serverBase + 'user/checktoken';
export const cueFindApi = serverBase + 'booking/find';
export const cueCompletedFindApi = serverBase + 'completedbooking/find';
export const operatorApi = serverBase + 'user/operators?fleetId=';
export const bookingFromApi = serverBase + 'booking/froms?fleetId=';
export const checkZoneSupportedByPoint =
  serverBase + 'booking/check-zone-supported';
export const getZoneWithLatLngApi =
  serverBase + 'zone/get-zone-by-lat-lng';
export const getFleetDetailApi = serverBase + 'fleet/details';
export const getAllFleetApi = serverBase + 'fleet/fetch';
export const getBookingDetailApi = serverBase + 'booking/details?bookId=';
export const getCustomerApi = serverBase + 'customer/find';
export const countCustomerApi = serverBase + 'customer/count';
export const checkExistCustomerPhoneApi = serverBase + 'customer/checkphone';
export const importCustomersApi = serverBase + 'customer/import';
export const deleteCustomerApi = serverBase + 'customer/multi-delete';
export const activateCustomerApi = serverBase + 'customer/multi-activate';
export const deactivateCustomerApi = serverBase + 'customer/multi-deactivate';
export const carTypeBaseLocationApi = serverBase + 'setting/get-service';
export const customerAutocompleteApi = serverBase + 'customer/autocomplete';
export const driverAutocompleteApi = serverBase + 'driver/autocomplete';
export const operationAutocompleteAffiliateApi =
  serverBase + 'operation/autocompleteaffiliate';
export const corprateCompaniesApi = serverBase + 'corporate/find';
export const countCorporateApi = serverBase + 'corporate/count';
export const exportCorporateToExcelApi = serverBase + 'corporate/export';
export const checkExistCorporateTravellerPhoneApi =
  serverBase + 'corporate/check-traveller-phone';
export const checkExistCorporateTravellerUserNameApi =
  serverBase + 'corporate/check-traveller-username';
export const paymentMethodApi = serverBase + 'fleet/paymentmethods?fleetId=';
export const operationAutocompleteApi = serverBase + 'operation/autocomplete';
export const operationAutocompleteMultiBookApi = serverBase + 'operation/autocompleteMultiBook';
export const etaFareApi = serverBase + 'setting/eta-fare';
export const getPayOutDataApi = serverBase + 'setting/getPayOutData';
export const etaDeliveryFareApi = serverBase + 'setting/eta-delivery-fare';
export const checkCustomerBookingApi = serverBase + 'customer/addWithBooking';
export const editCustomerApi = serverBase + 'customer/edit';
export const addCustomerApi = serverBase + 'customer/add';
export const nearestDriverApi = serverBase + 'operation/nearest';
export const findOneCustomerApi = serverBase + 'customer/findOne';
export const exportCustomerToExcelApi = serverBase + 'customer/export';
export const getPendingPaymentCustomerApi =
  serverBase + 'customer/pending-payment';
export const getEmergencyContactCustomerApi =
  serverBase + 'customer/emergency-contact';
export const getTotalBalanceCustomerApi = serverBase + 'customer/total-balance';
export const writeOffDebtCustomerApi = serverBase + 'customer/write-off-debt';
export const topupPaxWalletCustomerApi =
  serverBase + 'customer/topup-pax-wallet';
export const updatePointCustomerApi = serverBase + 'customer/update-point';
export const checkPromoCodeApi = serverBase + 'promotion/check';
export const addPromoCodeUseApi = serverBase + 'promotion/addPromoCodeUse';
export const addNewCreditApi = serverBase + 'credit/add';
export const editTotalBookingApi = serverBase + 'completedbooking/editTotal';
export const getRetryCompleteBookingApi =
  serverBase + 'booking/getFirstPayDetail';
export const getServiceFeeApi = serverBase + 'booking/getServiceFee';
export const getCorporateDetailForBookingApi =
  serverBase + 'corporate/get-by-booking';
export const getRejectInfoApi = serverBase + 'booking/reject-info';
export const tencentApi = serverBase + 'tencent';
export const findFleetApi = serverBase + 'user/fleets';
export const getMenuItemsOutputDataApi =
  serverBase + 'item/get_items_output_data';

// driver
export const getDriverApi = serverBase + 'driver/find';
export const deleteDriverApi = serverBase + 'driver/multi-delete';
export const activateMultiDriverApi = serverBase + 'driver/multi-activate';
export const deactivateMultiDriverApi = serverBase + 'driver/multi-deactivate';
export const activateDriverApi = serverBase + 'driver/activate';
export const deactivateDriverApi = serverBase + 'driver/deactivate';
export const editDriverApi = serverBase + 'driver/update';
export const updateDriverDocumentApi = serverBase + 'driver/update-driver-document'
export const addDriverApi = serverBase + 'driver/add';
export const findOneDriverApi = serverBase + 'driver/details';
export const checkExistDriverPhoneApi = serverBase + 'driver/checkphone';
export const exportDriverToExcelApi = serverBase + 'driver/export';
export const driverFindApi = serverBase + 'operation/find';
export const operationFindByIdApi = serverBase + 'operation/find-by-ids';
export const mapDriversGetApi = serverBase + 'map/drivers';
export const mapBookingsGetApi = serverBase + 'map/bookings';
export const mapHeatmapApi = serverBase + 'map/heatmap';
export const getCurrencies = serverBase + 'information/currencies';
export const getDriverReservationBookingApi =
  serverBase + 'booking/driver/reservation';
export const getDriverAccountRequireFieldApi =
  serverBase + 'driver/get-bank-requirement';
export const removeBankInfoApi = serverBase + 'driver/remove-bank-info';
export const countDriverApi = serverBase + 'driver/count';
export const driverTotalBalanceApi = serverBase + 'driver/total-wallet';
export const driverEmergencyContactApi =
  serverBase + 'driver/emergency-contact';
export const setFleetCommissionDriverApi =
  serverBase + 'driver/fleet-commission';
export const setDocumentsMissingApi = serverBase + 'driver/documents-missing';

export const findOneVehicleApi = serverBase + 'vehicle/details';
export const fetchRelativeInfoApi = serverBase + 'driver/relativeinfo';
export const createDriverApi = serverBase + 'driver/create';
export const addVehicleApi = serverBase + 'driver/addVehicle';
export const removeVehicleApi = serverBase + 'driver/removeVehicle';
export const switchVehicleApi = serverBase + 'driver/switch-vehicle';
export const resestPasswordApi = serverBase + 'driver/reset-password';
export const creditBalanceApi = serverBase + 'driver/creditbalance';
export const updateCashBalanceApi = serverBase + 'driver/cashbalance';
export const getMessageApi = serverBase + 'inbox/find';
export const deleteMessagesApi = serverBase + 'inbox/delete';
export const sendMessageApi = serverBase + 'inbox/create';
export const exportMessageReceiversApi = serverBase + 'inbox/export-to-excel';
export const editMessageApi = serverBase + 'inbox/edit';
// corporate
export const createCorporateApi = serverBase + 'corporate/create';
export const updateCorporateApi = serverBase + 'corporate/update';
export const detailCorporateApi = serverBase + 'corporate/details';
export const activeCorporateApi = serverBase + 'corporate/active';
export const multiActiveCorporateApi = serverBase + 'corporate/multi-active';
export const multiDeleteCorporateApi = serverBase + 'corporate/multil-delete';
export const createTravelerApi = serverBase + 'corporate/traveler-create';
export const updateTravelerApi = serverBase + 'corporate/traveler-update';
export const findTravelerApi = serverBase + 'corporate/traveler-find';
export const findCorpAlias = serverBase + 'corporate/findAlias';
export const countTravelerApi = serverBase + 'corporate/traveler-count';
export const activeTravelerApi = serverBase + 'corporate/traveler-active';
export const deleteTravelerApi = serverBase + 'corporate/traveler-delete';
export const updatePaymentCorporateApi =
  serverBase + 'corporate/payment-update';
export const deleteCardCorporateApi = serverBase + 'corporate/delete-card';
export const addCardCorporateApi = serverBase + 'corporate/add-card';
export const addUserCorporateApi = serverBase + 'corporate/add-user';
export const updateUserCorporateApi = serverBase + 'corporate/update-user';
export const findUserCorporateApi = serverBase + 'corporate/find-user';
export const deleteUserCorporateApi = serverBase + 'corporate/delete-user';
export const fetchCarTypeCorporateApi = serverBase + 'corporate/fetch-car-type';
export const updatePricingCorporateApi =
  serverBase + 'corporate/update-pricing';
export const updateSupplierCorporateApi = serverBase + 'corporate/update-supplier';
export const updateCarTypeCorporateApi = serverBase + 'corporate/update-car-type';
export const assignRateCorporateApi = serverBase + 'corporate/assign-rate';
export const resetPasswordCorporateApi =
  serverBase + 'corporate/reset-password';
export const topUpCorporateApi = serverBase + 'corporate/top-up';
// airline
export const createAirlineApi = serverBase + 'airline/create';
export const updateAirlineApi = serverBase + 'airline/update';
export const detailAirlineApi = serverBase + 'airline/details';
export const activeAirlineApi = serverBase + 'airline/active';
export const multiActiveAirlineApi = serverBase + 'airline/multi-active';
export const multiDeleteAirlineApi = serverBase + 'airline/multil-delete';
export const createTravelerAirlineApi = serverBase + 'airline/traveler-create';
export const updateTravelerAirlineApi = serverBase + 'airline/traveler-update';
export const findTravelerAirlineApi = serverBase + 'airline/traveler-find';
export const countTravelerAirlineApi = serverBase + 'airline/traveler-count';
export const activeTravelerAirlineApi = serverBase + 'airline/traveler-active';
export const deleteTravelerAirlineApi = serverBase + 'airline/traveler-delete';
export const updatePaymentAirlineApi = serverBase + 'airline/payment-update';
export const deleteCardAirlineApi = serverBase + 'airline/delete-card';
export const addCardAirlineApi = serverBase + 'airline/add-card';
export const addUserAirlineApi = serverBase + 'airline/add-user';
export const updateUserAirlineApi = serverBase + 'airline/update-user';
export const findUserAirlineApi = serverBase + 'airline/find-user';
export const deleteUserAirlineApi = serverBase + 'airline/delete-user';
export const fetchCarTypeAirlineApi = serverBase + 'airline/fetch-car-type';
export const updatePricingAirlineApi = serverBase + 'airline/update-pricing';
export const assignRateAirlineApi = serverBase + 'airline/assign-rate';
export const resetPasswordAirlineApi = serverBase + 'airline/reset-password';
export const topUpAirlineApi = serverBase + 'airline/top-up';
export const airlineCompaniesApi = serverBase + 'airline/find';
export const countAirlineApi = serverBase + 'airline/count';
export const checkExistAirlineTravellerPhoneApi =
  serverBase + 'airline/check-traveller-phone';
export const addCrewAirlineApi = serverBase + 'airline/add-crew';
export const updateCrewAirlineApi = serverBase + 'airline/update-crew';
export const findCrewAirlineApi = serverBase + 'airline/find-crew';
export const deleteCrewAirlineApi = serverBase + 'airline/delete-crew';
export const resestPasswordCrewApi = serverBase + 'airline/reset-password-crew';
export const activeCrewAirlineApi = serverBase + 'airline/active-crew';
export const checkExistAirlineCrewPhoneApi =
  serverBase + 'airline/check-crew-phone';
// reports
export const reportBookingDetailsApi = serverBase + 'report/booking-details';
export const reportFinancialApi = serverBase + 'report/financial';
export const reportFinancialDriverApi = serverBase + 'report/financial-driver';
export const reportFinancialProfitApi =
  serverBase + 'report/financial-fleet-profit';
export const reportDriverReferralDetailApi =
  serverBase + 'report/referral-details';
export const reportFinancialCompanyApi =
  serverBase + 'report/financial-company';
export const reportCompanySettlementApi =
  serverBase + 'report/company-settlement';
export const reportCompanySettlementDetailsApi =
  serverBase + 'report/company-settlement-details';
export const reportCarActivityApi = serverBase + 'report/car-activity';
export const reportCarActivityLogsApi = serverBase + 'report/car-activity-logs';
export const reportAffiliationApi = serverBase + 'report/affiliation';
export const reportPrepaidTopUpApi = serverBase + 'report/prepaid';
export const reportOperatorLogsApi = serverBase + 'report/operator-logs';
export const reportCorporateApi = serverBase + 'report/corporate';
export const reportBookingLogsApi = serverBase + 'report/booking-logs';
export const reportDispatchHistorysApi = serverBase + 'report/dispatch-history';
export const reportBookingLogGpsApi = serverBase + 'report/booking-log-gps';
export const reportBookingMapApi = serverBase + 'report/booking-map';
export const reportPromotionApi = serverBase + 'report/promotion';
export const reportDriverThumbRatingApi = serverBase + 'report/rating';
export const reportDriverStarsRatingApi = serverBase + 'report/driver-rating';
export const reportPassengerRatingApi = serverBase + 'report/passenger-rating';
export const reportMDispatcherApi = serverBase + 'report/mdispatcher';
export const reportTotalAdjustmentApi = serverBase + 'report/total-adjustment';
export const reportIncidentCancellationApi =
  serverBase + 'report/incident-cancellation';
export const reportRevenueFleetApi = serverBase + 'report/revenue-fleet';
export const reportRevenueDriverApi = serverBase + 'report/revenue-driver';
export const reportRevenueMonthlyApi = serverBase + 'report/revenue-monthly';
export const reportDailyDriverApi = serverBase + 'report/daily-driver';
export const reportDailyCarApi = serverBase + 'report/daily-car';
export const reportDailyDriverCarApi = serverBase + 'report/daily-driver-car';
export const reportDriverLoginStatusApi =
  serverBase + 'report/driver-login-status';
export const reportBookingSummaryApi = serverBase + 'report/booking-summary';
export const reportCreditWalletApi =
  serverBase + 'report/driver-deposit'; /* Change from */
export const reportCashWalletApi = serverBase + 'report/driver-cash-wallet';
export const reportPaidToDriverApi = serverBase + 'report/paid-to-driver';
export const reportSettlementHistoryApi =
  serverBase + 'report/settlement-history';
export const reportSettlementApi = serverBase + 'report/settlement';
export const reportThirdPartyLocationApi =
  serverBase + 'report/third-party-location';
export const reportDocumentExpiryApi = serverBase + 'report/document-expiry';
export const reportDriverWithdrawalApi =
  serverBase + 'report/withdrawal-history';
export const report3rdBooking =
  serverBase + 'report/third-party-booking-log';
export const reportPenaltyCompensationApi =
  serverBase + 'report/cc-penalty-compensate';
export const reportIncompletePaymentApi =
  serverBase + 'report/incomplete-payment';
export const reportRefundApi = serverBase + 'report/refund';
export const reportPassengerWalletApi = serverBase + 'report/passenger-wallet';
export const reportAcceptanceCancellationRateApi =
  serverBase + 'report/acceptance-cancellation';
export const reportReferralRefersPaxApi =
  serverBase + 'report/cc-referral-history';
export const reportReferralRefersDriverApi =
  serverBase + 'report/cc-referral-driver-history';
export const reportReferralRefersDriverRefereeApi =
  serverBase + 'report/cc-referral-driver-referees';
export const reportPassengerRefersPaxApi =
  serverBase + 'report/cc-pax-referral-history';
export const reportReferralRefersPaxExportApi =
  serverBase + 'v2/export-referral-history';
export const reportReferralRefersPaxDetailApi =
  serverBase + 'report/cc-referral-passengers';
export const reportPaxReferralRefersPaxDetailApi =
  serverBase + 'report/cc-pax-referral-passengers';
export const payToDriverApi = serverBase + 'driver/report/pay';
export const notifyCrashClientAPI = serverBase + 'crash-client';
export const payoutToDriverApi = serverBase + 'driver/report/payout';
export const invalidBankAccountApi =
  serverBase + 'driver/report/updateInvalidPayout';
export const clearSettlementApi = serverBase + 'driver/report/remove';
export const sendReceiptApi = serverBase + 'booking/sendReceipt';
export const sendPayoutApi = serverBase + 'email/sendPayout';
export const getExportPercentApi = serverBase + 'export/percent';
export const reportAirlineApi = serverBase + 'report/airline';
export const getAirlineByIdApi = serverBase + 'airline/find-one';
export const fetchAirlineApi = serverBase + 'airline/fetch?fleetId=';

export const getFleetFareSettingApi = serverBase + 'setting/fleet-fare';
export const getTechFeeApi = serverBase + 'setting/tech-fee';
export const add3rdPartyIntegrationAPI = serverBase + 'setting/add-3rd-party-integration';
export const update3rdIntegrationAPI = serverBase + 'setting/update-3rd-party-integration';
export const addHolidayTaxisAPI = serverBase + 'setting/add-3rd-party-holidaytaxis';
export const checkCredentialIntegrationAPI = serverBase + 'setting/check-credential-integration';
export const get3rdPartyIntegrationAPI = serverBase + 'setting/get-3rd-party-integration';
export const delete3rdPartyIntegrationAPI = serverBase + 'setting/delete-3rd-party-integration';
export const deleteHolidayTaxisAPI = serverBase + 'setting/delete-3rd-party-holidaytaxis';
export const getComission = serverBase + 'setting/commission';

export const saveInvoiceSettingAPI = serverBase + 'setting/save-invoice-setting';
export const getInvoiceSettingAPI = serverBase + 'setting/get-invoice-setting';

export const getCompaniesOfFleetApi = serverBase + 'company/fetch?fleetId=';
export const getCitiesOfFleetApi = serverBase + 'city/fetch?fleetId=';

export const getDriversOfFleetApi = serverBase + 'driver/fetch';
export const loadMoreDriversOfFleetApi = serverBase + 'driver/load-more';
export const getCorporateByIdApi = serverBase + 'corporate/find-one';
export const fetchCorporateApi = serverBase + 'corporate/fetch?fleetId=';
export const getCompletedBookingDetail =
  serverBase + 'completedbooking/details?bookId=';
export const deleteCreditApi = serverBase + 'credit/delete';
export const checkUnionPayCreditApi = serverBase + 'credit/check-unionpay-card';
export const getClientSecretApi = serverBase + 'credit/get-client-secret';

// settings
export const fetchAdditionalServicesApi = serverBase + 'setting/services/fetch';
export const createAdditionalServicesApi =
  serverBase + 'setting/services/create';
export const additionalServicesGetOneApi =
  serverBase + 'setting/services/details';
export const additionalServicesUpdatedApi =
  serverBase + 'setting/services/update';
export const deleteAdditionalServicesApi =
  serverBase + 'setting/services/delete';
export const getFleetInfoApi = serverBase + 'setting/general/get';
export const saveGeneralSettingsApi = serverBase + 'setting/general/save';
export const saveSettingsCommissionApi =
  serverBase + 'setting/general/saveFleetCommission';
export const userFindApi = serverBase + 'user/find';
export const userFindOneApi = serverBase + 'user/findOne';
export const userCreateApi = serverBase + 'user/create';
export const userCreateSupplierApi = serverBase + 'user/create-supplier';
export const userUpdateSupplierApi = serverBase + 'user/update-supplier';
export const userUpdateActiveSupplierApi = serverBase + 'user/active-supplier';
export const userUpdatedApi = serverBase + 'user/update';
export const userDeleteApi = serverBase + 'user/delete';
export const userActiveApi = serverBase + 'user/active';
export const exportUserToExcelApi = serverBase + 'user/export';
export const userRolesGetApi = serverBase + 'roles?fleetId=';
export const userResetPasswordApi = serverBase + 'user/resetpassword';
export const mapZoneFindApi = serverBase + 'fzone/find';
export const mapZoneFinAlldApi = serverBase + 'zone/findAll?fleetId=';
export const mapZoneFindAlldNotGeoCoordinatesApi = serverBase + 'zone/findAllNotGeoCoordinates?fleetId=';
export const getAllZonesApi = serverBase + 'fzone/findAll?fleetId=';
export const fetchAllZonesApi = serverBase + 'fzone/fetchAll?fleetId=';
export const updateZoneApi = serverBase + 'fzone/update';
export const activeZoneApi = serverBase + 'fzone/active';
export const createZoneApi = serverBase + 'fzone/create';
export const deactiveZoneApi = serverBase + 'fzone/deactive';
export const deleteZoneApi = serverBase + 'fzone/delete';
export const importZoneApi = serverBase + 'fzone/import';

// sms booking template
export const sendMessageBookingApi = serverBase + 'smsbookingtemplate/sendMessage'
export const getSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/fetch';
export const getAllSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/getAll';
export const createSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/create';
export const updateSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/update';
export const deleteSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/delete';
export const detailsSMSBookingTemplateApi = serverBase + 'smsbookingtemplate/details';

export const companyGetAllApi = serverBase + 'company/find';
export const companyFetchApi = serverBase + 'company/fetch';
export const companyCreateApi = serverBase + 'company/create';
export const companyUpdateApi = serverBase + 'company/update';
export const companyDeleteApi = serverBase + 'company/delete';
export const companyGetOneApi = serverBase + 'company/details';
export const activeCompanyApi = serverBase + 'company/active';
export const multiActiveCompanyApi = serverBase + 'company/multi-active';
export const exportCompanyToExcelApi = serverBase + 'company/export';

export const apiKeyFindApi = serverBase + 'apiKey/find';
export const apiKeyCreateApi = serverBase + 'apiKey/create';
export const apiKeyDeleteApi = serverBase + 'apiKey/delete';
export const apiKeyDetailApi = serverBase + 'apiKey/details';
export const apiKeyUpdateApi = serverBase + 'apiKey/update';
export const createEndpointAPI = serverBase + 'apiKey/createEndpoint';
export const deleteEndpointAPI = serverBase + 'apiKey/deleteEndpoint';
export const updateEndpointAPI = serverBase + 'apiKey/updateEndpoint';

export const getDispatchSettingDetailApi =
  serverBase + 'process/details?fleetId=';
export const saveDispatchSettingDetailApi = serverBase + 'process/update';
export const getVoiceSMSSettingDetailApi =
  serverBase + 'smstwilio/details?fleetId=';
export const saveVoiceSMSSettingDetailApi = serverBase + 'smstwilio/update';

export const getVoipSettingApi = serverBase + 'voip/details?fleetId=';
export const saveVoipSettingApi = serverBase + 'voip/update';

export const rateRegularFetchApi = serverBase + 'rate/regular/fetch';
export const rateRegularFindApi = serverBase + 'rate/regular/find';
export const rateRegularFindOneApi = serverBase + 'rate/regular/findOne';
export const rateRegularCreateApi = serverBase + 'rate/regular/add';
export const rateRegularUpdatedApi = serverBase + 'rate/regular/edit';
export const rateRegularDeleteApi = serverBase + 'rate/regular/delete';
export const rateRegularActiveApi = serverBase + 'rate/regular/activate';
export const rateRegularDeactiveApi = serverBase + 'rate/regular/deactivate';

export const rateFlatFetchApi = serverBase + 'rate/flat/fetch';
export const rateFlatFindApi = serverBase + 'rate/flat/find';
export const rateFlatImportApi = serverBase + 'rate/flat/import';
export const rateFlatExportApi = serverBase + 'rate/flat/export';
export const rateFlatFindOneApi = serverBase + 'rate/flat/findOne';
export const rateFlatCreateApi = serverBase + 'rate/flat/add';
export const rateFlatUpdatedApi = serverBase + 'rate/flat/edit';
export const rateFlatDeleteApi = serverBase + 'rate/flat/delete';
export const rateFlatActiveApi = serverBase + 'rate/flat/activate';
export const rateFlatDeactiveApi = serverBase + 'rate/flat/deactivate';

export const rateHourlyFetchApi = serverBase + 'rate/hourly/fetch';
export const rateHourlyFindApi = serverBase + 'rate/hourly/find';
export const rateHourlyFindOneApi = serverBase + 'rate/hourly/findOne';
export const rateHourlyCreateApi = serverBase + 'rate/hourly/add';
export const rateHourlyUpdatedApi = serverBase + 'rate/hourly/edit';
export const rateHourlyDeleteApi = serverBase + 'rate/hourly/delete';
export const rateHourlyActiveApi = serverBase + 'rate/hourly/activate';
export const rateHourlyDeactiveApi = serverBase + 'rate/hourly/deactivate';

export const flatRoutesFindApi = serverBase + 'rate/flat/routes';
export const flatRoutesDetailApi = serverBase + 'rate/flat/routes/details';
export const getDetailFlatRateRoutesByNameApi = serverBase + 'rate/flat/routes/detailsByName';
export const flatRoutesAddApi = serverBase + 'rate/flat/routes/add';
export const flatRoutesEditApi = serverBase + 'rate/flat/routes/edit';
export const flatRoutesDeleteApi = serverBase + 'rate/flat/routes/delete';

export const vehicleFindApi = serverBase + 'vehicle/find';
export const vehicleFindByIdsApi = serverBase + 'vehicle/findByIds';
export const bookingAutoCompleteApi = serverBase + 'vehicle/booking-auto-complete';
export const vehicleFetchApi = serverBase + 'vehicle/fetch';
export const vehicleFindOneApi = serverBase + 'vehicle/details';
export const vehicleCreateApi = serverBase + 'vehicle/create';
export const vehicleUpdatedApi = serverBase + 'vehicle/update';
export const vehicleDeleteApi = serverBase + 'vehicle/delete';
export const vehicleActiveApi = serverBase + 'vehicle/active';
export const vehicleMultiActiveApi = serverBase + 'vehicle/multi-active';
export const vehicleMultiDeleteApi = serverBase + 'vehicle/multi-delete';
export const vehicleLoadMoreApi = serverBase + 'vehicle/load-more';
export const vehicleLoadMoreByTypeApi =
  serverBase + 'vehicle/load-more-by-type';
export const exportVehicleToExcelApi = serverBase + 'vehicle/export';
export const fetchAirportZoneApi = serverBase + 'airport-zones';

export const vehiclemakeFindApi = serverBase + 'vehiclemake/find';
export const vehiclemakeFetchApi = serverBase + 'vehiclemake/fetch';
export const vehiclemakeCreateApi = serverBase + 'vehiclemake/create';
export const vehiclemakeUpdatedApi = serverBase + 'vehiclemake/update';
export const vehiclemakeDeleteApi = serverBase + 'vehiclemake/delete';

export const vehiclemodelFindApi = serverBase + 'vehiclemodel/find';
export const vehiclemodelFetchApi = serverBase + 'vehiclemodel/fetchbymake';
export const vehiclemodelCreateApi = serverBase + 'vehiclemodel/create';
export const vehiclemodelUpdatedApi = serverBase + 'vehiclemodel/update';
export const vehiclemodelDeleteApi = serverBase + 'vehiclemodel/delete';

export const vehiclecolorFindApi = serverBase + 'vehiclecolor/find';
// export const vehiclemakeFetchApi = serverBase + 'vehiclemake/fetch'
export const vehiclecolorCreateApi = serverBase + 'vehiclecolor/create';
export const vehiclecolorUpdatedApi = serverBase + 'vehiclecolor/update';
export const vehiclecolorDeleteApi = serverBase + 'vehiclecolor/delete';
export const vehiclecolorAutocompleteApi =
  serverBase + 'vehiclecolor/autocomplete';

export const carTypedApi = serverBase + 'vehicletype/getnames?fleetId=';
export const carTypeFindApi = serverBase + 'vehicletype/find';
export const carTypeUpdateConfigApi = serverBase + 'vehicletype/updateConfig';
export const carTypeFindOneApi = serverBase + 'vehicletype/details';
export const carTypeAssignrateApi = serverBase + 'vehicletype/assignrate';
export const carTypeAutocompleteApi = serverBase + 'vehicletype/autocomplete';
export const carTypeImportAssignRateApi = serverBase + 'vehicletype/importAssignRate';
export const carTypeExportRateApi = serverBase + 'vehicletype/exportRate';
export const carTypeFetchByCompanyApi =
  serverBase + 'vehicletype/fetchbycompany';
export const carTypeUpdateReOrderApi = serverBase + "vehicletype/updateReOrder"

export const hourlyPackagerateFindApi = serverBase + 'packagerate/find';
export const hourlyPackagerateDetailApi = serverBase + 'packagerate/findOne';
export const hourlyPackagerateDetailByNameApi = serverBase + 'packagerate/findByName';
export const hourlyPackagerateAddApi = serverBase + 'packagerate/add';
export const hourlyPackagerateEditApi = serverBase + 'packagerate/edit';
export const hourlyPackagerateDeleteApi = serverBase + 'packagerate/delete';

export const surchargeCreateApi = serverBase + 'setting/surcharge/create';
export const surchargeUpdateApi = serverBase + 'setting/surcharge/update';
export const surchargeDeleteApi = serverBase + 'setting/surcharge/delete';
export const surchargeDetailGetApi = serverBase + 'setting/surcharge/details';

export const createFleetServiceApi =
  serverBase + 'setting/services/createFleetService';
export const getFleetServiceApi =
  serverBase + 'setting/services/getFleetService';
export const updateFleetServiceApi =
  serverBase + 'setting/services/updateFleetService';
export const deleteFleetServiceApi =
  serverBase + 'setting/services/deleteFleetService';

export const getGatewayZonesApi = serverBase + 'setting/gateway-zones?fleetId=';
export const getGatewaysApi = serverBase + 'setting/gateways';
export const getStripeConnectStatusApi = serverBase + 'setting/get-stripe-connect-status';
export const stripeConnectOnboardingApi = serverBase + 'setting/strip-connect-Onboarding';
export const stripeConnectLoginApi = serverBase + 'setting/stripe-connect-login';
export const disconnectStripeApi = serverBase + 'setting/disconnect-stripe';
export const checkExistingStripeAPI = serverBase + 'setting/check-existing-connect-stripe';

// Flight infor Apis
export const getAllflightNamesApi = serverBase + 'flight/airlinename';
export const getFlightSchedulesApi =
  serverBase + 'flight/airlineFlightSchedules';
export const getInfoFlightApi = serverBase + 'flight-info/get';

// Notification Apis
export const notificationGetAllApi = serverBase + 'notification/getall';
export const notificationMarkedAllAsViewedApi =
  serverBase + 'notification/viewed';
export const notificationMarkedItemAsReadApi = serverBase + 'notification/read';
export const notificationDeleteApi = serverBase + 'notification/delete';
export const notificationHistory = serverBase + 'report/notification-history';
export const sosReadApi = serverBase + 'sos/read';
export const sosDeleteApi = serverBase + 'sos/delete';
export const sosGetAllApi = serverBase + 'sos/getall';
export const sosMardedAllAsViewedApi = serverBase + 'sos/viewed';
export const sosHistory = serverBase + 'report/sos-history';

export const signUpNotificationGetAllApi =
  serverBase + 'signUpNotification/getall';
export const signUpNotificationMarkedAllAsViewedApi =
  serverBase + 'signUpNotification/viewed';
export const signUpNotificationMarkedItemAsReadApi =
  serverBase + 'signUpNotification/read';
export const signUpNotificationDeleteApi =
  serverBase + 'signUpNotification/delete';
export const signUpHistory =
  serverBase + 'report/driver-signed-up-notifications';

export const getCrosszoneRateApi = serverBase + 'v2/distance/geolocations';
export const affiliateNearestDriverApi =
  serverBase + 'operation/nearestAffiliate';
export const affiliateFareSettingApi =
  serverBase + 'setting/fleet-fare-affiliate';

// Shift setting apis
export const shiftSettingsDetailsApi = serverBase + 'shift/details';
export const shiftSettingsDeleteApi = serverBase + 'shift/delete';
export const shiftSettingsUpdateApi = serverBase + 'shift/update';
export const shiftSettingsCreateApi = serverBase + 'shift/create';
export const shiftSettingsFind = serverBase + 'shift/find';

export const shiftTemplateDetailsApi = serverBase + 'shiftTemplate/details';
export const shiftTemplateDeleteApi = serverBase + 'shiftTemplate/delete';
export const shiftTemplateUpdateApi = serverBase + 'shiftTemplate/update';
export const shiftTemplateCreateApi = serverBase + 'shiftTemplate/create';
export const shiftTemplateFindApi = serverBase + 'shiftTemplate/find';
export const shiftTemplateLoadAllShiftSettingsApi = serverBase + 'shift/fetch';
export const shiftOperationSettingsApi = serverBase + 'shiftTemplate/fetch';

// Promotion Apis
export const promotionCampaignCreateApi =
  serverBase + 'promotioncampaign/create';
export const promotionCampaignUpdateApi =
  serverBase + 'promotioncampaign/update';
export const promotionCampaignDetailsApi =
  serverBase + 'promotioncampaign/details';
export const promotionCampaignDeleteApi =
  serverBase + 'promotioncampaign/delete';
export const promotionCampaignFindApi = serverBase + 'promotioncampaign/find';
export const promotionCampaignActiveApi =
  serverBase + 'promotioncampaign/active';

export const promotionCodeCreateApi = serverBase + 'promotioncode/create';
export const promotionCodeUpdateApi = serverBase + 'promotioncode/update';
export const promotionCodeDetailsApi = serverBase + 'promotioncode/details';
export const promotionCodeDeleteApi = serverBase + 'promotioncode/delete';
export const promotionSendInboxApi = serverBase + 'promotioncode/send';
export const promotionCodeMultiDeleteApi =
  serverBase + 'promotioncode/multidelete';
export const promotionCodeFindApi = serverBase + 'promotioncode/find';
export const promotionCodeFetchForReportApi =
  serverBase + 'promotioncode/fetchForReport';
export const promotionCodeActivateApi = serverBase + 'promotioncode/activate';
export const promotionCodeMultiActivateApi =
  serverBase + 'promotioncode/multiactivate';
export const promotionCodeFetchCampaign =
  serverBase + 'promotioncampaign/fetch?fleetId=';
export const promotionCodeFetch = serverBase + 'promotioncode/fetch?fleetId=';
export const promotionCodeImports = serverBase + 'promotion/import';
export const promotionCodeFetchForReferral =
  serverBase + 'promotioncode/fetchForRefferal';
export const getPromotionCodeInfoApi = serverBase + 'promotioncode/getInfo';
export const getStatusRecurringApi = serverBase + 'booking/recurrings';
export const prePaidForBookingApi = serverBase + 'booking/prePaidForBooking';
export const getPaymentActivitiesApi = serverBase + 'booking/getPaymentActivities';
export const getPaymentLinkForPrepaidApi = serverBase + 'booking/getPaymentLinkForPrepaid';
export const deactivatePrepaidPaymentLinkAPI = serverBase + 'booking/deactivatePrepaidPaymentLink';
export const postPaidForBookingAPI = serverBase + 'booking/postPaidForBooking';
export const prePayRefundAPI = serverBase + 'booking/prePayRefund';
export const getPaymentLinkForPostpaidAPI = serverBase + 'booking/getPaymentLinkForPostpaid';
export const deactivatePaymentLinkForBookingAPI = serverBase + 'booking/deactivatePaymentLinkForBooking';
// Quest
export const eventFindApi = serverBase + 'event/find?';
export const eventFetchApi = serverBase + 'event/fetch?';
export const eventDetailApi = serverBase + 'event/detail?';
export const eventCreateApi = serverBase + 'event/create';
export const eventUpdateApi = serverBase + 'event/update';
export const eventUpdateStatusApi = serverBase + 'event/activate';
export const eventFindAllApi = serverBase + 'event/findAll?';

// Voucher code
export const voucherCodeFindApi = serverBase + 'vouchercode/find';
export const voucherCodeCreateApi = serverBase + 'vouchercode/create';
export const voucherCodeUpdateApi = serverBase + 'vouchercode/update';
export const voucherCodeDetailsApi = serverBase + 'vouchercode/details';
export const voucherCodeActivateApi = serverBase + 'vouchercode/activate';
export const voucherCodeDeleteApi = serverBase + 'vouchercode/delete';
export const voucherCodeMultiActivateApi =
  serverBase + 'vouchercode/multiactivate';
export const voucherCodeMultiDeleteApi = serverBase + 'vouchercode/multidelete';
export const voucherCodeImports = serverBase + 'vouchercode/import';
export const voucherCodeFetchApi = serverBase + 'vouchercode/fetch?fleetId=';
export const voucherCodeFetchForReportApi =
  serverBase + 'vouchercode/fetchForReport';

// Permision Apis
export const permissionSettingsDetailsApi = serverBase + 'roles/details';
export const permissionSettingsDeleteApi = serverBase + 'roles/delete';
export const permissionSettingsUpdateApi = serverBase + 'roles/update';
export const permissionSettingsCreateApi = serverBase + 'roles/create';
export const permissionSettingsFind = serverBase + 'roles/find';
export const permissionSettingsActive = serverBase + 'roles/activate';
export const permissionrolesDefaultApi = serverBase + 'roles/default';
export const permissionRolesAllowableSpecifiefPermissionApi =
  serverBase + 'roles/get-allowable-specified';
export const exportPermissionsToExcelApi = serverBase + 'roles/export';

// City Apis
export const citySettingsDetailsApi = serverBase + 'city/details';
export const citySettingsDeleteApi = serverBase + 'city/delete';
export const citySettingsUpdateApi = serverBase + 'city/update';
export const citySettingsCreateApi = serverBase + 'city/create';
export const citySettingsFind = serverBase + 'city/find';

// jobTab Apis
export const jobTabSettingsDetailsApi = serverBase + 'jobTab/details';
export const jobTabSettingsDeleteApi = serverBase + 'jobTab/delete';
export const jobTabSettingsUpdateApi = serverBase + 'jobTab/update';
export const jobTabSettingsCreateApi = serverBase + 'jobTab/create';
export const jobTabSettingsFind = serverBase + 'jobTab/find';

// Driver Note Apis
export const driverNoteSettingsDetailsApi = serverBase + 'driver-note/details';
export const driverNoteSettingsDeleteApi = serverBase + 'driver-note/delete';
export const driverNoteSettingsUpdateApi = serverBase + 'driver-note/update';
export const driverNoteSettingsCreateApi = serverBase + 'driver-note/create';
export const driverNoteSettingsFind = serverBase + 'driver-note/find';
export const driverNoteAutocompleteApi =
  serverBase + 'driver-note/autocomplete';
// Partner type Apis
export const mDispatcherTypeSettingsDetailsApi =
  serverBase + 'mdispatchertype/details';
export const mDispatcherTypeSettingsDeleteApi =
  serverBase + 'mdispatchertype/delete';
export const mDispatcherTypeSettingsUpdateApi =
  serverBase + 'mdispatchertype/update';
export const mDispatcherTypeSettingsCreateApi =
  serverBase + 'mdispatchertype/create';
export const mDispatcherTypeSettingsFind = serverBase + 'mdispatchertype/find';
export const mDispatcherTypeSettingsFetchApi =
  serverBase + 'mdispatchertype/fetch?fleetId=';

// mDispatcher Type Apis
export const mDispatcherDetailsApi = serverBase + 'mdispatcher/details';
export const mDispatcherDeleteApi = serverBase + 'mdispatcher/delete';
export const mDispatcherUpdateApi = serverBase + 'mdispatcher/update';
export const mDispatcherCreateApi = serverBase + 'mdispatcher/create';
export const mDispatcherFindApi = serverBase + 'mdispatcher/find';
export const mDispatcherCountApi = serverBase + 'mdispatcher/count';
export const mDispatcherActiveApi = serverBase + 'mdispatcher/active';
export const mDispatcherMultipleActiveApi =
  serverBase + 'mdispatcher/multiactive';
export const mDispatcherMultipleDeleteApi =
  serverBase + 'mdispatcher/multidelete';
export const mDispatcherResetPasswordApi =
  serverBase + 'mdispatcher/resetPassword';
export const fetchMDispatcherNamesApi = serverBase + 'mdispatcher/fetch';
export const checkExistmDispatcherPhoneApi =
  serverBase + 'mdispatcher/checkphone';

// Flight Intergration Apis
export const flightAwareSettingsGetApi = serverBase + 'flightAware/get';
export const flightAwareSettingsUpdateFind = serverBase + 'flightAware/set';

// Flight Provider Apis
export const detailFlightProviderApi = serverBase + 'flight-provider/details';
export const updateFlightProviderApi = serverBase + 'flight-provider/update';

// Queuing Area
export const queuingAreaFetchApi = serverBase + 'queuingarea/fetch';

// Operation
export const operationCompanyApi = serverBase + 'company/operator';
export const fetchOperationApi = serverBase + 'operation/fetch';
export const assginOperationApi = serverBase + 'operation/assign';
export const unassginOperationApi = serverBase + 'operation/unassign';

// Queuing area
export const queuingAreaSettingsDetailsApi = serverBase + 'queuingarea/details';
export const queuingAreaSettingsDeleteApi = serverBase + 'queuingarea/delete';
export const queuingAreaSettingsUpdateApi = serverBase + 'queuingarea/update';
export const queuingAreaSettingsCreateApi = serverBase + 'queuingarea/create';
export const queuingAreaSettingsFind = serverBase + 'queuingarea/find';
export const queuingAreaSettingsGetAll = serverBase + 'queuingarea/fetch';
export const queuingAreaSettingsActive = serverBase + 'queuingarea/active';
export const queuingAreaOtherAreasApi = serverBase + 'queuingarea/other-areas';
export const queuingAreaSettingsCheckAddress =
  serverBase + 'queuingarea/check-address';
export const queuingAreaSettingsGetMDispatcherListForQueuing =
  serverBase + 'mdispatcher/fetch-for-queuing';

export const queuingAreaGetDriverListApi =
  serverBase + 'queuingarea/driver-list';
export const queuingAreaDriverDeleteApi =
  serverBase + 'queuingarea/remove-driver';
export const queuingAreaDriverChangeOrderApi =
  serverBase + 'queuingarea/change-order-driver';
export const queuingAreaDriverInsertApi =
  serverBase + 'queuingarea/insert-driver';

// App Banner
export const getAppBannerSettingsApi = serverBase + 'setting/appbanner/get';
export const setAppBannerSettingsApi = serverBase + 'setting/appbanner/save';
export const setPlaybackSettingsApi =
  serverBase + 'setting/appbanner/save-playback';
// Third Party
export const createThirdPartyLocationApi = serverBase + 'thirdparty/create';
export const findThirdPartyLocationApi = serverBase + 'thirdparty/find';
export const detailsThirdPartyLocationApi = serverBase + 'thirdparty/details';
export const updateThirdPartyLocationApi = serverBase + 'thirdparty/update';
export const deleteThirdPartyLocationApi = serverBase + 'thirdparty/delete';

// Category

export const createCategoryApi = serverBase + 'category/create';
export const findCategoryApi = serverBase + 'category/find';
export const detailsCategoryApi = serverBase + 'category/details';
export const updateCategoryApi = serverBase + 'category/update';
export const deleteCategoryApi = serverBase + 'category/delete';

// Reservation
export const getReservationApi = serverBase + 'booking/reservation';

// Initial

export const initialServerApi =
  serverBase + 'initial';

// Dashboard
export const getBookingStatisticApi = serverBase + 'report/booking-statistic';
export const getOthersInfoApi = serverBase + 'dashboard/info';

// Referral
export const getReferralDriverToPaxDetailsApi = serverBase + 'referral/details';
export const saveReferralDriverToPaxDetailsApi = serverBase + 'referral/update';
export const saveReferralDriverToDriverDetailsApi = serverBase + 'referralDrv/update';
export const getReferralCodeListApi = serverBase + 'referral/fetchForReport';
export const findOneReferralCodeApi = serverBase + 'referral/findOne';
export const updatePromoCodeReferralDriverApi =
  serverBase + 'referral/updatePromo';
export const removePromoCodeReferralDriverApi =
  serverBase + 'referral/removePromo';

// Passenger Referral Pax
export const getReferralPassengerToPaxDetailsApi =
  serverBase + 'paxreferral/details';
export const saveReferralPassengerToPaxDetailsApi =
  serverBase + 'paxreferral/update';
export const updatePromoCodeReferralPassengerToPaxDetailsApi =
  serverBase + 'paxreferral/updatePromo';
export const removePromoCodeReferralPassengerToPaxDetailsApi =
  serverBase + 'paxreferral/removePromo';

// Payment info
export const ownerAddNewCardApi = serverBase + 'credit/add-for-owner';
export const ownerGetPaymentInfoApi = serverBase + 'credit/fleet-owner-info';
export const deleteCreditTokenForFleetOwnerApi =
  serverBase + 'credit/delete-owner-credit';
export const setDefaultCardForFleetOwnerApi =
  serverBase + 'credit/set-default-owner-credit';
export const getSecureFieldSessionTSYSAPI =
  serverBase + 'setting/getSecureFieldSession';
export const getCheckoutPageAPI = serverBase + 'setting/getCheckoutPage';

// Withdrawal
export const getWidthdrawalRequestsApi = serverBase + 'withdrawal-request/find';
export const resolveDrawalRequestApi =
  serverBase + 'withdrawal-request/resolve';
export const resolveMultiDrawalRequestApi =
  serverBase + 'withdrawal-request/resolve-multi';

// Service Fees by zone
export const updateServiceFeeApplyTypeApi =
  serverBase + 'setting/general/apply-type';
export const updateFleetCommissionApplyTypeApi =
  serverBase + 'setting/general/apply-type-commission';
export const getServiceFeesApi = serverBase + 'setting/service-fee/get';
export const getServiceFeesSettingApi = serverBase + 'setting/get-service-setting';
export const updateServiceFeesApi = serverBase + 'setting/service-fee/update';
export const createSurchargeServiceFeesApi =
  serverBase + 'setting/service-fee/create-surcharge';
export const updateSurchargeServiceFeesApi =
  serverBase + 'setting/service-fee/update-surcharge';
export const deleteSurchargeServiceFeesApi =
  serverBase + 'setting/service-fee/delete-surcharge';

// Dynamic surcharge
export const getOperationZonesApi = serverBase + 'dynamic-surcharge/fetchzone';
export const getDynamicSurchargeByZoneApi =
  serverBase + 'dynamic-surcharge/fetch-by-zone';
export const getOpertaionDetailApi = serverBase + 'zone/get';
export const createDynamicSurchargeApi =
  serverBase + 'dynamic-surcharge/create';
export const updateDynamicSurchargeApi =
  serverBase + 'dynamic-surcharge/update';
export const deleteDynamicSurchargeApi =
  serverBase + 'dynamic-surcharge/delete';
export const activeDynamicSurchargeApi =
  serverBase + 'dynamic-surcharge/active';
export const deactiveDynamicSurchargeApi =
  serverBase + 'dynamic-surcharge/deactive';

// Dynamic fare
export const getDynamicFareOperationZonesApi =
  serverBase + 'dynamic-fare/fetchzone';
export const getDynamicFareByZoneApi =
  serverBase + 'dynamic-fare/fetch-by-zone';
export const createDynamicFareApi = serverBase + 'dynamic-fare/create';
export const updateDynamicFareApi = serverBase + 'dynamic-fare/update';
export const deleteDynamicFareApi = serverBase + 'dynamic-fare/delete';
export const activeDynamicFareApi = serverBase + 'dynamic-fare/active';
export const deactiveDynamicFareApi = serverBase + 'dynamic-fare/deactive';

// Zone No Show
export const fetchZoneNoShowApi = serverBase + 'zone-no-show/fetch';
export const createZoneNoShowApi = serverBase + 'zone-no-show/create';
export const updateZoneNoShowApi = serverBase + 'zone-no-show/update';
export const deleteZoneNoShowApi = serverBase + 'zone-no-show/delete';
export const activeZoneNoShowApi = serverBase + 'zone-no-show/active';
export const deactiveZoneNoShowApi = serverBase + 'zone-no-show/deactive';

// Service Fees by zone
export const getAllAirportFeeApi = serverBase + 'settings/airport-fee';
export const updateAirportFeeApi = serverBase + 'settings/airport-fee/update';
export const createAirportFeeApi = serverBase + 'settings/airport-fee/create';
export const deleteAirportFeeApi = serverBase + 'settings/airport-fee/delete';
export const updateAllAirportFeeApi =
  serverBase + 'settings/airport-fee/update-all';

// Face mask auth by zone
export const getAllFaceMaskZoneApi = serverBase + 'settings/face-mask';
export const updateAllFaceMaskZoneApi =
  serverBase + 'settings/face-mask/update-all';

/* Default Top up Value */
export const getDefaultTopupValuesApi =
  serverBase + 'setting/get-default-value';
export const updateDefaultTopupValuesApi =
  serverBase + 'setting/update-default-value';

// Service Fees by zone
export const updateAppBannerApi = serverBase + 'app-banner/update';
export const createAppBannerApi = serverBase + 'app-banner/create';
export const deleteAppBannerApi = serverBase + 'app-banner/delete';
export const fetchAppBannerApi = serverBase + 'app-banner/fetch';
export const activateAppBannerApi = serverBase + 'app-banner/activate';
export const deactivateAppBannerApi = serverBase + 'app-banner/deactivate';
export const orderPriorityAppBannerApi =
  serverBase + 'app-banner/order-priority';

// Smart Data
export const topDriversApi = serverBase + 'top-drivers';
export const exportTopDriversApi = serverBase + 'top-drivers/export';

export const uploadPhotoApi = serverBase + 'upload/photo';
export const uploadDocumentApi = serverBase + 'upload/document';

// Intercity Zone
export const fetchIntercityZonesApi =
  serverBase + 'intercity-zone/fetch?fleetId=';
export const updateIntercityZoneApi = serverBase + 'intercity-zone/update';
export const activeIntercityZoneApi = serverBase + 'intercity-zone/active';
export const createIntercityZoneApi = serverBase + 'intercity-zone/create';
export const deactiveIntercityZoneApi = serverBase + 'intercity-zone/deactive';
export const deleteIntercityZoneApi = serverBase + 'intercity-zone/delete';
export const importIntercityZoneApi = serverBase + 'intercity-zone/import';

// Intercity Rate
export const intercityRateFetchApi = serverBase + 'rate/intercity/fetch';
export const intercityRateFindApi = serverBase + 'rate/intercity/find';
export const intercityRateFindOneApi = serverBase + 'rate/intercity/findOne';
export const intercityRateCreateApi = serverBase + 'rate/intercity/add';
export const intercityRateUpdatedApi = serverBase + 'rate/intercity/edit';
export const intercityRateDeleteApi = serverBase + 'rate/intercity/delete';
export const intercityRateActiveApi = serverBase + 'rate/intercity/activate';
export const intercityRateDeactiveApi =
  serverBase + 'rate/intercity/deactivate';

// Intercity Route
export const intercityRoutesFindApi = serverBase + 'rate/intercity/routes';
export const intercityRoutesDetailApi =
  serverBase + 'rate/intercity/routes/details';
export const intercityRoutesAddApi = serverBase + 'rate/intercity/routes/add';
export const intercityRoutesEditApi = serverBase + 'rate/intercity/routes/edit';
export const intercityRoutesDeleteApi =
  serverBase + 'rate/intercity/routes/delete';
export const intercityRoutesFindWithCarTypeApi =
  serverBase + 'rate/intercity/routes/findWithCarType';
export const intercityRoutesFetchWithCarTypeApi =
  serverBase + 'rate/intercity/routes/fetchRoute?fleetId=';

// Delivery Rate
export const deliveryRateFetchApi = serverBase + 'rate/delivery/fetch';
export const deliveryRateFindApi = serverBase + 'rate/delivery/find';
export const deliveryRateFindOneApi = serverBase + 'rate/delivery/findOne';
export const deliveryRateCreateApi = serverBase + 'rate/delivery/add';
export const deliveryRateUpdatedApi = serverBase + 'rate/delivery/edit';
export const deliveryRateDeleteApi = serverBase + 'rate/delivery/delete';
export const deliveryRateActiveApi = serverBase + 'rate/delivery/activate';
export const deliveryRateDeactiveApi = serverBase + 'rate/delivery/deactivate';

// Street Sharing Rate
export const sharingRateFetchApi = serverBase + 'rate/sharing/fetch';
export const sharingRateFindApi = serverBase + 'rate/sharing/find';
export const sharingRateFindOneApi = serverBase + 'rate/sharing/findOne';
export const sharingRateCreateApi = serverBase + 'rate/sharing/add';
export const sharingRateUpdatedApi = serverBase + 'rate/sharing/edit';
export const sharingRateDeleteApi = serverBase + 'rate/sharing/delete';
export const sharingRateActiveApi = serverBase + 'rate/sharing/activate';
export const sharingRateDeactiveApi = serverBase + 'rate/sharing/deactivate';

// Trip
export const getTripApi = serverBase + 'trip/find';
export const getTripDetailsApi = serverBase + 'trip/details';

// Payout
export const reportPayoutHistoryApi =
  serverBase + 'report/payout-driver-history';
export const reportPayoutToDriverApi = serverBase + 'report/pay-to-driver';

// Payout merchant
export const reportPayoutMerchantHistoryApi =
  serverBase + 'report/payout-merchant-history';
export const reportPayoutToMerchantApi = serverBase + 'report/pay-to-merchant';
export const payoutToMerchantApi = serverBase + 'driver/report/payout-merchant';

export const getPayoutTimeApi = serverBase + 'payout-time/fetch';
export const findPayoutTimeApi = serverBase + 'payout-time/find';

// VOIP
export const getAccCCVoipApi = serverBase + 'user/ccvoip';

// event
export const reportQuestApi = serverBase + 'report/quest';

export const reportVoucherApi = serverBase + 'report/redeemed';

// Merchant
export const getMerchantListApi = serverBase + 'merchant/find?';
export const findMerchantUsers = serverBase + 'merchant/find_user?';
export const detailsMerchantUser = serverBase + 'merchant/details_user?';
export const activateMerchantUser = serverBase + 'merchant/activate_user';
export const activateMultipleMerchantUserApi =
  serverBase + 'merchant/multiple_activate_user';
export const resetPasswordMerchantUserApi =
  serverBase + 'merchant/reset_password';
export const createMerchantUserApi = serverBase + 'merchant/create_user';
export const updateMerchantUserApi = serverBase + 'merchant/update_user';
export const merchantAutocompleteApi = serverBase + 'merchant/autocomplete';
export const detailsMerchant = serverBase + 'merchant/details?';
export const reportMerchantApi = serverBase + 'report/merchant';
export const reportMerchantCashWalletApi =
  serverBase + 'report/cc-merchant-cash-transaction-history';
export const reportMerchantCreditWalletApi =
  serverBase + 'report/cc-merchant-credit-transaction-history';
export const reportDeleteAccountRequestApi =
  serverBase + 'report/delete-account-request';
export const reportAffiliationPayoutHistory =
  serverBase + 'report/affiliation-payout-history';
export const reportAffiliationPayoutHistoryDetails =
  serverBase + 'report/affiliation-payout-history-details';

export const getBankRequirementApi =
  serverBase + 'merchant/get-bank-requirement';
export const deleteBankInfoMerchantApi =
  serverBase + 'merchant/remove_bank_info';
export const walletCashMerchantBalanceApi =
  serverBase + 'merchant/update_cash_wallet_balance';
export const walletCreditMerchantBalanceApi =
  serverBase + 'merchant/update_credit_wallet_balance';

// Filter Template
export const getFilterTemplateApi = serverBase + 'filter/fetch?';
export const addFilterTemplateApi = serverBase + 'filter/add';
export const deleteFilterTemplateApi = serverBase + 'filter/delete';

// Airline
export const getAirlineApi = serverBase + 'airline/fetch?';

// Point config
export const getPointConfigDetailsApi = serverBase + 'point-config/details';
export const updatePointConfigApi = serverBase + 'point-config/update';
export const addRedeemPointConfigApi = serverBase + 'point-config/addRedeem';
export const updateRedeemPointConfigApi =
  serverBase + 'point-config/updateRedeem';
export const removeRedeemPointConfigApi =
  serverBase + 'point-config/removeRedeem';

// Driver Fields
export const driverFieldsFetchAPI = serverBase + 'driver-fields/fetch'
export const driverFieldsDetailAPI = serverBase + 'driver-fields/details'
export const driverFieldsEditAPI = serverBase + 'driver-fields/update'

// Driver Document
export const driverDocumentFetchAPI = serverBase + 'driver-document/fetch'
export const driverDocumentDetailAPI = serverBase + 'driver-document/details'
export const driverDocumentEditAPI = serverBase + 'driver-document/update'

// Map Server
// export const serverMAP = process.env.REACT_APP_MAP_SERVER;
export const getDistanceMatrixAPI = serverBase + 'map/distancematrix';
export const getAutocompleteAPI = serverBase + 'map/autocomplete';
export const getPlaceDetailAPI = serverBase + 'map/place-detail';
export const getDirectionsAPI = serverBase + 'map/directions';
export const getReverseGeoCodingAPI = serverBase + 'map/reverse-geocoding';
export const getGeoCodingAPI = serverBase + 'map/geoCoding';
export const getTimezoneAPI = serverBase + 'map/timezone';
export const componentsVersion = serverBase + 'get-components-version';
export const ipCheck = serverBase + 'ipcheck';

// email server
export const getEmailConfigDetailApi = serverBase + 'config-email-server/details'
export const updateEmailConfigDetailApi = serverBase + 'config-email-server/update'

export const acceptOfferBookingApi = jupiterBase + '/api/supplier/bookings/{bookId}/accept';

export const searchGroupBookingAPI = jupiterBase + '/api/cc/manifest/search?';
export const groupBookingAPI = jupiterBase + '/api/cc/manifest/group';
export const ungroupBookingAPI = jupiterBase + '/api/cc/manifest/ungroup';
export const assignDriverGroupAPI =
  jupiterBase + '/api/cc/manifest/assign-driver';
export const detachDriverGroupAPI =
  jupiterBase + '/api/cc/manifest/detach-driver';
export const removeBookingGroupAPI =
  jupiterBase + '/api/cc/manifest/remove-book';

//complete booking
export const overrideCustomerCancelApi =
  jupiterBase + '/api/cc/dispatch/override-customer-cancel';
export const overrideDriverCancelApi =
  jupiterBase + '/api/cc/dispatch/override-driver-cancel';
export const overrideCustomerNoShowApi =
  jupiterBase + '/api/cc/dispatch/override-customer-noshow';
export const overrideCustomerWithoutServiceAPI =
  jupiterBase + '/api/cc/dispatch/complete-without-service';

// booking
export const updateFinishedBookingApi =
  jupiterBase + '/api/cc/booking/update-finished-booking';
export const checkChargeDrvCancelApi =
  jupiterBase + '/api/cc/booking/check-charge-driver-cancel';
export const checkChargeCCCancelApi =
  jupiterBase + '/api/cc/booking/check-charge-cc-cancel';

export const createNewBookingAPI =
  jupiterBase + '/api/cc/booking/create';
export const updateBookingAPI =
  jupiterBase + '/api/cc/booking/update';
export const acceptHydraBookingAPI =
  jupiterBase + '/api/cc/booking/accept-booking';
export const thirPartyBookingAPI =
  jupiterBase + '/api/cc/booking/confirm-3rd';
export const denytHydraBookingAPI =
  jupiterBase + '/api/cc/booking/deny-booking';
export const rejectHydraBookingAPI =
  jupiterBase + '/api/cc/booking/reject-booking';
export const changePaymentTypeBookingAPI =
  jupiterBase + '/api/cc/booking/change-payment-type';
export const cancelBookingAPI =
  jupiterBase + '/api/cc/booking/cancel';
export const completeBookingAPI =
  jupiterBase + '/api/cc/booking/complete';
export const incidentBookingAPI =
  jupiterBase + '/api/cc/booking/incident';
export const markFailedRecipientAPI =
  jupiterBase + '/api/cc/booking/mark-failed';
export const rejectBookingAPI =
  jupiterBase + '/api/cc/booking/reject-booking';
export const retryCompleteBookingAPI =
  jupiterBase + '/api/cc/booking/retry-complete-booking';
export const changeSupplierAPI =
  jupiterBase + '/api/cc/booking/change-suppliers';
export const assignDriverAPI =
  jupiterBase + '/api/cc/booking/assign-books-driver';
export const offerDriverAPI =
  jupiterBase + '/api/cc/booking/offer-books-driver';
export const detachDriverAPI =
  jupiterBase + '/api/cc/booking/detach-books-driver';
export const detachVehicleAPI =
  jupiterBase + '/api/cc/booking/detach-vehicle';
export const unassignAllAPI =
  jupiterBase + '/api/cc/booking/unassign-all';
export const etaFareMultiCarApi =
  jupiterBase + '/api/cc/booking/eta-transport';
export const etaFareMultiCarDeliveryApi =
  jupiterBase + '/api/cc/booking/eta-delivery';
export const getDirectionFromJupiterAPI =
  jupiterBase + '/api/cc/booking/eta-distance-and-duration';

export const etaFareIntercityApi =
  jupiterBase + '/api/cc/booking/eta-intercity';

export const syncThirdPartyBookingAPI =
  jupiterBase + '/api/third-party-booking/sync';

export const syncThirdPartyBookingSetKeyAPI =
  jupiterBase + '/api/third-party-booking/set-keys';

export const syncThirdPartyBookingRemoveKeyAPI =
  jupiterBase + '/api/third-party-booking/remove-keys';

  // trailNotes
export const trailNotesApi = jupiterBase + '/api/cc/booking/trail-notes';

// setting pwa

export const getPWASettingsApi = serverBase + 'setting/pwa/get';
export const setPWASettingsApi = serverBase + 'setting/pwa/save';


// Export
export const findExport = serverBase + 'exports/find';
export const deleteExportApi = serverBase + 'exports/delete';

// social
export const getSocialSettingApi = serverBase + 'about-content/details';
export const updateSocialSettingApi = serverBase + 'about-content/update';

// vehicleType
export const updateVehicleTypeApi = serverBase + 'vehicletype/addOrEdit';
export const detailUpdateCarTypeApi = serverBase + 'vehicletype/detailUpdateCarType';

// Invoice 
export const getBookingInvoiceApi = serverInvoice + '/api/invoice/listBookings'
export const getListInvoiceApi = serverInvoice + '/api/invoice/listInvoices'
export const createInvoiceApi = serverInvoice + '/api/invoice/create'
export const detailInvoiceApi = serverInvoice + '/api/invoice'
export const overviewInvoiceApi = serverInvoice + '/api/invoice/overview'
export const editInvoiceApi = serverInvoice + '/api/invoice'
export const deleteInvoiceApi = serverInvoice + '/api/invoice'
export const getPDFInvoiceApi = serverInvoice + '/api/invoice/pdf'
export const sendMailInvoiceApi = serverInvoice + '/api/invoice/send-mail'

// SMS provider
export const getInfoSMSApi = serverBase + 'smsIntegration/getInfoSMS'
export const getSMSCountriesAPI = serverBase + 'smsIntegration/get-sms-countries'
export const createSMSCountriesAPI = serverBase + 'smsIntegration/create-by-country'
export const deleteSMSCountriesAPI = serverBase + 'smsIntegration/delete-by-country'
export const updateSMSCountriesAPI = serverBase + 'smsIntegration/update-by-country'
export const updateInfoSMSApi = serverBase + 'smsIntegration/updateInfoSMS'

export const chargeCustomerForInvoiceAPI = serverBase + 'invoice/charge-customer-for-invoice'
export const updateBookingVisibilityAPI = jupiterBase + '/api/cc/booking/visibility'