export const tableWebhookColumns = [
  {
    key: 'url',
    label: 'APIKeySetting.URL',
    width: 120,
    fixed: true,
    textEllipsis: true,
  },
  {
    key: 'signingSecret',
    label: 'APIKeySetting.signingSecret',
    width: 160,
    textEllipsis: true,
  },
  {
    key: 'listeningFor',
    label: 'APIKeySetting.listeningFor',
    width: 140,
    textEllipsis: true,
    customCell: (data) => {
      console.log('data', data);
      if (data.events)
        return `${data?.events?.length} ${
          data.events?.length === 1 ? 'event' : 'events'
        }`;
      return '';
    },
  },
  {
    key: 'actions',
    label: 'mDispatcher.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
