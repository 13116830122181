import React, { useState } from 'react';
import { Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import DateTime from '../../../../components/dateTime/DateTime';
import { ValidCase, Validator } from '../../../../components/validator';
import moment from 'moment-timezone';
import styles from '../../styles/formDocument.module.scss';
import Trash from '../../../../assets/images/icons/delete.svg';
import FileGim from '../../../../assets/images/icons/fileGim.svg';
import closeIcon from '../../../../assets/images/icons/closeWhite.svg';
import fileIcon from '../../../../assets/images/icons/fileIconWhite.svg';

const ModalDocument = ({
  initState,
  indexState,
  nameDocument,
  d,
  type,
  state,
  props,
  notification,
  handleDriverDocumentChange,
  validatorCallback,
}) => {
  const { detailItem, documentDeleteList, editable } = state;
  const { language } = props;

  const [modalDoc, setModalDoc] = useState(false);
  const [formData, setFormData] = useState({
    files: [],
    oldFile: initState?.documentUrl,
    expiredDate: initState?.expiredDate,
    documentId: initState?.documentId || '',
  });

  const handleShowModalDoc = () => {
    setFormData({
      files: [],
      oldFile: initState?.documentUrl,
      expiredDate: initState?.expiredDate,
      documentId: initState?.documentId || '',
    });
    setModalDoc(true);
  };

  const handleHideModalDoc = () => {
    setModalDoc(false);
    setFormData({
      files: [],
      oldFile: initState?.documentUrl,
      expiredDate: initState?.expiredDate,
      documentId: initState?.documentId || '',
    });
  };

  const handleDocumentKeyChange = (value) => {
    const defaultFormData = { ...formData };
    defaultFormData.documentId = value.target.value;
    setFormData(defaultFormData);
  };

  const validExpiryDate = (current) => {
    var pastDate = moment().subtract(10, 'year');
    var futureDate = moment().add(50, 'year');
    return current.isAfter(pastDate) && current.isBefore(futureDate);
  };

  const isDisableExpiryDate = (keyName) => {
    if (state.driverDocument[keyName]) return false;

    if (
      detailItem &&
      detailItem.documentExpiry &&
      detailItem.documentExpiry.length > 0
    ) {
      let doc = detailItem.documentExpiry.find(
        (obj) => obj.documentKey == keyName && obj.documentUrl
      );
      if (doc) {
        let deleted = documentDeleteList.find((obj) => obj == doc.documentKey);
        if (deleted) return true || !state.driverDocument[keyName];
      }

      if (doc) return false;
    }
    //check if user insert file
    return formData.oldFile ? false : formData.files.length > 0 ? false : true;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (!files || files.length === 0) return;
    let error = false;
    let errorType = false;
    const newFormData = { ...formData };
    // if (newFormData?.oldFile) {
    //   return notification('error', I18n.t('driver.removeOldFile'));
    // }
    if (files.length > 0) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/pdf',
        'application/x-7z-compressed',
        'application/x-rar-compressed',
        'application/zip',
      ];
      if (files.length > 1) {
        return notification('error', I18n.t('driver.maxLengthFiles'));
      }
      const maxSize = 10 * 1024 * 1024;
      Array.from(files).forEach((file) => {
        if (file.size > maxSize) {
          error = true;
        }
        if (!allowedTypes.includes(file.type)) {
          errorType = true;
        }
      });
      if (error) return notification('error', I18n.t('driver.maxSizeFiles'));
      if (errorType) return notification('error', I18n.t('driver.typeFiles'));

      // newFormData['files'] = [...newFormData.files, ...files];
      newFormData['oldFile'] = null;
      newFormData['files'] = [...files];
      setFormData(newFormData);
    }
  };

  const handleChangeFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) return;
    const files = [...e.target.files];
    let error = false;
    let errorType = false;
    const newFormData = { ...formData };
    // if (newFormData?.oldFile) {
    //   return notification('error', I18n.t('driver.removeOldFile'));
    // }
    if (files.length > 1) {
      return notification('error', I18n.t('driver.maxLengthFiles'));
    }
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/x-7z-compressed',
      'application/x-rar-compressed',
      'application/zip',
    ];
    const maxSize = 10 * 1024 * 1024;
    files.forEach((file) => {
      if (file.size > maxSize) {
        error = true;
      }
      if (!allowedTypes.includes(file.type)) {
        errorType = true;
      }
    });
    if (error) return notification('error', I18n.t('driver.maxSizeFiles'));
    if (errorType) return notification('error', I18n.t('driver.typeFiles'));

    // newFormData['files'] = [...newFormData.files, ...files];
    newFormData['oldFile'] = null;
    newFormData['files'] = [...files];
    setFormData(newFormData);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDeleteFile = (index) => {
    const newFormData = { ...formData };
    newFormData.files.splice(index, 1);
    setFormData(newFormData);
  };

  const handleDeleteOldFile = () => {
    const newFormData = { ...formData };
    newFormData.oldFile = null;
    setFormData(newFormData);
  };

  const onExpiryDateChange = (value, document) => {
    const newFormData = { ...formData };
    newFormData.expiredDate = value.format('MM/DD/YYYY');
    setFormData(newFormData);
  };

  const handeUpdateDocument = async () => {
    if (d.isExpiredDate && d.requireExpiredDate && !formData.expiredDate) {
      return notification('error', I18n.t('driver.ERROR_INPUT_EXPIRYDATE'));
    }

    if (d.documentId && d.requireDocumentId && !formData.requireDocumentId) {
      return notification('error', I18n.t('driver.ERROR_INPUT_DOCUMETID'));
    }
    try {
      var data = new FormData();
      data.append('fleetId', props.auth.selectedFleet.fleetId);
      data.append('driverId', props.params.driverId);
      data.append('documentId', formData.documentId);
      data.append('expiredDate', formData.expiredDate);
      data.append('timezone', props.auth.selectedFleet.timezone);
      data.append(d.keyName, formData.files[0] || formData.oldFile);
      if (formData.files.length === 0 && !formData.oldFile) {
        data.append('isDelete', true);
      }
      data.append('keyName', d.keyName);

      const result = await props.updateDriverDocument(data);
      if (result?.ok && result?.res?.phone) {
        let propsResult = result.res
        delete propsResult.phone
        handleHideModalDoc();
        handleDriverDocumentChange(propsResult, indexState)
      }
    } catch (error) {}
  };

  return (
    <>
      <div style={{ width: '80px' }}>
        <a
          style={{ width: '80px' }}
          href="javascript:void(0)"
          className="text-active"
          onClick={handleShowModalDoc}
        >
          {type === 'new' ? I18n.t('driver.Upload') : I18n.t('driver.Edit')}
        </a>
      </div>
      <Modal
        show={modalDoc}
        backdrop={true}
        dialogClassName={styles['confirm-dialog']}
        className={'confirm modalBodyFixed'}
      >
        <Modal.Body>
          <div className={styles['header-group']}>
            <div className={styles['title']}>
              {I18n.t('driver.editDocument')}
            </div>
            <div onClick={handleHideModalDoc} className={styles['close-icon']}>
              <img src={closeIcon} alt="closeIcon" />
            </div>
          </div>
          <div className={styles['document-name']}>
            <span>{nameDocument}</span>
          </div>
          <div className={styles['document-name']}>
            <span>{I18n.t('driver.documentToUpload')}</span>
          </div>
          <div
            className={styles['group-input-activity']}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className={styles['file-upload']}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={fileIcon} alt="icon-files" />
                <p
                  style={{ marginBottom: '0' }}
                  className={styles['document-name']}
                >
                  {I18n.t('driver.addFiles')}
                </p>
                <p
                  style={{ marginBottom: '0' }}
                  className={styles['text-size']}
                >
                  {I18n.t('driver.fileSize')}
                </p>
              </div>

              <input
                type="file"
                multiple
                className={styles['input-file']}
                accept=".jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip"
                max="10485760"
                onChange={(e) => handleChangeFile(e)}
              />
            </div>
          </div>
          <p
            style={{ marginBottom: '10px', marginTop: '10px' }}
            className={styles['text-size']}
          >
            {I18n.t('driver.fileSizeDes')}
          </p>
          {formData.oldFile ? (
            <div className={styles['group-files']}>
              <div className={styles['group-file']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                  }}
                >
                  <img src={FileGim} alt="FileGim" />
                  <a href={formData.oldFile} target="_blank">
                    <span>{nameDocument}</span>
                  </a>
                </div>
                <img
                  onClick={() => handleDeleteOldFile()}
                  className={styles['cursor']}
                  src={Trash}
                  alt="Trash"
                  style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                />
              </div>
            </div>
          ) : (
            formData?.files?.length > 0 && (
              <div className={styles['group-files']}>
                {formData?.files?.map((file, index) => (
                  <div className={styles['group-file']} key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                      }}
                    >
                      <img src={FileGim} alt="FileGim" />
                      <span>{file.name}</span>
                    </div>
                    <img
                      onClick={() => handleDeleteFile(index)}
                      className={styles['cursor']}
                      src={Trash}
                      alt="Trash"
                      style={{
                        width: '15px',
                        height: '15px',
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))}
              </div>
            )
          )}

          {d.isExpiredDate && (
            <FormGroup>
              <Form.Label>
                <Translate value="driver.Expiry_Date" />
                {d.requireExpiredDate && <span className="require"> *</span>}
              </Form.Label>
              <DateTime
                inputProps={{
                  readOnly: true,
                  className: 'date-readonly form-control',
                  disabled: isDisableExpiryDate(d.keyName),
                }}
                value={
                  formData && formData.expiredDate
                    ? moment(formData.expiredDate)
                        .locale(language.toLowerCase())
                        .format('MM/DD/YYYY')
                    : null
                }
                timeFormat={false}
                dateFormat={true}
                onChange={(e) => {
                  onExpiryDateChange(e, d);
                }}
                isValidDate={validExpiryDate}
                placeholder={
                  I18n.t('driver.Expiry_Date') +
                  ' ' +
                  I18n.t('driver.placeholderDate')
                }
                closeOnSelect
                checkShowOnTop
                parrentMarginBottom={30}
                id={d.keyName + '-expiredDate'}
              />
            </FormGroup>
          )}

          {d.documentId ? (
            <FormGroup>
              <Form.Label>
                <Translate value="driver.Document_Id" />
                {d.requireDocumentId && <span className="require"> *</span>}
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                id={d.keyName + '-documentId'}
                maxLength={50}
                value={formData.documentId || ''}
                onChange={(e) => {
                  handleDocumentKeyChange(e);
                }}
              />
            </FormGroup>
          ) : null}

          <div className={styles['groupButton']}>
            <div onClick={handleHideModalDoc} className={styles['closeButton']}>
              {I18n.t('driver.Close')}
            </div>
            <div
              onClick={() => handeUpdateDocument()}
              className={styles['updateButton']}
            >
              {I18n.t('driver.Update')}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDocument;
