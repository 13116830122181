import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const ISendPro = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.keyId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.keyId || ''}
          onChange={(e) => {
            props.handleInputChange('keyId', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.senderId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.senderId || ''}
          onChange={(e) => {
            props.handleInputChange('senderId', e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default ISendPro;
