import React, { Component } from 'react';
import {
  FormGroup,
  Image,
  FormControl,
  Button 
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import {
  CCLiteCommonFunc, statusOfferDriverOnBookDetail
} from '../../../utils/commonFunctions';
import { userType } from '../../../constants/commondata';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import RadioButton from '../../radioButton/radio';
import timeIcon from '../../../assets/images/icons/time.svg';

class DispatchInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false
    };
    this.renderDriverSuggestion = this.renderDriverSuggestion.bind(this);
  }

  toggleLightbox = (selectedIndex) => {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name;
  }

  renderDriverSuggestion(suggestion, { query }) {
    let { status } = suggestion;
    const { handleDriverCalendarClick, data } = this.props;
    if (!suggestion.online) {
      status = 'offline';
    } else if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0] ? suggestion.currentJob[0].status : suggestion.status;
    }
    let pickUpTime = data.time;
    const isBookingDetail = !!data.bookId;

    if (isBookingDetail) {
      pickUpTime = data.request.pickUpTime;
    }

    return (
      <div className={`suggestion-content driver-assign ${status}`}>
        <div className="status" />
        <div className="info">
          <span className="">
            {`${suggestion.firstName} ${suggestion.lastName}${suggestion.company ? ` / ${suggestion.company.companyName}` : ''
              }`}
          </span>
          <span>{`${suggestion.vehicle.plateNumber} / ${suggestion.phone}`}</span>
        </div>
        {pickUpTime != 'Now' ? (
          <div
            className="calendar"
            onClick={e => {
              // need to support handleDriverCalendarClick for delivery
              handleDriverCalendarClick && handleDriverCalendarClick(suggestion);
            }}
          >
            <Image src={timeIcon} />
          </div>
        ) : null}
      </div>
    );
  }

  checkShowWarningLimitBalance = () => {
    let { data = {}, driver, selectedFleet = {}, bookingCurrency = {} } = this.props
    const isBookingDetail = !!data.bookId;
    let { driverDeposit = false, warningAmount = [] } = selectedFleet
    let creditBalances = _.get(driver, 'driverInfo.creditBalances', [])
    if (creditBalances.length == 0) {
      creditBalances = _.get(driver, 'driverId.driverInfo.creditBalances', [])
    }
    let valueBalanceDriver = CCLiteCommonFunc.getValueOfMultiCurrencies(
      creditBalances,
      bookingCurrency.iso,
      'value'
    )
    let valueBalanceSetting = CCLiteCommonFunc.getValueOfMultiCurrencies(
      warningAmount,
      bookingCurrency.iso,
      'value'
    )

    if (!driverDeposit) return false

    if (isBookingDetail) {
      let paymentType = data.request && data.request.paymentType || 0
      let driverFullname = _.get(data, 'drvInfo.fullName', '')
      if (
        paymentType == 1
        && data.dispatchType == 1
        && driverFullname
        && (creditBalances.length > 0)
        && (valueBalanceSetting >= valueBalanceDriver)
      ) {
        return true
      }
    } else {
      if (
        data.paymentMethod == 1
        && data.dispatch_type == 1
        && !!data.driver
        && creditBalances.length > 0
        && (valueBalanceSetting >= valueBalanceDriver)
      ) {
        return true
      }
    }
    return false
  }

  render() {
    const {
      user,
      data,
      handleChangeDispatchType,
      newBooking,
      isSubmited,
      driversuggestions,
      onDriverSuggestionsFetchRequested,
      onDriverSuggestionsClearRequested,
      handleAddtService,
      driverAutocompleteSlectedHandle,
      handleDriverChanged,
      cartypeSelected,
      handleDispatch3rdOnchange,
      valid,
      isEditable,
      isDispatchAble,
      selectedFleet = {},
      handleChangeNote,
      groupId,
      showAssignDriverModal,
      isInGroupModal = false,
      isNotesEnable,
      handleRideSharingChange
    } = this.props;

    const isBookingDetail = !!data.bookId;

    let dispatchType = data.dispatch_type;
    let driverName = data.driver;

    let isDispatchDisable = false;
    let autoSuggestDisabled = isDispatchDisable
      || (data.dispatch_type == 0
        || cartypeSelected === null
        || (newBooking.locationService && newBooking.locationService.crossZone));
    let pickUpTime = data.time;

    if (isBookingDetail) {
      pickUpTime = data.request.pickUpTime;
      dispatchType = data.dispatchType;
      driverName = data.drvInfo ? data.drvInfo.fullName || '' : '';

      if (
        data.pricingType === 1
        && data.fleetId != data.request.psgFleetId
        && (data.status === 'accepted' || data.status === 'confirmed' || data.status === 'action')
      ) {
        /**
         * Booking farmIn -> able assign to driver
         */
        isDispatchDisable = false;
        autoSuggestDisabled = data.dispatchType == 0 || isDispatchDisable;
      } else {
        isDispatchDisable = (!isEditable(data) && !isDispatchAble(data)) || data.pricingType;
        autoSuggestDisabled = data.dispatchType == 0 || cartypeSelected == null || isDispatchDisable;
      }
    }

    return (
      <div>
        <FormGroup>
          {
            (!groupId || !(isInGroupModal && groupId)) &&
            <FormGroupTitle>
              <Translate value="newbooking.DISPATCH" />
            </FormGroupTitle>
          }
          {
            !isInGroupModal && groupId &&
            <div>
              <div className="btn_reassign">
                <label>Driver</label>
                <Button 
                  className="btn-header mr0 btn btn-primary"
                  onClick={showAssignDriverModal}
                >
                  {driverName ? 'Reassign' : 'Assign'}
                </Button>
              </div>
              <FormControl
                type="text"
                value={driverName}
                placeholder=""
                disabled={true}
                className="form-custom"
              />
            </div>
          }
          {(user.userType == userType.FleetAdmin || user.userType == userType.FleetUser) &&
          (!groupId || (isInGroupModal && groupId)) &&
            <FormGroup>
              <FormGroup className="radio-button-group">
                {
                  !isInGroupModal &&
                  <RadioButton
                    text={
                      this.props.isShuttle
                        ? 'Manual dispatch'
                        : I18n.t('newbooking.Auto_dispatch')
                    }
                    value={0}
                    onChange={handleChangeDispatchType}
                    checked={dispatchType == 0 || dispatchType == 2}
                    name="Auto_dispatch"
                    disabled={isDispatchDisable}
                  />
                }
                <RadioButton
                  text={I18n.t('newbooking.Assign_driver')}
                  value={1}
                  name="Auto_dispatch"
                  onChange={handleChangeDispatchType}
                  checked={dispatchType == 1}
                  disabled={isDispatchDisable}
                />
                {
                  !this.props.isShuttle &&
                  pickUpTime !== 'Now' &&
                  statusOfferDriverOnBookDetail(data.status) &&
                  <RadioButton
                    text={I18n.t('newbooking.Offer_driver')}
                    value={3}
                    name="Auto_dispatch"
                    onChange={handleChangeDispatchType}
                    checked={dispatchType === 3}
                    disabled={isDispatchDisable}
                  />
                }
              </FormGroup>
              <FormGroup
                className="assign-driver-container"
              >
                <Autosuggest
                  suggestions={driversuggestions}
                  onSuggestionsFetchRequested={onDriverSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onDriverSuggestionsClearRequested}
                  getSuggestionValue={this.getDriverSuggestionValue}
                  renderSuggestion={this.renderDriverSuggestion}
                  onSuggestionSelected={driverAutocompleteSlectedHandle}
                  shouldRenderSuggestions={() => true}
                  inputProps={{
                    className: 'form-control form-custom',
                    value: driverName,
                    placeholder: I18n.t('newbooking.Search_here'),
                    onChange: handleDriverChanged,
                    disabled: autoSuggestDisabled
                  }}
                />
              </FormGroup>
              {data.status === 'confirmed' && driverName && <div style={{color: '#04BE76', cursor: 'pointer', width: 'fit-content'}} onClick={this.props.handleDetachDrv}>
                <Translate
                  value="newbooking.Unassign"
                />
              </div>}
              {
                this.checkShowWarningLimitBalance()
                && (
                  <Translate
                    value="newbooking.Driver_credit_limited_balance_warning"
                    className="text-warning"
                  />
                )
              }
            </FormGroup>
          }
          {
            !isInGroupModal &&
            <>
              <div className="cb-dispatch">
                {// if new booking
                  !isBookingDetail && selectedFleet.partyDispatching && !this.props.isShuttle ? (
                    <CcCheckbox
                      disabled={
                        user.userType == userType.CorporateAdmin || user.userType == userType.CorporateUser
                      }
                      checked={data.dispatch3rd}
                      onChange={handleDispatch3rdOnchange}
                      text={I18n.t('newbooking.dispatch_3rd')}
                    />
                  ) : null}

                {// if booking detail check if old book is not dispatch 3rd
                  isBookingDetail && selectedFleet.partyDispatching && data.pricingType == 0 && !this.props.isShuttle ? (
                    <CcCheckbox
                      checked={data.dispatch3rd}
                      onChange={handleDispatch3rdOnchange}
                      disabled={
                        isBookingDetail
                        && (!isEditable(data)
                          || data.psgInfo.phone == 'No Phone'
                          || (user.userType == userType.CorporateAdmin
                            || user.userType == userType.CorporateUser))
                      }
                      text={I18n.t('newbooking.dispatch_3rd')}
                    />
                  ) : null}
              </div>
              {
                cartypeSelected &&
                newBooking.locationService &&
                !newBooking.locationService.crossZone && (
                  <CcCheckbox
                    checked={data.rideSharing}
                    onChange={handleRideSharingChange}
                    disabled={isBookingDetail && !isEditable(data)}
                    text={"Batch Delivery"}
                  />
                )
              }
              <FormGroup>
                <FormGroupTitle> 
                  {I18n.t("newbooking.driverNotes")} 
                </FormGroupTitle>
                <FormControl
                  type="text"
                  as="textarea"
                  onChange={handleChangeNote}
                  value={isBookingDetail ? data.request.note : data.note}
                  rows={4}
                  maxLength={1000}
                  className={"form-custom"}
                  placeholder={I18n.t("newbooking.note_for_driver")}
                  disabled={isBookingDetail ? !isNotesEnable(data) : false}
                />
              </FormGroup>
            </>
          }
        </FormGroup>
      </div>
    );
  }
}

export default DispatchInfo;
