import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validation } from '../../../utils/commonFunctions';
import { ValidCase, Validator } from '../../../components/validator';
import { checkPaymentShow } from '../../../components/bookingDetail/bookFunction/payment';
const pmMethodsSupported = [1, 2, 4, 5, 7, 25, 26];

function PaymentMethodSelect(props) {
  // only support Cash, Credit, Direct Invoicing
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isDisableSelectMethodCorp, setDisableSelectMethodCorp] = useState(false); // case admin select method A but login corp board not support A

  useEffect(() => {
    const {
      isDisableSelectMethodCorp,
      paymentMethodCorporates,
      paymentMethodIndividuals
    } = checkPaymentShow({
      auth: props.auth,
      companySelected: props.supplierSelected,
      corporateSelected: props.corporateInfo,
      paymentMethodIndividuals: props.paymentMethodIndividuals?.filter(pm => pmMethodsSupported.includes(pm.value)),
      paymentMethodCorporates: props.paymentMethodsCorporate?.filter(pm => pmMethodsSupported.includes(pm.value)),
      isBookingDetail: props.isBookDetail,
      traveler_type: props.travelerType,
      requestPaymentType: props.value
    })
    setPaymentMethods(props.travelerType === 1 ? paymentMethodCorporates :paymentMethodIndividuals)
    setDisableSelectMethodCorp(isDisableSelectMethodCorp)

  }, [props.travelerType, props.companySelected, props.corporateInfo]);

  // const getOptionMethod = () => {
  //   const {
  //     isDisableSelectMethodCorp,
  //     paymentMethodCorporates,
  //     paymentMethodIndividuals
  //   } = checkPaymentShow({
  //     auth: props.auth,
  //     companySelected: props.supplierSelected,
  //     corporateSelected: props.corporateInfo,
  //     paymentMethodIndividuals: props.paymentMethodIndividuals?.filter(pm => pmMethodsSupported.includes(pm.value)),
  //     paymentMethodCorporates: props.paymentMethodsCorporate?.filter(pm => pmMethodsSupported.includes(pm.value)),
  //     isBookingDetail: props.isBookDetail,
  //     requestPaymentType: props.value
  //   })
  //   if(props.travelerType === 1) {
  //     return paymentMethodCorporates
  //   }
  //   return paymentMethodIndividuals
  // }

  const handleChange = (e) => {
    props.onChange(parseInt(e.target.value))
  };

  return (
    <FormGroup
      className={
        !props.isSubmited ? null : props.valid.payment_method === false ? 'error' : null
      }
    >
      <FormControl
        as="select"
        placeholder="select"
        className={'form-custom'}
        onChange={handleChange}
        value={props.value}
        style={{ marginBottom: 0 }}
        disabled={props.disabled || isDisableSelectMethodCorp}
      >
        <option value={0}>
          {I18n.t(`newbooking.Choose_a_payment_method`)}
        </option>
        {paymentMethods?.map(payment => {
          return (
            <option key={payment.value} value={payment.value}>
              {I18n.t(`General.patmentMethod_${payment.value}`)}
            </option>
          );
        })}
      </FormControl>
      <Validator
        callback={props.validatorCallback}
        id="payment_method"
      >
        <ValidCase
          valid={props.value !== 0}
          message={I18n.t('messages.commonMessages.Required_field')}
          hide={!props.isSubmited}
        />
      </Validator>
    </FormGroup>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    paymentMethodIndividuals: state.paymentMethod.individualTypes,
    paymentMethodsCorporate: state.paymentMethod.corporateTypes,
    carType: state.intercityBooking.common.carType,
    routes: state.intercityBooking.common.routes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodSelect);
