import React, { useState, useEffect } from 'react';
import { Button, Image, Col, Modal } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import remove_booking from '../../../assets/images/icons/remove_bookingGR.svg';
import { transformDataBooking } from '../../../utils/transformData';

const GroupInfoBooking = ({
  listGroup,
  groupId,
  bookIdCurrent,
  disableRemoveBtn,
  isDelivery,
  ...props
}) => {
  const [isShowUngroupModal, setShowUngroupModal] = useState(false);
  const [isShowRemoveBookingModal, setShowRemoveBookingModal] = useState(false);

  const checkShowUngroup = (listGroup = []) => {
    if( !listGroup || listGroup.length === 0) return false
    return listGroup.some(
      (booking) => booking.status === 'confirmed' || booking.status === 'action'
    );
  };

  const handleRemoveConfirmed = () => {
    setShowRemoveBookingModal(false)
    props.removeBookingGroup(bookIdCurrent)
  }


  const renderUnGroupModal = () => {
    return (
      <Modal
        show={isShowUngroupModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        onHide={() => setShowUngroupModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ungroup</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowUngroupModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              isDelivery 
              ? I18n.t('bookingdetail.confirmUngroupDelivery')
              : I18n.t('bookingdetail.confirmUngroupTransport')
            }
          </p>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-save mr-md"
            onClick={props.handleUngroupBooking}
          >
            {I18n.t('bookingdetail.Yes')}
          </Button>
          <Button className="btn-cancel" onClick={() => setShowUngroupModal(false)}>
            {I18n.t('bookingdetail.No')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderRemoveBookingGroupModal = () => {
    return (
      <Modal
        show={isShowRemoveBookingModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="confirm"
        onHide={() => setShowRemoveBookingModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Booking</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowRemoveBookingModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>
            {
              isDelivery 
              ? I18n.t('bookingdetail.confirmRemoveGroupDelivery')
              : I18n.t('bookingdetail.confirmRemoveGroupTransport')
            }
          </p>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="btn-save mr-md"
            onClick={handleRemoveConfirmed}
          >
            {I18n.t('bookingdetail.Yes')}
          </Button>
          <Button className="btn-cancel" onClick={() => setShowRemoveBookingModal(false)}>
            {I18n.t('bookingdetail.No')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <>
      {listGroup.length > 0 && (
        <>
          <Col xs={12}>
            <p className="text_groupId">
              GroupId: <span>#{groupId}</span>
            </p>
            {
              <div className="bookingGroup">
                {listGroup.map((booking) => {
                  const namePsg = _.get(booking, 'psgInfo.firstName', '');
                  const isActiving = booking.bookId === bookIdCurrent;
                  const transformBooking = transformDataBooking({
                    booking: booking,
                    commonData: props.commonData,
                    keys: ['status']
                  })
                  const status =
                    transformBooking.status === 'pre'
                      ? 'statusDisplay.pending'
                      : `statusDisplay.${transformBooking.status}`;
                  return (
                    <div
                      onClick={() =>
                        props.showDetailBookingGroup(booking.bookId)
                      }
                      className={isActiving ? 'active' : ''}
                      key={booking.bookId}
                    >
                      <span>{`#${booking.bookId}`}</span>
                      <Translate className="status_book" value={status} />
                      <span>{namePsg}</span>
                      {isActiving && (
                        <Button
                          className="btn-header btn_remove_booking btn-primary"
                          onClick={setShowRemoveBookingModal}
                        >
                          {disableRemoveBtn && (
                            <Image src={remove_booking} />
                          )}
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
            }
          </Col>
          <Col xs={12}>
            <div className="container_ungroup">
              <span>{`${listGroup.length} Bookings`}</span>
              {checkShowUngroup(listGroup) && (
                <Button
                  className="btn-header btn_ungroup ml0 btn btn-primary"
                  onClick={() => setShowUngroupModal(true)}
                >
                  Ungroup All
                </Button>
              )}
            </div>
          </Col>
        </>
      )}
      {renderUnGroupModal()}
      {renderRemoveBookingGroupModal()}
    </>
  );
};

GroupInfoBooking.defaultProps = {
  listGroup: [],
  groupId: '',
  bookIdCurrent: '',
  showDetailBookingGroup: () => {},
  showRemoveBookingModal: () => {},
  handleUngroupBooking: () => {},
  disableRemoveBtn: false,
  isDelivery: false,
}

GroupInfoBooking.propTypes = {
  listGroup: PropTypes.array.isRequired,
  groupId: PropTypes.string.isRequired,
  bookIdCurrent: PropTypes.string.isRequired,
  showDetailBookingGroup: PropTypes.func.isRequired, // handle when click a item in group
  handleUngroupBooking: PropTypes.func.isRequired, // handle after click confirm Ungroup
  removeBookingGroup: PropTypes.func.isRequired, // handle after click confirm remove a booking out of group
  disableRemoveBtn: PropTypes.bool,
  isDelivery: PropTypes.bool,
};

export default GroupInfoBooking;
