import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QQUltis } from './ultis'
import {
    Image
} from 'react-bootstrap'
import close_icon from './../../assets/images/icons/close_icon.svg'

class Autocomplete extends Component {
    constructor(prop) {
        super();
        this.state = {
            Autocomplete: null
        }
    }
    componentDidMount() {
        if (this.refs.autocomplete_control && window.qq) {
            this.state.Autocomplete = new window.qq.maps.place.Autocomplete(this.refs.autocomplete_control);
            window.qq.maps.event.addListener(this.state.Autocomplete, 'confirm', this.confirmListener)
            if (this.props.autoFocus) {
                setTimeout(() => {
                    if (this.refs.autocomplete_control) {
                        this.refs.autocomplete_control.focus();
                    }
                }, 50)
            }
            if (this.props.inputRef && typeof this.props.inputRef == 'function') {
                this.props.inputRef(this.state.Autocomplete)
            }
        }
    }
    confirmListener = (e) => {
        if (this.props.onConfirm && typeof this.props.onConfirm == 'function') {
            if (this.state.Autocomplete.place) {
                this.props.onConfirm(e.value, QQUltis.fullAddressGeocode(this.state.Autocomplete.place))
            } else {
                this.props.onConfirm(e.value);
            }
        }
    }
    onChangeHandle = (e) => {
        if (this.props.onChange && typeof this.props.onChange == 'function') {
            this.props.onChange(e.target.value)
        }
    }
    onConfirmHandle = (e) => {

    }
    handleAddressRemoved = (e) => {
        this.refs.autocomplete_control.value = '';
        if (this.props.onChange && typeof this.props.onChange == 'function') {
            this.props.onChange('')
        }
        if (this.props.handleAddressRemoved && typeof this.props.handleAddressRemoved) {
            this.props.handleAddressRemoved(e);
        }
    }
    render() {
        const props = { ...this.props, onChange: this.onChangeHandle }
        delete props.classNames;
        delete props.autoFocus;
        let isCloseButton = props.value && !props.disabled
        if (props.closeButton) {
            isCloseButton = true
        }
        return (
            <div className={"autoplaces-input " + this.props.classNames}>
                <input ref='autocomplete_control' className='form-control form-custom' type='text' {...props} />
                {
                    (isCloseButton) && <Image
                        className="remove-icon"
                        src={close_icon}
                        onClick={this.handleAddressRemoved}
                    />
                }
            </div>
        )
    }
}

Autocomplete.propTypes = {
    onConfirm: PropTypes.func.isRequired
}

export default Autocomplete;