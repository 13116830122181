import React,{Component} from 'react';
import './validator.scss'
export default class WarningSelect extends Component{
    constructor(props) {
        super(props);
        const { value = null } = props || {};
        this.state = {
            value
        }
    }
    render() {
        const {
            message = '',
            className = '',
            disabled = false
            } = this.props || {};

        if (disabled) return null;
        return (
            <p className={className}>
                {
                    message
                }
            </p>
        )
    }
}