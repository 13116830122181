import { callApi, UrlBuilder } from '../utils/apiUtils';
import {
  updateAppBannerApi,
  activateAppBannerApi,
  createAppBannerApi,
  deactivateAppBannerApi,
  deleteAppBannerApi,
  fetchAppBannerApi,
  orderPriorityAppBannerApi
} from '../constants/ApiConfigs';


export function createAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(createAppBannerApi, config, null, null, null, true);
}
export function updateAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(updateAppBannerApi, config, null, null, null, true);
}

export function activeAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(activateAppBannerApi, config, null, null, null, true);
}

export function deactiveAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(deactivateAppBannerApi, config, null, null, null, true);
}
export function deleteAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(deleteAppBannerApi, config, null, null, null, true);
}

export function fetchAppBanner(options = {}) {
  const config = {
    method: 'get'
  };

  return callApi(UrlBuilder(fetchAppBannerApi, options), config, null, null, null, true);
}

export function orderPriorityAppBanner(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(orderPriorityAppBannerApi, config, null, null, null, true);
}
