import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import { convertFormat24h } from '../../utils/commonFunctions';
export const PartnerColumns = [
  {
    key: 'amount',
    label: 'mDispatcher.Amount',
    width: 120,
    fixed: true,
    textEllipsis: true,
    customCell: (data) => {
      return currencyFormatter.format(data.invoiceAmount || 0, {
        code: data.currencyIso,
      });
    },
  },
  {
    key: 'status',
    label: 'invoice.Status',
    width: 100,
    sortName: 'status',
  },
  {
    key: 'invoiceId',
    label: 'invoice.invoiceNumber',
    width: 140,
  },
  {
    key: 'numOfItems',
    label: 'invoice.noItem',
    width: 120,
    sortName: 'numOfItems',
  },
  {
    key: 'customerName',
    label: 'invoice.Client',
    width: 150,
    customCell: (data) => {
      let customerName = data.customerName;
      if (data.invoiceType === 1) customerName = data.billingName;
      return customerName;
    },
    textEllipsis: true,
    customCellAndEllipis: true,
  },
  {
    key: 'invoiceType',
    label: 'invoice.Type',
    width: 120,
    customCell: (data) => {
      let txt = '';
      switch (data.invoiceType) {
        case 0:
          txt = 'Individual';
          break;
        case 1:
          txt = 'Corporate';
          break;
        case 2:
          txt = 'Affiliate Supplier';
          break;
        default:
          break;
      }
      return txt;
    },
    sortName: 'invoiceType',
  },
  {
    key: 'dueTime',
    label: 'invoice.dueDate',
    width: 120,
    sortName: 'dueTime',
    customCell: (data) => {
      if (data.dueTime) return moment(data.dueTime).format('MMM DD');
      return '';
    },
    textEllipsis: true,
    sortName: 'dueTime',
  },
  {
    key: 'createdDate',
    width: 120,
    label: 'invoice.issuedDate',
    customCell: (data, index, fleetSetting) => {
      if (data.createdDate)
        return moment(data.createdDate).format(
          `MMM DD, ${convertFormat24h(fleetSetting.format24Hour)}`
        );
      return '';
    },
    sortName: 'createdDate',
  },
  {
    key: 'actions',
    label: 'mDispatcher.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
