import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { ValidCase, Validator } from '../../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  trimPhoneNumber,
} from '../../../../../utils/commonFunctions';
import { I18n, Translate } from 'react-redux-i18n';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as userActions from '../../../../../actions/userActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';
import * as settingActions from '../../../../../actions/settingActions';
import { Link } from 'react-router';
import IntlTelInputApp from '../../../../../components/intlTelInputCustome/IntlTelInputApp';

const SupplierUserForm = (props, context) => {
  const PERMISSION = useMemo(
    () => [
      {
        key: 'owner',
        label: I18n.t('supplier.owner'),
        note: I18n.t('supplier.ownerNote'),
      },
      {
        key: 'user',
        label: I18n.t('supplier.manageUsers'),
        note: I18n.t('supplier.manageUsersNote'),
      },
      {
        key: 'driver',
        label: I18n.t('supplier.manageDrivers'),
        note: I18n.t('supplier.manageDriversNote'),
      },
      {
        key: 'vehicle',
        label: I18n.t('supplier.manageVehicles'),
        note: I18n.t('supplier.manageVehiclesNote'),
      },
      {
        key: 'map',
        label: I18n.t('supplier.mapView'),
        note: I18n.t('supplier.mapViewNote'),
      },
      {
        key: 'calendar',
        label: I18n.t('supplier.calendarView'),
        note: I18n.t('supplier.calendarViewNote'),
      },
      {
        key: 'offers',
        label: I18n.t('supplier.offersView'),
        note: I18n.t('supplier.offersViewNote'),
      },
      {
        key: 'booking',
        label: I18n.t('supplier.bookingView'),
        note: I18n.t('supplier.bookingViewNote'),
      },
      {
        key: 'bookingCompleted',
        label: I18n.t('supplier.bookingCompletedView'),
        note: I18n.t('supplier.bookingCompletedViewNote'),
      },
      {
        key: 'pricing',
        label: I18n.t('supplier.pricing'),
        note: I18n.t('supplier.pricingNote'),
      },
    ],
    []
  );

  const FIELDS = useMemo(
    () => [
      { key: 'firstName', label: I18n.t('supplier.firstName') },
      { key: 'lastName', label: I18n.t('supplier.lastName') },
      { key: 'userName', label: I18n.t('supplier.userName') },
      { key: 'email', label: I18n.t('supplier.email') },
      { key: 'phone', label: I18n.t('supplier.phoneNumber') },
    ],
    []
  );

  const [detailItem, setDetailItem] = useState({});

  useEffect(() => {
    if (props.userId) {
      props.userActions
        .getOneUser({
          userId: props.userId,
          fleetId: props.auth.selectedFleet.fleetId,
        })
        .then((data) => {
          if (data.ok) {
            updateDataUser(data.res);
          } else {
            if (data.error) {
              context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              context.notification('error', I18n.t('user.Load_user_fail'));
            }
          }
        });
    }
  }, [props.userId]);

  useEffect(() => {
    if (props?.location?.query?.action === 'view') {
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    initDetailSupplier()
  }, [props.supplierId]);

  const [validState, setValidState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validPhone, setValidPhone] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const ValidatorCallback = (id, valid) => {
    const newValidState = { ...validState };
    if (newValidState && newValidState[id] !== valid) {
      newValidState[id] = valid;
      setValidState(newValidState);
    }
  };
  const checkPermissionUser =
    props?.auth?.user?.roles?.supplierPermission?.owner ||
    props?.auth?.user?.roles?.supplierPermission?.user ||
    !props?.auth?.user?.roles?.isSupplier;

  const updateDataUser = (newInfo) => {
    setFormData({
      ...formData,
      firstName: newInfo.firstName,
      lastName: newInfo.lastName,
      email: newInfo.email,
      phone: newInfo.phone,
      userName: newInfo.userName,
    });
    if (newInfo.supplierPermission) {
      setPermission({
        ...permision,
        ...newInfo.supplierPermission,
      });
    }
  };

  const [formData, setFormData] = useState(
    _.reduce(
      FIELDS,
      (acc, f) => {
        acc[f.key] = '';
        return acc;
      },
      {}
    )
  );

  const [permision, setPermission] = useState(
    _.reduce(
      PERMISSION,
      (acc, permission) => {
        acc[permission.key] = false;
        return acc;
      },
      {}
    )
  );

  const saveUser = async () => {
    if (isDisabled) {
      setDisabled(false);
      return;
    }

    if (!isSubmitted) {
      setIsSubmitted(true);
    }

    if (!CCLiteCommonFunc.isFormValid(validState)) {
      return;
    }

    props.loadingBarActions.hideLoadingSpiner();
    
    if (!detailItem?.supplierJobRequired) {
      delete permision.offers;
    }

    if (Object.values(permision).every((value) => value === false)) {
      context.notification('error', I18n.t('supplier.permissionRequired'));
      return;
    }
    const userData = {
      ...formData,
      phone: trimPhoneNumber(formData.phone),
      supplierPermission: permision,
      fleetId: props.auth.selectedFleet.fleetId,
      supplierId: props.supplierId,
    };
    if (props.userId) {
      // update user
      userData.userId = props.userId;
      const result = await props.userActions.updateUserSupplier(userData);
      if (result?.res?.userId) {
        context.notification('success', I18n.t('user.Update_user_success'));
        setDisabled(true);
      } else {
        if (data.error) {
          context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          context.notification('error', I18n.t('user.Update_user_fail'));
        }
      }
    } else {
      // create user
      const result = await props.userActions.addUserSupplier(userData);
      if (!result.ok) {
        if (result.error) {
          context.notification(
            'error',
            I18n.t('errors.' + result.error.errorCode)
          );
        } else {
          context.notification('error', I18n.t('user.Create_user_fail'));
        }
      } else {
        context.notification(
          'success',
          I18n.t('user.Create_user_success').format(
            result.res.email,
            result.res.email
          )
        );
        backUserList();
      }
    }
    setIsSubmitted(true);
  };

  const backUserList = () => {
    props.router.push(`/supplier/detail/${props.supplierId}/users`);
  };

  const handlePermissionChange = (e, key) => {
    let newPermission = {
      ...permision,
      [key]: e.target.checked,
    };

    if (key === 'owner') {
      newPermission = _.reduce(
        PERMISSION,
        (acc, permission) => {
          acc[permission.key] = e.target.checked;
          return acc;
        },
        {}
      );
    } else {
      delete newPermission.owner;
      newPermission['owner'] = Object.values(newPermission).every(
        (value) => value === true
      );
    }

    setPermission(newPermission);
  };

  const initDetailSupplier = async () => {
    try {
      const res = await props.settingActions.geDetailCompany({
        companyId: props.supplierId,
        fleetId: props.auth.selectedFleet.fleetId,
        isSupplier: true,
      });

      if (res.ok) {
        setDetailItem({
          ...res.res,
        });
      }
    } catch (error) {
    }
  };

  const renderPermission = () => {
    return (
      <div>
        <h2 style={{ marginTop: '20px', color: 'white' }}>
          {I18n.t('supplier.permissions')}
        </h2>
        <p style={{ color: '#98A2B3' }}>{I18n.t('supplier.permissionsNote')}</p>
        <div className="slPermission">
          {PERMISSION.map((p) => {
            if (
              p.key === 'offers' &&
              !detailItem?.supplierJobRequired
            ) {
              return <></>;
            }
            return (
              <CcCheckbox
                checked={permision[p.key]}
                onChange={(e) => handlePermissionChange(e, p.key)}
                text={p.label}
                note={p.note}
                key={p.key}
                id={p.key}
                disabled={!checkPermissionUser || isDisabled}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const handlePhoneChange = (
    status,
    value,
    countryData,
    number,
    id,
    isBlur
  ) => {
    setFormData({
      ...formData,
      phone: number,
    });
    setValidPhone(status);
  };

  const handleFieldsChange = (e, key) => {
    let val = e.target.value;
    if (key === 'userName') {
      val = (val || '').replaceAll(' ', '');
    }
    setFormData({
      ...formData,
      [key]: val,
    });
  };
  const renderField = () => {
    return (
      <Form.Row>
        {FIELDS.map((f) => (
          <Form.Group
            as={Col}
            md="4"
            className={`qup-input-group ${
              isSubmitted
                ? validState[f.key] === false
                  ? 'error'
                  : null
                : null
            }`}
            key={f.key}
          >
            <Form.Label>
              {f.label}
              {f.key != 'phone' && <span className="require">*</span>}
            </Form.Label>
            {f.key === 'phone' ? (
              <IntlTelInputApp
                css={['intl-tel-input', 'form-control']}
                utilsScript={'libphonenumber.js'}
                value={formData[f.key]}
                onPhoneNumberChange={handlePhoneChange}
                onPhoneNumberBlur={handlePhoneChange}
                disabled={!checkPermissionUser || isDisabled}
                id={f.key}
              />
            ) : (
              <Form.Control
                className="form-custom"
                type="text"
                value={formData[f.key]}
                autocomplete="off"
                onChange={(e) => handleFieldsChange(e, f.key)}
                placeholder={I18n.t('supplier.' + f.key)}
                disabled={
                  !checkPermissionUser ||
                  isDisabled ||
                  (f.key === 'userName' && props.userId ? true : false)
                }
              />
            )}
            <Validator id={f.key} callback={ValidatorCallback}>
              {f.key != 'phone' && (<ValidCase
                valid={!Validation.isStringEmpty(formData[f.key])}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmitted}
              />)}
              {f.key === 'email' && (
                <ValidCase
                  valid={Validation.validateEmail(formData[f.key])}
                  message={I18n.t(
                    'messages.commonMessages.invalid_email_format'
                  )}
                  hide={!isSubmitted}
                />
              )}
              {f.key === 'phone' && formData?.phone?.length && (
                <ValidCase
                  hide={!isSubmitted}
                  valid={validPhone}
                  message={I18n.t('driver.ERROR_INPUT_PHONE')}
                />
              )}
            </Validator>
          </Form.Group>
        ))}
      </Form.Row>
    );
  };

  const [confirmReset, setConfirmReset] = useState(false);
  const confirmResetPassword = async () => {
    if (props.userId) {
      const result = await props.userActions.resetPasswordUser({
        fleetId: props.auth.selectedFleet.fleetId,
        userId: props.userId,
      });

      if (result.ok && result.res) {
        context.notification('success', I18n.t('user.Reset_password_success'));
        setConfirmReset(false);
        setDisabled(true);
      } else {
        this.context.notification(
          'error',
          I18n.t('errors.' + result.error.errorCode)
        );
      }
    }
  };

  const renderConfirmDelete = () => {
    return (
      <Modal
        show={confirmReset}
        backdrop={true}
        className={'confirm'}
        onHide={() => setConfirmReset(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="user.RESET_PASSWORD" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setConfirmReset(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Translate value="user.CONFIRM_RESET_PASSWORD" />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button
            className={'btn-save mr-md'}
            onClick={() => confirmResetPassword()}
          >
            <Translate value="user.Yes" />
          </Button>
          <Button
            className={'btn-cancel'}
            onClick={() => setConfirmReset(false)}
          >
            <Translate value="user.No" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="createSupplierUser">
      {renderField()}
      {renderPermission()}
      {checkPermissionUser && (
        <div>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={backUserList}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          <Button
            style={{ padding: '6px 15px', minWidth: 'unset' }}
            className="btn-save ml-md mt-md"
            onClick={saveUser}
          >
            <Translate
              value={`${
                props.userId
                  ? isDisabled
                    ? 'supplier.editOperator'
                    : 'supplier.updateOperator'
                  : 'supplier.addOperator'
              }`}
            />
          </Button>
          {props.userId && (
            <Button
              className="btn-reset mt-md ml-md"
              onClick={() => setConfirmReset(true)}
              // disabled={isDisabled}
            >
              <Translate value="corporate.Reset_password" />
            </Button>
          )}
        </div>
      )}

      {renderConfirmDelete()}
    </div>
  );
};

SupplierUserForm.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierUserForm);
