import {
        FLEET_INFO_REQUEST,
        FLEET_INFO_SUCCESS,
        FLEET_INFO_FAILURE
    } from '../actions/settingActions'
export default function settings(state={}, action={}){
    switch(action.type){
        case FLEET_INFO_REQUEST:{
            return state;
        }
        case FLEET_INFO_SUCCESS:{
            return action.data
        }
        case FLEET_INFO_FAILURE:{
            return state;
        }
        default: return state;
    }
}