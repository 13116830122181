import React, { useState } from 'react'

const WeekDayList = [
  { text: 'Mo', key: 'mon'},
  { text: 'Tu', key: 'tue'},
  { text: 'We', key: 'wed'},
  { text: 'Th', key: 'thu'},
  { text: 'Fr', key: 'fri'},
  { text: 'Sa', key: 'sat'},
  { text: 'Su', key: 'sun'}
]

const WeekDays = ({ weekDays, onChangeWeekDays }) => {

  const onChange = (key) => {
    let newWeekDays = {...weekDays}
    newWeekDays[key] = !newWeekDays[key]
    onChangeWeekDays(newWeekDays)
  }
  
  return (
    <div className="group_days">
      {
        WeekDayList.map((day) => {
          const subClass = weekDays[day.key] ? 'checked' : '';
          return (
            <button className={subClass} onClick={() => onChange(day.key)}>
              {day.text}
            </button>
          )
        })
      }
    </div>
  )
}

WeekDays.defaultProps = {
  weekDays: {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false
  },
  onChangeWeekDays: () => {}
}

export default WeekDays