import React from 'react';
import { FormGroup, FormControl, Form, InputGroup } from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import { Validator, ValidCase } from '../../../../components/validator';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { Translate, I18n } from 'react-redux-i18n';
const ExtraWaitTime = (props) => {
  const {
    handleInputChange,
    isSubmited,
    valid,
    dialogData,
    auth,
    editable,
    ValidatorCallback,
    disabled,
  } = props;

  return (
    <>
      <FormGroupTitle>
        <Translate value="regularSetting.extraWaitTime" />
        <QuestionCircleTooltip
          text={
            <div>
              <Translate value="regularSetting.extraWaitTimeTooltip" />
              <Translate value="regularSetting.extraWaitTimeTooltip2" />
            </div>
          }
        />
      </FormGroupTitle>
      <FormGroup
        className={`direction-row from-to qup-input-group ${
          !isSubmited ? null : valid.fareWaitTimeNow === false ? 'error' : null
        }`}
      >
        <Form.Label>
          <Translate value="regularSetting.fareWaitTimeNow" />
        </Form.Label>
        <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            {auth.selectedFleet.currencies[0].symbol}
          </InputGroup.Text>
        </InputGroup.Prepend>
          <FormControl
            type="text"
            className={'form-custom'}
            onChange={(e) => {
              handleInputChange('fareWaitTimeNow', e);
            }}
            onBlur={(e) => {
              textboxNumberHelper.onBlurHandle(e, (e) => {
                handleInputChange('fareWaitTimeNow', e);
              });
            }}
            onFocus={(e) => {
              textboxNumberHelper.onfocusHandle(e, (e) => {
                handleInputChange('fareWaitTimeNow', e);
              });
            }}
            value={dialogData?.fareWaitTimeNow}
            placeholder={I18n.t('regularSetting.From')}
            disabled={disabled}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              /<Translate value="regularSetting.minute" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Validator
          id="fareWaitTimeNow"
          callback={ValidatorCallback}
          disabled={!editable}
        >
          <ValidCase
            valid={Validation.isNumber(dialogData.fareWaitTimeNow || 0)}
            message={I18n.t('messages.commonMessages.must_be_number')}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>
      <FormGroup
        className={`direction-row from-to qup-input-group ${
          !isSubmited
            ? null
            : valid.fareWaitTimeLater === false
            ? 'error'
            : null
        }`}
      >
        <Form.Label>
          <Translate value="regularSetting.fareWaitTimeLater" />
        </Form.Label>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              {auth.selectedFleet.currencies[0].symbol}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className={'form-custom'}
            onChange={(e) => {
              handleInputChange('fareWaitTimeLater', e);
            }}
            onBlur={(e) => {
              textboxNumberHelper.onBlurHandle(e, (e) => {
                handleInputChange('fareWaitTimeLater', e);
              });
            }}
            onFocus={(e) => {
              textboxNumberHelper.onfocusHandle(e, (e) => {
                handleInputChange('fareWaitTimeLater', e);
              });
            }}
            value={dialogData?.fareWaitTimeLater}
            placeholder={I18n.t('regularSetting.From')}
            disabled={disabled}
          />
          <InputGroup.Append>
            <InputGroup.Text>
              /<Translate value="regularSetting.minute" />
            </InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Validator
          id="fareWaitTimeLater"
          callback={ValidatorCallback}
          disabled={!editable}
        >
          <ValidCase
            valid={Validation.isNumber(dialogData.fareWaitTimeLater || 0)}
            message={I18n.t('messages.commonMessages.must_be_number')}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>
    </>
  );
};

export default ExtraWaitTime;
