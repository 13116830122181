import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment-timezone";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  FormGroup,
  FormControl,
  Form,
  Modal
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import {
  find,
  map,
  get,
  debounce,
  pick,
  forEach,
  filter,
  remove,
  cloneDeep
} from "lodash";
import StickyTable from "../../components/table/stickyTable/StickyTable";
import CCDropDown from "../../components/dropdown/ccDropDown";
import TableActions from "../../components/table/tableAction/TableActions";
import { loadMoreDrivers } from "../../actions/commonDataAction";
import {
  getWithdrawalRequests,
  resolveDrawalRequest,
  resolveMultiDrawalRequest
} from "../../actions/reportActions";
import { fields } from "./fields";
import CcCheckbox from "../../components/ccCheckbox/CcCheckbox";
import "./styles.scss";
import CurrencyFormater from "currency-formatter";
import Confirm from "../../components/confirm/Confirm";

const RequestActions = [
  { label: "withdrawal.approve", eventKey: "approve" },
  { label: "withdrawal.reject", eventKey: "reject" }
];

class WithdrawalRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footerData: {
        limit: 20,
        page: 0,
        total: 0
      },
      loading: false,
      list: [],
      summary: {},
      tableHeight: 500,
      query: {
        driver: ["all"],
        company: ["all"],
        currencyISO: ""
      },
      defaultCurrency: "",
      driverQuery: {
        limit: 50,
        page: 0,
        total: 0,
        list: [],
        selectedObj: null,
        search: "",
        hasMore: true,
      },
      confirmApprove: null,
      rejectParams: {
        reason: ""
      }
    };

    this.searchDriverDebounce = debounce(this.handleSearchDriver, 200);
  }

  componentDidMount() {
    const { zones, auth } = this.props;
    const defaultZone = find(zones || [], z => z.isDefault === true);
    const currency = defaultZone
      ? defaultZone.currency
      : auth.selectedFleet.currencies[0];
    this.handleSearchDriver("", null, true);
    this.setState(
      {
        query: {
          ...this.state.query,
          currencyISO: currency ? currency.iso : ""
        },
        defaultCurrency: currency ? currency.iso : ""
      },
      () => this.getWithdrawalRequests()
    );
  }

  handleSelectCompany = value => {
    const { query, driverQuery } = this.state;
    this.setState(
      {
        query: {
          ...query,
          company: [value],
          driver: ["all"]
        },
        driverQuery: {
          ...driverQuery,
          selectedObj: null
        }
      },
      () => this.handleSearchDriver("", null, true)
    );
  };

  handleSelectDriver = value => {
    if (!value) {
      this.setState(
        {
          query: {
            ...this.state.query,
            driver: ["all"]
          },
          driverQuery: {
            ...this.state.driverQuery,
            selectedObj: null
          }
        },
        () => this.handleSearchDriver("", null, true)
      );
    } else {
      this.setState({
        query: {
          ...this.state.query,
          driver: [value]
        },
        driverQuery: {
          ...this.state.driverQuery,
          selectedObj: find(this.state.driverQuery.list, o => o._id === value)
        }
      });
    }
  };

  handleScrollBottomDriverList = () =>
    this.handleSearchDriver(this.state.driverQuery.search, null);

  handleSearchDriver = (inputValue, callback, refresh = false) => {
    const { driverQuery, query } = this.state;
    const { loadMoreDrivers } = this.props;
    const { fleetId } = this.props.auth.selectedFleet;

    let params = pick(driverQuery, ["limit", "page", "search"]);
    params.fleetId = fleetId;
    params.companyId = query.company[0];

    if (refresh || inputValue !== driverQuery.search) {
      params.page = 0;
      params.search = inputValue;
    } else {
      params.page++;

      if (!driverQuery.hasMore)
        return callback && callback();
    }

    loadMoreDrivers(params).then(data => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          driverQuery: {
            ...driverQuery,
            ...data.res,
            search: inputValue,
            hasMore: data.res.list.length === params.limit,
            list:
              params.page > 0
                ? driverQuery.list.concat(data.res.list)
                : data.res.list
          }
        });
      }
      if (callback) callback();
    });
  };

  getDriverDropdownTitle = () => {
    const { driverQuery } = this.state;
    let title = "";

    if (!driverQuery.selectedObj) {
      title = I18n.t("report.query.common.all");
    } else {
      title = get(driverQuery.selectedObj, "fullName", "");
    }

    return (
      <Translate
        value="report.query.driver"
        className="dropdown-title"
        name={title}
      />
    );
  };

  handleSelectCurrency = value => {
    this.setState({
      query: {
        ...this.state.query,
        currencyISO: value
      }
    });
  };

  getWithdrawalRequests = (page, limit) => {
    this.setState({ loading: true });
    const { findRequest, auth } = this.props;
    const { footerData, query } = this.state;
    let params = {
      limit: limit || footerData.limit,
      page: page || parseInt(page) === 0 ? page : footerData.page,
      query: {
        ...query,
        fleetId: auth.selectedFleet.fleetId
      }
    };

    findRequest(params).then(data => {
      if (data.ok && data.res && data.res.list) {
        const response = data.res;
        this.setState({
          loading: false,
          footerData: {
            page: response.page,
            total: response.total,
            limit: response.limit
          },
          list: response.list,
          summary: response.summary || {}
        });
      } else {
        this.context.notification("error", I18n.t("withdrawal.getDataFailure"));
        this.setState({ loading: false });
      }
    });
  };

  handleNumItemsPerPageChange = limit => this.getWithdrawalRequests(0, limit);

  handlePaginationSelect = page => this.getWithdrawalRequests(page);

  handleMenuClick = (eventKey, data) => {
    switch (eventKey) {
      case "approve":
        this.handleConfirmApproveModal = this.approveOneRequest.bind(
          this,
          data
        );
        this.setState({
          confirmApprove: {
            body: I18n.t("withdrawal.message.approve").format(
              CurrencyFormater.format(data.amount, { code: data.currencyISO })
            ),
            closeButtonText: I18n.t("withdrawal.message.closeButtonText"),
            buttonTitle: I18n.t("withdrawal.message.buttonConfirmTitle")
          }
        });
        break;
      case "reject":
        const { auth } = this.props;
        let params = pick(data, [
          "referenceId",
          "currencySymbol",
          "receiverAccount",
          "amount",
          "driverName"
        ]);
        params.fleetId = auth.selectedFleet.fleetId;
        params.resolveType = "reject";
        this.setState({
          rejectParams: {
            ...params,
            reason: ""
          },
          showRejectModal: true
        });
        break;
      default:
        break;
    }
  };

  handleCloseConfirmModal = () =>
    this.setState({ confirmApprove: null, resolving: false });

  handleApproveMultiRequest = () => {
    if (this.state.resolving) return false;

    this.handleConfirmApproveModal = this.approveMultiRequest.bind(this);
    this.setState({
      confirmApprove: {
        body: I18n.t("withdrawal.message.approveMulti"),
        closeButtonText: I18n.t("withdrawal.message.closeButtonText"),
        buttonTitle: I18n.t("withdrawal.message.buttonConfirmTitle")
      }
    });
  };

  approveMultiRequest() {
    const { list, resolving } = this.state;

    if (resolving) return false;

    const { auth, resolveMultiRequest } = this.props;
    const requests = filter(list, r => r.checked === true);

    if (!requests.length)
      return this.context.notification(
        "error",
        "Please select request to approve"
      );

    const requestParams = {
      fleetId: auth.selectedFleet.fleetId,
      resolveType: "approve",
      requests: map(requests, item =>
        pick(item, [
          "referenceId",
          "currencySymbol",
          "receiverAccount",
          "amount",
          "driverName"
        ])
      )
    };

    this.setState(
      {
        resolving: true,
        confirmApprove: {
          ...this.state.confirmApprove,
          disabledConfirm: true
        }
      },
      () => {
        resolveMultiRequest(requestParams)
          .then(data => {
            this.setState({ confirmApprove: null, resolving: false }, () =>
              this.getWithdrawalRequests()
            );
            if (data.ok && data.res) {
              const failRequest = data.res.failRequests.length;
              const successRequest = data.res.successRequests.length;
              let messages = [];

              if (successRequest) {
                messages.push(
                  I18n.t("withdrawal.message.approveMultiSuccess").format(
                    successRequest,
                    requests.length
                  )
                );
              }

              if (failRequest) {
                messages.push(
                  I18n.t("withdrawal.message.approveMultiFail").format(
                    failRequest,
                    requests.length
                  )
                );
              }

              const notificationMsg = (
                <div>
                  {map(messages, (m, index) => {
                    return index === messages.length - 1 ? (
                      m
                    ) : (
                        <React.Fragment>
                          {m}
                          <br />
                        </React.Fragment>
                      );
                  })}
                </div>
              );

              this.context.notification(
                failRequest ? "error" : "success",
                notificationMsg
              );
            }
          })
          .catch(error =>
            this.setState({ confirmApprove: null, resolving: false })
          );
      }
    );
  }

  approveOneRequest(itemData) {
    if (this.state.resolving) return false;

    const { resolveRequest, auth } = this.props;
    let params = pick(itemData, [
      "referenceId",
      "currencySymbol",
      "receiverAccount",
      "amount",
      "driverName"
    ]);
    params.fleetId = auth.selectedFleet.fleetId;
    params.resolveType = "approve";

    this.setState(
      {
        resolving: true,
        confirmApprove: {
          ...this.state.confirmApprove,
          disabledConfirm: true
        }
      },
      () => {
        resolveRequest(params).then(data => {
          if (data.ok && data.res && data.res.success) {
            this.setState({ confirmApprove: null, resolving: false }, () => {
              this.context.notification(
                "success",
                I18n.t("withdrawal.message.approveSuccess")
              );
              this.getWithdrawalRequests();
            });
          } else {
            this.getWithdrawalRequests();
            this.setState({ confirmApprove: null, resolving: false }, () => {
              const errMessage = _.get(data, 'error.response.response.message', '')
              this.context.notification(
                "error",
                errMessage ? errMessage : I18n.t("withdrawal.message.approveFail")
              );
            });
          }
        });
      }
    );
  }

  handleCloseConfirmRejectModal = () =>
    this.setState({ showRejectModal: false, resolving: false });

  handleRejectRequest = () => {
    const { rejectParams, resolving } = this.state;
    const { resolveRequest } = this.props;

    if (resolving) return false;

    if (!rejectParams.referenceId) {
      return this.context.notification("error", "Can't get Reference #");
    }

    if (!rejectParams.reason.trim().length) {
      return this.context.notification(
        "error",
        I18n.t("withdrawal.message.pleaseInputReason")
      );
    }

    this.setState({ resolving: true }, () => {
      resolveRequest(rejectParams).then(data => {
        if (data.ok && data.res && data.res.success) {
          this.setState(
            {
              showRejectModal: false,
              resolving: false
            },
            () => {
              this.getWithdrawalRequests();
              this.context.notification(
                "success",
                I18n.t("withdrawal.message.rejectSuccess")
              );
            }
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("withdrawal.message.rejectFail")
          );
        }
      });
    });
  };

  resetFilter = () => {
    this.setState(
      {
        query: {
          driver: ["all"],
          company: ["all"],
          currencyISO: this.state.defaultCurrency
        },
        driverQuery: {
          ...this.state.driverQuery,
          hasMore: true,
          selectedObj: null
        }
      },
      () => {
        this.getWithdrawalRequests(0);
        this.handleSearchDriver("", null, true);
      }
    );
  };

  handleSelectAllClick = () => {
    const newList = cloneDeep(this.state.list);
    const pendingRequests = filter(newList, o => o.status === 'pending');
    const isSelectAll =
      pendingRequests.length &&
      filter(pendingRequests, r => r.checked).length === pendingRequests.length;

    if (isSelectAll) {
      forEach(newList, r => {
        if (r.status === 'pending') {
          r.checked = false;
        }
      });
    } else {
      forEach(newList, r => {
        if (r.status === 'pending') {
          r.checked = true;
        }
      });
    }

    const isCanApprovedMulti = this.checkCanApproveRequestChecked(newList);

    this.setState({
      list: newList,
      isCanApprovedMulti
    });
  };

  handleSelectRowClick = (checked, referenceId) => {
    let list = cloneDeep(this.state.list);
    forEach(list, r => {
      if (r.referenceId === referenceId) {
        r.checked = checked;
        return false;
      }
    });
    const isCanApprovedMulti = this.checkCanApproveRequestChecked(list);
    this.setState({ list, isCanApprovedMulti });
  };

  checkCanApproveRequestChecked = (requests) => {
    const requestChecked = requests.length && filter(requests, r => r.checked);
    return requestChecked.findIndex(o => o.status !== 'pending') === -1;
  }

  getTableColumns = () => {
    const { list, summary } = this.state;
    const { permissions, auth } = this.props;
    let tableComlumns = Object.assign([], fields);
    const pendingRequests = filter(list, o => o.status === 'pending');
    const selectAll =
      pendingRequests.length && filter(pendingRequests, o => o.checked).length === pendingRequests.length;

    if (permissions && !permissions.actions)
      remove(tableComlumns, col => col.key === "actions");

    forEach(tableComlumns, column => {
      switch (column.key) {
        case "checkBox":
          column.customHeader = columnKey => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={selectAll}
                onChange={this.handleSelectAllClick}
              />
            );
          };
          column.customCell = rowData => (
            <CcCheckbox
              className="table-selectbox"
              checked={rowData.checked}
              disabled={rowData.status !== 'pending'}
              onChange={e => {
                this.handleSelectRowClick(
                  e.target.checked,
                  rowData.referenceId
                );
              }}
            />
          );
          break;
        case "createdDate":
          column.columnFooter = <div>{summary.totalRequest}</div>;
          column.customCell = rowData => {
            return rowData.createdDate
              ? moment
                .parseZone(rowData.createdDate)
                .tz(auth.selectedFleet.timezone)
                .format("MM/DD/YYYY hh:mm A")
              : "";
          };
          break;
        case "currentBalance":
          column.customCell = rowData => {
            const currentBalance = CurrencyFormater.format(rowData[column.key], {
              code: rowData.currencyISO
            });
            return currentBalance;
          };
          break;
        case "amount":
          column.customCell = rowData => {
            const amount = CurrencyFormater.format(rowData[column.key], {
              code: rowData.currencyISO
            });
            return amount;
          };
          column.columnFooter = (
            <div>
              {CurrencyFormater.format(summary.totalAmount, {
                code: summary.currencyISO
              })}
            </div>
          );
          break;
        case "actions":
          column.customCell = (rowData, rowIndex) => rowData.status === 'pending' ? (
            <TableActions
              rowIndex={rowIndex}
              rowData={rowData}
              onSelect={this.handleMenuClick}
              totalRow={list.length}
              rowHeight={50}
              menuItems={RequestActions}
              tableHeight={this.state.tableHeight}
            />
          ) : null;
          break;
        default:
          break;
      }
      if (column && column.toolTip) {
        column.customHeader = () => {
          return (
            <OverlayTrigger
              overlay={(
                <Tooltip id="tooltip">
                  <Translate value={column.toolTip} />
                </Tooltip>
              )}
              placement="top"
              delayHide={150}
            >
              <div className={`${column.className ? column.className : ''}`}>{/* ` */}
                <Translate value={`withdrawal.${column.key}`} />
              </div>
            </OverlayTrigger>
          );
        };
      }
    });

    return tableComlumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 64,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  render() {
    const {
      footerData,
      query,
      driverQuery,
      list,
      loading,
      confirmApprove,
      rejectParams,
      showRejectModal,
      resolving,
      isCanApprovedMulti
    } = this.state;

    const { permissions, companies, suppliers, currencies } = this.props;

    let companyList = Object.assign([], [...companies, ...suppliers]);
    companyList.unshift({
      name: I18n.t("report.query.common.all"),
      _id: "all"
    });
    const selectedDriver =
      !query.driver || query.driver[0] === "all" ? [] : query.driver;
    const driverDropdownTitle = this.getDriverDropdownTitle();
    const multiSelect = list.length && filter(list, r => r.checked).length;

    return (
      <div className="content withdrawal">
        <div
          className="header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <div className="group-left">
            <CCDropDown
              id="company-dropdown"
              items={companyList}
              title={I18n.t("report.query.company")}
              selectedItems={query.company}
              valueKey="_id"
              labelKey="name"
              onSelect={this.handleSelectCompany}
              noTranslateLabel
            />
            <CCDropDown
              id="driver-dropdown"
              title={I18n.t("report.query.driver")}
              valueKey="_id"
              labelKey="fullName"
              items={driverQuery.list}
              selectedItems={selectedDriver}
              onSelect={this.handleSelectDriver}
              onSearchChange={this.searchDriverDebounce}
              onScrollBottom={this.handleScrollBottomDriverList}
              customTitle={
                <OverlayTrigger
                  overlay={
                    <Tooltip id={"driver-tooltip"}>
                      {driverDropdownTitle}
                    </Tooltip>
                  }
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  {driverDropdownTitle}
                </OverlayTrigger>
              }
              searchable
              async
              enableClearAll
              noTranslateLabel
            />
            <CCDropDown
              id="currency-dropdown"
              items={currencies}
              title={I18n.t("report.query.currency")}
              selectedItems={query.currencyISO ? [query.currencyISO] : []}
              valueKey="iso"
              labelKey="iso"
              onSelect={this.handleSelectCurrency}
              noTranslateLabel
            />
            <Button
              className="btn-save"
              onClick={() => this.getWithdrawalRequests(0)}
              disabled={loading}
            >
              <Translate value="report.query.view" />
            </Button>
            {/* <Button
              className="btn-header text-deactive-header"
              onClick={this.resetFilter}
            >
              <Translate value="customer.Clear" />
            </Button> */}
          </div>
          <div className="group-right">
            {(!permissions || permissions.actions) && multiSelect && isCanApprovedMulti ? (
              <Button
                className="btn-save"
                onClick={this.handleApproveMultiRequest}
                disabled={resolving}
              >
                <Translate value="withdrawal.approve" />
              </Button>
            ) : null}
          </div>
        </div>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={list}
            footerData={footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={50}
            getTableHeight={this.getTableHeight}
            footerHeight={40}
          />
        </div>
        <Confirm
          className="confirm-approve-request"
          confirm={confirmApprove}
          handleConfirmCloseClick={this.handleCloseConfirmModal}
          handleConfirmButtonClick={this.handleConfirmApproveModal}
        />
        <Modal
          show={showRejectModal}
          backdrop={"static"}
          dialogClassName="confirm-dialog"
          onHide={this.handleCloseConfirmRejectModal}
          className="confirm reject-widthdrawal-request"
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="withdrawal.rejectRequest" />
            </Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <FormGroup>
              <Form.Label>
                <Translate value="withdrawal.reason" />
                <span className="text-remove"> *</span>
              </Form.Label>
              <FormControl
                value={rejectParams.reason}
                type="text"
                className="form-custom"
                onChange={e =>
                  this.setState({
                    rejectParams: {
                      ...rejectParams,
                      reason: e.target.value
                    }
                  })
                }
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-cancel"
              onClick={this.handleCloseConfirmRejectModal}
            >
              <Translate value="customer.Cancel" />
            </Button>
            <Button
              className="btn-save"
              onClick={this.handleRejectRequest}
              disabled={resolving}
            >
              <Translate value="customer.Save" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

WithdrawalRequests.contextTypes = {
  notification: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  companies: state.commonData.companies,
  suppliers: state.commonData.suppliers,
  currencies: state.commonData.currencies,
  permissions: state.menuHandle.modulePermission,
  zones: state.commonData.mapZone
});

const mapDispatchToProps = dispatch => ({
  findRequest: bindActionCreators(getWithdrawalRequests, dispatch),
  resolveRequest: bindActionCreators(resolveDrawalRequest, dispatch),
  resolveMultiRequest: bindActionCreators(resolveMultiDrawalRequest, dispatch),
  loadMoreDrivers: bindActionCreators(loadMoreDrivers, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawalRequests);
