/* eslint-disable no-param-reassign */
import * as _ from 'lodash'
import {
  SET_BOOKINGS_IMPORT,
  UPDATE_BOOKING_IMPORT,
  SHOW_VALIDATION_POPUP,
  SET_NUMBERS_STATUS,
  RESET_VALUES,
  UNSET_VALUES,
} from '../actions/importBookingsAction'

const initialState = {
  corporateId: '',
  bookings: [],
  numberOfImported: 0,
  numberOfImportedSuccess: 0,
  numberOfValidated: 0,
  numberOfValid: 0,
  numberOfInvalid: 0,
  validated: false,
  imported: false,
  isImporting: false,
  isLoading: false,
  batchId: '',
  file: null,
  addresses: {},
  errorCode: '',
  errorData: {},
  showValidationErrorModal: false
}

export default function importBookings(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_BOOKING_IMPORT: {
      const bookings = [].concat(state.bookings)
      const updateBookings = [].concat(action.booking)
      updateBookings.forEach((booking) => {
        bookings[booking.no - 1] = booking
      })
      const invalid = bookings.reduce((s, b) => {
        if (b.status !== 'Valid' && b.status !== 'Success') {
          return s + 1
        }
        return s
      }, 0)

      const newState = bookings.reduce(
        (sum, booking) => {
          if (_.get(booking, ['_meta_', 'imported'])) {
            sum.numberOfImported += 1
            if (sum.numberOfImported + invalid === bookings.length) {
              sum.imported = true
              sum.isImporting = false
              sum.isLoading = false
            } else {
              sum.isImporting = true
            }
            if (_.get(booking, ['_meta_', 'importedSuccess'])) {
              sum.numberOfImportedSuccess += 1
            }
          }
          if (_.get(booking, ['_meta_', 'validated'])) {
            sum.numberOfValidated += 1
            if (sum.numberOfValidated === 1) {
              sum.isLoading = false
            }
            if (sum.numberOfValidated === bookings.length) {
              sum.validated = true
            }
            if (booking.status === 'Valid' || booking.status === 'Success') {
              sum.numberOfValid += 1
            } else {
              sum.numberOfInvalid += 1
            }
          }
          if (!sum.errorCode && _.get(booking, ['_meta_', 'errorCode'])) {
            sum.errorCode = _.get(
              booking,
              ['_meta_', 'errorCode'],
            )
            sum.errorData = _.get(
              booking,
              ['_meta_', 'errorData'],
              {}
            )
          }
          return sum
        },
        {
          imported: state.imported,
          validated: state.validated,
          isLoading: state.isLoading,
          isImporting: state.isImporting,
          numberOfImported: 0,
          numberOfImportedSuccess: 0,
          numberOfValidated: 0,
          numberOfValid: 0,
          numberOfInvalid: 0,
        }
      )
      if (newState.validated && newState.errorCode) {
        newState.showValidationErrorModal = true;
      }
      return { ...state, bookings, ...newState }
    }
    case SHOW_VALIDATION_POPUP: {
      return { ...state, ...action.data }
    }
    case SET_BOOKINGS_IMPORT: {
      return { ...state, bookings: action.bookings }
    }
    case SET_NUMBERS_STATUS: {
      const newState = _.omitBy(action.data, _.isNil)
      return { ...state, ...newState }
    }
    case UNSET_VALUES: {
      return {
        ...initialState,
        ...action.data,
      }
    }
    case RESET_VALUES: {
      return {
        ...state,
        bookings: state.bookings.map((b) => {
          _.set(b, ['_meta_','validated'], false);
          _.set(b, ['_meta_','imported'], false);
          return b
        }),
        numberOfImported: 0,
        numberOfImportedSuccess: 0,
        numberOfValidated: 0,
        numberOfValid: 0,
        numberOfInvalid: 0,
        validated: false,
        imported: false,
        isImporting: false,
        isLoading: false,
        ...action.data,
      }
    }
    default:
      return state
  }
}
