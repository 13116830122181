import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Modal,
    Button,
    Container,
    Row,
    Image,
    Col,
    FormGroup,
    Form
} from 'react-bootstrap';

import './viewphotocapture.scss';


const ViewPhotoCapture = (props) => {
    const { title, listItems, keyValue, showLabel = true } = props;

    return (
        <div className='booking-dispatch-logs'>
            <Modal
                show={props.show}
                backdrop="static"
                dialogClassName="confirm-dialog logs-modal"
                bsSize="lg"
                onHide={() => props.closeModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title ? I18n.t('bookingdetail.' + title) : ''}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        {listItems.map(item => {
                            let images = item[keyValue] || [];
                            return (
                                <FormGroup
                                    className={"recipients"}
                                >
                                    {showLabel &&
                                        <Form.Label>
                                            {keyValue === "collectedPhotos" ? "Merchant" : "Recipients"} name: {item.name} / phone: {item.phone}
                                        </Form.Label>
                                    }
                                    <Row>
                                        {images.length > 0 && images.map(image => {
                                            return (
                                                <Col xs={12} md={12}>
                                                    <a
                                                        href={image}
                                                        className="text-active"
                                                        target="_blank"
                                                    >
                                                        <Image className="image-photo-capture" src={image} rounded />
                                                    </a>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </FormGroup>
                            )
                        })}
                    </Container>
                </Modal.Body>

            </Modal>
        </div>
    )
}


function mapStateToProps(state, ownProp) {
    return {
        selectedFleet: state.auth.selectedFleet,
        user: state.auth.user
    };
};


export default connect(
    mapStateToProps
)(ViewPhotoCapture);