export const HomeAffiliate = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Home",
    value: "home"
  },
  {
    label: "cue.Affiliate",
    value: "affiliate"
  }
];
export const NetworkType = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.farmOut",
    value: "farmOut"
  },
  {
    label: "cue.roaming",
    value: "roaming"
  },
  {
    label: "cue.farmIn",
    value: "farmIn"
  },
  {
    label: "cue.InHouse",
    value: "inHouse"
  }
];
export const Service = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Transport",
    value: "transport"
  },
  {
    label: "cue.delivery",
    value: "delivery"
  },
  {
    label: "cue.intercity",
    value: "intercity"
  }
];
export const BookingType = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.now",
    value: "asap"
  },
  {
    label: "cue.reservation",
    value: "reservation"
  },
  {
    label: "cue.rideSharing",
    value: "rideSharing"
  },
  {
    label: "report.query.bookingTypeItem.intercity",
    value: "intercity"
  },
  {
    label: "report.query.bookingTypeItem.delivery",
    value: "delivery"
  },
  {
    label: "report.query.bookingTypeItem.batchDelivery",
    value: "batchDelivery"
  },
  {
    label: "cue.shuttle",
    value: "shuttle"
  }
];

export const BookingStatus = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Pending",
    value: "pending"
  },
  {
    label: "cue.Dispatching",
    value: "dispatching"
  },
  {
    label: "cue.Waiting_for_process",
    value: "action"
  },
  {
    label: "cue.Confirmed_reservation",
    value: "confirmed"
  },
  {
    label: "cue.Driver_on_the_way",
    value: "booked"
  },
  {
    label: "cue.Arrived_and_waiting",
    value: "arrived"
  },
  {
    label: "cue.Passenger_on_board",
    value: "engaged"
  },
  {
    label: "cue.Dropped_off",
    value: "droppedOff"
  },
  {
    label: "cue.allocated",
    value: "allocated"
  },
  {
    label: "cue.supplierAccepted",
    value: "supplierAccepted"
  }
];
export const BookingDeliveryStatus = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Waiting_for_process",
    value: "action"
  },
  {
    label: "cue.Order_accepted",
    value: "orderAccepted"
  },
  {
    label: "cue.Confirmed",
    value: "confirmedAccepted"
  },
  {
    label: "cue.Driver_on_the_way_to_sender",
    value: "driverSender"
  },
  {
    label: "cue.Driver_on_the_way_to_collect_cash",
    value: "driverCash"
  },
  {
    label: "cue.Driver_on_the_way_to_collect_goods",
    value: "driverGoods"
  },
  {
    label: "cue.otwMerchant",
    value: "otwMerchant"
  },
  {
    label: "cue.Arrived_waiting_for_sender",
    value: "arrivedSender"
  },
  {
    label: "cue.Arrived_waiting_to_collect_cash",
    value: "arrivedCash"
  },
  {
    label: "cue.arrivedAndWaitingToCollectItem",
    value: "arrivedAndWaitingToCollectItem"
  },
  {
    label: "cue.Delivered",
    value: "delivering"
  }
];
export const BookingStatusAll = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Pending",
    value: "pending"
  },
  {
    label: "cue.Dispatching",
    value: "dispatching"
  },
  {
    label: "cue.Waiting_for_process",
    value: "action"
  },
  {
    label: "cue.Confirmed_reservation",
    value: "confirmed"
  },
  {
    label: "cue.Driver_on_the_way",
    value: "booked"
  },
  {
    label: "cue.Arrived_and_waiting",
    value: "arrived"
  },
  {
    label: "cue.Passenger_on_board",
    value: "engaged"
  },
  {
    label: "cue.Dropped_off",
    value: "droppedOff"
  },
  {
    label: "cue.Order_accepted",
    value: "orderAccepted"
  },
  {
    label: "cue.Confirmed",
    value: "confirmedAccepted"
  },
  {
    label: "cue.Driver_on_the_way_to_sender",
    value: "driverSender"
  },
  {
    label: "cue.Driver_on_the_way_to_collect_cash",
    value: "driverCash"
  },
  {
    label: "cue.Driver_on_the_way_to_collect_goods",
    value: "driverGoods"
  },
  {
    label: "cue.otwMerchant",
    value: "otwMerchant"
  },
  {
    label: "cue.Arrived_waiting_for_sender",
    value: "arrivedSender"
  },
  {
    label: "cue.Arrived_waiting_to_collect_cash",
    value: "arrivedCash"
  },
  {
    label: "cue.arrivedAndWaitingToCollectItem",
    value: "arrivedAndWaitingToCollectItem"
  },
  /*{
    label: "Arrived_waiting_to_deliver_goods",
    value: "arrivedGoods"
  },*/
  {
    label: "cue.Delivered",
    value: "delivering"
  },
  {
    label: "cue.allocated",
    value: "allocated"
  },
  {
    label: "cue.supplierAccepted",
    value: "supplierAccepted"
  }
];

export const OfferStatus = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Pending",
    value: "pending"
  },
  {
    label: "cue.Dispatching",
    value: "dispatching"
  },
  {
    label: "cue.Confirmed_reservation",
    value: "confirmed"
  },
  {
    label: "cue.allocated",
    value: "allocated"
  },
]
export const FinishedStatus = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.No_show",
    value: "noShow"
  },
  {
    label: "cue.Canceled",
    value: "canceled"
  },
  {
    label: "cue.Completed",
    value: "completed"
  },
  {
    label: "cue.Incident",
    value: "incident"
  },
  {
    label: "cue.completedWithoutService",
    value: "completedWithoutService"
  },
  {
    label: "cue.partialCompleted",
    value: "partialCompleted"
  },
  {
    label: "cue.failed",
    value: "failed"
  }
];

export const PassengerType = [
  {
    label: "cue.All",
    value: -1
  },
  {
    label: "cue.VIP",
    value: 1
  },
  {
    label: "cue.Non_VIP",
    value: 0
  }
];

export const paidStatus = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "bookingdetail.pending",
    value: "pending"
  },
  {
    label: "bookingdetail.paid",
    value: "paid"
  },
  {
    label: "bookingdetail.partial",
    value: "partial"
  },
];

export const RideSharing = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Matching",
    value: "matching"
  },
  {
    label: "cue.Unmatching",
    value: "unmatching"
  }
];

export const SupportService = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.SOS",
    value: "sos"
  },
  {
    label: "cue.Non_SOS",
    value: "nonsos"
  }
];

export const RideType = [
  {
    label: "cue.All",
    value: "all"
  },
  {
    label: "cue.Corporate",
    value: "corporate"
  },
  {
    label: "cue.Individual",
    value: "individual"
  }
];

export const RideServices = [
  {
    value: "all",
    label: "cue.All"
  },
  {
    value: "regulation",
    label: "cue.regulation"
  },
  {
    value: "rideSharing",
    label: "cue.rideSharing"
  }
];

export const JobVisibility = [
  {
    value: 'all',
    label: 'cue.All'
  },
  {
    value: 'public',
    label: 'cue.jobPubic'
  },
  {
    value: 'private',
    label: 'cue.jobPrivate'
  }
];
