import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

export default class ConfirmModal extends React.Component {
  render() {
    const {
      confirm,
      closeModal,
      handleConfirmCloseClick,
      handleConfirmButtonClick,
    } = this.props;

    return (
      <Modal
        show={!!confirm}
        backdrop="static"
        className="confirm"
        onHide={closeModal}
      >
        {confirm.title ? (
          <Modal.Header>
            <Modal.Title>{confirm.title}</Modal.Title>
          </Modal.Header>
        ) : null}
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: confirm.body }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-reset" onClick={handleConfirmCloseClick}>
            {confirm.closeButtonText
              ? confirm.closeButtonText
              : I18n.t('cue.Close')}
          </Button>
          {confirm.buttonTitle ? (
            <Button className="btn-save" onClick={handleConfirmButtonClick}>
              {confirm.buttonTitle}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    );
  }
}
