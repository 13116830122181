import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Row,
  Col,
  Image,
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import { Validator, ValidCase } from '../../../components/validator';
import { Validation } from '../../../utils/commonFunctions';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import UploadFile from '../../../components/upload/upload';
import UploadAvatarTemplate from './UploadAvatarTemplate';
import CreditBalance from './CreditBalance';
import CashBalance from './CashBalance';
import { avatar } from '../../../constants/commondata';

import BankAccount from './BankAccount';
import './AddEditMerchantUser.scss';
import _ from 'lodash';

const IMAGE_ACCEPTED = '.jpg, .jpeg, .png';

function AddEditMerchantUser(props) {
  const {
    dialogData,
    merchantList = [],
    bankRequirement,
    selectedFleet,
    language,
    editable,
    showDialog,
    canUpdate = false,
    isSubmitted,
    isShowCreditWalletBalance,
    isShowCashWalletBalance,
    valid,
    phoneValidation,
    phoneExistValidation,
    ValidatorCallback,
    handleInputChange,
    handlePhoneChange,
    handleImageChange,
    handleRemoveBankDocumentChange,
    handleRemoveBankInfo,
    handleStoreChange,
    onSearchChange,
    onScrollBottom,
    closeFormModal,
    saveDialogForm,
    handleClickEditButton,
    handleClickCashWalletChange,
    handleClickCreditWalletChange,
    handleClickCashCancelWallet,
    handleClickCreditCancelWallet,
    handleWalletBalanceSuccess,
    handleClickResetPasswordButton,
    selectedCurrencyObj,
		permissions
  } = props;

  const [allowEditBankInfo, setAllowEditBankInfo] = useState(false);
  const [showRemoveBankInfo, setShowRemoveBankInfo] = useState(false);
  useEffect(() => {
    if (!dialogData.isBankVerified && editable) {
      setAllowEditBankInfo(true);
    }
    if (dialogData.isBankVerified && editable) {
      setShowRemoveBankInfo(true);
    } else {
      setShowRemoveBankInfo(false);
    }
  }, [dialogData]);

  const handleClickUploadImage = (changeFile, error, key) => {
    if (editable) {
      handleImageChange(changeFile, error, key);
    }
    return;
  };
	const renderCreditWalletButton = () => {
		if(permissions.changebalance) {
			return (
				<React.Fragment>
					<a
					onClick={() => {
							handleClickCreditWalletChange();
					}}
					href="javascript:void(0)"
					className="text-active"
					>
				{I18n.t('driver.Change_balance')}
				</a>
				</React.Fragment>
			)
		}
	};
	const renderCashWalletButton  = () => {
		if(permissions.changecashbalance) {
			return (<React.Fragment>
				<a
				onClick={() => {
						handleClickCashWalletChange();
				}}
				href="javascript:void(0)"
				className="text-active"
				>
				{I18n.t('driver.Change_balance')}
			</a>
			</React.Fragment>
			)
		}
	};
  const isHideBankForm = bankRequirement && bankRequirement.hideAll;
  const merchantCashWalletEnalble = _.get(
    dialogData,
    'merchantCashWallet.enable',
    false
  );
  const merchantCreditWalletEnalble = _.get(
    dialogData,
    'merchantCreditWallet.enable',
    false
  );

  const currMerchantId = dialogData.id ? dialogData?.merchantId?.id : dialogData.merchantId;
  const companyName = (dialogData.id ? dialogData?.companyId?.name : merchantList?.find(item => item.id === currMerchantId)?.companyId?.name) || '';

  return (
    <Modal
      show={true}
      backdrop={true}
      // onHide={closeFormModal}
      dialogClassName={'card-dialog'}
    >
      <Modal.Header className="text-center pd-t-30 pd-b-30" closeButton onHide={closeFormModal}>
        <Modal.Title>
          {dialogData.id ? (
            editable ? (
              <Translate value="merchant.EDIT_MERCHANT_USER" />
            ) : (
              <Translate value="merchant.MERCHANT_USER_DETAILS" />
            )
          ) : (
            <Translate value="merchant.ADD_MERCHANT_USER" />
          )}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeFormModal}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="display-flex justify-content-center align-items-start">
          <FormGroup
            className={`app-banner-wrapper ${
              !isSubmitted ? null : valid.avatar === false ? 'error' : null
            }`}
          >
            <div className="merchant-avatar-container">
              <Image
                className="merchant-avatar"
                src={dialogData.avatar ? dialogData.avatar : avatar.driver}
                circle
              />
              <UploadFile
                id="avatar"
                name="avatar"
                accepts={IMAGE_ACCEPTED}
                onChange={(data, error) =>
                  handleClickUploadImage(data, error, 'avatar')
                }
                disabled={!editable}
                customTemplate={UploadAvatarTemplate}
              />
            </div>
          </FormGroup>
          <div className="display-flex justify-content-start align-items-center" style={{display: 'flex', flexDirection: 'column'}}>
            <FormGroup
              className={`select-store-name ${
                !isSubmitted
                  ? null
                  : valid.merchantId === false
                  ? 'error'
                  : null
              }`}
            >
              <Form.Label>
                <Translate value="merchant.user.storeName" />
                <span className="require"> *</span>
              </Form.Label>
              {!!dialogData.id ? (
                <FormControl
                  className="form-custom"
                  type="text"
                  value={
                    dialogData.merchantId && dialogData.merchantId.name
                      ? dialogData.merchantId.name
                      : ''
                  }
                  placeholder={I18n.t('merchant.user.store')}
                  disabled={true}
                />
              ) : (
                <CCDropDown
                  id="merchant-dropdown"
                  items={merchantList}
                  title={I18n.t('merchant.user.Select_store')}
                  valueKey="id"
                  labelKey="name"
                  className={
                    'merchant-select-dropdown form-custom' +
                    (!dialogData.merchantId ? ' no-item-selected' : '')
                  }
                  onSelect={(e) => handleStoreChange(e)}
                  selectedItems={
                    dialogData.merchantId ? [dialogData.merchantId] : []
                  }
                  onSearchChange={onSearchChange}
                  onScrollBottom={onScrollBottom}
                  customTitle={
                    <span className="dropdown-title">
                      {dialogData.merchantName
                        ? dialogData.merchantName
                        : I18n.t('merchant.user.Select_store')}
                    </span>
                  }
                  disabled={false}
                  searchable
                  async
                  enableClearAll
                  noTranslateLabel
                />
              )}
              <Validator id="merchantId" callback={ValidatorCallback}>
                <ValidCase
                  hide={!isSubmitted}
                  valid={!Validation.isStringEmpty(dialogData.merchantId)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>

            <FormGroup className={'select-store-name'}>
              <Form.Label>
                <Translate value="message.Company" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={companyName}
                placeholder={I18n.t('message.Company')}
                disabled={true}
              />
            </FormGroup>
            
          </div>
        </div>
        <Row>
          <Col xs={12} md={isHideBankForm ? 12 : 6}>
            <FormGroupTitle>
              <Translate value="merchant.ACCOUNT_INFO" />
            </FormGroupTitle>
            <div className="form-group-info">
              <FormGroup
                className={
                  !isSubmitted
                    ? null
                    : valid.firstName === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="merchant.user.First_name" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={dialogData.firstName ? dialogData.firstName : ''}
                  onChange={(e) => handleInputChange(e, 'firstName')}
                  disabled={!editable}
                  autoFocus
                />
                <Validator id="firstName" callback={ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(dialogData.firstName)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>

              <FormGroup>
                <Form.Label>
                  <Translate value="merchant.user.Last_name" />
                  {/* <span className="require"> *</span> */}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={dialogData.lastName ? dialogData.lastName : ''}
                  onChange={(e) => handleInputChange(e, 'lastName')}
                  disabled={!editable}
                />
              </FormGroup>

              <FormGroup
                className={
                  !isSubmitted
                    ? null
                    : valid.phoneNumber === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="merchant.user.Phone_Number" />
                  <span className="require"> *</span>
                </Form.Label>
                <IntlTelInputApp
                  css={['intl-tel-input', 'form-control']}
                  utilsScript={'libphonenumber.js'}
                  value={dialogData.phoneNumber}
                  onPhoneNumberBlur={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    handlePhoneChange(
                      status,
                      value,
                      countryData,
                      number,
                      id,
                      true
                    );
                  }}
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    handlePhoneChange(
                      status,
                      value,
                      countryData,
                      number,
                      id,
                      false
                    );
                  }}
                  disabled={!editable}
                />
                <Validator id="phoneNumber" callback={ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(dialogData.phoneNumber)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={phoneValidation}
                    message={I18n.t('merchant.user.ERROR_INPUT_VALIDPHONE')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={phoneExistValidation}
                    message={I18n.t('merchant.user.ERROR_EXISTED_PHONE')}
                  />
                </Validator>
              </FormGroup>

              <FormGroup
                className={
                  !isSubmitted ? null : valid.email === false ? 'error' : null
                }
              >
                <Form.Label>
                  <Translate value="merchant.user.Email" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={dialogData.email ? dialogData.email : ''}
                  onChange={(e) => handleInputChange(e, 'email')}
                  placeholder={I18n.t('merchant.user.Email')}
                  disabled={!editable}
                />
                <Validator id="email" callback={ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(dialogData.email)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.validateEmail(dialogData.email)}
                    message={I18n.t(
                      'messages.commonMessages.invalid_email_format'
                    )}
                  />
                </Validator>
              </FormGroup>
            </div>
          </Col>
          {!isHideBankForm && (
            <Col xs={6} md={6}>
              <FormGroupTitle>
                <Translate value="merchant.BILLING_ADDRESS" />
              </FormGroupTitle>
              <BankAccount
                dialogData={dialogData}
                bankRequirement={bankRequirement}
                selectedFleet={selectedFleet}
                language={language}
                editable={allowEditBankInfo}
                isSubmitted={isSubmitted}
                valid={valid}
                ValidatorCallback={ValidatorCallback}
                handleInputChange={handleInputChange}
                handleClickUploadImage={handleClickUploadImage}
                handleRemoveBankDocumentChange={handleRemoveBankDocumentChange}
                showRemoveBankInfo={showRemoveBankInfo}
                handleRemoveBankInfo={handleRemoveBankInfo}
              />
            </Col>
          )}
        </Row>

        <Row>
          {merchantCreditWalletEnalble && (
            <Col xs={12} md={6}>
              <FormGroupTitle>
                <Translate value="merchant.CREDIT_WALLET" />
              </FormGroupTitle>
              <div className="form-group-info">
                <FormGroup>
                  <Form.Label>
                    <Translate value="driver.Balance" />
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={
                      currencyFormatter.format(_.get(dialogData, 'wallet.creditWallet.value', 0), {
                        code: _.get(
                          dialogData,
                          'wallet.creditWallet.currencyISO',
                          ''
                        )
                      })
                    }
                    disabled={true}
                  />
                  {renderCreditWalletButton()}
                  <CreditBalance
                    isShow={isShowCreditWalletBalance}
                    walletBalances={_.get(
                      dialogData,
                      'wallet.creditWallet',
                      {}
                    )}
                    currencies={[selectedCurrencyObj]}
                    merchant={dialogData}
                    handleClickCreditCancelWallet={
                      handleClickCreditCancelWallet
                    }
                    handleWalletBalanceSuccess={handleWalletBalanceSuccess}
                    // currency={selectedCurrencyObj}
                  />
                </FormGroup>
              </div>
            </Col>
          )}
          {merchantCashWalletEnalble && (
            <Col xs={6} md={6}>
              <FormGroupTitle>
                <Translate value="merchant.CASH_WALLET" />
              </FormGroupTitle>
              <div className="form-group-info">
                <FormGroup>
                  <Form.Label>
                    <Translate value="driver.Balance" />
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={
                      currencyFormatter.format(_.get(dialogData, 'wallet.cashWallet.currentBalance', 0), {
                        code: _.get(
                          dialogData,
                          'wallet.cashWallet.currencyISO',
                          ''
                        )
                      })
                    }
                    // onChange={e => handleInputChange(e, "lastName")}
                    disabled={true}
                  />
                  {renderCashWalletButton()}
                  <CashBalance
                    isShow={isShowCashWalletBalance}
                    walletBalances={_.get(dialogData, 'wallet.cashWallet', {})}
                    currencies={[selectedCurrencyObj]}
                    merchant={dialogData}
                    handleClickCashCancelWallet={handleClickCashCancelWallet}
                    handleWalletBalanceSuccess={handleWalletBalanceSuccess}
                    // currency={selectedCurrencyObj}
                  />
                </FormGroup>
              </div>
            </Col>
          )}
        </Row>

        <FormGroup className="qup-input-group">
          <p className="text">
            <Translate value="merchant.user.note_account_password_default" />
          </p>
        </FormGroup>
      </Modal.Body>

      <Modal.Footer>
        <div className="text-center">
          {canUpdate ? (
            editable ? (
              <Button className="btn-save mr-md" onClick={saveDialogForm}>
                <Translate value="mDispatcher.Save" />
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  handleClickEditButton('Edit', dialogData.id);
                }}
                className="btn-save mr-md"
              >
                <Translate value="mDispatcher.Edit" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={closeFormModal}>
            <Translate value="mDispatcher.Cancel" />
          </Button>
          {canUpdate && dialogData.id ? (
            <Button
              onClick={handleClickResetPasswordButton}
              className="ml-md btn-reset"
            >
              <Translate value="mDispatcher.Reset_Password" />
            </Button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditMerchantUser;
