import React from 'react';
import BaseReport from './../base';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as fieldMappings from './fields';
import * as settingActions from './../../../actions/settingActions';
import {
  prepaidPaymentMethods,
  transactionTypesCorporateBalance,
} from './../../../constants/commondata';
import {
  reportCorporateApi,
  reportPrepaidTopUpApi,
} from '../../../constants/ApiConfigs';

class ReportPrepaidTopUp extends React.Component {
  constructor() {
    super();
    this.state = {
      corporates: [],
    };
  }

  UNSAFE_componentWillMount() {
    if ((this.props.user || {}).corporateId) {
      this.setState({ corporates: false });
    } else {
      this.props.actions
        .fetchAllCorporateForSelection(this.props.selectedFleet.fleetId)
        .then((resp) => {
          let corporates = resp.res.map((doc) => {
            return {
              _id: doc._id,
              name: doc.companyInfo.name,
            };
          });
          this.setState({ corporates });
        });
    }
  }
  render() {
    return (
      <div className="content">
        <BaseReport
          tittle="Corporate prepaid"
          fieldMappings={fieldMappings}
          transactionType={transactionTypesCorporateBalance}
          apiurl={reportPrepaidTopUpApi}
          dateRange
          company={this.state.corporates}
          currency
          paymentMethod={prepaidPaymentMethods}
          search="report.query.search.prepaidTopUp"
          prepaidTopUp
          rowHeight={60}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportPrepaidTopUp);
