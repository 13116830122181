import React, { Component } from "react";
import { connect } from "react-redux";
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle'
import {
  FormGroup,
  FormControl
} from "react-bootstrap";
import { userType, SUPPLIER_TYPE } from "../../../constants/commondata";
import { Translate, I18n } from "react-redux-i18n";
import currencyFormatter from "currency-formatter";
import { CCLiteCommonFunc } from "../../../utils/commonFunctions";
import moment from "moment";
import VehicleSelection from './VehicleSelection';
import _ from "lodash";

class BookingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      selectedIndex: 0,
      lightboxIsOpen: false,
    };

    this.renderDriverCompany = this.renderDriverCompany.bind(this);
    this.renderCarTypeBookDetail = this.renderCarTypeBookDetail.bind(this);
    this.renderCarTypeNewBooking = this.renderCarTypeNewBooking.bind(this);
    this.timeModeRef = React.createRef();
  }

  renderDriverCompany() {
    const {
      driverCompany,
      handleCompanyDriverChange,
      commonData,
      locationPickUp,
      newBooking, // new booking common data
      user, // logged user
      selectedFleet, // current fleet
      data, // booking form data
      isEditable,
      companyList,
      corporate
    } = this.props;

    const bookDetail = !!data.bookId;
    const isPermissionCompany = !!user.roles.companyId;

    let disable = false;
    let companyId = driverCompany ? driverCompany._id : -1;
    let companyRender = companyList ? companyList : [...commonData.companies, ...commonData.suppliers];
    if (bookDetail) {
      disable = !isEditable(data);
      companyId = data.request.companyId;
      // #SL-27667
      // Trường hợp FleetAdmin hoặc FleetUser Assign 1 company mà corp setting ẩn thì disable  
      const userTypeLogin = _.get(user, 'userType', '')
      const supplierType = _.get(corporate, 'supplier.type', '')
      if (
        [userType.CorporateUser, userType.CorporateAdmin].includes(userTypeLogin)
        && supplierType === SUPPLIER_TYPE.customized
        && !companyRender.some(c => c._id === companyId)
      ) {
        companyRender = commonData.companies 
          && [...commonData.companies, ...commonData.suppliers].filter(c => c._id === companyId)
        disable = true
      }
    }


    const view = (
      <FormGroup>
        <FormControl
          as="select"
          className="form-custom"
          value={companyId}
          disabled={disable}
          onChange={handleCompanyDriverChange}
        >
          {isPermissionCompany && !bookDetail ? null : <option value={-1}>{I18n.t("newbooking.All_company")}</option>}
          {companyRender.map(c => {
            return (
              <option key={c._id} value={c._id}>
                {c.name}
              </option>
            );
          })}
        </FormControl>
      </FormGroup>
    );

    if (
      locationPickUp &&
      data.pricingType !== 1 &&
      (newBooking.locationService && !newBooking.locationService.crossZone)
    ) {
      // if authenticated user is fleet admin or fleet user, business is the same
      if (
        user.userType == userType.FleetAdmin ||
        user.userType == userType.FleetUser
      ) {
        return view;
      } else if (
        user.userType == userType.CorporateAdmin ||
        user.userType == userType.CorporateUser
      ) {
        // if authenticated user is corp admin or corp user, check if setting hide or show company
        if (!selectedFleet.generalSetting.hideProviderFromCorp) {
          return view;
        }
      }
    }
    return null;
  }

  renderCarTypeNewBooking() {
    const {
      cartypeSelected,
      //carType Props
      handleCarTypeChanged,
      //new Booking Props
      newBooking,
      locationDestination,
      locationPickUp,
      time,
      selectedFleet,
      data,
      nearestDriverList,
      etaFareMultiCar
    } = this.props;

    let vehicleTypes = newBooking.locationService && newBooking.locationService.vehicleType.filter(item => item.delivery) || []

    return locationPickUp && newBooking.locationService ? (
      <FormGroup>
        <VehicleSelection
          nearestDriverList={nearestDriverList}
          locationDestination={locationDestination}
          cartypeSelected={cartypeSelected}
          etaFareMultiCar={etaFareMultiCar}
          vehicleTypes={vehicleTypes}
          selectedFleet={selectedFleet}
          handleCarTypeChanged={handleCarTypeChanged}
          time={time}
          data={data}
        />
      </FormGroup>
    ) : (
      ''
    );
  }

  renderCarTypeBookDetail() {
    const {
      data,
      newBooking,
      locationPickUp,
      isEditable,
      handleCarTypeChanged,
      time,
      locationDestination,
      cartypeSelected,
      selectedFleet,
      etaFareMultiCar,
      isDisableWhenPrepaided
    } = this.props;
    let vehicleTypes =
      (newBooking.locationService &&
        newBooking.locationService.vehicleType.filter(
          (item) => item.delivery
        )) ||
      [];
      const menuId = _.get(data, 'deliveryInfo.menuId', 'bfc-menuid')
      // filter carType khác menuId với trường hợp book từ App
      if(menuId !== 'bfc-menuid') {
        vehicleTypes = vehicleTypes.filter(item => item.menuId === menuId)
      }
    return (data && newBooking.locationService && locationPickUp) ||
      CCLiteCommonFunc.isBookingStatusCompleted(data.status) ? (
      <VehicleSelection
        disabled={!isEditable(data) || isDisableWhenPrepaided}
        isBookDetail={true}
        locationDestination={locationDestination}
        isStatusCompleted={CCLiteCommonFunc.isBookingStatusCompleted(
          data.status
        )}
        data={data}
        nearestDriverList={[]}
        cartypeSelected={cartypeSelected}
        etaFareMultiCar={etaFareMultiCar}
        vehicleTypes={vehicleTypes}
        selectedFleet={selectedFleet}
        handleCarTypeChanged={handleCarTypeChanged}
        time={time}
        showAllVehilceBtn={this.props.showAllVehilceBtn}
        handleShowAllVehilceBtn={this.props.handleShowAllVehilceBtn}
      />
    ) : (
      ''
    );
  }

  handleSelectDate = e => {
    this.setState({ showDatePicker: false }, () =>
      this.props.handleTimeChanged(e)
    );
  };

  handleChangeHour = (e, hourValue, timeMode) => {
    const { selectedFleet: { format24Hour }, onChangePickUpHour } = this.props;
    if (!format24Hour) {
      if (parseInt(hourValue) === 12) {
        hourValue = timeMode === I18n.t("generalSetting.AM") ? "0" : "12";
      }
      else {
        hourValue = moment(`${hourValue}:00 ${timeMode}`, 'HH:mm A').format("HH");
      }
    }
    onChangePickUpHour({
      ...e,
      target: {
        ...e.target,
        value: hourValue
      }
    });
  }

  handleSelectHour = (e) => {
    let hourValue = e.target.value;
    const timeMode = this.timeModeRef.current ? this.timeModeRef.current.value : '';
    this.handleChangeHour(e, hourValue, timeMode);
  }

  handleSelectTimeMode = (e, time) => {
    const timeMode = e.target.value;
    let hourValue = timeMode === I18n.t("generalSetting.PM") ? time.format("HH") : time.format("hh");
    this.handleChangeHour(e, hourValue, timeMode);
  }

  toggleLightbox = (selectedIndex) => {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const {
      data,
      cartypeSelected,
      isEditable,
      isPickupTimeEditable,
    } = this.props;

    const bookDetails = !!data.bookId;

    let rideSharingDisabled = false;
    let superHelper = (CCLiteCommonFunc.isBookingStatusCompleted(data.status) && data.superHelper) ?
      true : cartypeSelected && cartypeSelected.superHelper
    let pickUpTimeDisabled =
      cartypeSelected && cartypeSelected.dispatchRideSharing || superHelper;


    if (bookDetails) {
      rideSharingDisabled = !isEditable(data) || data.pricingType;
      pickUpTimeDisabled = !isPickupTimeEditable(data) || !data.reservation;
    }

    return (
      <div>
        <FormGroupTitle>
          {I18n.t('newbooking.Vehicle_Selection')}
        </FormGroupTitle>
        {this.renderDriverCompany()}
        {bookDetails
          ? this.renderCarTypeBookDetail()
          : this.renderCarTypeNewBooking()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.i18n && state.i18n.locale ? state.i18n.locale : "en-US"
  };
}

export default connect(
  mapStateToProps,
  null
)(BookingInfo);
