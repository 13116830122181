import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';

import { Col, Row, Button, ButtonToolbar } from 'react-bootstrap';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { ModalConfirmEditServiceFees } from '../modals';
import { filterZonesBasedPermission } from '../../../../utils/commonFunctions';

class CustomZoneList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalZone: filterZonesBasedPermission(this.props.auth, this.props.commonData.mapZone, [...this.props.commonData.companies, ...this.props.commonData.suppliers]),
    };
  }

  handleEditZone = zone => {
    const { isChangeFormServiceFees } = this.props;
    if (isChangeFormServiceFees) {
      this.setState({
        confirmZone: zone,
        isShowModal: true
      });
    } else {
      this.doEditZone(zone)
    }

  };

  isActiveZone = zone => {
    const { activeZone } = this.state;
    return activeZone && zone._id === activeZone._id;
  };

  handleConfirmEdit = (zone) => {
    const { confirmZone } = this.state;
    this.doEditZone(confirmZone);
  };

  doEditZone = (activeZone) => {
    const { fleetId } = this.props.auth.selectedFleet;
    const { onEditServiceFees } = this.props;
    this.props.settingActions
      .getServiceFeesByZone({ fleetId: fleetId, zoneId: activeZone._id })
      .then(data => {
        onEditServiceFees(data.res, activeZone);
        this.setState({ activeZone, isShowModal: false });
      });
  }

  closeConfirmDialog = () => {
    this.setState({ isShowModal: false });
  };

  render() {
    const { isShowModal, activeZone } = this.state;
    return (
      <Row className="sub-controls">
        <Col md={12}>
          {isShowModal && activeZone && (
            <ModalConfirmEditServiceFees
              currentZone={activeZone.zoneName}
              closeConfirmDialog={this.closeConfirmDialog}
              handleConfirm={this.handleConfirmEdit}
            />
          )}
          <div className="additional-service-table-container">
            <table className="table table-no-pagination">
              <tbody>
                {(this.state.finalZone || []).map((zone, index) => {
                  return (
                    <tr
                      key={zone._id}
                      className={this.isActiveZone(zone) ? 'zone-active' : ''}
                    >
                      <td>
                        <p>{zone.zoneName}</p>
                      </td>
                      <td className="actions">
                        <div>
                          <a
                            onClick={() => {
                              this.handleEditZone(zone);
                            }}
                          >
                            <i className="fa fa-edit fa-2x" />
                          </a>
                        </div>

                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomZoneList);
