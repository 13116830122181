import React from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { loadMoreDrivers } from '../../../../actions/commonDataAction';

class DriverList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      txtSearch: '',
      page: 0,
      limit: 50,
      selectedDrivers: [],
      drivers: [],
      hasMore: true
    };

    this.searchDebounce = _.debounce(this.getDrivers, 500);
    this.loadMoreDebounce = _.debounce(this.getDrivers, 300);
  }

  componentDidMount() {
    const { loadMoreDrivers, selectedFleet } = this.props;
    const { limit, page, search } = this.state;

    loadMoreDrivers({
      limit,
      page,
      search,
      fleetId: selectedFleet.fleetId,
      companyId: 'all'
    }).then(data => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          drivers: data.res.list,
          hasMore: data.res.list.length >= limit
        });
      }
    });
  }

  handleSearchChange = e => {
    this.setState({ search: e.target.value }, () =>
      this.searchDebounce.apply(this)
    );
  };

  getDrivers = () => {
    const { selectedFleet, loadMoreDrivers } = this.props;
    let { search, limit, page, txtSearch, drivers, hasMore } = this.state;
    const searchTrim = search.trim();
    let params = {
      limit,
      fleetId: selectedFleet.fleetId,
      companyId: 'all',
      search: searchTrim,
      isActive: true,
    };

    if (searchTrim !== txtSearch) {
      params.page = 0;
    } else {
      if (!hasMore) return true;
      params.page = page + 1;
    }

    loadMoreDrivers(params).then(data => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          page: params.page,
          txtSearch: searchTrim,
          drivers:
            params.page > 0 ? drivers.concat(data.res.list) : data.res.list,
          hasMore: data.res.list.length >= params.limit
        });
      }
    });
  };

  handleScrollBottom = e => {
    let element = e.target;
    if (element) {
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        this.loadMoreDebounce.apply(this);
      }
    }
  };

  handleSelectDriver = e => {
    const { onChange } = this.props;
    const selectedOptions = _.filter(e.target.options, o => o.selected);
    const ids = _.map(selectedOptions, o => o.value)
    this.setState({
      selectedDrivers: _.map(selectedOptions, o => o.value),
    }, () => onChange(ids))
  }

  render() {
    const { selectedDrivers, drivers, search, disable } = this.state;

    return (
      <div className="driver-list">
        <FormGroup>
          <Form.Label>
            <Translate value="operationSetting.Driver" />
            <span className="require"> *</span>
          </Form.Label>
          <FormControl
            type="text"
            value={search}
            onChange={this.handleSearchChange}
            className="form-custom"
            placeholder={I18n.t('operationSetting.Search_driver_name')}
          />
        </FormGroup>
        <div>
          <FormControl
            as="select"
            multiple={true}
            onChange={this.handleSelectDriver}
            value={selectedDrivers}
            className="form-custom"
            disabled={disable}
            onScroll={this.handleScrollBottom}
          >
            {_.map(drivers, item => (
              <option key={item._id} value={item._id}>
                {item.fullName}
              </option>
            ))}
          </FormControl>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedFleet: state.auth.selectedFleet
});

const mapDispatchToProps = dispatch => ({
  loadMoreDrivers: bindActionCreators(loadMoreDrivers, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverList);
