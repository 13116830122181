import {
    MAP_DRIVER_REQUEST,
    MAP_DRIVER_SUCCESS,
    MAP_BOOKING_SUCCESS
} from '../actions/mapActions'
import {realtimeHelper} from '../utils/realTimeHelper'
var _ = require('lodash');

window.list = {}
const defaultState = {
    list: {},
    total: -1,
    page: -1,
    limit: 500,
    filter: {
        status: {
            bookIn: true,
            offered: true,
            booked: true,
            arrived: true,
            engaged: true,
            delivering: true,
            droppedOff: true,
            bookOff: true,
            waiting: true
        },
        company: -1,
        carType: -1,
        txtSearch: ''
    }
}
export default function map(state = defaultState, action = {}) {
    switch (action.type) {
        case MAP_DRIVER_REQUEST: {
            return state;
        }
        case MAP_DRIVER_SUCCESS: {
            realtimeHelper.MAP_DRIVER_SUCCESS(action.data);
            return state;
        }
        case MAP_BOOKING_SUCCESS:{
            realtimeHelper.MAP_BOOKING_SUCCESS(action.data)
            return state;
        }
        default: return state;
    }
}