import React from 'react';
import _ from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';
import Balance from './Balance';

export default class CreditWallet extends React.PureComponent {
  state = {
    isShowCustomerDeposit: false,
    balance: undefined,
    currencyISO: "",
    paxWallet: []
  };

  componentDidMount() {
    const { paxWallet } = this.props;
    if (paxWallet.length) {
      this.setState({
        paxWallet: paxWallet
      })
    } else{
      this.initPaxWallet()
    }
  };

  initPaxWallet = () => {
    const { currencies } = this.props;
    let converCurrencies = [];
    currencies.map(cur => {
      converCurrencies.push({currencyISO: cur.iso, value: 0})
    });
    this.setState({
      paxWallet: converCurrencies
    });
  };

  handleConfirmCloseClick = () => {
    this.setState({
      // confirm: null,
      isShowCustomerDeposit: false
    });
  }

  handleChangeBalanceClick = (balance, currencyISO) => {
    this.setState({
      isShowCustomerDeposit: true,
      balance: balance,
      currencyISO: currencyISO
    });
  };

  handleWalletBalanceSuccess = (walletBalance) => {
    let { paxWallet } = this.state;
    paxWallet.find(o => o.currencyISO === walletBalance.paxWallet.currencyISO).value = walletBalance.paxWallet.value;
    this.setState({
      isShowCustomerDeposit: false,
      paxWallet
    },
      () => {
        this.props.handleChangeBalance();
      }
    );
  };

  render() {
    const { userId, canTopupWallet } = this.props;
    const { isShowCustomerDeposit, paxWallet } = this.state;
    return (
      <div className="emergencyContact">
        <table className="table table-no-pagination cc-table-striped">
          <thead>
            <tr>
              <th>
                <Translate value="customer.currency" />
              </th>
              <th>
                <Translate value="customer.balance" />
              </th>
              <th>
                <Translate value="customer.changeBalance" />
              </th>
            </tr>
          </thead>
        </table>
        <div className="emergencyContact__contactList">
          <table className="table table-no-pagination cc-table-striped">
            <thead>
              <tr>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {!paxWallet || !paxWallet.length ? (
                <tr>
                  <td className="text-center" colSpan={2}>
                    <Translate value="reservation.noData" />
                  </td>
                </tr>
              ) : null}
              {(paxWallet || []).map(cur => (
                <tr key={cur.currencyISO}>
                  <td>{cur.currencyISO}</td>
                  <td>
                    {currencyFormatter.format(cur.value, {
                      code: cur.currencyISO
                    })}
                  </td>
                  <td>
                    <div className="changeBalanceBtn">
                      <Button
                        className="btn-save"
                        disabled={!canTopupWallet}
                        onClick={() => { this.handleChangeBalanceClick(cur.value, cur.currencyISO) }}
                      >
                        <Translate value="customer.change" />
                      </Button>
                      {canTopupWallet ? null : <span className="tooltiptext">{I18n.t(`customer.AccessDenied`)}</span>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-md">
          <Button className="btn-reset ml0" onClick={this.props.closeDialog}>
            <Translate value="bookingdetail.Close" />
          </Button>
        </div>
        <Balance
          isShow={isShowCustomerDeposit}
          balance={this.state.balance}
          currencyISO={this.state.currencyISO}
          userId={userId}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
          handleWalletBalanceSuccess={this.handleWalletBalanceSuccess}
        />
      </div>
    );
  }
}
