import React, { PureComponent } from 'react';
import { Button, Modal, Form, FormGroup, FormControl } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

const REASON_INFO = [
  {
    key: 'CANT_FULFILL_CUSTOMER_REQUEST',
    text: 'Can’t fulfill customer’s request'
  },{
    key: 'INCOMPLETE_BOOKING_DETAILS',
    text: 'Incomplete booking details'
  },{
    key: 'NO_AVAILABILITY',
    text: 'No availability'
  },{
    key: 'RATE_ERROR',
    text: 'Rate error'
  },{
    key: 'ROAD_CLOSURE',
    text: 'Road Closure'
  },{
    key: 'OTHER',
    text: 'Other'
  }
]
class FarmInConfirmationModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      reason: 'NO_AVAILABILITY'
    }
  }

  handleReasonChange = (e) => {
    this.setState({
      reason: e.target.value
    })
  }

  render() {
    const {
      farmInInfo,
    } = this.props;
    const {
      messageConfirm,
      showConfirmFarmIn,
      title,
      callback,
      closeConfirmFarmIn,
      textOkBtn,
      textCloseBtn,
      isCancel3rdBooking
    } = farmInInfo || {}
    return (
      <Modal
        show={showConfirmFarmIn}
        backdrop
        dialogClassName="confirm-dialog update-book-detail-modal"
        onHide={closeConfirmFarmIn}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mr-b-20'>{messageConfirm}</p>
          {
            isCancel3rdBooking && (
              <FormGroup>
                <Form.Label>
                  <Translate value="newbooking.reasonCancel3rd" />
                </Form.Label>
                <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={this.state.reason}
                    onChange={this.handleReasonChange}
                  >
                    {
                      REASON_INFO.map(rs => (
                        <option value={rs.key}>{rs.text}</option>    
                      ))
                    }
                  </FormControl>
              </FormGroup>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => callback(REASON_INFO.find(rs => rs.key === this.state.reason))}
            className="btn-save"
          >
            {textOkBtn}
          </Button>
          <Button onClick={closeConfirmFarmIn} className="btn-cancel">
            {
              textCloseBtn 
              ? textCloseBtn
              : <Translate value="bookingdetail.Back" />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FarmInConfirmationModal;
