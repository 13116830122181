import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as flightActions from '../../../actions/flightActions';
import * as loadingActions from '../../../actions/loadingBarActions';
import _ from 'lodash';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  InputGroup,
  Button,
  Radio,
  ButtonToolbar,
  DropdownButton,
  
  Modal,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Tab,
  Tabs
} from 'react-bootstrap';

import Autosuggest from 'react-autosuggest';
import { I18n, Translate } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';

class FlightInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupFlight: false,
      flightInfo: {},
      flightScheduleSuggestions: []
    };
  }

  handleFlightNumerChange = (e, suggest) => {
    let value = e.target.value || '';
    if (suggest) {
      value = suggest.newValue;
    }
    this.props.intercityBookingActions.updateCommonData({
      flightService : { 
        ...this.props.flightService,
        flightNumber: value.replace(/\s/g, '').toUpperCase(),
      }
    });
  }

  handleMeetdriverChange = e => {
    this.props.intercityBookingActions.updateCommonData({
      flightService : {
        ...this.props.flightService,
        meetDriver: e.target.value === "0" ? 0 : 1
      }
    });
  };

  isFromAirPort = () => {
    const { locationPickUp, locationService = {}, extraDestination } = this.props;
    const { extraAirport: extraAirports = [] } = locationService || {};
    const extraAirport = (extraAirports || []).find(extraAP => extraAP && !!extraAP._id);
    const isActiveAirport =locationService
      && locationService.additionalService
      && locationService.additionalService.fromAirport
      && locationService.additionalService.fromAirport.isActive;
    const isAirportBook = (locationService
      && locationService.fromAirport
      && locationService.fromAirport._id)
      || (locationService
        && locationService.airport
        && locationService.airport[0])
      || (extraAirport && extraAirport._id);

    return (locationPickUp || extraDestination) && isActiveAirport && isAirportBook;
  };

  isToAirPort = () => {
    const { locationDestination, locationService = {}, extraDestination } = this.props;
    const { extraAirport: extraAirports = [] } = locationService || {};
    const extraAirport = (extraAirports || []).find(extraAP => extraAP && !!extraAP._id);
    const isActiveAirport = locationService
      && locationService.additionalService
      && locationService.additionalService.toAirportNew
      && locationService.additionalService.toAirportNew.isActive;
    const isAirportBook = (locationService
      && locationService.toAirport
      && locationService.toAirport._id)
      || (locationService
        && locationService.airport
        && locationService.airport[1])
      || (extraAirport && extraAirport._id);

    return (locationDestination || extraDestination) && isActiveAirport && isAirportBook;
  };

  renderFlightScheduleSuggestion = (suggestion, { query }) => {
    const isFromAirPort = this.isFromAirPort();
    const showTime = isFromAirPort ? suggestion.arrivaltime : suggestion.departuretime;
    return (
      <div className="suggestion-content">
        <p className="name">
          {suggestion.iata + suggestion.flightNumber}
          (
          {suggestion.originIata}
          -
          {suggestion.destinationIata}
          )
        </p>
        <p className="name">
          {moment
            .tz(
              showTime * 1000,
              this.props.locationService.fromAirport
                ? this.props.locationService.fromAirport.timezone
                : this.props.locationService.toAirport.timezone
            )
            .format('DD-MMM-YYYY hh:mmA Z')}
        </p>
      </div>
    );
  };

  showPopupFlightInfo = () => {
    this.setState({ showPopupFlight: true })
    let queryBody = {
      fleetId: this.props.fleetId,
      ident: this.props.flightInfo.ident,
      departuretime: this.props.flightInfo.departuretime
    }
    if (this.props.faFlightID) queryBody.faFlightID = this.props.faFlightID
    this.props.flightActions.getInfoFlight(queryBody).then(data => {
      if (data.ok && data.res) {
        this.setState({ flightInfo: data.res })
        console.log(data.res)
      }
    });
  }

  closeDialog = () => {
    this.setState({ showPopupFlight: false })
  }

  getFlightScheduleSuggestionValue = suggestion => suggestion.iata + suggestion.flightNumber;

  escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  flightScheduleAplyButtonClick = () => {
    const { flightNumber, auth, selectedFleet, locationService = {}, common } = this.props
    const {pickupDate, pickupTime} = common
    // const { locationService } = 
    if (this.props.flightSchedule) {
      // this.state.flight = null;
      // this.state.data.airline = '';
      // this.state.flightNumber = '';
      this.props.intercityBookingActions.updateCommonData({
        flightService : {
          ...this.props.flightService,
          flightNumber: '',
          flightSchedule: null
        }
      });
      this.setState({
        flightScheduleSuggestions: []
      });
    } else {
      const escapedValue = this.escapeRegexCharacters(
        flightNumber.replace(/\s/g, '')
      ).toUpperCase();

      if (escapedValue === '') {
        return [];
      }

      const regex = /^[\D]*[0-9]+$/g;
      if (!regex.test(escapedValue)) {
        this.context.notification('error', I18n.t('messages.booking.Invalid_format'));
        return;
      }

      let pickupTimeSubmit = 'Now'
      if(pickupDate && pickupTime) {
        pickupTimeSubmit = moment(pickupDate)
              .hour(pickupTime.hour)
              .minute(pickupTime.minute)
              .format('YYYY-MM-DD HH:mm')
      }

      const IATA = escapedValue.replace(new RegExp('[0-9]', 'g'), '');
      if (IATA.length > 0) {
        if (this.lastAutoCompleteRequestId !== null) {
          clearTimeout(this.lastAutoCompleteRequestId);
        }
        const searchObject = {
          fleetId: selectedFleet.fleetId,
          airportIcao: locationService && locationService.fromAirport
            ? locationService.fromAirport.icao
            : locationService.toAirport.icao,
          direction: locationService.fromAirport ? 'destination' : 'origin',
          airlineIcao: IATA,
          // pickUpTime:
          //   this.state.data.time == 'Now'
          //     ? 'Now'
          //     : moment(this.state.data.time).format('YYYY-MM-DD HH:mm'),
          pickUpTime: pickupTimeSubmit,
          flightNumber: escapedValue.replace(IATA, ''),
          timezone: locationService.fromAirport
            ? locationService.fromAirport.timezone
            : locationService.toAirport.timezone
        };

        if (escapedValue.length == 0) {
          this.setState({
            flightScheduleSuggestions: []
          });
        }

        this.props.loadingActions.showLoadingSpiner();
        this.props.flightActions.getFlightSchedules(searchObject).then(data => {
          if (data.ok && data.res) {
            this.setState({
              flightScheduleSuggestions: data.res
            });
            if (data.res.length == 0) {
              this.context.notification('error', I18n.t('messages.booking.Flight_not_found'));
            }
          } else {
            this.context.notification('error', I18n.t('messages.booking.Flight_not_found'));
          }
          this.props.loadingActions.hideLoadingSpiner();
        });
      } else {
        this.context.notification('error', I18n.t('messages.booking.Invalid_format'));
      }
    }
  };

  // Flight name auto complete
  flightScheduleAutocompleteSlectedHandle = (
    event,
    {
      suggestion, suggestionValue, suggestionIndex, sectionIndex, method
    }
  ) => {
    let flightNumber = suggestion.iata + suggestion.flightNumber;
    this.props.intercityBookingActions.updateCommonData({
      flightService : {
        ...this.props.flightService,
        flightNumber: flightNumber,
        flightSchedule: suggestion
      }
    });
    this.setState({
      ...this.state,
      flightScheduleSuggestions: []
    });
  };


  // getFlightScheduleSuggestions = value => this.state.flightScheduleSuggestions;
  onFlightScheduleSuggestionsFetchRequested = ({ value }) => {
    // this.setState({
    //   flightScheduleSuggestions: this.getFlightScheduleSuggestions(value)
    // });
  };

  onFlightScheduleSuggestionsClearRequested = () => {
    this.setState({
      flightScheduleSuggestions: []
    });
  };

  render() {
    const {
      locationService = {},
      selectedFleet,
      flightNumber,
      flightService = {},
      isViewOnly = false,
      isBookDetail = false,
      flightSchedule
    } = this.props;
    const { flightScheduleSuggestions } = this.state
    const meetDriver = flightService.type || 0 
    let meetGreetFee = null;
    let onCurbFee = null;
    let flightInfo = this.state.flightInfo
    let flightInfoProp = this.props.flightInfo || {}
    let lastHistory = flightInfo.lastHistory || {}
    let departuretimeDay = "";
    let departuretimeHour = "";
    let showIconFlight = false;
    let estimateDay = ""
    let estimateHour = ""
    let actualDay = ""
    let actualHour = ""
    let terminalGateDept = ""
    let terminalGateArr = ""
    let arrivaltimeDay = ""
    let arrivaltimeHour = ""
    estimateDay = moment(flightInfoProp.arrivaltime * 1000).format('MMMM DD YYYY');
    const bookingCurrency = locationService && locationService.currency
      ? locationService.currency
      : selectedFleet.currency;
    estimateHour = moment(flightInfoProp.arrivaltime * 1000).format('h:mm A');
    if (!_.isEmpty(flightInfo)) {
      arrivaltimeDay = moment(flightInfo.filed_arrivaltime * 1000).format('MMMM DD YYYY');
      arrivaltimeHour = moment(flightInfo.filed_arrivaltime * 1000).format('h:mm A');
      actualDay = (flightInfo.actualdeparturetime > 0 ?
        moment(flightInfo.actualdeparturetime * 1000).format('MMMM DD YYYY')
        : "")
      actualHour = (flightInfo.actualdeparturetime > 0 ?
        moment(flightInfo.actualdeparturetime * 1000).format('h:mm A')
        : "")
      terminalGateDept = "Terminal " + flightInfo.terminal_orig + " - " + "Gate " + flightInfo.gate_orig
      terminalGateArr = "Terminal " + flightInfo.terminal_dest + " - " + "Gate " + flightInfo.gate_dest
    }

    if (flightInfo.filed_departuretime > 0) {
      departuretimeDay = moment(flightInfo.filed_departuretime * 1000).format('MMMM DD YYYY')
      departuretimeHour = moment(flightInfo.filed_departuretime * 1000).format('h:mm A')
    } else {
      departuretimeDay = moment(flightInfoProp.departuretime * 1000).format('MMMM DD YYYY')
      departuretimeHour = moment(flightInfoProp.departuretime * 1000).format('h:mm A')
    }

    if ((selectedFleet?.process?.flight && flightInfoProp.faFlightID)
      || (flightInfoProp.ident && flightInfoProp.departuretime)) {
      showIconFlight = true
    }

    if (
      this.isFromAirPort()
      && locationService.additionalService.fromAirport.meetDriver
    ) {
      meetGreetFee = locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies
        && locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0]
        ? locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0].value
        : 0;

      onCurbFee = locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies
        && locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0]
        ? locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0].value
        : 0;
    }

    const pricingType = locationService.crossZone ? 1 : 0

    const isAirport = this.isFromAirPort() || this.isToAirPort();
    if (!isAirport) return '';

    let disable = false;
    if (isBookDetail) disable = isViewOnly || pricingType;

    return (
      <div>
        {(this.isFromAirPort()
          && locationService.additionalService.fromAirport.flightInfo)
          || (this.isToAirPort()
            && locationService.additionalService.toAirportNew.flightInfo) ? (
            <FormGroup>
              <FormGroupTitle>
                <Translate value="newbooking.FLY_INFO" />
                {showIconFlight && <i className={"fa fa-info-circle mr-l-5"} onClick={this.showPopupFlightInfo} />}
              </FormGroupTitle>
              {selectedFleet.moduleSettings.flightAPIIntegration ? (
                <FormGroup>
                  <div className="add-on-input-group">
                    <Autosuggest
                      className="schedule-input"
                      suggestions={flightScheduleSuggestions}
                      onSuggestionsFetchRequested={this.onFlightScheduleSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onFlightScheduleSuggestionsClearRequested}
                      getSuggestionValue={this.getFlightScheduleSuggestionValue}
                      renderSuggestion={this.renderFlightScheduleSuggestion}
                      onSuggestionSelected={this.flightScheduleAutocompleteSlectedHandle}
                      alwaysRenderSuggestions={
                        flightSchedule
                          ? false
                          : !!(flightScheduleSuggestions && flightScheduleSuggestions.length > 0)
                      }
                      inputProps={{
                        className: 'form-control form-custom',
                        value: flightNumber || '',
                        onChange: this.handleFlightNumerChange,
                        placeholder: I18n.t('newbooking.FlightExample'),
                        disabled: (!!flightSchedule) || disable,
                        maxLength: 100
                      }}
                    />
                    {flightNumber && !disable ? (
                      <span
                        variant={flightSchedule ? 'warning' : 'success'}
                        className="schedule-button add-on-button"
                        onClick={this.flightScheduleAplyButtonClick}
                        disabled={!flightNumber || flightNumber.length == 0}
                      >
                        {flightSchedule ? I18n.t('newbooking.Remove') : I18n.t('bookingdetail.Apply')}
                      </span>
                    ) : (
                        ''
                      )}
                  </div>
                </FormGroup>
              ) : (
                  <FormGroup>
                    <FormControl
                      type="text"
                      placeholder={I18n.t('newbooking.FlightExample')}
                      value={flightNumber}
                      className="form-custom"
                      onChange={this.handleFlightNumerChange}
                      maxLength={100}
                      disabled={disable}
                    />
                  </FormGroup>
                )}
            </FormGroup>
          ) : null}
        {/* {this.isFromAirPort()
          && locationService.additionalService.fromAirport.meetDriver ? (
            pricingType == 1 ? (
              <FormGroup>
                <FormControl
                  as="select"
                  placeholder={I18n.t('newbooking.Meet_driver')}
                  className="form-custom"
                  value={meetDriver}
                  disabled={disable}
                  onChange={this.handleMeetdriverChange}
                >
                  <option key={1} value="1">
                    {I18n.t('newbooking.Meet_Greet_option')}
                  </option>
                  <option key={2} value="0">
                    {I18n.t('newbooking.On_Curb_option')}
                  </option>
                </FormControl>
              </FormGroup>
            ) : (
                <FormGroup>
                  <FormControl
                    as="select"
                    placeholder={I18n.t('newbooking.Meet_driver')}
                    className="form-custom"
                    value={meetDriver}
                    disabled={disable}
                    onChange={this.handleMeetdriverChange}
                  >
                    <option key={1} value={1}>
                      {I18n.t('newbooking.Meet_Greet_option')}
                      {' '}
                      =
                    {' '}
                      {currencyFormatter.format(meetGreetFee, { code: bookingCurrency.iso })}
                    </option>
                    <option key={2} value={0}>
                      {I18n.t('newbooking.On_Curb_option')}
                      {' '}
                      =
                    {' '}
                      {currencyFormatter.format(onCurbFee, { code: bookingCurrency.iso })}
                    </option>
                  </FormControl>
                </FormGroup>
              )
          ) : (
            ''
          )} */}
        <Modal
          show={this.state.showPopupFlight}
          backdrop="static"
          onHide={this.closeDialog}
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="card-dialog"
          className="confirm flightInfoModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {flightNumber}
            </Modal.Title>
            <button type="button" className="close" aria-label="Close" onClick={this.closeDialog}><span aria-hidden="true">×</span></button>
          </Modal.Header>
          <Modal.Body className="clearfix popupFlight text">
            {(lastHistory.eventcode == "change") ? (
              <div>
                <p className="fl-mes">{lastHistory.long_desc}</p>
                <p className="fl-status">{lastHistory.eventcode}</p>
              </div>
            ) : ""
            }
            <div className="col-flight">
              <span><b>Departure</b></span>
              <span><b>Arrival</b></span>
            </div>
            <div className="col-flight">
              <span><b>Scheduled:</b><br /> {departuretimeDay} <br /> {departuretimeHour}</span>
              <span><b>Scheduled:</b><br /> {arrivaltimeDay} <br /> {arrivaltimeHour}</span>
            </div>
            <div className="col-flight">
              <span><b>Actual:</b><br /> {actualDay} <br /> {actualHour}</span>
              <span><b>Estimated:</b><br /> {estimateDay} <br /> {estimateHour}</span>
            </div>
            <div className="col-flight">
              <span><b>Terminal - Gate:</b><br /> {terminalGateDept}</span>
              <span><b>Terminal - Gate:</b><br /> {terminalGateArr}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="fill ">
              <Button
                className="btn-reset mr-md"
                onClick={this.closeDialog}
              >
                <Translate value="driver.Close" />
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    common: state.intercityBooking.common,
    location: state.commonData.location,
    locationPickUp: state.intercityBooking.common.pickupLocation,
    locationDestination: state.intercityBooking.common.destinationLocation,
    flightService: state.intercityBooking.common.flightService,
    flightNumber: state.intercityBooking.common.flightService.flightNumber,
    flightSchedule: state.intercityBooking.common.flightService.flightSchedule,
    pickupAddress: state.intercityBooking.common.pickupAddress,
    pickupFrom: state.intercityBooking.common.pickupFrom,
    routeSelected: state.intercityBooking.common.routeSelected,
  };
}

FlightInfo.contextTypes = {
  notification: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
    flightActions: bindActionCreators(
      flightActions,
      dispatch
    ),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightInfo);
