import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import * as appBanerActions from '../../../actions/appBanerActions';

import '../settings.scss';
import BannerList from './BannerList';

class GeneralSetting extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div
        className="content custom-form overflow-auto general-setting"
        ref={ref => (this.refTabs = ref)}
      >
        <Tabs id="tab" defaultActiveKey="pax" animation={false} className="cue-tabs" unmountOnExit>
          <Tab eventKey="pax" title={I18n.t('appBanner.pax')}>
            <BannerList appType="pax" parentRef={this.refTabs} />
          </Tab>
          <Tab eventKey="driver" title={I18n.t('appBanner.driver')}>
            <BannerList appType="driver" parentRef={this.refTabs} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appBanerServices: bindActionCreators(appBanerActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSetting);
