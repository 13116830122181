/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportMerchantCreditWalletApi } from '../../../constants/ApiConfigs';
import * as settingActions from '../../../actions/settingActions';
import { merchantCreditTransactionTypes } from '../../../constants/commondata';

class ReportMerchantCreditTransactionHistory extends React.Component {
  render () {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Merchant Report',
      Export: true,
    });
    const checkOwner = !this.props.user.isAdmin
      && typeof this.props.user.roles.fleetId !== 'undefined'
      ? this.props.user.roles.fleetId.ownerId === this.props.user._id
      : true;
    checkExport = this.props.user.isAdmin || checkOwner ? true : checkExport !== -1;
    if (
      !this.props.user.isAdmin
      && this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    return (
      <div className="content">
        <BaseReport
          tittle="Merchant Credit Transaction history"
          fieldMappings={fieldMappings}
          apiurl={reportMerchantCreditWalletApi}
          currency
          dateRange
          company
          companyId
          storeMerchant={{
            title: 'report.query.merchant',
            selectedLabel: 'report.query.selectedMerchant'
          }}
          multiSelectionsFilters={[{
            options: merchantCreditTransactionTypes,
            title: 'report.query.transactionType',
            key: 'transactionTypes'
          }]}
          noexport={!checkExport}
          search="report.query.search.merchantCreditTransactionHistory"
        />
      </div>
    );
  }
}
function mapStateToProps (state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}
function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMerchantCreditTransactionHistory);
