import { initialServerApi } from '../constants/ApiConfigs';
import axios from 'axios';
const handleSetFavicon = (faviconUrl) => {
  if (faviconUrl) {
    const favicon = document.createElement('link');
    favicon.rel = 'shortcut icon';
    favicon.href = faviconUrl;
    const head = document.head || document.getElementsByTagName('head')[0];
    const oldFavicon = document.querySelector('link[rel="shortcut icon"]');
    if (oldFavicon) {
      head.removeChild(oldFavicon);
    }
    head.appendChild(favicon);
  } else {
    const favicon = document.createElement('link');
    favicon.rel = 'shortcut icon';
    favicon.href = './favicon.ico';
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(favicon);
  }
};

const handleLoadSettingServer = () => {
  axios
    .post(
      initialServerApi,
      { clientUrl: window.location.origin },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => {
      if (res?.data?.res?.favicon) {
        handleSetFavicon(res.data.res.favicon);
      } else {
        handleSetFavicon('');
      }
    })
    .catch((error) => handleSetFavicon(''));
};

export default handleLoadSettingServer;
