import * as _ from 'lodash'
import { socketConfigs } from '../../constants/socketConfigs'
import { socketDispatchApi } from '../../utils/socketUtils'
import geocoding from './geocoding'

async function handleDeliveryBooking(booking, index) {
  const rq = Object.assign({}, booking)
  rq.no = index + 1
  let errorCode = '';

  if (rq.status !== 'Valid') {
    _.set(rq, ['_meta_', 'validated'], true)
    this.props.importBookingsAction.updateBookings(rq)
    return rq
  }
  const recipients = booking.deliveryInfo.recipients
  const puPoint = await geocoding.call(
    this,
    booking.deliveryInfo.pickup.address.address
  )
  const recipientPoints = await Promise.all(
    recipients.map((i) => geocoding.call(this, i.address.address))
  )
  if (puPoint.success) {
    rq.status = 'Valid';
  } else {
    rq.status = puPoint.status
    errorCode = 'invalidSenderAddress'
  }
  rq.deliveryInfo.pickup.address = _.extend(
    booking.deliveryInfo.pickup.address,
    puPoint.success ? puPoint.data : {}
  )

  if (recipientPoints.length) {
    rq.deliveryInfo.recipients = rq.deliveryInfo.recipients.map(
      (recipient, i) => {
        if (recipientPoints[i].success) {
          recipient.address = _.extend(
            recipient.address,
            recipientPoints[i].data
          )
        } else {
          rq.status = 'Invalid'
          errorCode = 'invalidRecipientAddress'
          recipient.isValid = false
        }
        return recipient
      }
    )
  }
  if (rq.status === 'Valid') {
    rq.jupiter = { jobType: 'delivery' }
    socketDispatchApi.emit(socketConfigs.send.validateBooking, {
      batchId: this.props.importBookings.batchId,
      jobType: 'delivery',
      booking: rq,
    })
  } else {
    _.set(rq, ['_meta_', 'validated'], true)
    _.set(rq, ['_meta_', 'errorCode'], errorCode)
  }
  this.props.importBookingsAction.updateBookings(rq)
  return rq
}

async function handleTransportBooking(booking, index) {
  if (booking.status !== 'Valid') {
    _.set(booking, ['_meta_', 'validated'], true)
    this.props.importBookingsAction.updateBookings(booking)
    return booking
  }
  const puPoint = await geocoding.call(this, booking.request.pickup.address)
  const doPoint = await geocoding.call(
    this,
    booking.request.destination.address
  )
  const rq = Object.assign({}, booking)

  rq.no = index + 1

  rq.status = puPoint.success ? 'Valid' : puPoint.status
  rq.puPoints = [
    {
      address: puPoint.success ? puPoint.data : booking.request.pickup,
      seats: booking.request.seats,
      luggage: booking.request.luggages,
      notes: booking.request.notes,
    },
  ]
  rq.request.pickup = puPoint.success
    ? puPoint.data
    : { address: booking.pickup }

  rq.doPoints = []
  if (doPoint) {
    rq.doPoints.push({
      address: doPoint.success ? doPoint.data : booking.request.destination,
    })
    rq.request.destination = doPoint.success
      ? doPoint.data
      : booking.request.destination
  }
  if (rq.status === 'Valid') {
    socketDispatchApi.emit(socketConfigs.send.validateBooking, {
      batchId: this.props.importBookings.batchId,
      booking: rq,
    })
  } else {
    _.set(rq, ['_meta_', 'validated'], true)
  }
  this.props.importBookingsAction.updateBookings(rq)
  return rq
}

export default async function validateAndTransformBooking(booking, index) {
  if (this.state.bookingType === 'transport') {
    return handleTransportBooking.call(this, booking, index)
  } else {
    return handleDeliveryBooking.call(this, booking, index)
  }
}
