import moment from 'moment'
import ReportUtils from './../base/utils'

export const fields = [{
  key: "month",
  label: "report.result.revenueMonthly.month",
  summary: "month",
  mutate: value => {
    return moment(value, 'MMMM YYYY').format('MM/DD/YYYY')    
  }
}, {
  key: "trip",
  label: "report.result.revenueMonthly.trip",
  summary: "trip"
}, {
  key: "subtotal",
  label: "report.result.revenueMonthly.subtotal",
  summary: "subtotal"
}, {
  key: "tipAmt",
  label: "report.result.revenueMonthly.tipAmt",
  summary: "tipAmt"
}, {
  key: "fleetServiceFee",
  label: "report.result.revenueMonthly.fleetServiceFee",
  summary: "fleetServiceFee"
}, {
  key: "techFee",
  label: "report.result.revenueMonthly.techFee",
  summary: "techFee"
}, {
  key: "salesTax",
  label: "report.result.revenueMonthly.salesTax",
  summary: "salesTax"
}, {
  key: "partnerCommission",
  label: "report.result.revenueMonthly.partnerCommission",
  summary: "partnerCommission"
}, {
  key: "totalFare",
  label: "report.result.revenueMonthly.totalFare",
  summary: "totalFare"
}, {
  key: "netEarning",
  label: "report.result.revenueMonthly.netEarning",
  summary: "netEarning",
  negativable: true
}]

export const fareFields = [
  "subtotal",
  "tipAmt",
  "techFee",
  "salesTax",
  "partnerCommission",
  "totalFare",
  "netEarning",
  'fleetServiceFee'
]