import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';
import { walletMerchantBalance } from '../../../actions/merchantActions';
import * as loadingBarActions from './../../../actions/loadingBarActions';
import {
  FormGroup,
  Alert,
  InputGroup,
  Form,
  Modal,
  FormControl,
  Button,
  DropdownButton,
  
} from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
class CashBalance extends React.Component {
  constructor() {
    super();
    this.state = {
      balance: '',
      operator: 'true',
      amount: '',
      newBalance: '',
      reason: '',
      valid: {},
      isSubmitted: false,
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleBalanceChange = this.handleBalanceChange.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.walletBalances && nextProps.merchant) {
      this.setState({
        isShow: nextProps.isShow,
        walletBalances: nextProps.walletBalances,
        currencies: nextProps.currencies,
        merchant: nextProps.merchant,
        balance:
          (nextProps.currencies &&
            nextProps.currencies.length &&
            nextProps.currencies[0] &&
            nextProps.currencies[0].iso) ||
          '',
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleBalanceChange(e) {
    this.setState({ balance: e.target.value });
  }

  handleOperatorChange(e) {
    this.setState({ operator: e.target.value === 'true' });
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value });
  }

  handleSaveClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    let amount = parseFloat(this.state.amount);
    if (!this.state.operator) {
      amount = 0 - amount;
    }
    this.props
      .walletMerchantBalance({
        fleetId: this.props.fleetId,
        merchantId: this.state.merchant.merchantId.id,
        merchantName: this.state.merchant.merchantId.name,
        name: this.state.merchant.fullName,
        phone: this.state.merchant.phone.full,
        amount: amount,
        walletType: 'cash',
        currencyISO: this.state.walletBalances.currencyISO,
        reason: this.state.reason,
      })
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res.returnCode == 200) {
          this.props.handleWalletBalanceSuccess(data.res.response);
          this.setState({
            balance: '',
            operator: 'true',
            amount: '',
            newBalance: '',
            reason: '',
            valid: {},
            isSubmitted: false,
          });
          this.context.notification(
            'success',
            I18n.t('merchant.INFO_ADD_BALANCE_SUCCESS')
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('messages.credits.' + data.res.returnCode)
          );
        }
      });
  }

  handleCancelClick() {
    this.props.handleClickCashCancelWallet();
    this.setState({
      balance: '',
      operator: 'true',
      amount: '',
      newBalance: '',
      reason: '',
      valid: {},
      isSubmitted: false,
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    if (this.state.isShow) {
      if (this.state.currencies && this.state.walletBalances) {
        const { valid, isSubmitted } = this.state;
        let chosenCreditValue = this.state.walletBalances.currentBalance;
        let operatorValue = null;
        let amount = Validation.isNumber(this.state.amount)
          ? parseFloat(this.state.amount)
          : 0.0;
        if (!Validation.isStringEmpty(this.state.balance)) {
          if (this.state.operator) {
            operatorValue = (amount + parseFloat(chosenCreditValue)).toFixed(2);
          } else {
            operatorValue = (parseFloat(chosenCreditValue) - amount).toFixed(2);
          }
        }
        return (
          <Modal
            show={true}
            onHide={this.props.closeDialog}
            backdrop="static"
            size="sm"
            aria-labelledby="contained-modal-title-sm"
            dialogClassName="change-balance-dialog"
            className="confirm"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-sm">
                <Translate value="driver.CASH_WALLET_DEPOSIT" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCancelClick}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className="clearfix">
              <FormGroup
                className={
                  isSubmitted
                    ? valid.balance === false
                      ? 'error'
                      : null
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Balance" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="select"
                  value={this.state.balance}
                  onChange={this.handleBalanceChange}
                  className="form-custom"
                >
                  {
                    <option
                      key={this.state.walletBalances.currencyISO}
                      value={this.state.walletBalances.currencyISO}
                    >
                      {this.state.walletBalances.currencyISO} :{' '}
                      {this.state.walletBalances.currentBalance}
                    </option>
                  }
                </FormControl>
                <Validator id="balance" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.balance)}
                    message={I18n.t('driver.ERROR_INPUT_BALANCE')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  isSubmitted ? (valid.amount === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Adjust_amount" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup className="single-addon-left">
                    <FormControl
                      style={{ width: '85%' }}
                      type="text"
                      value={this.state.amount}
                      onChange={this.handleAmountChange}
                    />
                    <FormControl
                      as="select"
                      value={this.state.operator}
                      style={{ width: '15%' }}
                      onChange={(e) => {
                        this.handleOperatorChange(e);
                      }}
                      className="form-custom input-group-addon-custom"
                    >
                      <option key={0} value={'true'}>
                        +
                      </option>
                      <option key={1} value={'false'}>
                        -
                      </option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator id="amount" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.amount)}
                    message={I18n.t('driver.ERROR_INPUT_AMOUNT')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      Validation.isNumber(this.state.amount) &&
                      parseFloat(this.state.amount) > 0
                    }
                    message={I18n.t('driver.ERROR_INPUT_VALID_AMOUNT')}
                  />
                </Validator>
              </FormGroup>
              {operatorValue ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="driver.New_balance" />
                  </Form.Label>
                  <FormGroup>
                    <span className="text-active">
                      {this.state.balance} : {operatorValue}
                    </span>
                  </FormGroup>
                </FormGroup>
              ) : null}
              <FormGroup
                className={`mb0 ${
                  isSubmitted ? (valid.reason === false ? 'error' : null) : null
                }`}
              >
                <Form.Label>
                  <Translate value="driver.Enter_reason" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="textarea"
                  value={this.state.reason}
                  onChange={this.handleReasonChange}
                  className="form-custom"
                />
                <Validator id="reason" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.reason)}
                    message={I18n.t('driver.ERROR_INPUT_REASON')}
                  />
                </Validator>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <div className="fill text-center">
                <Button
                  className="btn-save mr-md"
                  onClick={this.handleSaveClick}
                >
                  <Translate value="driver.Save" />
                </Button>
                <Button className="btn-cancel" onClick={this.handleCancelClick}>
                  <Translate value="driver.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
CashBalance.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    fleetId: state.auth.selectedFleet.fleetId,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    walletMerchantBalance: (options) => {
      return dispatch(walletMerchantBalance(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CashBalance);
