import { I18n } from 'react-redux-i18n';
import React from 'react';
import ReportUtils from '../base/utils';
import { Localize } from 'react-redux-i18n';

export const fields = [
  {
    key: 'referrer',
    label: 'report.result.driverRefersDriver.referrer',
    borderRight: true,
    rowSpan: 2,
    fixed: true
  },
  {
    key: 'phoneNumber',
    label: 'report.result.driverRefersDriver.phoneNumber',
  },
  {
    key: 'username',
    label: 'report.result.driverRefersDriver.username',
    isShow: 'driverUserName'
  },
  {
    key: 'companyName',
    label: 'report.result.driverRefersDriver.companyName',
  },
  {
    key: 'referralCode',
    label: 'report.result.driverRefersDriver.referralCode',
  },
  {
    key: 'numberOfReferees',
    label: 'report.result.driverRefersDriver.numberOfReferees',
    summary: 'numberOfReferees'
  },
  {
    key: 'activeReferees',
    label: 'report.result.driverRefersDriver.activeReferees',
    summary: 'activeReferees'
  },

  {
    label: 'report.result.referralHistory.detail',
    key: 'driverReferrerId',
    link: {
      label: 'report.result.referralHistory.showDetail',
      newTab: true,
      href: '/reports/Driver_refers_drivers/Referee',
      name: 'goToListReferee',
      params: ['driverId', 'driverName','driverReferrerId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'List all driver which referred by this driver'
    }
  }
];

/* export const fareFields = ['subtotal', 'referralEarning']; */
