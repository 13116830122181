import React from 'react';
import { Translate } from 'react-redux-i18n';

export default ({
  data,
  columnKey,
  customHeader,
  className,
  width,
  sortType,
  sortName,
  sortHandle,
  isFooter
}) => (
  <div
    className={
      'table-header stickyTableCellContainer ' + (className ? className : '')
    }
  >
    <div className="cellTable_row">
      <div className="cellTable_cell">
        <div className={'cell_content'} style={width ? { width: width + 'px'}: null}>
          {customHeader ? (
            isFooter ? (
              customHeader
            ) : (
              customHeader(columnKey)
            )
          ) : data.sortName ? (
            <div
              className="cursor-pointer"
              onClick={() => sortHandle(data.sortName)}
            >
              <Translate value={data.label} className="header-title-margin" />
              <i
                className={
                  sortName === data.sortName
                    ? sortType === -1
                      ? 'fa fa-sort-desc'
                      : 'fa fa-sort-asc'
                    : 'fa fa-sort'
                }
                aria-hidden="true"
              />
            </div>
          ) : (
            <div>
              <Translate value={data.label} />
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);
