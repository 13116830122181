import ReportUtils from './../base/utils'

export const fields = [{
    key: "date",
    label: "report.result.promotion.date",
    summary: "date",
    mutate: value => ReportUtils.formatTime(value, {}, 'MM/DD/YYYY')
}, {
    key: "promoCode",
    label: "report.result.promotion.promoCode",
    summary: "promoCode"
}, {
    key: "users",
    label: "report.result.promotion.numberOfUsers",
    toolTip: "report.result.promotion.numberOfUsersApplyPromotion",
    summary: "users"
}, {
    key: "numberOfCompletedUsers",
    label: "report.result.promotion.numberOfCompletedUsers",
    toolTip: "report.result.promotion.numberOfUsersApplyPromotionAndCompleteBooking",
    summary: "numberOfCompletedUsers"
},{
    key: "applied",
    label: "report.result.promotion.numberOfPromoCodeAppliance",
    toolTip: "report.result.promotion.numberOfPromoCodeApplianceTooltip",
    summary: "applied"
}, {
    key: "booked",
    label: "report.result.promotion.numberOfSuccessfulBookings",
    toolTip: "report.result.promotion.numberOfSuccessfulBookingsTooltip",
    summary: "booked"
},
    {
        key: "uses",
        label: "report.result.promotion.numberOfCompletedBookings",
        toolTip: "report.result.promotion.numberOfCompletedBookingsTooltip",
        summary: "uses"
    },
    {
        key: "totalPromoAmount",
        label: "report.result.promotion.totalPromoAmount",
        summary: "totalPromoAmount"
    }, {
        key: "totalReceipt",
        label: "report.result.promotion.totalReceipt",
        summary: "totalReceipt"
    }];

export const fareFields = [
    "totalPromoAmount",
    "totalReceipt"
];
