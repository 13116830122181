import React, { Component } from 'react';
import _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../../../../components/validator';

const inputIndexList = [0, 1, 2];

export default class MultiCurrency extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      defaultTopupData,
      auth,
      hasPermission,
      handleInputCurrenciesChange,
      keyApp,
      DefaultCurenciesInputField,
      ValidatorCallback,
      isSubmited,
      valid,
      validateDataMinMax,
      activeKeys,
      tabMultiSelectHandle,
      creditWalletConfig: {
        enable,
        maximumByCurrencies = [],
        minimumByCurrencies = [],
      } = {},
    } = this.props;
    const currentCurrencies = auth.selectedFleet.currencies
      ? [...auth.selectedFleet.currencies].sort((cprevious, cnext) => {
          if (cprevious.iso < cnext.iso) return -1;
          if (cprevious.iso > cnext.iso) return 1;
          return 0;
        })
      : null;
    const activeKey = activeKeys[`${keyApp}App`]
      ? activeKeys[`${keyApp}App`]
      : currentCurrencies[0].iso;
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        activeKey={activeKey}
        animation={false}
        onSelect={(key) => tabMultiSelectHandle(key, keyApp)}
      >
        {currentCurrencies.map((c) => {
          const maximumObjectByCurrencies = maximumByCurrencies.find(
            (m) => m.currencyISO === c.iso
          );
          const minimumObjectByCurrencies = minimumByCurrencies.find(
            (m) => m.currencyISO === c.iso
          );

          const maximumValueByCurrencies =
            maximumObjectByCurrencies && maximumObjectByCurrencies.value > 0
              ? maximumObjectByCurrencies.value
              : 0;
          const minimumValueByCurrencies = minimumObjectByCurrencies
            ? minimumObjectByCurrencies.value
            : 0;

          const errMessage =
            maximumValueByCurrencies > 0 &&
            maximumValueByCurrencies > minimumValueByCurrencies
              ? 'value_must_between'
              : 'greater_or_equa';
          return (
            <Tab
              className="currencies-tab-item pt-lg"
              eventKey={c.iso}
              title={c.iso}
              key={c.iso}
            >
              <FormGroup
                className={
                  !isSubmited || !enable
                    ? null
                    : valid[`${keyApp}App${c.iso}`] === false
                    ? 'error'
                    : null
                }
              >
                {inputIndexList.map((ind) => {
                  const currentValue =
                    !defaultTopupData || !defaultTopupData[ind]
                      ? null
                      : defaultTopupData[ind].find(
                          (df) => df.currencyISO === c.iso
                        );
                  return (
                    <React.Fragment key={ind}>
                      <Form.Label>
                        <Translate
                          value="generalSetting.Default_value"
                          className="mr-sm"
                        />
                        {ind + 1}
                        <span className="require ml-sm"> *</span>
                      </Form.Label>
                      <FormGroup className="qup-input-group">
                        <InputGroup
                          key={ind}
                          className={`single-addon-left ${
                            !hasPermission ? 'disabled' : ''
                          }`}
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Text>{c.symbol}</InputGroup.Text>
                          </InputGroup.Prepend>{' '}
                          <FormControl
                            type="text"
                            className="fomt-custom"
                            onChange={(e) => {
                              handleInputCurrenciesChange(
                                c.iso,
                                e,
                                `${keyApp}App`,
                                ind
                              );
                            }}
                            onKeyPress={(e) => {
                              handleInputCurrenciesChange(
                                c.iso,
                                e,
                                `${keyApp}App`,
                                ind
                              );
                            }}
                            value={
                              currentValue
                                ? currentValue.value
                                : DefaultCurenciesInputField
                            }
                            disabled={!hasPermission}
                          />
                        </InputGroup>
                      </FormGroup>
                    </React.Fragment>
                  );
                })}

                <Validator
                  id={`${keyApp}App${c.iso}`}
                  callback={ValidatorCallback}
                >
                  <ValidCase
                    valid={validateDataMinMax(defaultTopupData, c.iso, keyApp)}
                    message={I18n.t(
                      `messages.commonMessages.${errMessage}`
                    ).format(
                      Math.round(minimumValueByCurrencies * 100) / 100,
                      Math.round(maximumValueByCurrencies * 100) / 100
                    )}
                    hide={!isSubmited}
                  />
                </Validator>
              </FormGroup>
            </Tab>
          );
        })}
      </Tabs>
    );
  }
}
/* Test, maybe no need
    onBlur={e => {
        textboxNumberHelper.onBlurHandle(e, event => {
            handleInputCurrenciesChange(c.iso, event, `${keyApp}App`, ind);
        });
    }}
    onFocus={e => {
        textboxNumberHelper.onfocusHandle(e, event => {
            handleInputCurrenciesChange(c.iso, event, `${keyApp}App`, ind);
        });
    }}
 */
