export default [{
  timezone: "Pacific/Midway",
  text: "(GMT-11:00) Pacific/Midway"
}, {
  timezone: "Pacific/Niue",
  text: "(GMT-11:00) Pacific/Niue"
}, {
  timezone: "Pacific/Pago_Pago",
  text: "(GMT-11:00) Pacific/Pago Pago"
}, {
  timezone: "Pacific/Samoa",
  text: "(GMT-11:00) Pacific/Samoa"
}, {
  timezone: "America/Adak",
  text: "(GMT-10:00) America/Adak"
}, {
  timezone: "America/Atka",
  text: "(GMT-10:00) America/Atka"
}, {
  timezone: "Pacific/Honolulu",
  text: "(GMT-10:00) Pacific/Honolulu"
}, {
  timezone: "Pacific/Johnston",
  text: "(GMT-10:00) Pacific/Johnston"
}, {
  timezone: "Pacific/Rarotonga",
  text: "(GMT-10:00) Pacific/Rarotonga"
}, {
  timezone: "Pacific/Tahiti",
  text: "(GMT-10:00) Pacific/Tahiti"
}, {
  timezone: "Pacific/Marquesas",
  text: "(GMT-9:30) Pacific/Marquesas"
}, {
  timezone: "America/Anchorage",
  text: "(GMT-9:00) America/Anchorage"
}, {
  timezone: "America/Juneau",
  text: "(GMT-9:00) America/Juneau"
}, {
  timezone: "America/Metlakatla",
  text: "(GMT-9:00) America/Metlakatla"
}, {
  timezone: "America/Nome",
  text: "(GMT-9:00) America/Nome"
}, {
  timezone: "America/Sitka",
  text: "(GMT-9:00) America/Sitka"
}, {
  timezone: "America/Yakutat",
  text: "(GMT-9:00) America/Yakutat"
}, {
  timezone: "Pacific/Gambier",
  text: "(GMT-9:00) Pacific/Gambier"
}, {
  timezone: "America/Dawson",
  text: "(GMT-8:00) America/Dawson"
}, {
  timezone: "America/Ensenada",
  text: "(GMT-8:00) America/Ensenada"
}, {
  timezone: "America/Los_Angeles",
  text: "(GMT-8:00) America/Los Angeles"
}, {
  timezone: "America/Santa_Isabel",
  text: "(GMT-8:00) America/Santa Isabel"
}, {
  timezone: "America/Tijuana",
  text: "(GMT-8:00) America/Tijuana"
}, {
  timezone: "America/Vancouver",
  text: "(GMT-8:00) America/Vancouver"
}, {
  timezone: "America/Whitehorse",
  text: "(GMT-8:00) America/Whitehorse"
}, {
  timezone: "Canada/Pacific",
  text: "(GMT-8:00) Canada/Pacific"
}, {
  timezone: "Canada/Yukon",
  text: "(GMT-8:00) Canada/Yukon"
}, {
  timezone: "Mexico/BajaNorte",
  text: "(GMT-8:00) Mexico/BajaNorte"
}, {
  timezone: "Pacific/Pitcairn",
  text: "(GMT-8:00) Pacific/Pitcairn"
}, {
  timezone: "America/Boise",
  text: "(GMT-7:00) America/Boise"
}, {
  timezone: "America/Cambridge_Bay",
  text: "(GMT-7:00) America/Cambridge Bay"
}, {
  timezone: "America/Chihuahua",
  text: "(GMT-7:00) America/Chihuahua"
}, {
  timezone: "America/Creston",
  text: "(GMT-7:00) America/Creston"
}, {
  timezone: "America/Dawson_Creek",
  text: "(GMT-7:00) America/Dawson Creek"
}, {
  timezone: "America/Denver",
  text: "(GMT-7:00) America/Denver"
}, {
  timezone: "America/Edmonton",
  text: "(GMT-7:00) America/Edmonton"
}, {
  timezone: "America/Fort_Nelson",
  text: "(GMT-7:00) America/Fort Nelson"
}, {
  timezone: "America/Hermosillo",
  text: "(GMT-7:00) America/Hermosillo"
}, {
  timezone: "America/Inuvik",
  text: "(GMT-7:00) America/Inuvik"
}, {
  timezone: "America/Mazatlan",
  text: "(GMT-7:00) America/Mazatlan"
}, {
  timezone: "America/Ojinaga",
  text: "(GMT-7:00) America/Ojinaga"
}, {
  timezone: "America/Phoenix",
  text: "(GMT-7:00) America/Phoenix"
}, {
  timezone: "America/Shiprock",
  text: "(GMT-7:00) America/Shiprock"
}, {
  timezone: "America/Yellowknife",
  text: "(GMT-7:00) America/Yellowknife"
}, {
  timezone: "Canada/Mountain",
  text: "(GMT-7:00) Canada/Mountain"
}, {
  timezone: "Mexico/BajaSur",
  text: "(GMT-7:00) Mexico/BajaSur"
}, {
  timezone: "America/Bahia_Banderas",
  text: "(GMT-6:00) America/Bahia Banderas"
}, {
  timezone: "America/Belize",
  text: "(GMT-6:00) America/Belize"
}, {
  timezone: "America/Chicago",
  text: "(GMT-6:00) America/Chicago"
}, {
  timezone: "America/Costa_Rica",
  text: "(GMT-6:00) America/Costa Rica"
}, {
  timezone: "America/El_Salvador",
  text: "(GMT-6:00) America/El Salvador"
}, {
  timezone: "America/Guatemala",
  text: "(GMT-6:00) America/Guatemala"
}, {
  timezone: "America/Indiana/Knox",
  text: "(GMT-6:00) America/Indiana/Knox"
}, {
  timezone: "America/Indiana/Tell_City",
  text: "(GMT-6:00) America/Indiana/Tell City"
}, {
  timezone: "America/Knox_IN",
  text: "(GMT-6:00) America/Knox IN"
}, {
  timezone: "America/Managua",
  text: "(GMT-6:00) America/Managua"
}, {
  timezone: "America/Matamoros",
  text: "(GMT-6:00) America/Matamoros"
}, {
  timezone: "America/Menominee",
  text: "(GMT-6:00) America/Menominee"
}, {
  timezone: "America/Merida",
  text: "(GMT-6:00) America/Merida"
}, {
  timezone: "America/Mexico_City",
  text: "(GMT-6:00) America/Mexico City"
}, {
  timezone: "America/Monterrey",
  text: "(GMT-6:00) America/Monterrey"
}, {
  timezone: "America/North_Dakota/Beulah",
  text: "(GMT-6:00) America/North Dakota/Beulah"
}, {
  timezone: "America/North_Dakota/Center",
  text: "(GMT-6:00) America/North Dakota/Center"
}, {
  timezone: "America/North_Dakota/New_Salem",
  text: "(GMT-6:00) America/North Dakota/New Salem"
}, {
  timezone: "America/Rainy_River",
  text: "(GMT-6:00) America/Rainy River"
}, {
  timezone: "America/Rankin_Inlet",
  text: "(GMT-6:00) America/Rankin Inlet"
}, {
  timezone: "America/Regina",
  text: "(GMT-6:00) America/Regina"
}, {
  timezone: "America/Resolute",
  text: "(GMT-6:00) America/Resolute"
}, {
  timezone: "America/Swift_Current",
  text: "(GMT-6:00) America/Swift Current"
}, {
  timezone: "America/Tegucigalpa",
  text: "(GMT-6:00) America/Tegucigalpa"
}, {
  timezone: "America/Winnipeg",
  text: "(GMT-6:00) America/Winnipeg"
}, {
  timezone: "Canada/Central",
  text: "(GMT-6:00) Canada/Central"
}, {
  timezone: "Canada/East-Saskatchewan",
  text: "(GMT-6:00) Canada/East-Saskatchewan"
}, {
  timezone: "Canada/Saskatchewan",
  text: "(GMT-6:00) Canada/Saskatchewan"
}, {
  timezone: "Chile/EasterIsland",
  text: "(GMT-6:00) Chile/EasterIsland"
}, {
  timezone: "Mexico/General",
  text: "(GMT-6:00) Mexico/General"
}, {
  timezone: "Pacific/Easter",
  text: "(GMT-6:00) Pacific/Easter"
}, {
  timezone: "Pacific/Galapagos",
  text: "(GMT-6:00) Pacific/Galapagos"
}, {
  timezone: "America/Atikokan",
  text: "(GMT-5:00) America/Atikokan"
}, {
  timezone: "America/Bogota",
  text: "(GMT-5:00) America/Bogota"
}, {
  timezone: "America/Cancun",
  text: "(GMT-5:00) America/Cancun"
}, {
  timezone: "America/Cayman",
  text: "(GMT-5:00) America/Cayman"
}, {
  timezone: "America/Coral_Harbour",
  text: "(GMT-5:00) America/Coral Harbour"
}, {
  timezone: "America/Detroit",
  text: "(GMT-5:00) America/Detroit"
}, {
  timezone: "America/Eirunepe",
  text: "(GMT-5:00) America/Eirunepe"
}, {
  timezone: "America/Fort_Wayne",
  text: "(GMT-5:00) America/Fort Wayne"
}, {
  timezone: "America/Guayaquil",
  text: "(GMT-5:00) America/Guayaquil"
}, {
  timezone: "America/Havana",
  text: "(GMT-5:00) America/Havana"
}, {
  timezone: "America/Indiana/Indianapolis",
  text: "(GMT-5:00) America/Indiana/Indianapolis"
}, {
  timezone: "America/Indiana/Marengo",
  text: "(GMT-5:00) America/Indiana/Marengo"
}, {
  timezone: "America/Indiana/Petersburg",
  text: "(GMT-5:00) America/Indiana/Petersburg"
}, {
  timezone: "America/Indiana/Vevay",
  text: "(GMT-5:00) America/Indiana/Vevay"
}, {
  timezone: "America/Indiana/Vincennes",
  text: "(GMT-5:00) America/Indiana/Vincennes"
}, {
  timezone: "America/Indiana/Winamac",
  text: "(GMT-5:00) America/Indiana/Winamac"
}, {
  timezone: "America/Indianapolis",
  text: "(GMT-5:00) America/Indianapolis"
}, {
  timezone: "America/Iqaluit",
  text: "(GMT-5:00) America/Iqaluit"
}, {
  timezone: "America/Jamaica",
  text: "(GMT-5:00) America/Jamaica"
}, {
  timezone: "America/Kentucky/Louisville",
  text: "(GMT-5:00) America/Kentucky/Louisville"
}, {
  timezone: "America/Kentucky/Monticello",
  text: "(GMT-5:00) America/Kentucky/Monticello"
}, {
  timezone: "America/Lima",
  text: "(GMT-5:00) America/Lima"
}, {
  timezone: "America/Louisville",
  text: "(GMT-5:00) America/Louisville"
}, {
  timezone: "America/Montreal",
  text: "(GMT-5:00) America/Montreal"
}, {
  timezone: "America/Nassau",
  text: "(GMT-5:00) America/Nassau"
}, {
  timezone: "America/New_York",
  text: "(GMT-5:00) America/New York"
}, {
  timezone: "America/Nipigon",
  text: "(GMT-5:00) America/Nipigon"
}, {
  timezone: "America/Panama",
  text: "(GMT-5:00) America/Panama"
}, {
  timezone: "America/Pangnirtung",
  text: "(GMT-5:00) America/Pangnirtung"
}, {
  timezone: "America/Port-au-Prince",
  text: "(GMT-5:00) America/Port-au-Prince"
}, {
  timezone: "America/Porto_Acre",
  text: "(GMT-5:00) America/Porto Acre"
}, {
  timezone: "America/Rio_Branco",
  text: "(GMT-5:00) America/Rio Branco"
}, {
  timezone: "America/Thunder_Bay",
  text: "(GMT-5:00) America/Thunder Bay"
}, {
  timezone: "America/Toronto",
  text: "(GMT-5:00) America/Toronto"
}, {
  timezone: "Brazil/Acre",
  text: "(GMT-5:00) Brazil/Acre"
}, {
  timezone: "Canada/Eastern",
  text: "(GMT-5:00) Canada/Eastern"
}, {
  timezone: "Cuba",
  text: "(GMT-5:00) Cuba"
}, {
  timezone: "America/Anguilla",
  text: "(GMT-4:00) America/Anguilla"
}, {
  timezone: "America/Antigua",
  text: "(GMT-4:00) America/Antigua"
}, {
  timezone: "America/Aruba",
  text: "(GMT-4:00) America/Aruba"
}, {
  timezone: "America/Asuncion",
  text: "(GMT-4:00) America/Asuncion"
}, {
  timezone: "America/Barbados",
  text: "(GMT-4:00) America/Barbados"
}, {
  timezone: "America/Blanc-Sablon",
  text: "(GMT-4:00) America/Blanc-Sablon"
}, {
  timezone: "America/Boa_Vista",
  text: "(GMT-4:00) America/Boa Vista"
}, {
  timezone: "America/Campo_Grande",
  text: "(GMT-4:00) America/Campo Grande"
}, {
  timezone: "America/Caracas",
  text: "(GMT-4:00) America/Caracas"
}, {
  timezone: "America/Cuiaba",
  text: "(GMT-4:00) America/Cuiaba"
}, {
  timezone: "America/Curacao",
  text: "(GMT-4:00) America/Curacao"
}, {
  timezone: "America/Dominica",
  text: "(GMT-4:00) America/Dominica"
}, {
  timezone: "America/Glace_Bay",
  text: "(GMT-4:00) America/Glace Bay"
}, {
  timezone: "America/Goose_Bay",
  text: "(GMT-4:00) America/Goose Bay"
}, {
  timezone: "America/Grand_Turk",
  text: "(GMT-4:00) America/Grand Turk"
}, {
  timezone: "America/Grenada",
  text: "(GMT-4:00) America/Grenada"
}, {
  timezone: "America/Guadeloupe",
  text: "(GMT-4:00) America/Guadeloupe"
}, {
  timezone: "America/Guyana",
  text: "(GMT-4:00) America/Guyana"
}, {
  timezone: "America/Halifax",
  text: "(GMT-4:00) America/Halifax"
}, {
  timezone: "America/Kralendijk",
  text: "(GMT-4:00) America/Kralendijk"
}, {
  timezone: "America/La_Paz",
  text: "(GMT-4:00) America/La Paz"
}, {
  timezone: "America/Lower_Princes",
  text: "(GMT-4:00) America/Lower Princes"
}, {
  timezone: "America/Manaus",
  text: "(GMT-4:00) America/Manaus"
}, {
  timezone: "America/Marigot",
  text: "(GMT-4:00) America/Marigot"
}, {
  timezone: "America/Martinique",
  text: "(GMT-4:00) America/Martinique"
}, {
  timezone: "America/Moncton",
  text: "(GMT-4:00) America/Moncton"
}, {
  timezone: "America/Montserrat",
  text: "(GMT-4:00) America/Montserrat"
}, {
  timezone: "America/Port_of_Spain",
  text: "(GMT-4:00) America/Port of Spain"
}, {
  timezone: "America/Porto_Velho",
  text: "(GMT-4:00) America/Porto Velho"
}, {
  timezone: "America/Puerto_Rico",
  text: "(GMT-4:00) America/Puerto Rico"
}, {
  timezone: "America/Santiago",
  text: "(GMT-4:00) America/Santiago"
}, {
  timezone: "America/Santo_Domingo",
  text: "(GMT-4:00) America/Santo Domingo"
}, {
  timezone: "America/St_Barthelemy",
  text: "(GMT-4:00) America/St Barthelemy"
}, {
  timezone: "America/St_Kitts",
  text: "(GMT-4:00) America/St Kitts"
}, {
  timezone: "America/St_Lucia",
  text: "(GMT-4:00) America/St Lucia"
}, {
  timezone: "America/St_Thomas",
  text: "(GMT-4:00) America/St Thomas"
}, {
  timezone: "America/St_Vincent",
  text: "(GMT-4:00) America/St Vincent"
}, {
  timezone: "America/Thule",
  text: "(GMT-4:00) America/Thule"
}, {
  timezone: "America/Tortola",
  text: "(GMT-4:00) America/Tortola"
}, {
  timezone: "America/Virgin",
  text: "(GMT-4:00) America/Virgin"
}, {
  timezone: "Atlantic/Bermuda",
  text: "(GMT-4:00) Atlantic/Bermuda"
}, {
  timezone: "Brazil/West",
  text: "(GMT-4:00) Brazil/West"
}, {
  timezone: "Canada/Atlantic",
  text: "(GMT-4:00) Canada/Atlantic"
}, {
  timezone: "Chile/Continental",
  text: "(GMT-4:00) Chile/Continental"
}, {
  timezone: "America/St_Johns",
  text: "(GMT-3:30) America/St Johns"
}, {
  timezone: "Canada/Newfoundland",
  text: "(GMT-3:30) Canada/Newfoundland"
}, {
  timezone: "America/Araguaina",
  text: "(GMT-3:00) America/Araguaina"
}, {
  timezone: "America/Argentina/Buenos_Aires",
  text: "(GMT-3:00) America/Argentina/Buenos Aires"
}, {
  timezone: "America/Argentina/Catamarca",
  text: "(GMT-3:00) America/Argentina/Catamarca"
}, {
  timezone: "America/Argentina/ComodRivadavia",
  text: "(GMT-3:00) America/Argentina/ComodRivadavia"
}, {
  timezone: "America/Argentina/Cordoba",
  text: "(GMT-3:00) America/Argentina/Cordoba"
}, {
  timezone: "America/Argentina/Jujuy",
  text: "(GMT-3:00) America/Argentina/Jujuy"
}, {
  timezone: "America/Argentina/La_Rioja",
  text: "(GMT-3:00) America/Argentina/La Rioja"
}, {
  timezone: "America/Argentina/Mendoza",
  text: "(GMT-3:00) America/Argentina/Mendoza"
}, {
  timezone: "America/Argentina/Rio_Gallegos",
  text: "(GMT-3:00) America/Argentina/Rio Gallegos"
}, {
  timezone: "America/Argentina/Salta",
  text: "(GMT-3:00) America/Argentina/Salta"
}, {
  timezone: "America/Argentina/San_Juan",
  text: "(GMT-3:00) America/Argentina/San Juan"
}, {
  timezone: "America/Argentina/San_Luis",
  text: "(GMT-3:00) America/Argentina/San Luis"
}, {
  timezone: "America/Argentina/Tucuman",
  text: "(GMT-3:00) America/Argentina/Tucuman"
}, {
  timezone: "America/Argentina/Ushuaia",
  text: "(GMT-3:00) America/Argentina/Ushuaia"
}, {
  timezone: "America/Bahia",
  text: "(GMT-3:00) America/Bahia"
}, {
  timezone: "America/Belem",
  text: "(GMT-3:00) America/Belem"
}, {
  timezone: "America/Buenos_Aires",
  text: "(GMT-3:00) America/Buenos Aires"
}, {
  timezone: "America/Catamarca",
  text: "(GMT-3:00) America/Catamarca"
}, {
  timezone: "America/Cayenne",
  text: "(GMT-3:00) America/Cayenne"
}, {
  timezone: "America/Cordoba",
  text: "(GMT-3:00) America/Cordoba"
}, {
  timezone: "America/Fortaleza",
  text: "(GMT-3:00) America/Fortaleza"
}, {
  timezone: "America/Godthab",
  text: "(GMT-3:00) America/Godthab"
}, {
  timezone: "America/Jujuy",
  text: "(GMT-3:00) America/Jujuy"
}, {
  timezone: "America/Maceio",
  text: "(GMT-3:00) America/Maceio"
}, {
  timezone: "America/Mendoza",
  text: "(GMT-3:00) America/Mendoza"
}, {
  timezone: "America/Miquelon",
  text: "(GMT-3:00) America/Miquelon"
}, {
  timezone: "America/Montevideo",
  text: "(GMT-3:00) America/Montevideo"
}, {
  timezone: "America/Paramaribo",
  text: "(GMT-3:00) America/Paramaribo"
}, {
  timezone: "America/Punta_Arenas",
  text: "(GMT-3:00) America/Punta Arenas"
}, {
  timezone: "America/Recife",
  text: "(GMT-3:00) America/Recife"
}, {
  timezone: "America/Rosario",
  text: "(GMT-3:00) America/Rosario"
}, {
  timezone: "America/Santarem",
  text: "(GMT-3:00) America/Santarem"
}, {
  timezone: "America/Sao_Paulo",
  text: "(GMT-3:00) America/Sao Paulo"
}, {
  timezone: "Atlantic/Stanley",
  text: "(GMT-3:00) Atlantic/Stanley"
}, {
  timezone: "Brazil/East",
  text: "(GMT-3:00) Brazil/East"
}, {
  timezone: "America/Noronha",
  text: "(GMT-2:00) America/Noronha"
}, {
  timezone: "Atlantic/South_Georgia",
  text: "(GMT-2:00) Atlantic/South Georgia"
}, {
  timezone: "Brazil/DeNoronha",
  text: "(GMT-2:00) Brazil/DeNoronha"
}, {
  timezone: "America/Scoresbysund",
  text: "(GMT-1:00) America/Scoresbysund"
}, {
  timezone: "Atlantic/Azores",
  text: "(GMT-1:00) Atlantic/Azores"
}, {
  timezone: "Atlantic/Cape_Verde",
  text: "(GMT-1:00) Atlantic/Cape Verde"
}, {
  timezone: "Africa/Abidjan",
  text: "(GMT 0:00) Africa/Abidjan"
}, {
  timezone: "Africa/Accra",
  text: "(GMT 0:00) Africa/Accra"
}, {
  timezone: "Africa/Bamako",
  text: "(GMT 0:00) Africa/Bamako"
}, {
  timezone: "Africa/Banjul",
  text: "(GMT 0:00) Africa/Banjul"
}, {
  timezone: "Africa/Bissau",
  text: "(GMT 0:00) Africa/Bissau"
}, {
  timezone: "Africa/Casablanca",
  text: "(GMT 0:00) Africa/Casablanca"
}, {
  timezone: "Africa/Conakry",
  text: "(GMT 0:00) Africa/Conakry"
}, {
  timezone: "Africa/Dakar",
  text: "(GMT 0:00) Africa/Dakar"
}, {
  timezone: "Africa/El_Aaiun",
  text: "(GMT 0:00) Africa/El Aaiun"
}, {
  timezone: "Africa/Freetown",
  text: "(GMT 0:00) Africa/Freetown"
}, {
  timezone: "Africa/Lome",
  text: "(GMT 0:00) Africa/Lome"
}, {
  timezone: "Africa/Monrovia",
  text: "(GMT 0:00) Africa/Monrovia"
}, {
  timezone: "Africa/Nouakchott",
  text: "(GMT 0:00) Africa/Nouakchott"
}, {
  timezone: "Africa/Ouagadougou",
  text: "(GMT 0:00) Africa/Ouagadougou"
}, {
  timezone: "Africa/Sao_Tome",
  text: "(GMT 0:00) Africa/Sao Tome"
}, {
  timezone: "Africa/Timbuktu",
  text: "(GMT 0:00) Africa/Timbuktu"
}, {
  timezone: "America/Danmarkshavn",
  text: "(GMT 0:00) America/Danmarkshavn"
}, {
  timezone: "Atlantic/Canary",
  text: "(GMT 0:00) Atlantic/Canary"
}, {
  timezone: "Atlantic/Faeroe",
  text: "(GMT 0:00) Atlantic/Faeroe"
}, {
  timezone: "Atlantic/Faroe",
  text: "(GMT 0:00) Atlantic/Faroe"
}, {
  timezone: "Atlantic/Madeira",
  text: "(GMT 0:00) Atlantic/Madeira"
}, {
  timezone: "Atlantic/Reykjavik",
  text: "(GMT 0:00) Atlantic/Reykjavik"
}, {
  timezone: "Atlantic/St_Helena",
  text: "(GMT 0:00) Atlantic/St Helena"
}, {
  timezone: "Europe/Belfast",
  text: "(GMT 0:00) Europe/Belfast"
}, {
  timezone: "Europe/Dublin",
  text: "(GMT 0:00) Europe/Dublin"
}, {
  timezone: "Europe/Guernsey",
  text: "(GMT 0:00) Europe/Guernsey"
}, {
  timezone: "Europe/Isle_of_Man",
  text: "(GMT 0:00) Europe/Isle of Man"
}, {
  timezone: "Europe/Jersey",
  text: "(GMT 0:00) Europe/Jersey"
}, {
  timezone: "Europe/Lisbon",
  text: "(GMT 0:00) Europe/Lisbon"
}, {
  timezone: "Europe/London",
  text: "(GMT 0:00) Europe/London"
}, {
  timezone: "Africa/Algiers",
  text: "(GMT+1:00) Africa/Algiers"
}, {
  timezone: "Africa/Bangui",
  text: "(GMT+1:00) Africa/Bangui"
}, {
  timezone: "Africa/Brazzaville",
  text: "(GMT+1:00) Africa/Brazzaville"
}, {
  timezone: "Africa/Ceuta",
  text: "(GMT+1:00) Africa/Ceuta"
}, {
  timezone: "Africa/Douala",
  text: "(GMT+1:00) Africa/Douala"
}, {
  timezone: "Africa/Kinshasa",
  text: "(GMT+1:00) Africa/Kinshasa"
}, {
  timezone: "Africa/Lagos",
  text: "(GMT+1:00) Africa/Lagos"
}, {
  timezone: "Africa/Libreville",
  text: "(GMT+1:00) Africa/Libreville"
}, {
  timezone: "Africa/Luanda",
  text: "(GMT+1:00) Africa/Luanda"
}, {
  timezone: "Africa/Malabo",
  text: "(GMT+1:00) Africa/Malabo"
}, {
  timezone: "Africa/Ndjamena",
  text: "(GMT+1:00) Africa/Ndjamena"
}, {
  timezone: "Africa/Niamey",
  text: "(GMT+1:00) Africa/Niamey"
}, {
  timezone: "Africa/Porto-Novo",
  text: "(GMT+1:00) Africa/Porto-Novo"
}, {
  timezone: "Africa/Tunis",
  text: "(GMT+1:00) Africa/Tunis"
}, {
  timezone: "Africa/Windhoek",
  text: "(GMT+1:00) Africa/Windhoek"
}, {
  timezone: "Atlantic/Jan_Mayen",
  text: "(GMT+1:00) Atlantic/Jan Mayen"
}, {
  timezone: "Europe/Amsterdam",
  text: "(GMT+1:00) Europe/Amsterdam"
}, {
  timezone: "Europe/Andorra",
  text: "(GMT+1:00) Europe/Andorra"
}, {
  timezone: "Europe/Belgrade",
  text: "(GMT+1:00) Europe/Belgrade"
}, {
  timezone: "Europe/Berlin",
  text: "(GMT+1:00) Europe/Berlin"
}, {
  timezone: "Europe/Bratislava",
  text: "(GMT+1:00) Europe/Bratislava"
}, {
  timezone: "Europe/Brussels",
  text: "(GMT+1:00) Europe/Brussels"
}, {
  timezone: "Europe/Budapest",
  text: "(GMT+1:00) Europe/Budapest"
}, {
  timezone: "Europe/Busingen",
  text: "(GMT+1:00) Europe/Busingen"
}, {
  timezone: "Europe/Copenhagen",
  text: "(GMT+1:00) Europe/Copenhagen"
}, {
  timezone: "Europe/Gibraltar",
  text: "(GMT+1:00) Europe/Gibraltar"
}, {
  timezone: "Europe/Ljubljana",
  text: "(GMT+1:00) Europe/Ljubljana"
}, {
  timezone: "Europe/Luxembourg",
  text: "(GMT+1:00) Europe/Luxembourg"
}, {
  timezone: "Europe/Madrid",
  text: "(GMT+1:00) Europe/Madrid"
}, {
  timezone: "Europe/Malta",
  text: "(GMT+1:00) Europe/Malta"
}, {
  timezone: "Europe/Monaco",
  text: "(GMT+1:00) Europe/Monaco"
}, {
  timezone: "Europe/Oslo",
  text: "(GMT+1:00) Europe/Oslo"
}, {
  timezone: "Europe/Paris",
  text: "(GMT+1:00) Europe/Paris"
}, {
  timezone: "Europe/Podgorica",
  text: "(GMT+1:00) Europe/Podgorica"
}, {
  timezone: "Europe/Prague",
  text: "(GMT+1:00) Europe/Prague"
}, {
  timezone: "Europe/Rome",
  text: "(GMT+1:00) Europe/Rome"
}, {
  timezone: "Europe/San_Marino",
  text: "(GMT+1:00) Europe/San Marino"
}, {
  timezone: "Europe/Sarajevo",
  text: "(GMT+1:00) Europe/Sarajevo"
}, {
  timezone: "Europe/Skopje",
  text: "(GMT+1:00) Europe/Skopje"
}, {
  timezone: "Europe/Stockholm",
  text: "(GMT+1:00) Europe/Stockholm"
}, {
  timezone: "Europe/Tirane",
  text: "(GMT+1:00) Europe/Tirane"
}, {
  timezone: "Europe/Vaduz",
  text: "(GMT+1:00) Europe/Vaduz"
}, {
  timezone: "Europe/Vatican",
  text: "(GMT+1:00) Europe/Vatican"
}, {
  timezone: "Europe/Vienna",
  text: "(GMT+1:00) Europe/Vienna"
}, {
  timezone: "Europe/Warsaw",
  text: "(GMT+1:00) Europe/Warsaw"
}, {
  timezone: "Europe/Zagreb",
  text: "(GMT+1:00) Europe/Zagreb"
}, {
  timezone: "Europe/Zurich",
  text: "(GMT+1:00) Europe/Zurich"
}, {
  timezone: "Africa/Blantyre",
  text: "(GMT+2:00) Africa/Blantyre"
}, {
  timezone: "Africa/Bujumbura",
  text: "(GMT+2:00) Africa/Bujumbura"
}, {
  timezone: "Africa/Cairo",
  text: "(GMT+2:00) Africa/Cairo"
}, {
  timezone: "Africa/Gaborone",
  text: "(GMT+2:00) Africa/Gaborone"
}, {
  timezone: "Africa/Harare",
  text: "(GMT+2:00) Africa/Harare"
}, {
  timezone: "Africa/Johannesburg",
  text: "(GMT+2:00) Africa/Johannesburg"
}, {
  timezone: "Africa/Kigali",
  text: "(GMT+2:00) Africa/Kigali"
}, {
  timezone: "Africa/Lubumbashi",
  text: "(GMT+2:00) Africa/Lubumbashi"
}, {
  timezone: "Africa/Lusaka",
  text: "(GMT+2:00) Africa/Lusaka"
}, {
  timezone: "Africa/Maputo",
  text: "(GMT+2:00) Africa/Maputo"
}, {
  timezone: "Africa/Maseru",
  text: "(GMT+2:00) Africa/Maseru"
}, {
  timezone: "Africa/Mbabane",
  text: "(GMT+2:00) Africa/Mbabane"
}, {
  timezone: "Africa/Tripoli",
  text: "(GMT+2:00) Africa/Tripoli"
}, {
  timezone: "Asia/Amman",
  text: "(GMT+2:00) Asia/Amman"
}, {
  timezone: "Asia/Beirut",
  text: "(GMT+2:00) Asia/Beirut"
}, {
  timezone: "Asia/Damascus",
  text: "(GMT+2:00) Asia/Damascus"
}, {
  timezone: "Asia/Gaza",
  text: "(GMT+2:00) Asia/Gaza"
}, {
  timezone: "Asia/Hebron",
  text: "(GMT+2:00) Asia/Hebron"
}, {
  timezone: "Asia/Jerusalem",
  text: "(GMT+2:00) Asia/Jerusalem"
}, {
  timezone: "Asia/Nicosia",
  text: "(GMT+2:00) Asia/Nicosia"
}, {
  timezone: "Asia/Tel_Aviv",
  text: "(GMT+2:00) Asia/Tel Aviv"
}, {
  timezone: "Europe/Athens",
  text: "(GMT+2:00) Europe/Athens"
}, {
  timezone: "Europe/Bucharest",
  text: "(GMT+2:00) Europe/Bucharest"
}, {
  timezone: "Europe/Chisinau",
  text: "(GMT+2:00) Europe/Chisinau"
}, {
  timezone: "Europe/Helsinki",
  text: "(GMT+2:00) Europe/Helsinki"
}, {
  timezone: "Europe/Kaliningrad",
  text: "(GMT+2:00) Europe/Kaliningrad"
}, {
  timezone: "Europe/Kiev",
  text: "(GMT+2:00) Europe/Kiev"
}, {
  timezone: "Europe/Mariehamn",
  text: "(GMT+2:00) Europe/Mariehamn"
}, {
  timezone: "Europe/Nicosia",
  text: "(GMT+2:00) Europe/Nicosia"
}, {
  timezone: "Europe/Riga",
  text: "(GMT+2:00) Europe/Riga"
}, {
  timezone: "Europe/Sofia",
  text: "(GMT+2:00) Europe/Sofia"
}, {
  timezone: "Europe/Tallinn",
  text: "(GMT+2:00) Europe/Tallinn"
}, {
  timezone: "Europe/Tiraspol",
  text: "(GMT+2:00) Europe/Tiraspol"
}, {
  timezone: "Europe/Uzhgorod",
  text: "(GMT+2:00) Europe/Uzhgorod"
}, {
  timezone: "Europe/Vilnius",
  text: "(GMT+2:00) Europe/Vilnius"
}, {
  timezone: "Europe/Zaporozhye",
  text: "(GMT+2:00) Europe/Zaporozhye"
}, {
  timezone: "Africa/Addis_Ababa",
  text: "(GMT+3:00) Africa/Addis Ababa"
}, {
  timezone: "Africa/Asmara",
  text: "(GMT+3:00) Africa/Asmara"
}, {
  timezone: "Africa/Asmera",
  text: "(GMT+3:00) Africa/Asmera"
}, {
  timezone: "Africa/Dar_es_Salaam",
  text: "(GMT+3:00) Africa/Dar es Salaam"
}, {
  timezone: "Africa/Djibouti",
  text: "(GMT+3:00) Africa/Djibouti"
}, {
  timezone: "Africa/Juba",
  text: "(GMT+3:00) Africa/Juba"
}, {
  timezone: "Africa/Kampala",
  text: "(GMT+3:00) Africa/Kampala"
}, {
  timezone: "Africa/Khartoum",
  text: "(GMT+3:00) Africa/Khartoum"
}, {
  timezone: "Africa/Mogadishu",
  text: "(GMT+3:00) Africa/Mogadishu"
}, {
  timezone: "Africa/Nairobi",
  text: "(GMT+3:00) Africa/Nairobi"
}, {
  timezone: "Asia/Aden",
  text: "(GMT+3:00) Asia/Aden"
}, {
  timezone: "Asia/Baghdad",
  text: "(GMT+3:00) Asia/Baghdad"
}, {
  timezone: "Asia/Bahrain",
  text: "(GMT+3:00) Asia/Bahrain"
}, {
  timezone: "Asia/Famagusta",
  text: "(GMT+3:00) Asia/Famagusta"
}, {
  timezone: "Asia/Istanbul",
  text: "(GMT+3:00) Asia/Istanbul"
}, {
  timezone: "Asia/Kuwait",
  text: "(GMT+3:00) Asia/Kuwait"
}, {
  timezone: "Asia/Qatar",
  text: "(GMT+3:00) Asia/Qatar"
}, {
  timezone: "Asia/Riyadh",
  text: "(GMT+3:00) Asia/Riyadh"
}, {
  timezone: "Europe/Istanbul",
  text: "(GMT+3:00) Europe/Istanbul"
}, {
  timezone: "Europe/Kirov",
  text: "(GMT+3:00) Europe/Kirov"
}, {
  timezone: "Europe/Minsk",
  text: "(GMT+3:00) Europe/Minsk"
}, {
  timezone: "Europe/Moscow",
  text: "(GMT+3:00) Europe/Moscow"
}, {
  timezone: "Europe/Simferopol",
  text: "(GMT+3:00) Europe/Simferopol"
}, {
  timezone: "Europe/Volgograd",
  text: "(GMT+3:00) Europe/Volgograd"
}, {
  timezone: "Indian/Antananarivo",
  text: "(GMT+3:00) Indian/Antananarivo"
}, {
  timezone: "Indian/Comoro",
  text: "(GMT+3:00) Indian/Comoro"
}, {
  timezone: "Indian/Mayotte",
  text: "(GMT+3:00) Indian/Mayotte"
}, {
  timezone: "Turkey",
  text: "(GMT+3:00) Turkey"
}, {
  timezone: "Asia/Tehran",
  text: "(GMT+3:30) Asia/Tehran"
}, {
  timezone: "Asia/Baku",
  text: "(GMT+4:00) Asia/Baku"
}, {
  timezone: "Asia/Dubai",
  text: "(GMT+4:00) Asia/Dubai"
}, {
  timezone: "Asia/Muscat",
  text: "(GMT+4:00) Asia/Muscat"
}, {
  timezone: "Asia/Tbilisi",
  text: "(GMT+4:00) Asia/Tbilisi"
}, {
  timezone: "Asia/Yerevan",
  text: "(GMT+4:00) Asia/Yerevan"
}, {
  timezone: "Europe/Astrakhan",
  text: "(GMT+4:00) Europe/Astrakhan"
}, {
  timezone: "Europe/Samara",
  text: "(GMT+4:00) Europe/Samara"
}, {
  timezone: "Europe/Saratov",
  text: "(GMT+4:00) Europe/Saratov"
}, {
  timezone: "Europe/Ulyanovsk",
  text: "(GMT+4:00) Europe/Ulyanovsk"
}, {
  timezone: "Indian/Mahe",
  text: "(GMT+4:00) Indian/Mahe"
}, {
  timezone: "Indian/Mauritius",
  text: "(GMT+4:00) Indian/Mauritius"
}, {
  timezone: "Indian/Reunion",
  text: "(GMT+4:00) Indian/Reunion"
}, {
  timezone: "Asia/Kabul",
  text: "(GMT+4:30) Asia/Kabul"
}, {
  timezone: "Asia/Aqtau",
  text: "(GMT+5:00) Asia/Aqtau"
}, {
  timezone: "Asia/Aqtobe",
  text: "(GMT+5:00) Asia/Aqtobe"
}, {
  timezone: "Asia/Ashgabat",
  text: "(GMT+5:00) Asia/Ashgabat"
}, {
  timezone: "Asia/Ashkhabad",
  text: "(GMT+5:00) Asia/Ashkhabad"
}, {
  timezone: "Asia/Atyrau",
  text: "(GMT+5:00) Asia/Atyrau"
}, {
  timezone: "Asia/Dushanbe",
  text: "(GMT+5:00) Asia/Dushanbe"
}, {
  timezone: "Asia/Karachi",
  text: "(GMT+5:00) Asia/Karachi"
}, {
  timezone: "Asia/Oral",
  text: "(GMT+5:00) Asia/Oral"
}, {
  timezone: "Asia/Samarkand",
  text: "(GMT+5:00) Asia/Samarkand"
}, {
  timezone: "Asia/Tashkent",
  text: "(GMT+5:00) Asia/Tashkent"
}, {
  timezone: "Asia/Yekaterinburg",
  text: "(GMT+5:00) Asia/Yekaterinburg"
}, {
  timezone: "Indian/Kerguelen",
  text: "(GMT+5:00) Indian/Kerguelen"
}, {
  timezone: "Indian/Maldives",
  text: "(GMT+5:00) Indian/Maldives"
}, {
  timezone: "Asia/Calcutta",
  text: "(GMT+5:30) Asia/Calcutta"
}, {
  timezone: "Asia/Colombo",
  text: "(GMT+5:30) Asia/Colombo"
}, {
  timezone: "Asia/Kolkata",
  text: "(GMT+5:30) Asia/Kolkata"
}, {
  timezone: "Asia/Kathmandu",
  text: "(GMT+5:45) Asia/Kathmandu"
}, {
  timezone: "Asia/Katmandu",
  text: "(GMT+5:45) Asia/Katmandu"
}, {
  timezone: "Asia/Almaty",
  text: "(GMT+6:00) Asia/Almaty"
}, {
  timezone: "Asia/Bishkek",
  text: "(GMT+6:00) Asia/Bishkek"
}, {
  timezone: "Asia/Dacca",
  text: "(GMT+6:00) Asia/Dacca"
}, {
  timezone: "Asia/Dhaka",
  text: "(GMT+6:00) Asia/Dhaka"
}, {
  timezone: "Asia/Kashgar",
  text: "(GMT+6:00) Asia/Kashgar"
}, {
  timezone: "Asia/Omsk",
  text: "(GMT+6:00) Asia/Omsk"
}, {
  timezone: "Asia/Qyzylorda",
  text: "(GMT+6:00) Asia/Qyzylorda"
}, {
  timezone: "Asia/Thimbu",
  text: "(GMT+6:00) Asia/Thimbu"
}, {
  timezone: "Asia/Thimphu",
  text: "(GMT+6:00) Asia/Thimphu"
}, {
  timezone: "Asia/Urumqi",
  text: "(GMT+6:00) Asia/Urumqi"
}, {
  timezone: "Indian/Chagos",
  text: "(GMT+6:00) Indian/Chagos"
}, {
  timezone: "Asia/Rangoon",
  text: "(GMT+6:30) Asia/Rangoon"
}, {
  timezone: "Asia/Yangon",
  text: "(GMT+6:30) Asia/Yangon"
}, {
  timezone: "Indian/Cocos",
  text: "(GMT+6:30) Indian/Cocos"
}, {
  timezone: "Asia/Bangkok",
  text: "(GMT+7:00) Asia/Bangkok"
}, {
  timezone: "Asia/Barnaul",
  text: "(GMT+7:00) Asia/Barnaul"
}, {
  timezone: "Asia/Ho_Chi_Minh",
  text: "(GMT+7:00) Asia/Ho Chi Minh "
}, {
  timezone: "Asia/Hovd",
  text: "(GMT+7:00) Asia/Hovd"
}, {
  timezone: "Asia/Jakarta",
  text: "(GMT+7:00) Asia/Jakarta"
}, {
  timezone: "Asia/Krasnoyarsk",
  text: "(GMT+7:00) Asia/Krasnoyarsk"
}, {
  timezone: "Asia/Novokuznetsk",
  text: "(GMT+7:00) Asia/Novokuznetsk"
}, {
  timezone: "Asia/Novosibirsk",
  text: "(GMT+7:00) Asia/Novosibirsk"
}, {
  timezone: "Asia/Phnom_Penh",
  text: "(GMT+7:00) Asia/Phnom Penh"
}, {
  timezone: "Asia/Pontianak",
  text: "(GMT+7:00) Asia/Pontianak"
}, {
  timezone: "Asia/Saigon",
  text: "(GMT+7:00) Asia/Saigon"
}, {
  timezone: "Asia/Tomsk",
  text: "(GMT+7:00) Asia/Tomsk"
}, {
  timezone: "Asia/Vientiane",
  text: "(GMT+7:00) Asia/Vientiane"
}, {
  timezone: "Indian/Christmas",
  text: "(GMT+7:00) Indian/Christmas"
}, {
  timezone: "Asia/Brunei",
  text: "(GMT+8:00) Asia/Brunei"
}, {
  timezone: "Asia/Choibalsan",
  text: "(GMT+8:00) Asia/Choibalsan"
}, {
  timezone: "Asia/Chongqing",
  text: "(GMT+8:00) Asia/Chongqing"
}, {
  timezone: "Asia/Chungking",
  text: "(GMT+8:00) Asia/Chungking"
}, {
  timezone: "Asia/Harbin",
  text: "(GMT+8:00) Asia/Harbin"
}, {
  timezone: "Asia/Hong_Kong",
  text: "(GMT+8:00) Asia/Hong Kong"
}, {
  timezone: "Asia/Irkutsk",
  text: "(GMT+8:00) Asia/Irkutsk"
}, {
  timezone: "Asia/Kuala_Lumpur",
  text: "(GMT+8:00) Asia/Kuala Lumpur"
}, {
  timezone: "Asia/Kuching",
  text: "(GMT+8:00) Asia/Kuching"
}, {
  timezone: "Asia/Macao",
  text: "(GMT+8:00) Asia/Macao"
}, {
  timezone: "Asia/Macau",
  text: "(GMT+8:00) Asia/Macau"
}, {
  timezone: "Asia/Makassar",
  text: "(GMT+8:00) Asia/Makassar"
}, {
  timezone: "Asia/Manila",
  text: "(GMT+8:00) Asia/Manila"
}, {
  timezone: "Asia/Shanghai",
  text: "(GMT+8:00) Asia/Shanghai"
}, {
  timezone: "Asia/Singapore",
  text: "(GMT+8:00) Asia/Singapore"
}, {
  timezone: "Asia/Taipei",
  text: "(GMT+8:00) Asia/Taipei"
}, {
  timezone: "Asia/Ujung_Pandang",
  text: "(GMT+8:00) Asia/Ujung Pandang"
}, {
  timezone: "Asia/Ulaanbaatar",
  text: "(GMT+8:00) Asia/Ulaanbaatar"
}, {
  timezone: "Asia/Ulan_Bator",
  text: "(GMT+8:00) Asia/Ulan Bator"
}, {
  timezone: "Australia/Perth",
  text: "(GMT+8:00) Australia/Perth"
}, {
  timezone: "Australia/West",
  text: "(GMT+8:00) Australia/West"
}, {
  timezone: "Asia/Pyongyang",
  text: "(GMT+8:30) Asia/Pyongyang"
}, {
  timezone: "Australia/Eucla",
  text: "(GMT+8:45) Australia/Eucla"
}, {
  timezone: "Asia/Chita",
  text: "(GMT+9:00) Asia/Chita"
}, {
  timezone: "Asia/Dili",
  text: "(GMT+9:00) Asia/Dili"
}, {
  timezone: "Asia/Jayapura",
  text: "(GMT+9:00) Asia/Jayapura"
}, {
  timezone: "Asia/Khandyga",
  text: "(GMT+9:00) Asia/Khandyga"
}, {
  timezone: "Asia/Seoul",
  text: "(GMT+9:00) Asia/Seoul"
}, {
  timezone: "Asia/Tokyo",
  text: "(GMT+9:00) Asia/Tokyo"
}, {
  timezone: "Asia/Yakutsk",
  text: "(GMT+9:00) Asia/Yakutsk"
}, {
  timezone: "Japan",
  text: "(GMT+9:00) Japan"
}, {
  timezone: "Pacific/Palau",
  text: "(GMT+9:00) Pacific/Palau"
}, {
  timezone: "Australia/Adelaide",
  text: "(GMT+9:30) Australia/Adelaide"
}, {
  timezone: "Australia/Broken_Hill",
  text: "(GMT+9:30) Australia/Broken Hill"
}, {
  timezone: "Australia/Darwin",
  text: "(GMT+9:30) Australia/Darwin"
}, {
  timezone: "Australia/North",
  text: "(GMT+9:30) Australia/North"
}, {
  timezone: "Australia/South",
  text: "(GMT+9:30) Australia/South"
}, {
  timezone: "Australia/Yancowinna",
  text: "(GMT+9:30) Australia/Yancowinna"
}, {
  timezone: "Asia/Ust-Nera",
  text: "(GMT+10:00) Asia/Ust-Nera"
}, {
  timezone: "Asia/Vladivostok",
  text: "(GMT+10:00) Asia/Vladivostok"
}, {
  timezone: "Australia/ACT",
  text: "(GMT+10:00) Australia/ACT"
}, {
  timezone: "Australia/Brisbane",
  text: "(GMT+10:00) Australia/Brisbane"
}, {
  timezone: "Australia/Canberra",
  text: "(GMT+10:00) Australia/Canberra"
}, {
  timezone: "Australia/Currie",
  text: "(GMT+10:00) Australia/Currie"
}, {
  timezone: "Australia/Hobart",
  text: "(GMT+10:00) Australia/Hobart"
}, {
  timezone: "Australia/Lindeman",
  text: "(GMT+10:00) Australia/Lindeman"
}, {
  timezone: "Australia/Melbourne",
  text: "(GMT+10:00) Australia/Melbourne"
}, {
  timezone: "Australia/NSW",
  text: "(GMT+10:00) Australia/NSW"
}, {
  timezone: "Australia/Queensland",
  text: "(GMT+10:00) Australia/Queensland"
}, {
  timezone: "Australia/Sydney",
  text: "(GMT+10:00) Australia/Sydney"
}, {
  timezone: "Australia/Tasmania",
  text: "(GMT+10:00) Australia/Tasmania"
}, {
  timezone: "Australia/Victoria",
  text: "(GMT+10:00) Australia/Victoria"
}, {
  timezone: "Pacific/Chuuk",
  text: "(GMT+10:00) Pacific/Chuuk"
}, {
  timezone: "Pacific/Guam",
  text: "(GMT+10:00) Pacific/Guam"
}, {
  timezone: "Pacific/Port_Moresby",
  text: "(GMT+10:00) Pacific/Port Moresby"
}, {
  timezone: "Pacific/Saipan",
  text: "(GMT+10:00) Pacific/Saipan"
}, {
  timezone: "Pacific/Truk",
  text: "(GMT+10:00) Pacific/Truk"
}, {
  timezone: "Pacific/Yap",
  text: "(GMT+10:00) Pacific/Yap"
}, {
  timezone: "Australia/LHI",
  text: "(GMT+10:30) Australia/LHI"
}, {
  timezone: "Australia/Lord_Howe",
  text: "(GMT+10:30) Australia/Lord Howe"
}, {
  timezone: "Asia/Magadan",
  text: "(GMT+11:00) Asia/Magadan"
}, {
  timezone: "Asia/Sakhalin",
  text: "(GMT+11:00) Asia/Sakhalin"
}, {
  timezone: "Asia/Srednekolymsk",
  text: "(GMT+11:00) Asia/Srednekolymsk"
}, {
  timezone: "Pacific/Bougainville",
  text: "(GMT+11:00) Pacific/Bougainville"
}, {
  timezone: "Pacific/Efate",
  text: "(GMT+11:00) Pacific/Efate"
}, {
  timezone: "Pacific/Guadalcanal",
  text: "(GMT+11:00) Pacific/Guadalcanal"
}, {
  timezone: "Pacific/Kosrae",
  text: "(GMT+11:00) Pacific/Kosrae"
}, {
  timezone: "Pacific/Norfolk",
  text: "(GMT+11:00) Pacific/Norfolk"
}, {
  timezone: "Pacific/Noumea",
  text: "(GMT+11:00) Pacific/Noumea"
}, {
  timezone: "Pacific/Pohnpei",
  text: "(GMT+11:00) Pacific/Pohnpei"
}, {
  timezone: "Pacific/Ponape",
  text: "(GMT+11:00) Pacific/Ponape"
}, {
  timezone: "Asia/Anadyr",
  text: "(GMT+12:00) Asia/Anadyr"
}, {
  timezone: "Asia/Kamchatka",
  text: "(GMT+12:00) Asia/Kamchatka"
}, {
  timezone: "Pacific/Auckland",
  text: "(GMT+12:00) Pacific/Auckland"
}, {
  timezone: "Pacific/Fiji",
  text: "(GMT+12:00) Pacific/Fiji"
}, {
  timezone: "Pacific/Funafuti",
  text: "(GMT+12:00) Pacific/Funafuti"
}, {
  timezone: "Pacific/Kwajalein",
  text: "(GMT+12:00) Pacific/Kwajalein"
}, {
  timezone: "Pacific/Majuro",
  text: "(GMT+12:00) Pacific/Majuro"
}, {
  timezone: "Pacific/Nauru",
  text: "(GMT+12:00) Pacific/Nauru"
}, {
  timezone: "Pacific/Tarawa",
  text: "(GMT+12:00) Pacific/Tarawa"
}, {
  timezone: "Pacific/Wake",
  text: "(GMT+12:00) Pacific/Wake"
}, {
  timezone: "Pacific/Wallis",
  text: "(GMT+12:00) Pacific/Wallis"
}, {
  timezone: "Pacific/Chatham",
  text: "(GMT+12:45) Pacific/Chatham"
}, {
  timezone: "Pacific/Apia",
  text: "(GMT+13:00) Pacific/Apia"
}, {
  timezone: "Pacific/Enderbury",
  text: "(GMT+13:00) Pacific/Enderbury"
}, {
  timezone: "Pacific/Fakaofo",
  text: "(GMT+13:00) Pacific/Fakaofo"
}, {
  timezone: "Pacific/Tongatapu",
  text: "(GMT+13:00) Pacific/Tongatapu"
}, {
  timezone: "Pacific/Kiritimati",
  text: "(GMT+14:00) Pacific/Kiritimati"
}]