import React, { Component } from 'react';
import {
    Button,
    Modal,
    FormGroup,
    Form,
    FormControl,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { Fragment } from 'react';

const CancelStatus = ['confirmed', 'booked', 'pending']

class CancelButton extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleIncidentReasonChanged = (e) => {
        this.setState({
            reason: e.target.value,
        });
    };

    handleRefundFareChanged = (e) => {
        this.setState({
            refundFare: e.target.checked,
        });
    };

    closeModal = () => {
        this.setState({ visible: false });
    }

    showConfirm = () => {
        this.setState({ visible: true, reason: '', refundFare: false });
    }

    handleSubmit = (e) => {
        this.props.onSubmit({
            reason: this.state.reason
        })
    }

    render() {
        const {
            tripInfo,
        } = this.props;

        const { reason, refundFare, visible } = this.state;
        if (!tripInfo || !CancelStatus.includes(tripInfo.status)) {
            return null;
        };

        return (
            <Fragment>
                <Button onClick={this.showConfirm} className="btn-cancel mr">
                    <Translate value="tripDetails.cancelTrip" />
                </Button>
                <Modal
                    show={visible}
                    backdrop={true}
                    dialogClassName="confirm-dialog"
                    onHide={this.closeModal}
                    className={'confirm'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate value="intercityBooking.ConfirmCancel" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <FormGroup>
              <Form.Label>
                <Translate value="bookingdetail.Input_reason" />
              </Form.Label>
              <FormControl
                onChange={this.handleIncidentReasonChanged}
                type="text"
                value={reason}
                className="form-custom"
              />
            </FormGroup> */}

                        <FormGroup>
                            <Form.Label>
                                <Translate value="tripDetails.note_cancel" />
                            </Form.Label>
                        </FormGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn-cancel" onClick={this.closeModal}>
                            <Translate value="bookingdetail.Cancel" />
                        </Button>
                        <Button
                            className="btn-save"
                            //   disabled={!reason}
                            onClick={this.handleSubmit}
                        >
                            <Translate value="bookingdetail.Ok" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default CancelButton;
