import React, { Component } from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../../../../components/validator';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';

export default class InputCurrency extends Component {
  render() {
    const {
      defaultTopupData,
      auth,
      currency,
      hasPermission,
      handleInputCurrenciesChange,
      keyApp,
      DefaultCurenciesInputField,
      ValidatorCallback,
      isSubmited,
      valid,
      validateDataMinMax,
      maximumValueByCurrenciesProps,
      minimumValueByCurrenciesProps,
      creditWalletConfig: {
        enable,
        maximumByCurrencies = [],
        minimumByCurrencies = [],
      } = {},
    } = this.props;
    const currencyCurrent = currency || auth.selectedFleet.currencies[0];

    const maximumObjectByCurrencies = maximumByCurrencies.find(
      (m) => m.currencyISO === currencyCurrent.iso
    );
    const minimumObjectByCurrencies = minimumByCurrencies.find(
      (m) => m.currencyISO === currencyCurrent.iso
    );

    // const maximumValueByCurrencies = maximumObjectByCurrencies && maximumObjectByCurrencies.value > 0 ? maximumObjectByCurrencies.value : 0;
    // const minimumValueByCurrencies = minimumObjectByCurrencies ? minimumObjectByCurrencies.value : 0;
    const maximumValueByCurrencies = parseFloat(maximumValueByCurrenciesProps);
    const minimumValueByCurrencies = parseFloat(minimumValueByCurrenciesProps);

    const errMessage =
      maximumValueByCurrencies > 0 &&
      maximumValueByCurrencies > minimumValueByCurrencies
        ? 'value_must_between'
        : 'greater_or_equa';
    return (
      <FormGroup
        className={
          !isSubmited || !enable
            ? null
            : valid[`${keyApp}App`] === false
            ? 'error'
            : null
        }
      >
        {[0, 1, 2].map((ind) => {
          const currentValue =
            !defaultTopupData || !defaultTopupData[ind]
              ? null
              : defaultTopupData[ind].find(
                  (df) => df.currencyISO === currencyCurrent.iso
                );
          return (
            <React.Fragment key={ind}>
              <Form.Label>
                <Translate
                  value="generalSetting.Default_value"
                  className="mr-sm"
                />
                {ind + 1}
                <span className="require ml-sm">*</span>
              </Form.Label>
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-left ${
                    !hasPermission ? 'disabled' : ''
                  }`}
                >
                  <InputGroup.Prepend>
                    <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
                  </InputGroup.Prepend>{' '}
                  <FormControl
                    type="text"
                    className="form-custom no-marginBottom"
                    onChange={(e) => {
                      handleInputCurrenciesChange(
                        currencyCurrent.iso,
                        e,
                        `${keyApp}App`,
                        ind
                      );
                    }}
                    value={
                      currentValue
                        ? currentValue.value
                        : DefaultCurenciesInputField
                    }
                    disabled={!hasPermission}
                  />
                </InputGroup>
              </FormGroup>
            </React.Fragment>
          );
        })}
        <Validator id={`${keyApp}App`} callback={ValidatorCallback}>
          <ValidCase
            valid={validateDataMinMax(
              defaultTopupData,
              currencyCurrent.iso,
              keyApp
            )}
            message={I18n.t(`messages.commonMessages.${errMessage}`).format(
              Math.round(minimumValueByCurrencies * 100) / 100,
              Math.round(maximumValueByCurrencies * 100) / 100
            )}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>
    );
  }
}
/* Test - mayby no need */
/*
onBlur={e => {
    textboxNumberHelper.onBlurHandle(e, event => {
        handleInputCurrenciesChange(currencyCurrent.iso, event, `${keyApp}App`, ind);
    });
}}
onFocus={e => {
    textboxNumberHelper.onfocusHandle(e, event => {
        handleInputCurrenciesChange(currencyCurrent.iso, event, `${keyApp}App`, ind);
    });
}} */
