import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';
import * as commonDataActions from '../../actions/commonDataAction';
import './index.scss';

class AutoSuggestAirport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      value: ''
    };
    this.onSuggestionsFetchRequested = debounce(this.onSuggestionsFetchRequested, 200);
  }

  customerAutocompleteSlectedHandle = (event, data) => {
    const { onSelectAirport } = this.props;
    if (onSelectAirport) {
      onSelectAirport(data);
    }
  };

  onSuggestionsFetchRequested = data => {
    if (data.reason !== 'input-focused') {
      const {
        commonDataActions: { getAllAirportZones }
      } = this.props;

      getAllAirportZones({ textSearch: data.value }).then(res => {
        this.setState({ suggestions: res.res.list });
      });
    }
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  getSuggestionValue = suggestion => suggestion.zoneName;

  renderSuggestion = (suggestion, { query }) => (
    <div className="suggestion-content">
      <div className="name">{suggestion.zoneName}</div>
    </div>
  );

  render() {
    const { suggestions, value } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search airport name, ICAO...',
      value,
      onChange: this.onChange,
      className: 'form-control form-custom'
    };
    return (
      <Autosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.customerAutocompleteSlectedHandle}
        shouldRenderSuggestions={() => true}
        inputProps={inputProps}
      />
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoSuggestAirport);
