import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../../components/validator';

class InputViaCurrency extends React.PureComponent {
  state = {
    valid: {},
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
    this.props.onChangeValid(this.state.valid);
  };

  renderMultiCurrency = (
    selectedFleet,
    rateInfo,
    editable,
    handleCancelInputMultiCurrenciesChange,
    fieldType,
    unitDistance,
    isSubmmitted
  ) => {
    const { valid } = this.state;

    return (
      <Col xs={12} md={12}>
        <Tabs
          id="currencies-tabs-Starting_Now"
          className="currencies-tabs"
          defaultActiveKey={selectedFleet.currencies[0].iso}
          animation={false}
          onSelect={this.tabSelectHandle}
        >
          {selectedFleet.currencies.map((c) => {
            if (rateInfo.feesByCurrencies) {
              const currentValue = rateInfo.feesByCurrencies.filter(
                (d) => d.currencyISO === c.iso
              )[0];
              return (
                <Tab
                  className="currencies-tab-item"
                  eventKey={c.iso}
                  title={c.iso}
                  key={c.iso}
                >
                  <FormGroup
                    className={`qup-input-group ${
                      isSubmmitted
                        ? valid[fieldType] === false
                          ? 'error'
                          : null
                        : null
                    }`}
                    >
                    <InputGroup
                      className={
                        (unitDistance ? null : 'single-addon-left') +
                        (!editable ? ' disabled' : '')
                      }
                      >
                      <InputGroup.Prepend>
                        <InputGroup.Text>{c.symbol}</InputGroup.Text>
                      </InputGroup.Prepend>{' '}
                      <FormControl
                        type="text"
                        onChange={(e) => {
                          handleCancelInputMultiCurrenciesChange(
                            fieldType,
                            c.iso,
                            e
                          );
                        }}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(e, (e) => {
                            handleCancelInputMultiCurrenciesChange(
                              fieldType,
                              c.iso,
                              e
                            );
                          });
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(e, (e) => {
                            handleCancelInputMultiCurrenciesChange(
                              fieldType,
                              c.iso,
                              e
                            );
                          });
                        }}
                        maxLength={15}
                        value={
                          currentValue
                            ? currentValue[fieldType]
                            : DefaultCurenciesNumberInputField
                        }
                        placeholder={I18n.t(`deliveryRate.${fieldType}`)}
                        disabled={!editable}
                      />
                      {unitDistance ? (
                        <InputGroup.Append>
                          <InputGroup.Text>
                            <span>/ </span>
                            {unitDistance}
                          </InputGroup.Text>
                        </InputGroup.Append>
                      ) : null}
                    </InputGroup>

                    <Validator id={fieldType} callback={this.validatorCallback}>
                      <ValidCase
                        valid={
                          rateInfo.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(d[fieldType]) &&
                              isNaN(d[fieldType])
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!isSubmmitted}
                      />
                      <ValidCase
                        valid={
                          rateInfo.feesByCurrencies.filter((d) => {
                            return !Validation.isGreaterOrEqual(
                              d[fieldType],
                              0
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!isSubmmitted}
                      />
                    </Validator>
                  </FormGroup>
                </Tab>
              );
            }
          })}
        </Tabs>
        <WarningFeeInput
          message={I18n.t('message.warningInputChangeOver')}
          className="text-soft-warning"
          disabled={!editable}
          value={
            !rateInfo.feesByCurrencies || !rateInfo.feesByCurrencies.length
              ? null
              : rateInfo.feesByCurrencies.map((fees) => {
                  const { currencyISO } = fees;
                  return {
                    [currencyISO]: fees[fieldType],
                  };
                })
          }
          timeDelay={500}
          typeOfCheck="higherOfLower"
        />
      </Col>
    );
  };

  renderSingleCurrency = (
    selectedFleet,
    rateInfo,
    editable,
    handleCancelInputMultiCurrenciesChange,
    fieldType,
    unitDistance,
    isSubmmitted
  ) => {
    const { valid } = this.state;
    const isShowUnitDistance = unitDistance
      ? rateInfo[fieldType + 'Type'] === 'distance' ||
        fieldType === 'feePerMinute'
        ? true
        : false
      : false;
    return (
      <Col xs={12} md={12}>
        <FormGroup
          className={`qup-input-group ${
            isSubmmitted ? (valid[fieldType] === false ? 'error' : null) : null
          }`}
        >
          <InputGroup
            className={
              (isShowUnitDistance ? null : 'single-addon-left') +
              (!editable ? ' disabled' : '')
            }
          >
            <InputGroup.Prepend>
              <InputGroup.Text>
                {selectedFleet.currencies[0].symbol}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="text"
              onChange={(e) => {
                handleCancelInputMultiCurrenciesChange(
                  fieldType,
                  selectedFleet.currencies[0].iso,
                  e
                );
              }}
              onBlur={(e) => {
                textboxNumberHelper.onBlurHandle(e, (e) => {
                  handleCancelInputMultiCurrenciesChange(
                    fieldType,
                    selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              onFocus={(e) => {
                textboxNumberHelper.onfocusHandle(e, (e) => {
                  handleCancelInputMultiCurrenciesChange(
                    fieldType,
                    selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              maxLength={15}
              value={
                rateInfo
                  ? rateInfo.feesByCurrencies.filter((d) => {
                      return d.currencyISO == selectedFleet.currencies[0].iso;
                    }) &&
                    rateInfo.feesByCurrencies.filter((d) => {
                      return d.currencyISO == selectedFleet.currencies[0].iso;
                    }).length > 0
                    ? rateInfo.feesByCurrencies.filter((d) => {
                        return d.currencyISO == selectedFleet.currencies[0].iso;
                      })[0][fieldType]
                    : DefaultCurenciesNumberInputField
                  : DefaultCurenciesNumberInputField
              }
              placeholder={I18n.t(`deliveryRate.${fieldType}`)}
              disabled={!editable}
            />
            {isShowUnitDistance ? (
              <InputGroup.Append>
                <InputGroup.Text>
                  <span>/ </span>
                  {unitDistance}
                </InputGroup.Text>
              </InputGroup.Append>
            ) : null}
          </InputGroup>

          <Validator id={fieldType} callback={this.validatorCallback}>
            <ValidCase
              valid={
                rateInfo.feesByCurrencies.filter((d) => {
                  return (
                    !Validation.isStringEmpty(d[fieldType]) &&
                    isNaN(d[fieldType])
                  );
                }).length == 0
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={
                rateInfo.feesByCurrencies.filter((d) => {
                  return !Validation.isGreaterOrEqual(d[fieldType], 0);
                }).length == 0
              }
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
              hide={!isSubmmitted}
            />
          </Validator>
        </FormGroup>
        <WarningFeeInput
          message={I18n.t('message.warningInputChangeOver')}
          className="text-soft-warning"
          disabled={!editable}
          value={
            rateInfo
              ? rateInfo.feesByCurrencies.filter((d) => {
                  return d.currencyISO == selectedFleet.currencies[0].iso;
                }) &&
                rateInfo.feesByCurrencies.filter((d) => {
                  return d.currencyISO == selectedFleet.currencies[0].iso;
                }).length > 0
                ? rateInfo.feesByCurrencies.filter((d) => {
                    return d.currencyISO == selectedFleet.currencies[0].iso;
                  })[0].fieldType
                : DefaultCurenciesNumberInputField
              : DefaultCurenciesNumberInputField
          }
          timeDelay={500}
          typeOfCheck="higherOfLower"
        />
      </Col>
    );
  };

  render() {
    const {
      selectedFleet,
      rateInfo,
      editable,
      handleCancelInputMultiCurrenciesChange,
      multiCurrency,
      fieldType,
      unitDistance,
      isSubmmitted,
    } = this.props;
    return (
      <div className="input-with-validator">
        {multiCurrency
          ? this.renderMultiCurrency(
              selectedFleet,
              rateInfo,
              editable,
              handleCancelInputMultiCurrenciesChange,
              fieldType,
              unitDistance,
              isSubmmitted
            )
          : this.renderSingleCurrency(
              selectedFleet,
              rateInfo,
              editable,
              handleCancelInputMultiCurrenciesChange,
              fieldType,
              unitDistance,
              isSubmmitted
            )}
      </div>
    );
  }
}

export default InputViaCurrency;
