import React from 'react';
import * as fieldMappings from './fields';
import { report3rdBooking } from '../../../constants/ApiConfigs';
import BaseReport from '../base';
import _ from 'lodash';
import { connect } from 'react-redux';
import { thirdPartyIntegration } from '../../../constants/commondata';

const ReportThirdPartyBooking = ({ selectedFleet, user }) => {

  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules, { name: 'Report' });
  let checkExport = _.findIndex(report.actions, {
    name: 'Third Party Booking (Booking.com)',
    Export: true,
  });
  let checkOwner =
    !user.isAdmin && typeof user.roles.fleetId !== 'undefined'
      ? user.roles.fleetId.ownerId === user._id
        ? true
        : false
      : true;
  checkExport =
    user.isAdmin || checkOwner ? true : checkExport === -1 ? false : true;
  if (!user.isAdmin && selectedFleet.fleetId === 'applecabssouth') {
    checkExport = false;
  }

  return (
    <div className="content">
      <BaseReport
        title={thirdPartyIntegration.bookingAPI}
        fieldMappings={fieldMappings}
        apiurl={report3rdBooking}
        dateRange
        thirdPartyAction
        search="report.query.search.thirdPartyBooking"
        noexport={!checkExport}
      />
    </div>
  );
};

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReportThirdPartyBooking);
