import { I18n } from 'react-redux-i18n';
import React, { useEffect, useState } from 'react';
import { CCLiteCommonFunc, checkShowCreateInvoicingBtn } from '../../../utils/commonFunctions';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { TRAVELER_TYPE_INVOICE } from '../../../containers/Invoice/CreateInvoiceForm';

const CreateInvoiceBtn = ({
  bookInfo,
  is3rdBooking,
  isApiDemandBooking,
  isSupplierPermission,
  router,
  closeBookForm,
  auth
}) => {
  const [show, setShow] = useState(false)

  useEffect(() =>{
    setShow(checkShowCreateInvoicingBtn({
      bookInfo,
      is3rdBooking,
      isApiDemandBooking,
      isSupplierPermission,
      auth
    }))
  }, [])

  const handleCreateInvoice = () => {
    const invoiceType = bookInfo?.travelerType === 1 ? TRAVELER_TYPE_INVOICE.corporate : TRAVELER_TYPE_INVOICE.individual,
      customerId = bookInfo?.travelerType === 1 ? bookInfo?.corporateInfo?.corporateId : bookInfo?.psgInfo?.userId,
      invoicePeriod = CCLiteCommonFunc.isBookingStatusCompleted(bookInfo.status) ? bookInfo?.finishedAt : bookInfo?.time?.pickUpTime;
    if(customerId && invoicePeriod) {
      closeBookForm();
      router?.push(`/invoice?action=createinvoice&invoiceType=${invoiceType}&customerId=${customerId}&invoicePeriod=${invoicePeriod}`)
    }
  }
  
  if (!show) return null
  return (
    <Button
      onClick={handleCreateInvoice}
      className="btn-send"
      style={{ textTransform: "capitalize" }}
    >
      {I18n.t("invoice.createBtn")}
    </Button>
  );
};

CreateInvoiceBtn.defaultProps = {
  bookInfo: {},
  is3rdBooking: false,
  isApiDemandBooking: false,
  isSupplierPermission: false,
};

CreateInvoiceBtn.propTypes = {
  bookInfo: PropTypes.object,
  is3rdBooking: PropTypes.bool,
  isApiDemandBooking: PropTypes.bool,
  isSupplierPermission: PropTypes.bool,
};


export default CreateInvoiceBtn;
