import React from 'react';
import _ from 'lodash';
import { textboxNumberHelper } from '../../../../../utils/commonFunctions';
import { InputGroup, FormGroup, FormControl } from 'react-bootstrap';

export default function InputMultiCurrencies({
  selectedFleet,
  value = [],
  editable,
  onChange,
  currentCurrency,
  isValidate = false,
}) {
  const handleInputCurrenciesChange = (currency, e) => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (!regrex.test(e.target.value) && isValidate) return;
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    onChange([cur]);
  };
  return (
    <div className="input-with-validator">
      <FormGroup className={'qup-input-group'}>
        <InputGroup
          className={'single-addon-left ' + (!editable ? 'disabled' : '')}
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{currentCurrency.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className={'form-custom'}
            onChange={(e) => {
              handleInputCurrenciesChange(currentCurrency.iso, e);
            }}
            onBlur={(e) => {
              textboxNumberHelper.onBlurHandle(e, (e) => {
                handleInputCurrenciesChange(currentCurrency.iso, e);
              });
            }}
            onFocus={(e) => {
              textboxNumberHelper.onfocusHandle(e, (e) => {
                handleInputCurrenciesChange(currentCurrency.iso, e);
              });
            }}
            value={_.get(value, '[0].value', 0)}
            disabled={!editable}
          />
        </InputGroup>
      </FormGroup>
    </div>
  );
}
