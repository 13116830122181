import ReportUtils from "./../base/utils";

export const fields = [
  {
    key: "checkBox",
    label: "report.result.payToDriver.checkBox",
    isCheckBox: true,
    valueKey: "driverId",
    width: 60
  },
  {
    key: "driverName",
    label: "report.result.payToDriver.driverName"
  },
  {
    key: "totalUnsettledTransactions",
    label: "report.result.payToDriver.totalUnsettledTransactions",
    summary: "totalUnsettledTransactions"
  },
  {
    key: "totalUnsettledAmount",
    label: "report.result.payToDriver.totalUnsettledAmount",
    summary: "totalUnsettledAmount"
  },
  {
    key: "actions",
    label: "report.result.payToDriver.actions",
    actions: [
      {
        label: "report.result.payToDriver.pay",
        name: "payToDriver",
        params: ["driverId"],
        isNotShow: (value, doc) => {
          return false;
        },
        confirm: {
          title: "report.result.payToDriver.pay",
          body: "report.result.payToDriver.confirmPay.body",
          buttonTitle: "report.result.payToDriver.confirmPay.buttonTitle",
          closeButtonText: "report.result.payToDriver.confirmPay.closeButtonText"
        }
      },
      {
        label: "report.result.payToDriver.remove",
        name: "clearSettlement",
        confirm: {
          title: "report.result.payToDriver.remove",
          body: "report.result.payToDriver.confirmClear.body",
          buttonTitle: "report.result.payToDriver.confirmClear.buttonTitle",
          closeButtonText: "report.result.payToDriver.confirmClear.closeButtonText",
        }
      }
    ],
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const fareFields = ["totalUnsettledAmount"];
