import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import {
  Col,
  Row,
  FormGroup,
  Button,
  ButtonToolbar,
  Modal,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import * as intercityRateActions from '../../../../actions/intercityRateActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { Columns } from './tableHeaderData';
import TableActions from '../../../../components/table/tableAction/TableActions';
import AddEditIntercityRateModal from './AddEditIntercityRateModal';
import { BsSearch } from 'react-icons/bs';

class IntercityRate extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      str: '',
    };
  }
  componentDidMount() {
    this.updateDataList();

    // case open from deeplink
    const locationQuery = _.get(this.props.router, 'location.query', {});
    if (locationQuery.rateId) {
      this.handleOpenFromDeeplink(locationQuery.rateId)
      }
  }

  handleOpenFromDeeplink = async (routeId) => {
    this.props.intercityRateActions
      .getDetailIntercityRateRoutes({
        routeId: routeId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((res) => {
        if (res.ok) {
          this.setState({
            showDialog: true,
            dialogData: {
              _id: res?.res?.intercityRateId,
              routeInfo: res.res,
              isDeeplink: true,
            },
            editable: false,
          });
        } else {
          if (res.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Loading_intercity_rate_fail')
            );
          }
        }
      });
  };

  handleSearchChange = (e) => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updateDataList();
    }
  };

  updateDataList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str,
    };

    this.props.intercityRateActions
      .getAllIntercityRates(params)
      .then((data) => {
        if (data.ok && data.res) {
          let { page, total, limit } = data.res;
          this.setState({
            dataList: data.res,
            footerData: { page, total, limit },
          });
        }
      });
  };

  handleAddButtonClick = () => {
    this.setState({ showDialog: true, dialogData: null, editable: true });
  };

  handleUpdateRateSucess = (showDialog = false) => {
    this.setState({ showDialog, dialogData: null });
    this.updateDataList();
  }

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
    });
  };

  handleMenuClick = (key, data) => {
    switch (key) {
      case 'View': {
        this.setState({
          showDialog: true,
          dialogData: data,
          editable: false
        })
        break;
      }
      case 'Edit': {
        this.setState({
          showDialog: true,
          dialogData: data,
          editable: true
        })
        break;
      }
      case 'Status': {
        if (data.isActive) {
          this.doDeactivate(data);
        } else {
          this.doActivate(data);
        }
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
      default:
        break;
    }
  };

  doActivate = (data) => {
    this.props.intercityRateActions
      .activeIntercityRate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: data._id,
      })
      .then((data) => {
        if (data.ok) {
          this.updateDataList();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('intercityRate.Update_intercity_rate_fail')
            );
          }
        }
      });
  };

  doDeactivate = (data) => {
    this.props.intercityRateActions
      .deactiveIntercityRate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: data._id,
      })
      .then((data) => {
        if (data.ok) {
          this.updateDataList();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('intercityRate.Update_intercity_rate_fail')
            );
          }
        }
      });
  };

  handlePaginationSelect = (eventKey) => {
    if (this.state.showDialog && this.state.dialogData) {
    } else {
      this.updateDataList(eventKey);
    }
  };

  handleNumItemsPerPageChange = (e) => {
    let numItemsPerPage = parseInt(e);
    this.updateDataList(0, numItemsPerPage);
  };

  confirmDeleteFlat = () => {
    this.props.intercityRateActions
      .deleteIntercityRate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('intercityRate.Delete_intercity_rate_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('intercityRate.Delete_intercity_rate_fail')
            );
          }
        }
      });
  };

  getTableColumns = () => {
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    let tableColumns = Object.assign([], Columns);

    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (regular) => {
            return (
              <OverlayTrigger
                overlay={<Tooltip id={'tooltip'}>{regular.name}</Tooltip>}
                placement="top"
                delayShow={300}
              >
                <div
                  className="clickable text-ellipsis"
                  onClick={() => {
                    this.handleMenuClick('View', regular);
                  }}
                >
                  {regular.name}
                </div>
              </OverlayTrigger>
            );
          };
          break;

        case 'latestUpdate':
          col.customCell = (regular) => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={regular.latestUpdate}
              />
            );
          };
          break;

        case 'regularStatus':
          col.customCell = (regular) => {
            let statusStr, statusClass;
            if (regular && regular.isActive) {
              statusStr = <Translate value="regular.Active" />;
              statusClass = 'Active';
            } else {
              if (regular && !regular.isActivate) {
                statusStr = <Translate value="regular.Inactive" />;
                statusClass = 'Inactive';
              } else statusStr = null;
            }
            return (
              <div className={statusClass}>
                {!regular.inUse && hasPermission ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', regular);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;

        case 'actions':
          col.customCell = (regular, rowIndex) => {
            if (hasPermission) {
              let actionItems = [
                {
                  label: 'regular.Edit',
                  eventKey: 'Edit',
                },
              ];

              if (!regular.inUse) {
                actionItems.push({
                  label: regular.isActive
                    ? 'regular.Deactivate'
                    : 'regular.Activate',
                  eventKey: 'Status',
                });

                if (!regular.isActive) {
                  actionItems.push({
                    label: 'regular.Delete',
                    eventKey: 'Delete',
                  });
                }
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={regular}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, regular);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', regular);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];

    const hasPermission =
      this.props.permissions && this.props.permissions.actions;

    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="intercityRate.Delete_intercity_rate" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="intercityRate.Delete_confirm_intercity_rate" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteFlat}
              >
                <Translate value="intercityRate.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="intercityRate.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {hasPermission && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="intercityRate.Add" />
              </Button>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
        {this.state.showDialog ? (
          <AddEditIntercityRateModal
            closeDialogForm={this.closeDialogForm}
            onSuccess={this.handleUpdateRateSucess}
            rateInfo={this.state.dialogData}
            editable={this.state.editable}
          />
        ) : null}
      </div>
    );
  }
}

IntercityRate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IntercityRate);
