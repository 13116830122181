import { userType } from '../../constants/commondata'
export default function () {
  let enabledTransport =
    this.props.auth.selectedFleet.generalSetting.importChauffeurBooking
  let enabledDelivery =
    this.props.auth.selectedFleet.delivery.enable &&
    this.props.auth.selectedFleet.generalSetting.importDeliveryBooking
  let {
    isAdmin = false,
    roles = {},
    userType: userTypeProp,
    isImportBooking = false,
    isImportDeliveryBooking = false,
  } = this.props.auth.user || {}
  if (
    userTypeProp === userType.CorporateAdmin ||
    userTypeProp == userType.CorporateUser
  ) {
    // show/hide for corporate
    return {
      enabledTransport: isImportBooking,
      enabledDelivery: isImportDeliveryBooking,
    }
  }
  if (!isAdmin) {
    const { modules = [] } = roles || {}
    const { actions = [] } = modules.find((i) => i.key === 'Cue') || {}
    if (enabledTransport) {
      enabledTransport = actions.some(
        (i) => i.name === 'ImportBookings' && i.isActive
      )
    }
    if (enabledDelivery) {
      enabledDelivery = actions.some(
        (i) => i.name === 'ImportDeliveryBookings' && i.isActive
      )
    }
  }
  return {
    enabledDelivery,
    enabledTransport,
  }
}
