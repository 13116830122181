
export const NEW_DELIVERY_FROM_EXISTED = "NEW_DELIVERY_FROM_EXISTED";
export const NEW_DELIVERY_REQUEST = "NEW_DELIVERY_REQUEST";
export const CLOSE_DELIVERY_FORM = "CLOSE_DELIVERY_FORM";
export const DELIVERY_REQUEST = "DELIVERY_REQUEST";
export const DELIVERY_SUCCESS = "DELIVERY_SUCCESS";
export const DELIVERY_FAILURE = "DELIVERY_FAILURE";

export function newDeliveryBookingFromExistedBooking(data) {
  return { type: NEW_DELIVERY_FROM_EXISTED, data };
}

export function newDeliveryBookingRequest() {
  return { type: NEW_DELIVERY_REQUEST };
}

export function closeDeliveryBookingForm() {
  return { type: CLOSE_DELIVERY_FORM };
}


function deliveryRequest() {
  return {
    type: DELIVERY_REQUEST
  }
};

function deliverySuccess(data) {
  return {
    type: DELIVERY_SUCCESS,
    payload: data.res
  }
};

function deliveryFailure(error) {
  return {
    type: DELIVERY_FAILURE,
    payload: error
  }
};