import React from 'react'
import _ from 'lodash'
import * as commonData from './../../../../constants/commondata'
import { Pagination } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

export default class PaginationBar extends React.Component {
  render() {
    let numberOfPages = 0
    let currentPage = 0
    let range = [<option key={0} value={0}>{0}</option>]
    if (this.props.total) {
      currentPage = this.props.page + 1
      numberOfPages = parseInt(_.ceil(parseFloat(this.props.total) / parseFloat(this.props.size))) || 1;;
      range = _.range(numberOfPages).map(i => <option key={i} value={i}>{i + 1}</option>)
    }
    return (
      <div className='clearfix cue-table-control'>
        <div className='left-controls'>
          <div className='control-group form-inline'>
            <label><Translate value='report.result.itemsPerPage' /></label>&nbsp;
            <select className="form-control" value={this.props.size}
              onChange={this.props.onChangeItemsPerPage}>
              {commonData.itemsPerPage.map(number => <option key={number} value={number}>{number}</option>)}
            </select>
            &nbsp;
            <label><Translate value='report.result.page' /></label>
            &nbsp;
              {currentPage}
            &nbsp;/&nbsp;
              {numberOfPages}
          </div>
        </div>
        {this.renderPagination()}
      </div>
    )
  }

  renderPagination() {
    let numberOfPages = parseInt(_.ceil(parseFloat(this.props.total) / parseInt(this.props.size))) || 1;
    let start = this.props.start
    let end = this.props.end
    let detailsLabel = this.props.total && <label><Translate value="report.result.showPages" from={start} to={end} total={this.props.total} /></label>
      || <label><Translate value="report.result.showPages" from={0} to={0} total={0} /></label>

    return (
      <div className='right-controls clearfix'>
        <div className='control-group form-inline'>
          <Pagination
            prev
            next
            first
            last
            ellipsis={false}
            boundaryLinks={false}
            items={Math.ceil(this.props.total / this.props.size)}
            maxButtons={5}
            activePage={this.props.page + 1}
            onSelect={this.props.onChangePagesRange} />
        </div>
        <div className='control-group form-inline paging-lage'>
          {detailsLabel}
        </div>
      </div>
    )
  }
}