import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import closeIcon from '../../../../assets/images/icons/closeWhite.svg';
import { I18n } from 'react-redux-i18n';

const LIMIT_SEARCH = 10;

const ConfirmDeleteVehicle = ({
  isShow,
  handleCloseModal,
  handleConfrimDelete,
  ...props
}) => {
  const [items, setItems] = useState([]);
  return (
    <Modal
      show={isShow}
      dialogClassName="confirm-dialog"
      backdrop="static"
      bsSize="small"
      className="confirm delete-vehicle-modal"
      onHide={() => handleCloseModal(false)}
    >
      <Modal.Body style={{ backgroundColor: '#24272E' }}>
        <div className="searchVehicleDrv">
          <div
            className="searchTop"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <h4 style={{ color: '#04BE76', fontSize: '16px' }}>Unlink Vehicle?</h4>
            <Button
              className="btn-header text-add-header"
              onClick={() => handleCloseModal(false)}
              style={{ padding: '0' }}
            >
              <img
                src={closeIcon}
                style={{ width: '12px', maxHeight: '50px' }}
              />
            </Button>
          </div>
          <p
            style={{
              padding: '15px 0',
              borderTop: '1px solid #363A44',
              margin: '10px 0',
            }}
          >
            Are you sure you want to unlink this vehicle?
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className="btn-header text-add-header"
              onClick={() => handleCloseModal(false)}
              style={{ marginRight: '20px' }}
            >
              {I18n.t('bookingdetail.Cancel')}
            </Button>
            <Button
              className="btn-save mr-md"
              onClick={() => handleConfrimDelete()}
              style={{ color: 'white' }}
            >
              {I18n.t('bookingdetail.Yes')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteVehicle;
