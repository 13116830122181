import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import {
    Col,
    Row,
    FormGroup,
    Form,
    Button,
    ButtonToolbar,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import RadioButton from '../../../components/radioButton/radio';

class PassengerRefersPax extends Component {
  constructor() {
    super();
    this.state = {
      statusDrvReferDrv: 'inactive',
      hasPermission: false
    }
  }

  componentDidMount() {
    const { auth, settingActions } = this.props;
    const modules = _.get(auth, 'selectedFleet.modules', []);
    const DrvToDrvModule =  _.find(modules, m => (m.name === "Driver to pax"));
    const hasPermission = _.get(_.find(DrvToDrvModule.actions, m => m.name === 'Actions'), 'isActive', false)
    settingActions.getReferralDriverToPaxDetails({
      fleetId: auth.selectedFleet.fleetId
    }).then(referralData => {
      const statusDrvReferDrv = _.get(referralData, 'res.statusDrvReferDrv', 'inactive')
      this.setState({statusDrvReferDrv, hasPermission: hasPermission})
    })
  }

  onChangeOption = (e) => {
    this.setState({
      statusDrvReferDrv: e.target.value
    });
  };

  handleSaveClick = () => {
    this.setState({ isSubmited: true });
    const { statusDrvReferDrv } = this.state;
    this.props.settingActions.saveReferralDriverToDriverDetails({
      statusDrvReferDrv: statusDrvReferDrv,
      fleetId: this.props.auth.selectedFleet.fleetId
    }).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res) {
            this.context.notification('success', I18n.t('referral.refferal_update_successfully'));
            this.setState({ isSubmited: false });
        }
    });
  }

  render() {
    const { statusDrvReferDrv, hasPermission } = this.state;
    return (
      <div
        className="content referral-form custom-form overflow-auto"
        style={{ paddingBottom: 140 }}
      >
        <Row>
          <Col xs={12} md={6} className="pax-to-pax-form">
            <div className="group-general">
              <FormGroupTitle className="ph pv0 mb-lg">
                <Translate value="Sidebar.Settings.Driver_refer_driver" />
              </FormGroupTitle>
              <div className="passenger-field-item">
                <FormGroup>
                  <div className="radio-button-group">
                    <Form.Label>
                      <Translate value="referral.Status" />
                      {' '}
                      {'\u00A0'}
                      <span className="danger"> *</span>
                    </Form.Label>
                    <RadioButton
                      text={I18n.t('referral.Active')}
                      value="active"
                      onChange={this.onChangeOption}
                      name="isActive"
                      id="isActive_0"
                      checked={statusDrvReferDrv === 'active'}
                      disabled={!hasPermission}
                    />
                    <RadioButton
                      text={I18n.t('referral.Inactive')}
                      value="inactive"
                      onChange={this.onChangeOption}
                      name="isActive"
                      id="isActive_1"
                      checked={statusDrvReferDrv === 'inactive'}
                      disabled={!hasPermission}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="passenger-note-item">
                <Form.Label>
                  <p>(<span className="danger">*</span>) <Translate value="referral.Required_fields" /></p>
                </Form.Label>
              </div>
              <div className="passenger-field-item">
                {
                  hasPermission && <ButtonToolbar className="text-center center mt-md">
                    <Button className="btn-save" onClick={this.handleSaveClick}>
                        <Translate value="dispatchSetting.Save" />
                    </Button>
                  </ButtonToolbar>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

PassengerRefersPax.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PassengerRefersPax)