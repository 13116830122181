import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'completedDate',
    label: 'report.result.driverWithdrawal.paymentTime',
    mutate: ReportUtils.formatTime,
    summary: 'size',
    fixed: true
  },
  {
    key: 'createdDate',
    label: 'report.result.driverWithdrawal.requestTime',
    mutate: ReportUtils.formatTime,
    fixed: true
  },
  {
    key: 'referenceId',
    label: 'report.result.driverWithdrawal.referenceId',
    fixed: true
  },
  {
    key: 'driverName',
    label: 'report.result.bookingDetails.driverName'
  },
  {
    key: 'driverPhone',
    label: 'report.result.financial.driverPhone'
  },
  {
    key: 'companyName',
    label: 'report.result.financial.companyName'
  },
  {
    key: 'transactionId',
    label: 'report.result.driverWithdrawal.transactionId',
    width: 250
  },
  {
    key: 'channelCode',
    label: 'withdrawal.channelCode',
    width: 250
  },
  {
    key: 'channelName',
    label: 'withdrawal.channelName',
    width: 250
  },
  {
    key: 'receiverAccount',
    label: 'report.result.operatorLogs.description',
    mutate: (value, doc) => {
      if (doc && doc.destination === "WingBank") {
        return I18n.t("withdrawal.transferToWingBank").format(value);
      }

      if(doc?.withdrawStatus === 'reversed') return 'Withdrawal reversed';
      
      if (doc?.destination === "Xendit") {
        if(doc?.type === 'EWALLET') return I18n.t("withdrawal.transferToEWallet").format(value);
      }

      if (value === 'creditWallet') {
        return I18n.t('withdrawal.transferToCreditWallet');
      }

      return I18n.t('withdrawal.transferToBankAccount').format(value);
    },
    width: 300
  },
  {
    key: 'currentBalance',
    label: 'withdrawal.currentBalance',
    width: 180,
    toolTip: 'report.result.driverWithdrawal.currentBalanceTooltip'
  },
  {
    key: 'requestAmount',
    label: 'withdrawal.requestAmount',
    width: 180
  },
  {
    key: 'amount',
    label: 'withdrawal.transferAmount',
    summary: 'amount',
    negativable: true,
    mutate: (value, doc) => {
      switch (doc.withdrawStatus) {
        case 'reversed':
          return ReportUtils.numberFormat(value * -1, 2, 2);
        default:
          return ReportUtils.numberFormat(value, 2, 2);
      }
    },
    width: 180
  },
  {
    key: 'currencyISO',
    label: 'report.result.creditWallet.currency',
    width: 120
  },
  {
    key: 'withdrawStatus',
    label: 'report.result.driverLoginStatus.status',
    mutate: (value, doc) => I18n.t(`report.result.driverWithdrawal.${value}`),
    width: 120
  },
  {
    key: 'operatorName',
    label: 'report.result.bookingDetails.operator'
  },
  {
    key: 'reason',
    label: 'report.result.driverWithdrawal.reason'
  }
];

export const fareFields = ['amount', 'requestAmount', 'currentBalance'];
export const floatFields = ['currencies'];
