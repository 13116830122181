import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import { associatedApps } from '../../../constants/commondata';
import ReportUtils from './../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.deleteAccountRequest.date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'phone',
    label: 'report.result.deleteAccountRequest.phone',
  },
  {
    key: 'username',
    label: 'report.result.deleteAccountRequest.username',
  },
  {
    key: 'supportId',
    label: 'report.result.deleteAccountRequest.supportId',
  },
  {
    key: 'requestFrom',
    label: 'report.result.deleteAccountRequest.requestFrom',
    mutate: (appType) =>
      I18n.t(
        _.get(_.keyBy(associatedApps, 'value'), [appType, 'label']) || appType
      ),
  },
  {
    key: 'associatedApps',
    label: 'report.result.deleteAccountRequest.associatedApps',
    mutate: (apps) =>
      apps
        .map((appType) =>
          I18n.t(
            _.get(_.keyBy(associatedApps, 'value'), [appType, 'label']) ||
              appType
          )
        )
        .join(', '),
  },
  {
    key: 'reason',
    label: 'report.result.deleteAccountRequest.reason',
  },
];
