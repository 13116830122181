import React from 'react'
import * as _ from 'lodash'
import { BarLoader } from 'react-spinners'

export default function formatBooking(showBookings, isImporting) {
  const bookings = [...showBookings].map((book) => {
    const b = _.cloneDeep(book)
    if (
      b.status === 'Valid' &&
      !b.bookId &&
      isImporting &&
      !_.get(b, ['_meta_', 'imported'])
    ) {
      b.status = (
        <div>
          Processing
          <BarLoader color="#04BE76" />
        </div>
      )
    } else if (_.get(b, ['_meta_', 'importedSuccess'])) {
      b.status = <span className="qup-green">Success</span>
    } else if (!_.get(b, ['_meta_', 'imported']) && b.status === 'Valid') {
      b.status = <span className="qup-green">Valid</span>
    } else {
      b.status = 'Invalid'
      _.set(b, ['_meta_', 'className'], 'qup-bg-red')
    }
    b.request.paymentType =
      [0, 'Cash', 2, 3, 4, 'Direct invoicing', 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 'Cash by sender', 'Cash by recipient'][b.request.paymentType] || ''
    return b
  })
  return bookings
}
