import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Modal,
} from 'react-bootstrap';
// import { bindActionCreators } from 'redux';s
import moment from 'moment-timezone';
// import ReactDOM from 'react-dom';
import _ from 'lodash';
// import formatCurrency from 'currency-formatter';

import UploadFile from '../../../../components/upload/upload';
import UploadImageTemplate from '../../../../components/upload/UploadImageTemplate';
import Select from '../../../../components/select/Select';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import Datetime from '../../../../components/dateTime/DateTime';
import RadioButton from '../../../../components/radioButton/radio';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import LimitSettingFormItem from '../LimitSettingFormItem';
import LimitSettingFormAmount from './LimitSettingFormAmount';
import PromocodeSchedules from './PromocodeSchedules';
import AddCorpForm from './AddCorpForm';

import {
  PromotionType,
  PromotionAmountType,
  PAYMENTS_ONLY_APP,
} from '../../../../constants/commondata';
import { Validator, ValidCase } from '../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  getPermissionViewByName,
} from '../../../../utils/commonFunctions';

const CustomerType = {
  All: 0,
  NewCustomer: 1,
  ReferredCustomers: 2,
  CorporateCustomers: 3,
};

class PromoCodeAddEdit extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmited: false,
      isShowAddCorpForm: false,
      corporateListDefault: [],
      hasPermissionCorporate: false,
      dialogData: {
        corporateList: [],
        budgetLimit: {
          isLimited: false,
        },
        quantity: {
          isLimited: false,
        },
        totalUsesLimit: {
          isLimited: false,
        },
        totalUsesLimitPerDay: {
          isLimited: false,
        },
        quantityLimitPerDay: {
          isLimited: false,
        },
        quantityLimitPerMonth: {
          isLimited: false,
        },
        quantityLimitPerYear: {
          isLimited: false,
        },
        campaign: {
          _id: '',
        },
        promotionCode: '',
        zoneId: [],
        paymentMethodsApply: [],
        promoStatus: PromotionType.Public,
      },
    };
  }

  componentDidMount() {
    const {
      data,
      valid,
      auth,
      isSubmited,
      getCorporateList,
      fleetId,
      selectedFleet,
    } = this.props;
    this.setState({
      valid,
      isSubmited,
    });

    if (data) {
      this.setState({
        dialogData: data,
      });
    }

    const params = {
      limit: 1000,
      query: {
        fleetId: fleetId,
      },
    };
    const permissionCorporate = getPermissionViewByName(auth.user, 'Corporate');
    if (
      _.get(selectedFleet, 'moduleSettings.corporate', false) &&
      permissionCorporate
    ) {
      getCorporateList(params).then((res) => {
        const list = _.get(res, 'res.list', []);
        this.setState({
          corporateListDefault: list,
          hasPermissionCorporate: true,
        });
      });
    }
  }

  handleChange = (event, key, flag = false) => {
    switch (key) {
      case 'CustomerType':
        this.props.onChangeCustomerType(event);
        break;

      case 'budgetLimit':
        this.props.handleBudgetLimitChange(flag, key);
        break;

      case 'budgetLimitDay':
        this.props.handleBudgetLimitChange(flag, key);
        break;

      case 'budgetLimitMonth':
        this.props.handleBudgetLimitChange(flag, key);
        break;

      case 'budgetLimitYear':
        this.props.handleBudgetLimitChange(flag, key);
        break;

      case 'valueLimitPerUse':
        this.props.handleValueUseLimitChange(flag);
        break;

      case 'minimumEstFareApply':
        this.props.handleMinimumEstFareApplyChange(flag);
        break;
      default:
        break;
    }
  };

  handleClickUploadImage = (changeFile, error, key) => {
    this.props.handleImageChange(changeFile, error, key);
    return;
  };

  showAddCorpForm = () => {
    this.setState({ isShowAddCorpForm: !this.state.isShowAddCorpForm });
  };

  validFromDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  convertOldPaymentMethod = (paymentMethodsApply) => {
    return paymentMethodsApply
      ? paymentMethodsApply.map((item) => {
          if (item && parseFloat(item)) {
            switch (parseFloat(item)) {
              case 1:
                return 'cash';
              case 2:
                return 'credit';
              case 4:
                return 'corpCredit';
              case 3:
                return 'mDispatcher';
              case 5:
                return 'directBilling';
              case 6:
                return 'fleetCard';
              case 7:
                return 'prepaid';
              case 8:
                return 'QRCode';
              case 9:
                return 'ApplePay';
              case 13:
                return 'PaxWallet';
              case 14:
                return 'TnGeWallet';
              case 19:
                return 'boosteWallet';
              case 20:
                return 'Vipps';
              case 21:
                return 'exZainCash';
            }
          }
          return item;
        })
      : [];
  };

  saveCorporateList = (newList = []) => {
    this.props.handleCorpCustomerChange(newList);
  };

  handleRemoveCorp = (id, isDisableValidDate) => {
    if (isDisableValidDate) return;
    const corporateList = this.state.dialogData.corporateList || [];
    const newCorps = corporateList.filter((corp) => {
      return corp !== id;
    });
    this.props.handleCorpCustomerChange(newCorps);
  };

  render() {
    const {
      selectedFleet,
      showDialogModal,
      disable,
      isEditable,
      isDetailForm,
      phonesImportExcel,
      isCustomerImportedExcel,
      campaignList,
      currencies,
      zonesToSelect,
      receiverList,
      paymentMethod,
      handleChangeService,
      permissions,
      ValidatorCallback,
      valid,
      isSubmited,
    } = this.props;
    let { dialogData, isShowAddCorpForm, corporateListDefault } = this.state;
    const { value: amountValue = 0 } = dialogData || {};
    const corporateList = dialogData.corporateList || [];
    const isEnabledVerify = selectedFleet.generalSetting.promoForVerifiedPax;
    let isDisableValidDate = dialogData._id ? !isEditable : disable;
    dialogData.paymentMethodsApply = this.convertOldPaymentMethod(
      dialogData.paymentMethodsApply
    );
    let isShowTransport =
      selectedFleet.transport && selectedFleet.transport.enable
        ? selectedFleet.transport.enable
        : false;
    let isShowShuttle =
      selectedFleet.shuttle && selectedFleet.shuttle.enable
        ? selectedFleet.shuttle.enable
        : false;
    let isShowDelivery =
      selectedFleet.delivery && selectedFleet.delivery.enable
        ? selectedFleet.delivery.enable
        : false;
    let isShowFood =
      selectedFleet.food && selectedFleet.food.enable
        ? selectedFleet.food.enable
        : false;
    let isShowMart =
      selectedFleet.mart && selectedFleet.mart.enable
        ? selectedFleet.mart.enable
        : false;
    let isShowIntercity =
      selectedFleet.intercity && selectedFleet.intercity.enable
        ? selectedFleet.intercity.enable
        : false;
    let chargeMinFareWhenApplyPromo = _.get(
      selectedFleet,
      'generalSetting.chargeMinFareWhenApplyPromo',
      false
    );
    const isPercent = dialogData.type === PromotionAmountType.Percent;
    let paymentMethods = [
      ...paymentMethod.individualTypes,
      ...paymentMethod.corporateTypes,
      ...paymentMethod.type3rd,
    ];
    paymentMethods = Array.from(new Set(paymentMethods.map((a) => a.key))).map(
      (key) => {
        return paymentMethods.find((a) => a.key === key);
      }
    );
    paymentMethods = paymentMethods.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    _.remove(paymentMethods, (pm) => PAYMENTS_ONLY_APP.includes(pm.key));
    // Maximum value/use
    let isShowValueLimitPerUse = false;
    if (selectedFleet.appRevision && selectedFleet.appRevision.length > 1) {
      let driversAppVersion = [];
      let versions = {};
      driversAppVersion = selectedFleet.appRevision.filter(
        (item) => item.appName.search('_d') !== -1 && item.isActive
      );
      if (driversAppVersion.length >= 2) {
        driversAppVersion.forEach((item) => {
          let minVersion = item.minRevSupport
            .split('.')
            .map((item) => parseInt(item));
          let key = item.platform;
          versions[key] = minVersion;
        });
      }
      if (
        versions.android &&
        versions.android.length > 2 &&
        versions.android[0] >= 4 &&
        versions.android[1] >= 6 &&
        versions.android[2] >= 4800
      ) {
        if (
          versions.iOS &&
          versions.iOS.length > 2 &&
          versions.iOS[0] >= 4 &&
          versions.iOS[1] >= 6 &&
          versions.iOS[2] >= 4800
        ) {
          isShowValueLimitPerUse = true;
        }
      }
    }

    return (
      <Modal
        onHide={this.props.closeFormModal}
        show={showDialogModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {dialogData._id ? (
              <Translate value="promotionSettings.Promotion_code" />
            ) : (
              <Translate value="promotionSettings.Add_promotion_code" />
            )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeFormModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <FormGroup className="app-banner-wrapper">
            <Form.Label>
              <Translate value="promotionSettings.promoIcon" />{' '}
            </Form.Label>
            <br />
            {dialogData._id && dialogData.promoIcon ? (
              <a
                href={dialogData.promoIcon}
                className="text-active"
                target="_blank"
              >
                <Translate value="promotionSettings.Click_here_to_view" />
              </a>
            ) : null}
            <UploadFile
              id="promoIcon"
              name="promoIcon"
              onChange={(data, error) =>
                this.handleClickUploadImage(data, error, 'promoIcon')
              }
              customTemplate={UploadImageTemplate}
              className="event-upload-image"
              customTemplateProps={{
                fileResult: dialogData.promoIcon || null,
                noImageTitle: 'promotionSettings.Upload_an_image',
              }}
              accepts="image/*"
              onlyImage
              // note={true}
              disabled={isDisableValidDate}
            />
          </FormGroup>

          <FormGroup className="app-banner-wrapper">
            <Form.Label>
              <Translate value="promotionSettings.bannerImage" />{' '}
            </Form.Label>
            <br />
            {dialogData._id && dialogData.bannerImage ? (
              <a
                href={dialogData.bannerImage}
                className="text-active"
                target="_blank"
              >
                <Translate value="promotionSettings.Click_here_to_view" />
              </a>
            ) : null}
            <UploadFile
              id="bannerImage"
              name="bannerImage"
              onChange={(data, error) =>
                this.handleClickUploadImage(data, error, 'bannerImage')
              }
              customTemplate={UploadImageTemplate}
              className="event-upload-image"
              customTemplateProps={{
                fileResult: dialogData.bannerImage || null,
                noImageTitle: 'promotionSettings.Upload_an_image',
              }}
              accepts="image/*"
              onlyImage
              // note={true}
              disabled={isDisableValidDate}
            />
          </FormGroup>

          <FormGroup
            className={
              !isSubmited ? null : valid.promotionCode === true ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="promotionSettings.Promotion_code" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.props.handlePromotionCodeChange}
              value={dialogData ? dialogData.promotionCode : ''}
              placeholder={I18n.t('promotionSettings.Promotion_code')}
              disabled={disable}
            />
            <Validator id="promotionCode" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  !Validation.isStringEmpty(dialogData.promotionCode) &&
                  (dialogData.promotionCode || '').length > 3
                }
                message={
                  dialogData.promotionCode
                    ? I18n.t(
                        'promotionSettings.Please_input_promotion_code_at_least_4_characters'
                      )
                    : I18n.t('promotionSettings.Please_input_promotion_code')
                }
              />
            </Validator>
          </FormGroup>

          <FormGroup
            className={
              !isSubmited ? null : valid.servicePromo === true ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="promotionSettings.Promotion_Services" />
            </Form.Label>
            <div className="pl-xl">
              <Row className="show-grid">
                {isShowTransport ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.transport
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'transport')}
                      text={I18n.t('promotionSettings.transport')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
                {isShowShuttle ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.shuttle
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'shuttle')}
                      text={I18n.t('promotionSettings.shuttle')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
                {isShowIntercity ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.intercity
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'intercity')}
                      text={I18n.t('promotionSettings.intercity')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
                {isShowDelivery ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.parcel
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'parcel')}
                      text={I18n.t('promotionSettings.parcel')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
                {isShowFood ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.food
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'food')}
                      text={I18n.t('promotionSettings.food')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
                {isShowMart ? (
                  <Col md={2}>
                    <CcCheckbox
                      checked={
                        dialogData && dialogData.service
                          ? dialogData.service.mart
                          : false
                      }
                      onChange={(e) => handleChangeService(e, 'mart')}
                      text={I18n.t('promotionSettings.mart')}
                      disabled={disable}
                    />
                  </Col>
                ) : null}
              </Row>
            </div>
            <Validator id="servicePromo" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  dialogData.service &&
                  (dialogData.service.transport === true ||
                    dialogData.service.shuttle === true ||
                    dialogData.service.intercity === true ||
                    dialogData.service.parcel === true ||
                    dialogData.service.food === true ||
                    dialogData.service.mart === true)
                }
                message={I18n.t('messages.commonMessages.choose_at_least_one')}
              />
            </Validator>
          </FormGroup>

          <FormGroup>
            <Form.Label>
              <Translate value="promotionSettings.Promotion_type" />
            </Form.Label>
            <select
              type="select"
              className="form-control form-custom"
              onChange={this.props.handlePromotionTypeChange}
              value={dialogData ? dialogData.promoStatus : ''}
              placeholder={I18n.t('promotionSettings.Promotion_type')}
              disabled={disable}
            >
              <option value={PromotionType.Public}>
                {I18n.t('promotionSettings.Public')}
              </option>
              <option value={PromotionType.Private}>
                {I18n.t('promotionSettings.Private')}
              </option>
              {dialogData.promoStatus === PromotionType.PrivatePoint && (
                <option value={PromotionType.PrivatePoint}>
                  Private (Point system)
                </option>
              )}
            </select>
          </FormGroup>

          {dialogData.promoStatus === PromotionType.Private ||
          dialogData.promoStatus === PromotionType.PrivatePoint ? (
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.receiverList === true
                  ? null
                  : 'error'
              }
            >
              <Form.Label className="label_import">
                <div>
                  <Translate value="message.Receiver_list" />{' '}
                  <span className="require"> *</span>
                </div>
                {!isDetailForm &&
                  (phonesImportExcel.length === 0 ? (
                    <div
                      onClick={this.props.handleImportClick}
                      className="text-add-header btn-import"
                    >
                      <Translate value="customer.Import" />
                    </div>
                  ) : (
                    <div
                      onClick={this.props.handleRemoveClick}
                      className="text-add-header"
                    >
                      {I18n.t('customer.Delete')}
                    </div>
                  ))}
              </Form.Label>
              {isCustomerImportedExcel != true &&
                phonesImportExcel.length == 0 && (
                  <>
                    <Select.Async
                      multi={true}
                      valueKey="id"
                      labelKey="value"
                      cache={false}
                      placeholder={I18n.t(
                        'message.Search_customer_name_customer_phone_number'
                      )}
                      searchPromptText={''}
                      loadingPlaceholder={I18n.t('message.Searching')}
                      noResultsText={I18n.t('message.No_results')}
                      className="receiver-list form-custom custom-select-control none-bg-arrow"
                      value={receiverList}
                      loadOptions={this.props.handleLoadOption}
                      onChange={this.props.handleCustomizedChange}
                      disabled={disable}
                    />
                    <Validator id="receiverList" callback={ValidatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={receiverList && receiverList.length > 0}
                        message={I18n.t(
                          'promotionSettings.Please_choose_at_least_one_customer'
                        )}
                      />
                    </Validator>
                  </>
                )}
              {this.state.fileName && (
                <p className="fileNameImport">{this.state.fileName}</p>
              )}
              {isCustomerImportedExcel && (
                <p className="fileNameImport">{'Imported from excel'}</p>
              )}
            </FormGroup>
          ) : null}

          <FormGroup
            className={
              !isSubmited ? null : valid.campaign ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="promotionSettings.Campaign" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <select
              type="select"
              className="form-control form-custom"
              onChange={this.props.handleCampaignChange}
              value={dialogData.campaign ? dialogData.campaign._id : ''}
              placeholder={I18n.t('promotionSettings.Campaign')}
              disabled={disable}
            >
              <option value="">
                {I18n.t('promotionSettings.Select_campaign')}
              </option>
              {campaignList && campaignList.length > 0
                ? campaignList.map((obj, index) => {
                    return (
                      <option value={obj._id} key={index}>
                        {obj.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <Validator id="campaign" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.campaign._id)}
                message={I18n.t('promotionSettings.Please_select_campaign')}
              />
            </Validator>
          </FormGroup>

          {currencies && currencies.length > 1 ? (
            <FormGroup>
              <Form.Label>
                <Translate value="promotionSettings.Currency" />
              </Form.Label>
              <select
                className="form-control form-custom"
                value={dialogData.currencyISO}
                onChange={this.props.onChangeCurrency}
                disabled={disable}
              >
                {currencies.map((c) => {
                  return (
                    <option value={c.iso} key={c.iso}>
                      {c.iso}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          ) : null}

          <FormGroup
            controlId="formControlsSelectMultiple"
            className={!isSubmited ? null : valid.zoneId ? null : 'error'}
          >
            <Form.Label>
              <Translate value="promotionSettings.Zone" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom select-zone"
              as="select"
              multiple
              onChange={this.props.handleZoneChange}
              value={dialogData.zoneId || []}
              disabled={disable}
            >
              {zonesToSelect.map((z) => {
                return (
                  <option key={z._id} value={z._id}>
                    {z.zoneName}
                  </option>
                );
              })}
            </FormControl>
            <Validator id="zoneId" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={dialogData.zoneId && dialogData.zoneId.length > 0}
                message={I18n.t('driver.ERROR_INPUT_ZONE')}
              />
            </Validator>
          </FormGroup>

          {/* Payment Method Apply */}
          {disable &&
          dialogData.paymentMethodsApply &&
          dialogData.paymentMethodsApply.length === 0 ? null : (
            <FormGroup
              controlId="formControlsSelectMultiple"
              className={
                !isSubmited ? null : valid.paymentMethodsApply ? null : 'error'
              }
            >
              <Form.Label>
                <Translate value="promotionSettings.paymentMethodsApply" />{' '}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                className="form-custom select-zone"
                as="select"
                multiple
                onChange={this.props.handlePaymentMethodsApplyChange}
                value={dialogData.paymentMethodsApply || []}
                disabled={disable}
              >
                {paymentMethods.map((z) => {
                  return (
                    <option key={z.key} value={z.key}>
                      {z.name}
                    </option>
                  );
                })}
              </FormControl>
              <Validator id="paymentMethodsApply" callback={ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={
                    dialogData.paymentMethodsApply &&
                    dialogData.paymentMethodsApply.length > 0
                  }
                  message={I18n.t('driver.ERROR_INPUT_ZONE')}
                />
              </Validator>
            </FormGroup>
          )}

          <FormGroup>
            <Form.Label>
              <Translate value="promotionSettings.Type" />
            </Form.Label>
            <select
              type="select"
              className="form-control form-custom"
              onChange={this.props.handleTypeChange}
              value={dialogData ? dialogData.type : ''}
              placeholder={I18n.t('promotionSettings.Campaign')}
              disabled={disable}
            >
              <option value={PromotionAmountType.Amount}>
                {I18n.t('promotionSettings.Amount')}
              </option>
              <option value={PromotionAmountType.Percent}>
                {I18n.t('promotionSettings.Percent')}
              </option>
            </select>
          </FormGroup>

          {dialogData.type === PromotionAmountType.Amount ? (
            <FormGroup
              className={`qup-input-group ${
                !isSubmited ? null : valid.promotionAmount ? null : 'error'
              }`}
            >
              <Form.Label>
                <Translate value="promotionSettings.Value" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <InputGroup
                className={'single-addon-left ' + (disable ? 'disabled' : '')}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {currencies &&
                    currencies.find((obj) => obj.iso == dialogData.currencyISO)
                      ? currencies.find(
                          (obj) => obj.iso == dialogData.currencyISO
                        ).symbol
                      : ''}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  step={0}
                  min={0}
                  className="form-custom number-no-spin"
                  disabled={disable}
                  onChange={this.props.handleAmountValueChange}
                  value={dialogData.value}
                  // onWheel={e => e.preventDefault()}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </InputGroup>
              <Validator id="promotionAmount" callback={ValidatorCallback}>
                <ValidCase
                  valid={!Validation.isStringEmpty(dialogData.value)}
                  message={I18n.t('promotionSettings.Please_input_value')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isNumber(dialogData.value)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!isSubmited}
                />
                {/* <ValidCase
                                    valid={Validation.isGreaterThan(dialogData.value, 0)}
                                    message={I18n.t(
                                        'messages.commonMessages.greater_than'
                                    ).format(0)}
                                    hide={!isSubmited}
                                /> */}
              </Validator>
            </FormGroup>
          ) : null}

          {dialogData.type === PromotionAmountType.Percent ? (
            <FormGroup
              className={`qup-input-group ${
                !isSubmited ? null : valid.value ? null : 'error'
              }`}
            >
              <Form.Label>
                <Translate value="promotionSettings.Value" />
              </Form.Label>
              <InputGroup
                className={'single-addon-right ' + (disable ? 'disabled' : '')}
              >
                <FormControl
                  type="number"
                  step={0}
                  className="form-custom number-no-spin"
                  min={0}
                  max={100}
                  onChange={this.props.handlePercentValueChange}
                  value={dialogData.value}
                  disabled={disable}
                  // onWheel={e => e.preventDefault()}
                  onWheel={(e) => e.currentTarget.blur()}
                />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>{' '}
              </InputGroup>
              <Validator id="value" callback={ValidatorCallback}>
                <ValidCase
                  valid={!Validation.isStringEmpty(dialogData.value)}
                  message={I18n.t('promotionSettings.Please_input_value')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isNumber(dialogData.value)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isInRange(dialogData.value, 0, 100)}
                  message={I18n.t(
                    'messages.commonMessages.Integer_from_range'
                  ).format(0, 100)}
                  hide={!isSubmited}
                />
                {/* <ValidCase
                                    valid={Validation.isGreaterThan(dialogData.value, 0)}
                                    message={I18n.t(
                                        'messages.commonMessages.greater_than'
                                    ).format(0)}
                                    hide={!isSubmited}
                                /> */}
              </Validator>
            </FormGroup>
          ) : null}

          {/* Validate From */}
          <FormGroup
            className={
              !isSubmited ? null : valid.validFrom ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="promotionSettings.Valid_from" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <Datetime
              onChange={this.props.handleValidFromChanged}
              inputProps={{
                readOnly: true,
                disabled: isDisableValidDate,
              }}
              // value={dialogData.validFrom}
              value={
                dialogData.validFrom
                  ? moment(dialogData.validFrom).format('l')
                  : ''
              }
              isValidDate={this.validFromDate}
              closeOnSelect
              timeFormat={false}
            />
            <Validator id="validFrom" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.validFrom)}
                message={I18n.t('promotionSettings.Please_select_date')}
              />
            </Validator>
          </FormGroup>

          {/* Validate To */}
          <FormGroup
            className={
              !isSubmited ? null : valid.validTo ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="promotionSettings.Valid_to" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <Datetime
              onChange={this.props.handleValidToChanged}
              inputProps={{
                readOnly: true,
                disabled: isDisableValidDate,
              }}
              // value={dialogData.validTo}
              value={
                dialogData.validTo ? moment(dialogData.validTo).format('l') : ''
              }
              isValidDate={this.validFromDate}
              closeOnSelect
              timeFormat={false}
            />
            <Validator id="validTo" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.validTo)}
                message={I18n.t('promotionSettings.Please_select_date')}
              />
            </Validator>
          </FormGroup>

          <FormGroup>
            <Form.Label>
              <Translate value="promotionSettings.Customer_type" />
            </Form.Label>
            <select
              className="form-control form-custom"
              disabled={disable}
              value={
                dialogData.newCustomer
                  ? CustomerType.NewCustomer
                  : dialogData.referredCustomers
                  ? CustomerType.ReferredCustomers
                  : dialogData.corporateCustomers
                  ? CustomerType.CorporateCustomers
                  : CustomerType.All
              }
              onChange={(e) => this.handleChange(e, 'CustomerType')}
            >
              <option value={CustomerType.All}>
                {I18n.t('promotionSettings.Common_customers')}
              </option>
              <option value={CustomerType.NewCustomer}>
                {I18n.t('promotionSettings.New_customer')}
              </option>
              {_.get(selectedFleet, 'moduleSettings.corporate', false) &&
                this.state.hasPermissionCorporate && (
                  <option
                    key={CustomerType.CorporateCustomers}
                    value={CustomerType.CorporateCustomers}
                  >
                    {I18n.t('promotionSettings.CorporateCustomers')}
                  </option>
                )}
              {/* {selectedFleet.referral &&
                                (selectedFleet.referral.enable || selectedFleet.referral.paxReferPax) &&
                                dialogData.promoStatus != PromotionType.Private ? (
                                    <option value={CustomerType.ReferredCustomers}>
                                        {I18n.t('promotionSettings.Referred_customers')}
                                    </option>
                                ) : (
                                    ''
                                )} */}
              {selectedFleet.referral &&
              (selectedFleet.referral.enable ||
                selectedFleet.referral.paxReferPax) ? (
                <option value={CustomerType.ReferredCustomers}>
                  {I18n.t('promotionSettings.Referred_customers')}
                </option>
              ) : (
                ''
              )}
            </select>
          </FormGroup>
          {
            /* modal add corporate form */
            isShowAddCorpForm && (
              <AddCorpForm
                closeCorpForm={this.showAddCorpForm}
                getCorporateList={this.props.getCorporateList}
                fleetId={this.props.fleetId}
                corporateListDefault={corporateListDefault}
                corporateList={corporateList}
                saveCorporateList={this.saveCorporateList}
              />
            )
          }
          {
            /* show list corporate */
            dialogData.corporateCustomers &&
              corporateList &&
              corporateList.length > 0 &&
              corporateListDefault &&
              corporateListDefault.length > 0 && (
                <div className="listCorp">
                  {corporateListDefault.map((corp) => {
                    if (corporateList.includes(corp._id)) {
                      return (
                        <p key={corp._id}>
                          {corp.companyInfo && corp.companyInfo.name}
                          <span>
                            <i
                              className="fa fa-trash fa-2x"
                              onClick={(e) =>
                                this.handleRemoveCorp(
                                  corp._id,
                                  isDisableValidDate
                                )
                              }
                            />
                          </span>
                        </p>
                      );
                    }
                  })}
                </div>
              )
          }
          {
            /* show button to open modal add corporate form */
            permissions &&
              permissions.actions &&
              dialogData.corporateCustomers &&
              !isDisableValidDate && (
                <FormGroup
                  className={
                    !isSubmited ? null : valid['corpList'] ? null : 'error'
                  }
                >
                  <Button
                    className={'btn-save mr-md'}
                    type="button"
                    onClick={this.showAddCorpForm}
                  >
                    <Translate value="generalSetting.Add" />
                  </Button>
                  <Validator id="corpList" callback={ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={
                        !dialogData.corporateCustomers ||
                        (corporateList && corporateList.length > 0)
                      }
                      message={I18n.t('promotionSettings.Please_add_corporate')}
                    />
                  </Validator>
                </FormGroup>
              )
          }
          <FormGroup
            className={
              dialogData.scheduleEnable
                ? !isSubmited
                  ? null
                  : valid.scheduleEnable
                  ? null
                  : 'error'
                : null
            }
          >
            <Form.Label>
              <div className="scheduleEnable mb">
                <Translate value="promotionSettings.schedules" />{' '}
                <CcCheckbox
                  checked={dialogData.scheduleEnable}
                  onChange={this.props.handleChangeScheduleEnable}
                  className="ml mb0"
                  disabled={disable}
                />
              </div>
            </Form.Label>
            {dialogData.scheduleEnable ? (
              <div>
                <PromocodeSchedules
                  schedules={dialogData.schedules}
                  onChange={this.props.handleChangeSchedules}
                  disable={disable}
                />
              </div>
            ) : null}

            {dialogData.scheduleEnable && (
              <Validator id="scheduleEnable" callback={ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={
                    dialogData.scheduleEnable && dialogData.schedules.length > 0
                  }
                  message={I18n.t('promotionSettings.Please_add_schedules')}
                />
              </Validator>
            )}
          </FormGroup>

          <LimitSettingFormAmount
            itemKey="minimumEstFareApply"
            itemValue={dialogData.minimumEstFareApply}
            currencies={currencies}
            currencyISO={dialogData.currencyISO}
            amountValue={amountValue}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.handleChange}
            onChangeValue={this.props.handleMinimumEstFareApplyValueChange}
            diabledInput={disable || dialogData.newCustomer}
            tooltip={
              'The promotion code is only applicable if estimated total fare is equal or greater than set value'
            }
          />

          {/* Maximum value/use */}
          {dialogData.type === PromotionAmountType.Percent ? (
            <LimitSettingFormAmount
              itemKey="valueLimitPerUse"
              itemValue={dialogData.valueLimitPerUse}
              currencies={currencies}
              currencyISO={dialogData.currencyISO}
              amountValue={amountValue}
              disable={disable}
              valid={valid}
              isSubmited={isSubmited}
              ValidatorCallback={ValidatorCallback}
              onChange={this.handleChange}
              onChangeValue={this.props.handleValueUseLimitValueChange}
              diabledInput={disable || dialogData.newCustomer}
            />
          ) : null}

          <LimitSettingFormAmount
            itemKey="budgetLimit"
            itemValue={dialogData.budgetLimit}
            isPercent={isPercent}
            currencies={currencies}
            currencyISO={dialogData.currencyISO}
            amountValue={amountValue}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.handleChange}
            onChangeValue={this.props.handleBudgetValueChange}
            diabledInput={disable || dialogData.newCustomer}
          />

          <LimitSettingFormItem
            itemKey="totalUsesLimit"
            itemValue={dialogData.totalUsesLimit}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable || dialogData.newCustomer}
          />

          <LimitSettingFormAmount
            itemKey="budgetLimitDay"
            budgetLimitValue={dialogData.budgetLimit}
            isPercent={isPercent}
            itemValue={dialogData.budgetLimitDay}
            currencies={currencies}
            currencyISO={dialogData.currencyISO}
            amountValue={amountValue}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.handleChange}
            onChangeValue={this.props.handleBudgetValueChange}
            diabledInput={disable || dialogData.newCustomer}
          />

          <LimitSettingFormItem
            itemKey="totalUsesLimitPerDay"
            itemValue={dialogData.totalUsesLimitPerDay}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable || dialogData.newCustomer}
            limitAmount={
              dialogData.totalUsesLimit.isLimited &&
              dialogData.totalUsesLimit.value
            }
          />
          <LimitSettingFormAmount
            itemKey="budgetLimitMonth"
            itemValue={dialogData.budgetLimitMonth}
            isPercent={isPercent}
            budgetLimitValue={dialogData.budgetLimit}
            currencies={currencies}
            currencyISO={dialogData.currencyISO}
            amountValue={amountValue}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.handleChange}
            onChangeValue={this.props.handleBudgetValueChange}
            diabledInput={disable || dialogData.newCustomer}
          />
          <LimitSettingFormItem
            itemKey="quantityLimitPerMonth"
            itemValue={dialogData.quantityLimitPerMonth}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable}
            limitAmount={
              dialogData.quantity.isLimited && dialogData.quantity.value
            }
          />
          <LimitSettingFormAmount
            itemKey="budgetLimitYear"
            itemValue={dialogData.budgetLimitYear}
            isPercent={isPercent}
            budgetLimitValue={dialogData.budgetLimit}
            currencies={currencies}
            currencyISO={dialogData.currencyISO}
            amountValue={amountValue}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.handleChange}
            onChangeValue={this.props.handleBudgetValueChange}
            diabledInput={disable || dialogData.newCustomer}
          />
          <LimitSettingFormItem
            itemKey="quantityLimitPerYear"
            itemValue={dialogData.quantityLimitPerYear}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable}
            limitAmount={
              dialogData.totalUsesLimit.isLimited &&
              dialogData.totalUsesLimit.value
            }
          />
          <LimitSettingFormItem
            itemKey="quantity"
            itemValue={dialogData.quantity}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable}
          />
          <LimitSettingFormItem
            itemKey="quantityLimitPerDay"
            itemValue={dialogData.quantityLimitPerDay}
            disable={disable}
            valid={valid}
            isSubmited={isSubmited}
            ValidatorCallback={ValidatorCallback}
            onChange={this.props.handleChangeDialogForm}
            diabledInput={disable}
            limitAmount={
              dialogData.quantity.isLimited && dialogData.quantity.value
            }
          />
          {chargeMinFareWhenApplyPromo && (
            <Form.Label>
              <div className="scheduleEnable keepMinFee mb">
                <span>
                  <Translate value="promotionSettings.keepMinFee"></Translate>
                  <QuestionCircleTooltip
                    text={'Flat rate and hourly rate doesn’t apply'}
                  />
                </span>
                <CcCheckbox
                  checked={dialogData.keepMinFee}
                  onChange={this.props.handleChangeKeepMinFee}
                  className="ml mb0"
                  disabled={disable}
                ></CcCheckbox>
              </div>
            </Form.Label>
          )}

          <Form.Label>
            <div className="scheduleEnable applyLimitFinished mb">
              <span>
                <Translate value="promotionSettings.applyWhenBudgetLimit"></Translate>
                <QuestionCircleTooltip
                  customeContent={
                    <div>
                      <p>
                        Example: promotion code value = $10, setting budget/user
                        = $50, user already use $45 from previous bookings. Next
                        booking estimated fare = $20.
                      </p>
                      <ul>
                        <li>
                          If setting is OFF: customer can't apply this promotion
                          code.
                        </li>
                        <li>
                          If setting is ON: customer can apply this promotion
                          code with discount value = $5
                        </li>
                      </ul>
                    </div>
                  }
                />
              </span>
              <CcCheckbox
                checked={dialogData.applyLimitFinished}
                onChange={this.props.handleChangeApplyLimitFinished}
                className="ml mb0"
                disabled={disable}
              ></CcCheckbox>
            </div>
          </Form.Label>

          {isEnabledVerify ? (
            <Form.Label>
              <div className="scheduleEnable keepMinFee mb">
                <span>
                  <span
                    title={I18n.t(
                      'promotionSettings.applyVerifiedCustomersTooltip'
                    )}
                  >
                    {I18n.t('promotionSettings.applyVerifiedCustomers')}
                  </span>
                </span>
                <CcCheckbox
                  checked={dialogData.applyVerifiedCustomers}
                  onChange={this.props.handleApplyVerifiedCustomersChange}
                  className="ml mb0"
                  disabled={disable}
                />
              </div>
            </Form.Label>
          ) : null}
          {dialogData.corporateCustomers ? (
            <Form.Label>
              <div className="scheduleEnable keepMinFee mb">
                <Translate value="promotionSettings.auto_add_corporate" />{' '}
                <CcCheckbox
                  checked={dialogData.isAutoAddCorporate}
                  onChange={this.props.handleAutoSendAddCorporate}
                  className="ml mb0"
                  disabled={disable}
                />
              </div>
            </Form.Label>
          ) : (
            <Form.Label>
              <div className="scheduleEnable keepMinFee mb">
                <Translate value="promotionSettings.Send_an_in_box_automatically_to_new_customers" />{' '}
                <CcCheckbox
                  checked={
                    dialogData.applyVerifiedCustomers && isEnabledVerify
                      ? true
                      : dialogData.autoSendInbox
                  }
                  onChange={this.props.handleAutoSendInboxChange}
                  className="ml mb0"
                  disabled={
                    disable ||
                    (dialogData.applyVerifiedCustomers && isEnabledVerify)
                  }
                />
              </div>
            </Form.Label>
          )}
          <FormGroup>
            <Form.Label>
              <Translate value="promotionSettings.Notes" />
            </Form.Label>
            <FormControl
              as="textarea"
              className="form-custom"
              onChange={this.props.handleNotesChange}
              value={dialogData.notes}
              disabled={disable}
              maxLength={200}
            />
          </FormGroup>

          <FormGroup>
            <Form.Label>
              <Translate value="promotionSettings.termAndCondition" />
            </Form.Label>
            <FormControl
              as="textarea"
              className="form-custom"
              onChange={this.props.handleTermAndConditionChange}
              value={dialogData.termAndCondition}
              disabled={isDisableValidDate}
              // maxLength={500}
            />
          </FormGroup>
          <Form.Label>
            (<span className="require">*</span>):{' '}
            <Translate value="promotionSettings.Required_fields" />
          </Form.Label>
        </Modal.Body>

        <Modal.Footer>
          {!permissions || permissions.actions ? (
            disable ? (
              isEditable ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.props.handleUpdatePromoCode}
                >
                  <Translate value="promotionSettings.Save" />
                </Button>
              ) : (
                <Button
                  className="btn-save mr-md"
                  onClick={this.props.handleEditPromoCode}
                >
                  <Translate value="promotionSettings.Edit" />
                </Button>
              )
            ) : (
              <Button
                className="btn-save mr-md"
                onClick={this.props.saveDialogForm}
              >
                <Translate value="promotionSettings.Save" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={this.props.closeFormModal}>
            <Translate value="promotionSettings.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PromoCodeAddEdit;
