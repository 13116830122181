import {
  ownerAddNewCardApi,
  ownerGetPaymentInfoApi,
  setDefaultCardForFleetOwnerApi,
  deleteCreditTokenForFleetOwnerApi
} from "../constants/ApiConfigs";
import { callApi, UrlBuilder } from "../utils/apiUtils";

export const OWNER_ADD_CARD_REQUEST = "OWNER_ADD_CARD_REQUEST";
export const OWNER_ADD_CARD_SUCCESS = "OWNER_ADD_CARD_SUCCESS";
export const OWNER_ADD_CARD_FAILURE = "OWNER_ADD_CARD_FAILURE";

export const OWNER_PAYMENT_INFO_REQUEST = "OWNER_PAYMENT_INFO_REQUEST";
export const OWNER_PAYMENT_INFO_SUCCESS = "OWNER_PAYMENT_INFO_SUCCESS";
export const OWNER_PAYMENT_INFO_FAILURE = "OWNER_PAYMENT_INFO_FAILURE";

export const SET_DEFAULT_CARD_REQUEST = "SET_DEFAULT_CARD_REQUEST";
export const SET_DEFAULT_CARD_SUCCESS = "SET_DEFAULT_CARD_SUCCESS";
export const SET_DEFAULT_CARD_FAILURE = "SET_DEFAULT_CARD_FAILURE";

export const DELETE_CREDIT_TOKEN_REQUEST = "DELETE_CREDIT_TOKEN_REQUEST";
export const DELETE_CREDIT_TOKEN_SUCCESS = "DELETE_CREDIT_TOKEN_SUCCESS";
export const DELETE_CREDIT_TOKEN_FAILURE = "DELETE_CREDIT_TOKEN_FAILURE";

function ownerAddNewCardRequest() {
  return {
    type: OWNER_ADD_CARD_REQUEST
  };
}

function ownerAddCardSuccess(data) {
  return {
    type: OWNER_ADD_CARD_SUCCESS,
    data: data.res
  };
}

function ownerAddCardFailure(error) {
  return {
    type: OWNER_ADD_CARD_FAILURE,
    error
  };
}

export function ownerAddNewCard(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    ownerAddNewCardApi,
    config,
    ownerAddNewCardRequest(),
    ownerAddCardSuccess,
    ownerAddCardFailure,
    true
  );
}

// get payment info
function ownerGetPaymentInfoResquest() {
  return {
    type: OWNER_PAYMENT_INFO_REQUEST
  };
}

function ownerGetPaymentInfoSuccess(data) {
  return {
    type: OWNER_PAYMENT_INFO_SUCCESS,
    data: data.res
  };
}

function ownerGetPaymentInfoFailure(error) {
  return {
    type: OWNER_PAYMENT_INFO_FAILURE,
    error
  };
}

export function ownerGetPaymentInfo(options = {}) {
  const config = {
    method: "get"
  };
  return callApi(
    UrlBuilder(ownerGetPaymentInfoApi, options),
    config,
    ownerGetPaymentInfoResquest(),
    ownerGetPaymentInfoSuccess,
    ownerGetPaymentInfoFailure,
    true
  );
}

// set default card
function setDefaultCardRequest() {
  return {
    type: SET_DEFAULT_CARD_REQUEST
  };
}

function setDefaultCardSuccess(data) {
  return {
    type: SET_DEFAULT_CARD_SUCCESS,
    data: data.res
  };
}

function setDefaultCardFailure(error) {
  return {
    type: SET_DEFAULT_CARD_FAILURE,
    error
  };
}

export function setDefaultCard(options = {}) {
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    setDefaultCardForFleetOwnerApi,
    config,
    setDefaultCardRequest(),
    setDefaultCardSuccess,
    setDefaultCardFailure,
    true
  );
}

// delete card
function deleteCreditTokenRequest() {
  return {
    type: DELETE_CREDIT_TOKEN_REQUEST
  };
}

function deleteCreditTokenSuccess(data) {
  return {
    type: DELETE_CREDIT_TOKEN_SUCCESS,
    data: data.res
  };
}

function deleteCreditTokenFailure(error) {
  return {
    type: DELETE_CREDIT_TOKEN_FAILURE,
    error
  };
}

export function deleteCreditToken(options = {}) {
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    deleteCreditTokenForFleetOwnerApi,
    config,
    deleteCreditTokenRequest(),
    deleteCreditTokenSuccess,
    deleteCreditTokenFailure,
    true
  );
}
