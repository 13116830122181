import React from "react";
import { QQMap, QQPolygon, QQPolyline } from "../qqMap";
import {ZoneActionStatus} from "../../constants/commondata";

const getTencentCenterPoint = coor => {
  var latLng = new window.qq.maps.LatLngBounds();
  if (coor) {
    latLng.extend(new window.qq.maps.LatLng(coor.lat, coor.lng));
    return latLng.getCenter();
  }
  return null;
};

const buildQQPolygonOption = (active, editable) => {
  if (active) {
    return {
      strokeColor: "#0000FF",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: new window.qq.maps.Color.fromHex("#0000FF", 0.4),
      editable: !!editable,
      zIndex: 2
    };
  }

  let options = {
    strokeColor: "#FFFF00",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: new window.qq.maps.Color.fromHex("#FF0000", 0.4),
    editable: !!editable,
    zIndex: 2
  };
  
  return options;
};


export default props => {
  let parentZone = null;
  let parentZones = null;

  if (props.zoneDraw.parentZone) {
    console.log('-parent zone')
    const paths = props.zoneDraw.parentZone.path.map(
      pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
    );

    parentZone = (
      <QQPolygon
        key={props.zoneDraw.parentZone._id}
        onLoaded={map => {
          props.onMapLoad(`Polygon_${props.zoneDraw.parentZone._id}`, map);
        }}
        path={paths}
        editable={false}
        mapkey={props.zoneDraw.parentZone._id}
        onMouseUp={props.handleMapClick}
        options={{
          strokeColor: "#e89110",
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: new window.qq.maps.Color.fromHex("#d3d3d3", 0.4),
          fillOpacity: 0.4,
          zIndex: 1
        }}
      />
    )
  }

  if (props.zoneDraw.parentZones && props.zoneDraw.parentZones.length) {
    parentZones = props.zoneDraw.parentZones.map(zone => {
      const paths = zone.path.map(
        pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
      );
      return (
        <QQPolygon
          key={zone._id}
          onLoaded={map => {
            props.onMapLoad(`Polygon_${zone._id}`, map);
          }}
          path={paths}
          editable={false}
          mapkey={zone._id}
          onMouseUp={props.handleMapClick}
          options={{
            strokeColor: "#e89110",
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: new window.qq.maps.Color.fromHex("#d3d3d3", 0.4),
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      )
    })
  }

  return (
    <QQMap
      className="fill"
      onLoad={map => {
        props.onMapLoad("map", map);
      }}
      map="zoneMap"
      events={{
        click: props.handleMapClick
      }}
      options={{
        center:
          getTencentCenterPoint(props.center) ||
          getTencentCenterPoint({ lat: 16.059959, lng: 108.224258 })
      }}
    >
      {props.status == ZoneActionStatus.Add
        ? props.zoneDraw.Zones.map((zone, index) => {
            if (
              props.zoneDraw.activeZone &&
              zone._id == props.zoneDraw.activeZone._id
            ) {
              let paths = props.zoneDraw.activeZone.path.map(
                pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
              );
              return (
                <QQPolygon
                  key={zone._id}
                  path={paths}
                  editable={props.editable}
                  onMouseUp={props.onDragEnd}
                  mapkey={`${index}`}
                  options={buildQQPolygonOption(true)}
                />
              );
            } else {
              let paths = zone.path.map(
                pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
              );
              return (
                <QQPolygon
                  key={zone._id}
                  path={paths}
                  onMouseUp={() => {
                    props.onDragEnd(zone);
                  }}
                  mapkey={`${index}`}
                  options={buildQQPolygonOption(false)}
                />
              );
            }
          })
        : ""}
      {props.status == ZoneActionStatus.View ? (
        props.zoneDraw.Zones.map((zone, index) => {
          if (
            props.zoneDraw.activeZone &&
            zone._id == props.zoneDraw.activeZone._id
          ) {
            let paths = zone.path.map(
              pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
            );
            return (
              <QQPolygon
                onLoaded={map => {
                  props.onMapLoad(`Polygon_${zone._id}}`, map);
                }}
                key={zone._id}
                path={paths}
                editable={props.editable}
                mapkey={`${index}`}
                options={buildQQPolygonOption(true)}
              />
            );
          } else {
            let paths = zone.path.map(
              pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
            );
            return (
              <QQPolygon
                onLoaded={map => {
                  props.onMapLoad(`Polygon_${zone._id}`, map);
                }}
                key={zone._id}
                path={paths}
                events={{
                  mouseup: () => {
                    props.onDragEnd(zone);
                  }
                }}
                mapkey={`${index}`}
                options={buildQQPolygonOption(false)}
              />
            );
          }
        })
      ) : props.status == ZoneActionStatus.Edit ? (
        props.zoneDraw.Zones.map((zone, index) => {
          if (
            props.zoneDraw.activeZone &&
            zone._id == props.zoneDraw.activeZone._id
          ) {
            let paths = props.zoneDraw.activeZone.path.map(
              pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
            );

            return props.polyline ? (
              <QQPolyline
                onLoaded={map => {
                  props.onMapLoad("Polyline", map);
                }}
                path={paths}
                options={buildQQPolygonOption(true, props.editable)}
                mapkey={`${index}`}
                events={{
                  click: props.handlePolylineClick
                }}
              />
            ) : (
              <QQPolygon
                onLoaded={map => {
                  props.onMapLoad(`Polygon_${zone._id}`, map);
                }}
                key={zone._id}
                path={paths}
                editable={props.editable}
                onMouseUp={props.onDragEnd}
                mapkey={`${index}`}
                options={buildQQPolygonOption(true)}
              />
            );
          } else {
            let paths = zone.path.map(
              pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
            );
            return (
              <QQPolygon
                key={zone._id}
                path={paths}
                onMouseUp={() => {
                  props.onDragEnd(zone);
                }}
                mapkey={`${index}`}
                options={buildQQPolygonOption(false)}
              />
            );
          }
        })
      ) : props.polyline ? (
        props.zoneDraw.activeZone.path &&
        props.zoneDraw.activeZone.path.length > 0 ? (
          <QQPolyline
            onLoaded={map => {
              console.log("---- add driver polyline ----")
              props.onMapLoad("Polyline", map);
            }}
            events={{
              click: props.handlePolylineClick
            }}
            path={props.zoneDraw.activeZone.path.map(
              pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
            )}
            mapkey={"Polyline"}
            options={buildQQPolygonOption(true, props.editable)}
          />
        ) : (
          ""
        )
      ) : (
        <QQPolygon
          onLoaded={map => {
            console.log("---- add driver poly gon ----")
            props.onMapLoad("Polygon", map);
          }}
          path={props.zoneDraw.activeZone.path.map(
            pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
          )}
          editable={props.editable}
          onMouseUp={props.onDragEnd}
          options={buildQQPolygonOption(true, props.editable)}
          mapkey={"Polygon"}
        />
      )}
      {parentZone}
      {parentZones}
    </QQMap>
  );
};
