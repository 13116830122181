import React, { Component } from "react";
import {
  FormGroup,
  FormControl
} from "react-bootstrap";
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import { I18n } from "react-redux-i18n";
import Carousel, { Modal, ModalGateway } from 'react-images';
import {
  CCLiteCommonFunc, getSuppilerPermission
} from '../../../utils/commonFunctions';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false
    };
  }

  toggleLightbox = (selectedIndex) => {
    this.setState(state => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const {
      data,
      farmIn,
      farmOut,
      handleChangeNote,
      handleChangeAffiliateNote,
      handleChangeNoteSuperHelper,
      cartypeSelected,
      isNewBookingHydra,
      isNotesEnable,
      selectedFleet,
      isHydraBooking,
      handleChangeOperatorNote
    } = this.props;
    const {lightboxIsOpen, selectedIndex} = this.state

    const isBookingDetail = !!data.bookId;
    const superHelper = (CCLiteCommonFunc.isBookingStatusCompleted(data.status) && data.superHelper) ? 
        true : cartypeSelected && cartypeSelected.superHelper
    const instructionDisable = data.status == 'engaged' || data.status == 'droppedOff'
    const instructions = data.request && data.request.instructions || {}
    const s3Host = selectedFleet.s3Host || ''

    // if(isHydraBooking) {
    //   return (
    //     <FormGroup>
    //       <FormGroupTitle>
    //         {I18n.t("newbooking.driverNotes")}
    //       </FormGroupTitle>
    //       <FormControl
    //         type="text"
    //         as="textarea"
    //         onChange={handleChangeNote}
    //         value={isBookingDetail ? data.request.note : data.note}
    //         rows={4}
    //         maxLength={1000}
    //         className={"form-custom"}
    //         // placeholder={I18n.t("newbooking.Note")}
    //         placeholder={I18n.t("newbooking.note_for_driver")}
    //         disabled={isBookingDetail ? !isNotesEnable(data) : false}
    //       />
    //     </FormGroup>
    //   )
    // }

    return (
      <>
        {
          superHelper ? (
            <div className={"CtSuperHelper"}>
              <FormGroup>
                <FormGroupTitle> 
                  {I18n.t("newbooking.instructions")}
                </FormGroupTitle>
                <FormControl
                  className="form-custom superHelper"
                  as="textarea"
                  placeholder={I18n.t("newbooking.instructions_placeholder")}
                  rows={4}
                  maxLength={1000}
                  disabled={instructionDisable}
                  onChange={handleChangeNoteSuperHelper}
                  value={instructions.content}
                />
              </FormGroup>
              {instructions && instructions.images &&
                <>
                  <div className="imgSuperHelper">
                    {instructions.images.map((url, j) => (
                      <div key={j} className="shImage">
                        <img
                          onClick={() => this.toggleLightbox(j)}
                          src={s3Host + url}
                        />
                      </div>
                    ))}
                  </div>
                  <ModalGateway>
                    {lightboxIsOpen ? (
                      <Modal onClose={this.toggleLightbox}>
                        <Carousel 
                          views={instructions.images.map((url, j) => ({source: s3Host + url}))}
                          currentIndex={selectedIndex}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </>
              }
            </div>
          ) : (
            <FormGroup>
              <FormGroupTitle> 
                {I18n.t("newbooking.driverNotes")} 
              </FormGroupTitle>
              <FormControl
                type="text"
                as="textarea"
                onChange={handleChangeNote}
                value={isBookingDetail ? data.request.note : data.note}
                rows={4}
                maxLength={1000}
                className={"form-custom"}
                placeholder={I18n.t("newbooking.note_for_driver")}
                disabled={isBookingDetail ? !isNotesEnable(data) : false}
              />
            </FormGroup>
          )
        }
        {
          (isHydraBooking || farmIn)
          && (
            <>
              <FormGroupTitle>
                {I18n.t("newbooking.affiliate_note")}
              </FormGroupTitle>
              <FormGroup>
                <FormControl
                  type="text"
                  as="textarea"
                  onChange={handleChangeAffiliateNote}
                  disabled={isBookingDetail ? !isNotesEnable(data) : false}
                  value={isBookingDetail ? data.request.affiliateNote : data.affiliateNote}
                  rows={4}
                  maxLength={1000}
                  className={'form-custom'}
                  placeholder={I18n.t('newbooking.affiliate_note_placeholder')}
                />
              </FormGroup>
            </>
          )
        }
        {
          !farmIn 
          && !getSuppilerPermission(this.props.auth)
          && (
            <>
              <FormGroupTitle>
                {I18n.t("newbooking.OPERATOR_NOTE")}
              </FormGroupTitle>
              <FormGroup>
                <FormControl
                  type="text"
                  as="textarea"
                  onChange={handleChangeOperatorNote}
                  disabled={isBookingDetail ? !isNotesEnable(data) : false}
                  value={isBookingDetail ? data.request.operatorNote : data.operatorNote}
                  rows={4}
                  maxLength={1000}
                  className={'form-custom'}
                  // placeholder={I18n.t("newbooking.Note")}
                  placeholder={I18n.t('newbooking.OPERATOR_NOTE')}
                />
              </FormGroup>
            </>
          )
        }
      </>
    );
  }
}

export default Notes;
