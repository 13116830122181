import React, { Component } from "react";
import { FormGroup, Image } from "react-bootstrap";
import { QQAutocomplete } from "../../qqMap";
import map_icon from "./../../../assets/images/icons/map_autocomplete.svg";
import recent_icon from "./../../../assets/images/icons/recent.svg";
import PlacesThirdPartyAutocomplete from "./../../placesThirdPartyAutocomplete/PlacesThirdPartyAutocomplete";
import { I18n, Translate } from "react-redux-i18n";
import AutocompleteWith3rd from '../../../components/qqMap/AutocompleteWith3rd'
import OverlayCC from "../../OverlayCC";

class LocationInput3rdTenent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false
    };
  }

  renderRecentItem = (p, index) => {
    const { onSelectRecent, thirdParty } = this.props;

    return (
      <li className="place-item" key={index}>
        <a
          onClick={() => {
            this.setState(
              {
                showOverlay: false
              },
              () => {
                thirdParty
                  ? onSelectRecent(p.address, {
                    location: {
                      address: p.address,
                      coordinates: p.geo,
                      city: p.city,
                      country: p.country
                    }
                  })
                  : onSelectRecent({
                    address: p.address,
                    coordinates: p.geo,
                    city: p.city,
                    country: p.country,
                    addressDetails: p.addressDetails
                  });
              }
            );
          }}
        >
          <i className="fa fa-map-marker" />
          <span>{p.address}</span>
        </a>
      </li>
    );
  };

  setShowOverlay = (showOverlay) => {
    this.setState({ showOverlay });
  }

  render() {
    const {
      recents,
      address,
      onChange,
      onRemove,
      onSelect,
      disabled,
      autoFocus,
      className,
      pickUp,
      onSelectParty,
      input3rd,
    } = this.props;

    return (
      <div>
        {
          input3rd ? (
            <FormGroup
              className={
                "place-container" +
                (address ? " has-address" : "") +
                (recents && recents.length ? " has-recent-input" : "") +
                (className ? " " + className : "")
              }
            >
              {!disabled && recents && recents.length ? (
                <React.Fragment>
                  <OverlayCC
                    childrenAction={
                      <div className={"recent-container"}>
                        <Image
                          className="recent-btn"
                          src={recent_icon}
                        />
                      </div>
                    }
                    childrenOverlay={
                      <div className={"recent-places-container chauffeurBK"}>
                        <ul className="list-unstyled">
                          {recents.map((item, index) =>
                            this.renderRecentItem(item, index)
                          )}
                        </ul>
                      </div>
                    }
                    setShowOverlay={this.setShowOverlay}
                    showOverlay={this.state.showOverlay}
                  />
                </React.Fragment>
              ) : (
                  ""
                )}
              <>
                <PlacesThirdPartyAutocomplete
                  value={address}
                  onChange={onChange}
                  handleAddressRemoved={onRemove}
                  placeholder={I18n.t("newbooking.Enter_3rd_party_location")}
                  styles={{}}
                  onSelect={onSelectParty}
                  classNames={recents && recents.length > 0 ? "has-recent" : ""}
                  disabled={disabled}
                  autoFocus={autoFocus}
                  pickUp={pickUp}
                  data={this.props.data}
                />
              </>
            </FormGroup>
          ) : (
            <FormGroup
              className={
                "place-container" +
                (address ? " has-address" : "") +
                (recents && recents.length ? " has-recent-input" : "") +
                (className ? " " + className : "")
              }
            >
              {!disabled && recents && recents.length ? (
                <React.Fragment>
                  <OverlayCC
                    childrenAction={
                      <div className={"recent-container"}>
                        <Image
                          className="recent-btn"
                          src={recent_icon}
                        />
                      </div>
                    }
                    childrenOverlay={
                      <div className={"recent-places-container chauffeurBK"}>
                        <ul className="list-unstyled">
                          {recents.map((item, index) =>
                            this.renderRecentItem(item, index)
                          )}
                        </ul>
                      </div>
                    }
                    setShowOverlay={this.setShowOverlay}
                    showOverlay={this.state.showOverlay}
                  />
                </React.Fragment>
              ) : (
                  ""
                )}
              <AutocompleteWith3rd 
                value={address}
                onSelect={onSelect}
                handleAddressRemoved={onRemove}
                onChange={onChange}
                disabled={disabled}
                autoFocus={autoFocus}
                classNames={recents && recents.length > 0 ? "has-recent" : ""}
                data={this.props.data}
              />
            </FormGroup>
          )
        }
      </div>
    );
  }
}

export default LocationInput3rdTenent;
