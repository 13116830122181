import React from 'react'
import {
  Form,
  FormControl,
  FormGroup
} from 'react-bootstrap'
import { Translate, I18n } from 'react-redux-i18n'
import Select from '../../../../components/select/Select'

export default class Combobox extends React.Component {
  render() {
    let list = this.props.list
    return (
      <FormGroup style={{ marginTop: 10 }}>
        <Form.Label style={{ fontWeight: 'bold' }}><Translate value={this.props.title} /></Form.Label>
        {this.props.search ?
          <Select
            options={
              list.map(item => {
                if (this.props.i18n || item.value === 'all') {
                  item.label = I18n.t(item.label)
                }
                return item
              })
            }
            placeholder={<Translate value='Select driver' />}
            valueKey='value'
            labelKey='label' simpleValue searchable={true}
            value={this.props.value}
            onChange={value => this.props.handleOnChange({}, value, this.props.name)}
            disabled={false} />
          :
          <FormControl as="select"
            value={this.props.value}
            onChange={this.props.handleOnChange}
            name={this.props.name}
          >
            {list.map((item, index) => (
              <option key={index} value={item.value}>
                {this.props.i18n || item.value === 'all' ? I18n.t(item.label) : item.label}
              </option>
            ))}
          </FormControl>}
      </FormGroup>
    )
  }
}
