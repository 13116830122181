import {
    getMerchantListApi,
    findMerchantUsers,
    activateMerchantUser,
    detailsMerchantUser,
    createMerchantUserApi,
    updateMerchantUserApi,
    activateMultipleMerchantUserApi,
    resetPasswordMerchantUserApi,
    detailsMerchant,
    getBankRequirementApi,
    deleteBankInfoMerchantApi,
    walletCreditMerchantBalanceApi,
    walletCashMerchantBalanceApi
} from '../constants/ApiConfigs'
import {
    callApi,
} from "../utils/apiUtils";
const queryString = require('query-string');

export function getMerchantList(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = getMerchantListApi + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}
export function merchanDetails(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };
    const stringified = queryString.stringify(options);
    let uri = detailsMerchant + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}
export function find(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = findMerchantUsers + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}


export function details(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };
    const stringified = queryString.stringify(options);
    let uri = detailsMerchantUser + stringified;
    return callApi(
        uri,
        config,
        null,
        null,
        null,
        true
    );
}

export function create(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        createMerchantUserApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function update(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        updateMerchantUserApi,
        config,
        null,
        null,
        null,
        true
    );
}




// export function count(options = {}) {
//     options = Object.assign({}, options);
//     const config = {
//         method: "post",
//         body: JSON.stringify(options)
//     };
//     return callApi(
//         mDispatcherCountApi,
//         config,
//         null,
//         null,
//         null,
//         true
//     );
// }

// export function deleteMdispatchers(options = {}) {
//     options = Object.assign({}, options);
//     const config = {
//         method: "post",
//         body: JSON.stringify(options)
//     };
//     return callApi(
//         mDispatcherMultipleDeleteApi,
//         config,
//         null,
//         null,
//         null,
//         true
//     );
// }

// export function deleteMdispatcher(options = {}) {
//     options = Object.assign({}, options);
//     const config = {
//         method: "post",
//         body: JSON.stringify(options)
//     };
//     return callApi(
//         mDispatcherDeleteApi,
//         config,
//         null,
//         null,
//         null,
//         true
//     );
// }

export function active(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        activateMerchantUser,
        config,
        null,
        null,
        null,
        true
    );
}

export function multiActive(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        activateMultipleMerchantUserApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function resetPassword(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        resetPasswordMerchantUserApi,
        config,
        null,
        null,
        null,
        true
    );
}

// export function checkExistmDispatcherPhone(options = {}) {
//     options = Object.assign({}, options);
//     const config = {
//         method: "post",
//         body: JSON.stringify(options)
//     };
//     return callApi(
//         checkExistmDispatcherPhoneApi,
//         config,
//         null,
//         null,
//         null,
//         false
//     );
// }

export function getBankRequirement(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: 'post',
        body: JSON.stringify(options)
    };
    return callApi(getBankRequirementApi, config, null, null, null, true);
}



export function deleteBankInfoMerchant(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteBankInfoMerchantApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function walletMerchantBalance(options = {}) {
    options = Object.assign({}, options);
    const config = {
      method: 'post',
      body: JSON.stringify(options)
    };
    let api_url = options.walletType === 'credit' ? walletCreditMerchantBalanceApi : walletCashMerchantBalanceApi
    return callApi(api_url, config, null, null, null, true);
}