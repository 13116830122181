import {
  BOOK_TYPE,
  STATUS_BEFORE_START,
  STATUS_SETTING_JOB_VISIBILITY,
  TYPE_BOOKING_GROUP
} from '../../constants/commondata';
import { checkCorporateUser, getBookingType, getBookingTypeGroup } from '../../utils/commonFunctions';

export const verifyStatusShowUnassignAllBtn = (books = []) => {
  if (!(books?.length > 0)) return false;
  if (books.every(bk => !STATUS_BEFORE_START.includes(bk.status))) return false;

  if (books?.length === 1) {
    return STATUS_BEFORE_START.includes(books[0].status);
  }

  return true;
};

export const getPlateNumberFromBook = ({ booking, isMobile }) => {
  const plateNumber =
    booking?.vehicle?.plateNumber || booking?.drvInfo?.plateNumber || '';
  if (isMobile) {
    let txtFullInfoVhc = plateNumber;
    if (booking?.vehicle?.plateNumber) {
      txtFullInfoVhc = `${booking?.vehicle?.plateNumber}
      ${
        booking?.vehicle?.vehicleModelName
          ? ` ${'\u2022'} ${booking?.vehicle?.vehicleModelName}`
          : ''
      }
      ${
        booking?.vehicle?.vhcColor
          ? ` ${'\u2022'} ${booking?.vehicle?.vhcColor}`
          : ''
      }
    `;
    }
    return txtFullInfoVhc;
  }

  return plateNumber;
};

export const checkListBookingHasAllCompany = (data) => {
  if (data.checkedGroup.length === 1) {
    return !data.checkedGroup.some(
      (item) => item.supplierCompanies && item.supplierCompanies.length > 0
    );
  } else if (data.checkedGroup.length > 1) {
    return data.checkedGroup.some(
      (item) => !item.supplierCompanies || !item.supplierCompanies.length
    );
  }
};

export const checkListBookingCanSetJobVisibility = (data) => {
  if (data.checkedGroup) {
    return data.checkedGroup.some(item => STATUS_SETTING_JOB_VISIBILITY.includes(item.status))
      && data.checkedGroup.some(item => !item.groupId);
  }
  return false;
};

export const checkIsBookNowAndDelivery = (data) => {
  let result = false;
  if (data.length > 0) {
    data.map((item) => {
      let bookType = getBookingType(item);
      let bookingTypeGroup = getBookingTypeGroup(item);
      if (bookType === BOOK_TYPE.now || bookingTypeGroup === TYPE_BOOKING_GROUP.delivery) {
        result = true;
      } else {
        return false;
      }
    });
  }
  return result;
};

export const checkCanPublicPrivateJobVisibility = (user, fleet, bookDetail, books) => {
  return !!(checkListBookingCanSetJobVisibility(bookDetail) && checkListBookingHasAllCompany(bookDetail)
    && !checkIsBookNowAndDelivery(books) && !checkCorporateUser(user)
    && !user?.roles?.isSupplier && fleet?.marketplace?.enable && fleet?.pwa?.enableDriverPWA
    && (!fleet?.supplierCompanies || !fleet?.supplierCompanies?.length));
};
