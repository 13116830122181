import React, { useState } from 'react';
import { DriverType } from '../../../../constants/commondata';
import {
  Col,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { I18n, Translate } from 'react-redux-i18n';
import UploadFile from '../../../../components/upload/upload';
import DateTime from '../../../../components/dateTime/DateTime';
import { ValidCase, Validator } from '../../../../components/validator';
import moment from 'moment-timezone';
import styles from '../../styles/formDocument.module.scss';
import Trash from '../../../../assets/images/icons/delete.svg';
import FileGim from '../../../../assets/images/icons/fileGim.svg';
import closeIcon from '../../../../assets/images/icons/closeWhite.svg';
import fileIcon from '../../../../assets/images/icons/fileIconWhite.svg';
import { Validation } from '../../../../utils/commonFunctions';
import ModalDocument from '../modals/modalDocument';
const FormDocument = (valProps) => {
  const {
    action,
    props,
    state,
    validatorCallback,
    notification,
    handleDriverDocumentChange,
  } = valProps;
  const { detailItem } = state;
  const { commonData, language } = props;
  
  let driverDocuments = [];
  const relativeInfo = state?.relativeInfoData || {};
  if (Object.keys(relativeInfo)?.length) {
    switch (detailItem.driverInfo.driverType) {
      case DriverType.IndividualDriver:
        driverDocuments = relativeInfo.documents.individual;
        break;
      case DriverType.OwnerOperator:
        driverDocuments = relativeInfo.documents.owner;
        break;
      case DriverType.deliveryIndividual:
        driverDocuments = relativeInfo.documents.deliveryIndividual;
        break;
      case DriverType.deliveryCompany:
        driverDocuments = relativeInfo.documents.deliveryCompany;
        break;
      default:
        break;
    }
  }
  const permission = props?.permissions?.actions || (props?.auth?.user?.roles?.isSupplier);
  return (
    <div>
      <Col className="add-edit-part">
        <div className={styles['tableContainer']}>
          <table className={styles['tableCustom']}>
            <tr>
              <th>{I18n.t('driver.name')}</th>
              <th>{I18n.t('driver.expiryDate')}</th>
              <th>{I18n.t('driver.action')}</th>
            </tr>
            {driverDocuments.map((d, index) => {
              let document = detailItem.documentExpiry
                ? detailItem.documentExpiry.find(
                    (doc) => doc.documentKey == d.keyName
                  )
                : null;

              let documentName = '';
              if (
                document &&
                document.documentUrl &&
                document.documentUrl.length > 0
              ) {
                let splitUrl = document.documentUrl.split('/');
                documentName = splitUrl[splitUrl.length - 1];
              }

              let nameDocument =
                d.title && d.title[props.language]
                  ? d.title[props.language]
                  : d.documentName;

              return (
                <tr key={index}>
                  <td>
                    <FormGroup
                      className={
                        !state.isSubmited
                          ? null
                          : state.valid[d.keyName + '-expiredDate'] === false
                          ? 'error'
                          : null
                      }
                      style={{ marginBottom: 0 }}
                    >
                      {nameDocument}
                      {d.isRequired && <span className="require"> *</span>}{' '}
                      {d.isRequired && (
                        <FormGroup
                          style={{ marginBottom: 0 }}
                          className="has-error"
                        >
                          <Validator
                            id={d.keyName}
                            callback={validatorCallback}
                          >
                            <ValidCase
                              key={d.keyName}
                              hide={!state.isSubmited}
                              valid={
                                (state.driverDocumentExpiry[index] &&
                                  state.driverDocumentExpiry[index]
                                    .documentUrl) ||
                                state.driverDocument[d.keyName]
                                  ? true
                                  : false
                              }
                              message={
                                I18n.t('driver.ERROR_INPUT_DOCUMENT') +
                                ' ' +
                                nameDocument
                              }
                            />
                          </Validator>
                        </FormGroup>
                      )}
                    </FormGroup>
                  </td>
                  <td>
                    {state.driverDocumentExpiry[index] &&
                    state.driverDocumentExpiry[index].expiredDate ? (
                      <>
                        <span>
                          {moment(state.driverDocumentExpiry[index].expiredDate)
                            .locale(language.toLowerCase())
                            .format('ll')}
                        </span>
                        {new Date(
                          state.driverDocumentExpiry[index].expiredDate
                        ) < new Date() ? (
                          <span
                            style={{ color: '#F04438' }}
                          >{` (expired)`}</span>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                  {permission && <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        width: '180px',
                      }}
                    >
                      {state.driverDocumentExpiry[index]?.documentUrl ? (
                        <>
                          <div style={{ width: '80px' }}>
                            <a
                              href={
                                state.driverDocumentExpiry[index]?.documentUrl
                              }
                              target="_blank"
                              className="text-active"
                            >
                              <Translate value="driver.Download" />
                            </a>
                          </div>

                          <ModalDocument
                            initState={state.driverDocumentExpiry[index]}
                            indexState={index}
                            nameDocument={nameDocument}
                            d={d}
                            type={'old'}
                            state={state}
                            props={props}
                            notification={notification}
                            handleDriverDocumentChange={
                              handleDriverDocumentChange
                            }
                            validatorCallback={validatorCallback}
                          />
                        </>
                      ) : (
                        <ModalDocument
                          initState={state.driverDocumentExpiry[index]}
                          indexState={index}
                          nameDocument={nameDocument}
                          d={d}
                          type={'new'}
                          state={state}
                          props={props}
                          notification={notification}
                          handleDriverDocumentChange={
                            handleDriverDocumentChange
                          }
                          validatorCallback={validatorCallback}
                        />
                      )}
                      {/* {state.driverDocumentExpiry[index]?.documentUrl && (
                        <div style={{ width: '80px' }}>
                          <a
                            style={{ width: '80px' }}
                            href="javascript:void(0)"
                            className="text-active"
                          >
                            {I18n.t('driver.Delete')}
                          </a>
                        </div>
                      )} */}
                    </div>}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </Col>
    </div>
  );
};

export default FormDocument;
