import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportDocumentExpiryApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
function ReportDocumentExpiry({selectedFleet,user}) {
  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules, {"name": 'Report'});
  let checkExport = _.findIndex(report.actions, {"name": "Document expiry", "Export": true});
  let checkOwner = !user.isAdmin && typeof user.roles.fleetId !== 'undefined' ? user.roles.fleetId.ownerId === user._id ? true : false : true ;
  checkExport = user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
  if(!user.isAdmin && selectedFleet.fleetId === "applecabssouth"){
    checkExport = false;
  }
  return (
    <div className="content">
      <BaseReport
        tittle="Document expiry"
        fieldMappings={fieldMappings}
        apiurl={reportDocumentExpiryApi}
        expiredDates
        company
        driver
        documentExpiryStatus
        nosummary
        noexport={!checkExport}
      />
    </div>
  );
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(ReportDocumentExpiry);
