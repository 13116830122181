import React from "react";
import { Button } from "react-bootstrap";
import { Translate } from "react-redux-i18n";

const UploadAvatarTemplate = ({
  onSelectFile,
  onRemoveFile,
  id,
  accept,
  name,
  disabled,
  isRequiredAvatar,
}) => {

  return (
    <div className={"upload-avatar-container" + (disabled ? " disabled" : "")}>
      <input
        type="file"
        name={name}
        id={id}
        accept={accept}
        className="upload-input"
        onChange={onSelectFile}
        disabled={disabled}
      />
      <Translate value="customer.Edit" />
      {isRequiredAvatar && <span className="require"> *</span>}
    </div>
  );
}

export default UploadAvatarTemplate;
