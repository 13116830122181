import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { Translate } from 'react-redux-i18n';

import DriverInfo from './DriverInfo';


class DriverInfoForm extends PureComponent {
  handleChangeCompany = (company) => {
    this.props.onChange('companyId', _.get(company, '_id'));
  };

  handleChangeDepartureDate = (value) => {
    this.props.onChange('departureDate', value);
  };

  handleChangeDepartureTime = (value) => {
    this.props.onChange('departureTime', value);
  };

  handleChangeCartype = (value) => {
    this.props.onChange('departureTime', null);
  };

  render() {
    const { tripInfo, valid, validatorCallback, isSubmited, isBookDetail, isViewOnly, onChangeDrvInfo, drvInfo } = this.props;
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="tripDetails.DRIVER_JOB" />
        </FormGroupTitle>
        <DriverInfo
          tripInfo={tripInfo}
          drvInfo={drvInfo}
          valid={valid}
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          disabled={isBookDetail}
          isViewOnly={isViewOnly}
          onChangeDrvInfo={onChangeDrvInfo}
        />
      </Fragment>
    );
  }
}

export default DriverInfoForm;
