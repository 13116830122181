import {
    TRIP_REQUEST,
    TRIP_SUCCESS,
    TRIP_FAILURE,
    UPDATE_TRIP_FILTER_REQUEST,
    TRIP_UPDATE_SUCCESS,
    TRIPS_FILTER_PARAMS,
    TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST,
    TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS,
    TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE,
    UPDATE_TRIP_COMMON_DATA,
    CLOSE_TRIP_DETAILS_FORM
} from "../actions/tripAction";
import { CCLiteCommonFunc } from "../utils/commonFunctions";
import { realtimeHelper } from '../utils/realTimeHelper';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

var _ = require('lodash');
// var Moment = require('moment');
// var extendMoment = require('moment-range');

const moment = extendMoment(Moment);

const defaultFilter = {
    sort: {
        time: 1
    },
    capacityStatus: {
        all: true,
        empty: true,
        notLive: true,
        live: true,
        full: true
    },
    tripTypes: {
        all: true,
        scheduled: true,
        requested: true,
        preferred: true,
    },
    carType: [],
    dateFrom: null,
    dateTo: null,
    routeId: "",
    operator: "",
    txtSearch: "",
    routeNumber: null,
    status: {
        all: true,
        confirmed: true,
        started: true,
        intransit: true
    }
};

const initialState = {
    loading: false,
    data: {},
    tripsData: {},
    listActiveSortedIndex: [],
    fillter: defaultFilter,
    error: '',
    common: {
        carTypes: [],
        carType: {},
        companyVehicleTypes: []
    }
};

function getCarTypeValid(state, action) {
    const { common: { companyId, carType, carTypes } } = state;
    if (companyId) {
        const isMatchCurrentCarType = action.payload.indexOf(_.get(carType, 'vehicleType')) !== -1
        const validCarTypes = carTypes.filter((car) => action.payload.indexOf(car.vehicleType) !== -1);
        return isMatchCurrentCarType ? carType : validCarTypes[0]
    }
    return carType || carTypes[0]
};

const trips = (state = initialState, action) => {
    switch (action.type) {
        case TRIP_REQUEST:
            return {
                ...state,
                loading: true,
                // tripsData: {},
                // listActiveSortedIndex: []
            }

        case UPDATE_TRIP_FILTER_REQUEST:
            return {
                ...state,
                loading: true,
                tripsData: {},
                listActiveSortedIndex: []
            }

        case TRIP_SUCCESS:
            let newIndex = [];
            if (action.payload.list.length > 0) {
                action.payload.list.map(item => {
                    if (!state.tripsData[item.tripId]) {
                        newIndex.push(item.tripId);
                    }
                    state.tripsData[item.tripId] = item;
                })
            };
            newIndex = state.listActiveSortedIndex.concat(newIndex);
            newIndex = [...new Set(newIndex)];
            return {
                ...state,
                loading: false,
                data: action.payload,
                listActiveSortedIndex: newIndex,
                error: ''
            }

        case TRIP_FAILURE:
            return {
                loading: false,
                data: [],
                error: action.payload
            }

        case TRIP_UPDATE_SUCCESS:
            if (!state.tripsData[action.payload.tripId]) {
                // state.listActiveSortedIndex.push(action.payload.tripId);

                //Filter data range:
                let dateFrom = state.fillter.dateFrom
                    ? moment(state.fillter.dateFrom)
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toISOString()
                    : null;
                let dateFromRange = dateFrom ? Date.parse(action.payload.startTime) > Date.parse(dateFrom) : true;
                let dateTo = state.fillter.dateTo
                    ? moment(state.fillter.dateTo)
                        .hour(23)
                        .minute(59)
                        .second(0)
                        .millisecond(59)
                        .toISOString()
                    : null;
                let dateToRange = dateTo ? Date.parse(action.payload.startTime) < Date.parse(dateTo) : true;

                //Filter Interciry Route:
                let intercity = state.fillter.routeId !== "" ? (action.payload.route === state.fillter.routeId && action.payload.routeNumber === state.fillter.routeNumber ? true : false) : true;

                //Filter Status trips:
                let status = state.fillter.status.all ? true : (
                    realtimeHelper.checkStatusTrip(state.fillter.status, action.payload)
                );

                //Filter Status Seat:
                let statusSeat = state.fillter.capacityStatus.all ? true : (
                    realtimeHelper.checkSeatsTrip(state.fillter.capacityStatus, action.payload)
                );

                //Filter Trip Type:
                let statusTripTypes = state.fillter.tripTypes.all ? true : (
                    realtimeHelper.checkTripTypes(state.fillter.tripTypes, action.payload)
                );

                //Filter Company user:
                let mappingDriverCompanyId = state.fillter.driverCompany ? realtimeHelper.checkCompany(state.fillter.driverCompany, action.payload) : true;

                if (dateFromRange && dateToRange && intercity && status && statusSeat && statusTripTypes && mappingDriverCompanyId) {
                    state.data.list.push(action.payload);
                    realtimeHelper.sortTripsList(state.fillter.sort, state.data.list);
                };

                let newIndex = [];
                if (state.fillter.txtSearch === "") {
                    state.data.list.map(item => {
                        newIndex.push(item.tripId);
                        state.listActiveSortedIndex = newIndex;
                    })
                };
            };

            if (state.tripsData[action.payload.tripId] && !state.loading) {
                if (action.payload.status === "confirmed"
                    || action.payload.status === "started"
                    || action.payload.status === "intransit"
                    || action.payload.status === "pending") {
                    //Filter Status trips:
                    let status = state.fillter.status.all ? true : (
                        realtimeHelper.checkStatusTrip(state.fillter.status, action.payload)
                    );
                    //Filter Status Seat:
                    let statusSeat = state.fillter.capacityStatus.all ? true : (
                        realtimeHelper.checkSeatsTrip(state.fillter.capacityStatus, action.payload)
                    );

                    //Filter Trip Type:
                    let statusTripTypes = state.fillter.tripTypes.all ? true : (
                        realtimeHelper.checkTripTypes(state.fillter.tripTypes, action.payload)
                    );

                    //Filter Company user:
                    let mappingDriverCompanyId = state.fillter.driverCompany ? realtimeHelper.checkCompany(state.fillter.driverCompany, action.payload) : true;

                    if (status && statusSeat && statusTripTypes && mappingDriverCompanyId && state.data.list.findIndex(x => x.tripId === action.payload.tripId) === -1) {
                        // console.log("Add")
                        state.data.list.push(action.payload);
                        realtimeHelper.sortTripsList(state.fillter.sort, state.data.list);
                    };
                    if (!status && state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                        // console.log("Remove")
                        if (state.listActiveSortedIndex.indexOf(action.payload.tripId) !== -1) {
                            state.listActiveSortedIndex.splice(state.listActiveSortedIndex.indexOf(action.payload.tripId), 1);
                        };
                        if (state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                            state.data.list.splice(state.data.list.findIndex(x => x.tripId === action.payload.tripId), 1);
                        };
                    };
                    if (!statusSeat && state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                        // console.log("Remove")
                        if (state.listActiveSortedIndex.indexOf(action.payload.tripId) !== -1) {
                            state.listActiveSortedIndex.splice(state.listActiveSortedIndex.indexOf(action.payload.tripId), 1);
                        };
                        if (state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                            state.data.list.splice(state.data.list.findIndex(x => x.tripId === action.payload.tripId), 1);
                        };
                    };
                    if (!mappingDriverCompanyId && state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                        if (state.listActiveSortedIndex.indexOf(action.payload.tripId) !== -1) {
                            state.listActiveSortedIndex.splice(state.listActiveSortedIndex.indexOf(action.payload.tripId), 1);
                        };
                        if (state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                            state.data.list.splice(state.data.list.findIndex(x => x.tripId === action.payload.tripId), 1);
                        };
                    };
                    let newIndex = [];
                    if (state.fillter.txtSearch === "") {
                        state.data.list.map(item => {
                            newIndex.push(item.tripId);
                            state.listActiveSortedIndex = newIndex;
                        })
                    };
                };
            };

            if (action.payload.status === "canceled" || action.payload.status === "completed" || action.payload.status === "incident" || action.payload.status === "noShow") {
                if (state.listActiveSortedIndex.indexOf(action.payload.tripId) !== -1) {
                    state.listActiveSortedIndex.splice(state.listActiveSortedIndex.indexOf(action.payload.tripId), 1);
                };
                if (state.data.list.findIndex(x => x.tripId === action.payload.tripId) !== -1) {
                    state.data.list.splice(state.data.list.findIndex(x => x.tripId === action.payload.tripId), 1);
                };
            };
            state.tripsData[action.payload.tripId] = action.payload;

            return {
                ...state
            }

        case TRIPS_FILTER_PARAMS:
            const data = action.payload || {};
            state.fillter = Object.assign(state.fillter, data);
            return {
                ...state,
                fillter: state.fillter
            }

        case TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_REQUEST: {
            return {
                ...state,
                common: {
                    ...state.common,
                    carType: {},
                    companyVehicleTypes: [],
                    companyId: action.payload.companyId
                }
            };
        };
        case TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_SUCCESS: {
            return {
                ...state,
                common: {
                    ...state.common,
                    carType: getCarTypeValid(state, action),
                    companyVehicleTypes: action.payload
                }
            };
        };
        case TRIP_FETCH_VEHICLE_TYPE_BY_COMPANY_FAILURE: {
            return {
                ...state,
                common: {
                    ...state.common,
                    companyVehicleTypes: []
                },
                error: action.payload
            };
        };
        case UPDATE_TRIP_COMMON_DATA: {
            return {
                ...state,
                common: {
                    ...state.common,
                    ...action.payload
                }
            };
        };
        case CLOSE_TRIP_DETAILS_FORM: {
            return {
                ...state,
                common: {
                    carTypes: [],
                    carType: {},
                    companyVehicleTypes: []
                }
            };;
        };

        default:
            return state
    }
}

export default trips