import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { set } from 'lodash';
import {
  Col, Row, FormGroup, FormControl, Form, Button, Modal
} from 'react-bootstrap';

import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import { Validator, ValidCase } from '../../../components/validator';
import Datetime from '../../../components/dateTime/DateTime';
import Select from '../../../components/select/Select';

import { CCLiteCommonFunc, Validation, trimPhoneNumber } from '../../../utils/commonFunctions';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

class AddEditCarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: props.dialogData || {
        plateNumber: '',
        color: '',
        caseNumber: '',
        year: null,
        maxPassengers: 0,
        maxLuggage: 0,
        vhcId: null,
        licenseExp: '',
        hwMetered: false,
        vehicleModel: {
          modelId: '',
          name: '',
          vehicleMakeId: '',
          vehicleMake: '',
          avatar: ''
        },
        company: {},
        type: {},
        phone: ''
      },
      phoneValidation: true,
      yearList: [],
      valid: {},
      editable: props.editable,
      colorSelected: null,
      textSearchCarColor: '',
    };
    this.searchCarColorsDebounce = _.debounce(this.searchCarColors, 300);
  }

  componentDidMount() {
    this.fetchModelList();
    this.initCommonData();
    if (this.props.dialogData) {
      const { color } = this.props.dialogData;
      this.setState({ colorSelected: { name: color ? color : '' } })
    }
  }

  initCommonData = () => {
    const { vehicleAgeLimit } = this.props.auth.selectedFleet.generalSetting;
    // SL-29287 User can select next year when input 'Vehicle year' for car type.
    const currentYear = new Date().getFullYear() + 1;
    let yearsLimit = 1999;
    if (vehicleAgeLimit.enable) {
      yearsLimit = currentYear - vehicleAgeLimit.years;
    }
    for (let i = currentYear; i > yearsLimit; i -= 1) {
      this.state.yearList.push(i);
    }
  };

  handlePhoneChange = (status, value, countryData, number) => {
    const { dialogData } = this.state;
    dialogData.phone = number;
    this.setState({
      dialogData,
      phoneValidation: status
    });
  };

  handleInputChange = (key, e) => {
    const { dialogData } = this.props;
    if (key === 'hwMetered') {
      dialogData[key] = e.target.checked;
    } else if (key === 'licenseExp') {
      dialogData[key] = e._d;
    } else if (key === 'plateNumber') {
      dialogData[key] = e.target.value.toUpperCase();
    } else {
      dialogData[key] = e.target.value;
    }
    this.setState({ dialogData });
  };

  handleInputColorChange = (value) => {
    this.setState({ textSearchCarColor: value });
  }

  handleInputBlur = (e) => {
    this.setState({ textSearchCarColor: '' });
  };

  selectChange = (key, e) => {
    switch (key) {
      case 'company': {
        const { companyList } = this.props;
        this.state.dialogData.company = null;
        companyList.forEach(data => {
          if (data._id === e.target.value) {
            this.state.dialogData.company = data;
          }
        });
        break;
      }
      case 'type': {
        const { carTypeList } = this.props;
        this.state.dialogData.type = {};
        carTypeList.forEach(data => {
          if (data._id === e.target.value) {
            this.state.dialogData.type = {
              typeId: data._id
            };
          }
        });
        break;
      }
      case 'vehicleModel_modelId': {
        if (!this.state.dialogData.vehicleModel) {
          this.state.dialogData.vehicleModel = {};
        }
        this.state.dialogData.vehicleModel.modelId = null;
        this.state.modelList.forEach(data => {
          if (data._id == e.target.value) {
            this.state.dialogData.vehicleModel.modelId = data._id;
          }
        });
        break;
      }
      default: {
        this.state.dialogData[key] = e.target.value;
        break;
      }
    }
    this.setState({ dialogData: this.state.dialogData });
  };

  handleChangeVehicleMake = e => {
    const { makeList } = this.props;
    const { dialogData } = this.state;

    dialogData.vehicleModel = dialogData.vehicleModel || {};
    dialogData.vehicleModel.modelId = null;

    const foundMake = makeList.find(o => o._id === e.target.value);
    dialogData.vehicleModel.vehicleMakeId = foundMake ? foundMake._id : null;

    this.setState({ dialogData }, this.fetchModelList);
  };

  saveDialogForm = e => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    const {
      auth: { selectedFleet }
    } = this.props;
    const { dialogData } = this.props;
    const { colorSelected } = this.state;

    const object = {
      fleetId: selectedFleet.fleetId,
      plateNumber: dialogData.plateNumber,
      typeId: dialogData.type ? dialogData.type._id || dialogData.type.typeId : '',
      makeId: dialogData.vehicleModel ? dialogData.vehicleModel.vehicleMakeId : '',
      modelId: dialogData.vehicleModel ? dialogData.vehicleModel.modelId : '',
      year: dialogData.year,
      hwMetered: dialogData.hwMetered,
      companyId: dialogData.company ? dialogData.company._id : '',
      maxPassengers: dialogData.maxPassengers,
      maxLuggage: dialogData.maxLuggage,
      // color: dialogData.color,
      color: colorSelected.name ? colorSelected.name : '',
      caseNumber: dialogData.caseNumber,
      licenseExp: dialogData.licenseExp ? moment(dialogData.licenseExp).format('MM/DD/YYYY') : null,
      vhcId: dialogData.vhcId,
      phone: trimPhoneNumber(dialogData.phone),
      vehicleOwner: dialogData.vehicleOwner,
    };
    dialogData.fleetId = selectedFleet.fleetId;
    this.props.loadingBarActions.showLoadingSpiner();
    if (dialogData._id) {
      object.vehicleId = dialogData._id;
      this.props.settingActions.updateCars(object).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
          } else if (data.message) {
            this.context.notification('error', I18n.t(`errors.${data.message.errorCode}`));
          } else {
            this.context.notification('error', I18n.t('carSetting.Update_car_fail'));
          }
          this.setState({ isSubmitted: false });
        } else {
          this.context.notification('success', I18n.t('carSetting.Update_car_success'));
          this.closeDialogForm();
          this.props.updateDataList();
        }
      });
    } else {
      this.props.settingActions.createCars(object).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
          } else if (data.message) {
            this.context.notification('error', I18n.t(`errors.${data.message.errorCode}`));
          } else {
            this.context.notification('error', I18n.t('carSetting.Create_car_fail'));
          }
          this.setState({ isSubmitted: false });
        } else {
          this.context.notification('success', I18n.t('carSetting.Create_car_success'));
          this.closeDialogForm();
          this.props.updateDataList();
        }
      });
    }
  };

  onEditable = () => {
    this.setState({ editable: true });
  };

  fetchModelList = () => {
    const {
      auth: { selectedFleet }
    } = this.props;
    const { dialogData } = this.state;
    const vehicleMakeId = _.get(dialogData, 'vehicleModel.vehicleMakeId');
    if (vehicleMakeId) {
      this.props.settingActions
        .getCarModelList({
          fleetId: selectedFleet.fleetId,
          vehicleMakeId
        })
        .then(res => {
          if (res.ok && res.res) {
            this.setState({ modelList: res.res });
          }
        });
    } else {
      this.setState({ modelList: [] });
    }
  };


  closeDialogForm = () => {
    const { closeDialogForm } = this.props;
    closeDialogForm();
  };

  ValidatorCallback = (id, valid) => {
    if (this.state.valid[id] !== valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  renderCarColorNoResult = () => {
    const { textSearchCarColor } = this.state;
    if (!textSearchCarColor) return null;
    return (
      <span>
        {I18n.t('message.No_results')}
        <button className="btn-link" onClick={this.onCreateNewCarColor}>
          Click here to add new
        </button>
        {textSearchCarColor}
      </span>
    );
  };

  onCreateNewCarColor = () => {
    const { textSearchCarColor } = this.state;
    let colorSelected = { name: textSearchCarColor }
    this.handleCarColorChange(colorSelected);
    this.carColorRef.select.blurInput();
  };

  handleLoadOption = (input, callback) => {
    if (!input) return callback();
    const { settingActions, auth } = this.props;
    this.searchCarColorsDebounce(
      auth.selectedFleet.fleetId,
      settingActions.autocompleteCarColor,
      input,
      callback
    );
  };

  searchCarColors = (fleetId, findCarColorAction, input, callback) => {
    const params = {
      str: input,
      fleetId,
      limit: 20,
      page: 0
    };

    findCarColorAction(params)
      .then(data => {
        if (data.ok && data.res && data.res.list.length > 0) {
          const result = { complete: true };
          result.options = (data.res.list || []).map(cat => ({
            name: cat.name,
            _id: cat._id
          }));
          callback(null, result);
        } else callback(null, null);
      })
      .catch(() => callback(null, null));
  };

  handleCarColorChange = (colorSelected) => {
    this.setState({
      colorSelected
    });
  }

  render() {
    const { dialogData, isSubmitted, valid } = this.state;
    const { makeList, companyList, carTypeList, language } = this.props;

    return (
      <Modal show backdrop bsSize="lg" onHide={this.closeDialogForm}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.dialogData._id ? (
              this.state.editable ? (
                <Translate value="carSetting.EDIT_CAR" />
              ) : (
                <Translate value="carSetting.DETAIL_CAR" />
              )
            ) : (
              <Translate value="carSetting.CREATE_CAR" />
            )}
          </Modal.Title>
          <button type="button" className="close" aria-label="Close" onClick={this.closeDialogForm}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  isSubmitted ? (valid.plateNumber === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="carSetting.Plate_Number" />
                  {' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('plateNumber', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.plateNumber : ''}
                  placeholder={I18n.t('carSetting.Plate_Number')}
                  maxLength={25}
                  // required={true}
                  disabled={!this.state.editable}
                />
                <Validator
                  id="plateNumber"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={this.state.dialogData.plateNumber.trim().length > 0}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={isSubmitted ? (valid.type === false ? 'error' : null) : null}
              >
                <Form.Label>
                  <Translate value="carSetting.Car_Type" />
                  {' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  value={this.state.dialogData.type.typeId}
                  onChange={e => {
                    this.selectChange('type', e);
                  }}
                  disabled={!this.state.editable}
                >
                  <option value="">{I18n.t('carSetting.Select_car_type')}</option>
                  {carTypeList.map(z => (
                    <option key={z._id} value={z._id}>
                      {z.vehicleType}
                    </option>
                  ))}
                </FormControl>
                <Validator
                  id="type"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      dialogData.type
                      && dialogData.type.typeId != ''
                      && Object.keys(dialogData.type).length !== 0
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  isSubmitted ? (valid.vehicleOwner === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="carSetting.vehicleOwner" />
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('vehicleOwner', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.vehicleOwner : ''}
                  placeholder={I18n.t('carSetting.vehicleOwner')}
                  maxLength={100}
                  disabled={!this.state.editable}
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Make" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  disabled={!this.state.editable}
                  onChange={this.handleChangeVehicleMake}
                  value={
                    this.state.dialogData.vehicleModel
                      ? this.state.dialogData.vehicleModel.vehicleMakeId
                      : ''
                  }
                >
                  <option>{I18n.t('carSetting.Select_car_make')}</option>
                  {makeList.map(z => (
                    <option key={z._id} value={z._id}>
                      {z.name}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Model" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  disabled={!this.state.editable}
                  onChange={e => {
                    this.selectChange('vehicleModel_modelId', e);
                  }}
                  value={
                    this.state.dialogData.vehicleModel
                      ? this.state.dialogData.vehicleModel.modelId
                      : ''
                  }
                >
                  <option>{I18n.t('carSetting.Select_a_car_model')}</option>
                  {this.state.modelList
                    ? this.state.modelList.map(z => (
                      <option key={z._id} value={z._id}>
                        {z.name}
                      </option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Case_number" />
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('caseNumber', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.caseNumber : ''}
                  placeholder={I18n.t('carSetting.Case_number')}
                  maxLength={50}
                  disabled={!this.state.editable}
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Year" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  disabled={!this.state.editable}
                  onChange={e => {
                    this.selectChange('year', e);
                  }}
                  value={this.state.dialogData.year}
                >
                  <option>{I18n.t('carSetting.Select_year')}</option>
                  {this.state.yearList
                    ? this.state.yearList.map(z => (
                      <option key={z} value={z}>
                        {z}
                      </option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup className={!isSubmitted ? null : valid.phone ? null : 'error'}>
                <Form.Label>
                  <Translate value="carSetting.Phone_Number" />
                </Form.Label>
                <FormGroup>
                  <IntlTelInputApp
                    css={['intl-tel-input', 'form-control form-custom']}
                    utilsScript="libphonenumber.js"
                    value={dialogData ? dialogData.phone : ''}
                    onPhoneNumberChange={this.handlePhoneChange}
                    disabled={!this.state.editable}
                  />
                  <Validator id="phone" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={this.state.phoneValidation}
                      message={I18n.t('carSetting.ERROR_INPUT_VALIDPHONE')}
                    />
                  </Validator>
                </FormGroup>
              </FormGroup>
              <CcCheckbox
                disabled={!this.state.editable}
                checked={this.state.dialogData ? this.state.dialogData.hwMetered : false}
                onChange={e => {
                  this.handleInputChange('hwMetered', e);
                }}
                text={I18n.t('carSetting.Hardware_Meter')}
              />
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.VIN" />
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('vhcId', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.vhcId || '' : ''}
                  placeholder={I18n.t('carSetting.VIN')}
                  disabled={!this.state.editable}
                />
              </FormGroup>
              <FormGroup
                className={isSubmitted ? (valid.company === false ? 'error' : null) : null}
              >
                <Form.Label>
                  <Translate value="carSetting.Company" />
                  {' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  disabled={!this.state.editable}
                  as="select"
                  className="form-custom"
                  onChange={e => {
                    this.selectChange('company', e);
                  }}
                  value={
                    this.state.dialogData.company ? this.state.dialogData.company.companyId : ''
                  }
                >
                  <option value="">{I18n.t('carSetting.Select_company')}</option>
                  {companyList.map(z => (
                    <option key={z._id} value={z._id}>
                      {z.name}
                    </option>
                  ))}
                </FormControl>
                <Validator
                  id="company"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      dialogData.company
                      && dialogData.company != null
                      && Object.keys(dialogData.company).length !== 0
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  isSubmitted ? (valid.maxPassengers === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="carSetting.Max_Passengers" />
                </Form.Label>
                <FormControl
                  type="number"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('maxPassengers', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.maxPassengers : ''}
                  placeholder={I18n.t('carSetting.Max_Passengers')}
                  disabled={!this.state.editable}
                />
                <Validator
                  id="maxPassengers"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isInteger(dialogData.maxPassengers)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isGreaterOrEqual(dialogData.maxPassengers, 0)}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={isSubmitted ? (valid.maxLuggage === false ? 'error' : null) : null}
              >
                <Form.Label>
                  <Translate value="carSetting.Max_Luggage" />
                </Form.Label>
                <FormControl
                  type="number"
                  className="form-custom"
                  onChange={e => {
                    this.handleInputChange('maxLuggage', e);
                  }}
                  value={this.state.dialogData ? this.state.dialogData.maxLuggage : ''}
                  placeholder={I18n.t('carSetting.Max_Luggage')}
                  disabled={!this.state.editable}
                />
                <Validator
                  id="maxLuggage"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isInteger(dialogData.maxLuggage)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isGreaterOrEqual(dialogData.maxLuggage, 0)}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Color" />
                </Form.Label>
                {this.state.editable ? (
                  <Select.Async
                    multi={false}
                    valueKey="_id"
                    labelKey="name"
                    cache={false}
                    ref={ref => this.carColorRef = ref}
                    placeholder={I18n.t('messages.car_mgmt.Search_car_color')}
                    searchPromptText={''}
                    loadingPlaceholder={I18n.t('messages.car_mgmt.Searching')}
                    noResultsText={this.renderCarColorNoResult()}
                    className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest"
                    value={this.state.colorSelected}
                    loadOptions={this.handleLoadOption}
                    onChange={this.handleCarColorChange}
                    disabled={!this.state.editable}
                    ignoreAccents={false}
                    // ignoreCase={false}
                    onInputChange={this.handleInputColorChange}
                    onBlur={this.handleInputBlur}
                  />
                ) : (
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={e => {
                      this.handleInputChange('color', e);
                    }}
                    value={this.state.dialogData ? this.state.dialogData.color : ''}
                    placeholder={I18n.t('carSetting.Color')}
                    disabled={!this.state.editable}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.License_expiry" />
                </Form.Label>
                <Datetime
                  inputProps={{
                    readOnly: true, 
                    disabled: !this.state.editable 
                  }}
                  value={this.state.dialogData.licenseExp
                    ? moment(this.state.dialogData.licenseExp).locale(language.toLowerCase()).format("L")
                    : ""}
                  dateFormat={true}
                  onChange={e => {
                    this.handleInputChange('licenseExp', e);
                  }}
                  placeholder={I18n.t('driver.placeholderDate')}
                  closeOnSelect
                />
              </FormGroup>
            </Col>
          </Row>
          <Form.Label>
            (
            <span className="require">*</span>
            ):
            {' '}
            <Translate value="carSetting.Required_fields" />
          </Form.Label>
        </Modal.Body>
        <Modal.Footer>
          {!this.props.permissions || this.props.permissions.actions ? (
            this.state.editable ? (
              <Button className="btn-save mr-md" onClick={this.saveDialogForm}>
                <Translate value="carSetting.Save" />
              </Button>
            ) : (
              <Button className="btn-save mr-md" onClick={this.onEditable}>
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={this.closeDialogForm}>
            <Translate value="carSetting.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddEditCarModal.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US'
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditCarModal);
