import ReportUtils from '../base/utils';
import _ from 'lodash';

export const fields = [
  {
    key: 'driverInfo',
    label: 'report.result.dailyDriverCar.driverInfo',
    sub: [
      {
        key: 'driverFullName',
        label: 'report.result.dailyDriverCar.driverName'
      },
      {
        key: 'idPermitType',
        label: 'report.result.idPermitType',
        checkShowFromProps: 'selectedFleet',
        isNotShow: selectedFleet => {
          const driverFields = _.get(selectedFleet, 'driverFields', []);
          return !_.find(driverFields, df => df.key === 'idType');
        }
      },
      {
        key: 'driverIdPermit',
        label: 'report.result.dailyDriverCar.driverIDPermit'
      },
      {
        key: 'driverPhone',
        label: 'report.result.dailyDriverCar.driverPhone'
      },
      {
        key: 'companyName',
        label: 'report.result.dailyDriverCar.companyName'
      }
    ]
  },
  {
    key: 'date',
    label: 'report.result.dailyDriverCar.date',
    rowSpan: 2,
    borderRight: true,
    mutate: ReportUtils.formatTime
  },
  {
    key: 'action',
    label: 'report.result.dailyDriverCar.action',
    rowSpan: 2,
    borderRight: true
  },
  {
    key: 'bookingId',
    label: 'report.result.dailyDriverCar.bookingId',
    rowSpan: 2,
    borderRight: true
  },
  {
    key: 'carInfo',
    label: 'report.result.dailyDriverCar.carInfo',
    sub: [
      {
        key: 'listRoles',
        label: 'report.result.dailyDriverCar.permission'
      },
      {
        key: 'licensePlate',
        label: 'report.result.dailyDriverCar.licensePlate'
      },
      {
        key: 'carId',
        label: 'report.result.dailyDriverCar.carId'
      },
      {
        key: 'carType',
        label: 'report.result.dailyDriverCar.carType'
      }
    ]
  }
];

export const fareFields = [];
