import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Button,
  Modal,
} from 'react-bootstrap';

import Autosuggest from 'react-autosuggest';
import { I18n, Translate } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
import { QQAutocomplete } from '../../qqMap';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import { HYDRA_STATUS, STATUS_BOOKING } from '../../../constants/commondata';

class FlightInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupFlight: false,
      flightInfo: {}
    };
  }

  isFromAirPort = () => {
    const { locationPickUp, newBooking, extraDestination } = this.props;
    const { locationService = {} } = newBooking || {};
    const { extraAirport: extraAirports = [] } = locationService || {};
    const extraAirport = (extraAirports || []).find(extraAP => extraAP && !!extraAP._id);
    // if(this.props.isHydraBooking) {
    //   return locationPickUp && newBooking.locationService && newBooking.locationService.airport && newBooking.locationService.airport[0]
    // }
    const isActiveAirport = newBooking
      && newBooking.locationService
      && newBooking.locationService.additionalService
      && newBooking.locationService.additionalService.fromAirport
      && newBooking.locationService.additionalService.fromAirport.isActive;
    const isAirportBook = (newBooking.locationService
      && newBooking.locationService.fromAirport
      && newBooking.locationService.fromAirport._id)
      || (newBooking.locationService
        && newBooking.locationService.airport
        && newBooking.locationService.airport[0])
      || (extraAirport && extraAirport._id);

    return (locationPickUp || extraDestination) && isActiveAirport && isAirportBook;
  };

  isToAirPort = () => {
    const { locationDestination, newBooking, extraDestination } = this.props;
    const { locationService = {} } = newBooking || {};
    const { extraAirport: extraAirports = [] } = locationService || {};
    const extraAirport = (extraAirports || []).find(extraAP => extraAP && !!extraAP._id);
    // if(this.props.isHydraBooking) {
    //   return locationDestination && newBooking.locationService && newBooking.locationService.airport && newBooking.locationService.airport[1]
    // }
    const isActiveAirport = newBooking
      && newBooking.locationService
      && newBooking.locationService.additionalService
      && newBooking.locationService.additionalService.toAirportNew
      && newBooking.locationService.additionalService.toAirportNew.isActive;
    const isAirportBook = (newBooking.locationService
      && newBooking.locationService.toAirport
      && newBooking.locationService.toAirport._id)
      || (newBooking.locationService
        && newBooking.locationService.airport
        && newBooking.locationService.airport[1])
      || (extraAirport && extraAirport._id);
    
    return (locationDestination || extraDestination) && isActiveAirport && isAirportBook;
  };

  renderWelcomeMessage = (isdisable) => {
    if(this.props.meetDriver == 1) {
      return (
        <FormGroup>
          <FormControl
            className="form-custom superHelper"
            as="textarea"
            placeholder={I18n.t("newbooking.welcome_psg")}
            rows={4}
            maxLength={100}
            disabled={isdisable}
            onChange={this.props.handleChangeWelcomeMessage}
            value={this.props.welcomeMessage}
          />
        </FormGroup>
      )
    }
    return null
  }

  renderFlightScheduleSuggestion = (suggestion, { query }) => {
    const isFromAirPort = this.isFromAirPort();
    const showTime = isFromAirPort ? suggestion.arrivaltime : suggestion.departuretime;
    return (
      <div className="suggestion-content">
        <p className="name">
          {suggestion.iata + suggestion.flightNumber}
          (
          {suggestion.originIata}
          -
          {suggestion.destinationIata}
          )
        </p>
        <p className="name">
          {moment
            .tz(
              showTime * 1000,
              this.props.newBooking.locationService.fromAirport
                ? this.props.newBooking.locationService.fromAirport.timezone
                : this.props.newBooking.locationService.toAirport.timezone
            )
            .format('DD-MMM-YYYY hh:mmA Z')}
        </p>
      </div>
    );
  };

  openHyperFligh = () => {
    const flightCode = this.props?.flightInfo?.ident ? this.props.flightInfo.ident : this.props.flightNumber
    window.open(`https://flightaware.com/live/flight/${flightCode}`)
  }

  showPopupFlightInfo = (hasFlightApi, flightawareHyperlink) => {
    if(!hasFlightApi && flightawareHyperlink) {
      this.openHyperFligh()
      return 
    }

    this.setState({ showPopupFlight: true })
    let queryBody = {
      fleetId: this.props.fleetId,
      ident: this.props.flightInfo.ident,
      departuretime: this.props.flightInfo.departuretime
    }
    if (this.props.faFlightID) queryBody.faFlightID = this.props.faFlightID
    this.props.flightActions.getInfoFlight(queryBody).then(data => {
      if (data.ok && data.res) {
        this.setState({ flightInfo: data.res })
      }
    });
  }

  closeDialog = () => {
    this.setState({ showPopupFlight: false })
  }

  getFlightScheduleSuggestionValue = suggestion => suggestion.iata + suggestion.flightNumber;

  render() {
    const {
      newBooking,
      selectedFleet,
      flightScheduleSuggestions,
      onFlightScheduleSuggestionsFetchRequested,
      onFlightScheduleSuggestionsClearRequested,
      flightScheduleAutocompleteSlectedHandle,
      flightSchedule,
      handleFlightNumerChange,
      flightScheduleAplyButtonClick,
      bookingCurrency,
      handleMeetdriverChange,
      data = {},
      meetDriver,
      flightNumber,
      isEditable,
      hydraStatus,
      isHydraBooking
    } = this.props;
    let meetGreetFee = null;
    let onCurbFee = null;
    let flightInfo = this.state.flightInfo
    let flightInfoProp = this.props.flightInfo || {}
    let lastHistory = flightInfo.lastHistory || {}
    let departuretimeDay = "";
    let departuretimeHour = "";
    let showIconFlight = false;
    let estimateDay = ""
    let estimateHour = ""
    let actualDay = ""
    let actualHour = ""
    let terminalGateDept = ""
    let terminalGateArr = ""
    let arrivaltimeDay = ""
    let arrivaltimeHour = ""
    const locationService = newBooking.locationService || {}
    const timeZonePickup = _.get(data,'request.pickup.timezone', '')
    const timeZoneDestination = _.get(data,'request.destination.timezone', '')
    const timeZone = locationService.fromAirport && timeZonePickup || locationService.toAirport && timeZoneDestination
    const flightawareHyperlink = _.get(selectedFleet, 'additionalService.flightawareHyperlink', true)
    if(flightInfoProp.estimatedarrivaltime > 0) {
      estimateDay = moment.tz(flightInfoProp.estimatedarrivaltime * 1000, timeZone).format('MMMM DD YYYY');
      estimateHour = moment.tz(flightInfoProp.estimatedarrivaltime * 1000, timeZone).format('h:mm A');
    }
    if (!_.isEmpty(flightInfo)) {
      if(flightInfo.filed_arrivaltime > 0) {
        arrivaltimeDay = moment.tz(flightInfo.filed_arrivaltime * 1000, timeZone).format('MMMM DD YYYY');
        arrivaltimeHour = moment.tz(flightInfo.filed_arrivaltime * 1000, timeZone).format('h:mm A');
      }
      actualDay = (flightInfo.actualdeparturetime > 0 ?
        moment(flightInfo.actualdeparturetime * 1000).format('MMMM DD YYYY')
        : "")
      actualHour = (flightInfo.actualdeparturetime > 0 ?
        moment(flightInfo.actualdeparturetime * 1000).format('h:mm A')
        : "")
      terminalGateDept = "Terminal " + flightInfo.terminal_orig + " - " + "Gate " + flightInfo.gate_orig
      terminalGateArr = "Terminal " + flightInfo.terminal_dest + " - " + "Gate " + flightInfo.gate_dest
    }
    if (flightInfo.filed_departuretime > 0) {
      departuretimeDay = moment.tz(flightInfo.filed_departuretime * 1000, timeZone).format('MMMM DD YYYY')
      departuretimeHour = moment.tz(flightInfo.filed_departuretime * 1000, timeZone).format('h:mm A')
    } else {
      departuretimeDay = moment.tz(flightInfoProp.departuretime * 1000, timeZone).format('MMMM DD YYYY')
      departuretimeHour = moment.tz(flightInfoProp.departuretime * 1000, timeZone).format('h:mm A')
    }

    if ((selectedFleet?.process?.flight && flightInfoProp.faFlightID)
      || (flightInfoProp.ident && flightInfoProp.departuretime)
    ) {
      showIconFlight = true
    }

    if(isHydraBooking && this.isFromAirPort()) {
      return (
        <>
          <FormGroup>
            <FormGroupTitle>
              <Translate value="newbooking.FLY_INFO" />
              { 
                ( showIconFlight 
                  || (flightawareHyperlink && (flightInfoProp.ident || this.props.flightNumber))
                ) && 
                <i 
                  className={"fa fa-info-circle mr-l-5"} 
                  onClick={() => this.showPopupFlightInfo(
                    showIconFlight, 
                    flightawareHyperlink
                  )} 
                />
              }
            </FormGroupTitle>
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              placeholder={I18n.t('newbooking.FlightExample')}
              value={flightNumber}
              className="form-custom"
              onChange={handleFlightNumerChange}
              maxLength={100}
              disabled={isHydraBooking && hydraStatus === HYDRA_STATUS.accepted}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              as="select"
              placeholder={I18n.t('newbooking.Meet_driver')}
              className="form-custom"
              value={meetDriver}
              disabled={isHydraBooking && hydraStatus === HYDRA_STATUS.accepted}
              onChange={handleMeetdriverChange}
            >
              <option key={1} value="1">
                {I18n.t('newbooking.Meet_Greet_option')}
              </option>
              <option key={2} value="0">
                {I18n.t('newbooking.On_Curb_option')}
              </option>
            </FormControl>
          </FormGroup>
          {this.renderWelcomeMessage(
            (isHydraBooking && hydraStatus === HYDRA_STATUS.accepted)
          )}
        </>
      )
    }

    if(isHydraBooking && this.isToAirPort()) {
      return (
        <>
          <FormGroup>
            <FormGroupTitle>
              <Translate value="newbooking.FLY_INFO" />
              { 
                ( showIconFlight 
                  || (flightawareHyperlink && (flightInfoProp.ident || this.props.flightNumber))
                ) && 
                <i 
                  className={"fa fa-info-circle mr-l-5"} 
                  onClick={() => this.showPopupFlightInfo(
                    showIconFlight, 
                    flightawareHyperlink
                  )} 
                />
              }
            </FormGroupTitle>
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              placeholder={I18n.t('newbooking.FlightExample')}
              value={flightNumber}
              className="form-custom"
              onChange={handleFlightNumerChange}
              maxLength={100}
              disabled={isHydraBooking && hydraStatus === HYDRA_STATUS.accepted}
            />
          </FormGroup>
        </>
      )
    }

    if (
      this.isFromAirPort()
      && newBooking.locationService.additionalService.fromAirport.meetDriver
    ) {
      meetGreetFee = newBooking.locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies
        && newBooking.locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0]
        ? newBooking.locationService.additionalService.fromAirport.meetDriver.meetDrvByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0].value
        : 0;

      onCurbFee = newBooking.locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies
        && newBooking.locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0]
        ? newBooking.locationService.additionalService.fromAirport.meetDriver.onCurbByCurrencies.filter(
          meet => meet.currencyISO == bookingCurrency.iso
        )[0].value
        : 0;
    }

    const isAirport = this.isFromAirPort() || this.isToAirPort();
    if (!isAirport) return '';

    const isBookingDetail = !!data.bookId;
    let disable = false;
    if (isBookingDetail) disable = !isEditable(data) 
      || (isHydraBooking && hydraStatus === HYDRA_STATUS.accepted);
    return (
      <div>
        {(this.isFromAirPort()
          && newBooking.locationService.additionalService.fromAirport.flightInfo)
          || (this.isToAirPort()
            && newBooking.locationService.additionalService.toAirportNew.flightInfo) ? (
            <FormGroup>
              {
                // isChauffeur
                // ? (
                //   <FormGroupTitle>
                //     <Translate value="newbooking.FLY_INFO" />
                //     {true && <i className={"fa fa-info-circle mr-l-5"} onClick={this.showPopupFlightInfo} />}
                //   </FormGroupTitle>
                // ) : (
                  <FormGroupTitle>
                    <Translate value="newbooking.FLY_INFO" />
                    { 
                      ( showIconFlight 
                        || (flightawareHyperlink && (flightInfoProp.ident || this.props.flightNumber))
                      ) && 
                      <i 
                        className={"fa fa-info-circle mr-l-5"} 
                        onClick={() => selectedFleet?.process?.flight && this.showPopupFlightInfo(
                          showIconFlight, 
                          flightawareHyperlink
                        )} 
                      />
                    }
                  </FormGroupTitle>
                // )
              }
              {(selectedFleet?.process?.flight && !isHydraBooking) ? (
                <FormGroup>
                  <div className="add-on-input-group">
                    <Autosuggest
                      className="schedule-input"
                      suggestions={flightScheduleSuggestions}
                      onSuggestionsFetchRequested={onFlightScheduleSuggestionsFetchRequested}
                      onSuggestionsClearRequested={onFlightScheduleSuggestionsClearRequested}
                      getSuggestionValue={this.getFlightScheduleSuggestionValue}
                      renderSuggestion={this.renderFlightScheduleSuggestion}
                      onSuggestionSelected={flightScheduleAutocompleteSlectedHandle}
                      alwaysRenderSuggestions={
                        flightSchedule
                          ? false
                          : !!(flightScheduleSuggestions && flightScheduleSuggestions.length > 0)
                      }
                      inputProps={{
                        className: 'form-control form-custom',
                        value: flightNumber || '',
                        onChange: handleFlightNumerChange,
                        placeholder: I18n.t('newbooking.FlightExample'),
                        disabled: (!!flightSchedule) || disable,
                        maxLength: 100
                      }}
                    />
                    {flightNumber && !disable ? (
                      <span
                        variant={flightSchedule ? 'warning' : 'success'}
                        className="schedule-button add-on-button"
                        onClick={flightScheduleAplyButtonClick}
                        disabled={!flightNumber || flightNumber.length == 0}
                      >
                        {flightSchedule ? I18n.t('newbooking.Remove') : I18n.t('bookingdetail.Apply')}
                      </span>
                    ) : (
                        ''
                      )}
                  </div>
                </FormGroup>
              ) : (
                  <FormGroup>
                    <FormControl
                      type="text"
                      placeholder={I18n.t('newbooking.FlightExample')}
                      value={flightNumber}
                      className="form-custom"
                      onChange={handleFlightNumerChange}
                      maxLength={100}
                      disabled={disable}
                    />
                  </FormGroup>
                )}
            </FormGroup>
          ) : null}
        {this.isFromAirPort()
          && newBooking.locationService.additionalService.fromAirport.meetDriver ? (
            <>
              {data.pricingType == 1 ? (
                <FormGroup>
                  <FormControl
                    as="select"
                    placeholder={I18n.t('newbooking.Meet_driver')}
                    className="form-custom"
                    value={meetDriver}
                    disabled={disable}
                    onChange={handleMeetdriverChange}
                  >
                    <option key={1} value="1">
                      {I18n.t('newbooking.Meet_Greet_option')}
                    </option>
                    <option key={2} value="0">
                      {I18n.t('newbooking.On_Curb_option')}
                    </option>
                  </FormControl>
                </FormGroup>
              ) : (
                  <FormGroup>
                    <FormControl
                      as="select"
                      placeholder={I18n.t('newbooking.Meet_driver')}
                      className="form-custom"
                      value={meetDriver}
                      disabled={disable}
                      onChange={handleMeetdriverChange}
                    >
                      <option key={1} value="1">
                        {I18n.t('newbooking.Meet_Greet_option')}
                        {' '}
                        =
                      {' '}
                        {currencyFormatter.format(meetGreetFee, { code: bookingCurrency.iso })}
                      </option>
                      <option key={2} value="0">
                        {I18n.t('newbooking.On_Curb_option')}
                        {' '}
                        =
                      {' '}
                        {currencyFormatter.format(onCurbFee, { code: bookingCurrency.iso })}
                      </option>
                    </FormControl>
                  </FormGroup>
              )}
              {this.renderWelcomeMessage(disable && !this.props.is3rdBooking)}
            </>
          ) : (
            ''
          )}
        <Modal
          show={this.state.showPopupFlight}
          backdrop="static"
          // onHide={this.handleModalHide}
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="card-dialog"
          className="confirm flightInfoModal"
          onHide={this.closeDialog}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {flightNumber}
            </Modal.Title>
            <button type="button" className="close" aria-label="Close" onClick={this.closeDialog}><span aria-hidden="true">×</span></button>
          </Modal.Header>
          <Modal.Body className="clearfix popupFlight text">
            {(lastHistory.eventcode == "change") ? (
              <div>
                <p className="fl-mes">{lastHistory.long_desc}</p>
                <p className="fl-status">{lastHistory.eventcode}</p>
              </div>
            ) : ""
            }
            <div className="col-flight">
              <span><b>Departure</b></span>
              <span><b>Arrival</b></span>
            </div>
            <div className="col-flight">
              <span><b>Scheduled:</b><br /> {departuretimeDay} <br /> {departuretimeHour}</span>
              <span><b>Scheduled:</b><br /> {arrivaltimeDay} <br /> {arrivaltimeHour}</span>
            </div>
            <div className="col-flight">
              <span><b>Actual:</b><br /> {actualDay} <br /> {actualHour}</span>
              <span><b>Estimated:</b><br /> {estimateDay} <br /> {estimateHour}</span>
            </div>
            <div className="col-flight">
              <span><b>Terminal - Gate:</b><br /> {terminalGateDept}</span>
              <span><b>Terminal - Gate:</b><br /> {terminalGateArr}</span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {
              flightawareHyperlink &&
              <Button
                className="btn-save mr-md"
                onClick={this.openHyperFligh}
              >
                Flight aware
              </Button>
            }
            <Button
              className="btn-reset mr-md"
              onClick={this.closeDialog}
            >
              <Translate value="driver.Close" />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FlightInfo;
