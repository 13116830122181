import _ from "lodash";
import React from 'react';
import {Col} from 'react-bootstrap';
import {Pie} from "react-chartjs-2";
import {I18n} from 'react-redux-i18n';
import {doughnutOptions} from '../../utils/chartUtils';

const Rating = ({auth, ratingChartData}) => {
  return (
    <>
      {!auth.selectedFleet.passenger.drvRating &&
      !_.isEmpty(ratingChartData) ? (
        <Col sm={6}>
          <div className='db-chart'>
            <Pie
              data={ratingChartData}
              options={{
                ...doughnutOptions,
                title: {
                  ...doughnutOptions.title,
                  text: I18n.t('dashboard.Ratings').format(
                    ratingChartData ? ratingChartData.datasets[0].data.sum() : 0,
                  ),
                  fontColor: '#e6e6e6',
                },
              }}
            />
          </div>
        </Col>
      ) : (
        ''
      )}
    </>
  )
};

export default Rating;
