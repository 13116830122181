import React from 'react';
import {
    FormControl,
    FormGroup,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';

import InputRequireInline from '../../../../components/InputRequireInline';
import { Validator, ValidCase, WarningFeeInput } from '../../../../components/validator';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

import { Validation } from '../../../../utils/commonFunctions';
import { ZoneActionStatus } from '../../../../constants/commondata';


export default function AddEditSurcharge(props) {
    const {
        surchargeModel,
        isSubmitted,
        valid,
        zoneAction,
        ValidatorCallback,
        handleFormFieldChange,
        handleSubmitSurcharge,
        handleClearDrawClick
    } = props;

    return (
        <div className="surcharge-form">
            <form onSubmit={handleSubmitSurcharge}>
                <InputRequireInline
                    className={!isSubmitted || valid.name ? null : 'error'}
                >
                    <FormControl
                        type="text"
                        className="form-custom"
                        placeholder={
                            surchargeModel.name.length
                                ? ''
                                : I18n.t('dynamicSurcharge.surchargeName')
                        }
                        value={surchargeModel.name}
                        onChange={e => handleFormFieldChange('name', e.target.value)}
                        maxLength="50"
                    />
                    <Validator id="name" callback={ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmitted}
                            valid={!Validation.isStringEmpty(surchargeModel.name)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                        />
                    </Validator>
                </InputRequireInline>
                <InputRequireInline
                    className={!isSubmitted || valid.parameter ? null : 'error'}
                >
                    <FormControl
                        type="number"
                        step="0.1"
                        min={0}
                        className="form-custom"
                        value={surchargeModel.parameter}
                        placeholder={
                            (surchargeModel.parameter + '').length
                                ? ''
                                : I18n.t('dynamicSurcharge.parameter')
                        }
                        onChange={e =>
                            handleFormFieldChange('parameter', e.target.value)
                        }
                    />
                    <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={surchargeModel.parameter}
                        disabled={zoneAction !== ZoneActionStatus.Edit}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                    />
                    <Validator id="parameter" callback={ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmitted}
                            valid={!Validation.isStringEmpty(surchargeModel.parameter)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                            hide={!isSubmitted}
                            valid={Validation.isNumber(surchargeModel.parameter)}
                            message={I18n.t('messages.commonMessages.must_be_number')}
                        />
                        <ValidCase
                            valid={Validation.isInRange(surchargeModel.parameter, 1, 10)}
                            message={I18n.t("messages.commonMessages.Integer_from_range").format(1, 10)}
                            hide={!isSubmitted}
                        />
                    </Validator>
                </InputRequireInline>

                <div className="text-base mb-md" style={{ fontStyle: 'italic' }}>
                    <Translate value="dynamicSurcharge.note" />
                </div>

                <FormGroup
                    className={!isSubmitted || valid.point ? null : 'error'}
                >
                    <Row className="show-grid">
                        <Col xs={6}>
                            <CcCheckbox
                                checked={surchargeModel.pickupPoint}
                                onChange={e => handleFormFieldChange("pickupPoint", e.target.checked)}
                                disabled={false}
                                text={<Translate value="dynamicSurcharge.pickupPoint" />}
                            />
                        </Col>
                        <Col xs={6}>
                            <CcCheckbox
                                checked={surchargeModel.dropOffPoint}
                                onChange={e => handleFormFieldChange("dropOffPoint", e.target.checked)}
                                disabled={false}
                                text={<Translate value="dynamicSurcharge.dropOffPoint" />}
                            />
                        </Col>
                    </Row>
                    <Validator id="point" callback={ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmitted}
                            valid={surchargeModel.pickupPoint || surchargeModel.dropOffPoint}
                            message={I18n.t('messages.commonMessages.choose_at_least_one')}
                        />
                    </Validator>
                </FormGroup>

                <div>
                    <Button className="btn-save mr-md" type="submit">
                        <Translate value="zoneSetting.Save" />
                    </Button>
                    <Button className="btn-cancel" onClick={handleClearDrawClick}>
                        <Translate value="zoneSetting.Clear_draw" />
                    </Button>
                </div>
            </form>
        </div>
    );
}
