import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as settingActions from "../../../actions/settingActions";
import * as loadingActions from '../../../actions/loadingBarActions';
import stripe_connect from '../../../assets/images/icons/stripe_connect.svg'
import stripe_disconnect from '../../../assets/images/icons/stripe_disconnect.svg'
import stripe_connected from '../../../assets/images/icons/stripe_connected.svg'
import "./stripeConnect.scss";
import {
  Button,
  Modal
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import {
  socketDispatchApi
} from '../../../utils/socketUtils';
import { socketConfigs } from '../../../constants/socketConfigs';
import { convertTextStatus } from '../../../utils/commonFunctions'

class StripeConnectSetting extends Component {
  constructor() {
    super();
    this.state = {
      stripeConnectStatus: 'empty', // [empty, pending, activated]
    };
  }
  componentDidMount() {
    this.props.settingActions
      .getStripeConnectStatus({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then(data => {
        if (data.res && data.res.connectStatus) {
          this.setState({ 
            stripeConnectStatus: data.res.connectStatus 
          })
        }
      });
  }

  hanldeOpenDisconnectModal = () => {
    this.setState({ isShowModal: true })
  }

  hanldeConnectOrDashboard = () => {
    const { stripeConnectStatus = '' } = this.state

    // connect to stripe
    if(stripeConnectStatus === 'empty') {
      this.props.loadingActions.showLoadingSpiner();
      this.props.settingActions
      .stripeConnectOnboarding({ 
        fleetId: this.props.auth.selectedFleet.fleetId,
        type: 'fleet'
      })
      .then(data => {
        this.props.loadingActions.hideLoadingSpiner();
        const response = data.res && data.res.response || {}
        if(response.url) {
          window.open(response.url, '_blank')
          socketDispatchApi.remove(socketConfigs.receive.stripeConnectStatus);
          socketDispatchApi.on(socketConfigs.receive.stripeConnectStatus, data => {
            if(data && data.status) {
              this.context.notification(
                'success',
                'The Stripe has  been connected successfully'
              )
              this.setState({ stripeConnectStatus: data.status })
            }
          });
        }
      })
      .catch(function () {
        this.props.loadingActions.hideLoadingSpiner();
        console.log('error load connect');
      });
    }

    // login to stripe
    if(stripeConnectStatus === 'pending' || stripeConnectStatus === 'activated') {
      this.props.loadingActions.showLoadingSpiner();
      this.props.settingActions
      .stripeConnectLogin({ 
        fleetId: this.props.auth.selectedFleet.fleetId,
        type: 'fleet'
      })
      .then(data => {
        this.props.loadingActions.hideLoadingSpiner();
        const response = data.res && data.res.response || {}
        if(response.url) {
          window.open(response.url, '_blank')
        }
      })
      .catch(function () {
        this.props.loadingActions.hideLoadingSpiner();
        console.log('error load connect');
      });
    }
  }

  handleCloseModal = () => {
    this.setState({ isShowModal: false })
  }

  handleConfirmedDisconnect = () => {
    this.props.loadingActions.showLoadingSpiner();
    this.props.settingActions
      .disconnectStripe({ 
        fleetId: this.props.auth.selectedFleet.fleetId,
        type: 'fleet'
      })
      .then(data => {
        this.props.loadingActions.hideLoadingSpiner();
        const response = data.res && data.res.response || {}
        if(response.disconnected) {
          this.context.notification(
            'success',
            'The Stripe has  been disconnected successfully'
          )
          this.setState({
            stripeConnectStatus: 'empty',
            isShowModal: false
          })
        } else {
          this.context.notification(
            'error',
            'There were errors when disconnecting account',
          )
          this.setState({
            isShowModal: false
          })
        }
      })
      .catch(function () {
        this.props.loadingActions.hideLoadingSpiner();
        console.log('error load connect');
      });
  }

  render() {
    const { 
      stripeConnectStatus,
      isShowModal,
      urlTripeConnect
     } = this.state
    const permissionStripeConnect = this.props.permissions || {}
    return (
      <div className="stripe_connect content vertical-flow-auto">
        {
          stripeConnectStatus === 'empty' &&
          <p className="warning_connect">
            <Translate value={'Sidebar.Settings.stripe_title_connect'} />
          </p>
        }
        <p className="strStatus">
          <Translate value={'Sidebar.Settings.Stripe_connect'} />:
          <span className={`stripe_status ${stripeConnectStatus}`}>
            {` ${convertTextStatus(stripeConnectStatus)}`}
          </span>
        </p>

        {
          permissionStripeConnect.actions && (
            <>
              {/* show Connect and Dashboard button  */}
              <p>
                <Button
                  onClick={this.hanldeConnectOrDashboard}
                  className="btn-send stripeButton"
                > 
                  {
                    stripeConnectStatus === 'empty' 
                    ? (
                      <>
                        <img src={stripe_connect} />
                        <Translate value="bookingdetail.Connect" />
                      </>
                    ) : (
                      <>
                        <img src={stripe_connected} />
                        <Translate value="bookingdetail.Dashboard" />
                      </>
                    )
                  }
                </Button>
              </p>
      
              {
                /* show disconnect button */
                ['pending', 'activated'].indexOf(stripeConnectStatus) > -1 && 
                <p>
                  <Button
                    onClick={this.hanldeOpenDisconnectModal}
                    className="btn-cancel stripeButton"
                  > 
                    <img src={stripe_disconnect} />
                    <Translate value="bookingdetail.Disconnect" />
                  </Button>
                </p>
              }
            </>
          )
        }

        {/*Modal confirm disconnect stripe */}
        <Modal
          show={isShowModal}
          backdrop={true}
          dialogClassName="confirm-dialog"
          className="confirm"
          onHide={this.handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Disconnect</Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.handleCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to disconnect from Stripe connect account?
            </p>
          </Modal.Body>
          <Modal.Footer className="text-center">
            <Button
              className="btn-save mr-md"
              onClick={this.handleConfirmedDisconnect}
            >
              {I18n.t('bookingdetail.Yes')}
            </Button>
            <Button className="btn-cancel" onClick={this.handleCloseModal}>
              {I18n.t('bookingdetail.No')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

StripeConnectSetting.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeConnectSetting);
