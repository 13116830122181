import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Row,
  Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class CancelPolicyIntercityFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleIntercityCancelPolicyDurationChange = (e) => {
    this.props.handleUpdateFormData(
      'cancellationPolicy.intercityDuration',
      e.target.value
    );
  };

  handleIntercityCancelPolicyCheckChange = (e) => {
    this.props.handleUpdateFormData(
      'cancellationPolicy.intercity',
      e.target.checked
    );
  };

  handleChangeIntercityDurationUnit = (e) => {
    let {
      formData: { cancellationPolicy },
    } = this.props;
    if (cancellationPolicy.intercityDurationUnit != e.target.value) {
      const durationUnit = e.target.value;
      let duration = cancellationPolicy.intercityDuration;
      if (durationUnit == 'hour') {
        duration = duration / 60;
      } else {
        duration = duration * 60;
      }
      this.props.handleUpdateFormData(
        'cancellationPolicy.intercityDurationUnit',
        durationUnit
      );
      this.props.handleUpdateFormData(
        'cancellationPolicy.intercityDuration',
        duration
      );
    }
  };

  render() {
    const {
      ValidatorCallback,
      editable,
      isSubmited,
      valid,
      selectedFleet,
    } = this.props;
    let {
      formData: { cancellationPolicy },
    } = this.props;
    cancellationPolicy = cancellationPolicy || {};
    const unitDistance = _.get(selectedFleet, 'unitDistance', 'km');
    if (!_.get(selectedFleet, 'intercity.enable', false)) {
      return null
    }
    return (
      <Fragment>
        <CcCheckbox
          disabled={editable}
          checked={cancellationPolicy.intercity || false}
          onChange={this.handleIntercityCancelPolicyCheckChange}
          text={
            <Fragment>
              {I18n.t('generalSetting.Intercity')}
              <QuestionCircleTooltip
                text={<Translate value="generalSetting.Intercity_tooltip" />}
              />
            </Fragment>
          }
          labelClassName="fs-16"
          className="jc-end"
        />

        {cancellationPolicy.intercity ? (
          <FormGroup
            className={
              !isSubmited
                ? null
                : valid.intercityDuration === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="generalSetting.Duration" />
              <span className="require">*</span>
            </Form.Label>
            <FormGroup className="qup-input-group">
              <InputGroup
                className={`single-addon-right ${editable ? 'disabled' : ''}`}
              >
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={this.handleIntercityCancelPolicyDurationChange}
                  onBlur={(e) => {
                    textboxNumberHelper.onBlurHandle(
                      e,
                      this.handleIntercityCancelPolicyDurationChange
                    );
                  }}
                  onFocus={(e) => {
                    textboxNumberHelper.onfocusHandle(
                      e,
                      this.handleIntercityCancelPolicyDurationChange
                    );
                  }}
                  placeholder={I18n.t('generalSetting.Duration')}
                  value={cancellationPolicy.intercityDuration}
                  disabled={editable}
                />
                <FormControl
                  as="select"
                  className="input-group-addon-custom form-custom"
                  value={cancellationPolicy.intercityDurationUnit || 'hour'}
                  onChange={this.handleChangeIntercityDurationUnit}
                  disabled={editable}
                >
                  <option value={'hour'}>{I18n.t('General.hrs')}</option>
                  <option value={'minute'}>{I18n.t('General.mins')}</option>
                </FormControl>
              </InputGroup>
            </FormGroup>
            <Validator id="intercityDuration" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={Validation.isNumber(
                  cancellationPolicy.intercityDuration
                )}
                message={I18n.t('messages.commonMessages.must_be_number')}
              />
              <ValidCase
                hide={!isSubmited}
                valid={Validation.isGreaterOrEqual(
                  cancellationPolicy.intercityDuration,
                  0
                )}
                message={I18n.t(
                  'messages.commonMessages.equal_or_greater_than_0'
                )}
              />
              <ValidCase
                hide={!isSubmited}
                valid={Validation.isLessOrEqual(
                  cancellationPolicy.intercityDuration,
                  9999
                )}
                message={I18n.t('messages.commonMessages.less_or_equal').format(
                  '9999'
                )}
              />
            </Validator>
          </FormGroup>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

export default CancelPolicyIntercityFormGroup;
