import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    Button,
    ButtonToolbar,
    Modal,
    ButtonGroup,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import formatCurrency from 'currency-formatter';

import Confirm from '../../../../components/confirm/Confirm';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { CCLiteCommonFunc } from '../../../../utils/commonFunctions';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import Import from './Import';
import { VoucherCodeColumns as Columns } from './tableHeaderColumn';
import TableActions from '../../../../components/table/tableAction/TableActions';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';

import AddEditVoucher from './AddEditVoucher';
import './voucherCode.scss';
import { BsSearch } from 'react-icons/bs';

const CONFIRM_DELETE_ID = 3;
const CONFIRM_SEND_INBOX_ID = 4;
let sendInBoxId;
class VoucherCode extends Component {
    constructor() {
        super();
        this.state = {
            valid: {},
            voucherCodeList: {
                list: [],
                page: 0,
                limit: 20,
                total: 1
            },
            dialogData: {
                promotionCode: '',
                campaign: {
                    _id: ''
                },
            },
            campaignList: [],
            disable: false,
            isEditable: false,
            searchKey: '',
            isImportFile: false,
            showConfirm: false,
            tableHeight: 500,
            rowHeight: 50,
            fileName: '',
            isShowImport: false,
            isDetailForm: false
        };
        this.getVoucherCodeList = this.getVoucherCodeList.bind(this);
        this.getCampaignList = this.getCampaignList.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
        this.handlePaginationChose = this.handlePaginationChose.bind(this);
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleItemSelectedChange = this.handleItemSelectedChange.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.deleteMultipleCodes = this.deleteMultipleCodes.bind(this);
        this.activeMultipleCodes = this.activeMultipleCodes.bind(this);

        this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
        this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);

        this.isDisableActive = this.isDisableActive.bind(this);
        this.isDisableDeactive = this.isDisableDeactive.bind(this);
        this.isDisableDelete = this.isDisableDelete.bind(this);
        this.searchStrChange = this.searchStrChange.bind(this);
        this.searchKeyPressHandle = this.searchKeyPressHandle.bind(this);

        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
        this.handleClickEditButton = this.handleClickEditButton.bind(this);
        this.closeFormModal = this.closeFormModal.bind(this);

        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.handleVoucherCodeChange = this.handleVoucherCodeChange.bind(this);
        this.handleCampaignChange = this.handleCampaignChange.bind(this);
        this.handleAmountValueChange = this.handleAmountValueChange.bind(this);
        this.handleValidFromChanged = this.handleValidFromChanged.bind(this);
        this.handleValidToChanged = this.handleValidToChanged.bind(this);

        this.saveDialogForm = this.saveDialogForm.bind(this);
        this.handleUpdateStateClick = this.handleUpdateStateClick.bind(this);
        this.deleltePromotionCode = this.deleltePromotionCode.bind(this);

        this.onImportButtonClick = this.onImportButtonClick.bind(this);
        this.onCancelImportFile = this.onCancelImportFile.bind(this);

        this.ValidatorCallback = this.ValidatorCallback.bind(this);
        this.confirmDeletePromotionCode = this.confirmDeletePromotionCode.bind(this);

        // this.handleSendToInboxClick = this.handleSendToInboxClick.bind(this);
        // this.sendToInboxPromotionCode = this.sendToInboxPromotionCode.bind(this);
    }

    componentDidMount() {
        this.getVoucherCodeList();
        this.getCampaignList();
    }

    getVoucherCodeList(page = 0, limit) {
        const {
            searchKey
        } = this.state;
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        if (!limit) limit = this.state.voucherCodeList.limit;
        settingActions
            .findVoucherCodes({ fleetId, limit, page, searchKey })
            .then(data => {
                if (data.ok && data.res) {
                    this.setState({
                        voucherCodeList: data.res
                    });
                }
            });
    };

    getCampaignList() {
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions.findAllCampaignForSelection(fleetId).then(data => {
            if (data.ok && data.res) {
                this.setState({
                    campaignList: data.res
                });
            }
        });
    }

    getTableHeight = () => {
        let verticalPadding = 10,
            toolbarMarginBottom = 10,
            toolbarheight = ReactDOM.findDOMNode(this.toobarContainer).clientHeight,
            parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
        let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
        this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        return this.state.tableHeight;
    };


    handleMenuClick(e, obj) {
        switch (e) {
            case 'Active':
            case 'Deactive':
                this.handleUpdateStateClick(obj._id, !obj.isActive);
                break;
            case 'Delete':
                this.deleltePromotionCode(obj);
                break;
            case 'Edit':
                this.handleClickEditButton(obj._id);
                this.handleEditPromoCode();
                break;
        }
    };

    isDisableActive() {
        const { voucherCodeList } = this.state;
        let selectedList = voucherCodeList.list.filter(obj => obj.selected);
        if (selectedList && selectedList.length > 0) {
            return !(selectedList.findIndex(obj => !obj.isActive) >= 0);
        }
        return true;
    }

    isDisableDeactive() {
        const { voucherCodeList } = this.state;
        let selectedList = voucherCodeList.list.filter(obj => obj.selected);
        if (selectedList && selectedList.length > 0) {
            return !(selectedList.findIndex(obj => obj.isActive) >= 0);
        }
        return true;
    }

    isDisableDelete() {
        const { voucherCodeList } = this.state;
        let selectedList = voucherCodeList.list.filter(obj => obj.selected);
        if (selectedList && selectedList.length > 0) {
            return !(selectedList.findIndex(obj => !obj.isActive) >= 0);
        }
        return true;
    }

    handleNumItemsPerPageChange(e) {
        let value = parseInt(e);
        this.getVoucherCodeList(0, value);
    }

    handlePaginationChose(e) {
        let value = parseInt(e);
        this.getVoucherCodeList(value);
    }

    handlePaginationSelect(value) {
        this.getVoucherCodeList(value);
    }

    searchStrChange(e) {
        this.setState({
            searchKey: e
        });
    }

    searchKeyPressHandle(e) {
        if (e.key == 'Enter') {
            this.getVoucherCodeList();
        }
    }

    openFormModal() {
        this.setState({ showDialogModal: true });
    }

    closeFormModal() {
        this.setState({
            showDialogModal: false,
            showConfirm: false,
            fileName: '',
            isDetailForm: false,
            isEditable: false
        });
    }


    handleAddButtonClick() {
        let currency = '';
        if (this.props.auth && this.props.commonData) {
            let zone = this.props.commonData.mapZone.find(obj => {
                return obj.isDefault;
            });
            if (!zone) zone = this.props.commonData.mapZone[0];
            currency = zone.currency.iso;
        }
        this.setState(
            {
                disable: false,
                isSubmited: false,
                receiverList: [],
                dialogData: {
                    campaign: {
                        _id: ''
                    },
                    valueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
                        [],
                        this.props.auth.selectedFleet.currencies
                    ),
                    voucherCode: '',
                    currencyISO: currency,
                    fileName: "",
                    validFrom: '',
                    validTo: ''
                }
            },
            this.openFormModal()
        );
    };

    mergeValueByCurrenciesToFleetCurrency(valueByCurrencies, currencies) {
        let result = [];
        if (valueByCurrencies) {
            result = currencies.map(obj => {
                let index = valueByCurrencies.findIndex(tmp => {
                    return tmp.currencyISO == obj.iso;
                });
                if (index >= 0) {
                    return {
                        ...obj,
                        value: valueByCurrencies[index].value,
                        currencyISO: obj.iso
                    };
                }
                return {
                    ...obj,
                    currencyISO: obj.iso
                };
            });
            return result;
        }
        return currencies.map(obj => {
            return {
                ...obj,
                currencyISO: obj.iso
            };
        });
    }

    onImportButtonClick() {
        this.setState({
            isImportFile: true
        });
    }

    onCancelImportFile() {
        this.setState({
            isImportFile: false
        });
    }



    handleClickEditButton(id) {
        const { auth, settingActions, loadingBarActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        let timeZone = auth.selectedFleet.timezone;
        let currencies = auth.selectedFleet.currencies;
        if (currencies === undefined) currencies = [];
        loadingBarActions.showLoadingSpiner();
        this.setState({
            disable: true
        });

        settingActions.detailsVoucherCode({ fleetId, id }).then(data => {
            loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
                let formData = data.res;
                let value = '';
                value = formData.valueByCurrencies[0].value;
                let valueByCurrencies = this.mergeValueByCurrenciesToFleetCurrency(
                    formData.valueByCurrencies,
                    currencies
                );
                let currencyISO = formData.valueByCurrencies[0].currencyISO;
                this.setState({
                    dialogData: {
                        ...formData,
                        valueByCurrencies,
                        validFrom: moment(formData.validFrom).tz(timeZone),
                        validTo: moment(formData.validTo).tz(timeZone),
                        value,
                        currencyISO
                    },
                    isDetailForm: true
                }); // end set State
                this.openFormModal();
            } // end if data.ok && data.res
            else {
                if (data.error) {
                    this.context.notification(
                        'error',
                        I18n.t('errors.' + data.error.errorCode)
                    );
                } else {
                    this.context.notification(
                        'error',
                        I18n.t('shiftSettings.Loading_voucher_code_fail')
                    );
                }
            }
        });
    };

    handleVoucherCodeChange(e) {
        let dialogData = this.state.dialogData || {};
        var characterAndDigitRegrex = /^[a-zA-Z0-9]*$/;
        if (characterAndDigitRegrex.test(e.target.value)) {
            dialogData.voucherCode = e.target.value ? e.target.value.toUpperCase() : '';
            this.setState({
                dialogData
            });
        }
    }

    handleCampaignChange(e) {
        let dialogData = this.state.dialogData || {};
        dialogData.campaign = {
            ...dialogData.campaign,
            _id: e.target.value
        };
        this.setState({
            dialogData
        });
    }

    onChangeCurrency(e) {
        let dialogData = this.state.dialogData || {};
        dialogData.currencyISO = e.target.value;
        this.setState({
            dialogData
        });
    }

    handleAmountValueChange(e) {
        let dialogData = this.state.dialogData || {};
        dialogData.value = e.target.value;
        this.setState({
            dialogData
        });
    }


    handleValidFromChanged(e) {
        let dialogData = this.state.dialogData || {};
        dialogData.validFrom = e._d;
        this.setState({
            dialogData
        });
    }

    handleValidToChanged(e) {
        let dialogData = this.state.dialogData || {};
        dialogData.validTo = e._d;
        this.setState({
            dialogData
        });
    }

    saveDialogForm() {
        const { auth, settingActions, loadingBarActions } = this.props;
        const { dialogData, valid } = this.state;
        this.setState({ isSubmited: true });
        if (!CCLiteCommonFunc.isFormValid(valid)) return;

        let fleetId = auth.selectedFleet.fleetId;

        let body = {
            fleetId,
            voucherCode: dialogData.voucherCode,
            campaignId: dialogData.campaign._id,
            validFrom: moment(dialogData.validFrom, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            validTo: moment(dialogData.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            valueByCurrencies: [
                {
                    value: dialogData.value,
                    currencyISO: dialogData.currencyISO
                }
            ]
        };
        loadingBarActions.showLoadingSpiner();
        settingActions.createVoucherCode(body)
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                    this.context.notification(
                        'success',
                        I18n.t('voucherSettings.Create_voucher_code_success')
                    );
                    this.closeFormModal();
                    this.getVoucherCodeList();
                    this.setState({ isSubmited: false });
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('voucherSettings.Create_voucher_code_fail')
                        );
                    }
                }
            });
    };

    getTableColumns = () => {
        let tableColumns = Object.assign([], Columns);
        const { voucherCodeList } = this.state;
        const { auth } = this.props;
        let timeZone = auth.selectedFleet.timezone;
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'table-selectbox':
                    col.customHeader = columnKey => {
                        return (
                            <CcCheckbox
                                className="table-selectbox all-checkbox"
                                checked={
                                    this.state.voucherCodeList
                                        ? this.state.voucherCodeList.list.length == 0
                                            ? false
                                            : this.state.voucherCodeList.list.filter(data => {
                                                return !data.selected;
                                            }).length == 0
                                        : false
                                }
                                onChange={e => {
                                    this.handleItemSelectedChange(null, e);
                                }}
                            />
                        );
                    };
                    col.customCell = data => {
                        return (
                            <CcCheckbox
                                className="table-selectbox"
                                disabled={data.inUse}
                                checked={data.inUse ? false : data.selected}
                                onChange={e => {
                                    this.handleItemSelectedChange(data._id, e);
                                }}
                            />
                        );
                    };
                    break;
                case 'voucherCode':
                    col.customCell = data => {
                        return (
                            <a
                                className="clickable"
                                onClick={e => {
                                    this.handleClickEditButton(data._id, e);
                                }}
                            >
                                {' '}
                                {data.voucherCode}
                            </a>
                        );
                    };
                    break;
                case 'value':
                    col.customCell = obj => {
                        let value =
                            obj.valueByCurrencies && obj.valueByCurrencies[0]
                                ? obj.valueByCurrencies[0]
                                : { value: 0 };
                        return formatCurrency.format(value.value, { code: value.currencyISO });
                    };
                    break;
                case 'validFrom':
                    col.customCell = obj => {
                        return moment(obj.validFrom)
                            .tz(timeZone)
                            .format('DD/MM/YYYY');
                    };
                    break;
                case 'validTo':
                    col.customCell = obj => {
                        return moment(obj.validTo)
                            .tz(timeZone)
                            .format('DD/MM/YYYY');
                    };
                    break;
                case 'isActive':
                    col.customCell = obj => {
                        let status = '';
                        if (obj.isUsed !== '') {
                            status = obj.isUsed;
                        } else {
                            if(obj.validTo && new Date() > Date.parse(obj.validTo)) {
                                status = 'expired'
                            } else {
                                status = obj.isActive ? 'Active' : 'Inactive';
                            }
                        };
                        return (
                            <div className={status}>
                                <span>
                                    <Translate value={`voucherSettings.${status}`} />
                                </span>
                            </div>
                        );
                    };
                    break;
                case 'actions':
                    col.customCell = (obj, rowIndex) => {
                        // let status = obj.isActive ? 'Active' : 'Inactive';
                        let isExpired = false;
                        if (new Date() > Date.parse(obj.validTo)) {
                            isExpired = true;
                        };
                        let actions = [];
                        if (!obj.editable && !isExpired) {
                            actions.push({
                                label: "voucherSettings.Edit",
                                eventKey: "Edit"
                            });
                        };
                        if (obj.isActive) {
                            actions.push({
                                label: 'voucherSettings.Deactivate',
                                eventKey: 'Deactive'
                            });
                        } else {
                            if (obj.campaign.isActive && !isExpired) {
                                actions.push({
                                    label: 'voucherSettings.Activate',
                                    eventKey: 'Active'
                                });
                            }
                            actions.push({
                                label: 'voucherSettings.Delete',
                                eventKey: 'Delete'
                            });
                        };

                        return (
                            <div className="driver-actions-container">
                                {obj.isUsed === '' &&
                                (!this.props.permissions || this.props.permissions.actions) &&
                                !isExpired ? (
                                    <TableActions
                                        rowIndex={rowIndex}
                                        rowData={obj}
                                        onSelect={this.handleMenuClick}
                                        totalRow={voucherCodeList.list.length}
                                        rowHeight={this.state.rowHeight}
                                        menuItems={actions}
                                        tableHeight={this.state.tableHeight}
                                    />
                                ) : (
                                        <a
                                            onClick={e => {
                                                this.handleClickEditButton(obj._id, e);
                                            }}
                                            href="#view"
                                        >
                                            <Translate value="customer.View" />
                                        </a>
                                    )}
                            </div>
                        );
                    };
                default:
                    break;
            }
        });

        return tableColumns;
    };

    handleItemSelectedChange(id, e) {
        const { voucherCodeList } = this.state;
        let listItem = _.cloneDeep(voucherCodeList.list);
        if (id != null) {
            let index = listItem.findIndex(obj => id === obj._id);
            if (index >= 0) listItem[index].selected = e.target.checked;
        } else {
            let value = e.target.checked;
            listItem = listItem.map(obj => {
                return {
                    ...obj,
                    selected: value
                };
            });
        }
        this.setState({
            voucherCodeList: {
                ...voucherCodeList,
                list: listItem
            }
        });
    }

    handleConfirmButtonClick(id) {
        switch (id) {
            case CONFIRM_DELETE_ID:
                {
                    this.deleteMultipleCodes();
                }
                break;
            case CONFIRM_SEND_INBOX_ID:
                {
                    this.sendToInboxPromotionCode();
                }
                break;
        }
        this.handleConfirmCloseClick();
    }

    handleConfirmCloseClick() {
        this.setState({
            confirm: null
        });
    }

    handleDeleteClick() {
        this.setState({
            confirm: {
                id: CONFIRM_DELETE_ID,
                title: I18n.t('voucherSettings.Confirmation'),
                body: (
                    <div>
                        <Translate value="voucherSettings.Delete_voucher_code_confirmation" />
                        <br />
                        <Translate value="voucherSettings.Just_delete_inactive" />
                    </div>
                ),
                buttonTitle: 'Yes',
                closeButtonText: 'No'
            }
        });
    }

    // sendToInboxPromotionCode() {
    //     const { auth, settingActions, loadingBarActions } = this.props;
    //     let fleetId = auth.selectedFleet.fleetId;
    //     loadingBarActions.showLoadingSpiner();
    //     settingActions
    //         .sendInboxPromotionCode({ fleetId, id: sendInBoxId })
    //         .then(data => {
    //             loadingBarActions.hideLoadingSpiner();
    //             if (data.ok && !data.error) {
    //                 this.context.notification(
    //                     'success',
    //                     I18n.t('voucherSettings.Send_inbox_success')
    //                 );
    //                 this.getVoucherCodeList();
    //             } else {
    //                 if (data.error) {
    //                     this.context.notification(
    //                         'error',
    //                         I18n.t('errors.' + data.error.errorCode)
    //                     );
    //                 } else {
    //                     this.context.notification(
    //                         'error',
    //                         I18n.t('voucherSettings.Send_inbox_fail')
    //                     );
    //                 }
    //             }
    //         });
    // }

    deleteMultipleCodes() {
        const { voucherCodeList } = this.state;
        const { auth, settingActions, loadingBarActions } = this.props;
        let ids = voucherCodeList.list
            .filter(obj => {
                return (!obj.isActive && obj.selected && obj.isUsed === '');
            })
            .map(obj => obj._id);

        let fleetId = auth.selectedFleet.fleetId;
        loadingBarActions.showLoadingSpiner();
        settingActions.deleteMultiVoucherCode({ fleetId, ids }).then(data => {
            loadingBarActions.hideLoadingSpiner();
            if (data.ok && !data.error) {
                if (ids.length > 1) {
                    this.context.notification(
                        'success',
                        I18n.t('voucherSettings.Delete_multiple_voucher_codes_success')
                    );
                } else {
                    this.context.notification(
                        'success',
                        I18n.t('voucherSettings.Delete_voucher_code_success')
                    );
                }

                this.getVoucherCodeList();
            } else {
                if (data.error) {
                    this.context.notification(
                        'error',
                        I18n.t('errors.' + data.error.errorCode)
                    );
                } else {
                    if (ids.length > 1) {
                        this.context.notification(
                            'error',
                            I18n.t('voucherSettings.Delete_multiple_voucher_codes_fail')
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('voucherSettings.Delete_voucher_code_fail')
                        );
                    }
                }
            }
            this.handleConfirmCloseClick();
        });
    }

    activeMultipleCodes(status) {
        const { voucherCodeList } = this.state;
        const { auth, settingActions, loadingBarActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        let ids = voucherCodeList.list
            .filter(obj => {
                return (obj.selected && obj.isUsed === '');
            })
            .map(obj => obj._id);
        if (ids.length == 0) return;
        loadingBarActions.showLoadingSpiner();
        settingActions
            .activeMultiVoucherCode({ fleetId, ids, isActive: status })
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                    if (ids.length > 1) {
                        this.context.notification(
                            'success',
                            I18n.t(
                                'voucherSettings.Update_multiple_voucher_code_status_success'
                            )
                        );
                    } else {
                        this.context.notification(
                            'success',
                            I18n.t('voucherSettings.Update_voucher_code_status_success')
                        );
                    }
                    this.getVoucherCodeList();
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        if (ids.length > 1) {
                            this.context.notification(
                                'error',
                                I18n.t(
                                    'voucherSettings.Update_multiple_voucher_code_status_fail'
                                )
                            );
                        } else {
                            this.context.notification(
                                'error',
                                I18n.t('voucherSettings.Update_voucher_code_status_fail')
                            );
                        }
                    }
                }
            });
    };

    deleltePromotionCode(obj) {
        this.setState({ showConfirm: true, dialogData: obj });
    }

    confirmDeletePromotionCode() {
        const { auth, settingActions, loadingBarActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        let id = this.state.dialogData._id;
        loadingBarActions.showLoadingSpiner();
        settingActions.deleteVoucherCode({ fleetId, id }).then(data => {
            loadingBarActions.hideLoadingSpiner();
            if (data.ok && !data.error) {
                this.closeFormModal();
                this.context.notification(
                    'success',
                    I18n.t('voucherSettings.Delete_voucher_codes_success')
                );
                this.getVoucherCodeList();
            } else {
                if (data.error) {
                    this.context.notification(
                        'error',
                        I18n.t('errors.' + data.error.errorCode)
                    );
                } else {
                    this.context.notification(
                        'error',
                        I18n.t('voucherSettings.Delete_voucher_codes_fail')
                    );
                }
            };
        });
    }

    // handleSendToInboxClick(id) {
    //     sendInBoxId = id;
    //     this.setState({
    //         confirm: {
    //             id: CONFIRM_SEND_INBOX_ID,
    //             title: I18n.t('voucherSettings.Confirmation'),
    //             body: I18n.t('voucherSettings.Send_inbox_confirmation'),
    //             buttonTitle: 'Yes',
    //             closeButtonText: 'No'
    //         }
    //     });
    // }

    handleEditPromoCode = () => {
        this.setState({
            isEditable: true
        })
    };

    handleUpdatePromoCode = () => {
        const { auth, settingActions, loadingBarActions } = this.props;
        const { dialogData, valid } = this.state;
        this.setState({ isSubmited: true });
        if (!CCLiteCommonFunc.isFormValid(valid)) return;
        let fleetId = auth.selectedFleet.fleetId;
        let body = {
            fleetId,
            id: dialogData._id,
            validFrom: moment(dialogData.validFrom, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            validTo: moment(dialogData.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        };
        loadingBarActions.showLoadingSpiner();
        settingActions.updateVoucherCode(body)
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                    this.context.notification(
                        'success',
                        I18n.t('voucherSettings.Update_voucher_code_status_success')
                    );
                    this.closeFormModal();
                    this.getVoucherCodeList();
                    this.setState({ isSubmited: false });
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('voucherSettings.Update_voucher_code_status_fail')
                        );
                    }
                }
            });
    };


    handleUpdateStateClick(id, status) {
        const { auth, settingActions, loadingBarActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        loadingBarActions.showLoadingSpiner();
        settingActions
            .activeVoucherCode({
                fleetId,
                id,
                isActive: status
            })
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok && !data.error) {
                    this.context.notification(
                        'success',
                        I18n.t('voucherSettings.Update_voucher_code_status_success')
                    );
                    this.getVoucherCodeList();
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('voucherSettings.Update_voucher_code_status_fail')
                        );
                    }
                }
            });
    };

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    };


    render() {
        const { dialogData, disable, voucherCodeList, isDetailForm } = this.state;
        const { commonData, auth, permissions } = this.props;
        const { selectedFleet } = auth;
        const bodyData =
            voucherCodeList && voucherCodeList.list ? voucherCodeList.list : [];
        let currentCurrency = dialogData.currencyISO;
        const allZonesByCurrency = _.filter(
            commonData.mapZone,
            z =>
                z.display &&
                z.isActive &&
                z.currency &&
                z.currency.iso === currentCurrency
        );

        return (
            <div className="content">
                {this.state.showConfirm ? (
                    <Modal onHide={this.closeFormModal} show={true} backdrop={true} className="confirm">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <Translate value="voucherSettings.Delete_voucher_code" />
                            </Modal.Title>
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={this.closeFormModal}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Translate value="voucherSettings.Delete_confirm_voucher_code" />
                            <br />
                            <Translate value="voucherSettings.Just_delete_inactive" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className={'btn-save'}
                                onClick={this.confirmDeletePromotionCode}
                            >
                                <Translate value="voucherSettings.Yes" />
                            </Button>
                            <Button className={'btn-cancel'} onClick={this.closeFormModal}>
                                <Translate value="voucherSettings.No" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) : (
                        ''
                    )}

                {
                    this.state.showDialogModal && <AddEditVoucher
                        selectedFleet={selectedFleet}
                        showDialogModal={this.state.showDialogModal}
                        data={this.state.dialogData}
                        valid={this.state.valid}
                        isSubmited={this.state.isSubmited}
                        campaignList={this.state.campaignList}
                        currencies={auth.selectedFleet.currencies}
                        disable={this.state.disable}
                        isEditable={this.state.isEditable}
                        isDetailForm={this.state.isDetailForm}
                        permissions={permissions}
                        ValidatorCallback={this.ValidatorCallback}

                        closeFormModal={this.closeFormModal}
                        onChangeCurrency={this.onChangeCurrency}
                        handleVoucherCodeChange={this.handleVoucherCodeChange}
                        handleCampaignChange={this.handleCampaignChange}
                        handleAmountValueChange={this.handleAmountValueChange}
                        handleValidFromChanged={this.handleValidFromChanged}
                        handleValidToChanged={this.handleValidToChanged}

                        saveDialogForm={this.saveDialogForm}
                        handleUpdatePromoCode={this.handleUpdatePromoCode}
                        handleEditPromoCode={this.handleEditPromoCode}

                    />
                }

                <ButtonToolbar
                    className="text-center header-button-group form-cus"
                    ref={node => (this.toobarContainer = node)}
                >
                    <ButtonGroup className="group-left">
                        <FormGroup className="search-format mb0">
                            <FormControl
                                className="search-form"
                                type="text"
                                placeholder={I18n.t('General.search')}
                                onChange={e => this.searchStrChange(e.target.value)}
                                onKeyPress={this.searchKeyPressHandle}
                            />
                            <BsSearch className="search-icon" />
                        </FormGroup>
                        {!this.props.permissions || this.props.permissions.actions ? (
                            <React.Fragment>
                                <Button
                                    className="btn-header text-add-header"
                                    onClick={this.handleAddButtonClick}
                                >
                                    <Translate value="voucherSettings.Add" />
                                </Button>
                                <Button
                                    className="btn-header text-delete-header"
                                    onClick={this.handleDeleteClick}
                                    disabled={this.isDisableDelete()}
                                >
                                    <Translate value="customer.Delete" />
                                </Button>
                                <Button
                                    className="btn-header text-active-header"
                                    disabled={this.isDisableActive()}
                                    onClick={() => {
                                        this.activeMultipleCodes(true);
                                    }}
                                >
                                    <Translate value="customer.Activate" />
                                </Button>
                                <Button
                                    className="btn-header text-deactive-header"
                                    disabled={this.isDisableDeactive()}
                                    onClick={() => {
                                        this.activeMultipleCodes(false);
                                    }}
                                >
                                    <Translate value="customer.Deactivate" />
                                </Button>
                            </React.Fragment>
                        ) : (
                                ''
                            )}
                    </ButtonGroup>
                    {!this.props.permissions || this.props.permissions.actions ? (
                        <ButtonGroup className="group-right">
                            <Button
                                className="btn-header text-add-header"
                                onClick={this.onImportButtonClick}
                            >
                                <Translate value="customer.Import" />
                            </Button>
                        </ButtonGroup>
                    ) : null}
                </ButtonToolbar>

                {this.state.isImportFile ? (
                    <Import
                        isShow={this.state.isImportFile}
                        import={this.props.settingActions.importVoucherCodes}
                        closeDialog={this.onCancelImportFile}
                        callback={this.getVoucherCodeList}
                    />
                ) : null}


                <div className="gridViewTable">
                    <StickyTable
                        columns={this.getTableColumns()}
                        bodyData={bodyData}
                        footerData={voucherCodeList}
                        handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.rowHeight}
                        getTableHeight={this.getTableHeight}
                    />
                </div>

                <Confirm
                    confirm={this.state.confirm}
                    handleConfirmButtonClick={this.handleConfirmButtonClick}
                    handleConfirmCloseClick={this.handleConfirmCloseClick}
                />
            </div>
        );
    }
}

VoucherCode.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        settings: state.settings,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoucherCode);
