import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import styles from '../../styles/formInfo.module.scss';
import { ValidCase, Validator, WarningFeeInput } from '../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  getSuppilerPermission,
} from '../../../../utils/commonFunctions';
import DeleteWhite from '../../../../assets/images/icons/deleteWhite.svg';
import VisibilityWhite from '../../../../assets/images/icons/visibilityWhite.svg';
import AddWhite from '../../../../assets/images/icons/addWhite.svg';
import {
  DriverType,
  SettelementSetting,
  paymentGetwaySettlement,
} from '../../../../constants/commondata';
import { DriverInfoFields } from '../../DynamicField';
import IntlTelInputApp from '../../../../components/intlTelInputCustome/IntlTelInputApp';
import DateTime from '../../../../components/dateTime/DateTime';
import moment from 'moment-timezone';
import UploadFile from '../../../../components/upload/upload';

const saveOptions = [
  {
    key: 'active',
    value: 'active',
  },
  {
    key: 'inactive',
    value: 'inactive',
  },
  {
    key: 'inReviewInProgress',
    value: 'inReviewInProgress',
  },
];

const FormInfo = (valProps) => {
  const {
    action,
    props,
    state,
    handleActiveChange,
    handleChangeImages,
    handleDeleteImages,
    handleVisibilityImages,
    handleDriverTypeChange,
    validatorCallback,
    handleServiceTypeChange,
    handleCompanyChange,
    companyChanged,
    getDriverValidationInfo,
    handleDriverInfoChange,
    handlePhoneChange,
    handlePhoneFieldChange,
    closeDialogForm,
    saveDialogForm,
    onEditable,
    handleDriverInfoBackupFieldChange,
    handleRemoveDriverInfoBackupFieldChange,
    handleResetPasswordClick,
    handleUsernameChange,
    supplierList,
  } = valProps;
  const { detailItem } = state;
  const { auth, commonData, permissions } = props;

  const enableDelivery =
    (auth.selectedFleet.delivery && auth.selectedFleet.delivery.enable) ||
    (auth.selectedFleet.food && auth.selectedFleet.food.enable) ||
    (auth.selectedFleet.mart && auth.selectedFleet.mart.enable);

  const renderDriverType = () => {
    const editable = (permissions && !permissions.actions) || !state.editable;

    let driverTypes = [
      { key: '0', value: DriverType.OwnerOperator, name: 'individual_vehicle' },
      {
        key: '1',
        value: DriverType.IndividualDriver,
        name: 'company_vehicle',
      },
    ];

    if (enableDelivery && detailItem?.driverInfo?.serviceType === 'delivery') {
      driverTypes = [
        {
          key: '2',
          value: DriverType.deliveryIndividual,
          name: 'individual_vehicle',
        },
        {
          key: '3',
          value: DriverType.deliveryCompany,
          name: 'company_vehicle',
        },
      ];
    }

    const driverTypeValue = _.get(detailItem, 'driverInfo.driverType', '');

    if (!driverTypeValue) {
      driverTypes.unshift({
        key: '-1',
        value: '',
        name: 'selectDriverType',
      });
    }

    return (
      <FormGroup
        className={
          !state.isSubmited ? null : !state.valid.driverType ? 'error' : null
        }
      >
        <Form.Label>
          <Translate value="driver.Driver_Type" />
          <span style={{ marginLeft: '5px' }} className="require">
            *
          </span>
        </Form.Label>
        <FormControl
          as="select"
          value={detailItem.driverInfo.driverType}
          placeholder="select"
          onChange={handleDriverTypeChange}
          disabled={editable}
          className="form-custom select-driver-type"
        >
          {driverTypes.map((e) => (
            <option key={e.key} value={e.value}>
              {I18n.t(`driver.${e.name}`)}
            </option>
          ))}
        </FormControl>
        <Validator
          id="driverType"
          callback={validatorCallback}
          className={styles['remove-margin']}
        >
          <ValidCase
            hide={!state.isSubmited}
            valid={!Validation.isStringEmpty(detailItem.driverInfo.driverType)}
            message={I18n.t('driver.ERROR_INPUT_DRIVER_TYPE')}
          />
        </Validator>
      </FormGroup>
    );
  };

  const renderDriverField = (
    item,
    isRequireStripe,
    isRequireMolpay,
    editable
  ) => {
    const { detailItem, driverInfoDynamic, isEditForm = false } = state;
    const { language } = props;
    let validation = getDriverValidationInfo(
      item,
      isRequireStripe,
      isRequireMolpay
    );
    let control = null;
    let fieldMapping = DriverInfoFields[item.key];
    switch (item.type) {
      case 'Phone':
        if (item.key === 'phone') {
          control = (
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={driverInfoDynamic.phone}
              onPhoneNumberBlur={(
                status,
                value,
                countryData,
                number,
                id,
                suggestion,
                isDialCodeOnly
              ) => {
                handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  true,
                  isDialCodeOnly
                );
              }}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  false
                );
              }}
              disabled={!editable}
              id={item.key}
            />
          );
        } else {
          control = (
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={state.driverInfoDynamic[item.key]}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                handlePhoneFieldChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  item.key
                );
              }}
              disabled={!editable}
              id={item.key}
            />
          );
        }
        break;
      case 'Date':
        control = (
          <DateTime
            // value={driverInfoDynamic[item.key]}
            value={
              driverInfoDynamic[item.key]
                ? moment(driverInfoDynamic[item.key])
                    .locale(language.toLowerCase())
                    .format('L')
                : ''
            }
            // timeFormat={false}
            dateFormat={true}
            onChange={(e) => handleDriverInfoChange({ value: e._d }, item.key)}
            inputProps={{ readOnly: true, disabled: !editable }}
            closeOnSelect
            placeholder={I18n.t('driver.placeholderDate')}
          />
        );
        break;
      case 'SelectBox':
        if (item.key == 'gender') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={state.driverInfoDynamic[item.key]}
              onChange={(e) =>
                handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={!editable}
            >
              <option key={-1} value={-1}>
                {I18n.t('customer.Select_Gender')}
              </option>
              <option key={0} value={0}>
                {I18n.t('customer.Male')}
              </option>
              <option key={1} value={1}>
                {I18n.t('customer.Female')}
              </option>
              <option key={2} value={2}>
                {I18n.t('customer.Other')}
              </option>
            </FormControl>
          );
        } else if (item.key == 'idType') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={state.driverInfoDynamic[item.key]}
              onChange={(e) =>
                handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={!editable}
            >
              <option key={-1} value="">
                {I18n.t('customer.Select_Idtype')}
              </option>
              {item.options &&
                item.options.length > 0 &&
                item.options.map((idType) => (
                  <option key={idType.key} value={idType.key}>
                    {idType.language[props.language]}
                  </option>
                ))}
            </FormControl>
          );
        } else if (item.key.substring(0, 6) === 'backup') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={driverInfoDynamic[item.key]}
              onChange={(e) =>
                handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={!editable}
            >
              <option key={0} value={''}>
                {item.title[props.language]}
              </option>
              {item && item.options
                ? item.options.map((option) => {
                    return (
                      <option key={option.key} value={option.key}>
                        {option.language[props.language]}
                      </option>
                    );
                  })
                : null}
            </FormControl>
          );
        } else {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={driverInfoDynamic[item.key]}
              onChange={(e) =>
                handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={!editable}
            >
              <option key={0} value={''}>
                {I18n.t(
                  fieldMapping && fieldMapping.selectTitle
                    ? fieldMapping.selectTitle
                    : 'Select'
                )}
              </option>
              {fieldMapping && fieldMapping.optionValue
                ? _.get(
                    fieldMapping.getFromProps ? props : state,
                    fieldMapping.optionValue,
                    []
                  ).map((s) => {
                    return (
                      <option
                        key={s[fieldMapping.opValueKey]}
                        value={s[fieldMapping.opValueKey]}
                      >
                        {s[fieldMapping.opLabelKey]}
                      </option>
                    );
                  })
                : null}
            </FormControl>
          );
        }
        break;
      case 'File':
        let fileName = '';
        if (
          driverInfoDynamic[item.key] &&
          driverInfoDynamic[item.key].length > 0
        ) {
          let splitUrl = driverInfoDynamic[item.key].split('/');
          fileName = splitUrl[splitUrl.length - 1];
        }

        control = (
          <div>
            <UploadFile
              id={item.key}
              name={item.key}
              disabled={!editable}
              onChange={(data, error) =>
                handleDriverInfoBackupFieldChange(data, error, item.key)
              }
              existFile={
                fileName
                  ? {
                      fileName,
                      onRemove: () =>
                        handleRemoveDriverInfoBackupFieldChange(item.key),
                    }
                  : null
              }
            />
            {driverInfoDynamic[item.key] &&
            typeof driverInfoDynamic[item.key] === 'string' ? (
              <a
                href={driverInfoDynamic[item.key]}
                className="text-active"
                target="_blank"
              >
                {driverInfoDynamic[item.key].match(/.(jpg|jpeg|png|gif)$/i) ? (
                  <Translate value="driver.Click_here_to_view" />
                ) : (
                  <Translate value="driver.Click_here_to_download" />
                )}
              </a>
            ) : null}
          </div>
        );
        break;
      case 'CheckBox':
        control = (
          <CcCheckbox
            checked={state.driverInfoDynamic[item.key].toString() === 'true'}
            onChange={(e) => {
              handleDriverInfoChange({ value: e.target.checked }, item.key);
            }}
            disabled={!editable}
            text={item.title[props.language]}
          />
        );
        break;
      default:
        control = (
          <FormControl
            className="form-custom number-no-spin"
            type={item.type.toLowerCase()}
            maxLength={
              fieldMapping && fieldMapping.maxLength
                ? fieldMapping.maxLength
                : ''
            }
            value={state.driverInfoDynamic[item.key]}
            onChange={(e) =>
              handleDriverInfoChange({ value: e.target.value }, item.key)
            }
            disabled={!editable}
            // disabled={validation.bankField ? !bankAccountEditable : editable}
            name={item.key}
          ></FormControl>
        );
        break;
    }

    const validMore = {};
    if (
      state.isSubmited &&
      item.key === 'email' &&
      state.driverInfoDynamic.email &&
      validation.bankField === true &&
      !Validation.validateEmail(state.driverInfoDynamic.email)
    ) {
      validMore.className = 'has-error';
    }

    return (
      <FormGroup {...validMore} className={validation.validationState}>
        {item.type !== 'CheckBox' && (
          <Form.Label>
            <span>{item.title[props.language]}</span>
            {validation.isRequired && <span className="require"> *</span>}
          </Form.Label>
        )}
        {control}
        {validation.validCases && validation.validCases.length ? (
          <Validator
            id={item.key}
            callback={validatorCallback}
            className={styles['remove-margin']}
          >
            {_.map(validation.validCases, (validCase, index) => (
              <ValidCase
                key={index}
                hide={!state.isSubmited}
                valid={validCase.valid}
                message={validCase.message}
              />
            ))}
          </Validator>
        ) : null}
      </FormGroup>
    );
  };

  let isRequiredAvatar = false;

  if (state.relativeInfoData && state.driverFiledList) {
    const avatar = state.driverFiledList.find((item) => {
      return item.key === 'avatar';
    });
    if (avatar) {
      isRequiredAvatar = avatar.isRequired;
    }
  }

  let isInreview = false;
  if (
    !state.detailItem.isActive &&
    state.detailItem.driverInfo &&
    !state.detailItem.driverInfo.isActivate
  ) {
    isInreview = true;
  }

  const disableActivateCheckbox =
    (props.permissions && !props.permissions.activatedriver) || !state.editable;

  let newSaveOptions = saveOptions;
  if (
    state?.detailItem?.driverInfo?.statusReview !== 'inComplete' &&
    state?.detailItem?.initStatusDriver !== 'inReviewInProgress'
  ) {
    newSaveOptions = newSaveOptions.filter(
      (elem) => elem.key !== 'inReviewInProgress'
    );
  }

  const renderImage = (val) => {
    const s3Host = props.auth.selectedFleet.s3Host || '';
    if (typeof val === 'string') {
      return `${val}`;
    } else if (val) {
      return URL.createObjectURL(val);
    }
  };
  const isShowImage = state?.detailItem?.avatar;

  const bankingInfoSetting = auth.selectedFleet.bankingInfo || {};

  const isBankingInfoRequired =
    bankingInfoSetting &&
    bankingInfoSetting.enable &&
    bankingInfoSetting.verifyAccount == SettelementSetting.verifyAccount
      ? true
      : false;

  const { creditConfig } = auth.selectedFleet;
  let checkRequireStripe =
    isBankingInfoRequired &&
    !creditConfig.multiGateway &&
    creditConfig.configGateway &&
    creditConfig.configGateway.gateway === paymentGetwaySettlement.Stripe;

  let isRequireStripe = false;
  let isRequireMolpay = false;
  if (props.auth.selectedFleet.drvPayout?.enable) {
    isRequireStripe = false;
    isRequireMolpay = true;
  } else {
    isRequireStripe = checkRequireStripe;
    isRequireMolpay = false;
  }

  let srcImage = state?.detailItem?.avatar;

  const isSupplier = props?.auth?.user?.roles?.isSupplier;

  let newCompaniesList = _.sortBy(
    [
      ...props?.commonData?.companies,
      ...(supplierList?.filter((item) => item.isActive) || []),
    ],
    [(company) => company.name.toLowerCase()]
  );
  if (state.editable) {
    if (_.get(detailItem, 'driverInfo.company.companyId', '') && supplierList.length && !newCompaniesList.find(item => item._id === _.get(detailItem, 'driverInfo.company.companyId', ''))) {
      handleCompanyChange('')
    }
  }

  return (
    <>
      <Row>
        <Col md={12} lg={12}>
          <FormGroup
            className={
              !state.isSubmited ? null : !state.valid.avatar ? 'error' : null
            }
          >
            <div className={styles['input_images']}>
              {state.editable && (
                <div
                  className={`${styles['file_upload']} ${styles['size-small']}`}
                >
                  <div className={styles['upload-group-image']}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <img
                        alt="AddWhite"
                        src={AddWhite}
                        style={{ width: '24px', height: '24px' }}
                      />
                      <p style={{ color: 'White' }}>Upload</p>
                    </div>
                  </div>
                  <input
                    type="file"
                    className={styles['inputfile']}
                    accept="image/*"
                    onChange={(e) => handleChangeImages(e)}
                    // disabled={isShowImage ? true : false}
                  />
                </div>
              )}

              {/* {state.editable && isShowImage && (
                <div
                  className={`${styles['file_upload_render']} ${styles['size-small']}`}
                >
                  <img
                    className={styles['image-render-small']}
                    src={renderImage(isShowImage)}
                    alt="iconOnMap"
                  />
                </div>
              )} */}

              {isShowImage && (
                <div
                  className={`${styles['file_upload_render']} ${styles['size-small']}`}
                >
                  <img
                    className={styles['image-render-small-2']}
                    src={renderImage(isShowImage)}
                    alt="iconOnMap"
                  />
                  <div className={`${styles['icon-group']}`}>
                    <div
                      className={styles['visibility-icon']}
                      onClick={() => handleVisibilityImages(true)}
                    >
                      <img alt="VisibilityWhite" src={VisibilityWhite} />
                    </div>
                    {state.editable && (
                      <div
                        className={styles['delete-icon']}
                        onClick={handleDeleteImages}
                      >
                        <img alt="DeleteWhite" src={DeleteWhite} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isRequiredAvatar ? (
              <Validator
                id="avatar"
                callback={validatorCallback}
                className={styles['remove-margin']}
              >
                <ValidCase
                  hide={!state.isSubmited}
                  valid={!Validation.isStringEmpty(srcImage)}
                  message={I18n.t('driver.ERROR_INPUT_AVATAR')}
                />
              </Validator>
            ) : null}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={4}>
          {action === 'add' ? (
            <FormGroup>
              <Form.Label>
                <span
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: 'orange',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                ></span>
                <Translate value="driver.inReviewInProgress" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={I18n.t(`driver.${detailItem.statusDriver}`)}
              />
            </FormGroup>
          ) : (
            <FormGroup>
              <Form.Label>
                <span
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor:
                      detailItem.statusDriver === 'active'
                        ? 'green'
                        : detailItem.statusDriver === 'inactive'
                        ? 'red'
                        : 'orange',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                ></span>
                <Translate
                  value={`driver.${
                    newSaveOptions.find(
                      (item) => item.value === detailItem.statusDriver
                    )?.key
                  }`}
                />
              </Form.Label>
              <FormControl
                as="select"
                value={detailItem.statusDriver}
                placeholder="select"
                onChange={handleActiveChange}
                disabled={disableActivateCheckbox}
                className="form-custom select-driver-type"
              >
                {newSaveOptions.map((e) => (
                  <option key={e.key} value={e.value}>
                    {I18n.t(`driver.${e.key}`)}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          )}
        </Col>
      </Row>

      <Row className={styles['flex-item-row']}>
        <Col md={12} lg={4} className={styles['flex-item-col']}>
          <FormGroup
            className={
              !state.isSubmited
                ? null
                : !state.valid.serviceType
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="driver.serviceType" />
              <span style={{ marginLeft: '5px' }} className="require">
                *
              </span>
            </Form.Label>
            <FormControl
              as="select"
              value={detailItem.driverInfo.serviceType}
              placeholder="select"
              onChange={handleServiceTypeChange}
              disabled={!state.editable}
              className="form-custom select-driver-type"
            >
              <option value={'transport'}>{I18n.t(`driver.transport`)}</option>
              {!isSupplier && enableDelivery && (
                <option value={'delivery'}>{I18n.t(`driver.delivery`)}</option>
              )}
            </FormControl>
            <Validator
              id="serviceType"
              callback={validatorCallback}
              className={styles['remove-margin']}
            >
              <ValidCase
                hide={!state.isSubmited}
                valid={
                  !Validation.isStringEmpty(detailItem.driverInfo.serviceType)
                }
                message={I18n.t('driver.ERROR_INPUT_SERVICE_TYPE')}
              />
            </Validator>
          </FormGroup>
        </Col>

        <Col md={12} lg={4} className={styles['flex-item-col']}>
          {renderDriverType()}
          {/* {isInreview && detailItem._id ? (
              <div className="display-flex driver-profile">
                <div className="driver-status">
                  <p>{I18n.t('driver.In_Review')}</p>
                </div>
                <div className="display-flex profile-status">
                  <p>
                    {profileStatus === 'completed'
                      ? I18n.t('driver.profile_completed')
                      : profileStatus === 'inComplete'
                      ? I18n.t('driver.profile_incomplete')
                      : I18n.t('driver.profile_inProgress')}
                  </p>
                  <span className="checkmark">
                    <div
                      className={`checkmark_circle ${
                        profileStatus === 'completed' ? '' : 'incomplete'
                      }`}
                    ></div>
                    <div className="checkmark_stem"></div>
                    <div className="checkmark_kick"></div>
                  </span>
                </div>
              </div>
            ) : null} */}
        </Col>

        {!props.auth?.user?.roles?.isSupplier && <Col md={12} lg={4} className={styles['flex-item-col']}>
          <FormGroup
            className={
              state.isSubmited
                ? state.valid.company
                  ? null
                  : 'has-error'
                : null
            }
          >
            <Form.Label>
              <Translate value="driver.Company" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              value={_.get(detailItem, 'driverInfo.company.companyId', '')}
              disabled={
                !state.editable ||
                getSuppilerPermission(auth)
              }
              onChange={(e) => handleCompanyChange(e.target.value)}
            >
              <option key={0} value={''}>
                {I18n.t('driver.All_company')}
              </option>
              {newCompaniesList?.length
                ? newCompaniesList.map((cp) => {
                    return (
                      <option key={cp._id} value={cp._id}>
                        {cp.name}
                      </option>
                    );
                  })
                : null}
            </FormControl>
            <Validator
              id="company"
              callback={validatorCallback}
              className={styles['remove-margin']}
            >
              <ValidCase
                hide={!state.isSubmited}
                valid={
                  detailItem.driverInfo.company &&
                  !Validation.isStringEmpty(
                    detailItem.driverInfo.company.companyId
                  )
                }
                message={I18n.t('driver.ERROR_INPUT_COMPANY')}
              />
            </Validator>
            {
              companyChanged &&
              <p className={'text-soft-warning mr-t-10 mr-b-0'} >
                {I18n.t('driver.Warning_change_company')}
              </p>
            }
          </FormGroup>
        </Col>}

        {auth.selectedFleet &&
        auth.selectedFleet.password &&
        auth.selectedFleet.password.driver &&
        auth.selectedFleet.password.regBy == 1 ? (
          <Col md={12} lg={4} className={styles['flex-item-col']}>
            <FormGroup
              className={
                state.isSubmited
                  ? state.valid.username
                    ? null
                    : 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="driver.Username" />{' '}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={detailItem.username}
                onChange={handleUsernameChange}
                disabled={!state.editable}
                maxLength={20}
                autoFocus
              />
              <Validator
                id="username"
                callback={validatorCallback}
                className={styles['remove-margin']}
              >
                <ValidCase
                  hide={!state.isSubmited}
                  valid={!Validation.isStringEmpty(detailItem.username)}
                  message={I18n.t('driver.ERROR_INPUT_USERNAME')}
                />
                <ValidCase
                  hide={!state.isSubmited}
                  valid={Validation.validateUsername(detailItem.username)}
                  message={I18n.t('driver.ERROR_INPUT_VALID_USERNAME')}
                />
              </Validator>
            </FormGroup>
          </Col>
        ) : null}

        {state.relativeInfoData &&
        state.driverFiledList &&
        Object.keys(state.driverInfoDynamic).length
          ? _.map(state.driverFiledList, (item) =>
              item.key === 'company' || item.key === 'avatar' ? null : (
                <Col
                  md={12}
                  lg={4}
                  className={styles['flex-item-col']}
                  key={item.key}
                >
                  {renderDriverField(
                    item,
                    isRequireStripe,
                    isRequireMolpay,
                    state.editable
                  )}
                </Col>
              )
            )
          : null}

        {auth.selectedFleet.referral.enable ? (
          <Col md={12} lg={4} className={styles['flex-item-col']}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.referral_code" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={detailItem.referralCode}
                disabled
              />
            </FormGroup>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col xs={12}>
          <div style={{ color: 'white' }}>
            {auth.selectedFleet.password.driver ? (
              <Translate value="driver.Note_password" />
            ) : null}
            (<span className="require">*</span>
            ): <Translate value="driver.Required_fields" />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}

          {!props.permissions.actions &&
            props.permissions.add &&
            action === 'add' && (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate value={'carSetting.Save'} />
              </Button>
            )}
          {(!props.permissions || props.permissions.actions) &&
          action !== 'add' &&
          props.params.driverId &&
          auth.selectedFleet.password.driver ? (
            <Button
              style={{ padding: '6px 15px', minWidth: 'unset' }}
              className="btn-reset ml-md mt-md"
              onClick={handleResetPasswordClick}
            >
              <Translate value="user.Reset_password" />
            </Button>
          ) : null}
        </Col>
      </Row>

      {state.zoomImage && (
        <>
          <div className={styles['modal-zoom']}>
            <span
              className={styles['close']}
              onClick={() => handleVisibilityImages(false)}
            >
              &times;
            </span>
            <div className={styles['modal-content']}>
              <img
                src={renderImage(isShowImage)}
                alt={'ZoomImage'}
                className={styles['zoomed-image']}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormInfo;
