import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../components/validator';
import { DefaultCurenciesNumberInputField } from '../../../../constants/commondata';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { Columns, HourlyPackageColumns } from './tableHeaderData';
import TableActions from '../../../../components/table/tableAction/TableActions';
import { forEach } from 'lodash';
import _ from 'lodash';
import ExtraWaitTime from '../components/extraWaitTime';
import { BsSearch } from 'react-icons/bs';

var currencyFormatter = require('currency-formatter');

class HourlySetting extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      editable: false,
      footerHourlyPakageData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      showConfirmPackage: false,
      valid: {},
      modelValid: {},
      isSubmited: false,
      isModelSubmited: false,
      tableHeight: 500,
      rowHeight: 50,
      packageRowHeight: 100,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      str: '',
      packageStr: '',
    };
  }

  componentDidMount() {
    this.setState({
      packageRowHeight:
        this.props.auth.selectedFleet.currencies.length < 3 ? 50 : 100,
    });
    const locationQuery = _.get(this.props.router, 'location.query', {});
    if (locationQuery.rateId) {
      this.handleOpenFromDeeplink(locationQuery.rateId);
    }
    this.updateDataList();
  }

  handleOpenFromDeeplink = async (packageID) => {
    try {
      const resultPackage =
        await this.props.settingActions.getDetailHourlyRatePackage({
          fleetId: this.props.auth.selectedFleet.fleetId,
          _id: packageID,
        });
      if (resultPackage?.res?._id) {
        const resultRate =
          await this.props.settingActions.getRateDetailRateHourly({
            _id: resultPackage.res?.fareHourlyId,
            fleetId: this.props.auth.selectedFleet.fleetId,
          });
        if (resultRate?.res?._id) {
          this.setState(
            {
              // modal rate
              showDialog: true,
              dialogData: resultRate.res,
              dialogChanged: false,
              editable: true,
              // modal package
              isEditPackageModel: true,
              isDisablePackageModel: false,
              packageModel: resultPackage.res,
              dialogChanged: false,
            },
            this.getHourlyPackageList
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('regularSetting.Load_hourly_rate_fail')
          );
        }
      } else {
        this.context.notification(
          'error',
          I18n.t('regularSetting.Load_hourly_rate_fail')
        );
      }
    } catch (error) {
      this.context.notification(
        'error',
        I18n.t('regularSetting.Load_hourly_rate_fail')
      );
    }
  };

  handleSearchChange = (e) => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updateDataList();
    }
  };

  updateDataList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str,
    };

    this.props.settingActions.getAllRateHourlies(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit },
        });
      }
    });
  };

  handleSearchInPackageChange = (e) => {
    let str = e.target.value;
    this.setState({ packageStr: str });
  };

  handleSearchInPackageKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getHourlyPackageList();
    }
  };

  getHourlyPackageList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerHourlyPakageData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerHourlyPakageData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        fareHourlyId: this.state.dialogData._id,
        search: this.state.packageStr,
      },
    };

    this.props.settingActions.getAllHourlyRatePackages(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.state.dialogData.dataList = data.res;
        this.setState({
          dialogData: this.state.dialogData,
          footerHourlyPakageData: { page, total, limit },
        });
      }
    });
  };

  saveDialogForm = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    this.props.loadingBarActions.showLoadingSpiner();

    this.state.dialogData.fareWaitTimeNow = this.state.dialogData
      .fareWaitTimeNow
      ? parseFloat(this.state.dialogData.fareWaitTimeNow)
      : 0;
    this.state.dialogData.fareWaitTimeLater = this.state.dialogData
      .fareWaitTimeLater
      ? parseFloat(this.state.dialogData.fareWaitTimeLater)
      : 0;
    if (this.state.dialogData._id) {
      let objectCompany = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        _id: this.state.dialogData._id,
      };
      this.props.settingActions
        .updateRateHourly(this.state.dialogData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('regularSetting.Update_hourly_rate_fail')
              );
            }
          } else {
            this.context.notification(
              'success',
              I18n.t('regularSetting.Update_hourly_rate_success')
            );
            this.closeDialogForm();
            this.updateDataList();
          }
        });
    } else {
      this.props.settingActions
        .createRateHourly(this.state.dialogData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('regularSetting.Create_hourly_rate_fail')
              );
            }
          } else {
            this.context.notification(
              'success',
              I18n.t('regularSetting.Create_hourly_rate_success')
            );
            this.closeDialogForm();
            this.updateDataList();
          }
        });
    }
  };

  handleInputChange = (key, e) => {
    this.state.dialogData[key] = e.target.value;
    if (key == 'onDemand_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.onDemand.payToDrv =
        e.target.value;
    }
    if (key == 'onDemand_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.onDemand.type = e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.inAdvance.payToDrv =
        e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.inAdvance.type = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver') {
      this.state.dialogData.noShow.payToDrv = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver_type') {
      this.state.dialogData.noShow.type = e.target.value;
    }
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleCancelInputChange = (key, e) => {
    switch (key) {
      case 'onDemand_isActive': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_tax': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_techFee': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_isActive': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_tax': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_techFee': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_tax': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTax = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_techFee': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTechFee = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };

  handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    if (group == 'noShow') {
      this.state.dialogData.noShow.valueByCurrencies =
        this.state.dialogData.noShow.valueByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.noShow.valueByCurrencies =
        this.state.dialogData.noShow.valueByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            return Object.assign(c, cur);
          }
          return c;
        });
      if (isAdd) {
        this.state.dialogData.noShow.valueByCurrencies.push(cur);
      }
    } else if (group == 'noShowPayDrvAmt') {
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            c.value = e.target.value;
            return c;
          }
          return c;
        });
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.noShow.drvGetAmtByCurrencies.push(ob);
      }
    } else if (group == 'onDemandPayDrvAmt') {
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else if (group == 'inAdvancePayDrvAmt') {
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else {
      this.state.dialogData.cancellationPolicy[group].valueByCurrencies =
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy[group].valueByCurrencies =
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return Object.assign(c, cur);
            }
            return c;
          }
        );
      if (isAdd) {
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.push(
          cur
        );
      }
    }

    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handlePerminuteInputChange = (key, e) => {
    switch (key) {
      case 'feePerMinute': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].feePerMinute = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minNow': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minNow = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minReservation': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minReservation =
          e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };

  handleDriverCancelChange = (e, type, key) => {
    const dialogData = this.state.dialogData || {};
    let valueInput = 0;
    if (key === 'value') {
      valueInput = (e.target.value || '').toString().replace(/[^0-9\.]+/, '');
    }
    if (key === 'isActive') valueInput = e.target.checked;
    if (key === 'type') valueInput = e.target.value;
    this.setState({
      dialogData: {
        ...dialogData,
        driverCancelPolicy: {
          ..._.get(dialogData, `driverCancelPolicy`, {}),
          [type]: {
            type: _.get(
              dialogData,
              `driverCancelPolicy.${type}.type`,
              'amount'
            ),
            isActive: _.get(
              dialogData,
              `driverCancelPolicy.${type}.isActive`,
              false
            ),
            value: _.get(dialogData, `driverCancelPolicy.${type}.value`, 0),
            [key]: valueInput,
          },
        },
      },
      dialogChanged: true,
    });
  };

  handlePayDrvCashTypeChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              value: _.get(dialogData, `noShow.payDrvCash.value`, 0),
              type: e.target.value,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                value: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.value`,
                  0
                ),
                type: e.target.value,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  handlePayDrvCashValueChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    let valueInput = (e.target.value || '')
      .toString()
      .replace(/[^.0-9\.]+/, '');
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              type: _.get(dialogData, `noShow.payDrvCash.type`, 'commission'),
              value: valueInput,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                type: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.payDrvCash.type`,
                  'commission'
                ),
                value: valueInput,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  handleAddButtonClick = () => {
    let object = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      name: '',
      noShow: {
        isActive: false,
        enableTax: false,
        enableTechFee: true,
        payToDrv: 'commission',
        valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
        drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
      },
      cancellationPolicy: {
        isActive: false,
        inAdvance: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
        onDemand: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
      },
    };
    this.setState({ showDialog: true, dialogData: object, editable: true });
  };
  handleAddPackageButtonClick = () => {
    let packagesOb = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      fareHourlyId: this.state.dialogData._id,
      name: '',
      duration: 0,
      type: 'hour',
      extraDurationType: 'hour',
      coveredDistance: 0,
      feesByCurrencies: this.props.auth.selectedFleet.currencies.map((data) => {
        return {
          currencyISO: data.iso,
          basedFee: 0,
          extraDuration: 0,
          extraDistance: 0,
        };
      }),
    };
    this.setState({ isEditPackageModel: true, packageModel: packagesOb });
  };
  savePackageModelClick = (e) => {
    e.preventDefault();
    if (!this.state.isModelSubmited) {
      this.setState({ isModelSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.modelValid)) {
      return;
    }

    let packagesOb = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      fareHourlyId: this.state.dialogData._id,
      name: this.state.packageModel.name,
      duration: this.state.packageModel.duration,
      type: this.state.packageModel.type,
      extraDurationType:
        this.state.packageModel.type === 'day'
          ? 'hour'
          : this.state.packageModel.extraDurationType,
      coveredDistance: this.state.packageModel.coveredDistance,
      feesByCurrencies: this.state.packageModel.feesByCurrencies,
    };

    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.packageModel._id) {
      packagesOb._id = this.state.packageModel._id;
      this.props.settingActions
        .updateHourlyRatePackage(packagesOb)
        .then((res) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (res.ok && res.res) {
            this.getHourlyPackageList();
            this.setState({
              isEditPackageModel: false,
              packageModel: null,
              isModelSubmited: false,
            });
            this.context.notification(
              'success',
              I18n.t('regularSetting.Update_hourly_rate_package_success')
            );
          } else {
            if (res.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + res.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('regularSetting.Update_hourly_rate_package_fail')
              );
            }
          }
        });
    } else {
      this.props.settingActions
        .createHourlyRatePackage(packagesOb)
        .then((res) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (res.ok && res.res) {
            this.getHourlyPackageList();
            this.setState({
              isEditPackageModel: false,
              packageModel: null,
              isModelSubmited: false,
            });
            this.context.notification(
              'success',
              I18n.t('regularSetting.Create_hourly_rate_package_success')
            );
          } else {
            if (res.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + res.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('regularSetting.Create_hourly_rate_package_fail')
              );
            }
          }
        });
    }
  };

  handlePacakgeInputChange = (key, key2, e) => {
    this.state.packageModel[key] = e.target.value;
    this.setState({ packageModel: this.state.packageModel });
  };

  handlePackageMultiCurrenciesChange = (group, key, currency, e) => {
    let isAdd = true;
    let cur = {
      currencyISO: currency,
      basedFee: 0,
      extraDuration: 0,
      extraDistance: 0,
    };
    this.state.packageModel[group] = this.state.packageModel[group].map((c) => {
      if (c.currencyISO == currency) {
        isAdd = false;
        c[key] = e.target.value;
      }
      return c;
    });
    if (isAdd) {
      cur[key] = e.target.value;
      this.state.packageModel[group].push(cur);
    }

    this.setState({ packageModel: this.state.packageModel });
  };
  confirmDeletePackage = () => {
    this.props.settingActions
      .deleteHourlyRatePackage({
        fleetId: this.props.auth.selectedFleet.fleetId,
        fareHourlyId: this.state.dialogData._id,
        _id: this.state.packageModel._id,
      })
      .then((res) => {
        if (res.ok && res.res) {
          this.getHourlyPackageList();
          this.setState({ showConfirmPackage: false, packageModel: null });
        } else {
          this.context.notification(
            'error',
            I18n.t('regularSetting.Delete_package_error')
          );
        }
      });
  };

  closeDialogPackageForm = () => {
    this.setState({ showConfirmPackage: false, packageModel: null });
  };

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
      isSubmited: false,
    });
  };

  closePackageModelForm = () => {
    this.setState({
      isEditPackageModel: false,
      isDisablePackageModel: false,
      packageModel: null,
      isModelSubmited: false,
    });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  ValidatorModelCallback = (id, valid, messages) => {
    if (this.state.modelValid[id] != valid) {
      this.state.modelValid[id] = valid;
      this.setState({ modelValidd: this.state.modelValidd });
    }
  };
  handleMenuClick = (key, data) => {
    switch (key) {
      case 'View': {
        this.props.settingActions
          .getRateDetailRateHourly({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                  editable: false,
                },
                this.getHourlyPackageList
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Load_hourly_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Edit': {
        this.props.settingActions
          .getRateDetailRateHourly({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                  editable: true,
                },
                this.getHourlyPackageList
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Load_hourly_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Status': {
        if (data.isActive) {
          this.props.settingActions
            .deactiveRateHourly({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_hourly_status')
                  );
                }
              }
            });
        } else {
          this.props.settingActions
            .activeRateHourly({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_hourly_status')
                  );
                }
              }
            });
        }

        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
    }
  };
  handlePackageMenuClick = (key, data) => {
    switch (key) {
      case 'Edit': {
        this.props.settingActions
          .getDetailHourlyRatePackage({
            fleetId: this.props.auth.selectedFleet.fleetId,
            fareHourlyId: this.state.dialogData._id,
            _id: data._id,
          })
          .then((res) => {
            if (res.ok && res.res) {
              this.setState({
                isEditPackageModel: true,
                packageModel: res.res,
                dialogChanged: false,
              });
            }
          });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirmPackage: true, packageModel: data });
        break;
      }
    }
  };
  handlePaginationSelect = (eventKey) => {
    if (this.state.showDialog && this.state.dialogData) {
      this.getHourlyPackageList(eventKey);
    } else {
      this.updateDataList(eventKey);
    }
  };

  handleNumItemsPerPageChange = (e) => {
    let numItemsPerPage = parseInt(e);
    if (this.state.showDialog && this.state.dialogData) {
      this.getHourlyPackageList(0, numItemsPerPage);
    } else {
      this.updateDataList(0, numItemsPerPage);
    }
  };

  confirmDeleteUser = () => {
    this.props.settingActions
      .deleteRateHourly({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('regularSetting.Delete_hourly_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Delete_hourly_fail')
            );
          }
        }
      });
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], Columns);
    // custom data by column
    forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (regular) => {
            return (
              <OverlayTrigger
                overlay={<Tooltip id={'tooltip'}>{regular.name}</Tooltip>}
                placement="top"
                delayShow={300}
              >
                <div
                  className="clickable text-ellipsis"
                  onClick={() => {
                    this.handleMenuClick('View', regular);
                  }}
                >
                  {regular.name}
                </div>
              </OverlayTrigger>
            );
          };
          break;

        case 'latestUpdate':
          col.customCell = (regular) => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={regular.latestUpdate}
              />
            );
          };
          break;

        case 'regularStatus':
          col.customCell = (regular) => {
            let statusStr, statusClass;
            if (regular && regular.isActive) {
              statusStr = <Translate value="regular.Active" />;
              statusClass = 'Active';
            } else {
              if (regular && !regular.isActivate) {
                statusStr = <Translate value="regular.Inactive" />;
                statusClass = 'Inactive';
              } else statusStr = null;
            }
            return (
              <div className={statusClass}>
                {!regular.inUse &&
                (!this.props.permissions || this.props.permissions.actions) ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', regular);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;

        case 'actions':
          col.customCell = (regular, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: 'regular.Edit',
                  eventKey: 'Edit',
                },
              ];

              if (!regular.inUse) {
                actionItems.push({
                  label: regular.isActive
                    ? 'regular.Deactivate'
                    : 'regular.Activate',
                  eventKey: 'Status',
                });

                if (!regular.isActive) {
                  actionItems.push({
                    label: 'regular.Delete',
                    eventKey: 'Delete',
                  });
                }
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={regular}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, regular);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', regular);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getHourlyPackageTableColumns = () => {
    let tableColumns = Object.assign([], HourlyPackageColumns);
    // custom data by column
    forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'packageDuration':
          col.customCell = (hourlyPackage) => {
            return (
              <span>
                {hourlyPackage.duration}{' '}
                {hourlyPackage.type == 'hour'
                  ? I18n.t('regularSetting.hours')
                  : I18n.t('regularSetting.days')}
              </span>
            );
          };
          break;
        case 'baseRate':
          col.customCell = (hourlyPackage) => {
            let renderList =
              hourlyPackage.feesByCurrencies.length > 3
                ? hourlyPackage.feesByCurrencies.slice(0, 3)
                : hourlyPackage.feesByCurrencies;
            return (
              <div>
                {renderList.map((item) => (
                  <div key={item.currencyISO}>
                    {currencyFormatter.format(item.basedFee, {
                      code: item.currencyISO,
                    })}
                  </div>
                ))}
                {hourlyPackage.feesByCurrencies.length > 3 && <div>...</div>}
              </div>
            );
          };
          break;
        case 'distanceCoverage':
          col.customCell = (hourlyPackage) => {
            return (
              <span>
                {hourlyPackage.coveredDistance}{' '}
                {this.props.auth.selectedFleet.unitDistance}
              </span>
            );
          };
          break;
        case 'extraDuration':
          col.customCell = (hourlyPackage) => {
            let renderList =
              hourlyPackage.feesByCurrencies.length > 3
                ? hourlyPackage.feesByCurrencies.slice(0, 3)
                : hourlyPackage.feesByCurrencies;
            return (
              <div>
                {renderList.map((item) => {
                  if (hourlyPackage.type === 'day') {
                    return (
                      <div>
                        {currencyFormatter.format(item.extraDuration, {
                          code: item.currencyISO,
                        }) +
                          '/' +
                          hourlyPackage.type}
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        {currencyFormatter.format(item.extraDuration, {
                          code: item.currencyISO,
                        }) +
                          '/' +
                          (hourlyPackage.extraDurationType || 'hour')}
                      </div>
                    );
                  }
                })}
                {hourlyPackage.feesByCurrencies.length > 3 && <div>...</div>}
              </div>
            );
          };
          break;
        case 'extraDistance':
          col.customCell = (hourlyPackage) => {
            let renderList =
              hourlyPackage.feesByCurrencies.length > 3
                ? hourlyPackage.feesByCurrencies.slice(0, 3)
                : hourlyPackage.feesByCurrencies;
            return (
              <div>
                {renderList.map((item) => (
                  <div>
                    {currencyFormatter.format(item.extraDistance, {
                      code: item.currencyISO,
                    }) +
                      '/' +
                      this.props.auth.selectedFleet.unitDistance}
                  </div>
                ))}
                {hourlyPackage.feesByCurrencies.length > 3 && <div>...</div>}
              </div>
            );
          };
          break;
        case 'actions':
          col.customCell = (hourlyPackage, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              const actionItems = [
                {
                  label: 'regularSetting.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: 'regularSetting.Delete',
                  eventKey: 'Delete',
                },
              ];
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={hourlyPackage}
                  onSelect={(eventKey) => {
                    this.handlePackageMenuClick(eventKey, hourlyPackage);
                  }}
                  totalRow={this.state.dialogData.dataList.list.length}
                  rowHeight={this.state.packageRowHeight}
                  menuItems={actionItems}
                  tableHeight={500}
                  tableId={'hourly-table-id'}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handlePackageMenuClick('Edit', hourlyPackage);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];

    const bodyHourlyPakageData =
      this.state.dialogData && this.state.dialogData.dataList
        ? this.state.dialogData.dataList.list
        : [];
    const { dialogData, isDisablePackageModel = false } = this.state;
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    const enablePenalty = _.get(
      this.props.auth,
      'selectedFleet.drvApp.enablePenalty',
      false
    );
    let payToDrvOnDemand = _.get(
      this.state.dialogData,
      'cancellationPolicy.onDemand.payToDrv',
      'commission'
    );
    let payToDrvInAdvance = _.get(
      this.state.dialogData,
      'cancellationPolicy.inAdvance.payToDrv',
      'commission'
    );
    let payToDrvNoShow = _.get(
      this.state.dialogData,
      'noShow.payToDrv',
      'commission'
    );
    const cancellationCashBooking = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.cancellationCashBooking',
      false
    );
    const payDrvCashType = _.get(
      dialogData,
      'cancellationPolicy.onDemand.payDrvCash.type',
      'commission'
    );
    const payDrvCashInAdvanceType = _.get(
      dialogData,
      'cancellationPolicy.inAdvance.payDrvCash.type',
      'commission'
    );
    const payDrvCashNoShowType = _.get(
      dialogData,
      'noShow.payDrvCash.type',
      'commission'
    );

    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="regularSetting.DELETE_HOURLY_RATE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="regularSetting.Delete_confirm_hour_rate" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteUser}
              >
                <Translate value="regularSetting.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showConfirmPackage ? (
          <Modal onHide={this.closeDialogPackageForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="regularSetting.DELETE_HOURLY_PACKAGE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogPackageForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="regularSetting.DELETE_PACKAGE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeletePackage}
              >
                <Translate value="regularSetting.Yes" />
              </Button>
              <Button
                className={'btn-cancel'}
                onClick={this.closeDialogPackageForm}
              >
                <Translate value="regularSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showDialog && this.state.dialogData && (
          <Modal
            show={true}
            backdrop={true}
            dialogClassName={
              this.state.dialogData._id ? 'fit-content-modal' : ''
            }
            onHide={this.closeDialogForm}
          >
            <Modal.Header className={'pd-r-l-60'}>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="regularSetting.EDIT_HOURLY_RATE" />
                  ) : (
                    <Translate value="regularSetting.VIEW_HOURLY_RATE" />
                  )
                ) : (
                  <Translate value="regularSetting.ADD_HOURLY_RATE" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className={'pd-r-l-60'}>
              <Row>
                <Col
                  md={this.state.dialogData._id ? 4 : 12}
                  xs={this.state.dialogData._id ? 4 : 12}
                >
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.name === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Name" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      onChange={(e) => {
                        this.handleInputChange('name', e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.name : ''
                      }
                      placeholder={I18n.t('regularSetting.Name')}
                      disabled={!this.state.editable}
                    />
                    <Validator
                      id="name"
                      callback={this.ValidatorCallback}
                      disabled={!this.state.editable}
                    >
                      <ValidCase
                        hide={!this.state.isModelSubmited}
                        valid={this.state.dialogData.name.trim().length > 0}
                        message={I18n.t(
                          'messages.commonMessages.Required_field'
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <div className={'vs-20'} />
                  <ExtraWaitTime
                    handleInputChange={this.handleInputChange}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    dialogData={this.state.dialogData}
                    auth={this.props.auth}
                    editable={this.state.editable}
                    ValidatorCallback={this.ValidatorCallback}
                    disabled={!this.state.editable}
                  />
                  <CcCheckbox
                    disabled={!this.state.editable}
                    checked={
                      this.state.dialogData.cancellationPolicy.onDemand.isActive
                    }
                    onChange={(e) => {
                      this.handleCancelInputChange('onDemand_isActive', e);
                    }}
                    text={I18n.t('regularSetting.On_demand')}
                    labelClassName="title"
                    className="form-group-title price_adjustment"
                  />
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.onDemand_valueByCurrencies === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={
                          this.state.dialogData.cancellationPolicy.onDemand.type
                        }
                        onChange={(e) => {
                          this.handleInputChange(
                            'onDemand_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Ondemand"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue = this.state.dialogData
                              .cancellationPolicy.onDemand.valueByCurrencies
                              ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                  (d) => {
                                    return d.currencyISO == c.iso;
                                  }
                                )[0]
                              : null;
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                    >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData
                                          .cancellationPolicy.onDemand.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'onDemand',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemand',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemand',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData ||
                            !this.state.dialogData.cancellationPolicy ||
                            !this.state.dialogData.cancellationPolicy
                              .onDemand ||
                            !this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies ||
                            !this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies.length
                              ? null
                              : this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.cancellationPolicy
                                  .onDemand.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'onDemand',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemand',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemand',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData.cancellationPolicy
                                  .onDemand.valueByCurrencies &&
                                this.state.dialogData.cancellationPolicy
                                  .onDemand.valueByCurrencies.length > 0
                                  ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0]
                                    ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          value={
                            this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies &&
                            this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies.length > 0
                              ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                )[0]
                                ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          disabled={!this.state.editable}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="onDemand_valueByCurrencies"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                            (d) => {
                              return (
                                !Validation.isStringEmpty(d.value) &&
                                isNaN(d.value)
                              );
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                            (d) => {
                              return !Validation.isGreaterOrEqual(d.value, 0);
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.cancellationPolicy.onDemand &&
                          this.state.dialogData.cancellationPolicy.onDemand
                            .enableTax
                            ? this.state.dialogData.cancellationPolicy.onDemand
                                .enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('onDemand_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.cancellationPolicy.onDemand
                              ? this.state.dialogData.cancellationPolicy
                                  .onDemand.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange('onDemand_techFee', e);
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={
                        this.state.dialogData.cancellationPolicy.onDemand
                          .payToDrv
                      }
                      onChange={(e) => {
                        this.handleInputChange('onDemand_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvOnDemand == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.onDemandPayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-onDemandPayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData
                                  .cancellationPolicy.onDemand
                                  ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                  className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                    >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                      >
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemandPayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'onDemandPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'onDemandPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.cancellationPolicy
                                .onDemand ||
                              !this.state.dialogData.cancellationPolicy.onDemand
                                .drvGetAmtByCurrencies ||
                              !this.state.dialogData.cancellationPolicy.onDemand
                                .drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemandPayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'onDemandPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'onDemandPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.cancellationPolicy.onDemand ? (
                        <Validator
                          id="onDemandPayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('onDemand', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange(
                                    'onDemand',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'onDemand',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'onDemand',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `cancellationPolicy.onDemand.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `cancellationPolicy.onDemand.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <CcCheckbox
                    disabled={!this.state.editable}
                    checked={
                      this.state.dialogData.cancellationPolicy.inAdvance
                        .isActive
                    }
                    onChange={(e) => {
                      this.handleCancelInputChange('inAdvance_isActive', e);
                    }}
                    text={I18n.t('regularSetting.In_advance')}
                    labelClassName="title"
                    className="form-group-title price_adjustment"
                  />
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.inAdvance_valueByCurrencies === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={
                          this.state.dialogData.cancellationPolicy.inAdvance
                            .type
                        }
                        onChange={(e) => {
                          this.handleInputChange(
                            'inAdvance_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tab-Inadvance"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue = this.state.dialogData
                              .cancellationPolicy.inAdvance.valueByCurrencies
                              ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                  (d) => {
                                    return d.currencyISO == c.iso;
                                  }
                                )[0]
                              : null;
                            return (
                              <Tab
                              className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData
                                          .cancellationPolicy.inAdvance.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'inAdvance',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvance',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvance',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData ||
                            !this.state.dialogData.cancellationPolicy ||
                            !this.state.dialogData.cancellationPolicy
                              .inAdvance ||
                            !this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies ||
                            !this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies.length
                              ? null
                              : this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.cancellationPolicy
                                  .inAdvance.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'inAdvance',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvance',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvance',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData.cancellationPolicy
                                  .inAdvance.valueByCurrencies &&
                                this.state.dialogData.cancellationPolicy
                                  .inAdvance.valueByCurrencies.length > 0
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0]
                                    ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          value={
                            this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies &&
                            this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies.length > 0
                              ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                )[0]
                                ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          disabled={!this.state.editable}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="inAdvance_valueByCurrencies"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                            (d) => {
                              return (
                                !Validation.isStringEmpty(d.value) &&
                                isNaN(d.value)
                              );
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                            (d) => {
                              return !Validation.isGreaterOrEqual(d.value, 0);
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.cancellationPolicy.inAdvance &&
                          this.state.dialogData.cancellationPolicy.inAdvance
                            .enableTax
                            ? this.state.dialogData.cancellationPolicy.inAdvance
                                .enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('inAdvance_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.cancellationPolicy.inAdvance
                              ? this.state.dialogData.cancellationPolicy
                                  .inAdvance.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange(
                              'inAdvance_techFee',
                              e
                            );
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={
                        this.state.dialogData.cancellationPolicy.inAdvance
                          .payToDrv
                      }
                      onChange={(e) => {
                        this.handleInputChange('inAdvance_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvInAdvance == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.inAdvancePayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-inAdvancePayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData
                                  .cancellationPolicy.inAdvance
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                    >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvancePayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'inAdvancePayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'inAdvancePayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance ||
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance.drvGetAmtByCurrencies ||
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance.drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                              >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvancePayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'inAdvancePayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'inAdvancePayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.cancellationPolicy.inAdvance ? (
                        <Validator
                          id="inAdvancePayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashInAdvanceType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('inAdvance', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashInAdvanceType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                              >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange(
                                    'inAdvance',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'inAdvance',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'inAdvance',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `cancellationPolicy.inAdvance.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `cancellationPolicy.inAdvance.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={'vs-20'} />
                  <FormGroupTitle>
                    <Translate value="regularSetting.PAX_MDISPATCHER_NO_SHOW" />
                    <QuestionCircleTooltip
                      text={
                        <Translate value="regularSetting.Pax_mDispatcher_no_show_tooltip" />
                      }
                    />
                  </FormGroupTitle>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.noShow_valueByCurrencies === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={this.state.dialogData.noShow.type}
                        onChange={(e) => {
                          this.handleInputChange(
                            'noShow_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Ondemand"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue = this.state.dialogData.noShow
                              .valueByCurrencies
                              ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                  (d) => {
                                    return d.currencyISO == c.iso;
                                  }
                                )[0]
                              : null;
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                                >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData.noShow.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'noShow',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.noShow ||
                            !this.state.dialogData.noShow.valueByCurrencies ||
                            !this.state.dialogData.noShow.valueByCurrencies
                              .length
                              ? null
                              : this.state.dialogData.noShow.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.noShow.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'noShow',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData.noShow
                                  .valueByCurrencies &&
                                this.state.dialogData.noShow.valueByCurrencies
                                  .length > 0
                                  ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0]
                                    ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              placeholder={I18n.t('regularSetting.Penalty')}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          value={
                            this.state.dialogData.noShow.valueByCurrencies &&
                            this.state.dialogData.noShow.valueByCurrencies
                              .length > 0
                              ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                )[0]
                                ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          placeholder={I18n.t('regularSetting.Penalty')}
                          disabled={!this.state.editable}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="noShow_valueByCurrencies"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.noShow.valueByCurrencies.filter(
                            (d) => {
                              return (
                                !Validation.isStringEmpty(d.value) &&
                                isNaN(d.value)
                              );
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.noShow.valueByCurrencies.filter(
                            (d) => {
                              return !Validation.isGreaterOrEqual(d.value, 0);
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.noShow &&
                          this.state.dialogData.noShow.enableTax
                            ? this.state.dialogData.noShow.enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('noShow_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.noShow
                              ? this.state.dialogData.noShow.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange('noShow_techFee', e);
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={this.state.dialogData.noShow.payToDrv}
                      onChange={(e) => {
                        this.handleInputChange('noShow_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvNoShow == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.noShowPayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-noShowPayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData.noShow
                                  ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                        >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShowPayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'noShowPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'noShowPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.noShow ||
                              !this.state.dialogData.noShow
                                .drvGetAmtByCurrencies ||
                              !this.state.dialogData.noShow
                                .drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.noShow.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShowPayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'noShowPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'noShowPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.noShow ? (
                        <Validator
                          id="noShowPayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashNoShowType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('noShow', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashNoShowType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet.currencies[0]
                                        .symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>{' '}
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange('noShow', e);
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'noShow',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'noShow',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `noShow.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `noShow.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  {enablePenalty && (
                    <>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.onDemand.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'onDemand',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_demand')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.onDemand.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'onDemand',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {_.get(
                                  dialogData,
                                  'driverCancelPolicy.onDemand.type',
                                  'amount'
                                ) === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'onDemand',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.onDemand.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className={'vs-20'} />
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.inAdvance.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'inAdvance',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_advance')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.inAdvance.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'inAdvance',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {_.get(
                                  dialogData,
                                  'driverCancelPolicy.inAdvance.type',
                                  'amount'
                                ) === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'inAdvance',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.inAdvance.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </>
                  )}
                  {this.state.dialogData._id ? null : (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="regularSetting.Assign_car_type" />
                      </Form.Label>
                      <select
                        className={'form-control form-custom'}
                        onChange={(e) => {
                          this.handleInputChange('carType', e);
                        }}
                      >
                        <option value="">
                          {I18n.t('regularSetting.Select_car_type')}
                        </option>
                        {this.props.commonData.carTypeCity.map((item) => {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.vehicleType}
                            </option>
                          );
                        })}
                      </select>
                    </FormGroup>
                  )}
                </Col>
                {this.state.dialogData._id ? (
                  <Col md={8} xs={8} className={'pd-l-60'}>
                    <FormGroupTitle>
                      <Translate value="regularSetting.HOURLY_PACKAGE_RATE" />
                    </FormGroupTitle>
                    <ButtonToolbar
                      className="text-center header-button-group"
                      ref={(node) => (this.toobarModalContainer = node)}
                    >
                      <ButtonGroup className="group-left">
                        <FormGroup className="search-format mb0">
                          <input
                            type="text"
                            className="form-control search-form"
                            value={this.state.packageStr}
                            onKeyPress={this.handleSearchInPackageKeyPress}
                            onChange={this.handleSearchInPackageChange}
                            placeholder={I18n.t('General.search')}
                          />
                          <BsSearch className="search-icon" />
                        </FormGroup>
                        {!this.props.permissions ||
                        this.props.permissions.actions ? (
                          <Button
                            className="btn-header text-add-header ml0"
                            onClick={this.handleAddPackageButtonClick}
                          >
                            {' '}
                            <Translate value="regularSetting.Add" />
                          </Button>
                        ) : null}
                      </ButtonGroup>
                    </ButtonToolbar>
                    <div className="gridViewTable" id="hourly-table-id">
                      <StickyTable
                        columns={this.getHourlyPackageTableColumns()}
                        bodyData={bodyHourlyPakageData}
                        footerData={this.state.footerHourlyPakageData}
                        handleNumItemsPerPageChange={
                          this.handleNumItemsPerPageChange
                        }
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.packageRowHeight}
                        getTableHeight={() => 500}
                      />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Row className="ml0">
                {this.state.dialogData._id ? (
                  <Form.Label className="mr-r-20">
                    (<span className="require">*</span>)
                    <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
                  </Form.Label>
                ) : null}
              </Row>
              <Row className="ml0">
                <Form.Label>
                  (<span className="require">*</span>
                  ): <Translate value="regularSetting.Required_fields" />
                </Form.Label>
              </Row>
            </Modal.Body>
            <Modal.Footer className={'pd-r-l-60'}>
              {hasPermission ? (
                this.state.editable ? (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={this.saveDialogForm}
                    disabled={!this.state.dialogData.name.trim()}
                  >
                    <Translate value="regularSetting.Save" />
                  </Button>
                ) : (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={(e) => {
                      this.handleMenuClick('changeStatus', e);
                    }}
                  >
                    <Translate value="regularSetting.Edit" />
                  </Button>
                )
              ) : (
                ''
              )}
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.isEditPackageModel && this.state.packageModel && (
          <Modal onHide={this.closePackageModelForm} show={true} backdrop={true}>
            <form onSubmit={this.savePackageModelClick}>
              <Modal.Header className={'pd-r-l-60'}>
                <Modal.Title>
                  {this.state.packageModel._id ? (
                    <Translate value="regularSetting.EDIT_PACKAGE" />
                  ) : (
                    <Translate value="regularSetting.ADD_PACKAGE" />
                  )}
                </Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closePackageModelForm}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body className={'pd-r-l-60'}>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.name === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.PackageId" />{' '}
                  </Form.Label>
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    value={this.state.packageModel._id}
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.name === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Package_name" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={(e) => {
                      this.handlePacakgeInputChange('name', null, e);
                    }}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(e, (e) => {
                        this.handlePacakgeInputChange('name', null, e);
                      });
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(e, (e) => {
                        this.handlePacakgeInputChange('name', null, e);
                      });
                    }}
                    value={
                      this.state.packageModel
                        ? this.state.packageModel.name
                        : ''
                    }
                    placeholder={I18n.t('regularSetting.Package_name')}
                    disabled={!hasPermission || isDisablePackageModel}
                  />
                  <Validator id="name" callback={this.ValidatorModelCallback}>
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={
                        !Validation.isStringEmpty(this.state.packageModel.name)
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.duration === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Package_duration" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormGroup className={'qup-input-group'}>
                    <InputGroup className={'single-addon-right'}>
                      <FormControl
                        type="text"
                        className={'form-custom'}
                        onChange={(e) => {
                          this.handlePacakgeInputChange('duration', null, e);
                        }}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(e, (e) => {
                            this.handlePacakgeInputChange('duration', null, e);
                          });
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(e, (e) => {
                            this.handlePacakgeInputChange('duration', null, e);
                          });
                        }}
                        value={
                          this.state.packageModel
                            ? this.state.packageModel.duration
                            : ''
                        }
                        placeholder={I18n.t('regularSetting.Package_duration')}
                        disabled={!hasPermission || isDisablePackageModel}
                      />
                      <FormControl
                        as="select"
                        className="form-custom input-group-addon-custom"
                        value={this.state.packageModel.type}
                        onChange={(e) => {
                          this.handlePacakgeInputChange('type', null, e);
                        }}
                        disabled={!hasPermission}
                      >
                        <option value={'hour'}>
                          {I18n.t('regularSetting.hour')}
                        </option>
                        <option value={'day'}>
                          {I18n.t('regularSetting.day')}
                        </option>
                      </FormControl>
                    </InputGroup>
                  </FormGroup>
                  <Validator
                    id="duration"
                    callback={this.ValidatorModelCallback}
                  >
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={this.state.packageModel.duration != ''}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={Validation.isInteger(
                        this.state.packageModel.duration
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={Validation.isNumber(
                        this.state.packageModel.duration
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_number'
                      ).format(0)}
                    />
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={Validation.isGreaterOrEqual(
                        this.state.packageModel.duration,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.basedFee === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Base_rate" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  {this.props.auth.selectedFleet.multiCurrencies ? (
                    <div className="input-with-validator">
                      <Tabs
                        id="currencies-tabs-basedFee"
                        className="currencies-tabs"
                        defaultActiveKey={
                          this.props.auth.selectedFleet.currencies[0].iso
                        }
                        animation={false}
                        onSelect={this.tabSelectHandle}
                      >
                        {this.props.auth.selectedFleet.currencies.map((c) => {
                          let currentValue = this.state.packageModel
                            .feesByCurrencies
                            ? this.state.packageModel.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0]
                            : null;
                          return (
                            <Tab
                              className="currencies-tab-item"
                              eventKey={c.iso}
                              title={c.iso}
                            >
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {c.symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handlePackageMultiCurrenciesChange(
                                        'feesByCurrencies',
                                        'basedFee',
                                        c.iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'basedFee',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'basedFee',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      currentValue
                                        ? currentValue.basedFee
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={
                                      !hasPermission || isDisablePackageModel
                                    }
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text>
                                      /
                                      <Translate value="regularSetting.Package_duration" />
                                    </InputGroup.Text>
                                  </InputGroup.Append>{' '}
                                </InputGroup>
                              </FormGroup>
                            </Tab>
                          );
                        })}
                      </Tabs>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={!hasPermission}
                        value={
                          !this.state.packageModel ||
                          !this.state.packageModel.feesByCurrencies ||
                          !this.state.packageModel.feesByCurrencies.length
                            ? null
                            : this.state.packageModel.feesByCurrencies.map(
                                (fees) => {
                                  const { currencyISO, basedFee } = fees;
                                  return {
                                    [currencyISO]: basedFee,
                                  };
                                }
                              )
                        }
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  ) : (
                    <div className="input-with-validator">
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            {
                              this.props.auth.selectedFleet.currencies[0]
                                .symbol
                            }
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={(e) => {
                              this.handlePackageMultiCurrenciesChange(
                                'feesByCurrencies',
                                'basedFee',
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'basedFee',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'basedFee',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            value={
                              this.state.packageModel.feesByCurrencies &&
                              this.state.packageModel.feesByCurrencies.length >
                                0
                                ? this.state.packageModel.feesByCurrencies[0]
                                    .basedFee
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission || isDisablePackageModel}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              /
                              <Translate value="regularSetting.Package_duration" />
                            </InputGroup.Text>
                          </InputGroup.Append>{' '}
                        </InputGroup>
                      </FormGroup>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={
                          this.state.packageModel.feesByCurrencies &&
                          this.state.packageModel.feesByCurrencies.length > 0
                            ? this.state.packageModel.feesByCurrencies[0]
                                .basedFee
                            : DefaultCurenciesNumberInputField
                        }
                        disabled={!hasPermission || isDisablePackageModel}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  )}
                  <Validator
                    id="basedFee"
                    callback={this.ValidatorModelCallback}
                  >
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return (
                            !Validation.isStringEmpty(d.basedFee) &&
                            isNaN(d.basedFee)
                          );
                        }).length == 0
                      }
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isModelSubmited}
                    />
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return !Validation.isGreaterOrEqual(d.basedFee, 0);
                        }).length == 0
                      }
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                      hide={!this.state.isModelSubmited}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.extraDuration === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Fee_per_extra_duration" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  {this.props.auth.selectedFleet.multiCurrencies ? (
                    <div className="input-with-validator">
                      <Tabs
                        id="currencies-tabs-departurePackageByCurrencies"
                        className="currencies-tabs"
                        defaultActiveKey={
                          this.props.auth.selectedFleet.currencies[0].iso
                        }
                        animation={false}
                        onSelect={this.tabSelectHandle}
                      >
                        {this.props.auth.selectedFleet.currencies.map((c) => {
                          let currentValue = this.state.packageModel
                            .feesByCurrencies
                            ? this.state.packageModel.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0]
                            : null;
                          return (
                            <Tab
                              className="currencies-tab-item"
                              eventKey={c.iso}
                              title={c.iso}
                            >
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {c.symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handlePackageMultiCurrenciesChange(
                                        'feesByCurrencies',
                                        'extraDuration',
                                        c.iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'extraDuration',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'extraDuration',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      currentValue
                                        ? currentValue.extraDuration
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={
                                      !hasPermission || isDisablePackageModel
                                    }
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text>
                                      /{this.state.packageModel.type}
                                    </InputGroup.Text>
                                  </InputGroup.Append>{' '}
                                </InputGroup>
                              </FormGroup>
                            </Tab>
                          );
                        })}
                      </Tabs>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={!hasPermission || isDisablePackageModel}
                        value={
                          !this.state.packageModel ||
                          !this.state.packageModel.feesByCurrencies ||
                          !this.state.packageModel.feesByCurrencies.length
                            ? null
                            : this.state.packageModel.feesByCurrencies.map(
                                (fees) => {
                                  const { currencyISO, extraDuration } = fees;
                                  return {
                                    [currencyISO]: extraDuration,
                                  };
                                }
                              )
                        }
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  ) : (
                    <div className="input-with-validator">
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup className="single-addon-right">
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={(e) => {
                              this.handlePackageMultiCurrenciesChange(
                                'feesByCurrencies',
                                'extraDuration',
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'extraDuration',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'extraDuration',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            value={
                              this.state.packageModel.feesByCurrencies &&
                              this.state.packageModel.feesByCurrencies.length >
                                0
                                ? this.state.packageModel.feesByCurrencies[0]
                                    .extraDuration
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission || isDisablePackageModel}
                          />
                          {this.state.packageModel.type === 'day' ? (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                /{this.state.packageModel.type}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          ) : (
                            <FormControl
                              as="select"
                              className="form-custom input-group-addon-custom"
                              value={this.state.packageModel.extraDurationType}
                              onChange={(e) => {
                                this.handlePacakgeInputChange(
                                  'extraDurationType',
                                  null,
                                  e
                                );
                              }}
                              disabled={!hasPermission || isDisablePackageModel}
                            >
                              <option value={'hour'}>
                                /{I18n.t('regularSetting.hour')}
                              </option>
                              <option value={'minute'}>
                                /{I18n.t('regularSetting.minute')}
                              </option>
                            </FormControl>
                          )}
                        </InputGroup>
                      </FormGroup>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={
                          this.state.packageModel.feesByCurrencies &&
                          this.state.packageModel.feesByCurrencies.length > 0
                            ? this.state.packageModel.feesByCurrencies[0]
                                .extraDuration
                            : DefaultCurenciesNumberInputField
                        }
                        disabled={!hasPermission || isDisablePackageModel}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  )}
                  <Validator
                    id="extraDuration"
                    callback={this.ValidatorModelCallback}
                  >
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return (
                            !Validation.isStringEmpty(d.extraDuration) &&
                            isNaN(d.extraDuration)
                          );
                        }).length == 0
                      }
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isModelSubmited}
                    />
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return !Validation.isGreaterOrEqual(
                            d.extraDuration,
                            0
                          );
                        }).length == 0
                      }
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                      hide={!this.state.isModelSubmited}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.coveredDistance === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Distance_coverage" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormGroup className={'qup-input-group'}>
                    <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {this.props.auth.selectedFleet.unitDistance}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        className={'form-custom'}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(e, (e) => {
                            this.handlePacakgeInputChange(
                              'coveredDistance',
                              null,
                              e
                            );
                          });
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(e, (e) => {
                            this.handlePacakgeInputChange(
                              'coveredDistance',
                              null,
                              e
                            );
                          });
                        }}
                        onChange={(e) => {
                          this.handlePacakgeInputChange(
                            'coveredDistance',
                            null,
                            e
                          );
                        }}
                        value={
                          this.state.packageModel
                            ? this.state.packageModel.coveredDistance
                            : ''
                        }
                        placeholder={I18n.t('regularSetting.Distance_coverage')}
                        disabled={!hasPermission || isDisablePackageModel}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>
                          /<Translate value="regularSetting.Package_duration" />
                        </InputGroup.Text>
                      </InputGroup.Append>{' '}
                    </InputGroup>
                  </FormGroup>
                  <Validator
                    id="coveredDistance"
                    callback={this.ValidatorModelCallback}
                  >
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={this.state.packageModel.coveredDistance != ''}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={Validation.isNumber(
                        this.state.packageModel.coveredDistance
                      )}
                      message={I18n.t(
                        'messages.commonMessages.must_be_number'
                      ).format(0)}
                    />
                    <ValidCase
                      hide={!this.state.isModelSubmited}
                      valid={Validation.isGreaterOrEqual(
                        this.state.packageModel.coveredDistance,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup
                  className={
                    !this.state.isModelSubmited
                      ? null
                      : this.state.modelValid.extraDistance === false
                      ? 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Fee_per_extra_distance" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  {this.props.auth.selectedFleet.multiCurrencies ? (
                    <div className="input-with-validator">
                      <Tabs
                        id="currencies-tabs-extraDistance"
                        className="currencies-tabs"
                        defaultActiveKey={
                          this.props.auth.selectedFleet.currencies[0].iso
                        }
                        animation={false}
                        onSelect={this.tabSelectHandle}
                      >
                        {this.props.auth.selectedFleet.currencies.map((c) => {
                          let currentValue = this.state.packageModel
                            .feesByCurrencies
                            ? this.state.packageModel.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0]
                            : null;
                          return (
                            <Tab
                            className="currencies-tab-item"
                              eventKey={c.iso}
                              title={c.iso}
                            >
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {c.symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'extraDistance',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handlePackageMultiCurrenciesChange(
                                            'feesByCurrencies',
                                            'extraDistance',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onChange={(e) => {
                                      this.handlePackageMultiCurrenciesChange(
                                        'feesByCurrencies',
                                        'extraDistance',
                                        c.iso,
                                        e
                                      );
                                    }}
                                    value={
                                      currentValue
                                        ? currentValue.extraDistance
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={
                                      !hasPermission || isDisablePackageModel
                                    }
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text>
                                      /
                                      {
                                        this.props.auth.selectedFleet
                                          .unitDistance
                                      }
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </FormGroup>
                            </Tab>
                          );
                        })}
                      </Tabs>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={!hasPermission || isDisablePackageModel}
                        value={
                          !this.state.packageModel ||
                          !this.state.packageModel.feesByCurrencies ||
                          !this.state.packageModel.feesByCurrencies.length
                            ? null
                            : this.state.packageModel.feesByCurrencies.map(
                                (fees) => {
                                  const { currencyISO, extraDistance } = fees;
                                  return {
                                    [currencyISO]: extraDistance,
                                  };
                                }
                              )
                        }
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  ) : (
                    <div className="input-with-validator">
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {
                                this.props.auth.selectedFleet.currencies[0]
                                  .symbol
                              }
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={(e) => {
                              this.handlePackageMultiCurrenciesChange(
                                'feesByCurrencies',
                                'extraDistance',
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'extraDistance',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.handlePackageMultiCurrenciesChange(
                                  'feesByCurrencies',
                                  'extraDistance',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            value={
                              this.state.packageModel.feesByCurrencies &&
                              this.state.packageModel.feesByCurrencies.length >
                                0
                                ? this.state.packageModel.feesByCurrencies[0]
                                    .extraDistance
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission || isDisablePackageModel}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              /{this.props.auth.selectedFleet.unitDistance}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </FormGroup>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={
                          this.state.packageModel.feesByCurrencies &&
                          this.state.packageModel.feesByCurrencies.length > 0
                            ? this.state.packageModel.feesByCurrencies[0]
                                .extraDistance
                            : DefaultCurenciesNumberInputField
                        }
                        disabled={!hasPermission || isDisablePackageModel}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  )}
                  <Validator
                    id="extraDistance"
                    callback={this.ValidatorModelCallback}
                  >
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return (
                            !Validation.isStringEmpty(d.extraDistance) &&
                            isNaN(d.extraDistance)
                          );
                        }).length == 0
                      }
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!this.state.isModelSubmited}
                    />
                    <ValidCase
                      valid={
                        this.state.packageModel.feesByCurrencies.filter((d) => {
                          return !Validation.isGreaterOrEqual(
                            d.extraDistance,
                            0
                          );
                        }).length == 0
                      }
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                      hide={!this.state.isModelSubmited}
                    />
                  </Validator>
                </FormGroup>
              </Modal.Body>
              <Modal.Footer className={'pd-r-l-60'}>
                {hasPermission && !isDisablePackageModel ? (
                  <Button className={'btn-save mr-md'} type="submit">
                    <Translate value="regularSetting.Save" />
                  </Button>
                ) : null}
                <Button
                  className={'btn-cancel'}
                  onClick={this.closePackageModelForm}
                >
                  <Translate value="regularSetting.Cancel" />
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {hasPermission && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="regularSetting.Add" />
              </Button>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

HourlySetting.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HourlySetting);
