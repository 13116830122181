import React, { useState, useEffect } from 'react';
import {
    FormGroup,
    Button,
    Modal,
    ButtonToolbar,
    ButtonGroup,
} from "react-bootstrap";
import { I18n, Translate } from "react-redux-i18n";
import _ from 'lodash';

import StickyTable from '../../../components/table/stickyTable/StickyTable';
import { FilterFavoriteColumns } from './tableHeaderColumn';
import Confirm from '../../../components/confirm/Confirm';
import { BsSearch } from 'react-icons/bs';


export default function SettingsFavoriteFilterModal(props) {
    const {
        filterTemplates = [],
        handleClose,
        handleDelete
    } = props;
    const [str, setStr] = useState('');
    const [confirm, setConfirm] = useState({});
    const [listFilterTemplates, setListFilterTemplates] = useState([]);

    useEffect(() => {
        setListFilterTemplates(filterTemplates);
    }, filterTemplates)

    const handleSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            setListFilterTemplates(filterTemplates.filter(e => e.name.toLowerCase().indexOf(str.toLowerCase()) > -1))
        }
    };

    const handleDeleteClick = (item) => {
        setConfirm({
            id: item._id,
            title: I18n.t('cue.Delete_Filter'),
            body: I18n.t('cue.CONFIRM_DELETE_FILTER_TEMPLATE'),
            buttonTitle: 'Yes',
            closeButtonText: 'No'
        })
    };

    const handleConfirmCloseClick = () => {
        setConfirm({});
    };

    const handleConfirmButtonClick = (id) => {
        setConfirm({});
        handleDelete(id);
    };

    const getTableColumns = () => {
        let tableColumns = Object.assign([], FilterFavoriteColumns);
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'name':
                    col.customCell = (data, index) => {
                        return (
                            <span>
                                {data.name
                                    ? data.name
                                    : ''}
                            </span>
                        );
                    };
                    break;
                case 'actions':
                    col.customCell = (item, index) => {
                        return (
                            <div>
                                <i
                                    className="fa fa-trash fa-2x"
                                    onClick={e => handleDeleteClick(item)}
                                />
                            </div>
                        );
                    };
                    break;
                default:
                    break;
            }
        });
        return tableColumns;
    }

    return (
        <>
            <Modal
                show
                size="sm"
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Translate value="cue.list_favorite_filter" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ButtonToolbar
                        className="text-center header-button-group"
                    >
                        <FormGroup className="search-format mb0">
                            <input
                                type="text"
                                className="form-control search-form"
                                value={str}
                                onKeyPress={handleSearchKeyPress}
                                onChange={e => setStr(e.target.value)}
                                placeholder={I18n.t("General.search")}
                            />
                            <BsSearch className="search-icon" />
                        </FormGroup>
                    </ButtonToolbar>

                    <div className="gridViewTable favorite_filter">
                        <StickyTable
                            columns={getTableColumns()}
                            bodyData={listFilterTemplates}
                            rowHeight={50}
                            getTableHeight={() => 500}
                            noPagination={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-save ml0"
                        onClick={handleClose}
                    >
                        <Translate value="cue.Close" />
                    </Button>
                </Modal.Footer>
            </Modal>
            {confirm.id &&
                <Confirm
                    confirm={confirm}
                    handleConfirmButtonClick={handleConfirmButtonClick}
                    handleConfirmCloseClick={handleConfirmCloseClick}
                />
            }
        </>
    )
}
