import React, { useState, useEffect } from 'react';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import { Translate, I18n } from 'react-redux-i18n';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import './style.scss'

const reorder = (list, startIndex, endIndex) => {
  //start reorder
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const COLUMN_FLIGHT = ['flightStatus', 'actual', 'scheduled'];
const COLUMN_FIXED = ['bookId', 'externalInfo.bookingReference'];

const SortableGrid = SortableContainer(
  ({ items, valueKey, selectedItems, onSelect }) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 50%)',
          overflow: 'hidden',
        }}
      >
        {items.map((item, index) => {
          return (
            <SortableGridItem
              key={`item-${index}`}
              number={index}
              index={index}
              item={item}
              disabled={COLUMN_FIXED.includes(item.key)}
              valueKey={valueKey}
              selectedItems={selectedItems}
              onSelect={onSelect}
              shouldCancelStart={() => window.innerWidth < 768}
              pressDelay={300}
            />
          );
        })}
      </div>
    );
  }
);

const SortableGridItem = SortableElement(
  ({ item, valueKey, selectedItems, onSelect, number }) => {
    const handleCheckboxChange = () => {
      onSelect(item[valueKey]);
    };
    const isChecked = selectedItems?.indexOf(item[valueKey]) !== -1;
    return (
      <div
        className="rearrangeCueAction"
        style={{
          padding: '5px 10px',
          cursor: 'grab',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span className="numberItem">{number + 1}</span>
        <CcCheckbox
          checked={isChecked}
          value="all"
          text={I18n.t(item.label)}
          onChange={handleCheckboxChange}
          className="dropdown-selectbox cb-cueAction text-ellipsis mr-l-5"
          labelClassName="text"
          disabled={item.disableSelect}
        />
        {COLUMN_FLIGHT.includes(item.key) && (
          <i className={'fa fa-solid fa-plane mr-l-5 '} />
        )}
      </div>
    );
  }
);

const RearrangeColumn = ({
  column = [],
  isShowModal = false,
  closeRearrangeModal,
  handleSaveRearrangeColumn = () => {},
  ...props
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (COLUMN_FIXED.includes(column[newIndex]?.key)) return;
    const newItems = reorder(column, oldIndex, newIndex);
    handleSaveRearrangeColumn(newItems);
  };

  return (
    <SortableGrid
      items={column}
      onSortEnd={onSortEnd}
      axis="xy"
      valueKey={props.valueKey}
      selectedItems={props.selectedItems}
      onSelect={props.onSelect}
    />
  );
};

export default RearrangeColumn;
