export const CityColumns = [
  {
    key: "Name",
    label: "city.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "link",
    label: "jobs.Link",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "actions",
    label: "city.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
