import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import * as settingActions from '../../../actions/settingActions';
import _ from 'lodash';
import '../settings.scss';
import Layout from './components/layout/layout';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import PropTypes from 'prop-types';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';

class Social extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      pax: null,
      driver: null,
      filesUpload: {},
      filesUploadResult: {}
    };
  }

  componentDidMount() {
    this.props.settingActions
      .getSocialSetting({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.res) {
          this.setState({
            id: data.res._id,
            pax: data.res.pax,
            driver: data.res.driver,
            filesUpload: {
              logoDriverIOS: data.res.logoDriverIOS,
              logoDriverAndroid: data.res.logoDriverAndroid,
            },
            filesUploadResult: {
              logoDriverIOS: data.res.logoDriverIOS,
              logoDriverAndroid: data.res.logoDriverAndroid,
            }
          });
        }
      });
  }

  handleChangeForm = (appType, value) => {
    const defaultValue = { ...this.state[appType] };
    defaultValue.contentFrom = value;
    this.setState({
      [appType]: defaultValue,
    });
  };

  handleChangeInputForm = (appType, key, value) => {
    const defaultValue = { ...this.state[appType] };
    defaultValue[key] = value;
    this.setState({
      [appType]: defaultValue,
    });
  };

  handleCheckEnablePhoneForm = (appType, value) => {
    const defaultValue = { ...this.state[appType] };
    defaultValue.enablePhone = value;
    this.setState({
      [appType]: defaultValue,
    });
  };

  handleImageChange = (key, data) => {
    let result = "",
    file = null
    if (data) {
      result = data.result;
      file = data.file;
    } 
    this.state.filesUpload[key] = result;
    this.state.filesUploadResult[key] = file;
    this.setState(this.state);
  }

  handleHtmlChange = (appType, key, data) => {
    const defaultValue = { ...this.state[appType] };

    let result = "",
      file = null

    if (data) {
      result = data.result;
      file = data.file;
    }
    defaultValue[key] = file;
    this.setState({
      [appType]: defaultValue,
    });
  }

  hanldeSaveSocialForm = (typeApp, valid) => {
    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return;
    }
    let objectUpdate = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      update: typeApp === 'driver' ? this.state.driver : this.state.pax,
      id: this.state.id,
      logoDriverAndroid: typeApp === 'driver' && this.state.filesUpload.logoDriverAndroid,
      logoDriverIOS: typeApp === 'driver' && this.state.filesUpload.logoDriverIOS,
      typeApp: typeApp
    };
    const data = new FormData();
    data.append("update", JSON.stringify(objectUpdate));
    data.append("file", Object(typeApp === 'driver' ? this.state.driver : this.state.pax).htmlUrl);

    this.props.settingActions
    .updateSocialSetting(data)
    .then((data) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (!data.ok) {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('social.Update_fail')
          );
        }
      } else {
        this.context.notification(
          'success',
          I18n.t('social.Update_success')
        );
        setTimeout(function () {
          window.location.reload();
        }, 1 * 1000);
      }
    });
  }


  render() {
    const { driver, pax, filesUpload, filesUploadResult } =
      this.state;
    return (
      <div className="content custom-form overflow-auto general-setting">
        <Tabs
          id="tab"
          defaultActiveKey={0}
          animation={false}
          className="cue-tabs"
        >
          {['pax', 'driver'].map((item, index) => (
            <Tab
              eventKey={index}
              key={index}
              title={I18n.t(`social.${item}_app`)}
            >
              {driver && pax && (
                <Layout
                  data={item === 'driver' ? driver : pax}
                  appType={item}
                  handleChangeForm={this.handleChangeForm}
                  handleCheckEnablePhoneForm={this.handleCheckEnablePhoneForm}
                  handleChangeInputForm={this.handleChangeInputForm}
                  handleImageChange={this.handleImageChange}
                  filesUpload={filesUpload}
                  filesUploadResult={filesUploadResult}
                  handleHtmlChange={this.handleHtmlChange}
                  hanldeSaveSocialForm={this.hanldeSaveSocialForm}
                  permissions={this.props.permissions}
                />
              )}
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  }
}

Social.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Social);
