import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

function TimeoutIcon({ data, lineShort, status }) {
  if (!data.timeout) {
    return null;
  }

  if (
    !['action', 'confirmed'].includes(status) &&
    status !== 'pending' &&
    !data.marketplace
  ) {
    return null;
  }

  //The order delivery
  if (data.delivery && data.drvInfo && data.drvInfo.userId) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="top"
      delayShow={300}
      overlay={
        <Tooltip id="tooltip">
          <strong>
            <Translate value={'cue.Time_out'} />
          </strong>
        </Tooltip>
      }
    >
      <>{!lineShort && <div className={`booking-timeout`} />}</>
    </OverlayTrigger>
  );
}

export const StatusText = ({ data, status }) => {
  if (!status) return '';
  if (
    (data.jobType === 'food' || data.jobType === 'mart') &&
    status === 'collecting'
  ) {
    status = 'arrivedAndWaitingToCollectItem';
  }
  return (
    <>
      {data.delivery && status === 'confirmed' ? (
        <Translate value={`statusDisplay.confirmed_delivery`} />
      ) : data.delivery === false && status === 'pre' ? (
        <Translate value={`statusDisplay.pending`} />
      ) : data.corporateInfo &&
        data.corporateInfo.isAirline === true &&
        (status === 'canceledByCorpAd' ||
          status === 'canceledByCorporateAdmin') ? (
        <Translate value={`statusDisplay.canceledByAirlineAdmin`} />
      ) : (
        <Translate value={`statusDisplay.${status}`} />
      )}
    </>
  );
};

// use for mobile
export const StatusColumnMobile = ({ booking = {}, color, transformBooking }) => {
  let status = transformBooking.status
  return (
    <span
      style={{
        color: color,
        fontWeight: 500,
      }}
    >
      <StatusText 
        data={booking}
        status={status}
      />
    </span>
  );
};

// use for Desktop
export default function StatusColumn({ data, selectedTab, lineShort, transformBooking }) {
  let status = transformBooking.status
  if (
    (data.jobType === 'food' || data.jobType === 'mart') &&
    status === 'collecting'
  ) {
    status = 'arrivedAndWaitingToCollectItem';
  }
  return (
    <div>
      <p>
        <StatusText data={data} status={status}/>
      </p>
      {selectedTab == 0 ? (
        <TimeoutIcon data={data} lineShort={lineShort} status={status}/>
      ) : !lineShort ? (
        <div
          className={
            data.timeout && !_.get(data, 'drvInfo.userId')
              ? 'booking-timeout'
              : ''
          }
        />
      ) : (
        ''
      )}
    </div>
  );
}
