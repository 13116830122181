import React, { useState, Fragment } from 'react';
import {
  ButtonToolbar,
  Button,
  Form,
  FormGroup,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import AddEditScheduleModal from './AddEditScheduleModal';
import { ObjectId, parStart } from '../../../../../utils/commonFunctions';

export default function RouteSchedules({ schedules, permissions, onChange }) {
  const [showDialog, setShowDialog] = useState(false);
  const [scheduleEdit, setScheduleEdit] = useState(null);

  const handleEditSchedule = (schedule, index) => {
    setShowDialog(true);
    setScheduleEdit(schedule);
  };
  const handleDeleteSchedule = (schedule, index) => {
    onChange(schedules.filter(s => s._id !== schedule._id))
  };
  const handleAddSchedule = () => {
    setShowDialog(true);
    setScheduleEdit({});
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setScheduleEdit(null);
  };

  const handleSubmitScheduleForm = (schedule, applyOtherDays) => {
    const foundIndex = _.findIndex(schedules, o => o._id === schedule._id);
    if(foundIndex === -1) {
      schedules.push(schedule);
    } else {
      _.set(schedules, foundIndex, schedule);
    }
    _.forEach(applyOtherDays, day => {
      schedules.push({
        _id: ObjectId(),
        dayOfWeek: day,
        times: schedule.times
      })
    })
    setShowDialog(false);
    setScheduleEdit(null);
    onChange([...schedules])
  }

  return (
    <Fragment>
      <table className="table table-no-pagination">
        <tbody>
          {schedules.map((schedule, index) => {
            return (
              <tr key={schedule.dayOfWeek}>
                <td>{I18n.t(`dayOfWeek.${schedule.dayOfWeek}`)}</td>
                <td>
                  {schedule.times
                    .map((time) => `${parStart(time.hour)}:${parStart(time.minute)}`)
                    .join(', ')}
                </td>
                <td className="actions">
                  {!permissions || permissions.actions ? (
                    <div>
                      <a
                        onClick={() => {
                          handleDeleteSchedule(schedule, index);
                        }}
                      >
                        <i className="fa fa-trash fa-2x" />
                      </a>
                      <a
                        onClick={() => {
                          handleEditSchedule(schedule, index);
                        }}
                      >
                        <i className="fa fa-edit fa-2x" />
                      </a>
                    </div>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {schedules.length < 7 ? (
        <ButtonToolbar className="text-center mv-md">
          <Button className={'btn-save'} onClick={handleAddSchedule}>
            <Translate value="generalSetting.Add" />
          </Button>
        </ButtonToolbar>
      ) : null}
      {showDialog ? (
        <AddEditScheduleModal
          schedule={scheduleEdit}
          onCloseDialog={handleCloseDialog}
          schedules={schedules}
          onSubmit={handleSubmitScheduleForm}
        />
      ) : null}
    </Fragment>
  );
}
