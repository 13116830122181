import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Button, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { updateBookingVisibility } from '../../../../../actions/settingActions';
import linkIcon from '../../../../../assets/images/icons/linkGreen.svg';
import { BOOK_TYPE, STATUS_SETTING_JOB_VISIBILITY, TYPE_BOOKING_GROUP } from '../../../../../constants/commondata';
import IconvsTextBtn from '../components/IconvsTextBtn';
import TrackLink from './TrackLinks';
import DriverLinks from './DriverLinks';
import {
  CCLiteCommonFunc, getBookingType, getBookingTypeGroup,
  isDeliveryBook
} from '../../../../../utils/commonFunctions';

const statusSupportTrackLink = [
  'booked',
  'engaged',
  'droppedOff',
  'arrived',
  'delivering',
  'collecting',
  'otwMerchant',
];

const Links = ({ bookInfo, selectedFleet, isCorporateBoard, auth, settingActions }, context) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isPublic, setIsPublic] = useState(() => {
    if (bookInfo?.supplierCompanies && bookInfo?.supplierCompanies?.length > 0) {
      return false;
    }
    if (bookInfo?.visibility) {
      return bookInfo.visibility === 'public';
    }
    return false;
  });

  const checkEnableTrack = () => {
    return (
      statusSupportTrackLink.indexOf(bookInfo?.status) >= 0 ||
      (bookInfo?.status === 'confirmed' &&
        bookInfo?.request?.pickupTime !== 'Now' &&
        !isDeliveryBook(bookInfo))
    );
  };

  const checkEnableDriver = () => {
    return (
      !CCLiteCommonFunc.isCompleteBooking(bookInfo.status) &&
      !bookInfo.intercity &&
      selectedFleet?.pwa?.enableDriverPWA &&
      bookInfo?.request?.pickUpTime !== 'Now' &&
      !isCorporateBoard
    );
  };

  const handleSwitchChange = async (checked) => {
    try {
      setIsPublic(checked);
      const updateData = {
        bookIds: [bookInfo.bookId],
        fleetId: auth.selectedFleet.fleetId,
        visibility: checked ? 'public' : 'private'
      };

      const result = await settingActions.updateBookingVisibility(updateData);
      if (!result.success) {
        context.notification('error', result.error?.message || I18n.t('bookingdetail.failApi'));
      } else {
        context.notification('success', I18n.t('bookingdetail.updateSuccessJobVisibilitySingle'));
      }
    } catch (error) {
      context.notification('error', I18n.t('bookingdetail.failApi'));
    }
  };

  const checkDisableSwitch = () => {
    return !STATUS_SETTING_JOB_VISIBILITY.includes(bookInfo?.status) ||
      (bookInfo?.supplierCompanies && bookInfo?.supplierCompanies?.length > 0);
  };

  const enableTrack = useMemo(() => checkEnableTrack(), [bookInfo]);
  const enableDriver = useMemo(() => checkEnableDriver(), [bookInfo, selectedFleet]);

  const renderSwitchWithTooltip = () => {
    const switchComponent = (
      <div>
        <Switch
          checked={isPublic}
          onChange={handleSwitchChange}
          onColor="#04BE76"
          offColor="#363A44"
          checkedIcon={false}
          uncheckedIcon={false}
          height={24}
          width={48}
          handleDiameter={20}
          disabled={checkDisableSwitch()}
        />
      </div>
    );

    if (checkDisableSwitch()) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={(props) => (
            <Tooltip id="tooltip-disabled" {...props}>
              {I18n.t('bookingdetail.settingsTooltip')}
            </Tooltip>
          )}
        >
          {switchComponent}
        </OverlayTrigger>
      );
    }
    return switchComponent;
  };

  const renderGroupLinks = () => {
    return (
      <div
        style={{
          borderTop: '1px solid #2D3039',
          borderBottom: '1px solid #2D3039',
          padding: '20px 0',
          marginTop: '10px'
        }}
      >
        {enableTrack && (
          <TrackLink bookInfo={bookInfo} selectedFleet={selectedFleet} />
        )}
        {checkShowSettingShareLink() && (
          <DriverLinks bookInfo={bookInfo} selectedFleet={selectedFleet} isPublic={isPublic}/>
        )}
      </div>
    );
  };

  const checkShowSettingShareLink = () => {
    return auth.selectedFleet?.marketplace?.enable && auth.selectedFleet?.pwa?.enableDriverPWA
      && !bookInfo?.groupId && checkIsBookNowAndDelivery(bookInfo);
  };

  const checkIsBookNowAndDelivery = (data) => {
    let bookType = getBookingType(data);
    let bookingTypeGroup = getBookingTypeGroup(data);
    return !(bookType === BOOK_TYPE.now || bookingTypeGroup === TYPE_BOOKING_GROUP.delivery);
  };

  const renderDialog = () => {
    return (
      <Modal
        show={showDialog}
        size="sm"
        backdrop
        dialogClassName="confirm-linksBook"
        onHide={() => setShowDialog(false)}
        className={'confirm'}
      >
        <Modal.Body
          style={{
            backgroundColor: '#24272E',
            paddingBottom: '0px',
          }}
        >
          <div>
            <h4 style={{ color: 'rgb(4, 190, 118)', fontWeight: 'bold' }}>
              {I18n.t('bookingdetail.Links')}
            </h4>
            <span>{I18n.t('bookingdetail.ClickCopy')}</span>
          </div>

          {renderGroupLinks()}

          {checkShowSettingShareLink() &&
            <div>
              <h4 style={{ color: 'rgb(4, 190, 118)', fontWeight: 'bold', paddingTop: '16px' }}>
                {I18n.t('bookingdetail.settings')}
              </h4>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 0'
              }}>
                <span>{I18n.t('bookingdetail.shareJobPublicly')}</span>
                {renderSwitchWithTooltip()}
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: '#24272E',
            justifyContent: 'end'
          }}
        >
          <Button
            onClick={() => setShowDialog(false)}
            variant="link"
            className="text-active"
          >
            {I18n.t('bookingdetail.Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  if (!enableTrack && !enableDriver) return null;
  return (
    <>
      <Button variant="link" onClick={() => setShowDialog(true)}>
        <IconvsTextBtn text="Links" icon={linkIcon} />
      </Button>
      {renderDialog()}
    </>
  );
};

Links.contextTypes = {
  notification: PropTypes.func
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators({ updateBookingVisibility }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Links);
