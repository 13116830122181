import { I18n } from 'react-redux-i18n';
import React from 'react';
import ReportUtils from '../base/utils';
import { Localize } from 'react-redux-i18n';

export const fields = [
  {
    key: 'referee',
    label: 'report.result.driverRefersDriver.referee',
    borderRight: true,
    rowSpan: 2,
    fixed: true,
  },
  {
    key: 'refereePhone',
    label: 'report.result.driverRefersDriver.refereePhone',
  },
  {
    key: 'refereeUsername',
    label: 'report.result.driverRefersDriver.refereeUsername',
    isShow: 'driverUserName',
  },
  {
    key: 'referredDate',
    label: 'report.result.driverRefersDriver.referredDate',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MMM DD, YYYY',
          })
        : 'N/A',
  },
  {
    key: 'activatedDate',
    label: 'report.result.driverRefersDriver.activatedDate',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MMM DD, YYYY',
          })
        : 'N/A',
  },
  {
    key: 'referralStatus',
    label: 'report.result.driverRefersDriver.referralStatus',
    mutate: (value) => (value === 'inReview' ? 'In review' : 'Activated'),
  },
  {
    key: 'lifeTimeBookings',
    label: 'report.result.driverRefersDriver.lifeTimeBookings',
  },
  {
    key: 'referrer',
    label: 'report.result.driverRefersDriver.referrerByDriver',
    summary: 'numberOfReferees',
  },
  {
    key: 'referrerPhone',
    label: 'report.result.driverRefersDriver.referrerPhone',
    summary: 'activeReferees',
  },
];

/* export const fareFields = ['subtotal', 'referralEarning']; */
