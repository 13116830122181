import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Modal,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { additionalServiceType } from '../../../../constants/commondata';

import {
  Validation,
  textboxNumberHelper,
  CCLiteCommonFunc,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../components/validator';
import RadioButton from '../../../../components/radioButton/radio';
import SelectMulti from '../../../../components/SelectMulti';

class ModalAddSurcharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {
        ...props.service,
        vehicleType: [],
      },
      additionalServicesValid: {},
    };
  }

  componentDidMount = () => {
    this.props.settingActions
      .loadCarType(this.props.auth.selectedFleet.fleetId)
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({
            vehicleOptions: data.res?.map((vh) => {
              return {
                value: vh._id,
                label: vh.vehicleType,
              };
            }),
            service: {
              ...this.props.service,
              vehicleType: this.props.service?.vehicleType.map((vh) => {
                return {
                  value: vh._id,
                  label: vh.vehicleType,
                };
              }),
            },
          });
        }
      });
  };

  handleCancelInputMultiCurrenciesChange = (currency, e) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    const { service } = this.state;
    service.serviceFeeByCurrencies =
      service.serviceFeeByCurrencies ||
      this.props.auth.selectedFleet.currencies.map((data) => {
        return {
          value: 0,
          currencyISO: data.iso,
        };
      });
    service.serviceFeeByCurrencies = service.serviceFeeByCurrencies.map((c) => {
      if (c.currencyISO == currency) {
        isAdd = false;
        return { ...c, value: e.target.value };
      }
      return c;
    });
    if (isAdd) {
      service.serviceFeeByCurrencies.push(cur);
    }
    this.setState({ service });
  };

  handleAdditionalServicesName = (e) => {
    const { service } = this.state;
    service.serviceName = e.target.value;
    this.setState({ service });
  };

  handleServiceType = (e) => {
    const { service } = this.state;
    service.serviceType = e.target.value;
    this.setState({ service });
  };

  handleCustomizedChange = (newArr) => {
    this.setState({
      service: {
        ...this.state.service,
        vehicleType: newArr,
      },
    });
  };

  ValidatorAdditionalServicesCallback = (id, valid, messages) => {
    if (this.state.additionalServicesValid[id] != valid) {
      this.state.additionalServicesValid[id] = valid;
      this.setState({
        additionalServicesValid: this.state.additionalServicesValid,
      });
    }
  };

  additionalServicesAddItemSaveClick = (e) => {
    const { service } = this.state;
    const { additionalServicesAddItemSaveClick } = this.props;
    e.preventDefault();
    if (!this.state.isAddtionalServicesSubmitted) {
      this.setState({ isAddtionalServicesSubmitted: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.additionalServicesValid)) {
      return;
    }
    additionalServicesAddItemSaveClick(service);
  };

  render() {
    const { service } = this.state;
    const { editable } = this.props;
    if (!service) return null;
    return (
      <Modal onHide={this.props.additionalServicesAddItemCloseClick} show={true} backdrop={true} dialogClassName="serviceModal">
        <Form onSubmit={this.additionalServicesAddItemSaveClick}>
          <Modal.Header closeButton>
            <Modal.Title>
              {service && service._id ? (
                <Translate value="generalSetting.Edit_additional_services" />
              ) : (
                <Translate value="generalSetting.Additional_services" />
              )}
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.props.additionalServicesAddItemCloseClick}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                !this.state.isAddtionalServicesSubmitted
                  ? null
                  : this.state.additionalServicesValid.name === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="generalSetting.Name" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={this.handleAdditionalServicesName}
                value={service ? service.serviceName : ''}
                placeholder={I18n.t('generalSetting.Name')}
                disabled={editable}
                maxLength={50}
              />
              <Validator
                id="name"
                callback={this.ValidatorAdditionalServicesCallback}
              >
                <ValidCase
                  hide={!this.state.isAddtionalServicesSubmitted}
                  valid={!Validation.isStringEmpty(service.serviceName)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !this.state.isAddtionalServicesSubmitted
                  ? null
                  : this.state.additionalServicesValid
                      .serviceFeeByCurrencies === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="generalSetting.Service_Fee_By_Currencies" />{' '}
                <span className="require">*</span>
              </Form.Label>
              {this.props.auth.selectedFleet.multiCurrencies ? (
                <Tabs
                  id="currencies-tabs-Ondemand"
                  className="currencies-tabs"
                  defaultActiveKey={
                    this.props.auth.selectedFleet.currencies[0].iso
                  }
                  animation={false}
                  onSelect={this.tabSelectHandle}
                >
                  {this.props.auth.selectedFleet.currencies.map((c) => {
                    let currentValue = service.serviceFeeByCurrencies
                      ? service.serviceFeeByCurrencies.filter((d) => {
                          return d.currencyISO == c.iso;
                        })[0]
                      : null;
                    return (
                      <Tab
                        className="currencies-tab-item"
                        eventKey={c.iso}
                        title={c.iso}
                      >
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left ' +
                              (editable ? 'disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>{c.symbol}</InputGroup.Text>
                            </InputGroup.Prepend>{' '}
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              maxLength={15}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  c.iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    c.iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    c.iso,
                                    e
                                  );
                                });
                              }}
                              value={currentValue ? currentValue.value : 0}
                              disabled={editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Tab>
                    );
                  })}
                </Tabs>
              ) : (
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-left ' + (editable ? 'disabled' : '')
                    }
                  >
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {this.props.auth.selectedFleet.currencies[0].symbol}
                      </InputGroup.Text>
                    </InputGroup.Prepend>{' '}
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      maxLength={15}
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          this.props.auth.selectedFleet.currencies[0].iso,
                          e
                        );
                      }}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(e, (e) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            this.props.auth.selectedFleet.currencies[0].iso,
                            e
                          );
                        });
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(e, (e) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            this.props.auth.selectedFleet.currencies[0].iso,
                            e
                          );
                        });
                      }}
                      value={
                        service
                          ? service.serviceFeeByCurrencies.filter((d) => {
                              return (
                                d.currencyISO ==
                                this.props.auth.selectedFleet.currencies[0].iso
                              );
                            })[0]
                            ? service.serviceFeeByCurrencies.filter((d) => {
                                return (
                                  d.currencyISO ==
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                );
                              })[0].value
                            : 0
                          : 0
                      }
                      disabled={editable}
                    />
                  </InputGroup>
                </FormGroup>
              )}
              <Validator
                id="serviceFeeByCurrencies"
                callback={this.ValidatorAdditionalServicesCallback}
              >
                <ValidCase
                  valid={
                    service &&
                    service.serviceFeeByCurrencies &&
                    service.serviceFeeByCurrencies.length > 0 &&
                    service.serviceFeeByCurrencies.filter((d) => {
                      return Validation.isStringEmpty(d.value);
                    }).length == 0
                  }
                  message={I18n.t('messages.commonMessages.Required_field')}
                  hide={!this.state.isAddtionalServicesSubmitted}
                />
                <ValidCase
                  valid={
                    service &&
                    service.serviceFeeByCurrencies &&
                    service.serviceFeeByCurrencies.length > 0 &&
                    service.serviceFeeByCurrencies.filter((d) => {
                      return (
                        !Validation.isStringEmpty(d.value) && isNaN(d.value)
                      );
                    }).length == 0
                  }
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!this.state.isAddtionalServicesSubmitted}
                />
                <ValidCase
                  valid={
                    service &&
                    service.serviceFeeByCurrencies &&
                    service.serviceFeeByCurrencies.length > 0 &&
                    service.serviceFeeByCurrencies.filter((d) => {
                      return !Validation.isGreaterOrEqual(d.value, 0);
                    }).length == 0
                  }
                  message={I18n.t(
                    'messages.commonMessages.greater_or_equa'
                  ).format('0')}
                  hide={!this.state.isAddtionalServicesSubmitted}
                />
              </Validator>
              <WarningCase
                validator={Validation.isStringEmpty(service.serviceName)}
                message={I18n.t('message.warningInputChangeOver')}
                initialValue={
                  service &&
                  service.serviceFeeByCurrencies &&
                  service.serviceFeeByCurrencies.length > 0 &&
                  service.serviceFeeByCurrencies.map((ob) => ob.value)
                }
                onChangeValue={
                  service &&
                  service.serviceFeeByCurrencies &&
                  service.serviceFeeByCurrencies.length > 0 &&
                  service.serviceFeeByCurrencies.map((ob) => ob.value)
                }
                range={20}
                typePercent={false}
              />
            </FormGroup>
            <FormGroup
              className={
                !this.state.isAddtionalServicesSubmitted
                  ? null
                  : this.state.additionalServicesValid.serviceType === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label inline className="mr-r-50">
                <Translate value="generalSetting.Service_type" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <RadioButton
                text={I18n.t('generalSetting.Optional')}
                inline
                onChange={this.handleServiceType}
                checked={
                  service
                    ? service.serviceType == additionalServiceType.Optional
                    : false
                }
                disabled={editable}
                value={additionalServiceType.Optional}
              />
              <RadioButton
                text={I18n.t('generalSetting.Compulsory')}
                inline
                onChange={this.handleServiceType}
                checked={
                  service
                    ? service.serviceType == additionalServiceType.Compulsory
                    : false
                }
                disabled={editable}
                value={additionalServiceType.Compulsory}
              />
              <Validator
                id="serviceType"
                callback={this.ValidatorAdditionalServicesCallback}
              >
                <ValidCase
                  hide={!this.state.isAddtionalServicesSubmitted}
                  valid={!Validation.isStringEmpty(service.serviceType)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="generalSetting.Vehicle_type" />
              </Form.Label>
              <SelectMulti
                isMulti
                value={service.vehicleType ?? []}
                onChange={this.handleCustomizedChange}
                options={this.state.vehicleOptions}
                isSearchable
              />
            </FormGroup>
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="generalSetting.Required_fields" />
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button className={'btn-save mr-md'} type="submit">
                <Translate value="generalSetting.Save" />
              </Button>
            ) : null}
            <Button
              className={'btn-cancel'}
              onClick={this.props.additionalServicesAddItemCloseClick}
            >
              <Translate value="generalSetting.Cancel" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddSurcharge;
