import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { I18n } from "react-redux-i18n";

import { Dropdown,  FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import { filter, map, cloneDeep, forEach, remove } from "lodash";
import IntercityCustomMenu from "./IntercityCustomMenu";
import CcCheckbox from "../ccCheckbox/CcCheckbox";
import "./style.scss";

class IntercityDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            pullRight: false
        };
    }

    getTitle = () => {
        const {
            selectedItems,
            selectedRouteNumber,
            title,
            items,
            valueKey,
            labelKey,
            routeNumber,
            hasAll,
            multiSelect,
            onlyShowAllText,
            noTranslateLabel,
            intercityRoutesMode
        } = this.props;
        if (!selectedItems || !selectedItems.length) return I18n.t(title);
        if (
            multiSelect &&
            selectedItems.length === items.length &&
            onlyShowAllText &&
            !hasAll
        ) {
            return title
                ? I18n.t(title) + ": " + I18n.t("General.ALL")
                : I18n.t("General.ALL");
        };
        if (intercityRoutesMode) {
            var selected = filter(
                items,
                item => {
                    if (Array.isArray(selectedItems)) {
                        let isChecked = selectedItems.indexOf(item[valueKey]) !== -1;
                        let isRouteNumberChecked = selectedRouteNumber.indexOf(item[routeNumber]) !== -1;
                        return (isChecked && isRouteNumberChecked);
                    } else {
                        return selectedItems === item[valueKey];
                    }
                }
            );
            var text = map(selected, item =>
                (item[labelKey] && item[routeNumber]) ? (item.firstLocation.name + " - " + item.secondLocation.name) : ""
            ).join(", ");

        } else {
            var selected = filter(
                items,
                item => {
                    if (Array.isArray(selectedItems)) {
                        return selectedItems.indexOf(item[valueKey]) !== -1;
                    } else {
                        return selectedItems === item[valueKey];
                    }
                }
            );
            var text = map(selected, item =>
                !noTranslateLabel && item[labelKey]
                    ? I18n.t(item[labelKey]) || item[labelKey]
                    : item[labelKey] || ""
            ).join(", ");
        };
        if (!multiSelect && title) text = I18n.t(title) + ": " + text;
        return text;
    };

    handleSelectItem = (eventKey, event, clearAll) => {
        const {
            selectedItems,
            isNumber,
            onSelect,
            multiSelect,
            returnOnSelect,
            items,
            valueKey,
            enableAllItemSelect
        } = this.props;
        if (clearAll) return onSelect(eventKey);
        let value = isNumber ? parseInt(eventKey) : eventKey;
        if (!multiSelect) {
            if (selectedItems[0] === value) return true;
            return onSelect(value);
        }

        // tra ve option vua click (co the duoc chon hoac khong)
        if (returnOnSelect) return onSelect(value);

        let tmpSelected = cloneDeep(selectedItems);
        let isExist = selectedItems.indexOf(value) !== -1;

        if (enableAllItemSelect && value === "all_item_auto_render") {
            if (selectedItems.length === items.length) {
                // bo chon tat ca
                let keys = [],
                    disableSelectItems = filter(items, item => item.disableSelect);
                if (disableSelectItems.length) {
                    forEach(disableSelectItems, item => {
                        if (selectedItems.includes(item[valueKey])) {
                            keys.push(item[valueKey]);
                        }
                    });
                }
                return onSelect(keys);
            } else {
                // chon tat ca
                let allKeys = map(items, item => item[valueKey]);
                return onSelect(allKeys);
            }
        }
        if (isExist) {
            remove(tmpSelected, item => item === value);
        } else {
            tmpSelected.push(value);
        }
        onSelect(tmpSelected);
    };

    handleToggleClick = (open, event, source) => {
        if (this.props.open) return;
        if (source && source.source === "clearAllItem") {
            return this.setState({ open: false });
        } else {
            if (
                this.props.multiSelect &&
                source.source !== "rootClose" &&
                this.state.open
            ) {
                return true;
            }
            if (!this.state.open) {
                let windowWidth = window.innerWidth;
                let domRect = event.currentTarget.getBoundingClientRect();
                let pullRight = false;
                if (windowWidth - domRect.left < 265) pullRight = true;
                this.setState({ open: true, pullRight });
            } else {
                this.setState({ open: false });
            }
        }
    }

    render() {
        const {
            id,
            items,
            multiSelect,
            labelKey,
            valueKey,
            routeNumber,
            enableClearAll,
            selectedItems,
            selectedRouteNumber,
            searchable,
            intercityRoutesMode,
            pullRight,
            customTitle,
            className,
            enableAllItemSelect,
            async,
            onScrollBottom,
            asyncDelay,
            onSearchChange,
            disabled,
            noTranslateLabel,
            applyButton,
            footer,
            customLabel
        } = this.props;

        let menuItems = Object.assign([], items);
        if (enableAllItemSelect && multiSelect)
            menuItems.unshift({
                [valueKey]: "all_item_auto_render",
                [labelKey]: "cue.All"
            });

        return (
            <Dropdown
                id={id}
                rootCloseEvent={"click"}
                className={"cc-drop-down " + (className ? className : "")}
                open={this.props.open ? true : this.state.open}
                onToggle={this.handleToggleClick}
                disabled={disabled}
                pullRight={pullRight || this.state.pullRight}
            >
                <Dropdown.Toggle>
                    {customTitle ? (
                        <OverlayTrigger
                            overlay={<Tooltip id={"tooltip"}>{customTitle}</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}
                        >
                            <span className="dropdown-title">{customTitle}</span>
                        </OverlayTrigger>
                    ) : (
                            <OverlayTrigger
                                overlay={<Tooltip id={"tooltip"}>{this.getTitle()}</Tooltip>}
                                placement="top"
                                delayShow={300}
                                delayHide={150}
                            >
                                <span className="dropdown-title">{this.getTitle()}</span>
                            </OverlayTrigger>
                        )}
                </Dropdown.Toggle>
                <IntercityCustomMenu
                    multiSelect={multiSelect}
                    enableClearAll={enableClearAll}
                    totalSelect={selectedItems.length}
                    items={menuItems}
                    labelKey={labelKey}
                    valueKey={valueKey}
                    searchable={searchable}
                    onSelect={this.handleSelectItem}
                    async={async}
                    onScrollBottom={onScrollBottom}
                    asyncDelay={asyncDelay}
                    onSearchChange={onSearchChange}
                    applyButton={applyButton}
                    footer={footer}
                    intercityRoutesMode={intercityRoutesMode}
                >
                    {enableAllItemSelect && multiSelect ? (
                        <Dropdown.Item
                            eventKey={"all_item_auto_render"}
                            className="all-item-auto-added"
                        >
                            <CcCheckbox
                                checked={selectedItems.length === items.length}
                                value="all_item_auto_render"
                                text={I18n.t("cue.All")}
                                onChange={() => { }}
                                className="dropdown-selectbox"
                                labelClassName="text"
                            />

                        </Dropdown.Item>
                    ) : null}
                    {map(items, item => {
                        let isChecked = selectedItems.indexOf(item[valueKey]) !== -1;
                        let isRouteNumberChecked = selectedRouteNumber.indexOf(item[routeNumber]) !== -1;
                        return (
                            <Dropdown.Item
                                eventKey={item[valueKey] + "." + item[routeNumber]}
                                key={item[valueKey] + "." + item[routeNumber]}
                                disabled={item.disableSelect}
                                className={!multiSelect && isChecked && isRouteNumberChecked ? "is-selected" : ""}
                            >
                                {multiSelect ? (
                                    <CcCheckbox
                                        checked={isChecked}
                                        value="all"
                                        text={intercityRoutesMode ? (
                                            !noTranslateLabel && item.firstLocation.name && item.secondLocation.name
                                                ? (item.firstLocation.name + " - " + item.secondLocation.name)
                                                : (item.firstLocation.name && item.secondLocation.name) || ""
                                        ) : (
                                                !noTranslateLabel && item[labelKey]
                                                    ? I18n.t(item[labelKey]) || item[labelKey]
                                                    : item[labelKey] || ""
                                            )
                                        }
                                        routeNumber={item.routeNumber}
                                        carTypes={intercityRoutesMode ? item.carType : null}
                                        pricePerSeat={intercityRoutesMode ? (
                                            item.routeSetting.pricePerSeat[0]
                                        ) : null}
                                        onChange={() => { }}
                                        className="dropdown-selectbox text-ellipsis"
                                        labelClassName="text"
                                        disabled={item.disableSelect}
                                    />
                                ) : (
                                        <span className="text">
                                            {customLabel
                                                ? customLabel(item)
                                                : !noTranslateLabel && item[labelKey]
                                                    ? I18n.t(item[labelKey]) || item[labelKey]
                                                    : item[labelKey] || ""}
                                        </span>
                                    )}
                            </Dropdown.Item>
                        );
                    })}
                </IntercityCustomMenu>
            </Dropdown >
        )
    }
}


IntercityDropDown.propTypes = {
    items: PropTypes.array.isRequired,
    multiSelect: PropTypes.bool,
    id: PropTypes.string,
    title: PropTypes.string,
    hasAll: PropTypes.bool,
    selectedItems: PropTypes.array.isRequired,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    searchable: PropTypes.bool,
    isNumber: PropTypes.bool,
    onSelect: PropTypes.func,
    enableClearAll: PropTypes.bool,
    returnOnSelect: PropTypes.bool,
    pullRight: PropTypes.bool,
    customTitle: PropTypes.any,
    className: PropTypes.string,
    enableAllItemSelect: PropTypes.bool, // tu dong them item All,
    onlyShowAllText: PropTypes.bool,
    async: PropTypes.bool,
    onScrollBottom: PropTypes.func,
    asyncDelay: PropTypes.number,
    onSearchChange: PropTypes.func,
    disabled: PropTypes.bool,
    noTranslateLabel: PropTypes.bool,
    applyButton: PropTypes.func,
    footer: PropTypes.bool,
    customLabel: PropTypes.func // custom label for item
};

export default IntercityDropDown