import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../components/validator';

function CarTypeSelect(props) {
  const carTypesValid = props.carTypeList.filter((car) => {
    if (props.request.companyId) {
      return props.companyVehicleTypes.indexOf(car.vehicleType) !== -1;
    }
    return true;
  });

  const handleChange = (e) => { 
    const selectedCar = carTypesValid.find(
      (o) => o.vehicleType === e.target.value
    );
    const routeSelected = selectedCar.route;
    props.intercityBookingActions.updateCommonData({
      carType: selectedCar,
      routeSetting: routeSelected.routeSetting,
      schedules: routeSelected.routeSetting.schedules,
      routeSelected,
      pickupTime: null
    });
    props.intercityBookingActions.etaFare();
    props.onChange();
  };

  if (!props.routeSelected) {
    return null;
  }
  return (
    <FormGroup className={props.isSubmited && props.valid.carType === false ? 'error' : null}>
      <Form.Label>
        <Translate value="cue.Car_type" />
        &nbsp;
        <span className="require"> *</span>
      </Form.Label>
      <FormControl
        as="select"
        placeholder="select"
        className={'form-custom'}
        onChange={handleChange}
        value={_.get(props.carType, 'vehicleType')}
        style={{ marginBottom: 0 }}
        disabled={props.disabled}
      >
        {/* <option key={'null'} value={null}>
          Select car type
        </option> */}
        {carTypesValid.map((car) => {
          return (
            <option key={car.vehicleType} value={car.vehicleType}>
              {car.vehicleType}
            </option>
          );
        })}
      </FormControl>
      <Validator
        callback={props.validatorCallback}
        id="carType"
      >
        <ValidCase
          valid={carTypesValid.length}
          hide
          message={I18n.t('messages.commonMessages.Required_field')}
        />
      </Validator>
    </FormGroup>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    auth: state.auth,
    carTypeList: state.intercityBooking.common.carTypes,
    carType: state.intercityBooking.common.carType,
    routes: state.intercityBooking.common.routes,
    routeSelected: state.intercityBooking.common.routeSelected,
    companyVehicleTypes: state.intercityBooking.common.companyVehicleTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CarTypeSelect);
