const fields = [
  {
    key: 'name',
    label: 'supplier.supplier',
    fixed: true
  },
  {
    key: 'adminName',
    label: 'corporate.Admin_Name',
  },
  {
    key: 'email',
    label: 'supplier.email',
  },
  {
    key: 'adminInfo.phone',
    label: 'supplier.phoneNumber'
  },
  {
    key: 'zones',
    label: 'supplier.zones'
  },
  {
    key: 'agent',
    label: 'supplier.agent',
  },
  {
    key: "connectStatus",
    label: "companySetting.connectStatus",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
  },
  {
    key: 'registration',
    label: 'customer.Registration_Date'
  },
  {
    key: 'status',
    label: 'supplier.status',
    width: 250
  },
  {
    key: 'actions',
    label: 'supplier.actions',
    width: 250
  },
];

export default fields
