import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _, { xor } from 'lodash';
import PropTypes from 'prop-types';
import './index.scss';
import * as reportActions from '../../../actions/reportActions';
import * as loadingActions from '../../../actions/loadingBarActions';
import * as commonActions from '../../../actions/commonDataAction';
import * as settingActions from '../../../actions/settingActions';
import {
  SMS_INTERGRATIONS,
  smsIntegrationList,
} from '../../../constants/commondata';
import {
  Col,
  FormControl,
  FormGroup,
  Row,
  Button,
  Form,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import RenderFormSMS from './components';
import Confirm from '../../../components/confirm/Confirm';
import CcCountrySelect from '../../../components/ccCountrySelect';

const SMSTab = (
  {
    auth,
    permissions,
    commonData,
    reportActions,
    loadingActions,
    settingActions,
    smsInfo,
    keyTab,
    updateInfoTab,
    TAB_KEYS,
  },
  context
) => {
  const [provider, setProvider] = useState();
  const [onLoad, setOnload] = useState(false);
  const [formData, setFormData] = useState({});
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [smsIntegrationListState, setSmsIntegrationList] = useState([]);

  const handleSelectProvider = (e) => {
    setProvider(e.target.value);
  };

  const handleInputChange = (key, e) => {
    setIsChange(true);
    const defaultVal = { ...formData };
    defaultVal[key] = e.target.value;
    setFormData(defaultVal);
  };

  const handlePhoneChange = (key, status, value, countryData, number, id) => {
    setIsChange(true);
    const defaultVal = { ...formData };
    defaultVal[key] = number;
    setFormData(defaultVal);
  };

  const resetForm = () => {
    setFormData({});
    setProvider('');
    setCountriesSelected([]);
  };

  useEffect(() => {
    if (keyTab === 'worldwide') {
      setSmsIntegrationList(smsIntegrationList);
      setProvider('');
    } else {
      setSmsIntegrationList(smsIntegrationList.filter((s) => s.value));
    }
  }, []);

  useEffect(() => {
    setFormData(smsInfo.info);
    setProvider(
      keyTab === 'add' ? SMS_INTERGRATIONS.SMSTwilio : smsInfo.provider
    );
    setCountriesSelected(
      (smsInfo.countries || []).map((l) => {
        return {
          value: l.countryCode,
          label: l.countryName,
        };
      })
    );
  }, [smsInfo.info]);

  const handleDeleteClick = () => {
    setConfirmInfo({
      title: I18n.t('corporate.Delete'),
      body: I18n.t('corporate.CONFIRM_DELETE_USER'),
      buttonTitle: 'Yes',
      closeButtonText: 'No',
    });
  };

  const handleDeleteConfirmed = async () => {
    loadingActions.showLoadingSpiner();
    try {
      let result = await settingActions.deleteSMSCountries({
        fleetId: auth.selectedFleet.fleetId,
        provider: smsInfo.provider,
        info: smsInfo?.info?._id,
      });
      loadingActions.hideLoadingSpiner();
      if (result?.status === 200 && result?.smsProviders) {
        context.notification(
          'success',
          I18n.t('smsIntegration.Deleted_success')
        );
        updateInfoTab(result?.smsProviders, TAB_KEYS.worldwide);
      } else {
        context.notification(
          'error',
          I18n.t(`smsIntegration.error.${result.txt_code}`)
        );
      }
    } catch (error) {
      loadingActions.hideLoadingSpiner();
      context.notification('error', 'error catch func');
    }
  };

  const handleSaveClick = async () => {
    if (!(countriesSelected?.length > 0) && keyTab !== TAB_KEYS.worldwide) {
      context.notification('error', I18n.t('customer.ERROR_INPUT_COUNTRY'));
      return;
    }

    if (provider === SMS_INTERGRATIONS.SMSTwilio && formData) {
      const regex = /^[a-zA-Z0-9 ]{3,11}$/;
      if (
        (formData?.senderId1 && !regex.test(formData.senderId1)) ||
        (formData?.senderId2 && !regex.test(formData.senderId2))
      ) {
        context.notification('error', 'The senderId must contain between 3 to 11 characters and should not include any special characters.');
        return;
      }
    }

    try {
      setOnload(true);
      let result;
      loadingActions.showLoadingSpiner();
      if (formData?._id || keyTab === TAB_KEYS.worldwide) {
        result = await settingActions.updateSMSCountries({
          fleetId: auth.selectedFleet.fleetId,
          provider,
          data: formData,
          countries: countriesSelected.map((c) => {
            return {
              countryCode: c.value,
              countryName: c.label,
            };
          }),
          info: formData?._id,
          oldProvider: smsInfo.provider,
          isWorldwide: keyTab === TAB_KEYS.worldwide,
        });
      } else {
        result = await settingActions.createSMSCountries({
          fleetId: auth.selectedFleet.fleetId,
          provider,
          data: formData,
          countries: countriesSelected.map((c) => {
            return {
              countryCode: c.value,
              countryName: c.label,
            };
          }),
        });
      }
      loadingActions.hideLoadingSpiner();
      if (result?.status === 200 && result?.smsProviders) {
        context.notification(
          'success',
          I18n.t('smsIntegration.updateSMSSuccess')
        );
        if (
          keyTab === TAB_KEYS.add ||
          (keyTab === TAB_KEYS.worldwide && !provider)
        )
          resetForm();
        updateInfoTab(
          result?.smsProviders,
          keyTab === TAB_KEYS.worldwide ? TAB_KEYS.worldwide : result.info
        );
      } else {
        context.notification(
          'error',
          I18n.t(`smsIntegration.error.${result.txt_code}`)
        );
      }
      setOnload(false);
    } catch (error) {
      loadingActions.hideLoadingSpiner();
      context.notification('error', 'error catch func');
      setOnload(false);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md={6}>
          {keyTab !== TAB_KEYS.worldwide && (
            <FormGroup>
              <Form.Label>
                <Translate value="smsIntegration.receiverCountries" />
              </Form.Label>
              <CcCountrySelect
                isMulti
                value={countriesSelected}
                onchange={setCountriesSelected}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Form.Label>
              <Translate value="smsIntegration.provider" />
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom no-marginBottom"
              onChange={handleSelectProvider}
              value={provider}
              disabled={onLoad}
            >
              {smsIntegrationListState.map((mode) => (
                <option value={mode.value} key={mode.value}>
                  {mode.label}
                </option>
              ))}
            </FormControl>
          </FormGroup>
          <RenderFormSMS
            provider={provider}
            formData={formData}
            handleInputChange={handleInputChange}
            handlePhoneChange={handlePhoneChange}
          />
          <FormGroup className="form-inline mt-xl">
            {permissions?.actions &&
              keyTab !== TAB_KEYS.add &&
              keyTab !== TAB_KEYS.worldwide && (
                <Button
                  className={'btn-cancel mr-md'}
                  disabled={onLoad}
                  onClick={handleDeleteClick}
                >
                  <Translate value="driver.Delete" />
                </Button>
              )}
            {!permissions || permissions.actions ? (
              <Button
                className={'btn-save'}
                disabled={onLoad}
                onClick={handleSaveClick}
              >
                <Translate value="flightIntegration.Save" />
              </Button>
            ) : (
              ''
            )}
          </FormGroup>
        </Col>
      </Row>
      <Confirm
        confirm={confirmInfo}
        handleConfirmButtonClick={handleDeleteConfirmed}
        handleConfirmCloseClick={() => setConfirmInfo(null)}
      />
    </div>
  );
};

SMSTab.contextTypes = {
  notification: PropTypes.func,
};

function SMSTabStateToProps(state, ownProp) {
  return {
    user: state.auth.user,
    auth: state.auth,
    commonData: state.commonData,
    permissions: state.menuHandle.modulePermission,
  };
}

function SMSTabDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}

export default connect(
  SMSTabStateToProps,
  SMSTabDispatchToProps
)(SMSTab);
