import {
    checkExistAirlineTravellerPhoneApi,
    airlineCompaniesApi,
    fetchAirlineApi,
    createAirlineApi,
    updateAirlineApi,
    detailAirlineApi,
    activeAirlineApi,
    multiActiveAirlineApi,
    multiDeleteAirlineApi,
    createTravelerAirlineApi,
    updateTravelerAirlineApi,
    findTravelerAirlineApi,
    activeTravelerAirlineApi,
    deleteTravelerAirlineApi,
    updatePaymentAirlineApi,
    addCardAirlineApi,
    deleteCardAirlineApi,
    addUserAirlineApi,
    updateUserAirlineApi,
    findUserAirlineApi,
    deleteUserAirlineApi,
    fetchCarTypeAirlineApi,
    updatePricingAirlineApi,
    assignRateAirlineApi,
    resetPasswordAirlineApi,
    topUpAirlineApi,
    countTravelerAirlineApi,
    countAirlineApi,
    resestPasswordCrewApi,
    deleteCrewAirlineApi,
    addCrewAirlineApi,
    updateCrewAirlineApi,
    findCrewAirlineApi,
    activeCrewAirlineApi,
    checkExistAirlineCrewPhoneApi
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";

function getCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        airlineCompaniesApi,
        config,
        null,
        null,
        null,
        true
    );
}

function countCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        countAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function multiDeleteCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        multiDeleteAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        multiDeleteAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function checkExistCorporateTravellerPhone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        checkExistAirlineTravellerPhoneApi,
        config,
        null,
        null,
        null,
        false
    );
}
function checkExistCrewPhone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        checkExistAirlineCrewPhoneApi,
        config,
        null,
        null,
        null,
        false
    );
}
function activeCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function multiActiveCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        multiActiveAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options),
    };
    return callApi(
        options._id ? updateAirlineApi : createAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}
function detailCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        detailAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getTravelerAirline(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findTravelerAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function countTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        countTravelerAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function activeTravelerAirline(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeTravelerAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateTravelerAirline(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        options._id ? updateTravelerAirlineApi : createTravelerAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteTravelerAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getOperator(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findUserAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateOperator(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        options._id ? updateUserAirlineApi : addUserAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteUserCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteUserAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function addCardCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        addCardAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteCardCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteCardAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function updatePaymentCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updatePaymentAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function updatePricingCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updatePricingAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getCarTypeCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        fetchCarTypeAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function resetPasswordCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        resetPasswordAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function assignRateCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        assignRateAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function topUpCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        topUpAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}
function getCrew(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findCrewAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateCrew(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        options._id ? updateCrewAirlineApi : addCrewAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteCrew(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteCrewAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}
function resetPasswordCrew(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        resestPasswordCrewApi,
        config,
        null,
        null,
        null,
        true
    );
}
function activeCrew(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeCrewAirlineApi,
        config,
        null,
        null,
        null,
        true
    );
}
export {
    checkExistCorporateTravellerPhone,
    getCorporate,
    detailCorporate,
    activeCorporate,
    multiActiveCorporate,
    multiDeleteCorporate,
    deleteCorporate,
    operateCorporate,
    getTravelerAirline,
    activeTravelerAirline,
    operateTravelerAirline,
    deleteTraveler,
    getOperator,
    operateOperator,
    deleteUserCorporate,
    addCardCorporate,
    deleteCardCorporate,
    getCarTypeCorporate,
    updatePaymentCorporate,
    updatePricingCorporate,
    resetPasswordCorporate,
    assignRateCorporate,
    topUpCorporate,
    countTraveler,
    countCorporate,
    getCrew,
    operateCrew,
    deleteCrew,
    resetPasswordCrew,
    activeCrew,
    checkExistCrewPhone
}
