import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Col, Row, Button, ButtonToolbar, Modal, Image, Container } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import './intercityBooking.scss';
import closeIcon from '../../assets/images/icons/close.svg';

import * as loadingActions from '../../actions/loadingBarActions';
import * as paymentMethodActions from '../../actions/paymentMethodActions';
import * as newbookingActions from '../../actions/newbookingAction';
import * as intercityBookingActions from '../../actions/intercityBookingActions';
import * as corporateActions from '../../actions/corporateAction';
import * as intercityRateActions from '../../actions/intercityRateActions';
import * as customerActions from '../../actions/customerAction';
import * as bookingDetailActions from '../../actions/bookingDetailAction';

import LocationInfoForm from './LocationInfoForm';
import FlightInfo from './FlightInfo';
import TravelerInfoForm from './TravelerInfoForm';
import AdditionalServiceForm from './AdditionalServiceForm';
import SeatLuggageForm from './TravelerInfoForm/SeatLuggageForm';
import NoteForm from './AdditionalServiceForm/NoteForm';
import PromotionForm from './PromotionForm';
import DispatchForm from './DispatchForm';
import PaymentForm from './PaymentForm';
import MapForm from './MapForm';
import TripEstimate from './TripEstimate';
import { CCLiteCommonFunc, Validation, trimPhoneNumber, handleResultUpdateFinishedBooking, checkCorporateUser } from '../../utils/commonFunctions';
import { socketDispatchApi } from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
import {
  LOCATION_TYPE,
  paymentMethodNumber,
  userType,
  EDITABLE_FARE,
  finishedStatusList,
} from '../../constants/commondata';
import Confirm from '../../components/confirm/Confirm';
import IncidentButton from './BookingAction/IncidentButton';
import CancelButton from './BookingAction/CancelButton';
import { UpdateConfirmationModal } from '../../components/bookingDetail';
import ConfirmUpdateModal from './BookingAction/ConfirmUpdateModal';
import CompleteButton from './BookingAction/CompleteButton';
import { tranformAdditionalServicesBeforeSubmit } from './utils';

import EditFareComponent from '../../components/bookingDetail/bookComponent/EditFareComponent';
import DispatchLogs from '../../components/DispatchLogs';
import * as settingActions from '../../actions/settingActions';
import * as uploadActions from '../../actions/uploadActions';
import {
  SendSMSBookingModal,
} from '../../components/bookingDetail';
import TrailNotes from '../../components/bookingDetail/bookComponent/trailNotes';
import { convertPaymentTypeWhenBookCompleted, getMsgCreateUpdateBook } from '../../components/bookingDetail/bookFunction/bookingInfo';
import { transformDataBooking } from '../../utils/transformData';
import TopActionBtns from '../../components/bookingDetail/bookComponent/TopActionBtns';

class IntercityBookingDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      request: {
        routeSelected: null,
        companyId: props.user.roles.companyId,
        departureDate: null,
        departureTime: null,
        dispatchType: 0,
      },
      showTrailNote: false,
      psgInfo: {
        firstName: '',
        phone: '',
      },
      drvInfo: {
        fullName: '',
      },
      showSMSBooking: false,
      smsBookingTemplateQuery: {
        list: [],
        listAll: [],
        page: 0,
        limit: 10,
        total: 0,
        selectedObj: null,
        hasMore: true,
        search: '',
      },
      disabledSpam: false,
      isSend: false,
      smsWillSend: {
        id: '',
        phone: '',
        name: '',
        content: '',
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: '',
        bookingId: '',
      },
      dispatchType: 0,
      forceAssign: false,
      valid: {},
      promoCodeMessageContent: {
        paymentMethods: [],
        minimumEstFare: '',
        schedules: false,
      },
      locationService: {},
      flightScheduleSuggestions: [],
      showConfirmUpdate: false,
      showWarningResetEditFare: false,
      corporateInfo: {},
      corporateUserInfo: {},
    };
    this.handleResultUpdateFinishedBooking = handleResultUpdateFinishedBooking.bind(this)
  }

  componentDidMount() {
    this.props.paymentMethodActions.paymentMethod(
      this.props.auth.selectedFleet.fleetId
    );
    this.props.intercityBookingActions.fetchCarTypeByCompany({
      companyId: this.props.user.roles.companyId,
      fleetId: this.props.auth.selectedFleet.fleetId,
    });
    this.initData();
    this.getSMSBookingTemplateList(false, () => {}, true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.common.isFareEdited !== prevProps.common.isFareEdited &&
      prevProps.common &&
      prevProps.common.isFareEdited
    ) {
      this.setState({ showWarningResetEditFare: true });
    }
    if (
      this.props.common.isFareEditedBefore !==
        prevProps.common.isFareEditedBefore &&
      prevProps.common &&
      prevProps.common.isFareEditedBefore
    ) {
      this.setState({ showWarningResetEditFare: true });
    }
  }

  initData = () => {
    const { bookInfo, selectedFleet } = this.props;
    let timezonePickUp = bookInfo.request.pickup.timezone
      ? bookInfo.request.pickup.timezone
      : selectedFleet.timezone;
    const query = {
      limit: 20,
      page: 0,
      query: {
        fleetId: selectedFleet.fleetId,
        fromZoneId: _.get(bookInfo, 'intercityInfo.routeFromZoneId'),
        toZoneId: _.get(bookInfo, 'intercityInfo.routeToZoneId'),
        corporateId: bookInfo?.corporateInfo?.corporateId
      },
    };
    this.setState({
      travelerType: bookInfo.travelerType,
      corporateUserInfo: {
        ...(bookInfo.corporateInfo || {}),
      }
    })
    if(bookInfo.travelerType === 1) {
      this.props.corporateActions
        .detailCorporate({
          fleetId: this.props.auth.selectedFleet.fleetId,
          _id: bookInfo?.corporateInfo?.corporateId,
        })
        .then((data) => {
          if (data.ok) {
            // this.updateCorporateInfoToReduce(data.res)
            this.props.intercityBookingActions.updateCommonData({
              corporateInfo: {
                ...(bookInfo.corporateInfo || {}),
                _id: bookInfo?.corporateInfo?.corporateId || '',
                ...data.res
              },
            });
          }
        });
    }
    this.props.intercityRateActions
      .findRouteWithCarType(query)
      .then(({ res }) => {
        this.setState({ routes: res.list });
        const bookingRoute = res.list[0];
        if (bookingRoute) {
          // location
          const pickupLocation = {
            lat: bookInfo.request.pickup.geo[1],
            lng: bookInfo.request.pickup.geo[0],
            city: bookInfo.request.pickup.city,
            cityName: bookInfo.request.pickup.cityName,
            zipCode: bookInfo.request.pickup.zipCode,
            address: bookInfo.request.pickup.address,
            businessName: bookInfo.request.pickup.businessName,
            from: bookInfo.request.pickup.from,
            offset: bookInfo.request.pickup.offset,
            timezone: bookInfo.request.pickup.timezone,
            countryCode: bookInfo.request.pickup.country,
            instructionLink: bookInfo.request.pickup.instructionLink || '',
            addressDetails: bookInfo.request.pickup.addressDetails,
          };
          const pickupAddress = bookInfo.request.pickup.address;
          const pickupFrom = bookInfo.request.pickup.from;
          const destinationLocation = {
            lat: bookInfo.request.destination.geo[1],
            lng: bookInfo.request.destination.geo[0],
            city: bookInfo.request.destination.city,
            cityName: bookInfo.request.destination.cityName,
            zipCode: bookInfo.request.destination.zipCode,
            address: bookInfo.request.destination.address,
            from: bookInfo.request.destination.from,
            countryCode: bookInfo.request.destination.country,
            instructionLink: bookInfo.request.destination.instructionLink || '',
            addressDetails: bookInfo.request.destination.addressDetails,
          };
          const destinationAddress = bookInfo.request.destination.address;
          const destinationFrom = bookInfo.request.destination.from;

          const carType = bookingRoute.carTypes.find(
            (c) => c.vehicleType === bookInfo.request.vehicleTypeRequest
          );
          let isValidCartype = true;
          if (this.state.request.companyId) {
            isValidCartype =
              this.props.common.companyVehicleTypes.indexOf(
                carType.vehicleType
              ) !== -1;
          }
          const additionalService = {};
          _.forEach(bookInfo.request.services, (sv) => {
            if (sv.active) {
              additionalService[sv.serviceId || sv._id] = true;
            }
          });

          let departureDate = '';
          let departureTime = {};
          let departureDateTo = '';
          let departureTimeTo = {};
          if (bookInfo.request.tripType === 'requested') {
            departureDate = moment(bookInfo.request.pickUpTimeFrom)
              .tz(bookInfo.request.pickup.timezone)
              .format('YYYY-MM-DDTHH:mm:ss');
            departureTime = {
              hour: moment(departureDate).hour(),
              minute: moment(departureDate).minute(),
            };
            departureDateTo = moment(bookInfo.request.pickUpTime)
              .tz(bookInfo.request.pickup.timezone)
              .format('YYYY-MM-DDTHH:mm:ss');
            departureTimeTo = {
              hour: moment(departureDateTo).hour(),
              minute: moment(departureDateTo).minute(),
            };
          } else {
            departureDate = moment(bookInfo.request.pickUpTime)
              .tz(timezonePickUp)
              .format('YYYY-MM-DDTHH:mm:ss');
            departureTime = {
              hour: moment(departureDate).hour(),
              minute: moment(departureDate).minute(),
            };
          }
          const { flightNumber, type, ident } = _.get(
            bookInfo,
            'request.moreInfo.flightInfo',
            {}
          );

          const markupDifference =
            bookInfo.request.estimate.fare.markupDifference || 0;
          let editFare;
          if (bookInfo.request.estimate.isFareEdited && !markupDifference) {
            editFare = {};
            for (var key in bookInfo.request.estimate.fare) {
              if (EDITABLE_FARE.includes(key)) {
                if (bookInfo.request.estimate.fare.hasOwnProperty(key)) {
                  if (
                    bookInfo.request.estimate.fare[key] !==
                    bookInfo.request.estimate.originFare[key]
                  ) {
                    editFare[key] = bookInfo.request.estimate.fare[key];
                  }
                }
              }
            }
          }
          this.props.intercityBookingActions.updateCommonData({
            editFare,
            schedules: _.get(carType.route, 'routeSetting.schedules', []),
            routeSelected: carType.route,
            routeSetting: carType.route.routeSetting,
            carTypes: bookingRoute.carTypes,
            carType: isValidCartype ? carType : null,
            pickupLocation,
            pickupAddress,
            pickupFrom,
            destinationLocation,
            destinationAddress,
            destinationFrom,
            paymentMethod: convertPaymentTypeWhenBookCompleted({bookInfo, currentPayment: bookInfo.request.paymentType}),
            // etaFare: bookInfo.request.estimate.fare,
            etaFare: {
              ...bookInfo.request.estimate.fare,
              etaFare:
                bookInfo.request.estimate.fare.etaFare - markupDifference,
              unroundedTotalAmt:
                bookInfo.request.estimate.fare.unroundedTotalAmt -
                markupDifference,
              totalWithoutPromo:
                bookInfo.request.estimate.fare.totalWithoutPromo -
                markupDifference,
              markupType: bookInfo.request.estimate.markupType,
              markupValue: bookInfo.request.estimate.markupValue,
              reasonMarkup: bookInfo.request.estimate.reasonMarkup,
            },
            originFare: bookInfo.request.estimate.originFare || {},
            isFareEdited: bookInfo.request.estimate.isFareEdited || false,
            isFareEditedBefore:
              bookInfo.request.estimate.isFareEdited || !!markupDifference,
            reasonEditFare: bookInfo.request.estimate.reasonEditFare || '',
            flightService: {
              flightNumber: flightNumber,
              meetDriver: type,
              flightSchedule: flightNumber && ident ? [] : null,
            },
            promo: {
              promoCode: bookInfo.request.promo,
              promoValue: bookInfo.request.promoValue,
              value:
                bookInfo.request.promoInfo && bookInfo.request.promoInfo.value
                  ? bookInfo.request.promoInfo.value
                  : 0,
              type:
                bookInfo.request.promoInfo && bookInfo.request.promoInfo.type
                  ? bookInfo.request.promoInfo.type
                  : '',
              maximumValue:
                bookInfo.request.promoInfo &&
                bookInfo.request.promoInfo.maximumValue
                  ? bookInfo.request.promoInfo.maximumValue
                  : {},
            },
            paxNumber: bookInfo.request.paxNumber,
            luggageNumber: bookInfo.request.luggageNumber,
            additionalService,
            // pickupTime: departureTime,
            // pickupDate: departureDate,
            pickupTime:
              bookInfo.request.tripType === 'requested'
                ? departureTimeTo
                : departureTime,
            pickupDate:
              bookInfo.request.tripType === 'requested'
                ? departureDateTo
                : departureDate,
            credits: bookInfo.psgInfo.creditInfo
              ? [bookInfo.psgInfo.creditInfo]
              : [],
            tripType: bookInfo.request.tripType
              ? bookInfo.request.tripType
              : '',
          });
          const updateState = {
            request: {
              ...bookInfo.request,
              paymentType: convertPaymentTypeWhenBookCompleted({bookInfo, currentPayment: bookInfo.request.paymentType}),
            },
            psgInfo: bookInfo.psgInfo,
            dispatchType: bookInfo.dispatchType,
          };
          if (bookInfo.drvInfo) {
            updateState.drvInfo = {
              ...bookInfo.drvInfo,
              fullName: bookInfo.drvInfo.fullName || bookInfo.drvInfo.phone,
            };
          }
          updateState.request.departureDate = departureDate;
          updateState.request.departureTime = departureTime;
          updateState.request.departureTimeTo = departureTimeTo;
          updateState.externalInfo = bookInfo.externalInfo;
          this.setState(updateState, () => {
            this.initPsgInfo(updateState.psgInfo);
            this.initPromocode(updateState.request);
          });
          this.getLocationService();
        }
      });
  };

  sendSMSBooking = e => {
    this.setState({
      showSMSBooking: !this.state.showSMSBooking,
      smsWillSend: {
        id: "",
        phone: !_.isString(this.props.bookInfo.psgInfo) ? (this.props.bookInfo.psgInfo.phone || '') : (this.props.bookInfo.drvInfo.phone || ''),
        name: "",
        content: "",
        subject: '',
        typeMessage: 'Inbox',
        typeRecipient: !_.isString(this.props.bookInfo.psgInfo) ? 'passenger': 'driver',
      },
      isSend: false,
      smsBookingTemplateQuery: {
        ...this.state.smsBookingTemplateQuery,
        selectedObj: null
      }
    });
  };

  getSMSBookingTemplateList = (loadMore = false, callback = null, firstLoad = false) => {
    let bodyRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
    };

    this.props.settingActions
      .getAllBookingSMSTemplates(bodyRequest)
      .then((data) => {
        if (data.ok && data.res) {
          let listFiter = data.res;
          const newData = listFiter.map((item) => {
            item.value = item.name;
            item.id = item._id;
            return item;
          });
          this.setState({
            smsBookingTemplateQuery: {
              ...this.state.smsBookingTemplateQuery,
              ...data.res,
              list: newData,
              listAll: newData,
            },
            smsWillSend: {
              id: "",
              phone: !_.isString(this.props.bookInfo.psgInfo) ? (this.props.bookInfo.psgInfo.phone || '') : (this.props.bookInfo.drvInfo.phone || ''),
              name: "",
              content: "",
              subject: "",
              typeMessage: 'Inbox',
              typeRecipient: !_.isString(this.props.bookInfo.psgInfo) ? 'passenger': 'driver',
            }
          });
        }
      });
  };
  handleSelectSMSBookingTemplate = (templateId) => {
    const smsBookingTemplateQuery = Object.assign(
      {},
      this.state.smsBookingTemplateQuery
    );
    smsBookingTemplateQuery.selectedObj = _.find(
      this.state.smsBookingTemplateQuery.list,
      (item) => item.id === templateId
    );

    if((templateId && templateId.length === 0) || !templateId) {
      smsBookingTemplateQuery.list = this.state.smsBookingTemplateQuery.listAll
    }

    this.setState({
      smsBookingTemplateQuery,
      smsWillSend: {
        ...this.state.smsWillSend,
        id: templateId || "",
        name: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.name ? smsBookingTemplateQuery.selectedObj.name : "",
        subject: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.name ? smsBookingTemplateQuery.selectedObj.name : "",
        content: smsBookingTemplateQuery.selectedObj && smsBookingTemplateQuery.selectedObj.content ? smsBookingTemplateQuery.selectedObj.content : "",
      },
    });
  };

  handleChangeMessageType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeMessage: value,
      }
    })
  }

  handleSendMessageClick = () => {
      let data = Object.assign({}, this.state.smsWillSend)
      data.phone = trimPhoneNumber(data.phone);
      data.bookingId = (this.state.data && this.state.data.bookId)
      const keyListArr = [
        '[FLEET_NAME]',
        '[FLEET_PHONE]',
        '[FLEET_EMAIL]',
        '[BOOKING_ID]',
        '[PASSENGER_FIRST_NAME]',
        '[PASSENGER_LAST_NAME]',
        '[PASSENGER_PHONE]',
        '[DRIVER_FIRST_NAME]',
        '[DRIVER_LAST_NAME]',
        '[DRIVER_PHONE]',
        '[PICKUP_TIME]',
        '[TRACK_LINK]'
      ]
      keyListArr.forEach((item) => {
        if(data.content.includes(item)) {
          let value = ''
          switch (item) {
            case '[FLEET_NAME]':
              value = this.props.auth.selectedFleet.name || ''
              break;            
            case '[FLEET_PHONE]':
              value = this.props.auth.selectedFleet.phone || ''
              break;            
            case '[FLEET_EMAIL]':
              value = this.props.auth.selectedFleet.email || ''
              break;            
            case '[BOOKING_ID]':
              value = this.props.bookInfo.bookId || ''
              break;            
            case '[PASSENGER_FIRST_NAME]':
              value = this.props.bookInfo.psgInfo && this.props.bookInfo.psgInfo.firstName && this.props.bookInfo.psgInfo.firstName || ''
              break;            
            case '[PASSENGER_LAST_NAME]':
              value = this.props.bookInfo.psgInfo && this.props.bookInfo.psgInfo.lastName && this.props.bookInfo.psgInfo.lastName || ''
              break;            
            case '[PASSENGER_PHONE]':
              value = (this.props.bookInfo.psgInfo && this.props.bookInfo.psgInfo.phone && this.props.bookInfo.psgInfo.phone !== 'No Phone' ? this.props.bookInfo.psgInfo.phone : '') || ''
              break;            
            case '[DRIVER_FIRST_NAME]':
              value = this.props.bookInfo.drvInfo && this.props.bookInfo.drvInfo.firstName && this.props.bookInfo.drvInfo.firstName || ''
              break;            
            case '[DRIVER_LAST_NAME]':
              value = this.props.bookInfo.drvInfo && this.props.bookInfo.drvInfo.lastName && this.props.bookInfo.drvInfo.lastName || ''
              break;            
            case '[DRIVER_PHONE]':
              value = (this.props.bookInfo.drvInfo && this.props.bookInfo.drvInfo.phone && this.props.bookInfo.drvInfo.phone !== 'No Phone' ? this.props.bookInfo.drvInfo.phone : '') || ''
              break;            
            case '[PICKUP_TIME]':
              let result = ''
              if(this.props.bookInfo.request.pickUpTime !== 'Now') {
                let timePickup = moment.tz(this.props.bookInfo.request.pickUpTime, this.props.auth.selectedFleet.timezone).toString()
                result = timePickup.slice(0, timePickup.indexOf('GMT') - 1)
              }
              value = this.props.bookInfo.request.pickUpTime === 'Now' ? 'Now' : result
              break;             
            case '[TRACK_LINK]':
              value = `${this.props.auth.selectedFleet.trackLink}/${this.props.bookInfo.token}` || ''
              break;
          }
          data.content = data.content.replaceAll(item, value)
        }
      })
      this.setState({isSend: true});
      if (this.state.smsWillSend.content.length === 0 || this.state.smsWillSend.phone.length === 0 || this.state.smsWillSend.subject.length === 0) return;
      if (Validation.validateSearchingPhone(data.phone) === false) {
        return this.context.notification(
          'error',
          I18n.t('errors.24001')
        );
      } else {
        data.fleetId = this.props.auth.selectedFleet.fleetId;
        this.setState({disabledSpam: true})
        this.props.settingActions
          .sendMessageBooking(data)
          .then((data) => {
            if(data && data.error === null) {
              this.context.notification(
                'success',
                I18n.t('report.result.bookingDetails.successSendMessage')
              )
              this.sendSMSBooking()
              return this.setState({disabledSpam: false})
            } else {
              this.context.notification(
                'error',
                data.error.message
              )
              return this.setState({disabledSpam: false})
            }
          })
      }
  }

  handleSearchSMSTemplate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.smsBookingTemplateQuery, [
      'search',
      'listAll',
      'list',
      'selectedObj'
    ]);
    if(params.listAll.length === 0 ) return
    if ((inputValue && inputValue.length > 0)) {
      params.search = inputValue;
      params.list = params.listAll.filter(item => item.name.toLowerCase().includes(inputValue.toLowerCase()))
      this.setState({smsBookingTemplateQuery: {...this.state.smsBookingTemplateQuery, ...params}})
    } else {
      params.search = '';
      params.list = params.listAll
      this.setState({smsBookingTemplateQuery: {...this.state.smsBookingTemplateQuery, ...params}})
    }
    return callback()
  };


  handleChangeRecipientType = (value) => {
    this.setState({
      smsWillSend: {
        ...this.state.smsWillSend,
        typeRecipient: value,
        phone: value === 'driver' ? this.props.bookInfo.drvInfo.phone : this.props.bookInfo.psgInfo.phone
      }
    })
  }

  handleContentChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, content: e} });  
  }

  handlePhoneChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, phone: e.target.value} });
  }

  handleSubjectChange = (e) => {
    this.setState({ smsWillSend: {...this.state.smsWillSend, subject: e.target.value} });
  }

  getLocationService = () => {
    const { auth, common } = this.props;
    const { pickupLocation, destinationLocation } = common;
    let options = {
      fleetId: auth.selectedFleet && auth.selectedFleet.fleetId,
    };
    if (pickupLocation) {
      options = {
        ...options,
        from: {
          geo: [pickupLocation.lng, pickupLocation.lat],
          zipCode: pickupLocation.zipCode,
        },
        zipCodeFrom: pickupLocation.zipCode,
      };
    }
    if (destinationLocation && destinationLocation.lat) {
      options = {
        ...options,
        to: {
          geo: [destinationLocation.lng, destinationLocation.lat],
          zipCode: destinationLocation.zipCode,
        },
        zipCodeTo: destinationLocation.zipCode,
      };
      // if (this.state.extraDestination && this.state.extraDestination.lat) {
      //   options = {
      //     ...options,
      //     to: {
      //       geo: [this.state.extraDestination.lng, this.state.extraDestination.lat]
      //     },
      //     extra: [
      //       {
      //         geo: [this.state.locationDestination.lng, this.state.locationDestination.lat]
      //       }
      //     ]
      //   };
      // }
    }
    if (options.to || options.from) {
      this.props.intercityBookingActions
        .getService(options)
        .then((response) => {
          const data = response.res || {};
          if (data) {
            let newLocationservice = {
              additionalService: data.additionalService,
              extraAirport: data.extraAirport,
              fromAirport: data.fromAirport,
              toAirport: data.toAirport,
              currency: data.currency,
            };
            this.setState({
              locationService: newLocationservice,
            });
          }
        });
    }
  };

  initPsgInfo = (psgInfo) => {
    const { selectedFleet } = this.props;
    this.props.customerActions
      .findOneCustomer({
        userId: psgInfo.userId,
        fleetId: selectedFleet.fleetId,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.handleChangePsgInfo(
            _.pick(data.res, [
              'credits',
              // 'outStanding'
            ])
          );
          this.props.intercityBookingActions.updateCommonData({
            credits: data.res.credits,
          });
        }
      });
  };

  initPromocode = (data) => {
    const { selectedFleet } = this.props;
    const { promoCodeMessageContent } = this.state;
    if (data.promo && data.promo !== '') {
      this.props.bookingDetailActions
        .getPromotionCodeInfo({
          fleetId: selectedFleet.fleetId,
          code: data.promo,
        })
        .then((data) => {
          if (data.ok && data.res) {
            let paymentMethods = [];
            let minimumEstFare = '';
            let schedules = false;
            if (data.res.paymentMethodsApply.length > 0) {
              paymentMethods = data.res.paymentMethodsApply;
            }
            if (data.res.minimumEstFareApply.isLimited) {
              minimumEstFare = currencyFormatter.format(
                data.res.minimumEstFareApply.valueByCurrencies[0].value,
                {
                  code: data.res.minimumEstFareApply.valueByCurrencies[0]
                    .currencyISO,
                }
              );
            }
            if (data.res.scheduleEnable) {
              schedules = data.res.scheduleEnable;
            }
            this.setState({
              promoCodeMessageContent: {
                ...promoCodeMessageContent,
                paymentMethods,
                minimumEstFare,
                schedules,
              },
            });
          }
        });
    }
  };

  handleChangeForm = (key, value) => {
    const { request } = this.state;
    request[key] = value;
    this.setState({ request: { ...request } });
  };

  handleChangeOperatorNote = (e) => {
    const { request } = this.state;
    request.operatorNote = e.target.value;
    this.setState({ request: { ...request } });
  };

  handleChangeBookingReference = (e) => {
    this.setState({ 
      externalInfo: {
        ...this.state?.externalInfo,
        bookingReference: e.target.value
      }
    });
  };

  handleChangePsgInfo = (info) => {
    const { psgInfo } = this.state;
    this.setState({ psgInfo: { ...psgInfo, ...info } });
  };

  handleChangeDispatchType = (value) => {
    this.setState({ dispatchType: value, isDetachDrv: false });
  };

  handleChangeDrvInfo = (info) => {
    const { drvInfo } = this.state;
    this.setState({ drvInfo: { ...drvInfo, ...info }, isDetachDrv: false });
  };

  handleChangeETAFare = (
    fareEdited,
    originFare,
    reasonEditFare,
    isFareEdited
  ) => {
    let shortTrip = this.props?.auth?.selectedFleet?.waiveOffCommission?.enable ? 
      (fareEdited?.etaFare > this.props?.auth?.selectedFleet?.waiveOffCommission?.amountByCurrencies[0]?.value
      ? false : true) : originFare?.shortTrip;
    this.props.intercityBookingActions.updateCommonData({
      etaFare: {
        ...fareEdited,
        markupDifference: 0,
        reasonMarkup: null,
        markupValue: 0,
        shortTrip
      },
      originFare,
      reasonEditFare,
      isFareEdited,
    });
  };

  handleChangeMarkupPrice = (
    originFare,
    markupDifference,
    reasonMarkup,
    markupType,
    markupValue
  ) => {
    this.props.intercityBookingActions.updateCommonData({
      etaFare: {
        ...originFare,
        markupDifference,
        reasonMarkup,
        markupType,
        markupValue: parseFloat(markupValue),
      },
      originFare,
      isFareEdited: false,
      reasonEditFare: null,
    });
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      let oldMessages = this.state.errorMessages;
      if (!valid) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      this.setState({
        valid: { ...this.state.valid },
        errorMessages: oldMessages,
      });
    }
  };

  checkFromToFlight = () => {
    const { common } = this.props;
    const { locationService } = this.state;
    const { pickupLocation, destinationLocation } = common;
    const isFromAirport =
      pickupLocation &&
      locationService &&
      locationService.additionalService &&
      locationService.additionalService.fromAirport &&
      locationService.additionalService.fromAirport.isActive &&
      ((locationService.fromAirport && locationService.fromAirport._id) ||
        (locationService.airport && locationService.airport[0]));
    const isToAirport =
      destinationLocation &&
      locationService &&
      locationService.additionalService &&
      locationService.additionalService.toAirportNew &&
      locationService.additionalService.toAirportNew.isActive &&
      ((locationService.toAirport && locationService.toAirport._id) ||
        (locationService.airport && locationService.airport[1]));
    // const isToExtraAirPort = destinationLocation
    //   && this.state.extraDestination
    //   && this.props.newBooking
    //   && this.props.newBooking.locationService
    //   && this.props.newBooking.locationService.additionalService
    //   && (
    //     (this.props.newBooking.locationService.additionalService.toAirportNew
    //       && this.props.newBooking.locationService.additionalService.toAirportNew.isActive)
    //     || (this.props.newBooking.locationService.additionalService.fromAirport
    //       && this.props.newBooking.locationService.additionalService.fromAirport.isActive)
    //   )
    //   && (this.props.newBooking.locationService.extraAirport
    //     && this.props.newBooking.locationService.extraAirport[0]
    //     && this.props.newBooking.locationService.extraAirport[0]._id)
    // )
    return {
      isFromAirport: isFromAirport ? true : false,
      isToAirport: isToAirport ? true : false,
    };
  };

  updateBookingRequestObjectBuilder = () => {
    const {
      request,
      psgInfo,
      drvInfo,
      forceAssign,
      dispatchType,
      retryDispatch,
      locationService,
    } = this.state;
    const {
      common,
      auth,
      bookInfo,
      commonData: { location },
    } = this.props;
    const {
      pickupLocation,
      destinationLocation,
      routeSelected,
      carType,
      pickupAddress,
      pickupFrom,
      destinationFrom,
      destinationAddress,
      pickupTime,
      pickupDate,
      promo,
      etaFare,
      originFare,
      reasonEditFare,
      isFareEdited,
      luggageNumber,
      paxNumber,
      additionalService,
      additionalServices,
      tripType,
      flightService,
    } = common;
    const markupDifference = (etaFare && etaFare.markupDifference) || 0;
    const object = _.defaults(
      {
        dispatchType,
        dispatcherId: auth.user._id,
        notifyCustomerViaEmail: this.state.notifyCustomerViaEmail || false,
        forceAssign,
        retryDispatch,
        fleetId: auth.selectedFleet.fleetId,
        intercityInfo: {
          routeId: routeSelected._id, // Intercity route ID
          routeNumber: routeSelected.routeNumber, // enum: [1, 2]
        },
        request: {
          vehicleTypeRequest: carType.vehicleType,
          companyId: request.companyId,
          pickup: {
            geo: [pickupLocation.lng, pickupLocation.lat],
            city: pickupLocation.city,
            timezone: pickupLocation.timezone,
            cityName: pickupLocation.cityName,
            address: pickupAddress,
            zipCode: pickupLocation.zipCode,
            country: pickupLocation.countryCode,
            from:
              pickupFrom == LOCATION_TYPE.Google && location.isChina
                ? LOCATION_TYPE.Tencent
                : pickupFrom,
            instructionLink: pickupLocation.instructionLink || '',
            addressDetails: pickupLocation.addressDetails,
          },
          destination: {
            geo: [destinationLocation.lng, destinationLocation.lat],
            city: destinationLocation.city,
            cityName: destinationLocation.cityName,
            address: destinationAddress,
            zipCode: destinationLocation.zipCode,
            country: destinationLocation.countryCode,
            from:
              destinationFrom == LOCATION_TYPE.google && location.isChina
                ? LOCATION_TYPE.tencent
                : destinationFrom,
            instructionLink: destinationLocation.instructionLink || '',
            addressDetails: destinationLocation.addressDetails,
          },
          pickUpTime: moment(pickupDate)
            .hour(pickupTime.hour)
            .minute(pickupTime.minute)
            .format('YYYY-MM-DD HH:mm'),
          pickUpTimeFrom: request.pickUpTimeFrom,
          vehicleType: carType.dispatch || [carType._id],
          fleetId: auth.selectedFleet.fleetId,
          special: '',
          tip: 0,
          promo: promo ? promo.promoCode : '',
          promoValue: promo ? promo.promoValue : '',
          estimate: {
            distance: this.state.disDur ? this.state.disDur.distance.text : '',
            time: this.state.disDur ? this.state.disDur.duration.text : '',
            estimateValue: this.state.disDur
              ? this.state.disDur.duration.value
              : '',
            fare: etaFare || {},
            fare: etaFare
              ? {
                  ...etaFare,
                  unroundedTotalAmt:
                    etaFare.unroundedTotalAmt + markupDifference,
                  totalWithoutPromo:
                    etaFare.totalWithoutPromo + markupDifference,
                  etaFare: etaFare.etaFare + markupDifference,
                }
              : {},
            isNormalFare: etaFare ? etaFare.normalFare : true,
            markupType: etaFare ? etaFare.markupType : 'amount',
            markupValue: etaFare ? etaFare.markupValue : 0,
            reasonMarkup: etaFare ? etaFare.reasonMarkup : '',
            originFare: originFare || {},
            reasonEditFare: reasonEditFare || '',
            isFareEdited: isFareEdited || false,
          },
          type: 0,
          typeRate: 0,
          paymentType: request.paymentType,
          notes: request.notes,
          operatorNote: request.operatorNote,
          paxNumber: paxNumber || 1,
          luggageNumber: luggageNumber || 0,
          tripType: tripType,
        },
        externalInfo: {
          ...this.state?.externalInfo,
          bookingReference: this.state?.externalInfo?.bookingReference || ''
        },
        psgInfo: psgInfo,
        drvInfo: {},
        operator: {
          userId: auth.user._id,
          name: `${auth.user.firstName || ''} ${auth.user.lastName || ''}`,
          role:
            auth.user.userType == userType.CorporateAdmin ||
            auth.user.userType == userType.CorporateUser
              ? 'corporate'
              : 'cc',
        },
        bookId: bookInfo.bookId,
      },
      bookInfo
    );
    if (object.psgInfo.phone && object.psgInfo.phone.length >= 6) {
      let phone = '';
      for (let i = 0; i < object.psgInfo.phone.length; i++) {
        const c = object.psgInfo.phone[i];
        if (c == '+' || parseInt(c) == 0 || !!parseInt(c)) {
          phone += c;
        }
      }
      object.psgInfo.phone = phone;
    }

    const fromToFlight = this.checkFromToFlight();

    // check request type
    if (fromToFlight.isFromAirport) {
      object.request.type = 1;
    }
    if (this.isToAirport && object.request.type != 1) {
      object.request.type = 2;
    }

    // need to fix with transport because dont see FaFlightID
    if (fromToFlight.isFromAirport || fromToFlight.isToAirport) {
      object.request.moreInfo = {
        flightInfo: {
          flightNumber:
            locationService.additionalService.fromAirport.flightInfo ||
            locationService.additionalService.toAirportNew.flightInfo
              ? flightService.flightNumber
              : '',
          type: flightService.meetDriver === 0 ? 0 : 1,
        },
      };

      let flightScheduleCommon =
        (flightService && flightService.flightSchedule) || {};
      if (
        this.props.auth.selectedFleet.moduleSettings.flightAPIIntegration &&
        flightScheduleCommon &&
        flightScheduleCommon.ident
      ) {
        object.request.moreInfo.flightInfo.ident = flightScheduleCommon.ident;
        object.request.moreInfo.flightInfo.actual_ident =
          flightScheduleCommon.actual_ident;
        object.request.moreInfo.flightInfo.departuretime =
          flightScheduleCommon.departuretime;
        object.request.moreInfo.flightInfo.arrivaltime =
          flightScheduleCommon.arrivaltime;
        object.request.moreInfo.flightInfo.origin = flightScheduleCommon.origin;
        object.request.moreInfo.flightInfo.destination =
          flightScheduleCommon.destination;
        object.request.moreInfo.flightInfo.aircrafttype =
          flightScheduleCommon.aircrafttype;
        object.request.moreInfo.flightInfo.meal_service =
          flightScheduleCommon.meal_service;
        object.request.moreInfo.flightInfo.seats_cabin_first =
          flightScheduleCommon.seats_cabin_first;
        object.request.moreInfo.flightInfo.seats_cabin_business =
          flightScheduleCommon.seats_cabin_business;
        object.request.moreInfo.flightInfo.seats_cabin_coach =
          flightScheduleCommon.seats_cabin_coach;
      }
    }

    if (object.request.paymentType === paymentMethodNumber.personalCard) {
      object.psgInfo.creditInfo = _.pick(request.creditInfo, [
        'localToken',
        'crossToken',
        'cardMask',
      ]);
    }

    if (additionalServices && additionalServices.length) {
      object.request.services = tranformAdditionalServicesBeforeSubmit(
        additionalServices,
        additionalService,
        bookInfo.currencyISO
      );
    }

    if (this.state.isDetachDrv){
      object.isDetachDrv = true
      object.drvInfo = {
        phone: '',
      };
    } else {
      if (dispatchType == 1) {
          object.drvInfo = {
            phone: drvInfo.phone,
          };
      } else {
        object.drvInfo = {
          phone: '',
        };
      }
    }

    return object;
  };

  handleDetachDrv = (e) => {
    const defaultState = {...this.state}
    defaultState.drvInfo = {};
    defaultState.dispatchType = 0;
    defaultState.isDetachDrv = true;
    this.setState(defaultState);
  }

  checkRequiredField = () => {
    const { common } = this.props;
    const requiredFields = [
      'pickupLocation',
      'destinationLocation',
      'routeSelected',
      'carType',
      'pickupAddress',
      'pickupFrom',
      'destinationFrom',
      'destinationAddress',
      'pickupTime',
      'pickupDate',
      'etaFare',
    ];
    return _.some(requiredFields, (field) => _.isNil(common[field]));
  };

  handleSaveBookingClick = (e) => {
    e && e.preventDefault();
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }

    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    if (
      this.state.request.paymentType == paymentMethodNumber.personalCard &&
      !this.state.psgInfo.creditInfo
    ) {
      this.context.notification(
        'error',
        I18n.t('messages.booking.Please_select_or_add_a_card')
      );
      return;
    }
    this.handleUpdateBooking();
  };

  handleNotifyCustomerViaEmailChange = (e) => {
    this.setState({ notifyCustomerViaEmail: e.target.checked })
  }

  doUpdateBooking = () => {
    this.props.loadingActions.showLoadingSpiner();
    if (this.isFinished(this.state.data)) {
      // Call dispatch API to update booking finished
      const updateParams = {
        bookId: this.props.bookInfo.bookId,
        fleetId: this.props.bookInfo.fleetId,
        notes: _.get(this.state, 'request.notes'),
        operatorNote: _.get(this.state, 'request.operatorNote'),
        clientCaseMatter: this.props?.common?.corporateInfo?.clientCaseMatter,
        chargeCode: this.props?.common?.corporateInfo?.chargeCode,
        bookingReference: this.state?.externalInfo?.bookingReference || '',
      };
      this.props.bookingDetailActions
        .updateFinishedBooking(updateParams)
        .then((res) => {
          this.props.loadingActions.hideLoadingSpiner();
          this.handleResultUpdateFinishedBooking(res)
        });
    } else {
      const {
        auth: { selectedFleet },
        commonData,
        common,
      } = this.props;
      const { psgInfo } = this.state;
      if (psgInfo.phone) {
        let customerObject = {
          fleetId: selectedFleet.fleetId,
          phone: psgInfo.phone
            .replace(new RegExp(' ', 'g'), '')
            .replace(new RegExp('-', 'g'), ''),
          firstName: psgInfo.firstName || '',
          lastName: psgInfo.lastName || '',
          email: psgInfo.email || '',
          rank: psgInfo.rank || '',
          tips: selectedFleet.fleetFareId ? selectedFleet.fleetFareId.tips : 0,
        };
        // SL-23585 Save 'company' for customer by Company of Booking request
        if (common.companyId) {
          let companyData = [...commonData.companies, ...commonData.suppliers].find(
            (c) => c._id === common.companyId
          );
          if (companyData) {
            customerObject.company = {
              companyId: companyData._id,
              name: companyData.name,
            };
          }
        }
        this.props.newbookingActions
          .checkCustomerBooking(customerObject)
          .then((data) => {
            if (data.ok && data.res && data.res.customer) {
              if (!this.state.psgInfo) {
                this.state.psgInfo = data.res.customer;
              }
              const bookingObj = this.updateBookingRequestObjectBuilder();
              if (
                this.state.status == 'engaged' ||
                this.state.status == 'droppedOff'
              ) {
                const changePaymentObject = {
                  paymentType: bookingObj.request.paymentType,
                  bookId: bookingObj.bookId,
                  fleetId: selectedFleet.fleetId,
                  operator: bookingObj.operator,
                  notes: _.get(this.state, 'request.note'),
                  operatorNote: _.get(this.state, 'request.operatorNote'),
                  clientCaseMatter: this.props?.common?.corporateInfo?.clientCaseMatter,
                  chargeCode: this.props?.common?.corporateInfo?.chargeCode,
                };
                if (bookingObj.request.paymentType == paymentMethodNumber.corporateCard) {
                  changePaymentObject.creditInfo = bookingObj.corporateInfo.creditInfo;
                }
                if (bookingObj.request.paymentType == paymentMethodNumber.personalCard) {
                  changePaymentObject.creditInfo = bookingObj.psgInfo.creditInfo;
                }
                this.props.bookingDetailActions.changePaymentTypeBooking(changePaymentObject)
                .then(res => {
                  this.handleResultUpdateBooking(res)
                })
              } else {
                this.props.bookingDetailActions.updateBooking(bookingObj).then((res) => {
                  this.handleResultUpdateBooking(res);
                });
              }
            } else {
              this.props.loadingActions.hideLoadingSpiner();
              this.context.notification(
                'error',
                I18n.t('messages.booking.Check_customer_error'),
                data.message
              );
            }
          });
      } else {
        const bookingObj = this.updateBookingRequestObjectBuilder();
        if (bookingObj) {
          this.props.bookingDetailActions.updateBooking(bookingObj)
          .then(res => {
            this.handleResultUpdateBooking(res)
          })
        } else {
          this.props.loadingActions.hideLoadingSpiner();
        }
      }
    }
  };

  doUpdateAndDispatch = () => {
    this.setState({ retryDispatch: true }, this.doUpdateBooking);
  };

  handleResultUpdateBooking = (data) => {
    this.props.loadingActions.hideLoadingSpiner();
    if (data.code == 200 || data.returnCode == 200) {
      this.context.notification(
        'success',
        I18n.t('messages.booking.Update_booking_success')
      );
      this.close();
    } else {
      const errMgs = this.showMessageErrorBooking(data);
      if (errMgs) {
        this.context.notification('error', errMgs);
      }
    }
  }

  showMessageErrorBooking = (data) => {
    let msg = (
      <Translate value="messages.booking.data_incorect" dangerousHTML />
    );
    const msgCommon = getMsgCreateUpdateBook(data)
    if(msgCommon) return msgCommon

    if(data.error?.externalId === false) {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
      return msg;
    }
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          const min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
        if (data.error.overlapTime) {
          // this.showConfirmForceOverlap();
          msg = I18n.t('messages.booking.driver_is_not_available');
        } else if (data.error.cannotAssignDriver) {
          msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
        } else if (dataInfo.seatsLuggageLimit === 0) {
          msg = I18n.t('messages.booking.seats_luggage_limit');
        } else if (dataInfo.fullCapacity === 0) {
          msg = I18n.t('messages.booking.full_capacity');
        }
      } else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      const dataInfo = data.info || data.error || {};
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (dataInfo.sameZone === false && dataInfo.crossZone === false) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        msg = I18n.t('messages.booking.driver_is_not_available');
      } else if (dataInfo.seatsLuggageLimit === 0) {
        msg = I18n.t('messages.booking.seats_luggage_limit');
      } else if (dataInfo.fullCapacity === 0) {
        msg = I18n.t('messages.booking.full_capacity');
      } else if (dataInfo.promo) {
        switch (dataInfo.promo) {
          case 10:
            msg = I18n.t('messages.promoMsg.430');
            break;
          case 11:
            msg = I18n.t('messages.promoMsg.431');
            break;
          case 12:
            msg = I18n.t('messages.promoMsg.432');
            break;
          case 13:
            msg = I18n.t('messages.promoMsg.433');
            break;
          default:
            break;
        }
      }
    }
  };

  verifyStripeSCA = (clientSecret) => {
    const { auth } = this.props;
    const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
    stripe.handleCardPayment(clientSecret).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.props.loadingActions.hideLoadingSpiner();
        const errorMessage = result.error.message || result.error.code;
        this.context.notification('error', errorMessage);
      }
    });
  };

  close = () => {
    this.props.intercityBookingActions.closeIntercityBookingForm();
    this.props.closeBookingDialog();
  };

  handleUpdateBooking = () => {
    this.setState({
      showConfirmUpdate: true,
      retryDispatch: false,
    });
  };

  handleCloseConfirmUpdate = () => {
    this.setState({
      showConfirmUpdate: false,
      retryDispatch: false,
    });
  };

  showConfirmForceOverlap = () => {
    this.setState({
      showConfirmForceOverlap: {
        title: I18n.t('bookingdetail.confirm_force_overlap'),
        body: I18n.t('bookingdetail.confirm_force_overlap_message'),
        buttonTitle: I18n.t('bookingdetail.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('bookingdetail.No'),
      },
      forceAssign: true,
    });
  };

  handleCloseConfirmForceOverlapModal = () => {
    this.setState({ showConfirmForceOverlap: null, forceAssign: false });
  };

  handleConfirmForceOverlapClick = () => {
    this.doUpdateBooking();
    this.setState({ showConfirmForceOverlap: null });
  };

  handleIncidentBooking = (params) => {
    const dataRequest = {
      bookId: this.props.bookInfo.bookId,
      reason: params.reason,
      refundFare: params.refundFare,
      fleetId: this.props.auth.selectedFleet.fleetId,
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.incidentBooking(dataRequest)
    .then(res => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification('error', I18n.t('messages.booking.incident_fail'));
      }
    })
  };

  handleCancelBooking = (params) => {
    const dataRequest = {
      bookId: this.props.bookInfo.bookId,
      reason: params.reason,
      refundFare: params.refundFare,
      notifyCustomerViaEmail: checkCorporateUser(this.props.auth?.user) ? true : params.hasNotifyCancelEmail,
      fleetId: this.props.auth.selectedFleet.fleetId,
      canceller:
        this.props.auth.user.roles.roleName == 'corporateAdmin'
          ? 'CorpAD'
          : 'CC',
      operator: {
        userId: this.props.auth.user._id,
        name: `${this.props.auth.user.firstName || ''} ${
          this.props.auth.user.lastName || ''
        }`,
      },
    };
    this.props.loadingActions.showLoadingSpiner();
    this.props.bookingDetailActions.cancelBooking(dataRequest)
    .then(res => {
      this.props.loadingActions.hideLoadingSpiner();
      if (res.returnCode == 200) {
      //   socketDispatchApi.emit(socketConfigs.send.trip.cancel, {
      //     tripId: this.props.bookInfo.tripId,
      //     reason: params.reason,
      //     canceller: this.props.auth.user.roles.roleName == 'corporateAdmin' ? 'CorpAD' : 'CC',
      //     operator: {
      //         userId: this.props.auth.user._id,
      //         name: `${this.props.auth.user.firstName || ''} ${this.props.auth.user.lastName || ''}`
      //     }
      // });
        this.context.notification(
          'success',
          I18n.t('messages.booking.Cancel_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.context.notification('error', I18n.t('messages.booking.cancle_fail'));
      }
    })
  };

  isNewBookingAble = (booking) => {
    const { permissions } = this.props;
    if (booking.pricingType === 1) {
      return false;
    }
    if (permissions && !permissions.actions) return false;
    return true;
  };

  isUpdateBookingAble = (booking) => {
    const { permissions } = this.props;

    if (this.isFinished()) {
      return true;
    } else {
      if (booking.pricingType === 1) {
        return false;
      }
      if (permissions && !permissions.actions) return false;
      return true;
    }
  };

  isCancelAble = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    return true;
  };

  isIncidentAble = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    return true;
  };

  isCompleteAble = (booking) => {
    const { permissions } = this.props;
    if (permissions && !permissions.actions) return false;
    return true;
  };

  newBookingButtonClick = () => {
    let prevState = _.pick(this.state, [
      'request',
      'psgInfo',
      'driverInfo',
      'dispatchType',
      'corporateInfo',
      'corporateUserInfo',
      'travelerType'
    ]);
    // transform payment method
    const paymentMethods = [1, 2]; // only support Cash, Credit
    if (paymentMethods.indexOf(prevState.request.paymentType) === -1) {
      prevState.request.paymentType = 0;
    }
    if (prevState.request.tripType === 'requested') {
      prevState.request.tripType = 'scheduled';
      prevState.request.departureTimeTo = {};
    }
    this.props.intercityBookingActions.updateCommonData({
      ...this.props.common,
      flightService: {},
      originFare: {},
      isFareEdited: false,
      isFareEditedBefore: false,
      reasonEditFare: '',
    });
    this.props.intercityBookingActions.newBookingFromBooking(prevState);
    this.props.closeBookingDialog();
  };

  isFinished = () => {
    return (
      !!this.props.bookInfo.finishedAt ||
      finishedStatusList.some((i) => i.value === this.props.bookInfo.status)
    );
  };

  render() {
    const { bookInfo } = this.props;
    const {
      request,
      psgInfo,
      drvInfo,
      dispatchType,
      valid,
      isSubmited,
      promoCodeMessageContent,
    } = this.state;
    const isViewOnly = bookInfo.status !== 'confirmed';
    let allowUpdateBooking = true;
    if (
      !this.isFinished() &&
      ['confirmed', 'booked'].findIndex((e) => e === bookInfo.status) === -1
    ) {
      allowUpdateBooking = false;
    }

    const isBookingCompleted =
      bookInfo.completedInfo &&
      bookInfo.completedInfo.total >= 0 &&
      bookInfo.status === 'completed'
        ? true
        : false;
    const isFareEdited =
      bookInfo.request.estimate && bookInfo.request.estimate.isFareEdited
        ? bookInfo.request.estimate.isFareEdited
        : false;
      const transformBooking = transformDataBooking({
        booking: this.props.bookInfo,
        commonData: this.props.commonData,
        keys: ['status']
      })
    return (
      <Modal
        show={!!this.props.show}
        onHide={this.close}
        backdrop
        dialogClassName="book-detail-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate
              value="bookingdetail.Booking_detail"
              className="white-text"
            />
            <span className="white-text">:</span> #{this.props.bookInfo.bookId}{' '}
            <span className="white-text">-</span>{' '}
            <Translate
              className="white-text text-tranform-none"
              value={`statusDisplay.${transformBooking.status}`}
            />
          </Modal.Title>
          {/* <Image src={closeIcon} onClick={this.close} className="close" /> */}
        </Modal.Header>
        <Modal.Body key="intercityBookingForm" className={`intercityBookingForm ${this.state.showTrailNote ? 'show-trail-note' : ''}`}>
          <div className="bookingTitle">
            <span className="bookingTitle__text">
              <Translate value="Sidebar.Intercity" />
            </span>
          </div>
          <Container fluid>
            <Row>
              <Col xs={12} md={7} className="mt">
                <Container fluid>
                  <Row>
                    <Col xs={12} md={4} className="info-content">
                      <LocationInfoForm
                        onChange={this.handleChangeForm}
                        request={request}
                        drvInfo={drvInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        getLocationService={this.getLocationService}
                        isBookDetail
                        isViewOnly={isViewOnly}
                        status={this.props.bookInfo.status}
                      />
                    </Col>
                    <Col xs={12} md={4} className="info-content">
                      <FlightInfo
                        locationService={this.state.locationService}
                        flightScheduleSuggestions={
                          this.state.flightScheduleSuggestions
                        }
                        isBookDetail
                        isViewOnly={isViewOnly}
                      />
                      <TravelerInfoForm
                        onChange={this.handleChangePsgInfo}
                        user={this.props.user}
                        corporateCompany={this.props.corporateCompany}
                        selectedFleet={this.props.selectedFleet}
                        psgInfo={psgInfo}
                        valid={valid}
                        travelerType={this.state.travelerType}
                        corporateInfo={this.props.common.corporateInfo}
                        corporateUserInfo={this.state.corporateUserInfo}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                        isViewOnly={isViewOnly}
                      />
                      <SeatLuggageForm
                        valid={valid}
                        isSubmited={isSubmited}
                        request={request}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                        isViewOnly={isViewOnly}
                      />
                      <AdditionalServiceForm
                        onChange={this.handleChangeForm}
                        request={request}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                        isViewOnly={isViewOnly}
                      />
                      <NoteForm
                        onChange={this.handleChangeForm}
                        value={request.notes}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                      />
                    </Col>
                    <Col xs={12} md={4} className="info-content">
                      <PromotionForm
                        psgInfo={psgInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                        isViewOnly={isViewOnly}
                      />
                      <DispatchForm
                        onChange={this.handleChangeDispatchType}
                        onChangeDrvInfo={this.handleChangeDrvInfo}
                        dispatchType={dispatchType}
                        request={request}
                        drvInfo={drvInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        isBookDetail
                        isViewOnly={isViewOnly}
                        status={this.props.bookInfo.status}
                        handleDetachDrv={this.handleDetachDrv}
                      />
                      <PaymentForm
                        onChange={this.handleChangeForm}
                        handleChangeOperatorNote={this.handleChangeOperatorNote}
                        onChangePsgInfo={this.handleChangePsgInfo}
                        request={request}
                        psgInfo={psgInfo}
                        valid={valid}
                        isSubmited={isSubmited}
                        validatorCallback={this.validatorCallback}
                        corporateInfo={this.props.common?.corporateInfo}
                        isBookDetail
                        isViewOnly={isViewOnly}
                        travelerType={this.state.travelerType}
                        externalInfo={this.state.externalInfo}
                        handleChangeBookingReference={this.handleChangeBookingReference}
                      />
                      {isBookingCompleted && isFareEdited && (
                        <EditFareComponent
                          viewHistory={true}
                          etaFare={bookInfo.request.estimate.fare}
                          data={bookInfo}
                          prevEtaFare={this.props.common}
                          bookType={'INTERCITY'}
                        />
                      )}
                    </Col>
                  </Row>
                </Container>
                <Col xs={12}>
                  <div className="intercityBookingForm__buttonContainer">
                    <div className="fill text-center btnBookingDetail" >
                      {this.isNewBookingAble(bookInfo) ? (
                        <Button
                          onClick={this.newBookingButtonClick}
                          className="btn-send mr mb"
                        >
                          <Translate value="bookingdetail.Clone_Booking" />
                        </Button>
                      ) : null}
                      {allowUpdateBooking ? (
                        this.isUpdateBookingAble(bookInfo) ? (
                          <Button
                            className="btn-save mr mb"
                            onClick={this.handleSaveBookingClick}
                          >
                            <Translate value="newbooking.Update" />
                          </Button>
                        ) : null
                      ) : null}

                      <DispatchLogs data={bookInfo} />
                      {this.isCancelAble(bookInfo) ? (
                        <CancelButton
                          bookInfo={bookInfo}
                          onSubmit={this.handleCancelBooking}
                          bookingDetailActions={this.props.bookingDetailActions}
                          auth={this.props.auth}
                        />
                      ) : null}
                      {this.isIncidentAble(bookInfo) ? (
                        <IncidentButton
                          bookInfo={bookInfo}
                          onIncidentBooking={this.handleIncidentBooking}
                        />
                      ) : null}
                      {this.isCompleteAble(bookInfo) ? (
                        <CompleteButton bookInfo={bookInfo} />
                      ) : null}
                    </div>
                  </div>
                  <ul className="promotion_note">
                    {promoCodeMessageContent.schedules ? (
                      <li className="text-soft-warning">
                        <Translate value="bookingdetail.promotion_note_schedules" />
                      </li>
                    ) : null}
                    {promoCodeMessageContent.minimumEstFare !== '' ? (
                      <li className="text-soft-warning">
                        <Translate
                          value="bookingdetail.promotion_note_minimum_fare"
                          fare={promoCodeMessageContent.minimumEstFare}
                        />
                      </li>
                    ) : null}
                    {promoCodeMessageContent.paymentMethods.length > 0 ? (
                      <li className="text-soft-warning">
                        <Translate
                          value="bookingdetail.promotion_note_paymentMethod"
                          method={promoCodeMessageContent.paymentMethods
                            .map((p) => I18n.t(`General.patmentMethod_${p}`))
                            .join(', ')}
                        />
                      </li>
                    ) : null}
                  </ul>
                </Col>
              </Col>
              <Col xs={12} md={5} style={{ height: '100%' }}>
                <div className="booking-map-container">
                  <TopActionBtns
                    bookInfo={bookInfo}
                    auth={this.props.auth}
                    sendSMSBooking={this.sendSMSBooking}
                    // chargeInvoicing={this.showChargeInvoicingModal}
                    hasPermission={this.props?.permissions?.actions}
                  />
                  <MapForm />
                  <TripEstimate
                    request={request}
                    prevEtaFare={this.props.common}
                    handleChangeETAFare={this.handleChangeETAFare}
                    handleChangeMarkupPrice={this.handleChangeMarkupPrice}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <ConfirmUpdateModal
          visible={this.state.showConfirmUpdate}
          showWarningResetEditFare={this.state.showWarningResetEditFare}
          selectedFleet={this.props.auth.selectedFleet}
          onClose={this.handleCloseConfirmUpdate}
          bookInfo={bookInfo}
          onUpdate={this.doUpdateBooking}
          notifyCustomerViaEmail={this.state.notifyCustomerViaEmail}
          handleNotifyCustomerViaEmailChange={this.handleNotifyCustomerViaEmailChange}
        />
        <Confirm
          confirm={this.state.showConfirmForceOverlap}
          handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
          handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
        />
        <SendSMSBookingModal
              showSMSBooking={this.state.showSMSBooking}
              sendSMSBooking={this.sendSMSBooking}
              smsBookingTemplateQuery={this.state.smsBookingTemplateQuery}
              getSMSBookingTemplateList={this.getSMSBookingTemplateList}
              handleSelectSMSBookingTemplate={this.handleSelectSMSBookingTemplate}
              handleChangeMessageType={this.handleChangeMessageType}
              handleChangeRecipientType={this.handleChangeRecipientType}
              handleContentChange={this.handleContentChange}
              handlePhoneChange={this.handlePhoneChange}
              handleSubjectChange={this.handleSubjectChange}
              smsWillSend={this.state.smsWillSend}
              handleSendMessageClick={this.handleSendMessageClick}
              checkDriver={this.props.bookInfo.drvInfo && !_.isString(this.props.bookInfo.drvInfo) && this.props.bookInfo.drvInfo && this.props.bookInfo.drvInfo.userId.length > 0 ? true : false}
              checkCustomer={this.props.bookInfo.psgInfo && !_.isString(this.props.bookInfo.psgInfo) && this.props.bookInfo.psgInfo && this.props.bookInfo.psgInfo.userId.length > 0 ? true : false}
              valid={this.state.valid}
              ValidatorCallback={this.validatorCallback}
              handleSearchSMSTemplate={this.handleSearchSMSTemplate}
              disabledSpam={this.state.disabledSpam}
              isSend={this.state.isSend}
            />
      </Modal>
    );
  }
}
IntercityBookingDetail.contextTypes = {
  notification: PropTypes.func,
};
IntercityBookingDetail.propTypes = {
  auth: PropTypes.object,
  newBooking: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    auth: state.auth,
    socket: state.socket,
    bookInfo: state.bookingDetail.data,
    commonData: state.commonData,
    common: state.intercityBooking.common,
    permissions: state.menuHandle.modulePermission,
    corporateCompany: state.corporateCompany,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntercityBookingDetail);
