import ReportUtils from "../reports/base/utils";
import { Translate, I18n } from "react-redux-i18n";
import _ from 'lodash';

export const fields = [
  {
    key: "checkBox",
    label: "",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    width: 60,
    fixed: true
  },
  {
    key: "createdDate",
    label: "withdrawal.requestTime",
    fixed: true
  },
  {
    key: "referenceId",
    label: "withdrawal.referenceId",
    fixed: true
  },
  {
    key: "driverName",
    label: "withdrawal.driverName",
    fixed: true
  },
  {
    key: "driverPhone",
    label: "withdrawal.phoneNumber"
  },
  {
    key: "companyName",
    label: "withdrawal.company"
  },
  {
    key: 'channelCode',
    label: 'withdrawal.channelCode',
    width: 250
  },
  {
    key: 'channelName',
    label: 'withdrawal.channelName',
    width: 250
  },
  {
    key: "receiverAccount",
    label: "withdrawal.description",
    customCell: rowData => {
      if (!rowData) return "";

      if (rowData?.destination === "Xendit" && rowData?.type === 'EWALLET') {
        return I18n.t("withdrawal.transferToEWallet").format(rowData.receiverAccount);
      }

      if (rowData.destination === "WingBank") {
        return I18n.t("withdrawal.transferToWingBank").format(
          rowData.receiverAccount
        );
      }

      if (rowData.receiverAccount === "creditWallet") {
        return I18n.t("withdrawal.transferToCreditWallet");
      }

      return I18n.t("withdrawal.transferToBankAccount").format(
        rowData.receiverAccount
      );
    },
    width: 250
  },
  {
    key: "currentBalance",
    label: "withdrawal.currentBalance",
    width: 140,
    toolTip: 'report.result.driverWithdrawal.currentBalanceTooltip'
  },
  {
    key: "amount",
    label: "withdrawal.transferAmount",
    width: 140
  },
  {
    key: "currencyISO",
    label: "withdrawal.currency",
    width: 100
  },
  {
    key: "status",
    label: "appBanner.status",
    customCell: rowData => {
      if (!rowData.status) return "";

      return _.upperFirst(rowData.status);
    },
    width: 100
  },
  {
    key: "actions",
    label: "driver.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
