import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Col, Row, Button, ButtonToolbar, Modal, Image } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import closeIcon from '../../../assets/images/icons/close.svg';
import { userType } from '../../../constants/commondata';
import { tripFetchCarTypeByCompany, updateTripCommonData, closeTripDetailsForm } from '../../../actions/tripAction';
import * as intercityRateActions from '../../../actions/intercityRateActions';
import * as loadingActions from '../../../actions/loadingBarActions';
import LocationInfoForm from './LocationInfoForm';
import DriverInfoForm from './DriverInfoForm';
import BookingForm from './BookingForm';
import { socketDispatchApi } from '../../../utils/socketUtils';
import { socketConfigs } from '../../../constants/socketConfigs';
import CancelButton from './CancelButton';
import ConfirmUpdateModal from './TripActions/ConfirmUpdateModal';

const statusAllow = [
    "confirmed",
    "pending"
];

class TripDetails extends Component {
    constructor(props) {
        super();
        this.state = {
            isSubmited: false,
            drvInfo: {},
            valid: {},
            routes: undefined,
            companyId: props.user.roles.companyId || undefined,
        }
    };

    componentDidMount() {
        this.props.tripFetchCarTypeByCompany({
            companyId: this.props.user.roles.companyId,
            fleetId: this.props.auth.selectedFleet.fleetId,
        });
        this.onSocketDispatch();
        this.initData();
    };

    onSocketDispatch = () => {
        socketDispatchApi.remove(socketConfigs.receive.trip.cancel);
        socketDispatchApi.on(socketConfigs.receive.trip.cancel, data => {
            this.props.loadingActions.hideLoadingSpiner();
            if (data.code === 1) {
                this.context.notification(
                    'success',
                    I18n.t('messages.trip.cancel_trip_success')
                );
                this.close();
            } else {
                const errMgs = this.showMessageErrorBooking(data);
                if (errMgs) {
                    this.context.notification('error', errMgs);
                }
            }
        });
        socketDispatchApi.remove(socketConfigs.receive.trip.assignDriver);
        socketDispatchApi.on(socketConfigs.receive.trip.assignDriver, data => {
            this.props.loadingActions.hideLoadingSpiner();
            if (data.code === 1) {
                this.context.notification(
                    'success',
                    I18n.t('messages.trip.cancel_trip_success')
                );
                this.close();
            } else {
                const errMgs = this.showMessageErrorBooking(data);
                if (errMgs) {
                    this.context.notification('error', errMgs);
                }
            }
        });
    };


    initData = () => {
        const { tripInfo, selectedFleet } = this.props;
        const query = {
            limit: 20,
            page: 0,
            query: {
                fleetId: selectedFleet.fleetId,
                fromZoneId: _.get(tripInfo, 'routeFromZoneId'),
                toZoneId: _.get(tripInfo, 'routeToZoneId')
            },
        };

        this.props.intercityRateActions
            .findRouteWithCarType(query)
            .then(({ res }) => {
                this.setState({ routes: res.list });
                const tripRoute = res.list[0]
                if (tripRoute) {
                    const carType = tripRoute.carTypes.find(c => c.vehicleType === tripInfo.carTypeRequest);
                    let isValidCartype = true;
                    if (this.state.companyId) {
                        isValidCartype = this.props.trips.common.companyVehicleTypes.indexOf(carType.vehicleType) !== -1
                    }
                    this.props.updateTripCommonData({
                        carTypes: tripRoute.carTypes,
                        carType: isValidCartype ? carType : null,
                    });
                }
            });

        this.setState({
            drvInfo: tripInfo.drvInfo
        });
    };


    showMessageErrorBooking = (data) => {
        let msg = <Translate value="messages.trip.data_incorect" dangerousHTML />
        if (data.code) {

        } else {
            const dataInfo = data.info;
            switch (dataInfo.errorCode) {
                case "INTERNAL":
                    return msg = I18n.t('messages.trip.INTERNAL')
                case "NOT_FOUND":
                    return msg = I18n.t('messages.trip.NOT_FOUND')
                case "SAME_DRIVER":
                    return msg = I18n.t('messages.trip.SAME_DRIVER')
                case "OVERLAP":
                    return msg = I18n.t('messages.trip.OVERLAP')
                case "CANNOT_ASSIGN":
                    return msg = I18n.t('messages.trip.CANNOT_ASSIGN')
                default:
                    return msg;
            };
        }
        return msg;
    }

    close = () => {
        this.props.closeTripDetailsForm();
        this.props.closeBookingDialog();
    };

    handleCancelBooking = (params) => {
        socketDispatchApi.emit(socketConfigs.send.trip.cancel, {
            tripId: this.props.tripInfo.tripId,
            reason: params.reason,
            canceller: this.props.auth.user.roles.roleName == 'corporateAdmin' ? 'CorpAD' : 'CC',
            operator: {
                userId: this.props.auth.user._id,
                name: `${this.props.auth.user.firstName || ''} ${this.props.auth.user.lastName || ''}`
            }
        });
        this.props.loadingActions.showLoadingSpiner();
    };

    doUpdateTrip = () => {
        const { drvInfo } = this.state;
        const { tripInfo, selectedFleet } = this.props;

        this.props.loadingActions.showLoadingSpiner();
        const dataRequest = {
            tripId: tripInfo.tripId,
            assignee: {
                userId: drvInfo.userId
            }
        };
        if (dataRequest) {
            socketDispatchApi.emit(socketConfigs.send.trip.assignDriver, dataRequest);
        } else {
            this.props.loadingActions.hideLoadingSpiner();
        }
    };

    validatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            let oldMessages = this.state.errorMessages;
            if (!valid) {
                if (!oldMessages) oldMessages = [];
                oldMessages = oldMessages.concat(messages);
            }
            this.setState({
                valid: { ...this.state.valid },
                errorMessages: oldMessages,
            });
        }
    };

    handleChangeDrvInfo = (info) => {
        const { drvInfo } = this.state;
        this.setState({ drvInfo: { ...drvInfo, ...info } });
    };

    handleUpdateBooking = () => {
        this.setState({
            showConfirmUpdate: true
        });
    };

    handleCloseConfirmUpdate = () => {
        this.setState({
            showConfirmUpdate: false,
        });
    }

    handleSaveBookingClick = (e) => {
        e && e.preventDefault();
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true });
        }
        this.handleUpdateBooking();
    };

    isEditable = tripInfo => {
        const { permissions } = this.props;
        if (permissions && !permissions.actions) return false;
        if (statusAllow.indexOf(tripInfo.status) !== -1) {
            return true;
        };
        return false;
    };


    render() {
        const { auth, commonData, tripInfo } = this.props;
        const { valid, isSubmited, drvInfo } = this.state;
        let isViewOnly = false;
        if (statusAllow.indexOf(tripInfo.status) === -1) {
            isViewOnly = true
        };

        return (
            <Modal
                show={this.props.isShow}
                onHide={this.close}
                backdrop
                dialogClassName="book-detail-model"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Translate value="tripDetails.Trip_ID" className="white-text" />
                        <span className="white-text">:</span>
                        {' '}
                        #
                        {this.props.tripInfo.tripId}
                        {' '}
                        <span className="white-text">-</span>
                        {' '}
                        <Translate
                            className="white-text text-tranform-none"
                            value={`statusDisplay.${this.props.tripInfo.status}`}
                        />
                    </Modal.Title>
                    {/* <Image src={closeIcon} onClick={this.close} className="close" /> */}
                </Modal.Header>
                <Modal.Body key="intercityBookingForm" className="intercityBookingForm">

                    <Row>
                        <Col xs={12} md={5} className="mt">
                            <Row>
                                <Col xs={12} md={6} className="info-content">
                                    <LocationInfoForm
                                        onChange={this.handleChangeForm}
                                        tripInfo={tripInfo}
                                        valid={valid}
                                        isSubmited={isSubmited}
                                        validatorCallback={this.validatorCallback}
                                        isBookDetail
                                        isViewOnly={isViewOnly}
                                    />
                                </Col>
                                <Col xs={12} md={6} className="info-content">
                                    <DriverInfoForm
                                        onChange={this.handleChangeForm}
                                        tripInfo={tripInfo}
                                        drvInfo={drvInfo}
                                        valid={valid}
                                        isSubmited={isSubmited}
                                        validatorCallback={this.validatorCallback}
                                        isBookDetail
                                        isViewOnly={isViewOnly}
                                        onChangeDrvInfo={this.handleChangeDrvInfo}
                                    />
                                </Col>
                            </Row>
                            <Col xs={12} md={6} mdOffset={6} className="intercityBookingForm__buttonContainer">
                                {this.isEditable(tripInfo) ? (
                                    <ButtonToolbar className="text-center">
                                        <Button
                                            className="btn-save mr"
                                            onClick={this.handleSaveBookingClick}
                                        >
                                            <Translate value="tripDetails.updateTrip" />
                                        </Button>
                                        <CancelButton tripInfo={tripInfo} onSubmit={this.handleCancelBooking} />
                                    </ButtonToolbar>
                                ) : null}
                            </Col>
                        </Col>
                        <Col xs={12} md={7} style={{ height: '100%' }} className="mt">
                            <div className="info-content">
                                <BookingForm
                                    tripInfo={tripInfo}
                                    selectedFleet={this.props.selectedFleet}
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <ConfirmUpdateModal
                    visible={this.state.showConfirmUpdate}
                    onClose={this.handleCloseConfirmUpdate}
                    tripInfo={tripInfo}
                    onUpdate={this.doUpdateTrip}
                    onUpdateAndDispatch={this.doUpdateAndDispatch}
                />
                {/*<Confirm
                    confirm={this.state.showConfirmForceOverlap}
                    handleConfirmCloseClick={this.handleCloseConfirmForceOverlapModal}
                    handleConfirmButtonClick={this.handleConfirmForceOverlapClick}
                /> */}
            </Modal>
        )
    }
};

TripDetails.contextTypes = {
    notification: PropTypes.func,
};
TripDetails.propTypes = {
    auth: PropTypes.object,
    // newBooking: PropTypes.object,
};


function mapStateToProps(state) {
    return {
        user: state.auth.user,
        selectedFleet: state.auth.selectedFleet,
        auth: state.auth,
        socket: state.socket,
        permissions: state.menuHandle.modulePermission,
        commonData: state.commonData,
        trips: state.trips
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadingActions: bindActionCreators(loadingActions, dispatch),
        intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
        tripFetchCarTypeByCompany: options => dispatch(tripFetchCarTypeByCompany(options)),
        updateTripCommonData: options => dispatch(updateTripCommonData(options)),
        closeTripDetailsForm: () => dispatch(closeTripDetailsForm())
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TripDetails);
