import React, { Component } from "react";
import {
  Modal,
  FormControl,
  Form,
  Button,
  Row,
  Col,
  FormGroup
} from "react-bootstrap";
import { debounce } from "lodash";

import { Translate, I18n } from "react-redux-i18n";
import CCDropDown from "../../../../components/dropdown/ccDropDown";

class SpecificDriver extends Component {
  constructor(props) {
    super(props);
  }

  handleScrollBottom = e => {
    let element = e.target;
    if (element) {
      if (element.scrollHeight - element.scrollTop <= element.clientHeight) {
        this.props.handleListScroll();
      }
    }
  };

  render() {
    const {
      handleShowBoxClick,
      handleDriverInputChange,
      handleSearchDriver,
      handleSelectItem,
      checkExistInList,
      checkFilterRender,
      addDriversSelectedToList,
      handlePermitFilterChange,
      removeCarDriverInSelectedList,
      handleSaveCarDriverSelectedList,
      handleSelectCompany,
      handleSelectCarType,
      search,
      driverList,
      strPermittedFilter,
      selectedDrivers,
      specificType,
      selectedCompany,
      selectedCarType,
      companies,
      carTypes,
      actions
    } = this.props;
    return (
      <Modal
        show={true}
        backdrop={"static"}
        onHide={() => {
          handleShowBoxClick(specificType);
        }}
        className="specific-permission-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="permission.DRIVERS" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={6} md={6} className="assign-container">
              <FormGroup className="assign-select">
                <div className="display-flex justify-content-between align-items-center">
                  {/* <Translate value="permission.Driver_list" /> */}
                  <div>
                    <CCDropDown
                      id="cartype-filter-dropdown"
                      valueKey="_id"
                      labelKey="vehicleType"
                      items={carTypes}
                      title={I18n.t("report.query.vehicleType")}
                      selectedItems={selectedCarType}
                      onSelect={handleSelectCarType}
                      applyButton={() => handleSearchDriver(specificType)}
                      noTranslateLabel
                      searchable
                      multiSelect
                      enableAllItemSelect
                      onlyShowAllText
                      footer
                      disabled={!actions}
                    />
                    <CCDropDown
                      id="company-filter-dropdown"
                      valueKey="_id"
                      labelKey="name"
                      items={companies}
                      title={I18n.t("report.query.company")}
                      selectedItems={selectedCompany ? [selectedCompany] : []}
                      onSelect={v => handleSelectCompany(v, specificType)}
                      noTranslateLabel
                      searchable
                      disabled={!actions}
                    />
                  </div>
                </div>
                <FormControl
                  type="text"
                  className={"form-custom mb-md"}
                  onChange={handleDriverInputChange}
                  onKeyPress={e =>
                    e.key === "Enter" ? handleSearchDriver(specificType) : null
                  }
                  value={search}
                  placeholder={I18n.t("permission.Enter_to_search")}
                  disabled={!actions}
                />
                <div style={{ position: "relative" }}>
                  <FormControl
                    as="select"
                    className={"form-custom"}
                    multiple
                    onChange={e => {
                      handleSelectItem(specificType, e);
                    }}
                    onScroll={this.handleScrollBottom}
                    disabled={!actions}
                  >
                    {driverList.map(item => {
                      if (!checkExistInList(specificType, item)) {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.fullName}
                          </option>
                        );
                      }
                    })}
                  </FormControl>
                  <div className="add-item-to-permitted">
                    <i
                      className="fa fa-arrow-circle-right"
                      aria-hidden="true"
                      onClick={() => {
                        actions && addDriversSelectedToList(specificType);
                      }}
                      disabled={!actions}
                    />
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col xs={6} md={6}>
              <FormGroup className="pt">
                <Form.Label>
                  <Translate value="permission.Permitted_driver_list" />
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  onChange={handlePermitFilterChange}
                  value={strPermittedFilter}
                  placeholder={I18n.t("permission.Enter_to_search")}
                  disabled={!actions}
                />
              </FormGroup>
              <ul className="compiled-list">
                {selectedDrivers.map(item => {
                  if (
                    checkFilterRender(strPermittedFilter, item, specificType)
                  ) {
                    return (
                      <li key={item._id}>
                        {item.fullName}
                        {
                          actions && <a>
                            <i
                              className="fa fa-remove"
                              aria-hidden="true"
                              onClick={() => {
                                removeCarDriverInSelectedList(specificType, item);
                              }}
                            />
                          </a>
                        }
                      </li>
                    );
                  }
                })}
              </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            actions && <Button
              className={"btn-save mr-md"}
              onClick={() => {
                handleSaveCarDriverSelectedList(specificType);
              }}
            >
              <Translate value="permission.Save" />
            </Button>
          }
          <Button
            className={"btn-cancel"}
            onClick={() => {
              handleShowBoxClick(specificType);
            }}
          >
            <Translate value="permission.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SpecificDriver;
