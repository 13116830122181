/* global google */
import React, { Component } from 'react'
import defaultStyles from '../placesAutocomplete/defaultStyles'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as newbookingActions from '../../actions/newbookingAction'
import AutoCompleteItem from '../placesAutocomplete/AutoCompleteItem';
import {
    Image
} from 'react-bootstrap'
import close_icon from './../../assets/images/icons/close_icon.svg'

const placeTypes = {
    pu: ['all', 'pu'],
    do: ['all', 'do']
};

const locationTypes = {
    single: 'single',
    multiple: 'multiple'
};

class Places3partyAutocomplete extends Component {
    constructor(props) {
        super(props)

        this.state = {
            autocompleteItems: [],
            valueChanged: false
        }
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.inputonBlurHandle = this.inputonBlurHandle.bind(this);
        this.handleRemoveAddress = this.handleRemoveAddress.bind(this);
        this.getReportThirdPartyLocationDebounce = _.debounce((searchText) => this.getReportThirdPartyLocation(searchText), 250);
    }

    clearAutocomplete = () => {
        this.setState({ autocompleteItems: [] })
    }

    handleInputChange = (event) => {
        this.state.valueChanged = true;
        this.props.onChange(event.target.value)
        if (!event.target.value) {
            this.clearAutocomplete()
            return
        }
        const searchText = event.target.value;
        this.getReportThirdPartyLocationDebounce(searchText);
    }

    getReportThirdPartyLocation = (_value) => {
        this.props.newbookingActions.getReportThirdPartyLocation({
            "fleetId": this.props.auth.selectedFleet.fleetId,
            "criteria": {
                "txtSearch": _value,
                'placeType': this.props.pickUp ? 'pu' : 'do'
            }
        }).then(data => {
            if (data && data.ok && data.res) {
                const { list = [] } = data.res || {};
                this.setState({
                    autocompleteItems: list.length == 0 ? [] : data.res.list.map((item, index) => {
                        return {
                            ...item,
                            index
                        }
                    })
                })
            }
        })
    }

    autocompleteItemStyle(active) {
        if (active) {
            return { ...defaultStyles.autocompleteItemActive, ...this.props.styles.autocompleteItemActive }
        } else {
            return {}
        }
    }

    selectAddress = (address, data) => {
        this.clearAutocomplete()
        this.handleSelect(address, data)
    }

    handleSelect = (address, data) => {
        this.props.onSelect ? this.props.onSelect(address, data) : this.props.onChange(address)
    }

    getActiveItem() {
        return this.state.autocompleteItems.find(item => item.active)
    }

    selectActiveItemAtIndex(index) {
        this.setActiveItemAtIndex(index)
        const activeItem = this.state.autocompleteItems ? this.state.autocompleteItems.find(item => item.index === index) : null;
        if (activeItem) this.props.onChange(activeItem.location.address)
    }

    handleEnterKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.handleEnterKeyWithoutActiveItem()
        } else {
            this.selectAddress(activeItem.location.address, activeItem)
        }
    }

    handleEnterKeyWithoutActiveItem() {
        if (this.props.onEnterKeyDown) {
            this.props.onEnterKeyDown(this.props.value)
            this.clearAutocomplete()
        } else {
            return //noop
        }
    }

    handleDownKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.selectActiveItemAtIndex(0)
        } else {
            const nextIndex = (activeItem.index + 1) % this.state.autocompleteItems.length
            this.selectActiveItemAtIndex(nextIndex)
        }
    }

    handleUpKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.selectActiveItemAtIndex(this.state.autocompleteItems.length - 1)
        } else {
            let prevIndex
            if (activeItem.index === 0) {
                prevIndex = this.state.autocompleteItems.length - 1
            } else {
                prevIndex = (activeItem.index - 1) % this.state.autocompleteItems.length
            }
            this.selectActiveItemAtIndex(prevIndex)
        }
    }

    handleInputKeyDown(event) {
        const ARROW_UP = 38
        const ARROW_DOWN = 40
        const ENTER_KEY = 13
        const ESC_KEY = 27

        switch (event.keyCode) {
            case ENTER_KEY:
                event.preventDefault()
                this.handleEnterKey()
                break
            case ARROW_DOWN:
                event.preventDefault() // prevent the cursor from moving
                this.handleDownKey()
                break
            case ARROW_UP:
                event.preventDefault() // prevent the cursor from moving
                this.handleUpKey()
                break
            case ESC_KEY:
                this.clearAutocomplete()
                break
        }
    }

    setActiveItemAtIndex = (index) => {
        this.setState({
            autocompleteItems: this.state.autocompleteItems.map((item, idx) => {
                if (idx === index) {
                    return { ...item, active: true }
                } else {
                    return { ...item, active: false }
                }
            }),
        })
    }

    autocompleteItemStyle(active) {
        if (active) {
            return { ...defaultStyles.autocompleteItemActive, ...this.props.styles.autocompleteItemActive }
        } else {
            return {}
        }
    }

    autocompleteClassName() {
        if (this.props.classNames) {
            return this.props.classNames.autocompleteContainer || '';
        } else {
            return '';
        }
    }

    renderAutocomplete() {
        const { autocompleteItems } = this.state
        if (autocompleteItems.length === 0) { return null }
        const { pickUp } = this.props;
        return (
            <div
                id="PlacesAutocomplete__autocomplete-container"
                className="autocomplete__cover"
            >
                <div className="autocomplete__container">
                    {
                        autocompleteItems.map((p, idx) => {
                            const { locationType, locations } = p || {};
                            if (locationType && locationType === locationTypes.multiple) {
                                const noLocations = locations ? locations.filter(l => {
                                    return (pickUp && placeTypes.pu.indexOf(l.placeType) > -1) || (!pickUp && placeTypes.do.indexOf(l.placeType) > -1)
                                }) : null;
                                if (!noLocations || !noLocations.length) return null;
                            }
                            return (
                                <AutoCompleteItem
                                    onMouseDown={this.selectAddress}
                                    suggestItem={p}
                                    key={idx}
                                    pickUp={pickUp}
                                />
                            )
                        })
                    }
                </div>
            </div>

        )
    }
    inputonBlurHandle(e) {
        this.clearAutocomplete();
        let activeItem = this.getActiveItem()
        if (activeItem) {
            this.handleSelect(activeItem.location.address, activeItem)
        }

    }
    renderInput() {
        const { classNames, placeholder, styles, value, autoFocus, inputName, inputId, disabled } = this.props
        return (
            <input
                type="text"
                placeholder={placeholder}
                className={'form-control form-custom third-party'}
                value={value}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
                style={styles.input}
                autoFocus={autoFocus}
                name={inputName || ''}
                id={inputId || ''}
                disabled={disabled || false}
            />
        )
    }
    handleRemoveAddress() {
        const ESC_KEY = 27
        if (!this.props.disabled) {
            this.clearAutocomplete();
            this.props.onChange(null)
        }
    }

    render() {
        const { classNames, value } = this.props
        return (
            <div className={"autoplaces-input " + classNames}>
                {this.renderInput()}
                {
                    value && <Image
                        className="remove-icon"
                        src={close_icon}
                        onClick={this.handleRemoveAddress}
                    />
                }
                {/* <span className="remove-icon" disabled={this.props.disabled || false} onClick={this.handleRemoveAddress}>
                    <i disabled={this.props.disabled || false} className="fa fa-remove"></i>
                </span> */}
                {
                    this.renderAutocomplete()
                }
            </div>

        )

    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        newbookingActions: bindActionCreators(newbookingActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Places3partyAutocomplete);