import {
  intercityRateFetchApi,
  intercityRateFindApi,
  intercityRateFindOneApi,
  intercityRateCreateApi,
  intercityRateUpdatedApi,
  intercityRateDeleteApi,
  intercityRateActiveApi,
  intercityRateDeactiveApi,

  intercityRoutesFindApi,
  intercityRoutesAddApi,
  intercityRoutesEditApi,
  intercityRoutesDeleteApi,
  intercityRoutesDetailApi,
  intercityRoutesFindWithCarTypeApi,
} from '../constants/ApiConfigs'
import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

//Rate Intercity settings
export function fetchIntercityRate(options = {}) {
  const config = {
      method: "get"
  };
  return callApi(
      UrlBuilder(intercityRateFetchApi, options),
      config,
      null,
      null,
      null,
      true
  );
}
export function getAllIntercityRates(options) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateFindApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function getRateDetailIntercityRate(options) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateFindOneApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function createIntercityRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateCreateApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function updateIntercityRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateUpdatedApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function activeIntercityRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "PUT",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateActiveApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function deactiveIntercityRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "PUT",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateDeactiveApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function deleteIntercityRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "POST",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRateDeleteApi,
      config,
      null,
      null,
      null,
      true
  );
}

//Intercity rate Routes settings

export function getAllIntercityRateRoutes(options) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRoutesFindApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function getDetailIntercityRateRoutes(options) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRoutesDetailApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function createIntercityRateRoute(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRoutesAddApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function updateIntercityRateRoute(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "post",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRoutesEditApi,
      config,
      null,
      null,
      null,
      true
  );
}
export function deleteIntercityRateRoute(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "POST",
      body: JSON.stringify(options)
  };

  return callApi(
      intercityRoutesDeleteApi,
      config,
      null,
      null,
      null,
      true
  );
}

export function findRouteWithCarType(options = {}) {
  options = Object.assign({}, options);
  const config = {
      method: "POST",
      body: JSON.stringify(options)
  };

  return callApi(
    intercityRoutesFindWithCarTypeApi,
      config,
      null,
      null,
      null,
      true
  );
}