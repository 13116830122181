import React, { useEffect, useState } from 'react';
import {
    FormGroup,
    Button,
    ButtonToolbar,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { BsPlus } from 'react-icons/bs';


function RedeemPoint({ promotions, hasPermission, handleOpenModal, handleClickDelete }) {
    const [vourcherList, setVourcherList] = useState([]);

    useEffect(() => {

        setVourcherList(promotions)
    }, [promotions])

    const handleAddButtonClick = () => {
        let object = {
            name: "",
            points: 0,
            amount: 0,
            tandc: "",
            validTime: 6,
            validTimeUnit: "month",
            services: [],
            campaignId: ""
        };
        handleOpenModal(object)
    };


    const handleClickMenuVoucherList = (key, data) => {
        switch (key) {
            case "Edit": {
                handleOpenModal(data)
                break;
            }
            case "Delete": {
                handleClickDelete(data)
                break;
            }
        }
    };


    return (
        <div className="form-group-info">
            <FormGroupTitle className="default-font-size">
                <Translate value="pointConfig.Redeem_points_to_voucher" />
            </FormGroupTitle>
            <FormGroup>
                <div className="model-list-container">
                    <table className="table table-no-pagination cc-table-striped">
                        <thead>
                            <tr className="">
                                <th>
                                    <Translate value="pointConfig.vourcherName" />
                                </th>
                                <th>
                                    <Translate value="pointConfig.pointsToRedeem" />
                                </th>
                                <th>
                                    <Translate value="pointConfig.vourcherValue" />
                                    {/* <QuestionCircleTooltip text={<Translate value="referral.isDefaultCode_hint" />} /> */}
                                </th>
                                <th>
                                    <Translate value="pointConfig.Actions" />
                                </th>
                            </tr>
                        </thead>
                        {vourcherList.length > 0 &&
                            <tbody>
                                {vourcherList.map(item => {
                                    return (
                                        <tr key={item._id}>
                                            <td>{item.name}</td>
                                            <td>{item.points}</td>
                                            <td>{item.amount}</td>
                                            <td>
                                                {hasPermission ? (
                                                    <div className="car-model-action">
                                                        {item._id ? (
                                                            <i
                                                                className="fa fa-edit fa-2x"
                                                                onClick={e => {
                                                                    handleClickMenuVoucherList('Edit', item);
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        <i
                                                            className="fa fa-trash fa-2x"
                                                            onClick={e => {
                                                                handleClickMenuVoucherList('Delete', item);
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                        }
                    </table>

                    {hasPermission && vourcherList.length < 20 ?
                        <ButtonToolbar className="text-center header-button-group">
                            <Button
                                className={"btn-header text-add-header ml0"}
                                onClick={handleAddButtonClick}
                            >
                                {" "}
                                <BsPlus />
                                <Translate value="pointConfig.add_voucher" />
                            </Button>
                        </ButtonToolbar>
                        : null
                    }
                </div>
            </FormGroup>
        </div >

    )
}

export default RedeemPoint
