import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  FormGroup,
  Form,
  FormControl,
  Row,
  Col,
  Button
} from 'react-bootstrap';

import { Validator, ValidCase } from '../../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc
} from '../../../../utils/commonFunctions';
import { LocationItemFormWrapper } from './LocationItemForm.style';

export default class LocationItemForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      valid: {},
      location: _.assign({
      }, props.location)
    };
  }

  handleRemove = () => {
    const { location, onRemove } = this.props;
    onRemove(location._id);
  };

  checkGoogleAddressByLatlng = () => {
    const { index } = this.props;
    this.setState({ isChecklatlngClicked: true });
    if (!this.state.valid.lat || !this.state.valid.lng) return;
    const { location, checkGoogleAddressByLatlng } = this.props;
    checkGoogleAddressByLatlng(location, index);
  };

  onChangeInput = (name, e) => {
    const { location } = this.state;
    const { onChange } = this.props;
    onChange(location._id, name, e.target.value);
  };

  validatorCallback = (id, valid) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState(
        { valid: this.state.valid },
        this.changeValidatorParentForm
      );
    }
  };

  changeValidatorParentForm = () => {
    const { valid } = this.state;
    const { validatorCallback, location } = this.props;
    const isInValid = _.values(valid).some(o => !o);
    validatorCallback(location._id, !isInValid);
  };

  render() {
    const { canUpdate, isSubmitted, location } = this.props;
    const { isChecklatlngClicked } = this.state;
    return (
      <LocationItemFormWrapper>
        <FormGroup
          className={
            !isSubmitted
              ? null
              : this.state.valid.name === true
              ? null
              : 'error'
          }
        >
          <Form.Label>
            <Translate value="thirdPartySettings.location" />
            <span className="require">*</span>
          </Form.Label>
          <FormControl
            type="text"
            className="form-custom"
            defaultValue={location.name}
            value={location.name}
            onChange={e => this.onChangeInput('name', e)}
            placeholder={I18n.t('thirdPartySettings.namePlaceholder')}
            disabled={!canUpdate}
          />
          <Validator id="name" callback={this.validatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(location.name)}
              message={I18n.t('thirdPartySettings.Please_input_name')}
            />
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isMaxlength(location.name, 150)}
              message={I18n.t('messages.commonMessages.Max_length').format(150)}
            />
          </Validator>
        </FormGroup>
        <FormGroup>
          <Form.Label>
            <Translate value="thirdPartySettings.placeType" />
          </Form.Label>
          <FormControl
            as="select"
            className="form-custom"
            value={location.placeType}
            onChange={e => this.onChangeInput('placeType', e)}
            disabled={!canUpdate}
          >
            <option value="all">
              {I18n.t('thirdPartySettings.all')}
            </option>
            <option value="pu">
              {I18n.t('thirdPartySettings.PU')}
            </option>
            <option value="do">
              {I18n.t('thirdPartySettings.DO')}
            </option>
          </FormControl>
        </FormGroup>
        <Row>
          <Col sm={6}>
            <FormGroup
              className={
                (!isSubmitted && !isChecklatlngClicked
                  ? null
                  : this.state.valid.lat === true
                  ? null
                  : 'error')
              }
            >
              <Form.Label>
                <Translate value="thirdPartySettings.Latitude" />
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="number"
                className="form-custom"
                onChange={e => this.onChangeInput('lat', e)}
                defaultValue={location.lat}
                value={location.lat}
                placeholder={I18n.t('thirdPartySettings.latitudePlaceholder')}
                disabled={!canUpdate}
              />
              <Validator id="lat" callback={this.validatorCallback}>
                <ValidCase
                  hide={!isSubmitted && !isChecklatlngClicked}
                  valid={!Validation.isStringEmpty(location.lat)}
                  message={I18n.t('thirdPartySettings.Please_input_latitude')}
                />
                <ValidCase
                  hide={!isSubmitted && !isChecklatlngClicked}
                  valid={
                    parseFloat(location.lat) <= 90 &&
                    parseFloat(location.lat) >= -90
                  }
                  message={I18n.t(
                    'thirdPartySettings.Error_message_lat_in_range'
                  )}
                />
                <ValidCase
                  hide={!isSubmitted}
                  valid={!Validation.isMaxlength(location.lng, 50)}
                  message={I18n.t('messages.commonMessages.Max_length').format(50)}
                />
              </Validator>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup
              className={
                (!isSubmitted && !isChecklatlngClicked
                  ? null
                  : this.state.valid.lng === true
                  ? null
                  : 'error')
              }
            >
              <Form.Label>
                <Translate value="thirdPartySettings.Longitude" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="number"
                className="form-custom"
                onChange={e => this.onChangeInput('lng', e)}
                defaultValue={location.lng}
                value={location.lng}
                placeholder={I18n.t('thirdPartySettings.longitudePlaceholder')}
                disabled={!canUpdate}
              />
              <Validator id="lng" callback={this.validatorCallback}>
                <ValidCase
                  hide={!isSubmitted && !isChecklatlngClicked}
                  valid={!Validation.isStringEmpty(location.lng)}
                  message={I18n.t('thirdPartySettings.Please_input_longitude')}
                />
                <ValidCase
                  hide={!isSubmitted && !isChecklatlngClicked}
                  valid={
                    !parseFloat(location.lng) <= 180 &&
                    parseFloat(location.lng) >= -180
                  }
                  message={I18n.t(
                    'thirdPartySettings.Error_message_lng_in_range'
                  )}
                />
                <ValidCase
                  hide={!isSubmitted}
                  valid={!Validation.isMaxlength(location.lng, 50)}
                  message={I18n.t('messages.commonMessages.Max_length').format(50)}
                />
              </Validator>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Form.Label title={I18n.t('thirdPartySettings.instructionLinkTooltip')}>
            <Translate value="thirdPartySettings.instructionLink" />
          </Form.Label>
          <FormControl
            type="text"
            className="form-custom"
            defaultValue={location.instructionLink}
            value={location.instructionLink}
            onChange={e => this.onChangeInput('instructionLink', e)}
            placeholder={I18n.t('thirdPartySettings.instructionLinkPlaceholder')}
            disabled={!canUpdate}
            title={I18n.t('thirdPartySettings.instructionLinkTooltip')}
          />
        </FormGroup>
        <div className="locationItem__footer">
          <Button
            className="btn-header locationItem__btnCheckMap text-add-header p0"
            onClick={this.checkGoogleAddressByLatlng}
          >
            <Translate value="thirdPartySettings.checkOnMap" />
          </Button>
          <Button
            className="btn-header locationItem__btnRemove text-remove p0"
            onClick={this.handleRemove}
          >
            <Translate value="thirdPartySettings.Remove" />
          </Button>
        </div>
      </LocationItemFormWrapper>
    );
  }
}
