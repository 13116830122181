import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import { MultiSelectCommonFunc, Validation } from "../../utils/commonFunctions";
import _ from "lodash";
import { FormGroup, Form, FormControl } from "react-bootstrap";
class MultipleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      if (Validation.isStringEmpty(this.state.str)) {
        this.setState({ data: nextProps.data, value: nextProps.value });
      } else {
        this.setState({ value: nextProps.value });
      }
    }
  }

  componentDidMount() {
    if (this.props.data && !this.state.data) {
      this.setState({ data: this.props.data });
    }
  }

  handleSearchChange(e) {
    let str = e.target.value.toLowerCase();
    let data = this.props.data;
    if (e.target.value.length > 0) {
      data = this.props.data.filter(item => {
        if (_.includes(item[this.props.name].toLowerCase(), str)) {
          return item;
        }
      });
    }
    this.setState({ data: data, str: str });
  }

  render() {
    return (
      <div>
        <Form.Label>
          {" "}
          {this.props.title}{" "}
          {this.props.isRequire ? <span className="require"> *</span> : null}
        </Form.Label>
        <FormGroup
          controlId="formControlsSelectMultiple"
          bsPrefix={this.props.className}
        >
          {this.props.isSearchAble ? (
            <FormControl
              style={{ height: "10%" }}
              type="text"
              className={`form-custom ${this.props.inputCustomClassName ? this.props.inputCustomClassName : ''}`}
              onChange={this.handleSearchChange}
              placeholder={this.props.searchPlaceHolder}
            />
          ) : null}
          <FormControl
            as="select"
            style={{ height: `${this.props.heightSelect || 100}%` }}
            multiple={this.props.isMultiple}
            htmlSize="7"
            onChange={e => {
              if (this.props.handleItemChange) {
                this.props.handleItemChange(
                  MultiSelectCommonFunc.getSelectValues(e)
                );
              }
            }}
            onClick={e => {
              if (this.props.handleItemClick) {
                this.props.handleItemClick([e.target.value]);
              }
            }}
            value={this.state.value}
            className={`form-custom ${this.props.selectCustomClassName ? this.props.selectCustomClassName : ''}`}
          >
            {this.state.data
              ? this.state.data.map(z => {
                  return (
                    <option key={z._id} value={z._id}>
                      {z[this.props.name]}
                    </option>
                  );
                })
              : null}
          </FormControl>
        </FormGroup>
      </div>
    );
  }
}
export default connect()(MultipleSelect);
