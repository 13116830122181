import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import { toLocaleNumber } from '../../utils/commonFunctions';
import revenueIcon from '../../assets/images/dashboard/Revenue.svg';
import bookReqIcon from '../../assets/images/dashboard/Bookrequest.svg';
import bookCompleteIcon from '../../assets/images/dashboard/BookComplete.svg';
import customerIcon from '../../assets/images/dashboard/Customer.svg';
import { renderHintLabel } from '../../utils/chartUtils';

const iconMap = {
  profit: revenueIcon,
  bookingRequests: bookReqIcon,
  completedBookings: bookCompleteIcon,
  newCustomers: customerIcon,
};

const AnalyticItem = ({ type, overall, criteria }) => {
  const getConfig = () => {
    switch (type) {
      case 'profit':
        return {
          iconClass: 'revenue',
          textColorClass: 'revenue-text-color',
          title: I18n.t('dashboard.Profit'),
          titleHint: I18n.t('dashboard.ProfitToolTips'),
          value: currencyFormatter.format(overall.profit.total, {
            code: criteria.currency.iso,
          }),
          changeHint: I18n.t('dashboard.ProfitChangeToolTips'),
          percentChange: overall.profit.dailyPercentChange,
          bottomLabel: `${currencyFormatter.format(overall.profit.revenue, { code: criteria.currency.iso })} ${I18n.t('dashboard.Revenue')}`,
          bottomHint: I18n.t('dashboard.RevenueToolTips'),
        };
      case 'bookingRequests':
        return {
          iconClass: 'booking-request',
          textColorClass: 'book-req-text-color',
          title: I18n.t('dashboard.Booking_requests'),
          titleHint: I18n.t('dashboard.BookingRequestsToolTips'),
          value: toLocaleNumber(overall.booking.total),
          changeHint: I18n.t('dashboard.BookingChangeHint'),
          percentChange: overall.booking.dailyPercentChange,
          bottomLabel: `${overall.booking.acceptancePercent}% ${I18n.t('dashboard.acceptance')}`,
          bottomHint: I18n.t('dashboard.PercentageOfAcceptanceToolTips'),
        };
      case 'completedBookings':
        return {
          iconClass: 'completed-booking',
          textColorClass: 'comple-book-text-color',
          title: I18n.t('dashboard.Complete_bookings'),
          titleHint: I18n.t('dashboard.CompletedBookingHint'),
          value: toLocaleNumber(overall.completed.total),
          changeHint: I18n.t('dashboard.CompleteBookingDailyChangeHint'),
          percentChange: overall.completed.dailyPercentChange,
          bottomLabel: `${overall.completed.satisfationPercent}% ${I18n.t('dashboard.satisfaction')}`,
          bottomHint: I18n.t('dashboard.PercentageOfSatisfactionHint'),
        };
      case 'newCustomers':
        return {
          iconClass: 'customer',
          textColorClass: 'customer-text-color',
          title: I18n.t('dashboard.New_customers'),
          titleHint: I18n.t('dashboard.NewCustomersHint'),
          value: toLocaleNumber(overall.customer.newCustomers),
          changeHint: I18n.t('dashboard.CustomerChangeHint'),
          percentChange: overall.customer.dailyPercentChange,
          bottomLabel: I18n.t('dashboard.Customer_count').format(
            toLocaleNumber(overall.customer.total || 0),
          ),
          bottomHint: I18n.t('dashboard.TotalCustomer'),
        };
      default:
        return {};
    }
  };

  const config = getConfig();

  return (
    <Col sm={12} md={6} lg={3}>
      <div className='db-body-overal-item db-items'>
        <div className={`db-item-icon ${config.iconClass}`}>
          <Image src={iconMap[type]} />
        </div>
        <div className='db-item-info'>
          <div>
            <div className={`${config.textColorClass} item-title`}>
              {renderHintLabel(config.title, config.titleHint)}
            </div>
            <div className={`overal-item-value ${config.textColorClass}`}>{config.value}</div>
            <div className='overal-item-bottom'>
              {renderHintLabel(I18n.t(`dashboard.${criteria.viewMode}_change`), config.changeHint)}
              <div className={config.percentChange < 0 ? 'negative mr-l-5' : 'positive mr-l-5'}>
                {config.percentChange}%
              </div>
            </div>
          </div>
          <div className='overal-item-bottom'>
            {renderHintLabel(config.bottomLabel, config.bottomHint, 'bottom')}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AnalyticItem;
