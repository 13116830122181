import React, { useState, useEffect } from 'react'
import RoutePreview from './RoutePreview'
import AddTraveler from "./AddTraveler"
import SelectPointForTraveler from './SelectPointForTraveler'
import _ from 'lodash'

const ShuttleTraveler = ({
  isShowRoutePreview,
  setIsShowRoutePreview,
  puPoints,
  doPoints,
  autocompleteCustomer,
  fleetId,
  corporateId,
  newbookingActions,
  auth,
  customerActions,
  setNewDoPuPointAddTraveler,
  traveler_type,
  data
}) => {
  const [isShowAddTraveler, setShowAddTraveler] = useState(false)
  const [isShowSelectPoint, setShowSelectPoint] = useState(false)
  const [customersSelected, setCustomerSelected] = useState([])
  const [doPointState, setDoPointState] = useState([])
  const [puPointState, setPuPointState] = useState([])
  const [foreEdit, setForeEdit] = useState(false)

  useEffect(() => {
    setDoPointState(doPoints)
    setPuPointState(puPoints)
  }, [doPoints, puPoints])

  const handleselectPickupDrop = (puDoSelected = {}) => {
    let puPointsRemovedPax = removePassengers([...puPointState], customersSelected)
    let doDoPointsRemovedPax = removePassengers([...doPointState], customersSelected)

    let newPuPoint = editPoint(puPointsRemovedPax, puDoSelected.pu)
    let newDoPoint = editPoint(doDoPointsRemovedPax, puDoSelected.do)
    setPuPointState(newPuPoint)
    setDoPointState(newDoPoint)
    setIsShowRoutePreview(true)
    setForeEdit(true)
    setCustomerSelected([])
  }

  const handleMovePoints = () => {
    setShowSelectPoint(true)
  }

  const handleRemovePoints = () => {
    let puPointsRemovedPax = removePassengers([...puPointState], customersSelected)
    let doDoPointsRemovedPax = removePassengers([...doPointState], customersSelected)

    setPuPointState(puPointsRemovedPax)
    setDoPointState(doDoPointsRemovedPax)
    setCustomerSelected([])
  }

  const backCloseModal = () => {
    setDoPointState(doPoints)
    setPuPointState(puPoints)
  }

  const editPoint = (listPointEdit, pointsSelected) => {
    let pointIndex = listPointEdit.findIndex((obj => obj.id == pointsSelected));
    let currentPassengers = listPointEdit[pointIndex] && listPointEdit[pointIndex].passengers || []
    listPointEdit[pointIndex].passengers = [
      ...currentPassengers,
      ...customersSelected
    ]
    return listPointEdit
  }

  const getPassengersNotShowWhenSearchs = () => {
    let passengersNotShow = []
    puPoints.forEach((point) => {
      let psg = point.passengers || []
      if(psg.length > 0) passengersNotShow = passengersNotShow.concat(psg)
    })
    puPointState.forEach((point) => {
      let psg = point.passengers || []
      if(psg.length > 0) passengersNotShow = passengersNotShow.concat(psg)
    })
    return passengersNotShow
  }

  const removePassengers = (points, passengers) => {
    let newPoints = []
    points.map((point) => {
      let newPassengers = point.passengers
      if(point.passengers && point.passengers.length > 0) {
        newPassengers = _.differenceWith(point.passengers, passengers, (a,b) => { return a.userId == b.userId})
      }
      newPoints.push({...point, passengers: newPassengers})
    })
    return newPoints
  }

  const handleSaveUpdate = () => {
    setNewDoPuPointAddTraveler(puPointState, doPointState)
  }

  return (
    <>
      <RoutePreview 
        isShowRoutePreview={isShowRoutePreview}
        setIsShowRoutePreview={setIsShowRoutePreview}
        puPoints={puPointState}
        doPoints={doPointState}
        setShowAddTraveler={setShowAddTraveler}
        setCustomerSelected={setCustomerSelected}
        customersSelected={customersSelected}
        backCloseModal={backCloseModal}
        handleMovePoints={handleMovePoints}
        handleSaveUpdate={handleSaveUpdate}
        foreEdit={foreEdit}
        setForeEdit={setForeEdit}
        data={data}
        handleRemovePoints={handleRemovePoints}
      />
      <AddTraveler
        isShowAddTraveler={isShowAddTraveler}
        fleetId={fleetId}
        autocompleteCustomer={autocompleteCustomer}
        setShowAddTraveler={setShowAddTraveler}
        setShowSelectPoint={setShowSelectPoint}
        setCustomerSelected={setCustomerSelected}
        auth={auth}
        newbookingActions={newbookingActions}
        customerActions={customerActions}
        setIsShowRoutePreview={setIsShowRoutePreview}
        customersSelected={customersSelected}
        passengersNotShow={getPassengersNotShowWhenSearchs()}
        traveler_type={traveler_type}
        data={data}
        corporateId={corporateId}
      />
      
      <SelectPointForTraveler
        setShowAddTraveler={setShowAddTraveler}
        isShowSelectPoint={isShowSelectPoint}
        setShowSelectPoint={setShowSelectPoint}
        setPointSelected={handleselectPickupDrop}
        puPoints={puPoints}
        doPoints={doPoints}
      />
    </>
  )
}

export default ShuttleTraveler