import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../settings.scss";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import CcCheckbox from "../../../components/ccCheckbox/CcCheckbox";
import * as settingActions from "../../../actions/settingActions";
import * as sharingRateActions from "../../../actions/sharingRateActions";
import * as intercityRateActions from "../../../actions/intercityRateActions";
import * as deliveryRateActions from "../../../actions/deliveryRateActions";
import { bindActionCreators } from "redux";
import { CCLiteCommonFunc, checkEnable3rdPartyIntegration, handleResultExport } from "../../../utils/commonFunctions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import TableActions from "../../../components/table/tableAction/TableActions";
import ExportComponent from "../../../components/ExportComponent";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import { CarTypeColumns } from "./tableHeaderData";
import { AFFILIATE_BOOKING_CAR_TYPE, thirdPartyIntegration } from "../../../constants/commondata"
import _ from "lodash";
import ImportAssignRate from './components/ImportAssignRate'
import FormCarType from "./components/formCarType";

class CarType extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      detailItem: null,
      showDialog: false,
      showDialogConfigure: false,
      showModalCarType: false,
      valueEditCarType: null,
      carTypeConfig: {},
      dialogData: null,
      dialogChanged: false,
      sortType: -1,
      regularRates: [],
      sharingRates: [],
      zoneList: [],
      flatRates: [],
      intercityRates: [],
      hourlyRates: [],
      deliveryRates: [],
      valid: {},
      editable: false,
      tableHeight: 500,
      affiliateCarType: [],
      rowHeight: 50,
      footerData: {
        limit: 100,
        total: 0,
        page: 0
      }
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.addZoneHourlyRate = this.addZoneHourlyRate.bind(this);
    this.addZoneRegularRate = this.addZoneRegularRate.bind(this);
    this.addZoneDeliveryRate = this.addZoneDeliveryRate.bind(this);
    this.selectChanggHandle = this.selectChanggHandle.bind(this);
    this.updateDataList = this.updateDataList.bind(this);
    this.removeZoneRateComplied = this.removeZoneRateComplied.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handleResultExport = handleResultExport.bind(this);
  }
  componentDidMount() {
    this.updateDataList();
    this.setState({
      enable3rdPartyIntegration: checkEnable3rdPartyIntegration(this.props.auth.selectedFleet)
    })
  }

  updateDataList(activePage, limit) {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId
      }
    };
    this.props.settingActions.getAllCarType(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit }
        });
      }
    });
  }

  saveDialogForm() {
    let rateObject = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      id: this.state.dialogData._id,
      regular: this.state.dialogData.zoneRateRegular.map(item => {
        return {
          zoneId: item.zone._id,
          rateId: item.rate._id
        };
      }),
      sharing: this.state.dialogData.zoneRateSharing.map(item => {
        return {
          zoneId: item.zone._id,
          rateId: item.rate._id
        };
      }),
      flatFareId: this.state.dialogData.flatFare
        ? this.state.dialogData.flatFare._id
        : null,
      intercityRateId: this.state.dialogData.intercityRate
        ? this.state.dialogData.intercityRate._id
        : null,
      hourly: this.state.dialogData.zoneRateHourly.map(item => {
        return {
          zoneId: item.zone._id,
          rateId: item.rate._id
        };
      }),
      delivery: this.state.dialogData.zoneRateDelivery.map(item => {
        return {
          zoneId: item.zone._id,
          rateId: item.rate._id
        };
      }),
      // deliveryRateId: this.state.dialogData.deliveryRate
      //   ? this.state.dialogData.deliveryRate._id
      //   : null,
    };
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions.UpdateCarType(rateObject).then(data => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (!data.ok) {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("carSetting.Update_car_fail")
          );
        }
      } else {
        this.context.notification(
          "success",
          I18n.t("carSetting.Update_car_type_success")
        );
        this.closeDialogForm();
        this.updateDataList();
      }
    });
  }

  saveDialogConfigureForm = () => {
    const { 
      carTypeConfig = {}, 
      affiliateCarType = [], 
      enable3rdPartyIntegration = [] 
    } = this.state
    const dataRequest = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      vehicleTypeId: carTypeConfig._id,
      bookNow: carTypeConfig.bookNow,
      bookLater: carTypeConfig.bookLater,
      vehicleType: carTypeConfig.vehicleType,
    }
    if(enable3rdPartyIntegration) {
      dataRequest.thirdPartyBooking = {
        thirdParty: thirdPartyIntegration.bookingAPI,
        vehicleTypes: affiliateCarType || []
      }
    } 
    dataRequest.affiliateCarType = affiliateCarType || []
    this.props.loadingBarActions.showLoadingSpiner()
    this.props.settingActions.carTypeUpdateConfig(dataRequest)
    .then(data => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (!data.ok) {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("carSetting.Update_car_fail")
          );
        }
      } else {
        this.context.notification(
          "success",
          I18n.t("carSetting.Update_car_type_success")
        );
        this.closeDialogConfigureForm();
      }
    })
  }

  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false
    });
  }
  closeDialogConfigureForm = () => {
    this.setState({
      showDialogConfigure: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false
    });
  }
  handleMenuClick(key, data) {
    switch (key) {
      case "AssignRate": {
        // this.props.settingActions.getAllMapZone(this.props.auth.selectedFleet._id).then(res => {
        //     if (res.ok && res.res) {
        //         this.setState({ zoneList: res.res });
        //     }
        // })
        this.props.settingActions
          .fetchRateRegular({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ regularRates: res.res });
            }
          });
        this.props.sharingRateActions
          .fetchSharingRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ sharingRates: res.res });
            }
          });
        this.props.settingActions
          .fetchRateFlat({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ flatRates: res.res });
            }
          });
        this.props.intercityRateActions
          .fetchIntercityRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ intercityRates: res.res });
            }
          });
        this.props.settingActions
          .fetchRateHourly({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ hourlyRates: res.res });
            }
          });
        this.props.deliveryRateActions
          .fetchDeliveryRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ deliveryRates: res.res });
            }
          });
        this.props.settingActions
          .getCarTypeDetail({ id: data._id })
          .then(res => {
            if (res.ok) {
              this.state.dialogData = res.res;
              this.setState({
                showDialog: true,
                dialogData: this.state.dialogData,
                dialogChanged: false,
                isAddModel: false,
                zoneList: res.res.zone,
                editable: true
              });
            } else {
              if (res.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + res.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Load_car_type_details_fail")
                );
              }
            }
          });
        break;
      }
      case "View": {
        this.props.settingActions
          .fetchRateRegular({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ regularRates: res.res });
            }
          });
        this.props.sharingRateActions
          .fetchSharingRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ sharingRates: res.res });
            }
          });
        this.props.settingActions
          .fetchRateFlat({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ flatRates: res.res });
            }
          });
        this.props.intercityRateActions
          .fetchIntercityRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ intercityRates: res.res });
            }
          });
        this.props.settingActions
          .fetchRateHourly({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ hourlyRates: res.res });
            }
          });
        this.props.deliveryRateActions
          .fetchDeliveryRate({ fleetId: this.props.auth.selectedFleet._id })
          .then(res => {
            if (res.ok && res.res) {
              this.setState({ deliveryRates: res.res });
            }
          });
        this.props.settingActions
          .getCarTypeDetail({ id: data._id })
          .then(res => {
            if (res.ok) {
              this.state.dialogData = res.res;
              this.setState({
                showDialog: true,
                dialogData: this.state.dialogData,
                dialogChanged: false,
                isAddModel: false,
                zoneList: res.res.zone,
                editable: false
              });
            } else {
              if (res.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + res.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Load_car_type_details_fail")
                );
              }
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Configure": {
        this.props.settingActions
          .getCarTypeDetail({ id: data._id })
          .then(res => {
            if (res.ok) {
              this.setState({ 
                showDialogConfigure: true,
                carTypeConfig: res.res || {},
                affiliateCarType: _.get(res, 'res.thirdPartyAssociateTypes[0].vehicleTypes', [])
              });
            } else {
              if (res.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + res.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Load_car_type_details_fail")
                );
              }
            }
          });
        break;
      }
      case "Edit": {
        const checkTransport = !data.intercity && !data.shuttle && !data.delivery && !data.food && !data.mart
        this.props.auth.selectedFleet?.transport?.enable &&
        checkTransport &&
        this.props.settingActions
          .detailUpdateCarType({ _id: data._id })
          .then(res => {
            if (res?.res?._id) {
              this.setState({
                valueEditCarType: res.res,
                showModalCarType: true,
              });
            } else {
              if (res.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + res.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Load_car_type_details_fail")
                );
              }
            }
          });
        break;
      }
    }
  }
  addZoneRegularRate(e) {
    let regularZones = this.state.zoneList.filter(item => {
      return !this.checkZoneAdded("regular_zone", item);
    });
    if (regularZones.length >= 1 && !this.state.dialogData.regularZones) {
      this.state.dialogData.regularZones = [regularZones[0]];
    }
    if (!this.state.dialogData.regularRate) {
      this.state.dialogData.regularRate = this.state.regularRates[0];
    }
    if (this.state.dialogData.regularZones) {
      this.state.dialogData.regularZones.map(z => {
        let objectAdd = {
          zone: z,
          rate: this.state.dialogData.regularRate
        };
        if (objectAdd.zone && objectAdd.rate) {
          this.state.dialogData.zoneRateRegular.push(objectAdd);
        }
      });
      this.state.dialogData.regularZones = [];
      this.setState({ dialogData: this.state.dialogData });
    }
  };
  addZoneSharingRate = (e) => {
    let sharingZones = this.state.zoneList.filter(item => {
      return !this.checkZoneAdded("sharing_zone", item);
    });
    if (sharingZones.length >= 1 && !this.state.dialogData.sharingZones) {
      this.state.dialogData.sharingZones = [sharingZones[0]];
    }
    if (!this.state.dialogData.sharingRate) {
      this.state.dialogData.sharingRate = this.state.sharingRates[0];
    }
    if (this.state.dialogData.sharingZones) {
      this.state.dialogData.sharingZones.map(z => {
        let objectAdd = {
          zone: z,
          rate: this.state.dialogData.sharingRate
        };
        if (objectAdd.zone && objectAdd.rate) {
          this.state.dialogData.zoneRateSharing.push(objectAdd);
        }
      });
      this.state.dialogData.sharingZones = [];
      this.setState({ dialogData: this.state.dialogData });
    }
  };
  addZoneDeliveryRate(e) {
    //console.log("this.state.zoneList ", this.state)
    let deliveryZones = this.state.zoneList.filter(item => {
      return !this.checkZoneAdded("delivery_zone", item);
    });
    if (deliveryZones.length >= 1 && !this.state.dialogData.deliveryZones) {
      this.state.dialogData.deliveryZones = [deliveryZones[0]];
    }
    if (!this.state.dialogData.deliveryRate) {
      this.state.dialogData.deliveryRate = this.state.deliveryRates[0];
    }
    if (this.state.dialogData.deliveryZones) {
      this.state.dialogData.deliveryZones.map(z => {
        let objectAdd = {
          zone: z,
          rate: this.state.dialogData.deliveryRate
        };
        if (objectAdd.zone && objectAdd.rate) {
          this.state.dialogData.zoneRateDelivery.push(objectAdd);
        }
      });
      this.state.dialogData.deliveryZones = [];
      this.setState({ dialogData: this.state.dialogData });
    }
  };
  selectChanggHandle(key, e) {
    switch (key) {
      case "regular_zone": {
        let zones = CCLiteCommonFunc.getSelectedOptons(e);
        this.state.dialogData.regularZones = zones.map(sl => {
          return this.props.commonData.mapZone.filter(a => {
            return a._id == sl;
          })[0];
        });
        break;
      }
      case "regular_rate": {
        this.state.dialogData.regularRate = this.state.regularRates.filter(
          a => {
            return a._id == e.target.value;
          }
        )[0];
        break;
      }
      case "sharing_zone": {
        let zones = CCLiteCommonFunc.getSelectedOptons(e);
        this.state.dialogData.sharingZones = zones.map(sl => {
          return this.props.commonData.mapZone.filter(a => {
            return a._id == sl;
          })[0];
        });
        break;
      }
      case "sharing_rate": {
        this.state.dialogData.sharingRate = this.state.sharingRates.filter(
          a => {
            return a._id == e.target.value;
          }
        )[0];
        break;
      }
      case "hourly_zone": {
        let zones = CCLiteCommonFunc.getSelectedOptons(e);
        this.state.dialogData.hourlyZones = zones.map(sl => {
          return this.props.commonData.mapZone.filter(a => {
            return a._id == sl;
          })[0];
        });
        break;
      }
      case "hourly_rate": {
        this.state.dialogData.hourlyRate = this.state.hourlyRates.filter(a => {
          return a._id == e.target.value;
        })[0];
        break;
      }
      case "flat": {
        this.state.dialogData.flatFare = "";
        this.state.flatRates.map(item => {
          if (item._id == e.target.value) {
            this.state.dialogData.flatFare = item;
          }
        });

        break;
      }
      case "intercity": {
        this.state.dialogData.intercityRate = "";
        this.state.intercityRates.map(item => {
          if (item._id == e.target.value) {
            this.state.dialogData.intercityRate = item;
          }
        });

        break;
      }
      case "delivery_zone": {
        let zones = CCLiteCommonFunc.getSelectedOptons(e);
        this.state.dialogData.deliveryZones = zones.map(sl => {
          return this.props.commonData.mapZone.filter(a => {
            return a._id == sl;
          })[0];
        });
        break;
      }
      case "delivery_rate": {
        this.state.dialogData.deliveryRate = this.state.deliveryRates.filter(a => {
          return a._id == e.target.value;
        })[0];
        break;
      }


    }
    this.setState({ dialogData: this.state.dialogData });
  }

  checkZoneAdded(key, data) {
    let added = false;
    switch (key) {
      case "regular_zone": {
        this.state.dialogData.zoneRateRegular.every(item => {
          if (item.zone._id == data._id) {
            added = true;
            return false;
          }
          return true;
        });
        break;
      };
      case "sharing_zone": {
        this.state.dialogData.zoneRateSharing.every(item => {
          if (item.zone._id == data._id) {
            added = true;
            return false;
          }
          return true;
        });
        break;
      };
      case "hourly_zone": {
        this.state.dialogData.zoneRateHourly.every(item => {
          if (item.zone._id == data._id) {
            added = true;
            return false;
          }
          return true;
        });
        break;
      };
      case "delivery_zone": {
        this.state.dialogData.zoneRateDelivery.every(item => {
          if (item.zone._id == data._id) {
            added = true;
            return false;
          }
          return true;
        });
        break;
      };
    }
    return added;
  }
  addZoneHourlyRate(e) {
    let hourlyZones = this.state.zoneList.filter(item => {
      return !this.checkZoneAdded("hourly_zone", item);
    });
    if (hourlyZones.length >= 1 && !this.state.dialogData.hourlyZones) {
      this.state.dialogData.hourlyZones = [hourlyZones[0]];
    }
    if (!this.state.dialogData.hourlyRate) {
      this.state.dialogData.hourlyRate = this.state.hourlyRates[0];
    }
    if (this.state.dialogData.hourlyZones) {
      this.state.dialogData.hourlyZones.map(z => {
        let objectAdd = {
          zone: z,
          rate: this.state.dialogData.hourlyRate
        };
        if (objectAdd.zone && objectAdd.rate) {
          this.state.dialogData.zoneRateHourly.push(objectAdd);
        }
      });
      this.state.dialogData.hourlyZones = [];
      this.setState({ dialogData: this.state.dialogData });
    }
  }
  removeZoneRateComplied(key, data) {
    switch (key) {
      case "regular": {
        let list = [];
        this.state.dialogData.zoneRateRegular.map(item => {
          if (item !== data) {
            list.push(item);
          }
        });
        this.state.dialogData.zoneRateRegular = list;
        break;
      }
      case "sharing": {
        let list = [];
        this.state.dialogData.zoneRateSharing.map(item => {
          if (item !== data) {
            list.push(item);
          }
        });
        this.state.dialogData.zoneRateSharing = list;
        break;
      }
      case "hourly": {
        let list = [];
        this.state.dialogData.zoneRateHourly.map(item => {
          if (item !== data) {
            list.push(item);
          }
        });
        this.state.dialogData.zoneRateHourly = list;
        break;
      }
      case "delivery": {
        let list = [];
        this.state.dialogData.zoneRateDelivery.map(item => {
          if (item !== data) {
            list.push(item);
          }
        });
        this.state.dialogData.zoneRateDelivery = list;
        break;
      }
    }
    this.setState({ dialogData: this.state.dialogData });
  }

  isFormValid(data) {
    let isValid = true;
    Object.keys(data).every(key => {
      if (!data[key]) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateDataList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e);
    this.updateDataList(0, numItemsPerPage);
  }

  confirmDeleteUser() {
    this.props.settingActions
      .deleteRateHourly({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id
      })
      .then(data => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            "success",
            I18n.t("carSetting.Delete_regular_rate_success")
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("carSetting.Delete_regular_rate_fail")
          );
        }
      });
  }

  getTableHeight = () => {
    const toolbarMarginBottom = 10,
      verticalPadding = 10,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    return parentHeight ? parentHeight - outerHeight : 0;
  };

  checkEnableSharing = () => {
    const moduleSetting = _.get(this.props.auth, 'selectedFleet.modules')
    const streetSharing = moduleSetting.filter(ob => ob.name === 'Street-sharing rate')
    return !_.isEmpty(streetSharing)
  }

  checkTranportCar = (data) => {
    return !(data.delivery 
      || data.food 
      || data.dispatchRideSharing 
      || data.shuttle
      || data.mart 
      || data.intercity 
      || (data.superHelper && !data.bookNow)
    )
  }

  changeAffiliateCarType = (e, key) => {
    let { affiliateCarType = [] } = this.state
    if(affiliateCarType.includes(key)) {
      affiliateCarType = affiliateCarType.filter((car) => car !== key)
    } else {
      affiliateCarType.push(key)
    }
    this.setState({
      affiliateCarType: affiliateCarType
    })
  }

  handleOrderCarTypes = (carTypes) => {
    const { auth: { selectedFleet } = {}, settingActions } = this.props || {};
    const { fleetId } = selectedFleet || {};
    let items = [];
    let params = {
      fleetId
    };
    carTypes.map(item => {
      items.push({ carTypeId: item._id, no: item.no })
    })

    if (items.length) {
      params.carTypes = items
    }
    settingActions.updateReOrderCarType(params)
      .then(data => {
        if (!data.error) {

        } else {
          if (data.error.errorCode) {
            return this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
          }
          this.context.notification('error', I18n.t('appBanner.Order_Priority_fail'));
        }
      });
  };

    // Drag Drop
    updateCarTypeNo = () => {
      const carTypes = this.state?.dataList?.list;
      let items = carTypes || [];
      for (var i = 0; i < items.length; i++) {
        items[i].no = i
      };
  
      this.setState(
        { dataList: {...this.state.dataList, list: items} },
        () => {
          this.handleOrderCarTypes(items);
        });
    }
  
    onDragStart = (e, index) => {
      this.draggedItem = this.state?.dataList?.list[index];
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/html", e.target.parentNode);
      e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };
  
    onDragOver = index => {
      const draggedOverItem = this.state?.dataList?.list[index];
  
      // if the item is dragged over itself, ignore
      if (this.draggedItem === draggedOverItem) {
        return;
      }
  
      // filter out the currently dragged item
      let carTypes = this.state?.dataList?.list?.filter(item => item !== this.draggedItem);
  
      // add the dragged item after the dragged over item
      carTypes.splice(index, 0, this.draggedItem);
  
      this.setState({ dataList: {...this.state.dataList, list: carTypes} },);
    };
  
    onDragEnd = () => {
      this.updateCarTypeNo();
      this.draggedIdx = null;
    };

  getTableColumns = () => {
    let tableColumns = Object.assign([], CarTypeColumns);
    const { auth: { selectedFleet } } = this.props;
    const isEnableIntercity = _.get(selectedFleet, 'intercity.enable', false);
    const isEnableDelivery = _.get(selectedFleet, 'delivery.enable', false);
    const isEnableFood = _.get(selectedFleet, 'food.enable', false);
    const isEnableMart = _.get(selectedFleet, 'mart.enable', false);
    const isEnableSharing = this.checkEnableSharing();
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      const returnCol = col;
      switch (col.key) {
        case "carTypeID":
          returnCol.customCell = doc => {
            const indexItem = _.findIndex(this.state?.dataList?.list, doc);
            return (
              <div
                className={`item_drag_Name`}
                key={indexItem}
                onDragOver={() => this.onDragOver(indexItem)}
              >
                <div
                  className={'item_drag'}
                  draggable
                  onDragStart={e => this.onDragStart(e, indexItem)}
                  onDragEnd={this.onDragEnd}
                >
                  <div className="item_drag_icon"></div>
                  <div className="item_drag_icon"></div>
                  <a
                    className="clickable"
                    onClick={() => {
                      this.handleMenuClick('View', doc);
                    }}
                    style={{maxWidth: 'calc(100% - 10px)'}}
                  >
                    <OverlayTrigger
                      placement="top"
                      delayShow={300}
                      overlay={
                        <Tooltip id="tooltip">
                          <span>{doc.carTypeID}</span>
                        </Tooltip>
                      }
                    >
                      <span className="text-ellipsis cursor-pointer">{doc.carTypeID}</span>
                    </OverlayTrigger>
                  </a>
                </div>
              </div>
            )
          };
          break;
        case "actions":
          col.customCell = (data, rowIndex) => {
            const checkTransport = !data.intercity && !data.shuttle && !data.delivery && !data.food && !data.mart
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "carSetting.Assign_Rate",
                  eventKey: "AssignRate"
                }
              ];
              if (this.props.auth.selectedFleet?.transport?.enable && checkTransport) {
                actionItems = [{
                  label: "carTypeSetting.Edit",
                  eventKey: "Edit"
                }, ...actionItems]
              }
              if(this.checkTranportCar(data)) {
                actionItems.push({
                  label: "carSetting.Configure",
                  eventKey: "Configure"
                })
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={data}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, data);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", data);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="carSetting.View" />
                </a>
              );
            }
          };
          break;
        case "intercityRate": {
          col.isHide = !isEnableIntercity
          break;
        }
        case "deliveryRate": {
          col.isHide = !isEnableDelivery && !isEnableFood && !isEnableMart;
          break;
        }
        case "sharingRate": {
          col.isHide = !isEnableSharing;
          break;
        }
      }
    });

    return tableColumns.filter(c => !c.isHide);
  };

  changeBookTypeConfig = (e, key) => {
    this.setState({
      carTypeConfig: {
        ...this.state.carTypeConfig,
        [key]: e.target.checked
      }
    })
  }

  handleImportClick = () => {
    this.setState({ isShowImport: true });
  }

  prepareParamExport = () => {
    return {
      fleetId: this.props.auth.selectedFleet.fleetId,
      total: _.get(this.state.footerData, 'total', 0)
    }
  }

  render() {
    const { carTypeConfig = {} } = this.state
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const isEnableSharing = this.checkEnableSharing() 

    return (
      <div className="content">
        {this.state.showDialog && this.state.dialogData ? (
          <Modal show={true} backdrop={true} bsSize="lg" onHide={this.closeDialogForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="carSetting.ASSIGN_RATE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Car_type_ID" />
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  value={
                    this.state.dialogData ? this.state.dialogData._id : ""
                  }
                  disabled={true}
                />
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.App_Display_Name" />
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  value={
                    this.state.dialogData
                      ? this.state.dialogData.appDisplayName
                      : ""
                  }
                  disabled={true}
                />
              </FormGroup>
              {this.state.dialogData.intercity ? (
                <FormGroup>
                  <FormGroupTitle>
                    <Translate value="carSetting.Intercity_Rate" />
                  </FormGroupTitle>
                  <FormControl
                    as="select"
                    className={"form-custom"}
                    value={
                      this.state.dialogData.intercityRate
                        ? this.state.dialogData.intercityRate._id
                        : ""
                    }
                    onChange={e => {
                      this.selectChanggHandle("intercity", e);
                    }}
                    disabled={!this.state.editable}
                  >
                    <option>{I18n.t("carSetting.Select_intercity_rate")}</option>
                    {this.state.intercityRates.map(item => {
                      return (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </FormControl>
                </FormGroup>
              ) : this.state.dialogData.delivery || this.state.dialogData.food || this.state.dialogData.mart ||  (this.state.dialogData.superHelper && !this.state.dialogData.bookNow) ? (
                <>
                  <FormGroup>
                    <FormGroupTitle>
                      <Translate value="carSetting.Delivery_Rate" />
                    </FormGroupTitle>
                    <Row>
                      <Col xs={4} md={4} className="assign-container">
                        <FormGroup className="assign-select">
                          <Form.Label>
                            <Translate value="carSetting.Zone" />
                          </Form.Label>
                          <FormControl
                            as="select"
                            className={"form-custom"}
                            htmlSize="5"
                            multiple
                            onChange={e => {
                              this.selectChanggHandle("delivery_zone", e);
                            }}
                            disabled={!this.state.editable}
                          >
                            {this.state.zoneList.map(item => {
                              if (!this.checkZoneAdded("delivery_zone", item)) {
                                if (item.display) {
                                  return (
                                    <option value={item._id} key={item._id}>
                                      {item.zoneName}
                                    </option>
                                  );
                              }
                              }
                            })}
                          </FormControl>
                        </FormGroup>
                        <div className="assign-ctr-contaner">
                          <table className="assign-control">
                            <tbody>
                              <tr>
                                <td>
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col xs={4} md={4} className="assign-container">
                        <FormGroup className="assign-select">
                          <Form.Label>
                            <Translate value="carSetting.Rate" />
                          </Form.Label>
                          <FormControl
                            as="select"
                            className={"form-custom none-bg-arrow"}
                            htmlSize={5}
                            onChange={e => {
                              this.selectChanggHandle("delivery_rate", e);
                            }}
                            disabled={!this.state.editable}
                          >
                            {this.state.deliveryRates.map(item => {
                              return (
                                <option value={item._id} key={item._id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </FormControl>
                        </FormGroup>
                        <div className="assign-ctr-contaner compile">
                          <table className="assign-control">
                            <tbody>
                              <tr>
                                <td>
                                  <i
                                    className="fa fa-arrow-circle-right"
                                    aria-hidden="true"
                                    onClick={this.addZoneDeliveryRate}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Col>
                      <Col xs={4} md={4}>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="carSetting.Zone_Rate" />
                          </Form.Label>
                          <ul className="compiled-list">
                            {this.state.dialogData.zoneRateDelivery.map(item => {
                              return (
                                <li key={item.zone._id}>
                                  {item.zone.zoneName} - {item.rate.name}
                                  {this.state.editable ? (
                                    <a className="ml-sm">
                                      <i
                                        className="fa fa-remove"
                                        aria-hidden="true"
                                        onClick={() => {
                                          this.removeZoneRateComplied(
                                            "delivery",
                                            item
                                          );
                                        }}
                                      />
                                    </a>
                                  ) : null}{" "}
                                </li>
                              );
                            })}
                          </ul>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupTitle>
                      <Translate value="carSetting.Flat_Fare" />
                    </FormGroupTitle>
                    <FormControl
                      as="select"
                      className={"form-custom"}
                      value={
                        this.state.dialogData.flatFare
                          ? this.state.dialogData.flatFare._id
                          : ""
                      }
                      onChange={e => {
                        this.selectChanggHandle("flat", e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option>{I18n.t("carSetting.Select_flat_rate")}</option>
                      {this.state.flatRates.map(item => {
                        if(item.serviceType === 'delivery') {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.name}
                            </option>
                          );
                        }
                        return null
                      })}
                    </FormControl>
                  </FormGroup>
                </>
              ) : (
                    <Fragment>
                      <FormGroup>
                        <FormGroupTitle>
                          <Translate value="carSetting.Regular_rate" />
                        </FormGroupTitle>
                        <Row>
                          <Col xs={4} md={4} className="assign-container">
                            <FormGroup className="assign-select">
                              <Form.Label>
                                <Translate value="carSetting.Zone" />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={"form-custom"}
                                htmlSize="5"
                                multiple
                                onChange={e => {
                                  this.selectChanggHandle("regular_zone", e);
                                }}
                                disabled={!this.state.editable}
                              >
                                {this.state.zoneList.map(item => {
                                  if (!this.checkZoneAdded("regular_zone", item)) {
                                    if (item.display) {
                                        return (
                                          <option value={item._id} key={item._id}>
                                            {item.zoneName}
                                          </option>
                                        );
                                      }
                                  }
                                })}
                              </FormControl>
                            </FormGroup>
                            <div className="assign-ctr-contaner">
                              <table className="assign-control">
                                <tbody>
                                  <tr>
                                    <td>
                                      <i
                                        className="fa fa-plus-circle"
                                        aria-hidden="true"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col xs={4} md={4} className="assign-container">
                            <FormGroup className="assign-select">
                              <Form.Label>
                                <Translate value="carSetting.Rate" />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={"form-custom none-bg-arrow"}
                                htmlSize={5}
                                onChange={e => {
                                  this.selectChanggHandle("regular_rate", e);
                                }}
                                disabled={!this.state.editable}
                              >
                                {this.state.regularRates.map(item => {
                                  return (
                                    <option value={item._id} key={item._id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </FormControl>
                            </FormGroup>
                            <div className="assign-ctr-contaner compile">
                              <table className="assign-control">
                                <tbody>
                                  <tr>
                                    <td>
                                      <i
                                        className="fa fa-arrow-circle-right"
                                        aria-hidden="true"
                                        onClick={this.addZoneRegularRate}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col xs={4} md={4}>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="carSetting.Zone_Rate" />
                              </Form.Label>
                              <ul className="compiled-list">
                                {this.state.dialogData.zoneRateRegular.map(item => {
                                  return (
                                    <li>
                                      {item.zone.zoneName} - {item.rate.name}
                                      {this.state.editable ? (
                                        <a className="ml-sm">
                                          <i
                                            className="fa fa-remove"
                                            aria-hidden="true"
                                            onClick={() => {
                                              this.removeZoneRateComplied(
                                                "regular",
                                                item
                                              );
                                            }}
                                          />
                                        </a>
                                      ) : null}{" "}
                                    </li>
                                  );
                                })}
                              </ul>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      {
                        /* street sharing */
                        isEnableSharing && this.state.dialogData.streetSharing && 
                        <FormGroup>
                          <FormGroupTitle>
                            <Translate value="carSetting.sharing_Rate" />
                          </FormGroupTitle>
                          <Row>
                            <Col xs={4} md={4} className="assign-container">
                              <FormGroup className="assign-select">
                                <Form.Label>
                                  <Translate value="carSetting.Zone" />
                                </Form.Label>
                                <FormControl
                                  as="select"
                                  className={"form-custom"}
                                  multiple
                                  onChange={e => {
                                    this.selectChanggHandle("sharing_zone", e);
                                  }}
                                  disabled={!this.state.editable}
                                >
                                  {this.state.zoneList.map(item => {
                                    if (!this.checkZoneAdded("sharing_zone", item)) {
                                      if (item.display) {
                                        return (
                                          <option value={item._id} key={item._id}>
                                            {item.zoneName}
                                          </option>
                                        );
                                    }
                                    }
                                  })}
                                </FormControl>
                              </FormGroup>
                              <div className="assign-ctr-contaner">
                                <table className="assign-control">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <i
                                          className="fa fa-plus-circle "
                                          aria-hidden="true"
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                            <Col xs={4} md={4} className="assign-container">
                              <FormGroup className="assign-select">
                                <Form.Label>
                                  <Translate value="carSetting.Rate" />
                                </Form.Label>
                                <FormControl
                                  as="select"
                                  className={"form-custom none-bg-arrow"}
                                  htmlSize={4}
                                  onChange={e => {
                                    this.selectChanggHandle("sharing_rate", e);
                                  }}
                                  disabled={!this.state.editable}
                                >
                                  {this.state.sharingRates.map(item => {
                                    return (
                                      <option value={item._id} key={item._id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </FormControl>
                              </FormGroup>
                              <div className="assign-ctr-contaner compile">
                                <table className="assign-control">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <i
                                          className="fa fa-arrow-circle-right"
                                          aria-hidden="true"
                                          onClick={this.addZoneSharingRate}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Col>
                            <Col xs={4} md={4}>
                              <FormGroup>
                                <Form.Label>
                                  <Translate value="carSetting.Zone_Rate" />
                                </Form.Label>
                                <ul className="compiled-list">
                                  {this.state.dialogData.zoneRateSharing.map(item => {
                                    return (
                                      <li>
                                        {item.zone.zoneName} - {item.rate.name}
                                        {this.state.editable ? (
                                          <a className="ml-sm">
                                            <i
                                              className="fa fa-remove"
                                              aria-hidden="true"
                                              onClick={() => {
                                                this.removeZoneRateComplied(
                                                  "sharing",
                                                  item
                                                );
                                              }}
                                            />
                                          </a>
                                        ) : null}
                                      </li>
                                    );
                                  })}
                                </ul>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      }
                      <FormGroup>
                        <FormGroupTitle>
                          <Translate value="carSetting.Flat_Fare" />
                        </FormGroupTitle>
                        <FormControl
                          as="select"
                          className={"form-custom"}
                          value={
                            this.state.dialogData.flatFare
                              ? this.state.dialogData.flatFare._id
                              : ""
                          }
                          onChange={e => {
                            this.selectChanggHandle("flat", e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option>{I18n.t("carSetting.Select_flat_rate")}</option>
                          {this.state.flatRates.map(item => {
                            if(item.serviceType === 'transport') {
                              return (
                                <option value={item._id} key={item._id}>
                                  {item.name}
                                </option>
                              );
                            }
                            return null
                          })}
                        </FormControl>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupTitle>
                          <Translate value="carSetting.Hourly_daily_rate" />
                        </FormGroupTitle>
                        <Row>
                          <Col xs={4} md={4} className="assign-container">
                            <FormGroup className="assign-select">
                              <Form.Label>
                                <Translate value="carSetting.Zone" />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={"form-custom"}
                                multiple
                                onChange={e => {
                                  this.selectChanggHandle("hourly_zone", e);
                                }}
                                disabled={!this.state.editable}
                              >
                                {this.state.zoneList.map(item => {
                                  if (!this.checkZoneAdded("hourly_zone", item)) {
                                    if (item.display) {
                                      return (
                                        <option value={item._id} key={item._id}>
                                          {item.zoneName}
                                        </option>
                                      );
                                  }
                                  }
                                })}
                              </FormControl>
                            </FormGroup>
                            <div className="assign-ctr-contaner">
                              <table className="assign-control">
                                <tbody>
                                  <tr>
                                    <td>
                                      <i
                                        className="fa fa-plus-circle "
                                        aria-hidden="true"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col xs={4} md={4} className="assign-container">
                            <FormGroup className="assign-select">
                              <Form.Label>
                                <Translate value="carSetting.Rate" />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={"form-custom none-bg-arrow"}
                                htmlSize={4}
                                onChange={e => {
                                  this.selectChanggHandle("hourly_rate", e);
                                }}
                                disabled={!this.state.editable}
                              >
                                {this.state.hourlyRates.map(item => {
                                  return (
                                    <option value={item._id} key={item._id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </FormControl>
                            </FormGroup>
                            <div className="assign-ctr-contaner compile">
                              <table className="assign-control">
                                <tbody>
                                  <tr>
                                    <td>
                                      <i
                                        className="fa fa-arrow-circle-right"
                                        aria-hidden="true"
                                        onClick={this.addZoneHourlyRate}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Col>
                          <Col xs={4} md={4}>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="carSetting.Zone_Rate" />
                              </Form.Label>
                              <ul className="compiled-list">
                                {this.state.dialogData.zoneRateHourly.map(item => {
                                  return (
                                    <li>
                                      {item.zone.zoneName} - {item.rate.name}
                                      {this.state.editable ? (
                                        <a className="ml-sm">
                                          <i
                                            className="fa fa-remove"
                                            aria-hidden="true"
                                            onClick={() => {
                                              this.removeZoneRateComplied(
                                                "hourly",
                                                item
                                              );
                                            }}
                                          />
                                        </a>
                                      ) : null}
                                    </li>
                                  );
                                })}
                              </ul>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Fragment>
                  )}
            </Modal.Body>
            <Modal.Footer>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={this.saveDialogForm}
                  >
                    <Translate value="carSetting.Save" />
                  </Button>
                ) : (
                    <Button
                      className={"btn-save mr-md"}
                      onClick={e => {
                        this.handleMenuClick("changeStatus", e);
                      }}
                    >
                      <Translate value="carSetting.Edit" />
                    </Button>
                  )
              ) : null}
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="carSetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        
        {
          this.state.showDialogConfigure && (
            <Modal show={true} backdrop={true} bsSize="lg" onHide={this.closeDialogConfigureForm}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {carTypeConfig && carTypeConfig.appDisplayName}
                </Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closeDialogConfigureForm}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="group-general">
                  <FormGroup>
                    <FormGroupTitle>
                      <Translate value="cue.Transport" />
                    </FormGroupTitle>
                    <div className="configBookType">
                      <CcCheckbox
                        checked={carTypeConfig.bookNow}
                        onChange={(e) => {
                            this.changeBookTypeConfig(e, 'bookNow')
                        }}
                        text="Book Now"
                        disabled={!this.state.editable}
                      />
                      {
                        carTypeConfig && !carTypeConfig.dispatchRideSharing &&
                        <CcCheckbox
                          checked={carTypeConfig.bookLater}
                          onChange={(e) => {
                              this.changeBookTypeConfig(e, 'bookLater')
                          }}
                          text="Book Later"
                          disabled={!this.state.editable}
                        />
                      }
                    </div>
                  </FormGroup>
                  {
                    this.state.enable3rdPartyIntegration &&
                    <FormGroup>
                      <FormGroupTitle>
                        <Translate value="carSetting.bookingAffiliate" />
                      </FormGroupTitle>
                      <div className="affiliate">
                        {
                          AFFILIATE_BOOKING_CAR_TYPE.map((car) => {
                            return (
                              <CcCheckbox
                                key={car.key}
                                checked={this.state.affiliateCarType.includes(car.key)}
                                onChange={(e) => {
                                    this.changeAffiliateCarType(e, car.key)
                                }}
                                text={car.title}
                                disabled={!this.state.editable}
                              />
                            )
                          })
                        }
                      </div>
                    </FormGroup>
                  }
                </div>
              </Modal.Body>
              <Modal.Footer>
                {!this.props.permissions || this.props.permissions.actions ? (
                  this.state.editable ? (
                    <Button
                      className={"btn-save mr-md"}
                      onClick={this.saveDialogConfigureForm}
                    >
                      <Translate value="carSetting.Save" />
                    </Button>
                  ) : (
                      <Button
                        className={"btn-save mr-md"}
                        onClick={e => {
                          this.handleMenuClick("changeStatus", e);
                        }}
                      >
                        <Translate value="carSetting.Edit" />
                      </Button>
                    )
                ) : null}
                <Button className={"btn-cancel"} onClick={this.closeDialogConfigureForm}>
                  <Translate value="carSetting.Cancel" />
                </Button>
              </Modal.Footer>
            </Modal>
          )
        }
        <FormCarType
          commonData={this.props.commonData}
          settingActions={this.props.settingActions}
          fleetId={this.props.auth.selectedFleet.fleetId}
          s3Host={this.props.auth.selectedFleet.s3Host}
          notification={this.context.notification}
          updateDataList={this.updateDataList}
          showModalCarType={this.state.showModalCarType}
          dataEdit={this.state.valueEditCarType}
          permissions={this.props.permissions}
          closeModal={() => { this.setState({ showModalCarType: false, valueEditCarType: null}) }}
        />
        <ImportAssignRate
          isShow={this.state.isShowImport}
          import={this.props.settingActions.carTypeImportAssignRate}
          closeDialog={() => { this.setState({isShowImport: false})}}
          callback={this.updateDataList}
          prepareParamExport={this.prepareParamExport}
          urlExport={this.props.settingActions.carTypeExportRate}
          user={this.props.auth.user}
        />
        <ButtonToolbar
          className="text-center header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            {this.props.auth.selectedFleet?.transport?.enable && !this.props.permissions || this.props.permissions.actions ? (
              <Button className="btn-header text-add-header ml0" onClick={() => this.setState({showModalCarType: true})}>
                <Translate value="companySetting.Add" />
              </Button>
            ) : null}
          </ButtonGroup>
          <ButtonGroup className="group-right">
              {
                (_.get(this.props.permissions, 'actions', false)) && 
                <>
                  <Button className="btn-header text-add-header" onClick={this.handleImportClick}>
                    <Translate value="customer.Import" />
                  </Button>
                  <ExportComponent 
                    prepareParamExport={this.prepareParamExport}
                    urlExport={this.props.settingActions.carTypeExportRate}
                    user={this.props.auth.user}
                  />
                </>
              }
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
            noPagination={true}
          />
        </div>
      </div>
    );
  }
}

CarType.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    deliveryRateActions: bindActionCreators(deliveryRateActions, dispatch),
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    sharingRateActions: bindActionCreators(sharingRateActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CarType);
