import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import {
  Row,
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
  Overlay,
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc,
  makeRandomStringAndNumber,
} from '../../../../utils/commonFunctions';
import { connect } from 'react-redux';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { thirdPartyIntegration } from '../../../../constants/commondata';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import { socketConfigs } from '../../../../constants/socketConfigs';
import { socketDispatchApi } from '../../../../utils/socketUtils';
import './index.scss';
import Corporate from '../components/corporate';

const HolidayTaxis = ({ ...props }, context) => {
  // const { notification } = useContext('context')
  const [validInfo, setValidInfo] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [corporateVal, setCorporateVal] = useState('');
  const [isAutoAccept, setIsAutoAccept] = useState(false);
  useEffect(() => {
    getDetail()
  }, []);

  const getDetail = () => {
    props.settingActions
      .get3rdPartyIntegration({
        fleetId: props.auth.selectedFleet.fleetId,
        thirdParty: thirdPartyIntegration.holidaytaxis,
      })
      .then((response) => {
        if(response?.res) {
          console.log(response)
          setApiKey(response?.res?.integration?.apiKey)
          setCorporateVal(response?.res?.credential?.corporate || '')
          setIsAutoAccept(response?.res?.integration?.isAutoAccept)
          setDisable(true)
        }
      });
  }

  const onSocketDispatch = () => {
    socketDispatchApi.remove(socketConfigs.receive.booking.bookingSynced);
    socketDispatchApi.on(
      socketConfigs.receive.booking.bookingSynced,
      () => {
        context.notification(
          'success',
          I18n.t('cue.syncingCompleted')
        );
        socketDispatchApi.remove(socketConfigs.receive.booking.bookingSynced);
      }
    );
  };

  const handleSaveApiKey = async () => {
    // case press button edit integration
    if (isDisable) {
      setDisable(false);
      return;
    }

    setIsSubmited(true);
    if (!CCLiteCommonFunc.isFormValid(validInfo)) {
      return;
    }

    props.loadingBarActions.showLoadingSpiner();

    let params = {
      fleetId: props.auth.selectedFleet.fleetId,
      apiKey: apiKey,
      thirdParty: thirdPartyIntegration.holidaytaxis,
      corporate: corporateVal,
      isAutoAccept: isAutoAccept,
    };
    try {
      const response = await props.settingActions.addHolidayTaxis(
        params
      );
      const data = response?.res || {};
      if (data?.apiKey) {
        setApiKey(data?.apiKey)
        setCorporateVal(data?.corporate || '');
        setDisable(true)
        props.settingActions.syncThirdPartyBookingSetKey({
          fleetId: props.auth.selectedFleet.fleetId,
          thirdParty: thirdPartyIntegration.holidaytaxis
        }).then(() => {
          onSocketDispatch()
        })
        context.notification(
          'success',
          'API key has been saved successfully.'
        );
      } else {
        context.notification(
          'error',
          'The errors occurred while saved API key.'
        );
      }
      props.loadingBarActions.hideLoadingSpiner();
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
    }
  };

  const handleApiKey = (e) => {
    setApiKey(e.target.value);
  };

  const handleChangeCorporate = (e) => {
    setCorporateVal(e.target.value);
  };

  const handleIsAutoAcceptChange = (e) => {
    setIsAutoAccept(e.target.checked)
  };

  const renderIntegration = () => (
    <>
      <h4>{I18n.t('holidayTaxis.title')}</h4>
      <p>{I18n.t('holidayTaxis.note')}</p>
      <FormGroup
        className={
          !isSubmited ? null : validInfo.clientKey === false ? 'error' : null
        }
      >
        <Form.Label>
          <Translate value={`holidayTaxis.apiKey`} />
          <span className="require"> *</span>
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          value={apiKey}
          minLength={1}
          maxLength={128}
          onChange={handleApiKey}
          placeholder={I18n.t(`holidayTaxis.placeHolderApiKey`)}
          disabled={isDisable}
        />
        <Validator id={'clientKey'} callback={validatorCallback}>
          <ValidCase
            valid={!Validation.isStringEmpty(apiKey)}
            message={I18n.t('messages.commonMessages.Required_field')}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>

      <Corporate
        handleChangeCorporate={handleChangeCorporate}
        corporateVal={corporateVal}
        thirdParty="HolidayTaxis"
        isDisable={isDisable}
      />
      <FormGroup>
        <CcCheckbox
          disabled={isDisable}
          checked={isAutoAccept}
          onChange={handleIsAutoAcceptChange}
          text={"Auto accept booking"}
        />
      </FormGroup>
      <div>
        <Button className="btn-save mr-md" onClick={handleSaveApiKey}>
          {isDisable
            ? I18n.t('bookingdetail.Edit')
            : I18n.t('bookingdetail.Save')}
        </Button>
        { 
          isDisable ? (
            <Button
              className="btn-cancel"
              onClick={() => setShowModal(true)}
              disabled={!isDisable}
            >
              {I18n.t('bookingdetail.Remove')}
            </Button>
          ) : (
            <Button
              className="btn-cancel"
              onClick={() => getDetail()}
            >
              {I18n.t('bookingdetail.Cancel')}
            </Button>
          )
        }
      </div>
    </>
  );

  const validatorCallback = (id, valid) => {
    if (validInfo[id] !== valid) {
      setValidInfo({
        ...validInfo,
        [id]: valid,
      });
    }
  };

  const [showModal, setShowModal] = useState(false);
  const renderModalDeleteConfirm = () => {
    return (
      <Modal onHide={() => setShowModal(false)} show={showModal} backdrop={true} className={'confirm'}>
        <Modal.Header closeButton>
          <Modal.Title>3rd party integration/ HolidayTaxis</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to de-integrate with HolidayTaxis ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={'btn-save mr-md'}
            onClick={confirmRemoveApiKey}
          >
            <Translate value="carSetting.Yes" />
          </Button>
          <Button className={'btn-cancel'} onClick={() => setShowModal(false)}>
            <Translate value="carSetting.No" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const confirmRemoveApiKey = async () => {
    props.loadingBarActions.showLoadingSpiner();
    props.settingActions
      .deleteHolidayTaxis({
        fleetId: props.auth.selectedFleet.fleetId,
        thirdParty: thirdPartyIntegration.holidaytaxis,
      })
      .then((response) => {
        props.loadingBarActions.hideLoadingSpiner();
        if (response.error === null) {
          context.notification(
            'success',
            'Integrate has been deleted successfully.'
          );
          props.settingActions.syncThirdPartyBookingRemoveKey({
            fleetId: props.auth.selectedFleet.fleetId,
            thirdParty: thirdPartyIntegration.holidaytaxis
          }).then(() => {
          })
          setShowModal(false);
          setDisable(false);
          setIsSubmited(false)
          setApiKey('')
          setCorporateVal('')
        } else {
          context.notification(
            'error',
            'The errors occurred while remove Integrate'
          );
        }
      });
  };

  return (
    <div className="holidayTaxis">
      <Row>
        <Col md={6} xs={12}>
          <div className="group-general">
            {renderIntegration()}
            {renderModalDeleteConfirm()}
          </div>
        </Col>
      </Row>
    </div>
  );
};

HolidayTaxis.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HolidayTaxis);
