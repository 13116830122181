export const DynamicFareColumns = [
  {
    key: "zoneName",
    label: "dynamicFare.operationZone",
    textEllipsis: true
  },
  {
    key: "activeFares",
    label: "dynamicFare.activeFare",
    width: 150
  }
]