import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
    FormGroup,
    Modal,
    Button,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import Moment from 'react-moment';
import PropTypes from 'prop-types';

import {
    dispatchOuterTableChange
} from './../../../utils/commonFunctions';
import TableActions from './../../../components/table/tableAction/TableActions';
import StickyTable from './../../../components/table/stickyTable/StickyTable';

import { driversListColumn } from './DriversListColumn';
import ModifyDriverOrder from './ModifyDriverOrder';

import * as queuingAreaActions from './../../../actions/queuingAreaActions';
import * as loadingBarActions from './../../../actions/loadingBarActions';
import moment from 'moment';

class DriversList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortType: -1,
            sortName: '',
            footerLoading: true,
            rowHeight: 50,
            tableHeight: 500,
            footerData: {
                limit: 20,
                total: 0,
                page: 0,
                active: 0
            },
            isShowOrderModal: false,
            removeDriver: null,
            driverNeedChange: null,
            isEditOrder: false,
            isAddDriver: false,
            valid: {
                driver: false,
                order: false,
                reason: false
            },
            isSubmited: false,
            driverId: null,
            order: 0,
            reason: ''
        };
    }

    componentDidMount() {
        this.getListDriversCheckinQueue();
    }

    shouldComponentUpdate(nextProps) {
        const { isActive } = nextProps || {};
        if (!isActive) return false;
        else if (isActive && !this.props.isActive) dispatchOuterTableChange();
        return true;
    }

    sortDriverList = (driver1, driver2) => {
        const { queueScore: queueScore1 } = driver1;
        const { queueScore: queueScore2 } = driver2;

        if (queueScore1 > queueScore2) {
            return 1;
        } else if (queueScore1 < queueScore2) {
            return -1;
        }
        return 0;
    }

    getListDriversCheckinQueue = () => {
        if (this.state.isLoading) return;
        const { queueId = '' } = this.props || {};
        const params = {
            _id: queueId,
            fleetId: this.props.auth.selectedFleet.fleetId
        };

        this.setState({
            isLoading: true
        });

        this.props.queuingAreaActions.fetchDriverListInQueue(params._id, params.fleetId).then(data => {
            if (data.ok && data.res) {
                const drivers = data.res.sort(this.sortDriverList);
                _.forEach(drivers, (driver, index) => {
                    driver.order = index + 1;

                    if (driver.queueCheckInTime) {
                        const ms = moment().diff(moment(driver.queueCheckInTime));
                        const durationInMs = moment.duration(ms);
                        const durationFormat = Math.floor(durationInMs.asHours()) + moment.utc(ms).format(":mm");
                        const duration = !durationFormat || durationInMs < 0 ? '00:00' : durationFormat;
                        driver.duration = duration;
                    }
                })
                this.setState({
                    drivers: drivers,
                    isLoading: false,
                    order: drivers.length + 1
                });
            } else {
                if (data.error && data.error.errorCode) {
                    let message = I18n.t(`errors.${data.error.errorCode}`);
                    if (data.error.paymentReturnCode == 407 && data.error.message) {
                        message += `Please check (${data.error.message})`;
                    }
                    this.context.notification('error', message);
                }
                this.setState({
                    isLoading: false
                });
            }
        });
    };

    handlePaginationSelect = page => this.getListDriversCheckinQueue(page);

    handleNumItemsPerPageChange = limit => this.getListDriversCheckinQueue(0, limit);

    handleMenuClick = (key, driver) => {
        const driverNeedChange = driver ? {
            value: driver.driverId,
            label: `${driver.firstName} ${driver.lastName} - ${driver.phone}`
        } : null;
        if (driver && driver.order) driverNeedChange.order = driver.order
        switch (key) {
            case 'View':
                return this.setState({
                    isShowOrderModal: true,
                    driverNeedChange,
                    isEditOrder: false,
                    isAddDriver: false
                });
            case 'changeOrder':
                return this.setState({
                    isShowOrderModal: true,
                    driverNeedChange,
                    isEditOrder: true,
                    isAddDriver: false
                });
            case 'Add':
                return this.setState({
                    isShowOrderModal: true,
                    driverNeedChange: null,
                    isEditOrder: false,
                    isAddDriver: true
                });
            case 'remove':
                return this.setState({
                    removeDriver: driver
                });
            case 'changeStatus':
                return this.setState({
                    isEditOrder: true,
                    isAddDriver: false
                });
            default:
                return;
        }
    }

    closeDialogForm = () => {
        const newState = {};
        const {
            isShowOrderModal,
            removeDriver,
            isEditOrder,
            isAddDriver
        } = this.state || {};
        if (isShowOrderModal) newState.isShowOrderModal = false;
        if (removeDriver) newState.removeDriver = null;
        if (isShowOrderModal) newState.driverNeedChange = null;
        if (isEditOrder) newState.isEditOrder = false;
        if (isAddDriver) newState.isAddDriver = false;
        this.setState({
            ...newState
        });
    }

    getTableHeight = () => {
        return 500;
    }

    getTableColumns = () => {
        const tableColumns = Object.assign([], driversListColumn);
        const removeColumns = [];
        const {
            permissions,
            auth: { selectedFleet } = {},
            editable
        } = this.props;
        // hidden columns
        _.remove(tableColumns, col => removeColumns.indexOf(col.key) !== -1);

        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'order':
                    col.customCell = driver => driver.queueUpdateByOperator ? (
                        <OverlayTrigger
                            placement="top"
                            delayShow={300}
                            overlay={
                                <Tooltip id="tooltip">
                                    <span>{I18n.t('queuingArea.drivers.updateBy')}</span>
                                </Tooltip>
                            }
                        >
                            <p style={{ margin: 0 }}>
                                {driver.order}
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{
                                    color: 'yellow',
                                    marginLeft: 5,
                                    fontSize: 14,
                                    float: 'right'
                                }}></i>
                            </p>
                        </OverlayTrigger>
                    ) : <span>{driver.order}</span>
                    break;
                case 'fullName':
                    col.customCell = driver => (
                        <OverlayTrigger
                            placement="top"
                            delayShow={300}
                            overlay={
                                <Tooltip id="tooltip">
                                    <span>{`${driver.firstName} ${driver.lastName}`}</span>
                                </Tooltip>
                            }
                        >
                            <a
                                className="clickable"
                                onClick={() => this.handleMenuClick('View', driver)}
                            >
                                {`${driver.firstName} ${driver.lastName}`}
                            </a>
                        </OverlayTrigger>
                    );
                    break;
                case 'queueCheckInTime':
                    col.customCell = driver => {
                        const { timezone = 'UTC' } = selectedFleet || {};
                        const timeInLocal = driver.queueCheckInTime ? moment(driver.queueCheckInTime) : null
                        const timeInTimezone = timeInLocal ? timeInLocal.tz(timezone).format('HH:mm') : null;

                        return <span>{
                            timeInTimezone ? timeInTimezone : ''
                        }</span>
                    }
                    break;
                case 'actions':
                    col.customCell = (driver, rowIndex) => {
                        if (permissions && permissions.actions) {
                            const actionItems = [
                                {
                                    label: 'queuingArea.drivers.changeOrder',
                                    eventKey: 'changeOrder'
                                },
                                {
                                    label: 'queuingArea.drivers.remove',
                                    eventKey: 'remove'
                                }
                            ];

                            return (
                                <TableActions
                                    rowIndex={rowIndex}
                                    rowData={driver}
                                    onSelect={eventKey => {
                                        this.handleMenuClick(eventKey, driver);
                                    }}
                                    totalRow={this.state.drivers.length}
                                    rowHeight={this.state.rowHeight}
                                    menuItems={actionItems}
                                    tableHeight={this.state.tableHeight}
                                    tableId={"driver-table-id"}
                                />
                            );
                        }
                        return (
                            <a
                                onClick={e => {
                                    e.preventDefault();
                                    this.handleMenuClick('View', driver);
                                }}
                                href="/"
                            >
                                <Translate value="queuingArea.View" />
                            </a>
                        );
                    };
                    break;
                default:
                    break;
            }
        });

        return tableColumns;
    }

    saveDialogForm = () => {
        if (this.driverModifier) return this.driverModifier.saveDialogForm();
    }

    confirmRemoveDriver = () => {
        const { removeDriver } = this.state || {};
        if (!removeDriver) return;
        const { driverId } = removeDriver || {};
        if (!driverId) return;
        const params = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            driverId,
            queueId: this.props.queueId
        }

        this.props.queuingAreaActions
            .removeDriverFromQueue(params)
            .then(data => {
                if (!data.ok) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t(`errors.${data.error.errorCode}`)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('queuingArea.drivers.removeDriverFail')
                        );
                    }
                } else {
                    if (!data.res || !data.res.code)
                        this.context.notification(
                            'error',
                            I18n.t('queuingArea.drivers.removeDriverFail')
                        );
                    else this.context.notification(
                        'success',
                        I18n.t('queuingArea.drivers.removeDriverSuccess')
                    );
                }
                this.getListDriversCheckinQueue();
                this.closeDialogForm();
            });
    }

    renderOrderDetailAndModify = () => {
        const {
            valid,
            driverNeedChange,
            isEditOrder,
            isAddDriver,
            order,
            reason,
            isSubmited,
            drivers = []
        } = this.state || {};
        const {
            permissions = {},
            carIds = [],
            carList = [],
            openQueue = true,
            queueId,
            editable
        } = this.props || {};
        const {
            actions = false
        } = permissions || {};

        return (
            <Modal onHide={this.closeDialogForm} show={true} backdrop={true} dialogClassName={"modal-max-600"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {driverNeedChange ? (
                            permissions && actions && isEditOrder ? (
                                <Translate value="queuingArea.drivers.changeOrderDriver" />
                            ) : (
                                    <Translate value="queuingArea.drivers.detailOrderDriver" />
                                )
                        ) : (
                                <Translate value="queuingArea.drivers.insertDriver" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ModifyDriverOrder
                        ref={driverModifier => {
                            if (driverModifier) this.driverModifier = driverModifier.getWrappedInstance();
                        }}
                        drivers={drivers}
                        isSubmited={isSubmited}
                        actions={permissions && actions}
                        isEditOrder={isEditOrder}
                        isAddDriver={isAddDriver}
                        driverNeedChange={driverNeedChange}
                        order={order}
                        reason={reason}
                        carIds={carIds}
                        carList={carList}
                        openQueue={openQueue}
                        queueId={queueId}
                        valid={valid}
                        ValidatorCallback={this.ValidatorCallback}
                        handleInputChange={this.handleInputChange}
                        handleSelectDriver={this.handleSelectDriver}
                        getListDriversCheckinQueue={this.getListDriversCheckinQueue}
                        closeDialogForm={this.closeDialogForm}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {
                        permissions && actions ? (
                            isEditOrder || isAddDriver ? <Button
                                className={"btn-save mr-md"}
                                onClick={this.saveDialogForm}

                            >
                                <Translate value="regularSetting.Save" />
                            </Button> : <Button
                                className={"btn-save mr-md"}
                                onClick={e => {
                                    this.handleMenuClick("changeStatus", e);
                                }}
                            >
                                    <Translate value="regularSetting.Edit" />
                                </Button>
                        ) : null
                    }
                    <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                        <Translate value="regularSetting.Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderRemoveConfirmModal = () => {
        return (
            <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Translate value="queuingArea.drivers.removeDriver" />
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Translate value="queuingArea.drivers.removeDriverConfirm" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className={"btn-save mr-md"}
                        onClick={this.confirmRemoveDriver}
                    >
                        <Translate value="queuingArea.drivers.Yes" />
                    </Button>
                    <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                        <Translate value="queuingArea.drivers.No" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const {
            isShowOrderModal,
            removeDriver
        } = this.state || {};
        const {
            language,
            driverFilter
        } = this.props || {};

        const bodyData = (!driverFilter || !driverFilter.length)
            ? (this.state.drivers || [])
            : (this.state.drivers || []).filter(driver => {
                const { firstName = '', lastName = '', phone } = driver || {};
                const fullName = (`${firstName} ${lastName}`).toLowerCase();
                return fullName.includes(driverFilter) || phone.includes(driverFilter);
            });

        return (
            <React.Fragment>
                {
                    removeDriver && this.renderRemoveConfirmModal()
                }
                {
                    isShowOrderModal && this.renderOrderDetailAndModify()
                }
                <div className="gridViewTable mt" id="driver-table-id">
                    <StickyTable
                        columns={this.getTableColumns()}
                        bodyData={bodyData}
                        rowHeight={this.state.rowHeight}
                        nopagination={true}
                        handlePaginationSelect={this.handlePaginationSelect}
                        handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                        sortType={this.state.sortType}
                        sortName={this.state.sortName}
                        getTableHeight={this.getTableHeight}
                        language={language}
                    />
                </div>
            </React.Fragment>
        )
    }
}

/* handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
handlePaginationSelect={this.handlePaginationSelect}
sortHandle={this.handleSortDriverClick} */
DriversList.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        commonData: state.commonData,
        language: state.i18n,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { withRef: true }
)(DriversList);