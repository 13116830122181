import _ from 'lodash';

export const fields = [
  {
    key: 'driverInfo',
    label: 'Driver info',
    sub: [
      {
        key: 'driverName',
        label: 'report.result.dailyDriver.driverName',
        summary: 'driverName',
        mutate: (value, doc) => _.get(doc, 'driverInfo.driverName', ''),
      },
      {
        key: 'idPermitType',
        label: 'report.result.idPermitType',
        mutate: (value, doc) => _.get(doc, 'driverInfo.idPermitType', ''),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          const driverFields = _.get(selectedFleet, 'driverFields', []);
          return !_.find(driverFields, (df) => df.key === 'idType');
        },
      },
      {
        key: 'driverIDPermit',
        label: 'report.result.dailyDriver.driverIDPermit',
        summary: 'driverIDPermit',
        mutate: (value, doc) => _.get(doc, 'driverInfo.driverIDPermit', ''),
      },
      {
        key: 'ssnNumber',
        label: 'report.result.dailyDriver.ssnNumber',
        summary: 'ssnNumber',
        checkShowFromProps: 'selectedFleet.driverSettlement',
        isNotShow: (driverSettlement) => !driverSettlement,
        mutate: (value, doc) => _.get(doc, 'driverInfo.ssnNumber', ''),
      },
      {
        key: 'driverPhone',
        label: 'report.result.dailyDriver.driverPhone',
        summary: 'driverPhone',
        mutate: (value, doc) => _.get(doc, 'driverInfo.driverPhone', ''),
      },
      {
        key: 'driverEmail',
        label: 'report.result.dailyDriver.driverEmail',
        summary: 'driverEmail',
        mutate: (value, doc) => _.get(doc, 'driverInfo.driverEmail', ''),
      },
      {
        key: 'plateNumber',
        label: 'report.result.dailyDriverCar.licensePlate',
        summary: 'plateNumber',
        mutate: (value, doc) => _.get(doc, 'driverInfo.plateNumber', ''),
      },
      {
        key: 'companyName',
        label: 'report.result.dailyDriver.companyName',
        summary: 'companyName',
        mutate: (value, doc) => _.get(doc, 'driverInfo.companyName', ''),
      },
      {
        key: 'driverAddress',
        label: 'report.result.dailyDriver.driverAddress',
        summary: 'driverAddress',
        mutate: (value, doc) => _.get(doc, 'driverInfo.driverAddress', ''),
      },
    ],
  },
  {
    key: 'driverWorkingHours',
    label: 'report.result.dailyDriver.driverWorkingHours',
    sub: [
      {
        key: 'workingHoursOnline',
        label: 'report.result.dailyDriver.workingHoursOnline',
        summary: 'workingHoursOnline',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.online', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.online', 0),
      },
      {
        key: 'workingHoursAvailable',
        label: 'report.result.dailyDriver.workingHoursAvailable',
        summary: 'workingHoursAvailable',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.available', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.available', 0),
      },
      {
        key: 'workingHoursPob',
        label: 'report.result.dailyDriver.workingHoursPob',
        toolTip: 'report.result.dailyDriver.workingHoursPobToolTip',
        summary: 'workingHoursPob',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.pob', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.pob', 0),
      },
      {
        key: 'workingHoursNotAvailable',
        label: 'report.result.dailyDriver.workingHoursNotAvailable',
        summary: 'workingHoursNotAvailable',
        summaryMutate: (value, doc) =>
          _.get(doc, 'workingHours.notAvailable', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.notAvailable', 0),
      },
      {
        key: 'workingHoursAccepted',
        label: 'report.result.dailyDriver.workingHoursAccepted',
        summary: 'workingHoursAccepted',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.accepted', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.accepted', 0),
      },
      {
        key: 'workingHoursIgnore',
        label: 'report.result.dailyDriver.workingHoursIgnore',
        summary: 'workingHoursIgnore',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.ignore', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.ignore', 0),
      },
      {
        key: 'workingHoursReject',
        label: 'report.result.dailyDriver.workingHoursReject',
        summary: 'workingHoursReject',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.reject', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.reject', 0),
      },
      {
        key: 'workingHoursCancel',
        label: 'report.result.dailyDriver.workingHoursCancel',
        summary: 'workingHoursCancel',
        summaryMutate: (value, doc) => _.get(doc, 'workingHours.cancel', 0),
        mutate: (value, doc) => _.get(doc, 'workingHours.cancel', 0),
      },
    ],
  },
  {
    key: 'distance',
    label: 'Driver traveled distance',
    label: 'report.result.dailyDriver.distance',
    sub: [
      {
        key: 'availableDistance',
        label: 'report.result.dailyDriver.availableDistance',
        summary: 'availableDistance',
        summaryMutate: (value, doc) =>
          _.get(doc, 'distance.availableDistance', 0),
        mutate: (value, doc) => _.get(doc, 'distance.availableDistance', 0),
        headerUnit: 'unitDistance',
      },
      {
        key: 'onTheWayDistance',
        label: 'report.result.dailyDriver.otwDistance',
        toolTip: 'report.result.dailyDriver.otwDistanceToolTip',
        summary: 'onTheWayDistance',
        summaryMutate: (value, doc) =>
          _.get(doc, 'distance.onTheWayDistance', 0),
        mutate: (value, doc) => _.get(doc, 'distance.onTheWayDistance', 0),
        headerUnit: 'unitDistance',
      },
      {
        key: 'pobDistance',
        label: 'report.result.dailyDriver.pobDistance',
        toolTip: 'report.result.dailyDriver.pobDistanceToolTip',
        summary: 'pobDistance',
        summaryMutate: (value, doc) => _.get(doc, 'distance.pobDistance', 0),
        mutate: (value, doc) => _.get(doc, 'distance.pobDistance', 0),
        headerUnit: 'unitDistance',
      },
    ],
  },
  {
    key: 'completedTrips',
    label: 'Completed trips',
    sub: [
      {
        key: 'completedTripsTotal',
        label: 'report.result.dailyDriver.completedTripsTotal',
        summary: 'completedTripsTotal',
        summaryMutate: (value, doc) => _.get(doc, 'completedTrips.total', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.total', 0),
      },
      {
        key: 'completedTripsCc',
        label: 'report.result.dailyDriver.completedTripsCc',
        summary: 'completedTripsCc',
        summaryMutate: (value, doc) => _.get(doc, 'completedTrips.cc', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.cc', 0),
      },
      {
        key: 'completedTripsCarHailing',
        label: 'report.result.dailyDriver.completedTripsCarHailing',
        summary: 'completedTripsCarHailing',
        summaryMutate: (value, doc) =>
          _.get(doc, 'completedTrips.carHailing', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.carHailing', 0),
      },
      {
        key: 'completedTripsStreetSharing',
        label: 'report.result.dailyDriver.completedTripsStreetSharing',
        summary: 'completedTripsStreetSharing',
        summaryMutate: (value, doc) =>
          _.get(doc, 'completedTrips.streetSharing', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.streetSharing', 0),
      },
      {
        key: 'completedTripsPaxApp',
        label: 'report.result.dailyDriver.completedTripsPaxApp',
        summary: 'completedTripsPaxApp',
        summaryMutate: (value, doc) => _.get(doc, 'completedTrips.paxApp', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.paxApp', 0),
      },
      {
        key: 'completedTripsWebBooking',
        label: 'report.result.dailyDriver.completedTripsWebBooking',
        summary: 'completedTripsWebBooking',
        summaryMutate: (value, doc) =>
          _.get(doc, 'completedTrips.webBooking', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.webBooking', 0),
      },
      {
        key: 'completedTripsMDispatcher',
        label: 'report.result.dailyDriver.completedTripsMDispatcher',
        summary: 'completedTripsMDispatcher',
        summaryMutate: (value, doc) =>
          _.get(doc, 'completedTrips.mDispatcher', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.mDispatcher', 0),
      },
      {
        key: 'completedTripsKiosk',
        label: 'report.result.dailyDriver.completedTripsKiosk',
        summary: 'completedTripsKiosk',
        summaryMutate: (value, doc) => _.get(doc, 'completedTrips.kiosk', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.kiosk', 0),
      },
      {
        key: 'completedTripsApi',
        label: 'report.result.dailyDriver.completedTripsApi',
        summary: 'completedTripsApi',
        summaryMutate: (value, doc) => _.get(doc, 'completedTrips.API', 0),
        mutate: (value, doc) => _.get(doc, 'completedTrips.API', 0),
      },
    ],
  },
  {
    key: 'ccActivity',
    label: 'CC activity',
    label: 'report.result.dailyDriver.CC_activity',
    sub: [
      {
        key: 'ccActivityDispatchedTrips',
        label: 'report.result.dailyDriver.ccActivityDispatchedTrips',
        summary: 'ccActivityDispatchedTrips',
        summaryMutate: (value, doc) =>
          _.get(doc, 'ccActivity.dispatchedTrips', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.dispatchedTrips', 0),
      },
      {
        key: 'ccActivityAccepted',
        label: 'report.result.dailyDriver.ccActivityAccepted',
        summary: 'ccActivityAccepted',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.accepted', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.accepted', 0),
      },
      {
        key: 'ccActivityReject',
        label: 'report.result.dailyDriver.ccActivityReject',
        summary: 'ccActivityReject',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.reject', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.reject', 0),
      },
      {
        key: 'ccActivityIgnore',
        label: 'report.result.dailyDriver.ccActivityIgnore',
        summary: 'ccActivityIgnore',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.ignore', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.ignore', 0),
      },
      {
        key: 'ccActivityCancelByPassenger',
        label: 'report.result.dailyDriver.ccActivityCancelByPassenger',
        summary: 'ccActivityCancelByPassenger',
        summaryMutate: (value, doc) =>
          _.get(doc, 'ccActivity.cancelByPassenger', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.cancelByPassenger', 0),
      },
      {
        key: 'ccActivityCancelByDriver',
        label: 'report.result.dailyDriver.ccActivityCancelByDriver',
        summary: 'ccActivityCancelByDriver',
        summaryMutate: (value, doc) =>
          _.get(doc, 'ccActivity.cancelByDriver', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.cancelByDriver', 0),
      },
      {
        key: 'ccActivityCancelByCc',
        label: 'report.result.dailyDriver.ccActivityCancelByCc',
        summary: 'ccActivityCancelByCc',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.cancelByCc', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.cancelByCc', 0),
      },
      {
        key: 'ccActivityCancelByAPI',
        label: 'report.result.dailyDriver.ccActivityCancelByAPI',
        summary: 'ccActivityCancelByAPI',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.cancelByAPI', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.cancelByAPI', 0),
      },
      {
        key: 'ccActivityNoShow',
        label: 'report.result.dailyDriver.ccActivityNoShow',
        summary: 'ccActivityNoShow',
        summaryMutate: (value, doc) => _.get(doc, 'ccActivity.noShow', 0),
        mutate: (value, doc) => _.get(doc, 'ccActivity.noShow', 0),
      },
    ],
  },
  {
    key: 'fareSummary',
    label: 'report.result.dailyDriver.fare_summary',
    sub: [
      {
        key: 'fareSummaryTotalCash',
        label: 'report.result.dailyDriver.fareSummaryTotalCash',
        summary: 'fareSummaryTotalCash',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.totalCash', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.totalCash', 0),
      },
      {
        key: 'fareSummaryTotalCreditCard',
        label: 'report.result.dailyDriver.fareSummaryTotalCreditCard',
        summary: 'fareSummaryTotalCreditCard',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareSummary.totalCreditCard', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.totalCreditCard', 0),
      },
      {
        key: 'fareSummaryTotalExternalCard',
        label: 'report.result.dailyDriver.fareSummaryTotalExternalCard',
        summary: 'fareSummaryTotalExternalCard',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareSummary.totalExternalCard', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.totalExternalCard', 0),
      },
      {
        key: 'fareSummaryTotalVippsWallet',
        label: 'report.result.dailyDriver.fareSummaryTotalVippseWallet',
        summary: 'fareSummaryTotalVippsWallet',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareSummary.vippseWallet', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.vippseWallet', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          if (
            selectedFleet.vippsWalletConfig &&
            selectedFleet.vippsWalletConfig.isActive
          ) {
            return false;
          }
          return true;
        },
      },
      {
        key: 'fareSummaryTotalDirectBilling',
        label: 'report.result.dailyDriver.fareSummaryTotalDirectBilling',
        summary: 'fareSummaryTotalDirectBilling',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareSummary.directBilling', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.directBilling', 0),
      },
      {
        key: 'fareSummaryTotalPrePaid',
        label: 'report.result.dailyDriver.fareSummaryTotalPrePaid',
        summary: 'fareSummaryTotalPrePaid',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.prePaid', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.prePaid', 0),
      },
      {
        key: 'fareSummaryTotalPaxWallet',
        label: 'report.result.dailyDriver.fareSummaryTotalPaxWallet',
        summary: 'fareSummaryTotalPaxWallet',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.paxWallet', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.paxWallet', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          if (
            selectedFleet.paxCreditWalletConfig &&
            selectedFleet.paxCreditWalletConfig.enable
          ) {
            return false;
          }
          return true;
        },
      },
      {
        key: 'fareSummaryTotalApplePay',
        label: 'report.result.dailyDriver.fareSummaryTotalApplePay',
        summary: 'fareSummaryTotalApplePay',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.applePay', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.applePay', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          if (
            selectedFleet.applePayConfig &&
            selectedFleet.applePayConfig.isActive
          ) {
            return false;
          }
          return true;
        },
      },
      {
        key: 'fareSummaryTotalTngeWallet',
        label: 'report.result.dailyDriver.fareSummaryTotalTngeWallet',
        summary: 'fareSummaryTotalTngeWallet',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.tngeWallet', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.tngeWallet', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          if (
            selectedFleet.tnGeWalletConfig &&
            selectedFleet.tnGeWalletConfig.isActive
          ) {
            return false;
          }
          return true;
        },
      },
      {
        key: 'fareSummaryTotalVippsWallet',
        label: 'report.result.dailyDriver.fareSummaryTotalVippseWallet',
        summary: 'fareSummaryTotalVippsWallet',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareSummary.vippseWallet', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.vippseWallet', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          if (
            selectedFleet.vippsWalletConfig &&
            selectedFleet.vippsWalletConfig.isActive
          ) {
            return false;
          }
          return true;
        },
      },
      {
        key: 'fareSummaryTotal',
        label: 'report.result.dailyDriver.fareSummaryTotal',
        summary: 'fareSummaryTotal',
        summaryMutate: (value, doc) => _.get(doc, 'fareSummary.total', 0),
        mutate: (value, doc) => _.get(doc, 'fareSummary.total', 0),
      },
    ],
  },
  {
    key: 'rating',
    label: 'Rating',
    isShow: 'driverThumbRating',
    sub: [
      {
        key: 'ratingNotes',
        label: 'report.result.dailyDriver.ratingNotes',
        summary: 'ratingNotes',
        isShow: 'driverThumbRating',
        summaryMutate: (value, doc) => _.get(doc, 'rating.notes', 0),
        mutate: (value, doc) => _.get(doc, 'rating.notes', 0),
      },
      {
        key: 'ratingDislike',
        label: 'report.result.dailyDriver.ratingDislike',
        summary: 'ratingDislike',
        isShow: 'driverThumbRating',
        summaryMutate: (value, doc) => _.get(doc, 'rating.dislike', 0),
        mutate: (value, doc) => _.get(doc, 'rating.dislike', 0),
      },
      {
        key: 'ratingLike',
        label: 'report.result.dailyDriver.ratingLike',
        summary: 'ratingLike',
        isShow: 'driverThumbRating',
        summaryMutate: (value, doc) => _.get(doc, 'rating.like', 0),
        mutate: (value, doc) => _.get(doc, 'rating.like', 0),
      },
    ],
  },
  {
    key: 'starsRating',
    label: 'Rating',
    isShow: 'driverStarRating',
    sub: [
      {
        key: 'stars1',
        label: 'report.result.dailyDriver.ratingStars1',
        summary: 'stars1',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.stars1', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.stars1', 0),
      },
      {
        key: 'stars2',
        label: 'report.result.dailyDriver.ratingStars2',
        summary: 'stars2',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.stars2', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.stars2', 0),
      },
      {
        key: 'stars3',
        label: 'report.result.dailyDriver.ratingStars3',
        summary: 'stars3',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.stars3', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.stars3', 0),
      },
      {
        key: 'stars4',
        label: 'report.result.dailyDriver.ratingStars4',
        summary: 'stars4',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.stars4', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.stars4', 0),
      },
      {
        key: 'stars5',
        label: 'report.result.dailyDriver.ratingStars5',
        summary: 'stars5',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.stars5', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.stars5', 0),
      },
      {
        key: 'avg',
        label: 'report.result.dailyDriver.ratingAvg',
        summary: 'avg',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.avg', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.avg', 0),
      },
      {
        key: 'notes',
        label: 'report.result.dailyDriver.ratingNotes',
        summary: 'notes',
        isShow: 'driverStarRating',
        summaryMutate: (value, doc) => _.get(doc, 'starsRating.notes', 0),
        mutate: (value, doc) => _.get(doc, 'starsRating.notes', 0),
      },
    ],
  },
  {
    key: 'fareDetails',
    label: 'Fare details',
    sub: [
      {
        key: 'fareDetailsBasicFare',
        label: 'report.result.dailyDriver.fareDetailsBasicFare',
        summary: 'fareDetailsBasicFare',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.basicFare', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.basicFare', 0),
      },
      {
        key: 'fareDetailsAirportFee',
        label: 'report.result.dailyDriver.fareDetailsAirportFee',
        summary: 'fareDetailsAirportFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.airportFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.airportFee', 0),
      },
      {
        key: 'fareDetailsMeetDriver',
        label: 'report.result.dailyCar.fareDetailsMeetDriver',
        isShow: 'meetDriver',
        summary: 'fareDetailsMeetDriver',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.meetDriver', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.meetDriver', 0),
      },
      {
        key: 'fareDetailsRushHour',
        label: 'report.result.dailyDriver.fareDetailsRushHour',
        isShow: 'rushHourActive',
        summary: 'fareDetailsRushHour',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.rushHour', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.rushHour', 0),
      },
      {
        key: 'fareDetailsHeavyTraffic',
        label: 'report.result.dailyDriver.fareDetailsHeavyTraffic',
        isShow: 'heavyTrafficActive',
        summary: 'fareDetailsHeavyTraffic',
        summaryMutate: (value, doc) =>
          _.get(doc, 'fareDetails.heavyTraffic', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.heavyTraffic', 0),
      },
      {
        key: 'fareDetailsTollFee',
        label: 'report.result.dailyCar.fareDetailsTollFee',
        isShow: 'tollFeeActive',
        summary: 'fareDetailsTollFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.tollFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.tollFee', 0),
      },
      {
        key: 'fareDetailsParkingFee',
        label: 'report.result.dailyCar.fareDetailsParkingFee',
        isShow: 'parkingFeeActive',
        summary: 'fareDetailsParkingFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.parkingFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.parkingFee', 0),
      },
      {
        key: 'fareDetailsGasFee',
        label: 'report.result.dailyCar.fareDetailsGasFee',
        isShow: 'gasFeeActive',
        summary: 'fareDetailsGasFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.gasFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.gasFee', 0),
      },
      {
        key: 'fareDetailsOtherFees',
        label: 'report.result.dailyDriver.fareDetailsOtherFees',
        isShow: 'otherFeeActive',
        summary: 'fareDetailsOtherFees',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.otherFees', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.otherFees', 0),
      },
      {
        key: 'fareDetailsAddOnPrice',
        label: 'report.result.dailyDriver.fareDetailsAddonPrice',
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
          return !addonPrice;
        },
        summary: 'fareDetailsAddOnPrice',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.addOnPrice', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.addOnPrice', 0),
      },
      {
        key: 'fareDetailsOldSubTotal',
        label: 'report.result.dailyDriver.fareDetailsOldSubTotal',
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
          return !addonPrice;
        },
        summary: 'fareDetailsOldSubTotal',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.oldSubTotal', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.oldSubTotal', 0),
      },
      {
        key: 'fareDetailsSubTotal',
        label: 'report.result.dailyDriver.fareDetailsSubTotal',
        summary: 'fareDetailsSubTotal',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.subTotal', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.subTotal', 0),
      },
      {
        key: 'fareDetailsSubTotalFF',
        label: 'report.result.dailyDriver.fareDetailsSubTotalFF',
        summary: 'fareDetailsSubTotalFF',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.subTotalFF', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.subTotalFF', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: (selectedFleet) => {
          const modules = _.get(selectedFleet, 'modules', []);
          return !_.find(
            modules,
            (m) => m.name === 'Driver to pax' || m.name === 'Pax refers Pax'
          );
        },
      },
      {
        key: 'fareDetailsTechFee',
        label: 'report.result.dailyDriver.fareDetailsTechFee',
        summary: 'fareDetailsTechFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.techFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.techFee', 0),
      },
      {
        key: 'fareDetailsBookingFee',
        label: 'report.result.dailyDriver.fareDetailsBookingFee',
        summary: 'fareDetailsBookingFee',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.bookingFee', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.bookingFee', 0),
      },
      {
        key: 'fareDetailsSalesTax',
        label: 'report.result.dailyDriver.fareDetailsSalesTax',
        isShow: 'taxActive',
        summary: 'fareDetailsSalesTax',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.salesTax', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.salesTax', 0),
      },
      {
        key: 'fareDetailsTip',
        label: 'report.result.dailyDriver.fareDetailsTip',
        isShow: 'tipActive',
        summary: 'fareDetailsTip',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.tip', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.tip', 0),
      },
      {
        key: 'fareDetailsPromoAmount',
        label: 'report.result.dailyDriver.fareDetailsPromoAmount',
        summary: 'fareDetailsPromoAmount',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.promoAmount', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.promoAmount', 0),
      },
      {
        key: 'fareDetailsTotalAmt',
        label: 'report.result.dailyDriver.fareDetailsTotalAmt',
        summary: 'fareDetailsTotalAmt',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.totalAmt', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.totalAmt', 0),
      },
    ],
  },
];

export const fareFields = [
  'fareSummaryTotalCash',
  'fareSummaryTotalCreditCard',
  'fareSummaryTotalExternalCard',
  'fareSummaryTotalVippsWallet',
  'fareSummaryTotalDirectBilling',
  'fareSummaryTotalPrePaid',
  'fareSummaryTotalPaxWallet',
  'fareSummaryTotalApplePay',
  'fareSummaryTotalTngeWallet',
  'fareSummaryTotalVippsWallet',
  'fareSummaryTotal',
  'fareDetailsBasicFare',
  'fareDetailsAirportFee',
  'fareDetailsMeetDriver',
  'fareDetailsTollFee',
  'fareDetailsParkingFee',
  'fareDetailsGasFee',
  'fareDetailsRushHour',
  'fareDetailsHeavyTraffic',
  'fareDetailsOtherFees',
  'fareDetailsSubTotal',
  'fareDetailsSubTotalFF',
  'fareDetailsTechFee',
  'fareDetailsBookingFee',
  'fareDetailsSalesTax',
  'fareDetailsTip',
  'fareDetailsPromoAmount',
  'fareDetailsTotalAmt',
  'fareDetailsAddOnPrice',
  'fareDetailsOldSubTotal',
];

export const floatFields = [
  'workingHoursOnline',
  'workingHoursAvailable',
  'workingHoursPob',
  'workingHoursNotAvailable',
  'onTheWayDistance',
  'pobDistance',
  'availableDistance',
  'avg',
];
