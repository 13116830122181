import { I18n } from 'react-redux-i18n';
import React from 'react';
import _ from 'lodash';
import { checkCorporateUser, getURLFareDeeplink } from '../../../utils/commonFunctions';
import { POINT_CAL_FARE_MODE } from '../../../constants/commondata';

const FareDeeplink = ({ fareInfo = {}, auth }) => {
  if (
    !fareInfo?.rateType ||
    !fareInfo?.rateId ||
    auth?.selectedFleet?.multiPointCalFareMode === POINT_CAL_FARE_MODE.perPoint ||
    checkCorporateUser(auth?.user)
  )
    return null;
  return (
    <a
      href={getURLFareDeeplink(fareInfo)}
      target="_blank"
      style={{ color: 'rgb(83, 177, 253)', fontSize: '14px' }}
    >
      {I18n.t('newbooking.viewRate')}
    </a>
  );
};

export default FareDeeplink;
