import * as _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'

export default function transformTransportFromExcelData(booking, index) {
  const convertPaymentType = (textValue = '') => {
    switch (textValue.toLowerCase()) {
      case 'cash':
        return 1
      case 'direct invoicing':
        return 5
      default:
        return 0
    }
  }
  let status = 'Valid'
  let time = moment.tz(booking.pickupDate, 'UTC')
  if (time.isValid()) {
    time = time.format('YYYY-MM-DD HH:mm')
  } else {
    time = booking.pickupDate
    status = 'Invalid'
  }

  const rq = {
    status,
    no: index + 1,
    travelerType: this.state.corporateId ? 1 : 0,
    bookFrom: 'CC',
    fleetId: this.props.auth.selectedFleet.fleetId,
    recurring: {
      mode: 'single',
      pickupTime: time,
    },
    psgInfo: {
      phone: booking.phone,
      email: booking.email,
      firstName: booking.passenger,
      fullName: booking.passenger,
    },
    corporateInfo: {
      corporateId: this.state.corporateId,
      clientCaseMatter: booking.tripDescription,
      chargeCode: booking.tripCode,
    },
    puPoints: [
      {
        address: booking.pickup,
      },
    ],
    doPoints: [{ address: { address: booking.dropoff } }],
    request: {
      fleetId: this.props.auth.selectedFleet.fleetId,
      psgFleetId: this.props.auth.selectedFleet.fleetId,
      pickUpTime: booking.pickupDate,
      paymentType: convertPaymentType(booking.paymentType + ''),
      pickup: { address: booking.pickup },
      destination: { address: booking.dropoff },
      notes: booking.notes || '',
      luggageNumber: booking.luggages || 0,
      rideSharing: _.toLower(booking.rideSharing) === 'yes',
      vehicleTypeRequest: booking.vehicleTypeRequest,
      paxNumber: booking.seats || 0,
      operatorNote: booking.internalNote || '',
      moreInfo: {
        flightInfo: {
          flightNumber: (booking.flightNumber || '') + '',
          type: 0,
        },
      },
      estimate: {},
    },
    externalInfo: {
      bookingReference: booking.externalId || '',
    }
  }
  if (this.state.corporateId) {
    const corporate = this.props.corporateCompany.find(
      (i) => i._id === this.state.corporateId
    )
    if (corporate) {
      rq.corporateInfo.name = corporate.companyInfo.name
    }
  }
  if (rq.request.paymentType === 0) {
    rq.status = 'Invalid'
  }

  return rq
}
