import React, { useEffect, useState } from 'react';
import { NAME_GATEWAY_FORCE_NEW_TAB } from '../../../constants/commondata';
import IframeAddCard from '../../IframeAddCard';
import { socketDispatchApi } from '../../../utils/socketUtils';
import { socketConfigs } from '../../../constants/socketConfigs';
import { I18n } from 'react-redux-i18n';

const Verify3dsCard = ({ code, auth, info, callBack3dsVerify }, context) => {
  const [isShowIframePreAuthModal, setShowIframePreAuthModal] = useState(false);
  const [urlIframePreAuth, setUrlIframePreAuth] = useState('');

  useEffect(() => {
    if (code === 113) {
      return verifyStripeSCA(info?.clientSecret);
    }

    // Payway
    if (code === 528 && info?.deepLinkUrl) {
      setShowIframePreAuthModal(true);
      setUrlIframePreAuth(info?.deepLinkUrl);
      if (
        NAME_GATEWAY_FORCE_NEW_TAB.includes(
          auth.selectedFleet?.creditConfig?.configGateway?.gateway
        )
      ) {
        window.open(info?.deepLinkUrl, '_blank');
      }
      return;
    }
  }, [code]);

	useEffect(() => {
		onSocketDispatch()
	}, [])

	const onSocketDispatch = (data) => {
    socketDispatchApi.remove(socketConfigs.receive.booking.chargeResult);
    socketDispatchApi.on(
      socketConfigs.receive.booking.chargeResult,
      (data) => {
				if (data?.returnCode === 200) {
					callBack3dsVerify(true)
				} else {
          setShowIframePreAuthModal(false)
          callBack3dsVerify()
          return
        }
      }
    );
  };

  const verifyStripeSCA = (clientSecret) => {
    const stripe = window.Stripe(auth?.selectedFleet?.stripePublicKey);
    if (stripe) {
      stripe.handleCardPayment(clientSecret).then((result) => {
        if (result.error) {
          // Display error.message in your UI.
          const errorMessage = result.error.message || result.error.code;
          context.notification('error', errorMessage);
        }
      });
    }
  };

  return (
    <IframeAddCard
      isShowModal={isShowIframePreAuthModal}
      urlIframe={urlIframePreAuth}
      auth={auth}
      title={'Verification'}
      closeModal={() => setShowIframePreAuthModal(false)}
    />
  );
};

export default Verify3dsCard;
