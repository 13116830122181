import React, { useState } from 'react'
import { Modal, OverlayTrigger, Tooltip, Button, FormControl, Form} from 'react-bootstrap'
import noteIcon from '../../assets/images/icons/noteIcon.svg'
import luggageIcon from '../../assets/images/icons/luggageIcon.svg'
import seatsIcon from '../../assets/images/icons/seatsIcon.svg'
import timePoint from '../../assets/images/icons/timePoint.svg'
import {
  STATUS_COMPLETED_DELIVERY
} from '../../constants/commondata'
import {
  checkLastActiveRecipient
} from '../../utils/commonFunctions'
import moment from 'moment'
import LocationInputContainer from './LocationInputContainer'
import { POINT_TYPE_TEXT } from '../../constants/commondata'
import { CCLiteCommonFunc } from '../../utils/commonFunctions'
import CCDropDown from '../../components/dropdown/ccDropDown'
import {
  FormGroup,
} from "react-bootstrap";
import _ from 'lodash';
import { I18n, Translate } from 'react-redux-i18n';
import { Validator, ValidCase } from '../validator'

const BOOK_FROM_TENCENT = ['tencent', 'apple', 'gg']
const STATUS_NOT_MARK_FAILED = STATUS_COMPLETED_DELIVERY
const ACTIONS_RECIPIENT = {
  view: 'view',
  remove: 'remove',
  markFailed: 'markFailed'
}

const PointInput = ({
  order,
  id,
  address,
  addressPointText,
  removePoint,
  editPoint,
  isEditDisabled,
  jobType,
  setIsShowRoutePreview,
  isBookingFromPaxApp,
  disabledAddTraveler,
  format24Hour,
  hasLocation = false,
  titleTime,
  setShowAddPoint,
  recentPlaces = {},
  options,
  setNewPoints,
  hasPartyLocation,
  checkPointInZoneSupported,
  fleetId,
  checkSupportZone,
  canRemove,
  handleCheckBlue,
  pointType,
  newBooking,
  isSubmited,
  listDirtyLocation,
  setDirtyLocation,
  isHydraBooking,
  pickUp,
  isChina,
  corporate,
  isBookDetails,
  handleShowHideMarkFailed,
  ...props
}) => {

  // const [isValidLocation, setIsValidLocation] = useState(true)
  const [validLocationInfo, setValidLocationInfo] = useState({
    valid: true,
    isSupportZone: true,
    isEmpty: true,
    hasTextNotDetailInfo: true // Nhap text nhung khong click chon address detail
  })


  const checkValidLoctionInfo = (data) => {
    if (setDirtyLocation) {
      let newListLocation = _.filter(listDirtyLocation, (locationId) => locationId != id)
      if (data.hasTextNotDetailInfo) {
        setDirtyLocation(newListLocation)
      } else {
        newListLocation.push(id)
        setDirtyLocation(newListLocation)
      }
    }
    setValidLocationInfo(data)
  }

  const handleMenuItemClick = (eventKey) => {
    if (eventKey === 'delete') {
      removePoint(id)
    }

    if (eventKey === 'edit') {
      editPoint({ id, addressPointText, order, ...props, from: address.from, address })
    }
  }

  let timeText = props.time
  if (timeText && !format24Hour) timeText = moment(timeText, 'HH:ss').format('hh:ss A')

  let addressText = address && address.address || ''

  const checkShowAddPointShuttle = () => {
    if (isEditDisabled && jobType !== 'delivery') return
    setShowAddPoint(id)
  }

  const convertStatusRecipient = (status) => {
    let statusText = ''
    switch (status) {
      case 'booked':
        statusText = 'Delivering'
        break;
      case 'arrived':
        statusText = 'Delivering'
        break;
      case 'delivered':
        statusText = 'Success'
        break;
      case 'completed':
        statusText = 'Success'
        break;
      case 'failed':
        statusText = 'Failed'
        break;
      default:
        break;
    }
    return statusText
  }

  const showShuttleLabel = () => {
    return <div
      onClick={checkShowAddPointShuttle}
      className="detailShuttle"
    >
      <span className="placeHolderPoint">{addressText || I18n.t('newbooking.Enter_a_location')}</span>
      {
        addressText &&
        <div className="metaShuttle">
          <p>Please check each customer before onboard</p>
          <div className="seatLuggage">
            <span>
              <img src={seatsIcon} />
              {props.seats}
            </span>
            <span>
              <img src={luggageIcon} />
              {props.luggage}
            </span>
            {
              props.time != '' &&
              <span>
                <img src={timePoint} />
                {timeText}
              </span>
            }
            {
              props.notes &&
              <span>
                <OverlayTrigger
                  overlay={<Tooltip id={"tooltip"}>{props.notes}</Tooltip>}
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <img src={noteIcon} />
                </OverlayTrigger>
              </span>
            }
          </div>
        </div>
      }
    </div>
  }

  const selectRecipientActions = (key) => {
    if(key === ACTIONS_RECIPIENT.view) checkShowAddPointShuttle()
    if(key === ACTIONS_RECIPIENT.remove) handleMenuItemClick('delete')
    if(key === ACTIONS_RECIPIENT.markFailed) handleShowHideMarkFailed(true, props.recipient)
  }

  const filterActionsRecipient = (recipient = {}) => {
    if(!recipient || _.isEmpty(recipient)) return []
    let actions = [{
      key: ACTIONS_RECIPIENT.view,
      label: 'bookingdetail.viewOrder',
    }]
    if(!STATUS_NOT_MARK_FAILED.includes(recipient.status) && recipient.orderIdServer) {
      actions.push({
        key: ACTIONS_RECIPIENT.markFailed,
        label: 'bookingdetail.markFailed'
      })
    }
    const recipientsData = _.get(props.data, 'deliveryInfo.recipients', [])
    if(canRemove && !checkLastActiveRecipient(recipientsData)) {
      actions.push({
        key: ACTIONS_RECIPIENT.remove,
        label: 'bookingdetail.Remove'
      })
    }
    return actions
  }

  const showDeliveryLabel = () => {
    if (pointType == POINT_TYPE_TEXT.pu) {
      return <div
        onClick={checkShowAddPointShuttle}
        className="detailShuttle"
      >
        {!addressText ?
          <span className="placeHolderPoint">{I18n.t('newbooking.Enter_a_sender')}</span>
          : <div>
            <span className="placeHolderPrimary">{props.sender.firstname} {props.sender.lastname} {props.sender.phone ? props.sender.phone.trim() : ''}</span>
            <span className="placeHolderPoint">{addressText}</span>
          </div>
        }
      </div>
    } else {
      const recipient = props.recipient || {}
      const statusBooking = props.data && props.data.status || ''
      return <div
        onClick={checkShowAddPointShuttle}
        className="detailShuttle"
      >
        {!addressText ?
          <span className="placeHolderPoint">{I18n.t('newbooking.Enter_a_recipient')}</span>
          : <div>
              <div className='topRecipient'>
                <span className="placeHolderPrimary">{props.recipient.name} {props.recipient.phone}</span>
                {isBookDetails && recipient.orderIdServer && (
                  <span className={`recipientId ${recipient.status}`}>
                    {`#${recipient.orderIdServer}`}
                    {convertStatusRecipient(recipient.status)
                      ? ` (${convertStatusRecipient(recipient.status)})`
                      : ''}
                  </span>
                )}
              </div>
              <span className="placeHolderPoint">{addressText}</span>
              <div className="divider" />
              <span className="placeHolderSecondary">{props.recipient.packageContent}</span>
              <span className="placeHolderSecondary">{props.recipient.instructions}</span>
              <span className="placeHolderSecondary">{props.recipient.orderId}</span>
              {(props.recipient.amount && parseFloat(props.recipient.amount)) ?
                <div>
                  <span className="placeHolderCOD">COD</span>
                  <span className="placeHolderInfo">{_.get(newBooking, 'locationService.currency.symbol', '')}</span>
                  <span className="placeHolderInfo">{props.recipient.amount}</span>
                </div> : null}
            </div>
        }
        {
          isBookDetails &&
          statusBooking != 'queue' &&
          !CCLiteCommonFunc.isCompleteBooking(statusBooking) &&
          <CCDropDown
            id="recipientActionsID"
            customTitle={<div className="recipient-actions-icon" />}
            hasStopPropagation
            items={filterActionsRecipient(recipient)}
            valueKey="key"
            labelKey="label"
            onSelect={e => selectRecipientActions(e, recipient)}
            selectedItems={[]}
            className="recipientActions"
            textActionBtn={I18n.t('cue.Rearrange')}
          />
        }
      </div>
    }
  }

  const convertAddressTencentByType = () => {
    const from =
      address && address.from ? address.from : props.from ? props.from : '';
    const isShowAdrBookAPI =
      props?.data?.bookFrom === 'API' && !from && address.address;
    return {
      address3rd: from === '3rd' ? address : {},
      isEditDisabled3rd:
        BOOK_FROM_TENCENT.includes(from) || isShowAdrBookAPI
        ? true
        : isEditDisabled,
      isEditDisabledWithout3rd: from === '3rd' ? true : isEditDisabled,
      addressWithout3rd:
        BOOK_FROM_TENCENT.includes(from) || isShowAdrBookAPI 
          ? address 
          : {},
    };
  };

  return (
    <FormGroup className={validLocationInfo.valid ? null : 'error'}>
      <div className="point">
        <span className="point_order">{order}</span>
        <div className="point_detail">
          {
            jobType === 'shuttle' ? (
              showShuttleLabel()
            ) : (jobType === 'delivery' ? (
              showDeliveryLabel()
            ) : (
              (
                isChina ? (
                  <>
                    {/* search 3rd tencent  */}
                    <LocationInputContainer
                      checkSupportZone={checkSupportZone}
                      checkPointInZoneSupported={checkPointInZoneSupported}
                      fleetId={fleetId}
                      address={convertAddressTencentByType().address3rd}
                      jobType={jobType}
                      hasPartyLocation={hasPartyLocation}
                      isEditDisabled={convertAddressTencentByType().isEditDisabled3rd}
                      // isShow={showAddPointPickup}
                      titleTime={titleTime}
                      recentPlaces={recentPlaces}
                      format24Hour={format24Hour}
                      options={options}
                      id={id}
                      setNewPoints={setNewPoints}
                      setValidLocationInfo={checkValidLoctionInfo}
                      validLocationInfo={validLocationInfo}
                      noValidate
                      pickUp={pickUp}
                      isHydraBooking={isHydraBooking}
                      isChina={isChina}
                      corporate={corporate}
                      input3rd
                      auth={props.auth}
                      data={props?.data}
                    />
                    <div className='lineLocation'></div>
                    {/* search tencent */}
                    <LocationInputContainer
                      checkSupportZone={checkSupportZone}
                      checkPointInZoneSupported={checkPointInZoneSupported}
                      fleetId={fleetId}
                      address={convertAddressTencentByType().addressWithout3rd}
                      jobType={jobType}
                      hasPartyLocation={hasPartyLocation}
                      isEditDisabled={convertAddressTencentByType().isEditDisabledWithout3rd}
                      // isShow={showAddPointPickup}
                      titleTime={titleTime}
                      recentPlaces={recentPlaces}
                      format24Hour={format24Hour}
                      options={options}
                      id={id}
                      setNewPoints={setNewPoints}
                      setValidLocationInfo={checkValidLoctionInfo}
                      validLocationInfo={validLocationInfo}
                      noValidate
                      pickUp={pickUp}
                      isHydraBooking={isHydraBooking}
                      isChina={isChina}
                      corporate={corporate}
                      inputTencent
                      auth={props.auth}
                      data={props?.data}
                    />
                  </>
                ) : (
                  <LocationInputContainer
                    checkSupportZone={checkSupportZone}
                    checkPointInZoneSupported={checkPointInZoneSupported}
                    fleetId={fleetId}
                    address={address || {}}
                    jobType={jobType}
                    data={props.data}
                    hasPartyLocation={hasPartyLocation}
                    isEditDisabled={isEditDisabled}
                    // isShow={showAddPointPickup}
                    titleTime={titleTime}
                    recentPlaces={recentPlaces}
                    format24Hour={format24Hour}
                    options={options}
                    id={id}
                    setNewPoints={setNewPoints}
                    setValidLocationInfo={checkValidLoctionInfo}
                    validLocationInfo={validLocationInfo}
                    noValidate
                    pickUp={pickUp}
                    isHydraBooking={isHydraBooking}
                    isChina={isChina}
                    corporate={corporate}
                    auth={props.auth}
                  />
                )
              )
            ))
          }
        </div>
        {
          // addressText &&
          canRemove &&
            (jobType !== 'delivery' ||
              (jobType === 'delivery' && !addressText) ||
              !isBookDetails) && (
              <div
                className={`point_action ${
                  jobType === 'shuttle' && addressText ? 'shuttleRm' : ''
                }`}
              >
                <span onClick={() => handleMenuItemClick('delete')}>
                  {I18n.t('newbooking.Remove')}
                </span>
              </div>
            )
        }
      </div>
      <Validator
        id="addressPickUp"
      >
        <ValidCase
          valid={validLocationInfo.isSupportZone}
          message={I18n.t('newbooking.No_support_location')}
        />
        <ValidCase
          valid={validLocationInfo.hasTextNotDetailInfo}
          message={I18n.t('Please select a valid address')}
        />
      </Validator>
      {
        isHydraBooking &&
        validLocationInfo.valid &&
        validLocationInfo.hasTextNotDetailInfo &&
        pickUp &&
        address && address.address &&
        <p style={{ color: '#FAC940' }}>This booking is serviced by an affiliate partner.</p>
      }
    </FormGroup>
  )
}

PointInput.defaultProps = {
  order: 1,
  addressPointText: '',
  address: { // exactly the same to current address schema
    cityName: '',
    locationDetails: '',
    zoneId: '',
    zoneName: '',
    from: '',
    address: '',
    instructionLink: '',
    geo: [],
    zipCode: '',
    timezone: '',
    offset: '',
    businessName: '',
    country: '',
    addressDetails: {
      floor: '',
      room: '',
      pointOfInterest: '',
      streetNumber: '',
      route: '',
      postalCode: '',
      country: '',
      cityName: '',
      political: '',
      countryCode: ''
    }, // exactly the same to current address details schema
  },
  seats: 0,
  jobType: '',
  luggage: 0,
  //['arrived', 'engaged', 'booked', 'accepted']
  status: '',
  // format 'HH:mm' 24h
  time: '00:00',
  notes: '',
  id: '',
  typePoint: '',
  pointType: '',
  passengers: [{
    userId: '',
    fullName: '',
    phone: ''
  }],
  newBooking: {},
  isEditDisabled: false,
  removePoint: () => { },
  editPoint: () => { }
}

export default PointInput