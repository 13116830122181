import React, { useEffect, useState } from 'react';
import Corporate from '../components/corporate';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import './index.scss';
import { I18n } from 'react-redux-i18n';
import { thirdPartyIntegration } from '../../../../constants/commondata';

const Karhoo = (props, context) => {
  const [corporateVal, setCorporateVal] = useState('');
  const [isDisable, setDisable] = useState(true);
  const handleChangeCorporate = (e) => {
    setCorporateVal(e.target.value);
  };

  const handleSaveApiKey = async (type) => {
    // case press button edit integration
    if (type === 'update' && isDisable) {
      setDisable(false);
      return;
    }

    props.loadingBarActions.showLoadingSpiner();

    let params = {
      fleetId: props.auth.selectedFleet.fleetId,
      corporate: corporateVal,
      thirdParty: thirdPartyIntegration.karhoo,
      type: type,
    };
    try {
      const response = await props.settingActions.update3rdPartyIntegration(
        params
      );
      const data = response?.res || {};
      if (!response?.error) {
        setCorporateVal(data?.corporate || '');
        setDisable(true);
        context.notification(
          'success',
          'Corporate has been saved successfully.'
        );
      } else {
        context.notification(
          'error',
          'The errors occurred while saved Corporate.'
        );
      }
      props.loadingBarActions.hideLoadingSpiner();
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
    }
  };

  const getDetail = () => {
    props.settingActions
      .get3rdPartyIntegration({
        fleetId: props.auth.selectedFleet.fleetId,
        thirdParty: thirdPartyIntegration.karhoo,
      })
      .then((response) => {
        if (response?.res) {
          setCorporateVal(response?.res?.credential?.corporate);
          setDisable(true);
        }
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div className="karhoo">
      <Row>
        <Col md={6} xs={12}>
          <div className="group-general">
            <h4>{I18n.t('Sidebar.Settings.KarhooIntegration')}</h4>

            <Corporate
              handleChangeCorporate={handleChangeCorporate}
              corporateVal={corporateVal}
              thirdParty="Karhoo"
              isDisable={isDisable}
            />

            <div>
              <Button className="btn-save mr-md" onClick={async () => await handleSaveApiKey('update')}>
                {isDisable
                  ? I18n.t('bookingdetail.Edit')
                  : I18n.t('bookingdetail.Save')}
              </Button>
              <Button className="btn-cancel" onClick={async () => await handleSaveApiKey('delete')}>
                {I18n.t('bookingdetail.Remove')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

Karhoo.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Karhoo);
