import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportIncidentCancellationApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
class ReportIncidentCancellation extends React.Component {
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, {"name": 'Report'});
    let checkExport = _.findIndex(report.actions, {"name": "Incident & Cancellation", "Export": true});
    let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
    checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
      if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
          checkExport = false;
      }
    return (
        <div className="content">
          <BaseReport
              tittle="Incident & Cancellation"
              fieldMappings={fieldMappings}
              apiurl={reportIncidentCancellationApi}
              dateRange
              search="report.query.search.incidentCancellation"
              canceledBy
              incidentCanceledTypes
              nosummary
              noexport={!checkExport}
              company
              />
        </div>
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(ReportIncidentCancellation);