import ReportUtils from './../base/utils'
import { I18n } from 'react-redux-i18n';

export const fields = [
  {
    key: "checkBox",
    label: "report.result.payoutToDriver.checkBox",
    isCheckBox: true,
    valueKey: "driverId",
    width: 60,
    fixed: true,
    isAlwayShow: true
  },
  {
    key: "driverName",
    label: "report.result.payoutToDriver.driverName",
    summary: "totalDriver",
    fixed: true,
    isAlwayShow: true
  },
  {
    key: "bankAccountHolder",
    label: "report.result.payoutToDriver.accountHoldName"
  },
  {
    key: "bankName",
    label: "report.result.payoutToDriver.bankName",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToDriver.accountNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToDriver.iBanNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "IFSCCode",
    label: "report.result.payoutToDriver.IFSCCode",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'IndiaDefault') return false
      return true
    }
  },
  {
    key: "isBankAccountOwner",
    label: "report.result.payoutToDriver.isBankAccountOwner",
    mutate: (value, doc) => {
      if (doc.isBankAccountOwner) {
        return I18n.t(`report.result.payoutToDriver.isBankAccountOwner_${doc.isBankAccountOwner}`);
      }
      return I18n.t(`report.result.payoutToDriver.isBankAccountOwner_${doc.isBankAccountOwner}`);
    }
  },
  {
    key: "beneficiaryIDIC",
    label: "report.result.payoutToDriver.beneficiaryIDIC"
  },
  {
    key: "bankRelationship",
    label: "report.result.payoutToDriver.bankRelationship",
    mutate: (value, doc) => {
      switch (doc.bankRelationship) {
        case 1:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 2:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 3:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 4:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 5:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 6:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`).format(
            doc.relationshipOtherName ? doc.relationshipOtherName : ''
          );
        default:
          return;
      }
    }
  },
  {
    key: "creditBalance",
    label: "report.result.payoutToDriver.creditBalance",
    summary: "totalCreditBalance",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.driverDeposit) {
        return false
      }
      return true
    }
  },
  {
    key: "cashWallet",
    label: "report.result.payoutToDriver.cashWalletBalance",
    summary: "totalCashWallet"
  },
  {
    key: "holdAmount",
    label: "report.result.payoutToDriver.holdAmount",
    summary: "totalHoldAmount"
  },
  {
    key: "payoutAmount",
    label: "report.result.payoutToDriver.payoutAmount",
    summary: "totalPayoutAmount"
  },
  {
    key: "companyName",
    label: "report.result.payoutToDriver.companyName"
  },
  {
    key: "phone",
    label: "report.result.payoutToDriver.driverPhone"
  },
  {
    key: "driverNumber",
    label: "report.result.payoutToDriver.driverId",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  }
];

export const fareFields = ["creditBalance", "cashWallet", "holdAmount", "payoutAmount"];
