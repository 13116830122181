import React from 'react';
import { Translate, I18n } from "react-redux-i18n";
import {
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    Tabs,
    Tab
} from "react-bootstrap";
import _ from 'lodash';
import copy from 'copy-to-clipboard';

export default function HtmlContentInbox(props) {
    const {
        languageOption = 'single',
        messages = [],
        subject,
        inboxContentId,
        content,
        handleChangeLanguageOption,
        handleSubjectChange,
        handleContentChange,
        handleChangeInput,
        subjectValidation,
        contentValidation,
        multiSubjectValidation,
        multiContentValidation,
        errorMessage,
        editable = true,
        allowContentUpdate = false,
        drvSignUpLanguagesFull
    } = props;

    let languageOptions = {
        single: 'single',
        multi: 'multi'
    };
    const handleCopyClick = (contentId) => {
        copy(contentId)
    }

    return (
        <div>
            <FormGroup
                className="apply-to-control"
            >
                <Form.Label>
                    <Translate value="appBanner.language" />
                </Form.Label>
                <FormControl
                    as="select"
                    className="form-custom"
                    value={languageOption}
                    defaultValue={languageOption}
                    onChange={handleChangeLanguageOption}
                    disabled={!editable}
                >
                    {_.values(languageOptions).map(type => (
                        <option key={type} value={type} selected="">
                            {I18n.t(`appBanner.${type}`)}
                        </option>
                    ))}
                </FormControl>
            </FormGroup>
            {languageOption === languageOptions.single || !languageOption ?
                <>
                    <FormGroup
                        className={
                            subjectValidation === false ? "error" : null
                        }
                    >
                        <Form.Label>
                            <Translate value="message.Subject" />
                            <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                            className="form-custom"
                            type="text"
                            value={subject}
                            onChange={handleSubjectChange}
                            disabled={!editable}
                        />
                    </FormGroup>
                    {inboxContentId ? (
                        <FormGroup>
                            <Form.Label>
                                <Translate value="message.ContentId" />
                            </Form.Label>
                            <FormGroup className="qup-input-group">
                                <InputGroup className="single-addon-right">
                                    <FormControl
                                        className="form-custom"
                                        type="text"
                                        value={inboxContentId}
                                        disabled={!editable}
                                    />
                                    <Button
                                        className="copy-button"
                                        onClick={() => {handleCopyClick(inboxContentId)}}
                                    >
                                        <i class="fa fa-copy" /> Copy
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </FormGroup>
                    ):(null)}
                    <FormGroup
                        className={
                            contentValidation === false ? "error" : null
                        }
                    >
                        <Form.Label>
                            <Translate value="message.Content" />
                            <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                            className="form-custom"
                            as="textarea"
                            value={content}
                            onChange={handleContentChange}
                            disabled={!allowContentUpdate}
                        />
                    </FormGroup>
                </>
                : null}


            {languageOption === languageOptions.multi ?
                <Tabs
                    id="currencies-tabs-Ondemand"
                    className="currencies-tabs"
                    defaultActiveKey={_.get(drvSignUpLanguagesFull, '[0].languageTag', 'en-US')}
                    animation={false}
                // onSelect={tabSelectHandle}
                >
                    {drvSignUpLanguagesFull && drvSignUpLanguagesFull.map(languageObj => {
                        const language = languageObj.languageTag
                        let message = messages.find(b => b.language === language);
                        const titleLanguage = languageObj.languageVariant || languageObj.languageTag
                        return (
                            <Tab className="currencies-tab-item" key={language} eventKey={language} title={titleLanguage}>
                                <div className="multi-language-content">
                                    <FormGroup
                                        className={
                                            multiSubjectValidation === false ? "error" : null
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="message.Subject" />
                                        </Form.Label>
                                        <FormControl
                                            className="form-custom"
                                            type="text"
                                            value={message && message.subject ? message.subject : ''}
                                            onChange={e => handleChangeInput(language, 'subject', e)}
                                            disabled={!editable}
                                        />
                                    </FormGroup>
                                    {inboxContentId ? (
                                        <FormGroup>
                                            <Form.Label>
                                                <Translate value="message.ContentId" />
                                            </Form.Label>
                                            <FormGroup className="qup-input-group">
                                                <InputGroup className="single-addon-right">
                                                    <FormControl
                                                        className="form-custom"
                                                        type="text"
                                                        value={inboxContentId}
                                                        disabled={!editable}
                                                    />
                                                    <Button
                                                        className="copy-button"
                                                        onClick={() => {handleCopyClick(inboxContentId)}}
                                                    >
                                                        <i class="fa fa-copy" /> Copy
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </FormGroup>
                                    ):(null)}
                                    <FormGroup
                                        className={
                                            multiContentValidation === false ? "error" : null
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="message.Content" />
                                        </Form.Label>
                                        <FormControl
                                            className="form-custom"
                                            as="textarea"
                                            value={message && message.content ? message.content : ''}
                                            onChange={e => handleChangeInput(language, 'content', e)}
                                            disabled={!allowContentUpdate}
                                        />
                                    </FormGroup>
                                    {(!multiSubjectValidation || !multiContentValidation) &&
                                        <div className="form-group has-error">
                                            <span className="help-block">
                                                <span className="message-item">
                                                    {errorMessage}
                                                </span>
                                            </span>
                                        </div>
                                    }
                                </div>
                            </Tab>
                        )
                    })}
                </Tabs>
                : null}

        </div>
    )
}
