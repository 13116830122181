import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeliveryGGMap from './DeliveryGGMap';
import DeliveryQQMap from './DeliveryQQMap';


class MapForm extends Component {
  handleMapLoad = ref => {
    this.map = ref;
    if (this.map && this.props.location.isChina) {
      window.qq.maps.event && window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.state.center = this.map.center;
      });
    }
  };

  render() {
    const {
      bookingInfo: { deliveryInfo },
      bookingInfo: { request },
      setDistanceDuration,
      location: { isChina }
    } = this.props;

    const { pickup, recipients } = deliveryInfo;
    if (isChina) {
      return (
        <DeliveryQQMap
          onMapLoad={this.handleMapLoad}
          pickupLocation={{ lat: pickup.address.geo[1], lng: pickup.address.geo[0] }}
          recipientsLocation={recipients}
          auth={this.props.auth}
        />
      );
    }

    return (
      <DeliveryGGMap
        onMapLoad={this.handleMapLoad}
        travelMode={request.travelMode}
        pickups={this.props.pickups}
        destinations={this.props.destinations}
        setDistanceDuration={setDistanceDuration}
        fleetId={this.props.fleetId}
        auth={this.props.auth}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    bookingInfo: state.bookingDetail.data,
    location: state.commonData.location,
    commonData: state.commonData
  };
}

export default connect(mapStateToProps)(MapForm);