export const QuestColumns = [
    {
        key: "name",
        label: "quest_event.name",
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
        textEllipsis: true,
        width: 300
    },
    {
        key: "userType",
        label: "quest_event.userType",
        textEllipsis: true
    },
    {
        key: "type",
        label: "quest_event.type",
        textEllipsis: true,
        width: 300
    },
    {
        key: "isLuckyDraw",
        label: "quest_event.isLuckyDraw",
        textEllipsis: true
    },
    {
        key: "startDate",
        label: "quest_event.start_date",
        textEllipsis: true
    },
    {
        key: "endDate",
        label: "quest_event.end_date",
        textEllipsis: true
    },
    {
        key: "numOfQualify",
        label: "quest_event.numOfQualify",
        textEllipsis: true
    },
    {
        key: "status",
        label: "quest_event.event_progress",
        textEllipsis: true
    },
    {
        key: "isActive",
        label: "quest_event.isActive",
        textEllipsis: true
    },
    {
        key: "actions",
        label: "quest_event.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]

export const QuestsListColumns = [
    {
        key: "table-selectbox",
        label: "",
        width: 40,
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
    },
    {
        key: "metricType",
        label: "quest_event.metricType",
        textEllipsis: true,
        width: 300,
    },
    {
        key: "task",
        label: "quest_event.task",
        textEllipsis: true,
        width: 250,
    },
    {
        key: "value",
        label: "quest_event.quantity",
        textEllipsis: true,
        width: 170,
    },
    {
        key: "points",
        label: "quest_event.points",
        textEllipsis: true,
        width: 200,
    },
    {
        key: "deepLink",
        label: "quest_event.deepLink",
        textEllipsis: true,
        width: 300,
    }
]

export const PrizeOrderColumns = [
    {
        key: "reward",
        label: "quest_event.reward",
        textEllipsis: true,
    },
    {
        key: "image",
        label: "quest_event.image",
        textEllipsis: true,
    },
    {
        key: "actions",
        label: "quest_event.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]


export const RankImageColumns = [
    {
        key: "from",
        label: "quest_event.from",
        textEllipsis: true,
    },
    {
        key: "to",
        label: "quest_event.to",
        textEllipsis: true,
    },
    {
        key: "image",
        label: "quest_event.image",
        textEllipsis: true,
    },
    {
        key: "actions",
        label: "quest_event.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]



export const CriteriaListColumns = [
    {
        key: "table-selectbox",
        label: "",
        width: 60,
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
    },
    {
        key: "criteriaType",
        label: "quest_event.criteriaType",
        textEllipsis: true,
        width: 200,
    },
    {
        key: "value",
        label: "quest_event.criteriaValue",
        textEllipsis: true,
        width: 200,
    }
]
