import React, {createElement} from 'react';
import _ from 'lodash';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  Container,
  Form,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as reportActions from '../../../actions/reportActions';
import * as settingActions from '../../../actions/settingActions';
import {
  reportBookingDetailsApi,
  sendReceiptApi,
} from '../../../constants/ApiConfigs';
import * as fieldMappings from './fields';
import BaseReport from '../base';

import * as commonData from '../../../constants/commondata';
import ViewPhotoCapture from '../../../components/ViewPhotoCapture';
import { getSuppilerPermission } from '../../../utils/commonFunctions';

const FILTER_HIDE_SUPPLIER = ['company', 'bookingService', 'paymentMethod', 'paymentStatus', 'bookingType', 'promocode', 'adjustPrice', 'currency', 'corporate']
class ReportBookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: null,
      showModal: false,
      sharedBooks: [],
      additionalServices: [],
      fleetServices: [],
      extraDestination: [],
      showServices: false,
      showFleetService: false,
      showExtraDestination: false,
      showEmailInputModal: false,
      showviewPhotoCapture: false,
      showRecipientsInfo: false,
      emailString: '',
      validEmail: false,
      fiterHideSupplier: _.fromPairs(FILTER_HIDE_SUPPLIER.map(key => [key, true])),
      fieldMappings: fieldMappings
    };
  }

  componentDidMount() {
    this.props.settingActions
      .fetchAllCorporateForSelection(this.props.selectedFleet.fleetId)
      .then((resp) => {
        const corporates = resp.res.map((doc) => ({
          value: doc._id,
          label: doc.companyInfo.name,
        }));
        corporates.unshift({
          value: 'all',
          label: I18n.t('report.query.common.all'),
        });
        this.setState({ corporates });
      });
      // if supplier permission then hide some filter || fields
    if(getSuppilerPermission(this.props.auth)) {
      this.setState({
        fiterHideSupplier: _.fromPairs(FILTER_HIDE_SUPPLIER.map(key => [key, false])),
        fieldMappings: {
          ...fieldMappings,
          fields: fieldMappings?.fields?.filter(f => !f.hideWithSupplierPermission)
        }
      })
    }
  }

  handleChangeEmailInput = (e) => {
    this.setState({ emailString: e.target.value });
    let emails = e.target.value
      .trim()
      .split(/[,;]/)
      .map((email) => email.toLowerCase().trim());
    emails = _.compact(emails);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    this.setState({
      validEmail: emails.every((email) => emailRegex.test(email)),
    });
  };

  showSharedBooks = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      sharedBooks: row.sharedBooks,
      bookingId: row.bookingId,
      showModal: true,
    });
  };

  showAdditionalServices = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      row: data.row,
      additionalServices: row.additionalServices,
      bookingId: row.bookingId,
      showServices: true,
    });
  };
  showFleetServices = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      row: data.row,
      fleetServices: row.fleetServices || [],
      bookingId: row.bookingId,
      showFleetService: true,
    });
  };

  showOtherFeeNote = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      row: data.row,
      bookingId: row.bookingId,
      showOtherFeeNote: true,
    });
  };

  showExtraDestination = (link, data = {}) => {
    const row = data.row || {};
    this.setState({
      row: data.row,
      extraDestination: row.extraDestination || [],
      bookingId: row.bookingId,
      showExtraDestination: true,
    });
  };

  showSendReceipt = (link, data = {}) => {
    this.setState({
      showEmailInputModal: true,
      row: data.row,
    });
  };

  showRecipientsInfo = (link, data, e) => {
    const row = data.row || {};
    this.setState({
      showRecipientsInfo: true,
      row,
      listItems: data.row.deliveryInfo.recipients,
      keyValue: 'deliveryInfo.recipients',
      title: 'view_recipients_info',
    });
  };

  showCollectedPhotos = (link, data, e) => {
    const row = data.row || {};
    this.setState({
      showviewPhotoCapture: true,
      row,
      listItems:
        row.carTypeService === 'delivery'
          ? [data.row.deliveryInfo.pickup]
          : data.row.deliveryInfo.merchants,
      keyValue: 'collectedPhotos',
      title: 'view_good_receipt',
    });
  };

  showDeliveredPhotos = (link, data, e) => {
    const row = data.row || {};
    this.setState({
      showviewPhotoCapture: true,
      row,
      listItems: data.row.deliveryInfo.recipients,
      keyValue: 'deliveredPhotos',
      title: 'view_delivery_receipt',
    });
  };

  sendReceipt() {
    let emails = this.state.emailString
      .trim()
      .split(/[,;]/)
      .map((email) => email.toLowerCase().trim());
    emails = _.compact(emails);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const validEmail = emails.every((email) => emailRegex.test(email));

    if (validEmail) {
      this.props.reportActions
        .sendReceipt(sendReceiptApi, {
          fleetId: this.props.selectedFleet.fleetId,
          bookId: this.state.row.bookingId,
          emails,
        })
        .then((resp) => {
          this.setState({
            showEmailInputModal: false,
            emailString: '',
            validEmail: false,
          });
          if (resp.res === 'OK') {
            this.context.notification(
              'success',
              I18n.t('report.result.bookingDetails.sentReceiptSuccess')
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('report.result.bookingDetails.sentReceiptFailed')
            );
          }
        });
    }
  }

  showProofOfFailure = (href) => {
    this.setState({
      showviewPhotoCapture: true,
      href: href,
      keyValue: 'proofOfFailure',
      title: 'proofOfFailure',
    });
  };

  noteOtherFeeModal = () => (
    <Modal
      show={this.state.showOtherFeeNote}
      backdrop="static"
      bsSize="lg"
      dialogClassName="additional-service-modal"
      onHide={() => this.setState({ showOtherFeeNote: false })}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center text-bold">
          #{this.state.bookingId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center' }}>
          { _.get(this.state.row, 'otherFeesDetails', '')}
        </p>
      </Modal.Body>
    </Modal>
  );

  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    const isSupplierPermission = getSuppilerPermission(this.props.auth)
    let checkExport = _.findIndex(report.actions, {
      name: 'Booking details',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    const isAdjustPrice = _.get(
      this.props.selectedFleet,
      'priceAdjustable',
      false
    );
    const isMarkupPrice = _.get(
      this.props.selectedFleet,
      'generalSetting.markupPrice',
      false
    );
    const isShowDynamicSurcharge = _.find(
      modules,
      (m) => m.name === 'Dynamic Surcharge'
    )
      ? true
      : false;
    const isShowDynamicFare = _.find(modules, (m) => m.name === 'Dynamic Fare')
      ? true
      : false;
    const isShowIntercityRouteFilter =
      this.props.selectedFleet.intercity &&
      this.props.selectedFleet.intercity.enable
        ? true
        : false;
    const serviceModal = (
      <Modal
        show={this.state.showServices}
        backdrop="static"
        bsSize="lg"
        dialogClassName="additional-service-modal"
        onHide={() => this.setState({ showServices: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center text-bold">
            <Translate value="report.result.bookingDetails.serviceFee" /> #
            {this.state.bookingId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.additionalServices.length === 0 ? (
            <div className="no-item-was-found">
              <Translate value="report.result.noResultFound" />
            </div>
          ) : (
            <div className="modal-report-table-container">
              <table className="table table-no-pagination cc-table-striped">
                <tbody>
                  {this.state.additionalServices.map((doc) => (
                    <tr>
                      {fieldMappings.serviceFields.map((item) => (
                        <td
                          className="modal-report-table-row-content-service"
                          style={{ padding: 20 }}
                        >
                          {item.mutate
                            ? item.mutate(doc[item.key], this.state.row)
                            : doc[item.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );

    const fleetServiceModal = (
      <Modal
        show={this.state.showFleetService}
        backdrop="static"
        bsSize="lg"
        dialogClassName="additional-service-modal"
        onHide={() => this.setState({ showFleetService: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center text-bold">
            <Translate value="report.result.bookingDetails.fleetServiceFee" /> #
            {this.state.bookingId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.fleetServices.length === 0 ? (
            <div className="no-item-was-found">
              <Translate value="report.result.noResultFound" />
            </div>
          ) : (
            <div className="modal-report-table-container">
              <table className="table table-no-pagination cc-table-striped">
                <tbody>
                  {this.state.fleetServices.map((doc) => (
                    <tr>
                      {fieldMappings.fleetServiceFields.map((item) => (
                        <td
                          className="modal-report-table-row-content-service"
                          style={{ padding: 20 }}
                        >
                          {item.mutate
                            ? item.mutate(doc[item.key], this.state.row)
                            : doc[item.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );

    const extraDestinationModal = (
      <Modal
        show={this.state.showExtraDestination}
        backdrop="static"
        bsSize="lg"
        dialogClassName="additional-service-modal"
        onHide={() => this.setState({ showExtraDestination: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" text-center text-bold">
            #{this.state.bookingId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.extraDestination.length === 0 ? (
            <div className="no-item-was-found">
              <Translate value="report.result.noResultFound" />
            </div>
          ) : (
            <div className="modal-report-table-container">
              <table className="table table-no-pagination cc-table-striped">
                <thead className="corider-header">
                  <tr>
                    {fieldMappings.extraDestinationFields.map((item) => (
                      <th style={{ padding: 20 }}>
                        <Translate value={item.label} />
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.state.extraDestination.map((doc) => (
                    <tr>
                      {fieldMappings.extraDestinationFields.map((item) => (
                        <td
                          className="modal-report-table-row-content-service"
                          style={{ padding: 20 }}
                        >
                          {item.mutate
                            ? item.mutate(doc[item.key], this.state.row)
                            : doc[item.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );

    const emailModal = (
      <Modal
        show={this.state.showEmailInputModal}
        backdrop="static"
        bsSize="lg"
        dialogClassName="logs-modal send-receipt-modal"
        onHide={() =>
          this.setState({
            showEmailInputModal: false,
            emailString: '',
            validEmail: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('report.result.bookingDetails.sendReceipt').toUpperCase()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="mb-lg">
            <FormControl
              type="text"
              value={this.state.emailString}
              onChange={this.handleChangeEmailInput}
              placeholder="email1@email.com;email2@email.com"
              className="form-custom"
            />
            {!this.state.validEmail && this.state.emailString ? (
              <div className="text-remove mt">
                <Translate value="report.query.errorMessage.invalidEmail" />
              </div>
            ) : null}
          </FormGroup>
          <div className="text-center">
            <Button
              className="btn-send mr-lg"
              disabled={!this.state.validEmail || !this.state.emailString}
              onClick={() => this.sendReceipt.call(this)}
            >
              <Translate value="report.result.bookingDetails.send" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={() => {
                this.setState({
                  showEmailInputModal: false,
                  emailString: '',
                  validEmail: false,
                });
              }}
            >
              <Translate value="report.result.bookingDetails.cancel" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );

    const renderRecipientsTableContent = (bookingInfo) => {
      if (this.state.total == 0) {
        return (
          <div className="no-item-was-found">
            <Translate value="report.result.noResultFound" />
          </div>
        );
      }
      return (
        <div className="modal-report-table-container">
          <table className="cc-table cc-table-striped">
            <thead>
              <tr>
                {fieldMappings.recipientsInfoFields.map((item) => (
                  <th
                    className="modal-report-table-header"
                    style={{ padding: 20 }}
                  >
                    <Translate value={item.label} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.listItems.map((doc, index) => (
                <tr key={index}>
                  {fieldMappings.recipientsInfoFields.map((item) => {
                    let nameItem = doc[item.key] ? doc[item.key] : '';
                    if(item.tooltip) {
                      const content = item.tooltipValue(doc[item.key], doc)
                      return (
                        <td
                          className="modal-report-table-row-content"
                          style={{ maxWidth: 25 }}
                        >
                          <OverlayTrigger
                            overlay={<Tooltip id="tooltip">{content}</Tooltip>}
                            delayShow={300}
                            delayHide={150}
                          >
                            <span className='text-ellipsis cursor-pointer' style={{padding: '20px'}}>{content}</span>
                          </OverlayTrigger>
                        </td>
                      )
                    }
                    if(item.isLink) {
                        const info = item.renderLink && item.renderLink(doc, bookingInfo)
                      return (
                        <td style={{textAlign: 'center'}}>
                          {info ? (
                            <img  
                              style={{maxHeight: '90px', cursor: 'pointer'}}
                              onClick={() => this.showProofOfFailure(info.href)}
                              src={info.href} 
                            />
                          ) : ''}
                        </td>
                      )
                    }
                    return (
                      <td
                        className="modal-report-table-row-content"
                        style={{ padding: 20 }}
                      >
                        {
                          item.mutate 
                          ? item.mutate(doc[item.key], doc, bookingInfo)
                          : nameItem
                        }
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    const viewRecipientsInfo = this.state.showRecipientsInfo ? (
      <Modal
        show={this.state.showRecipientsInfo}
        backdrop="static"
        dialogClassName="confirm-dialog logs-modal"
        bsSize="lg"
        onHide={() =>
          this.setState({
            showRecipientsInfo: false,
            row: null,
            listItems: [],
            keyValue: '',
            title: '',
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>{`BOOKING #${this.state.row && this.state.row.bookingId}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderRecipientsTableContent(this.state.row)}</Modal.Body>
      </Modal>
    ) : null;

    const viewPhotoCapture = this.state.showviewPhotoCapture ? (
      <Modal
        show={this.state.showviewPhotoCapture}
        backdrop="static"
        dialogClassName="confirm-dialog logs-modal"
        bsSize="lg"
        onHide={() => {
          if(this.state.keyValue === 'proofOfFailure') {
            this.setState({
              showviewPhotoCapture: false,
              keyValue: '',
              title: '',
              href: ''
            })
          } else {
            this.setState({
              showviewPhotoCapture: false,
              row: null,
              listItems: [],
              keyValue: '',
              title: ''
            })
          }
        }
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.title
              ? I18n.t('report.result.bookingDetails.' + this.state.title)
              : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            this.state.keyValue === 'proofOfFailure' ? (
              <Image
                className="image-photo-capture"
                src={this.state.href}
                style={{ display: 'inherit', maxWidth: '50%', margin: '0 auto'}}
                rounded
              />
            ) : (
              <Container>
                {this.state.listItems.map((item, index) => {
                  let images = item[this.state.keyValue] || [];
                  return (
                    <FormGroup className={'recipients'} key={index}>
                      {/* {this.state.row.carTypeService === "delivery" &&
                        <Form.Label>
                          {this.state.keyValue === "collectedPhotos" ? "Merchant" : "Recipients"} name: {item.name} / phone: {item.phone}
                        </Form.Label>
                      } */}
                      {this.state.keyValue !== 'collectedPhotos' && (
                        <Form.Label>
                          {this.state.keyValue === 'collectedPhotos'
                            ? I18n.t('report.query.merchant')
                            : I18n.t('newbooking.Recipients')}{' '}
                          name: {item.name} / phone: {item.phone}
                        </Form.Label>
                      )}
                      <Row>
                        {images.length > 0 &&
                          images.map((image, indexImage) => {
                            return (
                              <Col xs={12} md={12} key={indexImage}>
                                <a
                                  href={image}
                                  className="text-active"
                                  target="_blank"
                                >
                                  <Image
                                    className="image-photo-capture"
                                    src={image}
                                    rounded
                                  />
                                </a>
                              </Col>
                            );
                          })}
                        {images.length === 0 && (
                          <div lassName="text-soft-warning">
                            <Translate value="report.result.bookingDetails.Image_not_found" />
                          </div>
                        )}
                      </Row>
                    </FormGroup>
                  );
                })}
              </Container>
            )
          }
        </Modal.Body>
      </Modal>
    ) : null;
    return (
      <div className="content">
        {serviceModal}
        {fleetServiceModal}
        {extraDestinationModal}
        {emailModal}
        {viewPhotoCapture}
        {viewRecipientsInfo}
        {this.noteOtherFeeModal()}
        <Modal
          show={this.state.showModal}
          backdrop="static"
          bsSize="lg"
          dialogClassName="logs-modal ride-sharing-modal"
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton className="ride-sharing-modal-header">
            <Modal.Title className="text-center">
              <Translate value="report.result.bookingDetails.coRider" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-no-pagination cc-table-striped">
              <thead className="corider-header">
                <tr>
                  {fieldMappings.rideSharingFields.map((item) => (
                    <th key={item.key} style={{ padding: 20 }}>
                      <Translate value={item.label} />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.state.sharedBooks.map((doc) => (
                  <tr>
                    {fieldMappings.rideSharingFields.map((item) => (
                      <td
                        className="modal-report-table-row-content"
                        style={{ padding: 20 }}
                      >
                        {doc[item.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
        <BaseReport
          tittle="BookingDetails"
          fieldMappings={this.state.fieldMappings}
          apiurl={reportBookingDetailsApi}
          dateRange
          finishedStatus
          {...this.state.fiterHideSupplier}
          corporate={
            this.state.fiterHideSupplier?.corporate
              ? this.state.corporates
              : null
          }
          driver
          bookingService={
            this.state.fiterHideSupplier?.bookingService
              ? commonData.bookingServices.filter(
                  (i) => i.value !== 'all' && i.value !== 'roaming'
                )
              : []
          }
          adjustPrice={
            this.state.fiterHideSupplier?.adjustPrice ? isAdjustPrice : false
          }
          bookFrom
          serviceType
          rideService
          pickUpZone
          tipAfterCompleted
          shortTrip
          usedPromocodes
          carTypeMultiple
          intercityRoute={isShowIntercityRouteFilter}
          search="report.query.search.bookingDetails"
          settings
          markupPrice={isMarkupPrice}
          actions={{
            showSharedBooks: this.showSharedBooks,
            showAdditionalServices: this.showAdditionalServices,
            showFleetServices: this.showFleetServices,
            showOtherFeeNote: this.showOtherFeeNote,
            showSendReceipt: this.showSendReceipt,
            showExtraDestination: this.showExtraDestination,
            showCollectedPhotos: this.showCollectedPhotos,
            showDeliveredPhotos: this.showDeliveredPhotos,
            showRecipientsInfo: this.showRecipientsInfo,
          }}
          dynamicColumn
          isReportBookingDetails
          isShowDynamicSurcharge={isShowDynamicSurcharge}
          isShowDynamicFare={isShowDynamicFare}
          noexport={!checkExport}
          favoriteFilter
          storeMerchant
        />
      </div>
    );
  }
}

ReportBookingDetails.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    commonData: state.commonData,
    user: state.auth.user,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBookingDetails);
