import React from 'react';
import PropTypes from 'prop-types';

const FormatItem = ({ text, bgColor, style }) => {
  return (
    <div 
      className="info-item"
      style={{
        backgroundColor: bgColor,
        ...style
      }}
    >
      <span>
        {text}
      </span>
    </div>
  );
};

FormatItem.propTypes = {
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  style: PropTypes.object
};

FormatItem.defaultProps = {
  bgColor: 'white',
  style: {}
};

export default FormatItem;
