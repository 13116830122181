import React from "react";
import _ from "lodash";
import {
  FormControl,
  FormGroup,
  Form,
  Modal,
  Row,
  Col,
  Button
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import moment from "moment";
import CurrencyFormater from "currency-formatter";

import "./style.scss";
import CcCheckbox from "../../../components/ccCheckbox/CcCheckbox";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import { Validator, ValidCase } from "../../../components/validator";
import {
  CCLiteCommonFunc,
  Validation,
  formatMMYY
} from "../../../utils/commonFunctions";
import * as loadingActions from "../../../actions/loadingBarActions";
import {
  ownerAddNewCard,
  ownerGetPaymentInfo,
  deleteCreditToken,
  setDefaultCard
} from "../../../actions/settingPaymentInfoActions";
import {
  getClientSecret
} from "../../../actions/creditCardActions";
import Confirm from "../../../components/confirm/Confirm";
import StripeAddCardForm from "../../../components/Stripe/StripeAddCardForm";

const reg = /^\d+$/;

class PaymentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCardForm: false,
      showHistoryModal: false,
      cardModel: this.initDefaultCardValue(),
      packageInfo: {
        createdDate: "",
        name: "",
        packageId: "",
        lastPayment: ""
      },
      triggerValidate: {
        cardHolder: false,
        cardNumber: false,
        expiredDate: false,
        cvv: false
      },
      valid: {},
      credits: [],
      history: []
    };
  }

  componentDidMount() {
    this.props.showLoadingSpiner();
    this.props
      .ownerGetPaymentInfo({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then(data => {
        this.props.hideLoadingSpiner();
        if (data.ok && data.res) {
          const { fleet, history, configStripe } = data.res;
          let credits = fleet ? fleet.credits || [] : [],
            packageInfo = fleet
              ? { ...fleet.packages, createdDate: fleet.createdDate }
              : this.state.packageInfo;

          let timezone = this.props.auth.selectedFleet.timezone;

          packageInfo.lastPayment =
            history && history.length && history[0].transactionDate
              ? this.parseTransactionDate(history[0].transactionDate)
              : "";
          this.setState({ packageInfo, credits, history, configStripe });
        }
      });
  }

  parseTransactionDate = (date, format) => {
    date = date.includes("Z") ? date : date + "Z";
    return moment(date)
      .tz(this.props.auth.selectedFleet.timezone)
      .format(format || "MMM DD, YYYY");
  };

  initDefaultCardValue = () => {
    return {
      cardHolder: "",
      cardNumber: "",
      expiredDate: "",
      cvv: ""
    };
  };

  handleAddNewCardClick = () => {
    if (!this.state.showAddCardForm) this.setState({ showAddCardForm: true });
  };

  isTriggerValidation = key => {
    return this.state.isSubmitted || this.state.triggerValidate[key];
  };

  onInputFieldBlur = key => {
    let { triggerValidate } = this.state;
    triggerValidate[key] = true;
    this.setState({
      triggerValidate: {
        ...triggerValidate
      }
    });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleCardHolderChange = e => {
    let cardHolder = e.target.value;
    this.setState({
      cardModel: {
        ...this.state.cardModel,
        cardHolder
      }
    });
  };

  handleCardNumberChange = e => {
    let value = e.target.value || "";
    let cardNumber = CCLiteCommonFunc.cardNumberFormat(value);
    this.setState({ cardModel: { ...this.state.cardModel, cardNumber } });
  };

  handleExpiredDateChange = e => {
    let value = e.target.value;
    if (
      this.state.cardModel.expiredDate &&
      value.length <= this.state.cardModel.expiredDate.length
    ) {
      //key delete or backspace
      this.setState({
        cardModel: { ...this.state.cardModel, expiredDate: value }
      });
    } else {
      this.setState({
        cardModel: { ...this.state.cardModel, expiredDate: formatMMYY(value) }
      });
    }
  };

  handleCVVChange = e => {
    if (reg.test(e.target.value) || e.target.value == "") {
      if (e.target.value.length <= 4) {
        let cvv = e.target.value;
        this.setState({
          cardModel: { ...this.state.cardModel, cvv }
        });
      }
    }
  };

  handleCloseModal = () => this.setState({ showHistoryModal: false });

  hanldeCardSetupSuccess = (setupIntent, cardHolder) => {
    // TOTO refactor request body
    const fakeCard = {
      cardNumber: '4111111111111111',
      creditCode: '',
      street: '',
      city: '',
      name: '',
      email: '',
      postalCode: '',
      expiredDate: '11/2021',
      cvv: '123',
      creditType: '',
      sca: true,
      isCrossZone: false
    };
    const creditCardObject = _.extend({}, fakeCard, {
      fleetId: this.props.auth.selectedFleet.fleetId,
      cardHolder,
      token: JSON.stringify({ mCard: '', mId: setupIntent.payment_method })
    });
    this.props.showLoadingSpiner();
    this.props.createNewCreditCard(creditCardObject).then(data => {
      this.props.hideLoadingSpiner();
      if (data.res && data.res.response) {
        let newCreditCards = Object.assign([], this.state.credits);
        newCreditCards.unshift(data.res.response);
        this.setState({
          credits: newCreditCards,
          showAddCardForm: false,
          cardModel: this.initDefaultCardValue(),
          isSubmitted: false,
          triggerValidate: {
            cardHolder: false,
            cardNumber: false,
            expiredDate: false,
            cvv: false
          }
        });
        this.context.notification(
          "success",
          I18n.t("customer.Add_card_success")
        );
      } else {
        if (data.res && data.res.returnCode && data.res.returnCode !== 200) {
          let message = I18n.t("messages.credits." + data.res.returnCode);
          this.context.notification("error", message, "", () => {}, 1000 * 10);
        }
      }
    });
  };

  handleSaveCreditCard = () => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
    let params = Object.assign({}, this.state.cardModel);
    // parse expireDate to format dd/yyyy , currently it has format mm/yy
    let expiredDate = params.expiredDate.split(" ").join("");
    let expiredDateSplit = expiredDate.split("/");

    if (expiredDateSplit && expiredDateSplit.length > 1) {
      expiredDateSplit[1] = "20" + expiredDateSplit[1];
      expiredDate = expiredDateSplit.join("/");
    }

    params.expiredDate = expiredDate;
    params.cardNumber = params.cardNumber.replace(/ /g, "");
    params.isCrossZone = false;
    params.fleetId = this.props.auth.selectedFleet.fleetId;

    this.props.showLoadingSpiner();
    this.props.createNewCreditCard(params).then(data => {
      this.props.hideLoadingSpiner();
      if (data.res && data.res.response) {
        let newCreditCards = Object.assign([], this.state.credits);
        newCreditCards.unshift(data.res.response);
        this.setState({
          credits: newCreditCards,
          showAddCardForm: false,
          cardModel: this.initDefaultCardValue(),
          isSubmitted: false,
          triggerValidate: {
            cardHolder: false,
            cardNumber: false,
            expiredDate: false,
            cvv: false
          }
        });
        this.context.notification(
          "success",
          I18n.t("customer.Add_card_success")
        );
      } else {
        if (data.res && data.res.returnCode && data.res.returnCode !== 200) {
          let message = I18n.t("messages.credits." + data.res.returnCode);
          this.context.notification("error", message, "", () => {}, 1000 * 10);
        }
      }
    });
  };

  handleSetDefaultCard = (value, id) => {
    const { credits } = this.state;
    let card = _.find(credits, item => item._id === id);
    if (value && !card.defaultCard) {
      this.props.showLoadingSpiner();
      this.props
        .setDefaultCard({
          fleetId: this.props.auth.selectedFleet.fleetId,
          crossToken: card.crossToken
        })
        .then(data => {
          this.props.hideLoadingSpiner();
          if (data.res && data.res.returnCode === 200) {
            let newCredits = Object.assign([], credits);
            _.forEach(newCredits, card => {
              card.defaultCard = card._id === id;
            });
            this.setState({ credits: newCredits });
          } else {
            this.forceUpdate();
          }
        });
    }
  };

  handleDeleteCard = id => {
    let card = _.find(this.state.credits, item => item._id === id);
    if (card && !card.defaultCard) {
      this.props.showLoadingSpiner();
      this.props
        .deleteCreditToken({
          fleetId: this.props.auth.selectedFleet.fleetId,
          crossToken: card.crossToken
        })
        .then(data => {
          this.props.hideLoadingSpiner();
          let credits = Object.assign([], this.state.credits);
          if (data.res && data.res.returnCode === 200) {
            _.remove(credits, card => card._id === id);
          }
          this.setState({ credits, confirm: null });
        });
    }
  };

  handleDeleteCardButtonClick = id => {
    this.setState({
      confirm: {
        id: id,
        title: I18n.t("customer.Delete"),
        body: I18n.t("customer.CONFIRM_DELETE_CARD"),
        buttonTitle: "Yes",
        closeButtonText: "No"
      }
    });
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  render() {
    const { packageInfo, credits, history, configStripe } = this.state;
    const { auth } = this.props;
    const editable = this.props.permissions && !this.props.permissions.actions;

    return (
      <div className="content vertical-flow-auto payment-info-container">
        <Row>
          <Col xs={12} className="payment-info-col">
            <table className="table cc-table large-padding">
              <thead className={"table-header"}>
                <tr className="">
                  <th>
                    <Translate value="payment_info.member_since" />
                  </th>
                  {/* <th>
                    <Translate value="payment_info.package_name" />
                  </th> */}
                  <th>
                    <Translate value="payment_info.last_payment" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {packageInfo.createdDate
                      ? moment(packageInfo.createdDate).format("MMM DD, YYYY")
                      : ""}
                  </td>
                  {/* <td>{packageInfo.name}</td> */}
                  <td>
                    <p className="mb0">{packageInfo.lastPayment}</p>
                    <a
                      className="text-active cursor-pointer"
                      onClick={() => this.setState({ showHistoryModal: true })}
                    >
                      {I18n.t("payment_info.history")}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <FormGroupTitle className="mt">
              {I18n.t("payment_info.credit_card")}
            </FormGroupTitle>
            <table className="table cc-table mb-md large-padding">
              <thead className={"table-header"}>
                <tr className="">
                  <th>
                    <Translate value="General.Name" />
                  </th>
                  <th>
                    <Translate value="payment_info.last_digits" />
                  </th>
                  <th>
                    <Translate value="payment_info.default" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.map(credits, item => (
                  <tr key={item._id}>
                    <td>{item.cardHolder}</td>
                    <td>* {item.cardMask.slice(-4)}</td>
                    <td>
                      <div className="display-flex justify-content-between align-items-center">
                        <CcCheckbox
                          checked={item.defaultCard}
                          onChange={e =>
                            this.handleSetDefaultCard(
                              e.target.checked,
                              item._id
                            )
                          }
                          value={item._id}
                          disabled={editable}
                        />
                        {!item.defaultCard && !editable ? (
                          <div
                            onClick={() =>
                              this.handleDeleteCardButtonClick(item._id)
                            }
                            className="delete-icon"
                          />
                        ) : null}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {editable ? null :  <Button
              className="btn-header text-add-header payment-add-credit-button mb"
              onClick={this.handleAddNewCardClick}
            >
              <i className="fa fa-plus-circle" />
              <Translate value="payment_info.add_new_card" />
            </Button>}
            {this.state.showAddCardForm ? (
              // <StripeAddCardForm
              //   stripeKey={configStripe.publicKey}
              //   fleetId={auth.selectedFleet.fleetId}
              //   getClientSecret={this.props.getClientSecret}
              //   onCardSetupSuccess={this.hanldeCardSetupSuccess}
              //   isAffiliate
              //   loadingActions={this.props.loadingActions}
              // />
              <div className="add-new-card-form">
                <Row>
                  <Col xs={12}>
                    <FormGroupTitle className="mb">
                      {I18n.t("payment_info.card_information")}
                    </FormGroupTitle>
                  </Col>
                  <Col xs={12}>
                    <StripeAddCardForm
                      stripeKey={configStripe.publicKey}
                      fleetId={auth.selectedFleet.fleetId}
                      creditConfig={auth.selectedFleet.creditConfig}
                      getClientSecret={this.props.getClientSecret}
                      onCardSetupSuccess={this.hanldeCardSetupSuccess}
                      isAffiliate
                      loadingActions={this.props.loadingActions}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
          </Col>
        </Row>
        <Modal
          show={this.state.showHistoryModal}
          onHide={this.handleCloseModal}
          dialogClassName="card-dialog payment-history-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="payment_info.payment_history" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table cc-table cc-table-striped large-padding">
              <thead className="table-header">
                <tr className="">
                  <th>
                    <Translate value="payment_info.transaction_date" />
                  </th>
                  <th>
                    <Translate value="payment_info.transaction_id" />
                  </th>
                  <th>
                    <Translate value="payment_info.billing_cycle" />
                  </th>
                  <th>
                    <Translate value="payment_info.amount" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {_.map(history, item => (
                  <tr key={item.transactionId}>
                    <td>
                      {item.transactionDate
                        ? this.parseTransactionDate(
                            item.transactionDate,
                            "MMM DD, YYYY hh:mm a"
                          )
                        : ""}
                    </td>
                    <td>{item.transactionId}</td>
                    <td>
                      {moment([item.year, parseInt(item.month) - 1]).format(
                        "MMM, YYYY"
                      )}
                    </td>
                    <td>
                      {CurrencyFormater.format(item.amount, {
                        code: item.currencyISO
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-reset" onClick={this.handleCloseModal}>
              <Translate value="cue.Close" />
            </Button>
          </Modal.Footer>
        </Modal>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleDeleteCard}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

PaymentInfo.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showLoadingSpiner: bindActionCreators(loadingActions.showLoadingSpiner, dispatch),
    hideLoadingSpiner: bindActionCreators(loadingActions.hideLoadingSpiner, dispatch),
    createNewCreditCard: bindActionCreators(ownerAddNewCard, dispatch),
    ownerGetPaymentInfo: bindActionCreators(ownerGetPaymentInfo, dispatch),
    deleteCreditToken: bindActionCreators(deleteCreditToken, dispatch),
    setDefaultCard: bindActionCreators(setDefaultCard, dispatch),
    getClientSecret: bindActionCreators(getClientSecret, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentInfo);
