import _ from 'lodash';

export const fields = [
  {
    key: 'driver',
    label: 'report.result.driverLoginStatus.driver',
    sort: 'driver'
  },
  {
    key: 'idPermitType',
    label: 'report.result.idPermitType',
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      const driverFields = _.get(selectedFleet, 'driverFields', []);
      return !_.find(driverFields, df => df.key === 'idType');
    }
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.driverLoginStatus.driverIdPermit',
    sort: 'driverIdPermit'
  },
  {
    key: 'phone',
    label: 'report.result.driverLoginStatus.phone',
    sort: 'phone'
  },
  {
    key: 'username',
    label: 'report.result.driverLoginStatus.username',
    sort: 'username',
    isShow: 'driverUserName'
  },
  {
    key: 'company',
    label: 'report.result.driverLoginStatus.company',
    sort: 'company'
  },
  {
    key: 'carType',
    label: 'report.result.driverLoginStatus.carType',
    sort: 'carType'
  },
  {
    key: 'licensePlate',
    label: 'report.result.driverLoginStatus.licensePlate',
    sort: 'licensePlate'
  },
  {
    key: 'status',
    label: 'report.result.driverLoginStatus.status',
    sort: 'status'
  }
];

export const fareFields = [];
