import React from 'react';
import ReportUtils from '../base/utils';
import { BsStarFill } from 'react-icons/bs';

export const fields = [
  /* {
        key: "companyId",
        label: "report.result.acceptanceCancellationRate.companyId",
        isShow: false
    },
    {
        key: "driverId",
        label: "report.result.acceptanceCancellationRate.driverId",
        isShow: false
    }, */
  {
    key: 'driverName',
    label: 'report.result.acceptanceCancellationRate.driverName',
    fixed: true,
  },
  {
    key: 'phoneNumber',
    label: 'report.result.acceptanceCancellationRate.phoneNumber',
  },
  {
    key: 'company',
    label: 'report.result.acceptanceCancellationRate.company',
  },
  {
    key: 'acceptanceRate',
    label: 'report.result.acceptanceCancellationRate.acceptanceRate',
    mutate: (value) => ReportUtils.numberFormat(value, 1, 1),
    headerUnit: <span>&nbsp;(%)</span>,
  },
  {
    key: 'rating',
    label: 'report.result.acceptanceCancellationRate.rating',
    headerUnit: <BsStarFill className='qup-color' />,
    mutate: (value) => (value == 0 ? '5.0' : value.toFixed(1)),
  },
  {
    key: 'cancellationRate',
    label: 'report.result.acceptanceCancellationRate.cancellationRate',
    mutate: (value) => ReportUtils.numberFormat(value, 1, 1),
    headerUnit: <span>&nbsp;(%)</span>,
  },
];
