import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Col,
  FormGroup,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {
  calculatorAmountDue,
  checkEnableStripe,
} from '../../../utils/commonFunctions';
import currencyFormatter from 'currency-formatter';
import Toggle from '../../Toggle';
import copy from 'copy-to-clipboard';

const RefundModal = (
  {
    closeRefundModal,
    bookInfo,
    selectedFleet,
    bookingDetailActions,
    transactionInfo,
    paymentHistory,
    auth
  },
  context
) => {
  const [isAmountType, setIsAmountType] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chargeNumber, setChargeNumber] = useState();
  const [maxRefundAmount, setMaxRefundAmount] = useState(0);
  const [note, setNote] = useState('');
  const [fareInfo, setFareInfo] = useState({
    amountDueAfter: 0,
    amountDue: 0,
    total: 0,
    chargeValueConverted: 0,
  });

  useEffect(() => {
    let maxRefund = transactionInfo?.amount;
    const refundedInfo = paymentHistory
      ?.filter(pm => pm.transactionOriginId == transactionInfo.id)
    
    if(refundedInfo?.length > 0) {
      maxRefund -= refundedInfo.reduce((total, rf) => total + (rf.amount || 0), 0)
    }
    setMaxRefundAmount(maxRefund)

  }, [])

  const calculateFare = useCallback(() => {
    const amountDue = maxRefundAmount || 0;
    const chargeValueConverted = isAmountType
      ? chargeNumber
      : (maxRefundAmount * chargeNumber) / 100;
    const amountDueAfter = amountDue - chargeValueConverted;
    setFareInfo({
      amountDueAfter,
      amountDue,
      chargeValueConverted,
    });
  }, [isAmountType, chargeNumber]);

  const handleChargeNumberChange = (e) => {
    setChargeNumber(e.target.value);
  };

  useEffect(() => {
    calculateFare();
  }, [chargeNumber, isAmountType]);

  const handleChargeConfirmed = async () => {
    if(fareInfo.chargeValueConverted > maxRefundAmount) {
      context.notification(
        'error',
        'The refund amount must be less than or equal to the refundable amount.'
      );
      return;
    }
    const params = {
      fleetId: selectedFleet?.fleetId,
      bookId: bookInfo.bookId,
      reason: note,
      amount: fareInfo.chargeValueConverted,
      id: transactionInfo.id,
      operatorId: auth?.user?._id
    };

    try {
      setIsLoading(true);
      let result = null;
      result = await bookingDetailActions.prePayRefund(params);
      setIsLoading(false);
      if (result?.res?.returnCode === 200) {
        bookingDetailActions.bookingDetailClosed();
        closeRefundModal();
        context.notification(
          'success',
          'This booking has been refund successfully!'
        );
      } else {
        context.notification(
          'error',
          result?.res?.response?.message
            ? result?.res?.response?.message
            : 'An error occurred during the payment'
        );
      }
    } catch (error) {
      context.notification('error', 'An error occurred during the payment');
      setIsLoading(false);
    }
  };

  const renderInfoFare = () => {
    return (
      <div className="fareCharge completedBK">
        <p>
          <span>
            <Translate value="bookingdetail.refundableAmount" />
          </span>
          <span>
            {currencyFormatter.format(maxRefundAmount, {
              code: transactionInfo.currencyISO,
            })}
          </span>
        </p>
      </div>
    )
  }

  return (
    <Modal
      show={true}
      backdrop={false}
      dialogClassName="confirm-dialog"
      onHide={closeRefundModal}
      className={'confirm confirmChageInvoicing'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value="bookingdetail.refundPayment" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderInfoFare()}
        <div className="chargeNumber">
          <div>
            <Toggle
              checked={isAmountType}
              handleChange={(checked) => {
                setIsAmountType(checked);
              }}
              iconUncheck={'%'}
              iconCheck={'$'}
            />
          </div>
          <FormGroup className="qup-input-group chargeControl">
            <InputGroup className="single-addon-left">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {isAmountType ? selectedFleet?.currencies[0]?.symbol : '%'}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                className="form-custom"
                onChange={handleChargeNumberChange}
                value={chargeNumber}
              />
            </InputGroup>
          </FormGroup>
        </div>
        <FormGroup>
          <FormControl
            type="text"
            className={'form-custom chargeInput'}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={I18n.t('bookingdetail.refundReason')}
            maxLength={1000}
          />
        </FormGroup>
        <div className="btnGroup">
          <Button
            className="btn-header text-add-header"
            onClick={closeRefundModal}
          >
            <Translate value="invoice.Close" />
          </Button>
          <Button
            className="btn-save view-button"
            onClick={handleChargeConfirmed}
            disabled={
              !(chargeNumber > 0) || isLoading
            }
          >
            {I18n.t('bookingdetail.refundBtn').format(
              currencyFormatter.format(fareInfo.chargeValueConverted, {
                code: transactionInfo.currencyISO,
              })
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

RefundModal.contextTypes = {
  notification: PropTypes.func,
};

RefundModal.prototype = {
  confirmCharge: PropTypes.func,
  closeRefundModal: PropTypes.func,
  bookInfo: PropTypes.object,
  selectedFleet: PropTypes.object,
  transactionInfo: PropTypes.object,
};

RefundModal.defaultProps = {
  closeRefundModal: () => {},
  confirmCharge: () => {},
  bookingDetailActions: () => {},
  bookInfo: {},
};

export default RefundModal;
