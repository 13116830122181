import React, { Component } from "react";
import {
  Button,
  Modal,
  FormGroup,
  Form,
  FormControl
} from "react-bootstrap";
import { Translate } from "react-redux-i18n";

class IncidentModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      showConfirmIncident,
      incidentButtonClick,
      data,
      handleIncidentReasonChanged,
      incidentReason,
      incidentOkButtonClick
    } = this.props;

    return (
      <Modal
        show={showConfirmIncident}
        backdrop={true}
        dialogClassName="confirm-dialog"
        onHide={incidentButtonClick}
        className={"confirm"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.BOOKING" /> #{data.bookId} -{" "}
            <Translate
              value={"statusDisplay.incident"}
              className="transform-none"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>
              <Translate value="bookingdetail.Input_reason" />
            </Form.Label>
            <FormControl
              onChange={handleIncidentReasonChanged}
              type="text"
              value={incidentReason}
              className="form-custom"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={incidentButtonClick}>
            <Translate value="bookingdetail.Cancel" />
          </Button>
          <Button
            className="btn-save"
            disabled={!incidentReason}
            onClick={incidentOkButtonClick}
          >
            <Translate value="bookingdetail.Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default IncidentModal;
