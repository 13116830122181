import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './settings.scss';
import * as settingActions from '../../actions/settingActions';

class Settings extends Component {
  componentDidMount() {}
  render() {
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
