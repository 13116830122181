import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import { updateCashBalance } from "../../actions/driverAction";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import {
  FormGroup,
  InputGroup,
  Form,
  Modal,
  FormControl,
  Button
} from "react-bootstrap";
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import Confirm from '../../components/confirm/Confirm';

class CashWalletBalance extends React.Component {
  constructor() {
    super();
    this.state = {
      balance: "",
      operator: true,
      amount: "",
      newBalance: "",
      reason: "",
      isPayout: false,
      valid: {},
      isSubmitted: false,
      showConfirmUpdate: {}
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleBalanceChange = this.handleBalanceChange.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleClickConfirmSave = this.handleClickConfirmSave.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleClickCloseConfirm = this.handleClickCloseConfirm.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.cashBalance &&
      nextProps.cashBalance &&
      nextProps.userId
    ) {
      this.setState({
        isShow: nextProps.isShow,
        cashBalance: nextProps.cashBalance,
        currencies: nextProps.currencies,
        userId: nextProps.userId,
        balance:
          (nextProps.currencies &&
            nextProps.currencies.length &&
            nextProps.currencies[0] &&
            nextProps.currencies[0].iso) ||
          ""
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleBalanceChange(e) {
    this.setState({ balance: e.target.value });
  }

  handleOperatorChange(e) {
    this.setState({ operator: e.target.value === "true" });
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value });
  }

  handleChangePayout = (e) => {
    this.setState({
      isPayout: e.target.checked,
      operator: false
    })
  }

  handleClickUpdate = () => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;

    this.setState({
      showConfirmUpdate: {
        title: I18n.t('driver.Confirm_update_cash_balance'),
        body: I18n.t('driver.Confirm_update_cash_balance_msg'),
        buttonTitle: I18n.t('driver.Yes'),
        closeButtonClass: 'btn-cancel',
        closeButtonText: I18n.t('driver.No')
      }
    });
  };

  handleClickConfirmSave() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    let amount = parseFloat(this.state.amount);
    if (!this.state.operator) {
      amount = 0 - amount;
    };
    const requestBody = {
      fleetId: this.props.fleetId,
      driverId: this.state.userId,
      amount: amount,
      currencyISO: this.state.balance,
      reason: this.state.reason,
      isPayout: this.state.isPayout
    };

    this.props
      .updateCashBalance(requestBody)
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res.returnCode == 200) {
          var cashWallet = {
            currencyISO: this.state.balance,
            newBalance: data.res.response.newBalance
          };
          this.props.handleChangeCashBalanceSuccess(cashWallet);
          this.setState({
            balance: "",
            operator: true,
            amount: "",
            newBalance: "",
            reason: "",
            isPayout: false,
            valid: {},
            isSubmitted: false,
            showConfirmUpdate: {}
          });
          this.context.notification(
            "success",
            I18n.t("driver.INFO_ADD_CASH_BALANCE_SUCCESS")
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("messages.credits." + data.res.returnCode)
          );
        }
      });
  }

  handleCancelClick() {
    this.props.handleConfirmCloseClick();
    this.setState({
      balance: "",
      operator: true,
      amount: "",
      newBalance: "",
      reason: "",
      isPayout: false,
      valid: {},
      isSubmitted: false,
      showConfirmUpdate: {}
    });
  }

  handleClickCloseConfirm() {
    this.setState({
      showConfirmUpdate: {}
    });
  };

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    const { currencies, cashBalance, balance, valid, isSubmitted } = this.state;
    if (this.state.isShow) {
      if (currencies && cashBalance) {
        let chosenCashBalance = {};
        if (cashBalance.currencyISO) {
          chosenCashBalance = cashBalance.currencyISO === balance ? cashBalance :
            {
              currencyISO: balance,
              currentBalance: 0.0
            };
        } else {
          chosenCashBalance = {
            currencyISO: balance,
            currentBalance: 0.0
          }
        };
        let chosenCashValue = 0.0;
        if (chosenCashBalance) {
          chosenCashValue = chosenCashBalance.currentBalance;
        }
        let operatorValue = null;
        let amount = Validation.isNumber(this.state.amount)
          ? parseFloat(this.state.amount)
          : 0.0;
        if (!Validation.isStringEmpty(this.state.balance)) {
          if (this.state.operator) {
            operatorValue = (amount + parseFloat(chosenCashValue)).toFixed(2);
          } else {
            operatorValue = (parseFloat(chosenCashValue) - amount).toFixed(2);
          }
        }
        return (
          <div>
            <Modal
              show={true}
              onHide={this.props.closeDialog}
              backdrop="static"
              size="sm"
              aria-labelledby="contained-modal-title-sm"
              dialogClassName="change-balance-dialog"
              className="confirm"
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-sm">
                  <Translate value="driver.CASH_WALLET_DEPOSIT" />
                </Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.handleCancelClick}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body className="clearfix">
                <FormGroup
                  className={
                    isSubmitted
                      ? valid.balance === false
                        ? "error"
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Balance" />{" "}
                    <span className="require"> *</span>
                  </Form.Label>
                  <FormControl
                    as="select"
                    value={this.state.balance}
                    onChange={this.handleBalanceChange}
                    className="form-custom"
                  >
                    {/* <option key={0} value={""}>
                      {I18n.t("driver.Select_balance")}
                    </option> */}
                    {this.state.currencies.map(cp => {
                      let cashWalletBalance = chosenCashBalance.currencyISO === cp.iso ? chosenCashBalance : {};
                      let cashWallet = { currencyISO: cp.iso, currentBalance: 0 };
                      if (cashWalletBalance) {
                        cashWallet = cashWalletBalance;
                        try {
                          cashWallet.currentBalance = parseFloat(cashWallet.currentBalance).toFixed(2);
                        } catch (error) {
                          console.error("Error: ", error);
                        }
                      }
                      return (
                        <option key={cp.iso} value={cp.iso}>
                          {cashWallet.currencyISO} : {cashWallet.currentBalance}
                        </option>
                      );
                    })}
                  </FormControl>
                  <Validator id="balance" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={!Validation.isStringEmpty(this.state.balance)}
                      message={I18n.t("driver.ERROR_INPUT_BALANCE")}
                    />
                  </Validator>
                </FormGroup>

                <FormGroup>
                  <CcCheckbox
                    labelClassName="title-payout"
                    checked={this.state.isPayout}
                    onChange={this.handleChangePayout}
                    // disabled={editable}
                    text={I18n.t('driver.payoutSelect')}
                  />
                </FormGroup>

                <FormGroup
                  className={
                    isSubmitted ? (valid.amount === false ? "error" : null) : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Adjust_amount" />{" "}
                    <span className="require"> *</span>
                  </Form.Label>
                  <FormGroup className="qup-input-group">
                    <InputGroup className="single-addon-left">
                      <FormControl
                        style={{ width: "85%" }}
                        type="text"
                        value={this.state.amount}
                        onChange={this.handleAmountChange}
                      />
                      <FormControl
                        as="select"
                        value={this.state.operator}
                        style={{ width: "15%" }}
                        onChange={e => {
                          this.handleOperatorChange(e);
                        }}
                        className="form-custom input-group-addon-custom"
                        disabled={this.state.isPayout ? true : false}
                      >
                        <option key={0} value={"true"}>
                          +
                      </option>
                        <option key={1} value={"false"}>
                          -
                      </option>
                      </FormControl>
                    </InputGroup>
                  </FormGroup>
                  <Validator id="amount" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={!Validation.isStringEmpty(this.state.amount)}
                      message={I18n.t("driver.ERROR_INPUT_AMOUNT")}
                    />
                    <ValidCase
                      hide={!isSubmitted}
                      valid={
                        Validation.isNumber(this.state.amount) &&
                        parseFloat(this.state.amount) > 0
                      }
                      message={I18n.t("messages.commonMessages.greater_than_0")}
                    />
                  </Validator>
                </FormGroup>
                {operatorValue ? (
                  <FormGroup>
                    <Form.Label>
                      <Translate value="driver.New_balance" />
                    </Form.Label>
                    <FormGroup>
                      <span className="text-active">
                        {this.state.balance} : {operatorValue}
                      </span>
                    </FormGroup>
                  </FormGroup>
                ) : null}
                <FormGroup
                  className={`mb0 ${
                    isSubmitted ? (valid.reason === false ? "error" : null) : null
                  }`}
                >
                  <Form.Label>
                    <Translate value="driver.Enter_reason" />
                    <span className="require"> *</span>
                  </Form.Label>
                  <FormControl
                    as="textarea"
                    value={this.state.reason}
                    onChange={this.handleReasonChange}
                    className="form-custom"
                  />
                  <Validator id="reason" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={!Validation.isStringEmpty(this.state.reason)}
                      message={I18n.t("driver.ERROR_INPUT_REASON")}
                    />
                  </Validator>
                </FormGroup>
              </Modal.Body>
              <Modal.Footer>
                <div className="fill text-center">
                  <Button
                    className="btn-save mr-md"
                    onClick={this.handleClickUpdate}
                  >
                    <Translate value="driver.Save" />
                  </Button>
                  <Button className="btn-cancel" onClick={this.handleCancelClick}>
                    <Translate value="driver.Cancel" />
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {this.state.showConfirmUpdate.title ? (
              <Confirm
                confirm={this.state.showConfirmUpdate}
                handleConfirmCloseClick={this.handleClickCloseConfirm}
                handleConfirmButtonClick={this.handleClickConfirmSave}
              />
            ) : null}

          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

CashWalletBalance.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    fleetId: state.auth.selectedFleet.fleetId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateCashBalance: options => {
      return dispatch(updateCashBalance(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashWalletBalance);
