import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form, InputGroup } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import * as intercityBookingActions from '../../../../actions/intercityBookingActions';
import { calculatorFleetORAdditionalService } from '../../../../utils/commonFunctions';

function FirstStep ({bookInfo, selectedFleet}) {
  const [showExtraServices, setShowExtraServices] = useState(false);
  const { request: { estimate: { fare }, services } } = bookInfo;
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Pick_up" />
        </Form.Label>
        <FormControl
          disabled
          type="text"
          className="form-custom"
          value={bookInfo.request.pickup.address}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Destination" />
        </Form.Label>
        <FormControl
          disabled
          type="text"
          className="form-custom"
          value={bookInfo.request.destination.address}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Basic_fare" />
        </Form.Label>
        <div className="input-with-validator">
          <FormGroup className="qup-input-group">
            <InputGroup className="single-addon-left">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {bookInfo.currencySymbol}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className="form-custom"
                type="text"
                value={currencyFormatter.format(fare.basicFare, {
                  code: bookInfo.currencyISO,
                  format: '%v'
                })}
                disabled
              />
            </InputGroup>
          </FormGroup>
        </div>
      </FormGroup>
      {/* {fareSettings.fare.heavyTrafficActive ||
      (fareSettings.heavyTraffic && fareSettings.heavyTraffic.enable) ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Heavy_traffic" />
          </Form.Label>
          <CcCheckbox
            checked={paymentComplete.heavyTrafficChecked}
            onChange={handleHeavyTrafficCheckboxChange}
            text={currencyFormatter.format(
              paymentComplete.heavyTrafficChecked
                ? bookInfo.pricingType == 0
                  ? CCLiteCommonFunc.getValueOfMultiCurrencies(
                      fareSettings.fare.heavyTrafficByCurrencies,
                      bookInfo.currencyISO,
                      'value'
                    ) || ''
                  : fareSettings.heavyTraffic.surcharge
                : '',
              { code: bookInfo.currencyISO }
            )}
            disabled={bookInfo.pricingType === 1}
          />
        </FormGroup>
      ) : (
        ''
      )} */}
      {selectedFleet.fleetFareId &&
      selectedFleet.fleetFareId.additionalServicesActive ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Addi_service" />
          </Form.Label>
          <FormGroup className="additional-service-form qup-input-group">
            <InputGroup className="single-addon-left">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {bookInfo.currencySymbol}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                readOnly
                value={currencyFormatter.format(
                  calculatorFleetORAdditionalService(services),
                  bookInfo.currencyISO
                )}
                onClick={() =>setShowExtraServices(true)}
                className="form-custom additional-service-input"
                disabled
              />
            </InputGroup>
          </FormGroup>
        </FormGroup>
      ) : null}

      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Subtotal" />
          {'\u00A0'}
        </Form.Label>
        <span className="amount">
          {currencyFormatter.format(fare.subTotal, {
            code: bookInfo.currencyISO
          })}
        </span>
      </FormGroup>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    bookInfo: state.bookingDetail.data
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FirstStep);