import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';

export const fields = [
  {
    label: 'report.result.companySettlement.companyName',
    key: 'companyName',
    summary: 'companyName'
  },
  {
    label: 'report.result.companySettlement.pendingSettlement',
    key: 'pendingSettlement',
    summary: 'pendingSettlement',
    negativable: true
  },
  {
    key: 'viewDetails',
    label: 'report.result.companySettlement.viewDetails',
    link: {
      label: 'report.query.view',
      newTab: true,
      href: '/reports/company_settlement/details',
      name: 'goToDetails',
      params: ['companyId'],
      excludeParams: ['pickUpZone'],
      description: 'Show list bookings of a payout transaction',
    },
  },
  {
    label: 'report.result.companySettlement.settlementType',
    key: 'settlementType',
    mutate: (value) => I18n.t(`report.query.companySettlementTypeItem.${value}`),
  }
];

export const fareFields = ['pendingSettlement'];
