import React, { PureComponent, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

import { writeOffDebtCustomer } from '../../../actions/customerAction';
import { paymentMethod as fetchPaymentMethod } from '../../../actions/paymentMethodActions';

import * as loadingBarActions from '../../../actions/loadingBarActions';

import WriteOffDebtModal from './WriteOffDebtModal';

class WriteOffDebt extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchPaymentMethod(this.props.auth.selectedFleet.fleetId);
  }

  handleShowModal = () => {
    this.setState({ isShowModal: true });
  };

  handleCloseModal = () => {
    this.setState({ isShowModal: false });
  };

  onApplyWriteOffDebt = formData => {
    const { auth, customer, writeOffDebt } = this.props;
    const currencyDefault = _.get(auth, 'selectedFleet.currencies[0]', {});
    const params = {
      ...formData,
      fleetId: auth.selectedFleet.fleetId,
      userId: customer.userId,
      currencyISO: currencyDefault?.iso
    };
    this.setState({ isLoading: true });

    writeOffDebt(params).then(({ res }) => {
      this.setState({ isLoading: false });
      if (res && res.returnCode === 200) {
        this.handleCloseModal();
        this.props.onWriteOffDebtSuccess();
        this.context.notification('success', I18n.t('customer.writeOffDebtSuccess'));
      } else {
        this.context.notification('error', I18n.t(`errors.${res.returnCode}`));
      }
    });
  };

  render() {
    const { isShowModal, isLoading } = this.state;
    const {
      auth, permissions, customer, paymentMethod
    } = this.props;
    if (permissions && !permissions.writeoffdebt) {
      return null;
    }

    return (
      <Fragment>
        {isShowModal && (
          <WriteOffDebtModal
            closeModal={this.handleCloseModal}
            customer={customer}
            selectedFleet={auth.selectedFleet}
            paymentMethod={paymentMethod}
            onApplyWriteOffDebt={this.onApplyWriteOffDebt}
            isLoading={isLoading}
          />
        )}
        <Button className="btn-header text-add-header p0" onClick={this.handleShowModal}>
          <Translate value="customer.WriteOffDebt" className="collapse-filter-title" />
        </Button>
      </Fragment>
    );
  }
}

WriteOffDebt.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    paymentMethod: state.paymentMethod
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    fetchPaymentMethod: bindActionCreators(fetchPaymentMethod, dispatch),
    writeOffDebt: bindActionCreators(writeOffDebtCustomer, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WriteOffDebt);
