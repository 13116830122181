import React, { Component } from 'react';
import pickupIcon from './../../../assets/images/icons/pickup.svg';
import distinationIcon from './../../../assets/images/icons/destination.svg';
import { locationType } from './../../../constants/commondata';
import { QQMap, QQMarker } from '../../../components/qqMap';

class DeliveryQQMap extends Component {
  constructor(e) {
    super(e);
  }

  render() {
    const {
      center,
      pickupLocation,
      pickupFrom,
      destinationLocation,
      destinationFrom,
      onMapLoad
    } = this.props;
    let qqCenter = null;
    if (center) {
      qqCenter = new window.qq.maps.LatLng(center.lat, center.lng);
    }
    return (
      <QQMap
        className="fill"
        options={{ center: qqCenter }}
        onLoad={onMapLoad}
      >
        {pickupLocation && (
          <QQMarker
            mapkey="pickup"
            options={{
              icon: pickupIcon,
              draggable: pickupFrom !== locationType.thirdParty,
              position: pickupLocation
            }}
          />
        )}
        {destinationLocation && (
          <QQMarker
            mapkey="dest"
            options={{
              icon: distinationIcon,
              draggable: destinationFrom !== locationType.thirdParty,
              position: destinationLocation
            }}
          />
        )}
      </QQMap>
    );
  }
}

export default DeliveryQQMap;
