import React, { Component } from 'react';
import {
  Button,
  Modal
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Fragment } from 'react';
import { showNotifyCancelEmailCheckbox } from '../../../components/bookingDetail/bookFunction/bookingInfo';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';

const CancelStatus = ['pre', 'confirmed', 'action', 'offered']

class CancelButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNotifyCancelEmail: false
    }
  }

  handleNotifyCustomerViaEmailChange = (e) => {
    this.setState({
      hasNotifyCancelEmail: e.target.checked,
    });
  };

  handleIncidentReasonChanged = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  handleRefundFareChanged = (e) => {
    this.setState({
      refundFare: e.target.checked,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  }

  showConfirm = () => {
    this.setState({ visible: true, reason: '', refundFare: false, hasNotifyCancelEmail: false });
  }

  handleSubmit = (e) => {
    this.props.onSubmit({
      reason: this.state.reason,
      refundFare: this.state.refundFare,
      hasNotifyCancelEmail: this.state.hasNotifyCancelEmail
    })
  }

  render() {
    const {
      bookInfo,
      selectedFleet
    } = this.props;

    const { visible, hasNotifyCancelEmail } = this.state;
    if (!bookInfo || !CancelStatus.includes(bookInfo.status)) {
      return null;
    }
    const isOfflinePayment = [1].includes(bookInfo.request.paymentType);
    return (
      <Fragment>
        <Button onClick={this.showConfirm} className="btn-cancel mr">
          <Translate value="bookingdetail.Cancel" />
        </Button>
        <Modal
          show={visible}
          backdrop={true}
          dialogClassName="confirm-dialog"
          onHide={this.closeModal}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="intercityBooking.ConfirmCancel" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {I18n.t('bookingdetail.CONFIRM_CANCEL_MESSAGE')}
            </p>
            {showNotifyCancelEmailCheckbox({
                auth: this.props.auth,
                bookInfo,
            }) && 
              <div style={{ display: 'block', marginTop: '1rem'}}>
                <CcCheckbox
                  checked={hasNotifyCancelEmail}
                  onChange={this.handleNotifyCustomerViaEmailChange}
                  text={I18n.t('newbooking.notify_via_email')}
                  style={{ marginBottom: '0px' }}
                  className='mr-b-0 mr-t-20'
                />
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModal}>
              <Translate value="bookingdetail.Cancel" />
            </Button>
            <Button
              className="btn-save"
              onClick={this.handleSubmit}
            >
              <Translate value="bookingdetail.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default CancelButton;
