import React, { PureComponent } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import _ from 'lodash';
import { ValidCase, Validator } from '../../../../../components/validator';
import InputMultiCurrencies from './InputMultiCurrencies';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../../components/questionCircleTooltip/QuestionCircleTooltip';

export default class RouteLimitSeatLuggageForm extends PureComponent {
  handleChangeWarningMinimumSeats = (e) => {
    const { onChangeLimitSetting } = this.props;
    onChangeLimitSetting('warningMinimumSeats', e.target.value);
  };

  handleChangeFreeLuggagePerSeat = (e) => {
    const { onChangeLimitSetting } = this.props;
    onChangeLimitSetting('freeLuggagePerSeat', e.target.value);
  };

  handleChangeFeePerExtraLuggage = (value) => {
    const { onChangeLimitSetting } = this.props;
    onChangeLimitSetting('feePerExtraLuggage', value);
  };

  handleChangeAllowStartLessMinRequiredSeats = (e) => {
    const { onChangeLimitSetting } = this.props;
    onChangeLimitSetting('allowStartLessMinRequiredSeats', e.target.checked);
  };

  handleChangeAllowToAddExtraLuggage = (e) => {
    const { onChangeLimitSetting } = this.props;
    onChangeLimitSetting('allowToAddExtraLuggage', e.target.checked);
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  render() {
    const {
      routekey,
      valid,
      validatorCallback,
      limitSetting,
      isSubmmitted,
      editable,
      selectedFleet,
      currentCurrency,
    } = this.props;
    return (
      <div>
        <FormGroup
          className={
            isSubmmitted
              ? valid[`${routekey}warningMinimumSeats`] === false
                ? 'error'
                : null
              : null
          }
        >
          <Form.Label>
            <Translate value="intercityRoute.warningMinimumSeats" />{' '}
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={I18n.t('intercityRoute.warningMinimumSeatsHint')}
            />
          </Form.Label>
          <FormControl
            type="text"
            className={'form-custom'}
            onChange={this.handleChangeWarningMinimumSeats}
            value={limitSetting.warningMinimumSeats}
            disabled={!editable}
          />
          <Validator
            id={`${routekey}warningMinimumSeats`}
            callback={validatorCallback}
          >
            <ValidCase
              valid={
                limitSetting.warningMinimumSeats &&
                Validation.isNumber(limitSetting.warningMinimumSeats)
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={Validation.isGreaterOrEqual(
                limitSetting.warningMinimumSeats,
                1
              )}
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                1
              )}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={Validation.isLessOrEqual(
                limitSetting.warningMinimumSeats,
                99
              )}
              message={I18n.t('messages.commonMessages.less_than').format(99)}
              hide={!isSubmmitted}
            />
          </Validator>
        </FormGroup>

        <FormGroup>
          <CcCheckbox
            checked={limitSetting.allowStartLessMinRequiredSeats}
            onChange={this.handleChangeAllowStartLessMinRequiredSeats}
            text={
              <span>
                {I18n.t(`intercityRoute.allowStartLessMinRequiredSeats`)}
                <QuestionCircleTooltip
                  text={I18n.t(
                    'intercityRoute.allowStartLessMinRequiredSeatsHint'
                  )}
                />
              </span>
            }
          />
        </FormGroup>

        <FormGroup
          className={
            isSubmmitted
              ? valid[`${routekey}freeLuggagePerSeat`] === false
                ? 'error'
                : null
              : null
          }
        >
          <Form.Label>
            <Translate value="intercityRoute.freeLuggagePerSeat" />{' '}
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={I18n.t('intercityRoute.freeLuggagePerSeatHint')}
            />
          </Form.Label>
          <FormControl
            type="text"
            className={'form-custom'}
            onChange={this.handleChangeFreeLuggagePerSeat}
            value={limitSetting.freeLuggagePerSeat}
            disabled={!editable}
          />
          <Validator
            id={`${routekey}freeLuggagePerSeat`}
            callback={validatorCallback}
          >
            <ValidCase
              valid={
                limitSetting.freeLuggagePerSeat &&
                Validation.isNumber(limitSetting.freeLuggagePerSeat)
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={Validation.isGreaterThan(
                limitSetting.freeLuggagePerSeat,
                0
              )}
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={Validation.isLessOrEqual(
                limitSetting.freeLuggagePerSeat,
                99
              )}
              message={I18n.t('messages.commonMessages.less_than').format(99)}
              hide={!isSubmmitted}
            />
          </Validator>
        </FormGroup>

        <FormGroup>
          <CcCheckbox
            checked={limitSetting.allowToAddExtraLuggage}
            onChange={this.handleChangeAllowToAddExtraLuggage}
            text={
              <span>
                {I18n.t(`intercityRoute.allowToAddExtraLuggage`)}
                <QuestionCircleTooltip
                  text={I18n.t('intercityRoute.allowToAddExtraLuggageHint')}
                />
              </span>
            }
          />
        </FormGroup>
        {limitSetting.allowToAddExtraLuggage ? (
          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}feePerExtraLuggage`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.feePerExtraLuggage" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={limitSetting.feePerExtraLuggage}
              onChange={this.handleChangeFeePerExtraLuggage}
              editable={editable}
            />

            <Validator
              id={`${routekey}feePerExtraLuggage`}
              callback={validatorCallback}
            >
              <ValidCase
                valid={Validation.isGreaterOrEqual(
                  _.get(limitSetting, 'feePerExtraLuggage[0].value', 0),
                  0
                )}
                hide={!isSubmmitted}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
              />
              <ValidCase
                valid={Validation.isLessOrEqual(
                  _.get(limitSetting, 'feePerExtraLuggage[0].value'),
                  999999999999
                )}
                message={I18n.t('messages.commonMessages.less_than').format(
                  this.formatCurrency(999999999999)
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>
        ) : null}
      </div>
    );
  }
}
