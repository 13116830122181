import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLocale, Translate, I18n, loadTranslations } from 'react-redux-i18n';
import { NotificationContainer } from 'react-notifications';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import Header from '../../components/header/Header';
import { logout, loadUserFleet } from '../../actions/auth';
import 'react-notifications/lib/notifications.css';
import './App.scss';
import './App-mobile.scss';
import './react-datetime.scss';
import '../../assets/styles/custom.scss';
import * as menuActions from '../../actions/sidebarCollapsedAction';
import * as commonActions from '../../actions/commonDataAction';
import * as socketActions from '../../actions/socketActions';
import * as mapActions from '../../actions/mapActions';
import * as cueActions from '../../actions/cueActions';
import {
  socketDispatchApi,
  socketDispatchAuth
} from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
import {
  newBookingRequest,
  closeNewBookingForm
} from '../../actions/newbookingAction';
import Confirm from '../../components/confirm/Confirm';
import * as bookingDetailActions from '../../actions/bookingDetailAction';
import * as notificationActions from '../../actions/notificationActions';
import * as loadingActions from '../../actions/loadingBarActions';
import * as reservationActions from '../../actions/reservationActions';
import * as callCenterSupportActions from '../../actions/callCenterSupportActions';
import * as newbookingActions from '../../actions/newbookingAction';
import notification_sound from '../../constants/staticFiles/notification_sound.mp3';
import { realtimeHelper } from '../../utils/realTimeHelper';
import { Alert, AlertContainer } from '../../components/notificationAlert';
import { CODE_ERR_COMPLETE_BOOK, userType } from '../../constants/commondata';

const uuidv4 = require('uuid/v4');

const desStatus = {
  ACTION: 'action',
  QUEUE: 'queue',
  CANCELED: 'canceled',
  CONFIRMED: 'confirmed'
};

class AppNotifArea extends Component {
  constructor() {
    super();
    this.state = {
      AddNew: false,
      notification: [],
      sosNotification: [],
      signUpNotification: [],
      sos: null,
      signUp: null,
      appNotification: [],
      isShowComfirm: false,
      isShowMobileSideBar: false
    };
  }

  componentDidMount() {
    const { fleetId } = this.props.selectedFleet || {};
    if (fleetId) this.initSystemData(fleetId);
  }

  shouldComponentUpdate(nextProps) {
    const { fleetId: currentFleetId } = this.props.selectedFleet || {};
    const { fleetId: nextFleetId } = nextProps.selectedFleet || {};
    if (!currentFleetId && nextFleetId) this.initSystemData(nextFleetId);
    return true;
  }

  initSystemData = fleetId => {
    socketDispatchAuth(
      fleetId,
      this.socketAuthenticationCallback,
      this.socketDisconnectedCallback,
      this.socketReconnectCallback
    );

    // socketLocalApi.socketLogin(() => {
    //   if (
    //     this.props.auth.user.userType == userType.FleetAdmin ||
    //     this.props.auth.user.userType == userType.FleetUser
    //   ) {
    //     //remove socket event if existed to avoid duplicate
    //     socketLocalApi.remove(socketConfigs.receive.ivr.newbooking);
    //     socketLocalApi.remove(socketConfigs.receive.ivr.existedBooking);
    //     //IVR socket listener
    //     socketLocalApi.on(socketConfigs.receive.ivr.newbooking, data => {
    //       this.props.callCenterSupportActions.addNewCallSupport(data);
    //       if (
    //         !this.props.newBooking.bookingFormShow &&
    //         !this.props.bookingDetail.isShowing
    //       ) {
    //         this.handleCallCenterSupportClick(data);
    //       }
    //     });
    //     socketLocalApi.on(socketConfigs.receive.ivr.existedBooking, data => {
    //       this.props.callCenterSupportActions.addNewCallSupport(data);
    //       if (
    //         !this.props.newBooking.bookingFormShow &&
    //         !this.props.bookingDetail.isShowing
    //       ) {
    //         this.handleCallCenterSupportClick(data);
    //       }
    //     });
    //   }
    // });

    this.props.notificationActions
      .notificationgetAll({ fleetId: fleetId }, false)
      .then(data => {
        if (data.ok && data.res) {
          this.setState({ notification: data.res.list || [] });
        }
      });
    this.props.notificationActions
      .sosGetAll({ fleetId: fleetId }, false)
      .then(data => {
        if (data.ok && data.res) {
          this.setState({ sosNotification: data.res.list || [] });
        }
      });
    this.props.notificationActions
      .signUpNotificationGetAll({ fleetId: fleetId }, false)
      .then(data => {
        if (data.ok && data.res) {
          this.setState({ signUpNotification: data.res.list || [] });
        }
      });
  };

  socketAuthenticationCallback = payload => {
    socketDispatchApi.remove(socketConfigs.receive.notification);
    socketDispatchApi.remove(socketConfigs.receive.notifyNewFarmInJob);
    socketDispatchApi.remove(socketConfigs.receive.notifyNewFarmInJobFrom3rd);
    socketDispatchApi.remove(socketConfigs.receive.notifyCanceledJobBy3rd);
    socketDispatchApi.remove(socketConfigs.receive.notifyUpdatedJobBy3rd);
    socketDispatchApi.remove(socketConfigs.receive.notifyMdispatch);
    socketDispatchApi.remove(socketConfigs.receive.notifyNoStart);
    socketDispatchApi.remove(socketConfigs.receive.notifyReservation);
    socketDispatchApi.remove(socketConfigs.receive.notifyDMCCanceled);
    socketDispatchApi.remove(socketConfigs.receive.sos);
    socketDispatchApi.remove(socketConfigs.receive.signUpNotify);
    socketDispatchApi.remove(socketConfigs.receive.booking.cancel);
    socketDispatchApi.remove(socketConfigs.receive.booking.rejectBooking);
    socketDispatchApi.remove(socketConfigs.receive.booking.bookingSynced);
    socketDispatchApi.remove(socketConfigs.receive.booking.complete);
    socketDispatchApi.remove(socketConfigs.receive.booking.completeWithoutPay);
    socketDispatchApi.remove(socketConfigs.receive.booking.edit);
    socketDispatchApi.remove(socketConfigs.receive.booking.incident);
    socketDispatchApi.remove(socketConfigs.receive.booking.new_booking);
    socketDispatchApi.remove(socketConfigs.receive.booking.reBooking);
    socketDispatchApi.remove(socketConfigs.receive.booking.remove);
    socketDispatchApi.remove(socketConfigs.receive.booking.removeHydra);
    socketDispatchApi.remove(
      socketConfigs.receive.booking.retryCompleteBooking
    );
    socketDispatchApi.remove(socketConfigs.receive.booking.update);
    socketDispatchApi.remove(socketConfigs.receive.booking.changePaymentType);
    socketDispatchApi.remove(socketConfigs.receive.booking.update_booking);
    socketDispatchApi.remove(socketConfigs.receive.driver.endShift);
    socketDispatchApi.remove(socketConfigs.receive.driver.new_driver);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_bookIn);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_bookOFF);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_offline);
    socketDispatchApi.remove(socketConfigs.receive.driver.update_info);
    socketDispatchApi.remove(socketConfigs.receive.driver.update_location);

    //global event listener for cue update
    if (payload.res && payload.res.fleetId) {
      this.props.socketActions.socketAuthenticated(payload.res.fleetId);
    }

    socketDispatchApi.on(socketConfigs.receive.booking.update_booking, data => {
      realtimeHelper.CUE_UPDATE(data);
      if (
        this.props.menuHandle &&
        this.props.menuHandle.selectedMainMenu &&
        this.props.menuHandle.selectedMainMenu.key === 'Reservation'
      ) {
        let book = realtimeHelper.cueBookings()[data.bookInfo.bookId];
        switch (data.param.desStatus) {
          case desStatus.CONFIRMED: {
            if (!_.isUndefined(book) && book.reservation && book.drvInfo) {
              this.props.reservationActions.addBookingToReservation(book);
            }
            break;
          }
          case desStatus.ACTION: {
            if (!_.isUndefined(book) && book.reservation && book.drvInfo) {
              this.props.reservationActions.removeBookingFromReservation(book);
            }
            break;
          }
          case desStatus.CANCELED: {
            if (
              !_.isUndefined(data) &&
              data.bookInfo &&
              data.bookInfo.reservation
            ) {
              this.props.reservationActions.removeBookingFromReservation(
                data.bookInfo
              );
            }
            break;
          }
          default: {
          }
        }
      }
    });

    socketDispatchApi.on(socketConfigs.receive.booking.new_booking, data => {
      realtimeHelper.CUE_ADD_NEW_BOOKING(data);
    });

    socketDispatchApi.on(socketConfigs.receive.booking.edit, data => {
      realtimeHelper.CUE_EDIT(data);
      if (
        this.props.menuHandle &&
        this.props.menuHandle.selectedMainMenu &&
        this.props.menuHandle.selectedMainMenu.key === 'Reservation'
      ) {
        let book = realtimeHelper.cueBookings()[data.bookId];
        if (_.isUndefined(book)) {
          this.props.bookingDetailActions
            .getBookingDetail(data.bookId, false, false)
            .then(response => {
              if (response.ok) {
                book = response.res;
                if (book.reservation && book.drvInfo) {
                  this.props.reservationActions.addBookingToReservation(book);
                }
              }
            });
        } else {
          if (book.reservation && book.drvInfo) {
            this.props.reservationActions.addBookingToReservation(book);
          }
        }
      }
    });

    socketDispatchApi.on(socketConfigs.receive.booking.remove, data => {
      realtimeHelper.CUE_REMOVE_BOOKING(data);
    });

    socketDispatchApi.on(socketConfigs.receive.booking.removeHydra, data => {
      realtimeHelper.CUE_REMOVE_BOOKING_HYDRA(data);
    });

    //global event listener for driver update
    socketDispatchApi.on(socketConfigs.receive.driver.new_driver, data => {
      realtimeHelper.MAP_DRIVER_ADD(data);
    });

    socketDispatchApi.on(socketConfigs.receive.driver.set_bookIn, data => {
      realtimeHelper.MAP_DRIVER_UPDATE_BOOKIN(data);
    });

    socketDispatchApi.on(socketConfigs.receive.driver.set_bookOFF, data => {
      realtimeHelper.MAP_DRIVER_UPDATE_BOOKOFF(data);
    });

    socketDispatchApi.on(socketConfigs.receive.driver.set_offline, data => {
      realtimeHelper.MAP_DRIVER_OFFLINE(data);
    });

    socketDispatchApi.on(socketConfigs.receive.driver.update_info, data => {
      realtimeHelper.MAP_DRIVER_UPDATE(data);
    });

    socketDispatchApi.on(socketConfigs.receive.driver.update_location, data => {
      realtimeHelper.MAP_DRIVER_UPDATE_LOCATION(data);
    });

    socketDispatchApi.on(socketConfigs.receive.booking.cancel, data => {
      this.props.loadingActions.hideLoadingSpiner();
      if (data.returnCode == 200) {
        this.notification(
          'success',
          I18n.t('messages.booking.Cancel_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.notification('error', I18n.t('messages.booking.cancle_fail'));
      }
    });

    socketDispatchApi.on(socketConfigs.receive.booking.rejectBooking, data => {
      this.props.loadingActions.hideLoadingSpiner();
      if (data.code == 200) {
        this.notification(
          'success',
          I18n.t('messages.booking.Reject_booking_success')
        );
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.notification('error', I18n.t('messages.booking.Reject_fail'));
      }
    });

    // socketDispatchApi.on(socketConfigs.receive.booking.bookingSynced, data => {
    //   if (data.code == 200) {
    //     alert('hello reducer')
    //     this.props.cueActions.updateStatusSyncThirdPartyBooking(data)
    //     this.notification(
    //       'success',
    //       I18n.t('messages.booking.booking_Synced_success')
    //     );
    //   } else {
    //     this.notification('error', I18n.t('messages.booking.bookingSynced_fail'));
    //   }
    // });

    // socketDispatchApi.on(socketConfigs.receive.booking.update, data => {
    //   this.props.loadingActions.hideLoadingSpiner();
    //   if (data.code == 200) {
    //     this.notification(
    //       'success',
    //       I18n.t('messages.booking.Update_booking_success')
    //     );
    //     this.props.bookingDetailActions.bookingDetailClosed();
    //   } else {
    //     this.notification('error', this.showMessageErrorBooking(data));
    //   }
    // });

    socketDispatchApi.on(
      socketConfigs.receive.booking.changePaymentType,
      data => {
        this.props.loadingActions.hideLoadingSpiner();
        if (data.returnCode == 200) {
          this.notification(
            'success',
            I18n.t('messages.booking.Update_booking_success')
          );
          this.props.bookingDetailActions.bookingDetailClosed();
        } else {
          this.notification('error', this.showMessageErrorBooking(data));
        }
      }
    );

    socketDispatchApi.on(socketConfigs.receive.booking.incident, data => {
      this.props.loadingActions.hideLoadingSpiner();
      if (data.returnCode == 200) {
        // this.notification('success', I18n.t('messages.booking.Booking_drop_of_success'))
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        this.notification('error', I18n.t('messages.booking.incident_fail'));
      }
    });

    socketDispatchApi.on(
      socketConfigs.receive.booking.completeWithoutPay,
      data => {
        this.props.loadingActions.hideLoadingSpiner();
        if (data.returnCode == 200) {
          this.props.bookingDetailActions.bookingDetailClosed();
        } else {
          this.notification('error', I18n.t('messages.booking.complete_fail'));
        }
      }
    );

    socketDispatchApi.on(socketConfigs.receive.booking.complete, data => {
      this.props.loadingActions.hideLoadingSpiner();
      if (data.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        var msg = '';
        if (
          data.response &&
          data.response.response &&
          data.response.response.message
        ) {
          msg += ' (' + data.response.response.message + ' )';
        }

        const errorCode = _.get(data, 'response.returnCode');
        if (CODE_ERR_COMPLETE_BOOK.includes(errorCode)) {
          msg = '(' + I18n.t(`errors.completeBooking.${errorCode}`) + ')';
        }

        this.notification(
          'error',
          I18n.t('messages.booking.complete_fail'),
          msg
        );
      }
    });

    socketDispatchApi.on(socketConfigs.receive.booking.completed2CC, data => {
      this.props.loadingActions.hideLoadingSpiner();
      if (data.returnCode == 200) {
        this.props.bookingDetailActions.bookingDetailClosed();
      } else {
        var msg = '';
        if (
          data.response &&
          data.response.response &&
          data.response.response.message
        ) {
          msg += ' (' + data.response.response.message + ' )';
        }
        this.notification(
          'error',
          I18n.t('messages.booking.complete_fail'),
          msg
        );
      }
    });

    socketDispatchApi.on(
      socketConfigs.receive.booking.retryCompleteBooking,
      data => {
        this.props.loadingActions.hideLoadingSpiner();
        if (data.returnCode == 200) {
          this.props.bookingDetailActions.bookingDetailClosed();
        } else {
          var msg = '';
          if (
            data.response &&
            data.response.response &&
            data.response.response.message
          ) {
            msg += ' (' + data.response.response.message + ' )';
          }
          this.notification(
            'error',
            I18n.t('messages.booking.complete_fail'),
            msg
          );
        }
      }
    );

    socketDispatchApi.on(socketConfigs.receive.notification, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyNewFarmInJob, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyNewFarmInJobFrom3rd, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyCanceledJobBy3rd, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyUpdatedJobBy3rd, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyMdispatch, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyNoStart, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyReservation, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });
    socketDispatchApi.on(socketConfigs.receive.notifyDMCCanceled, data => {
      this.state.notification.unshift(data);
      this.playMptificationSound();
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ notification: this.state.notification });
    });

    socketDispatchApi.on(socketConfigs.receive.sos, data => {
      this.state.sosNotification.unshift(data);
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ sosNotification: this.state.sosNotification, sos: data });
    });

    socketDispatchApi.on(socketConfigs.receive.signUpNotify, data => {
      this.state.signUpNotification.unshift(data);
      this.props.notificationActions.notificationHasNew(data);
      this.setState({ signUpNotification: this.state.signUpNotification });
    });
  };

  // verifyStripeSCA = (clientSecret) => {
  //   const { auth } = this.props;
  //   const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
  //   stripe.handleCardPayment(
  //     clientSecret
  //   ).then((result) => {
  //     if (result.error) {
  //       // Display error.message in your UI.
  //       this.props.loadingActions.hideLoadingSpiner();
  //       const errorMessage = result.error.message || result.error.code;
  //       this.context.notification('error', errorMessage);
  //     };
  //   });
  // }

  socketDisconnectedCallback = payload => {
    this.props.socketActions.socketDisconnected();
    socketDispatchApi.remove(socketConfigs.receive.booking.update_booking);
    socketDispatchApi.remove(socketConfigs.receive.booking.edit);
    socketDispatchApi.remove(socketConfigs.receive.driver.new_driver);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_bookIn);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_bookOFF);
    socketDispatchApi.remove(socketConfigs.receive.driver.set_offline);
    socketDispatchApi.remove(socketConfigs.receive.driver.update_info);
    socketDispatchApi.remove(socketConfigs.receive.driver.update_location);

    socketDispatchApi.remove(socketConfigs.receive.booking.cancel);
    socketDispatchApi.remove(socketConfigs.receive.booking.update);
    socketDispatchApi.remove(socketConfigs.receive.booking.incident);
    socketDispatchApi.remove(socketConfigs.receive.booking.completeWithoutPay);
    socketDispatchApi.remove(socketConfigs.receive.booking.complete);
    socketDispatchApi.remove(socketConfigs.receive.booking.newBooking);
    socketDispatchApi.remove(socketConfigs.receive.booking.rejectBooking);
  };

  socketReconnectCallback = payload => {
    this.props.socketActions.socketReconnectAttempt();
  };

  getChildContext() {
    return {
      notification: this.notification,
      currenLanguage: this.props.language,
      user: this.props.auth.user,
      reloadCueAnhMap: this.initCueAndDriverData
    };
  }

  playMptificationSound = () => {
    const { selectedFleet = {} } = this.props || {};
    const { generalSetting: { soundNotify = false } = {} } =
      selectedFleet || {};
    if (!soundNotify) return;
    if (
      this.refs.notification_sound_player &&
      this.refs.notification_sound_player.paused
    ) {
      this.refs.notification_sound_player.play();
    }
  };

  handleCallCenterSupportClick = item => {
    if (this.props.newBooking.bookingFormShow) {
      this.setState({ isShowComfirm: true });
    } else {
      if (item.bookId) {
        this.props.bookingDetailActions
          .getBookingDetail(item.bookId)
          .then(data => {
            if (!data.res || data.error) {
              this.context.notification(
                'error',
                I18n.t('cue.Error_load_booking')
              );
            }
          });
        this.props.callCenterSupportActions.removeCallSupport(item);
      } else {
        this.props.callCenterSupportActions.removeCallSupport(item);
        let NewBK = {
          booking: {},
          customer: {
            phone: item.phone
          }
        };
        this.props.newbookingActions.newBookingFromExistedBooking(NewBK);
      }
    }
  };

  handleDismissCallCenterSupportClick = item => {
    this.props.callCenterSupportActions.removeCallSupport(item);
  };

  handleConfirmCloseClick = () => {
    this.setState({ isShowComfirm: false, sos: null });
  };

  showMessageErrorBooking = (data) => {
    let msg = <Translate value="messages.booking.data_incorect" dangerousHTML />
    if (data.code) {
      msg = I18n.t('messages.booking.' + data.code);
      const dataInfo = data.info || data.error;
      if (data.code == 303) {
        if (data.error.minimumBookAhead) {
          let hour = 0;
          const min = data.error.minimumBookAhead % 60;
          if (data.error.minimumBookAhead > 0) {
            hour = Math.floor(data.error.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (data.error.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            data.error.maximumBookAhead
          );
        }
        if (data.error.overlapTime) {
          msg = I18n.t('messages.booking.reservation_booking_overlaps');
        } else if (data.error.cannotAssignDriver) {
          msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
        }
      } else if (dataInfo.exDoDiffDo === false) {
        msg = I18n.t('messages.booking.exDoDiffDo');
      }
    } else {
      const dataInfo = data.info || data.error;
      if (dataInfo.limit === false) {
        msg = I18n.t('messages.booking.booking_limited');
      } else if (
        dataInfo.sameZone === false &&
        dataInfo.crossZone === false
      ) {
        msg = I18n.t('messages.booking.area_service_unavailable');
      } else if (!dataInfo.rate) {
        msg = I18n.t(
          'messages.booking.Please_reload_page_or_check_your_connection'
        );
      } else if (!dataInfo.pickUpTime) {
        msg = I18n.t('messages.booking.time_invalid');
        if (dataInfo.minimumBookAhead) {
          let hour = 0;
          let min = dataInfo.minimumBookAhead % 60;
          if (dataInfo.minimumBookAhead > 0) {
            hour = Math.floor(dataInfo.minimumBookAhead / 60);
          }
          msg = I18n.t('messages.booking.minimumBookAhead').format(hour, min);
        }
        if (dataInfo.maximumBookAhead) {
          msg = I18n.t('messages.booking.maximumBookAhead').format(
            dataInfo.maximumBookAhead
          );
        }
        if (dataInfo.exDoDiffDo === false) {
          msg = I18n.t('messages.booking.exDoDiffDo');
        }
      } else if (
        dataInfo.psgInfo === false ||
        dataInfo.psgInfo.isActive === false
      ) {
        msg = I18n.t('messages.booking.psg_inactivate');
      } else if (dataInfo.preAuthorized === false) {
        if (dataInfo.preAuthorizedCode) {
          msg = I18n.t('messages.credits.' + dataInfo.preAuthorizedCode);
        }
      } else if (dataInfo.cannotAssignDriver) {
        msg = I18n.t('messages.booking.Driver_signed_out_or_deactivated');
      } else if (dataInfo.overlapTime) {
        msg = I18n.t('messages.booking.reservation_booking_overlaps');
      }
    }
    return msg;
  }

  notification = (type, title, message, clickHandler, time = 5000) => {
    type = type == 'error' ? 'danger' : type;
    let noti = {
      type: type,
      title: title,
      message: message,
      onClick: clickHandler,
      id: uuidv4({ msecs: new Date().getTime() })
    };
    this.state.appNotification.unshift(noti);
    this.setState({ appNotification: this.state.appNotification });
    setTimeout(() => {
      this.removeAppNotification(noti);
    }, time);
  };

  removeAppNotification = noti => {
    this.setState({
      appNotification: this.state.appNotification.filter(n => {
        return n.id != noti.id;
      })
    });
  };

  buildPageTitle = menu => {
    let title = '';
    if (this.props.newBooking.bookingFormShow) {
      title = I18n.t('Sidebar.New_booking');
    } else {
      if (menu) {
        title += I18n.t(menu.text);
        if (menu.sub && menu.sub.length > 0) {
          let sub = menu.sub.filter(mn => {
            return mn.active && mn.show;
          });
          sub = sub[0] || menu.sub[0];
          title += '/' + this.buildPageTitle(sub);
        }
      }
    }

    return title;
  };

  SOSNotificationItemClickHandle = data => {
    this.props.notificationActions.sosMarkItemAsRead({
      fleetId: this.props.selectedFleet.fleetId,
      id: data.id,
      index: this.state.sosNotification.indexOf(data)
    });

    let { sosNotification } = this.state;
    sosNotification = sosNotification.map(item => {
      if (data == item) {
        data.unread = false;
      }
      return item;
    });

    this.setState({ sos: data, sosNotification });
  };

  SOSNotificationDeleteItem = data => {
    this.props.notificationActions
      .sosDelete({
        fleetId: this.props.selectedFleet.fleetId,
        id: data.id,
        index: _.findIndex(this.state.sosNotification, data)
      })
      .then(res => {
        if (res.res && res.res.code) {
          this.state.sosNotification.splice(
            this.state.sosNotification.indexOf(data),
            1
          );
          this.setState({ sosNotification: this.state.sosNotification });
        }
      });
  };

  notificationItemClickHandle = Noti => {


    const noActions = ['DebtWriteOff', 'driverCancelTrip', 'minimumSeatRequired']
    if (noActions.indexOf(Noti.action) === -1) {
      this.props.bookingDetailActions
        .getBookingDetail(Noti.bookId, false)
        .then(data => {
          if (!data.res || data.error) {
            this.props.bookingDetailActions
              .getBookingDetail(Noti.bookId, true)
              .then(data => {
                if (!data.res || data.error) {
                  this.notification(
                    'error',
                    I18n.t('messages.booking.Booking_detail_not_found')
                  );
                }
              });
          }
        });
      this.state.notification = this.state.notification.map(item => {
        if (Noti == item) {
          Noti.unread = false;
        }
        return item;
      });
      this.setState({ notification: this.state.notification });
      this.props.notificationActions.notificationMarkItemAsRead({
        fleetId: this.props.selectedFleet.fleetId,
        id: Noti.id,
        index: this.state.notification.indexOf(Noti)
      });
    }
  };

  notificationDeleteItem = data => {
    this.props.notificationActions.notificationDeleteItem({
      fleetId: this.props.selectedFleet.fleetId,
      id: data.id,
      index: this.state.notification.indexOf(data)
    });
    this.state.notification.splice(this.state.notification.indexOf(data), 1);
    this.setState({ notification: this.state.notification });
  };

  notificationClickToView = () => {
    const { notification } = this.state;
    const isViewAll = _.findIndex(notification, o => !o.viewed) === -1;
    if (isViewAll) {
      return;
    }
    this.state.notification = this.state.notification.map(data => {
      data.viewed = true;
      return data;
    });
    this.props.notificationActions.notificationmarkAllAsViewed({
      fleetId: this.props.selectedFleet.fleetId
    });
    this.setState({ notification: this.state.notification });
  };

  sosClickToView = () => {
    const { sosNotification } = this.state;
    const isViewAll = _.findIndex(sosNotification, o => !o.viewed) === -1;
    if (isViewAll) {
      return;
    }
    _.each(sosNotification, s => {
      s.viewed = true;
    });
    this.props.notificationActions.sosMarkAllAsViewed({
      fleetId: this.props.selectedFleet.fleetId
    });
    this.setState({ sosNotification });
  };

  hanldClickToViewDriverSignUp = () => {
    const { signUpNotification } = this.state;
    const isViewAll = _.findIndex(signUpNotification, o => !o.viewed) === -1;
    if (isViewAll) {
      return;
    }
    _.each(signUpNotification, s => {
      s.viewed = true;
    });
    this.props.notificationActions.signUpNotificationMarkAllAsViewed({
      fleetId: this.props.selectedFleet.fleetId
    });
    this.setState({ signUpNotification });
  };

  hanldClickSignUpNotificationItem = data => {
    this.props.notificationActions
      .signUpNotificationMarkItemAsRead({
        fleetId: this.props.selectedFleet.fleetId,
        id: data.id,
        index: this.state.signUpNotification.indexOf(data)
      });

    let { signUpNotification } = this.state;
    signUpNotification = signUpNotification.map(item => {
      if (data === item) {
        data.unread = false;
      }
      return item;
    });
    this.setState({ signUp: data, signUpNotification });
  };

  handleDeleteSignUpNotificationItem = data => {
    this.props.notificationActions
      .signUpNotificationDelete({
        fleetId: this.props.selectedFleet.fleetId,
        id: data.id,
        index: _.findIndex(this.state.signUpNotification, data)
      })
      .then(res => {
        if (res.res && res.res.code) {
          this.state.signUpNotification.splice(
            this.state.signUpNotification.indexOf(data),
            1
          );
          this.setState({ signUpNotification: this.state.signUpNotification });
        }
      });
  };

  languageSelected = lang => {
    this.props.setLanguage(lang);
  };

  handleBookingDetailView = bookId => {
    this.props.bookingDetailActions.getBookingDetail(bookId * 1).then(data => {
      if (!data.res || data.error) {
        this.props.bookingDetailActions
          .getBookingDetail(bookId * 1, true)
          .then(data => {
            if (!data.res || data.error) {
              this.notification(
                'error',
                I18n.t('messages.booking.Booking_detail_not_found')
              );
            } else {
              this.handleConfirmCloseClick();
            }
          });
      } else {
        this.handleConfirmCloseClick();
      }
    });
  };

  render() {
    let isViewProfile;
    let isViewChangePass;
    let isViewNotifications, isViewSOS, isViewSignUpNotifications;
    if (this.props.location.pathname === '/profile') {
      isViewProfile = true;
    }
    if (this.props.location.pathname === '/change-password') {
      isViewChangePass = true;
    }
    if (this.props.location.pathname === '/notifications') {
      isViewNotifications = true;
    }
    if (this.props.location.pathname === '/sos') {
      isViewSOS = true;
    }
    if (this.props.location.pathname === '/signupnotifications') {
      isViewSignUpNotifications = true;
    }
    return (
      <>
        <audio controls ref="notification_sound_player" className="hidden">
          <source src={notification_sound} type="audio/ogg" />
        </audio>
        <Header
          title={
            isViewChangePass
              ? I18n.t('userProfile.Change_password')
              : isViewProfile
                ? I18n.t('userProfile.My_account')
                : this.buildPageTitle(this.props.menuHandle.selectedMainMenu)
          }
          isShowNotification={!this.props.defaultPw}
          languageSelect={this.languageSelected}
          logout={this.props.handleLogout}
          notification={this.state.notification}
          clicktoView={this.notificationClickToView}
          onMenuClick={this.props.sideBarmenuClick}
          itemclick={this.notificationItemClickHandle}
          viewedSOS={this.sosClickToView}
          viewedDriverSignUp={this.hanldClickToViewDriverSignUp}
          signUpItemClick={this.hanldClickSignUpNotificationItem}
          deleteSignUpNotiItem={this.handleDeleteSignUpNotificationItem}
          handleOpenDetailPage={this.props.handleOpenDetailPage}
          deleteNotiItem={this.notificationDeleteItem}
          router={this.props.router}
          hideNewBooking={() => {
            this.props.closeNewBookingRequest();
          }}
          sosNotification={this.state.sosNotification}
          signUpNotification={this.state.signUpNotification}
          SOSitemclick={this.SOSNotificationItemClickHandle}
          deleteSOSNotiItem={this.SOSNotificationDeleteItem}
          translations={this.props.language.translations}
          mobileHambergerClick={this.props.mobileHambergerClick}
          collapsed={this.props.sidebarCollapsed}
          collapedClickHandle={this.props.sidebarCollapsedClickHandle}
        />
        <NotificationContainer />
        <AlertContainer>
          {this.props.callcenterSupport.inCommingQueue &&
            this.props.callcenterSupport.inCommingQueue.length > 0 &&
            this.props.callcenterSupport.inCommingQueue.map(item => {
              return !!item.bookId ? (
                <Alert
                  onDismiss={e => {
                    e.stopPropagation();
                    this.handleDismissCallCenterSupportClick(item);
                  }}
                  key={item.uuid}
                  onClick={() => {
                    this.handleCallCenterSupportClick(item);
                  }}
                  type="warning"
                  className="clickable"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: I18n.t(
                        'messages.commonMessages.callcenter_existed_booking_message'
                      ).format(item.bookId, item.phone)
                    }}
                  />
                </Alert>
              ) : (
                <Alert
                  onDismiss={e => {
                    e.stopPropagation();
                    this.handleDismissCallCenterSupportClick(item);
                  }}
                  key={item.uuid}
                  onClick={() => {
                    this.handleCallCenterSupportClick(item);
                  }}
                  className="clickable"
                  type="success"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: I18n.t(
                        'messages.commonMessages.callcenter_newbooking_message'
                      ).format(item.phone)
                    }}
                  />
                </Alert>
              );
            })}
          {this.state.appNotification &&
            this.state.appNotification.map(noti => {
              return (
                <Alert
                  onDismiss={e => {
                    e.stopPropagation();
                    this.removeAppNotification(noti);
                  }}
                  key={noti.id}
                  onClick={noti.onClick}
                  type={noti.type == 'error' ? 'danger' : noti.type}
                  className={noti.onClick ? 'clickable' : ''}
                >
                  <div>
                    {noti.title && <h4 className="noti-title">{noti.title}</h4>}
                    {noti.message ? (
                      _.isString(noti.message) ? (
                        <span>{noti.message}</span>
                      ) : noti.message.errorCode ? (
                        <Translate value={'errors.' + noti.message.errorCode} />
                      ) : null
                    ) : null}
                  </div>
                </Alert>
              );
            })}
        </AlertContainer>
        {this.state.isShowComfirm ? (
          <Confirm
            confirm={{
              title: 'Warning!',
              body: I18n.t(
                'messages.commonMessages.callcenter_close_newbooking_form_warning'
              )
            }}
            handleConfirmCloseClick={this.handleConfirmCloseClick}
          />
        ) : null}
        {(this.props.auth.user.userType == userType.FleetAdmin ||
          this.props.auth.user.userType == userType.FleetUser) &&
          this.state.sos && (
            <Confirm
              confirm={{
                title: (
                  <span className="red">
                    <i className="fa fa-warning" />
                    <Translate
                      className="text-tranform-none"
                      value="messages.commonMessages.Emergency_SOS_Title"
                    />
                  </span>
                ),
                body: (
                  <div>
                    <p>
                      <span className="bold">
                        {I18n.t('General.App_SOS_dialog_form_message_1').format(
                          this.state.sos.fromApp === "crew" ? "Crew" :
                            this.state.sos.fromApp == 'passenger'
                              ? 'Pax'
                              : 'Driver'
                        )}
                      </span>
                    </p>
                    <p>
                      <span>
                        {I18n.t('General.App_SOS_dialog_form_message_2')}
                      </span>
                      {' '}
                      <a
                        target={'_blank'}
                        href={
                          'https://maps.google.com/maps?q=' +
                          this.state.sos.geo[1] +
                          ',' +
                          this.state.sos.geo[0]
                        }
                      >
                        https://maps.google.com/maps?q=
                        {this.state.sos.geo[1]},{this.state.sos.geo[0]}
                      </a>
                    </p>
                    <p
                      className="clickable blue"
                      onClick={() => {
                        this.handleBookingDetailView(this.state.sos.bookId);
                      }}
                    >
                      {I18n.t('General.App_SOS_dialog_form_message_3').format(
                        this.state.sos.bookId,
                        this.state.sos.driverName || '',
                        this.state.sos.passengerName || '',
                        this.state.sos.licensePlate || '',
                        moment
                          .tz(
                            this.state.sos.createdDate || this.state.sos.date,
                            this.props.selectedFleet.timezone
                          )
                          .format('DD/MM/YYYY HH:mm')
                      )}
                    </p>
                  </div>
                )
              }}
              handleConfirmCloseClick={this.handleConfirmCloseClick}
              className="sms-dialog"
            />
          )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    menuHandle,
    i18n,
    auth,
    newBooking,
    loadingBar,
    notification
  } = state;
  if (menuHandle) {
    return {
      sidebarCollapsed: menuHandle.sidebarCollapsed,
      menuHandle: menuHandle,
      pagetitle: menuHandle.pagetitle,
      language: i18n,
      newbookingFormShowed: menuHandle.newbookingFormShowed,
      defaultPw: auth.user && auth.user.defaultPw,
      auth: auth,
      selectedFleet: auth.selectedFleet,
      newBooking: newBooking,
      loadingBar: loadingBar,
      notis: notification,
      bookingDetail: state.bookingDetail,
      callcenterSupport: state.callcenterSupport
    };
  } else {
    return {
      sidebarCollapsed: false,
      menuHandle: {},
      pagetitle: 'Home',
      language: 'en-US',
      selectedFleet: auth.selectedFleet
    };
  }
}

AppNotifArea.propTypes = {};

AppNotifArea.contextTypes = {
  router: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

AppNotifArea.childContextTypes = {
  notification: PropTypes.func,
  currenLanguage: PropTypes.object,
  user: PropTypes.object,
  socket: PropTypes.object,
  reloadCueAnhMap: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    menuActions: bindActionCreators(menuActions, dispatch),
    setLanguage: lang => {
      localStorage.setItem('language', lang);
      dispatch(setLocale(lang));
    },
    loadTranslations: data => dispatch(loadTranslations(data)),
    logout: () => dispatch(logout()),
    loadUserFleet: options => dispatch(loadUserFleet(options)),
    commonActions: bindActionCreators(commonActions, dispatch),
    socketActions: bindActionCreators(socketActions, dispatch),
    newBookingRequest: () => dispatch(newBookingRequest()),
    closeNewBookingRequest: () => dispatch(closeNewBookingForm()),
    mapActions: bindActionCreators(mapActions, dispatch),
    cueActions: bindActionCreators(cueActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    reservationActions: bindActionCreators(reservationActions, dispatch),
    callCenterSupportActions: bindActionCreators(
      callCenterSupportActions,
      dispatch
    ),
    newbookingActions: bindActionCreators(newbookingActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true
})(AppNotifArea);
