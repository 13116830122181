import React from 'react'
import Switch from "react-switch";
import PropTypes from "prop-types";

const Toogle = ({
  checked,
  handleChange,
  iconUncheck,
  iconCheck,
  activeColor,
  bgColor,
  disabled
}) => {
  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      handleDiameter={28}
      offColor={bgColor || "#121418"}
      onColor={bgColor || "#121418"}
      offHandleColor={activeColor || "#04BE76"}
      onHandleColor={activeColor || "#04BE76"}
      height={40}
      width={70}
      borderRadius={35}
      uncheckedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 14,
            paddingRight: 2
          }}
        >
          {iconCheck}
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 14,
            paddingRight: 2
          }}
          >
          {iconUncheck}
        </div>
      }
      uncheckedHandleIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: 'white',
            fontSize: 14
          }}
        >
          {iconUncheck}
        </div>
      }
      checkedHandleIcon={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: 'white',
            fontSize: 14
          }}
        >
          {iconCheck}
        </div>
      }
      className="react-switch"
      id="small-radius-switch"
    />
  )
}

Toogle.prototype = {
  checked: PropTypes.bool,
  checked: PropTypes.func,
  iconUncheck: PropTypes.string,
  iconCheck: PropTypes.string,
  activeColor: PropTypes.string,
  bgColor: PropTypes.string,
}

export default Toogle