import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { LOCATION_TYPE } from '../../../constants/commondata';
import LocationInput from '../../../components/bookingDetail/bookComponent/LocationInput';
import { geoPlaceDetailMapProvider } from '../../../utils/mapUtils';
import { QQUltis } from '../../../components/qqMap';
import { getLanguageAutoCompleteSearch, Validation } from '../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../components/validator';
import { getGoogleAddress } from '../../../components/bookingDetail/bookFunction/bookingInfo';

class DestinationLocation extends PureComponent {
  state = {
    addressDestination: '',
    destinationFrom: '',
    destinationAddress: '',
    editable: true,
  };


  componentDidMount() {
    if (this.props.destinationAddress) {
      this.setState({ addressDestination: this.props.destinationAddress });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.destinationAddress !== this.props.destinationAddress) {
      this.setState({addressDestination: nextProps.destinationAddress, destinationFrom: nextProps.destinationFrom })
    }
  }

  handleSelectThirdPartyDestination = (addressDestination, data) => {
    if (addressDestination && addressDestination !== '') {
      if (data && data.location) {
        const cityCountry = data.location.city;
        let cityName = '';
        if (cityCountry) {
          const citySplit = cityCountry.split('_');
          if (citySplit && citySplit.length >= 3) {
            if (citySplit[1]) {
              cityName = citySplit[1];
            } else if (citySplit[2]) {
              cityName = citySplit[2];
            }
          }
        }
        this.setState({ addressDestination });
        this.props.intercityBookingActions.updateCommonData({
          destinationLocation: {
            lat: data.location.coordinates[1],
            lng: data.location.coordinates[0],
            zipCode: data.location.zipCode,
            countryCode: data.location.countryCode,
            city: data.location.city,
            cityName,
            instructionLink: data.location.instructionLink,
            addressDetails: data.location.addressDetails
          },
          destinationAddress: addressDestination,
          destinationFrom: LOCATION_TYPE.ThirdParty,
        });
        this.props.intercityBookingActions.etaFare();
        this.props.getLocationService()
      }
    } else {
      this.setState({
        locationDestination: null,
        loadingDestination: false,
      });
    }
  };

  handleSelectDestination = (addressDestination, placeId, pointOfInterest, sessionToken) => {
    const {
      location: { isChina },
    } = this.props;
    if (addressDestination && addressDestination != '') {
      this.setState({
        loadingDestination: true,
        destinationFrom: LOCATION_TYPE.Google,
      });
      const callback = (err, location, results) => {
        if (err) {
          this.setState({
            locationDestination: null,
            loadingDestination: false,
          });
        } else {
          const adrText = getGoogleAddress({
            googleAddress: this.props.selectedFleet.googleAddress,
            formattedAddress: location?.formatted_address,
            suggestionAddress: addressDestination
          })
          this.setState({
            loadingDestination: false,
            addressDestination: adrText
          });
          this.props.intercityBookingActions.updateCommonData({
            destinationLocation: location,
            destinationAddress: adrText,
            destinationFrom: isChina
              ? LOCATION_TYPE.Tencent
              : LOCATION_TYPE.Google,
          });
          this.props.intercityBookingActions.etaFare();
          this.props.getLocationService()
        }
      };
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props.language,
        bookId: this.props?.bookInfo?.bookId
      });
    } else {
      this.setState({
        locationDestination: null,
        loadingDestination: false,
      });
    }
  };

  handleChangeThirdPartyDestination = (addressThirdPartyDestination) => {
    if (
      addressThirdPartyDestination &&
      addressThirdPartyDestination.length > 0
    ) {
      this.setState({
        addressDestination: addressThirdPartyDestination,
        destinationFrom: LOCATION_TYPE.ThirdParty,
      });
    } else {
      this.handleAddressRemovedDestination();
    }
  };

  handleChangeDestination = (addressDestination) => {
    if (addressDestination && addressDestination.length > 0) {
      this.setState({
        addressDestination,
        destinationFrom: LOCATION_TYPE.Google,
      });
    } else {
      this.handleAddressRemovedDestination();
    }
  };

  handleAddressRemovedDestination = () => {
    this.setState({
      addressDestination: '',
      destinationFrom: null,
    });
    this.props.intercityBookingActions.updateCommonData({
      destinationLocation: null,
      destinationAddress: '',
      destinationFrom: null,
    });
    this.props.intercityBookingActions.etaFare();
    this.props.getLocationService()
  };

  render() {
    const {
      location,
      valid,
      selectedFleet,
      validatorCallback,
      isSubmited,
      destinationLocation,
      routeSelected,
      isViewOnly,
      options
    } = this.props;
    const { partyLocation } = selectedFleet.moduleSettings;
    const { addressDestination, destinationFrom } = this.state;
    const editable = !isViewOnly
    return (
      <FormGroup
        className={((addressDestination && routeSelected) || isSubmited) && valid.addressDestination === false ? 'error' : null}
      >
        <Form.Label>
          <Translate value="newbooking.Destination" />{' '}
          <span className="require"> *</span>
        </Form.Label>
        {partyLocation && (
          <LocationInput
            address={
              destinationFrom !== LOCATION_TYPE.ThirdParty
                ? ''
                : addressDestination
            }
            onChange={this.handleChangeThirdPartyDestination}
            onRemove={this.handleAddressRemovedDestination}
            onSelect={this.handleSelectThirdPartyDestination}
            disabled={
              !editable ||
              (destinationLocation && destinationFrom !== LOCATION_TYPE.ThirdParty)
            }
            thirdParty
            value={addressDestination}
            // recents={editable && recentPlaces && recentPlaces.destination}
            onSelectRecent={this.handleSelectThirdPartyDestination}
            isChina={location.isChina}
            options={options}
            pickUp
            data={this.props.bookInfo}
            center={this.props.center}
          />
        )}

        <LocationInput
          // recents={editable && recentPlaces && recentPlaces.destination}
          address={
            destinationFrom == LOCATION_TYPE.ThirdParty
              ? ''
              : addressDestination
          }
          onSelectRecent={this.handleSelectRecentPickup}
          onChange={this.handleChangeDestination}
          onRemove={this.handleAddressRemovedDestination}
          onSelect={this.handleSelectDestination}
          center={this.props.center}
          value={addressDestination}
          disabled={isViewOnly || destinationFrom == LOCATION_TYPE.ThirdParty}
          isChina={location.isChina}
          className="mb0"
          data={this.props.bookInfo}
          options={options}
        />
        <Validator
          callback={validatorCallback}
          id="addressDestination"
        >
          <ValidCase
            valid={addressDestination}
            hide={(!addressDestination || !routeSelected)}
            message={I18n.t('newbooking.No_support_location')}
          />
          <ValidCase
            valid={
              Validation.insidePolygon(
                destinationLocation,
                _.get(routeSelected, 'secondLocation.coordinates[0]')
              )
            }
            hide={(!addressDestination || !routeSelected)}
            message={I18n.t('newbooking.No_support_location')}
          />
        </Validator>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    location: state.commonData.location,
    destinationLocation: state.intercityBooking.common.destinationLocation,
    destinationFrom: state.intercityBooking.common.destinationFrom,
    destinationAddress: state.intercityBooking.common.destinationAddress,
    routeSelected: state.intercityBooking.common.routeSelected,
    language: state.i18n,
    bookInfo: state.bookingDetail.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinationLocation);
