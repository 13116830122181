import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Row,
  Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import ModalZoneNoShow from '../modals/ModalZoneNoShow';

class NoShowFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDrivernoShowCheckChange = (e) => {
    this.props.handleUpdateFormData('noShow.isActive', e.target.checked);
  };

  handleApplyNoShowCountDownClockChange = (e) => {
    this.props.handleUpdateFormData('noShow.applyCountDown', e.target.checked);
  };

  handleIsLimitRadiusChange = (e) => {
    const { formData } = this.props;
    if (e.target.checked && _.isNil(formData.noShow.limitRadiusValue)) {
      this.props.handleUpdateFormData('noShow.limitRadiusValue', 0.5);
    }
    this.props.handleUpdateFormData('noShow.isLimitRadius', e.target.checked);
  };

  handleNoShowCountdownDurationChange = (e) => {
    this.props.handleUpdateFormData('noShow.countdownDuration', e.target.value);
  };

  handleChangeMaxWaitTime = (e) => {
    this.props.handleUpdateFormData('noShow.maxWaitTime', e.target.value);
  };

  validateMaxWaitTime = () => {
    const { formData } = this.props;
    let maxWaitTime = _.get(formData, 'noShow.maxWaitTime');
    maxWaitTime = _.isNull(maxWaitTime) ? 1 : parseInt(maxWaitTime);
    return maxWaitTime >= 0 && maxWaitTime <= 9999;
  };

  handleLimitRadiusValueChange = (e) => {
    this.props.handleUpdateFormData('noShow.limitRadiusValue', e.target.value);
  };

  validateNoShowDuration = () => {
    const { formData } = this.props;
    let duration = _.get(formData, 'noShow.countdownDuration');
    duration = _.isNull(duration) ? 1 : parseInt(duration);
    return duration >= 1 && duration <= 9999;
  };

  validateLimitRadiusValue = () => {
    const { formData } = this.props;
    let limitValue = _.get(formData, 'noShow.limitRadiusValue');
    limitValue = _.isNull(limitValue) ? 1 : parseFloat(limitValue);
    return limitValue > 0 && limitValue <= 9999;
  };

  handleCloseModalZoneNoShow = () => {
    this.setState({ showModalZoneNoShow: false });
  };

  openModalZoneNoShow = () => {
    this.setState({ showModalZoneNoShow: true });
  };

  render() {
    const { ValidatorCallback, editable, isSubmited, valid, selectedFleet } =
      this.props;
    let {
      formData: { noShow },
    } = this.props;
    noShow = noShow || {};
    const unitDistance = _.get(selectedFleet, 'unitDistance', 'km');
    return (
      <Fragment>
        <CcCheckbox
          disabled={editable}
          checked={noShow.isActive || false}
          onChange={this.handleDrivernoShowCheckChange}
          text={I18n.t('generalSetting.MDISPATCHER_NO_SHOW')}
          labelClassName="fs-16"
          className="jc-end"
        />

        {noShow.isActive ? (
          <Row className="pl-xl">
            <Col xs={12}>
              <CcCheckbox
                disabled={editable}
                checked={noShow.applyCountDown || false}
                onChange={this.handleApplyNoShowCountDownClockChange}
                text={I18n.t('generalSetting.Apply_countdown_clock')}
              />
              {noShow.applyCountDown ? (
                <>
                  <FormGroup
                    className={
                      !isSubmited
                        ? null
                        : valid.noShowDuration === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="generalSetting.DurationRegularBooking" />
                      <QuestionCircleTooltip
                        text={I18n.t(
                          'generalSetting.DurationRegularBookingToolTip'
                        )}
                      />
                    </Form.Label>
                    <FormGroup className="qup-input-group">
                      <InputGroup
                        className={`single-addon-right ${
                          editable ? 'disabled' : ''
                        }`}
                      >
                        <FormControl
                          type="text"
                          className="form-custom"
                          onChange={this.handleNoShowCountdownDurationChange}
                          onBlur={(e) => {
                            textboxNumberHelper.onBlurHandle(
                              e,
                              this.handleNoShowCountdownDurationChange
                            );
                          }}
                          onFocus={(e) => {
                            textboxNumberHelper.onfocusHandle(
                              e,
                              this.handleNoShowCountdownDurationChange
                            );
                          }}
                          value={noShow.countdownDuration}
                          placeholder={I18n.t(
                            'generalSetting.DurationRegularBooking'
                          )}
                          disabled={editable}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>mins</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </FormGroup>
                    <Validator id="noShowDuration" callback={ValidatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={Validation.isInteger(noShow.countdownDuration)}
                        message={I18n.t(
                          'messages.commonMessages.must_be_integer'
                        )}
                      />
                      <ValidCase
                        hide={!isSubmited}
                        valid={this.validateNoShowDuration()}
                        message={I18n.t(
                          'messages.commonMessages.Integer_from_range'
                        ).format('1', '9999')}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup
                    className={
                      !isSubmited
                        ? null
                        : valid.noShowMaxWaitTime === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="generalSetting.maxWaitTime" />
                      <QuestionCircleTooltip
                        text={I18n.t('generalSetting.maxWaitTimeToolTip')}
                      />
                    </Form.Label>
                    <FormGroup className="qup-input-group">
                      <InputGroup
                        className={`single-addon-right ${
                          editable ? 'disabled' : ''
                        }`}
                      >
                        <FormControl
                          type="text"
                          className="form-custom"
                          onChange={this.handleChangeMaxWaitTime}
                          onBlur={(e) => {
                            textboxNumberHelper.onBlurHandle(
                              e,
                              this.handleChangeMaxWaitTime
                            );
                          }}
                          onFocus={(e) => {
                            textboxNumberHelper.onfocusHandle(
                              e,
                              this.handleChangeMaxWaitTime
                            );
                          }}
                          value={noShow.maxWaitTime}
                          placeholder={I18n.t(
                            'generalSetting.maxWaitTimePlaceholder'
                          )}
                          disabled={editable}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>mins</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </FormGroup>
                    <Validator
                      id="noShowMaxWaitTime"
                      callback={ValidatorCallback}
                    >
                      <ValidCase
                        hide={!isSubmited}
                        valid={Validation.isInteger(noShow.maxWaitTime)}
                        message={I18n.t(
                          'messages.commonMessages.must_be_integer'
                        )}
                      />
                      <ValidCase
                        hide={!isSubmited}
                        valid={this.validateMaxWaitTime()}
                        message={I18n.t(
                          'messages.commonMessages.Integer_from_range'
                        ).format('0', '9999')}
                      />
                    </Validator>
                    <FormGroup>
                      <a
                        className="pl0 text-active cursor-pointer"
                        onClick={this.openModalZoneNoShow}
                      >
                        <Translate value="generalSetting.noShowCountDown.customizedNoShow" />
                      </a>
                    </FormGroup>
                  </FormGroup>
                </>
              ) : (
                ''
              )}
            </Col>
            <Col xs={12}>
              <CcCheckbox
                disabled={editable}
                checked={noShow.isLimitRadius || false}
                onChange={this.handleIsLimitRadiusChange}
                text={
                  <Fragment>
                    {I18n.t('generalSetting.Limit_radius')}
                    <QuestionCircleTooltip
                      text={
                        <Translate value="generalSetting.Limit_radius_tooltip" />
                      }
                    />
                  </Fragment>
                }
              />
              {noShow.isLimitRadius ? (
                <FormGroup
                  className={
                    !isSubmited
                      ? null
                      : valid.limitRadiusValue === false
                      ? 'error'
                      : null
                  }
                >
                  {/* <Form.Label>
                    <Translate value="generalSetting.DurationRegularBooking" />
                    <QuestionCircleTooltip
                      text={<Translate value="generalSetting.Limit_radius_tooltip" />}
                    />
                  </Form.Label> */}
                  <FormGroup className="qup-input-group">
                    <InputGroup
                      className={`single-addon-right ${
                        editable ? 'disabled' : ''
                      }`}
                    >
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleLimitRadiusValueChange}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(
                            e,
                            this.handleLimitRadiusValueChange
                          );
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(
                            e,
                            this.handleLimitRadiusValueChange
                          );
                        }}
                        value={
                          _.isNil(noShow.limitRadiusValue)
                            ? 0.5
                            : noShow.limitRadiusValue
                        }
                        placeholder={I18n.t('generalSetting.Limit_radius')}
                        disabled={editable}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>{unitDistance}</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </FormGroup>
                  <Validator id="limitRadiusValue" callback={ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={Validation.isNumber(noShow.limitRadiusValue)}
                      message={I18n.t('messages.commonMessages.must_be_number')}
                    />
                    <ValidCase
                      hide={!isSubmited}
                      valid={Validation.isGreaterThan(
                        noShow.limitRadiusValue,
                        0
                      )}
                      message={I18n.t('messages.commonMessages.greater_than_0')}
                    />
                    <ValidCase
                      hide={!isSubmited}
                      valid={this.validateLimitRadiusValue()}
                      message={I18n.t(
                        'messages.commonMessages.value_must_between'
                      ).format('0', '9999')}
                    />
                  </Validator>
                </FormGroup>
              ) : (
                ''
              )}
            </Col>
          </Row>
        ) : (
          ''
        )}
        {this.state.showModalZoneNoShow && (
          <ModalZoneNoShow
            show={this.state.showModalZoneNoShow}
            onCloseModal={this.handleCloseModalZoneNoShow}
            editable={editable}
          />
        )}
      </Fragment>
    );
  }
}

export default NoShowFormGroup;
