import {
  callApi,
  saveUserToLocal,
  loadUserProfile,
  removeUserProfile,
  saveUserVoip,
  UrlBuilder
} from "../utils/apiUtils";

import {
  userLoginApi,
  getFleetDetailApi,
  getAllFleetApi,
  userLogoutApi,
  changePasswordApi,
  resetPasswordApi,
  forgotPasswordApi,
  editProfileApi,
  checkResetPasswordTokenApi,
  findFleetApi,
  getAccCCVoipApi
} from "../constants/ApiConfigs";
import _ from "lodash"
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FLEET_REQUEST = "FLEET_REQUEST";
export const FLEET_SUCCESS = "FLEET_SUCCESS";
export const FLEET_FAILURE = "FLEET_FAILURE";

export const CHANGE_FLEET = "CHANGE_FLEET";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const FIND_FLEET_REQUEST = "FIND_FLEET_REQUEST";

export function checkResetPasswordToken(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(checkResetPasswordTokenApi, config, null, null, null, true);
}
function getAccCCVoip(token,fleetId) {
  fetch(getAccCCVoipApi+`?fleetId=${fleetId}`,{
    method: 'GET',
    headers: { authorization: `${token}` },
  })
    .then(response => response.json())
    .then(data => {
      saveUserVoip(data.res)
    });
  
}
function loginRequest(user) {
  return {
    type: LOGIN_REQUEST,
    user
  };
}

function loginSuccess(payload) {
  saveUserToLocal(payload.res);
  return {
    type: LOGIN_SUCCESS,
    user: payload.res.user,
    token: payload.token,
    fleet: []
  };
}

function loginFailure(error) {
  return {
    type: LOGIN_FAILURE,
    error
  };
}

export function login(username, password, remember) {
  const config = {
    method: "post",
    body: JSON.stringify({
      username,
      password,
      remember
    })
  };

  return callApi(
    userLoginApi,
    config,
    loginRequest(username),
    loginSuccess,
    loginFailure,
    true
  );
}

function logoutRequest(user) {
  return {
    type: LOGOUT_REQUEST,
    user
  };
}

function logoutSuccess(paylad) {
  removeUserProfile();
  return {
    type: LOGOUT_SUCCESS,
    user: null
  };
}

function logoutFailure(error) {
  return {
    type: LOGOUT_FAILURE,
    error
  };
}

export function logout(user) {
  const config = {
    method: "post"
  };
  return callApi(
    userLogoutApi,
    config,
    logoutRequest,
    logoutSuccess,
    logoutFailure
  );
}

function loadUserFleetRequest() {
  return {
    type: FLEET_REQUEST
  };
}

function loadUserFleetSuccess(data) {
  var userStr = localStorage.getItem('user');
  var user = JSON.parse(userStr);
  getAccCCVoip(user.token,data.res.fleetId)
  saveLogoToLocalStorage(data.res)
  return {
    type: FLEET_SUCCESS,
    data: data.res
  };
}

function loadUserFleetFailure(error) {
  return {
    type: FLEET_FAILURE,
    error
  };
}
export function loadUserFleet(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "get"
  };

  return callApi(
    UrlBuilder(getFleetDetailApi, options),
    config,
    loadUserFleetRequest(),
    loadUserFleetSuccess,
    loadUserFleetFailure,
    true
  );
}

export function changeFleet(fleet) {
  return {
    type: CHANGE_FLEET,
    data: fleet
  };
}

function changePasswordSuccess() {
  let user = loadUserProfile();
  user.user.defaultPw = false;
  saveUserToLocal(user);
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    data: user
  };
}

export function changePassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    changePasswordApi,
    config,
    null,
    changePasswordSuccess,
    null,
    true
  );
}

export function resetPassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(resetPasswordApi, config, null, null, null, true);
}

export function forgotPassword(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(forgotPasswordApi, config, null, null, null, true);
}

export function editProfile(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(
    editProfileApi,
    config,
    null,
    () => {
      editProfileSuccess(options);
      return {
        type: EDIT_PROFILE_SUCCESS
      };
    },
    null,
    true
  );
}

function findFleetRequest() {
  return {
    type: FIND_FLEET_REQUEST
  };
}

export const findFleet = (options) => {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    findFleetApi,
    config,
    findFleetRequest(),
    null,
    null,
    false
  );
};

function saveLogoToLocalStorage(data = {}) {
  let logoUrl =_.get(data, 'logo', '')
  if(data.logoCustomize && data.s3Host) logoUrl = data.s3Host + data.logoCustomize
  localStorage.setItem("logoUrl", JSON.stringify(logoUrl));
}

function editProfileSuccess(data) {
  let user = loadUserProfile();
  user.user.firstName = data.firstName;
  user.user.lastName = data.lastName;
  user.user.email = data.email;
  user.user.address = data.address;
  saveUserToLocal(user);
}
