import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalShowImage = ({show, title, url, handleShow}) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={handleShow}
      aria-labelledby="contained-modal-title-sm"
      dialogClassName="card-dialog"
      className="confirm flightInfoModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleShow}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="">
            <img style={{width: '100%'}} alt="logo" src={url}/>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShowImage;
