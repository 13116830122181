import React from 'react';
import {
  FormGroup,
  Button
} from 'react-bootstrap';
import moment from 'moment';
import { BeatLoader } from 'react-spinners';
import { I18n, Translate } from 'react-redux-i18n';
import DateRange from '../../components/DateRange';
import DateTime from '../../components/dateTime/DateTime';
import FilterTypeSelect from './components/FilterTypeSelect';
import TopSizeSelect from './components/TopSizeSelect';
import Confirm from '../../components/confirm/Confirm';
import ExportComponent from '../../components/ExportComponent';

export default class SmartDataFilter extends React.PureComponent {
  state = {
    selecteTime: 'today',
    topSize: 20,
    filterType: 'completed',
    startDate: moment()
      .startOf('day')
      .toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
    disableDatetimeSelection: false
  };

  componentDidMount() {
    this.onSubmit();
  }

  handleChangeDateRange = params => {
    let { disableDatetimeSelection } = this.state;
    disableDatetimeSelection = params.selectedButton !== 'custom';
    if (params.selectedButton !== 'custom') {
      this.setState({
        startDate: params.fromDate,
        endDate: params.toDate,
        selecteTime: params.selectedButton,
        disableDatetimeSelection
      });
    } else {
      this.setState({
        selecteTime: params.selectedButton,
        disableDatetimeSelection
      });
    }
  };

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.dateFromInput.focus();
    }
  };

  handleDateStartDateChange = e => {
    this.setState({ startDate: e._d });
    if (!this.state.endDate) {
      const today = new Date();
      this.setState({ endDate: today });
    }
  };

  handleDateEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  handleSelectTopSize = topSize => {
    this.setState({ topSize: parseFloat(topSize?? 0) });
  };

  handleSelectFilterType = filterType => {
    this.setState({ filterType });
  };

  isFilteredDayValid = current => {
    return !current.isAfter(DateTime.moment());
  };

  onExport = () => {
    this.setState({
      confirm: {
        id: 'CONFIRM_EXPORT_SMART_DATA',
        title: I18n.t('customer.Export_to_excel'),
        body: I18n.t('customer.CONFIRM_EXPORT_TO_EXCEL'),
        buttonTitle: 'Yes',
        closeButtonText: 'No'
      }
    });
  }

  onExportConfirm = () => {
    this.setState({
      confirm: null
    }, this.props.onExport);
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  }

  onSubmit = () => {
    const { topSize, filterType, startDate, endDate } = this.state;
    const params = {
      fromDate: startDate,
      toDate: endDate,
      filterType,
      topSize: Math.abs(topSize),
      filterSort: topSize > 0 ? 'desc' : 'asc'
    };
    this.props.onSubmit(params);
  }

  render() {
    const { language } = this.props;
    const {
      selecteTime,
      startDate,
      endDate,
      disableDatetimeSelection,
      topSize,
      filterType
    } = this.state;
    return (
      <div
        className="smartData__filter"
        ref={this.filterRef}
      >
        <div className="group-left">
          <div className="mb0">
            <DateRange
              selectedButton={selecteTime}
              setDateRange={this.handleChangeDateRange}
              customRangeSelectCallback={this.handleCustomDateSelect}
            />
          </div>
          <FormGroup className="mb0">
            <DateTime
              value={moment(startDate).locale(language)}
              timeFormat={false}
              onChange={this.handleDateStartDateChange}
              inputProps={{
                readOnly: true,
                className: 'date-readonly form-control',
                placeholder: I18n.t('customer.Registration_date_from'),
                value: startDate
                  ? moment(startDate)
                      .locale(language)
                      .format('L')
                  : '',
                disabled: disableDatetimeSelection
              }}
              isValidDate={this.isFilteredDayValid}
              closeOnSelect
            />
          </FormGroup>
          <FormGroup className="mb0">
            <DateTime
              value={moment(endDate).locale(language)}
              timeFormat={false}
              onChange={this.handleDateEndDateChange}
              inputProps={{
                readOnly: true,
                className: 'date-readonly form-control',
                placeholder: I18n.t('customer.Registration_date_to'),
                value: endDate
                  ? moment(endDate)
                      .locale(language)
                      .format('L')
                  : '',
                disabled: disableDatetimeSelection
              }}
              isValidDate={this.isFilteredDayValid}
              closeOnSelect
            />
          </FormGroup>
        </div>
        <div className="group-right">
          <TopSizeSelect
            selectedItem={topSize}
            onSelect={this.handleSelectTopSize}
          />
          <FilterTypeSelect
            selectedItem={filterType}
            onSelect={this.handleSelectFilterType}
          />
          <Button className="btn-save view-button" onClick={this.onSubmit}>View</Button>
          {this.props.exportPermit && (
            <ExportComponent 
              prepareParamExport={this.props.prepareParamExport}
              urlExport={this.props.urlExport}
              user={this.props.auth.user}
            />
          )}
        </div>

        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.onExportConfirm}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}
