import React from 'react';
import Moment from 'react-moment';
import { I18n } from 'react-redux-i18n';
import { getBookingType } from '../../../utils/commonFunctions';
import { BOOK_TYPE } from '../../../constants/commondata';
const PickupDropOffTime = ({
  booking = {},
  lineShort,
  auth,
  isMobile
}) => {
  const timeFormat = auth?.selectedFleet?.format24Hour ? 'HH:mm' : 'hh:mm A',
    pickUpTime = booking?.time?.pickUpTime,
    timezone = booking?.request?.pickup?.timezone
  const pickupTimeText = (
    <Moment
      tz={timezone}
      format={`${timeFormat}, ddd MMM DD, YYYY`}
      date={pickUpTime}
      locale={booking?.language}
    />
  )
  if(isMobile) {
    const isBookNow = getBookingType(booking) === BOOK_TYPE.now
    return (
      <div className='pk-time'>
        <span>{I18n.t('cue.pickupTime')}</span>
        <span
          style={{
            color: isBookNow ? '#FDB022' : 'white',
            fontWeight: 500
          }}
        >{isBookNow ? 'ASAP' : pickupTimeText}</span>
      </div>
    )
  }
  return (
    <div className={'time-fleet'}>
      <p>
        <Moment
          tz={timezone}
          format={`MMM DD, YYYY ${timeFormat}`}
          date={pickUpTime}
          locale={booking.language}
        />
      </p>
      {booking?.time?.droppedOff && !lineShort ? (
        <p>
          <Moment
            tz={booking?.request?.destination?.timezone}
            format={`MMM DD, YYYY ${timeFormat}`}
            date={booking?.time?.droppedOff}
            locale={booking?.language}
          />
        </p>
      ) : (
        ''
      )}
    </div>
  )
};

export default PickupDropOffTime;
