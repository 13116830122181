import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Col, Row, Button, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { 
  FEE_TYPE,
  additionalServiceType,
  serviceTypes, 
 } from '../../../../constants/commondata';
class FleetService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { editable, 
      enableFleetService, 
      formData, 
      onFleetServiceChange, 
      fleetServiceRemoveItemClick, 
      fleetServiceEditItemClick, 
      fleetServiceAddItemClick,
      permissions
     } = this.props;
    return (
      <>
        {
          enableFleetService &&
          // <div className="group-general">
          <div>
            <CcCheckbox
              disabled={editable}
              checked={
                formData &&
                  formData.fleetServiceActive
                  ? formData.fleetServiceActive
                  : false
              }
              onChange={onFleetServiceChange}
              text={'Fleet service fees'}
              labelClassName={'title'}
              className="form-group-title mr-t-10 no-marginBottom"
            />
            {formData && formData.fleetServiceActive ? (
              <Row className="sub-controls">
                <Col md={12}>
                  <div className="additional-service-table-container" style={{height: 'max-content', maxHeight: '400px'}}>
                    <table className="table table-no-pagination">
                      <tbody>
                        {formData.fleetServices &&
                          formData.fleetServices.length > 0
                          ? formData.fleetServices.map(
                            (service, index) => {
                              return (
                                <tr key={service.serviceId}>
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      delayShow={300}
                                      overlay={
                                        <Tooltip id="sevice-name-tooltip">
                                          <span>
                                            {service.serviceType ==
                                              additionalServiceType.Compulsory
                                              ? service.serviceName + ' *'
                                              : service.serviceName}
                                          </span>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="text-ellipsis">
                                        {service.serviceType ==
                                          additionalServiceType.Compulsory
                                          ? service.serviceName + ' *'
                                          : service.serviceName}
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{service.serviceType}</td>
                                  <td>
                                    {service.serviceFeeType === FEE_TYPE.Percent
                                      ? `${service.serviceFeePercent}%`
                                      : service &&
                                        service.serviceFeeByCurrencies &&
                                        service.serviceFeeByCurrencies
                                          .map((d) => {
                                            return (
                                              d.currencyISO + ' ' + d.value
                                            );
                                          })
                                          .join(', ')}
                                  </td>
                                  {/* <td>
                                    {service && service.vehicleType
                                      ? service.vehicleType
                                        .map(d => {
                                          return d.vehicleType;
                                        })
                                        .join(', ')
                                      : ''}
                                  </td> */}

                                  <td className="actions">
                                    {!permissions ||
                                      permissions.actions ? (
                                      <div>
                                        <a
                                          onClick={() => {
                                            fleetServiceRemoveItemClick(
                                              service,
                                              index
                                            );
                                          }}
                                        >
                                          <i className="fa fa-trash fa-2x" />
                                        </a>
                                        <a
                                          onClick={() => {
                                            fleetServiceEditItemClick(
                                              service,
                                              index
                                            );
                                          }}
                                        >
                                          <i className="fa fa-edit fa-2x" />
                                        </a>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : null}
                      </tbody>
                    </table>
                  </div>
                  {(!permissions || permissions.actions)
                    && formData.fleetServices.length <= 100 ? (
                    <ButtonToolbar className="text-center mv-md">
                      <Button className="btn-save" onClick={fleetServiceAddItemClick}>
                        <Translate value="generalSetting.Add" />
                      </Button>
                    </ButtonToolbar>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              ) : (
                ''
              )}
          </div>
        }
      </>
    );
  }
}

export default FleetService
