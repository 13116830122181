import React from 'react';
import ReportUtils from '../base/utils';
import _ from 'lodash';

export const dispatchHistoryFields = [
  { key: 'performer', label: 'report.result.dispatchHistory.performer' },
  { key: 'name', label: 'report.result.dispatchHistory.name' },
  { key: 'action', label: 'report.result.dispatchHistory.action' },
  {
    key: 'date',
    label: 'report.result.prepaidTopUp.date',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MM/DD/YYYY hh:mm:ss A',
          })
        : 'N/A',
  },
  { key: 'location', label: 'report.result.dispatchHistory.location' },
  { key: 'estimate', label: 'report.result.dispatchHistory.estimate' },
];

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.bookingLogs.bookingId',
    summary: 'size',
    fixed: true,
  },
  {
    key: 'externalId',
    label: 'report.result.thirdPartyBooking.externalId',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) =>
      !_.get(selectedFleet, 'moduleSettings.thirdPartyBooking', false),
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    key: 'bookingService',
    label: 'report.result.bookingLogs.bookingService',
    summary: 'bookingService',
  },
  {
    key: 'driverName',
    label: 'report.result.bookingLogs.driverName',
    summary: 'driverName',
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.bookingLogs.driverIdPermit',
    summary: 'driverIdPermit',
  },
  {
    key: 'customerName',
    label: 'report.result.bookingLogs.customerName',
    summary: 'customerName',
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'pickupTime',
    label: 'report.result.bookingLogs.pickupTime',
    summary: 'pickupTime',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MM/DD/YYYY hh:mm:ss A',
          })
        : 'N/A',
  },
  {
    key: 'onBoardTime',
    label: 'report.result.bookingLogs.onBoardTime',
    summary: 'onBoardTime',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MM/DD/YYYY hh:mm:ss A',
          })
        : 'N/A',
  },
  {
    key: 'droppedOffTime',
    label: 'report.result.bookingLogs.droppedOffTime',
    summary: 'droppedOffTime',
    mutate: (value, doc) =>
      value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MM/DD/YYYY hh:mm:ss A',
          })
        : 'N/A',
  },
  {
    key: 'pickupLocation',
    label: 'report.result.bookingLogs.pickupLocation',
    manualTooltip: (value, doc, options) => {
      if (Array.isArray(doc.puPoints) && doc.puPoints.length > 1) {
        return <>{<p>{doc.puPoints.join('\n\n')}</p>}</>;
      }
      return null;
    },
    summary: 'pickupLocation',
  },
  {
    key: 'destination',
    label: 'report.result.bookingLogs.destination',
    manualTooltip: (value, doc, options) => {
      if (Array.isArray(doc.doPoints) && doc.doPoints.length > 1) {
        return <>{<p>{doc.doPoints.join('\n\n')}</p>}</>;
      }
      return null;
    },
    summary: 'destination',
  },
  {
    label: 'report.result.bookingLogs.dispatchHistory',
    key: 'bookId',
    link: {
      label: 'report.result.bookingLogs.showDispatchHistory',
      name: 'showDispatchHistory',
      params: ['bookingId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Show dispatching history of this booking',
    },
  },
  {
    label: 'report.result.bookingLogs.paymentActivities',
    key: 'paymentLogs',
    width: 180,
    link: {
      label: 'report.result.bookingLogs.Show',
      name: 'showPaymentLogs',
      params: ['bookingId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Show payment history of this booking',
    },
  },
  {
    key: 'onTheWayDistance',
    label: 'report.result.bookingLogs.otwDistance',
    headerUnit: 'unitDistance',
  },
  {
    key: 'distanceTour',
    label: 'report.result.bookingLogs.distanceTour',
    headerUnit: 'unitDistance',
  },
  {
    key: 'onBoardDuration',
    label: 'report.result.bookingLogs.duration',
    summary: 'duration',
  },
  {
    key: 'paidBy',
    label: 'report.result.bookingLogs.paidBy',
    summary: 'paidBy',
    mutate: (value) => {
     if(value === 'mDispatcher') return 'partner'  
     if(value === 'BankOfGeorgia') return 'Bank Of Georgia'  
     return value
    }
  },
  {
    key: 'bookFrom',
    label: 'report.result.bookingLogs.bookFrom',
    summary: 'bookFrom',
    mutate: (value, doc) => {
      if (value) {
        switch (value) {
          case 'mDispatcher':
            value = 'Partner';
            break;
          case 'streetSharing':
            value = 'Street sharing';
            break;
          default:
            break;
        }
      }
      return value;
    },
  },
];

export const floatFields = ['distanceTour', 'onTheWayDistance'];
