import {NEW_CALL_SUPPORT,
    CALL_SUPPORT_ITEM_REMOVE} from '../actions/callCenterSupportActions'

const initState = {
    inCommingQueue:[]
}
export default function callcenterSupport(state=initState ,action={}){
    switch(action.type){
        case NEW_CALL_SUPPORT:{
            if(!state.inCommingQueue.find(cs=>{return action.data.phone==cs.phone})){
                state.inCommingQueue.unshift(action.data); 
                return {...state};           
            }
            return state;
        }
        case CALL_SUPPORT_ITEM_REMOVE:{
            return {...state,inCommingQueue: state.inCommingQueue.filter(item=>{return item.uuid!==action.data.uuid})};
        }
        default:{
            return state;
        }
    }
}