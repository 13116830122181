import React from 'react';
import styles from './styles/create.module.scss';
import FormDriver from './components/formDriver';
import HeaderGr from './components/headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';


const CreateDriver = (props) => {
  const Breadcrumbs = [
    {
      path: '/driver',
      name: I18n.t('driver.Driver'),
    },
    {
      path: '/driver/add',
      name: I18n.t('driver.addDriver'),
    },
  ];
  
  return (
    <div className={styles['layout']}>
      <HeaderGr 
        breadcrumbs={Breadcrumbs} 
        title={I18n.t('driver.addDriver')} 
        navigate={props.router}
      />
      <FormDriver
        action="add"
        navigate={props.router}
        params={{ driverId: null }}
      />
    </div>
  );
};

export default connect()(CreateDriver);
