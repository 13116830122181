import React, { Component } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import pickupIcon from './../../../assets/images/icons/pickup.svg';
import distinationIcon from './../../../assets/images/icons/destination.svg';
import des1_Icon from './../../../assets/images/icons/destination1.svg';
import des2_Icon from './../../../assets/images/icons/des2.svg';
import { locationType } from './../../../constants/commondata';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';

class IntercityBookingGGMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      markers: []
    };
  }

  componentDidUpdate(prevProps) {
    const locationChanged =
      prevProps.pickupLocation !== this.props.pickupLocation ||
      prevProps.destinationLocation !== this.props.destinationLocation ||
      prevProps.extraDestinationLocation !== this.props.extraDestinationLocation;

    if (locationChanged) {
      this.renderMarkers();
    }
  }

  componentWillUnmount() {
    this.state.markers.forEach(marker => marker.map = null);
  }

  renderMarkers = () => {
    const { map } = this.state;
    if (!map) return;

    // Clear existing markers
    this.state.markers.forEach(marker => marker.map = null);
    const newMarkers = [];

    const {
      pickupLocation,
      handlePickupDrapEnd,
      pickupFrom,
      destinationLocation,
      handleDestinationDrapEnd,
      destinationFrom,
      extraDestinationLocation,
      handleExtraDestinationDrapEnd,
      extraDestinationFrom
    } = this.props;

    // Add pickup marker
    if (pickupLocation) {
      const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
        {
          lat: pickupLocation.lat,
          lng: pickupLocation.lng
        },
        pickupIcon, '', 100, 32, 32, 16, 5,
        pickupFrom !== locationType.thirdParty,
        pickupFrom !== locationType.thirdParty
      );

      if (pickupFrom !== locationType.thirdParty) {
        marker.addListener('dragend', handlePickupDrapEnd);
      }
      marker.map = map;
      newMarkers.push(marker);
    }

    // Add destination markers
    if (destinationLocation) {
      const icon = extraDestinationLocation ? des1_Icon : distinationIcon;

      const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
        {
          lat: destinationLocation.lat,
          lng: destinationLocation.lng
        },
        icon, '', 100, 32, 32, 16, 5,
        destinationFrom !== locationType.thirdParty,
        destinationFrom !== locationType.thirdParty
      );

      if (destinationFrom !== locationType.thirdParty) {
        marker.addListener('dragend', handleDestinationDrapEnd);
      }
      marker.map = map;
      newMarkers.push(marker);
    }

    // Add extra destination marker
    if (extraDestinationLocation) {
      const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
        {
          lat: extraDestinationLocation.lat,
          lng: extraDestinationLocation.lng
        },
        des2_Icon, '', 100, 32, 32, 16, 5,
        extraDestinationFrom !== locationType.thirdParty,
        extraDestinationFrom !== locationType.thirdParty
      );

      if (extraDestinationFrom !== locationType.thirdParty) {
        marker.addListener('dragend', handleExtraDestinationDrapEnd);
      }
      marker.map = map;
      newMarkers.push(marker);
    }
    this.setState({ markers: newMarkers });
  };

  onMapLoad = (mapInstance) => {
    this.setState({ map: mapInstance }, () => {
      this.renderMarkers();
      if (this.props.onMapLoad) {
        this.props.onMapLoad(mapInstance);
      }
    });
  };

  render() {
    const { center } = this.props;

    return (
      <div className="fill booking-map">
        <GoogleMap
          onLoad={this.onMapLoad}
          center={center}
          zoom={14}
          options={{
            gestureHandling: 'greedy',
            mapId: process.env.REACT_APP_MAP_ID
          }}
          mapContainerStyle={{ height: '100%' }}
        >
        </GoogleMap>
      </div>
    );
  }
}

export default IntercityBookingGGMap;
