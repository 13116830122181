import React, { useEffect, useState } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    Form,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import _, { truncate } from 'lodash';
import moment from "moment";

import { Validator, ValidCase } from '../../../components/validator';
import { europeCountry, relationships } from '../../../constants/commondata';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import UploadFile from '../../../components/upload/upload';
import RadioButton from '../../../components/radioButton/radio';
import DateTime from '../../../components/dateTime/DateTime';

import {
    Validation,
} from '../../../utils/commonFunctions';

import InputField from './InputField';
import { BsQuestionCircleFill } from 'react-icons/bs';

const initFields = {
    accountHolder: { show: true, require: false },
    accountNumber: { show: true, require: false },
};


const isBirthDayValid = (current) => {
    return !current.isAfter(DateTime.moment());
};

const renderCustomTitleBankName = (dialogData, bankRequirement) => {
    const bankInfo = bankRequirement.bankInfo;
    if (!dialogData.bankCode) {
        return (
            <span className="dropdown-title">
                {I18n.t('driver.Select_a_bank')}
            </span>
        );
    }
    const foundBank = _.find(bankInfo, b => b.bankCode === dialogData.bankCode);
    if (typeof foundBank === 'undefined') {
        return null;
    }
    return (
        <span className="dropdown-title">
            {foundBank.bankName}
        </span>
    )
};

function BankAccount(props) {
    const {
        dialogData,
        bankRequirement,
        selectedFleet,
        language,
        editable,
        isSubmitted,
        valid,
        ValidatorCallback,
        handleInputChange,
        handleClickUploadImage,
        handleRemoveBankDocumentChange,
        showRemoveBankInfo,
        handleRemoveBankInfo,
    } = props;

    const [fields, setFields] = useState(initFields);
    const [verificationDocumentFrontFileName, setVerificationDocumentFrontFileName] = useState("");
    const [verificationDocumentBackFileName, setVerificationDocumentBackFileName] = useState("");
    const [additionalDocumentFrontFileName, setAdditionalDocumentFrontFileName] = useState("");
    const [additionalDocumentBackFileName, setAdditionalDocumentBackFileName] = useState("");

    useEffect(() => {
        let fields = Object.assign(initFields);
        if (bankRequirement) {
            fields.accountHolder = { show: !bankRequirement.hideAccountHolder, require: bankRequirement.accountHolder };
            fields.accountNumber = { show: !bankRequirement.hideAccountNumber, require: bankRequirement.accountNumber };
            fields.routingNumber = { show: !bankRequirement.hideRoutingNumber, require: bankRequirement.routingNumber };

            fields.bankName = { show: !bankRequirement.hideBankName, require: bankRequirement.bankName };
            if (bankRequirement.hideBankName && bankRequirement.bankInfo && bankRequirement.bankInfo.length > 0) {
                fields.bankCode = { show: true, require: bankRequirement.bankCode };
            };

            fields.ssn = { show: !bankRequirement.hideSsn, require: bankRequirement.ssn };

            fields.address = { show: !bankRequirement.hideAddress, require: bankRequirement.address };
            fields.city = { show: !bankRequirement.hideCity, require: bankRequirement.city };
            fields.state = { show: !bankRequirement.hideState, require: bankRequirement.state };
            fields.postalCode = { show: !bankRequirement.hidePostalCode, require: bankRequirement.postalCode };

            fields.birthDay = { show: !bankRequirement.hideBirthDay, require: bankRequirement.birthDay };
            fields.ibanNumber = { show: !bankRequirement.hideIbanNumber, require: bankRequirement.ibanNumber };
            fields.sortCode = { show: !bankRequirement.hideSortCode, require: bankRequirement.sortCode };
            fields.institutionNumber = { show: !bankRequirement.hideInstitutionNumber, require: bankRequirement.institutionNumber };
            fields.transitNumber = { show: !bankRequirement.hideTransitNumber, require: bankRequirement.transitNumber };
            fields.IDtype = { show: !bankRequirement.hideIDtype, require: bankRequirement.IDtype };
            fields.IDnumber = { show: !bankRequirement.hideIDnumber, require: bankRequirement.IDnumber };

            fields.verificationDocument = { show: !bankRequirement.hideVerificationDocument, require: bankRequirement.verificationDocument };
            fields.additionalDocument = { show: !bankRequirement.hideAdditionalDocument, require: bankRequirement.additionalDocument };

            fields.relationship = { show: bankRequirement.showRelationship, require: true };
            fields.verifiedStatus = { show: bankRequirement.showVerifiedStatus, require: true };

            setFields(fields);
        }
    }, [bankRequirement])

    useEffect(() => {
        // verificationDocument
        if (
            dialogData.id &&
            dialogData.verificationDocumentFront &&
            dialogData.verificationDocumentFront.length > 0
        ) {
            let splitUrlVerificationDocumentFront = dialogData.verificationDocumentFront.split('/');
            setVerificationDocumentFrontFileName(splitUrlVerificationDocumentFront[splitUrlVerificationDocumentFront.length - 1]);
        } else {
            setVerificationDocumentFrontFileName("");
        };
        if (
            dialogData.id &&
            dialogData.verificationDocumentBack &&
            dialogData.verificationDocumentBack.length > 0
        ) {
            let splitUrlVerificationDocumentBack = dialogData.verificationDocumentBack.split('/');
            setVerificationDocumentBackFileName(splitUrlVerificationDocumentBack[splitUrlVerificationDocumentBack.length - 1]);
        } else {
            setVerificationDocumentBackFileName("");
        };
        // additionalDocument
        if (
            dialogData.id &&
            dialogData.additionalDocumentFront &&
            dialogData.additionalDocumentFront.length > 0
        ) {
            let splitUrlAdditionalDocumentFront = dialogData.additionalDocumentFront.split('/');
            setAdditionalDocumentFrontFileName(splitUrlAdditionalDocumentFront[splitUrlAdditionalDocumentFront.length - 1]);
        } else {
            setAdditionalDocumentFrontFileName("");
        };
        if (
            dialogData.id &&
            dialogData.additionalDocumentBack &&
            dialogData.additionalDocumentBack.length > 0
        ) {
            let splitUrlAdditionalDocumentBack = dialogData.additionalDocumentBack.split('/');
            setAdditionalDocumentBackFileName(splitUrlAdditionalDocumentBack[splitUrlAdditionalDocumentBack.length - 1]);
        } else {
            setAdditionalDocumentBackFileName("");
        };
    }, [props]);

    return (
        <div className="form-group-info">
            {showRemoveBankInfo && (
                <FormGroup>
                    <a onClick={handleRemoveBankInfo} href="#">
                        <Translate className="cursor-pointer red" value="newbooking.Remove" />
                    </a>
                </FormGroup>
            )}
            {fields.accountHolder && fields.accountHolder.show &&
                <InputField
                    itemKey={"accountHolder"}
                    require={fields.accountHolder.require || false}
                    valueData={dialogData.accountHolder || ""}
                    placeholderCustom={"First_M_Last"}
                    editable={editable}
                    validField={valid.accountHolder}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.accountNumber && fields.accountNumber.show &&
                <InputField
                    itemKey={"accountNumber"}
                    require={fields.accountNumber.require || false}
                    valueData={dialogData.accountNumber || ""}
                    editable={editable}
                    validField={valid.accountNumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.ibanNumber && fields.ibanNumber.show &&
                <InputField
                    itemKey={"ibanNumber"}
                    require={fields.ibanNumber.require || false}
                    valueData={dialogData.ibanNumber || ""}
                    editable={editable}
                    validField={valid.ibanNumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.bankName && fields.bankName.show &&
                <InputField
                    itemKey={"bankName"}
                    require={fields.bankName.require || false}
                    valueData={dialogData.bankName || ""}
                    editable={editable}
                    validField={valid.bankName}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.bankCode && fields.bankCode.show &&
                <FormGroup
                    className={
                        !isSubmitted
                            ? null
                            : valid.bankCode === false && fields.bankCode.require
                                ? 'error'
                                : null
                    }
                >
                    <Form.Label>
                        <Translate value="merchant.user.bankName" />
                        {fields.bankCode.require && <span className="require"> *</span>}
                    </Form.Label>
                    <CCDropDown
                        id="RTBnumber-dropdown"
                        items={bankRequirement.bankInfo}
                        title=""
                        valueKey="bankCode"
                        labelKey="bankName"
                        className="driver-select-licence-dropdown driverSelectBankName form-custom"
                        onSelect={item => handleInputChange(item, "bankCode")}
                        selectedItems={
                            dialogData.bankCode
                                ? [dialogData.bankCode]
                                : []
                        }
                        customTitle={renderCustomTitleBankName(dialogData, bankRequirement)}
                        disabled={!editable}
                        searchable
                        noTranslateLabel
                        enableClearAll
                    />
                    {fields.bankCode.require &&
                        <Validator id="bankCode" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmitted}
                                valid={!Validation.isStringEmpty(dialogData.bankCode) && dialogData.bankCode !== "0"}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    }
                </FormGroup>
            }

            {fields.routingNumber && fields.routingNumber.show &&
                <InputField
                    itemKey={"routingNumber"}
                    require={fields.routingNumber.require || false}
                    valueData={dialogData.routingNumber || ""}
                    editable={editable}
                    validField={valid.routingNumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.ssn && fields.ssn.show &&
                <InputField
                    itemKey={"ssn"}
                    require={fields.ssn.require || false}
                    valueData={dialogData.ssn || ""}
                    editable={editable}
                    validField={valid.ssn}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.sortCode && fields.sortCode.show &&
                <InputField
                    itemKey={"sortCode"}
                    require={fields.sortCode.require || false}
                    valueData={dialogData.sortCode || ""}
                    editable={editable}
                    validField={valid.sortCode}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.transitNumber && fields.transitNumber.show &&
                <InputField
                    itemKey={"transitNumber"}
                    require={fields.transitNumber.require || false}
                    valueData={dialogData.transitNumber || ""}
                    editable={editable}
                    validField={valid.transitNumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.IDtype && fields.IDtype.show &&
                <FormGroup
                    className={
                        !isSubmitted
                            ? null
                            : !valid.IDtype && fields.IDtype.require
                                ? 'error'
                                : null
                    }
                >
                    <Form.Label>
                        <Translate value="merchant.user.IDtype" />
                        {fields.IDtype.require && <span className="require"> *</span>}
                    </Form.Label>
                    <FormControl
                        as="select"
                        className="card-type form-custom"
                        value={dialogData.IDtype || ""}
                        onChange={e => handleInputChange(e, "IDtype")}
                        disabled={!editable}
                    >
                        <option key={-1} value={""}>
                            {I18n.t('customer.Select_Idtype')}
                        </option>
                        {bankRequirement.listIDType && bankRequirement.listIDType.options && bankRequirement.listIDType.options.map(item => {
                            return (
                                <option key={item.key} value={item.key}>
                                    {I18n.t(`merchant.user.${item.language[language]}`)}
                                </option>
                            )
                        })}
                    </FormControl>
                    {fields.IDtype.require &&
                        <Validator id={"IDtype"} callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmitted}
                                valid={!Validation.isStringEmpty(dialogData.IDtype)}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    }
                </FormGroup>
            }

            {fields.IDnumber && fields.IDnumber.show &&
                <InputField
                    itemKey={"IDnumber"}
                    require={fields.IDnumber.require || false}
                    valueData={dialogData.IDnumber || ""}
                    editable={editable}
                    validField={valid.IDnumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.institutionNumber && fields.institutionNumber.show &&
                <InputField
                    itemKey={"institutionNumber"}
                    require={fields.institutionNumber.require || false}
                    valueData={dialogData.institutionNumber || ""}
                    editable={editable}
                    validField={valid.institutionNumber}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.birthDay && fields.birthDay.show &&
                <FormGroup
                    className={
                        !isSubmitted
                            ? null
                            : !valid.birthDay && fields.birthDay.require
                                ? 'error'
                                : null
                    }
                >
                    <Form.Label>
                        <Translate value="merchant.user.birthDay" />
                        {fields.birthDay.require && <span className="require"> *</span>}
                    </Form.Label>
                    <DateTime
                        isValidDate={isBirthDayValid}
                        inputProps={{
                            readOnly: true,
                            disabled: !editable,
                            className: editable
                                ? 'form-control date'
                                : 'date-readonly form-control date'
                        }}
                        value={dialogData.birthDay ? moment(dialogData.birthDay) : ""}
                        timeFormat={false}
                        onChange={e => handleInputChange(e, "birthDay")}
                        placeholder={"dd/mm/yyyy"}
                        closeOnSelect
                    />
                    {fields.birthDay.require &&
                        <Validator id={"birthDay"} callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmitted}
                                valid={!Validation.isStringEmpty(dialogData.birthDay)}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    }
                </FormGroup>
            }

            {fields.verificationDocument && fields.verificationDocument.show &&
                <>
                    <FormGroup
                        className={
                            !isSubmitted
                                ? null
                                : valid.verificationDocumentFront === false && fields.verificationDocument.require
                                    ? 'error'
                                    : null
                        }
                    >
                        <Form.Label>
                            <Translate value="merchant.user.verificationDocumentFront" />
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={(
                                    <Tooltip id="tooltip">
                                        <Translate value="merchant.user.Verification_document_tooltip" />
                                    </Tooltip>
                                )}
                            >
                                <BsQuestionCircleFill />
                            </OverlayTrigger>
                            {fields.verificationDocument.require && <span className="require"> *</span>}
                        </Form.Label>
                        <br />
                        {dialogData.id && dialogData.verificationDocumentFront ? (
                            <a
                                href={dialogData.verificationDocumentFront}
                                target="_blank"
                            >
                                <Translate value="driver.Click_here_to_download" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="verificationDocumentFront"
                            name="verificationDocumentFront"
                            disabled={!editable}
                            onChange={(data, error) => handleClickUploadImage(data, error, 'verificationDocumentFront')}
                            existFile={
                                verificationDocumentFrontFileName
                                    ? {
                                        fileName: verificationDocumentFrontFileName,
                                        onRemove: () =>
                                            handleRemoveBankDocumentChange("verificationDocumentFront")
                                    }
                                    : null
                            }
                        />
                        {fields.verificationDocument.require &&
                            <Validator id="verificationDocumentFront" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={!!(dialogData.verificationDocumentFront)}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        }
                    </FormGroup>
                    <FormGroup
                        className={
                            !isSubmitted
                                ? null
                                : valid.verificationDocumentBack === false && fields.verificationDocument.require
                                    ? 'error'
                                    : null
                        }
                    >
                        <Form.Label>
                            <Translate value="merchant.user.verificationDocumentBack" />
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={(
                                    <Tooltip id="tooltip">
                                        <Translate value="merchant.user.Verification_document_tooltip" />
                                    </Tooltip>
                                )}
                            >
                                <BsQuestionCircleFill />
                            </OverlayTrigger>
                            {fields.verificationDocument.require && <span className="require"> *</span>}
                        </Form.Label>
                        <br />
                        {dialogData.id && dialogData.verificationDocumentBack ? (
                            <a
                                href={dialogData.verificationDocumentBack}
                                target="_blank"
                            >
                                <Translate value="driver.Click_here_to_download" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="verificationDocumentBack"
                            name="verificationDocumentBack"
                            disabled={!editable}
                            onChange={(data, error) => handleClickUploadImage(data, error, 'verificationDocumentBack')}
                            existFile={
                                verificationDocumentBackFileName
                                    ? {
                                        fileName: verificationDocumentBackFileName,
                                        onRemove: () =>
                                            handleRemoveBankDocumentChange("verificationDocumentBack")
                                    }
                                    : null
                            }
                        />
                        {fields.verificationDocument.require &&
                            <Validator id="verificationDocumentBack" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={!!(dialogData.verificationDocumentBack)}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        }
                    </FormGroup>
                </>
            }

            {fields.additionalDocument && fields.additionalDocument.show &&
                <>
                    <FormGroup
                        className={
                            !isSubmitted
                                ? null
                                : valid.additionalDocumentFront === false && fields.additionalDocument.require
                                    ? 'error'
                                    : null
                        }
                    >
                        <Form.Label>
                            <Translate value="merchant.user.additionalDocumentFront" />
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={(
                                    <Tooltip id="tooltip">
                                        <Translate value="merchant.user.Additional_document_tooltip" />
                                    </Tooltip>
                                )}
                            >
                                <BsQuestionCircleFill />
                            </OverlayTrigger>
                            {fields.additionalDocument.require && <span className="require"> *</span>}
                        </Form.Label>
                        <br />
                        {dialogData.id && dialogData.additionalDocumentFront ? (
                            <a
                                href={dialogData.additionalDocumentFront}
                                target="_blank"
                            >
                                <Translate value="driver.Click_here_to_download" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="additionalDocumentFront"
                            name="additionalDocumentFront"
                            disabled={!editable}
                            onChange={(data, error) => handleClickUploadImage(data, error, 'additionalDocumentFront')}
                            existFile={
                                additionalDocumentFrontFileName
                                    ? {
                                        fileName: additionalDocumentFrontFileName,
                                        onRemove: () =>
                                            handleRemoveBankDocumentChange("additionalDocumentFront")
                                    }
                                    : null
                            }
                        />
                        {fields.additionalDocument.require &&
                            <Validator id="additionalDocumentFront" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={!!(dialogData.additionalDocumentFront)}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        }
                    </FormGroup>
                    <FormGroup
                        className={
                            !isSubmitted
                                ? null
                                : valid.additionalDocumentBack === false && fields.additionalDocument.require
                                    ? 'error'
                                    : null
                        }
                    >
                        <Form.Label>
                            <Translate value="merchant.user.additionalDocumentBack" />
                            &nbsp;
                            <OverlayTrigger
                                placement="top"
                                overlay={(
                                    <Tooltip id="tooltip">
                                        <Translate value="merchant.user.Additional_document_tooltip" />
                                    </Tooltip>
                                )}
                            >
                                <BsQuestionCircleFill />
                            </OverlayTrigger>
                            {fields.additionalDocument.require && <span className="require"> *</span>}
                        </Form.Label>
                        <br />
                        {dialogData.id && dialogData.additionalDocumentBack ? (
                            <a
                                href={dialogData.additionalDocumentBack}
                                target="_blank"
                            >
                                <Translate value="driver.Click_here_to_download" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="additionalDocumentBack"
                            name="additionalDocumentBack"
                            disabled={!editable}
                            onChange={(data, error) => handleClickUploadImage(data, error, 'additionalDocumentBack')}
                            existFile={
                                additionalDocumentBackFileName
                                    ? {
                                        fileName: additionalDocumentBackFileName,
                                        onRemove: () =>
                                            handleRemoveBankDocumentChange("additionalDocumentBack")
                                    }
                                    : null
                            }
                        />
                        {fields.additionalDocument.require &&
                            <Validator id="additionalDocumentBack" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmitted}
                                    valid={!!(dialogData.additionalDocumentBack)}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        }
                    </FormGroup>
                </>
            }

            {fields.address && fields.address.show &&
                <InputField
                    itemKey={"address"}
                    require={fields.address.require || false}
                    valueData={dialogData.address || ""}
                    editable={editable}
                    validField={valid.address}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.city && fields.city.show &&
                <InputField
                    itemKey={"city"}
                    require={fields.city.require || false}
                    valueData={dialogData.city || ""}
                    editable={editable}
                    validField={valid.city}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.state && fields.state.show &&
                <InputField
                    itemKey={"state"}
                    require={fields.state.require || false}
                    valueData={dialogData.state || ""}
                    editable={editable}
                    validField={valid.state}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.postalCode && fields.postalCode.show &&
                <InputField
                    itemKey={"postalCode"}
                    require={fields.postalCode.require || false}
                    valueData={dialogData.postalCode || ""}
                    editable={editable}
                    validField={valid.postalCode}
                    isSubmitted={isSubmitted}
                    ValidatorCallback={ValidatorCallback}
                    handleInputChange={handleInputChange}
                />
            }

            {fields.relationship && fields.relationship.show &&
                <>
                    <FormGroup>
                        <Row style={{
                            width: '100%'
                        }}>
                            <Col xs={12} className="mb-sm">
                                <Form.Label>
                                    <Translate value="driver.isBankAccountOwner" />
                                </Form.Label>
                            </Col>
                            <Col lg={3} md={6} sm={6} xs={12}>
                                <RadioButton
                                    text={I18n.t('driver.yes')}
                                    inline
                                    name="isCustomized"
                                    value="true"
                                    checked={dialogData.isBankAccountOwner}
                                    onChange={e => handleInputChange(e, "isBankAccountOwner")}
                                    disabled={!editable}
                                />
                            </Col>
                            <Col lg={3} md={6} sm={6} xs={12}>
                                <RadioButton
                                    text={I18n.t('driver.no')}
                                    inline
                                    name="isCustomized"
                                    value="false"
                                    checked={!dialogData.isBankAccountOwner}
                                    onChange={e => handleInputChange(e, "isBankAccountOwner")}
                                    disabled={!editable}
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    {!dialogData.isBankAccountOwner &&
                        <>
                            <InputField
                                itemKey={"beneficiaryIDIC"}
                                require={true}
                                valueData={dialogData.beneficiaryIDIC || ""}
                                editable={editable}
                                validField={valid.beneficiaryIDIC}
                                isSubmitted={isSubmitted}
                                ValidatorCallback={ValidatorCallback}
                                handleInputChange={handleInputChange}
                            />
                            <FormGroup
                                className={
                                    isSubmitted && !dialogData.isBankAccountOwner ? (valid.bankRelationship ? null : 'error') : null
                                }
                            >
                                <Form.Label>
                                    <Translate value="driver.relationship" />
                                    <span className="require"> *</span>
                                </Form.Label>
                                <FormControl
                                    className="form-custom"
                                    as="select"
                                    value={dialogData.bankRelationship}
                                    placeholder="Select relationship status"
                                    onChange={e => handleInputChange(e, "bankRelationship")}
                                    disabled={!editable}
                                >
                                    <option key={0} value={""}>
                                        {I18n.t("driver.select_relationship")}
                                    </option>
                                    {relationships.map(item => {
                                        return (
                                            <option key={item.value} value={item.value}>
                                                {I18n.t(`${item.label}`)}
                                            </option>
                                        );
                                    })}
                                </FormControl>
                                <Validator id="bankRelationship" callback={ValidatorCallback}>
                                    <ValidCase
                                        hide={!isSubmitted}
                                        valid={!Validation.isStringEmpty(dialogData.bankRelationship)}
                                        message={I18n.t('messages.commonMessages.Required_field')}
                                    />
                                </Validator>
                            </FormGroup>

                            {dialogData.bankRelationship && parseInt(dialogData.bankRelationship) === 6 &&
                                <InputField
                                    itemKey={"relationshipOtherName"}
                                    require={true}
                                    valueData={dialogData.relationshipOtherName || ""}
                                    editable={editable}
                                    validField={valid.relationshipOtherName}
                                    isSubmitted={isSubmitted}
                                    ValidatorCallback={ValidatorCallback}
                                    handleInputChange={handleInputChange}
                                />
                            }
                        </>
                    }
                </>
            }

            {fields.verifiedStatus && fields.verifiedStatus.show && dialogData.id &&
                <FormGroup className="bankAccount__bankingStatus">
                    <Form.Label>
                        <Translate value="driver.banking_status" />
                    </Form.Label>
                    {dialogData.isBankVerified ? (
                        <div className="text-active ml-lg">{I18n.t('driver.bankingStatus.verified')}</div>
                    ) : (
                        <div className="red ml-lg">{I18n.t('driver.bankingStatus.not_verified')}</div>
                    )}
                </FormGroup>
            }

        </div>
    )
}


export default BankAccount
