import React from 'react';
import IntlTelInputApp from '../../../../components/intlTelInputCustome/IntlTelInputApp';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const Twilio = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.accountSID" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.accountSID || ''}
          onChange={(e) => {
            props.handleInputChange('accountSID', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.authToken" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.authToken || ''}
          onChange={(e) => {
            props.handleInputChange('authToken', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.applicationId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.applicationId || ''}
          onChange={(e) => {
            props.handleInputChange('applicationId', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.phoneNumber" />
        </Form.Label>
        <FormGroup>
          <IntlTelInputApp
            css={['intl-tel-input', 'form-control form-custom']}
            utilsScript={'libphonenumber.js'}
            value={props?.formData?.phoneNumber || ''}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              props.handlePhoneChange(
                'phoneNumber',
                status,
                value,
                countryData,
                number,
                id
              );
            }}
            disabled={props.onLoad}
          />
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.phoneNumber2" />
        </Form.Label>
        <FormGroup>
          <IntlTelInputApp
            css={['intl-tel-input', 'form-control form-custom']}
            utilsScript={'libphonenumber.js'}
            value={props?.formData?.phoneNumber2 || ''}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              props.handlePhoneChange(
                'phoneNumber2',
                status,
                value,
                countryData,
                number,
                id
              );
            }}
            disabled={props.onLoad}
          />
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.SenderID_1" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.senderId1 || ''}
          onChange={(e) => {
            props.handleInputChange('senderId1', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.SenderID_2" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.senderId2 || ''}
          onChange={(e) => {
            props.handleInputChange('senderId2', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.maskedPhone" />
        </Form.Label>
        <FormGroup>
          <IntlTelInputApp
            css={['intl-tel-input', 'form-control form-custom']}
            utilsScript={'libphonenumber.js'}
            value={props?.formData?.maskedPhone || ''}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              props.handlePhoneChange(
                'maskedPhone',
                status,
                value,
                countryData,
                number,
                id
              );
            }}
            disabled={props.onLoad}
          />
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.smsMarketingNumber" />
        </Form.Label>
        <FormGroup>
          <IntlTelInputApp
            css={['intl-tel-input', 'form-control form-custom']}
            utilsScript={'libphonenumber.js'}
            value={props?.formData?.smsMarketingNumber || ''}
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              props.handlePhoneChange(
                'smsMarketingNumber',
                status,
                value,
                countryData,
                number,
                id
              );
            }}
            disabled={props.onLoad}
          />
        </FormGroup>
      </FormGroup>
    </div>
  );
};

export default Twilio;
