import React, { Component } from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../settings.scss";
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    Form,
    Button
} from "react-bootstrap";
import IntlTelInputApp from "../../../components/intlTelInputCustome/IntlTelInputApp";
import { Translate, I18n } from "react-redux-i18n";

import { Validator, ValidCase, WarningFeeInput } from '../../../components/validator';
import { CCLiteCommonFunc, Validation, textboxNumberHelper } from '../../../utils/commonFunctions';

import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';

class VoipSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fleet: this.props.auth.selectedFleet.fleetId,
            initdataDefault: {
                driver: {
                    callCCVoip: false,
                    callCCMobile: false,
                    callPassengerVoip: false,
                    callPassengerMobile: false
                },
                passenger: {
                    callCCVoip: false,
                    callCCMobile: false,
                    callDriverVoip: false,
                    callDriverMobile: false
                }
            },
            valid: {},
            isChanged: false,
            isSubmited: false
        };
    };

    componentDidMount() {
        this.getVoipDetails();
    }

    getVoipDetails = () => {
        this.props.settingActions
            .getVoipSetting(this.props.auth.selectedFleet.fleetId)
            .then(data => {
                if (data.res) {
                    this.setState({ initdataDefault: data.res });
                } else {
                    if (data.error) {
                        this.context.notification(
                            "error",
                            I18n.t("errors." + data.error.errorCode)
                        );
                    }
                    // else {
                    //     this.context.notification(
                    //         "error",
                    //         I18n.t("message.Load_voip_fail")
                    //     );
                    // }
                }
            });
    };

    ValidatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    };

    handleSaveClick = () => {
        this.setState({ isChanged: false, isSubmited: true });
        const { initdataDefault } = this.state;
        if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
            const body = _.cloneDeep(initdataDefault);
            body.fleetId = this.props.auth.selectedFleet.fleetId;
            let newBody = _.pick(body, ['fleetId', 'driver', 'passenger'])
            this.props.loadingBarActions.showLoadingSpiner();
            this.props.settingActions
                .saveVoipSetting(newBody)
                .then(data => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    if (data.ok && !data.error) {
                        this.context.notification(
                            "success",
                            I18n.t("message.Update_voip_success")
                        );
                    } else {
                        if (data.error) {
                            this.context.notification(
                                "error",
                                I18n.t("errors." + data.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                "error",
                                I18n.t("message.Update_voip_fail")
                            );
                        }
                        this.setState({ isChanged: true, isSubmited: false });
                    }
                });
        } else {
            this.setState({ isChanged: true });
        }
    };


    handleMapStatusSettingChanged = (code) => {
        const { initdataDefault } = this.state;
        switch (code) {
            case 'driver.callCCVoip':
                initdataDefault.driver.callCCVoip = !this.state.initdataDefault.driver.callCCVoip;
                break;
            case 'driver.callCCMobile':
                initdataDefault.driver.callCCMobile = !this.state.initdataDefault.driver.callCCMobile;
                break;
            case 'driver.callPassengerVoip':
                initdataDefault.driver.callPassengerVoip = !this.state.initdataDefault.driver.callPassengerVoip;
                break;
            case 'driver.callPassengerMobile':
                initdataDefault.driver.callPassengerMobile = !this.state.initdataDefault.driver.callPassengerMobile;
                break;
            case 'passenger.callCCVoip':
                initdataDefault.passenger.callCCVoip = !this.state.initdataDefault.passenger.callCCVoip;
                break;
            case 'passenger.callCCMobile':
                initdataDefault.passenger.callCCMobile = !this.state.initdataDefault.passenger.callCCMobile;
                break;
            case 'passenger.callDriverVoip':
                initdataDefault.passenger.callDriverVoip = !this.state.initdataDefault.passenger.callDriverVoip;
                break;
            case 'passenger.callDriverMobile':
                initdataDefault.passenger.callDriverMobile = !this.state.initdataDefault.passenger.callDriverMobile;
                break;
            default:
                break;
        };
        this.setState({ initdataDefault: { ...initdataDefault }, isChanged: true });
    };

    render() {
        const { voip } = this.props.auth.selectedFleet.generalSetting;
        const hasPermission = !this.props.permissions || this.props.permissions.actions;
        const { initdataDefault, isSubmited, valid } = this.state;
        return (
            <div className="voice-sms-content">
                <Row>
                    <Col xs={12} md={6}>
                        <div className="group-general">
                            <FormGroupTitle>
                                <Translate value="voiceSMSSetting.DRIVER_APP" />
                            </FormGroupTitle>
                            <div className="voip-item">
                                {voip && voip.forApp ? (
                                    <FormGroup
                                        className={
                                            !isSubmited ? null : valid.callPassenger ? null : 'error'
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="voiceSMSSetting.allowDriverToCallPassenger" />
                                        </Form.Label>

                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.driver.callPassengerVoip}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('driver.callPassengerVoip');
                                                }}
                                                text={I18n.t('voiceSMSSetting.voip')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.driver.callPassengerMobile}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('driver.callPassengerMobile');
                                                }}
                                                text={I18n.t('voiceSMSSetting.mobile')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>

                                        <Validator id="callPassenger" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={initdataDefault.driver.callPassengerVoip === true || initdataDefault.driver.callPassengerMobile === true}
                                                message={I18n.t('messages.commonMessages.choose_at_least_one')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : null}
                                {voip && voip.drvCallCC ? (
                                    <FormGroup
                                        className={
                                            !isSubmited ? null : valid.drvCallCC ? null : 'error'
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="voiceSMSSetting.allowDriverToCallHelpdesk" />
                                        </Form.Label>
                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.driver.callCCVoip}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('driver.callCCVoip');
                                                }}
                                                text={I18n.t('voiceSMSSetting.voip')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.driver.callCCMobile}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('driver.callCCMobile');
                                                }}
                                                text={I18n.t('voiceSMSSetting.mobile')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>
                                        <Validator id="drvCallCC" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={initdataDefault.driver.callCCVoip === true || initdataDefault.driver.callCCMobile === true}
                                                message={I18n.t('messages.commonMessages.choose_at_least_one')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : null}
                            </div>

                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="voiceSMSSetting.PASSENGER_APP" />
                            </FormGroupTitle>
                            <div className="voip-item">
                                {voip && voip.forApp ? (
                                    <FormGroup
                                        className={
                                            !isSubmited ? null : valid.callDriver ? null : 'error'
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="voiceSMSSetting.allowPassengerToCallDriver" />
                                        </Form.Label>

                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.passenger.callDriverVoip}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('passenger.callDriverVoip');
                                                }}
                                                text={I18n.t('voiceSMSSetting.voip')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.passenger.callDriverMobile}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('passenger.callDriverMobile');
                                                }}
                                                text={I18n.t('voiceSMSSetting.mobile')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>

                                        <Validator id="callDriver" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={initdataDefault.passenger.callDriverVoip === true || initdataDefault.passenger.callDriverMobile === true}
                                                message={I18n.t('messages.commonMessages.choose_at_least_one')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : null}
                                {voip && voip.paxCallCC ? (
                                    <FormGroup
                                        className={
                                            !isSubmited ? null : valid.paxCallCC ? null : 'error'
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="voiceSMSSetting.allowPassengerToCallHelpdesk" />
                                        </Form.Label>
                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.passenger.callCCVoip}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('passenger.callCCVoip');
                                                }}
                                                text={I18n.t('voiceSMSSetting.voip')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <CcCheckbox
                                                checked={initdataDefault.passenger.callCCMobile}
                                                onChange={() => {
                                                    this.handleMapStatusSettingChanged('passenger.callCCMobile');
                                                }}
                                                text={I18n.t('voiceSMSSetting.mobile')}
                                                disabled={!hasPermission}
                                            />
                                        </FormGroup>
                                        <Validator id="paxCallCC" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={initdataDefault.passenger.callCCVoip === true || initdataDefault.passenger.callCCMobile === true}
                                                message={I18n.t('messages.commonMessages.choose_at_least_one')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : null}
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12}>
                        <div className="save-voip" >
                            <FormGroup className="form-inline">
                                {!this.props.permissions || this.props.permissions.actions ? (
                                    <Button
                                        className={"btn-save mt-sm"}
                                        disabled={!this.state.isChanged}
                                        onClick={this.handleSaveClick}
                                    >
                                        <Translate value="voiceSMSSetting.Save" />
                                    </Button>
                                ) : (
                                        ""
                                    )}
                            </FormGroup>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }
};


VoipSettings.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VoipSettings);
