import React from "react";
// import { FormControl } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import moment from "moment";
import "moment-timezone";

// import Datetime from "../../../components/dateTime/DateTime";
import CCDropDown from "../../../components/dropdown/ccDropDown";
import _ from "lodash";

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeDateButtons(props.selectedButton);
  }

  handleChangeDateButtons = buttonType => {
    let newState = {};
    /* const { locale: localeCode = 'en' } = this.props || {}; */
    const localeCode = 'vi';
    switch (buttonType) {
      case "today":
        newState = {
          fromDate: moment()
            .startOf("day")
            .toISOString(),
          toDate: moment()
            .endOf("day")
            .toISOString()
        };
        break;
      case "tomorrow":
        newState = {
          fromDate: moment()
            .subtract(-1, "days")
            .startOf("day")
            .toISOString(),
          toDate: moment()
            .endOf("day")
            .toISOString()
        };
        break;
      case "yesterday":
        newState = {
          fromDate: moment()
            .subtract(1, "days")
            .startOf("day")
            .toISOString(),
          toDate: moment()
            .subtract(1, "days")
            .endOf("day")
            .toISOString()
        };
        break;
      case "thisMonth":
        newState = {
          fromDate: moment()
            .startOf("month")
            .toISOString(),
          toDate: moment()
            .endOf("month")
            .toISOString()
        };
        break;
      case "lastMonth":
        newState = {
          fromDate: moment()
            .subtract(1, "month")
            .startOf("month")
            .toISOString(),
          toDate: moment()
            .subtract(1, "month")
            .endOf("month")
            .toISOString()
        };
        break;
      case "nextMonth":
        newState = {
          fromDate: moment()
            .subtract(-1, "month")
            .startOf("month")
            .toISOString(),
          toDate: moment()
            .subtract(-1, "month")
            .endOf("month")
            .toISOString()
        };
        break;
      case "past30Days":
        newState = {
          fromDate: moment()
            .subtract(29, "days")
            .startOf("day")
            .toISOString(),
          toDate: moment()
            .endOf("day")
            .toISOString()
        };
        break;
      case "past7Days":
        newState = {
          fromDate: moment()
            .subtract(6, "days")
            .startOf("day")
            .toISOString(),
          toDate: moment()
            .endOf("day")
            .toISOString()
        };
        break;
      case "thisYear":
        newState = {
          fromDate: moment()
            .startOf("year")
            .toISOString(),
          toDate: moment()
            .endOf("year")
            .toISOString()
        };
        break;
      case "thisWeek":
        newState = {
          fromDate: moment()
            .locale(localeCode)
            .startOf('week')
            .startOf('day')
            .toISOString(),
          toDate: moment()
            .locale(localeCode)
            .endOf('week')
            .endOf('day')
            .toISOString()
        };
        break;
      case "nextWeek":
        newState = {
          fromDate: moment()
            .subtract(-1, "week")
            .locale(localeCode)
            .startOf('week')
            .startOf('day')
            .toISOString(),
          toDate: moment()
            .subtract(-1, "week")
            .locale(localeCode)
            .endOf('week')
            .endOf('day')
            .toISOString()
        };
        break;
      case "lastWeek":
        newState = {
          fromDate: moment()
            .subtract(1, "week")
            .locale(localeCode)
            .startOf('week')
            .startOf('day')
            .toISOString(),
          toDate: moment()
            .subtract(1, "week")
            .locale(localeCode)
            .endOf('week')
            .endOf('day')
            .toISOString()
        };
        break;
      case "custom":
        this.props.customRangeSelectCallback();
        break;
      default:
        break;
    }
    this.props.setDateRange({ ...newState, selectedButton: buttonType });
  };

  getTitle = () => {
    let selectedItem = _.find(
      this.props.buttons,
      item => item.value === this.props.selectedButton
    );
    return (
      <span className="dropdown-title">
        {selectedItem ? I18n.t(selectedItem.label) : "--:--"}
      </span>
    );
  };

  render() {
    return (
      <CCDropDown
        id="date-range-dropdown"
        items={this.props.buttons}
        title={"Select"}
        customTitle={this.getTitle()}
        selectedItems={
          this.props.selectedButton ? [this.props.selectedButton] : []
        }
        valueKey="value"
        labelKey="label"
        onSelect={this.handleChangeDateButtons}
        className={
          `${this.props.className} daterange-dropdown`
        }
        disabled={this.props.disabled}
      />
    );
  }
}
