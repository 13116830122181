import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
  InputGroup,
} from 'react-bootstrap';
import TableActions from '../../../components/table/tableAction/TableActions';
import { connect } from 'react-redux';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import { tableAPIKeyColumns } from './tableAPIKeyColumns';
import { forEach } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import Confirm from '../../../components/confirm/Confirm';
import Webhook from './Webhook';
import './index.scss';
import { getCorporate } from '../../../actions/corporateAction';

const ACTIONS_TYPE = {
  updateInfo: 'updateInfo',
  viewInfo: 'viewInfo',
  delete: 'delete',
  editHooks: 'editHooks',
  viewHook: 'viewHooks',
};

const ACTION_ITEMS = [
  {
    eventKey: ACTIONS_TYPE.updateInfo,
    label: 'Update Info',
    hasPermission: true,
  },
  {
    eventKey: ACTIONS_TYPE.editHooks,
    label: 'Edit Webhooks',
    hasPermission: true,
  },
  {
    eventKey: ACTIONS_TYPE.viewHook,
    label: 'View WebHooks',
    hasPermission: false,
  },
  {
    eventKey: ACTIONS_TYPE.viewInfo,
    label: 'View Info',
    hasPermission: false,
  },
  {
    eventKey: ACTIONS_TYPE.delete,
    label: 'Delete',
    hasPermission: true,
  },
];

const APIManagement = ({ ...props }, context) => {
  const [hasPermissionAction, setHasPermissionAction] = useState(true);

  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [corporate, setCorporate] = useState('');
  const [permission, setPermission] = useState('partner');
  const [corporates, setCorporates] = useState([]);
  const [showCreateAPIKeyModal, setShowCreateAPIKeyModal] = useState(false);

  const [showApiKeyInfoModal, setShowApiKeyInfoModal] = useState(false);
  const [keyInfo, setKeyInfo] = useState({});

  const [APIKeys, setAPIKeys] = useState([]);
  const [tableHeight, setTableHeight] = useState(500);
  const [rowHeight, setRowHeight] = useState(50);
  const [footerData, setFooterData] = useState({
    limit: 20,
    total: 0,
    page: 0,
  });
  const [sortName, setSortName] = useState();
  const [sortType, setSortType] = useState();

  const [confirm, setConfirm] = useState();
  const [infoUpdating, setInfoUpdating] = useState();

  const [showWebhookModal, setShowWebhookModal] = useState(false);

  const toobarContainerRef = useRef(null);
  const parentContainer = useRef(null);

  useEffect(() => {
    setHasPermissionAction(props.permissions?.actions || false);
  }, []);

  useEffect(() => {
    getListCorporates();
    getApiKeyList();
  }, [sortName, sortType]);

  const getListCorporates = async () => {
    try {
      const param = {
        limit: 1000,
        page: 0,
        query: {
          fleetId: props.auth.selectedFleet.fleetId,
          isActive: true,
        },
      };
      const response = await props.getCorporate(param);
      if (response.ok) {
        let selectLists =
          response?.res?.list?.map((item) => {
            return {
              _id: item?._id,
              name: item?.companyInfo?.name || '',
            };
          }) || [];
        setCorporates(selectLists);
      }
    } catch (error) {}
  };

  const getApiKeyList = (activePage, limit) => {
    let param = {
      limit: limit ? limit : footerData.limit,
      page: _.isNumber(activePage) ? activePage : footerData.page,
      fleetId: props.auth.selectedFleet.fleetId,
    };
    if (sortName) {
      param.sort = {
        [sortName]: sortType,
      };
    }
    props.settingActions.apiKeyFind(param).then((response) => {
      const data = response.res || {};
      if (data.list) {
        setAPIKeys(data.list || []);
        setFooterData({
          limit: data.limit,
          total: data.total,
          page: data.page,
        });
      }
    });
  };

  const openKeyModal = (data) => {
    setKeyInfo({
      clientId: data.clientId,
      clientSecret: data.clientSecret,
    });
    setShowApiKeyInfoModal(true);
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], tableAPIKeyColumns);
    forEach(tableColums, (col) => {
      switch (col.key) {
        case 'clientId':
          col.customCell = (data) => {
            return (
              <span
                onClick={() => {
                  openKeyModal(data);
                }}
                className={'columnCopy'}
                style={{ cursor: 'pointer' }}
              >
                {data.clientId}
              </span>
            );
          };
          break;
        case 'clientSecret':
          col.customCell = (data) => {
            return (
              <span
                onClick={() => {
                  openKeyModal(data);
                }}
                style={{ cursor: 'pointer' }}
                className={'columnCopy'}
              >
                {data.clientSecret}
              </span>
            );
          };
          break;
        case 'clientType':
          col.customCell = (data) => {
            if (data.corporate)
              return (
                <a href={`/corporate/edit/${data.corporate}`} target="_blank">
                  <span className={'columnCopy'}>
                    {I18n.t('APIKeySetting.Corporate')}
                  </span>
                </a>
              );
            return I18n.t('APIKeySetting.Individual');
          };
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            let filterActionByPermission = ACTION_ITEMS.filter(
              (ob) => ob.hasPermission == hasPermissionAction
            );
            return (
              <TableActions
                rowIndex={rowIndex}
                rowData={obj}
                onSelect={(eventKey) => handleMenuClick(eventKey, obj)}
                totalRow={APIKeys ? APIKeys.length : 0}
                rowHeight={rowHeight}
                menuItems={filterActionByPermission}
                tableHeight={tableHeight}
                tableId={'table_api'}
              />
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  const handleMenuClick = (key, obj) => {
    switch (key) {
      case ACTIONS_TYPE.editHooks: {
        setShowWebhookModal(true);
        setInfoUpdating(obj._id);
        setPermission(obj.permission);
        break;
      }
      case ACTIONS_TYPE.viewHook: {
        setShowWebhookModal(true);
        setInfoUpdating(obj._id);
        setPermission(obj.permission);
        break;
      }
      case ACTIONS_TYPE.updateInfo: {
        props.settingActions
          .detailApiKey({
            fleetId: props.auth.selectedFleet.fleetId,
            apiKeyId: obj._id,
          })
          .then((response) => {
            if (response.res._id) {
              setAppName(response.res.appName);
              setCorporate(response.res.corporate);
              setAppDescription(response.res.appDescription);
              setPermission(response.res.permission);
              setShowCreateAPIKeyModal(true);
              setInfoUpdating(obj._id);
            } else {
              context.notification('error', '');
            }
          })
          .catch(() => {});
        break;
      }
      case ACTIONS_TYPE.viewInfo: {
        props.settingActions
          .detailApiKey({
            fleetId: props.auth.selectedFleet.fleetId,
            apiKeyId: obj._id,
          })
          .then((response) => {
            if (response.res._id) {
              setAppName(response.res.appName);
              setAppDescription(response.res.appDescription);
              setPermission(response.res.permission);
              setCorporate(response.res.corporate);
              setShowCreateAPIKeyModal(true);
              setInfoUpdating(obj._id);
            } else {
              context.notification('error', '');
            }
          })
          .catch(() => {});
        break;
      }
      case ACTIONS_TYPE.delete: {
        setConfirm({
          id: ACTIONS_TYPE.delete,
          title: 'Delete API Key?',
          body: 'Are you sure you want to delete this API Key?',
          buttonTitle: 'Delete',
          closeButtonText: 'Cancel',
          apiKeyId: obj?._id,
          appName: obj?.appName,
        });
        break;
      }
      default:
        break;
    }
  };

  const handleConfirmButtonClick = (id, confirm) => {
    switch (id) {
      case ACTIONS_TYPE.delete: {
        props.settingActions
          .deleteApiKey({
            fleetId: props.auth.selectedFleet.fleetId,
            apiKeyId: confirm.apiKeyId,
            appName: confirm.appName,
          })
          .then((response) => {
            if (!response.error) {
              context.notification(
                'success',
                'API has been deleted successfully.'
              );
              handleConfirmCloseClick();
              getApiKeyList();
            } else {
              context.notification('error', '');
            }
          })
          .catch(() => {});
        break;
      }
    }
  };

  const handleConfirmCloseClick = () => {
    setConfirm(null);
  };

  const getTableHeight = () => {
    let parentContentVerticalPadding = 40,
      toolbarheight = toobarContainerRef.current
        ? toobarContainerRef.current.clientHeight + 30
        : 0,
      parentHeight = parentContainer?.current
        ? parentContainer?.current?.clientHeight
        : 0;
    let outerHeight = parentContentVerticalPadding + toolbarheight;
    let newTableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) newTableHeight = 550;
    setTableHeight(newTableHeight);

    return newTableHeight;
  };

  const handleCreateOrUpdateApplication = async () => {
    const param = {
      fleetId: props.auth.selectedFleet.fleetId,
      appName: appName,
      corporate: corporate,
      appDescription: appDescription,
      permission: permission,
    };
    if (infoUpdating) param.apiKeyId = infoUpdating;
    props.loadingBarActions.showLoadingSpiner();
    try {
      const response = infoUpdating
        ? await props.settingActions.updateApiKey(param)
        : await props.settingActions.createApiKey(param);
      props.loadingBarActions.hideLoadingSpiner();
      if (response.res?._id) {
        context.notification(
          'success',
          infoUpdating
            ? 'API has been updated successfully.'
            : 'API has been created successfully.'
        );
        getApiKeyList();
        closeCreateAPIKeyModal();
        return;
      } else {
        context.notification('error', response?.error?.message);
      }
    } catch (error) {
      props.loadingBarActions.hideLoadingSpiner();
    }
  };

  const handleChangeCorporate = (e) => {
    setCorporate(e.target.value);
  };

  const handleChangePermission = (e) => {
    setPermission(e.target.value);
  }

  const handleCopyClick = (key) => {
    copy(key);
    context.notification('success', 'Copied!');
  };

  const closeCreateAPIKeyModal = () => {
    setShowCreateAPIKeyModal(false);
    setAppName();
    setAppDescription();
    setPermission('partner');
    setCorporate('');
    setInfoUpdating();
  };

  const renderApiKeyInfo = () => {
    return (
      <Modal
        show={showApiKeyInfoModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="KeyApiModal"
        onHide={() => {
          setShowApiKeyInfoModal(false);
          setKeyInfo();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('APIKeySetting.APIKey')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.clientKey`} />
            </Form.Label>
            <InputGroup className="single-addon-right">
              <FormControl
                className={'form-custom textareaEmail'}
                value={keyInfo?.clientId}
                onChange={(e) => {
                  setAppName(e.target.value);
                }}
                disabled={true}
                maxLength={1000}
              />
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i
                    class="fa fa-copy"
                    onClick={() => {
                      handleCopyClick(keyInfo?.clientId);
                    }}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.secretKey`} />
            </Form.Label>
            <InputGroup className="single-addon-right">
              <FormControl
                className={'form-custom textareaEmail'}
                value={keyInfo?.clientSecret}
                disabled={true}
                maxLength={1000}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <i
                    class="fa fa-copy"
                    onClick={() => {
                      handleCopyClick(keyInfo?.clientSecret);
                    }}
                  />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-save view-button"
              onClick={() => {
                setShowApiKeyInfoModal(false);
                setKeyInfo();
              }}
            >
              <Translate value="APIKeySetting.Done" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderCreateOrUpdateAPIKeyModal = () => {
    return (
      <Modal
        show={showCreateAPIKeyModal}
        backdrop={true}
        dialogClassName="confirm-dialog"
        className="createApiModal"
        onHide={closeCreateAPIKeyModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {infoUpdating
              ? I18n.t('APIKeySetting.updateApplication')
              : I18n.t('APIKeySetting.RegisterApp')}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeCreateAPIKeyModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.appName`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className={'form-custom textareaEmail'}
              value={appName}
              disabled={!hasPermissionAction}
              onChange={(e) => {
                setAppName(e.target.value);
              }}
              maxLength={100}
            />
          </FormGroup>

          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.permission`} />
            </Form.Label>

            <FormControl
              as="select"
              value={permission}
              className="form-custom"
              onChange={handleChangePermission}
            >
              <option value={'partner'}>
                {I18n.t('APIKeySetting.partner')}
              </option>
              <option value={'fleetManager'}>
                {I18n.t('APIKeySetting.fleetManager')}
              </option>
            </FormControl>
          </FormGroup>

          {!['fleetManager'].includes(permission) && <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.Corporate`} />
            </Form.Label>

            <FormControl
              as="select"
              value={corporate}
              className="form-custom"
              onChange={handleChangeCorporate}
            >
              <option value={''}>
                {I18n.t('emailConfig.none') +
                  ' ' +
                  I18n.t('Sidebar.Settings.Selected')}
              </option>
              {corporates.map((data) => (
                <option key={data._id} value={data._id}>
                  {data.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>}

          <FormGroup>
            <Form.Label>
              <Translate value={`APIKeySetting.appDescription`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              as="textarea"
              className={'form-custom textareaEmail'}
              value={appDescription}
              disabled={!hasPermissionAction}
              onChange={(e) => {
                setAppDescription(e.target.value);
              }}
              // placeholder={I18n.t('invoice.Content')}
              rows={5}
              maxLength={1000}
            />
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-save view-button"
              onClick={handleCreateOrUpdateApplication}
              disabled={!appDescription || !appName || !hasPermissionAction}
            >
              {infoUpdating
                ? I18n.t('APIKeySetting.updateApplication')
                : I18n.t('APIKeySetting.RegisterBtn')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const handleNumItemsPerPageChange = (limit) => getApiKeyList(0, limit);
  const handlePaginationSelect = (page) => getApiKeyList(parseInt(page));
  const handleSortApiKey = (sortName) => {
    setSortName(sortName);
    setSortType(sortType == 1 ? -1 : 1);
  };

  const handleCloseWebhookModal = () => {
    setShowWebhookModal(false);
    setInfoUpdating();
    setPermission('partner');
    getApiKeyList();
  };

  return (
    <div className="APIKeySetting" ref={parentContainer}>
      <Col md={10} xs={12}>
        <div className="group-general">
          <div ref={toobarContainerRef}>
            <h4>{I18n.t('APIKeySetting.APIKey')}</h4>
            <p>{I18n.t('APIKeySetting.APIKey_title')}</p>
            <div className="btn_new">
              <Button
                className="btn-save mr-md"
                onClick={() => {
                  setShowCreateAPIKeyModal(true);
                }}
                disabled={!hasPermissionAction}
              >
                +<span>{I18n.t('APIKeySetting.newKeyBtn')}</span>
              </Button>
            </div>
          </div>
          <div className="gridViewTable" id="table_api">
            <StickyTable
              columns={getTableColumns()}
              bodyData={APIKeys}
              footerData={footerData}
              rowHeight={rowHeight}
              getTableHeight={getTableHeight}
              handleNumItemsPerPageChange={handleNumItemsPerPageChange}
              handlePaginationSelect={handlePaginationSelect}
              sortHandle={handleSortApiKey}
              sortType={sortType}
              sortName={sortName}
              // footerLoading={this.state.footerLoading}
              // isLoading={this.state.isLoading}
              settings={props.auth?.selectedFleet}
            />
          </div>
        </div>
      </Col>
      <Confirm
        confirm={confirm}
        className={'confirmInvoice'}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />
      {showWebhookModal && infoUpdating && (
        <Webhook
          handleClose={handleCloseWebhookModal}
          apiKeyId={infoUpdating}
          permission={permission}
        />
      )}
      {
        renderCreateOrUpdateAPIKeyModal() // popup to create or update application
      }
      {
        renderApiKeyInfo() // popup open api key to copy
      }
    </div>
  );
};

APIManagement.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCorporate: (options) => dispatch(getCorporate(options)),
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(APIManagement);
