import React, { useState, useEffect } from 'react'
import { Translate, I18n } from 'react-redux-i18n';
import { LOCATION_TYPE, POINT_TYPE_TEXT } from '../../constants/commondata';
import SelectTimeRecurring from '../DateModeBooking/SelectTimeRecurring';
import _ from 'lodash'
import CCInputNumber from '../InputNumber';
import LocationInputContainer from './LocationInputContainer'
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  Form,
  Row,
  Col
} from "react-bootstrap";

const EditPointModal = ({
  setShowAddPoint,
  isShow,
  hasPartyLocation,
  value,
  submitEditPoint,
  dataEditingPoint,
  recentPlaces,
  fleetId,
  checkPointInZoneSupported,
  setValueEditPoint,
  jobType,
  format24Hour,
  isBookDetails,
  options,
  data,
  auth
}) => {
  const [location, setLocation] = useState(null)
  const [isDirtyLocation, setIsDirtyLocation] = useState(false)
  const [dataMeta, setDataMeta] = useState({
    seats: 1,
    luggage: 0,
    time: '',
    notes: ''
  })
  const [isEditDataMeta, setEditDataMeta] = useState(false)

  useEffect(() => {
    setDataMeta({
      seats: dataEditingPoint.seats || 1,
      luggage: dataEditingPoint.luggage || 0,
      time: dataEditingPoint.time || '',
      notes: dataEditingPoint.notes || ''
    })
  }, [value, dataEditingPoint])

  const onChangeDataMeta = (key, e) => {
    setEditDataMeta(true)
    let value = ''
    if(key != 'notes') {
      value = e
    } else {
      value = e.target.value
    }
    setDataMeta({
      ...dataMeta,
      [key]: value
    })
  }

  const closeModal = () => {
    // setShowAddPoint(false)
    setInitialForm()
  }

  const handleSelectPickUp = (address) => {
    if(address && address.address) {
      setLocation(address)
    } else {
      setLocation({})
    }
  };

  const setInitialForm = () => {
    setLocation(null)
    setValueEditPoint('')
    setShowAddPoint(false)
    setDataMeta({
      seats: 1,
      luggage: 0,
      time: '',
      notes: ''
    })
    setEditDataMeta(false)
    setIsDirtyLocation(false)
  };

  const handleEditNewPoints = () => {
    if(location == null) {
      if(dataMeta) {
        submitEditPoint({
          address: dataEditingPoint.address,
          addressPointText: dataEditingPoint.address && dataEditingPoint.address.address,
          from: dataEditingPoint.from,
          id: dataEditingPoint.id,
          order: dataEditingPoint.order,
          pointType: dataEditingPoint.pointType,
          dataMeta: dataMeta
        })
        setInitialForm()
        return
      }
      return
    }
    let address = location.address || {}
    if(address.lat && address.lng) {
      address.geo = [address.lng, address.lat]
      address.address = location.addressPointText
      submitEditPoint({
        address: address,
        addressPointText: location.addressPointText,
        id: dataEditingPoint.id,
        order: dataEditingPoint.order,
        pointType: dataEditingPoint.pointType,
        dataMeta: dataMeta
      })
      setInitialForm()
    }
  }

  let disableAddBtn = true
  if(location && location.address && location.address.lat ) disableAddBtn = false
  let latLocation = location && location.address && location.address.lat || null
  let addressTextEdit = _.get(dataEditingPoint, 'address.address', '') || null
  if(
    latLocation
    || (isEditDataMeta && addressTextEdit && !isDirtyLocation)
  ) {
    disableAddBtn = false
  }

  let recents = []

  if(dataEditingPoint.pointType == POINT_TYPE_TEXT.pu) {
    recents = recentPlaces.pickup
  }

  if(dataEditingPoint.pointType == POINT_TYPE_TEXT.do) {
    recents = recentPlaces.destination
  }

  return (
    <Modal
        show={isShow}
        backdrop="static"
        key="modal-form"
        dialogClassName="confirm-dialog"
        className="confirm pointModal"
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <Translate value="newbooking.CANCEL_BOOKING" /> */}
            Edit Location
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LocationInputContainer 
            checkPointInZoneSupported={checkPointInZoneSupported}
            fleetId={fleetId}
            address={dataEditingPoint && dataEditingPoint.address || {}}
            jobType={jobType}
            hasPartyLocation={hasPartyLocation}
            recentPlaces={recents}
            format24Hour={format24Hour}
            options={options}
            data={data}
            id={dataEditingPoint && dataEditingPoint.id}
            checkSupportZone={dataEditingPoint.pointType == POINT_TYPE_TEXT.pu ? true : false}
            setNewPoints={handleSelectPickUp}
            setIsDirtyLocation={setIsDirtyLocation}
            auth={auth}
          />
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="bookingdetail.Seat" />
                </Form.Label>
                <CCInputNumber
                  value={dataMeta.seats}
                  min={1}
                  onChange={(e) => onChangeDataMeta('seats', parseInt(e || 0))}
                  inputMode="number"
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="bookingdetail.Luggage" />
                </Form.Label>
                <CCInputNumber
                  placeholder="Luggage"
                  value={dataMeta.luggage}
                  min={0}
                  onChange={(e) => onChangeDataMeta('luggage', parseInt(e || 0))}
                />
              </FormGroup>
            </Col>
          </Row>
          <SelectTimeRecurring
            recurringTime={dataMeta.time}
            setRecurringTime={(value) => onChangeDataMeta('time', value)}
            format24Hour={format24Hour}
            isBookDetails={isBookDetails}
            isEdit
            title={
              dataEditingPoint.pointType == POINT_TYPE_TEXT.do 
              ? I18n.t("newbooking.Drop_off_time")
              : I18n.t("newbooking.Pick_up_time")
            }              />
          <FormGroup>
            <Form.Label>
              <Translate value="bookingdetail.Notes" />
            </Form.Label>
            <FormControl
              as="textarea"
              placeholder=""
              className="form-custom"
              onChange={(e) => onChangeDataMeta('notes', e)}
              value={dataMeta.notes}
              rows={4}
              maxLength={1000}
            ></FormControl>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleEditNewPoints}
            className="btn-save"
            disabled={disableAddBtn}
          >
            <Translate value="newbooking.Save" />
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

EditPointModal.defaultProps = {
  isShow: false,
  isChina: false,
  hasPartyLocation: true,
  value: '',
  dataEditingPoint: {},
  recentPlaces: {},

  //show/hide modal
  setShowAddPoint: () => {},
  checkPointInZoneSupported: () => {},
  submitEditPoint: () => {},
  setValueEditPoint: () => {}
}

export default EditPointModal