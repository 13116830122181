import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { LOCATION_TYPE } from '../../../constants/commondata';
import LocationInput from '../../../components/bookingDetail/bookComponent/LocationInput';
import { geoPlaceDetailMapProvider } from '../../../utils/mapUtils';
import { QQUltis } from '../../../components/qqMap';
import { ValidCase, Validator } from '../../../components/validator';
import { Validation, getLanguageAutoCompleteSearch } from '../../../utils/commonFunctions';
import { getGoogleAddress } from '../../../components/bookingDetail/bookFunction/bookingInfo';

class PickupLocation extends PureComponent {
  state = {
    addressPickUp: '',
    pickupFrom: '',
    pickupAddress: '',
  };

  componentDidMount() {
    if (this.props.pickupAddress) {
      this.setState({ addressPickUp: this.props.pickupAddress });
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.pickupAddress !== this.props.pickupAddress) {
      this.setState({ addressPickUp: nextProps.pickupAddress, pickupFrom: nextProps.pickupFrom })
    }
  }

  handleSelectThirdPartyPickUp = (addressPickUp, data) => {
    if (addressPickUp && addressPickUp !== '') {
      if (data && data.location) {
        const cityCountry = data.location.city;
        let cityName = '';
        if (cityCountry) {
          const citySplit = cityCountry.split('_');
          if (citySplit && citySplit.length >= 3) {
            if (citySplit[1]) {
              cityName = citySplit[1];
            } else if (citySplit[2]) {
              cityName = citySplit[2];
            }
          }
        }
        this.setState({ addressPickUp });
        this.props.intercityBookingActions.updateCommonData({
          pickupLocation: {
            lat: data.location.coordinates[1],
            lng: data.location.coordinates[0],
            zipCode: data.location.zipCode,
            countryCode: data.location.countryCode,
            city: data.location.city,
            cityName,
            instructionLink: data.location.instructionLink,
            addressDetails: data.location.addressDetails
          },
          pickupFrom: LOCATION_TYPE.ThirdParty,
          pickupAddress: addressPickUp,
        });
        this.props.intercityBookingActions.etaFare();
        this.props.getLocationService()
      }
    } else {
      this.setState({
        geocodeResultsPickUp: null,
        locationPickUp: null,
        loadingPickUp: false,
      });
    }
  };

  handleSelectPickUp = (addressPickUp, placeId, pointOfInterest, sessionToken) => {
    const {
      location: { isChina },
    } = this.props;
    if (addressPickUp && addressPickUp != '') {
      this.setState({
        // addressPickUp,
        loadingPickUp: true,
        pickupFrom: LOCATION_TYPE.Google,
      });
      const callback = (err, location, results) => {
        if (err) {
          this.setState({
            locationPickUp: null,
            loadingPickUp: false,
          });
        } else {
          const adrText = getGoogleAddress({
            googleAddress: this.props.selectedFleet.googleAddress,
            formattedAddress: location?.formatted_address,
            suggestionAddress: addressPickUp
          })
          this.setState({
            loadingPickUp: false,
            addressPickUp: adrText
          });
          this.props.intercityBookingActions.updateCommonData({
            pickupLocation: location,
            pickupAddress: adrText,
            pickupFrom: isChina ? LOCATION_TYPE.Tencent : LOCATION_TYPE.Google,
          });
          this.props.intercityBookingActions.etaFare();
          this.props.getLocationService()
        }
      };
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props.language,
        bookId: this.props?.bookInfo?.bookId
      });
    } else {
      this.setState({
        locationPickUp: null,
        loadingPickUp: false,
      });
    }
  };

  handleChangeThirdPartyPickUp = (addressThirdPartyPickUp) => {
    if (addressThirdPartyPickUp && addressThirdPartyPickUp.length > 0) {
      this.setState({
        addressPickUp: addressThirdPartyPickUp,
        pickupFrom: LOCATION_TYPE.ThirdParty,
      });
    } else {
      this.handleAddressRemovedPickUp();
    }
  };

  handleChangePickUp = (addressPickUp) => {
    if (addressPickUp && addressPickUp.length > 0) {
      this.setState({
        addressPickUp,
        pickupFrom: LOCATION_TYPE.Google,
      });
    } else {
      this.handleAddressRemovedPickUp();
    }
  };

  handleAddressRemovedPickUp = () => {
    this.setState({
      addressPickUp: '',
      locationPickUp: null,
      pickupFrom: null
    });
    this.props.intercityBookingActions.updateCommonData({
      pickupLocation: null,
      pickupAddress: '',
      pickupFrom: null,
    });
    this.props.intercityBookingActions.etaFare();
    this.props.getLocationService()
  };

  render() {
    const { location, valid, validatorCallback, selectedFleet, pickupLocation, routeSelected, isSubmited, isViewOnly, options } = this.props;
    const { partyLocation } = selectedFleet.moduleSettings;
    const { addressPickUp, pickupFrom } = this.state;
    return (
      <FormGroup
        className={ ((pickupLocation && routeSelected) || isSubmited) && valid.addressPickUp === false ? 'error' : null}
      >
        <Form.Label>
          <Translate value="newbooking.Pick_up" />{' '}
          <span className="require"> *</span>
        </Form.Label>
        {partyLocation && (
          <LocationInput
            address={
              pickupFrom !== LOCATION_TYPE.ThirdParty ? '' : addressPickUp
            }
            onChange={this.handleChangeThirdPartyPickUp}
            onRemove={this.handleAddressRemovedPickUp}
            onSelect={this.handleSelectThirdPartyPickUp}
            disabled={
              isViewOnly ||
              (pickupLocation && pickupFrom !== LOCATION_TYPE.ThirdParty)
            }
            thirdParty
            value={addressPickUp}
            // recents={pickUpEditable && recentPlaces && recentPlaces.pickup}
            onSelectRecent={this.handleSelectThirdPartyPickUp}
            isChina={location.isChina}
            options={options}
            pickUp
            data={this.props.bookInfo}
            center={this.props.center}
          />
        )}

        <LocationInput
          // recents={pickUpEditable && recentPlaces && recentPlaces.pickup}
          address={pickupFrom == LOCATION_TYPE.ThirdParty ? '' : addressPickUp}
          center={this.props.center}
          onSelectRecent={this.handleSelectRecentPickup}
          onChange={this.handleChangePickUp}
          onRemove={this.handleAddressRemovedPickUp}
          onSelect={this.handleSelectPickUp}
          value={addressPickUp}
          disabled={isViewOnly || pickupFrom == LOCATION_TYPE.ThirdParty}
          isChina={location.isChina}
          className="mb0"
          data={this.props.bookInfo}
          options={options}
        />
        <Validator
          callback={validatorCallback}
          id="addressPickUp"
        >
          <ValidCase
            valid={addressPickUp}
            hide={(!pickupLocation || !routeSelected)}
            message={I18n.t('newbooking.No_support_location')}
          />
          <ValidCase
            valid={
              Validation.insidePolygon(
                pickupLocation,
                _.get(routeSelected, 'firstLocation.coordinates[0]')
              )
            }
            hide={(!pickupLocation || !routeSelected)}
            message={I18n.t('newbooking.No_support_location')}
          />
        </Validator>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    location: state.commonData.location,
    pickupLocation: state.intercityBooking.common.pickupLocation,
    pickupAddress: state.intercityBooking.common.pickupAddress,
    pickupFrom: state.intercityBooking.common.pickupFrom,
    routeSelected: state.intercityBooking.common.routeSelected,
    language: state.i18n,
    bookInfo: state.bookingDetail.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PickupLocation);
