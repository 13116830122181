import React, { Component } from 'react';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import { InputGroup, FormControl, FormGroup } from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';

import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../../components/validator';

export default class InputCurrency extends Component {
  state = {
    valid: {},
  };

  getCurrencyValue = () => {
    const { currentFieldValue, currency } = this.props;
    const currencyCurrent = _.find(
      currentFieldValue || [],
      (o) => o.currencyISO === currency.iso
    );
    return currencyCurrent ? this.parseInputValue(currencyCurrent.value) : 0;
  };

  handleCancelInputMultiCurrenciesChange = (e) => {
    const { currency, onChange } = this.props;
    let { currentFieldValue } = this.props;
    let isAdd = true;
    const currencyISO = currency.iso;
    const cur = {
      currencyISO,
      value: e.target.value,
    };
    currentFieldValue =
      currentFieldValue ||
      this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
      }));
    currentFieldValue = currentFieldValue.map((c) => {
      if (c.currencyISO === currencyISO) {
        isAdd = false;
        return { ...c, value: e.target.value };
      }
      return c;
    });
    if (isAdd) {
      currentFieldValue.push(cur);
    }
    if (onChange) onChange(currentFieldValue);
  };

  parseInputValue = (value) => {
    const { currency } = this.props;
    const { isFocus } = this.state;
    if (isFocus) {
      return value.toString().replace(/[^.0-9]+/, '') || 0;
    }
    return currencyFormatter.format(value || 0, {
      code: currency.iso,
      format: '%v',
    });
  };

  setFocus = () => {
    this.setState({ isFocus: true });
  };

  unsetFocus = () => {
    this.setState({ isFocus: false });
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  ValidatorCallback = (id, valid) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  render() {
    const { currency, currentFieldValue, disabled } = this.props;
    const { valid } = this.state;
    return (
      <FormGroup
        className={`qup-input-group mb0 ${valid.inputCurrency === false ? 'error' : null}`}
      >
        <InputGroup className="single-addon-left">
          <InputGroup.Prepend>
            <InputGroup.Text>{currency.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className="form-custom no-marginBottom"
            onChange={this.handleCancelInputMultiCurrenciesChange}
            onBlur={(e) => {
              this.unsetFocus();
              textboxNumberHelper.onBlurHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(event);
              });
            }}
            onFocus={(e) => {
              this.setFocus();
              textboxNumberHelper.onfocusHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(event);
              });
            }}
            value={this.getCurrencyValue()}
            disabled={disabled}
          />
          {' '}
        </InputGroup>
        <Validator id="inputCurrency" callback={this.ValidatorCallback}>
          <ValidCase
            valid={
              currentFieldValue.filter(
                (d) => !Validation.isStringEmpty(d.value) && _.isNaN(d.value)
              ).length === 0
            }
            message={I18n.t('messages.commonMessages.must_be_number')}
          />
          <ValidCase
            valid={
              currentFieldValue.filter(
                (d) => !Validation.isGreaterOrEqual(d.value, 0)
              ).length === 0
            }
            message={I18n.t('messages.commonMessages.greater_or_equa').format(
              0
            )}
          />
          <ValidCase
            valid={
              currentFieldValue.filter(
                (d) => !Validation.isLessOrEqual(d.value, 9999999999)
              ).length === 0
            }
            message={I18n.t('messages.commonMessages.less_than').format(
              this.formatCurrency(9999999999)
            )}
          />
        </Validator>
        <WarningCase
          validator={valid.inputCurrency}
          message={I18n.t('message.warningInputChangeOver')}
          initialValue={currentFieldValue.map((ob) => ob.value)}
          onChangeValue={currentFieldValue.map((ob) => ob.value)}
          range={20}
          typePercent={false}
        />
      </FormGroup>
    );
  }
}
