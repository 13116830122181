import React from 'react';
import {Col} from 'react-bootstrap';
import {Line} from "react-chartjs-2";
import {I18n} from 'react-redux-i18n';
import {renderHintLabel} from '../../utils/chartUtils';

const ProfitAndFinishedBook = ({revenueChartData}) => {
  return (
    <Col sm={12} className='mr-t-20'>
      <div className='db-body chart-container'>
        <div className='db-chart-title'>
                  <span className='title'>
                    {renderHintLabel(
                      I18n.t('dashboard.Profit_finish_booking'),
                      I18n.t('dashboard.ProfitAndFinishedBookingHint'),
                      'bottom',
                    )}
                  </span>
        </div>
        <div className='db-chart pd-20'>
          <Line
            data={revenueChartData}
            options={{
              maintainAspectRatio: false,

              scales: {
                yAxes: [
                  {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontColor: '#E5793B',
                    },
                  },
                  {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {},
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontColor: '#04BE76',
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontColor: '#e6e6e6',
                    },
                    gridLines: {},
                  },
                ],
              },
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  fontSize: 12,
                  boxWidth: 20,
                  fontColor: '#e6e6e6',
                },
              },
            }}
          />
        </div>
      </div>
    </Col>
  )
};

export default ProfitAndFinishedBook;
