import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _, { xor } from 'lodash';
import PropTypes from 'prop-types';
import SMSTabs from './SMSTab';
import './index.scss';
import * as reportActions from '../../../actions/reportActions';
import * as loadingActions from '../../../actions/loadingBarActions';
import * as commonActions from '../../../actions/commonDataAction';
import * as settingActions from '../../../actions/settingActions';
import { smsIntegrationList } from '../../../constants/commondata';
import AddIcon from '../../../assets/images/icons/add.svg';
import {
  Col,
  Form,
  FormControl,
  FormGroup,
  Row,
  Button,
  Tabs,
  Tab,
  Image,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import RenderFormSMS from './components';
import { data } from 'jquery';

const TAB_KEYS = {
  worldwide: 'worldwide',
  add: 'add',
};

const SMSIntegration = (
  {
    commonData,
    auth,
    permissions,
    reportActions,
    loadingActions,
    settingActions,
  },
  context
) => {
  const [onLoad, setOnload] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB_KEYS.worldwide);
  const [tabsInfo, setTabsInfo] = useState([]);

  const getSMSCountries = async () => {
    try {
      setOnload(true);
      let result = await settingActions.getSMSCountries({
        fleetId: auth.selectedFleet.fleetId,
      });

      if (result?.status === 200) {
        convertDataToTabs(result.smsProviders);
      } else {
        context.notification('error', result.message);
      }
      setOnload(false);
    } catch (error) {
      context.notification('error', error);
      setOnload(false);
    }
  };

  const convertDataToTabs = (smsProviders, tabKey = TAB_KEYS.worldwide) => {
    if (!Array.isArray(smsProviders) || smsProviders.length === 0) return [];
    const tabs = [];
    smsProviders.map((s) => {
      tabs.push({
        key:
          s?.countries?.length > 0
            ? s?.info?._id 
            : TAB_KEYS.worldwide,
        title:
          s.countries.map((ct) => ct.countryCode)?.join(', ') || 'Worldwide',
        data: s,
      });
    });
    setTabsInfo(tabs);
    handleTabSelect(tabKey);
  };

  useEffect(() => {
    getSMSCountries();
  }, []);

  const handleTabSelect = (key) => {
    setCurrentTab(key);
  };

  return (
    <div className="content smsContainer" style={{ overflow: 'auto'}}>
      <Row>
        <Col md={6}>
          <Tabs
            activeKey={currentTab}
            id="controlled-tab"
            className={`tabs_smsProvider`}
            onSelect={handleTabSelect}
          >
            {tabsInfo &&
              tabsInfo.map((t) => (
                <Tab key={t.key} eventKey={t.key} title={t.title}>
                  <SMSTabs
                    keyTab={t.key}
                    smsInfo={t.data}
                    updateInfoTab={convertDataToTabs}
                    TAB_KEYS={TAB_KEYS}
                  />
                </Tab>
              ))}
            <Tab 
              key={TAB_KEYS.add} 
              eventKey={TAB_KEYS.add} 
              title={(
                <Image 
                  className="add-icon" 
                  src={AddIcon} 
                  style={{ width: '20px' }}
                />
              )}>
              <SMSTabs
                keyTab={TAB_KEYS.add}
                smsInfo={{}}
                updateInfoTab={convertDataToTabs}
                TAB_KEYS={TAB_KEYS}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

SMSIntegration.contextTypes = {
  notification: PropTypes.func,
};

function SMSIntegrationStateToProps(state, ownProp) {
  return {
    user: state.auth.user,
    auth: state.auth,
    commonData: state.commonData,
    permissions: state.menuHandle.modulePermission,
  };
}

function SMSIntegrationDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}

export default connect(
  SMSIntegrationStateToProps,
  SMSIntegrationDispatchToProps
)(SMSIntegration);
