import { I18n } from 'react-redux-i18n';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import logIcon from '../../../../assets/images/icons/logGreen.svg';
import Links from './links';
import IconvsTextBtn from './components/IconvsTextBtn';
import PropTypes from 'prop-types';
import DispatchLogs from '../../../DispatchLogs';
import PaymentLogs from '../PaymentLogs';
import { checkShowViewPaymentLogsBtn, isAllDeliveryBookType } from '../../../../utils/commonFunctions';

const Logs = ({
  bookInfo,
  selectedFleet,
  is3rdBooking,
  isSupplierPermission,
  hasPermission,
  isFoodMart,
  isCorporateBoard,
  ...props
}) => {
  const [showModalDispatch, setShowModalDispatch] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);

  const enablePaymentLog = useMemo(
    () =>
      checkShowViewPaymentLogsBtn({
        bookInfo,
        is3rdBooking,
        isSupplierPermission
      }),
    []
  );

  const enableDispatchLog = useMemo(() => !isSupplierPermission, []);
  const enablePhotoDelivery = useMemo(() => isAllDeliveryBookType(bookInfo), []);

  const renderDispatchLog = () => {
    if (!enableDispatchLog) return null;
    return (
      <DispatchLogs
        showModal={showModalDispatch}
        setShowModal={setShowModalDispatch}
        data={bookInfo}
        type={'cue'}
      />
    );
  };

  const renderPaymentLog = () => {
    if (!enablePaymentLog) return null;
    return showModalPayment ? (
      <PaymentLogs
        bookInfo={bookInfo}
        hasPermissionAction={hasPermission}
        handleClose={() => setShowModalPayment(false)}
        isCorporateBoard={isCorporateBoard}
      />
    ) : null;
  };

  if(!enablePaymentLog && enableDispatchLog && enablePhotoDelivery) return null;

  return (
    <div style={{ display: "inline-block"}}>
      {renderDispatchLog()}
      {renderPaymentLog()}
      <Dropdown style={{ display: "inline-block" }} className='dropItemDark'>
        <Dropdown.Toggle variant="link" id="dropdown-basic" className='dropdown-toggle-hide-arrow'>
          <IconvsTextBtn text={I18n.t('supplier.logs')} icon={logIcon} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            enableDispatchLog &&
            <Dropdown.Item onSelect={() => setShowModalDispatch(true)}>
              {I18n.t('bookingdetail.ViewLog')}
            </Dropdown.Item>
          }
          {
            enablePaymentLog &&
            <Dropdown.Item onSelect={() => setShowModalPayment(true)}>
              {I18n.t('bookingdetail.ViewPaymentLogs')}
            </Dropdown.Item>
          }
          {
            enablePhotoDelivery &&
            <>
              <Dropdown.Item onSelect={() => props.setShowDeliveredPhotosModal(true)}>
                {I18n.t('bookingdetail.view_delivery_receipt')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => props.setShowCollectedPhotosModal(true)}>
                {I18n.t('bookingdetail.view_good_receipt')}
              </Dropdown.Item>
            </>
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

Logs.defaultProps = {
  bookInfo: {},
  selectedFleet: {},
  delivery: false,
  hasPermission: false,
  isCorporateBoard: false,
};

Logs.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  isCorporateBoard: PropTypes.bool,
  setShowCollectedPhotosModal: PropTypes.func,
  setShowDeliveredPhotosModal: PropTypes.func,
};

export default Logs;
