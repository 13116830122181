import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fare from '../components/Fare';
import * as actions from '../../../../actions/dynamicFareActions';

const mapStateFromProps = state => ({
  selectedFleet: state.auth ? state.auth.selectedFleet : null,
  auth: state.auth,
  permissions: state.menuHandle.modulePermission,
  commonData: state.commonData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateFromProps,
  mapDispatchToProps
)(Fare);
