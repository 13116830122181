export const VehilceColumns = [
  {
    key: 'photo',
    label: 'driver.Photo',
    width: 120,
    fixed: true,
    textEllipsis: true,
  },
  {
    key: 'vehicleModel.name',
    label: 'driver.Model',
    width: 100,
  },
  {
    key: 'plateNumber',
    label: 'driver.licensePlateNumber',
    width: 140,
  },
  {
    key: 'type.name',
    label: 'cue.Car_type',
    width: 140,
  },
  {
    key: 'status',
    label: 'user.Status',
    width: 120,
  },
  {
    key: 'actions',
    label: '',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
