import React from "react";
import BaseReport from "./../base";
import * as fieldMappings from "./fields";
import { reportQuestApi } from "../../../constants/ApiConfigs";
import { connect } from "react-redux";
import * as commonDataActions from '../../../actions/commonDataAction';
import { bindActionCreators } from 'redux';
import ReportUtils from '../base/utils';
import _ from 'lodash';


class FirstWillWinReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules,{"name":'Report'});
    let checkExport = _.findIndex(report.actions,{"name":"Quests","Export":true});
      let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
      checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
      if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
          checkExport = false;
      }
    return (
        <div className="content">
            <BaseReport
                tittle="Quest"
                fieldMappings={fieldMappings}
                apiurl={reportQuestApi}
                userType
                eventType
                event
                eventState
                search="report.query.search.firstWillWin"
                noexport={!checkExport}
                />
        </div>
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission,
    user: state.auth.user
  };
}
function mapDispatchToProps(dispatch) {
  return {
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirstWillWinReport);
