import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import styles from '../../styles/formInfo.module.scss';
import { ValidCase, Validator } from '../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  getSuppilerPermission,
} from '../../../../utils/commonFunctions';
import DeleteWhite from '../../../../assets/images/icons/deleteWhite.svg';
import VisibilityWhite from '../../../../assets/images/icons/visibilityWhite.svg';
import AddWhite from '../../../../assets/images/icons/addWhite.svg';
import IntlTelInputApp from '../../../../components/intlTelInputCustome/IntlTelInputApp';
import DateTime from '../../../../components/dateTime/DateTime';
import moment from 'moment-timezone';
import UploadFile from '../../../../components/upload/upload';
import Autosuggest from 'react-autosuggest';
import { CountryDropdown } from 'react-country-region-selector';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

const FormInfo = (valProps) => {
  const {
    isSubmitted,
    agents,
    state,
    detailItem,
    agentList,
    validatorCallback,
    handleInputChange,
    corporationTypes,
    handleSelectChange,
    handlePhoneChange,

    onAgentsSuggestionsFetchRequested,
    onAgentsSuggestionsClearRequested,
    getAgentSuggestionValue,
    agentAutocompleteSlectedHandle,
    handleAgentChange,
    handleAgentChangeBlur,

    suggestionListRef,

    closeDialogForm,
    saveDialogForm,
    onEditable,
    props,
    handleChangeCheckBox
  } = valProps;

  const { action } = props;

  const isSupplier = props?.auth?.user?.roles?.isSupplier;
  return isSupplier ? (
    <>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <div className={styles['additional-title']}>
            {
              [
                ...props?.commonData?.companies,
                ...props?.commonData?.suppliers,
              ]?.find(
                (item) => item._id === props?.auth?.user?.roles?.companyId
              )?.name
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.firstName" />
            </Form.Label>
            <FormControl
              type="text"
              value={props.auth?.user?.firstName || ''}
              disabled={true}
              className="form-custom"
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.lastName" />
            </Form.Label>
            <FormControl
              type="text"
              value={props.auth?.user?.lastName || ''}
              disabled={true}
              className="form-custom"
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.email" />
            </Form.Label>
            <FormControl
              type="text"
              value={props.auth?.user?.email || ''}
              disabled={true}
              className="form-custom"
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.phoneNumber" />
            </Form.Label>
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={props.auth?.user?.phone}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  ) : (
    <>
      {detailItem._id && (
        <Row>
          <Col xs={12} md={6} lg={4}>
            <CcCheckbox
              disabled={!state.editable}
              checked={detailItem ? detailItem.activeSupplier : false}
              onChange={(e) => {
                handleChangeCheckBox('activeSupplier', e);
              }}
              text={I18n.t('supplier.activeSupplier')}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={
              isSubmitted ? (state.valid.name === false ? 'error' : null) : null
            }
          >
            <Form.Label>
              <Translate value="supplier.companyName" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={(e) => {
                handleInputChange('name', e);
              }}
              value={detailItem.name || ''}
              placeholder={I18n.t('supplier.companyName')}
              maxLength={25}
              // required={true}
              disabled={!state.editable}
            />
            <Validator
              id="name"
              callback={validatorCallback}
              disabled={!state.editable}
            >
              <ValidCase
                hide={!isSubmitted}
                valid={detailItem?.name?.trim()?.length > 0}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.corporationType" />
            </Form.Label>
            <FormControl
              as="select"
              value={detailItem.corporationType}
              placeholder="select"
              onChange={(e) => handleSelectChange('corporationType', e)}
              disabled={!state.editable}
              className="form-custom select-supplier-custom"
            >
              {corporationTypes?.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.agent" />
            </Form.Label>
            <FormControl
              as="select"
              className="form-custom"
              placeholder={I18n.t('supplier.agent')}
              value={detailItem?.agentId}
              onChange={handleAgentChange}
              disabled={
                _.get(props.auth, 'user.roles.companyId', null)
                  ? true
                  : !state.editable
              }
            >
              <option value={''}>None</option>
              {agents.map((a) => (
                <option key={a._id} value={a._id}>
                  {a.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.country" />
            </Form.Label>
            <CountryDropdown
              value={detailItem.country}
              onChange={(val) => handleSelectChange('country', val)}
              disabled={!state.editable}
              classes="form-control form-custom"
            />
          </FormGroup>
        </Col>

        {[
          'address',
          'zipCode',
          'city',
          'state',
          'tax',
          'businessRegistration',
          'vatNumber',
        ].map((item, index) => {
          return (
            <Col key={index} xs={12} md={6} lg={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value={`supplier.${item}`} />
                </Form.Label>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={(e) => {
                    handleInputChange(item, e);
                  }}
                  value={detailItem[item] || ''}
                  placeholder={I18n.t(`supplier.${item}`)}
                  maxLength={25}
                  // required={true}
                  disabled={!state.editable}
                />
              </FormGroup>
            </Col>
          );
        })}
      </Row>

      <div className={styles['additional-title']}>
        <Translate value="supplier.ADMIN_INFORMATION" />
      </div>

      <Row>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.firstName" />
            </Form.Label>
            <FormControl
              type="text"
              value={detailItem?.adminInfo?.firstName || ''}
              onChange={(e) => handleInputChange('adminInfo.firstName', e)}
              disabled={!state.editable}
              className="form-custom"
              placeholder={I18n.t('supplier.firstName')}
            />
          </FormGroup>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.lastName" />
            </Form.Label>
            <FormControl
              type="text"
              value={detailItem?.adminInfo?.lastName || ''}
              onChange={(e) => handleInputChange('adminInfo.lastName', e)}
              disabled={!state.editable}
              className="form-custom"
              placeholder={I18n.t('supplier.lastName')}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup>
            <Form.Label>
              <Translate value="supplier.phoneNumber" />
            </Form.Label>
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={detailItem?.adminInfo?.phone}
              onPhoneNumberBlur={(
                status,
                value,
                countryData,
                number,
                id,
                suggestion,
                isDialCodeOnly
              ) => {
                handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  true,
                  isDialCodeOnly
                );
              }}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  false
                );
              }}
              disabled={!state.editable}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormGroup
            className={`qup-input-group ${
              isSubmitted
                ? state.valid.email === false
                  ? 'error'
                  : null
                : null
            }`}
          >
            <Form.Label>
              <Translate value="supplier.email" />
            </Form.Label>
            <FormControl
              type="text"
              value={detailItem?.adminInfo?.email || ''}
              onChange={(e) => handleInputChange('adminInfo.email', e)}
              disabled={!state.editable}
              className="form-custom"
              placeholder={I18n.t('supplier.email')}
            />
            <Validator id={'email'} callback={validatorCallback}>
              <ValidCase
                valid={Validation.validateEmail(detailItem?.adminInfo?.email)}
                message={I18n.t('messages.commonMessages.invalid_email_format')}
                hide={!isSubmitted}
              />
            </Validator>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}

          {/* {(!props.permissions || props.permissions.actions) &&
          action !== 'add' &&
          props.params.driverId &&
          auth.selectedFleet.password.driver ? (
            <Button
              style={{ padding: '6px 15px', minWidth: 'unset' }}
              className="btn-reset ml-md mt-md"
              onClick={handleResetPasswordClick}
            >
              <Translate value="user.Reset_password" />
            </Button>
          ) : null} */}
        </Col>
      </Row>
    </>
  );
};

export default FormInfo;
