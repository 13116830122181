import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { Link } from 'react-router';
import qs from 'query-string';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "react-moment";
import { Translate, I18n } from "react-redux-i18n";

import {
    FormGroup,
    FormControl,
    Form,
    Button,
    Modal,
    Tab,
    Tabs,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

import "./Trip.scss";
import {
    userType,
    TRIPSearchBy
} from "../../constants/commondata";
import {
    CCLiteCommonFunc,
    Validation,
    textboxNumberHelper,
    dispatchOuterTableChange
} from "../../utils/commonFunctions";

import * as loadingActions from '../../actions/loadingBarActions';
import * as commonActions from "../../actions/commonDataAction";
import { getTrip, updateTripFillter, updateTrip, updateTripFilterParams, getTripDetails } from '../../actions/tripAction';
import { socketConfigs } from '../../constants/socketConfigs';
import { socketDispatchApi } from '../../utils/socketUtils';

import TripFilter from './components/TripFilter';
import EstimatedFareColumn from './components/EstimatedFareColumn';

import StickyTable from "../../components/table/stickyTable/StickyTable";
import { TripColumns } from "./headerData";
import TripDetails from './components/TripDetails';


const ItemPerPage = 20;

class Trip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: {
                time: 1
            },
            selectItem: null,
            txtSearch: "",
            searchBy: TRIPSearchBy[0].value,
            fillter: {
                capacityStatus: {
                    all: true,
                    empty: true,
                    notLive: true,
                    live: true,
                    full: true
                },
                carType: [],
                routeId: "",
                operator: "",
                routeNumber: null,
                status: {
                    all: true,
                    confirmed: true,
                    started: true,
                    intransit: true,
                    pending: true
                },
                tripTypes: {
                    all: true,
                    scheduled: true,
                    requested: true,
                    preferred: true,
                }
            },
            showRideSharingFilter: false,
            valid: {},
            rowHeight: 100,
            showDataPage: 1,
            totalItem: 0,
            pageDisplayed: 0,
            limitInit: 20,
            isLoading: false,
            tripDetailsShowing: false,
            tripDetailsData: {},
            listTrips: [],
            autoRefresh: this.props.auth.selectedFleet.map.isAutoRefresh ? this.props.auth.selectedFleet.map.value * 1000 : 500
        };
        this.isFiltered = false;

        this.tripDataRequest = this.tripDataRequest.bind(this);
    };

    componentDidMount() {
        const { fillter } = this.state;
        if (
            this.props.auth.user.userType == userType.FleetAdmin ||
            this.props.auth.user.userType == userType.FleetUser
        ) {
            this.tripDataRequest();
            if (this.props.auth.user.roles.companyId) {
                let newFillter = {
                    ...fillter,
                    driverCompany: this.props.auth.user.roles.companyId
                };
                this.setState({
                    fillter: newFillter
                }, () => {
                    this.props.updateTripFilterParams(newFillter);
                });
            };
        };
        this.onSocketDispatch();
        this.props.commonActions.autoRefreshCueAndMapChange(true);
        if (this.props.auth.selectedFleet.map && this.props.auth.selectedFleet.map.isAutoRefresh) {
            this.state.intervalId = this.handleStartStopGetTrips();
        }
    };

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        if (this.props.auth.selectedFleet.map && this.props.auth.selectedFleet.map.isAutoRefresh) {
            clearInterval(this.state.intervalId);
        }
    };

    onSocketDispatch = () => {
        socketDispatchApi.remove(socketConfigs.receive.trip.updateTrip);
        socketDispatchApi.on(socketConfigs.receive.trip.updateTrip, data => {
            if (data.trip && this.props.commonData.isAutoRefreshCueAndMap) {
                this.props.updateTrip(data.trip);
            }
        });
        socketDispatchApi.on(socketConfigs.receive.trip.newTrip, data => {
            if (data.trip && this.props.commonData.isAutoRefreshCueAndMap) {
                this.props.updateTrip(data.trip);
            }
        });
    }

    handleSearchByChange = value => {
        this.setState({ searchBy: value });
    };


    handleScrollBottom = () => {
        let page = 0;
        page = this.state.showDataPage + 1;
        let showedItem = this.state.showDataPage * ItemPerPage;
        let limit = page * this.state.limitInit;
        if (this.state.totalItem > showedItem) {
            this.tripDataRequest(limit);
            this.setState({
                showDataPage: this.state.showDataPage + 1
            });
        };
    };

    handleToggleActiveFilter = () => {
        let currentFilter = "showActiveFilter";
        this.setState(
            { [currentFilter]: !this.state[currentFilter] },
            dispatchOuterTableChange()
        );
    };

    txtSearchPressHandle = async e => {
        if (e.key === "Enter" && !this.state.isLoading) {
            let txtSearch = this.state.txtSearch;
            txtSearch = (e.target.value || "").trim();

            await this.setState({
                txtSearch: txtSearch
            });

            if (txtSearch === this.state.txtSearch) {
                await this.handleFilterChange(0);
            };

        }
    };


    updateDatefrom = e => {
        let fillter = this.state.fillter;
        fillter.dateFrom = e._d;
        this.setState(
            {
                fillter: fillter
            },
            () => {
                this.handleFilterChange(0);
            }
        );
    };

    updateDateTo = e => {
        let fillter = this.state.fillter;
        fillter.dateTo = e._d;
        this.setState(
            {
                fillter: fillter
            },
            () => {
                this.handleFilterChange(0);
            }
        );
    };


    updateFillterIntercityRoute = newValue => {
        let fillter = {};
        var uniqueId = newValue.slice(0, -2);
        var uniqueRoute = parseInt(newValue.slice(-1));
        fillter.intercityRoutes = _.filter(this.props.commonData.intercityRoutes, item => {
            if (item._id === uniqueId && item.routeNumber === uniqueRoute) {
                var unique = true
            } else {
                var unique = false
            }
            return unique;
        });
        if (fillter.intercityRoutes && fillter.intercityRoutes[0]) {
            fillter.routeId = fillter.intercityRoutes[0]._id;
            fillter.routeNumber = fillter.intercityRoutes[0].routeNumber;
        } else {
            fillter.routeId = "";
            fillter.routeNumber = null;
        };
        this.handleFilterChange(fillter);
    };

    statusItemClicked = key => {
        let fillter = {
            status: this.state.fillter.status
        };
        if (!fillter) return;
        if (key == "all") {
            if (fillter.status.all) {
                Object.keys(fillter.status).map(key => {
                    fillter.status[key] = false;
                });
            } else {
                Object.keys(fillter.status).map(key => {
                    fillter.status[key] = true;
                });
            }
        } else {
            fillter.status[key] = !fillter.status[key];
            fillter.status.all = true;
            Object.keys(fillter.status).map(key => {
                if (key != "all" && fillter.status[key] == false) {
                    fillter.status.all = false;
                }
            });
        }
        this.handleFilterChange(fillter);
    };


    capacityStatusItemClicked = key => {
        let fillter = {
            capacityStatus: this.state.fillter.capacityStatus
        };
        if (!fillter) return;
        if (key == "all") {
            if (fillter.capacityStatus.all) {
                Object.keys(fillter.capacityStatus).map(key => {
                    fillter.capacityStatus[key] = false;
                });
            } else {
                Object.keys(fillter.capacityStatus).map(key => {
                    fillter.capacityStatus[key] = true;
                });
            }
        } else {
            fillter.capacityStatus[key] = !fillter.capacityStatus[key];
            fillter.capacityStatus.all = true;
            Object.keys(fillter.capacityStatus).map(key => {
                if (key != "all" && fillter.capacityStatus[key] == false) {
                    fillter.capacityStatus.all = false;
                }
            });
        }
        this.handleFilterChange(fillter);
    };


    handleTripTypesItemClicked = key => {
        let fillter = {
            tripTypes: this.state.fillter.tripTypes
        };
        if (!fillter) return;
        if (key == "all") {
            if (fillter.tripTypes.all) {
                Object.keys(fillter.tripTypes).map(key => {
                    fillter.tripTypes[key] = false;
                });
            } else {
                Object.keys(fillter.tripTypes).map(key => {
                    fillter.tripTypes[key] = true;
                });
            }
        } else {
            fillter.tripTypes[key] = !fillter.tripTypes[key];
            fillter.tripTypes.all = true;
            Object.keys(fillter.tripTypes).map(key => {
                if (key != "all" && fillter.tripTypes[key] == false) {
                    fillter.tripTypes.all = false;
                }
            });
        };
        this.handleFilterChange(fillter);
    };

    updateFillterOperactor = newValue => {
        this.handleFilterChange({ operator: newValue });
    };

    updateFillterCarTypes = newValue => {
        let fillter = {};
        // fillter.carType = _.filter(this.props.commonData.carTypeIntercity, item => {
        //     return newValue.includes(item.vehicleType);
        // });
        fillter.carType = _.filter(this.props.commonData.carType, item => {
            return newValue.includes(item.vehicleType);
        });
        this.handleFilterChange(fillter);
    };

    updateAutoRefresh = (e) => {
        this.props.commonActions.autoRefreshCueAndMapChange(e);
        if (e) {
            this.handleFilterChange({})
        }
    };

    handleFilterChange = newFilter => {
        let fillter = this.state.fillter;
        fillter = Object.assign(fillter, newFilter);
        this.setState(
            {
                fillter: fillter,
                showDataPage: 1
            },
            () => {
                this.updateTripFilter(0);
            }
        );
    };

    tripDataRequest = (limit, fillter = {}) => {
        this.setState({
            isLoading: true
        });

        const param = {
            limit: limit || this.state.limitInit,
            page: 0,
            sort: this.state.sort,
            query: {
                ... this.state.fillter,
                fleetId: this.props.auth.selectedFleet.fleetId
            }
        };

        if (this.state.sortName) {
            param.sort = { [this.state.sortName]: this.state.sortType };
        }

        if (this.state.txtSearch) {
            param.query.txtSearch = this.state.txtSearch.trim();
            param.query.searchBy = this.state.searchBy;
        };

        if (this.state.txtSearch && !this.state.txtSearch === '') {
            param.query.txtSearch = this.state.txtSearch.trim();
            param.query.searchBy = this.state.searchBy;
            param.page = 0;
        };

        param.query = CCLiteCommonFunc.convertFilterToQueryTrip(param.query);
        this.props.getTrip(param)
            .then(data => {
                if (data.res) {
                    const { page, total, list } = data.res;
                    this.setState({
                        isLoading: false,
                        listTrips: list,
                        totalItem: total,
                        pageDisplayed: page
                    });
                };
            });
    }


    updateTripFilter = (limit, sort, fillter = {}) => {
        this.setState({
            isLoading: true
        });

        const param = {
            limit: limit || this.state.limitInit,
            page: 0,
            sort: sort || this.state.sort,
            query: {
                ... this.state.fillter,
                fleetId: this.props.auth.selectedFleet.fleetId
            }
        };

        if (this.state.sortName) {
            param.sort = { [this.state.sortName]: this.state.sortType };
        }

        if (this.state.txtSearch) {
            param.query.txtSearch = this.state.txtSearch.trim();
            param.query.searchBy = this.state.searchBy;
        };

        if (!this.state.txtSearch === '') {
            param.query.txtSearch = this.state.txtSearch.trim();
            param.query.searchBy = this.state.searchBy;
            param.page = 0;
        };

        param.query = CCLiteCommonFunc.convertFilterToQueryTrip(param.query);

        // Update Filter params for realtime trips
        const filterParams = { ...this.state.fillter, sort: { ...param.sort }, txtSearch: this.state.txtSearch };
        this.props.updateTripFilterParams(filterParams);

        this.props.updateTripFillter(param)
            .then(data => {
                if (data.res) {
                    const { page, total, list } = data.res;
                    this.setState({
                        isLoading: false,
                        listTrips: list,
                        totalItem: total,
                        pageDisplayed: page
                    });
                }
            });
    }

    getTripList = () => {
        const { listActiveSortedIndex, tripsData } = this.props.trips;
        if (!listActiveSortedIndex) return [];
        let list = [];
        if (!Array.isArray(listActiveSortedIndex)) return [];
        let count = 0;
        _.forEach(listActiveSortedIndex, key => {
            let data = _.cloneDeep(tripsData[key]);
            if (data) {
                count++;
                list.push(data);
            }
        });
        return list;
    };

    handleStartStopGetTrips = () => {
        return setInterval(() => {
            if (
                this.props.auth.selectedFleet.map &&
                this.props.auth.selectedFleet.map.isAutoRefresh &&
                this.props.commonData.isAutoRefreshCueAndMap
            ) {
                let list = this.getTripList();
                this.setState({
                    listTrips: list
                });
            } else {
                return;
            };
        }, this.state.autoRefresh);
    };

    tripIdSortClick = () => {
        let sortValue = 1;
        if (this.state.sort.tripId) {
            sortValue = this.state.sort.tripId != 1 ? 1 : -1;
        }
        let sort = {};
        sort = { tripId: sortValue };
        this.setState(
            {
                sort: { tripId: sortValue },
                showDataPage: 1
            },
            () => {
                this.updateTripFilter(0, sort);
            }
        );
    };

    startTimeSortClick = () => {
        let sortValue = 1;
        if (this.state.sort.time) {
            sortValue = this.state.sort.time != 1 ? 1 : -1;
        };
        let sort = {};
        sort = { time: sortValue };
        this.setState(
            {
                sort: { time: sortValue },
                showDataPage: 1
            },
            () => {
                this.updateTripFilter(0, sort);
            }
        );
    };


    getTableColumns = () => {
        const { state } = this;
        const { auth, auth: { selectedFleet: { format24Hour, timezone } }, language: { locale: language } } = this.props;
        const timeFormat = format24Hour ? 'HH:mm' : 'hh:mm A';
        let tableColumns = Object.assign([], TripColumns);
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case "tripId":
                    col.customCell = data => (
                        <div
                            className={"status_cell_wrapper status " + data.status}
                            trip-id={data.tripId}
                        >
                            <p>{data.tripId}</p>
                        </div>
                    );
                    col.customHeader = columnKey => (
                        <div
                            className="trip-id cursor-pointer"
                            onClick={this.tripIdSortClick}
                        >
                            <Translate value="trip.tripId" />
                            {this.state.sort.tripId ? (
                                <i
                                    className={
                                        this.state.sort.tripId == 1
                                            ? "fa fa-sort-desc"
                                            : "fa fa-sort-asc"
                                    }
                                    aria-hidden="true"
                                />
                            ) : (
                                <i className="fa fa-sort" aria-hidden="true" />
                            )}
                        </div>
                    );
                    break;

                case "status":
                    col.customCell = data => (
                        <Translate value={`statusDisplay.${data.status}`} />
                    );
                    break;

                case "tripTypes":
                    col.customCell = data => (
                        <Translate value={`tripTypes.${data.tripType}`} />
                    );
                    break;

                case "startTime":
                    col.customCell = data => {
                        data.language = language;
                        return (
                            <div>
                                <p>
                                    <Moment
                                        tz={data.timezone || timezone}
                                        format={`MMM DD, YYYY ${timeFormat}`}
                                        date={data.startTime}
                                        locale={data.language}
                                    />
                                </p>
                            </div>
                        )
                    };
                    col.customHeader = columnKey => (
                        <div onClick={this.startTimeSortClick} className="cursor-pointer">
                            <Translate value="trip.Start_Time" />
                            {this.state.sort.time ? (
                                <i
                                    className={
                                        this.state.sort.time == -1
                                            ? "fa fa-sort-desc"
                                            : "fa fa-sort-asc"
                                    }
                                    aria-hidden="true"
                                />
                            ) : (
                                <i className="fa fa-sort" aria-hidden="true" />
                            )}
                        </div>
                    );
                    break;

                case "intercityRoute":
                    col.customCell = data => {
                        return (
                            <div>
                                <p>{(data.routeFromZoneName && data.routeToZoneName) ? (data.routeFromZoneName + " - " + data.routeToZoneName) : null}</p>
                            </div>
                        )
                    };
                    break;

                case "seatsStatus":
                    col.customCell = data => (
                        <div>
                            <span><Translate value={`statusDisplay.${data.capacityStatus}`} /> {data.bookedSeats}/{data.seats}</span>
                        </div>
                    );
                    break;

                case "noOfBooking":
                    col.customCell = data => {
                        // cue?searchBy=tripId&txtSearch=10000000709
                        const params = {
                            searchBy: 'tripId',
                            txtSearch: data.tripId
                        };
                        return (
                            <div>
                                <p>{data.bookings.length === 0 ? (data.bookings.length) :
                                    (
                                        <Link
                                            target="_blank"
                                            className="text-active"
                                            to={`/cue?${qs.stringify(params)}`}
                                        >
                                            {data.bookings.length}
                                        </Link>
                                    )
                                }</p>
                            </div>
                        )
                    };
                    break;

                case "vehicle":
                    col.customCell = data => (
                        <div>
                            <p>{data.carTypeRequest ? data.carTypeRequest : null}/</p>
                            <p>{data.drvInfo ? data.drvInfo.plateNumber : "---"}</p>
                        </div>
                    );
                    break;

                case "driver":
                    col.customCell = data =>
                        data.drvInfo ? (
                            <div>
                                <p>{(data.drvInfo.fullName || "")}</p>
                                <p>{data.drvInfo.phone}</p>
                            </div>
                        ) : (
                            <div />
                        );
                    break;

                case "estimatedFare":
                    col.customCell = data => <EstimatedFareColumn trip={data} auth={this.props.auth} />;
                    break;
            }
        });

        return tableColumns;
    }



    getTableHeight = () => {
        let filterNode = this.activeFilter;
        let parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0,
            verticalPadding = 10,
            filterMarginBottom = 10,
            filterHeight =
                filterNode && filterNode.clientHeight ? filterNode.clientHeight : 0;
        let outerHeight =
            verticalPadding + filterHeight + filterMarginBottom;
        let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        if (window.innerHeight < 850 && tableHeight < 600) tableHeight = 650;
        return tableHeight;
    };

    rowClickHandle = (event, index) => {
        let currentTarget = event.currentTarget;
        if (currentTarget) {
            let statusColumn = currentTarget.querySelector(".status_cell_wrapper");
            if (statusColumn) {
                let tripId = statusColumn.getAttribute("trip-id");
                if (tripId) {
                    this.props.getTripDetails({ tripId: tripId })
                        .then(data => {
                            if (!data.res || data.error) {
                                this.context.notification(
                                    "error",
                                    I18n.t("trip.Error_load_trip")
                                );
                            };

                            if (data.ok) {
                                this.setState({
                                    tripDetailsShowing: true,
                                    tripDetailsData: data.res
                                })
                            };

                        });
                }
            }
        }
    };

    closeBookingDialog = () => {
        this.setState({
            tripDetailsShowing: !this.state.tripDetailsShowing,
            tripDetailsData: {}
        })
    };

    renderFilter = () => {
        return (
            <div>
                <TripFilter
                    showFilter={this.state.showActiveFilter}
                    commonData={this.props.commonData}
                    auth={this.props.auth}
                    txtSearchPressHandle={this.txtSearchPressHandle}
                    handleSearchByChange={this.handleSearchByChange}
                    searchBy={this.state.searchBy || TRIPSearchBy[0].value}
                    searchByOptions={TRIPSearchBy}
                    fillter={this.state.fillter}
                    updateDatefrom={this.updateDatefrom}
                    updateDateTo={this.updateDateTo}
                    handleToggleFilter={this.handleToggleActiveFilter}
                    updateFillterIntercityRoute={this.updateFillterIntercityRoute}
                    statusItemClicked={this.statusItemClicked}
                    capacityStatusItemClicked={this.capacityStatusItemClicked}
                    handleTripTypesItemClicked={this.handleTripTypesItemClicked}
                    updateFillterOperactor={this.updateFillterOperactor}
                    updateFillterCarTypes={this.updateFillterCarTypes}
                    tripDataRequest={this.tripDataRequest}
                    commonActions={this.props.commonActions}
                    updateAutoRefresh={this.updateAutoRefresh}
                />

                {this.state.tripDetailsShowing ? (
                    <TripDetails
                        auth={this.props.auth}
                        commonData={this.props.commonData}
                        isShow={this.state.tripDetailsShowing}
                        tripInfo={this.state.tripDetailsData}
                        closeBookingDialog={this.closeBookingDialog}
                    />
                ) : null}
            </div>
        );
    };



    renderTable = () => {
        let data = this.props.auth.selectedFleet.map && this.props.auth.selectedFleet.map.isAutoRefresh ? this.state.listTrips : this.getTripList();
        //limit cues to render is 30 for 1 page
        let rowNumber = ItemPerPage * this.state.showDataPage;
        data = data.slice(0, rowNumber);

        return (
            <StickyTable
                columns={this.getTableColumns()}
                bodyData={data}
                rowHeight={this.state.rowHeight}
                noPagination={true}
                className="cue-table"
                contentTop={true}
                scrollBottomCallback={_.debounce(this.handleScrollBottom, 500)}
                handleRowClick={this.rowClickHandle}
                getTableHeight={this.getTableHeight}
                dynamicColumns={true}
            />
        );
    };


    render() {
        return (
            <div className="content pt0 tripContainer">
                <div ref={node => (this.activeFilter = node)}>
                    {this.renderFilter()}
                </div>
                <div className="gridViewTable">{this.renderTable()}</div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        auth: state.auth,
        commonData: state.commonData,
        selectedFleet: state.auth.selectedFleet,
        corporateCompany: state.corporateCompany,
        language: state.i18n,
        permissions: state.menuHandle.modulePermission,
        trips: state.trips
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        getTrip: options => dispatch(getTrip(options)),
        updateTripFillter: options => dispatch(updateTripFillter(options)),
        updateTrip: data => dispatch(updateTrip(data)),
        updateTripFilterParams: data => dispatch(updateTripFilterParams(data)),
        getTripDetails: options => dispatch(getTripDetails(options))
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Trip);