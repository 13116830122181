import React from "react";
import BaseReport from "./../base";
import * as fieldMappings from "./fields";
import { reportPayoutHistoryApi } from "../../../constants/ApiConfigs";
import { connect } from "react-redux";
import * as commonDataActions from '../../../actions/commonDataAction';
import { bindActionCreators } from 'redux';
import ReportUtils from '../base/utils';
import _ from 'lodash';

const dateRange = [
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth',
    default: true
  },
  {
    value: 'lastMonth',
    key: 'lastMonth',
    label: 'report.query.lastMonth'
  },
  {
    value: 'thisYear',
    key: 'thisYear',
    label: 'report.query.thisYear'
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.custom'
  }
];


class PayoutHistoryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { permissions = null, auth: { user } = {}, selectedFleet } = this.props || {};
    var exportPermission = false;
    let drvPayoutGateway = _.get(selectedFleet, 'drvPayout.gateway')
    if (user.isAdmin) {
      if (this.props.auth.user.roles && this.props.auth.user.roles.moduleAdmin && this.props.auth.user.roles.moduleAdmin.actionCC) {
        exportPermission = true;
      }
    } else if (!this.props.permissions || this.props.permissions.actions) {
      exportPermission = this.props.permissions && this.props.permissions.export ? this.props.permissions.export : false;
    } else {
      exportPermission = this.props.permissions && this.props.permissions.export ? this.props.permissions.export : false;
    }
    return (
      permissions && exportPermission ? (
        <div className="content">
          <BaseReport
            tittle="Payout history"
            fieldMappings={fieldMappings}
            apiurl={reportPayoutHistoryApi}
            dateRange={dateRange}
            company
            driver
            enablePayoutTime
            currency
            search="report.query.search.driverHistory"
            noexport={!exportPermission}
            gateway={drvPayoutGateway}
          />
        </div>)
        :
        (
          <div className="content">
            <BaseReport
              tittle="Payout history"
              fieldMappings={fieldMappings}
              apiurl={reportPayoutHistoryApi}
              dateRange={dateRange}
              company
              driver
              // noexport
              // payoutTime={this.state.payoutTimes}
              enablePayoutTime
              payoutId={this.state.payoutId}
              currency
              search="report.query.search.driverHistory"
              gateway={drvPayoutGateway}
            />
          </div>
        )
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutHistoryReport);
