import {
  PAYMENT_METHOD_REQUEST,
  PAYMENT_METHOD_SUCCESS,
  PAYMENT_METHOD_FAILURE,
} from "../actions/paymentMethodActions";
function initializeState() {
  
  return {
    "individualTypes": [],
    "corporateTypes": [],
    "type3rd": [],
    "airlineTypes": []
  }
}
export default function paymentMethod(state = initializeState(),action={}){
    switch(action.type){
        case PAYMENT_METHOD_REQUEST:{
            return state;
        }
        case PAYMENT_METHOD_SUCCESS:{
            return action.data
        }
        case PAYMENT_METHOD_FAILURE:{
            return initializeState()
        }
        default :
            return state;
    }
    return state;
}