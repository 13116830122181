import React, { useMemo } from 'react';
import styles from './styles/detail.module.scss';
import FormDriver from './components/formDriver';
import HeaderGr from './components/headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import ErrorPage from '../misc/ErrorPage';

const defineTabs = ['info', 'vehicle', 'documents', 'emergency-contact', 'payment', 'settings', 'addNewVehicle'];
const DetailDriver = (props) => {

  const breadcrumbs = useMemo(() => ([
    {
      path: '/driver',
      name: I18n.t('driver.Driver'),
    },
    {
      path: `/driver/view/${props.params.driverId}/info`,
      name: I18n.t('driver.detailDriver'),
    },
  ]), [props.params.driverId]);

  if (!defineTabs.includes(props?.params?.tabActive)) {
    return <ErrorPage/>;
  }

  return (
    <div className={styles['layout']}>
      {
        props?.params?.tabActive !== 'addNewVehicle' && 
        <HeaderGr
          breadcrumbs={breadcrumbs}
          navigate={props.router}
          title={I18n.t('driver.detailDriver')}
        />
      }
      <FormDriver
        action={props?.location?.state?.edit ? 'edit' : 'view'}
        navigate={props.router}
        location={props.location}
        params={props.params}
      />
    </div>
  );
};
 
export default connect()(DetailDriver);
