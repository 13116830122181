import React from 'react';
import ReactDOM from 'react-dom';
import { Table, Column, ColumnGroup, Plugins } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TableFooter from '../tableFooter/TableFooter';
import {
  OUTER_TABLE_CHANGE,
  TOGGLE_SIDEBAR,
} from '../../../constants/customEvent';
import '../../../containers/cue/cue.scss';
import './stickyTable.scss';
import TableCell from './TableCell';
import TableHeader from './TableHeader';

const defaultFooterHeight = 52;
class StickyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 1000,
      tableHeight: 0,
      customeWidthColumn: [],
      customeOrderColumn: [],
    };
    this.handleResizeDebounce = _.debounce(this.handleResize, 700);
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.useParentSize || !this.props.parentSize) {
      this.handleResize();
      window.addEventListener('resize', this.handleResizeDebounce);
      window.addEventListener(TOGGLE_SIDEBAR, this.handleResizeDebounce);
      window.addEventListener(OUTER_TABLE_CHANGE, this.handleResizeDebounce);
    } else {
      let nextParentSize = {
        width: this.props.parentSize.width,
        height: this.props.parentSize.height,
        customHandleEventData: true,
        useParentSize: true,
      };
      this.handleResize(nextParentSize);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.notCheckShouldComponentUpdate) return true
    let checkState = _.isEqual(this.state, nextState);
    if (!checkState) return true;
    if (
      nextProps.shouldRender &&
      nextProps.shouldRender !== this.props.shouldRender
    )
      return true;

    // check columns prop
    if (nextProps.dynamicColumns) {
      if (this.props.columns.length !== nextProps.columns.length) {
        return true;
      } else {
        let diffKey = _.differenceBy(
          this.props.columns,
          nextProps.columns,
          'key'
        );
        if (diffKey.length) return true;
      }
    }
    // check parent size change
    if (nextProps.useParentSize) {
      let checkSize =
        this.props.parentSize.width !== nextProps.parentSize.width ||
        this.props.parentSize.height !== nextProps.parentSize.height;
      if (checkSize) {
        return true;
      }
    }
    // check other props
    let checkOtherProps =
      _.isEqual(this.props.bodyData, nextProps.bodyData) &&
      _.isEqual(this.props.footerData, nextProps.footerData) &&
      _.isEqual(this.props.rowHeight, nextProps.rowHeight) &&
      _.isEqual(this.props.sortName, nextProps.sortName) &&
      _.isEqual(this.props.sortType, nextProps.sortType) &&
      _.isEqual(this.props.noPagination, nextProps.noPagination) &&
      _.isEqual(this.props.className, nextProps.className) &&
      _.isEqual(this.props.footerLoading, nextProps.footerLoading) &&
      _.isEqual(this.props.language, nextProps.language) &&
      _.isEqual(this.props.columns, nextProps.columns) &&
      _.isEqual(this.props.typeBookingGroup, nextProps.typeBookingGroup) &&
      _.isEqual(this.props.checkedGroup, nextProps.checkedGroup);
    if (!checkOtherProps) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.useParentSize || !nextProps.parentSize) {
      this.handleResize();
    } else {
      let nextParentSize = {
        width: nextProps.parentSize.width,
        height: nextProps.parentSize.height,
        customHandleEventData: true,
        useParentSize: true,
      };
      this.handleResize(nextParentSize);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (!this.props.useParentSize) {
      window.removeEventListener('resize', this.handleResizeDebounce);
      window.removeEventListener(TOGGLE_SIDEBAR, this.handleResizeDebounce);
      window.removeEventListener(OUTER_TABLE_CHANGE, this.handleResizeDebounce);
    }
  }

  handleResize = (eventData) => {
    let width, tableHeight, totalHeight;
    const calculateDimensions = () => {
      if (!this._isMounted) return;
      
      let footerHeight = this.getFooterHeight();
      if (eventData && eventData.customHandleEventData && eventData.useParentSize) {
        width = eventData.width;
        totalHeight = eventData.height;
      } else {
        let parentNode = {}
        if(ReactDOM.findDOMNode(this)) {
          parentNode = ReactDOM.findDOMNode(this)?.parentNode;
        }
        if (parentNode) {
          width = parentNode.clientWidth || 800;
        }
        totalHeight = this.props.getTableHeight();
      }
  
      if (!width && this.props.getParentWidthManual) {
        width = this.props.getParentWidthManual();
      }
  
      if (this.props.descreaseWidth) width -= this.props.descreaseWidth;
  
      tableHeight = totalHeight ? totalHeight - footerHeight : 0;
  
      // Check if the state actually needs to be updated
      if (this.state.width !== width || this.state.tableHeight !== tableHeight) {
        this.setState({ width, tableHeight });
      }
    };
  
    requestAnimationFrame(calculateDimensions);
  };

  rowHeightGetter = (index) => {
    if (this.props.rowHeightGetter) {
      return this.props.rowHeightGetter(index);
    }
    return this.getRowHeight();
  };

  getRowHeight = () => {
    return this.props.rowHeight || this.props.rowHeight === 0
      ? this.props.rowHeight
      : 100;
  };

  getHeaderHeight = () =>
    this.props.headerHeight || this.props.headerHeight === 0
      ? this.props.headerHeight
      : 50;

  getFooterHeight = () => {
    if (this.props.noPagination) return 0;
    let footer = ReactDOM.findDOMNode(this.tableFooter) || 0;
    let footerHeight = footer
      ? footer.clientHeight || defaultFooterHeight
      : defaultFooterHeight;
    return footerHeight;
  };

  onVerticalScroll = (scrollPosition) => {
    const { bodyData, scrollBottomCallback } = this.props;
    if (!bodyData.length) return true;
    let rowHeight = this.getRowHeight(),
      headerHeight = this.getHeaderHeight(),
      bodyHeight = this.state.tableHeight;
    // scrollBottomHeight = 17; // chieu cao cua thanh scroll ben duoi,
    let contentHeight = bodyData.length * rowHeight;
    let verticalScrollContainerHeight = bodyHeight - headerHeight;
    let bottomPosition =
      contentHeight - verticalScrollContainerHeight - 2 * rowHeight;
    // bottomPosition: end of scroll list
    // (2 * rowHeight): load scroll callback before 2 row
    if (scrollPosition >= bottomPosition && scrollBottomCallback) {
      scrollBottomCallback(true);
    }
    return true;
  };

  calculateColWidth = () => {
    let columns = Object.assign([], this.props.columns);
    let totalExpectWidth = 0;
    let totalColumns = 0;

    _.forEach(columns, (col) => {
      if (col.subColumns) {
        _.forEach(col.subColumns, (subCol) => {
          subCol.columnWidth = subCol.width || 200;
          totalExpectWidth += subCol.columnWidth;
          totalColumns++;
        });
      } else {
        const { customeWidthColumn = {} } = this.state;
        if (customeWidthColumn[col.key]) {
          col.columnWidth = customeWidthColumn[col.key];
        } else {
          col.columnWidth = col.width || 200;
        }
        totalExpectWidth += col.columnWidth;
        totalColumns++;
      }
    });

    if (this.props.useParentSize) return columns;

    if (this.props.useParentSize) return columns;

    if (this.state.width && totalExpectWidth < this.state.width - 30) {
      // 30: vertical scroll width
      let extra = (this.state.width - totalExpectWidth - 30) / totalColumns;
      _.forEach(columns, (col) => {
        if (col.subColumns) {
          _.forEach(col.subColumns, (subCol) => {
            subCol.columnWidth += extra;
          });
        } else {
          col.columnWidth += extra;
        }
      });
    }
    return columns;
  };

  _onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    this.setState({
      customeWidthColumn: {
        ...this.state.customeWidthColumn,
        [columnKey]: newColumnWidth,
      },
    });
  };

  _onColumnReorderEndCallback = (event) => {
    // let columnOrderNew = this.state.customeOrderColumn.filter((columnKey) => {
    //   return columnKey !== event.reorderColumn;
    // });
    // if (event.columnAfter) {
    //   let index = columnOrderNew.indexOf(event.columnAfter);
    //   columnOrderNew.splice(index, 0, event.reorderColumn);
    //   // this.setState({
    //   //   customeOrderColumn: columnOrderNew,
    //   // });
    // }
    this.props.onChangeReorder(event)
  };

  onColumnReorderStart = (columnKey) => {
    this.setState({
      isReordering: {
        [columnKey]: false,
      },
    });
  };

  render() {
    const {
      bodyData,
      className,
      footerData,
      handleNumItemsPerPageChange,
      handlePaginationSelect,
      sortType,
      sortHandle,
      sortName,
      noPagination,
      contentTop,
      handleRowClick,
      groupHeaderHeight,
      footerHeight,
      footerLoading,
      lineShort,
      isImportBooking = false,
      hasReorder = false,
    } = this.props;
    let newColumns = this.calculateColWidth();
    // if(hasReorder) {
    //   const { customeOrderColumn } = this.state;
    //   if (customeOrderColumn.length > 0) {
    //     newColumns = newColumns.sort(
    //       (a, b) =>
    //         customeOrderColumn.findIndex((i) => i === a.key) -
    //         customeOrderColumn.findIndex((i) => i === b.key)
    //     );
    //   }
    // }

    const groupCellRender = (item, index) => {
      let columnDivider =
        index !== 0 &&
        !item.subColumns &&
        (newColumns[index - 1].subColumns || item.columnDivider)
          ? ' column_divider'
          : '';
      return item.subColumns ? (
        //Sub columns
        <ColumnGroup
          header={
            <TableHeader
              className={
                item.headerClass
                  ? item.headerClass + ' group-header-wrapper'
                  : ' group-header-wrapper'
              }
              customHeader={item.customHeader}
              data={item}
            />
          }
          align={item.align || 'center'}
          fixed={item.fixed || false}
        >
          {_.map(item.subColumns, (subItem, subIndex) => {
            let subColumnDivider =
              (index !== 0 && subIndex === 0) || subItem.columnDivider
                ? ' column_divider'
                : '';
            let headerClass = subItem.headerClass
              ? subItem.headerClass + ' group-header-child'
              : ' group-header-child';

            return (
              <Column
                header={
                  <TableHeader
                    className={headerClass + subColumnDivider}
                    customHeader={subItem.customHeader}
                    data={subItem}
                    sortType={sortType}
                    sortHandle={sortHandle}
                    sortName={sortName}
                  />
                }
                footer={
                  footerHeight && subItem.columnFooter ? (
                    <TableHeader
                      className={headerClass + subColumnDivider}
                      customHeader={subItem.columnFooter}
                      data={subItem}
                      isFooter={true}
                    />
                  ) : null
                }
                columnKey={subItem.key}
                width={subItem.columnWidth}
                cell={
                  <TableCell
                    data={bodyData}
                    customCell={subItem.customCell}
                    className={
                      subItem.cellClass
                        ? subItem.cellClass + subColumnDivider
                        : subColumnDivider
                    }
                    textEllipsis={subItem.textEllipsis}
                    noPadding={subItem.noPadding}
                    tableHeight={this.state.tableHeight}
                    totalRow={bodyData.length}
                    lineShort={lineShort}
                    settings={this.props.settings}
                  />
                }
                align={subItem.align || 'left'}
                pureRendering={true}
              />
            );
          })}
        </ColumnGroup>
      ) : (
        <ColumnGroup header={''}>
          <Column
            key={index}
            header={
              <TableHeader
                className={
                  item.headerClass
                    ? item.headerClass + ' group-column-no-sub' + columnDivider
                    : ' group-column-no-sub' + columnDivider
                }
                customHeader={item.customHeader}
                data={item}
                sortType={sortType}
                sortHandle={sortHandle}
                sortName={sortName}
              />
            }
            footer={
              item.columnFooter ? (
                <TableHeader
                  className={
                    item.headerClass
                      ? item.headerClass + columnDivider
                      : columnDivider
                  }
                  customHeader={item.columnFooter}
                  data={item}
                  isFooter={true}
                />
              ) : null
            }
            columnKey={item.key}
            width={item.columnWidth}
            fixed={item.fixed || false}
            cell={
              <TableCell
                data={bodyData}
                customCell={item.customCell}
                className={
                  item.cellClass
                    ? item.cellClass + columnDivider
                    : columnDivider
                }
                textEllipsis={item.textEllipsis}
                noPadding={item.noPadding}
                tableHeight={this.state.tableHeight}
                totalRow={bodyData.length}
                lineShort={lineShort}
                settings={this.props.settings}
              />
            }
            align={item.align || 'left'}
            pureRendering={true}
          />
        </ColumnGroup>
      );
    };

    const cellRender = (item, index) => {
      // Normal column
      let columnDivider = item.columnDivider ? ' column_divider' : '';
      const TableHeaderElement = (
        <TableHeader
          text={item.label}
          className={
            item.headerClass ? item.headerClass + columnDivider : columnDivider
          }
          customHeader={item.customHeader}
          data={item}
          sortType={sortType}
          sortHandle={sortHandle}
          sortName={sortName}
        />
      );
      return (
        <Column
          key={item.key}
          header={
            hasReorder && !item.fixed ? (
              <Plugins.ReorderCell // support reorder column
                onColumnReorderEnd={this._onColumnReorderEndCallback}
              >
                <Plugins.ResizeCell // support resize column
                  onColumnResizeEnd={this._onColumnResizeEndCallback}
                  minWidth={100}
                >
                  {TableHeaderElement}
                </Plugins.ResizeCell>
              </Plugins.ReorderCell>
            ) : (
              TableHeaderElement
            )
          }
          footer={
            item.columnFooter ? (
              <TableHeader
                text={item.label}
                className={
                  item.headerClass
                    ? item.headerClass + columnDivider
                    : columnDivider
                }
                width={item.columnWidth}
                customHeader={item.columnFooter}
                data={item}
                isFooter={true}
              />
            ) : null
          }
          columnKey={item.key}
          width={item.columnWidth}
          fixed={item.fixed || false}
          allowCellsRecycling={true}
          cell={
            <TableCell
              data={bodyData}
              customCell={item.customCell}
              className={
                item.cellClass ? item.cellClass + columnDivider : columnDivider
              }
              textEllipsis={item.textEllipsis}
              customCellAndEllipis={item.customCellAndEllipis}
              noPadding={item.noPadding}
              tableHeight={this.state.tableHeight}
              totalRow={bodyData.length}
              lineShort={lineShort}
              settings={this.props.settings}
            />
          }
          align={item.align || 'left'}
          pureRendering={true}
        />
      );
    };

    return (
      <div
        className={
          'qup-sticky-table ' +
          (className ? className : '') +
          (contentTop ? ' content-top' : '') +
          (groupHeaderHeight ? ' has-group-column' : '')
        }
      >
        {isImportBooking ? (
          <Table
            rowsCount={bodyData.length}
            rowHeight={this.getRowHeight()}
            rowHeightGetter={this.rowHeightGetter}
            subRowHeightGetter={this.props.subRowHeightGetter}
            rowClassNameGetter={this.props.rowClassNameGetter}
            width={this.state.width}
            maxHeight={this.state.tableHeight}
            headerHeight={this.getHeaderHeight()}
            onVerticalScroll={this.onVerticalScroll}
            rowExpanded={this.props.rowExpanded}
            keyboardScrollEnabled={true}
            onRowClick={handleRowClick}
            groupHeaderHeight={groupHeaderHeight || 0}
            footerHeight={footerHeight || 0}
            touchScrollEnabled={true}
          >
            {/* Group Column */}
            {groupHeaderHeight
              ? _.map(newColumns, groupCellRender)
              : _.map(newColumns, cellRender)}
          </Table>
        ) : (
          <Table
            rowsCount={bodyData.length}
            rowHeight={this.getRowHeight()}
            rowHeightGetter={this.rowHeightGetter}
            subRowHeightGetter={this.props.subRowHeightGetter}
            rowClassNameGetter={this.props.rowClassNameGetter}
            width={this.state.width}
            height={this.state.tableHeight}
            headerHeight={this.getHeaderHeight()}
            onVerticalScroll={this.onVerticalScroll}
            rowExpanded={this.props.rowExpanded}
            keyboardScrollEnabled={true}
            onRowClick={handleRowClick}
            groupHeaderHeight={groupHeaderHeight || 0}
            footerHeight={footerHeight || 0}
            touchScrollEnabled={true}
          >
            {/* Group Column */}
            {groupHeaderHeight
              ? _.map(newColumns, groupCellRender)
              : _.map(newColumns, cellRender)}
          </Table>
        )}
        {noPagination ? null : (
          <TableFooter
            handleNumItemsPerPageChange={handleNumItemsPerPageChange}
            handlePaginationSelect={handlePaginationSelect}
            data={footerData}
            ref={(node) => (this.tableFooter = node)}
            isLoading={footerLoading}
          />
        )}
      </div>
    );
  }
}

StickyTable.propTypes = {
  bodyData: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  handleNumItemsPerPageChange: PropTypes.func,
  handlePaginationSelect: PropTypes.func,
  className: PropTypes.string,
  footerData: PropTypes.shape({
    page: PropTypes.number,
    total: PropTypes.number,
    limit: PropTypes.number,
    active: PropTypes.number,
  }),
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  sortType: PropTypes.number,
  sortName: PropTypes.string,
  noPagination: PropTypes.bool,
  scrollBottomCallback: PropTypes.func,
  rowExpanded: PropTypes.func,
  onScrollEnd: PropTypes.func,
  handleRowClick: PropTypes.func,
  getTableHeight: PropTypes.func,
  getParentWidthManual: PropTypes.func,
  dynamicColumns: PropTypes.bool,
  useParentSize: PropTypes.bool,
  parentSize: PropTypes.object,
  groupHeaderHeight: PropTypes.number,
  footerHeight: PropTypes.number,
  footerLoading: PropTypes.bool,
  lineShort: PropTypes.bool,
  settings: PropTypes.object,
};

export default StickyTable;
