export const TripStatus = [
  {
    label: "trip.All",
    value: "all"
  },
  {
    label: "trip.Confirmed",
    value: "confirmed"
  },
  {
    label: "trip.Started",
    value: "started"
  },
  {
    label: "trip.In_Transit",
    value: "intransit"
  },
  {
    label: "trip.Pending",
    value: "pending"
  }
];



export const SeatStatus = [
  {
    label: "trip.All",
    value: "all"
  },
  {
    label: "trip.Empty",
    value: "empty"
  },
  {
    label: "trip.Partial_not_live",
    value: "notLive"
  },
  {
    label: "trip.Partial_live",
    value: "live"
  },
  {
    label: "trip.Full",
    value: "full"
  }
];


export const TripTypes = [
  {
    label: "trip.All",
    value: "all"
  },
  {
    label: "trip.scheduled",
    value: "scheduled"
  },
  {
    label: "trip.requested",
    value: "requested"
  },
  {
    label: "trip.preferred",
    value: "preferred"
  }
];