import React from 'react';
import _ from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import currencyFormatter from 'currency-formatter';
import PointModal from './PointModal';

export default class Point extends React.PureComponent {
  state = {
    isShowCustomerPoint: false,
    currentPoint: 0,
    pointValue: 0
  };

  componentDidMount() {
    const { currentPoint } = this.props;
    this.setState({
    	currentPoint: currentPoint
    })
  };


  handleConfirmCloseClick = () => {
    this.setState({
      // confirm: null,
      isShowCustomerPoint: false
    });
  }

  handleChangePointClick = (currentPoint) => {
    this.setState({
      isShowCustomerPoint: true,
      currentPoint: currentPoint,
    });
  };

  handlePointSuccess = (res) => {
    this.setState({
      isShowCustomerPoint: false,
      currentPoint: _.get(res, 'points.points.available', 0)
    },
      () => {
        // this.props.handleChangePoint();
      }
    );
  };

  render() {
    const { userId, canUpdatePoint } = this.props;
    const { isShowCustomerPoint, currentPoint } = this.state;
    return (
      <div className="emergencyContact">
        <table className="table table-no-pagination cc-table-striped">
          <thead>
            <tr>
              <th>
                <Translate value="customer.Points" />
              </th>
              <th>
                <Translate value="customer.change" />
              </th>
            </tr>
          </thead>
        </table>
        <div className="emergencyContact__contactList">
          <table className="table table-no-pagination cc-table-striped">
            <thead>
              <tr>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentPoint}</td>
                <td>
                  <div className="changeBalanceBtn">
                    <Button
                      className="btn-save"
                      disabled={!canUpdatePoint}
                      onClick={() => { this.handleChangePointClick(this.state.currentPoint) }}
                    >
                      <Translate value="customer.change" />
                    </Button>
                    {canUpdatePoint ? null : <span className="tooltiptext">{I18n.t(`customer.AccessDenied`)}</span>}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-center mt-md">
          <Button className="btn-reset ml0" onClick={this.props.closeDialog}>
            <Translate value="bookingdetail.Close" />
          </Button>
        </div>
        <PointModal
          isShow={isShowCustomerPoint}
          currentPoint={this.state.currentPoint}
          userId={userId}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
          handlePointSuccess={this.handlePointSuccess}
        />
      </div>
    );
  }
}
