import { addNewCreditApi, deleteCreditApi, checkUnionPayCreditApi, getClientSecretApi } from '../constants/ApiConfigs';
import { callApi, UrlBuilder } from '../utils/apiUtils';

export const CREDIT_ADD_REQUEST = 'CREDIT_ADD_REQUEST';
export const CREDIT_ADD_SUCCESS = 'CREDIT_ADD_SUCCESS';
export const CREDIT_ADD_FAILURE = 'CREDIT_ADD_FAILURE';
export const CREDIT_DELETE_REQUEST = 'CREDIT_DELETE_REQUEST';
export const CREDIT_DELETE_SUCCESS = 'CREDIT_DELETE_SUCCESS';
export const CREDIT_DELETE_FAILURE = 'CREDIT_DELETE_FAILURE';

function addNewCreditRequest() {
  return {
    type: CREDIT_ADD_REQUEST
  };
}

function addNewCreditSuccess(data) {
  return {
    type: CREDIT_ADD_SUCCESS,
    data: data.res
  };
}

function addNewCreditFailure(error) {
  return {
    type: CREDIT_ADD_FAILURE,
    error
  };
}

function deleteCreditRequest() {
  return {
    type: CREDIT_DELETE_REQUEST
  };
}

function deleteCreditSuccess(data) {
  return {
    type: CREDIT_DELETE_SUCCESS,
    data: data.res
  };
}

function deleteCreditFailure(error) {
  return {
    type: CREDIT_DELETE_FAILURE,
    error
  };
}

export function addNewCreditCard(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(
    addNewCreditApi,
    config,
    addNewCreditRequest(),
    addNewCreditSuccess,
    addNewCreditFailure,
    true
  );
}

export function deleteCreditCard(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'delete',
    body: JSON.stringify(options)
  };
  return callApi(
    deleteCreditApi,
    config,
    deleteCreditRequest(),
    deleteCreditSuccess,
    deleteCreditFailure,
    true
  );
}

export function checkUnionPayCredit(options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(checkUnionPayCreditApi, config, null, null, null, true);
}

export function getClientSecret(options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(getClientSecretApi, config, null, null, null, true);
}
