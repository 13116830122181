import ReportUtils from '../base/utils';

const writeOffDebtReasons = ['Pay from app', 'Pay from Pax'];

export const fields = [
  {
    key: 'bookId',
    label: 'report.result.imcompletePayment.bookingId',
    rowSpan: 2,
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    key: 'tripId',
    label: 'report.result.refund.tripId',
    rowSpan: 2,
  },
  {
    key: 'route',
    label: 'report.result.refund.route',
    rowSpan: 2,
    mutate: (value, doc) => {
      return `${doc.routeFromZone} - ${doc.routeToZone}`;
    },
  },
  {
    key: 'bookFrom',
    label: 'report.result.imcompletePayment.bookFrom',
    rowSpan: 2,
  },
  {
    key: 'refundTime',
    label: 'report.result.refund.refundTime',
    rowSpan: 2,
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'customerName',
    label: 'report.result.imcompletePayment.customerName',
    rowSpan: 2,
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'paymentMethod',
    label: 'report.result.imcompletePayment.requestedPaymentMethod',
    rowSpan: 2,
    mutate: ReportUtils.mappingPaymentMethod,
  },
  {
    key: 'totalAmt',
    label: 'report.result.imcompletePayment.totalAmt',
    rowSpan: 2,
    summary: 'totalAmt',
  },
  {
    key: 'refundAmount',
    label: 'report.result.refund.refundAmount',
    rowSpan: 2,
    summary: 'refundAmount',
  },
  {
    key: 'paymentStatus',
    label: 'report.result.refund.paymentStatus',
    rowSpan: 2,
    mutate: ReportUtils.mappingPaymentStatusRefund,
  },
  {
    key: 'reason',
    label: 'report.result.imcompletePayment.reason',
    rowSpan: 2,
  },
];

export const floatFields = ['refundAmount', 'totalAmt'];

export const fareFields = ['totalAmt', 'refundAmount'];
