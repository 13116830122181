import ReportUtils from '../base/utils';
import _ from 'lodash';

export const fields = [
  {
    key: 'date',
    label: 'report.result.rating.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.rating.bookingId',
    summary: 'bookingId',
  },
  {
    key: 'driverName',
    label: 'report.result.rating.driverName',
    summary: 'driverName',
  },
  {
    key: 'driverPhone',
    label: 'report.result.documentExpiry.driverPhone',
  },
  {
    key: 'idPermitType',
    label: 'report.result.idPermitType',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const driverFields = _.get(selectedFleet, 'driverFields', []);
      return !_.find(driverFields, (df) => df.key === 'idType');
    },
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.rating.driverIdPermit',
    summary: 'driverIdPermit',
  },
  {
    key: 'customerName',
    label: 'report.result.rating.customerName',
    summary: 'customerName',
  },
  {
    key: 'customerPhone',
    label: 'report.result.rating.customerPhone',
    summary: 'customerPhone',
  },
  {
    key: 'like',
    label: 'report.result.rating.like',
    summary: 'like',
  },
  {
    key: 'dislike',
    label: 'report.result.rating.dislike',
    mutate: (value, doc) => !doc.like,
    summary: 'totalDislike',
  },
  {
    key: 'notes',
    label: 'report.result.rating.notes',
    summary: 'notes',
  },
];

export const fareFields = [];
