import { CCLiteCommonFunc } from '../utils/commonFunctions';

export const validationShowWarningLimitBalance = ({
  booking = {},
  driver = {},
  auth = {},
  currency = {},
}) => {
  const { selectedFleet } = auth;
  const { enable = false, enableOnlineJob = false } =
    selectedFleet?.driverDepositConfig || {};
  if (!enable) return false;
  const isBookingDetail = !!booking.bookId;
  let { driverDeposit = false, warningAmount = [] } = selectedFleet;
  let creditBalances = _.get(driver, 'driverInfo.creditBalances', []);
  if (creditBalances.length == 0) {
    creditBalances = _.get(driver, 'driverId.driverInfo.creditBalances', []);
  }
  let valueBalanceDriver = CCLiteCommonFunc.getValueOfMultiCurrencies(
    creditBalances,
    currency.iso,
    'value'
  );
  let valueBalanceSetting = CCLiteCommonFunc.getValueOfMultiCurrencies(
    warningAmount,
    currency.iso,
    'value'
  );

  if (!driverDeposit) return false;

  if (isBookingDetail) {
    let paymentType = (booking.request && booking.request.paymentType) || 0;
    let driverFullname = _.get(booking, 'drvInfo.fullName', '') || driver?.phone;
    if (
      (enableOnlineJob || paymentType == 1) &&
      driverFullname &&
      creditBalances.length > 0 &&
      valueBalanceSetting >= valueBalanceDriver
    ) {
      return true;
    }
  } else {
    if (
      (enableOnlineJob || booking.paymentMethod == 1) &&
      !!booking.driver &&
      creditBalances.length > 0 &&
      valueBalanceSetting >= valueBalanceDriver
    ) {
      return true;
    }
  }
  return false;
};

export const validationShowWarningLimitBalanceMultipleBooking = ({
  bookings = [],
  driver = {},
  auth = {},
  currency = {},
}) => {
  let resultValid = false;
  for (let booking of bookings) {
    const checkBook = validationShowWarningLimitBalance({
      booking,
      driver,
      auth,
      currency,
    });

    if (checkBook) {
      resultValid = true;
      break;
    }
  }

  return resultValid;
};
