import React, { useEffect } from 'react';
import styles from './styles/index.module.scss';
import CloseIcon from '../../assets/images/icons/closeWhite.svg';

const Drawer = (props) => {
  const {
    title,
    isOpen,
    children,
    classNameDrawer,
    classNameBody,
    onClose,
    position = 'right',
    closeBackdrop = false,
    footer,
    styleDrawer = {},
    isShowTitle = true,
  } = props;

  return (
    <div
      aria-hidden={isOpen ? 'false' : 'true'}
      className={`${styles['drawer-container']} ${
        isOpen ? styles['open'] : ''
      } ${classNameDrawer && classNameDrawer}`}
    >
      <div style={styleDrawer} className={`${styles['drawer']} ${styles[position]}`} role="dialog">
        {isShowTitle && <div className={styles['drawer-header']}>
          <div onClick={onClose} className={styles['close-icon']}>
            <img src={CloseIcon} alt="closeIcon" />
          </div>
          <div className={styles['title-header']}>
            <span>{title}</span>
          </div>
        </div>}
        <div className={`${styles['drawer-body']} ${classNameBody && classNameBody}`}>{children}</div>
        {footer && <div className={styles['drawer-footer']}
        >{footer}</div>}
      </div>
      <div
        className={styles['backdrop']}
        onClick={() => {
          closeBackdrop && onClose();
        }}
      />
    </div>
  );
};

export default Drawer;
