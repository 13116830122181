export const Columns = [
    {
        key: "name",
        label: "promotionSettings.Name",
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar"
    },
    {
        key: "status",
        label: "promotionSettings.Status",
    },
    {
        key: "actions",
        label: "promotionSettings.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
];

export const PromoCodeColumns = [
    {
        key: "table-selectbox",
        label: "",
        width: 60,
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
        textEllipsis: true
    },
    {
        key: "promotionCode",
        label: "promotionSettings.Promotion_code",
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
        textEllipsis: true
    },
    {
        key: "promoType",
        label: "promotionSettings.Promotion_type",
        textEllipsis: true
    },
    {
        key: "operationZone",
        label: "promotionSettings.Operation_zone",
        textEllipsis: true
    },
    {
        key: "campaign.name",
        label: "promotionSettings.Campaign",
        textEllipsis: true
    },
    {
        key: "type",
        label: "promotionSettings.Type",
        textEllipsis: true
    },
    {
        key: "value",
        label: "promotionSettings.Value",
        textEllipsis: true
    },
    {
        key: "validFrom",
        label: "promotionSettings.Valid_from",
        textEllipsis: true
    },
    {
        key: "validTo",
        label: "promotionSettings.Valid_to",
        textEllipsis: true
    },
    {
        key: "totalUsesUser",
        label: "promotionSettings.Total_uses_user",
        textEllipsis: true
    },
    {
        key: "releaseQuantity",
        label: "promotionSettings.Released_quantity",
        textEllipsis: true
    },
    {
        key: "sendInboxAction",
        label: "promotionSettings.Number_of_sending_inbox",
        textEllipsis: true
    },
    {
        key: "status",
        label: "promotionSettings.Status",
        textEllipsis: true
    },
    {
        key: "actions",
        label: "promotionSettings.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]