import React from 'react'
import _ from 'lodash';
import moment from 'moment';
import tz from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form, Col, Row } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CurrencyFormatter from 'currency-formatter';

import { parStart } from '../../../../utils/commonFunctions';
import * as intercityBookingActions from '../../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../../components/validator';

function TripInfo(props) {

  const { auth, auth: { selectedFleet: { format24Hour, timezone, unitDistance } }, language: { locale: language }, tripInfo } = props;
  const timeFormat = format24Hour ? 'HH:mm' : 'hh:mm A';
  const newTimezone = tripInfo.timezone || timezone;

  const pickUpTime = tripInfo.pickUpTime ? moment(tripInfo.pickUpTime).tz(newTimezone).format(timeFormat) : null;
  const startTime = tripInfo.startTime ? moment(tripInfo.startTime).tz(newTimezone).format(timeFormat) : null;

  const timeDisplay = (tripInfo.tripType && (tripInfo.tripType === "requested" || tripInfo.tripType === "preferred")) ? `${pickUpTime} - ${startTime}` : `${startTime}`;
  const isTripRequest = (tripInfo.tripType && (tripInfo.tripType === "requested" || tripInfo.tripType === "preferred")) ? true : false;

  return (
    <FormGroup>
      <Row>

        <Col xs={12} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.route" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? (tripInfo.routeFromZoneName + " - " + tripInfo.routeToZoneName) : ""
              }
              placeholder={I18n.t("tripDetails.route")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={12} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.startTime" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? (moment(tripInfo.startTime).tz(newTimezone).format(`MMMM DD,`) + ` ${timeDisplay}`) : ""
              }
              placeholder={I18n.t("tripDetails.startTime")}
              disabled={props.disabled}
            />
            {isTripRequest && <Form.Control.Feedback>{I18n.t('bookingdetail.PICKUP_TIME_RANGE').format(timeDisplay)}</Form.Control.Feedback>}
          </FormGroup>
        </Col>

        <Col xs={6} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.distance" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? `${tripInfo.estimate.distance} ${unitDistance}` : ""
              }
              placeholder={I18n.t("tripDetails.distance")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={6} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.duration" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? `${tripInfo.estimate.duration} ${tripInfo.estimate.durationUnit}` : ""
              }
              placeholder={I18n.t("tripDetails.duration")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={12} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.fare" />
            </Form.Label>
            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? CurrencyFormatter.format(tripInfo.estimate.fare, {
                  code: tripInfo.currencyISO,
                }) : ""
              }
              placeholder={I18n.t("tripDetails.fare")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={12} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.cartype" />
            </Form.Label>
            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? tripInfo.carTypeRequest : ""
              }
              placeholder={I18n.t("tripDetails.cartype")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={12} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.seatStatus" />
            </Form.Label>
            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? (
                  (I18n.t(`tripDetails.${tripInfo.capacityStatus}`) + ' ' + `${tripInfo.bookedSeats}/${tripInfo.seats}`)
                ) : ""
              }
              placeholder={I18n.t("tripDetails.seatStatus")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>


        <Col xs={6} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.noOfBooking" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? `${tripInfo.bookings.length}` : ""
              }
              placeholder={I18n.t("tripDetails.noOfBooking")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>

        <Col xs={6} >
          <FormGroup>
            <Form.Label>
              <Translate value="tripDetails.luggage" />
            </Form.Label>

            <FormControl
              type="text"
              className={"form-custom"}
              // onChange={e => {
              //   this.handleInputChange("name", e);
              // }}
              value={
                tripInfo ? `${tripInfo.luggage - tripInfo.availableLuggage}` : ""
              }
              placeholder={I18n.t("tripDetails.luggage")}
              disabled={props.disabled}
            />
          </FormGroup>
        </Col>
      </Row>
    </FormGroup>
  )
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    language: state.i18n,
    routeSelected: state.intercityBooking.common.routeSelected,
    schedules: state.intercityBooking.common.schedules,
    pickupDate: state.intercityBooking.common.pickupDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TripInfo);