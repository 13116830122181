export const DriverInfoFields = {
  firstName: {
    maxLength: 30,
    dbKey: "firstName",
    value: "firstName"
  },
  lastName: {
    maxLength: 30,
    dbKey: "lastName",
    value: "lastName"
  },
  phone: {
    dbKey: "phone",
    value: "phone"
  },
  email: {
    dbKey: "email",
    value: "email"
  },
  preferredCity: {
    optionValue: "commonData.relativeInfo.cities",
    getFromProps: true,
    selectTitle: "driver.Select_city",
    dbKey: "driverCityId",
    value: "driverInfo.city.cityId",
    opValueKey: "_id",
    opLabelKey: "name"
  },
  licenseState: {
    dbKey: "driverLicenseState",
    value: "driverInfo.driverLicenseState"
  },
  licenseNumber: {
    dbKey: "driverLicenseNumber",
    value: "driverInfo.driverLicenseNumber"
  },
  licenseExpiry: {
    dbKey: "driverLicenseExp",
    value: "driverInfo.driverLicenseExp"
  },
  birthday: {
    dbKey: "dateOfBirth",
    value: "driverInfo.dateOfBirth"
  },
  zipcode: {
    dbKey: "bankZip",
    value: "driverInfo.bankZip"
  },
  state: {
    dbKey: "bankState",
    value: "driverInfo.bankState"
  },
  city: {
    dbKey: "bankCity",
    value: "driverInfo.bankCity"
  },
  address: {
    dbKey: "bankAddress",
    value: "driverInfo.bankAddress"
  },
  driverId: {
    dbKey: "drvId",
    maxLength: 50,
    value: "driverInfo.drvId"
  },
  idType: {
    dbKey: "idType",
    value: "driverInfo.idType"
  }
};