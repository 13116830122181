import React, { Fragment } from 'react';
import { FormGroup, FormControl, Form, InputGroup } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../components/validator';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import TipDefaulValueTabs from './TipDefaulValueTabs';

class TipFromGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDefaultTipChange = (e) => {
    this.props.handleUpdateFormData('tips', e.target.value);
  };

  handleTipCheckChange = (e) => {
    this.props.handleUpdateFormData('tipActive', e.target.checked);
  };

  handleChangeCreditTipMethod = (e) => {
    this.props.handleUpdateFormData(
      'tipPaymentMethod.credit',
      e.target.checked
    );
  };

  handleChangeWalletTipMethod = (e) => {
    this.props.handleUpdateFormData(
      'tipPaymentMethod.wallet',
      e.target.checked
    );
  };

  handleChangeTnGTipMethod = (e) => {
    this.props.handleUpdateFormData(
      'tipPaymentMethod.tnGeWallet',
      e.target.checked
    );
  };

  handleChangeVippsMethod = (e) => {
    this.props.handleUpdateFormData('tipPaymentMethod.vipps', e.target.checked);
  };
  handleChangeTipMethod = (e, key) => {
    if (e && key) {
      this.props.handleUpdateFormData(
        `tipPaymentMethod.${key}`,
        e.target.checked
      );
    }
  };

  handleChangeTipDefaultValues = (values) => {
    this.props.handleUpdateFormData('tipDefaultValues', values);
  };

  handleChangeTipDefaultType = (values) => {
    this.props.handleUpdateFormData(
      'tipDefaultValues',
      this.props.formDataInitial.tipDefaultValues
    );
    this.props.handleUpdateFormData('tipDefaultType', values);
  };

  isValidTipDefaultValues = (values, type = 'amount') => {
    if (!values || values.length < 3) {
      return false;
    }

    let isValid = true;
    const { currencies } = this.props.selectedFleet;
    _.forEach(values, (value) => {
      if (type === 'amount') {
        if (!value || value.values.length < currencies.length) {
          isValid = false;
        }
        const indexInvalid = (value.values || []).findIndex(
          (o) =>
            !o.value ||
            parseInt(o.value) <= 0 ||
            parseFloat(o.value).toFixed(1) != parseFloat(o.value) ||
            isNaN(o.value)
        );
        if (indexInvalid !== -1) {
          isValid = false;
        }
      } else {
        if (
          !value.valuePercent ||
          parseInt(value.valuePercent) <= 0 ||
          parseInt(value.valuePercent) > 100 ||
          parseFloat(value.valuePercent).toFixed(1) !=
            parseFloat(value.valuePercent) ||
          isNaN(value.valuePercent)
        ) {
          isValid = false;
        }
      }
    });
    return isValid;
  };

  isEnableTip = () => {
    const {
      formData: { tipPaymentMethod },
    } = this.props;
    return (
      tipPaymentMethod.credit ||
      tipPaymentMethod.wallet ||
      tipPaymentMethod.tnGeWallet ||
      tipPaymentMethod.vipps ||
      tipPaymentMethod.googlePay ||
      tipPaymentMethod.applePay
    );
  };

  render() {
    const {
      formData,
      formDataInitial,
      ValidatorCallback,
      editable,
      valid,
      isSubmited,
      selectedFleet,
      hasPermission,
    } = this.props;
    const isEnableTip = this.isEnableTip();
    return (
      <div className="group-general">
        <CcCheckbox
          checked={formData ? formData.tipActive : false}
          onChange={this.handleTipCheckChange}
          text={I18n.t('generalSetting.TIP')}
          labelClassName="title"
          className="form-group-title"
          disabled={!hasPermission}
        />
        {formData && formData.tipActive ? (
          <Fragment>
            <FormGroup
              className={`sub-controls no-marginBottom ${
                !isSubmited ? null : valid.tips === false ? 'error' : null
              }`}
            >
              <Form.Label>
                <Translate value="generalSetting.Default_tip" />
                <span className="require">*</span>
              </Form.Label>
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-right ${editable ? 'disabled' : ''}`}
                >
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={this.handleDefaultTipChange}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(
                        e,
                        this.handleDefaultTipChange
                      );
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(
                        e,
                        this.handleDefaultTipChange
                      );
                    }}
                    value={formData ? formData.tips : ''}
                    placeholder={I18n.t('generalSetting.Default_tip')}
                    disabled={editable || !hasPermission}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="tips" callback={ValidatorCallback}>
                <ValidCase
                  valid={!Validation.isStringEmpty(formData.tips)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isNumber(formData.tips)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isGreaterOrEqual(formData.tips, 0)}
                  message={I18n.t(
                    'messages.commonMessages.greater_or_equa'
                  ).format(0)}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isInteger(formData.tips)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={Validation.isInRange(formData.tips, 0, 100)}
                  message={I18n.t(
                    'messages.commonMessages.Integer_from_range'
                  ).format(0, 100)}
                  hide={!isSubmited}
                />
              </Validator>
              <WarningCase
                validator={valid.tips}
                message={I18n.t('message.warningInputChangeOver')}
                initialValue={formData.tips}
                onChangeValue={formData.tips}
                range={20}
                typePercent={true}
              />
            </FormGroup>
            <FormGroup
              className={`sub-controls no-marginBottom ${
                !isSubmited ? null : valid.tips === false ? 'error' : null
              }`}
            >
              <Form.Label>
                <Translate value="generalSetting.tipPaymentMethod" />
              </Form.Label>

              <div className="pl-xl">
                {selectedFleet.creditConfig.enable ? (
                  <CcCheckbox
                    checked={
                      formData ? formData.tipPaymentMethod.credit : false
                    }
                    onChange={this.handleChangeCreditTipMethod}
                    text={I18n.t('generalSetting.creadit')}
                    disabled={!hasPermission}
                  />
                ) : null}
                {selectedFleet.paxCreditWallet ? (
                  <CcCheckbox
                    checked={
                      formData ? formData.tipPaymentMethod.wallet : false
                    }
                    onChange={this.handleChangeWalletTipMethod}
                    text={I18n.t('generalSetting.wallet')}
                    disabled={!hasPermission}
                  />
                ) : null}
                {selectedFleet.tnGeWalletConfig.isActive ? (
                  <CcCheckbox
                    checked={
                      formData ? formData.tipPaymentMethod.tnGeWallet : false
                    }
                    onChange={this.handleChangeTnGTipMethod}
                    text={I18n.t('generalSetting.tnGeWallet')}
                    disabled={!hasPermission}
                  />
                ) : null}
                {selectedFleet.vippsWalletConfig.isActive ? (
                  <CcCheckbox
                    checked={formData ? formData.tipPaymentMethod.vipps : false}
                    onChange={this.handleChangeVippsMethod}
                    text={I18n.t('generalSetting.vippseWallet')}
                    disabled={!hasPermission}
                  />
                ) : null}
                {selectedFleet.googlePayConfig?.isActive ? (
                  <CcCheckbox
                    checked={
                      formData ? formData.tipPaymentMethod.googlePay : false
                    }
                    onChange={(e) => this.handleChangeTipMethod(e, 'googlePay')}
                    text={I18n.t('generalSetting.googlePay')}
                    disabled={!hasPermission}
                  />
                ) : null}
                {selectedFleet.applePayConfig?.isActive ? (
                  <CcCheckbox
                    checked={
                      formData ? formData.tipPaymentMethod.applePay : false
                    }
                    onChange={(e) => this.handleChangeTipMethod(e, 'applePay')}
                    text={I18n.t('generalSetting.applePay')}
                    disabled={!hasPermission}
                  />
                ) : null}
              </div>
            </FormGroup>
            {isEnableTip ? (
              <FormGroup
                className={`sub-controls no-marginBottom ${
                  !isSubmited
                    ? null
                    : valid.tipDefaultValues === false
                    ? 'error'
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="generalSetting.tipDefaultValues" />
                </Form.Label>
                <TipDefaulValueTabs
                  currencies={selectedFleet.currencies}
                  tipDefaultValues={formData.tipDefaultValues}
                  tipDefaultType={formData.tipDefaultType}
                  onChange={this.handleChangeTipDefaultValues}
                  handleChangeTipDefaultType={this.handleChangeTipDefaultType}
                  editable={editable}
                />
                <Validator id="tipDefaultValues" callback={ValidatorCallback}>
                  <ValidCase
                    valid={this.isValidTipDefaultValues(
                      formData.tipDefaultValues,
                      formData.tipDefaultType
                    )}
                    message={I18n.t(
                      'messages.commonMessages.integer_greater_than_0'
                    )}
                    hide={!isSubmited}
                  />
                </Validator>
                <WarningCase
                  validator={valid.tipDefaultValues}
                  name={'defaultTipValue'}
                  message={I18n.t('message.warningInputChangeOver')}
                  initialValue={formDataInitial.tipDefaultValues.map((ob) => {
                    return (
                      ob.values &&
                      ob.values.length > 0 &&
                      ob.values.map((tip) => tip)
                    );
                  })}
                  onChangeValue={formData.tipDefaultValues.map((ob) => {
                    return (
                      ob.values &&
                      ob.values.length > 0 &&
                      ob.values.map((tip) => tip)
                    );
                  })}
                  range={20}
                  typePercent={false}
                />
              </FormGroup>
            ) : null}
          </Fragment>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default TipFromGroup;
