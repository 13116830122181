import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import { FormGroup, FormControl } from 'react-bootstrap';
import CurrencyFormatter from 'currency-formatter';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as settingActions from '../../../actions/settingActions';
// import { RootCloseWrapper } from 'react-overlays';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { SERVICE_TYPE } from '../../../constants/commondata';

class AdditionalServiceForm extends PureComponent {
  state = {
    services: [],
    selectedServices: {},
    showServices: false,
  };
  componentDidMount() {
    this.initServices();
  }

  initServices = () => {
    const { fleetId, fleetFareId } = this.props.selectedFleet;
    if (fleetFareId.additionalServicesActive) {
      this.props.settingActions
        .getAdditionalServicesList({ fleetId })
        .then(({ res }) => {
          this.setState({ services: res });
          this.props.intercityBookingActions.updateCommonData({
            additionalServices: res
          })
        });
    };
  };

  onSelectAdditionalService = (serviceId) => {
    const { additionalService } = this.props;
    additionalService[serviceId] = !additionalService[serviceId];
    this.props.intercityBookingActions.updateCommonData({
      additionalService: { ...additionalService }
    })
    this.props.intercityBookingActions.etaFare();
  };

  getCurrencyValue = (currencies) => {
    const { currencyISO } = this.props.routeSelected;
    const foundCurrency = currencies.find(c => c.currencyISO === currencyISO)
    return foundCurrency ? foundCurrency.value : null;
  }

  extraFee = () => {
    const { services } = this.state;
    const { carType, routeSelected, additionalService } = this.props;
    return _.reduce(services, (sum, service) => {
      const isMatchCartype = service.vehicleType.findIndex(o => o.vehicleType === carType.vehicleType) !== -1;
      const isActive = service.serviceType === SERVICE_TYPE.Compulsory || additionalService[service._id]
      if (isMatchCartype && isActive) {
        return sum + this.getCurrencyValue(service.serviceFeeByCurrencies)
      }
      return sum
    }, 0)
  }

  render() {
    const { request, valid, routeSelected, carType, additionalService, isBookDetail, isViewOnly, selectedFleet } = this.props;
    const { services, showServices, selectedServices } = this.state;

    if (!selectedFleet.fleetFareId.additionalServicesActive) {
      return null
    };

    if (!carType || !routeSelected) {
      return null
    }
    const servicesMatched = _.filter(services, service => service.vehicleType.findIndex(o => o.vehicleType === carType.vehicleType) !== -1)
    if (!servicesMatched.length) {
      return null;
    }
    const disabled = isBookDetail && request.paymentType !== 1 || isViewOnly
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.Addl_Services" />
        </FormGroupTitle>
        <FormGroup className="additional-service-form">
          <FormControl
            readOnly
            value={CurrencyFormatter.format(this.extraFee(), {
              code: routeSelected.currencyISO
            })}
            onClick={() => this.setState({ showServices: !showServices })}
            className={
              'form-custom form-control' +
              (disabled ? ' disabled' : '')
            }
          />
          {/* {showServices && (
            <RootCloseWrapper
              onRootClose={(e) => {
                this.setState({ showServices: false });
              }}
              event={'click'}
              disabled={!showServices}
            >
              <div className={'additional-services-item'}>
                {servicesMatched.map((obj) => {
                  const disabledSelect =
                    obj.serviceType == SERVICE_TYPE.Compulsory || disabled;
                  return (
                    <div
                      key={obj._id}
                      className={`item${disabledSelect ? ' disable' : ''}`}
                      onClick={() => {
                        if (obj.serviceType == SERVICE_TYPE.Compulsory || disabled)
                          return;
                        this.onSelectAdditionalService(obj._id);
                      }}
                    >
                      <div className="overlay-div" />
                      <CcCheckbox
                        checked={
                          obj.serviceType == SERVICE_TYPE.Compulsory || additionalService[obj._id]
                        }
                        disabled={
                          obj.serviceType == SERVICE_TYPE.Compulsory || disabled
                        }
                        onChange={() => {
                          return;
                        }}
                        readOnly
                      />
                      <div className={'item-name text-ellipsis'}>
                        {obj.serviceName}
                      </div>
                      <div className={'item-value'}>
                        {CurrencyFormatter.format(this.getCurrencyValue(obj.serviceFeeByCurrencies), {
                          code: routeSelected.currencyISO,
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </RootCloseWrapper>
          )} */}
        </FormGroup>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    carType: state.intercityBooking.common.carType,
    routeSelected: state.intercityBooking.common.routeSelected,
    additionalService: state.intercityBooking.common.additionalService,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalServiceForm);
