import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as settingActions from "../../actions/settingActions";
import "./settings.scss";
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { flightProvider } from '../../constants/commondata';

class FlightIntegration extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      isChanged: false,
      provider: '',
      onLoad: false,
    };
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    this.props.settingActions
      .detailFlightProvider({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then(data => {
        if (data.res) {
          this.setState({ formData: data.res.data ? data.res.data : {}, provider: data.res.provider });
        }
      });
  }
  handleSaveClick() {
    let ob = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      provider: this.state.provider,
      update: {
        username: this.state.formData.username || "",
        apiId: this.state.formData.apiId || "",
        apiKey: this.state.formData.apiKey || ""
      }
    };
    this.props.settingActions.updateFlightProvider(ob).then(data => {
      if (data.ok) {
        this.setState({ isChanged: false });
        this.context.notification(
          "success",
          I18n.t("flightIntegration.Update_flight_integration_success")
        );
        setTimeout(() => {
          window.location.reload();
      }, 1 * 1000);
      } else {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("flightIntegration.Update_flight_integration_fail")
          );
        }
      }
    });
  }
  handleInputChange(key, e) {
    this.state.formData[key] = e.target.value;
    this.state.isChanged = true;
    this.setState({ formData: this.state.formData });
  }
  handleSelectProvider = (e) => {
    this.state.provider = e.target.value
    this.setState({ provider: this.state.provider, onLoad: true });
    if (!e.target.value) return this.setState({onLoad: false});
    this.props.settingActions
    .detailFlightProvider({ fleetId: this.props.auth.selectedFleet.fleetId, provider: e.target.value })
    .then(data => {
      if (data.res) {
        this.setState({ formData: data.res.data ? data.res.data : {}, onLoad: false });
      }
  });
  }
  render() {
    return (
      <div className="content">
        <Row>
          <Col md={6}>
          <FormGroup>
              <Form.Label>
                <Translate value="flightIntegration.provider" />
              </Form.Label>
                  <FormControl
                    as="select"
                    className="form-custom no-marginBottom"
                    onChange={this.handleSelectProvider}
                    value={this.state.provider}
                    disabled={this.state.onLoad}
                  >
                    {flightProvider.map(mode => (
                      <option value={mode.value} key={mode.value}>{I18n.t(mode.label)}</option>
                    ))}
                  </FormControl>
              </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {this.state.provider === '' && <></>}
            {this.state.provider === 'flightaware' && <FormGroup>
              <Form.Label>
                <Translate value="flightIntegration.Username" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                value={this.state.formData?.username || ""}
                disabled={this.state.onLoad}
                onChange={e => {
                  this.handleInputChange("username", e);
                }}
              />
            </FormGroup>}
            
            {this.state.provider === 'flightStats' && <FormGroup>
              <Form.Label>
                <Translate value="flightIntegration.apiId" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                value={this.state.formData?.apiId || ""}
                disabled={this.state.onLoad}
                onChange={e => {
                  this.handleInputChange("apiId", e);
                }}
              />
            </FormGroup>}
            {this.state.provider !== '' && <FormGroup>
              <Form.Label>
                <Translate value="flightIntegration.Key" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                disabled={this.state.onLoad}
                value={this.state.formData?.apiKey || ""}
                onChange={e => {
                  this.handleInputChange("apiKey", e);
                }}
              />
            </FormGroup>}
            <FormGroup className="form-inline">
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className={"btn-save mt-sm"}
                  disabled={this.state.onLoad}                  
                  onClick={this.handleSaveClick}
                >
                  <Translate value="flightIntegration.Save" />
                </Button>
              ) : (
                ""
              )}
            </FormGroup>
          </Col>
          <Col md={6} />
        </Row>
      </div>
    );
  }
}
FlightIntegration.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightIntegration);
