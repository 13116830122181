import _ from 'lodash'
import React from 'react'

export const TransportColumns = [
  { key: 'no', label: 'importBookings.no', fixed: true, width: 50 },
  {
    key: 'status',
    label: 'importBookings.status',
    fixed: true,
    width: 150,
  },
  { key: 'recurring.pickupTime', label: 'importBookings.pickupDate' },
  { key: 'request.pickup.address', label: 'importBookings.pickup' },
  {
    key: 'request.destination.address',
    label: 'importBookings.dropoff',
  },
  { key: 'psgInfo.fullName', label: 'importBookings.passenger' },
  { key: 'psgInfo.phone', label: 'importBookings.phone' },
  { key: 'psgInfo.email', label: 'search_by.passenger_email' },
  {
    key: 'request.vehicleTypeRequest',
    label: 'importBookings.vehicleTypeRequest',
  },
  { key: 'request.paxNumber', label: 'importBookings.seats' },
  { key: 'request.luggageNumber', label: 'importBookings.luggages' },
  {
    key: 'request.rideSharing',
    label: 'importBookings.rideSharing',
    customCell: (rowData) =>
      rowData.request.rideSharing ? (
        <i className="fa fa-check fa-2x activateIcon" />
      ) : (
        ''
      ),
  },
  {
    key: 'request.moreInfo.flightInfo.flightNumber',
    label: 'importBookings.flightNumber',
    customCell: (rowData, index, settings) => {
      const flightInfo = _.get(rowData, 'request.moreInfo.flightInfo', {}) || {}
      const value = flightInfo.flightNumber || ''
      if (!value) return ''
      if (!_.get(rowData, '_meta_.validated')) {
        return value
      }
      const enabledFlightAPI = _.get(
        settings,
        'moduleSettings.flightAPIIntegration'
      )
      if (!enabledFlightAPI) {
        return value
      }
      if (flightInfo.estimatedarrivaltime || flightInfo.departuretime) {
        return (
          <div className="flightNumber">
            <div>{value}</div>
            <div className="green">(Verified)</div>
          </div>
        )
      }
      return (
        <div className="flightNumber">
          <div>{value}</div>
          <div className="red">(Unverified)</div>
        </div>
      )
    },
  },
  { key: 'request.notes', label: 'importBookings.notes' },
  {
    key: 'request.operatorNote',
    label: 'importBookings.operatorNote',
  },
  { key: 'request.paymentType', label: 'importBookings.paymentType' },
  { key: 'externalInfo.bookingReference', label: 'importBookings.externalId' },
  {
    key: 'corporateInfo.clientCaseMatter',
    label: 'importBookings.clientCaseMatter',
  },
  {
    key: 'corporateInfo.chargeCode',
    label: 'importBookings.chargeCode',
  },
]

export const DeliveryColumns = [
  { key: 'no', label: 'importBookings.delivery.no', fixed: true, width: 40 },
  {
    key: 'status',
    label: 'importBookings.status',
    fixed: true,
    width: 150,
  },
  { key: 'recurring.pickupTime', label: 'importBookings.delivery.pickupDate' },
  {
    key: 'deliveryInfo.pickup.name',
    label: 'importBookings.delivery.senderName',
  },
  {
    key: 'deliveryInfo.pickup.phone',
    label: 'importBookings.delivery.senderPhone',
  },
  {
    key: 'deliveryInfo.pickup.email',
    label: 'importBookings.delivery.senderMail',
  },
  {
    key: 'deliveryInfo.pickup.address.address',
    label: 'importBookings.delivery.senderAddress',
  },
  {
    key: 'deliveryInfo.pickup.address.locationDetails',
    label: 'importBookings.delivery.senderLocationDetails',
  },
  {
    width: 150,
    height: 200,
    key: 'request.deliveryInfo.recipients',
    label: 'importBookings.delivery.recipients',
    customCell: (rowData) => {
      const recipients = rowData.deliveryInfo.recipients
      return recipients.length
    },
  },
  {
    key: 'request.rideSharing',
    label: 'importBookings.delivery.rideSharing',
    customCell: (rowData) =>
      rowData.request.rideSharing ? (
        <i className="fa fa-check fa-2x activateIcon" />
      ) : (
        ''
      ),
  },
  {
    key: 'request.paymentType',
    label: 'importBookings.paymentType',
  },
  {
    key: 'request.vehicleTypeRequest',
    label: 'importBookings.vehicleTypeRequest',
  },
  {
    key: 'request.notes',
    label: 'importBookings.notes',
  },
  {
    key: 'request.operatorNote',
    label: 'importBookings.delivery.operatorNote',
  },
  {
    key: 'externalInfo.bookingReference',
    label: 'importBookings.externalId',
  }
]
