import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form, Image } from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import SearchAndSelect from "../../../components/bookingDetail/bookComponent/SearchAndSelect";
import { Validation, trimPhoneNumber } from '../../../utils/commonFunctions';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as customerAutocompleteAction from '../../../actions/customerAutocompleteAction';
import * as customerActions from '../../../actions/customerAction';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import { Validator, ValidCase } from '../../../components/validator';
import currencyFormatter from 'currency-formatter';
import { TRAVELER_TYPE, userType } from '../../../constants/commondata';
import RadioButton from '../../../components/radioButton/radio';
import { getTravelerTypeTextCommon } from '../../../components/bookingDetail/bookFunction/bookingInfo';
import hide_button from '../../../assets/images/icons/hide.svg';
import show_button from '../../../assets/images/icons/show.svg';
require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

class TravellerInfoForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      phoneStatus: true,
      showMore: false
    };
    this.getSuggestions = _.debounce(this.getSuggestions, 300);
  }

  escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Customer auto complete
  getSuggestions = value => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    let searchObject = this.getParamSearchCustomer(escapedValue);

    if (this.autoCompleteType === 'firstName') {
      searchObject = { ...searchObject, name: '1' };
    } else if (this.autoCompleteType === 'lastName') {
      searchObject = { ...searchObject, name: '1' };
    } else if (this.autoCompleteType === 'email') {
      searchObject = { ...searchObject, email: '1' };
    } else {
      searchObject.str = searchObject.str.replace(/\D/g, '');
    }

    if (searchObject.str.length == 0) {
      return [];
    }
    this.props.customerAutocompleteAction.customerAutocomplete(searchObject).then(data => {
      const regex = new RegExp(`\\b${escapedValue}`, 'i');
      this.setState({
        suggestions: _.get(data.res, 'list', [])
      });
    });
  };

  handleChangePhone = (status, value, countryData, number, id, suggestion) => {
    this.autoCompleteType = 'phone';
    const newNumber = suggestion ? suggestion.newValue : number;
    this.props.onChange({ phone: newNumber });
  };

  handleChangeEmail = (e) => {
    this.props.onChange({ email: e.target.value });
  };

  handleChangeFirstName = (event, { newValue, method }) => {
    this.autoCompleteType = 'firstName';
    this.props.onChange({ firstName: newValue });
  };

  handleChangeLastName = (e) => {
    this.props.onChange({ lastName: e.target.value });
  };

  customerVipClickHandle = () => {
    if(!this.props.psgInfo.rank) {
      this.props.onChange({ rank: 1 });
    } else {
      this.props.onChange({ rank: 0 });
    }
  }

  clearCustomerData = number => {
    const { psgInfo } = this.props;
    this.props.onChange({
      userId: '',
      firstName: psgInfo.firstName ? psgInfo.firstName : '',
      lastName: psgInfo.lastName ? psgInfo.lastName : '',
      phone: number,
      email: '',
      cardHolder: '',
      cardNumber: '',
      expiredDate: '',
      cvv: '',
      city: '',
      state: '',
      cardAddress: '',
      postalCode: '',
      country: '',
      rank: 0,
      outStanding: []
    });
    this.props.clearCorpUserInfo()
    this.props.intercityBookingActions.updateCommonData({
      credits: []
    })
  };

  getParamSearchCustomer = (txtSearch) => {
    return {
      fleetId: this.props.selectedFleet.fleetId,
      str: txtSearch,
      travelerType: getTravelerTypeTextCommon({ travelerType: this.props.travelerType, coporateInfo: this.props.corporateInfo }),
      corporateId: this.props.corporateInfo?._id,
      airlineId: this.props.corporateInfo?._id,
    }
  }

  handleBlurPhone = (status, value, countryData, number, id, suggestion, isCountryCodeOnly) => {
    const { psgInfo } = this.props;
    this.state.phoneStatus = status;
    if (isCountryCodeOnly) {
      this.clearCustomerData('');
      return;
    }
    if (suggestion && suggestion.highlightedSuggestion) {
      this.customerAutocompleteSlectedHandle(null, {
        suggestion: suggestion.highlightedSuggestion
      });
    } else {
      const suggestions = this.state.suggestions.filter(item => trimPhoneNumber(item.phone) === trimPhoneNumber(psgInfo.phone));
      if (suggestions[0]) {
        this.customerAutocompleteSlectedHandle(null, {
          suggestion: suggestions[0]
        });
      } else {
        this.props.customerAutocompleteAction
          .customerAutocomplete(this.getParamSearchCustomer(trimPhoneNumber(number).replace(/\D/g, '')))
          .then(data => {
            if (data.res) {
              const list = _.get(data.res, 'list', [])
              const suggestions = list.filter(item => trimPhoneNumber(item.phone) === trimPhoneNumber(psgInfo.phone));
              if (suggestions[0]) {
                const suggestData = suggestions[0];
                suggestData.phone = number;
                this.customerAutocompleteSlectedHandle(null, {
                  suggestion: suggestData
                });
              }
              else {
                this.clearCustomerData(number);
              }
            } else {
              this.clearCustomerData(number);
            }
          });
      }
    }
  };

  onSuggestionsFetchRequested = data => {
    if (data.reason !== 'input-focused' && !this.props.isBookDetail) {
      this.getSuggestions(data.value)
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };


  getSuggestionValue(suggestion) {
    if (this.autoCompleteType === 'firstName') {
      return suggestion.firstName ? suggestion.firstName : '';
    }
    if (this.autoCompleteType === 'lastName') {
      return suggestion.lastName ? suggestion.lastName : '';
    }
    if (this.autoCompleteType === 'email') {
      return suggestion.email ? suggestion.email : '';
    }
    return suggestion.phone ? suggestion.phone : '';
  }

  renderSuggestion = (suggestion, { query }) => {
    return (
      <div className="suggestion-content">
        <div className="name">
          {suggestion.firstName}
          {' '}
          {suggestion.lastName}
-
          {suggestion.phone}
        </div>
        <div className="email">{suggestion.email}</div>
      </div>
    );
  }


  customerAutocompleteSlectedHandle = (
    event,
    {
      suggestion, suggestionValue, suggestionIndex, sectionIndex, method
    }
  ) => {
    this.state.customer = suggestion ? Object.assign(this.state.customer || {}, suggestion) : null;
    const { fleetId } = this.props.selectedFleet;
    this.props.customerActions
      .getPendingPaymentCustomer({ fleetId, userId: suggestion.userId })
      .then(data => {
        const outStanding = _.get(data, 'res.outStanding', []).filter(o => o.amount > 0);
        const {psgInfo} = this.props;
        this.props.onChange({
          ...psgInfo,
          userId: suggestion.userId,
          firstName: suggestion ? suggestion.firstName : '',
          lastName: suggestion ? suggestion.lastName : '',
          phone: suggestion ? suggestion.phone : '',
          email: suggestion ? suggestion.email : '',
          rank: suggestion ? suggestion.rank : 0,
          outStanding,
          corporateInfo: suggestion.corporateInfo
        })
        this.props.intercityBookingActions.updateCommonData({
          credits: suggestion.credits || []
        })
      });
  };

  renderSelectTravelerType = () => {
    const {
      isBookDetail,
      user,
      selectedFleet,
      corporateCompany,
      travelerType,
      handleChangeTravelerType
    } = this.props;
    return (
      isBookDetail ? (
        <FormGroup>
          <FormControl
            className="form-custom"
            placeholder={I18n.t('newbooking.Corporation')}
            value={
              travelerType === 1
                ? I18n.t('newbooking.Corporation')
                : I18n.t('newbooking.Individual')
            }
            disabled
          />
        </FormGroup>
      ) : (
        (user.userType == userType.FleetAdmin ||
          user.userType == userType.FleetUser) &&
        selectedFleet.moduleSettings.corporate &&
        corporateCompany?.length > 0 && (
          <FormGroup className="radio-button-group">
            <RadioButton
              text={
                I18n.t(
                  'newbooking.Individual'
                )
              }
              value={0}
              onChange={handleChangeTravelerType}
              name="trevellerType"
              id="trevellerType_0"
              checked={travelerType === 0}
            />
            <RadioButton
              text={
                I18n.t(
                  'newbooking.Corporation'
                )
              }
              value={1}
              onChange={handleChangeTravelerType}
              name="trevellerType"
              id="trevellerType_1"
              checked={travelerType === 1 || travelerType === 2}
            />
          </FormGroup>
        )
      )
    )
  }

  renderCorporateList = () => {
    const {
      isBookDetail,
      user,
      corporateInfo,
      corporateCompany,
      travelerType: travelerTypeProp,
      handleChangeCorporateInfo,
    } = this.props;
    if (
      ![TRAVELER_TYPE.Corporate, TRAVELER_TYPE.Airline].includes(travelerTypeProp) ||
      ![userType.FleetAdmin, userType.FleetUser].includes(user.userType)
    )
      return null;
    return isBookDetail ? (
      <FormGroup>
        <FormControl
          type="text"
          className="form-custom"
          value={corporateInfo.name}
          placeholder={I18n.t('newbooking.Corporation')}
          disabled
        />
      </FormGroup>
    ) : (
      <FormGroup>
        <SearchAndSelect
          options={corporateCompany}
          value={corporateInfo.corporateId || corporateInfo?._id}
          onChange={handleChangeCorporateInfo}
          placeholder={I18n.t('newbooking.Corporation')}
          className="form-custom"
          valueKey="_id"
          labelKey="companyInfo.name"
          allowClear={false}
        />
      </FormGroup>
    );
  };

  showMoreCorpUser = () => {
    this.setState({
      showMore: !this.state.showMore,
    });
  };

  renderCorpUserInfo = () => {
    const {
      travelerType,
      selectedFleet,
      corporateUserInfo,
      isBookDetail,
      handleChangeCorpUser: handleChangeCorpUserProp,
      isSubmited,
      validatorCallback,
      valid,
      isViewOnly
    } = this.props;
    const { showMore } = this.state;
    let isShowTravlerCorporate =
      travelerType === TRAVELER_TYPE.Corporate &&
      selectedFleet?.generalSetting?.advanceInfoCorp;
    return (
      <div>
        {isShowTravlerCorporate && showMore && (
          <FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={(e) => handleChangeCorpUserProp('department', e)}
                value={corporateUserInfo.department}
                placeholder={I18n.t('newbooking.Department')}
                disabled={isBookDetail || isViewOnly}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={(e) => handleChangeCorpUserProp('corpDivision', e)}
                value={corporateUserInfo.corpDivision}
                placeholder={I18n.t('newbooking.Corp_division')}
                disabled={isBookDetail}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={(e) => handleChangeCorpUserProp('managerName', e)}
                value={corporateUserInfo.managerName}
                placeholder={I18n.t('newbooking.Manager_name')}
                disabled={isBookDetail || isViewOnly}
                className="form-custom"
              />
            </FormGroup>

            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.Manager_email === false
                  ? 'error'
                  : null
              }
            >
              <FormControl
                type="email"
                onChange={(e) => handleChangeCorpUserProp('managerEmail', e)}
                value={corporateUserInfo.managerEmail}
                placeholder={`${I18n.t('newbooking.Manager_email')}`}
                disabled={isBookDetail || isViewOnly}
                className="form-custom"
              />
              <Validator
                callback={validatorCallback}
                id="Manager_email"
                disabled={travelerType === TRAVELER_TYPE.Individual}
              >
                <ValidCase
                  valid={Validation.validateEmail(
                    corporateUserInfo.managerEmail
                  )}
                  hide={!isSubmited}
                  message={I18n.t(
                    'messages.commonMessages.invalid_email_format'
                  )}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={(e) => handleChangeCorpUserProp('costCentre', e)}
                value={corporateUserInfo.costCentre}
                placeholder={I18n.t('newbooking.Cost_centre')}
                disabled={isBookDetail || isViewOnly}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={(e) => handleChangeCorpUserProp('corpId', e)}
                value={corporateUserInfo.corpId}
                placeholder={I18n.t('newbooking.Corp_id')}
                disabled={isBookDetail || isViewOnly}
                className="form-custom"
              />
            </FormGroup>
          </FormGroup>
        )}
        {isShowTravlerCorporate &&
          (showMore ? (
            <FormGroup
              className="add-extra-des-btn"
              onClick={this.showMoreCorpUser}
            >
              <Image className="add-icon" src={hide_button} />
              <Translate value="newbooking.Hide" />
            </FormGroup>
          ) : (
            <FormGroup
              className="add-extra-des-btn"
              onClick={this.showMoreCorpUser}
            >
              <Image className="add-icon" src={show_button} />
              <Translate value="newbooking.Show_more" />
            </FormGroup>
          ))}
      </div>
    );
  };

  render() {
    const {
      psgInfo,
      valid,
      validatorCallback,
      isSubmited,
      isBookDetail,
      isViewOnly,
    } = this.props;
    const { phoneStatus, suggestions } = this.state;
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.TRAVELER_INFORMATION" />
        </FormGroupTitle>
        {this.renderSelectTravelerType()}
        {this.renderCorporateList()}
        <FormGroup
          className={
            !isSubmited
              ? null
              : valid.phone === false || valid.phoneStatus == false
                ? 'error'
                : null
          }
        >
          <div className="customer-phone">
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control form-custom']}
              utilsScript="libphonenumber.js"
              value={psgInfo.phone}
              onPhoneNumberChange={this.handleChangePhone}
              suggestions={suggestions || []}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={this.renderSuggestion}
              onSuggestionSelected={this.customerAutocompleteSlectedHandle}
              onPhoneNumberBlur={this.handleBlurPhone}
              placeholder={`${I18n.t('newbooking.Phone_number')} *`}
              disabled={isBookDetail || isViewOnly}
            />
            <div className="customer-rank-container">
              <div
                className={psgInfo.rank === 1 ? 'hover rank vip' : 'hover rank'}
                onClick={this.customerVipClickHandle}
              >
                <span>VIP</span>
              </div>
            </div>
          </div>
          <Validator
            callback={validatorCallback}
            id="phone"
          >
            <ValidCase
              valid={!Validation.isStringEmpty(psgInfo.phone)}
              hide
              message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
            />
          </Validator>
          <Validator
            callback={validatorCallback}
            id="phoneStatus"
            disabled={Validation.isStringEmpty(psgInfo.phone) || isBookDetail}
          >
            <ValidCase valid={phoneStatus} hide />
          </Validator>
        </FormGroup>

        <FormGroup
          className={!isSubmited ? null : valid.First_name == false ? 'error' : null}
        >
          <Autosuggest
            suggestions={suggestions || []}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.customerAutocompleteSlectedHandle}
            shouldRenderSuggestions={() => true}
            inputProps={{
              className: 'form-control form-custom',
              value: psgInfo.firstName,
              placeholder: I18n.t('newbooking.First_name') + ' *',
              onChange: this.handleChangeFirstName,
              disabled: isBookDetail || isViewOnly
            }}
          />
          <Validator callback={validatorCallback} id="First_name">
            <ValidCase
              valid={!Validation.isStringEmpty(psgInfo.firstName)}
              message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
              hide
            />
          </Validator>
        </FormGroup>
        <FormGroup
          className={!isSubmited ? null : valid.Last_name == false ? 'error' : null}
        >
          <FormControl
            type="text"
            value={psgInfo.lastName}
            onChange={this.handleChangeLastName}
            className="form-custom"
            placeholder={`${I18n.t('newbooking.Last_name')}`}
            disabled={isBookDetail || isViewOnly}
          />
        </FormGroup>
        <FormGroup className={!isSubmited ? null : valid.email === false ? 'error' : null}>
          <FormControl
            type="text"
            placeholder={I18n.t('newbooking.Email_address')}
            value={psgInfo.email}
            onChange={this.handleChangeEmail}
            className="form-custom"
            disabled={isBookDetail || isViewOnly}
          />

          <Validator callback={validatorCallback} id="email">
            <ValidCase
              hide={!isSubmited}
              valid={Validation.validateEmail(psgInfo.email)}
              message={I18n.t('messages.commonMessages.invalid_email_format')}
            />
          </Validator>
          {psgInfo.outStanding && psgInfo.outStanding.length ? (
            <div className="text-remove">
              {' '}
              <Translate
                value="newbooking.Warning_pending_payment"
                fare={currencyFormatter.format(psgInfo.outStanding[0].amount, {
                  code: psgInfo.outStanding[0].currencyISO,
                })}
              />
              {' '}
            </div>
          ) : null}
        </FormGroup>
        {this.renderCorpUserInfo()}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customerAutocompleteAction: bindActionCreators(customerAutocompleteAction, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TravellerInfoForm);
