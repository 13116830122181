import React from 'react';
import { FormGroup, Col, Form } from 'react-bootstrap';
import UploadFile from '../../../../components/upload/upload';
import { I18n, Translate } from 'react-redux-i18n';
import { ValidCase, Validator } from '../../../../components/validator';
import { Validation } from '../../../../utils/commonFunctions';

const RenderUploadFile = ({
  item,
  handleImageChange,
  filesUpload,
  disabled,
  handleOnShowImage,
  valid,
  isSubmited,
  ValidatorCallback,
  validSize
}) => {
  let fileName = '';
  const files = filesUpload;
  if (files && files[item] && files[item].length > 0) {
    let splitUrl = files[item].split('/');
    fileName = splitUrl[splitUrl.length - 1];
  }

  return (
    <FormGroup
      className={
        !isSubmited
          ? null
          : valid[item] === false
          ? 'error'
          : null
      }
    >
      <Col md={4} xs={12} className={'pl0'}>
        <Form.Label>
          <Translate value={`social.${item}`} />
        </Form.Label>
      </Col>
      <Col md={6} xs={12} className={'pl0'}>
        <div>
          {files && files[item] && typeof files[item] === 'string' ? (
            <a
              onClick={() => handleOnShowImage(files[item], item)}
              className="text-active"
            >
              <Translate value="driver.Click_here_to_view" />
            </a>
          ) : null}
        </div>
        <UploadFile
          id={item}
          name={item}
          accepts='.jpg, .jpeg, .png'
          className="pwa-upload"
          onlyImage
          onChange={(data, error) => {
            handleImageChange(item, data, error);
          }}
          disabled={disabled}
          existFile={
            fileName
              ? {
                  fileName,
                  onRemove: () => {
                    handleImageChange(item);
                  },
                }
              : null
          }
        />
         <Validator
          id={item}
          callback={ValidatorCallback}
        >
          <ValidCase
            valid={validSize[item]}
            message={I18n.t('pwaSettings.errorUploadFileSize').format(
              300
            )}
            hide={!isSubmited}
          />
        </Validator>
      </Col>
    </FormGroup>
  );
};

export default RenderUploadFile;
