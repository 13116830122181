import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, FormControl, Form, InputGroup } from 'react-bootstrap';

import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';

import { ShuttleDispatchMode, CheckMode } from '../../../constants/commondata';
import { Validator, ValidCase } from '../../../components/validator';
import { Validation } from '../../../utils/commonFunctions';

function Shuttle(props) {
  const {
    formData = {},
    shuttleDispatchMode = 0,
    shuttleMinimumBookAhead,
    shuttleMaximumBookAhead,
    editable,
    valid,
    isSubmitted,
    ValidatorCallback,
    checkDispatchMode,
    handleTextInputChange,
    handleChangeShuttleUnit,
  } = props;
  return (
    <div>
      <FormGroupTitle>
        <Translate value="dispatchSetting.Shuttle" />
      </FormGroupTitle>
      <FormGroup>
        <Form.Label>
          <Translate value="dispatchSetting.Shuttle_Dispatch_mode" />
          <QuestionCircleTooltip
            text={
              <Translate
                value="dispatchSetting.Dispatch_setting_Shuttle_Dispatch_mode_tooltip"
                dangerousHTML
              />
            }
          />
        </Form.Label>
        <FormControl
          as="select"
          value={shuttleDispatchMode || 0}
          onChange={(e) => {
            handleTextInputChange('shuttleDispatchMode', e);
          }}
          className={'form-custom'}
          disabled={editable}
        >
          <option value={ShuttleDispatchMode.Manual}>
            {I18n.t('dispatchSetting.Manual')}
          </option>
          {/* <option value={ShuttleDispatchMode.ImmediateAutoDispatch}>
                        {I18n.t("dispatchSetting.Immediate_auto_dispatch")}
                    </option>
                    <option value={ShuttleDispatchMode.DelayedAutoDispatch}>
                        {I18n.t("dispatchSetting.Delayed_auto_dispatch")}
                    </option> */}
        </FormControl>
      </FormGroup>

      {shuttleDispatchMode === ShuttleDispatchMode.Manual && (
        <FormGroup
          className={
            !isSubmitted
              ? null
              : valid.shuttleAutoDispatchBefore === false
              ? 'error'
              : null
          }
        >
          <Form.Label>
            <Translate value="dispatchSetting.Auto_dispatch_before_pickup_time" />
          </Form.Label>
          <FormGroup className={'qup-input-group'}>
            <InputGroup
              className={'single-addon-right' + (editable ? ' disabled' : '')}
            >
              <FormControl
                type="number"
                className={'form-custom number-no-spin'}
                value={formData.shuttleAutoDispatchBefore || ''}
                onChange={(e) => {
                  handleTextInputChange('shuttleAutoDispatchBefore', e);
                }}
                disabled={editable}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <Translate value="dispatchSetting.Mins" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
          <Validator
            id="shuttleAutoDispatchBefore"
            callback={ValidatorCallback}
          >
            <ValidCase
              hide={!isSubmitted}
              valid={Validation.isGreaterThan(
                formData.shuttleAutoDispatchBefore,
                0
              )}
              message={I18n.t('messages.commonMessages.greater_than_0')}
            />
          </Validator>
        </FormGroup>
      )}

      <FormGroup
        className={
          !isSubmitted
            ? null
            : valid.shuttleMinimumBookAhead === false
            ? 'error'
            : null
        }
      >
        <Form.Label>
          <Translate value="dispatchSetting.Minimum_book_ahead" />
        </Form.Label>
        <FormGroup className={'qup-input-group'}>
          <InputGroup
            className={'single-addon-right ' + (editable ? ' disabled' : '')}
          >
            <FormControl
              type="number"
              className={'form-custom number-no-spin'}
              value={
                shuttleMinimumBookAhead
                  ? shuttleMinimumBookAhead.value || ''
                  : ''
              }
              onChange={(e) => {
                handleTextInputChange('shuttleMinimumBookAhead_value', e);
              }}
              disabled={editable}
            />
            <FormControl
              as="select"
              className="input-group-addon-custom form-custom"
              value={
                shuttleMinimumBookAhead && shuttleMinimumBookAhead.unit
                  ? shuttleMinimumBookAhead.unit
                  : 'hour'
              }
              onChange={(e) => {
                handleChangeShuttleUnit('shuttleMinimumBookAhead', e);
              }}
              disabled={editable}
            >
              <option value={'hour'}>{I18n.t('General.hrs')}</option>
              <option value={'minute'}>{I18n.t('General.mins')}</option>
            </FormControl>
          </InputGroup>
        </FormGroup>
        <Validator id="shuttleMinimumBookAhead" callback={ValidatorCallback}>
          <ValidCase
            hide={!isSubmitted}
            valid={!Validation.isStringEmpty(shuttleMinimumBookAhead.value)}
            message={I18n.t('messages.commonMessages.Required_field')}
          />
          {shuttleMinimumBookAhead.unit === 'hour' && (
            <ValidCase
              valid={Validation.isDecimal(shuttleMinimumBookAhead.value)}
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmitted}
            />
          )}
          {shuttleMinimumBookAhead.unit === 'minute' && (
            <ValidCase
              hide={!isSubmitted}
              valid={Validation.isInteger(shuttleMinimumBookAhead.value)}
              message={I18n.t('messages.commonMessages.must_be_integer')}
            />
          )}
          <ValidCase
            hide={!isSubmitted}
            valid={Validation.isGreaterThan(shuttleMinimumBookAhead.value, 0)}
            message={I18n.t('messages.commonMessages.greater_than_0')}
          />
        </Validator>
      </FormGroup>

      <FormGroup
        className={
          !isSubmitted
            ? null
            : valid.shuttleMaximumBookAhead === false
            ? 'error'
            : null
        }
      >
        <Form.Label>
          <Translate value="dispatchSetting.Maximum_book_ahead" />
        </Form.Label>
        <FormGroup className={'qup-input-group'}>
          <InputGroup
            className={'single-addon-right ' + (editable ? ' disabled' : '')}
          >
            <FormControl
              type="number"
              className={'form-custom number-no-spin'}
              value={
                shuttleMaximumBookAhead
                  ? shuttleMaximumBookAhead.value || ''
                  : ''
              }
              onChange={(e) => {
                handleTextInputChange('shuttleMaximumBookAhead_value', e);
              }}
              disabled={editable}
            />
            <FormControl
              as="select"
              className="input-group-addon-custom form-custom"
              value={
                shuttleMaximumBookAhead && shuttleMaximumBookAhead.unit
                  ? shuttleMaximumBookAhead.unit
                  : 'week'
              }
              onChange={(e) => {
                handleTextInputChange('shuttleMaximumBookAhead_unit', e);
              }}
              disabled={editable}
            >
              <option value={'day'}>{I18n.t('dispatchSetting.Day')}</option>
              <option value={'week'}>{I18n.t('dispatchSetting.Week')}</option>
              <option value={'month'}>{I18n.t('dispatchSetting.Month')}</option>
            </FormControl>
          </InputGroup>
        </FormGroup>
        <Validator id="shuttleMaximumBookAhead" callback={ValidatorCallback}>
          <ValidCase
            hide={!isSubmitted}
            valid={
              shuttleMaximumBookAhead
                ? Validation.isInteger(shuttleMaximumBookAhead.value)
                : true
            }
            message={I18n.t('messages.commonMessages.must_be_integer')}
          />
          <ValidCase
            hide={
              !isSubmitted ||
              (shuttleMaximumBookAhead
                ? !Validation.isInteger(shuttleMaximumBookAhead.value)
                : true)
            }
            valid={
              shuttleMaximumBookAhead
                ? Validation.isGreaterThan(shuttleMaximumBookAhead.value, 0)
                : true
            }
            message={I18n.t('messages.commonMessages.greater_than_0')}
          />
        </Validator>
      </FormGroup>
    </div>
  );
}

export default Shuttle;
