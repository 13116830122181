export const ShiftColumnsColumns = [
  {
    key: "Name",
    label: "shiftSettings.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "Time_Range",
    label: "shiftSettings.Time_Range"
  },
  {
    key: "actions",
    label: "shiftSettings.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const ShiftTempleColumns = [
  {
    key: "Name",
    label: "General.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "Mon",
    width: 50,
    label: "General.Mon"
  },
  {
    key: "Tue",
    width: 50,
    label: "General.Tue"
  },
  {
    key: "Wed",
    width: 50,
    label: "General.Wed"
  },
  {
    key: "Thu",
    width: 50,
    label: "General.Thu"
  },
  {
    key: "Fri",
    width: 50,
    label: "General.Fri"
  },
  {
    key: "Sat",
    width: 50,
    label: "General.Sat"
  },
  {
    key: "Sun",
    width: 50,
    label: "General.Sun"
  },
  {
    key: "actions",
    label: "shiftSettings.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

