import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import {
  reportCarActivityApi,
  reportCarActivityLogsApi
} from '../../../constants/ApiConfigs';
import * as reportActions from '../../../actions/reportActions';

class ReportCarActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      list: [],
      total: 0
    };
  }

  showCarActivityLogs = (link, data, e) => {
    const params = data.queryBody;
    params.criteria = params.criteria || {};
    params.criteria.vehicleId = data.row.vehicleId;
    params.criteria.date = moment(params.criteria.month)
      .date(link.key)
      .format('YYYY-MM-DD');
    params.criteria = _.pick(params.criteria, ['date', 'vehicleId', 'timezone']);
    params.from = 0;
    params.size = 1000;
    this.fetchLogsData(params);
  }

  fetchLogsData(params) {
    return this.props.reportActions
      .fetchReportDataFromApi(
        reportCarActivityLogsApi,
        Object.assign(params, {
          fleetId: _.get(this.props, 'selectedFleet.fleetId', null)
        })
      )
      .then(resp => {
        this.setState({
          list: _.get(resp, 'res.list', []),
          total: _.get(resp, 'res.total', 0),
          showModal: true
        });
      })
      .catch(error => {});
  }

  renderTableContent() {
    if (this.state.total == 0) {
      return (
        <div className="no-item-was-found">
          <Translate value="report.result.noResultFound" />
        </div>
      );
    }
    return (
      <div className="modal-report-table-container">
        <table className="table table-no-pagination cc-table-striped">
          <thead>
            <tr>
              {fieldMappings.logFields.map(item => (
                <th className="modal-report-table-header" style={{ padding: 20 }}>
                  <Translate value={item.label} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((doc, index) => (
              <tr>
                {fieldMappings.logFields.map(item => (
                  <td className="modal-report-table-row-content" style={{ padding: 20 }}>
                    {(item.mutate && item.mutate(doc[item.key])) || doc[item.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const carTypes = this.props.carType.map(carTypeObj => ({ label: carTypeObj.vehicleType, value: carTypeObj.vehicleType }));
    carTypes.unshift({ value: 'all', label: I18n.t('report.query.common.all') });
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules,{"name":'Report'});
    let checkExport = _.findIndex(report.actions,{"name":"Car's activity","Export":true});
    let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
    checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
    if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
      checkExport = false;
    }
    return (
      <div className="content">
        <Modal
          show={this.state.showModal}
          backdrop="static"
          dialogClassName="confirm-dialog"
          bsSize="lg"
          dialogClassName="logs-modal"
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="report.result.carActivityLogsTitle" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderTableContent()}</Modal.Body>
        </Modal>
        <BaseReport
          tittle="CarActivity"
          fieldMappings={fieldMappings}
          apiurl={reportCarActivityApi}
          timezone
          company
          carType={carTypes}
          plate
          month
          search="report.query.search.carActivity"
          actions={{ showCarActivityLogs: this.showCarActivityLogs }}
          noexport={!checkExport}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    companies: state.commonData.companies,
    suppliers: state.commonData.suppliers,
    drivers: state.commonData.drivers,
    vehicles: state.commonData.vehicles,
    carType: state.commonData.carType,
    user: state.auth.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCarActivity);
