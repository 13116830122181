import React from 'react';
import CCDropDown from '../../../components/dropdown/ccDropDown';

export const topButtons = [
  {
    value: 20,
    key: 'thisYear',
    label: 'smartData.query.top20'
  },
  {
    value: 50,
    key: '50',
    label: 'smartData.query.top50'
  },
  {
    value: 100,
    key: '100',
    label: 'smartData.query.top100'
  },
  {
    value: -20,
    key: '-20',
    label: 'smartData.query.bottom20'
  },
  {
    value: -50,
    key: '-50',
    label: 'smartData.query.bottom50'
  },
  {
    value: -100,
    key: '-100',
    label: 'smartData.query.bottom100'
  }
];

export default function (props) {
  return (
    <CCDropDown
      id="date-range222-dropdown"
      items={topButtons}
      title=""
      selectedItems={props.selectedItem ? [props.selectedItem] : []}
      valueKey="value"
      labelKey="label"
      onSelect={props.onSelect}
      className="daterange-dropdown text-active"
      disabled={props.disabled}
    />
    
  );
}
