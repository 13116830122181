import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Tab,
  Tabs,
  ButtonToolbar,
  Row,
  Col,
} from 'react-bootstrap';
import { GithubPicker } from 'react-color';
import PropTypes from 'prop-types';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import IntlTelInputApp from '../../components/intlTelInputCustome/IntlTelInputApp';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import UploadFile from '../../components/upload/upload';
import UploadImageTemplate from '../../components/upload/UploadImageTemplate';
import Confirm from '../../components/confirm/Confirm';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  trimPhoneNumber,
} from '../../utils/commonFunctions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import _ from 'lodash';
import {
  operateCorporate,
  resetPasswordCorporate,
} from '../../actions/corporateAction';
import { userType } from '../../constants/commondata';
import QuestionCircleTooltip from '../../components/questionCircleTooltip/QuestionCircleTooltip';
import './style.scss';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const corporateCommissionType = {
  PERCENT: 'Percent',
  AMOUNT: 'Amount',
};

class Add extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      commissionByCurrencies: [],
      commissionType: corporateCommissionType.PERCENT,
      commission: false,
      isCommissionBookingApp: false,
      isImportBooking: false,
      isImportDeliveryBooking: false,
      businessProfileOnly: false,
      isCreateRoaming: false,
      isSubmitted: false,
      isEditting: false,
      phoneValidation: true,
      showColorPicker: false,
      isCorporateVip: false,
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleSaveCorporateClick = this.handleSaveCorporateClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.handleCompanyIdChange = this.handleCompanyIdChange.bind(this);
    this.handleCompanyAddressChange =
      this.handleCompanyAddressChange.bind(this);
    this.handleInvoiceEmailChange = this.handleInvoiceEmailChange.bind(this);
    this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.renderCommistion = this.renderCommistion.bind(this);
    this.handleCommissionTypeChange =
      this.handleCommissionTypeChange.bind(this);
    this.handleCommissionChange = this.handleCommissionChange.bind(this);
    this.handleCommissionAbleChange =
      this.handleCommissionAbleChange.bind(this);
    this.handleTravelerSignatureChange =
      this.handleTravelerSignatureChange.bind(this);
    this.handleParcelDeliveryChange =
      this.handleParcelDeliveryChange.bind(this);
    /* this.handleTrackingLogChange = this.handleTrackingLogChange.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this); */
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleVATNumberChange = this.handleVATNumberChange.bind(this);
    this.handleColorCodeBookingChange =
      this.handleColorCodeBookingChange.bind(this);
  }

  componentDidMount() {
    const defaultPercentCommission = [
      {
        commissionValue: 0,
        currencyISO: '',
      },
    ];
    const defaultAmountCommission = [];
    this.props.auth.selectedFleet &&
      this.props.auth.selectedFleet.currencies.map((item) => {
        defaultAmountCommission.push({
          commissionValue: 0,
          currencyISO: item.iso,
        });
      });

    if (this.props.data) {
      this.setState(
        Object.assign(
          {},
          this.props.data,
          {
            percentCommission:
              this.props.data.commission &&
              this.props.data.commissionType === corporateCommissionType.PERCENT
                ? this.props.data.commissionByCurrencies
                : defaultPercentCommission,
          },
          {
            amountCommission:
              this.props.data.commission &&
              this.props.data.commissionType === corporateCommissionType.AMOUNT
                ? this.props.data.commissionByCurrencies
                : defaultAmountCommission,
          }
        )
      );
    } else {
      this.setState({
        commissionByCurrencies: defaultPercentCommission,
        percentCommission: defaultPercentCommission,
        amountCommission: defaultAmountCommission,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isSubmitted: false, phoneValidation: true });
    if (nextProps.data) {
      this.setState(nextProps.data);
    }
  }

  handleCompanyNameChange(e) {
    this.setState({ companyName: e.target.value });
  }

  handleCompanyIdChange(e) {
    this.setState({ companyId: e.target.value });
  }

  handleCompanyTypeChange = (e) => {
    this.setState({
      isAirline: e.target.value === '1',
    });
  };

  handleCompanyAddressChange(e) {
    this.setState({ companyAddress: e.target.value });
  }

  handleInvoiceEmailChange(e) {
    this.setState({ invoiceEmail: e.target.value });
  }

  handleConfirmEmailChange(e) {
    this.setState({ confirmEmail: e.target.value });
  }

  handleVATNumberChange(e) {
    this.setState({ vATNumber: e.target.value });
  }

  handleColorCodeBookingChange(value) {
    this.setState({ colorCodeBooking: value });
  }

  setFocusColorCodeBooking = () => {
    this.setState({
      showColorPicker: true,
    });
  };

  onBlurColorCodeBooking = () => {
    this.setState({
      showColorPicker: false,
    });
  };

  handleUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  handleAliasUrlChange = (e) => {
    this.setState({ aliasUrl: e.target.value });
  };

  handlePhoneChange(status, value, countryData, number, id, isBlur) {
    this.setState({
      phoneNumber: number,
      phoneValidation: status,
    });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleSaveCorporateClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    const requestData = {
      _id: this.state._id,
      fleetId: this.props.auth.selectedFleet.fleetId,
      companyName: this.state.companyName,
      companyId: this.state.companyId,
      isAirline: this.state.isAirline || false,
      companyAddress: this.state.companyAddress,
      invoiceEmail: this.state.invoiceEmail || '',
      confirmEmail: this.state.confirmEmail || '',
      vATNumber: this.state.vATNumber,
      username: (this.state.username && this.state.username.trim()) || '',
      firstName: this.state.firstName || '',
      lastName: this.state.lastName || '',
      phoneNumber: trimPhoneNumber(this.state.phoneNumber),
      email: this.state.email,
      commission: this.state.commission,
      registeredFrom: 'CC',
      commissionValue: this.state.commissionValue,
      commissionType: this.state.commissionType,
      isCommissionBookingApp: this.state.isCommissionBookingApp,
      isImportBooking: this.state.isImportBooking,
      isImportDeliveryBooking: this.state.isImportDeliveryBooking,
      businessProfileOnly: this.state.businessProfileOnly,
      isCreateRoaming: this.state.isAirline
        ? false
        : this.state.isCreateRoaming,
      commissionByCurrencies: this.state.commissionByCurrencies || [],
      travellerSignature: this.state.travellerSignature,
      enableParcelDelivery: this.state.enableParcelDelivery,
      isCorporateVip: this.state.isCorporateVip || false,
      /* rating: this.state.rating,
      trackingLog: this.state.trackingLog, */
      colorCodeBooking: this.state.colorCodeBooking || '',
      aliasUrl: this.state.aliasUrl || '',
      backgroundCustomize: this.state.backgroundCustomize || '',
      logoCustomize: this.state.logoCustomize || '',
    };
    if (
      requestData.commissionType === corporateCommissionType.AMOUNT &&
      requestData.commissionByCurrencies.length === 0
    ) {
      requestData.commissionByCurrencies = [
        {
          currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
          commissionValue: requestData.commissionValue,
        },
      ];
    }
    this.props.operateCorporate(requestData).then((data) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (data.ok) {
        if (this.state._id) {
          this.context.notification(
            'success',
            I18n.t('corporate.INFO_UPDATE_CORPORATE_SUCCESS')
          );
        } else {
          this.context.notification(
            'success',
            I18n.t('corporate.INFO_ADD_CORPORATE_SUCCESS').format(
              this.state.username,
              this.state.email
            )
          );
        }
        this.props.router.push({
          pathname: '/corporate',
        });
      } else {
        this.setState({ isSubmitted: false });
        this.context.notification(
          'error',
          I18n.t(`errors.${data.error.errorCode}`)
        );
      }
    });
  }

  handleResetPasswordClick() {
    this.setState({
      confirm: {
        title: I18n.t('corporate.Reset_password'),
        body: I18n.t('corporate.COMFIRM_RESET_PASSWORD'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      },
    });
  }

  handleConfirmButtonClick() {
    this.setState({ confirm: null });
    this.props
      .resetPasswordCorporate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        corpId: this.props.corporateId,
        _id: this.state.corporateUserId,
      })
      .then((data) => {
        if (data.ok) {
          this.context.notification(
            'success',
            I18n.t('corporate.INFO_RESET_PASSWORD_SUCCESS').format(
              this.state.username,
              this.state.email
            )
          );
          this.props.router.push({
            pathname: '/corporate',
          });
        } else {
          this.setState({ isSubmitted: false });
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        }
      });
  }

  handleConfirmCloseClick() {
    this.setState({
      confirm: null,
    });
  }

  handleCancelClick() {
    this.props.router.push({
      pathname: '/corporate',
    });
  }

  handleCommissionAbleChange(e) {
    let { commissionByCurrencies } = this.state;
    let { commissionType } = this.state;
    if (!commissionByCurrencies) {
      commissionByCurrencies = this.state.percentCommission;
      commissionType = corporateCommissionType.PERCENT;
    }
    this.setState({
      commission: e.target.checked,
      commissionByCurrencies,
      commissionType,
    });
  }

  handleCommissionBookingApp = (e) => {
    this.setState({
      isCommissionBookingApp: e.target.checked,
    });
  };

  handleChangeValueCorporate = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleImportBooking = (e) => {
    this.setState({
      isImportBooking: e.target.checked,
    });
  };

  handleImportDeliveryBooking = (e) => {
    this.setState({
      isImportDeliveryBooking: e.target.checked,
    });
  };

  handleBusinessProfileOnly = (e) => {
    this.setState({
      businessProfileOnly: e.target.checked,
    })
  }

  handleCreateRoamingChange = (e) => {
    this.setState({
      isCreateRoaming: e.target.checked,
    });
  };

  handleCommissionTypeChange(e) {
    this.setState({
      commissionByCurrencies:
        e.target.value === corporateCommissionType.AMOUNT
          ? this.state.amountCommission
          : this.state.percentCommission,
      commissionType: e.target.value,
    });
  }

  handleLogoChange(data, error) {
    let result = '',
      file = null;

    if (data) {
      result = data.result;
      file = data.file;
    }

    this.setState({
      logoCustomize: result,
    });
  }

  handleBgImageChange(data, error) {
    let result = '',
      file = null;

    if (data) {
      result = data.result;
      file = data.file;
    }

    this.setState({
      backgroundCustomize: result,
    });
  }

  handleRemoveLogoCorp = () => {
    this.setState({
      logoCorporate: '',
      newLogoCorporate: '',
    });
  };

  handleRemoveBackgroundCorp = () => {
    this.setState({
      backgroundCorporate: '',
      newBackgroundCorporate: '',
    });
  };

  handleCommissionChange(e, iso) {
    const numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    const com = e.target.value;
    if (numberWithDotReg.test(com) || com === '') {
      if (iso) {
        // amount
        let { commissionByCurrencies } = this.state;
        if (
          !commissionByCurrencies ||
          (this.props.auth.selectedFleet &&
            this.props.auth.selectedFleet.currencies.length <= 1)
        ) {
          commissionByCurrencies = [];
        }
        const index = commissionByCurrencies.find((x) => x.currencyISO == iso);
        if (index) {
          commissionByCurrencies = commissionByCurrencies.map((x) => {
            if (x.currencyISO == iso) {
              x.commissionValue = com;
            }
            return x;
          });
        } else {
          commissionByCurrencies.push({
            commissionValue: com,
            currencyISO: iso,
          });
        }
        this.setState({
          commissionByCurrencies,
        });
      } else {
        // percent
        const commissionByCurrencies = [];
        commissionByCurrencies.push({
          commissionValue: com,
          currencyISO: '',
        });
        this.setState({ commissionByCurrencies });
      }
    }
  }

  handleTravelerSignatureChange(e) {
    this.setState({ travellerSignature: e.target.checked });
  }
  handleParcelDeliveryChange(e) {
    const checked = e.target.checked;
    const newState = { enableParcelDelivery: checked };
    if (!checked) {
      newState.isImportDeliveryBooking = false;
    }
    this.setState(newState);
  }

  /*   handleTrackingLogChange(e) {
    this.setState({ trackingLog: e.target.checked });
  }

  handleRatingChange(e) {
    this.setState({ rating: e.target.checked });
  } */

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  renderCommistion() {
    const { commissionByCurrencies } = this.state;
    const editable = !this.props.permissions || this.props.permissions.actions;
    const isCorpAdmin = this.props.auth.user.userType === 'CorporateAdmin';
    if (
      this.state.commission &&
      this.props.auth.selectedFleet &&
      commissionByCurrencies
    ) {
      const { length } = this.props.auth.selectedFleet.currencies;
      const symbol =
        this.state.commissionType === corporateCommissionType.PERCENT
          ? '%'
          : this.props.auth.selectedFleet.currencies[0].symbol;
      if (length <= 1) {
        return (
          <React.Fragment>
            <Col xs={12} sm={6} md={4} className="car-left">
              <FormGroup>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={this.state.commissionType}
                  placeholder="Select Fleet Commission"
                  onChange={this.handleCommissionTypeChange}
                  disabled={!editable || isCorpAdmin}
                >
                  <option value={corporateCommissionType.PERCENT}>
                    {I18n.t('driver.Percentage')}
                  </option>
                  <option value={corporateCommissionType.AMOUNT}>
                    {I18n.t('driver.Amount')}
                  </option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4} className="car-right">
              <FormGroup
                className={
                  this.state.isSubmited
                    ? this.state.valid.commission
                      ? null
                      : 'error'
                    : null
                }
              >
                <FormGroup className="addon-inner">
                  <div className="addon-icon">{symbol}</div>
                  <FormControl
                    className="form-custom"
                    type="text"
                    onChange={(e) => {
                      if (
                        this.state.commissionType ===
                        corporateCommissionType.PERCENT
                      ) {
                        this.handleCommissionChange(e);
                      } else {
                        this.handleCommissionChange(
                          e,
                          this.props.auth.selectedFleet.currencies[0].iso
                        );
                      }
                    }}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(e, (e) => {
                        if (
                          this.state.commissionType ===
                          corporateCommissionType.PERCENT
                        ) {
                          this.handleCommissionChange(e);
                        } else {
                          this.handleCommissionChange(
                            e,
                            this.props.auth.selectedFleet.currencies[0].iso
                          );
                        }
                      });
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(e, (e) => {
                        if (
                          this.state.commissionType ===
                          corporateCommissionType.PERCENT
                        ) {
                          this.handleCommissionChange(e);
                        } else {
                          this.handleCommissionChange(
                            e,
                            this.props.auth.selectedFleet.currencies[0].iso
                          );
                        }
                      });
                    }}
                    value={
                      commissionByCurrencies[0]
                        ? commissionByCurrencies[0].commissionValue
                        : '0'
                    }
                    disabled={!editable || isCorpAdmin}
                  />
                </FormGroup>
                <WarningFeeInput
                  message={I18n.t('message.warningInputChangeOver')}
                  className="text-soft-warning"
                  value={
                    commissionByCurrencies[0]
                      ? commissionByCurrencies[0].commissionValue
                      : '0'
                  }
                  disabled={!editable || isCorpAdmin}
                  timeDelay={500}
                  typeOfCheck="higherOfLower"
                />
                <Validator id="commission" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={Validation.isNumber(this.state.commission)}
                    message={I18n.t('driver.ERROR_COMMISSION_NUMBERS')}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <Col xs={12} sm={6} md={4}>
            <FormGroup>
              <FormControl
                className="form-custom"
                as="select"
                value={this.state.commissionType}
                placeholder="Select Fleet Commission"
                onChange={this.handleCommissionTypeChange}
                disabled={!editable}
              >
                <option value={corporateCommissionType.PERCENT}>
                  {I18n.t('driver.Percentage')}
                </option>
                <option value={corporateCommissionType.AMOUNT}>
                  {I18n.t('driver.Amount')}
                </option>
              </FormControl>
            </FormGroup>
          </Col>
          {this.state.commissionType == corporateCommissionType.PERCENT ? (
            <Col xs={12} sm={6} md={4}>
              <FormGroup className="qup-input-group">
                <InputGroup className="single-addon-right">
                  <InputGroup.Prepend>
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={
                      commissionByCurrencies[0]
                        ? commissionByCurrencies[0].commissionValue
                        : 0
                    }
                    onChange={this.handleCommissionChange}
                    disabled={!editable || isCorpAdmin}
                  />
                </InputGroup>
              </FormGroup>
              <WarningFeeInput
                message={I18n.t('message.warningInputChangeOver')}
                className="text-soft-warning"
                value={
                  commissionByCurrencies[0]
                    ? commissionByCurrencies[0].commissionValue
                    : 0
                }
                disabled={!editable || isCorpAdmin}
                timeDelay={500}
                typeOfCheck="higherOfLower"
              />
            </Col>
          ) : (
            <Col xs={12} className="p0">
              <Col xs={12} sm={6} md={4}>
                <Tabs id="uncontrolled-tab-example">
                  {this.props.auth.selectedFleet.currencies.map((c, i) => {
                    const currency = commissionByCurrencies.filter(
                      (x) => x.currencyISO == c.iso
                    );
                    let currencyValue = '';
                    if (currency[0]) {
                      currencyValue = currency[0].commissionValue;
                    }
                    return (
                      <Tab eventKey={i} title={c.iso}>
                        <FormGroup className="qup-input-group">
                          <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                              <InputGroup.Text>{c.symbol}</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              className="form-custom"
                              type="text"
                              value={currencyValue}
                              onChange={(e) => {
                                this.handleCommissionChange(e, c.iso);
                              }}
                              disabled={!editable || isCorpAdmin}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Tab>
                    );
                  })}
                </Tabs>
                <WarningFeeInput
                  message={I18n.t('message.warningInputChangeOver')}
                  className="text-soft-warning"
                  disabled={!editable || isCorpAdmin}
                  value={
                    !commissionByCurrencies || !commissionByCurrencies.length
                      ? null
                      : commissionByCurrencies.map((fees) => {
                          const { currencyISO, commissionValue } = fees;
                          return {
                            [currencyISO]: commissionValue,
                          };
                        })
                  }
                  timeDelay={500}
                  typeOfCheck="higherOfLower"
                />
              </Col>
            </Col>
          )}
        </React.Fragment>
      );
    }
  }

  render() {
    const userTypeLogin = this.props.auth?.user?.userType;
    const isCorpAdmin = userTypeLogin === 'CorporateAdmin';
    const isCorporateBoard = [
      userType.CorporateAdmin,
      userType.CorporateUser,
    ].includes(userTypeLogin);
    const editable = !this.props.permissions || this.props.permissions.actions;
    const {
      delivery = {},
      generalSetting = {},
      affiliate = {},
    } = this.props.auth.selectedFleet || {};
    const importChauffeurBooking = generalSetting.importChauffeurBooking;
    const importDeliveryBooking =
      generalSetting.importDeliveryBooking &&
      delivery.enable &&
      delivery.corporate &&
      this.state.enableParcelDelivery &&
      !this.state.isAirline;
    const enableHydraDispatch = affiliate.dispatching;
    const s3Host = _.get(this.props.auth, 'selectedFleet.s3Host', '');
    return (
      <div
        className={`content form-detail-container cru-corporate-form pb0 ${
          this.props.className ? ` ${this.props.className}` : ''
        }`}
      >
        <div className="cru-corporate-child">
          <Row>
            <Col xs={12}>
              <FormGroupTitle>
                <Translate value="corporate.COMPANY_INFORMATION" />
              </FormGroupTitle>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.companyName === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.Company_name" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleCompanyNameChange}
                  value={this.state.companyName}
                  disabled={isCorpAdmin || !editable}
                  autoFocus
                />
                <Validator id="companyName" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.companyName)}
                    message={I18n.t('corporate.ERROR_INPUT_COMPANYNAME')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.Company_Type" />
                </Form.Label>
                <FormControl
                  as="select"
                  className={'form-custom'}
                  value={this.state.isAirline ? 1 : 0}
                  onChange={this.handleCompanyTypeChange}
                  disabled={this.props.data && this.props.data._id}
                >
                  <option value={0} key="commission">
                    {I18n.t('corporate.Standard')}
                  </option>
                  <option value={1} key="fixAmount">
                    {I18n.t('corporate.Airline')}
                  </option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.Company_ID" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={this.state.companyId}
                  onChange={this.handleCompanyIdChange}
                  disabled={!editable}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.Company_address" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={this.state.companyAddress}
                  onChange={this.handleCompanyAddressChange}
                  disabled={!editable}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.invoiceEmail === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.Invoice_email" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleInvoiceEmailChange}
                  value={this.state.invoiceEmail}
                  placeholder="email01@example.com;email02@example.com"
                  disabled={!editable}
                />
                <Validator id="invoiceEmail" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      Validation.isStringEmpty(this.state.invoiceEmail) ||
                      Validation.validateMultiEmail(this.state.invoiceEmail)
                    }
                    message={I18n.t('corporate.ERROR_INPUT_VALID_EMAIL')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.confirmEmail === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.Confirmation_email" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleConfirmEmailChange}
                  value={this.state.confirmEmail}
                  placeholder="email01@example.com;email02@example.com"
                  disabled={!editable}
                />
                <Validator id="confirmEmail" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      Validation.isStringEmpty(this.state.confirmEmail) ||
                      Validation.validateMultiEmail(this.state.confirmEmail)
                    }
                    message={I18n.t('corporate.ERROR_INPUT_VALID_EMAIL')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.vATNumber === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.VAT_number" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleVATNumberChange}
                  value={this.state.vATNumber}
                  disabled={!editable}
                  maxLength={50}
                />
              </FormGroup>
            </Col>
            {!isCorpAdmin && (
              <Col xs={12} sm={6} md={4}>
                <FormGroup
                  className={
                    !this.state.isSubmitted
                      ? null
                      : this.state.valid.colorCodeBooking === false
                      ? 'error'
                      : null
                  }
                  className={'qup-input-group'}
                >
                  <Form.Label>
                    <Translate value="corporate.colorCodeBooking" />
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <div
                          style={{
                            backgroundColor: this.state.colorCodeBooking,
                            width: '20px',
                            height: '20px',
                          }}
                        ></div>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onFocus={() => this.setFocusColorCodeBooking()}
                      onBlur={() => this.onBlurColorCodeBooking()}
                      // onChange={this.handleColorCodeBookingChange}
                      value={
                        this.state.colorCodeBooking
                          ? this.state.colorCodeBooking
                          : ''
                      }
                      disabled={!editable}
                      maxLength={10}
                    />
                    <InputGroup.Append className="color-code-booking">
                      <InputGroup.Text>
                        {this.state.colorCodeBooking && (
                          <i
                            className="fa fa-trash fa-2x"
                            onClick={(e) => {
                              this.handleColorCodeBookingChange('');
                            }}
                          />
                        )}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <div className="colorPicker">
                    {this.state.showColorPicker && (
                      <GithubPicker
                        width={'90%'}
                        color={
                          this.state.colorCodeBooking
                            ? this.state.colorCodeBooking
                            : '#fff'
                        }
                        onSwatchHover={(updatedColor) =>
                          this.handleColorCodeBookingChange(updatedColor.hex)
                        }
                      />
                    )}
                  </div>
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row className="mb-lg">
            <Col xs={12}>
              <FormGroupTitle>
                <Translate value="corporate.ADMIN_ACCOUNT" />
              </FormGroupTitle>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.username === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.Username" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleUsernameChange}
                  value={this.state.username}
                  disabled={this.props.data && this.props.data._id}
                />
                <Validator id="username" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.username)}
                    message={I18n.t('corporate.ERROR_INPUT_USERNAME')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.firstName === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.First_name" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleFirstNameChange}
                  value={this.state.firstName}
                  disabled={!editable}
                />
                <Validator id="firstName" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.firstName)}
                    message={I18n.t('corporate.ERROR_INPUT_FIRSTNAME')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.Last_name" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={this.state.lastName}
                  onChange={this.handleLastNameChange}
                  disabled={!editable}
                />
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.phone
                    ? null
                    : 'error'
                }
              >
                <Form.Label>
                  <Translate value="corporate.Phone_Number" />
                </Form.Label>
                <FormGroup>
                  <IntlTelInputApp
                    css={['intl-tel-input', 'form-control']}
                    utilsScript="libphonenumber.js"
                    value={this.state.phoneNumber}
                    onPhoneNumberChange={this.handlePhoneChange}
                    disabled={!editable}
                  />
                  <Validator id="phone" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmitted}
                      valid={this.state.phoneValidation}
                      message={I18n.t('customer.ERROR_INPUT_VALIDPHONE')}
                    />
                  </Validator>
                </FormGroup>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <FormGroup
                className={
                  !this.state.isSubmitted
                    ? null
                    : this.state.valid.email === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="corporate.Email" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  onChange={this.handleEmailChange}
                  value={this.state.email}
                  disabled={!editable}
                />
                <Validator id="email" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.email)}
                    message={I18n.t('corporate.ERROR_INPUT_EMAIL')}
                  />
                  <ValidCase
                    hide={!this.state.isSubmitted}
                    valid={
                      Validation.isStringEmpty(this.state.email) ||
                      Validation.validateEmail(this.state.email)
                    }
                    message={I18n.t('corporate.ERROR_INPUT_VALID_EMAIL')}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroupTitle>
                <Translate value="corporate.Branding" />
              </FormGroupTitle>
            </Col>
            <Col xs={4}>
              <div className="imgCustomize">
                <FormGroup>
                  <Form.Label>
                    <Translate value="corporate.logoImage" />
                  </Form.Label>
                  <UploadFile
                    id="logoCustomize"
                    name="paxApp.urlImage1"
                    onChange={(data, error) => {
                      this.handleLogoChange(data, error);
                    }}
                    customTemplate={UploadImageTemplate}
                    customTemplateProps={{
                      fileResult: this.state.logoCustomize
                        ? this.state.logoCustomize.includes('corporate')
                          ? `${s3Host}${this.state.logoCustomize}`
                          : this.state.logoCustomize
                        : null,
                    }}
                    className="app-banner-upload-image imgCorpCt"
                    accepts="image/*"
                    onlyImage
                    // disabled={!hasPermission}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label>
                    <Translate value="corporate.bgImage" />
                  </Form.Label>
                  <UploadFile
                    id="bgCustomize"
                    name="paxApp.urlImage1"
                    onChange={(data, error) => {
                      this.handleBgImageChange(data, error);
                    }}
                    customTemplate={UploadImageTemplate}
                    customTemplateProps={{
                      fileResult: this.state.backgroundCustomize
                        ? this.state.backgroundCustomize.includes('corporate')
                          ? `${s3Host}${this.state.backgroundCustomize}`
                          : this.state.backgroundCustomize
                        : null,
                    }}
                    className="app-banner-upload-image imgCorpCt"
                    accepts="image/*"
                    onlyImage
                    // disabled={!hasPermission}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs={4}>
              <FormGroup>
                <Form.Label>
                  <Translate value="corporate.aliasTitle" />
                </Form.Label>
                <div className="aliasUrl">
                  <span>{`${window.origin}/?corp=`}</span>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.aliasUrl === false
                        ? 'error'
                        : null
                    }
                  >
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={this.state.aliasUrl}
                      onChange={this.handleAliasUrlChange}
                      disabled={!editable}
                      placeholder={'Enter name'}
                    />
                    <Validator id="aliasUrl" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          this.state.aliasUrl === '' ||
                          /^[a-zA-Z0-9]+$/.test(this.state.aliasUrl)
                        }
                        message={I18n.t('Allow Only numbers and characters')}
                      />
                    </Validator>
                  </FormGroup>
                </div>
              </FormGroup>
            </Col>
          </Row>
          {!isCorporateBoard && (
            <Row>
              {this.props.corporateInfo.travellerSignature ? (
                <Col xs={12}>
                  <FormGroup>
                    <CcCheckbox
                      className="checkbox-margin"
                      checked={this.state.travellerSignature}
                      onChange={this.handleTravelerSignatureChange}
                      disabled={!editable || isCorpAdmin}
                      text={<Translate value="corporate.Traveler_signature" />}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              {this.props.auth.selectedFleet.delivery.corporate &&
              !this.state.isAirline ? (
                <Col xs={12}>
                  <FormGroup>
                    <CcCheckbox
                      className="checkbox-margin"
                      checked={this.state.enableParcelDelivery}
                      onChange={this.handleParcelDeliveryChange}
                      disabled={!editable || isCorpAdmin}
                      text={<Translate value="corporate.Parcel_Delivery" />}
                    />
                  </FormGroup>
                </Col>
              ) : null}
              <Col xs={12}>
                <FormGroup>
                  <CcCheckbox
                    className="checkbox-margin"
                    checked={this.state.commission}
                    onChange={this.handleCommissionAbleChange}
                    disabled={!editable || isCorpAdmin}
                    text={<Translate value="corporate.Commission" />}
                  />
                </FormGroup>
              </Col>
              {this.renderCommistion()}
              <Col xs={12}>
                <FormGroup>
                  <CcCheckbox
                    className="checkbox-margin"
                    checked={this.state.isCorporateVip}
                    onChange={(e) =>
                      this.handleChangeValueCorporate(
                        'isCorporateVip',
                        e.target.checked
                      )
                    }
                    disabled={!editable || isCorpAdmin}
                    text={
                      <span>
                        <Translate value="corporate.isCorporateVip" />{' '}
                        <QuestionCircleTooltip
                          text={
                            <Translate value="corporate.isCorporateVipToolTip" />
                          }
                        />
                      </span>
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup>
                  <CcCheckbox
                    className="checkbox-margin"
                    checked={this.state.isCommissionBookingApp}
                    onChange={this.handleCommissionBookingApp}
                    disabled={!editable || isCorpAdmin}
                    text={<Translate value="corporate.commissionBooking" />}
                  />
                </FormGroup>
              </Col>
              {importChauffeurBooking && (
                <Col xs={12}>
                  <FormGroup>
                    <CcCheckbox
                      className="checkbox-margin"
                      checked={this.state.isImportBooking}
                      onChange={this.handleImportBooking}
                      disabled={!editable || isCorpAdmin}
                      text={
                        <Translate value="corporate.importChauffeurBooking" />
                      }
                    />
                  </FormGroup>
                </Col>
              )}
              {importDeliveryBooking && (
                <Col xs={12}>
                  <FormGroup>
                    <CcCheckbox
                      className="checkbox-margin"
                      checked={this.state.isImportDeliveryBooking}
                      onChange={this.handleImportDeliveryBooking}
                      disabled={!editable || isCorpAdmin}
                      text="Able to import delivery bookings"
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12}>
                <FormGroup>
                  <CcCheckbox
                    className="checkbox-margin"
                    checked={this.state.businessProfileOnly}
                    onChange={this.handleBusinessProfileOnly}
                    disabled={!editable || isCorpAdmin}
                    text={
                      <span>
                        Business Profile Only{' '}
                        <QuestionCircleTooltip
                          text={
                            'If enabled, travelers can only book via their business profile and book with corporate rates'
                          }
                        />
                      </span>
                    }
                  />
                </FormGroup>
              </Col>
              {enableHydraDispatch && !this.state.isAirline && (
                <Col xs={12}>
                  <FormGroup>
                    <CcCheckbox
                      className="checkbox-margin"
                      checked={this.state.isCreateRoaming}
                      onChange={this.handleCreateRoamingChange}
                      disabled={!editable || isCorpAdmin}
                      text="Allow to create farm-out bookings"
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          )}

          <div className="text-require-page">
            (<span className="require">*</span>
            ): <Translate value="corporate.Required_fields" />
          </div>
          <ButtonToolbar className="text-center mv-md">
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className="btn-save mr-md"
                onClick={this.handleSaveCorporateClick}
              >
                <Translate value="corporate.Save" />
              </Button>
            ) : null}
            {!isCorpAdmin ? (
              <React.Fragment>
                <Button
                  className="btn-cancel mr-md"
                  onClick={this.handleCancelClick}
                >
                  <Translate value="corporate.Cancel" />
                </Button>
                {editable && this.state._id ? (
                  <Button
                    className="btn-reset"
                    onClick={this.handleResetPasswordClick}
                  >
                    <Translate value="corporate.Reset_password" />
                  </Button>
                ) : null}
              </React.Fragment>
            ) : null}
          </ButtonToolbar>
          <Confirm
            confirm={this.state.confirm}
            handleConfirmButtonClick={this.handleConfirmButtonClick}
            handleConfirmCloseClick={this.handleConfirmCloseClick}
          />
        </div>
      </div>
    );
  }
}

Add.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    corporateInfo: state.auth.selectedFleet.corporate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    operateCorporate: (options) => dispatch(operateCorporate(options)),
    resetPasswordCorporate: (options) =>
      dispatch(resetPasswordCorporate(options)),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Add);
