import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc,
} from '../../../utils/commonFunctions';
import { connect } from 'react-redux';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import './index.scss';


const Invoice = ({ ...props }, context) => {
  // const { notification } = useContext('context')
  const [validInfo, setValidInfo] = useState({});
  const [isSubmited, setIsSubmited] = useState(false);
  const [isDisableCredentials, setDisableCredentials] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');
  const [headerDescription, setHeaderDescription] = useState('');
  const [footerTitle, setFooterTitle] = useState('');
  const [footerDescription, setFooterDescription] = useState('');
  const [hasPermissionAction, setHasPermissionAction] = useState(true);

  useEffect(() => {
    setHasPermissionAction(props.permissions?.actions)
    props.settingActions
      .getInvoiceSetting({
        fleetId: props.auth.selectedFleet.fleetId
      })
      .then((response) => {
        const data = response.res || {};
        if (data.headerTitle) {
          setHeaderTitle(data.headerTitle)
          setHeaderDescription(data.headerDescription)
          setFooterTitle(data.footerTitle)
          setFooterDescription(data.footerDescription)
          setDisableCredentials(true);
        }
      });
  }, []);

  const handleSave = async () => {
    // case press button edit
    if (isDisableCredentials) {
      setDisableCredentials(false);
      return;
    }
    
    setIsSubmited(true);
    if (!CCLiteCommonFunc.isFormValid(validInfo)) {
      return;
    }

    const data = {
      fleetId: props.auth.selectedFleet.fleetId,
      headerTitle,
      headerDescription,
      footerTitle,
      footerDescription
    }

    props.loadingBarActions.showLoadingSpiner();
    const response = await props.settingActions.saveInvoiceSetting(data)
    props.loadingBarActions.hideLoadingSpiner();
    if (response.res?.headerTitle) {
      context.notification(
        'success',
        'Invoice has been saved successfully.'
      );
      setDisableCredentials(true);
      return
    }
    context.notification(
      'error',
      'The errors occurred while saved Invoice.'
    );
  };

  const validatorCallback = (id, valid) => {
    if (validInfo[id] !== valid) {
      setValidInfo({
        ...validInfo,
        [id]: valid,
      });
    }
  };

  return (
    <div className="invoiceSetting">
      <Col md={6} xs={12}>
        <div className="group-general">
          <h4>{I18n.t('Sidebar.Settings.invoiceBranding')}</h4>
          <p>{I18n.t('Sidebar.Settings.titleInvoice')}</p>
          <FormGroup
            className={
              !isSubmited ? null : validInfo.headerTitle === false ? 'error' : null
            }
          >
            <Form.Label>
              <Translate value={`Sidebar.Settings.titleheader`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="text"
              className={'form-custom'}
              maxLength={500}
              value={headerTitle}
              onChange={(e) => setHeaderTitle(e.target.value)}
              placeholder={'Enter header title'}
              disabled={isDisableCredentials || !hasPermissionAction}
            />
            <Validator id={'headerTitle'} callback={validatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(headerTitle)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
          <FormGroup
            className={
              !isSubmited ? null : validInfo.headerDescription === false ? 'error' : null
            }
          >
            <Form.Label>
              <Translate value={`Sidebar.Settings.descriptionHeader`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              as="textarea"
              className={'form-custom'}
              value={headerDescription}
              onChange={(e) => setHeaderDescription(e.target.value)}
              placeholder={I18n.t('Sidebar.Settings.placehodelHeader')}
              disabled={isDisableCredentials || !hasPermissionAction}
              rows={5}
              maxLength={5000}
            />
            <Validator id={'headerDescription'} callback={validatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(headerDescription)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
          <FormGroup
            className={
              !isSubmited ? null : validInfo.footerTitle === false ? 'error' : null
            }
          >
            <Form.Label>
              <Translate value={`Sidebar.Settings.titleFooter`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="text"
              className={'form-custom'}
              value={footerTitle}
              onChange={(e) => setFooterTitle(e.target.value)}
              placeholder={'Enter footer title'}
              disabled={isDisableCredentials || !hasPermissionAction}
              maxLength={500}
            />
            <Validator id={'footerTitle'} callback={validatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(footerTitle)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
          <FormGroup
            className={
              !isSubmited ? null : validInfo.headerDescription === false ? 'error' : null
            }
          >
            <Form.Label>
              <Translate value={`Sidebar.Settings.descriptionFooter`} />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              as="textarea"
              className={'form-custom'}
              value={footerDescription}
              onChange={(e) => setFooterDescription(e.target.value)}
              placeholder={I18n.t('Sidebar.Settings.placehodelFooter')}
              disabled={isDisableCredentials || !hasPermissionAction}
              rows={5}
              maxLength={5000}
            />
            <Validator id={'footerDescription'} callback={validatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(footerDescription)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
        </div>
        <div>
          <Button 
            className="btn-save mr-md" 
            onClick={handleSave}
            disabled={!hasPermissionAction}
          >
            {isDisableCredentials
              ? I18n.t('bookingdetail.Edit')
              : I18n.t('bookingdetail.Save')}
          </Button>
        </div>
      </Col>
    </div>
  );
};

Invoice.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
