import {
    getMessageApi,
    customerAutocompleteApi,
    driverAutocompleteApi,
    deleteMessagesApi,
    sendMessageApi,
    carTypeAutocompleteApi,
    exportMessageReceiversApi,
    editMessageApi,
    merchantAutocompleteApi
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";
function getMessage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        getMessageApi,
        config,
        null,
        null,
        null,
        true
    );
}

function exportReceivers(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportMessageReceiversApi,
        config,
        null,
        null,
        null,
        true
    );
}


function autocompleteCustomer(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        customerAutocompleteApi,
        config,
        null,
        null,
        null
    );
}
function autocompleteMerchant(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        merchantAutocompleteApi,
        config,
        null,
        null,
        null
    );
}
function autocompleteDriver(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        driverAutocompleteApi,
        config,
        null,
        null,
        null
    );
}

function autocompleteCarType(options) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        carTypeAutocompleteApi,
        config,
        null,
        null,
        null
    );
}

function deleteMessages(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteMessagesApi,
        config,
        null,
        null,
        null,
        true
    );
}

function sendMessage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        sendMessageApi,
        config,
        null,
        null,
        null,
        true
    );
}

function editMessage(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        editMessageApi,
        config,
        null,
        null,
        null,
        true
    );
}

export { getMessage, autocompleteCustomer, autocompleteDriver, deleteMessages, sendMessage, autocompleteCarType, exportReceivers, editMessage,autocompleteMerchant }