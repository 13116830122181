/*global PayFacto*/
import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import * as creditCardActions from '../../../../actions/creditCardActions';
import * as corporateAction from '../../../../actions/corporateAction';
import * as newbookingActions from '../../../../actions/newbookingAction';
import IframeAddCard from '../../../IframeAddCard'
import { NAME_GATEWAY, NAME_GATEWAY_FORCE_NEW_TAB, USER_TYPE_ADD_CART } from '../../../../constants/commondata'
import { checkGateWayAddCardFromURL, getUrlLoadAddCardTSYS, handleResultAddCard } from '../../../../utils/commonFunctions'
import QuickAddNewCardModal from './QuickAddNewCardModal';
import './index.scss'
class QuickAddNewCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false,
      payFactoInitialized: false,
      isShowAddNewCard: true
    };
  }

  handleAddCreditCard = creditCard => {
    const { corpId, customer, gateWay } = this.props;
    return this.handleAddNewCustomer().then(newCustomer => {
      let { userId } = customer;
      if (newCustomer) {
        userId = newCustomer.userId;
      }
      if (!userId && !corpId) return;

      const creditInfo = _.extend({}, creditCard, {
        fleetId: this.props.auth.selectedFleet.fleetId,
        userId,
        corpId,
        userType: corpId ? USER_TYPE_ADD_CART.corporate : USER_TYPE_ADD_CART.passenger
      });

      if (
        (!this.props.auth.selectedFleet.creditConfig.multiGateway
          && this.props.auth.selectedFleet.creditConfig.configGateway.zipCode.enable)
        || (this.props.auth.selectedFleet.creditConfig.multiGateway
          && gateWay && gateWay.zipCode.enable)
      ) {
        creditInfo.street = creditCard.street;
        creditInfo.city = creditCard.city;
        creditInfo.state = creditCard.state;
        creditInfo.country = creditCard.country;
        creditInfo.postalCode = creditCard.postalCode;
        creditInfo.phone = creditCard.phone
          .replace(new RegExp(' ', 'g'), '')
          .replace(new RegExp('-', 'g'), '');
      }

      // handle add card corporate
      if (corpId) {
        return this.props.corporateAction.addCardCorporate(creditInfo);
      }

      return this.props.creditCardActions.addNewCreditCard(creditInfo);
    });
  };

  handleAddNewCustomer = async () => {
    const {
      newCustomer,
      customer,
      auth,
      corpId,
      handleAddNewCustomerSuccess
    } = this.props;
    const userId = _.get(customer, 'userId');
    if (userId || corpId) {
      return null;
    }
    const customerObject = _.extend(
      {
        fleetId: auth.selectedFleet.fleetId,
        tips: auth.selectedFleet.fleetFareId ? auth.selectedFleet.fleetFareId.tips : 0
      },
      newCustomer
    );

    return this.props.newbookingActions.checkCustomerBooking(customerObject).then(data => {
      if (data.ok && data.res && data.res.customer) {
        handleAddNewCustomerSuccess(data.res.customer);
        return data.res.customer;
      }
      return null;
    });
  };

  handleAddCardSuccess = cardAdded => {
    this.setState({ isShowModal: false });
    this.props.handleAddCardSuccess(cardAdded);
  };

  handleShowModal = () => {
    // check customer info
    const {
      newCustomer,
      gateWay,
      customer,
      corpId,
      auth
    } = this.props;
    let nameGateway = gateWay && gateWay.gateway || ''
    const currentUser = _.extend({}, customer, newCustomer);
    if (
      !corpId
      && (!currentUser.phone || !currentUser.firstName || !currentUser.lastName || !currentUser.email)
    ) {
      this.context.notification('error', I18n.t('bookingdetail.Require_customer_info'));
      return;
    }

    if (nameGateway == NAME_GATEWAY.TSYS) {
      this.loadFormAddCardByGateway(NAME_GATEWAY.TSYS)
      return;
    }

    if (nameGateway == NAME_GATEWAY.Paymaya) {
      this.loadFormAddCardByGateway(NAME_GATEWAY.Paymaya)
      return;
    }

    if (checkGateWayAddCardFromURL(nameGateway)) {
      this.loadFormAddCardByGateway(nameGateway)
      return;
    }

    this.setState({
      isShowModal: auth.selectedFleet
    });
  };

  closeModalTSYS = () => {
    const { newCustomer, customer, reloadCustomerTSYSGateway, reloadCorporateCompany, paymentType } = this.props;
    const currentUser = _.extend({}, customer, newCustomer);
    if(this.state.isShowTSYSModal) this.setState({isShowTSYSModal: false})
    reloadCustomerTSYSGateway(currentUser.phone)
    if(paymentType === 4) reloadCorporateCompany()
  }


  loadFormAddCardByGateway = async (gateWayName) => {
    const {
      auth, customer, newCustomer, paymentType, corpId
    } = this.props;

    this.handleAddNewCustomer()
    .then(async newCus => {
      const currentUser = _.extend({}, customer, newCustomer);
      let userId = currentUser.userId
      let type = 'customer'
      if (newCus) {
        userId = newCus.userId;
      }
      if (!userId && !corpId) return;

      if (paymentType === 4 && gateWayName !== NAME_GATEWAY.PayWay) {
        userId = corpId
        type = 'corporate'
      }
      if(gateWayName === NAME_GATEWAY.Paymaya) {
        this.props.newbookingActions.getCheckoutPage({
          fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
          userId: userId,
          corporateId: userId,
          type: type
        }).then((data) => {
          let { res = {} } = data
          if (res.returnCode === 200) {
            let response = res.response || {}
            let url = response['3ds_url'] || ''
            handleResultAddCard(url, this.props.handleAddCardSuccess)
          } else if (res.returnCode) {
            this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
          }
        })
      }

      if(gateWayName === NAME_GATEWAY.TSYS) {
        this.props.newbookingActions.getSecureFieldSessionTSYS({
          fleetId: auth && auth.selectedFleet && auth.selectedFleet.fleetId || '',
          userId: userId
        }).then((data) => {
          let { res = {} } = data
          if (res.returnCode === 200) {
            let response = res.response || {}
            // this.initializePayFacto(secureID, sessionId)
            let { secureID = '', sessionId = '', showAddress = false } = response
            this.setState({
              urlIframe: getUrlLoadAddCardTSYS(secureID, sessionId, showAddress),
              isShowIframeAddCardModal: true
            })
          } else if (res.returnCode) {
            this.context.notification('error', I18n.t(`messages.credits.${res.returnCode}`));
          }
        })
      }

      if(checkGateWayAddCardFromURL(gateWayName)) {
        const { corpId } = this.props;
        const request_params = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          userId: userId
        };
        // handle get url form to add card
        try {
          let result = {}

          if (paymentType === 4) {
            request_params.corpId = corpId
            result = await this.props.corporateAction.addCardCorporate(request_params);
          } else {
            result = await this.props.creditCardActions.addNewCreditCard(request_params);
          }

          if(result?.res?.response?.['3ds_url']) {
            if(NAME_GATEWAY_FORCE_NEW_TAB.includes(gateWayName)) {
              handleResultAddCard(result?.res?.response?.['3ds_url'], this.props.handleAddCardSuccess)
            } else {
              this.setState({
                urlIframe: result?.res?.response?.['3ds_url'],
                isShowIframeAddCardModal: true
              }) 
            }
          } else if(result.res?.returnCode) {
            this.context.notification('error', I18n.t(`messages.credits.${result.res?.returnCode}`));
          }
        } catch (error) {
          this.context.notification('error', 'Catch Error');
        }
      }
      
    })
  }

  closeModalIframeAddCard = () => {
    const { newCustomer, customer, reloadCustomerTSYSGateway, reloadCorporateCompany, paymentType } = this.props;
    const currentUser = _.extend({}, customer, newCustomer);
    this.setState({isShowIframeAddCardModal: false})
    reloadCustomerTSYSGateway(currentUser.phone)
    if(paymentType === 4) reloadCorporateCompany()
  }

  handleCloseModal = () => {
    this.setState({ isShowModal: false });
  };

  render() {
    const { isShowModal, isLoading   } = this.state;
    const {
      auth, customer, corpId, gateWay, newCustomer, isHydraBooking, fleetSupplierId
    } = this.props;
    const currentUser = _.extend({}, customer, newCustomer);
    return (
      <Fragment>
        {isShowModal && (
          <QuickAddNewCardModal
            closeModal={this.handleCloseModal}
            currentUser={currentUser}
            isHydraBooking={isHydraBooking}
            fleetSupplierId={fleetSupplierId}
            selectedFleet={auth.selectedFleet}
            isLoading={isLoading}
            gateWay={gateWay || {}}
            userId={_.get(customer, 'userId')}
            corpId={corpId}
            onSubmitForm={this.handleAddCreditCard}
            handleAddCardSuccess={this.handleAddCardSuccess}
          />
        )}
        {
          <Button className="btn-header text-add-header p0" onClick={this.handleShowModal}>
            <Translate value="bookingdetail.Add_new_card" className="collapse-filter-title" />
          </Button>
        }
        <IframeAddCard 
          isShowModal={this.state.isShowIframeAddCardModal}
          urlIframe={this.state.urlIframe}
          closeModal={this.closeModalIframeAddCard}
          auth={auth}
        />
      </Fragment>
    );
  }
}

QuickAddNewCard.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    creditCardActions: bindActionCreators(creditCardActions, dispatch),
    corporateAction: bindActionCreators(corporateAction, dispatch),
    newbookingActions: bindActionCreators(newbookingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickAddNewCard);