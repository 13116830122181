export const DriverDocumentColumn = [
  {
    key: 'DocumentKey',
    label: 'driverDocument.DocumentKey',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
    fixed: true,
  },
  {
    key: 'DocumentName',
    label: 'driverDocument.DocumentName',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'CommandCenter',
    label: 'driverDocument.CommandCenter',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'SignUp',
    label: 'driverDocument.SignUp',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'CompanyTransport',
    label: 'driverDocument.CompanyTransport',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'CompanyDelivery',
    label: 'driverDocument.CompanyDelivery',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'IndividualTransport',
    label: 'driverDocument.IndividualTransport',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'IndividualDelivery',
    label: 'driverDocument.IndividualDelivery',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'Require',
    label: 'driverDocument.Require',
    cellClass: 'cell-align-toolbar',
    headerClass: 'header-align-toolbar',
  },
  {
    key: 'Actions',
    label: 'driverDocument.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
