import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import { Button, ButtonToolbar, Modal, ButtonGroup, FormGroup } from 'react-bootstrap';
import _ from 'lodash';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import TableActions from '../../../components/table/tableAction/TableActions';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import TemplateModal from './TemplateModal';
import { BsSearch } from 'react-icons/bs';

const fields = [
  {
    key: 'name',
    label: 'voiceSMSSetting.bookingSMSTemplate.name',
    textEllipsis: true
  },
  {
    key: 'content',
    label: 'voiceSMSSetting.bookingSMSTemplate.content',
    textEllipsis: true
  },
  {
    key: 'action',
    label: 'voiceSMSSetting.bookingSMSTemplate.action',
  },
];

class BookingSMSTemplate extends Component {
  constructor() {
    super();
    this.state = {
      bookingSMSTemplates: null,
      activePage: 1,
      numItemsPerPage: 20,
      showForm: false,
      dialogData: null,
      showConfirm: false,
      sortType: -1,
      tableHeight: 500,
      rowHeight: 50,
      editable: false,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
    };

    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleNumItemsPerPageChange =
      this.handleNumItemsPerPageChange.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateBookingSMSTemplateList =
      this.updateBookingSMSTemplateList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleCloseConfirmDialog = this.handleCloseConfirmDialog.bind(this);
    this.confirmDeleteBookingSMSTemplate =
      this.confirmDeleteBookingSMSTemplate.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
  }
  componentDidMount() {
    this.updateBookingSMSTemplateList();
  }

  updateBookingSMSTemplateList(activePage, limit) {
    const params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str
    };

    this.props.settingActions.findBookingSMSTemplates(params).then((data) => {
      console.log(data);
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          bookingSMSTemplates: data.res,
          footerData: { page, total, limit },
        });
      }
    });
  }

  handleAddButtonClick() {
    const objectData = {
      name: '',
      content: '',
      transportation: true,
      assistant: false,
    };
    this.setState({
      showForm: true,
      dialogData: objectData,
      editable: true
    });
  }

  handleCloseConfirmDialog() {
    this.setState({ dialogData: null, showConfirm: false });
  }

  handleMenuClick(key, data) {
    switch (key) {
      case 'Edit': {
        this.props.settingActions
          .detailsBookingSMSTemplate({
            id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showForm: true,
                dialogData: data.res,
                editable: true
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('voiceSMSSetting.bookingSMSTemplate.Load_fail')
                );
              }
            }
          });
        break;
      }
      case 'View': {
        this.props.settingActions
          .detailsBookingSMSTemplate({
            id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showForm: true,
                dialogData: data.res,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('voiceSMSSetting.bookingSMSTemplate.Load_fail')
                );
              }
            }
          });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateBookingSMSTemplateList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    this.updateBookingSMSTemplateList(0, parseInt(e));
  }

  confirmDeleteBookingSMSTemplate() {
    this.props.settingActions
      .deleteBookingSMSTemplate({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.handleCloseConfirmDialog();
          this.updateBookingSMSTemplateList();
          this.context.notification(
            'success',
            I18n.t('voiceSMSSetting.bookingSMSTemplate.Delete_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('voiceSMSSetting.bookingSMSTemplate.Delete_fail')
            );
          }
        }
      });
  }

  handleSearchChange = e => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = e => {
    if (e.key === "Enter") {
      this.updateBookingSMSTemplateList();
    }
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], fields);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (data) => {
            return !data.editable ? (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick('View', data);
                }}
              >
                {data.name}
              </a>
            ) : (
              <a className="disabled clickable">{data.name}</a>
            );
          };
          break;
      

        case 'action':
          col.customCell = (data, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [];
              if (!data.editable) {
                actionItems.push({
                  label: 'city.Edit',
                  eventKey: 'Edit',
                });
              }
              if (!data.editable) {
                actionItems.push({
                  label: 'companySetting.Delete',
                  eventKey: 'Delete',
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={data}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, data);
                  }}
                  totalRow={this.state.bookingSMSTemplates.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', data);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="city.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    const { parentRef } = this.props;
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const tabListHeight = 60;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = parentRef
      ? ReactDOM.findDOMNode(parentRef).clientHeight
      : 0;
    const outerHeight =
      verticalPadding + toolbarMarginBottom + toolbarheight + tabListHeight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    // return 600;
    return this.state.tableHeight;
  };

  handleCloseForm() {
    this.setState({ showForm: false, dialogData: null, editable: false });
  }

  handleSubmitSuccess() {
    this.updateBookingSMSTemplateList();
    this.handleCloseForm();
  }

  render() {
    const bodyData =
      this.state.bookingSMSTemplates &&
      this.state.bookingSMSTemplates.list.length > 0
        ? this.state.bookingSMSTemplates.list
        : [];

    return (
      <div className="content">
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={(node) => (this.toobarContainer = node)}
          >
            <ButtonGroup className="group-left">
              <FormGroup className="search-format mb0">
                <input
                  type="text"
                  className="form-control search-form"
                  value={this.state.str}
                  onKeyPress={this.handleSearchKeyPress}
                  onChange={this.handleSearchChange}
                  placeholder={I18n.t('General.search')}
                />
                <BsSearch className="search-icon" />
              </FormGroup>
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className="btn-header text-add-header ml0"
                  onClick={this.handleAddButtonClick}
                >
                  {' '}
                  <Translate value="companySetting.Add" />
                </Button>
              ) : null}
            </ButtonGroup>
          </ButtonToolbar>
        ) : (
          ''
        )}
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
        {this.state.showConfirm ? (
          <Modal onHide={this.handleCloseConfirmDialog} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="voiceSMSSetting.bookingSMSTemplate.DELETE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCloseConfirmDialog}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="voiceSMSSetting.bookingSMSTemplate.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteBookingSMSTemplate}
              >
                <Translate value="city.Yes" />
              </Button>
              <Button
                className={'btn-cancel'}
                onClick={this.handleCloseConfirmDialog}
              >
                <Translate value="city.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showForm ? (
          <TemplateModal
            data={this.state.dialogData}
            editable={this.state.editable}
            onSuccess={this.handleSubmitSuccess}
            onCancel={this.handleCloseForm}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

BookingSMSTemplate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingSMSTemplate);
