import React, { useEffect, useState } from 'react';
import { VehilceColumns } from './tableColumns';
import './vehicleDriver.scss';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { forEach } from 'lodash';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import CCDropDown from '../../../../components/dropdown/ccDropDown';
import closeIcon from '../../../../assets/images/icons/close-vehicle.svg';
import SearchVehicle from './SearchVehicle';
import { Button } from 'react-bootstrap';
import ConfirmDeleteVehicle from './ConfirmDeleteVehicle';
export const VEHICLE_ACTION = {
  new: 'NEW',
  link: 'LINK',
};

const VehicleDriver = ({
  vehicleIds = [],
  companyId,
  state,
  auth,
  ...props
}, context) => {
  const [vehicleIdsDetail, setVehicleIdsDetail] = useState([]);
  const [switchToAnyVehicle, setSwitchToAnyVehicle] = useState(false);
  const [infoDeleteVehicleModal, setInfoDeleteVehicleModal] = useState({
    isShow: false,
    vehicleId: '',
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      if (vehicleIds?.length > 0) {
        try {
          const rs = await props.settingActions.vehicleFindByIds({
            fleetId: auth?.selectedFleet.fleetId,
            vehicleIds: vehicleIds,
            companyId: companyId
          });
          if (rs?.res?.length > 0) {
            setVehicleIdsDetail(rs.res);
          }
        } catch (error) {
          context.notification('error', I18n.t('errors.systemError'))
        }
      } else {
        setVehicleIdsDetail([]);
      }
    };

    fetchVehicles();
  }, [JSON.stringify(vehicleIds?.sort())]);

  useEffect(() => {
    setSwitchToAnyVehicle(props.switchToAnyVehicle)
  }, [props.switchToAnyVehicle]);

  const closeConfirmDeleteVehicleModal = () => {
    setInfoDeleteVehicleModal({
      isShow: false,
      vehicleId: '',
    });
  }

  const handleRemoveVehicle = async () => {
    const respone = await props.driverActions.removeVehicle({
      vehicleId: infoDeleteVehicleModal.vehicleId,
      fleetId: auth?.selectedFleet.fleetId,
      userId: props.userId,
    });
    if (respone.res?.status === 200) {
      props.updateVehicleIds(respone.res.vehicleIds || []);
      closeConfirmDeleteVehicleModal();
      context.notification('success', 'Vehicle has been removed successfully')
    } else {
      if (!respone.ok) {
        if (respone.error) {
          context.notification(
            'error',
            I18n.t(`errors.${respone?.error?.errorCode}`)
          );
        } else {
          context.notification('error', I18n.t('errors.systemError'))
        }
      }
    }
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], VehilceColumns);
    forEach(tableColums, (col) => {
      const style = {
        color: 'white',
        display: 'inline-block',
        padding: '5px',
        'border-radius': '5px',
      };
      switch (col.key) {
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            console.log('obj', obj);
            return (
              <Button
                className="btn-header text-add-header"
                onClick={(e) => setInfoDeleteVehicleModal({ isShow: true, vehicleId: obj._id })}
              >
                <img
                  src={closeIcon}
                  style={{ width: '20px', maxHeight: '50px' }}
                  className='closeIcon'
                />
              </Button>
            );
          };
          break;
        case 'photo':
          col.customCell = (data) => {
            return (
              <img
                src={data.image}
                alt="Event"
                style={{ maxWidth: '100%', maxHeight: '50px' }}
              />
            );
          };
          break;
        case 'vehicleModel.name':
          col.customCell = (data) => {
            const mode = `${data?.vehicleModel?.vehicleMake} ${data?.vehicleModel?.name}`;
            return (
              <span>
                {data.year || ''}
                {`${mode.length > 1 && data.year ? ` - ${mode}` : mode}`}
                {`${data.color ? ` - ${data.color}` : ''}`}
              </span>
            );
          };
          break;
        case 'status':
          col.customCell = (carMgmtData) => {
            let statusStr;
            let statusClass;
            if (carMgmtData && !carMgmtData.isReview) {
              statusStr = <Translate value="driver.In_Review" />;
              statusClass = 'Inreview';
            } else if (carMgmtData && carMgmtData.isActive) {
              statusStr = <Translate value="carSetting.Active" />;
              statusClass = 'Active';
            } else if (carMgmtData && !carMgmtData.isActive) {
              statusStr = <Translate value="carSetting.Inactive" />;
              statusClass = 'Inactive';
            }
            return (
              <div className={statusClass}>
                  {statusStr}
              </div>
            );
          };
          break;
        case 'plateNumber':
          col.customCell = (data) => {
            return (
              <a href={`/vehicle/${data._id}`} target='_blank' style={{ color: '#04BE76'}}>
                {data.plateNumber}
              </a>
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColums;
  };

  const handleAddVehicle = (action) => {
    if (action === VEHICLE_ACTION.new) {
      props.navigate.push(`/driver/view/${props.userId}/addNewVehicle`)
    }
    if (action === VEHICLE_ACTION.link) {
      handleLinkVehicleModal(true);
    }
  };

  const [openLinkVehicleModal, setOpenLinkVehicleModal] = useState(false);
  const handleLinkVehicleModal = (show) => {
    setOpenLinkVehicleModal(show);
  };

  const renderMainContent = () => {
    const isNewVehicle = auth?.user?.roles?.isSupplier ? auth?.user?.roles?.supplierPermission?.vehicle : true;
    const selectVehicleAction = [
      { value: VEHICLE_ACTION.new, title: 'driver.newVehicle' },
      { value: VEHICLE_ACTION.link, title: 'driver.linkVehicle' },
    ]
    if (!isNewVehicle) {
      selectVehicleAction.splice(0, 1);
    }
    return (
      <>
        <div className={'switchCB'} style={{ margin: '30px 0'}}>
          <div>
            <CcCheckbox
              onChange={(e) => {props.handleSwitchToAnyVehicleChange(e.target.checked);}}
              checked={switchToAnyVehicle}
              text={I18n.t('driver.switchVehicle')}
              className={'cb'}
              labelClassName={'cbLabel'}
            />
            <span>{I18n.t('driver.switchVehicleNote')}</span>
          </div>
          <CCDropDown
            id="group_btn_actions"
            items={selectVehicleAction}
            title={I18n.t('driver.addVehicle')}
            dropup={true}
            selectedItems={[]}
            valueKey="value"
            labelKey="title"
            onSelect={handleAddVehicle}
            disabled={switchToAnyVehicle}
            className="btn_vehicle"
            noTooltip
          />
        </div>
        <div className="gridViewTable">
          <StickyTable
            columns={getTableColumns()}
            bodyData={switchToAnyVehicle ? [] : vehicleIdsDetail}
            rowHeight={70}
            getTableHeight={() => 500}
            noPagination
            settings={props.auth?.selectedFleet}
          />
        </div>
      </>
    )
  }
  return (
    <div id={'vehicleDriver'}>
      <>{renderMainContent()}</>

      {/* Modal Popup */}
      <SearchVehicle
        fleetId={auth?.selectedFleet.fleetId}
        settingActions={props.settingActions}
        driverActions={props.driverActions}
        userId={props.userId}
        openLinkVehicleModal={openLinkVehicleModal}
        handleLinkVehicleModal={handleLinkVehicleModal}
        updateVehicleIds={props.updateVehicleIds}
        companyId={companyId}
        carType={props.commonData.carType}
      />
      <ConfirmDeleteVehicle 
        isShow={infoDeleteVehicleModal.isShow}
        handleCloseModal={() => closeConfirmDeleteVehicleModal()}
        handleConfrimDelete={handleRemoveVehicle}
      />
    </div>
  );
};

VehicleDriver.prototype = {
  vehicleIds: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
};

VehicleDriver.defaultProps = {
  vehicleIds: [],
  companyId: '',
  auth: {},
  handleSwitchToAnyVehicleChange: () => {},
};

VehicleDriver.contextTypes = {
  notification: PropTypes.func,
};

export default VehicleDriver;
