import React from "react";
import { Translate } from "react-redux-i18n";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { I18n } from "react-redux-i18n";
import _ from "lodash";
import "./tableFooter.scss";
import { toLocaleNumber } from "../../../utils/commonFunctions";

class TableFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: this.props.data ? this.props.data.page || 1 : "",
      prevLimit: this.props.data ? this.props.data.limit : 20,
      prevPage: this.props.data ? this.props.data.page || 1 : ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.data &&
      (props.data.limit !== state.prevLimit ||
        props.data.page !== state.prevPage)
    ) {
      return {
        selectedPage: props.data.page + 1,
        prevLimit: props.data.limit,
        prevPage: props.data.page
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.data) {
      return true;
    }
    return false;
  }

  handlePageClick = e => {
    if (e.target && e.target.nodeName === "DIV") {
      let pageData = e.target.getAttribute("pagedata");
      if (parseInt(pageData) === this.props.data.page) return true;
      this.props.handlePaginationSelect(parseInt(pageData));
    }
  };

  getDisplayPage = pageList => {
    let list = pageList;
    if (pageList.length > 5) {
      if (this.props.data.page < 3) {
        list = pageList.slice(0, 5);
      } else {
        if (
          this.props.data.page === pageList.length - 1 ||
          pageList.length - this.props.data.page < 3
        ) {
          list = pageList.slice(pageList.length - 5);
        } else {
          list = pageList.slice(
            this.props.data.page - 2,
            this.props.data.page + 3
          );
        }
      }
    }
    return list;
  };

  renderPageSelect = totalPages => {
    let indents = [];
    let showInput = totalPages > 100;
    if (!showInput) {
      for (let i = 1; i <= totalPages; i++) {
        indents.push(
          <option key={i} value={i - 1}>
            {i}
          </option>
        );
      }
    }
    let inputValue = parseInt(this.state.selectedPage);
    let errorInputValue =
      !inputValue ||
      isNaN(inputValue) ||
      inputValue < 0 ||
      inputValue > totalPages;

    return (
      <React.Fragment>
        <label inline="true" className="mr-sm">
          <Translate value="table_header.Page" />
        </label>
        {showInput ? (
          <FormControl
            type="number"
            min={1}
            max={totalPages}
            step={1}
            className={
              "form-custom number-no-spin page-input" +
              (errorInputValue ? " error-page" : "")
            }
            value={this.state.selectedPage}
            onChange={this.handlePageInputChange}
            onKeyPress={this.handlePageInputPress}
          />
        ) : (
          <select
            className="form-control page-control select-page mr-sm"
            inline="true"
            value={this.props.data.page}
            onChange={e =>
              this.props.handlePaginationSelect(parseInt(e.target.value))
            }
          >
            {indents}
          </select>
        )}
        <label inline="true">
          <Translate value="table_header.OF" /> {toLocaleNumber(totalPages)}
        </label>
      </React.Fragment>
    );
  };

  handlePageInputChange = e => {
    this.setState({
      selectedPage: e.target.value
    });
  };

  handlePageInputPress = e => {
    if (e.key === "Enter") {
      let inputValue = parseInt(this.state.selectedPage);
      let errorInputValue =
        !inputValue ||
        isNaN(inputValue) ||
        inputValue < parseInt(e.target.min) ||
        inputValue > parseInt(e.target.max);
      if (!errorInputValue) {
        this.props.handlePaginationSelect(inputValue - 1);
      }
    }
  };

  render() {
    const { isLoading, data } = this.props;
    if (!data) {
      return <div className="qup-pagination-wrapper pagination-no-data" />;
    }
    let tableClass =
      (this.props.className || "") +
      " qup-pagination-wrapper " +
      (this.props.data.total === 0 ? "pagination-no-data" : "");

    let totalPages = 1;
    let limites = [5, 10, 20, 50, 70, 100];

    totalPages = Math.ceil(this.props.data.total / this.props.data.limit);
    let pages = [];

    for (let i = 0; i < totalPages; i++) {
      pages.push({ id: i, page: i + 1 });
    }

    if (this.props.limites) limites = this.props.limites;

    let displayPage = this.getDisplayPage(pages);

    let startItems =
      this.props.data.total === 0
        ? 0
        : this.props.data.page * this.props.data.limit + 1;
    const endItems =
      (this.props.data.page + 1) * this.props.data.limit >=
      this.props.data.total
        ? this.props.data.total
        : (this.props.data.page + 1) * this.props.data.limit;

    return (
      <div className={tableClass.trim()}>
        {isLoading && (
          <div className={"footer-loading "}>
            <i className="fa fa-circle-o-notch fa-spin spinner-icon" />
          </div>
        )}
        <div className="left-controls">
          <div className="item-per-page">
            <label inline="true" className="mr-sm">
              <Translate value="table_header.Items_per_page" />
            </label>
            <select
              className="form-control page-control select-page-item mr-lg"
              value={this.props.data.limit}
              inline="true"
              onChange={e =>
                this.props.handleNumItemsPerPageChange(parseInt(e.target.value))
              }
            >
              {_.map(limites, (item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="select-page">{this.renderPageSelect(totalPages)}</div>
        </div>
        <div className="right-controls">
          <div className="shown-items">
            <Translate
              value="table_header.Shown_Active"
              start={toLocaleNumber(startItems)}
              end={toLocaleNumber(endItems)}
              total={toLocaleNumber(this.props.data.total)}
            />
            {this.props.data.active || this.props.data.active === 0 ? (
              <Translate
                value="table_header.Active"
                active={toLocaleNumber(this.props.data.active)}
              />
            ) : null}
          </div>
          <div className="cc-paginations-container">
            <ul onClick={this.handlePageClick} className="paginations">
              {/*First page*/}
              <li>
                <div pagedata="0" className="ml0 transition">
                  «
                </div>
              </li>
              {/*Prev page*/}
              <li>
                <div
                  pagedata={
                    this.props.data.page === 0
                      ? this.props.data.page
                      : this.props.data.page - 1
                  }
                  className="mr-md ml0 transition"
                >
                  ‹
                </div>
              </li>
              {_.map(displayPage, (item, index) => (
                <li key={item.id}>
                  <div
                    pagedata={item.id}
                    className={this.props.data.page === item.id ? "active" : ""}
                  >
                    {item.page}
                  </div>
                </li>
              ))}
              {/*Next page*/}
              <li>
                <div
                  pagedata={
                    this.props.data.page < pages.length - 1
                      ? this.props.data.page + 1
                      : this.props.data.page
                  }
                  className="ml-md mr0 transition"
                >
                  ›
                </div>
              </li>
              {/*Lastest page*/}
              <li>
                <div pagedata={pages.length - 1} className="mr0 transition">
                  »
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

TableFooter.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.number,
    total: PropTypes.number,
    limit: PropTypes.number
  }),
  handleNumItemsPerPageChange: PropTypes.func.isRequired,
  handlePaginationSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default TableFooter;
