import React, { useState } from 'react';
import { getPlateNumberFromBook } from '../cueFunctions';

const VehicleColumn = ({
  booking,
  lineShort,
  isMobile,
  handleAssignDriverOneBooking,
  isCueFinished,
}) => {
  const vehicleType =
      booking?.request?.vehicleTypeRequest || booking?.vehicle?.vehicleType,
    plateNumber = getPlateNumberFromBook({ booking, isMobile: false });
  if (isMobile) {
    return (
      <div
        className="info-item vehicle"
        onClick={(e) => handleAssignDriverOneBooking(booking, e)}
      >
        {vehicleType}
      </div>
    );
  }
  return (
    <div
      className={`vehicle-info ${lineShort ? ' custom__lineShort' : ''}`}
      style={{ cursor: `${isCueFinished ? '' : 'pointer'}` }}
      onClick={(e) => handleAssignDriverOneBooking(booking, e)}
    >
      <p className={'text-ellipsis'}>
        {<p style={{ display: 'inline-block' }}>{vehicleType}</p>}
        {plateNumber && lineShort ? (
          <>
            <div className="straight-line" style={{ marginLeft: '5px' }}></div>{' '}
            <p style={{ display: 'inline-block' }}>{plateNumber}</p>
          </>
        ) : (
          ''
        )}
      </p>
      {!lineShort && <p className={'text-ellipsis'}>{plateNumber || '---'}</p>}
    </div>
  );
};

export default VehicleColumn;
