import _ from 'lodash';
import React from 'react';
import ReportUtils from '../base/utils';
import { FiDownload } from "react-icons/fi";
export const fields = [
  {
    key: 'createdDate',
    label: 'report.result.export.createdDate',
    summary: 'date',
    mutate: (value) => ReportUtils.formatTime(value, {}, 'MM/DD/YYYY'),
  },
  {
    key: 'moduleName',
    label: 'report.result.export.name',
  },
  {
    key: 'status',
    label: 'report.result.export.status',
    mutate: (value, doc) => {
      const statusKeys = {
        NOT_PROGRESS: 'In-progress',
        IN_PROGRESS: 'In-progress',
        COMPLETED: 'Completed',
        FAILED: 'Failed',
      };
      return statusKeys[_.get(doc, 'status', '')];
    },
  },
  {
    key: 'urlDownload',
    label: 'report.result.export.download',
    mutate: (value, doc) => {
      if(doc.urlDownload && doc.urlDownload.includes('http')) {
        return <a href={doc.urlDownload}>
          <FiDownload />          
        </a>
      }
      return null
    },
    link: {
      notMergeParams: true,
      name: 'downloadReport',
    },
    width: 120,
    isAlwayShow: true,
  },
];
