import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Row,
  Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { DefaultCurenciesNumberInputField } from '../../../../constants/commondata';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../components/validator';
import RadioButton from '../../../../components/radioButton/radio';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { ModalCustomizedAirportFee } from '../modals';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class AirportFeeFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFromAirPortCheckChange = (e) => {
    this.props.handleUpdateFormData(
      'airport.fromAirportActive',
      e.target.checked
    );
  };

  handleFromAirportChange = (e) => {
    this.props.handleUpdateFormData('airport.fromAirport', e.target.value);
  };

  handleToAirPortCheckChange = (e) => {
    this.props.handleUpdateFormData(
      'airport.toAirportActive',
      e.target.checked
    );
  };

  handleToAirportChange = (e) => {
    this.props.handleUpdateFormData('airport.toAirport', e.target.value);
  };

  getCurrencyValue = (currenciesAmount, currencyISO) => {
    const currencyCurrent = _.find(
      currenciesAmount || [],
      (o) => o.currencyISO === currencyISO
    );
    return currencyCurrent ? currencyCurrent.value : 0;
  };

  handleCancelInputMultiCurrenciesChange = (currency, e, keyName) => {
    const { handleUpdateFormData, formData } = this.props;
    let currentFieldValue = _.get(formData, keyName);
    let isAdd = true;
    const cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    currentFieldValue =
      currentFieldValue ||
      this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
      }));
    currentFieldValue = currentFieldValue.map((c) => {
      if (c.currencyISO === currency) {
        isAdd = false;
        return { ...c, value: e.target.value };
      }
      return c;
    });
    if (isAdd) {
      currentFieldValue.push(cur);
    }
    handleUpdateFormData(keyName, currentFieldValue);
  };

  handeIsCustomizedChange = (e) => {
    const value = e.target.value == 'true';
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('airport.isCustomized', value);
  };

  handlePaytoChange(e, key) {
    const value = parseInt(e.target.value || 0);
    switch (key) {
      case 'meetDriver': {
        this.props.handleUpdateFormData('meetDriver.payTo', value);
        break;
      }
      case 'airport': {
        this.props.handleUpdateFormData('airport.payTo', value);
        break;
      }
      default:
        break;
    }
  }

  handleCloseModalCustomizedAirportFee = () => {
    this.setState({ showModalAirportFee: false });
  };

  openModalCustomizedAirportFee = () => {
    this.setState({ showModalAirportFee: true });
  };

  renderMultiCurrency = (key) => {
    const { formData, editable, auth } = this.props;
    const currentField = _.get(formData, key);
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        defaultActiveKey={auth.selectedFleet.currencies[0].iso}
        animation={false}
        onSelect={this.tabSelectHandle}
      >
        {auth.selectedFleet.currencies.map((c) => {
          const currentValue = currentField
            ? currentField.filter((d) => d.currencyISO === c.iso)[0]
            : null;
          return (
            <Tab
              className="currencies-tab-item"
              eventKey={c.iso}
              title={c.iso}
              key={c.iso}
            >
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-left ${editable ? 'disabled' : ''}`}
                  >
                  <InputGroup.Prepend>
                    <InputGroup.Text>{c.symbol}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    className="fomt-custom"
                    onChange={(e) => {
                      this.handleCancelInputMultiCurrenciesChange(
                        c.iso,
                        e,
                        key
                      );
                    }}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    value={
                      currentValue
                        ? currentValue.value
                        : DefaultCurenciesNumberInputField
                    }
                    disabled={editable}
                  />
                </InputGroup>
              </FormGroup>
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  renderInputCurrency = (keyName) => {
    const { formData, editable, auth, currency } = this.props;
    const currencyCurrent = currency || auth.selectedFleet.currencies[0];
    const currentField = _.get(formData, keyName);
    return (
      <FormGroup className="qup-input-group">
        <InputGroup
          className={`single-addon-left ${editable ? 'disabled' : ''}`}
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className="form-custom no-marginBottom"
            onChange={(e) => {
              this.handleCancelInputMultiCurrenciesChange(
                currencyCurrent.iso,
                e,
                keyName
              );
            }}
            onBlur={(e) => {
              textboxNumberHelper.onBlurHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            onFocus={(e) => {
              textboxNumberHelper.onfocusHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            value={this.getCurrencyValue(currentField, currencyCurrent.iso)}
            disabled={editable}
          />
        </InputGroup>
      </FormGroup>
    );
  };

  renderAirportSameFee = () => {
    const { formData, editable, ValidatorCallback } = this.props;
    return (
      <div className="mt-sm">
        <CcCheckbox
          disabled={editable}
          checked={formData ? formData.airport.fromAirportActive : false}
          onChange={this.handleFromAirPortCheckChange}
          text={I18n.t('generalSetting.From_airport')}
        />
        {formData && formData.airport.fromAirportActive ? (
          <FormGroup
            className={`sub-controls ${
              !this.props.isSubmited
                ? null
                : this.props.valid.fromAirportByCurrencies === false
                ? 'error'
                : null
            }`}
          >
            {this.props.auth.selectedFleet.multiCurrencies
              ? this.renderMultiCurrency('airport.fromAirportByCurrencies')
              : this.renderInputCurrency('airport.fromAirportByCurrencies')}
            <Validator
              id="fromAirportByCurrencies"
              callback={ValidatorCallback}
            >
              <ValidCase
                valid={
                  formData.airport.fromAirportByCurrencies.filter(
                    (d) =>
                      !Validation.isStringEmpty(d.value) && _.isNaN(d.value)
                  ).length == 0
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!this.props.isSubmited}
              />
              <ValidCase
                valid={
                  formData.airport.fromAirportByCurrencies.filter(
                    (d) => !Validation.isGreaterOrEqual(d.value, 0)
                  ).length == 0
                }
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
                hide={!this.props.isSubmited}
              />
            </Validator>
            <WarningCase
              validator={this.props.valid.fromAirportByCurrencies}
              message={I18n.t('message.warningInputChangeOver')}
              initialValue={formData.airport.fromAirportByCurrencies.map(
                (ob) => ob.value
              )}
              onChangeValue={formData.airport.fromAirportByCurrencies.map(
                (ob) => ob.value
              )}
              range={20}
              typePercent={false}
            />
          </FormGroup>
        ) : (
          ''
        )}

        <CcCheckbox
          disabled={editable}
          checked={formData ? formData.airport.toAirportActive : false}
          onChange={this.handleToAirPortCheckChange}
          text={I18n.t('generalSetting.To_airport')}
        />
        {formData && formData.airport.toAirportActive ? (
          <FormGroup
            className={`sub-controls ${
              !this.props.isSubmited
                ? null
                : this.props.valid.airport_toAirportByCurrencies === false
                ? 'error'
                : null
            }`}
          >
            {this.props.auth.selectedFleet.multiCurrencies
              ? this.renderMultiCurrency('airport.toAirportByCurrencies')
              : this.renderInputCurrency('airport.toAirportByCurrencies')}
            <Validator
              id="airport_toAirportByCurrencies"
              callback={ValidatorCallback}
            >
              <ValidCase
                valid={
                  formData.airport.toAirportByCurrencies.filter(
                    (d) => !Validation.isStringEmpty(d.value) && isNaN(d.value)
                  ).length == 0
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!this.props.isSubmited}
              />
              <ValidCase
                valid={
                  formData.airport.toAirportByCurrencies.filter(
                    (d) => !Validation.isGreaterOrEqual(d.value, 0)
                  ).length == 0
                }
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
                hide={!this.props.isSubmited}
              />
            </Validator>
            <WarningCase
              validator={this.props.valid.airport_toAirportByCurrencies}
              message={I18n.t('message.warningInputChangeOver')}
              initialValue={formData.airport.toAirportByCurrencies.map(
                (ob) => ob.value
              )}
              onChangeValue={formData.airport.toAirportByCurrencies.map(
                (ob) => ob.value
              )}
              range={20}
              typePercent={false}
            />
          </FormGroup>
        ) : (
          ''
        )}
      </div>
    );
  };

  renderAirportCustomizedFee = () => (
    <FormGroup
      className="mh-0"
      style={{
        marginBottom: '0',
      }}
    >
      <a
        className="text-active cursor-pointer"
        onClick={this.openModalCustomizedAirportFee}
      >
        <Translate value="generalSetting.Customized" />
      </a>
    </FormGroup>
  );

  render() {
    const { formData, ValidatorCallback, editable } = this.props;
    return (
      <div className="group-general">
        <FormGroupTitle className="title mb-sm">
          <Translate value="generalSetting.AIRPORT_FEE" />
        </FormGroupTitle>
        <FormGroup className="radio-group">
          <Row
            style={{
              width: '100%',
            }}
          >
            <Col xs={12} className="mb-sm">
              <Form.Label>
                <Translate value="generalSetting.apply" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="generalSetting.airportFee.tooltipApply" />
                  }
                />
              </Form.Label>
            </Col>
            <Col lg={6} md={12} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('generalSetting.sameFee')}
                inline
                name="isCustomized"
                value="false"
                checked={!formData.airport.isCustomized}
                onChange={this.handeIsCustomizedChange}
                disabled={editable}
              />
            </Col>
            <Col lg={6} md={12} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('generalSetting.customizedAirportfee')}
                inline
                name="isCustomized"
                value="true"
                checked={formData.airport.isCustomized}
                onChange={this.handeIsCustomizedChange}
                disabled={editable}
              />
              {formData.airport.isCustomized
                ? this.renderAirportCustomizedFee()
                : null}
            </Col>
            {formData.airport.isCustomized ? null : (
              <Col xs={12}>{this.renderAirportSameFee()}</Col>
            )}
          </Row>
        </FormGroup>
        <FormGroup className="radio-group">
          <Row
            style={{
              width: '100%',
            }}
          >
            <Col lg={3} md={12}>
              <Form.Label>
                <Translate value="generalSetting.Pay_to" />
              </Form.Label>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('newbooking.Fleet_Company')}
                inline
                name="airport"
                value={1}
                checked={formData.airport.payTo == 1}
                onChange={(e) => {
                  this.handlePaytoChange(e, 'airport');
                }}
                disabled={editable}
              />
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('newbooking.Driver_Supplier')}
                inline
                name="airport"
                value={0}
                checked={formData.airport.payTo == 0}
                onChange={(e) => {
                  this.handlePaytoChange(e, 'airport');
                }}
                disabled={editable}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroupTitle className="title ">
          <Translate value="generalSetting.MEET_DRIVER" />
        </FormGroupTitle>
        <FormGroup
          className={
            !this.props.isSubmited
              ? null
              : this.props.valid.onCurbByCurrencies === false
              ? 'error'
              : null
          }
        >
          <Form.Label>
            <Translate value="generalSetting.On_curb" />
          </Form.Label>
          {this.props.auth.selectedFleet.multiCurrencies
            ? this.renderMultiCurrency('meetDriver.onCurbByCurrencies')
            : this.renderInputCurrency('meetDriver.onCurbByCurrencies')}
          <Validator id="onCurbByCurrencies" callback={ValidatorCallback}>
            <ValidCase
              valid={
                formData.meetDriver.onCurbByCurrencies.filter(
                  (d) => !Validation.isStringEmpty(d.value) && isNaN(d.value)
                ).length == 0
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!this.props.isSubmited}
            />
            <ValidCase
              valid={
                formData.meetDriver.onCurbByCurrencies.filter(
                  (d) => !Validation.isGreaterOrEqual(d.value, 0)
                ).length == 0
              }
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
              hide={!this.props.isSubmited}
            />
          </Validator>
          <WarningCase
            validator={this.props.valid.onCurbByCurrencies}
            message={I18n.t('message.warningInputChangeOver')}
            initialValue={formData.meetDriver.onCurbByCurrencies.map(
              (ob) => ob.value
            )}
            onChangeValue={formData.meetDriver.onCurbByCurrencies.map(
              (ob) => ob.value
            )}
            range={20}
            typePercent={false}
          />
        </FormGroup>
        <FormGroup
          className={
            !this.props.isSubmited
              ? null
              : this.props.valid.meetDrvByCurrencies === false
              ? 'error'
              : null
          }
        >
          <Form.Label>
            <Translate value="generalSetting.Meet_Greet" />
          </Form.Label>
          {this.props.auth.selectedFleet.multiCurrencies
            ? this.renderMultiCurrency('meetDriver.meetDrvByCurrencies')
            : this.renderInputCurrency('meetDriver.meetDrvByCurrencies')}
          <Validator id="meetDrvByCurrencies" callback={ValidatorCallback}>
            <ValidCase
              valid={
                formData.meetDriver.meetDrvByCurrencies.filter(
                  (d) => !Validation.isStringEmpty(d.value) && isNaN(d.value)
                ).length == 0
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!this.props.isSubmited}
            />
            <ValidCase
              valid={
                formData.meetDriver.meetDrvByCurrencies.filter(
                  (d) => !Validation.isGreaterOrEqual(d.value, 0)
                ).length == 0
              }
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
              hide={!this.props.isSubmited}
            />
          </Validator>
        </FormGroup>
        <WarningCase
          validator={this.props.valid.meetDrvByCurrencies}
          message={I18n.t('message.warningInputChangeOver')}
          initialValue={formData.meetDriver.meetDrvByCurrencies.map(
            (ob) => ob.value
          )}
          onChangeValue={formData.meetDriver.meetDrvByCurrencies.map(
            (ob) => ob.value
          )}
          range={20}
          typePercent={false}
        />
        <FormGroup className="radio-group no-marginBottom">
          <Form.Label className="mr-r-50 mb0">
            <Translate value="generalSetting.Pay_to" />
          </Form.Label>
          <RadioButton
            text={I18n.t('newbooking.Fleet_Company')}
            inline
            name="meetDriver"
            value={1}
            checked={formData.meetDriver.payTo == 1}
            onChange={(e) => {
              this.handlePaytoChange(e, 'meetDriver');
            }}
            disabled={editable}
          />
          <RadioButton
            text={I18n.t('newbooking.Driver_Supplier')}
            inline
            name="meetDriver"
            value={0}
            checked={formData.meetDriver.payTo == 0}
            onChange={(e) => {
              this.handlePaytoChange(e, 'meetDriver');
            }}
            disabled={editable}
          />
        </FormGroup>
        {this.state.showModalAirportFee && (
          <ModalCustomizedAirportFee
            show={this.state.showModalAirportFee}
            onCloseModal={this.handleCloseModalCustomizedAirportFee}
            defaultAirportFee={formData.airport}
            editable={editable}
          />
        )}
      </div>
    );
  }
}

export default AirportFeeFormGroup;
