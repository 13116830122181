import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";
import {getBookingStatisticApi, getOthersInfoApi} from '../constants/ApiConfigs'

export function getBookingStatistic(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        getBookingStatisticApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getOtherInfo(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    let url = UrlBuilder(getOthersInfoApi, options)
    return callApi(
        UrlBuilder(getOthersInfoApi, options),
        config,
        null,
        null,
        null,
        true
    );
}