import React from 'react';
import {
  FormGroup, Form, Modal, Button
} from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as settingActions from '../../../../../actions/settingActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';

import './ModalCustomizedAirportFee.scss';
import AutoSuggestAirport from '../../../../../components/AutoSuggestAirport';
import AirportFeeComlumn from './AirportFeeColumn';
import { Validation } from '../../../../../utils/commonFunctions';

class ModalAirportFee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      settingActions: { getAllAirportFee },
      auth: { selectedFleet }
    } = this.props;

    getAllAirportFee({ fleetId: selectedFleet.fleetId }).then(({ res }) => {
      this.setState({ airportFee: res });
    });
  }

  handleSelectAirport = selected => {
    const { suggestion } = selected;
    const { defaultAirportFee } = this.props;
    const airportFeeAdded = _.extend({}, defaultAirportFee, {
      airportZoneId: suggestion
    });
    const { airportFee } = this.state;
    const existsAirport = airportFee.find(o => o.airportZoneId._id === suggestion._id);
    if (existsAirport) {
      return;
    }

    airportFee.push(airportFeeAdded);
    this.setState({ airportFee });
  };

  handleSubmit = () => {
    const {
      settingActions: { updateAllAirportFee },
      auth: { selectedFleet },
      onCloseModal
    } = this.props;
    const { airportFee } = this.state;
    const isVaid = this.validateBeforeSubmit(airportFee);
    if (isVaid) {
      const airportFeeUpdate = airportFee.map(airport => _.extend({}, airport, {
        airportZoneId: _.get(airport, 'airportZoneId._id', airport.airportZoneId)
      }));
      updateAllAirportFee({
        fleetId: selectedFleet.fleetId,
        airportFee: airportFeeUpdate
      }).then(() => {
        onCloseModal();
      });
    }
  };

  validateBeforeSubmit = airportFee => {
    const invalidAirports = airportFee.filter(o => {
      const isInValidToAirport = o.toAirportByCurrencies.findIndex(
        d => !Validation.isGreaterOrEqual(d.value, 0)
            || !Validation.isLessOrEqual(d.value, 9999999999)
      ) !== -1;
      const isInValidFromAirport = o.fromAirportByCurrencies.findIndex(
        d => !Validation.isGreaterOrEqual(d.value, 0)
            || !Validation.isLessOrEqual(d.value, 9999999999)
      ) !== -1;

      return isInValidToAirport || isInValidFromAirport;
    });
    return invalidAirports.length === 0;
  };

  onRemoveAirport = airport => {
    const { airportFee } = this.state;
    this.setState({
      airportFee: airportFee.filter(o => o.airportZoneId._id != airport.airportZoneId._id)
    });
  };

  onChangeAirport = airport => {
    const { airportFee } = this.state;
    _.each(airportFee, o => {
      if (o.airportZoneId._id === airport.airportZoneId._id) {
        o = airport;
      }
    });
    this.setState({ airportFee });
  };

  render() {
    const {
      show,
      onCloseModal,
      defaultAirportFee,
      auth: { selectedFleet },
      editable
    } = this.props;
    const { airportFee } = this.state;
    return (
      <Modal show={show} backdrop onHide={this.props.onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="generalSetting.customizedAirportfee" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.onCloseModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="airportModal__body">
          {editable ? null : (
            <FormGroup className="airportModal__search">
              <Form.Label>
                <Translate value="generalSetting.airportFee.titleSearchAiportZone" />
              </Form.Label>
              <AutoSuggestAirport onSelectAirport={this.handleSelectAirport} />
            </FormGroup>
          )}
          <div className="airportTable__header">
            <table className="table table-no-pagination">
              <thead>
                <tr>
                  <td className="tableAirport__airportCol">
                    <Translate value="generalSetting.airportFee.airport" />
                  </td>
                  {selectedFleet.multiCurrencies ? (
                    <td className="tableAirport__currencyCol">
                      <Translate value="generalSetting.airportFee.currency" />
                    </td>
                  ) : null}
                  <td className="tableAirport__fromAirportCol">
                    <Translate value="generalSetting.airportFee.fromAirportFee" />
                  </td>
                  <td className="tableAirport__toAirportCol">
                    <Translate value="generalSetting.airportFee.toAirportFee" />
                  </td>
                  <td className="tableAirport__actionCol" />
                </tr>
              </thead>
            </table>
          </div>
          <div className="airportTable__tbody">
            <table className="table table-no-pagination">
              <thead>
                <tr>
                  <th className="tableAirport__airportCol p0" />
                  {selectedFleet.multiCurrencies ? (
                    <th className="tableAirport__currencyCol p0" />
                  ) : null}
                  <th className="tableAirport__fromAirportCol  p0" />
                  <th className="tableAirport__toAirportCol p0" />
                  <th className="tableAirport__actionCol p0" />
                </tr>
              </thead>
              <tbody>
                {!airportFee || !airportFee.length ? (
                  <tr>
                    <td className="text-center" colSpan={5}>
                      <Translate value="reservation.noData" />
                    </td>
                  </tr>
                ) : null}
                {(airportFee || []).map((airport, index) => (
                  <AirportFeeComlumn
                    key={_.get(airport, 'airportZoneId._id', airport.airportZoneId)}
                    airport={airport}
                    index={index}
                    auth={this.props.auth}
                    permissions={this.props.auth}
                    onChange={this.onChangeAirport}
                    onRemoveAirport={this.onRemoveAirport}
                    defaultAirportFee={defaultAirportFee}
                    editable={editable}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <FormGroup className="text-center pt-md mb0">
            <Form.Label>
              <Translate value="generalSetting.noteAirportFeeModal" />
            </Form.Label>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          {editable ? null : (
            <Button
              className="btn-save mr-md"
              type="button"
              onClick={this.handleSubmit}
              disabled={editable}
            >
              <Translate value="generalSetting.Save" />
            </Button>
          )}
          <Button className="btn-cancel" onClick={onCloseModal}>
            <Translate value="generalSetting.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAirportFee);
