export const CarMgmtColumns = [
  {
    key: "palateNumber",
    label: "carSetting.Plate_Number",
    fixed: true,
    width: 230,
    textEllipsis: true
  },
  {
    key: "type.name",
    label: "carSetting.Car_Type"
  },
  {
    key: "hardwareMeter",
    label: "carSetting.Hardware_Meter"
  },
  {
    key: "vehicleModel.vehicleMake",
    label: "carSetting.Make"
  },
  {
    key: "vehicleModel.name",
    label: "carSetting.Car_Model"
  },
  {
    key: "company.name",
    label: "carSetting.Company",
    textEllipsis: true
  },
  {
    key: "createdDate",
    label: "carSetting.Created_Date",
    width: 200
  },
  {
    key: "regularStatus",
    label: "carSetting.Status",
    width: 100
  },
  {
    key: "actions",
    label: "carSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const CarTypeColumns = [
  {
    key: "carTypeID",
    label: "carSetting.Car_type_ID",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "appDisplayName",
    label: "carSetting.App_Display_Name",
    textEllipsis: true
  },
  {
    key: "dispatchTo",
    label: "carSetting.Dispatch_To",
    textEllipsis: true
  },
  {
    key: "normalFare",
    label: "carSetting.Normal_Fare",
    textEllipsis: true
  },
  {
    key: "sharingRate",
    label: "carSetting.sharing_Rate",
    textEllipsis: true
  },
  {
    key: "flatFare",
    label: "carSetting.Flat_Fare",
    textEllipsis: true
  },
  {
    key: "hourlyDailyRate",
    label: "carSetting.Hourly_daily_rate",
    textEllipsis: true
  },
  {
    key: "intercityRate",
    label: "carSetting.Intercity_Rate",
    textEllipsis: true
  },
  {
    key: "deliveryRate",
    label: "carSetting.Delivery_Rate",
    textEllipsis: true
  },
  {
    key: "actions",
    label: "carSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const MakeModalColumns = [
  {
    key: "Name",
    label: "carSetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "actions",
    label: "carSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];

export const CarColorColumns = [
  {
    key: "Name",
    label: "carSetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true
  },
  {
    key: "actions",
    label: "carSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
