import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  FormGroup,
  FormControl,
  InputGroup,
  Tab,
  Tabs,
  Button,
} from 'react-bootstrap';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../../components/validator';
import { currencies } from 'currency-formatter';

class InputViaCurrency extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      data: [
        {
          numOfPax: 1,
          value: 1,
        },
        {
          numOfPax: 2,
          value: 2,
        },
        {
          numOfPax: 3,
          value: 3,
        },
      ],
    };
  }

  renderSingleCurrency = (
    selectedFleet,
    rateInfo,
    editable,
    handleCancelInputMultiCurrenciesChange,
    fieldType,
    unitDistance,
    isSubmmitted
  ) => {
    const { valid } = this.state;
    console.log('unitDistance ', unitDistance);
    const isShowUnitDistance = unitDistance
      ? rateInfo[fieldType + 'Type'] === 'distance' ||
        fieldType === 'feePerMinute'
        ? true
        : false
      : false;
    return (
      <Col xs={12} md={12}>
        <FormGroup
          className={`qup-input-group ${
            isSubmmitted ? (valid[fieldType] === false ? 'error' : null) : null
          }`}
        >
          <InputGroup
            className={
              (isShowUnitDistance ? null : 'single-addon-left') +
              (!editable ? ' disabled' : '')
            }
          >
            <FormControl
              type="text"
              onChange={(e) => {
                handleCancelInputMultiCurrenciesChange(
                  fieldType,
                  selectedFleet.currencies[0].iso,
                  e
                );
              }}
              onBlur={(e) => {
                textboxNumberHelper.onBlurHandle(e, (e) => {
                  handleCancelInputMultiCurrenciesChange(
                    fieldType,
                    selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              onFocus={(e) => {
                textboxNumberHelper.onfocusHandle(e, (e) => {
                  handleCancelInputMultiCurrenciesChange(
                    fieldType,
                    selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              value={
                rateInfo
                  ? rateInfo.feesByCurrencies.filter((d) => {
                      return d.currencyISO == selectedFleet.currencies[0].iso;
                    }) &&
                    rateInfo.feesByCurrencies.filter((d) => {
                      return d.currencyISO == selectedFleet.currencies[0].iso;
                    }).length > 0
                    ? rateInfo.feesByCurrencies.filter((d) => {
                        return d.currencyISO == selectedFleet.currencies[0].iso;
                      })[0][fieldType]
                    : DefaultCurenciesNumberInputField
                  : DefaultCurenciesNumberInputField
              }
              placeholder={I18n.t(`deliveryRate.${fieldType}`)}
              disabled={!editable}
            />
            <InputGroup.Prepend>
              <InputGroup.Text>
                {selectedFleet.currencies[0].symbol}
              </InputGroup.Text>
            </InputGroup.Prepend>{' '}
            {isShowUnitDistance ? (
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <span>/ </span>
                  {unitDistance}
                </InputGroup.Text>
              </InputGroup.Prepend>
            ) : null}
          </InputGroup>

          <Validator id={fieldType} callback={this.validatorCallback}>
            <ValidCase
              valid={
                rateInfo.feesByCurrencies.filter((d) => {
                  return (
                    !Validation.isStringEmpty(d[fieldType]) &&
                    isNaN(d[fieldType])
                  );
                }).length == 0
              }
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={
                rateInfo.feesByCurrencies.filter((d) => {
                  return !Validation.isGreaterOrEqual(d[fieldType], 0);
                }).length == 0
              }
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
              hide={!isSubmmitted}
            />
          </Validator>
        </FormGroup>
        <WarningFeeInput
          message={I18n.t('message.warningInputChangeOver')}
          className="text-soft-warning"
          disabled={!editable}
          value={
            rateInfo
              ? rateInfo.feesByCurrencies.filter((d) => {
                  return d.currencyISO == selectedFleet.currencies[0].iso;
                }) &&
                rateInfo.feesByCurrencies.filter((d) => {
                  return d.currencyISO == selectedFleet.currencies[0].iso;
                }).length > 0
                ? rateInfo.feesByCurrencies.filter((d) => {
                    return d.currencyISO == selectedFleet.currencies[0].iso;
                  })[0].fieldType
                : DefaultCurenciesNumberInputField
              : DefaultCurenciesNumberInputField
          }
          timeDelay={500}
          typeOfCheck="higherOfLower"
        />
      </Col>
    );
  };

  handleRateCurrenciesChange = (e, currenciesIso, index) => {
    let valueInput = e.target.value;
    valueInput = valueInput.replace(/[^0-9. ]/g, '');
    valueInput = valueInput.replace(/\s/g, '');
    let feesByCurrencies = this.props.feesByCurrencies[0];
    let feePerPaxNew = feesByCurrencies.feePerPax || [];
    feePerPaxNew[index].value = valueInput;
    this.props.handleCancelInputMultiCurrenciesChange(
      this.props.fieldType,
      currenciesIso,
      feePerPaxNew
    );
  };

  handleAddButtonClick = (currencies) => {
    let feesByCurrencies = this.props.feesByCurrencies[0];
    let feePerPaxNew = feesByCurrencies.feePerPax || [];
    feePerPaxNew.push({
      numOfPax: feePerPaxNew.length + 1,
      value: 0,
    });
    this.props.handleCancelInputMultiCurrenciesChange(
      this.props.fieldType,
      currencies.iso,
      feePerPaxNew
    );
  };

  handleRemoveButtonClick = (currencies) => {
    let feesByCurrencies = this.props.feesByCurrencies[0];
    let feePerPaxNew = feesByCurrencies.feePerPax || [];
    feePerPaxNew.pop();
    this.props.handleCancelInputMultiCurrenciesChange(
      this.props.fieldType,
      currencies.iso,
      feePerPaxNew
    );
  };

  renderInput = (
    currencies,
    value,
    index,
    editable,
    fieldType,
    isSubmmitted
  ) => {
    return (
      <FormGroup
        className={'qup-input-group not-margin'}
      >
        <InputGroup
          className={'single-addon-left' + (!editable ? ' disabled' : '')}
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{currencies.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            maxLength={15}
            onChange={(e) => {
              this.handleRateCurrenciesChange(
                e,
                currencies.iso,
                index,
                editable
              );
            }}
            onBlur={(e) => {
              textboxNumberHelper.onBlurHandle(e, (e) => {
                this.handleRateCurrenciesChange(
                  e,
                  currencies.iso,
                  index,
                  editable
                );
              });
            }}
            onFocus={(e) => {
              textboxNumberHelper.onfocusHandle(e, (e) => {
                this.handleRateCurrenciesChange(
                  e,
                  currencies.iso,
                  index,
                  editable
                );
              });
            }}
            value={value}
            className="form-custom"
            disabled={!editable}
          />
        </InputGroup>

        <Validator id={fieldType} callback={this.validatorCallback}>
          {/* <ValidCase
								valid={
										rateInfo.feesByCurrencies.filter(d => {
												return (
														!Validation.isStringEmpty(d[fieldType]) &&
														isNaN(d[fieldType])
												);
										}).length == 0
								}
								message={I18n.t(
										"messages.commonMessages.must_be_number"
								)}
								hide={!isSubmmitted}
						/>
						<ValidCase
								valid={
										rateInfo.feesByCurrencies.filter(d => {
												return !Validation.isGreaterOrEqual(
														d[fieldType],
														0
												);
										}).length == 0
								}
								message={I18n.t(
										"messages.commonMessages.greater_or_equa"
								).format(0)}
								hide={!isSubmmitted}
						/> */}
        </Validator>
      </FormGroup>
    );
  };

  render() {
    const {
      selectedFleet = {},
      feesByCurrencies = [],
      rateInfo,
      editable,
      handleCancelInputMultiCurrenciesChange,
      multiCurrency,
      fieldType,
      unitDistance,
      isSubmmitted,
    } = this.props;
    let currencies = selectedFleet.currencies[0];
    let feePerPax = feesByCurrencies[0] && feesByCurrencies[0].feePerPax;
    return (
      <div className="input-with-validator">
        {/* <div className="title_SharingRate"> */}
        <div
          className="title_SharingRate"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <p>Fare based on number of passenger</p>
          <Button
            className="btn-header text-add-header ml0"
            onClick={(e) => this.handleAddButtonClick(currencies)}
            disabled={!editable}
          >
            {' '}
            <Translate value="deliveryRate.Add" />
          </Button>
        </div>
        <table className="table table-no-pagination cc-table-striped">
          <thead>
            <tr>
              <th>Number of pax</th>
              <th>Rate/ {selectedFleet.unitDistance}</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {feePerPax.map((row, index) => (
              <tr>
                <td>{row.numOfPax}</td>
                <td>
                  {this.renderInput(currencies, row.value, index, editable)}
                </td>
                <td>
                  {feePerPax.length === index + 1 && index != 0 ? (
                    <Button
                      className="btn-header text-add-header ml0"
                      onClick={(e) => this.handleRemoveButtonClick(currencies)}
                      disabled={!editable}
                    >
                      <Translate value="deliveryRate.Remove" />
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

InputViaCurrency.defaultProps = {
  currencyISO: 'USD',
  editable: false,
  feesByCurrencies: [
    {
      feePerMinute: 0,
      startingFee: 0,
      minimumFee: 0,
      currencyISO: 'USD',
      feePerPax: [],
    },
  ],
  selectedFleet: {},
};
export default InputViaCurrency;
