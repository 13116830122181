import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { Modal } from 'react-bootstrap';
import './dispatchlogs.scss';
import MapGps from './customMap/mapGps';
import rightPanelClose from '../../assets/images/icons/rightPanelClose.svg';
import rightPanelOpen from '../../assets/images/icons/rightPanelOpen.svg';
import closeGps from '../../assets/images/icons/close-gps.svg';
import { reportBookingLogGpsApi } from '../../constants/ApiConfigs';
import * as fieldMappings from './fields';
import {
  greenGroup,
  redGroup,
  greyGroup,
  purpleGroup,
  orangeGroup,
  blueGroup,
} from './groupItem';
import moment from 'moment';
import _ from 'lodash';
import ReportUtils from '../../containers/reports/base/utils';
import redPoint from '../../assets/images/icons/redPoint.svg';
import purplePoint from '../../assets/images/icons/purplePoint.svg';
import greenPoint from '../../assets/images/icons/greenPoint.svg';
import greyPoint from '../../assets/images/icons/greyPoint.svg';
import trackingPointDrop from '../../assets/images/icons/trackingPointDrop.svg';
import trackingPointPick from '../../assets/images/icons/trackingPointPick.svg';
import pickupNew from '../../assets/images/icons/pickupNew.svg';
import destinationNew from '../../assets/images/icons/destinationNew.svg';
import ModalDetailLogs from './components/modalDetailLogs';
import { mapIcons, thirdPartyIntegration } from '../../constants/commondata';
import { CCLiteCommonFunc, getURLToCheck3rdBooking } from '../../utils/commonFunctions';

let mapComponent = null;

const FormModalLogGps = (props) => {
  const {
    commonData,
    map,
    auth,
    data,
    selectedFleet,
    reportActions,
    loadingActions,
    showModal,
    setShowModal,
    type,
  } = props;
  const [dataBooking, setDataBooking] = useState({});
  const [logsData, setLogsData] = useState({});
  const [showMap, setShowMap] = useState(true);
  const [isCheckedAll, setIsCheckedAll] = useState(true);

  const [panToLocation, setPanToLocation] = useState(null);
  const [countChangeQQMap, setCountChangeQQMap] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [listActiveKey, setListActiveKey] = useState([]);
  const [listMarker, setListMarker] = useState([]);
  const [lastPoint, setLastPoint] = useState({});
  const [typeDelivery, setTypeDelivery] = useState(false);
  const [url3rdBooking, setUrl3rdInfo] = useState({});

  useEffect(() => {
    if (data) {
      setDataBooking(data);
      setLastPoint(
        data?.originDestinationGeoLon && data?.originDestinationGeoLat
          ? {
              geo: [
                data?.originDestinationGeoLon,
                data?.originDestinationGeoLat,
              ],
            }
          : data?.request?.origin?.destination
      );
      if (type === 'report') {
        setTypeDelivery(data?.bookingType === 'delivery' ? true : false);
      } else {
        setTypeDelivery(data?.delivery);
      }
      setUrl3rdInfo(getInfoURL3rdBooking(data));
    }
  }, [data]);

  useEffect(() => {
    if (dataBooking.bookId && showModal) {
      getDispatchLogsHistory(dataBooking.bookId);
    }
  }, [showModal, dataBooking.bookId]);

  const getDispatchLogsHistory = (bookId) => {
    loadingActions.showLoadingSpiner();
    let params = {
      fleetId: _.get(selectedFleet, 'fleetId', null),
      locale: localStorage.getItem('language') || 'en',
      localTimezone: moment.tz.guess(),
      criteria: {
        bookId: bookId,
        timezone: selectedFleet.timezone || '',
      },
      from: 0,
      size: 1000,
    };

    reportActions
      .fetchReportDataFromApi(reportBookingLogGpsApi, params)
      .then((resp) => {
        loadingActions.hideLoadingSpiner();
        setLoading(false);
        if (resp.ok) {
          resp.res.list = resp?.res?.list
            ?.filter(
              (item) =>
                item.action !== 'droppedOff' &&
                item.action !== 'arrived' &&
                item.action !== 'collecting'
            )
            .map((item) => {
              return {
                ...item,
                isChecked: true,
              };
            });
          if (dataBooking) {
            let puPointData = (
              !typeDelivery
                ? dataBooking?.puPointsGps || dataBooking?.puPoints
                : dataBooking?.deliveryInfo?.merchants?.length > 0
                ? dataBooking?.deliveryInfo?.merchants
                : [dataBooking?.deliveryInfo?.pickup?.address]
            ).map((val) => val?.geo || val?.address?.geo);
            let doPointData = (
              !typeDelivery
                ? dataBooking?.doPointsGps || dataBooking?.doPoints
                : dataBooking?.deliveryInfo?.recipients
            ).map((val) => val?.geo || val?.address?.geo);
            let points = [...puPointData, ...doPointData];
            points = points.filter((item) => item);
            if (points?.length > 0) {
              let sumLat = 0;
              let sumLng = 0;
              for (const point of points) {
                sumLat += point[1];
                sumLng += point[0];
              }
              const midLat = sumLat / points.length;
              const midLng = sumLng / points.length;
              setPanToLocation({ lat: midLat, lng: midLng });
            }
          }
          setListActiveKey(resp.res?.list?.map((item) => item.isChecked));
          setLogsData(resp.res);
        } else {
          setErrors(true);
          setShowModal(false);
        }
      });
  };

  const handleCheckedPan = (index, doc, checked, isAll, isPan) => {
    if (isPan) {
      handlePanToMarker(doc, index);
    } else {
      let defaultCheckedList = [...listActiveKey];
      let defaultListMarker = [...listMarker];
      if (!mapComponent) return;
      if (isAll) {
        defaultListMarker.forEach((item) => {
          item.marker && item.marker.setMap(checked ? mapComponent : null);
          item.child &&
            item.child.forEach((mak) =>
              mak.setMap(checked ? mapComponent : null)
            );
        });
        defaultCheckedList = defaultCheckedList?.map((val) => (val = checked));
        setIsCheckedAll(checked);
      } else {
        if (defaultListMarker[index]?.marker) {
          defaultListMarker[index].marker.setMap(checked ? mapComponent : null);
        } else {
          defaultListMarker[index]?.child &&
            defaultListMarker[index].child.forEach((mak) =>
              mak.setMap(checked ? mapComponent : null)
            );
        }
        defaultCheckedList[index] = checked;
        handlePanToMarker(doc, index, defaultCheckedList);
      }
      // setCountChangeQQMap(countChangeQQMap + 1);
      setListActiveKey(defaultCheckedList);
      setListMarker(defaultListMarker);
    }
  };

  const closeButton = () => {
    setShowModal(false);
    setLogsData({});
    setLoading(true);
    setErrors(false);
  };

  const handleUpdateMap = async (map) => {
    if (props.commonData.location && props.commonData.location.isChina) {
      const mapComponent = map;
      let markerList = [];

      if (dataBooking) {
        let puPointData = (
          !typeDelivery
            ? dataBooking?.puPointsGps || dataBooking?.puPoints
            : dataBooking?.deliveryInfo?.merchants?.length > 0
            ? dataBooking?.deliveryInfo?.merchants
            : [dataBooking?.deliveryInfo?.pickup?.address]
        ).map((val) => val?.geo || val?.address?.geo);
        let doPointData = (
          !typeDelivery
            ? dataBooking?.doPointsGps || dataBooking?.doPoints
            : dataBooking?.deliveryInfo?.recipients
        ).map((val) => val?.geo || val?.address?.geo);
        let points = JSON.parse(
          JSON.stringify([...puPointData, ...doPointData])
        );
        points = points.filter((item) => item);
        if (lastPoint?.geo?.length > 0) {
          points = points.concat([lastPoint?.geo]);
        }

        if (logsData?.list?.length > 0) {
          let pointAction = logsData?.list
            ?.filter((item) => item.location !== 'N/A')
            .map((item) => {
              let arr = item.location.split(', ');
              return [parseFloat(arr[0]), parseFloat(arr[1])];
            });
          points = points.concat(pointAction);
        }

        if (points?.length >= 2) {
          let bounds = new window.qq.maps.LatLngBounds();
          for (const point of points) {
            point &&
              bounds.extend(new window.qq.maps.LatLng(point[1], point[0]));
          }
          mapComponent.fitBounds(bounds);
        }
        let currentPuPoints = JSON.parse(
          JSON.stringify(
            !typeDelivery
              ? dataBooking?.puPointsGps || dataBooking?.puPoints
              : dataBooking?.deliveryInfo?.merchants?.length > 0
              ? dataBooking?.deliveryInfo?.merchants
              : [dataBooking?.deliveryInfo?.pickup?.address]
          )
        );
        if (currentPuPoints) {
          currentPuPoints?.forEach((item, index) => {
            let geoData = item?.geo || item?.address?.geo;
            if (geoData?.length > 0) {
              let image = new Image();
              image.src = pickupNew;
              let canvas = document.createElement('canvas');
              let context = canvas.getContext('2d');
              let txtFill = (index + 1).toString();
              image.onload = function () {
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);

                context.font = '16px Arial';
                context.fillStyle = 'white';
                context.fillText(txtFill, 15, 27);
                context.drawImage(image, 0, 0);
              };
            }
          });
        }

        let currentDoPoint = JSON.parse(
          JSON.stringify(
            !typeDelivery
              ? dataBooking?.doPointsGps || dataBooking?.doPoints
              : dataBooking?.deliveryInfo?.recipients
          )
        );
        Array.isArray(currentDoPoint) && currentDoPoint?.pop();
        if (lastPoint?.geo?.length > 0) {
          Array.isArray(currentDoPoint) && currentDoPoint?.push(lastPoint);
        }
        if (currentDoPoint) {
          currentDoPoint?.forEach((item, index) => {
            let geoData = item?.geo || item?.address?.geo;
            if (geoData?.length > 0) {
              let image = new Image();
              image.src = destinationNew;
              let canvas = document.createElement('canvas');
              let context = canvas.getContext('2d');
              let txtFill = (
                ((!typeDelivery
                  ? dataBooking?.puPointsGps || dataBooking?.puPoints
                  : dataBooking?.deliveryInfo?.merchants?.length > 0
                  ? dataBooking?.deliveryInfo?.merchants
                  : [dataBooking?.deliveryInfo?.pickup?.address]
                )?.length || 0) +
                index +
                1
              ).toString();
              image.onload = function () {
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);

                context.font = '16px Arial';
                context.fillStyle = 'white';
                context.fillText(txtFill, 15, 27);
                context.drawImage(image, 0, 0);
              };
            }
          });
        }
      }
      if (logsData?.list?.length > 0) {
        logsData?.list?.forEach((item, index) => {
          let currentPoint = null;
          let size = null;
          if (redGroup.includes(item.action)) {
            currentPoint = redPoint;
            size = new window.qq.maps.Size(15, 15);
          } else if (greenGroup.includes(item.action)) {
            currentPoint = greenPoint;
            size = new window.qq.maps.Size(15, 15);
          } else if (purpleGroup.includes(item.action)) {
            currentPoint = purplePoint;
            size = new window.qq.maps.Size(15, 15);
          } else if (greyGroup.includes(item.action)) {
            currentPoint = greyPoint;
            size = new window.qq.maps.Size(15, 15);
          }

          let listPoint = [];
          const isPickOfDriver =
            item.action === 'Driver on the way' ||
            item.action === 'otwMerchant' ||
            item.action === 'Driver on the way to sender';
          const isDropOfPax =
            item.action === 'Passenger on board' ||
            item.action === 'Delivering' ||
            item.action === 'delivering';
          if (isPickOfDriver || isDropOfPax) {
            listPoint = item.child
              .filter((val) => val.location !== 'N/A')
              .map((val) => {
                let newArr = val.location.split(', ');
                [newArr[0], newArr[1]] = [newArr[1], newArr[0]];
                return newArr;
              });
          }

          let arr = item.location.split(', ');
          [arr[0], arr[1]] = [arr[1], arr[0]];

          if (currentPoint) {
            const customIcon = new window.qq.maps.MarkerImage(
              currentPoint,
              size,
              new window.qq.maps.Point(0, 0)
            );
            const marker = new window.qq.maps.Marker({
              position: new window.qq.maps.LatLng(arr[0], arr[1]),
              map: mapComponent,
              icon: customIcon,
              zIndex: 40,
            });
            window.windowInfoGPS = new window.qq.maps.InfoWindow({
              map: mapComponent,
            });
            markerList.push({ marker: marker });
          } else {
            let objectListPoint = {
              marker: null,
            };
            if (listPoint?.length > 0) {
              let childList = [];
              listPoint.forEach((valPoint) => {
                const customIcon = new window.qq.maps.MarkerImage(
                  (isPickOfDriver && trackingPointPick) ||
                    (isDropOfPax && trackingPointDrop),
                  new window.qq.maps.Size(10, 10),
                  new window.qq.maps.Point(0, 0)
                );
                const childMarker = new window.qq.maps.Marker({
                  position: new window.qq.maps.LatLng(valPoint[0], valPoint[1]),
                  map: mapComponent,
                  icon: customIcon,
                  zIndex: 0,
                });
                childList.push(childMarker);
              });
              objectListPoint['child'] = childList;
            }
            markerList.push(objectListPoint);
            setListMarker(markerList);
            window.windowInfoGPS = new window.qq.maps.InfoWindow({
              map: mapComponent,
            });
          }
        });
      }
    } else {
      if (map) {
        mapComponent = map;
        const markerList = [];

        if (dataBooking) {
          const puPointData = (!typeDelivery
              ? dataBooking?.puPointsGps || dataBooking?.puPoints
              : dataBooking?.deliveryInfo?.merchants?.length > 0
                ? dataBooking?.deliveryInfo?.merchants
                : [dataBooking?.deliveryInfo?.pickup?.address]
          ).map((val) => val?.geo || val?.address?.geo);

          const doPointData = (!typeDelivery
              ? dataBooking?.doPointsGps || dataBooking?.doPoints
              : dataBooking?.deliveryInfo?.recipients
          ).map((val) => val?.geo || val?.address?.geo);

          let points = JSON.parse(JSON.stringify([...puPointData, ...doPointData]));
          points = points.filter((item) => item);

          if (lastPoint?.geo?.length > 0) {
            points = points.concat([lastPoint?.geo]);
          }

          if (logsData?.list?.length > 0) {
            const pointAction = logsData?.list
              ?.filter((item) => item.location !== 'N/A')
              .map((item) => {
                const arr = item.location.split(', ');
                return [parseFloat(arr[0]), parseFloat(arr[1])];
              });
            points = points.concat(pointAction);
          }

          if (points?.length >= 2) {
            const bounds = new google.maps.LatLngBounds();
            for (const point of points) {
              point &&
              bounds.extend(new google.maps.LatLng(point[1], point[0]));
            }
            mapComponent.fitBounds(bounds);
          }
        }
        logsData?.list?.length > 0 && logsData.list.forEach((item, index) => {
          let currentPoint = null;
          const markerSize = 15;
          const childMarkerSize = 10;

          if (redGroup.includes(item.action)) {
            currentPoint = redPoint;
          } else if (greenGroup.includes(item.action)) {
            currentPoint = greenPoint;
          } else if (purpleGroup.includes(item.action)) {
            currentPoint = purplePoint;
          } else if (greyGroup.includes(item.action)) {
            currentPoint = greyPoint;
          }

          let listPoint = [];
          const isPickOfDriver = ['Driver on the way', 'otwMerchant', 'Driver on the way to sender'].includes(item.action);
          const isDropOfPax = ['Passenger on board', 'Delivering', 'delivering'].includes(item.action);

          if (isPickOfDriver || isDropOfPax) {
            listPoint = item.child
              .filter((val) => val.location !== 'N/A')
              .map((val) => {
                const newArr = val.location.split(', ');
                [newArr[0], newArr[1]] = [newArr[1], newArr[0]];
                return newArr;
              });
          }

          let arr = item.location.split(', ');
          [arr[0], arr[1]] = [arr[1], arr[0]];

          if (currentPoint && arr[0] && arr[1]) {
            const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
              { lat: parseFloat(arr[0]), lng: parseFloat(arr[1]) },
              currentPoint,
              ''
              , 50, markerSize, markerSize);
            marker.map = map;
            markerList.push({ marker });
          } else {
            let objectListPoint = { marker: null };
            if (listPoint?.length > 0) {
              const childList = [];
              listPoint.forEach((val) => {
                const childMarker = CCLiteCommonFunc.createAdvancedMarkerPoint(
                  { lat: parseFloat(val[0]), lng: parseFloat(val[1]) },
                  isPickOfDriver ? trackingPointPick : trackingPointDrop,
                  ''
                  , 0, childMarkerSize, childMarkerSize);
                childMarker.map = map;
                childList.push(childMarker);
              });
              objectListPoint['child'] = childList;
            }
            markerList.push(objectListPoint);
          }
        });
        setListMarker(markerList);
        window.windowInfoGPS = new google.maps.InfoWindow({
          map: mapComponent,
        });
      }
    }
  };

  const handlePanToMarker = (doc, index, listActiveK) => {
    const removeInfoKey = [
      'Driver on the way',
      'otwMerchant',
      'Driver on the way to sender',
      'Passenger on board',
      'Delivering',
      'delivering',
    ];

    let defaultListActiveKey = listActiveK || listActiveKey;
    let newArrLoca = doc.location.split(', ');
    [newArrLoca[0], newArrLoca[1]] = [newArrLoca[1], newArrLoca[0]];
    window.windowInfoGPS.close();
    if (props.commonData.location && props.commonData.location.isChina) {
      if (mapComponent && defaultListActiveKey[index]) {
        mapComponent.setCenter(
          new window.qq.maps.LatLng(
            parseFloat(newArrLoca[0]),
            parseFloat(newArrLoca[1])
          )
        );
        if (!removeInfoKey.includes(doc.action)) {
          window.windowInfoGPS.setPosition(mapComponent.getCenter());
          window.windowInfoGPS
            .setContent(`<div style="color: black !important; font-size: 14px;">
              <span style="font-weight: 700;">
                ${defaultListActiveKey?.length - index}. ${
            doc.action[0].toLowerCase() === doc.action[0]
              ? doc.action[0].toUpperCase() + doc.action.substring(1)
              : doc.action
          }
              </span>
              -
              ${
                doc
                  ? ReportUtils.formatTime(doc.date, null, {
                      formatStr: 'MM/DD/YYYY hh:mm:ss A',
                    })
                  : 'N/A'
              }
            </div>`);
          window.windowInfoGPS.open(mapComponent, mapComponent.getCenter());
        } else {
          let listPoint = doc.child
            .filter((val) => val.location !== 'N/A')
            .map((val) => {
              let newArr = val.location.split(', ');
              [newArr[0], newArr[1]] = [newArr[1], newArr[0]];
              return newArr;
            });
          let indexPointMid = parseInt(listPoint.length / 2);
          mapComponent.setCenter(
            new window.qq.maps.LatLng(
              parseFloat(listPoint[indexPointMid][0]),
              parseFloat(listPoint[indexPointMid][1])
            )
          );
        }
      }
    } else {
      if (mapComponent && defaultListActiveKey[index]) {
        if (!removeInfoKey.includes(doc.action)) {
          mapComponent.setCenter(
            new google.maps.LatLng(
              parseFloat(newArrLoca[0]),
              parseFloat(newArrLoca[1])
            )
          );
          window.windowInfoGPS.setOptions({
            content: `<div style="color: black !important; font-size: 14px;">
              <span style="font-weight: 700;">
              ${defaultListActiveKey?.length - index}. ${
              doc.action[0].toLowerCase() === doc.action[0]
                ? doc.action[0].toUpperCase() + doc.action.substring(1)
                : doc.action
            }
              </span>
              -
              ${
                doc
                  ? ReportUtils.formatTime(doc.date, null, {
                      formatStr: 'MM/DD/YYYY hh:mm:ss A',
                    })
                  : 'N/A'
              }
            </div>`,
            maxWidth: 400,
            pixelOffset: new google.maps.Size(0, -17),
          });
          window.windowInfoGPS.setPosition(mapComponent.getCenter());
          window.windowInfoGPS.open(mapComponent, mapComponent.getCenter());
        } else {
          let listPoint = doc.child
            .filter((val) => val.location !== 'N/A')
            .map((val) => {
              let newArr = val.location.split(', ');
              [newArr[0], newArr[1]] = [newArr[1], newArr[0]];
              return newArr;
            });
        }
      }
    }
  };

  const getInfoURL3rdBooking = (dataBooking) => {
    const externalId =
      dataBooking?.externalId || dataBooking?.externalInfo?.bookingReference;
    const result = {
      show: false,
      url: '',
      alt: `Ref: ${externalId}`,
    };
    if (
      [
        thirdPartyIntegration.bookingAPI,
        thirdPartyIntegration.holidaytaxis,
        thirdPartyIntegration.hoppa,
      ].includes(dataBooking.bookFrom)
    ) {
      result.show = true;
      result.url = getURLToCheck3rdBooking(dataBooking);
    }
    return result;
  };

  let showDispatchLogs = showModal && !loading && !errors;

  return (
    showDispatchLogs ? (
      <Modal
        show={showModal}
        backdrop="static"
        dialogClassName="book-detail-model"
        bsSize="lg"
        onHide={() => closeButton()}
      >
        <Modal.Body
          style={{
            backgroundColor: '#24272E',
            padding: 0,
            height: '100%',
            color: 'black',
          }}
        >
          <div className="modal-layout-log-gps">
            <div
              className={showMap ? 'left-side' : 'left-side-hide-map'}
              style={{
                width: showMap ? '60vw' : '100vw',
                padding: '20px 0',
                transition: 'width 0.5s linear',
                height: '100%',
              }}
            >
              <div className="layout-header">
                <div className="header">
                  <p>
                    {dataBooking && dataBooking.bookId ? (
                      <>
                        <span>BOOKING #{dataBooking.bookId} </span>
                        <span>
                          {url3rdBooking?.show && (
                            <span>
                              (
                              <a
                                style={{
                                  color: '#53b1fd',
                                  textDecoration: 'underline',
                                }}
                                href={url3rdBooking?.url}
                                target="_blank"
                              >
                                {url3rdBooking?.alt}
                              </a>
                              )
                            </span>
                          )}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <div>
                    <img
                      onClick={() => setShowMap(!showMap)}
                      src={showMap ? rightPanelClose : rightPanelOpen}
                      alt={showMap ? 'rightPanelClose' : 'rightPanelOpen'}
                    />
                    <img
                      onClick={closeButton}
                      className="close"
                      src={closeGps}
                      alt="closeIcon"
                    />
                  </div>
                </div>
              </div>

              <div
                className="modal-report-table-container"
                style={{
                  maxHeight: 'calc(100% - 30px)',
                  width: '100%',
                  overflowX: 'auto',
                  overflowY: 'auto',
                }}
              >
                <table
                  style={{ width: '100%' }}
                  className="cc-table cc-table-striped"
                >
                  <thead>
                    <tr>
                      <th className="modal-report-table-header">
                        <div className="container">
                          <label style={{ cursor: 'pointer' }}>
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                handleCheckedPan(
                                  null,
                                  null,
                                  e.target.checked,
                                  true,
                                  false
                                );
                              }}
                              checked={isCheckedAll}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </th>
                      <th
                        className="modal-report-table-header"
                        style={{ padding: '20px' }}
                      >
                        No.
                      </th>
                      {fieldMappings.dispatchHistoryFields.map((item) => (
                        <th
                          key={item.key}
                          className="modal-report-table-header"
                          style={{ padding: 20 }}
                        >
                          <Translate value={item.label} />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {logsData &&
                      logsData?.list?.length > 0 &&
                      logsData?.list?.map((doc, index) => {
                        let color = '#FFFFFF';
                        if (greenGroup.includes(doc.action)) {
                          color = '#12B76A';
                        } else if (redGroup.includes(doc.action)) {
                          color = '#F04438';
                        } else if (greyGroup.includes(doc.action)) {
                          color = '#98A2B3';
                        } else if (purpleGroup.includes(doc.action)) {
                          color = '#7B61FF';
                        } else if (orangeGroup.includes(doc.action)) {
                          color = '#F79009';
                        } else if (blueGroup.includes(doc.action)) {
                          color = '#2E90FA';
                        }

                        return (
                          <>
                            <tr
                              style={{
                                opacity: doc?.location === 'N/A' ? 0.7 : 1,
                              }}
                              key={index}
                            >
                              <td className="modal-report-table-header">
                                <div className="container">
                                  <label style={{ cursor: 'pointer' }}>
                                    <input
                                      type="checkbox"
                                      disabled={doc?.location === 'N/A' && true}
                                      onChange={(e) => {
                                        handleCheckedPan(
                                          index,
                                          doc,
                                          e.target.checked,
                                          false,
                                          false
                                        );
                                      }}
                                      checked={
                                        doc?.location === 'N/A'
                                          ? true
                                          : listActiveKey[index]
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              </td>
                              <td
                                onClick={() => {
                                  if (doc.location !== 'N/A') {
                                    handleCheckedPan(
                                      index,
                                      doc,
                                      null,
                                      null,
                                      true
                                    );
                                  }
                                }}
                                className="modal-report-table-header"
                                style={{ padding: '20px' }}
                              >
                                {logsData?.list?.length - index}
                              </td>
                              {fieldMappings.dispatchHistoryFields.map(
                                (item) => {
                                  let nameItem = doc[item.key];
                                  if (doc[item.key] === 'passenger') {
                                    nameItem = I18n.t(
                                      `report.result.dispatchHistory.${
                                        doc[item.key]
                                      }`
                                    );
                                  }
                                  if (
                                    item.key === 'location' &&
                                    doc[item.key] !== 'N/A'
                                  ) {
                                    let arr = nameItem.split(', ');
                                    [arr[0], arr[1]] = [arr[1], arr[0]];
                                    nameItem = arr
                                      .toString()
                                      .replace(',', ', ');
                                  }

                                  return doc?.changes?.length ? (
                                    <td
                                      key={item.key}
                                      className="modal-report-table-row-content"
                                    >
                                      <ModalDetailLogs keyItem={item.key} data={doc}>
                                        {(item.mutate &&
                                          item.mutate(
                                            doc[item.key],
                                            doc,
                                            typeDelivery
                                          )) ||
                                          nameItem}
                                      </ModalDetailLogs>
                                    </td>
                                  ) : (
                                    <td
                                      key={item.key}
                                      className="modal-report-table-row-content"
                                      style={{
                                        padding: 20,
                                        color:
                                          item.key === 'action'
                                            ? color
                                            : '#FFFFFF',
                                      }}
                                      onClick={() => {
                                        if (doc.location !== 'N/A') {
                                          handleCheckedPan(
                                            index,
                                            doc,
                                            null,
                                            null,
                                            true
                                          );
                                        }
                                      }}
                                    >
                                      {(item.mutate &&
                                        item.mutate(
                                          doc[item.key],
                                          doc,
                                          typeDelivery
                                        )) ||
                                        nameItem}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className={showMap ? 'right-side' : 'right-side-hide-map'}
              style={{
                width: showMap ? '40vw' : '0vw',
                transition: 'width 0.5s linear',
              }}
            >
              {logsData?.list?.length > 0 && (
                <MapGps
                  auth={auth}
                  map={map}
                  commonData={commonData}
                  logsDataList={logsData.list}
                  panToLocation={panToLocation}
                  countChangeQQMap={countChangeQQMap}
                  dataBooking={dataBooking}
                  mapComponent={mapComponent}
                  handleUpdateMap={handleUpdateMap}
                  lastPoint={lastPoint}
                  typeDelivery={typeDelivery}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    ) : null
  );
};

export default FormModalLogGps;
