import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import RadioButton from '../../../components/radioButton/radio';
import { Validator, ValidCase } from '../../../components/validator';
import { Validation } from '../../../utils/commonFunctions';

class LimitSettingFormItem extends React.Component {
  handleTotalUsesLimitChange = isLimited => {
    const { itemKey, itemValue, onChange } = this.props;
    onChange(itemKey, _.extend({}, itemValue, { isLimited }));
  };

  handleTotalUsesValueChange = e => {
    const { itemKey, itemValue, onChange } = this.props;
    onChange(itemKey, _.extend({}, itemValue, { value: e.target.value }));
  };

  renderLabel = key => {
    switch (key) {
      case 'totalUsesLimit':
        return <Translate value="promotionSettings.Total_uses_per_user" />;
      case 'quantity':
        return <Translate value="promotionSettings.Released_quantity" />;
      case 'totalUsesLimitPerDay':
        return <Translate value="promotionSettings.totalUsesLimitPerDay" />;
      case 'quantityLimitPerDay':
        return <Translate value="promotionSettings.quantityLimitPerDay" />;
      case 'quantityLimitPerMonth':
        return <Translate value="promotionSettings.quantityLimitPerMonth" />;
      case 'quantityLimitPerYear':
        return <Translate value="promotionSettings.quantityLimitPerYear" />;
      default:
        return null;
    }
  };

  render() {
    const { itemKey, disable, diabledInput, valid, isSubmited, limitAmount } = this.props;
    let { itemValue } = this.props;
    itemValue = itemValue || {};
    return (
      <Row>
        <Col xs={12} md={12}>
          <div className="row-limit-edition">
            <Form.Label className="row-limit-edition-label">
              {this.renderLabel(itemKey)}
            </Form.Label>

            <span className="mr-l-10 mr-r-10 row-limit-edition-check">
              <RadioButton
                text={I18n.t('promotionSettings.Unlimited')}
                onChange={e => {
                  this.handleTotalUsesLimitChange(false);
                }}
                checked={!itemValue.isLimited}
                name={itemKey}
                disabled={disable}
              />
            </span>
            <span className="mr-l-10 mr-r-10 row-limit-edition-check">
              <RadioButton
                text={I18n.t('promotionSettings.Limited')}
                onChange={e => {
                  this.handleTotalUsesLimitChange(true);
                }}
                checked={itemValue.isLimited}
                name={itemKey}
                disabled={disable}
              />
            </span>
            <div className="row-limit-edition-input">
              {itemValue.isLimited ? (
                <FormGroup
                  className={
                    !isSubmited ? null : valid[itemKey] ? null : 'error'
                  }
                >
                  <FormControl
                    type="number"
                    step={0}
                    min={0}
                    className="form-custom"
                    onChange={this.handleTotalUsesValueChange}
                    onWheel={e => e.preventDefault()}
                    value={itemValue.value}
                    disabled={diabledInput}
                  />
                  <Validator
                    id={itemKey}
                    callback={this.props.ValidatorCallback}
                  >
                    <ValidCase
                      valid={!Validation.isStringEmpty(itemValue.value)}
                      message={I18n.t('promotionSettings.Please_input_value')}
                      hide={!isSubmited}
                    />
                    <ValidCase
                      valid={Validation.isInteger(itemValue.value)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                      hide={!isSubmited}
                    />
                    {limitAmount ? (
                      <ValidCase
                        valid={Validation.isLessOrEqual(itemValue.value, limitAmount || 0)}
                        message={I18n.t(
                          'messages.commonMessages.less_or_equal'
                        ).format(limitAmount)}
                        hide={!isSubmited}
                      />
                    ) : null}
                  </Validator>
                </FormGroup>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default LimitSettingFormItem;
