import {
  carTypeBaseLocationApi,
  checkCustomerBookingApi,
  nearestDriverApi,
  checkPromoCodeApi,
  addPromoCodeUseApi,
  getCrosszoneRateApi,
  affiliateNearestDriverApi,
  getDriverReservationBookingApi,
  reportThirdPartyLocationApi,
  getSecureFieldSessionTSYSAPI,
  getCheckoutPageAPI,
  checkZoneSupportedByPoint,
  createNewBookingAPI
} from '../constants/ApiConfigs';
import { callApi, UrlBuilder } from '../utils/apiUtils';

export const CAR_TYPE_BASE_LOCATION_REQUEST = 'CAR_TYPE_BASE_LOCATION_REQUEST';
export const CAR_TYPE_BASE_LOCATION_SUCCESS = 'CAR_TYPE_BASE_LOCATION_SUCCESS';
export const CAR_TYPE_BASE_LOCATION_FAILURE = 'CAR_TYPE_BASE_LOCATION_FAILURE';

export const CHECK_CUSTOMER_BOOKING_REQUEST = 'CHECK_CUSTOMER_BOOKING_REQUEST';
export const CHECK_CUSTOMER_BOOKING_SUCCESS = 'CHECK_CUSTOMER_BOOKING_SUCCESS';
export const CHECK_CUSTOMER_BOOKING_FAILURE = 'CHECK_CUSTOMER_BOOKING_FAILURE';

export const CHECK_NEAREST_DRIVER_REQUEST = 'CHECK_NEAREST_DRIVER_REQUEST';
export const CHECK_NEAREST_DRIVER_SUCCESS = 'CHECK_NEAREST_DRIVER_SUCCESS';
export const CHECK_NEAREST_DRIVER_FAILURE = 'CHECK_NEAREST_DRIVER_FAILURE';

export const NEW_BOOKING_FROM_EXISTED = 'NEW_BOOKING_FROM_EXISTED';
export const NEWBOOKING_FORM_REQUEST = 'NEWBOOKING_FORM_REQUEST';
export const CLOSE_NEWBOOKING_FORM = 'CLOSE_NEWBOOKING_FORM';

export const GET_CROSSZONE_INFO_SUCCESS = 'GET_CROSSZONE_INFO_SUCCESS';

export function getReportThirdPartyLocation (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(reportThirdPartyLocationApi, config, null, null, null, false);
}

export function checkZoneSupportedWithPoint (options = {}) {
  const config = {
    method: 'POST',
    body: JSON.stringify(options),
  };

  return callApi(checkZoneSupportedByPoint, config, null, null, null, true);
}

function getCarTypeBaseLocationRequest () {
  return {
    type: CAR_TYPE_BASE_LOCATION_REQUEST,
  };
}

function getCarTypeBaseLocationSuccess (data) {
  return {
    type: CAR_TYPE_BASE_LOCATION_SUCCESS,
    data: data.res,
  };
}

function getCarTypeBaseLocationFailure (error) {
  return {
    type: CAR_TYPE_BASE_LOCATION_FAILURE,
    error,
  };
}

export function getCarTypeBaseLocation (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    carTypeBaseLocationApi,
    config,
    getCarTypeBaseLocationRequest(),
    getCarTypeBaseLocationSuccess,
    getCarTypeBaseLocationFailure
  );
}

function checkCustomerBookingRequest () {
  return {
    type: CHECK_CUSTOMER_BOOKING_REQUEST,
  };
}

function checkCustomerBookingSuccess (data) {
  return {
    type: CHECK_CUSTOMER_BOOKING_SUCCESS,
    data,
  };
}

function checkCustomerBookingFailure (error) {
  return {
    type: CHECK_CUSTOMER_BOOKING_FAILURE,
    error,
  };
}

export function checkCustomerBooking (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    checkCustomerBookingApi,
    config,
    checkCustomerBookingRequest(),
    checkCustomerBookingSuccess,
    checkCustomerBookingFailure
  );
}

function checkNearestDriverRequest () {
  return {
    type: CHECK_NEAREST_DRIVER_REQUEST,
  };
}

function checkNearestDriverSuccess (data) {
  return {
    type: CHECK_NEAREST_DRIVER_SUCCESS,
    data: data.res,
  };
}

function checkNearestDriverFailure (error) {
  return {
    type: CHECK_NEAREST_DRIVER_FAILURE,
    error,
  };
}

export function checkNearestDriver (options = {}, crossZone) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    crossZone ? affiliateNearestDriverApi : nearestDriverApi,
    config,
    checkNearestDriverRequest(),
    checkNearestDriverSuccess,
    checkNearestDriverFailure
  );
}
export function checkpromoCode (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(checkPromoCodeApi, config);
}

export function addPromoCodeUse (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(addPromoCodeUseApi, config);
}

export function newBookingRequest (bookType) {
  return { type: NEWBOOKING_FORM_REQUEST, bookType };
}
export function closeNewBookingForm () {
  return { type: CLOSE_NEWBOOKING_FORM };
}

export function newBookingFromExistedBooking (data) {
  return {
    type: NEW_BOOKING_FROM_EXISTED,
    data,
  };
}

function getCrossZoneRateSucess (data) {
  data.res.response.packagesRate = data.res.response.packageRates;
  delete data.res.response.packageRates;
  return {
    type: GET_CROSSZONE_INFO_SUCCESS,
    data: data.res.response,
  };
}
export function getCrossZoneRate (options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getCrosszoneRateApi, config, null, getCrossZoneRateSucess);
}

export function getSecureFieldSessionTSYS (options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(getSecureFieldSessionTSYSAPI, options), config, null, null, null, true);
}

export function getCheckoutPage (options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(getCheckoutPageAPI, options), config, null, null, null, true);
}

export function createNewBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(createNewBookingAPI, config, null, null, null, true);
}

export function getDriverReservationBooking (options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(
    getDriverReservationBookingApi,
    config,
    null,
    null,
    null,
    true
  );
}
