import React, { Component } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as settingActions from '../../../../actions/settingActions';
import DefaultTopupValuesForm from './DefaultTopupValuesForm';
import { CCLiteCommonFunc } from '../../../../utils/commonFunctions';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import {
    Col,
    Row,
    Button
} from 'react-bootstrap';

const keyApps = ['passenger', 'driver'];

class DefaultTopupValues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid: {},
            driverApp: [],
            passengerApp: [],
            driverLimitTopup: {
                minimumByCurrencies: [{
                    currencyISO: 'MYR',
                    value: ''
                }],
                maximumByCurrencies: [{
                    currencyISO: 'MYR',
                    value: ''
                }],
                valueByCurrencies: [{
                    currencyISO: 'MYR',
                    value: ''
                }]
            },
            passengerLimitTopup: {
                minimumByCurrencies: [{
                    currencyISO: 'MYR',
                    value: ''
                }],
                maximumByCurrencies: [{
                    currencyISO: 'MYR',
                    value: ''
                }],
            },
            isdriverSubmited: false,
            ispassengerSubmited: false,
            activeKeys: {},
            isSaveButtonDisable: true
        }
    }

    tabMultiSelectHandle = (activeKey, keyApp) => {
        if (activeKey !== this.state.activeKeys[`${keyApp}App`]) {
            this.setState({
                activeKeys: {
                    ...this.state.activeKeys,
                    [`${keyApp}App`]: activeKey
                }
            });
        }
    }

    getDisableSaveButton = (dataApp) => {
        const driverApp = dataApp.driverApp.map(c => c.values.filter(vl => vl.value > 0))
        const passengerApp = dataApp.passengerApp.map(c => c.values.filter(vl => vl.value > 0))

        const dataNotEnough3InputsDriverApp = driverApp.find(d => d.length !== driverApp[0].length);
        if (driverApp && driverApp.length && dataNotEnough3InputsDriverApp) return true;

        const dataNotEnough3InputsPassengerApp = passengerApp.find(d => d.length !== passengerApp[0].length);
        if (passengerApp && passengerApp.length && dataNotEnough3InputsPassengerApp) return true;

        const drvMinimumByCurrencies = _.get(dataApp.driverLimitTopup, 'minimumByCurrencies[0].value'),
            drvMaxByCurrencies = _.get(dataApp.driverLimitTopup, 'maximumByCurrencies[0].value'),
            drvValueByCurrencies = _.get(dataApp.driverLimitTopup, 'valueByCurrencies[0].value'),
            paxMinimumByCurrencies = _.get(dataApp.passengerLimitTopup, 'minimumByCurrencies[0].value'),
            paxMaxByCurrencies = _.get(dataApp.passengerLimitTopup, 'maximumByCurrencies[0].value')

        const enableDriverDeposit = _.get(this.props.auth, 'selectedFleet.driverDeposit', false)
        const enablePaxCreditWallet = _.get(this.props.auth, 'selectedFleet.paxCreditWallet', false)
        
        if(enableDriverDeposit) {
            if(this.props.auth.selectedFleet && driverApp && driverApp.find(item => item.length === 0)) return true
            if(drvMinimumByCurrencies != '0') {
                if(!drvMinimumByCurrencies || 
                    drvMinimumByCurrencies.length === 0 ||
                    !drvMaxByCurrencies || 
                    drvMaxByCurrencies.length === 0) {
                        return true
                    }
                if(drvValueByCurrencies != '0') {
                    if(!drvValueByCurrencies || 
                        drvValueByCurrencies.length === 0) {
                            return true
                    }
                }
            } else {
                if(!drvMaxByCurrencies || 
                    drvMaxByCurrencies.length === 0) {
                        return true
                    }
                    if(drvValueByCurrencies != '0') {
                        if(!drvValueByCurrencies || 
                            drvValueByCurrencies.length === 0) {
                                return true
                        }
                    }
            }
        }

        if(enablePaxCreditWallet) {
            if(this.props.auth.selectedFleet && enablePaxCreditWallet && passengerApp && passengerApp.find(item => item.length === 0)) return true
            if(paxMinimumByCurrencies != '0') {
                if(!paxMinimumByCurrencies || 
                    paxMinimumByCurrencies.length === 0 ||
                    !paxMaxByCurrencies || 
                    paxMaxByCurrencies.length === 0) {
                        return true
                    }
            } else {
                if(!paxMaxByCurrencies || 
                    paxMaxByCurrencies === 0) {
                        return true
                    }
            }
        }

        return false
    }

    ValidatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    handleSaveClick = () => {
        this.setState({ isSubmited: true });

        /* Get valid data from State with keyApp, ex: if keyApp = driverApp => get all data valid like {driverAppUSD: true, driverAppERU: false} ...*/
        const { valid } = this.state;
        /* const validDataofKey = {};
        if (valid) {
            for (let key in valid) {
                if (key.startsWith(keyApp)) validDataofKey[key] = valid[key]
            }
        } */

        /* Get fail key of valid data to detect what active Tabs id. Ex: if fail key is "driverAppVND" => tabs will switch to focus "VND" tab */
        let activeKeyFail = CCLiteCommonFunc.getFailValidKey(valid);
        if (activeKeyFail) {
            /* Find app form that have fail data currency */
            const keyAppActive = keyApps.find(k => activeKeyFail.startsWith(k));
            if (keyAppActive) {
                this.setState({
                    activeKeys: {
                        ...this.state.activeKeys,
                        /* Set state to know to focus tabs */
                        [`${keyAppActive}App`]: activeKeyFail.replace(`${keyAppActive}App`, '')
                    }
                });
                return;
            }
        }
        const dataDriverLimitTopupInput = this.state.driverLimitTopup;
        const dataPassengerLimitTopupInput = this.state.passengerLimitTopup;
        if((this?.props?.driverCreditWallet && dataDriverLimitTopupInput?.maximumByCurrencies[0]?.value <= dataDriverLimitTopupInput?.minimumByCurrencies[0]?.value) ||
            (this?.props?.passengerCreditWallet && dataPassengerLimitTopupInput?.maximumByCurrencies[0]?.value <= dataPassengerLimitTopupInput?.minimumByCurrencies[0]?.value)
            ) return;

        const {
            settingActions: { updateDefaultTopupValues },
            auth: { selectedFleet }
        } = this.props;

        /* Generate data to update */
        const dataUpdate = {
            fleetId: selectedFleet.fleetId,
            /* get data to update only by keyApp, ex: if keyApp = "driverApp" => only get driverApp state to update */
            //[keyApp]: this.state[keyApp]
            driverApp: this.state.driverApp,
            passengerApp: this.state.passengerApp,
            driverDeposit: this.state.driverLimitTopup,
            paxCreditWallet: this.state.passengerLimitTopup,
        };

        this.props.loadingBarActions.showLoadingSpiner();
        updateDefaultTopupValues(dataUpdate)
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                this.setState({ isSubmited: false });
                if (data.ok && data.res) {
                    this.context.notification('success', I18n.t('generalSetting.Update_general_success'));
                    const { driverApp, passengerApp } = data.res || {};
                    if (data.ok && data.res) {
                        this.setState({
                            driverApp,
                            passengerApp
                        });
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 1 * 1000);
                } else if (data.error) {
                    this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
                } else {
                    this.context.notification('error', I18n.t('generalSetting.Update_general_fail'));
                }
            });
    }

    handleUpdateTopupValuesState = (currencyISO, value, keyApp, index) => {
        if (value === null || value === undefined || value === '') value = '';
        else if (isNaN(value)) return; /* Dont update if value exists but not a number */
        /* Generate change value */
        const changeValues = { currencyISO, value };

        /* Set default app data if app data is empty or not exists */
        const newState = this.state[keyApp].map((vl, i) => {
            if (i === index) {
                const values = vl.values;
                /* Check if curency with value was existed in state data */
                const existsValue = values ? values.find(value => (value.currencyISO === changeValues.currencyISO)) : null;
                /* Replace value if existed */
                if (existsValue) {
                    const newValus = changeValues.value && parseFloat(changeValues.value) > 0 ? values.map(value => {
                        if (value.currencyISO === changeValues.currencyISO) return {
                            currencyISO: changeValues.currencyISO,
                            value: changeValues.value
                        };
                        return value;
                    }) : /* If new value not more than 0, remove it */ values.filter(value => value.currencyISO !== changeValues.currencyISO);
                    return {
                        values: newValus
                    }
                }
                /* Return new value if not existed */
                return {
                    values: changeValues.value && parseFloat(changeValues.value) > 0 ? [
                        ...vl.values,
                        changeValues
                    ] : vl.values
                };
            }
            return vl;
        });
        const dataApp = {
            ...this.state,
            [keyApp]: newState
        }
        const isSaveButtonDisable = this.getDisableSaveButton(dataApp);
        this.setState({
            [keyApp]: newState,
            isSaveButtonDisable
        });
    }

    handleUpdateLimitTopup = (key, value, keyApp) => {
        // if(value === '0') return
        if (value === null || value === undefined || value === '') value = '';
        else if (isNaN(value)) {
            if(key === 'valueByCurrencies') {
                let chartFirst = value.charAt(0)
                let chartSecond = value.charAt(1)
                if(chartFirst !== '-') {
                    return
                } else {
                    const findDuplicateCharacter = (str, char) =>
                        str.split(char).length - 1;
                    const result = findDuplicateCharacter(value, '-');
                    if(result !== 1) {
                        return
                    } else {
                        if(chartSecond === '0') return
                        if(value.length > 1 && String(Number(value)) !== value) return
                    }
                }
            } else {
                return;
            }
        } 
        let stateLimit
        let isSaveButtonDisable
        switch (keyApp) {
            case 'passenger':
                stateLimit = {...this.state.passengerLimitTopup};
                stateLimit[key][0].value = value;
                isSaveButtonDisable = this.getDisableSaveButton({...this.state, passengerLimitTopup: stateLimit});
                this.setState({
                    passengerLimitTopup: stateLimit,
                    isSaveButtonDisable
                })
                break;
            case 'driver':
                stateLimit = {...this.state.driverLimitTopup};
                stateLimit[key][0].value = value;
                isSaveButtonDisable = this.getDisableSaveButton({...this.state, driverLimitTopup: stateLimit});
                this.setState({
                    driverLimitTopup: stateLimit,
                    isSaveButtonDisable
                })
                break;
        }
    }

    componentDidMount() {
        const {
            settingActions: { getDefaultTopupValues },
            auth: { selectedFleet }
        } = this.props;
        getDefaultTopupValues({ fleetId: selectedFleet.fleetId })
            .then(data => {
                if (data.ok && data.res) {
                    const defaultData = [
                        { values: [] },
                        { values: [] },
                        { values: [] }
                    ];
                    const { driverApp = defaultData, passengerApp = defaultData, driverDeposit, paxCreditWallet } = data.res;
                    const driverAppData = defaultData.map((dt, i) => {
                        if (driverApp[i]) return driverApp[i];
                        return dt;
                    });
                    const passengerAppData = defaultData.map((dt, i) => {
                        if (passengerApp[i]) return passengerApp[i];
                        return dt;
                    });
                    const isSaveButtonDisable = this.getDisableSaveButton({
                        driverApp: driverAppData,
                        passengerApp: passengerAppData,
                        driverLimitTopup: driverDeposit,
                        passengerLimitTopup: paxCreditWallet,
                    });
                    this.setState({
                        driverApp: driverAppData,
                        passengerApp: passengerAppData,
                        driverLimitTopup: driverDeposit,
                        passengerLimitTopup: paxCreditWallet,
                        isSaveButtonDisable
                    });
                }
            });
    }

    render() {
        const { auth, permissions, auth: { selectedFleet } } = this.props;
        const { paxCreditWalletConfig: passengerCreditWalletConfig, driverDepositConfig: driverCreditWalletConfig } = selectedFleet || {};
        const {
            driverApp,
            passengerApp,
            driverLimitTopup,
            passengerLimitTopup,
            valid,
            activeKeys,
            isSaveButtonDisable = true
        } = this.state;
        const hasPermission = permissions && permissions.actions;
        return <Row className="default-top-up">
            {
                keyApps.map(keyApp => {
                    const appActived = this.props[`${keyApp}CreditWallet`]; {/* passengerCreditWallet or driverCreditWallet */ }
                    return !appActived ? null : (
                        <Col md={4} sm={12} key={keyApp}>
                            <DefaultTopupValuesForm
                                ValidatorCallback={this.ValidatorCallback}
                                handleSaveClick={this.handleSaveClick}
                                auth={auth}
                                hasPermission={hasPermission}
                                keyApp={keyApp}
                                key={keyApp}
                                driverApp={driverApp}
                                passengerApp={passengerApp}
                                driverLimitTopup={driverLimitTopup}
                                passengerLimitTopup={passengerLimitTopup}
                                handleUpdateTopupValuesState={this.handleUpdateTopupValuesState}
                                handleUpdateLimitTopup={this.handleUpdateLimitTopup}
                                isSubmited={this.state.isSubmited}
                                valid={valid}
                                tabMultiSelectHandle={this.tabMultiSelectHandle}
                                activeKeys={activeKeys}
                                passengerCreditWalletConfig={passengerCreditWalletConfig}
                                driverCreditWalletConfig={driverCreditWalletConfig}
                            />
                        </Col>
                    )
                })

            }
            {
                hasPermission && (
                    <Col xs={12}>
                        <div className="text-left mv-md default-top-up__requiredfieldstext">
                            (<span className="require">*</span>): <Translate value="driver.Required_fields" />
                        </div>
                        <Button className={'btn-save'} disabled={isSaveButtonDisable} onClick={() => this.handleSaveClick()}>
                            <Translate value="generalSetting.Save" />
                        </Button>
                    </Col>
                )
            }
        </Row>
    }
}

DefaultTopupValues.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        /* commonData: state.commonData, */
        auth: state.auth,
        settings: state.settings,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DefaultTopupValues);