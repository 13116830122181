import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { FormGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as newbookingActions from '../../../actions/newbookingAction';
import moment from 'moment';

class PromotionForm extends PureComponent {
  state = {
    promoCode: '',
  };

  onClickApplyPromo = () => {
    const { common, selectedFleet, psgInfo, request, isBookDetail } = this.props;
    if (common.promo) {
      this.props.intercityBookingActions.updateCommonData({
        promo: null,
      });
      this.props.intercityBookingActions.etaFare();
    } else {
      const promotionObject = {
        fleetId: selectedFleet.fleetId,
        pickupTime: moment(common.pickupDate)
          .hour(common.pickupTime.hour)
          .minute(common.pickupTime.minute)
          .format('YYYY-MM-DD HH:mm'),
        promotionCode: this.state.promoCode,
        userId: psgInfo ? psgInfo.userId : '',
        bookFrom: 'CC',
        currencyISO: common.routeSelected.currencyISO,
        geo: [common.pickupLocation.lng, common.pickupLocation.lat],
        serviceType: 1, // 0: transport, 1: intercity, 2: parcel, 3: food, 4: mart
        etaFare: common.etaFare && common.etaFare.etaFare ? common.etaFare.etaFare : 0,
        paymentType: request.paymentType,
      };

      this.props.newbookingActions
        .checkpromoCode(promotionObject)
        .then((data) => {
          if (data.returnCode === 200) {
            this.props.intercityBookingActions.updateCommonData({
              promo: {
                ...data.response,
                promoCode: this.state.promoCode,
                promoValue: data.response
                  ? data.response.type == 'Amount'
                    ? currencyFormatter.format(-data.response.value, {
                      code: common.routeSelected.currencyISO,
                    })
                    : `${-data.response.value}%`
                  : ''
              },
            });
            this.props.intercityBookingActions.etaFare();
          } else if (data.returnCode) {
            this.context.notification(
              'error',
              I18n.t(`messages.promoMsg.${data.returnCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('messages.booking.Check_promo_error')
            );
          }
        });
    }
  };

  handleChangePromo = (e) => {
    this.setState({ promoCode: e.target.value.toUpperCase() });
  };

  render() {
    const { request, valid, common, psgInfo, isBookDetail } = this.props;
    let { promoCode } = this.state;
    if (common.promo) {
      promoCode = common.promo.promoCode;
    }
    const buttonDisable = !promoCode || isBookDetail;
    const promoDisabled =
      !psgInfo.phone ||
      !common.pickupTime ||
      !common.pickupDate ||
      common.promo || isBookDetail
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.PromoTitle" />
        </FormGroupTitle>
        <FormGroup>
          <div className="add-on-input-group mb">
            <FormControl
              type="text"
              onChange={this.handleChangePromo}
              value={(promoCode || '').toUpperCase()}
              placeholder={I18n.t('newbooking.Promo_code')}
              disabled={promoDisabled}
              className="form-custom"
            />
            {!buttonDisable && (
              <span
                variant="success"
                className="add-on-button"
                onClick={this.onClickApplyPromo}
              >
                {common.promo
                  ? I18n.t('newbooking.Remove')
                  : I18n.t('newbooking.Apply')}
              </span>
            )}
          </div>
          {common.promo && common.promo.promoValue ? (
            <div>
              <Translate value="newbooking.Promo" className="promotion-value" />&nbsp;
              <span className="promotion-value">
                {common.promo.promoValue}
              </span>
            </div>
          ) : null}
        </FormGroup>
      </Fragment>
    );
  }
}

PromotionForm.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    common: state.intercityBooking.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PromotionForm);
