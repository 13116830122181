import React from 'react';
import currencyFormatter from 'currency-formatter';
export const Columns = [
  {
    key: "name",
    label: "regularSetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "latestUpdate",
    label: "regularSetting.Last_Update",
    width: 200
  },
  {
    key: "regularStatus",
    label: "regularSetting.Status",
    width: 100
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];


export const RouterTableColumns = [
  {
    key: "routeName",
    label: "intercityRoute.alias",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "route1Fare",
    label: "regularSetting.Route_1_Fare",
    customCell: route => {
      let renderList = route.routeOneSetting.pricePerSeat.length > 3
          ? route.routeOneSetting.pricePerSeat.slice(0, 3)
          : route.routeOneSetting.pricePerSeat;
      return (
        <div>
          {renderList.map(item => {
            return (
              <div>
                {currencyFormatter.format(item.value, {
                  code: item.currencyISO
                })}
              </div>
            )
          })}
          {route.routeOneSetting.pricePerSeat.length >
            3 && <div>...</div>}
        </div>
      );
    }
  },
  {
    key: "route2Fare",
    label: "regularSetting.Route_2_Fare",
    customCell: route => {
      let renderList = route.routeTwoSetting.pricePerSeat.length > 3
          ? route.routeTwoSetting.pricePerSeat.slice(0, 3)
          : route.routeTwoSetting.pricePerSeat;
      if (!route.routeTwoEnable) {
        return null;
      }
      return (
        <div>
          {renderList.map(item => (
            <div>
              {
                currencyFormatter.format(item.value, {
                  code: item.currencyISO
                })}
            </div>
          ))}
          {route.routeTwoSetting.pricePerSeat.length >
            3 && <div>...</div>}
        </div>
      );
    }
  },
  {
    key: "latestUpdate",
    label: "regularSetting.Last_Update",
    width: 200
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
