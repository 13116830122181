import ReportUtils from './../base/utils';
import React from 'react';
import _ from 'lodash';
import { BsStarFill } from 'react-icons/bs';
var check = {};
export const fields = [
  {
    key: "fullName",
    label: "report.result.customerQuest.name"
  },
  {
    key: "phoneNumber",
    label: "report.result.customerQuest.phoneNumber"
  },
  {
    key: "supportId",
    label: "report.result.customerQuest.supportId",
    isShowEvent: data  => {
      return (data.type === 'passenger')
    }
  },
  {
    key: "username",
    label: "report.result.customerQuest.userName",
    isShowEvent: data  => {
      return (data.type === 'driver')
    }
  },
  {
    key: "completedDate",
    label: "report.result.customerQuest.completedDate",
    mutate: (value, doc) => (value
        ? ReportUtils.formatTime(value, doc, {
      formatStr: 'MM/DD/YYYY hh:mm:ss A'
    })
        : 'N/A'),
    isShowEvent: data  => {
      return ((data.type === 'driver' || data.type === 'passenger') &&  (data.event !== 'all' && data.event !== 'largest will win' ))
    }
  },
  {
    key: "isQualified",
    label: "report.result.customerQuest.isQualified",
    isShowEvent: data  => {
      return ((data.type === 'driver' || data.type === 'passenger') &&  (data.event !== 'all' && data.event !== 'largest will win' ))
    },
    mutate: (value, doc) => (value
        ? 'Qualified'
        : 'Not qualified')
  },
  {
    key: "ticket",
    label: "report.result.customerQuest.ticket",
    isShowEvent: data  => {
      return ((data.type === 'driver' || data.type === 'passenger') &&  (data.event !== 'all' && data.event !== 'largest will win' ))
    }
  },

  {
    key: "ranking",
    label: "report.result.customerQuest.ranking",
    isShowEvent: data  => {
      return ((data.type === 'driver' || data.type === 'passenger')  &&  ( data.event === 'largest will win' ))
    }
  },
  {
    key: "points",
    label: "report.result.customerQuest.points",
    isShowEvent: data  => {
      return ((data.type === 'driver' || data.type === 'passenger') &&  ( data.event === 'largest will win' ))
    },
    mutate: (value, doc) => (Math.round(value))
  },
  {
    key: "bookingDistance",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_distance'});
      return check;
    },
    label: data  => {
      var label = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_distance'});
      return label ? label.task : "" ;
    },
    distanceUnit:true,
    translate:true,
    headerUnit: "unitDistance",
    width: 230
  },
  {
    key: "bookingTime",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_time'});
      return check;
    },
    label: data  => {
      const label = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_time'});
      return label ? label.task : "" ;
    },
    translate:true,
    headerUnit: <span>&nbsp;(mins)</span>,
    width: 230
  },
  {
    key: "bookingAmountSpent",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_amount_spent'});
      return check;
    },
    label: data  => {
      const label = _.find(data.eventQuest,(a) => { return a.metricType === 'booking_amount_spent'});
      return label ? label.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfOndemand",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_ondemand'});
      return check;
    },
    label: data  => {
      const label = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_ondemand'});
      return label ? label.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfReservation",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_reservation'});
      return check;
    },
    label: data  => {
      const label = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_reservation'});
      return label ? label.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfIntercity",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_intercity'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_intercity'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfDelivery",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_delivery'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_delivery'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numberOfFoodMart",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'number_of_food_mart'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'number_of_food_mart'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfReferral",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_referral'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_referral'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfComplete",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_complete'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_complete'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfSharing",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_sharing'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_sharing'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "topupWalletAmount",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'topup_wallet_amount'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'topup_wallet_amount'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfCompleteWallet",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_complete_wallet'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_complete_wallet'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numberOfCompleteBooking",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'number_of_complete_booking'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'number_of_complete_booking'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "numOfCompleteBookingByReferee",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_complete_booking_by_referee'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_complete_booking_by_referee'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },
  {
    key: "availableTime",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'available_time'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'available_time'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230,
    mutate: (value, doc) => (value/60)
  },
  {
    key: "numOfReferralDriver",
    isShowEvent: data  => {
      check = _.find(data.eventQuest,(a) => { return  a.metricType === 'num_of_referral_driver'});
      return check;
    },
    label: data  => {
      const quest = _.find(data.eventQuest,(a) => { return a.metricType === 'num_of_referral_driver'});
      return quest ? quest.task : "" ;
    },
    translate:true,
    width: 230
  },

  {
    key: "acceptanceRating",
    isShowEvent: data  => {
      check = _.find(data.eventCriterias,(a) => { return  a.criteriaType === 'acceptance_rating'});
      return check;
    },
    mutate: (value, doc) => _.get(doc, 'criterias.acceptanceRating', 0) >= 100 ? 100 : _.get(doc, 'criterias.acceptanceRating', 0),
    label: "report.result.customerQuest.acceptanceRating",
    headerUnit: <span>&nbsp;(%)</span>
  },
  {
    key: "cancellationRating",
    isShowEvent: data  => {
      check = _.find(data.eventCriterias,(a) => { return  a.criteriaType === 'cancellation_rating'});
      return check;
    },
    mutate: (value, doc) => _.get(doc, 'criterias.cancellationRating', 0) >= 100 ? 100 : _.get(doc, 'criterias.cancellationRating', 0),
    label: "report.result.customerQuest.cancellationRating",
    headerUnit: <span>&nbsp;(%)</span>
  },
  {
    key: "driverRating",
    isShowEvent: data  => {
      check = _.find(data.eventCriterias,(a) => { return   a.criteriaType === 'driver_rating'});
      return check;
    },
    mutate: (value, doc) => _.get(doc, 'criterias.driverRating', 0),
    label: "report.result.customerQuest.driverRating",
    headerUnit: <BsStarFill className="qup-color" />,
  }
];
export const floatFields = ['availableTime','acceptanceRating','cancellationRating','driverRating','bookingAmountSpent','bookingTime'];
export const fareFields = ['bookingAmountSpent','topupWalletAmount']