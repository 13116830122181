import React from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../../settings.scss";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup
} from "react-bootstrap";
import FormGroupTitle from "../../../../components/formGroupTitile/FormGroupTitle";
import * as intercityRateActions from "../../../../actions/intercityRateActions";
import * as loadingBarActions from "../../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase, WarningFeeInput } from "../../../../components/validator";
import {
CCLiteCommonFunc,
Validation,
textboxNumberHelper
} from "../../../../utils/commonFunctions";
import {
DefaultCurenciesNumberInputField
} from "../../../../constants/commondata";
import CcCheckbox from "../../../../components/ccCheckbox/CcCheckbox";
import QuestionCircleTooltip from "../../../../components/questionCircleTooltip/QuestionCircleTooltip";
import _ from "lodash";
import IntercityRoute from './IntercityRoute';

var currencyFormatter = require("currency-formatter");

export class AddEditIntercityRateModal extends React.PureComponent {
  state = {
    valid: {},
    editable: this.props.editable,
    isSubmmitted: false,
    rateInfo: {
      fleetId: this.props.auth.selectedFleet.fleetId,
      name: ""
    }
  };

  componentDidMount() {
    const { rateInfo } = this.props;
    if (rateInfo && rateInfo._id) {
      this.props.intercityRateActions
        .getRateDetailIntercityRate({
          _id: rateInfo._id,
          fleetId: this.props.auth.selectedFleet.fleetId
        })
        .then(res => {
          if (res.ok) {
            this.setState({ rateInfo: res.res});
          } else {
            if (res.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + res.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("regularSetting.Loading_intercity_rate_fail")
              );
            }
          }
        });
    }
  }

  handleEdit = () => {
    this.setState({ editable: true })
  }

  handleInputChange = (key, e) => {
    const { rateInfo } = this.state;
    rateInfo[key] = e.target.value;
    this.setState({ rateInfo: { ...rateInfo }, dialogChanged: true });
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  saveDialogForm = () => {
    const { rateInfo } = this.state;
    const body = _.cloneDeep(rateInfo);
    body.fleetId = this.props.auth.selectedFleet.fleetId;
    this.setState({ isSubmmitted: true });
    if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
      this.props.loadingBarActions.showLoadingSpiner();
      if (body._id) {
        this.props.intercityRateActions
          .updateIntercityRate(body)
          .then(data => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (!data.ok) {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("regularSetting.Update_intercity_rate_fail")
                );
              }
            } else {
              this.context.notification(
                "success",
                I18n.t("regularSetting.Update_intercity_rate_success")
              );
              this.props.onSuccess();
            }
          });
      } else {
        this.props.intercityRateActions
          .createIntercityRate(body)
          .then(data => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
              this.context.notification(
                "success",
                I18n.t("regularSetting.Created_intercity_rate_success")
              );
              this.setState({ rateInfo: data.res, isSubmmitted: false, editable: false });
              this.props.onSuccess(true);
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("regularSetting.Created_intercity_rate_fail")
                );
              }
            }
          });
      }
    }
  };

  render() {
    const { rateInfo, valid, isSubmmitted } = this.state;
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    return (
      <Modal
        show={true}
        backdrop={true}
        dialogClassName="intercityRateModal"
        onHide={this.props.closeDialogForm}
      >
        <Modal.Header className={"pd-r-l-60"}>
          <Modal.Title>
            {rateInfo._id ? (
              this.state.editable ? (
                <Translate value="intercityRate.edit_intercity_rate" />
              ) : (
                  <Translate value="intercityRate.view_intercity_rate" />
                )
            ) : (
                <Translate value="intercityRate.add_intercity_rate" />
              )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeDialogForm}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className={"pd-r-l-60"}>
          <Row>
            <Col
              md={rateInfo._id ? 12 : 12}
              xs={rateInfo._id ? 12 : 12}
            >
              <FormGroup
                className={
                  isSubmmitted
                    ? valid.name === false
                      ? "error"
                      : null
                    : null
                }
              >
                <Form.Label>
                  <Translate value="regularSetting.Name" />{" "}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  onChange={e => {
                    this.handleInputChange("name", e);
                  }}
                  value={
                    rateInfo ? rateInfo.name : ""
                  }
                  placeholder={I18n.t("regularSetting.Name")}
                  disabled={!this.state.editable}
                />
                <Validator
                  id="name"
                  callback={this.validatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmmitted}
                    valid={rateInfo.name.trim().length > 0}
                    message={I18n.t(
                      "messages.commonMessages.Required_field"
                    )}
                  />
                </Validator>
              </FormGroup>
              <div className={"vs-20"} />
              {rateInfo._id ? null : (
                <FormGroup>
                  <Form.Label>
                    <Translate value="regularSetting.Assign_car_type" />
                  </Form.Label>
                  <select
                    className="form-control form-custom"
                    onChange={e => {
                      this.handleInputChange("carType", e);
                    }}
                  >
                    <option value="">
                      {I18n.t("regularSetting.Select_car_type")}
                    </option>
                    {this.props.commonData.carTypeIntercity.map(item => {
                      return (
                        <option value={item._id} key={item._id}>
                          {item.vehicleType}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              )}
            </Col>
  
            {rateInfo._id || this.props?.rateInfo?.routeInfo ? (
              <Col md={12} xs={12}>
                <IntercityRoute 
                  intercityRateId={rateInfo._id || this.props?.rateInfo?._id}
                  routeInfoDeeplink={this.props.rateInfo?.routeInfo} 
                  editable={this.state.editable} 
                />
              </Col>
            ) : null}
          </Row>
          <Row className="ml0">
            {rateInfo._id ? (
              <Form.Label className="mr-r-20">
                (<span className="require">*</span>
                ): <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
              </Form.Label>
            ) : null}
          </Row>
          <Row className="ml0">
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="regularSetting.Required_fields" />
            </Form.Label>
          </Row>
        </Modal.Body>
        <Modal.Footer className={"pd-r-l-60"}>
          {hasPermission ? (
            this.state.editable ? (
              <Button
                className={"btn-save mr-md"}
                onClick={this.saveDialogForm}
                disabled={!this.state.editable}
              >
                <Translate value="regularSetting.Save" />
              </Button>
            ) : (
                <Button
                  className={"btn-save mr-md"}
                  onClick={this.handleEdit}
                >
                  <Translate value="regularSetting.Edit" />
                </Button>
              )
          ) : (
              ""
            )}
          <Button className={"btn-cancel"} onClick={this.props.closeDialogForm}>
            <Translate value="regularSetting.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

AddEditIntercityRateModal.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditIntercityRateModal);