import { data } from "jquery";
import {
  ETA_FARE_REQUEST,
  ETA_FARE_SUCCESS,
  ETA_FARE_MULTI_CAR_SUCCESS,
  ETA_FARE_FAILURE,
  ETA_HYDRA_FARE_SUCCESS,
} from "../actions/etaFareActions";


export default function etaFare(state=null,action={}){
    switch(action.type){
        case ETA_FARE_REQUEST:{
            return state;
        }
        case ETA_FARE_SUCCESS:{
            if(action.data){
                return Object.assign({}, state, action.data);
            }else{
                return null;
            }
            
        }
        case ETA_FARE_MULTI_CAR_SUCCESS:{
            if(action.data) {
                const { response, vehicleSelecting = {} } = action.data
                const fareCarSelecting = response && response.find(vhc =>
                    vhc &&  
                    vhc.vehicleType === vehicleSelecting.vehicleType
                )
                return Object.assign({}, state, fareCarSelecting);
            } else {
                return null;
            }
            
        }
        case ETA_HYDRA_FARE_SUCCESS:{
            if(action.data) {
                const { etaData, vehicleSelecting = {} } = action.data
                const fareCarSelecting = etaData && etaData.find(vhc => 
                    vhc.vehicleType === vehicleSelecting.vehicleType &&
                    vhc.vehicleTypeLocal === vehicleSelecting.providerOfVhc.vehicleType &&
                    vhc.localFleetId === vehicleSelecting.providerOfVhc.fleetId
                )
                return Object.assign({}, state, fareCarSelecting);
            } else {
                return null;
            }
            
        }
        case ETA_FARE_FAILURE:{
            return null;
        }
        default:
            return state
    }
}