import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import PaymentMethodSelect from './PaymentMethodSelect';
import CreditSelect from './CreditSelect';
import { FormGroup, FormControl } from 'react-bootstrap';
import { paymentMethodNumber } from '../../../constants/commondata';

class LocationInfoForm extends PureComponent {
  handleChangePaymentMethod = (value) => {
    this.props.onChange('paymentType', value);
  };

  handleChangeOperatorNote = (e) => {
    this.props.handleChangeOperatorNote(e);
  };

  handleChangeCredit = (value, isCorp) => {
    if(isCorp) {
      this.props.intercityBookingActions.updateCommonData({
        ...this.props.common,
        corporateInfo: {
          ...this.props.common.corporateInfo,
          creditInfo: value
        }
      });
    } else {
      this.props.onChangePsgInfo({ creditInfo: value });
    }
  };

  handleAddNewCustomerSuccess = (newCustomer) => {
    this.props.onChangePsgInfo({
      ...this.props.psgInfo,
      userId: newCustomer.userId,
      firstname: newCustomer.firstName,
      lastname: newCustomer.lastName,
      phone: newCustomer.phone,
      email: newCustomer.email,
      isCustomerVip: !!newCustomer.rank,
    });
  };

  render() {
    const {
      request,
      psgInfo,
      valid,
      isSubmited,
      validatorCallback,
      isBookDetail,
      handleChangeBookingReference,
      externalInfo,
      travelerType,
      supplierSelected,
      corporateInfo,
      handleClientCaseMatterChange,
      clientCaseMatter,
      handleChargeCodeChange,
      chargeCode
    } = this.props;
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.PAYMENT_METHOD" />
        </FormGroupTitle>
        <PaymentMethodSelect
          value={request.paymentType}
          onChange={this.handleChangePaymentMethod}
          valid={valid}
          isSubmited={isSubmited}
          validatorCallback={validatorCallback}
          disabled={isBookDetail}
          travelerType={travelerType}
          corporateInfo={corporateInfo}
          supplierSelected={supplierSelected}
          isBookDetail
        />
        <CreditSelect
          isHide={![paymentMethodNumber.personalCard, paymentMethodNumber.corporateCard].includes(request.paymentType)}
          paymentType={request.paymentType}
          // value={psgInfo.creditInfo}
          psgInfo={psgInfo}
          travelerType={travelerType}
          corporateInfo={corporateInfo}
          onChange={this.handleChangeCredit}
          onAddNewCustomer={this.handleAddNewCustomerSuccess}
          valid={valid}
          isSubmited={isSubmited}
          validatorCallback={validatorCallback}
          disabled={isBookDetail}
          externalInfo={externalInfo}
          handleChangeBookingReference={handleChangeBookingReference}
        />
        {
          (parseInt(travelerType) === 1 ||
          request.paymentType == 5 ||
          request.paymentType == paymentMethodNumber.corporateCard ||
          request.paymentType == 7) && (
          <div>
            <FormGroup className="mr-t-5">
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleClientCaseMatterChange}
                  value={clientCaseMatter}
                  placeholder={I18n.t('bookingdetail.Client_case_matter')}
                  disabled={isBookDetail}
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleChargeCodeChange}
                  value={chargeCode}
                  placeholder={I18n.t('bookingdetail.Charge_code')}
                  disabled={isBookDetail}
                />
              </FormGroup>
            </FormGroup>
          </div>
        )
        }
        <FormGroupTitle>
          <Translate value="newbooking.OPERATOR_NOTE" />
        </FormGroupTitle>
        <FormGroup>
          <FormControl
            type="text"
            as="textarea"
            onChange={this.handleChangeOperatorNote}
            value={request.operatorNote}
            rows={4}
            maxLength={1000}
            className={'form-custom'}
            // placeholder={I18n.t("newbooking.Note")}
            placeholder={I18n.t('newbooking.operator_note')}
          />
        </FormGroup>
      </Fragment>
    );
  }
}

export default LocationInfoForm;
