import React from 'react'
import { I18n } from 'react-redux-i18n';
import ReportUtils from '../../containers/reports/base/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const dispatchHistoryFields = [
  { key: 'performer', label: 'report.result.dispatchHistory.performer' },
  {
    key: 'name',
    label: 'report.result.dispatchHistory.name',
    mutate: (value, doc) => {
      let nameChildObj = doc?.child?.find((i) => i.name);
      return nameChildObj ? nameChildObj?.name : value;
    },
  },
  {
    key: 'action',
    label: 'report.result.dispatchHistory.action',
    mutate: (value, doc, isDelivery) => {
      if (['detachVehicle', 'assignVehicle'].includes(doc.actionCode)) {
        return (
          <p>
            <span>
              {I18n.t(`report.result.dispatchHistory.${doc.actionCode}`)}
            </span>
            {doc.moreInfo?.plateNumber && (
              <>
                {' - '}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip">
                      <p>
                        <p style={{ color: '#04BE76' }}>
                          {`${doc.moreInfo.vehicleType} - ${doc.moreInfo.plateNumber}`}
                        </p>
                        <span style={{ color: '#98A2B3', fontSize: '12px' }}>
                          {`${doc.moreInfo.year ?? ''} ${
                            doc.moreInfo.vehicleMakeName ?? ''
                          } ${doc.moreInfo.vehicleModelName ?? ''} ${
                            doc.moreInfo.color
                              ? ` ${'\u2022'} ${doc.moreInfo.color}`
                              : ''
                          }`}
                        </span>
                      </p>
                    </Tooltip>
                  }
                  placement="top"
                >
                  <span className="dropdown-title" style={{ color: '#04BE76' }}>
                    {doc.moreInfo?.plateNumber}
                  </span>
                </OverlayTrigger>
              </>
            )}
          </p>
        );
      }
      const text = isDelivery ? 'to sender' : '';
      const result =
        value === 'Driver on the way'
          ? `Driver on the way ${text}`
          : value === 'otwMerchant'
          ? 'On the way to merchant'
          : value[0].toLowerCase() === value[0]
          ? value[0].toUpperCase() + value.substring(1)
          : value;
      return result;
    },
  },
  {
    key: 'date',
    label: 'report.result.dispatchHistory.date',
    mutate: (value, doc) => {
      const checkAction =
        doc.action === 'Driver on the way' ||
        doc.action === 'Driver on the way to sender' ||
        doc.action === 'otwMerchant' ||
        doc.action === 'Passenger on board' ||
        doc.action === 'Delivering' ||
        doc.action === 'delivering';

      if (checkAction) return '-';
      return value
        ? ReportUtils.formatTime(value, doc, {
            formatStr: 'MM/DD/YYYY hh:mm:ss A',
          })
        : 'N/A';
    },
  },
  {
    key: 'location',
    label: 'report.result.dispatchHistory.location',
    mutate: (value, doc) => {
      const checkAction =
        doc.action === 'Driver on the way' ||
        doc.action === 'Driver on the way to sender' ||
        doc.action === 'otwMerchant' ||
        doc.action === 'Passenger on board' ||
        doc.action === 'Delivering' ||
        doc.action === 'delivering';

      if (checkAction) return '-';
      return value;
    },
  },
  {
    key: 'estimate',
    label: 'report.result.dispatchHistory.estimate',
    mutate: (value, doc) => {
      let estimateChildObj = doc?.child?.find((i) => i.estimate !== 'N/A');
      return estimateChildObj ? estimateChildObj?.estimate : value;
    },
  },
];
