import React, { Component } from 'react';
import {
    Col,
    FormGroup,
    FormControl,
    Form,
    Button,
    Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import _, { assign, isEmpty } from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';

import { Validator, ValidCase } from './../../../../components/validator'
import { CCLiteCommonFunc, Validation } from './../../../../utils/commonFunctions'

class AddEditCategoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogData: {},
            isSubmited: false,
            valid: {},
            isSubmited: false
        };
    }

    componentDidMount() {
        const { dialogData } = this.props || {};
        if (dialogData && dialogData._id) {
            this.setState({
                dialogData
            });
        }
    }

    handleNameChange = (e) => {
        const { dialogData } = this.state
        this.setState({
            dialogData: {
                ...dialogData,
                name: e.target.value
            }
        })
    }

    handleSaveButtonClick = () => {
        const { dialogData, valid } = this.state;
        this.setState({ isSubmited: true });
        if (!CCLiteCommonFunc.isFormValid(valid)) return;

        const { auth, settingActions, loadingBarActions } = this.props
        let fleetId = auth.selectedFleet.fleetId
        let name = dialogData.name

        let body = {
            fleetId,
            name,
        }

        loadingBarActions.showLoadingSpiner();

        if (dialogData._id && dialogData._id.length > 0) {
            body = {
                ...body,
                categoryId: dialogData._id
            }
            settingActions.updateCategory(body)
                .then((data) => {
                    loadingBarActions.hideLoadingSpiner()
                    if (data.ok) {
                        this.props.closeFormModal()
                        this.props.updateCategoryList()
                        this.context.notification('success', I18n.t('thirdPartySettings.Update_category_success'))
                        this.setState({ isSubmited: false })
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('thirdPartySettings.Update_category_fail'))
                        }
                    }
                })
        } else {
            settingActions.createCategory(body)
                .then((data) => {
                    loadingBarActions.hideLoadingSpiner()
                    if (data.ok) {
                        this.props.closeFormModal()
                        this.props.updateCategoryList()
                        this.context.notification('success', I18n.t('thirdPartySettings.Create_category_success'))
                        this.setState({ isSubmited: false })
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('thirdPartySettings.Create_category_fail'))
                        }
                    }
                })
        }
    }

    ValidatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    render() {
        const canUpdate = !this.props.permissions || this.props.permissions.actions;

        return (
            <Modal
                onHide={this.props.closeFormModal}
                show={this.props.show}
                dialogClassName={"modal-max-600"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.state.dialogData._id ? (
                            <Translate value="thirdPartySettings.Edit_Category" />
                        ) : (
                                <Translate value="thirdPartySettings.Add_Category" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.props.closeFormModal}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup className={!this.state.isSubmited ? null : (this.state.valid.name ? null : 'error')}>
                        <Form.Label><Translate value='promotionSettings.Name' />{" "}<span className='require'>*</span></Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={this.handleNameChange}
                            value={this.state.dialogData ? this.state.dialogData.name : ''}
                            placeholder={I18n.t("promotionSettings.Name")}
                            disabled={!canUpdate}
                        />
                        <Validator id='name' callback={this.ValidatorCallback}>
                            <ValidCase
                                hide={!this.state.isSubmited}
                                valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                                message={I18n.t('thirdPartySettings.Please_input_category_name')}
                            />
                            <ValidCase
                                hide={!this.state.isSubmited}
                                valid={!Validation.isMaxlength(this.state.dialogData.name, 50)}
                                message={I18n.t('messages.commonMessages.Max_length').format(50)}
                            />
                        </Validator>
                    </FormGroup>
                    <Form.Label>(<span className='require'>*</span>): <Translate value='promotionSettings.Required_fields' /></Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    {canUpdate ? (
                        <Button
                            className="btn-save mr-md"
                            onClick={this.handleSaveButtonClick}
                        >
                            <Translate value="thirdPartySettings.Save" />
                        </Button>
                    ) : null}

                    <Button className="btn-cancel" onClick={this.props.closeFormModal}>
                        <Translate value="thirdPartySettings.Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    }
}

AddEditCategoryModal.contextTypes = {
    notification: PropTypes.func
};

export default AddEditCategoryModal;