import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'bookId',
    label: 'report.result.affiliationPayoutHistory.bookId',
  },
  {
    key: 'networkType',
    label: 'report.result.affiliationPayoutHistory.networkType',
    mutate: (value) => {
      switch (value) {
        case 'roaming':
          return I18n.t('report.query.bookingServiceItem.roaming');
        case 'farmIn':
          return I18n.t('report.query.bookingServiceItem.farmIn');
        case 'farmOut':
          return I18n.t('report.query.bookingServiceItem.homeFleet');
        default:
          return value;
      }
    },
  },
  {
    key: 'payoutId',
    label: 'report.result.affiliationPayoutHistory.payoutId',
  },
  {
    key: 'dateTime',
    label: 'report.result.affiliationPayoutHistory.dateTime',
  },
  {
    key: 'totalPayout',
    label: 'report.result.affiliationPayoutHistory.totalPayout',
    mutate: ReportUtils.formatFare,
    summaryMutate: ReportUtils.formatFare,
    summary: 'totalPayout',
  },
];
