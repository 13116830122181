import React from 'react';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

export const fields = [
  {
    label: 'report.result.financial.driverName',
    key: 'driverName',
    summary: 'driverName',
  },
  {
    label: 'report.result.financial.driverPhone',
    key: 'driverPhone',
    summary: 'driverPhone',
  },
  {
    label: 'report.result.financial.driverUserName',
    key: 'driverUserName',
    isShow: 'driverUserName',
  },
  {
    label: 'report.result.financial.companyName',
    key: 'companyName',
    summary: 'companyName',
  },
  {
    label: 'report.result.financial.bookingList',
    key: 'driverId',
    link: {
      label: 'report.result.financial.showBookingList',
      newTab: true,
      href: '/reports/financial/driver',
      name: 'goToFinancialDriver',
      params: ['driverId', 'companyId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description:
        'Show list financial drivers filtered by driverId and companyId',
    },
    summary: 'driverId',
  },
  {
    label: 'report.result.financial.totalFare',
    key: 'totalFare',
    summary: 'totalFare',
  },
  {
    key: 'driverTax',
    label: 'report.result.financialDriver.driverTax',
    summary: 'driverTax',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      return (
        _.get(selectedFleet, 'drvPayout.gateway') !== 'StripeConnect' &&
        _.get(selectedFleet, 'fleetDeduction.separateTax') !== 'fleetTax'
      );
    },
  },
  {
    label: 'report.result.financial.netEarning',
    key: 'netEarning',
    summary: 'netEarning',
    negativable: true,
  },
  {
    key: 'grossEarning',
    label: 'report.result.financialDriver.grossEarning',
    summary: 'grossEarning',
    negativable: true,
  },
  {
    key: 'tipAfterCompleted',
    label: 'report.result.financialDriver.driverTipAfterCompleted',
    summary: 'tipAfterCompleted',
    negativable: true,
  },
  {
    label: 'report.result.financial.totalEarningNet',
    toolTip: (fleet) => {
      return 'report.result.financial.totalEarningNetTooltip';
    },
    key: 'totalEarningNet',
    summary: 'totalEarningNet',
    negativable: true,
  },
  {
    label: 'report.result.financial.totalEarningGross',
    toolTip: (fleet) => {
      return 'report.result.financial.totalEarningGrossTooltip';
    },
    key: 'totalEarningGross',
    summary: 'totalEarningGross',
    negativable: true,
  },
];

export const fareFields = [
  'totalFare',
  'netEarning',
  'grossEarning',
  'referralEarning',
  'totalEarningNet',
  'totalEarningGross',
  'tipAfterCompleted',
  'driverTax',
];
