
import {
    carTypedApi,
    operatorApi,
    bookingFromApi,
    getCitiesOfFleetApi,
    getCompaniesOfFleetApi,
    vehicleFetchApi,
    vehicleLoadMoreApi,
    getDriversOfFleetApi,
    mapZoneFinAlldApi,
    fetchRelativeInfoApi,
    getCurrencies,
    permissionRolesAllowableSpecifiefPermissionApi,
    getGatewayZonesApi,
    loadMoreDriversOfFleetApi,
    checkRequestLocationApi,
    vehicleLoadMoreByTypeApi,
    fetchAirportZoneApi,
    intercityRoutesFetchWithCarTypeApi,

    getPayoutTimeApi,
    findPayoutTimeApi,

    getFilterTemplateApi,
    addFilterTemplateApi,
    deleteFilterTemplateApi,
    getAirlineApi,
    mapZoneFindAlldNotGeoCoordinatesApi
} from '../constants/ApiConfigs'

import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";


export const CARTYPE_REQUEST = "CARTYPE_REQUEST";
export const CARTYPE_SUCCESS = "CARTYPE_SUCCESS";
export const CARTYPE_FAILURE = "CARTYPE_FAILURE";

export const OPERATOR_REQUEST = "OPERATOR_REQUEST";
export const OPERATOR_SUCCESS = "OPERATOR_SUCCESS";
export const OPERATOR_FAILURE = "OPERATOR_FAILURE";

export const BOOKING_FROM_REQUEST = "BOOKING_FROM_REQUEST";
export const BOOKING_FROM_SUCCESS = "BOOKING_FROM_SUCCESS";
export const BOOKING_FROM_FAILURE = "BOOKING_FROM_FAILURE";
export const FETCH_CITIES_REQUEST = "FETCH_CITIES_REQUEST";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAILURE = "FETCH_CITIES_FAILURE";
export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";


export const FETCH_VEHICLES_REQUEST = "FETCH_VEHICLES_REQUEST";
export const FETCH_VEHICLES_SUCCESS = "FETCH_VEHICLES_SUCCESS";
export const FETCH_VEHICLES_FAILURE = "FETCH_VEHICLES_FAILURE";
export const LOAD_MORE_VEHICLES_REQUEST = "LOAD_MORE_VEHICLES_REQUEST";
export const LOAD_MORE_VEHICLES_SUCCESS = "LOAD_MORE_VEHICLES_SUCCESS";
export const LOAD_MORE_VEHICLES_FAILURE = "LOAD_MORE_VEHICLES_FAILURE";
export const LOAD_MORE_VEHICLES_BY_TYPE_REQUEST = "LOAD_MORE_VEHICLES_BY_TYPE_REQUEST";
export const LOAD_MORE_VEHICLES_BY_TYPE_SUCCESS = "LOAD_MORE_VEHICLES_BY_TYPE_SUCCESS";
export const LOAD_MORE_VEHICLES_BY_TYPE_FAILURE = "LOAD_MORE_VEHICLES_BY_TYPE_FAILURE";

export const FETCH_DRIVERS_REQUEST = "FETCH_DRIVERS_REQUEST";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILURE = "FETCH_DRIVERS_FAILURE";
export const LOAD_MORE_DRIVERS_REQUEST = "LOAD_MORE_DRIVERS_REQUEST";
export const LOAD_MORE_DRIVERS_SUCCESS = "LOAD_MORE_DRIVERS_SUCCESS";
export const LOAD_MORE_DRIVERS_FAILURE = "LOAD_MORE_DRIVERS_FAILURE";

export const MAP_ZONE_REQUEST = "MAP_ZONE_REQUEST";
export const MAP_ZONE_SUCCESS = "MAP_ZONE_SUCCESS";
export const MAP_ZONE_SUCCESS_FULL_GEO = "MAP_ZONE_SUCCESS_FULL_GEO";
export const MAP_ZONE_FAILURE = "MAP_ZONE_FAILURE";

export const GATEWAY_ZONE_REQUEST = "GATEWAY_ZONE_REQUEST";
export const GATEWAY_ZONE_SUCCESS = "GATEWAY_ZONE_SUCCESS";
export const GATEWAY_ZONE_FAILURE = "GATEWAY_ZONE_FAILURE";

export const FETCH_RELATIVE_INFO_REQUEST = "FETCH_RELATIVE_INFO_REQUEST";
export const FETCH_RELATIVE_INFO_SUCCESS = "FETCH_RELATIVE_INFO_SUCCESS";
export const FETCH_RELATIVE_INFO_FAILURE = "FETCH_RELATIVE_INFO_FAILURE";

export const FETCH_CURRENCIES_REQUEST = "FETCH_CURRENCIES_REQUEST";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";

export const GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_REQUEST = 'GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_REQUEST'
export const GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_SUCCESS = 'GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_SUCCESS'
export const GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_FAILED = 'GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_FAILED'

export const UPDATE_AUTO_REFRESH_CUE_AND_MAP = 'UPDATE_AUTO_REFRESH_CUE_AND_MAP'

export const SET_IS_CHINA = 'SET_IS_CHINA';

export const INTERCITYROUTEWITHCARTYPE_REQUEST = "INTERCITYROUTEWITHCARTYPE_REQUEST";
export const INTERCITYROUTEWITHCARTYPE_SUCCESS = "INTERCITYROUTEWITHCARTYPE_SUCCESS";
export const INTERCITYROUTEWITHCARTYPE_FAILURE = "INTERCITYROUTEWITHCARTYPE_FAILURE";

export const GET_PAYOUTTIME_REQUEST = "GET_PAYOUTTIME";
export const GET_PAYOUTTIME_SUCCESS = "GET_PAYOUTTIME_SUCCESS";
export const GET_PAYOUTTIME_FAILURE = "GET_PAYOUTTIME_FAILURE";


export const AIRLINE_REQUEST = "AIRLINE_REQUEST";
export const AIRLINE_SUCCESS = "AIRLINE_SUCCESS";
export const AIRLINE_FAILURE = "AIRLINE_FAILURE";

function loadCarTypeRequest() {
    return {
        type: CARTYPE_REQUEST
    }
}

function loadCarTypeSuccess(data) {
    return {
        type: CARTYPE_SUCCESS,
        data: data.res
    }
}

function loadCarTypeFailure(error) {
    return {
        type: CARTYPE_FAILURE,
        error
    }
}
export function loadCarType(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        carTypedApi + fleetId,
        config,
        loadCarTypeRequest(),
        loadCarTypeSuccess,
        loadCarTypeFailure,
        true
    );
}

function loadOperatorRequest() {
    return {
        type: OPERATOR_REQUEST
    }
}

function loadOperatorSuccess(data) {
    return {
        type: OPERATOR_SUCCESS,
        data: data.res.list
    }
}

function loadOperatorFailure(error) {
    return {
        type: OPERATOR_FAILURE,
        error
    }
}
export function loadOperator(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        operatorApi + fleetId,
        config,
        loadOperatorRequest(),
        loadOperatorSuccess,
        loadOperatorFailure,
        true
    );
}

function bookingFromRequest() {
    return {
        type: BOOKING_FROM_REQUEST
    }
}

function bookingFromSuccess(data) {
    return {
        type: BOOKING_FROM_SUCCESS,
        data: data.res
    }
}

function bookingFromFailure(error) {
    return {
        type: BOOKING_FROM_FAILURE,
        error
    }
}
export function loadBookingFrom(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        bookingFromApi + fleetId,
        config,
        bookingFromRequest(),
        bookingFromSuccess,
        bookingFromFailure,
        true
    );
}


function fetchCitiesRequest() {
    return {
        type: FETCH_CITIES_REQUEST
    }
}

function fetchCitiesSuccess(data) {
    return {
        type: FETCH_CITIES_SUCCESS,
        data: data.res.list
    }
}

function fetchCitiesFailure(error) {
    return {
        type: FETCH_CITIES_FAILURE,
        error
    }
}

export function fetchCities(fleetId, isLoading = true) {
    const config = {
        method: "get",
    }
    return callApi(
        getCitiesOfFleetApi + fleetId,
        config,
        fetchCitiesRequest(),
        fetchCitiesSuccess,
        fetchCitiesFailure,
        isLoading
    );
}

function fetchCompaniesRequest() {
    return {
        type: FETCH_COMPANIES_REQUEST
    }
}

function fetchCompaniesSuccess(data) {
    return {
        type: FETCH_COMPANIES_SUCCESS,
        data: data.res.list,
        suppliers: data.res.suppliers
    }
}

function fetchCompaniesFailure(error) {
    return {
        type: FETCH_COMPANIES_FAILURE,
        error
    }
}
export function fetchCompanies(fleetId, isLoading = true) {
    const config = {
        method: "get",
    }
    return callApi(
        getCompaniesOfFleetApi + fleetId,
        config,
        fetchCompaniesRequest(),
        fetchCompaniesSuccess,
        fetchCompaniesFailure,
        isLoading
    );
}

function fetchVehiclesRequest() {
    return {
        type: FETCH_VEHICLES_REQUEST
    }
}

function fetchVehiclesSuccess(data) {
    return {
        type: FETCH_VEHICLES_SUCCESS,
        data: data.res,
        fetchTime: new Date() - 60000
    }
}

function fetchVehiclesFailure(error) {
    return {
        type: FETCH_VEHICLES_FAILURE,
        error
    }
}
export function fetchVehicles(options) {
    const config = {
        method: "get",
    }
    return callApi(
        UrlBuilder(vehicleFetchApi, options),
        config,
        fetchVehiclesRequest(),
        fetchVehiclesSuccess,
        fetchVehiclesFailure,
        true
    );
}

function loadMoreVehiclesRequest() {
    return {
        type: LOAD_MORE_VEHICLES_REQUEST
    }
}

function loadMoreVehiclesSuccess(data) {
    return {
        type: LOAD_MORE_VEHICLES_SUCCESS,
        data: data.res
    }
}

function loadMoreVehiclesFailure(error) {
    return {
        type: LOAD_MORE_VEHICLES_FAILURE,
        error
    }
}

export function loadMoreVehicles(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        vehicleLoadMoreApi,
        config,
        loadMoreVehiclesRequest(),
        loadMoreVehiclesSuccess,
        loadMoreVehiclesFailure,
        isLoading
    );
}

function loadMoreVehiclesByTypeRequest() {
    return {
        type: LOAD_MORE_VEHICLES_BY_TYPE_REQUEST
    }
}

function loadMoreVehiclesByTypeSuccess(data) {
    return {
        type: LOAD_MORE_VEHICLES_BY_TYPE_SUCCESS,
        data: data.res
    }
}

function loadMoreVehiclesByTypeFailure(error) {
    return {
        type: LOAD_MORE_VEHICLES_BY_TYPE_FAILURE,
        error
    }
}

export function loadMoreVehiclesByType(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(vehicleLoadMoreByTypeApi, options),
        config,
        loadMoreVehiclesByTypeRequest(),
        loadMoreVehiclesByTypeSuccess,
        loadMoreVehiclesByTypeFailure,
        isLoading
    );
}


function fetchCurrenciesRequest() {
    return {
        type: FETCH_CURRENCIES_REQUEST
    }
}

function fetchCurrenciesSuccess(data) {
    return {
        type: FETCH_CURRENCIES_SUCCESS,
        data: data.res
    }
}

function fetchCurrenciesFailure(error) {
    return {
        type: FETCH_CURRENCIES_FAILURE,
        error
    }
}
export function fetchCurrencies() {
    const config = {
        method: "get",
    }
    return callApi(
        getCurrencies,
        config,
        fetchCurrenciesRequest(),
        fetchCurrenciesSuccess,
        fetchCurrenciesFailure,
        true
    );
}

function fetchDriversRequest() {
    return {
        type: FETCH_DRIVERS_REQUEST
    }
}

function fetchDriversSuccess(data) {
    return {
        type: FETCH_DRIVERS_SUCCESS,
        data: data.res,
        fetchTime: new Date() - 60000
    }
}

function fetchDriversFailure(error) {
    return {
        type: FETCH_DRIVERS_FAILURE,
        error
    }
}
export function fetchDrivers(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(getDriversOfFleetApi, options),
        config,
        fetchDriversRequest(),
        fetchDriversSuccess,
        fetchDriversFailure,
        isLoading
    );
}

function loadMoreDriversRequest() {
    return {
        type: LOAD_MORE_DRIVERS_REQUEST
    }
}

function loadMoreDriversSuccess(data) {
    return {
        type: LOAD_MORE_DRIVERS_SUCCESS,
        data: data.res
    }
}

function loadMoreDriversFailure(error) {
    return {
        type: LOAD_MORE_DRIVERS_FAILURE,
        error
    }
}

export function loadMoreDrivers(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        loadMoreDriversOfFleetApi,
        config,
        loadMoreDriversRequest(),
        loadMoreDriversSuccess,
        loadMoreDriversFailure,
        isLoading
    );
}

function loadMapZoneRequest() {
    return {
        type: MAP_ZONE_REQUEST
    }
}

function loadMapZoneSuccess(data) {
    return {
        type: MAP_ZONE_SUCCESS,
        data: data.res
    }
}

function loadMapZoneSuccessFullGeo(data) {
    return {
        type: MAP_ZONE_SUCCESS_FULL_GEO,
        data: data.res
    }
}

function loadMapZoneFailure(error) {
    return {
        type: MAP_ZONE_FAILURE,
        error
    }
}

export function loadMapZone(fleetId, isLoading = true) {
    let options = {
        "limit": 100,
        "page": 0,
        "query": {
            "fleetId": fleetId,
            "isActive": true,
            "display": true
        },
        "selects": {
            "zoneName": 1,
            "center": 1,
            geo: 1
        }
    }
    options = Object.assign({}, options);
    const config = {
        method: "get",
    };

    return callApi(
        mapZoneFindAlldNotGeoCoordinatesApi + fleetId,
        config,
        loadMapZoneRequest(),
        loadMapZoneSuccess,
        loadMapZoneFailure,
        isLoading
    );
}

export function loadMapZoneFullGeo(fleetId) {
    let options = {
        "limit": 100,
        "page": 0,
        "query": {
            "fleetId": fleetId,
            "isActive": true,
            "display": true
        },
        "selects": {
            "zoneName": 1,
            "center": 1,
            geo: 1
        }
    }
    options = Object.assign({}, options);
    const config = {
        method: "get",
    };

    return callApi(
        mapZoneFinAlldApi + fleetId,
        config,
        loadMapZoneRequest(),
        loadMapZoneSuccessFullGeo,
        loadMapZoneFailure,
        true
    );
}

function getGatewayZonesRequest() {
    return {
        type: GATEWAY_ZONE_REQUEST
    }
}

function getGatewayZonesSuccess(data) {
    return {
        type: GATEWAY_ZONE_SUCCESS,
        data: data.res
    }
}

function getGatewayZonesFailure(error) {
    return {
        type: GATEWAY_ZONE_FAILURE,
        error
    }
}

export function getGatewayZones(fleetId) {
    const config = {
        method: "get",
    };

    return callApi(
        getGatewayZonesApi + fleetId,
        config,
        getGatewayZonesRequest(),
        getGatewayZonesSuccess,
        getGatewayZonesFailure,
        true
    );
}

function fetchRelativeInfoRequest() {
    return {
        type: FETCH_RELATIVE_INFO_REQUEST
    }
}

function fetchRelativeInfoSuccess(data) {
    return {
        type: FETCH_RELATIVE_INFO_SUCCESS,
        data: data.res
    }
}

function fetchRelativeInfoFailure(error) {
    return {
        type: FETCH_RELATIVE_INFO_FAILURE,
        error
    }
}

export function fetchRelativeInfo(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(fetchRelativeInfoApi, options),
        config,
        fetchRelativeInfoRequest(),
        fetchRelativeInfoSuccess,
        fetchRelativeInfoFailure,
        true
    );
}

function getAllowableDriversAndCarsRequest() {
    return {
        type: GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_REQUEST
    }
}

function getAllowableDriversAndCarsSuccess(data) {
    return {
        type: GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_SUCCESS,
        data: data.res
    }
}

function getAllowableDriversAndCarsFailure(error) {
    return {
        type: GET_ALLOWABLE_DATA_SPECIFIED_PERMISSION_FAILED,
        error
    }
}

export function getAllowableDriversAndCars(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(permissionRolesAllowableSpecifiefPermissionApi, options),
        config,
        getAllowableDriversAndCarsRequest(),
        getAllowableDriversAndCarsSuccess,
        getAllowableDriversAndCarsFailure,
        isLoading
    );
}

export function getAllAirportZones(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(fetchAirportZoneApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function autoRefreshCueAndMapChange(value) {
    return {
        type: UPDATE_AUTO_REFRESH_CUE_AND_MAP,
        data: value
    }
}


export function setIsChina(data) {
    return {
        type: SET_IS_CHINA,
        data: data
    }
}

function loadIntercityRoutesFetchWithCarTypeRequest() {
    return {
        type: INTERCITYROUTEWITHCARTYPE_REQUEST
    }
}

function loadIntercityRoutesFetchWithCarTypeSuccess(data) {
    return {
        type: INTERCITYROUTEWITHCARTYPE_SUCCESS,
        data: data.res
    }
}

function loadIntercityRoutesFetchWithCarTypeFailure(error) {
    return {
        type: INTERCITYROUTEWITHCARTYPE_FAILURE,
        error
    }
}

export function loadIntercityRoutesFetchWithCarType(fleetId) {
    const config = {
        method: "get"
    };

    return callApi(
        intercityRoutesFetchWithCarTypeApi + fleetId,
        config,
        loadIntercityRoutesFetchWithCarTypeRequest(),
        loadIntercityRoutesFetchWithCarTypeSuccess,
        loadIntercityRoutesFetchWithCarTypeFailure,
        true
    );
}
function getPayTimeRequest() {
    return {
        type: GET_PAYOUTTIME_REQUEST
    }
}

function getPayTimeSuccess(data) {
    return {
        type: GET_PAYOUTTIME_SUCCESS,
        data: data.res
    }
}

function getPayTimeFailure(error) {
    return {
        type: GET_PAYOUTTIME_FAILURE,
        error
    }
}

export function getPayoutTimes(options = {}, isLoading = true) {
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(getPayoutTimeApi, options),
        config,
        getPayTimeRequest(),
        getPayTimeSuccess,
        getPayTimeFailure,
        isLoading
    );
}

export function findPayoutTimes(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findPayoutTimeApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getFilterTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "GET"
    };
    return callApi(
        UrlBuilder(getFilterTemplateApi, options),
        config,
        null,
        null,
        null,
        true
    );
}

export function addFilterTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        addFilterTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteFilterTemplate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteFilterTemplateApi,
        config,
        null,
        null,
        null,
        true
    );
}



function loadAirlineRequest() {
    return {
        type: AIRLINE_REQUEST
    }
}

function loadAirlineSuccess(data) {
    return {
        type: AIRLINE_SUCCESS,
        data: data.res.list
    }
}

function loadAirlineFailure(error) {
    return {
        type: AIRLINE_FAILURE,
        error
    }
}
export function loadAirline(options) {
    const config = {
        method: "get"
    };

    return callApi(
        UrlBuilder(getAirlineApi, options),
        config,
        loadAirlineRequest(),
        loadAirlineSuccess,
        loadAirlineFailure,
        true
    );
}
