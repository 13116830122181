import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Button,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
  Modal,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import './style.scss';
import * as queuingAreaActions from './../../actions/queuingAreaActions';
import * as loadingBarActions from './../../actions/loadingBarActions';
import * as commonDataActions from './../../actions/commonDataAction';
import { QueuingAreaColumns } from './tableHeaderColumns';
import TableActions from './../../components/table/tableAction/TableActions';
import StickyTable from './../../components/table/stickyTable/StickyTable';
import QueueModalTabs from './QueueModalTabs';

class QueuingArea extends Component {
  constructor() {
    super();
    this.state = {
      QueuingAreas: null,
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      carList: [],
      editable: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      },
      queueId: null,
      queueName: null
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handlePaginationChose = this.handlePaginationChose.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateQueuingAreasList = this.updateQueuingAreasList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.confirmDeleteQueuingArea = this.confirmDeleteQueuingArea.bind(this);
  }

  componentDidMount() {
    this.updateQueuingAreasList();
  }

  renderDataItemList() {
    const { handleMenuClick, changeStatus } = this;
    if (this.state.QueuingAreas && this.state.QueuingAreas.list.length > 0) {
      return this.state.QueuingAreas.list.map(queue => {
        if (!queue.editable && queue.deletable) return;
        return (
          <tr key={queue._id}>
            <td scope="row">
              <a
                className="clickable"
                onClick={() => {
                  handleMenuClick('View', queue);
                }}
              >
                {queue.name}
              </a>
            </td>
            <td scope="row">{queue.location.address}</td>
            <td scope="row">
              {queue.radius} {this.props.auth.selectedFleet.unitDistance}
            </td>
            <td scope="row">{queue.minDriver}</td>
            <td scope="row">
              {queue.partnerList &&
                queue.partnerList.map((mdis, index) =>
                  index === queue.partnerList.length - 1
                    ? mdis.partnerName
                    : `${mdis.partnerName}, `
                )}
            </td>
            <td className={queue.isActive ? 'activated' : 'inactivated'}>
              {(!this.props.permissions || this.props.permissions.actions) &&
                !queue.inUse ? (
                  <a
                    onClick={() => {
                      handleMenuClick('Status', queue);
                    }}
                  >
                    {queue.isActive ? (
                      <Translate value="queuingArea.Active" />
                    ) : (
                        <Translate value="queuingArea.Inactive" />
                      )}
                  </a>
                ) : queue.isActive ? (
                  <Translate value="queuingArea.Active" />
                ) : (
                    <Translate value="queuingArea.Inactive" />
                  )}
            </td>
            <td>
              {!this.props.permissions || this.props.permissions.actions ? (
                <DropdownButton
                  title={<i className="fa fa-bars" />}
                  pullRight
                  id="bg-nested-dropdown"
                  onSelect={eventKey => {
                    handleMenuClick(eventKey, queue);
                  }}
                >
                  <Dropdown.Item eventKey="Edit">
                    <Translate value="queuingArea.Edit" />
                  </Dropdown.Item>
                  {queue.inUse ? (
                    ''
                  ) : (
                      <Dropdown.Item eventKey="Status">
                        {queue.isActive ? (
                          <Translate value="queuingArea.Deactivate" />
                        ) : (
                            <Translate value="queuingArea.Activate" />
                          )}
                      </Dropdown.Item>
                    )}
                  {queue.inUse || queue.isActive ? null : (
                    <Dropdown.Item eventKey="Delete">
                      <Translate value="queuingArea.Delete" />
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              ) : (
                  <a
                    onClick={() => {
                      handleMenuClick('View', queue);
                    }}
                    href="javascript:void(0)"
                  >
                    <Translate value="queuingArea.View" />
                  </a>
                )}
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td className="item-no-found" colSpan={7}>
          <Translate value="messages.item_no_found" />
        </td>
      </tr>
    );
  }

  getTableColumns = () => {
    const tableColumns = Object.assign([], QueuingAreaColumns);
    // custom data by column
    _.forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'queuingArea':
          col.customCell = queue => (
            <a
              className="clickable"
              onClick={() => {
                this.handleMenuClick('View', queue);
              }}
            >
              {queue.name}
            </a>
          );
          break;

          break;
        case 'allowRadius':
          col.customCell = queue => (
            <span>
              {queue.radius} {this.props.auth.selectedFleet.unitDistance}
            </span>
          );
          break;
        case 'minDrivers':
          col.customCell = queue => <span> {queue.minDriver}</span>;
          break;
        case 'mDispatcher':
          col.customCell = queue => {
            const content = queue.partnerList
              ? queue.partnerList.map((mdis, index) =>
                index === queue.partnerList.length - 1
                  ? mdis.partnerName
                  : `${mdis.partnerName}, `
              )
              : '';
            return (
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <div>{content}</div>
                  </Tooltip>
                }
              >
                <div className="cursor-pointer text-ellipsis">{content}</div>
              </OverlayTrigger>
            );
          };
          break;
        case 'queuingStatus':
          col.customCell = queue => (
            <div className={queue.isActive ? 'Active' : 'Inactive'}>
              {!this.props.permissions ||
                (this.props.permissions.actions && !queue.inUse) ? (
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.handleMenuClick('Status', queue);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {queue.isActive ? (
                      <Translate value="queuingArea.Active" />
                    ) : (
                        <Translate value="queuingArea.Inactive" />
                      )}
                  </a>
                ) : queue.isActive ? (
                  <Translate value="queuingArea.Active" />
                ) : (
                    <Translate value="queuingArea.Inactive" />
                  )}
            </div>
          );
          break;
        case 'actions':
          col.customCell = (queue, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              const actionItems = [
                {
                  label: 'queuingArea.Edit',
                  eventKey: 'Edit'
                }
              ];

              if (!queue.inUse) {
                actionItems.push({
                  label: queue.isActive
                    ? 'queuingArea.Deactivate'
                    : 'queuingArea.Activate',
                  eventKey: 'Status'
                });
              }

              if (queue.inUse || queue.isActive) {
              } else {
                actionItems.push({
                  label: 'queuingArea.Delete',
                  eventKey: 'Delete'
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={queue}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, queue);
                  }}
                  totalRow={this.state.QueuingAreas.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            }
            return (
              <a
                onClick={e => {
                  this.handleMenuClick('View', queue);
                }}
                href="javascript:void(0)"
              >
                <Translate value="queuingArea.View" />
              </a>
            );
          };
          break;
        default:
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  confirmDeleteQueuingArea() {
    this.props.queuingAreaActions
      .deleteQueuingArea({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id
      })
      .then(data => {
        if (data.ok && !data.error) {
          this.closeDialogForm();
          this.updateQueuingAreasList();
          this.context.notification(
            'success',
            I18n.t('queuingArea.Delete_queuingArea_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('queuingArea.Delete_queuingArea_fail')
          );
        }
      });
  }

  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      editable: false,
      queueId: null,
      queueName: null
    });
  }

  handleUpdateFormSuccess = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
      editable: false
    });
    this.updateQueuingAreasList();
  }

  handleMenuClick(key, queuingArea) {
    switch (key) {
      case 'Edit': {
        this.setState({
          showDialog: true,
          queueId: queuingArea._id,
          queueName: queuingArea.name,
          editable: true
        });
        break;
      }
      case 'View': {
        this.setState({
          showDialog: true,
          queueId: queuingArea._id,
          queueName: queuingArea.name,
          editable: false
        });
        break;
      }
      case 'Status': {
        this.props.queuingAreaActions
          .changeStatusQueuingArea({
            fleetId: this.props.auth.selectedFleet.fleetId,
            _id: queuingArea._id,
            status: !queuingArea.isActive
          })
          .then(data => {
            if (data.ok) {
              this.updateQueuingAreasList();
            } else {
              this.context.notification(
                'error',
                I18n.t('queuingArea.Update_status_error')
              );
            }
          });
        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: queuingArea });
        break;
      }
      default:
        break;
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateQueuingAreasList(eventKey);
  }

  handlePaginationChose(e) {
    this.updateQueuingAreasList(0, parseInt(e));
  }

  handleNumItemsPerPageChange = limit => this.updateQueuingAreasList(0, limit);

  handleAddButtonClick() {
    this.setState({
      showDialog: true,
      queueId: null,
      editable: true
    });
  }

  updateQueuingAreasList(activePage, limitSize) {
    const params = {
      limit: limitSize || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId
    };

    this.props.queuingAreaActions.findQueuingAreas(params).then(data => {
      if (data.ok && data.res) {
        const { page, total, limit } = data.res;
        this.setState({
          QueuingAreas: data.res,
          footerData: { page, total, limit }
        });
      } else if (data.error) {
        this.context.notification(
          'error',
          I18n.t(`errors.${data.error.errorCode}`)
        );
      } else {
        this.context.notification(
          'error',
          I18n.t('queuingArea.Load_queuing_area_fail')
        );
      }
    });
  }

  render() {
    const bodyData = this.state.QueuingAreas && this.state.QueuingAreas.list.length > 0
      ? this.state.QueuingAreas.list
      : [];
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="queuingArea.DELETE_QUEUING_AREA" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="queuingArea.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-save mr-md"
                onClick={this.confirmDeleteQueuingArea}
              >
                <Translate value="queuingArea.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="queuingArea.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        {this.state.showDialog ? (
          <QueueModalTabs
            editable={this.state.editable}
            queueId={this.state.queueId}
            queueName={this.state.queueName}
            closeDialogForm={this.closeDialogForm}
            carList={this.state.carList}
            handleMenuClick={this.handleMenuClick}
            handleUpdateFormSuccess={this.handleUpdateFormSuccess}
            zonesData={bodyData}
          />
        ) : null}
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              className="btn-header text-add-header ml0"
              onClick={this.handleAddButtonClick}
            >
              {' '}
              <Translate value="queuingArea.Add" />
            </Button>
          </ButtonToolbar>
        ) : (
            ''
          )}
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

QueuingArea.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueuingArea);
