import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import IntlTelInputApp from "../../components/intlTelInputCustome/IntlTelInputApp";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { editProfile } from "../../actions/auth";
import Moment from "react-moment";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar
} from "react-bootstrap";

class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmitted: false,
      isEditting: false
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  componentDidMount() {
    if (this.props.auth) {
      this.setState(Object.assign({}, this.state, this.props.auth.user));
    }
    console.log(`Version: ${process.env.REACT_APP_VERSION}`);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  handleFirstnameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleAddressChange(e) {
    this.setState({ address: e.target.value });
  }

  handleSaveClick() {
    if (!this.state.isEditting) {
      this.setState({ isEditting: true });
      return;
    }
    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .editProfile({
        userId: this.state._id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        address: this.state.address
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          this.setState({ isSubmitted: false, isEditting: false });
        } else {
          this.setState({ isSubmitted: false });
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        }
      });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    return (
      <div className="content form-detail-container">
        <Row>
          <Col xs={12} sm={6} md={4}>
            <FormGroup>
              <Form.Label>
                <Translate value="userProfile.Username" />
              </Form.Label>
              <FormControl
                type="text"
                value={this.state.userName}
                disabled={true}
                placeholder={I18n.t("userProfile.Username")}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.firstName === false
                    ? "error"
                    : null
              }
            >
              <Form.Label>
                <Translate value="userProfile.First_name" />
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                onChange={this.handleFirstnameChange}
                value={this.state.firstName}
                placeholder={I18n.t("userProfile.First_name")}
                disabled={!this.state.isEditting}
                className="form-custom"
              />
              <Validator id="firstName" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={!Validation.isStringEmpty(this.state.firstName)}
                  message={I18n.t("userProfile.ERROR_INPUT_FIRSTNAME")}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="userProfile.Last_name" />
              </Form.Label>
              <FormControl
                type="text"
                onChange={this.handleLastNameChange}
                value={this.state.lastName}
                placeholder={I18n.t("userProfile.Last_name")}
                disabled={!this.state.isEditting}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="userProfile.Phone_number" />
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={["intl-tel-input", "form-control", "form-custom"]}
                  utilsScript={"libphonenumber.js"}
                  value={this.state.phone}
                  disabled={true}
                  autoPlaceholder={false}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.email === false
                    ? "error"
                    : null
              }
            >
              <Form.Label>
                <Translate value="userProfile.Email_address" />
              </Form.Label>
              <FormControl
                type="text"
                onChange={this.handleEmailChange}
                value={this.state.email}
                placeholder={I18n.t("userProfile.Email_address")}
                disabled={!this.state.isEditting}
                className="form-custom"
              />
              <Validator id="email" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={
                    Validation.isStringEmpty(this.state.email) ||
                    Validation.validateEmail(this.state.email)
                  }
                  message={I18n.t("userProfile.ERROR_INPUT_VALID_EMAIL")}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="userProfile.Address" />
              </Form.Label>
              <FormControl
                type="text"
                onChange={this.handleAddressChange}
                value={this.state.address}
                placeholder={I18n.t("userProfile.Address")}
                disabled={!this.state.isEditting}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                (<span className="require">*</span>
                ): <Translate value="userProfile.Required_fields" />
              </Form.Label>
            </FormGroup>
            <ButtonToolbar className="text-center">
              <Button
                className="btn-save mr-md"
                onClick={this.handleSaveClick}
                type="submit"
              >
                {this.state.isEditting ? (
                  <Translate value="userProfile.Save" />
                ) : (
                  <Translate value="userProfile.Edit_profile" />
                )}
              </Button>
              <Button
                className="btn-reset"
                onClick={() => {
                  this.props.router.push("/change-password");
                }}
              >
                <Translate value="userProfile.Change_password" />
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
    );
  }
}

UserProfile.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editProfile: options => {
      return dispatch(editProfile(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
