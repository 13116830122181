import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _, { set } from 'lodash';

import { ValidCase, Validator } from '../../../components/validator';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import Select from '../../../components/select/Select';
import {
  CCLiteCommonFunc,
  Validation,
  getCompanyOfOperator,
  getSuppilerPermission,
  trimPhoneNumber,
} from '../../../utils/commonFunctions';

import styles from '../styles/formVehicle.module.scss';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import DateTime from '../../../components/dateTime/DateTime';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';
import FormInfo from './formInfo';

let carColorRef = null;
const FormVehicle = (props, context) => {
  const [isPermissionCompany, setPermissionCompany] = useState(false)
  const [state, setState] = useState({
    phoneValidation: true,
    yearList: [],
    valid: {},
    colorSelected: null,
    textSearchCarColor: '',
    editable: props.action === 'view' ? false : true,
  });
  const [supplierList, setSupplierList] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [dialogData, setDialogData] = useState({
    plateNumber: '',
    color: '',
    caseNumber: '',
    year: null,
    maxPassengers: 0,
    maxLuggage: 0,
    vhcId: null,
    licenseExp: '',
    hwMetered: false,
    vehicleModel: {
      modelId: '',
      name: '',
      vehicleMakeId: '',
      vehicleMake: '',
      avatar: '',
    },
    company: {},
    type: {},
    phone: '',

    insuranceVehicle: {
      policyNumber: '',
      effectiveDate: '',
      expiredDate: '',
    },
    activeVehicle: false
  });

  const getAllSupplier = async () => {
    if (!props.auth?.user?.roles?.isSupplier) {
      let param = {
        limit: 500,
        page: 0,
        fleetId: props.auth?.selectedFleet?.fleetId,
        isSupplier: true,
        agentId: props.auth?.user?.roles?.companyId,
      };
      await props.settingActions.getAllCompany(param).then((data) => {
        if (data.ok) {
          if (data?.res?.list?.length) {
            setSupplierList(data?.res?.list)
          }
        }
      });
    }
  }

  useEffect(() => {
    const permissionCompany = getCompanyOfOperator(
      props.auth,
      [...props.commonData.companies, ...props.commonData.suppliers]
    )
    if(permissionCompany?._id) {
      const companyInfo = getCompanyOfOperator(
        props.auth,
        [...props.commonData.companies, ...props.commonData.suppliers]
      )
      setPermissionCompany(true)
      if(props.action === 'add') {
        setDialogData({
          ...dialogData,
          company: {
            ...companyInfo,
            _id: permissionCompany._id,
          },
        })
      }
    }
  }, [])

  useEffect(() => {
    if(props.companyIdFromDriverModule) {
      let newData = {
        ...dialogData,
        company: {
          _id: props.companyIdFromDriverModule,
        },
      }
      setDialogData(newData);
    }
  }, [props.companyIdFromDriverModule, supplierList.length]);

  const handleSelectRef = (ref) => {
    carColorRef = ref;
  };

  const onEditable = () => {
    const defaultState = { ...state };
    defaultState.editable = true;
    setState(defaultState);
  };

  const handlePhoneChange = (status, value, countryData, number) => {
    const defaultDialogData = { ...dialogData };
    defaultDialogData.phone = number;
    setDialogData(defaultDialogData);
  };

  const handleInputChange = (key, e) => {
    const defaultDialogData = { ...dialogData };
    if (key === 'hwMetered' || key === 'activeVehicle') {
      defaultDialogData[key] = e.target.checked;
    } else if (key === 'licenseExp') {
      defaultDialogData[key] = e._d;
    } else if (key === 'plateNumber') {
      defaultDialogData[key] = e.target.value.toUpperCase();
    } else {
      defaultDialogData[key] = e.target.value;
    }
    setDialogData(defaultDialogData);
  };

  const handleChangeCarInsurance = (key, e) => {
    const defaultDialogData = { ...dialogData };
    defaultDialogData.insuranceVehicle[key] = e.target.value;
    setDialogData(defaultDialogData);
  };

  const handleInputBlur = (e) => {
    const defaultState = { ...state };
    defaultState.textSearchCarColor = '';
    setState(defaultState);
  };

  const handleInputColorChange = (value) => {
    const defaultState = { ...state };
    defaultState.textSearchCarColor = value;
    setState(defaultState);
  };

  const searchCarColors = (fleetId, findCarColorAction, input, callback) => {
    const params = {
      str: input,
      fleetId,
      limit: 20,
      page: 0,
    };

    findCarColorAction(params)
      .then((data) => {
        if (data.ok && data.res && data.res.list.length > 0) {
          const result = { complete: true };
          result.options = (data.res.list || []).map((cat) => ({
            name: cat.name,
            _id: cat._id,
          }));
          callback(null, result);
        } else callback(null, null);
      })
      .catch(() => callback(null, null));
  };

  const debouncedSearchCarColors = _.debounce(
    (fleetId, autocompleteCarColor, input, callback) => {
      searchCarColors(fleetId, autocompleteCarColor, input, callback);
    },
    300
  );

  const handleLoadOption = (input, callback) => {
    if (!input) return callback();
    const { settingActions, auth } = props;

    debouncedSearchCarColors(
      auth.selectedFleet.fleetId,
      settingActions.autocompleteCarColor,
      input,
      callback
    );
  };

  const handleCarColorChange = (colorSelected) => {
    const defaultState = { ...state };
    defaultState.colorSelected = colorSelected;
    setState(defaultState);
  };

  const onCreateNewCarColor = () => {
    const { textSearchCarColor } = state;
    let colorSelected = { name: textSearchCarColor };
    carColorRef.select.blurInput();
    handleCarColorChange(colorSelected);
  };

  const renderCarColorNoResult = () => {
    const { textSearchCarColor } = state;
    if (!textSearchCarColor) return null;
    return (
      <span>
        {I18n.t('message.No_results')}
        <button className="btn-link" onClick={onCreateNewCarColor}>
          Click here to add new
        </button>
        {textSearchCarColor}
      </span>
    );
  };

  const ValidatorCallback = (id, valid) => {
    const defaultState = { ...state };
    if (defaultState.valid && defaultState.valid[id] !== valid) {
      defaultState.valid[id] = valid;
      setState(defaultState);
    }
  };

  const handleChangeVehicleMake = async (e) => {
    const { makeList } = state;
    const defaultDialogData = { ...dialogData };

    defaultDialogData.vehicleModel = dialogData.vehicleModel || {};
    defaultDialogData.vehicleModel.modelId = null;

    const foundMake = makeList.find((o) => o._id === e.target.value);
    defaultDialogData.vehicleModel.vehicleMakeId = foundMake
      ? foundMake._id
      : null;

    await fetchModelList();

    setDialogData(defaultDialogData);
  };

  const selectChange = (key, e) => {
    const defaultState = { ...state };
    const defaultDialogData = { ...dialogData };
    switch (key) {
      case 'company': {
        defaultDialogData.company = null;
        [...props?.commonData?.companies, ...supplierList].forEach((data) => {
          if (data._id === e) {
            defaultDialogData.company = data;
          }
        });
        break;
      }
      case 'type': {
        const { carTypeList } = defaultState;
        defaultDialogData.type = {};
        carTypeList.forEach((data) => {
          if (data._id === e.target.value) {
            defaultDialogData.type = {
              typeId: data._id,
            };
          }
        });
        break;
      }
      case 'vehicleModel_modelId': {
        if (!defaultDialogData.vehicleModel) {
          defaultDialogData.vehicleModel = {};
        }
        defaultDialogData.vehicleModel.modelId = null;
        defaultState.modelList.forEach((data) => {
          if (data._id == e.target.value) {
            defaultDialogData.vehicleModel.modelId = data._id;
          }
        });
        break;
      }
      default: {
        defaultDialogData[key] = e.target.value;
        break;
      }
    }
    setDialogData(defaultDialogData);
  };

  const fetchModelList = async () => {
    const defaultDialogData = { ...dialogData };
    const defaultState = { ...state };
    const vehicleMakeId = _.get(
      defaultDialogData,
      'vehicleModel.vehicleMakeId'
    );
    if (vehicleMakeId) {
      const resultCarModelList = await props.settingActions.getCarModelList({
        fleetId: props.auth.selectedFleet.fleetId,
        vehicleMakeId,
      });
      if (resultCarModelList?.res?.length) {
        defaultState['modelList'] = resultCarModelList.res;
      }
    } else {
      defaultState['modelList'] = [];
    }
    setState(defaultState);
  };

  const closeDialogForm = () => {
    if (props.addNewFromDriverModule && props.closeAddVehiclePage) {
      props.closeAddVehiclePage();
    } else {
      props.navigate.push('/vehicle');
    }
  };

  const initCommonData = async (vehicleId) => {
    const [resultCarMakeList, resultCarTypeList, ] =
      await Promise.all([
        props.settingActions.getCarMakeList({
          fleetId: props.auth.selectedFleet.fleetId,
        }),
        props.settingActions.loadCarType(props.auth.selectedFleet.fleetId),
      ]);
    const defaultState = { ...state };
    if (resultCarMakeList.ok && resultCarMakeList.res) {
      defaultState['makeList'] = resultCarMakeList.res;
    }
    if (resultCarTypeList.ok && resultCarTypeList.res) {
      defaultState['carTypeList'] = resultCarTypeList.res;
    }

    const { vehicleAgeLimit } = props.auth.selectedFleet.generalSetting;
    const currentYear = new Date().getFullYear() + 1;
    let yearsLimit = 1999;
    if (vehicleAgeLimit.enable) {
      yearsLimit = currentYear - vehicleAgeLimit.years;
    }
    for (let i = currentYear; i > yearsLimit; i -= 1) {
      defaultState.yearList.push(i);
    }

    if (vehicleId) {
      const resultDetailCar = await props.settingActions.getRateDetailCar({
        vehicleId,
      });
      if (resultDetailCar.ok) {
        let defaultDialogData = {};
        if (
          resultDetailCar.res.vehicleModel
            ? resultDetailCar.res.vehicleModel.vehicleMakeId
            : ''
        ) {
          const resultCarModelList = await props.settingActions.getCarModelList(
            {
              fleetId: props.auth.selectedFleet.fleetId,
              vehicleMakeId: resultDetailCar.res.vehicleModel.vehicleMakeId,
            }
          );

          if (resultCarModelList.ok && resultCarModelList.res) {
            defaultState.modelList = resultCarModelList.res;
          }
        }

        defaultDialogData = resultDetailCar.res;
        defaultDialogData.insuranceVehicle =
          defaultDialogData.insuranceVehicle || {};
        defaultState.colorSelected = { name: resultDetailCar.res.color };
        defaultDialogData.activeVehicle = !resultDetailCar.res.isReview ? false : resultDetailCar.res.isActive;
        setDialogData(defaultDialogData);
      } else if (resultDetailCar.error) {
        context.notification(
          'error',
          I18n.t(`errors.${resultDetailCar.error.errorCode}`)
        );
      }
    }
    setState(defaultState);
  };

  const saveDialogForm = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!CCLiteCommonFunc.isFormValid(state.valid)) {
      return;
    }
    const {
      auth: { selectedFleet },
    } = props;
    const { colorSelected } = state;
    const object = {
      fleetId: selectedFleet.fleetId,
      plateNumber: dialogData.plateNumber,
      typeId: dialogData.type
        ? dialogData.type._id || dialogData.type.typeId
        : '',
      makeId: dialogData.vehicleModel
        ? dialogData.vehicleModel.vehicleMakeId
        : '',
      modelId: dialogData.vehicleModel ? dialogData.vehicleModel.modelId : '',
      year: (dialogData?.year === 'Select year' ? 0 : dialogData?.year) || 0,
      hwMetered: dialogData.hwMetered,
      companyId: dialogData.company ? dialogData.company._id : '',
      maxPassengers: dialogData.maxPassengers,
      maxLuggage: dialogData.maxLuggage,
      // color: dialogData.color,
      color: colorSelected?.name ? colorSelected?.name : '',
      caseNumber: dialogData.caseNumber,
      licenseExp: dialogData.licenseExp
        ? moment(dialogData.licenseExp).format('MM/DD/YYYY')
        : null,
      vhcId: dialogData.vhcId,
      phone: trimPhoneNumber(dialogData.phone),
      vehicleOwner: dialogData.vehicleOwner,
      insuranceVehicle: dialogData.insuranceVehicle,
    };
    dialogData.fleetId = selectedFleet.fleetId;
    props.loadingBarActions.showLoadingSpiner();
    if (dialogData._id) {
      object.vehicleId = dialogData._id;
      await props.settingActions.updateCars(object).then(async (data) => {
        props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else if (data.message) {
            context.notification(
              'error',
              I18n.t(`errors.${data.message.errorCode}`)
            );
          } else {
            context.notification('error', I18n.t('carSetting.Update_car_fail'));
          }
          setIsSubmitted(false);
        } else {
          if (dialogData.activeVehicle != dialogData.isActive) {
            let isActive = dialogData.activeVehicle;
            await props.settingActions.activeCars({
              fleetId: selectedFleet.fleetId,
              vehicleId: dialogData._id,
              isActive: isActive
            })
          }
          context.notification(
            'success',
            I18n.t('carSetting.Update_car_success')
          );
          closeDialogForm();
        }
      });
    } else {
      await props.settingActions.createCars(object).then((data) => {
        props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else if (data.message) {
            context.notification(
              'error',
              I18n.t(`errors.${data.message.errorCode}`)
            );
          } else {
            context.notification('error', I18n.t('carSetting.Create_car_fail'));
          }
          setIsSubmitted(false);
        } else {
          if (props.addNewFromDriverModule && props.callBackAddNewVehicle) {
            // Add vehicle from driver module
            props.callBackAddNewVehicle(data?.res);
          } else {
            context.notification(
              'success',
              I18n.t('carSetting.Create_car_success')
            );
            closeDialogForm();
          }
        }
      });
    }
  };

  useEffect(() => {
    initCommonData(props.vehicleId);
    getAllSupplier();
  }, [props.auth.selectedFleet.fleetId, props.vehicleId]);

  return (
    <div className={styles['layout']}>
      {props.vehicleId ? (
        <Tabs
          id="tab"
          defaultActiveKey={0}
          animation={false}
          className="cue-tabs"
        >
          <Tab eventKey={0} key={0} title={I18n.t('carSetting.vehicleInfo')}>
            <div style={{ paddingTop: '20px' }}>
              <FormInfo
                isSubmitted={isSubmitted}
                dialogData={dialogData}
                state={state}
                ValidatorCallback={ValidatorCallback}
                handleChangeVehicleMake={handleChangeVehicleMake}
                renderCarColorNoResult={renderCarColorNoResult}
                handleLoadOption={handleLoadOption}
                handleCarColorChange={handleCarColorChange}
                handleInputColorChange={handleInputColorChange}
                handleInputBlur={handleInputBlur}
                handlePhoneChange={handlePhoneChange}
                closeDialogForm={closeDialogForm}
                saveDialogForm={saveDialogForm}
                handleSelectRef={handleSelectRef}
                props={props}
                onEditable={onEditable}
                handleInputChange={handleInputChange}
                isPermissionCompany={isPermissionCompany}
                selectChange={selectChange}
                action={'update'}
                handleChangeCarInsurance={handleChangeCarInsurance}
                supplierList={supplierList}
              />
            </div>
          </Tab>
          {/* <Tab
            disabled
            eventKey={1}
            key={1}
            title={I18n.t('carSetting.Documents')}
          ></Tab> */}
        </Tabs>
      ) : (
        <FormInfo
          isSubmitted={isSubmitted}
          dialogData={dialogData}
          state={state}
          ValidatorCallback={ValidatorCallback}
          handleChangeVehicleMake={handleChangeVehicleMake}
          renderCarColorNoResult={renderCarColorNoResult}
          handleLoadOption={handleLoadOption}
          handleCarColorChange={handleCarColorChange}
          handleInputColorChange={handleInputColorChange}
          handleInputBlur={handleInputBlur}
          handlePhoneChange={handlePhoneChange}
          closeDialogForm={closeDialogForm}
          saveDialogForm={saveDialogForm}
          handleSelectRef={handleSelectRef}
          props={props}
          onEditable={onEditable}
          handleInputChange={handleInputChange}
          isPermissionCompany={isPermissionCompany}
          selectChange={selectChange}
          companyIdFromDriverModule={props.companyIdFromDriverModule}
          action={'add'}
          handleChangeCarInsurance={handleChangeCarInsurance}
          supplierList={supplierList}
        />
      )}
    </div>
  );
};

FormVehicle.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FormVehicle);
