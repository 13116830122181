import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import {
  FormGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { DynamicFareColumns } from './fields';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { getCompanyOfOperator } from '../../../../utils/commonFunctions';
import { BsSearch } from 'react-icons/bs';

class DynamicFare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      list: [],
      rowHeight: 50,
      footerData: {
        page: 0,
        limit: 20,
        total: 0
      }
    };
  }

  componentDidMount() {
    this.fetchOperationZons();
  }

  initToolbarRef = ref => this.toobarContainer = ref;

  fetchOperationZons = (page, limit) => {
    const { footerData, textSearch } = this.state;
    const { actions, selectedFleet, auth, commonData } = this.props;

    let params = {
      limit: limit || footerData.limit,
      page: page || parseInt(page) === 0 ? page : footerData.page,
      query: {
        textSearch,
        fleetId: selectedFleet.fleetId
      }
    };

    // show zone based setting company user
    if (auth?.user.roles?.companyId) {
      const zoneOfCompanyUser = _.get(
        getCompanyOfOperator(auth, [...commonData.companies, ...commonData.suppliers]),
        'operationZone',
        []
      );
      if (zoneOfCompanyUser?.length > 0) {
        params.query.limitZonesId = zoneOfCompanyUser
      }
    }

    actions.getOperationZone(params).then(data => {
      if (data.ok && data.res) {
        this.setState({
          list: data.res.list || [],
          footerData: {
            ...footerData,
            limit: data.res.limit,
            page: data.res.page,
            total: data.res.total
          }
        });
      } else {
        this.context.notification('error', I18n.t('General.Can_not_get_data'));
      }
    });
  };

  handlePaginationSelect = page => this.fetchOperationZons(page);

  handleNumItemsPerPageChange = limit => this.fetchOperationZons(0, limit);

  handleSearchKeyPress = e => {
    if (e.key === 'Enter') {
      this.fetchOperationZons(0);
    } else {
      this.setState({
        textSearch: e.target.value
      });
    }
  };

  getTableColumns = () => {
    const { router } = this.props;

    let tableColumns = Object.assign([], DynamicFareColumns);
    _.forEach(tableColumns, column => {
      if (column.key === 'zoneName') {
        column.customCell = rowData => (
          <OverlayTrigger
            placement="top"
            delayShow={300}
            overlay={
              <Tooltip id="tooltip">
                <span>{rowData.zoneName}</span>
              </Tooltip>
            }
          >
            <a
              className="clickable"
              onClick={() =>
                router.push({
                  pathname: '/settings/Dynamic_fare/' + rowData._id
                })
              }
            >
              {rowData.zoneName}
            </a>
          </OverlayTrigger>
        );
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let parentContentVerticalPadding = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight + 10
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = parentContentVerticalPadding + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;

    if (window.innerHeight < 850 && this.state.tableHeight < 500)
      tableHeight = 550;

    return tableHeight;
  };

  render() {
    const { footerData, textSearch, list, rowHeight } = this.state;

    return (
      <div className="content">
        <div className="header-button-group" ref={this.initToolbarRef}>
          <div className="group-left">
            <FormGroup className="search-format">
              <FormControl
                type="text"
                className="form-control search-form"
                value={textSearch}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchKeyPress}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
          </div>
        </div>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={list}
            footerData={footerData}
            rowHeight={rowHeight}
            getTableHeight={this.getTableHeight}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
          />
        </div>
      </div>
    );
  }
}

DynamicFare.contextTypes = {
  notification: PropTypes.func
};

export default DynamicFare;
