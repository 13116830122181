import NotFoundImage from "../../assets/images/not_found.svg";
import AccessDeniedImage from "../../assets/images/access_denied.svg";
import ServerErrorImage from "../../assets/images/500.svg";
import crashImage from "../../assets/images/crash.svg";

export const ErrorImage = {
  NotFound: NotFoundImage,
  AccessDenied: AccessDeniedImage,
  ServerError: ServerErrorImage,
  Crash: crashImage
};
