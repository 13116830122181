import React, { Component } from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import CountryList from './CountryList';
import RootModal from './RootModal';

class FlagDropDown extends Component {
  static propTypes = {
    allowDropdown: PropTypes.bool,
    dropdownContainer: PropTypes.string,
    separateDialCode: PropTypes.bool,
    dialCode: PropTypes.string,
    countryCode: PropTypes.string,
    showDropdown: PropTypes.bool,
    clickSelectedFlag: PropTypes.func,
    handleSelectedFlagKeydown: PropTypes.func,
    isMobile: PropTypes.bool,
    setFlag: PropTypes.func,
    countries: PropTypes.arrayOf(PropTypes.object),
    inputTop: PropTypes.number,
    inputOuterHeight: PropTypes.number,
    preferredCountries: PropTypes.arrayOf(PropTypes.object),
    highlightedCountry: PropTypes.number,
    changeHighlightCountry: PropTypes.func,
    titleTip: PropTypes.string,
    refCallback: PropTypes.func.isRequired,
    onDropdownToggle: PropTypes.func, // New prop for handling dropdown close
  };

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: props.showDropdown || false
    };
    this.handleDropdownClose = this.handleDropdownClose.bind(this);
  }

  toggleDropdown = (newState) => {
    if (this.props.onDropdownToggle) {
      this.props.onDropdownToggle(newState);
    } else {
      this.setState({ showDropdown: newState });
    }
  }

  handleDropdownClose() {
    this.toggleDropdown(false);
  }

  handleFlagClick = (e) => {
    e.preventDefault();
    if (this.props.allowDropdown) {
      const newDropdownState = !(this.props.onDropdownToggle ? this.props.showDropdown : this.state.showDropdown);
      this.toggleDropdown(newDropdownState);
    }
    if (this.props.clickSelectedFlag) {
      this.props.clickSelectedFlag(e);
    }
  }

  render() {
    const flagClassObj = {
      'iti-flag': true,
    };
    const showDropdown = this.props.onDropdownToggle ? this.props.showDropdown : this.state.showDropdown;
    const arrowClass = classNames({
      'iti-arrow': true,
      up: showDropdown,
    });

    if (this.props.countryCode) {
      flagClassObj[this.props.countryCode] = true;
    }

    const flagClass = classNames(flagClassObj);
    const genCountryList = () =>
      <CountryList
        ref={ (countryList) => { this.countryList = countryList; } }
        dropdownContainer={ this.props.dropdownContainer }
        isMobile={ this.props.isMobile }
        showDropdown={ showDropdown }
        setFlag={ this.props.setFlag }
        countries={ this.props.countries }
        inputTop={ this.props.inputTop }
        inputOuterHeight={ this.props.inputOuterHeight }
        preferredCountries={ this.props.preferredCountries }
        highlightedCountry={ this.props.highlightedCountry }
        changeHighlightCountry={ this.props.changeHighlightCountry }
        onDropdownClose={ this.handleDropdownClose }
      />;

    return (
      <div
        ref={ this.props.refCallback }
        className="flag-container"
      >
        <div
          className="selected-flag"
          tabIndex={ this.props.allowDropdown ? '0' : '' }
          onClick={ this.handleFlagClick }
          onKeyDown={ this.props.handleSelectedFlagKeydown }
          title={ this.props.titleTip }
        >
          <div className={ flagClass } />
          { this.props.separateDialCode && <div className="selected-dial-code">{ this.props.dialCode }</div> }
          { this.props.allowDropdown && <div className={ arrowClass } /> }
        </div>
        { genCountryList() }
      </div>
    );
  }
}

export default FlagDropDown;
