import React from 'react'
import moment from 'moment'
import 'moment-timezone'
import {
  FormGroup
} from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import ReactDatetime from 'react-datetime'

export default class MonthSelector extends React.Component {
  render() {
    return (
      <FormGroup>
        <ReactDatetime
          viewMode='months'
          inputProps={{ contenteditable: false, placeholder: I18n.t("report.query.month") }}
          value={moment(this.props.value)}
          dateFormat="MMM YYYY"
          timeFormat={false}
          onChange={this.props.handleChangeMonth}
          locale={this.props.locale}
          closeOnSelect
        />
      </FormGroup>
    )
  }
}