import React, { useState } from 'react'
import PointInput from './PointInput'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { POINT_TYPE_TEXT, STATUS_COMPLETED_DELIVERY } from '../../constants/commondata';
import { CCLiteCommonFunc } from '../../utils/commonFunctions';

const STATUS_NOT_REMOVE_DELIVERY = STATUS_COMPLETED_DELIVERY

const reorder = (list, startIndex, endIndex) => {
  //start reorder
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  //end reorder

  //Update order method of list point
  // let newPoints = result.map((point, id) => {
  //   point.order = id + 1
  //   return point
  // })

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  margin: `0 0 ${5}px 0`,
  background: isDragging ? "lightgreen" : "#363a44",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#363a44",
  width: '100%'
});


const PointDragGroup = ({
  listPoint,
  changeListPoints,
  removePoint,
  editPoint,
  disabledEditDrag,
  isDisableEditBooking,
  setIsShowRoutePreview,
  jobType,
  isBookingFromPaxApp,
  disabledAddTraveler,
  format24Hour,
  hasPartyLocation,
  // isShow,
  titleTime,
  recentPlaces,
  options,
  setNewPoints,
  checkPointInZoneSupported,
  checkSupportZone,
  fleetId,
  setShowAddPoint,
  pointType,
  newBooking,
  isSubmited,
  listDirtyLocation,
  setDirtyLocation,
  isHydraBooking,
  pickUp,
  corporate,
  isChina,
  isBookDetails,
  handleShowHideMarkFailed,
  data,
  limitNumberPoint,
  ...props
}) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let newListPoint = reorder(
      listPoint,
      result.source.index,
      result.destination.index
    )

    changeListPoints(newListPoint)
  }

  let isDropPointDisabled = false

  if (jobType == 'shuttle' || disabledEditDrag) isDropPointDisabled = true
  let numUnCompleted = 0;
  if (pointType === POINT_TYPE_TEXT.do && jobType === 'delivery') {
    numUnCompleted = listPoint.filter(item => !item.recipient || item.recipient.status !== "completed").length;
  }
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          isDropDisabled={isDropPointDisabled}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {listPoint.map((item, index) => {
                let idKey = item.id && item.id.toString() || index
                let canRemove = true
                if (jobType === 'delivery') {
                  if ((index == 0 && (pointType === POINT_TYPE_TEXT.pu || pointType == POINT_TYPE_TEXT.do))
                    || (item.recipient && STATUS_NOT_REMOVE_DELIVERY.includes(item.recipient.status)) || isDisableEditBooking || numUnCompleted === 1) {
                    canRemove = false
                  }
                } else if ((index == 0 && (pointType === POINT_TYPE_TEXT.pu || pointType == POINT_TYPE_TEXT.do) || disabledEditDrag)
                ) {
                  canRemove = false
                }
                return (
                  <Draggable key={idKey} draggableId={idKey} index={index} isDragDisabled={limitNumberPoint === 1}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <PointInput
                          {...item}
                          data={data}
                          isBookingFromPaxApp={isBookingFromPaxApp}
                          handleShowHideMarkFailed={handleShowHideMarkFailed}
                          corporate={corporate}
                          isEditDisabled={disabledEditDrag}
                          removePoint={removePoint}
                          canRemove={canRemove}
                          isHydraBooking={isHydraBooking}
                          // editPoint= {editPoint}
                          jobType={jobType}
                          setIsShowRoutePreview={setIsShowRoutePreview}
                          disabledAddTraveler={disabledAddTraveler}
                          format24Hour={format24Hour}
                          id={item.id}

                          hasPartyLocation={hasPartyLocation}
                          titleTime={titleTime}
                          recentPlaces={recentPlaces}
                          options={options}
                          setNewPoints={setNewPoints}
                          checkSupportZone={checkSupportZone}
                          checkPointInZoneSupported={checkPointInZoneSupported}
                          setShowAddPoint={setShowAddPoint}
                          fleetId={fleetId}
                          newBooking={newBooking}
                          isBookDetails={isBookDetails}
                          pointType={pointType}
                          isSubmited={isSubmited}
                          listDirtyLocation={listDirtyLocation}
                          setDirtyLocation={setDirtyLocation}
                          pickUp={pickUp}
                          isChina={isChina}
                          auth={props.auth}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              }
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

PointDragGroup.defaultProps = {
  listPoint: [],
  isDropOff: false,
  disabledEditDrag: false,
  jobType: '',
  newBooking: {},
  removePoint: () => { },
  changeListPoints: () => { }
}

export default PointDragGroup