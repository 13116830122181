/* eslint no-console:0 */
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import React from 'react';
import './CcInputNumber.scss';

const upHandler = <i className="fa fa-caret-up" />;
const downHandler = <i className="fa fa-caret-down" />;

export default function CCInputNumber(props) {
  return (
    <InputNumber
      className="CCInputNumber"
      aria-label="Number input example that demonstrates custom styling"
      upHandler={upHandler}
      downHandler={downHandler}
      {...props}
    />
  );
}
