import { I18n } from "react-redux-i18n";

export const Columns = [
  {
    key: 'No.',
    label: 'smartData.No',
    width: 70,
    fixed: true,
    customCell: (doc, index) => index + 1
  },
  {
    key: 'driverName',
    label: 'smartData.driverName',
    sortName: 'driverName',
    fixed: true,
    width: 300,
    textEllipsis: true
  },
  {
    key: 'phone',
    label: 'smartData.phone',
    sortName: 'phone'
  },
  {
    key: 'rating',
    label: 'smartData.rating',
    sortName: 'rating'
  },
  {
    key: 'completed',
    label: 'smartData.completed',
    sortName: 'completed'
  },
  {
    key: 'noShow',
    label: 'smartData.noShow',
    sortName: 'noShow'
  },
  {
    key: 'incident',
    label: 'smartData.incident',
    sortName: 'incident'
  },
  {
    key: 'canceled',
    label: 'smartData.canceled',
    sortName: 'canceled'
  },
  {
    key: 'accepted',
    label: 'smartData.accepted',
    sortName: 'accepted'
  },
  {
    key: 'reject',
    label: 'smartData.reject',
    sortName: 'reject'
  },
  {
    key: 'ignore',
    label: 'smartData.ignore',
    sortName: 'ignore'
  },
  {
    key: 'earning',
    label: 'smartData.earning',
    sortName: 'earning',
    customCell: (doc) => I18n.l(doc.earning)
  }
];