import currencyFormatter from 'currency-formatter'
import { I18n } from 'react-redux-i18n'
import * as _ from 'lodash'
import uuidv4 from 'uuid/v4'
import React, { Component } from 'react'
import { MdOutlineError } from 'react-icons/md'

export class DeliveryRecipients extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const recipients = this.props.booking.deliveryInfo.recipients
    if (!recipients.length) {
      return <></>
    }
    const recipientMap = (recipient) => {
      const { selectedItems } = recipient.menuData
      const packageContent =
        (selectedItems.find((i) => i.fieldKey === 'packageInfo') || {})
          .fieldValue || ''
      const instructions =
        (selectedItems.find((i) => i.fieldKey === 'request.notes') || {})
          .fieldValue || ''
      const orderNumber =
        (selectedItems.find((i) => i.fieldKey === 'request.orderId') || {})
          .fieldValue || ''
      const cod =
        (selectedItems.find((i) => i.fieldKey === 'cashOnDelivery') || {})
          .fieldValue || {}
      let statusIcon = recipient.isValid ? (
        <i className="fa fa-check qup-green" />
      ) : (
        <MdOutlineError color="#DE6C59" size={18} />
      )
      return (
        <tr key={uuidv4()}>
          <td>{statusIcon} </td>
          <td>{recipient.order} </td>
          <td>{orderNumber} </td>
          <td>{recipient.name}</td>
          <td>{recipient.phone}</td>
          <td>{recipient.address.address}</td>
          <td>{recipient.address.locationDetails}</td>
          <td>{packageContent}</td>
          <td>{instructions}</td>
          <td>
            {cod.value
              ? currencyFormatter.format(cod.value || 0.0, {
                  code: cod.iso,
                })
              : ''}
          </td>
        </tr>
      )
    }
    return (
      <div>
        <table className="recipient-table">
          <thead>
            <tr>
              <th> </th>
              <th>#</th>
              <th>{I18n.t('importBookings.delivery.orderNumber')}</th>
              <th>{I18n.t('importBookings.delivery.recipientName')}</th>
              <th>{I18n.t('importBookings.delivery.recipientPhone')}</th>
              <th>{I18n.t('importBookings.delivery.recipientAddress')}</th>
              <th>
                {I18n.t('importBookings.delivery.recipientLocationDetails')}
              </th>
              <th>{I18n.t('importBookings.delivery.packageContent')}</th>
              <th>{I18n.t('importBookings.delivery.instructions')}</th>
              <th>{I18n.t('importBookings.delivery.cod')}</th>
            </tr>
          </thead>
          <tbody>{_.sortBy(recipients, 'order').map(recipientMap)}</tbody>
        </table>
      </div>
    )
  }
}
