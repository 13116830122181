import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as appBanerActions from '../../../../actions/appBanerActions';
import TableActions from '../../../../components/table/tableAction/TableActions';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { bannerColumns } from './bannerFields';
import ConfirmDeleteBanner from './ConfirmDeleteBanner';
import AddEditBannerModal from '../AddEditBannerModal';
import AddEditPlaybackSettingModal from '../AddEditPlaybackSettingModal';
import * as settingActions from '../../../../actions/settingActions';
import { ReactComponent as Move } from "../../../../assets/images/icons/move.svg";

class BannerList extends React.Component {
  constructor() {
    super();
    this.state = {
      banners: [],
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      },
      playbackSetting: {},
      order: {},
    };
  }

  componentDidMount() {
    const { auth: { selectedFleet } = {} } = this.props || {};
    const { fleetId } = selectedFleet || {};
    this.fetchAppBanners();
    this.props.settingActions.fleetInfo({ fleetId: fleetId }).then(data => {
      if (data.fleet) {
        this.setState({
          playbackSetting: data.fleet.playbackSetting
        });
      }
    })
  }

  getTableHeight = () => {
    const { parentRef } = this.props;
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const tabListHeight = 60;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = parentRef ? ReactDOM.findDOMNode(parentRef).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight + tabListHeight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    // return 600;
    return this.state.tableHeight;
  };

  handlePaginationSelect = (eventKey) => {
    this.fetchAppBanners(eventKey);
  }

  handleNumItemsPerPageChange = (e) => {
    const numItemsPerPage = parseInt(e);
    this.fetchAppBanners(0, numItemsPerPage);
  }

  handleAddButtonClick = () => {
    const { appType } = this.props;
    this.setState({
      activeBanner: {
        action: '',
        appType,
        applyTo: '',
        imageUrl: null,
        name: '',
        viewType: 'image',
        displayDuration: 7,
        languageOption: 'single',
        banners: [],
        version: "",
        comparisonVersionApp: "is",
        platform: "all",
        userStatus: [],
        zones: []
      },
      showDialog: true,
      editable: true
    });
  };
  handleSettingButtonClick = () => {
    const playbackSetting = this.state.playbackSetting;
    this.setState({
      playbackSetting: {
        enable: playbackSetting != null ? playbackSetting.enable : false,
        displayBannerAgainAfter: playbackSetting != null ? playbackSetting.displayBannerAgainAfter : 1,
        displayBannerAgainAfterType: playbackSetting != null && playbackSetting.displayBannerAgainAfterType ? playbackSetting.displayBannerAgainAfterType : 'second',
        stopDisplayBannerAfter: playbackSetting != null ? playbackSetting.stopDisplayBannerAfter : 1
      },
      showDialogSetting: true,
      editableSetting: true
    });
  };
  handleMenuClick(key, obj) {
    // const { auth, settingActions } = this.props;
    // const { fleetId } = auth.selectedFleet;
    switch (key) {
      case 'Edit': {
        this.updateBanner(obj);
        break;
      }
      case 'View': {
        this.setState({ showDialog: true, activeBanner: obj, editable: false });
        break;
      }
      case 'Active': {
        this.activeBanner(obj);
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, activeBanner: obj });
        break;
      }
      default:
        break;
    }
  }

  closeFormModal = () => {
    const playbackSetting = this.state.playbackSetting;
    this.setState({
      showDialog: false,
      showConfirm: false,
      editable: false,
      activeBanner: null,
      showDialogSetting: false,
      playbackSetting: {
        enable: playbackSetting != null ? playbackSetting.enable : false,
        displayBannerAgainAfter: playbackSetting != null ? playbackSetting.displayBannerAgainAfter : 1,
        displayBannerAgainAfterType: playbackSetting != null ? playbackSetting.displayBannerAgainAfterType : 'second',
        stopDisplayBannerAfter: playbackSetting != null ? playbackSetting.stopDisplayBannerAfter : 1
      },
      editableString: false
    });
  };
  closeFormModalSetting = () => {
    this.setState({
      showDialog: false,
      showConfirm: false,
      showDialogSetting: false
    });
  }
  handleDeleteBanner = () => {
    const { auth, appBanerServices } = this.props;
    const { fleetId } = auth.selectedFleet;
    const { activeBanner } = this.state;
    const body = {
      bannerId: activeBanner._id,
      fleetId
    };
    appBanerServices.deleteAppBanner(body).then(data => {
      if (!data.error) {
        const { banners } = this.state;
        this.setState({
          banners: banners.filter(o => o._id !== activeBanner._id),
          activeBanner: null,
          showConfirm: false
        });
        this.context.notification('success', I18n.t('appBanner.Delete_banner_success'));
      } else {
        this.closeFormModal();
        if (data.error.errorCode) {
          return this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
        }
        this.context.notification('error', I18n.t('appBanner.Delete_banner_fail'));
      }
    });
  };

  handleAddBannerSuccess = banner => {
    const { banners } = this.state;
    const indexFounded = _.findIndex(banners, o => o._id === banner._id);
    let newBanners = [...banners];
    if (indexFounded !== -1) {
      newBanners[indexFounded] = banner;
    } else {
      newBanners = [banner].concat(banners);
    }
    this.setState({
      banners: newBanners,
      showDialog: false,
      showConfirm: false,
      editable: false,
      activeBanner: null
    });
  };
  handleAddPlaybackSetting = playbackSetting => {
    this.setState({
      showDialogSetting: false,
      playbackSetting: playbackSetting
    });
  }
  fetchAppBanners = (activePage, limit) => {
    const { appBanerServices, appType } = this.props;
    const params = {
      limit: limit || this.state.footerData.limit,
      page: activePage || activePage === 0 ? activePage : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      appType
    };
    appBanerServices.fetchAppBanner(params).then(data => {
      if (!data.error) {
        const { page, total } = data.res;
        this.setState({
          banners: data.res.list,
          footerData: { page, total, limit: params.limit }
        });
      }
    });
  };

  updateBanner = obj => {
    this.setState({
      activeBanner: obj,
      showDialog: true,
      editable: true
    });
  };

  activeBanner = banner => {
    const { auth, appBanerServices } = this.props;
    const { fleetId } = auth.selectedFleet;
    const updateData = {
      fleetId,
      bannerId: banner._id
    };
    const action = banner.activate ? 'deactiveAppBanner' : 'activeAppBanner';
    appBanerServices[action](updateData).then(res => {
      if (!res.error) {
        const { banners } = this.state;
        const newBanners = _.map(banners, o => {
          let { activate } = o;
          if (o._id === banner._id) {
            activate = !banner.activate;
          }
          return _.extend({}, o, {
            activate
          });
        });
        this.setState({ banners: newBanners });
      }
    });
  };

  handleOrderBanners = (banners) => {
    const { appType, auth: { selectedFleet } = {}, appBanerServices } = this.props || {};
    const { fleetId } = selectedFleet || {};
    let items = [];
    let params = {
      appType,
      fleetId
    };
    banners.map(item => {
      items.push({ bannerId: item._id, priority: item.priority })
    })

    if (items.length) {
      params.banners = items
    }
    appBanerServices.orderPriorityAppBanner(params)
      .then(data => {
        if (!data.error) {

        } else {
          if (data.error.errorCode) {
            return this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
          }
          this.context.notification('error', I18n.t('appBanner.Order_Priority_fail'));
        }
      });
  };

  // Drag Drop
  updateBannerPriority = () => {
    const { banners } = this.state;
    let items = banners || [];
    for (var i = 0; i < items.length; i++) {
      items[i].priority = i
    };

    this.setState(
      { banners: items },
      () => {
        this.handleOrderBanners(items);
      });
  }

  onDragStart = (e, index) => {
    this.draggedItem = this.state.banners[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = index => {
    const draggedOverItem = this.state.banners[index];

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let banners = this.state.banners.filter(item => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    banners.splice(index, 0, this.draggedItem);

    this.setState({ banners });
  };

  onDragEnd = () => {
    this.updateBannerPriority();
    this.draggedIdx = null;
  };

  getTableColumns = () => {
    const tableColumns = Object.assign([], bannerColumns);
    // custom data by column
    _.each(tableColumns, col => {
      const returnCol = col;
      switch (col.key) {
        case 'Name':
          returnCol.customCell = doc => {
            const indexItem = _.findIndex(this.state.banners, doc);
            return (
              <div
                className={`item_drag_${col.key}`}
                key={indexItem}
                onDragOver={() => this.onDragOver(indexItem)}
              >
                <div
                  className={'item_drag'}
                  draggable
                  onDragStart={e => this.onDragStart(e, indexItem)}
                  onDragEnd={this.onDragEnd}
                >
                  <div className="item_drag_icon"></div>
                  <div className="item_drag_icon"></div>
                  <a
                    className="clickable"
                    onClick={() => {
                      this.handleMenuClick('View', doc);
                    }}
                  >
                    {doc.name}
                  </a>
                </div>
              </div>
            )
          };
          break;
        case 'viewType':
          returnCol.customCell = doc => I18n.t(`appBanner.${doc.viewType}`);
          break;
        case 'activate':
          returnCol.customCell = doc => {
            const textView = doc.activate ? 'Active' : 'Inactive';
            const className = doc.activate ? 'Active' : 'Inactive';
            const translateText = (
              <Translate className={className} value={`promotionSettings.${textView}`} />
            );
            if (!this.props.permissions || this.props.permissions.actions) {
              return (
                <a
                  onClick={() => {
                    this.handleMenuClick('Active', doc);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {translateText}
                </a>
              );
            }
            return translateText;
          };
          break;

        case 'actions':
          returnCol.customCell = (doc, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              const actions = [];
              actions.push({ label: 'promotionSettings.Edit', eventKey: 'Edit' });
              if (doc.activate) {
                actions.push({ label: 'promotionSettings.Deactivate', eventKey: 'Active' });
              } else {
                actions.push({ label: 'promotionSettings.Activate', eventKey: 'Active' });
                actions.push({ label: 'promotionSettings.Delete', eventKey: 'Delete' });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={doc}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, doc);
                  }}
                  totalRow={this.state.banners.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actions}
                  tableHeight={this.state.tableHeight}
                />
              );
            }
            return (
              <a
                onClick={e => {
                  this.handleMenuClick('View', doc);
                }}
                href="javascript:void(0)"
              >
                <Translate value="shiftSettings.View" />
              </a>
            );
          };
          break;
        default:
          break;
      }
    });
    return tableColumns;
  };

  render() {
    const { permissions } = this.props;
    const { banners } = this.state;
    const hasPermission = !permissions || permissions.actions;
    return (
      <div className="content pt-sm pl0">
        {hasPermission ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button className="btn-header text-add-header ml0" onClick={this.handleAddButtonClick}>
              {' '}

              <Translate value="shiftSettings.Add" />
            </Button>
            <Button className="btn-header text-add-header ml0" onClick={this.handleSettingButtonClick}>
              {' '}
              <Translate value="appBanner.playback_settings" />
            </Button>
          </ButtonToolbar>
        ) : null}
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={banners || []}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
        {this.state.showConfirm ? (
          <ConfirmDeleteBanner
            confirmDelete={this.handleDeleteBanner}
            closeFormModal={this.closeFormModal}
          />
        ) : (
          ''
        )}
        {this.state.showDialog && this.state.activeBanner ? (
          <AddEditBannerModal
            banner={this.state.activeBanner}
            editable={this.state.editable}
            closeDialogForm={this.closeFormModal}
            onAddBannerSuccess={this.handleAddBannerSuccess}
            playbackSetting={this.state.playbackSetting}
          />
        ) : null}
        {this.state.showDialogSetting && this.state.playbackSetting ? (
          <AddEditPlaybackSettingModal
            playbackSetting={this.state.playbackSetting}
            editable={this.state.editableSetting}
            closeDialogForm={this.closeFormModalSetting}
            onAddBannerSuccess={this.handleAddPlaybackSetting}
          />
        ) : null}
      </div>
    );
  }
}

BannerList.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    appBanerServices: bindActionCreators(appBanerActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BannerList);
