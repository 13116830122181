import {
  CUSTOMER_AUTOCOMPLETE_REQUEST,
  CUSTOMER_AUTOCOMPLETE_SUCCESS,
  CUSTOMER_AUTOCOMPLETE_FAILURE,
} from "../actions/customerAutocompleteAction";

const initialState={
    data:[],
    error:null
}
export default function customerAutocomplete(state=initialState,action={}){
    switch(action.type){
        case CUSTOMER_AUTOCOMPLETE_REQUEST:{
            return state;
        }
        case CUSTOMER_AUTOCOMPLETE_SUCCESS:{
            return {...state, data:action.data};
        }
        case CUSTOMER_AUTOCOMPLETE_FAILURE:{
            return {...initialState,error:action.error}
        }
        default:
            return state
    }
}