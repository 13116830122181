import React, { useEffect, useRef, useState } from 'react';
import FormInfo from './forms/formInfo';
import styles from '../styles/formSupplier.module.scss';
import { parsePhoneNumber } from 'libphonenumber-js';
import { connect } from 'react-redux';
import * as settingActions from '../../../actions/settingActions';
import * as paymentMethodActions from '../../../actions/paymentMethodActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import {
  CCLiteCommonFunc,
  Validation,
  MultiSelectCommonFunc,
  textboxNumberHelper,
  convertToMinutes,
  convertToHours,
  convertToMinutesFromType,
  trimPhoneNumber,
} from '../../../utils/commonFunctions';
import PropTypes from 'prop-types';
import SupplierUser from './forms/formUser/SupplierUser';

import { I18n } from 'react-redux-i18n';
import { Tab, Tabs } from 'react-bootstrap';
import { PAYMENTS_ONLY_APP, UNIT_TIME } from '../../../constants/commondata';
import FormPolicy from './forms/formPolicy';
import FormSetting from './forms/formSetting';
let phone;
let corporationTypes = [
  'Sole Proprietorship',
  'General Partnership',
  'LP',
  'LLP',
  'LLLP',
  'LLC',
  'LC',
  'Ltd. Co',
  'PLLC',
  'Corp.',
  'Inc.',
];
let lastAutoCompleteRequestId = null;
const FormSupplier = (props, context) => {
  const [state, setState] = useState({
    isSubmitted: false,
    valid: {},
    editable: props.action !== 'view' ? true : false,
  });
  const [validTab, setValidTab] = useState({
    info: false,
    policy: false,
    setting: false,
  });
  const [agents, setAgents] = useState([]);
  const suggestionListRef = useRef(null);
  const [emailList, setEmailList] = useState([]);
  const [detailItem, setDetailItem] = useState({
    agentId: _.get(props.auth, 'user.roles.companyId', null)
      ? _.get(props, 'commonData.companies[0]._id', {})
      : null,
    corporationType: '',
    country: '',
    address: '',
    zipCode: '',
    city: '',
    state: '',
    tax: '',
    businessRegistration: '',
    vatNumber: '',
    adminInfo: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    },
    activeSupplier: false,
  });

  const handlePhoneChange = (
    status,
    value,
    countryData,
    number,
    id,
    isBlur,
    isDialCodeOnly
  ) => {
    try {
      const defaultDetailItem = { ...detailItem };
      let newStatus = false;
      if (!status && number.length > 6 && number !== '+undefined') {
        const phoneNumber = parsePhoneNumber(number);
        if (phoneNumber) {
          newStatus = phoneNumber.isValid();
        }
      }
      if (isBlur) {
        if (!status) return false;
        let newPhone;
        if (
          countryData &&
          '+' + countryData.dialCode == trimPhoneNumber(number)
        ) {
          newPhone = '';
        } else {
          newPhone = trimPhoneNumber(number);
        }
        if (newPhone !== phone) {
          setDetailItem(defaultDetailItem);
        }
      } else {
        defaultDetailItem.adminInfo.phone = number;
        setDetailItem(defaultDetailItem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validatorCallback = (id, valid, messages) => {
    const defaultState = { ...state };

    if (defaultState.valid && defaultState.valid[id] !== valid) {
      defaultState.valid[id] = valid;
      setState(defaultState);
    }
  };

  const handleInputChange = (key, e) => {
    const defaultDetailItem = { ...detailItem };
    _.set(defaultDetailItem, key, e.target.value);
    setDetailItem(defaultDetailItem);
  };

  const handleSelectChange = (key, e) => {
    const defaultDetailItem = { ...detailItem };
    if (key === 'country') {
      defaultDetailItem[key] = e;
    } else {
      defaultDetailItem[key] = e.target.value;
    }
    setDetailItem(defaultDetailItem);
  };

  const escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const getAgentsList = async (search) => {
    try {
      let params = {
        limit: 999,
        page: 0,
        fleetId: props.auth.selectedFleet.fleetId,
        search: search,
      };

      const res = await props.settingActions.getAllCompany(params);
      const defaultState = { ...state };

      if (res.ok && res.res) {
        setAgents(res.res.list);
      } else {
        setAgents([]);
      }
    } catch (error) {}
  };

  const handleAgentChange = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.agentId = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleConnectTokenChange = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.connectToken = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleEmailsApplyChange = (values) => {
    setEmailList(values);
  };

  const handleOperationZoneChange = (value, action) => {
    const newValue = value.map((item) => item.value);
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.operationZone = newValue;
    setDetailItem(defaultDetailItem);
  };

  const handleCommissionChange = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.commissionValue = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handlePaymentMethodsApplyChange = (value, action) => {
    const newValue = value.map((item) => item.value);
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.paymentMethodsApply = newValue;
    setDetailItem(defaultDetailItem);
  };

  const handleChangeAdvanceBookingVisibility = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.advanceBookingVisibility = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleBroadcastBookingChange = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.broadcastBooking = e.target.checked;
    setDetailItem(defaultDetailItem);
  };

  const handleChangeDelayBroadcastValue = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.delayBroadcast.value = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleChangeReleaseTime = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.releaseTime = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleChangeDelayBroadcastType = (e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem.delayBroadcast.unit = e.target.value;
    setDetailItem(defaultDetailItem);
  };

  const handleChangeCheckBox = (key, e) => {
    const defaultDetailItem = { ...detailItem };
    defaultDetailItem[key] = e.target.checked;
    setDetailItem(defaultDetailItem);
  };

  const showCommissionClick = () => {
    const defaultState = { ...state };
    defaultState.showCommission = !defaultState.showCommission;
    setState(defaultState);
  };

  const saveCommissionEdit = () => {
    const defaultState = { ...state };
    handleCommissionCompanyChange(detailItem.commissionCompanyValue, 'value');
    defaultState.showCommission = false;
    setState(defaultState);
  };

  const handleCommissionTypeChangeAndValue = (e, serviceType = '', key) => {
    let regrex = /^(\d*\.?\d{0,2})$/;
    const defaultDetailItem = { ...detailItem };
    //if(key == 'value' && !regrex.test(e.target.value)) return
    let commissionCompanyValue = defaultDetailItem.commissionCompanyValue;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = commissionCompanyValue.map((commission) => {
        if (
          commission.serviceType === serviceType &&
          (key === 'type' || (key !== 'type' && regrex.test(e.target.value)))
        ) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        defaultDetailItem.commissionCompanyValue = newValueCommissions;
        setDetailItem(defaultDetailItem);
      }
    }
  };

  const handleCommissionCompanyChange = (data, key = 'type') => {
    const defaultDetailItem = { ...detailItem };
    if (key == 'value') {
      defaultDetailItem.commissionCompanyValue = data.filter(function (c) {
        return c.value !== 'N/A';
      });
    } else {
      defaultDetailItem.commissionCompanyType = data.target.value;
    }
    setDetailItem(defaultDetailItem);
  };

  const handleValidTabs = async () => {
    const defaultValidTab = {
      info: false,
      policy: false,
      setting: false,
    };
    const defaultState = { ...state };

    if (!CCLiteCommonFunc.isFormValid(state.valid)) {
      if (!state.valid?.paymentMethodsApply || !state.valid?.zone) {
        defaultValidTab.policy = true;
      }
      setValidTab(defaultValidTab);
      setState(defaultState);
      return;
    }

    if (detailItem._id && detailItem.connectToken) {
      props.loadingBarActions.showLoadingSpiner();
      const dataResponse = await props.settingActions.checkExistingStripe({
        fleetId: props.auth.selectedFleet.fleetId,
        token: detailItem.connectToken,
      });
      const status = _.get(dataResponse, 'res.response.status', '');
      if (status === 'activated') {
      } else {
        props.loadingBarActions.hideLoadingSpiner();
        if (status === 'pending') {
          defaultValidTab.setting = true;
          context.notification(
            'error',
            'The stripe account id you provided is in-review, please try again'
          );
          setValidTab(defaultValidTab);

          return;
        }
        defaultValidTab.setting = true;

        context.notification(
          'error',
          'The stripe account id you provided is incorrect, please try again'
        );
        setValidTab(defaultValidTab);

        return;
      }
    }
  };

  const saveDialogForm = async () => {
    try {
      handleValidTabs();
      const defaultState = { ...state };
      if (!state.isSubmitted) {
        defaultState.isSubmitted = true;
      }
      if (!CCLiteCommonFunc.isFormValid(state.valid)) {
        setState(defaultState);
        return;
      }
      detailItem.fleetId = props.auth.selectedFleet.fleetId;
      if (!detailItem?.name?.trim()) {
        setState(defaultState);
        return;
      }
      props.loadingBarActions.showLoadingSpiner();
      if (detailItem._id) {
        const delayBroadcast = detailItem.delayBroadcast || {};
        let objectCompany = {
          ...detailItem,
          fleetId: props.auth.selectedFleet.fleetId,
          name: detailItem.name,
          commissionValue: detailItem.commissionValue,
          commissionCompanyType: detailItem.commissionCompanyType,
          commissionCompanyValue: detailItem.commissionCompanyValue.map(
            (item) => {
              return {
                ...item,
                value: item.value === 'N/A' ? 0 : item.value,
              };
            }
          ),
          companyId: detailItem._id,
          isHideFare: detailItem.isHideFare || false,
          broadcastBooking: detailItem.broadcastBooking || false,
          delayBroadcast: {
            valueInMinute: convertToMinutesFromType(
              delayBroadcast?.value,
              delayBroadcast?.unit
            ),
            value: delayBroadcast?.value || 0,
            unit: delayBroadcast?.unit || UNIT_TIME.Minute,
          },
          emails: emailList.map((item) => item.value) || [],
          paymentMethodsApply: detailItem.paymentMethodsApply || [],
          operationZone: detailItem.operationZone || [],
          advanceBookingVisibility: detailItem.advanceBookingVisibility || 7,
          releaseTime: convertToMinutes(detailItem.releaseTime || '00:00'),
          agentId: detailItem?.agentId || null,
          type: 'supplier',
        };
        objectCompany.connectToken = '';
        // Check existing stripe
        if (detailItem.connectToken) {
          props.loadingBarActions.showLoadingSpiner();
          const dataResponse = await props.settingActions.checkExistingStripe({
            fleetId: props.auth.selectedFleet.fleetId,
            token: detailItem.connectToken,
          });
          const status = _.get(dataResponse, 'res.response.status', '');
          if (status === 'activated') {
            objectCompany.connectToken = detailItem.connectToken;
          } else {
            props.loadingBarActions.hideLoadingSpiner();
            if (status === 'pending') {
              context.notification(
                'error',
                'The stripe account id you provided is in-review, please try again'
              );
              return;
            }
            context.notification(
              'error',
              'The stripe account id you provided is incorrect, please try again'
            );
            return;
          }
        }

        await props.settingActions.updateCompany(objectCompany).then(async (data) => {
          props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              if (data.error.status === 411) {
                if (data.error.errorCode === 5003) {
                  context.notification(
                    'error',
                    I18n.t(
                      'New zone select not includes the old zone that has been assigned'
                    )
                  );
                } else {
                  context.notification(
                    'error',
                    I18n.t(
                      'Unable to to delete a zone that has been assigned to a driver'
                    )
                  );
                }
              } else {
                context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              }
            } else {
              context.notification(
                'error',
                I18n.t('companySetting.Update_company_fail')
              );
            }
          } else {
            context.notification(
              'success',
              I18n.t('companySetting.Update_company_success')
            );
            setValidTab({
              info: false,
              policy: false,
              setting: false,
            });
            // setTimeout(() => {/
            defaultState.isSubmitted = false;
            defaultState.editable = false;
            setState(defaultState);

            if (detailItem.activeSupplier != detailItem.isActive) {
              let isActive = detailItem.activeSupplier;
              const resultActive = await props.settingActions.activeCompany({
                _id: detailItem._id,
                fleetId: props.auth.selectedFleet.fleetId,
                isActive: isActive,
              });
              if (!resultActive.ok) {
                props.loadingBarActions.hideLoadingSpiner();
                context.notification(
                  'error',
                  resultActive?.error?.message || I18n.t(`errors.${resultActive.error.errorCode}`)
                );
                return 
              }
    
              if (resultActive.status == 400) {
                props.loadingBarActions.hideLoadingSpiner();
                context.notification(
                  'error',
                  I18n.t('companySetting.Update_company_fail')
                );
                return
              }
            }
          }
        });
      } else {
        const params = {
          ...detailItem,
          releaseTime: convertToMinutes(detailItem.releaseTime),
          delayBroadcast: {
            ...detailItem?.delayBroadcast,
            valueInMinute: convertToMinutesFromType(
              detailItem?.delayBroadcast?.value,
              detailItem?.delayBroadcast?.unit
            ),
          },
          agentId: detailItem?.agentId || null,
          type: 'supplier',
        };
        props.settingActions.createCompany(params).then((data) => {
          props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              context.notification(
                'error',
                I18n.t('companySetting.Create_company_fail')
              );
            }
          } else {
            context.notification(
              'success',
              I18n.t('companySetting.Create_company_success')
            );
            props.navigate.push({
              // pathname: `/supplier/detail/${data?.res?._id}/info`,
              pathname: `/supplier`,
              // state: {
              //   edit: false,
              // },
            });
            setState(defaultState);
          }
        });
      }
    } catch (error) {
      context.notification('error', I18n.t('Something went wrong'));
      console.log(error);
    }
  };

  const handleTabSelect = (key) => {
    props.navigate.push({
      pathname: `/supplier/detail/${props.params?.supplierId}/${key}`,
    });
  };

  const renderOldPayment = (data = {}) => {
    let paymentMethod = state?.paymentMethodState?.map((ob) => ob.key) || [];
    if (data?.res?.paymentMethodsApply?.length > 0) {
      paymentMethod = data.res.paymentMethodsApply;
    }
    return paymentMethod;
  };

  const renderOldOperationZone = (data = {}) => {
    let zones = state.fleetZones?.map((ob) => ob._id) || [];
    if (data?.res?.operationZone?.length > 0) {
      zones = data.res.operationZone;
    }
    return zones;
  };

  const handleLoadOptionDebounce = (input, callback) => {
    const reEmail =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!reEmail.test(input))
      return callback({
        complete: false,
        options: [],
      });
    const result = {
      complete: true,
      options: [
        {
          value: input,
          id: input,
        },
      ],
    };
    callback(null, result);
  };

  const initDetailSupplier = async () => {
    try {
      const res = await props.settingActions.geDetailCompany({
        companyId: props.params.supplierId,
        fleetId: props.auth.selectedFleet.fleetId,
        isSupplier: true,
      });

      if (res.ok) {
        setEmailList(
          res?.res?.emails?.map((item) => {
            return {
              id: item,
              value: item,
            };
          }) || []
        );
        setDetailItem({
          ...res.res,
          paymentMethodsApply: renderOldPayment(res),
          operationZone: renderOldOperationZone(res),
          releaseTime: convertToHours(res?.res?.releaseTime || 0),
          agentId: res.res?.agentId?._id || null,
          activeSupplier: res.res?.isActive || false,
        });
      } else {
        props.navigate.push('/supplier');
      }
    } catch (error) {
      console.log(error);
      props.navigate.push('/supplier');
    }
  };

  const initPaymentList = async () => {
    try {
      await props.paymentMethodActions
        .paymentMethod(props.auth.selectedFleet.fleetId)
        .then((data) => {
          const paymentMethodFleet = data?.res || {};
          let paymentMethods = [
            ...paymentMethodFleet.individualTypes,
            ...paymentMethodFleet.corporateTypes,
            ...paymentMethodFleet.type3rd,
          ];
          paymentMethods = Array.from(
            new Set(paymentMethods.map((a) => a.key))
          ).map((key) => {
            return paymentMethods.find((a) => a.key === key);
          });

          // remove paymentType chi support tren app
          _.remove(paymentMethods, (pm) => PAYMENTS_ONLY_APP.includes(pm.key));

          setState({
            ...state,
            paymentMethodState: paymentMethods,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const closeDialogForm = () => {
    props.navigate.push('/supplier');
  };

  const onEditable = () => {
    const defaultState = { ...state };
    defaultState.editable = true;
    setState(defaultState);
  };

  useEffect(() => {
    getAgentsList();
  }, []);

  useEffect(() => {
    if (props.auth.selectedFleet.fleetId && props?.params?.supplierId) {
      initDetailSupplier();
      initPaymentList();
    }
  }, [props.auth.selectedFleet.fleetId, props?.params?.supplierId]);

  const renderFromInfo = () => {
    return (
      <FormInfo
        isSubmitted={state.isSubmitted}
        agents={agents}
        state={state}
        detailItem={detailItem}
        validatorCallback={validatorCallback}
        handleInputChange={handleInputChange}
        corporationTypes={corporationTypes}
        handleSelectChange={handleSelectChange}
        handlePhoneChange={handlePhoneChange}
        handleAgentChange={handleAgentChange}
        props={props}
        onEditable={onEditable}
        saveDialogForm={saveDialogForm}
        closeDialogForm={closeDialogForm}
        suggestionListRef={suggestionListRef}
      />
    );
  };

  const tabsRef = useRef();
  const getTabHieght = () => {};

  return (
    <div className={styles['layout']}>
      {props.params?.supplierId ? (
        <Tabs
          id="supplierDetail"
          defaultActiveKey={props?.params?.tabActive || 'info'}
          onSelect={handleTabSelect}
          className={'supplierTabs'}
          ref={tabsRef}
        >
          <Tab
            eventKey={'info'}
            title={<div>{I18n.t('supplier.Account')}</div>}
          >
            <FormInfo
              isSubmitted={state.isSubmitted}
              agents={agents}
              state={state}
              detailItem={detailItem}
              validatorCallback={validatorCallback}
              handleInputChange={handleInputChange}
              corporationTypes={corporationTypes}
              handleSelectChange={handleSelectChange}
              handlePhoneChange={handlePhoneChange}
              handleAgentChange={handleAgentChange}
              props={props}
              onEditable={onEditable}
              saveDialogForm={saveDialogForm}
              closeDialogForm={closeDialogForm}
              suggestionListRef={suggestionListRef}
              handleChangeCheckBox={handleChangeCheckBox}
            />
          </Tab>

          {!props?.auth?.user?.roles?.isSupplier && (
            <Tab
              eventKey={'policy'}
              title={
                <div style={{ color: validTab?.policy ? '#a94442' : '' }}>
                  {I18n.t('supplier.policy')}
                </div>
              }
            >
              <FormPolicy
                handleOperationZoneChange={handleOperationZoneChange}
                props={props}
                detailItem={detailItem}
                state={state}
                validatorCallback={validatorCallback}
                onEditable={onEditable}
                saveDialogForm={saveDialogForm}
                closeDialogForm={closeDialogForm}
                handleCommissionChange={handleCommissionChange}
                handlePaymentMethodsApplyChange={
                  handlePaymentMethodsApplyChange
                }
                handleChangeAdvanceBookingVisibility={
                  handleChangeAdvanceBookingVisibility
                }
                handleBroadcastBookingChange={handleBroadcastBookingChange}
                handleChangeDelayBroadcastValue={
                  handleChangeDelayBroadcastValue
                }
                handleChangeReleaseTime={handleChangeReleaseTime}
                handleChangeDelayBroadcastType={handleChangeDelayBroadcastType}
                handleChangeCheckBox={handleChangeCheckBox}
                agents={agents}
                handleCommissionCompanyChange={handleCommissionCompanyChange}
                showCommissionClick={showCommissionClick}
                saveCommissionEdit={saveCommissionEdit}
                handleCommissionTypeChangeAndValue={
                  handleCommissionTypeChangeAndValue
                }
              />
            </Tab>
          )}
          {(!props?.auth?.user?.roles?.isSupplier ||
            props?.auth?.user?.roles?.supplierPermission?.user) && (
            <Tab
              eventKey={'users'}
              title={<div>{I18n.t('supplier.users')}</div>}
            >
              <SupplierUser
                router={props.navigate}
                supplierId={props.params?.supplierId}
                detailItem={detailItem}
              />
            </Tab>
          )}

          {!props?.auth?.user?.roles?.isSupplier && (
            <Tab
              eventKey={'setting'}
              title={
                <div style={{ color: validTab?.setting ? '#a94442' : '' }}>
                  {I18n.t('supplier.setting')}
                </div>
              }
            >
              <FormSetting
                props={props}
                detailItem={detailItem}
                state={state}
                validatorCallback={validatorCallback}
                onEditable={onEditable}
                saveDialogForm={saveDialogForm}
                closeDialogForm={closeDialogForm}
                handleConnectTokenChange={handleConnectTokenChange}
                handleEmailsApplyChange={handleEmailsApplyChange}
                emailList={emailList}
                handleLoadOptionDebounce={handleLoadOptionDebounce}
              />
            </Tab>
          )}
        </Tabs>
      ) : (
        <>{renderFromInfo()}</>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
  };
}

FormSupplier.contextTypes = {
  notification: PropTypes.func,
};
FormSupplier.prototype = {
  action: PropTypes.string.isRequired,
  navigate: PropTypes.bool.isRequired,
  params: PropTypes.bool.isRequired,
};

FormSupplier.defaultProps = {
  action: '',
  navigate: {},
  params: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSupplier);
