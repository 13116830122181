export const FilterFavoriteColumns = [
    {
        key: "name",
        label: "cue.favorite_filter_name",
        textEllipsis: true,
    },
    {
        key: "actions",
        label: "cue.favorite_filter_actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]