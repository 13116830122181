import React, { Component } from "react";
import {
  FormGroup,
  FormControl,
  Row,
  Col
} from "react-bootstrap";
import {
  CCLiteCommonFunc,
  Validation,
} from "../../../utils/commonFunctions";
import _, { debounce } from 'lodash'
import { I18n, Translate } from "react-redux-i18n";
import FormGroupTitle from "../../formGroupTitile/FormGroupTitle";
import { Validator, ValidCase, WarningFeeInput } from "./../../validator";
var currencyFormatter = require("currency-formatter");

class PromoInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { isShowWarning: true };
    this.onClickApplyPromo = this.onClickApplyPromo.bind(this);
  }

  isDisable = () => {
    const { locationPickUp, data, newBooking } = this.props;
    return (
      !(locationPickUp && data.phone) ||
      (newBooking.locationService && newBooking.locationService.crossZone)
    );
  };

  onClickApplyPromo() {
    const { data, handlePromoApplyClick, canEditPromo } = this.props;
    let promoDisabled = data.bookId ? !canEditPromo(data) : this.isDisable();
    if (promoDisabled) return;
    handlePromoApplyClick();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bookId } = nextProps;
    if (bookId && this.props.bookId && bookId != this.props.bookId) {
      this.setState({isShowWarning: false})
    }
  }
  
  handleChangeTip = (e) => {
    this.setState({isShowWarning: true})
    this.props.handleChangeTip(e)
  }

  render() {
    const {
      handleChangePromo,
      data,
      promo,
      newBooking,
      locationPickUp,
      valid,
      isSubmited,
      selectedFleet,
      bookingCurrency = {},
      ValidatorCallback,
      isDispatchAble,
      canEditPromo,
      jobType,
      groupId,
      bookId,
      isDisableOtherOperation,
      isDisableWhenPrepaided
    } = this.props;
    const isBookingDetail = !!data.bookId;
    const promoCode = isBookingDetail ? data.request.promo : data.promo;

    let tip = data.tips;
    let tipDisabled = false;
    let promoDisabled = this.isDisable();
    let buttonDisable = isBookingDetail ? !canEditPromo(data) : promoDisabled;
    let isActiveTip = jobType != 'delivery' && 
      ((selectedFleet.fleetFareId && selectedFleet.fleetFareId.tipActive) ||
      (locationPickUp &&
        newBooking.locationService &&
        newBooking.locationService.crossZone &&
        newBooking.locationService.additionalService &&
        newBooking.locationService.additionalService.tip &&
        newBooking.locationService.additionalService.tip.enable));

    if (isBookingDetail) {
      tip = data.request.tip || 0;
      tipDisabled = !isDispatchAble(data) || data.pricingType || data.dispatchRideSharing || isDisableOtherOperation || isDisableWhenPrepaided;
      promoDisabled = !canEditPromo(data) || !!data.promoApplied || !!promo || isDisableOtherOperation || isDisableWhenPrepaided;
    }

    if (
      !isActiveTip &&
      newBooking &&
      newBooking.locationService &&
      newBooking.locationService.crossZone
    ) {
      //if tip not active and is crossZone . return ""
      return "";
    }

    let hideApplyPromo = false;
    if (
      data.request &&
      data.request.estimate &&
      data.request.estimate.fare &&
      data.request.estimate.fare.addOnPrice &&
      data.request.estimate.fare.addOnPrice !== 0
    ) {
      hideApplyPromo = true;
    };

    return (
      <div>
        <FormGroupTitle>
          {isActiveTip ? (
            <Translate
              value="newbooking.TIP_PROMO"
              className="transform-none"
            />
          ) : (
            <Translate value="newbooking.PromoTitle" />
          )}
        </FormGroupTitle>
        <Row>
          {
            isActiveTip &&
            <Col md={6}>
              <FormGroup
                className={
                  !isSubmited ? null : valid.tip === false ? "error" : null
                }
              >
                {isActiveTip ? (
                  <div className="input-with-validator">
                    <div className="add-on-input-group">
                      <FormControl
                        type="number"
                        value={tip}
                        onChange={this.handleChangeTip}
                        className="form-custom"
                        min="0"
                        disabled={tipDisabled}
                        onWheel={e => e.preventDefault()}
                      />

                      <span variant="success" className="add-on-button" disabled="">
                        %
                    </span>
                      {
                        !groupId &&
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={tipDisabled}
                          value={tip}
                          groupId={groupId}
                          bookId={bookId}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      }
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <Validator callback={ValidatorCallback} id="tip">
                  <ValidCase
                    valid={Validation.isInRange(tip, 0, 100)}
                    hide={true}
                    message={I18n.t(
                      "messages.commonMessages.Integer_from_range"
                    ).format("0", "100")}
                  />
                </Validator>
              </FormGroup>
            </Col>
          }
          <Col md={6}>
            {(
              (newBooking &&
                newBooking.locationService &&
                newBooking.locationService.crossZone) ||
              (data.pricingType && data.pricingType == 1) ||
              hideApplyPromo
            )
              ? null
              : (
                <FormGroup>
                  <div className="add-on-input-group">
                    <FormControl
                      type="text"
                      onChange={handleChangePromo}
                      value={(promoCode || '').toUpperCase()}
                      placeholder={I18n.t("newbooking.Promo_code")}
                      disabled={promoDisabled}
                      className="form-custom"
                    />
                    {!buttonDisable && (
                      <span
                        variant="success"
                        className="add-on-button"
                        onClick={this.onClickApplyPromo}
                      >
                        {promo
                          ? I18n.t("newbooking.Remove")
                          : I18n.t("newbooking.Apply")}
                      </span>
                    )}
                  </div>
                </FormGroup>
              )}
            {!isBookingDetail && promo ? (
              <div>
                <Translate value="newbooking.Promo" className="promotion-value" />
                <span className="promotion-value"> -</span>
                <span className="promotion-value">
                  {promo.type == "Amount"
                    ? currencyFormatter.format(promo.value, {
                      code: bookingCurrency.iso
                    })
                    : promo.value + "%"}
                </span>
              </div>
            ) : null}
            {isBookingDetail && promo ? (
              <FormGroup>
                <Translate value="newbooking.Promo" className="promotion-value" />
                {"\u00A0"}
                {CCLiteCommonFunc.isBookingStatusCompleted(data.status) ||
                (promo && !promo.type) ? (
                  <span>{data.request.promoValue}</span>
                ) : !!promo ? (
                  <span>
                    -
                    {promo.type == 'Amount'
                      ? currencyFormatter.format(promo.value, {
                          code: data.currencyISO,
                        })
                      : promo.value + '%'}
                  </span>
                ) : null}
              </FormGroup>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default PromoInfo;
