import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

import './upload.scss';
import './uploadImage.scss';

const ACCEPTED_FILES =
  '.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip';
const MAX_FILE_SIZE = 8;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: '',
      file: props.file || '',
    };
    this.inputElement = React.createRef();
  }

  isValidImageFile = (file) => {
    const validExtensions = (this.props.accepts || ACCEPTED_FILES).split(', ');
    const maxFileSize = this.props.maxFileSize || MAX_FILE_SIZE;
    const fileExtension =
      file.name.substring(file.name.lastIndexOf('.'), file.name.length) ||
      file.name;
    if (this.props.onlyImage) {
      const isImage = file.type.includes('image');
      if (!isImage) return { status: false, error: 'INVALID_EXTENSION' };
    } else if (validExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
      return { status: false, error: 'INVALID_EXTENSION' };
    }
    if (file.size > maxFileSize * 1024 * 1000) {
      return { status: false, error: 'INVALID_FILE_SIZE' };
    }
    return { status: true };
  };

  handleFileChange = async (e) => {
    if (this.props.handleFileChange) {
      this.props.handleFileChange(e);
    } else {
      e.preventDefault();
      const { target } = e;
      if (!target.files.length) return false;

      const file = target.files[0];
      const validateFile = this.isValidImageFile(file);
      if (!validateFile.status) {
        return this.handleNoFileSelected(validateFile.error);
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.error) {
          this.handleNoFileSelected(reader.error);
        } else {
          this.setState(
            {
              file,
              result: reader.result,
            },
            () => {
              this.props.onChange(Object.assign({}, this.state), null);
            }
          );
        }
      };
      if (this.props.accepts && this.props.accepts.indexOf('csv') !== -1) {
        reader.readAsText(file);
      } else {
        reader.readAsDataURL(file);
      }
    }
  };

  handleNoFileSelected = (error, removeFile = false) => {
    if (this.inputElement && this.inputElement.current) {
      this.inputElement.current.value = '';
    }
    // if (this.inputElement) ReactDOM.findDOMNode(this.inputElement).value = '';
    this.setState({ file: '', result: '' }, () => {
      this.props.onChange(null, error);
      if (removeFile && this.props.removeFileCallback)
        this.props.removeFileCallback();
    });
  };

  render() {
    const {
      name,
      id,
      note,
      accepts,
      className,
      disabled,
      existFile,
      isRequiredAvatar,
    } = this.props;
    let { file } = this.state;
    if (!file) {
      file = this.props.file;
    }
    const CustomTemplate = this.props.customTemplate;

    if (CustomTemplate) {
      return (
        <CustomTemplate
          onSelectFile={this.handleFileChange}
          onRemoveFile={this.handleNoFileSelected}
          id={id}
          accept={accepts || ACCEPTED_FILES}
          name={name}
          disabled={disabled}
          className={className}
          note={note}
          ref={this.inputElement}
          {...this.state}
          {...this.props.customTemplateProps}
          isRequiredAvatar={isRequiredAvatar}
        />
      );
    }

    return (
      <div className="qup-upload-wrapper">
        <div
          className={`form-control upload-container ${className || ''}${
            file ? ' selected' : ''
          }${disabled ? ' disabled' : ''}`}
        >
          <span className={`text-inner${existFile ? ' exist-file' : ''}`}>
            {existFile
              ? existFile.fileName
              : file
              ? file.name
              : this.props.text || I18n.t('upload_document.NO_FILE_CHOSEN')}
          </span>
          {existFile ? (
            <div
              className="upload-icon remove"
              onClick={() => (disabled ? null : existFile.onRemove())}
              disabled={disabled}
            />
          ) : file && !this.props.noRemoveIcon ? (
            <div
              className="upload-icon remove"
              onClick={() => this.handleNoFileSelected(null, true)}
              disabled={disabled}
            />
          ) : this.props.noIcon ? null : (
            <div
              className="upload-icon upload"
              onClick={() => this.inputElement.click()}
              disabled={disabled}
            />
          )}
          <input
            type="file"
            name={name}
            id={id}
            accept={accepts || ACCEPTED_FILES}
            className="upload-input"
            onChange={this.props.handleFileChange || this.handleFileChange}
            ref={(input) => (this.inputElement = input)}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

UploadFile.contextTypes = {
  notification: PropTypes.func,
};

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  accepts: PropTypes.string,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  customTemplate: PropTypes.any,
  existFile: PropTypes.object, // for edit case
};

export default UploadFile;
