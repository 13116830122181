import { I18n } from 'react-redux-i18n';
import React from 'react';
import _ from 'lodash';
import Links from './links';
import PropTypes from 'prop-types';
import Logs from './Logs';
import MessageBtn from './MessageBtn';
import ChargeBtn from './ChargeBtn';
import { checkCorporateUser } from '../../../../utils/commonFunctions';
import { useMemo } from 'react';

const TopActionBtns = ({
  bookInfo,
  isSupplierPermission,
  is3rdBooking,
  hasPermission,
  sendSMSBooking: sendSMSBookingPropFunc,
  chargeInvoicing: chargeInvoicingPropFunc,
  auth,
  ...props
}) => {

  const isCorporateBoard = useMemo(() => checkCorporateUser(auth?.user), []);

  return (
    <div style={{ textAlign: 'right' }}>
      <Links 
        bookInfo={bookInfo} 
        selectedFleet={auth?.selectedFleet} 
        isCorporateBoard={isCorporateBoard}
      />
      <Logs
        bookInfo={bookInfo}
        selectedFleet={auth?.selectedFleet}
        isSupplierPermission={isSupplierPermission}
        is3rdBooking={is3rdBooking}
        hasPermission={hasPermission}
        isFoodMart={props.isFoodMart}
        setShowDeliveredPhotosModal={props.setShowDeliveredPhotosModal}
        setShowCollectedPhotosModal={props.setShowCollectedPhotosModal}
        isCorporateBoard={isCorporateBoard}
      />
      <MessageBtn
        hasPermission={hasPermission}
        sendSMSBooking={sendSMSBookingPropFunc}
        bookInfo={bookInfo}
        auth={auth}
      />
      {
        !props?.isFoodMart &&
        !isCorporateBoard &&
        <ChargeBtn
          bookInfo={bookInfo}
          selectedFleet={auth?.selectedFleet}
          isSupplierPermission={isSupplierPermission}
          is3rdBooking={is3rdBooking}
          hasPermission={hasPermission}
          chargeInvoicing={chargeInvoicingPropFunc}
        />
      }
    </div>
  );
};

TopActionBtns.defaultProps = {
  bookInfo: {},
  auth: {},
  delivery: false,
  isSupplierPermission: false,
  is3rdBooking: false,
  hasPermission: false,
  isFoodMart: false,
  sendSMSBooking: () => {},
  chargeInvoicing: () => {},
  setShowDeliveredPhotosModal: () => {},
  setShowCollectedPhotosModal: () => {},
};

TopActionBtns.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  sendSMSBooking: PropTypes.func.isRequired,
  chargeInvoicing: PropTypes.func.isRequired,
  setShowCollectedPhotosModal: PropTypes.func,
  setShowDeliveredPhotosModal: PropTypes.func,
  isSupplierPermission: PropTypes.bool,
  is3rdBooking: PropTypes.bool,
  isFoodMart: PropTypes.bool,
};

export default TopActionBtns;
