import React, { Component, Fragment } from 'react';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    Button,
    Modal
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Validator, ValidCase, WarningFeeInput } from "../../../../components/validator";
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class AddPromoCodeModal extends Component {
    constructor() {
        super();
        this.state = {
            sourceApply: []
        }
    }

    componentDidMount() {
        if (this.props.dialogData.autoApplyOnWeb.sourceApply.length > 0) {
            this.setState({
                sourceApply: this.props.dialogData.autoApplyOnWeb.sourceApply
            })
        }
    };

    render() {
        const {
            dialogData,
            editable,
            promosAvailable,
            promotionCodeList,
            selectedFleet,
            hasPermission,
            dialogChanged,
            sourceToSelect,
            sourceUsed,
            isSubmited,
            valid,
            validatorCallback,
            closeDialogForm,
            handleSelectPromoCode,
            handleSaveDialogForm,
            handleClickMenuPromosList,
            handleChangePrompCodeOption,
            handleChangeSourceApply
        } = this.props;
        let timeZone = selectedFleet.timezone;
        let promoCode = '';
        if (dialogData.promoId) {
            let promoData = promotionCodeList.filter(item => item._id === dialogData.promoId);
            promoCode = `${promoData[0].promotionCode} (${moment(promoData[0].validFrom)
                .tz(timeZone)
                .format('DD/MM/YYYY')} - ${moment(promoData[0].validTo)
                    .tz(timeZone)
                    .format('DD/MM/YYYY')})`;
        };

        let sourceKey = [];
        if (dialogData.autoApplyOnWeb.sourceApply.length > 0) {
            dialogData.autoApplyOnWeb.sourceApply.forEach(element => {
                sourceKey.push(element.key);
            });
        };

        let sourceAvailable = [];
        if (sourceToSelect) {
            sourceAvailable = sourceUsed.filter(item => {
                return this.state.sourceApply.find(({ sourceId }) => item.sourceId === sourceId)
            });
            sourceAvailable = sourceAvailable.concat(sourceToSelect.filter(item => {
                return !sourceUsed.find(({ sourceId }) => item.sourceId === sourceId)
            }));
        };

        return (
            <Modal onHide={closeDialogForm} show={true} backdrop={true} dialogClassName={"modal-max-600"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {dialogData.promoCode ? (
                            editable ? (
                                <Translate value="referral.EDIT_PROMO_CODE" />
                            ) : (
                                    <Translate value="referral.DETAIL_PROMO_CODE" />
                                )
                        ) : (
                                <Translate value="referral.CREATE_PROMO_CODE" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Form.Label>
                            <Translate value="referral.promoCode" />{" "}
                            <span className="require">*</span>
                        </Form.Label>
                        {dialogData.promoCode ? (
                            <FormControl
                                type="text"
                                className={"form-custom"}
                                value={
                                    dialogData ? promoCode : ""
                                }
                                disabled={true}
                            />
                        ) : (
                                <select
                                    className="form-control form-custom"
                                    value={dialogData.promoId}
                                    onChange={e => handleSelectPromoCode(e)}
                                    disabled={!hasPermission}
                                >
                                    <option value="">{I18n.t('referral.select_a_promotion')}</option>
                                    {promosAvailable.map(obj => (
                                        <option key={obj.promotionCode} value={obj._id}>
                                            {`${obj.promotionCode} (${moment(obj.validFrom)
                                                .tz(timeZone)
                                                .format('DD/MM/YYYY')} - ${moment(obj.validTo)
                                                    .tz(timeZone)
                                                    .format('DD/MM/YYYY')})`}
                                        </option>
                                    ))}
                                </select>
                            )}

                    </FormGroup>
                    <CcCheckbox
                        checked={dialogData.isSendInbox ? dialogData.isSendInbox : false}
                        onChange={e => handleChangePrompCodeOption('isSendInbox', e)}
                        disabled={!editable || dialogData.isDefault}
                        text={I18n.t(
                            'referral.Send_an_in_box_automatically_to_new_customers'
                        )}
                    />
                    <CcCheckbox
                        checked={dialogData.isDefault ? dialogData.isDefault : false}
                        onChange={e => handleChangePrompCodeOption('isDefault', e)}
                        disabled={!editable}
                        text={
                            <Fragment>
                                {I18n.t('referral.set_default_promo_code')}
                                <QuestionCircleTooltip text={<Translate value="referral.isDefaultCode_hint" />} />
                            </Fragment>
                        }
                    />
                    {sourceToSelect && (
                        <div>
                            <CcCheckbox
                                checked={dialogData.autoApplyOnWeb ? dialogData.autoApplyOnWeb.enable : false}
                                onChange={e => handleChangePrompCodeOption('autoApplyOnWeb', e)}
                                disabled={!editable}
                                text={I18n.t(
                                    'referral.autoApplyOnWeb'
                                )}
                            />
                            {dialogData.autoApplyOnWeb.enable ? (
                                <FormGroup
                                    controlId="formControlsSelectMultiple"
                                    className={isSubmited ? (valid.sourceKey ? null : "error") : null}
                                >
                                    <FormControl
                                        className="form-custom select-zone"
                                        as="select"
                                        multiple
                                        onChange={handleChangeSourceApply}
                                        value={sourceKey}
                                        disabled={!editable}
                                    >
                                        {sourceAvailable.map(z => {
                                            if (z.key) {
                                                return (
                                                    <option key={z.sourceId} value={z.key}>
                                                        {z.key}
                                                    </option>
                                                );
                                            }
                                        })}
                                    </FormControl>
                                    <Validator id="sourceKey" callback={validatorCallback}>
                                        <ValidCase
                                            hide={!isSubmited}
                                            valid={
                                                dialogData.autoApplyOnWeb.enable &&
                                                dialogData.autoApplyOnWeb.sourceApply.length > 0
                                            }
                                            message={I18n.t("referral.ERROR_INPUT_SOURCE")}
                                        />
                                    </Validator>
                                </FormGroup>
                            ) : null}
                        </div>
                    )}
                    <Form.Label>
                        (<span className="require">*</span>):{" "}
                        <Translate value="city.Required_fields" />
                    </Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    {hasPermission ? (
                        editable ? (
                            <Button
                                className={"btn-save mr-md"}
                                onClick={handleSaveDialogForm}
                                disabled={
                                    !dialogData.promoId.trim() ||
                                    !dialogChanged
                                }
                            >
                                <Translate value="referral.Save" />
                            </Button>
                        ) : (
                                <Button
                                    className={"btn-save mr-md"}
                                    onClick={e => {
                                        handleClickMenuPromosList("changeStatus", e);
                                    }}
                                >
                                    <Translate value="referral.Edit" />
                                </Button>
                            )
                    ) : null
                    }
                    <Button className={"btn-cancel"} onClick={closeDialogForm}>
                        <Translate value="city.Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AddPromoCodeModal