import React, { useRef, useState } from 'react';
import { useRootClose } from 'react-overlays';
import { PropTypes } from '../notificationAlert/container';

const OverlayCC = ({ 
	disabled: _disabled, 
	childrenOverlay: _childrenOverlay, 
	childrenAction: _childrenAction, 
	setShowOverlay: _setShowOverlay, 
	showOverlay: _showOverlay, 
	...props
}) => {
  const ref = useRef();
  const handleRootClose = () => _setShowOverlay(false);
  useRootClose(ref, handleRootClose, {
    disabled: !_showOverlay,
  });
  return (
    <div>
      <div
        onClick={() => {
          if (_disabled) return;
          _setShowOverlay(true);
        }}
      >
        {_childrenAction}
      </div>
      {_showOverlay && <div ref={ref}>{_childrenOverlay}</div>}
    </div>
  );
};

// OverlayCC.propTypes = {
//   childrenOverlay: PropTypes.node.isRequired,
//   childrenAction: PropTypes.node.isRequired,
//   setShowOverlay: PropTypes.func.isRequired,
//   showOverlay: PropTypes.bool.isRequired,
//   disabled: PropTypes.bool.isRequired,
// };

export default OverlayCC;
