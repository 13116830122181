import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  FormControl,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { BsSearch } from 'react-icons/bs';
import './styles/index.scss';
import CCDropDown from '../../components/dropdown/ccDropDown';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import tableColumnsDefault from './fields/fieldSupplier';
import { Link } from 'react-router';
import moment from 'moment-timezone';
import TableActions from '../../components/table/tableAction/TableActions';
import copy from 'copy-to-clipboard';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import Confirm from '../../components/confirm/Confirm';
import ExportComponent from '../../components/ExportComponent';

const CONFIRM_ACTIVATE_ID = 1;
const CONFIRM_DEACTIVATE_ID = 2;

const Supplier = (props, context) => {
  const toobarContainerRef = useRef();
  const parentRef = useRef();
  const toolbarExtendRef = useRef();
  const [canUpdate, setCanUpdate] = useState(true);
  const [isShowInbox, setIShowInbox] = useState(true);
  const [tableHeight, setTableHeight] = useState(0);

  const [footerData, setFooterData] = useState({
    limit: 20,
    total: 0,
    page: 0,
  });

  useEffect(() => {
    getTableHeight();
    getListSuppliers();
  }, []);

  const [textSearch, setTextSearch] = useState('');
  const [statusSearch, setStatusSearch] = useState(-1);
  const [zoneSearch, setZoneSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSupplier] = useState([]);
  const [filterQuery, setFilQuery] = useState({
    agentId: '',
    zoneId: '',
    activeStatus: '',
  });
  const [confirm, setConfirm] = useState(null);

  const [initState, setInitState] = useState({
    showPercent: false,
    exportPercent: false,
  });

  const handleFilterQueryChange = (key, val) => {
    const defaultFilterQuery = { ...filterQuery };
    defaultFilterQuery[key] = val;
    setFilQuery(defaultFilterQuery);
  };

  const getListSuppliers = (activePage, limit, isClear) => {
    let param = {
      limit: limit ? limit : footerData.limit || 20,
      page: _.isNumber(activePage) ? activePage : footerData.page || 0,
      fleetId: props.auth?.selectedFleet?.fleetId,
      isSupplier: true,
    };
    if (!isClear) {
      if (filterQuery.agentId) {
        param.agentId = filterQuery.agentId;
      }
      if (filterQuery.zoneId) {
        param.zoneId = filterQuery.zoneId;
      }
      if (filterQuery.activeStatus) {
        param.activeStatus = filterQuery.activeStatus;
      }
    }

    if (textSearch) {
      param.search = textSearch.trim();
      if (!activePage) {
        param.page = 0;
      }
    }
    setIsLoading(true);
    props.settingActions.getAllCompany(param).then((data) => {
      if (data.ok) {
        let { page, total, limit, list } = data.res || {};
        if (list) {
          setSupplier(list);
          setFooterData({
            ...footerData,
            limit,
            page,
            total,
          });
          setIsLoading(false);
        }
      } else {
        setSupplier([]);
        setFooterData({
          ...footerData,
        });
        setIsLoading(false);
      }
    }).catch((e) => {
      setSupplier([]);
      setFooterData({
        ...footerData,
      });
      setIsLoading(false);
    });
  };

  const handleNumItemsPerPageChange = (limit) => getListSuppliers(0, limit);
  const handlePaginationSelect = (page) => getListSuppliers(parseInt(page));

  const changeCompanyStatus = (companyIds, isActive) => {
    if (companyIds.length == 1) {
      props.settingActions
        .activeCompany({
          _id: companyIds[0],
          fleetId: props.auth.selectedFleet.fleetId,
          isActive: !isActive,
        })
        .then((data) => {
          if (data.ok) {
            window.location.reload();
            const listSup = _.cloneDeep(suppliers);
            _.forEach(listSup, (item) => {
              if (item._id === companyIds[0]) {
                item.isActive = !isActive;
                return false;
              }
            });
            setSupplier(listSup);
          } else {
            context.notification(
              'error',
              data?.error?.message || I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    } else {
      props.settingActions
        .multiActiveCompany({
          ids: companyIds,
          fleetId: props.auth.selectedFleet.fleetId,
          status: !isActive,
        })
        .then((data) => {
          if (data.ok) {
            window.location.reload();
            const listSup = _.cloneDeep(suppliers);
            _.forEach(listSup, (item) => {
              if (isActive) {
                if (data.res.list.includes(item._id)) {
                  item.isActive = !isActive;
                }
              } else if (companyIds.includes(item._id)) {
                item.isActive = !isActive;
              }
              item.checked = false;
            });
            setSupplier(listSup);
          } else {
            context.notification(
              'error',
              data?.error?.message || I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    }
  };

  const handleMenuClick = (key, company) => {
    switch (key) {
      case 'Edit': {
        props.router.push({
          pathname: `/supplier/detail/${company._id}/info`,
          state: {
            edit: true,
          },
        });
        break;
      }
      case 'View': {
        props.router.push({
          pathname: `/supplier/detail/${company._id}/info`,
          state: {
            edit: false,
          },
        });
        break;
      }
      case 'Activate': {
        changeCompanyStatus([company._id], company.isActive);
        break;
      }
      case 'Deactivate': {
        changeCompanyStatus([company._id], company.isActive);
        break;
      }
      case 'CopySignUp': {
        const { selectedFleet } = props.auth,
          linkSignUp = `${selectedFleet?.providerSignUpLink}?f=${selectedFleet?.fleetToken}&c=${company?._id}&isIframe=true&fpc=true`;
        copy(linkSignUp);
        context.notification(
          'success',
          'Sign up link has been coppied successfully!'
        );
        break;
      }
    }
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], tableColumnsDefault);

    const companyCommission = _.get(
      props.auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );
    if (!companyCommission) {
      _.remove(tableColums, (columns) => columns.key === 'connectStatus');
    }

    if (!props?.auth?.user?.roles?.isSupplier && props.auth?.user?.roles?.companyId) {
      _.remove(tableColums, (columns) => columns.key === 'agent');
    }

    _.forEach(tableColums, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (supplier) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <span>{supplier.name}</span>
                </Tooltip>
              }
            >
              <Link
                className="clickable"
                to={`/supplier/detail/${supplier._id}/info`}
              >
                {supplier.name}
              </Link>
            </OverlayTrigger>
          );
          break;
        case 'adminName':
          col.customCell = (supplier) => {
            return (
              <span>
                {supplier.adminInfo?.firstName || ''}{' '}
                {supplier.adminInfo?.lastName || ''}
              </span>
            );
          };
          break;

        case 'agent':
          col.customCell = (supplier) => {
            return <span>{supplier?.agentId?.name || ''}</span>;
          };
          break;
        case 'connectStatus':
          col.customCell = (company) => {
            return company.connectStatus === 'activated' ? (
              <span className="stripe_status activated">Active</span>
            ) : company.connectStatus === 'pending' ? (
              <span className="stripe_status pending">In Review</span>
            ) : (
              <span className="stripe_status empty">Inactive</span>
            );
          };
          break;

        case 'registration':
          col.customCell = (supplier) => {
            return (
              <span>{moment(supplier?.createdDate).format('MM/DD/YYYY')}</span>
            );
          };
          break;
        case 'status':
          col.customCell = (supplier) => {
            return supplier.isActive === true ? (
              <span className="stripe_status activated">Active</span>
            ) : (
              <span className="stripe_status empty">Inactive</span>
            );
          };
          break;
        case 'email':
          col.customCell = (supplier) => {
            return (
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip-email">
                    <span>{supplier?.adminInfo?.email || ''}</span>
                  </Tooltip>
                }
              >
                <spam>{supplier?.adminInfo?.email || ''}</spam>
              </OverlayTrigger>
            );
          };

          break;

        case 'zones':
          col.customCell = (supplier) => {
            const zoneStr = supplier?.operationZone
              ?.map((item) => item.zoneName)
              .join(', ');
            return (
              <OverlayTrigger
                placement="top"
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip-zone">
                    <span>{zoneStr}</span>
                  </Tooltip>
                }
              >
                <spam>
                  {supplier?.operationZone?.[0]?.zoneName || ''}
                  {supplier?.operationZone?.length > 1 ? '...' : ''}
                </spam>
              </OverlayTrigger>
            );
          };

          break;
        case 'actions':
          col.customCell = (company, rowIndex) => {
            if (!props.permissions || props.permissions.actions) {
              let actionItems = [
                {
                  label: 'companySetting.View',
                  eventKey: 'View',
                },
                {
                  label: 'companySetting.Edit',
                  eventKey: 'Edit',
                },
              ];
              if (!props?.auth?.user?.roles?.isSupplier) {
                if (company.isActive) {
                  actionItems.push({
                    label: 'companySetting.Deactivate',
                    eventKey: 'Deactivate',
                  });
                } else {
                  actionItems.push({
                    label: 'companySetting.Activate',
                    eventKey: 'Activate',
                  });
                }
              } else {
                actionItems = actionItems.filter(item => item.eventKey !== 'Edit')
              }

              if (
                props.auth?.selectedFleet?.providerSetting?.otherSetting
                  ?.showSupplierSelection
              ) {
                actionItems.push({
                  label: 'companySetting.CopySignUpLink',
                  eventKey: 'CopySignUp',
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={company}
                  onSelect={(eventKey) => {
                    handleMenuClick(eventKey, company);
                  }}
                  totalRow={suppliers.length}
                  rowHeight={50}
                  menuItems={actionItems}
                  disabled={actionItems && actionItems.length === 0}
                  tableHeight={500}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    handleMenuClick('View', company);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="companySetting.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    const checkBoxCol = renderSelectBoxColumn();
    tableColums.unshift(checkBoxCol);

    return tableColums;
  };

  const handleApplyFilter = () => {
    getListSuppliers(0, null);
  };

  const handleClearFilter = () => {
    setFilQuery({
      agentId: '',
      zoneId: '',
      activeStatus: '',
    });
    getListSuppliers(0, null, true);
  };

  const renderSelectBoxColumn = () => {
    let isCheckedAll = false;
    if (suppliers?.length > 0) {
      isCheckedAll =
        _.filter(suppliers, (item) => item.checked).length ===
        suppliers?.length;
    }

    return {
      key: 'table-selectbox',
      label: '',
      width: 60,
      fixed: true,
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      customHeader: (columnKey) => (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={isCheckedAll}
          onChange={handleSelectAllClick}
        />
      ),
      customCell: (sup) => (
        <CcCheckbox
          className="table-selectbox"
          checked={sup.checked}
          //Do not allow operator check/uncheck to action the Apple Review Account
          onChange={(e) => {
            handleSelectRowClick(e.target.checked, sup._id);
          }}
        />
      ),
    };
  };

  const handleSearchChange = (val) => {
    setTextSearch(val);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      getListSuppliers(0);
    }
  };

  const handleSelectAllClick = (e) => {
    const { target } = e;
    const listSup = _.cloneDeep(suppliers);
    _.forEach(listSup, (sup) => {
      sup.checked = target.checked;
    });
    setSupplier(listSup);
  };

  const handleSelectRowClick = (checked, supId) => {
    const listSup = _.cloneDeep(suppliers);
    _.forEach(listSup, (sup) => {
      if (supId === sup._id) {
        sup.checked = checked;
        return false;
      }
    });
    setSupplier(listSup);
  };

  const activeMultipleSupplier = (status) => {
    if (suppliers.filter((user) => user.checked).length === 0) {
      setConfirm({
        title: !status
          ? I18n.t('supplier.Deactivate')
          : I18n.t('supplier.Activate'),
        body: !status
          ? I18n.t('supplier.ALERT_SELECT_DEACTIVATE')
          : I18n.t('supplier.ALERT_SELECT_ACTIVATE'),
      });
    } else {
      setConfirm({
        id: !status ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
        title: !status
          ? I18n.t('supplier.Deactivate')
          : I18n.t('supplier.Activate'),
        body: !status
          ? I18n.t('supplier.CONFIRM_DEACTIVATE_MULTI')
          : I18n.t('supplier.CONFIRM_ACTIVATE_MULTI'),
        buttonTitle: 'Yes',
        closeButtonText: 'No',
      });
    }
  };

  const prepareParamExport = () => {
    const query = { fleetId: props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (textSearch) {
      query.txtSearch = textSearch;
    }
    if (filterQuery.agentId) {
      query.agentId = filterQuery.agentId;
    }
    if (filterQuery.zoneId) {
      query.zoneId = filterQuery.zoneId;
    }
    if (filterQuery.activeStatus) {
      query.status = filterQuery.activeStatus;
    }
    const sort = { createdDate: -1 };
    query.timezone = props.auth.selectedFleet.timezone;

    return {
      query,
      sort,
      total: _.get(footerData, 'total', 0),
    };
  };

  const handleConfirmButtonClick = (id) => {
    let ids = [];
    if (suppliers) {
      ids = suppliers.filter((obj) => obj.checked).map((obj) => obj._id);
    }
    switch (id) {
      case CONFIRM_ACTIVATE_ID: {
        handleUpdateMultipleStatusClick(ids, true);
        break;
      }
      case CONFIRM_DEACTIVATE_ID: {
        handleUpdateMultipleStatusClick(ids, false);
        break;
      }
    }
    handleConfirmCloseClick();
  };

  const handleConfirmCloseClick = () => {
    setConfirm(null);
  };

  const handleUpdateMultipleStatusClick = (ids, status) => {
    const { auth } = props;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      fleetId,
      ids,
      isActive: status,
    };
    props.settingActions.multiActiveCompany(body).then((response) => {
      if (response.ok && !response.error) {
        context.notification(
          'success',
          I18n.t('supplier.Update_supplier_user_status_success')
        );
        const { page, limit } = footerData;
        window.location.reload();
        // getListSuppliers(page, limit);
      } else {
        if (response.error) {
          context.notification(
            'error',
            response.error?.message || I18n.t('errors.' + response.error.errorCode)
          );
        } else {
          context.notification(
            'error',
            I18n.t('supplier.Update_supplier_user_status_fail')
          );
        }
      }
    });
  };

  const getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarheight = toobarContainerRef?.current?.offsetHeight || 0;
    const parentHeight = parentRef?.current?.offsetHeight || 0;
    const toolbarExtendHeight = toolbarExtendRef?.current?.offsetHeight || 0;
    const outerHeight = verticalPadding + toolbarheight + toolbarExtendHeight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    return tableHeight;
  };

  const companies = _.cloneDeep([
    ...props.commonData.companies,
    ...props.commonData.suppliers,
  ]);
  const getZoneBasedCompany = () => {
    const companyId = props.auth?.user?.roles?.companyId || '',
      fleetZones = props.commonData.mapZone,
      fleetCompany = [
        ...props.commonData.companies,
        ...props.commonData.suppliers,
      ];

    if (companyId) {
      const cpInfo = fleetCompany.find((cp) => cp._id === companyId);
      if (cpInfo?.operationZone?.length > 0) {
        return fleetZones.filter((zone) =>
          cpInfo?.operationZone.includes(zone._id)
        );
      }
    }
    const newZones = _.filter(fleetZones, (z) => z.isActive && z.display);
    return newZones;
  };
  let zoneItems = getZoneBasedCompany() || [];
  const { permissions = null, auth: { user } = {} } = props || {};
  const { actions = false, export: exportPermit = false } = permissions || {};
  const exportPermission = user.isAdmin ? true : exportPermit;

  return (
    <div className="content overflow-auto" ref={parentRef}>
      <div className="mb">
        {!props?.auth?.user?.roles?.isSupplier && (
          <ButtonToolbar
            className="text-center header-button-group mb0"
            ref={toobarContainerRef}
          >
            <div className="group-left btn-group">
              <FormGroup className="search-format">
                <FormControl
                  className="search-form"
                  type="text"
                  value={textSearch || ''}
                  placeholder={I18n.t('General.search')}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  onKeyPress={handleSearchKeyPress}
                />
                <BsSearch className="search-icon" />
              </FormGroup>
              {canUpdate && (
                <React.Fragment>
                  {!props.auth?.user?.roles?.isSupplier && (
                    <Button
                      className="btn-header text-add-header"
                      onClick={() => {
                        props.router.push('/supplier/add');
                      }}
                    >
                      <Translate value="mDispatcher.Add" />
                    </Button>
                  )}
                  {/* <Button
                  className="btn-header text-delete-header"
                  disabled={false}
                  // onClick={openConfirmDeleteDialog}
                >
                  <Translate value="promotionSettings.Delete" />
                </Button> */}
                  <Button
                    className="btn-header text-active-header"
                    onClick={() => {
                      activeMultipleSupplier(true);
                    }}
                  >
                    <Translate value="mDispatcher.Activate" />
                  </Button>
                  <Button
                    className="btn-header text-deactive-header"
                    onClick={() => {
                      activeMultipleSupplier(false);
                    }}
                  >
                    <Translate value="mDispatcher.Deactivate" />
                  </Button>
                </React.Fragment>
              )}
            </div>
            <ButtonGroup className="group-right">
              {permissions && exportPermission && (
                <ExportComponent
                  prepareParamExport={prepareParamExport}
                  urlExport={props.settingActions.exportCompanyToExcel}
                  user={user}
                />
              )}
              {/* {isShowInbox ? (
              <Button
                className="btn-header text-add-header"
                // onClick={handleSendMessageClick}
              >
                <Translate value="driver.Send_Message" />
              </Button>
            ) : null} */}
            </ButtonGroup>
          </ButtonToolbar>
        )}

        {!props?.auth?.user?.roles?.isSupplier && (
          <div
            className="supplier-toolbar-extend driver-toolbar-filter"
            ref={toolbarExtendRef}
          >
            <div className="toolbarExtend--leftContent">
              <CCDropDown
                id="agent-dropdown"
                items={companies}
                title={I18n.t('supplier.agent')}
                selectedItems={filterQuery.agentId}
                valueKey="_id"
                labelKey="name"
                onSelect={(e) => handleFilterQueryChange('agentId', e)}
                noTranslateLabel
                searchable
                enableClearAll
              />
              <CCDropDown
                id="zone-dropdown"
                items={zoneItems}
                title={I18n.t('supplier.zone')}
                valueKey="_id"
                labelKey="zoneName"
                selectedItems={filterQuery.zoneId}
                onSelect={(e) => handleFilterQueryChange('zoneId', e)}
                searchable
                enableClearAll
              />
              <CCDropDown
                id="status-dropdown"
                items={[
                  {
                    label: 'All',
                    value: '',
                  },
                  {
                    label: 'Active',
                    value: 'Active',
                  },
                  {
                    label: 'Inactive',
                    value: 'Inactive',
                  },
                ]}
                title={I18n.t('cue.Status')}
                selectedItems={filterQuery.activeStatus}
                labelKey="label"
                valueKey="value"
                onSelect={(e) => handleFilterQueryChange('activeStatus', e)}
              />
              <Button
                className="btn-header text-add-header"
                onClick={handleApplyFilter}
              >
                <Translate value="customer.Apply" />
              </Button>
              <Button
                className="btn-header text-deactive-header"
                onClick={handleClearFilter}
              >
                <Translate value="customer.Clear" />
              </Button>
            </div>
          </div>
        )}

        <div className="gridViewTable">
          <StickyTable
            columns={getTableColumns()}
            bodyData={suppliers}
            footerData={footerData}
            rowHeight={50}
            getTableHeight={getTableHeight}
            handleNumItemsPerPageChange={handleNumItemsPerPageChange}
            handlePaginationSelect={handlePaginationSelect}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Confirm
        confirm={confirm}
        handleConfirmButtonClick={handleConfirmButtonClick}
        handleConfirmCloseClick={handleConfirmCloseClick}
      />
    </div>
  );
};

Supplier.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);
