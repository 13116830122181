import React from 'react';
import _ from 'lodash';
import {
  Modal, FormGroup, FormControl, Button
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportNotificationActions from '../../../actions/reportNotificationActions';
import * as bookingDetailActions from "../../../actions/bookingDetailAction";
import * as notificationActions from "../../../actions/notificationActions";
import * as loadingActions from "../../../actions/loadingBarActions";
import BaseNotifications from '../base/BaseNotifications';
import { sosHistory } from "../../../constants/ApiConfigs"
import * as commonData from '../../../constants/commondata';
import PropTypes from "prop-types";
import Confirm from "../../../components/confirm/Confirm";
var moment = require("moment");
class SOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sos: null,
      isShowComfirm: false,
    }
  }

  deleteNotiItem = (data, index) => {
    this.props.notificationActions
      .sosDelete({
        fleetId: this.props.selectedFleet.fleetId,
        id: data.id,
        index: index
      })
  }


  itemclick = (data, index) => {
    console.log(data);
    this.setState({ sos: data });
    this.props.notificationActions.sosMarkItemAsRead({
      fleetId: this.props.selectedFleet.fleetId,
      id: data.id,
      index: index
    })
  }

  handleConfirmCloseClick = () => {
    this.setState({ isShowComfirm: false, sos: null });
  }

  handleBookingDetailView = (bookId) => {
    if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
      return this.props.router.push({pathname:`/supplier-booking-detail/${bookId}`, state: {selectedTab: 0}})
    }
    this.props.bookingDetailActions.getBookingDetail(bookId * 1).then(data => {
      if (!data.res || data.error) {
        this.props.bookingDetailActions
          .getBookingDetail(bookId * 1, true)
          .then(data => {
            if (!data.res || data.error) {
              this.notification(
                "error",
                I18n.t("messages.booking.Booking_detail_not_found")
              );
            }
          });
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <BaseNotifications
          apiurl={sosHistory}
          itemclick={this.itemclick}
          deleteNotiItem={this.deleteNotiItem}
          placeholderSearch={I18n.t("notifications.sos.sosSearch")}
          notificationTypes={commonData.sosStatus}
          isSOS
        />
        {this.state.isShowComfirm ? (
          <Confirm
            confirm={{
              title: "Warning!",
              body: I18n.t(
                "messages.commonMessages.callcenter_close_newbooking_form_warning"
              )
            }}
            handleConfirmCloseClick={this.handleConfirmCloseClick}
          />
        ) : null}
        {
          this.state.sos && (
            <Confirm
              confirm={{
                title: (
                  <span className="red">
                    <i className="fa fa-warning" />
                    <Translate
                      className="text-tranform-none"
                      value="messages.commonMessages.Emergency_SOS_Title"
                    />
                  </span>
                ),
                body: (
                  <div>
                    <p>
                      <span className="bold">
                        {I18n.t("General.App_SOS_dialog_form_message_1").format(
                          this.state.sos.fromApp === "crew" ? "Crew" :
                            this.state.sos.fromApp === "passenger"
                              ? "Pax"
                              : "Driver"
                        )}
                      </span>
                    </p>
                    <p>
                      <span>
                        {I18n.t("General.App_SOS_dialog_form_message_2")}
                      </span>
                      {' '}
                      {this.state.sos.geo ? (<a
                        target={"_blank"}
                        href={
                          "https://maps.google.com/maps?q=" +
                          this.state.sos.geo[1] +
                          "," +
                          this.state.sos.geo[0]
                        }
                      >
                        https://maps.google.com/maps?q=
                        {this.state.sos.geo[1]},{this.state.sos.geo[0]}
                      </a>) : null}
                    </p>
                    <p
                      className="clickable blue"
                      onClick={() => {
                        this.handleBookingDetailView(this.state.sos.bookId);
                      }}
                    >
                      {I18n.t("General.App_SOS_dialog_form_message_3").format(
                        this.state.sos.bookId,
                        this.state.sos.driverName || "",
                        this.state.sos.passengerName || "",
                        this.state.sos.licensePlate || "",
                        moment.tz(this.state.sos.createdDate || this.state.sos.date, this.props.selectedFleet.timezone).format("DD/MM/YYYY HH:mm")
                      )}
                    </p>
                  </div>
                )
              }}
              handleConfirmCloseClick={this.handleConfirmCloseClick}
            />
          )}
      </React.Fragment>

    );
  }
}

SOS.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportNotificationActions: bindActionCreators(reportNotificationActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SOS);
