import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { I18n, Translate } from 'react-redux-i18n';
import FormDataTrailNotes from './formDataTrailNotes';
import Drawer from '../../../drawer';

const TrailNotesNewBooking = (props) => {
  const {
    notification,
    listTrailNotes,
    handleAddTrailNotes,
    handleDeleteTrailNotes,
    uploadService,
    fleetId,
  } = props;
  const [showDrawerTrailNotes, setShowDrawerTrailNotes] = useState(false);
  const [formData, setFormData] = useState({
    operator: true,
    driver: false,
    passenger: false,
    text: '',
    files: [],
  });

  const handleResetForm = () => {
    setShowDrawerTrailNotes(false);
  };

  const handleChangeValue = (key, value) => {
    const newFormData = { ...formData };
    newFormData[key] = value;
    setFormData(newFormData);
  };

  const handleChangeFile = (e) => {
    const files = [...e.target.files];
    let error = false;
    let errorType = false;
    const newFormData = { ...formData };
    if (newFormData?.files?.length + files.length > 3) {
      return notification('error', I18n.t('trailNotes.maxLengthFiles'));
    }
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/webp',
      'text/plain',
    ];
    const maxSize = 10 * 1024 * 1024;
    files.forEach((file) => {
      if (file.size > maxSize) {
        error = true;
      }
      if (!allowedTypes.includes(file.type)) {
        errorType = true;
      }
    });
    if (error) return notification('error', I18n.t('trailNotes.maxSizeFiles'));
    if (errorType) return notification('error', I18n.t('trailNotes.typeFiles'));

    newFormData['files'] = [...newFormData.files, ...files];
    setFormData(newFormData);
  };

  const handleDeleteFile = (index) => {
    const newFormData = { ...formData };
    newFormData.files.splice(index, 1);
    setFormData(newFormData);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    let error = false;
    let errorType = false;
    if (files.length > 0) {
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp',
        'text/plain',
      ];

      const newFormData = { ...formData };
      if (newFormData?.files?.length + files.length > 3) {
        return notification('error', I18n.t('trailNotes.maxLengthFiles'));
      }
      const maxSize = 10 * 1024 * 1024;
      Array.from(files).forEach((file) => {
        if (file.size > maxSize) {
          error = true;
        }
        if (!allowedTypes.includes(file.type)) {
          errorType = true;
        }
      });
      if (error)
        return notification('error', I18n.t('trailNotes.maxSizeFiles'));

      if (errorType) return notification('error', I18n.t('trailNotes.typeFiles'));
      newFormData['files'] = [...newFormData.files, ...files];
      setFormData(newFormData);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const addTrailNote = async () => {
    let resultUpload = [];
    if (formData.text?.trim() === '' && formData.files.length === 0) {
      return;
    }
    if (formData.files.length > 0) {
      const imageTypes = [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp',
      ]
      resultUpload = await Promise.all(
        formData.files.map((file) => {
          const form = new FormData();
          form.append('photo', file);
          form.append('fleetId', fleetId);
          form.append('module', 'quest');
          if (imageTypes.includes(file.type)) {
            form.append('contentDisposition', 'inline');
          }
          return uploadService.uploadDocument(form);
        })
      );
      resultUpload = resultUpload.map((item, index) => {
        return {
          name: formData.files[index]?.name,
          url: item?.res?.Location,
        };
      });
    }

    if (resultUpload.some((item) => !item?.url)) {
      return notification('error', I18n.t('messages.booking.data_incorect'));
    }
    const defaultFormData = { ...formData };
    if (defaultFormData.text === '') {
      delete defaultFormData.text;
    }
    handleAddTrailNotes({ ...defaultFormData, files: resultUpload });
    setFormData({
      operator: true,
      driver: false,
      passenger: false,
      text: '',
      files: [],
    });
  };

  const deleteTrailNote = (id, index) => {
    handleDeleteTrailNotes(index);
  };

  return (
    <div className={styles['layout']}>
      <Drawer
        isOpen={showDrawerTrailNotes}
        onClose={handleResetForm}
        title={<Translate value={'trailNotes.bookingActivity'} />}
        footer={null}
        classNameBody={styles['drawer-trailnotes']}
        styleDrawer={{
          marginTop: '60px',
          height: 'calc(100% - 60px)',
          width: '500px',
        }}
      >
        <FormDataTrailNotes
          formData={formData}
          handleChangeValue={handleChangeValue}
          handleChangeFile={handleChangeFile}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleDeleteFile={handleDeleteFile}
          handleCreateTrailNotes={addTrailNote}
          listTrailNotes={listTrailNotes}
          handleDeleteTrailNotes={deleteTrailNote}
        />
      </Drawer>
      <div className={styles['header']}>
        <span className={styles['text-green']}>
          {I18n.t('trailNotes.bookingActivity')}
        </span>
        <span
          className={styles['view-all']}
          onClick={() => setShowDrawerTrailNotes(true)}
        >
          {I18n.t('trailNotes.viewAll')}
        </span>
      </div>
      <FormDataTrailNotes
        formData={formData}
        handleChangeValue={handleChangeValue}
        handleChangeFile={handleChangeFile}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleDeleteFile={handleDeleteFile}
        handleCreateTrailNotes={addTrailNote}
        listTrailNotes={listTrailNotes}
        handleDeleteTrailNotes={deleteTrailNote}
      />
    </div>
  );
};

export default TrailNotesNewBooking;
