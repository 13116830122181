/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportDeleteAccountRequestApi } from '../../../constants/ApiConfigs';
import { associatedApps } from '../../../constants/commondata';
import * as settingActions from '../../../actions/settingActions';

class ReportDeleteAccountRequest extends React.Component {
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Deletion request',
      Export: true,
    });
    const checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner ? true : checkExport !== -1;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    return (
      <div className="content">
        <BaseReport
          tittle="Deletion request"
          fieldMappings={fieldMappings}
          apiurl={reportDeleteAccountRequestApi}
          dateRange
          multiSelectionsFilters={[
            {
              options: associatedApps,
              title: 'report.query.associatedApps',
              key: 'associatedApps',
            },
          ]}
          noexport={true}
          search="report.query.search.deleteAccountRequest"
        />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDeleteAccountRequest);
