export const Columns = [
  {
    key: "name",
    label: "deliverySetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    width: 300,
    textEllipsis: true
  },
  {
    key: "latestUpdate",
    label: "deliverySetting.Last_Update"
  },
  {
    key: "deliveryStatus",
    label: "deliverySetting.Status"
  },
  {
    key: "actions",
    label: "deliverySetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
