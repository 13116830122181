import {
  sharingRateFetchApi,
  sharingRateFindApi,
  sharingRateFindOneApi,
  sharingRateUpdatedApi,
  sharingRateCreateApi,
  sharingRateDeleteApi,
  sharingRateActiveApi,
  sharingRateDeactiveApi
} from '../constants/ApiConfigs';

import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

//Rate Delivery settings
export function fetchSharingRate(options = {}) {
  const config = {
    method: "GET"
  };
  return callApi(
    UrlBuilder(sharingRateFetchApi, options),
    config,
    null,
    null,
    null,
    true
  );
};

export function createSharingRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateCreateApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function updateSharingRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateUpdatedApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function activeSharingRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateActiveApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function deactiveSharingRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateDeactiveApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function deleteSharingRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateDeleteApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function getAllSharingRates(options) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateFindApi,
    config,
    null,
    null,
    null,
    true
  );
};

export function getDetailSharingRate(options) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    sharingRateFindOneApi,
    config,
    null,
    null,
    null,
    true
  );
}
