import React, { Component, ReactPortal } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
} from 'react-bootstrap';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';
import { MdAutoFixHigh } from 'react-icons/md';
import SuggestContent from '../../../components/suggestContent/SuggestContent';

const AUTOFILL_ITEMS = [
  { value: '[FLEET_NAME]', description: 'Fleet name' },
  { value: '[FLEET_PHONE]', description: 'Fleet phone' },
  { value: '[FLEET_EMAIL]', description: 'Fleet email' },
  { value: '[BOOKING_ID]', description: 'Booking ID' },
  { value: '[PASSENGER_FIRST_NAME]', description: 'Passenger first name' },
  { value: '[PASSENGER_LAST_NAME]', description: 'Passenger last name' },
  { value: '[PASSENGER_PHONE]', description: 'Passenger phone' },
  { value: '[DRIVER_FIRST_NAME]', description: 'Driver first name' },
  { value: '[DRIVER_LAST_NAME]', description: 'Driver last name' },
  { value: '[DRIVER_PHONE]', description: 'Driver phone' },
  { value: '[PICKUP_TIME]', description: 'PickUp time' },
  { value: '[TRACK_LINK]', description: 'Track link' },
];

const TRIGGER_CHARACTER = '[';
const SUGGESTION_WIDTH = 310;

class TemplateModal extends Component {
  constructor() {
    super();
    this.state = {
      dialogData: {
        name: '',
        content: '',
        transportation: true,
        assistant: false,
      },
      valid: {},
      isSubmitted: false,
      editable: false,
    };

    // properties
    this.handleClickEditButton = this.handleClickEditButton.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.validatorCallback = this.validatorCallback.bind(this);
  }

  componentDidMount() {
    if (this.props.data && this.props.data._id) {
      this.setState({ dialogData: { ...this.props.data }, editable: this.props.editable });
    } else {
      this.setState({ editable: this.props.editable });
    }
  }

  async saveDialogForm() {
    if (this.state.isSubmitted) {
      return;
    }

    this.setState({ isSubmitted: true });

    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }

    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;

    if (!this.state.dialogData.name.trim()) {
      return;
    }

    if (!this.state.dialogData.content.trim()) {
      return;
    }

    this.props.loadingBarActions.showLoadingSpiner();

    if (this.state.dialogData._id) {
      const objectBookingSMSTemplate = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        content: this.state.dialogData.content,
        id: this.state.dialogData._id,
      };

      this.props.settingActions
        .updateBookingSMSTemplate(objectBookingSMSTemplate)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('voiceSMSSetting.bookingSMSTemplate.Update_fail')
              );
            }
            this.setState({ isSubmitted: false });
          } else {
            this.context.notification(
              'success',
              I18n.t('voiceSMSSetting.bookingSMSTemplate.Update_success')
            );
            this.props.onSuccess();
          }
        });
    } else {
      this.props.settingActions
        .createBookingSMSTemplate(this.state.dialogData)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (!data.ok) {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('voiceSMSSetting.bookingSMSTemplate.Create_fail')
              );
            }

            this.setState({ isSubmitted: false });
          } else {
            this.context.notification(
              'success',
              I18n.t('voiceSMSSetting.bookingSMSTemplate.Create_success')
            );
            this.props.onSuccess();
          }
        });
    }
  }

  handleNameChange(e) {
    this.setState({
      dialogData: { ...this.state.dialogData, name: e.target.value },
    });
  }

  handleClickEditButton() {
    this.setState({ editable: true });
  }

  validatorCallback(id, valid) {
    if (this.state.valid[id] !== valid) {
      const newState = { ...this.state.valid, [id]: valid };
      this.setState({ valid: newState });
    }
  }

  handleSetStateContentChange = (value) => {
    this.setState({dialogData: {...this.state.dialogData, content: value}});
  }

  render() {
    return (
      <>
        <Modal onHide={this.props.onCancel} show={true} backdrop={true} dialogClassName={'modal-max-600'} onHide={this.props.onCancel}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.dialogData && this.state.dialogData._id ? (
                this.state.editable ? (
                  <Translate value="voiceSMSSetting.bookingSMSTemplate.EDIT" />
                ) : (
                  <Translate value="voiceSMSSetting.bookingSMSTemplate.DETAIL" />
                )
              ) : (
                <Translate value="voiceSMSSetting.bookingSMSTemplate.ADD" />
              )}
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.props.onCancel}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                !this.state.isSubmitted
                  ? null
                  : this.state.valid.name
                  ? null
                  : 'error'
              }
            >
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSTemplate.name" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={this.handleNameChange}
                value={this.state.dialogData && this.state.dialogData.name}
                placeholder={I18n.t('voiceSMSSetting.bookingSMSTemplate.name')}
                disabled={!this.state.editable}
                maxLength={100}
              />
              <Validator id="name" callback={this.validatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmitted}
                  valid={!Validation.isStringEmpty(this.state.dialogData && this.state.dialogData.name)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <SuggestContent
                //state
                isSubmitted={this.state.isSubmitted}
                editable={this.state.editable}
                value={(this.state.dialogData && this.state.dialogData.content) || ''}
                //function
                validatorCallback={this.validatorCallback}
                handleSetStateContentChange={this.handleSetStateContentChange}
                //another
                label={'voiceSMSSetting.bookingSMSTemplate.content'}
                placeholder={'voiceSMSSetting.bookingSMSTemplate.content'}
                TRIGGER_CHARACTER={TRIGGER_CHARACTER}
                AUTOFILL_ITEMS={AUTOFILL_ITEMS}
                SUGGESTION_WIDTH={SUGGESTION_WIDTH}
                require={true}

            />
          </Modal.Body>
          <Modal.Footer>
            {!this.props.permissions || this.props.permissions.actions ? (
              this.state.editable ? (
                <Button
                  className={'btn-save mr-md'}
                  onClick={this.saveDialogForm}
                  disabled={!CCLiteCommonFunc.isFormValid(this.state.valid)}
                >
                  <Translate value="city.Save" />
                </Button>
              ) : (
                <Button
                  className={'btn-save mr-md'}
                  onClick={this.handleClickEditButton}
                >
                  <Translate value="city.Edit" />
                </Button>
              )
            ) : null}
            <Button className={'btn-cancel'} onClick={this.props.onCancel}>
              <Translate value="city.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

TemplateModal.contextTypes = {
  notification: PropTypes.func,
};

TemplateModal.propTypes = {
  settingActions: PropTypes.object.isRequired,
  loadingBarActions: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  data: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateModal);
