import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.scss';
import { I18n, Translate } from 'react-redux-i18n';
import FormDataTrailNotes from './formDataTrailNotes';
import Drawer from '../../../drawer';

const TrailNotes = (props) => {
  const {
    notification,
    settingActions,
    bookId,
    fleetId,
    uploadService,
    setShowTrailNote,
    auth
  } = props;
  const [showDrawerTrailNotes, setShowDrawerTrailNotes] = useState(false);
  const [formData, setFormData] = useState({
    operator: true,
    driver: false,
    passenger: false,
    text: '',
    files: [],
  });
  const [listTrailNotes, setListTrailNotes] = useState([]);

  const handleResetForm = () => {
    setShowTrailNote(false)
    setShowDrawerTrailNotes(false);
  };

  const handleChangeValue = (key, value) => {
    const newFormData = { ...formData };
    newFormData[key] = value;
    setFormData(newFormData);
  };

  const handleChangeFile = (e) => {
    const files = [...e.target.files];
    let error = false;
    let errorType = false;
    const newFormData = { ...formData };
    if (newFormData?.files?.length + files.length > 3) {
      return notification('error', I18n.t('trailNotes.maxLengthFiles'));
    }
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/webp',
      'text/plain',
    ];
    const maxSize = 10 * 1024 * 1024;
    files.forEach((file) => {
      if (file.size > maxSize) {
        error = true;
      }
      if (!allowedTypes.includes(file.type)) {
        errorType = true;
      }
    });
    if (error) return notification('error', I18n.t('trailNotes.maxSizeFiles'));
    if (errorType) return notification('error', I18n.t('trailNotes.typeFiles'));

    newFormData['files'] = [...newFormData.files, ...files];
    setFormData(newFormData);
  };

  const handleDeleteFile = (index) => {
    const newFormData = { ...formData };
    newFormData.files.splice(index, 1);
    setFormData(newFormData);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    let error = false;
    let errorType = false;
    if (files.length > 0) {
      const allowedTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/csv',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/webp',
        'text/plain',
      ];
      const newFormData = { ...formData };
      if (newFormData?.files?.length + files.length > 3) {
        return notification('error', I18n.t('trailNotes.maxLengthFiles'));
      }
      const maxSize = 10 * 1024 * 1024;
      Array.from(files).forEach((file) => {
        if (file.size > maxSize) {
          error = true;
        }
        if (!allowedTypes.includes(file.type)) {
          errorType = true;
        }
      });
      if (error)
        return notification('error', I18n.t('trailNotes.maxSizeFiles'));
      if (errorType) return notification('error', I18n.t('trailNotes.typeFiles'));

      newFormData['files'] = [...newFormData.files, ...files];
      setFormData(newFormData);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCreateTrailNotes = async () => {
    try {
      let resultUpload = [];
      if (formData.text?.trim() === '' && formData.files.length === 0) {
        return ;
      }
      if (formData.files.length > 0) {
        const imageTypes = [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/webp',
        ]
        resultUpload = await Promise.all(
          formData.files.map((file) => {
            const form = new FormData();
            form.append('photo', file);
            form.append('fleetId', fleetId);
            form.append('module', 'quest');
            if (imageTypes.includes(file.type)) {
              form.append('contentDisposition', 'inline');
            }
            return uploadService.uploadDocument(form);
          })
        );
        resultUpload = resultUpload.map((item, index) => {
          return {
            name: formData.files[index]?.name,
            url: item?.res?.Location,
          };
        });
      }

      if (resultUpload.some((item) => !item?.url)) {
        return notification(
          'error',
          I18n.t('messages.booking.data_incorect')
        );
      }

      const body = {
        bookId,
        fleetId,
        notes: [
          {
            availability: {
              operator: formData.operator,
              driver: formData.driver,
              passenger: formData.passenger,
            },
            text: formData.text,
            files: resultUpload,
          },
        ],
      };

      if (body.notes[0].text === '') {
        delete body.notes[0].text;
      }

      const result = await settingActions.createTrailNotes(body);

      if (result?.errorCode === 'TRAIL_NOTE_LIMIT') return notification('error', I18n.t('trailNotes.maxComments'))
      if (result[0]?._id) {
        setFormData({
          operator: true,
          driver: false,
          passenger: false,
          text: '',
          files: [],
        });
        await handleGetListTrailNotes();
        return notification(
          'success',
          I18n.t('trailNotes.successCreateTrailNotes')
        );
      } else {
        notification('error', I18n.t('messages.booking.data_incorect'));
      }
    } catch (error) {
      notification('error', I18n.t('messages.booking.data_incorect'));
    }
  };

  const handleDeleteTrailNotes = async (noteId) => {
    try {
      const result = await settingActions.deleteTrailNotes({
        bookId,
        fleetId,
        noteId,
      });

      if (result.success) {
        const defaultListTrailNotes = listTrailNotes.filter(
          (item) => item._id !== noteId
        );
        notification('success', I18n.t('trailNotes.successDeleteTrailNotes'));
        setListTrailNotes(defaultListTrailNotes);
      } else {
        notification('error', I18n.t('messages.booking.data_incorect'));
      }
    } catch (error) {
      notification('error', I18n.t('messages.booking.data_incorect'));
    }
  };

  const handleGetListTrailNotes = async () => {
    try {
      const result = await settingActions.getTrailNotes({
        fleetId,
        bookId,
      });
      setListTrailNotes(result || []);
    } catch (error) {
      notification('error', I18n.t('messages.booking.data_incorect'));
    }
  };

  useEffect(() => {
    handleGetListTrailNotes();
  }, [bookId]);

  return (
    <div className={styles['layout']}>
      <Drawer
        isOpen={showDrawerTrailNotes}
        onClose={handleResetForm}
        title={<Translate value={'trailNotes.bookingActivity'} />}
        footer={null}
        classNameBody={styles['drawer-trailnotes']}
        styleDrawer={{ width: '500px' }}
      >
        <FormDataTrailNotes
          formData={formData}
          handleChangeValue={handleChangeValue}
          handleChangeFile={handleChangeFile}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleDeleteFile={handleDeleteFile}
          handleCreateTrailNotes={handleCreateTrailNotes}
          listTrailNotes={listTrailNotes}
          handleDeleteTrailNotes={handleDeleteTrailNotes}
          auth={auth}
        />
      </Drawer>
      <div className={styles['header']}>
        <span className={styles['text-green']}>
          {I18n.t('trailNotes.bookingActivity')}
        </span>
        <span
          className={styles['view-all']}
          onClick={() => {
            setShowTrailNote(true)
            setShowDrawerTrailNotes(true);
          }}
        >
          {I18n.t('trailNotes.viewAll')}
        </span>
      </div>
      <FormDataTrailNotes
        formData={formData}
        auth={auth}
        handleChangeValue={handleChangeValue}
        handleChangeFile={handleChangeFile}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleDeleteFile={handleDeleteFile}
        handleCreateTrailNotes={handleCreateTrailNotes}
        listTrailNotes={listTrailNotes}
        handleDeleteTrailNotes={handleDeleteTrailNotes}
      />
    </div>
  );
};

export default TrailNotes;
