import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'passengerName',
    label: 'report.result.referralHistory.detailFields.passengerName',
  },
  {
    key: 'passengerPhone',
    label: 'report.result.referralHistory.detailFields.passengerPhone',
  },
  {
    key: 'appliedDate',
    label: 'report.result.referralHistory.detailFields.appliedDate',
    mutate: (value, doc) =>
      value ? ReportUtils.formatTime(value, doc, {}) : 'N/A',
  },
  {
    key: 'firstBookingDate',
    label: 'report.result.referralHistory.detailFields.firstBookingDate',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'fullName',
    label: 'report.result.referralHistory.detailFields.referredByDriver',
  },
  {
    key: 'phone',
    label: 'report.result.referralHistory.detailFields.driverPhone',
  },
  {
    key: 'referralCode',
    label: 'report.result.referralHistory.detailFields.referralCode',
  },
];
