import _ from 'lodash';

export const fields = [
  {
    key: 'carInfo',
    label: 'Car info',
    sub: [
      {
        key: 'carInfoCompanyName',
        label: 'report.result.dailyCar.carInfoCompanyName',
        summary: 'carInfoCompanyName',
        mutate: (value, doc) => _.get(doc, 'carInfo.companyName', '')
      },
      {
        key: 'carInfoPermission',
        label: 'report.result.dailyCar.carInfoPermission',
        summary: 'carInfoPermission',
        mutate: (value, doc) => _.get(doc, 'carInfo.permission', '')
      },
      {
        key: 'carInfoVehiclePlateNumber',
        label: 'report.result.dailyCar.carInfoVehiclePlateNumber',
        summary: 'carInfoVehiclePlateNumber',
        mutate: (value, doc) => _.get(doc, 'carInfo.vehiclePlateNumber', '')
      },
      {
        key: 'carInfoVhcId',
        label: 'report.result.dailyCar.carInfoVhcId',
        summary: 'carInfoVhcId',
        mutate: (value, doc) => _.get(doc, 'carInfo.vhcId', '')
      },
      {
        key: 'carInfoCarType',
        label: 'report.result.dailyCar.carInfoCarType',
        summary: 'carInfoCarType',
        mutate: (value, doc) => _.get(doc, 'carInfo.carType', '')
      }
    ]
  },
  {
    key: 'completedTrips',
    label: 'Completed trips',
    sub: [
      {
        key: 'completedTripsTotal',
        label: 'report.result.dailyCar.completedTripsTotal',
        summary: 'completedTripsTotal',
        mutate: (value, doc) => _.get(doc, 'completedTrips.total', 0)
      },
      {
        key: 'completedTripsCc',
        label: 'report.result.dailyCar.completedTripsCc',
        summary: 'completedTripsCc',
        mutate: (value, doc) => _.get(doc, 'completedTrips.cc', 0)
      },
      {
        key: 'completedTripsCarHailing',
        label: 'report.result.dailyCar.completedTripsCarHailing',
        summary: 'completedTripsCarHailing',
        mutate: (value, doc) => _.get(doc, 'completedTrips.carHailing', 0)
      },
      {
        key: 'completedTripsStreetSharing',
        label: 'report.result.dailyCar.completedTripsStreetSharing',
        summary: 'completedTripsStreetSharing',
        mutate: (value, doc) => _.get(doc, 'completedTrips.streetSharing', 0)
      },
      {
        key: 'completedTripsPaxApp',
        label: 'report.result.dailyCar.completedTripsPaxApp',
        summary: 'completedTripsPaxApp',
        mutate: (value, doc) => _.get(doc, 'completedTrips.paxApp', 0)
      },
      {
        key: 'completedTripsWebBooking',
        label: 'report.result.dailyCar.completedTripsWebBooking',
        summary: 'completedTripsWebBooking',
        mutate: (value, doc) => _.get(doc, 'completedTrips.webBooking', 0)
      },
      {
        key: 'completedTripsMDispatcher',
        label: 'report.result.dailyCar.completedTripsMDispatcher',
        summary: 'completedTripsMDispatcher',
        mutate: (value, doc) => _.get(doc, 'completedTrips.mDispatcher', 0)
      },
      {
        key: 'completedTripsKiosk',
        label: 'report.result.dailyCar.completedTripsKiosk',
        summary: 'completedTripsKiosk',
        mutate: (value, doc) => _.get(doc, 'completedTrips.kiosk', 0)
      },
      {
        key: 'completedTripsAPI',
        label: 'report.result.dailyCar.completedTripsAPI',
        summary: 'completedTripsAPI',
        mutate: (value, doc) => _.get(doc, 'completedTrips.API', 0)
      }
    ]
  },
  {
    key: 'fareDetails',
    label: 'report.result.dailyCar.fareDetails',
    sub: [
      {
        key: 'fareDetailsBasicFare',
        label: 'report.result.dailyCar.fareDetailsBasicFare',
        summary: 'fareDetailsBasicFare',
        mutate: (value, doc) => _.get(doc, 'fareDetails.basicFare', 0)
      },
      {
        key: 'fareDetailsAirportFee',
        label: 'report.result.dailyCar.fareDetailsAirportFee',
        summary: 'fareDetailsAirportFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.airportFee', 0)
      },
      {
        key: 'fareDetailsMeetDriver',
        label: 'report.result.dailyCar.fareDetailsMeetDriver',
        isShow: 'meetDriver',
        summary: 'fareDetailsMeetDriver',
        mutate: (value, doc) => _.get(doc, 'fareDetails.meetDriver', 0)
      },
      {
        key: 'fareDetailsRushHour',
        label: 'report.result.dailyCar.fareDetailsRushHour',
        isShow: 'rushHourActive',
        summary: 'fareDetailsRushHour',
        mutate: (value, doc) => _.get(doc, 'fareDetails.rushHour', 0)
      },
      {
        key: 'fareDetailsHeavyTraffic',
        label: 'report.result.dailyCar.fareDetailsHeavyTraffic',
        isShow: 'heavyTrafficActive',
        summary: 'fareDetailsHeavyTraffic',
        mutate: (value, doc) => _.get(doc, 'fareDetails.heavyTraffic', 0)
      },
      {
        key: 'fareDetailsTollFee',
        label: 'report.result.dailyCar.fareDetailsTollFee',
        isShow: 'tollFeeActive',
        summary: 'fareDetailsTollFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.tollFee', 0)
      },
      {
        key: 'fareDetailsParkingFee',
        label: 'report.result.dailyCar.fareDetailsParkingFee',
        isShow: 'parkingFeeActive',
        summary: 'fareDetailsParkingFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.parkingFee', 0)
      },
      {
        key: 'fareDetailsGasFee',
        label: 'report.result.dailyCar.fareDetailsGasFee',
        isShow: 'gasFeeActive',
        summary: 'fareDetailsGasFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.gasFee', 0)
      },
      {
        key: 'fareDetailsOtherFees',
        label: 'report.result.dailyCar.fareDetailsOtherFees',
        isShow: 'otherFeeActive',
        summary: 'fareDetailsOtherFees',
        mutate: (value, doc) => _.get(doc, 'fareDetails.otherFees', 0)
      },
      {
        key: 'fareDetailsAddOnPrice',
        label: 'report.result.dailyCar.fareDetailsAddonPrice',
        checkShowFromProps: 'selectedFleet',
        isNotShow: selectedFleet => {
          const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
          return !addonPrice;
        },
        summary: 'fareDetailsAddOnPrice',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.addOnPrice', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.addOnPrice', 0)
      },
      {
        key: 'fareDetailsOldSubTotal',
        label: 'report.result.dailyCar.fareDetailsOldSubTotal',
        checkShowFromProps: 'selectedFleet',
        isNotShow: selectedFleet => {
          const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
          return !addonPrice;
        },
        summary: 'fareDetailsOldSubTotal',
        summaryMutate: (value, doc) => _.get(doc, 'fareDetails.oldSubTotal', 0),
        mutate: (value, doc) => _.get(doc, 'fareDetails.oldSubTotal', 0)
      },
      {
        key: 'fareDetailsSubTotal',
        label: 'report.result.dailyCar.fareDetailsSubTotal',
        summary: 'fareDetailsSubTotal',
        mutate: (value, doc) => _.get(doc, 'fareDetails.subTotal', 0)
      },
      {
        key: 'fareDetailsSubTotalFF',
        label: 'report.result.dailyCar.fareDetailsSubTotalFF',
        summary: 'fareDetailsSubTotalFF',
        mutate: (value, doc) => _.get(doc, 'fareDetails.subTotalFF', 0),
        checkShowFromProps: 'selectedFleet',
        isNotShow: selectedFleet => {
          const modules = _.get(selectedFleet, 'modules', []);
          return !_.find(modules, m => (m.name === "Driver to pax" || m.name === "Pax refers Pax"));
        }
      },
      {
        key: 'fareDetailsTechFee',
        label: 'report.result.dailyCar.fareDetailsTechFee',
        summary: 'fareDetailsTechFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.techFee', 0)
      },
      {
        key: 'fareDetailsBookingFee',
        label: 'report.result.dailyCar.fareDetailsBookingFee',
        summary: 'fareDetailsBookingFee',
        mutate: (value, doc) => _.get(doc, 'fareDetails.bookingFee', 0)
      },
      {
        key: 'fareDetailsSalesTax',
        label: 'report.result.dailyCar.fareDetailsSalesTax',
        isShow: 'taxActive',
        summary: 'fareDetailsSalesTax',
        mutate: (value, doc) => _.get(doc, 'fareDetails.salesTax', 0)
      },
      {
        key: 'fareDetailsTip',
        label: 'report.result.dailyCar.fareDetailsTip',
        isShow: 'tipActive',
        summary: 'fareDetailsTip',
        mutate: (value, doc) => _.get(doc, 'fareDetails.tip', 0)
      },
      {
        key: 'fareDetailsPromoAmount',
        label: 'report.result.dailyCar.fareDetailsPromoAmount',
        summary: 'fareDetailsPromoAmount',
        mutate: (value, doc) => _.get(doc, 'fareDetails.promoAmount', 0)
      },
      {
        key: 'fareDetailsTotalAmt',
        label: 'report.result.dailyCar.fareDetailsTotalAmt',
        summary: 'fareDetailsTotalAmt',
        mutate: (value, doc) => _.get(doc, 'fareDetails.totalAmt', 0)
      }
    ]
  }
];

export const fareFields = [
  'fareDetailsBasicFare',
  'fareDetailsAirportFee',
  'fareDetailsMeetDriver',
  'fareDetailsRushHour',
  'fareDetailsHeavyTraffic',
  'fareDetailsTollFee',
  'fareDetailsParkingFee',
  'fareDetailsGasFee',
  'fareDetailsOtherFees',
  'fareDetailsSubTotal',
  'fareDetailsSubTotalFF',
  'fareDetailsTechFee',
  'fareDetailsBookingFee',
  'fareDetailsSalesTax',
  'fareDetailsTip',
  'fareDetailsPromoAmount',
  'fareDetailsTotalAmt',
  'fareDetailsAddOnPrice',
  'fareDetailsOldSubTotal'
];
