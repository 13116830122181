import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./reservation.scss";
import ReservationList from "./ReservationList";
import ReservationSearch from "./ReservationSearch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reservationActions from "./../../actions/reservationActions";
import moment from "moment";
import _ from "lodash";
import * as commonDataActions from "../../actions/commonDataAction";
import { showDriversInReservation } from "./../../constants/commondata";
import ReactDOM from "react-dom";
import { TOGGLE_SIDEBAR } from "../../constants/customEvent";
import { DayOfWeek } from './ReservationList';

const minsADay = 60 * 24;
const drawingColor = {
  START_BAR: "#5ac8f9",
  NORMAL_BLOCK: "rgba(166, 220, 246, 0.6)",
  OVERLAP_BLOCK: "#ff3b2f"
};

const time = {
  DAY: "day",
  WEEK: "week"
};
class Reservation extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      limit: 20,
      page: 0,
      total: 0,
      sort: null,
      searchData: {
        tab: time.DAY,
        companyId: "all",
        driverId: "all",
        vehicleId: "all",
        vehicleType: "all",
        showType: "reserved",
        dateFrom: moment(new Date())
          .subtract(1, "days")
          .hour(0)
          .minute(0)
          .second(0)._d,
        dateTo: moment(new Date())
          .hour(23)
          .minute(59)
          .second(59)._d,
        fleetDateFrom: moment(new Date())
          .subtract(1, "days")
          .hour(0)
          .minute(0)
          .second(0)._d,
        searchText: ""
      }
    };
  }

  componentDidMount = () => {
    this.calculateTableSize();
    window.addEventListener("resize", _.debounce(this.calculateTableSize, 500));
    window.addEventListener(
      TOGGLE_SIDEBAR,
      _.debounce(this.calculateTableSize, 500)
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      _.debounce(() => this.calculateTableSize(true), 500)
    );
    window.removeEventListener(
      TOGGLE_SIDEBAR,
      _.debounce(() => this.calculateTableSize(true), 500)
    );
  }

  calculateTableSize = (unmount) => {
    if (unmount) return;
    let horizontalPadding = 20;
    let verticalPadding = 10;
    let totalHeight = this && ReactDOM.findDOMNode(this) ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let totalWidth = this && ReactDOM.findDOMNode(this) ? ReactDOM.findDOMNode(this).clientWidth : 0;
    let filterHeight = this.reservationSearch && ReactDOM.findDOMNode(this.reservationSearch)
      ? ReactDOM.findDOMNode(this.reservationSearch).clientHeight
      : 160;
    let height = totalHeight ? totalHeight - filterHeight - verticalPadding : 0,
      width = totalWidth ? totalWidth - horizontalPadding : 0;
    if (window.innerHeight < 850 && height < 600) height = 600;
    this.setState({
      height,
      width
    });
  };

  //Reference: https://reactjs.org/docs/context.html
  getChildContext() {
    return {
      drawingColor,
      time
    };
  }

  onSearch = searchingFilter => {
    const searchData = {
      ...this.state.searchData
    };

    const {
      fleetTimeZone,
      companyId,
      driverId,
      vehicleId,
      vehicleType,
      searchText,
      showType,
      commonFormat = 'MM/DD/YYYY HH:mm:ss',
      timeRange
    } = searchingFilter;

    searchData.companyId = companyId;
    searchData.driverId = driverId;
    searchData.vehicleId = vehicleId;
    searchData.vehicleType = vehicleType;
    searchData.searchText = searchText;
    searchData.showType = showType;

    /*==========================================================
    **Get time range from date picker & convert to Fleet time.
    **Ex: In default. Timerange is a local time.
    **Convert 12/11/2019 00:00:00 in GMT+10 (local time)
    **To 12/11/2019 00:00:00 in GMT-8 (fleet time)
    ==========================================================*/
    const timeFromInFleetTimeZone = moment(timeRange.from);
    const timeToInFleetTimeZone = moment(timeRange.to);

    /*==========================================================
    **Convert fleet time to UTC time
    **Ex: 12/11/2019 00:00 in GMT-8 (fleet time)
    **=> 12/11/2019 08:00 in GMT-8 (UTC time)
    ==========================================================*/
    const timeFromInUTC0 = moment.tz(timeFromInFleetTimeZone.format(commonFormat), commonFormat, fleetTimeZone).utc();
    const timeToInUTC0 = moment.tz(timeToInFleetTimeZone.format(commonFormat), commonFormat, fleetTimeZone).utc();

    searchData.dateFrom = timeFromInUTC0._d;
    searchData.dateTo = timeToInUTC0._d;
    searchData.fleetDateFrom = timeFromInFleetTimeZone._d;

    searchData.tab = timeRange.timeType;

    this.setState(
      {
        searchData,
        limit: this.state.limit,
        page: 0
      },
      () => {
        this.prepareData(timeRange.timeType);
      }
    );
  };

  //Tab: WEEK or DAY
  prepareData = tab => {
    let options = {
      limit: this.state.limit,
      page: this.state.page,
      sort: this.state.sort,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        companyId: this.props.auth?.user?.roles?.isSupplier ? this.props.auth?.user?.roles?.supplierId : this.state.searchData.companyId,
        driverId: this.state.searchData.driverId,
        vehicleId: this.state.searchData.vehicleId,
        vehicleType: this.state.searchData.vehicleType,
        dateFrom: this.state.searchData.dateFrom,
        dateTo: this.state.searchData.dateTo,
        txtSearch: this.state.searchData.searchText,
        showType: this.state.searchData.showType
      }
    };
    this.props.reservationActions
      .getReservation(options)
      .then(data => {
        let searchData = this.state.searchData;
        searchData.tab = tab;
        this.setState({
          searchData,
          total: data.res.total,
          page: data.res.page,
          limit: data.res.limit
        });
      })
      .then(error => {
        //console.log(error);
      });
  };

  createWeekData = reservations => {
    const { auth: { selectedFleet: { timezone: fleetTimeZone } } } = this.props;
    _.map(reservations, (reservation, index) => {
      let weekData = new Array(7);
      /* DayOfWeek */
      _.forEach(reservation.bookings, booking => {
        const USDay = moment(booking.request.pickUpTime).tz(fleetTimeZone).day(); /* USDay is number of day in US local: Mon is 1, Sun is 0 */
        const bookingDay = DayOfWeek.find(day => day.USDay === USDay);

        if (bookingDay) {
          const weekDay = bookingDay.numberOfDay; /* Number of Day is day number in VN: Mon is 0, Sun is 6 */
          if (!weekData[weekDay] || !Array.isArray(weekData[weekDay]))
            weekData[weekDay] = [booking];
          else
            weekData[weekDay].push(booking);
        }
      });
      reservation.weekData = weekData;
    });
    return reservations;
  };

  createDayData = reservations => {
    const { auth: { selectedFleet: { timezone: fleetTimeZone } } } = this.props;
    let calculatedData = [];
    _.map(reservations, reservation => {
      let calculatedPositions = [];
      let validBookings = this.filterValidBookings(reservation.bookings);
      _.map(validBookings, booking => {
        const { pickUpTime, pickup } = booking.request;

        const pickupTimeAtFleetZone = moment(pickUpTime).tz(fleetTimeZone);
        /*==========================================================
        **Convert Pickup in UTC time to Pickup in Fleet time
        **Get hour and mins of Pickup time in Fleet time
        ==========================================================*/

        const hours = pickupTimeAtFleetZone.hour();
        const mins = pickupTimeAtFleetZone.minute();
        let startingPosition = hours * 60 + mins;
        let duration = Math.floor(
          (booking.request.estimate.estimateValue
            ? booking.request.estimate.estimateValue
            : 0) / 60
        );
        let hasStartPoint = true;

        // Case: We had reservation in the past and duration still pass to selected day,
        // It means the pickUpTime is before the selected day
        if (
          moment(booking.request.pickUpTime)
            .hour(0)
            .minute(0)
            .second(0)
            .isBefore(
              moment(this.state.searchData.dateFrom)
                .hour(23)
                .minute(59)
                .second(59),
              "day"
            )
        ) {
          startingPosition = 0;
          duration =
            booking.request.estimate.estimateValue &&
              booking.request.estimate.estimateValue >= 60
              ? Math.floor(booking.request.estimate.estimateValue / 60) -
              (minsADay - (hours * 60 + mins))
              : 0;
          hasStartPoint = false;
        }

        //Every calculated position is percent
        //divide position for mins a day to get percent
        let position = {
          startingPosition: startingPosition / minsADay,
          duration:
            duration + startingPosition <= minsADay
              ? duration / minsADay
              : (minsADay - startingPosition) / minsADay,
          endingPosition:
            duration + startingPosition <= minsADay
              ? (duration + startingPosition) / minsADay
              : 1,
          isOverLap: false,
          hasStartPoint,
          hasEndPoint: duration + startingPosition <= minsADay,
          bookings: [booking]
        };
        calculatedPositions.push(position);
      });

      //Combine the normal reservation and overlapped reservation into drawing list
      calculatedPositions = _.concat(
        calculatedPositions,
        this.calculatingOverLapTime(calculatedPositions)
      );

      if (
        (this.state.searchData.showType === showDriversInReservation.reserved &&
          !_.isEmpty(calculatedPositions)) ||
        this.state.searchData.showType !== showDriversInReservation.reserved
      ) {
        calculatedData.push({
          drawingList: calculatedPositions,
          ...reservation
        });
      }
    });
    return calculatedData;
  };

  /*
   ** Name: filterValidBookings
   ** Description: After getting data from server, data will have some unvalid booking.
   ** Example, all bookings(in the reservations) started and finished in the day before. So, it's not fit selected day.
   ** Effect: Only day
   ** Parameters: array(bookings)
   ** Return: array(valid bookings)
   */
  filterValidBookings(bookings) {
    let validBookings = [];
    let midNightOfSelectedDay = moment(this.state.searchData.dateFrom).utc();
    let nearlyTomorrowOfSelectedDay = moment(this.state.searchData.dateTo).utc();


    validBookings = _.filter(bookings, booking => {
      // the booking should have dropOffTime(pickUpTime + duration) > the selected day
      let duration = booking.request.estimate.estimateValue
        ? booking.request.estimate.estimateValue
        : 0;
      let dropOffTime = moment(booking.request.pickUpTime).add(
        duration,
        "seconds"
      );
      return (
        dropOffTime.isAfter(midNightOfSelectedDay) &&
        moment(booking.request.pickUpTime).isSameOrBefore(
          nearlyTomorrowOfSelectedDay
        )
      );
    });
    return validBookings;
  }

  /*
   ** Name: calculatingOverLapTime
   ** Description: calculating the overlap range between 2 reservation overlapRange = Max(endA, endB) - Min(startA, startB)
   ** Check 2 dates are overlapped Max(startA, startB) <= Min(EndA, EndB)
   ** Starting point from overlapTime is Min(startA, startB)
   ** Ending point from overlapTime is Max(endA, endB)
   ** Parameters: array
   ** Return: array
   ** Reference: https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
   */
  calculatingOverLapTime(drawingList) {
    let overlapTimeArray = [];
    for (var i = 0; i < drawingList.length - 1; i++) {
      let isOverLappedFlag = false;
      let overLappedElement = _.clone(drawingList[i]);
      for (var j = i + 1; j < drawingList.length; j++) {
        let startMax = _.max([
          overLappedElement.startingPosition,
          drawingList[j].startingPosition
        ]);
        let endMin = _.min([
          overLappedElement.endingPosition,
          drawingList[j].endingPosition
        ]);
        if (startMax <= endMin) {
          isOverLappedFlag = true;
          let startingPosition = _.min([
            overLappedElement.startingPosition,
            drawingList[j].startingPosition
          ]);
          let endingPosition = _.max([
            overLappedElement.endingPosition,
            drawingList[j].endingPosition
          ]);
          overLappedElement.startingPosition = startingPosition;
          overLappedElement.endingPosition = endingPosition;
          overLappedElement.duration =
            endingPosition >= startingPosition
              ? endingPosition - startingPosition
              : 0;
          overLappedElement.isOverLap = true;
          overLappedElement.hasStartPoint = false;
          overLappedElement.hasEndPoint = true;
          overLappedElement.bookings = _.concat(
            overLappedElement.bookings,
            drawingList[j].bookings
          );
        }
      }
      if (isOverLappedFlag) {
        overlapTimeArray.push(overLappedElement);
      }
    }
    return overlapTimeArray;
  }

  handleRawReservationsData = reservationsFromProps => {
    let reservations = [];
    if (this.state.searchData.tab === time.WEEK) {
      reservations = this.createWeekData(reservationsFromProps);
    } else {
      reservations = this.createDayData(reservationsFromProps);
    }
    return reservations;
  };

  handleNumItemsPerPageChange = limit => {
    this.setState(
      {
        limit: limit,
        page: 0
      },
      () => this.prepareData(this.state.searchData.tab)
    );
  };

  handlePaginationSelect = page => {
    this.setState(
      {
        page: parseInt(page)
      },
      () => this.prepareData(this.state.searchData.tab)
    );
  };

  render() {
    const { total, page, limit } = this.state;
    //console.log({ data: this.props.reservations });
    return (
      <div className="content">
        <ReservationSearch
          onSearch={this.onSearch}
          defaultData={this.state.searchData}
          timeRangeChangeCallback={_.debounce(this.calculateTableSize, 500)}
          ref={node => (this.reservationSearch = node)}
          fleetId={this.props.auth.selectedFleet.fleetId}
          auth={this.props.auth}
          fleetTimeZone={this.props.auth.selectedFleet.timezone}
        />
        <ReservationList
          onTab={this.state.searchData.tab}
          reservations={this.handleRawReservationsData(this.props.reservations)}
          footerData={{ total, page, limit }}
          handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
          handlePaginationSelect={this.handlePaginationSelect}
          height={this.state.height || 0}
          width={this.state.width || 200}
          startDate={this.state.searchData.fleetDateFrom}
          fleetTimeZone={this.props.auth.selectedFleet.timezone}
          router={this.props.router}
        />
      </div>
    );
  }
}

Reservation.childContextTypes = {
  drawingColor: PropTypes.object,
  time: PropTypes.object
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    reservations: state.reservations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reservationActions: bindActionCreators(reservationActions, dispatch),
    commonDataActions: bindActionCreators(commonDataActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservation);
