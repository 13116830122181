import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    ButtonToolbar,
    Tab,
    Tabs,
    Modal
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import momentTz from 'moment-timezone';
import _ from 'lodash';
import Promise from 'bluebird';

import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';

import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { Validator, ValidCase, WarningFeeInput } from '../../../../components/validator';
import { CCLiteCommonFunc, Validation, textboxNumberHelper, MultiSelectCommonFunc } from '../../../../utils/commonFunctions';
import RadioButton from '../../../../components/radioButton/radio';
import DateTime from '../../../../components/dateTime/DateTime';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import AddPromoCodeModal from '../Components/AddPromoCodeModal';
import { BsPlus } from 'react-icons/bs';


const PassengerGetType = {
    Money: 'money',
    Promo: 'promo'
};

class PassengerRefersPax extends Component {
    constructor() {
        super();
        this.state = {
            formData: {
                refererGet: {
                    money: [],
                },
                refereeGet: {
                    type: PassengerGetType.Money,
                    paidByDriver: false,
                    money: [],
                    promo: {
                        promoCode: '',
                        promoId: ''
                    }
                },
                isExpired: false,
                fromDate: null,
                expiryDate: null,
                isLimitInvitation: false,
                limitedUser: 0,
                isActive: false
            },
            promotionCodeList: [],
            valid: {},
            isSubmited: false,
            showDialogPromoCode: false,
            showConfirmToDelete: false,
            editable: false,
            dialogChanged: false,
            dialogData: {},
            sourceToSelect: undefined,
            sourceUsed: []
        }
    }

    componentDidMount() {
        const { auth, settingActions } = this.props;
        if (auth.selectedFleet.fleetConfigs && auth.selectedFleet.fleetConfigs.webLink) {
            this.setState({
                sourceToSelect: auth.selectedFleet.fleetConfigs.webLink
            })
        };
        Promise.all([
            settingActions.fetchPromotionCodeForReferral({
                fleetId: auth.selectedFleet.fleetId
            }),
            settingActions.getReferralPassengerToPaxDetails({
                fleetId: auth.selectedFleet.fleetId
            })
        ])
            .spread((promoData, referralData) => {
                const promotionCodeList = promoData.res ? promoData.res : [];
                let formData = referralData.res
                    ? Object.assign({}, referralData.res)
                    : Object.assign({}, this.state.formData);

                if (formData.refereeGet.promos.length > 0) {
                    formData.refereeGet.promos = formData.refereeGet.promos.filter(item => {
                        return promotionCodeList.find(({ _id }) => item.promoId === _id)
                    })
                };

                let sourceUsed = [];
                if (formData.refereeGet.promos) {
                    _.forEach(formData.refereeGet.promos, promo => {
                        if (promo.autoApplyOnWeb.sourceApply.length > 0) {
                            sourceUsed = sourceUsed.concat(promo.autoApplyOnWeb.sourceApply);
                        };
                    })
                };

                if (formData.fromDate) {
                    const { selectedFleet } = auth;
                    const stringDate = momentTz(formData.fromDate)
                        .tz(selectedFleet.timezone)
                        .format('YYYY-MM-DD');
                    formData.fromDate = moment(stringDate);
                } else {
                    const { selectedFleet } = auth;
                    const stringDate = momentTz(new Date())
                        .tz(selectedFleet.timezone)
                        .format('YYYY-MM-DD');
                    formData.fromDate = moment(stringDate);
                };

                if (formData.expiryDate) {
                    const { selectedFleet } = auth;
                    const stringDate = momentTz(formData.expiryDate)
                        .tz(selectedFleet.timezone)
                        .format('YYYY-MM-DD');
                    formData.expiryDate = moment(stringDate);
                };

                const refererGet = formData.refererGet;
                const refereeGet = formData.refereeGet;

                if (!refererGet.money.length) {
                    formData = this.initDefaultPassengerReferValue(formData, 'refererGet');
                }

                if (!refereeGet.money.length) {
                    formData = this.initDefaultPassengerReferValue(formData, 'refereeGet');
                }


                this.setState({ promotionCodeList, formData, sourceUsed });
            })
            .catch(error => {
                let formData = Object.assign({}, this.state.formData);
                formData = this.initDefaultPassengerReferValue(formData, 'refererGet');
                formData = this.initDefaultPassengerReferValue(formData, 'refereeGet');
                this.setState({ formData });
            });
    }

    onChangeOption = (key, e, validKey) => {
        const { formData, valid } = this.state;
        let { value } = e.target;
        if (value == '0') value = false;
        if (value == '1') value = true;
        if (validKey && !value) {
            _.set(valid, validKey, true);
        }
        _.set(formData, key, value);
        this.setState({
            formData,
            valid: {}
        });
    };

    onChangePromoPaidByOption = (e) => {
        let formData = Object.assign({}, this.state.formData);
        let { value } = e.target;
        if (value == '0') value = false;
        if (value == '1') value = true;
        formData.refereeGet.paidByDriver = value;
        this.setState({ formData });
    };

    handleChangeFefereeWillType = e => {
        let formData = Object.assign({}, this.state.formData);
        formData = this.initDefaultPassengerReferValue(formData, 'refereeGet');
        formData.refereeGet.type = e.target.value;
        this.setState({ formData });
    };

    handleMultiCurrenciesChange(key, iso, e) {
        let item = _.get(this.state.formData, key, []);
        const { formData } = this.state;
        let { value } = e.target;
        if (value < 0) value = 0;
        if (item == []) {
            item = [
                {
                    value,
                    currencyISO: iso
                }
            ];
        } else {
            const findCurrency = item.findIndex(c => {
                return c.currencyISO === iso
            });
            if (findCurrency >= 0) {
                item[findCurrency].value = value;
            } else {
                item.push({
                    value,
                    currencyISO: iso
                });
            }
        }
        _.set(formData, key, item);
        this.setState({ formData });
    };


    onInputChange = (key, e, { type, min, max } = {}) => {
        const { formData } = this.state;
        let { value } = e.target;
        if (type) {
            if (type == 'int') {
                value = parseInt(value);
            }
            if (type == 'number') {
                value = parseFloat(value);
            }
            if (min && value < min) {
                value = min;
            }
        }

        _.set(formData, key, value);

        this.setState({
            formData
        });
    };

    onChangeValidDate = (key, e) => {
        const { formData } = this.state;
        formData[key] = e;
        this.setState({
            formData
        });
    };

    validDate = current => {
        const yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    };

    initDefaultPassengerReferValue = (formData, packageType) => {
        const { auth } = this.props;
        formData[packageType].money = [];
        _.forEach(auth.selectedFleet.currencies, c => {
            const obj = {
                value: 0,
                currencyISO: c.iso
            };
            formData[packageType].money.push(obj);
        });

        return formData;
    };

    ValidatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    };

    handleSaveClick = () => {
        this.setState({ isSubmited: true });
        const { selectedFleet } = this.props.auth;
        const promoList = this.state.promotionCodeList;

        if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
            this.props.loadingBarActions.showLoadingSpiner();
            const form = _.extend({}, this.state.formData);
            form.fleetId = this.props.auth.selectedFleet.fleetId;

            if (form.refereeGet.type === PassengerGetType.Promo) {
                const customerPromo = form.refereeGet.promo;
                const promoObj = promoList.find(obj => obj._id == customerPromo.promoId);
                if (promoObj) {
                    form.refereeGet.promo.promoCode = promoObj.promotionCode;
                    form.refereeGet.promo.type = promoObj.type;
                    form.refereeGet.promo.valueByCurrencies = promoObj.valueByCurrencies;
                } else {
                    form.refereeGet.promo.promoCode = '';
                    form.refereeGet.promo.promoId = '';
                };
            } else {
                form.refereeGet.promo.promoCode = '';
                form.refereeGet.promo.promoId = '';
            };

            const fromDate = form.fromDate.format('YYYY-MM-DD');
            form.fromDate = momentTz
                .tz(fromDate, selectedFleet.timezone)
                .startOf('day')
                .toDate();

            if (form.isExpired) {
                const stringDate = form.expiryDate.format('YYYY-MM-DD');
                form.expiryDate = momentTz
                    .tz(stringDate, selectedFleet.timezone)
                    .endOf('day')
                    .toDate();
            } else {
                form.expiryDate = null;
            }
            if (!form.isLimitInvitation) {
                form.limitedUser = 0;
            }
            if (form.expiryDate != null && form.expiryDate < form.fromDate) {
                this.setState({ isSubmited: false });
                this.context.notification('error', I18n.t('errors.13012'));
                this.props.loadingBarActions.hideLoadingSpiner();
                return;
            }
            this.props.settingActions.saveReferralPassengerToPaxDetails(form).then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.res) {
                    this.context.notification('success', I18n.t('referral.refferal_update_successfully'));
                    this.setState({ isSubmited: false });
                }
            });
        }
    };

    handleAddButtonClick = () => {
        let object = {
            promoCode: "",
            promoId: "",
            isSendInbox: false,
            isDefault: false,
            autoApplyOnWeb: {
                enable: false,
                sourceApply: []
            },
        };
        this.setState({
            showDialogPromoCode: true,
            editable: true,
            dialogData: object,
        });
    };

    handleCloseDialogForm = () => {
        this.setState({
            showDialogPromoCode: false,
            showConfirmToDelete: false,
            editable: false,
            dialogChanged: false,
            dialogData: null,
        });
    };

    handleSelectPromoCode = (e) => {
        const { dialogData } = this.state;
        let { value } = e.target;
        this.setState({
            dialogData: {
                ...dialogData,
                promoId: value
            },
            dialogChanged: true
        })
    };

    handleChangePrompCodeOption = (key, e) => {
        const { dialogData } = this.state;
        let { checked } = e.target;
        if (key === "isDefault" && checked) {
            this.setState({
                dialogData: {
                    ...dialogData,
                    isSendInbox: checked,
                    isDefault: checked
                },
                dialogChanged: true
            });
        } else if (key === "autoApplyOnWeb") {
            this.setState({
                dialogData: {
                    ...dialogData,
                    autoApplyOnWeb: {
                        sourceApply: checked ? dialogData.autoApplyOnWeb.sourceApply : [],
                        enable: checked
                    }
                },
                dialogChanged: true
            })
        } else {
            this.setState({
                dialogData: {
                    ...dialogData,
                    [key]: checked
                },
                dialogChanged: true
            })
        };
    };

    handleChangeSourceApply = (e) => {
        const { dialogData, sourceToSelect } = this.state;
        let value = MultiSelectCommonFunc.getSelectValues(e);
        let sourceApply = [];
        value.forEach(e => {
            var data = sourceToSelect.find(s => s.key === e);
            sourceApply.push(data);
        });
        this.setState({
            dialogData: {
                ...dialogData,
                autoApplyOnWeb: {
                    ...dialogData.autoApplyOnWeb,
                    sourceApply
                }
            },
            dialogChanged: true
        })
    };

    handleClickMenuPromosList = (key, data) => {
        switch (key) {
            case "View": {
                this.setState({
                    showDialogPromoCode: true,
                    dialogData: data,
                    editable: false,
                    dialogChanged: false,
                });
                break;
            }
            case "Edit": {
                this.setState({
                    showDialogPromoCode: true,
                    dialogData: data,
                    editable: true,
                    dialogChanged: false,
                });
                break;
            }
            case "changeStatus": {
                this.setState({ editable: true });
                break;
            }
            case "Delete": {
                this.setState({ showConfirmToDelete: true, dialogData: data });
                break;
            }
        }
    };

    handleSaveDialogForm = () => {
        this.setState({ isSubmited: true });
        const { selectedFleet } = this.props.auth;
        const { promotionCodeList, dialogData, formData } = this.state;
        const promoObj = promotionCodeList.find(item => item._id == dialogData.promoId);

        let promos = formData.refereeGet.promos || [];
        let promoUpdated = {};
        let index = -1;

        if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
            return;
        };

        if (promoObj) {
            if (dialogData.promoCode) {
                // Edit promotion code
                index = promos.findIndex(x => x.promoId === dialogData.promoId);
                if (index !== -1) {
                    promos.splice(index, 1);
                };
            };
            promoUpdated.promoCode = promoObj.promotionCode;
            promoUpdated.promoId = promoObj._id;
            promoUpdated.type = promoObj.type;
            promoUpdated.valueByCurrencies = promoObj.valueByCurrencies;
            promoUpdated.isSendInbox = dialogData.isSendInbox;
            promoUpdated.isDefault = dialogData.isDefault;
            promoUpdated.autoApplyOnWeb = dialogData.autoApplyOnWeb;

            // Only 1 promotion code is default
            if (dialogData.isDefault) {
                promos = promos.map(promo => {
                    if (promo.isDefault) {
                        promo.isDefault = false;
                    }
                    return promo;
                });
                promos.unshift(promoUpdated)
            } else {
                if (index === -1) {
                    promos.push(promoUpdated);
                } else {
                    promos.splice(index, 0, promoUpdated);
                }
            };
        };
        this.props.loadingBarActions.showLoadingSpiner();
        const requestBody = {
            fleetId: selectedFleet.fleetId,
            promos
        };
        this.props.settingActions.updatePromoCodeReferralPassengerToPaxDetails(requestBody)
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.res) {
                    let sourceUsed = [];
                    if (promos) {
                        _.forEach(promos, promo => {
                            if (promo.autoApplyOnWeb.sourceApply.length > 0) {
                                sourceUsed = sourceUsed.concat(promo.autoApplyOnWeb.sourceApply);
                            };
                        })
                    };
                    this.context.notification('success', I18n.t('referral.refferal_update_successfully'));
                    this.setState({
                        formData: {
                            ...formData,
                            refereeGet: {
                                ...formData.refereeGet,
                                promos
                            }
                        },
                        isSubmited: false,
                        showDialogPromoCode: false,
                        showConfirmToDelete: false,
                        editable: false,
                        dialogChanged: false,
                        dialogData: null,
                        sourceUsed
                    });
                }
            });
    };

    handleClickDeletePromoCode = () => {
        const { selectedFleet } = this.props.auth;
        const { formData, dialogData } = this.state;
        let promos = formData.refereeGet.promos || [];
        this.props.loadingBarActions.showLoadingSpiner();
        const requestBody = {
            fleetId: selectedFleet.fleetId,
            promoId: dialogData.promoId
        };
        if (promos.findIndex(x => x.promoId === dialogData.promoId) !== -1) {
            promos.splice(promos.findIndex(x => x.promoId === dialogData.promoId), 1)
        };
        this.props.settingActions.removePromoCodeReferralPassengerToPaxDetails(requestBody)
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.res) {
                    let sourceUsed = [];
                    if (promos) {
                        _.forEach(promos, promo => {
                            if (promo.autoApplyOnWeb.sourceApply.length > 0) {
                                sourceUsed = sourceUsed.concat(promo.autoApplyOnWeb.sourceApply);
                            };
                        })
                    };
                    this.context.notification('success', I18n.t('referral.refferal_update_successfully'));
                    this.setState({
                        formData: {
                            ...formData,
                            refereeGet: {
                                ...formData.refereeGet,
                                promos
                            }
                        },
                        isSubmited: false,
                        showDialogPromoCode: false,
                        showConfirmToDelete: false,
                        editable: false,
                        dialogChanged: false,
                        dialogData: null,
                        sourceUsed
                    });
                }
            });
    }

    renderCurrencies = (key, value) => {
        const idCreator = key.replace('.', '_');
        const { isSubmited = false } = this.state || {};
        const { permissions: { actions: hasPermission = false } = {}, auth: { selectedFleet, multiCurrencies } } = this.props || {};
        let amount = 0;
        let objData = {};
        if (value && value.length > 0) {
            objData = value.find(d => d.currencyISO === selectedFleet.currencies[0].iso);
            if (objData && objData.value) {
                amount = objData.value;
            };
        };

        return multiCurrencies ? (
            <div className="input-with-validator">
                <Tabs
                    id={`currencies-tabs-${idCreator}`}
                    className="currencies-tabs"
                    defaultActiveKey={this.props.auth.selectedFleet.currencies[0].iso}
                    animation={false}
                    onSelect={this.tabSelectHandle}
                >
                    {this.props.auth.selectedFleet.currencies.map(c => {
                        const currentValue = value ? value.filter(d => d.currencyISO == c.iso)[0] : null;
                        return (
                            <Tab className="currencies-tab-item" eventKey={c.iso} title={c.iso} key={c.iso}>
                                <FormGroup className="qup-input-group">
                                    <InputGroup className="single-addon-left">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                            {c.symbol}
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            className="form-custom number-no-spin"
                                            type="number"
                                            min={0}
                                            onChange={e => {
                                                this.handleMultiCurrenciesChange(key, c.iso, e);
                                            }}
                                            onBlur={e => {
                                                textboxNumberHelper.onBlurHandle(e, e => {
                                                    this.handleMultiCurrenciesChange(key, c.iso, e);
                                                });
                                            }}
                                            onFocus={e => {
                                                textboxNumberHelper.onfocusHandle(e, e => {
                                                    this.handleMultiCurrenciesChange(key, c.iso, e);
                                                });
                                            }}
                                            value={currentValue ? currentValue.value : ''}
                                            onWheel={e => e.preventDefault()}
                                            disabled={!hasPermission}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Tab>
                        );
                    })}
                </Tabs>
                <WarningFeeInput
                    message={I18n.t('message.warningInputChangeOver')}
                    className="text-soft-warning"
                    disabled={!hasPermission}
                    isReset={isSubmited}
                    value={
                        !value
                            || !value.length
                            ? null
                            : value.map(fees => {
                                const { currencyISO, value: vl } = fees;
                                return {
                                    [currencyISO]: vl
                                }
                            })}
                    timeDelay={500}
                    typeOfCheck="higherOfLower"
                />
            </div>
        ) : (
                <div className="input-with-validator">
                    <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                {this.props.auth.selectedFleet.currencies[0].symbol}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="number"
                                min={0}
                                className="form-custom"
                                onChange={e => {
                                    this.handleMultiCurrenciesChange(
                                        key,
                                        this.props.auth.selectedFleet.currencies[0].iso,
                                        e
                                    );
                                }}
                                onBlur={e => {
                                    textboxNumberHelper.onBlurHandle(e, e => {
                                        this.handleMultiCurrenciesChange(
                                            key,
                                            this.props.auth.selectedFleet.currencies[0].iso,
                                            e
                                        );
                                    });
                                }}
                                onFocus={e => {
                                    textboxNumberHelper.onfocusHandle(e, e => {
                                        this.handleMultiCurrenciesChange(
                                            key,
                                            this.props.auth.selectedFleet.currencies[0].iso,
                                            e
                                        );
                                    });
                                }}
                                value={amount}
                                onWheel={e => e.preventDefault()}
                                disabled={!hasPermission}
                            />
                        </InputGroup>
                    </FormGroup>
                    <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={!hasPermission}
                        isReset={isSubmited}
                        value={value && value[0] ? value[0].value : 0}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                    />
                </div>
            );
    };

    render() {
        const {
            formData, valid, isSubmited, promotionCodeList, sourceToSelect
        } = this.state;
        const editable = this.props.permissions && !this.props.permissions.actions;
        const timeZone = this.props.auth.selectedFleet.timezone;
        const { permissions: { actions: hasPermission = false } = {} } = this.props || {};
        const promos = formData.refereeGet.promos || [];

        let promosAvailable = [];
        if (promotionCodeList.length > 0) {
            promosAvailable = promotionCodeList.filter(item => {
                return !promos.find(({ promoId }) => item._id === promoId)
            })
        };

        return (
            <div
                className="content referral-form custom-form overflow-auto"
                style={{ paddingBottom: 140 }}
            >
                <Row>
                    <Col xs={12} md={6} className="pax-to-pax-form">
                        <div className="group-general">
                            <FormGroupTitle className="ph pv0 mb-lg">
                                <Translate value="referral.passenger_refers_to_pax" />
                            </FormGroupTitle>
                            <div className="passenger-field-item">
                                <FormGroup>
                                    <div className="radio-button-group">
                                        <Form.Label>
                                            <Translate value="referral.Status" />
                                            {' '}
                                            {'\u00A0'}
                                            <span className="danger"> *</span>
                                        </Form.Label>
                                        <RadioButton
                                            text={I18n.t('referral.Active')}
                                            value="1"
                                            onChange={e => this.onChangeOption('isActive', e)}
                                            name="isActive"
                                            id="isActive_0"
                                            checked={formData.isActive}
                                            disabled={!hasPermission}
                                        />
                                        <RadioButton
                                            text={I18n.t('referral.Inactive')}
                                            value="0"
                                            onChange={e => this.onChangeOption('isActive', e)}
                                            name="isActive"
                                            id="isActive_1"
                                            checked={!formData.isActive}
                                            disabled={!hasPermission}
                                        />
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="passenger-field-item">
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="referral.referer_will_get" />
                                    </Form.Label>
                                    {this.renderCurrencies('refererGet.money', formData.refererGet.money)}
                                </FormGroup>
                            </div>

                            <div className="passenger-field-item">
                                <FormGroup>
                                    <Form.Label>
                                        <Translate value="referral.referee_will_get" />
                                    </Form.Label>
                                    <select
                                        className="form-control form-custom"
                                        value={formData.refereeGet.type}
                                        onChange={this.handleChangeFefereeWillType}
                                        disabled={!hasPermission}
                                    >
                                        <option value={PassengerGetType.Money}>{I18n.t('referral.amount')}</option>
                                        <option value={PassengerGetType.Promo}>{I18n.t('referral.promo')}</option>
                                    </select>

                                </FormGroup>
                                {formData.refereeGet.type === PassengerGetType.Money ?
                                    (this.renderCurrencies('refereeGet.money', formData.refereeGet.money))
                                    :
                                    <div>
                                        <FormGroup>
                                            <div className="model-list-container">
                                                <table className="table table-no-pagination cc-table-striped">
                                                    <thead>
                                                        <tr className="">
                                                            <th>
                                                                <Translate value="referral.promoCode" />
                                                            </th>
                                                            <th>
                                                                <Translate value="referral.isSendInbox" />
                                                            </th>
                                                            <th>
                                                                <Translate value="referral.isDefaultCode" />
                                                                <QuestionCircleTooltip text={<Translate value="referral.isDefaultCode_hint" />} />
                                                            </th>
                                                            {sourceToSelect && <th>
                                                                <Translate value="referral.autoApplyOnWeb" />
                                                            </th>}
                                                            <th>
                                                                <Translate value="referral.Actions" />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formData.refereeGet.promos
                                                            ? formData.refereeGet.promos.map(m => {
                                                                let promoCode = '';
                                                                if (m.promoId) {
                                                                    let promoData = promotionCodeList.filter(item => item._id === m.promoId);
                                                                    promoCode = `${promoData[0].promotionCode} (${moment(promoData[0].validFrom)
                                                                        .tz(timeZone)
                                                                        .format('DD/MM/YYYY')} - ${moment(promoData[0].validTo)
                                                                            .tz(timeZone)
                                                                            .format('DD/MM/YYYY')})`;
                                                                };
                                                                return (
                                                                    <tr key={m.promoId}>
                                                                        <td>{promoCode}</td>
                                                                        <td>
                                                                            {m.isSendInbox ? (
                                                                                <i className="fa fa-check fa-2x activateIcon" />
                                                                            ) : ''}
                                                                        </td>
                                                                        <td>
                                                                            {m.isDefault ? (
                                                                                <i className="fa fa-check fa-2x activateIcon" />
                                                                            ) : ''}
                                                                        </td>
                                                                        {sourceToSelect && <td>
                                                                            {m.autoApplyOnWeb.enable ?
                                                                                m.autoApplyOnWeb.sourceApply.map(item => <span key={item.sourceId}>{item.key}; </span>)
                                                                                : ''}
                                                                        </td>}
                                                                        <td>
                                                                            {hasPermission ? (
                                                                                <div className="car-model-action">
                                                                                    {formData._id ? (
                                                                                        <i
                                                                                            className="fa fa-edit fa-2x"
                                                                                            onClick={e => {
                                                                                                this.handleClickMenuPromosList('Edit', m);
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                            ""
                                                                                        )}
                                                                                    <i
                                                                                        className="fa fa-trash fa-2x"
                                                                                        onClick={e => {
                                                                                            this.handleClickMenuPromosList('Delete', m);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                    ""
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                            : ""}
                                                    </tbody>
                                                </table>
                                                <ButtonToolbar className="text-center header-button-group">
                                                    <Button
                                                        className={"btn-header text-add-header ml0"}
                                                        onClick={this.handleAddButtonClick}
                                                    >
                                                        {" "}
                                                        <BsPlus />
                                                        <Translate value="referral.add_promoCode" />
                                                    </Button>
                                                </ButtonToolbar>
                                            </div>
                                        </FormGroup>

                                        <FormGroup>
                                            <div className="radio-button-group">
                                                <Form.Label>
                                                    <Translate value="referral.promoPaidBy" />
                                                    {' '}
                                                    {'\u00A0'}
                                                    <span className="danger"> *</span>
                                                </Form.Label>
                                                <RadioButton
                                                    text={I18n.t('referral.fleet')}
                                                    value="0"
                                                    onChange={e => this.onChangePromoPaidByOption(e)}
                                                    name="paidByDriver"
                                                    id="paidByDriver_0"
                                                    checked={!formData.refereeGet.paidByDriver}
                                                    disabled={!hasPermission}
                                                />
                                                <RadioButton
                                                    text={I18n.t('referral.driver')}
                                                    value="1"
                                                    onChange={e => this.onChangePromoPaidByOption(e)}
                                                    name="paidByDriver"
                                                    id="paidByDriver_1"
                                                    checked={formData.refereeGet.paidByDriver}
                                                    disabled={!hasPermission}
                                                />
                                            </div>
                                        </FormGroup>
                                    </div>
                                }
                            </div>

                            <div className="passenger-field-item">
                                <FormGroup className={!isSubmited ? null : valid.fromDate ? null : 'error'}>
                                    <Form.Label>
                                        <Translate value="referral.Valid_from" />
                                    </Form.Label>
                                    <DateTime
                                        className="form-custom"
                                        onChange={e => this.onChangeValidDate('fromDate', e)}
                                        value={formData.fromDate}
                                        isValidDate={this.validDate}
                                        closeOnSelect
                                        viewMode="days"
                                        timeFormat={false}
                                        disabled={!hasPermission}
                                    />
                                    <Validator id="fromDate" callback={this.ValidatorCallback}>
                                        <ValidCase
                                            hide={!isSubmited}
                                            valid={!Validation.isStringEmpty(formData.fromDate)}
                                            message={I18n.t('messages.commonMessages.Required_field')}
                                        />
                                    </Validator>
                                </FormGroup>

                                <FormGroup>
                                    <div className="radio-button-group">
                                        <Form.Label>
                                            <Translate value="referral.Expiration_date" />
                                            {'\u00A0'}
                                            <span className="danger"> *</span>
                                        </Form.Label>
                                        <RadioButton
                                            text={I18n.t('referral.Never')}
                                            value="0"
                                            onChange={e => this.onChangeOption('isExpired', e)}
                                            name="isExpired"
                                            id="isExpired_0"
                                            checked={!formData.isExpired}
                                            disabled={!hasPermission}
                                        />
                                        <RadioButton
                                            text={I18n.t('referral.Customized_date')}
                                            value="1"
                                            onChange={e => this.onChangeOption('isExpired', e)}
                                            name="isExpired"
                                            id="isExpired_1"
                                            checked={formData.isExpired}
                                            disabled={!hasPermission}
                                        />
                                    </div>
                                </FormGroup>
                                {formData.isExpired ? (
                                    <FormGroup className={!isSubmited ? null : valid.expiryDate ? null : 'error'}>
                                        <Form.Label>
                                            <Translate value="referral.Valid_to" />
                                        </Form.Label>
                                        <DateTime
                                            className="form-custom"
                                            onChange={e => this.onChangeValidDate('expiryDate', e)}
                                            value={formData.expiryDate}
                                            isValidDate={this.validDate}
                                            closeOnSelect
                                            viewMode="days"
                                            timeFormat={false}
                                            disabled={!hasPermission}
                                        />
                                        <Validator id="expiryDate" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={!Validation.isStringEmpty(formData.expiryDate)}
                                                message={I18n.t('messages.commonMessages.Required_field')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : (
                                        ''
                                    )}
                            </div>

                            <div className="passenger-field-item">
                                <FormGroup>
                                    <div className="radio-button-group">
                                        <Form.Label>
                                            <Translate value="referral.Pax_invitation_limit" />
                                            {'\u00A0'}
                                            <span className="danger"> *</span>
                                        </Form.Label>
                                        <RadioButton
                                            text={I18n.t('referral.unlimited')}
                                            value="0"
                                            onChange={e => this.onChangeOption('isLimitInvitation', e)}
                                            name="isLimitInvitation"
                                            id="isLimitInvitation_0"
                                            checked={!formData.isLimitInvitation}
                                            disabled={!hasPermission}
                                        />
                                        <RadioButton
                                            text={I18n.t('referral.limited')}
                                            value="1"
                                            onChange={e => this.onChangeOption('isLimitInvitation', e)}
                                            name="isLimitInvitation"
                                            id="isLimitInvitation_1"
                                            checked={formData.isLimitInvitation}
                                            disabled={!hasPermission}
                                        />
                                    </div>
                                </FormGroup>
                                {formData.isLimitInvitation ? (
                                    <FormGroup
                                        className={!isSubmited ? null : valid.isLimitInvitation ? null : 'error'}
                                    >
                                        <FormControl
                                            className="form-custom number-no-spin"
                                            type="number"
                                            min={0}
                                            onChange={e => this.onInputChange('limitedUser', e, {
                                                type: 'number',
                                                min: 0
                                            })
                                            }
                                            value={formData.limitedUser}
                                            onWheel={e => e.preventDefault()}
                                            disabled={!hasPermission}
                                        />
                                        <Validator id="isLimitInvitation" callback={this.ValidatorCallback}>
                                            <ValidCase
                                                hide={!isSubmited}
                                                valid={
                                                    !formData.isLimitInvitation
                                                    || Validation.isGreaterThan(formData.limitedUser, 0)
                                                }
                                                message={I18n.t('messages.commonMessages.greater_than_0')}
                                            />
                                        </Validator>
                                    </FormGroup>
                                ) : (
                                        ''
                                    )}
                            </div>
                            <div className="passenger-note-item">
                                <Form.Label>
                                    <p>(<span className="danger">*</span>) <Translate value="referral.Required_fields" /></p>
                                </Form.Label>
                            </div>
                            <div className="passenger-note-item">
                                <Form.Label>
                                    <Translate value="referral.note" />
                                </Form.Label>
                            </div>

                            <div className="passenger-field-item">
                                {
                                    hasPermission && <ButtonToolbar className="text-center center mt-md">
                                        <Button className="btn-save" onClick={this.handleSaveClick}>
                                            <Translate value="dispatchSetting.Save" />
                                        </Button>
                                    </ButtonToolbar>
                                }
                            </div>

                        </div>

                    </Col>
                </Row>

                {this.state.showDialogPromoCode && this.state.dialogData ? (
                    <AddPromoCodeModal
                        dialogData={this.state.dialogData}
                        promosAvailable={promosAvailable}
                        promotionCodeList={promotionCodeList}
                        selectedFleet={this.props.auth.selectedFleet}
                        hasPermission={hasPermission}
                        editable={this.state.editable}
                        dialogChanged={this.state.dialogChanged}
                        sourceToSelect={this.state.sourceToSelect}
                        sourceUsed={this.state.sourceUsed}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        validatorCallback={this.ValidatorCallback}
                        closeDialogForm={this.handleCloseDialogForm}
                        handleSelectPromoCode={this.handleSelectPromoCode}
                        handleSaveDialogForm={this.handleSaveDialogForm}
                        handleClickMenuPromosList={this.handleClickMenuPromosList}
                        handleChangePrompCodeOption={this.handleChangePrompCodeOption}
                        handleChangeSourceApply={this.handleChangeSourceApply}
                    />
                ) : null}

                {this.state.showConfirmToDelete ? (
                    <Modal onHide={this.handleCloseDialogForm} show={true} backdrop={true} className={"confirm"}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <Translate value="referral.DELETE_PROMO_CODE" />
                            </Modal.Title>
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={this.handleCloseDialogForm}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Translate value="referral.DELETE_CONFIRM_PROMO_CODE" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className={"btn-save mr-md"}
                                onClick={this.handleClickDeletePromoCode}
                            >
                                <Translate value="referral.Yes" />
                            </Button>
                            <Button className={"btn-cancel"} onClick={this.handleCloseDialogForm}>
                                <Translate value="referral.No" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) : null}
            </div>
        )
    }
}

PassengerRefersPax.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        users: state.users,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PassengerRefersPax)