import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { importCustomers } from "../../actions/customerAction";
import { Translate, I18n } from "react-redux-i18n";
import { bindActionCreators } from "redux";
import * as loadingBarActions from "./../../actions/loadingBarActions";

import {
  Modal,
  FormGroup,
  Form,
  Button
} from "react-bootstrap";

import UploadFile from '../../components/upload/uploadSendMessage';

const FILE_ACCEPTED = ".xls, .xlsx";
const MAX_FILE_SIZE = 3;
class Import extends React.Component {
  constructor() {
    super();
    this.state = {
      phones: [],
      fileName: ''
    };
  }

  handleSaveClick = () => {
    if (this.state.phones.length == 0) {
      this.context.notification(
        "error",
        I18n.t("errors.22005"),
      );
      this.props.handleImportCustomerClick({
        phones: [],
        fileName: ''
      })
      return
    }
    this.props.handleImportCustomerClick({
      phones: this.state.phones,
      fileName: this.state.fileName,
      file: this.state.file
    })
    this.closeDialog();
  }

  closeDialog = () => {
    this.setState({
      importSuccess: false,
      total: 0,
      ignored: 0,
      created: 0
    });
    this.props.closeDialog();
  }

  deleteFileImport = () => {
    this.setState({ phones: [], fileName: '' })
  }

  handleFileChange = (data) => {
    if (data) {
      this.setState({
        phones: data.phones,
        fileName: data.fileName,
        file: data.file
      })
      return
    }

    this.setState({
      phones: [],
      fileName: ""
    })
  }


  render() {
    let { appType, templeteLink } = this.props
    if (this.props.isShow) {
      return (
        <Modal
          show={true}
          onHide={this.closeDialog}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="card-dialog"
          className="confirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {appType == "driver"
                ? <Translate style={{ textTransform: "lowercase" }} value="driver.ADD_DRIVER" />
                : appType == "passenger" ? <Translate value="customer.ADD_CUSTOMER" />
                  : <Translate value="merchant.ADD_MERCHANT" />}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix text">
            {this.state.importSuccess ? (
              <FormGroup>
                <Translate value="customer.Number_successful_imports" />{" "}
                <b>{this.state.created}</b>
                <br />
                <Translate value="customer.Number_skipped" />{" "}
                <b>{this.state.ignored}</b>
              </FormGroup>
            ) : (
              <FormGroup className="mb0">
                <div className="body-import">
                  <Form.Label>
                    <Translate value="customer.File" />
                  </Form.Label>
                  <a href={templeteLink || `${process.env.REACT_APP_S3_SERVER}/templates/Recipient_Import_Template.xlsx`} className="linkTemplate"><Translate value="customer.Download_template" /></a>
                </div>
                <UploadFile
                  id="customer_import_file"
                  name={"customer_import_file"}
                  handleFileChange={this.handleFileChange}
                  accepts={FILE_ACCEPTED}
                  maxRows={10000}
                  loadingActions={this.props.loadingBarActions}
                />
                <p className="noteSize">The import file must be less than 10,000 rows</p>
              </FormGroup>
            )}
          </Modal.Body>
          {this.state.importSuccess ? null : (
            <Modal.Footer>
              <div className="">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <Button
                    className="btn-save mr-md"
                    onClick={this.handleSaveClick}
                  >
                    <Translate value="customer.Save" />
                  </Button>
                ) : null}
                <Button className="btn-cancel" onClick={this.closeDialog}>
                  <Translate value="customer.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return null;
    }
  }
}


Import.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    importCustomers: options => {
      return dispatch(importCustomers(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Import);
