import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Modal, FormGroup, Form, Button, FormControl
} from 'react-bootstrap';
import LoadingSpiner from '../../../components/loadingSpiner/LoadingSpiner';

class SmsVerifyModal extends PureComponent {
  constructor() {
    super();
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isShow && nextProps.isShow !== this.props.isShow) {
      this.setState({ data3ds: '' });
    }
  }

  handleVerficationCodeChange = e => {
    this.setState({ data3ds: e.target.value });
  };

  handleSubmitVerificatioCode = () => {
    const { onSubmit } = this.props;
    const { data3ds } = this.state;
    onSubmit({ data3ds });
  };

  render() {
    return (
      <Modal
        show={this.props.isShow}
        onHide={this.props.closeDialog}
        backdrop="static"
        aria-labelledby="contained-modal-title-sm"
        dialogClassName="card-dialog"
        className="confirm"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="customer.Sms_verification" style={{ textTransform: 'none' }} />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeDialog}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="clearfix text">
          <FormGroup className="mb0">
            <Form.Label>
              <Translate value="customer.Sms_verification" />
            </Form.Label>
            <FormControl
              type="text"
              value={this.state.data3ds}
              onChange={this.handleVerficationCodeChange}
              className="form-custom"
              placeholder={I18n.t('customer.Sms_verification_placeholder')}
            />
          </FormGroup>

          <LoadingSpiner show={this.props.isLoading} />
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <Button
              className="btn-save mr-md"
              onClick={this.handleSubmitVerificatioCode}
              disabled={!this.state.data3ds}
            >
              <Translate value="customer.Save" />
            </Button>
            <Button className="btn-cancel" onClick={this.props.closeDialog}>
              <Translate value="customer.Cancel" />
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

SmsVerifyModal.propTypes = {
  closeDialog: PropTypes.func,
  isShow: PropTypes.bool
};
export default SmsVerifyModal;
