import {
  fetchIntercityZonesApi,
  updateIntercityZoneApi,
  activeIntercityZoneApi,
  createIntercityZoneApi,
  deactiveIntercityZoneApi,
  deleteIntercityZoneApi,
  importIntercityZoneApi,
} from '../constants/ApiConfigs';
import { callApi } from '../utils/apiUtils';

export const FLEET_INFO_REQUEST = 'FLEET_INFO_REQUEST';
export const FLEET_INFO_SUCCESS = 'FLEET_INFO_SUCCESS';
export const FLEET_INFO_FAILURE = 'FLEET_INFO_FAILURE';

// Zone setting
export function getAllMapZone(fleetId) {
  const config = {
    method: 'get'
  };

  return callApi(fetchIntercityZonesApi + fleetId, config, null, null, null, true);
}

export function createZone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(createIntercityZoneApi, config, null, null, null, true);
}
export function updateZone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(updateIntercityZoneApi, config, null, null, null, true);
}

export function activeZone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(activeIntercityZoneApi, config, null, null, null, true);
}

export function deactiveZone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(deactiveIntercityZoneApi, config, null, null, null, true);
}
export function deleteZone(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(deleteIntercityZoneApi, config, null, null, null, true);
}

export function fetchAllMapZone(fleetId) {
  const config = {
    method: 'get'
  };

  return callApi(fetchIntercityZonesApi + fleetId, config, null, null, null, true);
}

export function importMapZone(options, hasImportFile) {
  const config = {
    method: 'POST',
    body: hasImportFile ? options : JSON.stringify(options),
    isFormData: hasImportFile
  };

  return callApi(importIntercityZoneApi, config, null, null, null, true);
}
