import _ from 'lodash';
import { SERVICE_TYPE } from "../../constants/commondata"

export const tranformAdditionalServicesBeforeSubmit = (services, serviceForm, currencyISO) => {
  return services.map(service => {
    const fee = service.serviceFeeByCurrencies.find(o => o.currencyISO === currencyISO)
    return {
      serviceId: service._id,
      name: service.serviceName,
      fee: _.get(fee, 'value', 0),
      type: service.serviceType,
      active: service.serviceType === SERVICE_TYPE.Compulsory || !!serviceForm[service._id]
    }
  })
}