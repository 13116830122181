import moment from "moment-timezone";
import React from 'react';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {MM_DD_YYYY, MM_YYYY, VIEW_MODES} from "../../constants/commondata";
import {validDate} from '../../utils/chartUtils';
import Datetime from "../dateTime/DateTime";


const Calender = ({auth, criteria, language, isLoading, dateChangeHandle, getDateValue}) => {
  const {timezone = 'BST'} = auth.selectedFleet || {};
  const localeCode = 'vi';

  return (
    <div className='calendar-inner'>
      <div className='calendar-select'>
        <Datetime
          viewMode={criteria.viewMode === VIEW_MODES.month ? 'months' : 'days'}
          className='mr-r-20'
          timeFormat={false}
          onChange={dateChangeHandle}
          closeOnSelect
          isValidDate={validDate}
          dateFormat={
            criteria.viewMode === VIEW_MODES.month ? MM_YYYY : MM_DD_YYYY
          }
          value={moment(criteria.date).tz(timezone).locale(language)}
          inputProps={{
            placeholder: MM_DD_YYYY,
            value: getDateValue(localeCode),
            readOnly: true,
          }}
          disabled={isLoading}
        />
        {auth?.selectedFleet?.currencies.length > 1 ? (
          <DropdownButton
            className='curency-format'
            title={criteria?.currency?.iso}
            disabled={isLoading}
          >
            {auth?.selectedFleet?.currencies.map((cr) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    this.currencyChangeHandle(cr);
                  }}
                  eventKey={cr.iso}
                >
                  {cr.iso}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        ) : null}
      </div>
    </div>
  )
};

export default Calender;
