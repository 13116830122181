export const MessageColumns = [
  {
    key: "table-selectbox",
    label: "",
    width: 60,
    fixed: true,
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
  },
  {
    key: "createdDate",
    label: "message.Date",
    fixed: true
  },
  {
    key: "subject",
    label: "message.Subject",
    width: 400,
    textEllipsis: true
  },
  {
    key: "to",
    label: "message.To",
    textEllipsis: true,
    width: 300
  },
  {
    key: "type",
    label: "message.Type"
  },
  {
    key: "expiryDate",
    label: "message.expiryDate"
  },
  {
    key: "actions",
    label: "message.Actions",
    width: 100,
    align: "right"
  }
];
