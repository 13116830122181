export const NEW_CALL_SUPPORT = "NEW_CALL_SUPPORT";
export const CALL_SUPPORT_ITEM_REMOVE = "CALL_SUPPORT_ITEM_REMOVE";

export function addNewCallSupport(data){
    return {
        type:NEW_CALL_SUPPORT,
        data:data
    }
}

export function removeCallSupport(data){
    return {
        type:CALL_SUPPORT_ITEM_REMOVE,
        data:data
    }
}
