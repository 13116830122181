import React,{Component} from 'react';
import './validator.scss'
 export default class ValidCase extends Component{
    render(){
        if(!this.props.valid && !this.props.hide){
            return(
                <span className='message-item'>
                    {this.props.message}
                </span>
            )
        }else{
            return null;
        }
        
    }
 }