import ReportUtils from './../base/utils'

export const fields = [{
  key: "date",
  label: "report.result.revenueDriver.date",
  summary: "date",
  mutate: value => ReportUtils.formatTime(value, {}, 'ddd MM/DD/YYYY')
}, {
  key: "driverName",
  label: "report.result.revenueDriver.driverName",
  summary: "driverName"
}, {
  key: "fareRevenue",
  label: "report.result.revenueDriver.fareRevenue",
  summary: "fareRevenue"
}, {
  key: "trip",
  label: "report.result.revenueDriver.trip",
  summary: "trip"
}, {
  key: "avgFareTrip",
  label: "report.result.revenueDriver.avgFareTrip",
  summary: "avgFareTrip"
}]

export const fareFields = [
  "fareRevenue",
  "avgFareTrip"
]
