import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { formatMMDDYYYY } from '../../utils/commonFunctions';


const assign = require('object-assign');
const PropTypes = require('prop-types');
const createClass = require('create-react-class');
const moment = require('moment');
const React = require('react');
const CalendarContainer = require('./src/CalendarContainer');

const TYPES = PropTypes;
const Datetime = createClass({
  propTypes: {
    value: TYPES.object | TYPES.string,
    // defaultValue: TYPES.object | TYPES.string,
    onFocus: TYPES.func,
    onBlur: TYPES.func,
    onChange: TYPES.func,
    locale: TYPES.string,
    utc: TYPES.bool,
    input: TYPES.bool,
    // dateFormat: TYPES.string | TYPES.bool,
    // timeFormat: TYPES.string | TYPES.bool,
    inputProps: TYPES.object,
    timeConstraints: TYPES.object,
    viewMode: TYPES.oneOf(['years', 'months', 'days', 'time']),
    isValidDate: TYPES.func,
    open: TYPES.bool,
    strictParsing: TYPES.bool,
    closeOnSelect: TYPES.bool,
    closeOnTab: TYPES.bool,
    nowButton: TYPES.bool
  },

  getDefaultProps() {
    const nof = function () { };
    return {
      className: '',
      defaultValue: '',
      inputProps: {},
      input: true,
      onFocus: nof,
      onBlur: nof,
      onChange: nof,
      timeFormat: true,
      timeConstraints: {},
      dateFormat: true,
      strictParsing: true,
      closeOnSelect: false,
      closeOnTab: true,
      utc: false,
      nowButton: false
    };
  },

  getInitialState() {
    const state = this.getStateFromProps(this.props);

    if (state.open === undefined) state.open = !this.props.input;

    state.currentView = this.props.dateFormat
      ? this.props.viewMode || state.updateOn || 'days'
      : 'days';

    return state;
  },

  getStateFromProps(props) {
    const formats = this.getFormats(props);
    const date = props.value || props.defaultValue;
    let selectedDate;
    let viewDate;
    let updateOn;
    let inputValue;

    if (date && typeof date === 'string') {
      if (date == 'Now') {
        inputValue = 'Now';
      } else {
        selectedDate = this.localMoment(date, formats.datetime);
      }
    } else if (date) selectedDate = this.localMoment(date);

    if (selectedDate && !selectedDate.isValid()) selectedDate = null;

    viewDate = selectedDate
      ? selectedDate.clone().startOf('month')
      : date == 'Now'
        ? this.localMoment(new Date())
        : this.localMoment().startOf('month');

    updateOn = this.getUpdateOn(formats);

    if (selectedDate) inputValue = selectedDate.format(formats.datetime);
    else if (date.isValid && !date.isValid()) inputValue = '';
    else inputValue = (date == 'Now' ? 'Now' : date) || '';

    return {
      updateOn,
      inputFormat: formats.datetime,
      viewDate,
      selectedDate,
      inputValue,
      open: props.open
    };
  },

  getUpdateOn(formats) {
    if (formats.date.match(/[lLD]/)) {
      return 'days';
    } if (formats.date.indexOf('M') !== -1) {
      return 'months';
    } if (formats.date.indexOf('Y') !== -1) {
      return 'years';
    }

    return 'days';
  },

  getFormats(props) {
    const formats = {
      date: props.dateFormat || '',
      time: props.timeFormat || ''
    };
    const locale = this.localMoment(props.date, null, props).localeData();
    if (formats.date === true) {
      formats.date = locale.longDateFormat('L');
    } else if (this.getUpdateOn(formats) !== 'days') {
      formats.time = '';
    }

    if (formats.time === true) {
      formats.time = locale.longDateFormat('LT');
    }

    formats.datetime = formats.date && formats.time
      ? `${formats.date} ${formats.time}`
      : formats.date || formats.time;

    return formats;
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const formats = this.getFormats(nextProps);
    let updatedState = {};
    const oldValue = this.props.value && this.props.value.format ? this.props.value.format() : this.props.value;
    const newValue = nextProps.value && nextProps.value.format ? nextProps.value.format() : nextProps.value;
    if (
      oldValue !== newValue
      || formats.datetime !== this.getFormats(this.props).datetime
    ) {
      updatedState = this.getStateFromProps(nextProps);
    }

    if (updatedState.open === undefined) {
      if (this.props.closeOnSelect && this.state.currentView !== 'time') {
        const curentValue = this.props.value ? moment(this.props.value) : moment();
        const nextValue = nextProps.value ? moment(nextProps.value) : moment();
        if (this.state.currentView === 'days') {
          const diffValueMinutes = nextValue.diff(curentValue, 'minutes');
          if (diffValueMinutes !== 0) updatedState.open = false;
          else updatedState.open = this.state.open;
        }
        else
          updatedState.open = false;
      } else {
        updatedState.open = this.state.open;
      }
    }

    if (nextProps.viewMode !== this.props.viewMode) {
      updatedState.currentView = nextProps.viewMode;
    }

    if (nextProps.locale !== this.props.locale) {
      if (this.state.viewDate) {
        const updatedViewDate = this.state.viewDate.clone().locale(nextProps.locale);
        updatedState.viewDate = updatedViewDate;
      }
      if (this.state.selectedDate) {
        const updatedSelectedDate = this.state.selectedDate.clone().locale(nextProps.locale);
        updatedState.selectedDate = updatedSelectedDate;
        updatedState.inputValue = updatedSelectedDate.format(formats.datetime);
      }
    }

    if (nextProps.utc !== this.props.utc) {
      if (nextProps.utc) {
        if (this.state.viewDate) updatedState.viewDate = this.state.viewDate.clone().utc();
        if (this.state.selectedDate) {
          updatedState.selectedDate = this.state.selectedDate.clone().utc();
          updatedState.inputValue = updatedState.selectedDate.format(formats.datetime);
        }
      } else {
        if (this.state.viewDate) updatedState.viewDate = this.state.viewDate.clone().local();
        if (this.state.selectedDate) {
          updatedState.selectedDate = this.state.selectedDate.clone().local();
          updatedState.inputValue = updatedState.selectedDate.format(formats.datetime);
        }
      }
    }

    this.setState(updatedState);
  },

  onInputChange(e) {
    if (e == 'Now') {
      return this.props.onChange(e);
    }
    const value = e.target === null
      ? e
      : this.state.inputValue.length >= e.target.value.length
        ? e.target.value
        : formatMMDDYYYY(e.target.value);
    const localMoment = this.localMoment(value, this.state.inputFormat);
    const update = { inputValue: value };
    if (localMoment.isValid() && !this.props.value) {
      update.selectedDate = localMoment;
      update.viewDate = localMoment.clone().startOf('month');
    } else {
      update.selectedDate = null;
    }

    return this.setState(update, function () {
      return this.props.onChange(localMoment.isValid() ? localMoment : this.state.inputValue);
    });
  },

  onInputKey(e) {
    if (e.which === 9 && this.props.closeOnTab) {
      this.closeCalendar();
    }
  },

  showView(view) {
    const me = this;
    return function () {
      me.setState({ currentView: view });
    };
  },

  setDate(type) {
    const me = this;
    const nextViews = {
      month: 'days',
      year: 'months'
    };
    return function (e) {
      me.setState({
        viewDate: me.state.viewDate
          .clone()
        [type](parseInt(e.target.getAttribute('data-value'), 10))
          .startOf(type),
        currentView: nextViews[type]
      });
    };
  },

  addTime(amount, type, toSelected) {
    return this.updateTime('add', amount, type, toSelected);
  },

  subtractTime(amount, type, toSelected) {
    return this.updateTime('subtract', amount, type, toSelected);
  },

  updateTime(op, amount, type, toSelected) {
    const me = this;

    return function () {
      const update = {};
      const date = toSelected ? 'selectedDate' : 'viewDate';
      update[date] = me.state[date].clone()[op](amount, type);

      me.setState(update);
    };
  },

  allowedSetTime: ['hours', 'minutes', 'seconds', 'milliseconds'],
  setTime(type, value) {
    let index = this.allowedSetTime.indexOf(type) + 1;
    const { state } = this;
    const date = (state.selectedDate || state.viewDate).clone();
    let nextType;

    // It is needed to set all the time properties
    // to not to reset the time
    date[type](value);
    for (; index < this.allowedSetTime.length; index++) {
      nextType = this.allowedSetTime[index];
      date[nextType](date[nextType]());
    }

    if (!this.props.value) {
      this.setState({
        selectedDate: date,
        inputValue: date.format(state.inputFormat)
      });
    }
    this.props.onChange(date);
  },

  updateSelectedDate(e, close) {
    const { target } = e;
    let modifier = 0;
    const { viewDate } = this.state;

    const checkValidDateTime = (val) => {
      return moment(val).isValid() ? val : null;
    }
  
    const currentDate = checkValidDateTime(this.state.selectedDate) || checkValidDateTime(viewDate) || moment();
    let date;

    if (target.className.indexOf('rdtDay') !== -1) {
      if (target.className.indexOf('rdtNew') !== -1) modifier = 1;
      else if (target.className.indexOf('rdtOld') !== -1) modifier = -1;

      date = viewDate
        .clone()
        .month(viewDate.month() + modifier)
        .date(parseInt(target.getAttribute('data-value'), 10));
    } else if (target.className.indexOf('rdtMonth') !== -1) {
      date = viewDate
        .clone()
        .month(parseInt(target.getAttribute('data-value'), 10));
      /* .date(currentDate.date()); */ /* This make monthly selector look like wrong */
    } else if (target.className.indexOf('rdtYear') !== -1) {
      date = viewDate
        .clone()
        .month(currentDate.month())
        .date(currentDate.date())
        .year(parseInt(target.getAttribute('data-value'), 10));
    }
    if(currentDate) {
      date
        .hours(currentDate.hours())
        .minutes(currentDate.minutes())
        .seconds(currentDate.seconds())
        .milliseconds(currentDate.milliseconds());
    }

    if (!this.props.value) {
      const open = !(this.props.closeOnSelect && close);
      if (!open) {
        this.props.onBlur(date);
      }

      this.setState({
        selectedDate: date,
        viewDate: date.clone().startOf('month'),
        inputValue: date.format(this.state.inputFormat),
        open
      });
    } else if (this.props.closeOnSelect && close) {
      this.closeCalendar();
    }

    this.props.onChange(date);
  },

  openCalendar(e) {
    if (!this.state.open) {
      if (!this.props.checkShowOnTop || !e || !e.target) {
        this.setState({ open: true }, function () {
          this.props.onFocus();
        });
      } else {
        const domRect = e.target.getBoundingClientRect();
        const pickerHeight = this.state.currentView === 'days' ? 234 : 190;
        const bottomExpectHeight = pickerHeight + (this.props.parrentMarginBottom || 0);
        const showOnTop = domRect
          ? window.innerHeight - (domRect.top + domRect.height) < bottomExpectHeight
          : this.state.showOnTop;
        this.setState({ open: true, showOnTop }, function () {
          this.props.onFocus();
        });
      }
    }
  },

  closeCalendar() {
    this.setState({ open: false }, function () {
      this.props.onBlur(this.state.selectedDate || this.state.inputValue);
    });
  },

  handleClickOutside() {
    if (this.props.input && this.state.open && !this.props.open) {
      this.setState({ open: false }, function () {
        this.props.onBlur(this.state.selectedDate || this.state.inputValue);
      });
    }
  },
  btnNowClickHandle() {
    this.setState({ inputValue: I18n.t('General.Now') });
    this.onInputChange('Now');
  },
  localMoment(date, format, props) {
    props = props || this.props;
    const momentFn = props.utc ? moment.utc : moment;
    const m = momentFn(date, format, props.strictParsing);
    //if (props.locale) m.locale(props.locale);
    return m;
  },

  componentProps: {
    fromProps: [
      'value',
      'isValidDate',
      'renderDay',
      'renderMonth',
      'renderYear',
      'timeConstraints'
    ],
    fromState: ['viewDate', 'selectedDate', 'updateOn'],
    fromThis: [
      'setDate',
      'setTime',
      'showView',
      'addTime',
      'subtractTime',
      'updateSelectedDate',
      'localMoment',
      'handleClickOutside'
    ]
  },

  getComponentProps() {
    const me = this;
    const formats = this.getFormats(this.props);
    const props = { dateFormat: formats.date, timeFormat: formats.time };
    this.componentProps.fromProps.forEach((name) => {
      props[name] = me.props[name];
    });
    this.componentProps.fromState.forEach((name) => {
      props[name] = me.state[name];
    });
    this.componentProps.fromThis.forEach((name) => {
      props[name] = me[name];
    });

    if (props.viewDate._locale) {
      let { language = 'en-gb' } = this.props || {};
      if (language === 'en-US') language = 'en-gb';

      const localeData = moment.localeData("vi");
      const week = localeData._week;
      const localeDatabyLangugage = moment.localeData(language);
      const localeDateDefault = moment.localeData('en');

      const viewDateLocale = props.viewDate._locale;
      viewDateLocale._months = localeDatabyLangugage._months || localeDateDefault._moths;
      viewDateLocale._monthsShort = localeDatabyLangugage._monthsShort || localeDateDefault._monthsShort;
      viewDateLocale._monthsParseExact = true;
      viewDateLocale._weekdays = localeDatabyLangugage._weekdays || localeDateDefault._weekdays;
      viewDateLocale._weekdaysShort = localeDatabyLangugage._weekdaysShort || localeDateDefault._weekdaysShort;
      viewDateLocale._weekdaysMin = localeDatabyLangugage._weekdaysMin || localeDateDefault._weekdaysMin;
      viewDateLocale._weekdaysParseExact = true;
      viewDateLocale._longDateFormat = localeDatabyLangugage._longDateFormat || localeDateDefault._longDateFormat;
      viewDateLocale._calendar = localeDatabyLangugage._calendar || localeDateDefault._calendar;
      viewDateLocale._relativeTime = localeDatabyLangugage._relativeTime || localeDateDefault._relativeTime;
      viewDateLocale._dayOfMonthOrdinalParse = localeDatabyLangugage._dayOfMonthOrdinalParse || localeDateDefault._dayOfMonthOrdinalParse;
      viewDateLocale._ordinal = localeDatabyLangugage.ordinal || localeDateDefault.ordinal;
      viewDateLocale._meridiemParse = localeDatabyLangugage._meridiemParse || localeDateDefault._meridiemParse;
      viewDateLocale._isPM = localeDatabyLangugage.isPM || localeDateDefault.isPM;
      viewDateLocale._meridiem = localeDatabyLangugage.meridiem || localeDateDefault.meridiem;
      viewDateLocale._meridiemHour = localeDatabyLangugage.meridiemHour || localeDateDefault.meridiemHour;
      viewDateLocale._week = week;
    }
    return props;
  },

  render() {
    // TODO: Make a function or clean up this code,
    // logic right now is really hard to follow
    let className = `rdt${
      this.props.className
        ? Array.isArray(this.props.className)
          ? ` ${this.props.className.join(' ')}`
          : ` ${this.props.className}`
        : ''}`;
    let children = [];

    if (this.props.input) {
      const finalInputProps = assign(
        {
          type: 'text',
          placeholder: this.props.placeholder || 'mm/dd/yy',
          className: 'form-control form-custom pickup-time',
          onClick: this.openCalendar,
          onFocus: this.openCalendar,
          onChange: this.onInputChange,
          onKeyDown: this.onInputKey,
          value: this.state.inputValue
        },
        this.props.inputProps
      );
      if (this.props.renderInput) {
        children = [
          React.createElement(
            'div',
            { key: 'i' },
            this.props.renderInput(finalInputProps, this.openCalendar)
          )
        ];
      } else {
        children = [React.createElement('input', assign({ key: 'i' }, finalInputProps))];
      }
    } else {
      className += ' rdtStatic';
    }

    if (this.props.checkShowOnTop && this.state.showOnTop) {
      className += ' rdtShowOnTop';
    }

    if (this.props.nowButton) {
      children = children.concat(
        React.createElement(
          'input',
          assign({
            key: 'btn',
            type: 'button',
            className: 'btn-now',
            onClick: this.btnNowClickHandle,
            value: I18n.t('General.Now'),
            disabled: this.props.inputProps.disabled
          })
        )
      );
    }

    if (this.state.open) className += ' rdtOpen';



    return React.createElement(
      'div',
      { className },
      children.concat(
        React.createElement(
          'div',
          { key: 'dt', className: 'rdtPicker' },
          React.createElement(CalendarContainer, {
            view: this.state.currentView,
            viewProps: this.getComponentProps(),
            onClickOutside: this.handleClickOutside
          })
        )
      )
    );
  }
});

// Make moment accessible through the Datetime class
Datetime.moment = moment;

function mapStateToProps(state) {
  const { localeCode: locale = 'en' } = state.auth.selectedFleet || {};
  return {
    locale,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US'
  };
}

export default connect(
  mapStateToProps,
  null
)(Datetime);
