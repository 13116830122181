import { CCLiteCommonFunc } from '../../../utils/commonFunctions'


function isBookingDetailFromAirPort() {
    if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
        if (this.state.data.request.type == 1) {
            return true;
        }
        return false;
    }
    return this.state.locationPickUp
        && this.state.locationPickUp.address
        && this.state.locationPickUp.address.length > 0
        && this.props.newBooking
        && this.props.newBooking.locationService
        && this.props.newBooking.locationService.additionalService
        && this.props.newBooking.locationService.additionalService.fromAirport
        && this.props.newBooking.locationService.additionalService.fromAirport
        && this.props.newBooking.locationService.additionalService.fromAirport.isActive
        && ((this.props.newBooking.locationService.fromAirport
            && this.props.newBooking.locationService.fromAirport._id) || (this.props.newBooking.locationService.airport && this.props.newBooking.locationService.airport[0]))
}

function isBookingDetailToAirPort() {
    if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
        if (this.state.data.request.type == 2) {
            return true;
        }
        return false;
    }
    return this.state.locationDestination
        && this.state.locationDestination.address
        && this.state.locationDestination.address.length > 0
        && this.props.newBooking
        && this.props.newBooking.locationService
        && this.props.newBooking.locationService.additionalService
        && this.props.newBooking.locationService.additionalService.toAirportNew
        && this.props.newBooking.locationService.additionalService.toAirportNew
        && this.props.newBooking.locationService.additionalService.toAirportNew.isActive
        && ((this.props.newBooking.locationService.toAirport
            && this.props.newBooking.locationService.toAirport._id) || (this.props.newBooking.locationService.airport && this.props.newBooking.locationService.airport[1]))
}

function isBookingDetailHaveExtraAirPort() {
    if (CCLiteCommonFunc.isBookingStatusCompleted(this.state.data.status)) {
        if (this.state.data.request.type == 2) {
            return true;
        }
        return false;
    }
    return (
        (this.state.locationDestination
            && this.state.locationDestination.address
            && this.state.locationDestination.address.length > 0)
        && (
            this.state.extraDestination
            && this.state.extraDestination.address
            && this.state.extraDestination.address.length > 0
        )
    )
        && this.props.newBooking
        && this.props.newBooking.locationService
        && this.props.newBooking.locationService.additionalService
        && (
            (this.props.newBooking.locationService.additionalService.toAirportNew
                && this.props.newBooking.locationService.additionalService.toAirportNew.isActive)
            ||
            (this.props.newBooking.locationService.additionalService.fromAirport
                && this.props.newBooking.locationService.additionalService.fromAirport.isActive)
        )
        && (this.props.newBooking.locationService.extraAirport && this.props.newBooking.locationService.extraAirport[0]
            && this.props.newBooking.locationService.extraAirport[0]._id)
}

export {
    isBookingDetailFromAirPort,
    isBookingDetailToAirPort,
    isBookingDetailHaveExtraAirPort
}