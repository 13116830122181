import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const Ovh = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.appKey" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.appKey || ''}
          onChange={(e) => {
            props.handleInputChange('appKey', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.appSecret" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.appSecret || ''}
          onChange={(e) => {
            props.handleInputChange('appSecret', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.consumerKey" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.consumerKey || ''}
          onChange={(e) => {
            props.handleInputChange('consumerKey', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.sender" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.sender || ''}
          onChange={(e) => {
            props.handleInputChange('sender', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.requestUrl" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.requestUrl || ''}
          onChange={(e) => {
            props.handleInputChange('requestUrl', e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default Ovh;
