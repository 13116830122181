import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  InputGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
  Modal,
  Tabs,
  Alert,
  Pagination,
  ButtonGroup,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import formatCurrency from 'currency-formatter';

import Select from '../../../components/select/Select';
import Confirm from '../../../components/confirm/Confirm';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import Datetime from '../../../components/dateTime/DateTime';
import { Validator, ValidCase } from '../../../components/validator';
import {
  CCLiteCommonFunc, Validation,
  trimPhoneNumber,
  MultiSelectCommonFunc
} from '../../../utils/commonFunctions';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import * as customerAutocompleteAction from '../../../actions/customerAutocompleteAction';
import * as corporateActions from '../../../actions/corporateAction';
import * as paymentMethodActions from '../../../actions/paymentMethodActions';
import * as uploadActions from '../../../actions/uploadActions';

import {
  PromotionAmountTypeFilter,
  PromotionStatusFilter,
  PromotionTypeFilter,
  PromotionType,
  PromotionAmountType
} from '../../../constants/commondata';
import './promotionCode.scss';
import Import from './Import';
import ImportCustomer from './ImportCustomer';
import { PromoCodeColumns as Columns } from './tableHeaderColumn';
import TableActions from '../../../components/table/tableAction/TableActions';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import RadioButton from '../../../components/radioButton/radio';
import LimitSettingFormItem from './LimitSettingFormItem';
import { th } from 'react-dom-factories';

import AddEditPromo from './AddEdit';
import { BsSearch } from 'react-icons/bs';

const CONFIRM_DELETE_ID = 1;
const CONFIRM_SEND_INBOX_ID = 2;
let sendInBoxId;

const CustomerType = {
  All: 0,
  NewCustomer: 1,
  ReferredCustomers: 2,
  CorporateCustomers: 3
};

class PromotionCode extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      promotionCodeList: {
        list: [],
        page: 0,
        limit: 20,
        total: 1
      },
      dialogData: {
        budgetLimit: {
          isLimited: false
        },
        budgetLimitDay: {
          isLimited: false,
          value: 0
        },
        budgetLimitMonth: {
          isLimited: false,
          value: 0
        },
        budgetLimitYear: {
          isLimited: false,
          value: 0
        },
        quantity: {
          isLimited: false
        },
        totalUsesLimit: {
          isLimited: false
        },
        totalUsesLimitPerDay: {
          isLimited: false
        },
        quantityLimitPerDay: {
          isLimited: false
        },
        quantityLimitPerMonth: {
          isLimited: false
        },
        campaign: {
          _id: ''
        },
        promotionCode: '',
        zoneId: [],
        promoStatus: PromotionType.Public,
        corporateList: []
      },
      campaignList: [],
      receiverList: [],
      campaignQuery: {
        list: [],
        page: 0,
        limit: 20,
        total: 0,
        campaignSelected: null,
        hasMore: true
      },
      filterQuery: {
        promotionType: ['public', 'private'],
        promotionAmountType: ["all", "Amount", "Percent"],
        promotionStatus: ["all", "active", "inactive"],
      },
      disable: false,
      isEditable: false,
      searchKey: '',
      isImportFile: false,
      showConfirm: false,
      tableHeight: 500,
      rowHeight: 50,
      phonesImportExcel: [],
      fileName: '',
      isShowImport: false,
      isDetailForm: false,
      imageUpload: {},
    };

    this.searchCustomerDebounce = _.debounce(this.searchCustomer, 500);

    this.updatePromotionCodeList = this.updatePromotionCodeList.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleUpdateStateClick = this.handleUpdateStateClick.bind(this);
    this.openFormModal = this.openFormModal.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleClickEditButton = this.handleClickEditButton.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePromotionCodeChange = this.handlePromotionCodeChange.bind(this);
    this.handleChangeService = this.handleChangeService.bind(this);
    this.handleLoadOption = this.handleLoadOption.bind(this);
    this.handlePromotionTypeChange = this.handlePromotionTypeChange.bind(this);
    this.handleCustomizedChange = this.handleCustomizedChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePercentValueChange = this.handlePercentValueChange.bind(this);
    this.handleValidFromChanged = this.handleValidFromChanged.bind(this);
    this.handleValidToChanged = this.handleValidToChanged.bind(this);
    this.handleBudgetLimitChange = this.handleBudgetLimitChange.bind(this);
    this.getCampaignList = this.getCampaignList.bind(this);
    this.handleBudgetValueChange = this.handleBudgetValueChange.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleCampaignChange = this.handleCampaignChange.bind(this);
    this.handleAutoSendInboxChange = this.handleAutoSendInboxChange.bind(this);
    this.handleNewCustomerChange = this.handleNewCustomerChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handlePaginationChose = this.handlePaginationChose.bind(this);
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.renderDataItemList = this.renderDataItemList.bind(this);
    this.handleItemSelectedChange = this.handleItemSelectedChange.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.deleteMultiplePromotionCodes = this.deleteMultiplePromotionCodes.bind(
      this
    );
    this.deleltePromotionCode = this.deleltePromotionCode.bind(this);
    this.activeMultiplesPromotionCodes = this.activeMultiplesPromotionCodes.bind(
      this
    );
    this.searchKeyPressHandle = this.searchKeyPressHandle.bind(this);
    this.searchStrChange = this.searchStrChange.bind(this);
    this.onImportButtonClick = this.onImportButtonClick.bind(this);
    this.onCancelImportFile = this.onCancelImportFile.bind(this);
    this.handleSendToInboxClick = this.handleSendToInboxClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.sendToInboxPromotionCode = this.sendToInboxPromotionCode.bind(this);
    this.confirmDeletePromotionCode = this.confirmDeletePromotionCode.bind(
      this
    );
    this.isDisableActive = this.isDisableActive.bind(this);
    this.isDisableDeactive = this.isDisableDeactive.bind(this);
    this.isDisableDelete = this.isDisableDelete.bind(this);
    this.onChangeCustomerType = this.onChangeCustomerType.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleAmountValueChange = this.handleAmountValueChange.bind(this);
    this.onChangeCurrency = this.onChangeCurrency.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleChangeScheduleEnable = this.handleChangeScheduleEnable.bind(this);
    this.handlePaymentMethodsApplyChange = this.handlePaymentMethodsApplyChange.bind(this);
    this.handleValueUseLimitChange = this.handleValueUseLimitChange.bind(this);
    this.handleValueUseLimitValueChange = this.handleValueUseLimitValueChange.bind(this);
    this.handleMinimumEstFareApplyChange = this.handleMinimumEstFareApplyChange.bind(this);
    this.handleMinimumEstFareApplyValueChange = this.handleMinimumEstFareApplyValueChange.bind(this);
    this.handleTermAndConditionChange = this.handleTermAndConditionChange.bind(this);
    this.handleChangeSchedules = this.handleChangeSchedules.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.searchCampaignDebounce = _.debounce(this.handleSearchCampaign, 300);
  }

  componentDidMount() {
    this.updatePromotionCodeList();
    this.getCampaignList();
    this.props.paymentMethodActions.paymentMethod(this.props.auth.selectedFleet.fleetId);
  }

  handleMenuClick(e, obj) {
    switch (e) {
      case 'Active':
      case 'Deactive':
        this.handleUpdateStateClick(obj._id, !obj.isActive);
        break;
      case 'SendToInbox':
        this.handleSendToInboxClick(obj._id);
        break;
      case 'Delete':
        this.deleltePromotionCode(obj);
        break;
      case 'Edit':
        this.handleClickEditButton(obj._id);
        this.handleEditPromoCode();
        break;
    }
  }

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      filterHeight = 40,
      toolbarheight = ReactDOM.findDOMNode(this.toobarContainer).clientHeight,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight + filterHeight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  updatePromotionCodeList(page = 0, limit) {
    const {
      /* promotionCodeList,
      numItemsPerPage,
      activePage, */
      searchKey,
      filterQuery,
      campaignQuery
    } = this.state;
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let {promotionType, promotionAmountType, promotionStatus} = filterQuery
    let campaignSelected = campaignQuery && campaignQuery.campaignSelected
    if (!limit) limit = this.state.promotionCodeList.limit;
    settingActions
      .findPromotionCodes({ 
        fleetId,
        limit, 
        page, 
        searchKey, 
        promotionType, 
        promotionAmountType, 
        promotionStatus,
        campaign: campaignSelected ? campaignSelected._id : 'all'
      })
      .then(data => {
        if (data.ok && data.res) {
          const { list } = data.res;
          _.forEach(list, procode => {
            procode.operationZone = procode.zoneId
              ? _.map(procode.zoneId, zone => {
                return zone.zoneName;
              }).join(`; `)
              : ``;
          });
          this.setState({
            promotionCodeList: data.res
          });
        }
      });
  }

  isDisableActive() {
    const { promotionCodeList } = this.state;
    let selectedList = promotionCodeList.list.filter(obj => obj.selected);
    if (selectedList && selectedList.length > 0) {
      return !(selectedList.findIndex(obj => !obj.isActive) >= 0);
    }
    return true;
  }

  isDisableDeactive() {
    const { promotionCodeList } = this.state;
    let selectedList = promotionCodeList.list.filter(obj => obj.selected);
    if (selectedList && selectedList.length > 0) {
      return !(selectedList.findIndex(obj => obj.isActive) >= 0);
    }
    return true;
  }

  isDisableDelete() {
    const { promotionCodeList } = this.state;
    let selectedList = promotionCodeList.list.filter(obj => obj.selected);
    if (selectedList && selectedList.length > 0) {
      return !(selectedList.findIndex(obj => !obj.isActive) >= 0);
    }
    return true;
  }

  getCampaignList() {
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    settingActions.findAllCampaignForSelection(fleetId).then(data => {
      if (data.ok && data.res) {
        const convertToCampaignFilter = data.res.map(item => {
          item.value = item._id;
          item.label = item.name;
          return item;
        });
        this.setState({
          campaignList: data.res,
          campaignQuery: {
            ...this.state.campaignQuery,
            list: convertToCampaignFilter
          }
        });
      }
    });
  }

  handleNumItemsPerPageChange(e) {
    let value = parseInt(e);
    this.updatePromotionCodeList(0, value);
  }

  handlePaginationChose(e) {
    let value = parseInt(e);
    this.updatePromotionCodeList(value);
  }

  handlePaginationSelect(value) {
    this.updatePromotionCodeList(value);
  }

  searchStrChange(e) {
    this.setState({
      searchKey: e
    });
  }

  searchKeyPressHandle(e) {
    if (e.key == 'Enter') {
      this.updatePromotionCodeList();
    }
  }

  openFormModal() {
    this.setState({ showDialogModal: true });
  }

  closeFormModal() {
    this.setState({
      showDialogModal: false,
      showConfirm: false,
      phonesImportExcel: [],
      fileName: '',
      isCustomerImportedExcel: false,
      isDetailForm: false,
      isEditable: false,
      imageUpload: {}
    });
  }


  handleAddButtonClick() {
    const { selectedFleet } = this.props.auth;
    let currency = '';
    if (this.props.auth && this.props.commonData) {
      let zone = this.props.commonData.mapZone.find(obj => {
        return obj.isDefault;
      });
      if (!zone) zone = this.props.commonData.mapZone[0];
      currency = zone.currency.iso;
    }
    this.setState(
      {
        disable: false,
        isSubmited: false,
        receiverList: [],
        dialogData: {
          budgetLimit: {
            isLimited: false,
            valueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
              [],
              this.props.auth.selectedFleet.currencies
            )
          },
          budgetLimitDay: {
            isLimited: false,
            value: 0
          },
          budgetLimitMonth: {
            isLimited: false,
            value: 0
          },
          corporateList: [],
          budgetLimitYear: {
            isLimited: false,
            value: 0
          },
          minimumEstFareApply: {
            isLimited: false,
            valueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
              [],
              this.props.auth.selectedFleet.currencies
            )
          },
          quantity: {
            isLimited: false,
            value: undefined
          },
          totalUsesLimit: {
            isLimited: false,
            value: undefined
          },
          quantityLimitPerDay: {
            isLimited: false,
            value: undefined
          },
          quantityLimitPerMonth: {
            isLimited: false,
            value: undefined
          },
          quantityLimitPerYear: {
            isLimited: false,
            value: undefined
          },
          totalUsesLimitPerDay: {
            isLimited: false,
            value: undefined
          },
          campaign: {
            _id: ''
          },
          type: PromotionAmountType.Amount,
          valueByCurrencies: this.mergeValueByCurrenciesToFleetCurrency(
            [],
            this.props.auth.selectedFleet.currencies
          ),
          promoStatus: PromotionType.Public,
          type: PromotionAmountType.Amount,
          promotionCode: '',
          currencyISO: currency,
          zoneId: [],
          phonesImportExcel: [],
          fileName: "",
          validFrom: '',
          validTo: '',
          autoSendInbox: false,
          notes: '',
          promoIcon: '',
          bannerImage: '',
          termAndCondition: '',
          service: {
            transport: true,
            shuttle: selectedFleet.shuttle && selectedFleet.shuttle.enable ? true : false,
            parcel: selectedFleet.delivery && selectedFleet.delivery.enable ? true : false,
            intercity: selectedFleet.intercity && selectedFleet.intercity.enable ? true : false,
            food: selectedFleet.food && selectedFleet.food.enable ? true : false,
            mart: selectedFleet.mart && selectedFleet.mart.enable ? true : false,
          },
          valueLimitPerUse: {
            isLimited: false,
            value: undefined
          },
          scheduleEnable: false,
          keepMinFee:false,
          applyLimitFinished: false,
          schedules: []
        },
        imageUpload: {}
      },
      this.openFormModal()
    );
  }

  onImportButtonClick() {
    this.setState({
      isImportFile: true
    });
  }

  onCancelImportFile() {
    this.setState({
      isImportFile: false
    });
  }

  mergeValueByCurrenciesToFleetCurrency(valueByCurrencies, currencies) {
    let result = [];
    if (valueByCurrencies) {
      result = currencies.map(obj => {
        let index = valueByCurrencies.findIndex(tmp => {
          return tmp.currencyISO == obj.iso;
        });
        if (index >= 0) {
          return {
            ...obj,
            value: valueByCurrencies[index].value,
            currencyISO: obj.iso
          };
        }
        return {
          ...obj,
          currencyISO: obj.iso
        };
      });
      return result;
    }
    return currencies.map(obj => {
      return {
        ...obj,
        currencyISO: obj.iso
      };
    });
  }

  handleClickEditButton(id) {
    // e.preventDefault();
    const { auth, settingActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let timeZone = auth.selectedFleet.timezone;
    let format24h = auth.selectedFleet.format24Hour;
    let currencies = auth.selectedFleet.currencies;
    if (currencies === undefined) currencies = [];
    loadingBarActions.showLoadingSpiner();
    this.setState({
      disable: true
    });

    settingActions.detailsPromotionCode({ fleetId, id }).then(data => {
      loadingBarActions.hideLoadingSpiner();
      if (data.ok && data.res) {
        let formData = data.res;
        let value = '';
        value = formData.valueByCurrencies[0].value;
        let zoneIdInfo = formData.zoneId || [];
        let zoneId = [];
        if (zoneIdInfo.length > 0) {
          zoneIdInfo.map(function (zone, index) {
            zoneId.push(zone._id);
          });
        }
        let valueByCurrencies = this.mergeValueByCurrenciesToFleetCurrency(
          formData.valueByCurrencies,
          currencies
        );
        let totalBudgetLimitValue =
          formData.budgetLimit &&
            formData.budgetLimit.valueByCurrencies &&
            formData.budgetLimit.valueByCurrencies.length
            ? formData.budgetLimit.valueByCurrencies[0].value
            : '';
        let minimumEstFareApplyValue = formData.minimumEstFareApply &&
          formData.minimumEstFareApply.valueByCurrencies &&
          formData.minimumEstFareApply.valueByCurrencies.length
          ? formData.minimumEstFareApply.valueByCurrencies[0].value
          : '';
        let currencyISO = formData.valueByCurrencies[0].currencyISO;
        this.setState({
          dialogData: {
            ...formData,
            valueByCurrencies,
            budgetLimit: {
              ...formData.budgetLimit,
              valueByCurrencies: totalBudgetLimitValue,
              value: totalBudgetLimitValue
            },
            corporateCustomers: formData.isCorporateCustomers,
            corporateList: formData.corporateId || [],
            validFrom: moment(formData.validFrom).tz(timeZone),
            validTo: moment(formData.validTo).tz(timeZone),
            value,
            zoneId,
            currencyISO,
            minimumEstFareApply: {
              ...formData.minimumEstFareApply,
              value: minimumEstFareApplyValue
            }
          },
          receiverList: formData.customerId
            ? formData.customerId.map(item => {
              item.value =
                item.firstName + ' ' + item.lastName + ' - ' + item.phone;
              item.id = item.userId;
              return item;
            })
            : [],
          isCustomerImportedExcel: formData.isCustomerImportedExcel || false,
          isDetailForm: true
        }); // end set State
        this.openFormModal();
      } // end if data.ok && data.res
      else {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('shiftSettings.Loading_promotion_code_fail')
          );
        }
      }
    });
  }

  handleUploadFile = file => {
    const {
      uploadService,
      auth: { selectedFleet }
    } = this.props;

    if (file) {
      const form = new FormData();
      form.append('photo', file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('module', 'promocode');
      return uploadService.uploadPhoto(form)
        .then(res => {
          if (!res.error) {
            return Promise.resolve(_.get(res, 'res.Key'));
          }
          this.context.notification('error', I18n.t('errors.upload_photo_fail'));
          return Promise.reject({ error: res.error });
        });
    }
    return Promise.resolve();
  };

  saveDialogForm() {
    const { dialogData, valid, receiverList, phonesImportExcel, imageUpload } = this.state;
    const { auth, settingActions, loadingBarActions } = this.props;
    let promises = [];
    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) return;
    let fleetId = auth.selectedFleet.fleetId;
    let timeZone = auth.selectedFleet.timezone;
    let listPhoneImport = (receiverList.length == 0 && phonesImportExcel.length > 0) ? phonesImportExcel : []
    loadingBarActions.showLoadingSpiner();
    let body = {
      fleetId,
      promotionCode: dialogData.promotionCode,
      promoStatus: dialogData.promoStatus,
      customerId:
        receiverList && receiverList.length > 0
          ? receiverList.map(obj => obj.id)
          : null,
      campaignId: dialogData.campaign._id,
      type: dialogData.type,
      validFrom: moment(dialogData.validFrom, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      validTo: moment(dialogData.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      budgetLimit: dialogData.budgetLimit,
      budgetLimitDay: dialogData.budgetLimitDay,
      budgetLimitMonth: dialogData.budgetLimitMonth,
      budgetLimitYear: dialogData.budgetLimitYear,
      isCorporateCustomers: dialogData.corporateCustomers,
      corporateId: dialogData.corporateList || [],
      isAutoAddCorporate: dialogData.isAutoAddCorporate || false,
      totalUsesLimit: dialogData.totalUsesLimit,
      totalUsesLimitPerDay: dialogData.totalUsesLimitPerDay,
      quantityLimitPerDay: dialogData.quantityLimitPerDay,
      quantityLimitPerMonth: dialogData.quantityLimitPerMonth,
      quantity: dialogData.quantity,
      newCustomer: dialogData.newCustomer,
      autoSendInbox: dialogData.autoSendInbox,
      applyVerifiedCustomers: dialogData.applyVerifiedCustomers,
      notes: dialogData.notes,
      referredCustomers: dialogData.referredCustomers,
      valueByCurrencies: [
        {
          value: dialogData.value,
          currencyISO: dialogData.currencyISO
        }
      ],
      zoneId: dialogData.zoneId,
      phonesImportExcel: listPhoneImport,
      service: dialogData.service,
      valueLimitPerUse: dialogData.valueLimitPerUse,
      minimumEstFareApply: dialogData.minimumEstFareApply,
      paymentMethodsApply: dialogData.paymentMethodsApply,
      quantityLimitPerYear: dialogData.quantityLimitPerYear,
      termAndCondition: dialogData.termAndCondition,
      scheduleEnable: dialogData.scheduleEnable,
      keepMinFee: dialogData.keepMinFee,
      applyLimitFinished: dialogData.applyLimitFinished,
      schedules: dialogData.schedules,
    };

    if (imageUpload.promoIcon) {
      promises.push(
        this.handleUploadFile(imageUpload.promoIcon)
          .then(dataImageUpload => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            };
            body.promoIcon = dataImageUpload;
          })
      );
    };
    if (imageUpload.bannerImage) {
      promises.push(
        this.handleUploadFile(imageUpload.bannerImage)
          .then(dataImageUpload => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            };
            body.bannerImage = dataImageUpload;
          })
      );
    };

    if (body.budgetLimit.isLimited) {
      body.budgetLimit.valueByCurrencies = [
        {
          value: dialogData.budgetLimit.value,
          currencyISO: dialogData.currencyISO
        }
      ];
    };

    if (body.minimumEstFareApply.isLimited) {
      body.minimumEstFareApply.valueByCurrencies = [
        {
          value: dialogData.minimumEstFareApply.value,
          currencyISO: dialogData.currencyISO
        }
      ];
    };

    if (body.applyVerifiedCustomers) {
      body.autoSendInbox = true;
    }

    Promise.all(promises).then(() => {
      settingActions.createPromotionCodes(body).then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('promotionSettings.Create_promotion_code_success')
          );
          if (data.res && (data.res.totalSuccess || data.res.totalFails)) {
            this.context.notification(
              'success',
              <Translate value="message.import_fail_success_passenger" success={data.res.totalSuccess} fail={data.res.totalFails}
              />
            );
          }
          this.closeFormModal();
          this.updatePromotionCodeList();
          this.setState({ isSubmited: false });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('promotionSettings.Create_promotion_code_fail')
            );
          }
          this.setState({ isChanged: true });
        }
      });
    }).catch(reason => {
      loadingBarActions.hideLoadingSpiner();
      console.log("reason ", reason)
    });
  }

  handleNameChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        name: e.target.value
      }
    });
  }

  handlePromotionCodeChange(e) {
    let dialogData = this.state.dialogData || {};
    // var characterAndDigitRegrex = /^[a-zA-Z0-9]*$/;
    // if (characterAndDigitRegrex.test(e.target.value)) {
      dialogData.promotionCode = e.target.value ? e.target.value.toUpperCase() : '';
      this.setState({
        dialogData
      });
    // }
  }

  handleChangeService(e, key) {
    let dialogData = this.state.dialogData || {};
    let value = e.target.checked;
    switch (key) {
      case "transport":
        dialogData.service.transport = value;
        this.setState({ dialogData });
        break;
      case "shuttle":
        dialogData.service.shuttle = value;
        this.setState({ dialogData });
        break;
      case "parcel":
        dialogData.service.parcel = value;
        this.setState({ dialogData });
        break;
      case "food":
        dialogData.service.food = value;
        this.setState({ dialogData });
        break;
      case "mart":
        dialogData.service.mart = value;
        this.setState({ dialogData });
        break;
      case "intercity":
        dialogData.service.intercity = value;
        this.setState({ dialogData });
        break;
      default:
        this.setState({ dialogData });
        break;
    };
  }

  handlePromotionTypeChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.promoStatus = e.target.value;
    this.setState({
      dialogData,
      valid: {}
    });
  }

  handleCampaignChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.campaign = {
      ...dialogData.campaign,
      _id: e.target.value
    };
    this.setState({
      dialogData
    });
  }

  onChangeCurrency(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.currencyISO = e.target.value;
    this.setState({
      dialogData
    });
  }

  handleZoneChange(e) {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    let dialogData = this.state.dialogData || {};
    dialogData.zoneId = value;
    this.setState({
      dialogData
    });
  };

  handlePaymentMethodsApplyChange(e) {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    let dialogData = this.state.dialogData || {};
    dialogData.paymentMethodsApply = value;
    this.setState({
      dialogData
    });
  }

  handleTypeChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.type = e.target.value;
    dialogData.value = '';
    this.setState({
      dialogData,
      valid: {}
    });
  }

  handlePercentValueChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.value = e.target.value;
    this.setState({
      dialogData
    });
  }

  handleAmountValueChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.value = e.target.value;
    this.setState({
      dialogData
    });
  }


  handleValidFromChanged(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.validFrom = e._d;
    this.setState({
      dialogData
    });
  }

  handleValidToChanged(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.validTo = e._d;
    this.setState({
      dialogData
    });
  }

  handleChangeScheduleEnable(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.scheduleEnable = e.target.checked;
    this.setState({
      dialogData
    });
  };

  handleChangeKeepMinFee = (e) => {
    let dialogData = this.state.dialogData || {};
    dialogData.keepMinFee = e.target.checked;
    this.setState({
      dialogData
    });
  };

  handleChangeApplyLimitFinished = (e) => {
    let dialogData = this.state.dialogData || {};
    dialogData.applyLimitFinished = e.target.checked;
    this.setState({
      dialogData
    });
  };

  handleBudgetNewLimitChange = (status, key) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        [key]: {
          isLimited: status,
        }
      },
      valid: {}
    });
  }

  handleBudgetLimitChange(status, key) {
    let dialogData = this.state.dialogData || {};
    dialogData[key].isLimited = status;
    dialogData[key].value = '';
    this.setState({
      dialogData,
      valid: {}
    });
  }

  handleCorpCustomerChange = (newList) => {
    let dialogData = this.state.dialogData || {};
    dialogData.corporateList = newList;
    this.setState({
      dialogData
    });
  }

  handleBudgetValueChange(e, key) {
    let dialogData = this.state.dialogData || {};
    dialogData[key].value = e.target.value;
    this.setState({
      dialogData
    });
  }

  handleMinimumEstFareApplyChange(status) {
    let dialogData = this.state.dialogData || {};
    dialogData.minimumEstFareApply.isLimited = status;
    dialogData.minimumEstFareApply.value = '';
    this.setState({
      dialogData,
      valid: {}
    });
  };

  handleMinimumEstFareApplyValueChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.minimumEstFareApply.value = e.target.value;
    this.setState({
      dialogData
    });
  };

  handleValueUseLimitChange(status) {
    let dialogData = this.state.dialogData || {};
    dialogData.valueLimitPerUse.isLimited = status;
    dialogData.valueLimitPerUse.value = 0;
    this.setState({
      dialogData,
      valid: {}
    });
  }

  handleValueUseLimitValueChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.valueLimitPerUse.value = e.target.value;
    this.setState({
      dialogData
    });
  }

  handleChangeDialogForm = (key, value) => {
    let dialogData = this.state.dialogData || {};
    dialogData[key] = value;
    this.setState({
      dialogData
    });
  };


  handleNewCustomerChange(e) {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        newCustomer: e.target.checked,
        totalUsesLimit: {
          ...dialogData.totalUsesLimit,
          value: '1',
          isLimited: true
        }
      }
    });
  }

  handleAutoSendInboxChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.autoSendInbox = e.target.checked;
    this.setState({
      dialogData
    });
  }

  handleAutoSendAddCorporate = (e) => {
    let dialogData = this.state.dialogData || {};
    dialogData.isAutoAddCorporate = e.target.checked;
    this.setState({
      dialogData
    });
  }

  handleApplyVerifiedCustomersChange = (e) => {
    let dialogData = this.state.dialogData || {};
    dialogData.applyVerifiedCustomers = e.target.checked;
    this.setState({
      dialogData
    });
  }

  handleNotesChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.notes = e.target.value;
    this.setState({
      dialogData
    });
  };

  handleTermAndConditionChange(e) {
    let dialogData = this.state.dialogData || {};
    dialogData.termAndCondition = e.target.value;
    this.setState({
      dialogData
    });
  }

  handleChangeSchedules(schedules) {
    let dialogData = this.state.dialogData || {};
    dialogData.schedules = schedules;
    this.setState({
      dialogData
    });
  };

  handleImageChange(changeFile, error, key) {
    const { dialogData, imageUpload } = this.state;
    const data = changeFile || {};
    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification('error', I18n.t('upload_document.Limit_size_upload').format(8));
      return;
    };
    dialogData[key] = data.result || null;
    this.setState({
      dialogData,
      imageUpload: {
        ...imageUpload,
        [key]: data.file || null
      }
    });
  };

  validFromDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  handleUpdateStateClick(id, status) {
    const { auth, settingActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;

    settingActions
      .activePromotionCode({
        fleetId,
        id,
        isActive: status
      })
      .then(data => {
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('promotionSettings.Update_promotion_code_status_success')
          );
          this.updatePromotionCodeList();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('promotionSettings.Update_promotion_code_status_fail')
            );
          }
        }
      });
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], Columns);
    const { promotionCodeList } = this.state;
    const { auth } = this.props;
    let timeZone = auth.selectedFleet.timezone;
    _.forEach(tableColumns, col => {
      switch (col.key) {
        case 'table-selectbox':
          col.customHeader = columnKey => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={
                  this.state.promotionCodeList
                    ? this.state.promotionCodeList.list.length == 0
                      ? false
                      : this.state.promotionCodeList.list.filter(data => {
                        return !data.selected;
                      }).length == 0
                    : false
                }
                onChange={e => {
                  this.handleItemSelectedChange(null, e);
                }}
              />
            );
          };
          col.customCell = data => {
            return (
              <CcCheckbox
                className="table-selectbox"
                disabled={data.inUse}
                checked={data.inUse ? false : data.selected}
                onChange={e => {
                  this.handleItemSelectedChange(data._id, e);
                }}
              />
            );
          };
          break;
        case 'promotionCode':
          col.customCell = data => {
            return (
              <a
                className="clickable"
                onClick={e => {
                  this.handleClickEditButton(data._id, e);
                }}
              >
                {' '}
                {data.promotionCode}
              </a>
            );
          };
          break;
        case 'promoType':
          col.customCell = data => {
            return data.promoStatus == PromotionType.Private ? (
              <Translate value="promotionSettings.Private" />
            ) : data.promoStatus == PromotionType.PrivatePoint ? (
              <Translate value="promotionSettings.Private" />
            ) : (
              <Translate value="promotionSettings.Public" />
            );
          };
          break;
        case 'type':
          col.customCell = data => {
            return data.type == PromotionAmountType.Amount ? (
              <Translate value="promotionSettings.Amount" />
            ) : (
              <Translate value="promotionSettings.Percent" />
            );
          };
          break;
        case 'value':
          col.customCell = obj => {
            let value =
              obj.valueByCurrencies && obj.valueByCurrencies[0]
                ? obj.valueByCurrencies[0]
                : { value: 0 };
            return obj.type === PromotionAmountType.Amount
              ? formatCurrency.format(value.value, { code: value.currencyISO })
              : `${value.value} %`;
          };
          break;
        case 'validFrom':
          col.customCell = obj => {
            return moment(obj.validFrom)
              .tz(timeZone)
              .format('DD/MM/YYYY');
          };
          break;
        case 'validTo':
          col.customCell = obj => {
            return moment(obj.validTo)
              .tz(timeZone)
              .format('DD/MM/YYYY');
          };
          break;
        case 'totalUsesUser':
          col.customCell = obj => {
            return obj.totalUsesLimit.isLimited ? (
              `${obj.totalUsesLimit.value}`
            ) : (
              <Translate value="promotionSettings.Unlimited" />
            );
          };
          break;
        case 'releaseQuantity':
          col.customCell = obj => {
            return obj.quantity.isLimited ? (
              `${obj.quantity.value}`
            ) : (
              <Translate value="promotionSettings.Unlimited" />
            );
          };
          break;
        case 'sendInboxAction':
          col.customCell = obj => {
            return obj.sendInboxAction;
          };
          break;
        case 'status':
          col.customCell = obj => {
            return (
              <div className={obj.isActive ? 'Active' : 'Inactive'}>
                {(!this.props.permissions || this.props.permissions.actions) &&
                  obj.campaign.isActive ? (
                  <a
                    onClick={() => {
                      this.handleUpdateStateClick(obj._id, !obj.isActive);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {obj.isActive ? (
                      <Translate value="promotionSettings.Active" />
                    ) : (
                      <Translate value="promotionSettings.Inactive" />
                    )}
                  </a>
                ) : obj.isActive ? (
                  <Translate value="promotionSettings.Active" />
                ) : (
                  <Translate value="promotionSettings.Inactive" />
                )}
              </div>
            );
          };
          break;
        case 'actions':
          col.customCell = (obj, rowIndex) => {
            let status = obj.isActive ? 'Active' : 'Inactive';
            let actions = [];
            if (!obj.editable) {
              actions.push({
                label: "promotionSettings.Edit",
                eventKey: "Edit"
              });
            };
            if (obj.isActive) {
              actions.push({
                label: 'promotionSettings.Deactivate',
                eventKey: 'Deactive'
              });
              if (!obj.newCustomer && !obj.referredCustomers) {
                actions.push({
                  label: 'promotionSettings.Send_to_inbox',
                  eventKey: 'SendToInbox'
                });
              }
            } else {
              if (obj.campaign.isActive) {
                actions.push({
                  label: 'promotionSettings.Activate',
                  eventKey: 'Active'
                });
              }
              actions.push({
                label: 'promotionSettings.Delete',
                eventKey: 'Delete'
              });
            }

            return (
              <div className="driver-actions-container">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <TableActions
                    rowIndex={rowIndex}
                    rowData={obj}
                    onSelect={this.handleMenuClick}
                    totalRow={promotionCodeList.list.length}
                    rowHeight={this.state.rowHeight}
                    menuItems={actions}
                    tableHeight={this.state.tableHeight}
                  />
                ) : (
                  <a
                    onClick={e => {
                      this.handleClickEditButton(obj._id, e);
                    }}
                    href="javascript:void(0)"
                  >
                    <Translate value="customer.View" />
                  </a>
                )}
              </div>
            );
          };
        default:
          break;
      }
    });

    return tableColumns;
  };

  renderDataItemList() {
    const { promotionCodeList } = this.state;
    const { auth } = this.props;
    let timeZone = auth.selectedFleet.timezone;
    if (promotionCodeList.list && promotionCodeList.list.length > 0) {
      return promotionCodeList.list.map(obj => {
        return (
          <tr key={obj._id} className="p-no-margin">
            <td scope="row">
              <CcCheckbox
                className="table-select-check"
                disabled={obj.inUse}
                checked={obj.inUse ? false : obj.selected}
                onChange={e => {
                  this.handleItemSelectedChange(obj._id, e);
                }}
              />
            </td>
            <td scope="row">
              <a
                onClick={e => {
                  this.handleClickEditButton(obj._id, e);
                }}
              >
                {' '}
                {obj.promotionCode}
              </a>
            </td>
            <td scope="row">
              <p>
                {obj.promoStatus == PromotionType.Private ? (
                  <Translate value="promotionSettings.Private" />
                ) : (
                  <Translate value="promotionSettings.Public" />
                )}
              </p>
            </td>
            <td scope="row">
              <p>{obj.campaign ? obj.campaign.name : ''}</p>
            </td>
            <td scope="row">
              <p>
                {obj.type == PromotionAmountType.Amount ? (
                  <Translate value="promotionSettings.Amount" />
                ) : (
                  <Translate value="promotionSettings.Percent" />
                )}
              </p>
            </td>
            <td scope="row">
              <p>
                {obj.type === PromotionAmountType.Amount
                  ? obj.valueByCurrencies
                    .map(obj => obj.currencyISO + ' ' + obj.value)
                    .join(', ')
                  : `${obj.valueByCurrencies[0].value} %`}
              </p>
            </td>
            <td scope="row">
              <p>
                {moment(obj.validFrom)
                  .tz(timeZone)
                  .format('DD/MM/YYYY')}
              </p>
            </td>
            <td scope="row">
              <p>
                {moment(obj.validTo)
                  .tz(timeZone)
                  .format('DD/MM/YYYY')}
              </p>
            </td>
            <td scope="row">
              <p>
                {obj.totalUsesLimit.isLimited ? (
                  `${obj.totalUsesLimit.value}`
                ) : (
                  <Translate value="promotionSettings.Unlimited" />
                )}
              </p>
            </td>
            <td scope="row">
              <p>
                {obj.quantity.isLimited ? (
                  `${obj.quantity.value}`
                ) : (
                  <Translate value="promotionSettings.Unlimited" />
                )}{' '}
              </p>
            </td>
            <td scope="row">
              <p>{obj.sendInboxAction}</p>
            </td>
            <td className={obj.isActive ? 'activated' : 'inactivated'}>
              {(!this.props.permissions || this.props.permissions.actions) &&
                obj.campaign.isActive ? (
                <a
                  onClick={() => {
                    this.handleUpdateStateClick(obj._id, !obj.isActive);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {obj.isActive ? (
                    <Translate value="promotionSettings.Active" />
                  ) : (
                    <Translate value="promotionSettings.Inactive" />
                  )}
                </a>
              ) : obj.isActive ? (
                <Translate value="promotionSettings.Active" />
              ) : (
                <Translate value="promotionSettings.Inactive" />
              )}
            </td>
            <td>
              {!this.props.permissions || this.props.permissions.actions ? (
                <DropdownButton
                  title={<i className="fa fa-bars" />}
                  id="bg-nested-dropdown"
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, obj);
                  }}
                  pullRight
                >
                  <Dropdown.Item
                    onClick={() => {
                      this.handleUpdateStateClick(obj._id, !obj.isActive);
                    }}
                    eventKey="UpdateStatus"
                  >
                    {obj.isActive ? (
                      <Translate value="promotionSettings.Deactivate" />
                    ) : obj.campaign.isActive ? (
                      <Translate value="promotionSettings.Activate" />
                    ) : null}
                  </Dropdown.Item>
                  {obj.isActive ? (
                    <Dropdown.Item
                      eventKey="SendToInbox"
                      onClick={e => {
                        this.handleSendToInboxClick(obj._id);
                      }}
                    >
                      <Translate value="promotionSettings.Send_to_inbox" />
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      eventKey="Delete"
                      onClick={e => {
                        this.deleltePromotionCode(obj);
                      }}
                    >
                      <Translate value="promotionSettings.Delete" />
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              ) : (
                <a
                  onClick={e => {
                    this.handleClickEditButton(obj._id, e);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="promotionSettings.View" />
                </a>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="item-no-found" colSpan={13}>
            <Translate value="messages.item_no_found" />
          </td>
        </tr>
      );
    }
  }

  handleItemSelectedChange(id, e) {
    const { promotionCodeList } = this.state;
    let listItem = _.cloneDeep(promotionCodeList.list);
    if (id != null) {
      let index = listItem.findIndex(obj => id === obj._id);
      if (index >= 0) listItem[index].selected = e.target.checked;
    } else {
      let value = e.target.checked;
      listItem = listItem.map(obj => {
        return {
          ...obj,
          selected: value
        };
      });
    }
    this.setState({
      promotionCodeList: {
        ...promotionCodeList,
        list: listItem
      }
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleLoadOption(input, callback) {
    if (!input) return callback();

    const { customerAutocompleteAction, auth } = this.props;
    const { receiverList } = this.state;
    this.searchCustomerDebounce(
      auth.selectedFleet.fleetId,
      customerAutocompleteAction,
      receiverList,
      input,
      callback
    );
  }

  searchCustomer = (
    fleetId,
    customerAutocompleteAction,
    receiverList,
    input,
    callback
  ) => {
    let ids = [];
    if (receiverList) ids = receiverList.map(item => item.id);

    let params = {
      fleetId,
      str: input
    },
      trimPhone = trimPhoneNumber(input, 'no plus');

    if (!Validation.validateSearchingPhone(trimPhone)) {
      params.name = 1;
    } else {
      params.str = trimPhone;
    }

    if (ids.length) params.ids = ids;

    customerAutocompleteAction.customerAutocomplete(params).then(data => {
      if (data.ok && data.res && data.res.list.length > 0) {
        let result = { complete: true };
        result.options = data.res.list.map(item => {
          item.value =
            item.firstName + ' ' + item.lastName + ' - ' + item.phone;
          item.id = item.userId;
          return item;
        });
        callback(null, result);
      } else {
        callback(null, null);
      }
    });
  };

  handleCustomizedChange(e) {
    this.setState({
      receiverListValidation: e.length > 0,
      receiverList: e
    });
  }

  handleConfirmButtonClick(id) {
    switch (id) {
      case CONFIRM_DELETE_ID:
        {
          this.deleteMultiplePromotionCodes();
        }
        break;
      case CONFIRM_SEND_INBOX_ID:
        {
          this.sendToInboxPromotionCode();
        }
        break;
    }
    this.handleConfirmCloseClick();
  }

  handleConfirmCloseClick() {
    this.setState({
      confirm: null
    });
  }

  handleDeleteClick() {
    this.setState({
      confirm: {
        id: CONFIRM_DELETE_ID,
        title: I18n.t('promotionSettings.Confirmation'),
        body: (
          <div>
            <Translate value="promotionSettings.Delete_promotion_code_confirmation" />
            <br />
            <Translate value="promotionSettings.Just_delete_inactive" />
          </div>
        ),
        buttonTitle: 'Yes',
        closeButtonText: 'No'
      }
    });
  }

  sendToInboxPromotionCode() {
    const { auth, settingActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    loadingBarActions.showLoadingSpiner();
    settingActions
      .sendInboxPromotionCode({ fleetId, id: sendInBoxId })
      .then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            'success',
            I18n.t('promotionSettings.Send_inbox_success')
          );
          this.updatePromotionCodeList();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('promotionSettings.Send_inbox_fail')
            );
          }
        }
      });
  }

  deleteMultiplePromotionCodes() {
    const { promotionCodeList } = this.state;
    const { auth, settingActions, loadingBarActions } = this.props;
    let ids = promotionCodeList.list
      .filter(obj => {
        return !obj.isActive && obj.selected;
      })
      .map(obj => obj._id);

    let fleetId = auth.selectedFleet.fleetId;
    loadingBarActions.showLoadingSpiner();
    settingActions.deleteMultiPromotionCode({ fleetId, ids }).then(data => {
      loadingBarActions.hideLoadingSpiner();
      if (data.ok && !data.error) {
        if (ids.length > 1) {
          this.context.notification(
            'success',
            I18n.t('promotionSettings.Delete_multiple_promotion_codes_success')
          );
        } else {
          this.context.notification(
            'success',
            I18n.t('promotionSettings.Delete_promotion_code_success')
          );
        }

        this.updatePromotionCodeList();
      } else {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          if (ids.length > 1) {
            this.context.notification(
              'error',
              I18n.t('promotionSettings.Delete_multiple_promotion_codes_fail')
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('promotionSettings.Delete_promotion_code_fail')
            );
          }
        }
      }
      this.handleConfirmCloseClick();
    });
  }

  activeMultiplesPromotionCodes(status) {
    const { promotionCodeList } = this.state;
    const { auth, settingActions, loadingBarActions } = this.props;

    let fleetId = auth.selectedFleet.fleetId;

    let ids = promotionCodeList.list
      .filter(obj => {
        return obj.selected;
      })
      .map(obj => obj._id);
    if (ids.length == 0) return;

    loadingBarActions.showLoadingSpiner();
    settingActions
      .activeMultiPromotionCode({ fleetId, ids, isActive: status })
      .then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          if (ids.length > 1) {
            this.context.notification(
              'success',
              I18n.t(
                'promotionSettings.Update_multiple_promotion_code_status_success'
              )
            );
          } else {
            this.context.notification(
              'success',
              I18n.t('promotionSettings.Update_promotion_code_status_success')
            );
          }
          this.updatePromotionCodeList();
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            if (ids.length > 1) {
              this.context.notification(
                'error',
                I18n.t(
                  'promotionSettings.Update_multiple_promotion_code_status_fail'
                )
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('promotionSettings.Update_promotion_code_status_fail')
              );
            }
          }
        }
      });
  }

  deleltePromotionCode(obj) {
    this.setState({ showConfirm: true, dialogData: obj });
  }

  confirmDeletePromotionCode() {
    const { auth, settingActions, loadingBarActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let id = this.state.dialogData._id;
    loadingBarActions.showLoadingSpiner();
    settingActions.deletePromotionCode({ fleetId, id }).then(data => {
      loadingBarActions.hideLoadingSpiner();
      if (data.ok && !data.error) {
        this.closeFormModal();
        this.context.notification(
          'success',
          I18n.t('promotionSettings.Delete_promotion_codes_success')
        );
        this.updatePromotionCodeList();
      } else {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('promotionSettings.Delete_promotion_codes_fail')
          );
        }
      }
    });
  }

  handleSendToInboxClick(id) {
    sendInBoxId = id;
    this.setState({
      confirm: {
        id: CONFIRM_SEND_INBOX_ID,
        title: I18n.t('promotionSettings.Confirmation'),
        body: I18n.t('promotionSettings.Send_inbox_confirmation'),
        buttonTitle: 'Yes',
        closeButtonText: 'No'
      }
    });
  }

  onChangeCustomerType(e) {
    let value = parseInt(e.target.value);
    let dialogData = this.state.dialogData || {};
    if (value === CustomerType.NewCustomer) {
      // if new customer
      dialogData.totalUsesLimit.value = '1';
      dialogData.totalUsesLimit.isLimited = true;
      dialogData.totalUsesLimitPerDay.value = '1';
      dialogData.totalUsesLimitPerDay.isLimited = true;
      dialogData.newCustomer = true;
      dialogData.referredCustomers = false;
      dialogData.corporateCustomers = false;
      dialogData.corporateList = [];
    } else if (value === CustomerType.ReferredCustomers) {
      // referredCustomers
      dialogData.referredCustomers = true;
      dialogData.newCustomer = false;
      dialogData.totalUsesLimit.value = '1';
      dialogData.totalUsesLimit.isLimited = true;
      dialogData.corporateCustomers = false;
      dialogData.corporateList = [];
    } else if (value === CustomerType.CorporateCustomers) {
      dialogData.corporateCustomers = true;
      dialogData.referredCustomers = false;
      dialogData.newCustomer = false;
    } else {
      dialogData.corporateCustomers = false;
      dialogData.corporateList = [];
      dialogData.newCustomer = false;
      dialogData.referredCustomers = false;
    }
    this.setState({
      dialogData
    });
  }

  handleImportClick = () => {
    this.setState({ isShowImport: true })
  }

  closeDialog = () => {
    this.setState({ isShowImport: false })
  }

  handleImportCustomerClick = (data) => {
    this.setState({
      phonesImportExcel: data.phones,
      fileName: data.fileName,
      receiverList: []
    })
  }

  handleRemoveClick = () => {
    this.setState({
      phonesImportExcel: [],
      fileName: "",
      receiverList: []
    })
  }

  handleEditPromoCode = () => {
    this.setState({
      isEditable: true
    })
  }

  handleUpdatePromoCode = () => {
    const { dialogData, valid, imageUpload } = this.state;
    const { auth, settingActions, loadingBarActions } = this.props;
    let promises = [];
    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) return;
    let fleetId = auth.selectedFleet.fleetId;
    loadingBarActions.showLoadingSpiner();
    let body = {
      fleetId,
      id: dialogData._id,
      validFrom: moment(dialogData.validFrom, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      validTo: moment(dialogData.validTo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      termAndCondition: dialogData.termAndCondition,
      corporateId: dialogData.corporateList
    };
    if (imageUpload.promoIcon) {
      promises.push(
        this.handleUploadFile(imageUpload.promoIcon)
          .then(dataImageUpload => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            };
            body.promoIcon = dataImageUpload;
          })
      );
    } else if (imageUpload.promoIcon === null) {
      body.promoIcon = '';
    };
    if (imageUpload.bannerImage) {
      promises.push(
        this.handleUploadFile(imageUpload.bannerImage)
          .then(dataImageUpload => {
            if (dataImageUpload && dataImageUpload.error) {
              loadingBarActions.hideLoadingSpiner();
              return;
            };
            body.bannerImage = dataImageUpload;
          })
      );
    } else if (imageUpload.bannerImage === null) {
      body.bannerImage = '';
    };
    Promise.all(promises).then(() => {
      settingActions.updatePromotionCode(body)
        .then(data => {
          loadingBarActions.hideLoadingSpiner();
          if (data.ok && !data.error) {
            this.context.notification(
              'success',
              I18n.t('promotionSettings.Update_promotion_code_status_success')
            );
            this.closeFormModal();
            this.updatePromotionCodeList();
            this.setState({ isSubmited: false });
          } else {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('promotionSettings.Update_promotion_code_status_fail')
              );
            }
            this.setState({ isChanged: true });
          }
        });
    }).catch(reason => {
      loadingBarActions.hideLoadingSpiner();
      console.log("reason ", reason)
    });
  }

  handleSelectCampaignId = (value) => {
    let { campaignQuery } = this.state
    if(value == '') {
      this.handleSearchCampaign('', null, true);
      this.setState({
        campaignQuery: {
          ...this.state.campaignQuery,
          campaignSelected: null
        },
      })
    } else {
      let campaignSelected = campaignQuery.list && campaignQuery.list.find((campaign) => campaign._id == value) || null
      this.setState({
        campaignQuery: {
          ...this.state.campaignQuery,
          campaignSelected: campaignSelected || null
        },
      })
    }
  }

  handleSearchCampaign = (inputValue, callback, forceUpdate = false) => {
    const params = _.pick(this.state.campaignQuery, ['search', 'page', 'limit']);
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      this.getCampaignListHasSearch(params, false, callback);
    } else {
      return callback ? callback() : null;
    }
  };

  getCampaignListHasSearch = (params, loadMore = false, callback = null) => {
    this.props.settingActions.getAllCampaign({
      ...params,
      fleetId: this.props.auth.selectedFleet.fleetId,
    })
      .then(data => {
        if (data.ok && data.res && data.res.list) {
          let listFiter = data.res.list;
          const newData = listFiter.map(item => {
            item.value = item._id;
            item.label = item.name;
            return item;
          });
          this.setState({
            campaignQuery: {
              ...this.state.campaignQuery,
              ...data.res,
              search: params.search || '',
              hasMore: data.res.list.length === params.limit,
              list: loadMore ? this.state.campaignQuery.list.concat(newData) : newData
            }
          });
        }
        if (callback) callback();
      });
  };

  getCampaignDropdownTitle = () => {
    const campaignId = this.state.campaignQuery.campaignSelected && this.state.campaignQuery.campaignSelected._id;
    const { campaignQuery } = this.state;
    let title = '';
    if (!campaignId || campaignId === 'all') {
      title = I18n.t('report.query.common.all');
    } else {
      title = _.get(_.find(campaignQuery.list, item => item.value === campaignId), 'label') || _.get(campaignQuery, 'selectedObj.name');
    }
    return <Translate value="report.query.campaign_report" className="dropdown-title" name={title} />;
  };

  handleLoadMoreCampaignOptions = () => {
    const params = _.pick(this.state.campaignQuery, ['search', 'page', 'limit']);
    params.page++;
    if (!this.state.campaignQuery.hasMore) return;
    this.getCampaignList(params, 'loadMore');
  };

  handleMultiSelectFilterChange = (values, filterName, valueForAll = 'all') => {
    // console.log(value)
    let newFilterQuery = this.state.filterQuery
    // const existAll = _.findIndex(this.state.filterQuery[filterName], item => item === valueForAll);
    const existAll = _.findIndex(this.state.filterQuery[filterName], item => item === valueForAll);
    const mappings = {
      promotionType: PromotionTypeFilter.map(item => item.value),
      promotionAmountType: PromotionAmountTypeFilter.map(item => item.value),
      promotionStatus: PromotionStatusFilter.map(item => item.value)
    };
    if (existAll !== -1) {
      if (values.includes(valueForAll)) {
        newFilterQuery[filterName] = _.filter(values, item => item !== valueForAll);
      } else {
        newFilterQuery[filterName] = [];
      }
      // } else if (values.includes(valueForAll) || values.length === mappings[filterName].length - 1) {
    } else if (values.includes(valueForAll)) {
      newFilterQuery[filterName] = mappings[filterName];
    } else {
      newFilterQuery[filterName] = values;
    }
    this.setState({
      filterQuery: newFilterQuery
    })
  }

  render() {
    const { dialogData, disable, promotionCodeList, phonesImportExcel, isCustomerImportedExcel, isDetailForm } = this.state;
    const { value: amountValue = 0 } = dialogData || {};
    const { commonData, auth, permissions } = this.props;
    const { selectedFleet } = auth;
    const bodyData =
      promotionCodeList && promotionCodeList.list ? promotionCodeList.list : [];
    let currentCurrency = dialogData.currencyISO;
    const allZonesByCurrency = _.filter(
      commonData.mapZone,
      z =>
        z.display &&
        z.isActive &&
        z.currency &&
        z.currency.iso === currentCurrency
    );
    let zonesToSelect = allZonesByCurrency;
    // const isEnabledVerify = selectedFleet.generalSetting.promoForVerifiedPax;
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeFormModal} show={true} backdrop={true} className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="promotionSettings.Delete_promotion_code" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeFormModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="promotionSettings.Delete_confirm_promotion_code" />
              <br />
              <Translate value="promotionSettings.Just_delete_inactive" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save'}
                onClick={this.confirmDeletePromotionCode}
              >
                <Translate value="promotionSettings.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeFormModal}>
                <Translate value="promotionSettings.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}


        {this.state.showDialogModal && <AddEditPromo
          selectedFleet={selectedFleet}
          auth={auth}
          showDialogModal={this.state.showDialogModal}
          data={this.state.dialogData}
          valid={this.state.valid}
          isSubmited={this.state.isSubmited}
          campaignList={this.state.campaignList}
          currencies={auth.selectedFleet.currencies}
          zonesToSelect={zonesToSelect}
          disable={this.state.disable}
          fleetId={this.props.auth.selectedFleet.fleetId}
          isEditable={this.state.isEditable}
          getCorporateList={this.props.corporateActions.getCorporate}
          handleCorpCustomerChange={this.handleCorpCustomerChange}
          isDetailForm={this.state.isDetailForm}
          phonesImportExcel={this.state.phonesImportExcel}
          isCustomerImportedExcel={this.state.isCustomerImportedExcel}
          receiverList={this.state.receiverList}
          paymentMethod={this.props.paymentMethod}
          permissions={permissions}
          closeFormModal={this.closeFormModal}
          handleUpdatePromoCode={this.handleUpdatePromoCode}
          handleEditPromoCode={this.handleEditPromoCode}
          handlePromotionCodeChange={this.handlePromotionCodeChange}
          handlePromotionTypeChange={this.handlePromotionTypeChange}
          handleLoadOption={this.handleLoadOption}
          handleCustomizedChange={this.handleCustomizedChange}
          handleCampaignChange={this.handleCampaignChange}
          onChangeCurrency={this.onChangeCurrency}
          handleZoneChange={this.handleZoneChange}
          handlePaymentMethodsApplyChange={this.handlePaymentMethodsApplyChange}
          handleTypeChange={this.handleTypeChange}
          handleAmountValueChange={this.handleAmountValueChange}
          handlePercentValueChange={this.handlePercentValueChange}
          handleValidFromChanged={this.handleValidFromChanged}
          handleValidToChanged={this.handleValidToChanged}
          handleChangeScheduleEnable={this.handleChangeScheduleEnable}
          handleChangeKeepMinFee={this.handleChangeKeepMinFee}
          handleChangeApplyLimitFinished={this.handleChangeApplyLimitFinished}
          onChangeCustomerType={this.onChangeCustomerType}
          handleBudgetLimitChange={this.handleBudgetLimitChange}
          handleBudgetNewLimitChange={this.handleBudgetNewLimitChange}
          handleBudgetValueChange={this.handleBudgetValueChange}
          handleMinimumEstFareApplyChange={this.handleMinimumEstFareApplyChange}
          handleMinimumEstFareApplyValueChange={this.handleMinimumEstFareApplyValueChange}
          handleValueUseLimitChange={this.handleValueUseLimitChange}
          handleValueUseLimitValueChange={this.handleValueUseLimitValueChange}
          handleChangeDialogForm={this.handleChangeDialogForm}
          handleApplyVerifiedCustomersChange={this.handleApplyVerifiedCustomersChange}
          handleAutoSendInboxChange={this.handleAutoSendInboxChange}
          handleAutoSendAddCorporate={this.handleAutoSendAddCorporate}
          handleNotesChange={this.handleNotesChange}
          handleTermAndConditionChange={this.handleTermAndConditionChange}
          saveDialogForm={this.saveDialogForm}
          ValidatorCallback={this.ValidatorCallback}
          handleImportClick={this.handleImportClick}
          handleRemoveClick={this.handleRemoveClick}
          handleChangeService={this.handleChangeService}
          handleChangeSchedules={this.handleChangeSchedules}
          handleImageChange={this.handleImageChange}
        />}

        <ButtonToolbar
          className="text-center header-button-group form-cus"
          ref={node => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <FormControl
                className="search-form"
                type="text"
                placeholder={I18n.t('General.search')}
                onChange={e => this.searchStrChange(e.target.value)}
                onKeyPress={this.searchKeyPressHandle}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {!this.props.permissions || this.props.permissions.actions ? (
              <React.Fragment>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleAddButtonClick}
                >
                  <Translate value="promotionSettings.Add" />
                </Button>
                <Button
                  className="btn-header text-delete-header"
                  onClick={this.handleDeleteClick}
                  disabled={this.isDisableDelete()}
                >
                  <Translate value="customer.Delete" />
                </Button>
                <Button
                  className="btn-header text-active-header"
                  disabled={this.isDisableActive()}
                  onClick={() => {
                    this.activeMultiplesPromotionCodes(true);
                  }}
                >
                  <Translate value="customer.Activate" />
                </Button>
                <Button
                  className="btn-header text-deactive-header"
                  disabled={this.isDisableDeactive()}
                  onClick={() => {
                    this.activeMultiplesPromotionCodes(false);
                  }}
                >
                  <Translate value="customer.Deactivate" />
                </Button>
              </React.Fragment>
            ) : (
              ''
            )}
          </ButtonGroup>
          {!this.props.permissions || this.props.permissions.actions ? (
            <ButtonGroup className="group-right">
              <Button
                className="btn-header text-add-header"
                onClick={this.onImportButtonClick}
              >
                <Translate value="customer.Import" />
              </Button>
            </ButtonGroup>
          ) : null}
        </ButtonToolbar>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <CCDropDown
            id="adjustPrice-dropdown"
            items={PromotionTypeFilter}
            title={'Promotion type'}
            valueKey="value"
            labelKey="label"
            selectedItems={this.state.filterQuery.promotionType}
            onSelect={(value) => this.handleMultiSelectFilterChange(value, 'promotionType')}
            multiSelect
            onlyShowAllText
            hasSelectInitial
          />
          <CCDropDown
            id="ptAmount-dropdown"
            items={PromotionAmountTypeFilter}
            title={'Type'}
            valueKey="value"
            labelKey="label"
            selectedItems={this.state.filterQuery.promotionAmountType}
            onSelect={(value) => this.handleMultiSelectFilterChange(value, 'promotionAmountType')}
            multiSelect
            onlyShowAllText
          />
          <CCDropDown
            id="ptStatus-dropdown"
            items={PromotionStatusFilter}
            title={'Status'}
            valueKey="value"
            labelKey="label"
            selectedItems={this.state.filterQuery.promotionStatus}
            onSelect={(value) => this.handleMultiSelectFilterChange(value, 'promotionStatus')}
            multiSelect
            onlyShowAllText
          />
          <CCDropDown
            id="campain-dropdown"
            // items={items}
            items={this.state.campaignQuery.list}
            title={I18n.t('report.query.campaign_report')}
            // selectedItems={this.state.campaignQuery.campaignSelected}
            selectedItems={
              !this.state.campaignQuery.campaignSelected
                || this.state.campaignQuery.campaignSelected === 'all'
                ? []
                : [this.state.campaignQuery.campaignSelected.campaignId]
            }
            valueKey="value"
            labelKey="label"
            // onSelect={onSelect}
            onSelect={value => this.handleSelectCampaignId(value)}
            onSearchChange={this.searchCampaignDebounce}
            onScrollBottom={this.handleLoadMoreCampaignOptions}
            noTranslateLabel
            searchable
            async
            enableClearAll
            customTitle={(
              <OverlayTrigger
                overlay={<Tooltip id="driver-tooltip">{this.getCampaignDropdownTitle()}</Tooltip>}
                placement="top"
                delayShow={300}
                delayHide={150}
              >
                {this.getCampaignDropdownTitle()}
              </OverlayTrigger>
            )}
          />
          <Button
            className="btn-save"
            onClick={()=> {this.updatePromotionCodeList(0)}}
          >
            {/* <Translate value="promotionSettings.Add" /> */}
            View
          </Button>
          {this.state.isImportFile ? (
            <Import
              isShow={this.state.isImportFile}
              import={this.props.settingActions.importPromotionCodes}
              closeDialog={this.onCancelImportFile}
              callback={this.updatePromotionCodeList}
            />
          ) : null}
        </div>
        {/* <TableHeader
                    data={this.state.promotionCodeList} isHeader={true}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                    value={this.state.searchKey}
                    handleSearchKeyPress={this.searchKeyPressHandle}
                    handleSearchChange={this.searchStrChange}
                /> */}

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={promotionCodeList}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>

        {/* <table className="table table-striped table-hover">
                    <thead>
                        <tr className=''>
                            <th>
                                <CcCheckbox
                                    className='table-select-check'
                                    checked={this.state.promotionCodeList ? this.state.promotionCodeList.list.length == 0 ? false :
                                        this.state.promotionCodeList.list.filter(data => { return !data.selected }).length == 0 : false
                                    }
                                    onChange={(e) => { this.handleItemSelectedChange(null, e) }} />
                            </th>
                            <th><Translate value='promotionSettings.Promotion_code' /></th>
                            <th><Translate value='promotionSettings.Promotion_type' /></th>
                            <th><Translate value='promotionSettings.Campaign' /></th>
                            <th><Translate value='promotionSettings.Type' /></th>
                            <th><Translate value='promotionSettings.Value' /></th>
                            <th><Translate value='promotionSettings.Valid_from' /></th>
                            <th><Translate value='promotionSettings.Valid_to' /></th>
                            <th><Translate value='promotionSettings.Total_uses_user' /></th>
                            <th><Translate value='promotionSettings.Released_quantity' /></th>
                            <th><Translate value='promotionSettings.Number_of_sending_inbox' /></th>
                            <th><Translate value='promotionSettings.Status' /></th>
                            <th style={{ width: 70 }}><Translate value='promotionSettings.Actions' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderDataItemList()}
                    </tbody>
                </table> */}
        {/* <TableHeader
                    data={this.state.promotionCodeList} isHeader={false}
                    name={''}
                    handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                    handlePaginationSelect={this.handlePaginationSelect}
                    value={this.state.searchKey}
                    handleSearchKeyPress={this.searchKeyPressHandle}
                    handleSearchChange={this.searchStrChange}
                /> */}
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
        <ImportCustomer
          isShow={this.state.isShowImport}
          handleImportCustomerClick={this.handleImportCustomerClick}
          closeDialog={this.closeDialog}
        />
      </div>
    );
  }
}

PromotionCode.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
    paymentMethod: state.paymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    customerAutocompleteAction: bindActionCreators(
      customerAutocompleteAction,
      dispatch
    ),
    paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionCode);
