import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'react-moment';
import * as intercityRateActions from '../../../../../actions/intercityRateActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouterTableColumns } from '../tableHeaderData'
import FormGroupTitle from "../../../../../components/formGroupTitile/FormGroupTitle";
import StickyTable from '../../../../../components/table/stickyTable/StickyTable';
import { I18n, Translate } from 'react-redux-i18n';
import TableActions from '../../../../../components/table/tableAction/TableActions';
import AddEditIntercityRouteModal from './AddEditIntercityRouteModal';
import Confirm from '../../../../../components/confirm/Confirm';
import { BsSearch } from 'react-icons/bs';
class IntercityRoute extends PureComponent {
  state = {
    tableHeight: 500,
    tableHeight: 300,
    rowHeight: 100,
    routes: [],
    textSearch: '',
    footerData: {
      limit: 20,
      total: 0,
      page: 0,
    },
  };

  componentDidMount() {
    this.getIntercityRoutesList()
    this.setState({
      rowHeight: this.props.auth.selectedFleet.currencies.length < 3 ? 50 : 100,})
    if(this.props.routeInfoDeeplink) {
      this.setState({ 
        showDialog: true, 
        dialogData: {
          _id: this.props.routeInfoDeeplink?._id
        }, 
        editable: true 
      });
    }
  }

  handleSearchInModalChange = e => {
    let str = e.target.value;
    this.setState({ textSearch: str });
  };

  handleSearchInModalKeyPress = e => {
    if (e.key === "Enter") {
      this.getIntercityRoutesList();
    }
  };

  handleAddButtonClick = () => {
    this.setState({ showDialog: true, dialogData: null, editable: true });
  };

  handleUpdateRateSucess = () => {
    this.setState({ showDialog: false, dialogData: null });
    this.getIntercityRoutesList();
  }

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      showConfirm: false,
    });
  };

  handleMenuClick = (key, data) => {
    switch (key) {
      case 'View': {
        this.setState({
          showDialog: true,
          dialogData: data,
          editable: false
        })
        break;
      }
      case 'Edit': {
        this.setState({
          showDialog: true,
          dialogData: data,
          editable: true
        })
        break;
      }
      case 'Delete': {
        this.setState({
          confirmDelete: {
            id: 'CONFIRM_DELETE_ROUTE',
            title: I18n.t('intercityRoute.Delete_intercity_route'),
            body: I18n.t('intercityRoute.Delete_confirm_intercity_route'),
            buttonTitle: 'Yes',
            closeButtonText: 'No'
          },
          dialogData: data 
        });
        break;
      }
      default:
        break;
    }
  };

  handleConfirmButtonClick = () => {
    this.props.intercityRateActions
      .deleteIntercityRateRoute({
        fleetId: this.props.auth.selectedFleet.fleetId,
        routeId: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.handleConfirmCloseClick();
          this.getIntercityRoutesList();
          this.context.notification(
            'success',
            I18n.t('intercityRoute.Delete_intercity_route_success')
          );
        } else {
          this.handleConfirmCloseClick();
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('intercityRoute.Delete_intercity_route_fail')
            );
          }
        }
      });
  }

  handleConfirmCloseClick = () => {
    this.setState({
      confirmDelete: null,
      dialogData: null 
    });
  }

  getIntercityRoutesList = (activePage, limit) => {
    const { intercityRateId, auth } = this.props;
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: auth.selectedFleet.fleetId,
      query: {
        fleetId: auth.selectedFleet.fleetId,
        intercityRateId,
        search: this.state.textSearch
      }
    };

    this.props.intercityRateActions.getAllIntercityRateRoutes(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          routes: data.res.list,
          footerData: { page, total, limit }
        });
      }
    });
  };

  handlePaginationSelect = (eventKey) => {
    this.getIntercityRoutesList(eventKey)
  }

  handleNumItemsPerPageChange = e => {
    let numItemsPerPage = parseInt(e);
    this.getIntercityRoutesList(0, numItemsPerPage);
  };

  getRouterTableColumns = () => {
    const { editable, permissions } = this.props;
    const hasPermission = !permissions || permissions.actions;
    let tableColumns = Object.assign([], RouterTableColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "latestUpdate":
          col.customCell = route => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={route.latestUpdate}
              />
            );
          };
          break;
        case "actions":
          col.customCell = (route, rowIndex) => {
            if (hasPermission) {
              let actionItems = [
                {
                  label: "regularSetting.Edit",
                  eventKey: "Edit"
                },
                {
                  label: "regularSetting.Delete",
                  eventKey: "Delete"
                }
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={route}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, route);
                  }}
                  totalRow={this.state.routes.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={500}
                  tableId={"intercity-route-table-id"}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("Edit", route);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  render() {
    const { routes, footerData, rowHeight } = this.state;
    const { permissions } = this.props;
    const hasPermission = !permissions || permissions.actions;
    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="regularSetting.Routes" />
        </FormGroupTitle>
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarModalContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.flatRoutersStr}
                onKeyPress={this.handleSearchInModalKeyPress}
                onChange={this.handleSearchInModalChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {hasPermission ? (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="regularSetting.Add" />
              </Button>
            ) : (
              ''
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable" id="intercity-route-table-id">
          <StickyTable
            columns={this.getRouterTableColumns()}
            bodyData={routes}
            footerData={footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={rowHeight}
            getTableHeight={() => 500}
          />
        </div>
        {this.state.showDialog ? (
          <AddEditIntercityRouteModal
            closeDialogForm={this.closeDialogForm}
            onSuccess={this.handleUpdateRateSucess}
            routeInfo={this.state.dialogData}
            routeInfoDeeplink={this.props.routeInfoDeeplink} 
            editable={this.state.editable}
            intercityRateId={this.props.intercityRateId}
          />
        ) : null}
        <Confirm
          confirm={this.state.confirmDelete}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </Fragment>
    );
  }
}

IntercityRoute.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(IntercityRoute);
