import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    InputGroup,
    Form,
    Button,
    Modal,
    ButtonToolbar,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import _ from 'lodash';

import RadioButton from '../../../../components/radioButton/radio';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import Datetime from '../../../../components/dateTime/DateTime';
import UploadFile from '../../../../components/upload/upload';
import Select from "../../../../components/select/Select";
import ImportModal from "../../../../components/message/Import";
import UploadImageTemplate from "../../../../components/upload/UploadImageTemplate";
import copy from 'copy-to-clipboard';


import { Validator, ValidCase } from '../../../../components/validator';
import {
    Validation
} from '../../../../utils/commonFunctions';

import {
    UserEventType,
    EventType
} from '../../../../constants/commondata';
import { BsPlus } from 'react-icons/bs';


function AddEditEvent(props) {
    const {
        fleetId,
        data,
        currency,
        showDialogModal,
        closeFormModal,
        isSubmited,
        valid,
        disable,
        permissions,
        carTypes,
        zones,
        editable,
        format24Hour,
        ValidatorCallback,
        handleInputChange,
        handleUserTypeChange,
        handleCarTypeChange,
        handleZoneChange,
        handleEventTypeChange,
        handleStartDateChanged,
        handleEndDateChanged,
        onChangeLimit,
        handleImageChange,
        handleClickOpenSubModal,
        saveDialogForm,
        updateDialogForm,
        handleLoadOptionDebounce,
        handleCustomizedChange,
        handleRemoveClick,
        handleImportCustomerClick
    } = props;

    const [isEditable, setIsEditable] = useState(editable);
    const [allowEditAble, setAllowEditAble] = useState({});
    const [isShowImport, setIsShowImport] = useState(false);

    useEffect(() => {
        if (data.status === 'not_start') {
            setAllowEditAble({
                name: true,
                carType: true,
                operationZones: true,
                numOfQualify: true,
                isLuckyDraw: true,
                startDate: true,
                endDate: true,
                quests: true,
                criterias: true,
                bannerText: true,
                buttonText: true,
                bannerLink: true,
                shortLink: true,
                buttonLinkDesktop: true,
                buttonLinkAndroid: true,
                buttonLinkiOS: true,
                termsURL: true,
                metadataTitle: true,
                metadataDescription: true,
                isDarkMode: true,
                isActive: true,
            })
        };
        if (data.status === 'active') {
            setAllowEditAble({
                name: true,
                endDate: true,
                bannerText: true,
                buttonText: true,
                bannerLink: true,
                shortLink: true,
                buttonLinkDesktop: true,
                buttonLinkAndroid: true,
                buttonLinkiOS: true,
                termsURL: true,
                metadataTitle: true,
                metadataDescription: true,
                isDarkMode: true,
                isActive: true,
            })
        };
    }, [data]);


    const validFromDate = (current) => {
        var yesterday = Datetime.moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    }

    const handleSaveButtonClick = () => {
        if (data.id) {
            updateDialogForm(data);
        } else {
            saveDialogForm(data);
        };
    };

    const handleEditButtonClick = () => {
        setIsEditable(true);
    };

    const handleClickUploadImage = (changeFile, error, key) => {
        if (isEditable) {
            handleImageChange(changeFile, error, key)
        }
        return;
    };

    const handleCopyClick = (id) => {
        copy(id)
    }

    const checkDisable = (key) => {
        let isDisableStartDate = disable ? (
            isEditable && allowEditAble[key] ? false : true
        ) : false;
        return isDisableStartDate;
    };

    const handleImportClick = () => {
        if(isEditable){
            setIsShowImport(true);
        }
    }

    const handleRemoveInternalClick = () => {
        if(isEditable){
            handleRemoveClick();
        }
    }

    const closeDialog = () => {
        setIsShowImport(false);
    }

    return (
        <>
            <Modal
                onHide={closeFormModal}
                show={showDialogModal}
                dialogClassName="custom-modal event-detail-form"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {data.id ? (
                            <Translate value="quest_event.event_details" />
                        ) : (
                            <Translate value="quest_event.add_event" />
                        )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={closeFormModal}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.name === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.name" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('name', e)}
                            value={data
                                ? data.name
                                : ''}
                            placeholder={I18n.t('quest_event.name')}
                            disabled={checkDisable("name")}
                        />
                        <Validator id="name" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.name)}
                                message={I18n.t('quest_event.Please_input_name')}
                            />
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isMaxlength(data.name, 50)}
                                message={I18n.t('messages.commonMessages.Max_length').format(50)}
                            />
                        </Validator>
                    </FormGroup>
                    {data.id ? (
                        <FormGroup>
                            <Form.Label>
                                <Translate value="quest_event.eventId" />
                            </Form.Label>
                            <FormGroup className="qup-input-group">
                                <InputGroup className="single-addon-right">
                                    <FormControl
                                        className="form-custom"
                                        type="text"
                                        value={data.id}
                                        disabled={!editable}
                                    />
                                    <Button
                                        className="copy-button"
                                        onClick={() => { handleCopyClick(data.id) }}
                                    >
                                        <i class="fa fa-copy" /> Copy
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </FormGroup>
                    ) : (null)}
                    <FormGroup>
                        <Form.Label>
                            <Translate value="quest_event.userType" />
                        </Form.Label>
                        <select
                            type="select"
                            className="form-control form-custom"
                            onChange={handleUserTypeChange}
                            value={
                                data && data.userType ? data.userType : ''
                            }
                            placeholder={I18n.t('quest_event.userType')}
                            disabled={checkDisable("userType")}
                        >
                            <option value={UserEventType.driver}>
                                {I18n.t('quest_event.driver')}
                            </option>
                            <option value={UserEventType.passenger}>
                                {I18n.t('quest_event.passenger')}
                            </option>
                        </select>
                    </FormGroup>

                    {data.userType === 'driver' &&
                        <FormGroup
                            controlId="formControlsSelectMultiple"
                            className={isSubmited ? (valid.carTypeIds ? null : "error") : null}
                        >
                            <Form.Label>
                                <Translate value="quest_event.carType" />{" "}
                                <span className="require"> *</span>
                            </Form.Label>
                            <FormControl
                                className="form-custom select-zone"
                                as="select"
                                multiple
                                onChange={handleCarTypeChange}
                                value={data && data.carTypeIds ? data.carTypeIds : []}
                                disabled={checkDisable("carType")}
                            >
                                {carTypes.map(z => {
                                    return (
                                        <option key={z._id} value={z._id}>
                                            {z.vehicleType}
                                        </option>
                                    );
                                })}
                            </FormControl>
                            <Validator
                                id="carTypeIds"
                                callback={ValidatorCallback}
                                disabled={false}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.carTypeIds && data.carTypeIds.length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    }

                    {data.userType === 'driver' &&
                        <FormGroup
                            controlId="formControlsSelectMultiple"
                            className={isSubmited ? (valid.zoneIds ? null : "error") : null}
                        >
                            <Form.Label>
                                <Translate value="quest_event.operationZones" />{" "}
                                <span className="require"> *</span>
                            </Form.Label>
                            <FormControl
                                className="form-custom select-zone"
                                as="select"
                                multiple
                                onChange={handleZoneChange}
                                value={data && data.zoneIds ? data.zoneIds : []}
                                disabled={checkDisable("operationZones")}
                            >
                                {zones.map(z => {
                                    return (
                                        <option key={z._id} value={z._id}>
                                            {z.zoneName}
                                        </option>
                                    );
                                })}
                            </FormControl>
                            <Validator
                                id="zoneIds"
                                callback={ValidatorCallback}
                                disabled={false}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.zoneIds && data.zoneIds.length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    }

                    <FormGroup>
                        <Form.Label>
                            <Translate value="quest_event.type" />
                        </Form.Label>
                        <select
                            type="select"
                            className="form-control form-custom"
                            onChange={handleEventTypeChange}
                            value={
                                data && data.type ? data.type : ''
                            }
                            placeholder={I18n.t('quest_event.type')}
                            disabled={checkDisable("type")}
                        >
                            <option value={EventType.first_will_win}>
                                {I18n.t('quest_event.first_will_win')}
                            </option>
                            <option value={EventType.largest_will_win}>
                                {I18n.t('quest_event.largest_will_win')}
                            </option>
                        </select>
                    </FormGroup>

                    {data.type === 'first_will_win' ? (
                        <FormGroup>
                            <div className="radio-button-group">
                                <Form.Label>
                                    <Translate value="quest_event.numOfQualify" />
                                    {'\u00A0'}
                                    <span className="danger"> *</span>
                                </Form.Label>
                                <RadioButton
                                    text={I18n.t('quest_event.unlimited')}
                                    value="0"
                                    onChange={e => onChangeLimit('isUnlimited', e)}
                                    name="isUnlimited"
                                    id="isUnlimited_0"
                                    checked={data.isUnlimited}
                                    disabled={checkDisable("numOfQualify")}
                                />
                                <RadioButton
                                    text={I18n.t('quest_event.limited')}
                                    value="1"
                                    onChange={e => onChangeLimit('isUnlimited', e)}
                                    name="isUnlimited"
                                    id="isUnlimited_1"
                                    checked={!data.isUnlimited}
                                    disabled={checkDisable("numOfQualify")}
                                />
                            </div>
                        </FormGroup>
                    ) : null}

                    {!data.isUnlimited ? (
                        <FormGroup
                            className={!isSubmited ? null : valid.isLimitInvitation ? null : 'error'}
                        >
                            <FormControl
                                className="form-custom number-no-spin"
                                type="number"
                                min={0}
                                onChange={e => handleInputChange('numOfQualify', e)}
                                value={data.numOfQualify}
                                disabled={checkDisable("numOfQualify")}
                            />
                            <Validator id="isLimitInvitation" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={
                                        data.isUnlimited
                                        || Validation.isGreaterThan(data.numOfQualify, 0)
                                    }
                                    message={I18n.t('messages.commonMessages.greater_than_0')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : (
                        ''
                    )}

                    {data.type === 'first_will_win' ? (
                        <CcCheckbox
                            checked={data.isLuckyDraw}
                            disabled={checkDisable("isLuckyDraw")}
                            onChange={e => {
                                handleInputChange('isLuckyDraw', e);
                            }}
                            text={(
                                <span>
                                    <Translate value="quest_event.isLuckyDraw" />
                                </span>
                            )}
                        />
                    ) : null}

                    {/* Start Date */}
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.startDate
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.start_date" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <Datetime
                            onChange={handleStartDateChanged}
                            dateFormat="MM/DD/YYYY"
                            timeFormat={format24Hour ? "HH:mm" : "hh:mm a"}
                            inputProps={{
                                readOnly: true,
                                disabled: checkDisable("startDate")
                            }}
                            value={data.startDate ? moment(data.startDate).format(format24Hour ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY hh:mm a') : ''}
                            isValidDate={validFromDate}
                        />
                        <Validator id="startDate" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.startDate)}
                                message={I18n.t('quest_event.Please_select_date')}
                            />
                        </Validator>
                    </FormGroup>

                    {/* End Date */}
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.endDate
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.end_date" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <Datetime
                            onChange={handleEndDateChanged}
                            dateFormat="MM/DD/YYYY"
                            timeFormat={format24Hour ? "HH:mm" : "hh:mm a"}
                            inputProps={{
                                readOnly: true,
                                disabled: checkDisable("endDate")
                            }}
                            value={data.endDate ? moment(data.endDate).format(format24Hour ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY hh:mm a') : ''}
                            isValidDate={validFromDate}
                        />
                        <Validator id="endDate" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.endDate)}
                                message={I18n.t('quest_event.Please_select_date')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className={`app-banner-wrapper ${isSubmited ? (valid.eventImage === false ? 'error' : null) : null}`}
                    >
                        <Form.Label>
                            <Translate value="quest_event.eventImage" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <br />
                        {data.eventImage ? (
                            <a
                                href={data.eventImage}
                                className="text-active"
                                target="_blank"
                            >
                                <Translate value="quest_event.Click_here_to_view" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="eventImage"
                            name="eventImage"
                            onChange={(data, error) => handleClickUploadImage(data, error, 'eventImage')}
                            customTemplate={UploadImageTemplate}
                            className="event-upload-image"
                            customTemplateProps={{
                                fileResult: data.eventImage || null,
                                noImageTitle: "quest_event.Upload_an_image"
                            }}
                            accepts="image/*"
                            onlyImage
                            note={true}
                            disabled={!isEditable}
                        />
                        <Validator
                            id="eventImage"
                            callback={ValidatorCallback}
                            disabled={!isEditable}
                        >
                            <ValidCase
                                hide={!isSubmited}
                                valid={data.eventImage && data.eventImage.trim().length > 0}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className="app-banner-wrapper"
                    >
                        <Form.Label>
                            <Translate value="quest_event.sharedImage" />{' '}
                        </Form.Label>
                        <br />
                        {data.sharedImage ? (
                            <a
                                href={data.sharedImage}
                                className="text-active"
                                target="_blank"
                            >
                                <Translate value="quest_event.Click_here_to_view" />
                            </a>
                        ) : null}
                        <UploadFile
                            id="sharedImage"
                            name="sharedImage"
                            onChange={(data, error) => handleClickUploadImage(data, error, 'sharedImage')}
                            customTemplate={UploadImageTemplate}
                            className="event-upload-image"
                            customTemplateProps={{
                                fileResult: data.sharedImage || null,
                                noImageTitle: "quest_event.Upload_an_image"
                            }}
                            accepts="image/*"
                            onlyImage
                            note={true}
                            disabled={!isEditable}
                        />
                    </FormGroup>

                    {data.type === 'first_will_win' ? (
                        <FormGroup
                            className={`app-banner-wrapper ${isSubmited ? (valid.qualifiedImage === false ? 'error' : null) : null}`}
                        >
                            <Form.Label>
                                <Translate value="quest_event.qualifiedImage" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <br />
                            {data.qualifiedImage ? (
                                <a
                                    href={data.qualifiedImage}
                                    className="text-active"
                                    target="_blank"
                                >
                                    <Translate value="quest_event.Click_here_to_view" />
                                </a>
                            ) : null}
                            <UploadFile
                                id="qualifiedImage"
                                name="qualifiedImage"
                                onChange={(data, error) => handleClickUploadImage(data, error, 'qualifiedImage')}
                                customTemplate={UploadImageTemplate}
                                className="event-upload-image"
                                customTemplateProps={{
                                    fileResult: data.qualifiedImage || null,
                                    noImageTitle: "quest_event.Upload_an_image"
                                }}
                                accepts="image/*"
                                onlyImage
                                note={true}
                                disabled={!isEditable}
                            />
                            <Validator
                                id="qualifiedImage"
                                callback={ValidatorCallback}
                                disabled={!isEditable}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.qualifiedImage && data.qualifiedImage.trim().length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : null}

                    {data.type === 'first_will_win' ? (
                        <FormGroup
                            className={`app-banner-wrapper ${isSubmited ? (valid.expiredImage === false ? 'error' : null) : null}`}
                        >
                            <Form.Label>
                                <Translate value="quest_event.expiredImage" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <br />
                            {data.expiredImage ? (
                                <a
                                    href={data.expiredImage}
                                    className="text-active"
                                    target="_blank"
                                >
                                    <Translate value="quest_event.Click_here_to_view" />
                                </a>
                            ) : null}
                            <UploadFile
                                id="expiredImage"
                                name="expiredImage"
                                onChange={(data, error) => handleClickUploadImage(data, error, 'expiredImage')}
                                customTemplate={UploadImageTemplate}
                                className="event-upload-image"
                                customTemplateProps={{
                                    fileResult: data.expiredImage || null,
                                    noImageTitle: "quest_event.Upload_an_image"
                                }}
                                accepts="image/*"
                                onlyImage
                                note={true}
                                disabled={!isEditable}
                            />
                            <Validator
                                id="expiredImage"
                                callback={ValidatorCallback}
                                disabled={!isEditable}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.expiredImage && data.expiredImage.trim().length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : null}

                    {data.isLuckyDraw ? (
                        <FormGroup
                            className={`app-banner-wrapper ${isSubmited ? (valid.luckyDrawImage === false ? 'error' : null) : null}`}
                        >
                            <Form.Label>
                                <Translate value="quest_event.luckyDrawImage" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <br />
                            {data.luckyDrawImage ? (
                                <a
                                    href={data.luckyDrawImage}
                                    className="text-active"
                                    target="_blank"
                                >
                                    <Translate value="quest_event.Click_here_to_view" />
                                </a>
                            ) : null}
                            <UploadFile
                                id="luckyDrawImage"
                                name="luckyDrawImage"
                                onChange={(data, error) => handleClickUploadImage(data, error, 'luckyDrawImage')}
                                customTemplate={UploadImageTemplate}
                                className="event-upload-image"
                                customTemplateProps={{
                                    fileResult: data.luckyDrawImage || null,
                                    noImageTitle: "quest_event.Upload_an_image"
                                }}
                                accepts="image/*"
                                onlyImage
                                note={true}
                                disabled={!isEditable}
                            />
                            <Validator
                                id="luckyDrawImage"
                                callback={ValidatorCallback}
                                disabled={!isEditable}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.luckyDrawImage && data.luckyDrawImage.trim().length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : null}

                    <FormGroup>
                        <div className="text-base extension-requirement mt">
                            <Translate value="quest_event.Required_Image" />
                        </div>
                    </FormGroup>

                    {/* Rank Image */}
                    {data.type === 'largest_will_win' ? (
                        <FormGroup
                            className={`app-banner-wrapper ${isSubmited ? (valid.rankImages === false ? 'error' : null) : null}`}
                        >
                            <Form.Label>
                                <Translate value="quest_event.rankImages" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <div className="model-list-container">
                                <table className="table table-no-pagination cc-table-striped">
                                    <thead>
                                        <tr className="">
                                            <th>
                                                <Translate value="quest_event.reward" />
                                            </th>
                                            <th>
                                                <Translate value="quest_event.image" />
                                            </th>
                                            <th>
                                                {/* <Translate value="quest_event.quantity" /> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.rankImages
                                            ? data.rankImages.map((m, index) => {
                                                let title = `${m.from} - ${m.to === Number.MAX_SAFE_INTEGER ? Infinity : m.to}`;
                                                return (
                                                    <tr key={index}>
                                                        <td>{title}</td>
                                                        <td>
                                                            <img
                                                                className="model-thumbnail"
                                                                src={m.image}
                                                            />
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={m.image}
                                                                className="text-active"
                                                                target="_blank"
                                                            >
                                                                <Translate value="quest_event.view" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </table>
                                <ButtonToolbar className="text-center header-button-group">
                                    {isEditable ? (
                                        <Button
                                            className={"btn-header text-add-header ml0"}
                                            onClick={e => handleClickOpenSubModal('rankImages')}
                                        >
                                            {" "}
                                            <BsPlus />
                                            <Translate value="quest_event.change" />
                                        </Button>

                                    ) : null}
                                </ButtonToolbar>
                            </div>
                            <Validator
                                id="rankImages"
                                callback={ValidatorCallback}
                                disabled={false}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.rankImages && data.rankImages.length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : null}

                    {/* Quests */}
                    <FormGroup
                        className={`app-banner-wrapper ${isSubmited ? (valid.quests === false ? 'error' : null) : null}`}
                    >
                        <Form.Label>
                            <Translate value="quest_event.Quests" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <div className="model-list-container">
                            <table className="table table-no-pagination cc-table-striped">
                                <thead>
                                    <tr className="quest-event">
                                        <th>
                                            <Translate value="quest_event.metricType" />
                                        </th>
                                        <th>
                                            <Translate value="quest_event.task" />
                                        </th>
                                        {data.type === EventType.first_will_win ? (
                                            <th>
                                                <Translate value="quest_event.quantity" />
                                            </th>
                                        ) : null}
                                        {data.type === EventType.largest_will_win ? (
                                            <th>
                                                <Translate value="quest_event.point_per_task" />
                                            </th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.quests
                                        ? data.quests.map((m, index) => {
                                            return (
                                                <tr key={index} className="quest-event">
                                                    <td>{I18n.t(`quest_event.${data.userType === 'passenger' ? 'passengerMetric' : 'driverMetric'}.${m.metricType}`)}</td>
                                                    <td>{m.task}</td>
                                                    {data.type === EventType.first_will_win ? (
                                                        <td>{m.value}</td>
                                                    ) : null}
                                                    {data.type === EventType.largest_will_win ? (
                                                        <td>{m.points}{' '}{I18n.t('quest_event.points')}</td>
                                                    ) : null}
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                            <ButtonToolbar className="text-center header-button-group">
                                {!checkDisable("quests") ? (
                                    <Button
                                        className={"btn-header text-add-header ml0"}
                                        onClick={e => handleClickOpenSubModal('quests')}
                                    >
                                        {" "}
                                        <BsPlus />
                                        <Translate value="quest_event.change" />
                                    </Button>
                                ) : null}
                            </ButtonToolbar>
                        </div>
                        <Validator
                            id="quests"
                            callback={ValidatorCallback}
                            disabled={false}
                        >
                            <ValidCase
                                hide={!isSubmited}
                                valid={data.quests && data.quests.length > 0}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    </FormGroup>

                    {/* Criteria */}
                    {data.userType === 'driver' ? (
                        <FormGroup
                            className="app-banner-wrapper"
                        >
                            <Form.Label>
                                <Translate value="quest_event.criterias" />{' '}
                            </Form.Label>
                            <div className="model-list-container">
                                <table className="table table-no-pagination cc-table-striped">
                                    <thead>
                                        <tr className="quest-event">
                                            <th>
                                                <Translate value="quest_event.criteriaType" />
                                            </th>
                                            <th>
                                                <Translate value="quest_event.criteriaValue" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.criterias
                                            ? data.criterias.map((m, index) => {
                                                return (
                                                    <tr key={index} className="quest-event">
                                                        <td>{<Translate value={`quest_event.${m.criteriaType}`} />}</td>
                                                        <td>{m.value}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </table>
                                <ButtonToolbar className="text-center header-button-group">
                                    {!checkDisable("criterias") ? (
                                        <Button
                                            className={"btn-header text-add-header ml0"}
                                            onClick={e => handleClickOpenSubModal('criterias')}
                                        >
                                            {" "}
                                            <BsPlus />
                                            <Translate value="quest_event.change" />
                                        </Button>
                                    ) : null}
                                </ButtonToolbar>
                            </div>
                        </FormGroup>
                    ) : null}

                    {/* Qualified Prizes */}
                    <FormGroup
                        className={`app-banner-wrapper ${isSubmited ? (valid.qualifiedPrizeOrder === false ? 'error' : null) : null}`}
                    >
                        <Form.Label>
                            <Translate value="quest_event.qualifiedPrizeOrder" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <div className="model-list-container">
                            <table className="table table-no-pagination cc-table-striped">
                                <thead>
                                    <tr className="">
                                        <th>
                                            <Translate value="quest_event.reward" />
                                        </th>
                                        <th>
                                            <Translate value="quest_event.image" />
                                        </th>
                                        <th>
                                            {/* <Translate value="quest_event.quantity" /> */}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.qualifiedPrizeOrder
                                        ? data.qualifiedPrizeOrder.map((m, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{m.reward}</td>
                                                    <td>
                                                        <img
                                                            className="model-thumbnail"
                                                            src={m.image}
                                                        />
                                                    </td>
                                                    <td>
                                                        <a
                                                            href={m.image}
                                                            className="text-active"
                                                            target="_blank"
                                                        >
                                                            <Translate value="quest_event.view" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                            <ButtonToolbar className="text-center header-button-group">
                                {isEditable ? (
                                    <Button
                                        className={"btn-header text-add-header ml0"}
                                        onClick={e => handleClickOpenSubModal('qualifiedPrizeOrder')}
                                    >
                                        {" "}
                                        <BsPlus />
                                        <Translate value="quest_event.change" />
                                    </Button>
                                ) : null}
                            </ButtonToolbar>
                        </div>
                        <Validator
                            id="qualifiedPrizeOrder"
                            callback={ValidatorCallback}
                            disabled={false}
                        >
                            <ValidCase
                                hide={!isSubmited}
                                valid={data.qualifiedPrizeOrder && data.qualifiedPrizeOrder.length > 0}
                                message={I18n.t('messages.commonMessages.Required_field')}
                            />
                        </Validator>
                    </FormGroup>

                    {/* Lucky Draw Prizes */}
                    {data.isLuckyDraw ? (
                        <FormGroup
                            className={`app-banner-wrapper ${isSubmited ? (valid.luckyDrawPrizeOrder === false ? 'error' : null) : null}`}
                        >
                            <Form.Label>
                                <Translate value="quest_event.luckyDrawPrizeOrder" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <div className="model-list-container">
                                <table className="table table-no-pagination cc-table-striped">
                                    <thead>
                                        <tr className="">
                                            <th>
                                                <Translate value="quest_event.reward" />
                                            </th>
                                            <th>
                                                <Translate value="quest_event.image" />
                                            </th>
                                            <th>
                                                {/* <Translate value="quest_event.quantity" /> */}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.luckyDrawPrizeOrder
                                            ? data.luckyDrawPrizeOrder.map((m, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{m.reward}</td>
                                                        <td>
                                                            <img
                                                                className="model-thumbnail"
                                                                src={m.image}
                                                            />
                                                        </td>
                                                        <td>
                                                            <a
                                                                href={m.image}
                                                                className="text-active"
                                                                target="_blank"
                                                            >
                                                                <Translate value="quest_event.view" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </table>
                                <ButtonToolbar className="text-center header-button-group">
                                    {isEditable ? (
                                        <Button
                                            className={"btn-header text-add-header ml0"}
                                            onClick={e => handleClickOpenSubModal('luckyDrawPrizeOrder')}
                                        >
                                            {" "}
                                            <BsPlus />
                                            <Translate value="quest_event.change" />
                                        </Button>
                                    ) : null}
                                </ButtonToolbar>
                            </div>
                            <Validator
                                id="luckyDrawPrizeOrder"
                                callback={ValidatorCallback}
                                disabled={false}
                            >
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={data.luckyDrawPrizeOrder && data.luckyDrawPrizeOrder.length > 0}
                                    message={I18n.t('messages.commonMessages.Required_field')}
                                />
                            </Validator>
                        </FormGroup>
                    ) : null}
                    <FormGroup>
                        <Form.Label className="label_import">
                            <div>
                                <Translate value="quest_event.suspend_list" />
                            </div>
                            {
                                (!data.phoneSuspendList || data.phoneSuspendList.length == 0) ?
                                    (
                                        <div onClick={handleImportClick} className="text-add-header btn-import">
                                            <Translate value="customer.Import" />
                                        </div>
                                    ) : (
                                        <div onClick={handleRemoveInternalClick} className="text-add-header btn-import">
                                            {I18n.t('customer.Delete')}
                                        </div>
                                    )
                            }
                        </Form.Label>
                        {(!data.phoneSuspendList || data.phoneSuspendList.length == 0) && <Select.Async
                            multi={true}
                            disabled={!isEditable}
                            valueKey="id"
                            labelKey="value"
                            cache={false}
                            placeholder=""
                            searchPromptText={
                                data.userType === UserEventType.driver
                                    ? I18n.t(
                                        "message.Search_driver_name_driver_phone_number"
                                    )
                                    : I18n.t(
                                        "message.Search_customer_name_customer_phone_number"
                                    )
                            }
                            loadingPlaceholder={I18n.t("message.Searching")}
                            noResultsText={I18n.t("message.No_results")}
                            className="receiver-list custom-select-control"
                            value={data.suspendList}
                            loadOptions={handleLoadOptionDebounce}
                            onChange={handleCustomizedChange}
                        // disabled={!this.state.driverStatus.length}
                        />}
                        {data.fileNameSuspendList &&
                            (data.linkSuspendList ?
                                <a className="fileNameImport" href={data.linkSuspendList}>{data.fileNameSuspendList}</a> :
                                <p className="fileNameImport">{data.fileNameSuspendList}</p>)}
                    </FormGroup>
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.bannerText === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.bannerText" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            as="textarea"
                            onChange={e => handleInputChange('bannerText', e)}
                            value={data
                                ? data.bannerText
                                : ''}
                            placeholder={I18n.t('quest_event.bannerText')}
                            disabled={checkDisable("bannerText")}
                            maxLength={200}
                        />
                        <Validator id="bannerText" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.bannerText)}
                                message={I18n.t('quest_event.Please_input_bannerText')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.buttonText === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.buttonText" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('buttonText', e)}
                            value={data
                                ? data.buttonText
                                : ''}
                            placeholder={I18n.t('quest_event.buttonText')}
                            disabled={checkDisable("buttonText")}
                        />
                        <Validator id="buttonText" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.buttonText)}
                                message={I18n.t('quest_event.Please_input_buttonText')}
                            />
                        </Validator>
                    </FormGroup>

                    {/* <FormGroup
                    className={
                        !isSubmited
                            ? null
                            : valid.bannerLink === true
                                ? null
                                : 'error'
                    }
                >
                    <Form.Label>
                        <Translate value="quest_event.bannerLink" />{' '}
                        <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                        type="text"
                        className="form-custom"
                        onChange={e => handleInputChange('bannerLink', e)}
                        value={data
                            ? data.bannerLink
                            : ''}
                        placeholder={I18n.t('quest_event.bannerLink')}
                        disabled={checkDisable("bannerLink")}
                    />
                    <Validator id="bannerLink" callback={ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmited}
                            valid={!Validation.isStringEmpty(data.bannerLink)}
                            message={I18n.t('quest_event.Please_input_bannerLink')}
                        />
                    </Validator>
                </FormGroup> */}

                    <div className={"btn_link_group"}>
                        <FormGroup
                            className={
                                !isSubmited
                                    ? null
                                    : valid.buttonLinkDesktop === true
                                        ? null
                                        : 'error'
                            }
                        >
                            <Form.Label>
                                <Translate value="quest_event.buttonLinkDesktop" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleInputChange('buttonLinkDesktop', e)}
                                value={data
                                    ? data.buttonLinkDesktop
                                    : ''}
                                placeholder={I18n.t('quest_event.buttonLinkDesktop')}
                                disabled={checkDisable("buttonLinkDesktop")}
                            />
                            <Validator id="buttonLinkDesktop" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={!Validation.isStringEmpty(data.buttonLinkDesktop)}
                                    message={I18n.t('quest_event.Please_input_buttonLinkDesktop')}
                                />
                            </Validator>
                        </FormGroup>

                        <FormGroup
                            className={
                                !isSubmited
                                    ? null
                                    : valid.buttonLinkAndroid === true
                                        ? null
                                        : 'error'
                            }
                        >
                            <Form.Label>
                                <Translate value="quest_event.buttonLinkAndroid" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleInputChange('buttonLinkAndroid', e)}
                                value={data
                                    ? data.buttonLinkAndroid
                                    : ''}
                                placeholder={I18n.t('quest_event.buttonLinkAndroid')}
                                disabled={checkDisable("buttonLinkAndroid")}
                            />
                            <Validator id="buttonLinkAndroid" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={!Validation.isStringEmpty(data.buttonLinkAndroid)}
                                    message={I18n.t('quest_event.Please_input_buttonLinkAndroid')}
                                />
                            </Validator>
                        </FormGroup>

                        <FormGroup
                            className={
                                !isSubmited
                                    ? null
                                    : valid.buttonLinkiOS === true
                                        ? null
                                        : 'error'
                            }
                        >
                            <Form.Label>
                                <Translate value="quest_event.buttonLinkiOS" />{' '}
                                <span className="require">*</span>
                            </Form.Label>
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleInputChange('buttonLinkiOS', e)}
                                value={data
                                    ? data.buttonLinkiOS
                                    : ''}
                                placeholder={I18n.t('quest_event.buttonLinkiOS')}
                                disabled={checkDisable("buttonLinkiOS")}
                            />
                            <Validator id="buttonLinkiOS" callback={ValidatorCallback}>
                                <ValidCase
                                    hide={!isSubmited}
                                    valid={!Validation.isStringEmpty(data.buttonLinkiOS)}
                                    message={I18n.t('quest_event.Please_input_buttonLinkiOS')}
                                />
                            </Validator>
                        </FormGroup>
                    </div>

                    <FormGroup>
                        <Form.Label>
                            <Translate value="quest_event.shortLink" />{' '}
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('shortLink', e)}
                            value={data
                                ? data.shortLink
                                : ''}
                            placeholder={I18n.t('quest_event.shortLink')}
                            disabled={checkDisable("shortLink")}
                        />
                    </FormGroup>

                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.termsURL === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.termsURL" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('termsURL', e)}
                            value={data
                                ? data.termsURL
                                : ''}
                            placeholder={I18n.t('quest_event.termsURL')}
                            disabled={checkDisable("termsURL")}
                        />
                        <Validator id="termsURL" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.termsURL)}
                                message={I18n.t('quest_event.Please_input_termsURL')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.metadataTitle === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.metadataTitle" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('metadataTitle', e)}
                            value={data
                                ? data.metadataTitle
                                : ''}
                            placeholder={I18n.t('quest_event.metadataTitle')}
                            disabled={checkDisable("metadataTitle")}
                        />
                        <Validator id="metadataTitle" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.metadataTitle)}
                                message={I18n.t('quest_event.Please_input_metadataTitle')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.metadataDescription === true
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="quest_event.metadataDescription" />{' '}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className="form-custom"
                            onChange={e => handleInputChange('metadataDescription', e)}
                            value={data
                                ? data.metadataDescription
                                : ''}
                            placeholder={I18n.t('quest_event.metadataDescription')}
                            disabled={checkDisable("metadataDescription")}
                        />
                        <Validator id="metadataDescription" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(data.metadataDescription)}
                                message={I18n.t('quest_event.Please_input_metadataDescription')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup>
                        <CcCheckbox
                            checked={data.isDarkMode}
                            disabled={checkDisable("isDarkMode")}
                            onChange={e => {
                                handleInputChange('isDarkMode', e);
                            }}
                            text={(
                                <span>
                                    <Translate value="quest_event.isDarkMode" />
                                </span>
                            )}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CcCheckbox
                            checked={data.isActive}
                            disabled={checkDisable("isActive")}
                            onChange={e => {
                                handleInputChange('isActive', e);
                            }}
                            text={(
                                <span>
                                    <Translate value="quest_event.isActive_details" />
                                </span>
                            )}
                            labelClassName="bold"
                        />
                    </FormGroup>
                    <Form.Label>
                        (
                        <span className="require">*</span>
                        ):
                        {' '}
                        <Translate value="quest_event.Required_fields" />
                    </Form.Label>

                </Modal.Body>

                <Modal.Footer>
                    {!permissions || permissions.actions && data.status !== 'closed' ? (
                        isEditable ? (
                            <Button className="btn-save mr-md" onClick={handleSaveButtonClick}>
                                <Translate value="quest_event.Save" />
                            </Button>
                        ) : (
                            <Button className="btn-save mr-md" onClick={handleEditButtonClick}>
                                <Translate value="quest_event.Edit" />
                            </Button>
                        )
                    ) : null}
                    <Button className="btn-cancel" onClick={closeFormModal}>
                        <Translate value="quest_event.Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>

            <ImportModal
                isShow={isShowImport}
                handleImportCustomerClick={handleImportCustomerClick}
                closeDialog={closeDialog}
                templeteLink={`${process.env.REACT_APP_S3_SERVER}/templates/Suspend_List_Import_Template.xlsx`}
                appType={data.userType}
            />
        </>
    )
}

export default AddEditEvent