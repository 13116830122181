import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import ReactDOM from 'react-dom';

import { Tabs, Tab } from 'react-bootstrap';
import CorpTraveler from './CorpTraveler';
import CorpOperator from './CorpOperator';
import PaymentMethod from './PaymentMethod';
import Pricing from './Pricing';
import Supplier from './Supplier';
import Add from './Add';
import Apps from './Apps';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { detailCorporate } from '../../actions/corporateAction';

import './style.scss';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const TAB_NAME = {
  Add: 'Add',
  CorpTraveler: 'CorpTraveler',
  PaymentMethod: 'PaymentMethod',
  CorpOperator: 'CorpOperator',
  Pricing: 'Pricing',
  Supplier: 'Supplier',
  Apps: 'Apps',
}

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.props.auth.user.userType !== 'CorporateUser' ? TAB_NAME.Add : TAB_NAME.CorpTraveler,
      contentHeight: 0,
      contentWidth: 0,
      corporateId: ""
    };
    this.handleAddCardSuccess = this.handleAddCardSuccess.bind(this);
    this.handleTopUpSuccess = this.handleTopUpSuccess.bind(this);
    this.handleDeleteCardSuccess = this.handleDeleteCardSuccess.bind(this);
    this.handleDirectBillingChange = this.handleDirectBillingChange.bind(this);
    this.handlePrepaidBillingChange = this.handlePrepaidBillingChange.bind(this);
  }

  componentDidMount() {
    if (this.props.params.corporateId) {
      this.setState({
        corporateId: this.props.params.corporateId
      }, () => {
        this.getCorporateDetails();
      });
    }
  };

  getCorporateDetails = (isFromTsysGateway = false) => {
    const { selectedFleet } = this.props.auth;
    const { corporateId } = this.state;
    this.props
      .detailCorporate({
        _id: corporateId,
        fleetId: selectedFleet.fleetId
      })
      .then(data => {
        if (data.ok && data.res) {
          const paymentMethods = data.res.paymentMethods.map(pm => {
            pm.checked = pm.isActive;
            return pm;
          });
          data.res.paymentMethods = paymentMethods;
          this.setState({ data: data.res, currentUser: data.res }, () => {
            if (this.props.auth.user.userType === 'CorporateUser' && !isFromTsysGateway) this.handleTabSelect(2);
          });
        } else {
          this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
          this.props.router.push({
            pathname: '/corporate'
          });
        }
      });
  };

  handleAddCardSuccess(credit, callback) {
    const { credits } = this.state.data;
    credits.push(credit);
    this.setState({ data: { ...this.state.data, credits } }, callback);
  }

  handleTopUpSuccess(amount, callback, is3ds) {
    const paymentMethods = this.state.data.paymentMethods.map(pm => {
      if (pm.type === 'prepaid') {
        if(is3ds) {
          pm.balance = amount
        } else {
          pm.balance += parseFloat(amount);
        }
      }
      return pm;
    });
    this.setState({ data: { ...this.state.data, paymentMethods } }, callback);
  }

  handleEditBalanceSuccess = (amount) => {
    const paymentMethods = this.state.data.paymentMethods.map(pm => {
      if (pm.type === 'prepaid') {
        pm.balance = amount;
      }
      return pm;
    });
    this.setState({ data: { ...this.state.data, paymentMethods } });
  }

  handleDeleteCardSuccess(credit, callback) {
    const remainCredits = this.state.data.credits;
    const index = remainCredits.indexOf(credit);
    remainCredits.splice(index, 1);
    this.setState({ data: { ...this.state.data, credits: remainCredits } }, callback);
  }

  handleDirectBillingChange(e) {
    const paymentMethods = this.state.data.paymentMethods.map(p => {
      if (p.type === 'directBilling') {
        p.checked = e.target.checked;
      }
      return p;
    });
    this.setState({
      data: { ...this.state.data, paymentMethods }
    });
  }

  handlePrepaidBillingChange(e) {
    const paymentMethods = this.state.data.paymentMethods.map(p => {
      if (p.type === 'prepaid') {
        p.checked = e.target.checked;
      }
      return p;
    });
    this.setState({
      data: { ...this.state.data, paymentMethods }
    });
  }

  handleCashChange = (e) => {
    const paymentMethods = this.state.data.paymentMethods.map(p => {
      if (p.type === 'cash') {
        p.checked = e.target.checked;
      }
      return p;
    });
    this.setState({
      data: { ...this.state.data, paymentMethods }
    });
  }

  handleEasyPaisaChange = (e) => {
    const paymentMethods = this.state.data.paymentMethods.map(p => {
      if (p.type === 'easyPaisa') {
        p.checked = e.target.checked;
      }
      return p;
    });
    this.setState({
      data: { ...this.state.data, paymentMethods }
    });
  }

  handleJazzCashChange = (e) => {
    const paymentMethods = this.state.data.paymentMethods.map(p => {
      if (p.type === 'jazzCash') {
        p.checked = e.target.checked;
      }
      return p;
    });
    this.setState({
      data: { ...this.state.data, paymentMethods }
    });
  }

  handleTabSelect = key => {
    const contentHeight = this.getContentHeight();
    const contentWidth = this.tabWrapper ? ReactDOM.findDOMNode(this.tabWrapper).clientWidth : 350;
    this.setState({ contentWidth, contentHeight, currentTab: key });
    // if ([TAB_NAME.CorpOperator, TAB_NAME.CorpTraveler, TAB_NAME.Supplier].includes(key)) {
    // } else {
    //   this.setState({ currentTab: key });
    // }
  };

  getContentHeight = () => {
    const parentHeight = this.tabWrapper ? ReactDOM.findDOMNode(this.tabWrapper).clientHeight : 350;
    const tabListHeight = 36;
    const contentHeight = parentHeight - tabListHeight;
    return contentHeight > 0 ? contentHeight : 0;
  };

  render() {
    if (this.state.data) {
      return (
        <div className="content editCorp overflow-auto" ref={node => (this.tabWrapper = node)}>
          <Tabs
            activeKey={this.state.currentTab}
            id="controlled-tab"
            className={
              `tabs-wrapper edit-corporate-wrapper${this.state.currentTab === 3 ? ' payment-method-tab' : ''}`
            }
            onSelect={this.handleTabSelect}
          >
            {this.props.auth.user.userType !== 'CorporateUser' ? (
              <Tab eventKey={TAB_NAME.Add} title={I18n.t('corporate.Account')}>
                <Add
                  data={this.state.data}
                  corporateId={this.props.params.corporateId}
                  router={this.props.router}
                />
              </Tab>
            ) : null}
            <Tab eventKey={TAB_NAME.CorpTraveler} title={I18n.t('corporate.Corp_traveler')}>
              <CorpTraveler
                corporateId={this.props.params.corporateId}
                router={this.props.router}
                contentHeight={this.state.contentHeight}
                contentWidth={this.state.contentWidth}
                getContentHeight={this.getContentHeight}
                currentUser={this.state.currentUser}
                currentTab={this.state.currentTab}
              />
            </Tab>
            <Tab eventKey={TAB_NAME.PaymentMethod} title={I18n.t('corporate.Payment_methods')}>
              <PaymentMethod
                credits={this.state.data.credits}
                getCorporateDetails={this.getCorporateDetails}
                paymentMethods={this.state.data.paymentMethods}
                currentUser={this.state.currentUser}
                corporateId={this.props.params.corporateId}
                router={this.props.router}
                handleAddCardSuccess={this.handleAddCardSuccess}
                handleEditBalanceSuccess={this.handleEditBalanceSuccess}
                handleDeleteCardSuccess={this.handleDeleteCardSuccess}
                handleTopUpSuccess={this.handleTopUpSuccess}
                handleDirectBillingChange={this.handleDirectBillingChange}
                handlePrepaidBillingChange={this.handlePrepaidBillingChange}
                handleCashChange={this.handleCashChange}
                handleEasyPaisaChange={this.handleEasyPaisaChange}
                handleJazzCashChange={this.handleJazzCashChange}
              />
            </Tab>
            {this.props.auth.user.userType !== 'CorporateUser' ? (
              <Tab eventKey={TAB_NAME.CorpOperator} title={I18n.t('corporate.Corp_operators')}>
                <CorpOperator
                  corporateId={this.props.params.corporateId}
                  router={this.props.router}
                  contentHeight={this.state.contentHeight}
                  contentWidth={this.state.contentWidth}
                  getContentHeight={this.getContentHeight}
                  currentTab={this.state.currentTab}
                />
              </Tab>
            ) : null}
            {this.props.auth.user.userType !== 'CorporateUser'
              && this.props.auth.user.userType != 'CorporateAdmin' ? (
              <Tab eventKey={TAB_NAME.Pricing} title={I18n.t('corporate.Pricing')}>
                <Pricing
                  corporateId={this.props.params.corporateId}
                  router={this.props.router}
                  pricing={this.state.data.pricing}
                  contentHeight={this.state.contentHeight}
                  contentWidth={this.state.contentWidth}
                  getContentHeight={this.getContentHeight}
                  currentTab={this.state.currentTab}
                  handleReloadData={this.getCorporateDetails}
                />
              </Tab>
            ) : null}
            {
              this.props.auth.user.userType !== 'CorporateUser'
              && this.props.auth.user.userType != 'CorporateAdmin'
              && (
                <Tab eventKey={TAB_NAME.Supplier} title={I18n.t('corporate.Supplier')}>
                  <Supplier
                    corporateId={this.props.params.corporateId}
                    companyList={this.props.commonData && [...this.props.commonData.companies, ...this.props.commonData.suppliers]}
                    supplier={this.state.data &&this.state.data.supplier}
                  />
                </Tab>
              )
            }
            {
              this.props.auth.user.userType !== 'CorporateUser' && (
                <Tab eventKey={TAB_NAME.Apps} title={I18n.t('corporate.Apps')}>
                  <Apps 
                    selectedFleet={this.props.auth?.selectedFleet}
                    corporateId={this.state.corporateId}
                    corpName={this.state?.data?.companyName}
                  />
                </Tab>
              )
            }
          </Tabs>
        </div>
      );
    }
    return null;
  }
}

Edit.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    commonData: state.commonData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    detailCorporate: options => dispatch(detailCorporate(options)),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
