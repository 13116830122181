import React, { useState, useEffect, useCallback, Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  Row,
  Col,
  FormGroup,
  Form,
  FormControl,
  Button,
  Modal,
  ButtonToolbar,
} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Validator, ValidCase } from '../../components/validator';
import { Validation, CCLiteCommonFunc } from '../../utils/commonFunctions';
import RadioButton from '../../components/radioButton/RadioButton';
import { connect } from 'react-redux';
import * as customerActions from '../../actions/customerAction';
import * as corporateActions from '../../actions/corporateAction';
import * as settingActions from '../../actions/settingActions';
import * as loadingBarActions from '../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import Autosuggest from 'react-autosuggest';
import DateTime from '../../components/dateTime/DateTime';
import { bookingColumn } from './tableBookingColumns';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import './invoice.scss';
import _, { result } from 'lodash';
import CloseIcon from '@icons/material/CloseIcon';
import { BsSearch } from 'react-icons/bs';

const SHOW_TYPE = [
  { value: 'all', title: 'All' },
  { value: 'selected', title: 'Selected' },
  { value: 'unselected', title: 'Unselected' },
];
class BookingPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      textSearch: '',
      isLoading: false,
      showTypeBookings: 'all',
      selectedBookings: [],
      bookingInfo: {
        limit: 20,
        total: 0,
        page: 0,
      },
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('mDispatcher.mDispatchers'),
      },
      footerLoading: true,
    };
  }

  componentDidMount() {
    if (this.props.selectedBookings) {
      this.setState(
        {
          selectedBookings: this.props.selectedBookings,
        },
        () => {
          this.getListBooking(0);
        }
      );
    }
  }

  handleSearchKeyPress = (e) => {
    if (this.state.isLoading) return;
    if (e.key === 'Enter') {
      this.getListBooking(0);
    }
  };
  handleBookingSelected = (id, e) => {
    let listItem = [...this.state.selectedBookings];
    if (id !== null) {
      if (listItem.includes(id)) {
        listItem = listItem.filter((bookId) => bookId !== id);
      } else {
        listItem.push(id);
      }
    } else {
      // all button
      if (e.target.checked) {
        listItem = this.state.bookingInfo?.allBookings || [];
      } else {
        listItem = [];
      }
    }
    this.setState({ selectedBookings: listItem });
  };

  getTableColumns = () => {
    let tableColums = Object.assign([], bookingColumn);
    _.forEach(tableColums, (col) => {
      switch (col.key) {
        case 'table-selectbox':
          let isCheckedAll =
            this.state.selectedBookings.length ===
            this.state.bookingInfo?.total;

          col.customHeader = (columnKey) => {
            return (
              <CcCheckbox
                className="table-selectbox all-checkbox"
                checked={isCheckedAll}
                onChange={(e) => this.handleBookingSelected(null, e)}
                disabled={this.props.invoiceDetail.status === 2}
              />
            );
          };
          col.customCell = (obj) => {
            return (
              <CcCheckbox
                className="table-selectbox"
                checked={this.state.selectedBookings.includes(obj.bookId)}
                disabled={this.props.invoiceDetail.status === 2}
                onChange={(e) => this.handleBookingSelected(obj.bookId, e)}
              />
            );
          };
          break;
        case 'customerName':
          col.customCell = (data) => {
            return `${data.psgInfo?.firstName} ${data.psgInfo?.lastName}`;
          };
          break;
      }
    });
    return tableColums;
  };

  getTableHeight = () => {
    let parentContentVerticalPadding = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight + 10
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = parentContentVerticalPadding + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && this.state.tableHeight < 500)
      tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return 500;
  };

  handleUpdateInvoice = () => {
    if (this.props.isEditInvoice) {
      const param = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        bookingIds: this.state.selectedBookings,
        invoiceId: this.props.invoiceDetail?.invoiceId,
      };
      this.props.settingActions.editInvoice(param).then((result) => {
        if (result?.data?.invoiceId) {
          return this.props.handleClose(true);
        }
        this.context.notification('error', I18n.t('errors.' + result.message));
      });
    } else {
      this.props.setSelectedBookings(this.state.selectedBookings);
      this.props.handleClose();
    }
  };

  handleSelectShowType = (value) => {
    this.setState({ showTypeBookings: value }, this.getListBooking(0));
  };

  handleNumItemsPerPageChange = (limit) => this.getListBooking(0, limit);
  handlePaginationSelect = (page) => this.getListBooking(parseInt(page));

  getListBooking(activePage, limit) {
    const { billingInfo, invoiceDetail, isEditInvoice, billingType } =
      this.props;
    let param = {
      limit: limit
        ? limit
        : this.state.bookingInfo
        ? this.state.bookingInfo.limit
        : numItemsPerPage,
      page: _.isNumber(activePage)
        ? activePage
        : this.state.bookingInfo
        ? this.state.bookingInfo.page
        : 0,
      fleetId: this.props.auth.selectedFleet.fleetId,
      timezone: this.props.auth.selectedFleet.timezone,
      fromDate: this.props.fromDate
        ? moment(this.props.fromDate).format('YYYY-MM-DD')
        : '',
      toDate: this.props.toDate
        ? moment(this.props.toDate).format('YYYY-MM-DD')
        : '',
      id: billingInfo?._id,
      invoiceType: billingType === 'corporate' ? 1 : 0,
    };
    if (isEditInvoice && invoiceDetail?.id) {
      param.invoiceId = invoiceDetail?.invoiceId;
    }
    if (this.state.textSearch) {
      param.search = this.state.textSearch.trim();
      if (!activePage) {
        param.page = 0;
      }
    }
    if (this.state.sortName) {
      param.sortBy = this.state.sortName;
      param.sortOrder = this.state.sortType;
    }
    this.setState({
      isLoading: true,
      footerLoading: false,
    });
    this.props.settingActions
      .getBookingInvoice(param)
      .then((data) => {
        if (data.ok) {
          let { page, total, limit } = data;
          if (!_.isUndefined(total)) {
            this.setState({
              bookingInfo: data,
              selectedBookings:
                this.state.selectedBookings?.length > 0
                  ? this.state.selectedBookings
                  : this.props.isEditInvoice
                  ? data.selectedBookings
                  : data.allBookings,
              footerData: {
                ...this.state.footerData,
                limit,
                page,
                total,
              },
              footerLoading: false,
              isLoading: false,
            });
          } else {
            this.setState(
              {
                bookingInfo: data,
                footerData: { ...this.state.footerData, limit, page },
                isLoading: false,
              },
              () => {
                if (countPartner) {
                  // this.loadTotalMDispatcher(param);
                }
              }
            );
          }
        }
      })
      .catch((err) => {
        this.context.notification('error', I18n.t('General.ServerError'));
      });
  }

  handleSortBooking = (sortName) => {
    this.setState(
      {
        sortName: sortName,
        sortType: this.state.sortType == 1 ? -1 : 1,
      },
      () => {
        this.getListBooking();
      }
    );
  };

  render() {
    const { bookingInfo = {}, footerData, selectedBookings } = this.state;
    const { invoiceDetail } = this.props;
    return (
      <Modal
        show={true}
        backdrop={true}
        bsSize="large"
        dialogClassName="confirm-dialog"
        className="bookingPopup"
        onHide={this.props.handleClose}
      >
        <Modal.Body>
          <div className="titlePopup">
            <h2>
              {this.props.isEditInvoice
                ? invoiceDetail.status === 2
                  ? I18n.t('invoice.viewInvoice')
                  : I18n.t('invoice.editInvoice')
                : I18n.t('invoice.createInvoice')}
            </h2>
            <Button
              type="button"
              className="btn-header text-add-header"
              // aria-label="Close"
              onClick={this.props.handleClose}
            >
              <CloseIcon color={'white'} />
            </Button>
          </div>
          <ButtonToolbar
            className="text-center header-button-group"
            ref={(node) => (this.toobarContainer = node)}
          >
            <div className="group-left btn-group">
              <FormGroup className="search-format">
                <FormControl
                  className="search-form"
                  type="text"
                  placeholder={I18n.t('invoice.searchPl')}
                  onChange={(e) =>
                    this.setState({ textSearch: e.target.value })
                  }
                  onKeyPress={this.handleSearchKeyPress}
                  value={this.state.textSearch}
                />
                <BsSearch className="search-icon" />
              </FormGroup>
              {/* <CCDropDown
                id="createInvoice-dropdown"
                items={SHOW_TYPE}
                title={'Show'}
                selectedItems={[
                  this.state.showTypeBookings
                ]}
                valueKey="value"
                labelKey="title"
                onSelect={this.handleSelectShowType}
                className="text-active"
                // disabled={this.props.disabled}
              /> */}
            </div>
          </ButtonToolbar>
          <div className="gridViewTable">
            <StickyTable
              columns={this.getTableColumns()}
              bodyData={bookingInfo.data || []}
              footerData={footerData}
              rowHeight={50}
              getTableHeight={this.getTableHeight}
              notCheckShouldComponentUpdate
              handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
              handlePaginationSelect={this.handlePaginationSelect}
              sortHandle={this.handleSortBooking}
              sortType={this.state.sortType}
              sortName={this.state.sortName}
              footerLoading={this.state.footerLoading}
              isLoading={this.state.isLoading}
              settings={this.props.auth?.selectedFleet}
            />
          </div>
          {this.props.invoiceDetail.status !== 2 && (
            <div className="countContainer">
              <span>{`
                ${selectedBookings?.length || 0} 
                of 
                ${bookingInfo?.total || 0} selected`}</span>
              <Button
                className="btn-save view-button"
                onClick={this.handleUpdateInvoice}
                disabled={!this.state.selectedBookings.length > 0}
              >
                <Translate value="invoice.updateInvoice" />
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

BookingPopup.contextTypes = {
  notification: PropTypes.func,
};

BookingPopup.defaultProps = {
  bookingList: [],
  selectedBookings: [],
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    corporateActions: bindActionCreators(corporateActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingPopup);
