import { 
    etaFareApi,
    etaDeliveryFareApi,
    getServiceFeeApi,
    etaFareMultiCarApi,
    etaFareMultiCarDeliveryApi,
    getPayOutDataApi,
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";

export const ETA_FARE_REQUEST = "ETA_FARE_REQUEST";
export const ETA_FARE_SUCCESS = "ETA_FARE_SUCCESS";
export const ETA_FARE_MULTI_CAR_SUCCESS = "ETA_FARE_MULTI_CAR_SUCCESS";
export const ETA_FARE_FAILURE = "ETA_FARE_FAILURE";
export const ETA_HYDRA_FARE_SUCCESS = "ETA_HYDRA_FARE_SUCCESS";

export const ETA_DELIVERY_FARE_REQUEST = "ETA_DELIVERY_FARE_REQUEST";
export const ETA_DELIVERY_FARE_SUCCESS = "ETA_DELIVERY_FARE_SUCCESS";
export const ETA_DELIVERY_FARE_FAILURE = "ETA_DELIVERY_FARE_FAILURE";

function getEtaFareRequest() {
    return {
        type: ETA_FARE_REQUEST
    }
}

function getEtaFareSuccess(data) {
    return {
        type: ETA_FARE_SUCCESS,
        data: data.res.response
    }
}

function getEtaFareMultiCarSuccess(data, vehicleSelecting) {
    return {
        type: ETA_FARE_MULTI_CAR_SUCCESS,
        data: {
            response: data,
            vehicleSelecting: vehicleSelecting
        }
    }
}

function getEtaFareFailure(error) {
    return {
        type: ETA_FARE_FAILURE,
        error
    }
}

export function etaFare(options = {}) {
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        etaFareApi,
        config,
        getEtaFareRequest(),
        getEtaFareSuccess,
        getEtaFareFailure
    );
}

export function getPayOutData(options = {}) {
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        getPayOutDataApi,
        config,
        getEtaFareRequest(),
        getEtaFareSuccess,
        getEtaFareFailure
    );
}

export function etaFareMultiCar(options = {}) {
    const vehicleSelecting = options.vehicleSelecting
    delete options.vehicleSelecting 
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        etaFareMultiCarApi,
        config,
        getEtaFareRequest(),
        data => getEtaFareMultiCarSuccess(data, vehicleSelecting),
        getEtaFareFailure
    );
}

export function etaFareMultiCarDelivery(options = {}) {
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        etaFareMultiCarDeliveryApi,
        config,
        getEtaDeliveryFareRequest(),
        getEtaDeliveryFareSuccess,
        getEtaDeliveryFareFailure
    );
}

export function etaFareMultiCarHydra(options = {}) {
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        etaFareMultiCarApi,
        config,
        null,
        null,
        null
    );
}

function getEtaDeliveryFareRequest() {
    return {
        type: ETA_DELIVERY_FARE_REQUEST
    }
}

function getEtaDeliveryFareSuccess(data) {
    return {
        type: ETA_DELIVERY_FARE_SUCCESS,
        data: data
    }
}

export function etaFareMultiCarHydraSuccess(data, vehicleSelecting) {
    return {
        type: ETA_HYDRA_FARE_SUCCESS,
        data: {
            etaData: data,
            vehicleSelecting: vehicleSelecting
        }
    }
}

function getEtaDeliveryFareFailure(error) {
    return {
        type: ETA_DELIVERY_FARE_FAILURE,
        error
    }
}

export function etaDeliveryFare(options = {}) {
    options = Object.assign({},  options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        etaDeliveryFareApi,
        config,
        getEtaDeliveryFareRequest(),
        getEtaDeliveryFareSuccess,
        getEtaDeliveryFareFailure
    );
}

export function getRushHourFee(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: 'get'
    }
    return callApi(
        UrlBuilder(getServiceFeeApi, options),
        config,
        null,
        null,
        null
    )
}