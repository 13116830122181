import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormGroup,
  FormControl,
  Form,
  Col,
  Modal,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Row,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';

import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../components/validator';
import DateTime from '../../../components/dateTime/DateTime';
import {
  Validation,
  textboxNumberHelper,
  filterCommissionServiceActive,
  getSuppilerPermission,
} from '../../../utils/commonFunctions';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import Select from '../../../components/select/Select';
import RadioButton from '../../../components/radioButton/radio';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import _ from 'lodash';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

class Operation extends React.PureComponent {
  constructor(props) {
    super(props);
    let commissionDriverValue = _.get(
      props.detailItem,
      'driverInfo.commissionDriverValue',
      []
    );
    let commissionCompanyValue = _.get(
      props.detailItem,
      'driverInfo.commissionCompanyValue',
      []
    );
    this.state = {
      colorSelected: null,
      textSearchCarColor: '',
      showCommission: false,
      commissionDriverValue: commissionDriverValue ? commissionDriverValue : [],
      commissionCompanyValue: commissionCompanyValue
        ? commissionCompanyValue
        : [],
    };
    this.searchCarColorDebounce = _.debounce(this.searchCarColor, 300);
  }

  showCommissionClick = () => {
    this.setState({ showCommission: !this.state.showCommission });
  };

  showCompanyCommissionClick = () => {
    this.setState({ showCompanyCommission: !this.state.showCompanyCommission });
  };

  handleCommissionTypeChangeAndValue(e, serviceType = '', key) {
    let regrex = /^(\d*\.?\d{0,2})$/;
    //if(key == 'value' && !regrex.test(e.target.value)) return
    let commissionDriverValue = this.state.commissionDriverValue;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = commissionDriverValue.map((commission) => {
        if (
          commission.serviceType === serviceType &&
          (key === 'type' || (key !== 'type' && regrex.test(e.target.value)))
        ) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        this.setState({
          commissionDriverValue: newValueCommissions,
        });
      }
    }
  }

  handleCompanyCommissionTypeChangeAndValue(e, serviceType = '', key) {
    let regrex = /^(\d*\.?\d{0,2})$/;
    let commissionCompanyValue = this.state.commissionCompanyValue;
    let newValueCommissions = [];
    if (serviceType) {
      newValueCommissions = commissionCompanyValue.map((commission) => {
        if (
          commission.serviceType === serviceType &&
          (key === 'type' || (key !== 'type' && regrex.test(e.target.value)))
        ) {
          return {
            ...commission,
            [key]: e.target.value,
          };
        }
        return commission;
      });
      if (newValueCommissions.length > 0) {
        this.setState({
          commissionCompanyValue: newValueCommissions,
        });
      }
    }
  }

  saveCommissionEdit = () => {
    this.props.handleCommissionDriverChange(
      this.state.commissionDriverValue,
      'value'
    );
    this.setState({
      showCommission: false,
    });
  };

  saveCompanyCommissionEdit = () => {
    this.props.handleCommissionCompanyChange(
      this.state.commissionCompanyValue,
      'value'
    );
    this.setState({
      showCompanyCommission: false,
    });
  };

  renderCompanyCommission = () => {
    let {
      detailItem,
      // editFleetCommission,
      handleCommissionCompanyChange,
      auth,
      editable,
    } = this.props;
    let commissionCompanyValue = filterCommissionServiceActive(
      this.state.commissionCompanyValue,
      auth.selectedFleet,
      true
    );
    let commissionCompanyType = detailItem.driverInfo.commissionCompanyType;
    return (
      <React.Fragment>
        <Col md={6} xs={12}>
          <FormGroup>
            <Form.Label className="mb">
              <Translate value="driver.Company_commission" />
              <OverlayTrigger
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <strong>
                      {commissionCompanyType === 'customize'
                        ? 'Can customize the commission amount company will get, for each service.'
                        : 'Get from company setting, for all services'}
                    </strong>
                  </Tooltip>
                }
              >
                <i className={'fa fa-question-circle mr-l-5'} />
              </OverlayTrigger>
            </Form.Label>
            <FormControl
              as="select"
              value={commissionCompanyType}
              onChange={handleCommissionCompanyChange}
              disabled={editable}
              className="form-custom"
            >
              <option value="default">Default</option>
              <option value="customize">Customize</option>
            </FormControl>
          </FormGroup>
        </Col>
        {commissionCompanyType === 'customize' && !editable && (
          <Col md={6}>
            <a
              onClick={this.showCompanyCommissionClick}
              href="javascript:void(0)"
              className="text-active editCommission"
            >
              Edit/Add
            </a>
          </Col>
        )}
        <Modal
          show={this.state.showCompanyCommission}
          onHide={this.showCompanyCommissionClick}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="add-edit-driver-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title_commission">
              Commission by service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix commissionBody">
            {commissionCompanyValue &&
              commissionCompanyValue.map((commission) => {
                return (
                  <Fragment>
                    <div className="group-general">
                      <div key={commission.serviceType}>
                        <Row>
                          <Col xs={12}>
                            <FormGroupTitle>
                              <Translate
                                value={`driver.${commission.serviceType}`}
                              />
                            </FormGroupTitle>
                          </Col>
                          <Col md={6} xs={12}>
                            <FormGroup>
                              <FormControl
                                as="select"
                                value={'percent'}
                                // onChange={
                                //   e => {this.handleCommissionTypeChangeAndValue(e, commission.serviceType, 'type')}
                                // }
                                disabled={editable}
                                className="form-custom"
                              >
                                <option value="percent">
                                  {I18n.t('driver.Percentage')}
                                </option>
                                {/* <option value="amount">{I18n.t("driver.Amount")}</option> */}
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="qup-input-group">
                              <div className="input-with-validator">
                                <InputGroup>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>{'%'}</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleCompanyCommissionTypeChangeAndValue(
                                        e,
                                        commission.serviceType,
                                        'value'
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCompanyCommissionTypeChangeAndValue(
                                            e,
                                            commission.serviceType,
                                            'value'
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCompanyCommissionTypeChangeAndValue(
                                            e,
                                            commission.serviceType,
                                            'value'
                                          );
                                        }
                                      );
                                    }}
                                    value={commission.value}
                                    disabled={editable}
                                  />
                                    <InputGroup.Append>
                                      <InputGroup.Text>
                                        /<Translate value="driver.transaction" />
                                      </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-save"
              onClick={this.saveCompanyCommissionEdit}
            >
              <Translate value="newbooking.Save" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={this.showCompanyCommissionClick}
            >
              <Translate value="newbooking.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  renderNewCommission = () => {
    let {
      detailItem,
      editFleetCommission,
      handleCommissionDriverChange,
      auth,
      editable,
    } = this.props;
    let commissionDriverValue = filterCommissionServiceActive(
      this.state.commissionDriverValue,
      auth.selectedFleet
    );
    let commissionDriverType = detailItem.driverInfo.commissionDriverType;
    const driverInsurance = auth.selectedFleet.generalSetting.driverInsurance;
    return (
      <React.Fragment>
        <Col md={6} xs={12}>
          <FormGroup>
            <Form.Label className="mb">
              <Translate value="driver.Fleet_commission" />
              <OverlayTrigger
                delayShow={300}
                overlay={
                  <Tooltip id="tooltip">
                    <strong>
                      {commissionDriverType === 'customize'
                        ? 'Customize the commission for this special driver.'
                        : 'You can set the default commission for all drivers in general settings.'}
                    </strong>
                  </Tooltip>
                }
              >
                <i className={'fa fa-question-circle mr-l-5'} />
              </OverlayTrigger>
            </Form.Label>
            <FormControl
              as="select"
              value={commissionDriverType}
              onChange={handleCommissionDriverChange}
              disabled={editFleetCommission}
              className="form-custom"
            >
              <option value="default">Default</option>
              <option value="customize">Customize</option>
            </FormControl>
          </FormGroup>
        </Col>
        {commissionDriverType === 'customize' && !editFleetCommission && (
          <Col md={6}>
            <a
              onClick={this.showCommissionClick}
              href="javascript:void(0)"
              className="text-active editCommission"
            >
              Edit/Add
            </a>
          </Col>
        )}
        <Modal
          show={this.state.showCommission}
          onHide={this.showCommissionClick}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="add-edit-driver-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title_commission">
              Commission by service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix commissionBody">
            {commissionDriverValue &&
              commissionDriverValue.map((commission) => {
                let symbol =
                  commission.type === 'percent'
                    ? '%'
                    : auth.selectedFleet.currencies[0].symbol;
                return (
                  <Fragment>
                    <div className="group-general">
                      <div key={commission.serviceType}>
                        <Row>
                          <Col xs={12}>
                            <FormGroupTitle>
                              <Translate
                                value={`driver.${commission.serviceType}`}
                              />
                            </FormGroupTitle>
                          </Col>
                          <Col md={6} xs={12}>
                            <FormGroup>
                              <FormControl
                                as="select"
                                value={commission.type}
                                onChange={(e) => {
                                  this.handleCommissionTypeChangeAndValue(
                                    e,
                                    commission.serviceType,
                                    'type'
                                  );
                                }}
                                disabled={editable}
                                className="form-custom"
                                >
                                <option value="percent">
                                  {I18n.t('driver.Percentage')}
                                </option>
                                <option value="amount">
                                  {I18n.t('driver.Amount')}
                                </option>
                              </FormControl>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="qup-input-group">
                              <div className="input-with-validator">
                                <InputGroup
                                  className={
                                    editFleetCommission ? 'disabled' : ''
                                  }
                                  >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>{symbol}</InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    onChange={(e) => {
                                      this.handleCommissionTypeChangeAndValue(
                                        e,
                                        commission.serviceType,
                                        'value'
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCommissionTypeChangeAndValue(
                                            e,
                                            commission.serviceType,
                                            'value'
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCommissionTypeChangeAndValue(
                                            e,
                                            commission.serviceType,
                                            'value'
                                          );
                                        }
                                      );
                                    }}
                                    value={commission.value}
                                    disabled={editable}
                                  />
                                  <InputGroup.Append>
                                    <InputGroup.Text>
                                      /<Translate value="driver.transaction" />
                                    </InputGroup.Text>
                                  </InputGroup.Append>
                                </InputGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        {driverInsurance && (
                          <Row>
                            <Col xs={12}>
                              <Form.Label className="mb titleService">
                                <Translate value={`driver.driverInsurance`} />
                              </Form.Label>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="qup-input-group">
                                <div className="input-with-validator">
                                  <InputGroup
                                    className={`single-addon-left ${
                                      editFleetCommission ? 'disabled' : ''
                                    }`}
                                    >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          auth.selectedFleet.currencies[0]
                                            .symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      onChange={(e) => {
                                        this.handleCommissionTypeChangeAndValue(
                                          e,
                                          commission.serviceType,
                                          'insurance'
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'insurance'
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCommissionTypeChangeAndValue(
                                              e,
                                              commission.serviceType,
                                              'insurance'
                                            );
                                          }
                                        );
                                      }}
                                      value={commission.insurance}
                                      disabled={editable}
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save" onClick={this.saveCommissionEdit}>
              <Translate value="newbooking.Save" />
            </Button>
            <Button className="btn-cancel" onClick={this.showCommissionClick}>
              <Translate value="newbooking.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  renderCommission = () => {
    const {
      detailItem,
      isSubmited,
      valid,
      commission,
      activeTabKey,
      editable,
      auth,
      handleCommissionTypeChange,
      handleCommissionChange,
      validatorCallback,
      handleTabSelect,
      selectedCurrency,
      commonData,
      editFleetCommission,
    } = this.props;

    let commissionByCurrencies = detailItem.driverInfo.commissionByCurrencies;
    let formInput = null;
    const commissionType = detailItem.driverInfo.commissionType;

    if (!auth.selectedFleet || !commissionByCurrencies) return null;

    if (
      commissionType === 'percent' ||
      auth.selectedFleet.currencies.length < 2
    ) {
      // render permission input
      let validateState =
        commissionType === 'percent'
          ? valid.percentCommission
          : valid.commission;

      let symbol =
        commissionType === 'percent'
          ? '%'
          : auth.selectedFleet.currencies[0].symbol;

      formInput = (
        <FormGroup
          className={`qup-input-group ${isSubmited ? (validateState ? null : 'error') : null}`}
        >
          <div className="input-with-validator">
            <InputGroup className={editFleetCommission ? 'disabled' : ''}>
              <InputGroup.Prepend>
                <InputGroup.Text>{symbol}</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                onChange={handleCommissionChange}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, handleCommissionChange);
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, handleCommissionChange);
                }}
                value={commission}
                disabled={editFleetCommission}
              />

              <InputGroup.Append>
                <InputGroup.Text>
                  /<Translate value="driver.transaction" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <WarningFeeInput
              message={I18n.t('message.warningInputChangeOver')}
              className="text-soft-warning"
              value={commission}
              isReset={commissionType === 'percent' ? true : false}
              disabled={editFleetCommission}
              timeDelay={500}
              typeOfCheck="higherOfLower"
            />
          </div>
          <Validator
            id={
              commissionType === 'percent' ? 'percentCommission' : 'commission'
            }
            callback={validatorCallback}
          >
            <ValidCase
              hide={!isSubmited}
              valid={
                Validation.isDecimal(commission) ||
                Validation.isStringEmpty(commission)
              }
              message={I18n.t('driver.ERROR_COMMISSION_NUMBERS')}
            />
          </Validator>
        </FormGroup>
      );
    } else {
      // render amount input
      let currency = _.find(
        commissionByCurrencies,
        (c) => c.currencyISO === selectedCurrency
      );
      let currencyValue = '';
      const selectedCurrencyObj = _.find(
        auth.selectedFleet.currencies,
        (c) => c.iso === selectedCurrency
      );

      if (currency) {
        currencyValue = currency.commissionValue;
      }

      formInput = (
        <FormGroup
          className={`qup-input-group ${
            isSubmited ? (valid[selectedCurrency] ? null : 'error') : null
          }`}
        >
          <div className="input-with-validator">
            <InputGroup className={editFleetCommission ? 'disabled' : ''}>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {(selectedCurrencyObj && selectedCurrencyObj.symbol) || ''}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                onChange={(e) => {
                  handleCommissionChange(e, selectedCurrency);
                }}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(e, (e) => {
                    handleCommissionChange(e, selectedCurrency);
                  });
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(e, (e) => {
                    handleCommissionChange(e, selectedCurrency);
                  });
                }}
                value={currencyValue}
                disabled={editFleetCommission}
              />

              <InputGroup.Append>
                <InputGroup.Text>
                  /<Translate value="driver.transaction" />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>

            <WarningFeeInput
              message={I18n.t('message.warningInputChangeOver')}
              className="text-soft-warning"
              value={currencyValue}
              isReset={false}
              disabled={editFleetCommission}
              timeDelay={500}
              typeOfCheck="higherOfLower"
            />
          </div>
          <Validator id={selectedCurrency} callback={validatorCallback}>
            <ValidCase
              hide={!isSubmited}
              valid={
                Validation.isDecimal(currencyValue) ||
                Validation.isStringEmpty(currencyValue)
              }
              message={I18n.t('driver.ERROR_COMMISSION_NUMBERS')}
            />
          </Validator>
        </FormGroup>
      );
    }
    return (
      <React.Fragment>
        <Col xs={12}>
          <Form.Label className="mb">
            <Translate value="driver.Fleet_commission" />
          </Form.Label>
        </Col>
        <Col md={6} xs={12}>
          <FormGroup>
            <FormControl
              as="select"
              value={detailItem.driverInfo.commissionType}
              onChange={handleCommissionTypeChange}
              disabled={editFleetCommission}
              className="form-custom"
            >
              <option value="percent">{I18n.t('driver.Percentage')}</option>
              <option value="amount">{I18n.t('driver.Amount')}</option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col md={6}>{formInput}</Col>
      </React.Fragment>
    );
  };

  handleInputColorChange = (value) => {
    this.setState({ textSearchCarColor: value });
  };

  handleInputBlur = (e) => {
    this.setState({ textSearchCarColor: '' });
  };

  renderCarColorNoResult = () => {
    const { textSearchCarColor } = this.state;
    if (!textSearchCarColor) return null;
    return (
      <span>
        {I18n.t('message.No_results')}
        <button className="btn-link" onClick={this.onCreateNewCarColor}>
          Click here to add new
        </button>
        {textSearchCarColor}
      </span>
    );
  };

  onCreateNewCarColor = () => {
    const { textSearchCarColor } = this.state;
    let colorSelected = { name: textSearchCarColor };
    this.handleCarColorChange(colorSelected);
    this.carColorRef.select.blurInput();
  };

  handleLoadOption = (input, callback) => {
    if (!input) return callback();
    const { settingActions, auth } = this.props;
    this.searchCarColorDebounce(
      auth.selectedFleet.fleetId,
      settingActions.autocompleteCarColor,
      input,
      callback
    );
  };

  searchCarColor = (fleetId, findCarColorAction, input, callback) => {
    const params = {
      str: input,
      fleetId,
      limit: 20,
      page: 0,
    };

    findCarColorAction(params)
      .then((data) => {
        if (data.ok && data.res && data.res.list.length > 0) {
          const result = { complete: true };
          result.options = (data.res.list || []).map((cat) => ({
            name: cat.name,
            _id: cat._id,
          }));
          callback(null, result);
        } else callback(null, null);
      })
      .catch(() => callback(null, null));
  };

  handleCarColorChange = (colorSelected) => {
    this.setState(
      {
        colorSelected,
      },
      () => {
        this.props.handleColorChange(this.state.colorSelected);
      }
    );
  };

  getZoneBasedCompany = () => {
    const companyId =
        this.props.detailItem?.driverInfo?.company?.companyId || '',
      fleetZones = this.props.commonData.mapZone,
      fleetCompany = [...this.props.commonData.companies, ...this.props.commonData.suppliers];

    if (companyId) {
      const cpInfo = fleetCompany.find((cp) => cp._id === companyId);
      if (cpInfo?.operationZone?.length > 0) {
        return fleetZones.filter((zone) => {
          if (!cpInfo?.operationZone?.length) {
            return true;
          }
          return cpInfo?.operationZone.includes(zone._id)
          }
        );
      }
    }
    return fleetZones;
  };

  render() {
    const {
      // states
      editable,
      isSubmited,
      valid,
      detailItem,
      isVehicleDidntAssign,
      isVhicleNoType,
      isExistingCar,
      vehicleByCompany,
      carType,
      vehicleOwner,
      carMake,
      carMakeList,
      carModel,
      color,
      passengers,
      luggage,
      licenseExpiry,
      caseNumber,
      vhcPhone,
      vhcPhoneValidation,
      selectedCarType,
      year,
      listShifts,
      // props
      commonData,
      auth,
      language,
      yearsElement,
      // funcs
      handleCompanyChange,
      validatorCallback,
      handleExistingCarChange,
      handleLicensePlateComboChange,
      handleLicensePlateChange,
      validateLicensePlate,
      handleCarTypeChange,
      handleVehicleOwnerChange,
      handleCarMakeChange,
      handleCarModelChange,
      handleYearChange,
      handleColorChange,
      handlePassengersChange,
      handleLuggageChange,
      handleLicenseExpiryChange,
      handlecaseNumberChange,
      handleVhcPhoneChange,
      handleZoneChange,
      handleShiftChange,
      handleTerminalIdChange,
      handleAuthKeyChange,
      licensePlate,
      handleSearchLicensePlate,
      handleLoadMoreLicensePlate,
      handleSelectCurrency,
      selectedCurrency,
      driverType,
    } = this.props;
    const companyCommission = _.get(
      auth,
      'selectedFleet.generalSetting.companyCommission',
      false
    );
    const allZonesByCurrency = _.filter(
      this.getZoneBasedCompany(),
      (z) => z.currency && z.currency.iso === selectedCurrency
    );

    let makeObj = carMake ? carMakeList.find((x) => x._id === carMake) : null;
    let carModels = makeObj ? makeObj.models || [] : [];
    let zonesToSelect = allZonesByCurrency;

    if (selectedCarType) {
      const zoneByCarType = selectedCarType.zoneId || [];
      zonesToSelect = _.filter(allZonesByCurrency, (zone) => {
        let exist = _.find(zoneByCarType, (z) => z._id === zone._id);
        return exist ? true : false;
      });
    } else if (commonData.relativeInfo) {
      zonesToSelect = _.filter(allZonesByCurrency, (zone) => {
        let exist = _.find(
          commonData.relativeInfo.zones,
          (z) => z._id === zone._id
        );
        return exist ? true : false;
      });
    }
    let carTypes = commonData.carType || [];
    if (
      driverType === 'deliveryIndividual' ||
      driverType === 'deliveryCompany'
    ) {
      carTypes = commonData.carTypeDelivery;
    }

    return (
      <Col xs={12} className="add-edit-part">
        <Col xs={12}>
          <FormGroupTitle>
            <Translate value="driver.OPERATION" />
          </FormGroupTitle>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label className="mr-lg">
              <Translate value="driver.Car" />
            </Form.Label>
            <div>
              <RadioButton
                name="radioGroup"
                checked={isExistingCar}
                value={true}
                onChange={handleExistingCarChange}
                disabled={editable}
                text={I18n.t('driver.Existing_car')}
              />
              <RadioButton
                name="radioGroup"
                checked={!isExistingCar}
                value={false}
                onChange={handleExistingCarChange}
                disabled={editable}
                text={I18n.t('driver.New_car')}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={isSubmited ? (valid.type ? null : 'error') : null}
          >
            <Form.Label>
              <Translate value="driver.Car_Type" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              disabled={editable}
              value={carType}
              placeholder="Select Car Type"
              onChange={(e) => {
                handleCarTypeChange(e.target.value);
              }}
            >
              <option key={0} value={''}>
                {I18n.t('driver.All_Car_Type')}
              </option>
              {/* {commonData.carType.map(ct => {
                return (
                  <option key={ct._id} value={ct._id}>
                    {ct.vehicleType}
                  </option>
                );
              })} */}
              {carTypes.map((ct) => {
                return (
                  <option key={ct._id} value={ct._id}>
                    {ct.vehicleType}
                  </option>
                );
              })}
            </FormControl>
            <Validator id="type" callback={validatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={carType && !Validation.isStringEmpty(carType)}
                message={I18n.t('messages.booking.Please_select_car_type')}
              />
            </Validator>
          </FormGroup>
        </Col>

        <Col xs={12} md={6}>
          <FormGroup
            className={
              isSubmited &&
              ((auth.selectedFleet && !auth.selectedFleet.licensePlate) ||
                !isExistingCar)
                ? valid.licensePlate
                  ? null
                  : 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="driver.License_plate" />
              {(auth.selectedFleet && !auth.selectedFleet.licensePlate) ||
              !isExistingCar ? (
                <span className="require"> *</span>
              ) : null}
            </Form.Label>
            {isExistingCar ? (
              <CCDropDown
                id="carLicensePlate-dropdown"
                items={vehicleByCompany.list}
                title={I18n.t('driver.License_plate')}
                valueKey="_id"
                labelKey="plateNumber"
                className={
                  'driver-select-licence-dropdown form-custom' +
                  (!detailItem.driverInfo.vehicleId ? ' no-item-selected' : '')
                }
                onSelect={handleLicensePlateComboChange}
                selectedItems={
                  detailItem.driverInfo.vehicleId
                    ? [detailItem.driverInfo.vehicleId]
                    : []
                }
                onSearchChange={_.debounce(handleSearchLicensePlate, 400)}
                onScrollBottom={handleLoadMoreLicensePlate}
                customTitle={
                  <span className="dropdown-title">
                    {detailItem.driverInfo.vehicleId
                      ? detailItem.driverInfo.licensePlate
                      : I18n.t('driver.Select_license_plate')}
                  </span>
                }
                disabled={editable}
                searchable
                async
                enableClearAll
                noTranslateLabel
              />
            ) : (
              <FormControl
                className="form-custom"
                type="text"
                maxLength={25}
                value={detailItem.driverInfo.licensePlate}
                onChange={handleLicensePlateChange}
                disabled={editable}
              />
            )}
            {(auth.selectedFleet && !auth.selectedFleet.licensePlate) ||
            !isExistingCar ? (
              <Validator id="licensePlate" callback={validatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={validateLicensePlate()}
                  message={I18n.t('driver.ERROR_INPUT_LICENSE_PLATE')}
                />
              </Validator>
            ) : null}
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              isSubmited ? (valid.company ? null : 'error') : null
            }
          >
            <Form.Label>
              <Translate value="driver.Company" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              value={_.get(detailItem, 'driverInfo.company.companyId', '')}
              disabled={
                editable ||
                isVehicleDidntAssign ||
                getSuppilerPermission(this.props.auth)
              }
              onChange={(e) => handleCompanyChange(e.target.value)}
            >
              <option key={0} value={''}>
                {I18n.t('driver.All_company')}
              </option>
              {commonData
                ? [...commonData.companies, ...commonData.suppliers].map((cp) => {
                    return (
                      <option key={cp._id} value={cp._id}>
                        {cp.name}
                      </option>
                    );
                  })
                : null}
            </FormControl>
            <Validator id="company" callback={validatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  detailItem.driverInfo.company &&
                  !Validation.isStringEmpty(
                    detailItem.driverInfo.company.companyId
                  )
                }
                message={I18n.t('driver.ERROR_INPUT_COMPANY')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.vehicleOwner" />
            </Form.Label>
            <FormControl
              className="form-custom"
              type="text"
              disabled={isExistingCar}
              value={vehicleOwner ? vehicleOwner : ''}
              placeholder={I18n.t('driver.vehicleOwner')}
              onChange={handleVehicleOwnerChange}
              maxLength={100}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Make" />
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              disabled={isExistingCar}
              value={carMake}
              placeholder="Select Car Make"
              onChange={handleCarMakeChange}
            >
              <option key={0} value={''}>
                {I18n.t('driver.Select_Car_Make')}
              </option>
              {carMakeList.map((cm) => (
                <option key={cm._id} value={cm._id}>
                  {cm.name}
                </option>
              ))}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Model" />
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              disabled={isExistingCar}
              value={carModel}
              placeholder={I18n.t('driver.Select_Car_Model')}
              onChange={handleCarModelChange}
            >
              <option key={0} value={''}>
                {I18n.t('driver.Select_Car_Model')}
              </option>
              {carModels.map((cm) => {
                return (
                  <option key={cm._id} value={cm._id}>
                    {cm.name}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Year" />
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              disabled={isExistingCar}
              value={year}
              placeholder="Select Year"
              onChange={handleYearChange}
            >
              {yearsElement
                ? yearsElement.map((z) => (
                    <option key={z} value={z}>
                      {z}
                    </option>
                  ))
                : ''}
            </FormControl>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Color" />
            </Form.Label>
            {isExistingCar ? (
              <FormControl
                className="form-custom"
                type="text"
                disabled={isExistingCar}
                value={color ? color : ''}
                onChange={handleColorChange}
              />
            ) : (
              <Select.Async
                multi={false}
                valueKey="_id"
                labelKey="name"
                cache={false}
                ref={(ref) => (this.carColorRef = ref)}
                placeholder={I18n.t('messages.car_mgmt.Search_car_color')}
                searchPromptText={''}
                loadingPlaceholder={I18n.t('messages.car_mgmt.Searching')}
                noResultsText={this.renderCarColorNoResult()}
                className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest"
                value={this.state.colorSelected}
                loadOptions={this.handleLoadOption}
                onChange={this.handleCarColorChange}
                disabled={isExistingCar}
                ignoreAccents={false}
                onInputChange={this.handleInputColorChange}
                onBlur={this.handleInputBlur}
              />
            )}
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Passengers" />
            </Form.Label>
            <FormControl
              className="form-custom"
              type="text"
              disabled={isExistingCar}
              value={passengers}
              onChange={handlePassengersChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Luggage" />
            </Form.Label>
            <FormControl
              className="form-custom"
              type="text"
              disabled={isExistingCar}
              value={luggage}
              onChange={handleLuggageChange}
            />
          </FormGroup>
        </Col>
        {auth.selectedFleet.generalSetting &&
          auth.selectedFleet.generalSetting
            .showInInsuranceFieldsAndVhcLicenseExp && (
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="driver.License_expiry" />
                </Form.Label>
                <DateTime
                  // value={licenseExpiry}
                  value={
                    licenseExpiry
                      ? moment(licenseExpiry)
                          .locale(language.toLowerCase())
                          .format('L')
                      : ''
                  }
                  // timeFormat={false}
                  dateFormat={true}
                  onChange={handleLicenseExpiryChange}
                  inputProps={{
                    readOnly: true,
                    disabled: isExistingCar,
                    className: isExistingCar
                      ? 'form-control'
                      : 'date-readonly form-control',
                  }}
                  placeholder={I18n.t('driver.placeholderDate')}
                  closeOnSelect
                />
              </FormGroup>
            </Col>
          )}
        <Col xs={12} md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Case_number" />
            </Form.Label>
            <FormControl
              className="form-custom"
              type="text"
              disabled={isExistingCar}
              value={caseNumber}
              maxLength={50}
              onChange={handlecaseNumberChange}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              !isSubmited ? null : valid.vhcPhone ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="driver.Phone_Number" />
            </Form.Label>
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={vhcPhone}
              onPhoneNumberChange={handleVhcPhoneChange}
              disabled={isExistingCar}
              id="vhcPhone"
            />
            <Validator id="vhcPhone" callback={validatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={vhcPhoneValidation}
                message={I18n.t('driver.ERROR_INPUT_PHONE')}
              />
            </Validator>
          </FormGroup>
        </Col>
        {/* SL-5526 */}
        {auth.selectedFleet.currencies &&
        auth.selectedFleet.currencies.length > 1 ? (
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="corporate.Currency" />
              </Form.Label>
              <FormControl
                className="form-custom"
                as="select"
                placeholder=""
                value={selectedCurrency}
                onChange={handleSelectCurrency}
                disabled={editable}
              >
                {auth.selectedFleet.currencies.map((item) => (
                  <option key={item.iso} value={item.iso}>
                    {item.iso}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          </Col>
        ) : null}

        {/*End SL-5526 */}
        {/*SL-20889 */}
        <Col xs={12} md={6}>
          <FormGroup
            controlId="formControlsSelectMultiple"
            className={isSubmited ? (valid.zone ? null : 'error') : null}
          >
            <Form.Label>
              <Translate value="driver.Zone" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom select-zone"
              as="select"
              multiple
              onChange={handleZoneChange}
              value={detailItem.driverInfo.zoneId}
              disabled={editable}
            >
              {zonesToSelect.map((z) => {
                if (z.display) {
                  return (
                    <option key={z._id} value={z._id}>
                      {z.zoneName}
                    </option>
                  );
                }
              })}
            </FormControl>
            <Validator id="zone" callback={validatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  detailItem.driverInfo.zoneId &&
                  detailItem.driverInfo.zoneId.length > 0
                }
                message={I18n.t('driver.ERROR_INPUT_ZONE')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={isSubmited ? (valid.shift ? null : 'error') : null}
          >
            <Form.Label>
              <Translate value="driver.Shift" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              className="form-custom"
              as="select"
              placeholder="Select Shift"
              value={detailItem.shift._id}
              onChange={handleShiftChange}
              disabled={editable}
            >
              {listShifts
                ? listShifts.map((s) => {
                    return (
                      <option key={s._id} value={s._id}>
                        {s.name}
                      </option>
                    );
                  })
                : null}
            </FormControl>
            <Validator id="shift" callback={validatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  detailItem.shift._id &&
                  !Validation.isStringEmpty(detailItem.shift._id)
                }
                message={I18n.t('driver.ERROR_INPUT_SHIFT')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12}>
          {auth.selectedFleet.generalSetting &&
          auth.selectedFleet.generalSetting.differentFleetCommission
            ? this.renderNewCommission()
            : this.renderCommission()}
        </Col>
        {companyCommission && (
          <Col xs={12}>{this.renderCompanyCommission()}</Col>
        )}
        {auth.selectedFleet.referral.enable ? (
          <Col md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="customer.referral_code" />
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={detailItem.referralCode}
                disabled
              />
            </FormGroup>
          </Col>
        ) : null}
        {auth.selectedFleet.b2bTermial ? (
          <React.Fragment>
            <Col md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="driver.Terminal_ID" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={detailItem.driverInfo.terminalId}
                  onChange={handleTerminalIdChange}
                  disabled={editable}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Form.Label>
                  <Translate value="driver.Auth_key" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={detailItem.driverInfo.authKey}
                  onChange={handleAuthKeyChange}
                  disabled={editable}
                />
              </FormGroup>
            </Col>
          </React.Fragment>
        ) : null}
      </Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Operation);
