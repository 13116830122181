import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';

class TotalBalanceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const { totalBalance, selectedFleet } = this.props;
    const { driverDeposit, driverCashWallet } = selectedFleet;
    let fleetCurrencies = selectedFleet.currencies;
    if (!selectedFleet.multiCurrencies) {
      fleetCurrencies = [_.head(selectedFleet.currencies)];
    }
    const currenciesBalance = fleetCurrencies.map(currency => {
      const foundCurrency = _.find(
        totalBalance || [],
        o => o.currency === currency.iso
      );
      return _.extend({}, currency, {
        cashBalance: _.get(foundCurrency, 'cashBalance', 0),
        creditBalance: _.get(foundCurrency, 'creditBalance', 0)
      });
    });
    return (
      <Modal show={true} backdrop={true} onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="driver.total_balance" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-no-pagination cc-table-striped">
            <thead className={'table-header'}>
              <tr>
                {driverCashWallet && (
                  <th>
                    <Translate value="driver.total_cash_balance" />
                  </th>
                )}
                {driverDeposit && <th>
                  <Translate value="driver.total_credit_balance" />
                </th>}
              </tr>
            </thead>
            <tbody>
              {currenciesBalance.map(item => {
                return (
                  <tr key={item.iso}>
                    {driverCashWallet && (
                      <td>
                        {currencyFormatter.format(item.cashBalance, {
                          code: item.iso
                        })}
                      </td>
                    )}
                    {driverDeposit && (
                      <td>
                        {currencyFormatter.format(item.creditBalance, {
                          code: item.iso
                        })}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="text-center mt-xl mb-lg">
            <Button className={'btn-cancel'} onClick={this.props.closeModal}>
              <Translate value="bookingdetail.Close" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TotalBalanceModal;
