import React, { useState } from 'react'
import FormGroupTitle from '../formGroupTitile/FormGroupTitle'
import { Translate } from 'react-redux-i18n';
import PointContainer from './PointContainer'
import RoutePreview from './Shuttle/RoutePreview'
import ShuttleTraveler from './Shuttle'
import './routeInfo.scss'
import { corporateCompany } from '../../actions/corporateCompanyAction';

const RouteInfo = ({
  puPoints,
  setNewPuPoint,
  doPoints,
  setNewDoPoint,
  recentPlaces,
  fleetId,
  checkPointInZoneSupported,
  limitPoint,
  isBookDetails,
  isPickupEditable,
  jobType,
  autocompleteCustomer,
  isDestinationEditable,
  isBookingEditable,
  partyLocation,
  isBookingFromPaxApp,
  format24Hour,
  auth,
  newbookingActions,
  customerActions,
  setNewDoPuPointAddTraveler,
  traveler_type,
  corporateId,
  options = {},
  corporateCompany,
  newBooking,
  cueActions,
  data,
  isSubmited,
  valid,
  promo,
  customer,
  cartypeSelected,
  paymentMethod,
  customerAutocompleteActions,
  customerAutocomplete,
  currencyISO,
  listDirtyLocation,
  isHydraBooking,
  setDirtyLocation,
  isChina,
  corporate,
  handleShowHideMarkFailed
}) => {

  const [isShowRoutePreview, setIsShowRoutePreview] = useState(false)
  return (
    <div className="createRoute">
      {/* <FormGroupTitle>
        <Translate value="newbooking.Create_Route" />
      </FormGroupTitle> */}

      <PointContainer
        puPoints={puPoints}
        handleShowHideMarkFailed={handleShowHideMarkFailed}
        jobType={jobType}
        limitPoint={limitPoint}
        recentPlaces={recentPlaces}
        setNewPuPoint={setNewPuPoint}
        doPoints={doPoints}
        fleetId={fleetId}
        isBookDetails={isBookDetails}
        checkPickupEditable={isPickupEditable}
        checkDestinationEditable={isDestinationEditable}
        checkBookingEditable={isBookingEditable}
        setNewDoPoint={setNewDoPoint}
        partyLocation={partyLocation}
        checkPointInZoneSupported={checkPointInZoneSupported}
        isBookingFromPaxApp={isBookingFromPaxApp}
        format24Hour={format24Hour}
        options={options}
        auth={auth}
        corporateCompany={corporateCompany}
        newBooking={newBooking}
        cueActions={cueActions}
        currencyISO={currencyISO}
        data={data}
        isSubmited={isSubmited}
        valid={valid}
        promo={promo}
        customer={customer}
        traveler_type={traveler_type}
        cartypeSelected={cartypeSelected}
        paymentMethod={paymentMethod}
        customerAutocompleteActions={customerAutocompleteActions}
        customerAutocomplete={customerAutocomplete}
        customerActions={customerActions}
        listDirtyLocation={listDirtyLocation}
        setDirtyLocation={setDirtyLocation}
        isHydraBooking={isHydraBooking}
        isChina={isChina}
        corporate={corporate}

        // shuttle
        setIsShowRoutePreview={setIsShowRoutePreview}
      />

      {/* Shuttle */}
      <ShuttleTraveler
        isShowRoutePreview={isShowRoutePreview}
        setIsShowRoutePreview={setIsShowRoutePreview}
        puPoints={puPoints}
        doPoints={doPoints}
        fleetId={fleetId}
        autocompleteCustomer={autocompleteCustomer}
        setNewPuPoint={setNewPuPoint}
        setNewDoPoint={setNewDoPoint}
        partyLocation={partyLocation}
        data={data}
        isBookingFromPaxApp={isBookingFromPaxApp}
        auth={auth}
        newbookingActions={newbookingActions}
        customerActions={customerActions}
        setNewDoPuPointAddTraveler={setNewDoPuPointAddTraveler}
        traveler_type={traveler_type}
        corporateId={corporateId}
      />
    </div>
  )
}

RouteInfo.defaultProps = {
  puPoints: [],
  doPoints: [],
  recentPlaces: {},
  fleetId: '',
  limitPoint: {},
  isBookDetails: false,
  jobType: '',
  auth: {},
  corporateCompany: {},
  newBooking: {},
  data: {},
  isSubmited: false,
  valid: false,
  promo: null,
  customer: null,
  traveler_type: null,
  cartypeSelected: null,
  paymentMethod: null,
  customerAutocompleteActions: null,
  customerAutocomplete: null,
  setNewPuPoint: () => { },
  setNewDoPoint: () => { },
  isPickupEditable: () => { },
  isDestinationEditable: () => { },
  checkPointInZoneSupported: () => { },
  partyLocation: false
}

export default RouteInfo