/* global google */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import moment from 'moment';
import { GoogleMap, HeatmapLayer } from '@react-google-maps/api';
import * as mapActions from '../../../actions/mapActions';
import HeatmapFilter from './HeatmapFilter';
import { geoPlaceDetailMapProvider } from '../../../utils/mapUtils';
import { mapIcons, heatMapMode, defaultHeatMapMode } from '../../../constants/commondata';
import { CCLiteCommonFunc, filterZonesBasedPermission, getLanguageAutoCompleteSearch } from '../../../utils/commonFunctions';

class Heatmap extends React.PureComponent {
  state = {
    heatmapData: []
  };

  componentDidMount() {
    this.getDefaultMap();
  }

  getDefaultMap = () => {
    const defaultMap = this.getDefaultZone();
    if (defaultMap && !this.state.selectedZone) {
      let latLng = null;
      if (
        this.props.commonData.location &&
        this.props.commonData.location.isChina
      ) {
          latLng = new window.qq.maps.LatLngBounds();
          let { coordinates } = defaultMap.geo;
          if (
            defaultMap.center &&
            defaultMap.center.coordinates &&
            defaultMap.center.coordinates.length > 0
          ) {
            coordinates = defaultMap.center.coordinates;
          }
          coordinates.map(cor => {
            cor.map(z => {
              latLng.extend(new window.qq.maps.LatLng(z[1], z[0]));
            });
          });
      } else {
        latLng = new google.maps.LatLngBounds();
        let coordinates = defaultMap?.geo?.coordinates || [];
        if (
          defaultMap.center &&
          defaultMap.center.coordinates &&
          defaultMap.center.coordinates.length > 0
        ) {
          coordinates = defaultMap.center.coordinates;
        }
        coordinates.map(cor => {
          cor.map(z => {
            latLng.extend(new google.maps.LatLng(z[1], z[0]));
          });
        });
      }

      const center = latLng.getCenter();
      if (this._mapComponent) {
        this._mapComponent.setCenter(center);
        this._mapComponent.setZoom(15);
        this.setState({ center });
      } else {
        setTimeout(() => {
          this.getDefaultMap();
        }, 100);
      }
    }
  }

  getDefaultZone = () => {
    const finalZone = filterZonesBasedPermission(this.props.auth, this.props.commonData.mapZone, [...this.props.commonData.companies, ...this.props.commonData.suppliers]);
    if (
      finalZone && finalZone.length > 0
    ) {
      let defaultMap = finalZone?.find(m => {
        return m?.isDefault && m?.isActive;
      });
      return defaultMap || finalZone?.[0];
    }
    return null;
  }

  getViewMode() {
    const { heatMapSetting } = this.props.fleet;
    return heatMapSetting.defaultMode || defaultHeatMapMode;
  }

  handleSubmit = params => {
    const {
      fleet: { fleetId }
    } = this.props;
    const query = { ...params, fleetId };
    this.setState({ loading: true });
    this.handleGetHeatmapData(query);
  };

  handleGetHeatmapData = (query, points = [], nextPage) => {
    this.props.mapActions
      .loadHeatmapData({
        query,
        page: nextPage || 0,
        limit: 5000
      })
      .then(({ res }) => {
        const filteredData = [];
        const { list, limit, page } = res;
        for (let i = 0; i < list.length; i++) {
          const point = list[i].location;
          const htmapPoint = {
            location: new google.maps.LatLng(point.lat, point.lon),
            weight: 1
          };
          filteredData.push(htmapPoint);
        }
        const { mapZone } = this.props.commonData;
        const zoneFilter = _.find(mapZone, o => o._id === query.zone);
        const center = {
          lat: zoneFilter.center.coordinates[0][0][1],
          lng: zoneFilter.center.coordinates[0][0][0],
        };
        points = points.concat(filteredData);
        if (limit <= list.length) {
          this.handleGetHeatmapData(query, points, page + 1);
        } else {
          this.setState({ heatmapData: points, center, loading: false, zoom: 12 });
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(new window.google.maps.LatLng(center.lat, center.lng));
          setTimeout(() => {
            this._mapComponent.fitBounds(bounds);
            this._mapComponent.setZoom(12);
          });
        }
      });
  }

  handleMapLoad = map => {
    if (map) {
      this._mapComponent = map
    }
  };

  handleSelectAddress = (data, placeName, pointOfInterest, sessionToken) => {
    if (placeName && placeName != '') {
      const callback = (err, localtion) => {
        if (err) {
          return;
        }
        if (!window.searchPlaceMarked) {
          window.searchPlaceMarked = new google.maps.marker.AdvancedMarkerElement({
            position: new google.maps.LatLng(localtion.lat, localtion.lng),
            content: CCLiteCommonFunc.createImgElementMarker(mapIcons.flag),
            map: this._mapComponent
          });
          this._mapComponent.setCenter(
            new google.maps.LatLng(localtion.lat, localtion.lng)
          );
          this._mapComponent.setZoom(18);
        } else {
          window.searchPlaceMarked.position = new google.maps.LatLng(localtion.lat, localtion.lng);
          window.searchPlaceMarked.content = CCLiteCommonFunc.createImgElementMarker(mapIcons.flag);
          window.searchPlaceMarked.map = this._mapComponent;
          this._mapComponent.setCenter(
            new google.maps.LatLng(localtion.lat, localtion.lng)
          );
          this._mapComponent.setZoom(18);
        }
        this.setState({ center: localtion });
      }
      geoPlaceDetailMapProvider({
          auth: this.props.auth,
          placeid: placeName,
          sessionToken,
          callback,
          language: this.props.language,
      })
    }
  };

  render() {
    const {
      fleet: { heatMapSetting }
    } = this.props;
    const heatMapOption = {
      opacity: 0.3,
      radius: 100,
      gradient: [
        'rgba(0,255,255,0)',
        '#00ff00',
        '#f6fd00',
        '#ffa21c',
        '#ff2a39'
      ],
      dissipating: true,
      ...heatMapSetting
    };
    const { heatmapData, loading } = this.state;
    return (
      <div className="mapqup fill mt-sm">
        <HeatmapFilter
          onSelectAddress={this.handleSelectAddress}
          onSubmit={this.handleSubmit}
          loading={loading}
        />
        {!this.props.commonData?.location?.isChina && (
          <GoogleMap
            onLoad={this.handleMapLoad}
            defaultZoom={15}
            center={this.state.center || { lat: 16.059959, lng: 108.224258 }}
            options={{ fullscreenControl: false, gestureHandling: 'greedy', mapId: process.env.REACT_APP_MAP_ID}}
            mapContainerClassName="mapqup fill"
          >
            {heatmapData?.length ? <HeatmapLayer data={heatmapData} options={heatMapOption} /> : null}
          </GoogleMap>
        )}
      </div>
    );
  }
}

Map.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    auth: state.auth,
    fleet: state.auth.selectedFleet,
    commonData: state.commonData,
    map: state.map,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n
  };
}

function mapDispatchToProps(dispatch) {
  return {
    mapActions: bindActionCreators(mapActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Heatmap);
