import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import { europeCountry, relationships } from '../../../constants/commondata';
import { Validator, ValidCase } from '../../../components/validator';
import { Validation } from '../../../utils/commonFunctions';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import UploadFile from '../../../components/upload/upload';
import CCDropDown from '../../../components/dropdown/ccDropDown';
import RadioButton from '../../../components/radioButton/radio';
import styles from '../styles/formPayment.module.scss';
import { BsQuestionCircleFill } from 'react-icons/bs';

class DriverInfo extends React.PureComponent {
  handleSelectBankName = (bankName) => {
    const { handleRTBNumberChange } = this.props;
    handleRTBNumberChange(bankName);
  };

  handleChangeBankName = (e) => {
    const bankName = e.target.value;
    const { handleRTBNumberChange } = this.props;
    handleRTBNumberChange(bankName);
  };

  renderCustomTitleBankName = () => {
    const { detailItem, bankInfoList } = this.props;
    if (!detailItem.driverInfo.routingNumber) {
      return (
        <span className="dropdown-title">{I18n.t('driver.Select_a_bank')}</span>
      );
    }
    const foundBank = _.find(
      bankInfoList,
      (b) => b.bankCode === detailItem.driverInfo.routingNumber
    );
    if (typeof foundBank === 'undefined') {
      return null;
    }
    return <span className="dropdown-title">{foundBank.bankName}</span>;
  };

  render() {
    const {
      // states
      isSubmited,
      valid,
      detailItem,
      verificationDocument,
      verificationDocumentBack,
      additionalDocument,
      additionalDocumentBack,
      bankAccountEditable,
      editable,
      reg,
      isRequireStripe,
      // props
      auth,
      // funcs
      isBankRequire,
      handleNameOfBankChange,
      handleIFSCCodeChange,
      validatorCallback,
      handleNameOfAccountChange,
      handleAccountNumberChange,
      handleCheckNumberChange,
      handleSSNChange,
      handleSortCodeChange,
      handleVerificationDocumentChange,
      handleVerificationDocumentBackChange,
      handleAdditionalDocumentChange,
      handleAdditionalDocumentBackChange,
      bankRequirement,
      bankInfoList,
      onRemoveBankInfo,
      handleRemoveBankDocumentChange,
      handeChangeIsBankAccountOwner,
      handleChangebeneficiary,
    } = this.props;
    const isShowRemoveButton = editable && detailItem.driverInfo.isBankVerified;

    /* hide verified status */
    const { selectedFleet } = auth;
    let isHideVerifyStatus = false;
    const walletGateway = _.get(selectedFleet, 'drvPayout.gateway');
    const showRelationship = _.get(
      selectedFleet,
      'drvPayout.showRelationship',
      false
    );
    if (
      bankRequirement.gateway !== 'Stripe' ||
      _.get(selectedFleet, 'bankingInfo.verifyAccount', 1) === 0
    ) {
      isHideVerifyStatus = true;
    }

    let additionalDocumentFileName = '';
    let additionalDocumentBackFileName = '';
    let verificationDocumentFileName = '';
    let verificationDocumentBackFileName = '';

    if (
      detailItem.driverInfo.additionalDocument &&
      detailItem.driverInfo.additionalDocument.length > 0
    ) {
      let splitUrlAdditionalDocument =
        detailItem.driverInfo.additionalDocument.split('/');
      additionalDocumentFileName =
        splitUrlAdditionalDocument[splitUrlAdditionalDocument.length - 1];
    }

    if (
      detailItem.driverInfo.additionalDocumentBack &&
      detailItem.driverInfo.additionalDocumentBack.length > 0
    ) {
      let splitUrlAdditionalDocumentBack =
        detailItem.driverInfo.additionalDocumentBack.split('/');
      additionalDocumentBackFileName =
        splitUrlAdditionalDocumentBack[
          splitUrlAdditionalDocumentBack.length - 1
        ];
    }

    if (
      detailItem.driverInfo.verificationDocument &&
      detailItem.driverInfo.verificationDocument.length > 0
    ) {
      let splitUrlVerificationDocument =
        detailItem.driverInfo.verificationDocument.split('/');
      verificationDocumentFileName =
        splitUrlVerificationDocument[splitUrlVerificationDocument.length - 1];
    }

    if (
      detailItem.driverInfo.verificationDocumentBack &&
      detailItem.driverInfo.verificationDocumentBack.length > 0
    ) {
      let splitUrlVerificationDocumentBack =
        detailItem.driverInfo.verificationDocumentBack.split('/');
      verificationDocumentBackFileName =
        splitUrlVerificationDocumentBack[
          splitUrlVerificationDocumentBack.length - 1
        ];
    }

    return (
      <>
        <Row>
          <Col xs={12}>
            <Form.Label>
              <h3 style={{ fontSize: '20px' }} className="mb-sm">
                <Translate value="driver.bankAccount" />
              </h3>
              <div className={styles['description']}>
                <Translate value="driver.bankAccountDes" />{' '}
              </div>
            </Form.Label>
          </Col>
          <Col xs={6} className="mb-md">
            {isShowRemoveButton ? (
              <a onClick={onRemoveBankInfo} href="javascript:void(0);">
                <Translate
                  className="cursor-pointer red"
                  value="newbooking.Remove"
                />
              </a>
            ) : null}
          </Col>
        </Row>
        <Row className={styles['flex-item-row']}>
          {bankRequirement.hideBankName ? null : (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <FormGroup
                className={
                  isSubmited &&
                  isBankRequire('bankName') &&
                  !detailItem.driverInfo.isBankVerified
                    ? valid.nameOfBank
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Name_of_bank" />
                  {isBankRequire('bankName') ? (
                    <span className="require"> *</span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={detailItem.driverInfo.nameOfBank}
                  onChange={handleNameOfBankChange}
                  disabled={!bankAccountEditable}
                />

                {isBankRequire('bankName') &&
                  !detailItem.driverInfo.isBankVerified && (
                    <Validator id="nameOfBank" callback={validatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            detailItem.driverInfo.nameOfBank
                          )
                        }
                        message={I18n.t('driver.ERROR_INPUT_NAME_OF_BANK')}
                      />
                    </Validator>
                  )}
              </FormGroup>
            </Col>
          )}
          {bankRequirement.hideIFSCCode ? null : (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <FormGroup
                className={
                  isSubmited &&
                  isBankRequire('IFSCCode') &&
                  !detailItem.driverInfo.isBankVerified
                    ? valid.IFSCCode
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.IFSCCode" />
                  {isBankRequire('IFSCCode') ? (
                    <span className="require"> *</span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={detailItem.driverInfo.IFSCCode}
                  onChange={handleIFSCCodeChange}
                  disabled={!bankAccountEditable}
                />

                {isBankRequire('IFSCCode') &&
                  !detailItem.driverInfo.isBankVerified && (
                    <Validator id="IFSCCode" callback={validatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            detailItem.driverInfo.IFSCCode
                          )
                        }
                        message={I18n.t('driver.IFSCCode')}
                      />
                    </Validator>
                  )}
              </FormGroup>
            </Col>
          )}
          <Col md={12} lg={4} className={styles['flex-item-col']}>
            <FormGroup
              className={
                isSubmited &&
                isBankRequire('accountName') &&
                !detailItem.driverInfo.isBankVerified
                  ? valid.nameOfAccount
                    ? null
                    : 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="driver.Name_of_account" />{' '}
                {isBankRequire('accountName') ? (
                  <span className="require"> *</span>
                ) : (
                  ''
                )}
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                placeholder={I18n.t('driver.First_M_Last')}
                value={detailItem.driverInfo.nameOfAccount}
                onChange={handleNameOfAccountChange}
                disabled={!bankAccountEditable}
              />
              {isBankRequire('accountName') &&
                !detailItem.driverInfo.isBankVerified && (
                  <Validator id="nameOfAccount" callback={validatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={
                        !Validation.isStringEmpty(
                          detailItem.driverInfo.nameOfAccount
                        )
                      }
                      message={I18n.t('driver.ERROR_INPUT_NAME_OF_ACCOUNT')}
                    />
                  </Validator>
                )}
            </FormGroup>
          </Col>
          {/* {!europeCountry.includes(auth.selectedFleet.countryCode) && ( */}
          {!bankRequirement.useIBAN &&
            !bankRequirement.useSortCode &&
            !bankRequirement.hideRoutingNumber && (
              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    isBankRequire('routingNumber') &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.routingNumber
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    {walletGateway === 'MOLPay' ? (
                      <Translate value="driver.bankName" />
                    ) : (
                      <Translate value="driver.RTBnumber" />
                    )}{' '}
                    {isBankRequire('routingNumber') ? (
                      <span className="require"> *</span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  {walletGateway === 'MOLPay' ? (
                    <CCDropDown
                      id="RTBnumber-dropdown"
                      items={bankInfoList}
                      title=""
                      valueKey="bankCode"
                      labelKey="bankName"
                      className="driver-select-licence-dropdown driverSelectBankName form-custom"
                      onSelect={this.handleSelectBankName}
                      selectedItems={
                        detailItem.driverInfo.routingNumber
                          ? [detailItem.driverInfo.routingNumber]
                          : []
                      }
                      // onSearchChange={_.debounce(handleSearchLicensePlate, 400)}
                      customTitle={this.renderCustomTitleBankName()}
                      disabled={!bankAccountEditable}
                      searchable
                      noTranslateLabel
                      enableClearAll
                    />
                  ) : (
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={
                        detailItem.driverInfo.routingNumber
                          ? detailItem.driverInfo.routingNumber
                          : ''
                      }
                      onChange={this.handleChangeBankName}
                      disabled={!bankAccountEditable}
                    />
                  )}
                  {isBankRequire('routingNumber') &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="routingNumber"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              detailItem.driverInfo.routingNumber
                            )
                          }
                          message={I18n.t('driver.ERROR_INPUT_BANK_NAME')}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>
            )}

          <Col md={12} lg={4} className={styles['flex-item-col']}>
            <FormGroup
              className={
                isSubmited &&
                isBankRequire('bankNumber') &&
                !detailItem.driverInfo.isBankVerified
                  ? valid.accountNumber
                    ? null
                    : 'error'
                  : null
              }
            >
              <Form.Label>
                {/* {europeCountry.includes(auth.selectedFleet.countryCode) ? ( */}
                {bankRequirement.useIBAN ? (
                  <Translate value="driver.IBAN_number" />
                ) : (
                  <Translate value="driver.Account_number" />
                )}

                {isBankRequire('bankNumber') ? (
                  <span className="require"> *</span>
                ) : (
                  ''
                )}
              </Form.Label>
              <FormControl
                className="form-custom"
                type="text"
                value={detailItem?.driverInfo?.accountNumber || ''}
                onChange={(e) => handleAccountNumberChange(e)}
                disabled={!bankAccountEditable}
                autoComplete="new-password"
              />
              {isBankRequire('bankNumber') &&
                !detailItem.driverInfo.isBankVerified && (
                  <Validator id="accountNumber" callback={validatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={
                        !Validation.isStringEmpty(
                          detailItem.driverInfo.accountNumber
                        )
                      }
                      message={I18n.t('driver.ERROR_INPUT_ACCOUNT_NUMBER')}
                    />
                    {/* <ValidCase
                  hide={!isSubmited}
                  valid={!(isRequireSettlement && !reg.test(detailItem.driverInfo.accountNumber))}
                  message={I18n.t('driver.ERROR_ACCOUNT_NUMBER_ONLY_NUMBERS')}
              /> */}
                  </Validator>
                )}
            </FormGroup>
          </Col>
          {bankRequirement.hideCheckNumber ? null : (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <FormGroup
                className={
                  isSubmited &&
                  isBankRequire('checkNumber') &&
                  !detailItem.driverInfo.isBankVerified
                    ? valid.checkNumber
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Check_number" />
                  {isBankRequire('checkNumber') ? (
                    <span className="require"> *</span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type="text"
                  value={detailItem.driverInfo.checkNumber}
                  onChange={handleCheckNumberChange}
                  disabled={!bankAccountEditable}
                />
                {isBankRequire('checkNumber') &&
                  !detailItem.driverInfo.isBankVerified && (
                    <Validator id="checkNumber" callback={validatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            detailItem.driverInfo.checkNumber
                          )
                        }
                        message={I18n.t('driver.ERROR_INPUT_CHECK_NUMBER')}
                      />
                    </Validator>
                  )}
              </FormGroup>
            </Col>
          )}
          {/* <Col md={12} lg={4} className={styles['flex-item-col']}>
          <FormGroup>
            <Form.Label>
              <Translate value="driver.Swift_code_number" />
            </Form.Label>
            <FormControl
              className="form-custom"
              type="text"
              value={detailItem.driverInfo.swiftCodeNumber}
              onChange={handleSwiftCodeNumberChange}
              disabled={!bankAccountEditable}
            />
          </FormGroup>
        </Col> */}
          {bankRequirement.hideSsn ? null : (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <FormGroup
                className={
                  isSubmited &&
                  isBankRequire('ssn') &&
                  !detailItem.driverInfo.isBankVerified
                    ? valid.ssn
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  {auth.selectedFleet.countryCode === 'US' ? (
                    <Translate value="driver.SSNNI" />
                  ) : (
                    <Translate value="driver.Document_Id" />
                  )}
                  {isBankRequire('ssn') ? (
                    <span className="require"> *</span>
                  ) : (
                    ''
                  )}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type={editable ? 'password' : 'text'}
                  value={
                    detailItem.driverInfo.ssn ? detailItem.driverInfo.ssn : ''
                  }
                  onChange={(e) => handleSSNChange(e)}
                  maxLength={50}
                  disabled={!bankAccountEditable}
                  autoComplete="new-password"
                />
                {isBankRequire('ssn') &&
                  !detailItem.driverInfo.isBankVerified && (
                    <Validator id="ssn" callback={validatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(detailItem.driverInfo.ssn)
                        }
                        message={
                          auth.selectedFleet.countryCode === 'US'
                            ? I18n.t('driver.ERROR_INPUT_SSN')
                            : I18n.t('driver.ERROR_INPUT_DocumentId')
                        }
                      />
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            detailItem.driverInfo.ssn
                          ) && detailItem.driverInfo.ssn.length >= 4
                        }
                        message={I18n.t('driver.ERROR_SSN_LENGTH')}
                      />
                    </Validator>
                  )}
              </FormGroup>
            </Col>
          )}

          {bankRequirement.useSortCode && (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <FormGroup
                className={
                  isSubmited &&
                  isBankRequire('sortCode') &&
                  !detailItem.driverInfo.isBankVerified
                    ? valid.sortCode
                      ? null
                      : 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.sortCode" />
                  {isBankRequire('sortCode') && (
                    <span className="require"> *</span>
                  )}
                </Form.Label>
                <FormControl
                  className="form-custom"
                  type={'text'}
                  value={
                    detailItem.driverInfo.sortCode
                      ? detailItem.driverInfo.sortCode
                      : ''
                  }
                  onChange={handleSortCodeChange}
                  maxLength={50}
                  disabled={!bankAccountEditable}
                />
                {!detailItem.driverInfo.isBankVerified &&
                  isBankRequire('sortCode') && (
                    <Validator id="sortCode" callback={validatorCallback}>
                      <ValidCase
                        hide={!isSubmited}
                        valid={
                          !Validation.isStringEmpty(
                            detailItem.driverInfo.sortCode
                          )
                        }
                        message={I18n.t('driver.ERROR_INPUT_SORTCODE')}
                      />
                      {/* <ValidCase
                    hide={!isSubmited}
                    valid={
                      !Validation.isStringEmpty(detailItem.driverInfo.sortCode)
                      && detailItem.driverInfo.sortCode.length >= 4
                    }
                    message={I18n.t('driver.ERROR_SSN_LENGTH')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={!(isRequireStripe && !reg.test(detailItem.driverInfo.sortCode))}
                    message={I18n.t('driver.ERROR_SSN_ONLY_NUMBERS')}
                  /> */}
                    </Validator>
                  )}
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row className={styles['flex-item-row']}>
          {bankRequirement.hideVerificationDocument ? null : (
            <>
              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    isBankRequire('verificationDocument') &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.verificationDocument
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Verification_document" />
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <Translate value="driver.Verification_document_tooltip" />
                        </Tooltip>
                      }
                    >
                      <BsQuestionCircleFill />
                    </OverlayTrigger>
                    {isBankRequire('verificationDocument') ? (
                      <span className="require"> *</span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <br />
                  {detailItem.driverInfo.verificationDocument ? (
                    <a
                      href={detailItem.driverInfo.verificationDocument}
                      target="_blank"
                    >
                      <Translate value="driver.Click_here_to_download" />
                    </a>
                  ) : null}
                  <UploadFile
                    id="verificationDocument"
                    name="verificationDocument"
                    disabled={!bankAccountEditable}
                    onChange={handleVerificationDocumentChange}
                    existFile={
                      verificationDocumentFileName
                        ? {
                            fileName: verificationDocumentFileName,
                            onRemove: () =>
                              handleRemoveBankDocumentChange(
                                'verificationDocument'
                              ),
                          }
                        : null
                    }
                  />
                  {isBankRequire('verificationDocument') &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="verificationDocument"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !!(
                              verificationDocument ||
                              detailItem.driverInfo.verificationDocument
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>

              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    isBankRequire('verificationDocument') &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.verificationDocumentBack
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Verification_document_back" />
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <Translate value="driver.Verification_document_tooltip" />
                        </Tooltip>
                      }
                    >
                      <BsQuestionCircleFill />
                    </OverlayTrigger>
                    {isBankRequire('verificationDocument') ? (
                      <span className="require"> *</span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <br />
                  {detailItem.driverInfo.verificationDocumentBack ? (
                    <a
                      href={detailItem.driverInfo.verificationDocumentBack}
                      target="_blank"
                    >
                      <Translate value="driver.Click_here_to_download" />
                    </a>
                  ) : null}
                  <UploadFile
                    id="verificationDocumentBack"
                    name="verificationDocumentBack"
                    disabled={!bankAccountEditable}
                    onChange={handleVerificationDocumentBackChange}
                    existFile={
                      verificationDocumentBackFileName
                        ? {
                            fileName: verificationDocumentBackFileName,
                            onRemove: () =>
                              handleRemoveBankDocumentChange(
                                'verificationDocumentBack'
                              ),
                          }
                        : null
                    }
                  />
                  {isBankRequire('verificationDocument') &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="verificationDocumentBack"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !!(
                              verificationDocumentBack ||
                              detailItem.driverInfo.verificationDocumentBack
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>
            </>
          )}
          {!bankRequirement.additionalDocument ? null : (
            <>
              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.additionalDocument
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Additional_document" />
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <Translate value="driver.Additional_document_tootip" />
                        </Tooltip>
                      }
                    >
                      <BsQuestionCircleFill />
                    </OverlayTrigger>
                    {isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified ? (
                      <span className="require"> *</span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <br />
                  {detailItem.driverInfo.additionalDocument ? (
                    <a
                      href={detailItem.driverInfo.additionalDocument}
                      target="_blank"
                    >
                      <Translate value="driver.Click_here_to_download" />
                    </a>
                  ) : null}
                  <UploadFile
                    id="additionalDocument"
                    name="additionalDocument"
                    disabled={!bankAccountEditable}
                    onChange={handleAdditionalDocumentChange}
                    existFile={
                      additionalDocumentFileName
                        ? {
                            fileName: additionalDocumentFileName,
                            onRemove: () =>
                              handleRemoveBankDocumentChange(
                                'additionalDocument'
                              ),
                          }
                        : null
                    }
                  />
                  {isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="additionalDocument"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !!(
                              additionalDocument ||
                              detailItem.driverInfo.additionalDocument
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>

              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.additionalDocumentBack
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.Additional_document_back" />
                    &nbsp;
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <Translate value="driver.Additional_document_tootip" />
                        </Tooltip>
                      }
                    >
                      <BsQuestionCircleFill />
                    </OverlayTrigger>
                    {isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified ? (
                      <span className="require"> *</span>
                    ) : (
                      ''
                    )}
                  </Form.Label>
                  <br />
                  {detailItem.driverInfo.additionalDocumentBack ? (
                    <a
                      href={detailItem.driverInfo.additionalDocumentBack}
                      target="_blank"
                    >
                      <Translate value="driver.Click_here_to_download" />
                    </a>
                  ) : null}
                  <UploadFile
                    id="additionalDocumentBack"
                    name="additionalDocumentBack"
                    disabled={!bankAccountEditable}
                    onChange={handleAdditionalDocumentBackChange}
                    existFile={
                      additionalDocumentBackFileName
                        ? {
                            fileName: additionalDocumentBackFileName,
                            onRemove: () =>
                              handleRemoveBankDocumentChange(
                                'additionalDocumentBack'
                              ),
                          }
                        : null
                    }
                  />
                  {isBankRequire('additionalDocument') &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="additionalDocumentBack"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !!(
                              additionalDocumentBack ||
                              detailItem.driverInfo.additionalDocumentBack
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
        <Row className="mb-md">
          {showRelationship && (
            <Col md={12} lg={4} className={styles['flex-item-col']}>
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col xs={12} className="mb-sm">
                  <Form.Label>
                    <Translate value="driver.isBankAccountOwner" />
                  </Form.Label>
                </Col>

                <Col lg={3} md={6} sm={6} xs={12}>
                  <RadioButton
                    text={I18n.t('driver.yes')}
                    inline
                    name="isCustomized"
                    value="true"
                    checked={detailItem.driverInfo.isBankAccountOwner}
                    onChange={handeChangeIsBankAccountOwner}
                    disabled={!bankAccountEditable}
                  />
                </Col>

                <Col lg={3} md={6} sm={6} xs={12}>
                  <RadioButton
                    text={I18n.t('driver.no')}
                    inline
                    name="isCustomized"
                    value="false"
                    checked={!detailItem.driverInfo.isBankAccountOwner}
                    onChange={handeChangeIsBankAccountOwner}
                    disabled={!bankAccountEditable}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className={styles['flex-item-row']}>
          {showRelationship && !detailItem.driverInfo.isBankAccountOwner && (
            <>
              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    !detailItem.driverInfo.isBankAccountOwner &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.beneficiaryIDIC
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.beneficiary_idic" />
                    <span className="require"> *</span>
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    type="text"
                    value={detailItem.driverInfo.beneficiaryIDIC}
                    onChange={(e) =>
                      handleChangebeneficiary('beneficiaryIDIC', e)
                    }
                    disabled={!bankAccountEditable}
                  />
                  {!detailItem.driverInfo.isBankAccountOwner &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="beneficiaryIDIC"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              detailItem.driverInfo.beneficiaryIDIC
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>

              <Col md={12} lg={4} className={styles['flex-item-col']}>
                <FormGroup
                  className={
                    isSubmited &&
                    !detailItem.driverInfo.isBankAccountOwner &&
                    !detailItem.driverInfo.isBankVerified
                      ? valid.bankRelationship
                        ? null
                        : 'error'
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="driver.relationship" />
                    <span className="require"> *</span>
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    as="select"
                    value={detailItem.driverInfo.bankRelationship}
                    placeholder="Select relationship status"
                    onChange={(e) =>
                      handleChangebeneficiary('bankRelationship', e)
                    }
                    disabled={!bankAccountEditable}
                  >
                    <option key={0} value={0}>
                      {I18n.t('driver.select_relationship')}
                    </option>
                    {relationships.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {I18n.t(`${item.label}`)}
                        </option>
                      );
                    })}
                  </FormControl>
                  {!detailItem.driverInfo.isBankAccountOwner &&
                    !detailItem.driverInfo.isBankVerified && (
                      <Validator
                        id="bankRelationship"
                        callback={validatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              detailItem.driverInfo.bankRelationship
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    )}
                </FormGroup>
              </Col>

              {showRelationship &&
                !!detailItem.driverInfo.bankRelationship &&
                (detailItem.driverInfo.bankRelationship === '6' ||
                  detailItem.driverInfo.bankRelationship === 6) && (
                  <Col md={12} lg={4} className={styles['flex-item-col']}>
                    <FormGroup
                      className={
                        isSubmited &&
                        !detailItem.driverInfo.isBankAccountOwner &&
                        (detailItem.driverInfo.bankRelationship === '6' ||
                          detailItem.driverInfo.bankRelationship === 6) &&
                        !detailItem.driverInfo.isBankVerified
                          ? valid.relationshipOtherName
                            ? null
                            : 'error'
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="driver.relationshipOtherName" />
                        <span className="require"> *</span>
                      </Form.Label>
                      <FormControl
                        className="form-custom"
                        type="text"
                        value={detailItem.driverInfo.relationshipOtherName}
                        onChange={(e) =>
                          handleChangebeneficiary('relationshipOtherName', e)
                        }
                        disabled={!bankAccountEditable}
                      />
                      {!detailItem.driverInfo.isBankAccountOwner &&
                        (detailItem.driverInfo.bankRelationship === '6' ||
                          detailItem.driverInfo.bankRelationship === 6) &&
                        !detailItem.driverInfo.isBankVerified && (
                          <Validator
                            id="relationshipOtherName"
                            callback={validatorCallback}
                          >
                            <ValidCase
                              hide={!isSubmited}
                              valid={
                                !Validation.isStringEmpty(
                                  detailItem.driverInfo.relationshipOtherName
                                )
                              }
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                            />
                          </Validator>
                        )}
                    </FormGroup>
                  </Col>
                )}
            </>
          )}

          {isHideVerifyStatus ? null : (
            <Col xs={12}>
              <FormGroup className="bankAccount__bankingStatus">
                <Form.Label>
                  <Translate value="driver.banking_status" />
                </Form.Label>
                {detailItem.driverInfo.isBankVerified ? (
                  <div className="text-active ml-lg">
                    <span
                      style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: detailItem.driverInfo.isBankVerified
                          ? 'green'
                          : 'red',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '8px',
                      }}
                    ></span>
                    {I18n.t('driver.bankingStatus.verified')}
                  </div>
                ) : (
                  <div className="red ml-lg">
                    <span
                      style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: detailItem.driverInfo.isBankVerified
                          ? 'green'
                          : 'red',
                        borderRadius: '50%',
                        display: 'inline-block',
                        marginRight: '8px',
                      }}
                    ></span>
                    {I18n.t('driver.bankingStatus.not_verified')}
                  </div>
                )}
              </FormGroup>
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default DriverInfo;
