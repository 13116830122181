import React, { Component } from 'react';
import _ from 'lodash';
import '../../settings.scss';
import { DefaultCurenciesInputField, DefaultCurenciesTopupAmountInputField } from '../../../../constants/commondata';
import InputCurrency from './InputCurrencys/InputCurrency';
import MultiCurrency from './InputCurrencys/MultiCurrency';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { WarningFeeInput } from '../../../../components/validator';
import {
    Col,
    Row,
    FormGroup,
    Button
} from 'react-bootstrap';
import LimitTopupAmount from './LimitTopupAmount/LimitTopupAmount';

export default class DefaultTopupValues extends Component {
    constructor(props) {
        super(props);
    }

    handleInputCurrenciesChange = (currency, e, keyName, index) => {
        const value = e.target.value;
        this.props.handleUpdateTopupValuesState(currency, value, keyName, index);
    };

    getDefaultTopupData = (data) => {
        const defaultTopupData = data.map(c => c.values.filter(vl => vl.value > 0))
        return defaultTopupData;
    }

    validateDataMinMax = (defaultTopupData, currency, id) => {
        // const dataCompareConfig = this.props[`${id}CreditWalletConfig`];

        /* Get data to validate from default topup data depending on currency */
        const dataValidating = _.reduce(defaultTopupData, (dataValidate, dataDefault) => {
            const dataCurrency = dataDefault.find(d => d.currencyISO === currency);
            if (dataCurrency) dataValidate.push(dataCurrency);
            return dataValidate;
        }, []);
        if (!dataValidating) return true;

        /* Start validating */
        // const { maximumByCurrencies = [], minimumByCurrencies = [] } = dataCompareConfig || {};
        const defaultLimitTopupData = this.props.keyApp ? this.props.keyApp === 'driver' ? this.props.driverLimitTopup : this.props.passengerLimitTopup : null
        /* Max validate */
        const maximumObjectByCurrencies = _.get(defaultLimitTopupData, 'maximumByCurrencies[0]')
        // maximumByCurrencies.find(m => m.currencyISO === currency);
        if (maximumObjectByCurrencies && maximumObjectByCurrencies.value > 0) {
            const overMaxData = dataValidating.find(d => parseFloat(d.value) > parseFloat(maximumObjectByCurrencies.value));
            if (overMaxData) return false;
        }

        /* Min validate */
        const minimumObjectByCurrencies = _.get(defaultLimitTopupData, 'minimumByCurrencies[0]')
        // minimumByCurrencies.find(m => m.currencyISO === currency);
        const underminData = dataValidating.find(
            d => minimumObjectByCurrencies
                ? parseFloat(d.value) < parseFloat(minimumObjectByCurrencies.value) || parseFloat(d.value) <= 0
                : parseFloat(d.value) <= 0
        );
        if (underminData) return false;

        return true;
    }

    render() {
        const {
            keyApp,
            hasPermission,
            auth,
            currency,
            ValidatorCallback,
            handleSaveClick,
            isSubmited,
            valid,
            tabMultiSelectHandle,
            activeKeys,
            driverLimitTopup,
            passengerLimitTopup,
        } = this.props;
        const defaultTopupData = this.props[`${keyApp}App`] ? this.getDefaultTopupData(this.props[`${keyApp}App`]) : null;
        const defaultLimitTopupData = this.props.keyApp ? keyApp === 'driver' ? driverLimitTopup : passengerLimitTopup : null
        const warningFeeInputData = defaultTopupData.map(dts => {
            return dts.map(dt => {
                const { currencyISO, value } = dt;
                return {
                    [currencyISO]: value
                }
            });
        });

        return (
            <div className="group-general">
                <FormGroupTitle>
                    <Translate value={`generalSetting.${keyApp}App`} />
                </FormGroupTitle>
                <Row>
                    <Col xs={12}>
                        {
                            auth.selectedFleet.multiCurrencies
                                ? <MultiCurrency
                                    keyApp={keyApp}
                                    defaultTopupData={defaultTopupData}
                                    handleInputCurrenciesChange={this.handleInputCurrenciesChange}
                                    hasPermission={hasPermission}
                                    auth={auth}
                                    currency={currency}
                                    DefaultCurenciesInputField={DefaultCurenciesInputField}
                                    ValidatorCallback={ValidatorCallback}
                                    isSubmited={isSubmited}
                                    valid={valid}
                                    validateDataMinMax={this.validateDataMinMax}
                                    activeKeys={activeKeys}
                                    tabMultiSelectHandle={tabMultiSelectHandle}
                                    creditWalletConfig={this.props[`${keyApp}CreditWalletConfig`]}
                                />
                                : <InputCurrency
                                    keyApp={keyApp}
                                    defaultTopupData={defaultTopupData}
                                    handleInputCurrenciesChange={this.handleInputCurrenciesChange}
                                    hasPermission={hasPermission}
                                    auth={auth}
                                    currency={currency}
                                    DefaultCurenciesInputField={DefaultCurenciesInputField}
                                    ValidatorCallback={ValidatorCallback}
                                    isSubmited={isSubmited}
                                    valid={valid}
                                    validateDataMinMax={this.validateDataMinMax}
                                    creditWalletConfig={this.props[`${keyApp}CreditWalletConfig`]}
                                    maximumValueByCurrenciesProps={_.get(defaultLimitTopupData, 'maximumByCurrencies[0].value')}
                                    minimumValueByCurrenciesProps={_.get(defaultLimitTopupData, 'minimumByCurrencies[0].value')}
                                />
                        }

                        <FormGroupTitle>
                            <Translate value={`generalSetting.limitTopupAmount.title`} />
                        </FormGroupTitle>
                        <LimitTopupAmount
                            keyApp={keyApp}
                            defaultLimitTopupData={defaultLimitTopupData}
                            handleUpdateLimitTopup={this.props.handleUpdateLimitTopup}
                            hasPermission={hasPermission}
                            auth={auth}
                            currency={currency}
                            DefaultCurenciesInputField={DefaultCurenciesTopupAmountInputField}
                            ValidatorCallback={ValidatorCallback}
                            isSubmited={isSubmited}
                            valid={valid}
                            validateDataMinMax={this.validateDataMinMax}
                            creditWalletConfig={this.props[`${keyApp}CreditWalletConfig`]}
                        />
                    </Col>
                    <Col xs={12}>
                        <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!hasPermission}
                            value={warningFeeInputData}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

