
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  newBookingRequest,
} from '../../actions/newbookingAction';

class NewbookingFromUrl extends React.Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.props.newBookingRequest()
  }
  render() {
    return (
      <div>
        New Booking
      </div>   
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // newbookingActions: bindActionCreators(newbookingActions, dispatch),
    newBookingRequest: () => dispatch(newBookingRequest()),
  };
}
export default connect(null, mapDispatchToProps)(NewbookingFromUrl);