import React, { Component } from 'react';
import {
  ButtonGroup,
  FormGroup,
  Modal,
  Button,
  Tabs,
  Tab,
  ButtonToolbar,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as queuingAreaActions from './../../actions/queuingAreaActions';

import AddEditQueuingArea from './AddEditQueuingArea';
import DriversList from './driverslist/DriversList';
import { BsSearch } from 'react-icons/bs';

class QueueModalTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      isLoading: false,
      str: '',
      driverFilter: '',
      queueDetailData: null,
    };
  }

  componentDidMount() {
    const { queueId, editable } = this.props || {};
    if (queueId)
      this.setState(
        {
          activeTab: editable ? 1 : 0,
        },
        () => this.fetchQueueDetailData(queueId)
      );
  }

  fetchQueueDetailData = (queueId) => {
    this.props.queuingAreaActions
      .detailsQueuingArea({
        _id: queueId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((data) => {
        if (!data.error) {
          const queueDetailData = data.res;
          this.setState({
            queueDetailData,
          });
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('queuingArea.Load_queuingArea_fail')
          );
        }
      });
  };

  setActiveTab = (e) => {
    e.preventDefault();
    const activeTab = parseInt(e.target.dataset.index) || 0;
    this.setState({
      activeTab,
    });
  };

  handleSearchKeyPress = (e) => {
    if (e.key == 'Enter') {
      this.setState({
        driverFilter: this.state.str.toLowerCase(),
      });
    }
  };

  handleSearchChange = (e) => {
    const str = e.target.value;
    this.setState({
      str,
    });
  };

  handleInsertDriver = () => {
    if (!this.driverList) return;
    this.driverList.handleMenuClick('Add');
  };

  handleGetListDrivers = () => {
    if (!this.driverList) return;
    this.driverList.getListDriversCheckinQueue();
  };

  handleChangeActiveTabs = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  render() {
    const {
      queueId,
      queueName,
      editable,
      carList,
      zonesData,
      handleMenuClick,
      handleUpdateFormSuccess,
      closeDialogForm,
      permissions,
    } = this.props || {};
    const { actions = false } = permissions || {};
    const {
      activeTab = 1,
      str,
      isLoading,
      driverFilter,
      queueDetailData,
    } = this.state || {};
    const { carIds = [], openQueue = true } = queueDetailData || {};

    console.log(activeTab);
    return (
      <Modal
        show
        backdrop
        dialogClassName="model-super-large fit-content-modal queu-area-modal"
        onHide={this.props.closeDialogForm}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {queueId ? (
              <span className="text-capitalize">{queueName}</span>
            ) : (
              <Translate value="queuingArea.ADD_QUEUING_AREA" />
            )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeDialogForm}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="queuing-area-tabs-group">
            <Tabs id="queuing-area-tab" onSelect={this.handleChangeActiveTabs}>
              {queueId && (
                <Tab
                  eventKey={0}
                  title={I18n.t('queuingArea.queuingDrivers')}
                  className="cue_active"
                >
                  <div className="gridViewTable" id="driver-table-id">
                    <DriversList
                      ref={(driverList) => {
                        if (driverList)
                          this.driverList = driverList.getWrappedInstance();
                      }}
                      queueId={queueId}
                      driverFilter={driverFilter}
                      carIds={carIds}
                      carList={carList}
                      openQueue={openQueue}
                      editable={editable}
                      isActive={activeTab == 0}
                    />
                  </div>
                </Tab>
              )}
              <Tab
                eventKey={1}
                title={I18n.t('queuingArea.queuing_area_info')}
                className="cue_active"
              >
                <AddEditQueuingArea
                  editable={editable}
                  carList={carList}
                  zonesData={zonesData}
                  handleMenuClick={handleMenuClick}
                  queueId={queueId}
                  handleUpdateFormSuccess={handleUpdateFormSuccess}
                  closeDialogForm={closeDialogForm}
                  queueDetailData={queueDetailData}
                />
              </Tab>
            </Tabs>
            {activeTab == 0 && queueId && (
              <ButtonToolbar
                role="presentation"
                className="queuing-driver-control"
              >
                <ButtonGroup className="group-left">
                  <FormGroup className="search-format mb0 search-by">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control search-form"
                        value={str}
                        onKeyPress={this.handleSearchKeyPress}
                        onChange={this.handleSearchChange}
                        placeholder={I18n.t('General.searchQueuingDriver')}
                        disabled={isLoading}
                      />
                      {isLoading ? (
                        <i className="fa fa-circle-o-notch fa-spin spinner-icon" />
                      ) : (
                        <BsSearch className="search-icon" />
                      )}
                    </div>
                  </FormGroup>
                </ButtonGroup>
                <ButtonGroup className="group-right">
                  {permissions && actions && (
                    <Button
                      className="btn-header"
                      onClick={() => this.handleInsertDriver()}
                    >
                      <Translate value="queuingArea.drivers.insert" />
                    </Button>
                  )}
                  <Button
                    className="btn-header"
                    onClick={() => this.handleGetListDrivers()}
                  >
                    <Translate value="queuingArea.drivers.refresh" />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            )}
          </div>
          {/* <div id="queuing-area-tab" className="cue-tabs queue-area-tab">

                        <ul role="tablist" className="nav nav-tabs">
                            {
                                queueId && <li role="presentation" className={activeTab == 0 ? "active" : ""}>
                                    <a id="queuing-area-tab-tab-0"
                                        role="tab"
                                        aria-controls="queuing-area-tab-pane-0"
                                        aria-selected={activeTab == 0 ? "true" : "false"}
                                        tabIndex={activeTab == 0 ? "0" : "-1"}
                                        data-index={0}
                                        onClick={this.setActiveTab}
                                        href="/">{I18n.t('queuingArea.queuingDrivers')}</a>
                                </li>
                            }
                            <li role="presentation" className={activeTab == 1 ? "active" : ""}>
                                <a id="queuing-area-tab-tab-1"
                                    role="tab"
                                    aria-controls="queuing-area-tab-pane-1"
                                    tabIndex={activeTab == 1 ? "0" : "-1"}
                                    data-index={1}
                                    onClick={this.setActiveTab}
                                    aria-selected={activeTab == 0 ? "true" : "false"}
                                    href="/">{I18n.t('queuingArea.queuing_area_info')}</a>
                            </li>
                            {
                                activeTab == 0 && queueId && <li role="presentation" className="queuing-driver-control">
                                    <ButtonGroup className="group-left">
                                        <FormGroup className="search-format mb0 search-by">
                                            <div className="input-container">
                                                <input
                                                    type="text"
                                                    className="form-control search-form"
                                                    value={str}
                                                    onKeyPress={this.handleSearchKeyPress}
                                                    onChange={this.handleSearchChange}
                                                    placeholder={I18n.t('General.searchQueuingDriver')}
                                                    disabled={isLoading}
                                                />
                                                {isLoading ? (
                                                    <i className="fa fa-circle-o-notch fa-spin spinner-icon" />
                                                ) : (
                                                        <BsSearch className="search-icon" />
                                                    )}
                                            </div>
                                        </FormGroup>
                                    </ButtonGroup>
                                    <ButtonGroup className="group-right">
                                        {
                                            permissions && actions && <Button
                                                className="btn-header"
                                                onClick={() => this.handleInsertDriver()}
                                            >
                                                <Translate value="queuingArea.drivers.insert" />
                                            </Button>
                                        }
                                        <Button
                                            className="btn-header"
                                            onClick={() => this.handleGetListDrivers()}
                                        >
                                            <Translate value="queuingArea.drivers.refresh" />
                                        </Button>
                                    </ButtonGroup>

                                </li>
                            }
                        </ul>
                        <div className="tab-content">
                            <div id="queuing-area-tab-pane-0"
                                aria-labelledby="queuing-area-tab-tab-0"
                                role="tabpanel"
                                aria-hidden={activeTab == 0 ? "false" : "true"}
                                className={`tab-pane ${activeTab == 0 ? 'active' : ''} fade in`}
                            >
                                <div className="gridViewTable" id="driver-table-id">
                                    <DriversList
                                        ref={driverList => {
                                            if (driverList) this.driverList = driverList.getWrappedInstance();
                                        }}
                                        queueId={queueId}
                                        driverFilter={driverFilter}
                                        carIds={carIds}
                                        carList={carList}
                                        openQueue={openQueue}
                                        editable={editable}
                                        isActive={activeTab == 0}
                                    />
                                </div>
                            </div>
                            <div id="queuing-area-tab-pane-1"
                                aria-labelledby="queuing-area-tab-tab-1"
                                role="tabpanel"
                                aria-hidden={activeTab == 1 ? "false" : "true"}
                                className={`tab-pane ${activeTab == 1 ? 'active' : ''} fade in`}
                            >
                                <AddEditQueuingArea
                                    editable={editable}
                                    carList={carList}
                                    zonesData={zonesData}
                                    handleMenuClick={handleMenuClick}
                                    queueId={queueId}
                                    handleUpdateFormSuccess={handleUpdateFormSuccess}
                                    closeDialogForm={closeDialogForm}
                                    queueDetailData={queueDetailData}
                                />
                            </div>

                        </div>
                    </div> */}
        </Modal.Body>
      </Modal>
    );
  }
}

QueueModalTabs.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueModalTabs);
