import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import {
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import {
  userType,
  HYDRA_STATUS,
  SUPPLIER_TYPE,
  STATUS_BOOKING,
} from '../../../constants/commondata';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import CcCheckbox from '../../ccCheckbox/CcCheckbox';
import {
  CCLiteCommonFunc,
  filterCarTypeWithoutAssignRate,
  filterCarTypeTransport,
  checkDisableSupplierSupportZone,
} from '../../../utils/commonFunctions';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import VehicleSelection from './VehicleSelection';
import SelectMulti from '../../SelectMulti';
import _ from 'lodash';
import { checkShowFare } from '../bookFunction/bookingInfo';

class BookingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDatePicker: false,
      selectedIndex: 0,
      lightboxIsOpen: false,
    };

    // this.renderDriverCompany = this.renderDriverCompany.bind(this);
    this.renderIntelHourlyPackageRate =
      this.renderIntelHourlyPackageRate.bind(this);
    this.renderRates = this.renderRates.bind(this);
    this.renderCarTypeBookDetail = this.renderCarTypeBookDetail.bind(this);
    this.renderCarTypeNewBooking = this.renderCarTypeNewBooking.bind(this);
    this.timeModeRef = React.createRef();
  }

  convertValueForMultiSelect = (supplierCompanies, companies, isMulti) => {
    let supplierArr = [];
    if (
      !companies ||
      !supplierCompanies ||
      supplierCompanies.includes('all') ||
      supplierCompanies.length === 0
    ) {
      supplierArr = [{ value: 'all', label: 'All Company' }];
    }
    supplierCompanies.map((cp) => {
      const cpInfo = companies.find((ob) => ob._id === cp);
      if (cpInfo) supplierArr.push({ value: cpInfo._id, label: cpInfo.name });
    });
    return isMulti ? supplierArr : supplierArr[0];
  };

  getOptionSupplier = () => {
    const {
      companyList,
      commonData,
      data,
      isEditable,
      roaming = false,
      farmIn,
      farmOut,
      user,
      corporate,
      is3rdBooking,
      zonePickup,
      supplierCompanies,
    } = this.props;
    const isPermissionCompany = !!user.roles.companyId;
    const bookDetail = !!data.bookId;
    let companyRender = companyList ? companyList : [...commonData.companies, ...commonData.suppliers];
    const userTypeLogin = _.get(user, 'userType', '');
    const supplierType = _.get(corporate, 'supplier.type', '');
    let disable = false;
    if (bookDetail) {
      disable =
        [STATUS_BOOKING.booked, STATUS_BOOKING.arrived].includes(data.status)
          ? false
          : !isEditable(data, true) || roaming;
      const statusHydraFarmin = _.get(data, 'hydraInfo.status');
      const bookingBookFrom = _.get(data, 'bookFrom');
      if (
        (farmIn &&
          (statusHydraFarmin === HYDRA_STATUS.pending ||
            statusHydraFarmin === HYDRA_STATUS.accepted ||
            bookingBookFrom === 'PWA')) ||
        (farmOut && statusHydraFarmin === HYDRA_STATUS.accepted)
        //  ||
        // isPermissionCompany
      )
        disable = true;

      // Disable select supplier with corporate board
      if (
        [userType.CorporateUser, userType.CorporateAdmin].includes(
          userTypeLogin // login o corporate board
        ) &&
        data?.operator?.isAdmin && // booking duoc tao tu user admin
        ((supplierType === SUPPLIER_TYPE.customized &&
          (supplierCompanies?.length === 0 || 
            !supplierCompanies.every((s) =>
              companyRender.some((c) => c._id === s)
            ))) || // corporate custome supplier nhung it nhat 1 supplier khong duoc support
          checkDisableSupplierSupportZone(
            zonePickup?._id,
            supplierCompanies,
            [...commonData.companies, ...commonData.suppliers]
          )) // or it nhat 1 supplier khong support zone
      ) {
        companyRender =
          commonData.companies &&
          [...commonData.companies, ...commonData.suppliers].filter((c) => supplierCompanies.includes(c._id));
        disable = true;
      }
    }

    if (
      !(
        [userType.CorporateUser, userType.CorporateAdmin].includes(
          userTypeLogin
        ) && supplierType === SUPPLIER_TYPE.customized
      ) 
      // &&
      // !isPermissionCompany
    ) {
      companyRender = [{ _id: 'all', name: 'All Company' }, ...companyRender];
    }

    const companyOption =
      (companyRender &&
        companyRender.map((cp) => {
          return { value: cp._id, label: cp.name };
        })) ||
      [];

    return {
      disable,
      companyOption,
    };
  };

  renderSupplier = () => {
    const {
      commonData,
      locationPickUp,
      newBooking, // new booking common data
      user, // logged user
      selectedFleet, // current fleet
      setSupplier,
      supplierId,
      farmIn,
      isHydraBooking,
      supplierCompanies,
      handleChangeSupplierCompanies: handleChangeSupplierCompaniesProp,
    } = this.props;

    const fleetEnableBroadcastBooking =
      selectedFleet?.process?.broadcastBooking || true;
    const isPermissionCompany = !!user.roles.companyId;
    let { disable, companyOption } = this.getOptionSupplier();
    const cpn = (
      <div className="mr-b-20">
        <SelectMulti
          isMulti={fleetEnableBroadcastBooking || false}
          isDisabled={disable}
          value={this.convertValueForMultiSelect(
            supplierCompanies,
            [...commonData.companies, ...commonData.suppliers],
            fleetEnableBroadcastBooking
          )}
          onChange={(newArr, option) =>
            handleChangeSupplierCompaniesProp(
              newArr,
              option,
              fleetEnableBroadcastBooking
            )
          }
          options={companyOption}
          isSearchable
        />
      </div>
    );

    if (
      locationPickUp &&
      (!isHydraBooking || farmIn)
      // (newBooking.locationService && !newBooking.locationService.crossZone)
    ) {
      // if authenticated user is fleet admin or fleet user, business is the same
      if (
        user.userType == userType.FleetAdmin ||
        user.userType == userType.FleetUser
      ) {
        return cpn;
      } else if (
        user.userType == userType.CorporateAdmin ||
        user.userType == userType.CorporateUser
      ) {
        // if authenticated user is corp admin or corp user, check if setting hide or show company
        if (!selectedFleet.generalSetting.hideProviderFromCorp) {
          return cpn;
        }
      }
    }

    if (isHydraBooking) {
      const fleetSupport = _.get(
        newBooking,
        'locationService.affiliateService.fleetSupport',
        []
      );
      const viewHydra = (
        <FormGroup>
          <FormControl
            as="select"
            className="form-custom"
            value={supplierId}
            disabled={disable}
            onChange={setSupplier}
          >
            {isPermissionCompany && !bookDetail ? null : (
              <option value={-1}>{I18n.t('newbooking.All_Suppliers')}</option>
            )}
            {fleetSupport.map((c) => {
              return (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              );
            })}
          </FormControl>
        </FormGroup>
      );
      return viewHydra;
    }
    return null;
  };

  renderIntelHourlyPackageRate(disabled) {
    const {
      etaFareState,
      etaFareProps,
      handleDurationPackageChange,
      data,
      locationDestination,
      newBooking,
      selectedFleet,
      isFareEdited = false
    } = this.props;
    let etaFare = etaFareState || etaFareProps;
    disabled = isFareEdited ? true : disabled;
    if (
      selectedFleet.generalSetting.disableHourlyRate &&
      newBooking.locationService.supportedRates.hourly &&
      newBooking.locationService.supportedRates.flatFare
    )
      return null;
    if (data.pricingType == 1) {
      var durationPackage = _.get(data.request, 'durationPackage', {});
      if (durationPackage && durationPackage._id) {
        return (
          <FormControl
            as="select"
            value={durationPackage._id}
            disabled={disabled}
            className="form-custom"
          >
            <option key={durationPackage._id} value={durationPackage._id}>
              {durationPackage.name}
            </option>
          </FormControl>
        );
      }
      return null;
    }
    if (data.duration) {
      let selectList = [];
      if (etaFare && etaFare.packages && etaFare.packages.length > 0) {
        // intel package available
        let intelPackages = etaFare.packages.sort(function (a, b) {
          // sort for the best price
          return a.value - b.value;
        });
        selectList = intelPackages.map((obj, index) => {
          let name = obj.name;
          if (locationDestination) {
            name =
              index == 0
                ? `${obj.name} - ${I18n.t(
                    'newbooking.Best_price'
                  )} ${currencyFormatter.format(obj.value, {
                    code: etaFare.currencyISO,
                  })}`
                : `${obj.name} - ${
                    etaFare.currencyISO
                  } ${currencyFormatter.format(obj.value, {
                    code: etaFare.currencyISO,
                  })}`;
          }
          return {
            name,
            value: obj.value,
            _id: obj._id,
          };
        });
      } else {
        selectList = newBooking.locationService.packagesRate;
      }
      return (
        <FormControl
          as="select"
          value={data.packageRateId}
          className="form-custom"
          disabled={disabled}
          placeholder="select"
          onChange={handleDurationPackageChange}
        >
          {selectList.map((packa) => {
            return (
              <option value={packa._id} key={packa._id}>
                {packa.name}
              </option>
            );
          })}
        </FormControl>
      );
    }
    return '';
  }

  renderRates(isHourly, isRoundTrip) {
    let {
      locationPickUp,
      newBooking,
      data,
      selectedFleet,
      etaFareState,
      handleRoundtripChange,
      handleBookingRateChange,
      isEditable,
      isMultiPuDoNotEmpty,
      multiPointCalFareMode,
      roaming,
      isDisableWhenPrepaided,
      isFareEdited
    } = this.props;

    const isBookDetail = !!data.bookId;
    let disabled = false;
    let showFareService =
      isMultiPuDoNotEmpty && multiPointCalFareMode === 'perPoint'
        ? false
        : true;
    let hideRoundTripBasedCalFare =
      isMultiPuDoNotEmpty && multiPointCalFareMode === 'startToEnd'
        ? true
        : false;
    if (isBookDetail) {
      disabled =
        !isEditable(data) ||
        data.pricingType ||
        roaming ||
        isDisableWhenPrepaided
    }
    disabled = isFareEdited ? true : disabled;
    if (
      locationPickUp &&
      newBooking.locationService &&
      newBooking.locationService.supportedRates
    )
      if (
        newBooking.locationService.supportedRates.flatFare ||
        newBooking.locationService.supportedRates.hourly
      ) {
        return (
          <div>
            {showFareService && (
              <>
                <FormGroupTitle>Pricing Plan</FormGroupTitle>
                <FormGroup sm={12}>
                  <FormControl
                    as="select"
                    className="form-custom"
                    onChange={handleBookingRateChange}
                    value={data.duration ? 1 : 0}
                    disabled={disabled}
                  >
                    {newBooking.locationService.supportedRates.flatFare ? (
                      <option value="0">
                        {I18n.t('newbooking.Flat_rate')}
                      </option>
                    ) : (
                      newBooking.locationService.supportedRates.regular && (
                        <option value="0">
                          {I18n.t('newbooking.Regular')}
                        </option>
                      )
                    )}
                    {newBooking.locationService.supportedRates.hourly &&
                      !(
                        // Disable hourly rate when both hourly and flat rate are available
                        (
                          selectedFleet.generalSetting.disableHourlyRate &&
                          newBooking.locationService.supportedRates.hourly &&
                          newBooking.locationService.supportedRates.flatFare
                        )
                      ) && (
                        <option value="1">
                          {I18n.t('newbooking.Duration')}
                        </option>
                      )}
                  </FormControl>
                </FormGroup>
                {data.duration
                  ? this.renderIntelHourlyPackageRate(disabled)
                  : newBooking.locationService.supportedRates.roundTrip &&
                    !hideRoundTripBasedCalFare && (
                      <FormGroup>
                        <CcCheckbox
                          checked={data.roundTrip}
                          onChange={handleRoundtripChange}
                          text={I18n.t('newbooking.Round_trip')}
                          disabled={disabled}
                        />
                      </FormGroup>
                    )}
              </>
            )}
          </div>
        );
      } else if ((etaFareState && !etaFareState.normalFare) || isHourly) {
        return (
          <div>
            <FormGroup sm={12}>
              <FormControl
                as="select"
                className="form-custom"
                onChange={handleBookingRateChange}
                value={data.duration ? 1 : 0}
                disabled={disabled}
              >
                {etaFareState &&
                !etaFareState.normalFare &&
                !!etaFareState.route ? (
                  <option value="0">{I18n.t('newbooking.Flat_rate')}</option>
                ) : (
                  (isHourly || !etaFareState.normalFare) && (
                    <option value="0">{I18n.t('newbooking.Regular')}</option>
                  )
                )}

                {isHourly && (
                  <option value="1">{I18n.t('newbooking.Duration')}</option>
                )}
              </FormControl>
            </FormGroup>
            {data.duration
              ? this.renderIntelHourlyPackageRate()
              : isRoundTrip && (
                  <FormGroup>
                    <CcCheckbox
                      checked={data.roundTrip}
                      onChange={handleRoundtripChange}
                      text={I18n.t('newbooking.Round_trip')}
                      disabled={disabled}
                    />
                  </FormGroup>
                )}
          </div>
        );
      }

    return <span />;
  }

  renderCarTypeNewBooking() {
    const {
      cartypeSelected,
      //carType Props
      handleCarTypeChanged,
      handleCarTypeChangedHydra,
      supplierId,
      nearestDriver,
      //new Booking Props
      newBooking,
      locationPickUp,
      time,
      isShuttle,
      nearestDriverList,
      selectedFleet,
      isHydraBooking,
      locationDestination,
      data,
      corporate,
      etaFareState
    } = this.props;
    let { etaFareMultiCar } = this.props;
    if (
      !checkShowFare({
        puPoints: locationPickUp ? [locationPickUp] : [],
        doPoints: locationDestination ? [locationDestination] : [],
        etaFare: etaFareState
      })
    ) {
      etaFareMultiCar = [];
    }
    let vehicleTypesRender =
      (newBooking.locationService && newBooking.locationService.vehicleType) ||
      [];
    if (isShuttle) {
      vehicleTypesRender = vehicleTypesRender.filter((ob) => ob.shuttle);
    } else {
      vehicleTypesRender = filterCarTypeTransport(vehicleTypesRender);
    }

    const notShowCarTypeCCWithoutRate = _.get(
      this.props.selectedFleet,
      'generalSetting.notShowCarTypeCCWithoutRate',
      false
    );
    if (notShowCarTypeCCWithoutRate) {
      vehicleTypesRender = filterCarTypeWithoutAssignRate(
        vehicleTypesRender,
        corporate,
        isShuttle
      );
    }
    // filter hydra cartype
    if (isHydraBooking) {
      const vehicleTypes = _.get(
        newBooking,
        'locationService.affiliateService.vehicleTypeHydraSplited',
        []
      );
      if (locationPickUp && vehicleTypes.length > 0) {
        let vehicleHydra = [];
        vehicleTypes.map((car) => {
          if (!supplierId || car.providerOfVhc.fleetId === supplierId) {
            vehicleHydra.push({
              ...car,
              provider: car.providerOfVhc,
            });
          }
        });
        vehicleTypesRender = vehicleHydra;
      }
    }

    return locationPickUp && newBooking.locationService ? (
      <FormGroup>
        <VehicleSelection
          nearestDriverList={nearestDriverList}
          locationDestination={locationDestination}
          cartypeSelected={cartypeSelected}
          etaFareMultiCar={etaFareMultiCar}
          vehicleTypes={vehicleTypesRender}
          selectedFleet={selectedFleet}
          handleCarTypeChanged={handleCarTypeChanged}
          time={time}
          data={data}
          handleCarTypeChangedHydra={handleCarTypeChangedHydra}
          isHydraBooking={isHydraBooking}
        />
      </FormGroup>
    ) : (
      ''
    );
  }

  renderCarTypeBookDetail() {
    const {
      data,
      newBooking,
      locationPickUp,
      isEditable,
      handleCarTypeChanged,
      handleCarTypeChangedHydra,
      time,
      isShuttle,
      cartypeSelected,
      selectedFleet,
      isHydraBooking,
      supplierId,
      farmIn,
      farmOut,
      roaming,
      corporate,
      locationDestination,
      is3rdBooking,
      isDisableWhenPrepaided,
      nameThirdParty,
      etaFareState
    } = this.props;
    let vehicleTypesRender =
      (newBooking.locationService && newBooking.locationService.vehicleType) ||
      [];
    const statusHydraFarmin = _.get(data, 'hydraInfo.status');
    const bookingBookFrom = _.get(data, 'bookFrom', '');
    let { etaFareMultiCar } = this.props;
    if (
      !checkShowFare({
        puPoints: locationPickUp ? [locationPickUp] : [],
        doPoints: locationDestination ? [locationDestination] : [],
        etaFare: etaFareState
      })
    ) {
      etaFareMultiCar = [];
    }
    if (isShuttle) {
      vehicleTypesRender = vehicleTypesRender.filter((ob) => ob.shuttle);
    } else {
      vehicleTypesRender = filterCarTypeTransport(vehicleTypesRender);
    }
    const notShowCarTypeCCWithoutRate = _.get(
      this.props.selectedFleet,
      'generalSetting.notShowCarTypeCCWithoutRate',
      false
    );
    if (notShowCarTypeCCWithoutRate) {
      vehicleTypesRender = filterCarTypeWithoutAssignRate(
        vehicleTypesRender,
        corporate,
        isShuttle
      );
    }
    // filter hydra cartype
    if (isHydraBooking) {
      const vehicleTypes = _.get(
        newBooking,
        'locationService.affiliateService.vehicleTypeHydraSplited',
        []
      );
      if (locationPickUp && vehicleTypes.length > 0) {
        let vehicleHydra = [];
        vehicleTypes.map((car) => {
          if (!supplierId || car.providerOfVhc.fleetId === supplierId) {
            vehicleHydra.push({
              ...car,
              provider: car.providerOfVhc,
            });
          }
        });
        vehicleTypesRender = vehicleHydra;
      }
    }
    return (data && newBooking.locationService && locationPickUp) ||
      CCLiteCommonFunc.isBookingStatusCompleted(data.status) ? (
      <VehicleSelection
        disabled={
          !isEditable(data) ||
          data.dispatchRideSharing ||
          (farmOut && statusHydraFarmin === HYDRA_STATUS.accepted) ||
          (farmIn &&
            (statusHydraFarmin === HYDRA_STATUS.pending ||
              statusHydraFarmin === HYDRA_STATUS.accepted ||
              bookingBookFrom === 'PWA')) ||
          roaming ||
          isDisableWhenPrepaided
        }
        is3rdBooking={is3rdBooking}
        nameThirdParty={nameThirdParty}
        isBookDetail={true}
        isHydraBooking={isHydraBooking}
        locationDestination={locationDestination}
        isStatusCompleted={CCLiteCommonFunc.isBookingStatusCompleted(
          data.status
        )}
        data={data}
        nearestDriverList={[]}
        cartypeSelected={cartypeSelected}
        handleCarTypeChangedHydra={handleCarTypeChangedHydra}
        etaFareMultiCar={etaFareMultiCar}
        vehicleTypes={vehicleTypesRender}
        selectedFleet={selectedFleet}
        handleCarTypeChanged={handleCarTypeChanged}
        time={time}
        auth={this.props.auth}
        showAllVehilceBtn={this.props.showAllVehilceBtn}
        handleShowAllVehilceBtn={this.props.handleShowAllVehilceBtn}
      />
    ) : (
      ''
    );
  }

  handleSelectDate = (e) => {
    this.setState({ showDatePicker: false }, () =>
      this.props.handleTimeChanged(e)
    );
  };

  handleChangeHour = (e, hourValue, timeMode) => {
    const {
      selectedFleet: { format24Hour },
      onChangePickUpHour,
    } = this.props;
    if (!format24Hour) {
      if (parseInt(hourValue) === 12) {
        hourValue = timeMode === I18n.t('generalSetting.AM') ? '0' : '12';
      } else {
        hourValue = moment(`${hourValue}:00 ${timeMode}`, 'HH:mm A').format(
          'HH'
        );
      }
    }
    onChangePickUpHour({
      ...e,
      target: {
        ...e.target,
        value: hourValue,
      },
    });
  };

  handleSelectHour = (e) => {
    let hourValue = e.target.value;
    const timeMode = this.timeModeRef.current
      ? this.timeModeRef.current.value
      : '';
    this.handleChangeHour(e, hourValue, timeMode);
  };

  handleSelectTimeMode = (e, time) => {
    const timeMode = e.target.value;
    let hourValue =
      timeMode === I18n.t('generalSetting.PM')
        ? time.format('HH')
        : time.format('hh');
    this.handleChangeHour(e, hourValue, timeMode);
  };

  toggleLightbox = (selectedIndex) => {
    this.setState((state) => ({
      lightboxIsOpen: !state.lightboxIsOpen,
      selectedIndex,
    }));
  };

  render() {
    const {
      data, // booking data
      // props used for pickup time
      cartypeSelected,
      //new Booking Props
      isHourly,
      isRoundTrip,
      isEditable,
      isPickupTimeEditable,
      selectedFleet: { format24Hour },
      isHasExtraDestination,
      isHydraBooking,
      roaming,
      corporate,
    } = this.props;
    const { showDatePicker, lightboxIsOpen, selectedIndex } = this.state;

    if (isHydraBooking && corporate && !corporate.isCreateRoaming) {
      return null;
    }
    const bookDetails = !!data.bookId;
    let rideSharingDisabled = false;
    let superHelper =
      CCLiteCommonFunc.isBookingStatusCompleted(data.status) && data.superHelper
        ? true
        : cartypeSelected && cartypeSelected.superHelper;
    let pickUpTimeDisabled =
      (cartypeSelected && cartypeSelected.dispatchRideSharing) || superHelper;

    if (bookDetails) {
      rideSharingDisabled = !isEditable(data) || isHydraBooking || roaming;
      pickUpTimeDisabled = !isPickupTimeEditable(data) || !data.reservation;
    }

    return (
      <div>
        <FormGroupTitle>
          {I18n.t('newbooking.Vehicle_Selection')}
        </FormGroupTitle>
        {this.renderSupplier()}
        {bookDetails
          ? this.renderCarTypeBookDetail()
          : this.renderCarTypeNewBooking()}
        {!_.isEmpty(cartypeSelected) &&
          this.renderRates(isHourly, isRoundTrip)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}

export default connect(mapStateToProps, null)(BookingInfo);
