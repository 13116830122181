import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import QuickAddNewCard from '../../../components/bookingDetail/bookComponent/QuickAddNewCard';
import { paymentGetwayDisabledNewBooking, paymentMethodNumber } from '../../../constants/commondata';
import { Validator, ValidCase } from '../../../components/validator';
import ExternalID from '../../../components/bookingDetail/bookComponent/ExternalID';
// only support Cash, Credit, Direct Invoicing
const paymentMethods = [1, 2, 5];

function CreditSelect(props) {
  if (props.isHide) {
    return (
      <ExternalID 
        value={props.externalInfo?.bookingReference || ''}
        handleChangeBookingReference={props.handleChangeBookingReference}
        is3rdBooking={false}
      />
    );
  }

  const handleChange = (e) => {
    const selectedCredit = _.find(
      props.paymentType === 4 ? props?.corporateInfo?.credits : props.credits,
      (c) => c.localToken === e.target.value
    );
    props.onChange(selectedCredit, props.paymentType === paymentMethodNumber.corporateCard);
  };

  const handleAddCardSuccess = (cardAdded) => {
    const isCorpType = props.paymentType === paymentMethodNumber.corporateCard
    if(isCorpType) {
      props.intercityBookingActions.updateCommonData({
        corporateInfo: {
          ...props?.corporateInfo,
          credits: props?.corporateInfo?.credits?.concat(cardAdded)
        }
      })
    } else {
      props.intercityBookingActions.updateCommonData({
        credits: props.credits.concat(cardAdded)
      })
    }
    props.onChange(cardAdded, isCorpType);
  }

  // const gatewayConfig = props.selectedFleet.creditConfig.multiGateway
  //   ? _.get(newBooking, 'locationService.paymentGateways', {})
  //   : _.get(selectedFleet, 'creditConfig.configGateway', {});
  const gatewayConfig = _.get(props.selectedFleet, 'creditConfig.configGateway', {});
  const supportWeb = gatewayConfig.supportWeb || false;
  const isDisableAddCard = paymentGetwayDisabledNewBooking.includes(gatewayConfig.gateway);
  const newCustomer = {
    phone: props.psgInfo.phone
      .replace(new RegExp(' ', 'g'), '')
      .replace(new RegExp('-', 'g'), ''),
    firstName: props.psgInfo.firstName || '',
    lastName: props.psgInfo.lastName || '',
    email: props.psgInfo.email || ''
  };
  const localCredits = props.paymentType === 4
    ? props?.corporateInfo?.credits || []
    : props.credits.filter(credit => credit.gateway === gatewayConfig.gateway )

  const creditInfo = props.paymentType === paymentMethodNumber.corporateCard
    ? props?.corporateInfo?.creditInfo || {}
    : props?.psgInfo?.creditInfo || {};
  return (
    <FormGroup
      className={`mr-t-10 ${
        !props.isSubmited ? null : props.valid.payment_card === false ? 'error' : null
      }`}
    >
      <FormControl
        as="select"
        placeholder="select"
        className={'form-custom mb'}
        onChange={handleChange}
        value={creditInfo?.localToken || ''}
        style={{ marginBottom: '10px' }}
        disabled={props.disabled}
      >
        <option value={0}>{I18n.t(`newbooking.Choose_a_stored_card`)}</option>
        {localCredits?.map((cre) => {
          return (
            <option key={cre.localToken} value={cre.localToken}>
              {cre.cardMask}
            </option>
          );
        })}
      </FormControl>
      <Validator
        callback={props.validatorCallback}
        id="payment_card"
      >
        <ValidCase
          valid={creditInfo}
          message={I18n.t('messages.commonMessages.Required_field')}
          hide={!props.isSubmited}
        />
      </Validator>
      <ExternalID 
        value={props.externalInfo?.bookingReference || ''}
        handleChangeBookingReference={props.handleChangeBookingReference}
        is3rdBooking={false}
      />
      {!isDisableAddCard && supportWeb && !props.disabled ? (
        <QuickAddNewCard
          corpId={props?.corporateInfo?._id}
          supportWeb={supportWeb}
          customer={props.psgInfo}
          handleAddCardSuccess={handleAddCardSuccess}
          gateWay={gatewayConfig}
          newCustomer={newCustomer}
          handleAddNewCustomerSuccess={props.onAddNewCustomer}
        />
      ) : null}
    </FormGroup>
  );
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    credits: state.intercityBooking.common.credits,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditSelect);
