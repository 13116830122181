import React from 'react';
import _ from 'lodash';
import { FormControl, Tab, Tabs } from 'react-bootstrap';
import TipDefaultValues from './TipDefaultValues';
import { I18n } from 'react-redux-i18n';

const ObjectId = (rnd = (r16) => Math.floor(r16).toString(16)) =>
  rnd(Date.now() / 1000) +
  ' '.repeat(16).replace(/./g, () => rnd(Math.random() * 16));

const initValues = _.map([0, 1, 2], () => ({
  _id: ObjectId(),
  values: [],
}));

export default class TipDefaulValueTabs extends React.PureComponent {
  handleChangeDefaultValues = (value, currencyISO, valueId) => {
    const {
      tipDefaultValues,
      tipDefaultType = 'amount',
      onChange,
    } = this.props;
    let defaultValues =
      tipDefaultValues && tipDefaultValues.length
        ? tipDefaultValues
        : initValues;
    defaultValues = _.cloneDeep(defaultValues);
    _.forEach(defaultValues, (val) => {
      if (val._id === valueId) {
        if (tipDefaultType === 'amount') {
          const curIndex = (val.values || []).findIndex(
            (o) => o.currencyISO === currencyISO
          );
          if (curIndex !== -1) {
            val.values[curIndex].value = value;
          } else {
            val.values.push({ value, currencyISO });
          }
        } else {
          if (!val?.values?.length) {
            val['values'] = [{
              currencyISO,
              value: 0
            }];
          }
          val['valuePercent'] = value;
        }
      }
    });
    onChange(defaultValues);
  };

  render() {
    const {
      currencies,
      tipDefaultValues,
      tipDefaultType = 'amount',
      editable,
    } = this.props;
    const defaultValues =
      tipDefaultValues && tipDefaultValues.length
        ? tipDefaultValues
        : initValues;
    if (currencies.length === 1) {
      return (
        <>
          <FormControl
            as="select"
            value={tipDefaultType}
            placeholder="select"
            className="form-custom"
            onChange={(e) => this.props.handleChangeTipDefaultType(e.target.value)}
          >
            <option value={'amount'}>{I18n.t(`referral.amount`)}</option>
            <option value={'percent'}>{I18n.t(`referral.percent`)}</option>
          </FormControl>
          <TipDefaultValues
            {...currencies[0]}
            defaultValues={defaultValues}
            tipDefaultType={tipDefaultType}
            editable={editable}
            onChange={this.handleChangeDefaultValues}
            handleChangeTipDefaultType={this.props.handleChangeTipDefaultType}
          />
        </>
      );
    }
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        defaultActiveKey={currencies[0].iso}
        animation={false}
        onSelect={this.tabSelectHandle}
      >
        {currencies.map((currency) => {
          return (
            <Tab
              className="currencies-tab-item"
              eventKey={currency.iso}
              title={currency.iso}
              key={currency.iso}
            >
              <TipDefaultValues
                {...currency}
                defaultValues={defaultValues}
                editable={editable}
                onChange={this.handleChangeDefaultValues}
              />
            </Tab>
          );
        })}
      </Tabs>
    );
  }
}
