import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import IntlTelInputApp from "../../../components/intlTelInputCustome/IntlTelInputApp";
import "../settings.scss";
import {
  Form,
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Modal,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import * as userActions from "../../../actions/userActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { CCLiteCommonFunc, Validation, handleResultExport } from "../../../utils/commonFunctions";
import { Validator, ValidCase } from "../../../components/validator";
import ExportComponent from "../../../components/ExportComponent";
import { trimPhoneNumber } from "../../../utils/commonFunctions";
import CcCheckbox from "../../../components/ccCheckbox/CcCheckbox";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import TableActions from "../../../components/table/tableAction/TableActions";
import { Columns } from "./tableHeaderData";
import ReactDOM from "react-dom";
import Confirm from "../../../components/confirm/Confirm";
import _ from "lodash";
import { BsSearch } from "react-icons/bs";
const CONFIRM_EXPORT_ID = 1;
class User extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
      phoneValidation: true,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      editable: false,
      showConfirm: false,
      sortType: -1,
      str: "",
      valid: [],
      isSubmited: false,
      showConfirmResetPassword: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        page: 0,
        total: 0
      },
      showPercent: false,
      exportPercent: 0,
      confirm: false
    };
    this.handleResultExport = handleResultExport.bind(this);
  }

  componentDidMount() {
    this.updateUsersList();
  }

  updateUsersList = (activePage, limit) => {
    this.props.userActions
      .getUsers({
        limit: limit || this.state.footerData.limit,
        page:
          activePage || activePage === 0
            ? activePage
            : this.state.footerData.page,
        query: {
          fleetId: this.props.auth.selectedFleet.fleetId,
          search: this.state.str
        }
      })
      .then(data => {
        if (data.ok && data.res) {
          let { page, total, limit } = data.res;
          this.setState({
            users: data.res,
            footerData: { page, total, limit }
          });
        }
      });
    this.props.userActions.getUserRoles(this.props.auth.selectedFleet.fleetId);
  };

  saveDialogForm = e => {
    e.preventDefault();
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.dialogData.userId) {
      let objectUser = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        userId: this.state.dialogData.userId,
        userName: this.state.dialogData.userName && this.state.dialogData.userName.trim(),
        firstName: this.state.dialogData.firstName,
        lastName: this.state.dialogData.lastName,
        agentId: this.state.dialogData.agentId,
        address: this.state.dialogData.address,
        phone: trimPhoneNumber(this.state.dialogData.phone),
        email: this.state.dialogData.email,
        roleName: this.state.dialogData.roleName,
        isActive: this.state.dialogData.isActive
      };
      this.props.userActions.updateUser(objectUser).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("user.Update_user_fail"));
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("user.Update_user_success")
          );
          this.closeDialogForm();
          this.updateUsersList();
        }
      });
    } else {
      let userData = Object.assign({}, this.state.dialogData)
      userData.phone = trimPhoneNumber(this.state.dialogData.phone)
      this.props.userActions.addUser(userData).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("user.Create_user_fail"));
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("user.Create_user_success").format(
              this.state.dialogData.userName,
              this.state.dialogData.email
            )
          );
          this.closeDialogForm();
          this.updateUsersList();
        }
      });
    }
  };
  handleUsernameChange = e => {
    this.state.dialogData.userName = e.target.value;
    if (this.state.dialogData.userName) {
      this.state.dialogData.userName = this.state.dialogData.userName.replace(
        /\s/g,
        ""
      );
    }
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleFirstnameChange = e => {
    this.state.dialogData.firstName = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleLastNameChange = e => {
    this.state.dialogData.lastName = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleAgentIdChange = e => {
    this.state.dialogData.agentId = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handlePhoneChange(status, value, countryData, number, id) {
    this.state.dialogData.phone = number;
    this.setState({
      dialogData: this.state.dialogData,
      dialogChanged: true,
      phoneValidation: status
    });
  }

  handleEmailChange = e => {
    this.state.dialogData.email = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleAddressChange = e => {
    this.state.dialogData.address = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleRoleNameChange = e => {
    this.state.dialogData.roleName = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };
  handleActiveChange = e => {
    this.state.dialogData.isActive = e.target.checked;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleAddButtonClick = () => {
    let objectUser = {
      userName: "",
      firstName: "",
      lastName: "",
      agentId: "",
      address: "",
      phone: "",
      email: "",
      roleName: "",
      isActive: true
    };
    this.setState({ showDialog: true, dialogData: objectUser, editable: true });
  };

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      isSubmited: false,
      showConfirmResetPassword: false,
      editable: false
    });
  };

  confirmResetPassword = () => {
    if (this.state.dialogData) {
      this.props.userActions
        .resetPasswordUser({
          fleetId: this.props.auth.selectedFleet.fleetId,
          userId: this.state.dialogData.userId
        })
        .then(data => {
          if (data.ok && data.res) {
            this.context.notification(
              "success",
              I18n.t("user.Reset_password_success")
            );
            this.setState({ showConfirmResetPassword: false });
          } else {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        });
    }
  };
  closeConfirmResetPasswordialog = () => {
    this.setState({ showConfirmResetPassword: false });
  };
  handleResetPasswordClick = () => {
    this.setState({ showConfirmResetPassword: true });
  };
  handleMenuClick = (key, user) => {
    switch (key) {
      case "View": {
        this.props.userActions
          .getOneUser({
            userId: user.userId,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: false
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("user.Load_user_fail")
                );
              }
            }
          });
        break;
      }
      case "Edit": {
        this.props.userActions
          .getOneUser({
            userId: user.userId,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: true
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("user.Load_user_fail")
                );
              }
            }
          });
        break;
      }
      case "Status": {
        this.props.userActions
          .changeUserStatus({
            fleetId: this.props.auth.selectedFleet.fleetId,
            userId: user.userId,
            isActive: !user.isActive
          })
          .then(data => {
            if (data.ok) {
              this.updateUsersList();
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("user.Update_user_status_fail")
                );
              }
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: user });
        break;
      }
    }
  };

  handleConfirmButtonClick = (id) => {
    switch (id) {
      case CONFIRM_EXPORT_ID:
        {}
        break;
    }
    this.handleConfirmCloseClick();
  }

  prepareParamExport = () => {
    let query = { fleetId: this.props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
    }
    let sort = { createdDate: this.state.sortType };
    if (this.state.sortName) {
      sort = { [this.state.sortName]: this.state.sortType };
    }
    return {
      query: query,
      sort: sort,
      total: _.get(this.state.footerData, 'total', 0),
    }
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  }


  handlePaginationSelect = eventKey => {
    this.updateUsersList(eventKey);
  };

  handleNumItemsPerPageChange = limit => {
    this.updateUsersList(0, limit);
  };

  handleSearchChange = e => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = e => {
    if (e.key === "Enter") {
      this.updateUsersList();
    }
  };

  confirmDeleteUser = () => {
    this.props.userActions
      .deleteUser({
        fleetId: this.props.auth.selectedFleet.fleetId,
        userId: this.state.dialogData.userId
      })
      .then(data => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateUsersList();
          this.context.notification(
            "success",
            I18n.t("user.Delete_user_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("user.Delete_user_fail"));
          }
        }
      });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], Columns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "userName":
          col.customCell = user => {
            return (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", user);
                }}
              >
                {user.userName}
              </a>
            );
          };
          break;

        case "phone":
          col.customCell = user => {
            return <span>{user.phone}</span>;
          };
          break;

        case "userStatus":
          col.customCell = user => {
            let statusStr, statusClass;
            if (user && user.isActive) {
              statusStr = <Translate value="user.Active" />;
              statusClass = "Active";
            } else {
              if (user && !user.isActivate) {
                statusStr = <Translate value="user.Inactive" />;
                statusClass = "Inactive";
              }
            }
            return (
              <div className={statusClass}>
                {!this.props.permissions || this.props.permissions.actions ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick("Status", user);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                    statusStr
                  )}
              </div>
            );
          };
          break;

        case "actions":
          col.customCell = (user, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "user.Edit",
                  eventKey: "Edit"
                },
                {
                  label: user.isActive ? "user.Deactivate" : "user.Activate",
                  eventKey: "Status"
                }
              ];

              if (!user.isActive) {
                actionItems.push({
                  label: "user.Delete",
                  eventKey: "Delete"
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={user}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, user);
                  }}
                  totalRow={this.state.users.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", user);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="user.View" />
                </a>
              );
            }
          };
          break;
      }
    });
    return tableColumns;
  };

  render() {
    const bodyData =
      this.state.users && this.state.users.list ? this.state.users.list : [];
    const { permissions = null, auth: { user } = {} } = this.props || {};
    const { actions = false } = permissions || {};
    const exportPermission = user.isAdmin ? true : permissions.export;
    return (
      <div className="content">
        {this.state.showConfirm && actions ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="user.DELETE_USER" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="user.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteUser}
              >
                <Translate value="user.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="user.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        {this.state.showConfirmResetPassword && actions ? (
          <Modal onHide={this.closeConfirmResetPasswordialog} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="user.RESET_PASSWORD" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeConfirmResetPasswordialog}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="user.CONFIRM_RESET_PASSWORD" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmResetPassword}
              >
                <Translate value="user.Yes" />
              </Button>
              <Button
                className={"btn-cancel"}
                onClick={this.closeConfirmResetPasswordialog}
              >
                <Translate value="user.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        {this.state.showDialog ? (
          <Modal show={true} backdrop={true} onHide={this.closeDialogForm}>
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.dialogData.userId ? (
                    this.state.editable ? (
                      <Translate value="user.EDIT_USER" />
                    ) : (
                        <Translate value="user.DETAIL_USER" />
                      )
                  ) : (
                      <Translate value="user.ADD_USER" />
                    )}
                </Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closeDialogForm}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={6} xs={12} className={"pl0"}>
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.userName
                            ? null
                            : "error"
                      }
                    >
                      <Form.Label>
                        <Translate value="user.Username" />{" "}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleUsernameChange}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.userName
                            : ""
                        }
                        placeholder={I18n.t("user.Username")}
                        disabled={!!this.state.dialogData.userId}
                      />
                      <Validator id="userName" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.dialogData.userName
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.firstName
                            ? null
                            : "error"
                      }
                    >
                      <Form.Label>
                        <Translate value="user.First_name" />{" "}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleFirstnameChange}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.firstName
                            : ""
                        }
                        placeholder={I18n.t("user.First_name")}
                        disabled={!this.state.editable}
                      />
                      <Validator id="firstName" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.dialogData.firstName
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup>
                      <Form.Label>
                        <Translate value="user.Last_name" />
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleLastNameChange}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.lastName
                            : ""
                        }
                        placeholder={I18n.t("user.Last_name")}
                        disabled={!this.state.editable}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Form.Label>
                        <Translate value="user.AgendId" />
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleAgentIdChange}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.agentId
                            : ""
                        }
                        placeholder={I18n.t("user.AgendId")}
                        disabled={!this.state.editable}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6} xs={12} className={"pr0"}>
                    <FormGroup>
                      <Form.Label>
                        <Translate value="user.Phone_number" />
                      </Form.Label>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.phone
                              ? null
                              : "error"
                        }
                      >
                        <IntlTelInputApp
                          css={["intl-tel-input", "form-control form-custom"]}
                          utilsScript={"libphonenumber.js"}
                          value={this.state.dialogData.phone}
                          onPhoneNumberChange={(
                            status,
                            value,
                            countryData,
                            number,
                            id
                          ) => {
                            this.handlePhoneChange(
                              status,
                              value,
                              countryData,
                              number,
                              id
                            );
                          }}
                          disabled={!this.state.editable}
                        />
                        <Validator id="phone" callback={this.ValidatorCallback}>
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={this.state.phoneValidation}
                            message={I18n.t("mDispatcher.ERROR_INPUT_VALIDPHONE")}
                          />
                        </Validator>
                      </FormGroup>
                    </FormGroup>
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.email
                            ? null
                            : "error"
                      }
                    >
                      <Form.Label>
                        <Translate value="user.Email_address" />{" "}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleEmailChange}
                        value={
                          this.state.dialogData ? this.state.dialogData.email : ""
                        }
                        placeholder={I18n.t("user.Email_address")}
                        disabled={!this.state.editable}
                      />
                      <Validator id="email" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(this.state.dialogData.email)
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={Validation.validateEmail(
                            this.state.dialogData.email
                          )}
                          message={I18n.t(
                            "messages.commonMessages.invalid_email_format"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup>
                      <Form.Label>
                        <Translate value="user.Address" />
                      </Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={this.handleAddressChange}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.address
                            : ""
                        }
                        placeholder={I18n.t("user.Address")}
                        disabled={!this.state.editable}
                      />
                    </FormGroup>
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.roleName
                            ? null
                            : "error"
                      }
                    >
                      <Form.Label>
                        <Translate value="user.Permission" />{" "}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        disabled={!this.state.editable}
                        as="select"
                        className="form-custom"
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.roleName
                            : ""
                        }
                        onChange={this.handleRoleNameChange}
                      >
                        <option value="">
                          {I18n.t("user.Select_permission")}
                        </option>
                        {this.props.users && this.props.users.roles
                          ? this.props.users.roles.map(role => (
                            <option key={role.name} value={role.name}>
                              {role.name}
                            </option>
                          ))
                          : null}
                      </FormControl>
                      <Validator id="roleName" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.dialogData.roleName
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  </Col>
                </Row>
                <CcCheckbox
                  disabled={!this.state.editable}
                  onChange={this.handleActiveChange}
                  checked={this.state.dialogData.isActive}
                  text={I18n.t("driver.Active")}
                />
                <Form.Label>
                  (<span className="require">*</span>
                  ): <Translate value="user.Required_fields" />
                </Form.Label>
              </Modal.Body>
              <Modal.Footer>
                {!this.props.permissions || this.props.permissions.actions ? (
                  this.state.editable ? (
                    <Button
                      className="btn-save mr-md"
                      onClick={this.saveDialogForm}
                      disabled={!this.state.dialogChanged}
                    >
                      <Translate value="user.Save" />
                    </Button>
                  ) : (
                      <Button
                        className={"btn-save mr-md"}
                        onClick={e => {
                          this.handleMenuClick("changeStatus", e);
                        }}
                      >
                        <Translate value="user.Edit" />
                      </Button>
                    )
                ) : null}
                <Button
                  className={
                    "btn-cancel" +
                    `${
                    this.state.dialogData.userId && !this.state.editable
                      ? " mr-md"
                      : ""
                    }`
                  }
                  onClick={this.closeDialogForm}
                >
                  <Translate value="user.Cancel" />
                </Button>
                {!this.props.permissions || this.props.permissions.actions ? (
                  this.state.dialogData.userId && !this.state.editable ? (
                    <Button
                      className={"btn-reset"}
                      onClick={this.handleResetPasswordClick}
                    >
                      <Translate value="user.Reset_password" />
                    </Button>
                  ) : null
                ) : null}
              </Modal.Footer>
            </Form>
          </Modal>
        ) : (
            ""
          )}

        <ButtonToolbar
          className="text-center header-button-group"
          ref={node => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t("General.search")}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button
                className="btn-header text-add-header"
                onClick={this.handleAddButtonClick}
              >
                {" "}

                <Translate value="user.Add" />
              </Button>
            ) : (
                null
              )}
          </ButtonGroup>
          <ButtonGroup className="group-right">
            {(permissions && exportPermission) && (
              <ExportComponent 
                prepareParamExport={this.prepareParamExport}
                urlExport={this.props.userActions.exportUserToExcel}
                user={user}
              />
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

User.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User);
