import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import { creditBalance } from "../../actions/driverAction";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import {
  FormGroup,
  Alert,
  InputGroup,
  Form,
  Modal,
  FormControl,
  Button,
  DropdownButton,
} from "react-bootstrap";
import { CountryDropdown } from "react-country-region-selector";
import FormGroupTitle from "../../components/formGroupTitile/FormGroupTitle";
class Balance extends React.Component {
  constructor() {
    super();
    this.state = {
      balance: "",
      operator: "true",
      amount: "",
      newBalance: "",
      reason: "",
      valid: {},
      isSubmitted: false
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleBalanceChange = this.handleBalanceChange.bind(this);
    this.handleOperatorChange = this.handleOperatorChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.creditBalances &&
      nextProps.creditBalances &&
      nextProps.userId
    ) {
      this.setState({
        isShow: nextProps.isShow,
        creditBalances: nextProps.creditBalances,
        currencies: nextProps.currencies,
        userId: nextProps.userId,
        balance:
          (nextProps.currencies &&
            nextProps.currencies.length &&
            nextProps.currencies[0] &&
            nextProps.currencies[0].iso) ||
          ""
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  handleBalanceChange(e) {
    this.setState({ balance: e.target.value });
  }

  handleOperatorChange(e) {
    this.setState({ operator: e.target.value === "true" });
  }

  handleAmountChange(e) {
    this.setState({ amount: e.target.value });
  }

  handleReasonChange(e) {
    this.setState({ reason: e.target.value });
  }

  handleSaveClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    let chosenCreditBalance = this.state.creditBalances.filter(
      x => x.currencyISO == this.state.balance
    );
    let chosenCreditValue = 0.0;
    if (chosenCreditBalance[0]) {
      chosenCreditValue = chosenCreditBalance[0].value;
    }
    let amount = parseFloat(this.state.amount);
    if (!this.state.operator) {
      amount = 0 - amount;
    }
    this.props
      .creditBalance({
        fleetId: this.props.fleetId,
        userId: this.state.userId,
        amount: amount,
        currencyISO: this.state.balance,
        reason: this.state.reason,
        currentAmount: chosenCreditValue
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res.returnCode == 200) {
          this.props.handleCreditBalanceSuccess(data.res.response);
          this.setState({
            balance: "",
            operator: "true",
            amount: "",
            newBalance: "",
            reason: "",
            valid: {},
            isSubmitted: false
          });
          this.context.notification(
            "success",
            I18n.t("driver.INFO_ADD_BALANCE_SUCCESS")
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("messages.credits." + data.res.returnCode)
          );
        }
      });
  }

  handleCancelClick() {
    this.props.handleConfirmCloseClick();
    this.setState({
      balance: "",
      operator: "true",
      amount: "",
      newBalance: "",
      reason: "",
      valid: {},
      isSubmitted: false
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    if (this.state.isShow) {
      if (this.state.currencies && this.state.creditBalances) {
        const { valid, isSubmitted } = this.state;
        let chosenCreditBalance = this.state.creditBalances.filter(
          x => x.currencyISO == this.state.balance
        );
        let chosenCreditValue = 0.0;
        if (chosenCreditBalance[0]) {
          chosenCreditValue = chosenCreditBalance[0].value;
        }
        let operatorValue = null;
        let amount = Validation.isNumber(this.state.amount)
          ? parseFloat(this.state.amount)
          : 0.0;
        if (!Validation.isStringEmpty(this.state.balance)) {
          if (this.state.operator) {
            operatorValue = (amount + parseFloat(chosenCreditValue)).toFixed(2);
          } else {
            operatorValue = (parseFloat(chosenCreditValue) - amount).toFixed(2);
          }
        }
        return (
          <Modal
            show={true}
            onHide={this.props.closeDialog}
            backdrop="static"
            size="sm"
            aria-labelledby="contained-modal-title-sm"
            dialogClassName="change-balance-dialog"
            className="confirm"
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-sm">
                <Translate value="driver.CREDIT_DEPOSIT" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCancelClick}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className="clearfix">
              <FormGroup
                className={
                  isSubmitted
                    ? valid.balance === false
                      ? "error"
                      : null
                    : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Balance" />{" "}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="select"
                  value={this.state.balance}
                  onChange={this.handleBalanceChange}
                  className="form-custom"
                >
                  <option key={0} value={""}>
                    {I18n.t("driver.Select_balance")}
                  </option>
                  {this.state.currencies.map(cp => {
                    let creditBalance = this.state.creditBalances.filter(
                      x => x.currencyISO == cp.iso
                    );
                    let credit = { currencyISO: cp.iso, value: "0.00" };
                    if (creditBalance[0]) {
                      credit = creditBalance[0];
                      try {
                        credit.value = credit.value.toFixed(2);
                      } catch (eer) {}
                    }
                    return (
                      <option key={cp.iso} value={cp.iso}>
                        {credit.currencyISO} : {credit.value}
                      </option>
                    );
                  })}
                </FormControl>
                <Validator id="balance" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.balance)}
                    message={I18n.t("driver.ERROR_INPUT_BALANCE")}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  isSubmitted ? (valid.amount === false ? "error" : null) : null
                }
              >
                <Form.Label>
                  <Translate value="driver.Adjust_amount" />{" "}
                  <span className="require"> *</span>
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup className="single-addon-left">
                    <FormControl
                      style={{ width: "85%" }}
                      type="text"
                      value={this.state.amount}
                      onChange={this.handleAmountChange}
                    />
                    <FormControl
                      as="select"
                      value={this.state.operator}
                      style={{ width: "15%" }}
                      onChange={e => {
                        this.handleOperatorChange(e);
                      }}
                      className="form-custom input-group-addon-custom"
                    >
                      <option key={0} value={"true"}>
                        +
                      </option>
                      <option key={1} value={"false"}>
                        -
                      </option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator id="amount" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.amount)}
                    message={I18n.t("driver.ERROR_INPUT_AMOUNT")}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      Validation.isNumber(this.state.amount) &&
                      parseFloat(this.state.amount) > 0
                    }
                    message={I18n.t("driver.ERROR_INPUT_VALID_AMOUNT")}
                  />
                </Validator>
              </FormGroup>
              {operatorValue ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="driver.New_balance" />
                  </Form.Label>
                  <FormGroup>
                    <span className="text-active">
                      {this.state.balance} : {operatorValue}
                    </span>
                  </FormGroup>
                </FormGroup>
              ) : null}
              <FormGroup
                className={`mb0 ${
                  isSubmitted ? (valid.reason === false ? "error" : null) : null
                }`}
              >
                <Form.Label>
                  <Translate value="driver.Enter_reason" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  as="textarea"
                  value={this.state.reason}
                  onChange={this.handleReasonChange}
                  className="form-custom"
                />
                <Validator id="reason" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(this.state.reason)}
                    message={I18n.t("driver.ERROR_INPUT_REASON")}
                  />
                </Validator>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <div className="fill text-center">
                <Button
                  className="btn-save mr-md"
                  onClick={this.handleSaveClick}
                >
                  <Translate value="driver.Save" />
                </Button>
                <Button className="btn-cancel" onClick={this.handleCancelClick}>
                  <Translate value="driver.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}
Balance.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    fleetId: state.auth.selectedFleet.fleetId
  };
}
function mapDispatchToProps(dispatch) {
  return {
    creditBalance: options => {
      return dispatch(creditBalance(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Balance);
