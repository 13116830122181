import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    Modal,
    Image,
    ButtonToolbar
} from 'react-bootstrap';
import _ from 'lodash';

import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { PrizeOrderColumns } from './tableHeaderColumn';
import { RankImageColumns } from './tableHeaderColumn';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import UploadFile from '../../../../components/upload/upload';
import './quest.scss';

import {
    MetricType
} from '../../../../constants/commondata';
import { BsPlus } from 'react-icons/bs';


function PrizesModal(props) {
    const {
        formName,
        data,
        disable,
        showDialogModal,
        closeFormModal,
        handleUpdateData,
        uploadService,
        selectedFleet,
        loadingBarActions,
        handleAlertNotification
    } = props;
    const [PrizeOrder, setPrizeOrder] = useState([]);

    useEffect(() => {
        if (data[formName]) {
            if (data[formName].length > 0) {
                setPrizeOrder(data[formName]);
            } else {
                if (formName === 'rankImages') {
                    setPrizeOrder([
                        {
                            from: 0,
                            to: 1,
                            image: ''
                        },
                        {
                            from: 2,
                            to: Number.MAX_SAFE_INTEGER,
                            image: ''
                        }
                    ])
                };
            };
        };
    }, [data]);

    const handleInputChange = (index, e) => {
        let listItem = _.cloneDeep(PrizeOrder);
        if (index >= 0) listItem[index].reward = e.target.value;
        setPrizeOrder(listItem);
    };

    const handleFromInputChange = (index, e) => {
        let listItem = _.cloneDeep(PrizeOrder);
        if (index >= 0) listItem[index].from = parseInt(e.target.value);
        setPrizeOrder(listItem);
    };

    const handleToInputChange = (index, e) => {
        let listItem = _.cloneDeep(PrizeOrder);
        let value = e.target.value.toLowerCase() === "infinity" ? Number.MAX_SAFE_INTEGER : e.target.value;
        if (index >= 0) {
            listItem[index].to = value;
            if (listItem[index + 1]) {
                listItem[index + 1].from = parseInt(value) + 1;
            } else {
                listItem.push({
                    from: parseInt(value) + 1,
                    to: Number.MAX_SAFE_INTEGER,
                    image: ''
                });
            }
        };
        setPrizeOrder(listItem);
    };

    const handleImageChange = (data, error, index) => {
        let listItem = _.cloneDeep(PrizeOrder);

        if (data) {
            if (index >= 0) {
                listItem[index].image = data.file;
                listItem[index].file = data.file;
            }
            setPrizeOrder(listItem);
        } else {
            if (error) {
                // this.context.notification('error', I18n.t('driver.' + error));
                if (index >= 0) listItem[index].image = null;
                setPrizeOrder(listItem);
            };
        }
    };

    const handleRemoveImage = (index) => {
        let listItem = _.cloneDeep(PrizeOrder);
        if (index >= 0) listItem[index].image = '';
        setPrizeOrder(listItem);
    };

    const handleAddPrizeButton = () => {
        let listItem = _.cloneDeep(PrizeOrder);
        if (formName === 'rankImages') {
            if (listItem.length > 0) {
                let itemLast = _.last(listItem);
                if (parseFloat(itemLast.to) === Number.MAX_SAFE_INTEGER) {
                    // Re-set value to
                    listItem[listItem.length - 1].to = listItem[listItem.length - 1].from;
                    // Set value Infinity for New item
                    listItem.push({
                        from: itemLast.from + 1,
                        to: Number.MAX_SAFE_INTEGER,
                        image: ''
                    });
                } else {
                    listItem.push({
                        from: parseFloat(itemLast.to) + 1,
                        to: Number.MAX_SAFE_INTEGER,
                        image: ''
                    });
                };

            } else {
                listItem.push({
                    from: 1,
                    to: 1,
                    image: ''
                });
            };
        } else {
            listItem.push({
                reward: '',
                image: ''
            });
        };
        setPrizeOrder(listItem);
    };

    const handleRemoveItemClick = (item, index) => {
        let listItem = _.cloneDeep(PrizeOrder);
        if (listItem[index + 1]) {
            listItem[index + 1].from = item.from;
        };
        listItem.splice(index, 1);
        setPrizeOrder(listItem);
    };

    const handleUploadFile = img => {
        if (img.file) {
            const form = new FormData();
            form.append('photo', img.file);
            form.append('fleetId', selectedFleet.fleetId);
            form.append('module', 'prize');
            return uploadService.uploadPhoto(form).then(res => {
                if (!res.error) {
                    return Promise.resolve(_.get(res, 'res.Location'));
                }
                handleAlertNotification(formName, 'errors.upload_photo_fail');
                return Promise.resolve({ error: res.error });
            });
        }
        return Promise.resolve(img.image);
    };


    const handleSelectedSave = () => {
        let listItem = _.cloneDeep(PrizeOrder);
        let promises = [];
        let newData = [];
        loadingBarActions.showLoadingSpiner();
        listItem.forEach(img => {
            if (img.file && (img.reward !== '' || img.from !== '')) {
                promises.push(
                    handleUploadFile(img).then(imageUpload => {
                        if (imageUpload && imageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        img.image = imageUpload;
                        img.file = '';
                    })
                );
            };
        });

        Promise.all(promises).then(() => {
            listItem.forEach(e => {
                if (
                    e.reward !== '' &&
                    formName !== 'rankImages'
                ) {
                    newData.push({
                        reward: e.reward,
                        image: e.image
                    })
                };
                if (
                    formName === 'rankImages' &&
                    e.from >= 0
                ) {
                    newData.push({
                        from: parseFloat(e.from),
                        to: parseFloat(e.to),
                        image: e.image
                    })
                };
            });
            loadingBarActions.hideLoadingSpiner();
            const items = formName === 'rankImages' ? newData.filter(e => !e.to || e.from > e.to || e.image === '') : newData.filter(e => e.image === '');
            if (items.length > 0) {
                handleAlertNotification(formName, items);
            } else {
                handleUpdateData(formName, newData);
            };
        });
    };

    const getTableColumns = () => {
        let tableColumns = Object.assign([], formName === 'rankImages' ? RankImageColumns : PrizeOrderColumns);
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'reward':
                    col.customCell = (data, index) => {
                        return (
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleInputChange(index, e)}
                                value={data.reward
                                    ? data.reward
                                    : ''}
                                placeholder={I18n.t('quest_event.reward')}
                            // disabled={disable}
                            />
                        );
                    };
                    break;
                case 'from':
                    col.customCell = (data, index) => {
                        let value = data.from === Number.MAX_SAFE_INTEGER ? Infinity : data.from;
                        return (
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleFromInputChange(index, e)}
                                value={value}
                                placeholder={I18n.t('quest_event.from')}
                                disabled={true}
                            />
                        );
                    };
                    break;
                case 'to':
                    col.customCell = (data, index) => {
                        let value = data.to === Number.MAX_SAFE_INTEGER ? Infinity : data.to;
                        return (
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={e => handleToInputChange(index, e)}
                                value={data.to
                                    ? value
                                    : ''}
                                placeholder={I18n.t('quest_event.to')}
                            // disabled={disable}
                            />
                        );
                    };
                    break;
                case 'image':
                    col.customCell = (data, index) => {
                        let imageName = '';
                        if (data.image && data.image.length > 0) {
                            let splitUrl = data.image.split('/');
                            imageName = splitUrl[splitUrl.length - 1];
                        };
                        let existFile = !!imageName;
                        return (
                            <UploadFile
                                id={data.reward + '.' + index}
                                name={data.reward + '.' + index}
                                // disabled={disable}
                                onChange={(data, error) =>
                                    handleImageChange(data, error, index)
                                }
                                existFile={
                                    existFile
                                        ? {
                                            fileName: imageName,
                                            onRemove: () =>
                                                handleRemoveImage(index)
                                        }
                                        : null
                                }
                                accepts="image/*"
                                onlyImage
                            />
                        );
                    };
                    break;
                case 'actions':
                    col.customCell = (item, index) => {
                        return (
                            <div className="prize-action">
                                {formName === 'rankImages' ? (
                                    <div>
                                        {item.from !== 1 && item.to < 9007199254740991 ? (
                                            <i
                                                className="fa fa-trash fa-2x"
                                                onClick={e => {
                                                    handleRemoveItemClick(item, index);
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                ) : (
                                        <div>
                                            <i
                                                className="fa fa-trash fa-2x"
                                                onClick={e => {
                                                    handleRemoveItemClick(item, index);
                                                }}
                                            />
                                        </div>
                                    )}

                            </div>
                        );
                    };
                    break;
                default:
                    break;
            }
        });
        return tableColumns;
    };

    return (
        <Modal
            onHide={closeFormModal}
            show={showDialogModal}
            dialogClassName="custom-modal quests-list table-header"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Translate value={`quest_event.${formName}`} />
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={closeFormModal}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <ButtonToolbar className="text-center header-button-group">
                    <Button
                        className={"btn-header text-add-header ml0"}
                        onClick={handleAddPrizeButton}
                    >
                        {" "}
                        <BsPlus />
                        <Translate value="quest_event.Add_Prize" />
                    </Button>
                </ButtonToolbar>
                <div className="gridViewTable">
                    <StickyTable
                        columns={getTableColumns()}
                        bodyData={PrizeOrder}
                        rowHeight={50}
                        getTableHeight={() => 500}
                        noPagination={true}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-cancel" onClick={closeFormModal}>
                    <Translate value="quest_event.Cancel" />
                </Button>
                <Button className="btn-save mr-md" onClick={handleSelectedSave}>
                    <Translate value="quest_event.ok" />
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default PrizesModal