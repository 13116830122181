import ReportUtils from './../base/utils'

export const fields = [

  {
    key: "payoutId",
    label: "report.result.payoutToMerchant.payoutId"
  },
  {
    key: "merchantName",
    label: "report.result.payoutToMerchant.merchantName"
  },
  {
    key: "phone",
    label: "report.result.payoutToMerchant.phone"
  },
  {
    key: "bankAccountHolder",
    label: "report.result.payoutToMerchant.bankAccountHolder"
  },
  {
    key: "bankName",
    label: "report.result.payoutToMerchant.bankName",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB' && selectedFleet.drvPayout.gateway !== 'credit') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToMerchant.accountNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToMerchant.iBanNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "paidAmount",
    label: "report.result.payoutToMerchant.payoutAmount",
    summary: "paidAmount"
  },
  {
    key: "payoutDate",
    label: "report.result.payoutToMerchant.payoutDate",
    mutate: (value, doc) => (value
      ? ReportUtils.formatTime(value, doc, {
        formatStr: 'MM/DD/YYYY hh:mm:ss A'
      })
      : 'N/A')
  }
];

export const fareFields = ["paidAmount"]