import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  InputGroup,
  Button,
  Radio,
  ButtonToolbar,
  DropdownButton,
  Dropdown,
  Modal,
  ListGroup,
  Form,
} from "react-bootstrap";
import { Validator, ValidCase } from "../../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../../utils/commonFunctions";
import "./shiftTemplate.scss";
import * as settingActions from "./../../../actions/settingActions";
import * as loadingBarActions from "./../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import TableHeader from "../../../components/table/TableHeader";
import { ShiftTempleColumns } from "./tableHeaderData";
import TableActions from "../../../components/table/tableAction/TableActions";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import _ from "lodash";

class ShiftTemplate extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      datalist: [],
      shiftTemplates: {
        limit: 20,
        list: [],
        page: 0,
        total: 1
      },
      numItemsPerPage: 20,
      showDialogModal: false,
      isSubmited: false,
      dialogData: {
        Mon: {},
        Tue: {},
        Wed: {},
        Thu: {},
        Fri: {},
        Sat: {},
        Sun: {},
        name: ""
      },
      shiftSettings: [],
      editable: false,
      showConfirm: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };

    this.renderDataItemList = this.renderDataItemList.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.openFormModal = this.openFormModal.bind(this);
    this.closeFormModal = this.closeFormModal.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.updateShiftTemplteList = this.updateShiftTemplteList.bind(this);
    this.loadShiftSettingsList = this.loadShiftSettingsList.bind(this);
    this.onChangeShiftSettings = this.onChangeShiftSettings.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleDeleteClickButton = this.handleDeleteClickButton.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.confirmDeleteShiftTemplate = this.confirmDeleteShiftTemplate.bind(
      this
    );
  }

  componentDidMount() {
    this.updateShiftTemplteList();
    this.loadShiftSettingsList();
  }

  loadShiftSettingsList() {
    const { shiftSettings, numItemsPerPage, activePage } = this.state;
    const { auth, settingActions } = this.props;
    settingActions
      .getAllShiftSettingsForSelection({ fleetId: auth.selectedFleet.fleetId })
      .then(res => {
        if (res.res) {
          this.setState({
            shiftSettings: res.res
          });
        }
      });
  }

  updateShiftTemplteList(activePage, limit) {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId
    };

    this.props.settingActions.findShiftTemplate(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          shiftTemplates: data.res,
          footerData: { page, total, limit }
        });
      }
    });
  }

  handleMenuClick(key, obj) {
    let id = obj._id;
    switch (key) {
      case "Edit": {
        const { shiftSettings, numItemsPerPage, activePage } = this.state;
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions.detailsShiftTemplate({ fleetId, id }).then(data => {
          this.setState({
            dialogData: data.res,
            editable: true
          });
          this.openFormModal();
        });
        break;
      }
      case "View": {
        const { shiftSettings, numItemsPerPage, activePage } = this.state;
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions.detailsShiftTemplate({ fleetId, id }).then(data => {
          this.setState({
            dialogData: data.res,
            editable: false
          });
          this.openFormModal();
        });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: obj });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
    }
  }

  handleDeleteClickButton(obj) {
    this.setState({ showConfirm: true, dialogData: obj });
  }

  confirmDeleteShiftTemplate() {
    const { shiftSettings, numItemsPerPage, activePage } = this.state;
    const { auth, settingActions } = this.props;
    let fleetId = auth.selectedFleet.fleetId;
    let id = this.state.dialogData._id;
    settingActions.deleteShiftTemplate({ fleetId, id }).then(data => {
      if (data.ok && data.res) {
        this.closeFormModal();
        this.updateShiftTemplteList();
        this.context.notification(
          "success",
          I18n.t("shiftSettings.Delete_shift_template_success")
        );
      } else {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("shiftSettings.Delete_shift_template_fail")
          );
        }
      }
    });
  }

  openFormModal() {
    this.setState({ showDialogModal: true });
  }

  closeFormModal() {
    this.setState({
      showDialogModal: false,
      editable: false,
      showConfirm: false
    });
  }

  onChangeShiftSettings(e, day) {
    let { dialogData } = this.state;
    let value = e.target.value;
    dialogData[day] = {
      name: value
    };
    if (day) {
      this.setState({
        dialogData
      });
    }
  }

  handleAddButtonClick() {
    this.openFormModal();
    this.setState({
      isSubmited: false,
      dialogData: {
        name: ""
      },
      editable: true
    });
  }

  handleSaveButtonClick() {
    const { valid, dialogData, shiftSettings } = this.state;
    const { auth, settingActions, loadingBarActions } = this.props;
    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) return;
    let fleetId = auth.selectedFleet.fleetId;
    let body = {
      name: dialogData.name,
      Mon: dialogData.Mon ? dialogData.Mon.name : shiftSettings[0].name,
      Tue: dialogData.Tue ? dialogData.Tue.name : shiftSettings[0].name,
      Wed: dialogData.Wed ? dialogData.Wed.name : shiftSettings[0].name,
      Thu: dialogData.Thu ? dialogData.Thu.name : shiftSettings[0].name,
      Fri: dialogData.Fri ? dialogData.Fri.name : shiftSettings[0].name,
      Sat: dialogData.Sat ? dialogData.Sat.name : shiftSettings[0].name,
      Sun: dialogData.Sun ? dialogData.Sun.name : shiftSettings[0].name,
      fleetId
    };
    loadingBarActions.showLoadingSpiner();
    if (dialogData._id) {
      settingActions
        .updateShiftTemplate({ ...body, id: dialogData._id })
        .then(data => {
          loadingBarActions.hideLoadingSpiner();
          if (!data.error && data.ok) {
            this.updateShiftTemplteList();
            this.setState({ isSubmited: false });
            this.context.notification(
              "success",
              I18n.t("shiftSettings.Update_shift_template_success")
            );
            this.closeFormModal();
          } else {
            if (data.error) {
              this.context.notification(
                "error",
                I18n.t("errors." + data.error.errorCode)
              );
            } else {
              this.context.notification(
                "error",
                I18n.t("shiftSettings.Update_shift_template_fail")
              );
            }
          }
        });
    } else {
      settingActions.createShiftTemplate(body).then(data => {
        loadingBarActions.hideLoadingSpiner();
        if (!data.error && data.ok) {
          this.updateShiftTemplteList();
          this.setState({ isSubmited: false });
          this.context.notification(
            "success",
            I18n.t("shiftSettings.Create_shift_template_success")
          );
          this.closeFormModal();
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("shiftSettings.Create_shift_template_fail")
            );
          }
        }
      });
    }
  }

  handleNameChange(e) {
    const value = e.target.value;
    let { dialogData } = this.state;
    dialogData.name = value;
    this.setState({
      ...dialogData
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateShiftTemplteList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e);
    this.updateShiftTemplteList(0, numItemsPerPage);
  }

  renderDataItemList() {
    const { shiftTemplates } = this.state;
    if (shiftTemplates && shiftTemplates.list.length > 0) {
      return shiftTemplates.list.map(obj => {
        return (
          <tr key={obj._id}>
            <td scope="row">
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", obj._id);
                }}
              >
                {obj.name}
              </a>
            </td>
            <td scope="row">{obj.Mon ? obj.Mon.name : ""}</td>
            <td scope="row">{obj.Tue ? obj.Tue.name : ""}</td>
            <td scope="row">{obj.Wed ? obj.Wed.name : ""}</td>
            <td scope="row">{obj.Thu ? obj.Thu.name : ""}</td>
            <td scope="row">{obj.Fri ? obj.Fri.name : ""}</td>
            <td scope="row">{obj.Sat ? obj.Sat.name : ""}</td>
            <td scope="row">{obj.Sun ? obj.Sun.name : ""}</td>
            <td>
              {!this.props.permissions || this.props.permissions.actions ? (
                <DropdownButton
                  title={<i className="fa fa-bars" />}
                  pullRight
                  id="bg-nested-dropdown"
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, obj._id);
                  }}
                >
                  <Dropdown.Item eventKey="Edit">
                    <Translate value="shiftSettings.Edit" />
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="Delete"
                    onClick={() => {
                      this.handleDeleteClickButton(obj);
                    }}
                  >
                    <Translate value="shiftSettings.Delete" />
                  </Dropdown.Item>
                </DropdownButton>
              ) : (
                <a
                  onClick={() => {
                    this.handleMenuClick("View", obj._id);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="shiftSettings.View" />
                </a>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td className="item-no-found" colSpan={9}>
            <Translate value="messages.item_no_found" />
          </td>
        </tr>
      );
    }
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], ShiftTempleColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "Name":
          col.customCell = shiftTemplate => {
            return (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", shiftTemplate);
                }}
              >
                {shiftTemplate.name}
              </a>
            );
          };
          break;
        case "Mon":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Mon ? shiftTemplate.Mon.name : ""}</span>
            );
          };
          break;
        case "Tue":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Tue ? shiftTemplate.Tue.name : ""}</span>
            );
          };
          break;
        case "Wed":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Wed ? shiftTemplate.Wed.name : ""}</span>
            );
          };
          break;
        case "Thu":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Thu ? shiftTemplate.Thu.name : ""}</span>
            );
          };
          break;
        case "Fri":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Fri ? shiftTemplate.Fri.name : ""}</span>
            );
          };
          break;
        case "Sat":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Sat ? shiftTemplate.Sat.name : ""}</span>
            );
          };
          break;
        case "Sun":
          col.customCell = shiftTemplate => {
            return (
              <span>{shiftTemplate.Sun ? shiftTemplate.Sun.name : ""}</span>
            );
          };
          break;

        case "actions":
          col.customCell = (shiftTemplate, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "shiftSettings.Edit",
                  eventKey: "Edit"
                },
                {
                  label: "shiftSettings.Delete",
                  eventKey: "Delete"
                }
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={shiftTemplate}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, shiftTemplate);
                  }}
                  totalRow={this.state.shiftTemplates.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", shiftTemplate);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="shiftSettings.View" />
                </a>
              );
            }
          };
          break;
        default:
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const { shiftSettings, dialogData } = this.state;
    const bodyData =
      this.state.shiftTemplates && this.state.shiftTemplates.list.length > 0
        ? this.state.shiftTemplates.list
        : [];
    let firstOption = "";
    if (shiftSettings && shiftSettings.length > 0)
      firstOption = shiftSettings[0].name;
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeFormModal} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="shiftSettings.Delete_shift_template" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeFormModal}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="shiftSettings.Delete_confirm_shift_template" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteShiftTemplate}
              >
                <Translate value="shiftSettings.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeFormModal}>
                <Translate value="shiftSettings.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
        <Modal
          onHide={this.closeFormModal}
          show={this.state.showDialogModal}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.dialogData._id ? (
                this.state.editable ? (
                  <Translate value="shiftSettings.Edit_shift_template" />
                ) : (
                  <Translate value="shiftSettings.Detail_shift_template" />
                )
              ) : (
                <Translate value="shiftSettings.Add_shift_template" />
              )}
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeFormModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.name
                    ? null
                    : "error"
              }
            >
              <Form.Label>
                <Translate value="shiftSettings.Name" />{" "}<span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                onChange={this.handleNameChange}
                value={
                  this.state.dialogData
                    ? this.state.dialogData.name
                    : firstOption
                }
                placeholder={I18n.t("shiftSettings.Name")}
                disabled={!this.state.editable}
              />
              <Validator id="name" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                  message={I18n.t("messages.commonMessages.Required_field")}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md={4}>
                  <Form.Label>
                    <Translate value="shiftSettings.Working_time" />{" "}<span className="require">*</span>
                  </Form.Label>
                </Col>
                <Col md={8}>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Mon" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Mon");
                        }}
                        value={
                          dialogData.Mon ? dialogData.Mon.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return (
                            <option value={obj.name} key={obj._id}>
                              {obj.name}
                            </option>
                          );
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Tue" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Tue");
                        }}
                        value={
                          dialogData.Tue ? dialogData.Tue.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return (
                            <option value={obj.name} key={obj._id}>
                              {obj.name}
                            </option>
                          );
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Wed" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Wed");
                        }}
                        value={
                          dialogData.Wed ? dialogData.Wed.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return <option value={obj.name}>{obj.name}</option>;
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Thu" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Thu");
                        }}
                        value={
                          dialogData.Thu ? dialogData.Thu.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return <option value={obj.name}>{obj.name}</option>;
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Fri" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Fri");
                        }}
                        value={
                          dialogData.Fri ? dialogData.Fri.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return <option value={obj.name}>{obj.name}</option>;
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Sat" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Sat");
                        }}
                        value={
                          dialogData.Sat ? dialogData.Sat.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return <option value={obj.name}>{obj.name}</option>;
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className={"working-time-row"}>
                    <Col md={4}>
                      <Translate value="General.Sun" />
                    </Col>
                    <Col md={8}>
                      <FormControl
                        as="select"
                        className={"form-custom"}
                        onChange={e => {
                          this.onChangeShiftSettings(e, "Sun");
                        }}
                        value={
                          dialogData.Sun ? dialogData.Sun.name : firstOption
                        }
                        disabled={!this.state.editable}
                      >
                        {shiftSettings.map(obj => {
                          return <option value={obj.name}>{obj.name}</option>;
                        })}
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormGroup>
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="shiftSettings.Required_fields" />
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            {!this.props.permissions ||
            (this.props.permissions && this.props.permissions.actions) ? (
              this.state.editable ? (
                <Button
                  className={"btn-save mr-md"}
                  onClick={this.handleSaveButtonClick}
                >
                  <Translate value="shiftSettings.Save" />
                </Button>
              ) : (
                <Button
                  className={"btn-save mr-md"}
                  onClick={e => {
                    this.handleMenuClick("changeStatus", e);
                  }}
                >
                  <Translate value="shiftSettings.Edit" />
                </Button>
              )
            ) : null}
            <Button className={"btn-cancel"} onClick={this.closeFormModal}>
              <Translate value="shiftSettings.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
        {!this.props.permissions ||
        (this.props.permissions && this.props.permissions.actions) ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              onClick={this.handleAddButtonClick}
              className="btn-header text-add-header ml0"
            >
              {" "}
              
              <Translate value="shiftSettings.Add" />
            </Button>
          </ButtonToolbar>
        ) : null}

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

ShiftTemplate.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftTemplate);
