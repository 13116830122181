import React from 'react';
import styles from '../styles/headerGr.module.scss';

const HeaderGr = ({ navigate, ...props }) => {
  const { breadcrumbs, title } = props;
  const handleChangePath = (path) => {
    navigate.push(path);
  };
  return (
    <div className={styles['grTitle']}>
      <div className={styles['grText']}>
        {breadcrumbs.map((route, index) => {
          return (
            <div className={styles['linkText']} key={index}>
              {index !== 0 && <div>{'>'}</div>}
              <div
                className={`
                  ${styles[`link`]} 
                  ${index === breadcrumbs.length - 1 ? styles['active'] : ''}`}
                onClick={() => handleChangePath(route?.path)}
              >
                {route?.name}
              </div>
            </div>
          );
        })}
      </div>
      {title && <div className={styles['title']}>{title}</div>}
    </div>
  );
};

export default HeaderGr;
