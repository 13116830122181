import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  InputGroup,
  Tab,
  Tabs,
} from 'react-bootstrap';

import { Validator, ValidCase } from '../../../components/validator';

import {
  CCLiteCommonFunc,
  Validation,
  MultiSelectCommonFunc,
} from '../../../utils/commonFunctions';

import * as appBanerActions from '../../../actions/appBanerActions';
import * as uploadActions from '../../../actions/uploadActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import UploadFile from '../../../components/upload/upload';
import UploadImageTemplate from '../../../components/upload/ImageUploadWrapper';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import '../settings.scss';

const ComparisonVersionAppOption = [
  'is',
  'is_not',
  'is_one_of',
  'is_not_one_of',
  'greater_or_equal',
  'less_or_equal',
];

const PlatformType = ['all', 'iOS', 'android'];

const driverStatusList = [
  {
    value: 'active',
    label: 'appBanner.Active',
    tooltip: 'appBanner.tooltip_active',
  },
  {
    value: 'completedProfile',
    label: 'appBanner.in_review_completed',
    tooltip: 'appBanner.tooltip_in_review_completed',
  },
  {
    value: 'incompletedProfile',
    label: 'appBanner.in_review_incomplete',
    tooltip: 'appBanner.tooltip_in_review_incomplete',
  },
  {
    value: 'inprogressProfile',
    label: 'appBanner.in_review_inProgress',
    tooltip: 'appBanner.tooltip_in_review_inProgress',
  },
  {
    value: 'inactive',
    label: 'appBanner.Inactive',
    tooltip: 'appBanner.tooltip_inactive',
  },
];

class AddEditBannerModal extends Component {
  constructor(props) {
    const banner = _.assign({}, props.banner);
    super(props);
    this.state = {
      valid: {},
      banner,
      file: null,
      editable: props.editable,
    };
  }

  handleImageChange = (changeFile, error) => {
    const { banner } = this.state;
    const data = changeFile || {};

    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification(
        'error',
        I18n.t('upload_document.Limit_size_upload').format(8)
      );
      return;
    }
    banner.imageUrl = data.result || null;
    this.setState({
      banner,
      file: data.file || null,
    });
  };

  handleInputChange = (key, event) => {
    const { banner } = this.state;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    this.setState({
      banner: {
        ...banner,
        [key]: value,
      },
    });
  };

  handleUserStatusChange = (e) => {
    const { banner } = this.state;
    let value = MultiSelectCommonFunc.getSelectValues(e);
    this.setState({
      banner: {
        ...banner,
        userStatus: value,
      },
    });
  };

  handleZoneChange = (e) => {
    const { banner } = this.state;
    let value = MultiSelectCommonFunc.getSelectValues(e);
    this.setState({
      banner: {
        ...banner,
        zones: value,
      },
    });
  };

  handldChangeBannersImage = (key, changeFile, error) => {
    const { banner } = this.state;
    let listItem = _.cloneDeep(banner.banners);
    const data = changeFile || {};
    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification(
        'error',
        I18n.t('upload_document.Limit_size_upload').format(8)
      );
      return;
    }
    let indexItem = listItem.findIndex((item) => item.language === key);
    if (indexItem !== -1) {
      listItem[indexItem].imageUrl = data.result || null;
      listItem[indexItem].file = data.file || null;
    } else {
      listItem.push({
        language: key,
        imageUrl: data.result || null,
        file: data.file || null,
      });
    }
    if (!data.result && !data.file) {
      listItem.splice(indexItem, 1);
    }
    banner.banners = listItem;
    this.setState({
      banner,
    });
  };

  saveDialogForm = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    const {
      auth: { selectedFleet },
    } = this.props;
    const { banner } = this.state;
    if (banner.languageOption === 'single') {
      const object = _.extend({ fleetId: selectedFleet.fleetId }, banner);
      this.props.loadingBarActions.showLoadingSpiner();
      this.handleUploadFile(object).then((imageUpload) => {
        if (imageUpload && imageUpload.error) {
          this.props.loadingBarActions.hideLoadingSpiner();
          return;
        }
        object.imageUrl = imageUpload;
        this.callServerSaveBanner(object, banner._id ? banner._id : '');
      });
    } else if (banner.languageOption === 'multi') {
      let promises = [];
      banner.banners.forEach((element) => {
        if (element.file) {
          promises.push(
            this.handleUploadBannerImage(element).then((imageUpload) => {
              if (imageUpload && imageUpload.error) {
                this.props.loadingBarActions.hideLoadingSpiner();
                return;
              }
              element.imageUrl = imageUpload;
              delete element.file;
            })
          );
        }
      });
      Promise.all(promises).then(() => {
        const object = _.extend({ fleetId: selectedFleet.fleetId }, banner);
        this.callServerSaveBanner(object, banner._id ? banner._id : '');
      });
    }
  };

  callServerSaveBanner = (data, bannerId = '') => {
    const { appBanerServices } = this.props;
    let object = data || {};
    if (bannerId !== '') {
      object.bannerId = bannerId;
      appBanerServices.updateAppBanner(object).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else if (data.message) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.message.errorCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('appBanner.Update_app_banner_fail')
            );
          }
          this.setState({ isSubmitted: false });
        } else {
          this.props.onAddBannerSuccess(data.res);
          this.context.notification(
            'success',
            I18n.t('appBanner.Update_app_banner_success')
          );
        }
      });
    } else {
      appBanerServices.createAppBanner(object).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          } else if (data.message) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.message.errorCode}`)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('appBanner.Create_app_banner_fail')
            );
          }
          this.setState({ isSubmitted: false });
        } else {
          this.props.onAddBannerSuccess(data.res);
          this.context.notification(
            'success',
            I18n.t('appBanner.Create_app_banner_success')
          );
        }
      });
    }
  };

  handleUploadFile = (newBanner) => {
    const { file } = this.state;
    const {
      banner,
      uploadService,
      auth: { selectedFleet },
    } = this.props;

    if (file && (newBanner.imageUrl !== banner.imageUrl || !banner._id)) {
      const form = new FormData();
      form.append('photo', file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('module', 'bannerApp');
      return uploadService.uploadPhoto(form).then((res) => {
        if (!res.error) {
          return Promise.resolve(_.get(res, 'res.Location'));
        }
        this.context.notification('error', I18n.t('errors.upload_photo_fail'));
        return Promise.resolve({ error: res.error });
      });
    }
    return Promise.resolve(banner.imageUrl);
  };

  handleUploadBannerImage = (img) => {
    const {
      uploadService,
      auth: { selectedFleet },
    } = this.props;
    if (img.file) {
      const form = new FormData();
      form.append('photo', img.file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('module', 'bannerApp');
      return uploadService.uploadPhoto(form).then((res) => {
        if (!res.error) {
          return Promise.resolve(_.get(res, 'res.Location'));
        }
        this.context.notification('error', I18n.t('errors.upload_photo_fail'));
        return Promise.resolve({ error: res.error });
      });
    }
    return Promise.resolve(img.imageUrl);
  };

  onEditable = () => {
    this.setState({ editable: true });
  };

  closeDialogForm = () => {
    const { closeDialogForm } = this.props;
    closeDialogForm();
  };

  ValidatorCallback = (id, valid) => {
    if (this.state.valid[id] !== valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  render() {
    const { banner, isSubmitted, valid, editable } = this.state;
    const { selectedFleet, zones } = this.props;
    let actionOptions = {
      LinkToWebsite: 'Link to website',
      MakeACall: 'Make a call',
      SendAnEmail: 'Send an email',
      BookNow: 'Book now',
      BookLate: 'Book late',
      Referral: 'Referral',
    };
    let applyTo = {
      HomeView: 'Home view',
      OtherView: 'Other view',
    };

    let languageOptions = {
      single: 'single',
      multi: 'multi',
    };
    let drvSignUpLanguages = selectedFleet.drvSignUpLanguages || ['en-US'];
    if (banner.appType === 'driver') {
      applyTo = {
        HomeView: 'Home view',
      };
      actionOptions = {
        LinkToWebsite: 'Link to website',
        MakeACall: 'Make a call',
        SendAnEmail: 'Send an email',
        Referral: 'Referral',
      };
    }

    return (
      <Modal show backdrop bsSize="lg" onHide={this.closeDialogForm}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.banner._id ? (
              this.state.editable ? (
                <Translate value="appBanner.edit_banner" />
              ) : (
                <Translate value="appBanner.detail_banner" />
              )
            ) : (
              <Translate value="appBanner.create_banner" />
            )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.closeDialogForm}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup
                className={
                  isSubmitted ? (valid.name === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="appBanner.Name" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>

                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={(e) => {
                    this.handleInputChange('name', e);
                  }}
                  value={banner.name}
                  placeholder={I18n.t('appBanner.Name')}
                  maxLength={50}
                  disabled={!editable}
                />
                <Validator
                  id="name"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={banner.name.trim().length > 0}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  isSubmitted
                    ? valid.viewType === false
                      ? 'error'
                      : null
                    : null
                }
              >
                <Form.Label>
                  <Translate value="appBanner.viewType" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  as="select"
                  defaultValue="image"
                  value={banner.viewType}
                  onChange={(e) => {
                    this.handleInputChange('viewType', e);
                  }}
                  className="form-custom"
                  disabled={!editable}
                >
                  {/* <option value="" disabled>{I18n.t('appBanner.Select_Type')}</option> */}
                  <option value="image">{I18n.t('appBanner.image')}</option>
                  {/* <option value="html" disabled>{I18n.t('appBanner.html')}</option> */}
                </FormControl>
                <Validator
                  id="viewType"
                  callback={this.ValidatorCallback}
                  disabled={!this.state.editable}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={banner.viewType.trim().length > 0}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup
                className={
                  isSubmitted ? (valid.type === false ? 'error' : null) : null
                }
              >
                <Form.Label>
                  <Translate value="appBanner.Action" />
                </Form.Label>
                <FormControl
                  as="select"
                  defaultValue={banner.action}
                  value={banner.action}
                  onChange={(e) => {
                    this.handleInputChange('action', e);
                  }}
                  className="form-custom"
                  disabled={!editable}
                >
                  <option value="">{I18n.t('appBanner.NA')}</option>
                  {_.values(actionOptions).map((action) => (
                    <option key={action} value={action} selected="">
                      {I18n.t(`appBanner.${action}`)}
                    </option>
                  ))}
                </FormControl>
                {banner.action &&
                banner.action !== actionOptions.BookLate &&
                banner.action != actionOptions.BookNow &&
                banner.action != actionOptions.Referral ? (
                  <FormControl
                    className="mt form-custom"
                    type="text"
                    onChange={(e) => {
                      this.handleInputChange('actionContent', e);
                    }}
                    disabled={!editable}
                    value={banner.actionContent}
                    placeholder={
                      banner.action == actionOptions.LinkToWebsite
                        ? 'https://www.example.com'
                        : banner.action == actionOptions.MakeACall
                        ? '+141555527777'
                        : 'support@example.com'
                    }
                  />
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup
                className={`apply-to-control ${
                  isSubmitted
                    ? valid.languageOption === false
                      ? 'error'
                      : null
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="appBanner.language" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  value={banner.languageOption}
                  defaultValue={banner.languageOption}
                  onChange={(e) => {
                    this.handleInputChange('languageOption', e);
                  }}
                  disabled={!editable}
                >
                  {_.values(languageOptions).map((type) => (
                    <option key={type} value={type} selected="">
                      {I18n.t(`appBanner.${type}`)}
                    </option>
                  ))}
                </FormControl>
                <Validator
                  id="languageOption"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(banner.languageOption)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>

              {banner.languageOption === 'single' ? (
                <FormGroup
                  className={`app-banner-wrapper ${
                    isSubmitted
                      ? valid.imageUrl === false
                        ? 'error'
                        : null
                      : null
                  }`}
                >
                  <UploadFile
                    id="banner.image"
                    name="banner.image"
                    onChange={this.handleImageChange}
                    customTemplate={UploadImageTemplate}
                    className="app-banner-upload-image"
                    customTemplateProps={{
                      fileResult: banner.imageUrl || null,
                    }}
                    accepts="image/*"
                    onlyImage
                    disabled={!editable}
                    maxFileSize={8}
                  />
                  <Validator
                    id="imageUrl"
                    callback={this.ValidatorCallback}
                    disabled={!this.state.editable}
                  >
                    <ValidCase
                      hide={!isSubmitted}
                      valid={
                        banner.imageUrl && banner.imageUrl.trim().length > 0
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
              ) : null}

              {banner.languageOption === 'multi' ? (
                <Tabs
                  id="currencies-tabs-Ondemand"
                  className="currencies-tabs"
                  defaultActiveKey={drvSignUpLanguages[0]}
                  animation={false}
                  onSelect={this.tabSelectHandle}
                >
                  {drvSignUpLanguages.map((language) => {
                    let image = (banner.banners || []).find(
                      (b) => b.language === language
                    );
                    let titleLanguage = I18n.t(`appBanner.${language}`);
                    return (
                      <Tab
                        className="currencies-tab-item"
                        key={language}
                        eventKey={language}
                        title={titleLanguage}
                      >
                        <FormGroup
                          className={`app-banner-wrapper ${
                            isSubmitted
                              ? valid.banners === false
                                ? 'error'
                                : null
                              : null
                          }`}
                        >
                          <UploadFile
                            id="banner.image"
                            name="banner.image"
                            onChange={(changeFile, error) =>
                              this.handldChangeBannersImage(
                                language,
                                changeFile,
                                error
                              )
                            }
                            customTemplate={UploadImageTemplate}
                            className="app-banner-upload-image"
                            customTemplateProps={{
                              fileResult:
                                image && image.imageUrl ? image.imageUrl : null,
                            }}
                            accepts="image/*"
                            onlyImage
                            disabled={!editable}
                            maxFileSize={8}
                          />
                          <Validator
                            id="banners"
                            callback={this.ValidatorCallback}
                          >
                            <ValidCase
                              hide={!isSubmitted}
                              valid={
                                banner.banners && banner.banners.length > 0
                              }
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                            />
                          </Validator>
                        </FormGroup>
                      </Tab>
                    );
                  })}
                </Tabs>
              ) : null}

              {banner.appType === 'driver' && (
                <FormGroup
                  className={`apply-to-control ${
                    isSubmitted
                      ? valid.userStatus === false
                        ? 'error'
                        : null
                      : null
                  }`}
                >
                  <Form.Label>
                    <Translate value="appBanner.userStatus" />{' '}
                    <span className="require">&nbsp;*</span>
                  </Form.Label>
                  <FormControl
                    className="form-custom app-banner-multi-select"
                    as="select"
                    multiple
                    onChange={this.handleUserStatusChange}
                    value={banner.userStatus}
                    disabled={!editable}
                  >
                    {driverStatusList.map((status) => {
                      return (
                        <option key={status.value} value={status.value}>
                          {I18n.t(status.label)}
                        </option>
                      );
                    })}
                  </FormControl>
                  <Validator id="userStatus" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmitted}
                      valid={banner.userStatus && banner.userStatus.length > 0}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
              )}

              <FormGroup
                className={`apply-to-control ${
                  isSubmitted ? (valid.zones === false ? 'error' : null) : null
                }`}
              >
                <Form.Label>
                  <Translate value="appBanner.zones" />{' '}
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  className="form-custom app-banner-multi-select"
                  as="select"
                  multiple
                  onChange={this.handleZoneChange}
                  value={banner.zones}
                  disabled={!editable}
                >
                  {zones.map((z) => {
                    return (
                      <option key={z._id} value={z._id}>
                        {z.zoneName}
                      </option>
                    );
                  })}
                </FormControl>
                <Validator id="zones" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={banner.zones && banner.zones.length > 0}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup
                className={`apply-to-control ${
                  isSubmitted
                    ? valid.applyTo === false
                      ? 'error'
                      : null
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="appBanner.Apply_to" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-custom"
                  value={banner.applyTo}
                  defaultValue={banner.applyTo}
                  onChange={(e) => {
                    this.handleInputChange('applyTo', e);
                  }}
                  disabled={!editable}
                >
                  <option value="">
                    {I18n.t('appBanner.Please_select_view')}
                  </option>
                  {_.values(applyTo).map((type) => (
                    <option key={type} value={type} selected="">
                      {I18n.t(`appBanner.${type}`)}
                    </option>
                  ))}
                </FormControl>
                <Validator id="applyTo" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmitted}
                    valid={!Validation.isStringEmpty(banner.applyTo)}
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup className={'qup-input-group'}>
                <Form.Label>
                  <Translate value="appBanner.display_duration" />
                </Form.Label>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    className={'form-custom number-no-spin'}
                    type="text"
                    onChange={(e) => {
                      this.handleInputChange('displayDuration', e);
                    }}
                    disabled={!editable}
                    value={banner.displayDuration}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Translate value="dispatchSetting.Seconds" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <Validator
                  id="displayDuration"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isInteger(banner.displayDuration)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.isGreaterThan(banner.displayDuration, 0)}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={`apply-to-control ${
                  isSubmitted
                    ? valid.version === false
                      ? 'error'
                      : null
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="appBanner.version" />
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup className="single-addon-right">
                    <FormControl
                      type="text"
                      className="form-custom"
                      value={banner.version}
                      placeholder={I18n.t('appBanner.version')}
                      onChange={(e) => this.handleInputChange('version', e)}
                      disabled={!editable}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={banner.comparisonVersionApp}
                      onChange={(e) =>
                        this.handleInputChange('comparisonVersionApp', e)
                      }
                      disabled={!editable}
                    >
                      {ComparisonVersionAppOption.map((item) => {
                        return (
                          <option key={item} value={item}>
                            {I18n.t(`appBanner.comparisonVersionApp.` + item)}
                          </option>
                        );
                      })}
                    </FormControl>
                  </InputGroup>
                </FormGroup>
              </FormGroup>
              <FormGroup
                className={`apply-to-control ${
                  isSubmitted
                    ? valid.platform === false
                      ? 'error'
                      : null
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="appBanner.platform" />
                  <span className="require">&nbsp;*</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={banner.platform}
                  onChange={(e) => this.handleInputChange('platform', e)}
                  disabled={!editable}
                >
                  {PlatformType.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {I18n.t(`appBanner.platformItem.` + item)}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <CcCheckbox
                checked={banner.activate}
                disabled={!editable}
                onChange={(e) => {
                  this.handleInputChange('activate', e);
                }}
                text={
                  <span>
                    <Translate value="referral.Active" />
                  </span>
                }
                labelClassName="bold"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!this.props.permissions || this.props.permissions.actions ? (
            this.state.editable ? (
              <Button className="btn-save mr-md" onClick={this.saveDialogForm}>
                <Translate value="carSetting.Save" />
              </Button>
            ) : (
              <Button className="btn-save mr-md" onClick={this.onEditable}>
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={this.closeDialogForm}>
            <Translate value="carSetting.Cancel" />
          </Button>
          <Row className="text-left">
            <Form.Label>
              <p>
                <Translate value="appBanner.note_1" limit={8} />
              </p>
              <p>
                <Translate value="appBanner.note_2" />
              </p>
              <p>
                <Translate value="appBanner.note_3" />
              </p>
            </Form.Label>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddEditBannerModal.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission,
    zones: state.commonData.mapZone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    uploadService: bindActionCreators(uploadActions, dispatch),
    appBanerServices: bindActionCreators(appBanerActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditBannerModal);
