import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Image
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import * as intercityBookingActions from '../../../../actions/intercityBookingActions';
import { additionalServiceType } from '../../../../constants/commondata';
import destinationIcon from '../../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../../assets/images/icons/pickup2.svg';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

const PaymentMethodOption = ({ method, bookData }) => {
  if (
    method.value === bookData.request.paymentType
    && (![9].includes(method.value) || ([9].includes(method.value) && bookData.status === 'droppedOff'))
    && ![14].includes(method.value)
    && method.value !== 1
  ) {
    return (
      <option key={method.value} value={method.value}>
        {I18n.t(`General.patmentMethod_${method.value}`)}
      </option>
    );
  }
  return null;
};

function SecondStep({ bookInfo, selectedFleet, paymentComplete, commonData, paymentMethod, fareSettings, ...props }) {
  const [showExtraServices, setShowExtraServices] = useState(false);
  const {
    request: {
      estimate: { fare },
      services,
      promo,
      promoValue,
      paymentType
    },
  } = bookInfo;

  const isBookingFromPaxApp = booking => {
    let bookFrom = commonData.bookingFrom.filter(a => a.value != 'PaxApp');
    bookFrom = bookFrom.find(a => a.value == booking.bookFrom || a.label == booking.bookFrom);
    if (bookFrom) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <FormGroup className="location-info">
        <Image src={pickupIcon} />
        <span>{bookInfo.request.pickup.address}</span>
      </FormGroup>
      <FormGroup className="location-info">
        <Image src={destinationIcon} />
        <span>{bookInfo.request.destination.address}</span>
      </FormGroup>
      <FormGroup>
        <Form.Label style={{ position: 'relative' }}>
          <Translate value="bookingdetail.Subtotal" />
          {'\u00A0'}
          {fare.min && (
            <Translate value="bookingdetail.Minimum" className="minimum" />
          )}
        </Form.Label>
        <span className="amount">
          {currencyFormatter.format(fare.subTotal, {
            code: bookInfo.currencyISO,
          })}
        </span>
      </FormGroup>
      {fareSettings.fare.tollFeeActive || fareSettings.tollFee ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Toll_fees" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.tollFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}
      {fareSettings.fare.parkingFeeActive || fareSettings.parkingFee ? (
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.parkingFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.parkingFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}
      {fareSettings.fare.gasFeeActive || fareSettings.gasFee ? (
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.gasFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  disabled
                  value={currencyFormatter.format(fare.gasFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : (
        ''
      )}

      {fare && fare.creditTransactionFee > 0 ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.creditTransactionFee" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  min={0}
                  value={currencyFormatter.format(fare.creditTransactionFee, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                  disabled
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : null}
      {fareSettings.fare.taxActive ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.Tax" />
          </Form.Label>
          <div className="input-with-validator">
            <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-left">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    {bookInfo.currencySymbol}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="text"
                  className="form-custom"
                  min={0}
                  value={currencyFormatter.format(fare.tax, {
                    code: bookInfo.currencyISO,
                    format: '%v'
                  })}
                  disabled
                />
              </InputGroup>
            </FormGroup>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Promo_code" />
        </Form.Label>
        <FormGroup>
          <div className="add-on-input-group">
            <FormControl
              type="text"
              value={(bookInfo.request.promo || '').toUpperCase()}
              placeholder={I18n.t('newbooking.Promo_code')}
              disabled
              className="form-custom"
            />
          </div>
        </FormGroup>
      </FormGroup>
     
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Promo" />
        </Form.Label>
        <span className="amount">
          {currencyFormatter.format(
            fare.promoAmount || 0,
            {
              code: bookInfo.currencyISO,
            }
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Total" />
        </Form.Label>
        <span className="amount total">
          {currencyFormatter.format(
            fare.etaFare,
            {
              code: bookInfo.currencyISO,
            }
          )}
        </span>
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="bookingdetail.Payment_Type" />
        </Form.Label>
        <FormControl
          as="select"
          type="text"
          className="form-custom"
          value={paymentType}
          disabled
        >
          {[
            <option key={1} value={1}>
              {I18n.t('General.patmentMethod_1')}
            </option>,
          ].concat(
            (bookInfo?.travelerType === 1 ? paymentMethod.corporateTypes : paymentMethod.individualTypes).map(pm => (
              <PaymentMethodOption method={pm} bookData={bookInfo} />
            ))
          )}
        </FormControl>
      </FormGroup>
      { paymentType === 1 ? (
        <FormGroup>
          <Form.Label>
            <Translate value="bookingdetail.pending_payment" />
          </Form.Label>
          <CcCheckbox
            checked={paymentComplete.isPending}
            onChange={props.handleCompletePaymentIsPendingChange}
            className="paymentModal__pendingPayment"
          />
        </FormGroup>
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    bookInfo: state.bookingDetail.data,
    commonData: state.commonData,
    paymentMethod: state.paymentMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);
