import React from 'react'
import counryCodes from 'country-codes-list'
import SelectMulti from '../SelectMulti'
import _ from 'lodash'

const Countries_Option = counryCodes.customArray({
  value: '{countryCode}',
  label: '{countryNameEn}'
})

const CcCountrySelect = ({
  isMulti,
  value,
  onchange,
}) => {
  return (
    <SelectMulti
      isMulti={isMulti}
      value={value}
      onChange={onchange}
      options={Countries_Option}
      classNamePrefix="selectMulti"
    />
  )
}

export default CcCountrySelect