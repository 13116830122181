import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../settings.scss';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  
  Modal,
  Col,
} from 'react-bootstrap';
import _, { truncate } from 'lodash';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';
import TableHeader from '../../../components/table/TableHeader';
import { DriverFieldsColumn } from './tableHeaderData';
import TableActions from '../../../components/table/tableAction/TableActions';
import StickyTable from '../../../components/table/stickyTable/StickyTable';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { tr } from 'react-dom-factories';
import RadioButton from '../../../components/radioButton/radio';

const keyCheckBox = [
  'availableCC',
  'availableDrvSignUp',
  'availableCompanyCar',
  'deliveryCompany',
  'availableIndividualCar',
  'deliveryIndividual',
  'required',
];

class DriverFields extends Component {
  constructor() {
    super();
    this.state = {
      DriverFields: null,
      activePage: 1,
      numItemsPerPage: 20,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmited: false,
      editable: false,
      tableHeight: 500,
      rowHeight: 50,
      languageCCList: [],
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
    };
  }
  componentDidMount() {
    this.updateDriverFieldsList();
  }

  updateDriverFieldsList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
      },
    };

    this.props.settingActions.fetchDriverFields(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit, languageCCList } = data.res;
        this.setState({
          DriverFields: data.res,
          footerData: { page, total, limit },
          languageCCList: languageCCList,
        });
      }
    });
  };

  detailDriverFields = (type, id) => {
    this.props.settingActions
      .detailDriverFields({
        id: id,
        fleetId: this.props.auth.selectedFleet.fleetId,
        languageCCList: this.state.languageCCList,
      })
      .then((data) => {
        if (data.ok) {
          if (type === 'view') {
            this.setState({
              showDialog: true,
              dialogData: data.res,
              dialogChanged: false,
              editable: false,
            });
          } else {
            this.setState({
              showDialog: true,
              dialogData: data.res,
              dialogChanged: false,
              editable: true,
            });
          }
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('driverFields.Load_fail')
            );
          }
        }
      });
  };

  handlePaginationSelect = (eventKey) => {
    this.updateDriverFieldsList(eventKey);
  };

  handleNumItemsPerPageChange = (e) => {
    this.updateDriverFieldsList(0, parseInt(e));
  };

  handleFieldFromChange = (e) => {
    const newState = { ...this.state.dialogData, fieldFrom: e.target.value };
    this.setState({ dialogData: newState, dialogChanged: true });
  };

  handleDocumentNameChange = (key, value) => {
    const arrNameState = [...this.state.dialogData.name];
    const indexNameChange = arrNameState.findIndex(
      (item) => item.language === key
    );

    if (indexNameChange !== -1) {
      arrNameState[indexNameChange].name = value;
    } else {
      arrNameState.push({
        language: key,
        name: value,
      });
    }
    this.setState({
      dialogData: { ...this.state.dialogData, name: arrNameState },
      dialogChanged: true,
    });
  };

  handleActiveChange = (key, checked) => {
    const newState = { ...this.state.dialogData, [key]: checked };
    this.setState({ dialogData: newState, dialogChanged: true });
  };

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
    });
  };

  editDriverFields = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    let objectDriverFields = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      update: this.state.dialogData,
      id: this.state.dialogData._id,
    };
    this.props.settingActions
      .editDriverFields(objectDriverFields)
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('driverFields.Update_fail')
            );
          }
        } else {
          this.context.notification(
            'success',
            I18n.t('driverFields.Update_success')
          );
          this.closeDialogForm();
          this.updateDriverFieldsList();
          this.setState({ isSubmited: false });
          setTimeout(function () {
            window.location.reload();
          }, 1 * 1000);
        }
      });
  };

  handleChangeExpCommandCenter = (value) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        isExpiredDate: value,
      },
    });
  };

  handleChangeExpDriverSignup = (value) => {
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        expiredDateForDrvSignUp: value,
      },
    });
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], DriverFieldsColumn);
    if (
      (this.props.auth.selectedFleet.delivery &&
        this.props.auth.selectedFleet.delivery.enable) ||
      (this.props.auth.selectedFleet.food &&
        this.props.auth.selectedFleet.food.enable) ||
      (this.props.auth.selectedFleet.mart &&
        this.props.auth.selectedFleet.mart.enable)
    ) {
      tableColumns = tableColumns;
    } else {
      tableColumns = tableColumns.filter(
        (item) =>
          item.key !== 'CompanyDelivery' && item.key !== 'IndividualDelivery'
      );
    }
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'FieldKey':
          col.customCell = (record) => {
            return !record.editable ? (
              <a
                className="clickable"
                onClick={() => this.detailDriverFields('view', record._id)}
              >
                {record.fieldKey}
              </a>
            ) : (
              <a className="disabled clickable">{record.fieldKey}</a>
            );
          };
          break;

        case 'FieldName':
          col.customCell = (record) => {
            return (
              <div>
                <span>{record.fieldName}</span>
              </div>
            );
          };
          break;

        case 'CommandCenter':
          col.customCell = (record) => {
            return (
              <div>
                {record.availableCC ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'SignUp':
          col.customCell = (record) => {
            return (
              <div>
                {record.availableDrvSignUp ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'CompanyTransport':
          col.customCell = (record) => {
            return (
              <div>
                {record.availableCompanyCar ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'CompanyDelivery':
          col.customCell = (record) => {
            return (
              <div>
                {record.deliveryCompany ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'IndividualTransport':
          col.customCell = (record) => {
            return (
              <div>
                {record.availableIndividualCar ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'IndividualDelivery':
          col.customCell = (record) => {
            return (
              <div>
                {record.deliveryIndividual ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'Require':
          col.customCell = (record) => {
            return (
              <div>
                {record.required ? (
                  <i className="fa fa-check fa-2x activateIcon" />
                ) : (
                  ''
                )}
              </div>
            );
          };
          break;
        case 'Actions':
          col.customCell = (record, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [];
              if (!record.editable) {
                actionItems.push({
                  label: 'driverFields.Edit',
                  eventKey: 'edit',
                });
              }
              if (!record.editable) {
                actionItems.push({
                  label: 'driverFields.View',
                  eventKey: 'view',
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={record}
                  onSelect={(eventKey) => {
                    this.detailDriverFields(eventKey, record._id);
                  }}
                  totalRow={this.state.DriverFields.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.detailDriverFields('view', record._id);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="driverFields.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const { isSubmited, valid, dialogData } = this.state;
    const { selectedFleet } = this.props.auth;
    const bodyData =
      this.state.DriverFields && this.state.DriverFields.list.length > 0
        ? this.state.DriverFields.list
        : [];

    // let isShowAssistant = (this.props.auth.selectedFleet && this.props.auth.selectedFleet.assistant) ? this.props.auth.selectedFleet.assistant.enable : false;
    let isShowDelivery =
      (selectedFleet.delivery && selectedFleet.delivery.enable) ||
      (selectedFleet.food && selectedFleet.food.enable) ||
      (selectedFleet.mart && selectedFleet.mart.enable);

    return (
      <div className="content">
        {this.state.showDialog ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="driverFields.EDIT_DRIVER_FIELDS" />
                  ) : (
                    <Translate value="driverFields.DETAIL_DRIVER_FIELDS" />
                  )
                ) : (
                  <Translate value="driverFields.ADD_DRIVER_FIELDS" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Col md={6} xs={12} className={'pl0'}>
                <FormGroup>
                  <Form.Label>
                    <Translate value="driverFields.fieldKey" />
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    type="text"
                    className="form-custom"
                    value={
                      this.state.dialogData
                        ? this.state.dialogData.fieldKey
                        : ''
                    }
                    placeholder={I18n.t('driverFields.fieldKey')}
                    disabled={true}
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>
                    <Translate value="driverFields.fieldName" />
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    disabled={!this.state.editable}
                    as="select"
                    className="form-custom"
                    value={
                      this.state.dialogData
                        ? this.state.dialogData.fieldFrom
                        : ''
                    }
                    onChange={this.handleFieldFromChange}
                  >
                    <option value="default">
                      {I18n.t('driverFields.default')}
                    </option>
                    <option value="customized">
                      {I18n.t('driverFields.customized')}
                    </option>
                  </FormControl>
                </FormGroup>

                {this.state.dialogData.fieldFrom === 'customized' &&
                  this.state.languageCCList &&
                  this.state.languageCCList.map((item, index) => (
                    <FormGroup
                      className={
                        !isSubmited
                          ? null
                          : this.state.valid[item.languageTag]
                          ? null
                          : 'error'
                      }
                      style={{ marginLeft: '15px' }}
                      key={index}
                    >
                      <Form.Label>{item.languageVariant}</Form.Label>
                      <FormControl
                        type="text"
                        className="form-custom"
                        onChange={(e) =>
                          this.handleDocumentNameChange(
                            item.languageTag,
                            e.target.value
                          )
                        }
                        value={
                          this.state.dialogData &&
                          this.state.dialogData.name &&
                          this.state.dialogData.name.find(
                            (value) => value.language === item.languageTag
                          )
                            ? this.state.dialogData.name.find(
                                (value) => value.language === item.languageTag
                              ).name
                            : ''
                        }
                        placeholder={item.languageVariant}
                        disabled={!this.state.editable}
                      />
                      <Validator
                        id={item.languageTag}
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.dialogData &&
                                this.state.dialogData.name &&
                                this.state.dialogData.name.find(
                                  (value) => value.language === item.languageTag
                                )
                                ? this.state.dialogData.name.find(
                                    (value) =>
                                      value.language === item.languageTag
                                  ).name
                                : ''
                            )
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  ))}
              </Col>
              <Col md={6} xs={12} className={'pr0'}>
                <div
                  style={{
                    backgroundColor: '#30343D',
                    padding: '20px',
                    borderRadius: '8px',
                    marginBottom: '20px',
                  }}
                >
                  <FormGroup>
                    <Form.Label>
                      <Translate value="driverFields.fieldType" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      disabled={true}
                      type="text"
                      className="form-custom"
                      value={
                        this.state.dialogData
                          ? this.state.dialogData.fieldType
                          : ''
                      }
                    ></FormControl>
                  </FormGroup>

                  {((this.props.auth.selectedFleet.delivery &&
                    this.props.auth.selectedFleet.delivery.enable) ||
                  (this.props.auth.selectedFleet.food &&
                    this.props.auth.selectedFleet.food.enable) ||
                  (this.props.auth.selectedFleet.mart &&
                    this.props.auth.selectedFleet.mart.enable)
                    ? keyCheckBox
                    : keyCheckBox.filter(
                        (item) =>
                          item !== 'deliveryCompany' &&
                          item !== 'deliveryIndividual'
                      )
                  ).map((item, index) => (
                    <div key={index}>
                      <CcCheckbox
                        disabled={
                          this.state.editable
                            ? this.state.dialogData &&
                              (this.state.dialogData.fieldKey === 'phone' ||
                                this.state.dialogData.fieldKey ===
                                  'firstName' ||
                                this.state.dialogData.fieldKey === 'lastName')
                              ? true
                              : false
                            : true
                        }
                        onChange={(e) =>
                          this.handleActiveChange(item, e.target.checked)
                        }
                        checked={this.state.dialogData[item]}
                        text={I18n.t(`driverFields.${item}`)}
                      />
                    </div>
                  ))}
                </div>
                <FormGroup style={{ marginBottom: 0 }}>
                  <Form.Label>
                    {`(`}
                    <span className="require">*</span>
                    {`) `}
                    Required fields
                  </Form.Label>
                </FormGroup>
              </Col>
            </Modal.Body>
            <Modal.Footer>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={this.editDriverFields}
                  >
                    <Translate value="city.Save" />
                  </Button>
                ) : (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={() => this.setState({ editable: true })}
                  >
                    <Translate value="driverFields.Edit" />
                  </Button>
                )
              ) : null}
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="driverFields.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

DriverFields.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DriverFields);
