import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../settings.scss";
import {
  Tab,
  Tabs,
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  Button
} from "react-bootstrap";
import IntlTelInputApp from "../../../components/intlTelInputCustome/IntlTelInputApp";
import { Translate, I18n } from "react-redux-i18n";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";

import VoipSettings from './VoipSettings';

class TwilioSettings extends Component {
  constructor() {
    super();
    this.state = {
      isChanged: false,
      formData: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }
  componentDidMount() {
    this.props.settingActions
      .getVoiceSMSSettingDetail(this.props.auth.selectedFleet.fleetId)
      .then(data => {
        if (data.res) {
          this.setState({ formData: data.res });
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("message.Load_message_and_sms_fail")
            );
          }
        }
      });
  }
  handleInputChange(key, e) {
    this.state.isChanged = true;
    this.state.formData[key] = e.target.value;
    this.setState({ formData: this.state.formData });
  }
  handlePhoneChange(key, status, value, countryData, number, id) {
    this.state.isChanged = true;
    this.state.formData[key] = number;
    this.setState({ formData: this.state.formData });
  }
  handleSaveClick() {
    this.setState({ isChanged: false });
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions
      .saveVoiceSMSSetting(this.state.formData)
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification(
            "success",
            I18n.t("message.Update_message_and_sms_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("message.Update_message_and_sms_fail")
            );
          }
          this.setState({ isChanged: true });
        }
      });
  };

  render() {
    const editable = this.props.permissions && !this.props.permissions.actions;
    return (
      <div className="voice-sms-content seting-voice-sms">
        <Row>
          <Col md={6} sm={12}>
            {/* <FormGroupTitle className="bold">TWILIO</FormGroupTitle> */}
            <FormGroupTitle>
              <Translate value="voiceSMSSetting.ACCOUNT_SETTINGS" />
            </FormGroupTitle>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.Account_SID" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                onChange={e => {
                  this.handleInputChange("accountSID", e);
                }}
                value={
                  this.state.formData ? this.state.formData.accountSID : ""
                }
                disabled={editable}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.Auth_Token" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                onChange={e => {
                  this.handleInputChange("authToken", e);
                }}
                value={this.state.formData ? this.state.formData.authToken : ""}
                disabled={editable}
              />
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.Application_ID" />
              </Form.Label>
              <FormControl
                type="text"
                className={"form-custom"}
                onChange={e => {
                  this.handleInputChange("applicationId", e);
                }}
                value={
                  this.state.formData ? this.state.formData.applicationId : ""
                }
                disabled={editable}
              />
            </FormGroup>

            <FormGroupTitle>
              <Translate value="voiceSMSSetting.SMS_SETTINGS" />
            </FormGroupTitle>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.SMS_number_1" />
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={["intl-tel-input", "form-control form-custom"]}
                  utilsScript={"libphonenumber.js"}
                  value={
                    this.state.formData ? this.state.formData.phoneNumber : ""
                  }
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    this.handlePhoneChange(
                      "phoneNumber",
                      status,
                      value,
                      countryData,
                      number,
                      id
                    );
                  }}
                  disabled={editable}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.SMS_number_2" />
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={["intl-tel-input", "form-control form-custom"]}
                  utilsScript={"libphonenumber.js"}
                  value={
                    this.state.formData ? this.state.formData.phoneNumber2 : ""
                  }
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    this.handlePhoneChange(
                      "phoneNumber2",
                      status,
                      value,
                      countryData,
                      number,
                      id
                    );
                  }}
                  disabled={editable}
                />
              </FormGroup>
            </FormGroup>

            <FormGroupTitle>
              <Translate value="voiceSMSSetting.MASKED_PHONE_SETTINGS" />
            </FormGroupTitle>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.Phone_number" />
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={["intl-tel-input", "form-control form-custom"]}
                  utilsScript={"libphonenumber.js"}
                  value={
                    this.state.formData
                      ? this.state.formData.maskedPhoneNumber[0]
                      : ""
                  }
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    this.handlePhoneChange(
                      "maskedPhoneNumber",
                      status,
                      value,
                      countryData,
                      number,
                      id
                    );
                  }}
                  disabled={editable}
                />
              </FormGroup>
            </FormGroup>
            <FormGroupTitle>
              <Translate value="voiceSMSSetting.SMS_MARKETING_SETTINGS" />
            </FormGroupTitle>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.Phone_number" />
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={["intl-tel-input", "form-control form-custom"]}
                  utilsScript={"libphonenumber.js"}
                  value={
                    this.state.formData
                      ? this.state.formData.smsMarketingNumber
                      : ""
                  }
                  onPhoneNumberChange={(
                    status,
                    value,
                    countryData,
                    number,
                    id
                  ) => {
                    this.handlePhoneChange(
                      "smsMarketingNumber",
                      status,
                      value,
                      countryData,
                      number,
                      id
                    );
                  }}
                  disabled={editable}
                />
              </FormGroup>
            </FormGroup>
            <FormGroup className="form-inline">
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className={"btn-save mt-sm"}
                  disabled={!this.state.isChanged}
                  onClick={this.handleSaveClick}
                >
                  <Translate value="voiceSMSSetting.Save" />
                </Button>
              ) : (
                  ""
                )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  };

}

TwilioSettings.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwilioSettings);
