import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Form,
  Image,
  InputGroup
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';
import { Validator, ValidCase } from '../../../validator';
import destinationIcon from '../../../../assets/images/icons/destination2.svg';
import pickupIcon from '../../../../assets/images/icons/pickup2.svg';
import { textboxNumberHelper } from "../../../../utils/commonFunctions";
import { transformDataBooking } from '../../../../utils/transformData';

class CompleteWithPaymentByAffiliateBooking extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      tollFeeHydra: 0,
      parkingFeeHydra: 0,
      gasFeeHydra: 0,
    };
  }

  handleCompleteTollFeesChange = (e) => {
    const value = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    this.setState({
      tollFeeHydra: value
    })
  }

  handleCompleteParkingFeesChange = (e) => {
    const value = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    this.setState({
      parkingFeeHydra: value
    })
  }

  handleCompleteGasFeesChange = (e) => {
    const value = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    this.setState({
      gasFeeHydra: value
    })
  }

  getTextSubmitButton = paymentComplete => (paymentComplete.isPending
    ? I18n.t('bookingdetail.Complete_booking')
    : I18n.t('bookingdetail.Pay_here'));

  render() {
    const {
      showConfirmComplete,
      completeWithPaymentCloseButtonClick,
      completeWithPaymentButtonClick,
      data,
      paymentMethod,
      paymentStep,
      locationPickUp,
      extraDestination,
      locationDestination,
      paymentComplete,
      handleCompletePaymentTypeChange,
      isShowmDispatcherCard,
      completeWithPaymentOkButtonClick,
      disableCompletePaymentNextClick,
      fareSettings = {},
      farmIn,
      isHydraBooking
    } = this.props;
    const isDMCCorpBooking = data.bookFrom === 'dmc' || data.bookFrom == 'corp';
    const fareSell = _.get(data, 'request.estimate.fare.qupSellPrice', 0)
    const transformBooking = transformDataBooking({
      booking: data,
      commonData: this.props.commonData,
      keys: ['status']
    })
    return (
      <Modal
        show={showConfirmComplete}
        backdrop="static"
        dialogClassName="confirm-dialog"
        onHide={completeWithPaymentCloseButtonClick}
        className="complete-with-payment-modal"
      >
        {showConfirmComplete ? (
          <Form onSubmit={completeWithPaymentButtonClick}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="bookingdetail.Booking_detail" className="white-text" />
                <span className="white-text">:</span>
                {` #${this.props.data.bookId}`}
                <span className="white-text"> -</span>
                {' '}
                <Translate
                  className="white-text text-tranform-none"
                  value={`statusDisplay.${transformBooking.status}`}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="newbooking-form complete-with-payment md-payment">
              <div
                className={'fill payment-dialog step1'}
              >
                <FormGroup className="location-info">
                  <Image src={pickupIcon} />
                  <span>{locationPickUp.address}</span>
                </FormGroup>
                <FormGroup className="location-info">
                  <Image src={destinationIcon} />
                  <span>
                    {extraDestination
                      ? extraDestination.address
                      : locationDestination
                        ? locationDestination.address
                        : ''}
                  </span>
                </FormGroup>
                {fareSettings.fare && fareSettings.fare.tollFeeActive && (
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Toll_fees" />
                    </Form.Label>
                    <div className="input-with-validator">
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {data.currencySymbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className="form-custom"
                            onChange={this.handleCompleteTollFeesChange}
                            value={this.state.tollFeeHydra}
                            onBlur={e => {
                              textboxNumberHelper.onBlurHandle(e, e => {
                                this.handleCompleteTollFeesChange(e);
                              });
                            }}
                            onFocus={e => {
                              textboxNumberHelper.onfocusHandle(e, e => {
                                this.handleCompleteTollFeesChange(e);
                              });
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </FormGroup>
                )}
                {fareSettings.fare && fareSettings.fare.parkingFeeActive && (
                  <FormGroup>
                    <Form.Label>
                      <Translate value="generalSetting.parkingFee" />
                    </Form.Label>
                    <div className="input-with-validator">
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {data.currencySymbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className="form-custom"
                            onChange={this.handleCompleteParkingFeesChange}
                            value={this.state.parkingFeeHydra}
                            onBlur={e => {
                              textboxNumberHelper.onBlurHandle(e, e => {
                                this.handleCompleteParkingFeesChange(e);
                              });
                            }}
                            onFocus={e => {
                              textboxNumberHelper.onfocusHandle(e, e => {
                                this.handleCompleteParkingFeesChange(e);
                              });
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </FormGroup>
                )}
                {fareSettings.fare && fareSettings.fare.gasFeeActive && (
                  <FormGroup>
                    <Form.Label>
                      <Translate value="generalSetting.gasFee" />
                    </Form.Label>
                    <div className="input-with-validator">
                      <FormGroup className="qup-input-group">
                        <InputGroup className="single-addon-left">
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {data.currencySymbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className="form-custom"
                            onChange={this.handleCompleteGasFeesChange}
                            value={this.state.gasFeeHydra}
                            onBlur={e => {
                              textboxNumberHelper.onBlurHandle(e, e => {
                                this.handleCompleteGasFeesChange(e);
                              });
                            }}
                            onFocus={e => {
                              textboxNumberHelper.onfocusHandle(e, e => {
                                this.handleCompleteGasFeesChange(e);
                              });
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </FormGroup>
                )}
                {
                  !farmIn &&
                  <FormGroup>
                    <Form.Label>
                      <Translate value="bookingdetail.Payment_Type" />
                    </Form.Label>
                    <FormControl
                      as="select"
                      type="text"
                      className="form-custom"
                      value={paymentComplete.paymentType}
                      onChange={handleCompletePaymentTypeChange}
                      disabled={isDMCCorpBooking || farmIn}
                    >
                      {isDMCCorpBooking ? ( // direct invoicing
                        data.request.paymentType == 5 ? (
                          <option value={5}>{I18n.t('General.patmentMethod_5')}</option>
                        ) : (
                          <option value={12}>{I18n.t('General.patmentMethod_12')}</option>
                        )
                      ) : (
                        <>
                          <option value={2}>{I18n.t('bookingdetail.Personal_Card')}</option>
                          <option value={5}>{I18n.t('General.patmentMethod_5')}</option>
                        </>
                      )}
                      {isShowmDispatcherCard
                        && paymentMethod.type3rd.map(pm => {
                          if (pm.value == 1 || pm.value == data.request.paymentType) {
                            return (
                              <option key={pm.value} value={pm.value}>
                                {I18n.t(`General.patmentMethod_${pm.value}`)}
                              </option>
                            );
                          }
                        })}
                    </FormControl>
                    <Validator className="red">
                      <ValidCase
                        valid={paymentStep == 0 || paymentComplete.paymentType != 8}
                        message={I18n.t('bookingdetail.QR_Code_method_not_allow_error_message')}
                      />
                    </Validator>
                  </FormGroup>
                }
              </div>
            </Modal.Body>
            <Modal.Footer className="pt0">
              <Button
                className="btn-save"
                disabled={
                  disableCompletePaymentNextClick
                  || (!locationDestination || locationDestination.lat == 0)
                  || (paymentStep != 0 && paymentComplete.paymentType == 8)
                }
                onClick={e => completeWithPaymentOkButtonClick(e, this.state.tollFeeHydra, this.state.parkingFeeHydra, this.state.gasFeeHydra)}
              >
                {this.getTextSubmitButton(paymentComplete)}
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

export default CompleteWithPaymentByAffiliateBooking;
