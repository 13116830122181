import React, { Component } from 'react';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    Button,
    Modal
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";


class AddEditCarColor extends Component {
    constructor() {
        super();
        this.state = {}
    }

    render() {
        const { dialogData, editable, dialogChanged, permissions, closeDialogForm, handleInputChange, saveDialogForm, handleMenuClick } = this.props;

        return (
            <Modal show={true} backdrop={true} dialogClassName={"modal-max-600"} onHide={closeDialogForm}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {dialogData._id ? (
                            editable ? (
                                <Translate value="carSetting.EDIT_CAR_COLOR" />
                            ) : (
                                    <Translate value="carSetting.DETAIL_CAR_COLOR" />
                                )
                        ) : (
                                <Translate value="carSetting.CREATE_CAR_COLOR" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <Form.Label>
                            <Translate value="carSetting.Car_Color" />{" "}
                            <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                            type="text"
                            className={"form-custom"}
                            onChange={e => {
                                handleInputChange("name", e);
                            }}
                            value={
                                dialogData ? dialogData.name : ""
                            }
                            placeholder={I18n.t("carSetting.Car_Color")}
                            disabled={!editable}
                            autoFocus={true}
                        />

                    </FormGroup>
                    <Form.Label>
                        (<span className="require">*</span>):{" "}
                        <Translate value="city.Required_fields" />
                    </Form.Label>
                </Modal.Body>
                <Modal.Footer>
                    {!permissions ||
                        permissions.actions ? (
                            editable ? (
                                <Button
                                    className={"btn-save mr-md"}
                                    onClick={saveDialogForm}
                                    disabled={
                                        !dialogData.name.trim() ||
                                        !dialogChanged
                                    }
                                >
                                    <Translate value="carSetting.Save" />
                                </Button>
                            ) : (
                                    <Button
                                        className={"btn-save mr-md"}
                                        onClick={e => {
                                            handleMenuClick("changeStatus", e);
                                        }}
                                    >
                                        <Translate value="carSetting.Edit" />
                                    </Button>
                                )
                        ) : null
                    }
                    <Button className={"btn-cancel"} onClick={closeDialogForm}>
                        <Translate value="city.Cancel" />
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


}

export default AddEditCarColor