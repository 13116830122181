import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Row,
  Col
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { Validation, textboxNumberHelper } from '../../../../utils/commonFunctions';
import { Validator, ValidCase, WarningCase } from '../../../../components/validator';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class ReservationPolicyGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleChangePolicy = e => {
    var policy = e.target.value;
    this.props.handleUpdateFormData('generalSetting.reservation.policy', policy);
    if (policy === 'quarterBefore'){
        this.props.handleUpdateFormData('generalSetting.reservation.expected', 'before');
        this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', 15);
    } else if (policy === 'halfBefore'){
        this.props.handleUpdateFormData('generalSetting.reservation.expected', 'before');
        this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', 30);
    } else if (policy === 'quarterAfter'){
        this.props.handleUpdateFormData('generalSetting.reservation.expected', 'after');
        this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', 15);
    } else if (policy === 'halfAfter'){
        this.props.handleUpdateFormData('generalSetting.reservation.expected', 'after');
        this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', 30);
    } else if (policy === 'custom'){
        this.props.handleUpdateFormData('generalSetting.reservation.expected', 'before');
        this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', 0);
    }
  };

  handleExpectedChange = e => {
    this.props.handleUpdateFormData('generalSetting.reservation.expected', e.target.value);
  };

  handleMinutesToArriveChange = e => {
    this.props.handleUpdateFormData('generalSetting.reservation.minutesToArrive', e.target.value);
  };

  render() {
    const {fleet, formData, ValidatorCallback, editable } = this.props;
    return (
      <div className="group-general">
        <FormGroupTitle className="title mb-sm">
          <Translate value="generalSetting.RESERVATION_POLICY" />
        </FormGroupTitle>
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.default_expected_pickup_time" />
            <QuestionCircleTooltip
                  text={<Translate value="generalSetting.default_expected_pickup_time_hint" />}
                />
          </Form.Label>
          <select
            className="form-control form-custom"
            value={fleet.generalSetting.reservation.policy}
            onChange={this.handleChangePolicy}
            disabled={editable}
          >
            <option value={'samePickup'}>{I18n.t('generalSetting.same_original_pickup_time')}</option>
            <option value={'quarterBefore'}>{I18n.t('generalSetting.quarter_before')}</option>
            <option value={'halfBefore'}>{I18n.t('generalSetting.half_before')}</option>
            <option value={'quarterAfter'}>{I18n.t('generalSetting.quarter_after')}</option>
            <option value={'halfAfter'}>{I18n.t('generalSetting.half_after')}</option>
            <option value={'custom'}>{I18n.t('generalSetting.custom')}</option>
          </select>
        </FormGroup>
        {fleet.generalSetting.reservation.policy === 'custom' &&
          <FormGroup
            className={
              !this.props.isSubmited
                ? null
                : this.props.valid.minutesToArrive ===
                  false
                  ? 'error'
                  : null
            }
          >
          <FormGroup className="qup-input-group">
              <InputGroup className="single-addon-right">
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={this.handleMinutesToArriveChange}
                  onBlur={e => {
                      textboxNumberHelper.onBlurHandle(
                      e,
                      this.handleMinutesToArriveChange
                      );
                  }}
                  onFocus={e => {
                      textboxNumberHelper.onfocusHandle(
                      e,
                      this.handleMinutesToArriveChange
                      );
                  }}
                  value={fleet.generalSetting.reservation.minutesToArrive}
                  disabled={editable}
                />
                <FormControl
                  as="select"
                  className="input-group-addon-custom form-custom"
                  value={
                      fleet.generalSetting.reservation.expected
                      ? fleet.generalSetting.reservation.expected : 'before'
                  }
                  onChange={this.handleExpectedChange}
                  disabled={editable}
                  >
                  <option value={'before'}>{I18n.t('generalSetting.min_before')}</option>
                  <option value={'after'}>{I18n.t('generalSetting.min_after')}</option>
                </FormControl>
              </InputGroup>
            </FormGroup>
            <Validator
              id="minutesToArrive"
              callback={ValidatorCallback}
            >
              <ValidCase
                valid={
                  !Validation.isStringEmpty(
                    fleet.generalSetting.reservation.minutesToArrive
                  )
                }
                message={I18n.t(
                  'messages.commonMessages.Required_field'
                )}
                hide={!this.props.isSubmited}
              />
              <ValidCase
                hide={!this.props.isSubmited}
                valid={Validation.isInteger(
                  fleet.generalSetting.reservation.minutesToArrive
                )}
                message={I18n.t(
                  'messages.commonMessages.must_be_integer'
                )}
              />
              <ValidCase
                hide={!this.props.isSubmited}
                valid={Validation.isGreaterOrEqual(
                  fleet.generalSetting.reservation.minutesToArrive,
                  0
                )}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
              />
            </Validator>
          </FormGroup>
        }
      </div>
    );
  }
}

export default ReservationPolicyGroup;
