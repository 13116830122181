import React, { Fragment } from 'react';
import _ from 'lodash';
import { FormGroup, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import DrawPolygonModal from './DrawPolygonModal';
import './PickupAreaList.scss';
import AreaNameWithColor from './AreaNameWithColor';
import { QueueColor } from './colorData';

export default class PickupAreaList extends React.PureComponent {
  state = {
    pickupActiveIndex: -1,
    showConfirm: false
  };

  handleEditDriverArea = () => {
    this.setState({
      showDrawDialog: true,
      showCheckInArea: true,
      showPickupArea: false
    });
  };

  handleAddPickupArea = () => {
    this.setState({
      showDrawDialog: true,
      showPickupArea: true
    });
  };

  handleEditPickupArea = (index) => {
    this.setState({
      showDrawDialog: true,
      showPickupArea: true,
      showCheckInArea: false,
      pickupActiveIndex: index
    });
  };

  handleRemovePickupArea = (index) => {
    this.setState({
      showConfirm: true,
      confirmIndex: index
    });
  }

  handleCloseDialog = () => {
    this.setState({
      showDrawDialog: false,
      showPickupArea: false,
      showCheckInArea: false,
      pickupActiveIndex: -1
    });
  };

  closeDialogConfirm = () => {
    this.setState({
      showConfirm: false,
      confirmIndex: -1
    });
  };

  confirmDelelePickupArea = () => {
    const { onSavePolygons, pickUpAreas, driverArea } = this.props;
    const { confirmIndex } = this.state;
    pickUpAreas.splice(confirmIndex, 1);
    onSavePolygons({
      pickUpAreas: [...pickUpAreas],
      driverArea
    });
    this.closeDialogConfirm();
  }

  onBoundPickupArea = (pickUpId) => {
    if (this.props.onBoundPickUpArea) {
      this.props.onBoundPickUpArea(pickUpId);
    }
  }

  onCheckInActive = () => {
    if (this.props.onCheckInActive) {
      this.props.onCheckInActive();
    }
  }

  render() {
    const { pickUpAreas = [], driverArea, editable, activePickUpId, activeCheckIn } = this.props;
    return (
      <Fragment>
        <FormGroup className="pr-lg">
          <Row>
            <Col xs={8}>
              <Form.Label>
                <AreaNameWithColor
                  onClick={this.onCheckInActive}
                  active={activeCheckIn}
                  color={QueueColor.checkIn.fill}
                  borderColor={QueueColor.checkIn.border}
                  name={I18n.t('queuingArea.driver_checkin_area')}
                />
              </Form.Label>
            </Col>
            <Col xs={4} className="text-right">
              <Button className="btn-link p0 btn-header" onClick={this.handleEditDriverArea} disabled={!editable}>
                { _.get(driverArea, 'coordinates[0]', []).length ? (
                  <Translate
                    className="text-active cursor-pointer"
                    value="queuingArea.Edit"
                  />
                ) : (
                  <Translate
                    className="text-active cursor-pointer"
                    value="queuingArea.Add"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="pickupAreasList pr-lg">
          <Row>
            <Col xs={8}>
              <Form.Label>
                <AreaNameWithColor
                  color={QueueColor.pickUp.fill}
                  borderColor={QueueColor.pickUp.border}
                  name={I18n.t('queuingArea.pax_pickup_area')}
                />
              </Form.Label>
            </Col>
            <Col xs={4} className="text-right">
              {pickUpAreas.length < 20 ? (
                <Button className="btn-link p0 btn-header" onClick={this.handleAddPickupArea} disabled={!editable}>
                  <Translate
                    className="text-active cursor-pointer"
                    value="queuingArea.Add"
                  />
                </Button>
              ) : null}
            </Col>
          </Row>
          {pickUpAreas.map((area, index) => {
            return (
              <Row>
                <Col xs={8}>
                  <Form.Label className="pl-xl">
                    <AreaNameWithColor
                      active={activePickUpId === area._id}
                      onClick={() => this.onBoundPickupArea(area._id)}
                      color={QueueColor.pickUp.fill}
                      borderColor={QueueColor.pickUp.border}
                      name={`${I18n.t('queuingArea.pickup_area')} ${index + 1}`}
                    />
                  </Form.Label>
                </Col>
                <Col xs={4} className="text-right">
                  <Button className="btn-link p0 btn-header" onClick={() => this.handleEditPickupArea(index)} disabled={!editable}>
                    <Translate
                      className="text-active cursor-pointer"
                      value="queuingArea.Edit"
                    />
                  </Button>
                  { pickUpAreas.length > 1 ? (
                    <Fragment>
                      <span className="ml">|</span>
                      <Button className="btn-link p0 btn-header"  onClick={() => this.handleRemovePickupArea(index)} disabled={!editable}>
                        <Translate
                          className="text-remove cursor-pointer ml"
                          value="queuingArea.Delete"
                        />
                      </Button>
                    </Fragment>
                  ) : null }
                </Col>
              </Row>
            );
          })}
        </FormGroup>
        {this.state.showDrawDialog ? (
          <DrawPolygonModal
            onCloseDialog={this.handleCloseDialog}
            showPickupArea={this.state.showPickupArea}
            showCheckInArea={this.state.showCheckInArea}
            pickupActiveIndex={this.state.pickupActiveIndex}
            {...this.props}
          />
        ) : null}

        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogConfirm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="queuingArea.delete_pickup_area" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogConfirm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="queuingArea.delete_pickup_area_confirm" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-save mr-md"
                onClick={this.confirmDelelePickupArea}
              >
                <Translate value="queuingArea.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.closeDialogConfirm}>
                <Translate value="queuingArea.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </Fragment>
    );
  }
}
