import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Promise from 'bluebird';
import _ from 'lodash';
import '../settings.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Tab,
  Tabs,
  Modal,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  MultiSelectCommonFunc,
} from '../../../utils/commonFunctions';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';
import { DefaultCurenciesNumberInputField } from '../../../constants/commondata';
import RadioButton from '../../../components/radioButton/radio';
import DateTime from '../../../components/dateTime/DateTime';
import AddPromoCodeModal from './Components/AddPromoCodeModal';
import { BsPlus } from 'react-icons/bs';

const DriverGetType = {
  Money: 'money',
  Settlement: 'settlement',
};

class DriverToPax extends Component {
  constructor() {
    super();
    this.state = {
      formData: {
        firstPackage: {
          driver: {
            type: DriverGetType.Money, // value = money/settlement/both
            money: [],
            settlement: {
              value: 0,
              maximum: [],
            },
          },
          passenger: {
            promoCode: '',
            promoId: '',
          },
          promos: [],
        },
        secondPackage: {
          totalBook: 3, // Tổng số booking được áp dụng, tương đương cột "To" trong phần setting
          driver: {
            type: DriverGetType.Money, // value = money/settlement/both
            money: [],
            settlement: {
              value: 0,
              maximum: [],
            },
          },
        },
        thirdPackage: {
          isLimited: false,
          totalBook: 0, // Tổng số booking được áp dụng, nếu có giới hạn số lượng booking, tương đương cột "Limited" trong phần setting
          driver: {
            type: DriverGetType.Money, // value = money/settlement/both
            money: [],
            settlement: {
              value: 0,
              maximum: [],
            },
          },
        },
        isExpired: false,
        expiryDate: null,
        isLimitInvitation: false,
        isDistributeIncentiveToDriver: false,
        distributeIncentiveToDriver: {
          period: 6,
          startDate: moment(),
          endDate: moment().add(6, 'months'),
        },
        limitedUser: 0,
        isActive: false,
        paidByDriver: false,
      },
      promotionCodeList: [],
      valid: {},
      isSubmited: false,
      showDialogPromoCode: false,
      showConfirmToDelete: false,
      editable: false,
      dialogChanged: false,
      dialogData: null,
      sourceToSelect: undefined,
      sourceUsed: [],
      isShowHistory: false,
    };
  }

  componentDidMount() {
    const { auth, settingActions } = this.props;

    if (
      auth.selectedFleet.fleetConfigs &&
      auth.selectedFleet.fleetConfigs.webLink
    ) {
      this.setState({
        sourceToSelect: auth.selectedFleet.fleetConfigs.webLink,
      });
    }

    Promise.all([
      settingActions.fetchPromotionCodeForReferral({
        fleetId: auth.selectedFleet.fleetId,
      }),
      settingActions.getReferralDriverToPaxDetails({
        fleetId: auth.selectedFleet.fleetId,
      }),
    ])
      .spread((promoData, referralData) => {
        const promotionCodeList = promoData.res ? promoData.res : [];
        let formData = referralData.res
          ? Object.assign({}, referralData.res)
          : Object.assign({}, this.state.formData);

        if (formData.firstPackage.promos.length > 0) {
          formData.firstPackage.promos = formData.firstPackage.promos.filter(
            (item) => {
              return promotionCodeList.find(({ _id }) => item.promoId === _id);
            }
          );
        }

        let sourceUsed = [];
        if (formData.firstPackage.promos) {
          _.forEach(formData.firstPackage.promos, (promo) => {
            if (promo.autoApplyOnWeb.sourceApply.length > 0) {
              sourceUsed = sourceUsed.concat(promo.autoApplyOnWeb.sourceApply);
            }
          });
        }

        if (formData.expiryDate) {
          const { selectedFleet } = auth;
          const stringDate = momentTz(formData.expiryDate)
            .tz(selectedFleet.timezone)
            .format('YYYY-MM-DD');
          formData.expiryDate = moment(stringDate);
        }
        if (formData.distributeIncentiveToDriver !== undefined) {
          if (formData.distributeIncentiveToDriver.startDate) {
            const { selectedFleet } = auth;
            const stringDate = momentTz(
              formData.distributeIncentiveToDriver.startDate
            )
              .tz(selectedFleet.timezone)
              .format('YYYY-MM-DD hh:mm:ss');
            formData.distributeIncentiveToDriver.startDate = moment(stringDate);
          }
          if (formData.distributeIncentiveToDriver.endDate) {
            const { selectedFleet } = auth;
            const stringDate = momentTz(
              formData.distributeIncentiveToDriver.endDate
            )
              .tz(selectedFleet.timezone)
              .format('YYYY-MM-DD hh:mm:ss');
            formData.distributeIncentiveToDriver.endDate = moment(stringDate);
          }
        } else {
          const distributeIncentiveToDriver = {
            period: 6,
            startDate: moment(),
            endDate: moment().add(6, 'months'),
          };
          formData.distributeIncentiveToDriver = distributeIncentiveToDriver;
        }
        const firstPackageDriver = formData.firstPackage.driver;
        const secondPackageDriver = formData.secondPackage.driver;
        const thirdPackageDriver = formData.thirdPackage.driver;

        if (
          !firstPackageDriver.money.length &&
          !firstPackageDriver.settlement.maximum.length
        ) {
          formData = this.initDefaultDriverReferValue(formData, 'firstPackage');
        }

        if (
          !secondPackageDriver.money.length &&
          !secondPackageDriver.settlement.maximum.length
        ) {
          formData = this.initDefaultDriverReferValue(
            formData,
            'secondPackage'
          );
        }

        if (
          !thirdPackageDriver.money.length &&
          !thirdPackageDriver.settlement.maximum.length
        ) {
          formData = this.initDefaultDriverReferValue(formData, 'thirdPackage');
        }

        if (
          !formData.secondPackage.totalBook ||
          formData.secondPackage.totalBook < 3
        ) {
          formData.secondPackage.totalBook = 3;
        }

        this.setState({ promotionCodeList, formData, sourceUsed });
      })
      .catch((error) => {
        let formData = Object.assign({}, this.state.formData);

        formData = this.initDefaultDriverReferValue(formData, 'firstPackage');
        formData = this.initDefaultDriverReferValue(formData, 'secondPackage');
        formData = this.initDefaultDriverReferValue(formData, 'thirdPackage');

        this.setState({ formData });
      });
  }

  initDefaultDriverReferValue = (formData, packageType) => {
    const { auth } = this.props;

    formData[packageType].driver.money = [];
    formData[packageType].driver.settlement.maximum = [];
    formData[packageType].driver.settlement.value = 0;

    _.forEach(auth.selectedFleet.currencies, (c) => {
      const obj = {
        value: 0,
        currencyISO: c.iso,
      };
      formData[packageType].driver.money.push(obj);
      formData[packageType].driver.settlement.maximum.push(obj);
    });

    return formData;
  };

  handleSaveClick = () => {
    this.setState({ isSubmited: true });

    if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
      this.props.loadingBarActions.showLoadingSpiner();
      const form = _.extend({}, this.state.formData);
      form.fleetId = this.props.auth.selectedFleet.fleetId;
      const promoList = this.state.promotionCodeList;
      const customerPromo = form.firstPackage.passenger;
      const distributeIncentiveToDriver = form.distributeIncentiveToDriver;
      const promoObj = promoList.find(
        (obj) => obj._id == customerPromo.promoId
      );
      if (promoObj) {
        form.firstPackage.passenger.promoCode = promoObj.promotionCode;
        form.firstPackage.passenger.value = promoObj.value;
        form.firstPackage.passenger.type = promoObj.type;
        form.firstPackage.passenger.valueByCurrencies =
          promoObj.valueByCurrencies;
      } else {
        form.firstPackage.passenger.promoCode = '';
        form.firstPackage.passenger.promoId = '';
      }

      if (form.isExpired) {
        const { selectedFleet } = this.props.auth;
        const stringDate = form.expiryDate.format('YYYY-MM-DD');
        form.expiryDate = momentTz
          .tz(stringDate, selectedFleet.timezone)
          .endOf('day')
          .toDate();
      } else {
        form.expiryDate = null;
      }
      if (form.isDistributeIncentiveToDriver) {
        form.distributeIncentiveToDriver.period =
          distributeIncentiveToDriver.period;
        if (distributeIncentiveToDriver.startDate) {
          const { selectedFleet } = this.props.auth;
          const stringDate =
            moment(distributeIncentiveToDriver.startDate).format('YYYY-MM-DD') +
            ' 00:00:00';
          form.distributeIncentiveToDriver.startDate = momentTz
            .tz(stringDate, selectedFleet.timezone)
            .startOf('day')
            .toDate();
        } else {
          form.distributeIncentiveToDriver.startDate = null;
        }
        if (distributeIncentiveToDriver.endDate) {
          const { selectedFleet } = this.props.auth;
          const stringDate =
            moment(distributeIncentiveToDriver.endDate).format('YYYY-MM-DD') +
            ' 23:59:59';
          form.distributeIncentiveToDriver.endDate = momentTz
            .tz(stringDate, selectedFleet.timezone)
            .endOf('day')
            .toDate();
        } else {
          form.distributeIncentiveToDriver.endDate = null;
        }
      }
      if (!form.isLimitInvitation) {
        form.limitedUser = 0;
      }

      if (!form.thirdPackage.isLimited) {
        form.thirdPackage.totalBook = 0;
      }

      this.props.settingActions
        .saveReferralDriverToPaxDetails(form)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.res) {
            this.context.notification(
              'success',
              I18n.t('referral.refferal_update_successfully')
            );
            this.setState({ isSubmited: false });
          }
        });
    }
  };

  changeDriverFirstPackageType = (e) => {
    let formData = Object.assign({}, this.state.formData);
    formData = this.initDefaultDriverReferValue(formData, 'firstPackage');
    formData.firstPackage.driver.type = e.target.value;
    this.setState({ formData });
  };

  onInputChange = (key, e, { type, min, max } = {}) => {
    const { formData } = this.state;
    let { value } = e.target;
    if (type) {
      if (type == 'int') {
        value = parseInt(value);
      }
      if (type == 'number') {
        value = parseFloat(value);
      }
      if (min && value < min) {
        value = min;
      }
    }

    _.set(formData, key, value);
    if (key === 'secondPackage.driver.type') {
      // reset money value
      formData.secondPackage.driver.money = [];
      formData.secondPackage.driver.settlement = {
        value: 0,
        maximum: [],
      };
    }
    if (key === 'thirdPackage.driver.type') {
      // reset money value
      formData.thirdPackage.driver.money = [];
      formData.thirdPackage.driver.settlement = {
        value: 0,
        maximum: [],
      };
    }
    if (key === 'distributeIncentiveToDriver.period') {
      // reset money value
      const endDate = formData.distributeIncentiveToDriver.startDate;
      formData.distributeIncentiveToDriver.endDate = moment(endDate).add(
        value,
        'months'
      );
    }
    this.setState({
      formData,
    });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  renderCurrencies = (key, value) => {
    /* console.log({ key, value, l: value[0] }); */
    const idCreator = key.replace('.', '_');
    const { isSubmited = false } = this.state || {};
    const { permissions: { actions: hasPermission = false } = {} } =
      this.props || {};
    return this.props.auth.selectedFleet.multiCurrencies ? (
      <div className="input-with-validator">
        <Tabs
          id={`currencies-tabs-${idCreator}`}
          className="currencies-tabs"
          defaultActiveKey={this.props.auth.selectedFleet.currencies[0].iso}
          animation={false}
          onSelect={this.tabSelectHandle}
        >
          {this.props.auth.selectedFleet.currencies.map((c) => {
            const currentValue = value
              ? value.filter((d) => d.currencyISO == c.iso)[0]
              : null;
            return (
              <Tab
                className="currencies-tab-item"
                eventKey={c.iso}
                title={c.iso}
                key={c.iso}
              >
                <FormGroup className="qup-input-group">
                  <InputGroup className="single-addon-left">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{c.symbol}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className="form-custom number-no-spin"
                      type="number"
                      min={0}
                      onChange={(e) => {
                        this.handleMultiCurrenciesChange(key, c.iso, e);
                      }}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(e, (e) => {
                          this.handleMultiCurrenciesChange(key, c.iso, e);
                        });
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(e, (e) => {
                          this.handleMultiCurrenciesChange(key, c.iso, e);
                        });
                      }}
                      value={currentValue ? currentValue.value : ''}
                      onWheel={(e) => e.preventDefault()}
                      disabled={!hasPermission}
                    />
                  </InputGroup>
                </FormGroup>
              </Tab>
            );
          })}
        </Tabs>
        <WarningFeeInput
          message={I18n.t('message.warningInputChangeOver')}
          className="text-soft-warning"
          disabled={!hasPermission}
          isReset={isSubmited}
          value={
            !value || !value.length
              ? null
              : value.map((fees) => {
                  const { currencyISO, value: vl } = fees;
                  return {
                    [currencyISO]: vl,
                  };
                })
          }
          timeDelay={500}
          typeOfCheck="higherOfLower"
        />
      </div>
    ) : (
      <div className="input-with-validator">
        <FormGroup className="qup-input-group">
          <InputGroup className="single-addon-left">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {this.props.auth.selectedFleet.currencies[0].symbol}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="number"
              min={0}
              className="form-custom"
              onChange={(e) => {
                this.handleMultiCurrenciesChange(
                  key,
                  this.props.auth.selectedFleet.currencies[0].iso,
                  e
                );
              }}
              onBlur={(e) => {
                textboxNumberHelper.onBlurHandle(e, (e) => {
                  this.handleMultiCurrenciesChange(
                    key,
                    this.props.auth.selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              onFocus={(e) => {
                textboxNumberHelper.onfocusHandle(e, (e) => {
                  this.handleMultiCurrenciesChange(
                    key,
                    this.props.auth.selectedFleet.currencies[0].iso,
                    e
                  );
                });
              }}
              value={value && value[0] ? value[0].value : 0}
              onWheel={(e) => e.preventDefault()}
              disabled={!hasPermission}
            />
          </InputGroup>
        </FormGroup>
        <WarningFeeInput
          message={I18n.t('message.warningInputChangeOver')}
          className="text-soft-warning"
          disabled={!hasPermission}
          isReset={isSubmited}
          value={value && value[0] ? value[0].value : 0}
          timeDelay={500}
          typeOfCheck="higherOfLower"
        />
      </div>
    );
  };

  onChangeLimit = (key, e, validKey) => {
    const { formData, valid } = this.state;
    let { value } = e.target;
    if (value == '0') value = false;
    if (value == '1') value = true;
    if (validKey && !value) {
      _.set(valid, validKey, true);
    }
    _.set(formData, key, value);
    this.setState({
      formData,
      valid: {},
    });
  };
  handleShowHistoryChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        isShowHistory: e.target.checked,
      },
    });
  };
  onChangeValidToDate = (e) => {
    const { formData } = this.state;
    formData.expiryDate = e;
    this.setState({
      formData,
    });
  };
  onChangePromoPaidByOption = (e) => {
    let { value } = e.target;
    if (value == '0') value = false;
    if (value == '1') value = true;
    this.setState({
      formData: {
        ...this.state.formData,
        paidByDriver: value,
      },
    });
  };
  onChangeEndDate = (e) => {
    const { formData } = this.state;
    formData.distributeIncentiveToDriver.endDate = e;
    this.setState({
      formData,
    });
  };
  validDate = (current) => {
    const yesterday = moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };
  handleMultiCurrenciesChange(key, iso, e) {
    let item = _.get(this.state.formData, key, []);
    const { formData } = this.state;
    let { value } = e.target;
    if (value < 0) value = 0;
    if (item == []) {
      item = [
        {
          value,
          currencyISO: iso,
        },
      ];
    } else {
      const findCurrency = item.findIndex((c) => c.currencyISO == iso);
      if (findCurrency >= 0) {
        item[findCurrency].value = value;
      } else {
        item.push({
          value,
          currencyISO: iso,
        });
      }
    }
    _.set(formData, key, item);
    this.setState({ formData });
  }

  handleAddButtonClick = () => {
    let object = {
      promoCode: '',
      promoId: '',
      isSendInbox: false,
      isDefault: false,
      autoApplyOnWeb: {
        enable: false,
        sourceApply: [],
      },
    };
    this.setState({
      showDialogPromoCode: true,
      editable: true,
      dialogData: object,
    });
  };

  handleCloseDialogForm = () => {
    this.setState({
      showDialogPromoCode: false,
      showConfirmToDelete: false,
      editable: false,
      dialogChanged: false,
      dialogData: null,
    });
  };

  handleSelectPromoCode = (e) => {
    const { dialogData } = this.state;
    let { value } = e.target;
    this.setState({
      dialogData: {
        ...dialogData,
        promoId: value,
      },
      dialogChanged: true,
    });
  };

  handleChangePrompCodeOption = (key, e) => {
    const { dialogData } = this.state;
    let { checked } = e.target;
    if (key === 'isDefault' && checked) {
      this.setState({
        dialogData: {
          ...dialogData,
          isSendInbox: checked,
          isDefault: checked,
        },
        dialogChanged: true,
      });
    } else if (key === 'autoApplyOnWeb') {
      this.setState({
        dialogData: {
          ...dialogData,
          autoApplyOnWeb: {
            sourceApply: checked ? dialogData.autoApplyOnWeb.sourceApply : [],
            enable: checked,
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          [key]: checked,
        },
        dialogChanged: true,
      });
    }
  };

  handleChangeSourceApply = (e) => {
    const { dialogData, sourceToSelect } = this.state;
    let value = MultiSelectCommonFunc.getSelectValues(e);
    let sourceApply = [];
    value.forEach((e) => {
      var data = sourceToSelect.find((s) => s.key === e);
      sourceApply.push(data);
    });
    this.setState({
      dialogData: {
        ...dialogData,
        autoApplyOnWeb: {
          ...dialogData.autoApplyOnWeb,
          sourceApply,
        },
      },
      dialogChanged: true,
    });
  };

  handleClickMenuPromosList = (key, data) => {
    switch (key) {
      case 'View': {
        this.setState({
          showDialogPromoCode: true,
          dialogData: data,
          editable: false,
          dialogChanged: false,
        });
        break;
      }
      case 'Edit': {
        this.setState({
          showDialogPromoCode: true,
          dialogData: data,
          editable: true,
          dialogChanged: false,
        });
        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirmToDelete: true, dialogData: data });
        break;
      }
    }
  };

  handleSaveDialogForm = () => {
    this.setState({ isSubmited: true });
    const { selectedFleet } = this.props.auth;
    const { promotionCodeList, dialogData, formData } = this.state;
    const promoObj = promotionCodeList.find(
      (item) => item._id == dialogData.promoId
    );

    let promos = formData.firstPackage.promos || [];
    let promoUpdated = {};
    let index = -1;

    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }

    if (promoObj) {
      if (dialogData.promoCode) {
        // Edit promotion code
        index = promos.findIndex((x) => x.promoId === dialogData.promoId);
        if (index !== -1) {
          promos.splice(index, 1);
        }
      }
      promoUpdated.promoCode = promoObj.promotionCode;
      promoUpdated.promoId = promoObj._id;
      promoUpdated.type = promoObj.type;
      promoUpdated.valueByCurrencies = promoObj.valueByCurrencies;
      promoUpdated.isSendInbox = dialogData.isSendInbox;
      promoUpdated.isDefault = dialogData.isDefault;
      promoUpdated.autoApplyOnWeb = dialogData.autoApplyOnWeb;

      // Only 1 promotion code is default
      if (dialogData.isDefault) {
        promos = promos.map((promo) => {
          if (promo.isDefault) {
            promo.isDefault = false;
          }
          return promo;
        });
        promos.unshift(promoUpdated);
      } else {
        if (index === -1) {
          promos.push(promoUpdated);
        } else {
          promos.splice(index, 0, promoUpdated);
        }
      }
    }
    this.props.loadingBarActions.showLoadingSpiner();
    const requestBody = {
      fleetId: selectedFleet.fleetId,
      promos,
    };
    this.props.settingActions
      .updatePromoCodeReferralDriver(requestBody)
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res) {
          let sourceUsed = [];
          if (promos) {
            _.forEach(promos, (promo) => {
              if (promo.autoApplyOnWeb.sourceApply.length > 0) {
                sourceUsed = sourceUsed.concat(
                  promo.autoApplyOnWeb.sourceApply
                );
              }
            });
          }
          this.context.notification(
            'success',
            I18n.t('referral.refferal_update_successfully')
          );
          this.setState({
            formData: {
              ...formData,
              firstPackage: {
                ...formData.firstPackage,
                promos,
              },
            },
            isSubmited: false,
            showDialogPromoCode: false,
            showConfirmToDelete: false,
            editable: false,
            dialogChanged: false,
            dialogData: null,
            sourceUsed,
          });
        }
      });
  };

  handleClickDeletePromoCode = () => {
    const { selectedFleet } = this.props.auth;
    const { formData, dialogData } = this.state;
    let promos = formData.firstPackage.promos || [];
    this.props.loadingBarActions.showLoadingSpiner();
    const requestBody = {
      fleetId: selectedFleet.fleetId,
      promoId: dialogData.promoId,
    };
    if (promos.findIndex((x) => x.promoId === dialogData.promoId) !== -1) {
      promos.splice(
        promos.findIndex((x) => x.promoId === dialogData.promoId),
        1
      );
    }
    this.props.settingActions
      .removePromoCodeReferralDriver(requestBody)
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res) {
          let sourceUsed = [];
          if (promos) {
            _.forEach(promos, (promo) => {
              if (promo.autoApplyOnWeb.sourceApply.length > 0) {
                sourceUsed = sourceUsed.concat(
                  promo.autoApplyOnWeb.sourceApply
                );
              }
            });
          }
          this.context.notification(
            'success',
            I18n.t('referral.refferal_update_successfully')
          );
          this.setState({
            formData: {
              ...formData,
              firstPackage: {
                ...formData.firstPackage,
                promos,
              },
            },
            isSubmited: false,
            showDialogPromoCode: false,
            showConfirmToDelete: false,
            editable: false,
            dialogChanged: false,
            dialogData: null,
            sourceUsed,
          });
        }
      });
  };

  render() {
    const { formData, valid, isSubmited, promotionCodeList, sourceToSelect } =
      this.state;
    const editable = this.props.permissions && !this.props.permissions.actions;
    const timeZone = this.props.auth.selectedFleet.timezone;
    const { permissions: { actions: hasPermission = false } = {} } =
      this.props || {};
    const promos = formData.firstPackage.promos || [];

    let promosAvailable = [];
    if (promotionCodeList.length > 0) {
      promosAvailable = promotionCodeList.filter((item) => {
        return !promos.find(({ promoId }) => item._id === promoId);
      });
    }

    return (
      <div
        className="content referral-form custom-form overflow-auto"
        style={{ paddingBottom: 140 }}
      >
        <Row>
          <Col xs={12} md={6} className="driver-to-pax-form">
            <FormGroupTitle className="ph pv0 mb-lg">
              <Translate value="referral.driver_refers_to_pax" />
            </FormGroupTitle>
            <div className="form-group-info">
              <FormGroupTitle className="default-font-size">
                <Translate value="referral.first_package" />
              </FormGroupTitle>
              <FormGroup>
                <Form.Label>
                  <Translate value="referral.driver_will_get" />
                </Form.Label>
                <select
                  className="form-control form-custom"
                  value={formData.firstPackage.driver.type}
                  onChange={this.changeDriverFirstPackageType}
                  disabled={!hasPermission}
                >
                  <option value={DriverGetType.Money}>
                    {I18n.t('referral.amount')}
                  </option>
                  <option value={DriverGetType.Settlement}>
                    {I18n.t('referral.settlement')}
                  </option>
                </select>
              </FormGroup>

              {formData.firstPackage.driver.type == DriverGetType.Money
                ? this.renderCurrencies(
                    'firstPackage.driver.money',
                    formData.firstPackage.driver.money
                  )
                : ''}

              {formData.firstPackage.driver.type == DriverGetType.Settlement ? (
                <div>
                  <div className="input-with-validator">
                    <FormGroup className="qup-input-group">
                      <InputGroup className="single-addon-right">
                        <FormControl
                          className="form-custom"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.onInputChange(
                              'firstPackage.driver.settlement.value',
                              e,
                              {
                                type: 'number',
                                min: 0,
                              }
                            )
                          }
                          value={formData.firstPackage.driver.settlement.value}
                          onWheel={(e) => e.preventDefault()}
                          disabled={!hasPermission}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </FormGroup>
                    <WarningFeeInput
                      message={I18n.t('message.warningInputChangeOver')}
                      className="text-soft-warning"
                      disabled={!hasPermission}
                      isReset={isSubmited}
                      value={formData.firstPackage.driver.settlement.value}
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                    />
                  </div>
                  <Form.Label>
                    <Translate value="referral.maximum" />
                  </Form.Label>
                  {this.renderCurrencies(
                    'firstPackage.driver.settlement.maximum',
                    formData.firstPackage.driver.settlement.maximum
                  )}
                </div>
              ) : (
                ''
              )}

              <FormGroup>
                <Form.Label>
                  <Translate value="referral.pax_will_get" />
                  {'\u00A0'}-{'\u00A0'}
                  <Translate value="referral.promotion_code" />
                </Form.Label>
                <div className="model-list-container">
                  <table className="table table-no-pagination cc-table-striped">
                    <thead>
                      <tr className="">
                        <th>
                          <Translate value="referral.promoCode" />
                        </th>
                        <th>
                          <Translate value="referral.isSendInbox" />
                        </th>
                        <th>
                          <Translate value="referral.isDefaultCode" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="referral.isDefaultCode_hint" />
                            }
                          />
                        </th>
                        {sourceToSelect && (
                          <th>
                            <Translate value="referral.autoApplyOnWeb" />
                          </th>
                        )}
                        <th>
                          <Translate value="referral.Actions" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.firstPackage.promos
                        ? formData.firstPackage.promos.map((m) => {
                            let promoCode = '';
                            if (m.promoId) {
                              let promoData = promotionCodeList.filter(
                                (item) => item._id === m.promoId
                              );
                              promoCode = `${
                                promoData[0].promotionCode
                              } (${moment(promoData[0].validFrom)
                                .tz(timeZone)
                                .format('DD/MM/YYYY')} - ${moment(
                                promoData[0].validTo
                              )
                                .tz(timeZone)
                                .format('DD/MM/YYYY')})`;
                            }
                            return (
                              <tr key={m.promoId}>
                                <td>{promoCode}</td>
                                <td>
                                  {m.isSendInbox ? (
                                    <i className="fa fa-check fa-2x activateIcon" />
                                  ) : (
                                    ''
                                  )}
                                </td>
                                <td>
                                  {m.isDefault ? (
                                    <i className="fa fa-check fa-2x activateIcon" />
                                  ) : (
                                    ''
                                  )}
                                </td>
                                {sourceToSelect && (
                                  <td>
                                    {m.autoApplyOnWeb.enable
                                      ? m.autoApplyOnWeb.sourceApply.map(
                                          (item) => (
                                            <span key={item.sourceId}>
                                              {item.key};{' '}
                                            </span>
                                          )
                                        )
                                      : ''}
                                  </td>
                                )}
                                <td>
                                  {hasPermission ? (
                                    <div className="car-model-action">
                                      {formData._id ? (
                                        <i
                                          className="fa fa-edit fa-2x"
                                          onClick={(e) => {
                                            this.handleClickMenuPromosList(
                                              'Edit',
                                              m
                                            );
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}
                                      <i
                                        className="fa fa-trash fa-2x"
                                        onClick={(e) => {
                                          this.handleClickMenuPromosList(
                                            'Delete',
                                            m
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : ''}
                    </tbody>
                  </table>
                  <ButtonToolbar className="text-center header-button-group">
                    <Button
                      className={'btn-header text-add-header ml0'}
                      onClick={this.handleAddButtonClick}
                    >
                      {' '}
                      <BsPlus />
                      <Translate value="referral.add_promoCode" />
                    </Button>
                  </ButtonToolbar>
                  <FormGroup>
                    <div
                      className="radio-button-group"
                      style={{ marginTop: '20px' }}
                    >
                      <Form.Label>
                        <Translate value="referral.promoPaidBy" /> {'\u00A0'}
                        <span className="danger"> *</span>
                      </Form.Label>
                      <RadioButton
                        text={I18n.t('referral.fleet')}
                        value="0"
                        onChange={this.onChangePromoPaidByOption}
                        name="paidByDriver"
                        id="paidByDriver_0"
                        checked={!formData.paidByDriver}
                        disabled={!hasPermission}
                      />
                      <RadioButton
                        text={I18n.t('referral.driver')}
                        value="1"
                        onChange={this.onChangePromoPaidByOption}
                        name="paidByDriver"
                        id="paidByDriver_1"
                        checked={formData.paidByDriver}
                        disabled={!hasPermission}
                      />
                    </div>
                  </FormGroup>
                </div>
              </FormGroup>
            </div>
            <div className="form-group-info">
              <FormGroupTitle className="default-font-size">
                <Translate value="referral.second_package" />
              </FormGroupTitle>

              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="referral.from" />
                    </Form.Label>
                    <FormControl className="form-custom" value={2} disabled />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup
                    className={
                      !isSubmited
                        ? null
                        : valid.secondPackageTotalBook
                        ? null
                        : 'error'
                    }
                  >
                    <Form.Label>
                      <Translate value="referral.to" />
                      {'\u00A0'}
                      <span className="danger">*</span>
                    </Form.Label>
                    <FormControl
                      className="form-custom number-no-spin"
                      type="number"
                      min={0}
                      onChange={(e) =>
                        this.onInputChange('secondPackage.totalBook', e, {
                          type: 'number',
                          min: 0,
                        })
                      }
                      value={formData.secondPackage.totalBook}
                      onWheel={(e) => e.preventDefault()}
                      disabled={!hasPermission}
                    />
                    <Validator
                      id="secondPackageTotalBook"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        hide={!isSubmited}
                        valid={parseFloat(formData.secondPackage.totalBook) > 2}
                        message={I18n.t(
                          'messages.commonMessages.greater_than'
                        ).format('2')}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup>
                <Form.Label>
                  <Translate value="referral.driver_will_get" />
                </Form.Label>
                <select
                  className="form-control form-custom"
                  value={formData.secondPackage.driver.type}
                  onChange={(e) =>
                    this.onInputChange('secondPackage.driver.type', e)
                  }
                  disabled={!hasPermission}
                >
                  <option value={DriverGetType.Money}>
                    {I18n.t('referral.amount')}
                  </option>
                  <option value={DriverGetType.Settlement}>
                    {I18n.t('referral.settlement')}
                  </option>
                </select>
              </FormGroup>

              {formData.secondPackage.driver.type == DriverGetType.Money
                ? this.renderCurrencies(
                    'secondPackage.driver.money',
                    formData.secondPackage.driver.money
                  )
                : ''}

              {formData.secondPackage.driver.type ==
              DriverGetType.Settlement ? (
                <div>
                  <div className="input-with-validator">
                    <FormGroup className="qup-input-group">
                      <InputGroup className="single-addon-right">
                        <FormControl
                          className="form-custom number-no-spin"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.onInputChange(
                              'secondPackage.driver.settlement.value',
                              e,
                              {
                                type: 'number',
                                min: 0,
                              }
                            )
                          }
                          value={formData.secondPackage.driver.settlement.value}
                          onWheel={(e) => e.preventDefault()}
                          disabled={!hasPermission}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </FormGroup>
                    <WarningFeeInput
                      message={I18n.t('message.warningInputChangeOver')}
                      className="text-soft-warning"
                      disabled={!hasPermission}
                      isReset={isSubmited}
                      value={formData.secondPackage.driver.settlement.value}
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                    />
                  </div>
                  <Form.Label>
                    <Translate value="referral.maximum" />
                  </Form.Label>
                  {this.renderCurrencies(
                    'secondPackage.driver.settlement.maximum',
                    formData.secondPackage.driver.settlement.maximum
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group-info">
              <FormGroupTitle className="default-font-size">
                <Translate value="referral.After_2nd_package" />
              </FormGroupTitle>
              <FormGroup>
                <Form.Label>
                  <Translate value="referral.from_end_value_of_above_package" />
                  {'\u00A0'}
                </Form.Label>
                <div className="radio-button-group">
                  <Form.Label>
                    <Translate value="referral.to" />
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('referral.unlimited')}
                    value="0"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'thirdPackage.isLimited',
                        e,
                        'thirdPackageTotalBook'
                      )
                    }
                    name="after2ndpackagelimit"
                    id="after2ndpackagelimit_0"
                    checked={!formData.thirdPackage.isLimited}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('referral.limited')}
                    value="1"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'thirdPackage.isLimited',
                        e,
                        'thirdPackageTotalBook'
                      )
                    }
                    name="after2ndpackagelimit"
                    id="after2ndpackagelimit_1"
                    checked={formData.thirdPackage.isLimited}
                    disabled={!hasPermission}
                  />
                </div>
              </FormGroup>
              {formData.thirdPackage.isLimited ? (
                <FormGroup
                  className={
                    !isSubmited
                      ? null
                      : valid.thirdPackageTotalBook
                      ? null
                      : 'error'
                  }
                >
                  <FormControl
                    className="form-custom number-no-spin"
                    type="number"
                    min={0}
                    onChange={(e) =>
                      this.onInputChange('thirdPackage.totalBook', e, {
                        type: 'number',
                        min: 0,
                      })
                    }
                    value={formData.thirdPackage.totalBook}
                    onWheel={(e) => e.preventDefault()}
                    disabled={!hasPermission}
                  />
                  <Validator
                    id="thirdPackageTotalBook"
                    callback={this.ValidatorCallback}
                  >
                    <ValidCase
                      hide={!isSubmited}
                      valid={Validation.isGreaterThan(
                        parseInt(formData.thirdPackage.totalBook),
                        parseInt(formData.secondPackage.totalBook)
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_than'
                      ).format(formData.secondPackage.totalBook)}
                    />
                  </Validator>
                </FormGroup>
              ) : (
                ''
              )}
              <FormGroup>
                <Form.Label>
                  <Translate value="referral.driver_will_get" />
                </Form.Label>
                <select
                  className="form-control form-custom"
                  value={formData.thirdPackage.driver.type}
                  onChange={(e) =>
                    this.onInputChange('thirdPackage.driver.type', e)
                  }
                  disabled={!hasPermission}
                >
                  <option value={DriverGetType.Money}>
                    {I18n.t('referral.amount')}
                  </option>
                  <option value={DriverGetType.Settlement}>
                    {I18n.t('referral.settlement')}
                  </option>
                </select>
              </FormGroup>

              {formData.thirdPackage.driver.type == DriverGetType.Money
                ? this.renderCurrencies(
                    'thirdPackage.driver.money',
                    formData.thirdPackage.driver.money
                  )
                : ''}

              {formData.thirdPackage.driver.type == DriverGetType.Settlement ? (
                <div>
                  <div className="input-with-validator">
                    <FormGroup className="qup-input-group">
                      <InputGroup className="single-addon-right">
                        <FormControl
                          className="form-custom number-no-spin"
                          type="number"
                          min={0}
                          onChange={(e) =>
                            this.onInputChange(
                              'thirdPackage.driver.settlement.value',
                              e,
                              {
                                type: 'number',
                                min: 0,
                              }
                            )
                          }
                          value={formData.thirdPackage.driver.settlement.value}
                          onWheel={(e) => e.preventDefault()}
                          disabled={!hasPermission}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </FormGroup>
                    <WarningFeeInput
                      message={I18n.t('message.warningInputChangeOver')}
                      className="text-soft-warning"
                      disabled={!hasPermission}
                      isReset={isSubmited}
                      value={formData.thirdPackage.driver.settlement.value}
                      timeDelay={500}
                      typeOfCheck="higherOfLower"
                    />
                  </div>
                  <Form.Label>
                    <Translate value="referral.maximum" />
                  </Form.Label>
                  {this.renderCurrencies(
                    'thirdPackage.driver.settlement.maximum',
                    formData.thirdPackage.driver.settlement.maximum
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group-info no-background">
              <FormGroup>
                <div className="radio-button-group">
                  <Form.Label>
                    <Translate value="referral.Expiration_date" />
                    {'\u00A0'}
                    <span className="danger"> *</span>
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('referral.Never')}
                    value="0"
                    onChange={(e) => this.onChangeLimit('isExpired', e)}
                    name="isExpired"
                    id="isExpired_0"
                    checked={!formData.isExpired}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('referral.Customized_date')}
                    value="1"
                    onChange={(e) => this.onChangeLimit('isExpired', e)}
                    name="isExpired"
                    id="isExpired_1"
                    checked={formData.isExpired}
                    disabled={!hasPermission}
                  />
                </div>
              </FormGroup>
              {formData.isExpired ? (
                <FormGroup
                  className={
                    !isSubmited ? null : valid.expiryDate ? null : 'error'
                  }
                >
                  <Form.Label>
                    <Translate value="referral.Valid_to" />
                  </Form.Label>
                  <DateTime
                    className="form-custom"
                    onChange={this.onChangeValidToDate}
                    value={formData.expiryDate}
                    isValidDate={this.validDate}
                    closeOnSelect
                    viewMode="days"
                    timeFormat={false}
                    disabled={!hasPermission}
                  />
                  <Validator id="expiryDate" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={!Validation.isStringEmpty(formData.expiryDate)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
              ) : (
                ''
              )}
              <FormGroup>
                <div className="radio-button-group">
                  <Form.Label>
                    <Translate value="referral.Invitation_limit" />
                    {'\u00A0'}
                    <span className="danger"> *</span>
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('referral.unlimited')}
                    value="0"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'isLimitInvitation',
                        e,
                        'isLimitInvitation'
                      )
                    }
                    name="isLimitInvitation"
                    id="isLimitInvitation_0"
                    checked={!formData.isLimitInvitation}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('referral.limited')}
                    value="1"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'isLimitInvitation',
                        e,
                        'isLimitInvitation'
                      )
                    }
                    name="isLimitInvitation"
                    id="isLimitInvitation_1"
                    checked={formData.isLimitInvitation}
                    disabled={!hasPermission}
                  />
                </div>
              </FormGroup>
              {formData.isLimitInvitation ? (
                <FormGroup
                  className={
                    !isSubmited
                      ? null
                      : valid.isLimitInvitation
                      ? null
                      : 'error'
                  }
                >
                  <FormControl
                    className="form-custom number-no-spin"
                    type="number"
                    min={0}
                    onChange={(e) =>
                      this.onInputChange('limitedUser', e, {
                        type: 'number',
                        min: 0,
                      })
                    }
                    value={formData.limitedUser}
                    onWheel={(e) => e.preventDefault()}
                    disabled={!hasPermission}
                  />
                  <Validator
                    id="isLimitInvitation"
                    callback={this.ValidatorCallback}
                  >
                    <ValidCase
                      hide={!isSubmited}
                      valid={
                        !formData.isLimitInvitation ||
                        Validation.isGreaterThan(formData.limitedUser, 0)
                      }
                      message={I18n.t('messages.commonMessages.greater_than_0')}
                    />
                  </Validator>
                </FormGroup>
              ) : (
                ''
              )}
              <FormGroup>
                <div className="radio-button-group">
                  <Form.Label>
                    <Translate value="referral.distributeIncentiveToDriver" />
                    {'\u00A0'}
                    <span className="danger"> *</span>
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('referral.Immediate')}
                    value="0"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'isDistributeIncentiveToDriver',
                        e,
                        'isDistributeIncentiveToDriver'
                      )
                    }
                    name="isDistributeIncentiveToDriver"
                    id="isDistributeIncentiveToDriver_0"
                    checked={!formData.isDistributeIncentiveToDriver}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('referral.Periodic')}
                    value="1"
                    onChange={(e) =>
                      this.onChangeLimit(
                        'isDistributeIncentiveToDriver',
                        e,
                        'isDistributeIncentiveToDriver'
                      )
                    }
                    name="isDistributeIncentiveToDriver"
                    id="isDistributeIncentiveToDriver_1"
                    checked={formData.isDistributeIncentiveToDriver}
                    disabled={!hasPermission}
                  />
                </div>
              </FormGroup>
              {formData.isDistributeIncentiveToDriver ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="referral.Period" />
                  </Form.Label>
                  <select
                    className="form-control form-custom"
                    value={formData.distributeIncentiveToDriver.period}
                    onChange={(e) =>
                      this.onInputChange(
                        'distributeIncentiveToDriver.period',
                        e
                      )
                    }
                    disabled={!hasPermission}
                  >
                    <option value="1">{I18n.t('referral.oneMonth')}</option>
                    <option value="3">{I18n.t('referral.threeMonth')}</option>
                    <option value="6">{I18n.t('referral.sixMonth')}</option>
                  </select>
                </FormGroup>
              ) : (
                ''
              )}
              {formData.isDistributeIncentiveToDriver ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="referral.startDate" />
                  </Form.Label>
                  <Form.Label>
                    <span style={{ padding: 20 }}>
                      {moment(
                        formData.distributeIncentiveToDriver.startDate
                      ).format('MMMM DD, YYYY')}
                    </span>
                  </Form.Label>
                </FormGroup>
              ) : (
                ''
              )}
              <CcCheckbox
                checked={formData.isShowHistory}
                onChange={this.handleShowHistoryChange}
                text={I18n.t('Sidebar.Settings.Show_referal_history')}
                disabled={!hasPermission}
              />
              {formData.isDistributeIncentiveToDriver ? (
                <FormGroup
                  className={
                    !isSubmited ? null : valid.endDate ? null : 'error'
                  }
                >
                  <Form.Label>
                    <Translate value="referral.nextDistributionDate" />
                  </Form.Label>
                  <DateTime
                    className="form-custom"
                    onChange={this.onChangeEndDate}
                    value={formData.distributeIncentiveToDriver.endDate}
                    closeOnSelect
                    viewMode="days"
                    isValidDate={this.validDate}
                    timeFormat={false}
                    disabled={!hasPermission}
                  />
                  <Validator id="endDate" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!isSubmited}
                      valid={
                        !Validation.isStringEmpty(
                          formData.distributeIncentiveToDriver.endDate
                        )
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
              ) : (
                ''
              )}
              <FormGroup>
                <div className="radio-button-group">
                  <Form.Label>
                    <Translate value="referral.Status" /> {'\u00A0'}
                    <span className="danger"> *</span>
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('referral.Active')}
                    value="1"
                    onChange={(e) => this.onChangeLimit('isActive', e)}
                    name="isActive"
                    id="isActive_0"
                    checked={formData.isActive}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('referral.Inactive')}
                    value="0"
                    onChange={(e) => this.onChangeLimit('isActive', e)}
                    name="isActive"
                    id="isActive_1"
                    checked={!formData.isActive}
                    disabled={!hasPermission}
                  />
                </div>
              </FormGroup>
              <Form.Label>
                (<span className="danger">*</span>
                )
                <Translate value="referral.Required_fields" />
              </Form.Label>
              <Form.Label>
                <Translate value="referral.note" />
              </Form.Label>
              {hasPermission && (
                <ButtonToolbar className="text-center center mt-md">
                  <Button className="btn-save" onClick={this.handleSaveClick}>
                    <Translate value="dispatchSetting.Save" />
                  </Button>
                </ButtonToolbar>
              )}
            </div>
          </Col>
        </Row>

        {this.state.showDialogPromoCode && this.state.dialogData ? (
          <AddPromoCodeModal
            dialogData={this.state.dialogData}
            promosAvailable={promosAvailable}
            promotionCodeList={promotionCodeList}
            selectedFleet={this.props.auth.selectedFleet}
            hasPermission={hasPermission}
            editable={this.state.editable}
            dialogChanged={this.state.dialogChanged}
            sourceToSelect={this.state.sourceToSelect}
            sourceUsed={this.state.sourceUsed}
            isSubmited={this.state.isSubmited}
            valid={this.state.valid}
            validatorCallback={this.ValidatorCallback}
            closeDialogForm={this.handleCloseDialogForm}
            handleSelectPromoCode={this.handleSelectPromoCode}
            handleSaveDialogForm={this.handleSaveDialogForm}
            handleClickMenuPromosList={this.handleClickMenuPromosList}
            handleChangePrompCodeOption={this.handleChangePrompCodeOption}
            handleChangeSourceApply={this.handleChangeSourceApply}
          />
        ) : null}

        {this.state.showConfirmToDelete ? (
          <Modal onHide={this.handleCloseDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="referral.DELETE_PROMO_CODE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.handleCloseDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="referral.DELETE_CONFIRM_PROMO_CODE" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.handleClickDeletePromoCode}
              >
                <Translate value="referral.Yes" />
              </Button>
              <Button
                className={'btn-cancel'}
                onClick={this.handleCloseDialogForm}
              >
                <Translate value="referral.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    );
  }
}

DriverToPax.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DriverToPax);
