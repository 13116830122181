import React, { Component } from 'react';
import {
  Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { socketDispatchApi } from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';
class IframeAddCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideBody: false
    }
  }
  
  componentDidMount() {
    socketDispatchApi.remove(socketConfigs.receive.booking.add_card_senangpay_result);
    socketDispatchApi.on(
      socketConfigs.receive.booking.add_card_senangpay_result,
      (data) => {
        if(data?.returnCode === 200) {
          this.props.closeModal()
        } else {
          this.context.notification(
            'error',
            I18n.t('General.ServerError')
            );
        }
      }
    );
    this.setState({
      hideBody: this.props.auth?.selectedFleet?.creditConfig?.configGateway?.gateway === 'ECPay'
    })
  }

  render() {
    const { isShowModal, urlIframe, closeModal, title } = this.props;
    const { hideBody } = this.state
    let titleTxt = title ? title : I18n.t('bookingdetail.Add_new_card')
    if(hideBody) titleTxt = 'Waiting for payment...'
    return (
      <Modal show={isShowModal} dialogClassName="modal-max-600" className="modal-payfactor-cc" onHide={closeModal}>
        <Modal.Header closeButton>
          {
            <Modal.Title>
              {titleTxt}
            </Modal.Title>
          }
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">×</span>
            </button>
        </Modal.Header>
        {
          !hideBody && (
            <Modal.Body className="modal-payfactor-body">
              <iframe 
                src={urlIframe}
                width="100%" 
                height="100%"
                className="ifPayCC"
              >
            </iframe>
            </Modal.Body>
          )
        }
      </Modal>
    );
  }
}

IframeAddCard.contextTypes = {
  notification: PropTypes.func
};

IframeAddCard.propTypes = {
  isShowModal: PropTypes.bool,
  closeModal: PropTypes.func,
  urlIframe: PropTypes.string
};

export default IframeAddCard;
