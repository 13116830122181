import React, { useState, useEffect } from 'react'
import { Translate, I18n } from 'react-redux-i18n';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl
} from "react-bootstrap";
import { Validation } from '../../utils/commonFunctions';
import { Validator, ValidCase } from '../validator';
import { HourList, MinList } from "../../constants/commondata";
import './dateModeBooking.scss';
import moment from 'moment'
import _ from 'lodash'

let isSelectedTime = false

const SelectTimeRecurring = ({
  recurringTime, 
  format24Hour,
  setRecurringTime,
  isSubmited,
  ValidatorCallback,
  valid,
  title,
  jobType,
  isBookDetails,
  bookingDetailOld
}) => {
  const [timeMode, setTimeMode] = useState('AM')

  useEffect(() => {
    if(isBookDetails) {
      let format = moment(recurringTime, 'HH:mm').format('A')
      if(format) setTimeMode(format)
    }
  }, [isBookDetails]);
  
  let hourValue = ''
  let minuteValue = ''
  if(recurringTime) {
    if(format24Hour) {
      hourValue = moment(`${recurringTime}`, 'HH:mm').format("HH")
    } else {
      hourValue = moment(`${recurringTime}`, 'HH:mm').format("hh")
    }
    minuteValue = moment(`${recurringTime}`, 'HH:mm').format("mm")
  }

  const onChangeHour = (e) => {
    isSelectedTime = true
    let hourTime = e.target && e.target.value
    let newRecurringTime = ''
    if(format24Hour) {
      newRecurringTime = moment(`${hourTime}:${minuteValue}`, 'HH:mm')
      .format('HH:mm')
    } else {
      if (parseInt(hourTime) === 12) {
        hourTime = timeMode === "AM" ? "0" : "12";
      }
      newRecurringTime = moment(`${hourTime}:${minuteValue} ${timeMode}`, `hh:mm A`)
      .format('HH:mm')
    }
    setRecurringTime(newRecurringTime)
  }

  const onChangeMin = (e) => {
    isSelectedTime = true
    let timeMinute = e.target && e.target.value
    let newRecurringTime = ''
    if(format24Hour) {
      newRecurringTime = moment(`${recurringTime}`, 'HH:mm')
      .set('minute', timeMinute)
      .format('HH:mm')
    } else {
      newRecurringTime = moment(`${recurringTime} ${timeMode}`, `hh:mm A`)
      .set('minute', timeMinute)
      .format('HH:mm')
    }
    setRecurringTime(newRecurringTime)
  }

  const onChangeTimeMode = (e) => {
    let value = e.target && e.target.value || 'AM'
    if(!format24Hour && recurringTime != '') {
      let recurringTimeFormat12h = moment(`${recurringTime}`, 'HH:mm').format('hh:mm')
      let newRecurringTime = moment(`${recurringTimeFormat12h} ${value}`, 'hh:mm A').format('HH:mm')
      setRecurringTime(newRecurringTime)
    }
    setTimeMode(value)
  }

  if(isBookDetails) isSelectedTime = true

  return (
    <>
      <Form.Label className="titleTime">
        {title}
      </Form.Label>
      <Row>
        {
          isBookDetails && 
          bookingDetailOld && (
            <Col xs={12}>
              <FormGroup>
                <FormControl
                  type="text"
                  value={_.get(bookingDetailOld, 'recurring.date', new Date())}
                  className="form-custom"
                  maxLength={100}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          )
        }
        <Col sm={format24Hour ? 6 : 4} md={format24Hour ? 6 : 12} lg={format24Hour ? 6 : 4}>
          <FormGroup 
            className={!isSubmited ? null : valid.recurringTime == false ? 'error' : null}
          >
            <select
              className="form-control form-custom"
              onChange={onChangeHour}
              value={isSelectedTime ? hourValue : ''}
            >
              <option key={"default"} value="" disabled>
                {I18n.t("newbooking.hh")}
              </option>
              {HourList.map(obj => {
                return ((!format24Hour && parseInt(obj) <= 12 && parseInt(obj) > 0) || format24Hour) ? (
                  <option key={obj} value={obj}>
                    {obj}
                  </option>
                ) : (<React.Fragment key={obj}></React.Fragment>);
              })}
            </select>
            <Validator
              callback={ValidatorCallback}
              id="recurringTime"
            >
              <ValidCase
                valid={!Validation.isStringEmpty(recurringTime)}
                hide
                message={I18n.t('newbooking.recurringTime')}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col sm={format24Hour ? 6 : 4} md={format24Hour ? 6 : 12} lg={format24Hour ? 6 : 4}>
          <FormGroup 
            className={!isSubmited ? null : valid.recurringTime == false ? 'error' : null}
          >
            <select
              className="form-control form-custom"
              value={isSelectedTime ? minuteValue : ''}
              onChange={onChangeMin}
            >
              <option key={"default"} value="" disabled>
                {I18n.t("newbooking.mm")}
              </option>
              {MinList.map(obj => {
                return (
                  <option key={obj} value={obj}>
                    {obj}
                  </option>
                );
              })}
            </select>
            <Validator
              callback={ValidatorCallback}
              id="recurringTime"
            >
              <ValidCase
                valid={!Validation.isStringEmpty(recurringTime)}
                hide
                message={I18n.t('newbooking.recurringTime')}
              />
            </Validator>
          </FormGroup>
        </Col>
        {
          !format24Hour &&
          <Col sm={4} md={12} lg={4}>
            <select
              className="form-control form-custom"
              placeholder={I18n.t("newbooking.mm")}
              value={timeMode}
              onChange={onChangeTimeMode}
              // disabled={recurringTimeDisabled}
            >
              <option value={I18n.t("generalSetting.AM")}>{I18n.t("generalSetting.AM")}</option>
              <option value={I18n.t("generalSetting.PM")}>{I18n.t("generalSetting.PM")}</option>
            </select>
          </Col>
        }
      </Row>
    </>
  )
}

SelectTimeRecurring.defaultProps = {
  title: '',
  recurringTime: "",
  setRecurringTime: () => {},
  format24Hour: false
}

export default SelectTimeRecurring