import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
    FormGroup,
    FormControl,
    Form
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import { CCLiteCommonFunc, Validation } from './../../../utils/commonFunctions';
import {
    getDriver,
} from './../../../actions/driverAction';

import { Validator, ValidCase } from './../../../components/validator';

import * as queuingAreaActions from './../../../actions/queuingAreaActions';
import * as loadingBarActions from './../../../actions/loadingBarActions';
import { autocompleteDriver } from './../../../actions/messageAction';

class ModifyDriverOrder extends Component {
    constructor(props) {
        super(props);
        const { driverNeedChange = {} } = props;
        const driverList = driverNeedChange ? [
            driverNeedChange
        ] : [];
        const { value: driverId, label } = driverNeedChange || {};
        const order = !driverNeedChange || !driverNeedChange.order ? 0 : driverNeedChange.order;

        this.state = {
            limit: 20,
            page: 0,
            sort: {
                createdDate: -1
            },
            driverQuery: {
                str: label || '',
                searchBy: 'fullNameSort',
                status: 'active'
            },
            driverNeedChange,
            driverList,
            isLoading: false,
            valid: {
                driver: false,
                order: false,
                reason: false
            },
            isSubmited: false,
            driverId,
            order,
            reason: '',
            searching: false
        }

        this.getDriverListDebounce = _.debounce(this.getDriverList, 300);
    }

    //#region 'Life cicle region'
    componentDidMount() {

    }
    //#endregion 'Life cicle region'

    //#region 'Get data region'
    getDriverList = () => {
        const {
            fleetId
        } = this.props.auth.selectedFleet || {};

        const {
            openQueue = true,
            carIds = [],
            drivers
        } = this.props;

        const {
            driverQuery,
            driverNeedChange
        } = this.state;

        const { str = '' } = driverQuery || {};

        const params = {
            fleetId,
            status: ['active'],
            search: str,
            ids: []
        };
        if (driverNeedChange && driverNeedChange.label === str.trim()) return;
        this.props.autocompleteDriver(params).then(data => {
            if (data.res) {
                const list = !data.res ? [] : data.res.map(driver => ({
                    value: driver._id,
                    label: `${driver.fullName} - ${driver.phone}`,
                    driverInfo: driver.driverInfo
                }));
                const driverList = openQueue
                    ? (list || []).filter(driver => {
                        const { value } = driver || {};

                        const exitsInQueue = (drivers || []).find(d => d.driverId === value);
                        if (exitsInQueue) return false;

                        return true;
                    })
                    : (list || []).filter(driver => {
                        const { driverInfo = {}, value } = driver || {};
                        const { vehicleId = '' } = driverInfo;

                        const isInCarIds = _.map(carIds || [], 'vehicleId').includes(vehicleId);
                        if (!isInCarIds) return false;

                        const exitsInQueue = (drivers || []).find(d => d.driverId === value);
                        if (exitsInQueue) return false;

                        return true;
                    });

                const newState = {
                    driverList,
                    searching: true
                }

                if (!driverList || !driverList.length) newState.driverNeedChange = null;
                this.setState({
                    ...newState
                })
            } else {
                if (data.error && data.error.errorCode) {
                    let message = I18n.t(`errors.${data.error.errorCode}`);
                    if (data.error.paymentReturnCode == 407 && data.error.message) {
                        message += `Please check (${data.error.message})`;
                    }
                    this.context.notification('error', message);
                }
                this.setState({
                    isLoading: false
                });
            }
        });
    }
    //#endregion 'Get data region'

    //#region 'Handle event region'
    saveDialogForm = () => {
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true });
        }
        if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
            return;
        }
        const {
            queueId = '',
            isAddDriver,
            isEditOrder
        } = this.props || {};
        const params = {
            queueId,
            fleetId: this.props.auth.selectedFleet.fleetId
        };
        const {
            driverNeedChange: { value: driverId = '' } = {},
            order,
            reason
        } = this.state || {};
        params.order = parseInt(order);
        params.reason = reason;
        params.driverId = driverId;

        this.props.loadingBarActions.showLoadingSpiner();
        if (isAddDriver) this.props.queuingAreaActions
            .insertDriverToQueue(params)
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (!data.ok || !data.res) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t(`errors.${data.error.errorCode}`)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('queuingArea.drivers.insertDriverFail')
                        );
                    }
                }
                else if (!data.res.code) {
                    const { rs = {} } = data.res || {};
                    if (rs) {
                        for (const key in rs) {
                            if (rs[key] === false || (key === 'position' && !rs.position.length)) this.context.notification(
                                'error',
                                I18n.t(`queuingArea.drivers.${key}InsertFailRs`)
                            );
                        }
                    }
                }
                else {
                    this.context.notification(
                        'success',
                        I18n.t('queuingArea.drivers.insertDriverSuccess')
                    );
                    this.props.getListDriversCheckinQueue();
                    this.props.closeDialogForm();
                    this.setState({
                        isSubmited: false,
                        driverNeedChange: null,
                        driverId: null,
                        order: 0,
                        reason: ''
                    });
                }
            });
        else if (isEditOrder) this.props.queuingAreaActions
            .changeDriverOrderInQueue(params)
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (!data.ok || !data.res) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t(`errors.${data.error.errorCode}`)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('queuingArea.drives.changeOrderFail')
                        );
                    }
                }
                else if (!data.res.code) {
                    const { rs = {} } = data.res || {};
                    if (rs) {
                        for (const key in rs) {
                            if (rs[key] === false || (key === 'position' && !rs.position.length)) this.context.notification(
                                'error',
                                I18n.t(`queuingArea.drivers.${key}UpdateErrRs`)
                            );
                        }
                    }
                } else {
                    this.context.notification(
                        'success',
                        I18n.t('queuingArea.drivers.changeOrderSuccess')
                    );
                    this.props.getListDriversCheckinQueue();
                    this.props.closeDialogForm();
                    this.setState({
                        isSubmited: false,
                        driverNeedChange: null,
                        driverId: null,
                        order: 0,
                        reason: ''
                    });
                }
            });
    }

    handleInputChange = (key, value) => {
        switch (key) {
            case 'order':
                value = (!value || !value.length) ? '' : parseInt(value.toString().replace(/\s*[^0-9]+/g, ""));
                if (value.toString().length > 4) return;
                break;
            case 'reason':
                if (value && value.length > 100) value = this.state.reason;
                break;
            default:
                break;

        }
        this.setState({
            [key]: value
        })
    }

    ValidatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    };

    onDriverSuggestionsFetchRequested = ({ value }) => {
        if (!value || !value.length) return this.onDriverSuggestionsClearRequested();
        this.getDriverListDebounce(value);
    }

    onDriverSuggestionsClearRequested = () => {
        this.setState({
            driverList: []
        })
    }

    getDriverSuggestionValue = (suggestion) => {
        return suggestion.label;
    }

    renderDriverSuggestion = suggestion => {
        const { value, label } = suggestion || {};
        if (!value || !value.length) return null;
        return (
            <div className={`suggestion-content driver-assign`}>
                <div className="info">
                    <span>{label}</span>
                </div>
            </div>
        );
    }

    driverAutocompleteSelectedHandle = (e, { suggestion }) => {
        this.setState({
            driverNeedChange: suggestion,
            searching: false
        })
    }

    handleDriverInputChanged = (e, searchObj) => {
        const { newValue } = searchObj || {};
        if (this.state.driverNeedChange && newValue.trim() === this.state.driverNeedChange.label) return;
        const driverQuery = {
            ...this.state.driverQuery,
            str: newValue
        }
        this.setState({
            driverQuery,
            searching: true
        });
    }

    handleDriverInputBlur = () => {
        const { driverNeedChange } = this.state;
        if (driverNeedChange) {
            const driverQuery = {
                ...this.state.driverQuery,
                str: driverNeedChange.label
            }
            this.setState({
                driverQuery
            });
        }
    }

    renderSuggestionsContainer = ({ containerProps, children, query }) => {
        if (query && query.length && !children && !this.state.driverNeedChange) {
            return (
                <div {...containerProps} className="dropdown-menu" style={{
                    display: 'block',
                    position: 'absolute',
                }}>
                    <p>{I18n.t('queuingArea.drivers.noDriverFound')}</p>
                </div>
            );
        }
        return (
            <div {...containerProps}>
                {children}
            </div>
        );
    }

    //#endregion 'Handle event region'

    render() {
        const {
            actions,
            isEditOrder,
            isAddDriver,
            drivers,
        } = this.props || {};

        const {
            driverQuery = {},
            isLoading,
            driverList,
            valid,
            order,
            reason,
            isSubmited,
            driverNeedChange,
            searching
        } = this.state || {};
        const isInputDisabled = isLoading || !actions || (!isAddDriver && !isEditOrder);
        const isDriverDisabled = isInputDisabled || (isEditOrder && !isAddDriver);

        const limitOrder = drivers.length + (isAddDriver && !isEditOrder ? 1 : 0);

        return (
            <React.Fragment>
                <FormGroup
                    style={{
                        position: 'relative'
                    }}
                    className={
                        `queue-driver-autosuggest ${
                        !isSubmited
                            ? null
                            : valid.driver
                                ? null
                                : 'error'
                    }`}
                >
                    <Form.Label>
                        <Translate value="cue.Driver" />{" "}
                        <span className="require">*</span>
                    </Form.Label>
                    <Autosuggest
                        suggestions={driverList}
                        onSuggestionsFetchRequested={this.onDriverSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onDriverSuggestionsClearRequested}
                        getSuggestionValue={this.getDriverSuggestionValue}
                        renderSuggestion={this.renderDriverSuggestion}
                        onSuggestionSelected={this.driverAutocompleteSelectedHandle}
                        shouldRenderSuggestions={() => true}
                        renderSuggestionsContainer={this.renderSuggestionsContainer}
                        inputProps={{
                            className: 'form-control form-custom',
                            value: driverQuery.str,
                            placeholder: I18n.t('queuingArea.drivers.selectToInsert'),
                            onChange: this.handleDriverInputChanged,
                            onBlur: this.handleDriverInputBlur,
                            disabled: isDriverDisabled
                        }}
                    />
                    <Validator id="driver" callback={this.ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmited}
                            valid={!Validation.isStringEmpty(driverNeedChange ? driverNeedChange.value : null)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup
                    className={
                        !isSubmited
                            ? null
                            : valid.order
                                ? null
                                : 'error'
                    }
                >
                    <Form.Label>
                        <Translate value="queuingArea.drivers.order" />{" "}
                        <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                        type="text"
                        className="form-custom"
                        onChange={e => {
                            this.handleInputChange('order', !e.target.value || e.target.value === '' ? '' : e.target.value);
                        }}
                        onBlur={(e) => {
                            if (!e.target.value || e.target.value === '') this.handleInputChange('order', 1)
                        }}
                        value={order.toString().replace(/\s*[^0-9]+/g, "")}
                        placeholder={I18n.t('queuingArea.drivers.changeOrder')}
                        disabled={isInputDisabled}
                    />
                    <Validator id="order" callback={this.ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmited}
                            valid={!Validation.isStringEmpty(order)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                            hide={!isSubmited}
                            valid={Validation.isNumber(order)}
                            message={I18n.t('messages.commonMessages.must_be_integer')}
                        />
                        <ValidCase
                            hide={!isSubmited}
                            valid={Validation.isInteger(order)}
                            message={I18n.t('messages.commonMessages.must_be_integer')}
                        />
                        <ValidCase
                            hide={!isSubmited}
                            valid={Validation.isGreaterOrEqual(parseInt(order), 1)}
                            message={I18n.t(
                                'messages.commonMessages.greater_or_equa'
                            ).format(1)}
                        />
                        <ValidCase
                            valid={Validation.isLessOrEqual(parseInt(order), limitOrder)}
                            message={I18n.t(
                                'messages.commonMessages.outOfInputQueueOrder'
                            )}
                            hide={!isSubmited}
                        />
                    </Validator>
                </FormGroup>
                <FormGroup
                    className={
                        !isSubmited
                            ? null
                            : valid.reason
                                ? null
                                : 'error'
                    }
                >
                    <Form.Label>
                        <Translate value="queuingArea.drivers.Reason" />{" "}
                        <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                        as="textarea"
                        className="form-custom"
                        wrap="hard"
                        onChange={e => {
                            this.handleInputChange('reason', e.target.value);
                        }}
                        rows={5}
                        value={reason}
                        disabled={isInputDisabled}
                    />
                    <Validator id="reason" callback={this.ValidatorCallback}>
                        <ValidCase
                            hide={!isSubmited}
                            valid={!Validation.isStringEmpty(reason)}
                            message={I18n.t('messages.commonMessages.Required_field')}
                        />
                    </Validator>
                </FormGroup>
            </React.Fragment>
        );
    }
}

ModifyDriverOrder.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        commonData: state.commonData,
        language: state.i18n
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDriver: bindActionCreators(getDriver, dispatch),
        queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
        autocompleteDriver: bindActionCreators(autocompleteDriver, dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { withRef: true }
)(ModifyDriverOrder);