import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
} from 'react-bootstrap';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { Validator, ValidCase } from './../../components/validator';
import { geoPlaceDetailMapProvider } from './../../utils/mapUtils';
import {
  CCLiteCommonFunc,
  Validation,
  getLanguageAutoCompleteSearch,
  isChinaLocation,
} from './../../utils/commonFunctions';
import { QQAutocomplete, QQUltis } from './../../components/qqMap';
import * as settingActions from './../../actions/settingActions';
import * as queuingAreaActions from './../../actions/queuingAreaActions';

import * as loadingBarActions from './../../actions/loadingBarActions';
import * as commonDataActions from './../../actions/commonDataAction';
import PlacesAutocomplete from './../../components/placesAutocomplete/PlacesAutocomplete';
import QuestionCircleTooltip from './../../components/questionCircleTooltip/QuestionCircleTooltip';
import CcCheckbox from './../../components/ccCheckbox/CcCheckbox';
import { ZoneActionStatus } from './../../constants/commondata';
import PickupAreaList from './PickupAreaList';
import QueuingAreaMap from './PickupAreaList/QueuingAreaMap';
import AutocompleteWith3rd from '../../components/qqMap/AutocompleteWith3rd';

const specificType = {
  car: 1,
  mDispatcher: 2,
  mDispatcherQueue: 3,
  carQueue: 4,
};

const AutocompleteItem = ({ suggestion }) => (
  <div>
    <i className="fa fa-map-marker" />
    {suggestion}
  </div>
);
const myStyles = {
  // label: { color: "red" },
  input: { width: '100%' },
  autocompleteContainer: { padding: '5px 12px' },
  autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
  autocompleteItemActive: { color: '#04BE76' },
  // color: "#04BE76",
};

const defaultLimit = {
  canceled: 25,
  unavailable: 5,
  range: 10,
};

class AddEditQueuingArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: {
        fleetId: props.auth.selectedFleet.fleetId,
        name: '',
        radius: 0,
        openQueue: false,
        minDriver: 0,
        location: {
          lng: null,
          lat: null,
          address: '',
          city: '',
          zipCode: '',
          country: '',
        },
        mDispatcherIds: [],
        carIds: [],
        timeRules: {
          canceled: defaultLimit.canceled,
          unavailable: defaultLimit.unavailable,
        },
        geo: {
          type: 'Polygon',
          coordinates: [[]],
        },
        pickUpAreas: [],
        range: defaultLimit.range,
      },
      valid: {},
      mDispatcherList: [],
      carList: [],
      filterStr: {
        mDispatcher: '',
        mDispatcherQueue: '',
        car: '',
        carQueue: '',
      },
      selecting: {
        selectingmDispatcher: [],
        selectingmDispatcherQueue: [],
        selectingCar: [],
        selectingCarQueue: [],
      },
      supportedLocation: false,

      mapState: {
        textSearch: '',
        zoneAction: ZoneActionStatus.View,
        zoneDraw: {
          activeZone: [],
          Zones: [],
          parentZones: [],
        },
        center: null,
        defaultZoom: 12,
        polyline: true,
        isSubmitted: false,
        valid: {
          name: true,
          parameter: true,
        },
      },
    };
    this.loadMoreVehicles = _.debounce(this.loadMoreVehicles, 200);
  }

  componentDidMount() {
    const { queueId, queueDetailData } = this.props;
    if (queueId) {
      if (!queueDetailData)
        this.props.queuingAreaActions
          .detailsQueuingArea({
            _id: queueId,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (!data.error) {
              this.fetchMDispatcherListForQueuing(
                this.props.auth.selectedFleet.fleetId,
                queueId
              );
              this.initData(data.res);
              this.loadMoreVehicles();
            } else if (data.error) {
              this.context.notification(
                'error',
                I18n.t(`errors.${data.error.errorCode}`)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('queuingArea.Load_queuingArea_fail')
              );
            }
          });
      else {
        this.fetchMDispatcherListForQueuing(
          this.props.auth.selectedFleet.fleetId,
          queueId
        );
        this.initData(queueDetailData);
        this.loadMoreVehicles();
      }
    } else {
      this.loadMoreVehicles();
    }
    this.fetchMDispatcher();
  }

  fetchMDispatcherListForQueuing = (fleetId, queueId) => {
    this.props.queuingAreaActions
      .getMDispatcherListForQueuing(fleetId, queueId)
      .then((mDispatchListData) => {
        if (mDispatchListData.ok && mDispatchListData.res) {
          this.setState({ mDispatcherList: mDispatchListData.res });
        }
      });
  };

  shouldComponentUpdate(nextProps) {
    const { zonesData, editable } = nextProps;
    const { zonesData: currentZonesData, editable: currentEditable } =
      this.props;
    if (
      !_.isEqual(zonesData, currentZonesData) ||
      editable !== currentEditable
    ) {
      // this.initZonesMap(zonesData, queueId, editable);
    }
    return true;
  }

  initData = (queueArea) => {
    const dialogData = queueArea;
    const { geo: editingGeo, geo: { type } = {}, location } = queueArea || {};
    const geo =
      editingGeo && !type
        ? {
            ...editingGeo,
            type: 'Polygon',
          }
        : editingGeo;
    if (geo) dialogData.geo = geo;

    const { mapState } = this.state;
    const newState = {
      dialogData,
      supportedLocation: !!queueArea._id,
      isSubmited: false,
    };

    if (location.lat && location.lng) {
      newState.mapState = {
        ...mapState,
        center: { lat: location.lat, lng: location.lng },
      };
    }
    this.setState({
      ...newState,
    });
  };

  fetchMDispatcher = () => {
    this.props.settingActions
      .fetchMDispatcherNamesForSelection({
        fleetId: this.props.auth.selectedFleet.fleetId,
        isActive: true,
        isWithOutQueuing: true,
      })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({
            mDispatcherList: data.res,
          });
        }
      });
  };

  handleInputChange = (key, e) => {
    const dialogData = {};

    switch (key) {
      case 'address': {
        dialogData.location = {
          ...this.state.dialogData.location,
          address: e,
        };
        //dialogData.location.address = e;
        if (!e || e.length == 0) {
          this.handleAddressRemovedPickUp();
        }
        break;
      }
      case 'autoDispatch':
      case 'openQueue': {
        dialogData[key] = e.target.checked;
        break;
      }
      case 'timeRules.canceled':
      case 'timeRules.unavailable':
        key = key.split('.')[1];
        if (
          !(
            e.target.value === null ||
            e.target.value === undefined ||
            e.target.value.length <= 4
          )
        )
          return;
        const timeRules = {
          ...this.state.dialogData.timeRules,
          [key]:
            e.target.value === null || e.target.value === undefined
              ? ''
              : parseInt(e.target.value.replace(/[^0-9\.]+/g, '')),
        };
        dialogData.timeRules = timeRules;
        break;
      case 'range':
        if (e.target && e.target.value) {
          if (parseInt(e.target.value.replace(/[^0-9\.]+/g, '')) > 100) return;
        }
        dialogData[key] =
          e.target.value === null || e.target.value === undefined
            ? ''
            : parseInt(e.target.value.replace(/[^0-9\.]+/g, ''));
        break;
      case 'minDriver':
        dialogData[key] =
          e.target.value === null || e.target.value === undefined
            ? ''
            : parseInt(e.target.value.replace(/[^0-9\.]+/g, ''));
        break;
      default: {
        dialogData[key] = e.target.value;
      }
    }

    this.setState({
      dialogData: {
        ...this.state.dialogData,
        ...dialogData,
      },
    });
  };

  handleSavePolygons = ({ driverArea, pickUpAreas }) => {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        geo: driverArea,
        pickUpAreas,
      },
    });
  };

  selectChanggHandle = (type, e) => {
    switch (type) {
      case specificType.car: {
        this.state.selecting.selectingCar =
          CCLiteCommonFunc.getSelectedOptons(e);
        break;
      }
      case specificType.carQueue: {
        this.state.selecting.selectingCarQueue =
          CCLiteCommonFunc.getSelectedOptons(e);
        break;
      }
      case specificType.mDispatcher: {
        this.state.selecting.selectingmDispatcher =
          CCLiteCommonFunc.getSelectedOptons(e);
        break;
      }
      case specificType.mDispatcherQueue: {
        this.state.selecting.selectingmDispatcherQueue =
          CCLiteCommonFunc.getSelectedOptons(e);
        break;
      }
    }
    this.setState({ selecting: this.state.selecting });
  };

  addRemoveSelecting = (type) => {
    if (!this.props.editable) {
      return;
    }
    const { dialogData } = this.state;
    switch (type) {
      case specificType.car: {
        const selectedItems = this.state.carList.filter((o) =>
          this.state.selecting.selectingCar.includes(o._id)
        );
        this.state.dialogData.carIds =
          this.state.dialogData.carIds.concat(selectedItems);
        this.state.selecting.selectingCar = [];
        if (selectedItems.length >= this.state.carList.length - 10) {
          this.loadMoreVehicles(0, this.state.filterStr.car);
        }
        break;
      }
      case specificType.carQueue: {
        if (this.state.selecting.selectingCarQueue.length > 0) {
          const result = [];
          const unSelects = [];
          this.state.dialogData.carIds.map((car) => {
            if (!this.state.selecting.selectingCarQueue.includes(car._id)) {
              result.push(car);
            } else {
              unSelects.push(car);
            }
          });
          this.state.dialogData.carIds = result;
          this.setState({
            carList: _.unionBy(unSelects.concat(this.state.carList), '_id'),
          });
        } else if (this.state.dialogData.carIds.length > 0) {
          this.state.dialogData.carIds = this.state.dialogData.carIds.slice(1);
        }

        this.state.selecting.selectingCarQueue = [];
        break;
      }
      case specificType.mDispatcher: {
        if (this.state.selecting.selectingmDispatcher.length > 0) {
          this.state.dialogData.mDispatcherIds =
            this.state.dialogData.mDispatcherIds.concat(
              this.state.selecting.selectingmDispatcher
            );
        } else {
          let itemSelected = null;
          this.state.mDispatcherList.every((item) => {
            if (
              !this.checkExistInList(specificType.mDispatcher, item) &&
              this.filterCheck(specificType.mDispatcher, item)
            ) {
              itemSelected = item;
              return false;
            }
            return true;
          });
          if (itemSelected) {
            this.state.dialogData.mDispatcherIds.push(itemSelected._id);
          }
        }
        this.state.selecting.selectingmDispatcher = [];
        break;
      }
      case specificType.mDispatcherQueue: {
        if (this.state.selecting.selectingmDispatcherQueue.length > 0) {
          const result = [];
          this.state.dialogData.mDispatcherIds.map((id) => {
            if (!this.state.selecting.selectingmDispatcherQueue.includes(id)) {
              result.push(id);
            }
          });
          this.state.dialogData.mDispatcherIds = result;
        } else if (this.state.dialogData.mDispatcherIds.length > 0) {
          this.state.dialogData.mDispatcherIds =
            this.state.dialogData.mDispatcherIds.slice(1);
        }
        this.state.selecting.selectingmDispatcherQueue = [];
        break;
      }
      default:
        break;
    }
    this.setState({ selecting: this.state.selecting });
  };

  filterCheck = (type, item) => {
    switch (type) {
      case specificType.car: {
        if (
          item.plateNumber
            .toLowerCase()
            .includes(this.state.filterStr.car.toLowerCase())
        ) {
          return true;
        }
        break;
      }
      case specificType.carQueue: {
        if (
          item.plateNumber
            .toLowerCase()
            .includes(this.state.filterStr.carQueue.toLowerCase())
        ) {
          return true;
        }
        break;
      }
      case specificType.mDispatcher: {
        if (
          item.fullName
            .toLowerCase()
            .includes(this.state.filterStr.mDispatcher.toLowerCase()) &&
          item.isActive
        ) {
          return true;
        }
        break;
      }
      case specificType.mDispatcherQueue:
        {
          if (
            item.fullName
              .toLowerCase()
              .includes(this.state.filterStr.mDispatcherQueue.toLowerCase())
          ) {
            return true;
          }
          break;
        }
        return false;
      default:
        break;
    }
  };

  handleListFilterChange = (type, e) => {
    switch (type) {
      case specificType.car: {
        this.state.filterStr.car = e.target.value;
        this.loadMoreVehicles(0, e.target.value);
        break;
      }
      case specificType.carQueue: {
        this.state.filterStr.carQueue = e.target.value;
        break;
      }
      case specificType.mDispatcher: {
        this.state.filterStr.mDispatcher = e.target.value;
        break;
      }
      case specificType.mDispatcherQueue: {
        this.state.filterStr.mDispatcherQueue = e.target.value;
        break;
      }
      default:
        break;
    }
    this.setState({ filterStr: this.state.filterStr });
  };

  handleSelectPickUp = (
    addressPickUp,
    placeId,
    pointOfInterest,
    sessionToken
  ) => {
    if (addressPickUp && addressPickUp != '') {
      if (!this.state.dialogData.location) {
        this.state.dialogData.location = {};
      }
      this.state.dialogData.location.address = addressPickUp;
      this.setState({ dialogData: this.state.dialogData });
      const callback = (err, localtionSelected, results) => {
        if (err && !localtionSelected) {
          return;
        }
        this.state.dialogData.location = {
          lng: localtionSelected.lng,
          lat: localtionSelected.lat,
          city: localtionSelected.city,
          address: addressPickUp,
          zipCode: localtionSelected.zipCode,
          country: localtionSelected.countryCode,
        };
        this.setState({
          dialogData: this.state.dialogData,
          mapState: {
            ...this.state.mapState,
            center: {
              lng: localtionSelected.lng,
              lat: localtionSelected.lat,
            },
          },
        });
        this.props.queuingAreaActions
          .checkAddressQueuingArea({
            fleetId: this.props.auth.selectedFleet.fleetId,
            lat: this.state.dialogData.location.lat,
            lng: this.state.dialogData.location.lng,
          })
          .then((checkData) => {
            if (checkData.ok && checkData.res) {
              this.setState({ supportedLocation: checkData.res.valid });
            } else {
              this.setState({ supportedLocation: false });
            }
          });
      };
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        language: this.props.language
      });
    } else {
      this.state.dialogData.location = {
        lng: null,
        lat: null,
        city: null,
        address: addressPickUp,
        zipCode: null,
      };
    }
  };

  handleAddressRemovedPickUp = () => {
    this.state.dialogData.location = {
      lng: null,
      lat: null,
      city: null,
      address: '',
      zipCode: null,
      country: null,
    };
    this.setState({ dialogData: this.state.dialogData });
  };

  checkExistInList = (type, data) => {
    if (specificType.car == type) {
      return _.map(this.state.dialogData.carIds, '_id').includes(data._id);
    }
    return this.state.dialogData.mDispatcherIds.includes(data._id);
  };

  isValidCheckInPolygons = () => {
    const { geo } = this.state.dialogData;
    if (_.get(geo, 'coordinates[0]', []).length < 3) {
      return false;
    }
    return true;
  };

  isValidPickUpPolygons = () => {
    const { pickUpAreas } = this.state.dialogData;
    if (_.get(pickUpAreas, '[0].geo.coordinates[0]', []).length < 3) {
      return false;
    }
    return true;
  };

  ValidatorCallback = (id, valid) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  transformCoordinatesPolygon = (coordinates) => {
    if (
      _.first(coordinates[0])[0] !== _.last(coordinates[0])[0] ||
      _.first(coordinates[0])[1] !== _.last(coordinates[0])[1]
    ) {
      coordinates[0].push(_.first(coordinates[0]));
    }
    return coordinates;
  };

  validatePolygon = (queue) => {
    const queueData = queue;
    queueData.geo.coordinates = this.transformCoordinatesPolygon(
      queueData.geo.coordinates
    );
    queueData.pickUpAreas = _.map(queueData.pickUpAreas, (area) => {
      return {
        ...area,
        geo: {
          ...area.geo,
          coordinates: this.transformCoordinatesPolygon(area.geo.coordinates),
        },
      };
    });
    return queueData;
  };

  saveDialogForm = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    this.props.loadingBarActions.showLoadingSpiner();
    const {
      fleetId = '',
      location: { lat, lng } = {},
      geo = {},
    } = this.state.dialogData;
    this.props.queuingAreaActions
      .checkAddressQueuingArea({
        fleetId,
        lat,
        lng,
        geo,
      })
      .then((checkData) => {
        if (checkData.ok && checkData.res) {
          let queueData = _.cloneDeep(this.state.dialogData);
          queueData.carIds = _.map(queueData.carIds, '_id');
          queueData = this.validatePolygon(queueData);
          if (this.state.dialogData._id) {
            this.props.queuingAreaActions
              .updateQueuingArea(queueData)
              .then((data) => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (!data.ok) {
                  if (data.error) {
                    this.context.notification(
                      'error',
                      I18n.t(`errors.${data.error.errorCode}`)
                    );
                  } else {
                    this.context.notification(
                      'error',
                      I18n.t('queuingArea.Update_queuingArea_fail')
                    );
                  }
                } else {
                  this.context.notification(
                    'success',
                    I18n.t('queuingArea.Update_queuingArea_success')
                  );
                  this.props.handleUpdateFormSuccess();
                  this.props.closeDialogForm();
                  this.setState({ isSubmited: false });
                }
              });
          } else {
            this.props.queuingAreaActions
              .createQueuingArea(queueData)
              .then((data) => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (!data.ok) {
                  if (data.error) {
                    this.context.notification(
                      'error',
                      I18n.t(`errors.${data.error.errorCode}`)
                    );
                  } else {
                    this.context.notification(
                      'error',
                      I18n.t('queuingArea.Create_queuingArea_fail')
                    );
                  }
                } else {
                  this.context.notification(
                    'success',
                    I18n.t('queuingArea.Create_queuingArea_success')
                  );
                  this.props.handleUpdateFormSuccess();
                  this.setState({ isSubmited: false });
                }
              });
          }
        } else {
          this.state.supportedLocation = false;
          this.props.loadingBarActions.hideLoadingSpiner();
        }
      });
  };

  loadMoreVehicles = (page = 0, textSearch) => {
    this.setState({ loadingCar: true });
    const { carIds } = this.state.dialogData;
    const omitIds = _.map(carIds, '_id');
    this.props.commonDataActions
      .loadMoreVehicles({
        fleetId: this.props.auth.selectedFleet.fleetId,
        isActive: true,
        page,
        limit: 50,
        omitIds,
        search: textSearch,
      })
      .then((data) => {
        if (data.ok && data.res) {
          const { carList } = this.state;
          const { list, total } = data.res;
          const newCarList = page === 0 ? list : carList.concat(list);
          this.setState({
            carList: newCarList,
            hasMore: newCarList.length < total,
            page,
            loadingCar: false,
          });
        }
      });
  };

  onCarListFormScroll = (e) => {
    const { hasMore, page, loadingCar, filterStr } = this.state;
    if (
      this.carListSelectRef.scrollHeight <
        this.carListSelectRef.scrollTop + 200 &&
      hasMore &&
      !loadingCar &&
      this.props.editable
    ) {
      this.loadMoreVehicles(page + 1, filterStr.car);
    }
  };

  handleEditPickupArea = (pickUpId) => {
    this.setState({ activePickUpId: pickUpId, activeCheckIn: false });
  };

  handleEditCheckInArea = () => {
    this.setState({ activePickUpId: null, activeCheckIn: true });
  };

  onBoundPickUpArea = (pickUpId) => {
    this.setState({ activePickUpId: pickUpId, activeCheckIn: false });
  };

  onCheckInActive = () => {
    this.setState({ activePickUpId: null, activeCheckIn: true });
  };

  render() {
    const { editable } = this.props;
    const { dialogData } = this.state || {};
    const { isChina } = this.props.commonData.location;
    const {
      timeRules: {
        canceled = defaultLimit.canceled,
        unavailable = defaultLimit.unavailable,
      } = {},
      range = defaultLimit.range,
    } = dialogData || {};
    return (
      <div className="AddEditQueingMap">
        <div className="row AddEditQueingMap__container">
          <div className="col-md-5 col-sm-6 AddEditQueingMap__cover">
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.name
                  ? null
                  : 'error'
              }
            >
              <Form.Label>
                <Translate value="queuingArea.Queuing_area" />
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                type="text"
                className="form-custom"
                onChange={(e) => {
                  this.handleInputChange('name', e);
                }}
                value={dialogData ? dialogData.name : ''}
                placeholder={I18n.t('queuingArea.Queuing_area')}
                disabled={!editable}
              />
              <Validator id="name" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={!Validation.isStringEmpty(dialogData.name)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !this.state.isSubmited
                  ? null
                  : this.state.valid.map
                  ? null
                  : 'error'
              }
            >
              <PickupAreaList
                queueId={dialogData._id}
                driverArea={dialogData.geo}
                pickUpAreas={dialogData.pickUpAreas}
                onSavePolygons={this.handleSavePolygons}
                editable={editable}
                center={this.state.mapState.center}
                onBoundPickUpArea={this.onBoundPickUpArea}
                onCheckInActive={this.onCheckInActive}
                activePickUpId={this.state.activePickUpId}
                activeCheckIn={this.state.activeCheckIn}
              />
              <Validator id="map" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={this.isValidCheckInPolygons()}
                  message={I18n.t(
                    'queuingArea.please_choose_driver_check_in_area'
                  )}
                />
                <ValidCase
                  hide={!this.state.isSubmited}
                  valid={this.isValidPickUpPolygons()}
                  message={I18n.t('queuingArea.please_choose_pick_up_area')}
                />
              </Validator>
            </FormGroup>
            <div className="AddEditQueingMap__map">
              <FormGroup>
                <QueuingAreaMap
                  queueId={this.props.queueId}
                  driverArea={dialogData.geo}
                  pickUpAreas={dialogData.pickUpAreas}
                  showCheckInArea={false}
                  showPickupArea={false}
                  handleEditCheckInArea={this.handleEditCheckInArea}
                  handleEditPickupArea={this.handleEditPickupArea}
                  activePickUpId={this.state.activePickUpId}
                  activeCheckIn={this.state.activeCheckIn}
                  center={this.state.mapState.center}
                />
              </FormGroup>
            </div>
          </div>
          <div className="col-md-7 col-sm-6 AddEditQueingMap__inputgroup">
            <Row>
              <Col xs={6}>
                <FormGroup
                  className={`assign-select ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.address
                      ? null
                      : 'error'
                  }`}
                >
                  <Form.Label>
                    <Translate value="queuingArea.Address" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  { isChina ? (
                    <AutocompleteWith3rd
                      value={dialogData?.location?.address}
                      onSelect={this.handleSelectPickUp}
                      handleAddressRemoved={() => {}}
                      onChange={e => {
                        this.handleInputChange('address', e);
                      }}
                      disabled={!editable}
                    />
                  ) : (
                    <div className="place-container">
                      <PlacesAutocomplete
                        value={dialogData.location.address}
                        onSelect={this.handleSelectPickUp}
                        onChange={(e) => {
                          this.handleInputChange('address', e);
                        }}
                        autocompleteItem={AutocompleteItem}
                        styles={myStyles}
                        placeholder={I18n.t('map.Enter_a_location')}
                        onEnterKeyDown={this.handleSelectPickUp}
                        handleAddressRemoved={this.handleAddressRemovedPickUp}
                        disabled={!editable}
                      />
                    </div>
                  )}

                  <Validator id="address" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={
                        !Validation.isStringEmpty(dialogData.location.address)
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={dialogData.location.lat && dialogData.location.lat}
                      message={I18n.t(
                        'queuingArea.Entered_Address_is_incorrect'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={this.state.supportedLocation}
                      message={I18n.t('queuingArea.Not_supported_zone')}
                    />
                  </Validator>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup
                  className={
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.minDriver
                      ? null
                      : 'error'
                  }
                >
                  <Form.Label>
                    <Translate value="queuingArea.Min_drivers_required" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    type="number"
                    className="form-custom"
                    onChange={(e) => {
                      this.handleInputChange('minDriver', e);
                    }}
                    value={dialogData ? dialogData.minDriver : ''}
                    placeholder={I18n.t('queuingArea.Min_drivers_required')}
                    disabled={!editable}
                  />
                  <Validator id="minDriver" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={!Validation.isStringEmpty(dialogData.minDriver)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isNumber(dialogData.minDriver)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isInteger(dialogData.minDriver)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(
                        dialogData.minDriver,
                        0
                      )}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(0)}
                    />
                  </Validator>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="rules-container">
                <FormGroup
                  className={`qup-input-group assign-select ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.canceled
                      ? null
                      : 'error'
                  }`}
                >
                  <Form.Label>
                    <Translate value="queuingArea.keepDriverPlaceCancelNoShow" />{' '}
                    <span className="require">*</span>
                    <QuestionCircleTooltip
                      text={
                        <Translate value="queuingArea.queueCanceledTooltip" />
                      }
                    />
                  </Form.Label>
                  <InputGroup className="single-addon-right">
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={(e) => {
                        this.handleInputChange('timeRules.canceled', e);
                      }}
                      value={canceled ? canceled : ''}
                      placeholder={defaultLimit.canceled}
                      disabled={!editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        {I18n.t('General.mins')}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Validator id="canceled" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={!Validation.isStringEmpty(canceled)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isNumber(canceled)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isInteger(canceled)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(canceled, 1)}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(1)}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isLessOrEqual(canceled, 9999)}
                      message={I18n.t(
                        'messages.commonMessages.less_or_equal'
                      ).format(9999)}
                    />
                  </Validator>
                </FormGroup>
              </Col>
              <Col xs={12} md={6} className="rules-container">
                <FormGroup
                  className={`qup-input-group ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.unavailable
                      ? null
                      : 'error'
                  }`}
                >
                  <Form.Label>
                    <Translate value="queuingArea.keepDriverPlaceUnavailable" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <InputGroup className="single-addon-right">
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={(e) => {
                        this.handleInputChange('timeRules.unavailable', e);
                      }}
                      value={unavailable ? unavailable : ''}
                      placeholder={defaultLimit.unavailable}
                      disabled={!editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        {I18n.t('General.mins')}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <Validator id="unavailable" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={!Validation.isStringEmpty(unavailable)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isNumber(unavailable)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isInteger(unavailable)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterOrEqual(unavailable, 1)}
                      message={I18n.t(
                        'messages.commonMessages.greater_or_equa'
                      ).format(1)}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isLessOrEqual(unavailable, 9999)}
                      message={I18n.t(
                        'messages.commonMessages.less_or_equal'
                      ).format(9999)}
                    />
                  </Validator>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className="rules-container">
                <FormGroup
                  className={`assign-select ${
                    !this.state.isSubmited
                      ? null
                      : this.state.valid.range
                      ? null
                      : 'error'
                  }`}
                >
                  <Form.Label>
                    <Translate value="queuingArea.range" />{' '}
                    <span className="require">*</span>
                    <QuestionCircleTooltip
                      text={<Translate value="queuingArea.queueRangeTooltip" />}
                    />
                  </Form.Label>
                  <FormControl
                    type="number"
                    className="form-custom"
                    onChange={(e) => {
                      this.handleInputChange('range', e);
                    }}
                    value={range ? range : ''}
                    placeholder={defaultLimit.range}
                    disabled={!editable}
                  />
                  <Validator id="range" callback={this.ValidatorCallback}>
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={!Validation.isStringEmpty(range)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isNumber(range)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isInteger(range)}
                      message={I18n.t(
                        'messages.commonMessages.must_be_integer'
                      )}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isGreaterThan(range, 0)}
                      message={I18n.t(
                        'messages.commonMessages.greater_than'
                      ).format(0)}
                    />
                    <ValidCase
                      hide={!this.state.isSubmited}
                      valid={Validation.isLessOrEqual(range, 100)}
                      message={I18n.t(
                        'messages.commonMessages.less_or_equal'
                      ).format(100)}
                    />
                  </Validator>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6} className="assign-container">
                <FormGroup className="assign-select">
                  <Form.Label>
                    <Translate value="queuingArea.Active_mDispatcher_list" />
                  </Form.Label>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={(e) => {
                      this.handleListFilterChange(specificType.mDispatcher, e);
                    }}
                    value={this.state.filterStr.mDispatcher}
                    placeholder={I18n.t('queuingArea.Enter_to_search')}
                    disabled={!editable}
                  />
                  <FormControl
                    as="select"
                    className="mr-t-5 form-custom"
                    multiple
                    onChange={(e) => {
                      this.selectChanggHandle(specificType.mDispatcher, e);
                    }}
                    disabled={!editable}
                  >
                    {this.state.mDispatcherList.map((item) => {
                      if (
                        !this.checkExistInList(
                          specificType.mDispatcher,
                          item
                        ) &&
                        this.filterCheck(specificType.mDispatcher, item)
                      ) {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.fullName}
                          </option>
                        );
                      }
                    })}
                  </FormControl>
                </FormGroup>
                <div className="assign-ctr-contaner compile">
                  <table className="assign-control">
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <i
                              className="fa fa-arrow-circle-right add"
                              aria-hidden="true"
                              onClick={() => {
                                this.addRemoveSelecting(
                                  specificType.mDispatcher
                                );
                              }}
                            />
                          </div>
                          <div>
                            <i
                              className="fa fa-arrow-circle-left remove"
                              aria-hidden="true"
                              onClick={() => {
                                this.addRemoveSelecting(
                                  specificType.mDispatcherQueue
                                );
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col xs={6} md={6}>
                <FormGroup className="mb0">
                  <Form.Label>
                    <Translate value="queuingArea.Queue_list" />
                  </Form.Label>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={(e) => {
                      this.handleListFilterChange(
                        specificType.mDispatcherQueue,
                        e
                      );
                    }}
                    value={this.state.filterStr.mDispatcherQueue}
                    placeholder={I18n.t('queuingArea.Enter_to_search')}
                    disabled={!editable}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControl
                    as="select"
                    className="mr-t-5 form-custom"
                    multiple
                    onChange={(e) => {
                      this.selectChanggHandle(specificType.mDispatcherQueue, e);
                    }}
                    disabled={!editable}
                  >
                    {this.state.mDispatcherList.map((item) => {
                      if (
                        this.checkExistInList(specificType.mDispatcher, item) &&
                        this.filterCheck(specificType.mDispatcherQueue, item)
                      ) {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.fullName}
                          </option>
                        );
                      }
                    })}
                  </FormControl>
                </FormGroup>
              </Col>
            </Row>

            <CcCheckbox
              disabled={!editable}
              checked={dialogData.openQueue}
              onChange={(e) => {
                this.handleInputChange('openQueue', e);
              }}
              text={I18n.t('queuingArea.Open_queuing_area')}
            />
            {!dialogData.openQueue ? (
              <Row>
                <Col xs={6} md={6} className="assign-container">
                  <FormGroup className="assign-select">
                    <Form.Label>
                      <Translate value="queuingArea.Active_car_list" />
                    </Form.Label>
                    <FormControl
                      type="text"
                      className="form-custom"
                      onChange={(e) => {
                        this.handleListFilterChange(specificType.car, e);
                      }}
                      value={this.state.filterStr.car}
                      placeholder={I18n.t('queuingArea.Enter_to_search')}
                      disabled={!editable}
                    />
                    <FormControl
                      as="select"
                      className="mr-t-5 form-custom"
                      multiple
                      onChange={(e) => {
                        this.selectChanggHandle(specificType.car, e);
                      }}
                      disabled={!editable}
                      onScroll={this.onCarListFormScroll}
                      inputRef={(ref) => {
                        this.carListSelectRef = ref;
                      }}
                    >
                      {this.state.carList.map((item) => {
                        if (
                          !this.checkExistInList(specificType.car, item) &&
                          this.filterCheck(specificType.car, item)
                        ) {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.plateNumber}
                            </option>
                          );
                        }
                      })}
                    </FormControl>
                  </FormGroup>
                  <div className="assign-ctr-contaner compile">
                    <table className="assign-control">
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <i
                                className="fa fa-arrow-circle-right add"
                                aria-hidden="true"
                                onClick={() => {
                                  this.addRemoveSelecting(specificType.car);
                                }}
                              />
                            </div>
                            <div>
                              <i
                                className="fa fa-arrow-circle-left remove"
                                aria-hidden="true"
                                onClick={() => {
                                  this.addRemoveSelecting(
                                    specificType.carQueue
                                  );
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col xs={6} md={6}>
                  <FormGroup className="mb0">
                    <Form.Label>
                      <Translate value="queuingArea.Queue_list" />
                    </Form.Label>
                    <FormControl
                      type="text"
                      className="form-custom"
                      onChange={(e) => {
                        this.handleListFilterChange(specificType.carQueue, e);
                      }}
                      value={this.state.filterStr.carQueue}
                      placeholder={I18n.t('queuingArea.Enter_to_search')}
                      disabled={!editable}
                    />
                  </FormGroup>
                  <FormControl
                    as="select"
                    className="mr-t-5 form-custom"
                    multiple
                    onChange={(e) => {
                      this.selectChanggHandle(specificType.carQueue, e);
                    }}
                    disabled={!editable}
                  >
                    {this.state.dialogData.carIds.map((item) => {
                      if (this.filterCheck(specificType.carQueue, item)) {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.plateNumber}
                          </option>
                        );
                      }
                    })}
                  </FormControl>
                </Col>
              </Row>
            ) : (
              <div className="opening-area"></div>
            )}
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="queuingArea.Required_fields" />
            </Form.Label>
          </div>
        </div>
        <Row>
          <Col xs={12} className="text-center mt-md">
            {!this.props.permissions || this.props.permissions.actions ? (
              editable ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.saveDialogForm}
                  // disabled={!CCLiteCommonFunc.isFormValid(valid)}
                >
                  <Translate value="queuingArea.Save" />
                </Button>
              ) : (
                <Button
                  className="btn-save mr-md"
                  onClick={(e) => {
                    this.props.handleMenuClick('changeStatus', e);
                  }}
                >
                  <Translate value="queuingArea.Edit" />
                </Button>
              )
            ) : null}
            <Button className="btn-cancel" onClick={this.props.closeDialogForm}>
              <Translate value="queuingArea.Cancel" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

AddEditQueuingArea.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    queuingAreaActions: bindActionCreators(queuingAreaActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    commonDataActions: bindActionCreators(commonDataActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditQueuingArea);
