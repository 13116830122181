import React, { PureComponent, Fragment } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

class ConfirmUpdateModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    closeModal = () => {
        this.props.onClose()
    }

    render() {
        const {
            tripInfo,
            visible
        } = this.props;
        return (
            <Modal
                show={visible}
                backdrop
                dialogClassName="confirm-dialog update-book-detail-modal"
                onHide={this.closeModal}
                className={'confirm'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Translate value="tripDetails.CONFIRM_UPDATE" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate value="tripDetails.CONFIRM_UPDATE_MESSAGE" />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeModal} className="btn-reset">
                        <Translate value="tripDetails.No" />
                    </Button>
                    <Button onClick={this.props.onUpdate} className="btn-save">
                        <Translate value="tripDetails.Update_info" />
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmUpdateModal;
