import { I18n } from 'react-redux-i18n';
import React from 'react';
import ReportUtils from '../base/utils';
import _ from 'lodash';

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.referralDetail.bookingId',
    summary: 'bookingId',
    width: 150,
    link: {
      newTab: true,
      href: '/reports/Booking_details',
      name: 'goToBookingDetails',
      params: ['bookingId'],
      excludeParams: ['bookingId', 'driverId', 'companyId', 'pickUpZone'],
      description: 'List all passengers which referred by this driver'
    }
  },
  {
    key: 'date',
    label: 'report.result.referralDetail.completedDate',
    summary: 'Completed time',
    mutate: ReportUtils.formatTime
  },
  {
    key: 'paidBy',
    label: 'report.result.referralDetail.paidBy',
    summary: 'paidBy'
  },
  {
    key: 'bookFrom',
    label: 'report.result.referralDetail.bookFrom',
    summary: 'bookFrom'
  },
  // {
  //   key: 'driverName',
  //   label: 'report.result.referralDetail.driverName',
  //   summary: 'Driver name'
  // },
  // {
  //   key: 'driverIdPermit',
  //   label: 'report.result.referralDetail.driverIdPermit',
  //   summary: 'ID# / National IC#'
  // },
  // {
  //   key: 'companyName',
  //   label: 'report.result.referralDetail.companyName',
  //   summary: 'Company'
  // },
  {
    key: 'referrerInfo',
    label: 'report.result.referralDetail.referrerInfo',
    sub: [
      {
        key: 'referrerInfo.driverName',
        label: 'report.result.referralDetail.driverName',
        width: 200,
        mutate: (value, doc) => {
          if (doc.referrerInfo) {
            return (
              <div>
                <span>
                  {doc.referrerInfo.driverName}
                </span>
              </div>
            );
          }
        }
      },
      {
        key: 'referrerInfo.phone',
        label: 'report.result.referralDetail.phoneReferral',
        width: 200,
        mutate: (value, doc) => {
          if (doc.referrerInfo) {
            return (
              <div>
                <span>
                  {doc.referrerInfo.phone}
                </span>
              </div>
            );
          }
        }
      },
      {
        key: 'referrerInfo.driverNumber',
        label: 'report.result.referralDetail.driverNumber',
        width: 200,
        mutate: (value, doc) => {
          if (doc.referrerInfo) {
            return (
              <div>
                <span>
                  {doc.referrerInfo.driverNumber}
                </span>
              </div>
            );
          }
        }
      },
      {
        key: 'referrerInfo.companyName',
        label: 'report.result.referralDetail.companyName',
        width: 200,
        mutate: (value, doc) => {
          if (doc.referrerInfo) {
            return (
              <div>
                <span>
                  {doc.referrerInfo.companyName}
                </span>
              </div>
            );
          }
        }
      },
    ]
  },
  {
    key: 'referralCode',
    label: 'report.result.referralDetail.referralCode',
    summary: 'referralCode'
  },
  {
    key: 'customerName',
    label: 'report.result.referralDetail.Customer',
    summary: 'customerName'
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'subTotalAfterPromotion',
    label: 'report.result.referralDetail.subtotal',
    summary: 'subTotalAfterPromotion'
  },
  // {
  //   key: 'subTotalFF',
  //   label: 'report.result.referralDetail.subTotalFF',
  //   summary: 'subTotalFF',
  //   checkShowFromProps: 'selectedFleet',
  //   isNotShow: selectedFleet => {
  //     const modules = _.get(selectedFleet, 'modules', []);
  //     return !_.find(modules, m => (m.name === "Driver to pax" || m.name === "Pax refers Pax"));
  //   }
  // },
  {
    key: 'referralEarning',
    label: 'report.result.referralDetail.referralEarning',
    sub: [
      {
        key: 'referralEarning',
        label: 'report.result.referralDetail.Amount',
        width: 120,
        summary: 'referralEarning'
      },
      {
        label: 'report.result.referralDetail.Note',
        className: 'text-align-center',
        mutate: (value, doc) => {
          if (doc.referralType == 'percent') {
            if (doc.referralPercent) {
              if (doc.isReferralMaxAmount) {
                return (
                  <div className="text-align-center">
                    <span>
                      {I18n.t('report.result.referralDetail.PercentOfSubTotal').format(
                        `${doc.referralPercent.toFixed(2)}`
                      )}
                    </span>
                    <span>
                      {I18n.t('report.result.referralDetail.max').format(
                        `${doc.referralMaxAmount.toFixed(2)}`
                      )}
                    </span>
                  </div>
                );
              }
              return (
                <div className="text-align-center">
                  <span>
                    {I18n.t('report.result.referralDetail.PercentOfSubTotal').format(
                      `${doc.referralPercent.toFixed(2)}`
                    )}
                  </span>
                </div>
              );
            }
          }
          return <div className="text-align-center">-</div>;
        },
        width: 200
      }
    ]
  },
  {
    key: 'currencyISO',
    label: 'report.result.referralDetail.Currency',
    summary: 'Currency',
    width: 100
  }
];

export const fareFields = ['subTotalAfterPromotion', 'subTotalFF', 'referralEarning'];
