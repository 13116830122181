import React, { Component } from 'react';
import { FormGroup, FormControl, Image } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import { Validation, CCLiteCommonFunc } from '../../../utils/commonFunctions';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import IntlTelInputApp from '../../intlTelInputCustome/IntlTelInputApp';
import { userType, paymentMethodNumber, TRAVELER_TYPE } from '../../../constants/commondata';
import RadioButton from '../../radioButton/radio';
import { Validator, ValidCase } from '../../validator';
import currencyFormatter from 'currency-formatter'
import show_button from '../../../assets/images/icons/show.svg';
import hide_button from '../../../assets/images/icons/hide.svg';

class TravellerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSuggestionValue(suggestion) {
    if (this.autoCompleteType === 'firstname') {
      return suggestion.firstName ? suggestion.firstName : '';
    }
    if (this.autoCompleteType === 'lastname') {
      return suggestion.lastName ? suggestion.lastName : '';
    }
    if (this.autoCompleteType === 'email') {
      return suggestion.email ? suggestion.email : '';
    }
    return suggestion.phone ? suggestion.phone : '';
  }

  isValidManagerEmail() {
    if (
      this.props.customer.corporateSelectedInfo
      && this.props.customer.corporateSelectedInfo.managerEmail
    ) return Validation.validateMultiEmail(this.props.customer.corporateSelectedInfo.managerEmail);
    return true;
  }

  renderSuggestion = (suggestion, { query }) => {
    return (
      <div className="suggestion-content">
        <div className="name">
          {suggestion.firstName}
          {' '}
          {suggestion.lastName}
-
          {suggestion.phone !== suggestion.userId ? suggestion.phone : null}
        </div>
        <div className="email">{suggestion.email}</div>
      </div>
    );
  }

  render() {
    const {
      user,
      data,
      handleChangeTravelerType,
      selectedFleet,
      corporateCompany,
      handleCompanyChange,
      isSubmited,
      valid,
      handleChangePhone,
      suggestions,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      customerAutocompleteSlectedHandle,
      handleBlurPhone,
      promo,
      CustomerVipClickHandle,
      ValidatorCallback,
      phoneStatus,
      handleChangeFirstNameCorporate,
      newBooking,
      handleChangeEmailCorporate,
      customer,
      handleChangeDepartment,
      handleChangeManagerName,
      handleChangeManagerEmail,
      handleChangeFirstName,
      handleChangeLastNameCorporate,
      traveler_type,
      cartypeSelected,
      isShowAll,
      handleShowMoreCustomer
    } = this.props;

    let REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION = false;
    if (typeof process.env.REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION === 'string') {
      REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION = process.env.REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION == 'true';
    } else {
      REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION = process.env.REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION;
    }

    let superHelper = (CCLiteCommonFunc.isBookingStatusCompleted(data.status) && data.superHelper) ? 
                  true : cartypeSelected && cartypeSelected.superHelper
    
    // const isPhoneRequire = data.isAddCredit
    //   || (newBooking.locationService && newBooking.locationService.crossZone)
    //   || data.traveler_type === TRAVELER_TYPE.Corporate
    //   || data.dispatch3rd
    //   || (data.paymentMethod == paymentMethodNumber.personalCard
    //     || data.paymentMethod == paymentMethodNumber.corporateCard)
    //   || REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION
    //   || superHelper;
    const isPhoneRequire = false

    const isLastNameRequire = REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION
      || (((selectedFleet.creditConfig.multiGateway
        && newBooking.locationService
        && !_.isEmpty(newBooking.locationService.paymentGateways)
        && newBooking.locationService.paymentGateways.requireName)
        || !selectedFleet.creditConfig.multiGateway)
        && data.isAddCredit);

    const isFirstNameRequire = REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION
      || (((selectedFleet.creditConfig.multiGateway
        && newBooking.locationService
        && !_.isEmpty(newBooking.locationService.paymentGateways)
        && newBooking.locationService.paymentGateways.requireName)
        || !selectedFleet.creditConfig.multiGateway)
        && data.isAddCredit);

    const isEmailRequire = REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION
      || (((selectedFleet.creditConfig.multiGateway
        && newBooking.locationService
        && !_.isEmpty(newBooking.locationService.paymentGateways)
        && newBooking.locationService.paymentGateways.requireEmail)
        || !selectedFleet.creditConfig.multiGateway)
        && data.isAddCredit);

    const isBookingDetail = data.bookId;

    // let isShowTravlerCorporate = data.traveler_type == TRAVELER_TYPE.Corporate
    //   && selectedFleet.generalSetting.advanceInfoCorp
    //   && customer;
    // if (isBookingDetail) {
    //   isShowTravlerCorporate = data.travelerType == TRAVELER_TYPE.Corporate
    //     && selectedFleet.generalSetting.advanceInfoCorp
    //     && customer;
    // }
    let isShowTravlerCorporate = true

    return (
      <div>
        <FormGroupTitle>
          {/* <Translate value="newbooking.TRAVELER_INFORMATION" /> */}
          Crew Info
        </FormGroupTitle>

        {/* {!isBookingDetail
          && ((user.userType == userType.FleetAdmin || user.userType == userType.FleetUser)
            && selectedFleet.moduleSettings.airlineBusiness
            && corporateCompany
            && corporateCompany.length > 0) && (
            <FormGroup className="radio-button-group">
              <RadioButton
                text={
                  I18n.t('newbooking.Individual') 
                }
                value="0"
                onChange={handleChangeTravelerType}
                name="trevellerType"
                id="trevellerType_0"
                checked={data.traveler_type == '0'}
                disabled={isBookingDetail}
              />
              <RadioButton
                text={
                  I18n.t('newbooking.Corporation')
                }
                value="1"
                onChange={handleChangeTravelerType}
                name="trevellerType"
                id="trevellerType_1"
                disabled={isBookingDetail}
                checked={data.traveler_type == '1'}
              />
            </FormGroup>
        )} */}
        {/* {isBookingDetail ? (
          <FormGroup>
            <FormControl
              className="form-custom"
              placeholder={I18n.t('newbooking.Company')}
              value={
                data.travelerType
                  ? I18n.t('newbooking.Corporation')
                  : I18n.t('newbooking.Individual')
              }
              disabled
            />
          </FormGroup>
        ) : (
          ''
        )} */}
        {!isBookingDetail && (user.userType == userType.FleetAdmin || user.userType == userType.FleetUser) ? (
            <FormGroup>
              <FormControl
                as="select"
                className="form-custom"
                placeholder={I18n.t('newbooking.Company')}
                value={data.company}
                onChange={handleCompanyChange}
              >
                {corporateCompany.map(corp => (
                  <option key={corp._id} value={corp._id}>
                    {corp.companyInfo.name}
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          ) : null}
        {isBookingDetail
        && (user.userType == userType.FleetAdmin || user.userType == userType.FleetUser) ? (
            <FormGroup>
              <FormControl
                type="text"
                className="form-custom"
                value={data.corporateInfo.name}
                placeholder={I18n.t('bookingdetail.Company')}
                disabled
              />
            </FormGroup>
          ) : (
            ''
          )}
        <FormGroup>
          {!isBookingDetail ? (
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.phone === false || valid.phoneStatus == false
                    ? 'error'
                    : null
              }
            >
              <div className="customer-phone">
                {(user.userType == userType.FleetAdmin || user.userType == userType.FleetUser)
                && !isBookingDetail ? (
                  <IntlTelInputApp
                    css={['intl-tel-input', 'form-control form-custom']}
                    utilsScript="libphonenumber.js"
                    value={data.phone}
                    onPhoneNumberChange={handleChangePhone}
                    suggestions={suggestions || []}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    onSuggestionSelected={customerAutocompleteSlectedHandle}
                    onPhoneNumberBlur={handleBlurPhone}
                    placeholder={`${I18n.t('newbooking.Phone_number')}${
                      isPhoneRequire ? ' *' : ''
                    }`}
                    disabled={!!promo}
                  />
                  ) : (
                    <IntlTelInputApp
                      css={['intl-tel-input', 'form-control form-custom']}
                      utilsScript="libphonenumber.js"
                      value={data.phone}
                      disabled={!!promo}
                      onPhoneNumberChange={handleChangePhone}
                      onPhoneNumberBlur={handleBlurPhone}
                    />
                  )}

                <div className="customer-rank-container">
                  <div
                    className={data.isCustomerVip ? 'hover rank vip' : 'hover rank'}
                    onClick={CustomerVipClickHandle}
                  >
                    <span>VIP</span>
                  </div>
                </div>
              </div>
              <Validator
                callback={ValidatorCallback}
                id="phone"
                disabled={
                  !(
                    data.isAddCredit
                    || (newBooking.locationService && newBooking.locationService.crossZone)
                    || traveler_type === TRAVELER_TYPE.Corporate
                    || data.dispatch3rd
                    || (data.paymentMethod == paymentMethodNumber.personalCard
                      || data.paymentMethod == paymentMethodNumber.corporateCard)
                    || REACT_APP_REQUIRED_EXTRA_CUSTOMER_INFORMATION
                    || superHelper
                  )
                }
              >
                <ValidCase
                  valid={!Validation.isStringEmpty(data.phone)}
                  hide
                  message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
                />
              </Validator>
              <Validator
                callback={ValidatorCallback}
                id="phoneStatus"
                disabled={Validation.isStringEmpty(data.phone)}
              >
                <ValidCase valid={phoneStatus} hide />
              </Validator>
            </FormGroup>
          ) : (
            <FormGroup>
              <div className="customer-phone">
                <FormControl
                  type="text"
                  className="form-custom"
                  value={data.psgInfo.phone}
                  placeholder={I18n.t('bookingdetail.Phone_number')}
                  disabled
                />
                <div className="customer-rank-container">
                  <div className={data.psgInfo.rank ? 'hover rank vip' : 'hover rank'}>
                    <span>VIP</span>
                  </div>
                </div>
              </div>
            </FormGroup>
          )}
        </FormGroup>

        {!isBookingDetail ? (
          <FormGroup
            className={!isSubmited ? null : valid.First_name == false ? 'error' : null}
          >
            {(user.userType == userType.FleetAdmin || user.userType == userType.FleetUser)
            && !isBookingDetail ? (
              <Autosuggest
                suggestions={suggestions || []}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={customerAutocompleteSlectedHandle}
                shouldRenderSuggestions={() => true}
                inputProps={{
                  className: 'form-control form-custom',
                  value: data.firstname,
                  placeholder: I18n.t('newbooking.First_name') + (isFirstNameRequire ? ' *' : ''),
                  onChange: handleChangeFirstName,
                  disabled: !!promo
                }}
              />
              ) : (
                <FormControl
                  type="text"
                  value={data.firstname}
                  onChange={handleChangeFirstNameCorporate}
                  disabled={!!promo}
                  className="form-custom"
                  placeholder={I18n.t('newbooking.First_name') + (isFirstNameRequire ? ' *' : '')}
                />
              )}

            <Validator callback={ValidatorCallback} id="First_name" disabled={!isFirstNameRequire}>
              <ValidCase
                valid={!Validation.isStringEmpty(data.firstname)}
                message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
                hide
              />
            </Validator>
          </FormGroup>
        ) : (
          <FormGroup>
            <FormControl
              type="text"
              className="form-custom"
              value={data.psgInfo.firstName}
              placeholder={I18n.t('bookingdetail.First_name')}
              disabled
            />
          </FormGroup>
        )}
        {isBookingDetail ? (
          <FormGroup>
            <FormControl
              type="text"
              value={data.psgInfo.lastName}
              className="form-custom"
              placeholder={`${I18n.t('newbooking.Last_name')} ${isLastNameRequire ? '*' : ''}`}
              disabled
            />
          </FormGroup>
        ) : (
          <FormGroup
          className={!isSubmited ? null : valid.Last_name == false ? 'error' : null}
          >
            <FormControl
              type="text"
              value={data.lastname}
              onChange={handleChangeLastNameCorporate}
              disabled={!!promo}
              className="form-custom"
              placeholder={`${I18n.t('newbooking.Last_name')} ${isLastNameRequire ? '*' : ''}`}
            />
            <Validator callback={ValidatorCallback} id="Last_name" disabled={!isLastNameRequire}>
              <ValidCase
                valid={!Validation.isStringEmpty(data.lastname)}
                hide
                message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
              />
            </Validator>
          </FormGroup>
        )}

        {isBookingDetail ? (
          <FormGroup>
            <FormControl
              type="text"
              placeholder={I18n.t('newbooking.Email_address') + (isEmailRequire ? ' *' : '')}
              value={data.psgInfo.email}
              className="form-custom"
              disabled
            />
            {data.outStanding && data.outStanding.length ? (
              <div className="text-outstanding-warning">
                {' '}
                <Translate
                  value="newbooking.Warning_pending_payment"
                  fare={currencyFormatter.format(data.outStanding[0].amount, {
                    code: data.outStanding[0].currencyISO,
                  })}
                />
                {' '}
              </div>
            ) : null}
          </FormGroup>
        ) : (
          <FormGroup className={!isSubmited ? null : valid.email === false ? 'error' : null}>
            <FormControl
              type="text"
              placeholder={I18n.t('newbooking.Email_address') + (isEmailRequire ? ' *' : '')}
              value={data.email}
              onChange={handleChangeEmailCorporate}
              disabled={!!promo}
              className="form-custom"
            />

            <Validator callback={ValidatorCallback} id="email">
              <ValidCase
                hide={!isSubmited}
                valid={Validation.validateEmail(data.email)}
                message={I18n.t('messages.commonMessages.invalid_email_format')}
              />
              <ValidCase
                valid={!(Validation.isStringEmpty(data.email) && isEmailRequire)}
                hide
                message={I18n.t('newbooking.Please_fill_in_all_the_required_fields')}
              />
            </Validator>
            {data.outStanding && data.outStanding.length ? (
              <div className="text-outstanding-warning">
                {' '}
                <Translate
                  value="newbooking.Warning_pending_payment"
                  fare={currencyFormatter.format(data.outStanding[0].amount, {
                    code: data.outStanding[0].currencyISO,
                  })}
                />
                {' '}
              </div>
            ) : null}
          </FormGroup>
        )}

        {isShowTravlerCorporate && isShowAll ? (
          <FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={handleChangeDepartment}
                value={
                  isBookingDetail
                    ? data.corporateInfo.department
                    : customer.corporateSelectedInfo
                      ? customer.corporateSelectedInfo.department || ''
                      : ''
                }
                placeholder={I18n.t('newbooking.Department')}
                disabled={!!promo || isBookingDetail}
                className="form-custom"
              />
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                onChange={handleChangeManagerName}
                value={
                  isBookingDetail
                    ? data.corporateInfo.managerName
                    : customer.corporateSelectedInfo
                      ? customer.corporateSelectedInfo.managerName || ''
                      : ''
                }
                placeholder={I18n.t('newbooking.Manager_name')}
                disabled={!!promo || isBookingDetail}
                className="form-custom"
              />
            </FormGroup>

            <FormGroup
              className={!isSubmited ? null : valid.Manager_email === false ? 'error' : null}
            >
              <FormControl
                type="email"
                onChange={handleChangeManagerEmail}
                value={
                  isBookingDetail
                    ? data.corporateInfo.managerEmail
                    : customer.corporateSelectedInfo
                      ? customer.corporateSelectedInfo.managerEmail || ''
                      : ''
                }
                placeholder={`${I18n.t('newbooking.Manager_email')}`}
                disabled={!!promo || isBookingDetail}
                className="form-custom"
              />
              <Validator
                callback={ValidatorCallback}
                id="Manager_email"
                disabled={traveler_type === TRAVELER_TYPE.Individual}
              >
                <ValidCase
                  valid={this.isValidManagerEmail()}
                  hide={!isSubmited}
                  message={I18n.t('messages.commonMessages.invalid_email_format')}
                />
              </Validator>
            </FormGroup>
          </FormGroup>
        ) : (
          ''
        )}

        {isShowTravlerCorporate
          && (isShowAll ? (
            <FormGroup
              className="add-extra-des-btn"
              onClick={() => {
                handleShowMoreCustomer(false);
              }}
            >
              <Image className="add-icon" src={hide_button} />
              <Translate value="newbooking.Hide" />
            </FormGroup>
          ) : (
            <FormGroup
              className="add-extra-des-btn"
              onClick={() => {
                handleShowMoreCustomer(true);
              }}
            >
              <Image className="add-icon" src={show_button} />
              <Translate value="newbooking.Show_more" />
            </FormGroup>
          ))}
      </div>
    );
  }
}

export default TravellerInfo;