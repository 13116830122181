import {
    checkExistCorporateTravellerPhoneApi,
    checkExistCorporateTravellerUserNameApi, 
    corprateCompaniesApi, 
    createCorporateApi, 
    updateCorporateApi, 
    detailCorporateApi, 
    activeCorporateApi,
    multiActiveCorporateApi, 
    multiDeleteCorporateApi, 
    createTravelerApi, 
    updateTravelerApi, 
    findTravelerApi, 
    activeTravelerApi,
    deleteTravelerApi, 
    updatePaymentCorporateApi, 
    addCardCorporateApi, 
    deleteCardCorporateApi, 
    addUserCorporateApi, 
    updateUserCorporateApi, 
    findUserCorporateApi, 
    deleteUserCorporateApi,
    fetchCarTypeCorporateApi, 
    updatePricingCorporateApi,
    updateSupplierCorporateApi, 
    updateCarTypeCorporateApi,
    assignRateCorporateApi, 
    resetPasswordCorporateApi,
    topUpCorporateApi,
    countTravelerApi,
    countCorporateApi,
    findCorpAlias
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";
import queryString from 'query-string'

function getCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        corprateCompaniesApi,
        config,
        null,
        null,
        null,
        true
    );
}

function findAliasUrl(options = {}){
    options = Object.assign({}, options);
    const config = {
        method: "GET",
    };
    const stringified = queryString.stringify(options);
    let uri = `${findCorpAlias}?${stringified}`;
  return callApi(
    uri,
    config,
    null,
    null,
    null,
    false
  );
}

function countCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        countCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function multiDeleteCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        multiDeleteCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        multiDeleteCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function checkExistCorporateTravellerPhone(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        checkExistCorporateTravellerPhoneApi,
        config,
        null,
        null,
        null,
        false
    );
}

function checkExistCorporateTravellerUsername(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        checkExistCorporateTravellerUserNameApi,
        config,
        null,
        null,
        null,
        false
    );
}

function activeCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function multiActiveCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        multiActiveCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options),
    };
    return callApi(
        options._id ? updateCorporateApi : createCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}
function detailCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        detailCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findTravelerApi,
        config,
        null,
        null,
        null,
        true
    );
}

function countTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        countTravelerApi,
        config,
        null,
        null,
        null,
        true
    );
}

function activeTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        activeTravelerApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        options._id ? updateTravelerApi : createTravelerApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteTraveler(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteTravelerApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getOperator(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        findUserCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function operateOperator(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        options._id ? updateUserCorporateApi : addUserCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteUserCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteUserCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function addCardCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        addCardCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function deleteCardCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "delete",
        body: JSON.stringify(options)
    };
    return callApi(
        deleteCardCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function updatePaymentCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updatePaymentCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function updatePricingCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updatePricingCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function updateSupplierCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updateSupplierCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}
function updateCarTypeCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        updateCarTypeCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function getCarTypeCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        fetchCarTypeCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function resetPasswordCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        resetPasswordCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function assignRateCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        assignRateCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

function topUpCorporate(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        topUpCorporateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export {
    checkExistCorporateTravellerPhone, 
    checkExistCorporateTravellerUsername, 
    getCorporate, 
    detailCorporate, 
    activeCorporate, 
    multiActiveCorporate, 
    multiDeleteCorporate, 
    deleteCorporate, 
    operateCorporate,
    getTraveler, 
    activeTraveler, 
    operateTraveler, 
    deleteTraveler, 
    getOperator, 
    operateOperator, 
    deleteUserCorporate, 
    addCardCorporate, 
    deleteCardCorporate,
    getCarTypeCorporate, 
    updatePaymentCorporate, 
    updatePricingCorporate, 
    resetPasswordCorporate, 
    assignRateCorporate, 
    topUpCorporate,
    countTraveler,
    countCorporate,
    updateSupplierCorporate,
    updateCarTypeCorporate,
    findAliasUrl
}
