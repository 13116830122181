import React from 'react';
import ReportUtils from '../base/utils';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

export const fields = [
  {
    key: 'date',
    label: 'report.result.corporate.date',
    summary: 'totalBookings',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.corporate.bookingId',
  },
  {
    key: 'corporateName',
    label: 'report.result.corporate.Corporate',
    summary: 'corporateName',
  },
  {
    key: 'operatorName',
    label: 'report.result.corporate.operatorName',
    summary: 'operatorName',
  },
  {
    key: 'corpTraveler',
    label: 'report.result.corporate.corpTraveler',
    summary: 'corpTraveler',
  },
  {
    key: 'paxNumber',
    label: 'cue.Pax',
    summary: 'paxNumber',
  },
  {
    key: 'luggageNumber',
    label: 'cue.Luggage',
  },
  {
    key: 'travelerSignature',
    label: 'report.result.corporate.travelerSignature',
    summary: 'travelerSignature',
    // isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
    //isImage: true
    link: {
      label: 'report.result.bookingDetails.view',
      name: 'showTravelerSignature',
      notMergeParams: true,
      description: 'Show travelerSignature of this booking',
      excludeParams: ['txtSearch', 'pickUpZone'],
      disable(doc) {
        if (!doc.travelerSignature || !doc.travelerSignature[0]) {
          return true;
        }
        if (doc.travelerSignature.length === 0) {
          return true;
        }
      },
    },
  },
  {
    key: 'corpId',
    label: 'report.result.corporate.corpId',
    summary: 'corpId',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'corpDivision',
    label: 'report.result.corporate.corpDivision',
    summary: 'corpDivision',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'costCentre',
    label: 'report.result.corporate.costCentre',
    summary: 'costCentre',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'corpPO',
    label: 'report.result.corporate.corpPO',
    summary: 'corpPO',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'managerName',
    label: 'report.result.corporate.managerName',
    summary: 'managerName',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'managerEmail',
    label: 'report.result.corporate.managerEmail',
    summary: 'managerEmail',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'title',
    label: 'report.result.corporate.title',
    summary: 'title',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'department',
    label: 'report.result.corporate.department',
    summary: 'department',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },

  {
    key: 'clientCaseMatter',
    label: 'report.result.corporate.clientCaseMatter',
    summary: 'clientCaseMatter',
  },
  {
    key: 'chargeCode',
    label: 'report.result.corporate.chargeCode',
    summary: 'chargeCode',
  },
  {
    key: 'driverName',
    label: 'report.result.corporate.driverName',
    summary: 'driverName',
  },
  {
    key: 'pickupLocation',
    label: 'report.result.corporate.pickupLocation',
    manualTooltip: (value, doc, options) => {
      if (Array.isArray(doc.puPoints) && doc.puPoints.length > 1) {
        return <>{<p>{doc.puPoints.join('\n\n')}</p>}</>;
      }
      return null;
    },
    summary: 'pickupLocation',
  },
  {
    key: 'destination',
    label: 'report.result.corporate.destination',
    manualTooltip: (value, doc, options) => {
      if (Array.isArray(doc.doPoints) && doc.doPoints.length > 1) {
        return <>{<p>{doc.doPoints.join('\n\n')}</p>}</>;
      }
      return null;
    },
    summary: 'destination',
  },
  {
    key: 'total',
    label: 'report.result.corporate.total',
    summary: 'total',
  },
  {
    key: 'subtotal',
    label: 'report.result.corporate.subtotal',
    summary: 'subtotal',
  },
  {
    key: 'subTotalFF',
    label: 'report.result.corporate.subTotalFF',
    summary: 'subTotalFF',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(
        modules,
        (m) => m.name === 'Driver to pax' || m.name === 'Pax refers Pax'
      );
    },
  },
  {
    key: 'commission',
    label: 'report.result.corporate.commission',
    summary: 'commission',
  },
  {
    key: 'paidBy',
    label: 'report.result.corporate.paidBy',
    summary: 'paidBy',
    mutate: (value) => {
      if(value === 'mDispatcher') return 'partner'  
      if(value === 'BankOfGeorgia') return 'Bank Of Georgia'  
      return value
    }
  },
  {
    key: 'paymentStatus',
    label: 'report.query.paymentStatus',
    summary: 'paymentStatus',
    mutate: ReportUtils.mappingPaymentStatus,
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
];

export const fareFields = ['total', 'subtotal', 'subTotalFF', 'commission'];
