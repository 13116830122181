import styled from 'styled-components';
import { FormControl,FormGroup } from 'react-bootstrap';

export const ContentInputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    :hover {
      color: #04be76;
      cursor: pointer;
    }
  }
`;

export const ContentInput = styled(FormControl)`
  resize: vertical;
  min-height: 150px;
  width: 100%;
  padding: 10px;
  border-radius: 5px
`

export const MirrorArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 6px 12px;
  z-index: -1;
  opacity: 0;
  line-height: 1.42857143;
  font-size: 14px;
  white-space: pre-wrap;
  white-space-collapse: preserve;
`

export const ContentInputFormGroup = styled(FormGroup)`
  position: relative;
`