import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import {
  FormGroup,
  FormControl,
  Button,
  ButtonToolbar,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'; 
import PropTypes from 'prop-types';
import StickyTable from '../../components/table/stickyTable/StickyTable';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import CCDropDown from '../../components/dropdown/ccDropDown';
import iconActivate from '../../assets/images/active.png';
import {
  getCorporate,
  activeCorporate,
  multiActiveCorporate,
  multiDeleteCorporate,
} from '../../actions/corporateAction';
import Confirm from '../../components/confirm/Confirm';
import { Columns, CorporateActionMenuItem } from './tableHeaderColums';
import TableActions from '../../components/table/tableAction/TableActions';
import { BsSearch } from 'react-icons/bs';
import './style.scss';
import * as settingActions from '../../actions/settingActions';
import ExportComponent from '../../components/ExportComponent';
import { bindActionCreators } from 'redux';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const CONFIRM_DELETE_ID = 1;
const CONFIRM_ACTIVATE_ID = 2;
const CONFIRM_DEACTIVATE_ID = 3;
const CONFIRM_DELETE_LINK_ID = 4;
const CORPORATE_TYPE = [
  { label: 'Standard Corporate', key: 'standard' },
  { label: 'Airline', key: 'airline' },
];
let deleteUserId;
class Corporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      confirm: null,
      sortType: -1,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
        active: null,
        name: I18n.t('corporate.Corporates'),
      },
      str: '',
      footerLoading: true,
      selectedCompanyType: ['standard', 'airline'],
    };
    this.getListCorporates = this.getListCorporates.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChangeAllCorporateStatus =
      this.handleChangeAllCorporateStatus.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleSortCorporateClick = this.handleSortCorporateClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth &&
      this.props.auth.user &&
      (this.props.auth.user.userType === 'CorporateAdmin' ||
        this.props.auth.user.userType === 'CorporateUser')
    ) {
      this.props.router.push(
        `/corporate/edit/${this.props.auth.user.corporateId}`
      );
    } else {
      this.getListCorporates(0, null, true);
    }
  }

  handleMenuClick(eventKey, detailItem) {
    if (eventKey == 0) {
      this.props.router.push({
        pathname: `/corporate/edit/${detailItem._id}`,
      });
    } else if (eventKey == 1) {
      this.changeCorporateStatus([detailItem._id], detailItem.isActive);
    } else if (eventKey == 3) {
      this.props.router.push({
        pathname: `/corporate/view/${detailItem._id}`,
      });
    } else {
      deleteUserId = detailItem._id;
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_LINK_ID,
          title: I18n.t('corporate.Delete'),
          body: I18n.t('corporate.CONFIRM_DELETE'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  }

  getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarMarginBottom = 10;
    const toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0;
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight - 35;
  };

  handlePaginationSelect = (page) => this.getListCorporates(parseInt(page));

  handleNumItemsPerPageChange = (limit) => {
    this.getListCorporates(0, limit);
  };

  getListCorporates(activePage, limit, countCorp, corporateType = []) {
    const param = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
      },
    };

    if (corporateType.length === 1 && corporateType[0] === 'standard')
      param.query.isAirline = false;
    if (corporateType.length === 1 && corporateType[0] === 'airline')
      param.query.isAirline = true;

    if (this.state.sortName) {
      param.sort = { [this.state.sortName]: this.state.sortType };
    } else {
      param.sort = { createdDate: this.state.sortType };
    }
    if (this.state.str) {
      param.query.str = this.state.str.trim();
      if (!activePage) {
        param.page = 0;
      }
    }
    this.setState({
      isLoading: true,
      footerLoading: countCorp,
    });
    this.props.getCorporate(param).then((data) => {
      if (data.ok) {
        const { page, limit } = data.res;
        this.setState({
          corporate: data.res,
          isLoading: false,
          footerData: {
            ...this.state.footerData,
            page,
            limit,
            total: data.res.total,
          },
          footerLoading: false,
        });
      }
    });
  }

  handleSelectRowClick = (checked, id) => {
    const listUser = _.cloneDeep(this.state.corporate.list);
    _.forEach(listUser, (item) => {
      if (id === item._id) {
        item.checked = checked;
        return false;
      }
    });
    this.setState({
      corporate: { ...this.state.corporate, list: listUser },
    });
  };

  handleSelectAllClick = (e) => {
    const { target } = e;
    const listCorp = _.cloneDeep(this.state.corporate.list);
    _.forEach(listCorp, (item) => {
      item.checked = target.checked;
    });
    this.setState({
      corporate: { ...this.state.corporate, list: listCorp },
    });
  };

  changeCorporateStatus = (userIds, isActive) => {
    if (userIds.length == 1) {
      this.props
        .activeCorporate({
          _id: userIds[0],
          fleetId: this.props.auth.selectedFleet.fleetId,
          status: !isActive,
        })
        .then((data) => {
          if (data.ok) {
            const listUser = _.cloneDeep(this.state.corporate.list);
            _.forEach(listUser, (item) => {
              if (item._id === userIds[0]) {
                item.isActive = !isActive;
                return false;
              }
            });
            let nModified = 0;
            if (data.res.updated) {
              nModified = 1;
            }
            const active = isActive
              ? this.state.corporate.active - nModified
              : this.state.corporate.active + nModified;
            this.setState({
              corporate: {
                ...this.state.corporate,
                list: listUser,
                active,
              },
              footerData: { ...this.state.footerData, active },
            });
          } else {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    } else {
      this.props
        .multiActiveCorporate({
          ids: userIds,
          fleetId: this.props.auth.selectedFleet.fleetId,
          status: !isActive,
        })
        .then((data) => {
          if (data.ok) {
            const listUser = _.cloneDeep(this.state.corporate.list);
            _.forEach(listUser, (user) => {
              if (isActive) {
                if (data.res.list.includes(user._id)) {
                  user.isActive = !isActive;
                }
              } else if (userIds.includes(user._id)) {
                user.isActive = !isActive;
              }
              user.checked = false;
            });
            const active = isActive
              ? this.state.corporate.active - data.res.nModified
              : this.state.corporate.active + data.res.nModified;
            this.setState({
              corporate: {
                ...this.state.corporate,
                list: listUser,
                active,
              },
              footerData: { ...this.state.footerData, active },
            });
          } else {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    }
  };

  handleSortCorporateClick(sortName) {
    if (this.state.isLoading) return;
    this.setState(
      {
        sortName,
        sortType: this.state.sortType == 1 ? -1 : 1,
      },
      function () {
        this.getListCorporates();
      }
    );
  }

  handleSearchChange(str) {
    this.setState({
      str,
    });
  }

  handleSearchKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.state.isLoading) return;
      this.getListCorporates(0, null, true);
    }
  }

  handleAddClick() {
    this.props.router.push('/corporate/add');
  }

  prepareParamExport = () => {
    const query = { fleetId: this.props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.txtSearch = this.state.str;
    }
    if (this.state.selectedCompanyType) {
      query.companyType = this.state.selectedCompanyType.length > 1 ? 'all' : this.state.selectedCompanyType[0];
    }
    const sort = { createdDate: -1 };
    query.timezone = this.props.auth.selectedFleet.timezone;

    return {
      query,
      sort,
      total: _.get(this.state.footerData, 'total', 0),
    };
  };


  handleChangeAllCorporateStatus(isActive) {
    if (this.state.corporate.list.filter((user) => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: isActive
            ? I18n.t('corporate.Deactivate')
            : I18n.t('corporate.Activate'),
          body: isActive
            ? I18n.t('corporate.ALERT_SELECT_DEACTIVATE')
            : I18n.t('corporate.ALERT_SELECT_ACTIVATE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: isActive ? CONFIRM_DEACTIVATE_ID : CONFIRM_ACTIVATE_ID,
          title: isActive
            ? I18n.t('corporate.Deactivate')
            : I18n.t('corporate.Activate'),
          body: isActive
            ? I18n.t('corporate.CONFIRM_DEACTIVATE_MULTI')
            : I18n.t('corporate.CONFIRM_ACTIVATE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  }

  handleDeleteClick() {
    if (this.state.corporate.list.filter((user) => user.checked).length == 0) {
      this.setState({
        confirm: {
          title: I18n.t('corporate.Delete'),
          body: I18n.t('corporate.ALERT_SELECT_DELETE'),
        },
      });
    } else {
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_ID,
          title: I18n.t('corporate.Delete'),
          body: I18n.t('corporate.CONFIRM_DELETE_MULTI'),
          buttonTitle: 'Yes',
          closeButtonText: 'No',
        },
      });
    }
  }

  handleConfirmCloseClick() {
    this.setState({ confirm: null });
  }

  handleConfirmButtonClick(id) {
    switch (id) {
      case CONFIRM_DELETE_ID:
        {
          if (this.state.corporate.list) {
            const listUser = this.state.corporate.list
              .filter((user) => user.checked)
              .map((user) => user._id);
            if (listUser && listUser.length > 0) {
              this.props
                .multiDeleteCorporate({
                  ids: listUser,
                  fleetId: this.props.auth.selectedFleet.fleetId,
                })
                .then((data) => {
                  if (data.ok) {
                    this.getListCorporates(null, null, true);
                  } else {
                  }
                });
            }
          }
        }
        break;
      case CONFIRM_ACTIVATE_ID:
        {
          if (this.state.corporate.list) {
            const listUser = this.state.corporate.list
              .filter((user) => user.checked)
              .map((user) => user._id);
            if (listUser && listUser.length > 0) {
              this.changeCorporateStatus(listUser, false);
            }
          }
        }
        break;
      case CONFIRM_DEACTIVATE_ID:
        {
          if (this.state.corporate.list) {
            const listUser = this.state.corporate.list
              .filter((user) => user.checked)
              .map((user) => user._id);
            if (listUser && listUser.length > 0) {
              this.changeCorporateStatus(listUser, true);
            }
          }
        }
        break;
      case CONFIRM_DELETE_LINK_ID:
        {
          this.props
            .multiDeleteCorporate({
              ids: [deleteUserId],
              fleetId: this.props.auth.selectedFleet.fleetId,
            })
            .then((data) => {
              if (data.ok) {
                this.getListCorporates(null, null, true);
              } else {
              }
            });
        }
        break;
    }
    this.handleConfirmCloseClick();
  }

  bookingTypeItemClicked = (values) => {
    this.getListCorporates(0, 20, true, values)
    this.setState({
      selectedCompanyType: values,
    });
  };

  renderSelectBoxColumn = () => {
    let isCheckedAll = false;
    if (
      this.state.corporate &&
      this.state.corporate.list &&
      this.state.corporate.list.length > 0
    ) {
      isCheckedAll =
        _.filter(this.state.corporate.list, (item) => item.checked).length ===
        this.state.corporate.list.length;
    }

    return {
      key: 'table-selectbox',
      label: '',
      width: 60,
      fixed: true,
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      customHeader: (columnKey) => (
        <CcCheckbox
          className="table-selectbox all-checkbox"
          checked={isCheckedAll}
          onChange={this.handleSelectAllClick}
        />
      ),
      customCell: (corporate) => (
        <CcCheckbox
          className="table-selectbox"
          checked={corporate.checked}
          onChange={(e) => {
            this.handleSelectRowClick(e.target.checked, corporate._id);
          }}
        />
      ),
    };
  };

  getTableColumns = () => {
    const tableColumns = Object.assign([], Columns);
    // custom data by column
    _.forEach(tableColumns, (col) => {
      switch (col.key) {
        case 'companyInfo.name':
          col.customCell = (corporate) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{corporate.companyInfo.name}</p>
                </Tooltip>
              }
            >
              <a
                className="clickable"
                onClick={() => {
                  !this.props.permissions || this.props.permissions.actions
                    ? this.handleMenuClick(0, corporate)
                    : this.handleMenuClick(3, corporate);
                }}
              >
                {corporate.companyInfo.name}
              </a>
            </OverlayTrigger>
          );
          break;
        case 'createdDate':
          col.customCell = (corporate) => (
            <Moment
              tz={this.props.auth.selectedFleet.timezone}
              format="MM/DD/YYYY"
              date={corporate.createdDate}
            />
          );
          break;
        case 'isAirline':
          col.customCell = (corporate) => {
            return corporate.isAirline ? 'Airline' : 'Standard Corporate';
          };
          break;
        case 'CorporateStatus':
          col.customCell = (data) => (
            <div className={data.isActive ? 'Active' : 'Inactive'}>
              {!this.props.permissions || this.props.permissions.actions ? (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.changeCorporateStatus([data._id], data.isActive);
                  }}
                  className="cursor-pointer"
                >
                  {data.isActive ? (
                    <Translate value="customer.Active" />
                  ) : (
                    <Translate value="customer.Inactive" />
                  )}
                </a>
              ) : data.isActive ? (
                <Translate value="customer.Active" />
              ) : (
                <Translate value="customer.Inactive" />
              )}
            </div>
          );
          break;
        case 'actions':
          col.customCell = (corporate, rowIndex) => {
            let statusClass;
            if (corporate && corporate.isActive) {
              statusClass = 'Active';
            } else {
              statusClass = 'Inactive';
            }
            return (
              <div className="driver-actions-container">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <TableActions
                    rowIndex={rowIndex}
                    rowData={corporate}
                    onSelect={this.handleMenuClick}
                    totalRow={this.state.corporate.list.length}
                    rowHeight={this.state.rowHeight}
                    menuItems={CorporateActionMenuItem[statusClass]}
                    tableHeight={this.state.tableHeight}
                  />
                ) : (
                  <a
                    onClick={() => this.handleMenuClick('3', corporate)}
                    href="javascript:void(0)"
                  >
                    <Translate value="corporate.View" />
                  </a>
                )}
              </div>
            );
          };
          break;
        default:
          break;
      }
    });

    const checkBoxCol = this.renderSelectBoxColumn();
    tableColumns.unshift(checkBoxCol);

    return tableColumns;
  };

  //
  render() {
    const bodyData =
      this.state.corporate && this.state.corporate.list
        ? this.state.corporate.list
        : [];
    const { permissions = null } = this.props || {};
    const { actions = false, export: exportPermit = false } = permissions || {};
    const exportPermission = this.props.auth.user.isAdmin ? true : exportPermit;

    return (
      <div className="content">
        <ButtonToolbar
          className="header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format">
              <FormControl
                className="search-form"
                type="text"
                placeholder={I18n.t('General.search')}
                onChange={(e) => this.handleSearchChange(e.target.value)}
                onKeyPress={this.handleSearchKeyPress}
                value={this.state.str}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {(!permissions || actions) && (
              <React.Fragment>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleAddClick}
                >
                  <Translate value="corporate.Add" />
                </Button>
                <Button
                  className="btn-header text-delete-header"
                  onClick={this.handleDeleteClick}
                >
                  <Translate value="corporate.Delete" />
                </Button>
                <Button
                  className="btn-header text-active-header"
                  onClick={() => {
                    this.handleChangeAllCorporateStatus(false);
                  }}
                >
                  <Translate value="corporate.Activate" />
                </Button>
                <Button
                  className="btn-header text-deactive-header"
                  onClick={() => {
                    this.handleChangeAllCorporateStatus(true);
                  }}
                >
                  <Translate value="corporate.Deactivate" />
                </Button>
              </React.Fragment>
            )}
          </ButtonGroup>
          <ButtonGroup className="group-right">
            {permissions && exportPermission && this.props.auth?.selectedFleet?.generalSetting?.ableToDownloadAccount &&
              <ExportComponent
                prepareParamExport={this.prepareParamExport}
                urlExport={this.props.settingActions.exportCorporateToExcel}
                user={this.props.auth.user}
              />
            }
          </ButtonGroup>
        </ButtonToolbar>
        <div className="customer-toolbar-extend filterCorporate">
          <CCDropDown
            id="pickUpZone-dropdown"
            title={I18n.t('corporate.Company_Type')}
            valueKey="key"
            labelKey="label"
            items={CORPORATE_TYPE}
            selectedItems={this.state.selectedCompanyType}
            onSelect={this.bookingTypeItemClicked}
            multiSelect
            enableAllItemSelect={true}
            noTranslateLabel
            onlyShowAllText
            hasSelectInitial
          />
        </div>

        <div className="gridViewTable" id="corp-table">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            sortHandle={this.handleSortCorporateClick}
            sortType={this.state.sortType}
            sortName={this.state.sortName}
            getTableHeight={this.getTableHeight}
            footerLoading={this.state.footerLoading}
            isLoading={this.state.isLoading}
          />
        </div>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Corporate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCorporate: (options) => dispatch(getCorporate(options)),
    activeCorporate: (options) => dispatch(activeCorporate(options)),
    multiActiveCorporate: (options) => dispatch(multiActiveCorporate(options)),
    multiDeleteCorporate: (options) => dispatch(multiDeleteCorporate(options)),
    settingActions: bindActionCreators(settingActions, dispatch),

  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Corporate);
