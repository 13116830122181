import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Modal, Button } from 'react-bootstrap';

export default function ({ confirmDelete, closeFormModal }) {
  return (
    <Modal onHide={closeFormModal} show backdrop className="confirm">
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value="appBanner.Delete_Banner" />
        </Modal.Title>
        <button type="button" className="close" aria-label="Close" onClick={closeFormModal}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Translate value="appBanner.Delete_confirm_banner" />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-save mr-md" onClick={confirmDelete}>
          <Translate value="appBanner.Yes" />
        </Button>
        <Button className="btn-cancel" onClick={closeFormModal}>
          <Translate value="appBanner.Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
