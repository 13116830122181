import React from 'react';
import { FormGroup, FormControl, Form, Col } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import { ValidCase, Validator } from '../../../../../components/validator';
import { Validation } from '../../../../../utils/commonFunctions';

const CustomizedForm = (props) => {
  const {
    data,
    appType,
    handleCheckEnablePhoneForm,
    handleChangeInputForm,
    disabled,
    valid,
    isSubmited,
    ValidatorCallback,
  } = props;

  const arrFrom = [
    'termOfUse',
    'policy',
    'email',
    'enablePhone',
    'phone',
    'site',
  ];
  return (
    <div>
      {arrFrom.map((item, index) => (
        <Col
          md={12}
          xs={12}
          className={'pl0'}
          style={{
            marginBottom: '20px',
            display:
              item === 'phone' && data.enablePhone === false ? 'none' : 'block',
          }}
          key={index}
        >
          <FormGroup
            className={
              item === 'email' && !isSubmited
                ? null
                : valid[`${appType}_${item}`] === false
                ? 'error'
                : null
            }
          >
            <Col md={4} xs={12} className={'pl0'}>
              {item === 'phone' ? (
                ''
              ) : (
                <Form.Label>
                  <Translate value={`social.${item}`} />
                </Form.Label>
              )}
            </Col>

            <Col md={6} xs={12} className={'pl0'}>
              {item === 'enablePhone' ? (
                <div style={{ marginBottom: '-20px' }}>
                  <CcCheckbox
                    onChange={(e) =>
                      handleCheckEnablePhoneForm(appType, e.target.checked)
                    }
                    checked={data[item]}
                    disabled={disabled}
                  />
                </div>
              ) : (
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={(e) =>
                    handleChangeInputForm(appType, item, e.target.value)
                  }
                  value={data[item]}
                  disabled={disabled}
                />
              )}
              {item === 'email' && <Validator id={`${appType}_${item}`} callback={ValidatorCallback}>
                <ValidCase
                  valid={Validation.validateEmail(data[item])}
                  message={I18n.t('messages.commonMessages.invalid_email_format')}
                  hide={!isSubmited}
                />
              </Validator>}
            </Col>
          </FormGroup>
        </Col>
      ))}
    </div>
  );
};

export default CustomizedForm;
