import React, { PureComponent } from 'react';
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { bookFrom } from "../../../constants/commondata";
import { I18n } from 'react-redux-i18n';

export default class PassengerColum extends PureComponent {
  render() {
    const { booking, lineShort, isMobile, isHidePhone } = this.props;
    if (!booking.psgInfo) return null;
    let psgName =
      booking.psgInfo.firstName || booking.psgInfo.lastName
        ? `${booking.psgInfo.firstName || ""} ${booking.psgInfo.lastName ||
        ""}`
        : "";
    const phoneNumber = booking?.psgInfo?.firstName !== bookFrom[6]?.value ? booking?.psgInfo?.phone : ''

    // Mobile UI
    if(isMobile) {
      return (
        <p>
          <span className='paxN'>{psgName}</span>
          {
            booking?.psgInfo?.rank === 1 && (
              <span className='vipPax'>{I18n.t('cue.VIP')}</span>
            )
          }
          <span>{!isHidePhone ? phoneNumber : ''}</span>
        </p>
      )
    }

    return (
      <div>
        <div className={`customer-info ${lineShort ? " custom__lineShort" : ""}`}>
          {psgName ? (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{psgName}</p>
                </Tooltip>
              }
            >
              <p className="text-ellipsis limit-character-15">
                {(booking.psgInfo.firstName || "") +
                  " " +
                  (booking.psgInfo.lastName || "")}
              </p>
            </OverlayTrigger>
          ) : null}
          {!isHidePhone && phoneNumber && (
            <>
            {psgName && <div className="straight-line"></div>}

            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <p>{phoneNumber}</p>
                </Tooltip>
              }
            >
              <p className="text-ellipsis limit-character-15">{phoneNumber}</p>
            </OverlayTrigger>
            </>
          )}
        </div>
        {!lineShort && booking.psgInfo.rank === 1 ? ( //rank vip, update constant later
          <div className="vip-image" />
        ) : (
          ""
        )}
        {!lineShort && booking.sos &&
          booking.sos.find(sos => sos.fromApp === "passenger") && (
            <div className="sos-image" />
          )}
      </div>
    );
  }
}
