import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { data } from 'jquery';
import { searchVehicles } from '../bookFunction/bookingInfo';
import { Button } from 'react-bootstrap';

const VehicleAutoComplete = ({
  disabled,
  selectedFleet,
  cartypeSelected,
  supplierCompanies,
  setVehicleAutoComplete,
  vehicle,
  isBookNow,
  vehicleType,
  dataMultiBook,
  showUnassignBtn,
  handleUnassignVehicle,
  isCue,
  drivers,
  vehicleTypes,
  resetCounterVehicleTextSearch: resetTextFromParent,
  ...props
}) => {
  const [vehicleSuggestions, setVehicleSuggestions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [lastAutoCompleteRequestId, setLastAutoCompleteRequestId] = useState();

  const handleVehicleChanged = (e) => {
    setVehicleAutoComplete({});
    setSearchText(e.target.value);
  };

  useEffect(() => {
    setSearchText('');
  }, [resetTextFromParent]);

  useEffect(() => {
    if (vehicle?.plateNumber) {
      setSearchText(vehicle.plateNumber);
    }
  }, []);

  const vehicleAutocompleteSlectedHandle = (__, { suggestion }) => {
    if (lastAutoCompleteRequestId !== null) {
      clearTimeout(lastAutoCompleteRequestId);
    }
    setVehicleAutoComplete(suggestion || {});
  };

  const getVehicleSuggestionValue = (suggestion) => {
    return suggestion.plateNumber;
  };

  const onVehicleSuggestionsClearRequested = () => {
    setVehicleSuggestions([]);
  };

  const renderVehicleSuggestion = (suggestion, { query }) => {
    const plateNumber = suggestion.plateNumber || '',
      vehicleType = suggestion.vehicleType || suggestion?.type?.name || '',
      vehicleModelName =
        suggestion.vehicleModelName || suggestion?.vehicleModel?.name || '',
      vhcColor = suggestion.vhcColor || suggestion.color || '',
      vhcYear = suggestion.vhcYear || suggestion.year || '';

    return (
      <div className={`suggestion-content driver-assign`}>
        <div className="info">
          <span className="">
            {`${plateNumber} 
              ${vehicleType && ` - ${vehicleType}`}
              ${vehicleModelName ? `/${vehicleModelName}` : ''}
              ${vhcColor ? ` - ${vhcColor}` : ''}
              ${vhcYear ? ` - ${vhcYear}` : ''}
            `}
          </span>
        </div>
      </div>
    );
  };

  const onVehicleSuggestionsFetchRequested = ({ value, reason }) => {
    getVehicleSuggestions(value);
  };

  const getVehicleSuggestions = (value) => {
    if (lastAutoCompleteRequestId) {
      clearTimeout(lastAutoCompleteRequestId);
    }

    setLastAutoCompleteRequestId(
      setTimeout(() => {
        getListVehicle(value.trim());
      }, 300)
    );
  };

  const getVehicleBookNow = (driverInfo) => {
    if (driverInfo?.vehicle?.vehicleId) {
      setVehicleSuggestions([
        {
          ...driverInfo.vehicle,
          _id: driverInfo.vehicle.vehicleId,
        },
      ]);
    } else {
      setVehicleSuggestions([]);
    }
  };

  const getListVehicleInDriver = async (textSearch) => {
    // book now
    if (isBookNow) {
      getVehicleBookNow(drivers[0]);
      return;
    }

    // book Reservation
    const regex = textSearch ? new RegExp(textSearch, 'i') : null,
      nonWordBoundary = '[^\\p{L}]',
      vehicleLater = _.flatten(
        drivers.map((drv) => {
          return (
            drv?.vehicles?.filter((vhc) => {
              if (!vehicleTypes.includes(vhc.vehicleType)) return false;

              if (!regex) return true;
              const year = vhc.year ? vhc.year.toString() : '';
              const color = vhc.color || '';
              const vehicleMake = vhc.vehicleModel?.vehicleMake || '';
              const vehicleModelName = vhc.vehicleModel?.name || '';
              const plateNumber = vhc.plateNumber || '';

              return (
                regex.test(year) ||
                regex.test(color) ||
                regex.test(vehicleMake) ||
                regex.test(vehicleModelName) ||
                regex.test(plateNumber) ||
                new RegExp(`(^|${nonWordBoundary})${textSearch}`, 'gui').test(
                  year
                ) ||
                new RegExp(`(^|${nonWordBoundary})${textSearch}`, 'gui').test(
                  color
                ) ||
                new RegExp(`(^|${nonWordBoundary})${textSearch}`, 'gui').test(
                  vehicleMake
                ) ||
                new RegExp(`(^|${nonWordBoundary})${textSearch}`, 'gui').test(
                  vehicleModelName
                )
              );
            }) || []
          );
        })
      );

    setVehicleSuggestions(vehicleLater);
  };

  const handleUnassignVehicleClick = () => {
    setSearchText('');
    handleUnassignVehicle();
  };

  const getListVehicle = async (escapedValue) => {
    // nếu có driver thì chỉ show vehicle của driver trừ khi bật Switch to any vehicle
    if (drivers?.length > 0 && drivers.every((drv) => !drv.switchToAnyVehicle))
      return getListVehicleInDriver(escapedValue);

    // Nếu có driver thì lấy supplier của driver
    const supplierIds =
      drivers?.length > 0
        ? drivers.map((dr) => dr?.company?.companyId)
        : supplierCompanies;
    try {
      if (vehicleTypes?.length > 0 && supplierIds) {
        const result = await props.settingActions?.bookingAutoComplete({
          fleetId: selectedFleet.fleetId,
          supplierIds: supplierIds,
          vehicleTypes: vehicleTypes,
          textSearch: escapedValue,
        });
        if (result.ok && result.res) {
          setVehicleSuggestions(result.res);
          return;
        }
        setVehicleSuggestions([]);
      }
    } catch (error) {
      setVehicleSuggestions([]);
    }
  };

  return (
    <div className="add-on-input-group">
      <Autosuggest
        suggestions={vehicleSuggestions}
        onSuggestionsFetchRequested={onVehicleSuggestionsFetchRequested}
        onSuggestionsClearRequested={onVehicleSuggestionsClearRequested}
        getSuggestionValue={getVehicleSuggestionValue}
        renderSuggestion={renderVehicleSuggestion}
        onSuggestionSelected={vehicleAutocompleteSlectedHandle}
        shouldRenderSuggestions={() => true}
        inputProps={{
          className: 'form-control form-custom',
          value: vehicle?.plateNumber ? vehicle?.plateNumber : searchText || '',
          placeholder: I18n.t('newbooking.Search_Vehicle'),
          onChange: handleVehicleChanged,
          disabled: disabled || isBookNow,
          style: props.styleCustom,
        }}
      />
      {showUnassignBtn && !disabled && (
        <Button
          className="add-on-button btn-header text-add-header"
          onClick={handleUnassignVehicleClick}
        >
          {I18n.t('newbooking.Unassign')}
        </Button>
      )}
    </div>
  );
};

VehicleAutoComplete.propTypes = {
  disabled: PropTypes.bool.isRequired,
  vehicle: PropTypes.object,
  drivers: PropTypes.array,
  isBookNow: PropTypes.bool,
  selectedFleet: PropTypes.object.isRequired,
  cartypeSelected: PropTypes.object.isRequired,
  supplierCompanies: PropTypes.array,
  settingActions: PropTypes.func,
  setVehicleAutoComplete: PropTypes.func,
};

export default VehicleAutoComplete;
