import './timeZonepicker.scss';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import Timezones from './timezones'
import moment from 'moment'
import tz from 'moment-timezone'
import { Navbar, Nav, NavItem, NavDropdown,  FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
// Imagine you have a list of languages that you'd like to autosuggest.

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = (value || '').trim().toLowerCase();
    const regex = new RegExp(inputValue, 'i')
    return Timezones.filter(elem => regex.test(elem.text) || regex.test(elem.timezone))
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.text}
    </div>
);

export default class TimeZonePicker extends Component {
    constructor(props) {
        super(props);

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        let defaultValue = getSuggestions(props.defaultValue)[0];

        this.state = {
            selectedItem: defaultValue,
            value: '',
            suggestions: Timezones || []
        };
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
        if (this.props.onChange) {
            this.props.onChange(event, { newValue })
        }
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            selectedItem: suggestion,
            suggestions: [],
            value: '',
            isShow: false
        });
        if (this.props.onSelected) {
            this.props.onSelected(event, suggestion)
        }
    }
    ShowClick = (e) => {
        this.state.isShow = !this.state.isShow;
        this.setState(this.state, () => {
            setTimeout(() => {
                if (this.nameInput.input) {
                    this.nameInput.input.focus()
                }
            }, 100)

        });
    }
    OnBlur = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isShow: false
        })
        return;
    }
    render() {
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            className: 'form-control form-custom',
            placeholder: 'Select time zone',
            value,
            onChange: this.onChange,
            autoFocus: true,
            onBlur: this.OnBlur
        };

        // Finally, render it!
        return (
            <div className="dropdown form-group time-zone" >
                <span
                    role="button" className="dropdown-toggle form-control" aria-haspopup="true" aria-expanded="false"
                    onClick={this.ShowClick}>
                    {
                        this.state.selectedItem ? <span> {this.state.selectedItem.text} </span> : ''
                    }
                    <span className="caret time-zone-caret"></span>
                </span>
                <ul role="menu" className={this.state.isShow ? "dropdown-menu active time-zone-list" : 'dropdown-menu time-zone-list'} aria-labelledby="basic-nav-dropdown">
                    <li>
                        <Autosuggest
                            theme={{
                                container: 'fleet-react-autosuggest__container',
                                containerOpen: 'fleet-react-autosuggest__container--open',
                                input: 'fleet-react-autosuggest__input form-control',
                                inputOpen: 'fleet-react-autosuggest__input--open',
                                inputFocused: 'fleet-react-autosuggest__input--focused',
                                suggestionsContainer: 'fleet-react-autosuggest__suggestions-container',
                                suggestionsContainerOpen: 'fleet-react-autosuggest__suggestions-container--open',
                                suggestionsList: 'fleet-react-autosuggest__suggestions-list',
                                suggestion: 'fleet-react-autosuggest__suggestion',
                                suggestionFirst: 'fleet-react-autosuggest__suggestion--first',
                                suggestionHighlighted: 'fleet-react-autosuggest__suggestion--highlighted',
                                sectionContainer: 'fleet-react-autosuggest__section-container',
                                sectionContainerFirst: 'fleet-react-autosuggest__section-container--first',
                                sectionTitle: 'fleet-react-autosuggest__section-title'
                            }}
                            ref={(input) => { this.nameInput = input; }}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={this.onSuggestionSelected}
                            shouldRenderSuggestions={() => { return true }}
                            alwaysRenderSuggestions={true}
                            inputProps={inputProps}
                        />
                    </li>
                </ul>
            </div>

        );
    }
}
