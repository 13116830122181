/* global google */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import Promise from 'bluebird';
import {
  FormGroup,
  FormControl,
  InputGroup,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import FareList from './FareList';
import TencentMap from '../../../../components/ZoneTencentMap';
import GettingStartedGoogleMap from '../../../../components/ZoneGoogleMap';
import { ZoneActionStatus } from '../../../../constants/commondata';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../components/validator';
import {
  Validation,
  CCLiteCommonFunc,
  getLanguageAutoCompleteSearch,
} from '../../../../utils/commonFunctions';
import InputRequireInline from '../../../../components/InputRequireInline';
import { getPaths } from './utils';
import Confirm from '../../../../components/confirm/Confirm';
import PlacesAutocomplete from '../../../../components/placesAutocomplete/PlacesAutocomplete';
import { geoPlaceDetailMapProvider } from '../../../../utils/mapUtils';
import '../style.scss';
import { BsSearch } from 'react-icons/bs';
import AutocompleteWith3rd from '../../../../components/qqMap/AutocompleteWith3rd';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

const defaultFareModel = () => ({
  name: '',
  parameter: '',
  type: 'factor',
  parameterDrop: '',
  typeDrop: 'factor',
});

const AutocompleteItem = ({ suggestion }) => (
  <div>
    <i className="fa fa-map-marker" />
    {suggestion}
  </div>
);
const myStyles = {
  input: { width: '100%' },
  autocompleteContainer: { padding: '5px 12px' },
  autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
  autocompleteItemActive: { color: '#04BE76' },
};

class Fare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: '',
      zoneAction: ZoneActionStatus.View,
      zoneDraw: {
        activeZone: null,
        Zones: [],
      },
      defaultZoom: 12,
      polyline: false,
      isSubmitted: false,
      valid: {
        name: true,
        parameter: true,
        type: true,
      },
      fareModel: defaultFareModel(),
    };

    this.getPaths = getPaths.bind(this);
  }

  componentDidMount() {
    const { actions, selectedFleet, params, router } = this.props;
    const getZoneDetailPromise = new Promise((resolve, reject) => {
      actions
        .getZoneDetail({
          fleetId: selectedFleet.fleetId,
          zoneId: params.zoneId,
        })
        .then((data) => {
          if (data.ok && data.res) {
            resolve(data.res);
          } else {
            reject({ error: 'Can not get data' });
          }
        })
        .catch((error) => reject(error));
    });

    const getFaresPromise = new Promise((resolve, reject) => {
      actions
        .getFares({
          fleetId: selectedFleet.fleetId,
          zoneId: params.zoneId,
        })
        .then((data) => {
          if (data.ok && data.res) {
            resolve(data.res);
          } else {
            reject({ error: 'Can not get data' });
          }
        })
        .catch((error) => reject(error));
    });

    Promise.all([getZoneDetailPromise, getFaresPromise])
      .spread((operationZone, fares) => {
        const mapZoneData = this.initMapZone(operationZone, fares);
        this.setState({ ...mapZoneData });
      })
      .catch((error) => {
        this.context.notification('error', I18n.t('General.Can_not_get_data'));
        router.push({ pathname: '/settings/Dynamic_fare' });
      });
  }

  initMapZone = (operationZone, fares) => {
    const { commonData } = this.props;
    const isChina = _.get(commonData, 'location.isChina', false);
    let parsedFares = [];

    _.forEach(fares, (zone) => {
      if (
        zone.geo &&
        zone.geo.coordinates &&
        zone.geo.coordinates[0] &&
        _.isArray(zone.geo.coordinates[0][0])
      ) {
        parsedFares.push({
          _id: zone._id,
          name: zone.name,
          type: zone.type,
          isActive: zone.isActive,
          parameter: zone.parameter,
          pickupZone: zone.pickupZone,
          dropOffZone: zone.dropOffZone,
          parameterDrop: zone.parameterDrop,
          typeDrop: zone.typeDrop,
          path: zone.geo.coordinates[0].map((pos) => {
            return {
              lat: pos[1],
              lng: pos[0],
            };
          }),
        });
      }
    });

    let allPoints = [];

    _.forEach(parsedFares, (item) => {
      allPoints.concat(item.path);
    });

    let center = this.state.center;
    const coordinates = _.get(operationZone, 'center.coordinates', []);

    if (coordinates.length) {
      center = {
        lat: coordinates[0][0][1],
        lng: coordinates[0][0][0],
      };
    }

    if (!allPoints.length) {
      allPoints = operationZone.geo.coordinates[0]
        ? operationZone.geo.coordinates[0].map((obj) => {
            return {
              lat: obj[1],
              lng: obj[0],
            };
          })
        : [];
    }

    if (allPoints.length) {
      this.mapZoneLoaded = true;

      if (isChina) {
        let latLng = new window.qq.maps.LatLngBounds();
        _.forEach(allPoints, (pos) => {
          latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
        });
        // COPY FROM Zone.js
        // for some reason i dont know but it needs to setTimeout for function this.map.fitBounds(latLng) working
        setTimeout(() => {
          this.map.fitBounds(latLng);
          if (allPoints.length == 1) {
            this.map.setZoom(10);
          }
        });
      } else {
        let latLng = new google.maps.LatLngBounds();
        _.forEach(allPoints, (pos) => {
          latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
        });

        if (this._map) {
          this._map.fitBounds(latLng);
          if (allPoints.length == 1) {
            this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
              10
            );
          }
        } else {
          setTimeout(() => {
            this._map && this._map.fitBounds(latLng);
            if (allPoints.length == 1) {
              this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setZoom(
                15
              );
            }
          }, 100);
        }
      }
    }

    const parentZone = {
      _id: operationZone._id,
      name: operationZone.zoneName,
      isParentZone: true,
      isActive: true,
      path: operationZone.geo.coordinates[0].map((pos) => {
        return {
          lat: pos[1],
          lng: pos[0],
        };
      }),
    };

    return {
      center,
      operationZone,
      fares,
      zoneAction: ZoneActionStatus.View,
      zoneDraw: {
        parentZone,
        Zones: parsedFares,
        activeZone: null,
      },
    };
  };

  getFares = () => {
    const { actions, selectedFleet, params } = this.props;

    actions
      .getFares({ fleetId: selectedFleet.fleetId, zoneId: params.zoneId })
      .then((data) => {
        if (data.ok && data.res) {
          this.setState({
            zoneDraw: {
              ...this.state.zoneDraw,
              Zones: data.res,
            },
          });
        } else {
          this.context.notification(
            'error',
            I18n.t('General.Can_not_get_data')
          );
        }
      });
  };

  handleSearchChange = (e) => this.setState({ textSearch: e.target.value });

  handleMapLoad = (key, ref) => {
    if (ref) {
      this[key] = ref;
    }
    if (key == 'map' && this.map && this.props.commonData?.location?.isChina) {
      window.qq.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.state.center = this.map.center;
      });
    }
  };

  onDragEnd = (e) => {
    if (e._id && this.state.zoneAction === ZoneActionStatus.View) {
      this.handleZoneRowClick(e);
    }
  };

  handleZoneRowClick = (zone) => {
    const { commonData } = this.props;
    let zoneDraw = this.state.zoneDraw;
    const isChina = _.get(commonData, 'location.isChina', false);
    let center = this.state.center;

    if (zone._id === zoneDraw._id) {
      return false;
    }

    if (isChina) {
      zoneDraw.activeZone = zone;
      let latLng = new window.qq.maps.LatLngBounds();
      zone.path.map((pos) => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      center = latLng.getCenter();
    } else {
      zoneDraw.activeZone = zone;
      let latLng = new google.maps.LatLngBounds();
      zone.path.map((pos) => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });

      if (this._map) {
        this._map.fitBounds(latLng);
      }

      center = latLng.getCenter();
    }

    this.setState({
      zoneDraw,
      fareModel: zone,
      zoneAction: ZoneActionStatus.View,
      center: center,
      polyline: false,
    });
  };

  ValidatorCallback = (id, value) => {
    const { valid } = this.state;

    if (valid[id] !== value) {
      this.setState({
        valid: {
          ...valid,
          [id]: value,
        },
      });
    }
  };

  handleAddButtonClick = () => {
    const { zoneDraw } = this.state;

    this.setState({
      zoneAction: ZoneActionStatus.Add,
      fareModel: defaultFareModel(),
      polyline: true,
      zoneDraw: {
        ...zoneDraw,
        activeZone: { path: [], name: '' },
      },
    });
  };

  handleFormFieldChange = (key, value) => {
    const { polyline } = this.state;
    const isChina = this.isChina();
    let zoneDraw = this.state.zoneDraw;

    if (!isChina) {
      if (polyline) {
        zoneDraw.activeZone.path = this.Polyline.getPath()
          .getArray()
          .map((p) => {
            return p.toJSON();
          });
      } else {
        zoneDraw.activeZone.path = this.Polygon.getPaths()
          .getAt(0)
          .getArray()
          .map((p) => {
            return p.toJSON();
          });
      }
    }

    this.setState({
      zoneDraw,
      fareModel: {
        ...this.state.fareModel,
        [key]: value,
      },
    });
  };

  handleSubmitFare = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    this.setState({
      isSubmitted: true,
    });
    const { actions, params, selectedFleet } = this.props;
    const { valid, fareModel } = this.state;

    if (!CCLiteCommonFunc.isFormValid(valid)) {
      return false;
    }

    let paths = this.getPaths();

    if (!paths) {
      return this.context.notification(
        'error',
        I18n.t('zoneSetting.Draw_zone'),
        ''
      );
    }

    let submitAction = '';
    let requestParams = {
      zoneId: params.zoneId,
      fleetId: selectedFleet.fleetId,
      name: fareModel.name,
      type: fareModel.type,
      parameter: fareModel.parameter || 0,
      geo: {
        coordinates: paths,
        type: 'Polygon',
      },
      pickupZone: fareModel.pickupZone || false,
      dropOffZone: fareModel.dropOffZone || false,
      parameterDrop: fareModel.parameterDrop || 0,
      typeDrop: fareModel.typeDrop,
    };

    if (!fareModel._id) {
      if (paths[0].length >= 3) {
        submitAction = 'create';
      }
    } else {
      requestParams.fareId = fareModel._id;
      submitAction = 'update';
    }

    if (submitAction) {
      actions[`${submitAction}DynamicFare`](requestParams).then((data) => {
        if (data.ok && data.res) {
          let { fares, operationZone } = this.state;

          if (submitAction === 'create') {
            fares.unshift(data.res);
          } else {
            let index = _.findIndex(fares, (item) => item._id === data.res._id);

            if (index !== -1) {
              fares[index] = data.res;
            }
          }

          const mapZoneData = this.initMapZone(operationZone, fares);
          this.Polyline = null;
          this.setState({
            ...mapZoneData,
            isSubmitted: false,
            polyline: false,
          });

          this.context.notification(
            'success',
            I18n.t(`dynamicFare.${submitAction}_fare_success`)
          );
        } else {
          this.setState({
            isSubmitted: false,
            polyline: false,
          });

          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t(`dynamicFare.${submitAction}_fare_fail`)
            );
          }
        }
      });
    }
  };

  handleCloseForm = () => {
    this.setState({
      isSubmitted: false,
    });
  };

  handlePolylineClick = (e) => {
    console.log('---- polyline click ---', e.vertex);
    if (e.vertex === 0) {
      const { commonData } = this.props;
      let { zoneDraw } = this.state;
      const isChina = _.get(commonData, 'location.isChina', false);

      if (isChina) {
        let lastPath =
          zoneDraw.activeZone.path[zoneDraw.activeZone.path.length - 1];

        if (!(e.latLng.lat === lastPath.lat && e.latLng.lng == lastPath.lng)) {
          zoneDraw.activeZone.path.push({
            lat: e.latLng.lat,
            lng: e.latLng.lng,
          });

          this.Polygon = zoneDraw.activeZone.path;
        }
      } else {
        zoneDraw.activeZone.path.push(e.latLng.toJSON());
      }

      this.setState({ polyline: false, zoneDraw });
    }
  };

  handleMapClick = (e) => {
    const { zoneAction, polyline } = this.state;
    let zoneDraw = this.state.zoneDraw;

    if (
      zoneAction === ZoneActionStatus.Add ||
      zoneAction === ZoneActionStatus.Edit
    ) {
      let isChina = this.isChina();

      if (isChina) {
        let latLng = e.latLng;

        if (latLng) {
          let polylinePath = this.Polyline
            ? this.Polyline.getPath().elems.map((obj) => {
                return {
                  lat: obj.lat,
                  lng: obj.lng,
                };
              })
            : null;

          if (polyline) {
            if (polylinePath) {
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            } else {
              polylinePath = [];
              polylinePath.push({ lat: latLng.lat, lng: latLng.lng });
            }

            zoneDraw.activeZone.path = polylinePath;
            this.setState({ zoneDraw });
          }
        }
      } else {
        console.log('----------- map click ------------', polyline);
        if (!polyline) {
          zoneDraw.activeZone.path = this.Polygon.getPaths()
            .getAt(0)
            .getArray()
            .map((p) => p.toJSON());
        } else {
          zoneDraw.activeZone.path = this.Polyline.getPath()
            .getArray()
            .map((p) => p.toJSON());
          zoneDraw.activeZone.path.push(e.latLng.toJSON());
        }

        this.setState({ zoneDraw });
      }
    }
  };

  isChina = () => _.get(this.props.commonData, 'location.isChina', false);

  handleClearDrawClick = () => {
    this.Polyline = null;
    let zoneDraw = this.state.zoneDraw;
    zoneDraw.activeZone.path = [];
    this.setState({ zoneDraw, polyline: true });
  };

  handleEditFare = (zone) => {
    let center = null;
    let zoneDraw = this.state.zoneDraw;
    const isChina = this.isChina();
    let latLng = isChina
      ? new window.qq.maps.LatLngBounds()
      : new google.maps.LatLngBounds();
    zoneDraw.activeZone = Object.assign({}, zone);

    if (isChina) {
      _.forEach(zone.path, (pos) => {
        latLng.extend(new window.qq.maps.LatLng(pos.lat, pos.lng));
      });
    } else {
      _.forEach(zone.path, (pos) => {
        latLng.extend(new google.maps.LatLng(pos.lat, pos.lng));
      });
    }

    if (this._map) {
      this._map.fitBounds(latLng);
    }

    center = latLng.getCenter();
    this.setState({
      zoneDraw,
      center,
      zoneAction: ZoneActionStatus.Edit,
      fareModel: zone,
    });
  };

  handleMenuItemClick = (eventKey, zone) => {
    switch (eventKey) {
      case ZoneActionStatus.Edit:
        return this.handleEditFare(zone);

      case ZoneActionStatus.Delete:
        if (!zone.isActive) {
          this.setState({
            confirmDelete: {
              fareId: zone._id,
              title: I18n.t('dynamicFare.delete_fare'),
              body: I18n.t('dynamicFare.delete_confirm_message'),
              buttonTitle: I18n.t('permission.Yes'),
              closeButtonText: I18n.t('permission.No'),
            },
          });
        }
        break;

      case ZoneActionStatus.Status:
        this.handleActivateFare(zone);

        break;
      default:
        break;
    }
  };

  handleActivateFare = (fare) => {
    const { actions, selectedFleet, params } = this.props;
    const actionName = fare.isActive ? 'deactive' : 'active';
    actions[`${actionName}DynamicFare`]({
      fleetId: selectedFleet.fleetId,
      zoneId: params.zoneId,
      fareId: fare._id,
    }).then((data) => {
      if (data.ok && data.res) {
        let { fares, operationZone } = this.state;
        const index = _.findIndex(fares, (item) => item._id === data.res._id);

        if (index !== -1) {
          fares[index] = data.res;
        }

        const mapZoneData = this.initMapZone(operationZone, fares);

        this.setState({ ...mapZoneData });

        this.context.notification(
          'success',
          I18n.t(`dynamicFare.${actionName}_fare_success`)
        );
      } else {
        this.context.notification(
          'error',
          I18n.t(`dynamicFare.${actionName}_fare_fail`)
        );
      }
    });
  };

  handleDeleteFare = () => {
    const { confirmDelete } = this.state;
    const { actions, selectedFleet, params } = this.props;

    if (confirmDelete.fareId) {
      this.setState(
        {
          confirmDelete: {
            ...confirmDelete,
            disabledConfirm: true,
          },
        },
        () => {
          actions
            .deleteDynamicFare({
              fleetId: selectedFleet.fleetId,
              zoneId: params.zoneId,
              fareId: confirmDelete.fareId,
            })
            .then((data) => {
              if (data.ok && data.res) {
                let { fares, operationZone } = this.state;

                _.remove(fares, (item) => item._id === confirmDelete.fareId);

                const mapZoneData = this.initMapZone(operationZone, fares);

                this.setState({
                  ...mapZoneData,
                  confirmDelete: null,
                });

                this.context.notification(
                  'success',
                  I18n.t(`dynamicFare.delete_fare_success`)
                );
              } else {
                this.setState({ confirmDelete: null });

                this.context.notification(
                  'error',
                  I18n.t(`dynamicFare.delete_fare_fail`)
                );
              }
            });
        }
      );
    }
  };

  handleConfirmCloseClick = () => this.setState({ confirmDelete: null });

  handleAddressChange = (address) => this.setState({ address });

  handleRemoveAddress = () => {
    if (window.searchPlaceMarked) {
      window.searchPlaceMarked.setMap(null);
    }

    this.setState({ address: '' });
  };

  handleSelectAddress = (address, placeId, pointOfInterest, sessionToken) => {
    const isChina = this.isChina();
    this.setState({ address });

    if (isChina) {
      const callback = (err, location, results) => {
        if (location) {
          this.map.setCenter(new qq.maps.LatLng(location.lat, location.lng));
          this.map.setZoom(18);
        } else {
          this.context.notification('warning', 'Location not found!');
        }
      }
      geoPlaceDetailMapProvider({
        auth: this.props.auth,
        placeid: placeId,
        sessionToken,
        callback,
        isTencent: true
      });
    } else {
      if (address && address !== '') {
        this.setState({ address });

        const callback = (err, location, results) => {
          this.setState({ center: location, defaultZoom: 18 });

          if (this._map) {
            this._map.setZoom(
              18
            );
          }
        };
        geoPlaceDetailMapProvider({
          auth: this.props.auth,
          placeid: placeId,
          sessionToken,
          callback,
        });
      }
    }
  };

  handleCancelButtonClick = () => {
    this.Polyline = null;
    this.setState({
      zoneAction: ZoneActionStatus.View,
      isSubmitted: false,
      polyline: false,
      zoneDraw: {
        ...this.state.zoneDraw,
        activeZone: null,
      },
    });
  };

  renderFares = () => {
    const { textSearch, zoneDraw, zoneAction, fareModel } = this.state;
    const { permissions } = this.props;
    const searchLowercase = textSearch.trim().toLowerCase();
    const filteredZones = _.filter(zoneDraw.Zones, (z) =>
      z.name.toLowerCase().includes(searchLowercase)
    );

    if (zoneAction === ZoneActionStatus.View) {
      return (
        <FareList
          fares={filteredZones}
          hasPermission={!permissions || permissions.actions}
          handleActivateFare={this.handleActivateFare}
          handleZoneRowClick={this.handleZoneRowClick}
          handleMenuItemClick={this.handleMenuItemClick}
          fareModel={fareModel}
        />
      );
    }

    return null;
  };

  renderMap = () => {
    const { zoneDraw, zoneAction, defaultZoom, polyline, center, address } =
      this.state;
    const isChina = this.isChina();

    if (isChina) {
      return (
        <React.Fragment>
          <TencentMap
            containerElement={<div className="mapqup fill" />}
            mapElement={<div style={{ height: `100%` }} />}
            center={center}
            onMapLoad={this.handleMapLoad}
            onPolygonComplete={() => {}}
            handlePolylineClick={this.handlePolylineClick}
            zoneDraw={JSON.parse(JSON.stringify(zoneDraw))}
            status={zoneAction}
            onDragEnd={this.onDragEnd}
            handleMapClick={this.handleMapClick}
            editable={
              zoneAction == ZoneActionStatus.Add ||
              zoneAction == ZoneActionStatus.Edit
            }
            polyline={polyline}
            defaultZoom={defaultZoom}
          />
          <div className="zone-location">
            <AutocompleteWith3rd
              className="form-custom form-control"
              value={address}
              onChange={this.handleAddressChange}
              onSelect={this.handleSelectAddress}
              handleAddressRemoved={this.handleRemoveAddress}
            />
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <GettingStartedGoogleMap
          containerElement="mapqup fill"
          mapElement={<div style={{ height: `100%` }} />}
          center={center}
          onMapLoad={this.handleMapLoad}
          onPolygonComplete={() => {}}
          handlePolylineClick={this.handlePolylineClick}
          zoneDraw={JSON.parse(JSON.stringify(zoneDraw))}
          status={zoneAction}
          onDragEnd={this.onDragEnd}
          handleMapClick={this.handleMapClick}
          editable={
            zoneAction == ZoneActionStatus.Add ||
            zoneAction == ZoneActionStatus.Edit
          }
          polyline={polyline}
          defaultZoom={defaultZoom}
        />
        <div className="zone-location">
          <PlacesAutocomplete
            value={address}
            onSelect={this.handleSelectAddress}
            onChange={this.handleAddressChange}
            autocompleteItem={AutocompleteItem}
            className="location-select form-custom"
            styles={myStyles}
            placeholder={I18n.t('map.Enter_a_location')}
            onEnterKeyDown={this.handleSelectAddress}
            handleAddressRemoved={this.handleRemoveAddress}
          />
        </div>
      </React.Fragment>
    );
  };

  renderFareForm = () => {
    const { fareModel, isSubmitted, valid, zoneAction } = this.state;
    const currency = this.props.selectedFleet.currencies[0];

    return (
      <div className="fare-form">
        <form onSubmit={this.handleSubmitFare}>
          <InputRequireInline
            className={!isSubmitted || valid.name ? null : 'error'}
          >
            <FormControl
              type="text"
              className="form-custom"
              value={fareModel.name}
              onChange={(e) =>
                this.handleFormFieldChange('name', e.target.value)
              }
              maxLength="50"
              placeholder={
                fareModel.name.length ? '' : I18n.t('dynamicFare.fareName')
              }
            />
            <Validator id="name" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isStringEmpty(fareModel.name)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
              <ValidCase
                hide={!isSubmitted}
                valid={!Validation.isMaxlength(fareModel.name, 50)}
                message={I18n.t('messages.commonMessages.Max_length').format(
                  50
                )}
              />
            </Validator>
          </InputRequireInline>
          <FormGroup
            validationState={!isSubmitted || valid.pickupZone ? null : 'error'}
          >
              <CcCheckbox
                checked={fareModel.pickupZone}
                onChange={(e) =>
                  this.handleFormFieldChange('pickupZone', e.target.checked)
                }
                text={I18n.t('dynamicSurcharge.pickupPoint')}
              />
            <Validator id="pickupZone" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={fareModel.pickupZone || fareModel.dropOffZone}
                message={I18n.t('messages.commonMessages.choose_at_least_one')}
              />
            </Validator>
          </FormGroup>
          {
            fareModel.pickupZone && (
              <>
                <FormGroup>
                  <FormControl
                    as="select"
                    className="form-custom"
                    value={fareModel.type ? fareModel.type : 'factor'}
                    onChange={(e) =>
                      this.handleFormFieldChange('type', e.target.value)
                    }
                  >
                    <option value={'factor'}>{I18n.t('dynamicFare.factor')}</option>
                    <option value={'fixAmount'}>
                      {I18n.t('dynamicFare.fix_amount')}
                    </option>
                  </FormControl>
                </FormGroup>
                {!fareModel.type || fareModel.type == 'factor' ? (
                  <>
                    <InputRequireInline
                      validationState={
                        !isSubmitted || valid.parameter ? null : 'error'
                      }
                      >
                      <FormControl
                        type="number"
                        placeholder={
                          (fareModel.parameter + '').length
                            ? ''
                            : I18n.t('dynamicFare.parameter')
                        }
                        step="0.1"
                        min={0}
                        className="form-custom"
                        value={fareModel.parameter}
                        onChange={(e) =>
                          this.handleFormFieldChange('parameter', e.target.value)
                        }
                      />
                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={fareModel.parameter}
                        disabled={zoneAction !== ZoneActionStatus.Edit}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                      <Validator id="parameter" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!isSubmitted}
                          valid={!Validation.isStringEmpty(fareModel.parameter)}
                          message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                          hide={!isSubmitted}
                          valid={Validation.isNumber(fareModel.parameter)}
                          message={I18n.t('messages.commonMessages.must_be_number')}
                        />
                        <ValidCase
                          valid={Validation.isGreaterThan(fareModel.parameter, 0)}
                          message={I18n.t(
                            'messages.commonMessages.greater_than'
                          ).format(0)}
                          hide={!isSubmitted}
                        />
                        <ValidCase
                          valid={Validation.isLessOrEqual(fareModel.parameter, 10)}
                          message={I18n.t(
                            'messages.commonMessages.less_or_equal'
                          ).format(10)}
                          hide={!isSubmitted}
                        />
                      </Validator>
                    </InputRequireInline>
                  </>
                ) : (
                  <>
                    <FormGroup
                      validationState={
                        !isSubmitted || valid.parameter ? null : 'error'
                      }
                    >
                      <FormGroup className="qup-input-group">
                        <InputGroup className={`single-addon-left`}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {currency.symbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="number"
                            step="0.1"
                            min={0}
                            className="form-custom"
                            value={fareModel.parameter}
                            onChange={(e) =>
                              this.handleFormFieldChange('parameter', e.target.value)
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={fareModel.parameter}
                        disabled={zoneAction !== ZoneActionStatus.Edit}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                      <Validator id="parameter" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!isSubmitted}
                          valid={!Validation.isStringEmpty(fareModel.parameter)}
                          message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                          hide={!isSubmitted}
                          valid={Validation.isNumber(fareModel.parameter)}
                          message={I18n.t('messages.commonMessages.must_be_number')}
                        />
                        <ValidCase
                          valid={Validation.isGreaterThan(fareModel.parameter, 0)}
                          message={I18n.t(
                            'messages.commonMessages.greater_than'
                          ).format(0)}
                          hide={!isSubmitted}
                        />
                      </Validator>
                    </FormGroup>
                  </>
                )}
              </>
            )
          }
          <FormGroup
            validationState={!isSubmitted || valid.dropOffZone ? null : 'error'}
          >
              <CcCheckbox
                checked={fareModel.dropOffZone}
                onChange={(e) =>
                  this.handleFormFieldChange('dropOffZone', e.target.checked)
                }
                text={I18n.t('dynamicSurcharge.dropOffPoint')}
              />
            <Validator id="dropOffZone" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!isSubmitted}
                valid={fareModel.pickupZone || fareModel.dropOffZone}
                message={I18n.t('messages.commonMessages.choose_at_least_one')}
              />
            </Validator>
          </FormGroup>
          {
            fareModel.dropOffZone && (
              <>
                <FormGroup>
                  <FormControl
                    as="select"
                    className="form-custom"
                    value={fareModel.typeDrop ? fareModel.typeDrop : 'factor'}
                    onChange={(e) =>
                      this.handleFormFieldChange('typeDrop', e.target.value)
                    }
                  >
                    <option value={'factor'}>{I18n.t('dynamicFare.factor')}</option>
                    <option value={'fixAmount'}>
                      {I18n.t('dynamicFare.fix_amount')}
                    </option>
                  </FormControl>
                </FormGroup>
                {!fareModel.typeDrop || fareModel.typeDrop == 'factor' ? (
                  <>
                    <InputRequireInline
                      validationState={
                        !isSubmitted || valid.parameterDrop ? null : 'error'
                      }
                      >
                      <FormControl
                        type="number"
                        placeholder={
                          fareModel?.parameterDrop
                            ? ''
                            : I18n.t('dynamicFare.parameter')
                        }
                        step="0.1"
                        min={0}
                        className="form-custom"
                        value={fareModel.parameterDrop}
                        onChange={(e) =>
                          this.handleFormFieldChange('parameterDrop', e.target.value)
                        }
                      />
                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={fareModel.parameterDrop}
                        disabled={zoneAction !== ZoneActionStatus.Edit}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                      <Validator id="parameterDrop" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!isSubmitted}
                          valid={!Validation.isStringEmpty(fareModel.parameterDrop)}
                          message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                          hide={!isSubmitted}
                          valid={Validation.isNumber(fareModel.parameterDrop)}
                          message={I18n.t('messages.commonMessages.must_be_number')}
                        />
                        <ValidCase
                          valid={Validation.isGreaterThan(fareModel.parameterDrop, 0)}
                          message={I18n.t(
                            'messages.commonMessages.greater_than'
                          ).format(0)}
                          hide={!isSubmitted}
                        />
                        <ValidCase
                          valid={Validation.isLessOrEqual(fareModel.parameterDrop, 10)}
                          message={I18n.t(
                            'messages.commonMessages.less_or_equal'
                          ).format(10)}
                          hide={!isSubmitted}
                        />
                      </Validator>
                    </InputRequireInline>
                  </>
                ) : (
                  <>
                    <FormGroup
                      validationState={
                        !isSubmitted || valid.parameterDrop ? null : 'error'
                      }
                    >
                      <FormGroup className="qup-input-group">
                        <InputGroup className={`single-addon-left`}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {currency.symbol}
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="number"
                            step="0.1"
                            min={0}
                            className="form-custom"
                            value={fareModel.parameterDrop}
                            onChange={(e) =>
                              this.handleFormFieldChange('parameterDrop', e.target.value)
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={fareModel.parameterDrop}
                        disabled={zoneAction !== ZoneActionStatus.Edit}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                      <Validator id="parameterDrop" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!isSubmitted}
                          valid={!Validation.isStringEmpty(fareModel.parameterDrop)}
                          message={I18n.t('messages.commonMessages.Required_field')}
                        />
                        <ValidCase
                          hide={!isSubmitted}
                          valid={Validation.isNumber(fareModel.parameterDrop)}
                          message={I18n.t('messages.commonMessages.must_be_number')}
                        />
                        <ValidCase
                          valid={Validation.isGreaterThan(fareModel.parameterDrop, 0)}
                          message={I18n.t(
                            'messages.commonMessages.greater_than'
                          ).format(0)}
                          hide={!isSubmitted}
                        />
                      </Validator>
                    </FormGroup>
                  </>
                )}
              </>
            )
          }
          <div className="text-base mb-md" style={{ fontStyle: 'italic' }}>
            <Translate value="dynamicFare.note_amount" />
          </div>
          <div>
            <Button className="btn-save mr-md" type="submit">
              <Translate value="zoneSetting.Save" />
            </Button>
            <Button className="btn-cancel" onClick={this.handleClearDrawClick}>
              <Translate value="zoneSetting.Clear_draw" />
            </Button>
          </div>
        </form>
      </div>
    );
  };

  render() {
    const { textSearch, zoneAction, confirmDelete } = this.state;
    const { permissions } = this.props;

    return (
      <div className="content fare-container">
        <Row style={{ height: `100%` }}>
          <Col lg={3} md={6}>
            <div className="header-button-group">
              {zoneAction === ZoneActionStatus.View ? (
                <React.Fragment>
                  {!permissions || permissions.actions ? (
                    <Button
                      className="btn-header text-add-header"
                      onClick={this.handleAddButtonClick}
                    >
                      <Translate value="driver.Add" />
                    </Button>
                  ) : null}
                  <FormGroup className="search-format">
                    <FormControl
                      type="text"
                      value={textSearch}
                      onChange={this.handleSearchChange}
                      className="form-custom search-form"
                      placeholder={I18n.t('General.search')}
                    />
                    <BsSearch className="search-icon" />
                  </FormGroup>
                </React.Fragment>
              ) : (
                <Button
                  className={'btn-header text-delete-header'}
                  onClick={this.handleCancelButtonClick}
                >
                  <i className="fa fa-times" />
                  <Translate value="zoneSetting.Cancel" />
                </Button>
              )}
            </div>
            {zoneAction !== ZoneActionStatus.View && this.renderFareForm()}
            {this.renderFares()}
          </Col>
          <Col lg={9} md={6} style={{ height: '100%' }}>
            {this.renderMap()}
          </Col>
        </Row>
        <Confirm
          confirm={confirmDelete}
          handleConfirmButtonClick={this.handleDeleteFare}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

Fare.contextTypes = {
  notification: PropTypes.func,
};

export default Fare;
