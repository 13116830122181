import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import IconvsTextBtn from './components/IconvsTextBtn';
import chargeIcon from '../../../../assets/images/icons/charge.svg';
import PropTypes from 'prop-types';
import { checkShowChargeInvoicingBtn } from '../../../../utils/commonFunctions';

const ChargeBtn = ({
  bookInfo,
  selectedFleet,
  hasPermission,
  is3rdBooking,
  isApiDemandBooking,
  isSupplierPermission,
  chargeInvoicing,
}) => {
  const show = useMemo(
    () =>
      checkShowChargeInvoicingBtn({
        bookInfo,
        is3rdBooking,
        isApiDemandBooking,
        isSupplierPermission,
        selectedFleet,
        hasPermission,
        chargeInvoicing,
      }),
    []
  );

  if (!show) return null;
  return (
    <Button variant="link" onClick={() => chargeInvoicing()}>
      <IconvsTextBtn text={I18n.t('bookingdetail.Charge')} icon={chargeIcon} />
    </Button>
  );
};

ChargeBtn.defaultProps = {
  bookInfo: {},
  selectedFleet: {},
  hasPermission: false,
  is3rdBooking: false,
  isSupplierPermission: false,
  chargeInvoicing: () => {},
};

ChargeBtn.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
  chargeInvoicing: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  is3rdBooking: PropTypes.bool,
  isApiDemandBooking: PropTypes.bool,
  isSupplierPermission: PropTypes.bool,
};

export default ChargeBtn;
