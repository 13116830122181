import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { QQUltis } from './ultis';
import { Image } from 'react-bootstrap';
import close_icon from './../../assets/images/icons/close_icon.svg';
import AutoCompleteItem from '../placesAutocomplete/AutoCompleteItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as tencentMapActions from '../../actions/tencentMapActions';
import { getAutocompleted } from '../../actions/mapProviderAction';
import { getChannelMap } from '../../utils/mapUtils';
import uuidv4 from 'uuid/v4';
import { I18n } from 'react-redux-i18n';
import { PROVIDER_MAP_TYPE } from '../../constants/commondata';
import { convertParamAutocomplete } from '../bookingDetail/bookFunction/bookingInfo';

class Autocomplete extends Component {
  constructor(prop) {
    super();
			this.state = { 
					autocompleteItems: [], 
					sessionToken: uuidv4({ msecs: new Date().getTime() }), 
			}
    this.autocompleteSearchDebounce = _.debounce(this.autocompleteSearch, 500);
  }
  componentDidMount() {
  }

  autocompleteSearch = (q, options, callback) => {
    getAutocompleted(
      convertParamAutocomplete({
        q,
        auth: this.props.auth,
        sessiontoken: this.state.sessionToken,
        forceProvider: PROVIDER_MAP_TYPE.tencent,
        bookId: this.props?.data?.bookId
      })
    ).then((response) => {
      let res = response.res || {};
      this.autocompleteCallback(res.predictions, res.status);
    });
  };

  autocompleteCallback(predictions, status) {
    if (status !== 'OK') {
      if (status === this.OVER_QUERY_LIMIT) {
        this.setState({
          confirm: {
            title: 'Warning',
            body: I18n.t('messages.commonMessages.Over_query_limit'),
            closeButtonText: I18n.t('General.Retry'),
          },
        });
      } else {
        if (this.props.onError && typeof this.props.onError == 'function') {
          this.props.onError(status);
        }
        if (this.props.clearItemsOnError) {
          this.clearAutocomplete();
        }
        return;
      }
    }
    let autocomplete = predictions.map((p, idx) => {
      return {
        suggestion: p.description,
				placeId: p.place_id,
        // index: autocompleted3rd && (autocompleted3rd.length + idx) || idx,
        // formattedSuggestion: this.formattedSuggestion(p.structured_formatting),
        // pointOfInterest,
        // type: 'gg'
      };
    });
    this.setState({
      autocompleteItems: autocomplete?.length > 0 ? autocomplete : [],
    });
  }


  selectAddress = (address, placeId, pointOfInterest = '') => {
    this.clearAutocomplete(true);
    this.handleSelect(address, placeId, pointOfInterest, this.state.sessionToken);
  };

  handleSelect = (address, placeId) => {
    this.state.valueChanged = false;
    if (this.props.onSelect) {
			if(this.props.handleAddressRemoved) {
				this.props.handleAddressRemoved();
			}
      this.props.onSelect(address, placeId, null, this.state.sessionToken);
    }
    this.props.onChange(address);
  };

  renderAutocomplete = () => {
    const { autocompleteItems } = this.state;
    if (!Array.isArray(autocompleteItems) || autocompleteItems?.length === 0) {
      return null;
    }
    return (
      <div>
        <div
          id="PlacesAutocomplete__autocomplete-container"
          className="autocomplete__placeContainer"
        >
          {autocompleteItems.map((p, idx) => {
            return (
              <AutoCompleteItem
                key={p.placeId || idx}
                suggestion={p.suggestion}
                pickUp={this.props.pickUp}
                suggestItem={p.placeId}
                onMouseDown={this.selectAddress}
              />
            );
          })}
        </div>
      </div>
    );
  };

  onChangeHandle = (e) => {
    if (this.props.onChange && typeof this.props.onChange == 'function') {
      this.props.onChange(e.target.value);
    }
  };
  onConfirmHandle = (e) => {};


	clearAutocomplete = (hasNewSession = false) => {
		if(hasNewSession) {
				let newSesion = uuidv4({ msecs: new Date().getTime() });
				this.setState({ autocompleteItems: [], sessionToken: newSesion });
		} else {
				this.setState({ autocompleteItems: [] });
		}
}

  handleInputChange = (event) => {
    this.state.valueChanged = true;
    this.props.onChange(event.target.value);
    if (!event.target.value) {
      this.clearAutocomplete();
      return;
    }
    this.autocompleteSearchDebounce(
      event.target.value,
      this.props.options,
      this.autocompleteCallback
    );
  };

	inputonBlurHandle = (e) => {
		this.clearAutocomplete()
}

  renderInput = () => {
    const {
      classNames,
      placeholder,
      styles,
      value,
      autoFocus,
      inputName,
      inputId,
      disabled,
    } = this.props;
    return (
      <input
        type="search"
        placeholder={I18n.t('newbooking.Enter_a_location')}
        className={'form-control form-custom-addon'}
        value={value ? value : ''}
        onChange={this.handleInputChange}
				// onBlur={this.inputonBlurHandle}
        autoFocus={autoFocus}
        name={inputName || ''}
        id={inputId || ''}
        disabled={disabled || false}
      />
    );
  };

  render() {
    const props = { ...this.props, onChange: this.onChangeHandle };
    delete props.classNames;
    delete props.autoFocus;
    let isCloseButton = props.value && !props.disabled;
    if (props.closeButton) {
      isCloseButton = true;
    }
    return (
      <div className={'autoplaces-input ' + this.props.classNames}>
        {this.renderInput()}
        {isCloseButton && (
          <Image
            className="remove-icon"
            src={close_icon}
            onClick={this.props.handleAddressRemoved}
          />
        )}
        {this.renderAutocomplete()}
      </div>
    );
  }
}

Autocomplete.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
	return {
			auth: state.auth,
			language: state.i18n
	}
}

function mapDispatchToProps(dispatch) {
  return {
    tencentMapActions: bindActionCreators(tencentMapActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);
