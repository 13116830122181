import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CiSearch } from "react-icons/ci";
import { FaCaretDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import './SearchAndSelect.scss';
import {I18n} from "react-redux-i18n";

class SearchAndSelect extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string
  };

  static defaultProps = {
    options: [],
    value: null,
    placeholder: I18n.t("General.search"),
    allowClear: true,
    className: '',
    isDisabled: false,
    valueKey: 'value',
    labelKey: 'label'
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      searchTerm: ''
    };
    this.dropdownRef = React.createRef();
    this.searchInputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  getNestedValue = (obj, path) => {
    return path.split('.').reduce((current, key) => {
      return current ? current[key] : undefined;
    }, obj);
  };

  getFilteredOptions = () => {
    const { options, labelKey } = this.props;
    const { searchTerm } = this.state;

    return options.filter(option => {
      const label = this.getNestedValue(option, labelKey)?.toString().toLowerCase() || '';
      return label.includes(searchTerm.toLowerCase());
    });
  };

  getOptionLabel = (option) => {
    const { labelKey } = this.props;
    return this.getNestedValue(option, labelKey);
  };

  getOptionValue = (option) => {
    const { valueKey } = this.props;
    return this.getNestedValue(option, valueKey);
  };

  getSelectedOption = () => {
    const { options, value, valueKey } = this.props;
    return options.find(option => this.getOptionValue(option) === value);
  };

  handleSelect = (option) => {
    const { onChange } = this.props;
    const value = this.getOptionValue(option);

    onChange({
      target: {
        value
      }
    });

    this.setState({ isOpen: false });
  };

  handleClear = (e) => {
    e.stopPropagation();
    const { onChange } = this.props;
    onChange({
      target: {
        value: ''
      }
    });
  };

  toggleDropdown = () => {
    const { isDisabled } = this.props;
    if (!isDisabled) {
      this.setState(prevState => ({
        isOpen: !prevState.isOpen,
        searchTerm: ''
      }));
    }
  };

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  render() {
    const {
      placeholder,
      allowClear,
      className,
      isDisabled,
      value
    } = this.props;
    const { isOpen } = this.state;
    const selectedOption = this.getSelectedOption();

    return (
      <div className={`search-select ${className}`} ref={this.dropdownRef}>
        <div
          onClick={this.toggleDropdown}
          className={`search-select__display ${isDisabled ? 'search-select__display--disabled' : ''}`}
        >
          <div className="search-select__selected-value">
            <span className={`search-select__placeholder ${selectedOption ? 'search-select__placeholder--selected' : ''}`}>
              {selectedOption ? this.getOptionLabel(selectedOption) : placeholder}
            </span>
          </div>
          <div className="search-select__actions">
            {allowClear && selectedOption && !isDisabled && (
              <IoMdClose
                className="search-select__clear-icon"
                onClick={this.handleClear}
              />
            )}
            <FaCaretDown
              className={`search-select__arrow-icon ${isOpen ? 'search-select__arrow-icon--open' : ''}`}
            />
          </div>
        </div>

        {isOpen && (
          <div className="search-select__dropdown">
            <div className="search-select__search">
              <div className="search-select__search-wrapper">
                <input
                  ref={this.searchInputRef}
                  type="text"
                  className="search-select__search-input"
                  placeholder={I18n.t("General.search")}
                  value={this.state.searchTerm}
                  onChange={this.handleSearchChange}
                />
                <CiSearch className="search-select__search-icon" />
              </div>
            </div>

            <div className="search-select__options">
              {this.getFilteredOptions().length > 0 ? (
                this.getFilteredOptions().map(option => {
                  const isSelected = this.getOptionValue(option) === value;
                  return (
                    <div
                      key={this.getOptionValue(option)}
                      className={`search-select__option ${isSelected ? 'search-select__option--selected' : ''}`}
                      onClick={() => this.handleSelect(option)}
                    >
                      <div className="search-select__option-content">
                        {isSelected && <FaCheck className="search-select__option-check" />}
                        <span className="search-select__option-label">
                          {this.getOptionLabel(option)}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="search-select__no-results">
                  {I18n.t("noResultFound")}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SearchAndSelect;
