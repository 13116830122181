/* eslint-disable react/button-has-type */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable space-before-function-paren */
/* eslint-disable class-methods-use-this */
// /* eslint-disable import/no-named-as-default-member */
// /* eslint-disable import/no-named-as-default */
/* eslint-disable no-param-reassign */
import * as _ from 'lodash'
import XLSX from 'xlsx'
import React, { Component } from 'react'
import { BeatLoader } from 'react-spinners'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import uuid from 'uuid'
import Promise from 'bluebird'
import { Modal, FormGroup, FormControl } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { FileOutlineIcon } from '@icons/material'
import * as commonActions from '../../actions/commonDataAction'
import * as corporateCompanyActions from '../../actions/corporateCompanyAction'
import * as importBookingsAction from '../../actions/importBookingsAction'
import { detailCorporate } from '../../actions/corporateAction'
import StickyTable from '../../components/table/stickyTable/StickyTable'
import UploadFile from '../../components/upload/upload'
import { TransportColumns, DeliveryColumns } from './columns'
import { userType } from '../../constants/commondata'
import { socketConfigs } from '../../constants/socketConfigs'
import { socketDispatchApi } from '../../utils/socketUtils'
import mapValueFromExcelJson from './mapValueFromExcel.js'
import './importBookings.scss'
import LoadingSpiner from '../../components/loadingSpiner/LoadingSpiner'
import { DeliveryRecipients } from './recipients'
import formatBooking from './formatBooking'
import transformTransportFromExcelData from './transformTransportFromExcelData'
import transformDeliveryFromExcelData from './transformDeliveryFromExcelData'
import validateAndTransformBooking from './validateAndTransformBooking'
import ValidationPopUp from './ValidationPopUp'
import checkPermission from './checkPermission'

// eslint-disable-next-line react/prefer-stateless-function
class ImportBookings extends Component {
  constructor(props) {
    super(props)
    const { enabledTransport } = checkPermission.call(this)
    this.state = {
      showValidationErrorModal: false,
      limitExceeded: false,
      footerData: {
        limit: 10,
        total: 0,
        page: 0,
      },
      showConfirmedImport: false,
      downloadTemplate: false,
      showImportedResult: false,
      bookingType: enabledTransport ? 'transport' : 'delivery',
      corporateId:
        this.props.auth.user.userType === userType.CorporateAdmin ||
        this.props.auth.user.userType === userType.CorporateUser
          ? this.props.auth.user.corporateId
          : '',
      corporate: {},
      showBookings: [],
      showExpandedRows: [],
    }
  }

  routerWillLeave() {
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if (this.state.showBookings.length > 0) {
      return I18n.t('importBookings.leavingConfirmMessage')
    }
  }

  componentWillUnmount() {
    this.props.importBookingsAction.unsetValues()
    window.onbeforeunload = null
  }

  componentWillMount() {
    const { selectedFleet } = this.props.auth
    const { corporateId } = this.state
    if (corporateId) {
      this.props
        .detailCorporate({
          _id: corporateId,
          fleetId: selectedFleet.fleetId,
        })
        .then((data) => {
          if (data.ok && data.res) {
            this.setState({ corporate: data.res })
          }
        })
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave.bind(this)
    )
    window.onbeforeunload = (event) => {
      if (this.state.downloadTemplate) {
        this.setState({
          downloadTemplate: false,
        })
        return
      }
      if (
        this.state.showBookings.length &&
        !this.props.importBookings.imported
      ) {
        const message =
          "Important: Please click on 'Save' button to leave this page."
        if (typeof event === 'undefined') {
          event = window.event
        }
        if (event) {
          event.returnValue = message
        }
        return message
      }
    }

    if (this.props.auth.selectedFleet.moduleSettings.corporate) {
      this.props.corporateCompanyActions.corporateCompany(
        this.props.auth.selectedFleet.fleetId
      )
    }
    if (this.props.auth.selectedFleet.moduleSettings.airlineBusiness) {
      this.props.corporateCompanyActions.corporateCompany(
        this.props.auth.selectedFleet.fleetId,
        true
      )
    }

    socketDispatchApi.on(
      socketConfigs.receive.validateBooking,
      async (data) => {
        if (data.batchId !== this.props.importBookings.batchId) {
          return
        }
        const { success, booking: b, error } = data
        _.set(b, ['_meta_', 'validated'], true)
        if (!success && error) {
          if (error.code) {
            _.set(b, ['_meta_', 'errorCode'], error.code)
            _.set(b, ['_meta_', 'errorData'], error.data)
          }
        }
        this.props.importBookingsAction.updateBookings(b)
        const skip = this.state.footerData.page * this.state.footerData.limit
        const showBookings = this.props.importBookings.bookings.slice(
          skip,
          skip + this.state.footerData.limit
        )
        const newState = {
          showBookings,
          showExpandedRows: _.range(showBookings.length).fill(true),
        }
        this.setState(newState)
      }
    )
    socketDispatchApi.on(socketConfigs.receive.importBooking, (data) => {
      if (data.batchId !== this.props.importBookings.batchId) {
        return
      }
      const b = data.booking
      _.set(b, ['_meta_', 'imported'], true)
      _.set(b, ['_meta_', 'importedSuccess'], data.success)
      _.set(b, ['_meta_', 'status'], data.booking.status)
      this.props.importBookingsAction.updateBookings(b)
      this.props.importBookingsAction.setNumbersStatus({ isImporting: false })
      const skip = this.state.footerData.page * this.state.footerData.limit
      const newState = {}
      newState.showBookings = this.props.importBookings.bookings.slice(
        skip,
        skip + this.state.footerData.limit
      )
      newState.showExpandedRows = _.range(newState.showBookings.length).fill(
        true
      )

      if (
        !this.state.showImportedResult &&
        this.props.importBookings.imported
      ) {
        newState.showImportedResult = true
      }
      if (!_.isEmpty(newState)) {
        this.setState(newState)
      }
    })
  }

  getTableColumns() {
    if (this.state.bookingType === 'transport') {
      return [].concat(TransportColumns)
    }
    return [].concat(DeliveryColumns)
  }

  handleNumItemsPerPageChange(limit) {
    const skip = limit * this.state.footerData.page
    const showBookings = _(this.props.importBookings.bookings)
      .drop(skip)
      .take(limit)
      .value()
    const showExpandedRows = _.range(showBookings.length).fill(true)

    this.setState({
      footerData: {
        ...this.state.footerData,
        limit,
      },
      showBookings,
      showExpandedRows,
    })
  }

  handlePaginationSelect(page) {
    page = parseInt(page)
    const skip = page * this.state.footerData.limit
    const showBookings = _(this.props.importBookings.bookings)
      .drop(skip)
      .take(this.state.footerData.limit)
      .value()
    const showExpandedRows = _.range(showBookings.length).fill(true)

    this.setState({
      footerData: {
        ...this.state.footerData,
        page,
      },
      showBookings,
      showExpandedRows,
    })
  }

  handleSortCustomerClick() {}

  async validateAndTransformBookings() {
    const newState = { isLoading: true }
    this.props.importBookingsAction.resetValues(newState)
    await Promise.map(
      this.props.importBookings.bookings,
      validateAndTransformBooking.bind(this)
    )
  }

  transformBookingToShowOnClient(booking, index) {
    if (this.state.bookingType === 'transport') {
      return transformTransportFromExcelData.call(this, booking, index)
    } else {
      return transformDeliveryFromExcelData.call(this, booking, index)
    }
  }

  transformBookingsToShowOnClient(bookings) {
    bookings = bookings || this.props.importBookings.bookings
    bookings = bookings.map(this.transformBookingToShowOnClient.bind(this))
    const bookingsWithExternalId = bookings.filter(b => !!_.get(b, 'externalInfo.bookingReference'));
    if (
      _.uniqBy(bookingsWithExternalId, 'externalInfo.bookingReference').length !==
      bookingsWithExternalId.length
    ) {
      return this.props.importBookingsAction.showHideValidationPopup({
        errorCode: 'duplicateBookingReference',
        errorData: {},
        showValidationErrorModal: true,
      })
    }
    const showBookings = _(bookings).take(this.state.footerData.limit).value()
    const showExpandedRows = _.range(showBookings.length).fill(true)

    this.setState({
      footerData: {
        ...this.state.footerData,
        page: 0,
        total: bookings.length,
      },
      showBookings,
      showExpandedRows,
    })
    this.props.importBookingsAction.setBookings(bookings)
  }

  async handleFileChange(e) {
    e.preventDefault()
    const { target } = e
    if (!target.files.length) return false

    const file = target.files[0]
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    if (!file) {
      // TODO: should show error message here
      return
    }

    reader.onloadend = () => {
      const newState = {
        batchId: uuid.v4(),
        numberOfValidated: 0,
        numberOfValid: 0,
        numberOfInvalid: 0,
        numberOfImported: 0,
        validated: false,
        imported: false,
        isImporting: false,
        file,
      }
      if (!this.state.limitExceeded) {
        this.props.importBookingsAction.setNumbersStatus(newState)
      }
    }

    reader.onload = (el) => {
      this.setState({ limitExceeded: false })
      const bstr = el.target.result
      const wb = XLSX.read(bstr, { type: 'binary' })
      if (this.state.bookingType === 'transport') {
        const wsName = wb.SheetNames[0]
        const ws = wb.Sheets[wsName]
        const dataFile = XLSX.utils.sheet_to_json(ws)
        // const regPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;
        const handleTransportDoc = (item) =>
          _.mapKeys(item, (_v, key) => {
            return mapValueFromExcelJson?.MapTransportValues[key] || key
          })
        const bookings = dataFile.map(handleTransportDoc)

        if (bookings.length > 10000) {
          this.setState({ limitExceeded: true })
        } else {
          this.transformBookingsToShowOnClient.call(this, bookings)
        }
      } else {
        // const wsName = wb.SheetNames[0];
        const ws = wb.Sheets['Template']
        const dataFile = XLSX.utils.sheet_to_json(ws)
        const firstRow = dataFile.shift()
        dataFile.shift()
        const handleDeliveryDoc = (item) =>
          _.mapKeys(
            item,
            (_v, key) =>
              mapValueFromExcelJson?.MapDeliveryValues.getMappings(firstRow)[
                key
              ] || key
          )
        let docs = dataFile.map(handleDeliveryDoc)
        docs = docs.filter((i) => !_.isEmpty(_.omit(i, ['no'])))
        let groups = _.groupBy(docs, 'multiStopBatch')
        const bookings = _.entries(groups).reduce(
          (list, [batchNumber, orders]) => {
            if (batchNumber === 'undefined' || orders.length === 1) {
              list = list.concat(orders)
            } else {
              list.push(orders)
            }
            return list
          },
          []
        )
        if (bookings.length > 10000) {
          this.setState({ limitExceeded: true })
        } else {
          this.transformBookingsToShowOnClient.call(this, bookings)
        }
      }
    }
    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  handleShowConfirmImport() {
    this.setState({
      showConfirmedImport: true,
    })
  }

  async handleSubmitBookings() {
    this.setState({ showConfirmedImport: false })
    this.props.importBookingsAction.setNumbersStatus({ isImporting: true })
    Promise.each(this.props.importBookings.bookings, (booking) => {
      if (booking.status === 'Valid') {
        return socketDispatchApi.emit(socketConfigs.send.importBooking, {
          batchId: this.props.importBookings.batchId,
          jobType: this.state.bookingType,
          booking,
        })
      }
    })
  }

  handleChangeCorporation(e) {
    const corporateId = e.target ? e.target.value : ''
    if (corporateId === this.state.corporateId) {
      return
    }
    const corporate = this.props.corporateCompany.find(
      (c) => c._id === corporateId
    )
    const bookings = this.props.importBookings.bookings.map((booking) => {
      booking.corporateInfo = {
        corporateId,
        name: corporate && corporate.companyInfo.name,
      }
      booking.travelerType = corporateId ? 1 : 0
      if (_.get(booking, ['_meta_', 'validated'])) {
        booking._meta_ = {}
        booking.status = 'Valid'
      }
      return booking
    })
    const skip = this.state.footerData.page * this.state.footerData.limit
    const showBookings = _(bookings)
      .drop(skip)
      .take(this.state.footerData.limit)
      .value()
    const showExpandedRows = _.range(showBookings.length).fill(true)

    this.setState({ corporateId, showBookings, showExpandedRows })
    this.props.importBookingsAction.resetValues({ bookings, corporateId })
  }

  handleChangeBookingType(e) {
    const bookingType = e.target ? e.target.value : ''
    if (bookingType === this.state.serviceType) {
      return
    }
    this.setState({ bookingType, showBookings: [], showExpandedRows: [] })
    this.props.importBookingsAction.resetValues({ bookings: [], file: null })
  }

  handleConfirmCloseClick() {
    this.setState({ showConfirmedImport: false })
  }

  handleCloseResultClick() {
    this.setState({ showImportedResult: false })
  }

  getTableHeight = () => {
    const filterNode = this.activeFilter
    const parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0
    const verticalPadding = 10
    const filterMarginBottom = 10
    const filterHeight =
      filterNode && filterNode.clientHeight ? filterNode.clientHeight : 0
    const outerHeight = verticalPadding + filterHeight + filterMarginBottom
    let tableHeight = parentHeight ? parentHeight - outerHeight : 0
    if (window.innerHeight < 850 && tableHeight < 600) tableHeight = 650
    const subRowsHeight =
      this.state.bookingType === 'transport'
        ? 0
        : this.state.showExpandedRows.reduce((sum, enable, index) => {
            if (!enable) {
              return sum
            }
            const booking = this.state.showBookings[index]
            const recipients = booking.deliveryInfo.recipients
            if (!recipients || recipients.length === 0) {
              return sum
            }
            return sum + 40 * (recipients.length + 1)
          }, 0)
    return tableHeight - 40 //+ subRowsHeight
  }

  subRowHeightGetter = (index) => {
    if (this.state.bookingType === 'transport') {
      return 0
    }
    if (!this.state.showExpandedRows[index]) {
      return 0
    }
    const booking = this.state.showBookings[index]
    const recipients = booking.deliveryInfo.recipients
    if (!recipients.length) {
      return 0
    }
    return 40 + recipients.length * 40
  }

  rowClickHandle = (event, index) => {
    const showExpandedRows = this.state.showExpandedRows
    showExpandedRows[index] = !showExpandedRows[index]
    this.setState({ showExpandedRows })
  }

  rowExpanded = ({ rowIndex }) => {
    if (this.state.bookingType === 'transport') {
      return
    }
    if (!this.state.showExpandedRows[rowIndex]) {
      return
    }
    const booking = this.state.showBookings[rowIndex]
    return <DeliveryRecipients booking={booking} />
  }

  render() {
    const LimitExceededMessage = (props) => (
      <Modal onHide={() => this.setState({ limitExceeded: false })} backdrop="static" show={props.visible} className="modal-import">
        <Modal.Header className="modal-header-import">
          <Modal.Title className="qup-red">Limit exceeded</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-import">
          <p>{I18n.t('importBookings.limitExceededBookings')}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-import">
          <button
            className="btn-cancel-import"
            onClick={() => this.setState({ limitExceeded: false })}
          >
            {I18n.t('importBookings.cancel')}
          </button>
        </Modal.Footer>
      </Modal>
    )

    const ConfirmImport = (props) => (
      <Modal backdrop="static" show={props.visible} className="modal-import" size='sm'>
        <Modal.Header className="modal-header-import">
          <Modal.Title className="qup-red">Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-import">
          <p>
            {this.props.importBookings.numberOfValid}/
            {this.props.importBookings.bookings.length}{' '}
            {I18n.t('importBookings.confirmMessage')}
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-import">
          <button
            className="btn-confirm-import"
            onClick={this.handleSubmitBookings.bind(this)}
          >
            Create
          </button>
          <button
            className="btn-cancel-import"
            onClick={this.handleConfirmCloseClick.bind(this)}
          >
            {I18n.t('importBookings.cancel')}
          </button>
        </Modal.Footer>
      </Modal>
    )

    const ImportedResult = (props) => (
      <Modal backdrop="static" show={props.visible} className="modal-import" size='sm'>
        <Modal.Body className="modal-body-import">
          <p>
            {_.template(I18n.t('importBookings.resultMessage'))({
              success: this.props.importBookings.numberOfImportedSuccess,
              failed:
                this.props.importBookings.bookings.length -
                this.props.importBookings.numberOfImportedSuccess,
            })}
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-import">
          <button
            className="btn-close-result"
            onClick={this.handleCloseResultClick.bind(this)}
          >
            {I18n.t('importBookings.close')}
          </button>
        </Modal.Footer>
      </Modal>
    )

    const validating =
      this.props.importBookings.isLoading ||
      (!this.props.importBookings.validated &&
        this.props.importBookings.numberOfValidated)
    const { enabledDelivery, enabledTransport } = checkPermission.call(this)

    const BookingTypeSelector = () => (
      <div className="corporation-selector">
        <FormGroup>
          <FormControl
            as="select"
            value={this.state.bookingType}
            className="form-custom"
            placeholder="Select booking type"
            onChange={this.handleChangeBookingType.bind(this)}
          >
            {enabledTransport ? (
              <option key="transport" value="transport">
                {I18n.t('cue.Transport')}
              </option>
            ) : null}
            {enabledDelivery ? (
              <option key="delivery" value="delivery">
                {I18n.t('cue.delivery')}
              </option>
            ) : null}
          </FormControl>
        </FormGroup>
      </div>
    )

    const CorporateSelector = () => (
      <div className="corporation-selector">
        <FormGroup>
          <FormControl
            as="select"
            value={this.state.corporateId}
            disabled={this.props.auth.user.corporateId}
            className="form-custom"
            placeholder="Select corporation"
            onChange={this.handleChangeCorporation.bind(this)}
          >
            <option value={''}>Select corporation</option>
            {this.props.auth.user.corporateId ? (
              <option
                key={this.props.auth.user.corporateId}
                value={this.props.auth.user.corporateId}
              >
                {this.state.corporate.companyName}
              </option>
            ) : (
              this.props.corporateCompany.map((a) => (
                <option key={a._id} value={a._id}>
                  {a.companyInfo.name}
                </option>
              ))
            )}
          </FormControl>
        </FormGroup>
      </div>
    )

    const ImportSection = () => (
      <div className="import-section">
        <div>
          <UploadFile
            noIcon
            disabled={validating || this.props.importBookings.isImporting}
            noRemoveIcon
            file={this.props.importBookings.file}
            accepts=".xlsx"
            text={
              <div className="import-icon-and-text">
                <FileOutlineIcon />
                Click or drop .xlsx file
              </div>
            }
            handleFileChange={this.handleFileChange.bind(this)}
          />
          {this.props.importBookings.isLoading &&
          !this.props.importBookings.validated &&
          !this.props.importBookings.numberOfValidated ? (
            <div className="import-result">
              <span>{I18n.t('importBookings.importingFileForReview')}</span>
            </div>
          ) : null}
          {this.props.importBookings.numberOfValidated > 0 ? (
            <div className="import-result-count">
              <div className="import-result-total">
                {I18n.t('importBookings.total')}:{' '}
                <span>{this.props.importBookings.bookings.length}</span>
              </div>
              <div className="import-result-valid">
                {I18n.t('importBookings.valid')}:{' '}
                <span>{this.props.importBookings.numberOfValid}</span>
              </div>
              <div className="import-result-invalid">
                {I18n.t('importBookings.invalid')}:{' '}
                <span>{this.props.importBookings.numberOfInvalid}</span>
              </div>
            </div>
          ) : null}
        </div>
        {validating ? (
          <div>
            <BeatLoader color="#FFF" size={12} loading />
          </div>
        ) : (
          <button
            type="button"
            disabled={
              this.props.importBookings.isLoading ||
              this.props.importBookings.isImporting ||
              this.props.importBookings.imported ||
              this.props.importBookings.bookings.length === 0 ||
              (!this.props.importBookings.validated &&
                this.props.importBookings.numberOfValidated) ||
              this.props.importBookings.numberOfInvalid ===
                this.props.importBookings.bookings.length
            }
            onClick={this.validateAndTransformBookings.bind(this)}
          >
            {I18n.t('importBookings.import')}
          </button>
        )}
      </div>
    )

    const SubmitButton = () => (
      <div className="submit-button">
        <LimitExceededMessage visible={this.state.limitExceeded} />
        <ValidationPopUp
          visible={this.props.importBookings.showValidationErrorModal}
          code={this.props.importBookings.errorCode}
          data={this.props.importBookings.errorData}
          onClose={() =>
            this.props.importBookingsAction.showHideValidationPopup({
              showValidationErrorModal: false,
            })
          }
        />
        <ConfirmImport
          visible={this.state.showConfirmedImport}
          handleConfirmButtonClick={this.handleSubmitBookings.bind(this)}
          handleConfirmCloseClick={this.handleConfirmCloseClick.bind(this)}
        />
        <ImportedResult
          visible={this.state.showImportedResult}
          handleConfirmCloseClick={this.handleCloseResultClick.bind(this)}
        />
        {this.props.importBookings.isImporting ? (
          <div>
            <BeatLoader color="#FFF" size={12} loading />
          </div>
        ) : (
          <button
            type="button"
            disabled={
              this.props.importBookings.isLoading ||
              this.props.importBookings.numberOfValid === 0 ||
              !this.props.importBookings.validated ||
              this.props.importBookings.imported ||
              this.props.importBookings.isImporting
            }
            onClick={this.handleShowConfirmImport.bind(this)}
          >
            {I18n.t('importBookings.submit')}
          </button>
        )}
      </div>
    )

    const DownloadTemplate = () => (
      <div className="template-learn-more qup-green">
        <button
          id="download-template-btn"
          className="btn btn-link qup-green"
          onClick={async () => {
            await this.setState({ downloadTemplate: true })
            const host = this.props.auth.selectedFleet.s3Host
            const service = this.state.bookingType
            window.location = `${host}import-templates/Import-${service}-template.xlsx`
          }}
        >
          {I18n.t('importBookings.downloadTemplate')}
        </button>
      </div>
    )

    const bookings = formatBooking(
      this.state.showBookings,
      this.props.importBookings.isImporting
    )

    let className = 'cue-table'
    if (this.state.bookingType === 'delivery') {
      className += ' row-delivery'
    }

    return (
      <div className="content content-cus">
        {false && <LoadingSpiner show={this.props.importBookings.isLoading} />}
        <div
          className="import-actions"
          ref={(node) => {
            this.activeFilter = node
          }}
        >
          <div className="corporate-and-import">
            <div className="import-page-title">Import bookings</div>
            <BookingTypeSelector />
            <CorporateSelector />
            <ImportSection />
          </div>
          <SubmitButton />
        </div>

        <DownloadTemplate />

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            isImportBooking
            bodyData={bookings}
            className={className}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange.bind(
              this
            )}
            handlePaginationSelect={this.handlePaginationSelect.bind(this)}
            subRowHeightGetter={this.subRowHeightGetter.bind(this)}
            handleRowClick={this.rowClickHandle.bind(this)}
            rowExpanded={this.rowExpanded.bind(this)}
            sortHandle={this.handleSortCustomerClick}
            getTableHeight={this.getTableHeight.bind(this)}
            language={this.props.language}
            settings={this.props.auth.selectedFleet}
          />
        </div>
      </div>
    )
  }
}

ImportBookings.contextTypes = {
  notification: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    selectedFleet: state.auth.selectedFleet,
    auth: state.auth,
    importBookings: state.importBookings,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
    corporateCompany: state.corporateCompany,
    socket: state.socket,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    importBookingsAction: bindActionCreators(importBookingsAction, dispatch),
    detailCorporate: (options) => dispatch(detailCorporate(options)),
    corporateCompanyActions: bindActionCreators(
      corporateCompanyActions,
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportBookings)
