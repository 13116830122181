import ReportUtils from './../base/utils'

export const fields = [{
  key: "date",
  label: "report.result.redeemed.date",
  summary: "date",
  mutate: value => ReportUtils.formatTime(value, {}, 'MM/DD/YYYY')
},
  {
    key: "campaignName",
    label: "report.result.redeemed.campaignName",
  },{
  key: "code",
  label: "report.result.redeemed.code",
  summary: "code"
}, {
  key: "customer",
  label: "report.result.redeemed.customers",
  summary: "customers"
}, {
  key: "phoneNumber",
  label: "report.result.redeemed.phone",
  summary: "customers"
}, {
  key: "value",
  label: "report.result.redeemed.value",
  summary: "value"
}, {
  key: "currency",
  label: "report.result.redeemed.currency",
}]

export const fareFields = [
  "value"
]
