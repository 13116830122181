import React from 'react';
import _ from 'lodash';
import {
  Modal, FormGroup, FormControl, Button
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportNotificationActions from '../../../actions/reportNotificationActions';
import * as bookingDetailActions from "../../../actions/bookingDetailAction";
import * as notificationActions from "../../../actions/notificationActions";
import * as loadingActions from "../../../actions/loadingBarActions";
import BaseNotifications from '../base/BaseNotifications';
import { notificationHistory } from "../../../constants/ApiConfigs"
import PropTypes from "prop-types";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
  }

  deleteNotiItem = (data) => {
    this.props.notificationActions.notificationDeleteItem({
      fleetId: this.props.selectedFleet.fleetId,
      id: data.id
    })
  }

  itemclick = (noti) => {
    if(noti.action != 'DebtWriteOff') {
      if (_.get(this.props, 'auth.user.roles.isSupplier', false)) {
        this.props.router.push({pathname:`/supplier-booking-detail/${noti.bookId}`, state: {selectedTab: 0}})
      } else {
        this.props.bookingDetailActions
        .getBookingDetail(noti.bookId*1, false)
        .then(data => {
          if (!data.res || data.error) {
            this.props.bookingDetailActions
              .getBookingDetail(noti.bookId*1, true)
              .then(data => {
                if (!data.res || data.error) {
                  this.context.notification(
                    "error",
                    I18n.t("messages.booking.Booking_detail_not_found")
                  );
                }
              });
          }
        });
      }
      this.props.notificationActions.notificationMarkItemAsRead({
        fleetId: this.props.selectedFleet.fleetId,
        id: noti.id
      })
    }
  }

  render() {
    return (
      <BaseNotifications
        apiurl={notificationHistory}
        itemclick={this.itemclick}
        deleteNotiItem={this.deleteNotiItem}
        placeholderSearch={I18n.t("notifications.search")}
        isNotification
        noexport
      />
    );
  }
}

Notifications.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportNotificationActions: bindActionCreators(reportNotificationActions, dispatch),
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
