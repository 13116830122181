import "./Login.scss";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import { bindActionCreators } from "redux";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import Helmet from "react-helmet";

import {
  Button,
  Form,
  Row,
  Col,
  FormControl,
  Checkbox,
  FormGroup,
  InputGroup
} from "react-bootstrap";
import { resetPassword, checkResetPasswordToken } from "../../actions/auth";

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmitted: false,
      isShowMessage: false
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );
    this.handleBackToLoginPage = this.handleBackToLoginPage.bind(this);
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .checkResetPasswordToken({
        token: this.props.params.token
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res && !data.res.isValid) {
          this.setState({ isShowMessage: true });
        }
      });
  }

  handleBackToLoginPage() {
    this.props.router.push({
      pathname: "/login"
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {}

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }

  handleConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleResetPassword(event) {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    //resetPassword
    this.props
      .resetPassword({
        password: this.state.newPassword,
        token: this.props.params.token
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        this.setState({ isSubmitted: false });
        if (data.ok) {
          this.props.router.push({
            pathname: "/login"
          });
        } else if (data.error) {
          this.setState({
            resetPasswordMessage: I18n.t("errors." + data.error.errorCode)
          });
        }
      });
  }
  render() {
    const { valid, isSubmitted } = this.state;
    return (
      <div className={"login-body"} horizontal>
        <Helmet title={"Reset password"} />
        <div className={"bg-image"}>
          {!this.state.isShowMessage ? (
            <div horizontal className={"login-form mt-md-2 mt-lg-4"}>
              {this.state.resetPasswordMessage ? (
                <span className="login-error">
                  {I18n.t("errors." + this.state.resetPasswordMessage)}
                </span>
              ) : (
                ""
              )}
              <FormGroup>
                <FormControl
                  type="password"
                  ref="password"
                  className="form-custom"
                  placeholder={I18n.t("resetPassword.New_Password")}
                  value={this.state.newPassword}
                  onChange={this.handleNewPasswordChange}
                  required
                  autoFocus
                />
                <Validator
                  className="login-error"
                  id="newPassword"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={Validation.validatePassword(this.state.newPassword)}
                    message={I18n.t(
                      "changePassword.ERROR_INPUT_VALID_PASSWORD"
                    )}
                  />
                </Validator>
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="password"
                  ref="password"
                  className="form-custom"
                  placeholder={I18n.t("resetPassword.New_Comfirm_Password")}
                  value={this.state.confirmPassword}
                  onChange={this.handleConfirmPasswordChange}
                  required
                />
                <Validator
                  className="login-error"
                  id="confirmPassword"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmitted}
                    valid={
                      this.state.newPassword === this.state.confirmPassword
                    }
                    message={I18n.t(
                      "changePassword.ERROR_INPUT_MATCH_PASSWORD"
                    )}
                  />
                </Validator>
              </FormGroup>
              <Button
                block
                type="submit"
              	className="text-center login-button btn-save"
                onClick={this.handleResetPassword}
              >
                <Translate value="resetPassword.Save" />
              </Button>
            </div>
          ) : (
            <div horizontal className={"login-form mt-md-2 mt-lg-4"}>
							<div className={"login-alert login-errors"}>
                <span>{I18n.t("resetPassword.TOKEN_EXPIRED")}</span>
              </div>
              <Button
                block
                type="submit"
              	className="text-center login-button btn-save"
                onClick={this.handleBackToLoginPage}
              >
                <Translate value="resetPassword.Back_To_Login_Page" />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: options => {
      return dispatch(resetPassword(options));
    },
    checkResetPasswordToken: options => {
      return dispatch(checkResetPasswordToken(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword);
