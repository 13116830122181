import { createPortal } from 'react-dom';
import * as S from './styles';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutSide';

function Suggestion({ items, onSelect, anchor, onClose }) {
  const [selectingItem, setSelectingItem] = useState(0);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.keyCode;

      if (key === 13) {
        // Enter
        if (!event.shiftKey) {
          event.preventDefault();
          onSelect(items[selectingItem]);
        }

        return;
      }

      if (items.length === 1) {
        return;
      }

      if (key === 40) {
        // arrow down
        event.preventDefault();
        const newIndex =
          selectingItem === items.length - 1 ? 0 : selectingItem + 1;
        setSelectingItem(newIndex);
      } else if (key === 38) {
        // arrow up
        event.preventDefault();
        const newIndex =
          selectingItem === 0 ? items.length - 1 : selectingItem - 1;
        setSelectingItem(newIndex);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [items, selectingItem, onSelect]);

  useEffect(() => {
    setSelectingItem(0);
  }, [items]);

  useLayoutEffect(() => {
    // scroll to selecting item
    if (popupRef.current) {
      const item = popupRef.current.querySelector(
        'li:nth-child(' + (selectingItem + 1) + ')'
      );

      if (item) {
        item.scrollIntoView({ block: 'nearest', inline: 'start' });
      }
    }
  }, [selectingItem]);

  const handleClickOutSide = (event) => {
    onClose(event);
  };

  useOnClickOutside(popupRef, handleClickOutSide);

  const handleSelectItem = (item) => () => {
    onSelect(item);
  };

  return (
    <>
      {createPortal(
        <S.SuggestionPopup style={anchor} ref={popupRef}>
          <ul>
            {items.map((item, index) => (
              <S.SuggestionItem
                key={item.value}
                onClick={handleSelectItem(item)}
                isSelecting={index === selectingItem}
              >
                <S.Title>{item.value}</S.Title>
                <S.Description>{item.description}</S.Description>
              </S.SuggestionItem>
            ))}
          </ul>
        </S.SuggestionPopup>,
        document.body
      )}
    </>
  );
}

Suggestion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  anchor: PropTypes.object,
};

export default Suggestion;
