import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../../settings.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import FormGroupTitle from '../../../../../components/formGroupTitile/FormGroupTitle';
import * as intercityRateActions from '../../../../../actions/intercityRateActions';
import * as intercityZoneActions from '../../../../../actions/intercityZoneActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import _ from 'lodash';
import RouteMap from './RouteMap';
import RouteSettings from './RouteSettings';
import RouteLimitSeatLuggageForm from './RouteLimitSeatLuggageForm';
import { tr } from 'react-dom-factories';

var currencyFormatter = require('currency-formatter');

class AddEditIntercityRouteModal extends React.PureComponent {
  state = {
    valid: {},
    zoneList: [],
    editable: this.props.editable,
    isSubmmitted: false,
    currentCurrency: this.props.auth.selectedFleet.currencies[0],
    showModal: true,
    routeInfo: {
      routeName: '',
      routeFrom: '',
      routeTo: '',
      currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
      firstLocation: null,
      secondLocation: null,
      routeOneSetting: {
        pricePerSeat: [],
        cancellationPenalty: [],
        scheduleEnable: true,
        schedules: [],
        requestTimeEnable: false,
        requestTime: {
          timeRange: 10,
          workingTime: {
            from: {
              hour: 0,
              minute: 0,
            },
            to: {
              hour: 23,
              minute: 59,
            },
          }
        }
      },
      routeTwoSetting: {
        pricePerSeat: [],
        cancellationPenalty: [],
        scheduleEnable: true,
        schedules: [],
        requestTimeEnable: false,
        requestTime: {
          timeRange: 10,
          workingTime: {
            from: {
              hour: 0,
              minute: 0,
            },
            to: {
              hour: 23,
              minute: 59,
            },
          }
        }
      },
      limitSeatLuggage: {
        warningMinimumSeats: 3,
        allowStartLessMinRequiredSeats: false,
        freeLuggagePerSeat: 1,
        allowToAddExtraLuggage: false,
        feePerExtraLuggage: []
      }
    },
  };

  componentWillMount() {
    const { routeInfo, routeInfoDeeplink } = this.props;
    const locationQuery = _.get(this.props.router, 'location.query', {});
    const routeInfoId = routeInfo?._id || routeInfoDeeplink?._id
    this.initIntercityZones();
    if (routeInfoId) {
      this.props.intercityRateActions
        .getDetailIntercityRateRoutes({
          routeId: routeInfoId,
          fleetId: this.props.auth.selectedFleet.fleetId,
        })
        .then((res) => {
          if (res.ok) {
            this.setState({ 
              routeInfo: res.res,
            });
          } else {
            if (res.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + res.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('regularSetting.Loading_intercity_rate_fail')
              );
            }
          }
        });
    } else if (routeInfoDeeplink?._id) {
      this.setState({ 
        routeInfo: routeInfoDeeplink,
      });
    }
  }

  initIntercityZones = () => {
    this.props.intercityZoneActions
      .getAllMapZone(this.props.auth.selectedFleet.fleetId)
      .then(({ res }) => {
        this.setState({ zoneList: res.filter(o => o.activate) });
      });
  };

  handleEdit = () => {
    this.setState({ editable: true });
  };

  handleInputChange = (key, e) => {
    const { routeInfo } = this.state;
    routeInfo[key] = e.target.value;
    this.setState({ routeInfo: { ...routeInfo }, dialogChanged: true });
  };

  handleChangeCurrencyISO = (e) => {
    const { routeInfo } = this.state;
    const { auth: { selectedFleet } } = this.props;
    const foundCurrency = _.find(selectedFleet.currencies, o => o.iso === e.target.value);
    routeInfo.currencyISO = e.target.value;
    routeInfo.currencySymbol = foundCurrency.symbol;
    this.setState({ routeInfo: { ...routeInfo }, currentCurrency: foundCurrency });
  };

  handleRouteZoneChange = (key, e) => {
    const { routeInfo } = this.state;
    if (e.target.value == '') {
      routeInfo[key] = null;
      this.setState({ routeModel: { ...routeInfo } });
    } else {
      this.state.zoneList.map((item) => {
        if (item._id == e.target.value) {
          routeInfo[key] = {
            zoneId: item._id,
            name: item.zoneName,
            type: 'Polygon',
            coordinates: item.geo.coordinates,
          };
        }
      });
      this.setState({ routeModel: { ...routeInfo } });
    }
  };

  handleChangeRouteOneSetting = (key, value) => {
    const {
      routeInfo,
      routeInfo: { routeOneSetting },
    } = this.state;
    routeOneSetting[key] = value;
    this.setState({
      routeInfo: { ...routeInfo, routeOneSetting: { ...routeOneSetting } },
    });
  };

  handleChangeScheduleEnableRouteOne = (e) => {
    const {
      routeInfo,
      routeInfo: { routeOneSetting },
    } = this.state;
    routeOneSetting.scheduleEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeOneSetting: { ...routeOneSetting } }
    });
  };

  handleChangeScheduleEnableRouteTwo = (e) => {
    const {
      routeInfo,
      routeInfo: { routeTwoSetting },
    } = this.state;
    routeTwoSetting.scheduleEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeTwoSetting: { ...routeTwoSetting } }
    });
  };

  handleChangeRequestTimeEnableRouteOne = (e) => {
    const {
      routeInfo,
      routeInfo: { routeOneSetting },
    } = this.state;
    routeOneSetting.requestTimeEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeOneSetting: { ...routeOneSetting } }
    });
  };

  handleChangeRequestTimeRouteOne = (key, value) => {
    const {
      routeInfo,
      routeInfo: { routeOneSetting },
    } = this.state;

    let requestTime = {};

    if (key === 'timeRange') {
      requestTime = value
    };

    if (key === 'requestTime') {
      requestTime = value
    };

    this.setState({
      routeInfo: { ...routeInfo, routeOneSetting: { ...routeOneSetting, requestTime  } }
    });
  };

  handleChangeHideRouteEnableRouteOne = e => {
    const {
      routeInfo,
      routeInfo: { routeOneSetting },
    } = this.state;
    routeOneSetting.hideRouteEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeOneSetting: { ...routeOneSetting } }
    });
  };

  handleChangeRequestTimeEnableRouteTwo = (e) => {
    const {
      routeInfo,
      routeInfo: { routeTwoSetting },
    } = this.state;
    routeTwoSetting.requestTimeEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeTwoSetting: { ...routeTwoSetting } }
    });
  };

  handleChangeRequestTimeRouteTwo = (key, value) => {
    const {
      routeInfo,
      routeInfo: { routeTwoSetting },
    } = this.state;

    let requestTime = {};

    if (key === 'timeRange') {
      requestTime = value
    };

    if (key === 'requestTime') {
      requestTime = value
    };

    this.setState({
      routeInfo: { ...routeInfo, routeTwoSetting: { ...routeTwoSetting, requestTime } }
    });
  };

  handleChangeHideRouteEnableRouteTwo = e => {
    const {
      routeInfo,
      routeInfo: { routeTwoSetting },
    } = this.state;
    routeTwoSetting.hideRouteEnable = e.target.checked;
    this.setState({
      routeInfo: { ...routeInfo, routeTwoSetting: { ...routeTwoSetting } }
    });
  };

  handleChangeRowTwoEnable = (e) => {
    const {
      routeInfo
    } = this.state;
    this.setState({
      routeInfo: { ...routeInfo, routeTwoEnable: e.target.checked }
    });
  }
  handleChangeRouteTwoSetting = (key, value) => {
    const {
      routeInfo,
      routeInfo: { routeTwoSetting },
    } = this.state;
    routeTwoSetting[key] = value;
    this.setState({
      routeInfo: { ...routeInfo, routeTwoSetting: { ...routeTwoSetting } },
    });
  };

  handleChangeLimitSetting = (key, value) => {
    const {
      routeInfo,
      routeInfo: { limitSeatLuggage },
    } = this.state;
    limitSeatLuggage[key] = value;
    this.setState({
      routeInfo: { ...routeInfo, limitSeatLuggage: { ...limitSeatLuggage } },
    });
  };

  validatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: { ...this.state.valid } });
    }
  };

  tranformCurrencies = (body) => {
    const currencyFields = [
      'routeOneSetting.cancellationPenalty',
      'routeOneSetting.pricePerSeat',
      'limitSeatLuggage.feePerExtraLuggage'
    ]
    if (body.routeTwoEnable) {
      currencyFields.push('routeTwoSetting.cancellationPenalty')
      currencyFields.push('routeTwoSetting.pricePerSeat')
    }
    _.forEach(currencyFields, field => {
      const value = _.get(body, `${field}[0].value`, 0);
      _.set(body, field, [{
        currencyISO: body.currencyISO || this.props.auth.selectedFleet.currencies[0].iso,
        value
      }])
    })
  };

  saveRouteModelClick = (e) => {
    e.preventDefault();
    this.setState({ isSubmmitted: true });
    if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
      const { routeInfo } = this.state;
      const body = _.cloneDeep(routeInfo);
      this.props.loadingBarActions.showLoadingSpiner();
      body.fleetId = this.props.auth.selectedFleet.fleetId;
      body.intercityRateId = this.props.intercityRateId;
      body.routeFrom = body.firstLocation.zoneId;
      body.routeTo = body.secondLocation.zoneId;
      body.currencyISO = body.currencyISO || this.props.auth.selectedFleet.currencies[0].iso;
      body.currencySymbol = body.currencySymbol || this.props.auth.selectedFleet.currencies[0].symbol;
      if(!body.routeOneSetting.requestTimeEnable){
        delete body.routeOneSetting.requestTime;
      };
      if(!body.routeTwoSetting.requestTimeEnable){
        delete body.routeTwoSetting.requestTime;
      };
      console.log("body ", body)

      this.tranformCurrencies(body)
      if (body._id) {
        body.routeId = body._id;
        this.props.intercityRateActions
          .updateIntercityRateRoute(body)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (!data.ok) {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('intercityRoute.Update_intercity_route_fail')
                );
              }
            } else {
              this.context.notification(
                'success',
                I18n.t('intercityRoute.Update_intercity_route_success')
              );
              this.props.onSuccess();
            }
          });
      } else {
        this.props.intercityRateActions
          .createIntercityRateRoute(body)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
              this.context.notification(
                'success',
                I18n.t('intercityRoute.created_intercity_route_success')
              );
              this.props.onSuccess();
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('intercityRoute.created_intercity_route_fail')
                );
              }
            }
          });
      }
    }
  };

  render() {
    const { routeInfo, valid, isSubmmitted, editable, showModal } = this.state;
    const {
      commonData: {
        location: { isChina },
      },
      auth: { selectedFleet }
    } = this.props;
    const hasPermission =
      (!this.props.permissions || this.props.permissions.actions);
    return (
      <Modal onHide={this.props.closeDialogForm} show={true} backdrop={true} bsSize="lg">
        <form onSubmit={this.saveRouteModelClick}>
          <Modal.Header>
            <Modal.Title>
              {routeInfo._id ? (
                <Translate value="regularSetting.EDIT_ROUTE" />
              ) : (
                  <Translate value="regularSetting.ADD_ROUTE" />
                )}
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.props.closeDialogForm}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body className={'pd-r-l-60'}>
            <Row>
              <Col sm={6}>
                <FormGroup
                  className={
                    this.state.isSubmmitted
                      ? valid.routeName === false
                        ? 'error'
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="intercityRoute.alias" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={(e) => {
                      this.handleInputChange('routeName', e);
                    }}
                    value={routeInfo ? routeInfo.routeName : ''}
                    placeholder={I18n.t('intercityRoute.alias')}
                    disabled={!hasPermission}
                  />
                  <Validator id="routeName" callback={this.validatorCallback}>
                    <ValidCase
                      valid={!Validation.isStringEmpty(routeInfo.routeName)}
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!this.state.isSubmmitted}
                    />
                  </Validator>
                </FormGroup>
              </Col>
              <Col sm={6}>
                {selectedFleet.multiCurrencies ? (
                  <FormGroup>
                    <Form.Label>
                      <Translate value="intercityRoute.Currency" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      onChange={this.handleChangeCurrencyISO}
                      value={routeInfo.currencyISO}
                      disabled={!hasPermission}
                    >
                      {selectedFleet.currencies.map((item) => {
                        return (
                          <option value={item.iso} key={item.iso}>
                            {item.iso}
                          </option>
                        );
                      })}
                    </FormControl>
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <FormGroup
                  className={
                    this.state.isSubmmitted
                      ? valid.fromZone === false
                        ? 'error'
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.First_location" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    as="select"
                    className={'form-custom'}
                    value={
                      routeInfo.firstLocation
                        ? routeInfo.firstLocation.zoneId
                        : ''
                    }
                    onChange={(e) => {
                      this.handleRouteZoneChange('firstLocation', e);
                    }}
                    disabled={!hasPermission || routeInfo._id}
                  >
                    <option value="">
                      {I18n.t('regularSetting.Select_a_zone')}
                    </option>
                    {this.state.zoneList
                      ? this.state.zoneList.map((item) => {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.zoneName}
                          </option>
                        );
                      })
                      : ''}
                  </FormControl>
                  <Validator
                    id="fromZone"
                    callback={this.validatorCallback}
                  >
                    <ValidCase
                      valid={routeInfo.firstLocation != null}
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!this.state.isSubmmitted}
                    />
                  </Validator>
                </FormGroup>
                <div className="route-map">
                  <RouteMap isChina={isChina} zone={routeInfo.firstLocation} />
                </div>
              </Col>
              <Col xs={6} md={6}>
                <FormGroup
                  className={
                    this.state.isSubmmitted
                      ? valid.toZone === false
                        ? 'error'
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Second_location" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    as="select"
                    className={'form-custom'}
                    value={
                      routeInfo.secondLocation
                        ? routeInfo.secondLocation.zoneId
                        : ''
                    }
                    onChange={(e) => {
                      this.handleRouteZoneChange('secondLocation', e);
                    }}
                    disabled={!hasPermission || routeInfo._id}
                  >
                    <option value="">
                      {I18n.t('regularSetting.Select_a_zone')}
                    </option>
                    {this.state.zoneList
                      ? this.state.zoneList.map((item) => {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.zoneName}
                          </option>
                        );
                      })
                      : ''}
                  </FormControl>
                  <Validator
                    id="toZone"
                    callback={this.validatorCallback}
                  >
                    <ValidCase
                      valid={routeInfo.secondLocation != null}
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!this.state.isSubmmitted}
                    />
                  </Validator>
                </FormGroup>
                <div className="route-map">
                  <RouteMap isChina={isChina} zone={routeInfo.secondLocation} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <FormGroupTitle className="mt-xl">
                  <div className="mb">
                    <Translate value="intercityRoute.routeOneSetup" />
                  </div>
                  {routeInfo.firstLocation && routeInfo.secondLocation ? (
                    <div>
                      <Translate value="regularSetting.Route_name" />
                      <QuestionCircleTooltip
                        text={I18n.t('intercityRoute.routeNameHint')}
                      />
                      :&nbsp;
                      <span className="text-base">
                        {`${_.get(routeInfo, 'firstLocation.name', I18n.t('intercityRoute.firstLocation'))}`}
                        <span>&nbsp;-&nbsp;</span>
                        {`${_.get(routeInfo, 'secondLocation.name', I18n.t('intercityRoute.secondLocation'))}`}
                      </span>
                    </div>
                  ) : null}
                </FormGroupTitle>
                <RouteSettings
                  routekey="routeOneSetting"
                  onChangeRouteSetting={this.handleChangeRouteOneSetting}
                  validatorCallback={this.validatorCallback}
                  editable={editable}
                  isSubmmitted={isSubmmitted}
                  valid={valid}
                  routeSetting={routeInfo.routeOneSetting}
                  selectedFleet={this.props.auth.selectedFleet}
                  currentCurrency={this.state.currentCurrency}
                  requestTime={routeInfo.routeOneSetting.requestTime}
                  handleChangeScheduleEnable={this.handleChangeScheduleEnableRouteOne}
                  handleChangeRequestTimeEnable={this.handleChangeRequestTimeEnableRouteOne}
                  // handleChangeRequestTimeSettings={this.handleChangeRequestTimeRouteOne}
                  handleChangeHideRouteEnable = {this.handleChangeHideRouteEnableRouteOne}
                />
              </Col>
              <Col xs={6} md={6}>
                <FormGroupTitle className="mt-xl">
                  <div className="routeSettingTitle mb">
                    <div>
                      <Translate value="intercityRoute.routetwoSetup" />
                    </div>
                    <CcCheckbox
                      checked={routeInfo.routeTwoEnable}
                      onChange={this.handleChangeRowTwoEnable}
                      className="ml mb0"
                    />
                  </div>

                  {routeInfo.firstLocation && routeInfo.secondLocation && routeInfo.routeTwoEnable ? (
                    <div>
                      <Translate value="regularSetting.Route_name" />
                      <QuestionCircleTooltip
                        text={I18n.t('intercityRoute.routeNameHint')}
                      />
                      :&nbsp;
                      <span className="text-base">
                        {`${_.get(routeInfo, 'secondLocation.name', I18n.t('intercityRoute.secondLocation'))}`}
                        <span>&nbsp;-&nbsp;</span>
                        {`${_.get(routeInfo, 'firstLocation.name', I18n.t('intercityRoute.firstLocation'))}`}
                      </span>
                    </div>
                  ) : null}
                </FormGroupTitle>
                {routeInfo.routeTwoEnable ? (
                  <RouteSettings
                    routekey="routeTwoSetting"
                    onChangeRouteSetting={this.handleChangeRouteTwoSetting}
                    validatorCallback={this.validatorCallback}
                    editable={editable}
                    isSubmmitted={isSubmmitted}
                    valid={valid}
                    routeSetting={routeInfo.routeTwoSetting}
                    selectedFleet={this.props.auth.selectedFleet}
                    currentCurrency={this.state.currentCurrency}
                    requestTime={routeInfo.routeTwoSetting.requestTime}
                    handleChangeScheduleEnable={this.handleChangeScheduleEnableRouteTwo}
                    handleChangeRequestTimeEnable={this.handleChangeRequestTimeEnableRouteTwo}
                    // handleChangeRequestTimeSettings={this.handleChangeRequestTimeRouteTwo}
                    handleChangeHideRouteEnable = {this.handleChangeHideRouteEnableRouteTwo}
                  />
                ) : null}
              </Col>
            </Row>
            <FormGroupTitle>
              <Translate value="intercityRoute.limitSeatLuggage" />
            </FormGroupTitle>
            <RouteLimitSeatLuggageForm
              routekey="limitSeatLuggage"
              onChangeLimitSetting={this.handleChangeLimitSetting}
              validatorCallback={this.validatorCallback}
              editable={editable}
              isSubmmitted={isSubmmitted}
              valid={valid}
              limitSetting={routeInfo.limitSeatLuggage}
              selectedFleet={this.props.auth.selectedFleet}
              currentCurrency={this.state.currentCurrency}
            />
          </Modal.Body>
          <Modal.Footer className={'pd-r-l-60'}>
            {hasPermission ? (
              <Button className={'btn-save mr-md'} type="submit">
                <Translate value="regularSetting.Save" />
              </Button>
            ) : (
                ''
              )}
            <Button
              className={'btn-cancel'}
              onClick={this.props.closeDialogForm}
            >
              <Translate value="regularSetting.Cancel" />
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

AddEditIntercityRouteModal.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    intercityZoneActions: bindActionCreators(intercityZoneActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditIntercityRouteModal);
