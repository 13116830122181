import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as settingActions from "../../actions/settingActions";
import "./settings.scss";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import FormGroupTitle from "../../components/formGroupTitile/FormGroupTitle";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import UploadFile from "../../components/upload/upload";
import UploadImageTemplate from "../../components/upload/UploadImageTemplate";

const actionOptions = {
  LinkToWebsite: "Link to website",
  MakeACall: "Make a call",
  SendAnEmail: "Send an email"
};
const applyTo = {
  HomeView: "Home view",
  OtherView: "Other view"
};
class AppBanner extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      isChanged: false,
      paxApp: {},
      driverApp: {},
      valid: {},
      isSubmitted: false
    };
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
  }
  componentDidMount() {
    this.props.settingActions
      .getAppBannerSetting({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then(data => {
        if (data.res && data.ok) {
          this.setState({ formData: data.res });
        }
      });
  }
  handleSaveClick() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    let ob = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      paxApp: this.state.formData.paxApp,
      driverApp: this.state.formData.driverApp
    };
    this.props.settingActions.saveAppBannerSetting(ob).then(data => {
      if (data.ok) {
        this.setState({ isChanged: false });
        this.context.notification(
          "success",
          I18n.t("appBanner.Update_app_banner_success")
        );
      } else {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("appBanner.Update_app_banner_fail")
          );
        }
      }
    });
  }

  handleInputChange(group, key, e) {
    if (!this.state.formData[group]) {
      this.state.formData[group] = {};
    }
    this.state.formData[group][key] = e.target.value;
    if (key == "action1") {
      this.state.formData[group]["txtAction1"] = "";
    } else if (key == "action2") {
      this.state.formData[group]["txtAction2"] = "";
    } else if (key == "action3") {
      this.state.formData[group]["txtAction3"] = "";
    }
    this.state.isChanged = true;
    this.setState({ formData: this.state.formData });
  }

  handleImageChange(group, key, data, error) {
    let result = "",
      file = null;

    if (data) {
      result = data.result;
      file = data.file;
    }

    this.state.formData[group][key] = result;
    this.state[group][key] = file;
    this.state.isChanged = true;
    this.setState(this.state);
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }
  render() {
    const hasPermission = !this.props.permissions || this.props.permissions.actions
    return (
      <div className="content vertical-flow-auto text-base app-banner-wrapper">
        <Row>
          <Col xs={12} className="app-banner-form-container">
            <FormGroupTitle>
              <Translate value="appBanner.Pax_app" />
            </FormGroupTitle>
            <table className="table noborder">
              <tbody>
                <tr>
                  <td>
                    <Form.Label>
                      <Translate value="appBanner.Apply_to" />{" "}<span className="require">*</span>
                    </Form.Label>
                  </td>
                  <td colSpan={3}>
                    <FormGroup
                      className={`apply-to-control ${
                        this.state.isSubmited
                          ? this.state.valid.paxApp === false
                            ? "error"
                            : null
                          : null
                      }`}
                    >
                      <FormControl
                        as="select"
                        value={
                          this.state.formData.paxApp
                            ? this.state.formData.paxApp.applyTo
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("paxApp", "applyTo", e);
                        }}
                        className="form-custom"
                      >
                        <option value="">
                          {I18n.t("appBanner.Please_select_view")}
                        </option>
                        <option value={applyTo.HomeView} selected="">
                          {I18n.t("appBanner.Home_view")}
                        </option>
                        <option value={applyTo.OtherView}>
                          {I18n.t("appBanner.Other_view")}
                        </option>
                      </FormControl>
                      <Validator id="paxApp" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.formData.paxApp
                                ? this.state.formData.paxApp.applyTo
                                : ""
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_1" />
                  </td>
                  <td>
                    <UploadFile
                      id="paxApp-banner-1"
                      name="paxApp.urlImage1"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "paxApp",
                          "urlImage1",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.paxApp &&
                            this.state.formData.paxApp.urlImage1) ||
                          null
                      }}
                      className="app-banner-upload-image"
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        value={
                          this.state.formData.paxApp
                            ? this.state.formData.paxApp.action1
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("paxApp", "action1", e);
                        }}
                        className="form-custom"
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.paxApp &&
                        this.state.formData.paxApp.action1 &&
                        this.state.formData.paxApp.action1.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange("paxApp", "txtAction1", e);
                            }}
                            value={
                              this.state.formData.paxApp
                                ? this.state.formData.paxApp.txtAction1
                                : ""
                            }
                            placeholder={
                              this.state.formData.paxApp.action1 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.example.com"
                                : this.state.formData.paxApp.action1 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@example.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_2" />
                  </td>
                  <td>
                    <UploadFile
                      id="paxApp-urlImage2"
                      name="paxApp.urlImage2"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "paxApp",
                          "urlImage2",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      className="app-banner-upload-image"
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.paxApp &&
                            this.state.formData.paxApp.urlImage2) ||
                          null
                      }}
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        value={
                          this.state.formData.paxApp
                            ? this.state.formData.paxApp.action2
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("paxApp", "action2", e);
                        }}
                        className="form-custom"
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.paxApp &&
                        this.state.formData.paxApp.action2 &&
                        this.state.formData.paxApp.action2.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange("paxApp", "txtAction2", e);
                            }}
                            value={
                              this.state.formData.paxApp
                                ? this.state.formData.paxApp.txtAction2
                                : ""
                            }
                            placeholder={
                              this.state.formData.paxApp.action2 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.qupworld.com"
                                : this.state.formData.paxApp.action2 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@qupworld.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_3" />
                  </td>
                  <td>
                    <UploadFile
                      id="paxApp-urlImage3"
                      name="paxApp.urlImage3"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "paxApp",
                          "urlImage3",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      className="app-banner-upload-image"
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.paxApp &&
                            this.state.formData.paxApp.urlImage3) ||
                          null
                      }}
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        value={
                          this.state.formData.paxApp
                            ? this.state.formData.paxApp.action3
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("paxApp", "action3", e);
                        }}
                        className="form-custom"
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.paxApp &&
                        this.state.formData.paxApp.action3 &&
                        this.state.formData.paxApp.action3.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange("paxApp", "txtAction3", e);
                            }}
                            value={
                              this.state.formData.paxApp
                                ? this.state.formData.paxApp.txtAction3
                                : ""
                            }
                            placeholder={
                              this.state.formData.paxApp.action3 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.qupworld.com"
                                : this.state.formData.paxApp.action3 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@qupworld.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <FormGroupTitle>
              <Translate value="appBanner.Driver_app" />
            </FormGroupTitle>
            <table className="table noborder">
              <tbody>
                <tr>
                  <td>
                    <Form.Label>
                      <Translate value="appBanner.Apply_to" />{" "}<span className="require">*</span>
                    </Form.Label>
                  </td>
                  <td colSpan={3}>
                    <FormGroup
                      className={`apply-to-control ${
                        this.state.isSubmited
                          ? this.state.valid.driverApp === false
                            ? "error"
                            : null
                          : null
                      }`}
                    >
                      <FormControl
                        as="select"
                        className="form-custom"
                        value={
                          this.state.formData.driverApp
                            ? this.state.formData.driverApp.applyTo
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("driverApp", "applyTo", e);
                        }}
                      >
                        <option value="">
                          {I18n.t("appBanner.Please_select_view")}
                        </option>
                        <option value={applyTo.HomeView} selected="">
                          {I18n.t("appBanner.Home_view")}
                        </option>
                        {/* <option value={applyTo.OtherView}><Translate value='appBanner.Other_view' /></option> */}
                      </FormControl>
                      <Validator
                        id="driverApp"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.formData.driverApp
                                ? this.state.formData.driverApp.applyTo
                                : ""
                            )
                          }
                          message={I18n.t(
                            "messages.commonMessages.Required_field"
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_1" />
                  </td>
                  <td>
                    <UploadFile
                      id="driverApp-urlImage1"
                      name="driverApp.urlImage1"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "driverApp",
                          "urlImage1",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      className="app-banner-upload-image"
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.driverApp &&
                            this.state.formData.driverApp.urlImage1) ||
                          null
                      }}
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className="form-custom"
                        value={
                          this.state.formData.driverApp
                            ? this.state.formData.driverApp.action1
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("driverApp", "action1", e);
                        }}
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.driverApp &&
                        this.state.formData.driverApp.action1 &&
                        this.state.formData.driverApp.action1.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange(
                                "driverApp",
                                "txtAction1",
                                e
                              );
                            }}
                            value={
                              this.state.formData.driverApp
                                ? this.state.formData.driverApp.txtAction1
                                : ""
                            }
                            placeholder={
                              this.state.formData.driverApp.action1 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.qupworld.com"
                                : this.state.formData.driverApp.action1 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@qupworld.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_2" />
                  </td>
                  <td>
                    <UploadFile
                      id="driverApp-urlImage2"
                      name="driverApp.urlImage2"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "driverApp",
                          "urlImage2",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      className="app-banner-upload-image"
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.driverApp &&
                            this.state.formData.driverApp.urlImage2) ||
                          null
                      }}
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className="form-custom"
                        value={
                          this.state.formData.driverApp
                            ? this.state.formData.driverApp.action2
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("driverApp", "action2", e);
                        }}
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.driverApp &&
                        this.state.formData.driverApp.action2 &&
                        this.state.formData.driverApp.action2.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange(
                                "driverApp",
                                "txtAction2",
                                e
                              );
                            }}
                            value={
                              this.state.formData.driverApp
                                ? this.state.formData.driverApp.txtAction2
                                : ""
                            }
                            placeholder={
                              this.state.formData.driverApp.action2 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.qupworld.com"
                                : this.state.formData.driverApp.action2 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@qupworld.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Translate value="appBanner.Banner_3" />
                  </td>
                  <td>
                    <UploadFile
                      id="driverApp-urlImage3"
                      name="driverApp.urlImage3"
                      onChange={(data, error) => {
                        this.handleImageChange(
                          "driverApp",
                          "urlImage3",
                          data,
                          error
                        );
                      }}
                      customTemplate={UploadImageTemplate}
                      className="app-banner-upload-image"
                      customTemplateProps={{
                        fileResult:
                          (this.state.formData.driverApp &&
                            this.state.formData.driverApp.urlImage3) ||
                          null
                      }}
                      accepts="image/*"
                      onlyImage
                      disabled={!hasPermission}
                    />
                  </td>
                  <td>
                    <Translate value="appBanner.Action" />
                  </td>
                  <td>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className="form-custom"
                        value={
                          this.state.formData.driverApp
                            ? this.state.formData.driverApp.action3
                            : ""
                        }
                        onChange={e => {
                          this.handleInputChange("driverApp", "action3", e);
                        }}
                        disabled={!hasPermission}
                      >
                        <option value="">{I18n.t("appBanner.NA")}</option>
                        <option value={actionOptions.LinkToWebsite} selected="">
                          {I18n.t("appBanner.Link_to_website")}
                        </option>
                        <option value={actionOptions.MakeACall}>
                          {I18n.t("appBanner.Make_a_call")}
                        </option>
                        <option value={actionOptions.SendAnEmail}>
                          {I18n.t("appBanner.Send_an_email")}
                        </option>
                      </FormControl>
                      {this.state.formData.driverApp &&
                        this.state.formData.driverApp.action3 &&
                        this.state.formData.driverApp.action3.length > 0 ? (
                          <FormControl
                            className="mt form-custom"
                            type="text"
                            onChange={e => {
                              this.handleInputChange(
                                "driverApp",
                                "txtAction3",
                                e
                              );
                            }}
                            value={
                              this.state.formData.driverApp
                                ? this.state.formData.driverApp.txtAction3
                                : ""
                            }
                            placeholder={
                              this.state.formData.driverApp.action3 ==
                                actionOptions.LinkToWebsite
                                ? "https://www.qupworld.com"
                                : this.state.formData.driverApp.action3 ==
                                  actionOptions.MakeACall
                                  ? "+141555527777"
                                  : "support@qupworld.com"
                            }
                            disabled={!hasPermission}
                          />
                        ) : null}
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </table>
            <FormGroup className="pv-md ph-md bottom-container">
              <p>
                <Translate value="appBanner.note_1" limit={8} />
              </p>
              <p>
                <Translate value="appBanner.note_2" />
              </p>
              <p>
                <Translate value="appBanner.note_3" />
              </p>
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className="btn-save"
                  disabled={!this.state.isChanged}
                  onClick={this.handleSaveClick}
                >
                  <Translate value="appBanner.Save" />
                </Button>
              ) : (
                  ""
                )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
AppBanner.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppBanner);
