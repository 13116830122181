import React from 'react';
import { Image } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
// import ReactDOM from 'react-dom';
import './uploadImage.scss';

class ImageUploadWrapper extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  // }

  handleRemoveClick = e => {
    e.stopPropagation();
    e.preventDefault();
    this.props.onRemoveFile(null);
  };

  render() {
    const {
      onSelectFile,
      id,
      accept,
      name,
      disabled,
      fileResult,
      className,
      noImageTitle,
      note
    } = this.props;

    return (
      <div className={`${className || ''} upload-image-wrapper${disabled ? ' disabled' : ''}`}>
        <input
          type="file"
          name={name}
          id={id}
          accept={accept}
          className="upload-input"
          onChange={onSelectFile}
          disabled={disabled}
          ref={this.props.inputRef}
        />
        {fileResult ? (
          <React.Fragment>
            <Image src={fileResult} className="image-container" />
            <div className="upload-icon remove" onClick={this.handleRemoveClick} />
          </React.Fragment>
        ) : (
          <div className="no-image-selected">
            <span className="no-image-title">
              {I18n.t(noImageTitle || 'appBanner.Upload_an_image')}
            </span>
            <div className="upload-icon upload" />
            {note && <p className="noteUpfile">{I18n.t('appBanner.Note')}</p>}
          </div>
        )}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <ImageUploadWrapper inputRef={ref} {...props} />);
