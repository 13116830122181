import React, { Component } from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { PromotionAmountType } from '../../../../constants/commondata';
import RadioButton from '../../../../components/radioButton/radio';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import { Validator, ValidCase } from '../../../../components/validator';
import { Validation } from '../../../../utils/commonFunctions';

class LimitSettingFormAmount extends Component {
  handleTotalUsesLimitChange = (e, isLimited) => {
    const { itemKey, onChange } = this.props;
    onChange(e, itemKey, isLimited);
  };

  handleTotalUsesValueChange = (e) => {
    const { onChangeValue, itemKey } = this.props;
    onChangeValue(e, itemKey);
  };

  renderLabel = (key) => {
    switch (key) {
      case 'budgetLimit':
        return <Translate value="promotionSettings.Budget_per_user" />;
      case 'budgetLimitDay':
        return <Translate value="promotionSettings.Budget_per_user_day" />;
      case 'budgetLimitMonth':
        return <Translate value="promotionSettings.Budget_per_user_month" />;
      case 'budgetLimitYear':
        return <Translate value="promotionSettings.Budget_per_user_year" />;
      case 'valueLimitPerUse':
        return <Translate value="promotionSettings.valueLimitPerUse" />;
      case 'minimumEstFareApply':
        return <Translate value="promotionSettings.minimumEstFareApply" />;
      default:
        return null;
    }
  };

  render() {
    const {
      itemKey,
      itemValue,
      disable,
      valid,
      isSubmited,
      ValidatorCallback,
      currencies,
      currencyISO,
      amountValue,
      tooltip,
      budgetLimitValue = {},
      isPercent,
    } = this.props;
    return (
      <Row>
        <Col xs={12} md={12}>
          <div className="row-limit-edition">
            <Form.Label className="row-limit-edition-label">
              {this.renderLabel(itemKey)}
              {tooltip && <QuestionCircleTooltip text={tooltip} />}
            </Form.Label>

            <span className="mr-l-10 mr-r-10 row-limit-edition-check">
              <RadioButton
                text={I18n.t('promotionSettings.Unlimited')}
                //value={0}
                onChange={(e) => {
                  this.handleTotalUsesLimitChange(e, false);
                }}
                checked={!itemValue?.isLimited ? true : false}
                name={'budgetLimitation'}
                disabled={disable}
              />
            </span>

            <span className="mr-l-10 mr-r-10 row-limit-edition-check">
              <RadioButton
                text={I18n.t('promotionSettings.Limited')}
                //value={0}
                onChange={(e) => {
                  this.handleTotalUsesLimitChange(e, true);
                }}
                checked={itemValue?.isLimited ? true : false}
                name={'budgetLimitation'}
                disabled={disable}
              />
            </span>

            {itemKey === 'budgetLimit' ||
            itemKey === 'budgetLimitDay' ||
            itemKey === 'budgetLimitMonth' ||
            itemKey === 'budgetLimitYear' ? (
              <div className="row-limit-edition-input">
                {itemValue?.isLimited ? (
                  <FormGroup
                    className={`qup-input-group ${
                      !isSubmited ? null : valid[itemKey] ? null : 'error'
                    }`}
                  >
                    <InputGroup
                      className={
                        'single-addon-left ' + (disable ? 'disabled' : '')
                      }
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {currencies &&
                          currencies.find((obj) => obj.iso == currencyISO)
                            ? currencies.find((obj) => obj.iso == currencyISO)
                                .symbol
                            : ''}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        className="form-custom"
                        disabled={disable}
                        onChange={this.handleTotalUsesValueChange}
                        value={itemValue.value}
                      />
                    </InputGroup>
                    <Validator id={itemKey} callback={ValidatorCallback}>
                      <ValidCase
                        valid={!Validation.isStringEmpty(itemValue.value)}
                        message={I18n.t('promotionSettings.Please_input_value')}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          !(itemValue.value !== '' && isNaN(itemValue.value))
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          !budgetLimitValue.isLimited ||
                          Validation.isLessOrEqual(
                            itemValue.value,
                            budgetLimitValue.value
                          )
                        }
                        message={I18n.t(
                          'messages.commonMessages.less_or_equal'
                        ).format(budgetLimitValue.value)}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          isPercent ||
                          parseFloat(itemValue.value) >=
                            (isNaN(amountValue) ? 0 : parseFloat(amountValue))
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(I18n.t('promotionSettings.Value'))}
                        hide={!isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                ) : null}
              </div>
            ) : null}

            {itemKey === 'valueLimitPerUse' ? (
              <div className="row-limit-edition-input">
                {itemValue?.isLimited ? (
                  <FormGroup
                    className={`qup-input-group ${
                      !isSubmited
                        ? null
                        : valid.valueLimitPerUse
                        ? null
                        : 'error'
                    }`}
                  >
                    <InputGroup
                      className={
                        'single-addon-left ' + (disable ? 'disabled' : '')
                      }
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {currencies &&
                          currencies.find((obj) => obj.iso == currencyISO)
                            ? currencies.find((obj) => obj.iso == currencyISO)
                                .symbol
                            : ''}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        className="form-custom"
                        disabled={disable}
                        onChange={this.handleTotalUsesValueChange}
                        value={itemValue.value}
                      />
                    </InputGroup>
                    <Validator
                      id="valueLimitPerUse"
                      callback={ValidatorCallback}
                    >
                      <ValidCase
                        valid={!Validation.isStringEmpty(itemValue.value)}
                        message={I18n.t('promotionSettings.Please_input_value')}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          !(itemValue.value !== '' && isNaN(itemValue.value))
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={parseFloat(itemValue.value) > 0}
                        message={I18n.t(
                          'messages.commonMessages.greater_than_0'
                        )}
                        hide={!isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                ) : null}
              </div>
            ) : null}

            {itemKey === 'minimumEstFareApply' ? (
              <div className="row-limit-edition-input">
                {itemValue?.isLimited ? (
                  <FormGroup
                    className={`qup-input-group ${
                      !isSubmited
                        ? null
                        : valid.minimumEstFareApply
                        ? null
                        : 'error'
                    }`}
                  >
                    <InputGroup
                      className={
                        'single-addon-left ' + (disable ? 'disabled' : '')
                      }
                      >
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {currencies &&
                          currencies.find((obj) => obj.iso == currencyISO)
                            ? currencies.find((obj) => obj.iso == currencyISO)
                                .symbol
                            : ''}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        className="form-custom"
                        disabled={disable}
                        onChange={this.handleTotalUsesValueChange}
                        value={itemValue.value}
                      />
                    </InputGroup>
                    <Validator
                      id="minimumEstFareApply"
                      callback={ValidatorCallback}
                    >
                      <ValidCase
                        valid={!Validation.isStringEmpty(itemValue.value)}
                        message={I18n.t('promotionSettings.Please_input_value')}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          !(itemValue.value !== '' && isNaN(itemValue.value))
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                ) : null}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }
}

export default LimitSettingFormAmount;
