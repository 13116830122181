import React, { Component } from 'react';
import _ from 'lodash';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Tab,
  Tabs,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../../../../components/validator';

export default class LimitTopupAmount extends Component {
  render() {
    const {
      defaultLimitTopupData,
      auth,
      currency,
      hasPermission,
      handleUpdateLimitTopup,
      keyApp,
      DefaultCurenciesInputField,
      ValidatorCallback,
      isSubmited,
      valid,
      validateDataMinMax,
      creditWalletConfig: {
        enable,
        maximumByCurrencies = [],
        minimumByCurrencies = [],
      } = {},
    } = this.props;
    const currencyCurrent = auth.selectedFleet.currencies[0];
    const maximumValue = _.get(
      defaultLimitTopupData,
      'maximumByCurrencies[0].value'
    );
    const minimumValue = _.get(
      defaultLimitTopupData,
      'minimumByCurrencies[0].value'
    );
    return (
      <FormGroup
        className={
          !isSubmited
            ? null
            : parseFloat(maximumValue) <= parseFloat(minimumValue)
            ? 'error'
            : null
        }
      >
        {Object.keys(defaultLimitTopupData || {}).map((item, index) => {
          let newDefaultItem =
            defaultLimitTopupData[item] && defaultLimitTopupData[item][0];
          return (
            <>
              <Form.Label>
                <Translate
                  value={`generalSetting.limitTopupAmount.${item}`}
                  className="mr-sm"
                />
                <span style={{ marginLeft: '0px' }} className="require">
                  *
                </span>
              </Form.Label>
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-left ${
                    !hasPermission ? 'disabled' : ''
                  }`}
                >
                  <InputGroup.Prepend>
                    <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
                  </InputGroup.Prepend>{' '}
                  <FormControl
                    type="text"
                    className="form-custom no-marginBottom"
                    onChange={(e) => {
                      handleUpdateLimitTopup(item, e.target.value, keyApp);
                    }}
                    value={
                      newDefaultItem
                        ? newDefaultItem.value === 0
                          ? DefaultCurenciesInputField
                          : newDefaultItem.value
                        : DefaultCurenciesInputField
                    }
                    disabled={!hasPermission}
                  />
                </InputGroup>
              </FormGroup>
            </>
          );
        })}
        <Validator id={`minmax`}>
          <ValidCase
            valid={
              parseFloat(maximumValue) <= parseFloat(minimumValue)
                ? false
                : true
            }
            message={'Maximum amount must greater than minimum amount'}
            hide={!isSubmited}
          />
        </Validator>
      </FormGroup>
    );
  }
}
