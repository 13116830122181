import React, { Component } from 'react';
import _, { debounce } from 'lodash';

import './validator.scss';

export default class WarningFeeInput extends Component {
    constructor(props) {
        super(props);
        const { value = null } = props || {};
        this.state = {
            value,
            warningState: true
        }
        this.validateInputDebounce = debounce(this.validateInput, 250);
    }

    isNumberOverDifference = (numbers = [1, 1], percentage) => {
        const [baseNumb, editNumb] = numbers;
        if (Math.abs(parseFloat(editNumb) - parseFloat(baseNumb)) > (parseFloat(baseNumb) * percentage)) return true;
        return false;
    }

    validateInputDebounce = (value, typeOfCheck, timeDelay = 250) => {
        return debounce(this.validateInput(value, typeOfCheck), timeDelay);
    }

    compareNumberValue = (baseValue, value, typeOfCheck) => {
        switch (typeOfCheck) {
            case 'higherOfLower':
                let statusOfCheck = !this.isNumberOverDifference([baseValue, value], 0.2);
                /* console.log({ baseValue, value, statusOfCheck }); */
                return statusOfCheck;
            default: return true;
        }
    }

    compareArrayValue = (value, baseValue, typeOfCheck) => {
        let statusOfCheck = true;
        for (let i = 0; i < value.length; i++) {
            /* Lấy object của từng currency */
            const iValueObject = value[i];

            /* statusOfCheck = this.compareArrayValue(iValueObject, baseValue, typeOfCheck); */
            if (Array.isArray(iValueObject) && iValueObject.length) {
                const baseValueArray = baseValue[i];
                if (!(Array.isArray(baseValueArray) && baseValueArray.length)) continue;

                statusOfCheck = this.compareArrayValue(iValueObject, baseValueArray, typeOfCheck);

                if (!statusOfCheck) break;
            }
            else {
                /* Lấy key đầu tiên để lấy currency */
                const keyOfValue = Object.keys(iValueObject)[0];
                /* Nếu không có key đầu tiên thì không xét nữa */
                if (!keyOfValue) continue;

                const iValue = iValueObject[keyOfValue];
                /* Nếu giá trị của key là rỗng (không phải bằng không) thì không xét tiếp */
                if (!['string', 'number'].includes(typeof iValue) || iValue === '') continue;

                /* Tìm object currency theo currency hiện tại đang xét */
                const baseKeyValueObject = baseValue.find(vl => !(vl[keyOfValue] === null || vl[keyOfValue] === undefined || vl[keyOfValue] === '' || parseFloat(vl[keyOfValue]) === 0));
                /* Nếu không có thì không xét */
                if (!baseKeyValueObject) continue;

                const baseKeyValue = baseKeyValueObject[keyOfValue];

                statusOfCheck = this.compareNumberValue(baseKeyValue, iValue, typeOfCheck);

                /* Nếu đã có warning thì break để cảnh báo, không cần xét trường hợp tiếp theo */
                if (!statusOfCheck) break;
            }
        }

        return statusOfCheck;
    }

    validateInput = (value, typeOfCheck) => {
        let statusOfCheck = true;
        const { value: baseValue } = this.state;

        /* const exampleValue = [
            {
                USD: 20
            },
            {
                VND: 30000
            },
            ...
        ] */
        if (!(value === null || value === undefined || baseValue === null || baseValue === undefined)) {
            /* Trường hợp value truyền vào là array (multi currency) */
            if (Array.isArray(value) && Array.isArray(baseValue) && value.length && baseValue.length) {
                /* Chỉ xét trường hợp nếu value mới và value gốc đều có ít nhất 1 currency. Nếu không thì không cảnh báo */

                statusOfCheck = this.compareArrayValue(value, baseValue, typeOfCheck);
            }
            /* Trường hợp giá trị truyền vào là 1 string hoặc 1 số (fleet có 1 currency) */
            else if (['string', 'number'].includes(typeof value) && !(value === '' || baseValue === '' || parseFloat(baseValue) === 0)) {
                statusOfCheck = this.compareNumberValue(baseValue, value, typeOfCheck);
            }
        }

        if (statusOfCheck === this.state.warningState) return;

        return this.setState({ warningState: statusOfCheck });
    }

    shouldComponentUpdate(nextProps) {
        const {
            value: nextValue,
            typeOfCheck = '',
            timeDelay,
            disabled,
            isReset: isNextReset = false
        } = nextProps;
        const { value: currentValue, isReset: isCurrentReset = false } = this.props || {};
        if (disabled) return true;
        if (
            this.state.value === undefined
            || this.state.value === null
            || this.state.value === ""
            || (Array.isArray(this.state.value) && !this.state.value.length)
            || isNextReset !== isCurrentReset
        ) {
            const value = (
                nextValue === undefined
                || nextValue === null
                || nextValue === ''
                || (Array.isArray(nextValue) && !nextValue.length)
            ) ? null : nextValue;

            if (value === null || (Array.isArray(value) && !value.length)) return true;
            this.setState({
                value
            });
        }
        else this.validateInputDebounce(nextValue, typeOfCheck, timeDelay);

        return true;
    }

    /* componentDidMount() {
        const { value = null } = this.props || {};
        console.log({ didmount: value });
    }

    componentWillUnmount() {
        const { value = null } = this.props || {};
        console.log({ unmout: value });
    } */

    render() {
        const {
            message = '',
            className = '',
            disabled = false
        } = this.props || {};

        const { warningState = true } = this.state || {};
        if (disabled || warningState) return null;
        return (
            <p className={className}>
                {
                    message
                }
            </p>
        )
    }
}
