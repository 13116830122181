import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { I18n } from 'react-redux-i18n';

const AcceptOfferButton = (props) => {
  const { handleAcceptBooking, data } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(0);
  const buttonRef = useRef(null);
  const [backgroundWidth, setBackgroundWidth] = useState(0);

  const handleDragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragMove = (e) => {
    if (isDragging) {
      const button = buttonRef.current;
      const container = button.parentElement;
      const containerRect = container.getBoundingClientRect();
      const buttonWidth = button.offsetWidth;
      const containerWidth = containerRect.width;
      
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const mouseX = clientX - containerRect.left;
      const newPosition = Math.min(
        Math.max(mouseX - buttonWidth / 2, 0),
        containerWidth - buttonWidth - 16
      );

      setPosition(newPosition);
      setBackgroundWidth(newPosition + buttonWidth + 16);
    }
  };

  const handleDragEnd = async (e) => {
    const button = buttonRef.current;
    const container = button.parentElement;
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const buttonWidth = button.offsetWidth;
    
    const clientX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    const mouseX = clientX - containerRect.left;
    const newPosition = Math.min(
      Math.max(mouseX - buttonWidth / 2, 0),
      containerWidth - buttonWidth - 16
    );

    if (newPosition > (containerWidth * 3) / 5) {
      const rs = await handleAcceptBooking(data);
      if (rs) {
        setPosition(containerWidth);
        setBackgroundWidth(containerWidth);
      } else {
        setPosition(0);
        setBackgroundWidth(0);
      }
    } else {
      setPosition(0);
      setBackgroundWidth(0);
    }
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);
    } else {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    };
  }, [isDragging]);

  return (
    <>
      <div
        className={`${styles.background} ${isDragging ? styles.dragging : ''}`}
        style={{ width: `${backgroundWidth}px` }}
      ></div>

      <div
        onClick={async (e) => {
          e.stopPropagation();
          // await handleAcceptBooking(data);
        }}
        className={styles.layout}
      >
        <div
          ref={buttonRef}
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          className={styles['icon-accept']}
          style={{ transform: `translateX(${position}px)` }}
        >
          <div className={styles['caret-icon']}></div>
        </div>
        <div className={styles['text-accept']}>
          <span>{I18n.t('supplier.Accept')}</span>
        </div>
      </div>
    </>
  );
};

export default AcceptOfferButton;
