import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DynamicSurcharge from '../components/DynamicSurcharge';
import * as actions from '../../../../actions/dynamicSurchargeAtions';

const mapStateFromProps = state => ({
  selectedFleet: state.auth ? state.auth.selectedFleet : null,
  commonData: state.commonData,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateFromProps,
  mapDispatchToProps
)(DynamicSurcharge);
