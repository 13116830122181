import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { getCustomer, countCustomers } from '../../../actions/customerAction';

function TotalCustomer({ selectedFleet, count, fetch, incurredCustomer }) {
  const [total, setTotal] = useState(null);

  useEffect(() => {
    if (_.get(selectedFleet, 'searchModule.Customer', false)) {
      fetch({ query: { fleetId: selectedFleet.fleetId }, limit: 5 }).then(
        data => {
          if (!data.error) {
            setTotal(_.get(data, 'res.summary.total', 0));
          }
        }
      );
    } else {
      count({ query: { fleetId: selectedFleet.fleetId } }).then(data => {
        if (!data.error) {
          setTotal(data.res.total);
        }
      });
    }
  }, []);
  return (
    <div className="totalCustomer">
      <span>
        <Translate value="customer.totalCustomer" />
        :&nbsp;
      </span>
      {total === null
        ? '-----'
        : new Intl.NumberFormat('en-us').format(total + incurredCustomer)}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: options => dispatch(getCustomer(options)),
    count: options => dispatch(countCustomers(options))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TotalCustomer);
