import { I18n } from 'react-redux-i18n';
import React from 'react';
import ReportUtils from '../base/utils';
import { Localize } from 'react-redux-i18n';

export const fields = [
  {
    key: 'driverName',
    label: 'report.result.referralHistory.driverName',
    summary: 'Driver name',
    borderRight: true,
    rowSpan: 2,
    fixed: true
  },
  {
    key: 'phoneNumber',
    label: 'report.result.referralHistory.phoneNumber',
  },
  {
    key: 'companyName',
    label: 'report.result.referralHistory.companyName',
  },
  {
    key: 'referralCode',
    label: 'report.result.referralHistory.referralCode',
  },
  {
    key: 'referredPax',
    label: 'report.result.referralHistory.referredPax',
    toolTip: 'report.result.referralHistory.referredPaxTooltip',
    summary: 'referredPax'
  },
  {
    key: 'activePax',
    label: 'report.result.referralHistory.activePax',
    toolTip: 'report.result.referralHistory.activePaxTooltip',
    summary: 'activePax'
  },
  {
    key: 'commission',
    label: 'report.result.referralHistory.commission',
    isShowDriverReferralField: ReportUtils.isShowDriverReferralField,
    sub: (list,obj) => {
      const comissionCurrencies = [];
      list.forEach(item => {
        (item.commission || []).forEach(cms => {
          if (comissionCurrencies.indexOf(cms.currencyISO) < 0) comissionCurrencies.push(cms.currencyISO);
        });
        if(!item.commission.length){
          (obj || []).forEach(cms => {
            // comissionCurrencies.push(cms.iso);
            if (comissionCurrencies.indexOf(cms.iso) < 0) comissionCurrencies.push(cms.iso);
          })
        };
      });
      return comissionCurrencies.map(currency => (
        {
          label: currency,
          mutate: (value, doc) => {
            const commissionOfCurrency = (doc.commission || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(commissionOfCurrency ? commissionOfCurrency.value : 0, 2, 2)
          },
          summary: 'commission',
          summaryMutate: (value) => {
            const commissionOfCurrency = (value || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(commissionOfCurrency ? commissionOfCurrency.value : 0, 2, 2)
          }
        }
      ))
    }
  },
  {
    key: 'expectedReferralEarning',
    label: 'report.result.referralHistory.expectedReferralEarning',
    isShowDriverReferralField: ReportUtils.isShowDriverReferralField,
    sub: (list,obj) => {
      const expectedReferralEarningCurrencies = [];
      list.forEach(item => {
        (item.expectedReferralEarning || []).forEach(cms => {
          if (expectedReferralEarningCurrencies.indexOf(cms.currencyISO) < 0) expectedReferralEarningCurrencies.push(cms.currencyISO);
        });
        if(!item.expectedReferralEarning.length){
          (obj || []).forEach(cms => {
            // expectedReferralEarningCurrencies.push(cms.iso);
            if (expectedReferralEarningCurrencies.indexOf(cms.iso) < 0) expectedReferralEarningCurrencies.push(cms.iso);
          })
        };
      });
      return expectedReferralEarningCurrencies.map(currency => (
        {
          label: currency,
          mutate: (value, doc) => {
            const expectedReferralEarningOfCurrency = (doc.expectedReferralEarning || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(expectedReferralEarningOfCurrency ? expectedReferralEarningOfCurrency.value : 0, 2, 2)
          },
          summary: 'expectedReferralEarning',
          summaryMutate: (value) => {
            const expectedReferralEarningOfCurrency = (value || []).find(c => c.currencyISO === currency);
            return ReportUtils.numberFormat(expectedReferralEarningOfCurrency ? expectedReferralEarningOfCurrency.value : 0, 2, 2)
          }
        }
      ))
    }
  },
  {
    label: 'report.result.referralHistory.detail',
    key: 'driverId',
    link: {
      label: 'report.result.referralHistory.showDetail',
      newTab: true,
      href: '/reports/Driver_Referral_history/Driver_refers_Pax/pax',
      name: 'goToListPassenger',
      params: ['driverId', 'driverName'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'List all passengers which referred by this driver'
    }
  }
];

/* export const fareFields = ['subtotal', 'referralEarning']; */
