import {
    tencentApi
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";
export function TencentMapWebserviceCall(url) {
    const config = {
        method: "post",
        body: JSON.stringify({ url: url })
    };
    return callApi(
        tencentApi,
        config
    );
}