import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { getCorporate } from '../../../../actions/corporateAction';

const Corporate = (props) => {
  const { corporateVal, handleChangeCorporate, thirdParty, isDisable } = props;
  const [corporateList, setCorporateList] = useState([]);

  const getListCorporates = async () => {
    try {
      const param = {
        limit: 1000,
        page: 0,
        query: {
          fleetId: props.auth.selectedFleet.fleetId,
          isActive: true,
        },
      };
      const response = await props.getCorporate(param);
      if (response.ok) {
        let selectLists =
          response?.res?.list?.map((item) => {
            return {
              _id: item?._id,
              name: item?.companyInfo?.name || '',
            };
          }) || [];
        setCorporateList(selectLists);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getListCorporates();
  }, []);

  return (
    <FormGroup>
      <Form.Label>
        <Translate value={`Sidebar.Settings.Corporate`} />
      </Form.Label>

      <FormControl
        as="select"
        value={corporateVal}
        className="form-custom"
        onChange={handleChangeCorporate}
        disabled={isDisable}
      >
        <option value={''}>
          {I18n.t(`emailConfig.none`) +
            ' ' +
            I18n.t('Sidebar.Settings.Selected')}
        </option>
        {corporateList.map((data) => (
          <option key={data._id} value={data._id}>
            {data.name}
          </option>
        ))}
      </FormControl>
    </FormGroup>
  );
};

Corporate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCorporate: (options) => dispatch(getCorporate(options)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Corporate);
