import React from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';

function NoteForm (props) {

  const handleChange = (e) => {
    props.onChange('notes', e.target.value)
  }
  return (
    <FormGroup>
      <Form.Label>
        <Translate value="cue.Note" />
      </Form.Label>
      <FormControl
        className="form-custom"
        as="textarea"
        placeholder={I18n.t("newbooking.Note")}
        rows={5}
        maxLength={1000}
        disabled={props.isViewOnly}
        onChange={handleChange}
        value={props.value}
      />
    </FormGroup>
  )
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NoteForm);