import React, { useState } from 'react'
import Copy_icon from '../../assets/images/icons/copy.svg';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import tracklink from './tracklink.scss';
import { Translate, I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';

const statusSupportTrackLink = ['booked', 'engaged', 'droppedOff', 'arrived', 'delivering', 'collecting', 'otwMerchant']
// const statusSupportTrackLinkReservation = ['confirmed', 'booked', 'engaged', 'droppedOff', 'arrived', 'delivering', 'collecting', 'otwMerchant']


const TrackLink = ({status = '', trackUrl = '', tokenBooking = '', pickupTime = 'Now', delivery = false}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  
  let trackUrlFull = `${trackUrl}/${tokenBooking}`
  // Neu setting trackUrl co '/' roi thi khoi them
  if(trackUrl && trackUrl.slice(-1) === '/') {
    trackUrlFull = `${trackUrl}${tokenBooking}`
  }
  
  const handleCopyClick = (trackUrlFull) => {
    copy(trackUrlFull)
    setShowTooltip(true)
  }

  const onMouseLeave = () => {
    if (showTooltip) setShowTooltip(false)
  }

  return (
    statusSupportTrackLink.indexOf(status) >= 0
    || (status == 'confirmed' && pickupTime != 'Now' && !delivery) ? 
    (
      <div>
        <a target="_blank" href={trackUrlFull}>
          <Translate value="bookingdetail.ViewTrackLink" />
        </a>
        <div className="copyTracklink">
          <img 
            src={Copy_icon} 
            style={{marginLeft: '5px', cursor: 'pointer'}}
            onClick={() =>  handleCopyClick(trackUrlFull)}
            onMouseLeave={onMouseLeave}
          />
          <span className={showTooltip ? 'show' : ''}>
            Copied!
          </span>
        </div>
      </div>
    ) : (
      null
    )
  )
}

export default TrackLink