import React, { Fragment } from 'react'
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import DisplayPhoto from './DisplayPhoto'
import DisplayItemize from './DisplayItemize'
import CurrencyFormatter from 'currency-formatter';

class PackageInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listMenuData: []
    }
  }

  componentDidMount() {
    const { bookInfo, bookInfo: {deliveryInfo}, bookInfo: {currencyISO} } = this.props
    let infoPackage = []
    if(bookInfo.jobType == "shopForYou") {
      infoPackage = deliveryInfo && deliveryInfo.merchants
    } else {
      infoPackage = deliveryInfo && deliveryInfo.recipients
    }
    infoPackage.map((recipient, id) => {
      let selectedItems = recipient.menuData && recipient.menuData.selectedItems || []
      if(selectedItems.length > 0) {
        this.props.cueActions
          .getMenuItemsOutputData(
            {
              currency: currencyISO,
              items: selectedItems
            }
          )
          .then(result => {
            if (result.res && result.res.code == 200) {
              this.setState((state) => ({
                listMenuData: [...state.listMenuData, result.res.res.data]
              }));
            }
          });
      }
    })
  }

  render() {
    const { listMenuData } = this.state
    return(
      <>
        {
          listMenuData.length > 0 
          && listMenuData.map((menuDatas, id) => {
            const indexPackage = listMenuData.length > 1 ? ++id : ''
            return (
              <div className="bookingDelivery" key={id}>
                <FormGroupTitle>
                  <Translate value={`${I18n.t('cue.Package_information')} ${indexPackage}`} />
                </FormGroupTitle>
        
                {menuDatas.map((selectedItem, id) => {
                  return (
                    <Fragment key={id}>
                      {selectedItem.componentType === "display-photo" ?
                        <DisplayPhoto
                          itemData={selectedItem} 
                          selectedFleet={this.props.selectedFleet}
                        />
                      : selectedItem.componentType === "display-itemize" && selectedItem.fieldType !== "String" ?
                        <DisplayItemize 
                          itemData={selectedItem}
                        />
                      : selectedItem.componentType === "display-item" && 
                        selectedItem.fieldType === "PriceCurrency" && 
                        selectedItem.value ?
                        <div className="orderItemPackage">
                          <div className="rowInfoPackage-item">
                            <span className="RIPLeft">
                              <CcCheckbox
                                checked={true}
                                text={selectedItem.title}
                                disabled={true}
                              />
                            </span>
                            <span className="RIPRight">
                              {CurrencyFormatter.format(selectedItem.value.value, {
                                code: selectedItem.value.iso,
                              })}
                            </span>
                          </div>
                        </div>
                      : selectedItem.componentType === "display-item" && selectedItem.fieldType === "String"?
                        <div className="orderItemPackage">
                          <div className="rowInfoPackage">
                            <span className="RIPLeft">{selectedItem.title}</span>
                            <span className="RIPRight">{selectedItem.value}</span>
                          </div>
                        </div>
                      : selectedItem.componentType == "display-itemize" && selectedItem.fieldType === "String" ?
                        <div className="orderItemPackage">
                          <div className="rowInfoPackage">
                            <span className="RIPLeft">{selectedItem.title}</span>
                            <span className="RIPRight">{selectedItem.items[0] && selectedItem.items[0].title}</span>
                          </div>
                        </div>
                      : null}
                    </Fragment>
                  )
                })}
              </div>
            )
          })
        }
      </>
    )
  }
}

PackageInformation.defaultProps = {
  bookInfo: {}
}

export default PackageInformation