import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const Plivo = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.authId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.authId || ''}
          onChange={(e) => {
            props.handleInputChange('authId', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.authToken" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.authToken || ''}
          onChange={(e) => {
            props.handleInputChange('authToken', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.phloId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.phloId || ''}
          onChange={(e) => {
            props.handleInputChange('phloId', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.senderId" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.senderId || ''}
          onChange={(e) => {
            props.handleInputChange('senderId', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.dlcNumber" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.dlcNumber || ''}
          onChange={(e) => {
            props.handleInputChange('dlcNumber', e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default Plivo;
