import React, { useState } from 'react'
import {
  Modal,
  Button,
  FormGroup
} from "react-bootstrap";
import addTraveler from '../../../assets/images/icons/addTraveler.svg'
import backIcon from '../../../assets/images/icons/backIcon.svg'
import { Translate, I18n } from "react-redux-i18n";
import { POINT_TYPE_TEXT } from '../../../constants/commondata'
import './RoutePreview.scss'

const SelectPointForTraveler = ({
  isShowSelectPoint,
  setShowSelectPoint,
  puPoints,
  doPoints,
  setPointSelected,
  setShowAddTraveler
}) => {

  const [recentPoint, setRecentPoint] = useState('pickup')
  const [pointsSelect, setPointsSelect] = useState({pu: '', do: ''})

  const closeModal = () => {
    setRecentPoint('pickup')
    setShowSelectPoint(false)
  }

  const handleSelectPickup = (id) => {
    setRecentPoint('dropoff')
    setPointsSelect({
      ...pointsSelect,
      pu: id
    })
  }

  const handleSelectDropOff = (id) => {
    let newPointSelect = {
      ...pointsSelect,
      do: id
    }
    setRecentPoint('pickup')
    setPointSelected(newPointSelect)
    setPointsSelect(newPointSelect)
    setShowSelectPoint(false)
  }

  const backClick = () => {
    if(recentPoint == 'pickup') {
      setShowAddTraveler(true)
      closeModal()
      return
    }
    if(recentPoint == 'dropoff') {
      setRecentPoint('pickup')
    }
  }
  
  return (
    <Modal
        show={isShowSelectPoint}
        backdrop="static"
        key="modal-form"
        dialogClassName="confirm-dialog routePreview selecPointModal"
        className="confirm pointModal"
        onHide={closeModal}
      >
        <Modal.Header>
          <div className="headerPre selectPoint">
            <span className="backBtn">
              <img 
                src={backIcon} 
                onClick={backClick}
              />
            </span>
            <span className="titlePre">
              {
                recentPoint === 'pickup'
                ? 'Select Pickup Point'
                : 'Select Drop Off Point'
              }
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="selectPoint-body">
            {
              recentPoint === 'pickup' 
              && puPoints.map((point) => (
                <div
                  key={point.id} 
                  onClick={() => handleSelectPickup(point.id)}
                >
                  <span>{point.order}</span>
                  <span>{point.address && point.address.address}</span>
                </div>
              ))
            }
            {
              recentPoint === 'dropoff' 
              && doPoints.map((point) => (
                <div 
                  key={point.id} 
                  onClick={() => handleSelectDropOff(point.id)}
                  className='dropoff'
                >
                  <span>{point.order}</span>
                  <span>{point.address && point.address.address}</span>
                </div>
              ))
            }
          </div>
        </Modal.Body>
      </Modal>
  )
}

SelectPointForTraveler.defaultProps = {
  puPoints: [],
  doPoints: [],
  recentPlaces: {},
  fleetId: '',
  limitPoint: {},
  isBookDetails: false,
  jobType: '',
  setNewPuPoint: () => {},
  setNewDoPoint: () => {},
  isPickupEditable: () => {},
  isDestinationEditable: () => {},
  checkPointInZoneSupported: () => {}
}

export default SelectPointForTraveler