import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'driverName',
    label: 'report.result.documentExpiry.driverName',
    link: {
      newTab: true,
      isNotShow: doc => {
        return !doc.driverId;
      },
      href: {
        templateStr: '/driver/view/${driverId}/info',
        params: {
          driverId: 'driverId'
        }
      },
      notMergeParams: true,
      name: 'gotoDriverDetails',
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Link to show driver details'
    }
  },
  {
    key: 'driverPhone',
    label: 'report.result.documentExpiry.driverPhone'
  },
  {
    key: 'company',
    label: 'report.result.documentExpiry.company'
  },
  {
    key: 'documentName',
    label: 'report.result.documentExpiry.documentName'
  },
  {
    key: 'expiredDate',
    label: 'report.result.documentExpiry.expiredDate',
    mutate: (value, doc, params) => (value
      ? ReportUtils.formatTime(value, doc, {
        timezone: _.get(params, 'fleet.timezone'),
        formatStr: 'MM/DD/YYYY'
      })
      : 'N/A')
  },
  {
    key: 'status',
    label: 'report.result.documentExpiry.status',
    mutate: value => [
      I18n.t('report.result.documentExpiry.statusStr.NA'),
      I18n.t('report.result.documentExpiry.statusStr.expired'),
      I18n.t('report.result.documentExpiry.statusStr.active')
    ][value || 0]
  }
];

export const fareFields = [];
