import React, { Component } from 'react';
import _ from 'lodash';
import { FormControl } from 'react-bootstrap';
import InputCurrency from './InputCurrency';

export default class AirportFeeComlumn extends Component {
  state = {};

  onRemoveAirport = () => {
    const { airport, index, onRemoveAirport } = this.props;
    onRemoveAirport(airport, index);
  };

  handleChangeFromAirport = value => {
    const { airport, onChange } = this.props;
    airport.fromAirportByCurrencies = value;
    if (onChange) {
      onChange(airport);
    }
  };

  handleChangeToAirport = value => {
    const { airport, onChange } = this.props;
    airport.toAirportByCurrencies = value;
    if (onChange) {
      onChange(airport);
    }
  };

  handleCurrencyChange = e => {
    const {
      auth: { selectedFleet }
    } = this.props;
    const currencyIso = e.target.value;
    const currencySelected = selectedFleet.currencies.find(o => o.iso === currencyIso);
    this.setState({
      currency: currencySelected || selectedFleet.currencies[0],
      currencyIso
    });
  };

  render() {
    const {
      airport,
      auth: { selectedFleet },
      defaultAirportFee,
      editable
    } = this.props;
    const { currency } = this.state;
    const currencyCurrent = currency || selectedFleet.currencies[0];
    return (
      <tr>
        <td className="text-ellipsis" style={{ maxWidth: 0 }}>
          <span title={_.get(airport, 'airportZoneId.zoneName')}>
            {_.get(airport, 'airportZoneId.zoneName')}
          </span>
        </td>
        {selectedFleet.multiCurrencies ? (
          <td>
            <div className="qup-input-group">
              <FormControl
                as="select"
                className=" form-custom"
                value={this.state.currencyIso}
                onChange={this.handleCurrencyChange}
                disabled={editable}
              >
                {selectedFleet.currencies.map(c => (
                  <option key={c.iso} value={c.iso}>
                    {c.iso}
                  </option>
                ))}
              </FormControl>
            </div>
          </td>
        ) : null}
        <td>
          <InputCurrency
            currency={currencyCurrent}
            currentFieldValue={airport.fromAirportByCurrencies}
            onChange={this.handleChangeFromAirport}
            disabled={!defaultAirportFee.fromAirportActive || editable}
          />
        </td>
        <td>
          <InputCurrency
            currency={currencyCurrent}
            currentFieldValue={airport.toAirportByCurrencies}
            onChange={this.handleChangeToAirport}
            disabled={!defaultAirportFee.toAirportActive || editable}
          />
        </td>
        <td>
          {editable ? '' : <i className="fa fa-trash fa-2x" onClick={this.onRemoveAirport} />}
        </td>
      </tr>
    );
  }
}
