export const TableVehicle = [
  {
    key: 'palateNumber',
    label: 'carSetting.Plate_Number',
    fixed: true,
    width: 230,
    textEllipsis: true,
  },

  {
    key: 'vehicleModel.vehicleMake',
    label: 'carSetting.Make',
  },
  {
    key: 'vehicleModel.name',
    label: 'carSetting.Car_Model',
  },
  {
    key: 'color',
    label: 'carSetting.Color',
  },
  {
    key: 'year',
    label: 'carSetting.Year',
  },
  {
    key: 'type.name',
    label: 'carSetting.Car_Type',
  },
  {
    key: 'agent.name',
    label: 'carSetting.Agent',
    textEllipsis: true
  },
  {
    key: 'supplier.name',
    label: 'carSetting.Supplier',
    textEllipsis: true
  },
  {
    key: 'hardwareMeter',
    label: 'carSetting.Hardware_Meter',
  },
  {
    key: 'createdDate',
    label: 'carSetting.Created_Date',
    width: 200,
  },
  {
    key: 'regularStatus',
    label: 'carSetting.Status',
    width: 100,
  },
  {
    key: 'actions',
    label: 'carSetting.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
