import React from "react";
import {
    FormGroup,
    Button,
    Image,
    FormControl,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import _ from "lodash";

import { I18n, Translate } from "react-redux-i18n";
import CCDropDown from "../../../components/dropdown/ccDropDown";
import CcCheckbox from "../../../components/ccCheckbox/CcCheckbox";
import IntercityDropDown from "../../../components/dropdown/IntercityDropDown";
import {
    TripStatus,
    SeatStatus,
    TripTypes
} from "./FilterItems";

import "./style.scss";
import ReactDatetime from "react-datetime";
import moment from "moment";
import OverlayCC from "../../../components/OverlayCC";
import { BsSearch } from "react-icons/bs";

class TripFilter extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            showFromDateDpk: false,
            showToDateDpk: false
        };
    };

    componentDidUpdate() {

    }

    handleSearchChange = e => {
        let value = e.target ? e.target.value : this.state.searchText;
        this.setState({ searchText: value });
    };

    onSelectFromDate = e => {
        this.setState({ showFromDateDpk: false }, () =>
            this.props.updateDatefrom(e)
        );
    };

    onSelectToDate = e => {
        this.setState({ showToDateDpk: false }, () =>
            this.props.updateDateTo(e)
        );
    };

    showFromDateDpk = (isShow) => {
        this.setState({ showFromDateDpk: isShow });
      }
    
      showToDateDpk = (isShow) => {
        this.setState({ showToDateDpk: isShow });
      }

    render() {

        let searchByOptions = this.props.searchByOptions;
        const { showFromDateDpk, showToDateDpk } = this.state;
        const filterData = this.props.fillter;
        let selectedStatus = [];
        let selectedCapacityStatus = [];
        let selectedTripTypes = [];

        _.forEach(TripStatus, item => {
            if (filterData.status[item.value]) {
                selectedStatus.push(item.value);
            }
        });

        _.forEach(SeatStatus, item => {
            if (filterData.capacityStatus[item.value]) {
                selectedCapacityStatus.push(item.value);
            }
        });

        _.forEach(TripTypes, item => {
            if (filterData.tripTypes[item.value]) {
                selectedTripTypes.push(item.value);
            }
        });


        const fromDatePickerValue = filterData.dateFrom
            ? moment(filterData.dateFrom).format("MM/DD/YYYY")
            : "";

        const toDatePickerValue = filterData.dateTo
            ? moment(filterData.dateTo).format("MM/DD/YYYY")
            : "";

        const selectedIntercityRoutes = _.map(
            filterData.intercityRoutes,
            item => item._id
        );

        const selectedIntercityRouteNumber = _.map(
            filterData.intercityRoutes,
            item => item.routeNumber
        );

        const selectedCarTypes = _.map(
            filterData.carType,
            item => item.vehicleType
        );

        const intercityLabel = item => {
            return (
                <div>
                    <OverlayTrigger
                        overlay={<Tooltip id="intercity-label-tooltip">{`${item.firstLocation.name} - ${item.secondLocation.name}`}</Tooltip>}
                        placement="top"
                        delayShow={100}
                        delayHide={150}
                    >
                        <div
                            style={{
                                'textOverflow': 'ellipsis',
                                'overflow': 'hidden',
                                'whiteSpace': 'nowrap'
                            }}
                        >{`${item.firstLocation.name} - ${item.secondLocation.name}`}</div>
                    </OverlayTrigger>
                    {_.map(item.carType, cartype => {
                        return (
                            <div className="text-more-info" key={cartype._id}>
                                <span>{cartype.vehicleType}</span>
                                <span> - </span>
                                <span>{item.routeSetting.pricePerSeat[0].value.toLocaleString('us-US', { style: 'currency', currency: item.routeSetting.pricePerSeat[0].currencyISO })}</span>
                            </div>
                        )
                    })}

                </div>
            );
        };

        return (
            <div className="cue-filter-container">
                <div className="header-button-group">
                    <div className="group-left">
                        <FormGroup className="search-format mb0 search-by">
                            <div className="input-container">
                                <input
                                    type="text"
                                    className="form-control search-form"
                                    value={this.state.searchText}
                                    onChange={this.handleSearchChange}
                                    onKeyPress={this.props.txtSearchPressHandle}
                                    placeholder={I18n.t("cue.Search_here")}
                                    disabled={this.props.isLoading}
                                />
                                <BsSearch className="search-icon" />
                            </div>
                            <CCDropDown
                                id="trip-search-by-dropdown"
                                title=""
                                items={searchByOptions}
                                selectedItems={[this.props.searchBy]}
                                valueKey="value"
                                labelKey="label"
                                onSelect={this.props.handleSearchByChange}
                                className="search-by-dropdown"
                                pullRight
                                disabled={this.props.isLoading}
                            />
                        </FormGroup>

                        <FormGroup className="cue-date-picker-group input">
                            <OverlayCC
                                childrenAction={
                                    <div
                                        className="form-control form-custom datepicker-input"
                                    >
                                        {fromDatePickerValue ? (
                                            <span>{fromDatePickerValue}</span>
                                        ) : (
                                                <Translate
                                                    value="report.query.fromDate"
                                                    className="placeholder"
                                                />
                                            )}
                                    </div>
                                }
                                childrenOverlay={
                                    <div className="datePicker-container">
                                        <ReactDatetime
                                            timeFormat={false}
                                            value={filterData.dateFrom}
                                            onChange={this.onSelectFromDate}
                                            dateFormat="MM/DD/YYYY"
                                            viewMode="days"
                                            input={false}
                                            open
                                        />
                                    </div>
                                }
                                setShowOverlay={this.showFromDateDpk}
                                showOverlay={this.state.showFromDateDpk}
                            />
                        </FormGroup>

                        <FormGroup className="cue-date-picker-group input">
                            <OverlayCC
                                childrenAction={
                                    <div
                                        className="form-control form-custom datepicker-input"
                                    >
                                        {toDatePickerValue ? (
                                            <span>{toDatePickerValue}</span>
                                        ) : (
                                                <Translate
                                                    value="report.query.toDate"
                                                    className="placeholder"
                                                />
                                            )}
                                    </div>
                                }
                                childrenOverlay={
                                    <div className="datePicker-container">
                                    <ReactDatetime
                                        timeFormat={false}
                                        value={filterData.dateTo}
                                        onChange={this.onSelectToDate}
                                        dateFormat="MM/DD/YYYY"
                                        viewMode="days"
                                        input={false}
                                        open
                                    />
                                </div>
                                }
                                setShowOverlay={this.showToDateDpk}
                                showOverlay={this.state.showToDateDpk}
                            />
                        </FormGroup>

                        {
                            this.props.auth.selectedFleet.map &&
                            this.props.auth.selectedFleet.map.isAutoRefresh && (
                                <FormGroup className="auto-refresh-form">
                                    <CcCheckbox
                                        checked={this.props.commonData.isAutoRefreshCueAndMap}
                                        className="table-selectbox all-checkbox"
                                        // onChange={e => {
                                        //     this.props.commonActions.autoRefreshCueAndMapChange(
                                        //         e.target.checked
                                        //     );
                                        // }}
                                        onChange={e => {
                                            this.props.updateAutoRefresh(e.target.checked)
                                        }}
                                        text={I18n.t("cue.auto_refrest_cue").format(
                                            this.props.auth.selectedFleet.map.value
                                        )}
                                    />
                                    <i
                                        className="fa fa-refresh auto-refresh-icon"
                                        onClick={() => {
                                            this.props.tripDataRequest();
                                        }}
                                    />
                                </FormGroup>
                            )}
                    </div>

                    <div className="group-right">
                        <Button
                            className="btn-header text-add-header"
                            onClick={this.props.handleToggleFilter}
                        >
                            <Translate
                                value={
                                    this.props.showFilter
                                        ? "cue.collapseFilter"
                                        : "cue.expandFilter"
                                }
                                className="collapse-filter-title"
                            />
                        </Button>
                        {/* <CCDropDown
                            id="filter-column-dropdown"
                            customTitle={<div className="column-filter-icon" />}
                            items={filterColumnItems}
                            valueKey="key"
                            labelKey="label"
                            onSelect={this.props.handleSelectColumn}
                            selectedItems={this.props.selectedColumns}
                            multiSelect={true}
                            pullRight={true}
                            className="column-filter"
                            enableAllItemSelect={true}
                        /> */}
                    </div>

                </div>

                <div
                    className={"filter-wrapper" + (this.props.showFilter ? " show" : "")}
                >
                    <IntercityDropDown
                        id="intercity-routes-dropdown"
                        items={this.props.commonData.intercityRoutes}
                        title={I18n.t("cue.Intercity_Route")}
                        selectedItems={selectedIntercityRoutes}
                        selectedRouteNumber={selectedIntercityRouteNumber}
                        valueKey="_id"
                        labelKey="routeName"
                        routeNumber="routeNumber"
                        onSelect={this.props.updateFillterIntercityRoute}
                        enableClearAll={true}
                        searchable={true}
                        intercityRoutesMode={true}
                        customLabel={intercityLabel}
                    />
                    <CCDropDown
                        id="trip-status-dropdown"
                        items={TripStatus}
                        title={I18n.t("trip.Status")}
                        selectedItems={selectedStatus}
                        valueKey="value"
                        labelKey="label"
                        onSelect={this.props.statusItemClicked}
                        multiSelect={true}
                        returnOnSelect={true}
                        onlyShowAllText={true}
                    />
                    <CCDropDown
                        id="trip-capacity-status"
                        items={SeatStatus}
                        title={I18n.t("trip.capacityStatus")}
                        selectedItems={selectedCapacityStatus}
                        valueKey="value"
                        labelKey="label"
                        onSelect={this.props.capacityStatusItemClicked}
                        multiSelect={true}
                        returnOnSelect={true}
                        onlyShowAllText={true}
                    />
                    <CCDropDown
                        id="trip-type"
                        items={TripTypes}
                        title={I18n.t("trip.Trip_Types")}
                        selectedItems={selectedTripTypes}
                        valueKey="value"
                        labelKey="label"
                        onSelect={this.props.handleTripTypesItemClicked}
                        multiSelect={true}
                        returnOnSelect={true}
                        onlyShowAllText={true}
                    />
                    {/* <CCDropDown
                        id="operator-dropdown"
                        items={this.props.commonData.operator}
                        title={I18n.t("cue.Operator")}
                        selectedItems={filterData.operator ? [filterData.operator] : []}
                        valueKey="userId"
                        labelKey="fullName"
                        onSelect={this.props.updateFillterOperactor}
                        enableClearAll={true}
                        searchable={true}
                    /> */}
                    {/* <CCDropDown
                        id="car-type-dropdown"
                        items={this.props.commonData.carTypeIntercity}
                        title={I18n.t("trip.Driver_vehicle")}
                        selectedItems={selectedCarTypes}
                        valueKey="vehicleType"
                        labelKey="vehicleType"
                        onSelect={this.props.updateFillterCarTypes}
                        multiSelect={true}
                        enableClearAll={true}
                        searchable={true}
                    /> */}
                </div>
            </div>
        )
    }




}

export default TripFilter