import * as _ from 'lodash'
import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Messages as ValidationMessages } from './validationMessages.js'
//import { I18n } from 'react-redux-i18n'

export default class ValidationPopUp extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { code, visible, onClose, data } = this.props
    const { title, message } = ValidationMessages(code, data) 
    return (
      <Modal onHide={onClose} backdrop="static" show={visible} className="modal-import">
        <Modal.Header className="modal-header-import">
          <Modal.Title className="qup-red">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-import">
          <p>{_.template(message)(data)}</p>
        </Modal.Body>
        <Modal.Footer className="modal-footer-import">
          <button className="btn-cancel-validation-message" onClick={onClose}>
            OK
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}
