import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.thirdPartyBooking.date',
    mutate: ReportUtils.formatTime,
    summary: 'totalBookings',
  },
  {
    key: 'bookingId',
    label: 'report.result.thirdPartyBooking.bookingId'
  },
  {
    key: 'externalId',
    label: 'report.result.thirdPartyBooking.externalId'
  },
  {
    key: 'psgPhone',
    label: 'report.result.thirdPartyBooking.psgPhone'
  },
  {
    key: 'psgName',
    label: 'report.result.thirdPartyBooking.psgName'
  },
  {
    key: 'pickupLocation',
    label: 'report.result.thirdPartyBooking.pickupLocation'
  },
  {
    key: 'pickupLocationLatLong',
    label: 'report.result.thirdPartyBooking.pickupLocationLatLong'
  },
  {
    key: 'dropoffLocation',
    label: 'report.result.thirdPartyBooking.dropoffLocation'
  },
  {
    key: 'dropoffLocationLatLong',
    label: 'report.result.thirdPartyBooking.dropoffLocationLatLong'
  },
  {
    key: 'pickupTime',
    label: 'report.result.thirdPartyBooking.pickupTime',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'numOfPassenger',
    label: 'report.result.thirdPartyBooking.numOfPassenger'
  },
  {
    key: 'action',
    label: 'report.result.thirdPartyBooking.action'
  },
];
