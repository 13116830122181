import React from 'react';
import BaseReport from '../base';
import { connect } from 'react-redux';
import * as fieldMappings from './fields';
import * as commonData from '../../../constants/commondata';
import { reportCompanySettlementDetailsApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';

class ReportCompanySettlement extends React.PureComponent {
  render () {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Company settlement',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
        typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
          ? false
          : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    const listPaymentStatus = [
      'all', 'full', 'partial', 'pending'
    ];
    const paymentStatus = commonData.bookingDetailsPaymentStatus.filter(i => listPaymentStatus.includes(i.value));
    return (
      <div className="content">
        <BaseReport
          tittle="Company settlement"
          fieldMappings={fieldMappings}
          apiurl={reportCompanySettlementDetailsApi}
          paymentStatus={{
            title: 'report.result.companySettlementDetails.originalPaymentStatus',
            options: paymentStatus
          }}
          dateRange
          company
          currency
          noexport={!checkExport}
        />
      </div>
    );
  }
}

function mapStateToProps (state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReportCompanySettlement);
