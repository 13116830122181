import styled, { css } from 'styled-components';

export const Title = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
  display: block;
`;

export const Description = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  opacity: 0.6;
  margin: 0;
`;

export const SuggestionItem = styled.li`
  padding: 10px 10px 10px 14px;
  box-shadow: 0px 1px 0px #000;

  :hover {
    cursor: pointer;
    background-color: #294945;
  }

  ${(props) =>
    props.isSelecting &&
    css`
      background-color: #294945;
    `}
`;

export const SuggestionPopup = styled.div`
  position: fixed;
  border-radius: 5px;
  overflow: auto;
  width: 310px;
  box-sizing: border-box;
  color: #e6e6e6;
  padding: 10px 0;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
  height: 300px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;
