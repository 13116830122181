import React from 'react';
import CompleteWithPaymentByAffiliateBooking from './CompleteWithPaymentByAffiliateBooking';

export default class CompleteWithPayment extends React.PureComponent {
  render() {
    const { farmIn } = this.props;
    if (farmIn) {
      return <CompleteWithPaymentByAffiliateBooking {...this.props} />;
    }
    return null;
  }
}
