export const DynamicSurchargeColumns = [
  {
    key: "zoneName",
    label: "dynamicSurcharge.operationZone",
    textEllipsis: true
  },
  {
    key: "activeSurcharges",
    label: "dynamicSurcharge.activeSurcharge",
    width: 150
  }
]