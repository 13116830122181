import React, { useEffect, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import FormatItem from '../FormatItem';
import {
  BOOK_TYPE,
  COLOR_SERVICE_TYPE,
  JOB_TYPE,
} from '../../../../constants/commondata';

export const Hourly = ({ booking }) => {
  if (booking?.request?.packageRateId && booking?.request?.packageRateName) {
    return (
      <FormatItem
        text={I18n.t(`newbooking.hourly`)}
        bgColor={COLOR_SERVICE_TYPE.hourly}
      />
    );
  }
  return null;
};

export const GroupId = ({ booking, bookType }) => {
  const isDelivery = [BOOK_TYPE.delivery, BOOK_TYPE.batchDelivery].includes(
    bookType
  );
  if (booking?.groupId) {
    return (
      <FormatItem
        text={
          <Translate
            value={isDelivery ? 'cue.batchDeliveryGroup' : 'cue.sharedTransfer'}
            id={booking?.groupId}
          />
        }
        bgColor={
          isDelivery
            ? COLOR_SERVICE_TYPE.batchDelivery
            : COLOR_SERVICE_TYPE.shareTransfer
        }
      />
    );
  }
  return null;
};

export const RoundTrip = ({ booking }) => {
  if (booking?.request?.type === 4 || booking?.request?.typeRate === 2) {
    return (
      <FormatItem
        text={I18n.t(`newbooking.Round_trip`)}
        bgColor={COLOR_SERVICE_TYPE.roundTrip}
      />
    );
  }
  return null;
};

export const FoundersFund = ({ booking }) => {
  if (booking?.request?.promoCustomerType === 'referral') {
    return (
      <FormatItem
        text={I18n.t(`cue.foundersFund`)}
        bgColor={COLOR_SERVICE_TYPE.foundersFund}
      />
    );
  }
  return null;
};

export const ZeroCommission = ({ booking }) => {
  if (booking?.request?.estimate?.fare?.shortTrip) {
    return (
      <FormatItem
        text={I18n.t(`cue.zeroCommission`)}
        bgColor={COLOR_SERVICE_TYPE.zeroCommission}
      />
    );
  }
  return null;
};

export const RateType = ({ booking }) => {
  const rateType = booking?.request?.type;
  let text,
    bgColor = '';
  switch (rateType) {
    case 1:
      text = I18n.t(`generalSetting.From_airport`);
      bgColor = COLOR_SERVICE_TYPE.fromAirport;
      break;
    case 2:
      text = I18n.t(`generalSetting.To_airport`);
      bgColor = COLOR_SERVICE_TYPE.toAirport;
      break;
    case 0:
      text = I18n.t(`newbooking.PointToPoint`);
      bgColor = COLOR_SERVICE_TYPE.pointToPoint;
      break;
    default:
      break;
  }

  if (text && bgColor) {
    return <FormatItem text={text} bgColor={bgColor} />;
  }
  return null;
};

export const DeliveryType = ({ booking }) => {
  const jobType = booking?.jobType;
  let text,
    bgColor = '';
  switch (jobType) {
    case JOB_TYPE.food:
      text = I18n.t(`bookingdetail.food`);
      bgColor = COLOR_SERVICE_TYPE.food;
      break;
    case JOB_TYPE.mart:
      text = I18n.t(`bookingdetail.mart`);
      bgColor = COLOR_SERVICE_TYPE.mart;
      break;
    default:
      text = I18n.t(`bookingdetail.Delivery`);
      bgColor = COLOR_SERVICE_TYPE.delivery;
      break;
  }
  if (text && bgColor) {
    return <FormatItem text={text} bgColor={bgColor} />;
  }
  return null;
};