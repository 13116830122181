import React from 'react';
import styles from './styles/create.module.scss';
import FormSupplier from './components/formSupplier';
import HeaderGr from './components/headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

const CreateSupplier = (props) => {
  const Breadcrumbs = [
    {
      path: '/supplier',
      name: I18n.t('supplier.Supplier'),
    },
    {
      path: '/supplier/add',
      name: I18n.t('supplier.addSupplier'),
    },
  ];
  
  return (
    <div className={styles['layout']}>
      <HeaderGr 
        breadcrumbs={Breadcrumbs} 
        title={I18n.t('supplier.addSupplier')} 
        navigate={props.router}
      />
      <FormSupplier
        action="add"
        navigate={props.router}
        params={{ supplierId: null }}
      />
    </div>
  );
};

export default connect()(CreateSupplier);
