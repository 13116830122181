import React, { useEffect, useState } from 'react';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import { Translate } from 'react-redux-i18n';
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import QRCode from 'react-qr-code';

const Apps = ({ selectedFleet, corporateId, corpName }) => {
  const [webLink, setWebLink] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    setWebLink(`${selectedFleet?.pwaLink}/?f=${selectedFleet?.fleetToken}&c=${corporateId}`)
  }, [])

  const handleCopyClick = (contentId) => {
    copy(contentId);
  };

  const onImageDownload = () => {
    const svg = document.getElementById('QRCodeWebBooking');
    if (!svg) return;
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      const downloadName = `QRCodeWebBooking-${corpName}.png`;
      downloadLink.download = downloadName;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div style={{ padding: '10px 20px'}}>
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <FormGroupTitle>
              <Translate
                value="corporate.qrTitle"
                style={{
                  fontWeight: 'bold',
                  marginBottom: '10px',
                  display: 'block',
                  textTransform: 'capitalize',
                }}
              />
            </FormGroupTitle>

            <Translate value="corporate.qrNote" style={{ color: 'white', marginBottom: '0px', display: 'block' }} />
            <FormGroup className="qup-input-group questLink">
              <InputGroup className="single-addon-right">
                <FormControl
                  className="form-custom"
                  type="text"
                  value={webLink}
                  disabled={true}
                />
                <OverlayTrigger
                  overlay={
                    isCopied ? (
                      <Tooltip id="Update_info">
                        <Translate value="bookingdetail.Copied" />
                      </Tooltip>
                    ) : (
                      <span></span>
                    )
                  }
                  show={true}
                  placement="top"
                  delayShow={300}
                  delayHide={150}
                >
                  <InputGroup.Append className="addonPartner">
                    <InputGroup.Text>
                      <i
                        class="fa fa-copy"
                        onClick={() => {
                          handleCopyClick(webLink);
                          setIsCopied(true);
                        }}
                        onMouseLeave={() => {
                          setIsCopied(false);
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </OverlayTrigger>
              </InputGroup>
            </FormGroup>
            <div id="qrContainer" style={{ marginTop: '30px'}}>
              <QRCode value={webLink} id="QRCodeWebBooking" size={250} />
              <Button onClick={onImageDownload} id="btnDownloadQR">
                Download QR
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Apps;
