import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

import './map.scss';
import Map from './Map';
import Heatmap from './Heatmap';

class GeneralSetting extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() { }

  render() {
    const { permissions } = this.props;
    if (permissions && !permissions.viewheatmap) {
      return <Map {...this.props} />;
    }
    return (
      <div className="content pt0 mapContainer">
        <Tabs
          id="tab"
          defaultActiveKey={0}
          animation={false}
          className="cue-tabs"
        >
          <Tab eventKey={0} title={I18n.t('map.driversLiveMap')}>
            <Map {...this.props} />
          </Tab>
          {!this.props?.auth?.user?.roles?.isSupplier && permissions && permissions.viewheatmap ? (
            <Tab eventKey={1} title={I18n.t('map.heatMap')}>
              <Heatmap />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSetting);
