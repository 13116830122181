import ReportUtils from './../base/utils'
export const fields = [
  {
    key: "checkBox",
    label: "report.result.payoutToMerchant.checkBox",
    isCheckBox: true,
    valueKey: "merchantId",
    width: 60
  },
  {
    key: "merchantName",
    label: "report.result.payoutToMerchant.merchantName",
    summary: "totalDriver"
  },
  {
    key: "phone",
    label: "report.result.payoutToMerchant.phone"
  },
  {
    key: "bankAccountHolder",
    label: "report.result.payoutToMerchant.bankAccountHolder"
  },
  {
    key: "bankName",
    label: "report.result.payoutToMerchant.bankName",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB' && selectedFleet.drvPayout.gateway !== 'credit') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToMerchant.accountNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToMerchant.iBanNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "pendingAmount",
    label: "report.result.payoutToMerchant.pendingAmount",
    summary: "totalPendingAmount"
  },
  {
    key: "holdAmount",
    label: "report.result.payoutToMerchant.holdAmount",
    summary: "totalHoldAmount"
  },
  {
    key: "payoutAmount",
    label: "report.result.payoutToMerchant.payoutAmount",
    summary: "totalPayoutAmount"
  }
];

export const fareFields = ["holdAmount", "pendingAmount", "payoutAmount"];
