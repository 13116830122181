import {
    NEW_SHUTTLE_REQUEST,
    CLOSE_SHUTTLE_FORM,
    SHUTTLE_REQUEST,
    SHUTTLE_SUCCESS,
    SHUTTLE_FAILURE
} from "../actions/shuttleAction";


const initialState = {
    isShow: false,
    loading: false,
    error: '',
    data: {},
    common: {
        carTypes: [],
        carType: {},
        companyVehicleTypes: []
    }
};

const shuttle = (state = initialState, action) => {
    switch (action.type) {
        case NEW_SHUTTLE_REQUEST:
            return {
                ...state,
                isShow: true
            };
        case CLOSE_SHUTTLE_FORM:
            return initialState;
        case SHUTTLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SHUTTLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            };
        case SHUTTLE_FAILURE:
            return {
                ...state,
                loading: false,
                data: {},
                error: action.payload
            };
        default:
            return state
    }
}

export default shuttle