import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import searchIcon from '../../../../assets/images/icons/magnifying-glass.svg';
import closeIcon from '../../../../assets/images/icons/closeWhite.svg';
import CCDropDown from '../../../../components/dropdown/ccDropDown';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';

const LIMIT_SEARCH = 10;

const SearchVehicle = ({
  fleetId,
  userId,
  openLinkVehicleModal,
  handleLinkVehicleModal,
  companyId,
  carType,
  ...props
}, context) => {
  const [items, setItems] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedCarTypes, setSelectedCarTypes] = useState([]);

  const fetchItems = async (textSearch, page, company, selectedCar) => {
    try {
      const params = {
        fleetId,
        limit: LIMIT_SEARCH,
        query: {
          fleetId,
          txtSearch: textSearch,
          companyId: company,
          carTypes: selectedCar,
          isActive: true,
        },
        page: page,
      };
      const response = await props.settingActions.getAllCars(params);
      if (response.ok && response.res) {
        const { page, total, list } = response.res;
        setItems((prevItems) => [...prevItems, ...list]);
        if (page * LIMIT_SEARCH >= total) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const debouncedFetchItems = useCallback(
    _.debounce((query, page, companyId, selectedCarTypes) => {
      return fetchItems(query, page, companyId, selectedCarTypes);
    }, 500),
    []
  );

  const searchByInput = (e) => {
    setQuery(e.target.value);
    setHasMore(true);
    setItems([]);
    setPage(0);
    debouncedFetchItems(e.target.value, 0, companyId, selectedCarTypes);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setItems([]);
    fetchItems(query, 0, companyId, selectedCarTypes);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const fetchMoreData = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    debouncedFetchItems(query, nextPage, companyId, selectedCarTypes);
  };

  const closeModal = () => {
    setQuery('');
    setItems([]);
    setSelectedCarTypes([]);
    setHasMore(true);
    setPage(0);
    handleLinkVehicleModal(false);
  }

  const handleItemClick = async (event, item) => {
    event.stopPropagation();
    try {
      const respone = await props.driverActions.addVehicle({
        vehicleId: item._id,
        vehicleType: item?.type?.name,
        fleetId: fleetId,
        userId,
      });
      if (respone?.res?.status === 200) {
        closeModal();
        props.updateVehicleIds(respone.res.vehicleIds || [], item);
      } else {
        context.notification(
          'error',
          I18n.t('errors.' + respone.error.errorCode)
        );
      }
    } catch (error) {
      
    }
  };

  const renderItems = (item) => {
    return (
      <div
        key={item._id}
        style={{
          padding: '15px 0',
        }}
        onMouseDown={(event) => handleItemClick(event, item)}
        className="itemSearch"
      >
        <div style={{ width: '100px' }}>
          <img
            src={item?.image}
            style={{ maxWidth: '100%', maxHeight: '50px' }}
          />
        </div>
        <div className="vhcInfo">
          <span className="vhcPlateNumber">{item?.plateNumber}</span>
          <span className="vhcModel">{`${item.year || ''} ${item?.vehicleModel?.vehicleMake || ''} ${item?.vehicleModel?.name || ''}`}</span>
          <span className="vhcType">{item?.color || ''}</span>
        </div>
      </div>
    );
  };

  const updateFillterCarTypes = (newValue) => {
    setSelectedCarTypes(newValue)
  }

  return (
    <Modal
      show={openLinkVehicleModal}
      dialogClassName="confirm-dialog"
      backdrop="static"
      size="sm"
      className="confirm searchDrvModal modalBodyFixed"
      onHide={() => closeModal(false)}
    >
      <Modal.Body style={{ backgroundColor: '#24272E' }}>
        <div className="searchVehicleDrv">
          <div className="searchTop">
            <label for="inputText">
              <img src={searchIcon} />
            </label>
            <input
              type="text"
              placeholder="Search or select vehicle"
              id="inputText"
              value={query}
              onChange={searchByInput}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              autoComplete="off"
            />
            <button type="button" onClick={() => closeModal(false)}>
              <img src={closeIcon} />
            </button>
          </div>
          <div className='carTypeDriverDrd'>
            <CCDropDown
              id="carTypeDriver_dropdown"
              hasIconAdd
              items={carType || []}
              title={I18n.t('cue.Car_type')}
              selectedItems={selectedCarTypes}
              valueKey="vehicleType"
              labelKey="vehicleType"
              noTranslateLabel
              onSelect={updateFillterCarTypes}
              multiSelect={true}
              enableClearAll={true}
              searchable={true}
            />
          </div>
          {isInputFocused && items.length > 0 && (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchMoreData}
              hasMore={hasMore}
              height={300}
            >
              {items.map((item, index) => renderItems(item))}
            </InfiniteScroll>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

SearchVehicle.contextTypes = {
  notification: PropTypes.func,
};

export default SearchVehicle;
