import React, { createElement } from 'react';
import CurrencyFormater from 'currency-formatter';
import _ from 'lodash';
import { Localize, Translate, I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';
import { BookingServiceType, PAYMENT_LINK_NAME } from '../../../constants/commondata';
import { BsEnvelopeFill } from "react-icons/bs";

const noShowInNetworkTypes = (networkTypes) => (param, options) => {
  const lastNetworkType = _.get(options, 'lastQuery.criteria.bookingService');
  return [].concat(networkTypes).indexOf(lastNetworkType) > -1;
};

const mutateFare = (value, doc) => {
  if (doc.bookingService === 'Farm out') {
    return '--';
  }
  return value;
};

const isNotShowSplitPayment = (selectedFleet) => {
  return !_.get(selectedFleet, 'partialPayment.enable', false);
};

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.bookingDetails.bookingId',
    summary: 'totalBookings',
    fixed: true,
    isAlwayShow: true,
  },
  {
    key: 'externalId',
    label: 'report.result.thirdPartyBooking.externalId',
    checkShowFromProps: 'selectedFleet',
    hideWithSupplierPermission: true
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    key: 'tripId',
    label: 'report.result.bookingDetails.tripId',
    mutate: (value, doc) => {
      if (doc.tripId) {
        return `${doc.tripId}`;
      }
      return '';
    },
    summary: 'tripId',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      if (selectedFleet.intercity && selectedFleet.intercity.enable) {
        return false;
      }
      return true;
    },
  },
  {
    key: 'bookFrom',
    label: 'report.result.bookingDetails.bookFrom',
    summary: 'bookFrom',
    mutate: (value) => {
      if (value) {
        switch (value) {
          case 'mDispatcher':
            value = 'Partner';
            break;
          case 'streetSharing':
            value = 'Street sharing';
            break;
          default:
            break;
        }
      }
      return value;
    },
    width: 180,
  },
  {
    key: 'bookingService',
    label: 'report.result.bookingDetails.bookingService',
    summary: 'bookingService',
    hideWithSupplierPermission: true
  },
  {
    key: 'bookingType',
    label: 'report.result.bookingDetails.bookingType',
    summary: 'bookingType',
    width: 150,
  },
  {
    key: 'serviceType',
    label: 'report.result.bookingDetails.serviceType',
    summary: 'serviceType',
  },
  {
    key: 'packageName',
    label: 'report.result.bookingDetails.packageName',
    summary: 'packageName',
    mutate: (value, doc) => {
      if(['From airport - hourly/daily', 'Hourly', 'To airport - hourly/daily'].includes(doc.serviceType)) {
        return doc.packageRateName
      }
      if(doc.flatRouteName && doc.bookingType !== 'Intercity') {
        return doc.flatRouteName
      }
      return ''
    },
    link: {
      href: {
        templateStr: '',
        params: {
          customerId: '',
        },
      },
      customeHref: {
        hourly: {
          templateStr: '/settings/Rate/Hourly?rateId=${packageRateId}',
          params: {
            packageRateId: 'packageRateId',
          },
        },
        flat: {
          templateStr: '/settings/Rate/Flat?rateId=${flatRouteId}',
          params: {
            flatRouteId: 'flatRouteId',
          } 
        }
      },
      newTab: true,
      notMergeParams: true,
      name: 'gotoCustomerDetails',
      description: 'Link to show customer details',
    },
  },
  {
    key: 'operator',
    label: 'report.result.bookingDetails.operator',
    summary: 'operator',
    hideWithSupplierPermission: true
  },
  {
    key: 'customerPhone',
    label: 'report.result.bookingDetails.customerPhone',
    summary: 'customerPhone',
    width: 250,
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'customerName',
    label: 'report.result.bookingDetails.customerName',
    summary: 'customerName',
    link: {
      href: {
        templateStr: '/customer/view/${customerId}?onlyView=TRUE',
        params: {
          customerId: 'customerId',
        },
      },
      newTab: true,
      notMergeParams: true,
      name: 'gotoCustomerDetails',
      description: 'Link to show customer details',
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'corporateName',
    label: 'bookingdetail.corporateName',
    hideWithSupplierPermission: true
  },
  {
    key: 'paxNumber',
    label: 'cue.Pax',
    summary: 'paxNumber',
  },
  {
    key: 'luggageNumber',
    label: 'cue.Luggage',
  },
  {
    key: 'customerIdNumber',
    label: 'report.result.bookingDetails.customerIdNumber',
    summary: 'customerIdNumber',
    link: {
      href: {
        templateStr: '/customer/view/${customerId}?onlyView=TRUE',
        params: {
          customerId: 'customerId',
        },
      },
      newTab: true,
      notMergeParams: true,
      name: 'gotoCustomerDetails',
      description: 'Link to show customer details',
    },
  },
  {
    key: 'corpDivision',
    label: 'report.result.bookingDetails.corpDivision',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'corpDept',
    label: 'report.result.bookingDetails.corpDept',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'chargeCode',
    label: 'report.result.bookingDetails.chargeCode',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'tripDescription',
    label: 'report.result.bookingDetails.tripDescription',
    isShowCorp: ReportUtils.isShowCorporateAdvanceInfo,
  },
  {
    key: 'isRideSharing',
    label: 'report.result.bookingDetails.rideSharing',
    mutate: (value, doc) => {
      if (value) {
        return doc.isMatchingSharing ? 'Matching' : 'Unmatching';
      }
      return 'N/A';
    },
    link: {
      isNotShow: (doc) => !doc.isMatchingSharing,
      notMergeParams: true,
      name: 'showSharedBooks',
      description: 'Link to show sharing bookings',
    },
    width: 150,
  },
  {
    key: 'queueName',
    label: 'report.result.bookingDetails.queueName',
    summary: 'queueName',
  },
  {
    key: 'bookingTime',
    label: 'report.result.bookingDetails.bookingTime',
    summary: 'bookingTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
  },
  {
    key: 'pickupTime',
    label: 'report.result.bookingDetails.pickupTime',
    summary: 'pickupTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
    isAlwayShow: true,
  },
  {
    key: 'expectedPickupTime',
    label: 'report.result.bookingDetails.expectedPickupTime',
    summary: 'expectedPickupTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      if (selectedFleet.cue && selectedFleet.cue.showETA) {
        return false;
      }
      return true;
    },
  },
  {
    key: 'dispatchTime',
    label: 'report.result.bookingDetails.dispatchTime',
    summary: 'dispatchTime',
    width: 150,
  },
  {
    key: 'onBoardTime',
    label: 'report.result.bookingDetails.onBoardTime',
    summary: 'onBoardTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
  },
  {
    key: 'droppedOffTime',
    label: 'report.result.bookingDetails.droppedOffTime',
    summary: 'droppedOffTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
  },
  {
    key: 'extraWaitTime',
    label: 'bookingdetail.extraWaitTime',
    width: 180,
  },
  {
    key: 'onBoardDuration',
    label: 'report.result.bookingDetails.onBoardDuration',
    summary: 'onBoardDuration',
    width: 180,
  },
  {
    key: 'dispatch',
    label: 'report.result.bookingDetails.dispatch',
    mutate: (value) => `${value} ${value > 1 ? 'times' : 'time'}`,
    summary: 'dispatch',
    width: 150,
  },
  {
    key: 'completedTime',
    label: 'report.result.bookingDetails.completedTime',
    summary: 'completedTime',
    mutate: (value, doc) => {
      return value ? ReportUtils.formatTime(value, doc) : 'N/A';
    },
    isAlwayShow: true,
  },
  {
    key: 'driverName',
    label: 'report.result.bookingDetails.driverName',
    summary: 'driverName',
    link: {
      newTab: true,
      isNotShow: (doc) => {
        if (doc.bookingServiceType === BookingServiceType.Home) {
          return true;
        }
        return !doc.driverId;
      },
      href: {
        templateStr: '/driver/view/${driverId}/info',
        params: {
          driverId: 'driverId',
        },
      },
      notMergeParams: true,
      name: 'gotoDriverDetails',
      description: 'Link to show driver details',
    },
  },
  {
    key: 'phone',
    label: 'report.result.documentExpiry.driverPhone',
  },
  {
    key: 'idPermitType',
    label: 'report.result.idPermitType',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const driverFields = _.get(selectedFleet, 'driverFields', []);
      return !_.find(driverFields, (df) => df.key === 'idType');
    },
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.bookingDetails.driverIdPermit',
    summary: 'driverIdPermit',
    width: 160,
  },
  {
    key: 'driverLicenseNumber',
    label: 'report.result.bookingDetails.driverLicenseNumber',
    summary: 'driverLicenseNumber',
  },
  {
    key: 'companyName',
    label: 'report.result.bookingDetails.companyName',
    summary: 'companyName',
    hideWithSupplierPermission: true
  },
  {
    key: 'carTypeRequested',
    label: 'report.result.bookingDetails.carTypeRequest',
    summary: 'carType',
    width: 160,
  },
  {
    key: 'carType',
    label: 'report.result.bookingDetails.carType',
    summary: 'carType',
    width: 150,
  },
  {
    key: 'licensePlate',
    label: 'report.result.bookingDetails.licensePlate',
    summary: 'licensePlate',
    link: {
      newTab: true,
      isNotShow: (doc) => {
        if (doc.bookingServiceType === BookingServiceType.Home) {
          return true;
        }
        return !doc.vehicleId;
      },
      href: {
        templateStr: '/settings/Car/Car_mgmt/detail/${vehicleId}',
        params: {
          vehicleId: 'vehicleId',
        },
      },
      notMergeParams: true,
      name: 'gotoCarDetails',
      description: 'Link for show car details',
    },
    width: 150,
  },
  {
    key: 'intercityRoute',
    label: 'report.result.bookingDetails.intercityRoute',
    mutate: (value, doc) => {
      if (doc.routeId) {
        return `${doc.routeFromZone} - ${doc.routeToZone}`;
      }
      return '';
    },
    summary: 'intercityRoute',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      if (selectedFleet.intercity && selectedFleet.intercity.enable) {
        return false;
      }
      return true;
    },
  },
  {
    key: 'pickUpZoneName',
    label: 'report.result.bookingDetails.pickUpZoneName',
    width: 200,
  },
  {
    key: 'pickupLocation',
    label: 'report.result.bookingDetails.pickupLocation',
    summary: 'pickupLocation',
    manualTooltip: (value, doc, options) => {
      if (Array.isArray(doc.puPoints) && doc.puPoints.length > 1) {
        return (
          <>
            <p>{doc.puPoints.join('\n\n')}</p>
          </>
        );
      }
      return null;
    },
    width: 300,
    isAlwayShow: true,
  },
  {
    key: 'geoPickup',
    label: 'report.result.bookingDetails.geoPickup',
    mutate: (value, doc) => `${doc.pickupLat}, ${doc.pickupLon}`,
    summary: 'geoPickup',
    width: 300,
  },
  {
    key: 'originDestination',
    label: 'report.result.bookingDetails.originDestination',
    summary: 'originDestination',
    width: 300,
  },
  {
    key: 'destination',
    label: 'report.result.bookingDetails.destination',
    summary: 'destination',
    manualTooltip: (value, doc, options) => {
      if (
        Array.isArray(doc.extraDestination) &&
        doc.extraDestination.length > 0
      ) {
        const { extraDestination = [] } = doc;
        const addresses = extraDestination.map((ed) => ed.address);
        return (
          <>
            {addresses && addresses.length && (
              <p>
                <Translate value="report.result.bookingDetails.extraDestination" />
                : {addresses.join(', ')}
              </p>
            )}
            <p>
              <Translate value="report.result.bookingDetails.destination" />:{' '}
              {value}
            </p>
          </>
        );
      }
      if (Array.isArray(doc.doPoints) && doc.doPoints.length > 1) {
        return <>{<p>{doc.doPoints.join('\n\n')}</p>}</>;
      }
      return null;
    },
    width: 300,
  },
  {
    key: 'geoDestination',
    label: 'report.result.bookingDetails.geoDestination',
    mutate: (value, doc) => `${doc.destinationLat}, ${doc.destinationLon}`,
    summary: 'geoDestination',
    width: 300,
  },
  {
    key: 'pickupFrom',
    label: 'report.result.bookingDetails.pickupFrom',
    summary: 'pickupFrom',
  },
  {
    key: 'originEstDistance',
    label: 'report.result.bookingDetails.originEstDistance',
    headerUnit: 'unitDistance',
    width: 230,
  },
  {
    key: 'distanceTour',
    label: 'report.result.bookingDetails.distanceTour',
    headerUnit: 'unitDistance',
  },
  {
    key: 'notes',
    label: 'report.result.bookingDetails.notes',
    summary: 'notes',
  },
  {
    key: 'internalNotes',
    label: 'report.result.bookingDetails.internalNotes',
    hideWithSupplierPermission: true
  },
  {
    key: 'instructions',
    label: 'report.result.bookingDetails.instructions',
    summary: 'instructions',
  },
  {
    key: 'recipientsNumber',
    label: 'report.result.bookingDetails.recipientsNumber',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) =>
      !selectedFleet.delivery || !selectedFleet.delivery.enable,
    mutate: (value, doc) => {
      const recipients = _.get(doc, 'deliveryInfo.recipients', []) || [];
      return recipients.length;
    },
  },
  {
    key: 'recipientsInfo',
    label: 'report.result.bookingDetails.recipientsInfo',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) =>
      !selectedFleet.delivery || !selectedFleet.delivery.enable,
    link: {
      label: 'report.result.bookingDetails.view',
      name: 'showRecipientsInfo',
      notMergeParams: true,
      description: 'Show list recipients of this delivery booking',
      disable(doc) {
        const recipients = _.get(doc, 'deliveryInfo.recipients', []) || [];
        return !recipients || recipients.length === 0;
      },
    },
  },
  {
    key: 'merchants',
    label: 'report.result.bookingDetails.view_good_receipt',
    summary: 'merchants',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      if (
        (selectedFleet.delivery && selectedFleet.delivery.enable) ||
        (selectedFleet.food && selectedFleet.food.enable) ||
        (selectedFleet.mart && selectedFleet.mart.enable)
      ) {
        return false;
      }
      return true;
    },
    link: {
      label: 'report.result.bookingDetails.view',
      name: 'showCollectedPhotos',
      notMergeParams: true,
      description: 'Show dispatching history of this booking',
      disable(doc) {
        if (!doc.collectedPhotos || !doc.collectedPhotos[0]) {
          return true;
        }
        if (doc.collectedPhotos.length === 0) {
          return true;
        }
        return doc.bookingType !== 'Delivery';
      },
    },
  },
  {
    key: 'recipients',
    label: 'report.result.bookingDetails.view_delivery_receipt',
    summary: 'recipients',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      if (
        (selectedFleet.delivery && selectedFleet.delivery.enable) ||
        (selectedFleet.food && selectedFleet.food.enable) ||
        (selectedFleet.mart && selectedFleet.mart.enable)
      ) {
        return false;
      }
      return true;
    },
    link: {
      label: 'report.result.bookingDetails.view',
      name: 'showDeliveredPhotos',
      notMergeParams: true,
      description: 'Show dispatching history of this booking',
      disable(doc) {
        if (!doc.deliveredPhotos || !doc.deliveredPhotos[0]) {
          return true;
        }
        if (doc.deliveredPhotos.length === 0) {
          return true;
        }
        return doc.bookingType !== 'Delivery';
      },
    },
  },
  {
    key: 'dynamicFare',
    label: 'report.result.bookingDetails.dynamicFare',
    width: 200,
    summary: 'dynamicFare',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(modules, (m) => m.name === 'Dynamic Fare');
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'dynamicFactor',
    label: 'report.result.bookingDetails.dynamicFactor',
    checkShowFromProps: 'selectedFleet',
    mutate: (value, doc) => {
      if (doc.bookingService === 'Farm out') {
        return '--';
      }
      if (value == 0) return '';
      return `${value || 0}x`;
    },
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(modules, (m) => m.name === 'Dynamic Fare');
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'surchargeParameter',
    label: 'report.result.bookingDetails.surchargeParameter',
    mutate: (value, doc) => {
      if (doc.bookingService === 'Farm out') {
        return '--';
      }
      return `${doc.surchargeParameter}x`;
    },
    width: 150,
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(modules, (m) => m.name === 'Dynamic Surcharge');
    },
  },
  {
    key: 'basicFareCalculator',
    label: 'report.result.bookingDetails.basicFareCalculator',
    mutate: mutateFare,
    summary: 'basicFareCalculator',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    hideWithSupplierPermission: true
  },
  {
    key: 'basicFare',
    label: 'report.result.bookingDetails.basicFare',
    mutate: mutateFare,
    summary: 'basicFare',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 180,
    hideWithSupplierPermission: true
  },
  {
    key: 'editFare',
    label: 'report.result.bookingDetails.editFare',
    isShow: (value) => value > 0,
    mutate: (value) => (value && true) || '',
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'oldBasicFare',
    label: 'report.result.bookingDetails.oldBasicFare',
    isShow: (value, doc) => doc.editFare > 0,
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    mutate: (value, doc) => {
      if (doc.bookingService === 'Farm out') {
        return '--';
      }
      return (doc.editFare && (value || 0)) || '';
    },
    width: 180,
    hideWithSupplierPermission: true
  },
  {
    key: 'extraWaitFee',
    label: 'bookingdetail.extraWaitFee',
    mutate: mutateFare,
    summary: 'extraWaitFee',
    hideWithSupplierPermission: true
  },
  {
    key: 'rushHour',
    label: 'report.result.bookingDetails.rushHour',
    isShow: 'rushHourActive',
    mutate: mutateFare,
    summary: 'rushHour',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'heavyTraffic',
    label: 'report.result.bookingDetails.heavyTraffic',
    isShow: 'heavyTrafficActive',
    mutate: mutateFare,
    summary: 'heavyTraffic',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'serviceFee',
    label: 'report.result.bookingDetails.serviceFee',
    isShow: 'additionalServicesActive',
    summary: 'serviceFee',
    isNotShow: noShowInNetworkTypes(['provideService']),
    mutate: (value, doc) => {
      if (doc.bookingService === 'Farm in') {
        return '--';
      }
      return ReportUtils.numberFormat(value || 0);
    },
    link: {
      isNotShow: (doc) => !doc.serviceFee,
      notMergeParams: true,
      name: 'showAdditionalServices',
      description: 'Link to show additional services',
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'otherFees',
    label: 'report.result.bookingDetails.otherFees',
    isShow: 'otherFeeActive',
    mutate: mutateFare,
    summary: 'otherFees',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    link: {
      isNotShow: (doc) => !doc.otherFeesDetails,
      notMergeParams: true,
      name: 'showOtherFeeNote',
      description: 'Link to show other fee note',
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'addOnPrice',
    label: 'report.result.bookingDetails.addonPrice',
    checkShowFromProps: 'selectedFleet',
    mutate: mutateFare,
    isNotShow: (selectedFleet) => {
      const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
      return !addonPrice;
    },
    summary: 'addOnPrice',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'oldSubTotal',
    label: 'report.result.bookingDetails.oldSubTotal',
    checkShowFromProps: 'selectedFleet',
    mutate: mutateFare,
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const addonPrice = _.get(selectedFleet, 'priceAdjustable', false);
      return !addonPrice;
    },
    summary: 'oldSubTotal',
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'editedDriverEarning',
    label: 'report.result.bookingDetails.editedDriverEarning',
    mutate: (value, doc) => (!doc.driverEarningType || doc.driverEarningType === 'default') ? ' ' : mutateFare(value, doc),
    summary: 'editedDriverEarning',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const enableEditDriverEarning = _.get(
        selectedFleet,
        'generalSetting.editDriverEarning',
        false
      );
      return !enableEditDriverEarning
    },
    width: 150,
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'driverEarning',
    label: 'report.result.bookingDetails.driverEarning',
    mutate: mutateFare,
    summary: 'driverEarning',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const enableEditDriverEarning = _.get(
        selectedFleet,
        'generalSetting.editDriverEarning',
        false
      );
      return !enableEditDriverEarning
    },
    width: 150,
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'subtotal',
    label: 'report.result.bookingDetails.subtotal',
    mutate: mutateFare,
    summary: 'subtotal',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'subTotalFF',
    label: 'report.result.bookingDetails.subTotalFF',
    summary: 'subTotalFF',
    mutate: mutateFare,
    width: 150,
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(
        modules,
        (m) => m.name === 'Driver to pax' || m.name === 'Pax refers Pax'
      );
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'airportFee',
    label: 'report.result.bookingDetails.airportFee',
    mutate: mutateFare,
    summary: 'airportFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'meetDriver',
    label: 'report.result.bookingDetails.meetDriver',
    isShow: 'meetDriver',
    mutate: mutateFare,
    summary: 'meetDriver',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'tollFee',
    label: 'report.result.bookingDetails.tollFee',
    isShow: 'tollFeeActive',
    mutate: mutateFare,
    summary: 'tollFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'parkingFee',
    label: 'report.result.bookingDetails.parkingFee',
    isShow: 'parkingFeeActive',
    mutate: mutateFare,
    summary: 'parkingFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'gasFee',
    label: 'report.result.bookingDetails.gasFee',
    isShow: 'gasFeeActive',
    mutate: mutateFare,
    summary: 'gasFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'fleetServiceFee',
    label: 'report.result.bookingDetails.fleetServiceFee',
    summary: 'fleetServiceFee',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      // if (noShowInNetworkTypes('provideService')(null, options)) {
      //   return true;
      // }
      const additionalFees = _.get(selectedFleet, 'additionalFees', 1);
      return !(additionalFees === 1);
    },
    mutate: (value, doc) => {
      if (doc.bookingService === 'Farm in') {
        return '--';
      }
      return ReportUtils.numberFormat(value || 0);
    },
    link: {
      isNotShow: (doc) => !doc.fleetServiceFee,
      notMergeParams: true,
      name: 'showFleetServices',
      description: 'Link to show fleet services',
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'techFee',
    label: 'report.result.bookingDetails.techFee',
    mutate: mutateFare,
    summary: 'techFee',
    isShow: 'techFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'bookingFee',
    label: 'report.result.bookingDetails.bookingFee',
    mutate: mutateFare,
    summary: 'bookingFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'salesTax',
    label: 'report.result.bookingDetails.salesTax',
    mutate: mutateFare,
    isShow: 'taxActive',
    summary: 'salesTax',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'tipAmt',
    label: 'report.result.bookingDetails.tipAmt',
    mutate: mutateFare,
    isShow: 'tipActive',
    summary: 'tipAmt',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'promoCode',
    label: 'report.result.bookingDetails.promoCode',
    isNotShow: noShowInNetworkTypes(['provideService']),
    hideWithSupplierPermission: true
  },
  {
    key: 'promoAmt',
    label: 'report.result.bookingDetails.promoAmt',
    summary: 'promoAmt',
    isNotShow: noShowInNetworkTypes(['provideService']),
    hideWithSupplierPermission: true
  },
  {
    key: 'creditTransactionFee',
    label: 'report.result.bookingDetails.creditTransactionFee',
    mutate: mutateFare,
    summary: 'creditTransactionFee',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    mutate: (value, obj) => ReportUtils.numberFormat(value * 1, 2, 2),
    summaryMutate: (value, obj) => ReportUtils.numberFormat(value * 1, 2, 2),
    hideWithSupplierPermission: true
  },
  {
    key: 'tipAfterCompleted',
    label: 'report.result.financialDriver.driverTipAfterCompleted',
    mutate: mutateFare,
    summary: 'tipAfterCompleted',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    negativable: true,
    manualTooltip: (value, doc, options) => {
      const {
        currencySymbol,
        currencyISO,
        tipTransactionFee,
        driverTipAfterCompleted,
      } = doc;
      const currency = CurrencyFormater.findCurrency(currencyISO);
      return I18n.t(
        'report.result.bookingDetails.tipAfterCompletedDetails'
      ).format(
        currency ? currency.symbol : currencySymbol,
        (driverTipAfterCompleted || 0).toFixed(2),
        (tipTransactionFee || 0).toFixed(2)
      );
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'markupDifference',
    label: 'report.result.bookingDetails.markupDifference',
    mutate: mutateFare,
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) => {
      if (noShowInNetworkTypes('homeFleet')(null, options)) {
        return true;
      }
      const markupPrice = _.get(
        selectedFleet,
        'generalSetting.markupPrice',
        false
      );
      return !markupPrice;
    },
    summary: 'markupDifference',
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: 'requestedTotalAmount',
    label: 'report.result.bookingDetails.requestedTotalAmount',
    summary: 'requestedTotalAmount',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) =>
      !_.get(selectedFleet, 'moduleSettings.thirdPartyBooking', false),
    hideWithSupplierPermission: true
  },
  {
    key: 'total',
    label: 'report.result.bookingDetails.total',
    summary: 'total',
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'fleetMarkup',
    label: 'report.result.bookingDetails.fleetMarkup',
    summary: 'fleetMarkup',
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'receivedWhenDropOff',
    label: 'report.result.bookingDetails.receivedWhenDropOff',
    summary: 'receivedWhenDropOff',
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'totalCharged',
    label: 'report.result.bookingDetails.actualReceived',
    summary: 'totalCharged',
    hideWithSupplierPermission: true
  },
  {
    key: 'differentAmt',
    label: 'report.result.bookingDetails.differenceAmt',
    mutate: mutateFare,
    summary: 'differentAmt',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    hideWithSupplierPermission: true
  },
  {
    key: 'customerDebt',
    label: 'report.result.bookingDetails.customerDebt',
    summary: 'customerDebt',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      return !selectedFleet?.outstandingPayment?.enable
    },
    hideWithSupplierPermission: true
  },
  {
    key: 'refundAmount',
    label: 'report.result.bookingDetails.refundAmount',
    mutate: mutateFare,
    summary: 'refundAmount',
    isNotShow: noShowInNetworkTypes(['homeFleet']),
    hideWithSupplierPermission: true
  },
  {
    key: 'shortTrip',
    label: 'report.query.shortTrip.shortTrip',
    summary: 'shortTrip',
    isShowShortTrip: 'shortTrip',
    summaryMutate: (value, obj) => (value && parseInt(value) > 0 ? value : ''),
    // isShowByFilter: { filterName: 'bookingService', value: ['localBooking'] }
  },
  {
    key: 'requestedPaymentMethod',
    label: 'report.result.bookingDetails.requestedPaymentMethod',
    summary: 'requestedPaymentMethod',
    mutate: (value, doc) => {
      if (value === 'mDispatcher') return 'Partner';
      if (value === 'BankOfGeorgia') return 'Bank Of Georgia';
      if (value === 'paymentLink') return `Payment link (${PAYMENT_LINK_NAME[doc.stripeMethod]})`;
      return value;
    },
    width: 250,
    hideWithSupplierPermission: true
  },
  {
    key: 'paidBy',
    label: 'report.result.bookingDetails.paidBy',
    summary: 'paidBy',
    mutate: (value) => {
      if (value === 'mDispatcher') return 'Partner';
      if (value === 'BankOfGeorgia') return 'Bank Of Georgia';
      return value;
    },
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
  {
    key: 'gatewayId',
    label: 'report.result.bookingDetails.transactionId',
    default: '',
    hideWithSupplierPermission: true
  },
  {
    key: 'paidByWallet',
    label: 'report.result.bookingDetails.paidByWallet',
    mutate: mutateFare,
    default: 0,
    summary: 'paidByWallet',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) =>
      isNotShowSplitPayment(selectedFleet) ||
      noShowInNetworkTypes('homeFleet')(null, options),
    hideWithSupplierPermission: true
  },
  {
    key: 'paidByOtherMethod',
    label: 'report.result.bookingDetails.paidByOtherMethod',
    mutate: mutateFare,
    default: 0,
    summary: 'paidByOtherMethod',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) =>
      isNotShowSplitPayment(selectedFleet) ||
      noShowInNetworkTypes('homeFleet')(null, options),
    hideWithSupplierPermission: true
  },
  {
    key: 'cashReceived',
    label: 'report.result.bookingDetails.cashReceived',
    mutate: mutateFare,
    default: 0,
    summary: 'cashReceived',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) =>
      isNotShowSplitPayment(selectedFleet) ||
      noShowInNetworkTypes('homeFleet')(null, options),
    hideWithSupplierPermission: true
  },
  {
    key: 'transferredChangeByWallet',
    label: 'report.result.bookingDetails.transferredChangeByWallet',
    mutate: mutateFare,
    default: 0,
    summary: 'transferredChangeByWallet',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) =>
      isNotShowSplitPayment(selectedFleet) ||
      noShowInNetworkTypes('homeFleet')(null, options),
    hideWithSupplierPermission: true
  },
  {
    key: 'returnedChangeByCash',
    label: 'report.result.bookingDetails.returnedChangeByCash',
    mutate: mutateFare,
    default: 0,
    summary: 'returnedChangeByCash',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet, options) =>
      isNotShowSplitPayment(selectedFleet) ||
      noShowInNetworkTypes('homeFleet')(null, options),
    hideWithSupplierPermission: true
  },
  {
    key: 'paymentStatus',
    label: 'report.query.paymentStatus',
    mutate: ReportUtils.mappingPaymentStatus,
    hideWithSupplierPermission: true
  },
  {
    key: 'sendReceiptButton',
    label: 'report.result.bookingDetails.sendReceipt',
    mutate: () => (
      <BsEnvelopeFill className="qup-color send-receipt-button"/>

    ),
    link: {
      notMergeParams: true,
      name: 'showSendReceipt',
    },
    width: 120,
    isAlwayShow: true,
    hideWithSupplierPermission: true
  },
];

export const rideSharingFields = [
  {
    key: 'customerName',
    label: 'report.result.bookingDetails.coriderName',
  },
  {
    key: 'bookId',
    label: 'report.result.bookingDetails.bookingId',
  },
];

export const serviceFields = [
  {
    key: 'name',
    label: 'report.result.bookingDetails.additionalServiceName',
  },
  {
    key: 'value',
    label: 'report.result.bookingDetails.additionalServiceValue',
    mutate: (value, doc) => (
      <Localize
        value={value}
        options={{
          style: 'currency',
          currency: doc.currencyISO,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
];

export const fleetServiceFields = [
  {
    key: 'serviceName',
    label: 'report.result.bookingDetails.additionalServiceName',
  },
  {
    key: 'fee',
    label: 'report.result.bookingDetails.additionalServiceValue',
    mutate: (value, doc) => (
      <Localize
        value={value}
        options={{
          style: 'currency',
          currency: doc.currencyISO,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
];

export const extraDestinationFields = [
  {
    key: 'address',
    label: 'report.result.bookingDetails.stopAddress',
  },
  {
    key: 'geo',
    label: 'report.result.bookingDetails.stopGeo',
    mutate: (value) => (value || []).join(', '),
  },
];

export const recipientsInfoFields = [
  { key: 'order', label: 'report.result.recipientsInfo.order' },
  {
    key: 'orderId',
    label: 'report.result.recipientsInfo.referenceNumber',
    mutate: (value, doc) => {
      try {
        const menuData = JSON.parse(doc.menuData);
        const items = menuData.selectedItems || [];
        const valueObject = items.find((i) => i.fieldKey === 'request.orderId');
        return _.get(valueObject, 'fieldValue', 'N/A')
      } catch (e) {
        return 'N/A';
      }
    },
  },
  {
    key: 'orderId',
    label: 'report.result.recipientsInfo.orderId',
  },
  { key: 'name', label: 'report.result.recipientsInfo.name' },
  { key: 'phone', label: 'report.result.recipientsInfo.phone' },
  {
    key: 'address',
    label: 'report.result.recipientsInfo.address',
    mutate: (value) => (value ? value.address : ''),
  },
  {
    key: 'fareDetails',
    label: 'report.result.recipientsInfo.ItemValue',
    mutate: (value, doc) => {
      try {
        const menuData = JSON.parse(doc.menuData);
        const items = menuData.selectedItems || [];
        const valueObject = items.find((i) => i.fieldKey === 'cashOnDelivery');
        return _.get(valueObject, 'fieldValue.value', 0)
      } catch (e) {
        return 0;
      }
    },
  },
  {
    key: 'fareDetails',
    label: 'report.result.recipientsInfo.deliveryFee',
    mutate: (value) => {
      const fee = _.get(value, 'deliveryFee');
      if (_.isNumber(fee)) {
        return fee.toFixed(2);
      }
      return 'N/A';
    },
  },
  {
    key: 'status',
    label: 'report.result.recipientsInfo.status',
    mutate: (value) => {
      if (value === 'failed') return 'Failed'
      if(['completed', 'delivered'].includes(value)) return 'Delivered';
      return ' '
    },
  },
  {
    key: 'finishedInfo',
    label: 'report.result.recipientsInfo.Performer',
    mutate: (value, doc) => {
      if(doc.status === 'failed') {
        const finishedBy = value && value.finishedBy || ''
        const converToText = {
          driver: 'Driver',
          operator: 'Operator',
        }
        if (_.isString(converToText[finishedBy])) {
          return converToText[finishedBy]
        }
        return ' ';
      }
      return ' '
    },
  },
  {
    key: 'finishedInfo',
    label: 'report.result.recipientsInfo.Reason',
    tooltip: true,
    tooltipValue: (value, doc) => {
      if(doc.status === 'failed') {
        return value && value.reason || '';
      }
      return ''
    },
  },
  {
    key: 'finishedInfo',
    label: 'report.result.recipientsInfo.proofOfFailure',
    isLink: true,
    renderLink: (doc) => {
      if(doc.status === 'failed') {
        const reasonImage = _.get(doc, 'finishedInfo.reasonImage', '')
        if(reasonImage) {
          return {
            href: reasonImage,
            label: 'View Image' 
          }
        }
        return ''
      }
      return ' '
    }
  },
];

export const floatFields = ['distanceTour', 'originEstDistance'];

export const fareFields = [
  'oldBasicFare',
  'fleetMarkup',
  'basicFareCalculator',
  'basicFare',
  'airportFee',
  'meetDriver',
  'rushHour',
  'heavyTraffic',
  'tollFee',
  'parkingFee',
  'gasFee',
  'otherFees',
  'addOnPrice',
  'markupDifference',
  'receivedWhenDropOff',
  'oldSubTotal',
  'subtotal',
  'subTotalFF',
  'techFee',
  'bookingFee',
  'salesTax',
  'tipAmt',
  'promoAmt',
  'requestedTotalAmount',
  'total',
  'totalNotRounded',
  'serviceFee',
  'totalCharged',
  'differentAmt',
  'customerDebt',
  'refundAmount',
  'tipAfterCompleted',
  'fleetServiceFee',
  'paidByWallet',
  'paidByOtherMethod',
  'cashReceived',
  'transferredChangeByWallet',
  'returnedChangeByCash',
  'editedDriverEarning',
  'driverEarning',
  'extraWaitFee',
  'dynamicFare'
];
