import React from 'react';
import styles from './styles/create.module.scss';
import FormVehicle from './components/formVehicle';
import HeaderGr from './components/headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

const CreateVehicle = (props) => {
  return (
    <div className={styles['layout']}>
      <HeaderGr
        grRoute={[
          I18n.t('carSetting.Vehicle'),
          I18n.t('carSetting.Add'),
        ]}
        title={I18n.t('carSetting.addVehicle')}
      />
      <FormVehicle action="add" navigate={props.router}/>

    </div>
  );
};

export default connect()(CreateVehicle);
