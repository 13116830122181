import {
  deliveryRateFetchApi,
  deliveryRateFindApi,
  deliveryRateFindOneApi,
  deliveryRateCreateApi,
  deliveryRateUpdatedApi,
  deliveryRateDeleteApi,
  deliveryRateActiveApi,
  deliveryRateDeactiveApi
} from '../constants/ApiConfigs';

import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

//Rate Delivery settings
export function fetchDeliveryRate(options = {}) {
  const config = {
    method: "GET"
  };
  return callApi(
    UrlBuilder(deliveryRateFetchApi, options),
    config,
    null,
    null,
    null,
    true
  );
};

export function createDeliveryRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateCreateApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function updateDeliveryRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateUpdatedApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function activeDeliveryRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateActiveApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function deactiveDeliveryRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateDeactiveApi,
    config,
    null,
    null,
    null,
    true
  );
}
export function deleteDeliveryRate(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateDeleteApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function getAllDeliveryRates(options) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateFindApi,
    config,
    null,
    null,
    null,
    true
  );
};

export function getDetailDeliveryRate(options) {
  options = Object.assign({}, options);
  const config = {
    method: "POST",
    body: JSON.stringify(options)
  };

  return callApi(
    deliveryRateFindOneApi,
    config,
    null,
    null,
    null,
    true
  );
}
