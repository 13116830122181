import React from 'react';
import { QQMap, QQPolygon, QQPolyline } from '../../../components/qqMap';
import { QueueColor } from './colorData';

const getTencentCenterPoint = coor => {
  var latLng = new window.qq.maps.LatLngBounds();
  if (coor) {
    latLng.extend(new window.qq.maps.LatLng(coor.lat, coor.lng));
    return latLng.getCenter();
  }
  return null;
};

const buildQQPolygonOption = (active, editable) => {
  if (active) {
    return {
      strokeColor: QueueColor.yourChoosen.border,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: new window.qq.maps.Color.fromHex(QueueColor.yourChoosen.fill, 0.4),
      editable: !!editable,
      zIndex: 2
    };
  }

  let options = {
    strokeColor: '#FFFF00',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: new window.qq.maps.Color.fromHex('#FF0000', 0.4),
    editable: !!editable,
    zIndex: 2
  };

  return options;
};

export default props => {
console.log("TCL: props", props)
  let parentZones = null;
  let driverArea = null;
  let pickUpAreas = null;
  let otherAreasPolygon = null;

  if (props.driverArea) {
    console.log('-driver Area');
    const paths = props.driverArea.path.map(
      pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
    );

    driverArea = (
      <QQPolygon
        key={props.driverArea.key}
        path={paths}
        editable={false}
        mapkey={props.driverArea.key}
        events={{
          mouseup: () => {
            props.onDriverAreaPolygonClick(props.driverArea);
          }
        }}
        options={{
          strokeColor: QueueColor.checkIn.border,
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: new window.qq.maps.Color.fromHex(
            QueueColor.checkIn.fill,
            0.4
          ),
          fillOpacity: 0.4,
          zIndex: 1
        }}
      />
    );
  }

  if (props.parentZones && props.parentZones.length) {
    parentZones = props.parentZones.map(zone => {
      const paths = zone.path.map(
        pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
      );
      return (
        <QQPolygon
          key={zone._id}
          onLoaded={map => {
            props.onMapLoad(`Polygon_${zone._id}`, map);
          }}
          path={paths}
          editable={false}
          mapkey={zone._id}
          onMouseUp={props.handleMapClick}
          options={{
            strokeColor: QueueColor.operationZones.border,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: new window.qq.maps.Color.fromHex('#ffffff00', 0.4),
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      );
    });
  }

  if (props.pickUpAreas && props.pickUpAreas.length) {
    pickUpAreas = props.pickUpAreas.map(zone => {
      const paths = zone.path.map(
        pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
      );
      return (
        <QQPolygon
          key={zone.key}
          onLoaded={map => {
            props.onMapLoad(`Polygon_${zone.key}`, map);
          }}
          path={paths}
          editable={false}
          mapkey={zone.key}
          // onMouseUp={props.handleMapClick}
          onMouseUp={() => {
            props.onPickUpPolygonClick(zone);
          }}
          options={{
            strokeColor: QueueColor.pickUp.border,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: new window.qq.maps.Color.fromHex(
              QueueColor.pickUp.fill,
              0.4
            ),
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      );
    });
  }

  if (props.otherAreasPolygon && props.otherAreasPolygon.length) {
    otherAreasPolygon = props.otherAreasPolygon.map(zone => {
      const paths = zone.path.map(
        pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
      );
      return (
        <QQPolygon
          key={zone.key}
          onLoaded={map => {
            props.onMapLoad(`Polygon_${zone.key}`, map);
          }}
          path={paths}
          editable={false}
          mapkey={zone.key}
          onMouseUp={props.handleMapClick}
          options={{
            strokeColor: QueueColor.otherAreas.border,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: new window.qq.maps.Color.fromHex(
              QueueColor.otherAreas.fill,
              0.4
            ),
            fillOpacity: 0.4,
            zIndex: 1
          }}
        />
      );
    });
  }

  return (
    <QQMap
      className="fill mapqup mb-md"
      onLoad={map => {
        props.onMapLoad('map', map);
      }}
      map="zoneMap"
      events={{
        click: props.handleMapClick
      }}
      options={{
        center:
          getTencentCenterPoint(props.center) ||
          getTencentCenterPoint({ lat: 16.059959, lng: 108.224258 })
      }}
    >
      {props.activeZone && !props.polyline ? (
        <QQPolygon
          onLoaded={map => {
            console.log('---- add driver poly gon ----');
            props.onMapLoad('Polygon', map);
          }}
          path={props.activeZone.path.map(
            pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
          )}
          editable
          onMouseUp={props.onDragEnd}
          options={buildQQPolygonOption(true, true)}
          mapkey="Polygon"
        />
      ) : null}
      {props.activeZone && props.polyline ? (
        <QQPolyline
          onLoaded={map => {
            console.log('---- add driver polyline ----');
            props.onMapLoad('Polyline', map);
          }}
          events={{
            click: props.handlePolylineClick
          }}
          path={props.activeZone.path.map(
            pos => new window.qq.maps.LatLng(pos.lat, pos.lng)
          )}
          editable
          mapkey="Polyline"
          options={buildQQPolygonOption(true, true)}
        />
      ) : null}
      {parentZones}
      {otherAreasPolygon}
      {driverArea}
      {pickUpAreas}
    </QQMap>
  );
};
