import React, { PureComponent } from 'react';
import { Translate } from "react-redux-i18n";
import {
  OverlayTrigger
} from "react-bootstrap";

export default class RideSharingColumn extends PureComponent {
  render() {
    const { booking, lineShort } = this.props;
    return (
      <div>
        {booking.dispatchRideSharing ? (
          <div className={"ride-sharing"}>
            {booking.bookingRideSharing.length > 0 ? (
              <div className={`ride-sharing-matching ${lineShort ? " custom__lineShort" : ""}`}>
                <Translate value="cue.Matching" />
                {booking.bookingRideSharing.map(bookShare => {
                  if (bookShare) {
                    return (
                      <OverlayTrigger
                        placement="top"
                        delayShow={200}
                        overlay={
                          <p className="text-ellipsis">
                            {(bookShare.psgInfo.firstName || "") +
                              (bookShare.psgInfo.lastName || "")}
                            /{bookShare.bookId}
                          </p>
                        }
                      >
                        <p className="text-ellipsis">
                          {(bookShare.psgInfo.firstName || "") +
                            (bookShare.psgInfo.lastName || "")}
                          /{bookShare.bookId}
                        </p>
                      </OverlayTrigger>
                    );
                  }
                })}
              </div>
            ) : (
              <Translate value="cue.Unmatching" />
            )}
          </div>
        ) : (
          <div className={`sharing-none ${lineShort ? " custom__lineShort" : ""}`}>
            <p>---</p>
            <p>---</p>
          </div>
        )}
      </div>
    )
  }
}
