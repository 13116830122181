import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import * as settingActions from "./../../actions/settingActions";
import { Translate, I18n } from "react-redux-i18n";
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import {
  getCarTypeCorporate,
  updateSupplierCorporate
} from "../../actions/corporateAction";
import {
  FormGroup,
  FormControl,
  ButtonToolbar,
  Button,
  Form
} from "react-bootstrap";
import PropTypes from "prop-types";
import _ from "lodash";

class Supplier extends Component {
  constructor(props) {
    super();
    this.state = {
      valid: {},
      isSubmitted: false,
      pricing: {},
      rowHeight: 50,
      supplier: props.supplier ,
      companyList: []
    };
  }
  componentDidMount() {
    if (this.props.companyList) {
      let companySaved = this.props.supplier && this.props.supplier.value || []
      let newCompanyList = this.props.companyList.map(company => {
        if(companySaved.some(ob => ob === company._id)) {
          return {
            ...company,
            isChecked: true
          }
        }
        return {
          ...company,
          isChecked: false
        }
      })
      this.setState({ companyList: newCompanyList });
    }

  }

  handleChangeCheckbox = (e, id) => {
    console.log(e.target.value)
    let newCompanyList = this.state.companyList.map(ob => {
      if(ob._id === id) {
        ob.isChecked = e.target.checked
      }
      return ob
    })
    this.setState({
      companyList: newCompanyList
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pricing) {
      this.setState({
        pricing: nextProps.pricing,
        shouldRenderTable:
          nextProps.contentHeight &&
          nextProps.contentWidth &&
          nextProps.contentHeight !== this.props.contentHeight &&
          nextProps.contentWidth !== this.props.contentWidth
      });
    }
  }

  handleTypeChange = (e) => {
    this.setState({
      supplier: {
        ...this.state.supplier,
        type: e.target.value
      }
    })
  }

  handleSaveSupplierClick = () => {
    this.props.loadingBarActions.showLoadingSpiner();
    let companysActive = []
    if(this.state.supplier.type == 'customized') {
      this.state.companyList.map(company => {
        if(company.isChecked) companysActive.push(company._id)
      })
    }
    this.props
      .updateSupplierCorporate({
        _id: this.props.corporateId,
        fleetId: this.props.auth.selectedFleet.fleetId,
        supplier: {
          type: this.state.supplier.type,
          value: companysActive
        }
      })
      .then(data => {
        this.setState({ isSubmitted: false });
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          this.context.notification(
            "success",
            'Supplier updated successfully'
          );
          this.setState({
            supplier: data.res
          });
        } else {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        }
      });
  }

  checkDisableSaveButton = () => {
    const { supplier = {}, companyList = [] } = this.state
    if(
      supplier.type === 'customized'
      && !companyList.some((company) => company.isChecked)
    ) return true
    return false
  }

  render() {
      const hasPermission = this.props.permissions && this.props.permissions.actions
      const disableSave = this.checkDisableSaveButton()
      return (
        <div className="corp-pricing-wrapper content">
          <div
            className="radio-select-group supplierContainer"
            ref={node => (this.radioSelectGroup = node)}
          >
            <FormGroup>
              <Form.Label>
                <Translate value="corporate.Supplier" />
              </Form.Label>
              <FormControl
                as="select"
                className="form-control form-custom"
                value={this.state.supplier.type}
                onChange={this.handleTypeChange}
              >
                <option value={'all'}>
                  {I18n.t("map.All_company")}
                </option>
                <option value={'customized'}>
                  {I18n.t("generalSetting.Customized")}
                </option>
              </FormControl>
            </FormGroup>
            {
              this.state.supplier.type === 'customized' &&
              <div className="supplierCompany">
                {
                  this.state.companyList.map(company => {
                    let info = this.state.companyList.find((ob) => ob._id === company._id)
                    return (
                      <div key={company._id}  className="cb_rtl">
                        <CcCheckbox
                          className="checkbox-margin leftToRight"
                          checked={info.isChecked}
                          onChange={(e) => this.handleChangeCheckbox(e, company._id)}
                          text={company.name}
                          leftToRight
                        />
                      </div>
                    )
                  })
                }
              </div>
            }
            <ButtonToolbar className="text-center">
              {hasPermission ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.handleSaveSupplierClick}
                  disabled={disableSave}
                >
                  <Translate value="corporate.Save" />
                </Button>
              ) : null}
            </ButtonToolbar>
          </div>
        </div>
      );
  }
}
Supplier.contextTypes = {
  notification: PropTypes.func
};
Supplier.defaultProps = {
  supplier: {
    type: 'all',
    value: []
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : "en-US"
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getCarTypeCorporate: options => {
      return dispatch(getCarTypeCorporate(options));
    },
    updateSupplierCorporate: options => {
      return dispatch(updateSupplierCorporate(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Supplier);
