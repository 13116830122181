import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';

export default ({
  data,
  rowIndex,
  columnKey,
  width,
  height,
  customCell,
  className, // cell_contianer class
  textEllipsis, // text-inline, ellipsis
  noPadding, // cell_content nopadding
  tableHeight,
  totalRow,
  lineShort,
  settings,
  customCellAndEllipis
}) => {
  const ellipsis = textEllipsis ? { width: width + 'px' } : null;
  const hasDivider = className.split(' ').includes('column_divider');
  let bottomDistance = 0;

  if (hasDivider && rowIndex === totalRow - 1) {
    const expectHeight = totalRow * height;
    if (expectHeight < tableHeight) {
      bottomDistance = tableHeight - expectHeight - height;
    }
  }

  const customClassName = _.get(data[rowIndex], '_meta_.className') || '';

  let content = _.get(data[rowIndex], columnKey);
  content = content || content === 0 ? content : '';

  const colorCodeBooking = data[rowIndex].colorCodeBooking
    ? data[rowIndex].colorCodeBooking
    : '';

  return (
    <React.Fragment>
      <div
        className={
          'stickyTableCellContainer '
          + (textEllipsis ? 'text-ellipsis ' : '')
          + (className || '')
          + customClassName
        }
      >
        <div
          className="cellTable_row"
          style={{ backgroundColor: colorCodeBooking }}
        >
          <div className="cellTable_cell">
            <div
              className={
                'cell_content'
                + (lineShort ? ' lineShort' : '')
                + (noPadding ? ' no-padding' : '')
              }
              style={ellipsis}
            >
              {customCell ? (
                customCellAndEllipis ? (
                  <OverlayTrigger
                    placement="top"
                    delayShow={300}
                    overlay={(
                      <Tooltip id="tooltip">
                        <div>{customCell(data[rowIndex], rowIndex, settings)}</div>
                      </Tooltip>
                    )}
                  >
                    <div className="cursor-pointer text-ellipsis">{customCell(data[rowIndex], rowIndex, settings)}</div>
                  </OverlayTrigger>
                ) : (
                  customCell(data[rowIndex], rowIndex, settings)
                )
              ) : ellipsis ? (
                <OverlayTrigger
                  placement="top"
                  delayShow={300}
                  overlay={(
                    <Tooltip id="tooltip">
                      <div>{content}</div>
                    </Tooltip>
                  )}
                >
                  <div className="cursor-pointer text-ellipsis">{content}</div>
                </OverlayTrigger>
              ) : (
                content
              )}
            </div>
          </div>
        </div>
      </div>
      {bottomDistance ? (
        <div
          className="last-row-extend-divider column_divider"
          style={{ height: `${bottomDistance}px` }}
        />
      ) : null}
    </React.Fragment>
  );
};
