import React from 'react';
import { Form, FormGroup, FormControl, Button } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import PlacesAutocomplete from '../../../components/placesAutocomplete/PlacesAutocomplete';
import { heatMapMode, defaultHeatMapMode } from '../../../constants/commondata';
import DateTime from '../../../components/dateTime/DateTime';
import { filterZonesBasedPermission } from '../../../utils/commonFunctions';

const AutocompleteItem = ({ suggestion }) => (
  <div className="map-inner">
    <i className="fa fa-map-marker" />
    <span>{suggestion}</span>
  </div>
);
const myStyles = {
  // label: { color: "red" },
  input: { width: '100%' },
  autocompleteContainer: { padding: '5px 12px' },
  autocompleteItem: { color: '#e6e6e6', margin: '5px 0' },
  autocompleteItemActive: { color: '#04BE76' }
  // color: "#04BE76",
};

class HeatmapFilter extends React.PureComponent {
  state = {
    zone: null,
    viewMode: this.getViewMode(),
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day')
  };

  componentDidMount() {
    this.setState({
      finalZone: filterZonesBasedPermission(this.props.auth, this.props.commonData.mapZone, [...this.props.commonData.companies, ...this.props.commonData.suppliers])
    }, () => {
      this.setState({ zone: this.getDefaultZone() });
    })
  }

  getDefaultZone() {
    if (
      this.state.finalZone?.length > 0
    ) {
      let defaultMap = this.state.finalZone?.find(m => {
        return m?.isDefault && m?.isActive;
      });
      return _.get(defaultMap || this.state.finalZone?.[0], '_id');
    }
    return null;

    // const { mapZone } = this.props.commonData;
    // if (
    //   mapZone && mapZone.length > 0
    // ) {
    //   const defaultMap = this.props.commonData.mapZone.find(m => m.isDefault);
    //   return _.get(defaultMap, '_id');
    // }
    // return null;
  }

  getViewMode() {
    const { heatMapSetting } = this.props.fleet;
    return heatMapSetting.defaultMode || defaultHeatMapMode;
  }

  handleZoneChange = e => {
    this.setState({ zone: e.target.value });
  };

  handleViewModeChange = e => {
    this.setState({ viewMode: e.target.value });
  };

  handleDateStartDateChange = e => {
    this.setState({ startDate: moment(e._d).startOf('day'), endDate: moment(e._d).endOf('day') });
    // if (!this.state.endDate) {
    //   const today = new Date();
    //   this.setState({ endDate: today });
    // }
  };

  handleDateEndDateChange = e => {
    this.setState({ endDate: e._d });
  };

  handleChangeAddress = data => {
    this.setState({ address: data });
  };

  handleAddressRemoved = () => {
    if (window.searchPlaceMarked) {
      window.searchPlaceMarked.setMap(null);
    }
    this.setState({ address: '' });
  };

  handleSelectAddress = (data, placeName) => {
    this.setState({
      address: data
    });
    if (this.props.onSelectAddress) {
      this.props.onSelectAddress(data, placeName);
    }
  };

  isFilteredDayValid = current => !current.isAfter(moment());

  handleSubmit = () => {
    const { zone, viewMode, startDate, endDate } = this.state;
    this.props.onSubmit({ zone, viewMode, startDate, endDate });
  };

  render() {
    const zones = this.props.commonData.mapZone || [];
    const { viewMode } = this.state;
    const isDisabledDatepicker = ['requestedBookings', 'activePassengers'].indexOf(viewMode) !== -1;
    return (
      <Form
        inline
        onSubmit={e => {
          e.preventDefault();
        }}
        className="heatmapFilter"
      >
        <div className="heatmapFilter__container">
          <div className="heatmapFilter__formItem child-1">
            <div className="place-container">
              <PlacesAutocomplete
                value={this.state.address}
                onSelect={this.handleSelectAddress}
                onChange={this.handleChangeAddress}
                autocompleteItem={AutocompleteItem}
                styles={myStyles}
                placeholder={I18n.t('map.Enter_a_location')}
                onEnterKeyDown={this.handleSelectAddress}
                handleAddressRemoved={this.handleAddressRemoved}
              />
            </div>
          </div>
          <div className="heatmapFilter__formItem child-2">
            <FormControl
              className="form-custom"
              as="select"
              onChange={this.handleZoneChange}
              value={this.state.zone}
            >
              {this.state.finalZone?.map(z => {
                if (z?.display) {
                  return (
                    <option key={z._id} value={z._id}>
                      {z.zoneName}
                    </option>
                  );
                }
                return '';
              })}
            </FormControl>
          </div>
          <div className="heatmapFilter__formItem child-3">
            <FormControl
              className="form-custom"
              as="select"
              onChange={this.handleViewModeChange}
              value={this.state.viewMode}
            >
              {heatMapMode.map(o => (
                <option key={o.value} value={o.value}>
                  {I18n.t(o.label)}
                </option>
              ))}
            </FormControl>
          </div>
          <div className="heatmapFilter__formItem heatmapFilter_datetime child-4">
            <FormGroup className="mb0">
              <DateTime
                value={moment(this.state.startDate)}
                timeFormat={false}
                onChange={this.handleDateStartDateChange}
                inputProps={{
                  readOnly: true,
                  className: 'date-readonly form-control',
                  placeholder: I18n.t('report.query.fromDate'),
                  value: this.state.startDate
                    ? moment(this.state.startDate).format('L')
                    : '',
                  disabled: isDisabledDatepicker
                }}
                isValidDate={this.isFilteredDayValid}
                closeOnSelect
                disabled={isDisabledDatepicker}
              />
            </FormGroup>
          </div>
          <div className="heatmapFilter__formItem child-5">
            <Button
              className="btn-save btn btn-primary child-btn"
              onClick={this.handleSubmit}
              disabled={this.props.loading}
            >
              <Translate value="customer.View" />
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    fleet: state.auth.selectedFleet,
    commonData: state.commonData,
    permissions: state.menuHandle.modulePermission,
    menuHandle: state.menuHandle,
    language: state.i18n
  };
}

function mapDispatchToProps() {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeatmapFilter);
