import _ from 'lodash';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.bookingSummary.date',
    mutate: ReportUtils.formatTime,
    summary: 'totalBookings',
    summaryUnit: 'booking(s)'
  },
  {
    key: 'operator',
    label: 'report.result.bookingSummary.operator'
  },
  {
    key: 'username',
    label: 'report.result.bookingSummary.username'
  },
  {
    key: 'action',
    label: 'report.result.bookingSummary.action'
  },
  {
    key: 'bookingId',
    label: 'report.result.bookingSummary.bookingId'
  },
  {
    key: 'externalId',
    label: 'report.result.thirdPartyBooking.externalId',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) =>
      !_.get(selectedFleet, 'moduleSettings.thirdPartyBooking', false),
  },
];
