import ReportUtils from '../base/utils';
import * as _ from 'lodash';

const formatFare = ReportUtils.formatFare;

const onlyShowInNetworkTypes = (networkTypes) => (param, options) => {
  const lastNetworkType = _.get(options, 'lastQuery.criteria.bookingService');
  return networkTypes.indexOf(lastNetworkType) === -1;
};

const genConversionLink = (key, keyInUSD) => {
  keyInUSD = keyInUSD || key + 'InUSD';
  return {
    name: 'showConversionModal',
    description: 'Show conversion info',
    isNotShow: (doc) => !doc[keyInUSD],
    props: (doc) => ({
      title: 'report.result.affiliation.' + key,
      bookId: doc.bookingId,
      original: doc[key],
      conversion: doc[keyInUSD],
      currencyISO: doc.currencyISO,
    }),
  };
};

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.affiliation.bookingId',
    rowSpan: 2,
    link: {
      newTab: true,
      href: '/reports/Booking_details',
      name: 'goToBookingDetails',
      params: ['bookingId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Show report booking details of this booking',
      isNotShow: (doc) => {
        return doc.bookingType !== 'Farm in';
      },
    },
  },
  {
    key: 'bookingType',
    label: 'report.result.affiliation.bookingType',
    rowSpan: 2,
  },
  {
    key: 'date',
    label: 'report.result.affiliation.date',
    rowSpan: 2,
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingStatus',
    label: 'report.result.affiliation.bookingStatus',
    rowSpan: 2,
    width: 220,
  },
  {
    key: 'paidBy',
    label: 'report.result.affiliation.paidBy',
    rowSpan: 2,
    borderRight: true,
    width: 220,
  },
  {
    key: 'totalInUSD',
    label: 'report.result.affiliation.totalInUSD',
    rowSpan: 2,
    borderRight: true,
    width: 120,
    mutate: formatFare,
    summaryMutate: formatFare,
    summary: 'totalInUSD',
    isNotShow: onlyShowInNetworkTypes([
      'roaming',
      'provideService',
      'homeFleet',
    ]),
    link: genConversionLink('totalFare', 'totalInUSD'),
  },
  {
    key: 'receivableInUSD',
    label: 'report.result.affiliation.receivableInUSD',
    rowSpan: 2,
    borderRight: true,
    width: 120,
    mutate: formatFare,
    summaryMutate: formatFare,
    summary: 'receivableInUSD',
    isNotShow: onlyShowInNetworkTypes(['provideService']),
    link: genConversionLink('receivable'),
  },
  {
    key: 'buyerCommissionInUSD',
    label: 'report.result.affiliation.buyerCommissionInUSD',
    rowSpan: 2,
    inlineCurrency: true,
    mutate: (v) => `${(v || 0).toFixed(2)}`,
    summary: 'buyerCommissionInUSD',
    summaryMutate: (v) => `$${v.toFixed(2)}`,
    isNotShow: onlyShowInNetworkTypes(['roaming']),
    link: genConversionLink('buyerCommission'),
  },
  {
    key: 'fleetMarkupInUSD',
    label: 'report.result.affiliation.fleetMarkupInUSD',
    rowSpan: 2,
    inlineCurrency: true,
    summary: 'fleetMarkupInUSD',
    mutate: formatFare,
    summaryMutate: formatFare,
    isNotShow: onlyShowInNetworkTypes(['homeFleet']),
    link: genConversionLink('fleetMarkup'),
  },
  {
    key: 'payableInUSD',
    label: 'report.result.affiliation.payableInUSD',
    rowSpan: 2,
    inlineCurrency: true,
    mutate: formatFare,
    summaryMutate: formatFare,
    summary: 'payableInUSD',
    isNotShow: onlyShowInNetworkTypes(['homeFleet']),
    link: genConversionLink('payable'),
  },
  {
    key: 'paymentMethod',
    label: 'report.result.affiliation.paymentMethod',
    isNotShow: onlyShowInNetworkTypes(['homeFleet', 'provideService']),
  },
  {
    key: 'paymentStatus',
    label: 'report.result.affiliation.paymentStatus',
    isNotShow: onlyShowInNetworkTypes(['homeFleet', 'provideService']),
  },
];
