import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n';
import { I18n } from 'react-redux-i18n';
import { Form } from 'react-bootstrap';

export default function DisplayItemize({ itemData }) {
  if(typeof itemData.items == 'undefined') return null
  return (
    <div className="orderItemPackage">
      <Form.Label>
        <Translate value={itemData.title} />
      </Form.Label>
      {
        itemData.items.map((item, id) => (  
          <div key={id} className="rowInfoPackage-item">
            <span className="RIPLeft">{item.title}</span>
            <span className="RIPRight">{item.value}</span>
          </div>
        ))
      }
    </div>
  )
}

DisplayItemize.prototype = {
  title : PropTypes.string,
  items  : PropTypes.array
}

DisplayItemize.defaultProps = {
  itemData: {}
}
