import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import BaseReport from '../base';
import PropTypes from 'prop-types';
import * as fieldMappings from './fields';
import {
    Modal, FormGroup, FormControl, Button, Form
} from 'react-bootstrap';
import {
    payoutToMerchantApi,
    reportPayoutToMerchantApi,
    invalidBankAccountApi
} from '../../../constants/ApiConfigs';
import ReportUtils from './../base/utils'
import * as loadingBarActions from '../../../actions/loadingBarActions';
import * as reportActions from '../../../actions/reportActions';
import * as commonData from '../../../constants/commondata';
import { socketDispatchApi } from '../../../utils/socketUtils';
import { socketConfigs } from '../../../constants/socketConfigs';

let newWindow = null;
let timer = null;
class PayToMerchantReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmailInputModal: false,
            emailString: '',
            validEmail: true,
            merchants: 0,
            minPayout: 0,
            holdAmount: 0,
            currency: '',
            total: 0,
            data: {},
            payoutDate: ''
        };
    }

    checkChild = () => {
        if (newWindow.closed) {
            // console.log("Child window closed");
            this.props.loadingBarActions.hideLoadingSpiner();
            socketDispatchApi.remove(socketConfigs.receive.payout.dnbResult);
            clearInterval(timer);
        }
    }

    payToAllMerchant = e => {
        const self = e;
        const data = _.cloneDeep(e.state);
        data.isPayAll = true;
        data.operatorId = e.props.user._id;
        const merchants = data.summary.totalMerchant;
        const totalAvailablePayout = data.summary.totalPayoutAmount;
        this.setState({
            showEmailInputModal: true,
            merchants: merchants,
            minPayout: data.queryBody.criteria.minPayout,
            holdAmount: data.queryBody.criteria.holdAmount,
            currency: data.queryBody.criteria.currency,
            total: totalAvailablePayout,
            data: data,
            self: self,
            emailString: e.props.user.email
        });
    };

    payToMerchants = e => {
        console.log("e.state.rowCheckBoxes ", e.state.rowCheckBoxes)
        if (
            _.isEmpty(e.state.rowCheckBoxes)
            || (e.state.rowCheckBoxes[0] === 'all' && e.state.rowCheckBoxes.length === 1)
        ) {
            e.setState({
                confirm: {
                    title: I18n.t('report.result.payoutToMerchant.selectMerchant.title'),
                    body: I18n.t('report.result.payoutToMerchant.selectMerchant.body')
                }
            });
        } else {
            const self = e;
            const data = _.cloneDeep(e.state);
            data.isPayAll = false;
            data.operatorId = e.props.user._id;
            const listCheck = data.list
                .filter(doc => data.rowCheckBoxes.indexOf(doc.merchantId) > -1);
            const merchants = _.map(listCheck, doc => doc.merchantId);
            const totalAvailablePayout = _.sumBy(listCheck, doc => doc.payoutAmount);
            this.setState({
                showEmailInputModal: true,
                merchants: merchants.length,
                minPayout: data.queryBody.criteria.minPayout,
                holdAmount: data.queryBody.criteria.holdAmount,
                currency: data.queryBody.criteria.currency,
                total: totalAvailablePayout,
                data: data,
                self: self,
                emailString: e.props.user.email
            });
        }
    }
    validBankAccount = e => {
        if (
            _.isEmpty(e.state.rowCheckBoxes)
            || (e.state.rowCheckBoxes[0] === 'all' && e.state.rowCheckBoxes.length === 1)
        ) {
            e.setState({
                confirm: {
                    title: I18n.t('report.result.payoutToMerchant.selectMerchant.title'),
                    body: I18n.t('report.result.payoutToMerchant.selectMerchant.body')
                }
            });
        } else {
            const self = e;
            const data = _.cloneDeep(e.state);
            data.action = 'remove';
            data.operatorId = e.props.user._id;
            const payoutData = data.list
                .filter(doc => data.rowCheckBoxes.indexOf(doc.merchantId) > -1).map(doc => doc.merchantId);
            this.props.reportActions
                .payoutToDriver(invalidBankAccountApi, {
                    fleetId: data.lastQuery.fleetId,
                    requestBody: {
                        listMerchant: payoutData,
                        type: "merchant",
                        operatorId: data.operatorId,
                        fleetId: data.lastQuery.fleetId,
                        action: data.action,
                        payoutDate: data.lastQuery.criteria.payoutDate,
                    }
                })
                .then(response => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    if (response.res) {
                        setTimeout(() => {
                            self.fetchReportData({ payoutDate: "" });
                            self.handleSearchDriver('', null, true);
                        }, 1 * 1000);
                        this.context.notification(
                            'success',
                            I18n.t(`report.result.payoutToMerchant.HasBeenUpdateAsValid`)
                        );

                    } else {
                        let message = I18n.t(`errors.${response.error.errorCode}`);
                        this.context.notification('error', message);
                    }
                })
                .catch(error => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    this.context.notification('error', error.toString());
                });
        }
    }
    invalidBankAccount = e => {
        if (
            _.isEmpty(e.state.rowCheckBoxes)
            || (e.state.rowCheckBoxes[0] === 'all' && e.state.rowCheckBoxes.length === 1)
        ) {
            e.setState({
                confirm: {
                    title: I18n.t('report.result.payoutToMerchant.selectMerchant.title'),
                    body: I18n.t('report.result.payoutToMerchant.selectMerchant.body')
                }
            });
        } else {
            const self = e;
            const data = _.cloneDeep(e.state);
            data.action = 'add';
            data.operatorId = e.props.user._id;
            const payoutData = data.list
                .filter(doc => data.rowCheckBoxes.indexOf(doc.merchantId) > -1).map(doc => doc.merchantId);
            this.props.reportActions
                .payoutToDriver(invalidBankAccountApi, {
                    fleetId: data.lastQuery.fleetId,
                    requestBody: {
                        listMerchant: payoutData,
                        type: "merchant",
                        operatorId: data.operatorId,
                        fleetId: data.lastQuery.fleetId,
                        action: data.action,
                        payoutDate: data.lastQuery.criteria.payoutDate,
                    }
                })
                .then(response => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    if (response.res) {
                        setTimeout(() => {
                            self.fetchReportData({ payoutDate: "" });
                            self.handleSearchDriver('', null, true);
                        }, 1 * 1000);
                        this.context.notification(
                            'success',
                            I18n.t(`report.result.payoutToMerchant.HasBeenUpdateAsInvalid`)
                        );

                    } else {
                        let message = I18n.t(`errors.${response.error.errorCode}`);
                        this.context.notification('error', message);
                    }
                })
                .catch(error => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    this.context.notification('error', error.toString());
                });
        }
    }

    handleChangeEmailInput = e => {
        this.setState({ emailString: e.target.value });
        let emails = e.target.value
            .trim()
            .split(/[,;]/)
            .map(email => email.toLowerCase().trim());
        emails = _.compact(emails);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        this.setState({
            validEmail: emails.every(email => emailRegex.test(email))
        });
    };
    sendReceipt() {
        let emails = this.state.emailString
            .trim()
            .split(/[,;]/)
            .map(email => email.toLowerCase().trim());
        emails = _.compact(emails);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const validEmail = emails.every(email => emailRegex.test(email));
        const data = this.state.data;
        if (validEmail) {
            const payoutData = data.list
                .filter(doc => data.rowCheckBoxes.indexOf(doc.merchantId) > -1).map(doc => doc.merchantId);
            this.props.loadingBarActions.showLoadingSpiner();
            this.props.reportActions
                .payoutToDriver(payoutToMerchantApi, {
                    fleetId: data.lastQuery.fleetId,
                    requestBody: {
                        listMerchant: payoutData,
                        operatorId: data.operatorId,
                        fleetId: data.lastQuery.fleetId,
                        minPayoutAmount: data.lastQuery.criteria.minPayout,
                        holdAmount: data.lastQuery.criteria.holdAmount,
                        currencyISO: data.lastQuery.criteria.currency,
                        payoutDate: data.lastQuery.criteria.payoutDate,
                        isPayAll: data.isPayAll,
                        timezone: data.timezone,
                        email: this.state.emailString
                    }
                })
                .then(response => {
                    if (response.res) {
                        if (response.res.data && response.res.data.response && response.res.data.response.url) {
                            newWindow = window.open(response.res.data.response.url, 'popup', 'width=500,height=500');
                            timer = setInterval(this.checkChild, 500);
                            socketDispatchApi.remove(socketConfigs.receive.payout.dnbResult);
                            socketDispatchApi.on(socketConfigs.receive.payout.dnbResult, dataPayout => {
                                this.props.loadingBarActions.hideLoadingSpiner();
                                this.setState({
                                    showEmailInputModal: false,
                                    emailString: '',
                                    validEmail: true
                                });
                                newWindow.close();
                                if (dataPayout.status === "success") {
                                    this.context.notification(
                                        'success',
                                        I18n.t('report.result.payoutToMerchant.selelctedMerchantHasBeenPaid')
                                    );
                                    this.state.self.fetchReportData({ payoutDate: "" });
                                } else {
                                    this.context.notification(
                                        'error',
                                        I18n.t('report.result.payoutToMerchant.selelctedMerchantHasBeenFailed')
                                    );
                                }
                            });
                        } else {
                            this.context.notification(
                                'success',
                                I18n.t(`report.result.payoutToMerchant.selelctedMerchantHasBeenPaid`)
                            );
                            this.setState({
                                showEmailInputModal: false,
                                emailString: '',
                                validEmail: true
                            }, () => {
                                this.props.loadingBarActions.hideLoadingSpiner();
                                this.state.self.fetchReportData({ payoutDate: "" });
                            });
                        }
                    } else {
                        this.props.loadingBarActions.hideLoadingSpiner();
                        this.setState({
                            showEmailInputModal: false,
                            emailString: '',
                            validEmail: true
                        });
                        let message = I18n.t(`errors.${response.error.errorCode}`);
                        this.context.notification('error', message);
                    }
                })
                .catch(error => {
                    this.props.loadingBarActions.hideLoadingSpiner();
                    this.setState({
                        showEmailInputModal: false,
                        emailString: '',
                        validEmail: true
                    });
                    this.context.notification('error', error.toString());
                });
        }
    }
    render() {
        let actions = null;
        const { permissions = null, auth: { user } = {}, selectedFleet } = this.props || {};
        const listFieldMappings = _.extend({}, fieldMappings);
        const listFields = _.filter(fieldMappings.fields, field => field.key != 'actions');
        let drvPayoutGateway = _.get(selectedFleet, 'drvPayout.gateway')
        var exportPermission = false;
        if (user.isAdmin) {
            if (this.props.auth.user.roles && this.props.auth.user.roles.moduleAdmin && this.props.auth.user.roles.moduleAdmin.actionCC) {
                actions = {
                    payToMerchants: this.payToMerchants,
                    payToAllMerchant: this.payToAllMerchant,
                    invalidBankAccount: this.invalidBankAccount,
                    validBankAccount: this.validBankAccount,
                };
                exportPermission = true;
            }
        } else if (!this.props.permissions || this.props.permissions.actions) {
            actions = {
                payToMerchants: this.payToMerchants,
                payToAllMerchant: this.payToAllMerchant,
                invalidBankAccount: this.invalidBankAccount,
                validBankAccount: this.validBankAccount,
            };
            exportPermission = this.props.permissions && this.props.permissions.export ? this.props.permissions.export : false;
        } else {
            listFieldMappings.fields = listFields;
            exportPermission = this.props.permissions && this.props.permissions.export ? this.props.permissions.export : false;
        }
        const emailModal = (
            <Modal
                show={this.state.showEmailInputModal}
                backdrop="static"
                bsSize="lg"
                dialogClassName="logs-modal send-receipt-modal"
                onHide={() => this.setState({
                    showEmailInputModal: false,
                    emailString: '',
                    validEmail: true,
                })
                }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18n.t('Sidebar.payout.Pay_To_Merchant').toUpperCase()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Translate
                        value="report.result.payoutToMerchant.confirmPay.body"
                        merchants={this.state.merchants}
                        currency={this.state.currency}
                        min={this.state.minPayout}
                        hold={this.state.holdAmount}
                        total={ReportUtils.numberFormat(this.state.total)}
                        dangerousHTML
                    />
                    <FormGroup className="mb-lg">
                        <Form.Label>
                            <Translate value="report.result.payoutToMerchant.sendPayout" />:
                        </Form.Label>
                        <FormControl
                            type="text"
                            value={this.state.emailString}
                            onChange={this.handleChangeEmailInput}
                            placeholder="email1@email.com;email2@email.com"
                            className="form-custom"
                        />
                        {!this.state.validEmail && this.state.emailString ? (
                            <div className="text-remove mt">
                                <Translate value="report.query.errorMessage.invalidEmail" />
                            </div>
                        ) : null}
                    </FormGroup>
                    <div className="text-center">
                        <Button
                            className="btn-send mr-lg"
                            disabled={!this.state.validEmail || !this.state.emailString}
                            onClick={() => this.sendReceipt.call(this)}
                        >
                            <Translate value="report.result.payoutToMerchant.pay" />
                        </Button>
                        <Button
                            className="btn-cancel"
                            onClick={() => {
                                this.setState({
                                    showEmailInputModal: false,
                                    emailString: '',
                                    validEmail: true
                                });
                            }}
                        >
                            <Translate value="report.result.bookingDetails.cancel" />
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
        return (
            <div className="content">
                {emailModal}
                <BaseReport
                    tittle="Pay to merchants"
                    fieldMappings={listFieldMappings}
                    apiurl={reportPayoutToMerchantApi}
                    filterDate
                    // company
                    // driver
                    storeMerchant
                    customViewButton
                    actions={actions}
                    payoutOption={commonData.payoutMerchantOptions}
                    minPayout
                    holdAmount
                    currency
                    noexport={!exportPermission}
                    payoutDate
                    gateway={drvPayoutGateway}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProp) {
    return {
        selectedFleet: state.auth.selectedFleet,
        user: state.auth.user,
        permissions: state.menuHandle.modulePermission,
        auth: state.auth,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch)
    };
}
PayToMerchantReport.contextTypes = {
    notification: PropTypes.func
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayToMerchantReport);
