import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import RadioButton from '../../../components/radioButton/radio';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import * as newbookingActions from '../../../actions/newbookingAction';
import moment from 'moment';
import DriverSelect from './DriverSelect';

const statusesCanAssign = ["confirmed", "pre", "action", "otwMerchant", "collecting", "arrived", "booked"];
class DispatchDriver extends PureComponent {
  state = {
  };

  handleChangeDispatchType = (e) => {
    this.props.onChange(parseInt(e.target.value));
    if (parseInt(e.target.value) === 0) {
      this.props.onChangeDrvInfo({
        phone: "",
        fullName: "",
      })
    }
  };

  render() {
    const { dispatchType, request, drvInfo, valid, common, onChangeDrvInfo, validatorCallback, isSubmited, status } = this.props;
    const { promoCode } = this.state;
    let isDispatchDisable = true;
    // if (status == "confirmed" || status == "pre" || status == "action") {
    //   isDispatchDisable = false;
    // }
    if (status) {
      isDispatchDisable = !statusesCanAssign.some(s => s === status);
    }

    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.DISPATCH" />
        </FormGroupTitle>
        <FormGroup>
          <FormGroup className="radio-button-group">
            <RadioButton
              text={I18n.t('newbooking.Auto_dispatch')}
              value={0}
              onChange={this.handleChangeDispatchType}
              checked={dispatchType === 0}
              name="Auto_dispatch"
              disabled={isDispatchDisable}
            />
            <RadioButton
              text={I18n.t('newbooking.Assign_driver')}
              value={1}
              name="Auto_dispatch"
              onChange={this.handleChangeDispatchType}
              checked={dispatchType === 1}
              disabled={isDispatchDisable}
            />

          </FormGroup>
          <DriverSelect
            request={request}
            drvInfo={drvInfo}
            dispatchType={dispatchType}
            onChangeDrvInfo={onChangeDrvInfo}
            isBookDetail={true}
            isSubmited={isSubmited}
            validatorCallback={validatorCallback}
            valid={valid}
            isDispatchDisable={isDispatchDisable}
          />
        </FormGroup>
      </Fragment>
    );
  }
}

DispatchDriver.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    common: state.intercityBooking.common,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DispatchDriver);
