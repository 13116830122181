import {
  notificationGetAllApi,
  notificationMarkedAllAsViewedApi,
  notificationMarkedItemAsReadApi,
  notificationDeleteApi,
  sosReadApi,
  sosDeleteApi,
  sosGetAllApi
} from '../constants/ApiConfigs'

import {
  callApi,
  UrlBuilder
} from "../utils/apiUtils";

export const LOAD_ALL_NOTIFICATION = 'LOAD_ALL_NOTIFICATION'

function notificationgetAllSuccess(data) {
  return {
    type: LOAD_ALL_NOTIFICATION,
    data: data
  }
}

export function notificationgetAll(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationGetAllApi,
    config,
    null,
    notificationgetAllSuccess,
    null,
    isLoading
  );
}

export function notificationmarkAllAsViewed(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationMarkedAllAsViewedApi,
    config,
    null,
    null,
    null,
    false
  );
}
export function notificationMarkItemAsRead(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationMarkedItemAsReadApi,
    config,
    null,
    null,
    null,
    false
  );
}
export function notificationDeleteItem(options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    notificationDeleteApi,
    config,
    null,
    null,
    null,
    false
  );
}

export function sosGetAll(options = {}, isLoading = true) {
  const config = {
    method: "get"
  };

  return callApi(
    UrlBuilder(sosGetAllApi, options),
    config,
    null,
    null,
    null,
    isLoading
  );
}

export function sosDelete(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: "delete",
    body: JSON.stringify(options)
  };

  return callApi(
    sosDeleteApi,
    config,
    null,
    null,
    null,
    isLoading
  );
}

