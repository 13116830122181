import React, { Component } from "react";
import "./ccCheckbox.scss";
import QuestionCircleTooltip from '../../components/questionCircleTooltip/QuestionCircleTooltip';
class CcCheckbox extends Component {
  constructor(props) {
    super(props);
  }

  labelClick = (e) => {
    if(this.props.hasStopPropagation) {
      e.stopPropagation();
    }
  }

  checkBoxOnChange = (e) => {
    if (typeof this?.props?.onChange === 'function') {
      this.props.onChange(e)
    }
  }
  
  handleOnclick = (e) => {
    if(this.props.hasStopPropagation) {
      e.stopPropagation();
    }
    return
  }

  render() {
    const {
      disabled,
      id,
      onChange,
      className,
      inputClassName,
      labelClassName,
      checked,
      text,
      children,
      name,
      value,
      hasTooltip = false,
      tooltipText,
      note
    } = this.props;
    let htmlId = id || Math.random().toString();
    return (
      <div
        className={
          "form-group cc-checkbox-wrapper " +
          (className || "") +
          (disabled ? " disabled" : "")
        }
      >
        {" "}
        <input
          type="checkbox"
          onChange={this.checkBoxOnChange}
          onClick={this.handleOnclick}
          checked={checked}
          disabled={disabled}
          name={name}
          value={value}
          className={"cc-checkbox " + (inputClassName || "")}
          id={htmlId}
        />
        <label htmlFor={htmlId} className={labelClassName || ""} onClick={ this.labelClick }>
          {children ? children: text}
          <i className="fa fa-check checked-icon" />
          {
            note && (
              <span className="noteCb" style={{ display: 'block', color: '#A1A1AA', paddingLeft: '27px'}}>
                {note}
              </span>
            )
          }
          {
            hasTooltip &&
            <QuestionCircleTooltip
              text={tooltipText}
            />
          }
        </label>
      </div>
    );
  }
}

CcCheckbox.defaultProps = {
  onChange: () => {}, // Provide a default function
};

export default CcCheckbox;
