import { operationAutocompleteApi, operationAutocompleteAffiliateApi, operationAutocompleteMultiBookApi } from '../constants/ApiConfigs'
import {
  callApi
} from "../utils/apiUtils";

export const DRIVER_AUTOCOMPLETE_REQUEST = "DRIVER_AUTOCOMPLETE_REQUEST";
export const DRIVER_AUTOCOMPLETE_SUCCESS = "DRIVER_AUTOCOMPLETE_SUCCESS";
export const DRIVER_AUTOCOMPLETE_FAILURE = "DRIVER_AUTOCOMPLETE_FAILURE";

function getDriverAutocompleteRequest() {
  return {
    type: DRIVER_AUTOCOMPLETE_REQUEST
  }
}

function getDriverAutocompleteSuccess(data) {
  return {
    type: DRIVER_AUTOCOMPLETE_SUCCESS,
    data: data.res.list
  }
}

function getDriverAutocompleteFailure(error) {
  return {
    type: DRIVER_AUTOCOMPLETE_FAILURE,
    error
  }
}
export function driverAutocomplete(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    operationAutocompleteApi,
    config,
    getDriverAutocompleteRequest(),
    getDriverAutocompleteSuccess,
    getDriverAutocompleteFailure
  );
}

export function driverAutocompleteMultiBook(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    operationAutocompleteMultiBookApi,
    config,
    getDriverAutocompleteRequest(),
    getDriverAutocompleteSuccess,
    getDriverAutocompleteFailure
  );
}

export function driverAutocompleteAffiliate(options) {
  options = Object.assign({}, options);
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };

  return callApi(
    operationAutocompleteAffiliateApi,
    config,
    getDriverAutocompleteRequest(),
    getDriverAutocompleteSuccess,
    getDriverAutocompleteFailure
  );
}