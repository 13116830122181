import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import Settings from './Settings';
import ServiceFee from './ServiceFee';
import DefaultTopupValues from './DefaultTopupValues';
import FleetCommission from './FleetCommission';
import { I18n } from 'react-redux-i18n';
import * as settingActions from '../../../actions/settingActions';
import { distanceConvert } from '../../../utils/commonFunctions'
import _ from 'lodash'
import '../settings.scss';
import { i } from 'react-dom-factories';

class GeneralSetting extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { auth: { selectedFleet } = {} } = this.props || {};
    const { fleetId, driverDepositConfig, paxCreditWalletConfig } = selectedFleet || {};

    const { enable: driverCreditWallet = false } = driverDepositConfig || {};
    const { enable = false, viaCredit = false, viaWallet = false } = paxCreditWalletConfig || {};
    const passengerCreditWallet = enable & (viaCredit || viaWallet);
    const differentFleetCommission = selectedFleet.generalSetting && selectedFleet.generalSetting.differentFleetCommission;
    this.props.settingActions.fleetInfo({ fleetId: fleetId }).then(data => {
      if (data.fleet && data.fare) {
        if (!data.fleet.SOSNumber) {
          data.fleet.SOSNumber = {
            zoneNumber:
              data.fleet.zones &&
              data.fleet.zones.map(zone => {
                return {
                  zoneId: zone._id,
                  zoneName: zone.zoneName,
                  phone: ''
                };
              }),
            defaultNumber: ''
          };
        }
        let limitRadiusArriveValue = _.get(data, 'fare.limitRadiusDriverCanArriveAndPickup.value', 0)
        if( limitRadiusArriveValue > 0 ) {
          const unitDistance = _.get(selectedFleet, 'unitDistance', 'km')
          data.fare.limitRadiusDriverCanArriveAndPickup.value = distanceConvert(limitRadiusArriveValue, unitDistance)
        }

        if (data.fare.noShow) {
          if (
            !data.fare.noShow.countdownDuration ||
            data.fare.noShow.countdownDuration < 1
          ) {
            data.fare.noShow.countdownDuration = 1;
          }
        }
        this.setState({
          data,
          driverCreditWallet,
          passengerCreditWallet,
          differentFleetCommission
        });
      } else if (data.error) {
        if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Load_general_fail')
          );
        }
      }
    });
  }

  render() {
    const { data,
      driverCreditWallet,
      passengerCreditWallet,
      differentFleetCommission
    } = this.state;
    if (!data) return null;
    return (
      <div className="content custom-form overflow-auto general-setting">
        <Tabs
          id="tab"
          defaultActiveKey={0}
          animation={false}
          className="cue-tabs"
        >
          <Tab eventKey={0} key={0} title={I18n.t('generalSetting.settings')}>
            <Settings formData={data.fare} fleet={data.fleet} />
          </Tab>
          <Tab
            eventKey={1}
            key={1}
            title={I18n.t('generalSetting.service_fees')}
          >
            <ServiceFee formData={data.fare} fleet={data.fleet} />
          </Tab>
          {differentFleetCommission ? (
            <Tab
              eventKey={2}
              key={2}
              title={I18n.t('generalSetting.commission_fleet')}
            >
              <FleetCommission formData={data.fare} fleet={data.fleet} />
            </Tab>
          ) : null}
          {driverCreditWallet || passengerCreditWallet ? (
            <Tab
              eventKey={3}
              key={3}
              title={I18n.t('generalSetting.Default_Topup_Values')}
            >
              <DefaultTopupValues
                fleet={data.fleet}
                driverCreditWallet={driverCreditWallet}
                passengerCreditWallet={passengerCreditWallet}
              />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSetting);
