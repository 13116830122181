export const Columns = [
  {
    key: 'fullNameSort',
    label: 'customer.Name',
    sortName: 'fullNameSort',
    fixed: true
  },
  {
    key: 'phone',
    label: 'customer.Phone_Number',
    sortName: 'phone'
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID'
  },
  {
    key: 'email',
    label: 'customer.Email',
    sortName: 'email',
    width: 250,
    textEllipsis: true
  },
  {
    key: 'rank',
    label: 'customer.VIP',
    sortName: 'rank',
    width: 100
  },
  {
    key: 'passengerInfo.companies',
    label: 'customer.companies',
    textEllipsis: true
  },
  {
    key: 'passengerInfo.corporateName',
    label: 'customer.Corporate_Name',
    textEllipsis: true
  },
  {
    key: 'registerFrom',
    label: 'customer.Registered_From',
    sortName: 'registerFrom'
  },
  {
    key: 'createdDate',
    label: 'customer.Registration_Date',
    sortName: 'createdDate'
  },
  {
    key: 'lastLogin.iOSVersion',
    label: 'customer.IOS_Last_Login_Version',
    sortName: 'lastLogin.iOSVersion',
    width: 250
  },
  {
    key: 'lastLogin.androidVersion',
    label: 'customer.Android_Last_Login_Version',
    sortName: 'lastLogin.androidVersion',
    width: 250
  },
  {
    key: 'lastActive',
    label: 'customer.Last_Active_Date'
  },
  {
    key: 'DaySincelastActive',
    label: 'customer.Days_Since_Last_Active'
  },
  {
    key: 'verifications',
    label: 'customer.Verification'
  },
  {
    key: 'booking.completed',
    label: 'customer.Completed_Bookings'
  },
  {
    key: 'loyalty.points.available',
    label: 'customer.Points'
  },
  {
    key: 'paxWallet',
    label: 'customer.Credit_balance'
  },
  {
    key: 'outStanding',
    label: 'customer.Outstanding_amount'
  },
  {
    key: 'referralCode',
    label: 'customer.referral_code'
  },
  // {
  //   key: 'migrationStatus',
  //   label: 'customer.Migration_Status',
  //   align: 'right',
  //   cellClass: 'table-actions',
  //   width: 150
  // },
  {
    key: 'customerStatus',
    label: 'customer.Status',
    width: 100,
    sortName: 'isActive'
  },
  {
    key: 'actions',
    label: 'customer.Actions',
    align: 'right',
    cellClass: 'table-actions',
    width: 100
  }
];

export const CustomerActionMenuItem = {
  Active: [{ label: 'customer.Edit', eventKey: 0 }, { label: 'customer.Deactivate', eventKey: 1 }],
  Inactive: [{ label: 'customer.Edit', eventKey: 0 }, { label: 'customer.Activate', eventKey: 1 }]
};
