import React from 'react';
import styles from '../styles/headerGr.module.scss';

const HeaderGr = (props) => {
  const { grRoute, title } = props;
  const [module, action] = grRoute;

  return (
    <div className={styles['grTitle']}>
      <div className={styles['grText']}>
        <div className={styles['linkText']}>
          <div
            className={styles['module']}
          >
            {module}
          </div>
        </div>
        {action && (
          <div className={styles['linkText']}>
            <div>{'>'}</div>
            <div
              className={styles['action']}
            >
              {action} {module}
            </div>
          </div>
        )}
      </div>
      {title && <div className={styles['title']}>{title}</div>}
    </div>
  );
};

export default HeaderGr;
