import React, { Component } from 'react';
import { Translate, I18n } from "react-redux-i18n";
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    ButtonToolbar,
    Modal,
    Tab,
    Tabs,
    OverlayTrigger,
    Tooltip,
    ButtonGroup
} from "react-bootstrap";
import {
    CCLiteCommonFunc,
    Validation,
    textboxNumberHelper
} from "../../../../../utils/commonFunctions";
import {
    deliveryTypeFare
} from "../../../../../constants/commondata";
import { Validator, ValidCase, WarningFeeInput } from "../../../../../components/validator";


class DistanceType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid:{}
        }
    }

    render() {
        const { isSubmmitted, editable, rateInfo, selectedFleet, handleInputChange, keyValue } = this.props;
        const { valid } = this.state;
        return (
            <Col xs={12} md={12}>
                <FormGroup>
                    <select
                        className="form-control form-custom"
                        onChange={e => {
                            handleInputChange(keyValue, e);
                        }}
                        value={rateInfo[keyValue]}
                        disabled={!editable}
                    >
                        {deliveryTypeFare.map(item => {
                            let newLabel = item.value === 'distance' ? (selectedFleet.unitDistance === 'km' ? item.label + '_km' : item.label + '_mile') : item.label;
                            return (
                                <option value={item.value} key={item.value}>
                                    {I18n.t(newLabel)}
                                </option>
                            );
                        })}
                    </select>
                </FormGroup>
            </Col>
        )
    }
}


export default DistanceType;
