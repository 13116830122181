import { SERVICE_FEE_FAILURE, SERVICE_FEE_SUCCESS } from '../actions/serviceFeeActions';
import _ from 'lodash'

export default function serviceFee(state={},action={}){
	switch(action.type){
		case SERVICE_FEE_SUCCESS:{
			return Object.assign(state, {
				list: action.data || [],
				fleetFareIdByZone: getTrueKeys(action.data)
			});
		}
		case SERVICE_FEE_FAILURE:{
			return state;
		}
		default: return state;
	}
}

function getTrueKeys(arr) {
  return arr.reduce((acc, obj) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] === true) {
        acc[key] = true;
      }
    });
    return acc;
  }, {});
}
