import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Row,
  Col
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';

import {
  Validation,
  textboxNumberHelper
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { heatMapMode, defaultHeatMapMode } from '../../../../constants/commondata';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class HeatMapFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangeDefaultMode = e => {
    this.props.handleUpdateFleet('heatMapSetting.defaultMode', e.target.value);
  };

  handleChangeRadius = e => {
    this.props.handleUpdateFleet('heatMapSetting.radius', e.target.value);
  };

  handleChangeIntensity = e => {
    this.props.handleUpdateFleet('heatMapSetting.intensity', e.target.value);
  };

  handleChangeOpacity = e => {
    this.props.handleUpdateFleet('heatMapSetting.opacity', e.target.value);
  };

  resetHeatmapSetting = () => {
    this.props.handleUpdateFleet('heatMapSetting', {
      defaultMode: defaultHeatMapMode,
      radius: 40,
      intensity: 80,
      opacity: 0.7
    });
  };

  render() {
    const { ValidatorCallback, editable, isSubmited, valid } = this.props;
    const {
      fleet: { heatMapSetting, heatMap }
    } = this.props;

    if (!_.get(heatMap, 'cc', false)) {
      return null;
    }

    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="generalSetting.HEAT_MAP" />
        </FormGroupTitle>
        <FormGroup className="no-marginBottom">
          <Form.Label className="text-trim-ellipsis">
            <Translate value="generalSetting.heatMap.defaultMode" />
          </Form.Label>
          <FormGroup>
            <FormControl
              as="select"
              className="form-custom no-marginBottom"
              onChange={this.handleChangeDefaultMode}
              value={heatMapSetting.defaultMode}
              disabled={editable}
            >
              {heatMapMode.map(mode => (
                <option value={mode.value} key={mode.value}>{I18n.t(mode.label)}</option>
              ))}
            </FormControl>
          </FormGroup>
        </FormGroup>
        <FormGroup className="no-marginBottom">
          <Form.Label className="text-trim-ellipsis">
            <Translate value="generalSetting.heatMap.radius" />
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={I18n.t('generalSetting.heatMap.tooltipRadius')}
            />
          </Form.Label>
          <FormGroup
            className={
              !isSubmited ? null : valid.heatmapRadius ? null : 'error'
            }
          >
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={this.handleChangeRadius}
              value={heatMapSetting.radius}
              disabled={editable}
            />
            <Validator id="heatmapRadius" callback={ValidatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(heatMapSetting.radius)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isNumber(heatMapSetting.radius)}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isGreaterThan(heatMapSetting.radius, 0)}
                message={I18n.t('messages.commonMessages.greater_than').format(
                  0
                )}
                hide={!isSubmited}
              />
              <ValidCase
                hide={!isSubmited}
                valid={Validation.isLessOrEqual(heatMapSetting.radius, 999)}
                message={I18n.t(
                  'messages.commonMessages.less_or_equal'
                ).format(999)}
              />
            </Validator>
          </FormGroup>
        </FormGroup>
        <FormGroup className="no-marginBottom">
          <Form.Label className="text-trim-ellipsis">
            <Translate value="generalSetting.heatMap.intensity" />
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={I18n.t('generalSetting.heatMap.tooltipIntensity')}
            />
          </Form.Label>
          <FormGroup
            className={
              !isSubmited ? null : valid.heatmapIntensity ? null : 'error'
            }
          >
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={this.handleChangeIntensity}
              value={heatMapSetting.intensity}
              disabled={editable}
            />
            <Validator id="heatmapIntensity" callback={ValidatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(heatMapSetting.intensity)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isNumber(heatMapSetting.intensity)}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isGreaterThan(heatMapSetting.intensity, 0)}
                message={I18n.t('messages.commonMessages.greater_than').format(
                  0
                )}
                hide={!isSubmited}
              />
              <ValidCase
                hide={!isSubmited}
                valid={Validation.isLessOrEqual(heatMapSetting.intensity, 999)}
                message={I18n.t(
                  'messages.commonMessages.less_or_equal'
                ).format(999)}
              />
            </Validator>
          </FormGroup>
        </FormGroup>
        <FormGroup className="no-marginBottom">
          <Form.Label className="text-trim-ellipsis">
            <Translate value="generalSetting.heatMap.opacity" />
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={I18n.t('generalSetting.heatMap.tooltipOpacity')}
            />
          </Form.Label>
          <FormGroup
            className={
              !isSubmited ? null : valid.heatmapOpacity ? null : 'error'
            }
          >
            <FormControl
              type="text"
              className="form-custom no-marginBottom"
              onChange={this.handleChangeOpacity}
              value={heatMapSetting.opacity}
              disabled={editable}
            />
            <Validator id="heatmapOpacity" callback={ValidatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(heatMapSetting.opacity)}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isNumber(heatMapSetting.opacity)}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isGreaterThan(heatMapSetting.opacity, 0)}
                message={I18n.t('messages.commonMessages.greater_than').format(
                  0
                )}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isInRange(heatMapSetting.opacity, 0, 1)}
                message={I18n.t(
                  'messages.commonMessages.value_must_between'
                ).format(0, 1)}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
        </FormGroup>
        <FormGroup className="no-marginBottom text-right">
          <a
            className="text-active cursor-pointer"
            onClick={this.resetHeatmapSetting}
          >
            <Translate value="generalSetting.Reset" />
          </a>
        </FormGroup>
      </Fragment>
    );
  }
}

export default HeatMapFormGroup;
