/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  FormGroup,
  Form,
  FormControl,
  Button,
  InputGroup,
  ListGroupItem,
  Row,
  Col,
} from 'react-bootstrap';
import { CountryDropdown } from 'react-country-region-selector';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import {
  paymentGetwayDisabledAddNew,
  USER_TYPE_ADD_CART,
} from '../../constants/commondata';
import { Validator, ValidCase } from '../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  formatMMYY,
  trimPhoneNumber,
} from '../../utils/commonFunctions';
import LoadingSpiner from '../../components/loadingSpiner/LoadingSpiner';
import IntlTelInputApp from '../../components/intlTelInputCustome/IntlTelInputApp';
import * as loadingActions from '../../actions/loadingBarActions';
import * as creditActions from '../../actions/creditCardActions';
import SmsVerifyModal from './components/SmsVerifyModal';
import StripeAddCardForm from '../../components/Stripe/StripeAddCardForm';

import { socketDispatchApi } from '../../utils/socketUtils';
import { socketConfigs } from '../../constants/socketConfigs';

require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const currencyFormatter = require('currency-formatter');

const reg = /^\d+$/;
const INPUT_CARD = 'inputCard';
const DIRECT_BILLING = 'directBilling';
class AddCard extends Component {
  constructor() {
    super();
    this.state = {
      country: '',
      cardHolder: '',
      cardNumber: '',
      expiredDate: '',
      cvv: '',
      add: '',
      city: '',
      state: '',
      postalCode: '',
      creditCode: '',
      creditType: 'IDCARD',
      isSubmitted: false,
      valid: {},
      triggerValidate: {
        cardHolder: false,
        cardNumber: false,
        expiredDate: false,
        cvv: false,
        address: false,
        city: false,
        state: false,
        zipcode: false,
        country: false,
        creditCode: false,
        creditType: false,
      },
      isUnionpay: false,
      isMasapay: false,
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCardHolderChange = this.handleCardHolderChange.bind(this);
    this.handleCardNumberChange = this.handleCardNumberChange.bind(this);
    this.handleExpiredDateChange = this.handleExpiredDateChange.bind(this);
    this.handleCVVChange = this.handleCVVChange.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
    this.handleAddCardClick = this.handleAddCardClick.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.onInputFieldBlur = this.onInputFieldBlur.bind(this);
    this.isTriggerValidation = this.isTriggerValidation.bind(this);
  }

  componentDidMount() {
    if (this.props.isTopUp) {
      if (this.props.isDirectBilling) {
        this.setState({ type: DIRECT_BILLING });
      } else if (this.props.credits && this.props.credits[0]) {
        this.setState({ type: this.props.credits[0]._id });
      } else {
        this.setState({ type: INPUT_CARD });
      }
      if (this.props.auth.selectedFleet) {
        this.setState({
          currencyISO: this.props.auth.selectedFleet.currencyISOValidation,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isDirectBilling === nextProps.isDirectBilling) {
      return;
    }
    if (this.props.isDirectBilling && this.state.type === DIRECT_BILLING) {
      this.setState({
        type: DIRECT_BILLING,
        valid: {},
      });
    } else if (!_.isEmpty(nextProps.credits)) {
      this.setState({
        type: nextProps.credits[0]._id,
        valid: {},
      });
    } else {
      this.setState({
        type: INPUT_CARD,
        valid: {},
      });
    }
  }

  onInputFieldBlur(key) {
    const { triggerValidate } = this.state;
    triggerValidate[key] = true;
    this.setState({
      triggerValidate: {
        ...triggerValidate,
      },
    });
  }

  onBlurCardNumner = (e) => {
    this.onInputFieldBlur('cardNumner');
    const {
      creditCardActions,
      auth: { selectedFleet },
    } = this.props;
    const cardNumber = e.target.value || '';
    if (cardNumber) {
      creditCardActions
        .checkUnionPayCredit({
          fleetId: selectedFleet.fleetId,
          cardNumber: _.camelCase(cardNumber),
        })
        .then(({ res }) => {
          const creditGateway = _.get(
            selectedFleet,
            'creditConfig.configGateway.gateway'
          );
          let { isMasapay } = this.state;
          if (creditGateway && creditGateway === 'Avis') {
            isMasapay = !res.response;
          }
          this.setState({ isUnionpay: res.response, isMasapay });
        });
    }
  };

  handleCreditCodeChange = (e) => {
    const creditCode = e.target.value || '';
    this.setState({ creditCode });
  };

  handleCreditTypeChange = (e) => {
    const creditType = e.target.value || '';
    this.setState({ creditType });
  };

  handleCountryChange(e) {
    this.setState({ country: e });
  }

  handleCardHolderChange(e) {
    const cardHolder = e.target.value;
    this.setState({
      cardHolder,
    });
  }

  handleCardNumberChange(e) {
    const value = e.target.value || '';
    const cardNumber = CCLiteCommonFunc.cardNumberFormat(value);
    this.setState({ cardNumber });
  }

  handleExpiredDateChange(e) {
    const { value } = e.target;
    if (
      this.state.expiredDate &&
      value.length <= this.state.expiredDate.length
    ) {
      // key delete or backspace
      this.setState({ expiredDate: value });
    } else {
      this.setState({
        expiredDate: formatMMYY(value),
      });
    }
    // if (value.length <= 7) {
    //     //when user press first letter
    //     if (value.length < 2 && reg.test(value)) {
    //         let num = parseInt(value);
    //         if (num > 1) {
    //             value = '0' + value + '/';
    //         }
    //         this.setState({ expiredDate: value });
    //     } else if (value.length == 2) {
    //         if (this.state.expiredDate.length == 1) {
    //             value = value + '/';
    //         }
    //         this.setState({ expiredDate: value });
    //     } else if (reg.test(value.substring(3, 5)) || reg.test(value.substring(0, 2))) {
    //         this.setState({ expiredDate: value });
    //     } else if (value == '') {
    //         this.setState({ expiredDate: value });
    //     }
    // }
  }

  handleCVVChange(e) {
    if (reg.test(e.target.value) || e.target.value == '') {
      if (e.target.value.length <= 4) {
        this.setState({ cvvValidation: true, cvv: e.target.value });
      }
    }
  }

  handleAddChange(e) {
    const add = e.target.value;
    this.setState({
      add,
    });
  }

  handleCityChange(e) {
    const city = e.target.value;
    this.setState({
      city,
    });
  }

  handleStateChange(e) {
    this.setState({
      state: e.target.value,
    });
  }

  handlePostalCodeChange(e) {
    const postalCode = e.target.value;
    this.setState({
      postalCode,
    });
  }

  handleCurrencyChange(e) {
    this.setState({
      currencyISO: e.target.value,
    });
  }

  handleAmountChange(e) {
    const amount = e.target.value;
    this.setState({
      amount,
    });
  }

  handleTypeChange(e) {
    this.setState({
      type: e.target.value,
      valid: {},
    });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  validForm = () => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    return true;
  };

  handleAddCardClick() {
    // this.props.loadingActions.showLoadingAddCardSpiner();
    // return this.openModalVerification();
    const creditCardObject = {
      corpId: this.props.corpId,
      fleetId: this.props.auth.selectedFleet.fleetId,
      zoneId: this.props.zoneId,
      userType: this.props.corpId
        ? USER_TYPE_ADD_CART.corporate
        : USER_TYPE_ADD_CART.passenger,
      phone:
        (this.props.currentUser &&
          trimPhoneNumber(this.props.currentUser.phoneNumber)) ||
        trimPhoneNumber(this.props.currentUser.phone) ||
        (this.props.auth.user && trimPhoneNumber(this.props.auth.user.phone)),
    };
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
    if (!this.props.isTopUp || this.state.type === INPUT_CARD) {
      // parse expireDate to format dd/yyyy , currently it has format mm/yy
      let expiredDate = this.state.expiredDate.split(' ').join('');
      const expiredDateSplit = expiredDate.split('/');
      if (expiredDateSplit && expiredDateSplit.length > 1) {
        expiredDateSplit[1] = `20${expiredDateSplit[1]}`;
        expiredDate = expiredDateSplit.join('/');
      }
      creditCardObject.userId = this.props.userId;
      creditCardObject.cardHolder = this.state.cardHolder;
      creditCardObject.cvv = this.state.cvv;
      creditCardObject.cardNumber = this.state.cardNumber.replace(/\s+/g, '');
      creditCardObject.expiredDate = expiredDate;

      if (
        (this.props.gateWay &&
          this.props.gateWay.zipCode &&
          this.props.gateWay.zipCode.enable) ||
        (this.props.auth.selectedFleet.creditConfig &&
          this.props.auth.selectedFleet.creditConfig.configGateway &&
          this.props.auth.selectedFleet.creditConfig.configGateway.zipCode &&
          this.props.auth.selectedFleet.creditConfig.configGateway.zipCode
            .enable) ||
        this.state.isMasapay
      ) {
        creditCardObject.street = this.state.add;
        creditCardObject.city = this.state.city;
        creditCardObject.state = this.state.state;
        creditCardObject.country = this.state.country;
        creditCardObject.postalCode = this.state.postalCode;
      }
    }
    if (this.props.isTopUp) {
      creditCardObject.amount = this.state.amount;
      creditCardObject.currencyISO = this.state.currencyISO;
      if (
        this.state.type !== DIRECT_BILLING &&
        this.state.type !== INPUT_CARD
      ) {
        creditCardObject.type = 'credit';
        creditCardObject.cardId = this.state.type;
      } else {
        creditCardObject.type = this.state.type;
      }
    }

    if (this.props.gateWay) {
      if (
        this.state.type === INPUT_CARD &&
        paymentGetwayDisabledAddNew.includes(this.props.gateWay.gateway)
      ) {
        this.context.notification('error', I18n.t('corporate.No_Support'));
        return;
      }
      if (this.props.gateWay.requireEmail) {
        if (this.props.currentUser.email) {
          creditCardObject.email = this.props.currentUser.email;
        } else {
          const message = this.props.isCustomer
            ? I18n.t('customer.required_email_payment')
            : this.props.ismDispatcher
            ? I18n.t('mDispatcher.required_email_payment')
            : I18n.t('customer.Require_email');
          this.context.notification('error', message);
          return;
        }
      }
      if (this.props.gateWay.requireName) {
        if (this.props.currentUser.lastName) {
          // creditCardObject.lastName = this.props.currentUser.lastName;
        } else {
          this.context.notification(
            'error',
            I18n.t('customer.Require_lastName')
          );
          return;
        }
      }

      /**
       * Don't required phone number when add corporate card
       */
      if (this.props.gateWay.requirePhone) {
        if (
          this.props.currentUser.phoneNumber ||
          this.props.currentUser.phone
        ) {
          creditCardObject.phone = trimPhoneNumber(
            this.props.currentUser.phoneNumber || this.props.currentUser.phone
          );
        } else {
          if (!this.props.isAddNewCorporateCard) {
            this.context.notification(
              'error',
              I18n.t('customer.Require_phone_number')
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('corporate.required_phone_number_payment')
            );
          }
          return;
        }
      }

      /* add unionpay, yeepay */
      if (this.state.isUnionpay) {
        creditCardObject.creditCode = this.state.creditCode;
        creditCardObject.creditType = this.state.creditType;
      }

      if (this.props.gateWay.creditPhone) {
        creditCardObject.creditPhone = trimPhoneNumber(this.state.creditPhone);
      }

      this.setState({
        triggerValidate: {
          cardHolder: false,
          cardNumber: false,
          expiredDate: false,
          cvv: false,
          address: false,
          city: false,
          state: false,
          zipcode: false,
          country: false,
          creditCode: false,
          creditType: false,
          creditPhone: false,
        },
      });
      this.addCardWebservice(creditCardObject);
    }
  }

  hanldeCardSetupSuccess = (setupIntent, cardHolder) => {
    // TOTO refactor request body
    const fakeCard = {
      cardNumber: '',
      creditCode: '',
      street: '',
      city: '',
      name: '',
      email: '',
      postalCode: '',
      expiredDate: '',
      cvv: '',
      creditType: '',
      sca: true,
    };
    const creditCardObject = _.extend({}, fakeCard, {
      fleetId: this.props.auth.selectedFleet.fleetId,
      zoneId: this.props.zoneId,
      corpId: this.props.corpId,
      userId: this.props.userId,
      userType: this.props.corpId
        ? USER_TYPE_ADD_CART.corporate
        : USER_TYPE_ADD_CART.passenger,
      phone:
        (this.props.currentUser &&
          trimPhoneNumber(this.props.currentUser.phoneNumber)) ||
        (this.props.auth.user && trimPhoneNumber(this.props.auth.user.phone)),
      cardHolder,
      token: JSON.stringify({ mCard: '', mId: setupIntent.payment_method }),
    });
    if (this.props.isTopUp) {
      creditCardObject.amount = this.state.amount;
      creditCardObject.currencyISO = this.state.currencyISO;
      if (
        this.state.type !== DIRECT_BILLING &&
        this.state.type !== INPUT_CARD
      ) {
        creditCardObject.type = 'credit';
        creditCardObject.cardId = this.state.type;
      } else {
        creditCardObject.type = this.state.type;
      }
    }

    this.addCardWebservice(creditCardObject);
  };

  checkHasTopUp3ds = (url) => {
    if (url) {
      this.props.handleTopUpWith3ds(url);
      return;
    }
  };

  addCardWebservice(creditCardObject) {
    /* check loading sms verification modal */
    if (creditCardObject.data3ds) {
      this.setState({ isLoadingSMSVerification: true });
    } else {
      this.props.loadingActions.showLoadingSpiner();
    }

    this.props.addCardWebservice(creditCardObject).then((data) => {
      this.setState({ isLoadingSMSVerification: false });

      const returnCode =
        data.res && data.res.returnCode
          ? data.res.returnCode
          : data.error && data.error.errorCode
          ? data.error && data.error.errorCode
          : '';

      if (data.res && (data.res.returnCode == 200 || data.res.isSuccess)) {
        this.props.loadingActions.hideLoadingSpiner();
        if (this.props.isTopUp && data.res?.['3ds_Url']) {
          this.checkHasTopUp3ds(data.res?.['3ds_Url']);
          return;
        }
        this.props.isTopUp
          ? this.context.notification(
              'success',
              I18n.t('customer.Add_card_top_up_success')
            )
          : this.context.notification(
              'success',
              I18n.t('customer.Add_card_success')
            );
        this.setState({
          country: '',
          cardHolder: '',
          cardNumber: '',
          expiredDate: '',
          cvv: '',
          add: '',
          city: '',
          state: '',
          postalCode: '',
          creditCode: '',
          creditType: 'IDCARD',
          isUnionpay: false,
          isMasapay: false,
          isSubmitted: false,
          creditCardObject: {},
          showVerification: false,
        });
        this.props.handleAddCardSuccess(data.res.response, this.state.amount);
      } else if (returnCode === 457) {
        /* verification unionpay */
        this.props.loadingActions.hideLoadingSpiner();
        this.openModalVerification(creditCardObject);
      } else if (returnCode === 113) {
        const clientSecret = _.get(
          data.error.response,
          'response.clientSecret'
        );
        this.verifyStripeSCA(clientSecret);
      } else {
        this.props.loadingActions.hideLoadingSpiner();
        const message = I18n.t(`messages.credits.${returnCode}`);
        this.context.notification('error', message, '', () => {}, 1000 * 10);
      }
    });
  }

  verifyStripeSCA = (clientSecret) => {
    const { auth } = this.props;
    const stripe = window.Stripe(auth.selectedFleet.stripePublicKey);
    stripe.handleCardPayment(clientSecret).then((result) => {
      if (result.error) {
        // Display error.message in your UI.
        this.props.loadingActions.hideLoadingSpiner();
        const errorMessage = result.error.message || result.error.code;
        this.context.notification('error', errorMessage);
      } else {
        this.onSocketDispatchTopup(clientSecret);
      }
    });
  };

  onSocketDispatchTopup = (clientSecret) => {
    socketDispatchApi.remove(socketConfigs.receive.corporate.topup);
    socketDispatchApi.on(socketConfigs.receive.corporate.topup, (data) => {
      if (data.client_secret === clientSecret) {
        this.props.loadingActions.hideLoadingSpiner();
        if (data.returnCode === 200) {
          this.context.notification(
            'success',
            I18n.t('customer.Add_card_top_up_success')
          );
          this.setState({
            country: '',
            cardHolder: '',
            cardNumber: '',
            expiredDate: '',
            cvv: '',
            add: '',
            city: '',
            state: '',
            postalCode: '',
            creditCode: '',
            creditType: 'IDCARD',
            isUnionpay: false,
            isMasapay: false,
            isSubmitted: false,
            creditCardObject: {},
            showVerification: false,
          });
          this.props.handleAddCardSuccess(
            { isSuccess: true },
            this.state.amount
          );
        } else {
          const message = I18n.t(`messages.credits.${data.returnCode}`);
          this.context.notification('error', message, '', () => {}, 1000 * 10);
        }
      }
    });
  };

  isTriggerValidation(key) {
    return this.state.isSubmitted || this.state.triggerValidate[key];
  }

  openModalVerification = (creditCardObject) => {
    this.setState({ showVerification: true, creditCardObject });
  };

  onCloseModalVerificationCode = () => {
    this.setState({ showVerification: false });
  };

  onSubmitVerfiyCode = (params) => {
    const { creditCardObject } = this.state;
    this.addCardWebservice(_.extend({}, creditCardObject, params));
  };

  renderFormTopUp = () => {
    if (!this.props.isTopUp) return null;
    return (
      <Row>
        <Col md={6}>
          <FormGroup>
            <Form.Label>
              <Translate value="corporate.Currency" />
            </Form.Label>
            <FormControl
              as="select"
              value={this.state.currencyISO}
              onChange={this.handleCurrencyChange}
              className="form-custom"
            >
              {/* this.props.auth.selectedFleet.currencies.map(cp => {
                                    if (cp.iso === this.props.auth.selectedFleet.currencyISOValidation) {
                                        return <option key={cp.iso} value={cp.iso}>{cp.iso}</option>
                                    } else {
                                        return null;
                                    }
                                }) */}
              <option
                key="0"
                value={this.props.auth.selectedFleet.currencyISOValidation}
              >
                {this.props.auth.selectedFleet.currencyISOValidation}
              </option>
            </FormControl>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup
            className={`qup-input-group ${
              !this.state.isSubmitted
                ? null
                : this.state.valid.amount === false
                ? 'error'
                : null
            }`}
          >
            <Form.Label>
              <Translate value="corporate.Topup_amount" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <InputGroup className="single-addon-left">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {
                    currencyFormatter.findCurrency(
                      this.props.auth.selectedFleet.currencyISOValidation
                    ).symbol
                  }
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                value={this.state.amount}
                onChange={this.handleAmountChange}
                className="form-custom"
              />
            </InputGroup>
            <Validator id="amount" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.state.isSubmitted}
                valid={
                  Validation.isNumber(this.state.amount) &&
                  parseFloat(this.state.amount) > 0
                }
                message={I18n.t('corporate.ERROR_INPUT_AMOUNT')}
              />
            </Validator>
          </FormGroup>
        </Col>
      </Row>
    );
  };

  handlePhoneChange = (status, value, countryData, number, id, isBlur) => {
    this.setState({
      creditPhone: number,
      creditPhoneValidation: status,
    });
  };

  renderCardForm = (creditGateway) => {
    const { auth, creditCardActions, isHydraBooking, fleetSupplierId } =
      this.props;
    // const hydraStripe = _.get(auth, 'selectedFleet.hydra', {})
    // if(isHydraBooking && hydraStripe.enable && hydraStripe.stripePublicKey) {
    //   return (
    //     <StripeAddCardForm
    //       stripeKey={hydraStripe.stripePublicKey}
    //       fleetId={auth.selectedFleet.fleetId}
    //       isHydraBooking={isHydraBooking}
    //       creditConfig={auth.selectedFleet.creditConfig}
    //       getClientSecret={creditCardActions.getClientSecret}
    //       onCardSetupSuccess={this.hanldeCardSetupSuccess}
    //       loadingActions={this.props.loadingActions}
    //       handleCloseAddCard={this.props.handleCloseAddCard}
    //       topUpElement={this.renderFormTopUp()}
    //       validForm={this.validForm}
    //     />
    //   )
    // }
    if (creditGateway === 'Stripe' && auth.selectedFleet.stripePublicKey) {
      return (
        <StripeAddCardForm
          stripeKey={auth.selectedFleet.stripePublicKey}
          fleetId={auth.selectedFleet.fleetId}
          creditConfig={auth.selectedFleet.creditConfig}
          getClientSecret={creditCardActions.getClientSecret}
          onCardSetupSuccess={this.hanldeCardSetupSuccess}
          loadingActions={this.props.loadingActions}
          handleCloseAddCard={this.props.handleCloseAddCard}
          topUpElement={this.renderFormTopUp()}
          validForm={this.validForm}
        />
      );
    }
    return (
      <Row>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              !this.isTriggerValidation('cardHolder')
                ? null
                : this.state.valid.cardHolder === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="customer.Card_holder" />
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="text"
              value={this.state.cardHolder}
              onChange={this.handleCardHolderChange}
              onBlur={() => this.onInputFieldBlur('cardHolder')}
              className="form-custom"
            />
            <Validator id="cardHolder" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.isTriggerValidation('cardHolder')}
                valid={!Validation.isStringEmpty(this.state.cardHolder)}
                message={I18n.t(
                  'messages.commonMessages.Card_holder_is_required'
                )}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              !this.isTriggerValidation('cardNumber')
                ? null
                : this.state.valid.cardNumber === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="customer.Card_number" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="text"
              maxLength={19}
              value={this.state.cardNumber}
              onChange={this.handleCardNumberChange}
              onBlur={this.onBlurCardNumner}
              className="form-custom"
            />
            <Validator id="cardNumber" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.isTriggerValidation('cardNumber')}
                valid={!Validation.isStringEmpty(this.state.cardNumber)}
                message={I18n.t(
                  'messages.commonMessages.Card_number_is_required'
                )}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              !this.isTriggerValidation('expiredDate')
                ? null
                : this.state.valid.expiredDate === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="customer.Expired_date" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="text"
              maxLength={7}
              placeholder="mm/yy"
              value={this.state.expiredDate}
              onChange={this.handleExpiredDateChange}
              onBlur={() => this.onInputFieldBlur('expiredDate')}
              className="form-custom"
            />
            <Validator id="expiredDate" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.isTriggerValidation('expiredDate')}
                valid={!Validation.isStringEmpty(this.state.expiredDate)}
                message={I18n.t(
                  'messages.commonMessages.Expired_date_is_required'
                )}
              />
              <ValidCase
                hide={!this.isTriggerValidation('expiredDate')}
                valid={Validation.validCreditsDateFormat(
                  this.state.expiredDate
                )}
                message={I18n.t(
                  'messages.commonMessages.Expired_date_is_invalid'
                )}
              />
              <ValidCase
                hide={!this.isTriggerValidation('expiredDate')}
                valid={Validation.validCreditsFutureDate(
                  this.state.expiredDate
                )}
                message={I18n.t(
                  'messages.commonMessages.Please_enter_a_future_date'
                )}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            className={
              !this.isTriggerValidation('cvv')
                ? null
                : this.state.valid.cvv === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="customer.CVV" />{' '}
              <span className="require"> *</span>
            </Form.Label>
            <FormControl
              type="number"
              maxLength={4}
              value={this.state.cvv}
              onChange={this.handleCVVChange}
              onBlur={() => this.onInputFieldBlur('cvv')}
              className="form-custom number-no-spin"
            />
            <Validator id="cvv" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.isTriggerValidation('cvv')}
                valid={!Validation.isStringEmpty(this.state.cvv)}
                message={I18n.t('messages.commonMessages.cvv_is_required')}
              />
            </Validator>
          </FormGroup>
        </Col>
        {this.props.gateWay.creditPhone && (
          <Col xs={12} md={6}>
            <FormGroup
              className={
                !this.state.isSubmitted ||
                !this.state.creditPhone ||
                this.state.creditPhoneValidation
                  ? null
                  : this.state.valid.creditPhone === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="messages.commonMessages.bankPhone" />{' '}
              </Form.Label>
              <FormGroup>
                <IntlTelInputApp
                  css={['intl-tel-input', 'form-control']}
                  utilsScript="libphonenumber.js"
                  value={this.state.creditPhone}
                  onPhoneNumberChange={this.handlePhoneChange}
                />
                <Validator id="creditPhone" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmitted || !this.state.creditPhone}
                    valid={
                      !this.state.creditPhone ||
                      this.state.creditPhoneValidation
                    }
                    message={I18n.t(
                      'messages.commonMessages.bankPhone_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </FormGroup>
          </Col>
        )}
        {(this.props.gateWay &&
          this.props.gateWay.zipCode &&
          this.props.gateWay.zipCode.enable) ||
        (this.props.auth.selectedFleet.creditConfig &&
          this.props.auth.selectedFleet.creditConfig.configGateway &&
          this.props.auth.selectedFleet.creditConfig.configGateway.zipCode &&
          this.props.auth.selectedFleet.creditConfig.configGateway.zipCode
            .enable) ||
        this.state.isMasapay ? (
          <React.Fragment>
            <Col xs={12} md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('address')
                    ? null
                    : this.state.valid.add === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.Address" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.add}
                  onChange={this.handleAddChange}
                  onBlur={() => this.onInputFieldBlur('address')}
                  className="form-custom"
                />
                <Validator id="add" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('address')}
                    valid={!Validation.isStringEmpty(this.state.add)}
                    message={I18n.t(
                      'messages.commonMessages.Address_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('city')
                    ? null
                    : this.state.valid.city === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.City" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  onBlur={() => this.onInputFieldBlur('city')}
                  className="form-custom"
                />
                <Validator id="city" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('city')}
                    valid={!Validation.isStringEmpty(this.state.city)}
                    message={I18n.t('messages.commonMessages.City_is_required')}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('state')
                    ? null
                    : this.state.valid.state === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.State" />
                  {['JetPay'].includes(this.props.gateWay.gateway) && (
                    <span className="require"> *</span>
                  )}
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.state}
                  onChange={this.handleStateChange}
                  className="form-custom"
                />
                <Validator
                  id="state"
                  callback={this.ValidatorCallback}
                  disabled={!['JetPay'].includes(this.props.gateWay.gateway)}
                >
                  <ValidCase
                    hide={!this.isTriggerValidation('state')}
                    valid={!Validation.isStringEmpty(this.state.state)}
                    message={I18n.t(
                      'messages.commonMessages.State_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('zipcode')
                    ? null
                    : this.state.valid.postalCode === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.Zip_code" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.postalCode}
                  onChange={this.handlePostalCodeChange}
                  onBlur={() => this.onInputFieldBlur('zipcode')}
                  className="form-custom"
                />
                <Validator id="postalCode" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('zipcode')}
                    valid={!Validation.isStringEmpty(this.state.postalCode)}
                    message={I18n.t(
                      'messages.commonMessages.Zipcode_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('country')
                    ? null
                    : this.state.valid.country === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.Country" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <CountryDropdown
                  value={this.state.country}
                  onChange={this.handleCountryChange}
                  classes="form-control form-custom"
                  onBlur={() => this.onInputFieldBlur('country')}
                />
                <Validator id="country" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('country')}
                    valid={!Validation.isStringEmpty(this.state.country)}
                    message={I18n.t(
                      'messages.commonMessages.Country_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </React.Fragment>
        ) : null}
      </Row>
    );
  };

  render() {
    let isPermission = true;
    const { supportWeb, noPermission, auth, gateWay, isHydraBooking } =
      this.props;
    if (
      this.props.permissions &&
      !this.props.permissions.actions &&
      !noPermission
    ) {
      isPermission = false;
    }
    if (!isPermission) return <div />;

    const fleetGateway = _.get(
      auth.selectedFleet,
      'creditConfig.configGateway.gateway'
    );
    const creditGateway = _.get(gateWay, 'gateway', fleetGateway);
    const isStripeForm =
      creditGateway === 'Stripe' && auth.selectedFleet.stripePublicKey;

    return (
      <div className="form-detail-container">
        {this.props.title ? (
          <FormGroupTitle className="pt0">{this.props.title}</FormGroupTitle>
        ) : null}
        {this.props.isTopUp ? (
          <FormGroup>
            <Form.Label>
              <Translate value="corporate.Paid_by" />
            </Form.Label>
            <FormControl
              as="select"
              onChange={this.handleTypeChange}
              className="form-custom"
              value={this.state.type}
            >
              {this.props.isDirectBilling ? (
                <option key="0" value={DIRECT_BILLING}>
                  {I18n.t('corporate.Direct_invoicing')}
                </option>
              ) : null}
              {this.props.credits
                ? _.map(
                    _.filter(
                      this.props.credits,
                      (credit) => credit.gateway === this.props.gateWay.gateway
                    ),
                    (filteredCredit, index) => {
                      const { length } = filteredCredit.cardMask;
                      const cardDetail = `${
                        filteredCredit.cardHolder
                      } * ${filteredCredit.cardMask.substring(
                        length - 4,
                        length
                      )}`;
                      return (
                        <option
                          key={filteredCredit._id}
                          value={filteredCredit._id}
                        >
                          {cardDetail}
                        </option>
                      );
                    }
                  )
                : null}
              {/* {supportWeb && (
                <option key="1" value={INPUT_CARD}>
                  {I18n.t('corporate.Enter_new_credit_card')}
                </option>
              )} */}
            </FormControl>
          </FormGroup>
        ) : null}
        {(((!this.props.isTopUp || this.state.type === INPUT_CARD) &&
          this.props.gateWay) ||
          isHydraBooking) &&
        !paymentGetwayDisabledAddNew.includes(this.props.gateWay.gateway)
          ? this.renderCardForm(creditGateway)
          : this.state.type === INPUT_CARD &&
            this.props.gateWay &&
            paymentGetwayDisabledAddNew.includes(
              this.props.gateWay.gateway
            ) && (
              <ListGroupItem variant="warning">
                <Translate value="corporate.No_Support" />
              </ListGroupItem>
            )}
        {isStripeForm && (!this.props.isTopUp || this.state.type === INPUT_CARD)
          ? null
          : this.renderFormTopUp()}
        {this.state.isUnionpay ? (
          <Row>
            <Col md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('creditType')
                    ? null
                    : this.state.valid.creditType === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.Credit_type" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={this.state.creditType}
                  onChange={this.handleCreditTypeChange}
                >
                  {unionpayCreditType.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </FormControl>
                <Validator id="creditType" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('creditType')}
                    valid={!Validation.isStringEmpty(this.state.creditType)}
                    message={I18n.t(
                      'messages.commonMessages.Credit_code_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup
                className={
                  !this.isTriggerValidation('creditCode')
                    ? null
                    : this.state.valid.creditCode === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="customer.Credit_code" />{' '}
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.creditCode}
                  onChange={this.handleCreditCodeChange}
                  // onBlur={() => this.onInputFieldBlur('creditCode')}
                  className="form-custom"
                />
                <Validator id="creditCode" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.isTriggerValidation('creditCode')}
                    valid={!Validation.isStringEmpty(this.state.creditCode)}
                    message={I18n.t(
                      'messages.commonMessages.Credit_code_is_required'
                    )}
                  />
                </Validator>
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {(isStripeForm &&
          (!this.props.isTopUp || this.state.type === INPUT_CARD)) ||
        isHydraBooking ? null : (
          <Col className="text-center mt-lg">
            <Button
              variant="success"
              onClick={this.handleAddCardClick}
              className="btn-save"
            >
              <Translate value="customer.Save" />
            </Button>
            {this.props.handleCloseAddCard ? (
              <Button
                onClick={this.props.handleCloseAddCard}
                className="btn-cancel ml-md"
              >
                <Translate value="customer.Cancel" />
              </Button>
            ) : null}
          </Col>
        )}
        <LoadingSpiner show={this.props.loadingBar.isShowAddCardSpiner} />
        <SmsVerifyModal
          isShow={this.state.showVerification}
          closeDialog={this.onCloseModalVerificationCode}
          onSubmit={this.onSubmitVerfiyCode}
          isLoading={this.state.isLoadingSMSVerification}
        />
      </div>
    );
  }
}
AddCard.contextTypes = {
  notification: PropTypes.func,
};

AddCard.propTypes = {
  currentUser: PropTypes.object,
  permissions: PropTypes.object,
  gateWay: PropTypes.object,
  auth: PropTypes.object,
  zoneId: PropTypes.string,
  userId: PropTypes.string,
  phone: PropTypes.string,
  credits: PropTypes.array,
  isDirectBilling: PropTypes.bool,
  corpId: PropTypes.bool,
  isCrossZone: PropTypes.bool,
  isTopUp: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    loadingBar: state.loadingBar,
    permissions: state.menuHandle.modulePermission,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingActions, dispatch),
    creditCardActions: bindActionCreators(creditActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
