import * as _ from 'lodash';

export default {
  MapTransportValues: {
    'No.': 'no',
    'Pickup date': 'pickupDate',
    'Pickup Location': 'pickup',
    'Destination Location': 'dropoff',
    'Passenger Name': 'passenger',
    'Passenger Phone': 'phone',
    'Passenger Email': 'email',
    'Car type': 'vehicleTypeRequest',
    Seats: 'seats',
    Luggage: 'luggages',
    'Ride-share': 'rideSharing',
    'Flight number': 'flightNumber',
    Notes: 'notes',
    'Internal notes': 'internalNote',
    'Payment type': 'paymentType',
    'Trip description': 'tripDescription',
    'Trip code': 'tripCode',
    'External ID': 'externalId',
  },
  MapDeliveryValues: {
    getMappings: function (firstRow) {
      const mappings = {
        __EMPTY: 'no',
        Sender: 'sender.pickupDate',
        __EMPTY_1: 'sender.name',
        __EMPTY_2: 'sender.mobileNumber',
        __EMPTY_3: 'sender.email',
        __EMPTY_4: 'sender.address',
        __EMPTY_5: 'sender.locationDetails',
        Recipient: 'recipient.name',
        __EMPTY_6: 'recipient.mobileNumber',
        __EMPTY_7: 'recipient.address',
        __EMPTY_8: 'recipient.locationDetails',
        Details: 'details.packageContent',
        __EMPTY_9: 'details.orderNumber',
        __EMPTY_10: 'details.instructionsForDriver',
        __EMPTY_11: 'details.codItemValue',
        __EMPTY_12: 'rideSharing',
        __EMPTY_13: 'paymentType',
        __EMPTY_14: 'vehicleTypeRequest',
        __EMPTY_15: 'notes',
        __EMPTY_16: 'internalNotes',
      }
      const columnNames = _.values(firstRow)
      if (columnNames.includes('External ID')) {
        return {
          ...mappings,
          __EMPTY_17: 'externalId',
          __EMPTY_18: 'multiStopBatch',
          __EMPTY_19: 'multiStopSequenceNumber',
        }
      }
      return {
        ...mappings,
        __EMPTY_17: 'multiStopBatch',
        __EMPTY_18: 'multiStopSequenceNumber',
      }
    },
  },
}
