import React,{Component} from 'react';
import './formGroupTitle.scss'
 export default class FormGroupTitle extends Component{
    render(){
        return(
            <div className={this.props.className? this.props.className +' form-group-title':'form-group-title'}>
                <span >{this.props.children}</span>
            </div>
        )
    }
 }