import React, { useEffect, useState } from 'react';
import { componentsVersion } from '../../constants/ApiConfigs';
import packageJson from '../../../package.json'
import { Table } from 'react-bootstrap';

const style = {
  backgroundColor: 'white', 
  width: '500px', 
  margin: '0 auto',
  marginTop: '20px'
}

const Version = () => {
  const [versionData, setVersionData] = useState([]);

  useEffect(() => {
    fetch(componentsVersion)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) setVersionData([...data, {name: 'Newcc Client', version: packageJson.version}]);
      })
      .catch((error) => {
        alert('error');
      });
  }, []);

  return (
    <div style={style}>
      <Table striped bordered condensed hover>
        <thead>
          <th>STT</th>
          <th>Name</th>
          <th>Current Version</th>
        </thead>
        <tbody>
          {versionData.map((cp, id) => (
            <tr key={cp.name}>
              <td>{id + 1}</td>
              <td>{cp.name}</td>
              <td>{cp.version}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Version;
