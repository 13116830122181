import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, Row, Col, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CCInputNumber from '../../../components/InputNumber';
import { Validator, ValidCase } from '../../../components/validator';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';

class SeatLuggageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.etaFare = _.debounce(this.props.intercityBookingActions.etaFare, 500)
  }

  onChangeLuggageNumber = (value) => {
    value = parseInt(value || 0);
    this.props.intercityBookingActions.updateCommonData({
      luggageNumber: value
    })
    this.etaFare();
  }

  onChangePaxNumber = (value) => {
    value = parseInt(value || 0);
    this.props.intercityBookingActions.updateCommonData({
      paxNumber: value
    })
    // this.props.intercityBookingActions.etaFare();
    this.etaFare();
  }

  getMaximumLuggage = () => {
    const { routeSelected, carType, paxNumber } = this.props;
    const freeLuggagePerSeat = _.get(routeSelected, 'limitSeatLuggage.freeLuggagePerSeat', 0)
    const limitLuggageByRoute = ( paxNumber || 1)*freeLuggagePerSeat;
    if(_.get(routeSelected, 'limitSeatLuggage.allowToAddExtraLuggage')) {
      return carType.maxLuggages
    }
    if (carType.maxLuggages) {
      return carType.maxLuggages > limitLuggageByRoute ? limitLuggageByRoute : carType.maxLuggages
    }
    return limitLuggageByRoute
  }

  render() {
    const {
      request,
      carType,
      paxNumber, luggageNumber,
      isBookDetail,
      isViewOnly
    } = this.props;

    const disabled = isBookDetail && request.paymentType !== 1 || isViewOnly;
    if (!carType) {
      return null;
    }
    return (
      <div>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Form.Label>
                <Translate value="newbooking.Seat" />
              </Form.Label>
              <CCInputNumber
                placeholder="Passenger"
                value={paxNumber || 1}
                min={1}
                onChange={this.onChangePaxNumber}
                max={
                  carType.maxPassengers
                    ? carType.maxPassengers
                    : undefined
                }
                inputMode="number"
                disabled={disabled}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className={this.props.isSubmited && this.props.valid.luggageNumber === false ? 'error' : null}>
              <Form.Label>
                <Translate value="newbooking.Luggage" />
              </Form.Label>
              <CCInputNumber
                placeholder="Luggage"
                value={luggageNumber || 0}
                min={0}
                onChange={this.onChangeLuggageNumber}
                max={this.getMaximumLuggage()}
                disabled={disabled}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    auth: state.auth,
    carType: state.intercityBooking.common.carType,
    paxNumber: state.intercityBooking.common.paxNumber,
    routeSelected: state.intercityBooking.common.routeSelected,
    luggageNumber: state.intercityBooking.common.luggageNumber,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SeatLuggageForm);
