export const PartnerColumns = [
  {
    key: "table-selectbox",
    label: "",
    width: 60,
    fixed: true,
    align: "center"
  },
  {
    key: "fullName",
    label: "mDispatcher.Name",
    width: 200,
    fixed: true,
    textEllipsis: true,
    sortName: "fullNameSort"
  },
  {
    key: "phone",
    label: "mDispatcher.Phone_Number",
    width: 200,
    sortName: "phone"
  },
  {
    key: "mDispatcherInfo.queuingArea.name",
    label: "mDispatcher.queuing_area",
    width: 250,
    sortName: "mDispatcherInfo.queuingArea.name",
    textEllipsis: true
  },
  {
    key: "mDispatcherInfo.partnerType.name",
    label: "mDispatcher.mdispatcher_type",
    width: 200,
    sortName: "mDispatcherInfo.partnerType.name",
    textEllipsis: true
  },
  {
    key: "registerFrom",
    label: "mDispatcher.registered_from",
    width: 200,
    sortName: "registerFrom"
  },
  {
    key: "createdDate",
    label: "mDispatcher.registration_date",
    width: 200,
    sortName: "createdDate"
  },
  {
    key: "status",
    label: "mDispatcher.status",
    width: 120,
    sortName: "isActive"
  },
  {
    key: "actions",
    label: "mDispatcher.actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
]