import { uploadPhotoApi, uploadDocumentApi } from '../constants/ApiConfigs';
import { callApi } from '../utils/apiUtils';

export function uploadPhoto(formData) {
  const config = {
    method: 'POST',
    body: formData,
    isFormData: true
  };
  return callApi(uploadPhotoApi, config, null, null, null, true);
}

export function uploadDocument(formData) {
  const config = {
    method: 'POST',
    body: formData,
    isFormData: true
  };
  return callApi(uploadDocumentApi, config, null, null, null, true);
}
