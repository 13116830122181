import React, { Component } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    InputGroup,
    Button, Radio,
    ButtonToolbar,
    DropdownButton,
    
    Modal,
    ListGroup,
    ListGroupItem,
    Checkbox,
    Tab,
    Tabs,
    Alert,
    Pagination,
    Form
} from 'react-bootstrap';
import { Validator, ValidCase } from '../../../components/validator'
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions'
import * as settingActions from './../../../actions/settingActions'
import * as loadingBarActions from './../../../actions/loadingBarActions'
import { bindActionCreators } from 'redux';
import TableHeader from '../../../components/table/TableHeader';
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import TableActions from "../../../components/table/tableAction/TableActions";
import { Columns } from './tableHeaderColumn'
import _ from 'lodash'

class Campaign extends Component {
    constructor() {
        super()
        this.state = {
            valid: {},
            isSubmitted: false,
            campaignList: {
                list: [],
                page: 0,
                limit: 20,
                total: 1
            },
            rowHeight: 50,
            dialogData: {},
            numItemsPerPage: 20,
            activePage: 1,
            editable: false,
            showConfirm: false,
            tableHeight: 500,

        }

        this.updateCampaignList = this.updateCampaignList.bind(this)
        this.openFormModal = this.openFormModal.bind(this)
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this)
        this.handlePaginationChose = this.handlePaginationChose.bind(this)
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this)
        this.closeFormModal = this.closeFormModal.bind(this)
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this)
        this.saveDialogForm = this.saveDialogForm.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.ValidatorCallback = this.ValidatorCallback.bind(this)
        this.confirmDeleteCampain = this.confirmDeleteCampain.bind(this)
        this.handleMenuClick = this.handleMenuClick.bind(this)
    }

    componentDidMount() {
        this.updateCampaignList()
    }

    handleMenuClick(key, obj) {
        const { auth, settingActions } = this.props
        let fleetId = auth.selectedFleet.fleetId
        switch (key) {
            case 'Edit': {
                settingActions.detailsCampaign({
                    fleetId: fleetId,
                    id: obj._id
                }).then((data) => {
                    if (data.ok) {
                        this.setState({
                            dialogData: data.res,
                            editable: true
                        })
                        this.openFormModal()
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('promotionSettings.Loading_campaign_fail'))
                        }
                    }
                })
                break;
            }
            case 'View': {
                settingActions.detailsCampaign({
                    fleetId: fleetId,
                    id: obj._id
                }).then((data) => {
                    if (data.ok) {
                        this.setState({
                            dialogData: data.res,
                            editable: false
                        })
                        this.openFormModal()
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('promotionSettings.Loading_campaign_fail'))
                        }
                    }
                })
                break;
            }
            case 'Active': {
                let body = {
                    id: obj._id,
                    fleetId: fleetId,
                    isActive: !obj.isActive
                }
                settingActions.activeCampaign(body)
                    .then((data) => {
                        if (data.ok) {
                            this.closeFormModal()
                            this.updateCampaignList()
                            this.context.notification('success', I18n.t('promotionSettings.Update_campaign_status_success'))
                            this.setState({ isSubmited: false })
                        } else {
                            if (data.error) {
                                this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                            } else {
                                this.context.notification('error', I18n.t('promotionSettings.Update_campaign_status_fail'))
                            }
                        }
                    })
                break;
            }
            case 'Delete': {
                this.setState({ showConfirm: true, dialogData: obj });
                break;
            }
            case 'changeStatus': {
                this.setState({ editable: true })
                break;
            }
        }
    }

    confirmDeleteCampain() {
        const { auth, settingActions } = this.props
        let fleetId = auth.selectedFleet.fleetId
        let body = {
            id: this.state.dialogData._id,
            fleetId: fleetId
        }
        settingActions.deleteCampaign(body)
            .then((data) => {
                if (data.ok) {
                    this.closeFormModal()
                    this.updateCampaignList()
                    this.context.notification('success', I18n.t('promotionSettings.Delete_campaign_success'))
                    this.setState({ isSubmited: false })
                } else {
                    if (data.error) {
                        this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                    } else {
                        this.context.notification('error', I18n.t('promotionSettings.Delete_campaign_fail'))
                    }
                }
            })
    }

    updateCampaignList(page = 0, limit) {
        const { auth, settingActions } = this.props
        let fleetId = auth.selectedFleet.fleetId
        if (!limit) limit = this.state.campaignList.limit
        settingActions.findCampaigns({ fleetId, limit, page })
            .then((data) => {
                if (data.ok && data.res) {
                    this.setState({
                        campaignList: data.res
                    })
                }
            })
    }

    handleNumItemsPerPageChange(e) {
        let value = parseInt(e)
        this.updateCampaignList(0, value)
    }

    handlePaginationChose(e) {
        let value = parseInt(e)
        this.updateCampaignList(value)
    }

    handlePaginationSelect(value) {
        this.updateCampaignList(value)
    }

    openFormModal() {
        this.setState({
            showDialogModal: true,
        })
    }

    closeFormModal() {
        this.setState({ showDialogModal: false,  showConfirm: false, editable: false })
    }

    handleAddButtonClick() {
        this.openFormModal()
        this.setState({
            isSubmited: false,
            dialogData: {},
            editable: true
        })
    }

    saveDialogForm() {
        const { dialogData, valid } = this.state
        this.setState({ isSubmited: true })
        if (!CCLiteCommonFunc.isFormValid(valid)) return

        const { auth, settingActions, loadingBarActions } = this.props
        let fleetId = auth.selectedFleet.fleetId
        let name = dialogData.name

        let body = {
            fleetId,
            name,
        }

        loadingBarActions.showLoadingSpiner()
        if (dialogData._id && dialogData._id.length > 0) {
            body = {
                ...body,
                id: dialogData._id
            }
            settingActions.updateCampaign(body)
                .then((data) => {
                    loadingBarActions.hideLoadingSpiner()
                    if (data.ok) {
                        this.closeFormModal()
                        this.updateCampaignList()
                        this.context.notification('success', I18n.t('promotionSettings.Update_campaign_success'))
                        this.setState({ isSubmited: false })
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('promotionSettings.Update_campaign_fail'))
                        }
                    }
                })
        } else {
            settingActions.createCampaign(body)
                .then((data) => {
                    loadingBarActions.hideLoadingSpiner()
                    if (data.ok) {
                        this.closeFormModal()
                        this.updateCampaignList()
                        this.context.notification('success', I18n.t('promotionSettings.Create_campaign_success'))
                        this.setState({ isSubmited: false })
                    } else {
                        if (data.error) {
                            this.context.notification('error', I18n.t('errors.' + data.error.errorCode))
                        } else {
                            this.context.notification('error', I18n.t('promotionSettings.Create_campaign_fail'))
                        }
                    }
                })
        }
    }

    handleNameChange(e) {
        const { dialogData } = this.state
        this.setState({
            dialogData: {
                ...dialogData,
                name: e.target.value
            }
        })
    }

    getTableHeight = () => {
        let verticalPadding= 10,
            toolbarMarginBottom = 10,
            toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
            parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
        let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
        this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        return this.state.tableHeight;
    };

    getTableColumns = () => {
        let tableColumns = Object.assign([], Columns);

        _.forEach(tableColumns, col => {
            switch (col.key) {
                case "name":
                    col.customCell = campaign => {
                        return (
                            <a
                                className="clickable"
                                onClick={() => {
                                    this.handleMenuClick('View', campaign);
                                }}
                            >
                                {campaign.name}
                            </a>
                        );
                    };
                    break;
                case "status":
                    col.customCell = obj => {
                        return <div className={obj.isActive ? "Active" : "Inactive"}>
                            {
                                !this.props.permissions || this.props.permissions.actions ?
                                    <a onClick={() => { this.handleMenuClick("Active", obj) }} style={{ cursor: 'pointer' }}>
                                        {
                                            obj.isActive ? <Translate value='promotionSettings.Active' />
                                                : <Translate value='promotionSettings.Inactive' />
                                        }
                                    </a> : obj.isActive ? <Translate value='promotionSettings.Active' />
                                        : <Translate value='promotionSettings.Inactive' />
                            }
                        </div>

                    };
                    break;


                case "actions":
                    col.customCell = (obj, rowIndex) => {
                        let status = obj.isActive ? "Active" : "Inactive";
                        let actions = []
                        actions.push({ label: "promotionSettings.Edit", eventKey: "Edit" })
                        if (obj.isActive) {
                            actions.push({ label: "promotionSettings.Deactivate", eventKey: "Active" })
                        } else {
                            actions.push({ label: "promotionSettings.Activate", eventKey: "Active" })
                            if (!obj.isInuse) {
                                actions.push({ label: "promotionSettings.Delete", eventKey: "Delete" })
                            }
                        }
                        return (
                            <div className="driver-actions-container">
                                {!this.props.permissions || this.props.permissions.actions ? (
                                    <TableActions
                                        rowIndex={rowIndex}
                                        rowData={obj}
                                        onSelect={this.handleMenuClick}
                                        totalRow={this.state.campaignList.list.length}
                                        rowHeight={this.state.rowHeight}
                                        menuItems={actions}
                                        tableHeight={this.state.tableHeight}
                                    />
                                ) : (
                                        <a
                                            onClick={() => this.handleMenuClick("View", obj)}
                                            href="javascript:void(0)"
                                        >
                                            <Translate value="customer.View" />
                                        </a>
                                    )}
                            </div>
                        );
                    };
                    break;
                default:
                    break;
            }
        });

        return tableColumns;
    };

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    render() {

        const bodyData =
            this.state.campaignList && this.state.campaignList.list
                ? this.state.campaignList.list
                : [];

        return (
            <div className='content'>
                {
                    this.state.showConfirm ?
                        <Modal onHide={this.closeFormModal} show={true} backdrop={true} className="confirm">
                            <Modal.Header closeButton>
                                <Modal.Title><Translate value='promotionSettings.Delete_campaign' /></Modal.Title>
                                <button type="button" className="close" aria-label="Close" onClick={this.closeFormModal}><span aria-hidden="true">×</span></button>
                            </Modal.Header>
                            <Modal.Body>
                                <Translate value='promotionSettings.Delete_confirm_campaign' />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className={"btn-save mr-md"} onClick={this.confirmDeleteCampain} ><Translate value='promotionSettings.Yes' /></Button>
                                <Button className={"btn-cancel"} onClick={this.closeFormModal}><Translate value='promotionSettings.Cancel' /></Button>
                            </Modal.Footer>
                        </Modal> : ''
                }
                {this.state.showDialogModal ? (
                    <Modal
                    show={true}
                    backdrop={true}
                    dialogClassName={"modal-max-600"}
                    onHide={this.closeFormModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.state.dialogData._id ? this.state.editable ? <Translate value='promotionSettings.Edit_campaign' /> : <Translate value='promotionSettings.Detail_campaign' />
                                    : <Translate value='promotionSettings.Add_campaign' />
                            }
                        </Modal.Title>
                        <button type="button" className="close" aria-label="Close" onClick={this.closeFormModal}><span aria-hidden="true">×</span></button>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup className={!this.state.isSubmited ? null : (this.state.valid.name ? null : 'error')}>
                            <Form.Label><Translate value='promotionSettings.Name' />{" "}<span className='require'>*</span></Form.Label>
                            <FormControl
                                type="text"
                                className="form-custom"
                                onChange={this.handleNameChange}
                                value={this.state.dialogData ? this.state.dialogData.name : ''}
                                placeholder={I18n.t("promotionSettings.Name")}
                                disabled={!this.state.editable}
                            />
                            <Validator id='name' callback={this.ValidatorCallback}>
                                <ValidCase
                                    hide={!this.state.isSubmited}
                                    valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                                    message={I18n.t('promotionSettings.Please_input_campaign_name')}
                                />
                            </Validator>
                        </FormGroup>
                        <Form.Label>(<span className='require'>*</span>): <Translate value='promotionSettings.Required_fields' /></Form.Label>
                    </Modal.Body>
                    <Modal.Footer>
                        {!this.props.permissions || this.props.permissions.actions ? this.state.editable ?
                            <Button className="btn-save mr-md" onClick={this.saveDialogForm}>
                                <Translate value='promotionSettings.Save' />
                            </Button>
                            : <Button className="btn-save mr-md"
                                onClick={(e) => { this.handleMenuClick("changeStatus", e) }}
                            >
                                <Translate value='promotionSettings.Edit' />
                            </Button>
                            : null
                        }
                        <Button className="btn-cancel" onClick={this.closeFormModal}><Translate value='promotionSettings.Cancel' /></Button>
                    </Modal.Footer>
                </Modal>
                ) : (
                ""
                )}
                {
                    !this.props.permissions || this.props.permissions.actions ?
                        <ButtonToolbar
                            ref={node => (this.toobarContainer = node)}
                            className='text-center header-button-group'
                        >
                            <Button
                                className="btn-header text-add-header ml0"
                                onClick={this.handleAddButtonClick}
                            >
                                
                                <Translate value="promotionSettings.Add" />
                            </Button>
                        </ButtonToolbar> : null
                }
                <div className="gridViewTable">
                    <StickyTable
                        columns={this.getTableColumns()}
                        bodyData={bodyData}
                        footerData={this.state.campaignList}
                        handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.rowHeight}
                        getTableHeight={this.getTableHeight}
                    />
                </div>
            </div>
        )
    }

}

Campaign.contextTypes = {
    notification: PropTypes.func,
}


function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        settings: state.settings,
        permissions: state.menuHandle.modulePermission
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);