import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Tabs, Tab, Button } from 'react-bootstrap';
import LocationItemForm from './LocationItemForm';
import { LocationListFormWrapper } from './LocationItemForm.style';

export default function ListOfLocationsForm({
  locations = [],
  handleAddMoreLocation,
  onRemoveLocation,
  activeLocation,
  onChangeTabLocation,
  handleChangeLocationForm,
  isSubmitted,
  ...other
}) {
  return (
    <LocationListFormWrapper>
      <div className="locationList__heading text-add-header no-hover">
        <Translate value="thirdPartySettings.locationList" />
        <Button
          className="btn-header text-add-header p0"
          onClick={handleAddMoreLocation}
        >
          <Translate value="thirdPartySettings.AddMore" />
        </Button>
      </div>
      <Tabs
        id="tab"
        defaultActiveKey={locations.length ? locations[0]._id : null}
        activeKey={activeLocation}
        animation={false}
        className="cue-tabs"
        onSelect={onChangeTabLocation}
      >
        {locations.map((location, index) => (
          <Tab key={location._id} eventKey={location._id} title={index + 1}>
            <LocationItemForm
              location={location}
              index={index}
              canUpdate={other.canUpdate}
              onRemove={onRemoveLocation}
              onChange={handleChangeLocationForm}
              isSubmitted={isSubmitted}
              {...other}
            />
          </Tab>
        ))}
      </Tabs>
    </LocationListFormWrapper>
  );
}
