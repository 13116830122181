import * as _ from 'lodash'
import Promise from 'bluebird'
import { PROVIDER_MAP_TYPE, locationType } from '../../constants/commondata'
import { getReverseGeoCoding } from '../../actions/mapProviderAction'
import { getZipCode, getCountryCityFromPlaces, getChannelMap } from '../../utils/mapUtils'
import { clearParams, getLanguageAutoCompleteSearch } from '../../utils/commonFunctions'

export default async function geocoding(address, count = 0) {
  if (!address || address === 'N/A') {
    return { success: false, status: 'No address' }
  }
  address = address.trim()
  try {
    if (this.props.importBookings.addresses[address]) {
      return {
        success: true,
        cached: true,
        data: this.props.importBookings.addresses[address],
      }
    }
    const { location, result } = await geocodeByAddress.call(this, address)
    if (!location || !result) {
      return { success: false, status: 'Geocoding failed' }
    }
    const addressComponents = result.address_components || []
    const getDetails = getAddressDetails(addressComponents)
    const addressObject = {
      geo:
        location.lng === null || location.lat === null
          ? []
          : [location.lng, location.lat],
      city: location.city,
      timezone: location.timezone,
      cityName: location.cityName,
      address: location.address || result.formatted_address || address,
      zipCode: location.zipCode,
      country: location.countryCode,
      from: locationType.google,
      instructionLink: location.instructionLink || '',
      addressDetails: {
        cityName: getDetails('administrative_area_level_1').long_name || '',
        country: getDetails('country').long_name || '',
        floor: getDetails('floor').long_name || '',
        pointOfInterest: getDetails('point_of_interest').long_name || '',
        political: getDetails('political').long_name || '',
        postalCode: getDetails('postal_code').long_name || '',
        room: getDetails('room').long_name || '',
        route: getDetails('route').long_name || '',
        streetNumber: getDetails('street_number').long_name || '',
        countryCode: getDetails('country').short_name || '',
      },
    }
    const currentAddresses = this.props.importBookings.addresses
    this.props.importBookingsAction.setNumbersStatus({
      addresses: {
        ...currentAddresses,
        [address]: addressObject,
      },
    })
    return { success: true, data: addressObject }
  } catch (e) {
    if (e.status === 'OVER_QUERY_LIMIT' && count < 100) {
      await Promise.delay(100)
      return geocoding.call(this, address, count + 1)
    }
    return {
      success: false,
      status: e.status || 'Geocoding failed',
      message: e.message,
    }
  }
}

async function geocodeByAddress(address) {
  const data = await getReverseGeoCoding(clearParams({
    input: address,
    fleetId: this.props.selectedFleet.fleetId,
    forceProvider: this.props.selectedFleet.generalSetting?.useTencentMapOnCC ? PROVIDER_MAP_TYPE.tencent : '',
    userId: this.props.auth?.user?._id,
    channel: getChannelMap(),
    language: getLanguageAutoCompleteSearch(this.props.language?.locale),
  }))
  if (!data || !data.res) {
    return {}
  }
  const { status, result } = data.res
  if (status !== 'OK') {
    return {}
  }
  result.address_components = result.address_components || []
  const countryObject = (result.address_components || []).find((item) =>
    item.types.includes('country')
  )

  const location = {
    lat: result.geometry.location.lat,
    lng: result.geometry.location.lng,
    zipCode: getZipCode(result),
    city: getCountryCityFromPlaces(result),
    country: countryObject ? countryObject.long_name : '',
    countryCode: countryObject ? countryObject.short_name : '',
  }

  return { location, result }
}

function getAddressDetails(items) {
  return (type) => {
    let result = {}
    items.forEach((item) => {
      const check = item.types.indexOf(type)
      if (check !== -1) {
        result = item
      }
    })
    return result
  }
}
