import React from 'react';
import {
  Button,
  Modal,
} from "react-bootstrap";
import { Translate } from "react-redux-i18n";
import AddSurcharge from './ModalAddSurcharge'
import AdditionalService from './ModalAdditionalService'
import FleetService from './ModalFleetService'
import AddServiceFeesSurcharge from './ModalAddServiceFeesSurcharge'
import CustomizedAirportFee from './ModalCustomizedAirportFee'
import CustomizedFaceMaskZone from './ModalCustomizedFaceMaskZone';

export const ModalConfirmDeleteSurcharge = props => {
  return (
    <Modal
      show={true}
      backdrop={true}
      className={'confirm'}
      onHide={props.closeDeleteServiceDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.itemConfirm == 'surcharge' ? (
            <Translate value="generalSetting.DELETE_SURCHARGE" />
          ) : props.itemConfirm == 'fleetService' ? (
            // <Translate value="generalSetting.DELETE_Fleet_service" />
            'Delete Fleet Service'
          ) : (
            <Translate value="generalSetting.DELETE_SERVICE" />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.itemConfirm == 'surcharge' ? (
          <Translate value="generalSetting.DELETE_SURCHARGE_CONFIRM" />
        ) : props.itemConfirm == 'service' ? (
          <Translate value="generalSetting.DELETE_ADDITIONAL_SERVICES_CONFIRM" />
        ) : props.itemConfirm == 'fleetService' ? (
          // <Translate value="generalSetting.DELETE_FLEET_SERVICES_CONFIRM" />
          'Are you sure you want to delete the selected fleet service?'
        ) : (
          <Translate value="generalSetting.DELETE_CONFIRM" />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className={'btn-save mr-md'} onClick={props.confirmDelete}>
          <Translate value="generalSetting.Yes" />
        </Button>
        <Button
          className={'btn-cancel'}
          onClick={props.closeDeleteServiceDialog}
        >
          <Translate value="generalSetting.No" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalConfirmChangeApplyType = props => {
  return (
    <Modal
      show={true}
      backdrop={true}
      className={'confirm'}
      onHide={props.closeConfirmDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {
            props.title ?  props.title : <Translate value="generalSetting.CHANGE_APPLY_SERVICE_FEES" />
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.applyType === 'all' ? <Translate value="generalSetting.CHANGE_APPLY_SERVICE_FEES_CONFIRM_ALL" /> : null }
        {props.applyType === 'custom_zone' ? <Translate value="generalSetting.CHANGE_APPLY_SERVICE_FEES_CONFIRM_CUSTOM" /> : null }
      </Modal.Body>
      <Modal.Footer>
        <Button className={'btn-save mr-md'} onClick={props.confirmChangeType}>
          <Translate value="generalSetting.Yes" />
        </Button>
        <Button
          className={'btn-cancel'}
          onClick={props.closeConfirmDialog}
        >
          <Translate value="generalSetting.No" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalConfirmEditServiceFees = props => {
  return (
    <Modal
      show={true}
      backdrop={true}
      className={'confirm'}
      onHide={props.closeConfirmDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translate value="generalSetting.EDIT_SERVICE_FEES" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Translate value="generalSetting.EDIT_SERVICE_FEES_CONFIRM" currentZone={props.currentZone}  />
      </Modal.Body>
      <Modal.Footer>
        <Button className={'btn-save mr-md'} onClick={props.handleConfirm}>
          <Translate value="generalSetting.Yes" />
        </Button>
        <Button
          className={'btn-cancel'}
          onClick={props.closeConfirmDialog}
        >
          <Translate value="generalSetting.No" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ModalAddSurcharge = AddSurcharge;
export const ModalAdditionalService = AdditionalService;
export const ModalFleetService = FleetService;
export const ModalAddServiceFeesSurcharge = AddServiceFeesSurcharge;
export const ModalCustomizedAirportFee = CustomizedAirportFee;
export const ModalCustomizedFaceMaskZone = CustomizedFaceMaskZone;
