import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';
import currencyFormatter from 'currency-formatter';

export const fields = [
  {
    key: 'dateAndTime',
    label: 'report.result.creditWallet.dateAndTime',
    mutate: ReportUtils.formatTime,
    summary: 'books',
    summaryMutate: (value, obj) => `${value}/${obj.totalBookings}`,
  },
  {
    key: 'driverName',
    label: 'report.result.creditWallet.driverName',
  },
  {
    key: 'phoneNumber',
    label: 'report.result.creditWallet.phoneNumber',
  },
  {
    key: 'userName',
    label: 'report.result.creditWallet.userName',
    isShow: 'driverUserName',
  },
  {
    key: 'company',
    label: 'report.result.creditWallet.company',
  },
  {
    key: 'type',
    label: 'report.result.creditWallet.type',
    mutate: (value, doc) => {
      switch (value) {
        case 'insurance':
          return I18n.t('report.result.creditWallet.insurance');
        case 'topUpWallet':
          return I18n.t('report.query.transactionTypeItem.topUpWallet').format(
            doc.walletName ? I18n.t(`report.query.paymentMethodItem.${doc.walletName}`) : 'External Wallet'
          );
        default:
          return I18n.t(`report.query.transactionTypeItem.${value}`);
      }
    },
  },
  {
    key: 'bookingId',
    label: 'report.result.creditWallet.bookingId',
  },
  {
    key: 'type',
    label: 'report.result.creditWallet.receiverType',
    mutate: (value, doc) => {
      switch (value) {
        case 'sentToCustomer':
          return 'Customer';
        case 'sentToDriver':
          return 'Driver';
        default:
          return '';
      }
    },
  },
  {
    key: 'receiverName',
    label: 'report.result.creditWallet.receiverName',
  },
  {
    key: 'receiverPhone',
    label: 'report.result.creditWallet.receiverPhone',
  },
  {
    key: 'senderName',
    label: 'report.result.creditWallet.senderName',
  },
  {
    key: 'senderPhone',
    label: 'report.result.creditWallet.senderPhone',
  },
  {
    key: 'operator',
    label: 'report.result.creditWallet.operator',
  },
  {
    key: 'reason',
    label: 'report.result.creditWallet.reason',
    mutate: (value, doc) => {
      switch (doc.type) {
        case 'topUp':
          return I18n.t('report.result.creditWallet.topUpReason').format(
            doc.cardMasked ? doc.cardMasked.slice(-4) : '(N/A)'
          );
        case 'cashWallet':
          return I18n.t('report.result.creditWallet.cashWalletReason');
        case 'topUpGCash':
          return I18n.t('report.result.creditWallet.topUpGCashReason');
        case 'bookingDeduction':
          return '';
        case 'insurance':
          return I18n.t('report.result.creditWallet.insurance');
        case 'topUpMOLPay':
          return I18n.t('report.result.creditWallet.topUpMOLPayReason').format(
            value
          );
        case 'topUpWallet':
          return I18n.t('report.result.creditWallet.topUpWalletReason').format(
            doc.walletName ? I18n.t(`report.query.paymentMethodItem.${doc.walletName}`) : 'External Wallet',
            value
          );
        case 'cashExcess':
          return I18n.t('report.result.creditWallet.cashExcessReason').format(
            currencyFormatter.format(doc.bookingFare, {
              code: doc.currency,
            }),
            currencyFormatter.format(doc.receivedAmount, {
              code: doc.currency,
            })
          );
        case 'driverTopUpForPax':
          if (doc.bookingId) {
            return I18n.t('report.result.creditWallet.cashExcessReason').format(
              currencyFormatter.format(doc.bookingFare, {
                code: doc.currency,
              }),
              currencyFormatter.format(doc.receivedAmount, {
                code: doc.currency,
              })
            );
          }
          return doc.reason;
        case 'referralEarning':
          return doc.reason
        default:
          return value;
      }
    },
    width: 250,
  },
  {
    key: 'chargedAmount',
    label: 'report.result.creditWallet.chargedAmount',
    negativable: true,
    summary: 'chargedAmount',
    summaryUnit: 'currencyISO',
  },
  {
    key: 'differentPercent',
    label: 'report.result.creditWallet.differentPercent',
    negativable: true,
    width: 250 /* ,
    mutate: (value, doc) => {
      if (!value) return '';
      switch (doc.chargedType) {
        case 'discount':
          return value === 0 ? value.toString() : '(' + value + ')';
        default:
          return value ? value.toString() : 0;
      }
    } */,
  },
  {
    key: 'amount',
    label: 'report.result.creditWallet.amount',
    negativable: true,
    summary: 'amount',
    summaryUnit: 'currencyISO',
  },
  {
    key: 'newAmount',
    label: 'report.result.creditWallet.newAmount',
    negativable: true,
  },
  {
    key: 'currency',
    label: 'report.result.creditWallet.currency',
  },
];
