import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Translate } from 'react-redux-i18n';
import CarTypeSelect from './CarTypeSelect';
import CompanySelect from './CompanySelect';
import RouteSelect from './RouteSelect';
import DepartureDatePicker from './DepartureDatePicker';
import DepartureTimeSelect from './DepartureTimeSelect';
import PickupLocation from './PickupLocation';
import DestinationLocation from './DestinationLocation';

class LocationInfoForm extends PureComponent {
  handleChangeCompany = (company) => {
    this.props.onChange('companyId', _.get(company, '_id'));
  };

  handleChangeDepartureDate = (value) => {
    this.props.onChange('departureDate', value);
  };

  handleChangeDepartureTime = (value) => {
    this.props.onChange('departureTime', value);
  };

  handleChangeCartype = (value) => {
    this.props.onChange('departureTime', null);
  };

  render() {
    const {
      request,
      drvInfo,
      valid,
      validatorCallback,
      isSubmited,
      isBookDetail,
      isViewOnly,
      getLocationService,
      status,
      travelerType,
      corporateInfo
    } = this.props;

    let isDisableTime = false;
    if (isBookDetail && (drvInfo && drvInfo.userId)) {
      isDisableTime = true
    };

    // Allow status is confirmed, booked change destination.
    let isDisableDestination = isViewOnly;
    if (isViewOnly &&
      ["confirmed", "booked"].findIndex(e => e === status) !== -1) {
      isDisableDestination = false;
    };

    return (
      <Fragment>
        <FormGroupTitle>
          <Translate value="newbooking.BOOKING_INFORMATION" />
        </FormGroupTitle>
        <RouteSelect
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          valid={valid}
          request={request}
          disabled={isBookDetail}
          isViewOnly={isViewOnly}
        />
        <CarTypeSelect
          validatorCallback={validatorCallback}
          request={request}
          isSubmited={isSubmited}
          onChange={this.handleChangeCartype}
          valid={valid}
          disabled={isBookDetail}
          isViewOnly={isViewOnly}
        />
        <CompanySelect
          value={request.companyId}
          onChange={this.handleChangeCompany}
          valid={valid}
          disabled={isBookDetail}
          isViewOnly={isViewOnly}
          travelerType={travelerType}
          corporateInfo={corporateInfo}
        />
        <DepartureDatePicker
          value={request.departureDate}
          onChange={this.handleChangeDepartureDate}
          valid={valid}
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          disabled={isDisableTime}
          isViewOnly={isViewOnly}
        />
        <DepartureTimeSelect
          value={request.departureTime}
          departureTimeTo={request.departureTimeTo}
          tripType={request.tripType}
          onChange={this.handleChangeDepartureTime}
          valid={valid}
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          disabled={isDisableTime}
          isViewOnly={isViewOnly}
        />
        <PickupLocation
          request={request}
          valid={valid}
          options={this.props.options}
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          isViewOnly={isViewOnly}
          getLocationService={getLocationService}
          />
        <DestinationLocation
          request={request}
          options={this.props.options}
          valid={valid}
          validatorCallback={validatorCallback}
          isSubmited={isSubmited}
          isViewOnly={isDisableDestination}
          getLocationService={getLocationService}
        />
      </Fragment>
    );
  }
}

export default LocationInfoForm;
