import { callApi, UrlBuilder } from "../utils/apiUtils";

import {
  getExportPercentApi,
  getWidthdrawalRequestsApi,
  resolveDrawalRequestApi,
  resolveMultiDrawalRequestApi,
  deleteExportApi,
  notifyCrashClientAPI
} from "../constants/ApiConfigs";

export const SET_BOOKING_LIST = "SET_BOOKING_LIST";
export const SAVE_INTERVAL = "SAVE_INTERVAL";
export const REMOVE_INTERVAL = "REMOVE_INTERVAL";

export function fetchReportDataFromApi(url, queryBody) {
  queryBody = Object.assign({}, queryBody);
  const config = {
    method: "post",
    body: JSON.stringify(queryBody)
  };
  return callApi(url, config);
}

export function deleteExportFromApi(queryBody) {
  queryBody = Object.assign({}, queryBody);
  const config = {
    method: "post",
    body: JSON.stringify(queryBody)
  };
  return callApi(deleteExportApi, config);
}

export function getExportPercent(options) {
  const config = {
    method: "get"
  };
  return callApi(UrlBuilder(getExportPercentApi, options), config);
}

export function payToDriver(url, body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(url, config);
}

export function clearSettlement(url, body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(url, config);
}

export function sendReceipt(url, body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(url, config);
}

export function sendPayout(url, body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(url, config);
}

export function saveInterval(interval) {
  return {
    type: SAVE_INTERVAL,
    interval
  };
}

export function removeInterval(interval) {
  return {
    type: REMOVE_INTERVAL,
    interval
  };
}

export const getWithdrawalRequests = (options = {}) => {
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(getWidthdrawalRequestsApi, config, null, null, null, true);
};

export const resolveDrawalRequest = (options = {}) => {
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(resolveDrawalRequestApi, config, null, null, null, true);
};

export const resolveMultiDrawalRequest = (options = {}) => {
  const config = {
    method: "post",
    body: JSON.stringify(options)
  };
  return callApi(resolveMultiDrawalRequestApi, config, null, null, null, true);
};
export function payoutToDriver(url, body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(url, config);
}

export function notifyCrashClient(body) {
  const config = {
    method: "post",
    body: JSON.stringify(body)
  };
  return callApi(notifyCrashClientAPI, config);
}