import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import ExportComponent from '../../../../components/ExportComponent'
import {
  Modal,
  FormGroup,
  Form,
  Button,
  Tabs,
  Tab,
} from 'react-bootstrap';
import UploadFile from '../../../../components/upload/upload';
const FILE_ACCEPTED = '.xls, .xlsx';
const MAX_FILE_SIZE = 5000000;
class ImportAssignRate extends React.Component {
  constructor() {
    super();
    this.state = {
      successItem: [],
      failedItem: [],
      serviceSupported: [
        {
          serviceName: 'Regular',
          keyInfo: 'regularInfo',
        },
        {
          serviceName: 'Delivery',
          keyInfo: 'deliveryInfo',
        },
        {
          serviceName: 'Hourly',
          keyInfo: 'hourlyInfo',
        },
        {
          serviceName: 'Flat',
          keyInfo: 'flatInfo',
        },
        {
          serviceName: 'Intercity',
          keyInfo: 'intercityInfo',
        },
        {
          serviceName: 'streetSharing',
          keyInfo: 'streetSharingInfo',
        },
      ],
      currentTab: 0,
    };
    this.handleFileChange = this.handleFileChange.bind(this);
    this.isValidDocumentFile = this.isValidDocumentFile.bind(this);
    this.handleImportCustomerClick = this.handleImportCustomerClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    const fleetconfig = this.props.auth.selectedFleet || {};
    const newServiceSupported = this.state.serviceSupported || []
    if (fleetconfig.transport && !fleetconfig.transport.enable) {
      _.remove(newServiceSupported, (item) => item.serviceName === 'Regular');
      _.remove(newServiceSupported, (item) => item.serviceName === 'Hourly');
    }
    if (!fleetconfig.hasCarStreetSharingActive) {
      _.remove(newServiceSupported, (item) => item.serviceName === 'streetSharing');
    }
    if (fleetconfig.intercity && !fleetconfig.intercity.enable) {
      _.remove(newServiceSupported, (item) => item.serviceName === 'Intercity');
    }
    if (fleetconfig.intercity && 
      !fleetconfig.transport.enable &&
      !fleetconfig.delivery.enable &&
      !fleetconfig.food.enable &&
      !fleetconfig.mart.enable
    ) {
     _.remove(newServiceSupported, (item) => item.serviceName === 'Flat');
    }
    if (fleetconfig.delivery && 
      !fleetconfig.delivery.enable &&
      !fleetconfig.food.enable &&
      !fleetconfig.mart.enable
    ) {
      _.remove(newServiceSupported, (item) => item.serviceName === 'Delivery');
    }
    this.setState({
      serviceSupported: newServiceSupported
    })
  };

  handleFileChange(data) {
    //e.preventDefault();
    let file = null;
    if (data) file = data.file;
    if (file && this.isValidDocumentFile(file)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          file: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      document.getElementById('file').value = '';
      this.setState({
        file: null,
      });
    }
  }

  isValidDocumentFile(file) {
    let fileExtensions = FILE_ACCEPTED.split(', ');
    let fileName =
      file.name.substring(file.name.lastIndexOf('.'), file.name.length) ||
      file.name;
    if (fileExtensions.indexOf(fileName.toLowerCase()) === -1) {
      this.context.notification(
        'error',
        I18n.t('promotionSettings.Invalid_file')
      );
      return false;
    } else if (file.size > MAX_FILE_SIZE) {
      this.context.notification(
        'error',
        I18n.t('promotionSettings.File_size_larger_than_5Mb')
      );
      return false;
    }
    return true;
  }

  handleImportCustomerClick() {
    if (!this.state.file) {
      return;
    }
    var data = new FormData();
    data.append('fleetId', this.props.auth.selectedFleet.fleetId);
    data.append('file', this.state.file);
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.import(data).then((respone) => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (respone.ok) {
        this.context.notification(
          'success',
          I18n.t('promotionSettings.Import_promotions_success')
        );
        this.setState({
          importSuccess: true,
          regularInfo: _.get(respone, 'res.regularInfo', {}),
          deliveryInfo: _.get(respone, 'res.deliveryInfo', {}),
          flatInfo: _.get(respone, 'res.flatInfo', {}),
          hourlyInfo: _.get(respone, 'res.hourlyInfo', {}),
          intercityInfo: _.get(respone, 'res.intercityInfo', {}),
          streetSharingInfo: _.get(respone, 'res.streetSharingInfo', {}),
        });
        this.props.callback()
      } else if (respone.error) {
        this.context.notification('error', _.get(respone, 'error.message', ''));
      }
    });
  }

  closeDialog() {
    this.setState({
      importSuccess: false,
      length: 0,
      skip: 0,
      imported: 0,
    });
    this.props.closeDialog();
  }

  buildSuccessCode(codes) {
    let codeString = codes
      .map((obj) => {
        return `${obj.promotionCode}${
          obj.customers.assigned > 0
            ? `(${obj.customers.assigned}/${obj.customers.total} ${I18n.t(
                'promotionSettings.Phone_Numbers'
              )})`
            : ''
        }`;
      })
      .join(', ');
    return I18n.t('promotionSettings.Import_Promotion_code_success').format(
      codeString
    );
  }

  buildFailedCode(codes) {
    let codeString = codes.join(', ');
    return I18n.t('promotionSettings.Import_Promotion_code_fail').format(
      codeString
    );
  }

  handleTabSelect = (key) => {
    this.setState({ currentTab: key });
  };

  render() {
    const { serviceSupported } = this.state;
    if (this.props.isShow) {
      return (
        <Modal
          show={true}
          onHide={this.closeDialog}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="confirm"
          className="confirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="promotionSettings.Import_Promotions" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix">
            {this.state.importSuccess ? (
              <Tabs
                activeKey={this.state.currentTab}
                id="controlled-tab"
                onSelect={this.handleTabSelect}
                defaultActiveKey={
                  serviceSupported[0] && serviceSupported[0].serviceName
                }
              >
                {serviceSupported.map((service, id) => {
                  const serviceInfo = this.state[service.keyInfo] || {};
                  return (
                    <Tab
                      eventKey={id}
                      title={I18n.t(`carSetting.${service.serviceName}`)}
                    >
                      <div className="contentImport">
                        <p>{`- Number of successful imports: ${serviceInfo.totalSuccess || 0}`}</p>
                        <p>{`- Number of skipped:  ${
                          (serviceInfo.removedItem &&
                            serviceInfo.removedItem.length) ||
                          0
                        }`}</p>
                        <ul>
                          {serviceInfo.removedItem &&
                            serviceInfo.removedItem.map((ob) => {
                              return (
                                <li
                                  key={ob.orderExcel}
                                >{`${ob.orderExcel}: ${ob.reason}`}</li>
                              );
                            })}
                        </ul>
                      </div>
                    </Tab>
                  );
                })}
              </Tabs>
            ) : (
              <FormGroup>
                <div className="body-import">
                  <Form.Label>
                    <Translate value="customer.File" />
                  </Form.Label>
                  <a
                    href={`${process.env.REACT_APP_S3_SERVER}/templates/ImportAssignRateTemplate.xlsx`}
                    className="linkTemplate"
                  >
                    <Translate value="customer.Download_template" />
                  </a>
                </div>
                <UploadFile
                  id={'file'}
                  name={'fileImport'}
                  onChange={this.handleFileChange}
                  accept={FILE_ACCEPTED}
                />
                <p>
                  You can 
                  <ExportComponent 
                    prepareParamExport={this.props.prepareParamExport}
                    urlExport={this.props.urlExport}
                    user={this.props.user}
                    title={'export'}
                  /> 
                  your data to edit it and then Import
                </p>
              </FormGroup>
            )}
          </Modal.Body>
          {this.state.importSuccess ? null : (
            <Modal.Footer>
              <Button className="btn-cancel mr-md" onClick={this.closeDialog}>
                <Translate value="promotionSettings.Cancel" />
              </Button>
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className="btn-save"
                  onClick={this.handleImportCustomerClick}
                >
                  <Translate value="promotionSettings.Save" />
                </Button>
              ) : null}
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return null;
    }
  }
}

ImportAssignRate.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ImportAssignRate);
