import styled from 'styled-components';

export const StripeFromWrapper = styled.div`
  .StripeElement {
    box-sizing: border-box;
    height: 34px;
    padding: 6px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #252830;
  }

  .StripeElement--focus {
    border: 1px solid #04BE76;
  }

  .StripeElement--invalid {
    border: 1px solid #a94442;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;