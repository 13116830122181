import React from "react";
import { FormControl, Button } from "react-bootstrap";
import { I18n, Translate } from "react-redux-i18n";
import "./style.scss";
import ReactDOM from "react-dom";
import { debounce, find, filter, map } from "lodash";

class CustomMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      isLoading: false
    };
  }

  componentDidMount() {
    if (this.props.onScrollBottom) {
      this.menuContainer.addEventListener(
        "scroll",
        debounce(this.handleScrollEvent, this.props.asyncDelay || 300)
      );
    }
  }

  componentWillUnmount() {
    if (this.props.onScrollBottom) {
      this.menuContainer.removeEventListener(
        "scroll",
        debounce(this.handleScrollEvent, this.props.asyncDelay || 300)
      );
    }
  }

  handleScrollEvent = e => {
    let element = e.target;
    if (element) {
      if (
        element.scrollHeight - element.scrollTop <=
        element.clientHeight + 30
      ) {
        this.props.onScrollBottom();
      }
    }
  };

  handleChange = e => {
    let inputValue = e.target.value;
    const { async, onSearchChange } = this.props;
    if (async) {
      let calllBack = () => {
        this.setState({ isLoading: false });
      };
      this.setState({ value: inputValue, isLoading: true }, () =>
        onSearchChange(this.state.value.trim(), calllBack)
      );
    } else {
      this.setState({ value: inputValue });
    }
  };

  focusNext() {
    const input = ReactDOM.findDOMNode(this.input);

    if (input) {
      input.focus();
    }
  }

  handleRootClose = event => this.props.onClose(event, { source: "rootClose" });

  handleMenuItemClick = (eventKey, event) => {
    const { multiSelect, onClose, onSelect } = this.props;
    // if (!multiSelect) onClose(event);
    onSelect(eventKey, event);
  };

  handleClearAllItemClick = event => {
    const { multiSelect, onClose, onSelect, intercityRoutesMode } = this.props;
    // onClose(event, { source: "clearAllItem" });
    this.setState({ value: "" });
    if (intercityRoutesMode) {
      onSelect("", event, true);
    } else {
      onSelect(!multiSelect ? "" : [], event, true);
    }
  };

  handleApplyClick = () => {
    const { onClose, applyButton } = this.props;
    // onClose({}, { source: "rootClose" });
    applyButton();
    this.setState({ value: "" });
  };

  renderChildren = () => {
    const { isLoading } = this.state;
    const { async, intercityRoutesMode } = this.props;
    if (isLoading) {
      return (
        <li>
          <a href="#">{I18n.t("message.Searching")}</a>
        </li>
      );
    }

    const { children, labelKey, items, valueKey } = this.props;
    let search = this.state.value;
    let filters = [],
      searchValue = search.trim().toLowerCase(),
      childArray = React.Children.toArray(children);

    if (searchValue && !async) {
      filters = filter(childArray, child => {
        let existed = find(items, item => {
          if (intercityRoutesMode) {
            var searchKey = item[valueKey] + "." + item.routeNumber;
            if (child.props.eventKey !== searchKey)
              return false;
          } else {
            if (child.props.eventKey !== item[valueKey] || !item[labelKey])
              return false;
          }
          var regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/g
          let text = regex.test(item[labelKey]) ? I18n.t(item[labelKey]) : item[labelKey];
          return text.toLowerCase().indexOf(searchValue) !== -1;
        });
        return existed ? true : false;
      });
    } else {
      filters = childArray;
    }
    if (!filters.length) {
      return (
        <li className="no-result">
          <a href="#">{I18n.t("message.No_results")}</a>
        </li>
      );
    }
    return map(filters, child =>
      React.cloneElement(child, {
        onSelect: this.handleMenuItemClick
      })
    );
  };

  render() {
    const {
      open,
      rootCloseEvent,
      bsPrefix,
      searchable,
      enableClearAll,
      totalSelect,
      pullRight,
      applyButton,
      footer,
      hasActionBtn,
      actionBtnFunc,
      textActionBtn
    } = this.props;

    const { value } = this.state;

    return (
      // <RootCloseWrapper
      //   disabled={!open}
      //   onRootClose={this.handleRootClose}
      //   event={rootCloseEvent}
      // >
        <>
          {searchable && (
            <div className="search-container">
              <FormControl
                ref={c => {
                  this.input = c;
                }}
                type="text"
                placeholder="Search"
                onChange={this.handleChange}
                value={value}
                className="search-input"
                autoFocus
              />
            </div>
          )}
          {enableClearAll && totalSelect ? (
            <a
              className="clear-all-item"
              onClick={this.handleClearAllItemClick}
            >
              {I18n.t("cue.clearSelectedItems")}
            </a>
          ) : null}
          <div
            className={"menu-container"}
            ref={node => (this.menuContainer = node)}
          >
            <ul className="list-unstyled">
              {this.renderChildren()}
            </ul>
            {
              hasActionBtn && (
              <div className="dropDown_apply">
                <Button
                  className="btn-save view-button"
                  onClick={actionBtnFunc}
                  // disabled={isDisabledViewButton}
                >
                  {/* {this.props.loading ? 'Loading...' : <Translate value="report.query.view" />} */}
                  {textActionBtn ? textActionBtn : 'Apply'}
                </Button>
              </div>
              )
            }
          </div>
          {footer && (
            <div className="dropdown-footer">
              {applyButton && (
                <a
                  href="javascript:void(0)"
                  className="text-active"
                  onClick={this.handleApplyClick}
                >
                  <Translate value="customer.Apply" />
                </a>
              )}
            </div>
          )}
        </>
      // </RootCloseWrapper>
    );
  }
}

export default CustomMenu;
