import React from 'react';
import classNames from 'classnames';
import { QueueColor } from './colorData';

export default function AreaNameWithColor({
  name,
  color,
  borderColor,
  active,
  onClick
}) {
  const wrapClass = classNames({
    areaName: true,
    'text-active': active || false
  });
  return (
    <div className={wrapClass} onClick={onClick}>
      <span
        className="areaName__colorIcon"
        style={{ backgroundColor: active ? QueueColor.yourChoosen.fill : color, borderColor: active ? QueueColor.yourChoosen.border : borderColor }}
      />
      <span>{name}</span>
    </div>
  );
}
