import TimeAgo from 'react-timeago';
import React, { Component } from 'react';
import {
  FormGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';
import _, { debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import ReportComponent from '../../reports/base/components';
import * as commonData from '../../../constants/commondata';
import Datetime from '../../../components/dateTime/DateTime';
import ExportComponent from '../../../components/ExportComponent';
import Filter from '../../reports/base/components/Filter';
import './BaseNotifications.scss';
import * as reportActions from '../../../actions/reportActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import GetEmailExpModal from '../../../components/getEmailExpModal';
import { renderMSGElement, checkEnable3rdPartyIntegration, handleResultExport, checkEnableHolidayTaxis, checkEnableKarhoo, checkEnableHoppa } from '../../../utils/commonFunctions'
import { BsSearch } from 'react-icons/bs';

const { DateRange } = ReportComponent;

class BaseNotifications extends Component {
  constructor(props) {
    super(props);
    const { timezone } = props.selectedFleet;
    let defaulDateRange = Array.isArray(props.dateRange) && props.dateRange.find(item => item.default);
    defaulDateRange = (defaulDateRange && defaulDateRange.value) || 'past30Days';
    this.state = {
      showPercent: false,
      notificationTypesStatus: commonData.notificationsStatus,
      exportPercent: 0,
      list: [],
      page: 0,
      timezone,
      queryBody: {
        fleetId: props.selectedFleet.fleetId,
        from: 0,
        size: 20,
        criteria: {
          selectedButton: defaulDateRange,
          fromDate: moment()
            .startOf('day')
            .toISOString(),
          toDate: moment().toISOString(),
          timezone: props.selectedFleet.timezone,
          month: moment().format('YYYY-MM'),
          notificationType: props.isSOS ? 'all' : commonData.notificationsStatus.map(item => item.value),
          txtSearch: '',
          sosTypeId: 'all'
        }
      }
    };
    this.handleResultExport = handleResultExport.bind(this);
  }

  componentDidMount() {
    this.fetchReportData();
    this.menuContainer.addEventListener('scroll', debounce(this.handleScrollEvent, 300));
      let newNotifyStatus = commonData.notificationsStatus
    if(!checkEnable3rdPartyIntegration(this.props.selectedFleet)) {
      newNotifyStatus = newNotifyStatus.filter(ob => {
        return !['newFarmInJobFrom3rd', 'canceledJobBy3rd', 'updatedJobBy3rd'].includes(ob.value)
      })
    }
    if(!checkEnableHolidayTaxis(this.props.selectedFleet)) {
      newNotifyStatus = newNotifyStatus.filter(ob => {
        return !['newFarmInJobFromHolidayTaxis', 'canceledJobByHolidayTaxis', 'updatedJobByHolidayTaxis'].includes(ob.value)
      })
    }
    if(!checkEnableKarhoo(this.props.selectedFleet)) {
      newNotifyStatus = newNotifyStatus.filter(ob => {
        return !['newFarmInJobFromKarhoo', 'canceledJobByKarhoo', 'updatedJobByKarhoo'].includes(ob.value)
      })
    }
    if(!checkEnableHoppa(this.props.selectedFleet)) {
      newNotifyStatus = newNotifyStatus.filter(ob => {
        return !['newFarmInJobFromHoppa', 'canceledJobByHoppa', 'updatedJobByHoppa'].includes(ob.value)
      })
    }
    this.setState({
      notificationTypesStatus: newNotifyStatus,
      queryBody: {
        ...this.state.queryBody,
        criteria: {
          ...this.state.queryBody.criteria,
          notificationType: this.props.isSOS ? 'all' : newNotifyStatus.map(item => item.value)
        }
      }
    })
  }

  componentWillUnmount() {
    this.menuContainer.removeEventListener('scroll', debounce(this.handleScrollEvent, 300));
  }

  shouldComponentUpdate(nextProps) {
    const { newNotification: currentNewNofitication } = this.props;
    const { newNotification: nextNewNofitication } = nextProps;
    if (nextNewNofitication && (!currentNewNofitication || nextNewNofitication.id !== currentNewNofitication.id)) {
      const { queryBody: { criteria: { toDate } = {} } = {} } = this.state;
      if (moment(toDate) > moment()) {
        this.setState({
          list: [
            nextNewNofitication,
            ...this.state.list
          ]
        });
      }
    }
    return true;
  }

  handleScrollEvent = e => {
    const element = e.target;
    if (element) {
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 30) {
        this.loadMoreNotification();
      }
    }
  };

  loadMoreNotification = () => {
    const { page, total } = this.state;
    const newState = Object.assign({}, this.state);
    const newPage = page + 1;
    newState.queryBody.from = newPage * 20;
    newState.page = newPage;
    if (newPage * 20 > total) return false;
    this.setState(newState, () => {
      this.fetchReportData();
    });
  };

  fetchReportData = params => {
    if (params && params.export) {
      this.props.loadingBarActions.showLoadingSpiner();
      return this.props.reportActions
        .fetchReportDataFromApi(this.props.apiurl, params)
        .then(resp => {
          this.props.loadingBarActions.hideLoadingSpiner();
          return this.handleResultExport(resp, params.isCheckExportExisted)
        })
        .catch((error) => {
          this.props.loadingBarActions.hideLoadingSpiner()
          console.log('Export error', error)
        })
    }
    const queryBody = _.pick(this.state.queryBody, ['from', 'size', 'fleetId']);
    queryBody.locale = localStorage.getItem('language') || 'en';
    queryBody.localTimezone = moment.tz.guess();

    const criteriaFields = ['notificationType', 'txtSearch', 'fromDate', 'toDate', 'timezone'];
    const { criteria } = this.state.queryBody;
    queryBody.criteria = _.pick(criteria, criteriaFields);
    const fleetId = queryBody.fleetId || _.get(this.props, 'selectedFleet.fleetId', null);
    const requestBody = Object.assign({}, queryBody, { fleetId });
    requestBody.criteria.timezone = _.get(this.props, 'selectedFleet.timezone', moment.tz.guess());
    const timeFields = ['fromDate', 'toDate'];
    const { timezone } = this.state;
    timeFields.forEach(field => {
      if (requestBody.criteria[field]) {
        const temp = moment(requestBody.criteria[field]).format('YYYY-MM-DD HH:mm:ss');
        requestBody.criteria[field] = moment.tz(temp, timezone).toISOString();
      }
    });

    if(this.props.isSignUpNotification && requestBody.criteria) {
      requestBody.criteria.notificationType = ['all']
    }

    this.props.loadingBarActions.showLoadingSpiner();
    return this.props.reportActions
      .fetchReportDataFromApi(this.props.apiurl, requestBody)
      .then(resp => {
        this.props.loadingBarActions.hideLoadingSpiner();
        const list = this.state.page > 0 ? this.state.list.concat(resp.res.list) : resp.res.list;
        const summary = resp.res && resp.res.summary ? resp.res.summary : {};
        const size = resp.res && resp.res.size ? resp.res.size : 20;
        const from = resp.res && resp.res.from ? resp.res.from : 0;
        const total = resp.res && resp.res.total ? resp.res.total : 0;

        this.setState({
          list,
          summary,
          total,
          size,
          resp: resp.res,
          lastQuery: requestBody,
          from
        });
      })
      .catch(() => {
        this.props.loadingBarActions.hideLoadingSpiner();
      });
  };

  deleteNotiItemHandle = async (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.deleteNotiItem(data, _.findIndex(this.state.list, data));
    this.state.list.splice(this.state.list.indexOf(data), 1);
    this.setState({ list: this.state.list });
  };

  itemclick = data => {
    this.props.itemclick(data, _.findIndex(this.state.list, data));
    this.state.list = this.state.list.map(item => {
      if (data == item) {
        data.unread = false;
      }
      return item;
    });
    this.setState({ list: this.state.list });
  };

  handleCustomDateSelect = () => {
    if (this.dateFromInput) {
      this.setState({ showDateFrom: true }, () => this.dateFromInput.focus());
    }
  }

  handleChangeTextSearch = e => {
    const newState = Object.assign({}, this.state);
    newState.queryBody.criteria.txtSearch = e.target.value;
    this.setState(newState);
  }

  handlePressKeyTextSearch = e => {
    if (e.key === 'Enter') {
      this.handleClickView(e);
    }
  }

  handleClickView(e) {
    const newState = Object.assign({}, this.state);
    newState.queryBody.from = 0;
    newState.page = 0;

    e.target.blur();
    this.setState(newState, () => {
      this.fetchReportData();
    });
  }

  handleChangeDateRange = params => {
    const newState = Object.assign({}, this.state);
    let { fromDate } = newState.queryBody.criteria;
    let { toDate } = newState.queryBody.criteria;
    if (params.fromDate) {
      fromDate = moment(params.fromDate).toISOString();
      if (fromDate > toDate) {
        toDate = moment(fromDate)
          .endOf('day')
          .toISOString();
      }
    }
    if (params.toDate) {
      toDate = moment(params.toDate).toISOString();
      if (fromDate > toDate) {
        fromDate = moment(toDate)
          .startOf('day')
          .toISOString();
      }
    }
    newState.page = 0;
    newState.queryBody.from = 0;
    newState.queryBody.criteria.fromDate = fromDate;
    newState.queryBody.criteria.toDate = toDate;
    newState.queryBody.criteria.selectedButton = params.selectedButton;
    this.setState(newState, () => {
      this.fetchReportData();
    });
  };

  handleDropDownMultiSelect = (values, filterName, valueForAll = 'all') => {
    const { queryBody } = this.state;
    const mappings = {
      notificationType: this.state.notificationTypesStatus.map(item => item.value)
    };
    const newQueryBody = Object.assign({}, queryBody);
    newQueryBody.from = 0;
    const existAll = _.findIndex(queryBody.criteria[filterName], item => item === valueForAll);

    if (existAll !== -1) {
      if (values.includes(valueForAll)) {
        newQueryBody.criteria[filterName] = _.filter(values, item => item !== valueForAll);
      } else {
        newQueryBody.criteria[filterName] = [];
      }
    } else if (values.includes(valueForAll) || values.length === mappings[filterName].length - 1) {
      newQueryBody.criteria[filterName] = mappings[filterName];
    } else {
      newQueryBody.criteria[filterName] = values;
    }
    this.setState({ page: 0, queryBody: newQueryBody }, () => {
      this.fetchReportData();
    });
  };

  prepareParamExport = () => {
    return {
      export: true,
      total: this.state.total,
      ...this.state.lastQuery,
    }
  }

  handleChangeCombobox(e, value, name) {
    const newState = Object.assign({}, this.state);
    name = name || _.get(e, 'target.name');
    value = value || _.get(e, 'target.value');
    newState.queryBody.criteria[name] = value;
    newState.queryBody.criteria.notificationType = value;
    newState.queryBody.from = 0;
    newState.page = 0;
    this.setState(newState, () => {
      this.fetchReportData();
    });
  }

  renderNotificationList = (actions) => {
    const { isSignUpNotification } = this.props;
    return (
      // apiurl
      <div className="noti-list-container">
        <ListGroup className="noti-list">
          {this.state.list.length > 0 ? (
            this.state.list.map((data) => (
              <ListGroupItem
                key={data.id}
                className={data.unread ? 'noti-item unread' : 'noti-item'}
                onClick={() => {
                  this.itemclick(data);
                }}
                href={isSignUpNotification && data.userId ? `/driver/view/${data.userId}/info` : null}
                target="_blank"
              >
                <div>
                  {
                    renderMSGElement(data.message || data.msg, data.thirdParty)
                  }
                  <span className="noti-item-time">
                    <TimeAgo date={moment.tz(data.date || data.createdDate, this.state.timezone).toISOString()} />
                  </span>
                </div>
                {
                  actions && <div
                    className="noti-delete"
                    onClick={e => {
                      this.deleteNotiItemHandle(e, data);
                    }}
                    role="button"
                    aria-hidden
                  />
                }
              </ListGroupItem>
            ))
          ) : (
              <ListGroupItem className="noti-item no-messaeg">
                <p className="noti-item-title">
                  <Translate value="General.You_dont_have_any_message" />
                </p>
              </ListGroupItem>
            )}
        </ListGroup>
      </div>
    )
  };

  render() {
    /* const { localeCode = 'en' } = this.props.selectedFleet || {}; */
    const localeCode = 'vi';
    const { permissions, language } = this.props || {};
    const { actions = false } = permissions || {};
    return (
      <div className="content notifications-container">
        <div className="base-report-container">
          <div className="report-query-builder" ref={node => (this.toobarContainer = node)}>
            <div className="top-filter-container header-button-group" xs={12}>
              <div className="group-left">
                <DateRange
                  selectedButton={this.state.queryBody.criteria.selectedButton}
                  setDateRange={this.handleChangeDateRange}
                  customRangeSelectCallback={this.handleCustomDateSelect}
                  buttons={commonData.dateButtonsForNotification}
                  locale={localeCode}
                />
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip id="searchTooltip" className="search-tool-tip">
                      <Translate value={this.props.placeholderSearch} />
                    </Tooltip>
                  )}
                >
                  <FormGroup className="search-format">
                    <FormControl
                      type="text"
                      value={this.state.queryBody.criteria.txtSearch}
                      onChange={this.handleChangeTextSearch}
                      onKeyPress={this.handlePressKeyTextSearch}
                      placeholder={this.props.placeholderSearch}
                      className="search-form"
                    />
                    <BsSearch className="search-icon" />
                  </FormGroup>
                </OverlayTrigger>

                <React.Fragment>
                  <FormGroup className="input">
                    <Datetime
                      timeFormat="hh:mm A"
                      dateFormat="YYYY-MM-DD"
                      value={moment(this.state.queryBody.criteria.fromDate)}
                      inputProps={{
                        ref: input => {
                          this.dateFromInput = input;
                        },
                        placeholder: I18n.t('report.query.fromDate'),
                        value: moment(this.state.queryBody.criteria.fromDate)
                          .locale(language)
                          .format('YYYY-MM-DD hh:mm A')
                      }}
                      onChange={e => this.handleChangeDateRange({
                        selectedButton: 'custom',
                        fromDate: e._d
                      })
                      }
                      closeOnSelect
                    />
                  </FormGroup>
                  <FormGroup className="input">
                    <Datetime
                      timeFormat="hh:mm A"
                      dateFormat="YYYY-MM-DD"
                      value={moment(this.state.queryBody.criteria.toDate)}
                      inputProps={{
                        placeholder: I18n.t('report.query.toDate'),
                        value: moment(this.state.queryBody.criteria.toDate)
                          .locale(language)
                          .format('YYYY-MM-DD hh:mm A')
                      }}
                      onChange={e => this.handleChangeDateRange({
                        selectedButton: 'custom',
                        toDate: e._d
                      })
                      }
                      closeOnSelect
                    />
                  </FormGroup>
                </React.Fragment>
                {this.props.isNotification ? (
                  <Filter.NotificationsStatus
                    items={this.state.notificationTypesStatus}
                    selectedItems={this.state.queryBody.criteria.notificationType}
                    onSelect={values => this.handleDropDownMultiSelect(values, 'notificationType')}
                  />
                ) : null}
                {this.props.isSOS ? (
                  <Filter.sosStatus
                    items={this.state.notificationTypesStatus}
                    selectedItems={this.state.queryBody.criteria.sosTypeId}
                    onSelect={value => this.handleChangeCombobox({}, value, 'sosTypeId')}
                  />
                ) : null}
              </div>
              <div className="group-right">
                {this.props.noexport || this.state.total === 0 ? null : (
                  <ExportComponent 
                    prepareParamExport={this.prepareParamExport}
                    urlExport={(params) => this.props.reportActions
                      .fetchReportDataFromApi(this.props.apiurl, params)}
                    user={this.props.user}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="gridViewTable" ref={node => (this.menuContainer = node)}>
            {this.renderNotificationList(actions)}
            <div ref="scroll_bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedFleet: state.auth.selectedFleet,
  user: state.auth.user,
  newNotification: state.notificationReducers.newNotification,
  permissions: state.menuHandle.modulePermission,
  language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-gb'
});
function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseNotifications);
