import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import {
  Button, Row, Col, FormGroup, Form, FormControl
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import FormGroupTitle from '../formGroupTitile/FormGroupTitle';
import { StripeFromWrapper } from './StripeAddCardForm.style';
import { CountryDropdown } from 'react-country-region-selector';

class StripeAddCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: "",
      country: "",
      address: "",
      postal_code: "",
      state: ""
    };
  }

  componentDidMount() {
    const { stripeKey } = this.props;

    // Create a Stripe client.
    const stripe = window.Stripe(stripeKey);

    // Create an instance of Elements.
    const elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        color: '#fff',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#56585e'
        }
      },
      invalid: {
        color: '#a94442',
        iconColor: '#a94442'
      }
    };

    // Create an instance of the card Element.
    const card = elements.create('card', { style });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');

    // Handle real-time validation errors from the card Element.
    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
    this.setState({ stripe, card });
  }

  handleCardHolderChange = e => {
    const cardHolder = e.target.value;
    this.setState({
      cardHolder
    });
  };

  onInputFieldBlur = (key) => {
    // console.log("onInputFieldBlur ", key);
  }

  handleChangeInput = (key, e) => {
    let value = '';
    if (key === 'country') {
      value = e;
    } else {
      value = e.target.value;
    };
    this.setState({
      [key]: value
    });
  }

  // Submit the form with the token ID.
  stripeTokenHandler = ev => {
    ev.preventDefault();
    this.setState({ isSubmitted: true });
    const { stripe, card, cardHolder, address, city, state, postal_code, country } = this.state;
    const { notification } = this.context;
    const {
      fleetId, getClientSecret, onCardSetupSuccess, loadingActions, validForm
    } = this.props;
    // valid form topup
    const validTopup = validForm ? validForm() : true;
    if (card._invalid || !cardHolder || !validTopup) {
      return;
    }

    let type = '';

    loadingActions.showLoadingSpiner();
    getClientSecret({ fleetId, type }).then(({ res }) => {
      if (res && res.returnCode === 200) {
        const { clientSecret } = res.response;
        let requestBody = {
          payment_method: {
            card,
            billing_details: {
              name: cardHolder
            },
          },
        };

        if (address) {
          requestBody.payment_method.billing_details = {
            ...requestBody.payment_method.billing_details,
            address: {
              city: city,
              country: country,
              line1: address,
              line2: null,
              postal_code: postal_code,
              state: state
            }
          }
        };

        stripe.confirmCardSetup(
          clientSecret,
          requestBody
        ).then(resp => {
          if (!resp.error) {
            // The setup has succeeded. Display a success message.
            card.clear();
            this.setState({
              cardHolder: '',
              city: "",
              country: "",
              address: "",
              postal_code: "",
              state: "",
              isSubmitted: false
            });
            onCardSetupSuccess(resp.setupIntent, cardHolder);
          } else {
            // Display error.message in your UI.
            loadingActions.hideLoadingSpiner();
            notification('error', resp.error.message);
          }
        })
          .catch(err => {
            loadingActions.hideLoadingSpiner();
            notification('error', err.error);
          });

        // stripe
        //   .handleCardSetup(clientSecret, card, {
        //     payment_method_data: {
        //       billing_details: {
        //         name: cardHolder
        //       }
        //     }
        //   })
        //   .then(resp => {
        //     if (!resp.error) {
        //       card.clear();
        //       this.setState({
        //         cardHolder: '',
        //         isSubmitted: false
        //       });
        //       onCardSetupSuccess(resp.setupIntent, cardHolder);
        //     } else {
        //       loadingActions.hideLoadingSpiner();
        //       notification('error', resp.error.message);
        //     }
        //   })
        //   .catch(err => {
        //     loadingActions.hideLoadingSpiner();
        //     notification('error', err.error);
        //   });
      } else {
        loadingActions.hideLoadingSpiner();
      }
    });
  };

  render() {
    const { creditConfig } = this.props;
    let showAddressBilling = false;

    if (
      creditConfig.configGateway &&
      creditConfig.configGateway.zipCode &&
      creditConfig.configGateway.zipCode.enable
    ) {
      showAddressBilling = true;
    };

    return (
      <StripeFromWrapper>
        <form onSubmit={this.stripeTokenHandler}>
          {this.props.title ? (
            <FormGroupTitle className="pt0">{this.props.title}</FormGroupTitle>
          ) : null}
          <Row>
            <Col xs={12} md={12}>
              <FormGroup
                className={this.state.isSubmitted && !this.state.cardHolder ? 'error' : null}
              >
                <Form.Label>
                  <Translate value="customer.Card_holder" />
                  <span className="require"> *</span>
                </Form.Label>
                <FormControl
                  type="text"
                  value={this.state.cardHolder}
                  onChange={this.handleCardHolderChange}
                  className="form-custom"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
                <Form.Label>
                  <Translate value="customer.Card_number" />
                  <span className="require"> *</span>
                </Form.Label>
                <div id="card-element" />
                <div id="card-errors" role="alert" className="text-remove" />
              </FormGroup>
            </Col>
          </Row>
          {showAddressBilling ?
            <Row>
              <React.Fragment>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={this.state.isSubmitted && !this.state.address ? 'error' : null}
                  >
                    <Form.Label>
                      <Translate value="customer.Address" />{' '}
                      <span className="require"> *</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.address}
                      onChange={e => this.handleChangeInput('address', e)}
                      onBlur={() => this.onInputFieldBlur('address')}
                      className="form-custom"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={this.state.isSubmitted && !this.state.city ? 'error' : null}
                  >
                    <Form.Label>
                      <Translate value="customer.City" />{' '}
                      <span className="require"> *</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.city}
                      onChange={e => this.handleChangeInput('city', e)}
                      onBlur={() => this.onInputFieldBlur('city')}
                      className="form-custom"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                  // className={this.state.isSubmitted && !this.state.state ? 'error' : null}
                  >
                    <Form.Label>
                      <Translate value="customer.State" />
                    </Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.state}
                      onChange={e => this.handleChangeInput('state', e)}
                      className="form-custom"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={this.state.isSubmitted && !this.state.postal_code ? 'error' : null}
                  >
                    <Form.Label>
                      <Translate value="customer.postal_code" />{' '}
                      <span className="require"> *</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      value={this.state.postal_code}
                      onChange={e => this.handleChangeInput('postal_code', e)}
                      onBlur={() => this.onInputFieldBlur('postal_code')}
                      className="form-custom"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    className={this.state.isSubmitted && !this.state.country ? 'error' : null}
                  >
                    <Form.Label>
                      <Translate value="customer.Country" />{' '}
                      <span className="require"> *</span>
                    </Form.Label>
                    <CountryDropdown
                      value={this.state.country}
                      onChange={e => this.handleChangeInput('country', e)}
                      classes="form-control form-custom"
                      onBlur={() => this.onInputFieldBlur('country')}
                      valueType={"short"}
                    />
                  </FormGroup>
                </Col>
              </React.Fragment>
            </Row>
            : null}
          {this.props.topUpElement ? this.props.topUpElement : null}
          <Row>
            <Col xs={12} md={12} className="text-center mt-lg">
              <Button
                variant="success"
                onClick={this.stripeTokenHandler}
                className="btn-save"
              >
                <Translate value="customer.Save" />
              </Button>
              {this.props.handleCloseAddCard ? (
                <Button onClick={this.props.handleCloseAddCard} className="btn-cancel ml-md">
                  <Translate value="customer.Cancel" />
                </Button>
              ) : null}
            </Col>
          </Row>
        </form>
      </StripeFromWrapper>
    );
  }
}

StripeAddCardForm.contextTypes = {
  notification: PropTypes.func
};

StripeAddCardForm.propTypes = {
  loadingActions: PropTypes.object,
  fleetId: PropTypes.string,
  creditConfig: PropTypes.object,
  getClientSecret: PropTypes.func,
  onCardSetupSuccess: PropTypes.func,
  stripeKey: PropTypes.string
};

export default StripeAddCardForm;
