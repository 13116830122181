export const ThirdPartyColumns = [
  {
    key: 'Alias',
    label: 'thirdPartySettings.Alias',
    textEllipsis: true
  },
  {
    key: 'Address',
    label: 'thirdPartySettings.Address',
    textEllipsis: true
  },
  {
    key: 'Longitude',
    label: 'thirdPartySettings.Longitude'
  },
  {
    key: 'Latitude',
    label: 'thirdPartySettings.Latitude'
  },
  {
    key: 'Last_update',
    label: 'thirdPartySettings.Last_update'
  },
  {
    key: 'Username',
    label: 'thirdPartySettings.Username'
  },
  {
    key: 'locationType',
    label: 'thirdPartySettings.locationType'
  },
  {
    key: 'categories',
    label: 'thirdPartySettings.category',
    textEllipsis: true
  },
  {
    key: 'actions',
    label: 'thirdPartySettings.Actions',
    align: 'right',
    cellClass: 'table-actions'
  }
];

export const CategoryColumns = [
  {
    key: 'name',
    label: 'thirdPartySettings.category',
    textEllipsis: true
  },
  {
    key: 'actions',
    label: 'thirdPartySettings.Actions',
    align: 'right',
    cellClass: 'table-actions'
  }
]