export const qqMapName = 'QQ_MAP_INSTANT'
export const mapEvents ={
    Map:['click'],
    Marker: ['animation_changed',
            'clickable_changed',
            'cursor_changed',
            'draggable_changed',
            'flat_changed',
            'icon_changed',
            'map_changed',
            'position_changed',
            'shadow_changed',
            'shape_changed',
            'title_changed',
            'visible_changed',
            'zindex_changed',
            'click',
            'mousedown',
            'mouseup',
            'mouseover',
            'mouseout',
            'dblclick',
            'rightclick',
            'dragstart',
            'dragging',
            'dragend',
            'moving',
            'moveend'],
    WindowInfo:[ 'content_changed',
                'position_changed',
                'zindex_changed',
                'domready',
                'closeclick'],
    Polygon: ['map_changed',
            'visible_changed',
            'zindex_changed',
            'click',
            'dblclick',
            'rightclick',
            'mousedown',
            'mouseup',
            'mouseover',
            'mouseout',
            'mousemove',
            'insertNode',
            'removeNode',
            'adjustNode'],
    Polyline: ['map_changed',
                'visible_changed',
                'zindex_changed',
                'click',
                'dblclick',
                'rightclick',
                'mousedown',
                'mouseup',
                'mouseover',
                'mouseout',
                'mousemove',
                'insertNode',
                'removeNode',
                'adjustNode'],
    Circle: ['center_changed',
            'map_changed',
            'radius_changed',
            'visible_changed',
            'zindex_changed',
            'click',
            'dblclick',
            'rightclick',
            'mousedown',
            'mouseup',
            'mouseover',
            'mouseout',
            'mousemove']
}

export const qqMapComponentName = {
    Marker:'Marker',
    WindowInfo:'WindowInfo',
    Polygon:'Polygon',
    Polyline:'Polyline',
    Circle: 'Circle',
    Direction: 'Direction'
}