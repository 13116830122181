import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {
  POINT_TYPE_TEXT,
  CPT_NOTES_ROW,
  CPT_TEXT_AREA_ROW,
  CPT_CHECKBOX_AMOUNT_ROW
} from '../../constants/commondata';
import _ from 'lodash'
import LocationInputContainer from './LocationInputContainer';
import { TravellerInfo } from '../../components/bookingDetail';
import { trimPhoneNumber, CCLiteCommonFunc, Validation, getTravelerTypeText } from '../../utils/commonFunctions';
import { TRAVELER_TYPE, userType } from '../../constants/commondata';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import { Validator, ValidCase } from "../../components/validator";
import { Modal, Button, FormGroup, FormControl } from "react-bootstrap";
import PackageInformation from '../../containers/newDeliveryBooking/PackageInformation';
import './editPointDeliveryModal3rdGG.scss';
const senderDefault = {
  traveler_type: TRAVELER_TYPE.Individual,
  phone: '',
  firstname: '',
  lastname: '',
  email: ''
};

let autoCompleteType;
let lastAutoCompleteRequestId;

const EditPointDeliveryModal = ({
  setShowAddPoint,
  isShow,
  hasPartyLocation,
  submitEditPoint,
  value,
  dataEditingPoint,
  fleetId,
  checkPointInZoneSupported,
  setValueEditPoint,
  jobType,
  format24Hour,
  options,
  auth,
  data,
  newBooking,
  cueActions,
  currencyISO,
  corporateCompany,
  promo,
  cartypeSelected,
  customerAutocompleteActions,
  customerAutocomplete,
  customerActions,
  isDisableAddEditPickup,
  isDisableAddEditDropOff
}) => {
  const [location, setLocation] = useState({ address: {} })
  const [isDirtyLocation, setIsDirtyLocation] = useState(false)
  const [isEditDataMeta, setEditDataMeta] = useState(false)
  const [showMoreCustomer, setShowMoreCustomer] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [phoneStatus, setPhoneStatus] = useState(true)
  const [validSender, setValidSender] = useState({})
  const [validRecipient, setValidRecipient] = useState({})
  const [errorMessages, setErrorMessages] = useState(null)
  const [isSubmited, setIsSubmited] = useState(false)
  const [recipient, setRecipient] = useState({})
  const [sender, setSender] = useState(senderDefault);

  let isDisableAddEditDropOffInternal = isDisableAddEditDropOff || !recipient._id;
  const isCompletedBooking = CCLiteCommonFunc.isCompleteBooking(data.status);

  if (isCompletedBooking) {
    isDisableAddEditDropOffInternal = false;
  }

  useEffect(() => {
    setSender(dataEditingPoint.sender || {
      ...senderDefault,
      traveler_type: (auth.user.userType != userType.FleetAdmin && auth.user.userType != userType.FleetUser) ? TRAVELER_TYPE.Corporate : TRAVELER_TYPE.Individual
    });
    setRecipient(dataEditingPoint.recipient || {});
    setLocation(dataEditingPoint);
  }, [value, dataEditingPoint])

  const closeModal = () => {
    setInitialForm()
  }

  const handleSelectPickUp = (newLocation) => {
    console.log('handleSelectPickUp', location, newLocation)
    if (newLocation && newLocation.address && newLocation.address.lat) {
      if (location && location.address && location.address.locationDetails) {
        newLocation.address.locationDetails = location.address.locationDetails;
      }
      setLocation(newLocation)
    } else {
      if (location && location.address && location.address.locationDetails) {
        setLocation({ address: { locationDetails: location.address.locationDetails } })
      } else {
        setLocation({})
      }
    }
  };

  const setInitialForm = () => {
    setLocation(null)
    setValueEditPoint('')
    setShowAddPoint(false)
    setEditDataMeta(false)
    setIsDirtyLocation(false)
    setIsSubmited(false)
    setSender(senderDefault)
    setRecipient({})
    setValidSender({})
    setValidRecipient({})
  };

  const handleEditNewPoints = () => {
    setIsSubmited(true);
    if (dataEditingPoint.pointType == POINT_TYPE_TEXT.pu && !CCLiteCommonFunc.isFormValid(validSender)) {
      return;
    }
    if (dataEditingPoint.pointType == POINT_TYPE_TEXT.do && !CCLiteCommonFunc.isFormValid(validRecipient)) {
      return;
    }
    if (location == null) {
      submitEditPoint({
        address: dataEditingPoint.address,
        addressPointText: dataEditingPoint.address && dataEditingPoint.address.address,
        from: dataEditingPoint.from,
        id: dataEditingPoint.id,
        order: dataEditingPoint.order,
        pointType: dataEditingPoint.pointType,
        sender: sender,
        recipient: recipient
      })
      setInitialForm()
      return
    }
    let address = location.address || {}
    if (address.lat && address.lng) {
      address.geo = [address.lng, address.lat]
      if (location.addressPointText) {
        address.address = location.addressPointText
      }
      submitEditPoint({
        address: address,
        addressPointText: location.addressPointText,
        id: dataEditingPoint.id,
        order: dataEditingPoint.order,
        pointType: dataEditingPoint.pointType,
        sender: sender,
        recipient: recipient
      })
      setInitialForm()
    }
  }

  const handleChangeFirstName = (event, { newValue, method }) => {
    autoCompleteType = 'firstname';
    setSender({ ...sender, firstname: newValue });
  };

  const handleChangeLastName = (e, { newValue, method }) => {
    autoCompleteType = 'lastname';
    setSender({ ...sender, lastname: newValue });
  };

  const handleChangeEmail = (e, { newValue, method }) => {
    autoCompleteType = 'email';
    setSender({ ...sender, email: newValue });
  };

  const handleChangeFirstNameCorporate = e => {
    setSender({ ...sender, firstname: e.target.value });
  };

  const handleChangeLastNameCorporate = e => {
    setSender({ ...sender, lastname: e.target.value });
  };

  const handleChangeEmailCorporate = e => {
    setSender({ ...sender, email: e.target.value });
  };

  const handleChangeCorpId = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.corpId = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeManagerName = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.managerName = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeManagerEmail = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.managerEmail = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeCostCentre = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.costCentre = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeDepartment = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.department = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeCorpDivision = e => {
    let customer = _.cloneDeep(sender.customer);

    if (!customer.corporateSelectedInfo) {
      customer.corporateSelectedInfo = {};
    }
    customer.corporateSelectedInfo.corpDivision = e.target.value;
    setSender({ ...sender, customer: customer });
  };

  const handleChangeTravelerType = e => {
    if (e.target.value == 0) {

      setSender({ ...sender, company: null, traveler_type: e.target.value, isCustomerVip: sender.oldVipCus});
    } else {
      const company = corporateCompany && corporateCompany.length > 0 ? corporateCompany[0] : null;

      const customer = buildCustomerWithSelectedCompany(
        sender.customer,
        company
      );
      setSender({ ...sender, company: company, customer: customer || {}, traveler_type: e.target.value, isCustomerVip: corporateCompany[0]?.isCorporateVip || customer.rank, oldVipCus: customer.rank });
    }
  };

  const buildCustomerWithSelectedCompany = (customer, selectedCompany) => {
    let cus = Object.assign({}, customer);
    if (cus && cus.passengerInfo) {
      if (cus.passengerInfo.corporateId == selectedCompany._id) {
        cus = {
          ...cus,
          corporateSelectedInfo: Object.assign({}, cus.corporateInfo || {})
        };
      } else {
        cus = { ...cus, corporateSelectedInfo: {} };
      }
    }
    return cus;
  };

  const handleChangePhone = (status, value, countryData, number, id, suggestion) => {
    autoCompleteType = 'phone';
    const newNumber = suggestion ? suggestion.newValue : number;
    setSender({ ...sender, phone: newNumber, isCustomerVip: sender.company?.isCorporateVip || false });
  };

  const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const getSuggestionValue = suggestion => {
    if (autoCompleteType === 'firstname') {
      return suggestion.firstName ? suggestion.firstName : '';
    } if (autoCompleteType === 'lastname') {
      return suggestion.lastName ? suggestion.lastName : '';
    } if (autoCompleteType === 'email') {
      return suggestion.email ? suggestion.email : '';
    }
    return suggestion.phone ? suggestion.phone : '';
  };

  // Customer auto complete
  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    if (lastAutoCompleteRequestId !== null) {
      clearTimeout(lastAutoCompleteRequestId);
    }
    const corpType = getTravelerTypeText(sender.traveler_type, sender.company)
    let searchObject = {
      fleetId: auth.selectedFleet.fleetId,
      str: escapedValue,
      travelerType: corpType
    };

    if(corpType === 'corporation') {
      searchObject.corporateId = _.get(sender, 'company._id', '')
    }

    if(corpType === 'airline') {
      searchObject.airlineId = _.get(sender, 'company._id', '')
    }

    if (autoCompleteType === 'firstname') {
      searchObject = { ...searchObject, name: '1' };
    } else if (autoCompleteType === 'lastname') {
      searchObject = { ...searchObject, name: '1' };
    } else if (autoCompleteType === 'email') {
      searchObject = { ...searchObject, email: '1' };
    } else {
      searchObject.str = searchObject.str.replace(/\D/g, '');
    }

    if (searchObject.str.length == 0) {
      return [];
    }
    lastAutoCompleteRequestId = setTimeout(() => {
      customerAutocompleteActions.customerAutocomplete(searchObject).then(data => {
        setSuggestions(data.res.list);
      });
    }, 500);
    const regex = new RegExp(`\\b${escapedValue}`, 'i');

    return customerAutocomplete.data.filter(person => regex.test(getSuggestionValue(person)));
  };

  const onSuggestionsFetchRequested = data => {
    if (data.reason !== 'input-focused') {
      setSuggestions(getSuggestions(data.value));
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleBlurPhone = (status, value, countryData, number, id, suggestion, isCountryCodeOnly) => {
    setPhoneStatus(status);
    if (isCountryCodeOnly) {
      clearCustomerData('');
      return;
    }
    if (suggestion && suggestion.highlightedSuggestion) {
      customerAutocompleteSlectedHandle(null, {
        suggestion: suggestion.highlightedSuggestion
      });
    } else {
      const suggestionsList = suggestions.filter(item => trimPhoneNumber(item.phone) === trimPhoneNumber(sender.phone));
      if (suggestionsList[0]) {
        customerAutocompleteSlectedHandle(null, {
          suggestion: suggestionsList[0]
        });
      } else {
        const corpType = getTravelerTypeText(sender.traveler_type, sender.company)
        let params = {
          fleetId: auth.selectedFleet.fleetId,
          str: trimPhoneNumber(number).replace(/\D/g, ''),
          travelerType: corpType
        }

        if(corpType === 'corporation') {
          params.corporateId = _.get(sender, 'company._id', '')
        }

        if(corpType === 'airline') {
          params.airlineId = _.get(sender, 'company._id', '')
        }
        customerAutocompleteActions
          .customerAutocomplete(params)
          .then(data => {
            if (data.res) {
              const suggestionsList = data.res.list.filter(item => trimPhoneNumber(item.phone) === trimPhoneNumber(sender.phone));
              if (suggestionsList[0]) {
                const suggestData = suggestionsList[0];
                suggestData.phone = number;
                customerAutocompleteSlectedHandle(null, {
                  suggestion: suggestData
                });
              }
              else {
                clearCustomerData(number);
              }
            } else {
              clearCustomerData(number);
            }
          });
      }
    }
  };

  const clearCustomerData = number => {
    setSender({
      ...sender,
      firstname: sender.firstname ? sender.firstname : '',
      lastname: sender.lastname ? sender.lastname : '',
      phone: number,
      email: sender.email,
      isCustomerVip: sender.company?.isCorporateVip|| false,
      outStanding: []
    });
  };

  const customerAutocompleteSlectedHandle = (
    event,
    {
      suggestion, suggestionValue, suggestionIndex, sectionIndex, method
    }
  ) => {
    if (lastAutoCompleteRequestId !== null) {
      clearTimeout(lastAutoCompleteRequestId);
    }
    let customer = suggestion ? Object.assign(sender.customer || {}, suggestion) : null;
    if (sender.company) {
      customer = buildCustomerWithSelectedCompany(customer, sender.company);
    }
    const { fleetId } = auth.selectedFleet;
    customerActions
      .getPendingPaymentCustomer({ fleetId, userId: suggestion.userId })
      .then(data => {
        setSender({
          ...sender,
          firstname: suggestion ? suggestion.firstName : '',
          lastname: suggestion ? suggestion.lastName : '',
          phone: suggestion && (suggestion.phone !== suggestion.userId) ? suggestion.phone : '',
          email: suggestion ? suggestion.email : '',
          passengerInfo: suggestion ? suggestion.passengerInfo : {},
          corporateInfo: suggestion ? suggestion.corporateInfo : {},
          credits: suggestion ? suggestion.credits : [],
          customer,
          isCustomerVip: sender.company?.isCorporateVip ? sender.company?.isCorporateVip : (suggestion ? !!suggestion.rank : false),
          oldVipCus: suggestion ? !!suggestion.rank : false,
        });
      });
  };

  const CustomerVipClickHandle = () => {
    setSender({ ...sender, isCustomerVip: !sender.isCustomerVip });
  };

  const handleShowMoreCustomer = show => {
    setShowMoreCustomer(show);
  };

  const ValidatorCallbackSender = (id, validData, messages) => {
    let newValid = _.cloneDeep(validSender);
    if (newValid[id] != validData) {
      newValid[id] = validData;
      let oldMessages = errorMessages;
      if (!validData) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      setValidSender(newValid);
      setErrorMessages(oldMessages);
    }
  };

  const ValidatorCallbackRecipient = (id, validData, messages) => {
    let newValid = _.cloneDeep(validRecipient);
    if (newValid[id] != validData) {
      newValid[id] = validData;
      let oldMessages = errorMessages;
      if (!validData) {
        if (!oldMessages) oldMessages = [];
        oldMessages = oldMessages.concat(messages);
      }
      setValidRecipient(newValid);
      setErrorMessages(oldMessages);
    }
  };

  const handleCompanyChange = e => {
    corporateCompany.map(corp => {
      if (corp._id == e.target.value) {
        const customerWithSelectedCompany = buildCustomerWithSelectedCompany(
          sender.customer,
          corp
        );
        setSender({ ...sender, company: corp, customer: customerWithSelectedCompany, isCustomerVip: corp.isCorporateVip || false });
      }
    });
  };

  const handleChangeRoom = e => {
    let locationObject = _.cloneDeep(location);
    if (!locationObject.address) {
      locationObject.address = {};
    }
    locationObject.address.locationDetails = e.target.value;
    setLocation(locationObject);
  };

  const handleChangeRecipientName = e => {
    setRecipient({ ...recipient, name: e.target.value });
  };

  const handleChangeRecipientPhone = e => {
    setRecipient({ ...recipient, phone: e.target.value });
  };

  const handleChangeRecipientPackageContent = e => {
    setRecipient({ ...recipient, packageContent: e.target.value });
  };

  const handleChangeRecipientInstructions = e => {
    setRecipient({ ...recipient, instructions: e.target.value });
  };

  const handleChangeRecipientOrderId = e => {
    setRecipient({ ...recipient, orderId: e.target.value });
  };

  const handleChangeAmount = (e) => {
    console.log('handleChangeAmount', e.target.value)
    setRecipient({ ...recipient, amount: e.target.value });
  };

  let disableAddBtn = true
  if (location && location.address && location.address.lat) disableAddBtn = false
  let latLocation = location && location.address && location.address.lat || null
  let addressTextEdit = _.get(dataEditingPoint, 'address.address', '') || null
  if (
    latLocation
    || (isEditDataMeta && addressTextEdit && !isDirtyLocation)
  ) {
    disableAddBtn = false
  }

  let recents = []

  if (sender.passengerInfo && sender.passengerInfo.recentPlaces) {
    if (dataEditingPoint.pointType == POINT_TYPE_TEXT.pu) {
      recents = sender.passengerInfo.recentPlaces.pickup.filter(item => !!item.addressDetails);
    }

    if (dataEditingPoint.pointType == POINT_TYPE_TEXT.do) {
      recents = sender.passengerInfo.recentPlaces.destination.filter(item => !!item.addressDetails);
    }
  }
  return (
    <Modal
      show={isShow}
      backdrop="static"
      key="modal-form"
      dialogClassName="confirm-dialog delivery-modal"
      className="confirm pointModal"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {dataEditingPoint.pointType == POINT_TYPE_TEXT.pu ?
            <Translate value="newbooking.Sender" /> :
            <Translate value="newbooking.Recipient" />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroupTitle>
          <Translate value="thirdPartySettings.location" />
        </FormGroupTitle>
        <LocationInputContainer
          checkPointInZoneSupported={checkPointInZoneSupported}
          changePlaceOrder={dataEditingPoint.pointType == POINT_TYPE_TEXT.pu}
          fleetId={fleetId}
          address={dataEditingPoint && dataEditingPoint.address || {}}
          jobType={jobType}
          auth={auth}
          hasPartyLocation={hasPartyLocation}
          recentPlaces={recents}
          format24Hour={format24Hour}
          options={options}
          data={data}
          id={dataEditingPoint && dataEditingPoint.id}
          checkSupportZone={dataEditingPoint.pointType == POINT_TYPE_TEXT.pu ? true : false}
          setNewPoints={handleSelectPickUp}
          setIsDirtyLocation={setIsDirtyLocation}
          isEditDisabled={(dataEditingPoint.pointType == POINT_TYPE_TEXT.pu && !isDisableAddEditPickup) || (dataEditingPoint.pointType == POINT_TYPE_TEXT.do && !isDisableAddEditDropOffInternal)}
        />
        <FormGroup>
          <FormControl
            type="text"
            className="form-custom"
            disabled={(dataEditingPoint.pointType == POINT_TYPE_TEXT.pu && !isDisableAddEditPickup) || (dataEditingPoint.pointType == POINT_TYPE_TEXT.do && !isDisableAddEditDropOffInternal)}
            value={location && location.address ? location.address.locationDetails : ''}
            placeholder={I18n.t('newbooking.Room_floor_block')}
            onChange={handleChangeRoom}
          />
        </FormGroup>
        {dataEditingPoint.pointType == POINT_TYPE_TEXT.pu ?
          <TravellerInfo
            valid={validSender}
            suggestions={suggestions}
            promo={promo}
            phoneStatus={phoneStatus}
            customer={sender.customer || {}}
            traveler_type={sender.traveler_type}
            data={Object.assign({}, sender, {
              bookId: (data || {}).bookId,
              psgInfo: data.psgInfo,
              corporateInfo: data.corporateInfo,
              company: sender.company ? sender.company._id : '',
              outStanding: sender.outStanding || data.outStanding
            })}
            isShowAll={showMoreCustomer}
            jobType={jobType}
            user={auth && auth.user}
            cartypeSelected={cartypeSelected}
            selectedFleet={auth && auth.selectedFleet}
            corporateCompany={corporateCompany}
            newBooking={newBooking}
            isSubmited={isSubmited}
            handleChangePhone={handleChangePhone}
            handleChangeTravelerType={handleChangeTravelerType}
            handleCompanyChange={handleCompanyChange}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            customerAutocompleteSlectedHandle={customerAutocompleteSlectedHandle}
            handleBlurPhone={handleBlurPhone}
            CustomerVipClickHandle={CustomerVipClickHandle}
            ValidatorCallback={ValidatorCallbackSender}
            handleChangeEmailCorporate={handleChangeEmailCorporate}
            handleChangeDepartment={handleChangeDepartment}
            handleChangeCorpDivision={handleChangeCorpDivision}
            handleChangeManagerName={handleChangeManagerName}
            handleChangeManagerEmail={handleChangeManagerEmail}
            handleChangeCostCentre={handleChangeCostCentre}
            handleChangeCorpId={handleChangeCorpId}
            handleChangeFirstName={handleChangeFirstName}
            handleChangeLastName={handleChangeLastName}
            handleChangeFirstNameCorporate={handleChangeFirstNameCorporate}
            handleChangeLastNameCorporate={handleChangeLastNameCorporate}
            handleChangeEmail={handleChangeEmail}
            handleShowMoreCustomer={handleShowMoreCustomer}
          />
          :
          <>
            <FormGroupTitle>
              <Translate value="newbooking.Recipient_Infomation" />
            </FormGroupTitle>
            <FormGroup
              className={`qup-input-group ${
                isSubmited
                  ? validRecipient['name'] === false
                    ? "error"
                    : null
                  : null
              }`}>
              <FormControl
                type="text"
                className="form-custom"
                value={recipient.name}
                placeholder={I18n.t('newbooking.Recipient_Name')}
                disabled={!isDisableAddEditDropOffInternal}
                onChange={handleChangeRecipientName}
              />
              <Validator id={'name'} callback={ValidatorCallbackRecipient}>
                <ValidCase
                  valid={!Validation.isStringEmpty(recipient.name)}
                  message={I18n.t("messages.commonMessages.Required_field")}
                  hide={!isSubmited}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={`qup-input-group ${
                isSubmited
                  ? validRecipient['phone'] === false
                    ? "error"
                    : null
                  : null
              }`}>
              <FormControl
                type="text"
                className="form-custom"
                value={recipient.phone}
                placeholder={I18n.t('newbooking.Recipient_Phone')}
                disabled={!isDisableAddEditDropOffInternal}
                onChange={handleChangeRecipientPhone}
              />
              <Validator id={'phone'} callback={ValidatorCallbackRecipient}>
                <ValidCase
                  valid={!Validation.isStringEmpty(recipient.phone)}
                  message={I18n.t("messages.commonMessages.Required_field")}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={!recipient.phone || Validation.isNumber(recipient.phone.replace(/ /g,''))}
                  message={I18n.t("messages.commonMessages.must_be_number")}
                  hide={!isSubmited}
                />
              </Validator>
            </FormGroup>
            <FormGroupTitle>
              <Translate value="newbooking.Details" />
            </FormGroupTitle>
            <FormGroup
              className={`qup-input-group ${
                isSubmited
                  ? validRecipient['packageContent'] === false
                    ? "error"
                    : null
                  : null
              }`}>
              <FormControl
                type="text"
                className="form-custom"
                as="textarea"
                value={recipient.packageContent}
                placeholder={I18n.t('newbooking.Package_Content')}
                disabled={!isDisableAddEditDropOffInternal}
                onChange={handleChangeRecipientPackageContent}
              />
              <Validator id={'packageContent'} callback={ValidatorCallbackRecipient}>
                <ValidCase
                  valid={!Validation.isStringEmpty(recipient.packageContent)}
                  message={I18n.t("messages.commonMessages.Required_field")}
                  hide={!isSubmited}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className="qup-input-group">
              <FormControl
                type="text"
                as="textarea"
                className="form-custom"
                value={recipient.orderId}
                placeholder={I18n.t('newbooking.Order_ID')}
                disabled={!isDisableAddEditDropOffInternal}
                onChange={handleChangeRecipientOrderId}
              />
            </FormGroup>
            <FormGroup
              className="qup-input-group">
              <FormControl
                type="text"
                as="textarea"
                className="form-custom"
                value={recipient.instructions}
                placeholder={I18n.t('newbooking.Instructions_for_driver')}
                disabled={!isDisableAddEditDropOffInternal}
                onChange={handleChangeRecipientInstructions}
              />
            </FormGroup>
            {recipient.menuData && recipient.menuData.selectedItems && recipient.menuData.selectedItems.length ?
              <>
                <PackageInformation
                  bookInfo={{
                    deliveryInfo: {
                      recipients: [{
                        menuData: {
                          selectedItems: recipient.menuData.selectedItems.filter(item => {
                            return !(item.componentType === CPT_TEXT_AREA_ROW && item.fieldKey === "packageInfo")
                              && !(item.componentType === CPT_NOTES_ROW && item.fieldKey === "request.notes")
                              && !(item.componentType === CPT_NOTES_ROW && item.fieldKey === "request.orderId")
                              && !(item.componentType === CPT_CHECKBOX_AMOUNT_ROW && item.fieldKey === "cashOnDelivery")
                          })
                        }
                      }]
                    },
                    jobType: jobType,
                    currencyISO: currencyISO
                  }}
                  selectedFleet={auth.selectedFleet}
                  cueActions={cueActions}
                />
              </> : null
            }
            <FormGroupTitle>
              <Translate value="newbooking.Cost_of_Goods" />
            </FormGroupTitle>
            <FormGroup
              className={`qup-input-group form-edit-fare ${
                isSubmited
                  ? validRecipient['amount'] === false
                    ? "error"
                    : null
                  : null
              }`}
            >
              <FormControl
                type="text"
                className="form-custom"
                placeholder={I18n.t('newbooking.Enter_amount')}
                onChange={e => handleChangeAmount(e)}
                value={recipient.amount}
                disabled={isCompletedBooking || recipient.status === 'failed'}
              />
              <Validator id={'amount'} callback={ValidatorCallbackRecipient}>
                <ValidCase
                  valid={recipient.amount === "" || recipient.amount === undefined || Validation.isNumber(recipient.amount)}
                  message={I18n.t("messages.commonMessages.must_be_number")}
                  hide={!isSubmited}
                />
                <ValidCase
                  valid={recipient.amount === "" || recipient.amount === undefined || parseFloat(recipient.amount) >= 0}
                  message={I18n.t("messages.commonMessages.greater_or_equa").format(0)}
                  hide={!isSubmited}
                />
              </Validator>
            </FormGroup>
          </>}
      </Modal.Body>
      <Modal.Footer>
        {
          ((dataEditingPoint.pointType == POINT_TYPE_TEXT.pu && isDisableAddEditPickup) || (dataEditingPoint.pointType == POINT_TYPE_TEXT.do && !isCompletedBooking)) ?
            <Button
              onClick={handleEditNewPoints}
              className="btn-save"
              disabled={disableAddBtn}
            >
              <Translate value="newbooking.Save" />
            </Button> :
            null
        }
      </Modal.Footer>
    </Modal>
  )
}

EditPointDeliveryModal.contextTypes = {
  notification: PropTypes.func
};

EditPointDeliveryModal.defaultProps = {
  isShow: false,
  isChina: false,
  hasPartyLocation: true,
  value: '',
  dataEditingPoint: {},
  recentPlaces: {},
  auth: {},
  corporateCompany: {},
  newBooking: {},
  cueActions: {},
  currencyISO: '',
  data: {},
  promo: null,
  cartypeSelected: null,
  paymentMethod: null,
  customerAutocompleteActions: null,
  customerAutocomplete: null,
  customerActions: null,

  //show/hide modal
  setShowAddPoint: () => { },
  checkPointInZoneSupported: () => { },
  submitEditPoint: () => { },
  setValueEditPoint: () => { }
}

export default EditPointDeliveryModal