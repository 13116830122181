import * as _ from 'lodash';

export const fields = [
  {
    label: 'report.result.financialCompany.companyName',
    key: 'companyName',
    summary: 'companyName'
  },
  {
    label: 'report.result.financialCompany.totalNetEarning',
    key: 'totalNetEarning',
    summary: 'totalNetEarning',
    negativable: true
  }, 
  {
    label: 'report.result.financialCompany.companyAmount',
    key: 'companyAmount',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const companyCommission = _.get(
        selectedFleet,
        'generalSetting.companyCommission',
        false
      );
      return !companyCommission;
    },
    negativable: true
  }
  
];

export const fareFields = ['companyAmount', 'totalNetEarning'];
