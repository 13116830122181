import React, { Component } from 'react';
import { connect } from 'react-redux';

class Reports extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.auth.user?.roles?.isSupplier) {
      this.props.router.push('/page-not-found');
      return <></>
    }
    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(Reports);
