import { ZoneActionStatus } from '../../../../constants/commondata';
import _ from 'lodash';

export const getPolylinePaths = (polyline, isChina) => {
  let results = null;

  if (isChina) {
    let polylinePath = polyline
      ? polyline.getPath().elems.map(obj => ({
          lat: obj.lat,
          lng: obj.lng
        }))
      : null;

    if (!polylinePath || !polylinePath.length) {
      return null;
    }

    if (polylinePath.length > 1) {
      polylinePath.push(polylinePath[0]);
    }

    results = [polylinePath.map(obj => [obj.lng, obj.lat])];
  } else {
    let path = polyline
      .getPath()
      .getArray()
      .map(p => {
        return [p.toJSON().lng, p.toJSON().lat];
      });

    if (!path[0]) {
      return null;
    }

    if (
      path[0][0] != path[path.length - 1][0] ||
      path[0][1] != path[path.length - 1][1]
    ) {
      path.push(path[0]);
    }

    results = [path];
  }

  return results;
};

export const getPolygonPaths = (polygon, isChina) => {
  let results = null;

  if (isChina) {
    //get Polygon from zoneId
    let qqPath = [];
    qqPath = polygon.getPath().elems;

    if (qqPath && qqPath.length > 0) {
      results = [qqPath.map(obj => [obj.lng, obj.lat])];
      let firstPoint = results[0][0];
      let lastPoint = results[0][results[0].length - 1];

      if (!(firstPoint[0] == lastPoint[0] && firstPoint[1] == lastPoint[1])) {
        results[0].push(firstPoint.slice());
      }
    }
  } else {
    results = polygon
      .getPaths()
      .getArray()
      .map(item => {
        let poly = item.getArray().map(p => {
          return [p.toJSON().lng, p.toJSON().lat];
        });

        if (
          poly[0][0] != poly[poly.length - 1][0] ||
          poly[0][1] != poly[poly.length - 1][1]
        ) {
          poly.push(poly[0]);
        }
        
        return poly;
      });
  }

  return results;
};

export function getPaths() {
  const { polyline, zoneAction, surchargeModel } = this.state;
  const { commonData } = this.props;
  const isChina = _.get(commonData, 'location.isChina', false);
  let path = null;

  if (polyline) {
    path = getPolylinePaths(this.Polyline, isChina);
  } else {
    let polygon = null;

    if (!isChina || zoneAction === ZoneActionStatus.Add) {
      polygon = this.Polygon;
    } else {
      if (zoneAction === ZoneActionStatus.Edit) {
        polygon = this[`Polygon_${surchargeModel._id}`];
      }
    }

    if (polygon) {
      path = getPolygonPaths(polygon, isChina);
    }
  }

  return path;
};
