import React from 'react';
import {  Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { ZoneActionStatus } from '../../../../constants/commondata';

const isDropup = index => {
  const containerHeight = window.innerHeight - 200; // height of zone list container

  if (containerHeight - 200 - index * 40 < 80) {
    return true;
  }

  return false;
};

export default props => (
  <div className="surcharge-list-container">
    <table className="table table-no-pagination cc-table-striped">
      <thead>
        <tr>
          <th>
            <Translate value="dynamicSurcharge.surcharge" />
          </th>
          <th>
            <Translate value="cue.Status" />
          </th>
          <th />
        </tr>
      </thead>
    </table>
    <div className="surcharge-list">
      <table className="table table-no-pagination cc-table-striped">
        <thead>
          <tr>
            <th />
            <th />
            <th />
          </tr>
        </thead>
        <tbody className="tableZone">
          {_.map(props.surcharges, (item, index) => (
            <tr 
              key={item._id} 
              onClick={() => props.handleZoneRowClick(item)}
              className={
                (props.surchargeModel && props.surchargeModel._id) == item._id 
                ? 'selected' 
                : ''
              }
            >
              <td>
                <div>{item.name}</div>
                {/* <div>x{item.parameter}</div> */}
              </td>
              <td className={item.isActive ? 'Active' : 'Inactive'}>
                {props.hasPermission ? (
                  <a onClick={() => props.handleActivateSurcharge(item)} className="clickable">
                    {item.isActive ? (
                      <Translate value="zoneSetting.Active" />
                    ) : (
                      <Translate value="zoneSetting.Inactive" />
                    )}
                  </a>
                ) : item.isActive ? (
                  <Translate value="zoneSetting.Active" />
                ) : (
                  <Translate value="zoneSetting.Inactive" />
                )}
              </td>
              <td>
                {props.hasPermission ? (
                  <Dropdown
                    id="bg-nested-dropdown"
                    className="table-actions-dropdown"
                    onSelect={(eventKey, event) => {
                      event.stopPropagation();
                      props.handleMenuItemClick(eventKey, item);
                    }}
                    onToggle={(isOpen, event, metadata) => event.stopPropagation()}
                    dropup={isDropup(index)}
                    pullRight
                  >
                    <Dropdown.Toggle>
                      <i className="fa fa-reorder" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="actions">
                      <Dropdown.Item eventKey={ZoneActionStatus.Edit}>
                        <Translate value="zoneSetting.Edit" />
                      </Dropdown.Item>
                      {item.inUse && item.isActive ? null : (
                        <Dropdown.Item eventKey={ZoneActionStatus.Status}>
                          {item.isActive ? (
                            <Translate value="zoneSetting.Deactivate" />
                          ) : (
                            <Translate value="zoneSetting.Activate" />
                          )}
                        </Dropdown.Item>
                      )}
                      {item.isActive || item.inUse ? null : (
                        <Dropdown.Item eventKey={ZoneActionStatus.Delete}>
                          <Translate value="zoneSetting.Delete" />
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
          {!props.surcharges.length && (
            <tr>
              <td colSpan={3}>
                <div className="pv text-remove text-center">
                  <Translate value="reservation.noData" />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);
