import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FormGroup,
    FormControl,
    Form,
    Col,
    Row,
    InputGroup,
    Tabs,
    Tab
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import "./note.scss";
import IntlTelInputApp from "../../../components/intlTelInputCustome/IntlTelInputApp";
import { Validator, ValidCase, WarningFeeInput } from "../../../components/validator";
import DateTime from "../../../components/dateTime/DateTime";
import {
    Validation,
    textboxNumberHelper
} from "../../../utils/commonFunctions";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import Select from "../../../components/select/Select";
import RadioButton from "../../../components/radioButton/radio";
import CCDropDown from "../../../components/dropdown/ccDropDown";
import _ from "lodash";

import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

class Note extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            textSearchNote: '',
        };
        this.searchNoteDebounce = _.debounce(this.searchNote, 300);
    }

    handleInputNoteChange = (value) => {
        this.setState({ textSearchNote: value });
    }

    handleInputBlur = (e) => {
        this.setState({ textSearchNote: '' });
    };

    renderNoteNoResult = () => {
        const { textSearchNote } = this.state;
        if (!textSearchNote) return null;
        return (
            <span>
            {I18n.t('message.No_results')}
                <button className="btn-link" onClick={this.onCreateNewNote}>
                    Click here to add new
                </button>
                {textSearchNote}
          </span>
        );
    };

    onCreateNewNote = () => {
        const { settingActions, auth } = this.props;
        const { textSearchNote } = this.state;
        const body = {
            fleetId: auth.selectedFleet.fleetId,
            name: textSearchNote
        };
        /*settingActions.createDriverNote(body).then(({ res }) => {
            if (res) {
                const { handleNoteChange, notes } = this.props;
                handleNoteChange((notes || []).concat([res]));
                this.noteRef.select.blurInput();
            }
        });*/
        const { handleNoteChange, notes } = this.props;
        let check = _.filter(notes,{"name":textSearchNote});
        if(check.length === 0){
            handleNoteChange((notes || []).concat([{"name":textSearchNote,"id":"textSearchNote"}]));
        }
        this.noteRef.select.blurInput();
    };

    handleLoadOption = (input, callback) => {
        if (!input) return callback();
        const { settingActions, auth } = this.props;
        this.searchNoteDebounce(
            auth.selectedFleet.fleetId,
            settingActions.driverNoteAutocomplete,
            input,
            callback
        );
    };

    searchNote = (fleetId, findNoteAction, input, callback) => {
        const params = {
            str: input,
            fleetId,
            limit: 20,
            page: 0
        };

        findNoteAction(params)
            .then(data => {
                if (data.ok && data.res && data.res.list.length > 0) {
                    const result = { complete: true };
                    result.options = (data.res.list || []).map(cat => ({
                        name: cat.name,
                        _id: cat._id
                    }));
                    callback(null, result);
                } else callback(null, null);
            })
            .catch(() => callback(null, null));
    };

    handleNoteChange = (noteSelected) => {
        this.setState({
            noteSelected
        }, () => {
            this.props.handleNoteChange(this.state.noteSelected)
        });
    }
    render() {
        const {
            disabled
            }= this.props;
        return (
            <FormGroup>
                <Form.Label>
                    <Translate value="driver.Notes" />
                </Form.Label>
                <Select.Async
                    multi={true}
                    valueKey="_id"
                    labelKey="name"
                    cache={false}
                    ref={ref => this.noteRef = ref}
                    placeholder={I18n.t('messages.driver_note.Search_driver_note')}
                    searchPromptText={''}
                    loadingPlaceholder={I18n.t('messages.driver_note.Searching')}
                    noResultsText={this.renderNoteNoResult()}
                    className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest-note"
                    value={this.props.notes }
                    loadOptions={this.handleLoadOption}
                    onChange={this.handleNoteChange}
                    disabled={disabled}
                    ignoreAccents={false}
                    ignoreCase={false}
                    onInputChange={this.handleInputNoteChange}
                    onBlur={this.handleInputBlur}
                    />
            </FormGroup>
        )
    }
}
function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        users: state.users,
        permissions: state.menuHandle.modulePermission,
        menuHandle: state.menuHandle
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Note);