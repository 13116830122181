import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import tableColumnsDefault from '../../../fields/fieldSupplierUser';
import { Link } from 'react-router';
import StickyTable from '../../../../../components/table/stickyTable/StickyTable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as userActions from '../../../../../actions/userActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import TableActions from '../../../../../components/table/tableAction/TableActions';

const SupplierUser = (props, context) => {
  const toobarContainerRef = useRef();
  const parentRef = useRef();
  const checkPermissionUser =
    props?.auth?.user?.roles?.supplierPermission?.owner ||
    props?.auth?.user?.roles?.supplierPermission?.user ||
    !props?.auth?.user?.roles?.isSupplier;
  const [permissionAction, setPermissionAction] = useState(checkPermissionUser);
  const [supplierUsers, setSupplierUsers] = useState([]);
  const [tableHeight, setTableHeight] = useState(500);
  const [isLoading, setIsLoading] = useState(false);
  const [footerData, setFooterData] = useState({
    limit: 20,
    total: 0,
    page: 0,
  });

  const handleResultPermissionKey = (key) => {
    let result = '';
    switch (key) {
      case 'user':
        result = 'manageUsers';
        break;
      case 'driver':
        result = 'manageDrivers';
        break;
      case 'vehicle':
        result = 'manageVehicles';
        break;
      case 'map':
        result = 'mapView';
        break;
      case 'calendar':
        result = 'calendarView';
        break;
      case 'booking':
        result = 'bookingView';
        break;
      case 'bookingCompleted':
        result = 'bookingCompletedView';
        break;
      case 'offers':
        result = 'offersView';
        break;
      case 'pricing':
        result = 'pricing';
        break;
      case 'owner':
        result = 'owner';
        break;
      default:
        break;
    }

    return I18n.t(`supplier.${result}`);
  };


  useEffect(() => {
    getUsersList(0, 20);
  }, [props.supplierId]);

  const getUsersList = (activePage, limit, countPartner) => {
    let param = {
      limit: limit ? limit : footerData.limit || 20,
      page: _.isNumber(activePage) ? activePage : footerData.page || 0,
      query: {
        fleetId: props.auth?.selectedFleet?.fleetId,
        supplierId: props.supplierId,
        isSupplier: true,
      },
    };

    setIsLoading(true);
    props.userActions.getUsers(param).then((data) => {
      if (data.ok && data?.res?.list) {
        let { page, total, limit } = data.res;
        setFooterData({
          ...footerData,
          limit,
          page,
          total: total || 0,
        });
        setSupplierUsers(data.res.list);
        setIsLoading(false);
      }
    });
  };

  const handleMenuClick = async (key, user) => {
    switch (key) {
      case 'View': {
        editUser(user.userId, true);
        break;
      }
      case 'Edit': {
        editUser(user.userId, false);
        break;
      }
      case 'Status': {
        const params = {
          fleetId: props.auth.selectedFleet.fleetId,
          userId: user.userId,
          isActive: !user.isActive,
        };
        try {
          const result = await props.userActions.userActiveSupplier(params);
          if (result?.res?._id) {
            context.notification('success', I18n.t('user.Update_user_success'));
            const newSupplierUsers = supplierUsers.map((s) => {
              if (s._id === result.res._id) {
                return result.res;
              }
              return s;
            });
            setSupplierUsers(newSupplierUsers);
          } else {
            if (data.error) {
              context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              context.notification('error', I18n.t('user.Update_user_fail'));
            }
          }
        } catch (error) {
          console.log(error);
          context.notification('error', I18n.t('user.Update_user_fail'));
        }
        break;
      }
      // }
      // case 'changeStatus': {
      //   this.setState({ editable: true });
      //   break;
      // }
      case 'Delete': {
        setConfirmDelete({
          isShow: true,
          user: user,
        });
        break;
      }
      default:
        break;
    }
  };

  const getTableColumns = () => {
    let tableColums = Object.assign([], tableColumnsDefault);
    _.forEach(tableColums, (col, index) => {
      switch (col.key) {
        case 'fullName':
          col.customCell = (supplier) => (
            <OverlayTrigger
              placement="top"
              delayShow={300}
              overlay={
                <Tooltip id="tooltip">
                  <span>{supplier.fullName}</span>
                </Tooltip>
              }
            >
              <span
                style={{ cursor: 'pointer' }}
                className="btn-header text-add-header"
                onClick={() => editUser(supplier.userId, true)}
              >
                {supplier.fullName}
              </span>
            </OverlayTrigger>
          );
          break;
        case 'supplierPermission':
          col.customCell = (data) => {
            let newData = JSON.parse(JSON.stringify(data));
            if (newData.supplierPermission) {
              if (newData.supplierPermission.owner) {
                return handleResultPermissionKey('owner');
              }
              if (!props?.detailItem?.supplierJobRequired) {
                newData.supplierPermission.offers = false;
              } else {
                newData.supplierPermission.offers = data.supplierPermission.offers;
              }
              return Object.keys(newData.supplierPermission)
                .filter((key) => newData.supplierPermission[key])
                .map((item) => handleResultPermissionKey(item))
                ?.join(', ');
            }

            return '';
          };
          break;
        case 'status':
          col.customCell = (user) => {
            let statusStr;
            let statusClass;
            if (user?.isActive) {
              statusStr = <Translate value="carSetting.Active" />;
              statusClass = 'Active';
            } else if (!user?.isActivate) {
              statusStr = <Translate value="carSetting.Inactive" />;
              statusClass = 'Inactive';
            }
            if (user.userId === props.auth.user._id) {
              return;
            }
            return (
              <>
                {permissionAction ? (
                  <Button
                    className={`btn-header text-add-header ${statusClass}`}
                    onClick={() => {
                      handleMenuClick('Status', user);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </Button>
                ) : (
                  <div style={{ color: user.isActive ? '#04BE76' : '#DE6C59' }}>
                    {statusStr}
                  </div>
                )}
              </>
            );
          };
          break;
        case 'actions':
          col.customCell = (user, rowIndex) => {
            if (permissionAction) {
              const actionItems = [
                {
                  label: 'carSetting.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: user.isActive
                    ? 'carSetting.Deactivate'
                    : 'carSetting.Activate',
                  eventKey: 'Status',
                },
              ];
              if (user.userId === props.auth.user._id) {
                actionItems.splice(1, 1);
              }
              if (!user.isActive) {
                actionItems.push({
                  label: 'carSetting.Delete',
                  eventKey: 'Delete',
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={user}
                  onSelect={(eventKey) => {
                    handleMenuClick(eventKey, user);
                  }}
                  totalRow={supplierUsers.length}
                  rowHeight={50}
                  menuItems={actionItems}
                  tableHeight={getTableHeight()}
                />
              );
            }
            return (
              <Button
                onClick={(e) => {
                  handleMenuClick('View', user);
                }}
                className="btn-header text-add-header"
              >
                <Translate value="carSetting.View" />
              </Button>
            );
          };
          break;
      }
    });
    return tableColums;
  };

  const editUser = (id, onlyView = false) => {
    props.router.push(
      `/supplier/detail/${props.supplierId}/edit-user?userId=${id}&action=${
        onlyView ? `view` : ''
      }`
    );
  };

  const createUser = () => {
    props.router.push(`/supplier/detail/${props.supplierId}/create-user`);
  };

  const getTableHeight = () => {
    const verticalPadding = 10;
    const toolbarheight = toobarContainerRef?.current?.offsetHeight;
    const parentHeight = parentRef?.current?.offsetHeight || 0;
    const toolbarExtendHeight = 0;
    const outerHeight = verticalPadding + toolbarheight + toolbarExtendHeight;
    let newTableHeight = parentHeight ? parentHeight - outerHeight : 0;
    if (window.innerHeight < 850 && newTableHeight < 500) newTableHeight = 550;
    return window.innerHeight - 400 > 500
      ? window.innerHeight - (props?.auth?.user?.roles?.isSupplier ? 270 : 370) + (toolbarheight ? 0 : 83)
      : 500;
  };

  const handleNumItemsPerPageChange = (limit) => getUsersList(0, limit);
  const handlePaginationSelect = (page) => getUsersList(parseInt(page));

  const [confirmDelete, setConfirmDelete] = useState({
    isShow: false,
    user: {},
  });

  const closeConfirmDelete = () => {
    setConfirmDelete({
      isShow: false,
      user: {},
    });
  };

  const deleteUser = async (user) => {
    if (!user.userId) return;
    try {
      const params = {
        fleetId: props.auth.selectedFleet.fleetId,
        userId: user.userId,
      };
      const data = await props.userActions.deleteUser(params);
      if (data?.res?._id) {
        context.notification('success', I18n.t('user.Delete_user_success'));
        const newSupplierUsers = supplierUsers.filter((s) => {
          if (s.userId !== data.res._id) {
            return s;
          }
        });
        setConfirmDelete({
          isShow: false,
          user: {},
        });
        setSupplierUsers(newSupplierUsers);
      } else {
        if (data.error) {
          context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          );
        } else {
          context.notification('error', I18n.t('user.Delete_user_fail'));
        }
      }
    } catch (error) {
      console.log(error);
      context.notification('error', I18n.t('user.Delete_user_fail'));
    }
  };

  const renderConfirmDelete = () => {
    return (
      <Modal
        show={confirmDelete.isShow}
        backdrop={true}
        className={'confirm'}
        onHide={closeConfirmDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="user.DELETE_USER" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={closeConfirmDelete}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Translate value="user.DELETE_CONFIRM" />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button
            className={'btn-save mr-md'}
            onClick={() => deleteUser(confirmDelete.user)}
          >
            <Translate value="user.Yes" />
          </Button>
          <Button className={'btn-cancel'} onClick={closeConfirmDelete}>
            <Translate value="user.No" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="supplierUser" ref={parentRef} style={{ height: '100%' }}>
      {permissionAction && (
        <div className="tabActions" ref={toobarContainerRef}>
          <Button
            className="btn-header text-add-header"
            onClick={createUser}
            disabled={!permissionAction}
          >
            {I18n.t('supplier.Add')}
          </Button>
        </div>
      )}
      <div className="gridViewTable">
        <StickyTable
          columns={getTableColumns()}
          bodyData={supplierUsers}
          footerData={footerData}
          rowHeight={50}
          getTableHeight={getTableHeight}
          handleNumItemsPerPageChange={handleNumItemsPerPageChange}
          handlePaginationSelect={handlePaginationSelect}
          isLoading={isLoading}
        />
      </div>

      {/* Modal Confirm */}
      {renderConfirmDelete()}
    </div>
  );
};

SupplierUser.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    commonData: state.commonData,
    language: state.i18n,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierUser);
