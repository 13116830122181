import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../settings.scss";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  Modal
} from "react-bootstrap";
import FormGroupTitle from "../../../components/formGroupTitile/FormGroupTitle";
import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { MakeModalColumns } from "./tableHeaderData";
import TableActions from "../../../components/table/tableAction/TableActions";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import _ from "lodash";
import UploadFile from "../../../components/upload/upload";
import UploadImageTemplate from "../../../components/upload/UploadImageTemplate";
import TableFooter from "../../../components/table/tableFooter/TableFooter";
import { BsPlus } from "react-icons/bs";

class MakeModel extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      isAddModel: false,
      carModel: null,
      showDeleteModelDialog: false,
      editable: false,
      valid: {},
      isSubmited: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      },
      modelFooterData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateDataList = this.updateDataList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.confirmDeleteUser = this.confirmDeleteUser.bind(this);
    this.AddModelButton = this.AddModelButton.bind(this);
    this.AddModelSaveClick = this.AddModelSaveClick.bind(this);
    this.deleteModel = this.deleteModel.bind(this);
    this.deleteModelConfirm = this.deleteModelConfirm.bind(this);
    this.editModelClick = this.editModelClick.bind(this);
  }
  componentDidMount() {
    this.updateDataList();
  }

  updateDataList(activePage, limit) {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: { fleetId: this.props.auth.selectedFleet.fleetId }
    };
    this.props.settingActions.getAllCarmake(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit }
        });
      }
    });
  }

  saveDialogForm() {
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.dialogData._id) {
      let objectCompany = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        id: this.state.dialogData._id
      };
      this.props.settingActions.updateCarMake(objectCompany).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("carSetting.Update_car_make_fail")
            );
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("carSetting.Update_car_make_success")
          );
          this.closeDialogForm();
          this.updateDataList();
        }
      });
    } else {
      let objectCompany = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        models: this.state.dialogData.models.map(md => {
          return {
            fleetId: this.props.auth.selectedFleet.fleetId,
            name: md.name,
            image: md.image
          };
        })
      };
      this.props.settingActions.createCarMake(objectCompany).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok && !data.res) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("carSetting.Create_car_make_fail")
            );
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("carSetting.Create_car_make_success")
          );
          this.closeDialogForm();
          this.updateDataList();
        }
      });
    }
  }

  handleInputChange(key, e) {
    if (key == "model_name") {
      this.state.carModel.name = e.target.value;
    } else {
      this.state.dialogData[key] = e.target.value;
    }

    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }

  handleAddButtonClick() {
    let object = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      name: "",
      models: []
    };
    this.state.carModel = {};
    this.setState({
      showDialog: true,
      dialogData: object,
      isAddModel: false,
      editable: true
    });
  }
  AddModelButton() {
    this.state.carModel = {};
    this.setState({
      carModel: { name: "" },
      isAddModel: !this.state.isAddModel
    });
  }
  AddModelSaveClick() {
    if (!this.state.carModel.name) {
      this.context.notification(
        "error",
        I18n.t("carSetting.model_name_is_required")
      );
      return;
    }
    if (this.state.dialogData._id) {
      this.props.loadingBarActions.showLoadingSpiner();
      if (this.state.carModel._id) {
        this.props.settingActions
          .updateCarModel({
            fleetId: this.props.auth.selectedFleet.fleetId,
            id: this.state.carModel._id,
            name: this.state.carModel.name,
            image: this.state.carModel.image
          })
          .then(data => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
              this.state.carModel = {};
              let index = this.state.dialogData.models.findIndex(obj => {
                return obj._id == data.res._id;
              }); //.push(data.res);
              if (index >= 0) {
                this.state.dialogData.models[index] = {
                  ...data.res
                };
              }
              this.state.isAddModel = false;
              this.setState({ carModel: this.state.carModel });
              this.context.notification(
                "success",
                I18n.t("carSetting.Update_car_model_success")
              );
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Update_car_model_fail")
                );
              }
            }
          });
      } else {
        this.props.settingActions
          .createCarModel({
            fleetId: this.props.auth.selectedFleet.fleetId,
            vehicleMakeId: this.state.dialogData._id,
            name: this.state.carModel.name,
            image: this.state.carModel.image
          })
          .then(data => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
              this.state.carModel = {};
              this.state.dialogData.models.push(data.res);
              this.state.isAddModel = false;
              this.setState({ carModel: this.state.carModel });
              this.context.notification(
                "success",
                I18n.t("carSetting.Create_car_model_success")
              );
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Create_car_model_fail")
                );
              }
            }
          });
      }
    } else {
      this.state.dialogData.models.push(this.state.carModel);
      this.state.carModel = {};
      this.state.isAddModel = false;
      this.setState({ dialogData: this.state.dialogData });
    }
  }
  deleteModel(data) {
    if (data._id) {
      this.setState({
        showDeleteModelDialog: !this.state.showDeleteModelDialog,
        carModel: data
      });
    } else {
      let list = [];
      this.state.dialogData.models.map(dt => {
        if (dt !== data) {
          list.push(dt);
        }
      });
      this.state.dialogData.models = list;
      this.setState({
        dialogData: this.state.dialogData,
        showDeleteModelDialog: false
      });
    }
  }
  deleteModelConfirm() {
    this.props.settingActions
      .deleteCarModel({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.carModel._id
      })
      .then(data => {
        if (data.ok && data.res) {
          let list = [];
          this.state.dialogData.models.map(dt => {
            if (dt._id !== this.state.carModel._id) {
              list.push(dt);
            }
          });
          this.state.dialogData.models = list;
          this.setState({
            dialogData: this.state.dialogData,
            showDeleteModelDialog: false
          });
        } else {
          this.setState({
            showDeleteModelDialog: false
          });
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        }
      });
  }
  editModelClick(data) {
    this.state.carModel = data;
    this.state.isAddModel = true;
    this.setState({
      carModel: {
        ...data
      }
    });
  }

  handleCarModelImageChange = (data, error) => {
    let result = "",
      file = null;
    if (data) {
      result = data.result;
      file = data.file;
    }
    this.setState({
      carModel: {
        ...this.state.carModel,
        image: result,
        imageFile: file
      }
    });
  };

  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false
    });
  }

  handleMenuClick(key, data) {
    switch (key) {
      case "View": {
        this.state.dialogData = data;
        let newState = {
          showDialog: true,
          dialogChanged: false,
          isAddModel: false,
          editable: false
        };
        this.getCarModelList(0, 20, newState);
        break;
      }
      case "Edit": {
        this.state.dialogData = { ...data };
        let newState = {
          showDialog: true,
          dialogChanged: false,
          isAddModel: false,
          editable: true
        };
        this.getCarModelList(0, 20, newState);
        break;
      }
      case "Status": {
        if (data.isActive) {
          this.props.settingActions
            .deactiveRateHourly({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id
            })
            .then(data => {
              if (data.ok) {
                this.updateDataList();
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Update_status_error")
                );
              }
            });
        } else {
          this.props.settingActions
            .activeRateHourly({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id
            })
            .then(data => {
              if (data.ok) {
                this.updateDataList();
              } else {
                this.context.notification(
                  "error",
                  I18n.t("carSetting.Update_status_error")
                );
              }
            });
        }

        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateDataList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    let numItemsPerPage = parseInt(e);
    this.updateDataList(0, numItemsPerPage);
  }

  confirmDeleteUser() {
    this.props.settingActions
      .deleteCarMake({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.dialogData._id
      })
      .then(data => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            "success",
            I18n.t("carSetting.Delete_car_make_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification(
              "error",
              I18n.t("carSetting.Delete_cat_make_fail")
            );
          }
        }
      });
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], MakeModalColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "Name":
          col.customCell = data => {
            return (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", data);
                }}
              >
                {data.name}
              </a>
            );
          };
          break;

        case "actions":
          col.customCell = (data, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: "carSetting.Edit",
                  eventKey: "Edit"
                }
              ];

              if (!data.inUse) {
                actionItems.push({
                  label: "carSetting.Delete",
                  eventKey: "Delete"
                });
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={data}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, data);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", data);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="carSetting.View" />
                </a>
              );
            }
          };
          break;
        default:
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  // model footer data
  handleModelPaginationSelect = newPage => this.getCarModelList(newPage);

  handleModelNumItemsPerPageChange = e => this.getCarModelList(0, parseInt(e));

  getCarModelList = (activePage, limit, newState = {}) => {
    const { modelFooterData, dialogData } = this.state;
    let params = {
      limit: limit || modelFooterData.limit,
      page: activePage || activePage === 0 ? activePage : modelFooterData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        vehicleMakeId: dialogData._id
      }
    };
    this.props.settingActions.getAllCarModel(params).then(res => {
      if (res.ok) {
        let { page, limit, total } = res.res;
        let newDialogData = Object.assign({}, this.state.dialogData);
        newDialogData.models = res.res.list;
        this.setState({
          dialogData: newDialogData,
          modelFooterData: { page, limit, total },
          ...newState
        });
      } else {
        if (res.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + res.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("carSetting.Loading_car_model_fail")
          );
        }
      }
    });
  };

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list.length > 0
        ? this.state.dataList.list
        : [];
    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="carSetting.DELETE_CAR_MAKE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="carSetting.DELETE_CONFIRM_CAR_MAKE" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteUser}
              >
                <Translate value="carSetting.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="carSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        {this.state.showDeleteModelDialog ? (
          <Modal onHide={this.deleteModel} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="carSetting.DELETE_CAR_MODEL" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.deleteModel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="carSetting.DELETE_CONFIRM_MODEL" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.deleteModelConfirm}
              >
                <Translate value="carSetting.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.deleteModel}>
                <Translate value="carSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        {this.state.showDialog && this.state.dialogData ? (
          <Modal show={true} backdrop={true} bsSize="lg" onHide={this.closeDialogForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="carSetting.EDIT_CAR_MAKE" />
                  ) : (
                      <Translate value="carSetting.DETAIL_CAR_MAKE" />
                    )
                ) : (
                    <Translate value="carSetting.CREATE_CAR_MAKE" />
                  )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <Form.Label>
                  <Translate value="carSetting.Car_Make" />{" "}
                  <span className="require">*</span>
                </Form.Label>
                <Row className="mr-t-10 mr-b-10">
                  <Col xs={10}>
                    <FormControl
                      type="text"
                      className={"form-custom"}
                      onChange={e => {
                        this.handleInputChange("name", e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.name : ""
                      }
                      placeholder={I18n.t("carSetting.Car_Make")}
                      disabled={!this.state.editable}
                      autoFocus={true}
                    />
                  </Col>
                  <Col xs={2}>
                    {!this.props.permissions ||
                      this.props.permissions.actions ? (
                        this.state.editable ? (
                          <Button
                            className={"btn-save pull-right"}
                            onClick={this.saveDialogForm}
                            disabled={
                              !this.state.dialogData.name.trim() ||
                              !this.state.dialogChanged
                            }
                          >
                            <Translate value="carSetting.Save" />
                          </Button>
                        ) : (
                            <Button
                              className={"btn-save mr-md"}
                              onClick={e => {
                                this.handleMenuClick("changeStatus", e);
                              }}
                            >
                              <Translate value="carSetting.Edit" />
                            </Button>
                          )
                      ) : null}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroupTitle>
                <Translate value="carSetting.MODEL_LIST" />
              </FormGroupTitle>
              {(!this.props.permissions || this.props.permissions.actions) &&
                !this.state.isAddModel ? (
                  <ButtonToolbar className="text-center header-button-group">
                    <Button
                      className={"btn-header text-add-header ml0"}
                      onClick={this.AddModelButton}
                    >
                      {" "}
                      <BsPlus />
                      <Translate value="carSetting.Add_Model" />
                    </Button>
                  </ButtonToolbar>
                ) : (
                  ""
                )}

              {this.state.isAddModel ? (
                <div className="add-model-container">
                  <Row className="mr-t-10 mr-b-10">
                    <Col xs={6}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="carSetting.Model_Name" />
                        </Form.Label>
                        <FormControl
                          type="text"
                          className={"form-custom"}
                          placeholder={I18n.t("carSetting.Model_Name")}
                          value={this.state.carModel.name}
                          autoFocus={true}
                          onChange={e => {
                            this.handleInputChange("model_name", e);
                          }}
                        />
                      </FormGroup>
                      <ButtonToolbar className="text-center bottom-button-group mr-t-10">
                        <Button
                          className="btn-header text-add-header mr-md"
                          onClick={this.AddModelSaveClick}
                          disabled={
                            !this.state.carModel.name.trim() ||
                            this.state.carModel.name.length === 0
                          }
                        >

                          {this.state.carModel._id ? (
                            <Fragment>
                              <i className="fa fa-save" />
                              <Translate value="carSetting.Save" />
                            </Fragment>
                          ) : (
                              <Fragment>
                                <i className="fa fa-save" />
                                <Translate value="carSetting.Save" />
                              </Fragment>
                            )}
                        </Button>
                        <Button
                          className="btn-header text-delete-header"
                          onClick={this.AddModelButton}
                        >
                          <i className="fa fa-remove" />
                          <Translate value="carSetting.Cancel" />
                        </Button>
                      </ButtonToolbar>
                    </Col>
                    <Col xs={6} className="">
                      <UploadFile
                        id="car-model-image"
                        name="car_model_image"
                        onChange={this.handleCarModelImageChange}
                        customTemplate={UploadImageTemplate}
                        className="car-model-upload-image"
                        customTemplateProps={{
                          fileResult: this.state.carModel.image || null,
                          noImageTitle: "carSetting.Upload_an_image"
                        }}
                        accepts="image/*"
                        onlyImage
                        note={true}
                      />
                    </Col>
                  </Row>
                </div>
              ) : (
                  ""
                )}

              <div className="model-list-container">
                <table className="table table-no-pagination cc-table-striped">
                  <thead>
                    <tr className="">
                      <th>
                        <Translate value="carSetting.Car_Model" />
                      </th>
                      <th>
                        <Translate value="carSetting.Image" />
                      </th>
                      <th>
                        <Translate value="carSetting.Actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dialogData
                      ? this.state.dialogData.models.map(m => {
                        return (
                          <tr key={m._id}>
                            <td>{m.name}</td>
                            <td>
                              <img
                                className="model-thumbnail"
                                src={m.image}
                              />
                            </td>
                            <td>
                              {!this.props.permissions ||
                                this.props.permissions.actions ? (
                                  <div className="car-model-action">
                                    {this.state.dialogData._id ? (
                                      <i
                                        className="fa fa-edit fa-2x"
                                        onClick={e => {
                                          this.editModelClick(m);
                                        }}
                                      />
                                    ) : (
                                        ""
                                      )}
                                    <i
                                      className="fa fa-trash fa-2x"
                                      onClick={e => {
                                        this.deleteModel(m);
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>
                <TableFooter
                  handleNumItemsPerPageChange={
                    this.handleModelNumItemsPerPageChange
                  }
                  handlePaginationSelect={this.handleModelPaginationSelect}
                  data={this.state.modelFooterData}
                  className="mb-md"
                />
              </div>
              <Form.Label>
                (<span className="require">*</span>
                ): <Translate value="carSetting.Required_fields" />
              </Form.Label>
            </Modal.Body>
            <Modal.Footer>
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="carSetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              className="btn-header text-add-header ml0"
              onClick={this.handleAddButtonClick}
            >
              {" "}

              <Translate value="carSetting.Add" />
            </Button>
          </ButtonToolbar>
        ) : (
            ""
          )}

        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

MakeModel.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MakeModel);
