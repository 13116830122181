import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';

import BookingList from './BookingList';

class BookingForm extends Component {
    constructor(props) {
        super();
        this.state = {
        }
    };

    getBookingList = () => {
        const { bookings } = this.props.tripInfo;
        if (!bookings) return [];
        let list = bookings;
        return list;
    };

    render() {
        return (
            <Fragment>
                {/* <FormGroupTitle>
                    <Translate value="tripDetails.BOOKINGS" />
                </FormGroupTitle> */}
                <BookingList
                    selectedFleet={this.props.selectedFleet}
                    tripId={this.props.tripInfo.tripId}
                />
            </Fragment>
        );
    }
}

export default BookingForm;
