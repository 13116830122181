import TimeAgo from "react-timeago";
import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  ListGroup,
  ListGroupItem,
  Image,
  Modal
} from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import FlagIconFactory from "react-flag-icon-css";
import { userType } from "../../constants/commondata";
import { findFleet, changeFleet } from "../../actions/auth";
import providerSignUpIcon from "../../assets/images/icons/providerSignUp.svg";
import sosIcon from "../../assets/images/icons/SOS.svg";
import bellIcon from "../../assets/images/icons/bell.svg";
import { renderMSGElement } from '../../utils/commonFunctions'
import collapseMenuIcon from "../../assets/images/icons/menu_collapsed.svg";
import CCDropDown from "../dropdown/ccDropDown";
import "./header.scss";
import { find, debounce } from "lodash";

const FlagIcon = FlagIconFactory(React, { useCssModules: false });

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicFleets: [],
      mobileShowFleetList: false,
      search: "",
      page: 0,
      allFleet: 0,
      totalFleet: 0,
      menuNotiOpened: false,
      menuSOSOpened: false,
      menuSignUpOpened: false
    };

    this.searchFleetDebounce = debounce(this.handleSearchFleet, 200);
  }

  componentDidMount() {
    const { user, findFleet } = this.props;

    findFleet({ page: 0, limit: 50 }).then(data => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          dynamicFleets: data.res.list,
          page: 0,
          allFleet: data.res.total,
          totalFleet: data.res.total
        });
      } else {
        this.setState({
          dynamicFleets: user.fleetList,
          allFleet: user.fleetList.length,
          totalFleet: user.fleetList.length
        });
      }
    });
  }

  handleSelectFleet = fleetId => {
    const fleetData = find(
      this.state.dynamicFleets,
      f => f.fleetId === fleetId
    );
    return fleetData && this.props.changeFleet(fleetData);
  };

  languageSelected = lang => {
    this.props.languageSelect(lang);
  };

  deleteNotiItemHandle = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.deleteNotiItem(data);
  };

  deleteSOSNotiItemHandle = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.deleteSOSNotiItem(data);
  };

  deleteSignUpNotiItemHandle = (e, data) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.deleteSignUpNotiItem(data);
  };

  onMyAccountClick = () => {
    this.props.hideNewBooking();
    this.props.router.push("/profile");
  };

  handleMobileSelectFleetClick = e => {
    const { mobileShowFleetList } = this.state;

    this.setState(
      {
        mobileShowFleetList: !mobileShowFleetList
      },
      () => {
        if (mobileShowFleetList) {
          this.handleSearchFleet("");
        }
      }
    );
  };

  handleSearchFleet = (inputValue, callback) => {
    const { search } = this.state;

    if (inputValue !== search) {
      return this.getFleets(inputValue, callback);
    } else {
      return callback && callback();
    }
  };

  loadMoreFleet = () => {
    const { search, page, totalFleet } = this.state;

    if ((page + 1) * 50 > totalFleet) return false;
    this.getFleets(search);
  };

  getFleets = (inputValue, callback) => {
    const { findFleet } = this.props;
    const { search, page, dynamicFleets, allFleet } = this.state;

    let params = {
      limit: 50,
      query: {
        search: inputValue
      }
    };

    params.page = inputValue.trim() !== search ? 0 : page + 1;

    findFleet(params).then(data => {
      if (data.ok && data.res && data.res.list) {
        const list = data.res.list;

        this.setState({
          page: params.page,
          search: inputValue,
          dynamicFleets: params.page > 0 ? dynamicFleets.concat(list) : list,
          totalFleet: data.res.total,
          allFleet: !inputValue.trim().length ? data.res.total : allFleet
        });
      }

      if (callback) callback();
    });
  };

  handleToggleNoti = (toggle) => {
    this.setState({ menuNotiOpened: !this.state.menuNotiOpened })
  }

  handleToggleSOS = (toggle) => {
    this.setState({ menuSOSOpened: !this.state.menuSOSOpened })
  }

  handleToggleSignUp = (toggle) => {
    this.setState({ menuSignUpOpened: !this.state.menuSignUpOpened })
  }

  handleOpenDetailPage = (params) => {
    this.props.handleOpenDetailPage(params);
    this.props.onMenuClick(params.replace('/', ''))
    switch (params) {
      case "/notifications":
        this.handleToggleNoti();
        break;
      case "/sos":
        this.handleToggleSOS();
        break;
      case "/signupnotifications":
        this.handleToggleSignUp();
        break;
    }
  }

  render() {
    const { dynamicFleets, allFleet } = this.state;
    const { selectedFleet, permissions, user } = this.props;
    const { actions: hasPermission = false } = permissions || {};
    return (
      <React.Fragment>
        <Navbar className={"navigator navbar-fixed-top desktop justify-content-between"} bg="light" expand="lg">
          {/* <ul className="nav navbar-nav navbar-left fleet-list">
            <li
              style={{ display: this.props.collapsed ? "none" : "block" }}
              className="collapse-icon"
            >
            </li>
          </ul> */}
          {
            this.props.collapsed ? ( <div> </div> ) : (
              <Navbar.Brand className="collapse-icon">
                  <a onClick={this.props.collapedClickHandle}>
                    <Image src={collapseMenuIcon} />
                  </a>
              </Navbar.Brand>
            )
          }
          <Nav className="justify-content-end">
            {!this.props.auth?.user?.roles?.isSupplier && (this.context.user.userType == userType.FleetAdmin ||
              this.context.user.userType == userType.FleetUser) && (
                <NavDropdown
                  id={"sos-drop"}
                  className="noti sos-item headerDrop"
                  eventKey={3}
                  style={{ padding: '10px' }}
                  title={
                    <div className="sos-icon count-container">
                      <Image src={sosIcon} />
                      {/* <span>{this.props.sosNotification.length}</span> */}
                      {this.props.sosNotification.filter(n => !n.viewed)
                        .length ? (
                          <div
                            className={
                              "count" +
                              (this.props.sosNotification.filter(n => !n.viewed)
                                .length < 100
                                ? this.props.sosNotification.filter(n => !n.viewed)
                                  .length > 9
                                  ? " count2"
                                  : ""
                                : " count3")
                            }
                          >
                            {
                              this.props.sosNotification.filter(n => !n.viewed)
                                .length
                            }
                          </div>
                        ) : null}
                    </div>
                  }
                  onClick={this.props.viewedSOS}
                  onToggle={this.handleToggleSOS}
                  open={this.state.menuSOSOpened}
                >
                  <NavDropdown.Item
                    style={{ padding: 0 }}
                  >
                    <div className="noti-container">
                      <Translate value="General.SOS_Notifications" />
                      <a
                        className="btn-show-all"
                        onClick={() => this.handleOpenDetailPage("/sos")}
                      >
                        {I18n.t("General.Show_all")}
                      </a>
                    </div>
                    <div className="noti-list-container">
                      <ListGroup className="noti-list">
                        {this.props.sosNotification.length > 0 ? (
                          this.props.sosNotification.map((data, key) => {
                            return (
                              <ListGroupItem
                                key={key}
                                className={
                                  data.unread ? "noti-item unread" : "noti-item"
                                }
                                onClick={() => {
                                  this.props.SOSitemclick(data);
                                }}
                              >
                                <p className="noti-item-title ">{data.msg}</p>
                                <span className="noti-item-time">
                                  <TimeAgo date={data.createdDate} />
                                </span>
                                {
                                  hasPermission && <div
                                    className="noti-delete"
                                    onClick={e => {
                                      this.deleteSOSNotiItemHandle(e, data);
                                    }}
                                  />
                                }
                              </ListGroupItem>
                            );
                          })
                        ) : (
                            <ListGroupItem className="noti-item no-messaeg">
                              <p className="noti-item-title">
                                <Translate value="General.You_dont_have_any_message" />
                              </p>
                            </ListGroupItem>
                          )}
                      </ListGroup>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {!this.props.auth?.user?.roles?.isSupplier && (this.context.user.userType == userType.FleetAdmin ||
              this.context.user.userType == userType.FleetUser) && (
                <NavDropdown
                  id={"provider-signup-drop"}
                  className="noti headerDrop"
                  eventKey={3}
                  style={{ padding: '10px' }}
                  title={
                    <div className="notification-icon count-container">
                      <Image src={providerSignUpIcon} />
                      {this.props.signUpNotification.filter(n => !n.viewed)
                        .length ? (
                          <div
                            className={
                              "count" +
                              (this.props.signUpNotification.filter(n => !n.viewed)
                                .length < 100
                                ? this.props.signUpNotification.filter(n => !n.viewed)
                                  .length > 9
                                  ? " count2"
                                  : ""
                                : " count3")
                            }
                          >
                            {
                              this.props.signUpNotification.filter(n => !n.viewed)
                                .length
                            }
                          </div>
                        ) : null}
                    </div>
                  }
                  onClick={this.props.viewedDriverSignUp}
                  onToggle={this.handleToggleSignUp}
                  open={this.state.menuSignUpOpened}
                >
                  <NavDropdown.Item
                    style={{ padding: 0 }}
                  >

                    <div className="noti-container">
                      <Translate value="General.Notifications" />
                      <a
                        className="btn-show-all"
                        onClick={() => this.handleOpenDetailPage("/signupnotifications")}
                      >
                        {I18n.t("General.Show_all")}
                      </a>
                    </div>
                    <div className="noti-list-container">
                      <ListGroup className="noti-list">
                        {this.props.signUpNotification.length > 0 ? (
                          this.props.signUpNotification.map((data, key) => {
                            return (
                              <ListGroupItem
                                key={key}
                                className={
                                  data.unread ? "noti-item unread" : "noti-item"
                                }
                                onClick={() => {
                                  this.props.signUpItemClick(data);
                                }}
                                href={data.userId ? `/driver/view/${data.userId}/info` : null}
                                target="_blank"
                              >
                                <p className="noti-item-title ">
                                  {data.msg}
                                </p>
                                <span className="noti-item-time">
                                  <TimeAgo date={data.createdDate} />
                                </span>
                                {
                                  hasPermission && <div
                                    className="noti-delete"
                                    onClick={e => {
                                      this.deleteSignUpNotiItemHandle(e, data);
                                    }}
                                  />
                                }
                              </ListGroupItem>
                            );
                          })
                        ) : (
                            <ListGroupItem className="noti-item no-messaeg">
                              <p className="noti-item-title">
                                <Translate value="General.You_dont_have_any_message" />
                              </p>
                            </ListGroupItem>
                          )}
                      </ListGroup>
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              )}

            {!this.props.auth?.user?.roles?.isSupplier && this.props.isShowNotification ? (
              <NavDropdown
                id={"noti-drop"}
                className="noti headerDrop"
                eventKey={3}
                style={{ padding: '10px' }}
                title={
                  <div className="notification-icon count-container">
                    <Image src={bellIcon} />
                    {this.props.notification.filter(n => !n.viewed).length ? (
                      <div
                        className={
                          "count" +
                          (this.props.notification.filter(n => !n.viewed)
                            .length < 100
                            ? this.props.notification.filter(n => !n.viewed)
                              .length > 9
                              ? " count2"
                              : ""
                            : " count3")
                        }
                      >
                        {this.props.notification.filter(n => !n.viewed).length}
                      </div>
                    ) : null}
                  </div>
                }
                onClick={this.props.clicktoView}
                onToggle={this.handleToggleNoti}
                open={this.state.menuNotiOpened}
              >
                <div className="noti-container">
                  <Translate value="General.Notifications" />
                  <a
                    className="btn-show-all"
                    onClick={() => this.handleOpenDetailPage("/notifications")}
                  >
                    {I18n.t("General.Show_all")}
                  </a>
                </div>
                <div className="noti-list-container">
                  <ListGroup className="noti-list">
                    {this.props.notification.length > 0 ? (
                      this.props.notification.map((data, key) => {
                        return (
                          <ListGroupItem
                            key={key}
                            className={
                              data.unread ? "noti-item unread" : "noti-item"
                            }
                            onClick={() => {
                              this.props.itemclick(data);
                            }}
                          >
                            {renderMSGElement(data.msg, data.thirdParty)}
                            <span className="noti-item-time">
                              <TimeAgo date={data.createdDate} />
                            </span>
                            {
                              hasPermission &&
                              <div
                                className="noti-delete"
                                onClick={e => {
                                  this.deleteNotiItemHandle(e, data);
                                }}
                              />
                            }
                          </ListGroupItem>
                        );
                      })
                    ) : (
                        <ListGroupItem className="noti-item no-messaeg">
                          <p className="noti-item-title">
                            <Translate value="General.You_dont_have_any_message" />
                          </p>
                        </ListGroupItem>
                      )}
                  </ListGroup>
                </div>
              </NavDropdown>
            ) : null}

            <NavDropdown
              eventKey={3}
              style={{ padding: '10px' }}
              title={
                this.context.user ? (
                  <span>
                    <i className="fa fa-user-circle-o" aria-hidden="true" />{" "}
                    {this.context.user.userName}
                  </span>
                ) : (
                    ""
                  )
              }
              id="basic-nav-dropdown"
            >
              <Dropdown.Item eventKey={3.1} onClick={this.onMyAccountClick}>
                <Translate value="General.My_Account" />
              </Dropdown.Item>
              <Dropdown.Item divider />
              <Dropdown.Item
                eventKey={3.2}
                onClick={() => {
                  this.props.logout();
                }}
              >
                <Translate value="General.Sign_out" />
              </Dropdown.Item>
            </NavDropdown>

            {Object.keys(this.props.translations).length > 1 ? (
              <NavDropdown
                id="header-languageSelect"
                eventKey={3}
                style={{ padding: '10px 0', color: 'white' }}
                title={
                  <button
                    className="header-languageSelect__btn"
                  >
                    <FlagIcon
                      code={
                        this.context.currenLanguage.translations[
                          this.context.currenLanguage.locale
                        ].flagCode
                      }
                    />
                    {
                      this.context.currenLanguage.translations[
                        this.context.currenLanguage.locale
                      ].name
                    }
                  </button>
                }
              >
                {Object.keys(this.props.translations).map(key => {
                  let lang = this.props.translations[key];
                  return (
                    <Dropdown.Item
                      className="header-dropdown-language"
                      eventKey={3.1}
                      key={lang.flagCode}
                      onClick={() => {
                        this.languageSelected(key);
                      }}
                    >
                      <FlagIcon code={lang.flagCode} />
                      {lang.name}
                    </Dropdown.Item>
                  );
                })}
              </NavDropdown>
            ) : (
                <li style={{ padding: '18px 10px'}}>
                  <a>
                    <FlagIcon
                      code={
                        this.context.currenLanguage.translations[
                          this.context.currenLanguage.locale
                        ].flagCode
                      }
                    />
                    {
                      this.context.currenLanguage.translations[
                        this.context.currenLanguage.locale
                      ].name
                    }
                  </a>
                </li>
              )}

            {selectedFleet && allFleet > 1  && (
              <CCDropDown
                id="fleets-dropdown"
                className="select-fleet-dropdown"
                title={""}
                valueKey="fleetId"
                labelKey="name"
                items={dynamicFleets}
                selectedItems={selectedFleet ? [selectedFleet.fleetId] : []}
                onSelect={this.handleSelectFleet}
                onSearchChange={this.searchFleetDebounce}
                customTitle={<span>{selectedFleet.name}</span>}
                onScrollBottom={this.loadMoreFleet}
                customLabel={item => `${item.name} (${item.fleetId})`}
                searchable
                async
                noTranslateLabel
                // drop={left}
              />
            )}
            
          </Nav>
        </Navbar>
        <Navbar className={"navigator navbar-fixed-top mobile"}>
          <div className="mobile-bar header-action text-left">
            <i
              className="fa fa-bars"
              aria-hidden="true"
              onClick={this.props.mobileHambergerClick}
            />
          </div>
          <div className="page-title">
            <span> {this.props.title} </span>
          </div>
          {allFleet > 1 ? (
            <div
              className={
                "mb-select-fleet-container header-action text-right" +
                (this.state.mobileShowFleetList ? " show" : "")
              }
            >
              <i
                className={
                  "cursor-pointer fa " +
                  (this.state.mobileShowFleetList ? "" : "fa-chevron-down")
                }
                onClick={this.handleMobileSelectFleetClick}
              />
              {selectedFleet ? <Modal
                show={this.state.mobileShowFleetList}
                backdrop={true}
                onHide={this.handleMobileSelectFleetClick}
                className="mb-select-fleet-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {this.props.selectedFleet ? (
                      <span> {this.props.selectedFleet.name} </span>
                    ) : (
                        ""
                      )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <CCDropDown
                      id="fleets-dropdown-mobile"
                      className="select-fleet-dropdown"
                      title={""}
                      valueKey="fleetId"
                      labelKey="name"
                      items={dynamicFleets}
                      selectedItems={
                        selectedFleet ? [selectedFleet.fleetId] : []
                      }
                      onSelect={this.handleSelectFleet}
                      onSearchChange={this.searchFleetDebounce}
                      customTitle={<span>{selectedFleet.name}</span>}
                      onScrollBottom={this.loadMoreFleet}
                      customLabel={item => `${item.name} (${item.fleetId})`}
                      searchable
                      async
                      noTranslateLabel
                      open
                    />
                  </div>
                </Modal.Body>
              </Modal> : null}
            </div>
          ) : null}
        </Navbar>
      </React.Fragment>
    );
  }
}

Header.contextTypes = {
  currenLanguage: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => ({
  selectedFleet: state.auth.selectedFleet,
  user: state.auth.user,
  permissions: state.menuHandle.modulePermission,
  auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  findFleet: options => dispatch(findFleet(options)),
  changeFleet: fleet => dispatch(changeFleet(fleet))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
