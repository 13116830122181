export const Columns = [
    {
      key: "name",
      label: "permission.Name",
      fixed: true,
      cellClass: "cell-align-toolbar",
      headerClass: "header-align-toolbar",
      width: 300
    },
    {
      key: "permissionStatus",
      label: "permission.Status"
    },
    {
      key: "actions",
      label: "permission.Actions",
      align: "right",
      cellClass: "table-actions"
    }
  ];

