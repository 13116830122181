import React from 'react';
import styles from '../../styles/formPayment.module.scss';
import { I18n, Translate } from 'react-redux-i18n';
import Balance from '../../Balance';
import CashWalletBalance from '../../CashWalletBalance';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import _ from 'lodash';
import BankAccount from '../BankAccount';
import {
  SettelementSetting,
  paymentGetwaySettlement,
} from '../../../../constants/commondata';
import Confirm from '../../../../components/confirm/Confirm';
import currencyFormatter from 'currency-formatter';

let driverIcEditable = false;
const reg = /^\d+$/;
const FILE_ACCEPTED =
  '.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip';
const FormPayment = (valProps) => {
  const {
    state,
    props,
    handleChangeBalanceClick,
    handleClickShowCashWalletBalance,
    handleConfirmCloseClick,
    handleCreditBalanceSuccess,
    handleChangeCashBalanceSuccess,

    handleStripeAccountIdChange,
    handleNameOfBankChange,
    validatorCallback,
    handleNameOfAccountChange,
    handleRTBNumberChange,
    handleAccountNumberChange,
    handleIFSCCodeChange,
    handleCheckNumberChange,
    handleSwiftCodeNumberChange,
    handleSSNChange,
    handleSortCodeChange,
    handleVerificationDocumentChange,
    handleVerificationDocumentBackChange,
    handleAdditionalDocumentChange,
    handleAdditionalDocumentBackChange,
    handleRemoveBankInfo,
    handleRemoveBankDocumentChange,
    handeChangeIsBankAccountOwner,
    handleChangebeneficiary,
    handleConfirmButtonClick,

    closeDialogForm,
    saveDialogForm,
    onEditable,
    action,
  } = valProps;
  const { detailItem, selectedCurrency, enableStripeConnect, editable } = state;
  const { auth, commonData, permissions } = props;
  const selectedCurrencyObj = _.find(
    auth.selectedFleet.currencies,
    (i) => i.iso == selectedCurrency
  );

  const { drvPayout, creditConfig } = props.auth.selectedFleet;
  const stripeConnectStatus = _.get(
    detailItem,
    'driverInfo.stripeConnectStatus',
    ''
  );

  driverIcEditable =
    detailItem.achTokenVerified && drvPayout.enable ? true : !editable;

  let bankAccountEditable = !editable;

  const bankingInfoSetting = auth.selectedFleet.bankingInfo || {};

  const isBankingInfoRequired =
    bankingInfoSetting &&
    bankingInfoSetting.enable &&
    bankingInfoSetting.verifyAccount == SettelementSetting.verifyAccount
      ? true
      : false;

  if (drvPayout.enable) {
    var isRequireStripe = false;
    var isRequireMolpay = true;
  } else {
    var isRequireStripe =
      isBankingInfoRequired &&
      !creditConfig.multiGateway &&
      creditConfig.configGateway &&
      creditConfig.configGateway.gateway === paymentGetwaySettlement.Stripe;
  }

  if (
    detailItem.achTokenVerified &&
    creditConfig.configGateway &&
    creditConfig.configGateway.gateway === paymentGetwaySettlement.Stripe
  ) {
    bankAccountEditable = true;
  } else if (detailItem.driverInfo.isBankVerified) {
    bankAccountEditable = true;
  }

  const isBankRequire = (key) => {
    const { detailItem, bankRequirement } = state;
    return bankRequirement[key] ? bankRequirement[key] : false;
  };

  const renderDriverDeposit = () => {
    if (!auth.selectedFleet) return null;

    let creditBalances = state.detailItem.driverInfo.creditBalances;
    let creditValue = '0.00';
    let creditBalance = _.find(
      creditBalances,
      (x) => x.currencyISO == selectedCurrency
    );
    let currencyObj = _.find(
      auth.selectedFleet.currencies,
      (i) => i.iso === selectedCurrency
    );

    if (creditBalance) {
      try {
        creditValue = creditBalance.value.toFixed(2);
      } catch (err) {
        creditValue = creditBalance.value;
      }
    }
    return (
      <div className={styles['valPayment']}>
        {currencyFormatter.format(creditValue, {
          symbol: currencyObj?.symbol,
        })}
      </div>
    );
  };

  const renderDepositButton = () => {
    const driverInfo = detailItem.driverInfo;
    let isDisableDeposit = false;
    if (driverInfo && !driverInfo.isActivate) {
      isDisableDeposit = true;
    }
    return isDisableDeposit ? (
      <React.Fragment>
        <p style={{ color: 'white' }} className="m0">
          {I18n.t('driver.Change_balance')}
        </p>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <a
          onClick={() => {
            handleChangeBalanceClick();
          }}
          href="javascript:void(0)"
          className="text-active"
        >
          {I18n.t('driver.Change_balance')}
        </a>
        {/* <p className="m0">{I18n.t('driver.Topup_note')}</p> */}
      </React.Fragment>
    );
  };

  const renderCashWalletButton = () => {
    const driverInfo = detailItem.driverInfo;
    let isDisableDeposit = false;
    if (driverInfo && !driverInfo.isActivate) {
      isDisableDeposit = true;
    }
    return isDisableDeposit ? (
      <React.Fragment>
        <p style={{ color: 'white' }} className="m0">
          {I18n.t('driver.Change_balance')}
        </p>
        {/* <p className="m0">{I18n.t('driver.Topup_note')}</p> */}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <a
          onClick={() => {
            handleClickShowCashWalletBalance();
          }}
          href="javascript:void(0)"
          className="text-active"
        >
          {I18n.t('driver.Change_balance')}
        </a>
        {/* <p className="m0">{I18n.t('driver.Topup_note')}</p> */}
      </React.Fragment>
    );
  };

  const renderCashBalance = () => {
    let balance = 0;
    let currencyObj = _.find(
      auth.selectedFleet.currencies,
      (i) => i.iso === selectedCurrency
    );
    if (selectedCurrency) {
      const cashWallet = _.get(detailItem, 'driverWallet.cashWallet', null);
      const currencyISO = _.get(cashWallet, 'currencyISO', '');
      const currentBalance = _.get(cashWallet, 'currentBalance', 0);
      const pendingBalance = _.get(cashWallet, 'pendingBalance', 0);
      balance = currencyISO ? currentBalance - pendingBalance : 0;
    }

    return (
      <div className={styles['valPayment']}>
        {currencyFormatter.format(balance.toFixed(2), {
          symbol: currencyObj?.symbol,
        })}
      </div>
    );
  };
  return (
    <>
      <div className={styles['balanceWallet']}>
        {detailItem._id &&
          commonData.relativeInfo.pricingSettings &&
          commonData.relativeInfo.pricingSettings.driverDeposit.enable && (
            <div className={styles['groupItems']}>
              <div className={styles['description']}>
                <Translate value="driver.CREDIT_DEPOSIT" />
              </div>
              {renderDriverDeposit()}
              {!permissions || permissions.changebalance ? (
                renderDepositButton()
              ) : (
                <p style={{ color: 'white' }} className="m0">
                  {I18n.t('driver.Change_balance')}
                </p>
              )}
            </div>
          )}
        {detailItem._id && auth.selectedFleet.driverCashWallet && (
          <div className={styles['groupItems']}>
            <div className={styles['description']}>
              <Translate value="driver.cash_wallet" />
            </div>
            {renderCashBalance()}
            {!permissions || permissions.changecashbalance ? (
              renderCashWalletButton()
            ) : (
              <p style={{ color: 'white' }} className="m0">
                {I18n.t('driver.Change_balance')}
              </p>
            )}
          </div>
        )}
      </div>
      <Balance
        isShow={state.isShowDriverDeposit}
        creditBalances={detailItem.driverInfo.creditBalances}
        currencies={[selectedCurrencyObj]}
        userId={detailItem._id}
        handleConfirmCloseClick={handleConfirmCloseClick}
        handleCreditBalanceSuccess={handleCreditBalanceSuccess}
        currency={selectedCurrencyObj}
      />
      <CashWalletBalance
        isShow={state.isShowCashWalletBalance}
        cashBalance={
          detailItem.driverWallet && detailItem.driverWallet.cashWallet
            ? detailItem.driverWallet.cashWallet
            : {}
        }
        currencies={[selectedCurrencyObj]}
        userId={detailItem._id}
        handleConfirmCloseClick={handleConfirmCloseClick}
        handleChangeCashBalanceSuccess={handleChangeCashBalanceSuccess}
        currency={selectedCurrencyObj}
      />

      {enableStripeConnect && (
        <>
          <Form.Label>
            <h3 style={{ fontSize: '20px' }} className="mb-sm">
              <Translate value="driver.stripeConnect" />
            </h3>
            <div className={styles['description']}>
              <Translate value="driver.stripeConnectDes" />{' '}
            </div>
          </Form.Label>
          <FormGroup className="mt-md">
            <Form.Label>
              <Translate value="companySetting.stripeConnectId" />{' '}
            </Form.Label>
            <FormControl
              type="text"
              className={'form-custom'}
              onChange={handleStripeAccountIdChange}
              value={state.stripeAccountId}
              disabled={!editable}
              placeholder={I18n.t('companySetting.stripeConnectId')}
            />
            <Form.Label>
              <p
                className="mt-sm"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor:
                      stripeConnectStatus === 'activated'
                        ? 'green'
                        : stripeConnectStatus === 'pending'
                        ? 'orange'
                        : 'red',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                ></span>
                {stripeConnectStatus === 'activated'
                  ? 'Active'
                  : stripeConnectStatus === 'pending'
                  ? 'In Review'
                  : 'Inactive'}
              </p>
            </Form.Label>
          </FormGroup>
        </>
      )}
      {auth.selectedFleet &&
      bankingInfoSetting.enable &&
      !enableStripeConnect ? (
        <BankAccount
          isSubmited={state.isSubmited}
          valid={state.valid}
          detailItem={detailItem}
          verificationDocument={state.verificationDocument}
          verificationDocumentBack={state.verificationDocumentBack}
          additionalDocument={state.additionalDocument}
          additionalDocumentBack={state.additionalDocumentBack}
          auth={auth}
          bankAccountEditable={
            drvPayout.enable ? !driverIcEditable : !bankAccountEditable
          }
          bankRequirement={state.bankRequirement}
          bankInfoList={state.bankInfoList}
          editable={!editable}
          reg={reg}
          FILE_ACCEPTED={FILE_ACCEPTED}
          isRequireStripe={isRequireStripe}
          isBankRequire={isBankRequire}
          handleNameOfBankChange={handleNameOfBankChange}
          validatorCallback={validatorCallback}
          handleNameOfAccountChange={handleNameOfAccountChange}
          handleRTBNumberChange={handleRTBNumberChange}
          handleAccountNumberChange={handleAccountNumberChange}
          handleIFSCCodeChange={handleIFSCCodeChange}
          handleCheckNumberChange={handleCheckNumberChange}
          handleSwiftCodeNumberChange={handleSwiftCodeNumberChange}
          handleSSNChange={handleSSNChange}
          handleSortCodeChange={handleSortCodeChange}
          handleVerificationDocumentChange={handleVerificationDocumentChange}
          handleVerificationDocumentBackChange={
            handleVerificationDocumentBackChange
          }
          handleAdditionalDocumentChange={handleAdditionalDocumentChange}
          handleAdditionalDocumentBackChange={
            handleAdditionalDocumentBackChange
          }
          onRemoveBankInfo={handleRemoveBankInfo}
          handleRemoveBankDocumentChange={handleRemoveBankDocumentChange}
          handeChangeIsBankAccountOwner={handeChangeIsBankAccountOwner}
          handleChangebeneficiary={handleChangebeneficiary}
        />
      ) : null}

      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default FormPayment;
