import React from 'react';
import {
  Modal,
  FormGroup,
  Container,
  Form,
  Row,
  Col,
  Image,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import * as settingActions from '../../../actions/settingActions';
import { reportCorporateApi } from '../../../constants/ApiConfigs';
import * as commonData from '../../../constants/commondata';

class ReportCorporate extends React.Component {
  constructor() {
    super();
    this.state = {
      corporates: [],
    };
  }

  showTravelerSignature = (link, data, e) => {
    const row = data.row || {};
    this.setState({
      showviewPhotoCapture: true,
      row,
      item: row.travelerSignature,
      title: 'travelerSignature',
    });
  };

  UNSAFE_componentWillMount() {
    if ((this.props.user || {}).corporateId) {
      this.setState({ corporates: false });
    } else {
      this.props.actions
        .fetchAllCorporateForSelection(this.props.selectedFleet.fleetId)
        .then((resp) => {
          const corporates = resp.res.map((doc) => ({
            value: doc._id,
            label: doc.companyInfo.name,
          }));
          corporates.unshift({
            value: 'all',
            label: I18n.t('report.query.common.all'),
          });
          this.setState({ corporates });
        });
    }
  }

  render() {
    const viewPhotoCapture = this.state.showviewPhotoCapture ? (
      <Modal
        show={this.state.showviewPhotoCapture}
        backdrop="static"
        dialogClassName="confirm-dialog logs-modal"
        bsSize="lg"
        onHide={() =>
          this.setState({
            showviewPhotoCapture: false,
            row: null,
            keyValue: '',
            title: '',
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.title
              ? I18n.t('report.result.corporate.' + this.state.title)
              : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <FormGroup className={'recipients'}>
              <Row>
                {this.state.item.length > 0 && (
                  <Col xs={12} md={12}>
                    <a
                      href={`${this.props.selectedFleet.s3Host}${this.state.item}`}
                      className="text-active"
                      target="_blank"
                    >
                      <Image
                        className="image-photo-capture"
                        src={`${this.props.selectedFleet.s3Host}${this.state.item}`}
                        rounded
                      />
                    </a>
                  </Col>
                )}
                {this.state.item.length === 0 && (
                  <div lassName="text-soft-warning">
                    <Translate value="report.result.bookingDetails.Image_not_found" />
                  </div>
                )}
              </Row>
            </FormGroup>
          </Container>
        </Modal.Body>
      </Modal>
    ) : null;
    return (
      <div className="content">
        {viewPhotoCapture}
        <BaseReport
          tittle="Corporate"
          fieldMappings={fieldMappings}
          apiurl={reportCorporateApi}
          dateRange
          finishedStatus
          corporate={this.state.corporates}
          bookingService={commonData.bookingServices.filter(
            (i) => i.value === 'localBooking' || i.value === 'homeFleet'
          )}
          currency
          actions={{
            showTravelerSignature: this.showTravelerSignature,
          }}
          company
          paymentMethod
          search="report.query.search.corporate"
          settings
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportCorporate);
