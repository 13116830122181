import {
    getReservationApi
} from '../constants/ApiConfigs';
import {
    callApi
} from "../utils/apiUtils";

export const SAVE_RESERVATION_TO_STORE = "SAVE_RESERVATION_TO_STORE";
export const ADD_BOOKING_TO_RESERVATION_IN_REALTIME = "ADD_BOOKING_TO_RESERVATION_IN_REALTIME";
export const REMOVE_BOOKING_FROM_RESERVATION_IN_REALTIME = "REMOVE_BOOKING_FROM_RESERVATION_IN_REALTIME";

function getReservation(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    }
    return callApi(
        getReservationApi,
        config,
        null,
        getReservationSuccess,
        null,
        true
    )
}

function getReservationSuccess(data) {
    return {
        type: SAVE_RESERVATION_TO_STORE,
        data: data.res.list,
    }
}

function addBookingToReservation(booking) {
    return {
        type: ADD_BOOKING_TO_RESERVATION_IN_REALTIME,
        data: booking,
    }
}

function removeBookingFromReservation(booking) {
    return {
        type: REMOVE_BOOKING_FROM_RESERVATION_IN_REALTIME,
        data: booking,
    }
}

export {
    getReservation,
    addBookingToReservation,
    removeBookingFromReservation
}