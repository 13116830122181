import { callApi, UrlBuilder } from '../utils/apiUtils';
import {
  getDynamicFareOperationZonesApi,
  getDynamicFareByZoneApi,
  getOpertaionDetailApi,
  createDynamicFareApi,
  updateDynamicFareApi,
  deleteDynamicFareApi,
  activeDynamicFareApi,
  deactiveDynamicFareApi
} from '../constants/ApiConfigs';

export const Types = {
  CREATE_DYNAMIC_FARE: 'CREATE_DYNAMIC_FARE',
  SUCCESS_CREATE_DYNAMIC_FARE: 'SUCCESS_CREATE_DYNAMIC_FARE',
  FAILED_CREATE_DYNAMIC_FARE: 'FAILED_CREATE_DYNAMIC_FARE',
  UPDATE_DYNAMIC_FARE: 'UPDATE_DYNAMIC_FARE',
  SUCCESS_UPDATE_DYNAMIC_FARE: 'SUCCESS_UPDATE_DYNAMIC_FARE',
  FAILED_UPDATE_DYNAMIC_FARE: 'FAILED_UPDATE_DYNAMIC_FARE',
  DELETE_DYNAMIC_FARE: 'DELETE_DYNAMIC_FARE',
  SUCCESS_DELETE_DYNAMIC_FARE: 'SUCCESS_DELETE_DYNAMIC_FARE',
  FAILED_DELETE_DYNAMIC_FARE: 'FAILED_DELETE_DYNAMIC_FARE',
  ACTIVE_DYNAMIC_FARE: 'ACTIVE_DYNAMIC_FARE',
  SUCCESS_ACTIVE_DYNAMIC_FARE: 'SUCCESS_ACTIVE_DYNAMIC_FARE',
  FAILED_ACTIVE_DYNAMIC_FARE: 'FAILED_ACTIVE_DYNAMIC_FARE',
  DEACTIVE_DYNAMIC_FARE: 'DEACTIVE_DYNAMIC_FARE',
  SUCCESS_DEACTIVE_DYNAMIC_FARE: 'SUCCESS_DEACTIVE_DYNAMIC_FARE',
  FAILED_DEACTIVE_DYNAMIC_FARE: 'FAILED_DEACTIVE_DYNAMIC_FARE'
};

export const getOperationZone = (options = {}) => {
  const config = {
    method: 'POST',
    body: JSON.stringify(options)
  };

  return callApi(getDynamicFareOperationZonesApi, config, null, null, null, true);
};

export const getFares = (options = {}) => {
  const config = { method: 'GET' };

  return callApi(
    UrlBuilder(getDynamicFareByZoneApi, options),
    config,
    null,
    null,
    null,
    true
  );
};

export const getZoneDetail = (options = {}) => {
  const config = { method: 'GET' };

  return callApi(
    UrlBuilder(getOpertaionDetailApi, options),
    config,
    null,
    null,
    null,
    true
  );
};

const requestCreateDynamicFare = () => ({
  type: Types.CREATE_DYNAMIC_FARE
});

const successCreateFare = () => ({
  type: Types.SUCCESS_CREATE_DYNAMIC_FARE
});

const failedCreateFare = () => ({
  type: Types.FAILED_CREATE_DYNAMIC_FARE
});

export const createDynamicFare = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    createDynamicFareApi,
    config,
    requestCreateDynamicFare(),
    successCreateFare,
    failedCreateFare,
    true
  );
};

const requestUpdateDynamicFare = () => ({
  type: Types.UPDATE_DYNAMIC_FARE
});

const successUpdateFare = () => ({
  type: Types.SUCCESS_UPDATE_DYNAMIC_FARE
});

const failedUpdateFare = () => ({
  type: Types.FAILED_UPDATE_DYNAMIC_FARE
});

export const updateDynamicFare = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    updateDynamicFareApi,
    config,
    requestUpdateDynamicFare(),
    successUpdateFare,
    failedUpdateFare,
    true
  );
};

const requestDeleteDynamicFare = () => ({
  type: Types.DELETE_DYNAMIC_FARE
});

const successDeleteFare = () => ({
  type: Types.SUCCESS_DELETE_DYNAMIC_FARE
});

const failedDeleteFare = () => ({
  type: Types.FAILED_DELETE_DYNAMIC_FARE
});

export const deleteDynamicFare = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    deleteDynamicFareApi,
    config,
    requestDeleteDynamicFare(),
    successDeleteFare,
    failedDeleteFare,
    true
  );
};

const requestActiveDynamicFare = () => ({
  type: Types.ACTIVE_DYNAMIC_FARE
});

const successActiveFare = () => ({
  type: Types.SUCCESS_ACTIVE_DYNAMIC_FARE
});

const failedActiveFare = () => ({
  type: Types.FAILED_ACTIVE_DYNAMIC_FARE
});

export const activeDynamicFare = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    activeDynamicFareApi,
    config,
    requestActiveDynamicFare(),
    successActiveFare,
    failedActiveFare,
    true
  );
};

const requestDeactiveDynamicFare = () => ({
  type: Types.DEACTIVE_DYNAMIC_FARE
});

const successDeactiveFare = () => ({
  type: Types.SUCCESS_DEACTIVE_DYNAMIC_FARE
});

const failedDeactiveFare = () => ({
  type: Types.FAILED_DEACTIVE_DYNAMIC_FARE
});

export const deactiveDynamicFare = (options = {}) => {
  const config = { method: 'POST', body: JSON.stringify(options) };

  return callApi(
    deactiveDynamicFareApi,
    config,
    requestDeactiveDynamicFare(),
    successDeactiveFare,
    failedDeactiveFare,
    true
  );
};
