import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import '../../settings.scss';
import './serviceFeesForm.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import {
  DefaultCurenciesNumberInputField,
  repeatType,
  additionalServiceType,
  SURCHARGE_DEFAULT,
  FEE_TYPE,
} from '../../../../constants/commondata';
import {
  ModalConfirmDeleteSurcharge,
  ModalAddServiceFeesSurcharge,
  ModalFleetService,
} from '../modals';
import TollFee from './FormItem/TollFee';
import OtherFee from './FormItem/OtherFee';
import TechFee from './FormItem/TechFee';
import FleetService from './FleetserviceForm';
import FormFee from './components/formFee';

const surchargeType = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
};

const surchargeInitial = { ...SURCHARGE_DEFAULT };

class ServiceFeesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceFees: props.serviceFees,
      showDialog: false,
      dialogData: {},
      showConfirmDelete: false,
      valid: {},
      isSubmited: false,
      rushHourIndex: -1,
      surchargeByCurrenciesInitial: [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.serviceFees &&
      nextProps.serviceFees.zoneId !== this.props.serviceFees.zoneId
    ) {
      this.state = {
        serviceFees: nextProps.serviceFees,
        showDialog: false,
        dialogData: {},
        showConfirmDelete: false,
        valid: {},
        isSubmited: false,
        isRushHourSubmitted: false,
        rushHourIndex: -1,
      };
    }
  }

  handleUpdateFormData = (key, value) => {
    const { serviceFees } = this.state;
    _.set(serviceFees, key, value);
    this.setState({ serviceFees });
    this.props.onChangeFormServiceFees();
  };

  handleRushHourCheckChange = (e) => {
    const { serviceFees } = this.state;
    serviceFees.rushHourActive = e.target.checked;
    this.setState({ serviceFees });
    this.props.onChangeFormServiceFees();
  };

  handleHeavyTrafficCheckChange = (e) => {
    const { serviceFees } = this.state;
    serviceFees.heavyTrafficActive = e.target.checked;
    this.setState({ serviceFees });
    this.props.onChangeFormServiceFees();
  };

  handleTaxCheckChange = (e) => {
    const { serviceFees } = this.state;
    serviceFees.taxActive = e.target.checked;
    if (!e.target.checked) {
      serviceFees.tax = '0';
    }
    this.setState({ serviceFees, valid: {} });
    this.props.onChangeFormServiceFees();
  };

  handleTaxSurchargeChange = (e) => {
    const { serviceFees } = this.state;
    serviceFees.tax = e.target.value;
    this.setState({ serviceFees });
    this.props.onChangeFormServiceFees();
  };

  handlePaytoChange = (e) => {
    const value = parseInt(e.target.value || 0);
    const { serviceFees } = this.state;
    serviceFees.tollFeePayTo = value;
    this.setState({ serviceFees });
    this.props.onChangeFormServiceFees();
  };

  rushHourRemoveItemClick = (item) => {
    this.options = {
      rushHourId: item.rushHourId,
      zoneId: this.state.serviceFees.zoneId,
      fleetId: this.props.auth.selectedFleet.fleetId,
    };
    this.itemConfirm = 'surcharge';
    this.setState({
      showConfirmDelete: true,
      itemConfirm: 'surcharge',
    });
  };

  addDistanceDefault = (surcharge = [], distanceDiversity = true) => {
    if (!distanceDiversity) {
      return [
        {
          ...surcharge[0],
          distanceRange: SURCHARGE_DEFAULT.distanceRange,
        },
      ];
    }
    const distanceRange = (surcharge[0] && surcharge[0].distanceRange) || [];
    return [
      {
        ...surcharge[0],
        distanceRange:
          distanceRange.length > 0
            ? distanceRange
            : SURCHARGE_DEFAULT.distanceRange,
      },
    ];
  };

  rushHourEditItemClick = (item, index) => {
    const surchargeSelected = this.state.serviceFees.rushHours[index];
    const dialogData = {
      name: surchargeSelected.name,
      date: {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      },
      From: surchargeSelected.start.split(':'),
      To: surchargeSelected.end.split(':'),
      surcharge: surchargeSelected.surcharge,
      timeCal: surchargeSelected.timeCal,
      surchargeByCurrencies: surchargeSelected.surchargeByCurrencies,
      surchargeNow: this.addDistanceDefault(
        surchargeSelected.surchargeNow,
        surchargeSelected.distanceDiversity
      ),
      surchargeReservation: this.addDistanceDefault(
        surchargeSelected.surchargeReservation,
        surchargeSelected.distanceDiversity
      ),
      surchargeType: surchargeSelected.surchargeType
        ? surchargeSelected.surchargeType
        : surchargeType.AMOUNT,
      percentSurcharge:
        surchargeSelected.surchargeType === surchargeType.PERCENT
          ? surchargeSelected.surchargeByCurrencies
          : [{ currencyISO: '', value: 0 }],
      amountSurcharge:
        surchargeSelected.surchargeType === surchargeType.AMOUNT
          ? surchargeSelected.surchargeByCurrencies
          : this.props.auth.selectedFleet.currencies.map((data) => ({
              value: 0,
              currencyISO: data.iso,
            })),
      repeatType: surchargeSelected.repeat,
      distanceDiversity: surchargeSelected.distanceDiversity,
    };
    if (surchargeSelected.repeat === repeatType.DayOfWeek) {
      surchargeSelected.date.map((d) => {
        dialogData.date[d] = true;
      });
    } else {
      dialogData.dateSurcharge = moment(surchargeSelected.startDate).format(
        'MM/DD/YYYY'
      );
    }
    this.setState({
      showDialog: true,
      dialogData,
      rushHourIndex: index,
      surchargeByCurrenciesInitial: surchargeSelected.surchargeByCurrencies,
      surchargeNowInitial: surchargeSelected.surchargeNow,
      surchargeReservationInitial: surchargeSelected.surchargeReservation,
    });
  };

  rushHourAddItemClick = () => {
    const dialogData = {
      name: '',
      date: {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      },
      From: ['00', '00'],
      To: ['23', '59'],
      surcharge: 0,
      timeCal: 0,
      surchargeByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => ({
          value: 0,
          currencyISO: data.iso,
          distanceRange: [
            {
              from: 0,
              to: Number.MAX_SAFE_INTEGER,
              surchargeValue: 0,
            },
          ],
        })
      ),
      surchargeNow: this.props.auth.selectedFleet.currencies.map((data) => ({
        ...surchargeInitial,
        currencyISO: data.iso,
      })),
      surchargeReservation: this.props.auth.selectedFleet.currencies.map(
        (data) => ({
          ...surchargeInitial,
          currencyISO: data.iso,
        })
      ),
      surchargeType: surchargeType.AMOUNT,
      percentSurcharge: [
        {
          currencyISO: '',
          value: 0,
          distanceRange: [
            {
              from: 0,
              to: Number.MAX_SAFE_INTEGER,
              surchargeValue: 0,
            },
          ],
        },
      ],
      amountSurcharge: this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
        distanceRange: [
          {
            from: 0,
            to: Number.MAX_SAFE_INTEGER,
            surchargeValue: 0,
          },
        ],
      })),
      repeatType: repeatType.DayOfWeek,
      distanceDiversity: false,
    };
    this.setState({
      showDialog: true,
      dialogData,
      rushHourIndex: -1,
      surchargeByCurrenciesInitial: [],
    });
  };

  rushHourAddItemSaveClick = (rushHour) => {
    const { serviceFees, rushHourIndex } = this.state;
    rushHour.zoneId = serviceFees.zoneId;
    if (rushHourIndex === -1) {
      this.props.settingActions
        .createSurchargeServiceFees(rushHour)
        .then((data) => {
          if (data.ok && data.res) {
            serviceFees.rushHours = data.res;
            this.setState({
              showDialog: false,
              serviceFees,
              dialogData: {},
            });
            this.context.notification(
              'success',
              I18n.t('generalSetting.Create_surcharge_success')
            );
          } else if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    } else {
      rushHour.rushHourId = serviceFees.rushHours[rushHourIndex].rushHourId;
      this.props.settingActions
        .updateSurchargeServiceFees(rushHour)
        .then((data) => {
          if (data.ok && data.res) {
            serviceFees.rushHours = data.res;
            this.setState({
              showDialog: false,
              serviceFees,
              dialogData: {},
            });
            this.context.notification(
              'success',
              I18n.t('generalSetting.Update_surcharge_success')
            );
          } else if (data.error) {
            this.context.notification(
              'error',
              I18n.t(`errors.${data.error.errorCode}`)
            );
          }
        });
    }
  };

  handleAlertNotification = (params) => {
    if (Array.isArray(params)) {
      params.forEach((e) => {
        if (e.distance.from > e.distance.to) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_distance_range_valid', {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        } else if (!e.distance.to) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_distance_to')
          );
        } else if (!e.distance.surchargeValue) {
          this.context.notification(
            'error',
            I18n.t('generalSetting.please_enter_surchargeValue', {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        }

        if (e.errorMsg) {
          this.context.notification(
            'error',
            I18n.t(`generalSetting.${e.errorMsg}`, {
              name: `from ${e.distance.from} - to ${
                e.distance.to === Number.MAX_SAFE_INTEGER
                  ? Infinity
                  : e.distance.to
              }`,
            })
          );
        }
      });
    }
  };

  closeDeleteServiceDialog = () => {
    this.setState({ showConfirmDelete: false });
  };

  confirmDelete = () => {
    const { serviceFees } = this.state;
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.itemConfirm === 'fleetService') {
      this.props.settingActions
        .deleteFleetService(this.options)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.res && data.res.fleetServices) {
            this.closeDeleteServiceDialog();
            this.setState({
              serviceFees: {
                ...this.state.serviceFees,
                fleetServices: data.res.fleetServices,
              },
            });
            this.context.notification(
              'success',
              'The fleet service has been deleted successfully'
            );
          } else if (data.error) {
            this.context.notification(
              'error',
              'Failed to create fleet service'
            );
          }
        });
    } else {
      this.props.settingActions
        .deleteSurchargeServiceFees(this.options)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.ok && data.res) {
            this.closeDeleteServiceDialog();
            serviceFees.rushHours = data.res;
            this.setState({ serviceFees });
            this.context.notification(
              'success',
              I18n.t('generalSetting.Delete_surcharge_success')
            );
          } else if (data.error) {
            this.context.notification(
              'error',
              I18n.t('generalSetting.Delete_surcharge_fail')
            );
          }
        });
    }
  };

  rushHourAddItemCloseClick = () => {
    this.setState({
      showDialog: false,
      dialogData: {},
      surchargeByCurrenciesInitial: [],
    });
  };

  handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
    const cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    switch (group) {
      case 'surchargeByCurrencies': {
        const numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
        const numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
        if (
          numberWithDotReg.test(e.target.value) ||
          numberWithCommaReg.test(e.target.value) ||
          Validation.isStringEmpty(e.target.value)
        ) {
          this.state.dialogData.surchargeByCurrencies =
            this.state.dialogData.surchargeByCurrencies ||
            this.props.auth.selectedFleet.currencies.map((data) => ({
              value: 0,
              currencyISO: data.iso,
            }));
          this.state.dialogData.surchargeByCurrencies =
            this.state.dialogData.surchargeByCurrencies.map((c) => {
              if (c.currencyISO == currency) {
                isAdd = false;
                return { ...c, value: e.target.value };
              }
              return c;
            });
          if (isAdd) {
            this.state.dialogData.surchargeByCurrencies.push(cur);
          }
          this.setState({ dialogData: this.state.dialogData });
          this.props.onChangeFormServiceFees();
        }
        break;
      }
      case 'heavyTrafficByCurrencies': {
        this.state.serviceFees.heavyTrafficByCurrencies =
          this.state.serviceFees.heavyTrafficByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          }));
        this.state.serviceFees.heavyTrafficByCurrencies =
          this.state.serviceFees.heavyTrafficByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.serviceFees.heavyTrafficByCurrencies.push(cur);
        }
        this.setState({ serviceFees: this.state.serviceFees });
        this.props.onChangeFormServiceFees();
        break;
      }
      case 'otherFee': {
        this.state.serviceFees.otherFee.valueByCurrencies =
          this.state.serviceFees.otherFee.valueByCurrencies ||
          this.props.auth.selectedFleet.currencies.map((data) => ({
            value: 0,
            currencyISO: data.iso,
          }));
        this.state.serviceFees.otherFee.valueByCurrencies =
          this.state.serviceFees.otherFee.valueByCurrencies.map((c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          this.state.serviceFees.otherFee.valueByCurrencies.push(cur);
        }
        this.setState({ serviceFees: this.state.serviceFees });
        this.props.onChangeFormServiceFees();
        break;
      }
      default:
        break;
    }
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleSaveClick = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    const { serviceFees } = this.state;
    const currency = _.get(this.props, 'zone.currency.iso', '');
    const { selectedFleet } = this.props.auth;
    const defaultCurrencyFleet = selectedFleet.currencies.map((data) => ({
      value: 0,
      currencyISO: data.iso,
    }));
    let { techFeeActive, techFeeByCurrencies = [] } = serviceFees;

    if (techFeeActive && techFeeByCurrencies.length === 0) {
      let techFeeByCurrenciesInitial = [
        {
          currencyISO: currency,
          commandCenter: 0,
          paxApp: 0,
          webBooking: 0,
          mDispatcher: 0,
          partner: 0,
          carHailing: 0,
          kiosk: 0,
          api: 0,
        },
      ];
      serviceFees.techFeeByCurrencies = techFeeByCurrenciesInitial;
    }
    let customeFleetServices = _.get(serviceFees, 'fleetServices', []);
    if (customeFleetServices.length > 0) {
      customeFleetServices = customeFleetServices.map((service) => {
        let newService = { ...service };
        if (
          service.vehicleType &&
          service.vehicleType.length > 0 &&
          _.every(service.vehicleType, _.isObject)
        ) {
          let vehicleTypeId = service.vehicleType.map((vhc) => vhc._id);
          newService.vehicleType = vehicleTypeId;
        }
        return newService;
      });
      serviceFees.fleetServices = customeFleetServices;
    }
    const updateData = _.extend({}, serviceFees, {
      otherFee: {
        value: serviceFees.otherFee.value,
        isEdit: serviceFees.otherFee.isEdit,
        addNote: serviceFees.otherFee.addNote,
        limitDriverInputActive: serviceFees.otherFee.limitDriverInputActive,
        valueByCurrencies:
          serviceFees.otherFee.valueByCurrencies || defaultCurrencyFleet,
        otherFeeDriverCanInput:
          serviceFees.otherFee.otherFeeDriverCanInput || defaultCurrencyFleet,
      },
      heavyTrafficByCurrencies:
        serviceFees.heavyTrafficByCurrencies || defaultCurrencyFleet,
    });
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions
      .updateServiceFeesByZone(updateData)
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        this.props.onChangeFormServiceFees(true);
        this.setState({ isSubmited: false });

        if (data.ok) {
          this.context.notification(
            'success',
            I18n.t('generalSetting.Update_general_success')
          );
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t(`errors.${data.error.errorCode}`)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('generalSetting.Update_general_fail')
          );
        }
      });
  };

  onFleetServiceChange = (e) => {
    this.setState({
      serviceFees: {
        ...this.state.serviceFees,
        fleetServiceActive: e.target.checked,
      },
    });
  };

  fleetServiceRemoveItemClick = (item, index) => {
    this.options = {
      fleetServiceId: item.serviceId,
      fleetId: this.props.auth.selectedFleet.fleetId,
      zoneId: this.state.serviceFees.zoneId,
      isDifferentZone: true,
    };
    this.itemConfirm = 'fleetService';
    this.setState({
      showConfirmDelete: true,
      itemConfirm: 'fleetService',
    });
  };

  fleetServiceEditItemClick = (item, index) => {
    this.setState({
      dialogData: item,
      showFleetServiceDialog: true,
    });
  };

  fleetServiceAddItemCloseClick = () => {
    this.state.dialogData.service = {};
    this.setState({
      showFleetServiceDialog: false,
      dialogData: this.state.dialogData,
      isFleetServiceSubmitted: false,
    });
  };

  fleetServiceAddItemSaveClick = (service) => {
    this.props.loadingBarActions.showLoadingSpiner();
    if (service && service._id) {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        fleetServiceId: service._id,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        applyTax: service.applyTax || false,
        serviceFeeType: service.serviceFeeType,
        vehicleType: service.vehicleType.map((item) => item.value),
        fleetCommission: service.fleetCommission,
        bookingType: service.bookingType || 'all',
        isActive: true,
        isDifferentZone: true,
        zoneId: this.state.serviceFees.zoneId,
      };
      if (service.serviceFeeType === FEE_TYPE.Percent) {
        serviceData.serviceFeePercent = service.serviceFeePercent || 0;
      } else {
        serviceData.serviceFeeByCurrencies =
          service.serviceFeeByCurrencies || [];
      }
      this.props.settingActions.updateFleetService(serviceData).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res) {
          this.setState({
            serviceFees: {
              ...this.state.serviceFees,
              fleetServices: data.res,
            },
            isFleetServiceSubmitted: false,
          });
          this.context.notification(
            'success',
            'The fleet service has been updated successfully'
          );
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification('error', 'Failed to updated service');
          }
        }
      });
    } else {
      const serviceData = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        serviceName: service.serviceName,
        serviceType: service.serviceType,
        serviceFeeType: service.serviceFeeType,
        vehicleType: service.vehicleType.map((item) => item.value),
        fleetCommission: service.fleetCommission,
        applyTax: service.applyTax || false,
        bookingType: service.bookingType || 'all',
        isActive: true,
        isDifferentZone: true,
        zoneId: this.state.serviceFees.zoneId,
      };
      if (service.serviceFeeType === FEE_TYPE.Percent) {
        serviceData.serviceFeePercent = service.serviceFeePercent || 0;
      } else {
        serviceData.serviceFeeByCurrencies =
          service.serviceFeeByCurrencies || [];
      }
      this.props.settingActions.addFleetService(serviceData).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.res && data.res.fleetServices) {
          // this.state.formData.services.unshift(service);
          this.setState({
            serviceFees: {
              ...this.state.serviceFees,
              fleetServices: data.res.fleetServices,
            },
            isFleetServiceSubmitted: false,
          });
          this.context.notification(
            'success',
            // I18n.t('generalSetting.Adding_fleet_service_success')
            'New fleet service has been created successfully'
          );
        } else if (data.error) {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              // I18n.t('generalSetting.Adding_fleet_service_fail')
              I18n.t('Failed to add fleet service ')
            );
          }
        }
      });
    }
    service = {};
    this.setState({
      showFleetServiceDialog: false,
      dialogData: this.state.dialogData,
    });
  };

  fleetServiceAddItemClick = () => {
    const dialogData = {
      serviceName: '',
      serviceType: additionalServiceType.Optional,
      serviceFeeByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        }
      ),
      serviceFeeType: FEE_TYPE.Amount,
      serviceFeePercent: 0,
      vehicleType: [],
      isActive: true,
      fleetCommission: {
        commissionType: 'percent',
        commissionByCurrencies: [
          {
            value: 0,
            currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
          },
        ],
      },
    };
    this.setState({
      showFleetServiceDialog: true,
      dialogData,
    });
  };

  render() {
    const disabled = this.props.disabled;
    const { zone, enableFleetService } = this.props;
    const { dialogData, surchargeByCurrenciesInitial } = this.state;
    const differentSurchargeForDifferentService = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.differentSurchargeForDifferentService',
      false
    );
    return this.state.serviceFees ? (
      <div className="content custom-form overflow-auto general-setting serviceFeesForm">
        {this.state.showConfirmDelete && !disabled ? (
          <ModalConfirmDeleteSurcharge
            closeDeleteServiceDialog={this.closeDeleteServiceDialog}
            confirmDelete={this.confirmDelete}
            itemConfirm={this.state.itemConfirm}
          />
        ) : null}
        {this.state.showDialog ? (
          <ModalAddServiceFeesSurcharge
            auth={this.props.auth}
            zone={zone}
            rushHourAddItemSaveClick={this.rushHourAddItemSaveClick}
            rushHourAddItemCloseClick={this.rushHourAddItemCloseClick}
            dialogData={dialogData}
            surchargeByCurrenciesInitial={surchargeByCurrenciesInitial}
            handleAlertNotification={this.handleAlertNotification}
          />
        ) : (
          ''
        )}

        <div className="serviceFeesForm__header">
          {this.props.zone.zoneName}
        </div>
        <div className="serviceFeesForm__body">
          <div className="group-general">
            <CcCheckbox
              disabled={disabled}
              checked={
                this.state.serviceFees
                  ? this.state.serviceFees.rushHourActive
                  : false
              }
              onChange={this.handleRushHourCheckChange}
              text={I18n.t('generalSetting.RUSH_HOUR')}
              labelClassName="title"
              className="form-group-title no-marginBottom"
            />
            {this.state.serviceFees && this.state.serviceFees.rushHourActive ? (
              <Row className="sub-controls">
                <Col md={12}>
                  <div
                    className="additional-service-table-container"
                    id="table-surchage-wrapper"
                  >
                    <div id="table-surchage-scroll">
                      <table className="table table-no-pagination">
                        <tbody>
                          {this.state.serviceFees.rushHours.map((rh, index) => (
                            <tr key={index}>
                              <td>
                                <p>{rh.name}</p>
                              </td>
                              <td>
                                <p>
                                  {rh.repeat === repeatType.DayOfWeek && (
                                    <Translate value="generalSetting.DayOfWeek" />
                                  )}
                                  {rh.repeat === repeatType.SingleDay && (
                                    <Translate value="generalSetting.SingleDay" />
                                  )}
                                  {rh.repeat === repeatType.Yearly && (
                                    <Translate value="generalSetting.Yearly" />
                                  )}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {rh.start} -{rh.end}{' '}
                                </p>
                                <p>
                                  {rh.repeat === repeatType.DayOfWeek
                                    ? rh.date
                                        .map((d) =>
                                          I18n.t(`generalSetting.${d}`)
                                        )
                                        .join(', ')
                                    : moment(rh.startDate).format('MM/DD/YYYY')}
                                </p>
                              </td>
                              <td>
                                <p>{rh.timeCal} mins</p>
                                {rh.distanceDiversity ? (
                                  I18n.t('generalSetting.distance_diversity')
                                ) : rh.surchargeType ===
                                  surchargeType.AMOUNT ? (
                                  <p>
                                    {rh.surchargeByCurrencies &&
                                    !differentSurchargeForDifferentService
                                      ? rh.surchargeByCurrencies
                                          .filter(
                                            (d) =>
                                              d.currencyISO == zone.currency.iso
                                          )
                                          .map(
                                            (d) => `${d.currencyISO} ${d.value}`
                                          )
                                          .join(', ')
                                      : ''}
                                  </p>
                                ) : (
                                  <p>
                                    {rh.surchargeByCurrencies[0] &&
                                    !differentSurchargeForDifferentService
                                      ? `${rh.surchargeByCurrencies[0].value} %`
                                      : ''}
                                  </p>
                                )}
                              </td>
                              <td className="actions">
                                {!this.props.permissions ||
                                this.props.permissions.actions ? (
                                  <div>
                                    <a
                                      onClick={() => {
                                        this.rushHourRemoveItemClick(rh, index);
                                      }}
                                    >
                                      <i className="fa fa-trash fa-2x" />
                                    </a>
                                    <a
                                      onClick={() => {
                                        this.rushHourEditItemClick(rh, index);
                                      }}
                                    >
                                      <i className="fa fa-edit fa-2x" />
                                    </a>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!disabled && (
                    <ButtonToolbar className="text-center mv-md">
                      <Button
                        className="btn-save"
                        onClick={this.rushHourAddItemClick}
                      >
                        <Translate value="generalSetting.Add" />
                      </Button>
                    </ButtonToolbar>
                  )}
                </Col>
              </Row>
            ) : (
              ''
            )}
          </div>
          <div className="group-general">
            <CcCheckbox
              checked={
                this.state.serviceFees
                  ? this.state.serviceFees.heavyTrafficActive
                  : false
              }
              onChange={this.handleHeavyTrafficCheckChange}
              text={I18n.t('generalSetting.HEAVY_TRAFFIC')}
              labelClassName="title"
              className="form-group-title"
              disabled={disabled}
            />
            {this.state.serviceFees &&
            this.state.serviceFees.heavyTrafficActive ? (
              <FormGroup
                className={`sub-controls ${
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.heavyTrafficByCurrencies === false
                    ? 'error'
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="generalSetting.Heavy_traffic_surcharge" />
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup
                    className={`single-addon-right ${
                      disabled ? 'disabled' : ''
                    }`}
                  >
                    <FormControl
                      type="text"
                      className="form-custom"
                      placeholder={I18n.t(
                        'generalSetting.Heavy_traffic_surcharge'
                      )}
                      onChange={(e) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          'heavyTrafficByCurrencies',
                          zone.currency.iso,
                          e
                        );
                      }}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(e, (e) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            'heavyTrafficByCurrencies',
                            zone.currency.iso,
                            e
                          );
                        });
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(e, (e) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            'heavyTrafficByCurrencies',
                            zone.currency.iso,
                            e
                          );
                        });
                      }}
                      value={
                        this.state.serviceFees.heavyTrafficByCurrencies &&
                        this.state.serviceFees.heavyTrafficByCurrencies.length >
                          0
                          ? this.state.serviceFees.heavyTrafficByCurrencies.filter(
                              (d) => d.currencyISO == zone.currency.iso
                            )[0]
                            ? this.state.serviceFees.heavyTrafficByCurrencies.filter(
                                (d) => d.currencyISO == zone.currency.iso
                              )[0].value
                            : DefaultCurenciesNumberInputField
                          : DefaultCurenciesNumberInputField
                      }
                      disabled={disabled}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>{zone.currency.symbol}</InputGroup.Text>
                    </InputGroup.Append>{' '}
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="heavyTrafficByCurrencies"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    valid={
                      this.state.serviceFees.heavyTrafficByCurrencies.filter(
                        (d) =>
                          !Validation.isStringEmpty(d.value) && isNaN(d.value)
                      ).length == 0
                    }
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!this.state.isSubmited}
                  />
                  <ValidCase
                    valid={
                      this.state.serviceFees.heavyTrafficByCurrencies.filter(
                        (d) => !Validation.isGreaterOrEqual(d.value, 0)
                      ).length == 0
                    }
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(0)}
                    hide={!this.state.isSubmited}
                  />
                </Validator>
              </FormGroup>
            ) : (
              ''
            )}
            <TollFee
              formData={this.state.serviceFees}
              editable={disabled}
              handleUpdateFormData={this.handleUpdateFormData}
              auth={this.props.auth}
              currency={zone.currency}
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
            />
            <FormFee
              keyFee={'parkingFee'}
              formData={this.state.serviceFees}
              editable={disabled}
              handleUpdateFormData={this.handleUpdateFormData}
              auth={this.props.auth}
              currency={zone.currency}
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
            />
            <FormFee
              keyFee={'gasFee'}
              formData={this.state.serviceFees}
              editable={disabled}
              handleUpdateFormData={this.handleUpdateFormData}
              auth={this.props.auth}
              currency={zone.currency}
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
            />
            <CcCheckbox
              disabled={disabled}
              checked={
                this.state.serviceFees
                  ? this.state.serviceFees.taxActive
                  : false
              }
              onChange={this.handleTaxCheckChange}
              text={I18n.t('generalSetting.TAX')}
              labelClassName="title"
              className="form-group-title"
            />
            {this.state.serviceFees && this.state.serviceFees.taxActive ? (
              <FormGroup
                className={`sub-controls ${
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.tax === false
                    ? 'error'
                    : null
                }`}
              >
                <Form.Label>
                  <Translate value="generalSetting.Tax_surcharge" />
                  <span className="require">*</span>
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup
                    className={`single-addon-right ${
                      disabled ? 'disabled' : ''
                    }`}
                  >
                    <FormControl
                      type="text"
                      className="form-custom"
                      onChange={this.handleTaxSurchargeChange}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(
                          e,
                          this.handleTaxSurchargeChange
                        );
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(
                          e,
                          this.handleTaxSurchargeChange
                        );
                      }}
                      value={
                        this.state.serviceFees ? this.state.serviceFees.tax : ''
                      }
                      placeholder={I18n.t('generalSetting.Tax_surcharge')}
                      disabled={disabled}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>{' '}
                  </InputGroup>
                </FormGroup>
                <Validator id="tax" callback={this.ValidatorCallback}>
                  <ValidCase
                    valid={
                      !Validation.isStringEmpty(this.state.serviceFees.tax)
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                    hide={!this.state.isSubmited}
                  />
                  <ValidCase
                    valid={Validation.isNumber(this.state.serviceFees.tax)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!this.state.isSubmited}
                  />
                  <ValidCase
                    valid={Validation.isGreaterOrEqual(
                      this.state.serviceFees.tax,
                      0
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(0)}
                    hide={!this.state.isSubmited}
                  />
                </Validator>
              </FormGroup>
            ) : (
              ''
            )}
            <OtherFee
              formData={this.state.serviceFees}
              editable={disabled}
              handleUpdateFormData={this.handleUpdateFormData}
              auth={this.props.auth}
              currency={zone.currency}
              isSubmited={this.state.isSubmited}
              valid={this.state.valid}
              ValidatorCallback={this.ValidatorCallback}
            />
            {this.props.fleetType === 'Limo' && (
              <TechFee
                formData={this.state.serviceFees}
                editable={disabled}
                handleUpdateFormData={this.handleUpdateFormData}
                auth={this.props.auth}
                currency={zone.currency}
                isSubmited={this.state.isSubmited}
                valid={this.state.valid}
                ValidatorCallback={this.ValidatorCallback}
              />
            )}
            {this.state.showFleetServiceDialog ? (
              <ModalFleetService
                fleetServiceAddItemCloseClick={
                  this.fleetServiceAddItemCloseClick
                }
                fleetServiceAddItemSaveClick={this.fleetServiceAddItemSaveClick}
                service={this.state.dialogData}
                auth={this.props.auth}
                permissions={this.props.permissions}
                editable={disabled}
                settingActions={this.props.settingActions}
              />
            ) : null}
            <FleetService
              editable={disabled}
              enableFleetService={enableFleetService}
              formData={this.state.serviceFees}
              permissions={this.props.permissions}
              onFleetServiceChange={this.onFleetServiceChange}
              fleetServiceRemoveItemClick={this.fleetServiceRemoveItemClick}
              fleetServiceEditItemClick={this.fleetServiceEditItemClick}
              fleetServiceAddItemClick={this.fleetServiceAddItemClick}
            />
          </div>
          <ButtonToolbar className="text-center group-general">
            {!this.props.permissions || this.props.permissions.actions ? (
              <Button className="btn-save" onClick={this.handleSaveClick}>
                <Translate value="generalSetting.Save" />
              </Button>
            ) : (
              ''
            )}
          </ButtonToolbar>
        </div>
      </div>
    ) : null;
  }
}
ServiceFeesForm.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceFeesForm);
