import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../settings.scss";
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  DropdownButton,
  
  Modal
} from "react-bootstrap";
import _, { truncate } from "lodash";

import * as settingActions from "../../../actions/settingActions";
import * as loadingBarActions from "../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase } from "../../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../../utils/commonFunctions";
import TableHeader from "../../../components/table/TableHeader";
import { DriverNoteColumns } from "./tableHeaderData";
import TableActions from "../../../components/table/tableAction/TableActions";
import StickyTable from "../../../components/table/stickyTable/StickyTable";
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { tr } from "react-dom-factories";

class DriverNote extends Component {
  constructor() {
    super();
    this.state = {
      driverNotes: null,
      activePage: 1,
      numItemsPerPage: 20,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      editable: false,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0
      }
    };
    this.handlePaginationSelect = this.handlePaginationSelect.bind(this);
    this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(
      this
    );
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.updateDriverNoteList = this.updateDriverNoteList.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.closeDialogForm = this.closeDialogForm.bind(this);
    this.saveDialogForm = this.saveDialogForm.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.confirmDeleteCity = this.confirmDeleteCity.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
  }
  componentDidMount() {
    this.updateDriverNoteList();
  }

  updateDriverNoteList(activePage, limit) {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId
    };

    this.props.settingActions.findDriverNotes(params).then(data => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({ driverNotes: data.res, footerData: { page, total, limit } });
      }
    });
  }

  saveDialogForm() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    if (!this.state.dialogData.name.trim()) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.dialogData._id) {
      let objectDriverNote = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: this.state.dialogData.name,
        id: this.state.dialogData._id
      };
      this.props.settingActions.updateDriverNote(objectDriverNote).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("driverNote.Update_fail"));
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("driverNote.Update_success")
          );
          this.closeDialogForm();
          this.updateDriverNoteList();
          this.setState({ isSubmited: false });
        }
      });
    } else {
      this.props.settingActions.createDriverNote(this.state.dialogData).then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (!data.ok) {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("driverNote.Create_fail"));
          }
        } else {
          this.context.notification(
            "success",
            I18n.t("driverNote.Create_success")
          );
          this.closeDialogForm();
          this.updateDriverNoteList();
          this.setState({ isSubmited: false });
        }
      });
    }
  }
  handleNameChange(e) {
    this.state.dialogData.name = e.target.value;
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  }

  handleChangeService = (e, key) => {
    const { dialogData } = this.state;

    switch (key) {

      default:
        this.setState({
          dialogData: {
            ...dialogData
          }
        });
        break;
    };
  }

  handleAddButtonClick() {
    let objectComany = {
      name: "",
      transportation: true,
      assistant: false,
      isShowDriverSignUp: true,
    };
    this.setState({
      showDialog: true,
      dialogData: objectComany,
      editable: true
    });
  }
  closeDialogForm() {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false
    });
  }
  handleMenuClick(key, city) {
    switch (key) {
      case "Edit": {
        this.props.settingActions
          .detailsDriverNote({
            id: city._id,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: true
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("driverNote.Load_fail")
                );
              }
            }
          });
        break;
      }
      case "View": {
        this.props.settingActions
          .detailsDriverNote({
            id: city._id,
            fleetId: this.props.auth.selectedFleet.fleetId
          })
          .then(data => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: false
              });
            } else {
              if (data.error) {
                this.context.notification(
                  "error",
                  I18n.t("errors." + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  "error",
                  I18n.t("driverNote.Load_fail")
                );
              }
            }
          });
        break;
      }
      case "changeStatus": {
        this.setState({ editable: true });
        break;
      }
      case "Delete": {
        this.setState({ showConfirm: true, dialogData: city });
        break;
      }
    }
  }

  handlePaginationSelect(eventKey) {
    this.updateDriverNoteList(eventKey);
  }

  handleNumItemsPerPageChange(e) {
    this.updateDriverNoteList(0, parseInt(e));
  }

  confirmDeleteCity() {
    this.props.settingActions
      .deleteDriverNote({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.dialogData._id
      })
      .then(data => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDriverNoteList();
          this.context.notification(
            "success",
            I18n.t("driverNote.Delete_success")
          );
        } else {
          if (data.error) {
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          } else {
            this.context.notification("error", I18n.t("driverNote.Delete_fail"));
          }
        }
      });
  }
  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  getTableColumns = () => {
    let tableColumns = Object.assign([], DriverNoteColumns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "Name":
          col.customCell = city => {
            return !city.editable ? (
              <a
                className="clickable"
                onClick={() => {
                  this.handleMenuClick("View", city);
                }}
              >
                {city.name}
              </a>
            ) : (
                <a className="disabled clickable">{city.name}</a>
              );
          };
          break;

        case "actions":
          col.customCell = (city, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [];
              if (!city.editable) {
                actionItems.push({
                  label: "city.Edit",
                  eventKey: "Edit"
                });
              }
              if (!city.editable) {
                actionItems.push({
                  label: "companySetting.Delete",
                  eventKey: "Delete"
                });
              }
              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={city}
                  onSelect={eventKey => {
                    this.handleMenuClick(eventKey, city);
                  }}
                  totalRow={this.state.driverNotes.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={e => {
                    this.handleMenuClick("View", city);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="city.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const { isSubmited, valid } = this.state;
    const bodyData =
      this.state.driverNotes && this.state.driverNotes.list.length > 0
        ? this.state.driverNotes.list
        : [];

    let isShowAssistant = (this.props.auth.selectedFleet && this.props.auth.selectedFleet.assistant) ? this.props.auth.selectedFleet.assistant.enable : false;

    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={"confirm"}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="driverNote.DELETE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="driverNote.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={"btn-save mr-md"}
                onClick={this.confirmDeleteCity}
              >
                <Translate value="city.Yes" />
              </Button>
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="city.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        {this.state.showDialog ? (
          <Modal show={true} backdrop={true} dialogClassName={"modal-max-600"} onHide={this.closeDialogForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="driverNote.EDIT" />
                  ) : (
                      <Translate value="driverNote.DETAIL" />
                    )
                ) : (
                    <Translate value="driverNote.ADD" />
                  )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <FormGroup
                className={
                  !this.state.isSubmited
                    ? null
                    : this.state.valid.name
                      ? null
                      : "error"
                }
              >
                <Form.Label>
                  <Translate value="driverNote.name" />{" "}
                  <span className="require">*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  className={"form-custom"}
                  onChange={this.handleNameChange}
                  value={
                    this.state.dialogData ? this.state.dialogData.name : ""
                  }
                  placeholder={I18n.t("driverNote.name")}
                  disabled={!this.state.editable}
                  maxLength={200}
                />
                <Validator id="name" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={
                      !Validation.isStringEmpty(this.state.dialogData.name)
                    }
                    message={I18n.t("messages.commonMessages.Required_field")}
                  />
                </Validator>
              </FormGroup>
              <Form.Label>
                (<span className="require">*</span>):{" "}
                <Translate value="city.Required_fields" />
              </Form.Label>
            </Modal.Body>
            <Modal.Footer>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={"btn-save mr-md"}
                    onClick={this.saveDialogForm}
                  disabled={!CCLiteCommonFunc.isFormValid(this.state.valid)}
                  >
                    <Translate value="city.Save" />
                  </Button>
                ) : (
                    <Button
                      className={"btn-save mr-md"}
                      onClick={e => {
                        this.handleMenuClick("changeStatus", e);
                      }}
                    >
                      <Translate value="city.Edit" />
                    </Button>
                  )
              ) : null}
              <Button className={"btn-cancel"} onClick={this.closeDialogForm}>
                <Translate value="city.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
            ""
          )}
        {!this.props.permissions || this.props.permissions.actions ? (
          <ButtonToolbar
            className="text-center header-button-group"
            ref={node => (this.toobarContainer = node)}
          >
            <Button
              className="btn-header text-add-header ml0"
              onClick={this.handleAddButtonClick}
            >
              {" "}

              <Translate value="city.Add" />
            </Button>
          </ButtonToolbar>
        ) : (
            ""
          )}
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

DriverNote.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverNote);
