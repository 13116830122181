import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  paymentMethodNumber,
  TRAVELER_TYPE,
  paymentGetwayDisabledNewBooking,
  HYDRA_STATUS,
  PAYMENTS_ONLY_APP,
  PAYMENT_LINK_NAME
} from '../../../constants/commondata';
import FormGroupTitle from '../../formGroupTitile/FormGroupTitle';
import { Validator, ValidCase } from '../../validator';
import { Validation, CCLiteCommonFunc, getSuppilerPermission } from '../../../utils/commonFunctions';
import { isPaymentMethodEditable, checkRemoveGoogleOrApplePay, checkPaymentShow } from '../bookFunction/payment';
import QuickAddNewCard from './QuickAddNewCard';
import currencyFormatter from 'currency-formatter';
import ExternalID from './ExternalID'
const PM_CASH_BY_SENDER = {
  key: 'cashBySender',
  name: 'Cash by sender',
  type: 'cash',
  value: 16,
};
const PM_CASH_BY_RECIPIENT = {
  key: 'cashByRecipient',
  name: 'Cash by recipient',
  type: 'cash',
  value: 17,
};

export const PAYMENTS_HIDE_NEW_BOOKING = [21, 23]
class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderNewBookingPaymentMethod() {
    const {
      data,
      handlePaymentMethodChange,
      paymentMethod,
      newBooking,
      corporateTypes,
      isSubmited,
      valid,
      handleCreditCardChange,
      handleAddCardSuccess,
      credit,
      customer,
      selectedFleet,
      auth,
      company,
      handleClientCaseMatterChange,
      handleChargeCodeChange,
      handleChangeOperatorNote,
      ValidatorCallback,
      handleAddNewCustomerSuccess,
      reloadCustomerTSYSGateway,
      reloadCorporateCompany,
      isShuttle,
      isDelivery,
      doPoints,
      isAirlineCorporate,
      airlineTypes,
      isHydraBooking,
      fleetSupplierId,
      companySelected
    } = this.props;
    const gatewayConfig = selectedFleet.creditConfig.multiGateway
      ? _.get(newBooking, 'locationService.paymentGateways', {})
      : _.get(selectedFleet, 'creditConfig.configGateway', {});

    const supportWeb = gatewayConfig.supportWeb || false;
    const isDisableAddCard = paymentGetwayDisabledNewBooking.includes(
      gatewayConfig.gateway
    );

    const isPersonalOrCorpCard =
      data.paymentMethod === paymentMethodNumber.personalCard ||
      data.paymentMethod === paymentMethodNumber.corporateCard;

    let corpId = '';

    if (
      data.traveler_type == TRAVELER_TYPE.Corporate &&
      data.paymentMethod === paymentMethodNumber.corporateCard
    ) {
      corpId = auth.user.corporateId || (company ? company._id : 0);
    }

    const newCustomer = {
      phone: data.phone
        .replace(new RegExp(' ', 'g'), '')
        .replace(new RegExp('-', 'g'), ''),
      firstName: data.firstname || '',
      lastName: data.lastname || '',
      email: data.email || '',
    };

    let paymentMethodIndividuals = [...(paymentMethod.individualTypes || [])];
    let paymentMethodCorporates = [...(corporateTypes || [])];

    _.remove(paymentMethodIndividuals, (ob) => PAYMENTS_HIDE_NEW_BOOKING.includes(ob.value) || PAYMENTS_ONLY_APP.includes(ob.key));
    _.remove(paymentMethodCorporates, (ob) => PAYMENTS_HIDE_NEW_BOOKING.includes(ob.value) || PAYMENTS_ONLY_APP.includes(ob.key));

    if (isShuttle) {
      paymentMethodIndividuals = paymentMethodIndividuals.filter(
        (payments) => payments.value != 1
      );
      paymentMethodCorporates = paymentMethodCorporates.filter(
        (payments) => payments.value != 1
      );
    }
    if (isDelivery) {
      paymentMethodIndividuals = paymentMethodIndividuals.filter(
        (payments) => payments.value != 1 && payments.value != 6
      );
      paymentMethodCorporates = paymentMethodCorporates.filter(
        (payments) => payments.value != 1 && payments.value != 6
      );
      if (
        !paymentMethodIndividuals.some(
          (item) => item.value === PM_CASH_BY_SENDER.value
        )
      ) {
        paymentMethodIndividuals.push(PM_CASH_BY_SENDER);
      }
      if (
        !paymentMethodCorporates.some(
          (item) => item.value === PM_CASH_BY_SENDER.value
        )
      ) {
        paymentMethodCorporates.push(PM_CASH_BY_SENDER);
      }
      if (doPoints.length <= 1) {
        if (
          !paymentMethodIndividuals.some(
            (item) => item.value === PM_CASH_BY_RECIPIENT.value
          )
        ) {
          paymentMethodIndividuals.push(PM_CASH_BY_RECIPIENT);
        }
        if (
          !paymentMethodCorporates.some(
            (item) => item.value === PM_CASH_BY_RECIPIENT.value
          )
        ) {
          paymentMethodCorporates.push(PM_CASH_BY_RECIPIENT);
        }
      }
    }
    // filter payment method based company setting
    const pmCheckedSetting = checkPaymentShow({
      auth,
      traveler_type: data.traveler_type,
      companySelected, 
      paymentMethodIndividuals, 
      paymentMethodCorporates,
      corporateSelected: company,
      isDelivery,
    })
    paymentMethodIndividuals = pmCheckedSetting.paymentMethodIndividuals
    paymentMethodCorporates = pmCheckedSetting.paymentMethodCorporates
    return (
      <div>
        <FormGroupTitle>
          <Translate value="newbooking.PAYMENT_METHOD" />
        </FormGroupTitle>
        <FormGroup>
          <FormControl
            as="select"
            value={data.paymentMethod}
            className="form-custom"
            placeholder="select"
            onChange={handlePaymentMethodChange}
          >
            <option value={0}>
              {I18n.t(`newbooking.Choose_a_payment_method`)}
            </option>
            {newBooking.locationService &&
            newBooking.locationService.crossZone ? (
              <option value={paymentMethodNumber.personalCard}>
                {I18n.t('bookingdetail.Personal_Card')}
              </option>
            ) : data.traveler_type == TRAVELER_TYPE.Individual ? (
              paymentMethodIndividuals.map((payment) => {
                if (
                  newBooking.locationService &&
                  newBooking.locationService.crossZone
                ) {
                  if (
                    payment.value == paymentMethodNumber.personalCard &&
                    payment.value != 9 &&
                    payment.value != 23
                  ) {
                    // dont show apple pay method in new booking
                    return (
                      <option key={payment.name} value={payment.value}>
                        {
                          I18n.t(
                            `General.patmentMethod_${payment.value}`
                          ) /* payment.name */
                        }
                      </option>
                    );
                  }
                } else if ([9, 13, 14, 20, 21, 23].indexOf(payment.value) === -1) {
                  // dont show apple pay, google pay, wallet method in new booking
                  return (
                    <option key={payment.name} value={payment.value}>
                      {
                        I18n.t(
                          `General.patmentMethod_${payment.value}`
                        ) /* payment.name */
                      }
                      {/* {payment.name} */}
                    </option>
                  );
                }
              })
            ) : isAirlineCorporate ? (
              airlineTypes.map((payment) => {
                if (
                  newBooking.locationService &&
                  newBooking.locationService.crossZone
                ) {
                  if (
                    payment.value == paymentMethodNumber.corporateCard &&
                    [13, 14, 20, 23].indexOf(payment.value) === -1
                  ) {
                    return (
                      <option key={payment.value} value={payment.value}>
                        {
                          I18n.t(
                            `General.patmentMethod_${payment.value}`
                          ) /* payment.name */
                        }
                        {/* {payment.name} */}
                      </option>
                    );
                  }
                } else if ([7, 4, 5].indexOf(payment.value) >= 0) {
                  return (
                    <option key={payment.value} value={payment.value}>
                      {
                        I18n.t(
                          `General.patmentAirlineMethod_${payment.value}`
                        ) /* payment.name */
                      }
                      {/* {payment.name} */}
                    </option>
                  );
                }
              })
            ) : (
              paymentMethodCorporates.map((payment) => {
                if (
                  newBooking.locationService &&
                  newBooking.locationService.crossZone
                ) {
                  if (
                    payment.value == paymentMethodNumber.corporateCard &&
                    [13, 14, 20, 21].indexOf(payment.value) === -1
                  ) {
                    return (
                      <option key={payment.name} value={payment.value}>
                        {
                          I18n.t(
                            `General.patmentMethod_${payment.value}`
                          ) /* payment.name */
                        }
                        {/* {payment.name} */}
                      </option>
                    );
                  }
                } else if ([9, 13, 14, 20, 21].indexOf(payment.value) === -1) {
                  return (
                    <option key={payment.name} value={payment.value}>
                      {
                        I18n.t(
                          `General.patmentMethod_${payment.value}`
                        ) /* payment.name */
                      }
                      {/* {payment.name} */}
                    </option>
                  );
                }
              })
            )}
          </FormControl>
          {isPersonalOrCorpCard ? (
            <FormGroup
              className={`mr-t-10 ${
                !isSubmited
                  ? null
                  : valid.payment_card === false
                  ? 'error'
                  : null
              }`}
            >
              <FormControl
                as="select"
                value={credit ? credit.crossToken || credit.localToken : ''}
                placeholder="select"
                className="form-custom"
                onChange={handleCreditCardChange}
              >
                <option value={0}>
                  {
                    I18n.t(
                      `newbooking.Choose_a_stored_card`
                    ) /* Nguyen change to plain text to fix warning "span" can not is child of option */
                  }
                  {/* <Translate value='newbooking.Choose_a_stored_card' /> */}
                </option>
                {data.paymentMethod == paymentMethodNumber.personalCard
                  ? customer &&
                    customer.credits &&
                    customer.credits.map((cre) => {
                      if(cre.crossToken) return null
                      if (
                        newBooking.locationService &&
                        newBooking.locationService.crossZone
                      ) {
                        if (!Validation.isStringEmpty(cre.crossToken)) {
                          return (
                            <option key={cre.crossToken} value={cre.crossToken}>
                              {cre.cardMask}
                            </option>
                          );
                        }
                      } else if (
                        !Validation.isStringEmpty(cre.localToken) &&
                        ((selectedFleet.creditConfig.multiGateway &&
                          newBooking.locationService &&
                          !_.isEmpty(
                            newBooking.locationService.paymentGateways
                          ) &&
                          newBooking.locationService.paymentGateways.gateway ===
                            cre.gateway) ||
                          (!selectedFleet.creditConfig.multiGateway &&
                            selectedFleet.creditConfig.configGateway &&
                            selectedFleet.creditConfig.configGateway.gateway ===
                              cre.gateway))
                      ) {
                        return (
                          <option key={cre.localToken} value={cre.localToken}>
                            {cre.cardMask}
                          </option>
                        );
                      }
                    })
                  : company &&
                    company.credits &&
                    company.credits
                      .filter(
                        (cr) =>
                          newBooking.locationService &&
                          newBooking.locationService.paymentGateways &&
                          ((selectedFleet.creditConfig.multiGateway &&
                            newBooking.locationService &&
                            !_.isEmpty(
                              newBooking.locationService.paymentGateways
                            ) &&
                            newBooking.locationService.paymentGateways
                              .gateway == cr.gateway) ||
                            (!selectedFleet.creditConfig.multiGateway &&
                              !_.isEmpty(
                                selectedFleet.creditConfig.configGateway
                              ) &&
                              selectedFleet.creditConfig.configGateway
                                .gateway == cr.gateway))
                      )
                      .map((cre) => (
                        <option
                          key={cre.crossToken || cre.localToken}
                          value={cre.crossToken || cre.localToken}
                        >
                          {cre.cardHolder} *
                          {(cre.cardMask || '').replace(/[\X\x]/g, '')}
                        </option>
                      ))}
              </FormControl>
              <Validator
                id="payment_card"
                callback={ValidatorCallback}
                disabled={!isPersonalOrCorpCard}
              >
                <ValidCase valid={credit} hide message="" />
              </Validator>
            </FormGroup>
          ) : (
            ''
          )}
          {this.renderExternalId()}
          {/* client case master */}
          {newBooking.locationService &&
          !newBooking.locationService.crossZone &&
          (parseInt(data.traveler_type) === 1 ||
            data.paymentMethod == 5 ||
            data.paymentMethod == paymentMethodNumber.corporateCard ||
            data.paymentMethod == 7) ? (
            <div>
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleClientCaseMatterChange}
                  value={data.clientCaseMatter}
                  placeholder={I18n.t('newbooking.Client_case_matter')}
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleChargeCodeChange}
                  value={data.chargeCode}
                  placeholder={I18n.t('newbooking.Charge_code')}
                />
              </FormGroup>
            </div>
          ) : (
            ''
          )}

          {/* add new card */}
          {!isDisableAddCard && supportWeb && isPersonalOrCorpCard ? (
            <QuickAddNewCard
              supportWeb={supportWeb}
              customer={data.psgInfo || customer}
              isHydraBooking={isHydraBooking}
              fleetSupplierId={fleetSupplierId}
              corpId={corpId}
              paymentType={data.paymentMethod}
              handleAddCardSuccess={handleAddCardSuccess}
              gateWay={gatewayConfig}
              newCustomer={newCustomer}
              handleAddNewCustomerSuccess={handleAddNewCustomerSuccess}
              reloadCustomerTSYSGateway={reloadCustomerTSYSGateway}
              reloadCorporateCompany={reloadCorporateCompany}
            />
          ) : null}
        </FormGroup>
        {
          !isHydraBooking && (
          isDelivery && 
          !getSuppilerPermission(this.props.auth) &&
            <>
              <FormGroupTitle>
                <Translate value="newbooking.OPERATOR_NOTE" />
              </FormGroupTitle>
              <FormGroup>
                <FormControl
                  type="text"
                  as="textarea"
                  onChange={handleChangeOperatorNote}
                  value={data.operatorNote}
                  rows={4}
                  maxLength={1000}
                  className={'form-custom'}
                  // placeholder={I18n.t("newbooking.Note")}
                  placeholder={I18n.t('newbooking.operator_note')}
                />
              </FormGroup>
            </>
          )
        }
      </div>
    );
  }

  renderBookingDetailPaymentMethod() {
    const {
      newBooking,
      data,
      handlePaymentMethodChange,
      paymentMethod,
      corporateTypes,
      isShowmDispatcherCard,
      handleCreditCardChange,
      handleAddCardSuccess,
      handleChangeOperatorNote,
      credit,
      selectedFleet,
      auth,
      customer,
      company,
      handleClientCaseMatterChange,
      handleChargeCodeChange,
      handleAddNewCustomerSuccess,
      reloadCustomerTSYSGateway,
      reloadCorporateCompany,
      isShuttle,
      isDelivery,
      doPoints,
      isAirlineCorporate,
      airlineTypes,
      isDisableOtherOperation = false,
      isHydraBooking,
      farmIn,
      farmOut,
      isBookFromPWA,
      is3rdBooking, 
      roaming,
      companySelected,
      isAllwayShowGoogleApplePay,
      isDisableWhenPrepaided
    } = this.props;

    const gatewayConfig = selectedFleet.creditConfig.multiGateway
      ? _.get(newBooking, 'locationService.paymentGateways', {})
      : _.get(selectedFleet, 'creditConfig.configGateway', {});

    const supportWeb = gatewayConfig.supportWeb || false;
    const isDisableAddCard =
      paymentGetwayDisabledNewBooking.includes(gatewayConfig.gateway) ||
      !isPaymentMethodEditable(data) ||
      is3rdBooking
    const hydraStatus = _.get(data, 'hydraInfo.status', 'none')
    const canEditPaymenHydra = !farmOut || (farmOut && hydraStatus != HYDRA_STATUS.accepted)
    const canChangeCard =
      (data.request.paymentType == paymentMethodNumber.personalCard ||
        data.request.paymentType == paymentMethodNumber.corporateCard ||
        data.request.paymentType ==
          paymentMethodNumber.personalCardChangedOnApp);

    const isBookFromMDispatcher = data.bookFrom === 'mDispatcher';

    let corpId = '';
    if (
      data.travelerType === TRAVELER_TYPE.Corporate &&
      data.request.paymentType === paymentMethodNumber.corporateCard
    ) {
      corpId = auth.user.corporateId || (company ? company._id : 0);
    }

    const newCustomer = {
      phone: data.phone || (customer || {}).phone,
      firstName: data.firstname || (customer || {}).firstName,
      lastName: data.lastname || (customer || {}).lastName,
      email: data.email || (customer || {}).email,
    };

    const isHydraBookingDMC = data.bookFrom == 'dmc' || data.bookFrom == 'corp';
    const primaryPartialMethod = _.get(data, 'request.primaryPartialMethod', -1)
    const walletCustomer = _.get(customer, 'paxWallet[0]', {})
    const walletCustomerName = _.get(auth, 'selectedFleet.paxCreditWalletConfig.walletName', '')

    let paymentMethodIndividuals = paymentMethod.individualTypes || [];
    let paymentMethodCorporates = corporateTypes || [];
    let walletName = _.get(data, 'request.walletName', '');
    let gateway = _.capitalize(_.get(data, 'request.gateway', ''));

    // Check show/hide googlePay vaf applePay
    const paymentRemoves = checkRemoveGoogleOrApplePay(data.request.paymentType, isAllwayShowGoogleApplePay)
    if(paymentRemoves?.length > 0) {
      paymentRemoves.map(pmRemove => {
        _.remove(
          paymentMethodIndividuals,
          (ob) => ob?.key?.toUpperCase() === pmRemove.toUpperCase()
        );
        _.remove(
          paymentMethodCorporates,
          (ob) => ob?.key?.toUpperCase() === pmRemove.toUpperCase()
        );
      })
    }

    let paymentTypeShow = data.request?.paymentType || 0
    _.remove(
      paymentMethodIndividuals,
      (ob) => PAYMENTS_ONLY_APP.includes(ob.key)
    );
    _.remove(
      paymentMethodCorporates,
      (ob) => PAYMENTS_ONLY_APP.includes(ob.key)
    );

    if (paymentTypeShow === 21) {
      _.remove(
        paymentMethodIndividuals,
        (ob) => ob.value == 21 && ob.name.toUpperCase() != gateway.toUpperCase()
      );
      _.remove(
        paymentMethodCorporates,
        (ob) => ob.value == 21 && ob.name.toUpperCase() != gateway.toUpperCase()
      );
    }

    if (isShuttle) {
      paymentMethodIndividuals = paymentMethodIndividuals.filter(
        (payments) => payments.value != 1
      );
      paymentMethodCorporates = paymentMethodCorporates.filter(
        (payments) => payments.value != 1
      );
    }
    if (isDelivery) {
      paymentMethodIndividuals = paymentMethodIndividuals.filter(
        (payments) => payments.value != 1 && payments.value != 6
      );
      paymentMethodCorporates = paymentMethodCorporates.filter(
        (payments) => payments.value != 1 && payments.value != 6
      );
      if (
        !paymentMethodIndividuals.some(
          (item) => item.value === PM_CASH_BY_SENDER.value
        )
      ) {
        paymentMethodIndividuals.push(PM_CASH_BY_SENDER);
      }
      if (
        !paymentMethodCorporates.some(
          (item) => item.value === PM_CASH_BY_SENDER.value
        )
      ) {
        paymentMethodCorporates.push(PM_CASH_BY_SENDER);
      }
      if (doPoints.length <= 1) {
        if (
          !paymentMethodIndividuals.some(
            (item) => item.value === PM_CASH_BY_RECIPIENT.value
          )
        ) {
          paymentMethodIndividuals.push(PM_CASH_BY_RECIPIENT);
        }
        if (
          !paymentMethodCorporates.some(
            (item) => item.value === PM_CASH_BY_RECIPIENT.value
          )
        ) {
          paymentMethodCorporates.push(PM_CASH_BY_RECIPIENT);
        }
      }
    }

    const phonePax = _.get(data, 'psgInfo.phone', '')
    if(!phonePax || phonePax === 'No Phone') {
      _.remove(paymentMethodIndividuals, pm => pm.value === 2 )
      _.remove(paymentMethodCorporates, pm => pm.value === 2 )
    }

    // filter payment method based company setting and corporate
    const pmCheckedSetting = checkPaymentShow({
        data,
        auth,
        traveler_type: data.travelerType,
        companySelected, 
        paymentMethodIndividuals, 
        paymentMethodCorporates,
        corporateSelected: company,
        isBookingDetail: true,
        requestPaymentType: data.request?.paymentType
    })
    paymentMethodIndividuals = pmCheckedSetting.paymentMethodIndividuals
    paymentMethodCorporates = pmCheckedSetting.paymentMethodCorporates

    // Truong hợp operator admin assign 1 method nhưng ở operator corp không support => show ra và disable
    if(pmCheckedSetting.isDisableSelectMethodCorp && paymentTypeShow !== 0) {
      return (
        <FormGroup>
          <FormGroupTitle>
            <Translate value="newbooking.PAYMENT_METHOD" />
          </FormGroupTitle>
          <Form.Label className="card-label">
            <FormControl
              value={I18n.t(`General.patmentMethod_${paymentTypeShow}`)}
              disabled
              className="form-custom"
            />
          </Form.Label>
        </FormGroup>
      )
    }
    
    return (
      <FormGroup>
        <FormGroupTitle>
          <Translate value="newbooking.PAYMENT_METHOD" />
        </FormGroupTitle>
        {isHydraBookingDMC ? (
          <Form.Label className="card-label">
            {/*hard code here because dmc has only 1 payment method. it is direct invoice*/}

            {paymentTypeShow == 12 ? (
              <FormControl
                value={I18n.t('General.patmentMethod_12')}
                disabled
                className="form-custom"
              />
            ) : (
              <FormControl
                value={I18n.t('General.patmentMethod_5')}
                disabled
                className="form-custom"
              />
            )}
          </Form.Label>
        ) : farmIn || roaming ? (
              roaming ? (
                <>
                  <FormControl
                    value={I18n.t('General.patmentMethod_2')}
                    disabled
                    className="form-custom"
                  />
                  <FormGroup className="">
                    <FormControl
                      value={
                        data.psgInfo && data.psgInfo.creditInfo
                          ? data.psgInfo.creditInfo.cardMask
                          : ''
                      }
                      className="form-custom"
                      disabled
                    />
                  </FormGroup>
                </>
              ) : (
                <FormControl
                  value={I18n.t('General.patmentMethod_5')}
                  disabled
                  className="form-custom"
                />
              )
        ) : paymentTypeShow ==
            paymentMethodNumber.personalCardChangedOnApp ? (
          <Form.Label className="card-label">
            <FormControl
              value={I18n.t('bookingdetail.Personal_Card')}
              className="form-custom"
              disabled
            />
          </Form.Label>
        ) : isPaymentMethodEditable(data || isDisableOtherOperation) &&
        !isDisableWhenPrepaided ? ( // disable when booking prepaided
          <FormControl
            as="select"
            className="form-custom"
            placeholder="select"
            onChange={handlePaymentMethodChange}
            value={paymentTypeShow}
            disabled={
              !isPaymentMethodEditable(data, is3rdBooking) 
              || isDisableOtherOperation
              || !canEditPaymenHydra
            }
          >
            <option value={0}>
              {I18n.t(`bookingdetail.Choose_a_payment_method`)}
            </option>
            {!data.travelerType
              ? paymentMethodIndividuals.map((payment) =>
                  ![13, 14, 20, 21].includes(payment.value) ||
                  ([13, 14, 20, 21].includes(payment.value) &&
                  paymentTypeShow === payment.value) ? (
                    <option key={payment.name} value={payment.value}>
                      {payment.value === 21
                        ? walletName ||
                          I18n.t(`General.patmentMethod_${payment.value}`)
                        : I18n.t(`General.patmentMethod_${payment.value}`)}
                    </option>
                  ) : null
                )
              : isAirlineCorporate
              ? airlineTypes.map((payment) =>
                  [7, 4, 5].indexOf(payment.value) >= 0 ? (
                    <option key={payment.value} value={payment.value}>
                      {I18n.t(`General.patmentAirlineMethod_${payment.value}`)}
                    </option>
                  ) : null
                )
              : paymentMethodCorporates.map((payment) =>
                  ![13, 14, 20, 21].includes(payment.value) ||
                  ([13, 14, 20, 21].includes(payment.value) &&
                  paymentTypeShow === payment.value) ? (
                    <option key={payment.name} value={payment.value}>
                      {payment.value === 21
                        ? walletName ||
                          I18n.t(`General.patmentMethod_${payment.value}`)
                        : I18n.t(`General.patmentMethod_${payment.value}`)}
                    </option>
                  ) : null
                )}
            {isShowmDispatcherCard || isBookFromMDispatcher // show more method partner card if booking from mDipatcher
              ? paymentMethod.type3rd.map((pm) => {
                  return (
                    <option key={pm.value} value={pm.value}>
                      {I18n.t(`General.patmentMethod_${pm.value}`)}
                    </option>
                  );
                })
              : null}
          </FormControl>
        ) : (
          <FormControl
            value={
              paymentTypeShow === 21
                ? walletName
                : paymentTypeShow === 24
                  ? `${I18n.t(`General.patmentMethod_24`)} (${PAYMENT_LINK_NAME[data.completedInfo?.stripeMethod]})`
                  : I18n.t(
                    `General.patmentMethod_${
                      paymentTypeShow == 0
                        ? paymentMethod.individualTypes &&
                          paymentMethod.individualTypes[0]
                          ? paymentMethod.individualTypes[0].value
                          : ''
                        : paymentTypeShow
                    }`
                  )
            }
            disabled
            className="form-custom"
          />
        )}
        {canChangeCard 
        && !farmIn 
        && !roaming && (
          <FormGroup>
            <FormControl
              className="mr-t-10 form-custom"
              as="select"
              placeholder="select"
              onChange={handleCreditCardChange}
              value={credit ? credit.localToken || credit.crossToken : ''}
              disabled={
                !isPaymentMethodEditable(data) 
                || isDisableOtherOperation
                || !canEditPaymenHydra
              }
            >
              <option value={0}>
                {I18n.t('bookingdetail.Choose_a_stored_card')}
              </option>
              {paymentTypeShow === paymentMethodNumber.personalCard ? (
                customer && customer.credits && customer.credits.length > 0 ? (
                  customer.credits.map((cre) => {
                    if(cre.crossToken) return null
                    if (
                      CCLiteCommonFunc.isBookingStatusCompleted(data.status)
                    ) {
                      if (
                        data.psgInfo &&
                        data.psgInfo.creditInfo &&
                        data.psgInfo.creditInfo.cardMask
                      ) {
                        return (
                          <option
                            key={
                              data.psgInfo.creditInfo.localToken ||
                              data.psgInfo.creditInfo.crossToken
                            }
                            value={
                              data.psgInfo.creditInfo.localToken ||
                              data.psgInfo.creditInfo.crossToken
                            }
                          >
                            {data.psgInfo.creditInfo.cardMask}
                          </option>
                        );
                      }
                    }
                    if (
                      !Validation.isStringEmpty(cre.crossToken) &&
                      data.pricingType == 1
                    ) {
                      return (
                        <option key={cre.crossToken} value={cre.crossToken}>
                          {cre.cardMask}
                        </option>
                      );
                    }
                    if (
                      (!Validation.isStringEmpty(cre.localToken) &&
                        data.pricingType != 1 &&
                        selectedFleet.creditConfig.multiGateway &&
                        ((newBooking.locationService &&
                          !_.isEmpty(
                            newBooking.locationService.paymentGateways
                          ) &&
                          newBooking.locationService.paymentGateways.gateway ==
                            cre.gateway) ||
                          (data.psgInfo &&
                            data.psgInfo.creditInfo &&
                            data.psgInfo.creditInfo.localToken ==
                              cre.localToken))) ||
                      (!selectedFleet.creditConfig.multiGateway &&
                        selectedFleet.creditConfig.configGateway &&
                        selectedFleet.creditConfig.configGateway.gateway ==
                          cre.gateway)
                    ) {
                      return (
                        <option key={cre.localToken} value={cre.localToken}>
                          {cre.cardMask}
                        </option>
                      );
                    }
                  })
                ) : data.psgInfo &&
                  data.psgInfo.creditInfo &&
                  (data.psgInfo.creditInfo.localToken ||
                    data.psgInfo.creditInfo.crossToken) ? (
                  <option
                    key={
                      data.psgInfo.creditInfo.localToken ||
                      data.psgInfo.creditInfo.crossToken
                    }
                    value={
                      data.psgInfo.creditInfo.localToken ||
                      data.psgInfo.creditInfo.crossToken
                    }
                  >
                    {data.psgInfo.creditInfo.cardMask}
                  </option>
                ) : (
                  ''
                )
              ) : paymentTypeShow !=
                  paymentMethodNumber.personalCardChangedOnApp &&
                company &&
                company.credits ? (
                CCLiteCommonFunc.isBookingStatusCompleted(data.status) ? (
                  paymentTypeShow !=
                    paymentMethodNumber.personalCardChangedOnApp &&
                  data.psgInfo &&
                  data.corporateInfo.creditInfo &&
                  data.corporateInfo.creditInfo.cardMask ? (
                    <option
                      key={
                        data.corporateInfo.creditInfo.localToken ||
                        data.corporateInfo.creditInfo.crossToken
                      }
                      value={
                        data.corporateInfo.creditInfo.localToken ||
                        data.corporateInfo.creditInfo.crossToken
                      }
                    >
                      {data.corporateInfo.creditInfo.cardHolder}{' '}
                      {(data.corporateInfo.creditInfo.cardMask || '').replace(
                        /[\X\x]/g,
                        ''
                      )}
                    </option>
                  ) : null
                ) : (
                  company.credits
                    .filter(
                      (cr) =>
                        CCLiteCommonFunc.isBookingStatusCompleted(
                          data.status
                        ) ||
                        (newBooking.locationService &&
                          newBooking.locationService.paymentGateways &&
                          ((selectedFleet.creditConfig.multiGateway &&
                            newBooking.locationService &&
                            !_.isEmpty(
                              newBooking.locationService.paymentGateways
                            ) &&
                            newBooking.locationService.paymentGateways
                              .gateway == cr.gateway) ||
                            (!selectedFleet.creditConfig.multiGateway &&
                              !_.isEmpty(
                                selectedFleet.creditConfig.configGateway
                              ) &&
                              selectedFleet.creditConfig.configGateway
                                .gateway == cr.gateway)))
                    )
                    .map((cre) => {
                      return (
                        <option
                          key={cre.localToken || cre.crossToken}
                          value={cre.localToken || cre.crossToken}
                        >
                          {cre.cardHolder}
                          {' *'}
                          {(cre.cardMask || '').replace(/[\X\x]/g, '')}
                        </option>
                      );
                    })
                )
              ) : (
                ''
              )}
            </FormControl>
          </FormGroup>
        )}

        
        {this.renderExternalId()}        
        {/* Client case master */}
        {data &&
        (parseInt(data.travelerType) === 1 ||
          paymentTypeShow == 5 ||
          paymentTypeShow == paymentMethodNumber.corporateCard ||
          paymentTypeShow == 7) ? (
          <div>
            <FormGroup className="mr-t-5">
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleClientCaseMatterChange}
                  value={
                    data.corporateInfo
                      ? data.corporateInfo.clientCaseMatter
                      : ''
                  }
                  placeholder={I18n.t('bookingdetail.Client_case_matter')}
                  disabled={!canEditPaymenHydra 
                    || (farmIn && hydraStatus === HYDRA_STATUS.pending)
                    || is3rdBooking
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="text"
                  className="form-custom"
                  onChange={handleChargeCodeChange}
                  value={
                    data.corporateInfo ? data.corporateInfo.chargeCode : ''
                  }
                  placeholder={I18n.t('bookingdetail.Charge_code')}
                  disabled={
                    !canEditPaymenHydra 
                    || (farmIn && hydraStatus === HYDRA_STATUS.pending)
                    || is3rdBooking
                  }
                />
              </FormGroup>
            </FormGroup>
          </div>
        ) : (
          ''
        )}

        {/* add new card form */}
        {!isDisableAddCard &&
        supportWeb &&
        canChangeCard &&
        !isBookFromPWA &&
        !roaming ? (
          <QuickAddNewCard
            supportWeb={supportWeb}
            customer={data.psgInfo || customer}
            corpId={corpId}
            paymentType={paymentTypeShow}
            handleAddCardSuccess={handleAddCardSuccess}
            gateWay={gatewayConfig}
            newCustomer={newCustomer}
            handleAddNewCustomerSuccess={handleAddNewCustomerSuccess}
            reloadCustomerTSYSGateway={reloadCustomerTSYSGateway}
            reloadCorporateCompany={reloadCorporateCompany}
          />
        ) : null}
        {
          primaryPartialMethod != -1 && (
            CCLiteCommonFunc.isBookingStatusCompleted(data.status) 
            ? <p>{ I18n.t('cue.partialWallet') }</p>
            : <p>
                {
                  I18n.t('cue.balanceWallet').format(
                    walletCustomerName ? walletCustomerName : I18n.t('generalSetting.wallet'),
                    currencyFormatter.format(walletCustomer.value, { code: walletCustomer.currencyISO })
                  )
                }
              </p>
          )
        }
        {
          (!isHydraBooking || (isHydraBooking && farmIn))
          && isDelivery
          && (
            <>
              <FormGroupTitle>
                <Translate value="newbooking.OPERATOR_NOTE" />
              </FormGroupTitle>
              <FormGroup>
                <FormControl
                  type="text"
                  as="textarea"
                  onChange={handleChangeOperatorNote}
                  value={data.request.operatorNote}
                  rows={4}
                  maxLength={1000}
                  className={'form-custom'}
                  disabled={isDisableOtherOperation}
                  // placeholder={I18n.t("newbooking.Note")}
                  placeholder={I18n.t('newbooking.operator_note')}
                />
              </FormGroup>
            </>
          )
        }
      </FormGroup>
    );
  }

  renderExternalId = () => {
    const {
      data,
      handleChangeBookingReference,
      is3rdBooking
    } = this.props
    return (
      <ExternalID
        value={data?.externalInfo?.bookingReference}
        handleChangeBookingReference={handleChangeBookingReference}
        is3rdBooking={is3rdBooking}
      />
    )
  }

  render() {
    const bookingDetail = this.props.data.bookId;
    if (bookingDetail) return this.renderBookingDetailPaymentMethod();
    return this.renderNewBookingPaymentMethod();
  }
}
PaymentInfo.propTypes = {
  isDisableWhenPrepaided: PropTypes.bool, // disable change payment when pre paided
};

export default PaymentInfo;
