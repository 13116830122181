import React from 'react';
import CurrencyFormater from 'currency-formatter';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get } from 'lodash';

export const Columns = [
  {
    key: 'fullName',
    label: 'driver.Driver_Name',
    sortName: 'fullNameSort',
    fixed: true,
    textEllipsis: true
  },
  {
    key: 'driverInfo.drvId',
    label: 'driver.Driver_ID'
  },
  {
    key: 'phone',
    label: 'driver.Phone_Number',
    sortName: 'phone'
  },
  {
    key: 'username',
    label: 'driver.Username',
    sortName: 'username'
  },
  {
    key: 'driverInfo.driverType',
    label: 'driver.Driver_Type',
    width: 250
  },
  {
    key: 'rank',
    label: 'driver.TOP',
    width: 100
  },
  {
    key: 'driverInfo.agent.name',
    label: 'driver.Agent',
    textEllipsis: true
  },
  {
    key: 'driverInfo.supplier.name',
    label: 'driver.Supplier',
    textEllipsis: true
  },
  {
    key: 'driverInfo.driverLicenseNumber',
    label: 'driver.Driver_License_Number',
    width: 250,
    textEllipsis: true
  },
  {
    key: 'driverInfo.licensePlate',
    label: 'driver.License_Plate'
  },
  {
    key: 'driverInfo.carType.name',
    label: 'driver.Car_Type'
  },
  {
    key: 'driverInfo.zoneName',
    label: 'driver.Zone',
    textEllipsis: true
  },
  {
    key: 'driverInfo.city.name',
    label: 'driver.City',
    textEllipsis: true
  },
  {
    key: 'registerFrom',
    label: 'driver.Registered_From'
  },
  {
    key: 'createdDate',
    label: 'driver.Registration_Date',
    sortName: 'createdDate'
  },
  {
    key: 'lastLogin.iOSVersion',
    label: 'driver.IOS_Last_Login_Version',
    width: 250
  },
  {
    key: 'lastLogin.androidVersion',
    label: 'driver.Android_Last_Login_Version',
    width: 250
  },
  {
    key: 'lastActive',
    label: 'driver.Last_Active_Date',
    sortName: 'lastActive'
  },
  {
    key: 'Days_Since_Last_Active',
    label: 'driver.Days_Since_Last_Active',
    sortName: 'lastActive',
    width: 220
  },
  {
    key: 'booking.completed',
    label: 'driver.Completed_bookings',
    sortName: 'booking'
  },
  {
    key: 'creditBalances',
    label: 'driver.Credit_Balance',
    sortName: 'creditBalance',
    width: 250,
    textEllipsis: true,
    customCell: driver => {
      const creditWallet = get(driver, 'driverWallet.creditWallet', null);
      const currencyISO = get(creditWallet, 'currencyISO', '');

      if (!creditWallet || !currencyISO) return 'N/A';

      const creditBalance = CurrencyFormater.format(
        creditWallet.value || 0,
        { code: currencyISO }
      );

      return (
        <OverlayTrigger
          placement="top"
          delayShow={300}
          overlay={
            <Tooltip id="cash-balance-tooltip">
              <p>{creditBalance}</p>
            </Tooltip>
          }
        >
          <span>{creditBalance || ''}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    key: 'cashBalance',
    label: 'driver.cash_balance',
    sortName: 'cashBalance',
    customCell: driver => {
      const cashWallet = get(driver, 'driverWallet.cashWallet', null);
      const currencyISO = get(cashWallet, 'currencyISO', '');

      if (!cashWallet || !currencyISO) return 'N/A';

      const currentBalance = get(cashWallet, 'currentBalance', 0);
      const pendingBalance = get(cashWallet, 'pendingBalance', 0);
      const cashBalance = CurrencyFormater.format(
        currentBalance - pendingBalance,
        { code: currencyISO }
      );

      return (
        <OverlayTrigger
          placement="top"
          delayShow={300}
          overlay={
            <Tooltip id="cash-balance-tooltip">
              <p>{cashBalance}</p>
            </Tooltip>
          }
        >
          <span>{cashBalance || ''}</span>
        </OverlayTrigger>
      );
    }
  },
  {
    key: 'referralCode',
    label: 'customer.referral_code'
  },
  {
    key: 'migrationStatus',
    label: 'customer.Migration_Status',
    width: 150
  },
  {
    key: 'driverInfo.stripeConnectStatus',
    label: 'driver.stripeConnectStatus',
    width: 150,
  },
  {
    key: 'profileStatus',
    label: 'driver.profileStatus',
    width: 150
  },
  {
    key: 'driverStatus',
    label: 'driver.Status',
    width: 100
  },
  {
    key: 'actions',
    label: 'driver.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions'
  }
];

export const DriverActionMenuItem = {
  Active: [
    { label: 'driver.Edit', eventKey: 0 },
    { label: 'driver.Deactivate', eventKey: 1 }
  ],
  Inreview: [
    { label: 'driver.Edit', eventKey: 0 },
    // { label: 'driver.Activate', eventKey: 1 },
    { label: 'driver.Deactivate', eventKey: 2 },
    // { label: 'driver.Delete', eventKey: 4 }
  ],
  InreviewAndInProgress: [
    { label: 'driver.Edit', eventKey: 0 },
    { label: 'driver.Deactivate', eventKey: 2 },
  ],
  Inactive: [
    { label: 'driver.Edit', eventKey: 0 },
    { label: 'driver.Activate', eventKey: 1 },
    // { label: 'driver.Delete', eventKey: 4 }
  ],
  disableActivateDriver: [
    { label: 'driver.Edit', eventKey: 0 }
  ]
};
