export const DriverFieldsColumn = [
    {
      key: 'FieldKey',
      label: 'driverFields.FieldKey',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
      fixed: true,
    },
    {
      key: 'FieldName',
      label: 'driverFields.FieldName',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'CommandCenter',
      label: 'driverFields.CommandCenter',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'SignUp',
      label: 'driverFields.SignUp',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'CompanyTransport',
      label: 'driverFields.CompanyTransport',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'CompanyDelivery',
      label: 'driverFields.CompanyDelivery',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'IndividualTransport',
      label: 'driverFields.IndividualTransport',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'IndividualDelivery',
      label: 'driverFields.IndividualDelivery',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'Require',
      label: 'driverFields.Require',
      cellClass: 'cell-align-toolbar',
      headerClass: 'header-align-toolbar',
    },
    {
      key: 'Actions',
      label: 'driverFields.Actions',
      width: 100,
      align: 'right',
      cellClass: 'table-actions',
    },
  ];
  