import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FormGroup, FormControl, Form, Image } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';

import * as driverAutocompleteActions from '../../../actions/driverAutocompleteActions';
import * as newbookingActions from '../../../actions/newbookingAction';
import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../components/validator';
import timeIcon from '../../../assets/images/icons/time.svg';
import { isCanAssignOfflineDriver } from '../../../components/bookingDetail/bookFunction/serviceRequest';
import { DriverCalendarInfo } from '../../../components/bookingDetail';
import {
  CCLiteCommonFunc,
  regexpDriverAutoComplete
} from '../../../utils/commonFunctions';

// const getSuggestionValue = (suggestion) => suggestion.routeName;

// const renderSuggestion = (suggestion) => (
//   <div className="pt pb">
//     <div>{`${suggestion.firstLocation.name} - ${suggestion.secondLocation.name}`}</div>
//   </div>
// );

class DriverSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: [],
      value: '',
      driverReservationDatePriview: 0,
      driverReservationBookings: []
    };
    this.getDriverSuggestions = _.debounce(this.getDriverSuggestions, 200);
  }

  getDriverSuggestionValue(suggestion) {
    return suggestion.driver.name;
  }

  renderDriverSuggestion = (suggestion, { query }) => {
    const { request } = this.props;
    let { status } = suggestion;
    if (!suggestion.online) {
      status = 'offline';
    } else if (suggestion.status == 'inProgress') {
      status = suggestion.currentJob[0]
        ? suggestion.currentJob[0].status
        : suggestion.status;
    }
    
    return (
      <div className={`suggestion-content driver-assign ${status}`}>
        <div className="status" />
        <div className="info">
          <span className="">
            {`${suggestion.firstName} ${suggestion.lastName}${suggestion.company ? ` / ${suggestion.company.companyName}` : ''
              }`}
          </span>
          <span>{`${suggestion.vehicle.plateNumber} / ${suggestion.phone}`}</span>
        </div>
        {request.pickUpTime !== "Now" &&
          <div
            className="calendar"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleDriverCalendarClick(suggestion);
            }}
          >
            <Image src={timeIcon} />
          </div>
        }
      </div>
    );
  }

  handleDriverCalendarClick = driver => {
    const { request, selectedFleet } = this.props;
    const fromDate = moment(request.pickUpTime).add(-1, 'days');
    const toDate = moment(request.pickUpTime).add(1, 'days');
    this.props.newbookingActions
      .getDriverReservationBooking({
        fleetId: selectedFleet.fleetId,
        driverId: driver.driver._id,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString()
      })
      .then(data => {
        if (data.ok && data.res) {
          this.setState({
            isShowDriverCalendar: true,
            driverReservationBookings: data.res,
            driverCalendarViewing: driver
          });
        }
      });
  };

  handleCloseDriverCalendarClick = e => {
    // this.state.driversuggestions = [];
    this.setState({
      isShowDriverCalendar: false,
      driverReservationBookings: [],
      driverReservationDatePriview: 0,
      driverCalendarViewing: null
    });
  };

  handleDriverReservationBookingDateChange = num => {
    this.setState({
      driverReservationDatePriview: this.state.driverReservationDatePriview + num
    });
  };

  getDriverSuggestionValue = (suggestion) => suggestion.driver.name;

  onDriverSuggestionsFetchRequested = ({ value }) => {
    this.getDriverSuggestions(value);
  };

  // driver auto complete render and handleAddressRemoved
  getDriverSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    const {
      request,
      selectedFleet,
      bookInfo,
      companyId,
      commonData: { carTypeFood, carTypeMart },
    } = this.props;
    const searchObject = {
      fleetId: selectedFleet.fleetId,
      str: escapedValue,
    };
    const regex = new RegExp(`\\b${escapedValue}`, 'i');
    const offlineDriver = isCanAssignOfflineDriver(
      selectedFleet,
      request.pickUpTime
    );

    let carTypeFoodMart = bookInfo.jobType === 'food' ? carTypeFood : carTypeMart;
    let carTypeSelected = carTypeFoodMart.find(carType =>
      carType.vehicleType == bookInfo.request.vehicleTypeRequest
    );

    this.props.driverAutocompleteActions
      .driverAutocomplete({
        fleetId: selectedFleet.fleetId,
        str: escapedValue,
        pickUpTime: request.pickUpTime === "Now" ? "Now" : moment(request.pickUpTime || null).format('YYYY-MM-DD HH:mm'),
        vehicleTypeId: carTypeSelected && carTypeSelected._id,
        companyId,
        offlineDriver,
      })
      .then((data) => {
        // const drivers = data.res && data.res.list.filter(
        //   (person) =>
        //     !escapedValue ||
        //     regex.test(this.getDriverSuggestionValue(person)) ||
        //     regex.test(person.vehicle.plateNumber) ||
        //     regex.test(person.phone) ||
        //     regex.test(person.firstName) ||
        //     regex.test(person.lastName)
        // ) || []

        const drivers = data.res && regexpDriverAutoComplete(data.res.list, escapedValue);
        this.setState({
          drivers
        });
      })
  };

  escapeRegexCharacters = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  onDriverSuggestionsClearRequested = () => {
    this.setState({
      driversuggestions: [],
    });
  };

  handleDriverSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.props.onChangeDrvInfo(Object.assign(this.props.drvInfo, {
      ...suggestion,
      fullName: suggestionValue
    }))
  };

  handleDriverChanged = (e) => {
    const updateData = { fullName: e.target.value }
    if (!e.target.value) {
      updateData.phone = ""
    }
    this.props.onChangeDrvInfo(updateData)
  };

  render() {
    const { drivers, value } = this.state;
    const {
      valid,
      isSubmited,
      validatorCallback,
      request,
      drvInfo,
      dispatchType,
      isDispatchDisable,
      selectedFleet,
      bookInfo
    } = this.props;
    // const autoSuggestDisabled = isDispatchDisable || dispatchType === 0 || !carType;
    const autoSuggestDisabled = isDispatchDisable || dispatchType === 0;
    return (
      <FormGroup
        className={`assign-driver-container ${
          !isSubmited ? null : valid.dispatch_driver === false ? 'error' : null
        }`}
      >
        <Autosuggest
          suggestions={drivers}
          onSuggestionsFetchRequested={this.onDriverSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onDriverSuggestionsClearRequested}
          getSuggestionValue={this.getDriverSuggestionValue}
          renderSuggestion={this.renderDriverSuggestion}
          onSuggestionSelected={this.handleDriverSelected}
          shouldRenderSuggestions={() => true}
          inputProps={{
            className: 'form-control form-custom',
            value: drvInfo.fullName,
            placeholder: autoSuggestDisabled ? I18n.t('bookingdetail.No_Driver_Yet') : I18n.t('bookingdetail.Search_here'),
            onChange: this.handleDriverChanged,
            disabled: autoSuggestDisabled,
          }}
        />

        <Validator
          callback={validatorCallback}
          id="dispatch_driver"
        >
          <ValidCase
            valid={drvInfo.phone || dispatchType !== 1}
            hide={!isSubmited}
            message={I18n.t('messages.commonMessages.Required_field')}
          />
        </Validator>
        <DriverCalendarInfo
          isShowDriverCalendar={this.state.isShowDriverCalendar}
          driverReservationBookings={this.state.driverReservationBookings}
          driverReservationDatePriview={this.state.driverReservationDatePriview}
          handleDriverReservationBookingDateChange={this.handleDriverReservationBookingDateChange}
          data={request}
          onClose={this.handleCloseDriverCalendarClick}
          driverCalendarViewing={this.state.driverCalendarViewing}
          fieldKey="pickUpTime"
        />
        {selectedFleet.driverDeposit
          && dispatchType == 1
          && selectedFleet.warningAmount
          && drvInfo
          && drvInfo.driverId
          && drvInfo.driverId.driverInfo
          && drvInfo.driverId.driverInfo.creditBalances
          && CCLiteCommonFunc.getValueOfMultiCurrencies(
            drvInfo.driverId.driverInfo.creditBalances,
            bookInfo.currencyISO,
            'value'
          )
          <= CCLiteCommonFunc.getValueOfMultiCurrencies(
            selectedFleet.warningAmount,
            bookInfo.currencyISO,
            'value'
          ) ? (
          <Translate
            value="newbooking.Driver_credit_limited_balance_warning"
            className="text-warning"
          />
        ) : null}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    bookInfo: state.bookingDetail.data,
    companyId: state.commonData.companyId,
    routeSelected: state.intercityBooking.common.routeSelected,
    commonData: state.commonData,
    companyVehicleTypes: state.intercityBooking.common.companyVehicleTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    driverAutocompleteActions: bindActionCreators(driverAutocompleteActions, dispatch),
    newbookingActions: bindActionCreators(newbookingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(DriverSelect);
