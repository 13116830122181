import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { importCustomers } from "../../actions/customerAction";
import { Validation } from "../../utils/commonFunctions";
import { Translate, I18n } from "react-redux-i18n";
import { bindActionCreators } from "redux";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import {
  Modal,
  FormGroup,
  Form,
  Button
} from "react-bootstrap";

import UploadFile from '../../components/upload/upload';

const FILE_ACCEPTED = ".xls, .xlsx";
const MAX_FILE_SIZE = 5000000;
class Import extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.handleImportCustomerClick = this.handleImportCustomerClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  handleFileChange = (data, error) => {
    let file = null
    if (data) file = data.file
    this.setState({
      file: file
    });
    if (error) {
      if (error === "INVALID_EXTENSION") {
        this.context.notification("error", I18n.t("driver.Invalid_file"));
      } else if (error === "INVALID_FILE_SIZE") {
        this.context.notification(
          "error",
          I18n.t("driver.File_size_larger_than_5Mb")
        );
      }
    }
  }

  handleImportCustomerClick() {
    if (!this.state.file) {
      return;
    }
    var data = new FormData();
    data.append("fleetId", this.props.auth.selectedFleet.fleetId);
    data.append("file", this.state.file);
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.importCustomers(data).then(respone => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (respone.ok) {
        this.context.notification(
          "info",
          I18n.t("customer.IMPORT_CUSTOMER_SUCCESS")
        );
        this.setState({
          importSuccess: true,
          total: respone.res.total,
          ignored: respone.res.ignored,
          created: respone.res.created
        });
        this.props.onSuccess();
      } else if (respone.error) {
        this.context.notification(
          "error",
          I18n.t("customer.Info_edit_error"),
          I18n.t("errors." + respone.error.errorCode)
        );
      }
    });
  }

  closeDialog() {
    this.setState({
      importSuccess: false,
      total: 0,
      ignored: 0,
      created: 0
    });
    this.props.closeDialog();
  }

  render() {
    if (this.props.isShow) {
      return (
        <Modal
          show={true}
          onHide={this.closeDialog}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="card-dialog"
          className="confirm"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="customer.ADD_CUSTOMER" />
            </Modal.Title>
            {/* <button type="button" className="close" aria-label="Close" onClick={this.closeDialog}><span aria-hidden="true">×</span></button> */}
          </Modal.Header>
          <Modal.Body className="clearfix text">
            {this.state.importSuccess ? (
              <FormGroup>
                <Translate value="customer.Number_successful_imports" />{" "}
                <b>{this.state.created}</b>
                <br />
                <Translate value="customer.Number_skipped" />{" "}
                <b>{this.state.ignored}</b>
              </FormGroup>
            ) : (
              <FormGroup className="mb0">
                <div className="body-import">
                  <Form.Label>
                    <Translate value="customer.File" />
                  </Form.Label>
                  <a href={`${process.env.REACT_APP_S3_SERVER}/templates/Customer_Import_Template_v2.xlsx`} className="linkTemplate"><Translate value="customer.Download_template" /></a>
                </div>
                <UploadFile
                  id="customer_import_file"
                  name={"customer_import_file"}
                  onChange={this.handleFileChange}
                  accepts={FILE_ACCEPTED}
                />
              </FormGroup>
            )}
          </Modal.Body>
          {this.state.importSuccess ? null : (
            <Modal.Footer>
              <div className="">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <Button
                    className="btn-save mr-md"
                    onClick={this.handleImportCustomerClick}
                  >
                    <Translate value="customer.Save" />
                  </Button>
                ) : null}
                <Button className="btn-cancel" onClick={this.closeDialog}>
                  <Translate value="customer.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return null;
    }
  }
}

Import.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission
  };
}
function mapDispatchToProps(dispatch) {
  return {
    importCustomers: options => {
      return dispatch(importCustomers(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Import);
