import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import XLSX from "xlsx";

import './upload.scss';
import './uploadImage.scss';

const ACCEPTED_FILES = '.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip';
const MAX_FILE_SIZE = 5;

class UploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: '',
      file: '',
      value: null,
      disabled: false
    };
    // this.inputElement = React.createRef();
    this.fileInput = React.createRef()
  }

  isValidImageFile = file => {
    const validExtensions = (this.props.accepts || ACCEPTED_FILES).split(', ');
    const maxFileSize = this.props.maxFileSize || MAX_FILE_SIZE;
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'), file.name.length) || file.name;
    if (this.props.onlyImage) {
      const isImage = file.type.includes('image');
      if (!isImage) return { status: false, error: 'INVALID_EXTENSION' };
    } else if (validExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
      return { status: false, error: 'INVALID_EXTENSION' };
    }
    if (file.size > maxFileSize * 1024 * 1000) {
      return { status: false, error: 'INVALID_FILE_SIZE' };
    }
    return { status: true };
  };

  handleFileChange = e => {
    e.preventDefault();
    const { target } = e;
    if (!target.files.length) return false;
    const file = target.files[0];
    const validateFile = this.isValidImageFile(file);

    if (validateFile.status) {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      if (file) {
        reader.onload = e => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const dataFile = XLSX.utils.sheet_to_json(ws);
          var regPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/;
          var phones = dataFile.reduce((newArr, item) => {
            if (regPhone.test(item['Phone number (required)'])) {
              newArr.push(`+${item['Phone number (required)']}`)
            }
            return newArr;
          }, []);
          if (this.props.maxRows) {
            if (this.props.maxRows >= phones.length) {
              this.setState({
                file,
                result: reader.result
              })
              this.props.handleFileChange({
                phones: phones,
                fileName: file.name,
                file: file
              })
            } else {
              this.context.notification(
                "error",
                // I18n.t("errors." + data.error.errorCode)
                `The import file must be less than ${this.props.maxRows} rows`
              );
            }
          } else {
            this.setState({
              file,
              result: reader.result
            })
            this.props.handleFileChange({
              phones: phones,
              fileName: file.name,
              file: file
            })
          }
          this.props.loadingActions.hideLoadingSpiner();
        };
        
        this.props.loadingActions.showLoadingSpiner();
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      }
    } else {
      this.handleNoFileSelected(validateFile.error);
    }
  };

  handleNoFileSelected = (error, removeFile = false) => {
    if (this.fileInput && this.fileInput.current) {
      this.fileInput.current.value = '';
    }
    this.setState({ file: '', result: '' }, () => {
      this.props.handleFileChange(null, error);
      if (removeFile && this.props.removeFileCallback) this.props.removeFileCallback();
    });
  };

  render() {
    const {
      id, accepts, className, disabled
    } = this.props;
    const { file } = this.state;
    return (
      <div className="qup-upload-wrapper">
        <div
          className={`form-control upload-container ${className || ''}${file ? ' selected' : ''}${
            disabled ? ' disabled' : ''
            }`}
        >
          <span className={`text-inner`}>
            {file ? file.name : I18n.t('upload_document.NO_FILE_CHOSEN')}
          </span>
          {
            file ? 
            (
              <div
                className="upload-icon remove"
                onClick={() => this.handleNoFileSelected(null, true)}
                disabled={disabled}
              />
            ) : (
              <div
                className="upload-icon upload"
                onClick={() => this.fileInput.current.click()}
                disabled={disabled}
              />
            )
          }
          <input
            type="file"
            name="importMessage"
            id={id}
            // value={this.state.value}
            accept={accepts || ACCEPTED_FILES}
            className="upload-input"
            onChange={this.handleFileChange}
            ref={this.fileInput}
            disabled={this.state.disabled}
          />
        </div>
      </div>
    );
  }
}

UploadFile.contextTypes = {
  notification: PropTypes.func
};

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  accepts: PropTypes.string,
  maxFileSize: PropTypes.number,
  className: PropTypes.string,
  customTemplate: PropTypes.any,
  existFile: PropTypes.object // for edit case
};

export default UploadFile;
