import React, { useState } from 'react'
import { Modal, Image } from 'react-bootstrap';
import Carousel from 'react-images';
import { I18n } from 'react-redux-i18n';
import closeIcon from '../../../assets/images/icons/close.svg';

export default function DisplayPhoto({ itemData }) {

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [selectedIndex, setSelectedIndex]   = useState(1)

  const toggleLightbox = (selectedIndex) => {
    const newSelectedIndex = selectedIndex || 0
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(newSelectedIndex)
  }
  const photoList = itemData.photoURL || []
  
  return (
    <>
      {
        photoList.length > 0 &&
        <div className="orderItemPackage">
          <div className="rowInfoPackage">
            <span className="RIPLeft">{itemData.title ? itemData.title : "Photos"}</span>
            <span className="RIPRight avatarGr">
              {
                photoList.map((url, id) => (
                  <img
                    onClick={() => toggleLightbox(id)}
                    src={url}
                    key={id}
                  />
                ))
              }
              {
                <Modal
                  show={lightboxIsOpen}
                  className={"modalPhotoDelivery"}
                >
                  <Modal.Header>
                    <Image src={closeIcon} onClick={e => toggleLightbox(selectedIndex, e)} className="close" />
                  </Modal.Header>
                  <Modal.Body>
                      <Carousel 
                        views={photoList.map((url) => ({source: url}))}
                        currentIndex={selectedIndex}
                      />
                  </Modal.Body>
                </Modal>
              }
            </span>
          </div>
        </div>
      }
    </>
  )
}


DisplayPhoto.defaultProps = {
  itemData  : {}
}
