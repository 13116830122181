import React, { useMemo } from 'react';
import HeaderGr from '../../../../drivers/components/headerGr';
import { I18n } from 'react-redux-i18n';
import SupplierUserForm from './SupplierUserForm';

const CreateSupplierUser = (props) => {
  const breadcrumbs = useMemo(
    () => [
      {
        path: '/supplier',
        name: I18n.t('supplier.Supplier'),
      },
      {
        path: `/supplier/detail/${props.supplierId}/users`,
        name: I18n.t('supplier.detailSupplier'),
      },
      {
        path: `/supplier/detail/${props.supplierId}/${
          props?.location?.query?.userId
            ? `edit-user?userId=${props?.location?.query?.userId}`
            : 'create-user'
        }`,
        name: I18n.t(
          `${
            props?.location?.query?.userId
            ? 'supplier.userDetail'
            : 'supplier.addUser'
          }`
        ),
      },
    ],
    []
  );

  return (
    <div style={{ padding: '10px', overflow: 'auto', height: '100%' }}>
      <HeaderGr
        breadcrumbs={breadcrumbs}
        navigate={props.router}
        title={I18n.t(props?.location?.query?.userId
          ? 'supplier.userDetail'
          : 'supplier.addUser')}
      />
      <SupplierUserForm
        supplierId={props.supplierId}
        location={props.location}
        router={props.router}
        userId={props?.location?.query?.userId || ''}
      />
    </div>
  );
};

export default CreateSupplierUser;
