export const CueColumns = [
  {
    key: "bookId",
    label: "cue.Bookingid",
    fixed: true,
    width: 200,
    cellClass: "status-column cell-align-toolbar",
    headerClass: "status-colum-header header-align-toolbar",
    noPadding: true,
    isAlwayShow: true,
  },
  {
    key: "externalInfo.bookingReference",
    label: "search_by.ExternalID",
    fixed: true,
    width: 160,
    hideWithSupplierPermission: true
  },
  {
    key: "networkType",
    label: "cue.networkType",
    width: 130,
    hideWithSupplierPermission: true
  },
  {
    key: "bookingType",
    label: "cue.Booking_type",
    textEllipsis: true,
    width: 180
  },
  {
    key: "serviceType",
    label: "report.result.bookingDetails.serviceType",
    textEllipsis: true,
    width: 250
  },
  {
    key: "groupId",
    label: "cue.GroupId",
    width: 120,
    hideWithSupplierPermission: true
  },
  {
    key: "status",
    label: "cue.Status",
    width: 180,
  },
  {
    key: "creationTime",
    label: "cue.creationTime",
    width: 190,
    
  },
  {
    key: "pickupDropOff",
    label: "cue.Pickup_Drop_Off",
    width: 190
  },
  {
    key: "pickupDropOffLocalTime",
    label: "cue.Pickup_Drop_Off_Local_Time",
    width: 190,
  },
  {
    key: "expectedTime",
    label: "cue.expectedTime",
    width: 190,
    hideWithSupplierPermission: true
  },
  {
    key: "location",
    label: "cue.Pickup_Location_Destination",
    textEllipsis: true,
    width: 300
  },
  {
    key: "intercityRoute",
    label: "cue.Intercity_Route",
    textEllipsis: true,
    width: 170
  },
  {
    key: "airportPickup",
    label: "cue.Airport_Pickup",
    width: 170
  },
  {
    key: "flightStatus",
    label: "cue.Flight_Status",
    customCell: doc => {
      const flightInfo = doc.request && doc.request.moreInfo && doc.request.moreInfo.flightInfo || {}
      const { flightNumber = '', departuretime = '', actualarrivaltime = 0, actualdeparturetime = 0} = flightInfo
      // Do not flight Info
      if(!flightNumber) return ''
      // have flight Info but not apply flight API
      if(!departuretime) return 'Not Verified'

      // have flight Info and apply flight API
      if (flightInfo.lastEventCode === 'diverted') return 'Diverted'
      if(actualarrivaltime > 0) return 'Arrived'
      if(actualdeparturetime > 0) return 'Active'
      let status = 'Scheduled'
      if(flightInfo.lastEventCode) {
        switch (flightInfo.lastEventCode) {
          case 'filed':
            status = `Filed`;
            break;
          case 'change':
            status = `Change`;
            break;
          case 'cancelled':
            status = `Cancelled`;
            break;
          case 'diverted':
            status = `Diverted`;
            break;
          case 'departure':
            status = `Active`;
            break;
          case 'arrival':
            status = `Arrived`;
            break;
          case 'minutes_out':
            status = `Minute out`;
            break;
          default:
            status = flightInfo.lastEventCode;
            break;
        }
      }
      return status
    },
    width: 190
  },
  
  {
    key: "scheduled",
    label: "cue.Scheduled",
    width: 190
  },
  {
    key: "actual",
    label: "cue.Actual",
    width: 190
  },
  {
    key: "vehicle",
    label: "cue.Vehicle",
    textEllipsis: true,
    width: 250
  },
  {
    key: "driver",
    label: "cue.Driver",
    textEllipsis: true,
    width: 270
  },
  {
    key: "drvInfo.companyName",
    label: "cue.Company",
    width: 150,
    hideWithSupplierPermission: true
  },
  {
    key: "spotTime",
    label: "cue.spotTime",
    width: 180,
    hideWithSupplierPermission: true
  },
  {
    key: "eta",
    label: "cue.ETA",
    width: 120,
    hideWithSupplierPermission: true
  },
  {
    key: "spareTime",
    label: "cue.spareTime",
    width: 120,
    hideWithSupplierPermission: true
  },
  {
    key: "rideType",
    label: "cue.Ride_type",
    width: 130,
    hideWithSupplierPermission: true
  },
  {
    key: "passenger",
    label: "cue.Passenger",
    width: 180,
    textEllipsis: true,
    hideWithSupplierPermission: true
  },
  {
    key: "request.paxNumber",
    label: "cue.Pax",
    width: 100,
  },
  {
    key: "request.luggageNumber",
    label: "cue.Luggage",
    width: 100,
  },
  {
    key: "estimatedFare",
    label: "cue.Estimated_Fare",
    hideWithSupplierPermission: true
  },
  {
    key: "corporateInfo.chargeCode",
    label: "cue.chargeCode",
    textEllipsis: true,
    width: 200,
    hideWithSupplierPermission: true
  },
  {
    key: "corporateInfo.clientCaseMatter",
    label: "cue.clientCaseMatter",
    textEllipsis: true,
    width: 200,
    hideWithSupplierPermission: true
  },
  {
    key: "payment",
    label: "cue.Payment",
    textEllipsis: true,
    width: 170,
    hideWithSupplierPermission: true
  },
  {
    key: "paidStatus",
    label: "bookingdetail.paidStatus",
    textEllipsis: true,
    width: 170,
    hideWithSupplierPermission: true
  },
  {
    key: "request.note",
    label: "cue.Notes",
    textEllipsis: true,
    customCell: doc => doc.request?.notes || doc.request?.note,
    width: 170
  },
  {
    key: "request.operatorNote",
    label: "cue.operatorNote",
    textEllipsis: true,
    width: 170,
    hideWithSupplierPermission: true
  },
  {
    key: 'request.instructions.content',
    label: 'report.result.bookingDetails.instructions',
    summary: 'instructions',
    textEllipsis: true,
    width: 170
  },
  {
    key: "type",
    label: "cue.Type",
    width: 170
  },
  {
    key: "rideSharing",
    label: "cue.Ride_Sharing",
    textEllipsis: true,
    width: 170
  },
  {
    key: "operator.name",
    label: "cue.Operator",
    customCell: doc => doc?.operator?.name || doc?.operator?.userName,
    hideWithSupplierPermission: true
  },
  {
    key: "recurring.batchId",
    label: "search_by.recurring_id",
    hideWithSupplierPermission: true
  },
  {
    key: 'visibility',
    label: 'cue.jobVisibility',
    hideWithSupplierPermission: true
  }
];
