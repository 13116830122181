import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Col,
  FormGroup,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {
  calculatorAmountDue,
  checkEnableStripe,
} from '../../../utils/commonFunctions';
import currencyFormatter from 'currency-formatter';
import Toggle from '../../Toggle';
import copy from 'copy-to-clipboard';
import { checkBookCorporateCard } from '../bookFunction/bookingInfo';
import Verify3dsCard from './Verify3dsCard';
import { code, use } from 'react-dom-factories';
import { CODE_VERIFY_3DS_CARD } from '../../../constants/commondata';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loadingActions from '../../../actions/loadingBarActions'
import { props } from 'bluebird';

const PAYMENT_SUPPORT = [
  'fleetCard',
  'cash',
  'credit',
  'corpCredit',
  'directBilling',
];
const ChargeInvoicingModal = (
  {
    closeChargeModal,
    confirmCharge,
    bookInfo,
    selectedFleet,
    paymentMethodSetting,
    customer,
    corpInfo,
    bookingDetailActions,
    isBookingCompleted,
    auth,
    farmOut,
    farmIn,
    fleetMarkup,
    ...props
  },
  context
) => {
  const [isAmountType, setIsAmountType] = useState(true);
  const [verify3dsInfo, setVerify3dsInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('default');
  const [chargeNumber, setChargeNumber] = useState();
  const [note, setNote] = useState('');
  const [fareInfo, setFareInfo] = useState({
    amountDueAfter: 0,
    amountDue: 0,
    total: 0,
  });
  const [paymentOption, setPaymentOption] = useState([]);

  const calculateFare = useCallback(() => {
    const amountDue = calculatorAmountDue(
      bookInfo,
      isBookingCompleted,
      farmOut,
      fleetMarkup,
      farmIn,
      selectedFleet
    );

    // Trường hợp book đã completed thi charge full
    if (isBookingCompleted) {
      setChargeNumber(amountDue);
      setFareInfo({
        amountDueAfter: 0,
        amountDue,
        chargeValueConverted: amountDue,
      });
      return;
    }

    const chargeValueConverted = isAmountType
      ? chargeNumber
      : (calculatorAmountDue(bookInfo) * chargeNumber) / 100;
    const amountDueAfter = amountDue - chargeValueConverted;
    setFareInfo({
      amountDueAfter,
      amountDue,
      chargeValueConverted,
    });
  }, [isAmountType, chargeNumber]);

  const handleChargeNumberChange = (e) => {
    setChargeNumber(e.target.value);
  };

  useEffect(() => {
    calculateFare();
  }, [chargeNumber, isAmountType]);

  // Handle options payment
  useEffect(() => {
    const { corporateTypes = [], individualTypes = [] } = paymentMethodSetting;
    let payment = (
      bookInfo?.travelerType === 1 ? corporateTypes : individualTypes
    ).filter((pm) => PAYMENT_SUPPORT.includes(pm.key));

    if (!isBookingCompleted) {
      // only support direct invoice with completed booking
      payment = payment.filter((pm) => pm.key !== 'directBilling');
    }

    if (checkEnableStripe(selectedFleet)) {
      // fleet enable Stripe => add payment link
      payment.push({
        key: 'paymentLink',
        type: 'paymentLink',
        value: 'paymentLink',
      });
    }
    if (
      payment.some((pm) => pm.key === 'credit') &&
      customer?.credits?.length > 0
    ) {
      customer?.credits.map((card) => {
        payment.push({
          key: card.localToken,
          type: 'credit',
          userType: 'individual',
          cardType: card?.cardType,
          title: renderTitleCard({ isCorp: false, card}),
          value: card.localToken,
        });
      });
    }
    if (
      payment.some((pm) => pm.key === 'corpCredit') &&
      corpInfo?.credits?.length > 0
    ) {
      corpInfo?.credits.map((card) => {
        payment.push({
          key: card.localToken,
          type: 'credit',
          userType: 'corporate',
          cardType: card?.cardType,
          title: renderTitleCard({ isCorp: true, card}),
          value: card.localToken,
        });
      });
    }

    // customer/corp đã xóa card nhưng trong booking còn lưu.
    const isCorp = checkBookCorporateCard(bookInfo);
    const cardOfBook = isCorp
      ? bookInfo?.corporateInfo?.creditInfo
      : bookInfo?.psgInfo?.creditInfo;
    if (
      cardOfBook?.localToken &&
      !payment.some((c) => c.key === cardOfBook?.localToken)
    ) {
      payment.push({
        key: cardOfBook.localToken,
        type: 'credit',
        value: cardOfBook.localToken,
        userType: isCorp ? 'corporate' : 'individual',
        title: renderTitleCard({ isCorp, card: cardOfBook }),
        ...cardOfBook,
      });
    }

    setPaymentOption(payment);
  }, []);

  const renderTitleCard = ({ isCorp, card }) => {
    return `${isCorp ? `Corporate Card` : `Personal Card`} - ${
      card?.cardType ? card?.cardType?.toUpperCase() : ''
    } *${card?.cardMask?.slice(-4)}`;
  };

  const handleChargeConfirmed = async () => {
    const paymentInfo = paymentOption.find((ob) => ob.key === paymentMethod);
    const params = {
      fleetId: selectedFleet?.fleetId,
      bookId: bookInfo.bookId,
      paymentMethod: paymentInfo.type === 'credit' ? 'credit' : paymentInfo.key,
      token: paymentInfo.type === 'credit' ? paymentInfo.value : '',
      cardType: paymentInfo.type === 'credit' ? paymentInfo.cardType : '',
      cardOwner: paymentInfo.type === 'credit' ? paymentInfo.userType : '',
      chargeNote: note,
      amount: fareInfo.chargeValueConverted,
      operatorId: auth?.user?._id,
    };

    try {
      setIsLoading(true);
      let result = null;
      if (isBookingCompleted) {
        result = await bookingDetailActions.postPaidForBooking(params);
      } else {
        result = await bookingDetailActions.prePaidForBooking(params);
      }
      if (result?.res?.returnCode === 200) {
        handleChargeSuccessfully()
      } else {
        if(CODE_VERIFY_3DS_CARD.includes(result.res.returnCode)) {
          props?.loadingActions.showLoadingSpiner()
          setVerify3dsInfo({
            code: result.res?.returnCode,
            info: result.res?.response
          })
        } else {
          setIsLoading(false);
          context.notification(
            'error',
            result?.res?.response?.message
              ? result?.res?.response?.message
              : 'An error occurred during the payment'
          );
        }
      }
    } catch (error) {
      context.notification('error', 'An error occurred during the payment');
      setIsLoading(false);
    }
  };

  const handleCreatePaymentLinkAndCopy = async () => {
    const params = {
      fleetId: selectedFleet?.fleetId,
      bookId: bookInfo.bookId,
      amount: fareInfo.chargeValueConverted,
      chargeNote: note,
      operatorId: auth?.user?._id,
    };

    try {
      setIsLoading(true);
      let result = null;
      if (isBookingCompleted) {
        result = await bookingDetailActions.getPaymentLinkForPostpaid(params);
      } else {
        result = await bookingDetailActions.getPaymentLinkForPrepaid(params);
      }
      setIsLoading(false);
      if (
        result?.res?.returnCode === 200 &&
        result?.res?.response?.paymentLink
      ) {
        bookingDetailActions.bookingDetailClosed();
        closeChargeModal();
        copy(result?.res?.response?.paymentLink);
        context.notification(
          'success',
          'Payment Link has been coppied successfully!'
        );
      } else {
        context.notification(
          'error',
          result?.res?.response?.message
            ? result?.res?.response?.message
            : 'An error occurred during the payment'
        );
      }
    } catch (error) {
      context.notification('error', I18n.t('General.ServerError'));
      setIsLoading(false);
    }
  };

  const renderInfoFare = () => {
    if (isBookingCompleted) {
      return (
        <div className="fareCharge completedBK">
          <p>
            <span>
              <Translate value="bookingdetail.AmountDueBefore" />
            </span>
            <span>
              {currencyFormatter.format(fareInfo.amountDue, {
                code: bookInfo.currencyISO,
              })}
            </span>
          </p>
        </div>
      );
    }
    return (
      <div className="fareCharge">
        <p>
          <span>
            <Translate value="bookingdetail.AmountDueBefore" />
          </span>
          <span>
            {currencyFormatter.format(fareInfo.amountDue, {
              code: bookInfo.currencyISO,
            })}
          </span>
        </p>
        <p>
          <span>
            {' '}
            <Translate value="bookingdetail.AmountDueAfter" />
          </span>
          <span>
            {currencyFormatter.format(fareInfo.amountDueAfter, {
              code: bookInfo.currencyISO,
            })}
          </span>
        </p>
      </div>
    );
  };

  const handleChargeSuccessfully = () => {
    setIsLoading(false);
    bookingDetailActions.bookingDetailClosed();
    closeChargeModal();
    context.notification(
      'success',
      'This booking has been paid successfully!'
    );
    setVerify3dsInfo({})
  }

  const callBack3dsVerify = (isSuccess) => {
    if(isSuccess) {
      handleChargeSuccessfully()
    } else {
      setIsLoading(false);
      context.notification(
        'error',
        'An error occurred during the payment'
      );
    }
    props?.loadingActions.hideLoadingSpiner()
    setVerify3dsInfo({})
  }

  return (
    <>
      <Verify3dsCard 
        code={verify3dsInfo?.code}
        info={verify3dsInfo?.info}
        auth={auth}
        context={context}
        callBack3dsVerify={callBack3dsVerify}
      />
      <Modal
        show={true}
        backdrop={false}
        dialogClassName="confirm-dialog"
        onHide={closeChargeModal}
        className={'confirm confirmChageInvoicing'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="bookingdetail.chargeCustomer" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderInfoFare()}
          {
            !isBookingCompleted && (
              <div className="chargeNumber">
                <div>
                  <Toggle
                    checked={isAmountType}
                    handleChange={(checked) => {
                      setIsAmountType(checked);
                    }}
                    iconUncheck={'%'}
                    iconCheck={'$'}
                    disabled={isBookingCompleted}
                  />
                </div>
                <FormGroup className="qup-input-group chargeControl">
                  <InputGroup className="single-addon-left">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        {isAmountType ? selectedFleet?.currencies[0]?.symbol : '%'}
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={handleChargeNumberChange}
                      value={chargeNumber}
                      disabled={isBookingCompleted}
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            )
          }
          <FormGroup>
            <FormControl
              as="select"
              className="selectCharge chargeInput"
              value={paymentMethod}
              placeholder="Payment Method"
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value={'default'} key={'default'}>
                {I18n.t('cue.Payment_Method')}
              </option>
              {paymentOption?.map((pm) => {
                if (['credit', 'corpCredit'].includes(pm.key)) return null;
                return (
                  <option value={pm.key} key={pm.key}>
                    {pm.type === 'credit'
                      ? pm.title
                      : I18n.t(`General.patmentMethod_${pm.value}`)}
                  </option>
                );
              })}
            </FormControl>
          </FormGroup>
          <div className="totalCharge">
            <span>Total</span>
            <span>
              {currencyFormatter.format(fareInfo.chargeValueConverted, {
                code: bookInfo.currencyISO,
              })}
            </span>
          </div>
          <FormGroup>
            <FormControl
              type="text"
              className={'form-custom chargeInput'}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder={'Add Note'}
              maxLength={1000}
            />
          </FormGroup>
          <div className="btnGroup">
            <Button
              className="btn-header text-add-header"
              onClick={closeChargeModal}
            >
              <Translate value="invoice.Close" />
            </Button>
            {paymentMethod === 'paymentLink' ? ( // Nếu chọn paymentType = pm link => create new paymentlink and copy
              <Button
                className="btn-save view-button"
                onClick={handleCreatePaymentLinkAndCopy}
                disabled={
                  paymentMethod === 'default' || !(chargeNumber > 0) || isLoading
                }
              >
                {I18n.t('invoice.CopyLink')}
              </Button>
            ) : (
              <Button
                className="btn-save view-button"
                onClick={handleChargeConfirmed}
                disabled={
                  paymentMethod === 'default' || !(chargeNumber > 0) || isLoading
                }
              >
                {I18n.t('invoice.chargeBtn').format(
                  currencyFormatter.format(fareInfo.chargeValueConverted, {
                    code: bookInfo.currencyISO,
                  })
                )}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

ChargeInvoicingModal.contextTypes = {
  notification: PropTypes.func,
};

ChargeInvoicingModal.prototype = {
  confirmCharge: PropTypes.func,
  closeChargeModal: PropTypes.func,
  bookInfo: PropTypes.object,
  selectedFleet: PropTypes.object,
  auth: PropTypes.object,
  isBookingCompleted: PropTypes.bool,
};

ChargeInvoicingModal.defaultProps = {
  closeChargeModal: () => {},
  confirmCharge: () => {},
  bookingDetailActions: () => {},
  bookInfo: {},
};

function mapDispatchToProps(dispatch) {
  return {
    loadingActions: bindActionCreators(loadingActions, dispatch),
  }
}
export default connect(null, mapDispatchToProps)(ChargeInvoicingModal)

