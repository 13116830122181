import React from 'react';
import { FormGroup, FormControl, InputGroup, Row, Col } from 'react-bootstrap';

const InputValue = ({
  _id,
  editable,
  symbol,
  iso,
  onChange,
  values,
  tipDefaultType,
  ...other
}) => {
  const handleChange = (e) => {
    let value = e.target.value;
    if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      onChange(value, iso, _id);
    }
  };
  let value = 0;
  if (tipDefaultType === 'amount') {
    const foundValue = (values || []).find((o) => o.currencyISO === iso);
    value = foundValue ? foundValue.value : 0;
  } else {
    value = values || 0;
  }

  return (
    <Col sm={12} md={4}>
      <FormGroup className="qup-input-group">
        <InputGroup
          className={`single-addon-left ${editable ? 'disabled' : ''}`}
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className="form-custom no-marginBottom"
            disabled={editable}
            onChange={handleChange}
            value={value}
            {...other}
          />
        </InputGroup>
      </FormGroup>
    </Col>
  );
};

class TipDefaultValues extends React.PureComponent {
  render() {
    const {
      defaultValues,
      tipDefaultType = 'amount',
      iso,
      symbol,
      editable,
    } = this.props;

    return (
      <Row className="mt-md">
        {defaultValues.map((value) => (
          <InputValue
            _id={value._id}
            key={value._id}
            iso={iso}
            symbol={tipDefaultType === 'amount' ? symbol : '%'}
            values={
              tipDefaultType === 'amount' ? value.values : value.valuePercent
            }
            editable={editable}
            onChange={this.props.onChange}
            tipDefaultType={tipDefaultType}
          />
        ))}
      </Row>
    );
  }
}

export default TipDefaultValues;
