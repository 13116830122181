import React from 'react';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';

export default class CustomerEmergencyContacts extends React.PureComponent {
  state = {
    contacts: []
  };

  componentDidMount() {
    const { getEmergencyContactCustomer, userId } = this.props;
    getEmergencyContactCustomer({ userId }).then(({ res }) => {
      this.setState({ contacts: res });
    });
  }

  render() {
    const { contacts } = this.state;
    const { selectedFleet } = this.props;
    if (!selectedFleet.SOS || !selectedFleet.SOS.passenger) {
      return null;
    }

    return (
      <div className="emergencyContact">
        <table className="table table-no-pagination cc-table-striped">
          <thead>
            <tr>
              <th>
                <Translate value="customer.Name" />
              </th>
              <th>
                <Translate value="customer.Phone_Number" />
              </th>
            </tr>
          </thead>
        </table>
        <div className="emergencyContact__contactList">
          <table className="table table-no-pagination cc-table-striped">
            <thead>
              <tr>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {!contacts || !contacts.length ? (
                <tr>
                  <td className="text-center" colSpan={2}>
                    <Translate value="reservation.noData" />
                  </td>
                </tr>
              ) : null}
              {contacts.map(contact => (
                <tr>
                  <td>{contact.name}</td>
                  <td>{contact.phone.full}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-center mt-md">
          <Button className="btn-reset ml0" onClick={this.props.closeDialog}>
            <Translate value="bookingdetail.Close" />
          </Button>
        </div>
      </div>
    );
  }
}
