/* global google */
import React, { Component } from 'react'
import defaultStyles from './defaultStyles'
import {
    Image
} from 'react-bootstrap'
import close_icon from './../../assets/images/icons/close_icon.svg'
import _ from "lodash"
import Confirm from "../confirm/Confirm";
import { I18n } from "react-redux-i18n";
import AutoCompleteItem from './AutoCompleteItem';
import { getAutocompleted } from '../../actions/mapProviderAction'
import { getChannelMap } from '../../utils/mapUtils'
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';
import { getLanguageAutoCompleteSearch } from '../../utils/commonFunctions'
import { convertParamAutocomplete } from '../bookingDetail/bookFunction/bookingInfo'

class PlacesAutocomplete extends Component {
    constructor(props) {
        super(props)
        // let session = new google.maps.places.AutocompleteSessionToken();
        let session = uuidv4({ msecs: new Date().getTime() });
        this.state = { autocompleteItems: [], valueChanged: false, sessionToken: session, confirm: null }
        this.autocompleteCallback = this.autocompleteCallback.bind(this)
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.inputonBlurHandle = this.inputonBlurHandle.bind(this);
        this.autocompleteSearchDebounce = _.debounce(this.autocompleteSearch, 1000)
    }

    componentDidMount(props) {
        if(window.google) {
            this.autocompleteService = new google.maps.places.AutocompleteService()
            this.autocompleteOK = google.maps.places.PlacesServiceStatus.OK
            this.OVER_QUERY_LIMIT = google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT
        }
    }

    autocompleteCallback(predictions, status) {
        if (status != this.autocompleteOK) {
            if (status === this.OVER_QUERY_LIMIT) {
                this.setState({
                    confirm: {
                        title: "Warning",
                        body: I18n.t("messages.commonMessages.Over_query_limit"),
                        closeButtonText: I18n.t("General.Retry")
                    }
                });
            } else {
                if (this.props.onError && typeof this.props.onError == 'function') {
                    this.props.onError(status)
                }
                if (this.props.clearItemsOnError) { this.clearAutocomplete() }
                return
            }
        }

        this.setState({
            autocompleteItems: predictions.map((p, idx) => {
                let pointOfInterest = '';
                if(p.types && p.structured_formatting) { 
                    const point_of_interest = p.types && p.types.indexOf("point_of_interest") || -1;
                    if (point_of_interest !== -1) {
                        pointOfInterest = p.structured_formatting.main_text ? p.structured_formatting.main_text : p.structured_formatting.secondary_text;
                    }
                }

                return ({
                    suggestion: p.description,
                    placeId: p.place_id,
                    active: false,
                    index: idx,
                    formattedSuggestion: this.formattedSuggestion(p.structured_formatting),
                    pointOfInterest
                })
            })
        })
    }

    handleSwichKey = () => {
        let storedSeletedKey = localStorage.getItem("indexSelectedKey");
        localStorage.setItem("limitedKey", storedSeletedKey);
        window.location.reload();
    }

    formattedSuggestion(structured_formatting) {
        if(structured_formatting) {
            return { mainText: structured_formatting.main_text, secondaryText: structured_formatting.secondary_text }
        }
        return {}
    }

    clearAutocomplete = (hasNewSession = false) => {
        if(hasNewSession) {
            let newSesion = uuidv4({ msecs: new Date().getTime() });
            this.setState({ autocompleteItems: [], sessionToken: newSesion });
        } else {
            this.setState({ autocompleteItems: [] });
        }
    }

    selectAddress = (address, placeId, pointOfInterest = '') => {
        this.clearAutocomplete(true)
        this.handleSelect(address, placeId, pointOfInterest)
    }

    handleSelect = (address, placeId, pointOfInterest = '') => {
        this.state.valueChanged = false;
        if (this.props.onSelect) {
            this.props.onSelect(address, placeId, pointOfInterest, this.state.sessionToken)
        }
        this.props.onChange(address)
    }

    getActiveItem() {
        return this.state.autocompleteItems.find(item => item.active)
    }

    selectActiveItemAtIndex(index) {
        const activeName = this.state.autocompleteItems.find(item => item.index === index).suggestion
        this.setActiveItemAtIndex(index)
        this.props.onChange(activeName)
    }

    handleEnterKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.handleEnterKeyWithoutActiveItem()
        } else {
            this.selectAddress(activeItem.suggestion, activeItem.placeId)
        }
    }

    handleEnterKeyWithoutActiveItem() {
        if (this.props.onEnterKeyDown) {
            let item = this.state.autocompleteItems[0]
            if (item) {
                this.props.onEnterKeyDown(item.suggestion, item.placeId)
                this.clearAutocomplete()
            }
        } else {
            return //noop
        }
    }

    handleDownKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.selectActiveItemAtIndex(0)
        } else {
            const nextIndex = (activeItem.index + 1) % this.state.autocompleteItems.length
            this.selectActiveItemAtIndex(nextIndex)
        }
    }

    handleUpKey() {
        const activeItem = this.getActiveItem()
        if (activeItem === undefined) {
            this.selectActiveItemAtIndex(this.state.autocompleteItems.length - 1)
        } else {
            let prevIndex
            if (activeItem.index === 0) {
                prevIndex = this.state.autocompleteItems.length - 1
            } else {
                prevIndex = (activeItem.index - 1) % this.state.autocompleteItems.length
            }
            this.selectActiveItemAtIndex(prevIndex)
        }
    }

    handleInputKeyDown(event) {
        const ARROW_UP = 38
        const ARROW_DOWN = 40
        const ENTER_KEY = 13
        const ESC_KEY = 27

        switch (event.keyCode) {
            case ENTER_KEY:
                event.preventDefault()
                this.handleEnterKey()
                break
            case ARROW_DOWN:
                event.preventDefault() // prevent the cursor from moving
                this.handleDownKey()
                break
            case ARROW_UP:
                event.preventDefault() // prevent the cursor from moving
                this.handleUpKey()
                break
            case ESC_KEY:
                this.clearAutocomplete()
                break
        }
    }

    setActiveItemAtIndex(index) {
        this.setState({
            autocompleteItems: this.state.autocompleteItems.map((item, idx) => {
                if (idx === index) {
                    return { ...item, active: true }
                } else {
                    return { ...item, active: false }
                }
            }),
        })
    }

    handleInputChange(event) {
        this.state.valueChanged = true;
        this.props.onChange(event.target.value)
        if (!event.target.value) {
            this.clearAutocomplete()
            return
        }
        this.autocompleteSearchDebounce(event.target.value, this.props.options, this.autocompleteCallback)
    }

    autocompleteSearch = (q, options = {}, callback) => {
        if(this.props.center) {
            options.location = `${this.props.center[0]?.[0]?.[1]},${this.props.center[0]?.[0]?.[0]}`
        }
        getAutocompleted(convertParamAutocomplete({
            q,
            auth: this.props.auth,
            sessiontoken: this.state.sessionToken,
            language: this.props.language,
            bookId: this.props?.data?.bookId,
            options: options
        }))
            .then(response => {
                let res = response.res || {}
                this.autocompleteCallback(res.predictions, res.status)
            })
    }

    autocompleteItemStyle(active) {
        if (active) {
            return { ...defaultStyles.autocompleteItemActive, ...this.props.styles.autocompleteItemActive }
        } else {
            return {}
        }
    }

    autocompleteClassName() {
        if (this.props.classNames) {
            return this.props.classNames.autocompleteContainer || '';
        } else {
            return '';
        }
    }

    renderAutocomplete() {
        const { autocompleteItems } = this.state
        const { styles } = this.props
        if (autocompleteItems.length === 0) { return null }
        return (
            <div
                id="PlacesAutocomplete__autocomplete-container"
                className="autocomplete__placeContainer"
            >
                {
                    autocompleteItems.map((p, idx) => (
                        <AutoCompleteItem
                            key={p.placeId}
                            suggestion={p.suggestion}
                            suggestItem={p.placeId}
                            pointOfInterest={p.pointOfInterest}
                            onMouseDown={this.selectAddress}
                        />
                    ))
                }
            </div>
        )
    }
    inputonBlurHandle(e) {
        if (e.target.value.trim().length > 0) {
            let activeItem = this.getActiveItem()
            if (activeItem) {
                this.handleSelect(activeItem.suggestion, activeItem.placeId)
            } else {
                if (e.target.value.trim().length > 0 && this.state.valueChanged) {
                    this.handleSelect(e.target.value, null)
                }
            }
        } else {
            this.clearAutocomplete()
        }
    }

    renderInput() {
        const { classNames, placeholder, styles, value, autoFocus, inputName, inputId, disabled } = this.props
        return (
            <input
                type="text"
                placeholder={placeholder}
                className={'form-control form-custom-addon'}
                value={value ? value : ""}
                onChange={this.handleInputChange}
                onKeyDown={this.handleInputKeyDown}
                onBlur={this.inputonBlurHandle}
                style={styles.input}
                autoFocus={autoFocus}
                name={inputName || ''}
                id={inputId || ''}
                disabled={disabled || false}
            />
        )
    }

    handleAddressRemoved = () => {
        if (!this.props.disabled) {
            this.props.handleAddressRemoved();
        }
        this.clearAutocomplete()
    }

    render() {
        const { classNames, value, disabled } = this.props
        return (

            <div className={"autoplaces-input " + (disabled ? "disabled " : "") + classNames}>
                {this.renderInput()}
                {
                    (value && !disabled) && <Image
                        className="remove-icon"
                        src={close_icon}
                        onClick={this.handleAddressRemoved}
                    />
                }

                {/* <span className="remove-icon" disabled={this.props.disabled||false}  onClick={this.handleAddressRemoved}>
                    <i disabled={this.props.disabled||false} className="fa fa-remove"></i>
                </span> */}
                {this.renderAutocomplete()}
                <Confirm
                    confirm={this.state.confirm}
                    handleConfirmCloseClick={this.handleSwichKey}
                />
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
      auth: state.auth,
      commonData: state.commonData,
      language: state.i18n
    };
  }

export default connect(
    mapStateToProps
)(PlacesAutocomplete);

// export default PlacesAutocomplete