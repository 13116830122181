import "./creditcard.scss";
import React, { Component } from "react";
import { Translate } from "react-redux-i18n";
class CreditCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let cardDetail = this.props.data.cardMask;
    if (this.props.showCardHolder) {
      let length = this.props.data.cardMask.length;
      cardDetail =
        this.props.data.cardHolder +
        " * " +
        this.props.data.cardMask.substring(length - 4, length);
    }
    return (
      <div className="credit-item">
        {this.props.isShowFP ? <div className="select-box" /> : null}
        <div className="card-icon">
          <i
            id={"creditcard"}
            className={this.props.data.cardType
              .toLowerCase()
              .replace(/\s+/g, "")}
          />
        </div>
        <div className="name">
          <div>{this.props.cardName}</div>
          <div>{cardDetail}</div>
        </div>
        {
          this.props.onlyView ? null : (
            <div className="delete">
          <div
            className="delete-icon"
            onClick={() => {
              this.props.handleDeleteCardClick(this.props.data);
            }}
          />
        </div>
          )
        }
      </div>
    );
  }
}

export default CreditCard;
