import React from 'react';
import _ from 'lodash';
import ReportUtils from '../base/utils';
import { BsStarFill } from 'react-icons/bs';

export const fields = [
  {
    key: 'date',
    label: 'report.result.rating.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.rating.bookingId',
  },
  {
    key: 'driverName',
    label: 'report.result.rating.driverName',
  },
  {
    key: 'driverPhone',
    label: 'report.result.documentExpiry.driverPhone',
  },
  {
    key: 'idPermitType',
    label: 'report.result.idPermitType',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const driverFields = _.get(selectedFleet, 'driverFields', []);
      return !_.find(driverFields, (df) => df.key === 'idType');
    },
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.rating.driverIdPermit',
  },
  {
    key: 'plateNumber',
    label: 'report.result.rating.plateNumber',
  },
  {
    key: 'customerName',
    label: 'report.result.rating.customerName',
  },
  {
    key: 'customerPhone',
    label: 'report.result.rating.customerPhone',
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'stars',
    label: 'report.result.rating.driverRating',
    headerUnit: <BsStarFill className="qup-color" />,
    summary: 'stars',
    summaryMutate: (value) => (value || 0).toFixed(1),
  },
  {
    key: 'vehicleRate',
    label: 'report.result.rating.vehicleRating',
    headerUnit: <BsStarFill className="qup-color" />,
    summary: 'vehicleRate',
    summaryMutate: (value) => (value || 0).toFixed(1),
    isShow: 'carRating',
  },
  {
    key: 'notes',
    label: 'report.result.rating.notes',
  },
];

export const fareFields = [];
