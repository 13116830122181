import moment from 'moment'
import 'moment-timezone'
import React from 'react'
import {
  Form
} from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import TimeZonePicker from '../../../../components/timeZonePicker/timeZonePicker'
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css'

export default class Timezone extends React.Component {
  render() {
    return (
      <div>
        <Form.Label style={{ fontWeight: 'bold' }}><Translate value='report.query.timezone' /></Form.Label>
        <TimeZonePicker
          defaultValue={this.props.defaultValue || moment.tz.guess()}
          onSelected={this.props.handleChangeTimezone}
        />
      </div>
    )
  }
}