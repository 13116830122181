import React, { Component } from 'react';
import { FormGroup, Row, Col, Form } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';

// import { Validation } from '../../../../utils/commonFunctions';
import FormGroupTitle from '../../../formGroupTitile/FormGroupTitle';
import { Validator } from '../../../validator';
import CCInputNumber from '../../../InputNumber';

class PassengerLuggageNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      data,
      valid,
      isSubmited,
      cartypeSelected,
      onChangePaxNumber,
      onChangeLuggageNumber,
      ValidatorCallback,
      isEditable: isEditableProps,
      isHydraBooking
    } = this.props;
    let { paxNumber, luggageNumber } = data;

    /* console.log("PassengerLuggageNumber -> render -> cartypeSelected", cartypeSelected) */
    // check condition show
    const noShowBookFrom = ['Kiosk', 'Web booking', 'mDispatcher'];
    if (!isHydraBooking && (!cartypeSelected || !cartypeSelected.allowPaxNumber || !cartypeSelected.allowLuggageNumber || noShowBookFrom.includes(data.bookFrom))) {
      return null;
    }

    const statusEditable = [
      'confirmed',
      'pending',
      'pre',
      // 'queue',
      // 'offered',
      'booked',
      'arrived',
      'action'
    ];
    const isBookingDetail = !!data.bookId;
    const isEditable = (!data.status || statusEditable.includes(data.status)) && 
      (isEditableProps && isEditableProps(data) || !isBookingDetail);

    if (isBookingDetail) {
      paxNumber = data.request.paxNumber;
      luggageNumber = data.request.luggageNumber;
    }

    let maxPassenger = cartypeSelected && cartypeSelected.maxPassengers
      ? cartypeSelected.maxPassengers
      : undefined
    let maxLuggage =  cartypeSelected && cartypeSelected.maxLuggages
      ? cartypeSelected.maxLuggages
      : undefined
    
    if(isHydraBooking) {
      maxPassenger = cartypeSelected && cartypeSelected.mP
      ? cartypeSelected.mP
      : undefined
      maxLuggage =  cartypeSelected && cartypeSelected.mL
      ? cartypeSelected.mL
      : undefined
    }

    return (
      <div>
        <FormGroupTitle>
          <Translate value="newbooking.PaxLuggageNumber" />
        </FormGroupTitle>
        <Row>
          <Col md={6}>
            <Form.Label>
              <Translate value="newbooking.paxNumber" />
            </Form.Label>
            <FormGroup
              className={
                !isSubmited ? null : valid.paxNumber === false ? 'error' : null
              }
            >
              <CCInputNumber
                placeholder="Passenger"
                value={paxNumber || 1}
                min={1}
                onChange={onChangePaxNumber}
                max={maxPassenger}
                // inputMode="number"
                disabled={!isEditable}
              />
              <Validator callback={ValidatorCallback} id="tip">
                {/* <ValidCase
                  valid={Validation.isInRange(paxNumber, 0, 100)}
                  hide
                  message={I18n.t(
                    'messages.commonMessages.Integer_from_range'
                  ).format('0', '100')}
                /> */}
              </Validator>
            </FormGroup>
          </Col>
          <Col md={6}>
            <Form.Label>
              <Translate value="newbooking.luggageNumber" />
            </Form.Label>
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.luggageNumber === false
                    ? 'error'
                    : null
              }
            >
              <CCInputNumber
                placeholder="Luggage"
                value={luggageNumber || 0}
                min={0}
                onChange={onChangeLuggageNumber}
                max={maxLuggage}
                disabled={!isEditable}
              />
              <Validator callback={ValidatorCallback} id="tip">
                {/* <ValidCase
                  valid={Validation.isInRange(luggageNumber, 0, 100)}
                  hide
                  message={I18n.t(
                    'messages.commonMessages.Integer_from_range'
                  ).format('0', '100')}
                /> */}
              </Validator>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PassengerLuggageNumber;
