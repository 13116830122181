import React, { useMemo } from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import driverIcon from '../../../../../assets/images/icons/driverLinkGreen.svg';
import PropTypes from 'prop-types';
import { DISPATCH_TYPE, STATUS_BOOKING } from '../../../../../constants/commondata';
import CopyNewTabBtn from '../components/CopyNewTabBtn';

const screenDriverPWAPath = {
  offer: 'offers',
  job: 'jobs',
  pending: 'pendings'
}

const DriverLinks = ({ bookInfo, selectedFleet, isPublic }) => {
  const getDriverUrl = () => {
    const driverLinkSetting = selectedFleet?.pwaDriverLink,
      bookId = bookInfo.bookId,
      hasDriver = !!bookInfo?.drvInfo?.phone;
    let path = '';
    if (bookInfo.dispatchType === DISPATCH_TYPE.offer && hasDriver) {
      path = screenDriverPWAPath.pending;
    }
    if (bookInfo.dispatchType === DISPATCH_TYPE.auto && !hasDriver) {
      path = screenDriverPWAPath.offer;
    }
    if (bookInfo.dispatchType === DISPATCH_TYPE.assign && hasDriver) {
      path = screenDriverPWAPath.job;
    }
    if (isPublic === true) {
      return `${driverLinkSetting}/${path}?token=${bookInfo.token}`;
    }
    return `${driverLinkSetting}/${path}?bookId=${bookId}`;
  };

  const driverUrl = useMemo(() => getDriverUrl(), [isPublic]);

  return (
    <CopyNewTabBtn 
      icon={driverIcon}
      text={I18n.t('bookingdetail.driverLink')}
      link={driverUrl}
    />
  );
};

DriverLinks.contextTypes = {
  notification: PropTypes.func,
}

DriverLinks.defaultProps = {
  bookInfo: {},
  selectedFleet: {},
  delivery: false,
};

DriverLinks.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};

export default DriverLinks;
