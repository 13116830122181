import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    Form,
    Button,
    Table,
    Tab,
    Tabs,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";
import * as commonActions from "../../../../actions/commonDataAction";
import { getListBookingsByTrip, getListCompletedBookingsByTrip } from '../../../../actions/tripAction';

import BookingItem from './BookingItem';


class BookingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripId: this.props.tripId || '',
            findBookings: {
                limit: 50,
                page: 0,
                sort: {
                    time: -1
                },
                query: {
                    txtSearch: "",
                    fleetId: "",
                    searchBy: "tripId"
                }
            },
            listBooking: []
        };
    };

    componentDidMount() {
        this.initData();
    };

    initData = () => {
        const { selectedFleet, tripId } = this.props;
        const { findBookings, listBooking } = this.state;
        let bookings = _.cloneDeep(listBooking);
        findBookings.query = {
            ...findBookings.query,
            fleetId: selectedFleet.fleetId,
            txtSearch: tripId,
        };
        this.props.getListBookingsByTrip(findBookings)
            .then(data => {
                if (data.ok) {
                    bookings = bookings.concat(data.res.list);
                    this.setState({
                        listBooking: bookings,
                    })
                };
            });
        this.props.getListCompletedBookingsByTrip(findBookings)
            .then(data => {
                if (data.ok) {
                    const dataCompleted = _.filter(data.res.list, function (o) {
                        return o.status !== "canceledByCC" && o.status !== "canceledByPassenger"
                    });
                    bookings = bookings.concat(dataCompleted);
                    this.setState({
                        listBooking: bookings,
                    })
                };
            });
    };

    render() {
        const { listBooking } = this.state;
        return (
            <div className="table-container">
                <ul className="booking-list">
                    {listBooking.length > 0 ? (
                        listBooking.map((item, index) =>
                            <li key={item.bookId}>
                                <BookingItem
                                    order={index}
                                    data={item}
                                />
                            </li>
                        )) : null}
                </ul>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        commonData: state.commonData,
        selectedFleet: state.auth.selectedFleet,
        corporateCompany: state.corporateCompany,
        language: state.i18n,
        permissions: state.menuHandle.modulePermission,
        trips: state.trips
    };
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        getListBookingsByTrip: options => dispatch(getListBookingsByTrip(options)),
        getListCompletedBookingsByTrip: options => dispatch(getListCompletedBookingsByTrip(options)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingList); 