import React from 'react';
import {
  FormGroup,
  Form,
  Row,
  Col
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import RadioButton from '../../../../components/radioButton/radio';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { ModalCustomizedFaceMaskZone } from '../modals';
import { ValidCase, Validator } from '../../../../components/validator';

class FaceMaskFormGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRange: [
        { key: "30", value: 30 },
        { key: "60", value: 60 },
        { key: "120", value: 120 },
        { key: "180", value: 180 },
        { key: "240", value: 240 },
        { key: "300", value: 300 },
        { key: "360", value: 360 },
        { key: "420", value: 420 },
        { key: "480", value: 480 },
        { key: "720", value: 720 },
        { key: "1440", value: 1440 },
        { key: "2880", value: 2880 },
        { key: "10080", value: 10080 },
      ],
      skipVerifyTimes: [
        { key: "1", value: 1 },
        { key: "2", value: 2 },
        { key: "3", value: 3 }
      ]

    };
  }

  handeIsCustomizedChange = e => {
    const value = e.target.value == 'true';
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('faceMaskAuth.isCustomized', value);
  };

  handleIsTurnOnVerifyCheckChange = e => {
    const { handleUpdateFormData } = this.props;
    let isChecked = e.target.checked;
    handleUpdateFormData('faceMaskAuth.isTurnOnVerify', isChecked);
    if (!isChecked) {
      handleUpdateFormData('faceMaskAuth.isForce', isChecked);
    };
  };

  handleIsForceCheckChange = e => {
    const { handleUpdateFormData } = this.props;
    let isChecked = e.target.checked;
    handleUpdateFormData('faceMaskAuth.isForce', isChecked);
    if (isChecked) {
      handleUpdateFormData('faceMaskAuth.isTurnOnVerify', isChecked);
    };
  };

  handleChangeTimeRangeToVerify = e => {
    this.props.handleUpdateFormData('faceMaskAuth.timeRangeToVerify', e.target.value);
  };

  handleChangeSkipVerifyTimes = e => {
    this.props.handleUpdateFormData('faceMaskAuth.skipVerifyTimes', e.target.value);
  };

  handleChangeNeedToVerify = (key, e) => {
    const { handleUpdateFormData } = this.props;
    let isChecked = e.target.checked;
    if (key === "openApp") {
      handleUpdateFormData('faceMaskAuth.needToVerify.openApp', isChecked);
    };
    if (key === "startReservation") {
      handleUpdateFormData('faceMaskAuth.needToVerify.startReservation', isChecked);
    };
  };

  handleCloseModalCustomizedFaceMaskZone = () => {
    this.setState({ showModalFaceMaskZone: false });
  };

  openModalCustomizedFaceMaskZone = () => {
    this.setState({ showModalFaceMaskZone: true });
  };

  renderFaceMask = () => {
    const { fleet, editable, isSubmited, valid, ValidatorCallback } = this.props;

    return (
      <div>
        <CcCheckbox
          disabled={editable}
          checked={(fleet.faceMaskAuth && fleet.faceMaskAuth.isTurnOnVerify) ? fleet.faceMaskAuth.isTurnOnVerify : false}
          onChange={this.handleIsTurnOnVerifyCheckChange}
          text={I18n.t('generalSetting.turn_on_verify')}
        />
        <CcCheckbox
          disabled={editable}
          checked={(fleet.faceMaskAuth && fleet.faceMaskAuth.isForce) ? fleet.faceMaskAuth.isForce : false}
          onChange={this.handleIsForceCheckChange}
          text={I18n.t('generalSetting.force_to_wear_face_mask')}
        />
        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.time_range_to_verify" />
          </Form.Label>
          <select
            className="form-control form-custom"
            value={(fleet.faceMaskAuth && fleet.faceMaskAuth.timeRangeToVerify) ? fleet.faceMaskAuth.timeRangeToVerify : "1440"}
            onChange={this.handleChangeTimeRangeToVerify}
            disabled={editable}
          >
            {this.state.timeRange.map(item => {
              let time = '';
              var hour = item.value / 60;
              var day = hour / 24;
              if (hour < 1) {
                time = `${item.value} ${I18n.t('generalSetting.minutes')}`;
              } else if (hour < 24) {
                time = hour === 1 ? `${hour} ${I18n.t('generalSetting.hour')}` : `${hour} ${I18n.t('generalSetting.hours')}`;
              } else if (hour >= 24) {
                time = hour === 24 ? `${day} ${I18n.t('generalSetting.day')}` : `${day} ${I18n.t('generalSetting.days')}`;
              };
              return (
                // <option key={item.key} value={item.value}>{item.key} {I18n.t('generalSetting.minutes')}</option>
                <option key={item.key} value={item.value}>{time}</option>
              )
            })}
          </select>
        </FormGroup>

        <FormGroup>
          <Form.Label>
            <Translate value="generalSetting.allow_driver_to_skip_verification_after" />
          </Form.Label>
          <select
            className="form-control form-custom"
            value={(fleet.faceMaskAuth && fleet.faceMaskAuth.skipVerifyTimes) ? fleet.faceMaskAuth.skipVerifyTimes : "2"}
            onChange={this.handleChangeSkipVerifyTimes}
            disabled={editable}
          >
            {this.state.skipVerifyTimes.map(item => {
              let times = '';
              if (item.value <= 1) {
                times = `${item.value} ${I18n.t('generalSetting.time')}`;
              } else {
                times = `${item.value} ${I18n.t('generalSetting.times')}`;
              };
              return (
                <option key={item.key} value={item.value}>{times}</option>
              )
            })}
          </select>
        </FormGroup>

        <FormGroup
          className={`sub-controls no-marginBottom ${
            isSubmited
              ? valid.needToVerify === false
                ? 'error'
                : null
              : null
          }`}
        >
          <Form.Label>
            <Translate value="generalSetting.driver_need_to_verify" />
          </Form.Label>
          <div className="pl-xl">
            <CcCheckbox
              disabled={editable}
              checked={(fleet.faceMaskAuth && fleet.faceMaskAuth.needToVerify.openApp) ? fleet.faceMaskAuth.needToVerify.openApp : false}
              onChange={e => this.handleChangeNeedToVerify("openApp", e)}
              text={I18n.t('generalSetting.open_app')}
            />
            <CcCheckbox
              disabled={editable}
              checked={(fleet.faceMaskAuth && fleet.faceMaskAuth.needToVerify.startReservation) ? fleet.faceMaskAuth.needToVerify.startReservation : false}
              onChange={e => this.handleChangeNeedToVerify("startReservation", e)}
              text={I18n.t('generalSetting.start_reservation')}
            />
          </div>
          <Validator id={'needToVerify'} callback={ValidatorCallback}>
            <ValidCase
              valid={
                fleet.faceMaskAuth.isTurnOnVerify === false ||
                (fleet.faceMaskAuth.needToVerify.openApp === true || fleet.faceMaskAuth.needToVerify.startReservation === true)
              }
              message={I18n.t('generalSetting.choose_at_least_one')}
              hide={!isSubmited}
            />
          </Validator>
        </FormGroup>
      </div>
    );
  };

  renderFaceMaskZoneCustomized = () => (
    <FormGroup className="mh-0" style={{
      marginBottom: '0'
    }}>
      <a
        className="text-active cursor-pointer"
        onClick={this.openModalCustomizedFaceMaskZone}
      >
        <Translate value="generalSetting.FaceMaskCustomized" />
      </a>
    </FormGroup>
  );

  render() {
    const { fleet, editable } = this.props;
    let isCustomized = false;
    if (fleet.faceMaskAuth && fleet.faceMaskAuth.isCustomized) {
      isCustomized = fleet.faceMaskAuth.isCustomized;
    };

    return (
      <div className="group-general">
        <FormGroupTitle className="title mb-sm">
          <Translate value="generalSetting.face_mask_verify" />
        </FormGroupTitle>

        <FormGroup>
          <Row>
            <Col xs={12} className="mb-sm">
              <Form.Label>
                <Translate value="generalSetting.apply" />
              </Form.Label>
            </Col>
            <Col lg={6} md={12} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('generalSetting.faceMask_allZones')}
                inline
                name="isCustomized"
                value="false"
                checked={!isCustomized}
                onChange={this.handeIsCustomizedChange}
                disabled={editable}
              />
            </Col>
            <Col lg={6} md={12} sm={6} xs={12}>
              <RadioButton
                text={I18n.t('generalSetting.faceMask_customized')}
                inline
                name="isCustomized"
                value="true"
                checked={isCustomized}
                onChange={this.handeIsCustomizedChange}
                disabled={editable}
              />
              {isCustomized
                ? this.renderFaceMaskZoneCustomized()
                : null}
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            {
              isCustomized
                ? null
                : (
                  <Col md={12}>
                    {this.renderFaceMask()}
                  </Col>
                )
            }
          </Row>
        </FormGroup>
        {this.state.showModalFaceMaskZone && (
          <ModalCustomizedFaceMaskZone
            show={this.state.showModalFaceMaskZone}
            onCloseModal={this.handleCloseModalCustomizedFaceMaskZone}
            editable={editable}
          />
        )}
      </div>
    );
  }
}

export default FaceMaskFormGroup;
