import _ from 'lodash'
import React from 'react'
import {
  Col,
  Row,
  Form,
  Checkbox
} from 'react-bootstrap'
import { Translate, I18n } from 'react-redux-i18n'
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox'
export default class CheckBoxes extends React.Component {
  render() {
    const valueForAll = _.isNil(this.props.valueForAll) ? 'all' : this.props.valueForAll
    return (
      <Row className="report-checkboxes">
        <Col xs={2} md={2}>
          <Form.Label style={{ fontWeight: 'bold' }}><Translate value={this.props.title} /></Form.Label>
        </Col>
        <Col xs={10} md={10}>
          <Row>
            {this.props.list.map((item, index) => (
              <Col xs={6} md={4} lg={3} key={index}>
                <CcCheckbox checked={this.props.checkedList.includes(item.value) || this.props.checkedList.includes(valueForAll)}
                  name={this.props.name}
                  value={item.value}
                  onChange={this.props.handleOnChange}
                  className='report-checkbox'
                  text={I18n.t(item.label)}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Form.Label style={{ color: 'red', fontSize: 13 }}>
              {this.props.checkedList.length === 0 ?
                <Translate value={`report.query.errorMessage.${this.props.name}`} />
                : ""}
            </Form.Label>
          </Row>
        </Col>
      </Row>
    )
  }
}
