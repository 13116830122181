import React from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import "./style.scss";
import { Translate } from "react-redux-i18n";
import ReactDOM from "react-dom";
import _ from "lodash"

class TableActions extends React.Component {
  constructor(props) {
    super(props);
  }

  handleToggleDropdown = (isOpen, event, rowIndex) => {
    if (!isOpen) return;
    const { tableId } = this.props;
    let rows = [];
    if (tableId) {
      let tableWrapper = document.getElementById(tableId);
      rows = tableWrapper.getElementsByClassName(
        "fixedDataTableRowLayout_rowWrapper"
      );
    } else {
      rows = document.getElementsByClassName(
        "fixedDataTableRowLayout_rowWrapper"
      );
    }

    let rowClick = null;
    _.forEach(rows, (row, index) => {
      if (row.querySelector(`#table-action-dropdown${rowIndex}`)) {
        rowClick = index;
        return false;
      }
    });

    if (rowClick !== null) {
      _.forEach(rows, row => row.style.zIndex = 0)
      rows[rowClick].style.zIndex = 1;
    }
  };

  handleOnSelect = eventKey => {
    let value = parseInt(eventKey);
    this.props.onSelect(isNaN(value) ? eventKey : value, this.props.rowData);
  };

  isDropup = () => {
    const { rowIndex, totalRow, rowHeight, tableHeight } = this.props;
    let listHeight = totalRow * rowHeight;
    if (tableHeight - listHeight > 200) {
      return false;
    }
    return (totalRow - (rowIndex + 1)) * rowHeight < 150;
  };

  render() {
    const { rowIndex, className, menuItems, disabled = false, customeIcon } = this.props;
    return (
      <Dropdown
        id={"table-action-dropdown" + rowIndex}
        onToggle={(isOpen, event, metadata) => this.handleToggleDropdown(isOpen, event, rowIndex)}
        className={"table-actions-dropdown " + (className ? className : "")}
        // dropup={this.isDropup()}
        disabled={disabled}
        onSelect={eventKey => this.handleOnSelect(eventKey)}
      >
        <Dropdown.Toggle>
          {
            customeIcon 
            ? customeIcon
            : <i className="fa fa-reorder" />
          }
        </Dropdown.Toggle>
        <Dropdown.Menu className="actions">
          {menuItems.map(item => (
            <Dropdown.Item eventKey={item.eventKey} key={item.eventKey}>
              <Translate value={item.label} />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

TableActions.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  rowData: PropTypes.object.isRequired,
  rowHeight: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  menuItems: PropTypes.array,
  tableHeight: PropTypes.number,
  disabled: PropTypes.bool
};

export default TableActions;
