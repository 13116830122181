import React from 'react';
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Form
} from 'react-bootstrap';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import { Translate, I18n } from 'react-redux-i18n';
import { Validator, ValidCase } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';

class WriteOffDebtModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '',
      reason: '',
      valid: {}
    };
  }

  ValidatorCallback = (id, valid) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handlePaymentMethodChange = e => {
    this.setState({ type: e.target.value });
  };

  handleReasonChange = e => {
    this.setState({ reason: e.target.value });
  };

  onSubmitForm = () => {
    const { type, reason } = this.state;
    this.props.onApplyWriteOffDebt({ type, reason });
  };

  tranformMethod = paymentMethods => {
    const ignoreMethods = [];
    return paymentMethods.map(method => {
      let { type } = method;
      switch (type) {
        case 'QRCode':
          ignoreMethods.push(type);
          break;
        case 'ApplePay':
          ignoreMethods.push(type);
          break;
        // case 'PaxWallet':
        //   ignoreMethods.push(type);
        //   break;
        case 'TnGeWallet':
          ignoreMethods.push(type);
          break;
        case 'Vipps':
          ignoreMethods.push(type);
          break;
        default:
          break;
      }
      return { ...method, type };
    }).filter(el => {
      return ignoreMethods.findIndex(type => type === el.type) === -1
    });
  };

  render() {
    const { customer, selectedFleet, paymentMethod, isLoading } = this.props;

    let outStanding = (customer.outStanding || []).filter(o => o.amount > 0);
    if (!outStanding.length) {
      const currencyDefault = _.get(selectedFleet, 'currencies[0]');
      outStanding = [
        {
          amount: 0,
          currencyISO: currencyDefault.iso
        }
      ];
    }
    const textOutStanding = outStanding
      .map(o =>
        currencyFormatter.format(o.amount, {
          code: o.currencyISO
        })
      )
      .join(', ');
    let paymentMethods = this.tranformMethod(paymentMethod.individualTypes);
    paymentMethods = paymentMethods.filter(payment => payment.value != 21)
    return (
      <Modal show backdrop dialogClassName="modal-max-600" onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{textOutStanding}</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            className={
              !this.state.isSubmited
                ? null
                : this.state.valid.type
                  ? null
                  : 'error'
            }
          >
            <Form.Label>
              <Translate value="customer.selectPayment" />{' '}
              <span className="require">*</span>
            </Form.Label>

            <FormControl
              as="select"
              className="form-custom"
              placeholder="select"
              onChange={this.handlePaymentMethodChange}
              value={this.state.type}
            >
              <option value="">
                {I18n.t('bookingdetail.Choose_a_payment_method')}
              </option>
              {paymentMethods.map(payment => (
                <option key={payment.type} value={payment.type}>
                  {I18n.t(`General.patmentMethod_${payment.value}`)}
                </option>
              ))}
            </FormControl>
            <Validator id="type" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.state.isSubmited}
                valid={!Validation.isStringEmpty(this.state.type)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
          <FormGroup
            className={
              !this.state.isSubmited
                ? null
                : this.state.valid.reason
                  ? null
                  : 'error'
            }
          >
            <Form.Label>
              <Translate value="cue.Reason" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.handleReasonChange}
              value={this.state.reason}
              placeholder={I18n.t('cue.Please_enter_reason')}
            />
            <Validator id="reason" callback={this.ValidatorCallback}>
              <ValidCase
                hide={!this.state.isSubmited}
                valid={!Validation.isStringEmpty(this.state.reason)}
                message={I18n.t('messages.commonMessages.Required_field')}
              />
            </Validator>
          </FormGroup>
          <Form.Label>
            <i>
              <Translate value="customer.writeOffDebtNotes" />
            </i>
          </Form.Label>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-save mr-md"
            onClick={this.onSubmitForm}
            disabled={
              !CCLiteCommonFunc.isFormValid(this.state.valid) || isLoading
            }
          >
            <Translate value="customer.Apply" />
          </Button>
          <Button className="btn-cancel" onClick={this.props.closeModal}>
            <Translate value="companySetting.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WriteOffDebtModal;
