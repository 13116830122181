import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import QuestionCircleTooltip from '../../components/questionCircleTooltip/QuestionCircleTooltip';
import * as loadingBarActions from './../../actions/loadingBarActions';
import Select from '../../components/select/Select';
import ImportModal from './Import';
import {
  autocompleteCustomer,
  autocompleteDriver,
  sendMessage,
  autocompleteCarType,
  autocompleteMerchant,
} from '../../actions/messageAction';
import * as uploadActions from '../../actions/uploadActions';

import {
  FormGroup,
  FormControl,
  Form,
  Button,
  Modal,
  Alert,
  Col,
  Row,
  InputGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  CCLiteCommonFunc,
  getPermissionCompanyId,
  trimPhoneNumber,
  Validation,
} from '../../utils/commonFunctions';
import { Validator, ValidCase } from '../../components/validator';
import Datetime from '../../components/dateTime/DateTime';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import CCDropDown from '../../components/dropdown/ccDropDown';

import VersionApp from './Components/VersionApp';
import LinkContentInbox from './Components/LinkContentInbox';
import HtmlContentInbox from './Components/HtmlContentInbox';

import { debounce } from 'lodash';
import './newMessage.scss';
import _ from 'lodash';

const driverStatusList = [
  { value: 'active', label: 'driver.Active', tooltip: 'driver.tooltip_active' },
  {
    value: 'completedProfile',
    label: 'driver.in_review_completed',
    tooltip: 'driver.tooltip_in_review_completed',
  },
  {
    value: 'incompletedProfile',
    label: 'driver.in_review_incomplete',
    tooltip: 'driver.tooltip_in_review_incomplete',
  },
  {
    value: 'inprogressProfile',
    label: 'driver.in_review_inProgress',
    tooltip: 'driver.tooltip_in_review_inProgress',
  },
  {
    value: 'inactive',
    label: 'driver.Inactive',
    tooltip: 'driver.tooltip_inactive',
  },
];
const AppType = {
  Driver: 'driver',
  Passenger: 'passenger',
  Merchant: 'merchant',
};

const FilterType = {
  Driver: 'driver',
  CarType: 'carType',
};

const ContentType = ['html', 'link'];

const PlatformType = ['all', 'iOS', 'android'];

const ComparisonVersionAppOption = [
  'is',
  'is_not',
  'is_one_of',
  'is_not_one_of',
  'greater_or_equal',
  'less_or_equal',
];
class NewMessage extends Component {
  constructor() {
    super();
    this.state = {
      isSubmited: false,
      valid: {},
      receiverList: [],
      type: 'Inbox',
      subject: '',
      content: '',
      allUser: true,
      company: 'All',
      status: 'active',
      filterType: 'driver',
      expiryDate: '',
      expiryDateRequire: false,
      phonesImportExcel: [],
      isShowImport: false,
      driverStatus: ['active'],
      versionApp: '',
      platform: 'all',
      zones: [],
      comparisonVersionApp: ComparisonVersionAppOption[0],
      contentType: ContentType[0],
      url: '',
      body: '',
      image: '',
      imageUpload: {},
      languageOption: 'single',
      messages: [],
      multiURLValidation: true,
      multiSubjectValidation: true,
      multiContentValidation: true,
    };
    this.handleAllUserChange = this.handleAllUserChange.bind(this);
    this.handleCustomizedChange = this.handleCustomizedChange.bind(this);
    this.handleLoadOption = this.handleLoadOption.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleChangeLanguageOption =
      this.handleChangeLanguageOption.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleChangeExpiryDateRequire =
      this.handleChangeExpiryDateRequire.bind(this);
    this.handleChangeExpiryDate = this.handleChangeExpiryDate.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleSendMessageClick = this.handleSendMessageClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.onChangeFilterType = this.onChangeFilterType.bind(this);
    this.handleLoadOptionDebounce = debounce(this.handleLoadOption, 500);
    this.checkDriverStatus = this.checkDriverStatus.bind(this);
    this.handleDriverStatusSettingChanged =
      this.handleDriverStatusSettingChanged.bind(this);
    this.handleChangeVersionApp = this.handleChangeVersionApp.bind(this);
    this.handleChangePlatformType = this.handleChangePlatformType.bind(this);
    this.handleChangeComparisonVersionApp =
      this.handleChangeComparisonVersionApp.bind(this);
    this.handleChangeContentType = this.handleChangeContentType.bind(this);
    this.handleChangeLinkForm = this.handleChangeLinkForm.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleChangeImageByLanguage =
      this.handleChangeImageByLanguage.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      // if(nextProps.data.permissions && nextProps.data.permissions.sendinbox) {
      //   this.setState({type : "Inbox"});
      // } else if (nextProps.data.permissions.sendsms){
      //   this.setState({type : "SMS"});
      // };
      this.setState(nextProps.data);
    }
  }

  componentDidMount() {
    let data = this.props.data || {};
    if (data.permissions && data.permissions.sendinbox) {
      this.setState({ type: 'Inbox' });
    } else if (data.permissions.sendsms) {
      this.setState({ type: 'SMS' });
    }
    const permissionCompanyId = getPermissionCompanyId(this.props.auth);
    if (permissionCompanyId) {
      this.setState({ company: permissionCompanyId });
    }
  }

  handleAllUserChange(e) {
    this.setState({ allUser: e.target.value === 'true' });
  }

  handleTypeChange(e) {
    this.setState({
      type: e.target.value,
      contentType: ContentType[0],
    });
  }

  handleStatusChange(e) {
    let status = e.target.value;
    if (status === 'All') {
      this.setState({
        status: status,
        driverStatus: [status],
        receiverList: [],
      });
    } else {
      this.setState({
        status: status,
        driverStatus: [status],
        receiverList: [],
        fileName: '',
        phonesImportExcel: [],
      });
    }
  }

  handleCompanyChange(e) {
    let company = e.target.value;
    if (company === 'All') {
      this.setState({
        company: company,
      });
    } else {
      this.setState({
        company: company,
        receiverList: [],
        fileName: '',
        phonesImportExcel: [],
      });
    }
  }
  handleZoneChange(e) {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      zones: value,
    });
  }

  onChangeFilterType(e) {
    let value = e.target.value;
    this.setState({
      filterType: value,
      receiverList: [],
      fileName: '',
      phonesImportExcel: [],
    });
  }

  handleChangeLanguageOption(e) {
    let value = e.target.value;
    this.setState({
      languageOption: value,
    });
  }

  handleChangeInput(language, key, e) {
    let value = e.target.value;
    let listMessages = _.cloneDeep(this.state.messages);
    let indexItem = listMessages.findIndex(
      (item) => item.language === language
    );
    if (indexItem !== -1) {
      listMessages[indexItem][key] = value;
    } else {
      listMessages.push({
        language,
        [key]: value,
      });
    }
    this.setState({
      messages: listMessages,
      multiURLValidation: true,
      multiSubjectValidation: true,
      multiContentValidation: true,
    });
  }

  handleSubjectChange(e) {
    let subject = e.target.value;
    this.setState({
      subjectValidation: subject ? true : false,
      subject: subject,
    });
  }

  handleContentChange(e) {
    let content = e.target.value;
    this.setState({
      contentValidation: content ? true : false,
      content: content,
    });
  }

  handleChangeExpiryDateRequire(e) {
    this.setState({
      expiryDateRequire: e.target.checked,
      expiryDate: '',
    });
  }

  handleChangeExpiryDate(e) {
    let expiryDate = e._d;
    this.setState({
      expiryDate: moment(expiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    });
  }

  validExpiryDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }
  handleDriverStatusSettingChanged(value) {
    if (!this.state.driverStatus) {
      this.state.driverStatus = [];
    }
    if (value === 'All') {
      if (this.state.driverStatus.length == driverStatusList.length) {
        this.state.driverStatus = [];
      } else {
        this.state.driverStatus = driverStatusList.map((status) => {
          return status.value;
        });
      }
    } else {
      if (this.state.driverStatus.includes(value)) {
        this.state.driverStatus = this.state.driverStatus.filter(
          (status) => status != value
        );
      } else {
        this.state.driverStatus.push(value);
      }
    }
    this.setState({
      driverStatus: this.state.driverStatus,
      driverStatusValidation:
        this.state.driverStatus.length != 0 ? true : false,
      receiverList: [],
    });
  }

  checkDriverStatus(value) {
    if (value == 'All') {
      return this.state.driverStatus.length == driverStatusList.length;
    } else {
      return this.state.driverStatus && this.state.driverStatus.includes(value);
    }
  }

  handleChangeVersionApp(e) {
    this.setState({ versionApp: e.target.value });
  }

  handleChangePlatformType(e) {
    this.setState({ platform: e.target.value });
  }

  handleChangeComparisonVersionApp(e) {
    this.setState({ comparisonVersionApp: e.target.value });
  }

  handleChangeContentType(e) {
    this.setState({ contentType: e.target.value });
  }

  handleChangeLinkForm(key, e) {
    switch (key) {
      case 'url':
        this.setState({ url: e.target.value });
        break;
      case 'subject':
        this.setState({ subject: e.target.value });
        break;
      case 'body':
        this.setState({ body: e.target.value });
        break;
      default:
        break;
    }
  }

  handleImageChange(changeFile, error) {
    const { imageUpload } = this.state;
    const data = changeFile || {};
    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification(
        'error',
        I18n.t('upload_document.Limit_size_upload').format(8)
      );
      return;
    }
    this.setState({
      image: data.result || null,
      imageUpload: {
        ...imageUpload,
        image: data.file || null,
      },
    });
  }

  handleChangeImageByLanguage(language, changeFile, error) {
    const { messages } = this.state;
    const data = changeFile || {};
    // validate file size 8MB
    if (error && error === 'INVALID_FILE_SIZE') {
      this.context.notification(
        'error',
        I18n.t('upload_document.Limit_size_upload').format(8)
      );
      return;
    }
    let listMessages = _.cloneDeep(messages);
    let indexItem = listMessages.findIndex(
      (item) => item.language === language
    );
    if (indexItem !== -1) {
      listMessages[indexItem].image = data.result || null;
      listMessages[indexItem].file = data.file || null;
    } else {
      listMessages.push({
        language,
        image: data.result || null,
        file: data.file || null,
      });
    }
    this.setState({
      messages: listMessages,
    });
  }

  handleUploadFile = (file) => {
    const {
      uploadService,
      auth: { selectedFleet },
    } = this.props;

    if (file) {
      const form = new FormData();
      form.append('photo', file);
      form.append('fleetId', selectedFleet.fleetId);
      form.append('module', 'message');
      return uploadService.uploadPhoto(form).then((res) => {
        if (!res.error) {
          return Promise.resolve(_.get(res, 'res.Location'));
        }
        this.context.notification('error', I18n.t('errors.upload_photo_fail'));
        return Promise.reject({ error: res.error });
      });
    }
    return Promise.resolve();
  };

  handleSendMessageClick() {
    const { timezone } = this.props.auth.selectedFleet;
    const { roles } = this.props;
    const { valid, imageUpload, messages, languageOption, contentType } =
      this.state;
    let promises = [];
    let messagesInvalid = [];
    messagesInvalid =
      contentType === ContentType[0]
        ? messages.filter((msg) => !msg.subject || !msg.content)
        : messages.filter((msg) => !msg.url || !msg.subject || !msg.body);

    this.setState({ isSubmited: true });
    if (!CCLiteCommonFunc.isFormValid(valid)) return;

    if (!this.state.allUser && this.state.receiverList.length === 0) {
      if (this.state.phonesImportExcel == 0) {
        this.setState({
          errorMessage: I18n.t(
            this.state.appType === AppType.Driver
              ? 'message.ERROR_INPUT_RECEIVERLIST'
              : 'message.ERROR_INPUT_CUSTOMER_RECEIVERLIST'
          ),
          receiverListValidation: false,
        });
        return;
      }
    }
    if (
      typeof this.state.driverStatus !== 'undefined' &&
      this.state.driverStatus.length <= 0
    ) {
      this.setState({
        errorMessage: I18n.t('message.PleaseSelectStatusOfDriver'),
        driverStatusValidation: false,
      });
      return;
    }
    if (contentType === ContentType[0] && languageOption === 'single') {
      if (!this.state.subject.trim()) {
        this.setState({
          errorMessage: I18n.t('message.ERROR_INPUT_SUBJECT'),
          subjectValidation: false,
        });
        return;
      }
      if (!this.state.content.trim()) {
        this.setState({
          errorMessage: I18n.t('message.ERROR_INPUT_CONTENT'),
          contentValidation: false,
        });
        return;
      }
    }

    if (languageOption === 'multi' && (!messages || messages.length === 0)) {
      this.setState({
        errorMessage: I18n.t('message.ERROR_INPUT_LEAST_ONE_SUBJECT_CONTENT'),
        multiURLValidation: contentType === ContentType[1] ? false : true,
        multiSubjectValidation: false,
        multiContentValidation: false,
      });
      return;
    }

    if (languageOption === 'multi' && messagesInvalid.length > 0) {
      messagesInvalid.forEach((msg) => {
        let language = I18n.t(`appBanner.${msg.language}`);
        if (contentType === ContentType[0]) {
          if (!msg.subject) {
            this.setState({
              errorMessage: I18n.t(
                'message.ERROR_INPUT_SUBJECT_MULTI_LANGUAGE'
              ).format(language),
              multiSubjectValidation: false,
            });
            return;
          }
          if (!msg.content) {
            this.setState({
              errorMessage: I18n.t(
                'message.ERROR_INPUT_CONTENT_MULTI_LANGUAGE'
              ).format(language),
              multiContentValidation: false,
            });
            return;
          }
        }

        if (contentType === ContentType[1]) {
          if (!msg.url) {
            this.setState({
              errorMessage: I18n.t(
                'message.ERROR_INPUT_URL_MULTI_LANGUAGE'
              ).format(language),
              multiURLValidation: false,
            });
            return;
          }
          if (!msg.subject) {
            this.setState({
              errorMessage: I18n.t(
                'message.ERROR_INPUT_TITLE_MULTI_LANGUAGE'
              ).format(language),
              multiSubjectValidation: false,
            });
            return;
          }
          if (!msg.body) {
            this.setState({
              errorMessage: I18n.t(
                'message.ERROR_INPUT_BODY_MULTI_LANGUAGE'
              ).format(language),
              multiContentValidation: false,
            });
            return;
          }
        }
      });
      return;
    }

    this.props.loadingBarActions.showLoadingSpiner();
    this.setState({ errorMessage: null });
    let data = Object.assign({}, this.state);
    data.fleetId = this.props.auth.selectedFleet.fleetId;
    data.phone = [];
    data.carType = [];

    if (imageUpload.image) {
      promises.push(
        this.handleUploadFile(imageUpload.image).then((dataImageUpload) => {
          if (dataImageUpload && dataImageUpload.error) {
            this.props.loadingBarActions.hideLoadingSpiner();
            return;
          }
          data.image = dataImageUpload;
        })
      );
    }

    if (
      this.state.appType === AppType.Driver &&
      this.state.filterType === FilterType.CarType
    ) {
      data.carType = this.state.receiverList.map((item) => item.value);
    } else {
      if (this.state.appType === AppType.Merchant) {
        data.phone = this.state.receiverList.map((item) => item.phone.full);
      } else {
        data.phone = this.state.receiverList.map((item) => item.phone);
      }
    }

    if (this.state.phonesImportExcel.length > 0) {
      data.phone = this.state.phonesImportExcel;
    }

    data.isImportExcel = this.state.phonesImportExcel.length > 0 ? true : false;

    data.allUser = this.state.allUser;
    delete data.phonesImportExcel;

    if (this.state.expiryDateRequire) {
      data.expiryDate = new Date(
        moment.tz(this.state.expiryDate, timezone).endOf('day').toISOString()
      );
    } else {
      data.expiryDate = '';
    }

    // SL-23661 Customer - Send Inbox for Customer with Operator user
    if (roles.companyId) {
      data.company = roles.companyId;
    }

    // Multi language
    if (languageOption === 'multi' && messages && messages.length > 0) {
      let listImage = _.cloneDeep(messages);
      listImage.forEach((img) => {
        if (img.file) {
          promises.push(
            this.handleUploadFile(img.file).then((imageUpload) => {
              if (imageUpload && imageUpload.error) {
                this.props.loadingBarActions.hideLoadingSpiner();
                return;
              }
              img.image = imageUpload;
              img.file = null;
            })
          );
        }
      });
      data.languages = listImage;
    }

    delete data.expiryDateRequire;
    delete data.isSubmited;
    delete data.valid;
    delete data.permissions;
    delete data.errorMessage;
    delete data.imageUpload;
    delete data.messages;
    data.status = this.state.driverStatus;

    Promise.all(promises).then(() => {
      this.props.sendMessage(data).then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        let res = data.res || {};
        if (data.ok && res.returnCode === 200) {
          this.setState({
            receiverList: [],
            versionApp: '',
            platform: 'all',
            comparisonVersionApp: ComparisonVersionAppOption[0],
            contentType: ContentType[0],
            url: '',
            body: '',
            image: '',
            imageUpload: {},
            type: 'Inbox',
            subject: '',
            content: '',
            allUser: true,
            company: this.getDefaultCompany(),
            status: 'active',
            driverStatus: ['active'],
            fileName: '',
            phonesImportExcel: [],
            expiryDate: '',
            expiryDateRequire: false,
            isSubmited: false,
            valid: {},
            languageOption: 'single',
            messages: [],
          });
          this.props.closeDialog();
          let total =
            res.totalFails || res.totalSuccess
              ? {
                  totalFails: res.totalFails,
                  totalSuccess: res.totalSuccess,
                  appType: this.state.appType,
                }
              : null;
          this.props.onSuccess(total);
        } else {
          if (data.error && data.error.errorCode) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          }
          if (res.returnCode === 304) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.returnCode)
            );
          }
          if (res.error && res.error.errorCode) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.error.errorCode)
            );
          }
        }
      });
    });
  }

  getDefaultCompany = () => {
    const permissionCompanyId = getPermissionCompanyId(this.props.auth);
    if (permissionCompanyId) {
      return permissionCompanyId;
    }
    return 'All';
  };

  handleCancelClick() {
    this.setState({
      receiverList: [],
      versionApp: '',
      platform: 'all',
      comparisonVersionApp: ComparisonVersionAppOption[0],
      contentType: ContentType[0],
      url: '',
      body: '',
      image: '',
      imageUpload: {},
      type: 'Inbox',
      subject: '',
      content: '',
      allUser: true,
      company: this.getDefaultCompany(),
      status: 'active',
      driverStatus: ['active'],
      errorMessage: null,
      subjectValidation: null,
      contentValidation: null,
      driverStatusValidation: null,
      receiverListValidation: null,
      phonesImportExcel: [],
      fileName: '',
      expiryDate: '',
      expiryDateRequire: false,
      isSubmited: false,
      valid: {},
      languageOption: 'single',
      messages: [],
      multiURLValidation: true,
      multiSubjectValidation: true,
      multiContentValidation: true,
    });
    this.props.closeDialog();
  }

  handleLoadOption(input, callback) {
    if (input) {
      let ids = this.state.receiverList.map((item) => item.id);
      if (this.state.appType === AppType.Driver) {
        if (
          this.state.filterType === FilterType.Driver &&
          this.state.driverStatus.length != 0
        ) {
          let params = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            search: input,
            companyId: this.state.company,
            status: this.state.driverStatus,
          };

          if (ids.length) params.ids = ids;

          this.props.autocompleteDriver(params).then((data) => {
            if (data.ok && data.res && data.res.length > 0) {
              let result = { complete: true };
              result.options = data.res.map((item) => {
                item.value = item.fullName + ' - ' + item.phone;
                item.id = item._id;
                return item;
              });
              callback(null, result);
            } else {
              callback(null, null);
            }
          });
        } else if (this.state.filterType === FilterType.CarType) {
          //autocomplete for car type
          this.props
            .autocompleteCarType({
              fleetId: this.props.auth.selectedFleet.fleetId,
              search: input,
            })
            .then((data) => {
              if (data.ok && data.res && data.res.length > 0) {
                let result = { complete: true };
                result.options = data.res.map((item) => {
                  item.value = item.vehicleType;
                  item.id = item._id;
                  return item;
                });
                callback(null, result);
              } else {
                callback(null, null);
              }
            });
        }
      } else if (this.state.appType === AppType.Passenger) {
        let filterOptions = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            str: input,
            status: this.state.status,
          },
          trimPhone = trimPhoneNumber(input, 'no plus');

        if (ids.length) filterOptions.ids = ids;

        if (!Validation.validateSearchingPhone(trimPhone)) {
          filterOptions.name = 1;
        } else {
          filterOptions.str = trimPhone;
        }

        this.props.autocompleteCustomer(filterOptions).then((data) => {
          if (data.ok && data.res && data.res.list.length > 0) {
            let result = { complete: true };
            result.options = data.res.list.map((item) => {
              let phone = item.phone !== item.userId ? item.phone : '';
              item.value = item.firstName + ' ' + item.lastName + ' - ' + phone;
              item.id = item.userId;
              return item;
            });
            callback(null, result);
          } else {
            callback(null, null);
          }
        });
      } else {
        let filterOptions = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            q: input,
          },
          trimPhone = trimPhoneNumber(input, 'no plus');

        //if (ids.length) filterOptions.ids = ids;
        if (this.state.status !== 'All') {
          filterOptions.status = this.state.status === 'active' ? 2 : 3;
        }
        if (Validation.validateSearchingPhone(trimPhone)) {
          filterOptions.q = trimPhone;
        }
        this.props.autocompleteMerchant(filterOptions).then((data) => {
          if (data.ok && data.res && data.res.data.length > 0) {
            let result = { complete: true };
            result.options = data.res.data.map((item) => {
              let phone = item.phone.full;
              item.value = item.firstName + ' ' + item.lastName + ' - ' + phone;
              //item.id = item.merchantId.id;
              return item;
            });
            callback(null, result);
          } else {
            callback(null, null);
          }
        });
      }
    } else {
      callback(null, null);
    }
  }

  handleCustomizedChange(e) {
    this.setState({
      receiverListValidation: e.length > 0,
      receiverList: e,
    });
  }

  renderTypeOption = () => {
    const { sendinbox = false, sendsms = false } = this.state.permissions || {};
    return (
      <FormControl
        className="form-custom"
        as="select"
        value={this.state.type}
        onChange={this.handleTypeChange}
      >
        {sendinbox && sendsms && (
          <option value="All">{I18n.t('message.All')}</option>
        )}
        {sendsms && <option value="SMS">SMS</option>}
        {sendinbox && <option value="Inbox">Inbox</option>}
      </FormControl>
    );
  };

  handleImportClick = () => {
    this.setState({ isShowImport: true });
  };

  closeDialog = () => {
    this.setState({ isShowImport: false });
  };

  handleImportCustomerClick = (data) => {
    this.setState({
      phonesImportExcel: data.phones,
      fileName: data.fileName,
      receiverList: [],
    });
  };

  handleRemoveClick = () => {
    this.setState({
      phonesImportExcel: [],
      fileName: '',
      receiverList: [],
    });
  };

  render() {
    const { localeCode, drvSignUpLanguages, drvSignUpLanguagesFull } =
      this.props.auth.selectedFleet;
    const { type } = this.state;

    if (this.state.isShow) {
      return (
        <>
          <Modal
            show={true}
            onHide={this.handleCancelClick}
            backdrop="static"
            aria-labelledby="contained-modal-title-sm"
            dialogClassName="card-dialog add-new-message-container"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="message.New_message" />
              </Modal.Title>
              {/* <button
               type="button"
               className="close"
               aria-label="Close"
               onClick={this.handleCancelClick}
               >
               <span aria-hidden="true">×</span>
               </button> */}
            </Modal.Header>
            <Modal.Body className="clearfix">
              <FormGroup>
                <Form.Label>
                  <Translate value="message.Type" />
                </Form.Label>
                {this.renderTypeOption()}
                {/* <FormControl
                 className="form-custom"
                 as="select"
                 value={this.state.type}
                 onChange={this.handleTypeChange}
                 >
                 <option value="All">{I18n.t("message.All")}</option>
                 <option value="SMS">SMS</option>
                 <option value="Inbox">Inbox</option>
                 </FormControl> */}
              </FormGroup>
              <FormGroup
                className={
                  this.state.driverStatusValidation === false ? 'error' : null
                }
              >
                <Form.Label>
                  {this.state.appType === AppType.Driver ? (
                    <Translate value="message.StatusOfDriver" />
                  ) : this.state.appType === AppType.Passenger ? (
                    <Translate value="message.StatusOfCustomer" />
                  ) : (
                    <Translate value="message.StatusOfMerchant" />
                  )}
                  <span className="require"> *</span>
                </Form.Label>
                {this.state.appType === AppType.Driver ? (
                  <Row
                    className={`sub-controls ${
                      this.state.driverStatusValidation === false
                        ? 'error'
                        : null
                    }`}
                  >
                    <Col md={12}>
                      <FormGroup>
                        <CcCheckbox
                          checked={this.checkDriverStatus('All')}
                          onChange={() => {
                            this.handleDriverStatusSettingChanged('All');
                          }}
                          text={I18n.t('generalSetting.All')}
                        />
                      </FormGroup>
                    </Col>
                    {driverStatusList.map((status) => {
                      return (
                        <Col md={12} key={status.value}>
                          <FormGroup>
                            <CcCheckbox
                              checked={this.checkDriverStatus(status.value)}
                              onChange={() => {
                                this.handleDriverStatusSettingChanged(
                                  status.value
                                );
                              }}
                              text={I18n.t(status.label)}
                              className="tool-tip"
                            />
                            <QuestionCircleTooltip
                              text={I18n.t(status.tooltip)}
                            />
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <FormControl
                    className="form-custom"
                    as="select"
                    value={this.state.status}
                    onChange={this.handleStatusChange}
                  >
                    <option value="All">{I18n.t('message.All')}</option>
                    <option value="active">{I18n.t('message.Active')}</option>
                    <option value="inactive">
                      {I18n.t('message.In_Active')}
                    </option>
                    {this.state.appType === AppType.Driver ? (
                      <option value="inReview">
                        {I18n.t('message.In_Review')}
                      </option>
                    ) : null}
                  </FormControl>
                )}
              </FormGroup>
              {this.state.appType === AppType.Driver ? (
                <FormGroup>
                  <Form.Label>
                    <Translate value="message.Company" />
                  </Form.Label>
                  <FormControl
                    className="form-custom"
                    as="select"
                    value={this.state.company}
                    onChange={this.handleCompanyChange}
                    disabled={!!getPermissionCompanyId(this.props.auth)}
                  >
                    <option value="All">{I18n.t('message.All')}</option>
                    {[...this.props.commonData.companies, ...this.props.commonData.suppliers].map((cp) => {
                      return (
                        <option key={cp._id} value={cp._id}>
                          {cp.name}
                        </option>
                      );
                    })}
                  </FormControl>
                </FormGroup>
              ) : null}
              <FormGroup>
                <Form.Label>
                  <Translate value="message.Send_to" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={this.state.allUser}
                  onChange={this.handleAllUserChange}
                >
                  <option value="true">
                    {this.state.appType === AppType.Driver
                      ? I18n.t('message.All_drivers')
                      : this.state.appType === AppType.Passenger
                      ? I18n.t('message.All_customers')
                      : I18n.t('message.All_merchants')}
                  </option>
                  <option value="false">
                    {this.state.appType === AppType.Driver
                      ? I18n.t('message.Customized_driver')
                      : this.state.appType === AppType.Passenger
                      ? I18n.t('message.Customized_customer')
                      : I18n.t('message.Customized_merchant')}
                  </option>
                </FormControl>
              </FormGroup>
              {this.state.appType === AppType.Driver &&
                this.state.allUser === false && (
                  <FormGroup>
                    <FormControl
                      className="form-custom"
                      as="select"
                      value={this.state.filterType}
                      onChange={this.onChangeFilterType}
                    >
                      <option value={FilterType.Driver}>
                        {I18n.t('message.Driver_List')}
                      </option>
                      <option value={FilterType.CarType}>
                        {I18n.t('message.Car_Type_List')}
                      </option>
                    </FormControl>
                  </FormGroup>
                )}

              {!this.state.allUser ? (
                <FormGroup
                  className={
                    this.state.receiverListValidation === false ? 'error' : null
                  }
                >
                  <div className="label_import">
                    <div>
                      <Translate value="message.Receiver_list" />
                      <span className="require"> *</span>
                    </div>
                    {this.state.filterType != 'carType' &&
                      (this.state.phonesImportExcel.length == 0 ? (
                        <div
                          onClick={this.handleImportClick}
                          className="text-add-header btn-import"
                        >
                          <Translate value="customer.Import" />
                        </div>
                      ) : (
                        <div
                          onClick={this.handleRemoveClick}
                          className="text-add-header btn-import"
                        >
                          {I18n.t('customer.Delete')}
                        </div>
                      ))}
                  </div>
                  {this.state.phonesImportExcel.length === 0 && (
                    <Select.Async
                      multi={true}
                      valueKey="id"
                      labelKey="value"
                      cache={false}
                      placeholder=""
                      searchPromptText={
                        this.state.appType === AppType.Driver
                          ? this.state.filterType === FilterType.Driver
                            ? I18n.t(
                                'message.Search_driver_name_driver_phone_number'
                              )
                            : I18n.t('message.Search_car_type')
                          : this.state.appType === AppType.Passenger
                          ? I18n.t(
                              'message.Search_customer_name_customer_phone_number'
                            )
                          : I18n.t(
                              'message.Search_merchant_name_merchant_phone_number'
                            )
                      }
                      loadingPlaceholder={I18n.t('message.Searching')}
                      noResultsText={I18n.t('message.No_results')}
                      className="receiver-list custom-select-control"
                      value={this.state.receiverList}
                      loadOptions={this.handleLoadOptionDebounce}
                      onChange={this.handleCustomizedChange}
                      disabled={!this.state.driverStatus.length}
                    />
                  )}
                  {this.state.fileName && (
                    <p className="fileNameImport">{this.state.fileName}</p>
                  )}
                </FormGroup>
              ) : null}

              <VersionApp
                versionApp={this.state.versionApp}
                comparisonVersionApp={this.state.comparisonVersionApp}
                handleChangeVersionApp={this.handleChangeVersionApp}
                handleChangeComparisonVersionApp={
                  this.handleChangeComparisonVersionApp
                }
                ComparisonVersionAppOption={ComparisonVersionAppOption}
                editable={true}
              />

              <FormGroup>
                <Form.Label>
                  <Translate value="message.platform" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={this.state.platform}
                  onChange={this.handleChangePlatformType}
                >
                  {PlatformType.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {I18n.t(`message.platformItem.` + item)}
                      </option>
                    );
                  })}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <Form.Label>
                  <Translate value="Sidebar.Settings.Zone" />
                </Form.Label>
                <FormControl
                  className="form-custom select-zone"
                  as="select"
                  multiple
                  value={this.state.zones}
                  onChange={this.handleZoneChange}
                  // onChange={handleChangeSourceApply}
                  // value={sourceKey}
                  editable={true}
                >
                  {this.props.commonData.mapZone.map((z) => {
                    if (z._id && z.isActive && z.display) {
                      return (
                        <option key={z._id} value={z._id}>
                          {z.zoneName}
                        </option>
                      );
                    }
                  })}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <Form.Label>
                  <Translate value="message.contentType" />
                </Form.Label>
                <FormControl
                  className="form-custom"
                  as="select"
                  value={this.state.contentType}
                  onChange={this.handleChangeContentType}
                >
                  {type === 'Inbox' ? (
                    ContentType.map((item) => (
                      <option key={item} value={item}>
                        {I18n.t(`message.contentTypeItem.` + item)}
                      </option>
                    ))
                  ) : (
                    <option key={ContentType[0]} value={ContentType[0]}>
                      {I18n.t('message.contentTypeItem.' + ContentType[0])}
                    </option>
                  )}
                </FormControl>
              </FormGroup>

              {this.state.contentType === 'html' ? (
                <HtmlContentInbox
                  languageOption={this.state.languageOption}
                  messages={this.state.messages}
                  subject={this.state.subject}
                  content={this.state.content}
                  drvSignUpLanguages={drvSignUpLanguages}
                  drvSignUpLanguagesFull={drvSignUpLanguagesFull}
                  handleSubjectChange={this.handleSubjectChange}
                  handleContentChange={this.handleContentChange}
                  handleChangeLanguageOption={this.handleChangeLanguageOption}
                  handleChangeInput={this.handleChangeInput}
                  subjectValidation={this.state.subjectValidation}
                  contentValidation={this.state.contentValidation}
                  multiSubjectValidation={this.state.multiSubjectValidation}
                  multiContentValidation={this.state.multiContentValidation}
                  errorMessage={this.state.errorMessage}
                  allowContentUpdate={true}
                />
              ) : (
                <LinkContentInbox
                  languageOption={this.state.languageOption}
                  messages={this.state.messages}
                  url={this.state.url}
                  subject={this.state.subject}
                  body={this.state.body}
                  image={this.state.image}
                  drvSignUpLanguages={drvSignUpLanguages}
                  drvSignUpLanguagesFull={drvSignUpLanguagesFull}
                  handleChangeLinkForm={this.handleChangeLinkForm}
                  handleImageChange={this.handleImageChange}
                  handleChangeImageByLanguage={this.handleChangeImageByLanguage}
                  handleChangeInput={this.handleChangeInput}
                  handleChangeLanguageOption={this.handleChangeLanguageOption}
                  valid={this.state.valid}
                  isSubmited={this.state.isSubmited}
                  ValidatorCallback={this.ValidatorCallback}
                  multiURLValidation={this.state.multiURLValidation}
                  multiSubjectValidation={this.state.multiSubjectValidation}
                  multiContentValidation={this.state.multiContentValidation}
                  errorMessage={this.state.errorMessage}
                />
              )}

              {type === 'Inbox' && this.state.allUser ? (
                <FormGroup>
                  <Form.Label>
                    <div className="requestTimeEnable mb">
                      <Translate value="message.expiryDate" />{' '}
                      <CcCheckbox
                        checked={this.state.expiryDateRequire}
                        onChange={this.handleChangeExpiryDateRequire}
                        className="ml mb0"
                      />
                    </div>
                  </Form.Label>
                  {this.state.expiryDateRequire ? (
                    <FormGroup
                    className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.expiryDate
                          ? null
                          : 'error'
                      }
                    >
                      <Datetime
                        onChange={this.handleChangeExpiryDate}
                        inputProps={{ readOnly: true, disabled: false }}
                        value={
                          this.state.expiryDate
                            ? moment(this.state.expiryDate).format('l')
                            : null
                        }
                        isValidDate={this.validExpiryDate}
                        closeOnSelect
                        checkShowOnTop
                        parrentMarginBottom={200}
                        timeFormat={false}
                        placeholder={I18n.t('message.expiryDate_placeholder')}
                      />
                      <Validator
                        id="expiryDate"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={
                            !Validation.isStringEmpty(this.state.expiryDate)
                          }
                          message={I18n.t(
                            'promotionSettings.Please_select_date'
                          )}
                        />
                      </Validator>
                    </FormGroup>
                  ) : null}
                </FormGroup>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <div className="text-center">
                <Button
                  className="btn-send mr-md"
                  variant="success"
                  onClick={this.handleSendMessageClick}
                >
                  <Translate value="message.Send" />
                </Button>
                <Button className="btn-cancel" onClick={this.handleCancelClick}>
                  <Translate value="message.Cancel" />
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
          <ImportModal
            isShow={this.state.isShowImport}
            handleImportCustomerClick={this.handleImportCustomerClick}
            closeDialog={this.closeDialog}
            appType={this.state.appType}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

NewMessage.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    commonData: state.commonData,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    autocompleteCustomer: (options) => {
      return dispatch(autocompleteCustomer(options));
    },
    autocompleteDriver: (options) => {
      return dispatch(autocompleteDriver(options));
    },
    sendMessage: (options) => {
      return dispatch(sendMessage(options));
    },
    autocompleteCarType: (options) => {
      return dispatch(autocompleteCarType(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    uploadService: bindActionCreators(uploadActions, dispatch),
    autocompleteMerchant: (options) => {
      return dispatch(autocompleteMerchant(options));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewMessage);
