import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import Autosuggest from 'react-autosuggest';
import currencyFormatter from 'currency-formatter';

import * as intercityRateActions from '../../../actions/intercityRateActions';
import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../components/validator';


const getSuggestionValue = (suggestion) => suggestion.routeName;

const renderSuggestion = (suggestion) => (
  <div className="pt pb">
    <div>{`${suggestion.firstLocation.name} - ${suggestion.secondLocation.name}`}</div>
  </div>
);

class RouteSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      value: '',
      searchFrom: '',
      searchTo: '',
    };
    this.fetchRoutes = _.debounce(this.fetchRoutes, 200);
  }

  searchFromRef = React.createRef();
  searchToRef = React.createRef();

  componentDidMount() {
    const { routeSelected } = this.props;
    if (
      !_.isEmpty(routeSelected) &&
      this.searchFromRef.current &&
      this.searchToRef.current
    ) {
      this.searchFromRef.current.input.value = routeSelected.firstLocation.name;
      this.searchToRef.current.input.value = routeSelected.secondLocation.name;
      this.setState({
        searchFrom: routeSelected.firstLocation.name,
        searchTo: routeSelected.secondLocation.name,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { routeSelected } = this.props;
    if (
      nextProps.routeSelected &&
      (nextProps?.routeSelected?.firstLocation?.zoneId !==
        _.get(routeSelected, 'firstLocation.zoneId') ||
        nextProps.routeSelected?.secondLocation?.zoneId !==
        _.get(routeSelected, 'secondLocation.zoneId'))
    ) {
      if (this.searchFromRef.current && this.searchToRef.current) {
        this.searchFromRef.current.input.value =
          nextProps.routeSelected?.firstLocation?.name || '';
        this.searchToRef.current.input.value =
          nextProps.routeSelected?.secondLocation?.name || '';
        this.setState({
          searchFrom: nextProps.routeSelected?.firstLocation?.name || '',
          searchTo: nextProps.routeSelected?.secondLocation?.name || '',
        });
      }
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.fetchRoutes();
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      routes: []
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    let carTypesSuggest = suggestion.carTypes || [];
    let carType = carTypesSuggest[0];
    this.setState({
      searchFrom: suggestion.firstLocation.name,
      searchTo: suggestion.secondLocation.name,
    });
    // this.props.onChange(suggestion);
    if (this.searchFromRef.current && this.searchToRef.current) {
      this.searchFromRef.current.input.value = suggestion.firstLocation.name;
      this.searchToRef.current.input.value = suggestion.secondLocation.name;
    }
    let isValidCartype = true;
    if (this.props.request.companyId) {
      carTypesSuggest = [];
      suggestion.carTypes.forEach(car => {
        this.props.companyVehicleTypes.forEach(comCar => {
          if (comCar === car.vehicleType) {
            carTypesSuggest.push(car);
          }
        })
      });
      carType = carTypesSuggest[0];
      isValidCartype =
        this.props.companyVehicleTypes.indexOf(carType.vehicleType) !== -1;
    };
    this.props.intercityBookingActions.updateCommonData({
      schedules: _.get(carType.route, 'routeSetting.schedules', []),
      routeSelected: carType.route,
      routeSetting: carType.route.routeSetting,
      carTypes: carTypesSuggest,
      carType: isValidCartype ? carType : null,
    });
    this.props.intercityBookingActions.etaFare()
  };

  onChangeSearchFrom = (event, { newValue }) => {
    this.setState({ searchFrom: newValue });
    if (!newValue) {
      this.resetRoute();
    }
  };

  onChangeSearchTo = (event, { newValue }) => {
    this.setState({ searchTo: newValue });
    if (!newValue) {
      this.resetRoute();
    }
  };

  resetRoute = () => {
    this.props.intercityBookingActions.updateCommonData({
      schedules: [],
      routeSelected: null,
      routeSetting: null,
      carTypes: [],
      carType: null,
      departureTime: null,
      departureDate: null,
    });
  };

  handleFocus = (input) => {
    this.setState({ inputFocus: input });
  };

  fetchRoutes = () => {
    const { searchFrom, searchTo } = this.state;
    const { companyVehicleTypes, request, common } = this.props;
    const query = {
      limit: 20,
      page: 0,
      query: {
        fleetId: this.props.selectedFleet.fleetId,
        searchFrom,
        searchTo,
        corporateId: common?.corporateInfo?._id
      },
    };
    const isPermissionCompany = !!this.props.user.roles.companyId;
    if (isPermissionCompany || request.companyId) {
      query.vehicleTypes = companyVehicleTypes;
    }

    this.props.intercityRateActions
      .findRouteWithCarType(query)
      .then(({ res }) => {
        this.setState({ routes: res.list });
      })
      .catch((err) => console.log(err));
  };

  renderInputComponent = ({ value, ...inputProps }) => (
    <div>
      <input
        type="text"
        {...inputProps}
        className={'form-control form-custom time-select'}
      />
    </div>
  );

  renderSearchFromSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }) => {
    return (
      <div
        {...containerProps}
        className={`${containerProps.className} selectRouteInput__from`}
      >
        {children}
      </div>
    );
  };

  renderSearchToSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }) => {
    return (
      <div
        {...containerProps}
        className={`${containerProps.className} selectRouteInput__to`}
      >
        {children}
      </div>
    );
  };

  render() {
    const { routes, searchFrom, searchTo } = this.state;
    const {
      valid,
      isSubmited,
      validatorCallback,
      routeSelected,
      disabled,
    } = this.props;
    // Autosuggest will pass through all these props to the input.
    const inputPropsSearchFrom = {
      autoFocus: true,
      value: searchFrom || _.get(routeSelected, 'firstLocation.name', ''),
      onChange: this.onChangeSearchFrom,
      placeholder: I18n.t('intercityBooking.Departure'),
      className: 'form-control form-custom',
      disabled: disabled,
    };
    const inputPropsSearchTo = {
      value: searchTo || _.get(routeSelected, 'secondLocation.name', ''),
      onChange: this.onChangeSearchTo,
      placeholder: I18n.t('intercityBooking.Arrival'),
      className: 'form-control form-custom',
      disabled: disabled,
    };
    return (
      <FormGroup
        className={isSubmited && valid.route === false ? 'error' : null}
      >
        <Form.Label>
          <Translate value="intercityBooking.route" />
          &nbsp;
          <span className="require"> *</span>
        </Form.Label>
        <div className="selectRouteInput">
          <Autosuggest
            ref={this.searchFromRef}
            suggestions={routes}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputPropsSearchFrom}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={
              this.renderSearchFromSuggestionsContainer
            }
            disabled={disabled}
          // shouldRenderSuggestions={() => searchFrom || searchTo}
          />
          <Form.Label className={'routeInput__hyphen mr-sm ml-sm'}>
            -
          </Form.Label>
          <Autosuggest
            ref={this.searchToRef}
            suggestions={routes}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            onSuggestionSelected={this.onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputPropsSearchTo}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={this.renderSearchToSuggestionsContainer}
            disabled={disabled}
          // shouldRenderSuggestions={() => searchFrom || searchTo}
          />
        </div>
        <Validator callback={validatorCallback} id="route">
          <ValidCase
            valid={!!routeSelected}
            hide={!isSubmited}
            message={I18n.t('messages.commonMessages.Required_field')}
          />
        </Validator>
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    selectedFleet: state.auth.selectedFleet,
    carType: state.commonData.carTypeIntercity,
    routeSelected: state.intercityBooking.common.routeSelected,
    common: state.intercityBooking.common,
    companyVehicleTypes: state.intercityBooking.common.companyVehicleTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intercityRateActions: bindActionCreators(intercityRateActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RouteSelect);
