import ReportUtils from './../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.settlementHistory.date',
    mutate: ReportUtils.formatTime,
    width: 180,
  },
  {
    key: 'driverName',
    label: 'report.result.settlementHistory.driverName',
  },
  {
    key: 'dateRange',
    label: 'report.result.settlementHistory.dateRange',
    mutate: (value, doc) => {
      let dateFrom = ReportUtils.formatTime(doc.dateRangeFrom);
      let dateTo = ReportUtils.formatTime(doc.dateRangeTo);
      return `${dateFrom} - ${dateTo}`;
    },
    width: 330,
  },
  {
    key: 'totalTransaction',
    label: 'report.result.settlementHistory.totalTransaction',
    summary: 'totalTransaction',
  },
  {
    key: 'paidAmount',
    label: 'report.result.settlementHistory.paidAmount',
    summary: 'paidAmount',
  },
];

export const fareFields = ['paidAmount'];
