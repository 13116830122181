const defaultStyles = {
  root: {
    position: 'absolute',
    paddingBottom: '0px',
  },
  autocompleteContainer: {
    position: 'absolute',
    top: '100%',
    left: '0',
    backgroundColor: '#252830',
    width: '100%',
    zIndex: 9999,
    //borderRadius : '5px',
    padding:"2px"
  },
  label: { color: 'red' },
  autocompleteItem: {
    //backgroundColor: '#ffffff',
    //padding: '10px',
    //color: '#fff',
    cursor: 'pointer',
  },
  autocompleteItemActive: {
    //backgroundColor: '#fafafa',
    color: 'blue',
    backgroundColor: '#363a44'
  },
  input: { width: '50%' }
}

export default defaultStyles