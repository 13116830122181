import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import '../settings.scss';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Validator, ValidCase } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';
import { DispatchMode, CheckMode } from '../../../constants/commondata';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip';

import Shuttle from './Shuttle';
import MarketPlace from './marketPlace';

class DispatchSetting extends Component {
  constructor() {
    super();
    this.state = {
      formData: {},
      dispatchMode: 0,
      shuttleDispatchMode: 0,
      isChanged: false,
      valid: {
        dispatchMode: true,
      },
      isSubmited: false,
      listSoundsMarketplace: [
        { key: '1', value: 1 },
        { key: '2', value: 2 },
        { key: '3', value: 3 },
      ],
      shuttleMinimumBookAhead: {},
      shuttleMaximumBookAhead: {},
    };
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleDispatchConfirmChange =
      this.handleDispatchConfirmChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.checkDispatchMode = this.checkDispatchMode.bind(this);
    this.handleTextIHrsMinTypeChange =
      this.handleTextIHrsMinTypeChange.bind(this);
    this.handleChangeSoundMarketplace =
      this.handleChangeSoundMarketplace.bind(this);
  }
  componentDidMount() {
    this.getDispatchDetails();
  }

  getDispatchDetails = () => {
    this.props.settingActions
      .geDispatchSettingDetail(this.props.auth.selectedFleet.fleetId)
      .then((data) => {
        let dispatchMode = 0;
        let shuttleDispatchMode = 0;
        if (data.res) {
          const response = _.cloneDeep(data.res);
          if (!response.isAutoDispatch || response.isMarketplaceBookLater) {
            dispatchMode = DispatchMode.Manual;
          } else {
            if (response.processBefore > 0) {
              dispatchMode = DispatchMode.DelayedAutoDispatch;
            } else {
              dispatchMode = DispatchMode.ImmediateAutoDispatch;
            }
          }
          let {
            minTimeBeforeReservationUnit = '',
            minTimeBeforeReservation,
            notificationNearbyDriversBeforePickupTime,
            notificationNearbyDriversBeforePickupTimeUnit,
          } = response;
          if (
            minTimeBeforeReservationUnit == 'hour' &&
            minTimeBeforeReservation > 0
          ) {
            response.minTimeBeforeReservation = minTimeBeforeReservation / 60;
          }
          if (
            notificationNearbyDriversBeforePickupTimeUnit == 'hour' &&
            notificationNearbyDriversBeforePickupTime > 0
          ) {
            response.notificationNearbyDriversBeforePickupTime =
              notificationNearbyDriversBeforePickupTime / 60;
          }
          shuttleDispatchMode = response.shuttleDispatchMode
            ? response.shuttleDispatchMode
            : 0;
          let shuttleMinimumBookAhead = response.shuttleMinimumBookAhead
            ? response.shuttleMinimumBookAhead
            : {};
          if (
            shuttleMinimumBookAhead.unit === 'hour' &&
            shuttleMinimumBookAhead.value
          ) {
            shuttleMinimumBookAhead.value = shuttleMinimumBookAhead.value / 60;
          }
          let shuttleMaximumBookAhead = response.shuttleMaximumBookAhead
            ? response.shuttleMaximumBookAhead
            : {};
          if (
            shuttleMaximumBookAhead.unit === 'day' &&
            shuttleMaximumBookAhead.value
          ) {
            shuttleMaximumBookAhead.value =
              shuttleMaximumBookAhead.value / 1440;
          } else if (
            shuttleMaximumBookAhead.unit === 'week' &&
            shuttleMaximumBookAhead.value
          ) {
            shuttleMaximumBookAhead.value =
              shuttleMaximumBookAhead.value / 10080;
          } else if (
            shuttleMaximumBookAhead.unit === 'month' &&
            shuttleMaximumBookAhead.value
          ) {
            shuttleMaximumBookAhead.value =
              shuttleMaximumBookAhead.value / 43800;
          }
          this.setState({
            formData: response,
            shuttleMinimumBookAhead,
            shuttleMaximumBookAhead,
            dispatchMode,
            shuttleDispatchMode,
          });
        }
      });
  };

  handleTextInputChange(key, e) {
    this.state.isChanged = true;
    let { valid } = this.state;
    this.setState({ valid: {} });
    if (key === 'marketplaceMaxDays') {
      e.target.value = parseInt(e.target.value || 0);
    }
    const regrex = /^(\d*\.?\d?|\d+\.)$/;
    switch (key) {
      case 'dispatchMode':
        this.state.dispatchMode = e.target.value;
        if (e.target.value == DispatchMode.Manual) {
          this.state.formData.isAutoDispatch = false;
        } else {
          this.state.formData.isAutoDispatch = true;
          this.state.formData.autoDispatchBefore = 0;
          if (e.target.value == DispatchMode.DelayedAutoDispatch) {
            this.state.formData.processBefore = 0; //it should be 1
          } else {
            this.state.formData.processBefore = 0;
          }
        }
        break;
      case 'maximumBookAhead_value':
        this.state.formData.maximumBookAhead.value = e.target.value;
        break;
      case 'maximumBookAhead_type':
        this.state.formData.maximumBookAhead.type = e.target.value;
        break;
      case 'minimumBookAhead':
        if (regrex.test(e.target.value)) {
          this.state.formData.minimumBookAhead = e.target.value;
        }
        break;
      case 'cancellationDuration':
        if (regrex.test(e.target.value)) {
          this.state.formData.cancellationDuration = e.target.value;
        }
        break;
      case 'notificationNearbyDriversBeforePickupTime':
        if (e.target.value) {
          this.state.formData.notificationNearbyDriversBeforePickupTime =
            e.target.value * 1;
        }
        break;
      case 'shuttleMinimumBookAhead_value':
        this.state.shuttleMinimumBookAhead.value = e.target.value;
        break;
      case 'shuttleMaximumBookAhead_value':
        this.state.shuttleMaximumBookAhead.value = e.target.value;
        break;
      case 'shuttleMaximumBookAhead_unit':
        this.state.shuttleMaximumBookAhead.unit = e.target.value;
        break;
      default:
        this.state.formData[key] = e.target.value;
        break;
    }
    this.setState({
      formData: this.state.formData,
      shuttleMinimumBookAhead: this.state.shuttleMinimumBookAhead,
      shuttleMaximumBookAhead: this.state.shuttleMaximumBookAhead,
    });
  }

  handleChangeShuttleUnit = (type, e) => {
    switch (type) {
      case 'shuttleMinimumBookAhead':
        if (this.state.shuttleMinimumBookAhead.unit != e.target.value) {
          this.state.shuttleMinimumBookAhead.unit = e.target.value;
          if (this.state.shuttleMinimumBookAhead.unit === 'hour') {
            this.state.shuttleMinimumBookAhead.value =
              this.state.shuttleMinimumBookAhead.value / 60;
          } else if (this.state.shuttleMinimumBookAhead.unit === 'minute') {
            this.state.shuttleMinimumBookAhead.value =
              this.state.shuttleMinimumBookAhead.value * 60;
          }
          this.setState({
            shuttleMinimumBookAhead: this.state.shuttleMinimumBookAhead,
          });
        }
        break;
      default:
        break;
    }
  };

  handleTextInputBlur = (key, e) => {
    const { value } = e.target || {};
    const newState = {
      isChanged: false,
    };
    switch (key) {
      case 'minTimeBeforeReservation':
        if (!value || !value.length) {
          newState.isChanged = true;
          newState.formData = {
            ...this.state.formData,
            minTimeBeforeReservation: 90,
          };
        }
        break;
      case 'maximumAcceptReservationPerDay':
        if (!value || !value.length) {
          newState.isChanged = true;
          newState.formData = {
            ...this.state.formData,
            maximumAcceptReservationPerDay: 2,
          };
        }
        break;
      case 'maximumAcceptReservation':
        if (!value || !value.length) {
          newState.isChanged = true;
          newState.formData = {
            ...this.state.formData,
            maximumAcceptReservation: 10,
          };
        }
        break;
      case 'localNotificationBefore':
        if (!value || !value.length) {
          newState.isChanged = true;
          newState.formData = {
            ...this.state.formData,
            localNotificationBefore: 60,
          };
        }
        break;
      default:
        break;
    }
    if (newState.isChanged)
      this.setState({
        ...newState,
      });
  };

  handleTextIHrsMinTypeChange(type, e) {
    let nextTypeUnit = e.target && e.target.value;
    let currentTypeUnit = this.state.formData[`${type}Unit`];
    let typeValue = this.state.formData[type];
    if (currentTypeUnit != nextTypeUnit) {
      if (nextTypeUnit == 'hour' && typeValue > 0) {
        typeValue = typeValue / 60;
      } else {
        typeValue = typeValue * 60;
      }
      this.setState({
        formData: {
          ...this.state.formData,
          [type]: typeValue,
          [`${type}Unit`]: nextTypeUnit,
        },
      });
    }
  }

  handleShortNoticeOrderUnit = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        shortNoticeOrderUnit: e.target.value,
      },
    });
  };

  handleChangeSoundMarketplace(e) {
    this.state.isChanged = true;
    this.state.formData.soundMarketplace = e.target.value;
    this.setState({ formData: this.state.formData });
  }

  handleDispatchConfirmChange(e) {
    this.state.isChanged = true;
    this.state.formData.assignReservation = e.target.checked;
    this.setState({ formData: this.state.formData });
  }
  handleAssignOfflineDriverhange = (e) => {
    this.state.isChanged = true;
    this.state.formData.assignReservationForOfflineDriver = e.target.checked;
    this.setState({ formData: this.state.formData });
  };
  handleBroadcastBookinghange = (e) => {
    this.state.isChanged = true;
    this.state.formData.broadcastBooking = e.target.checked;
    this.setState({ formData: this.state.formData });
  };
  handleSaveClick() {
    const { shuttleMinimumBookAhead, shuttleMaximumBookAhead } = this.state;
    this.setState({ isSubmited: true });
    if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
      this.setState({ isChanged: false });
      this.props.loadingBarActions.showLoadingSpiner();
      const { marketplace, intercity } = this.props.fleet;
      let processUpdate = { ...this.state.formData };
      if (
        marketplace &&
        !marketplace.enable &&
        intercity &&
        !intercity.enable
      ) {
        processUpdate = _.omit(processUpdate, [
          'maximumAcceptReservation',
          'maximumAcceptReservationPerDay',
          'localNotificationBefore',
        ]);
      }

      if (shuttleMinimumBookAhead.unit === 'hour') {
        processUpdate.shuttleMinimumBookAhead.value =
          shuttleMinimumBookAhead.value * 60;
      } else {
        processUpdate.shuttleMinimumBookAhead.value =
          shuttleMinimumBookAhead.value;
      }

      if (shuttleMaximumBookAhead.unit === 'day') {
        processUpdate.shuttleMaximumBookAhead.value =
          shuttleMaximumBookAhead.value * 1440;
      } else if (shuttleMaximumBookAhead.unit === 'week') {
        processUpdate.shuttleMaximumBookAhead.value =
          shuttleMaximumBookAhead.value * 10080;
      } else if (shuttleMaximumBookAhead.unit === 'month') {
        processUpdate.shuttleMaximumBookAhead.value =
          shuttleMaximumBookAhead.value * 43800;
      }

      processUpdate.minTimeBeforeReservationUnit =
        processUpdate.minTimeBeforeReservationUnit || 'minute';
      processUpdate.notificationNearbyDriversBeforePickupTimeUnit =
        processUpdate.notificationNearbyDriversBeforePickupTimeUnit || 'minute';
      processUpdate.shuttleMinimumBookAhead.unit = shuttleMinimumBookAhead.unit;
      processUpdate.shuttleMaximumBookAhead.unit = shuttleMaximumBookAhead.unit;

      this.props.settingActions
        .saveDispatchSetting(processUpdate)
        .then((data) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (data.ok && !data.error) {
            this.context.notification(
              'success',
              I18n.t('dispatchSetting.Save_dispatch_success')
            );
            this.setState({ isSubmited: false });
            // window.location.reload();
            this.getDispatchDetails();
          } else {
            if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('dispatchSetting.Save_dispatch_fail')
              );
            }
            this.setState({ isChanged: true });
          }
        });
    }
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  checkDispatchMode(mode) {
    const { formData, dispatchMode } = this.state;
    if (dispatchMode == DispatchMode.Manual) {
      if (mode == CheckMode.checkInteger)
        return Validation.isInteger(formData.autoDispatchBefore);
      if (mode == CheckMode.checkGreaterThan0)
        return Validation.isGreaterThan(formData.autoDispatchBefore, 0);
    } else if (dispatchMode == DispatchMode.DelayedAutoDispatch) {
      if (mode == CheckMode.checkInteger)
        return Validation.isInteger(formData.processBefore);
      if (mode == CheckMode.checkGreaterThan0)
        return Validation.isGreaterThan(formData.processBefore, 0);
    }
    return true;
  }

  render() {
    const { formData, valid, isSubmited, dispatchMode } = this.state;
    const editable = this.props.permissions && !this.props.permissions.actions;
    const {
      marketplace,
      intercity = {},
      moduleSettings = {},
      shuttle = {},
    } = this.props.fleet;
    const {
      selectedFleet,
      selectedFleet: { recurring, dispatchGroup },
    } = this.props.auth;

    return (
      <div className="content setting-dispatch">
        <Row>
          <Col md={6} sm={12}>
            <FormGroupTitle>
              <Translate value="dispatchSetting.GENERAL" />
              <QuestionCircleTooltip
                text={
                  <Translate value="dispatchSetting.Dispatch_setting_general_tooltip" />
                }
              />
            </FormGroupTitle>
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.offerInterval === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Offer_interval" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="dispatchSetting.Offer_interval_toolTip" />
                  }
                />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={formData.offerInterval || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('offerInterval', e);
                    }}
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Translate value="dispatchSetting.Seconds" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="offerInterval" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isInteger(formData.offerInterval)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={
                    !isSubmited || !Validation.isInteger(formData.offerInterval)
                  }
                  valid={Validation.isGreaterThan(formData.offerInterval, 0)}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            {moduleSettings.merchant && (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.offerIntervalMerchant === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Offer_interval_merchant" />
                  <QuestionCircleTooltip
                    text={
                      <Translate value="dispatchSetting.Offer_interval_merchant_toolTip" />
                    }
                  />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={formData.offerIntervalMerchant || ''}
                      onChange={(e) => {
                        this.handleTextInputChange('offerIntervalMerchant', e);
                      }}
                      disabled={editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="dispatchSetting.Seconds" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="offerIntervalMerchant"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(formData.offerIntervalMerchant)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={
                      !isSubmited ||
                      !Validation.isInteger(formData.offerIntervalMerchant)
                    }
                    valid={Validation.isGreaterThan(
                      formData.offerIntervalMerchant,
                      0
                    )}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
            )}
            {dispatchGroup && dispatchGroup.enable ? (
              <FormGroup>
                <Form.Label>
                  <Translate value="dispatchSetting.soundMarketplace" />
                </Form.Label>
                <select
                  className="form-control form-custom"
                  value={
                    formData.soundMarketplace ? formData.soundMarketplace : '1'
                  }
                  onChange={this.handleChangeSoundMarketplace}
                  disabled={editable}
                >
                  {this.state.listSoundsMarketplace.map((item) => {
                    let times = `${I18n.t('dispatchSetting.sound')} ${
                      item.value
                    }`;
                    return (
                      <option key={item.key} value={item.value}>
                        {times}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            ) : null}
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.autoReDispatch === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Auto_re_dispatch_interval" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="dispatchSetting.Auto_re_dispatch_interval_toolTip" />
                  }
                />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={this.state.formData.autoReDispatch || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('autoReDispatch', e);
                    }}
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Translate value="dispatchSetting.Seconds" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="autoReDispatch" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isInteger(formData.autoReDispatch)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={
                    !isSubmited ||
                    !Validation.isInteger(formData.autoReDispatch)
                  }
                  valid={Validation.isGreaterThan(formData.autoReDispatch, 0)}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.maximumDispatchDuration === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Maximum_auto_dispatch_duration" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="dispatchSetting.Maximum_auto_dispatch_duration_toolTip" />
                  }
                />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={this.state.formData.maximumDispatchDuration || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('maximumDispatchDuration', e);
                    }}
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Translate value="dispatchSetting.Seconds" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator
                id="maximumDispatchDuration"
                callback={this.ValidatorCallback}
              >
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isInteger(formData.maximumDispatchDuration)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isGreaterThan(
                    formData.maximumDispatchDuration,
                    0
                  )}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.bookingTimeOut === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Time_out" />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={this.state.formData.bookingTimeOut || 0}
                    onChange={(e) => {
                      this.handleTextInputChange('bookingTimeOut', e);
                    }}
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <Translate value="dispatchSetting.mins" />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="bookingTimeOut" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isInteger(formData.bookingTimeOut)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isGreaterThan(formData.bookingTimeOut, 0)}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            <FormGroupTitle>
              <Translate value="dispatchSetting.IN_ADVANCE" />
            </FormGroupTitle>
            {/* <Form className={"flex-start-default flex-bw-center"}>
              <Form.Label className="mr-r-50">
                <Translate value="dispatchSetting.Assign_reservation_to_driver" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="dispatchSetting.Dispatch_setting_Assign_reservation_to_driver_tooltip" />
                  }
                />
              </Form.Label>
            </Form>
            <FormGroup className={"flex-start-default flex-bw-center"}>
              <CcCheckbox
                checked={this.state.formData.assignReservation || false}
                onChange={this.handleDispatchConfirmChange}
                disabled={editable}
                className={"mr-b-0"}
                text={I18n.t(
                  "dispatchSetting.Dispatcher_confirms_job_for_driver"
                )}
              />
              <CcCheckbox
                checked={this.state.formData.assignReservationForOfflineDriver || false}
                onChange={this.handleAssignOfflineDriverhange}
                disabled={editable}
                className={"mr-b-0"}
                text={I18n.t(
                  "dispatchSetting.Dispatcher_confirms_job_for_offline_driver"
                )}
              />
            </FormGroup> */}
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.minimumBookAhead === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Minimum_book_ahead" />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={this.state.formData.minimumBookAhead || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('minimumBookAhead', e);
                    }}
                    disabled={editable}
                  />
                  <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={
                      this.state.formData
                        ? this.state.formData.minimumBookAheadUnit || 0
                        : 'hour'
                    }
                    onChange={(e) => {
                      this.handleTextIHrsMinTypeChange('minimumBookAhead', e);
                    }}
                    disabled={editable}
                  >
                    <option value={'hour'}>{I18n.t('General.hrs')}</option>
                    <option value={'minute'}>{I18n.t('General.mins')}</option>
                  </FormControl>
                </InputGroup>
              </FormGroup>
              <Validator
                id="minimumBookAhead"
                callback={this.ValidatorCallback}
                multi={true}
              >
                <ValidCase
                  hide={!isSubmited}
                  valid={!Validation.isStringEmpty(formData.minimumBookAhead)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
                {this.state.formData.minimumBookAheadUnit == 'hour' && (
                  <ValidCase
                    valid={Validation.isDecimal(formData.minimumBookAhead)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!this.state.isSubmited}
                  />
                )}
                {this.state.formData.minimumBookAheadUnit == 'minute' && (
                  <ValidCase
                    hide={!this.state.isSubmited}
                    valid={Validation.isInteger(formData.minimumBookAhead)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                )}
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isGreaterThan(formData.minimumBookAhead, 0)}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.maximumBookAhead === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Maximum_book_ahead" />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right ' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={
                      this.state.formData.maximumBookAhead
                        ? this.state.formData.maximumBookAhead.value || ''
                        : ''
                    }
                    onChange={(e) => {
                      this.handleTextInputChange('maximumBookAhead_value', e);
                    }}
                    disabled={editable}
                  />
                  <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={
                      this.state.formData.maximumBookAhead
                        ? this.state.formData.maximumBookAhead.type || 0
                        : 'week'
                    }
                    onChange={(e) => {
                      this.handleTextInputChange('maximumBookAhead_type', e);
                    }}
                    disabled={editable}
                  >
                    <option value={'day'}>
                      {I18n.t('dispatchSetting.Day')}
                    </option>
                    <option value={'week'}>
                      {I18n.t('dispatchSetting.Week')}
                    </option>
                    <option value={'month'}>
                      {I18n.t('dispatchSetting.Month')}
                    </option>
                  </FormControl>
                </InputGroup>
              </FormGroup>
              <Validator
                id="maximumBookAhead"
                callback={this.ValidatorCallback}
                multi={true}
              >
                <ValidCase
                  hide={!isSubmited}
                  valid={
                    formData.maximumBookAhead
                      ? Validation.isInteger(formData.maximumBookAhead.value)
                      : true
                  }
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={
                    !isSubmited ||
                    (formData.maximumBookAhead
                      ? !Validation.isInteger(formData.maximumBookAhead.value)
                      : true)
                  }
                  valid={
                    formData.maximumBookAhead
                      ? Validation.isGreaterThan(
                          formData.maximumBookAhead.value,
                          0
                        )
                      : true
                  }
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            {recurring && recurring.enable ? (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.maximumPeriodRecurringOrdersFleet === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Maximum_period_recurring_oders_fleet" />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right ' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={
                        this.state.formData.maximumPeriodRecurringOrdersFleet
                      }
                      onChange={(e) => {
                        this.handleTextInputChange(
                          'maximumPeriodRecurringOrdersFleet',
                          e
                        );
                      }}
                      disabled={editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="dispatchSetting.Month" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="maximumPeriodRecurringOrdersFleet"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(
                      formData.maximumPeriodRecurringOrdersFleet
                    )}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isGreaterThan(
                      formData.maximumPeriodRecurringOrdersFleet,
                      0
                    )}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.maximumPeriodRecurringOrdersFleet,
                      12
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(12)}
                  />
                </Validator>
              </FormGroup>
            ) : null}
            {recurring && recurring.enable ? (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.maximumPeriodRecurringOrdersCorp === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Maximum_period_recurring_oders_corp" />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right ' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={
                        this.state.formData.maximumPeriodRecurringOrdersCorp
                      }
                      onChange={(e) => {
                        this.handleTextInputChange(
                          'maximumPeriodRecurringOrdersCorp',
                          e
                        );
                      }}
                      disabled={editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="dispatchSetting.Month" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="maximumPeriodRecurringOrdersCorp"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(
                      formData.maximumPeriodRecurringOrdersCorp
                    )}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isGreaterThan(
                      formData.maximumPeriodRecurringOrdersCorp,
                      0
                    )}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.maximumPeriodRecurringOrdersCorp,
                      12
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(12)}
                  />
                </Validator>
              </FormGroup>
            ) : null}
            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.cancellationDuration === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.Driver_cancellation_duration" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="dispatchSetting.Dispatch_setting_Driver_cancellation_duration_tooltip" />
                  }
                />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={this.state.formData.cancellationDuration || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('cancellationDuration', e);
                    }}
                    disabled={editable}
                  />
                  <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={
                      this.state.formData
                        ? this.state.formData.cancellationDurationUnit || 0
                        : 'hour'
                    }
                    onChange={(e) => {
                      this.handleTextIHrsMinTypeChange(
                        'cancellationDuration',
                        e
                      );
                    }}
                    disabled={editable}
                  >
                    <option value={'hour'}>{I18n.t('General.hrs')}</option>
                    <option value={'minute'}>{I18n.t('General.mins')}</option>
                  </FormControl>
                </InputGroup>
              </FormGroup>
              <Validator
                id="cancellationDuration"
                callback={this.ValidatorCallback}
              >
                {this.state.formData.cancellationDurationUnit == 'hour' && (
                  <ValidCase
                    valid={Validation.isDecimal(formData.cancellationDuration)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                    hide={!this.state.isSubmited}
                  />
                )}
                {this.state.formData.cancellationDurationUnit == 'minute' && (
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(formData.cancellationDuration)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                )}
                <ValidCase
                  hide={
                    !isSubmited ||
                    !Validation.isInteger(formData.cancellationDuration)
                  }
                  valid={Validation.isGreaterThan(
                    formData.cancellationDuration,
                    0
                  )}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="dispatchSetting.Dispatch_mode" />
                <QuestionCircleTooltip
                  text={
                    <Translate
                      value="dispatchSetting.Dispatch_setting_Dispatch_mode_tooltip"
                      dangerousHTML
                    />
                  }
                />
              </Form.Label>
              <FormControl
                as="select"
                value={this.state.dispatchMode || 0}
                onChange={(e) => {
                  this.handleTextInputChange('dispatchMode', e);
                }}
                className={'form-custom'}
                disabled={
                  editable || this.state.formData.isMarketplaceBookLater
                }
              >
                <option value={DispatchMode.Manual}>
                  {I18n.t('dispatchSetting.Manual')}
                </option>
                <option value={DispatchMode.ImmediateAutoDispatch}>
                  {I18n.t('dispatchSetting.Immediate_auto_dispatch')}
                </option>
                <option value={DispatchMode.DelayedAutoDispatch}>
                  {I18n.t('dispatchSetting.Delayed_auto_dispatch')}
                </option>
              </FormControl>
            </FormGroup>
            {
              //cheat here, dont know why but we need a valid dispatch mode always true for DispatchMode.ImmediateAutoDispatch for the first time
              this.state.dispatchMode == DispatchMode.ImmediateAutoDispatch ? (
                <Validator id="dispatchMode" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={true}
                    valid={true}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={true}
                    valid={true}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              ) : (
                ''
              )
            }

            {this.state.dispatchMode == DispatchMode.Manual ? (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.dispatchMode === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Auto_dispatch_before_pickup_time" />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={this.state.formData.autoDispatchBefore || ''}
                      onChange={(e) => {
                        this.handleTextInputChange('autoDispatchBefore', e);
                      }}
                      disabled={editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="dispatchSetting.hrs" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup>
                <Validator id="dispatchMode" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={
                      !isSubmited ||
                      !Validation.isInteger(formData.autoDispatchBefore)
                    }
                    valid={this.checkDispatchMode(CheckMode.checkGreaterThan0)}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
            ) : (
              ''
            )}
            {this.state.dispatchMode == DispatchMode.DelayedAutoDispatch ? (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.dispatchMode === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Process_in_advance" />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={this.state.formData.processBefore || ''}
                      onChange={(e) => {
                        this.handleTextInputChange('processBefore', e);
                      }}
                      autoFocus={this.state.valid.processBefore === false}
                      disabled={editable}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>
                        <Translate value="dispatchSetting.mins" />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </FormGroup>
                <Validator id="dispatchMode" callback={this.ValidatorCallback}>
                  <ValidCase
                    hide={!isSubmited}
                    valid={this.checkDispatchMode(CheckMode.checkInteger)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={
                      !isSubmited ||
                      !Validation.isInteger(formData.processBefore)
                    }
                    valid={this.checkDispatchMode(CheckMode.checkGreaterThan0)}
                    message={I18n.t('messages.commonMessages.greater_than_0')}
                  />
                </Validator>
              </FormGroup>
            ) : (
              ''
            )}

            <FormGroup
              className={
                !isSubmited
                  ? null
                  : valid.minTimeBeforeReservation === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="dispatchSetting.minTimeBeforeReservation" />
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right' + (editable ? ' disabled' : '')
                  }
                >
                  <FormControl
                    type="number"
                    className={'form-custom number-no-spin'}
                    value={formData.minTimeBeforeReservation || ''}
                    onChange={(e) => {
                      this.handleTextInputChange('minTimeBeforeReservation', e);
                    }}
                    onBlur={(e) =>
                      this.handleTextInputBlur('minTimeBeforeReservation', e)
                    }
                    disabled={editable}
                  />
                  <FormControl
                    as="select"
                    className="input-group-addon-custom form-custom"
                    value={
                      this.state.formData
                        ? this.state.formData.minTimeBeforeReservationUnit ||
                          'minute'
                        : 'minute'
                    }
                    onChange={(e) => {
                      this.handleTextIHrsMinTypeChange(
                        'minTimeBeforeReservation',
                        e
                      );
                    }}
                    disabled={editable}
                  >
                    <option value={'minute'}>{I18n.t('General.mins')}</option>
                    <option value={'hour'}>{I18n.t('General.hrs')}</option>
                  </FormControl>
                </InputGroup>
              </FormGroup>
              <Validator
                id="minTimeBeforeReservation"
                callback={this.ValidatorCallback}
              >
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isInteger(
                    formData.minTimeBeforeReservation
                  )}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isGreaterThan(
                    formData.minTimeBeforeReservation,
                    0
                  )}
                  message={I18n.t(
                    'messages.commonMessages.greater_than_0'
                  ).format(0)}
                />
                {/* <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isLessOrEqual(formData.minTimeBeforeReservation, 1440)}
                  message={I18n.t("messages.commonMessages.less_or_equal").format(1440)}
                /> */}
              </Validator>
            </FormGroup>
            {(marketplace.enable || intercity.enable) && (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.maximumAcceptReservationPerDay === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Maximum_Day" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="dispatchSetting.Dispatch_setting_Maximum_Day_tooltip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={formData.maximumAcceptReservationPerDay || ''}
                      onChange={(e) => {
                        this.handleTextInputChange(
                          'maximumAcceptReservationPerDay',
                          e
                        );
                      }}
                      onBlur={(e) =>
                        this.handleTextInputBlur(
                          'maximumAcceptReservationPerDay',
                          e
                        )
                      }
                      disabled={editable}
                    />
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="maximumAcceptReservationPerDay"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(
                      formData.maximumAcceptReservationPerDay
                    )}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isGreaterOrEqual(
                      formData.maximumAcceptReservationPerDay,
                      1
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(1)}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.maximumAcceptReservationPerDay,
                      50
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(50)}
                  />
                </Validator>
              </FormGroup>
            )}
            {(marketplace.enable || intercity.enable) && (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.maximumAcceptReservation === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Maximum_Accept_Reservation" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="dispatchSetting.Maximum_Accept_Reservation_tooltip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={formData.maximumAcceptReservation || ''}
                      onChange={(e) => {
                        this.handleTextInputChange(
                          'maximumAcceptReservation',
                          e
                        );
                      }}
                      onBlur={(e) =>
                        this.handleTextInputBlur('maximumAcceptReservation', e)
                      }
                      disabled={editable}
                    />
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="maximumAcceptReservation"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(
                      formData.maximumAcceptReservation
                    )}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isGreaterOrEqual(
                      formData.maximumAcceptReservation,
                      1
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(1)}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.maximumAcceptReservation,
                      500
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(500)}
                  />
                </Validator>
              </FormGroup>
            )}
            {marketplace.enable && (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.notificationNearbyDriversBeforePickupTime === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.Notification_nearby_drivers_before_pickup_time" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="dispatchSetting.Notification_nearby_drivers_before_pickup_time_tooltip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={
                        formData.notificationNearbyDriversBeforePickupTime || ''
                      }
                      onChange={(e) => {
                        this.handleTextInputChange(
                          'notificationNearbyDriversBeforePickupTime',
                          e
                        );
                      }}
                      onBlur={(e) =>
                        this.handleTextInputBlur(
                          'notificationNearbyDriversBeforePickupTime',
                          e
                        )
                      }
                      disabled={editable}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={
                        this.state.formData
                          ? this.state.formData
                              .notificationNearbyDriversBeforePickupTimeUnit ||
                            'minute'
                          : 'minute'
                      }
                      onChange={(e) => {
                        this.handleTextIHrsMinTypeChange(
                          'notificationNearbyDriversBeforePickupTime',
                          e
                        );
                      }}
                      disabled={editable}
                    >
                      <option value={'minute'}>{I18n.t('General.mins')}</option>
                      <option value={'hour'}>{I18n.t('General.hrs')}</option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="notificationNearbyDriversBeforePickupTime"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(
                      formData.notificationNearbyDriversBeforePickupTime
                    )}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isGreaterOrEqual(
                      formData.notificationNearbyDriversBeforePickupTime,
                      10
                    )}
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(10)}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.notificationNearbyDriversBeforePickupTime,
                      9999
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(9999)}
                  />
                </Validator>
              </FormGroup>
            )}

            {marketplace.enable && (selectedFleet?.process?.broadcastBooking || true) && (
              <FormGroup
                className={
                  !isSubmited
                    ? null
                    : valid.shortNoticeOrder === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="dispatchSetting.shortNoticeOrder" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="dispatchSetting.shortNoticeOrderTooltip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup className={'qup-input-group'}>
                  <InputGroup
                    className={
                      'single-addon-right' + (editable ? ' disabled' : '')
                    }
                  >
                    <FormControl
                      type="number"
                      className={'form-custom number-no-spin'}
                      value={formData.shortNoticeOrder}
                      onChange={(e) => {
                        this.handleTextInputChange('shortNoticeOrder', e);
                      }}
                      onBlur={(e) =>
                        this.handleTextInputBlur('shortNoticeOrder', e)
                      }
                      disabled={editable}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={
                        this.state?.formData?.shortNoticeOrderUnit || 'hour'
                      }
                      onChange={this.handleShortNoticeOrderUnit}
                      disabled={editable}
                    >
                      <option value={'hour'}>{I18n.t('General.hrs')}</option>
                      <option value={'day'}>{I18n.t('General.days')}</option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator
                  id="shortNoticeOrder"
                  callback={this.ValidatorCallback}
                >
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isInteger(formData.shortNoticeOrder)}
                    message={I18n.t('messages.commonMessages.must_be_integer')}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={
                      this.state?.formData?.shortNoticeOrderUnit === 'day'
                        ? Validation.isGreaterOrEqual(
                            formData.shortNoticeOrder,
                            1
                          )
                        : Validation.isGreaterOrEqual(
                            formData.shortNoticeOrder,
                            0
                          )
                    }
                    message={I18n.t(
                      'messages.commonMessages.greater_or_equa'
                    ).format(
                      this.state?.formData?.shortNoticeOrderUnit === 'day'
                        ? 1
                        : 24
                    )}
                  />
                  <ValidCase
                    hide={!isSubmited}
                    valid={Validation.isLessOrEqual(
                      formData.shortNoticeOrder,
                      9999
                    )}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format(9999)}
                  />
                </Validator>
              </FormGroup>
            )}

            {shuttle.enable && (
              <Shuttle
                formData={formData}
                shuttleDispatchMode={this.state.shuttleDispatchMode}
                shuttleMinimumBookAhead={this.state.shuttleMinimumBookAhead}
                shuttleMaximumBookAhead={this.state.shuttleMaximumBookAhead}
                editable={editable}
                valid={valid}
                isSubmitted={isSubmited}
                ValidatorCallback={this.ValidatorCallback}
                checkDispatchMode={this.checkDispatchMode}
                handleTextInputChange={this.handleTextInputChange}
                handleChangeShuttleUnit={this.handleChangeShuttleUnit}
              />
            )}
            {/* {marketplace.enable &&
              <MarketPlace
                formData={formData}
                editable={editable}
                valid={valid}
                isSubmitted={isSubmited}
                ValidatorCallback={this.ValidatorCallback}
                handleTextInputChange={this.handleTextInputChange}
              />
            } */}

            <FormGroup className="form-inline text-left button-right-group direction-row">
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className={'btn-save'}
                  disabled={!this.state.isChanged}
                  onClick={this.handleSaveClick}
                >
                  <Translate value="dispatchSetting.Save" />
                </Button>
              ) : (
                ''
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

DispatchSetting.contextTypes = {
  notification: PropTypes.func,
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    fleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DispatchSetting);
