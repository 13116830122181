import React, { PureComponent, Fragment } from 'react';
import {
  FormGroup,
  Button,
  Image,
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import _ from 'lodash';

import { getDriverTotalBalance } from '../../../actions/driverAction';
import * as loadingBarActions from '../../../actions/loadingBarActions';

import totalBalanceIcon from '../../../assets/images/icons/withdrawal_active.svg';
import TotalBalanceModal from './TotalBalanceModal';

class TotalBalance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowModal: false
    };
  }

  handleShowTotalBalance = () => {
    const { getDriverTotalBalance, auth } = this.props;
    this.props.loadingBarActions.showLoadingSpiner();
    getDriverTotalBalance({ fleetId: auth.selectedFleet.fleetId }).then(
      data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        console.log(data);
        this.setState({ isShowModal: true, totalBalance: data.res });
      }
    );
  };

  handleCloseModal = () => {
    this.setState({ isShowModal: false });
  };

  render() {
    const { isShowModal, totalBalance } = this.state;
    const { getDriverTotalBalance, auth } = this.props;
    const driverDeposit = _.get(auth, 'selectedFleet.driverDeposit');
    const cashBalance = _.get(auth, 'selectedFleet.driverCashWallet');
    if (!driverDeposit && !cashBalance) {
      return null;
    }
    return (
      <Fragment>
        {isShowModal && (
          <TotalBalanceModal
            closeModal={this.handleCloseModal}
            totalBalance={totalBalance}
            selectedFleet={auth.selectedFleet}
          />
        )}
        <Button
          className="btn-header text-add-header"
          onClick={this.handleShowTotalBalance}
        >
          <Translate
            value="driver.total_balance"
            className="collapse-filter-title"
          />
        </Button>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDriverTotalBalance: bindActionCreators(getDriverTotalBalance, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalBalance);
