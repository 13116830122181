import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const Dialog = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.key" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.key || ''}
          onChange={(e) => {
            props.handleInputChange('key', e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default Dialog;
