import React, {useState, useEffect} from 'react'
import checkIcon from '../../../assets/images/icons/checkIcon.svg'
import checkedIcon from '../../../assets/images/icons/checkedIcon.svg'

const TravelerRow = ({
  isPointPickup,
  passenger,
  hasSelect,
  addCustomerMove,
  removeCustomerMove,
  customersSelected
}) => {

  
  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    if(customersSelected && customersSelected.length == 0) {
      setChecked(false)
    }
  }, [customersSelected])

  const handleSelectTravaler = () => {
    if(hasSelect) {
      if(isChecked) {
        removeCustomerMove(passenger)
      } else {
        addCustomerMove(passenger)
      }
      setChecked(!isChecked)
    }
    return
  }

  const classContainer = hasSelect ? 'ptPassengers hasSelect' : 'ptPassengers'

  return (
    <p className={classContainer} onClick={handleSelectTravaler}>
      <span>{passenger.fullName}</span>
      <span>
        {passenger.phone}
        {
          hasSelect &&
          <span className="iconCheck">
            <img 
              src={isChecked ? checkedIcon : checkIcon} 
            />
          </span>
        }
      </span>
    </p>
  )
}

export default TravelerRow