import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import {
  STATUS_EXTENAL_INFO,
  thirdPartyIntegration,
} from '../../../constants/commondata';

const Accept3rdButton = ({
  isAcceptConfirm3rdBookingStatus,
  statusExtenalInfo,
  handleAcceptOrConfirm3rdPartyBooking,
  nameThirdParty,
  bookInfo
}) => {
  if(!isAcceptConfirm3rdBookingStatus) return null

  if (nameThirdParty === thirdPartyIntegration.holidaytaxis)
    return (
      <Button
        onClick={handleAcceptOrConfirm3rdPartyBooking}
        className="btn-send"
      >
        {statusExtenalInfo === STATUS_EXTENAL_INFO.PCAN ||
        statusExtenalInfo === STATUS_EXTENAL_INFO.PAMM ? (
          <Translate value="bookingdetail.Confirm" />
        ) : (
          <Translate value="bookingdetail.Accept" />
        )}
      </Button>
    );

  if (nameThirdParty === thirdPartyIntegration.bookingAPI || nameThirdParty === thirdPartyIntegration.hoppa)
    return (
      <Button
        onClick={handleAcceptOrConfirm3rdPartyBooking}
        className="btn-send"
      >
        <Translate value="bookingdetail.Accept" />
      </Button>
    );

  return null;
};

Accept3rdButton.defaultProps = {
  isAcceptConfirm3rdBookingStatus: false,
  statusExtenalInfo: '',
  bookInfo: {},
  handleAcceptOrConfirm3rdPartyBooking: {},
};

Accept3rdButton.propTypes = {
  isAcceptConfirm3rdBookingStatus: PropTypes.bool.isRequired,
  statusExtenalInfo: PropTypes.string.isRequired,
  bookInfo: PropTypes.string.isRequired,
  handleAcceptOrConfirm3rdPartyBooking: PropTypes.func.isRequired,
};

export default Accept3rdButton;
