import moment from 'moment'

export function Messages(code, data = {}) {
  switch (code) {
    case 'invalidSenderInformation':
      return {
        title: 'Invalid Sender Information',
        message:
          'A multi-stop group can only have one set of pickup date, sender info, ride-share, payment type, and car type.',
      }
    case 'invalidRecipientInformation':
      return {
        title: 'Invalid Recipient Information',
        message: 'Recipient information is invalid.',
      }
    case 'invalidCod':
      return {
        title: 'Invalid Payment Type',
        message: 'Cash on recipient cannot be applied on multi-stop group',
      }
    case 'invalidPaymentType':
      return {
        title: 'Invalid Payment Type',
        message:
          'Multi-stop groups only support Cash by Sender and Direct invoicing as the payment type.',
      }
    case 'invalidSequenceNumber':
      return {
        title: 'Invalid Sequence Number',
        message:
          'Multi-stop sequence numbers cannot be empty or duplicated, and must be arranged in order.',
      }
    case 'invalidNumberOfRecipients':
      return {
        title: 'Invalid number of recipients:',
        message:
          'Maximum recipients in one multi-stop group is ${maxRecipients}.',
      }
    case 'invalidSenderAddress':
      return {
        title: 'Invalid Sender Information',
        message: 'Invalid address',
      }
    case 'invalidRecipientAddress':
      return {
        title: 'Invalid Recipient Information',
        message: 'Invalid address',
      }
    case 'carTypeNotSupportedForThisLocation':
    case 'UNSUPPORTED_CAR_TYPE':
      return {
        title: 'Car type not supported for this location',
        message:
          'This service is not available for this area. Please check with your admin to enable this option.',
      }
    case 'UNSUPPORTED_ZONE':
      return {
        title: 'Zone is not supported',
        message:
          'This service is not available for this area. Please check with your admin to enable this option.',
      }
    case 'INVALID_PICKUP_TIME':
      if (data.maximumBookAhead) {
        const timeStr =
          data.maximumBookAhead > 1
            ? `${data.maximumBookAhead} days`
            : `${data.maximumBookAhead} day`
        return {
          title: 'Invalid Pickup Time',
          message: `Reservation pickup time cannot be later than ${timeStr} from current time`,
        }
      } else if (data.minimumBookAhead) {
        const d = moment.duration(data.minimumBookAhead, 'minutes')
        const hours = d.hours()
        const minutes = d.minutes()
        const timeArr = []
        if (hours) {
          timeArr.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`)
        }
        if (minutes) {
          timeArr.push(`${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`)
        }
        return {
          title: 'Invalid Pickup Time',
          message: `Reservation pickup time must be at least ${timeArr.join(
            ' '
          )} from current time`,
        }
      } else {
        return {
          title: 'Invalid Pickup Time',
          message: 'Invalid pickup time',
        }
      }
    case 'EXTERNAL_ID_EXISTED':
    case 'duplicateBookingReference': {
      return {
        title: 'Duplicate Booking Reference',
        message: 'Booking reference should not be duplicated throughout all bookings',
      }
    }
    case 'invalidBookingReference': {
      return {
        title: 'Invalid Booking Reference',
        message: 'Booking reference should be the same throughout all recipients',
      }
    }
    default:
      return {
        title: 'Something went wrong',
        message: 'Something went wrong',
      }
  }
}
