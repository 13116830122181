import {
  CORPORATE_COMPANY_REQUEST,
  CORPORATE_COMPANY_SUCCESS,
  CORPORATE_COMPANY_FAILURE,
} from "../actions/corporateCompanyAction";

export default function corporateCompany(state=[],action={}){
    switch(action.type){
        case CORPORATE_COMPANY_REQUEST:{
            return state;
        }
        case CORPORATE_COMPANY_SUCCESS:{
            return action.data;
        }
        case CORPORATE_COMPANY_FAILURE:{
            return state;
        }
        default:
            return state
    }
}