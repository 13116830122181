import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportCreditWalletApi } from '../../../constants/ApiConfigs';
import { transactionTypesDriverWallet } from '../../../constants/commondata';
import { I18n } from "react-redux-i18n";

function ReportCreditWallet({ selectedFleet ,user}) {
  let newTransactionTypesDriverWallet = [...transactionTypesDriverWallet]
  const hideTypes = [];
  const drvWalletConfig = _.get(selectedFleet, 'drvWalletConfig', []);
  var gatewayMOLPay = drvWalletConfig.some(walletGateway => walletGateway.gateway == 'MOLPay')
  if (!gatewayMOLPay) {
    hideTypes.push('topUpMOLPay');
  }

  var gatewayTelebirr = drvWalletConfig.some(walletGateway => walletGateway.gateway == 'Telebirr')
  if (!gatewayTelebirr) {
    hideTypes.push('topUpTelebirr');
  }

  if (!selectedFleet.creditConfig.enable) {
    hideTypes.push('topUp');
  }

  if (!selectedFleet.generalSetting.driverInsurance) {
    hideTypes.push('insurance');
  }
  
  if (!selectedFleet.moduleSettings.merchant) {
    hideTypes.push('merchantCommission');
  }
    // add driver wallet by setting (based ConfigWallet collection mongoDB)
  if(drvWalletConfig && drvWalletConfig.length > 0) {
    newTransactionTypesDriverWallet = [...transactionTypesDriverWallet]
      drvWalletConfig.map((wallet) => {
        if( 
          wallet.drvApp 
          && wallet.gateway
          && wallet.gateway != 'MOLPay'
          || wallet.gateway == 'Kushok'
        ) {
          newTransactionTypesDriverWallet.push({
              value: wallet.gateway, 
              label: `Top up by ${I18n.t(`report.query.paymentMethodItem.${wallet.gateway}`)}`
          })
        }
      })
  }

  if(!_.get(
    selectedFleet,
    'outstandingPayment.enable',
    false
  )) {
    hideTypes.push('customerDebt')
  }

  const enableWithdrawalCashToCredit = _.get(
    selectedFleet,
    'driverCashWalletConfig.cashToCredit.enable'
  );
  if (!selectedFleet.driverCashWallet || !enableWithdrawalCashToCredit) {
    hideTypes.push('cashWallet');
  }
  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules,{"name":'Report'});
  let checkExport = _.findIndex(report.actions,{"name":"Driver Deposit","Export":true});
  let checkOwner = !user.isAdmin && typeof user.roles.fleetId !== 'undefined' ? user.roles.fleetId.ownerId === user._id ? true : false : true ;
  checkExport = user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
  if(!user.isAdmin && selectedFleet.fleetId === "applecabssouth"){
    checkExport = false;
  }
  return (
    <div className="content">
      <BaseReport
        tittle="Driver deposit"
        fieldMappings={fieldMappings}
        apiurl={reportCreditWalletApi}
        dateRange
        company
        driver
        currency
        transactionType={newTransactionTypesDriverWallet.filter(o => hideTypes.indexOf(o.value) === -1)}
        search="report.query.search.creditWallet"
        multiLineSummary="currencies"
        multiCurrencies
        driverUserName="driverUserName"
        noexport={!checkExport}
      />
    </div>
  );
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(ReportCreditWallet);