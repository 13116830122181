import {
    userFindApi,
    userFindOneApi,
    userCreateApi,
    userCreateSupplierApi,
    userUpdatedApi,
    userDeleteApi,
    userActiveApi,
    userRolesGetApi,
    userResetPasswordApi,
    exportUserToExcelApi, 
    userUpdateSupplierApi,
    userUpdateActiveSupplierApi
} from '../constants/ApiConfigs'
import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAILURE = "USERS_FAILURE";

export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAILURE = "USER_ROLE_FAILURE";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const USER_ACTIVATE_REQUEST = "USER_ACTIVATE_REQUEST";
export const USER_ACTIVATE_SUCCESS = "USER_ACTIVATE_SUCCESS";
export const USER_ACTIVATE_FAILURE = "USER_ACTIVATE_FAILURE";

export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS";
export const USER_EDIT_FAILURE = "USER_EDIT_FAILURE";

export const USER_ADD_REQUEST = "USER_ADD_REQUEST";
export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAILURE = "USER_ADD_FAILURE";

function getUserRolesRequest(){
    return{
        type:USER_ROLE_REQUEST
    }
}

function getUserRolesSuccess(data){
    return{
        type:USER_ROLE_SUCCESS,
        data:data.res
    }
}

function getUserRolesFailure(error){
    return{
        type:USER_ROLE_FAILURE,
        error
    }
}
export  function getUserRoles(fleetId){
    const config = {
        method: "get"
    };
    return callApi(
        userRolesGetApi+fleetId,
        config,
        getUserRolesRequest(),
        getUserRolesSuccess,
        getUserRolesSuccess,
        true
    );
}

function getUsersRequest(){
    return{
        type:USERS_REQUEST
    }
}

function getUsersSuccess(data){
    return{
        type:USERS_SUCCESS,
        data:data.res
    }
}

function getUsersFailure(error){
    return{
        type:USERS_FAILURE,
        error
    }
}
export  function getUsers(options){
 options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userFindApi,
        config,
        getUsersRequest(),
        getUsersSuccess,
        getUsersFailure,
        true
    );
}

function changeUserStatusRequest(){
    return{
        type:USER_ACTIVATE_REQUEST
    }
}

function changeUserStatusSuccess(data){
    return{
        type:USER_ACTIVATE_SUCCESS,
        data:data.res
    }
}

function changeUserStatusFailure(error){
    return{
        type:USER_ACTIVATE_FAILURE,
        error
    }
}

export  function changeUserStatus(options){
 options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userActiveApi,
        config,
        changeUserStatusRequest(),
        changeUserStatusSuccess,
        changeUserStatusFailure,
        true
    );
}

export function getOneUser(options){
    options = Object.assign({}, options);
    const config = {
        method: "get"
    };
    return callApi(
        UrlBuilder(userFindOneApi,options),
        config,
        null,
        null,
        null,
        true
    );
}

export function addUser(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function addUserSupplier(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userCreateSupplierApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateUserSupplier(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userUpdateSupplierApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function userActiveSupplier(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userUpdateActiveSupplierApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateUser(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userUpdatedApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteUser(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function resetPasswordUser(options){
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        userResetPasswordApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function exportUserToExcel(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        exportUserToExcelApi,
        config,
        null,
        null,
        null,
        false
    );
}
