import React from 'react';
import BaseReport from '../base';
import { connect } from 'react-redux';
import * as fieldMappings from './fields';
import { reportDriverReferralDetailApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';

function ReferralDetail(props) {
  const modules = _.get(props.selectedFleet, 'modules', []);
  const report = _.find(modules, { name: 'Report' });
  let checkExport = _.findIndex(report.actions, {
    name: 'Driver refers customer (Bookings)',
    Export: true,
  });
  let checkOwner =
    !props.user.isAdmin && typeof props.user.roles.fleetId !== 'undefined'
      ? props.user.roles.fleetId.ownerId === props.user._id
        ? true
        : false
      : true;
  checkExport =
    props.user.isAdmin || checkOwner ? true : checkExport === -1 ? false : true;
  if (!props.user.isAdmin && props.selectedFleet.fleetId === 'applecabssouth') {
    checkExport = false;
  }
  return (
    <div className="content">
      <BaseReport
        tittle="Financial Driver"
        fieldMappings={fieldMappings}
        apiurl={reportDriverReferralDetailApi}
        dateRange
        company
        driver
        currency
        paymentMethod
        search="report.query.search.driverRefersCustomerBookings"
        settings
        noexport={!checkExport}
      />
    </div>
  );
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReferralDetail);
