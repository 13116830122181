import {
	getServiceFeesSettingApi,
  userFindApi,
} from '../constants/ApiConfigs';
import { UrlBuilder, callApi } from '../utils/apiUtils';

export const SERVICE_FEE_SUCCESS = 'SERVICE_FEE_SUCCESS';
export const SERVICE_FEE_FAILURE = 'SERVICE_FEE_FAILURE';
export const SERVICE_FEE_REQUEST = 'SERVICE_FEE_REQUEST';

function getRequest() {
  return {
    type: SERVICE_FEE_REQUEST,
  };
}

function getSuccess(data) {
  return {
    type: SERVICE_FEE_SUCCESS,
    data: data.res,
  };
}

function getFailure(error) {
  return {
    type: SERVICE_FEE_FAILURE,
    error,
  };
}

export function getServiceFeesSetting(options = {}) {
	options = Object.assign({}, options);
	const config = {
			method: "GET"
	};

	return callApi(
			UrlBuilder(getServiceFeesSettingApi, options),
			config,
			getRequest(),
			getSuccess,
			getFailure,
			true
	);
}
