import React, { Fragment } from 'react';
import {
  FormGroup,
  Form,
  Tabs,
  Tab,
  InputGroup,
  FormControl,
  Row,
  Col,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../../components/validator';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import RadioButton from '../../../../../components/radioButton/radio';

export default class OtherFee extends React.Component {
  state = {
    currentFocus: {},
  };

  handleOtherFeeCheckChange = (e) => {
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('otherFeeActive', e.target.checked);
  };

  handleOtherFeeCanAddNoteCheckChange = (e) => {
    const { handleUpdateFormData } = this.props;
    handleUpdateFormData('otherFee.addNote', e.target.checked);
  };

  handleOtherFeeCanEditCheckChange = (e) => {
    const { handleUpdateFormData, ValidatorCallback } = this.props;
    if (!e.target.checked) {
      ValidatorCallback('otherFee_otherFeeDriverCanInput', true);
    }
    handleUpdateFormData('otherFee.isEdit', e.target.checked);
  };

  handeLimitDriverInputActiveChange = (e) => {
    const value = e.target.value == 'true';
    const { handleUpdateFormData, ValidatorCallback } = this.props;
    if (!value) {
      ValidatorCallback('otherFee_otherFeeDriverCanInput', true);
    }
    handleUpdateFormData('otherFee.limitDriverInputActive', value);
  };

  handleCancelInputMultiCurrenciesChange = (currency, e, keyName) => {
    const { handleUpdateFormData, formData } = this.props;
    let currentFieldValue = _.get(formData, keyName);
    let isAdd = true;
    const valueParse = (e.target.value || '')
      .toString()
      .replace(/[^.0-9]+/, '');
    const cur = {
      currencyISO: currency,
      value: valueParse,
    };
    currentFieldValue =
      currentFieldValue ||
      this.props.auth.selectedFleet.currencies.map((data) => ({
        value: 0,
        currencyISO: data.iso,
      }));
    currentFieldValue = currentFieldValue.map((c) => {
      if (c.currencyISO === currency) {
        isAdd = false;
        return { ...c, value: valueParse };
      }
      return c;
    });
    if (isAdd) {
      currentFieldValue.push(cur);
    }
    handleUpdateFormData(keyName, currentFieldValue);
  };

  getLimitOtherFee = (currency) => {
    const {
      formData,
      auth: { selectedFleet },
    } = this.props;
    const currencyCurrent = currency || selectedFleet.currencies[0];
    const maxOtherFee = 9999999999;
    if (!formData.otherFee.limitDriverInputActive || !formData.otherFee.isEdit)
      return maxOtherFee;
    const currencyAmountCurrent = _.find(
      formData.otherFee.otherFeeDriverCanInput || [],
      (o) =>
        o.currencyISO === currencyCurrent.currencyISO ||
        o.currencyISO === currencyCurrent.iso
    );
    return currencyAmountCurrent ? currencyAmountCurrent.value : maxOtherFee;
  };

  getCurrencyValue = (currenciesAmount, currencyISO, keyName) => {
    const currencyCurrent = _.find(
      currenciesAmount || [],
      (o) => o.currencyISO === currencyISO
    );
    if (currencyCurrent) {
      return this.parseInputValue(currencyCurrent.value, currencyISO, keyName);
    }
    return 0;
  };

  setFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = true;
    this.setState({
      currentFocus,
    });
  };

  setUnFocus = (key) => {
    const { currentFocus } = this.state;
    currentFocus[key] = false;
    this.setState({
      currentFocus,
    });
  };

  parseInputValue = (value, currencyISO, keyName) => {
    const { currentFocus } = this.state;
    const isFocues = currentFocus[keyName];

    if (isFocues) {
      return (value || '').toString().replace(/[^.0-9]+/, '') || 0;
    }

    return currencyFormatter.format(value || 0, {
      code: currencyISO,
      format: '%v',
    });
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  renderMultiCurrency = (key) => {
    const { formData, editable, auth } = this.props;
    const currentField = _.get(formData, key);
    return (
      <Tabs
        id="currencies-tabs-Ondemand"
        className="currencies-tabs"
        defaultActiveKey={auth.selectedFleet.currencies[0].iso}
        animation={false}
        onSelect={this.tabSelectHandle}
      >
        {auth.selectedFleet.currencies.map((c) => {
          const currentValue = currentField
            ? currentField.filter((d) => d.currencyISO === c.iso)[0]
            : null;
          return (
            <Tab
              className="currencies-tab-item"
              eventKey={c.iso}
              title={c.iso}
              key={c.iso}
            >
              <FormGroup className="qup-input-group">
                <InputGroup
                  className={`single-addon-left ${editable ? 'disabled' : ''}`}
                  >
                  <InputGroup.Prepend>
                    <InputGroup.Text>{c.symbol}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    className="fomt-custom"
                    onChange={(e) => {
                      this.handleCancelInputMultiCurrenciesChange(
                        c.iso,
                        e,
                        key
                      );
                    }}
                    onBlur={(e) => {
                      this.setUnFocus(key);
                      textboxNumberHelper.onBlurHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    onFocus={(e) => {
                      this.setFocus(key);
                      textboxNumberHelper.onfocusHandle(e, (event) => {
                        this.handleCancelInputMultiCurrenciesChange(
                          c.iso,
                          event,
                          key
                        );
                      });
                    }}
                    value={
                      currentValue
                        ? currentValue.value
                        : DefaultCurenciesNumberInputField
                    }
                    disabled={editable}
                  />
                  {' '}
                </InputGroup>
              </FormGroup>
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  renderInputCurrency = (keyName) => {
    const { formData, editable, auth, currency } = this.props;
    const currencyCurrent = currency || auth.selectedFleet.currencies[0];
    const currentField = _.get(formData, keyName);
    return (
      <FormGroup className="qup-input-group">
        <InputGroup
          className={`single-addon-left ${editable ? 'disabled' : ''}`}
        >
          <InputGroup.Prepend>
            <InputGroup.Text>{currencyCurrent.symbol}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="text"
            className="form-custom no-marginBottom"
            onChange={(e) => {
              this.handleCancelInputMultiCurrenciesChange(
                currencyCurrent.iso,
                e,
                keyName
              );
            }}
            onBlur={(e) => {
              this.setUnFocus(keyName);
              textboxNumberHelper.onBlurHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            onFocus={(e) => {
              this.setFocus(keyName);
              textboxNumberHelper.onfocusHandle(e, (event) => {
                this.handleCancelInputMultiCurrenciesChange(
                  currencyCurrent.iso,
                  event,
                  keyName
                );
              });
            }}
            value={this.getCurrencyValue(
              currentField,
              currencyCurrent.iso,
              keyName
            )}
            disabled={editable}
          />
          {' '}
        </InputGroup>
      </FormGroup>
    );
  };

  renderLimitDriverCanInput = () => {
    const { formData, auth, currency } = this.props;
    if (formData.otherFee.limitDriverInputActive) {
      return auth.selectedFleet.multiCurrencies && !currency
        ? this.renderMultiCurrency('otherFee.otherFeeDriverCanInput')
        : this.renderInputCurrency('otherFee.otherFeeDriverCanInput');
    }
    return null;
  };

  render() {
    const {
      editable,
      formData,
      isSubmited,
      valid,
      ValidatorCallback,
      currency,
      auth,
    } = this.props;
    let {
      otherFee: { otherFeeDriverCanInput },
    } = formData;
    if (!otherFeeDriverCanInput || !otherFeeDriverCanInput.length) {
      otherFeeDriverCanInput = [auth.selectedFleet.currencies[0]];
    }
    return (
      <Fragment>
        <CcCheckbox
          disabled={editable}
          checked={formData ? formData.otherFeeActive : false}
          onChange={this.handleOtherFeeCheckChange}
          text={I18n.t('generalSetting.OTHER_FEES')}
          labelClassName="title"
          className="form-group-title"
        />
        {formData && formData.otherFeeActive ? (
          <Row>
            <Col>
              <Col md={6}>
                <CcCheckbox
                  disabled={editable}
                  checked={formData ? formData.otherFee.isEdit : false}
                  onChange={this.handleOtherFeeCanEditCheckChange}
                  text={I18n.t('generalSetting.Can_edit')}
                />
              </Col>
              <Col md={6}>
                <CcCheckbox
                  disabled={editable}
                  checked={formData ? formData.otherFee.addNote : false}
                  onChange={this.handleOtherFeeCanAddNoteCheckChange}
                  text={I18n.t('generalSetting.Can_add_note')}
                />
              </Col>
              <Col md={12}>
                <Form.Label>
                  <Translate value="generalSetting.Other_fees_amount" />
                </Form.Label>
                <FormGroup
                  className={`no-marginBottom ${
                    !isSubmited
                      ? null
                      : valid.otherFee_valueByCurrencies === false
                      ? 'error'
                      : null
                  }`}
                >
                  {this.props.auth.selectedFleet.multiCurrencies && !currency
                    ? this.renderMultiCurrency('otherFee.valueByCurrencies')
                    : this.renderInputCurrency('otherFee.valueByCurrencies')}
                  <Validator
                    id="otherFee_valueByCurrencies"
                    callback={ValidatorCallback}
                  >
                    <ValidCase
                      valid={
                        formData.otherFee.valueByCurrencies.filter(
                          (d) =>
                            !Validation.isStringEmpty(d.value) &&
                            _.isNaN(d.value)
                        ).length === 0
                      }
                      message={I18n.t('messages.commonMessages.must_be_number')}
                      hide={!isSubmited}
                    />
                    <ValidCase
                      valid={
                        formData.otherFee.valueByCurrencies.filter(
                          (d) => !Validation.isGreaterOrEqual(d.value, 0)
                        ).length === 0
                      }
                      message={I18n.t(
                        'messages.commonMessages.greater_than'
                      ).format(0)}
                      hide={!isSubmited}
                    />
                    <ValidCase
                      valid={
                        formData.otherFee.valueByCurrencies.filter(
                          (d) =>
                            !Validation.isLessOrEqual(
                              d.value,
                              this.getLimitOtherFee(d)
                            )
                        ).length === 0
                      }
                      message={I18n.t('messages.commonMessages.limit_amount')}
                      hide={!isSubmited}
                    />
                  </Validator>
                  <WarningCase
                    validator={valid.otherFee_valueByCurrencies}
                    message={I18n.t('message.warningInputChangeOver')}
                    initialValue={formData.otherFee.valueByCurrencies.map(
                      (ob) => ob.value
                    )}
                    onChangeValue={formData.otherFee.valueByCurrencies.map(
                      (ob) => ob.value
                    )}
                    range={20}
                    typePercent={false}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                {formData.otherFee.isEdit ? (
                  <FormGroup className="no-marginBottom">
                    <Form.Label className="mr-r-50">
                      <Translate value="generalSetting.tollFeeDriverCanInput" />
                    </Form.Label>
                    <RadioButton
                      text={I18n.t('generalSetting.unlimited')}
                      inline
                      name="limitDriverInputActive"
                      value="false"
                      checked={!formData.otherFee.limitDriverInputActive}
                      onChange={this.handeLimitDriverInputActiveChange}
                      disabled={editable}
                    />
                    <RadioButton
                      text={I18n.t('generalSetting.limited')}
                      inline
                      name="limitDriverInputActive"
                      value="true"
                      checked={formData.otherFee.limitDriverInputActive}
                      onChange={this.handeLimitDriverInputActiveChange}
                      disabled={editable}
                    />
                  </FormGroup>
                ) : null}
                {formData.otherFee.isEdit &&
                formData.otherFee.limitDriverInputActive ? (
                  <FormGroup
                    className={`no-marginBottom ${
                      !isSubmited
                        ? null
                        : valid.otherFee_otherFeeDriverCanInput === false
                        ? 'error'
                        : null
                    }`}
                  >
                    {this.renderLimitDriverCanInput()}
                    <Validator
                      id="otherFee_otherFeeDriverCanInput"
                      callback={ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          otherFeeDriverCanInput.filter(
                            (d) =>
                              !Validation.isStringEmpty(d.value) &&
                              _.isNaN(d.value)
                          ).length === 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          otherFeeDriverCanInput.filter(
                            (d) => !Validation.isGreaterThan(d.value, 0)
                          ).length === 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_than'
                        ).format(0)}
                        hide={!isSubmited}
                      />
                      <ValidCase
                        valid={
                          otherFeeDriverCanInput.filter(
                            (d) =>
                              !Validation.isLessOrEqual(d.value, 9999999999)
                          ).length === 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.less_or_equal'
                        ).format(this.formatCurrency(9999999999))}
                        hide={!isSubmited}
                      />
                    </Validator>
                    <WarningCase
                      validator={valid.otherFee_otherFeeDriverCanInput}
                      message={I18n.t('message.warningInputChangeOver')}
                      initialValue={otherFeeDriverCanInput.map(
                        (ob) => ob.value
                      )}
                      onChangeValue={otherFeeDriverCanInput.map(
                        (ob) => ob.value
                      )}
                      range={20}
                      typePercent={false}
                    />
                  </FormGroup>
                ) : null}
              </Col>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}
