import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../settings.scss';
import {
  Tab,
  Tabs,
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  Button,
} from 'react-bootstrap';
import IntlTelInputApp from '../../../components/intlTelInputCustome/IntlTelInputApp';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import * as settingActions from '../../../actions/settingActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import RadioButton from '../../../components/radioButton/radio';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { ValidCase, Validator } from '../../../components/validator';
import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';

class EmailConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChanged: false,
      isSubmited: false,
      formData: null,
      stateCustomized: {
        isDefaultServer: false,
        fromName: '',
        email: '',
        replyTo: '',
        userAuth: '',
        password: '',
        smtpServer: '',
        port: '',
        isAuthenticated: false,
        option: 'none',
        isStarttlsEnable: false,
        isSSLEnable: false,
      },
      valid: {},
    };
  }
  componentDidMount() {
    this.handleGetEmailConfigDetail();
  }

  handleGetEmailConfigDetail = () => {
    this.props.settingActions
      .getEmailConfigDetail({ fleetId: this.props.auth.selectedFleet.fleetId })
      .then((data) => {
        if (data.res) {
          this.setState({
            stateCustomized: {
              ...this.state.stateCustomized,
              ...data.res.emailServer,
            },
          });
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification('error', I18n.t('emailConfig.loadFail'));
          }
        }
      });
  };

  hanldeChangeEmailType = (e) => {
    this.setState({
      stateCustomized: {
        ...this.state.stateCustomized,
        isDefaultServer: e,
      },
      isChanged: true,
    });
  };

  hanldeChangeCheckBoxAuthen = (value) => {
    this.setState({
      stateCustomized: {
        ...this.state.stateCustomized,
        isAuthenticated: value,
      },
      isChanged: true,
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      stateCustomized: {
        ...this.state.stateCustomized,
        option: e.target.value,
      },
      isChanged: true,
    });
  };

  handleChangeText = (key, value) => {
    this.setState({
      stateCustomized: {
        ...this.state.stateCustomized,
        [key]: value,
      },
      isChanged: true,
    });
  };

  ValidatorCallback = (id, valid) => {
    if (this.state && this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleUpdateEmailConfig = () => {
    this.setState({ isChanged: false });
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions
      .updateEmailConfigDetail({
        fleetId: this.props.auth.selectedFleet.fleetId,
        stateCustomized: this.state.stateCustomized,
      })
      .then((data) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok && !data.error) {
          this.context.notification('success', I18n.t('emailConfig.success'));
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification('error', I18n.t('emailConfig.fail'));
          }
          this.setState({ isChanged: true });
        }
      });
  };

  render() {
    const disabled = this.props.permissions && !this.props.permissions.actions;
    const { stateCustomized } = this.state;
    return (
      <div className="voice-sms-content seting-voice-sms">
        <Row>
          <Col
            md={8}
            sm={15}
            style={{
              background: '#30343D',
              borderRadius: '6px',
              marginLeft: '5px',
              paddingBottom: '7px',
              paddingTop: '7px',
            }}
          >
            <FormGroup className="radio-button-group">
              <Row style={{ display: 'flex', alignItems: 'end' }}>
                <Col xs={5}>
                  <Form.Label>
                    <Translate value="emailConfig.emailServer" />
                  </Form.Label>
                </Col>
                <Col xs={7}>
                  <RadioButton
                    text={I18n.t('emailConfig.default')}
                    value={true}
                    onChange={(e) => this.hanldeChangeEmailType(true)}
                    checked={stateCustomized.isDefaultServer}
                    id="default"
                    name="email_server"
                    disabled={disabled}
                  />
                  <RadioButton
                    text={I18n.t('emailConfig.customized')}
                    value={false}
                    name="email_server"
                    id="customized"
                    onChange={(e) => this.hanldeChangeEmailType(false)}
                    checked={!stateCustomized.isDefaultServer}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </FormGroup>
            {stateCustomized.isDefaultServer === false && (
              <>
                {Object.keys(stateCustomized)
                  .filter(
                    (item) =>
                      item !== 'isDefaultServer' &&
                      item !== 'isAuthenticated' &&
                      item !== 'option' &&
                      item !== 'isStarttlsEnable' &&
                      item !== 'isSSLEnable'
                  )
                  .map((item, index) => (
                    <FormGroup
                      className={`qup-input-group ${
                        !this.state.isSubmited
                          ? null
                          : this.state.valid[item] === false
                          ? 'error'
                          : null
                      }`}
                      key={index}
                    >
                      <Row>
                        <Col xs={5}>
                          <Form.Label>
                            {I18n.t(`emailConfig.${item}`)}
                            <span className="require">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={7}>
                          <FormControl
                            className="form-custom"
                            type={item === 'password' ? 'password' : 'text'}
                            value={stateCustomized[item]}
                            onChange={(e) =>
                              this.handleChangeText(item, e.target.value)
                            }
                            disabled={disabled}
                          />
                          <Validator
                            id={item}
                            callback={this.ValidatorCallback}
                          >
                            <ValidCase
                              valid={
                                !Validation.isStringEmpty(stateCustomized[item])
                              }
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                              hide={!this.state.isSubmited}
                            />
                          </Validator>
                        </Col>
                      </Row>
                    </FormGroup>
                  ))}

                <FormGroup>
                  <Row>
                    <Col xs={5}>
                      <Form.Label>
                        <Translate value="emailConfig.isAuthenticated" />
                      </Form.Label>
                    </Col>
                    <Col xs={7}>
                      <CcCheckbox
                        disabled={disabled}
                        checked={stateCustomized.isAuthenticated}
                        onChange={() =>
                          this.hanldeChangeCheckBoxAuthen(
                            !stateCustomized.isAuthenticated
                          )
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Col xs={5}>
                      <Form.Label>
                        <Translate value="emailConfig.option" />{' '}
                      </Form.Label>
                    </Col>
                    <Col xs={7}>
                      <FormControl
                        as="select"
                        className="form-custom"
                        value={
                          this.state.stateCustomized
                            ? this.state.stateCustomized.option
                            : ''
                        }
                        disabled={disabled}
                        onChange={this.handleOptionChange}
                      >
                        <option value="none">
                          {I18n.t('emailConfig.none')}
                        </option>
                        <option value="starttls">
                          {I18n.t('emailConfig.tls')}
                        </option>
                        <option value="ssl">{I18n.t('emailConfig.ssl')}</option>
                      </FormControl>
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}

            <FormGroup className="form-inline">
              {!this.props.permissions || this.props.permissions.actions ? (
                <Button
                  className={'btn-save mt-sm'}
                  disabled={!this.state.isChanged}
                  onClick={this.handleUpdateEmailConfig}
                >
                  <Translate value="emailConfig.Save" />
                </Button>
              ) : (
                ''
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

EmailConfig.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EmailConfig);
