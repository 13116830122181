import React from 'react';
import { connect } from 'react-redux';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportFinancialApi } from '../../../constants/ApiConfigs';
import * as commonData from '../../../constants/commondata';
import _ from 'lodash';
class ReportFinancial extends React.PureComponent {
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Financial (Driver)',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    let checkReferralDetailsActive = _.findIndex(report.actions, {
      name: 'Driver refers customer (Bookings)',
      isActive: true,
    });
    checkReferralDetailsActive =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkReferralDetailsActive === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    return (
      <div className="content">
        <BaseReport
          tittle="Financial (Fleet)"
          fieldMappings={fieldMappings}
          apiurl={reportFinancialApi}
          dateRange
          finishedStatus
          company
          driver
          bookingService={commonData.bookingServices.filter(
            (i) =>
              i.value !== 'all' &&
              i.value !== 'homeFleet' &&
              i.value !== 'roaming'
          )}
          currency
          paymentMethod
          shortTrip
          search="report.query.search.financial"
          noexport={!checkExport}
          isActivatedReferralDetails={checkReferralDetailsActive}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReportFinancial);
