import {
    callApi
  } from "../utils/apiUtils";
import {customerAutocompleteApi} from '../constants/ApiConfigs'

export const CUSTOMER_AUTOCOMPLETE_REQUEST = "CUSTOMER_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_AUTOCOMPLETE_SUCCESS = "CUSTOMER_AUTOCOMPLETE_SUCCESS";
export const CUSTOMER_AUTOCOMPLETE_FAILURE = "CUSTOMER_AUTOCOMPLETE_FAILURE";


function getCustomerAutocompleteRequest(){
    return{
        type:CUSTOMER_AUTOCOMPLETE_REQUEST
    }
}

function getCustomerAutocompleteSuccess(data){
    return{
        type:CUSTOMER_AUTOCOMPLETE_SUCCESS,
        data:data.res.list
    }
}

function getCustomerAutocompleteFailure(error){
    return{
        type:CUSTOMER_AUTOCOMPLETE_FAILURE,
        error
    }
}
export function customerAutocomplete(options){
     options = Object.assign({},options);
    const config = {
      method: "post",
      body: JSON.stringify(options)
    };

    return callApi(
        customerAutocompleteApi,
        config,
        getCustomerAutocompleteRequest(),
        getCustomerAutocompleteSuccess,
        getCustomerAutocompleteFailure
    );
}