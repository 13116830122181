import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { I18n } from 'react-redux-i18n';
import { Image } from 'react-bootstrap';
import { ErrorImage } from './errorImages';

export default class ErrorPage extends Component {
  render() {
    let errorType = this.props.errorType || 'NotFound';
    return (
      <div className={'error-img-container'}>
        <Helmet title={I18n.t('Page not found')} />
        <Image src={ErrorImage[errorType]} className="error-image" />
        <div className="error-message-container">
          <p className="oops">Oops!</p>
          <p className="error-message">
            {this.props.errorMessage
              ? this.props.errorMessage
              : I18n.t('General.' + errorType)}
          </p>
        </div>
      </div>
    );
  }
}
