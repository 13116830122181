import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import { convertFormat24h } from '../../../utils/commonFunctions';
import { I18n } from 'react-redux-i18n';
export const tableAPIKeyColumns = [
  {
    key: 'appName',
    label: 'APIKeySetting.appName',
    width: 120,
    fixed: true,
    textEllipsis: true,
    sortName: 'appName',
  },
  {
    key: 'clientId',
    label: 'APIKeySetting.clientKey',
    width: 140,
    textEllipsis: true,
  },
  {
    key: 'clientSecret',
    label: 'APIKeySetting.secretKey',
    width: 140,
    textEllipsis: true,
  },
  {
    key: 'clientType',
    label: 'APIKeySetting.clientType',
    width: 140,
    textEllipsis: true,
  },
  {
    key: 'createdDate',
    label: 'APIKeySetting.Created',
    width: 120,
    sortName: 'createdDate',
    customCell: (data, index, fleetSetting) => {
      if (data.createdDate)
        return moment(data.createdDate).format(
          `MMM DD YYYY, ${convertFormat24h(fleetSetting.format24Hour)}`
        );
      return '';
    },
  },
  {
    key: 'Webhook',
    label: 'APIKeySetting.Webhook',
    width: 120,
    customCell: (data) => {
      if (data.webhooks)
        return `${data?.webhooks?.length} ${
          data.webhooks?.length > 1 ? 'hooks' : 'hook'
        }`;
      return '';
    },
  },
  {
    key: 'actions',
    label: 'mDispatcher.Actions',
    width: 100,
    align: 'right',
    cellClass: 'table-actions',
  },
];
