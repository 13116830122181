import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'payoutId',
    label: 'report.result.affiliationPayoutHistory.payoutId',
  },
  {
    key: 'dateTime',
    label: 'report.result.affiliationPayoutHistory.dateTime',
  },
  {
    key: 'settlement',
    label: 'report.result.affiliationPayoutHistory.settlement',
    mutate: (value) =>
      I18n.t(`report.query.affiliationSettlementItem.${value}`),
  },
  {
    key: 'paymentMethod',
    label: 'report.result.affiliationPayoutHistory.paymentMethod',
    mutate: (value) => I18n.t(`report.query.affiliationPaymentMethod.${value}`),
  },
  {
    key: 'totalPayout',
    label: 'report.result.affiliationPayoutHistory.totalPayout',
    summary: 'totalPayout',
    mutate: ReportUtils.formatFare,
    summaryMutate: ReportUtils.formatFare,
  },
  {
    key: 'viewDetails',
    label: 'report.result.affiliationPayoutHistory.viewDetails',
    link: {
      label: 'report.query.view',
      newTab: true,
      href: '/reports/affiliation/payout_history/details',
      name: 'goToAffiliationPayoutDetails',
      params: ['payoutId'],
      excludeParams: ['txtSearch', 'pickUpZone'],
      description: 'Show list bookings of a payout transaction',
    },
  },
];
