import React from 'react';
import BaseReport from './../base';
import * as fieldMappings from './fields';
import { reportPenaltyCompensationApi } from '../../../constants/ApiConfigs';

const bookingStatus = [
  { value: 'all', label: 'report.query.bookingStatusItem.all' },
  { value: 'incident', label: 'report.query.bookingStatusItem.incident' },
  { value: 'paxNoShow', label: 'report.query.bookingStatusItem.paxNoShow' },
  {
    value: 'canceledByTimeOut',
    label: 'report.query.bookingStatusItem.canceledByTimeOut',
  },
  {
    value: 'canceledByCustomer',
    label: 'report.query.bookingStatusItem.canceledByCustomer',
  },
  {
    value: 'canceledBySupplier',
    label: 'report.query.bookingStatusItem.canceledBySupplier',
  },
];

class ReportAffiliation extends React.Component {
  render() {
    return (
      <div className="content">
        <BaseReport
          tittle="Affiliation"
          fieldMappings={fieldMappings}
          apiurl={reportPenaltyCompensationApi}
          bookingStatus={bookingStatus}
          dateRange
          supHeader
          nofooter
          noexport
          search="report.query.search.penaltyCompensation"
        />
      </div>
    );
  }
}
export default ReportAffiliation;
