import _ from 'lodash';
import ReportUtils from '../base/utils';

export const fields = [
  {
    label: 'report.result.financialProfit.bookingId',
    key: 'bookId',
    summary: 'bookId',
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    label: 'report.result.financialProfit.subTotal',
    key: 'subTotal',
    summary: 'subTotal',
    summaryUnit: 'key',
  },
  {
    key: 'subTotalFF',
    label: 'report.result.financialProfit.subTotalFF',
    summary: 'subTotalFF',
    summaryUnit: 'key',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const modules = _.get(selectedFleet, 'modules', []);
      return !_.find(
        modules,
        (m) => m.name === 'Driver to pax' || m.name === 'Pax refers Pax'
      );
    },
  },
  {
    label: 'report.result.financialProfit.fleetCommission',
    key: 'comm',
    summary: 'comm',
    summaryUnit: 'key',
  },
  {
    label: 'report.result.financialProfit.fleetServiceFee',
    toolTip: 'report.result.financialProfit.fleetServiceFeeTooltip',
    key: 'fleetCommissionFromServiceFee',
    summary: 'fleetCommissionFromServiceFee',
    summaryUnit: 'key',
  },
  {
    label: 'report.result.financialProfit.techFee',
    key: 'techFee',
    summary: 'techFee',
    summaryUnit: 'key',
  },
  {
    label: 'report.result.financialProfit.promotion',
    key: 'promoAmount',
    summary: 'promoAmount',
    summaryUnit: 'key',
  },
  {
    key: 'markupDifference',
    label: 'report.result.financialProfit.markupDifference',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const markupPrice = _.get(
        selectedFleet,
        'generalSetting.markupPrice',
        false
      );
      return !markupPrice;
    },
    summary: 'markupDifference',
    summaryUnit: 'key',
  },
  {
    label: 'report.result.financialProfit.profit',
    key: 'profit',
    summary: 'profit',
    summaryUnit: 'key',
    negativable: true,
  },
  {
    label: 'report.result.financialProfit.currency',
    key: 'currencyISO',
    summary: 'currencyISO',
  },
];

export const floatFields = [
  'subTotal',
  'subTotalFF',
  'comm',
  'techFee',
  'promoAmount',
  'markupDifference',
  'profit',
  'fleetCommissionFromServiceFee',
];
