import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportIncompletePaymentApi } from '../../../constants/ApiConfigs';
import { connect } from 'react-redux';
import _ from 'lodash';
class ReportAffiliation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      listColumnSelected: this.getLocalColumns(),
    };
  }

  getLocalColumns = () => {
    let selectColumns = [];
    let columnDefault = fieldMappings.fields
    _.forEach(columnDefault, col => {
      if (col.isAlwayShow) {
        selectColumns.push(col.key);
      }
    });
    return selectColumns;
  };

  handleSelectColumn = list => {
    if (list.length === 0) {
      list = this.getLocalColumns();
    };
    this.setState({
      listColumnSelected: list
    });
  };

  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, {"name": 'Report'});
    let checkExport = _.findIndex(report.actions, {"name": "Incomplete payment", "Export": true});
    let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
    checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
      if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
          checkExport = false;
      }

    const listColumnSelected = this.state.listColumnSelected
    let customFieldMappings = {...fieldMappings}
    
    customFieldMappings.fields = _.filter(
      [...fieldMappings.fields], 
      (column) => {
        return listColumnSelected.includes(column.key)
      }
    )
    return (
        <div className="content">
          <BaseReport
              tittle="Incomplete Payment"
              fieldMappings={customFieldMappings}
              apiurl={reportIncompletePaymentApi}
              listColumnSelected={this.state.listColumnSelected}
              listDefaultColumn={fieldMappings.fields}
              handleSelectColumn={this.handleSelectColumn}
              hasCustomShowColumns
              paymentOriginalStatus
              paymentCurrentStatus
              bookFrom
              timeFilterType
              dateRange
              supHeader
              currency
              company
              search="report.query.search.incompletePayment"
              noexport={!checkExport}
              />
        </div>
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(ReportAffiliation);