import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import ReportDriverThumbRating from "./../driver-thumb-rating"
import ReportDriverStarsRating from "./../driver-stars-rating"

class ReportRating extends React.Component {
  render() {
    if (_.get(this.props.selectedFleet, "passenger.drvRating", 1) === 0) {
      return <ReportDriverStarsRating />
    } else {
      return <ReportDriverThumbRating />
    }
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportRating)
