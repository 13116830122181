import React, { useState } from 'react';
import { Translate } from 'react-redux-i18n';
import FormatItem from './FormatItem';

const AirportPickup = ({
  booking,
  lineShort,
  auth,
  isMobile
}) => {
  const flightInfo = booking?.request?.moreInfo?.flightInfo || {},
    flightawareHyperlink = auth?.selectedFleet?.additionalService?.flightawareHyperlink || true,
    ident = flightInfo.ident;

  const getInfoFlight = (e, ident, flightNumber) => {
    e.stopPropagation()
    const flightCode = ident ? ident : flightNumber
    window.open(`https://flightaware.com/live/flight/${flightCode}`)
  }

  const flightNumberText = (
    <>
      {flightInfo.flightNumber && flightawareHyperlink ? (
            <>
              <span
                className={'identInfo'}
                onClick={(e) =>
                  getInfoFlight(
                    e,
                    ident,
                    flightInfo.flightNumber
                  )
                }
              >
                {flightInfo.flightNumber}
              </span>
            </>
          ) : (
            <>
              {flightInfo.airline && <p>{flightInfo.airline}</p>}
              {flightInfo.flightNumber && (
                <p>{flightInfo.flightNumber}</p>
              )}
            </>
          )}
    </>
  )
  if(isMobile) {
    return (
      <>
        {
          flightInfo?.flightNumber && (
            <FormatItem 
              text={flightNumberText}
              bgColor={'rgba(255, 255, 255, 0.12)'}
            />
          )
        }
      </>
    )
  }
  return (
    <div>
      {flightInfo ? (
        <div>
          {flightNumberText}
          {flightInfo.type >= 0 &&
          booking.request.type == 1 &&
          !booking.intercity &&
          !lineShort ? (
            <span>
              {' - '}
              {flightInfo.type == 0 ? (
                <Translate value="cue.On_curb" />
              ) : (
                <Translate value="cue.Meet_Greet" />
              )}
            </span>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
};

export default AirportPickup;
