import React from 'react';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { Tooltip, OverlayTrigger, FormGroup } from 'react-bootstrap';
import ReactDatetime from 'react-datetime';

import _ from 'lodash';
import CCDropDown from '../../../../components/dropdown/ccDropDown';
import IntercityDropDown from '../../../../components/dropdown/IntercityDropDown';
import {
  WithdrawalStatusItems,
  WithdrawalTransactionTypeItems,
} from '../../../../constants/commondata';

const Year = ({ selectedItems, items, onSelect }) => (
  <CCDropDown
    id="year-dropdown"
    items={items}
    title={I18n.t('report.query.year')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    isNumber
  />
);
const PayoutTime = ({ selectedItems, items, onSelect, onScrollBottom }) => (
  <CCDropDown
    id="payout-time-dropdown"
    items={items}
    title={I18n.t('report.query.payoutDriver.payoutEndTime')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    onScrollBottom={onScrollBottom}
    style={{ maxWith: '420px !important' }}
  />
);
const Category = ({
  items,
  selectedItems,
  onSelect,
  onScrollBottom,
  onSearchChange,
}) => (
    <CCDropDown
      id="category-dropdown"
      items={items}
      title=""
      selectedItems={selectedItems}
      onScrollBottom={onScrollBottom}
      onSearchChange={onSearchChange}
      valueKey="value"
      labelKey="label"
      onSelect={onSelect}
      searchable
      noTranslateLabel
      async
    />
  );

const Company = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="company-dropdown"
      items={items}
      title={I18n.t('report.query.company')}
      selectedItems={selectedItems}
      valueKey="value"
      labelKey="label"
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      noTranslateLabel
      searchable
      async
      enableClearAll
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
    />
  );

const Corporate = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="corporate-dropdown"
    items={items}
    title={I18n.t('Sidebar.Corporate')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);
const Airline = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="airline-dropdown"
    items={items}
    title={I18n.t('Sidebar.Airline')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);
const Operator = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="operator-dropdown"
    title={I18n.t('report.query.operator')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    noTranslateLabel
  />
);

const CarType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="vehicleType-dropdown"
    title={I18n.t('report.query.vehicleType')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    noTranslateLabel
  />
);

const CorporateName = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="CorporateName-dropdown"
    title={I18n.t('report.query.CorporateName')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    noTranslateLabel
  />
);

const CarTypeMultiple = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="car-type-multiple"
    title="cue.Car_type"
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
    noTranslateLabel
  // id="car-type-dropdown"
  // title={I18n.t("cue.Car_type")}
  // items={items}
  // selectedItems={selectedItems}
  // labelKey="lable"
  // valueKey="value"
  // onSelect={onSelect}
  // multiSelect
  // enableClearAll
  // searchable
  />
);

const Plate = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="carLicensePlate-dropdown"
      items={items}
      title={I18n.t('report.query.carLicensePlate')}
      valueKey="_id"
      labelKey="plateNumber"
      onSelect={onSelect}
      selectedItems={selectedItems}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="plate-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
    />
  );

const Driver = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="driver-dropdown"
      title={I18n.t('report.query.driver')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const DriverReferrer = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="driver-referrer-dropdown"
      title={I18n.t('report.query.referer')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-referrer-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const Referee = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="referee-dropdown"
      title={I18n.t('report.query.referee')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="referee-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const Customer = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="driver-dropdown"
      title={I18n.t('report.query.customer')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );

const Referer = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="driver-dropdown"
      title={I18n.t('report.query.referer')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );

const ReferralCode = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="referral-code-dropdown"
      title={I18n.t('report.query.referralCode')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );

const intercityLabel = (item) => (
  <div>
    <OverlayTrigger
      overlay={
        <Tooltip id="intercity-label-tooltip">{`${item.firstLocation.name} - ${item.secondLocation.name}`}</Tooltip>
      }
      placement="top"
      delayShow={100}
      delayHide={150}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {`${item.firstLocation.name} - ${item.secondLocation.name}`}
      </div>
    </OverlayTrigger>
    {_.map(item.carType, (cartype) => (
      <div className="text-more-info">
        <span>{cartype.vehicleType}</span>
        <span> - </span>
        <span>
          {item.routeSetting.pricePerSeat[0].value.toLocaleString('us-US', {
            style: 'currency',
            currency: item.routeSetting.pricePerSeat[0].currencyISO,
          })}
        </span>
      </div>
    ))}
  </div>
);

const UsedPromocodes = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
  customLabel,
}) => (
    <CCDropDown
      id="driver-dropdown"
      title={I18n.t('report.query.usedPromoCode')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customLabel={customLabel}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );

const FarmType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="farmType-dropdown"
    items={items}
    title={I18n.t('report.query.farmType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const Campaign = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="company-dropdown"
      items={items}
      title={I18n.t('report.query.campaign_report')}
      selectedItems={selectedItems}
      valueKey="value"
      labelKey="label"
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      noTranslateLabel
      searchable
      async
      enableClearAll
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
    />
  );

// ///////////////////////////
const Promocode = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="promocode-dropdown"
    items={items}
    title={I18n.t('report.query.promocode')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);

const PromocodeType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="adjustPrice-dropdown"
    items={items}
    title="Promotion type"
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
    hasSelectInitial
  />
);

const VoucherCode = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="voucher-code-dropdown"
      title={I18n.t('report.query.voucherCode')}
      valueKey="id"
      labelKey="value"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const MDispatcherType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="mDispatcherType-dropdown"
    items={items}
    title={I18n.t('report.query.mDispatcherType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);

const MDispatcher = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="mDispatcher-dropdown"
    items={items}
    title={I18n.t('report.query.mDispatcher')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);
const BookingService = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('report.query.bookingService')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const PayoutOption = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="payoutOption-dropdown"
    items={items}
    title={I18n.t('report.query.payoutOption')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const WithdrawalStatus = ({ selectedItems, onSelect }) => (
  <CCDropDown
    id="withdrawal-status-dropdown"
    items={WithdrawalStatusItems}
    title={I18n.t('report.query.withdrawalStatus')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const WithdrawalTransactionType = ({ selectedItems, onSelect }) => (
  <CCDropDown
    id="withdrawal-transaction-type-dropdown"
    items={WithdrawalTransactionTypeItems}
    title={I18n.t('report.query.withDrawalTransactionType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const Currency = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="currency-dropdown"
    items={items}
    title={I18n.t('report.query.currency')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    noTranslateLabel
  />
);

const CanceledBy = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="canceledBy-dropdown"
    items={items}
    title={I18n.t('report.query.canceledBy')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

// ////
const Action = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="action-dropdown"
    items={items}
    title={I18n.t('report.query.action')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const LoginStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="status-dropdown"
    items={items}
    title={I18n.t('report.query.status')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const Module = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="module-dropdown"
    items={items}
    title={I18n.t('report.query.module')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const DriverType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="driverType-dropdown"
    items={items}
    title={I18n.t('report.query.driverType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const MerchantType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="merchantType-dropdown"
    items={items}
    title={I18n.t('report.query.merchantType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const MerchantService = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="merchantService-dropdown"
    items={items}
    title={I18n.t('report.query.merchantService')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const UserType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="userType-dropdown"
    items={items}
    title={I18n.t('report.query.userType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const EventType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="eventType-dropdown"
    items={items}
    title={I18n.t('report.query.eventType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const Event = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="eventId-dropdown"
      title={I18n.t('report.query.eventName')}
      valueKey="value"
      labelKey="label"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const EventState = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="eventState-dropdown"
    items={items}
    title={I18n.t('report.query.eventState')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const Store = ({
  title,
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="eventId-dropdown"
      title={I18n.t(title || 'report.query.storeName')}
      valueKey="value"
      labelKey="label"
      items={items}
      selectedItems={selectedItems}
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="driver-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          {customTitle}
        </OverlayTrigger>
      }
      searchable
      async
      enableClearAll
      noTranslateLabel
    />
  );
const ExpiredDate = ({ value, onChange, locale }) => (
  <FormGroup>
    <ReactDatetime
      viewMode="days"
      inputProps={{
        contenteditable: false,
        placeholder: I18n.t('report.query.expiredDate'),
      }}
      value={value}
      dateFormat="MM/DD/YYYY"
      timeFormat={false}
      onChange={onChange}
      locale={locale}
      closeOnSelect
    />
  </FormGroup>
);
const FilterDate = ({ value, onChange, locale }) => (
  <FormGroup>
    <ReactDatetime
      viewMode="days"
      inputProps={{
        contenteditable: false,
        placeholder: I18n.t('report.query.payoutDriver.payoutEndTime'),
      }}
      value={value}
      dateFormat="MM/DD/YYYY"
      timeFormat="hh:mm A"
      onChange={onChange}
      locale={locale}
      closeOnSelect
    />
  </FormGroup>
);

// /////////////////////////

const BookFrom = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="booking-from-dropdown"
    items={items}
    title={I18n.t('report.query.bookingFrom')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const ThirdPartyAction = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="third-party-action-dropdown"
    items={items}
    title={I18n.t('report.query.thirdPartyAction')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const FinishedStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="finishedStatus-dropdown"
    items={items}
    title={I18n.t('report.query.bookingStatus')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const AffiliationPayoutType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="affiliation-payout-type-dropdown"
    items={items}
    title={I18n.t('report.query.payoutType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const PaymentMethod = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="paymentMethod-dropdown"
    items={items}
    title={I18n.t('report.query.paymentMethod')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);
const MultiSelectionsFilter = ({ items, selectedItems, onSelect, title }) => (
  <CCDropDown
    id="paymentMethod-dropdown"
    items={items}
    title={I18n.t(title)}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const TipAfterCompleted = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="tip-dropdown"
    title={I18n.t('report.query.tip')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    hasAll
    onlyShowAllText
  />
);

const BookingStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="booking-status-dropdown"
    items={items}
    title={I18n.t('report.query.bookingStatus')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const Settlement = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('report.query.settlement')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const NetworkType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('report.query.bookingService')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);
const CompanySettlementType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('report.query.settlementType')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const PayoutPaymentStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('report.query.paymentStatus')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const PaymentStatus = ({ title, items, selectedItems, onSelect }) => (
  <CCDropDown
    id="paymentStatus-dropdown"
    items={items}
    title={I18n.t(title || 'report.query.paymentStatus')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const BookingType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingType-dropdown"
    items={items}
    title={I18n.t('report.query.bookingType')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);
const AdjustPrice = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="adjustPrice-dropdown"
    items={items}
    title={I18n.t('report.query.adjustPrice')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);
const IntercityRoute = ({
  items,
  selectedItems,
  selectedRouteNumber,
  onSelect,
  customTitle,
}) => (
    <IntercityDropDown
      id="intercity-routes-dropdown"
      items={items}
      title={I18n.t('report.query.intercityRoute')}
      valueKey="value"
      labelKey="label"
      routeNumber="routeNumber"
      selectedItems={selectedItems}
      selectedRouteNumber={selectedRouteNumber}
      onSelect={onSelect}
      enableClearAll
      searchable
      intercityRoutesMode
      customLabel={intercityLabel}
      customTitle={customTitle}
    />
  );

// IncidentCanceledTypes
const IncidentCanceledTypes = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="type-dropdown"
    items={items}
    title={I18n.t('report.query.type')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const ServiceType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="serviceType-dropdown"
    items={items}
    title={I18n.t('report.query.serviceType')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);
const RatingType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="ratingType-dropdown"
    title={I18n.t('report.query.ratingType')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const CarStars = ({ items, title, selectedItems, onSelect }) => (
  <CCDropDown
    id="stars-dropdown"
    title={I18n.t(title || 'report.query.driverRating')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
    isNumber
  />
);

const VehicleStars = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="vehicleRate-dropdown"
    title={I18n.t('report.query.vehicleRating')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
    isNumber
  />
);
const TransactionType = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="transactionType-dropdown"
    title={I18n.t('report.query.transactionType')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const RideService = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="rideService-dropdown"
    title={I18n.t('report.query.rideService')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const Timezone = ({
  items,
  selectedItems,
  onSelect,
  onSearchChange,
  onScrollBottom,
  customTitle,
}) => (
    <CCDropDown
      id="timezone-dropdown"
      items={items}
      title={I18n.t('report.query.timezone')}
      selectedItems={selectedItems}
      valueKey="timezone"
      labelKey="text"
      onSelect={onSelect}
      onSearchChange={onSearchChange}
      onScrollBottom={onScrollBottom}
      className="timeZoneReport"
      customTitle={
        <OverlayTrigger
          overlay={<Tooltip id="time-zone-tooltip">{customTitle}</Tooltip>}
          placement="top"
          delayShow={200}
          delayHide={150}
        >
          <span className="dropdown-title">{customTitle}</span>
        </OverlayTrigger>
      }
      searchable
      async
    />
  );

const PaymentOriginalStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="originalStatus-dropdown"
    title="report.query.originalStatus"
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const PaymentCurrentStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="currentStatus-dropdown"
    title={I18n.t('report.query.currentStatus')}
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const PickupZone = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="pickUpZone-dropdown"
    title="cue.Zone"
    valueKey="value"
    labelKey="label"
    items={items}
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const NotificationsStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="notificationsStatus-dropdown"
    className="menu-container"
    items={items}
    title={I18n.t('notifications.statusItem.all')}
    valueKey="value"
    labelKey="label"
    selectedItems={selectedItems}
    onSelect={onSelect}
    multiSelect
    onlyShowAllText
  />
);

const sosStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="bookingService-dropdown"
    items={items}
    title={I18n.t('notifications.statusItem.all')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const shortTrip = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="shortTrip-dropdown"
    items={items}
    title={I18n.t('report.query.shortTrip.shortTrip')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const StatusDriver = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="status-dropdown"
    items={items}
    title={I18n.t('report.query.status')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

const DocumentExpiryStatus = ({ items, selectedItems, onSelect }) => (
  <CCDropDown
    id="status-dropdown"
    items={items}
    title={I18n.t('report.query.status')}
    selectedItems={selectedItems}
    valueKey="value"
    labelKey="label"
    onSelect={onSelect}
  />
);

export default {
  Year,
  Category,
  Company,
  Corporate,
  Operator,
  CarType,
  CorporateName,
  CarTypeMultiple,
  Plate,
  Driver,
  Customer,
  Referer,
  ReferralCode,
  UsedPromocodes,
  FarmType,
  Campaign,
  Promocode,
  MDispatcherType,
  MDispatcher,
  BookingService,
  Currency,
  CanceledBy,
  IncidentCanceledTypes,
  Action,
  LoginStatus,
  Module,
  DriverType,
  ExpiredDate,
  BookFrom,
  ThirdPartyAction,
  PaymentMethod,
  BookingStatus,
  PaymentStatus,
  BookingType,
  IntercityRoute,
  ServiceType,
  RatingType,
  CarStars,
  VehicleStars,
  TransactionType,
  RideService,
  Timezone,
  WithdrawalStatus,
  WithdrawalTransactionType,
  PaymentOriginalStatus,
  PaymentCurrentStatus,
  PickupZone,
  NotificationsStatus,
  sosStatus,
  TipAfterCompleted,
  shortTrip,
  PayoutOption,
  FilterDate,
  PayoutTime,
  UserType,
  EventType,
  Event,
  EventState,
  VoucherCode,
  DriverReferrer,
  Referee,
  StatusDriver,
  AdjustPrice,
  Airline,
  MerchantService,
  MerchantType,
  Store,
  DocumentExpiryStatus,
  PromocodeType,
  MultiSelectionsFilter,
  FinishedStatus,
  AffiliationPayoutType,
  PayoutPaymentStatus,
  Settlement,
  NetworkType,
  CompanySettlementType,
};
