import {paymentMethodApi} from '../constants/ApiConfigs'
import {
  callApi
} from "../utils/apiUtils";

export const PAYMENT_METHOD_REQUEST = "PAYMENT_METHOD_REQUEST";
export const PAYMENT_METHOD_SUCCESS = "PAYMENT_METHOD_SUCCESS";
export const PAYMENT_METHOD_FAILURE = "PAYMENT_METHOD_FAILURE";

function paymentMethodRequest(){
    return{
        type:PAYMENT_METHOD_REQUEST
    }
}

function paymentMethodSuccess(data){
    return{
        type:PAYMENT_METHOD_SUCCESS,
        data:data.res
    }
}

function paymentMethodFailure(error){
    return{
        type:PAYMENT_METHOD_FAILURE,
        error
    }
}
export  function paymentMethod(fleetId){
    const config = {
      method: "get"
    };

    return callApi(
        paymentMethodApi+fleetId,
        config,
        paymentMethodRequest(),
        paymentMethodSuccess,
        paymentMethodFailure
    );
}