import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import {
  reportPaidToDriverApi,
  payToDriverApi,
  clearSettlementApi
} from '../../../constants/ApiConfigs';
import * as loadingBarActions from '../../../actions/loadingBarActions';

const doPayToDrivers = function (data) {
  this.setState({ confirm: false });
  const drivers = data.list
    .filter(doc => data.rowCheckBoxes.indexOf(doc.driverId) > -1)
    .map(doc => doc.driverId);
  const driverNames = data.list
    .filter(doc => data.rowCheckBoxes.indexOf(doc.driverId) > -1)
    .map(doc => doc.driverName);
  this.props.loadingBarActions.showLoadingSpiner();
  this.props.reportActions
    .payToDriver(payToDriverApi, {
      drivers,
      query: data.lastQuery,
      driverNames
    })
    .then(response => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (response.res) {
        this.fetchReportData();
        this.context.notification(
          'success',
          I18n.t('report.result.payToDriver.paymentSuccessfully')
        );
      } else {
        this.context.notification('error', handleErrPay(response));
      }
    })
    .catch(error => {
      this.props.loadingBarActions.hideLoadingSpiner();
      this.context.notification('error', error.toString());
    });
};

const handleErrPay = (response) => {
  let message = response.error.message || '';
  if(response?.error?.returnCode && message) {
    message = `${I18n.t(`messages.credits.${response.error.returnCode}`)} (${message})`
  }
  return message
}

class ReportPaidToDriver extends React.Component {
  payToDriver(link, data, e) {
    const requestBody = Object.assign({}, data.queryBody);
    requestBody.criteria.driverId = data.row.driverId;
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.reportActions
      .payToDriver(payToDriverApi, requestBody)
      .then(response => {
        this.props.loadingBarActions.hideLoadingSpiner();
        this.setState({ confirm: false });
        if (response.res) {
          this.context.notification(
            'success',
            I18n.t('report.result.payToDriver.paymentSuccessfully')
          );
          this.fetchReportData();
        } else {
          this.context.notification('error', handleErrPay(response));
        }
      })
      .catch(error => {
        this.props.loadingBarActions.hideLoadingSpiner();
        this.context.notification('error', error.toString());
      });
  }

  payToDrivers(e) {
    if (
      _.isEmpty(this.state.rowCheckBoxes)
      || (this.state.rowCheckBoxes[0] === 'all' && this.state.rowCheckBoxes.length === 1)
    ) {
      this.setState({
        confirm: {
          title: I18n.t('report.result.payToDriver.pay'),
          body: I18n.t('report.result.payToDriver.selectDriver.body')
        }
      });
    } else {
      const self = this;
      const data = _.cloneDeep(this.state);
      this.handleConfirmButtonClick = doPayToDrivers.bind(self, data);
      this.setState({
        confirm: {
          title: I18n.t('report.result.payToDriver.pay'),
          body: I18n.t('report.result.payToDriver.confirmPay.body'),
          closeButtonText: I18n.t('report.result.payToDriver.confirmPay.closeButtonText'),
          buttonTitle: I18n.t('report.result.payToDriver.confirmPay.buttonTitle')
        }
      });
    }
  }
  clearSettlement(link, data, e) {
    const requestBody = Object.assign({}, data.queryBody);
    requestBody.criteria.driverId = data.row.driverId;
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.reportActions
        .clearSettlement(
        clearSettlementApi,
        Object.assign(
            {
              fleetId: requestBody.fleetId,
              currency: requestBody.criteria.currency
            },
            data.row
        )
    )
        .then(response => {
          this.props.loadingBarActions.hideLoadingSpiner();
          if (response.res) {
            this.setState({
              confirm: false,
              list: this.state.list.filter(doc => doc.driverId != data.row.driverId),
              total: this.state.total - 1,
              summary: {
                totalUnsettledTransactions:
                this.state.summary.totalUnsettledTransactions - data.row.totalUnsettledTransactions,
                totalUnsettledAmount:
                this.state.summary.totalUnsettledAmount - data.row.totalUnsettledAmount,
                size: this.state.summary.size - 1
              },
              footerData: {
                ...this.state.footerData,
              total: this.state.total - 1
            }
          });
          this.context.notification('success', I18n.t('report.result.payToDriver.clearSuccess'));
        } else {
      let message = I18n.t(`errors.${response.error.errorCode}`);
      if (!response.error.errorCode) {
        message = I18n.t('report.result.payToDriver.clearFailed');
      }
      this.context.notification('error', message);
    }
  })
.catch(error => {
      this.props.loadingBarActions.hideLoadingSpiner();
      this.context.notification('error', error.toString());
});
}
  render() {
    const dateRange = [
      {
        value: 'thisYear',
        key: 'thisYear',
        label: 'report.query.thisYear'
      },
      {
        value: 'thisMonth',
        key: 'thisMonth',
        label: 'report.query.thisMonth',
        default: true
      },
      {
        value: 'lastMonth',
        key: 'lastMonth',
        label: 'report.query.lastMonth'
      },
      {
        value: 'custom',
        key: 'custom',
        label: 'report.query.custom'
      }
    ];

    const bookingService = [
      {
        value: 'localBooking',
        label: 'report.query.bookingServiceItem.localBooking'
      },
      {
        value: 'provideService',
        label: 'report.query.bookingServiceItem.provideService'
      }
    ];

    let actions = null;
    const listFieldMappings = _.extend({}, fieldMappings);
    const listFields = _.filter(fieldMappings.fields, field => field.key != 'actions');
    if (!this.props.permissions || this.props.permissions.actions) {
      actions = {
        payToDriver: this.payToDriver,
        payToDrivers: this.payToDrivers,
        clearSettlement:this.clearSettlement
      };
    } else {
      listFieldMappings.fields = listFields;
    }

    return (
      <div className="content">
        <BaseReport
          tittle="Pay to driver"
          fieldMappings={listFieldMappings}
          apiurl={reportPaidToDriverApi}
          dateRange={dateRange}
          company
          driver
          bookingService={bookingService}
          currency
          paymentMethod
          customViewButton
          actions={actions}
          noexport
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPaidToDriver);
