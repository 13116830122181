import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n';
import { POINT_TYPE_TEXT } from '../../constants/commondata'
import EditPointModal from './EditPointModal3rdGG'
import EditPointDeliveryModal from './EditPointDeliveryModal3rdGG'
import PointInputDragGroup from './PointInputDragGroup'
import _ from 'lodash'
import {
  getMenuDataFromRecipient,
} from '../../components/bookingDetail/bookFunction/serviceRequest';

const PointContainer = ({
  puPoints,
  setNewPuPoint,
  doPoints,
  setNewDoPoint,
  checkPointInZoneSupported,
  recentPlaces = {},
  fleetId,
  limitPoint,
  isBookDetails,
  jobType,
  checkPickupEditable,
  checkDestinationEditable,
  checkBookingEditable,
  setIsShowRoutePreview,
  partyLocation,
  isBookingFromPaxApp,
  format24Hour,
  options,
  data,
  auth,
  corporateCompany,
  newBooking,
  cueActions,
  currencyISO,
  isSubmited,
  valid,
  promo,
  customer,
  traveler_type,
  cartypeSelected,
  paymentMethod,
  customerAutocompleteActions,
  customerAutocomplete,
  customerActions,
  listDirtyLocation,
  isHydraBooking,
  setDirtyLocation,
  isChina,
  corporate,
  handleShowHideMarkFailed
}) => {
  const [showEditPoint, setShowEditPoint] = useState(false)
  const [valueEditPoint, setValueEditPoint] = useState('')
  const [dataEditingPUPoint, setDataEditingPUPoint] = useState({})

  // Add a new point from form AddPointModal
  const setNewPoints = (newPoint, listCurrent, key) => {
    let idPoint = newPoint.id
    let pointIndex = listCurrent.findIndex((obj => obj.id == idPoint))
    let addressObj = newPoint.address || {}
    if (_.isEmpty(addressObj)) {
      listCurrent[pointIndex] = {
        id: newPoint.id,
        hasLocation: false,
        order: newPoint.order || 0
      }
    } else {
      addressObj.geo = [addressObj.lng, addressObj.lat]
      addressObj.from = newPoint.from
      addressObj.address = newPoint.addressPointText
      if(listCurrent[pointIndex]) {
        listCurrent[pointIndex].address = addressObj
        listCurrent[pointIndex].hasLocation = true
        listCurrent[pointIndex].addressPointText = newPoint.addressPointText
        listCurrent[pointIndex].from = newPoint.from
        listCurrent[pointIndex].id = newPoint.id
        listCurrent[pointIndex].order = newPoint.order
        listCurrent[pointIndex].seats = newPoint.seats
        listCurrent[pointIndex].luggage = newPoint.luggage
        listCurrent[pointIndex].time = newPoint.time
        listCurrent[pointIndex].notes = newPoint.notes
        listCurrent[pointIndex].supportHydraBooking = newPoint.supportHydraBooking || false
        listCurrent[pointIndex].supportLocalBooking = newPoint.supportLocalBooking || false
        listCurrent[pointIndex].sender = newPoint.sender
        listCurrent[pointIndex].recipient = newPoint.recipient
      }
    }
    setNewPointsByType([...listCurrent], key)
  }

  // Remove Point from pu/do list point
  const removePoint = (id, pointType) => {
    if (!id || !pointType) return

    let newPoints = []
    if (setDirtyLocation) {
      setDirtyLocation(listDirtyLocation.filter((locationId) => locationId !== id))
    }
    if (pointType == POINT_TYPE_TEXT.pu) {
      newPoints = puPoints.filter((point) => point.id != id)
    }
    if (pointType === POINT_TYPE_TEXT.do) {
      newPoints = doPoints.filter((point) => point.id != id)
    }

    setNewPointsByType(newPoints, pointType)
  }

  // set new data for point edited
  const submitEditPoint = (point) => {
    let updatedPoint = []
    if (point.pointType == POINT_TYPE_TEXT.pu && puPoints.length > 0) {
      updatedPoint = [...puPoints]
    }
    if (point.pointType == POINT_TYPE_TEXT.do && doPoints.length > 0) {
      updatedPoint = [...doPoints]
    }
    let pointIndex = updatedPoint.findIndex((obj => obj.id == point.id));
    let dataMeta = point.dataMeta || {}
    updatedPoint[pointIndex].address = point.address
    updatedPoint[pointIndex].hasLocation = true
    updatedPoint[pointIndex].addressPointText = point.addressPointText
    updatedPoint[pointIndex].from = point.from
    updatedPoint[pointIndex].id = point.id
    updatedPoint[pointIndex].order = point.order
    updatedPoint[pointIndex].seats = dataMeta.seats
    updatedPoint[pointIndex].luggage = dataMeta.luggage
    updatedPoint[pointIndex].time = dataMeta.time
    updatedPoint[pointIndex].notes = dataMeta.notes

    updatedPoint[pointIndex].sender = point.sender
    updatedPoint[pointIndex].recipient = point.recipient
    if (point.pointType == POINT_TYPE_TEXT.do) {
      if (updatedPoint[pointIndex].recipient && !updatedPoint[pointIndex].recipient.menuData) {
        updatedPoint[pointIndex].recipient.menuData = {
          menuId: "bfc-menuid",
          selectedItems: getMenuDataFromRecipient(updatedPoint[pointIndex].recipient, newBooking.locationService ? newBooking.locationService.currency : null)
        }
      }
    }
    setNewPointsByType(updatedPoint, point.pointType)
  }

  const setNewPointsByType = (points, type) => {
    if (type === POINT_TYPE_TEXT.pu) {
      setNewPuPoint(points)
    } else {
      setNewDoPoint(points)
    }
  }

  const AddMoreInputPickup = (listCurrent, key) => {
    setNewPointsByType(
      [
        ...listCurrent,
        {
          order: listCurrent.length + 1,
          id: new Date().getTime(),
          hasLocation: false
        }
      ],
      key
    )
  }

  const handleShowAddPointPickup = (id, type) => {
    let pointEdit = puPoints.find((ob) => ob.id == id)
    if (type === POINT_TYPE_TEXT.do) {
      pointEdit = doPoints.find((ob) => ob.id == id)
    }

    if (pointEdit && pointEdit.id) {
      pointEdit.pointType = type
      let addressPointText = pointEdit.address && pointEdit.address.address || ''
      setDataEditingPUPoint(pointEdit)
      setValueEditPoint(addressPointText)
      setShowEditPoint(true)
    }
  }

  const checkHasPointEmpty = () => {
    if (
      puPoints.some(ob => _.isEmpty(_.get(ob, 'address', {})))
      || doPoints.some(ob => _.isEmpty(_.get(ob, 'address', {})))
    ) {
      return true
    }
    return false
  }

  // Disable Add Traveler shuttle
  let disabledAddTraveler = true
  if (
    doPoints.length > 0
    && puPoints.length > 0
    && jobType === 'shuttle'
    && !checkHasPointEmpty()
  ) {
    disabledAddTraveler = false
  }

  // set max point from setting Admin
  const isDisableAddPickup = ((puPoints.length > 0 && jobType === 'delivery') || (puPoints.length >= limitPoint.maxPickup && jobType != 'shuttle')) ? true : false
  const isDisableAddDropOff = (doPoints.length >= limitPoint.maxDropOff && jobType != 'shuttle') ? true : false

  // Disable add/edit/drag from status booking
  let isDisableAddEditPickup = true
  let isDisableAddEditDropOff = true
  let isDisableEditBooking = true

  if (isBookDetails) {
    isDisableAddEditPickup = checkPickupEditable()
    isDisableAddEditDropOff = checkDestinationEditable()
    isDisableEditBooking = checkBookingEditable()
  }
  let isAbleAddDropOff = !(isDisableAddDropOff || !isDisableAddEditDropOff);
  if (jobType === 'delivery') {
    const unCompletedReceipient = doPoints.filter(item => !item.recipient || item.recipient.status != "completed").length;
    const deliveredReceipient = doPoints.filter(item => item.recipient && item.recipient.status == "delivered").length;
    isAbleAddDropOff = !isDisableAddDropOff && isDisableEditBooking && unCompletedReceipient > 0
      && !(unCompletedReceipient == 1 && deliveredReceipient == 1);
  }
  return (
    <div className="createPoint">

      {/* pickup points */}
      <div className="PointsDetail pickupPoint">
        <div className="dragAdd">
          <span>
            {jobType === 'delivery' ? I18n.t("newbooking.Sender") : I18n.t("newbooking.Pickup_Point")}
            <span className="require"> *</span>
          </span>
          {
            !(isDisableAddPickup || !isDisableAddEditPickup) &&
            <button
              className="btn_add"
              onClick={() => AddMoreInputPickup(puPoints, POINT_TYPE_TEXT.pu)}
            >
              +
            </button>
          }
          {
            !disabledAddTraveler &&
            <button
              className="btn_Preview"
              onClick={() => setIsShowRoutePreview(true)}
            >
              Preview
            </button>
          }
          <div>

          </div>
        </div>
        <PointInputDragGroup
          listPoint={puPoints}
          limitNumberPoint={limitPoint.enable ? limitPoint.maxPickup : 1}
          corporate={corporate}
          disabledEditDrag={!isDisableAddEditPickup}
          jobType={jobType}
          removePoint={(id) => { removePoint(id, POINT_TYPE_TEXT.pu) }}
          disabledAddTraveler
          isBookingFromPaxApp={isBookingFromPaxApp}
          changeListPoints={
            (newList) => setNewPointsByType(newList, POINT_TYPE_TEXT.pu)
          }

          // shuttle
          format24Hour={format24Hour}

          // new 
          hasPartyLocation={partyLocation}
          isShow={false}
          titleTime={I18n.t("newbooking.Pick_up_time")}
          setShowAddPoint={(id) => handleShowAddPointPickup(id, POINT_TYPE_TEXT.pu)}
          recentPlaces={recentPlaces.pickup}
          options={options}
          setNewPoints={
            (newPoint) => setNewPoints(newPoint, puPoints, POINT_TYPE_TEXT.pu)
          }
          checkPointInZoneSupported={checkPointInZoneSupported}
          checkSupportZone
          pointType={POINT_TYPE_TEXT.pu}
          fleetId={fleetId}
          newBooking={newBooking}
          isSubmited={isSubmited}
          data={data}
          listDirtyLocation={listDirtyLocation}
          setDirtyLocation={setDirtyLocation}
          isHydraBooking={isHydraBooking}
          pickUp
          isChina={isChina}
          auth={auth}
        />
      </div>

      {/* Drop points */}
      <div className="PointsDetail dropOffPoint">
        <div className="dragAdd dropoff">
          <span>
            {jobType === 'delivery' ? I18n.t("newbooking.Recipient") : I18n.t("newbooking.Destination")}
            {(jobType === 'shuttle' || jobType === 'delivery') && <span className="require"> *</span>}
          </span>
          {
            isAbleAddDropOff &&
            <button
              className="btn_add"
              onClick={() => AddMoreInputPickup(doPoints, POINT_TYPE_TEXT.do)}
            >
              +
              {/* + {I18n.t("newbooking.Add")} */}
            </button>
          }
        </div>

        <PointInputDragGroup
          listPoint={doPoints}
          limitNumberPoint={limitPoint.enable ? limitPoint.maxDropOff : 1}
          isBookDetails={isBookDetails}
          handleShowHideMarkFailed={handleShowHideMarkFailed}
          disabledEditDrag={!isDisableAddEditDropOff}
          isDisableEditBooking={!isDisableEditBooking}
          jobType={jobType}
          removePoint={(id) => { removePoint(id, POINT_TYPE_TEXT.do) }}
          disabledAddTraveler
          isBookingFromPaxApp={isBookingFromPaxApp}
          data={data}
          changeListPoints={
            (newList) => setNewPointsByType(newList, POINT_TYPE_TEXT.do)
          }

          // new 
          hasPartyLocation={partyLocation}
          titleTime={I18n.t("newbooking.Pick_up_time")}
          recentPlaces={recentPlaces.destination}
          options={options}
          setNewPoints={
            (newPoint) => setNewPoints(newPoint, doPoints, POINT_TYPE_TEXT.do)
          }
          checkPointInZoneSupported={checkPointInZoneSupported}
          fleetId={fleetId}

          // shuttle
          setIsShowRoutePreview={setIsShowRoutePreview}
          pointType={POINT_TYPE_TEXT.do}
          format24Hour={format24Hour}
          setShowAddPoint={(id) => handleShowAddPointPickup(id, POINT_TYPE_TEXT.do)}
          newBooking={newBooking}
          isSubmited={isSubmited}
          listDirtyLocation={listDirtyLocation}
          setDirtyLocation={setDirtyLocation}
          isChina={isChina}
          auth={auth}
        />
      </div>
      {jobType === 'delivery' ?
        <EditPointDeliveryModal
          checkPointInZoneSupported={checkPointInZoneSupported}
          fleetId={fleetId}
          isDisableAddEditPickup={isDisableAddEditPickup}
          isDisableAddEditDropOff={isDisableAddEditDropOff}
          recentPlaces={recentPlaces}
          hasPartyLocation={partyLocation}
          isShow={showEditPoint}
          value={valueEditPoint}
          setValueEditPoint={setValueEditPoint}
          setShowAddPoint={setShowEditPoint}
          submitEditPoint={submitEditPoint}
          dataEditingPoint={dataEditingPUPoint}
          jobType={jobType}
          options={options}
          format24Hour={format24Hour}
          isBookDetails={isBookDetails}
          auth={auth}
          corporateCompany={corporateCompany}
          newBooking={newBooking}
          cueActions={cueActions}
          currencyISO={currencyISO}
          data={data}
          isSubmited={isSubmited}
          valid={valid}
          promo={promo}
          customer={customer}
          traveler_type={traveler_type}
          cartypeSelected={cartypeSelected}
          paymentMethod={paymentMethod}
          customerAutocompleteActions={customerAutocompleteActions}
          customerAutocomplete={customerAutocomplete}
          customerActions={customerActions}
        /> :
        <EditPointModal
          checkPointInZoneSupported={checkPointInZoneSupported}
          fleetId={fleetId}
          recentPlaces={recentPlaces}
          hasPartyLocation={partyLocation}
          isShow={showEditPoint}
          value={valueEditPoint}
          auth={auth}
          setValueEditPoint={setValueEditPoint}
          setShowAddPoint={setShowEditPoint}
          submitEditPoint={submitEditPoint}
          dataEditingPoint={dataEditingPUPoint}
          data={data}
          jobType={jobType}
          options={options}
          format24Hour={format24Hour}
          isBookDetails={isBookDetails}
        />}
    </div>
  )
}

PointContainer.defaultProps = {
  puPoints: [],
  doPoints: [],
  isChina: false,
  jobType: '',
  limitPoint: {},
  recentPlaces: {},
  fleetId: '',
  isBookDetails: false,
  data: {},
  partyLocation: false,
  isBookingFromPaxApp: false,
  auth: {},
  corporateCompany: {},
  newBooking: {},
  isSubmited: false,
  valid: false,
  promo: null,
  customer: null,
  traveler_type: null,
  cartypeSelected: null,
  paymentMethod: null,
  customerAutocompleteActions: null,
  customerAutocomplete: null,
  customerActions: null,
  setNewPuPoint: () => { },
  checkPointInZoneSupported: () => { },
  setNewDoPoint: () => { },
  checkPickupEditable: () => { },
  checkDestinationEditable: () => { },
  checkBookingEditable: () => { }
}

export default PointContainer