import React, { PureComponent } from 'react';
import {
  Button,
  FormControl,
  Modal,
  FormGroup,
  Form,
  Tooltip,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import _ from 'lodash';
import CCDropDown from '../../../dropdown/ccDropDown';
import '../../bookingDetail.scss';
import RadioButton from '../../../radioButton/radio';
import { ValidCase, Validator } from '../../../validator';
import { Validation } from '../../../../utils/commonFunctions';
import SuggestContent from '../../../suggestContent/SuggestContent';

const TemplateSMSBooking = ({
  items,
  selectedItems,
  onSelect,
  handleSearchSMSTemplate,
}) => (
  <CCDropDown
    id="template-dropdown"
    title={''}
    valueKey="id"
    labelKey="value"
    items={items}
    selectedItems={[(selectedItems && selectedItems.id) || '']}
    onSelect={onSelect}
    async
    noTranslateLabel
    onSearchChange={_.debounce(handleSearchSMSTemplate, 400)}
    searchable
    enableClearAll
  />
);

const AUTOFILL_ITEMS = [
  { value: '[FLEET_NAME]', description: 'Fleet name' },
  { value: '[FLEET_PHONE]', description: 'Fleet phone' },
  { value: '[FLEET_EMAIL]', description: 'Fleet email' },
  { value: '[BOOKING_ID]', description: 'Booking ID' },
  { value: '[PASSENGER_FIRST_NAME]', description: 'Passenger first name' },
  { value: '[PASSENGER_LAST_NAME]', description: 'Passenger last name' },
  { value: '[PASSENGER_PHONE]', description: 'Passenger phone' },
  { value: '[DRIVER_FIRST_NAME]', description: 'Driver first name' },
  { value: '[DRIVER_LAST_NAME]', description: 'Driver last name' },
  { value: '[DRIVER_PHONE]', description: 'Driver phone' },
  { value: '[PICKUP_TIME]', description: 'PickUp time' },
  { value: '[TRACK_LINK]', description: 'Track link' },
];

const TRIGGER_CHARACTER = '[';
const SUGGESTION_WIDTH = 310;

class SendSMSBookingModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSetStateContentChange = (value) => {
    this.props.handleContentChange(value);
  }

  render() {
    const {
      showSMSBooking,
      sendSMSBooking,
      smsBookingTemplateQuery,
      getSMSBookingTemplateList,
      handleSelectSMSBookingTemplate,
      handleChangeMessageType,
      handleChangeRecipientType,
      handleContentChange,
      handlePhoneChange,
      smsWillSend,
      handleSendMessageClick,
      checkDriver,
      checkCustomer,
      handleSearchSMSTemplate,
      handleSubjectChange,

      valid,
      validatorCallback,
    } = this.props;

    return (
      <>
        <Modal
          show={showSMSBooking}
          backdrop="static"
          dialogClassName="confirm-dialog update-book-detail-modal"
          onHide={() => {
            sendSMSBooking();
          }}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate
                value={`voiceSMSSetting.bookingSMSModal.titleMessage`}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSModal.messageType" />
              </Form.Label>
              <FormGroup className="radio-button-group">
                <RadioButton
                  text={I18n.t('voiceSMSSetting.bookingSMSModal.inbox')}
                  value={'Inbox'}
                  onChange={() => handleChangeMessageType('Inbox')}
                  checked={smsWillSend.typeMessage === 'Inbox' && true}
                  name="Message_Type"
                />
                <RadioButton
                  text={I18n.t('voiceSMSSetting.bookingSMSModal.sms')}
                  value={'SMS'}
                  name="Message_Type"
                  onChange={() => handleChangeMessageType('SMS')}
                  checked={smsWillSend.typeMessage === 'SMS' && true}
                />
              </FormGroup>
            </FormGroup>

            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSModal.sendTo" />
              </Form.Label>
              <FormGroup className="radio-button-group">
                {checkCustomer && (
                  <RadioButton
                    text={I18n.t('voiceSMSSetting.bookingSMSModal.passenger')}
                    value={'passenger'}
                    onChange={() => handleChangeRecipientType('passenger')}
                    checked={smsWillSend.typeRecipient === 'passenger' && true}
                    name="Send_To"
                  />
                )}
                {checkDriver && (
                  <RadioButton
                    text={I18n.t('voiceSMSSetting.bookingSMSModal.driver')}
                    value={'driver'}
                    name="Send_To"
                    onChange={() => handleChangeRecipientType('driver')}
                    checked={smsWillSend.typeRecipient === 'driver' && true}
                  />
                )}
              </FormGroup>
            </FormGroup>

            <FormGroup
              className={
                !this.props.isSend
                  ? null
                  : !Validation.isStringEmpty(smsWillSend.phone)
                  ? null
                  : 'error'
              }
            >
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSModal.phoneNumber" />
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                disabled={true}
                type="text"
                className={'form-custom'}
                onChange={handlePhoneChange}
                value={smsWillSend ? smsWillSend.phone : ''}
              />
              <Validator id="phone" callback={validatorCallback}>
                <ValidCase
                  hide={!this.props.isSend}
                  valid={
                    this.props.isSend &&
                    !Validation.isStringEmpty(smsWillSend.phone)
                  }
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSModal.template" />
              </Form.Label>
              <div className="template-sms-dropdown sms-booking-template-dropdown">
                <TemplateSMSBooking
                  onSelect={handleSelectSMSBookingTemplate}
                  items={smsBookingTemplateQuery.list}
                  selectedItems={smsBookingTemplateQuery.selectedObj}
                  handleSearchSMSTemplate={handleSearchSMSTemplate}
                />
              </div>
            </FormGroup>

            <FormGroup
              className={
                !this.props.isSend
                  ? null
                  : !Validation.isStringEmpty(smsWillSend.subject)
                  ? null
                  : 'error'
              }
            >
              <Form.Label>
                <Translate value="voiceSMSSetting.bookingSMSModal.Subject" />
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={handleSubjectChange}
                value={smsWillSend ? smsWillSend.subject : ''}
                placeholder={I18n.t('voiceSMSSetting.bookingSMSModal.Subject')}
              />
              <Validator id="subject" callback={validatorCallback}>
                <ValidCase
                  hide={!this.props.isSend}
                  valid={
                    this.props.isSend &&
                    !Validation.isStringEmpty(smsWillSend.subject)
                  }
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>

            <SuggestContent
                //state
                isSubmitted={this.props.isSend}
                editable={true}
                value={(this.props.smsWillSend && this.props.smsWillSend.content) || ''}
                //function
                validatorCallback={validatorCallback}
                handleSetStateContentChange={this.handleSetStateContentChange}
                //another
                label={'voiceSMSSetting.bookingSMSTemplate.content'}
                placeholder={'voiceSMSSetting.bookingSMSTemplate.content'}
                TRIGGER_CHARACTER={TRIGGER_CHARACTER}
                AUTOFILL_ITEMS={AUTOFILL_ITEMS}
                SUGGESTION_WIDTH={SUGGESTION_WIDTH}
                require={true}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.props.disabledSpam}
              onClick={() => {
                handleSendMessageClick();
              }}
              className="btn-save"
            >
              <Translate value="message.Send" />
            </Button>
            <Button
              onClick={() => {
                sendSMSBooking();
              }}
              className="btn-cancel"
            >
              <Translate value="message.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default SendSMSBookingModal;
