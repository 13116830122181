import React, { Component } from 'react';
import _ from 'lodash'
import {
  Button,
  Modal,
  FormControl,
  Form,
  FormGroup
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { Fragment } from 'react';
import {
  STATUS_COMPLETED_DELIVERY
} from '../../../constants/commondata'

const STATUS_NOT_MARK_FAILED = STATUS_COMPLETED_DELIVERY

class MarkFailedBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  setReasonMarkFail = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  }

  showConfirm = () => {
    this.setState({ visible: true, reason: ''});
  }

  handleSubmit = (e) => {
    this.props.onSubmit(this.state.reason)
    this.closeModal();
  }

  render() {
    const {
      bookInfo,
    } = this.props;

    const { visible } = this.state;
    const recipient = _.get(bookInfo, 'deliveryInfo.recipients[0]', null)
    if (!recipient || STATUS_NOT_MARK_FAILED.includes(recipient.status)) {
      return null;
    }
    return (
      <Fragment>
        <Button onClick={this.showConfirm} className="btn-cancel mr capitalize">
          <Translate value="bookingdetail.markFailed" />
        </Button>
        <Modal
          show={visible}
          backdrop={true}
          dialogClassName="confirm-dialog"
          onHide={this.closeModal}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {`Order #${recipient.orderId} - Fail to deliver`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Form.Label>
                {I18n.t('bookingdetail.InputReason')}
              </Form.Label>
              <FormControl
                className="form-custom"
                as="textarea"
                placeholder={I18n.t('bookingdetail.InputReason')}
                rows={3}
                maxLength={1000}
                value={this.state.reason}
                onChange={this.setReasonMarkFail}
              />
              <span className='text-soft-warning mr-t-5 block'>
                <i>{I18n.t('messages.booking.warning_markFail_lastRecipient')}</i>
              </span>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModal}>
              <Translate value="bookingdetail.Cancel" />
            </Button>
            <Button
              className="btn-save"
              onClick={this.handleSubmit}
              disabled={!this.state.reason}
            >
              <Translate value="bookingdetail.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default MarkFailedBtn;
