import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest';
import { formatPhoneNumber } from './../../utils/commonFunctions'

class TelInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    fieldName: PropTypes.string,
    fieldId: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    handleInputChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    refCallback: PropTypes.func.isRequired,
  };

  render() {
    if (this.props.suggestions) {
      let propsInt = {
        ...(this.props.inputProps),
        ref: this.props.refCallback,
        type: "tel",
        autoComplete: this.props.autoComplete,
        className: this.props.className,
        disabled: this.props.disabled ? 'disabled' : false,
        readOnly: this.props.readonly ? 'readonly' : false,
        name: this.props.fieldName,
        id: this.props.fieldId,
        value: this.props.value,
        placeholder: this.props.placeholder,
        onChange: this.props.handleInputChange,
        onBlur: this.props.handleOnBlur,
        onFocus: this.props.handleOnFocus,
        autoFocus: this.props.autoFocus,
        maxLength: this.props.maxLength
      };
      return (
        <Autosuggest
          suggestions={this.props.suggestions}
          onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
          getSuggestionValue={this.props.getSuggestionValue}
          renderSuggestion={this.props.renderSuggestion}
          onSuggestionSelected={this.props.onSuggestionSelected}
          inputProps={propsInt} />
      );
    } else {
      return (
        <input
          { ...this.props.inputProps }
          ref={this.props.refCallback}
          type="tel"
          autoComplete={this.props.autoComplete}
          className={this.props.className}
          disabled={this.props.disabled ? 'disabled' : false}
          readOnly={this.props.readonly ? 'readonly' : false}
          name={this.props.fieldName}
          id={this.props.fieldId}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.handleInputChange}
          onBlur={this.props.handleOnBlur}
          onFocus={this.props.handleOnFocus}
          autoFocus={this.props.autoFocus}
        />
      );
    }
  }
}

export default TelInput;
