import React, { useState, useEffect } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    InputGroup,
    Button,
    Modal,
} from 'react-bootstrap';
import _ from 'lodash';

import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { CriteriaListColumns as Columns } from './tableHeaderColumn';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { Validation } from '../../../../utils/commonFunctions';

import {
    EventType,
    CriteriaTypes
} from '../../../../constants/commondata';


function CriteriaModal(props) {
    const {
        data,
        currency,
        disable,
        showDialogModal,
        closeFormModal,
        handleUpdateModal,
        handleAlertNotification
    } = props;
    const [criterias, setCriterias] = useState([]);
    const [userType, setUserType] = useState('');
    const [eventType, setEventType] = useState('');

    useEffect(() => {
        if (data.userType) {
            setUserType(data.userType);
        };
        if (data.type) {
            setEventType(data.type);
        };
        if (data.criterias) {
            initCriteriaType(data.userType, data.criterias)
        } else {
            initCriteriaType(data.userType, [])
        };
    }, [data]);

    const initCriteriaType = (userType, criterias) => {
        let criteriaTypes = [];
        if (criterias.length > 0) {
            criterias.forEach(e => {
                e.selected = true;
                criteriaTypes.push(e);
            })
        };
        if (userType) {
            CriteriaTypes[userType].forEach(e => {
                if ((_.findIndex(criteriaTypes, o => o.criteriaType === e.criteriaType)) === -1) {
                    criteriaTypes.push({
                        criteriaType: e.criteriaType,
                        value: 0,
                        selected: false
                    })
                }
            })
        };
        setCriterias(criteriaTypes);
    }

    const handleItemSelectedChange = (id, e) => {
        let listItem = _.cloneDeep(criterias);
        if (id != null) {
            let index = listItem.findIndex(obj => id === obj.criteriaType);
            if (index >= 0) listItem[index].selected = e.target.checked;
        } else {
            let value = e.target.checked;
            listItem = listItem.map(obj => {
                return {
                    ...obj,
                    selected: value
                };
            });
        }
        setCriterias(listItem);
    };

    const handleInputChange = (key, data, e) => {
        let listItem = _.cloneDeep(criterias);
        let index = listItem.findIndex(obj => data.criteriaType === obj.criteriaType);
        if (key === "value") {
            if (index >= 0) {
                listItem[index].value = e.target.value;
            };
        };
        setCriterias(listItem);
    };

    const handleSelectedSave = () => {
        let listItem = _.cloneDeep(criterias);
        let criteriasUpdate = [];
        listItem.forEach(e => {
            if (e.selected) {
                criteriasUpdate.push({
                    criteriaType: e.criteriaType,
                    value: e.value,
                })
            };
        });
        let items = [];
        items = criteriasUpdate.filter(e => {
            let value = parseFloat(e.value);
            if (e.criteriaType === 'driver_rating') {
                if (value <= 0 || value > 5) {
                    criteriasUpdate.map(c => {
                        if (c.criteriaType === e.criteriaType) {
                            c.errorMsg = 'the_range_1_to_5'
                        }
                    });
                    return true
                };
            };
            if (e.criteriaType === 'acceptance_rating' || e.criteriaType === 'cancellation_rating') {
                if (value <= 0 || value > 100) {
                    criteriasUpdate.map(c => {
                        if (c.criteriaType === e.criteriaType) {
                            c.errorMsg = 'the_range_1_to_100'
                        }
                    });
                    return true
                };
            };
            return !e.value || !Validation.isNumber(e.value)
        });
        if (items.length > 0) {
            handleAlertNotification('criterias', items);
        } else {
            handleUpdateModal(criteriasUpdate);
        };
    };

    const getTableColumns = () => {
        let tableColumns = Object.assign([], Columns);
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'table-selectbox':
                    col.customHeader = columnKey => {
                        return (
                            <CcCheckbox
                                className="table-selectbox all-checkbox"
                                checked={
                                    criterias
                                        ? criterias.length == 0
                                            ? false
                                            : criterias.filter(data => {
                                                return !data.selected;
                                            }).length == 0
                                        : false
                                }
                                onChange={e => {
                                    handleItemSelectedChange(null, e);
                                }}
                            />
                        );
                    };
                    col.customCell = data => {
                        return (
                            <CcCheckbox
                                className="table-selectbox"
                                checked={data.selected}
                                onChange={e => {
                                    handleItemSelectedChange(data.criteriaType, e);
                                }}
                            />
                        );
                    };
                    break;
                case 'criteriaType':
                    col.customCell = data => {
                        return (<Translate value={`quest_event.${data.criteriaType}`} />);
                    };
                    break;

                case 'value':
                    col.customCell = data => {
                        return (
                            <FormGroup
                                className={"qup-input-group"}
                            >
                                <InputGroup
                                    className={
                                        "single-addon-right" +
                                        (!disable ? " disabled" : "")
                                    }
                                >
                                    <FormControl
                                        type="text"
                                        className="form-custom"
                                        onChange={e => handleInputChange('value', data, e)}
                                        value={data.value
                                            ? data.value
                                            : ''}
                                        placeholder={I18n.t('quest_event.value')}
                                    />
                                </InputGroup>
                            </FormGroup>
                        )
                    };
                    break;
                default:
                    break;
            }
        });
        return tableColumns;
    };

    return (
        <Modal
            onHide={closeFormModal}
            show={showDialogModal}
            dialogClassName="custom-modal quests-list table-header"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Translate value="quest_event.criterias_list" />
                </Modal.Title>
                <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={closeFormModal}
                >
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="gridViewTable">
                    <StickyTable
                        columns={getTableColumns()}
                        bodyData={criterias}
                        rowHeight={50}
                        getTableHeight={() => 500}
                    />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn-cancel" onClick={closeFormModal}>
                    <Translate value="quest_event.Cancel" />
                </Button>
                <Button className="btn-save mr-md" onClick={handleSelectedSave}>
                    <Translate value="quest_event.ok" />
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default CriteriaModal