export const Columns = [
  {
    key: "name",
    label: "regularSetting.Name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "serviceType",
    label: "regularSetting.serviceType",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "latestUpdate",
    label: "regularSetting.Last_Update",
    width: 200
  },
  {
    key: "regularStatus",
    label: "regularSetting.Status",
    width: 100
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];


export const RouterTableColumns = [
  {
    key: "routeName",
    label: "regularSetting.Route_name",
    cellClass: "cell-align-toolbar",
    headerClass: "header-align-toolbar",
    fixed: true,
    textEllipsis: true
  },
  {
    key: "routeType",
    label: "regularSetting.Route_type"
  },
  {
    key: "route1Fare",
    label: "regularSetting.Route_1_Fare"
  },
  {
    key: "route2Fare",
    label: "regularSetting.Route_2_Fare"
  },
  {
    key: "roundTrip",
    label: "regularSetting.Round_trip"
  },
  {
    key: "roundTripFare",
    label: "regularSetting.Round_Trip_Fare"
  },
  {
    key: "latestUpdate",
    label: "regularSetting.Last_Update",
    width: 200
  },
  {
    key: "actions",
    label: "regularSetting.Actions",
    width: 100,
    align: "right",
    cellClass: "table-actions"
  }
];
