import {
    queuingAreaSettingsGetAll,
    queuingAreaSettingsDetailsApi,
    queuingAreaSettingsDeleteApi,
    queuingAreaSettingsUpdateApi,
    queuingAreaSettingsCreateApi,
    queuingAreaSettingsFind,
    queuingAreaSettingsActive,
    queuingAreaOtherAreasApi,
    queuingAreaSettingsCheckAddress,
    queuingAreaSettingsGetMDispatcherListForQueuing,

    queuingAreaGetDriverListApi,
    queuingAreaDriverDeleteApi,
    queuingAreaDriverChangeOrderApi,
    queuingAreaDriverInsertApi
} from '../constants/ApiConfigs';

import {
    callApi,
    UrlBuilder
} from "../utils/apiUtils";

//Queuing Area
export function fetchDriverListInQueue(_id, fleetId) {
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(queuingAreaGetDriverListApi, { _id, fleetId }),
        config,
        null,
        null,
        null,
        true
    );
}

export function insertDriverToQueue(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaDriverInsertApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function changeDriverOrderInQueue(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaDriverChangeOrderApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function removeDriverFromQueue(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaDriverDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function fetchAllQueuingArea(fleetId) {
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(queuingAreaSettingsGetAll, { fleetId }),
        config,
        null,
        null,
        null,
        true
    );
}

export function findQueuingAreas(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsFind,
        config,
        null,
        null,
        null,
        true
    );
}

export function createQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsCreateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function updateQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsUpdateApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function deleteQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsDeleteApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function detailsQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsDetailsApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function changeStatusQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsActive,
        config,
        null,
        null,
        null,
        true
    );
}

export function checkAddressQueuingArea(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
        queuingAreaSettingsCheckAddress,
        config,
        null,
        null,
        null,
        true
    );
}

export function queuingAreaOtherAreas(options = {}) {
    options = Object.assign({}, options);
    const config = {
        method: "POST",
        body: JSON.stringify(options)
    };

    return callApi(
      queuingAreaOtherAreasApi,
        config,
        null,
        null,
        null,
        true
    );
}

export function getMDispatcherListForQueuing(fleetId, queuingAreaId) {
    const config = {
        method: "get",
    };

    return callApi(
        UrlBuilder(queuingAreaSettingsGetMDispatcherListForQueuing, { fleetId, queuingAreaId }),
        config,
        null,
        null,
        null,
        true
    );
}