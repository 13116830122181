import React, { useState } from 'react';
import styles from './styles/index.module.scss';
import { I18n, Translate } from 'react-redux-i18n';
import CcCheckbox from '../../../ccCheckbox/CcCheckbox';
import FileGim from '../../../../assets/images/icons/fileGim.svg';
import TrashGreen from '../../../../assets/images/icons/trashGreen.svg';
import TrashSilver from '../../../../assets/images/icons/trashSilver.svg';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import { getSuppilerPermission } from '../../../../utils/commonFunctions';

const ModalConFirmDeleted = ({ id, index, handleDeleteTrailNotes }) => {
  const [showConfirmDeleted, setShowConfirmDeleted] = useState(false);

  return (
    <>
      <img
        className={styles['trash']}
        src={TrashSilver}
        alt="trashSilver"
        onClick={() => setShowConfirmDeleted(true)}
      />
      <Modal
        show={showConfirmDeleted}
        backdrop={true}
        dialogClassName="confirm-dialog"
        onHide={() => setShowConfirmDeleted(false)}
        className={'confirm'}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="trailNotes.deleteTrailNote" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{
            __html: I18n.t('trailNotes.msgDeleteTrailNote'),
          }}
        ></Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-save"
            onClick={() => {
              setShowConfirmDeleted(false);
              handleDeleteTrailNotes(id, index);
            }}
          >
            <Translate value="bookingdetail.Yes" />
          </Button>
          <Button
            className="btn-cancel"
            onClick={() => setShowConfirmDeleted(false)}
          >
            <Translate value="bookingdetail.No" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FormDataTrailNotes = (props) => {
  const {
    formData,
    handleChangeValue,
    handleChangeFile,
    handleDrop,
    handleDragOver,
    handleDeleteFile,
    handleCreateTrailNotes,
    listTrailNotes,
    handleDeleteTrailNotes,
    auth,
  } = props;

  return (
    <>
      <div className={styles['form-submit']}>
        <div className={styles['group-checkbox-activity']}>
          <CcCheckbox
            className={styles['custom-checkbox']}
            checked={formData?.operator}
            onChange={(e) => handleChangeValue('operator', e.target.checked)}
            text={
              <span className={styles['text-content']}>
                <Translate value="trailNotes.operator" />
              </span>
            }
          />
          <CcCheckbox
            className={styles['custom-checkbox']}
            checked={formData?.driver}
            onChange={(e) => handleChangeValue('driver', e.target.checked)}
            text={
              <span className={styles['text-content']}>
                <Translate value="trailNotes.driver" />
              </span>
            }
          />
          <CcCheckbox
            className={styles['custom-checkbox']}
            checked={formData?.passenger}
            onChange={(e) => handleChangeValue('passenger', e.target.checked)}
            text={
              <span className={styles['text-content']}>
                <Translate value="trailNotes.passenger" />
              </span>
            }
          />
        </div>

        <div
          className={styles['group-input-activity']}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <textarea
            value={formData?.text}
            placeholder={I18n.t('trailNotes.pHInput')}
            className={styles['input-text']}
            type="text"
            maxlength="1000"
            rows="4"
            cols="50"
            wrap="hard-soft"
            onChange={(e) => handleChangeValue('text', e.target.value)}
          />
          {formData?.files?.length > 0 && (
            <div className={styles['group-files']}>
              {formData?.files?.map((file, index) => (
                <div className={styles['group-file']} key={index}>
                  <img src={FileGim} alt="FileGim" />
                  <span>{file.name}</span>
                  <img
                    onClick={() => handleDeleteFile(index)}
                    className={styles['cursor']}
                    src={TrashGreen}
                    alt="TrashGreen"
                  />
                </div>
              ))}
            </div>
          )}
          <div className={styles['group-footer']}>
            <div className={styles['file-upload']}>
              <span className={styles['text-green']}>
                {I18n.t('trailNotes.addFiles')}
              </span>
              <input
                type="file"
                multiple
                className={styles['input-file']}
                accept=".pdf, .doc, .docx, .csv, .xls, .xlsx, .jpeg, .jpg, .png, .webp, .txt, .note"
                max="10485760"
                onChange={(e) => handleChangeFile(e)}
              />
            </div>
            <span
              onClick={handleCreateTrailNotes}
              className={styles['text-green']}
              style={{ cursor: 'pointer' }}
            >
              {I18n.t('trailNotes.save')}
            </span>
          </div>
        </div>
      </div>

      {listTrailNotes.length > 0 && (
        <div className={styles['list-note']}>
          {listTrailNotes?.map((item, index) => (
            <div className={styles['group-note']} key={index}>
              <div className={styles['header-note']}>
                <div className={styles['title']}>
                  <span style={{ fontWeight: 'bold' }}>
                    {!item?.createdBy?.firstName && !item?.createdBy?.lastName
                      ? item?.createdBy?.userName
                      : (item?.createdBy?.firstName +
                        ' ' +
                        item?.createdBy?.lastName)}
                  </span>
                  <span>
                    &nbsp;&middot;&nbsp;
                    {moment().diff(item.createdDate, 'days') > 1
                      ? moment(item.createdDate).format('MMM D, YYYY, HH:mm')
                      : moment(item.createdDate).fromNow()}
                  </span>
                  {item.availability.operator && (
                    <span>&nbsp;&middot;&nbsp;Op</span>
                  )}
                  {item.availability.driver && (
                    <span>&nbsp;&middot;&nbsp;Dr</span>
                  )}
                  {item.availability.passenger && (
                    <span>&nbsp;&middot;&nbsp;C</span>
                  )}
                </div>
                {item.from !== 'HolidayTaxis' &&
                  !getSuppilerPermission(auth) && (
                    <ModalConFirmDeleted
                      id={item._id}
                      index={index}
                      handleDeleteTrailNotes={handleDeleteTrailNotes}
                    />
                  )}
              </div>
              <div
                style={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{ __html: item.text }}
              ></div>
              {item.files?.length > 0 && (
                <div className={styles['group-files']}>
                  {item.files?.map((file, index) => (
                    <div className={styles['group-file']} key={index}>
                      <img src={FileGim} alt="FileGim" />
                      <a href={file.url} target="_blank">
                        <span>{file.name}</span>
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FormDataTrailNotes;
