export const TripColumns = [
  {
    key: "tripId",
    label: "trip.tripId",
    fixed: true,
    isAlwayShow: true,
    width: 160,
    cellClass: "status-column cell-align-toolbar",
    headerClass: "status-colum-header header-align-toolbar",
    noPadding: true
  },
  {
    key: "status",
    label: "cue.Status",
    width: 140,
    isAlwayShow: true
  },
  {
    key: "tripTypes",
    label: "trip.tripTypes",
    width: 140,
    isAlwayShow: true
  },
  {
    key: "startTime",
    label: "trip.Start_Time",
    isAlwayShow: true
  },
  {
    key: "intercityRoute",
    label: "cue.Intercity_Route",
    textEllipsis: true,
    width: 220
  },
  {
    key: "seatsStatus",
    label: "trip.Seats_Status",
  },
  {
    key: "noOfBooking",
    label: "trip.No_Of_Booking",
  },
  {
    key: "vehicle",
    label: "trip.Vehicle",
    width: 150
  },
  {
    key: "driver",
    label: "cue.Driver"
  },
  {
    key: "estimatedFare",
    label: "trip.Estimated_Fare"
  },
  // {
  //   key: "operator.name",
  //   label: "cue.Operator"
  // }
];
