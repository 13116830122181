import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { changePassword } from "../../actions/auth";
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  Row,
  Col
} from "react-bootstrap";
class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmitted: false
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
    this.handleCurrentPasswordChange = this.handleCurrentPasswordChange.bind(
      this
    );
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(
      this
    );
  }

  handleCurrentPasswordChange(e) {
    this.setState({ currentPassword: e.target.value });
  }

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }

  handleConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  handleChangePasswordClick() {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return false;
    this.props.loadingBarActions.showLoadingSpiner();
    this.props
      .changePassword({
        userId: this.props.userId,
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPassword
      })
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        this.setState({ isSubmitted: false });
        if (data.ok) {
          this.props.router.push({
            pathname: "/"
          });
        } else {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
          this.setState({ currentPassword: "" });
        }
      });
  }

  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  render() {
    const { valid, isSubmitted } = this.state;
    return (
      <div className="content form-detail-container">
        <Row>
          <Col xs={12} sm={6} md={4}>
            <FormGroup
              className={
                isSubmitted
                  ? valid.currentPassword === false
                    ? "error"
                    : null
                  : null
              }
            >
              <Form.Label>
                <Translate value="changePassword.Current_password" />{" "}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                type="password"
                value={this.state.currentPassword}
                onChange={this.handleCurrentPasswordChange}
                className="form-custom"
                autoFocus
              />
              <Validator id="currentPassword" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmitted}
                  valid={!Validation.isStringEmpty(this.state.currentPassword)}
                  message={I18n.t("changePassword.ERROR_INPUT_PASSWORD")}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                isSubmitted
                  ? valid.newPassword === false
                    ? "error"
                    : null
                  : null
              }
            >
              <Form.Label>
                <Translate value="changePassword.New_password" />{" "}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                type="password"
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange}
                className="form-custom"
              />
              <Validator id="newPassword" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmitted}
                  valid={Validation.validatePassword(this.state.newPassword)}
                  message={I18n.t("changePassword.ERROR_INPUT_VALID_PASSWORD")}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                isSubmitted
                  ? valid.confirmPassword === false
                    ? "error"
                    : null
                  : null
              }
            >
              <Form.Label>
                <Translate value="changePassword.Confirm_password" />{" "}
                <span className="require"> *</span>
              </Form.Label>
              <FormControl
                type="password"
                value={this.state.confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                className="form-custom"
              />
              <Validator id="confirmPassword" callback={this.ValidatorCallback}>
                <ValidCase
                  hide={!isSubmitted}
                  valid={this.state.newPassword === this.state.confirmPassword}
                  message={I18n.t("changePassword.ERROR_INPUT_MATCH_PASSWORD")}
                />
              </Validator>
            </FormGroup>
            <ButtonToolbar className="text-center">
              <Button
                variant="success"
                onClick={this.handleChangePasswordClick}
                className="btn-save"
              >
                <Translate value="changePassword.Save" />
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </div>
    );
  }
}
ChangePassword.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    userId: state.auth.user._id
  };
}
function mapDispatchToProps(dispatch) {
  return {
    changePassword: options => {
      return dispatch(changePassword(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
