import React, { Fragment } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import SelectMultiple from '../../../../components/SelectMultiple';
import {
  ValidCase,
  Validator,
  WarningFeeInput,
} from '../../../../components/validator';
import _ from 'lodash';
import styles from '../../styles/formPolicy.module.scss';
import {
  Validation,
  filterCommissionServiceActive,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { UNIT_TIME } from '../../../../constants/commondata';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';

const FormPolicy = (valProps) => {
  const {
    handleOperationZoneChange,
    props,
    detailItem,
    state,
    validatorCallback,
    closeDialogForm,
    saveDialogForm,
    onEditable,
    handleCommissionChange,
    handlePaymentMethodsApplyChange,
    handleChangeAdvanceBookingVisibility,
    handleBroadcastBookingChange,
    handleChangeDelayBroadcastValue,
    handleChangeReleaseTime,
    handleChangeDelayBroadcastType,

    handleChangeCheckBox,
    agents,
    handleCommissionCompanyChange,
    showCommissionClick,
    saveCommissionEdit,
    handleCommissionTypeChangeAndValue,
  } = valProps;

  const { action, auth } = props;
  const { valid, editable } = state;

  const companyCommission = _.get(
    props.auth,
    'selectedFleet.generalSetting.companyCommission',
    false
  );

  let fleetZones = _.filter(
    props?.commonData?.mapZone,
    (z) => z.isActive && z.display
  );

  if (detailItem.agentId) {
    const zoneAgents =
      agents?.find((ag) => ag._id === detailItem.agentId)?.operationZone || [];

    fleetZones = fleetZones.filter((zone) => {
      if (zoneAgents.length === 0) {
        return true;
      }
      return zoneAgents?.includes(zone._id);
    });
  }

  const zoneOptions = fleetZones.map((zone) => {
    return {
      value: zone._id,
      label: zone.zoneName,
    };
  });

  const defaultZone = zoneOptions.filter((zone) => {
    return detailItem?.operationZone?.includes(zone.value);
  });

  const paymentOptions = state?.paymentMethodState?.map((payment) => {
    return {
      value: payment.key,
      label: payment.name,
    };
  });

  const defaultPayment = paymentOptions?.filter((payment) => {
    return detailItem?.paymentMethodsApply?.includes(payment.value);
  });

  const fleetEnableBroadcastBooking =
    props.auth?.selectedFleet?.process?.broadcastBooking || true;

  const delayBroadcast = detailItem?.delayBroadcast || {};
  const releaseTime = detailItem?.releaseTime || '';

  const renderNewCommission = () => {
    let commissionDriverValue = filterCommissionServiceActive(
      detailItem.commissionCompanyValue,
      auth.selectedFleet
    );
    let commissionCompanyType = detailItem?.commissionCompanyType;
    return (
      <Fragment>
        <FormGroup style={{ marginBottom: '7px' }}>
          <Form.Label className="mb">
            <Translate value="" />
          </Form.Label>
          <Form.Label>
            <h3 style={{ fontSize: '20px' }} className="mb-sm">
              <Translate value="supplier.commissionValue" />
            </h3>
            <div className={styles['description']}>
              <Translate value="supplier.commissionValueDes" />{' '}
            </div>
          </Form.Label>
          <FormControl
            as="select"
            value={commissionCompanyType}
            onChange={handleCommissionCompanyChange}
            disabled={!state.editable}
            className="form-custom"
          >
            <option value="default">Default</option>
            <option value="customize">Customize</option>
            <option value="payToDriver">Fleet pay to Driver</option>
          </FormControl>
        </FormGroup>
        {state.editable && commissionCompanyType === 'customize' ? (
          <a
            onClick={showCommissionClick}
            href="javascript:void(0)"
            className="text-active editCommission"
            style={{ marginTop: '0px', marginBottom: '10px' }}
          >
            Edit/Add
          </a>
        ) : (
          commissionCompanyType === 'default' ? <>
            <FormGroup
              className={
                !state.isSubmitted
                  ? null
                  : state.valid.commissionValue
                  ? null
                  : 'error'
              }
            >
              <FormGroup className="qup-input-group">
                <InputGroup
                  disabled={!state.editable}
                  className={styles['input-custom']}
                >
                  <InputGroup.Prepend className="qup-first">
                    <InputGroup.Text>{'%'}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={handleCommissionChange}
                    value={detailItem ? detailItem.commissionValue : ''}
                    disabled={!state.editable}
                    placeholder={I18n.t('supplier.commissionValue')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>{'/Transaction'}</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="commissionValue" callback={validatorCallback}>
                {/* <ValidCase
              hide={!state.isSubmitted}
              valid={!Validation.isStringEmpty(detailItem.commissionValue)}
              message={I18n.t('messages.commonMessages.Required_field')}
            /> */}
                <ValidCase
                  hide={!state.isSubmitted}
                  valid={Validation.isNumber(detailItem.commissionValue)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                />
                <ValidCase
                  hide={!state.isSubmitted}
                  valid={Validation.isGreaterOrEqual(
                    detailItem.commissionValue,
                    0
                  )}
                  message={I18n.t(
                    'messages.commonMessages.greater_or_equa'
                  ).format(0)}
                />
              </Validator>
            </FormGroup>
          </> : <div style={{marginBottom: '20px'}}></div>
        )}
        <Modal
          show={state.showCommission}
          onHide={showCommissionClick}
          backdrop="static"
          aria-labelledby="contained-modal-title-sm"
          dialogClassName="add-edit-driver-dialog"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title_commission">
              Commission by service
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="clearfix commissionBody">
            {commissionDriverValue &&
              commissionDriverValue.map((commission) => {
                let symbol =
                  commission.type === 'percent'
                    ? '%'
                    : auth.selectedFleet.currencies[0].symbol;
                return (
                  <Fragment>
                    <div className="group-general">
                      <div key={commission.serviceType}>
                        <Row>
                          <Col xs={12}>
                            <FormGroupTitle>
                              <Translate
                                value={`driver.${commission.serviceType}`}
                              />
                            </FormGroupTitle>
                          </Col>
                          <Col md={12}>
                            <FormGroup className="qup-input-group">
                              <InputGroup
                                disabled={!state.editable}
                                className={styles['input-custom']}
                              >
                                <InputGroup.Prepend className="qup-first">
                                  <InputGroup.Text>{'%'}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    handleCommissionTypeChangeAndValue(
                                      e,
                                      commission.serviceType,
                                      'value'
                                    );
                                  }}
                                  value={commission.value || 0}
                                  disabled={!state.editable}
                                  placeholder={I18n.t(
                                    'supplier.commissionValue'
                                  )}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    {'/Transaction'}
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-save" onClick={saveCommissionEdit}>
              <Translate value="newbooking.Save" />
            </Button>
            <Button className="btn-cancel" onClick={showCommissionClick}>
              <Translate value="newbooking.Cancel" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  };

  return (
    <>
      <FormGroup
        controlId="formControlsSelectMultipleZone"
        className={
          state.isSubmitted ? (state?.valid?.zone ? null : 'error') : null
        }
      >
        <Form.Label>
          <h3 style={{ fontSize: '20px' }} className="mb-sm">
            <Translate value="driver.operationZones" />
            <span className="require"> *</span>
          </h3>
          <div className={styles['description']}>
            <Translate value="driver.operationZonesDes" />{' '}
          </div>
        </Form.Label>
        <SelectMultiple
          onChange={handleOperationZoneChange}
          options={zoneOptions}
          value={defaultZone}
          isMulti={true}
          disabled={!state.editable}
        />
        <Validator id="zone" callback={validatorCallback}>
          <ValidCase
            hide={!state.isSubmitted}
            valid={
              detailItem.operationZone && detailItem.operationZone.length > 0
            }
            message={I18n.t('driver.ERROR_INPUT_ZONE')}
          />
        </Validator>
      </FormGroup>

      {renderNewCommission()}

      <FormGroup
        controlId="formControlsSelectMultiplePayment"
        className={
          state.isSubmitted
            ? state?.valid?.paymentMethodsApply
              ? null
              : 'error'
            : null
        }
      >
        <Form.Label>
          <h3 style={{ fontSize: '20px' }} className="mb-sm">
            <Translate value="companySetting.paymentMethodsApply" />
            <span className="require"> *</span>
          </h3>
          <div className={styles['description']}>
            <Translate value="supplier.paymentMethodsApplyDes" />{' '}
          </div>
        </Form.Label>
        <SelectMultiple
          onChange={handlePaymentMethodsApplyChange}
          options={paymentOptions}
          value={defaultPayment}
          isMulti={true}
          disabled={!state.editable}
        />
        <Validator id="paymentMethodsApply" callback={validatorCallback}>
          <ValidCase
            hide={!state.isSubmitted}
            valid={
              detailItem.paymentMethodsApply &&
              detailItem.paymentMethodsApply.length > 0
            }
            message={I18n.t('driver.ERROR_INPUT_ZONE')}
          />
        </Validator>
      </FormGroup>

      <Form.Label>
        <h3 style={{ fontSize: '20px' }} className="mb-sm">
          <Translate value="supplier.bookingSettings" />
        </h3>
        <div style={{ marginBottom: '10px' }} className={styles['description']}>
          <Translate value="supplier.bookingSettingsDes" />{' '}
        </div>
      </Form.Label>
      {props.auth?.selectedFleet?.marketplace?.enable && (
        <FormGroup
          className={
            !state?.isSubmitted
              ? null
              : state?.valid?.advanceBookingVisibility === false
              ? 'error'
              : null
          }
        >
          <Form.Label>
            <Translate value="companySetting.advanceBookingVisibility" />
            <QuestionCircleTooltip
              text={
                <Translate
                  value="supplier.advanceBookingVisibilityTooltip"
                  dangerousHTML
                />
              }
            />
          </Form.Label>
          <FormGroup className="qup-input-group">
            <InputGroup
              className={`single-addon-right ${
                !state.editable ? 'disabled' : ''
              } ${styles['input-custom']}`}
            >
              <FormControl
                type="number"
                className="form-custom"
                onChange={handleChangeAdvanceBookingVisibility}
                onBlur={(e) => {
                  textboxNumberHelper.onBlurHandle(
                    e,
                    handleChangeAdvanceBookingVisibility
                  );
                }}
                onFocus={(e) => {
                  textboxNumberHelper.onfocusHandle(
                    e,
                    handleChangeAdvanceBookingVisibility
                  );
                }}
                placeholder={I18n.t('companySetting.delayBroadcast')}
                value={detailItem.advanceBookingVisibility}
                disabled={!state.editable}
              />
              <InputGroup.Append>
                <InputGroup.Text>{I18n.t('General.day')}</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </FormGroup>
          <Validator id="advanceBookingVisibility" callback={validatorCallback}>
            <ValidCase
              hide={!state?.isSubmitted}
              valid={Validation.isNumber(detailItem.advanceBookingVisibility)}
              message={I18n.t('messages.commonMessages.must_be_number')}
            />
            <ValidCase
              hide={!state?.isSubmitted}
              valid={Validation.isGreaterOrEqual(
                detailItem.advanceBookingVisibility,
                1
              )}
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                1
              )}
            />
            <ValidCase
              hide={!state?.isSubmitted}
              valid={Validation.isLessOrEqual(
                detailItem.advanceBookingVisibility,
                9999
              )}
              message={I18n.t('messages.commonMessages.less_or_equal').format(
                '9999'
              )}
            />
          </Validator>
        </FormGroup>
      )}

      {fleetEnableBroadcastBooking && (
        <>
          <CcCheckbox
            checked={detailItem?.broadcastBooking}
            onChange={handleBroadcastBookingChange}
            text={I18n.t('companySetting.Broadcast_orders')}
            disabled={!state.editable}
          />
          {detailItem?.broadcastBooking && (
            <>
              <FormGroup
                className={
                  !state?.isSubmitted
                    ? null
                    : state?.valid?.delayBroadcast === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="companySetting.delayBroadcast" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="supplier.delayBroadcastToolTip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup className="qup-input-group">
                  <InputGroup
                    className={`single-addon-right ${
                      !state.editable ? 'disabled' : ''
                    } ${styles['input-custom']}`}
                  >
                    <FormControl
                      type="number"
                      className="form-custom"
                      onChange={handleChangeDelayBroadcastValue}
                      onBlur={(e) => {
                        textboxNumberHelper.onBlurHandle(
                          e,
                          handleChangeDelayBroadcastValue
                        );
                      }}
                      onFocus={(e) => {
                        textboxNumberHelper.onfocusHandle(
                          e,
                          handleChangeDelayBroadcastValue
                        );
                      }}
                      placeholder={I18n.t('companySetting.delayBroadcast')}
                      value={delayBroadcast.value}
                      disabled={!state.editable}
                    />
                    <FormControl
                      as="select"
                      className="input-group-addon-custom form-custom"
                      value={delayBroadcast.unit || 'minute'}
                      onChange={handleChangeDelayBroadcastType}
                      disabled={!state.editable}
                    >
                      <option value={UNIT_TIME.Minute}>
                        {I18n.t('General.mins')}
                      </option>
                      <option value={UNIT_TIME.Hour}>
                        {I18n.t('General.hrs')}
                      </option>
                      <option value={UNIT_TIME.Day}>
                        {I18n.t('General.days')}
                      </option>
                    </FormControl>
                  </InputGroup>
                </FormGroup>
                <Validator id="delayBroadcast" callback={validatorCallback}>
                  <ValidCase
                    hide={!state?.isSubmitted}
                    valid={Validation.isNumber(delayBroadcast.value)}
                    message={I18n.t('messages.commonMessages.must_be_number')}
                  />
                  <ValidCase
                    hide={!state?.isSubmitted}
                    valid={Validation.isGreaterOrEqual(delayBroadcast.value, 0)}
                    message={I18n.t(
                      'messages.commonMessages.equal_or_greater_than_0'
                    )}
                  />
                  <ValidCase
                    hide={!state?.isSubmitted}
                    valid={Validation.isLessOrEqual(delayBroadcast.value, 9999)}
                    message={I18n.t(
                      'messages.commonMessages.less_or_equal'
                    ).format('9999')}
                  />
                </Validator>
              </FormGroup>
              <FormGroup
                className={
                  !state?.isSubmitted
                    ? null
                    : state?.valid?.releaseTime === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="companySetting.releaseTime" />
                  <QuestionCircleTooltip
                    text={
                      <Translate
                        value="supplier.releaseTimeToolTip"
                        dangerousHTML
                      />
                    }
                  />
                </Form.Label>
                <FormGroup
                  className={`${styles['input-custom']} qup-input-group`}
                >
                  <FormControl
                    className={`form-custom`}
                    type="text"
                    maxLength={5}
                    onChange={handleChangeReleaseTime}
                    placeholder={'HH:MM'}
                    value={releaseTime}
                    disabled={!state.editable}
                  />
                </FormGroup>
                <Validator id="releaseTime" callback={validatorCallback}>
                  <ValidCase
                    hide={!state?.isSubmitted}
                    valid={Validation.timeFormat24h(releaseTime)}
                    message={'Invalid format HH:MM'}
                  />
                </Validator>
              </FormGroup>
            </>
          )}
        </>
      )}

      <Form.Label>
        <h3 style={{ fontSize: '20px' }} className="mb-sm">
          <Translate value="supplier.otherSettings" />
        </h3>
      </Form.Label>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <CcCheckbox
              checked={detailItem.isHideFare}
              onChange={(e) => handleChangeCheckBox('isHideFare', e)}
              disabled={!state.editable}
              text={<Translate value="supplier.hideFare" />}
              className="mb-sm"
            ></CcCheckbox>
            <div
              className={styles['description']}
              style={{ marginLeft: '27px' }}
            >
              <Translate value="supplier.hideFareDes" />{' '}
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <CcCheckbox
              checked={detailItem.supplierJobRequired}
              onChange={(e) => handleChangeCheckBox('supplierJobRequired', e)}
              disabled={!state.editable}
              text={<Translate value="supplier.supplierJobRequired" />}
              className="mb-sm"
            ></CcCheckbox>
            <div
              className={styles['description']}
              style={{ marginLeft: '27px' }}
            >
              <Translate value="supplier.supplierJobRequiredDes" />{' '}
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FormGroup>
            <CcCheckbox
              checked={detailItem.supplierApproval}
              onChange={(e) => handleChangeCheckBox('supplierApproval', e)}
              disabled={!state.editable}
              text={<Translate value="supplier.supplierApproval" />}
              className="mb-sm"
            ></CcCheckbox>
            <div
              className={styles['description']}
              style={{ marginLeft: '27px' }}
            >
              <Translate value="supplier.supplierApprovalDes" />{' '}
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}

          {/* {(!props.permissions || props.permissions.actions) &&
          action !== 'add' &&
          props.params.driverId &&
          auth.selectedFleet.password.driver ? (
            <Button
              style={{ padding: '6px 15px', minWidth: 'unset' }}
              className="btn-reset ml-md mt-md"
              onClick={handleResetPasswordClick}
            >
              <Translate value="user.Reset_password" />
            </Button>
          ) : null} */}
        </Col>
      </Row>
    </>
  );
};

export default FormPolicy;
