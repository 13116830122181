import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Translate } from 'react-redux-i18n';
import { getDriver, countDriver } from '../../../actions/driverAction';

function TotalDriver({ selectedFleet, fetch, count, driverNumberChanges, total }) {
    // const [total, setTotal] = useState(null);

    // useEffect(() => {
    //     if (_.get(selectedFleet, 'searchModule.Driver', false)) {
    //         fetch({ query: { fleetId: selectedFleet.fleetId }, limit: 5 }).then(
    //             data => {
    //                 if (!data.error) {
    //                     setTotal(_.get(data, 'res.summary.total', 0));
    //                 }
    //             }
    //         );
    //     } else {
    //         count({ query: { fleetId: selectedFleet.fleetId } }).then(data => {
    //             if (!data.error) {
    //                 setTotal(data.res.total);
    //             }
    //         });
    //     }
    // }, []);


    return (
        <div className="totalDriver">
            <span>
                <Translate value="driver.totalDrivers" />
          :&nbsp;
        </span>
            {total === null
                ? '-----'
                : new Intl.NumberFormat('en-us').format(total + driverNumberChanges)}
        </div>
    )
}


function mapStateToProps(state) {
    return {
        selectedFleet: state.auth.selectedFleet,
        language: state.i18n,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetch: options => dispatch(getDriver(options)),
        count: options => dispatch(countDriver(options))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalDriver)