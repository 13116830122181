import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import HeaderGr from '../drivers/components/headerGr'
import FormSupplier from './components/formSupplier';
import CreateSupplierUser from './components/forms/formUser/CreateSupplierUser';

const SupplierDetail = (props) => {
  const breadcrumbs = useMemo(
    () => [
      {
        path: '/supplier',
        name: I18n.t('supplier.Supplier'),
      },
      {
        path: `/supplier/detail/${props.params.supplierId}/info`,
        name: I18n.t('supplier.detailSupplier'),
      },
    ],
    [props.params.supplierId]
  );

  if(['create-user', 'edit-user'].includes(props?.params?.tabActive)) {
    return (
      <CreateSupplierUser 
        supplierId={props.params?.supplierId}
        router={props.router}
        location={props.location}
      />
    )
  }

  const getHeightParent = () => {
    return prRef?.current?.offsetHeight || 0
  }

  return (
    <div className="supplierDetails" style={{ overflow: 'auto', height: '100%' }}>
      {!props?.auth?.user?.roles?.isSupplier && <HeaderGr
        breadcrumbs={breadcrumbs}
        navigate={props.router}
        title={I18n.t('supplier.detailSupplier')}
      />}
      <FormSupplier
        action={props?.location?.state?.edit ? 'edit' : 'view'}
        navigate={props.router}
        location={props.location}
        params={props.params}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(SupplierDetail);
