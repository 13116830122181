import {getAllflightNamesApi,
        getFlightSchedulesApi,
        getInfoFlightApi} from '../constants/ApiConfigs'
import {
  callApi, UrlBuilder
} from "../utils/apiUtils";

export function getAllFlightNames(options){
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        getAllflightNamesApi,
        config,
        null,
        null,
        null,
        false
    );
}
export function getFlightSchedules(options){
    const config = {
        method: "post",
        body: JSON.stringify(options)
    };
    return callApi(
        getFlightSchedulesApi,
        config,
        null,
        null,
        null,
        false
    );
}

export function getInfoFlight(options){
    const config = {
        method: 'get'
      };
    
    return callApi(UrlBuilder(getInfoFlightApi, options), config, null, null, null, true);
}