import React, { Component } from 'react';
import currencyFormatter from 'currency-formatter';
import { I18n, Translate } from 'react-redux-i18n';
import _, { trimEnd } from 'lodash';
import {
  CCLiteCommonFunc,
  roundOff,
  checkEnableEditDriverEarning,
  checkShowInfoPrePaid,
  calculatorAmountDue,
  getNameAffiliateCarType,
  getSuppilerPermission,
  getURLFareDeeplink,
  getURLToCheck3rdBooking,
  checkCorporateUser,
} from '../../../utils/commonFunctions';
import EditFare from './EditFareComponent';
import {
  HYDRA_STATUS,
  NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD,
  PAYOUT_CUSTOM_TYPE,
  AFFILIATE_BOOKING_CAR_TYPE,
  PAID_STATUS,
  thirdPartyIntegration,
  STATUS_EXTENAL_INFO,
  locationType,
} from '../../../constants/commondata';
import FareDeeplink from './FareDeeplink';
import { calculatorPayoutWhenCustom, checkShowFare, getFareWhenDroppedOff } from '../bookFunction/bookingInfo';

class TripEstimate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowEditFare: false,
      allowMarkupPrice: false,
      isCorporateBoard: checkCorporateUser(this.props?.auth?.user)
    };
  }

  componentDidMount() {
    if (this.props.bookingPermission) {
      const enableEditDriverEarning = checkEnableEditDriverEarning(
        this.props.auth,
        this.props.bookingPermission
      );
      const editFare =
        this.props.bookingPermission.find((item) => item.name === 'EditFare') ||
        {};
      const { status, request = {} } = this.props.data;
      //if (!drvInfo || !drvInfo.userId) {
      if (status != 'engaged' && status != 'droppedOff') {
        if (
          !request.promoCustomerType ||
          request.promoCustomerType !== 'referral'
        ) {
          this.setState({
            enableEditDriverEarning: enableEditDriverEarning,
            allowEditFare:
              this.props.selectedFleet.generalSetting.editBookingFare &&
              editFare.isActive &&
              !this.props.isHydraBooking,
            allowMarkupPrice:
              (this.props.selectedFleet.generalSetting.markupPrice &&
                editFare.isActive &&
                !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(
                  parseInt(this.props.data.paymentMethod)
                ) &&
                !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(
                  parseInt(request.paymentType)
                )) ||
              this.props.isHydraBooking,
          });
        }
      }
      this.getServiceFeeByZone();
      //};
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.promo !== prevProps.promo) {
      if (this.props.bookingPermission) {
        const editFare =
          this.props.bookingPermission.find(
            (item) => item.name === 'EditFare'
          ) || {};
        const { status, request = {} } = this.props.data;
        //if (!drvInfo || !drvInfo.userId) {
        if (status != 'engaged' && status != 'droppedOff') {
          if (
            this.props.promo &&
            this.props.promo.promoCustomerType &&
            this.props.promo.promoCustomerType === 'referral' &&
            !request.promoCustomerType
          ) {
            this.setState({
              allowEditFare:
                this.props.selectedFleet.generalSetting.editBookingFare &&
                editFare.isActive,
              allowMarkupPrice:
                this.props.selectedFleet.generalSetting.markupPrice &&
                editFare.isActive &&
                !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(
                  parseInt(this.props.data.paymentMethod)
                ),
            });
          }
        }
        //};
      }
    } else if (
      this.props.data.paymentMethod !== prevProps.data.paymentMethod ||
      (this.props.data.request &&
        this.props.data.request.paymentType !==
          prevProps.data.request.paymentType)
    ) {
      if (this.props.bookingPermission) {
        const editFare =
          this.props.bookingPermission.find(
            (item) => item.name === 'EditFare'
          ) || {};
        const { status } = this.props.data;
        //if (!drvInfo || !drvInfo.userId) {
        if (status != 'engaged' && status != 'droppedOff') {
          this.setState({
            allowMarkupPrice:
              this.props.selectedFleet.generalSetting.markupPrice &&
              editFare.isActive &&
              !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(
                parseInt(this.props.data.paymentMethod)
              ) &&
              (!this.props.data.request ||
                !NOT_ALLOW_MARKUP_PRICE_PAYMENT_METHOD.includes(
                  parseInt(this.props.data.request.paymentType)
                )),
          });
        }
        //};
      }
    }
  }

  getServiceFeeByZone = () => {
    const { fleetId } = this.props.selectedFleet;
    if (!this.props.data?.request?.pickup?.zoneId) {
      return;
    }
    this.props.settingActions
      .getServiceFeesByZone({
        fleetId: fleetId,
        zoneId: this.props.data?.request?.pickup?.zoneId,
      })
      .then((data) => {
        this.setState({ serviceFeeZone: data.res });
      });
  };

  renderHourlyPackagesNote = () => {
    // const {} = this.state
    const {
      cartypeSelected,
      newBooking,
      destinationFrom,
      addressDestination,
      data,
      etaFare,
      selectedFleet,
      etaProps,
    } = this.props;
    const destination =
      destinationFrom == locationType.thirdParty ? '' : addressDestination;
    if (data.duration && !_.isEmpty(destination)) {
      // should be a hourly booking that have destination
      // car type must be estimated by action fare type and booking is not cross zone type
      if (
        cartypeSelected &&
        cartypeSelected.actualFare &&
        !newBooking.locationService.isCrossZone
      ) {
        const message = I18n.t(
          'messages.booking.You_may_be_charged_extra_fee_if_your_trip_excceds'
        );
        const limitUnits = [];
        const selectedPackageId = data.packageRateId;
        const eta = etaFare || etaProps;
        if (eta) {
          const selectedPackage = eta.packages
            ? eta.packages.find((obj) => obj._id == selectedPackageId)
            : null;
          if (selectedPackage) {
            let currencyValue = selectedPackage.feesByCurrencies.find(
              (obj) => obj.currencyISO == eta.currencyISO
            );
            currencyValue =
              currencyValue || selectedPackage.feesByCurrencies[0];
            if (currencyValue) {
              if (currencyValue.extraDistance || currencyValue.extraDuration) {
                if (selectedPackage.duration) {
                  limitUnits.push(
                    `${selectedPackage.duration} ${selectedPackage.type}(s)`
                  );
                }
                if (selectedPackage.coveredDistance) {
                  const distanceUnit = selectedFleet.unitDistance;
                  limitUnits.push(
                    `${selectedPackage.coveredDistance} ${distanceUnit}`
                  );
                }
                return <span> {`* ${message} ${limitUnits.join(`, `)}`} </span>;
              }
            }
          }
        }

        // if(cartypeSelected)
      }
    }
    return <span />;
  };

  renderEstimatedFare = () => {
    const { data, selectedFleet, farmOut, isHydraBooking, farmIn } = this.props;

    if (CCLiteCommonFunc.isBookingStatusCompleted(data.status)) {
      let total = data.completedInfo?.total || 0;
      if (farmIn) {
        total = data.completedInfo?.totalBuyFare || 0;
      }
      return currencyFormatter.format(total, {
        code: data.currencyISO,
      });
    }

    const markupDifference = data.request.estimate.fare?.markupDifference || 0;
    // SL-9047 [New CC (N)] Show fare on CC when Driver already dropped off
    if (data.status === 'droppedOff' && _.get(data, 'droppedOffInfo.total')) {
      return currencyFormatter.format(getFareWhenDroppedOff({ bookInfo: data, selectedFleet: selectedFleet}), { code: data.currencyISO })
    }

    // if Hydra booking
    if (isHydraBooking) {
      let etaNumber = _.get(data, 'request.estimate.fare.etaFare', 0);
      if (farmOut) {
        etaNumber = _.get(data, 'request.estimate.fare.qupSellPrice', 0);
      }
      return etaNumber
        ? currencyFormatter.format(etaNumber, {
            code: data.currencyISO,
          })
        : '';
    }

    // if home booking or local booking, show estimated fare
    if (
      data.pricingType === 0 ||
      data.request.psgFleetId === selectedFleet.fleetId ||
      farmIn
    ) {
      const etaFareRoundOff = roundOff(
        _.get(data, 'request.estimate.fare.etaFare', 0) + markupDifference,
        data.currencyISO,
        selectedFleet.rounding
      );
      return data.request.estimate.fare &&
        !_.isEmpty(data.request.estimate.fare)
        ? currencyFormatter.format(etaFareRoundOff, { code: data.currencyISO })
        : currencyFormatter.format(0, { code: data.currencyISO });
    }
  };

  renderEstimateRoute = () => {
    const { data, selectedFleet } = this.props;

    if (data.pricingType === 0) {
      if (
        data.request.estimate.fare &&
        !_.isEmpty(data.request.estimate.fare) &&
        data.request.estimate.fare.normalFare === false
      ) {
        return data.request.estimate.fare
          ? data.request.estimate.fare.route
          : '';
      }

      // affiliate booking
      // if home booking, show estimated fare
    } else if (data.pricingType === 1) {
      if (
        data.request.estimate.fare &&
        !_.isEmpty(data.request.estimate.fare) &&
        !data.request.estimate.fare.normalFare
      ) {
        return data.request.estimate.fare
          ? data.request.estimate.fare.route
          : '';
      }
    }

    return '';
  };

  renderDriverEarningUIBookingDetail = () => {
    const { data, auth, bookingPermission = [] } = this.props;
    const fareBookingDetail = _.get(data, 'request.estimate.fare', {});
    return (
      <>
        {this.state.enableEditDriverEarning && (
          <div className="item">
            <Translate value="supplier.totalPayout" />
            <span
              className={
                fareBookingDetail.addOnPrice &&
                fareBookingDetail.addOnPrice !== 0
                  ? fareBookingDetail.addOnPrice > 0
                    ? 'adjust-detail'
                    : 'adjust-slow-detail'
                  : null
              }
            >
              <div className="driverEarningCT">
                <div>
                  <p style={{ marginBottom: '0px' }}>
                    {fareBookingDetail.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default &&
                      currencyFormatter.format(
                        calculatorPayoutWhenCustom(fareBookingDetail, this.state.serviceFeeZone, this.props.fareSettings?.fare, this.props.selectedFleet),
                        { code: fareBookingDetail.currencyISO }
                      )}
                  </p>
                  <p
                    className={
                      fareBookingDetail.supplierEarningType !==
                      PAYOUT_CUSTOM_TYPE.default
                        ? 'subFare'
                        : ''
                    }
                  >
                   {this.props.supplierCompanies?.length != 1 && !this.props.driver?.phone && fareBookingDetail.supplierEarningType === 'default'
                      ? PAYOUT_TBD
                      :
                        (!this.props?.driver?.phone 
                          && (
                            this.props.commonData?.companies?.find(item => item._id === this.props.supplierCompanies?.[0])
                            || this.props.commonData?.suppliers?.find(item => item._id === this.props.supplierCompanies?.[0])?.commissionCompanyType === 'payToDriver'
                            )
                          )
                        ? PAYOUT_TBD :
                        currencyFormatter.format(
                          calculatorPayoutWhenCustom(
                            {...fareBookingDetail, supplierEarningType: 'default'}, 
                            Object.keys(this.props.serviceFeeZone || {}).length ? this.props.serviceFeeZone : this.state.serviceFeeZone, 
                            this.props.fareSettings?.fare || this.state.fareSettings?.fare, 
                            this.props.selectedFleet, 
                            this.props.commonData?.suppliers?.find(item => item._id === (this.props.supplierCompanies?.[0] || this.props.driver?.company?.companyId)),
                            this.props.bookType,
                            this.props.fareSettings || this.state.fareSettings,
                            this.props.driver,
                          ),
                          { code: fareBookingDetail?.currencyISO }
                        )
                      }
                  </p>
                </div>
              </div>
            </span>
          </div>
        )}
      </>
    );
  };

  getTextWarningRateNotAvailable = () => {
    const {
      newBooking,
      data,
      user,
      locationPickUp,
      locationDestination,
      isHydraBooking,
      is3rdBooking,
    } = this.props;
    if (is3rdBooking) return null;
    let supportedRates =
      _.get(newBooking, 'locationService.supportedRates') || {};
    if (isHydraBooking) {
      supportedRates =
        _.get(newBooking, 'locationService.affiliateService.supportedRates') ||
        {};
    }
    let warningText = '';

    // not apply for hydra booking
    if (isHydraBooking || !locationPickUp || !locationDestination) {
      return null;
    }

    // Cartype was assigned ONLY hourly rate && Type rate is selected "Regular"
    if (
      !supportedRates.regular &&
      (supportedRates.assignFlatFare || supportedRates.hourly) &&
      !data.duration &&
      !supportedRates.flatFare
    ) {
      warningText = I18n.t('newbooking.Not_available');
    }

    // Cartype was NOT assigned any rate
    if (
      !supportedRates.assignFlatFare &&
      !supportedRates.hourly &&
      !supportedRates.regular
    ) {
      if (
        user.userType === 'CorporateUser' ||
        user.userType === 'CorporateAdmin'
      ) {
        return <div>&nbsp;</div>;
      }

      warningText = I18n.t('newbooking.Warning_assigned_rate');
    }

    return warningText ? (
      <div className="trip-estimate-container">
        <div className="estimate-header">
          <div className="estimate-title">
            <Translate value="newbooking.TRIP_ESTIMATE" />
          </div>
        </div>
        <span className="white-text">{warningText}</span>
      </div>
    ) : null;
  };

  renderWarningOutstanding = () => {
    const { isTransportBooking, selectedFleet, data } = this.props;
    const enableOutstandingPayment = _.get(
      selectedFleet,
      'outstandingPayment.enable',
      false
    );
    const outStandingAmount = _.get(data, 'outStanding', []);
    if (
      enableOutstandingPayment &&
      outStandingAmount.length > 0 &&
      isTransportBooking
    ) {
      return (
        <p className="text-outstanding-warning">
          {I18n.t('newbooking.Warning_outstanding_balance')}
        </p>
      );
    }
  };

  checkShowTripInfo = (etaFare) => {
    return checkShowFare({ puPoints: this.props.puPoints, doPoints: this.props.doPoints, etaFare })
  };

  checkShowRoute = () => {
    // book detail
    if(this.props?.data?.bookId) return !!this.props?.data?.request?.estimate?.fare?.route

    // new book
    return !!this.props?.etaFare?.route
  }

  renderNewBooking = () => {
    const {
      etaFare,
      disDur,
      locationPickUp,
      newBooking,
      locationDestination,
      selectedFleet,
      data,
      promo,
      bookingPermission = [],
      reasonMarkup,
      markupType,
      markupValue,
      isHydraBooking,
      farmIn,
      fleetMarkup,
      driverCompany,
      puPoints,
      doPoints,
    } = this.props;

    const permissionShowFare =
      bookingPermission.find((ob) => ob.name === 'ShowFare') || {};
    const isShowFare = _.get(permissionShowFare, 'isActive', true);
    const { allowEditFare, allowMarkupPrice } = this.state;
    const unit = selectedFleet.unitDistance;
    let distanText = disDur ? disDur?.distance?.text : '';
    if(distanText) {
      distanText = distanText?.replace('mi', '').replace('km', '');
      distanText = `${distanText} ${unit}`;
    }

    if (!isHydraBooking) {
      const warningText = this.getTextWarningRateNotAvailable();
      if (warningText) {
        return warningText;
      }
    }

    let promoInfo = {};
    if (promo) {
      promoInfo = {
        value: promo.value,
        type: promo.type,
        maximumValue: promo.maximumValue,
        keepMinFee: promo.keepMinFee,
      };
    }

    if (this.checkShowTripInfo(etaFare)) {
      return (
        <div className="trip-estimate-container">
          <div className="estimate-header" style={{ flexWrap: 'wrap'}}>
            <div className="estimate-title">
              <Translate value="newbooking.TRIP_ESTIMATE" />
              <FareDeeplink
                fareInfo={etaFare}
                puPoints={puPoints}
                doPoints={doPoints}
                auth={this.props.auth}
              />
            </div>
            <div className="estimate-route">
              {!etaFare.normalFare && this.checkShowRoute() ? (
                <div>
                  <Translate value="newbooking.Route" style={{ color: '#04BE76' }}/>
                  {': '}
                  <span className="white-text">
                    {etaFare ? etaFare.route : ''}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="estimateInfo">
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Distance" />
                <span>{distanText}</span>
              </div>
            )}
            {locationPickUp && locationDestination && disDur && (
              <div className="item">
                <Translate value="newbooking.Duration" />
                <span>{disDur.duration.text}</span>
              </div>
            )}
            {isShowFare && (
              <>
                {allowEditFare ||
                allowMarkupPrice ||
                this.state.enableEditDriverEarning ||
                isHydraBooking ? (
                  <EditFare
                    etaFare={etaFare}
                    isHydraBooking={isHydraBooking}
                    driverCompany={driverCompany}
                    farmIn={farmIn}
                    fleetMarkup={fleetMarkup}
                    reasonMarkup={reasonMarkup}
                    markupType={markupType}
                    markupValue={markupValue}
                    promoInfo={promoInfo}
                    allowEditFare={isHydraBooking ? false : allowEditFare}
                    allowMarkupPrice={isHydraBooking ? true : allowMarkupPrice}
                    data={data}
                    bookingPermission={bookingPermission}
                    prevEtaFare={this.props.prevEtaFare}
                    selectedFleet={selectedFleet}
                    user={this.props.user}
                    locationPickUp={locationPickUp}
                    handleChangeETAFare={this.props.handleChangeETAFare}
                    handleChangeMarkupPrice={this.props.handleChangeMarkupPrice}
                    bookType={this.props.bookType}
                    driver={this.props.driver}
                    isNewSettingAdditionFee={this.props.isNewSettingAdditionFee}
                    settingActions={this.props.settingActions}
                    companyList={this.props.companyList}
                    supplierCompanies={this.props.supplierCompanies}
                    driverInfo={this.props.driver}
                    serviceFeeZone={this.state.serviceFeeZone || {}}
                  />
                ) : (
                  <>
                    <div className="item">
                      <Translate value="newbooking.Fare" />
                      <span
                        className={
                          etaFare.addOnPrice && etaFare.addOnPrice !== 0
                            ? etaFare.addOnPrice > 0
                              ? 'adjust-detail'
                              : 'adjust-slow-detail'
                            : null
                        }
                      >
                        {currencyFormatter.format(
                          isHydraBooking
                            ? etaFare.qupSellPrice
                            : etaFare.etaFare,
                          { code: etaFare.currencyISO }
                        )}{' '}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="note">{this.renderHourlyPackagesNote()}</div>
          {this.renderWarningOutstanding()}
        </div>
      );
    }
    return '';
  };

  getStatusConfirm3rdBooking = () => {
    const { isAcceptConfirm3rdBookingStatus, data } = this.props,
      externalInfo = data?.externalInfo || {};
    if (CCLiteCommonFunc.isBookingStatusCompleted(data.status)) return '';

    if (isAcceptConfirm3rdBookingStatus) {
      if (externalInfo.status === STATUS_EXTENAL_INFO.PAMM) {
        return 'Pending Amendment';
      }
      if (externalInfo.status === STATUS_EXTENAL_INFO.PCAN) {
        return 'Pending Cancellation';
      }
      if (externalInfo.status === STATUS_EXTENAL_INFO.PCON) {
        return 'Booking Pending Confirmation';
      }
    }
    return '';
  };

  renderTitleBooking3rd = () => {
    const { is3rdBooking } = this.props;
    const externalInfo = this.props?.data?.externalInfo || {},
      nameAffiliate = getNameAffiliateCarType(this.props.data);
    let statusConfirm3rd = this.getStatusConfirm3rdBooking();
    return (
      <div style={{ width: '100%' }}>
        {statusConfirm3rd && (
          <p style={{ color: '#F04438', marginBottom: 0, fontSize: '17px' }}>
            {statusConfirm3rd}
          </p>
        )}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div className="tripFarmIn 3rdBooking">
            <span className="farmInTrip">Farm-in:</span>
            <span>{` ${externalInfo.thirdParty}`}</span>
            {is3rdBooking && (
              externalInfo.thirdParty === thirdPartyIntegration.hoppa ? 
              <p className="Id3rd">
                <span>
                  {externalInfo.bookingReference}
                </span>
              </p>
              :
              <p className="Id3rd">
                <span>
                  <a
                    href={getURLToCheck3rdBooking(this.props.data)}
                    target="_blank"
                    style={{
                      color: '#53b1fd',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    {externalInfo.bookingReference}{' '}
                  </a>
                  {`${nameAffiliate ? ` ${'\u2022'} ${nameAffiliate}` : ''}`}
                </span>
              </p>
            )}
          </div>
          <FareDeeplink
            fareInfo={this.props.data?.request?.estimate?.fare}
            puPoints={this.props.puPoints}
            doPoints={this.props.doPoints}
            auth={this.props.auth}
          />
        </div>
      </div>
    );
  };

  renderDistanceAndDuration = (distance, duration, isShowInfoPrePaid) => {
    const { disDur, locationPickUp, locationDestination } = this.props;
    if (!disDur || !locationPickUp || !locationDestination) return;

    if (isShowInfoPrePaid) {
      return <div>{`${distance} - ${duration}`}</div>;
    }

    return (
      <>
        <div className="item">
          <Translate value="newbooking.Distance" />
          <span>{distance}</span>
        </div>
        <div className="item">
          <Translate value="newbooking.Duration" />
          <span> {duration}</span>
        </div>
      </>
    );
  };

  renderAmountDueStatusInvoicing = () => {
    const { data, farmOut, fleetMarkup, farmIn } = this.props;
    const amountDue = calculatorAmountDue(
      data,
      CCLiteCommonFunc.isBookingStatusCompleted(data.status),
      farmOut,
      fleetMarkup,
      farmIn,
      this.props.selectedFleet
    );
    const status = data.paidStatus || PAID_STATUS.pending;
    return (
      <>
        <div className="item">
          <Translate value="newbooking.AmountDue" />
          <span>
            {currencyFormatter.format(amountDue, {
              code: data.currencyISO,
            })}
          </span>
        </div>
        <div className="item">
          <Translate value="newbooking.Status" />
          <span className={`bk-${status} btnStatus`}>
            {I18n.t(`bookingdetail.${status}`)}
          </span>
        </div>
      </>
    );
  };

  renderDistanceAndDurationTime = (droppedOffInfo, time) => {
    const { unitDistance } = this.props.selectedFleet;
    const { distanceTour = 0 } = droppedOffInfo || {};
    const { droppedOff, engaged, booked, completed } = time;

    let resultDistance = '';

    if (unitDistance === 'km') {
      resultDistance = parseFloat(distanceTour / 1000).toFixed(1) + ' km';
    } else if (unitDistance === 'mi') {
      resultDistance = parseFloat(distanceTour / 1609.34).toFixed(1) + ' mi';
    }

    let dropoffTime = new Date(droppedOff || completed);
    let engagedTime = new Date(engaged || booked);
    let timeDifference = dropoffTime - engagedTime;
    let minutesDifference = Math.floor(timeDifference / (1000 * 60)) || 0;
    let hoursDifference = Math.floor(minutesDifference / 60);
    let resultDurationTime =
      minutesDifference < 60
        ? minutesDifference + ' mins'
        : hoursDifference + ' h ' + (minutesDifference % 60) + ' mins';

    return (
      <div>
        {resultDistance} - {resultDurationTime}
      </div>
    );
  };

  renderInvoiceId = (invoiceId) => {
    if(this.state.isCorporateBoard) return null;
    return (
      <a 
        href={`/invoice?action=viewinvoice&invoiceid=${invoiceId}`} 
        target="_blank"
        style={{ color: 'rgb(83, 177, 253)', fontSize: '14px' }}
      >
        {invoiceId}
      </a>
    );
  };

  renderBookingDetail = () => {
    const {
      disDur,
      data,
      promo,
      selectedFleet,
      locationPickUp,
      locationDestination,
      isHasExtraDestination,
      bookingPermission = [],
      prevEtaFare,
      isDisableOtherOperation,
      isHydraBooking,
      hydraStatusText,
      cartypeSelected,
      roaming,
      etaFareLocalOrigin,
      etaFareMultiCar,
      farmIn,
      farmOut,
      is3rdBooking,
      permissionActionBooking,
      isApiDemandBooking,
      puPoints,
      doPoints,
      isSupplierPermission
    } = this.props;
    const { allowEditFare, allowMarkupPrice } = this.state;
    const permissionShowFare =
      bookingPermission.find((ob) => ob.name === 'ShowFare') || {};
    const isShowInfoPrePaid = checkShowInfoPrePaid({
      bookInfo: this.props.data, 
      is3rdBooking, 
      isApiDemandBooking, 
      isSupplierPermission,
      selectedFleet
    });
    const isShowFare =
      _.get(permissionShowFare, 'isActive', true) &&
      !getSuppilerPermission(this.props.auth);
    const unit = selectedFleet.unitDistance;
    let distanText = disDur
      ? disDur.distance.text
      : data.request.estimate.distance;
    let nameProvider = '';
    if(distanText) {
      distanText = distanText
        ? distanText.replace('mi', '').replace('km', '')
        : '';
      distanText = `${distanText} ${unit}`;
    }
    let prevTotalFare = null;
    if (
      prevEtaFare &&
      prevEtaFare.originFare &&
      ((prevEtaFare.isFareEdited && prevEtaFare.originFare.etaFare) ||
        (data.request &&
          data.request.estimate &&
          data.request.estimate.fare.markupDifference))
    ) {
      prevTotalFare = prevEtaFare.originFare.etaFare;
    }

    const warningText = this.getTextWarningRateNotAvailable();
    if (warningText) {
      return warningText;
    }

    let promoInfo = {};
    if (promo && promo.type) {
      promoInfo = {
        value: promo.value,
        type: promo.type,
        maximumValue: promo.maximumValue,
        keepMinFee: promo.keepMinFee,
      };
    } else if (data.request.promoInfo) {
      promoInfo = {
        value: data.request.promoInfo.value,
        type: data.request.promoInfo.type,
        maximumValue: data.request.promoInfo.maximumValue,
        keepMinFee: data.request.promoInfo,
      };
    }
    if (isHydraBooking) {
      nameProvider = _.get(cartypeSelected, 'providerOfVhc.fleetName');
    }

    if (
      this.checkShowTripInfo(data?.request?.estimate?.fare) ||
      isShowInfoPrePaid
    ) {
      return (
        <>
          <div className="trip-estimate-container">
            {data.invoiceId && (
              <div className="estimate-header invoiceTrip">
                <div className="estimate-title hasInvoicing">
                  <span className="farmInTrip">Invoice</span>
                  <div>{this.renderInvoiceId(data.invoiceId)}</div>
                </div>
              </div>
            )}
            <div className="estimate-header">
              {is3rdBooking || isApiDemandBooking ? (
                this.renderTitleBooking3rd()
              ) : farmIn ? (
                <div className="tripFarmIn">
                  <span className="farmInTrip">Farm-in</span>
                  <span>{` from ${data.request.psgFleetName}`}</span>
                </div>
              ) : (
                <>
                  <div
                    className={`estimate-title ${
                      isShowInfoPrePaid && 'hasInvoicing'
                    }`}
                  >
                    <div className="titleInvoice">
                      <Translate value="newbooking.TRIP_ESTIMATE" />
                      {this.checkShowRoute() &&
                      !isHasExtraDestination ? (
                        <div className="text-ellipsis">
                          <Translate value="newbooking.Route" />
                          {': '}
                          <span className="white-text">
                            {this.renderEstimateRoute()}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                      {isShowInfoPrePaid &&
                        (data.status === 'completed' &&
                        data.jobType === 'rideHailing'
                          ? this.renderDistanceAndDurationTime(
                              data.droppedOffInfo,
                              data.time
                            )
                          : this.renderDistanceAndDuration(
                              // chi show khi payment la direct invoicing
                              distanText,
                              disDur?.duration?.text,
                              isShowInfoPrePaid
                            ))}
                    </div>
                    <FareDeeplink
                      fareInfo={this.props.data?.request?.estimate?.fare}
                      puPoints={puPoints}
                      doPoints={doPoints}
                      auth={this.props.auth}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={`estimateInfo ${farmIn ? 'farmIn' : ''}`}>
              {!isShowInfoPrePaid &&
                this.renderDistanceAndDuration(
                  // Show khi payment khac direct invoicing
                  distanText,
                  disDur?.duration?.text,
                  isShowInfoPrePaid
                )}

              {
                // show fare and driver earning
                isShowFare && (
                  <>
                    {allowEditFare ||
                    allowMarkupPrice ||
                    this.state.enableEditDriverEarning ||
                    isHydraBooking ? (
                      <EditFare
                        canEditMarkupHydra={this.props.canEditMarkupHydra}
                        etaFare={data.request.estimate.fare}
                        is3rdBooking={this.props.is3rdBooking}
                        saveHolidayTaxisCompletedEditFare={
                          this.props.saveHolidayTaxisCompletedEditFare
                        }
                        farmOut={farmOut}
                        etaFareLocalOrigin={etaFareLocalOrigin}
                        isHydraAccepted={
                          hydraStatusText === HYDRA_STATUS.accepted
                        }
                        fleetMarkup={this.props.fleetMarkup}
                        isHydraBooking={isHydraBooking}
                        reasonMarkup={data.request.estimate.reasonMarkup}
                        markupType={data.request.estimate.markupType}
                        markupValue={data.request.estimate.markupValue}
                        forceMarkupPrice={
                          data.request.estimate.forceMarkupPrice
                        }
                        promoInfo={promoInfo}
                        allowEditFare={isHydraBooking ? false : allowEditFare}
                        allowMarkupPrice={
                          isHydraBooking ? true : allowMarkupPrice
                        }
                        isDisableOtherOperation={isDisableOtherOperation}
                        data={data}
                        bookingPermission={this.props.bookingPermission}
                        isFareEditedBefore={this.props.isFareEditedBefore}
                        prevEtaFare={this.props.prevEtaFare}
                        selectedFleet={selectedFleet}
                        handleChangeETAFare={this.props.handleChangeETAFare}
                        handleChangeMarkupPrice={
                          this.props.handleChangeMarkupPrice
                        }
                        bookType={this.props.bookType}
                        driver={this.props.driver}
                        isNewSettingAdditionFee={
                          this.props.isNewSettingAdditionFee
                        }
                        settingActions={this.props.settingActions}
                        user={this.props.user}
                        locationPickUp={locationPickUp}
                        isShowInfoPrePaid={isShowInfoPrePaid}
                        renderAmountDueStatusInvoicing={
                          this.renderAmountDueStatusInvoicing
                        }
                        companyList={this.props.companyList}
                        supplierCompanies={this.props.supplierCompanies}
                        serviceFeeZone={this.state.serviceFeeZone || {}}
                      />
                    ) : (
                      <>
                        <div className="item">
                          {farmIn || is3rdBooking ? (
                            <Translate value="newbooking.Receivable" />
                          ) : (
                            <Translate value="newbooking.Fare" />
                          )}
                          <span
                            className={
                              data.request.estimate?.fare?.addOnPrice &&
                              data.request.estimate?.fare?.addOnPrice !== 0
                                ? data.request.estimate?.fare?.addOnPrice > 0
                                  ? 'adjust-detail'
                                  : 'adjust-slow-detail'
                                : 'farmInFareTrip'
                            }
                          >
                            {this.renderEstimatedFare()}
                          </span>
                          {prevTotalFare && (
                            <div className="item">
                              <span style={{ textDecoration: 'line-through' }}>
                                {currencyFormatter.format(prevTotalFare, {
                                  code: data.currencyISO,
                                })}
                              </span>
                            </div>
                          )}
                        </div>
                        {isShowInfoPrePaid &&
                          this.renderAmountDueStatusInvoicing()}
                        {this.renderDriverEarningUIBookingDetail()}
                      </>
                    )}
                  </>
                )
              }
            </div>
            <div className="note">{this.renderHourlyPackagesNote()}</div>
            {this.renderWarningOutstanding()}
          </div>
          {isHydraBooking && !roaming && (
            <div className="trip-estimate-container">
              <div className="estimate-hydra">
                <span className="estimate-title">Farm-out</span>
                {hydraStatusText !== HYDRA_STATUS.none && (
                  <span className={`hydraStatus ${hydraStatusText}`}>
                    {hydraStatusText}
                  </span>
                )}
              </div>
              {hydraStatusText === HYDRA_STATUS.denied ||
              hydraStatusText === HYDRA_STATUS.rejected ? (
                <div className="estimate-hydra-bt">
                  <p>
                    The affiliate has rejected the job, please choose another
                    provider to farm out the job to or find other alternatives.
                  </p>
                </div>
              ) : (
                <div className="estimate-hydra-bt">
                  <div>
                    <p>Price</p>
                    <span>
                      {currencyFormatter.format(
                        _.get(data, 'request.estimate.fare.qupSellPrice', ''),
                        {
                          code: _.get(
                            data,
                            'request.estimate.fare.currencyISO',
                            ''
                          ),
                        }
                      )}
                    </span>
                  </div>
                  <div>
                    <p>Affiliate</p>
                    <span>{nameProvider}</span>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      );
    }
    return '';
  };

  render() {
    const { data } = this.props;
    if (data.bookId) return this.renderBookingDetail();
    return this.renderNewBooking();
  }
}

export default TripEstimate;
