import ReportUtils from './../base/utils'
import { I18n } from 'react-redux-i18n';

export const fields = [

  {
    key: "payoutId",
    label: "report.result.payoutToDriver.payoutID"
  },
  {
    key: "driverName",
    label: "report.result.payoutToDriver.driverName"
  },
  {
    key: "phone",
    label: "report.result.payoutToDriver.driverPhone"
  },
  {
    key: "driverNumber",
    label: "report.result.payoutToDriver.driverId",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "bankAccountHolder",
    label: "report.result.payoutToDriver.accountHoldName"
  },
  {
    key: "bankName",
    label: "report.result.payoutToDriver.bankName",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway == 'DNB') {
        return true
      }
      return false
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToDriver.accountNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway !== 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "accountNumber",
    label: "report.result.payoutToDriver.iBanNumber",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'DNB') {
        return false
      }
      return true
    }
  },
  {
    key: "IFSCCode",
    label: "report.result.payoutToDriver.IFSCCode",
    checkShowFromProps: 'selectedFleet',
    isNotShow: selectedFleet => {
      if (selectedFleet.drvPayout && selectedFleet.drvPayout.gateway === 'IndiaDefault') return false
      return true
    }
  },
  {
    key: "isBankAccountOwner",
    label: "report.result.payoutToDriver.isBankAccountOwner",
    mutate: (value, doc) => {
      if (doc.isBankAccountOwner) {
        return I18n.t(`report.result.payoutToDriver.isBankAccountOwner_${doc.isBankAccountOwner}`);
      }
      return I18n.t(`report.result.payoutToDriver.isBankAccountOwner_${doc.isBankAccountOwner}`);
    }
  },
  {
    key: "beneficiaryIDIC",
    label: "report.result.payoutToDriver.beneficiaryIDIC"
  },
  {
    key: "bankRelationship",
    label: "report.result.payoutToDriver.bankRelationship",
    mutate: (value, doc) => {
      switch (doc.bankRelationship) {
        case 1:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 2:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 3:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 4:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 5:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`);
        case 6:
          return I18n.t(`report.result.payoutToDriver.bankRelationship_${doc.bankRelationship}`).format(
            doc.relationshipOtherName ? doc.relationshipOtherName : ''
          );
        default:
          return;
      }
    }
  },
  {
    key: "companyName",
    label: "report.result.payoutToDriver.companyName"
  },

  {
    key: "payoutDate",
    label: "report.result.payoutToDriver.payoutDate",
    mutate: (value, doc) => (value
      ? ReportUtils.formatTime(value, doc, {
        formatStr: 'MM/DD/YYYY hh:mm:ss A'
      })
      : 'N/A')
  },
  {
    key: "paidAmount",
    label: "report.result.payoutToDriver.payoutAmount",
    summary: "paidAmount"
  },

];

export const fareFields = ["paidAmount"]