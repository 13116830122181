import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import ReactDOM from 'react-dom';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../components/validator';
import {
  DefaultCurenciesNumberInputField,
  REGULAR_MODE,
} from '../../../../constants/commondata';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import _, { parseInt } from 'lodash';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { Columns } from './tableHeaderData';
import TableActions from '../../../../components/table/tableAction/TableActions';

import PriceAdjustment from './components/PriceAdjustment';
import ExtraWaitTime from '../components/extraWaitTime';
import { BsSearch } from 'react-icons/bs';

class RegularSetting extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      editable: false,
      valid: {},
      isSubmited: false,
      carTypeList: null,
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      str: '',
    };
  }

  async componentDidMount() {
    this.updateDataList();
    const locationQuery = _.get(this.props.router, 'location.query', {});
    if (locationQuery.rateId) {
      this.handleOpenFromDeeplink(locationQuery.rateId);
    }
  }

  handleOpenFromDeeplink = async (routeId) => {
    try {
      const result = await this.props.settingActions.getRateDetailRegular({
        _id: routeId,
        fleetId: this.props.auth?.selectedFleet?.fleetId,
      });
      if (result.ok) {
        this.setState({
          showDialog: true,
          dialogData: result.res,
          dialogChanged: false,
          editable: true,
        });
      } else {
        if (result.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + result?.error?.errorCode)
          );
        } else {
          this.context.notification(
            'error',
            I18n.t('regularSetting.Loading_regular_rate_fail')
          );
        }
      }
    } catch (error) {
      this.context.notification(
        'error',
        I18n.t('regularSetting.Loading_regular_rate_fail')
      );
    }
  };

  handleSearchChange = (e) => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updateDataList();
    }
  };

  updateDataList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str,
    };

    this.props.settingActions.getRateAllRegulars(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit },
        });
      }
    });
  };

  saveDialogForm = () => {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
      this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
      this.state.dialogData.firstDistanceTo = this.state.dialogData
        .firstDistanceTo
        ? parseFloat(this.state.dialogData.firstDistanceTo)
        : 0;
      this.state.dialogData.fareWaitTimeNow = this.state.dialogData
        .fareWaitTimeNow
        ? parseFloat(this.state.dialogData.fareWaitTimeNow)
        : 0;
      this.state.dialogData.fareWaitTimeLater = this.state.dialogData
        .fareWaitTimeLater
        ? parseFloat(this.state.dialogData.fareWaitTimeLater)
        : 0;
      this.state.dialogData.secondDistanceFrom = this.state.dialogData
        .secondDistanceFrom
        ? parseFloat(this.state.dialogData.secondDistanceFrom)
        : 0;
      this.state.dialogData.secondDistanceTo = this.state.dialogData
        .secondDistanceTo
        ? parseFloat(this.state.dialogData.secondDistanceTo)
        : 0;
      this.state.dialogData.feesByCurrencies =
        _.isArray(this.state.dialogData.feesByCurrencies) &&
        this.state.dialogData.feesByCurrencies.length > 0
          ? this.state.dialogData.feesByCurrencies.map((item) => {
              return {
                currencyISO: item.currencyISO,
                feeAfterSecondDistance: item.feeAfterSecondDistance
                  ? parseFloat(item.feeAfterSecondDistance)
                  : 0,
                feeFirstDistance: item.feeFirstDistance
                  ? parseFloat(item.feeFirstDistance)
                  : 0,
                feePerMinute: item.feePerMinute
                  ? parseFloat(item.feePerMinute)
                  : 0,
                fareWaitTimeNow: item.fareWaitTimeNow
                  ? parseFloat(item.fareWaitTimeNow)
                  : 0,
                fareWaitTimeLater: item.fareWaitTimeLater
                  ? parseFloat(item.fareWaitTimeLater)
                  : 0,
                feeSecondDistance: item.feeSecondDistance
                  ? parseFloat(item.feeSecondDistance)
                  : 0,
                minNow: item.minNow ? parseFloat(item.minNow) : 0,
                minReservation: item.minReservation
                  ? parseFloat(item.minReservation)
                  : 0,
                startingNow: item.startingNow
                  ? parseFloat(item.startingNow)
                  : 0,
                startingReservation: item.startingReservation
                  ? parseFloat(item.startingReservation)
                  : 0,
              };
            })
          : [];
      this.props.loadingBarActions.showLoadingSpiner();
      if (this.state.dialogData._id) {
        let objectCompany = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          name: this.state.dialogData.name,
          _id: this.state.dialogData._id,
        };
        this.props.settingActions
          .updateRateRegular(this.state.dialogData)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (!data.ok) {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Update_regular_rate_fail')
                );
              }
            } else {
              this.context.notification(
                'success',
                I18n.t('regularSetting.Update_regular_rate_success')
              );
              this.closeDialogForm();
              this.updateDataList();
            }
          });
      } else {
        this.props.settingActions
          .createRateRegular(this.state.dialogData)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (!data.ok) {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Create_regular_rate_fail')
                );
              }
            } else {
              this.context.notification(
                'success',
                I18n.t('regularSetting.Create_regular_rate_success')
              );
              this.closeDialogForm();
              this.updateDataList();
            }
          });
      }
    }
  };
  handleInputChange = (key, e) => {
    this.state.dialogData[key] = e.target.value;
    if (key == 'firstDistanceTo') {
      this.state.dialogData.secondDistanceFrom = e.target.value;
    }
    if (key == 'onDemand_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.onDemand.payToDrv =
        e.target.value;
    }
    if (key == 'onDemand_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.onDemand.type = e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.inAdvance.payToDrv =
        e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.inAdvance.type = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver') {
      this.state.dialogData.noShow.payToDrv = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver_type') {
      this.state.dialogData.noShow.type = e.target.value;
    }
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handlePayDrvCashTypeChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              value: _.get(dialogData, `noShow.payDrvCash.value`, 0),
              type: e.target.value,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                value: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.value`,
                  0
                ),
                type: e.target.value,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  handlePayDrvCashValueChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    let valueInput = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              type: _.get(dialogData, `noShow.payDrvCash.type`, 'commission'),
              value: valueInput,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                type: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.payDrvCash.type`,
                  'commission'
                ),
                value: valueInput,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    if (group == 'noShow') {
      this.state.dialogData.noShow.valueByCurrencies =
        this.state.dialogData.noShow.valueByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.noShow.valueByCurrencies =
        this.state.dialogData.noShow.valueByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            return { ...c, value: e.target.value };
          }
          return c;
        });
      if (isAdd) {
        this.state.dialogData.noShow.valueByCurrencies.push(cur);
      }
    } else if (group == 'noShowPayDrvAmt') {
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            c.value = e.target.value;
            return c;
          }
          return c;
        });
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.noShow.drvGetAmtByCurrencies.push(ob);
      }
    } else if (
      group == 'startingNow' ||
      group == 'startingReservation' ||
      group == 'feeFirstDistance' ||
      group == 'feeSecondDistance' ||
      group == 'feeAfterSecondDistance' ||
      group == 'feePerMinute' ||
      group == 'fareWaitTimeNow' ||
      group == 'fareWaitTimeLater' ||
      group == 'minNow' ||
      group == 'minReservation'
    ) {
      this.state.dialogData.feesByCurrencies =
        this.state.dialogData.feesByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.feesByCurrencies =
        this.state.dialogData.feesByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            c[group] = e.target.value;
            return c;
          }
          return c;
        });
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          startingNow: DefaultCurenciesNumberInputField,
          startingReservation: DefaultCurenciesNumberInputField,
          feeFirstDistance: DefaultCurenciesNumberInputField,
          feeSecondDistance: DefaultCurenciesNumberInputField,
          feeAfterSecondDistance: DefaultCurenciesNumberInputField,
          feePerMinute: DefaultCurenciesNumberInputField,
          fareWaitTimeNow: DefaultCurenciesNumberInputField,
          fareWaitTimeLater: DefaultCurenciesNumberInputField,
          minNow: DefaultCurenciesNumberInputField,
          minReservation: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.feesByCurrencies.push(ob);
      }
    } else if (group == 'onDemandPayDrvAmt') {
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else if (group == 'inAdvancePayDrvAmt') {
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else {
      this.state.dialogData.cancellationPolicy[group].valueByCurrencies =
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy[group].valueByCurrencies =
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          }
        );
      if (isAdd) {
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.push(
          cur
        );
      }
    }

    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handlePriceAdjustmentChange = (key, e, currencyISO) => {
    const { dialogData } = this.state;
    let isAdd = true;
    let priceAdjustment = dialogData.priceAdjustment || {
      isActive: false,
      minimumPercent: 0,
      maximumPercent: 0,
      valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
        (data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        }
      ),
    };

    let cur = {
      currencyISO,
      value: e.target.value,
    };

    switch (key) {
      case 'isActive':
        priceAdjustment.isActive = e.target.checked;
        break;
      case 'minimumPercent':
        priceAdjustment.minimumPercent = e.target.value;
        break;
      case 'maximumPercent':
        priceAdjustment.maximumPercent = e.target.value;
        break;
      case 'valueByCurrencies':
        priceAdjustment.valueByCurrencies =
          priceAdjustment.valueByCurrencies.map((c) => {
            if (c.currencyISO === currencyISO) {
              isAdd = false;
              return { ...c, value: e.target.value };
            }
            return c;
          });
        if (isAdd) {
          priceAdjustment.valueByCurrencies.push(cur);
        }
        break;
      default:
        break;
    }
    this.setState({
      dialogData: {
        ...dialogData,
        priceAdjustment,
      },
      dialogChanged: true,
    });
  };

  handleCancelInputChange = (key, e) => {
    switch (key) {
      case 'onDemand_isActive': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_tax': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_techFee': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_isActive': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_tax': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_techFee': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_tax': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTax = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_techFee': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTechFee = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };

  handleDriverCancelChange = (e, type, key) => {
    const dialogData = this.state.dialogData || {};
    let valueInput = 0;
    if (key === 'value') {
      valueInput = (e.target.value || '').toString().replace(/[^0-9\.]+/, '');
    }
    if (key === 'isActive') valueInput = e.target.checked;
    if (key === 'type') valueInput = e.target.value;
    this.setState({
      dialogData: {
        ...dialogData,
        driverCancelPolicy: {
          ..._.get(dialogData, `driverCancelPolicy`, {}),
          [type]: {
            type: _.get(
              dialogData,
              `driverCancelPolicy.${type}.type`,
              'amount'
            ),
            isActive: _.get(
              dialogData,
              `driverCancelPolicy.${type}.isActive`,
              false
            ),
            value: _.get(dialogData, `driverCancelPolicy.${type}.value`, 0),
            [key]: valueInput,
          },
        },
      },
      dialogChanged: true,
    });
  };

  handlePerminuteInputChange = (key, e) => {
    switch (key) {
      case 'feePerMinute': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].feePerMinute = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'fareWaitTimeNow': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].fareWaitTimeNow =
          e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'fareWaitTimeLater': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].fareWaitTimeLater =
          e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minNow': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minNow = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minReservation': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minReservation =
          e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };

  handleAddButtonClick = () => {
    let object = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      name: '',
      startingASAP: 0,
      startingPreBook: 0,
      firstDistanceFrom: 0,
      firstDistanceTo: '',
      feeFirstDistance: 0,
      afterFirstDistance: 0,
      feeAfterFirstDistance: 0,
      secondDistanceFrom: '',
      secondDistanceTo: '',
      feeSecondDistance: 0,
      fareWaitTimeNow: 0,
      fareWaitTimeLater: 0,
      afterSecondDistance: 0,
      feeAfterSecondDistance: 0,
      perTime: 0,
      perDistance: 0,
      speedThreshold: 0,
      feeWaiting: 0,
      minASAP: 0,
      minPreBook: 0,
      feeTimeOver: 0.5,
      isDefault: false,
      timeOver: 0,
      feesByCurrencies: this.props.auth.selectedFleet.currencies.map((data) => {
        return {
          minReservation: 0,
          minNow: 0,
          feePerMinute: 0,
          fareWaitTimeNow: 0,
          fareWaitTimeLater: 0,
          feeAfterSecondDistance: 0,
          feeSecondDistance: 0,
          feeFirstDistance: 0,
          startingReservation: 0,
          startingNow: 0,
          currencyISO: data.iso,
        };
      }),
      isActive: true,
      noShow: {
        isActive: false,
        enableTax: false,
        enableTechFee: true,
        payToDrv: 'commission',
        valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
        drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
      },
      cancellationPolicy: {
        isActive: false,
        inAdvance: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
        onDemand: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
      },
      priceAdjustment: {
        isActive: false,
        minimumPercent: 0,
        maximumPercent: 0,
        valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: DefaultCurenciesNumberInputField,
              currencyISO: data.iso,
            };
          }
        ),
      },
    };
    if (this.state.carTypeList == null) {
    }
    this.setState({ showDialog: true, dialogData: object, editable: true });
  };
  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
      isSubmited: false,
    });
  };
  handleMenuClick = (key, data) => {
    switch (key) {
      case 'View': {
        this.props.settingActions
          .getRateDetailRegular({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: false,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Loading_regular_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Edit': {
        this.props.settingActions
          .getRateDetailRegular({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: data.res,
                dialogChanged: false,
                editable: true,
              });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Loading_regular_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Status': {
        if (data.inUse) {
          break;
        }
        if (data.isActive) {
          this.props.settingActions
            .deactiveRateRegular({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_regular_status_fail')
                  );
                }
              }
            });
        } else {
          this.props.settingActions
            .activeRateRegular({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_regular_status_fail')
                  );
                }
              }
            });
        }

        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        if (data.inUse) {
          break;
        }
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
    }
  };

  handlePaginationSelect = (eventKey) => {
    this.updateDataList(eventKey);
  };

  handleNumItemsPerPageChange = (limit) => {
    this.updateDataList(0, limit);
  };

  confirmDeleteUser = () => {
    this.props.settingActions
      .deleteRateRegular({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('regularSetting.Delete_regular_rate_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Delete_regular_rate_fail')
            );
          }
        }
      });
  };
  isFormValid(data) {
    let isValid = true;
    Object.keys(data).every((key) => {
      if (!data[key]) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  }

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  getTableColumns = () => {
    let tableColumns = Object.assign([], Columns);
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (regular) => {
            return (
              <OverlayTrigger
                overlay={<Tooltip id={'tooltip'}>{regular.name}</Tooltip>}
                placement="top"
                delayShow={300}
              >
                <div
                  className="clickable text-ellipsis"
                  onClick={() => {
                    this.handleMenuClick('View', regular);
                  }}
                >
                  {regular.name}
                </div>
              </OverlayTrigger>
            );
          };
          break;

        case 'latestUpdate':
          col.customCell = (regular) => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={regular.latestUpdate}
              />
            );
          };
          break;

        case 'regularStatus':
          col.customCell = (regular) => {
            let statusStr, statusClass;
            if (regular && regular.isActive) {
              statusStr = <Translate value="regular.Active" />;
              statusClass = 'Active';
            } else {
              if (regular && !regular.isActivate) {
                statusStr = <Translate value="regular.Inactive" />;
                statusClass = 'Inactive';
              } else statusStr = null;
            }
            return (
              <div className={statusClass}>
                {!regular.inUse &&
                (!this.props.permissions || this.props.permissions.actions) ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', regular);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;

        case 'actions':
          col.customCell = (regular, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: 'regular.Edit',
                  eventKey: 'Edit',
                },
              ];

              if (!regular.inUse) {
                actionItems.push({
                  label: regular.isActive
                    ? 'regular.Deactivate'
                    : 'regular.Activate',
                  eventKey: 'Status',
                });

                if (!regular.isActive) {
                  actionItems.push({
                    label: 'regular.Delete',
                    eventKey: 'Delete',
                  });
                }
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={regular}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, regular);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', regular);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  render() {
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const { dialogData } = this.state;
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    const priceAdjustable =
      this.props.auth.selectedFleet.priceAdjustable || false;
    const cancellationCashBooking = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.cancellationCashBooking',
      false
    );
    const enablePenalty = _.get(
      this.props.auth,
      'selectedFleet.drvApp.enablePenalty',
      false
    );
    let payToDrvOnDemand = _.get(
      this.state.dialogData,
      'cancellationPolicy.onDemand.payToDrv',
      'commission'
    );
    let payToDrvInAdvance = _.get(
      this.state.dialogData,
      'cancellationPolicy.inAdvance.payToDrv',
      'commission'
    );
    let payToDrvNoShow = _.get(
      this.state.dialogData,
      'noShow.payToDrv',
      'commission'
    );
    let payDrvCashType = _.get(
      dialogData,
      'cancellationPolicy.onDemand.payDrvCash.type',
      'commission'
    );
    let payDrvCashInAdvanceType = _.get(
      dialogData,
      'cancellationPolicy.inAdvance.payDrvCash.type',
      'commission'
    );
    let payDrvCashNoShowType = _.get(
      dialogData,
      'noShow.payDrvCash.type',
      'commission'
    );
    return (
      <div className="content custom-form">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="regularSetting.DELETE_REGULAR_RATE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="regularSetting.Delete_confirm_regular_rate" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteUser}
              >
                <Translate value="regularSetting.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showDialog ? (
          <Modal
            show={true}
            backdrop={true}
            dialogClassName="fit-content-modal rate-modal"
            onHide={this.closeDialogForm}
          >
            <Modal.Header className={'pd-r-l-60'}>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="regularSetting.EDIT_REGULAR_RATE" />
                  ) : (
                    <Translate value="regularSetting.VIEW_REGULAR_RATE" />
                  )
                ) : (
                  <Translate value="regularSetting.ADD_REGULAR_RATE" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className={'pd-r-l-60'}>
              <Row>
                <Col md={4} sm={12}>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.name === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Name" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      onChange={(e) => {
                        this.handleInputChange('name', e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.name : ''
                      }
                      placeholder={I18n.t('regularSetting.Name')}
                      disabled={!this.state.editable}
                    />
                    <Validator
                      id="name"
                      callback={this.ValidatorCallback}
                      disabled={!this.state.editable}
                    >
                      <ValidCase
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.name)
                        }
                        message={I18n.t(
                          'messages.commonMessages.Required_field'
                        )}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <div className={'vs-20'} />
                  <FormGroupTitle>
                    <Translate value="regularSetting.Mode" />
                  </FormGroupTitle>
                  <FormGroup>
                    <FormControl
                      as="select"
                      className="mr-t-5 form-custom"
                      value={this.state.dialogData.mode}
                      onChange={(e) => {
                        this.handleInputChange('mode', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option
                        value={REGULAR_MODE.timeAndMileage}
                        key="timeAndMileage"
                      >
                        {I18n.t('regularSetting.timeAndMileage')}
                      </option>
                      <option
                        value={REGULAR_MODE.timeOrMileage}
                        key="timeOrMileage"
                      >
                        {I18n.t('regularSetting.timeOrMileage')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {this.state.dialogData.mode === 'timeOrMileage' && (
                    <>
                      <div className={'vs-20'} />
                      <FormGroup>
                        <Form.Label>
                          <Translate
                            value={
                              this.props.auth.selectedFleet.unitDistance ===
                              'km'
                                ? 'regularSetting.speedThresholdKM'
                                : 'regularSetting.speedThresholdMI'
                            }
                          />
                        </Form.Label>
                        <FormControl
                          type="number"
                          className="form-custom"
                          onChange={(e) => {
                            this.handleInputChange('speedThreshold', e);
                          }}
                          placeholder={I18n.t(
                            this.props.auth.selectedFleet.unitDistance === 'km'
                              ? 'regularSetting.speedThresholdKM'
                              : 'regularSetting.speedThresholdMI'
                          )}
                          onBlur={(e) => {
                            textboxNumberHelper.onBlurHandle(e, (e) => {
                              this.handleInputChange('speedThreshold', e);
                            });
                          }}
                          onFocus={(e) => {
                            textboxNumberHelper.onfocusHandle(e, (e) => {
                              this.handleInputChange('speedThreshold', e);
                            });
                          }}
                          value={this.state.dialogData.speedThreshold}
                          disabled={!this.state.editable}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.perTime" />
                        </Form.Label>
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="number"
                              className="form-custom"
                              onChange={(e) => {
                                this.handleInputChange('perTime', e);
                              }}
                              value={this.state.dialogData.perTime}
                              placeholder={I18n.t('regularSetting.perTime')}
                              disabled={!this.state.editable}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleInputChange('perTime', e);
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleInputChange('perTime', e);
                                });
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={_.get(dialogData, `perTime`, 0)}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Form.Label>
                          <Translate
                            value={
                              this.props.auth.selectedFleet.unitDistance ===
                              'km'
                                ? 'regularSetting.perDistanceKM'
                                : 'regularSetting.perDistanceMI'
                            }
                          />
                        </Form.Label>
                        <FormGroup className="qup-input-group">
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="number"
                              className="form-custom"
                              onChange={(e) => {
                                this.handleInputChange('perDistance', e);
                              }}
                              value={this.state.dialogData.perDistance}
                              disabled={!this.state.editable}
                              placeholder={I18n.t(
                                this.props.auth.selectedFleet.unitDistance ===
                                  'km'
                                  ? 'regularSetting.perDistanceKM'
                                  : 'regularSetting.perDistanceMI'
                              )}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleInputChange('perDistance', e);
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleInputChange('perDistance', e);
                                });
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={_.get(dialogData, `perDistance`, 0)}
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </FormGroup>
                    </>
                  )}
                  <div className={'vs-20'} />
                  <FormGroupTitle>
                    <Translate value="regularSetting.STARTING" />
                  </FormGroupTitle>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.startingNow === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Starting_Now" />
                    </Form.Label>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Starting_Now"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            {
                              /* console.log({ feesByCurrencies: this.state.dialogData.feesByCurrencies }); */
                            }
                            let currentValue =
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0];
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                                key={c.iso}
                                >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {c.symbol}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <FormControl
                                      type="text"
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'startingNow',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'startingNow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'startingNow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.startingNow
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.feesByCurrencies ||
                            !this.state.dialogData.feesByCurrencies.length
                              ? null
                              : this.state.dialogData.feesByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, startingNow } = fees;
                                    return {
                                      [currencyISO]: startingNow,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'startingNow',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'startingNow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'startingNow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ) &&
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].startingNow
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              placeholder={I18n.t(
                                'regularSetting.Starting_Now'
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ) &&
                                this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.feesByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].startingNow
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="startingNow"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(d.startingNow) &&
                              isNaN(d.startingNow)
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return !Validation.isGreaterOrEqual(
                              d.startingNow,
                              0
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.startingReservation === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Starting_Reservation" />
                    </Form.Label>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Starting_Reservation"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue =
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0];
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'startingReservation',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'startingReservation',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'startingReservation',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.startingReservation
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.feesByCurrencies ||
                            !this.state.dialogData.feesByCurrencies.length
                              ? null
                              : this.state.dialogData.feesByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, startingReservation } =
                                      fees;
                                    return {
                                      [currencyISO]: startingReservation,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'startingReservation',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'startingReservation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'startingReservation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ) &&
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].startingReservation
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              placeholder={I18n.t(
                                'regularSetting.Starting_Reservation'
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ) &&
                                this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.feesByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].startingReservation
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="startingReservation"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(
                                d.startingReservation
                              ) && isNaN(d.startingReservation)
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(
                                d.startingReservation
                              ) &&
                              !Validation.isGreaterOrEqual(
                                d.startingReservation,
                                0
                              )
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  {this.state.dialogData.mode !== 'timeOrMileage' && (
                    <>
                      <div className={'vs-20'} />
                      <FormGroupTitle>
                        <Translate value="regularSetting.FIRST_DISTANCE_FEE" />
                      </FormGroupTitle>
                      <FormGroup className={'direction-row from-to'}>
                        <Form.Label className={'from-to-label'}>
                          <Translate value="regularSetting.First_distance_fee" />
                        </Form.Label>
                        <FormGroup className="direction-row">
                          <FormGroup
                            className={`direction-row from-to qup-input-group ${
                              !this.state.isSubmited
                                ? null
                                : this.state.valid.firstDistanceFrom === false
                                ? 'error'
                                : null
                            }`}
                          >
                            <Form.Label>
                              <Translate value="regularSetting.From" />
                            </Form.Label>
                            <InputGroup
                              className={
                                'single-addon-right' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleInputChange(
                                    'firstDistanceFrom',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleInputChange(
                                      'firstDistanceFrom',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleInputChange(
                                      'firstDistanceFrom',
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.firstDistanceFrom
                                    : ''
                                }
                                placeholder={I18n.t('regularSetting.From')}
                                disabled={!this.state.editable}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  {this.props.auth.selectedFleet.unitDistance}
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <Validator
                              id="firstDistanceFrom"
                              callback={this.ValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  this.state.dialogData.firstDistanceFrom ==
                                    '' ||
                                  !isNaN(
                                    this.state.dialogData.firstDistanceFrom
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.isSubmited}
                              />
                            </Validator>
                          </FormGroup>
                          <FormGroup
                            className={`direction-row from-to qup-input-group ${
                              !this.state.isSubmited
                                ? null
                                : this.state.valid.firstDistanceTo === false
                                ? 'error'
                                : null
                            }`}
                          >
                            <Form.Label>
                              <Translate value="regularSetting.To" />{' '}
                              <span className="require">*</span>
                            </Form.Label>
                            <InputGroup
                              className={
                                'single-addon-right' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleInputChange('firstDistanceTo', e);
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleInputChange(
                                      'firstDistanceTo',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleInputChange(
                                      'firstDistanceTo',
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.firstDistanceTo
                                    : ''
                                }
                                placeholder={I18n.t('regularSetting.To')}
                                disabled={!this.state.editable}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  {this.props.auth.selectedFleet.unitDistance}
                                </InputGroup.Text>
                              </InputGroup.Append>{' '}
                            </InputGroup>
                            <Validator
                              id="firstDistanceTo"
                              callback={this.ValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  !Validation.isStringEmpty(
                                    this.state.dialogData.firstDistanceTo
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.Required_field'
                                )}
                                hide={!this.state.isSubmited}
                              />
                              <ValidCase
                                valid={
                                  this.state.dialogData.firstDistanceTo == '' ||
                                  !isNaN(this.state.dialogData.firstDistanceTo)
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.isSubmited}
                              />
                              <ValidCase
                                valid={
                                  parseFloat(
                                    this.state.dialogData.firstDistanceTo
                                  ) >=
                                  parseFloat(
                                    this.state.dialogData.firstDistanceFrom
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.distance_from_equa_or_smaller'
                                )}
                                hide={!this.state.isSubmited}
                              />
                            </Validator>
                          </FormGroup>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.feeFirstDistance === false
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Fee_for_first_distance" />
                        </Form.Label>
                        {this.props.auth.selectedFleet.multiCurrencies ? (
                          <div className="input-with-validator">
                            <Tabs
                              id="currencies-tabs-Fee_for_first_distance"
                              className="currencies-tabs"
                              defaultActiveKey={
                                this.props.auth.selectedFleet.currencies[0].iso
                              }
                              animation={false}
                              onSelect={this.tabSelectHandle}
                            >
                              {this.props.auth.selectedFleet.currencies.map(
                                (c) => {
                                  let currentValue =
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0];
                                  return (
                                    <Tab
                                      className="currencies-tab-item"
                                      eventKey={c.iso}
                                      title={c.iso}
                                    >
                                      <FormGroup className="qup-input-group">
                                        <InputGroup
                                          className={
                                            !this.state.editable
                                              ? ' disabled'
                                              : ''
                                          }
                                        >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              {c.symbol}
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            onChange={(e) => {
                                              this.handleCancelInputMultiCurrenciesChange(
                                                'feeFirstDistance',
                                                c.iso,
                                                e
                                              );
                                            }}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeFirstDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeFirstDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            value={
                                              currentValue
                                                ? currentValue.feeFirstDistance
                                                : DefaultCurenciesNumberInputField
                                            }
                                            disabled={!this.state.editable}
                                          />
                                          <InputGroup.Append>
                                            <InputGroup.Text>
                                              /
                                              {
                                                this.props.auth.selectedFleet
                                                  .unitDistance
                                              }
                                            </InputGroup.Text>
                                          </InputGroup.Append>{' '}
                                        </InputGroup>
                                      </FormGroup>
                                    </Tab>
                                  );
                                }
                              )}
                            </Tabs>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                !this.state.dialogData.feesByCurrencies ||
                                !this.state.dialogData.feesByCurrencies.length
                                  ? null
                                  : this.state.dialogData.feesByCurrencies.map(
                                      (fees) => {
                                        const {
                                          currencyISO,
                                          feeFirstDistance,
                                        } = fees;
                                        return {
                                          [currencyISO]: feeFirstDistance,
                                        };
                                      }
                                    )
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        ) : (
                          <div className="input-with-validator">
                            <FormGroup className="qup-input-group">
                              <InputGroup
                                className={
                                  !this.state.editable ? ' disabled' : ''
                                }
                              >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet
                                        .currencies[0].symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'feeFirstDistance',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'feeFirstDistance',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'feeFirstDistance',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }
                                    );
                                  }}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ) &&
                                        this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.feesByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].feeFirstDistance
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  placeholder={I18n.t(
                                    'regularSetting.Fee_for_first_distance'
                                  )}
                                  disabled={!this.state.editable}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    /
                                    {this.props.auth.selectedFleet.unitDistance}
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ) &&
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].feeFirstDistance
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        )}
                        <Validator
                          id="feeFirstDistance"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(
                                      d.feeFirstDistance
                                    ) && isNaN(d.feeFirstDistance)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.feeFirstDistance,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      </FormGroup>
                    </>
                  )}
                  <div className={'vs-20'} />
                </Col>
                <Col md={4} sm={12}>
                  {this.state.dialogData.mode !== 'timeOrMileage' && (
                    <>
                      <FormGroupTitle>
                        <Translate value="regularSetting.SECOND_DISTANCE_FEE" />
                      </FormGroupTitle>
                      <FormGroup className={'direction-row from-to'}>
                        <Form.Label className={'from-to-label'}>
                          <Translate value="regularSetting.Second_distance_fee" />
                        </Form.Label>
                        <FormGroup className=" direction-row">
                          <FormGroup
                            className={`direction-row from-to qup-input-group ${
                              !this.state.isSubmited
                                ? null
                                : this.state.valid.secondDistanceFrom === false
                                ? 'error'
                                : null
                            }`}
                          >
                            <Form.Label>
                              <Translate value="regularSetting.From" />
                            </Form.Label>
                            <InputGroup
                              className={'single-addon-right disabled'}
                            >
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleInputChange(
                                    'secondDistanceFrom',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleInputChange(
                                      'secondDistanceFrom',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleInputChange(
                                      'secondDistanceFrom',
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.secondDistanceFrom
                                    : ''
                                }
                                placeholder={I18n.t('regularSetting.From')}
                                disabled={true}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  {this.props.auth.selectedFleet.unitDistance}
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <Validator
                              id="secondDistanceFrom"
                              callback={this.ValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  this.state.dialogData.secondDistanceFrom ==
                                    '' ||
                                  !isNaN(
                                    this.state.dialogData.secondDistanceFrom
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.isSubmited}
                              />
                            </Validator>
                          </FormGroup>
                          <FormGroup
                            className={`direction-row from-to qup-input-group ${
                              !this.state.isSubmited
                                ? null
                                : this.state.valid.secondDistanceTo === false
                                ? 'error'
                                : null
                            }`}
                          >
                            <Form.Label>
                              <Translate value="regularSetting.To" />{' '}
                              <span className="require">*</span>
                            </Form.Label>
                            <InputGroup
                              className={
                                'single-addon-right' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleInputChange('secondDistanceTo', e);
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleInputChange(
                                      'secondDistanceTo',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleInputChange(
                                      'secondDistanceTo',
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.secondDistanceTo
                                    : ''
                                }
                                placeholder={I18n.t('regularSetting.To')}
                                disabled={!this.state.editable}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  {this.props.auth.selectedFleet.unitDistance}
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                            <Validator
                              id="secondDistanceTo"
                              callback={this.ValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  !Validation.isStringEmpty(
                                    this.state.dialogData.secondDistanceTo
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.Required_field'
                                )}
                                hide={!this.state.isSubmited}
                              />
                              <ValidCase
                                valid={
                                  this.state.dialogData.secondDistanceTo ==
                                    '' ||
                                  !isNaN(this.state.dialogData.secondDistanceTo)
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.isSubmited}
                              />
                              <ValidCase
                                valid={
                                  parseFloat(
                                    this.state.dialogData.secondDistanceTo
                                  ) >=
                                  parseFloat(
                                    this.state.dialogData.secondDistanceFrom
                                  )
                                }
                                message={I18n.t(
                                  'messages.commonMessages.distance_from_equa_or_smaller'
                                )}
                                hide={!this.state.isSubmited}
                              />
                            </Validator>
                          </FormGroup>
                        </FormGroup>
                      </FormGroup>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.feeSecondDistance === false
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Second_distance_fee" />
                        </Form.Label>
                        {this.props.auth.selectedFleet.multiCurrencies ? (
                          <div className="input-with-validator">
                            <Tabs
                              id="currencies-tabs-Fee_after_second_distance"
                              className="currencies-tabs"
                              defaultActiveKey={
                                this.props.auth.selectedFleet.currencies[0].iso
                              }
                              animation={false}
                              onSelect={this.tabSelectHandle}
                            >
                              {this.props.auth.selectedFleet.currencies.map(
                                (c) => {
                                  let currentValue =
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0];
                                  return (
                                    <Tab
                                      className="currencies-tab-item"
                                      eventKey={c.iso}
                                      title={c.iso}
                                    >
                                      <FormGroup className="qup-input-group">
                                        <InputGroup
                                          className={
                                            !this.state.editable
                                              ? ' disabled'
                                              : ''
                                          }
                                        >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              {c.symbol}
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            onChange={(e) => {
                                              this.handleCancelInputMultiCurrenciesChange(
                                                'feeSecondDistance',
                                                c.iso,
                                                e
                                              );
                                            }}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeSecondDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeSecondDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            value={
                                              currentValue
                                                ? currentValue.feeSecondDistance
                                                : DefaultCurenciesNumberInputField
                                            }
                                            disabled={!this.state.editable}
                                          />
                                          <InputGroup.Append>
                                            <InputGroup.Text>
                                              /
                                              {
                                                this.props.auth.selectedFleet
                                                  .unitDistance
                                              }
                                            </InputGroup.Text>
                                          </InputGroup.Append>{' '}
                                        </InputGroup>
                                      </FormGroup>
                                    </Tab>
                                  );
                                }
                              )}
                            </Tabs>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                !this.state.dialogData.feesByCurrencies ||
                                !this.state.dialogData.feesByCurrencies.length
                                  ? null
                                  : this.state.dialogData.feesByCurrencies.map(
                                      (fees) => {
                                        const {
                                          currencyISO,
                                          feeSecondDistance,
                                        } = fees;
                                        return {
                                          [currencyISO]: feeSecondDistance,
                                        };
                                      }
                                    )
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        ) : (
                          <div className="input-with-validator">
                            <FormGroup className="qup-input-group">
                              <InputGroup
                                className={
                                  !this.state.editable ? ' disabled' : ''
                                }
                              >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet
                                        .currencies[0].symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'feeSecondDistance',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'feeSecondDistance',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'feeSecondDistance',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }
                                    );
                                  }}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ) &&
                                        this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.feesByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].feeSecondDistance
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  placeholder={I18n.t(
                                    'regularSetting.Second_distance_fee'
                                  )}
                                  disabled={!this.state.editable}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    /
                                    {this.props.auth.selectedFleet.unitDistance}
                                  </InputGroup.Text>
                                </InputGroup.Append>{' '}
                              </InputGroup>
                            </FormGroup>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ) &&
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].feeSecondDistance
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        )}
                        <Validator
                          id="feeSecondDistance"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(
                                      d.feeSecondDistance
                                    ) && isNaN(d.feeSecondDistance)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.feeSecondDistance,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      </FormGroup>
                      <div className={'vs-20'} />
                      <FormGroupTitle>
                        <Translate value="regularSetting.AFTER_SECOND_DISTANCE" />
                      </FormGroupTitle>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.feeAfterSecondDistance === false
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Fee_after_second_distance" />
                        </Form.Label>
                        {this.props.auth.selectedFleet.multiCurrencies ? (
                          <div className="input-with-validator">
                            <Tabs
                              id="currencies-tabs-Fee_after_second_distance"
                              className="currencies-tabs"
                              defaultActiveKey={
                                this.props.auth.selectedFleet.currencies[0].iso
                              }
                              animation={false}
                              onSelect={this.tabSelectHandle}
                            >
                              {this.props.auth.selectedFleet.currencies.map(
                                (c) => {
                                  let currentValue =
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0];
                                  return (
                                    <Tab
                                      className="currencies-tab-item"
                                      eventKey={c.iso}
                                      title={c.iso}
                                    >
                                      <FormGroup className="qup-input-group">
                                        <InputGroup
                                          className={
                                            !this.state.editable
                                              ? ' disabled'
                                              : ''
                                          }
                                        >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              {c.symbol}
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            onChange={(e) => {
                                              this.handleCancelInputMultiCurrenciesChange(
                                                'feeAfterSecondDistance',
                                                c.iso,
                                                e
                                              );
                                            }}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeAfterSecondDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feeAfterSecondDistance',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            value={
                                              currentValue
                                                ? currentValue.feeAfterSecondDistance
                                                : DefaultCurenciesNumberInputField
                                            }
                                            disabled={!this.state.editable}
                                          />
                                          <InputGroup.Append>
                                            <InputGroup.Text>
                                              /
                                              {
                                                this.props.auth.selectedFleet
                                                  .unitDistance
                                              }
                                            </InputGroup.Text>
                                          </InputGroup.Append>
                                        </InputGroup>
                                      </FormGroup>
                                    </Tab>
                                  );
                                }
                              )}
                            </Tabs>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                !this.state.dialogData.feesByCurrencies ||
                                !this.state.dialogData.feesByCurrencies.length
                                  ? null
                                  : this.state.dialogData.feesByCurrencies.map(
                                      (fees) => {
                                        const {
                                          currencyISO,
                                          feeAfterSecondDistance,
                                        } = fees;
                                        return {
                                          [currencyISO]: feeAfterSecondDistance,
                                        };
                                      }
                                    )
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        ) : (
                          <div className="input-with-validator">
                            <FormGroup className="qup-input-group">
                              <InputGroup
                                className={
                                  !this.state.editable ? ' disabled' : ''
                                }
                              >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet
                                        .currencies[0].symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'feeAfterSecondDistance',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'feeAfterSecondDistance',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'feeAfterSecondDistance',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }
                                    );
                                  }}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ) &&
                                        this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.feesByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].feeAfterSecondDistance
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  placeholder={I18n.t(
                                    'regularSetting.Fee_after_second_distance'
                                  )}
                                  disabled={!this.state.editable}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    /
                                    {this.props.auth.selectedFleet.unitDistance}
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ) &&
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].feeAfterSecondDistance
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        )}
                        <Validator
                          id="feeAfterSecondDistance"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(
                                      d.feeAfterSecondDistance
                                    ) && isNaN(d.feeAfterSecondDistance)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.feeAfterSecondDistance,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      </FormGroup>
                      <div className={'vs-20'} />
                      <FormGroupTitle>
                        <Translate value="regularSetting.PER_MINUTE" />
                      </FormGroupTitle>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : this.state.valid.feePerMinute === false
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Fee_per_minute" />
                        </Form.Label>
                        {this.props.auth.selectedFleet.multiCurrencies ? (
                          <div className="input-with-validator">
                            <Tabs
                              id="currencies-tabs-Fee_per_minute"
                              className="currencies-tabs"
                              defaultActiveKey={
                                this.props.auth.selectedFleet.currencies[0].iso
                              }
                              animation={false}
                              onSelect={this.tabSelectHandle}
                            >
                              {this.props.auth.selectedFleet.currencies.map(
                                (c) => {
                                  let currentValue =
                                    this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0];
                                  return (
                                    <Tab
                                      className="currencies-tab-item"
                                      eventKey={c.iso}
                                      title={c.iso}
                                    >
                                      <FormGroup className="qup-input-group">
                                        <InputGroup
                                          className={
                                            !this.state.editable
                                              ? ' disabled'
                                              : ''
                                          }
                                        >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              {c.symbol}
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            onChange={(e) => {
                                              this.handleCancelInputMultiCurrenciesChange(
                                                'feePerMinute',
                                                c.iso,
                                                e
                                              );
                                            }}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feePerMinute',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'feePerMinute',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            value={
                                              currentValue
                                                ? currentValue.feePerMinute
                                                : DefaultCurenciesNumberInputField
                                            }
                                            disabled={!this.state.editable}
                                          />
                                          <InputGroup.Append>
                                            <InputGroup.Text>
                                              /
                                              <Translate value="regularSetting.minute" />
                                            </InputGroup.Text>
                                          </InputGroup.Append>{' '}
                                        </InputGroup>
                                      </FormGroup>
                                    </Tab>
                                  );
                                }
                              )}
                            </Tabs>
                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                !this.state.dialogData.feesByCurrencies ||
                                !this.state.dialogData.feesByCurrencies.length
                                  ? null
                                  : this.state.dialogData.feesByCurrencies.map(
                                      (fees) => {
                                        const { currencyISO, feePerMinute } =
                                          fees;
                                        return {
                                          [currencyISO]: feePerMinute,
                                        };
                                      }
                                    )
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        ) : (
                          <div className="input-with-validator">
                            <FormGroup className="qup-input-group">
                              <InputGroup
                                className={
                                  !this.state.editable ? ' disabled' : ''
                                }
                              >
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet
                                        .currencies[0].symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'feePerMinute',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'feePerMinute',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'feePerMinute',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }
                                    );
                                  }}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.feesByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.feesByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].feePerMinute
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  placeholder={I18n.t(
                                    'regularSetting.Fee_per_minute'
                                  )}
                                  disabled={!this.state.editable}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    /<Translate value="regularSetting.minute" />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>

                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!this.state.editable}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].feePerMinute
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        )}
                        <Validator
                          id="feePerMinute"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.feePerMinute) &&
                                    isNaN(d.feePerMinute)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.feePerMinute,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      </FormGroup>
                    </>
                  )}

                  <ExtraWaitTime
                    handleInputChange={this.handleInputChange}
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    dialogData={this.state.dialogData}
                    auth={this.props.auth}
                    editable={this.state.editable}
                    ValidatorCallback={this.ValidatorCallback}
                    disabled={!this.state.editable}
                  />

                  <FormGroupTitle>
                    <Translate value="regularSetting.MINIMUM" />
                  </FormGroupTitle>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.minNow === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Minimum_Now" />
                    </Form.Label>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Minimum_Now"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue =
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0];
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'minNow',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'minNow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'minNow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.minNow
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.feesByCurrencies ||
                            !this.state.dialogData.feesByCurrencies.length
                              ? null
                              : this.state.dialogData.feesByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, minNow } = fees;
                                    return {
                                      [currencyISO]: minNow,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'minNow',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'minNow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'minNow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].minNow
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              placeholder={I18n.t('regularSetting.Minimum_Now')}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length
                                ? this.state.dialogData.feesByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].minNow
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator id="minNow" callback={this.ValidatorCallback}>
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(d.minNow) &&
                              isNaN(d.minNow)
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return !Validation.isGreaterOrEqual(d.minNow, 0);
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>

                  <div className={'vs-20'} />
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.minReservation === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Minimum_Reservation" />
                    </Form.Label>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Minimum_Reservation"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue =
                              this.state.dialogData.feesByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0];
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'minReservation',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'minReservation',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'minReservation',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.minReservation
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.feesByCurrencies ||
                            !this.state.dialogData.feesByCurrencies.length
                              ? null
                              : this.state.dialogData.feesByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, minReservation } =
                                      fees;
                                    return {
                                      [currencyISO]: minReservation,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {
                                  this.props.auth.selectedFleet.currencies[0]
                                    .symbol
                                }
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'minReservation',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'minReservation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'minReservation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.feesByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.feesByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].minReservation
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              placeholder={I18n.t(
                                'regularSetting.Minimum_Reservation'
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.feesByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.feesByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].minReservation
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator
                      id="minReservation"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return (
                              !Validation.isStringEmpty(d.minReservation) &&
                              isNaN(d.minReservation)
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.feesByCurrencies.filter((d) => {
                            return !Validation.isGreaterOrEqual(
                              d.minReservation,
                              0
                            );
                          }).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                </Col>

                <Col md={4} sm={12}>
                  {priceAdjustable && (
                    <PriceAdjustment
                      editable={this.state.editable}
                      dialogData={this.state.dialogData}
                      selectedFleet={this.props.auth.selectedFleet}
                      isSubmited={this.state.isSubmited}
                      valid={this.state.valid}
                      ValidatorCallback={this.ValidatorCallback}
                      handlePriceAdjustmentChange={
                        this.handlePriceAdjustmentChange
                      }
                    />
                  )}

                  <div className={'vs-20'} />
                  {/* <FormGroupTitle>
                    <Translate value="regularSetting.PAX_MDISPATCHER_CANCELLATION" />
                    <QuestionCircleTooltip
                      text={
                        <Translate value="regularSetting.Pax_mDispatcher_cancellation_tooltip" />
                      }
                    />
                  </FormGroupTitle> */}
                  <CcCheckbox
                    disabled={!this.state.editable}
                    checked={
                      this.state.dialogData.cancellationPolicy.onDemand
                        ? this.state.dialogData.cancellationPolicy.onDemand
                            .isActive
                        : false
                    }
                    onChange={(e) => {
                      this.handleCancelInputChange('onDemand_isActive', e);
                    }}
                    text={I18n.t('regularSetting.On_demand')}
                    labelClassName="title"
                    className="form-group-title price_adjustment"
                  />
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.onDemand === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={
                          this.state.dialogData.cancellationPolicy.onDemand.type
                        }
                        onChange={(e) => {
                          this.handleInputChange(
                            'onDemand_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tabs-Ondemand"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue = this.state.dialogData
                              .cancellationPolicy.onDemand
                              ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                  (d) => {
                                    return d.currencyISO == c.iso;
                                  }
                                )[0]
                              : this;
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData
                                          .cancellationPolicy.onDemand.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'onDemand',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemand',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemand',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.cancellationPolicy
                              .onDemand ||
                            !this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies ||
                            !this.state.dialogData.cancellationPolicy.onDemand
                              .valueByCurrencies.length
                              ? null
                              : this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.cancellationPolicy
                                  .onDemand.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'onDemand',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemand',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemand',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                  : DefaultCurenciesNumberInputField
                              }
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : DefaultCurenciesNumberInputField
                              : DefaultCurenciesNumberInputField
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    {this.state.dialogData.cancellationPolicy.onDemand ? (
                      <Validator
                        id="onDemand"
                        callback={this.ValidatorCallback}
                      >
                        <ValidCase
                          valid={
                            this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                              (d) => {
                                return (
                                  !Validation.isStringEmpty(d.value) &&
                                  isNaN(d.value)
                                );
                              }
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.isSubmited}
                        />
                        <ValidCase
                          valid={
                            this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                              (d) => {
                                return !Validation.isGreaterOrEqual(d.value, 0);
                              }
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.greater_or_equa'
                          ).format(0)}
                          hide={!this.state.isSubmited}
                        />
                      </Validator>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.cancellationPolicy.onDemand &&
                          this.state.dialogData.cancellationPolicy.onDemand
                            .enableTax
                            ? this.state.dialogData.cancellationPolicy.onDemand
                                .enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('onDemand_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.cancellationPolicy.onDemand
                              ? this.state.dialogData.cancellationPolicy
                                  .onDemand.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange('onDemand_techFee', e);
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={
                        this.state.dialogData.cancellationPolicy.onDemand
                          .payToDrv
                      }
                      onChange={(e) => {
                        this.handleInputChange('onDemand_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvOnDemand == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.onDemandPayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-onDemandPayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData
                                  .cancellationPolicy.onDemand
                                  ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemandPayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'onDemandPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'onDemandPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.cancellationPolicy
                                .onDemand ||
                              !this.state.dialogData.cancellationPolicy.onDemand
                                .drvGetAmtByCurrencies ||
                              !this.state.dialogData.cancellationPolicy.onDemand
                                .drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'onDemandPayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'onDemandPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'onDemandPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.cancellationPolicy.onDemand ? (
                        <Validator
                          id="onDemandPayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('onDemand', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange(
                                    'onDemand',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'onDemand',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'onDemand',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `cancellationPolicy.onDemand.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `cancellationPolicy.onDemand.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <CcCheckbox
                    disabled={!this.state.editable}
                    checked={
                      this.state.dialogData.cancellationPolicy.inAdvance
                        ? this.state.dialogData.cancellationPolicy.inAdvance
                            .isActive
                        : false
                    }
                    onChange={(e) => {
                      this.handleCancelInputChange('inAdvance_isActive', e);
                    }}
                    text={I18n.t('regularSetting.In_advance')}
                    labelClassName="title"
                    className="form-group-title price_adjustment"
                  />
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.inAdvance === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={
                          this.state.dialogData.cancellationPolicy.inAdvance
                            .type
                        }
                        onChange={(e) => {
                          this.handleInputChange(
                            'inAdvance_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tab-Inadvance"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            {
                              /* console.log({ pen: this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies }) */
                            }
                            let currentValue = this.state.dialogData
                              .cancellationPolicy.inAdvance
                              ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                  (d) => {
                                    return d.currencyISO == c.iso;
                                  }
                                )[0]
                              : {};
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData
                                          .cancellationPolicy.inAdvance.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'inAdvance',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvance',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvance',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.cancellationPolicy
                              .inAdvance ||
                            !this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies ||
                            !this.state.dialogData.cancellationPolicy.inAdvance
                              .valueByCurrencies.length
                              ? null
                              : this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.cancellationPolicy
                                  .inAdvance.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'inAdvance',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvance',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvance',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : 0
                                  : 0
                              }
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : 0
                              : 0
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator id="inAdvance" callback={this.ValidatorCallback}>
                      <ValidCase
                        valid={
                          !this.state.dialogData.cancellationPolicy.inAdvance
                            ? false
                            : this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          !this.state.dialogData.cancellationPolicy.inAdvance
                            ? false
                            : this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.cancellationPolicy.inAdvance &&
                          this.state.dialogData.cancellationPolicy.inAdvance
                            .enableTax
                            ? this.state.dialogData.cancellationPolicy.inAdvance
                                .enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('inAdvance_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.cancellationPolicy.inAdvance
                              ? this.state.dialogData.cancellationPolicy
                                  .inAdvance.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange(
                              'inAdvance_techFee',
                              e
                            );
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={
                        this.state.dialogData.cancellationPolicy.inAdvance
                          .payToDrv
                      }
                      onChange={(e) => {
                        this.handleInputChange('inAdvance_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvInAdvance == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.inAdvancePayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-inAdvancePayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData
                                  .cancellationPolicy.inAdvance
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvancePayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'inAdvancePayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'inAdvancePayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance ||
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance.drvGetAmtByCurrencies ||
                              !this.state.dialogData.cancellationPolicy
                                .inAdvance.drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'inAdvancePayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'inAdvancePayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'inAdvancePayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.cancellationPolicy.inAdvance ? (
                        <Validator
                          id="inAdvancePayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashInAdvanceType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('inAdvance', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashInAdvanceType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange(
                                    'inAdvance',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'inAdvance',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'inAdvance',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `cancellationPolicy.inAdvance.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `cancellationPolicy.inAdvance.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={'vs-20'} />
                  <FormGroupTitle>
                    <Translate value="regularSetting.PAX_MDISPATCHER_NO_SHOW" />
                    <QuestionCircleTooltip
                      text={
                        <Translate value="regularSetting.Pax_mDispatcher_no_show_tooltip" />
                      }
                    />
                  </FormGroupTitle>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.noShow === false
                        ? 'error'
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Penalty" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Penalty_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormGroup>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={this.state.dialogData.noShow.type}
                        onChange={(e) => {
                          this.handleInputChange(
                            'noShow_Pay_to_driver_type',
                            e
                          );
                        }}
                        disabled={!this.state.editable}
                      >
                        <option value="amount" key="amount">
                          {I18n.t('regularSetting.Amount')}
                        </option>
                        <option value="percent" key="percent">
                          {I18n.t('regularSetting.Percent')}
                        </option>
                      </FormControl>
                    </FormGroup>
                    {this.props.auth.selectedFleet.multiCurrencies ? (
                      <div className="input-with-validator">
                        <Tabs
                          id="currencies-tab-Inadvance"
                          className="currencies-tabs"
                          defaultActiveKey={
                            this.props.auth.selectedFleet.currencies[0].iso
                          }
                          animation={false}
                          onSelect={this.tabSelectHandle}
                        >
                          {this.props.auth.selectedFleet.currencies.map((c) => {
                            let currentValue =
                              this.state.dialogData.noShow.valueByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0];
                            return (
                              <Tab
                                className="currencies-tab-item"
                                eventKey={c.iso}
                                title={c.iso}
                              >
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {this.state.dialogData.noShow.type ===
                                        'percent'
                                          ? '%'
                                          : c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'noShow',
                                          c.iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShow',
                                              c.iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        currentValue
                                          ? currentValue.value
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Tab>
                            );
                          })}
                        </Tabs>

                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            !this.state.dialogData.noShow ||
                            !this.state.dialogData.noShow.valueByCurrencies ||
                            !this.state.dialogData.noShow.valueByCurrencies
                              .length
                              ? null
                              : this.state.dialogData.noShow.valueByCurrencies.map(
                                  (fees) => {
                                    const { currencyISO, value } = fees;
                                    return {
                                      [currencyISO]: value,
                                    };
                                  }
                                )
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    ) : (
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {this.state.dialogData.noShow.type === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleCancelInputMultiCurrenciesChange(
                                  'noShow',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShow',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                });
                              }}
                              value={
                                this.state.dialogData
                                  ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    ).length > 0
                                    ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : 0
                                  : 0
                              }
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                        <WarningFeeInput
                          message={I18n.t('message.warningInputChangeOver')}
                          className="text-soft-warning"
                          disabled={!this.state.editable}
                          value={
                            this.state.dialogData
                              ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                ).length > 0
                                ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0].value
                                : 0
                              : 0
                          }
                          timeDelay={500}
                          typeOfCheck="higherOfLower"
                        />
                      </div>
                    )}
                    <Validator id="noShow" callback={this.ValidatorCallback}>
                      <ValidCase
                        valid={
                          this.state.dialogData.noShow.valueByCurrencies.filter(
                            (d) => {
                              return (
                                !Validation.isStringEmpty(d.value) &&
                                isNaN(d.value)
                              );
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.must_be_number'
                        )}
                        hide={!this.state.isSubmited}
                      />
                      <ValidCase
                        valid={
                          this.state.dialogData.noShow.valueByCurrencies.filter(
                            (d) => {
                              return !Validation.isGreaterOrEqual(d.value, 0);
                            }
                          ).length == 0
                        }
                        message={I18n.t(
                          'messages.commonMessages.greater_or_equa'
                        ).format(0)}
                        hide={!this.state.isSubmited}
                      />
                    </Validator>
                  </FormGroup>
                  <Row>
                    <Col md={6} sm={12}>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={
                          this.state.dialogData.noShow &&
                          this.state.dialogData.noShow.enableTax
                            ? this.state.dialogData.noShow.enableTax
                            : false
                        }
                        onChange={(e) => {
                          this.handleCancelInputChange('noShow_tax', e);
                        }}
                        text={I18n.t('regularSetting.Tax')}
                      />
                    </Col>
                    {this.props.auth.selectedFleet.techFeeActive ? (
                      <Col md={6} sm={12}>
                        <CcCheckbox
                          disabled={!this.state.editable}
                          checked={
                            this.state.dialogData.noShow
                              ? this.state.dialogData.noShow.enableTechFee
                              : true
                          }
                          onChange={(e) => {
                            this.handleCancelInputChange('noShow_techFee', e);
                          }}
                          text={I18n.t('regularSetting.TechFee')}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="regularSetting.Pay_to_driver" />
                      <QuestionCircleTooltip
                        text={
                          <Translate value="regularSetting.Pay_to_driver_tooltip" />
                        }
                      />
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={this.state.dialogData.noShow.payToDrv}
                      onChange={(e) => {
                        this.handleInputChange('noShow_Pay_to_driver', e);
                      }}
                      disabled={!this.state.editable}
                    >
                      <option value="commission" key="commission">
                        {I18n.t('regularSetting.Commission')}
                      </option>
                      <option value="fixAmount" key="fixAmount">
                        {I18n.t('regularSetting.Fix_amount')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {payToDrvNoShow == 'fixAmount' ? (
                    <FormGroup
                      className={
                        !this.state.isSubmited
                          ? null
                          : this.state.valid.noShowPayDrvAmt === false
                          ? 'error'
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-noShowPayDrvAmt"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.dialogData.noShow
                                  ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : this;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={
                                          'single-addon-left' +
                                          (!this.state.editable
                                            ? ' disabled'
                                            : '')
                                        }
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShowPayDrvAmt',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'noShowPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'noShowPayDrvAmt',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!this.state.editable}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              !this.state.dialogData.noShow ||
                              !this.state.dialogData.noShow
                                .drvGetAmtByCurrencies ||
                              !this.state.dialogData.noShow
                                .drvGetAmtByCurrencies.length
                                ? null
                                : this.state.dialogData.noShow.drvGetAmtByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleCancelInputMultiCurrenciesChange(
                                    'noShowPayDrvAmt',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'noShowPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleCancelInputMultiCurrenciesChange(
                                      'noShowPayDrvAmt',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.dialogData
                                    ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      ).length > 0
                                      ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        )[0].value
                                      : DefaultCurenciesNumberInputField
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={
                              this.state.dialogData
                                ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  ).length > 0
                                  ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          d.currencyISO ==
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso
                                        );
                                      }
                                    )[0].value
                                  : DefaultCurenciesNumberInputField
                                : DefaultCurenciesNumberInputField
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.dialogData.noShow ? (
                        <Validator
                          id="inAdvancePayDrvAmt"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    !Validation.isStringEmpty(d.value) &&
                                    isNaN(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return !Validation.isGreaterOrEqual(
                                    d.value,
                                    0
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'messages.commonMessages.greater_or_equa'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                          <ValidCase
                            valid={
                              this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                (d) => {
                                  return (
                                    parseFloat(d.drvGet) > parseFloat(d.value)
                                  );
                                }
                              ).length == 0
                            }
                            message={I18n.t(
                              'regularSetting.Driver_gets_greater_than_penalty_amount'
                            ).format(0)}
                            hide={!this.state.isSubmited}
                          />
                        </Validator>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  ) : null}
                  {cancellationCashBooking && (
                    <>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Pay_to_driver_Cash" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pay_to_driver_tooltip" />
                            }
                          />
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={payDrvCashNoShowType}
                          onChange={(e) => {
                            this.handlePayDrvCashTypeChange('noShow', e);
                          }}
                          disabled={!this.state.editable}
                        >
                          <option value="commission" key="commission">
                            {I18n.t('regularSetting.Commission')}
                          </option>
                          <option value="fixAmount" key="fixAmount">
                            {I18n.t('regularSetting.Fix_amount')}
                          </option>
                        </FormControl>
                      </FormGroup>
                      {payDrvCashNoShowType === 'fixAmount' && (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup
                              className={
                                'single-addon-left' +
                                (!this.state.editable ? ' disabled' : '')
                              }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handlePayDrvCashValueChange('noShow', e);
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'noShow',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handlePayDrvCashValueChange(
                                      'noShow',
                                      e
                                    );
                                  });
                                }}
                                value={_.get(
                                  dialogData,
                                  `noShow.payDrvCash.value`,
                                  0
                                )}
                                disabled={!this.state.editable}
                              />
                            </InputGroup>
                          </FormGroup>
                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!this.state.editable}
                            value={_.get(
                              dialogData,
                              `noShow.payDrvCash.value`,
                              0
                            )}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={'vs-20'} />
                  {enablePenalty && (
                    <>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.onDemand.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'onDemand',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_demand')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.onDemand.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'onDemand',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {_.get(
                                  dialogData,
                                  'driverCancelPolicy.onDemand.type',
                                  'amount'
                                ) === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'onDemand',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.onDemand.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className={'vs-20'} />
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.inAdvance.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'inAdvance',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_advance')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.inAdvance.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'inAdvance',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {_.get(
                                  dialogData,
                                  'driverCancelPolicy.inAdvance.type',
                                  'amount'
                                ) === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'inAdvance',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.inAdvance.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </>
                  )}
                  {this.state.dialogData._id ? null : (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="regularSetting.Assign_car_type" />
                      </Form.Label>
                      <FormControl
                        as="select"
                        className={'form-custom'}
                        value={this.state.dialogData.vehicleTypeId}
                        onChange={(e) => {
                          this.handleInputChange('vehicleTypeId', e);
                        }}
                      >
                        <option>
                          {I18n.t('regularSetting.Select_car_type')}
                        </option>
                        {this.props.commonData.carTypeCity.map((item) => {
                          return (
                            <option value={item._id} key={item._id}>
                              {item.vehicleType}
                            </option>
                          );
                        })}
                      </FormControl>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Row className="ml0">
                {this.state.dialogData._id ? (
                  <Form.Label className="mr-r-20">
                    (<span className="require">*</span>)
                    <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
                  </Form.Label>
                ) : null}
              </Row>
              <Row className={'ml0'}>
                <Form.Label>
                  (<span className="require">*</span>
                  ): <Translate value="regularSetting.Required_fields" />
                </Form.Label>
              </Row>
            </Modal.Body>
            <Modal.Footer className={'pd-r-l-60'}>
              {!this.props.permissions || this.props.permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={this.saveDialogForm}
                    disabled={!this.state.dialogChanged}
                  >
                    <Translate value="regularSetting.Save" />
                  </Button>
                ) : (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={(e) => {
                      this.handleMenuClick('changeStatus', e);
                    }}
                  >
                    <Translate value="regularSetting.Edit" />
                  </Button>
                )
              ) : (
                ''
              )}
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {hasPermission && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="regularSetting.Add" />
              </Button>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

RegularSetting.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RegularSetting);
