import React, { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

class ExternalID extends Component {
  render() {
    const { value, handleChangeBookingReference, is3rdBooking } = this.props;
    return (
      <FormGroup>
        <FormControl
          type="text"
          onChange={handleChangeBookingReference}
          value={value}
          maxLength={150}
          className={'form-custom'}
          disabled={is3rdBooking}
          placeholder={I18n.t('newbooking.ExternalID')}
        />
      </FormGroup>
    );
  }
}

export default ExternalID;
