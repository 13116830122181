import React from 'react';
import { Translate, I18n } from "react-redux-i18n";
import {
    FormGroup,
    FormControl,
    InputGroup,
    Button,
    Form,
    Tabs,
    Tab
} from "react-bootstrap";
import _ from 'lodash';

import UploadFile from '../../../components/upload/upload';
import UploadImageTemplate from "../../../components/upload/UploadImageTemplate";
import { Validation } from "../../../utils/commonFunctions";
import { Validator, ValidCase } from '../../../components/validator';
import copy from 'copy-to-clipboard';

export default function LinkContentInbox(props) {
    const {
        languageOption,
        messages,
        url,
        subject,
        inboxContentId,
        body,
        image,
        editable = true,
        handleChangeLinkForm,
        handleImageChange,
        handleChangeImageByLanguage,
        handleChangeInput,
        handleChangeLanguageOption,
        valid,
        isSubmited,
        ValidatorCallback,
        multiURLValidation,
        multiSubjectValidation,
        multiContentValidation,
        errorMessage,
        drvSignUpLanguagesFull
    } = props;


    let languageOptions = {
        single: 'single',
        multi: 'multi'
    };

    const handleClickUploadImage = (changeFile, error, language = "en-US") => {
        if (languageOption === languageOptions.single) {
            handleImageChange(changeFile, error)
        } else {
            handleChangeImageByLanguage(language, changeFile, error)
        };
        return;
    };
    const handleCopyClick = (contentId) => {
        copy(contentId)
    }

    return (
        <div>
            <FormGroup
                className="apply-to-control"
            >
                <Form.Label>
                    <Translate value="appBanner.language" />
                </Form.Label>
                <FormControl
                    as="select"
                    className="form-custom"
                    value={languageOption}
                    defaultValue={languageOption}
                    onChange={handleChangeLanguageOption}
                    disabled={!editable}
                >
                    {_.values(languageOptions).map(type => (
                        <option key={type} value={type} selected="">
                            {I18n.t(`appBanner.${type}`)}
                        </option>
                    ))}
                </FormControl>
            </FormGroup>

            {languageOption === languageOptions.single || !languageOption ?
                <>
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.url
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="message.url" />
                            <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                            className="form-custom"
                            type="text"
                            value={url}
                            onChange={e => handleChangeLinkForm('url', e)}
                            disabled={!editable}
                        />
                        <Validator id="url" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(url)}
                                message={I18n.t('message.Please_input')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.subject
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="message.title" />
                            <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                            className="form-custom"
                            type="text"
                            value={subject}
                            onChange={e => handleChangeLinkForm('subject', e)}
                            disabled={!editable}
                        />
                        <Validator id="subject" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(subject)}
                                message={I18n.t('message.Please_input')}
                            />
                        </Validator>
                    </FormGroup>
                    {inboxContentId ? (
                        <FormGroup>
                            <Form.Label>
                                <Translate value="message.ContentId" />
                            </Form.Label>
                            <FormGroup className="qup-input-group">
                                <InputGroup className="single-addon-right">
                                    <FormControl
                                        className="form-custom"
                                        type="text"
                                        value={inboxContentId}
                                        disabled={!editable}
                                    />
                                    <Button
                                        onClick={() => {handleCopyClick(inboxContentId)}}
                                    >
                                        <i class="fa fa-copy" /> Copy
                                    </Button>
                                </InputGroup>
                            </FormGroup>
                        </FormGroup>
                    ):(null)}
                    <FormGroup
                        className={
                            !isSubmited
                                ? null
                                : valid.body
                                    ? null
                                    : 'error'
                        }
                    >
                        <Form.Label>
                            <Translate value="message.body" />
                            <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                            className="form-custom"
                            as="textarea"
                            value={body}
                            onChange={e => handleChangeLinkForm('body', e)}
                            disabled={!editable}
                        />
                        <Validator id="body" callback={ValidatorCallback}>
                            <ValidCase
                                hide={!isSubmited}
                                valid={!Validation.isStringEmpty(body)}
                                message={I18n.t('message.Please_input')}
                            />
                        </Validator>
                    </FormGroup>

                    <FormGroup
                        className="app-banner-wrapper"
                    >
                        <Form.Label>
                            <Translate value="message.image_notification" />{' '}
                        </Form.Label>
                        <UploadFile
                            id="image"
                            name="image"
                            onChange={(data, error) => handleClickUploadImage(data, error)}
                            customTemplate={UploadImageTemplate}
                            className="event-upload-image"
                            customTemplateProps={{
                                fileResult: image || null,
                                noImageTitle: "promotionSettings.Upload_an_image"
                            }}
                            accepts="image/*"
                            onlyImage
                            disabled={!editable}
                        />
                    </FormGroup>
                </>
                : null}

            {languageOption === languageOptions.multi ?
                <Tabs
                    id="currencies-tabs-Ondemand"
                    className="currencies-tabs"
                    defaultActiveKey={_.get(drvSignUpLanguagesFull, '[0].languageTag', 'en-US')}
                    animation={false}
                // onSelect={tabSelectHandle}
                >
                    {drvSignUpLanguagesFull && drvSignUpLanguagesFull.map(languageObj => {
                        const language = languageObj.languageTag
                        let message = messages.find(b => b.language === language);
                        const titleLanguage = languageObj.languageVariant || languageObj.languageTag
                        return (
                            <Tab className="currencies-tab-item" key={language} eventKey={language} title={titleLanguage}>
                                <div className="multi-language-content">
                                    <FormGroup
                                        className={
                                            multiURLValidation === false ? "error" : null
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="message.url" />
                                        </Form.Label>
                                        <FormControl
                                            className="form-custom"
                                            type="text"
                                            value={message && message.url ? message.url : ""}
                                            onChange={e => handleChangeInput(language, 'url', e)}
                                            disabled={!editable}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className={
                                            multiSubjectValidation === false ? "error" : null
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="message.title" />
                                        </Form.Label>
                                        <FormControl
                                            className="form-custom"
                                            type="text"
                                            value={message && message.subject ? message.subject : ""}
                                            onChange={e => handleChangeInput(language, 'subject', e)}
                                            disabled={!editable}
                                        />
                                    </FormGroup>
                                    {inboxContentId ? (
                                        <FormGroup>
                                            <Form.Label>
                                                <Translate value="message.ContentId" />
                                            </Form.Label>
                                            <FormGroup className="qup-input-group">
                                                <InputGroup className="single-addon-right">
                                                    <FormControl
                                                        className="form-custom"
                                                        type="text"
                                                        value={message.inboxContentId}
                                                        disabled={!editable}
                                                    />
                                                    <Button
                                                        className="copy-button"
                                                        onClick={() => {handleCopyClick(inboxContentId)}}
                                                    >
                                                        <i class="fa fa-copy" /> Copy
                                                    </Button>
                                                </InputGroup>
                                            </FormGroup>
                                        </FormGroup>
                                    ):(null)}
                                    <FormGroup
                                        className={
                                            multiContentValidation === false ? "error" : null
                                        }
                                    >
                                        <Form.Label>
                                            <Translate value="message.body" />
                                        </Form.Label>
                                        <FormControl
                                            className="form-custom"
                                            as="textarea"
                                            value={message && message.body ? message.body : ""}
                                            onChange={e => handleChangeInput(language, 'body', e)}
                                            disabled={!editable}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className="app-banner-wrapper"
                                    >
                                        <Form.Label>
                                            <Translate value="message.image_notification" />{' '}
                                        </Form.Label>
                                        <UploadFile
                                            id="image"
                                            name="image"
                                            onChange={(data, error) => handleClickUploadImage(data, error, language)}
                                            customTemplate={UploadImageTemplate}
                                            className="event-upload-image"
                                            customTemplateProps={{
                                                fileResult: message && message.image ? message.image : null,
                                                noImageTitle: "promotionSettings.Upload_an_image"
                                            }}
                                            accepts="image/*"
                                            onlyImage
                                            disabled={!editable}
                                        />
                                    </FormGroup>

                                    {(!multiURLValidation || !multiSubjectValidation || !multiContentValidation) &&
                                        <div className="form-group has-error">
                                            <span className="help-block">
                                                <span className="message-item">
                                                    {errorMessage}
                                                </span>
                                            </span>
                                        </div>
                                    }
                                </div>
                            </Tab>
                        )
                    })}

                </Tabs>
                : null}


        </div>
    )
}
