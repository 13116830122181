import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportAffiliationApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
function ReportAffiliation({ selectedFleet, user }) {
  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules, { name: 'Report' });
  let checkExport = _.findIndex(report.actions, {
    name: 'Affiliation',
    Export: true,
  });
  let checkOwner =
    !user.isAdmin && typeof user.roles.fleetId !== 'undefined'
      ? user.roles.fleetId.ownerId === user._id
        ? true
        : false
      : true;
  checkExport =
    user.isAdmin || checkOwner ? true : checkExport === -1 ? false : true;
  if (!user.isAdmin && selectedFleet.fleetId === 'applecabssouth') {
    checkExport = false;
  }
  const bookingServices = [
    {
      value: 'roaming',
      label: 'report.query.bookingServiceItem.roaming',
    },
    {
      value: 'homeFleet',
      label: 'report.query.farmTypeItem.farmOut',
    },
    {
      value: 'provideService',
      label: 'report.query.bookingServiceItem.provideService',
    },
  ];

  return (
    <div className="content">
      <BaseReport
        tittle="Affiliation"
        fieldMappings={fieldMappings}
        apiurl={reportAffiliationApi}
        bookingService={bookingServices}
        bookingStatus
        payoutPaymentStatus
        dateRange
        supHeader
        nofooter
        noexport
        search="report.query.search.affiliation"
        noexport={!checkExport}
      />
    </div>
  );
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(ReportAffiliation);
