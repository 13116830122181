import React,{Component} from 'react';
import './validator.scss';
import { Validation } from '../../utils/commonFunctions';
import _ from 'lodash'

export default class WarningCase extends Component{
  constructor(props) {
    super(props)
    this.state = {
      initialValue: this.props.initialValue,
      display: false
    }
  }

  checkIsRange(valueInitial, valueCurrent) {
    let min = valueInitial -  valueInitial*this.props.range/100
    let max = valueInitial +  valueInitial*this.props.range/100
    return Validation.isInRange(valueCurrent, min, max)
  }

  componentWillReceiveProps(nextProps) {
    let initialValue = this.state.initialValue
    let nextValue = nextProps.onChangeValue
    if(_.isNumber(nextValue) || _.isString(nextValue)) {
      if(initialValue != 0) {
        let currentValue = (Validation.isNumber(nextValue) ? nextValue : 0)
        this.setState({display: !this.checkIsRange(initialValue, currentValue)})
      } else {
        this.setState({display: false})
      }
    }

    if(_.isArray(nextValue)) {
      if(nextProps.name == "defaultTipValue") {
        let showWarning = false
        initialValue && initialValue.map((ob, index) => {
          ob.map((vlCurrency) => {
            let iso = vlCurrency.currencyISO
            let nextVlCurrency = _.find(nextValue[index], (o) => o.currencyISO == iso)
            if(!_.isUndefined(nextVlCurrency) && !this.checkIsRange(vlCurrency.value, nextVlCurrency.value)) {
              showWarning = true
            }
          })
        })
        this.setState({display: showWarning})
      } else {
        let display = initialValue && initialValue?.filter((ob, index) => {
          if(ob == 0 || initialValue.length == 0) return false
          let currentValue = (Validation.isNumber(nextValue[index]) ? nextValue[index] : 0)
          return !this.checkIsRange(ob, currentValue)
        }).length != 0
        this.setState({display: display})
      }
    }
  }

  render(){
    if(this.state.display && this.props.validator){
      return(
        <span style={{color: "#F2D45F"}}>
            {this.props.message}
        </span>
      )
    }
    return null;
  }
 }


 WarningCase.defaultProps = {
  validator: true, // Khi đúng hết valicase thì mới hiển thị warning
  message: "",
  initialValue: [], // Array number or number
  onChangeValue: [], // Array number or number
  range: 20,
  typePercent: false
};