import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';

import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../../components/validator';
import FormGroupTitle from '../../../../../components/formGroupTitile/FormGroupTitle';
import QuestionCircleTooltip from '../../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import { DefaultCurenciesNumberInputField } from '../../../../../constants/commondata';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
} from '../../../../../utils/commonFunctions';

export default function PriceAdjustment(props) {
  const {
    editable,
    dialogData,
    selectedFleet,
    isSubmited,
    valid,
    ValidatorCallback,
    handlePriceAdjustmentChange,
  } = props;
  return (
    <div>
      <CcCheckbox
        checked={
          dialogData.priceAdjustment
            ? dialogData.priceAdjustment.isActive
            : false
        }
        onChange={(e) => {
          handlePriceAdjustmentChange('isActive', e);
        }}
        text={I18n.t('regularSetting.PRICE_ADJUSTABLE')}
        labelClassName="title"
        className="form-group-title price_adjustment"
        disabled={!editable}
      />
      {dialogData.priceAdjustment && dialogData.priceAdjustment.isActive ? (
        <div>
          <FormGroup
            className={
              !isSubmited
                ? null
                : valid.minimumPercent === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="regularSetting.minimum_Percent_Adjust" />
            </Form.Label>
            <FormGroup className={'qup-input-group'}>
              <InputGroup className={'single-addon-right disabled'}>
                <FormControl
                  type="text"
                  className={'form-custom'}
                  onChange={(e) => {
                    handlePriceAdjustmentChange('minimumPercent', e);
                  }}
                  onBlur={(e) => {
                    textboxNumberHelper.onBlurHandle(e, (e) => {
                      handlePriceAdjustmentChange('minimumPercent', e);
                    });
                  }}
                  onFocus={(e) => {
                    textboxNumberHelper.onfocusHandle(e, (e) => {
                      handlePriceAdjustmentChange('minimumPercent', e);
                    });
                  }}
                  value={
                    dialogData.priceAdjustment
                      ? dialogData.priceAdjustment.minimumPercent
                      : ''
                  }
                  placeholder={I18n.t('regularSetting.minimum_Percent_Adjust')}
                  disabled={!editable}
                />
                <InputGroup.Append>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </FormGroup>
            <Validator
              id="minimumPercent"
              callback={ValidatorCallback}
              disabled={!editable}
            >
              <ValidCase
                valid={
                  dialogData.priceAdjustment.minimumPercent == '' ||
                  !isNaN(dialogData.priceAdjustment.minimumPercent)
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>

          <FormGroup
            className={
              !isSubmited
                ? null
                : valid.maximumPercent === false
                ? 'error'
                : null
            }
          >
            <Form.Label>
              <Translate value="regularSetting.maximum_Percent_Adjust" />
            </Form.Label>
            <FormGroup className={'qup-input-group'}>
              <InputGroup className={'single-addon-right disabled'}>
                <FormControl
                  type="text"
                  className={'form-custom'}
                  onChange={(e) => {
                    handlePriceAdjustmentChange('maximumPercent', e);
                  }}
                  onBlur={(e) => {
                    textboxNumberHelper.onBlurHandle(e, (e) => {
                      handlePriceAdjustmentChange('maximumPercent', e);
                    });
                  }}
                  onFocus={(e) => {
                    textboxNumberHelper.onfocusHandle(e, (e) => {
                      handlePriceAdjustmentChange('maximumPercent', e);
                    });
                  }}
                  value={
                    dialogData.priceAdjustment
                      ? dialogData.priceAdjustment.maximumPercent
                      : ''
                  }
                  placeholder={I18n.t('regularSetting.maximum_Percent_Adjust')}
                  disabled={!editable}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup.Prepend>{' '}
              </InputGroup>
            </FormGroup>
            <Validator
              id="maximumPercent"
              callback={ValidatorCallback}
              disabled={!editable}
            >
              <ValidCase
                valid={
                  dialogData.priceAdjustment.maximumPercent == '' ||
                  !isNaN(dialogData.priceAdjustment.maximumPercent)
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>
        </div>
      ) : null}
    </div>
  );
}
