import { en } from './default/en'
import { en_gb } from './default/en_gb'
import { vi } from './default/vi'
import { fr } from './default/fr'
import { cn_trad } from './default/cn_trad'
import { cn_simp } from './default/cn_simp'
import { it } from './default/it'
import { es } from './default/es'
import { tl } from './default/tl'

import { en as dmcEN } from './DMC/en'
import { vi as dmcVN } from './DMC/vi'
import { fr as dmcFR } from './DMC/fr'

function getLanguage() {
    switch (process.env.REACT_APP_PROJECT) {
        case "DMC":
            return {
                'en-US': dmcEN,
                'fr-FR': dmcFR
            }
        default:
            return {
                'en-US': en,
                'en-GB': en_gb,
                'fr-FR': fr,
                'zh-HK': cn_trad,
                'it-IT': it,
                'zh-CN': cn_simp,
                'es-ES': es,
                'vi-VN': vi,
                'tl-PH': tl
            }
    }
}

export const translations = getLanguage();