import { NEW_NOTIFICATION, NEW_SOS_NOTIFICATION } from '../actions/notificationActions'

const initState = {
    newNotification: null,
    newSOSNotification: null
}
export default function notificationReducers(state = initState, action = {}) {
    switch (action.type) {
        case NEW_NOTIFICATION: {
            return {
                ...state, newNotification: action.data
            }
        }
        case NEW_SOS_NOTIFICATION: {
            return {
                ...state, newSOSNotification: action.data
            }
        }
        default: {
            return state;
        }
    }
}