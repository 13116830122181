import React, { useState, useEffect } from 'react'
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form, InputGroup, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { parStart } from '../../../utils/commonFunctions';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../components/validator';

function DepartureTimeSelect(props) {
  const [time, setTime] = useState('');
  const [tripType, setTripType] = useState(null);
  const [timePrev, setTimePrev] = useState('');
  const [departureTimePrev, setDepartureTimePrev] = useState({});

  const dayOfWeek = props.pickupDate ? moment(props.pickupDate).day() : -1;
  const selectedSchedule = _.find(props.schedules, o => o.dayOfWeek === dayOfWeek)
  const times = _.get(selectedSchedule, 'times', []);

  useEffect(() => {
    if (props.tripType === "requested") {
      const timeValue = props.value && props.departureTimeTo ? `${parStart(props.value.hour)}:${parStart(props.value.minute)} - ${parStart(props.departureTimeTo.hour)}:${parStart(props.departureTimeTo.minute)}` : null;
      setTime(timeValue);
      setTripType(props.tripType)
    } else {
      const timeValue = props.value ? `${parStart(props.value.hour)}:${parStart(props.value.minute)}` : null;
      setTime(timeValue);
      setTripType(props.tripType);
    }
  }, [props]);

  useEffect(() => {
    let timeValue = "";
    if (props.tripType === "requested") {
      timeValue = props.value && props.departureTimeTo ? `${parStart(props.value.hour)}:${parStart(props.value.minute)} - ${parStart(props.departureTimeTo.hour)}:${parStart(props.departureTimeTo.minute)}` : "";
    };
    setTimePrev(timeValue);
    setDepartureTimePrev(props.departureTimeTo);
  }, [props.departureTimeTo]);

  const handleChange = (e) => {
    let selectedTime = _.find(times, o => `${o.hour}:${o.minute}` === e.target.value);
    if (!selectedTime && tripType === "requested") {
      selectedTime = departureTimePrev
    };
    props.onChange(selectedTime)
    props.intercityBookingActions.updateCommonData({
      pickupTime: selectedTime,
      tripType: selectedTime ? (selectedTime._id ? "scheduled" : "requested") : "scheduled"
    })
    props.intercityBookingActions.etaFare();
  }

  const isBookDetails = props.disabled ? props.disabled : false;

  return (
    <FormGroup
      className={props.isSubmited && props.valid.departureTime === false ? 'error' : null}
    >
      <Form.Label>
        <Translate value="intercityBooking.departureTime" />
        &nbsp;
        <span className="require"> *</span>
      </Form.Label>
      {isBookDetails ?
        (
          <FormGroup>
            <FormControl
              type="text"
              placeholder={I18n.t('bookingdetail.select')}
              value={time}
              className="form-custom"
              disabled={true}
            />
            {tripType === "requested" ? <Form.Control.Feedback>{I18n.t('bookingdetail.PICKUP_TIME_RANGE').format(time)}</Form.Control.Feedback>
              : null}
          </FormGroup>
        ) :
        (
          <FormControl
            as="select"
            placeholder="select"
            className={"form-custom"}
            onChange={handleChange}
            value={props.value && props.common.tripType === "scheduled" ? `${props.value.hour}:${props.value.minute}` : ""}
            style={{ marginBottom: 0 }}
            disabled={props.disabled}
          >
            <option
              key={''}
              value={''}
            >
              {tripType === "requested" ?
                `Requested: ${timePrev}` :
                I18n.t('bookingdetail.select_time')
              }
              {/* Select time */}
            </option>
            {times.map(time => {
              return (
                <option
                  key={`${time.hour}:${time.minute}`}
                  value={`${time.hour}:${time.minute}`}
                >
                  {`${parStart(time.hour)}:${parStart(time.minute)}`}
                </option>
              );
            })}
          </FormControl>
        )
      }
      <Validator
        callback={props.validatorCallback}
        id="departureTime"
      >
        <ValidCase
          valid={!!props.value}
          hide
          message={I18n.t('messages.commonMessages.Required_field')}
        />
      </Validator>
    </FormGroup>
  )
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    common: state.intercityBooking.common,
    routeSelected: state.intercityBooking.common.routeSelected,
    schedules: state.intercityBooking.common.schedules,
    pickupDate: state.intercityBooking.common.pickupDate
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(intercityBookingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DepartureTimeSelect);