import React from 'react';
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    InputGroup
} from "react-bootstrap";
import { I18n, Translate } from 'react-redux-i18n';
import currencyFormatter from 'currency-formatter';

import {
    Validation,
    textboxNumberHelper,
} from "../../../../utils/commonFunctions";
import { Validator, ValidCase } from "../../../validator";
import { thirdPartyIntegration } from '../../../../constants/commondata';

function FieldTextbox({
    keyField,
    valueField,
    originValue,
    currencySymbol,
    currencyISO,
    isSubmitted,
    validField,
    ValidatorCallback,
    handleEditInputField,
    disabled,
    isMinimumTotal,
    is3rdBooking,
    data,
}) {
    switch (keyField) {
        case "subTotal":
        case "etaFare":
            const holidaytaxis = is3rdBooking && data?.externalInfo?.thirdParty === thirdPartyIntegration.holidaytaxis
            return (
                <>  
                    {
                        !holidaytaxis &&
                        <div className={"horizontal-line"}></div>
                    }
                    <Row key={keyField}>
                        <Col xs={3}>
                            <FormGroup className={'form-edit-fare'}>
                                <Form.Label className="edit-fare label-bold">
                                    <Translate value={`cue.editFare.${keyField}`} />
                                    {
                                        isMinimumTotal && keyField === 'etaFare' ? ' (minimum)' : ''
                                    }
                                </Form.Label>
                            </FormGroup>
                        </Col>
                            {
                                holidaytaxis ? (
                                    <>
                                        <Col xs={6} >
                                            <FormGroup
                                                className={`qup-input-group form-edit-fare ${
                                                    isSubmitted
                                                        ? validField === false
                                                            ? "error"
                                                            : null
                                                        : null
                                                }`}
                                            >
                                                <InputGroup className="single-addon-left">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>
                                                            {currencySymbol}
                                                        </InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                        type="text"
                                                        className="form-custom"
                                                        onChange={e => handleEditInputField(e, keyField)}
                                                        onBlur={e => {
                                                            textboxNumberHelper.onBlurHandle(e, e => handleEditInputField(e, keyField));
                                                        }}
                                                        onFocus={e => {
                                                            textboxNumberHelper.onfocusHandle(
                                                                e,
                                                                e => handleEditInputField(e, keyField)
                                                            );
                                                        }}
                                                        value={valueField}
                                                    />
                                                </InputGroup>
                                                <Validator id={keyField} callback={ValidatorCallback}>
                                                    <ValidCase
                                                        valid={
                                                            !Validation.isStringEmpty(valueField)
                                                        }
                                                        message={I18n.t("messages.commonMessages.Required_field")}
                                                        hide={!isSubmitted}
                                                    />
                                                    <ValidCase
                                                        valid={Validation.isNumber(valueField)}
                                                        message={I18n.t("messages.commonMessages.must_be_number")}
                                                        hide={!isSubmitted}
                                                    />
                                                </Validator>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} >
                                            {parseFloat(valueField) !== data?.externalInfo?.price &&
                                                <FormGroup className={'form-edit-fare'}>
                                                    <Form.Label className="edit-fare">
                                                        <span style={{ textDecoration: "line-through" }}>
                                                            {currencyFormatter.format(data?.externalInfo?.price, {
                                                                code: currencyISO
                                                            })}
                                                        </span>
                                                    </Form.Label>
                                                </FormGroup>
                                            }
                                        </Col>
                                    </>
                                ) : (
                                    <Col xs={9}>
                                        <FormGroup
                                            className={`form-edit-fare ${
                                                isSubmitted
                                                    ? validField === false
                                                        ? "error"
                                                        : null
                                                    : null
                                            }`}>
                                            <Form.Label className="edit-fare label-bold total-fare">
                                                <span>
                                                    {currencyFormatter.format(valueField, {
                                                        code: currencyISO
                                                    })}
                                                </span>
                                            </Form.Label>
                                            {keyField == "etaFare" &&
                                                <Validator id="etaFare" callback={ValidatorCallback}>
                                                    <ValidCase
                                                        valid={(valueField < (originValue * 10)) || originValue === 0}
                                                        message={I18n.t(
                                                            'messages.commonMessages.large_10x_markup_price'
                                                        ).format(0)}
                                                        hide={!isSubmitted}
                                                    />
                                                </Validator>}
                                        </FormGroup>
                                    </Col>
                                )
                            }
                    </Row>
                </>
            );
        default:
            return (
                <Row key={keyField}>
                    <Col xs={3}  >
                        <FormGroup className={'form-edit-fare'}>
                            <Form.Label className="edit-fare">
                                <Translate value={`cue.editFare.${keyField}`} />
                                {/* <span className="require">*</span> */}
                            </Form.Label>
                        </FormGroup>
                    </Col>
                    {keyField === "serviceFee" || keyField === "tax" || keyField === "promoAmount" || keyField === "fleetService" ?
                        <Col xs={9} >
                            <FormGroup className={'form-edit-fare'}>
                                <Form.Label className="edit-fare">
                                    <span>
                                        {currencyFormatter.format(valueField, {
                                            code: currencyISO
                                        })}
                                        {'  '}
                                        {keyField === "serviceFee" ? <span style={{ fontStyle: "italic" }}>{I18n.t("cue.editFare.Note_serviceFee")}</span> : null}
                                        {keyField === "fleetService" ? <span style={{ fontStyle: "italic" }}>{`(to edit service fees, visit outer list)`}</span> : null}
                                    </span>
                                </Form.Label>
                            </FormGroup>
                        </Col>
                        :
                        <>
                            <Col xs={6} >
                                <FormGroup
                                    className={`qup-input-group form-edit-fare ${
                                        isSubmitted
                                            ? validField === false
                                                ? "error"
                                                : null
                                            : null
                                    }`}
                                >
                                    <InputGroup className="single-addon-left">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">
                                                {currencySymbol}
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            type="text"
                                            className="form-custom"
                                            onChange={e => handleEditInputField(e, keyField)}
                                            onBlur={e => {
                                                textboxNumberHelper.onBlurHandle(e, e => handleEditInputField(e, keyField));
                                            }}
                                            onFocus={e => {
                                                textboxNumberHelper.onfocusHandle(
                                                    e,
                                                    e => handleEditInputField(e, keyField)
                                                );
                                            }}
                                            value={valueField}
                                            disabled={disabled}
                                        />
                                    </InputGroup>
                                    <Validator id={keyField} callback={ValidatorCallback}>
                                        <ValidCase
                                            valid={
                                                !Validation.isStringEmpty(valueField)
                                            }
                                            message={I18n.t("messages.commonMessages.Required_field")}
                                            hide={!isSubmitted}
                                        />
                                        <ValidCase
                                            valid={Validation.isNumber(valueField)}
                                            message={I18n.t("messages.commonMessages.must_be_number")}
                                            hide={!isSubmitted}
                                        />
                                    </Validator>
                                </FormGroup>
                            </Col>
                            <Col xs={3} >
                                {parseFloat(valueField) !== originValue &&
                                    <FormGroup className={'form-edit-fare'}>
                                        <Form.Label className="edit-fare">
                                            <span style={{ textDecoration: "line-through" }}>
                                                {currencyFormatter.format(originValue, {
                                                    code: currencyISO
                                                })}
                                            </span>
                                        </Form.Label>
                                    </FormGroup>
                                }
                            </Col>
                        </>
                    }
                </Row>
            );
    }
}


export default React.memo(FieldTextbox)