import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Modal, FormGroup, FormControl, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import BaseReport from '../base';
import * as fieldMappings from './fields';
import {
  reportCashWalletApi,
  sendPayoutApi,
} from '../../../constants/ApiConfigs';
import * as reportActions from '../../../actions/reportActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';

const transactionTypesCash = [
  { value: 'all', label: 'report.query.transactionTypeItem.all' },
  {
    value: 'bankAccount',
    label: 'report.query.transactionTypeItem.bankAccount',
  },
  {
    value: 'creditWallet',
    label: 'report.query.transactionTypeItem.creditWallet',
  },
  {
    value: 'tipAfterCompleted',
    label: 'report.query.transactionTypeItem.tipAfterCompleted',
  },
  { value: 'netEarning', label: 'report.query.transactionTypeItem.netEarning' },
  // { value: 'referralEarning', label: 'report.query.transactionTypeItem.referralEarning' },
  { value: 'itemValue', label: 'report.query.transactionTypeItem.itemValue' },
  { value: 'payout', label: 'report.query.transactionTypeItem.payoutManual' },
  {
    value: 'payoutDriver',
    label: 'report.query.transactionTypeItem.payoutDriver',
  },
  {
    value: 'updatedBalance',
    label: 'report.query.transactionTypeItem.updatedBalance',
  },
  {
    value: 'partialEarning',
    label: 'report.query.transactionTypeItem.partialEarning',
  },
  {
    value: 'WingBank',
    label: 'report.query.transactionTypeItem.WingBank',
  },
  {
    value: 'eWallet',
    label: 'report.query.transactionTypeItem.eWallet',
  },
  {
    value: 'reversed',
    label: 'report.query.transactionTypeItem.reversed',
  },
];
class ReportCashWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingId: null,
      showModal: false,
      sharedBooks: [],
      additionalServices: [],
      extraDestination: [],
      showServices: false,
      showExtraDestination: false,
      showEmailInputModal: false,
      emailString: '',
      validEmail: false,
    };
  }

  handleChangeEmailInput = (e) => {
    this.setState({ emailString: e.target.value });
    let emails = e.target.value
      .trim()
      .split(/[,;]/)
      .map((email) => email.toLowerCase().trim());
    emails = _.compact(emails);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    this.setState({
      validEmail: emails.every((email) => emailRegex.test(email)),
    });
  };

  showSendPayout = (link, data = {}) => {
    this.setState({
      showEmailInputModal: true,
      row: data.row,
    });
  }

  sendReceipt() {
    const { changedAmount, currencyISO, driverId, referenceId, reason } =
      this.state.row;
    let email = this.state.emailString
      .trim()
      .split(/[,;]/)
      .map((email) => email.toLowerCase().trim());
    email = _.compact(email);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const validEmail = email.every((mail) => emailRegex.test(mail));
    if (validEmail) {
      var requestBody = {
        userId: driverId,
        currencyISO: currencyISO,
        amount: parseFloat(changedAmount),
        reason: reason,
        referenceId: referenceId,
        type: 'manual',
        email,
      };
      this.props.loadingBarActions.showLoadingSpiner();
      this.props.reportActions
        .sendPayout(sendPayoutApi, requestBody)
        .then((resp) => {
          this.props.loadingBarActions.hideLoadingSpiner();
          this.setState({
            showEmailInputModal: false,
            emailString: '',
            validEmail: false,
          });
          if (resp.res.returnCode === 200) {
            this.context.notification(
              'success',
              I18n.t('report.result.bookingDetails.sentReceiptSuccess')
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('report.result.bookingDetails.sentReceiptFailed')
            );
          }
        });
    }
  }

  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Driver cash wallet',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    const emailModal = (
      <Modal
        show={this.state.showEmailInputModal}
        backdrop="static"
        bsSize="lg"
        dialogClassName="logs-modal send-receipt-modal"
        onHide={() =>
          this.setState({
            showEmailInputModal: false,
            emailString: '',
            validEmail: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {I18n.t('report.result.cashWallet.sendPayoutTitle').toUpperCase()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="mb-lg">
            <FormControl
              type="text"
              value={this.state.emailString}
              onChange={this.handleChangeEmailInput}
              placeholder="email1@email.com;email2@email.com"
              className="form-custom"
            />
            {!this.state.validEmail && this.state.emailString ? (
              <div className="text-remove mt">
                <Translate value="report.query.errorMessage.invalidEmail" />
              </div>
            ) : null}
          </FormGroup>
          <div className="text-center">
            <Button
              className="btn-send mr-lg"
              disabled={!this.state.validEmail || !this.state.emailString}
              onClick={() => this.sendReceipt.call(this)}
            >
              <Translate value="report.result.bookingDetails.send" />
            </Button>
            <Button
              className="btn-cancel"
              onClick={() => {
                this.setState({
                  showEmailInputModal: false,
                  emailString: '',
                  validEmail: false,
                });
              }}
            >
              <Translate value="report.result.bookingDetails.cancel" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );

    return (
      <div className="content">
        {emailModal}
        <BaseReport
          tittle="Cash Wallet"
          fieldMappings={fieldMappings}
          apiurl={reportCashWalletApi}
          dateRange
          company
          driver
          currency
          symbol
          transactionType={transactionTypesCash}
          search="report.query.search.cashWallet"
          noexport={!checkExport}
          actions={{
            showSendPayout: this.showSendPayout,
          }}
        />
      </div>
    );
  }
}
/* 
'all','topUp','bookingDeduction','topUpGCash','cashWallet','topUpMOLPay'
"all","topUp","editBalance","bookingDeduction","cashWallet"
"all", "bankAccount", "creditWallet", "tipAfterCompleted", "netEarning", "referralEarning"
 */

ReportCashWallet.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportCashWallet);
