import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import {
  CCLiteCommonFunc,
  Validation,
} from '../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
} from '../../components/validator';

export default class GetEmailExpModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      email: '',
      isSubmitted: false,
    };
  }

  componentDidMount() {
    this.setState({
      email: this.props.user && this.props.user.email || ''
    })
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleConfirmButtonClick = (e) => {
    this.setState({
      isSubmitted: true,
    });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.handleResulEmailExport(this.state.email);
  };

  render() {
    const { closeModal } = this.props;
    const { valid, email, isSubmitted } = this.state;
    return (
      <Modal
        show={true}
        backdrop="static"
        className="confirm"
        onHide={closeModal}
      >
        <Modal.Header>
          <Modal.Title>{I18n.t('customer.Export_to_excel')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            className={
              isSubmitted ? (valid.email === false ? 'error' : null) : null
            }
          >
            <Form.Label>
              <Translate value="report.query.sendDownloadLink" />
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.handleEmailChange}
              value={email || ''}
              maxLength={500}
            />
            <Validator id="email" callback={this.ValidatorCallback}>
              <ValidCase
                valid={email && !Validation.isStringEmpty(email)}
                message={I18n.t('corporate.ERROR_INPUT_EMAIL')}
                hide={!isSubmitted}
              />
              <ValidCase
                valid={Validation.validateEmail(email)}
                message={I18n.t('corporate.ERROR_INPUT_VALID_EMAIL')}
                hide={!isSubmitted}
              />
            </Validator>
          </FormGroup>
          <p>{<Translate value="report.query.generaltingYourExcel" />}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-reset" onClick={closeModal}>
            {I18n.t('cue.Close')}
          </Button>
          <Button className="btn-save" onClick={this.handleConfirmButtonClick}>
            {I18n.t('cue.Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
