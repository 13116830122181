import _ from "lodash";
import React from 'react';
import {Col} from 'react-bootstrap';
import {Pie} from "react-chartjs-2";
import {I18n} from 'react-redux-i18n';
import {doughnutOptions} from '../../utils/chartUtils';

const FinishedBooking = ({auth, bookStatusChartData}) => {
  return (
    <Col sm={!auth.selectedFleet.passenger.drvRating ? 6 : 12}>
      {!_.isEmpty(bookStatusChartData) && (
        <div className='db-chart'>
          <Pie
            data={bookStatusChartData}
            options={{
              ...doughnutOptions,
              title: {
                ...doughnutOptions.title,
                text: I18n.t('dashboard.Finished_booking_char_header').format(
                  bookStatusChartData ? bookStatusChartData.datasets[0].data.sum() : 0,
                ),
                fontColor: '#e6e6e6',
              },
            }}
          />
        </div>
      )}
    </Col>
  )
};

export default FinishedBooking;
