import React, { Fragment } from 'react';
import _ from 'lodash';
import { Modal, Row, Col, FormGroup, Form, Button } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import QueuingAreaMap from './QueuingAreaMap';
import AreaNameWithColor from './AreaNameWithColor';
import { ObjectId } from '../../../utils/commonFunctions';
import { QueueColor } from './colorData';
import { isOverlapPolygons } from '../../../utils/polygonUtil';

export default class DrawPolygonModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeZone: null,
      showCheckInArea: false,
      showPickupArea: false,
      driverArea: _.cloneDeep(props.driverArea),
      pickUpAreas: _.cloneDeep(props.pickUpAreas || []),
      polyline: props.polyline || false,
      pickupActiveId: null,
      center: props.center
    };
  }

  componentDidMount() {
    const { showCheckInArea, showPickupArea, pickupActiveIndex, pickUpAreas } = this.props;
    if (showCheckInArea) {
      this.handleEditCheckInArea();
    }

    if (showPickupArea) {
      if (pickupActiveIndex !== -1) {
        this.handleEditPickupArea(pickUpAreas[pickupActiveIndex]._id);
      } else {
        this.handleAddPickupArea();
      }
    }
  }

  handleEditCheckInArea = () => {
    const { driverArea, pickUpAreas, showCheckInArea, activeZone, polyline, center } = this.state;
    if (polyline || showCheckInArea) {
      return;
    }
    if (activeZone) {
      if (showCheckInArea) {
        driverArea.coordinates[0] = activeZone.path.map(o => [o.lng, o.lat]);
      } else {
        const activeIndex = _.findIndex(pickUpAreas, o => o._id === activeZone._id);
        _.set(pickUpAreas[activeIndex], 'geo.coordinates[0]', activeZone.path.map(o => [o.lng, o.lat]));
      }
    }
    const activeZoneUpdate = {
      key: ObjectId(),
      path: driverArea.coordinates[0].map(pos => {
        return {
          lat: pos[1],
          lng: pos[0]
        };
      })
    };
    const firstPos = _.get(driverArea, 'coordinates[0][0]');
    this.setState({
      activeZone: activeZoneUpdate,
      polyline: activeZoneUpdate.path.length === 0,
      showPickupArea: false,
      showCheckInArea: true,
      pickupActiveId: null,
      center: firstPos ? { lat: firstPos[1], lng: firstPos[0] } : center
    });
  }

  handleAddPickupArea = () => {
    const { driverArea, pickUpAreas, showCheckInArea, activeZone, polyline, pickupActiveId } = this.state;
    if (polyline) {
      return;
    }
    if (activeZone) {
      if (showCheckInArea) {
        driverArea.coordinates[0] = activeZone.path.map(o => [o.lng, o.lat]);
      } else {
        const activeIndex = _.findIndex(pickUpAreas, o => o._id === pickupActiveId);
        _.set(pickUpAreas[activeIndex], 'geo.coordinates[0]', activeZone.path.map(o => [o.lng, o.lat]));
      }
    }
    const _id = ObjectId();
    pickUpAreas.push({
      _id,
      name: `Pick up areas ${pickUpAreas.length + 1}`,
      geo: {
        type: 'Polygon',
        coordinates: [[]]
      }
    });

    this.setState({
      pickUpAreas: [...pickUpAreas],
      driverArea: { ...driverArea },
      activeZone: {
        _id,
        key: 'active_polygon',
        path: []
      },
      pickupActiveId: _id,
      showPickupArea: true,
      showCheckInArea: false,
      polyline: true
    });
  }

  handleEditPickupArea = (pickUpId) => {
    const { pickUpAreas, driverArea, showCheckInArea, activeZone, polyline, pickupActiveId } = this.state;
    if (polyline || pickupActiveId === pickUpId) return;
    const activeIndex = _.findIndex(pickUpAreas, o => o._id === pickUpId);
    const currentActiveIndex = _.findIndex(pickUpAreas, o => o._id === pickupActiveId);
    if (activeZone) {
      if (showCheckInArea) {
        driverArea.coordinates[0] = activeZone.path.map(o => [o.lng, o.lat]);
      } else {
        _.set(pickUpAreas[currentActiveIndex], 'geo.coordinates[0]', activeZone.path.map(o => [o.lng, o.lat]));
      }
    }
    const nextActiveZone = {
      _id: pickUpId,
      key: 'active_polygon',
      path: pickUpAreas[activeIndex].geo.coordinates[0].map(pos => {
        return {
          lat: pos[1],
          lng: pos[0]
        };
      })
    };
    const firstPos = _.get(nextActiveZone, 'path[0]');
    this.setState({
      pickUpAreas: [...pickUpAreas],
      driverArea: { ...driverArea },
      activeZone: nextActiveZone,
      center: firstPos,
      pickupActiveId: pickUpId,
      showPickupArea: true,
      showCheckInArea: false,
      polyline: false,
    });
  }

  handleRemovePickupArea = (pickUpId) => {
    const { pickUpAreas, activeZone, polyline, pickupActiveId } = this.state;
    const activeIndex = _.findIndex(pickUpAreas, o => o._id === pickUpId);
    pickUpAreas.splice(activeIndex, 1);
    this.setState({
      pickUpAreas: [...pickUpAreas],
      activeZone: pickupActiveId === pickUpId ? null : activeZone,
      polyline: pickupActiveId === pickUpId ? false : polyline,
    });
  }

  handleClearDrawClick = () => {
    this.setState({
      activeZone: {
        key: 'active_polygon',
        path: []
      },
      polyline: true
    });
  }

  handleChangeActiveZone = (activeZone, polyline) => {
    this.setState({ activeZone: { ...activeZone }, polyline: _.isUndefined(polyline) ? this.state.polyline : polyline });
  }

  onSavePolygons = () => {
    const { onSavePolygons, onCloseDialog } = this.props;
    const { driverArea, pickUpAreas, polyline, activeZone, showCheckInArea, pickupActiveId } = this.state;
    if (polyline) return;
    if (activeZone) {
      if (showCheckInArea) {
        driverArea.coordinates[0] = activeZone.path.map(o => [o.lng, o.lat]);
      } else {
        const activeIndex = _.findIndex(pickUpAreas, o => o._id === pickupActiveId);
        console.log("TCL: DrawPolygonModal -> onSavePolygons -> activeIndex", activeIndex)
        _.set(pickUpAreas[activeIndex], 'geo.coordinates[0]', activeZone.path.map(o => [o.lng, o.lat]));
      }
    }

    if (isOverlapPolygons(pickUpAreas)) {
      return this.context.notification(
        'error',
        I18n.t('errors.30006')
      );
    }

    onSavePolygons({
      driverArea: { ...driverArea },
      pickUpAreas: [...pickUpAreas]
    });
    onCloseDialog();
  }

  render() {
    const { pickUpAreas, driverArea, pickupActiveId } = this.state;
    const { activeZone, showCheckInArea, showPickupArea, polyline } = this.state;
    return (
      <Modal
        show
        backdrop
        onHide={this.props.onCloseDialog}
        dialogClassName="model-super-large fit-content-modal draw-area-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="queuingArea.EDIT_POLYGONS" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.onCloseDialog}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3} className="leftContent" >
              <div className="leftContent__areaList">
                <FormGroup>
                  <Row>
                    <Col xs={8}>
                      <Form.Label>
                        <AreaNameWithColor active={showCheckInArea} color={QueueColor.checkIn.fill} borderColor={QueueColor.checkIn.border} name={I18n.t('queuingArea.driver_checkin_area')} />
                      </Form.Label>
                    </Col>
                    <Col xs={4} className="text-right">
                      <span onClick={this.handleEditCheckInArea}>
                        { _.get(driverArea, 'coordinates[0]', []).length ? (
                          <Translate
                            className="text-active cursor-pointer"
                            value="queuingArea.Edit"
                          />
                        ) : (
                          <Translate
                            className="text-active cursor-pointer"
                            value="queuingArea.Add"
                          />
                        )}
                      </span>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col xs={8}>
                      <Form.Label>
                        <AreaNameWithColor color={QueueColor.pickUp.fill} borderColor={QueueColor.pickUp.border} name={I18n.t('queuingArea.pax_pickup_area')} />
                      </Form.Label>
                    </Col>
                    <Col xs={4} className="text-right">
                      {pickUpAreas.length < 20 ? (
                        <span onClick={this.handleAddPickupArea}>
                          <Translate
                            className="text-active cursor-pointer"
                            value="queuingArea.Add"
                          />
                        </span>
                      ) : null}
                    </Col>
                  </Row>
                  {pickUpAreas.map((area, index) => {
                    return (
                      <Row>
                        <Col xs={8}>
                          <Form.Label className="pl-xl">
                            <AreaNameWithColor
                              active={showPickupArea && pickupActiveId === area._id}
                              color={pickupActiveId === area._id ? QueueColor.yourChoosen.fill : QueueColor.pickUp.fill}
                              borderColor={pickupActiveId === area._id ? QueueColor.yourChoosen.border : QueueColor.pickUp.border}
                              name={`${I18n.t('queuingArea.pickup_area')} ${index + 1}`}
                            />
                          </Form.Label>
                        </Col>
                        <Col xs={4} className="text-right">
                          <span onClick={() => this.handleEditPickupArea(area._id)}>
                            <Translate
                              className="text-active cursor-pointer"
                              value="queuingArea.Edit"
                            />
                          </span>
                          { pickUpAreas.length > 1 ? (
                            <Fragment>
                              <span className="ml">|</span>
                              <span onClick={() => this.handleRemovePickupArea(area._id)}>
                                <Translate
                                  className="text-remove cursor-pointer ml"
                                  value="queuingArea.Delete"
                                />
                              </span>
                            </Fragment>
                          ) : null }
                        </Col>
                      </Row>
                    )
                  })}
                </FormGroup>
              </div>
              <div className="leftContent__description">
                <AreaNameWithColor color={QueueColor.yourChoosen.fill} borderColor={QueueColor.yourChoosen.border} name={I18n.t('queuingArea.your_chosen_polygon')} />
                <AreaNameWithColor color={QueueColor.otherAreas.fill} borderColor={QueueColor.otherAreas.border} name={I18n.t('queuingArea.other_queues')} />
                <AreaNameWithColor color={QueueColor.operationZones.fill} borderColor={QueueColor.operationZones.border} name={I18n.t('queuingArea.operation_zones')} />
              </div>
            </Col>
            <Col xs={9}>
              <QueuingAreaMap
                queueId={this.props.queueId}
                driverArea={driverArea}
                pickUpAreas={pickUpAreas}
                activeZone={activeZone}
                pickupActiveId={pickupActiveId}
                polyline={polyline}
                showCheckInArea={showCheckInArea}
                showPickupArea={showPickupArea}
                onChangeActiveZone={this.handleChangeActiveZone}
                handleEditPickupArea={this.handleEditPickupArea}
                handleEditCheckInArea={this.handleEditCheckInArea}
                center={this.state.center}
              />
              <Row>
                <Col xs={4} className="mb-md">
                  <Button className="btn-reset" onClick={this.handleClearDrawClick}>
                    <Translate value="zoneSetting.Clear_draw" />
                  </Button>
                </Col>
                <Col xs={8} className="text-right mb-md">
                  <Button
                    className="btn-save mr-md"
                    onClick={this.onSavePolygons}
                  >
                    <Translate value="queuingArea.Save" />
                  </Button>
                  <Button className="btn-cancel" onClick={this.props.onCloseDialog}>
                    <Translate value="queuingArea.Cancel" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

DrawPolygonModal.contextTypes = {
  notification: PropTypes.func
};
