import React from 'react';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import { reportDailyDriverApi } from '../../../constants/ApiConfigs';
import _ from 'lodash';
import { connect } from 'react-redux';
 function ReportDailyDriver({selectedFleet ,user}) {
  const dateRange = [
    {
      value: 'thisMonth',
      key: 'thisMonth',
      label: 'report.query.thisMonth'
    },
    {
      value: 'past30Days',
      key: 'past30Days',
      label: 'report.query.past30Days'
    },
    {
      value: 'today',
      key: 'today',
      label: 'report.query.today'
    },
    {
      value: 'custom',
      key: 'custom',
      label: 'report.query.custom'
    }
  ];
  const modules = _.get(selectedFleet, 'modules', []);
  const report = _.find(modules,{"name":'Report'});
  let checkExport = _.findIndex(report.actions,{"name":"Daily Driver","Export":true});
  let checkOwner = !user.isAdmin && typeof user.roles.fleetId !== 'undefined' ? user.roles.fleetId.ownerId === user._id ? true : false : true ;
  checkExport = user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
  if(!user.isAdmin && selectedFleet.fleetId === "applecabssouth"){
         checkExport = false;
  }
  return (
    <div className="content">
      <BaseReport
        tittle="Daily Driver"
        fieldMappings={fieldMappings}
        apiurl={reportDailyDriverApi}
        dateRange={dateRange}
        company
        driver
        currency
        search="report.query.search.dailyDriver"
        supHeader
        nofooter
        settings
        noexport={!checkExport}
        isReportDailyDriver
      />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(
    mapStateToProps,
    () => { }
)(ReportDailyDriver);
