import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Validation } from '../../../utils/commonFunctions'
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import * as loadingBarActions from './../../../actions/loadingBarActions'
import {
    Modal,
    FormGroup,
    Form,
    InputGroup,
    FormControl,
    Button,
} from 'react-bootstrap';
import UploadFile from './../../../components/upload/upload'
const FILE_ACCEPTED = '.xls, .xlsx';
const MAX_FILE_SIZE = 5000000;
class Import extends React.Component {
    constructor() {
        super();
        this.state = {
            successItem: [],
            failedItem: []
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.isValidDocumentFile = this.isValidDocumentFile.bind(this);
        this.handleImportCustomerClick = this.handleImportCustomerClick.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

    }

    handleFileChange(data) {
        //e.preventDefault();
        let file = null
        if (data) file = data.file
        if (file && this.isValidDocumentFile(file)) {
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    file: file
                });
            }
            reader.readAsDataURL(file);
        } else {
            document.getElementById("file").value = "";
            this.setState({
                file: null
            });
        }
    }

    isValidDocumentFile(file) {
        let fileExtensions = FILE_ACCEPTED.split(", ");
        let fileName = file.name.substring(file.name.lastIndexOf('.'), file.name.length) || file.name;
        if (fileExtensions.indexOf(fileName.toLowerCase()) === -1) {
            this.context.notification('error', I18n.t('promotionSettings.Invalid_file'));
            return false;
        } else if (file.size > MAX_FILE_SIZE) {
            this.context.notification('error', I18n.t('promotionSettings.File_size_larger_than_5Mb'));
            return false;
        }
        return true;
    }

    handleImportCustomerClick() {
        if (!this.state.file) {
            return;
        }
        var data = new FormData();
        data.append('fleetId', this.props.auth.selectedFleet.fleetId);
        data.append('file', this.state.file);
        this.props.loadingBarActions.showLoadingSpiner();
        this.props.import(data).then(respone => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (respone.ok) {
                //this.context.notification('success', I18n.t('promotionSettings.Import_promotions_success'));
                this.setState({
                    importSuccess: true,
                    length: respone.res.total,
                    skip: respone.res.ignored,
                    imported: respone.res.created,
                    successItem: respone.res.success,
                    failedItem: respone.res.failed,
                });
                this.props.callback()
            } else if (respone.error) {
                this.context.notification('error', I18n.t('messages.commonMessages.Error_message'), I18n.t('errors.' + respone.error.errorCode));
            }
        });
    }

    closeDialog() {
        this.setState({
            importSuccess: false,
            length: 0,
            skip: 0,
            imported: 0,
        })
        this.props.closeDialog();
    }

    buildSuccessCode(codes) {
        let codeString = codes.map(obj => {
            return `${obj.promotionCode}${obj.customers.assigned > 0
                ? `(${obj.customers.assigned}/${obj.customers.total} ${I18n.t('promotionSettings.Phone_Numbers')})` : ''}`
        }).join(', ')
        return I18n.t('promotionSettings.Import_Promotion_code_success').format(codeString)
    }

    buildFailedCode(codes) {
        let codeString = codes.join(", ")
        return I18n.t('promotionSettings.Import_Promotion_code_fail').format(codeString)
    }

    render() {
        if (this.props.isShow) {
            return (
                <Modal
                    show={true} onHide={this.closeDialog}
                    backdrop='static' aria-labelledby="contained-modal-title-sm"
                    dialogClassName='confirm'
                    className="confirm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title><Translate value='promotionSettings.Import_Promotions' /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='clearfix'>
                        {this.state.importSuccess ?
                            <FormGroup>
                                <Translate value='promotionSettings.Number_successful_imports' /> <b>{this.state.imported} </b>

                                {
                                    this.state.successItem && this.state.successItem.length > 0 && `. `
                                }
                                {
                                    this.state.successItem && this.state.successItem.length > 0 ?
                                        <span dangerouslySetInnerHTML={{ __html: this.buildSuccessCode(this.state.successItem) }} /> :
                                        null
                                }

                                <br />
                                <Translate value='promotionSettings.Number_skipped' /> <b>{this.state.skip}.  </b>
                                {
                                    this.state.failedItem && this.state.failedItem.length > 0 ?
                                        <span dangerouslySetInnerHTML={{ __html: this.buildFailedCode(this.state.failedItem) }} /> :
                                        null
                                }

                            </FormGroup>
                            :
                            <FormGroup>
                                <div className="body-import">
                                  <Form.Label>
                                    <Translate value="customer.File" />
                                  </Form.Label>
                                  <a href={`${process.env.REACT_APP_S3_SERVER}/templates/ImportPromotionTemplate_v2_1.xlsx`}className="linkTemplate"><Translate value="customer.Download_template" /></a>
                                </div>
                                <UploadFile
                                    id={"file"}
                                    name={"fileImport"}
                                    onChange={this.handleFileChange}
                                    accept={FILE_ACCEPTED}
                                />
                            </FormGroup>
                        }
                    </Modal.Body>
                    {this.state.importSuccess ? null :
                        <Modal.Footer>
                            <Button className="btn-cancel mr-md" onClick={this.closeDialog}>
                                <Translate value='promotionSettings.Cancel' />
                            </Button>
                            {!this.props.permissions || this.props.permissions.actions ?
                                <Button className="btn-save" onClick={this.handleImportCustomerClick}>
                                    <Translate value='promotionSettings.Save' /></Button>
                                : null}
                        </Modal.Footer>
                    }
                </Modal>
            )
        } else {
            return null;
        }
    }
}

Import.contextTypes = {
    notification: PropTypes.func
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    }
}
function mapDispatchToProps(dispatch) {
    return {
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Import);