import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";

const EliteTelecom = (props) => {
  return (
    <div>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.webService" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.webService || ''}
          onChange={(e) => {
            props.handleInputChange('webService', e);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Form.Label>
          <Translate value="smsIntegration.bearerToken" />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          disabled={props.onLoad}
          value={props?.formData?.bearerToken || ''}
          onChange={(e) => {
            props.handleInputChange('bearerToken', e);
          }}
        />
      </FormGroup>
    </div>
  );
};

export default EliteTelecom;
