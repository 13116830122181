import React, { Component } from 'react';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';

import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';

export default class FaceMaskZoneComlumn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRange: [
        { key: "30", value: 30 },
        { key: "60", value: 60 },
        { key: "120", value: 120 },
        { key: "180", value: 180 },
        { key: "240", value: 240 },
        { key: "300", value: 300 },
        { key: "360", value: 360 },
        { key: "420", value: 420 },
        { key: "480", value: 480 },
        { key: "720", value: 720 },
        { key: "1440", value: 1440 },
        { key: "2880", value: 2880 },
        { key: "10080", value: 10080 },
      ],
      skipVerifyTimes: [
        { key: "1", value: 1 },
        { key: "2", value: 2 },
        { key: "3", value: 3 }
      ]
    };
  }

  handleChangeCheckBox = (key, e) => {
    const { zone, onChange, onValidate } = this.props;
    let isChecked = e.target.checked;
    if (key === "openApp" || key === "startReservation") {
      zone.needToVerify[key] = e.target.checked;
    } else {
      zone[key] = e.target.checked;
    };
    // SL-20012
    if (key === "isForce" && isChecked) {
      zone.isTurnOnVerify = e.target.checked;
      zone.needToVerify.openApp = e.target.checked;
    };
    if (key === "isTurnOnVerify" && !isChecked) {
      zone.isForce = e.target.checked;
      zone.needToVerify.openApp = e.target.checked;
      zone.needToVerify.startReservation = e.target.checked;
    };
    // Default openApp is true
    if (key === "isTurnOnVerify" && isChecked) {
      zone.needToVerify.openApp = e.target.checked;
    }
    // Not allow to turn off both (openApp & startReservation)
    if (zone.isTurnOnVerify && (zone.needToVerify.openApp || zone.needToVerify.startReservation)) {
      onValidate(zone.operationZoneId, true)
    } else if (!zone.isTurnOnVerify) {
      onValidate(zone.operationZoneId, true)
    } else {
      onValidate(zone.operationZoneId, false)
    }
    onChange(zone);
  };

  handleChangeTimeRangeToVerify = e => {
    const { zone, onChange } = this.props;
    zone.timeRangeToVerify = parseInt(e.target.value);
    onChange(zone);
  };

  handleChangeSkipVerifyTimes = e => {
    const { zone, onChange } = this.props;
    zone.skipVerifyTimes = parseInt(e.target.value);
    onChange(zone);
  };

  render() {
    const {
      zone,
      editable
    } = this.props;

    return (
      <tr>
        <td className="text-ellipsis" style={{ maxWidth: 0 }}>
          <span title={_.get(zone, 'zoneName')}>
            {_.get(zone, 'zoneName')}
          </span>
        </td>
        <td>
          <CcCheckbox
            disabled={editable}
            checked={_.get(zone, 'isTurnOnVerify')}
            onChange={e => this.handleChangeCheckBox('isTurnOnVerify', e)}
          />
        </td>
        <td>
          <CcCheckbox
            disabled={editable}
            checked={_.get(zone, 'isForce')}
            onChange={e => this.handleChangeCheckBox('isForce', e)}
          />
        </td>
        <td>
          <select
            className="form-control form-custom form-verify-face-mask"
            value={_.get(zone, 'timeRangeToVerify')}
            onChange={this.handleChangeTimeRangeToVerify}
            disabled={editable}
          >
            {this.state.timeRange.map(item => {
              let time = '';
              var hour = item.value / 60;
              var day = hour / 24;
              if (hour < 1) {
                time = `${item.value} ${I18n.t('generalSetting.minutes')}`;
              } else if (hour < 24) {
                time = hour === 1 ? `${hour} ${I18n.t('generalSetting.hour')}` : `${hour} ${I18n.t('generalSetting.hours')}`;
              } else if (hour >= 24) {
                time = hour === 24 ? `${day} ${I18n.t('generalSetting.day')}` : `${day} ${I18n.t('generalSetting.days')}`;
              };
              return (
                <option key={item.key} value={item.value}>{time}</option>
              )
            })}
          </select>
        </td>
        <td>
          <select
            className="form-control form-custom form-verify-face-mask"
            value={_.get(zone, 'skipVerifyTimes')}
            onChange={this.handleChangeSkipVerifyTimes}
            disabled={editable}
          >
            {this.state.skipVerifyTimes.map(item => {
              let times = '';
              if (item.value <= 1) {
                times = `${item.value} ${I18n.t('generalSetting.time')}`;
              } else {
                times = `${item.value} ${I18n.t('generalSetting.times')}`;
              };
              return (
                <option key={item.key} value={item.value}>{times}</option>
              )
            })}
          </select>
        </td>
        <td>
          <CcCheckbox
            disabled={editable}
            checked={_.get(zone, 'needToVerify.openApp')}
            onChange={e => this.handleChangeCheckBox('openApp', e)}
          />
        </td>
        <td>
          <CcCheckbox
            disabled={editable}
            checked={_.get(zone, 'needToVerify.startReservation')}
            onChange={e => this.handleChangeCheckBox('startReservation', e)}
          />
        </td>
      </tr>
    );
  }
}
