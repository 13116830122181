import { topDriversApi, exportTopDriversApi } from '../constants/ApiConfigs';
import { callApi } from '../utils/apiUtils';

function topDrivers(options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(Object.assign({}, options))
  };
  return callApi(topDriversApi, config, null, null, null, true);
}

function exportTopDrivers(options = {}) {
  const config = {
    method: 'post',
    body: JSON.stringify(Object.assign({}, options))
  };
  return callApi(exportTopDriversApi, config, null, null, null, true);
}

export { topDrivers, exportTopDrivers };
