import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import CurrencyFormatter from 'currency-formatter';
import { Translate, I18n } from 'react-redux-i18n';
import QuestionCircleTooltip from "../../components/questionCircleTooltip/QuestionCircleTooltip";
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Modal,
  Image,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import _ from 'lodash';
import { Promise } from 'bluebird';
import { parsePhoneNumber } from 'libphonenumber-js';

import './addEdit.scss';
import {
  findOneVehicle,
  fetchVehicleByCompany,
  findOneDriver,
  checkExistDriverPhone,
  operateDriver,
  resestPassword,
  getBankAccountRequireField,
  getEmergencyContactDriver,
  removeBankInfo,
  setDocumentsMissing,
} from '../../actions/driverAction';
import {
  loadMoreVehicles,
  loadMoreVehiclesByType,
} from '../../actions/commonDataAction';

import { updateDriverInfo } from '../../actions/mapActions';
import * as loadingBarActions from './../../actions/loadingBarActions';
import * as settingActions from './../../actions/settingActions';
import {
  fetchRelativeInfo,
  fetchCompanies,
} from '../../actions/commonDataAction';
import Confirm from '../../components/confirm/Confirm';
import Balance from './Balance';
import CashWalletBalance from './CashWalletBalance';
import {
  avatar,
  paymentGetwaySettlement,
  DriverType,
  defaultCommissionFleet,
  europeCountry,
  SettelementSetting,
} from '../../constants/commondata';

import DateTime from '../../components/dateTime/DateTime';
import FormGroupTitle from '../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox';
import {
  MultiSelectCommonFunc,
  Validation,
  CCLiteCommonFunc,
  trimPhoneNumber,
  getSuppilerPermission,
} from '../../utils/commonFunctions';
import { Validator, ValidCase } from '../../components/validator';
import Operation from './components/Operation';
import Note from './components/Note';

import BankAccount from './components/BankAccount';
import UploadFile from '../../components/upload/upload';
import UploadAvatarTemplate from './components/UploadAvatarTemplate';
import IntlTelInputApp from '../../components/intlTelInputCustome/IntlTelInputApp';
import { DriverInfoFields } from './DynamicField';
import DriverEmergencyContacts from './components/DriverEmergencyContact';
import { BsQuestionCircleFill } from 'react-icons/bs';

var ReactScriptLoaderMixin =
  require('react-script-loader').ReactScriptLoaderMixin;
require('react-intl-tel-input/dist/libphonenumber.js');
require('react-intl-tel-input/dist/main.css');

const reg = /^\d+$/;
const FILE_ACCEPTED =
  '.jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx, .pdf, .7z, .rar, .zip';
const IMAGE_ACCEPTED = '.jpg, .jpeg, .png';
const MAX_FILE_SIZE = 5000000;
const LIMIT_LIST_VEHICLE = 50;
const year = new Date().getFullYear();
const tooltip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Force_meter_tooltip" />
  </Tooltip>
);
const rideSharingToolTip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Ride_sharing_tooltip" />
  </Tooltip>
);
const marketplaceToolTip = (
  <Tooltip id="tooltip">
    <Translate value="driver.Market_place_tooltip" />
  </Tooltip>
);

const BankAccountFields = [
  'address',
  'city',
  'state',
  'zipcode',
  'birthday',
  'email',
];
const MolpayRequireFields = [
  'email',
  'driverId',
  'address',
  'city',
  'state',
  'zipcode',
  'birthday',
];
let yearsElement = [];
let phone;
let isVhicleNoType = false;
let driverIcEditable = false;
const saveOptions = [
  {
    key: 'active',
    value: 'active',
  },
  {
    key: 'inactive',
    value: 'inactive',
  },
  {
    key: 'inReviewInProgress',
    value: 'inReviewInProgress',
  },
];
class AddEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: null,
      isExistingCar: true,
      vehicleByCompany: {
        limit: LIMIT_LIST_VEHICLE,
        page: 0,
        total: 0,
        search: '',
        // companyId: null,
        list: [],
      },
      vehicleDetails: {},
      carMakeList: [],
      passengers: 0,
      luggage: 0,
      caseNumber: '',
      vhcPhone: '',
      year,
      commission: 0,
      isEditForm: false,
      stripeAccountId: '',
      stripeAccountIdVerified: '',
      detailItem: {
        driverInfo: {
          company: {},
          city: {},
          idType: '',
          driverType: DriverType.OwnerOperator,
          driverDocument: {},
          commissionByCurrencies: [],
          commissionType: 'percent',
          commissionDriverType: 'default',
          commissionDriverValue: [],
          commissionCompanyType: 'default',
          commissionCompanyValue: [],
          creditBalances: [],
          statusReview: 'completed',
          isBankAccountOwner: true,
          hasTopUpOtherDriver: false,
        },
        shift: {},
        phone: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        rideSharing: false,
        marketplace: false,
        marketplaceSettingMode: 'default',
        maximumAcceptReservationPerDay: 0,
        maximumAcceptReservation: 0,
      },
      originDriverType: '',
      driverInfoDynamic: {},
      driverDocument: {},
      driverDocumentExpiry: [],
      driverFiledList: [],
      editable: false,
      isShowDriverDeposit: false,
      isShowCashWalletBalance: false,
      documentDeleteList: [],
      isView: true,
      licensePlateValidation: true,
      valid: {},
      vhcPhoneValidation: true,
      isSubmited: false,
      bankRequirement: {
        driverId: false,
        driverIc: false,
        accountName: false,
        address: false,
        bankName: false,
        bankNumber: false,
        birthday: false,
        checkNumber: false,
        city: false,
        routingNumber: false,
        ssn: false,
        state: false,
        verificationDocument: false,
        verificationDocumentBack: false,
        zipcode: false,
        IFSCCode: false,
      },
      bankInfoList: [],
      relativeInfoData: {},
      phoneFieldValidation: {},
      isVehicleDidntAssign: false,
      visible: props.route.path !== '/driver',
      listShifts: [],
      statusDriver: 'active',
      documentsOrigin: [],
      documentsMissing: [],
    };
    this.handleDriverTypeChange = this.handleDriverTypeChange.bind(this);
    this.handleVhcPhoneChange = this.handleVhcPhoneChange.bind(this);
    this.isValidImageFile = this.isValidImageFile.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNameOfBankChange = this.handleNameOfBankChange.bind(this);
    this.handleNameOfAccountChange = this.handleNameOfAccountChange.bind(this);
    this.handleRTBNumberChange = this.handleRTBNumberChange.bind(this);
    this.handleAccountNumberChange = this.handleAccountNumberChange.bind(this);
    this.handleSwiftCodeNumberChange =
      this.handleSwiftCodeNumberChange.bind(this);
    this.handleAvatarChange = this.handleAvatarChange.bind(this);
    this.handleSSNChange = this.handleSSNChange.bind(this);
    this.handleSortCodeChange = this.handleSortCodeChange.bind(this);
    this.handleCheckNumberChange = this.handleCheckNumberChange.bind(this);
    this.handleSaveDriverClick = this.handleSaveDriverClick.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChangeBalanceClick = this.handleChangeBalanceClick.bind(this);
    this.handleVehicleOwnerChange = this.handleVehicleOwnerChange.bind(this);
    this.handleClickShowCashWalletBalance =
      this.handleClickShowCashWalletBalance.bind(this);
    this.handleCarMakeChange = this.handleCarMakeChange.bind(this);
    this.handleCarModelChange = this.handleCarModelChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handlePassengersChange = this.handlePassengersChange.bind(this);
    this.handleLuggageChange = this.handleLuggageChange.bind(this);
    this.handleLicenseExpiryChange = this.handleLicenseExpiryChange.bind(this);
    this.handleExistingCarChange = this.handleExistingCarChange.bind(this);
    this.handleShiftChange = this.handleShiftChange.bind(this);
    this.handleZoneChange = this.handleZoneChange.bind(this);
    this.handleCommissionChange = this.handleCommissionChange.bind(this);
    this.handleCreditBalanceSuccess =
      this.handleCreditBalanceSuccess.bind(this);
    this.handleChangeCashBalanceSuccess =
      this.handleChangeCashBalanceSuccess.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleTerminalIdChange = this.handleTerminalIdChange.bind(this);
    this.handleAuthKeyChange = this.handleAuthKeyChange.bind(this);
    this.handleForceMeterChange = this.handleForceMeterChange.bind(this);
    this.handleTopDriverChange = this.handleTopDriverChange.bind(this);
    this.isBirthDayValid = this.isBirthDayValid.bind(this);
    this.handleCheckPolicyNumber = this.handleCheckPolicyNumber.bind(this);
    this.handleCheckEffectiveDate = this.handleCheckEffectiveDate.bind(this);
    this.handleCheckExpiredDate = this.handleCheckExpiredDate.bind(this);
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.isDisableExpiryDate = this.isDisableExpiryDate.bind(this);
    this.isShowDownloadDocument = this.isShowDownloadDocument.bind(this);
    this.handleRemoveSelectedDriverDocument =
      this.handleRemoveSelectedDriverDocument.bind(this);
    this.isShowIconDocumentRemove = this.isShowIconDocumentRemove.bind(this);
    this.handleRideSharingChange = this.handleRideSharingChange.bind(this);
    this.handleMarketplaceChange = this.handleMarketplaceChange.bind(this);
    this.handlecaseNumberChange = this.handlecaseNumberChange.bind(this);
    this.validatorCallback = this.validatorCallback.bind(this);
    this.checkTabError = this.checkTabError.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.validateLicensePlate = this.validateLicensePlate.bind(this);
    this.isBankRequire = this.isBankRequire.bind(this);
    this.isRideSharingEnabled = this.isRideSharingEnabled.bind(this);
    this.sendOperateDriverRequest = this.sendOperateDriverRequest.bind(this);
    this.uploadStripeVerificationDocument =
      this.uploadStripeVerificationDocument.bind(this);
    this.isChangeZipcode = false;
    this.handleDocumentKeyChange = this.handleDocumentKeyChange.bind(this);
  }

  componentDidMount() {
    const {
      commonData,
      auth,
      fetchCompanies,
      fetchRelativeInfo,
      getBankAccountRequireField,
      findOneDriver,
      params,
      route,
      router,
      settingActions,
    } = this.props;
    const fleetId = auth.selectedFleet.fleetId;
    let visible = false;
    const defaultZone = _.find(
      commonData.mapZone || [],
      (z) => z.isDefault === true
    );

    this.initCommonData();
    let promiseTask = [fetchCompanies(fleetId).then((data) => data)];

    if (!commonData.relativeInfo) {
      promiseTask.push(fetchRelativeInfo({ fleetId }));
    } else {
      promiseTask.push({
        ok: true,
        res: commonData.relativeInfo,
      });
    }

    const fleet = auth.selectedFleet;
    // set Stripe is the default gateway
    let gateway = '';

    if (fleet.drvPayout.enable) {
      gateway = fleet.drvPayout.gateway;
    } else if (fleet.creditConfig.configGateway) {
      gateway = fleet.creditConfig.configGateway.gateway;
    }

    const enableStripeConnect = fleet.drvPayout && fleet.drvPayout.activeConnectAccount || false;
    this.setState({
      enableStripeConnect: enableStripeConnect
    })

    if (!gateway) gateway = 'Stripe';

    promiseTask.push(
      getBankAccountRequireField({
        fleetId,
        gateway,
      })
    );

    if (params.driverId) {
      promiseTask.push(
        findOneDriver({
          userId: params.driverId,
          fleetId,
        })
      );
      visible = true;
    } else {
      promiseTask.push(null);
    }

    let editable = this.state.editable,
      isView = this.state.isView,
      licensePlateValidation = this.state.licensePlateValidation,
      bankRequirement = Object.assign({}, this.state.bankRequirement),
      driverInfoDynamic = Object.assign({}, this.state.driverInfoDynamic);
    let bankInfoList = Object.assign({}, this.state.bankInfoList);

    if (route && route.path !== '/driver/view/:driverId') {
      editable = true;
      isView = false;
    }

    if (auth.selectedFleet && auth.selectedFleet.licensePlate) {
      licensePlateValidation = true;
    }

    promiseTask.push(settingActions.getCarMakeList({ fleetId }));

    Promise.all(promiseTask)
      .spread(
        (
          companiesData = {},
          rltData,
          bankRequirementData,
          driverData,
          carMakeResponse
        ) => {
          let relativeInfoData =
            rltData && rltData.ok && rltData.res ? rltData.res : null;
          if (!relativeInfoData) {
            this.context.notification(
              'error',
              I18n.t(
                'errors.' +
                  (relativeInfoData.error && relativeInfoData.error.errorCode)
              )
            );
            return router.push({ pathname: '/driver' });
          }

          if (
            bankRequirementData &&
            bankRequirementData.res &&
            bankRequirementData.res.returnCode == 200
          ) {
            bankRequirement = bankRequirementData.res.response;
            if (bankRequirementData.res.response.driverIc) {
              bankRequirement.driverId =
                bankRequirementData.res.response.driverIc;
            }
            bankInfoList = _.get(
              bankRequirementData,
              'res.response.bankInfo',
              []
            );
          }

          const carMakeList =
            _.get(carMakeResponse, 'res', null) ||
            _.get(relativeInfoData, 'makes', null) ||
            [];

          // ReOrder Shifts
          const shiftList = _.get(relativeInfoData, 'shifts', null) || [];
          let listShifts = [];
          if (shiftList) {
            shiftList.forEach((e) => {
              if (e.name === 'Full') {
                listShifts.unshift(e);
              } else {
                listShifts.push(e);
              }
            });
          }

          if (driverData) {
            if (driverData.ok && driverData.res) {
              driverInfoDynamic = this.parseDriverInfo(
                driverData.res,
                relativeInfoData.fields
              );

              let statusDriver = driverData.res.isActive
                ? 'active'
                : driverData.res.driverInfo.isActivate
                ? 'inactive'
                : 'inReviewInProgress';

              let commissionByCurrencies =
                driverData.res.driverInfo.commissionByCurrencies;
              let commission = this.state.commission;

              if (commissionByCurrencies && commissionByCurrencies.length > 0) {
                commission = commissionByCurrencies[0].commissionValue;
              }

              if (
                driverData.res.driverInfo &&
                !driverData.res.driverInfo.commissionType
              )
                driverData.res.driverInfo.commissionType = 'percent';

              phone = driverData.res.phone;

              // SL-5266
              let newDriverData = Object.assign({}, driverData.res);
              let selectedCurrency =
                defaultZone && defaultZone.currency
                  ? defaultZone.currency.iso
                  : '';

              if (
                driverData.res.driverInfo.zoneId &&
                driverData.res.driverInfo.zoneId.length
              ) {
                const parseZone = _.map(
                  driverData.res.driverInfo.zoneId,
                  (zId) =>
                    _.find(commonData.mapZone, (i) => i._id === zId) || {}
                );
                const firstZone = parseZone[0];

                if (!firstZone.currency) {
                  newDriverData.driverInfo.zoneId = [];
                } else if (parseZone.length === 1) {
                  selectedCurrency = firstZone.currency.iso;
                } else {
                  let otherCurrency = _.find(
                    parseZone.slice(1),
                    (z) =>
                      !z.currency || z.currency.iso !== firstZone.currency.iso
                  );

                  if (!otherCurrency) {
                    selectedCurrency = firstZone.currency.iso;
                  } else {
                    newDriverData.driverInfo.zoneId = [];
                  }
                }
              } else {
                newDriverData.driverInfo.zoneId = [];
              }
              var nostes = newDriverData.driverInfo.notes;
              if (
                newDriverData.driverInfo.notes != null &&
                newDriverData.driverInfo.notes.length != 0
              ) {
                nostes = newDriverData.driverInfo.notes
                  .split(',')
                  .map(function (m) {
                    return { name: m, id: m };
                  });
                newDriverData.driverInfo.notes = nostes;
              }

              if (newDriverData.shift && !newDriverData.shift._id) {
                newDriverData.shift = {
                  _id: listShifts[0]._id,
                };
              }
              const achToken = _.get(driverData, 'res.driverInfo.achToken')

              this.setState(
                {
                  carMakeList,
                  stripeAccountId: enableStripeConnect && achToken ? achToken : '',
                  accountConnectedStripe: enableStripeConnect && achToken,
                  detailItem: newDriverData,
                  phoneFrefix: newDriverData.phone,
                  vehicleByCompany: {
                    ...this.state.vehicleByCompany,
                    vehicleType: driverData.res.driverInfo?.carType?._id,
                    companyId: driverData.res.driverInfo?.company?.companyId,
                  },
                  documentExpiry: driverData.res.documentExpiry,
                  loadData: driverData.res.documentExpiry,
                  originDriverType: driverData.res.driverInfo.driverType,
                  commission,
                  driverInfoDynamic,
                  selectedCurrency,
                  editable: editable,
                  isView: isView,
                  licensePlateValidation: licensePlateValidation,
                  bankRequirement: bankRequirement,
                  carType: _.get(driverData, 'res.driverInfo.carType._id'),
                  visible,
                  relativeInfoData,
                  bankInfoList,
                  listShifts,
                  statusDriver,
                },
                () => {
                  this.initDriverDocumentExpiryList(
                    driverData.res.documentExpiry,
                    relativeInfoData
                  );
                  this.initDriverFiledList(relativeInfoData);
                  this.handleCompanyChange(
                    _.get(driverData.res, 'driverInfo.company.companyId', '')
                  );
                  this.handleLicensePlateComboChange(
                    _.get(driverData, 'res.driverInfo.vehicleId', '')
                  );

                  this.initDocumentsOrigin(
                    driverData.res.driverInfo,
                    driverData.res.documentExpiry,
                    relativeInfoData.fields
                  );
                }
              );
            } else {
              this.context.notification(
                'error',
                I18n.t('errors.' + driverData.error.errorCode)
              );
              router.push({ pathname: '/driver' });
            }
          } else {
            driverInfoDynamic = this.parseDriverInfo(
              this.state.detailItem,
              relativeInfoData.fields
            );

            this.setState(
              {
                carMakeList,
                selectedCurrency:
                  defaultZone && defaultZone.currency
                    ? defaultZone.currency.iso
                    : '',
                detailItem: {
                  ...this.state.detailItem,
                  driverInfo: {
                    ...this.state.detailItem.driverInfo,
                    zoneId: [],
                  },
                  shift: {
                    _id: listShifts[0]?._id,
                  },
                },
                editable,
                isView,
                licensePlateValidation,
                bankRequirement,
                driverInfoDynamic,
                relativeInfoData,
                bankInfoList,
                listShifts,
              },
              () => {
                this.handleLicensePlateComboChange(null);
                this.initDriverDocumentExpiryList(undefined, relativeInfoData);
                this.initDriverFiledList(relativeInfoData);
              }
            );
          }
        }
      )
      .catch((error) => {
        console.log('============error=========', error);
      });
  }

  getCompanyDefault = () => {
    if(this.state?.detailItem?.driverInfo?.company?.companyId) {
      return {
        companyId: this.state?.detailItem?.driverInfo?.company?.companyId
      }
    }
    let company = null
    if(getSuppilerPermission(this.props.auth)) {
      company = {
        companyId: this.props.auth?.user?.roles?.companyId
      }
    }
    return company
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params, visible } = this.props;
    const { params: nextParams } = nextProps;
    if (nextParams.driverId && nextParams.driverId !== params.driverId) {
      this.initDriverInfo(nextProps);
    }

    if (nextProps.visible !== visible) {
      // handle hide dialog
      if (nextProps.visible) {
        this.initDefaultData();
      } else {
        this.setState({ visible: nextProps.visible });
      }
    }
    // if (!this.isGetRelativeInfo && nextProps.commonData.relativeInfo) {
    //   this.isGetRelativeInfo = true;
    //   this.initDriverDocumentExpiryList(
    //     this.state.loadData,
    //     nextProps.commonData.relativeInfo
    //   );
    //   let driverInfoDynamic = this.parseDriverInfo(
    //     this.state.detailItem,
    //     nextProps.commonData.relativeInfo.fields
    //   );
    //   this.setState({ driverInfoDynamic });
    // }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("componentDidUpdate ", prevProps, prevState)
  // }

  initDriverInfo = (nextProps) => {
    const { findOneDriver: getDetailDriver, auth, commonData } = this.props;
    const { params, actionType } = nextProps;
    const { relativeInfoData } = this.state;
    const { fleetId } = auth.selectedFleet;
    const defaultZone = _.find(
      commonData.mapZone || [],
      (z) => z.isDefault === true
    );

    let editable = this.state.editable;
    let isView = this.state.isView;
    let licensePlateValidation = this.state.licensePlateValidation;
    let driverInfoDynamic = Object.assign({}, this.state.driverInfoDynamic);

    editable = actionType !== 'view';
    isView = actionType === 'view';

    getDetailDriver({
      userId: params.driverId,
      fleetId,
    }).then((driverData) => {
      if (driverData.ok && driverData.res) {
        driverInfoDynamic = this.parseDriverInfo(
          driverData.res,
          relativeInfoData.fields
        );

        let commissionByCurrencies =
          driverData.res.driverInfo.commissionByCurrencies;
        let commission = this.state.commission;

        if (commissionByCurrencies && commissionByCurrencies.length > 0) {
          commission = commissionByCurrencies[0].commissionValue;
        }

        if (
          driverData.res.driverInfo &&
          !driverData.res.driverInfo.commissionType
        )
          driverData.res.driverInfo.commissionType = 'percent';

        phone = driverData.res.phone;

        // SL-5266
        let newDriverData = Object.assign({}, driverData.res);
        let selectedCurrency =
          defaultZone && defaultZone.currency ? defaultZone.currency.iso : '';

        if (
          driverData.res.driverInfo.zoneId &&
          driverData.res.driverInfo.zoneId.length
        ) {
          const parseZone = _.map(
            driverData.res.driverInfo.zoneId,
            (zId) => _.find(commonData.mapZone, (i) => i._id === zId) || {}
          );
          const firstZone = parseZone[0];

          if (!firstZone.currency) {
            newDriverData.driverInfo.zoneId = [];
          } else if (parseZone.length === 1) {
            selectedCurrency = firstZone.currency.iso;
          } else {
            let otherCurrency = _.find(
              parseZone.slice(1),
              (z) => !z.currency || z.currency.iso !== firstZone.currency.iso
            );

            if (!otherCurrency) {
              selectedCurrency = firstZone.currency.iso;
            } else {
              newDriverData.driverInfo.zoneId = [];
            }
          }
        } else {
          newDriverData.driverInfo.zoneId = [];
        }
        var nostes = newDriverData.driverInfo.notes;
        if (
          newDriverData.driverInfo.notes != null &&
          newDriverData.driverInfo.notes.length != 0
        ) {
          nostes = newDriverData.driverInfo.notes.split(',').map(function (m) {
            return { name: m, id: m };
          });
          newDriverData.driverInfo.notes = nostes;
        }
        this.setState(
          {
            detailItem: newDriverData,
            vehicleByCompany: {
              ...this.state.vehicleByCompany,
              vehicleType: driverData.res.driverInfo?.carType?._id,
              companyId: driverData.res.driverInfo?.company?.companyId,
            },
            documentExpiry: driverData.res.documentExpiry,
            originDriverType: driverData.res.driverInfo.driverType,
            commission,
            driverInfoDynamic,
            selectedCurrency,
            carType: _.get(driverData, 'res.driverInfo.carType._id'),
            visible: true,
            editable,
            isView,
          },
          () => {
            this.initDriverDocumentExpiryList(
              driverData.res.documentExpiry,
              relativeInfoData
            );

            this.handleCompanyChange(
              _.get(driverData.res, 'driverInfo.company.companyId', '')
            );
            this.handleLicensePlateComboChange(
              _.get(driverData, 'res.driverInfo.vehicleId', '')
            );
          }
        );
      }
    });
  };

  initDefaultData = () => {
    const detailItem = {
      driverInfo: {
        company: this.getCompanyDefault(),
        city: {},
        driverType: DriverType.IndividualDriver,
        driverDocument: {},
        commissionByCurrencies: [],
        commissionType: 'percent',
        creditBalances: [],
      },
      shift: {},
      phone: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      rideSharing: false,
      marketplace: false,
    };
    const { relativeInfoData, driverFiledList } = this.state;

    const driverInfoDynamic = this.parseDriverInfo(detailItem, driverFiledList);

    this.setState(
      {
        confirm: null,
        isExistingCar: true,
        vehicleDetails: {},
        passengers: 0,
        luggage: 0,
        caseNumber: '',
        vhcPhone: '',
        commission: 0,
        detailItem: {
          ...detailItem,
          driverInfo: {
            ...detailItem.driverInfo,
            zoneId: [],
          },
        },
        driverInfoDynamic,
        visible: true,
        editable: true,
        driverDocument: {},
        driverDocumentExpiry: [],
        isShowDriverDeposit: false,
        documentDeleteList: [],
        isView: true,
        licensePlateValidation: true,
        valid: {},
        vhcPhoneValidation: true,
        isSubmited: false,
        phoneFieldValidation: {},
        isVehicleDidntAssign: false,
      },
      () => {
        this.handleLicensePlateComboChange(null);
        this.initDriverDocumentExpiryList(undefined, relativeInfoData);
      }
    );
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.params.driverId) {
  //     if (!nextState.detailItem._id) {
  //       return false;
  //     }
  //   }
  //   if (!nextProps.auth.selectedFleet || !nextProps.commonData.relativeInfo) {
  //     return false;
  //   }
  //   return true;
  // }

  initCommonData = () => {
    const { auth = {} } = this.props
    const generalSetting = _.get(auth, 'selectedFleet.generalSetting', {});
    const { vehicleAgeLimit = {}, disableMarketPlace = false } = generalSetting 
    // SL-29287 User can select next year when input 'Vehicle year' for car type.
    const currentYear = new Date().getFullYear() + 1;
    let yearsLimit = 1999;
    if (vehicleAgeLimit.enable) {
      yearsLimit = currentYear - vehicleAgeLimit.years;
    }
    for (let i = currentYear; i > yearsLimit; i -= 1) {
      yearsElement.push(i);
    }
    const company = this.getCompanyDefault()
    if(!disableMarketPlace) {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          marketplace: !disableMarketPlace,
          driverInfo: {
            ...this.state?.detailItem?.driverInfo,
            company: company,
          }
        }
      }, () => {
        this.getVehiclesByType(company?.companyId)
      })
    }
  };

  parseDriverInfo = (data, fields) => {
    const s3Host = this.props.auth.selectedFleet.s3Host || '';

    let driverInfo = {};
    _.forEach(fields, (item) => {
      //if (item.key !== 'company' && (this.state.driverType === DriverType.IndividualDriver && item.availableIndividualCar) || (this.state.driverType === DriverType.OwnerOperator && item.availableCompanyCar)) {
      if (item.key !== 'company') {
        let valueKey = DriverInfoFields[item.key]
          ? DriverInfoFields[item.key].value
          : item.key.includes('backup')
          ? 'driverInfo.' + item.key
          : item.key;
        driverInfo[item.key] = _.get(data, valueKey, '');
        if (
          item.key.includes('backup') &&
          item.type === 'Date' &&
          driverInfo[item.key]
        ) {
          driverInfo[item.key] = moment(driverInfo[item.key]).format(
            'MM/DD/YYYY'
          );
        }
        if (
          item.key.includes('backup') &&
          item.type === 'File' &&
          driverInfo[item.key]
        ) {
          driverInfo[item.key] = s3Host + driverInfo[item.key];
        }
      }
    });
    return driverInfo;
  };

  initDocumentsOrigin = (driverInfo, documents, fields) => {
    const s3Host = this.props.auth.selectedFleet.s3Host || '';
    const { documentsMissing } = this.state.detailItem.driverInfo;
    let documentsOrigin = documentsMissing || [];
    documents.forEach((doc) => {
      let index = documentsOrigin.findIndex((e) => e.key === doc.documentKey);
      if (index !== -1) {
        documentsOrigin[index] = {
          key: doc.documentKey,
          originalName: doc.documentFileName || '',
          originalURL:
            (doc.documentUrl && doc.documentUrl.replace(s3Host, '')) || '',
        };
      } else {
        documentsOrigin.push({
          key: doc.documentKey,
          originalName: doc.documentFileName || '',
          originalURL:
            (doc.documentUrl && doc.documentUrl.replace(s3Host, '')) || '',
        });
      }
    });

    if (driverInfo.verificationDocument) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'verificationDocument'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'verificationDocument',
          originalName: driverInfo.verificationDocumentName || '',
          originalURL: driverInfo.verificationDocument.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'verificationDocument',
          originalName: driverInfo.verificationDocumentName || '',
          originalURL: driverInfo.verificationDocument.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.verificationDocumentBack) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'verificationDocumentBack'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'verificationDocumentBack',
          originalName: driverInfo.verificationDocumentBackName || '',
          originalURL: driverInfo.verificationDocumentBack.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'verificationDocumentBack',
          originalName: driverInfo.verificationDocumentBackName || '',
          originalURL: driverInfo.verificationDocumentBack.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.additionalDocument) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'additionalDocument'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'additionalDocument',
          originalName: driverInfo.additionalDocumentName || '',
          originalURL: driverInfo.additionalDocument.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'additionalDocument',
          originalName: driverInfo.additionalDocumentName || '',
          originalURL: driverInfo.additionalDocument.replace(s3Host, ''),
        });
      }
    }

    if (driverInfo.additionalDocumentBack) {
      let index = documentsOrigin.findIndex(
        (e) => e.key === 'additionalDocumentBack'
      );
      if (index !== -1) {
        documentsOrigin[index] = {
          key: 'additionalDocumentBack',
          originalName: driverInfo.additionalDocumentBackName || '',
          originalURL: driverInfo.additionalDocumentBack.replace(s3Host, ''),
        };
      } else {
        documentsOrigin.push({
          key: 'additionalDocumentBack',
          originalName: driverInfo.additionalDocumentBackName || '',
          originalURL: driverInfo.additionalDocumentBack.replace(s3Host, ''),
        });
      }
    }

    fields.forEach((item) => {
      if (
        item.key.includes('backup') &&
        item.type === 'File' &&
        driverInfo[item.key]
      ) {
        let keyName = item.key + 'Name';
        let index = documentsOrigin.findIndex((e) => e.key === item.key);
        if (index !== -1) {
          documentsOrigin[index] = {
            key: item.key,
            originalName: driverInfo[keyName] || '',
            originalURL: driverInfo[item.key] || '',
          };
        } else {
          documentsOrigin.push({
            key: item.key,
            originalName: driverInfo[keyName] || '',
            originalURL: driverInfo[item.key] || '',
          });
        }
      }
    });
    this.setState({ documentsOrigin });
  };

  handleChangeStatus(e) {
    if (this.props.params && this.props.params.driverId) {
      this.props.router.push({
        pathname: '/driver/edit/' + this.props.params.driverId,
      });
    }
    this.setState({ editable: true, isView: false, isEditForm: true });
  }

  handleConfirmCloseClick() {
    this.setState({
      confirm: null,
      isShowDriverDeposit: false,
      isShowCashWalletBalance: false,
      vhcPhoneValidation: true,
    });
  }

  handleCreditBalanceSuccess(creditBalances) {
    let {
      detailItem: { driverWallet = {} },
      selectedCurrency,
    } = this.state;
    driverWallet.creditWallet = _.find(
      creditBalances,
      (o) => o.currencyISO === selectedCurrency
    );
    this.setState(
      {
        confirm: null,
        isShowDriverDeposit: false,
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            creditBalances,
          },
          driverWallet,
        },
      },
      () => {
        this.props.onUpdateDriver(
          { userId: this.state.detailItem.userId, driverWallet },
          true
        );
      }
    );
  }

  handleChangeCashBalanceSuccess(cashBalance) {
    let {
      detailItem: { driverWallet = {} },
    } = this.state;
    if (!cashBalance || !cashBalance.newBalance) return;
    if (driverWallet.cashWallet && driverWallet.cashWallet.currentBalance) {
      driverWallet.cashWallet.currentBalance = cashBalance.newBalance;
    } else {
      driverWallet = {
        cashWallet: {
          currentBalance: cashBalance.newBalance,
          currencyISO: cashBalance.currencyISO,
        },
      };
    }
    this.setState(
      {
        confirm: null,
        isShowCashWalletBalance: false,
        detailItem: {
          ...this.state.detailItem,
          driverWallet,
        },
      },
      () => {
        this.props.onUpdateDriver(
          { userId: this.state.detailItem.userId, driverWallet },
          true
        );
      }
    );
  }

  handleConfirmButtonClick(id) {
    switch (id) {
      case 'RESET_PASSWORD': {
        this.doResetPassword();
        break;
      }
      case 'REMOVE_BANK_INFOR': {
        this.doRemoveBankInfo();
        break;
      }
      default:
        break;
    }
  }

  doResetPassword = () => {
    this.props
      .resestPassword({
        userId: this.props.params.driverId,
        fleetId: this.props.auth.selectedFleet.fleetId,
      })
      .then((data) => {
        this.setState({ confirm: null });
        if (data.ok) {
          this.context.notification(
            'info',
            I18n.t('driver.Reset_password_success')
          );
        } else {
          this.context.notification('error', I18n.t('errors.undefined'));
        }
      });
  };

  handleResetPasswordClick() {
    this.setState({
      confirm: {
        id: 'RESET_PASSWORD',
        title: I18n.t('user.Reset_password'),
        body: I18n.t('user.CONFIRM_RESET_PASSWORD'),
        buttonTitle: 'OK',
      },
    });
  }

  handleDriverTypeChange(e) {
    let driverType = e.target.value;

    this.setState(
      {
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            driverType,
          },
          documentExpiry:
            driverType === this.state.originDriverType
              ? this.state.documentExpiry
              : [],
        },
        driverDocument: {},
        documentDeleteList: [],
        carType: '',
        valid: {},
        isSubmited: false,
      },
      () => {
        this.initDriverDocumentExpiryList(
          this.state.detailItem.documentExpiry,
          this.props.commonData.relativeInfo
        );
        this.initDriverFiledList(this.props.commonData.relativeInfo);
      }
    );
  }

  handleDriverInfoChange = (data, key) => {
    const { capitalLetter } = this.props.auth.selectedFleet;
    if (key === 'zipcode') {
      this.isChangeZipcode = true;
    }
    let value = data.value;
    if (
      capitalLetter &&
      capitalLetter.name.cc &&
      (key === 'firstName' || key === 'lastName')
    ) {
      value = value.toUpperCase();
    }
    this.setState({
      driverInfoDynamic: { ...this.state.driverInfoDynamic, [key]: value },
    });
  };

  handlePhoneFieldChange = (
    status,
    value,
    countryData,
    number,
    id,
    fieldKey,
    isBlur
  ) => {
    this.setState({
      driverInfoDynamic: {
        ...this.state.driverInfoDynamic,
        [fieldKey]: number,
      },
      phoneFieldValidation: {
        ...this.state.phoneFieldValidation,
        [fieldKey]: status,
      },
    });
  };

  handlePhoneChange = (
    status,
    value,
    countryData,
    number,
    id,
    isBlur,
    isDialCodeOnly
  ) => {
    // Retry validate phone number
    let newStatus = false;
    if (!status && number.length > 6 && number !== '+undefined') {
      const phoneNumber = parsePhoneNumber(number);
      if (phoneNumber) {
        newStatus = phoneNumber.isValid();
      }
    }

    if (isBlur) {
      if (!status) return false;
      let newPhone;
      if (
        countryData &&
        '+' + countryData.dialCode == trimPhoneNumber(number)
      ) {
        newPhone = '';
      } else {
        newPhone = trimPhoneNumber(number);
      }
      if (newPhone !== phone) {
        this.props
          .checkExistDriverPhone({
            fleetId: this.props.auth.selectedFleet.fleetId,
            phone: newPhone,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({ phoneExistValidation: data.res && !data.res.existed });
            }
          });
      }
    } else {
      this.setState({
        phoneExistValidation: true,
        phoneValidation: status ? status : newStatus,
        driverInfoDynamic: {
          ...this.state.driverInfoDynamic,
          phone: number,
        },
      });
    }
  };

  handleLinkChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          link: e.target.value,
        },
      },
    });
  }
  handleLinkChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          link: e.target.value,
        },
      },
    });
  }
  handleNotesChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          notes: e.target.value,
        },
      },
    });
  }

  isBirthDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  handleAvatarChange(data, error) {
    if (data) {
      this.setState({
        avatar: data.file,
        detailItem: { ...this.state.detailItem, avatarFile: data.result },
      });
    } else {
      if (error) this.context.notification('error', I18n.t('driver.' + error));
      this.setState({
        avatar: null,
        detailItem: { ...this.state.detailItem, avatarFile: null },
      });
    }
  }

  isValidImageFile(file) {
    let fileExtensions = IMAGE_ACCEPTED.split(', ');
    let fileName =
      file.name.substring(file.name.lastIndexOf('.'), file.name.length) ||
      file.name;
    if (fileExtensions.indexOf(fileName.toLowerCase()) === -1) {
      this.context.notification('error', I18n.t('driver.Invalid_file'));
      return false;
    } else if (file.size > MAX_FILE_SIZE) {
      this.context.notification(
        'error',
        I18n.t('driver.File_size_larger_than_5Mb')
      );
      return false;
    }
    return true;
  }

  handleVerificationDocumentChange = (data, error) => {
    if (error) {
      this.context.notification('error', I18n.t('driver.' + error));
    }
    this.setState({
      verificationDocument: data ? data.file : null,
    });
  };

  handleVerificationDocumentBackChange = (data, error) => {
    if (error) {
      this.context.notification('error', I18n.t('driver.' + error));
    }
    this.setState({
      verificationDocumentBack: data ? data.file : null,
    });
  };

  handleAdditionalDocumentChange = (data, error) => {
    if (error) {
      this.context.notification('error', I18n.t('driver.' + error));
    }
    this.setState({
      additionalDocument: data ? data.file : null,
    });
  };

  handleAdditionalDocumentBackChange = (data, error) => {
    if (error) {
      this.context.notification('error', I18n.t('driver.' + error));
    }
    this.setState({
      additionalDocumentBack: data ? data.file : null,
    });
  };

  handleRemoveBankDocumentChange = (key) => {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          [key]: '',
        },
      },
      [key]: '',
    });
  };

  handeChangeIsBankAccountOwner = (e) => {
    const value = e.target.value === 'true';
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          isBankAccountOwner: value,
        },
      },
    });
  };

  handleChangebeneficiary = (key, e) => {
    let value = e.target.value;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          [key]: value,
        },
      },
    });
  };

  handleDocumentChange = (data, error) => {
    if (error) {
      this.context.notification('error', I18n.t('driver.' + error));
    }
    // data: { result, file }
    this.setState({ document: data ? data.file : null });
  };

  handleDriverDocumentChange = (data, error, keyName) => {
    if (data) {
      let documentExpiry = Object.assign(
        [],
        this.state.detailItem.documentExpiry
      );
      _.remove(documentExpiry, (item) => item.documentKey === keyName);
      this.setState({
        driverDocument: { ...this.state.driverDocument, [keyName]: data.file },
        detailItem: {
          ...this.state.detailItem,
          documentExpiry,
        },
      });
    } else {
      if (error) {
        this.context.notification('error', I18n.t('driver.' + error));
      }
      this.handleRemoveCurrentSelectedDocumentExpiry(keyName);
    }
  };

  handleDocumentKeyChange = (value, document, index) => {
    let { driverDocumentExpiry } = this.state;
    if (!driverDocumentExpiry.length) {
      this.initDriverDocumentExpiryList(
        undefined,
        this.props.commonData.relativeInfo
      );
    }
    driverDocumentExpiry[index].documentId = value.target.value;
    this.setState({ driverDocumentExpiry });
  };

  handleDriverInfoBackupFieldChange = (data, error, keyName) => {
    if (data) {
      this.setState({
        driverInfoDynamic: {
          ...this.state.driverInfoDynamic,
          [keyName]: data.file,
        },
      });
    } else {
      if (error) {
        this.context.notification('error', I18n.t('driver.' + error));
        this.setState({
          driverInfoDynamic: {
            ...this.state.driverInfoDynamic,
            [keyName]: null,
          },
        });
      }
    }
  };

  handleRemoveDriverInfoBackupFieldChange = (keyName) => {
    let { driverInfoDynamic } = this.state;
    this.setState({
      driverInfoDynamic: {
        ...driverInfoDynamic,
        [keyName]: '',
      },
    });
  };

  handleRemoveCurrentSelectedDocumentExpiry = (keyName) => {
    document.getElementById(keyName).value = '';
    let { driverDocumentExpiry } = this.state;

    let expiryIndex = driverDocumentExpiry.findIndex((obj) => {
      return obj.documentKey == keyName;
    });
    if (expiryIndex >= 0) {
      driverDocumentExpiry[expiryIndex].expiredDate = undefined;
      driverDocumentExpiry[expiryIndex].documentUrl = undefined;
    }
    this.setState({
      driverDocument: {
        ...this.state.driverDocument,
        [keyName]: null,
        driverDocumentExpiry,
      },
    });
  };

  handleRemoveSelectedDriverDocument(keyName) {
    document.getElementById(keyName).value = '';
    let { documentDeleteList, driverDocumentExpiry, driverDocument } =
      this.state;
    let expiryIndex = driverDocumentExpiry.findIndex((obj) => {
      return obj.documentKey == keyName;
    });
    if (expiryIndex >= 0) {
      driverDocumentExpiry[expiryIndex].expiredDate = undefined;
      driverDocumentExpiry[expiryIndex].documentUrl = undefined;
    }
    documentDeleteList.push(keyName);
    this.setState({
      driverDocument: { ...this.state.driverDocument, [keyName]: null },
      documentDeleteList,
      driverDocumentExpiry,
    });
  }

  handleRemoveBankInfo = () => {
    this.setState({
      confirm: {
        id: 'REMOVE_BANK_INFOR',
        title: I18n.t('driver.REMOVE_BANKING_INFOR'),
        body: I18n.t('driver.CONFIRM_REMOVE_BANKING_INFOR'),
        buttonTitle: I18n.t('newbooking.Yes'),
        closeButtonText: I18n.t('newbooking.No'),
      },
    });
  };

  doRemoveBankInfo = () => {
    const {
      auth: {
        selectedFleet: { fleetId },
      },
      params: { driverId },
    } = this.props;
    this.props.removeBankInfo({ fleetId, driverId }).then(({ res }) => {
      const detailItem = _.assign({}, this.state.detailItem);
      const bankInfo = {
        nameOfBank: '',
        nameOfAccount: '',
        routingNumber: '',
        accountNumber: '',
        checkNumber: '',
        ssn: '',
        swiftCodeNumber: '',
        verificationDocument: '',
        additionalDocument: '',
        verificationDocumentBack: '',
        additionalDocumentBack: '',
        isBankVerified: false,
      };
      detailItem.driverInfo = _.assign({}, detailItem.driverInfo, bankInfo);
      detailItem.achTokenVerified = false;
      this.setState({ detailItem, confirm: null });
    });
  };

  isShowIconDocumentRemove(d) {
    let isShowDocument = false;
    const { documentDeleteList, detailItem, editable } = this.state;
    if (
      detailItem &&
      detailItem.documentExpiry &&
      detailItem.documentExpiry.length > 0
    ) {
      let doc = detailItem.documentExpiry.find(
        (obj) => obj.documentKey == d.keyName && obj.documentUrl
      );
      if (doc) {
        let deleted = documentDeleteList.find((obj) => obj == doc.documentKey);
        if (!deleted) {
          isShowDocument = true;
        }
      }
    }
    return (
      (editable && this.state.driverDocument[d.keyName] != null) ||
      (editable && isShowDocument)
    );
  }

  isShowDownloadDocument(d) {
    let result = true;
    const { documentDeleteList } = this.state;
    //check if there is delete item
    if (!d) return false;
    if (documentDeleteList && documentDeleteList.length > 0) {
      documentDeleteList.map((obj) => {
        if (d.documentKey == obj) {
          result = false;
        }
      });
    }
    return result;
  }

  isDisableExpiryDate(keyName) {
    //check if there is a document from server
    const { detailItem, documentDeleteList } = this.state; //.documentExpiry
    if (this.state.driverDocument[keyName]) return false;
    if (
      detailItem &&
      detailItem.documentExpiry &&
      detailItem.documentExpiry.length > 0
    ) {
      let doc = detailItem.documentExpiry.find(
        (obj) => obj.documentKey == keyName && obj.documentUrl
      );
      if (doc) {
        let deleted = documentDeleteList.find((obj) => obj == doc.documentKey);
        if (deleted) return true || !this.state.driverDocument[keyName];
      }

      if (doc) return false;
    }
    //check if user insert file
    return !this.state.driverDocument[keyName];
  }

  handleIFSCCodeChange = (e) => {
    let IFSCCode = e.target.value;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          IFSCCode: IFSCCode,
        },
      },
    });
  };

  handleNameOfBankChange(e) {
    let nameOfBank = e.target.value;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          nameOfBank: nameOfBank,
        },
      },
    });
  }

  handleNameOfAccountChange(e) {
    let nameOfAccount = e.target.value;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          nameOfAccount: nameOfAccount,
        },
      },
    });
  }

  handleRTBNumberChange(routingNumber) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          routingNumber,
        },
      },
    });
  }

  handleAccountNumberChange(e) {
    let accountNumber = e.target.value;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          accountNumber: accountNumber,
        },
      },
    });
  }

  handleSSNChange(e) {
    let ssn = e.target.value;
    if (ssn.length <= 50) {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: { ...this.state.detailItem.driverInfo, ssn: ssn },
        },
      });
    }
  }

  handleSortCodeChange(e) {
    let sortCode = e.target.value;
    if (reg.test(sortCode) || sortCode.trim() === '') {
      if (sortCode.length <= 50) {
        this.setState({
          detailItem: {
            ...this.state.detailItem,
            driverInfo: { ...this.state.detailItem.driverInfo, sortCode },
          },
        });
      }
    }
  }

  handleCheckNumberChange(e) {
    if (Validation.isDigitOnly(e.target.value) || e.target.value === '') {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            checkNumber: e.target.value,
          },
        },
      });
    }
  }

  handleSwiftCodeNumberChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          swiftCodeNumber: e.target.value,
        },
      },
    });
  }

  handleChangeBalanceClick(e) {
    this.setState({
      isShowDriverDeposit: true,
    });
  }

  handleClickShowCashWalletBalance(e) {
    this.setState({
      isShowCashWalletBalance: true,
    });
  }

  handleCheckPolicyNumber(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          policyNumber: e.target.value,
        },
      },
    });
  }

  handleCheckEffectiveDate(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          effectiveDate: e.target.value,
        },
      },
    });
  }

  handleCheckExpiredDate(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          expiredDate: e.target.value,
        },
      },
    });
  }

  handleStripeAccountIdChange = (e) => {
    this.setState({
      stripeAccountId: e.target.value
    });
  }

  handleCompanyChange = (companyId) => {
    let zoneId = this.state.detailItem.driverInfo.zoneId;
    if (this.state.detailItem.driverInfo.company) {
      if (companyId != this.state.detailItem.driverInfo.company.companyId) {
        zoneId = [];
      }
    }

    let detailItem = {
      ...this.state.detailItem,
      driverInfo: {
        ...this.state.detailItem.driverInfo,
        company: companyId ? { companyId: companyId } : this.getCompanyDefault(),
        zoneId: zoneId,
      },
    };
    this.setState({
      detailItem
    }, () => this.getVehiclesByType(companyId));
  };

  getVehiclesByType = (companyId) => {
    let licensePlateValidation = this.props.auth.selectedFleet.licensePlate
      ? true
      : false;
    let queryObject = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      isActive: true,
      page: 0,
      limit: LIMIT_LIST_VEHICLE,
      search: '',
    };

    if (companyId) queryObject.companyId = companyId;

    if (this.state.vehicleByCompany.vehicleType) {
      queryObject.vehicleType = this.state.vehicleByCompany.vehicleType;
    }

    this.props.loadMoreVehiclesByType(queryObject).then((data) => {
      if (data.ok && data.res && data.res.list) {
        let vehicleByCompany = {
          ...this.state.vehicleByCompany,
          ...data.res,
          search: '',
          companyId: companyId || '',
        };

        this.setState({
          vehicleByCompany,
          licensePlateValidation,
        });
      }
    });
  }

  handleLicensePlateComboChange = (e) => {
    const { commonData } = this.props;
    const { detailItem } = this.state;
    let isDelivery = false;
    if (
      detailItem.driverInfo.driverType === DriverType.deliveryIndividual ||
      detailItem.driverInfo.driverType === DriverType.deliveryCompany
    ) {
      isDelivery = true;
    }
    if (!e) {
      // clear selected license
      this.setState(
        {
          detailItem: this.state.detailItem,
          detailItem: {
            ...this.state.detailItem,
            driverInfo: {
              ...this.state.detailItem.driverInfo,
              company: this.getCompanyDefault(),
              licensePlate: null,
              vehicleId: null,
              zoneId: [],
            },
          },
          vehicleByCompany: {
            limit: LIMIT_LIST_VEHICLE,
            page: 0,
            total: 0,
            search: '',
            list: [],
          },
          isVehicleDidntAssign: false,
          vehicleOwner: '',
          carMake: '',
          carModel: '',
          year: year,
          color: '',
          passengers: 0,
          luggage: 0,
          caseNumber: '',
          vhcPhone: '',
          licenseExpiry: '',
          licensePlate: '',
          licensePlateValidation: this.props.auth.selectedFleet.licensePlate
            ? true
            : false,
        },
        () => this.handleSearchLicensePlate('', null, true)
      );
    } else {
      let zoneId = this.state.detailItem.driverInfo.zoneId;
      if (e !== this.state.detailItem.driverInfo.vehicleId) zoneId = [];
      if (!this.state.vehicleDetails[e]) {
        this.props.findOneVehicle({ vehicleId: e }).then((data) => {
          if (data.ok) {
            let carType = _.get(data.res, 'type.typeId', '');
            if (isDelivery) {
              if (
                commonData.carTypeDelivery.findIndex(
                  (e) => e._id === carType
                ) === -1
              ) {
                carType = undefined;
              }
            }
            let vehicleDetails = this.state.vehicleDetails;
            vehicleDetails[e] = data.res;
            let isCompanyAssigned =
              this.state.isExistingCar &&
              data.res &&
              _.get(data.res.company, '_id')
                ? true
                : false;
            isVhicleNoType =
              this.state.isExistingCar &&
              data.res &&
              _.get(data.res.type, '_id')
                ? false
                : true;
            if (_.get(data.res.company, '_id')) {
              this.setState({
                vehicleByCompany: {
                  ...this.state.vehicleByCompany,
                  companyId: data.res.company._id,
                },
              });
            }

            this.setState({
              licensePlateValidation: true,
              vehicleDetails: vehicleDetails,
              detailItem: {
                ...this.state.detailItem,
                driverInfo: {
                  ...this.state.detailItem.driverInfo,
                  licensePlate: data.res.plateNumber,
                  vehicleId: data.res._id,
                  company: data.res.company
                    ? data.res.company
                    : this.getCompanyDefault(),
                  zoneId: zoneId,
                },
              },
              carType,
              vehicleOwner: data.res.vehicleOwner ? data.res.vehicleOwner : '',
              carMake: data.res.vehicleModel
                ? data.res.vehicleModel.vehicleMakeId
                : '',
              carModel: data.res.vehicleModel
                ? data.res.vehicleModel.modelId
                : '',
              year: data.res.year,
              color: data.res.color,
              passengers: data.res.maxPassengers,
              luggage: data.res.maxLuggage,
              licenseExpiry: data.res.licenseExp,
              licensePlate: data.res.plateNumber,
              caseNumber: data.res.caseNumber,
              vhcPhone: data.res.phone,
              isVehicleDidntAssign: isCompanyAssigned,
            });
          }
        });
      } else {
        let carType = this.state.vehicleDetails[e].type.typeId
          ? this.state.vehicleDetails[e].type.typeId
          : '';
        if (isDelivery) {
          if (
            commonData.carTypeDelivery.findIndex((e) => e._id === carType) ===
            -1
          ) {
            carType = undefined;
          }
        }
        let isCompanyAssigned =
          this.state.isExistingCar &&
          _.get(this.state.vehicleDetails[e].company, '_id')
            ? true
            : false;
        isVhicleNoType =
          this.state.isExistingCar &&
          this.state.vehicleDetails[e].type &&
          _.get(this.state.vehicleDetails[e].type, '_id')
            ? false
            : true;
        this.setState({
          licensePlateValidation: true,
          detailItem: {
            ...this.state.detailItem,
            driverInfo: {
              ...this.state.detailItem.driverInfo,
              company: this.state.vehicleDetails[e].company,
              licensePlate: this.state.vehicleDetails[e].plateNumber,
              vehicleId: this.state.vehicleDetails[e]._id,
              zoneId: zoneId,
            },
          },
          carMake: this.state.vehicleDetails[e].vehicleModel
            ? this.state.vehicleDetails[e].vehicleModel.vehicleMakeId
            : '',
          carModel: this.state.vehicleDetails[e].vehicleModel
            ? this.state.vehicleDetails[e].vehicleModel.modelId
            : '',
          carType,
          year: this.state.vehicleDetails[e].year,
          color: this.state.vehicleDetails[e].color,
          passengers: this.state.vehicleDetails[e].maxPassengers,
          luggage: this.state.vehicleDetails[e].maxLuggage,
          caseNumber: this.state.vehicleDetails[e].caseNumber,
          vhcPhone: this.state.vehicleDetails[e].phone,
          licenseExpiry: this.state.vehicleDetails[e].licenseExp,
          licensePlate: this.state.vehicleDetails[e].plateNumber,
          isVehicleDidntAssign: isCompanyAssigned,
        });
      }
    }
  };

  getLicensePlateList = (params, loadMore = false, callback = null) => {
    this.props
      .loadMoreVehiclesByType({
        ...params,
        fleetId: this.props.auth.selectedFleet.fleetId,
        isActive: true,
      })
      .then((data) => {
        if (data.ok && data.res && data.res.list) {
          this.setState({
            vehicleByCompany: {
              ...this.state.vehicleByCompany,
              ...data.res,
              search: params.search || '',
              companyId: params.companyId,
              list: loadMore
                ? this.state.vehicleByCompany.list.concat(data.res.list)
                : data.res.list,
            },
          });
        }
        if (callback) callback();
      });
  };

  handleSearchLicensePlate = (inputValue, callback, forceUpdate = false) => {
    let params = _.pick(this.state.vehicleByCompany, [
      'search',
      'page',
      'limit',
      'vehicleType',
    ]);
    if (this.state.vehicleByCompany.companyId) {
      params.companyId = this.state.vehicleByCompany.companyId;
    }
    if (inputValue !== params.search || forceUpdate) {
      params.page = 0;
      params.search = inputValue;
      this.getLicensePlateList(params, false, callback);
    } else {
      return callback ? callback() : null;
    }
  };

  handleLoadMoreLicensePlate = () => {
    let params = _.pick(this.state.vehicleByCompany, [
      'search',
      'page',
      'limit',
      'vehicleType',
    ]);
    if (this.state.vehicleByCompany.companyId) {
      params.companyId = this.state.vehicleByCompany.companyId;
    }
    params.page++;
    if (params.page * params.limit >= this.state.vehicleByCompany.total) return;
    this.getLicensePlateList(params, 'loadMore');
  };

  handleVehicleOwnerChange(e) {
    this.setState({ vehicleOwner: e.target.value });
  }

  handleCarMakeChange(e) {
    let carMake = e.target.value;
    this.setState({ carMake: carMake ? carMake : null });
  }

  handleCarTypeChange = (vehicleId) => {
    if (this.state.isSubmited) {
      this.setState({
        isSubmited: false,
        valid: {},
      });
    }

    if (isVhicleNoType) {
      this.setState({ carType: vehicleId ? vehicleId : null });
    } else {
      let carType = vehicleId;
      let zoneId = this.state.detailItem.driverInfo.zoneId;
      if (carType != this.state.carType) {
        zoneId = [];
      }
      this.setState({
        carType: carType ? carType : null,
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            company: this.getCompanyDefault(),
            vehicleId: null,
            zoneId: [],
          },
        },
        isVehicleDidntAssign: false,
      });
      let queryObject = vehicleId
        ? {
            fleetId: this.props.auth.selectedFleet.fleetId,
            vehicleType: vehicleId,
            isActive: true,
            page: 0,
            limit: LIMIT_LIST_VEHICLE,
            search: '',
          }
        : {
            fleetId: this.props.auth.selectedFleet.fleetId,
            isActive: true,
            page: 0,
            limit: LIMIT_LIST_VEHICLE,
            search: '',
          };
      this.props.loadMoreVehiclesByType(queryObject).then((data) => {
        if (data.ok && data.res && data.res.list) {
          let vehicleByCompany = {
            ...data.res,
            search: '',
            vehicleType: vehicleId,
          };
          this.setState({
            vehicleByCompany,
          });
        }
      });
    }
  };

  handleLicensePlateChange = (e) => {
    let licensePlate = e.target.value;
    this.setState({
      licensePlateValidation: licensePlate ? true : false,
      licensePlate: licensePlate.toUpperCase(),
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          licensePlate: licensePlate.toUpperCase(),
        },
      },
    });
  };

  validateLicensePlate() {
    let valid = true;
    if (!this.state.licensePlate) {
      if (!this.state.isExistingCar) {
        valid = false;
      } else {
        if (!this.props.auth.selectedFleet.licensePlate) {
          valid = false;
        }
      }
    }
    return valid;
  }

  handleExistingCarChange(e) {
    this.setState({
      isExistingCar: e.target.value == 'true',
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          company: this.getCompanyDefault(),
          licensePlate: null,
          vehicleId: null,
          zoneId: [],
        },
      },
      carType: '',
      carMake: '',
      carModel: '',
      year: year,
      color: '',
      passengers: 0,
      luggage: 0,
      caseNumber: '',
      vhcPhone: '',
      licenseExpiry: '',
      licensePlate: '',
    });
  }

  handleCarModelChange(e) {
    let carModel = e.target.value;
    this.setState({ carModel: carModel ? carModel : null });
  }

  handleYearChange(e) {
    let year = e.target.value;
    this.setState({ year: year ? year : null });
  }

  handleColorChange(e) {
    // this.setState({ color: e.target.value });
    if (e) {
      this.setState({ color: e.name ? e.name : '' });
    } else {
      this.setState({ color: '' });
    }
  }
  handleNoteChange(notes) {
    if (notes) {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            notes: notes,
          },
        },
      });
    }
  }
  handlePassengersChange(e) {
    let passengers = e.target.value;
    if (reg.test(passengers) || passengers == '') {
      this.setState({ passengers: passengers });
    }
  }

  handleLuggageChange(e) {
    let luggage = e.target.value;
    if (reg.test(luggage) || luggage == '') {
      this.setState({ luggage: luggage });
    }
  }

  handleTerminalIdChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          terminalId: e.target.value,
        },
      },
    });
  }

  handleAuthKeyChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          authKey: e.target.value,
        },
      },
    });
  }

  handleLicenseExpiryChange(e) {
    this.setState({ licenseExpiry: e._d });
  }

  handlecaseNumberChange(e) {
    this.setState({ caseNumber: e.target.value });
  }

  handleVhcPhoneChange(status, value, countryData, number, id, isBlur) {
    this.setState({ vhcPhone: number, vhcPhoneValidation: status });
  }

  handleZoneChange(e) {
    let value = MultiSelectCommonFunc.getSelectValues(e);
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: { ...this.state.detailItem.driverInfo, zoneId: value },
      },
    });
  }

  handleShiftChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        shift: { ...this.state.detailItem.shift, _id: e.target.value },
      },
    });
  }

  handleCommissionDriverChange = (data, key = 'type') => {
    if (key == 'value') {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            commissionDriverValue: data.filter(function (c) {
              return c.value !== 'N/A';
            }),
          },
        },
      });
    } else {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            commissionDriverType: data.target.value,
          },
        },
      });
    }
  };

  handleCommissionCompanyChange = (data, key = 'type') => {
    if (key == 'value') {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            commissionCompanyValue: data.filter(function (c) {
              return c.value !== 'N/A';
            }),
          },
        },
      });
    } else {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          driverInfo: {
            ...this.state.detailItem.driverInfo,
            commissionCompanyType: data.target.value,
          },
        },
      });
    }
  };

  handleCommissionTypeChange = (e) => {
    let commissionByCurrencies = [];
    let valid = this.state.valid;
    this.props.auth.selectedFleet &&
      this.props.auth.selectedFleet.currencies.map((item) => {
        commissionByCurrencies.push({
          commissionValue: 0,
          currencyISO: item.iso,
        });
        valid[item.iso] = true;
      });
    valid['percentCommission'] = true;
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          commissionType: e.target.value,
          commissionByCurrencies: commissionByCurrencies,
        },
      },
      commission: 0,
      valid,
    });
  };

  validExpiryDate(current) {
    var pastDate = moment().subtract(10, 'year');
    var futureDate = moment().add(50, 'year');
    return current.isAfter(pastDate) && current.isBefore(futureDate);
  }

  handleCommissionChange(e, iso) {
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    let com = e.target ? e.target.value : '';
    if (
      numberWithDotReg.test(com) ||
      numberWithCommaReg.test(com) ||
      com === ''
    ) {
      if (iso) {
        //amount
        let commissionByCurrencies =
          this.state.detailItem.driverInfo.commissionByCurrencies;
        if (!commissionByCurrencies) {
          commissionByCurrencies = [];
        }
        let index = commissionByCurrencies.find((x) => x.currencyISO == iso);
        if (index) {
          commissionByCurrencies = commissionByCurrencies.map((x) => {
            if (x.currencyISO == iso) {
              x.commissionValue = com === '' ? '' : com;
            }
            return x;
          });
        } else {
          commissionByCurrencies.push({
            commissionValue: com === '' ? '' : com,
            currencyISO: iso,
          });
        }
        this.setState({
          detailItem: {
            ...this.state.detailItem,
            driverInfo: {
              ...this.state.detailItem.driverInfo,
              commissionByCurrencies: commissionByCurrencies,
            },
          },
        });
      } else {
        //percent
        this.setState({ commission: com === '' ? '' : com });
      }
    }
  }

  handleForceMeterChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          forceMeter: e.target.checked,
        },
      },
    });
  }

  handleTopDriverChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, rank: e.target.checked ? 1 : 0 },
    });
  }

  handleRideSharingChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, rideSharing: e.target.checked },
    });
  }

  handleMarketplaceChange(e) {
    let newDetailItem = { ...this.state.detailItem, marketplace: e.target.checked }
    console.log(e.target.checked)
    if(!e.target.checked) {
      newDetailItem = {
        ...newDetailItem,
        marketplaceSettingMode: 'default',
        maximumAcceptReservationPerDay: 0,
        maximumAcceptReservation: 0,
      }
    }
    this.setState({
      detailItem: newDetailItem,
    });
  }

  handleTopUpOtherDriverChange = (e) => {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          hasTopUpOtherDriver: e.target.checked,
        },
      },
    });
  };

  handleActiveChange(e) {
    let value = e.target.value;
    switch (value) {
      case 'inReviewInProgress':
        this.setState({
          detailItem: { ...this.state.detailItem, isActive: false },
          statusDriver: value,
        });
        break;
      case 'inactive':
        this.setState({
          detailItem: { ...this.state.detailItem, isActive: false },
          statusDriver: value,
        });
        break;
      case 'active':
        this.setState({
          detailItem: { ...this.state.detailItem, isActive: true },
          statusDriver: value,
        });
        break;
      default:
        break;
    }

    // this.setState({
    //   detailItem: { ...this.state.detailItem, isActive: e.target.checked }
    // });
  }

  handleMarketplaceTypeChange = (e) => {
    const value = e.target.value;
    let newDetailItem = { 
      ...this.state.detailItem,
      marketplaceSettingMode: value
    }
    if(value === 'customized') {
      newDetailItem = { 
        ...newDetailItem,
        maximumAcceptReservationPerDay: 0,
        maximumAcceptReservation: 0, 
      }
    }
    this.setState({
      detailItem: newDetailItem,
    });
  }

  handleUsernameChange(e) {
    let username = e.target.value;
    this.setState({
      detailItem: { ...this.state.detailItem, username: username },
    });
  }

  handleAcceptChange = (e, keyName) => {
    const valueParse = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    let valueParseFloat = parseFloat(valueParse)
    if(isNaN(valueParseFloat)) valueParseFloat = 0
    this.setState({
      detailItem: { 
        ...this.state.detailItem,
        [keyName]: valueParseFloat
      },
    });
  }

  handleCancelClick() {
    if (!this.props.isDriverList) {
      return this.props.router.push({
        pathname: '/driver',
      });
    }
    yearsElement = [];
    this.setState(
      {
        visible: false,
        detailItem: {
          driverInfo: {
            company: this.getCompanyDefault(),
            city: {},
            driverType: DriverType.IndividualDriver,
            driverDocument: {},
            commissionByCurrencies: [],
            commissionType: 'percent',
            creditBalances: [],
          },
          shift: {},
          phone: '',
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          rideSharing: false,
          marketplace: false,
        },
        valid: {},
      },
      this.props.closeDialog
    );
  }

  onExpiryDateChange = (value, document, index) => {
    let { driverDocumentExpiry } = this.state;
    if (!driverDocumentExpiry.length) {
      this.initDriverDocumentExpiryList(
        undefined,
        this.props.commonData.relativeInfo
      );
    }
    driverDocumentExpiry[index].expiredDate = value.format('MM/DD/YYYY');
    this.setState({ driverDocumentExpiry });
  };
  onDocumentIdChange = (value, document, index) => {
    let { driverDocumentExpiry } = this.state;
    if (!driverDocumentExpiry.length) {
      this.initDriverDocumentExpiryList(
        undefined,
        this.props.commonData.relativeInfo
      );
    }
    driverDocumentExpiry[index].documentId = value;
    this.setState({ driverDocumentExpiry });
  };
  initDriverDocumentExpiryList = (documentExpiry, relativeInfo) => {
    if (!relativeInfo) return;
    const driverType = this.state.detailItem.driverInfo.driverType;

    let documentList = [];
    if (driverType === DriverType.IndividualDriver) {
      documentList = relativeInfo.documents.individual;
    } else if (driverType === DriverType.OwnerOperator) {
      documentList = relativeInfo.documents.owner;
    } else if (driverType === DriverType.deliveryIndividual) {
      documentList = relativeInfo.documents.deliveryIndividual;
    } else if (driverType === DriverType.deliveryCompany) {
      documentList = relativeInfo.documents.deliveryCompany;
    }

    let driverDocumentExpiry = [];

    if (documentList && documentList.length > 0) {
      _.forEach(documentList, (obj) => {
        let expiryObj = {
          documentKey: obj.keyName,
          documentName: obj.documentName,
        };

        if (documentExpiry && documentExpiry.length) {
          let avaiExpiryDate = documentExpiry.find(
            (item) => item.documentKey == expiryObj.documentKey
          );

          if (avaiExpiryDate && avaiExpiryDate.expiredDate) {
            expiryObj.expiredDate = moment
              .tz(
                avaiExpiryDate.expiredDate,
                this.props.auth.selectedFleet.timezone
              )
              .format('MM/DD/YYYY');
          }
          if (avaiExpiryDate && avaiExpiryDate.documentId) {
            expiryObj.documentId = avaiExpiryDate.documentId;
          }
          if (avaiExpiryDate && avaiExpiryDate.documentUrl) {
            expiryObj.documentUrl = avaiExpiryDate.documentUrl;
          }
        }
        driverDocumentExpiry.push(expiryObj);
      });
    }

    this.setState({
      driverDocumentExpiry,
    });
  };
  initDriverFiledList = (relativeInfo) => {
    if (!relativeInfo) return;
    const driverType = this.state.detailItem.driverInfo.driverType;
    let driverFiledList = [];
    switch (driverType) {
      case DriverType.IndividualDriver:
        driverFiledList = _.filter(relativeInfo.fields, {
          availableCompanyCar: true,
        });
        break;
      case DriverType.OwnerOperator:
        driverFiledList = _.filter(relativeInfo.fields, {
          availableIndividualCar: true,
        });
        break;
      case DriverType.deliveryIndividual:
        driverFiledList = _.filter(relativeInfo.fields, {
          deliveryIndividual: true,
        });
        break;
      case DriverType.deliveryCompany:
        driverFiledList = _.filter(relativeInfo.fields, {
          deliveryCompany: true,
        });
        break;
      default:
        break;
    }
    this.setState({
      driverFiledList,
    });
  };
  validatorCallback(id, valid, messages) {
    let stateValid = Object.assign({}, this.state.valid);
    if (stateValid[id] !== valid) {
      stateValid[id] = valid;
      this.setState({ valid: stateValid });
    }
  }

  handleClickSave = async () => {
    const {
      detailItem,
      statusDriver,
      driverFiledList,
      documentsOrigin,
      isSubmited,
      valid,
      stripeAccountId,
      enableStripeConnect,
      accountConnectedStripe
    } = this.state;
    const { selectedFleet } = this.props
    if(enableStripeConnect) {
      if(!stripeAccountId && accountConnectedStripe) {
        this.context.notification(
          "error",
          "Can not remove existed stripe account id"
        );
        return
      }

      if(stripeAccountId) {
        const dataResponse = await this.props.settingActions
          .checkExistingStripe({ 
            fleetId: selectedFleet.fleetId,
            token: stripeAccountId
          })
          const status = _.get(dataResponse, 'res.response.status', '')
          if(status === 'activated') {
            this.setState({
              stripeAccountIdVerified: stripeAccountId
            })
          } else {
            this.props.loadingBarActions.hideLoadingSpiner();
            if(status === 'pending') {
              this.context.notification(
                "error",
                "The stripe account id you provided is in-review, please try again"
              );
              return
            }
            this.context.notification(
              "error",
              "The stripe account id you provided is incorrect, please try again"
            );
            return 
          }
      }
    }
    
    let dataValid = Object.assign({}, valid);
    let keys = Object.keys(dataValid);
    let data = detailItem;
    let documentsMissing = [];

    if (!isSubmited) {
      this.setState({ isSubmited: true });
    }

    let newDriverFiledList = driverFiledList.filter((item) => {
      return item.type !== 'File';
    });

    let fieldFileList = driverFiledList.filter((item) => {
      return item.type === 'File' && item.isRequired;
    });

    let listFile = [];
    listFile = keys.filter(
      (key) => key.toLowerCase().search('document') !== -1
    );
    if (fieldFileList.length > 0) {
      fieldFileList.forEach((field) => {
        listFile.push(field.key);
      });
    }

    if (listFile.length > 0) {
      listFile.forEach((key) => {
        if (!dataValid[key]) {
          let original = documentsOrigin.find((doc) => doc.key === key);
          documentsMissing.push({
            key,
            originalName: original ? original.originalName : '',
            originalURL: original ? original.originalURL : '',
          });
        }
      });
    }

    if (_.get(selectedFleet, 'generalSetting.compulsoryCarInsurance', false)) {
      newDriverFiledList = [
        ...newDriverFiledList,
        { key: 'policyNumber' },
        { key: 'expiredDate' },
        { key: 'effectiveDate' },
      ];
    }

    if (!CCLiteCommonFunc.isFormValidDriver(valid, newDriverFiledList)) {
      this.context.notification('error', I18n.t('errors.missing_some_field'));
      this.checkTabError();
      document.getElementById('page-content').scrollTop = 0;
      return;
    }

    if (
      documentsMissing.length === 0 &&
      statusDriver === 'inReviewInProgress'
    ) {
      this.setState({ statusDriver: 'active' });
      data.driverInfo.statusReview = 'completed';
      data.isActive = true;
    } else {
      switch (statusDriver) {
        case 'inReviewInProgress':
          data.driverInfo.statusReview = 'inProgress';
          data.isActive = false;
          break;
        case 'inactive':
          if (data.driverInfo.statusReview !== 'completed') {
            data.driverInfo.statusReview = 'inComplete';
            data.isActive = false;
          } else {
            data.driverInfo.statusReview = 'completed';
            data.isActive = false;
          }
          break;
        case 'active':
          data.driverInfo.statusReview = 'completed';
          data.isActive = true;
          break;
        default:
          break;
      }
    }

    this.setState(
      {
        detailItem: data,
        documentsMissing,
      },
      () => {
        this.handleSaveDriverClick();
      }
    );
  }

  handleSaveDriverClick() {
    const { auth } = this.props;
    const { valid, detailItem, stripeAccountIdVerified } = this.state;
    const s3Host = auth.selectedFleet.s3Host || '';
    const groupBrand = auth.selectedFleet.syncPaxProfile || { enable: false };
    let dataValid = Object.assign({}, valid);
    let keys = Object.keys(dataValid);
    let results = keys.filter((key) => key.search('document') !== -1);
    let dataValidDocuments = {};
    const companyCommission = _.get(auth, 'selectedFleet.generalSetting.companyCommission', false)

    // SL-20827: Filter data document to validate
    if (results.length > 0) {
      for (var i = 0; i < results.length; i++) {
        dataValidDocuments[results[i]] = dataValid[results[i]];
      }
    }

    // KAN-1456: Hide required documents if car type is 'Package Delivery' or 'Shop For You'
    // if (
    //   carType &&
    //   (auth.selectedFleet.fleetId === 'gojosg' || auth.selectedFleet.fleetId === 'demo-gojo')
    // ) {
    //   let carTypeIndex = commonData.carTypeDelivery.findIndex(e => e._id === carType);
    //   if (carTypeIndex !== -1) {
    //     for (var i = 0; i < results.length; i++) {
    //       delete dataValidDocuments[results[i]]
    //     }
    //   }
    // };

    if(!_.get(detailItem, 'driverInfo.driverType', '')) {
      this.context.notification(
        "error",
        I18n.t('driver.ERROR_INPUT_DRIVER_TYPE')
      );
      return
    }

    let validBannkAccountOwner = _.pick(dataValid, [
      'beneficiaryIDIC',
      'bankRelationship',
      'relationshipOtherName',
    ]);
    if (
      !CCLiteCommonFunc.isFormValid(validBannkAccountOwner) &&
      !detailItem.driverInfo.isBankAccountOwner
    ) {
      return;
    }

    if (
      !CCLiteCommonFunc.isFormValidDriver(
        dataValid,
        this.state.driverFiledList
      ) &&
      this.state.detailItem.driverInfo.statusReview === 'completed'
    ) {
      this.context.notification('error', I18n.t('errors.missing_some_field'));
      this.checkTabError();
      document.getElementById('page-content').scrollTop = 0;
      return;
    }

    if (
      !CCLiteCommonFunc.isFormValidDocument(
        dataValidDocuments,
        this.state.driverDocumentExpiry
      ) &&
      this.state.detailItem.driverInfo.statusReview === 'completed'
    ) {
      document.getElementById('page-content').scrollTop = 0;
      return;
    }
    var data = new FormData();
    data.append('fleetId', this.props.auth.selectedFleet.fleetId);
    this.props.params.driverId &&
      data.append('driverId', this.props.params.driverId);
    data.append('driverType', this.state.detailItem.driverInfo.driverType);
    this.state.avatar && data.append('avatar', this.state.avatar);
    data.append('username', this.state.detailItem.username);
    if(stripeAccountIdVerified) {
      data.append('stripeAccountIdVerified', stripeAccountIdVerified)
    }
    if (groupBrand.enable) {
      data.append('groupId', groupBrand.groupId);
    }
    _.forEach(this.state.driverFiledList, (item) => {
      if (item.key !== 'company') {
        let dbKey =
          DriverInfoFields[item.key] && DriverInfoFields[item.key].dbKey
            ? DriverInfoFields[item.key].dbKey
            : item.key;
        let value = this.state.driverInfoDynamic[item.key];

        /**
         * backup10 used for the EVP expiry date (Mycar)
         */
        if (value && item.type === 'Date') {
          value = moment(value).format('MM/DD/YYYY');
        }

        if (item.type === 'Phone') {
          //hardcode for mycar
          if (
            this.props.auth.selectedFleet.fleetId === 'mycar' &&
            this.state.detailItem.driverInfo.statusView === 'In review' &&
            this.state.phoneFrefix &&
            this.state.phoneFrefix.includes('+00')
          ) {
            value = this.state.phoneFrefix;
          } else {
            value = trimPhoneNumber(value);
          }
        }
        if (item.key === 'gender') {
          if (value == 0) {
            value = 0;
          } else {
            value = value || -1;
          }
        }

        if (item.key === 'idType') {
          value = value || '';
        }

        if (item.key === 'state') {
          String.prototype.trim = function (charlist) {
            return this.trimLeft(charlist).trimRight(charlist);
          };
          value = value ? value.trim() : '';
        }

        if (
          item.key.substring(0, 6) === 'backup' &&
          item.type === 'File' &&
          typeof value === 'string'
        ) {
          value = value.replace(s3Host, '');
        }

        data.append(dbKey, value);
      }
    });

    data.append('document', this.state.document);
    data.append('link', this.state.detailItem.driverInfo.link);
    data.append(
      'notes',
      _.map(this.state.detailItem.driverInfo.notes, 'name').join(', ')
    );
    data.append('bankRequirement', JSON.stringify(this.state.bankRequirement));
    this.state.driverDocument.document1 &&
      data.append('document1', this.state.driverDocument.document1);
    this.state.driverDocument.document2 &&
      data.append('document2', this.state.driverDocument.document2);
    this.state.driverDocument.document3 &&
      data.append('document3', this.state.driverDocument.document3);
    this.state.driverDocument.document4 &&
      data.append('document4', this.state.driverDocument.document4);
    this.state.driverDocument.document5 &&
      data.append('document5', this.state.driverDocument.document5);
    this.state.driverDocument.document6 &&
      data.append('document6', this.state.driverDocument.document6);
    this.state.driverDocument.document7 &&
      data.append('document7', this.state.driverDocument.document7);
    this.state.driverDocument.document8 &&
      data.append('document8', this.state.driverDocument.document8);
    this.state.driverDocument.document9 &&
      data.append('document9', this.state.driverDocument.document9);
    this.state.driverDocument.document10 &&
      data.append('document10', this.state.driverDocument.document10);
    this.state.driverDocument.document11 &&
      data.append('document11', this.state.driverDocument.document11);
    this.state.driverDocument.document12 &&
      data.append('document12', this.state.driverDocument.document12);
    this.state.driverDocument.document13 &&
      data.append('document13', this.state.driverDocument.document13);
    this.state.driverDocument.document14 &&
      data.append('document14', this.state.driverDocument.document14);
    this.state.driverDocument.document15 &&
      data.append('document15', this.state.driverDocument.document15);
    this.state.driverDocument.document16 &&
      data.append('document16', this.state.driverDocument.document16);
    this.state.driverDocument.document17 &&
      data.append('document17', this.state.driverDocument.document17);
    this.state.driverDocument.document18 &&
      data.append('document18', this.state.driverDocument.document18);
    this.state.driverDocument.document19 &&
      data.append('document19', this.state.driverDocument.document19);
    this.state.driverDocument.document20 &&
      data.append('document20', this.state.driverDocument.document20);
    if (
      this.state.driverDocumentExpiry &&
      this.state.driverDocumentExpiry.length > 0
    ) {
      data.append(
        `driverDocumentExpiry`,
        JSON.stringify(this.state.driverDocumentExpiry)
      );
    }
    data.append('timezone', this.props.auth.selectedFleet.timezone);
    if (
      this.props.auth.selectedFleet.bankingInfo &&
      this.props.auth.selectedFleet.bankingInfo.enable
    ) {
      data.append('nameOfBank', this.state.detailItem.driverInfo.nameOfBank);
      data.append('IFSCCode', this.state.detailItem.driverInfo.IFSCCode);
      data.append(
        'nameOfAccount',
        this.state.detailItem.driverInfo.nameOfAccount
      );
      // if (!europeCountry.includes(this.props.auth.selectedFleet.countryCode)) {
      if (this.state.bankRequirement && !this.state.bankRequirement.useIBAN) {
        data.append(
          'routingNumber',
          this.state.detailItem.driverInfo.routingNumber
        );
      }
      data.append(
        'accountNumber',
        this.state.detailItem.driverInfo.accountNumber
      );
      data.append(
        'checkNumber',
        this.state.detailItem.driverInfo.checkNumber
          ? this.state.detailItem.driverInfo.checkNumber
          : ''
      );
      data.append(
        'swiftCodeNumber',
        this.state.detailItem.driverInfo.swiftCodeNumber
          ? this.state.detailItem.driverInfo.swiftCodeNumber
          : ''
      );
      data.append(
        'ssn',
        this.state.detailItem.driverInfo.ssn
          ? this.state.detailItem.driverInfo.ssn
          : ''
      );
      data.append(
        'sortCode',
        this.state.detailItem.driverInfo.sortCode
          ? this.state.detailItem.driverInfo.sortCode
          : ''
      );
      this.state.verificationDocument &&
        data.append('verificationDocument', this.state.verificationDocument);
      this.state.additionalDocument &&
        data.append('additionalDocument', this.state.additionalDocument);
      this.state.verificationDocumentBack &&
        data.append(
          'verificationDocumentBack',
          this.state.verificationDocumentBack
        );
      this.state.additionalDocumentBack &&
        data.append(
          'additionalDocumentBack',
          this.state.additionalDocumentBack
        );

      // SL-24302 Need to remove verificationDocument and adddtionalDocument out of driverInfo when delete on CC
      if (
        detailItem.driverInfo.verificationDocument === '' &&
        !this.state.verificationDocument
      ) {
        data.append('verificationDocument', '');
      } else {
        data.append(
          'verificationDocument',
          detailItem.driverInfo.verificationDocument
        );
      }
      if (
        detailItem.driverInfo.additionalDocument === '' &&
        !this.state.additionalDocument
      ) {
        data.append('additionalDocument', '');
      } else {
        data.append(
          'additionalDocument',
          detailItem.driverInfo.additionalDocument
        );
      }
      if (
        detailItem.driverInfo.verificationDocumentBack === '' &&
        !this.state.verificationDocumentBack
      ) {
        data.append('verificationDocumentBack', '');
      } else {
        data.append(
          'verificationDocumentBack',
          detailItem.driverInfo.verificationDocumentBack
        );
      }
      if (
        detailItem.driverInfo.additionalDocumentBack === '' &&
        !this.state.additionalDocumentBack
      ) {
        data.append('additionalDocumentBack', '');
      } else {
        data.append(
          'additionalDocumentBack',
          detailItem.driverInfo.additionalDocumentBack
        );
      }

      // SL-23737
      data.append(
        'isBankAccountOwner',
        detailItem.driverInfo.isBankAccountOwner ? 'true' : 'false'
      );
      data.append(
        'beneficiaryIDIC',
        detailItem.driverInfo.beneficiaryIDIC
          ? detailItem.driverInfo.beneficiaryIDIC
          : ''
      );
      data.append(
        'bankRelationship',
        detailItem.driverInfo.bankRelationship
          ? detailItem.driverInfo.bankRelationship
          : ''
      );
      data.append(
        'relationshipOtherName',
        detailItem.driverInfo.relationshipOtherName
          ? detailItem.driverInfo.relationshipOtherName
          : ''
      );
    }

    this.state.detailItem.driverInfo.policyNumber &&
      data.append(
        'policyNumber',
        this.state.detailItem.driverInfo.policyNumber
      );
    this.state.detailItem.driverInfo.effectiveDate &&
      data.append(
        'effectiveDate',
        this.state.detailItem.driverInfo.effectiveDate
      );
    this.state.detailItem.driverInfo.expiredDate &&
      data.append('expiredDate', this.state.detailItem.driverInfo.expiredDate);
    data.append(
      'companyId',
      this.state.detailItem.driverInfo.company.companyId
    );
    data.append('existingCar', this.state.isExistingCar);
    this.state.detailItem.driverInfo.vehicleId &&
      data.append('vehicleId', this.state.detailItem.driverInfo.vehicleId);
    this.state.isVehicleDidntAssign &&
      data.append('isVehicleDidntAssign', this.state.isVehicleDidntAssign);
    this.state.carType && data.append('vehicleTypeId', this.state.carType);
    data.append('licensePlate', this.state.licensePlate);
    this.state.vehicleOwner &&
      data.append('vehicleOwner', this.state.vehicleOwner);
    this.state.carMake && data.append('makeId', this.state.carMake);
    this.state.carModel && data.append('modelId', this.state.carModel);
    data.append('year', this.state.year);
    this.state.color && data.append('color', this.state.color);
    data.append('passengers', this.state.passengers);
    data.append('luggage', this.state.luggage);
    data.append('caseNumber', this.state.caseNumber);
    data.append('vhcPhone', trimPhoneNumber(this.state.vhcPhone));
    this.state.licenseExpiry &&
      data.append(
        'licenseExpiry',
        moment(this.state.licenseExpiry).format('MM/DD/YYYY')
      );
    data.append('zoneId', this.state.detailItem.driverInfo.zoneId);
    data.append('shiftTemplateId', this.state.detailItem.shift._id);

    if (
      this.state.detailItem.driverInfo.commissionCompanyValue &&
      companyCommission
    ) {
      data.append(
        'commissionCompanyType',
        this.state.detailItem.driverInfo.commissionCompanyType
      );
      data.append(
        'commissionCompanyValue',
        JSON.stringify(
          this.state.detailItem.driverInfo.commissionCompanyValue
        )
      );
    }

    // Apply new flow commission
    if (
      auth.selectedFleet.generalSetting &&
      auth.selectedFleet.generalSetting.differentFleetCommission
    ) {
      data.append(
        'commissionDriverType',
        this.state.detailItem.driverInfo.commissionDriverType
      );
      if (this.state.detailItem.driverInfo.commissionDriverValue) {
        data.append(
          'commissionDriverValue',
          JSON.stringify(
            this.state.detailItem.driverInfo.commissionDriverValue.filter(
              function (c) {
                return c.value !== 'N/A';
              }
            )
          )
        );
      }
    } else {
      // old flow commission
      data.append(
        'commissionType',
        this.state.detailItem.driverInfo.commissionType
      );
      if (
        this.state.detailItem.driverInfo.commissionType == 'percent' ||
        this.props.auth.selectedFleet.currencies.length <= 1
      ) {
        data.append(
          'commission',
          this.state.commission ? this.state.commission : 0
        );
      } else {
        var commissions = {};
        this.state.detailItem.driverInfo.commissionByCurrencies.map((c) => {
          commissions[c.currencyISO] = c.commissionValue;
        });
        data.append('commissions', JSON.stringify(commissions));
      }
    }

    data.append(
      'deleteDocument',
      JSON.stringify(this.state.documentDeleteList)
    );
    data.append('terminalId', this.state.detailItem.driverInfo.terminalId);
    data.append('authKey', this.state.detailItem.driverInfo.authKey);
    data.append('active', this.state.detailItem.isActive);
    data.append(
      'statusReview',
      this.state.detailItem.isActive
        ? 'completed'
        : this.state.detailItem.driverInfo.statusReview
    );

    data.append('topDriver', this.state.detailItem.rank);
    data.append('forceMeter', this.state.detailItem.driverInfo.forceMeter);
    data.append('rideSharing', this.state.detailItem.rideSharing);
    data.append('marketplace', this.state.detailItem.marketplace);
    data.append('marketplaceSettingMode', this.state.detailItem.marketplaceSettingMode);
    data.append('maximumAcceptReservationPerDay', this.state.detailItem.maximumAcceptReservationPerDay);
    data.append('maximumAcceptReservation', this.state.detailItem.maximumAcceptReservation);
    data.append(
      'hasTopUpOtherDriver',
      detailItem.driverInfo.hasTopUpOtherDriver ? 'true' : 'false'
    );
    /**
     * Generate object for update vehicle not assign yet
     */
    let vehicleNeedAssign = this.state.vehicleDetails[
      this.state.detailItem.driverInfo.vehicleId
    ]
      ? this.initVehicleUpdate(
          this.state.vehicleDetails[this.state.detailItem.driverInfo.vehicleId]
        )
      : {};

    const fleet = this.props.auth.selectedFleet;
    this.props.loadingBarActions.showLoadingSpiner();
    if (
      !fleet.drvPayout.enable &&
      fleet.bankingInfo &&
      fleet.bankingInfo.enable &&
      fleet.bankingInfo.verifyAccount == SettelementSetting.verifyAccount &&
      fleet.creditConfig.configGateway &&
      // fleet.countryCode == 'FR' &&
      this.state.bankRequirement.legalToken &&
      fleet.creditConfig.configGateway.gateway == 'Stripe' &&
      window.Stripe &&
      this.props.commonData.relativeInfo.stripe &&
      this.props.commonData.relativeInfo.stripe.publicKey &&
      !this.state.detailItem.driverInfo.isBankVerified
    ) {
      // Upload document to Stripe;
      let verificationFile = null;
      let additionalFile = null;
      let verificationBackFile = null;
      let additionalBackFile = null;
      if (this.state.verificationDocument) {
        verificationFile = this.uploadStripeVerificationDocument(
          this.state.verificationDocument
        );
      } else {
        verificationFile =
          this.state.detailItem.driverInfo.verificationDocumentId;
      }
      if (this.state.verificationDocumentBack) {
        verificationBackFile = this.uploadStripeVerificationDocument(
          this.state.verificationDocumentBack
        );
      } else {
        verificationBackFile =
          this.state.detailItem.driverInfo.verificationDocumentBackId;
      }
      if (this.state.additionalDocument) {
        additionalFile = this.uploadStripeAdditionalDocument(
          this.state.additionalDocument
        );
      } else {
        additionalFile = this.state.detailItem.driverInfo.additionalDocumentId;
      }
      if (this.state.additionalDocumentBack) {
        additionalBackFile = this.uploadStripeAdditionalDocument(
          this.state.additionalDocumentBack
        );
      } else {
        additionalBackFile =
          this.state.detailItem.driverInfo.additionalDocumentBackId;
      }

      Promise.all([
        verificationFile,
        verificationBackFile,
        additionalFile,
        additionalBackFile,
      ])
        .then((files) => {
          const [
            verificationDocumentId,
            verificationDocumentBackId,
            additionalDocumentId,
            additionalDocumentBackId,
          ] = files;
          data.append('verificationDocumentId', verificationDocumentId);
          data.append('verificationDocumentBackId', verificationDocumentBackId);
          data.append('additionalDocumentId', additionalDocumentId);
          data.append('additionalDocumentBackId', additionalDocumentBackId);
          if (!this.state.detailItem.isActive) {
            return this.sendOperateDriverRequest(data, vehicleNeedAssign);
          }
          // Get Stripe token before submit form
          if (!this.legalToken || this.isChangeZipcode) {
            this.createStripeToken(files).then((result) => {
              if (result) {
                this.legalToken = result;
                data.append('legalToken', result);
                this.sendOperateDriverRequest(data, vehicleNeedAssign);
              } else {
                this.props.loadingBarActions.hideLoadingSpiner();
              }
            });
          } else {
            data.append('legalToken', this.legalToken);
            this.sendOperateDriverRequest(data, vehicleNeedAssign);
          }
        })
        .catch((err) => {
          console.error(err);
          this.props.loadingBarActions.hideLoadingSpiner();
        });
    } else {
      this.sendOperateDriverRequest(data, vehicleNeedAssign);
    }
  }

  initVehicleUpdate(vehDetail) {
    let typeId = isVhicleNoType ? this.state.carType : vehDetail.type.typeId;
    return {
      fleetId: this.props.auth.selectedFleet.fleetId,
      companyId: this.state.detailItem.driverInfo.company.companyId,
      vehicleId: this.state.detailItem.driverInfo.vehicleId,
      typeId: typeId,
      makeId:
        vehDetail && vehDetail.vehicleModel
          ? vehDetail.vehicleModel.vehicleMakeId
          : '',
      modelId:
        vehDetail && vehDetail.vehicleModel
          ? vehDetail.vehicleModel.modelId
          : '',
      licenseExp: vehDetail && vehDetail.licenseExp ? vehDetail.licenseExp : '',
      isActive: vehDetail && vehDetail.isActive ? vehDetail.isActive : '',
      listRoles: vehDetail && vehDetail.listRoles ? vehDetail.listRoles : '',
      caseNumber: vehDetail && vehDetail.caseNumber ? vehDetail.caseNumber : '',
      plateNumber:
        vehDetail && vehDetail.plateNumber ? vehDetail.plateNumber : '',
      hwMetered: vehDetail && vehDetail.hwMetered ? vehDetail.hwMetered : false,
      maxPassengers:
        vehDetail && vehDetail.maxPassengers ? vehDetail.maxPassengers : 0,
      maxLuggage: vehDetail && vehDetail.year ? vehDetail.year : 0,
      color: vehDetail && vehDetail.color ? vehDetail.color : '',
      phone: vehDetail && vehDetail.phone ? vehDetail.phone : '',
      year: vehDetail && vehDetail.year ? vehDetail.year : '',
      vhcId: vehDetail && vehDetail.vhcId ? vehDetail.vhcId : '',
    };
  }

  sendOperateDriverRequest(data, vehicleNeedAssign) {
    const { syncPaxProfile } = this.props.auth.selectedFleet;
    this.props
      .operateDriver(data, !!this.props.params.driverId)
      .then((respone) => {
        /**
         * update setting car mgmt
         */
        this.props.loadingBarActions.hideLoadingSpiner();
        if (respone.ok) {
          if (
            this.state.isExistingCar &&
            (!this.state.isVehicleDidntAssign || isVhicleNoType) &&
            !_.isEmpty(vehicleNeedAssign)
          ) {
            this.props.settingActions
              .updateCars(vehicleNeedAssign)
              .then((data) => {
                if (!data.ok) {
                  if (data.error) {
                    this.context.notification(
                      'error',
                      I18n.t('errors.' + data.error.errorCode)
                    );
                  } else if (data.message) {
                    this.context.notification(
                      'error',
                      I18n.t('errors.' + data.message.errorCode)
                    );
                  } else {
                    this.context.notification(
                      'error',
                      I18n.t('carSetting.Update_car_fail')
                    );
                  }
                }
              });
          }

          // Update Documents Missing
          this.props
            .setDocumentsMissing({
              fleetId: respone.res.fleetId,
              userId: respone.res.userId,
              documentsMissing: this.state.documentsMissing,
            })
            .then(({ res }) => {});

          this.props.updateDriverInfo(respone.res);

          this.context.notification(
            'success',
            I18n.t(
              !!this.props.params.driverId
                ? 'driver.DRIVER_UPDATE_SUCCESS'
                : 'driver.DRIVER_CREATE_SUCCESS'
            )
          );
          const { isActive } = this.state.detailItem;
          const { isComplete, statusReview } = this.state.detailItem.driverInfo;

          // tranform is active
          respone.res.isActive = isActive;
          if (isActive) {
            respone.res.driverInfo.isActivate = true;
            respone.res.driverInfo.isComplete = true;
            respone.res.driverInfo.statusReview = 'completed';
          }

          // transform is complete profile when keep InReview
          if (!isActive && !isComplete) {
            respone.res.driverInfo.isComplete = true;
          }

          // transform is In-review and profile in-progress
          if (!isActive && statusReview === 'inProgress') {
            respone.res.driverInfo.isActivate = false;
            respone.res.driverInfo.isComplete = false;
            respone.res.driverInfo.statusReview = 'inProgress';
          }

          this.props.onUpdateDriver({
            ...respone.res,
            driverWallet: this.state.detailItem.driverWallet,
          });
          this.props.router.push({
            pathname: '/driver',
          });
          yearsElement = [];
        } else if (respone.error) {
          let message = I18n.t('errors.' + respone.error.errorCode);
          if (respone.error.errorCode === 3040) {
            message = I18n.t('errors.' + respone.error.errorCode, {
              brandName: syncPaxProfile.groupName
                ? syncPaxProfile.groupName
                : '',
            });
          }
          if (respone.error.paymentReturnCode == 407 && respone.error.message) {
            message += `Please check (${respone.error.message})`;
          }
          if (
            respone.error.paymentReturnCode === 493 &&
            respone.error.paymentMessage
          ) {
            message += `(${respone.error.paymentMessage})`;
          }
          this.context.notification(
            'error',
            I18n.t('messages.commonMessages.Error_message'),
            message
          );
        } else {
          console.error('Error: ', respone);
          this.context.notification(
            'error',
            I18n.t('messages.commonMessages.Error_message'),
            respone
          );
        }
      });
  }

  uploadStripeVerificationDocument(file) {
    const stripe = window.Stripe(
      this.props.commonData.relativeInfo.stripe.publicKey
    );
    let formData = new FormData();
    // formData.append('file', this.state.verificationDocument);
    formData.append('file', file);
    formData.append('purpose', 'identity_document');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then((response) => {
      return response.json().then((o) => o.id);
    });
  }

  uploadStripeAdditionalDocument = (file) => {
    const stripe = window.Stripe(
      this.props.commonData.relativeInfo.stripe.publicKey
    );
    const formData = new FormData();
    // formData.append('file', this.state.additionalDocument);
    formData.append('file', file);
    formData.append('purpose', 'additional_verification');
    return fetch('https://uploads.stripe.com/v1/files', {
      method: 'POST',
      headers: { Authorization: `Bearer ${stripe._apiKey}` },
      body: formData,
    }).then((response) => {
      return response.json().then((o) => o.id);
    });
  };

  createStripeToken = (files) => {
    const { commonData } = this.props;
    const { driverInfoDynamic, detailItem } = this.state;
    const stripePublicKey = _.get(
      commonData,
      'relativeInfo.stripe.publicKey',
      ''
    );
    const stripe = window.Stripe(stripePublicKey);
    const [
      verificationFile,
      verificationBackFile,
      additionalFile,
      additionalBackFile,
    ] = files;

    let form = {
      business_type: 'individual',
      individual: {
        first_name: driverInfoDynamic.firstName || '',
        last_name: driverInfoDynamic.lastName || '',
        phone: driverInfoDynamic.phone || '',
        email: driverInfoDynamic.email || '',
        address: {
          line1: driverInfoDynamic.address || '',
          city: driverInfoDynamic.city || '',
          state: driverInfoDynamic.state || '',
          postal_code: driverInfoDynamic.zipcode || '',
        },
      },
      tos_shown_and_accepted: true,
    };
    let dob = _.clone(driverInfoDynamic.birthday);
    dob = moment(dob, 'YYYY/MM/DD');
    form.individual.dob =
      typeof driverInfoDynamic.birthday == 'string'
        ? this.getDayMonthYear(driverInfoDynamic.birthday)
        : {
            day: dob.format('D'),
            month: dob.format('M'),
            year: dob.format('YYYY'),
          };

    /* Split firstname, lastname by accountName */
    if (detailItem.driverInfo.nameOfAccount) {
      const arrName = detailItem.driverInfo.nameOfAccount.split(' ');
      form.individual.first_name = arrName[0];
      form.individual.last_name = detailItem.driverInfo.nameOfAccount
        .substring(form.individual.first_name.length)
        .trim();
    }

    if (verificationFile || verificationBackFile) {
      form.individual.verification = {
        document: {
          front: verificationFile,
          back: verificationBackFile,
        },
      };
    }

    if (additionalFile || additionalBackFile) {
      form.individual.verification = form.individual.verification || {};
      form.individual.verification.additional_document = {
        front: additionalFile,
        back: additionalBackFile,
      };
    }

    return stripe
      .createToken('account', form)
      .then((data) => {
        if (data.error) {
          this.context.notification('error', data.error.message);
          return null;
        }
        return data.token.id;
      })
      .catch((err) => {
        this.context.notification('error', err.message);
        return null;
      });
  };

  getDayMonthYear(birthday) {
    var d = new Date(birthday),
      month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear();
    return {
      day: day,
      month: month,
      year: year,
    };
  }

  checkTabError() {
    if (this.state.valid[this.state.activeTabKey]) {
      var errorTab = this.props.auth.selectedFleet.currencies.find((c, i) => {
        return !this.state.valid[c.iso];
      });
      if (errorTab) {
        this.setState({
          activeTabKey: errorTab.iso,
        });
      }
    }
  }

  handleTabSelect(key) {
    this.setState({ activeTabKey: key });
  }

  renderDriverDeposit = () => {
    const { auth } = this.props;

    if (!auth.selectedFleet) return null;

    const { selectedCurrency } = this.state;
    let creditBalances = this.state.detailItem.driverInfo.creditBalances;
    let creditValue = '0.00';
    let creditBalance = _.find(
      creditBalances,
      (x) => x.currencyISO == selectedCurrency
    );
    let currencyObj = _.find(
      auth.selectedFleet.currencies,
      (i) => i.iso === selectedCurrency
    );

    if (creditBalance) {
      try {
        creditValue = creditBalance.value.toFixed(2);
      } catch (err) {
        creditValue = creditBalance.value;
      }
    }

    return (
      <FormGroup className="qup-input-group">
        <InputGroup className="single-addon-left">
          <InputGroup.Prepend>
            <InputGroup.Text>
              {(currencyObj && currencyObj.symbol) || ''}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl type="text" value={creditValue} disabled={true} />
        </InputGroup>
      </FormGroup>
    );
  };

  renderCashBalance = () => {
    const { selectedCurrency, detailItem } = this.state;
    let symbol = '',
      balance = 0;

    if (selectedCurrency) {
      const cashWallet = _.get(detailItem, 'driverWallet.cashWallet', null);
      const currencyISO = _.get(cashWallet, 'currencyISO', '');
      const currentBalance = _.get(cashWallet, 'currentBalance', 0);
      const pendingBalance = _.get(cashWallet, 'pendingBalance', 0);
      symbol = CurrencyFormatter.findCurrency(currencyISO || selectedCurrency);
      balance = currencyISO ? currentBalance - pendingBalance : 0;
    }

    return (
      <FormGroup className="qup-input-group">
        <InputGroup className="single-addon-left">
          <InputGroup.Prepend>
            <InputGroup.Text>
              {symbol && symbol.symbol}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl type="text" value={balance.toFixed(2)} disabled={true} />
        </InputGroup>
      </FormGroup>
    );
  };

  renderDepositButton = () => {
    const driverInfo = this.state.detailItem.driverInfo;
    let isDisableDeposit = false;
    if (driverInfo && !driverInfo.isActivate) {
      isDisableDeposit = true;
    }
    return isDisableDeposit ? (
      <React.Fragment>
        <p className="m0">{I18n.t('driver.Change_balance')}</p>
        <p className="m0">{I18n.t('driver.Topup_note')}</p>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <a
          onClick={() => {
            this.handleChangeBalanceClick();
          }}
          href="javascript:void(0)"
          className="text-active"
        >
          {I18n.t('driver.Change_balance')}
        </a>
        <p className="m0">{I18n.t('driver.Topup_note')}</p>
      </React.Fragment>
    );
  };

  renderCashWalletButton = () => {
    const driverInfo = this.state.detailItem.driverInfo;
    let isDisableDeposit = false;
    if (driverInfo && !driverInfo.isActivate) {
      isDisableDeposit = true;
    }
    return isDisableDeposit ? (
      <React.Fragment>
        <p className="m0">{I18n.t('driver.Change_balance')}</p>
        {/* <p className="m0">{I18n.t('driver.Topup_note')}</p> */}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <a
          onClick={() => {
            this.handleClickShowCashWalletBalance();
          }}
          href="javascript:void(0)"
          className="text-active"
        >
          {I18n.t('driver.Change_balance')}
        </a>
        {/* <p className="m0">{I18n.t('driver.Topup_note')}</p> */}
      </React.Fragment>
    );
  };

  renderDriverType = () => {
    const { auth, commonData, permissions } = this.props;
    const { detailItem } = this.state;
    const editable =
      (permissions && !permissions.actions) || !this.state.editable;
    const enableDelivery =
      (auth.selectedFleet.delivery && auth.selectedFleet.delivery.enable) ||
      (auth.selectedFleet.food && auth.selectedFleet.food.enable) ||
      (auth.selectedFleet.mart && auth.selectedFleet.mart.enable);

    let driverTypes = [
      { key: '0', value: DriverType.OwnerOperator, name: 'Owner_Operator' },
      {
        key: '1',
        value: DriverType.IndividualDriver,
        name: 'Individual_Driver',
      },
    ];
    const driverTypeValue =  _.get(detailItem, 'driverInfo.driverType', '')

    if(!driverTypeValue) {
      driverTypes.unshift({ 
        key: '-1', 
        value: '', 
        name: 'selectDriverType'
      })  
    }

    if (enableDelivery) {
      driverTypes.push(
        {
          key: '2',
          value: DriverType.deliveryIndividual,
          name: 'deliveryIndividual',
        },
        { key: '3', value: DriverType.deliveryCompany, name: 'deliveryCompany' }
      );
    }

    return (
      <FormGroup
        className={
          !this.state.isSubmited
            ? null
            : !this.state.valid.driverType
            ? 'error'
            : null
        }
      >
        <FormControl
          as="select"
          value={detailItem.driverInfo.driverType}
          placeholder="select"
          onChange={this.handleDriverTypeChange}
          disabled={editable}
          className="form-custom select-driver-type"
        >
          {driverTypes.map((e) => (
            <option key={e.key} value={e.value}>
              {I18n.t(`driver.${e.name}`)}
            </option>
          ))}
        </FormControl>
        <Validator id="driverType" callback={this.validatorCallback}>
          <ValidCase
            hide={!this.state.isSubmited}
            valid={!Validation.isStringEmpty(detailItem.driverInfo.driverType)}
            message={I18n.t('driver.ERROR_INPUT_DRIVER_TYPE')}
          />
        </Validator>
      </FormGroup>
    );
  };

  isBankRequire(key) {
    const { detailItem, bankRequirement } = this.state;
    // const isBankingInfoRequired =
    //   this.props.auth.selectedFleet &&
    //   this.props.auth.selectedFleet.bankingInfo &&
    //   this.props.auth.selectedFleet.bankingInfo.enable &&
    //   this.props.auth.selectedFleet.bankingInfo.verifyAccount ==
    //   SettelementSetting.verifyAccount;
    // if (isBankingInfoRequired) {
    //   return this.state.bankRequirement[key];
    // }
    return bankRequirement[key] ? bankRequirement[key] : false;
  }

  isRideSharingEnabled() {
    return true;
    //TODO: This code should be removed but we wait if there are other chances from PO
    const { carType } = this.state;
    const carTypeList = this.props.commonData
      ? this.props.commonData.carType
      : null;
    if (carTypeList && carTypeList.length > 0) {
      let carTypeSelected = carTypeList.find((obj) => obj._id == carType);
      if (carTypeSelected) {
        return carTypeSelected.dispatchRideSharing;
      }
    }
    return false;
  }

  getDriverValidationInfo = (item, isRequireStripe, isRequireMolpay) => {
    let isBankField;
    if (isRequireMolpay) {
      isBankField = MolpayRequireFields.includes(item.key);
    } else {
      isBankField = BankAccountFields.includes(item.key);
    }
    let validation = {
      isRequired: item.isRequired,
      validationState: null,
      validCases: [],
      bankField: false,
    };

    if (isBankField) {
      validation.isRequired =
        this.isBankRequire(item.key) || isRequireStripe || item.isRequired;
      validation.bankField = true;
      validation.validationState =
        this.state.isSubmited && validation.isRequired
          ? this.state.valid[item.key]
            ? null
            : 'error'
          : null;
      if (validation.isRequired) {
        var newMessage =
          item.key == 'driverId'
            ? I18n.t('driver.ERROR_REQUIRE_') + item.title[this.props.language]
            : I18n.t('driver.ERROR_INPUT_' + item.key.toUpperCase());
        validation.validCases = [
          {
            valid: !Validation.isStringEmpty(
              this.state.driverInfoDynamic[item.key]
            ),
            message: newMessage,
          },
        ];
      }

      if (item.type === 'Email') {
        validation.validCases.push({
          valid: Validation.validateEmail(
            this.state.driverInfoDynamic[item.key]
          ),
          message: I18n.t('driver.ERROR_INPUT_VALID_EMAIL'),
        });
      }

      if (item.type === 'Phone') {
        validation.validCases.push({
          valid: this.state.driverInfoDynamic[item.key]
            ? this.state.phoneFieldValidation[item.key]
            : true,
          message: I18n.t('driver.ERROR_INPUT_PHONE'),
        });
      }
    } else {
      switch (item.key) {
        case 'phone':
          validation.validCases = [
            {
              valid: this.state.driverInfoDynamic.phone
                ? this.state.phoneValidation
                : true,
              message: I18n.t('driver.ERROR_INPUT_PHONE'),
            },
            {
              valid: this.state.driverInfoDynamic.phone
                ? this.state.phoneExistValidation
                : true,
              message: I18n.t('driver.ERROR_EXISTED_PHONE'),
            },
          ];
          break;
        default:
          if (item.type === 'Email') {
            validation.validCases.push({
              valid: Validation.validateEmail(
                this.state.driverInfoDynamic[item.key]
              ),
              message: I18n.t('driver.ERROR_INPUT_VALID_EMAIL'),
            });
          }

          if (item.type === 'Phone') {
            validation.validCases.push({
              valid: this.state.driverInfoDynamic[item.key]
                ? this.state.phoneFieldValidation[item.key]
                : true,
              message: I18n.t('driver.ERROR_INPUT_PHONE'),
            });
          }

          // Check Gender require
          if (item.key === 'gender' && item.isRequired == true) {
            validation.validCases.push({
              valid: this.state.driverInfoDynamic['gender'] != -1,
              message:
                I18n.t('driver.ERROR_REQUIRE_') +
                item.title[this.props.language],
            });
          }
          if (item.type === 'idType' && item.isRequired == true) {
            validation.validCases.push({
              valid: this.state.driverInfoDynamic['idType'] != '',
              message: I18n.t(
                'driver.ERROR_REQUIRE_' + item.title[this.props.language]
              ),
            });
          }

          break;
      }

      if (validation.isRequired) {
        validation.validCases.push({
          valid: !Validation.isStringEmpty(
            this.state.driverInfoDynamic[item.key]
          ),
          message:
            I18n.t('driver.ERROR_REQUIRE_') + item.title[this.props.language],
        });
      }

      validation.validationState =
        !this.state.isSubmited ||
        this.state.valid[item.key] ||
        this.state.valid[item.key] === null ||
        this.state.valid[item.key] === undefined
          ? null
          : 'error';
    }
    return validation;
  };

  renderDriverField = (item, isRequireStripe, isRequireMolpay, editable) => {
    const { detailItem, driverInfoDynamic, isEditForm = false } = this.state;
    const { language } = this.props;
    let validation = this.getDriverValidationInfo(
      item,
      isRequireStripe,
      isRequireMolpay
    );
    let control = null;
    let fieldMapping = DriverInfoFields[item.key];
    switch (item.type) {
      case 'Phone':
        if (item.key === 'phone') {
          control = (
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={this.state.driverInfoDynamic.phone}
              onPhoneNumberBlur={(
                status,
                value,
                countryData,
                number,
                id,
                suggestion,
                isDialCodeOnly
              ) => {
                this.handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  true,
                  isDialCodeOnly
                );
              }}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                this.handlePhoneChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  false
                );
              }}
              disabled={editable || isEditForm}
              id={item.key}
            />
          );
        } else {
          control = (
            <IntlTelInputApp
              css={['intl-tel-input', 'form-control']}
              utilsScript={'libphonenumber.js'}
              value={this.state.driverInfoDynamic[item.key]}
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                this.handlePhoneFieldChange(
                  status,
                  value,
                  countryData,
                  number,
                  id,
                  item.key
                );
              }}
              disabled={editable || isEditForm}
              id={item.key}
            />
          );
        }
        break;
      case 'Date':
        control = (
          <DateTime
            // value={driverInfoDynamic[item.key]}
            value={
              driverInfoDynamic[item.key]
                ? moment(driverInfoDynamic[item.key])
                    .locale(language.toLowerCase())
                    .format('L')
                : ''
            }
            // timeFormat={false}
            dateFormat={true}
            onChange={(e) =>
              this.handleDriverInfoChange({ value: e._d }, item.key)
            }
            inputProps={{ readOnly: true, disabled: editable }}
            closeOnSelect
            placeholder={I18n.t('driver.placeholderDate')}
          />
        );
        break;
      case 'SelectBox':
        if (item.key == 'gender') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={this.state.driverInfoDynamic[item.key]}
              onChange={(e) =>
                this.handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={editable}
            >
              <option key={-1} value={-1}>
                {I18n.t('customer.Select_Gender')}
              </option>
              <option key={0} value={0}>
                {I18n.t('customer.Male')}
              </option>
              <option key={1} value={1}>
                {I18n.t('customer.Female')}
              </option>
              <option key={2} value={2}>
                {I18n.t('customer.Other')}
              </option>
            </FormControl>
          );
        } else if (item.key == 'idType') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={this.state.driverInfoDynamic[item.key]}
              onChange={(e) =>
                this.handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={editable}
            >
              <option key={-1} value="">
                {I18n.t('customer.Select_Idtype')}
              </option>
              {item.options &&
                item.options.length > 0 &&
                item.options.map((idType) => (
                  <option key={idType.key} value={idType.key}>
                    {idType.language[this.props.language]}
                  </option>
                ))}
            </FormControl>
          );
        } else if (item.key.substring(0, 6) === 'backup') {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={driverInfoDynamic[item.key]}
              onChange={(e) =>
                this.handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={editable}
            >
              <option key={0} value={''}>
                {item.title[this.props.language]}
              </option>
              {item && item.options
                ? item.options.map((option) => {
                    return (
                      <option key={option.key} value={option.key}>
                        {option.language[this.props.language]}
                      </option>
                    );
                  })
                : null}
            </FormControl>
          );
        } else {
          control = (
            <FormControl
              className="form-custom"
              as="select"
              value={driverInfoDynamic[item.key]}
              onChange={(e) =>
                this.handleDriverInfoChange({ value: e.target.value }, item.key)
              }
              disabled={editable}
            >
              <option key={0} value={''}>
                {I18n.t(
                  fieldMapping && fieldMapping.selectTitle
                    ? fieldMapping.selectTitle
                    : 'Select'
                )}
              </option>
              {fieldMapping && fieldMapping.optionValue
                ? _.get(
                    fieldMapping.getFromProps ? this.props : this.state,
                    fieldMapping.optionValue,
                    []
                  ).map((s) => {
                    return (
                      <option
                        key={s[fieldMapping.opValueKey]}
                        value={s[fieldMapping.opValueKey]}
                      >
                        {s[fieldMapping.opLabelKey]}
                      </option>
                    );
                  })
                : null}
            </FormControl>
          );
        }
        break;
      case 'File':
        let fileName = '';
        if (
          driverInfoDynamic[item.key] &&
          driverInfoDynamic[item.key].length > 0
        ) {
          let splitUrl = driverInfoDynamic[item.key].split('/');
          fileName = splitUrl[splitUrl.length - 1];
        }

        control = (
          <div>
            {driverInfoDynamic[item.key] &&
            typeof driverInfoDynamic[item.key] === 'string' ? (
              <a
                href={driverInfoDynamic[item.key]}
                className="text-active"
                target="_blank"
              >
                {driverInfoDynamic[item.key].match(/.(jpg|jpeg|png|gif)$/i) ? (
                  <Translate value="driver.Click_here_to_view" />
                ) : (
                  <Translate value="driver.Click_here_to_download" />
                )}
              </a>
            ) : null}
            <UploadFile
              id={item.key}
              name={item.key}
              disabled={editable}
              onChange={(data, error) =>
                this.handleDriverInfoBackupFieldChange(data, error, item.key)
              }
              existFile={
                fileName
                  ? {
                      fileName,
                      onRemove: () =>
                        this.handleRemoveDriverInfoBackupFieldChange(item.key),
                    }
                  : null
              }
            />
          </div>
        );
        break;
      case 'CheckBox':
        control = (
          <CcCheckbox
            checked={
              this.state.driverInfoDynamic[item.key].toString() === 'true'
            }
            onChange={(e) => {
              this.handleDriverInfoChange(
                { value: e.target.checked },
                item.key
              );
            }}
            disabled={editable}
            text={item.title[this.props.language]}
          />
        );
        break;
      default:
        control = (
          <FormControl
            className="form-custom number-no-spin"
            type={item.type.toLowerCase()}
            maxLength={
              fieldMapping && fieldMapping.maxLength
                ? fieldMapping.maxLength
                : ''
            }
            value={this.state.driverInfoDynamic[item.key]}
            onChange={(e) =>
              this.handleDriverInfoChange({ value: e.target.value }, item.key)
            }
            disabled={editable}
            // disabled={validation.bankField ? !bankAccountEditable : editable}
            name={item.key}
          ></FormControl>
        );
        break;
    }

    return (
      <FormGroup className={validation.validationState}>
        {item.type !== 'CheckBox' && (
          <Form.Label>
            <span>{item.title[this.props.language]}</span>
            {validation.isRequired && <span className="require"> *</span>}
          </Form.Label>
        )}
        {control}
        {validation.validCases && validation.validCases.length ? (
          <Validator id={item.key} callback={this.validatorCallback}>
            {_.map(validation.validCases, (validCase, index) => (
              <ValidCase
                key={index}
                hide={!this.state.isSubmited}
                valid={validCase.valid}
                message={validCase.message}
              />
            ))}
          </Validator>
        ) : null}
      </FormGroup>
    );
  };

  // SL-5266 filter zone by currency
  handleSelectCurrency = (e) =>
    this.setState({
      selectedCurrency: e.target.value,
      detailItem: {
        ...this.state.detailItem,
        driverInfo: {
          ...this.state.detailItem.driverInfo,
          zoneId: [],
        },
      },
    });

  render() {
    const {
      auth,
      commonData,
      permissions,
      language,
      selectedFleet: { generalSetting, creditConfig, drvPayout },
    } = this.props;
    const { detailItem, selectedCurrency, carType, statusDriver, valid, enableStripeConnect } =
      this.state;

    if (!auth.selectedFleet || !commonData.relativeInfo) {
      return null;
    }

    if (this.props.params.driverId) {
      if (!detailItem._id) {
        return null;
      }
    }
    const driverType = _.get(detailItem, 'driverInfo.driverType', '')
    const bankingInfoSetting = auth.selectedFleet.bankingInfo || {};
    // const { creditConfig } = auth.selectedFleet;
    // const { walletConfig } = auth.selectedFleet;
    const selectedCurrencyObj = _.find(
      auth.selectedFleet.currencies,
      (i) => i.iso == selectedCurrency
    );
    const selectedCarType = commonData.carType.find((ct) => ct._id == carType);
    const isBankingInfoRequired =
      bankingInfoSetting &&
      bankingInfoSetting.enable &&
      bankingInfoSetting.verifyAccount == SettelementSetting.verifyAccount
        ? true
        : false;

    /**
     * Support MOLpay gateway
     */
    if (drvPayout.enable) {
      var isRequireStripe = false;
      var isRequireMolpay = true;
    } else {
      var isRequireStripe =
        isBankingInfoRequired &&
        !creditConfig.multiGateway &&
        creditConfig.configGateway &&
        creditConfig.configGateway.gateway === paymentGetwaySettlement.Stripe;
    }

    const stripeConnectStatus = _.get(detailItem, 'driverInfo.stripeConnectStatus', '')

    const editable =
      (permissions && !permissions.actions) || !this.state.editable;

    const disableActivateCheckbox =
      (permissions && !permissions.activatedriver) || !this.state.editable;

    let bankAccountEditable = !editable;

    if (
      detailItem.achTokenVerified &&
      creditConfig.configGateway &&
      creditConfig.configGateway.gateway === paymentGetwaySettlement.Stripe
    ) {
      bankAccountEditable = false;
    } else if (detailItem.driverInfo.isBankVerified) {
      bankAccountEditable = false;
    }

    driverIcEditable =
      detailItem.achTokenVerified && drvPayout.enable ? true : editable;
    // const driverDocuments = !commonData.relativeInfo
    //   ? []
    //   : detailItem.driverInfo.driverType === DriverType.IndividualDriver
    //     ? commonData.relativeInfo.documents.individual
    //     : commonData.relativeInfo.documents.owner;
    let driverDocuments = [];
    if (commonData.relativeInfo) {
      switch (detailItem.driverInfo.driverType) {
        case DriverType.IndividualDriver:
          driverDocuments = commonData.relativeInfo.documents.individual;
          break;
        case DriverType.OwnerOperator:
          driverDocuments = commonData.relativeInfo.documents.owner;
          break;
        case DriverType.deliveryIndividual:
          driverDocuments =
            commonData.relativeInfo.documents.deliveryIndividual;
          break;
        case DriverType.deliveryCompany:
          driverDocuments = commonData.relativeInfo.documents.deliveryCompany;
          break;
        default:
          break;
      }
    }

    let isRequiredAvatar = false;
    if (commonData.relativeInfo && this.state.driverFiledList) {
      const avatar = this.state.driverFiledList.find((item) => {
        return item.key === 'avatar';
      });
      if (avatar) {
        isRequiredAvatar = avatar.isRequired;
      }
    }

    let srcImage = detailItem.avatarFile
      ? detailItem.avatarFile
      : detailItem.avatar;

    let isInreview = false;
    if (
      !detailItem.isActive &&
      detailItem.driverInfo &&
      !detailItem.driverInfo.isActivate
    ) {
      isInreview = true;
    }

    let profileStatus =
      detailItem.driverInfo && detailItem.driverInfo.statusReview
        ? detailItem.driverInfo.statusReview
        : '';

    // let isCarTypeDelivery = false;
    // if (carType &&
    //   (auth.selectedFleet.fleetId === 'gojosg' || auth.selectedFleet.fleetId === 'demo-gojo')
    // ) {
    //   let carTypeIndex = commonData.carTypeDelivery.findIndex(e => e._id === carType);
    //   isCarTypeDelivery = carTypeIndex === -1 ? false : true;
    // };

    let newSaveOptions = saveOptions;
    if (CCLiteCommonFunc.isFormValid(valid)) {
      newSaveOptions = newSaveOptions.filter(
        (elem) => elem.key !== 'inReviewInProgress'
      );
    }

    return (
      <Modal
        show={this.state.visible}
        onHide={this.handleCancelClick}
        backdrop="static"
        aria-labelledby="contained-modal-title-sm"
        dialogClassName="add-edit-driver-dialog"
      >
        <Modal.Header closeButton />
        <Modal.Body className="clearfix">
          <Row className="show-grid">
            <Col xs={12}>
              <Col
                xs={12}
                className="add-edit-part no-flex add-edit-part-header"
              >
                <div className="display-flex justify-content-center align-items-start">
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : !this.state.valid.avatar
                        ? 'error'
                        : null
                    }
                  >
                    <div className="driver-avatar-container">
                      <Image
                        className="driver-avatar"
                        src={
                          detailItem.avatarFile
                            ? detailItem.avatarFile
                            : detailItem.avatar
                            ? detailItem.avatar
                            : avatar.driver
                        }
                        circle
                      />
                      <UploadFile
                        id="avatar"
                        name="avatar"
                        accepts={IMAGE_ACCEPTED}
                        onChange={this.handleAvatarChange}
                        disabled={editable}
                        customTemplate={UploadAvatarTemplate}
                        isRequiredAvatar={isRequiredAvatar}
                      />
                    </div>
                    {isRequiredAvatar ? (
                      <Validator id="avatar" callback={this.validatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmited}
                          valid={!Validation.isStringEmpty(srcImage)}
                          message={I18n.t('driver.ERROR_INPUT_AVATAR')}
                        />
                      </Validator>
                    ) : null}
                  </FormGroup>

                  <FormGroup>
                    <Form.Label>
                      <Translate value="driver.Driver_Type" />
                    </Form.Label>
                    <div className="display-flex justify-content-start align-items-center">
                      {this.renderDriverType()}
                    </div>
                    {isInreview && detailItem._id ? (
                      <div className="display-flex driver-profile">
                        <div className="driver-status">
                          <p>{I18n.t('driver.In_Review')}</p>
                        </div>
                        <div className="display-flex profile-status">
                          <p>
                            {profileStatus === 'completed'
                              ? I18n.t('driver.profile_completed')
                              : profileStatus === 'inComplete'
                              ? I18n.t('driver.profile_incomplete')
                              : I18n.t('driver.profile_inProgress')}
                          </p>
                          <span className="checkmark">
                            <div
                              className={`checkmark_circle ${
                                profileStatus === 'completed'
                                  ? ''
                                  : 'incomplete'
                              }`}
                            ></div>
                            <div className="checkmark_stem"></div>
                            <div className="checkmark_kick"></div>
                          </span>
                        </div>
                      </div>
                    ) : null}
                  </FormGroup>
                </div>
              </Col>
            </Col>
          </Row>
          <div className="part-container">
            <Row className="show-grid">
              <Col md={6} mdPush={6} className="pl-sm">
                {detailItem._id &&
                commonData.relativeInfo.pricingSettings &&
                commonData.relativeInfo.pricingSettings.driverDeposit.enable ? (
                  <Col xs={12} className="add-edit-part">
                    <Col xs={12}>
                      <FormGroupTitle>
                        <Translate value="driver.CREDIT_DEPOSIT" />
                      </FormGroupTitle>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="driver.Balance" />
                        </Form.Label>
                        {this.renderDriverDeposit()}
                        {!permissions || permissions.changebalance
                          ? this.renderDepositButton()
                          : null}
                      </FormGroup>
                    </Col>
                  </Col>
                ) : null}

                {detailItem._id && auth.selectedFleet.driverCashWallet ? (
                  <Col xs={12} className="add-edit-part">
                    <Col xs={12}>
                      <FormGroupTitle>
                        <Translate value="driver.cash_wallet" />
                      </FormGroupTitle>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="driver.Balance" />
                        </Form.Label>
                        {this.renderCashBalance()}
                        {!permissions || permissions.changecashbalance
                          ? this.renderCashWalletButton()
                          : null}
                      </FormGroup>
                    </Col>
                  </Col>
                ) : null}
                {(detailItem.driverInfo.driverType ===
                  DriverType.OwnerOperator ||
                  detailItem.driverInfo.driverType ===
                    DriverType.deliveryIndividual) &&
                generalSetting.showInInsuranceFieldsAndVhcLicenseExp ? (
                  <Col xs={12} className="add-edit-part">
                    <Col xs={12}>
                      <FormGroupTitle>
                        <Translate value="driver.CAR_INSURANCE" />
                      </FormGroupTitle>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          !this.state.isSubmited ||
                          !generalSetting.compulsoryCarInsurance
                            ? null
                            : !this.state.valid.policyNumber
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="driver.Policy_number" />
                          {generalSetting.compulsoryCarInsurance && (
                            <span className="require"> *</span>
                          )}
                        </Form.Label>
                        <FormControl
                          type="text"
                          value={detailItem.driverInfo.policyNumber}
                          onChange={this.handleCheckPolicyNumber}
                          disabled={editable}
                          className="form-custom"
                        />
                        <Validator
                          id="policyNumber"
                          callback={this.validatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={
                              !generalSetting.compulsoryCarInsurance ||
                              !Validation.isStringEmpty(
                                detailItem.driverInfo.policyNumber
                              )
                            }
                            message={'Please input policy number.'}
                          />
                        </Validator>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          !this.state.isSubmited ||
                          !generalSetting.compulsoryCarInsurance
                            ? null
                            : !this.state.valid.effectiveDate
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="driver.Effective_date" />
                          {generalSetting.compulsoryCarInsurance && (
                            <span className="require"> *</span>
                          )}
                        </Form.Label>
                        <FormControl
                          type="text"
                          value={detailItem.driverInfo.effectiveDate}
                          onChange={this.handleCheckEffectiveDate}
                          disabled={editable}
                          className="form-custom"
                        />
                        <Validator
                          id="effectiveDate"
                          callback={this.validatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={
                              !generalSetting.compulsoryCarInsurance ||
                              !Validation.isStringEmpty(
                                detailItem.driverInfo.effectiveDate
                              )
                            }
                            message={'Please input effective date.'}
                          />
                        </Validator>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup
                        className={
                          !this.state.isSubmited ||
                          !generalSetting.compulsoryCarInsurance
                            ? null
                            : !this.state.valid.expiredDate
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="driver.Expired_date" />
                          {generalSetting.compulsoryCarInsurance && (
                            <span className="require"> *</span>
                          )}
                        </Form.Label>
                        <FormControl
                          type="text"
                          value={detailItem.driverInfo.expiredDate}
                          onChange={this.handleCheckExpiredDate}
                          disabled={editable}
                          className="form-custom"
                        />
                        {generalSetting.compulsoryCarInsurance && (
                          <Validator
                            id="expiredDate"
                            callback={this.validatorCallback}
                          >
                            <ValidCase
                              hide={!this.state.isSubmited}
                              valid={
                                !Validation.isStringEmpty(
                                  detailItem.driverInfo.expiredDate
                                )
                              }
                              message={'Please input expired date'}
                            />
                          </Validator>
                        )}
                      </FormGroup>
                    </Col>
                  </Col>
                ) : null}
                <Operation
                  carMakeList={this.state.carMakeList}
                  editable={editable}
                  editFleetCommission={
                    editable === false
                      ? auth.user.isAdmin
                        ? false
                        : !permissions.editfleetcommission
                      : editable
                  }
                  isSubmited={this.state.isSubmited}
                  valid={this.state.valid}
                  detailItem={detailItem}
                  isExistingCar={this.state.isExistingCar}
                  vehicleByCompany={this.state.vehicleByCompany}
                  isVehicleDidntAssign={this.state.isVehicleDidntAssign}
                  isVhicleNoType={isVhicleNoType}
                  licensePlate={this.state.licensePlate}
                  carType={this.state.carType}
                  vehicleOwner={this.state.vehicleOwner}
                  carMake={this.state.carMake}
                  carModel={this.state.carModel}
                  color={this.state.color}
                  passengers={this.state.passengers}
                  luggage={this.state.luggage}
                  licenseExpiry={this.state.licenseExpiry}
                  caseNumber={this.state.caseNumber}
                  vhcPhone={this.state.vhcPhone}
                  vhcPhoneValidation={this.state.vhcPhoneValidation}
                  commonData={commonData}
                  auth={auth}
                  selectedCarType={selectedCarType}
                  year={this.state.year}
                  yearsElement={yearsElement}
                  commission={this.state.commission}
                  listShifts={this.state.listShifts}
                  //funcs
                  handleCompanyChange={this.handleCompanyChange}
                  validatorCallback={this.validatorCallback}
                  handleExistingCarChange={this.handleExistingCarChange}
                  handleLicensePlateComboChange={
                    this.handleLicensePlateComboChange
                  }
                  handleLicensePlateChange={this.handleLicensePlateChange}
                  validateLicensePlate={this.validateLicensePlate}
                  handleCarTypeChange={this.handleCarTypeChange}
                  handleVehicleOwnerChange={this.handleVehicleOwnerChange}
                  handleCarMakeChange={this.handleCarMakeChange}
                  handleCarModelChange={this.handleCarModelChange}
                  handleYearChange={this.handleYearChange}
                  handleColorChange={this.handleColorChange}
                  handlePassengersChange={this.handlePassengersChange}
                  handleLuggageChange={this.handleLuggageChange}
                  handleLicenseExpiryChange={this.handleLicenseExpiryChange}
                  handlecaseNumberChange={this.handlecaseNumberChange}
                  handleVhcPhoneChange={this.handleVhcPhoneChange}
                  handleZoneChange={this.handleZoneChange}
                  handleShiftChange={this.handleShiftChange}
                  handleTerminalIdChange={this.handleTerminalIdChange}
                  handleAuthKeyChange={this.handleAuthKeyChange}
                  handleCommissionChange={this.handleCommissionChange}
                  handleCommissionTypeChange={this.handleCommissionTypeChange}
                  handleCommissionDriverChange={
                    this.handleCommissionDriverChange
                  }
                  handleCommissionCompanyChange={
                    this.handleCommissionCompanyChange
                  }
                  handleTabSelect={this.handleTabSelect}
                  handleSearchLicensePlate={this.handleSearchLicensePlate}
                  handleLoadMoreLicensePlate={this.handleLoadMoreLicensePlate}
                  selectedCurrency={this.state.selectedCurrency}
                  handleSelectCurrency={this.handleSelectCurrency}
                  referralCode={this.state.detailItem.referralCode}
                  driverType={detailItem.driverInfo.driverType}
                />
                {
                  enableStripeConnect && (
                    <Col xs={12} className="add-edit-part">
                      <Col xs={12}>
                        <FormGroupTitle>
                          <Translate value="driver.Stripe_connect" />
                        </FormGroupTitle>
                        <p>
                          {
                            I18n.t('driver.status_stripe')
                              .format(stripeConnectStatus === 'activated' 
                                ? 'Active' 
                                : stripeConnectStatus === 'pending'
                                  ? 'In Review'
                                  :'Inactive'
                              )
                          }
                        </p>
                        {stripeConnectStatus !== 'activated' &&
                          <p style={{color: "#F2D45F"}}>
                            Stripe Connect account is required to activate this driver profile.
                          </p>
                        }
                        <FormGroup>
                        <Form.Label>
                          <Translate value="companySetting.stripeConnectId" />{" "}
                        </Form.Label>
                        <FormControl
                          type="text"
                          className={"form-custom"}
                          onChange={this.handleStripeAccountIdChange}
                          value={this.state.stripeAccountId}
                          disabled={editable}
                          placeholder={I18n.t("companySetting.stripeConnectId")}
                        />
                      </FormGroup>
                      </Col>
                    </Col>
                  )
                }
                {auth.selectedFleet 
                && bankingInfoSetting.enable
                && !enableStripeConnect ? (
                  <BankAccount
                    isSubmited={this.state.isSubmited}
                    valid={this.state.valid}
                    detailItem={detailItem}
                    verificationDocument={this.state.verificationDocument}
                    verificationDocumentBack={
                      this.state.verificationDocumentBack
                    }
                    additionalDocument={this.state.additionalDocument}
                    additionalDocumentBack={this.state.additionalDocumentBack}
                    auth={auth}
                    bankAccountEditable={
                      drvPayout.enable ? !driverIcEditable : bankAccountEditable
                    }
                    bankRequirement={this.state.bankRequirement}
                    bankInfoList={this.state.bankInfoList}
                    editable={editable}
                    reg={reg}
                    FILE_ACCEPTED={FILE_ACCEPTED}
                    isRequireStripe={isRequireStripe}
                    isBankRequire={this.isBankRequire}
                    handleNameOfBankChange={this.handleNameOfBankChange}
                    validatorCallback={this.validatorCallback}
                    handleNameOfAccountChange={this.handleNameOfAccountChange}
                    handleRTBNumberChange={this.handleRTBNumberChange}
                    handleAccountNumberChange={this.handleAccountNumberChange}
                    handleIFSCCodeChange={this.handleIFSCCodeChange}
                    handleCheckNumberChange={this.handleCheckNumberChange}
                    handleSwiftCodeNumberChange={
                      this.handleSwiftCodeNumberChange
                    }
                    handleSSNChange={this.handleSSNChange}
                    handleSortCodeChange={this.handleSortCodeChange}
                    handleVerificationDocumentChange={
                      this.handleVerificationDocumentChange
                    }
                    handleVerificationDocumentBackChange={
                      this.handleVerificationDocumentBackChange
                    }
                    handleAdditionalDocumentChange={
                      this.handleAdditionalDocumentChange
                    }
                    handleAdditionalDocumentBackChange={
                      this.handleAdditionalDocumentBackChange
                    }
                    onRemoveBankInfo={this.handleRemoveBankInfo}
                    handleRemoveBankDocumentChange={
                      this.handleRemoveBankDocumentChange
                    }
                    handeChangeIsBankAccountOwner={
                      this.handeChangeIsBankAccountOwner
                    }
                    handleChangebeneficiary={this.handleChangebeneficiary}
                  />
                ) : null}
                  {
                    auth.selectedFleet &&
                    auth.selectedFleet.marketplace &&
                    auth.selectedFleet.marketplace.enable && (
                      <Col xs={12} className="add-edit-part">
                        <Col xs={12}>
                          <FormGroupTitle>
                            <Translate value="driver.MARKETPLACE" />
                          </FormGroupTitle>
                          <CcCheckbox
                            checked={detailItem.marketplace == 1}
                            onChange={this.handleMarketplaceChange}
                            disabled={editable}
                            text={<Translate value="driver.Market_place" />}
                          >
                            <span>
                              {I18n.t('driver.Market_place')}{' '}
                              <OverlayTrigger
                                placement="top"
                                overlay={marketplaceToolTip}
                              >
                                <BsQuestionCircleFill />
                              </OverlayTrigger>
                            </span>
                          </CcCheckbox>
                        </Col>
                        {
                          detailItem.marketplace == 1 &&
                          <>
                            <Col xs={6}>
                              <FormGroup>
                                <FormControl
                                  as="select"
                                  value={_.get(detailItem, 'marketplaceSettingMode', 'default')}
                                  placeholder="select"
                                  onChange={this.handleMarketplaceTypeChange}
                                  disabled={disableActivateCheckbox}
                                  className="form-custom select-driver-type"
                                >
                                  <option key={'default'} value={'default'}>
                                    {I18n.t(`driver.default`)}
                                  </option>
                                  <option key={'customized'} value={'customized'}>
                                    {I18n.t(`driver.customize`)}
                                  </option>
                                </FormControl>
                              </FormGroup>
                            </Col>
                            {
                              _.get(detailItem, 'marketplaceSettingMode', 'default') === 'customized'
                              && (
                                <>
                                  <Col xs={6}></Col>
                                  <Col xs={6}>
                                    <FormGroup>
                                      <Form.Label>
                                        <Translate value="driver.acceptInDay" />
                                        <QuestionCircleTooltip
                                          text={
                                            <Translate
                                              value="dispatchSetting.Dispatch_setting_Maximum_Day_tooltip"
                                              dangerousHTML
                                            />
                                          }
                                        />
                                      </Form.Label>
                                      <FormControl
                                        className="form-custom"
                                        type="text"
                                        value={_.get(detailItem, 'maximumAcceptReservationPerDay', 0)}
                                        onChange={e => this.handleAcceptChange(e, 'maximumAcceptReservationPerDay')}
                                        disabled={editable}
                                        maxLength={20}
                                        autoFocus
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col xs={6}>
                                    <FormGroup>
                                      <Form.Label>
                                        <Translate value="driver.acceptATime" />
                                        <QuestionCircleTooltip
                                          text={
                                            <Translate
                                              value="dispatchSetting.Maximum_Accept_Reservation_tooltip"
                                              dangerousHTML
                                            />
                                          }
                                        />
                                      </Form.Label>
                                      <FormControl
                                        className="form-custom"
                                        type="text"
                                        value={_.get(detailItem, 'maximumAcceptReservation', 0)}
                                        onChange={e => this.handleAcceptChange(e, 'maximumAcceptReservation')}
                                        disabled={editable}
                                        maxLength={20}
                                        autoFocus
                                      />
                                    </FormGroup>
                                  </Col>
                                </>
                              )
                            }
                          </>
                        }
                      </Col>
                    )
                  }
                <Col xs={12} className="add-edit-part">
                  {auth.selectedFleet && auth.selectedFleet.hardwareMeter ? (
                    <Col xs={12} md={6}>
                      <CcCheckbox
                        checked={detailItem.driverInfo.forceMeter}
                        onChange={this.handleForceMeterChange}
                        disabled={editable}
                        text={I18n.t('driver.Force_meter')}
                      >
                        <span>
                          {I18n.t('driver.Force_meter')}{' '}
                          <OverlayTrigger placement="top" overlay={tooltip}>
                            <BsQuestionCircleFill />
                          </OverlayTrigger>
                        </span>
                      </CcCheckbox>
                    </Col>
                  ) : null}
                  <Col xs={12} md={6}>
                    <CcCheckbox
                      checked={detailItem.rank == 1}
                      onChange={this.handleTopDriverChange}
                      disabled={editable}
                      text={<Translate value="driver.TOP_driver" />}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <CcCheckbox
                      checked={detailItem.rideSharing == 1}
                      onChange={this.handleRideSharingChange}
                      disabled={editable || !this.isRideSharingEnabled()}
                      text={<Translate value="driver.Ride_sharing" />}
                    >
                      <span>
                        {I18n.t('driver.Ride_sharing')}{' '}
                        <OverlayTrigger
                          placement="top"
                          overlay={rideSharingToolTip}
                        >
                          <BsQuestionCircleFill />
                        </OverlayTrigger>
                      </span>
                    </CcCheckbox>
                  </Col>
                  {auth.selectedFleet &&
                  auth.selectedFleet.allowDrvTopupDriverViaCreditWallet ? (
                    <Col xs={12} md={6}>
                      <CcCheckbox
                        checked={detailItem.driverInfo.hasTopUpOtherDriver}
                        onChange={this.handleTopUpOtherDriverChange}
                        disabled={editable}
                        text={'Top up other drivers'}
                      >
                        Top up other drivers
                      </CcCheckbox>
                    </Col>
                  ) : null}
                  <Col xs={12} md={6}>
                    {/* <CcCheckbox
                      checked={detailItem.isActive == 1}
                      onChange={this.handleActiveChange}
                      disabled={disableActivateCheckbox}
                      text={<Translate value="driver.Active" />}
                    /> */}
                    <FormControl
                      as="select"
                      value={statusDriver}
                      placeholder="select"
                      onChange={this.handleActiveChange}
                      disabled={disableActivateCheckbox}
                      className="form-custom select-driver-type"
                    >
                      {newSaveOptions.map((e) => (
                        <option key={e.key} value={e.value}>
                          {I18n.t(`driver.${e.key}`)}
                        </option>
                      ))}
                    </FormControl>
                  </Col>
                </Col>
              </Col>
              <Col md={6} mdPull={6} className="pr-sm">
                <Col xs={12} className="add-edit-part">
                  <Col xs={12}>
                    <FormGroupTitle>
                      <Translate value="driver.CONTACT_INFO" />
                    </FormGroupTitle>
                    {
                      !driverType &&
                      <p className='Inreview'>
                        <Translate value='driver.PlsSelectDriverType'/>
                      </p>
                    }
                  </Col>
                  {auth.selectedFleet &&
                  auth.selectedFleet.password &&
                  auth.selectedFleet.password.driver &&
                  auth.selectedFleet.password.regBy == 1 ? (
                    <Col md={6} xs={12}>
                      <FormGroup
                        className={
                          !this.state.isSubmited
                            ? null
                            : !this.state.valid.username
                            ? 'error'
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="driver.Username" />{' '}
                          <span className="require"> *</span>
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          value={detailItem.username}
                          onChange={this.handleUsernameChange}
                          disabled={editable}
                          maxLength={20}
                          autoFocus
                        />
                        <Validator
                          id="username"
                          callback={this.validatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={
                              !Validation.isStringEmpty(detailItem.username)
                            }
                            message={I18n.t('driver.ERROR_INPUT_USERNAME')}
                          />
                          <ValidCase
                            hide={!this.state.isSubmited}
                            valid={Validation.validateUsername(
                              detailItem.username
                            )}
                            message={I18n.t(
                              'driver.ERROR_INPUT_VALID_USERNAME'
                            )}
                          />
                        </Validator>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {commonData.relativeInfo &&
                  this.state.driverFiledList &&
                  Object.keys(this.state.driverInfoDynamic).length
                    ? _.map(this.state.driverFiledList, (item) =>
                        item.key === 'company' ||
                        item.key === 'avatar' ? null : (
                          <Col xs={12} md={6} key={item.key}>
                            {this.renderDriverField(
                              item,
                              isRequireStripe,
                              isRequireMolpay,
                              editable
                            )}
                          </Col>
                        )
                      )
                    : null}
                </Col>
                <div>
                  <Col xs={12} className="add-edit-part">
                    <Col xs={12}>
                      <FormGroupTitle>
                        <Translate value="driver.FOR_INSPECTOR" />
                      </FormGroupTitle>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="driver.Document" />
                        </Form.Label>{' '}
                        <br />
                        {detailItem.driverInfo.document ? (
                          <a
                            href={detailItem.driverInfo.document}
                            className="text-active"
                          >
                            <Translate value="driver.Click_here_to_download" />
                          </a>
                        ) : null}
                        <UploadFile
                          id={'document'}
                          name={'driver_document'}
                          onChange={this.handleDocumentChange}
                          disabled={editable}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="driver.Link" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          value={detailItem.driverInfo.link}
                          onChange={this.handleLinkChange}
                          disabled={editable}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={12}>
                      <Note
                        notes={detailItem.driverInfo.notes}
                        handleNoteChange={this.handleNoteChange}
                        disabled={editable}
                      />
                    </Col>
                  </Col>
                </div>
                <div>
                  <Col xs={12} className="add-edit-part">
                    <Col xs={12}>
                      <FormGroupTitle>
                        <Translate
                          value="driver.UPLOAD_DOCUMENT"
                          className="mb-xl"
                        />
                        <div className="text-base extension-requirement mt">
                          <Translate value="driver.EXTENSION_REQUIREMENT" />
                        </div>
                      </FormGroupTitle>
                      {
                        !driverType &&
                        <p className='Inreview'>
                          <Translate value='driver.PlsSelectDriverType'/>
                        </p>
                      }
                    </Col>
                    {driverDocuments.map((d, index) => {
                      let document = detailItem.documentExpiry
                        ? detailItem.documentExpiry.find(
                            (doc) => doc.documentKey == d.keyName
                          )
                        : null;
                      let documentName = '';
                      if (
                        document &&
                        document.documentUrl &&
                        document.documentUrl.length > 0
                      ) {
                        let splitUrl = document.documentUrl.split('/');
                        documentName = splitUrl[splitUrl.length - 1];
                      }

                      let existFile =
                        this.isShowDownloadDocument(document) && documentName;
                      let nameDocument =
                        d.title && d.title[this.props.language]
                          ? d.title[this.props.language]
                          : d.documentName;
                      return (
                        <Col xs={12} key={d.keyName}>
                          <FormGroup>
                            <Col xs={12} md={nameDocument.length > 40 ? 12 : 6}>
                              <FormGroup
                                className={
                                  !this.state.isSubmited
                                    ? null
                                    : this.state.valid[d.keyName] === false
                                    ? 'error'
                                    : null
                                }
                              >
                                <Form.Label>
                                  {nameDocument}{' '}
                                  {/* KAN-1456 Hide required documents */}
                                  {d.isRequired && (
                                    <span className="require"> *</span>
                                  )}
                                </Form.Label>
                                <br />
                                {existFile && this.state.isView ? (
                                  <div>
                                    <a
                                      href={document.documentUrl}
                                      className="text-active"
                                      target="_blank"
                                    >
                                      {document.documentUrl.match(
                                        /.(jpg|jpeg|png|gif)$/i
                                      ) ? (
                                        <Translate value="driver.Click_here_to_view" />
                                      ) : (
                                        <Translate value="driver.Click_here_to_download" />
                                      )}
                                    </a>
                                  </div>
                                ) : null}
                                <UploadFile
                                  id={d.keyName}
                                  name={d.keyName}
                                  disabled={editable}
                                  onChange={(data, error) =>
                                    this.handleDriverDocumentChange(
                                      data,
                                      error,
                                      d.keyName,
                                      index
                                    )
                                  }
                                  existFile={
                                    existFile
                                      ? {
                                          fileName: documentName,
                                          onRemove: () =>
                                            this.handleRemoveSelectedDriverDocument(
                                              d.keyName
                                            ),
                                        }
                                      : null
                                  }
                                />
                              </FormGroup>
                              {d.isRequired && (
                                <FormGroup>
                                  <Validator
                                    id={d.keyName}
                                    callback={this.validatorCallback}
                                  >
                                    <ValidCase
                                      key={d.keyName}
                                      hide={!this.state.isSubmited}
                                      valid={
                                        (this.state.driverDocumentExpiry[
                                          index
                                        ] &&
                                          this.state.driverDocumentExpiry[index]
                                            .documentUrl) ||
                                        this.state.driverDocument[d.keyName]
                                          ? true
                                          : false
                                      }
                                      message={
                                        I18n.t('driver.ERROR_INPUT_DOCUMENT') +
                                        ' ' +
                                        nameDocument
                                      }
                                    />
                                  </Validator>
                                </FormGroup>
                              )}
                            </Col>
                            {d.isExpiredDate ? (
                              <Col xs={12} md={6}>
                                <FormGroup
                                  className={
                                    !this.state.isSubmited
                                      ? null
                                      : this.state.valid[
                                          d.keyName + '-expiredDate'
                                        ] === false
                                      ? 'error'
                                      : null
                                  }
                                >
                                  <Form.Label>
                                    <Translate value="driver.Expiry_Date" />
                                    {d.requireExpiredDate && (
                                      <span className="require"> *</span>
                                    )}
                                  </Form.Label>
                                  <DateTime
                                    inputProps={{
                                      readOnly: true,
                                      className: 'date-readonly form-control',
                                      disabled:
                                        editable ||
                                        this.isDisableExpiryDate(d.keyName),
                                    }}
                                    value={
                                      this.state.driverDocumentExpiry[index] &&
                                      this.state.driverDocumentExpiry[index]
                                        .expiredDate
                                        ? moment(
                                            this.state.driverDocumentExpiry[
                                              index
                                            ].expiredDate
                                          )
                                            .locale(language.toLowerCase())
                                            .format('L')
                                        : null
                                    }
                                    // timeFormat={"MM/DD/YYYY"}
                                    dateFormat={true}
                                    onChange={(e) => {
                                      this.onExpiryDateChange(e, d, index);
                                    }}
                                    isValidDate={this.validExpiryDate}
                                    placeholder={
                                      I18n.t('driver.Expiry_Date') +
                                      ' ' +
                                      I18n.t('driver.placeholderDate')
                                    }
                                    closeOnSelect
                                    checkShowOnTop
                                    parrentMarginBottom={30}
                                    id={d.keyName + '-expiredDate'}
                                  />
                                </FormGroup>
                                {d.isExpiredDate && d.requireExpiredDate && (
                                  <FormGroup>
                                    <Validator
                                      id={d.keyName + '-expiredDate'}
                                      callback={this.validatorCallback}
                                    >
                                      <ValidCase
                                        key={d.keyName + '-expiredDate'}
                                        hide={!this.state.isSubmited}
                                        valid={
                                          this.state.driverDocumentExpiry[
                                            index
                                          ] &&
                                          this.state.driverDocumentExpiry[index]
                                            .expiredDate
                                            ? true
                                            : false
                                        }
                                        message={I18n.t(
                                          'driver.ERROR_INPUT_EXPIRYDATE'
                                        )}
                                      />
                                    </Validator>
                                  </FormGroup>
                                )}
                              </Col>
                            ) : null}
                            {d.documentId ? (
                              <Col xs={12} md={6}>
                                <FormGroup
                                  className={
                                    !this.state.isSubmited
                                      ? null
                                      : this.state.valid[
                                          d.keyName + '-documentId'
                                        ] === false
                                      ? 'error'
                                      : null
                                  }
                                >
                                  <Form.Label>
                                    <Translate value="driver.Document_Id" />
                                    {d.requireDocumentId && (
                                      <span className="require"> *</span>
                                    )}
                                  </Form.Label>
                                  <FormControl
                                    className="form-custom"
                                    type="text"
                                    id={d.keyName + '-documentId'}
                                    maxLength={50}
                                    value={
                                      this.state.driverDocumentExpiry[index] &&
                                      this.state.driverDocumentExpiry[index]
                                        .documentId
                                        ? this.state.driverDocumentExpiry[index]
                                            .documentId
                                        : ''
                                    }
                                    onChange={(e) => {
                                      this.handleDocumentKeyChange(e, d, index);
                                    }}
                                    disabled={editable}
                                  />
                                </FormGroup>
                                {d.documentId && d.requireDocumentId && (
                                  <FormGroup>
                                    <Validator
                                      id={d.keyName + '-documentId'}
                                      callback={this.validatorCallback}
                                    >
                                      <ValidCase
                                        key={d.keyName + '-documentId'}
                                        hide={!this.state.isSubmited}
                                        valid={
                                          this.state.driverDocumentExpiry[
                                            index
                                          ] &&
                                          this.state.driverDocumentExpiry[index]
                                            .documentId
                                            ? true
                                            : false
                                        }
                                        message={I18n.t(
                                          'driver.ERROR_INPUT_DOCUMETID'
                                        )}
                                      />
                                    </Validator>
                                  </FormGroup>
                                )}
                              </Col>
                            ) : null}
                            <div
                              className="col-xs-12"
                              style={{
                                borderBottom: '2px solid rgb(58 56 56)',
                                margin: '5px 10px',
                                width: '96%',
                              }}
                            ></div>
                          </FormGroup>
                        </Col>
                      );
                    })}
                  </Col>
                </div>

                {this.props.params.driverId ? (
                  <DriverEmergencyContacts
                    selectedFleet={this.props.auth.selectedFleet}
                    getEmergencyContactDriver={
                      this.props.getEmergencyContactDriver
                    }
                    userId={this.props.params.driverId}
                  />
                ) : null}
              </Col>
            </Row>
            <div className="text-left mb-md">
              {auth.selectedFleet.password.driver ? (
                <Translate value="driver.Note_password" />
              ) : null}
              (<span className="require">*</span>
              ): <Translate value="driver.Required_fields" />
            </div>
            <div className="text-center mb-md btn-edit-driver-group">
              {!permissions || permissions.actions ? (
                this.state.editable ? (
                  <Button
                    className="btn-save mr-md"
                    onClick={this.handleClickSave}
                  >
                    <Translate value="driver.Save" />
                  </Button>
                ) : (
                  <Button
                    className="btn-save mr-md"
                    onClick={(e) => {
                      this.handleChangeStatus(e);
                    }}
                  >
                    <Translate value="driver.Edit" />
                  </Button>
                )
              ) : null}
              <Button
                className="btn-cancel ml0"
                onClick={this.handleCancelClick}
              >
                <Translate value="driver.Cancel" />
              </Button>

              {(!permissions || permissions.actions) &&
              detailItem._id &&
              auth.selectedFleet.password.driver ? (
                <Button
                  className="btn-reset ml-md"
                  onClick={this.handleResetPasswordClick}
                >
                  <Translate value="user.Reset_password" />
                </Button>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Balance
          isShow={this.state.isShowDriverDeposit}
          creditBalances={detailItem.driverInfo.creditBalances}
          currencies={[selectedCurrencyObj]}
          userId={detailItem._id}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
          handleCreditBalanceSuccess={this.handleCreditBalanceSuccess}
          currency={selectedCurrencyObj}
        />
        <CashWalletBalance
          isShow={this.state.isShowCashWalletBalance}
          cashBalance={
            detailItem.driverWallet && detailItem.driverWallet.cashWallet
              ? detailItem.driverWallet.cashWallet
              : {}
          }
          currencies={[selectedCurrencyObj]}
          userId={detailItem._id}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
          handleChangeCashBalanceSuccess={this.handleChangeCashBalanceSuccess}
          currency={selectedCurrencyObj}
        />
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </Modal>
    );
  }
}

AddEdit.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    auth: state.auth,
    selectedFleet: state.auth.selectedFleet,
    commonData: state.commonData,
    permissions: state.menuHandle.modulePermission,
    language: state.i18n && state.i18n.locale ? state.i18n.locale : 'en-US',
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchVehicleByCompany: (options) => {
      return dispatch(fetchVehicleByCompany(options));
    },
    findOneVehicle: (options) => {
      return dispatch(findOneVehicle(options));
    },
    findOneDriver: (options) => {
      return dispatch(findOneDriver(options));
    },
    resestPassword: (options) => {
      return dispatch(resestPassword(options));
    },
    checkExistDriverPhone: (options) => {
      return dispatch(checkExistDriverPhone(options));
    },
    fetchRelativeInfo: (options) => {
      return dispatch(fetchRelativeInfo(options));
    },
    operateDriver: (options, isEdit) => {
      return dispatch(operateDriver(options, isEdit));
    },
    updateDriverInfo: (options) => {
      return dispatch(updateDriverInfo(options));
    },
    getBankAccountRequireField: (options) => {
      return dispatch(getBankAccountRequireField(options));
    },
    removeBankInfo: (options) => {
      return dispatch(removeBankInfo(options));
    },
    setDocumentsMissing: (options) => {
      return dispatch(setDocumentsMissing(options));
    },
    getEmergencyContactDriver: (options) => {
      return dispatch(getEmergencyContactDriver(options));
    },
    fetchCompanies: bindActionCreators(fetchCompanies, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    loadMoreVehicles: bindActionCreators(loadMoreVehicles, dispatch),
    loadMoreVehiclesByType: bindActionCreators(
      loadMoreVehiclesByType,
      dispatch
    ),
    settingActions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEdit);
