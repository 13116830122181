import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { I18n, Translate } from 'react-redux-i18n';
import SelectMultiple from '../../../../components/SelectMultiple';
import { ValidCase, Validator } from '../../../../components/validator';
import _ from 'lodash';
import styles from '../../styles/formSetting.module.scss';
import {
  Validation,
  textboxNumberHelper,
} from '../../../../utils/commonFunctions';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import { UNIT_TIME } from '../../../../constants/commondata';
import Select from '../../../../components/select/Select';

const FormSetting = (valProps) => {
  const {
    props,
    state,
    editable,
    detailItem,
    handleConnectTokenChange,
    closeDialogForm,
    saveDialogForm,
    onEditable,
    handleEmailsApplyChange,
    emailList,
    handleLoadOptionDebounce,
  } = valProps;
  const { action } = props;
  const companyCommission = _.get(
    props.auth,
    'selectedFleet.generalSetting.companyCommission',
    false
  );

  const stripeConnectStatus = _.get(detailItem, 'connectStatus', '');

  const emailOptions = detailItem?.emails?.map((email) => {
    return {
      value: email,
      label: email,
    };
  });

  return (
    <>
      {companyCommission && (
        <>
          <Form.Label>
            <h3 style={{ fontSize: '20px' }} className="mb-sm">
              <Translate value="driver.stripeConnect" />
            </h3>
            <div className={styles['description']}>
              <Translate value="driver.stripeConnectDes" />{' '}
            </div>
          </Form.Label>
          <FormGroup className={`mt-md`}>
            <Form.Label>
              <Translate value="companySetting.stripeConnectId" />{' '}
            </Form.Label>
            <FormControl
              type="text"
              className={`${styles['input-custom-2']} form-custom`}
              onChange={handleConnectTokenChange}
              value={detailItem.connectToken}
              disabled={!state?.editable}
              placeholder={I18n.t('companySetting.stripeConnectId')}
            />
            <Form.Label>
              <p
                className="mt-sm"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0',
                }}
              >
                <span
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor:
                      stripeConnectStatus === 'activated'
                        ? 'green'
                        : stripeConnectStatus === 'pending'
                        ? 'orange'
                        : 'red',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '8px',
                  }}
                ></span>
                {stripeConnectStatus === 'activated'
                  ? 'Active'
                  : stripeConnectStatus === 'pending'
                  ? 'In Review'
                  : 'Inactive'}
              </p>
            </Form.Label>
          </FormGroup>
        </>
      )}

      <FormGroup controlId="formControlsSelectMultipleEmail">
        <FormGroup controlId="emails">
          <Form.Label>
            <h3 style={{ fontSize: '20px' }} className="mb-sm">
              <Translate value="supplier.emailsApply" />
            </h3>
            <div className={styles['description']}>
              <Translate value="supplier.emailsApplyDes" />{' '}
            </div>
          </Form.Label>
          <Select.Async
            multi={true}
            valueKey="id"
            labelKey="value"
            cache={false}
            placeholder="email01@example.com;email02@example.com"
            searchPromptText={I18n.t('invoice.addRecipients')}
            loadingPlaceholder={I18n.t('message.Searching')}
            noResultsText={I18n.t('message.No_results')}
            className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest email-notification supplier-select"
            value={emailList || []}
            loadOptions={handleLoadOptionDebounce}
            onChange={(emails) => {
              handleEmailsApplyChange(emails || []);
            }}
            disabled={!state?.editable}
          />
        </FormGroup>
      </FormGroup>

      <Row>
        <Col xs={12}>
          <Button
            style={{ padding: '6px 15px' }}
            className="btn-cancel mt-md"
            onClick={closeDialogForm}
          >
            <Translate value="carSetting.discardChange" />
          </Button>
          {!props.permissions || props.permissions.actions ? (
            state.editable ? (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={saveDialogForm}
              >
                <Translate
                  value={
                    action === 'add' ? 'carSetting.Save' : 'carSetting.Update'
                  }
                />
              </Button>
            ) : (
              <Button
                style={{ padding: '6px 15px', minWidth: 'unset' }}
                className="btn-save ml-md mt-md"
                onClick={onEditable}
              >
                <Translate value="carSetting.Edit" />
              </Button>
            )
          ) : null}

          {/* {(!props.permissions || props.permissions.actions) &&
          action !== 'add' &&
          props.params.driverId &&
          auth.selectedFleet.password.driver ? (
            <Button
              style={{ padding: '6px 15px', minWidth: 'unset' }}
              className="btn-reset ml-md mt-md"
              onClick={handleResetPasswordClick}
            >
              <Translate value="user.Reset_password" />
            </Button>
          ) : null} */}
        </Col>
      </Row>
    </>
  );
};

export default FormSetting;
