import { I18n } from 'react-redux-i18n';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.incidentCancellation.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.incidentCancellation.bookingId',
    summary: 'bookingId',
  },
  {
    key: 'customerName',
    label: 'report.result.incidentCancellation.customerName',
    summary: 'customerName',
  },
  {
    key: 'customerPhone',
    label: 'report.result.incidentCancellation.customerPhone',
    summary: 'customerPhone',
  },
  {
    key: 'supportId',
    label: 'customer.Support_ID',
  },
  {
    key: 'operatorName',
    label: 'report.result.cashWallet.operatorName',
  },
  {
    key: 'bookFrom',
    label: 'report.result.incidentCancellation.bookFrom',
    summary: 'bookFrom',
    mutate: (value) => {
      if (value) return value.replace('mDispatcher', 'Partner');
      return value;
    },
    width: 180,
  },
  {
    key: 'canceledBy',
    label: 'report.result.incidentCancellation.canceledBy',
    summary: 'canceledBy',
    mutate: (value, doc) => value && value.replace('mDispatcher', 'Partner'),
  },
  {
    key: 'type',
    label: 'report.result.incidentCancellation.type',
    summary: 'type',
  },
  {
    key: 'reason',
    label: 'report.result.incidentCancellation.reason',
    summary: 'reason',
    mutate: (value, doc) => {
      let reason = value;
      if (doc.reasonCode != 5 && doc.canceledBy === 'Passenger') {
        if (doc.reasonCode == -1) return '';
        const pathTransle = `report.result.incidentCancellation.reasonCode.${doc.reasonCode}`;
        reason = I18n.t(pathTransle);
      }
      if (doc.reasonCode != 5 && doc.canceledBy === 'Merchant') {
        if (doc.reasonCode == -1) return '';
        const pathTransle = `report.result.incidentCancellation.reasonCode.${doc.reasonCode}`;
        reason = I18n.t(pathTransle);
      }
      return reason;
    },
  },
  {
    key: 'canceller',
    label: 'report.result.incidentCancellation.canceller',
    summary: 'canceller',
  },
];

export const fareFields = [];
