import _ from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BaseReport from '../base';
import * as fieldMappings from './fields';
import {
  reportBookingLogsApi,
  reportDispatchHistorysApi,
} from '../../../constants/ApiConfigs';
import * as reportActions from '../../../actions/reportActions';
import * as mapActions from '../../../actions/mapActions';
import * as loadingActions from '../../../actions/loadingBarActions';
import * as commonActions from '../../../actions/commonDataAction';
import FormModalLogGps from '../../../components/DispatchLogs/formModalLogGps';
import PaymentLogs from '../../../components/bookingDetail/bookComponent/PaymentLogs';

const bookingService = [
  {
    value: 'localBooking',
    label: 'report.query.bookingServiceItem.localBooking',
  },
  {
    value: 'homeFleet',
    label: 'report.query.farmTypeItem.farmOut',
  },
  {
    value: 'provideService',
    label: 'report.query.bookingServiceItem.provideService',
  },
];

class ReportBookingLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      bookId: '',
      currentBook: {},
      showPaymentLogsModal: false,
      bookIdShowPaymentLog: '',
      list: [],
      total: 0,
    };
  }

  showDispatchHistory = (link, data, e) => {
    this.setState({
      showModal: true,
      currentBook: { ...data.row, bookId: data.row.bookingId },
    });
  };

  showPaymentLogs = (link, data, e) => {
    this.setState({ bookIdShowPaymentLog: data.row.bookingId, showPaymentLogsModal: true })
  }

  closePaymentLogsModal = () => { this.setState({ bookIdShowPaymentLog: '', showPaymentLogsModal: false })}

  fetchLogsData(params) {
    this.props.reportActions
      .fetchReportDataFromApi(
        reportDispatchHistorysApi,
        Object.assign(params, {
          fleetId: _.get(this.props, 'selectedFleet.fleetId', null),
        })
      )
      .then((resp) => {
        this.setState({
          list: _.get(resp, 'res.list', []),
          total: _.get(resp, 'res.total', 0),
          showModal: true,
        });
      })
      .catch((error) => {});
  }

  renderTableContent() {
    if (this.state.total == 0) {
      return (
        <div className="no-item-was-found">
          <Translate value="report.result.noResultFound" />
        </div>
      );
    }
    return (
      <div className="modal-report-table-container">
        <table className="cc-table cc-table-striped">
          <thead>
            <tr>
              {fieldMappings.dispatchHistoryFields.map((item) => (
                <th
                  className="modal-report-table-header"
                  style={{ padding: 20 }}
                >
                  <Translate value={item.label} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((doc, index) => (
              <tr>
                {fieldMappings.dispatchHistoryFields.map((item) => {
                  let nameItem = doc[item.key];
                  if (item.key === 'location' && doc[item.key] !== 'N/A') {
                    let arr = nameItem.split(', ');
                    [arr[0], arr[1]] = [arr[1], arr[0]];
                    nameItem = arr.toString().replace(',', ', ');
                  }
                  return (
                    <td
                      className="modal-report-table-row-content"
                      style={{ padding: 20 }}
                    >
                      {(item.mutate && item.mutate(doc[item.key])) || nameItem}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const tittle = `BOOKING #${this.state.bookId}`;
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'Booking logs',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }

    return (
      <div className="content">
        {Object.keys(this.state.currentBook)?.length > 0 && (
          <FormModalLogGps
            commonData={this.props.commonData}
            map={this.props.map}
            auth={this.props.auth}
            data={this.state.currentBook}
            selectedFleet={this.props.selectedFleet}
            reportActions={this.props.reportActions}
            loadingActions={this.props.loadingActions}
            showModal={this.state.showModal}
            setShowModal={(val) => this.setState({ showModal: val })}
            type={"report"}
          />
        )}
        {this.state.showPaymentLogsModal && (
          <PaymentLogs
            bookInfo={{ bookId: this.state.bookIdShowPaymentLog }}
            hasPermissionAction={false}
            handleClose={this.closePaymentLogsModal}
          />
        )}
        <BaseReport
          tittle="Booking logs"
          fieldMappings={fieldMappings}
          apiurl={reportBookingLogsApi}
          dateRange
          finishedStatus
          company
          driver
          bookingService={bookingService}
          bookFrom
          paymentMethod
          search="report.query.search.bookingLogs"
          actions={{
            showPaymentLogs: this.showPaymentLogs,
            showDispatchHistory: this.showDispatchHistory,
          }}
          noexport={!checkExport}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
    auth: state.auth,
    map: state.map,
    commonData: state.commonData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reportActions: bindActionCreators(reportActions, dispatch),
    loadingActions: bindActionCreators(loadingActions, dispatch),
    mapActions: bindActionCreators(mapActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportBookingLogs);
