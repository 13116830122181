import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import '../../settings.scss';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Tab,
  Tabs
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase, WarningCase, WarningFeeInput } from '../../../../components/validator';
import { DefaultCurenciesNumberInputField, repeatType } from '../../../../constants/commondata';
import {
  ModalConfirmDeleteSurcharge,
  ModalAddSurcharge,
  ModalConfirmChangeApplyType
} from '../modals';
import CustomZoneList from './CustomZoneList';
// import ServiceFeesForm from './ServiceFeesForm';
import ServiceCommissionForm from './FormItem/ServiceCommissionForm';

const surchargeType = {
  AMOUNT: 'amount',
  PERCENT: 'percent'
};
const applyType = {
  ALL: 'all',
  CUSTOM_ZONE: 'custom_zone'
};

class FleetCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData,
      dataCommissionZoneEditing: [],
      fleet: props.fleet,
      showDialog: false,
      dialogData: {},
      showConfirmDelete: false,
      valid: {},
      isSubmited: false,
      rushHourIndex: -1,
      surchargeByCurrenciesInitial: []
    };
  }

  handleUpdateFormData = (key, value) => {
    const { formData } = this.state;
    if(key == 'sameZones') {
      formData.defaultFleetCommissionValue.sameZones = value
    } else if(key == 'differentZones') { 
      this.setState({
        dataCommissionZoneEditing: value
      })
      return
    } else {
      _.set(formData, key, value);
    }
    this.setState({ formData });
  };

  handleApplyTypeChange = e => {
    this.setState({
      showConfirmChangeApplyType: true,
      handleApplyTypeChange: e.target.value
    });
  };

  closeDeleteServiceDialog = () => {
    this.setState({ showConfirmDelete: false });
  };

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  };

  handleSaveClick = (isDifferentZones) => {
    let { formData = {}, zoneEditing, dataCommissionZoneEditing} = this.state
    const data = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      defaultFleetCommissionType: formData.defaultFleetCommissionType
    }
    if(isDifferentZones) {
      let { defaultFleetCommissionValue } = formData 
      let commissionZones = defaultFleetCommissionValue.differentZones || []
      let newCommissionZones = commissionZones.map(commission => {
        if(commission.zoneId == zoneEditing._id) {
          return {
            zoneId: commission.zoneId,
            value: dataCommissionZoneEditing
          }
        }
        return commission
      })
      defaultFleetCommissionValue.differentZones = newCommissionZones
      data.defaultFleetCommissionValue = defaultFleetCommissionValue
    } else {
      data.defaultFleetCommissionValue = formData.defaultFleetCommissionValue
    }
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions.saveSettingsCommission(data).then(res => {
      this.props.loadingBarActions.hideLoadingSpiner();
      this.setState({ isSubmited: false });

      if (res.ok) {
        this.context.notification('success', I18n.t('generalSetting.Update_fleet_commission_success'));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1 * 1000);
      } else if (res.error) {
        this.context.notification('error', I18n.t(`errors.${data.error.errorCode}`));
      } else {
        this.context.notification('error', I18n.t('generalSetting.Update_fleet_commission_fail'));
      }
    });
  };


  handleEditServiceFees = (serviceFees, zoneEditing) => {
    let differentZone =  _.get(this.state.formData, 'defaultFleetCommissionValue.differentZones', [])
    let commissionEditing = differentZone.find(zone => zone.zoneId == zoneEditing._id)
    this.setState({
      serviceFees,
      zoneEditing,
      isEditServiceFees: true,
      isChangeFormServiceFees: false,
      dataCommissionZoneEditing: commissionEditing && commissionEditing.value || []
    });
  };

  onChangeFormServiceFees = submited => {
    this.setState({ isChangeFormServiceFees: !submited });
  };

  confirmChangeApplyType = () => {
    const { fleetId } = this.props.auth.selectedFleet;
    const { formData, handleApplyTypeChange } = this.state;
    this.props.loadingBarActions.showLoadingSpiner();
    this.props.settingActions.updateFleetCommissionApplyType({
      fleetId, 
      applyType: handleApplyTypeChange,
      defaultFleetCommissionValue: this.props.formData.defaultFleetCommissionValue
    })
    .then(data => {
      this.props.loadingBarActions.hideLoadingSpiner();
      if (data.ok && data.res) {
        formData.defaultFleetCommissionType = handleApplyTypeChange;
        formData.defaultFleetCommissionValue = data.res.defaultFleetCommissionValue || []
        this.setState({
          formData,
          showConfirmChangeApplyType: false,
          isEditServiceFees: false,
          serviceFees: null,
          isChangeFormServiceFees: false,
          zoneEditing: null
        });
        this.context.notification('success', I18n.t('generalSetting.Update_fleet_commission_success'));
      } else if (data.error) {
        this.context.notification('error', I18n.t('generalSetting.Update_fleet_commission_fail'));
      }
    });
  };

  closeConfirmDialog = () => {
    this.setState({ showConfirmChangeApplyType: false });
  };

  render() {
    const editable = this.props.permissions && !this.props.permissions.actions;
    const { allowCongfigSettingForEachZone } = _.get(
      this.props.auth,
      'selectedFleet.generalSetting',
      {}
    );
    const fleetType = _.get(this.props.auth, 'selectedFleet.fleetType', '')
    const { defaultFleetCommissionType } = this.state.formData;
    const defaultFleetCommissionValue = this.state.formData.defaultFleetCommissionValue || []

    return this.state.formData ? (
      <div className="content custom-form-commission overflow-auto general-setting pl0">
        {this.state.showConfirmChangeApplyType && !editable ? (
          <ModalConfirmChangeApplyType
            closeConfirmDialog={this.closeConfirmDialog}
            confirmChangeType={this.confirmChangeApplyType}
            applyType={defaultFleetCommissionType}
            title={'Change apply default fleet commission'}
          />
        ) : null}
        {this.state.showDialog && !editable ? (
          <ModalAddSurcharge
            auth={this.props.auth}
            rushHourAddItemSaveClick={this.rushHourAddItemSaveClick}
            rushHourAddItemCloseClick={this.rushHourAddItemCloseClick}
            dialogData={this.state.dialogData}
            surchargeByCurrenciesInitial={this.state.surchargeByCurrenciesInitial}
            handleAlertNotification={this.handleAlertNotification}
          />
        ) : (
          ''
        )}
        {allowCongfigSettingForEachZone && (
          <Row>
            <Col md={6} xs={12}>
              <FormGroup className="mt-md">
                <Form.Label>
                  <Translate value="generalSetting.apply" />
                </Form.Label>
                <FormControl
                  as="select"
                  className="form-control form-custom"
                  value={defaultFleetCommissionType}
                  onChange={this.handleApplyTypeChange}
                  disabled={editable}
                >
                  <option value={'sameZones'}>{I18n.t('generalSetting.apply_all')}</option>
                  <option value={'differentZones'}>
                    {I18n.t('generalSetting.apply_custom_zone')}
                  </option>
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6} xs={12}>
            {this.state.formData.defaultFleetCommissionType === 'differentZones'
              && allowCongfigSettingForEachZone ? (
              <CustomZoneList
                onEditServiceFees={this.handleEditServiceFees}
                serviceFees={this.state.serviceFees}
                isChangeFormServiceFees={this.state.isChangeFormServiceFees}
              />
            ) : null}
            {defaultFleetCommissionType === 'sameZones' || !allowCongfigSettingForEachZone ? (
              <>
                <ServiceCommissionForm 
                  valueCommissions={defaultFleetCommissionValue.sameZones}
                  auth={this.props.auth}
                  handleUpdateFormData={this.handleUpdateFormData}
                  defaultFleetCommissionType={defaultFleetCommissionType}
                  editable={editable}
                />
                <ButtonToolbar className="text-center mv-md">
                {!this.props.permissions || this.props.permissions.actions ? (
                  <Button className="btn-save" onClick={this.handleSaveClick}>
                    <Translate value="generalSetting.Save" />
                  </Button>
                ) : (
                  ''
                )}
                </ButtonToolbar>
              </>
            ) : null}
          </Col>
          <Col md={6} xs={12}>
            {this.state.isEditServiceFees && (
              <ServiceCommissionForm
                zone={this.state.zoneEditing}
                valueCommissions={this.state.dataCommissionZoneEditing}
                handleUpdateFormData={this.handleUpdateFormData}
                defaultFleetCommissionType={defaultFleetCommissionType}
                isCommissionZone
                disabled={editable}
                auth={this.props.auth}
                handleSaveClick={() => this.handleSaveClick(true)}
              />
            )}
          </Col>
        </Row>
      </div>
    ) : null;
  }
}

FleetCommission.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

FleetCommission.defaultProps = {
  
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FleetCommission);
