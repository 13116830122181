import React, { PureComponent } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
} from 'react-bootstrap';
import _ from 'lodash';
import { ValidCase, Validator } from '../../../../../components/validator';
import InputMultiCurrencies from './InputMultiCurrencies';
import { Validation, parStart } from '../../../../../utils/commonFunctions';
import FormGroupTitle from '../../../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../../../components/ccCheckbox/CcCheckbox';
import RouteSchedules from './RouteSchedules';
import QuestionCircleTooltip from '../../../../../components/questionCircleTooltip/QuestionCircleTooltip';
const hoursOfDay = _.range(0, 24).map((s) => _.padStart(s, 2, '00'));
const minutesOfHour = _.range(0, 60).map((s) => _.padStart(s, 2, '00'));

export default class RouteSettings extends PureComponent {
  handleChangeDistance = (e) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('distance', e.target.value);
  };

  handleChangeDuration = (e) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('duration', e.target.value);
  };

  handleChangeDurationUnit = (e) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('durationUnit', e.target.value);
  };

  handleChangePricePerSeat = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('pricePerSeat', value);
  };

  handleChangeCancellationPenalty = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('cancellationPenalty', value);
  };

  handleChangeNoShowPenalty = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('noShowPenalty', value);
  };

  handleChangeSchedules = (schedules) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('schedules', schedules);
  };

  handleChangeExtraFee = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('extraFee', value);
  };

  handleInputChange = (key, e) => {
    const { onChangeRouteSetting } = this.props;
    // if(key === 'canclePayToDriverValue') {
    //   onChangeRouteSetting(e)
    //   return
    // }
    const value = e.target.value || e.target.checked;
    onChangeRouteSetting(key, value);
  };

  handleChangeCanclePayToDriverValue = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('canclePayToDriverValue', value);
  };

  handleChangeNoShowPayToDriverValue = (value) => {
    const { onChangeRouteSetting } = this.props;
    onChangeRouteSetting('noShowPayToDriverValue', value);
  };

  formatCurrency = (amount) => {
    const VNDFormatter = Intl.NumberFormat('us-US');
    return VNDFormatter.format(amount);
  };

  isValidCancellationPenalty = () => {
    const {
      routeSetting: { cancellationPenalty, pricePerSeat },
    } = this.props;
    let isVaid = true;
    const priceValue = _.get(pricePerSeat, [0, 'value'], 0);
    const penaltyValue = _.get(cancellationPenalty, [0, 'value'], 0);
    return parseFloat(priceValue) >= parseFloat(penaltyValue);
  };

  renderRequestTime = () => {
    const {
      routekey,
      valid,
      validatorCallback,
      routeSetting,
      isSubmmitted,
      editable,
      currentCurrency,
    } = this.props;
    console.log('routeSetting ', routeSetting);

    return (
      <div>
        <FormGroup
          className={
            isSubmmitted
              ? valid[`${routekey}extraFee`] === false
                ? 'error'
                : null
              : null
          }
        >
          <Form.Label>
            <Translate value="intercityRoute.extraFee" />{' '}
            <span className="require">*</span>
            <QuestionCircleTooltip
              text={<Translate value="intercityRoute.allows_adding_extra" />}
            />
          </Form.Label>
          <InputMultiCurrencies
            currentCurrency={currentCurrency}
            value={routeSetting.extraFee}
            onChange={this.handleChangeExtraFee}
            editable={editable}
          />

          <Validator id={`${routekey}extraFee`} callback={validatorCallback}>
            <ValidCase
              valid={Validation.isNumber(
                _.get(routeSetting, 'extraFee[0].value')
              )}
              message={I18n.t('messages.commonMessages.must_be_number')}
              hide={!isSubmmitted}
            />
            <ValidCase
              valid={Validation.isGreaterOrEqual(
                _.get(routeSetting, 'extraFee[0].value', 0),
                0
              )}
              hide={!isSubmmitted}
              message={I18n.t('messages.commonMessages.greater_or_equa').format(
                0
              )}
            />
            <ValidCase
              valid={Validation.isLessOrEqual(
                _.get(routeSetting, 'extraFee[0].value'),
                999999999999
              )}
              message={I18n.t('messages.commonMessages.less_than').format(
                this.formatCurrency(999999999999)
              )}
              hide={!isSubmmitted}
            />
          </Validator>
        </FormGroup>
      </div>
    );
  };

  render() {
    const {
      routekey,
      valid,
      validatorCallback,
      routeSetting,
      isSubmmitted,
      editable,
      selectedFleet,
      currentCurrency,
      handleChangeScheduleEnable,
      handleChangeRequestTimeEnable,
      handleChangeHideRouteEnable,
    } = this.props;

    return (
      <Row>
        <Col xs={12} md={12}>
          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}pricePerSeat`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.pricePerSeat" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={routeSetting.pricePerSeat}
              onChange={this.handleChangePricePerSeat}
              editable={editable}
            />

            <Validator
              id={`${routekey}pricePerSeat`}
              callback={validatorCallback}
            >
              <ValidCase
                valid={Validation.isGreaterOrEqual(
                  _.get(routeSetting, 'pricePerSeat[0].value', 0),
                  0
                )}
                hide={!isSubmmitted}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
              />
              <ValidCase
                valid={Validation.isLessOrEqual(
                  _.get(routeSetting, 'pricePerSeat[0].value'),
                  999999999999
                )}
                message={I18n.t('messages.commonMessages.less_than').format(
                  this.formatCurrency(999999999999)
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>

          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}distance`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.Distance" />{' '}
              <span className="require">*</span>
            </Form.Label>

            <FormGroup className="qup-input-group">
              <InputGroup
                className={
                  'single-addon-right' + (!editable ? ' disabled' : '')
                }
              >
                <FormControl
                  type="text"
                  className={'form-custom'}
                  onChange={this.handleChangeDistance}
                  value={routeSetting.distance}
                  disabled={!editable}
                />
                <InputGroup.Append>
                  <InputGroup.Text>
                    {selectedFleet.unitDistance}
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </FormGroup>
            <Validator id={`${routekey}distance`} callback={validatorCallback}>
              <ValidCase
                valid={
                  routeSetting.distance &&
                  Validation.isNumber(routeSetting.distance)
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={Validation.isGreaterOrEqual(routeSetting.distance, 0)}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={Validation.isLessOrEqual(routeSetting.distance, 9999)}
                message={I18n.t('messages.commonMessages.less_than').format(
                  9999
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>

          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}duration`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.duration" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormGroup className="qup-input-group">
              <InputGroup
                className={
                  'single-addon-right' + (!editable ? ' disabled' : '')
                }
              >
                <FormControl
                  type="text"
                  className={'form-custom'}
                  onChange={this.handleChangeDuration}
                  value={routeSetting.duration}
                  disabled={!editable}
                />
                <FormControl
                  as="select"
                  className="input-group-addon-custom form-custom"
                  value={routeSetting.durationUnit || 'hour'}
                  onChange={this.handleChangeDurationUnit}
                  disabled={!editable}
                >
                  <option value={'hour'}>{I18n.t('General.hours')}</option>
                  <option value={'minute'}>{I18n.t('General.mins')}</option>
                </FormControl>
              </InputGroup>
            </FormGroup>
            <Validator id={`${routekey}duration`} callback={validatorCallback}>
              <ValidCase
                valid={
                  routeSetting.duration &&
                  Validation.isNumber(routeSetting.duration)
                }
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={Validation.isGreaterOrEqual(routeSetting.duration, 0)}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
                hide={!isSubmmitted}
              />
              <ValidCase
                valid={Validation.isLessOrEqual(routeSetting.duration, 9999)}
                message={I18n.t('messages.commonMessages.less_than').format(
                  9999
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>
        </Col>
        <Col xs={12} md={12}>
          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}schedules`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <div className="scheduleEnable mb">
                <Translate value="intercityRoute.schedules" />{' '}
                {/* <span className="require">*</span> */}
                <CcCheckbox
                  checked={routeSetting.scheduleEnable}
                  onChange={handleChangeScheduleEnable}
                  className="ml mb0"
                />
              </div>
            </Form.Label>
            {routeSetting.scheduleEnable ? (
              <div>
                <RouteSchedules
                  schedules={routeSetting.schedules}
                  onChange={this.handleChangeSchedules}
                />
                <Validator
                  id={`${routekey}schedules`}
                  callback={validatorCallback}
                >
                  <ValidCase
                    valid={
                      routeSetting.schedules &&
                      routeSetting.schedules.length > 0
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                    hide={!isSubmmitted}
                  />
                </Validator>
              </div>
            ) : (
              <div>
                <Validator
                  id={`${routekey}schedules`}
                  callback={validatorCallback}
                >
                  <ValidCase
                    valid={
                      routeSetting.requestTimeEnable === true ||
                      routeSetting.scheduleEnable === true
                    }
                    // message={I18n.t('messages.commonMessages.choose_at_least_one_intercity_schedule')}
                    hide={!isSubmmitted}
                  />
                </Validator>
              </div>
            )}
          </FormGroup>
        </Col>

        <Col xs={12} md={12}>
          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}requestTime`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <div className="requestTimeEnable mb">
                <Translate value="intercityRoute.requestTimeEnable" />
                <CcCheckbox
                  checked={routeSetting.requestTimeEnable}
                  onChange={handleChangeRequestTimeEnable}
                  className="ml mb0"
                />
              </div>
            </Form.Label>
            {routeSetting.requestTimeEnable ? (
              <FormGroup className="ml">{this.renderRequestTime()}</FormGroup>
            ) : (
              <div>
                <Validator
                  id={`${routekey}requestTime`}
                  callback={validatorCallback}
                >
                  <ValidCase
                    valid={
                      routeSetting.requestTimeEnable === true ||
                      routeSetting.scheduleEnable === true
                    }
                    message={I18n.t(
                      'messages.commonMessages.choose_at_least_one_intercity_schedule'
                    )}
                    hide={!isSubmmitted}
                  />
                </Validator>
              </div>
            )}
          </FormGroup>
        </Col>

        <Col xs={12} md={12}>
          <FormGroup>
            <Form.Label>
              <div className="hideRouteEnable mb">
                <Translate value="intercityRoute.hideRouteEnable" />
                <QuestionCircleTooltip
                  text={
                    <Translate value="intercityRoute.this_is_to_limit_users" />
                  }
                />
                <CcCheckbox
                  checked={routeSetting.hideRouteEnable}
                  onChange={handleChangeHideRouteEnable}
                  className="ml mb0"
                />
              </div>
            </Form.Label>
          </FormGroup>

          <FormGroupTitle className="mt-xl">
            <div className="mb">
              {/* <Translate value="intercityRoute.routeOneSetup" /> */}
              Pax/partner cancellation
            </div>
          </FormGroupTitle>

          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}cancellationPenalty`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.cancellationPenalty" />{' '}
              <span className="require">*</span>
              <QuestionCircleTooltip
                text={
                  'The amount customer will be charged per seat. In addition to the penalty customer will pay tax fee if applied.'
                }
              />
            </Form.Label>
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={routeSetting.cancellationPenalty}
              onChange={this.handleChangeCancellationPenalty}
              editable={true}
              isValidate
            />

            <Validator
              id={`${routekey}cancellationPenalty`}
              callback={validatorCallback}
            >
              <ValidCase
                valid={Validation.isGreaterOrEqual(
                  _.get(routeSetting, 'cancellationPenalty[0].value', 0),
                  0
                )}
                hide={!isSubmmitted}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
              />
              <ValidCase
                valid={this.isValidCancellationPenalty()}
                message={I18n.t(
                  'intercityRoute.penalty_less_than_or_equal_to_price'
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>

          <CcCheckbox
            checked={routeSetting.cancelTax}
            onChange={(e) => {
              this.handleInputChange('cancelTax', e);
            }}
            text={I18n.t('regularSetting.Tax')}
          />
          <FormGroup>
            <Form.Label>
              <Translate value="regularSetting.Pay_to_driver" />
              <QuestionCircleTooltip
                text={
                  <Translate value="regularSetting.Pay_to_driver_tooltip" />
                }
              />
            </Form.Label>
            <FormControl
              as="select"
              className={'form-custom'}
              value={routeSetting.canclePayToDriver}
              onChange={(e) => {
                this.handleInputChange('canclePayToDriverType', e);
              }}
            >
              <option value="commission" key="commission">
                {I18n.t('regularSetting.Commission')}
              </option>
              <option value="fixAmount" key="fixAmount">
                {I18n.t('regularSetting.Fix_amount')}
              </option>
            </FormControl>
          </FormGroup>
          {routeSetting.canclePayToDriverType == 'fixAmount' ? (
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={routeSetting.canclePayToDriverValue}
              onChange={this.handleChangeCanclePayToDriverValue}
              editable={true}
              isValidate
            />
          ) : null}

          {/****************  No Show ***************/}
          <FormGroupTitle className="mt-xl">
            <div className="mb">Pax/partner no show</div>
          </FormGroupTitle>

          <FormGroup
            className={
              isSubmmitted
                ? valid[`${routekey}noShowPenalty`] === false
                  ? 'error'
                  : null
                : null
            }
          >
            <Form.Label>
              <Translate value="intercityRoute.cancellationPenalty" />{' '}
              <span className="require">*</span>
              <QuestionCircleTooltip
                text={'Customer will be charged 100% of estimated fare.'}
              />
            </Form.Label>
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={routeSetting.noShowPenalty}
              onChange={this.handleChangeNoShowPenalty}
              editable={true}
              isValidate
            />

            <Validator
              id={`${routekey}noShowPenalty`}
              callback={validatorCallback}
            >
              <ValidCase
                valid={Validation.isGreaterOrEqual(
                  _.get(routeSetting, 'noShowPenalty[0].value', 0),
                  0
                )}
                hide={!isSubmmitted}
                message={I18n.t(
                  'messages.commonMessages.greater_or_equa'
                ).format(0)}
              />
              <ValidCase
                valid={this.isValidCancellationPenalty()}
                message={I18n.t(
                  'intercityRoute.penalty_less_than_or_equal_to_price'
                )}
                hide={!isSubmmitted}
              />
            </Validator>
          </FormGroup>

          <CcCheckbox
            checked={routeSetting.noShowTax}
            onChange={(e) => {
              this.handleInputChange('noShowTax', e);
            }}
            text={I18n.t('regularSetting.Tax')}
          />
          <FormGroup>
            <Form.Label>
              <Translate value="regularSetting.Pay_to_driver" />
              <QuestionCircleTooltip
                text={
                  'Driver’s compensate based on set commission.'
                  // <Translate value="regularSetting.Pay_to_driver_tooltip" />
                }
              />
            </Form.Label>
            <FormControl
              as="select"
              className={'form-custom'}
              value={routeSetting.noShowPayToDriver}
              onChange={(e) => {
                this.handleInputChange('noShowPayToDriverType', e);
              }}
            >
              <option value="commission" key="commission">
                {I18n.t('regularSetting.Commission')}
              </option>
              <option value="fixAmount" key="fixAmount">
                {I18n.t('regularSetting.Fix_amount')}
              </option>
            </FormControl>
          </FormGroup>
          {routeSetting.noShowPayToDriverType == 'fixAmount' ? (
            <InputMultiCurrencies
              currentCurrency={currentCurrency}
              value={routeSetting.noShowPayToDriverValue}
              onChange={this.handleChangeNoShowPayToDriverValue}
              editable={true}
              isValidate
            />
          ) : null}
        </Col>
      </Row>
    );
  }
}
