import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Translate, I18n } from 'react-redux-i18n'
import _, {
  forEach,
  find,
  map,
  debounce,
  remove,
  unionBy,
  orderBy,
  filter,
} from 'lodash'

import '../settings.scss'
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  Image,
} from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import {
  getDefaultRolesPermission,
  findPermission,
  updatePermission,
  createPermission,
  detailsPermission,
  changeStatusPermission,
  deletePermission,
  exportPermissionsToExcel,
} from '../../../actions/settingActions'
import {
  loadMoreDrivers,
  loadMoreVehicles,
} from '../../../actions/commonDataAction'
import {
  showLoadingSpiner,
  hideLoadingSpiner,
} from '.././../../actions/loadingBarActions'
import { Validator, ValidCase } from '../../../components/validator'
import { CCLiteCommonFunc, Validation, handleResultExport } from '../../../utils/commonFunctions'
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle'
import ExportComponent from '../../../components/ExportComponent'
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox'
import QuestionCircleTooltip from '../../../components/questionCircleTooltip/QuestionCircleTooltip'
import RadioButton from '../../../components/radioButton/radio'
import StickyTable from '../../../components/table/stickyTable/StickyTable'
import { Columns } from './tableHeaderData'
import TableActions from '../../../components/table/tableAction/TableActions'
import SpecificDriver from './components/SpecificDriver'
import SpecificCar from './components/SpecificCar'
import { BeatLoader } from 'react-spinners'
import excelIcon from '../../../assets/images/excel.svg'
import Confirm from '../../../components/confirm/Confirm'
import { th } from 'react-dom-factories'
import { BsSearch } from 'react-icons/bs'

const specificType = {
  driver: 1,
  car: 2,
}
const CONFIRM_EXPORT_ID = 1
class Permission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editable: false,
      permissions: null,
      numItemsPerPage: 20,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      valid: {},
      isSubmitted: false,
      isShowDriverbox: false,
      isShowCarsbox: false,
      carList: [],
      selectedCars: [],
      selectedDrivers: [],
      selectedCompany: '',
      companyOperator: false,
      supplierPermission: false,
      selectedCarType: [],
      defaultRoles: [],
      tableHeight: 500,
      rowHeight: 50,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      driverQuery: {
        total: 0,
        limit: 50,
        page: 0,
        list: [],
        search: '',
        hasMore: true,
      },
      carQuery: {
        total: 0,
        limit: 50,
        page: 0,
        list: [],
        search: '',
      },
      str: '',
      showPercent: false,
      exportPercent: 0,
      confirm: false,
    }

    this.handleDriverListScrollDebounce = debounce(
      this.handleDriverListScroll,
      300
    )
    this.handleResultExport = handleResultExport.bind(this);
    this.handleCarListScrollDebounce = debounce(this.handleCarListScroll, 300)
  }

  componentDidMount() {
    const { commonData, auth, getDefaultRolesPermission } = this.props
    this.updatePermissionsList()
    getDefaultRolesPermission({
      fleetId: auth.selectedFleet.fleetId,
    }).then((data) => {
      if (data.ok && data.res) {
        const defaultRoles = data.res
        /* Find and remove "Actions" action of Fleet Info settings */
        const fleetInfoRoles = _.find(
          defaultRoles,
          (role) => role.name.toLowerCase() === 'fleet info' && role.actions
        )
        if (fleetInfoRoles)
          remove(fleetInfoRoles.actions, (item) => item.name === 'Actions')
        /* ------- */
        if (
          !auth.selectedFleet.generalSetting ||
          (!auth.selectedFleet.generalSetting.editBookingFare &&
            !auth.selectedFleet.generalSetting.markupPrice)
        ) {
          forEach(defaultRoles, (role) => {
            if (role.key === 'Cue' && role.actions) {
              remove(role.actions, (item) => item.name === 'EditTotal')
            } else if (role.key === 'Report' && role.actions) {
              remove(role.actions, (item) => item.name === 'Total adjustment')
            }
          })
        }
        if (
          !auth.selectedFleet.generalSetting ||
          !auth.selectedFleet.generalSetting.importChauffeurBooking
        ) {
          forEach(defaultRoles, (role) => {
            if (role.key === 'Cue' && role.actions) {
              remove(role.actions, (item) => item.name === 'ImportBookings')
            }
          })
        }
        if (
          !auth.selectedFleet.generalSetting ||
          !auth.selectedFleet.generalSetting.importDeliveryBooking ||
          !auth.selectedFleet.delivery ||
          !auth.selectedFleet.delivery.enable
        ) {
          forEach(defaultRoles, (role) => {
            if (role.key === 'Cue' && role.actions) {
              remove(
                role.actions,
                (item) => item.name === 'ImportDeliveryBookings'
              )
            }
          })
        }
        if (
          !auth.selectedFleet.generalSetting ||
          !auth.selectedFleet.generalSetting.ableToDownloadAccount
        ) {
          forEach(defaultRoles, (role) => {
            if (role.key === 'Driver' && role.actions) {
              remove(role.actions, (item) => item.name === 'Export')
            } else if (role.key === 'Customer' && role.actions) {
              remove(role.actions, (item) => item.name === 'Export')
            } else if (role.key === 'Corporate' && role.actions) {
              remove(role.actions, (item) => item.name === 'Export')
            } else if (role.key === 'Vehicle' && role.actions) {
              remove(role.actions, (item) => item.name === 'Export')
            }
          })
        }
        this.setState({ defaultRoles })
      }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedCompany, selectedCarType } = this.state
    const { commonData } = nextProps
    const newStates = {}

    if (commonData) {
      if (
        !selectedCompany &&
        commonData.companies &&
        [...commonData.companies].length
      ) {
        newStates.selectedCompany = commonData.companies[0]._id
      }

      if (
        !selectedCarType.length &&
        commonData.carType &&
        commonData.carType.length
      ) {
        newStates.selectedCarType = map(commonData.carType, (o) => o._id)
      }
    }

    if (Object.keys(newStates).length) {
      this.setState({ ...newStates })
    }
  }

  handleSearchChange = (e) => {
    const str = e.target.value
    this.setState({ str })
  }

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updatePermissionsList()
    }
  }

  updatePermissionsList = (activePage, limit) => {
    const params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        search: this.state.str,
      },
    }

    this.props.findPermission(params).then((data) => {
      if (data.ok && data.res) {
        const { page, total, limit } = data.res
        this.setState({
          permissions: data.res,
          footerData: { page, total, limit },
        })
      }
    })
  }

  noActionSelected = () => {
    if (
      !this.state.dialogData ||
      !this.state.dialogData.modules ||
      !this.state.dialogData.modules.length
    )
      return true
    const isSelect = find(this.state.dialogData.modules, (m) => {
      const actionSelected = find(m.actions, (action) => action.isActive)
      return !!actionSelected
    })
    return !isSelect
  }

  saveDialogForm = () => {
    const { dialogData, isSubmited, valid, supplierPermission, companyOperator } = this.state
    const {
      showLoadingSpiner,
      hideLoadingSpiner,
      auth,
      commonData,
      updatePermission,
      createPermission,
    } = this.props
    const { notification } = this.context

    if (this.noActionSelected()) {
      return notification('error', I18n.t('permission.No_action_selected'))
    }

    if (!isSubmited) this.setState({ isSubmited: true })

    if (!CCLiteCommonFunc.isFormValid(valid)) return false

    const body = {
      ...dialogData,
      fleetId: auth.selectedFleet.fleetId,
      companyOperator: companyOperator,
      supplierPermission: supplierPermission,
    }

    showLoadingSpiner()

    if (body.checkDriverCar === 1) {
      if (!body.companyId) {
        body.companyId =
          commonData.companies &&
          commonData.companies.length &&
          commonData.companies[0]._id
      }
    } else {
      body.companyId = null
    }

    body.drivers = map(dialogData.drivers, (item) => item._id)
    body.vehicles = map(dialogData.vehicles, (item) => item._id)

    if (body._id) {
      body.id = body._id
      delete body._id

      updatePermission(body).then((data) => {
        hideLoadingSpiner()
        if (!data.ok) {
          if (data.error) {
            notification('error', I18n.t(`errors.${data.error.errorCode}`))
          } else {
            notification('error', I18n.t('permission.Update_permission_fail'))
          }
        } else {
          notification(
            'success',
            I18n.t('permission.Update_permission_success')
          )
          this.closeDialogForm()
          this.updatePermissionsList()
          this.setState({ isSubmited: false })
        }
      })
    } else {
      createPermission(body).then((data) => {
        hideLoadingSpiner()
        if (!data.ok) {
          if (data.error) {
            notification('error', I18n.t(`errors.${data.error.errorCode}`))
          } else {
            notification('error', I18n.t('permission.Create_permission_fail'))
          }
        } else {
          notification(
            'success',
            I18n.t('permission.Create_permission_success')
          )
          this.closeDialogForm()
          this.updatePermissionsList()
          this.setState({ isSubmited: false })
        }
      })
    }
  }

  handleNameChange = (e) => {
    this.state.dialogData.name = e.target.value
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true })
  }

  handleEditFareChange = (name, key, action, value) => {
    this.handlePermissionCheck(name, key, action, value)
  }

  handlePermissionCheck = (name, key, action, value) => {
    if (!this.state.dialogData) {
      return false
    }
    const { modules = [] } = this.state.dialogData
    const lookupModule = _.find(
      modules,
      (m) => name === m.name && key === m.key
    )
    const isAddNewModule = !lookupModule
    /* If module of action not exists in state. */
    if (isAddNewModule) {
      const actions = [
        {
          name: action,
          isActive: value,
        },
      ]
      /* If in new module has no View action, add it. */
      if (action.toLowerCase() !== 'view')
        actions.push({
          name: 'View',
          isActive: true,
        })
      modules.push({
        name,
        key,
        actions,
      })
    } else {
      const { actions } = lookupModule
      let isAddNewAction = true
      /* Loop and set isActive for actions */
      _.forEach(actions, (a) => {
        if (a.name === action) {
          a.isActive = value
          isAddNewAction = false
        }
        if (action.toLowerCase() === 'view') {
          if (!value) {
            a.isActive = false
          }
        } else if (value && a.name.toLowerCase() === 'view') {
          a.isActive = true
        }
      })
      /* If action does not exists in module actions. Add action to actions list of module */
      if (isAddNewAction) {
        actions.push({
          name: action,
          isActive: value,
        })
      }
    }
    const dialogData = {
      ...this.state.dialogData,
      modules,
    }
    this.setState({
      dialogData,
      dialogChanged: true,
    })
  }
  handlePermissionReportCheck = (name, key, sub, action, value) => {
    if (!this.state.dialogData) {
      return false
    }
    const { modules = [] } = this.state.dialogData
    const lookupModule = _.find(
      modules,
      (m) => name === m.name && key === m.key
    )
    const isAddNewModule = !lookupModule
    /* If module of action not exists in state. */
    if (isAddNewModule) {
      const actions = [
        {
          name: sub,
          isActive: value,
          Export: value,
        },
      ]
      /* If in new module has no View action, add it. */
      if (action.toLowerCase() !== 'view')
        actions.push({
          name: 'View',
          isActive: true,
        })
      modules.push({
        name,
        key,
        actions,
      })
    } else {
      const { actions } = lookupModule
      let isAddNewAction = true
      /* Loop and set isActive for actions */
      _.forEach(actions, (a) => {
        if (a.name === sub) {
          if (action.toLowerCase() === 'export') {
            a.Export = value
            if (value) {
              a.isActive = value
            }
          } else {
            if (!value) {
              a.Export = false
            }
            a.isActive = value
          }
          isAddNewAction = false
        }
        if (action.toLowerCase() === 'view') {
          if (!value) {
            a.isActive = false
          }
        } else if (value && a.name.toLowerCase() === 'view') {
          a.isActive = true
        }
      })
      /* If action does not exists in module actions. Add action to actions list of module */
      if (isAddNewAction) {
        actions.push({
          name: sub,
          isActive: value,
          Export: value,
        })
      }
    }
    const dialogData = {
      ...this.state.dialogData,
      modules,
    }
    this.setState({
      dialogData,
      dialogChanged: true,
    })
  }
  getPermissonValue = (name, key, action) => {
    if (!this.state.dialogData) {
      return false
    }
    if (!this.state.dialogData.modules) {
      return false
    }
    if (name !== 'Report') {
      let module = this.state.dialogData.modules.filter(
        (m) => name == m.name && key == m.key
      )[0]
      if (!module) {
        return false
      }
      module = module.actions.filter((a) => a == null || a.name == action)[0]
      if (!module) {
        return false
      }
      return module.isActive
    } else {
      let module = this.state.dialogData.modules.filter(
        (m) => name == m.name
      )[0]
      if (!module) {
        return false
      }
      module = module.actions.filter((a) => a == null || a.name == key)[0]
      if (!module) {
        return false
      }
      if (action == 'isActive') {
        return module.isActive
      } else if (action == 'Export') {
        return module.Export
      } else {
        return false
      }
    }
  }

  handledialogCheck = (key, value) => {
    this.state.dialogData[key] = value

    if (key == 'checkDriverCar') {
      this.state.isShowCarsbox = false
      this.state.isShowDriverbox = false
      this.state.companyOperator = false
      this.state.supplierPermission = false

      if (value === 1) {
        this.state.dialogData.companyId = this.props.commonData.companies[0]
          ? this.props.commonData.companies[0]._id
          : null
      } else if (value === 2) {
        this.state.selectedCars = this.state.dialogData.vehicles
        this.state.selectedDrivers = this.state.dialogData.drivers
        this.state.dialogData.companyId = null
      }
    }

    this.setState({ dialogData: this.state.dialogData })
  }

  handleShowBoxClick = (value) => {
    const {
      isShowDriverbox,
      isShowCarsbox,
      dialogData,
      driverQuery,
      carQuery,
    } = this.state
    const { commonData } = this.props

    if (value === specificType.driver) {
      if (isShowDriverbox) {
        this.setState({ isShowDriverbox: false })
      } else {
        this.setState(
          {
            selectedDrivers: dialogData.drivers || [],
            selectingDrivers: [],
            isShowDriverbox: true,
            strPermittedFilter: '',
            selectedCarType: map(commonData.carType, (o) => o._id),
            selectedCompany:
              commonData.companies && commonData.companies.length
                ? commonData.companies[0]._id
                : '',
            driverQuery: {
              ...driverQuery,
              search: '',
            },
          },
          () => this.getDriverList({ page: 0 }, false)
        )
      }
    } else if (isShowCarsbox) {
      this.setState({ isShowCarsbox: false })
    } else {
      this.setState(
        {
          selectedCars: dialogData.vehicles || [],
          selectingCars: [],
          isShowCarsbox: true,
          strPermittedFilter: '',
          selectedCarType: map(commonData.carType, (o) => o._id),
          selectedCompany:
            commonData.companies && commonData.companies.length
              ? commonData.companies[0]._id
              : '',
          carQuery: {
            ...carQuery,
            search: '',
          },
        },
        () => this.getCarList({ page: 0 }, false)
      )
    }
  }

  handleCompanyChange = (e) => {
    const { dialogData } = this.state
    dialogData.companyId = e.target.value
    this.setState({ dialogData })
  }

  handleAddButtonClick = () => {
    const { driverQuery, carQuery } = this.state
    const { auth } = this.props
    const objecInit = {
      name: '',
      fleetId: auth.selectedFleet.fleetId,
      checkDriverCar: 0,
      action: false,
      view: false,
      vehicles: [],
      drivers: [],
      modules: [],
      isActive: true,
      allFleet: false,
      allVehicle: true,
      handle: true,
      systemRole: false,
    }
    this.setState({
      showDialog: true,
      dialogData: objecInit,
      isSubmited: false,
      editable: true,
      driverQuery: {
        ...driverQuery,
        list: [],
        page: 0,
        search: '',
      },
      carQuery: {
        ...carQuery,
        list: [],
        page: 0,
        search: '',
      },
    })
  }

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      editable: false,
      companyOperator: false,
      supplierPermission: false,
    })
  }

  convertData = (data) => {
    let modules = (data && data.modules) || []
    modules = modules.map((ob) => {
      if (ob.name === 'New booking' || ob.name === 'Cue') {
        let actions = ob.actions
        if (actions && !actions.some((ob) => ob.name === 'ShowFare')) {
          actions.push({ isActive: true, name: 'ShowFare' })
          return {
            ...ob,
            actions,
          }
        }
        return ob
      }
      return ob
    })
    return {
      ...data,
      modules,
    }
  }

  handleMenuClick = (key, permission) => {
    switch (key) {
      case 'Edit': {
        this.props
          .detailsPermission({
            id: permission._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              const { driverQuery, carQuery } = this.state
              this.setState({
                showDialog: true,
                dialogData: this.convertData(data.res),
                dialogChanged: false,
                editable: true,
                selectedCarType: [],
                selectedCars: data.res.vehicles || [],
                selectedDrivers: data.res.drivers || [],
                companyOperator: data.res.companyOperator || false,
                supplierPermission: data.res.supplierPermission || false,
                driverQuery: {
                  ...driverQuery,
                  list: [],
                  page: 0,
                  search: '',
                },
                carQuery: {
                  ...carQuery,
                  list: [],
                  page: 0,
                  search: '',
                },
              })
            } else if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              )
            } else {
              this.context.notification(
                'error',
                I18n.t('permission.Load_permission_fail')
              )
            }
          })
        break
      }
      case 'View': {
        this.props
          .detailsPermission({
            id: permission._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState({
                showDialog: true,
                dialogData: this.convertData(data.res),
                dialogChanged: false,
                editable: false,
                selectedCars: data.res.vehicles || [],
                selectedDrivers: data.res.drivers || [],
                companyOperator: data.res.companyOperator || false,
                supplierPermission: data.res.supplierPermission || false,
              })
            } else if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              )
            } else {
              this.context.notification(
                'error',
                I18n.t('permission.Load_permission_fail')
              )
            }
          })
        break
      }
      case 'Status': {
        this.props
          .changeStatusPermission({
            fleetId: this.props.auth.selectedFleet.fleetId,
            id: permission._id,
            isActive: !permission.isActive,
          })
          .then((data) => {
            if (data.ok) {
              this.updatePermissionsList()
            } else if (data.error) {
              this.context.notification(
                'error',
                I18n.t('errors.' + data.error.errorCode)
              )
            } else {
              this.context.notification(
                'error',
                I18n.t('permission.Update_status_error')
              )
            }
          })
        break
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: permission })
        break
      }
    }
  }

  handlePaginationSelect = (eventKey) => {
    this.updatePermissionsList(eventKey)
  }

  handleNumItemsPerPageChange = (limit) => {
    this.updatePermissionsList(0, limit)
  }

  confirmDeletePermission = () => {
    this.props
      .deletePermission({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm()
          this.updatePermissionsList()
          this.context.notification(
            'success',
            I18n.t('permission.Delete_permission_success')
          )
        } else if (data.error) {
          this.context.notification(
            'error',
            I18n.t('errors.' + data.error.errorCode)
          )
        } else {
          this.context.notification(
            'error',
            I18n.t('permission.Delete_permission_fail')
          )
        }
      })
  }

  ValidatorCallback = (id, valid, messages) => {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid
      this.setState({ valid: this.state.valid })
    }
  }

  checkExistInList = (type, data) => {
    const listToCheck =
      type === specificType.car ? 'selectedCars' : 'selectedDrivers'
    return find(this.state[listToCheck], (item) => item._id === data._id)
  }

  handleSaveCarDriverSelectedList = (type) => {
    if (type == specificType.car) {
      this.state.dialogData.vehicles = Object.assign(
        [],
        this.state.selectedCars
      )
    } else {
      this.state.dialogData.drivers = Object.assign(
        [],
        this.state.selectedDrivers
      )
    }
    this.setState({ isShowDriverbox: false, isShowCarsbox: false })
  }

  addCarsDriversSelectedToList = (type) => {
    const { selectingCars, selectedCars, selectingDrivers, selectedDrivers } =
      this.state

    if (type == specificType.car) {
      if (!selectingCars || !selectingCars.length) return

      this.setState({
        selectedCars: selectedCars.concat(selectingCars),
        selectingCars: [],
      })
    } else {
      if (!selectingDrivers || !selectingDrivers.length) return

      this.setState({
        selectedDrivers: selectedDrivers.concat(selectingDrivers),
        selectingDrivers: [],
      })
    }
  }

  handleSelectItem = (type, e) => {
    const { driverQuery, carQuery } = this.state
    const selectedItems = CCLiteCommonFunc.getSelectedOptons(e)

    if (type == specificType.driver) {
      this.state.selectingDrivers = selectedItems.map(
        (sl) =>
          driverQuery.list.filter((a) => {
            return a._id == sl
          })[0]
      )
    } else {
      this.state.selectingCars = selectedItems.map(
        (sl) =>
          carQuery.list.filter((a) => {
            return a._id == sl
          })[0]
      )
    }
  }

  removeCarDriverInSelectedList = (type, item) => {
    const { commonData } = this.props
    const {
      selectedDrivers,
      driverQuery,
      carQuery,
      selectedCars,
      selectedCarType,
      selectedCompany,
    } = this.state

    if (specificType.car == type) {
      const newSelected = filter(selectedCars, (o) => o._id !== item._id)
      let newCarList = []

      if (
        item.companyId === selectedCompany &&
        (!selectedCarType.length ||
          commonData.carType.length === selectedCarType.length ||
          selectedCarType.includes(item.vehicleTypeId)) &&
        item.plateNumber.toLowerCase().includes(carQuery.search.toLowerCase())
      ) {
        newCarList = orderBy(
          unionBy(carQuery.list, [item], '_id'),
          (o) => o.plateNumber.toLowerCase(),
          ['asc']
        )
      } else {
        newCarList = carQuery.list
      }

      this.setState({
        selectedCars: newSelected,
        carQuery: {
          ...carQuery,
          list: newCarList,
        },
      })
    } else {
      const newSelected = filter(selectedDrivers, (o) => o._id !== item._id)
      let newDriverList = []

      if (
        item.companyId === selectedCompany &&
        (!selectedCarType.length || selectedCarType.includes(item.carTypeId)) &&
        item.fullName.toLowerCase().includes(driverQuery.search.toLowerCase())
      ) {
        newDriverList = orderBy(
          unionBy(driverQuery.list, [item], '_id'),
          (o) => o.fullName.toLowerCase(),
          ['asc']
        )
      } else {
        newDriverList = driverQuery.list
      }

      this.setState({
        selectedDrivers: newSelected,
        driverQuery: {
          ...driverQuery,
          list: newDriverList,
        },
      })
    }
  }

  handleDriverInputChange = (e) => {
    this.setState({
      driverQuery: { ...this.state.driverQuery, search: e.target.value },
    })
  }

  handleCarInputChange = (e) => {
    this.setState({
      carQuery: { ...this.state.carQuery, search: e.target.value },
    })
  }

  handleDriverListScroll = () => {
    const { driverQuery } = this.state
    const page = driverQuery.page + 1

    if (driverQuery.hasMore) {
      this.getDriverList({ page: driverQuery.page + 1 }, true)
    }
  }

  handleCarListScroll = () => {
    const { carQuery } = this.state
    const page = carQuery.page + 1

    if (page * carQuery.limit < carQuery.total) {
      this.getCarList({ page: carQuery.page + 1 }, true)
    }
  }

  handlePermitFilterChange = (e) => {
    this.setState({ strPermittedFilter: e.target.value })
  }

  getTableHeight = () => {
    const verticalPadding = 10
    let toolbarMarginBottom = 10
    let toolbarheight = this.toobarContainer
      ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
      : 0
    let parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0
    const outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0
    return this.state.tableHeight
  }

  checkFilterRender(str, item, type) {
    if (!str || str.length == 0) {
      return true
    }
    str = str.toLowerCase()
    if (type == specificType.car) {
      if (item.plateNumber.toLowerCase().includes(str)) {
        return true
      }
    } else if (item.fullName.toLowerCase().includes(str)) {
      return true
    }
    return false
  }

  renderMainMenu = () => {
    const { defaultRoles, dialogData = {} } = this.state || {}
    const { selectedFleet } = this.props.auth
    const merchantCashWalletEnalble = _.get(
      selectedFleet,
      'merchantCashWallet.enable',
      false
    )
    const merchantCreditWalletEnalble = _.get(
      selectedFleet,
      'merchantCreditWallet.enable',
      false
    )
    return (
      <Row className={'mr-r-l-0'}>
        {defaultRoles &&
          defaultRoles.map((role) => {
            if (role.key == 'Booking') {
              const showFare =
                role.actions.findIndex((item) => item.name === 'ShowFare') !==
                -1
              const showEditFare =
                role.actions.findIndex((item) => item.name === 'EditFare') !==
                  -1 ||
                this.props.auth.selectedFleet.generalSetting.markupPrice;
              const showEditDriverEarning = selectedFleet?.generalSetting?.editDriverEarning;
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup key={role.name + '_View'}>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Sidebar'
                        )}
                      />
                    </Form.Label>
                    <CcCheckbox
                      onChange={(e) => {
                        this.handlePermissionCheck(
                          role.name,
                          role.key,
                          'View',
                          e.target.checked
                        )
                        this.handlePermissionCheck(
                          role.name,
                          role.key,
                          'Actions',
                          e.target.checked
                        )
                      }}
                      checked={this.getPermissonValue(
                        role.name,
                        role.key,
                        'View'
                      )}
                      text={I18n.t(
                        CCLiteCommonFunc.stringToLanguageKey(
                          'Actions',
                          'permission'
                        )
                      )}
                      className={'ml-md'}
                      disabled={
                        !(
                          !this.props.permissions ||
                          this.props.permissions.actions
                        )
                      }
                    />
                    {showEditFare && (
                      <CcCheckbox
                        onChange={(e) => {
                          this.handleEditFareChange(
                            role.name,
                            role.key,
                            'EditFare',
                            e.target.checked,
                          )
                        }}
                        checked={this.getPermissonValue(
                          role.name,
                          role.key,
                          'EditFare'
                        )}
                        text={I18n.t(
                          CCLiteCommonFunc.stringToLanguageKey(
                            'EditFare',
                            'permission'
                          )
                        )}
                        className={'ml-md'}
                        disabled={
                          !(
                            !this.props.permissions ||
                            this.props.permissions.actions
                          )
                        }
                      />
                    )}
                    {showEditDriverEarning && (
                      <CcCheckbox
                        onChange={(e) => {
                          this.handlePermissionCheck(
                            role.name,
                            role.key,
                            'EditDriverEarning',
                            e.target.checked
                          )
                        }}
                        checked={this.getPermissonValue(
                          role.name,
                          role.key,
                          'EditDriverEarning'
                        )}
                        text={I18n.t(
                          CCLiteCommonFunc.stringToLanguageKey(
                            'EditDriverEarning',
                            'permission'
                          )
                        )}
                        className={'ml-md'}
                        disabled={
                          !(
                            !this.props.permissions ||
                            this.props.permissions.actions
                          )
                        }
                      />
                    )}
                    {showFare && (
                      <CcCheckbox
                        onChange={(e) => {
                          this.handlePermissionCheck(
                            role.name,
                            role.key,
                            'ShowFare',
                            e.target.checked
                          )
                        }}
                        checked={this.getPermissonValue(
                          role.name,
                          role.key,
                          'ShowFare'
                        )}
                        text={I18n.t(
                          CCLiteCommonFunc.stringToLanguageKey(
                            'ShowFare',
                            'permission'
                          )
                        )}
                        className={'ml-md'}
                        disabled={
                          !(
                            !this.props.permissions ||
                            this.props.permissions.actions
                          )
                        }
                      />
                    )}
                  </FormGroup>
                </Col>
              )
            } else if (role.key === 'Driver') {
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup key={role.name + '_View'}>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Merchant'
                        )}
                      />
                    </Form.Label>
                    {role.actions.map((act) => {
                      if (
                        act.name === 'ChangeCashBalance' &&
                        !_.get(selectedFleet, 'driverCashWallet', false)
                      )
                        return null
                      if (
                        act.name === 'ChangeBalance' &&
                        !_.get(selectedFleet, 'driverDeposit', false)
                      )
                        return null
                      return (
                        <CcCheckbox
                          key={role.name + '_' + act.name}
                          onChange={(e) => {
                            this.handlePermissionCheck(
                              role.name,
                              role.key,
                              act.name,
                              e.target.checked
                            )
                          }}
                          checked={this.getPermissonValue(
                            role.name,
                            role.key,
                            act.name
                          )}
                          text={I18n.t(
                            CCLiteCommonFunc.stringToLanguageKey(
                              act.name,
                              'permission'
                            )
                          )}
                          className={'ml-md'}
                          disabled={
                            !(
                              !this.props.permissions ||
                              this.props.permissions.actions
                            )
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </Col>
              )
            } else if (role.key == 'Merchant') {
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup key={role.name + '_View'}>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Merchant'
                        )}
                      />
                    </Form.Label>
                    {role.actions.map((act) => {
                      if (
                        act.name === 'ChangeCashBalance' &&
                        !merchantCashWalletEnalble
                      )
                        return null
                      if (
                        act.name === 'ChangeBalance' &&
                        !merchantCreditWalletEnalble
                      )
                        return null
                      return (
                        <CcCheckbox
                          key={role.name + '_' + act.name}
                          onChange={(e) => {
                            this.handlePermissionCheck(
                              role.name,
                              role.key,
                              act.name,
                              e.target.checked
                            )
                          }}
                          checked={this.getPermissonValue(
                            role.name,
                            role.key,
                            act.name
                          )}
                          text={I18n.t(
                            CCLiteCommonFunc.stringToLanguageKey(
                              act.name,
                              'permission'
                            )
                          )}
                          className={'ml-md'}
                          disabled={
                            !(
                              !this.props.permissions ||
                              this.props.permissions.actions
                            )
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </Col>
              )
            } else if (role.key == 'Reservation') {
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup key={role.name + '_View'}>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Sidebar'
                        )}
                      />
                    </Form.Label>
                    <CcCheckbox
                      onChange={(e) => {
                        this.handlePermissionCheck(
                          role.name,
                          role.key,
                          'View',
                          e.target.checked
                        )
                        this.handlePermissionCheck(
                          role.name,
                          role.key,
                          'Actions',
                          e.target.checked
                        )
                      }}
                      checked={this.getPermissonValue(
                        role.name,
                        role.key,
                        'View'
                      )}
                      text={I18n.t(
                        CCLiteCommonFunc.stringToLanguageKey(
                          'View',
                          'permission'
                        )
                      )}
                      className={'ml-md'}
                      disabled={
                        !(
                          !this.props.permissions ||
                          this.props.permissions.actions
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              )
            } else if (role.name == role.key && role.key != 'Report') {
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Sidebar'
                        )}
                      />
                    </Form.Label>
                    {role.actions.map((act) => {
                      return (
                        <CcCheckbox
                          key={role.name + '_' + act.name}
                          onChange={(e) => {
                            this.handlePermissionCheck(
                              role.name,
                              role.key,
                              act.name,
                              e.target.checked
                            )
                          }}
                          checked={this.getPermissonValue(
                            role.name,
                            role.key,
                            act.name
                          )}
                          text={I18n.t(
                            CCLiteCommonFunc.stringToLanguageKey(
                              act.name,
                              'permission'
                            )
                          )}
                          className={'ml-md'}
                          disabled={
                            !(
                              !this.props.permissions ||
                              this.props.permissions.actions
                            )
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </Col>
              )
            } else if (
              role.key === 'Settlement' ||
              role.key === 'WithdrawalRequests' ||
              role.key === 'QueuingArea' ||
              role.key === 'SmartData' ||
              role.key === 'CampaignBuilder' ||
              role.key === 'Invoice'
            ) {
              return (
                <Col xs={3} md={3} className={'pd-r-l-0'} key={role.key}>
                  <FormGroup>
                    <Form.Label className="bold title">
                      <Translate
                        value={CCLiteCommonFunc.stringToLanguageKey(
                          role.name,
                          'Sidebar'
                        )}
                      />
                    </Form.Label>
                    {role.actions.map((act) => {
                      let labelName = act.name
                      return (
                        <CcCheckbox
                          key={role.name + '_' + act.name}
                          onChange={(e) => {
                            this.handlePermissionCheck(
                              role.name,
                              role.key,
                              act.name,
                              e.target.checked
                            )
                          }}
                          checked={this.getPermissonValue(
                            role.name,
                            role.key,
                            act.name
                          )}
                          text={I18n.t(
                            CCLiteCommonFunc.stringToLanguageKey(
                              labelName,
                              'permission'
                            )
                          )}
                          className={'ml-md'}
                          disabled={
                            !(
                              !this.props.permissions ||
                              this.props.permissions.actions
                            )
                          }
                          hasTooltip={act.name === 'editBooking'}
                          tooltipText={
                            act.name === 'editBooking'
                              ? I18n.t('permission.Hint_edit_booking')
                              : ''
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </Col>
              )
            }
            return null
          })}
      </Row>
    )
  }

  renderPayout = () => {
    const roles = this.state.defaultRoles.filter((r) => r.key == 'Payout')
    const rows = Math.ceil(roles.length / 3)
    const renderObj = []
    for (let i = 0; i < rows; i++) {
      const rowRoles = roles.slice(
        i * 3,
        i * 3 + 3 <= roles.length ? i * 3 + 3 : roles.length
      )
      renderObj[i] = rowRoles
    }
    return (
      <Col xs={12} md={12}>
        {renderObj.map((row, index) => {
          return (
            <Row key={index}>
              {row.map((role, index) => {
                let title = CCLiteCommonFunc.stringToLanguageKey(
                  role.name,
                  'Sidebar.payout'
                )
                return (
                  <Col xs={4} md={4} key={role.name} className={'pd-r-l-0'}>
                    <FormGroup>
                      <Form.Label className="bold title">
                        <Translate value={title} />
                      </Form.Label>
                      {role.actions.map((act) => {
                        return (
                          <CcCheckbox
                            key={role.name + '_' + act.name}
                            onChange={(e) => {
                              this.handlePermissionCheck(
                                role.name,
                                role.key,
                                act.name,
                                e.target.checked
                              )
                            }}
                            checked={this.getPermissonValue(
                              role.name,
                              role.key,
                              act.name
                            )}
                            text={I18n.t(
                              CCLiteCommonFunc.stringToLanguageKey(
                                act.name,
                                'permission'
                              )
                            )}
                            className={'ml-md'}
                            disabled={
                              !(
                                !this.props.permissions ||
                                this.props.permissions.actions
                              )
                            }
                          />
                        )
                      })}
                    </FormGroup>
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Col>
    )
  }

  renderSettings = () => {
    const roles = this.state.defaultRoles.filter((r) => r.key == 'Setting')
    const rows = Math.ceil(roles.length / 3)
    const renderObj = []
    for (let i = 0; i < rows; i++) {
      const rowRoles = roles.slice(
        i * 3,
        i * 3 + 3 <= roles.length ? i * 3 + 3 : roles.length
      )
      renderObj[i] = rowRoles
    }
    return (
      <Col xs={12} md={12}>
        {renderObj.map((row, index) => (
          <Row key={index}>
            {row.map((role, index) => {
              {
                /* 
              Old code: Only render "VIEW" action of Fleet info. In new code, "Actions" action of "Fleet info" has been removed in ComponentDidMount life cycle
              */
              }
              {
                /* if (role.name == "Fleet info") {
                return (
                  <Col xs={4} md={4} className={"pd-r-l-0"} key={index}>
                    <FormGroup key={role.name + "_View"}>
                      <Form.Label className="bold title">
                        <Translate
                          value={CCLiteCommonFunc.stringToLanguageKey(
                            role.name,
                            "Sidebar.Settings"
                          )}
                        />
                      </Form.Label>
                      <CcCheckbox
                        disabled={!this.state.editable}
                        onChange={e => {
                          this.handlePermissionCheck(
                            role.name,
                            role.key,
                            "View",
                            e.target.checked
                          );
                        }}
                        checked={this.getPermissonValue(
                          role.name,
                          role.key,
                          "View"
                        )}
                        text={I18n.t(
                          CCLiteCommonFunc.stringToLanguageKey(
                            "View",
                            "permission"
                          )
                        )}
                        className={"ml-md"}
                      />
                    </FormGroup>
                  </Col>
                );
              } else { */
              }
              let title = CCLiteCommonFunc.stringToLanguageKey(
                role.name,
                'Sidebar.Settings'
              )
              if (role.name == 'Driver to pax') {
                title = CCLiteCommonFunc.stringToLanguageKey(
                  'Referral',
                  'Sidebar.Settings'
                )
              }
              return (
                <Col xs={4} md={4} key={role.name} className={'pd-r-l-0'}>
                  <FormGroup>
                    <Form.Label className="bold title">
                      <Translate value={title} />
                    </Form.Label>
                    {role.actions.map((act) => {
                      return (
                        <CcCheckbox
                          key={role.name + '_' + act.name}
                          onChange={(e) => {
                            this.handlePermissionCheck(
                              role.name,
                              role.key,
                              act.name,
                              e.target.checked
                            )
                          }}
                          checked={this.getPermissonValue(
                            role.name,
                            role.key,
                            act.name
                          )}
                          text={I18n.t(
                            CCLiteCommonFunc.stringToLanguageKey(
                              act.name,
                              'permission'
                            )
                          )}
                          className={'ml-md'}
                          disabled={
                            !(
                              !this.props.permissions ||
                              this.props.permissions.actions
                            )
                          }
                        />
                      )
                    })}
                  </FormGroup>
                </Col>
              )
              {
                /* } */
              }
            })}
          </Row>
        ))}
      </Col>
    )
  }

  renderReports = () => {
    const role = this.state.defaultRoles.filter(
      (r) => r.name == r.key && r.key == 'Report'
    )[0];
    return (
      <Row>
        {
          role &&
          role.actions.map((act) => {
            if (this.state.supplierPermission) { // only show bookingDetail report when supplierPermission
              if (act.name !== 'Booking details') {
                return null;
              }
            }
            return (
              <Col
                xs={4}
                md={4}
                className={'pd-r-l-0'}
                key={role.key + '_' + act.name}
              >
                <FormGroup>
                  <Form.Label className="bold title">
                    <Translate
                      value={CCLiteCommonFunc.stringToLanguageKey(
                        act.name,
                        'Sidebar.Reports'
                      )}
                    />
                  </Form.Label>
                  <CcCheckbox
                    key={role.name + '_' + act.name + '_isActive'}
                    onChange={(e) => {
                      this.handlePermissionReportCheck(
                        role.name,
                        role.key,
                        act.name,
                        'isActive',
                        e.target.checked
                      );
                    }}
                    checked={this.getPermissonValue(
                      role.name,
                      act.name,
                      'isActive'
                    )}
                    text={I18n.t(
                      CCLiteCommonFunc.stringToLanguageKey('View', 'permission')
                    )}
                    className={'ml-md'}
                  />
                  <CcCheckbox
                    key={role.name + '_' + act.name + '_Export'}
                    onChange={(e) => {
                      this.handlePermissionReportCheck(
                        role.name,
                        role.key,
                        act.name,
                        'Export',
                        e.target.checked
                      );
                    }}
                    checked={this.getPermissonValue(role.name, act.name, 'Export')}
                    text={I18n.t(
                      CCLiteCommonFunc.stringToLanguageKey('Export', 'permission')
                    )}
                    className={'ml-md'}
                    disabled={
                      !(!this.props.permissions || this.props.permissions.actions)
                    }
                  />
                </FormGroup>
              </Col>
            );
          })
        }
      </Row>
    )
  }

  handleFullPerrmisionsClick = (e) => {
    if (e.target.checked) {
      this.state.defaultRoles.map((mn) => {
        mn &&
          mn.actions &&
          mn.actions.map((act) => {
            if (mn.name !== 'Report') {
              this.handlePermissionCheck(mn.name, mn.key, act.name, true)
            } else {
              this.handlePermissionReportCheck(
                mn.name,
                mn.key,
                act.name,
                'Export',
                true
              )
            }
          })
      })
    } else {
      this.state.defaultRoles.map((mn) => {
        mn &&
          mn.actions &&
          mn.actions.map((act) => {
            if (mn.name !== 'Report') {
              this.handlePermissionCheck(mn.name, mn.key, act.name, false)
            } else {
              this.handlePermissionReportCheck(
                mn.name,
                mn.key,
                act.name,
                'Export',
                false
              )
              this.handlePermissionReportCheck(
                mn.name,
                mn.key,
                act.name,
                'isActive',
                false
              )
            }
          })
      })
    }
  }

  isFullPermissionChecked = () => {
    let isChecked = true
    this.state.defaultRoles.every((mn) => {
      mn &&
        mn.actions &&
        mn.actions.map((act) => {
          if (mn.name !== 'Report') {
            if (!this.getPermissonValue(mn.name, mn.key, act.name)) {
              isChecked = false
            }
            return isChecked
          } else {
            if (
              !this.getPermissonValue(mn.name, act.name, 'isActive') ||
              !this.getPermissonValue(mn.name, act.name, 'Export')
            ) {
              isChecked = false
            }
            return isChecked
          }
        })
      return isChecked
    })
    return isChecked
  }

  getTableColumns = () => {
    const tableColumns = Object.assign([], Columns)
    // custom data by column
    forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (permission) => <span>{permission.name}</span>
          break

        case 'permissionStatus':
          col.customCell = (permission) => {
            let statusStr
            let statusClass
            if (permission && permission.isActive) {
              statusStr = <Translate value="permission.Active" />
              statusClass = 'Active'
            } else if (permission && !permission.isActivate) {
              statusStr = <Translate value="permission.Inactive" />
              statusClass = 'Inactive'
            }
            return (
              <div className={statusClass}>
                {!this.props.permissions || this.props.permissions.actions ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', permission)
                    }}
                    href="#status"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            )
          }
          break

        case 'actions':
          col.customCell = (permission, rowIndex) => {
            if (!this.props.permissions || this.props.permissions.actions) {
              let actionItems = [
                {
                  label: 'permission.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: permission.isActive
                    ? 'permission.Deactivate'
                    : 'permission.Activate',
                  eventKey: 'Status',
                },
              ]

              if (!permission.isActive) {
                actionItems.push({
                  label: 'permission.Delete',
                  eventKey: 'Delete',
                })
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={permission}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, permission)
                  }}
                  totalRow={this.state.permissions.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              )
            }
            return (
              <a
                onClick={(e) => {
                  this.handleMenuClick('View', permission)
                }}
                href="javascript:void(0)"
              >
                <Translate value="permission.View" />
              </a>
            )
          }
          break
      }
    })

    return tableColumns
  }

  getDriverList = (params = {}, loadMore = false) => {
    const { loadMoreDrivers, auth, commonData } = this.props
    const { selectedCompany, selectedCarType, selectedDrivers } = this.state
    const { driverQuery } = this.state
    const query = {
      fleetId: auth.selectedFleet.fleetId,
      isActive: true,
      searchByName: true,
      companyId: selectedCompany,
      limit: driverQuery.limit,
      page: driverQuery.page,
      search: driverQuery.search,
    }

    if (
      selectedCarType.length !== 0 &&
      selectedCarType.length !== commonData.carType.length
    )
      query.carType = selectedCarType

    if (selectedDrivers.length) {
      query.ids = map(selectedDrivers, (driver) => driver._id)
    }

    loadMoreDrivers(Object.assign({}, query, params)).then((data) => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          driverQuery: {
            ...driverQuery,
            ...data.res,
            hasMore: data.res.list.length === query.limit,
            list: loadMore
              ? driverQuery.list.concat(data.res.list)
              : data.res.list,
          },
        })
      }
    })
  }

  getCarList = (params = {}, loadMore = false) => {
    const { loadMoreVehicles, auth, commonData } = this.props
    const { selectedCompany, selectedCarType, selectedCars } = this.state
    const { carQuery } = this.state
    const query = {
      fleetId: auth.selectedFleet.fleetId,
      companyId: selectedCompany,
      limit: carQuery.limit,
      page: carQuery.page,
      search: carQuery.search,
    }

    if (
      selectedCarType.length !== 0 &&
      selectedCarType.length !== commonData.carType.length
    )
      query.vehicleType = selectedCarType

    if (selectedCars.length) {
      query.omitIds = map(selectedCars, (car) => car._id)
    }

    loadMoreVehicles(Object.assign({}, query, params)).then((data) => {
      if (data.ok && data.res && data.res.list) {
        this.setState({
          carQuery: {
            ...carQuery,
            ...data.res,
            list: loadMore
              ? carQuery.list.concat(data.res.list)
              : data.res.list,
          },
        })
      }
    })
  }

  handleSelectCarType = (value) => {
    this.setState({
      selectedCarType: value,
    })
  }

  handleSelectCompany = (value, type) => {
    this.setState(
      {
        selectedCompany: value,
      },
      () =>
        type === specificType.driver
          ? this.getDriverList({ page: 0 }, false)
          : this.getCarList({ page: 0 }, false)
    )
  }

  handleConfirmButtonClick = (id) => {
    switch (id) {
      case CONFIRM_EXPORT_ID:
        {}
        break
    }
    this.handleConfirmCloseClick()
  }

  prepareParamExport = () => {
    let query = { fleetId: this.props.auth.selectedFleet.fleetId };
    query.locale = localStorage.getItem('language');
    if (this.state.str) {
      query.str = this.state.str;
    }
    let sort = { createdDate: this.state.sortType };
    if (this.state.sortName) {
      sort = { [this.state.sortName]: this.state.sortType };
    }
    return {
      query,
      sort,
      total: _.get(this.state.footerData, 'total', 0),
    }
  }

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null })
  }

  handleApplyButtonClick = (type) =>
    type === specificType.driver
      ? this.getDriverList({ page: 0 }, false)
      : this.getCarList({ page: 0 }, false)

  handleChangeSupplierPermission = () => {
    if(!this.state.supplierPermission) {
      this.setState({
        supplierPermission: true,
        companyOperator: false,
        dialogData: {
          ...this.state.dialogData,
          modules: [
            {
              "name": "Cue",
              "key": "Cue",
              "actions": [
                {
                  "name": "Actions",
                  "isActive": true
                },
                {
                  "name": "View",
                  "isActive": true
                }
              ]
            },
            {
              "name": "Map",
              "key": "Map",
              "actions": [
                {
                  "name": "Actions",
                  "isActive": true
                },
                {
                  "name": "View",
                  "isActive": true
                }
              ]
            },
            {
              "name": "Driver",
              "key": "Driver",
              "actions": [
                {
                  "name": "View",
                  "isActive": true
                },
                {
                  "name": "Actions",
                  "isActive": true
                },
                {
                  "name": "ActivateDriver",
                  "isActive": true
                },
                {
                  "name": "ChangeBalance",
                  "isActive": true
                },
                {
                  "name": "ChangeCashBalance",
                  "isActive": true
                },
                {
                  "name": "Export",
                  "isActive": true
                },
                {
                  "name": "TotalBalance",
                  "isActive": true
                },
                {
                  "name": "SeFleetCommission",
                  "isActive": true
                },
                {
                  "name": "sendSMS",
                  "isActive": true
                },
                {
                  "name": "sendInbox",
                  "isActive": true
                },
                {
                  "name": "EditFleetCommission",
                  "isActive": true
                }
              ]
            },
            {
              "name" : "Vehicle",
              "key" : "Vehicle",
              "actions" : [
                {
                    "name" : "View",
                    "isActive" : true
                },
                {
                    "name" : "Actions",
                    "isActive" : true
                },
                {
                    "name" : "Export",
                    "isActive" : true
                }
              ],
          }
          ]
        }
      })
    } else {
      this.setState({
        supplierPermission: !this.state.supplierPermission,
        dialogData: {
          ...this.state.dialogData,
          modules: []
        }
      })
    }
  }

  render() {
    const bodyData =
      this.state.permissions && this.state.permissions.list
        ? this.state.permissions.list
        : []
    const { permissions = null, auth: { user } = {} } = this.props || {}
    const { actions = false } = permissions || {}
    const exportPermission = user.isAdmin ? true : permissions.export
    const payoutRoles = this.state.defaultRoles.filter((r) => r.key == 'Payout')
    return (
      <div className="content">
        {this.state.showConfirm && actions ? (
          <Modal onHide={this.closeDialogForm} show backdrop className="confirm">
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="permission.delete_permission" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="permission.DELETE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-save mr-md"
                onClick={this.confirmDeletePermission}
              >
                <Translate value="permission.Yes" />
              </Button>
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="permission.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.isShowDriverbox ? (
          <SpecificDriver
            handleShowBoxClick={this.handleShowBoxClick}
            handleDriverInputChange={this.handleDriverInputChange}
            handleSelectItem={this.handleSelectItem}
            checkExistInList={this.checkExistInList}
            checkFilterRender={this.checkFilterRender}
            addDriversSelectedToList={this.addCarsDriversSelectedToList}
            handlePermitFilterChange={this.handlePermitFilterChange}
            removeCarDriverInSelectedList={this.removeCarDriverInSelectedList}
            handleSaveCarDriverSelectedList={
              this.handleSaveCarDriverSelectedList
            }
            search={this.state.driverQuery.search}
            driverList={this.state.driverQuery.list}
            strPermittedFilter={this.state.strPermittedFilter}
            selectedDrivers={this.state.selectedDrivers}
            specificType={specificType.driver}
            companies={this.props.commonData.companies}
            selectedCompany={this.state.selectedCompany}
            selectedCarType={this.state.selectedCarType}
            carTypes={this.props.commonData.carType || []}
            handleSelectCarType={this.handleSelectCarType}
            handleSelectCompany={this.handleSelectCompany}
            handleSearchDriver={this.handleApplyButtonClick}
            handleListScroll={this.handleDriverListScrollDebounce}
            actions={actions}
          />
        ) : null}
        {this.state.isShowCarsbox ? (
          <SpecificCar
            handleShowBoxClick={this.handleShowBoxClick}
            handleCarInputChange={this.handleCarInputChange}
            handleSelectItem={this.handleSelectItem}
            checkExistInList={this.checkExistInList}
            checkFilterRender={this.checkFilterRender}
            addCarsSelectedToList={this.addCarsDriversSelectedToList}
            handlePermitFilterChange={this.handlePermitFilterChange}
            removeCarDriverInSelectedList={this.removeCarDriverInSelectedList}
            handleSaveCarDriverSelectedList={
              this.handleSaveCarDriverSelectedList
            }
            search={this.state.carQuery.search}
            carList={this.state.carQuery.list}
            strPermittedFilter={this.state.strPermittedFilter}
            selectedCars={this.state.selectedCars}
            specificType={specificType.car}
            companies={this.props.commonData.companies}
            selectedCompany={this.state.selectedCompany}
            selectedCarType={this.state.selectedCarType}
            carTypes={this.props.commonData.carType || []}
            handleSelectCarType={this.handleSelectCarType}
            handleSelectCompany={this.handleSelectCompany}
            handleSearchCar={this.handleApplyButtonClick}
            handleListScroll={this.handleCarListScrollDebounce}
            actions={actions}
          />
        ) : null}
        {this.state.showDialog ? (
          <Modal
            show
            backdrop="static"
            dialogClassName="fit-content-modal permission-detail"
            onHide={this.closeDialogForm}
          >
            <Modal.Header className={'text-center pd-t-30 pd-b-30"'}>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="permission.Edit_permission" />
                  ) : (
                    <Translate value="permission.Detail_permission" />
                  )
                ) : (
                  <Translate value="permission.Add_permission" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className="pd-r-l-60 permission-form">
              <Row>
                <Col xs={6} md={6}>
                  <FormGroup
                    className={
                      !this.state.isSubmited
                        ? null
                        : this.state.valid.name
                        ? null
                        : 'error'
                    }
                  >
                    <Form.Label>
                      <Translate value="permission.Name" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      className="form-custom"
                      onChange={this.handleNameChange}
                      value={
                        this.state.dialogData ? this.state.dialogData.name : ''
                      }
                      placeholder={I18n.t('permission.Name')}
                      disabled={!this.state.editable}
                    />
                    <Validator id="name" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmited}
                        valid={
                          !Validation.isStringEmpty(this.state.dialogData.name)
                        }
                        message={I18n.t(
                          'messages.commonMessages.Required_field'
                        )}
                      />
                    </Validator>
                  </FormGroup>
                  <div className="vs-10" />
                  <FormGroup className="direction-row select-permission-type-group">
                    <Form.Label className=" mr-r-50">
                      <Translate value="permission.Driver_Car" />
                    </Form.Label>
                    <RadioButton
                      text={I18n.t('permission.All')}
                      inline
                      name="Driver_Car"
                      value={0}
                      checked={this.state.dialogData.checkDriverCar == 0}
                      disabled={!this.state.editable}
                      onChange={() => {
                        this.handledialogCheck('checkDriverCar', 0)
                      }}
                    />
                    <QuestionCircleTooltip
                      text={<Translate value="permission.All_tooltip" />}
                    />
                    <RadioButton
                      text={I18n.t('permission.Company')}
                      inline
                      name="Driver_Car"
                      value={1}
                      checked={this.state.dialogData.checkDriverCar == 1}
                      disabled={!this.state.editable}
                      onChange={() => {
                        this.handledialogCheck('checkDriverCar', 1)
                      }}
                    />
                    <QuestionCircleTooltip
                      text={<Translate value="permission.Company_tooltip" />}
                    />
                  </FormGroup>
                  {this.state.dialogData.checkDriverCar == 1 ? (
                    <FormGroup>
                      <FormControl
                        className="form-custom form-custom"
                        as="select"
                        value={this.state.dialogData.companyId}
                        onChange={this.handleCompanyChange}
                        disabled={!actions}
                      >
                        {this.props.commonData.companies.map((c) => (
                          <option value={c._id}>{c.name}</option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  ) : null}
                  {this.state.dialogData.checkDriverCar == 2 ? (
                    <Row>
                      <Col xs={12}>
                        <FormGroup className="text-center mr-b-10 direction-row float-left">
                          <a
                            className="mr-r-20 center-items cursor-pointer text-active"
                            onClick={() => {
                              this.handleShowBoxClick(specificType.driver)
                            }}
                          >
                            <i
                              className="fa-2x fa fa-list-alt"
                              aria-hidden="true"
                            />
                            <Translate value="permission.Drivers" />
                          </a>
                          <a
                            className="mr-l-20 center-items cursor-pointer text-active"
                            onClick={() => {
                              this.handleShowBoxClick(specificType.car)
                            }}
                          >
                            <i
                              className="fa-2x fa fa-list-alt"
                              aria-hidden="true"
                            />
                            <Translate value="permission.Cars" />
                          </a>
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : null}
                  <div className="vs-10" />
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Form.Label className="bold title">
                          <Translate value="permission.Full_permissions" />
                        </Form.Label>
                        <CcCheckbox
                          onChange={this.handleFullPerrmisionsClick}
                          checked={this.isFullPermissionChecked()}
                          text={I18n.t('permission.Full_permissions')}
                          className="ml cpn"
                          disabled={!actions || this.state.supplierPermission}
                        />
                      </FormGroup>
                    </Col>
                    {this.state.dialogData.checkDriverCar == 1 && (
                      <Col md={4}>
                        <FormGroup>
                          <Form.Label className="bold title">
                            <Translate value="permission.companyOperator" />
                          </Form.Label>
                          <CcCheckbox
                            onChange={() => {
                              this.setState({
                                companyOperator: !this.state.companyOperator,
                              })
                            }}
                            checked={this.state.companyOperator}
                            text={I18n.t('permission.editBooking')}
                            className="ml cpn"
                            disabled={!actions || this.state.supplierPermission}
                            hasTooltip
                            tooltipText={I18n.t('permission.Hint_edit_booking')}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  {
                    !this.state.supplierPermission &&
                    <div className="group-roles">{this.renderMainMenu()}</div>
                  }
                  <div className="vs-30" />
                  <div className="group-roles">
                    <FormGroupTitle>
                      <Translate value="Sidebar.REPORTS" />
                    </FormGroupTitle>
                    {this.renderReports()}
                  </div>
                  {
                    this.state.supplierPermission &&
                    <Col xs={12} md={12} className="mr-t-20 pd-r-l-0">
                      <CcCheckbox
                        checked={this.state.dialogData.isActive}
                        disabled={this.state.dialogData.inUse || !actions}
                        onChange={(e) => {
                          this.handledialogCheck('isActive', e.target.checked)
                        }}
                        text={
                          <span>
                            <Translate value="permission.Active" />{' '}
                            <span className="require">*</span>
                          </span>
                        }
                        labelClassName="bold"
                      />
                    </Col>
                  }
                </Col>
                <Col xs={6} md={6}>
                  {payoutRoles.length > 0 &&
                  !this.state.supplierPermission && (
                    <>
                      <div className="group-roles">
                        <FormGroupTitle>
                          <Translate value="Sidebar.Payout" />
                        </FormGroupTitle>
                        {this.renderPayout()}
                      </div>
                      <div className="vs-30" />
                    </>
                  )}
                  {
                    !this.state.supplierPermission &&
                    <div className="group-roles">
                      <FormGroupTitle>
                        <Translate value="Sidebar.Setting" />
                      </FormGroupTitle>
                      {this.renderSettings()}
                    </div>
                  }
                  {
                    !this.state.supplierPermission &&
                    <Col xs={12} md={12} className="mr-t-20 pd-r-l-0">
                      <CcCheckbox
                        checked={this.state.dialogData.isActive}
                        disabled={this.state.dialogData.inUse || !actions}
                        onChange={(e) => {
                          this.handledialogCheck('isActive', e.target.checked)
                        }}
                        text={
                          <span>
                            <Translate value="permission.Active" />{' '}
                            <span className="require">*</span>
                          </span>
                        }
                        labelClassName="bold"
                      />
                    </Col>
                  }
                </Col>
              </Row>
              <div className="vs-10" />
              <Form.Label>
                (<span className="require">*</span>
                ): <Translate value="permission.Required_fields" />
              </Form.Label>
            </Modal.Body>
            <Modal.Footer className="pd-r-l-60">
              {actions ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.saveDialogForm}
                  disabled={!CCLiteCommonFunc.isFormValid(this.state.valid)}
                >
                  <Translate value="permission.Save" />
                </Button>
              ) : null}
              <Button className="btn-cancel" onClick={this.closeDialogForm}>
                <Translate value="permission.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}

        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {actions ? (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="permission.Add" />
              </Button>
            ) : null}
          </ButtonGroup>
          {permissions && (actions || exportPermission) ? (
            <ButtonGroup className="group-right">
              {exportPermission &&
                <ExportComponent 
                  prepareParamExport={this.prepareParamExport}
                  urlExport={this.props.exportPermissionsToExcel}
                  user={this.props.auth.user}
                />
              }
            </ButtonGroup>
          ) : (
            ''
          )}
        </ButtonToolbar>
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    )
  }
}

Permission.contextTypes = {
  notification: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    users: state.users,
    permissions: state.menuHandle.modulePermission,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showLoadingSpiner: bindActionCreators(showLoadingSpiner, dispatch),
    hideLoadingSpiner: bindActionCreators(hideLoadingSpiner, dispatch),
    loadMoreDrivers: bindActionCreators(loadMoreDrivers, dispatch),
    loadMoreVehicles: bindActionCreators(loadMoreVehicles, dispatch),
    getDefaultRolesPermission: bindActionCreators(
      getDefaultRolesPermission,
      dispatch
    ),
    findPermission: bindActionCreators(findPermission, dispatch),
    updatePermission: bindActionCreators(updatePermission, dispatch),
    createPermission: bindActionCreators(createPermission, dispatch),
    detailsPermission: bindActionCreators(detailsPermission, dispatch),
    changeStatusPermission: bindActionCreators(
      changeStatusPermission,
      dispatch
    ),
    deletePermission: bindActionCreators(deletePermission, dispatch),
    exportPermissionsToExcel: bindActionCreators(
      exportPermissionsToExcel,
      dispatch
    ),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Permission)
