import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate, I18n } from "react-redux-i18n";
import IntlTelInputApp from "../../components/intlTelInputCustome/IntlTelInputApp";
import { Validator, ValidCase } from "../../components/validator";
import { CCLiteCommonFunc, Validation } from "../../utils/commonFunctions";
import * as loadingBarActions from "./../../actions/loadingBarActions";
import { CountryDropdown } from "react-country-region-selector";
import DateTime from "../../components/dateTime/DateTime";
import Confirm from "../../components/confirm/Confirm";
import CcCheckbox from "../../components/ccCheckbox/CcCheckbox";
import {
  getTraveler,
  activeTraveler,
  operateTraveler,
  deleteTraveler,
  checkExistCorporateTravellerPhone,
  countTraveler
} from "../../actions/corporateAction";
import {
  getTravelerAirline,
  activeTravelerAirline,
  operateTravelerAirline,
  activeCrew,
  deleteCrew,
  resetPasswordCrew,
  // deleteTraveler,
  checkExistCrewPhone,
  // countTraveler,
  operateCrew,
  getCrew
} from "../../actions/airlineAction";
import { trimPhoneNumber } from "../../utils/commonFunctions";
import Moment from "react-moment";
import _ from "lodash";
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  Button,
  ButtonToolbar,
  ButtonGroup
} from "react-bootstrap";
import PropTypes from "prop-types";
import StickyTable from "../../components/table/stickyTable/StickyTable";
import { CorpTravelerColumns, CorpTravelerActions } from "./tableHeaderColums";
import TableActions from "../../components/table/tableAction/TableActions";
import moment from "moment";
import { is } from "bluebird";
import { BsSearch } from "react-icons/bs";
const CONFIRM_DELETE_LINK_ID = 1;
let deleteUserId;
let phone;
class CropTraveler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      isSubmitted: false,
      activePage: 1,
      confirm: null,
      sortType: -1,
      phoneValidation: true,
      phoneExistValidation: true,
      usernameExistValidation: true,
      rowHeight: 50,
      tableHeight: 500,
      shouldRenderTable: false,
      footerData: {
        page: 0,
        limit: 20,
        total: 0
      },
      footerLoading: true
    };
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleSaveTravelerClick = this.handleSaveTravelerClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAssistantEmailChange = this.handleAssistantEmailChange.bind(
      this
    );
    this.handleCorpIdChange = this.handleCorpIdChange.bind(this);
    this.handleCorpDivisionChange = this.handleCorpDivisionChange.bind(this);
    this.handleCostCentreChange = this.handleCostCentreChange.bind(this);
    this.handleCorpPOChange = this.handleCorpPOChange.bind(this);
    this.handleCorpManagerNameChange = this.handleCorpManagerNameChange.bind(
      this
    );
    this.handleCorpManagerEmailChange = this.handleCorpManagerEmailChange.bind(
      this
    );
    this.handleCorpJobTitleChange = this.handleCorpJobTitleChange.bind(this);
    this.handleCorpDeparmentChange = this.handleCorpDeparmentChange.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.changeTravelerStatus = this.changeTravelerStatus.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleCancelAddingClick = this.handleCancelAddingClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleConfirmCloseClick = this.handleConfirmCloseClick.bind(this);
    this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleZipCodeChange = this.handleZipCodeChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleDateOfBirthChanged = this.handleDateOfBirthChanged.bind(this);
    this.isBirthDayValid = this.isBirthDayValid.bind(this);
  }
  componentDidMount() {
    this.getListTravelers(0, null, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isSubmitted: false,
      phoneValidation: true,
      phoneExistValidation: true,
      usernameExistValidation: true,
      shouldRenderTable:
        nextProps.contentHeight &&
        nextProps.contentWidth &&
        nextProps.contentHeight !== this.props.contentHeight &&
        nextProps.contentWidth !== this.props.contentWidth
    });
  }
  
  convertFromAirlineUserToCorporate = (airlineData = {}, isAirline = false) => {
    if(!isAirline) return airlineData
    let newListUser = _.get(airlineData, 'list', [])
    if(newListUser.length > 0) {
      newListUser = newListUser.map(user => {
        return {
          ...user,
          corporateInfo: {
            assistantEmail: user.assistantEmail || "",
            corpDivision: user.assistantEmail || "",
            corpId: user.corpId || "",
            corpPO: user.corpPO || "",
            costCentre: user.costCentre || "",
            department: user.department || "",
            managerEmail: user.managerEmail || "",
            managerName: user.managerName || "",
            title: user.jobTitle || "",
            status: user.isActive ? 2 : 0
          },
          profile: {
            address: user.address || {},
            "gender": user.gender || 0,
            "dob": user.dateOfBirth
          }    
        }
      })
    }
    return {
      ...airlineData,
      list: newListUser
    }
  }

  getListTravelers = (activePage, limit, countTraveler) => {
    if (this.props.corporateId) {
      let param = {
        limit: limit || this.state.footerData.limit,
        page:
          activePage || activePage === 0
            ? activePage
            : this.state.footerData.page,
        query: {
          fleetId: this.props.auth.selectedFleet.fleetId
        },
        corpId: this.props.corporateId
      };
      if (this.state.sortName) {
        param.sort = { [this.state.sortName]: this.state.sortType };
      } else {
        param.sort = { createdDate: this.state.sortType };
      }
      if (this.state.str) {
        param.query.str = this.state.str.trim();
      }
      this.setState({
        isLoading: true,
        // footerLoading: true
      });
      const isAirline = this.props.currentUser && this.props.currentUser.isAirline || false 
      const urlGetTravelerApi = this.getUrlApiBasedCorpType(this.props.getTraveler, this.props.getCrew)
      urlGetTravelerApi(param).then(data => {
        if (data.ok) {
          const { page, limit } = data.res;
          const travalerInfo = this.convertFromAirlineUserToCorporate(data.res, isAirline)

          this.setState({
            isLoading: false,
            traveler: travalerInfo,
            footerData: {
              ...this.state.footerData,
              page, 
              limit
            }
          });
        }
      });
      if (countTraveler) {
        this.props.countTraveler(param).then(data => {
          if (data.ok) {
            this.setState({
              footerLoading: false,
              footerData: {
                ...this.state.footerData,
                total: data.res
              }
            });
          }
        });
      }
    }
  };

  handleSortTravelerClick = sortName => {
    if (this.state.isLoading) return;
    this.setState(
      {
        sortName: sortName,
        sortType: this.state.sortType == 1 ? -1 : 1
      },
      function () {
        this.getListTravelers();
      }
    );
  };

  handleMenuClick(eventKey, detailItem) {
    if (eventKey == 0) {
      this.setState({
        isAdding: true,
        detailItem: detailItem
      });
    } else if (eventKey == 1) {
      let status = detailItem.corporateInfo.status === 2;
      if (detailItem.corporateInfo.status === 1) {
        status = false;
      }
      this.changeTravelerStatus(detailItem._id, status);
    } else if (eventKey == 2) {
      let status = detailItem.corporateInfo.status === 2;
      if (detailItem.corporateInfo.status === 1) {
        status = true;
      }
      this.changeTravelerStatus(detailItem._id, status);
    } else {
      deleteUserId = detailItem._id;
      this.setState({
        confirm: {
          id: CONFIRM_DELETE_LINK_ID,
          title: I18n.t("corporate.Delete"),
          body: I18n.t("corporate.CONFIRM_DELETE"),
          buttonTitle: "Yes",
          closeButtonText: "No"
        }
      });
    }
  }

  getUrlApiBasedCorpType = (urlStandard, urlAirline) => {
    const isAirline = this.props.currentUser && this.props.currentUser.isAirline || false
    if(isAirline) return urlAirline
    return urlStandard
  }

  handleConfirmButtonClick(id) {
    this.handleConfirmCloseClick();
      if (id === CONFIRM_DELETE_LINK_ID) {
      const urlDeleteTraveler = this.getUrlApiBasedCorpType(this.props.deleteTraveler, this.props.deleteCrew)
      urlDeleteTraveler({
          _id: deleteUserId,
          fleetId: this.props.auth.selectedFleet.fleetId,
          corpId: this.props.corporateId
        })
        .then(data => {
          if (data.ok) {
            this.getListTravelers(null, null, true);
          } else {
          }
        });
    } else {
      this.props
        .resetPasswordCrew({
          fleetId: this.props.auth.selectedFleet.fleetId,
          corpId: this.props.corporateId,
          _id: this.state.detailItem._id
        })
        .then(data => {
          if (data.ok) {
            this.context.notification(
              "success",
              I18n.t("airline.INFO_RESET_PASSWORD_SUCCESS").format(this.state.detailItem.userName)
            );
          } else {
            this.setState({ isSubmitted: false });
            this.context.notification(
              "error",
              I18n.t("errors." + data.error.errorCode)
            );
          }
        });
    }
    this.handleConfirmCloseClick();
  }

  handleConfirmCloseClick() {
    this.setState({ confirm: null });
  }

  changeTravelerStatus(id, isActive) {
    const urlActiveTraveler = this.getUrlApiBasedCorpType(this.props.activeTraveler, this.props.activeCrew)
    urlActiveTraveler(
        {
          _id: id,
          fleetId: this.props.auth.selectedFleet.fleetId,
          corpId: this.props.corporateId,
          status: !isActive
        },
        isActive
      )
      .then(data => {
        if (data.ok) {
          let listTravelers = _.cloneDeep(this.state.traveler.list);
          _.forEach(listTravelers, traveler => {
            if (id === traveler._id) {
              traveler.corporateInfo.status = isActive ? 0 : 2;
            }
            traveler.checked = false;
            return traveler;
          });
          let nModified = 0;
          if (data.res.updated) {
            nModified = 1;
          }
          this.setState({
            traveler: {
              ...this.state.traveler,
              list: listTravelers,
              active: isActive
                ? this.state.traveler.active - nModified
                : this.state.traveler.active + nModified
            }
          });
        } else {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        }
      });
  }

  handleAddClick() {
    this.setState({
      isAdding: true,
      detailItem: {
        corporateInfo: {},
        profile: {}
      }
    });
  }

  handleCancelClick() {
    this.props.router.push({
      pathname: "/corporate"
    });
  }

  handleCancelAddingClick() {
    this.setState({
      isAdding: false,
      isSubmitted: false
    });
  }

  handleFirstNameChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, firstName: e.target.value }
    });
  }
  handleUserNameChange = (e) => {
    this.setState({
      detailItem: { ...this.state.detailItem, userName: e.target.value }
    });
  }
  handleLastNameChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, lastName: e.target.value }
    });
  }
  handleEmailChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, email: e.target.value }
    });
  }
  handleAssistantEmailChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          assistantEmail: e.target.value
        }
      }
    });
  }
  handleGenderChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          gender: e
        }
      }
    });
  }

  isBirthDayValid(current) {
    return !current.isAfter(DateTime.moment());
  }

  handleDateOfBirthChanged(e) {
    if (e._d) {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          profile: {
            ...this.state.detailItem.profile,
            dob: moment(e._d).format("MM/DD/YYYY")
          }
        }
      });
    }
  }

  handleStreetAddressChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          address: {
            ...this.state.detailItem.profile.address,
            street: e.target.value
          }
        }
      }
    });
  }

  handleCityChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          address: {
            ...this.state.detailItem.profile.address,
            city: e.target.value
          }
        }
      }
    });
  }

  handleStateChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          address: {
            ...this.state.detailItem.profile.address,
            state: e.target.value
          }
        }
      }
    });
  }

  handleZipCodeChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          address: {
            ...this.state.detailItem.profile.address,
            zipcode: e.target.value
          }
        }
      }
    });
  }

  handleCountryChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        profile: {
          ...this.state.detailItem.profile,
          address: {
            ...this.state.detailItem.profile.address,
            country: e
          }
        }
      }
    });
  }

  handleAddressChange(e) {
    this.setState({
      detailItem: { ...this.state.detailItem, address: e.target.value }
    });
  }
  handlePhoneChange(status, value, countryData, number, id, isBlur) {
    if (isBlur) {
      let urlCheckPhone = this.getUrlApiBasedCorpType(this.props.checkExistCorporateTravellerPhone, this.props.checkExistCrewPhone)
      if (!status) return false
      let newPhone, params;
      if (countryData && "+" + countryData.dialCode === number) {
        newPhone = "";
      } else {
        newPhone = trimPhoneNumber(number);
      }
      if (this.state.detailItem && this.state.detailItem._id) {
        params = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          phone: newPhone,
          id: this.state.detailItem._id
        };
      } else {
        params = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          phone: newPhone
        };
      }
      if (newPhone !== phone) {
        urlCheckPhone(params).then(data => {
          if (data.ok) {
            this.setState({ phoneExistValidation: !data.res.existed });
          }
        });
      }
    } else {
      this.setState({
        detailItem: {
          ...this.state.detailItem,
          phone: number
        },
        phoneExistValidation: true,
        phoneValidation: status
      });
    }
  }

  handleCorpIdChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          corpId: e.target.value
        }
      }
    });
  }

  handleCorpDivisionChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          corpDivision: e.target.value
        }
      }
    });
  }

  handleCostCentreChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          costCentre: e.target.value
        }
      }
    });
  }

  handleCorpPOChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          corpPO: e.target.value
        }
      }
    });
  }

  handleCorpManagerNameChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          managerName: e.target.value
        }
      }
    });
  }

  handleCorpManagerEmailChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          managerEmail: e.target.value
        }
      }
    });
  }

  handleCorpJobTitleChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          title: e.target.value
        }
      }
    });
  }

  handleCorpDeparmentChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          department: e.target.value
        }
      }
    });
  }

  handleActiveChange(e) {
    this.setState({
      detailItem: {
        ...this.state.detailItem,
        corporateInfo: {
          ...this.state.detailItem.corporateInfo,
          status: e.target.checked ? 2 : 0
        }
      }
    });
  }

  handlePaginationSelect = page => this.getListTravelers(parseInt(page));

  handleNumItemsPerPageChange = limit => {
    this.getListTravelers(0, limit);
  };

  handleSearchChange(str) {
    this.setState({
      str: str
    });
  }

  handleSearchKeyPress(e) {
    if (e.key === "Enter") {
      if (this.state.isLoading) return;
      this.getListTravelers(0, null, true);
    }
  }

  handleSaveTravelerClick = () => {
    this.setState({ isSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    this.props.loadingBarActions.showLoadingSpiner();
    const detailItem = this.state.detailItem
    const isAirline = this.props.currentUser && this.props.currentUser.isAirline || false
    let data = {
      _id: detailItem._id,
      fleetId: this.props.auth.selectedFleet.fleetId,
      // corporateId: this.props.corporateId,
      firstName: detailItem.firstName,
      lastName: detailItem.lastName,
      phoneNumber: trimPhoneNumber(detailItem.phone),
      email: detailItem.email,
      assistantEmail: detailItem.corporateInfo.assistantEmail,
      //
      companyAddress: detailItem.corporateInfo.companyAddress,
      //
      gender:
        detailItem.profile &&
        parseInt(detailItem.profile.gender),
      street:
        detailItem.profile &&
        detailItem.profile.address &&
        detailItem.profile.address.street,
      city:
        detailItem.profile &&
        detailItem.profile.address &&
        detailItem.profile.address.city,
      state:
        detailItem.profile &&
        detailItem.profile.address &&
        detailItem.profile.address.state,
      zipcode:
        detailItem.profile &&
        detailItem.profile.address &&
        detailItem.profile.address.zipcode,
      country:
        detailItem.profile &&
        detailItem.profile.address &&
        detailItem.profile.address.country,
      managerName: detailItem.corporateInfo.managerName,
      managerEmail: detailItem.corporateInfo.managerEmail,
      department: detailItem.corporateInfo.department,
      corpDivision: detailItem.corporateInfo.corpDivision,
      costCentre: detailItem.corporateInfo.costCentre,
      corpPO: detailItem.corporateInfo.corpPO,
      registerFrom: "CC"
    }
    let dispatchFunc = this.getUrlApiBasedCorpType(this.props.operateTraveler, this.props.operateCrew)
    if(isAirline) {
      data = {
        ...data,
        isAirline: true,
        airlineId: this.props.corporateId,
        corpId: detailItem.corporateInfo.corpId,
        phone: trimPhoneNumber(detailItem.phone),
        username: detailItem.userName,
        isActive: detailItem.corporateInfo.status === 2,
        jobTitle: detailItem.corporateInfo.title,
        dateOfBirth:
          detailItem.profile &&
          detailItem.profile.dob &&
          moment(detailItem.profile.dob).format("MM/DD/YYYY")
      }
      
    } else {
      data = {
        ...data,
        isAirline: false,
        corpId: detailItem.corporateInfo.corpId,
        phoneNumber: trimPhoneNumber(detailItem.phone),
        corporateId: this.props.corporateId,
        title: detailItem.corporateInfo.title,
        active: detailItem.corporateInfo.status === 2,
        dob:
          detailItem.profile &&
          detailItem.profile.dob &&
          moment(detailItem.profile.dob).format("MM/DD/YYYY"),
      }
    }
    dispatchFunc(data)
      .then(data => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (data.ok) {
          let newList;
          if (!this.state.detailItem._id) {
            newList = this.state.traveler.list;
            newList.unshift(data.res);
          } else {
            newList = this.state.traveler.list.map(tv => {
              if (tv._id == data.res._id) {
                return data.res;
              }
              return tv;
            });
          }
          this.setState({
            isSubmitted: false,
            isAdding: false,
            detailItem: {},
            // traveler: { ...this.state.traveler, list: newList }
          }, () => this.getListTravelers(null, null, true));
        } else {
          this.setState({ isSubmitted: false });
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        }
      });
    }


  ValidatorCallback(id, valid, messages) {
    if (this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleResetPasswordClick = () => {
    this.setState({
        confirm: {
            title: I18n.t("airline.Reset_password"),
            body: I18n.t("airline.COMFIRM_RESET_PASSWORD"),
            buttonTitle: "Yes",
            closeButtonText: "No"
        }
    });
}

  getTableColumns = () => {
    let tableColumns = Object.assign([], CorpTravelerColumns);
    const { permissions = null } = this.props || {};
    const { actions = false } = permissions || {};
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case "createdDate":
          col.customCell = data => (
            <p>
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MM/DD/YYYY"
                date={data.corporateInfo && data.corporateInfo.travelerCreatedAt || data.createdDate}
              />
            </p>
          );
          break;
        case "status":
          col.customCell = data => {
            if (!data.corporateInfo) {
              data.corporateInfo = {};
            }
            let classStr = "Inactive";
            let statusStr = <Translate value="corporate.Inactive" />;
            if (data.corporateInfo.status === 1) {
              classStr = "Inreview";
              statusStr = <Translate value="corporate.In_Review" />;
            } else if (data.corporateInfo.status === 2) {
              classStr = "Active";
              statusStr = <Translate value="corporate.Active" />;
            }
            return (
              <div className={classStr}>
                {!this.props.permissions || this.props.permissions.actions ? (
                  <a
                    onClick={() => {
                      this.changeTravelerStatus(
                        data._id,
                        data.corporateInfo.status === 2
                      );
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                    <span>{statusStr}</span>
                  )}
              </div>
            );
          };
          break;
        case "actions":
          col.customCell = (data, rowIndex) => {
            if (!data.corporateInfo) {
              data.corporateInfo = {};
            }
            let currentStatus = "Inactive";
            if (data.corporateInfo.status === 1) {
              currentStatus = "Inreview";
            } else if (data.corporateInfo.status === 2) {
              currentStatus = "Active";
            }
            return (permissions && actions) ? (
              <TableActions
                rowIndex={rowIndex}
                rowData={data}
                onSelect={this.handleMenuClick}
                totalRow={this.state.traveler.list.length}
                rowHeight={this.state.rowHeight}
                menuItems={CorpTravelerActions[currentStatus]}
                tableHeight={this.state.tableHeight}
                tableId="corp-traveler-table"
              />
            ) : (
                <a
                  onClick={() => {
                    this.handleMenuClick(0, data);
                  }}
                  href="javascript:void(0)"
                >
                  <Translate value="corporate.View" />
                </a>
              );
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let contentHeight =
      this.props.getContentHeight() || this.props.contentHeight;
    let toolbarHeight = this.toolbarContainer
      ? this.toolbarContainer.clientHeight || 64
      : 64;
    let toobarMarginBottom = 10;
    let tableHeight = contentHeight
      ? contentHeight - (toobarMarginBottom + toolbarHeight)
      : 0;
    if (window.innerHeight < 850 && tableHeight < 500) tableHeight = 550;
    this.state.tableHeight = tableHeight;
    return tableHeight;
  };

  render() {
    const bodyData =
      this.props.contentHeight < 1 || this.props.contentWidth < 1
        ? []
        : this.state.traveler &&
          this.state.traveler.list &&
          this.state.traveler.list.length
          ? this.state.traveler.list
          : [];
    const { permissions = null, currentUser = {} } = this.props || {};
    const { actions = false } = permissions || {};
    const isAirlineCorporate = currentUser.isAirline
    return (
      <div className="corptraveler-wrapper">
        {this.state.isAdding ? (
          <div className="w-100 ">
            <div className="content form-detail-container">
              <Row>
                {
                  isAirlineCorporate &&
                  <Col xs={12} sm={6} md={4}>
                    <FormGroup
                      className={
                        !this.state.isSubmitted
                          ? null
                          : this.state.valid.userName === false
                            ? "error"
                            : null
                      }
                    >
                      <Form.Label>
                        <Translate value="corporate.User_Name" />
                        <span className="require">*</span>
                      </Form.Label>
                      <FormControl
                        className="form-custom"
                        type="text"
                        onChange={this.handleUserNameChange}
                        value={this.state.detailItem.userName}
                        autoFocus
                        disabled={!actions || !!this.state.detailItem._id}
                      />
                      <Validator id="userName" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.detailItem.userName
                            )
                          }
                          message={"Please input user name."}
                        />
                      </Validator>
                    </FormGroup>
                  </Col>
                }
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.firstName === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.First_name" />
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleFirstNameChange}
                      value={this.state.detailItem.firstName}
                      autoFocus
                      disabled={!actions}
                    />
                    <Validator id="firstName" callback={this.ValidatorCallback}>
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          !Validation.isStringEmpty(
                            this.state.detailItem.firstName
                          )
                        }
                        message={I18n.t("corporate.ERROR_INPUT_FIRSTNAME")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Last_name" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleLastNameChange}
                      value={this.state.detailItem.lastName}
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.phone === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Phone_Number" />
                      {!isAirlineCorporate && <span className="require">*</span>}
                    </Form.Label>
                    <IntlTelInputApp
                      css={["intl-tel-input", "form-control"]}
                      utilsScript={"libphonenumber.js"}
                      value={this.state.detailItem.phone}
                      onPhoneNumberBlur={(
                        status,
                        value,
                        countryData,
                        number,
                        id
                      ) => {
                        this.handlePhoneChange(
                          status,
                          value,
                          countryData,
                          number,
                          id,
                          true
                        );
                      }}
                      onPhoneNumberChange={(
                        status,
                        value,
                        countryData,
                        number,
                        id
                      ) => {
                        this.handlePhoneChange(
                          status,
                          value,
                          countryData,
                          number,
                          id,
                          false
                        );
                      }}
                      disabled={!actions}
                    />
                    {
                      !isAirlineCorporate &&
                      <Validator id="phone" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={
                            !Validation.isStringEmpty(this.state.detailItem.phone)
                          }
                          message={I18n.t("corporate.ERROR_INPUT_PHONE")}
                        />
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={this.state.phoneExistValidation}
                          message={I18n.t("corporate.ERROR_EXISTED_PHONE")}
                        />
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={this.state.phoneValidation}
                          message={I18n.t("corporate.ERROR_INPUT_VALIDPHONE")}
                        />
                      </Validator>
                    }
                    {
                      isAirlineCorporate && 
                      <Validator id="phone" callback={this.ValidatorCallback}>
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={this.state.phoneExistValidation}
                          message={I18n.t("corporate.ERROR_EXISTED_PHONE")}
                        />
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={this.state.phoneValidation}
                          message={I18n.t("corporate.ERROR_INPUT_VALIDPHONE")}
                        />
                      </Validator>
                    }
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.email === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Email_address" />
                      {isAirlineCorporate && <span className="require">*</span>}
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleEmailChange}
                      value={this.state.detailItem.email}
                      placeholder={"email@example.com"}
                      disabled={!actions}
                    />
                    <Validator id="email" callback={this.ValidatorCallback}>
                      {
                        isAirlineCorporate &&
                        <ValidCase
                          hide={!this.state.isSubmitted}
                          valid={
                            !Validation.isStringEmpty(
                              this.state.detailItem.email
                            )
                          }
                          message={"Please input Email."}
                        />
                      }
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          Validation.isStringEmpty(
                            this.state.detailItem.email
                          ) ||
                          Validation.validateEmail(this.state.detailItem.email)
                        }
                        message={I18n.t("corporate.ERROR_INPUT_VALID_EMAIL")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup
                    className={
                      !this.state.isSubmitted
                        ? null
                        : this.state.valid.assistantEmail === false
                          ? "error"
                          : null
                    }
                  >
                    <Form.Label>
                      <Translate value="corporate.Assistant_email" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      onChange={this.handleAssistantEmailChange}
                      value={this.state.detailItem.corporateInfo.assistantEmail}
                      disabled={!actions}
                    />
                    <Validator
                      id="assistantEmail"
                      callback={this.ValidatorCallback}
                    >
                      <ValidCase
                        hide={!this.state.isSubmitted}
                        valid={
                          Validation.isStringEmpty(
                            this.state.detailItem.corporateInfo.assistantEmail
                          ) ||
                          Validation.validateEmail(
                            this.state.detailItem.corporateInfo.assistantEmail
                          )
                        }
                        message={I18n.t("corporate.ERROR_INPUT_VALID_EMAIL")}
                      />
                    </Validator>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Gender" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      as="select"
                      value={
                        this.state.detailItem.profile &&
                          this.state.detailItem.profile.gender >= 0
                          ? this.state.detailItem.profile.gender
                          : -1
                      }
                      onChange={e => {
                        this.handleGenderChange(e.target.value);
                      }}
                      disabled={!actions}
                    >
                      <option key={-1} value={-1}>
                        {I18n.t("corporate.Select_Gender")}
                      </option>
                      <option key={0} value={0}>
                        {I18n.t("corporate.Male")}
                      </option>
                      <option key={1} value={1}>
                        {I18n.t("corporate.Female")}
                      </option>
                    </FormControl>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Date_of_birth" />
                    </Form.Label>
                    <FormGroup>
                      <DateTime
                        timeFormat={false}
                        inputProps={{
                          className: "form-control",
                          disabled: !actions
                        }}
                        isValidDate={this.isBirthDayValid}
                        value={
                          this.state.detailItem.profile &&
                          this.state.detailItem.profile.dob &&
                          moment(this.state.detailItem.profile.dob)
                        }
                        onChange={this.handleDateOfBirthChanged}
                        closeOnSelect
                      />
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Address" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={
                        this.state.detailItem.profile &&
                        this.state.detailItem.profile.address &&
                        this.state.detailItem.profile.address.street
                      }
                      onChange={this.handleStreetAddressChange}
                      placeholder={I18n.t("corporate.Street_address")}
                      maxLength={200}
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.City" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={
                        this.state.detailItem.profile &&
                        this.state.detailItem.profile.address &&
                        this.state.detailItem.profile.address.city
                      }
                      onChange={this.handleCityChange}
                      placeholder={I18n.t("corporate.City")}
                      maxLength={50}
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.State" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={
                        this.state.detailItem.profile &&
                        this.state.detailItem.profile.address &&
                        this.state.detailItem.profile.address.state
                      }
                      onChange={this.handleStateChange}
                      placeholder={I18n.t("corporate.State")}
                      maxLength={50}
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Zip_code" />
                    </Form.Label>
                    <FormControl
                      className="form-custom"
                      type="text"
                      value={
                        this.state.detailItem.profile &&
                        this.state.detailItem.profile.address &&
                        this.state.detailItem.profile.address.zipcode
                      }
                      onChange={this.handleZipCodeChange}
                      placeholder={I18n.t("corporate.Zip_code")}
                      maxLength={20}
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <FormGroup>
                    <Form.Label>
                      <Translate value="corporate.Country" />
                    </Form.Label>
                    <CountryDropdown
                      value={
                        this.state.detailItem.profile &&
                        this.state.detailItem.profile.address &&
                        this.state.detailItem.profile.address.country
                      }
                      onChange={this.handleCountryChange}
                      defaultOptionLabel={I18n.t("corporate.selectCountry")}
                      classes="form-control form-custom"
                      disabled={!actions}
                    />
                  </FormGroup>
                </Col>
                {this.props.advanceInfoCorp ? (
                  <React.Fragment>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Job_title" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpJobTitleChange}
                          value={this.state.detailItem.corporateInfo.title}
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Department" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpDeparmentChange}
                          value={this.state.detailItem.corporateInfo.department}
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Corp_division" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpDivisionChange}
                          value={
                            this.state.detailItem.corporateInfo.corpDivision
                          }
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Manager_name" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpManagerNameChange}
                          value={
                            this.state.detailItem.corporateInfo.managerName
                          }
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup
                        className={
                          !this.state.isSubmitted
                            ? null
                            : this.state.valid.managerEmail === false
                              ? "error"
                              : null
                        }
                      >
                        <Form.Label>
                          <Translate value="corporate.Manager_email" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpManagerEmailChange}
                          value={
                            this.state.detailItem.corporateInfo.managerEmail
                          }
                          placeholder="email01@example.com;email02@example.com"
                          disabled={!actions}
                        />
                        <Validator
                          id="managerEmail"
                          callback={this.ValidatorCallback}
                        >
                          <ValidCase
                            hide={!this.state.isSubmitted}
                            valid={
                              Validation.isStringEmpty(
                                this.state.detailItem.corporateInfo.managerEmail
                              ) ||
                              Validation.validateMultiEmail(
                                this.state.detailItem.corporateInfo.managerEmail
                              )
                            }
                            message={I18n.t(
                              "corporate.ERROR_INPUT_VALID_EMAIL"
                            )}
                          />
                        </Validator>
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Cost_centre" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCostCentreChange}
                          value={this.state.detailItem.corporateInfo.costCentre}
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Corp_id" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpIdChange}
                          value={this.state.detailItem.corporateInfo.corpId}
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <FormGroup>
                        <Form.Label>
                          <Translate value="corporate.Corp_PO" />
                        </Form.Label>
                        <FormControl
                          className="form-custom"
                          type="text"
                          onChange={this.handleCorpPOChange}
                          value={this.state.detailItem.corporateInfo.corpPO}
                          disabled={!actions}
                        />
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                ) : null}
                <Col xs={12}>
                  <CcCheckbox
                    className="corp-traveler-checkbox mr-t-10"
                    checked={this.state.detailItem.corporateInfo.status === 2}
                    onChange={this.handleActiveChange}
                    text={I18n.t("corporate.Active")}
                    disabled={!actions}
                  />
                  {
                    isAirlineCorporate &&
                    <Form.Label>
                      <Translate value="airline.Required_fields_crews" />
                    </Form.Label>
                  }
                  <Form.Label>
                    (<span className="require">*</span>
                    ): <Translate value="corporate.Required_fields" />
                  </Form.Label>
                  <ButtonToolbar className="text-center display-flex justify-content-start align-items-center pseudo-none pv-md">
                    {permissions && actions ? (
                      <Button
                        variant="success"
                        onClick={this.handleSaveTravelerClick}
                        className="mr-md btn-save"
                      >
                        <Translate value="corporate.Save" />
                      </Button>
                    ) : null}
                    <Button
                      onClick={this.handleCancelAddingClick}
                      className="btn-cancel mr-md"
                    >
                      <Translate value="corporate.Cancel" />
                    </Button>
                    {
                      this.state.detailItem._id && isAirlineCorporate
                      ? <Button
                          className="btn-reset"
                          onClick={this.handleResetPasswordClick}
                        >
                          <Translate value="airline.Reset_password" />
                        </Button>
                      : null
                    }
                  </ButtonToolbar>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
            <div>
              <ButtonToolbar
                className="text-center header-button-group"
                ref={node => (this.toolbarContainer = node)}
              >
                <ButtonGroup className="group-left">
                  <FormGroup className="search-format">
                    <FormControl
                      className="search-form"
                      type="text"
                      placeholder={I18n.t("General.search")}
                      onChange={e => this.handleSearchChange(e.target.value)}
                      onKeyPress={this.handleSearchKeyPress}
                      value={this.state.str}
                    />
                    <BsSearch className="search-icon" />
                  </FormGroup>
                  {
                    (!permissions || actions) && (
                      <Button
                        className="btn-header text-add-header"
                        onClick={this.handleAddClick}
                      >
                        {" "}

                        <Translate value="corporate.Add" />
                      </Button>
                    )
                  }
                  <Button
                    className="btn-header text-cancel-header"
                    onClick={this.handleCancelClick}
                  >
                    {" "}
                    <Translate value="corporate.Cancel" />
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <div className="gridViewTable" id="corp-traveler-table">
                <StickyTable
                  columns={this.getTableColumns()}
                  bodyData={bodyData}
                  footerData={this.state.footerData}
                  handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                  handlePaginationSelect={this.handlePaginationSelect}
                  rowHeight={this.state.rowHeight}
                  sortHandle={this.handleSortTravelerClick}
                  notCheckShouldComponentUpdate
                  sortType={this.state.sortType}
                  sortName={this.state.sortName}
                  getTableHeight={this.getTableHeight}
                  getParentWidthManual={() => this.props.contentWidth}
                  shouldRender={this.state.shouldRenderTable}
                  footerLoading={this.state.footerLoading}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          )}
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
      </div>
    );
  }
}

CropTraveler.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
    advanceInfoCorp: state.auth.selectedFleet.generalSetting.advanceInfoCorp
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTraveler: options => {
      return dispatch(getTraveler(options));
    },
    countTraveler: options => {
      return dispatch(countTraveler(options));
    },
    activeTraveler: options => {
      return dispatch(activeTraveler(options));
    },
    operateTraveler: options => {
      return dispatch(operateTraveler(options));
    },
    deleteTraveler: options => {
      return dispatch(deleteTraveler(options));
    },
    checkExistCorporateTravellerPhone: options => {
      return dispatch(checkExistCorporateTravellerPhone(options));
    },
    
    // Airline dispatch
    getTravelerAirline: options => {
      return dispatch(getTravelerAirline(options));
    },
    activeTravelerAirline: options => {
      return dispatch(activeTravelerAirline(options));
    },
    activeCrew: options => {
      return dispatch(activeCrew(options));
    },
    deleteCrew: options => {
      return dispatch(deleteCrew(options));
    },
    resetPasswordCrew: options => {
      return dispatch(resetPasswordCrew(options));
    },
    operateTravelerAirline: options => {
      return dispatch(operateTravelerAirline(options));
    },
    operateCrew: options => {
      return dispatch(operateCrew(options));
    },
    getCrew: options => {
      return dispatch(getCrew(options));
    },
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
    checkExistCrewPhone: options => {
      return dispatch(checkExistCrewPhone(options));
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CropTraveler);
