import React from 'react';
import { Translate, I18n } from "react-redux-i18n";
import {
    FormGroup,
    FormControl,
    Form,
    InputGroup
} from "react-bootstrap";

export default function VersionApp(props) {
    const {
        versionApp,
        comparisonVersionApp,
        ComparisonVersionAppOption,
        handleChangeVersionApp,
        handleChangeComparisonVersionApp,
        editable = true
    } = props;

    return (
        <FormGroup>
            <Form.Label>
                <Translate value="message.versionApp" />
                {/* <span className="require">*</span> */}
            </Form.Label>
            <FormGroup className="qup-input-group">
                <InputGroup className="single-addon-left">
                    <FormControl
                        type="text"
                        className="form-custom"
                        value={versionApp}
                        placeholder={I18n.t('message.versionApp')}
                        onChange={handleChangeVersionApp}
                        disabled={!editable}
                    />
                    <FormControl
                        as="select"
                        className="input-group-addon-custom form-custom"
                        value={comparisonVersionApp}
                        onChange={handleChangeComparisonVersionApp}
                        disabled={!editable}
                    >
                        {ComparisonVersionAppOption.map(item => {
                            return (<option key={item} value={item}>{I18n.t(`message.comparisonVersionApp.` + item)}</option>)
                        })}
                    </FormControl>
                </InputGroup>
            </FormGroup>
        </FormGroup>
    )
}
