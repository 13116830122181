import { Localize, Translate } from 'react-redux-i18n';
import React from 'react';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReportUtils from '../base/utils';

export const fields = [
  {
    key: 'date',
    label: 'report.result.financialDriver.date',
    summary: 'date',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingId',
    label: 'report.result.financialDriver.bookingId',
    summary: 'bookingId',
  },
  {
    key: 'bookingStatus',
    label: 'report.result.bookingDetails.bookingStatus',
    mutate: ReportUtils.renderBookingStatus,
  },
  {
    key: 'paidBy',
    label: 'report.result.financialDriver.paidBy',
    summary: 'paidBy',
    mutate: (value) => {
      if (value === 'mDispatcher') return 'partner';
      if (value === 'BankOfGeorgia') return 'Bank Of Georgia';
      return value;
    },
  },
  {
    key: 'bookFrom',
    label: 'report.result.financialDriver.bookFrom',
    summary: 'bookFrom',
    mutate: (value) => {
      switch (value) {
        case 'streetSharing':
          value = 'Street Sharing';
          break;
        default:
          break;
      }
      return value;
    },
  },
  {
    key: 'bookingType',
    label: 'report.result.financialDriver.bookingType',
    summary: 'bookingType',
  },
  {
    key: 'companyName',
    label: 'report.result.financialDriver.companyName',
    summary: 'companyName',
  },
  {
    key: 'driverName',
    label: 'report.result.financialDriver.driverName',
    summary: 'driverName',
  },
  {
    key: 'idPermitType',
    label: 'report.result.idPermitType',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const driverFields = _.get(selectedFleet, 'driverFields', []);
      return !_.find(driverFields, (df) => df.key === 'idType');
    },
  },
  {
    key: 'driverIdPermit',
    label: 'report.result.financialDriver.driverIdPermit',
    summary: 'driverIdPermit',
  },
  {
    key: 'totalFare',
    label: 'report.result.financialDriver.totalFare',
    summary: 'totalFare',
    width: 220,
  },
  {
    key: 'subtotal',
    label: 'report.result.financialDriver.subtotal',
    summary: 'subtotal',
  },
  {
    key: 'airportFee',
    label: 'report.result.financialDriver.airportFee',
    summary: 'airportFee',
    mutate: (value, doc, options) => {
      if (value) {
        return (
          <div style={{ display: 'flex' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="search-tool-tip">
                  {doc.airportPayTo == PayTo.Driver ? (
                    <Translate value="report.result.financialDriver.payToDriver" />
                  ) : (
                    <Translate value="report.result.financialDriver.payToFleet" />
                  )}
                </Tooltip>
              }
            >
              <span>{value && value.toFixed(2)}</span>
            </OverlayTrigger>
          </div>
        );
      }
      return value;
    },
    textEllipsis: false,
  },
  {
    key: 'meetDriver',
    label: 'report.result.financialDriver.meetDriver',
    summary: 'meetDriver',
    mutate: (value, doc, options) => {
      if (value) {
        return (
          <div style={{ display: 'flex' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="search-tool-tip">
                  {doc.meetDriverPayTo == PayTo.Driver ? (
                    <Translate value="report.result.financialDriver.payToDriver" />
                  ) : (
                    <Translate value="report.result.financialDriver.payToFleet" />
                  )}
                </Tooltip>
              }
            >
              <span>{value && value.toFixed(2)}</span>
            </OverlayTrigger>
          </div>
        );
      }
      return value;
    },
    textEllipsis: false,
  },
  {
    key: 'tollFee',
    label: 'report.result.financialDriver.tollFee',
    summary: 'tollFee',
    isShow: 'tollFeeActive',
    mutate: (value, doc) => {
      if (value) {
        return (
          <div style={{ display: 'flex' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="search-tool-tip">
                  {doc.tollFeePayTo == PayTo.Driver ? (
                    <Translate value="report.result.financialDriver.payToDriver" />
                  ) : (
                    <Translate value="report.result.financialDriver.payToFleet" />
                  )}
                </Tooltip>
              }
            >
              <span>{value && value.toFixed(2)}</span>
            </OverlayTrigger>
          </div>
        );
      }
      return value;
    },
    textEllipsis: false,
  },
  {
    key: 'parkingFee',
    label: 'report.result.financialDriver.parkingFee',
    summary: 'parkingFee',
    isShow: 'parkingFeeActive',
    mutate: (value, doc) => {
      if (value) {
        return (
          <div style={{ display: 'flex' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="search-tool-tip">
                  {doc.parkingFeePayTo == PayTo.Driver ? (
                    <Translate value="report.result.financialDriver.payToDriver" />
                  ) : (
                    <Translate value="report.result.financialDriver.payToFleet" />
                  )}
                </Tooltip>
              }
            >
              <span>{value && value.toFixed(2)}</span>
            </OverlayTrigger>
          </div>
        );
      }
      return value;
    },
    textEllipsis: false,
  },
  {
    key: 'gasFee',
    label: 'report.result.financialDriver.gasFee',
    summary: 'gasFee',
    isShow: 'gasFeeActive',
    mutate: (value, doc) => {
      if (value) {
        return (
          <div style={{ display: 'flex' }}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="search-tool-tip">
                  {doc.gasFeePayTo == PayTo.Driver ? (
                    <Translate value="report.result.financialDriver.payToDriver" />
                  ) : (
                    <Translate value="report.result.financialDriver.payToFleet" />
                  )}
                </Tooltip>
              }
            >
              <span>{value && value.toFixed(2)}</span>
            </OverlayTrigger>
          </div>
        );
      }
      return value;
    },
    textEllipsis: false,
  },
  {
    key: 'transactionFee',
    label: 'report.result.financialDriver.transactionFee',
    summary: 'transactionFee',
  },
  {
    key: 'fleetServiceFee',
    label: 'report.result.financialDriver.fleetServiceFee',
    summary: 'fleetServiceFee',
    excludeParams: ['txtSearch', 'pickUpZone'],
    link: {
      isNotShow: (doc) => !doc.fleetServiceFee,
      notMergeParams: true,
      name: 'showFleetServices',
      description: 'Link to show fleet services',
    },
  },
  {
    key: 'techFee',
    label: 'report.result.financialDriver.techFee',
    summary: 'techFee',
  },
  {
    key: 'salesTax',
    label: 'report.result.financialDriver.salesTax',
    isShow: 'taxActive',
    summary: 'salesTax',
  },
  {
    key: 'tipAmt',
    label: 'report.result.financialDriver.tipAmt',
    isShow: 'tipActive',
    summary: 'tipAmt',
  },
  {
    key: 'bookingFee',
    label: 'report.result.financialDriver.bookingFee',
    summary: 'bookingFee',
  },
  {
    key: 'stripeFee',
    label: 'report.result.financialDriver.stripeFee',
    summary: 'stripeFee',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      return selectedFleet?.creditConfig?.configGateway?.gateway !== 'Stripe'
    },
  },
  {
    key: 'commission',
    label: 'report.result.financialDriver.commission',
    summary: 'commission',
  },
  {
    key: 'supplierAmount',
    label: 'report.result.financialDriver.supplierAmount',
    summary: 'supplierAmount',
  },
  {
    key: 'deduction',
    label: 'report.result.financialDriver.deduction',
    summary: 'deduction',
  },
  {
    key: 'promoAmt',
    label: 'report.result.financialDriver.promoAmt',
    summary: 'promoAmt',
  },
  {
    key: 'ridePayment',
    label: 'report.result.financialDriver.ridePayment',
    summary: 'ridePayment',
  },
  {
    key: 'editedDriverEarning',
    label: 'report.result.financialDriver.editedDriverEarning',
    summary: 'editedDriverEarning',
    mutate: (value, doc) => (!doc.driverEarningType || doc.driverEarningType === 'default') ? ' ' : value,
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      const enableEditDriverEarning = _.get(
        selectedFleet,
        'generalSetting.editDriverEarning',
        false
      );
      return !enableEditDriverEarning
    },
  },
  {
    key: 'driverTax',
    label: 'report.result.financialDriver.driverTax',
    summary: 'driverTax',
    checkShowFromProps: 'selectedFleet',
    isNotShow: (selectedFleet) => {
      return (
        _.get(selectedFleet, 'drvPayout.gateway') !== 'StripeConnect' &&
        _.get(selectedFleet, 'fleetDeduction.separateTax') !== 'fleetTax'
      );
    },
  },
  {
    key: 'grossEarning',
    label: 'report.result.financialDriver.grossEarning',
    summary: 'grossEarning',
  },
  {
    key: 'driverDeduction',
    label: 'report.result.financialDriver.driverDeduction',
    summary: 'driverDeduction',
    isShow: 'driverDeposit',
  },
  {
    key: 'netEarning',
    label: 'report.result.financialDriver.netEarning',
    summary: 'netEarning',
    negativable: true,
  },
  {
    key: 'tipAfterCompleted',
    label: 'report.result.financialDriver.driverTipAfterCompleted',
    summary: 'tipAfterCompleted',
    negativable: true,
  },
  {
    key: 'payoutType',
    label: 'report.result.financialDriver.payoutType',
    mutate: (value) => {
      if (value) {
        const languageKey = `report.query.affiliationPayoutTypeItem.${value}`;
        return <Translate value={languageKey} />;
      } else {
        return '';
      }
    },
  },
  {
    key: 'shortTrip',
    label: 'report.result.financial.note',
    mutate: (value) =>
      value ? <Translate value="report.result.financial.shortTrip" /> : null,
  },
  {
    key: 'instructions',
    label: 'report.result.bookingDetails.instructions',
  },
];

export const fareFields = [
  'totalFare',
  'subtotal',
  'airportFee',
  'meetDriver',
  'tollFee',
  'parkingFee',
  'gasFee',
  'transactionFee',
  'techFee',
  'salesTax',
  'tipAmt',
  'bookingFee',
  'commission',
  'supplierAmount',
  'deduction',
  'promoAmt',
  'ridePayment',
  'driverTax',
  'grossEarning',
  'driverDeduction',
  'netEarning',
  'tipAfterCompleted',
  'fleetServiceFee',
  'driverTax',
  'editedDriverEarning',
  'stripeFee'
];

export const fleetServiceFields = [
  {
    key: 'serviceName',
    label: 'report.result.bookingDetails.additionalServiceName',
  },
  {
    key: 'fee',
    label: 'report.result.bookingDetails.additionalServiceValue',
    mutate: (value, doc) => (
      <Localize
        value={value}
        options={{
          style: 'currency',
          currency: doc.currencyISO,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }}
      />
    ),
  },
];

const PayTo = {
  Driver: 0,
  Fleet: 1,
};
