import React from 'react'
import { connect } from 'react-redux';
import BaseReport from './../base'
import * as fieldMappings from './fields'
import { reportRevenueFleetApi } from '../../../constants/ApiConfigs'
import _ from 'lodash';
let bookingService = [
  { value: "localBooking", label: "report.query.bookingServiceItem.localBooking" },
  { value: "provideService", label: "report.query.bookingServiceItem.provideService" },
]

class ReportRevenueFleet extends React.Component {
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules,{"name":'Report'});
    let checkExport = _.findIndex(report.actions,{"name":"Revenue Summary (Fleet)","Export":true});
    let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
    checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
    if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
      checkExport = false;
    }
    return (
      <div className="content">
        <BaseReport
          tittle="Fleet"
          fieldMappings={fieldMappings}
          apiurl={reportRevenueFleetApi}
          dateRange
          bookingService={bookingService}
          currency
          company
          noexport={!checkExport}
        />
      </div>
    )
  }
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(ReportRevenueFleet);
