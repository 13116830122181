import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translate, I18n } from "react-redux-i18n";
import "../../settings.scss";
import {
    Col,
    Row,
    FormGroup,
    FormControl,
    Form,
    InputGroup,
    Button,
    ButtonToolbar,
    Modal,
    Tab,
    Tabs,
    OverlayTrigger,
    Tooltip,
    ButtonGroup
} from "react-bootstrap";
import FormGroupTitle from "../../../../components/formGroupTitile/FormGroupTitle";
import * as deliveryRateActions from "../../../../actions/deliveryRateActions";
import * as loadingBarActions from "../../../../actions/loadingBarActions";
import { bindActionCreators } from "redux";
import { Validator, ValidCase, WarningFeeInput } from "../../../../components/validator";
import {
    CCLiteCommonFunc,
    Validation,
    textboxNumberHelper
} from "../../../../utils/commonFunctions";
import {
    DefaultCurenciesNumberInputField,
    deliveryTypeFare
} from "../../../../constants/commondata";
import CcCheckbox from "../../../../components/ccCheckbox/CcCheckbox";
import QuestionCircleTooltip from "../../../../components/questionCircleTooltip/QuestionCircleTooltip";
import _ from "lodash";
import InputViaCurrency from './Components/InputViaCurrency';
import InputFromToDistance from './Components/InputFromToDistance';
import DistanceType from './Components/DistanceType';


var currencyFormatter = require("currency-formatter");

class AddEditDelivery extends PureComponent {
    state = {
        valid: {},
        editable: this.props.editable,
        isSubmmitted: false,
        dialogChanged: false,
        rateInfo: {
            fleetId: this.props.auth.selectedFleet.fleetId,
            name: "",
            firstDistanceFrom: 0,
            firstDistanceTo: undefined,
            secondDistanceFrom: 0,
            secondDistanceTo: undefined,
            thirdDistanceFrom: 0,
            thirdDistanceTo: undefined,
            feeFirstDistanceType: 'distance',
            feeSecondDistanceType: 'distance',
            feeThirdDistanceType: 'distance',
            feeAfterThirdDistanceType: 'distance',
            feesByCurrencies: this.props.auth.selectedFleet.currencies.map(data => {
                return {
                    currencyISO: data.iso,
                    startingFee: 0,
                    feeFirstDistance: 0,
                    feeSecondDistance: 0,
                    feeThirdDistance: 0,
                    feeAfterThirdDistance: 0,
                    feePerMinute: 0,
                    feeMinimum: 0,
                    feeFirstStop: 0,
                    feeExtraStop: 0
                };
            }),
            isActive: true,
            noShow: {
                valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
                    data => {
                        return {
                            value: 0,
                            currencyISO: data.iso
                        };
                    }
                )
            },
            cancellationPolicy: {
                valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
                    data => {
                        return {
                            value: 0,
                            currencyISO: data.iso,
                            chargeType: 'amount'
                        };
                    }
                )
            }
        }
    };

    componentDidMount() {
        const { rateInfo } = this.props;
        if (rateInfo && rateInfo._id) {
            this.props.deliveryRateActions
                .getDetailDeliveryRate({
                    _id: rateInfo._id,
                    fleetId: this.props.auth.selectedFleet.fleetId
                })
                .then(res => {
                    if (res.ok) {
                        this.setState({ rateInfo: res.res });
                    } else {
                        if (res.error) {
                            this.context.notification(
                                "error",
                                I18n.t("errors." + res.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                "error",
                                I18n.t("regularSetting.Loading_delivery_rate_fail")
                            );
                        }
                    }
                });
        }
    };

    handleEdit = () => {
        this.setState({ editable: true })
    }

    handleInputChange = (key, e) => {
        const { rateInfo } = this.state;
        rateInfo[key] = e.target.value;
        if (key == "firstDistanceTo") {
            rateInfo.secondDistanceFrom = e.target.value;
        };
        if (key == "secondDistanceTo") {
            rateInfo.thirdDistanceFrom = e.target.value;
        };
        this.setState({ rateInfo: { ...rateInfo }, dialogChanged: true });
    };


    handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
        const { rateInfo } = this.state;
        let valueInput = e.target.value;
        valueInput = valueInput.replace(/[^0-9. ]/g, "");
        valueInput = valueInput.replace(/\s/g, "");
        let cur = {
            currencyISO: currency,
            value: valueInput
        };
        let isAdd = true;
        if (group == "noShow") {
            //   this.state.rateInfo.noShow.valueByCurrencies =
            //     this.state.rateInfo.noShow.valueByCurrencies ||
            //     this.props.auth.selectedFleet.currencies.map(data => {
            //       return {
            //         value: DefaultCurenciesNumberInputField,
            //         currencyISO: data.iso
            //       };
            //     });
            //   this.state.rateInfo.noShow.valueByCurrencies = this.state.rateInfo.noShow.valueByCurrencies.map(
            //     c => {
            //       if (c.currencyISO == currency) {
            //         isAdd = false;
            //         return { ...c, value: e.target.value };
            //       }
            //       return c;
            //     }
            //   );
            //   if (isAdd) {
            //     this.state.rateInfo.noShow.valueByCurrencies.push(cur);
            //   }
        } else if (
            group === "startingFee" ||
            group === "feeFirstDistance" ||
            group === "feeSecondDistance" ||
            group === "feeThirdDistance" ||
            group === "feeAfterThirdDistance" ||
            group === "feePerMinute" ||
            group === "feeFirstStop" ||
            group === "feeExtraStop" ||
            group === "feeMinimum"
        ) {
            rateInfo.feesByCurrencies =
                rateInfo.feesByCurrencies ||
                this.props.auth.selectedFleet.currencies.map(data => {
                    return {
                        value: DefaultCurenciesNumberInputField,
                        currencyISO: data.iso
                    };
                });
            rateInfo.feesByCurrencies = rateInfo.feesByCurrencies.map(
                c => {
                    if (c.currencyISO == currency) {
                        isAdd = false;
                        c[group] = cur.value;
                        return c;
                    }
                    return c;
                }
            );

            if (isAdd) {
                let ob = {
                    currencyISO: currency,
                    startingFee: DefaultCurenciesNumberInputField,
                    feeFirstDistance: DefaultCurenciesNumberInputField,
                    feeSecondDistance: DefaultCurenciesNumberInputField,
                    feeAfterSecondDistance: DefaultCurenciesNumberInputField,
                    feePerMinute: DefaultCurenciesNumberInputField,
                    feeFirstStop: DefaultCurenciesNumberInputField,
                    feeExtraStop: DefaultCurenciesNumberInputField,
                    minNow: DefaultCurenciesNumberInputField,
                    minReservation: DefaultCurenciesNumberInputField
                };
                ob[group] = e.target.value;
                rateInfo.feesByCurrencies.push(ob);
            }
        } else {
            //   rateInfo.cancellationPolicy[group].valueByCurrencies =
            //     rateInfo.cancellationPolicy[group].valueByCurrencies ||
            //     this.props.auth.selectedFleet.currencies.map(data => {
            //       return {
            //         value: DefaultCurenciesNumberInputField,
            //         currencyISO: data.iso
            //       };
            //     });
            //   rateInfo.cancellationPolicy[
            //     group
            //   ].valueByCurrencies = rateInfo.cancellationPolicy[
            //     group
            //   ].valueByCurrencies.map(c => {
            //     if (c.currencyISO == currency) {
            //       isAdd = false;
            //       return { ...c, value: e.target.value };
            //     }
            //     return c;
            //   });
            //   if (isAdd) {
            //     rateInfo.cancellationPolicy[group].valueByCurrencies.push(
            //       cur
            //     );
            //   }
        }
        this.setState({ rateInfo: { ...rateInfo }, dialogChanged: true });
    };

    validatorCallback = (id, valid, messages) => {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        };
        // console.log("this.state.valid ", this.state.valid);
    };

    pushValid = (data) => {
        const { valid } = this.state;
        const newValid = { ...valid, ...data };
        this.setState({ valid: newValid });
    };

    saveDialogForm = () => {
        const { rateInfo } = this.state;
        const body = _.cloneDeep(rateInfo);
        body.fleetId = this.props.auth.selectedFleet.fleetId;
        this.setState({ isSubmmitted: true });
        if (CCLiteCommonFunc.isFormValid(this.state.valid)) {
            this.props.loadingBarActions.showLoadingSpiner();
            if (body._id) {
                this.props.deliveryRateActions
                    .updateDeliveryRate(body)
                    .then(data => {
                        this.props.loadingBarActions.hideLoadingSpiner();
                        if (!data.ok) {
                            if (data.error) {
                                this.context.notification(
                                    "error",
                                    I18n.t("errors." + data.error.errorCode)
                                );
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("regularSetting.Update_delivery_rate_fail")
                                );
                            }
                        } else {
                            this.context.notification(
                                "success",
                                I18n.t("regularSetting.Update_delivery_rate_success")
                            );
                            this.props.onSuccess();
                        }
                    });
            } else {
                this.props.deliveryRateActions
                    .createDeliveryRate(body)
                    .then(data => {
                        this.props.loadingBarActions.hideLoadingSpiner();
                        if (data.ok && data.res) {
                            this.context.notification(
                                "success",
                                I18n.t("regularSetting.Created_delivery_rate_success")
                            );
                            this.setState({ rateInfo: data.res, isSubmmitted: false, editable: false });
                            this.props.onSuccess();
                        } else {
                            if (data.error) {
                                this.context.notification(
                                    "error",
                                    I18n.t("errors." + data.error.errorCode)
                                );
                            } else {
                                this.context.notification(
                                    "error",
                                    I18n.t("regularSetting.Created_delivery_rate_fail")
                                );
                            }
                        }
                    });
            }
        }
    };

    render() {
        const { rateInfo, valid, isSubmmitted, editable } = this.state;
        const hasPermission =
            !this.props.permissions || this.props.permissions.actions;

        return (
            <Modal
                show={true}
                backdrop={true}
                dialogClassName="deliveryRateModal"
                onHide={this.props.closeDialogForm}
            >
                <Modal.Header className={"pd-r-l-60"}>
                    <Modal.Title>
                        {rateInfo._id ? (
                            editable ? (
                                <Translate value="deliveryRate.edit_delivery_rate" />
                            ) : (
                                    <Translate value="deliveryRate.view_delivery_rate" />
                                )
                        ) : (
                                <Translate value="deliveryRate.add_delivery_rate" />
                            )}
                    </Modal.Title>
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.props.closeDialogForm}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body className={"pd-r-l-60"}>
                    <Row>
                        <Col
                            md={rateInfo._id ? 12 : 12}
                            xs={rateInfo._id ? 12 : 12}
                        >
                            <FormGroup
                                className={
                                    isSubmmitted
                                        ? valid.name === false
                                            ? "error"
                                            : null
                                        : null
                                }
                            >
                                <Form.Label>
                                    <Translate value="regularSetting.Name" />{" "}
                                    <span className="require">*</span>
                                </Form.Label>
                                <Col xs={12} md={12}>
                                    <FormControl
                                        type="text"
                                        className={"form-custom"}
                                        onChange={e => {
                                            this.handleInputChange("name", e);
                                        }}
                                        value={
                                            rateInfo ? rateInfo.name : ""
                                        }
                                        placeholder={I18n.t("regularSetting.Name")}
                                        disabled={!editable}
                                    />
                                </Col>
                                <Validator
                                    id="name"
                                    callback={this.validatorCallback}
                                    disabled={!editable}
                                >
                                    <ValidCase
                                        valid={rateInfo.name.trim().length > 0}
                                        message={I18n.t(
                                            "messages.commonMessages.Required_field"
                                        )}
                                        hide={!isSubmmitted}
                                    />
                                </Validator>
                            </FormGroup>

                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.STARTING" />
                            </FormGroupTitle>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Starting_fee" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='startingFee'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='startingFee'
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>


                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.FIRST_DISTANCE_FEE" />
                            </FormGroupTitle>

                            <FormGroup className={"direction-row from-to"}>
                                <Form.Label className={"from-to-label"}>
                                    <Translate value="regularSetting.First_distance" />
                                </Form.Label>
                                <InputFromToDistance
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    from='firstDistanceFrom'
                                    to='firstDistanceTo'
                                    onChangeValid={this.pushValid}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_for_first_distance" />
                                </Form.Label>
                                < DistanceType
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    keyValue='feeFirstDistanceType'
                                />
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeFirstDistance'
                                        unitDistance={this.props.auth.selectedFleet.unitDistance}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeFirstDistance'
                                            unitDistance={this.props.auth.selectedFleet.unitDistance}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>


                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.SECOND_DISTANCE_FEE" />
                            </FormGroupTitle>

                            <FormGroup className={"direction-row from-to"}>
                                <Form.Label className={"from-to-label"}>
                                    <Translate value="regularSetting.Second_distance" />
                                </Form.Label>
                                <InputFromToDistance
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    from='secondDistanceFrom'
                                    to='secondDistanceTo'
                                    onChangeValid={this.pushValid}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_for_second_distance" />
                                </Form.Label>

                                < DistanceType
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    keyValue='feeSecondDistanceType'
                                />
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeSecondDistance'
                                        unitDistance={this.props.auth.selectedFleet.unitDistance}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeSecondDistance'
                                            unitDistance={this.props.auth.selectedFleet.unitDistance}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>



                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.THIRD_DISTANCE_FEE" />
                            </FormGroupTitle>

                            <FormGroup className={"direction-row from-to"}>
                                <Form.Label className={"from-to-label"}>
                                    <Translate value="regularSetting.Third_distance" />
                                </Form.Label>
                                <InputFromToDistance
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    from='thirdDistanceFrom'
                                    to='thirdDistanceTo'
                                    onChangeValid={this.pushValid}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_for_third_distance" />
                                </Form.Label>

                                < DistanceType
                                    isSubmmitted={isSubmmitted}
                                    editable={editable}
                                    rateInfo={rateInfo}
                                    selectedFleet={this.props.auth.selectedFleet}
                                    handleInputChange={this.handleInputChange}
                                    keyValue='feeThirdDistanceType'
                                />
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeThirdDistance'
                                        unitDistance={this.props.auth.selectedFleet.unitDistance}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeThirdDistance'
                                            unitDistance={this.props.auth.selectedFleet.unitDistance}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>


                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.AFTER_DISTANCE_FEE" />
                            </FormGroupTitle>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_after_third_distance" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeAfterThirdDistance'
                                        unitDistance={this.props.auth.selectedFleet.unitDistance}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeAfterThirdDistance'
                                            unitDistance={this.props.auth.selectedFleet.unitDistance}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>


                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.PER_MINUTE" />
                            </FormGroupTitle>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Fee_per_minute" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feePerMinute'
                                        unitDistance={<Translate value="regularSetting.minute" />}
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}

                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feePerMinute'
                                            unitDistance={I18n.t("regularSetting.minute")}
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>


                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.MINIMUM" />
                            </FormGroupTitle>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.Minimum" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeMinimum'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeMinimum'
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>
                            
                            {/* fee per stop */}
                            <div className={"vs-20"} />
                            <FormGroupTitle>
                                <Translate value="regularSetting.Fer_Stop" />
                            </FormGroupTitle>
                            <FormGroup>
                                <Form.Label>
                                    <Translate value="regularSetting.First_Stop" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeFirstStop'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={false}
                                        fieldType='feeFirstStop'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                )}

                                <Form.Label>
                                    <Translate value="regularSetting.Each_Stop" />
                                </Form.Label>
                                {this.props.auth.selectedFleet.multiCurrencies ? (
                                    <InputViaCurrency
                                        selectedFleet={this.props.auth.selectedFleet}
                                        rateInfo={rateInfo}
                                        editable={editable}
                                        handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                        multiCurrency={true}
                                        fieldType='feeExtraStop'
                                        isSubmmitted={isSubmmitted}
                                        onChangeValid={this.pushValid}
                                    />
                                ) : (
                                        <InputViaCurrency
                                            selectedFleet={this.props.auth.selectedFleet}
                                            rateInfo={rateInfo}
                                            editable={editable}
                                            handleCancelInputMultiCurrenciesChange={this.handleCancelInputMultiCurrenciesChange}
                                            multiCurrency={false}
                                            fieldType='feeExtraStop'
                                            isSubmmitted={isSubmmitted}
                                            onChangeValid={this.pushValid}
                                        />
                                    )}
                            </FormGroup>

                            <div className={"vs-20"} />
                            {rateInfo._id ? null : (
                                <div>
                                    <FormGroupTitle>
                                        <Translate value="regularSetting.Assign_rate_to_car_type" />
                                    </FormGroupTitle>
                                    <FormGroup>
                                        <Form.Label>
                                            <Translate value="regularSetting.car_type" />
                                        </Form.Label>
                                        <Col xs={12} md={12}>
                                            <select
                                                className="form-control form-custom"
                                                onChange={e => {
                                                    this.handleInputChange("vehicleTypeId", e);
                                                }}
                                            >
                                                <option value="">
                                                    {I18n.t("regularSetting.Select_car_type")}
                                                </option>
                                                {this.props.commonData.carTypeDelivery.map(item => {
                                                    return (
                                                        <option value={item._id} key={item._id}>
                                                            {item.vehicleType}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                    </FormGroup>
                                </div>
                            )}

                            <div className={"vs-20"} />
                            <Row className="ml0">
                                {rateInfo._id ? (
                                    <Form.Label className="mr-r-20">
                                        (<span className="require">*</span>): <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
                                    </Form.Label>
                                ) : null}
                            </Row>
                            <Row className={"ml0"}>
                                <Form.Label>
                                    (<span className="require">*</span>): <Translate value="regularSetting.Required_fields" />
                                </Form.Label>
                            </Row>

                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer className={"pd-r-l-60"}>
                    {hasPermission ? (
                        editable ? (
                            <Button
                                className={"btn-save mr-md"}
                                onClick={this.saveDialogForm}
                                disabled={!this.state.dialogChanged}
                            >
                                <Translate value="regularSetting.Save" />
                            </Button>
                        ) : (
                                <Button
                                    className={"btn-save mr-md"}
                                    onClick={this.handleEdit}
                                >
                                    <Translate value="regularSetting.Edit" />
                                </Button>
                            )
                    ) : (
                            ""
                        )}
                    <Button className={"btn-cancel"} onClick={this.props.closeDialogForm}>
                        <Translate value="regularSetting.Cancel" />
                    </Button>
                </Modal.Footer>

            </Modal>
        )
    };
};


AddEditDelivery.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deliveryRateActions: bindActionCreators(deliveryRateActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditDelivery);