import { mapDriversGetApi, mapBookingsGetApi, mapHeatmapApi } from '../constants/ApiConfigs';

import { callApi } from '../utils/apiUtils';

export const MAP_DRIVER_REQUEST = 'MAP_DRIVER_REQUEST';
export const MAP_DRIVER_SUCCESS = 'MAP_DRIVER_SUCCESS';
export const MAP_DRIVER_FAILURE = 'MAP_DRIVER_FAILURE';
export const MAP_BOOKING_SUCCESS = 'MAP_BOOKING_SUCCESS';
export const MAP_DRIVER_ADD = 'MAP_DRIVER_ADD';
export const MAP_DRIVER_UPDATE = 'MAP_DRIVER_UPDATE';
export const MAP_DRIVER_OFFLINE = 'MAP_DRIVER_OFFLINE';
export const MAP_DRIVER_UPDATE_BOOKIN = 'MAP_DRIVER_UPDATE_BOOKIN';
export const MAP_DRIVER_UPDATE_BOOKOFF = 'MAP_DRIVER_UPDATE_BOOKOFF';
export const MAP_DRIVER_UPDATE_INFO = 'MAP_DRIVER_UPDATE_INFO';

function loadMapDriverRequest() {
  return {
    type: MAP_DRIVER_REQUEST
  };
}

function loadMapDriverSuccess(data) {
  return {
    type: MAP_DRIVER_SUCCESS,
    data: data.res
  };
}

function loadMapDriverFailure(error) {
  return {
    type: MAP_DRIVER_FAILURE,
    error
  };
}
export function loadMapDriver(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(
    mapDriversGetApi,
    config,
    loadMapDriverRequest(),
    loadMapDriverSuccess,
    loadMapDriverFailure,
    isLoading
  );
}

function cueSuccess(data) {
  return {
    type: MAP_BOOKING_SUCCESS,
    data: data.res
  };
}

export function mapBoookingsFind(options = {}, isloading = true) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };
  return callApi(mapBookingsGetApi, config, null, cueSuccess, null, isloading);
}

export function addNewDriver(driver) {
  return {
    type: MAP_DRIVER_ADD,
    data: driver
  };
}

export function updateDriver(driver) {
  return {
    type: MAP_DRIVER_UPDATE,
    data: driver
  };
}
export function updateDriverOffline(data) {
  return {
    type: MAP_DRIVER_OFFLINE,
    data
  };
}

export function updateDriverBookIn(data) {
  return {
    type: MAP_DRIVER_UPDATE_BOOKIN,
    data
  };
}

export function updateDriverBookOff(data) {
  return {
    type: MAP_DRIVER_UPDATE_BOOKOFF,
    data
  };
}

export function updateDriverInfo(data) {
  return {
    type: MAP_DRIVER_UPDATE_INFO,
    data
  };
}


export function loadHeatmapData(options = {}, isLoading = true) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options)
  };

  return callApi(
    mapHeatmapApi,
    config,
    loadMapDriverRequest(),
    null,
    null,
    isLoading
  );
}