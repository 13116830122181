import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Col,
    Row,
    Button,
    ButtonToolbar,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';


import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import Confirm from '../../../components/confirm/Confirm';
import * as settingActions from '../../../actions/settingActions';
import * as paymentMethodActions from '../../../actions/paymentMethodActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';

import ConvertSpent from './Components/ConvertSpent';
import ConvertTopup from './Components/ConvertTopup';
import RedeemPoint from './Components/RedeemPoint';
import ModalAddEditVoucher from './Components/ModalAddEditVoucher';
import Other from './Components/Other';


const CONFIRM_DELETE_ID = 1;
class PointConfig extends Component {
    constructor() {
        super();
        this.state = {
            formData: {},
            valid: {},
            campaignList: [],
            serviceAvailable: [],
            isSubmitted: false,
            showModalAddEditVoucher: null,
            showConfirmToDelete: null,
        }
        this.getCampaignList = this.getCampaignList.bind(this);
        this.getServiceAvailable = this.getServiceAvailable.bind(this);
        this.ValidatorCallback = this.ValidatorCallback.bind(this);
    }

    componentDidMount() {
        const { auth: { selectedFleet }, settingActions } = this.props;
        this.props.loadingBarActions.showLoadingSpiner();
        this.props.paymentMethodActions.paymentMethod(this.props.auth.selectedFleet.fleetId);
        settingActions.getPointConfigDetails({ fleetId: selectedFleet.fleetId })
            .then(data => {
                this.props.loadingBarActions.hideLoadingSpiner();
                if (data.ok) {
                    let formData = data.res
                        ? Object.assign({}, data.res)
                        : Object.assign({}, this.state.formData);
                    this.setState({ formData });
                } else if (data.error) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('pointConfig.Load_data_fail')
                        );
                    }
                }
            })
            .catch(error => {
                let formData = Object.assign({}, this.state.formData);
                this.setState({ formData });
            });
        this.getCampaignList();
        this.getServiceAvailable();
    }

    getCampaignList() {
        const { auth, settingActions } = this.props;
        let fleetId = auth.selectedFleet.fleetId;
        settingActions.findAllCampaignForSelection(fleetId).then(data => {
            if (data.ok && data.res) {
                this.setState({
                    campaignList: data.res
                });
            }
        });
    };

    getServiceAvailable() {
        const { auth: { selectedFleet } } = this.props;
        let serviceAvailable = [];
        if (selectedFleet.transport && selectedFleet.transport.enable) {
            serviceAvailable.push("transport");
        };
        if (selectedFleet.delivery && selectedFleet.delivery.enable) {
            serviceAvailable.push("delivery");
        };
        if (selectedFleet.food && selectedFleet.food.enable) {
            serviceAvailable.push("food");
        };
        if (selectedFleet.mart && selectedFleet.mart.enable) {
            serviceAvailable.push("mart");
        };
        if (selectedFleet.intercity && selectedFleet.intercity.enable) {
            serviceAvailable.push("intercity");
        };
        this.setState({ serviceAvailable });
    };

    handleChangeData = (key, data) => {
        const { formData } = this.state
        switch (key) {
            case "ConvertSpent":
                this.setState({
                    formData: {
                        ...formData,
                        metrics: {
                            ...formData.metrics,
                            spent_amount: data
                        }
                    }
                })
                break;
            case "ConvertTopup":
                this.setState({
                    formData: {
                        ...formData,
                        metrics: {
                            ...formData.metrics,
                            top_up: data
                        }
                    }
                })
                break;
            default:
                break;
        }
    };

    handleChangeInput = (key, value) => {
        let data = this.state.formData;
        switch (key) {
            case "instructionURL":
                data.others[key] = value;
                break;
            case "displayName":
                data.others[key] = value;
                break;
            case "isActive":
                data.isActive = value;
                break;
            default:
                break;
        };
        this.setState({
            formData: data
        })
    }


    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    handleOpenModal = (data) => {
        this.setState({
            showModalAddEditVoucher: data
        })
    }
    closeModalForm = () => {
        this.setState({
            showModalAddEditVoucher: null,
            showConfirmToDelete: null,
            isSubmitted: false
        })
    }

    handleChangeInputAddEditVoucher = (key, value) => {
        const { showModalAddEditVoucher } = this.state;
        this.setState({
            showModalAddEditVoucher: {
                ...showModalAddEditVoucher,
                [key]: value
            }
        })
    }


    handleClickDelete = (data) => {
        this.setState({
            showConfirmToDelete: {
                voucherId: data._id,
                id: CONFIRM_DELETE_ID,
                title: I18n.t('pointConfig.Confirmation'),
                body: (
                    <div>
                        <Translate value="pointConfig.Delete_voucher_confirmation" />
                    </div>
                ),
                buttonTitle: 'Yes',
                closeButtonText: 'No'
            }
        });
    }

    handleConfirmButtonClick = (id) => {
        switch (id) {
            case CONFIRM_DELETE_ID:
                this.handleDeleteVoucher();
                break;
            default:
                break;
        }
    }

    handleSaveVoucher = () => {
        const { settingActions, loadingBarActions, auth: { selectedFleet } } = this.props;
        const { formData, showModalAddEditVoucher, serviceAvailable } = this.state;
        this.setState({ isSubmitted: true });
        if (!CCLiteCommonFunc.isFormValid(this.state.valid)) return;
        loadingBarActions.showLoadingSpiner();
        let requestBody = {};
        if (showModalAddEditVoucher._id) {
            requestBody = {
                fleetId: selectedFleet.fleetId,
                id: showModalAddEditVoucher._id,
                name: showModalAddEditVoucher.name,
                points: showModalAddEditVoucher.points,
                amount: showModalAddEditVoucher.amount,
                tandc: showModalAddEditVoucher.tandc,
                validTime: showModalAddEditVoucher.validTime,
                validTimeUnit: showModalAddEditVoucher.validTimeUnit,
                campaignId: showModalAddEditVoucher.campaignId,
                services: serviceAvailable
            };
            settingActions.updateRedeemPointConfig(requestBody)
                .then(data => {
                    loadingBarActions.hideLoadingSpiner();
                    if (data.ok) {
                        let promotion = data.res.promotion
                            ? data.res.promotion
                            : formData.redeem.promotion;
                        this.setState({
                            formData: {
                                ...formData,
                                redeem: {
                                    promotion
                                }
                            }
                        });
                        this.context.notification('success', I18n.t('pointConfig.Update_voucher_successfully'));
                    } else if (data.error) {
                        if (data.error) {
                            this.context.notification(
                                'error',
                                I18n.t('errors.' + data.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                'error',
                                // I18n.t('errors.undefined')
                                I18n.t('pointConfig.Update_voucher_fail')
                            );
                        }
                    }
                })
        } else {
            requestBody = {
                fleetId: selectedFleet.fleetId,
                promotion: {
                    name: showModalAddEditVoucher.name,
                    points: showModalAddEditVoucher.points,
                    amount: showModalAddEditVoucher.amount,
                    tandc: showModalAddEditVoucher.tandc,
                    validTime: showModalAddEditVoucher.validTime,
                    validTimeUnit: showModalAddEditVoucher.validTimeUnit,
                    campaignId: showModalAddEditVoucher.campaignId,
                    services: serviceAvailable
                }
            };
            settingActions.addRedeemPointConfig(requestBody)
                .then(data => {
                    loadingBarActions.hideLoadingSpiner();
                    if (data.ok) {
                        let promotion = data.res.promotion
                            ? data.res.promotion
                            : formData.redeem.promotion;
                        this.setState({
                            formData: {
                                ...formData,
                                redeem: {
                                    promotion
                                }
                            }
                        });
                        this.context.notification('success', I18n.t('pointConfig.Create_voucher_successfully'));
                    } else if (data.error) {
                        if (data.error) {
                            this.context.notification(
                                'error',
                                I18n.t('errors.' + data.error.errorCode)
                            );
                        } else {
                            this.context.notification(
                                'error',
                                // I18n.t('errors.undefined')
                                I18n.t('pointConfig.Create_voucher_fail')
                            );
                        }
                    }
                })
        }
        this.closeModalForm();
    }

    handleDeleteVoucher = () => {
        const { settingActions, loadingBarActions, auth: { selectedFleet } } = this.props;
        const { formData, showConfirmToDelete } = this.state;
        loadingBarActions.showLoadingSpiner();
        let requestBody = {
            fleetId: selectedFleet.fleetId,
            id: showConfirmToDelete.voucherId,
        };
        settingActions.removeRedeemPointConfig(requestBody)
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok) {
                    let promotion = data.res.promotion
                        ? data.res.promotion
                        : formData.redeem.promotion;
                    this.setState({
                        formData: {
                            ...formData,
                            redeem: {
                                promotion
                            }
                        }
                    });
                    this.context.notification('success', I18n.t('pointConfig.Delete_voucher_successfully'));
                } else if (data.error) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('pointConfig.Delete_voucher_fail')
                        );
                    }
                }
            })
        this.closeModalForm();
    }

    handleSaveClick = () => {
        const { settingActions, loadingBarActions } = this.props;
        const { formData } = this.state;
        let requestBody = _.cloneDeep(formData);
        let spentAmountInvalid = [];
        let topUpInvalid = [];
        let spentAmountValid = true;
        let topUpValid = true;
        delete requestBody.redeem;

        if (requestBody.isActive) {
            spentAmountInvalid = (requestBody.metrics.spent_amount || []).filter(item => parseFloat(item.value) < 0);
            topUpInvalid = (requestBody.metrics.top_up || []).filter(item => parseFloat(item.value) < 0);
            spentAmountValid = (requestBody.metrics.spent_amount || []).some(item => parseFloat(item.value) !== 0);
            topUpValid = (requestBody.metrics.top_up || []).some(item => parseFloat(item.value) !== 0);
        };
        if (spentAmountInvalid.length > 0) {
            this.context.notification(
                'error',
                I18n.t('pointConfig.Please_enter_value_is_number_spentAmount').format(I18n.t(`pointConfig.${spentAmountInvalid[0].service}`))
            );
            return
        };
        if (topUpInvalid.length > 0) {
            this.context.notification(
                'error',
                I18n.t('pointConfig.Please_enter_value_is_number_topUp').format(I18n.t(`paymentMethod.paymentMethod_${topUpInvalid[0].type}`))
            );
            return
        };
        if (!spentAmountValid && !topUpValid) {
            this.context.notification(
                'error',
                I18n.t('pointConfig.Please_enter_value_least_one')
            );
            return
        };

        loadingBarActions.showLoadingSpiner();
        settingActions.updatePointConfig(requestBody)
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok) {
                    let formData = data.res
                        ? Object.assign({}, data.res)
                        : Object.assign({}, this.state.formData);
                    this.setState({ formData });
                    this.context.notification('success', I18n.t('pointConfig.update_successfully'), 50000);
                } else if (data.error) {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('errors.undefined')
                        );
                    }
                }
            })
    }

    render() {
        const { formData, showModalAddEditVoucher, campaignList } = this.state;
        const { auth: { selectedFleet }, permissions, paymentMethod, paymentMethod: { passengerWallet = [] } } = this.props;
        const hasPermission = permissions && permissions.actions ? permissions.actions : false;

        let showConvertTopup = true;
        if (!selectedFleet.paxCreditWallet || passengerWallet.length === 0) {
            showConvertTopup = false;
        }

        return (
            <div
                className="content referral-form custom-form overflow-auto"
                style={{ paddingBottom: 140 }}
            >
                <Row>
                    <Col xs={12} md={6} className="pax-to-pax-form">
                        <FormGroupTitle className="ph pv0 mb-lg">
                            <Translate value="pointConfig.point_config" />
                        </FormGroupTitle>
                        <ConvertSpent
                            spentAmount={formData.metrics && formData.metrics.spent_amount || []}
                            selectedFleet={selectedFleet}
                            paymentMethod={paymentMethod}
                            hasPermission={hasPermission}
                            handleChangeData={this.handleChangeData}
                        />
                        {showConvertTopup &&
                            <ConvertTopup
                                topupAmount={formData.metrics && formData.metrics.top_up || []}
                                selectedFleet={selectedFleet}
                                paymentMethod={paymentMethod}
                                hasPermission={hasPermission}
                                handleChangeData={this.handleChangeData}
                            />
                        }
                        <RedeemPoint
                            promotions={formData.redeem && formData.redeem.promotion || []}
                            selectedFleet={selectedFleet}
                            hasPermission={hasPermission}
                            handleOpenModal={this.handleOpenModal}
                            handleClickDelete={this.handleClickDelete}
                        />
                        <Other
                            others={formData.others || []}
                            isActive={formData.isActive || false}
                            selectedFleet={selectedFleet}
                            hasPermission={hasPermission}
                            editable={true}
                            handleChangeInput={this.handleChangeInput}
                        />
                        <div className="passenger-field-item">
                            {
                                hasPermission && <ButtonToolbar className="text-center center mt-md">
                                    <Button className="btn-save" onClick={this.handleSaveClick}>
                                        <Translate value="dispatchSetting.Save" />
                                    </Button>
                                </ButtonToolbar>
                            }
                        </div>

                    </Col>
                </Row>

                {showModalAddEditVoucher &&
                    <ModalAddEditVoucher
                        data={showModalAddEditVoucher}
                        editable={true}
                        selectedFleet={selectedFleet}
                        hasPermission={hasPermission}
                        campaignList={campaignList}
                        handleChangeInput={this.handleChangeInputAddEditVoucher}
                        handleSaveForm={this.handleSaveVoucher}
                        closeModalForm={this.closeModalForm}
                        valid={this.state.valid}
                        isSubmitted={this.state.isSubmitted}
                        ValidatorCallback={this.ValidatorCallback}
                    />}

                <Confirm
                    confirm={this.state.showConfirmToDelete}
                    handleConfirmButtonClick={this.handleConfirmButtonClick}
                    handleConfirmCloseClick={this.closeModalForm}
                />
            </div>
        )
    }
}

PointConfig.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        users: state.users,
        paymentMethod: state.paymentMethod,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
        paymentMethodActions: bindActionCreators(paymentMethodActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PointConfig)