import React from 'react';
import { FormGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './styles.scss';

// DON'T SET PLACEHOLDER FOR FORM CONTROL
// LET'S USE PLACEHOLDER AS PROPS OF THIS COMPONENT
// PLACEHOLDER DON"T INCLUSE "*" CHARACTER
// WHEN INPUT HAS VALUE, PLACEHOLDER SHOULD BE '' 
const InputRequireInline = props => {
  const copyProps = Object.assign({}, props);
  const className = props.className || '';
  const placeholder =
    (props.placeholder === null || props.placeholder === undefined
      ? ''
      : props.placeholder) + '';
  delete copyProps.className;
  delete copyProps.placeholder;

  return (
    <FormGroup
      className={`qup-input-group input-require-inline ${className || ''}`}
      {...copyProps}
    >
      {copyProps.children}
    </FormGroup>
  );
};

InputRequireInline.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default InputRequireInline;
