import React, { Component } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  Form,
  FormControl,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { Fragment } from 'react';
import { PM_SHOW_REFUND_FARE } from '../../../constants/commondata';
import { showNotifyCancelEmailCheckbox } from '../../../components/bookingDetail/bookFunction/bookingInfo';
import { checkCorporateUser } from '../../../utils/commonFunctions';

const CancelStatus = ['confirmed', 'booked']

class CancelButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNotifyCancelEmail: false
    }
  }

  handleNotifyCustomerViaEmailChange = (e) => {
    this.setState({
      hasNotifyCancelEmail: e.target.checked,
    });
  };
  componentDidMount = async () => {
    if(checkCorporateUser(this.props?.auth?.user)) {
      try {
        const result = await this.props.bookingDetailActions
        .checkChargeCCCancel({
          fleetId: _.get(this.props.auth, 'selectedFleet.fleetId', ''),
          bookId: this.props.bookInfo.bookId,
        })
        if(result && result.returnCode === 200 && result.charge) {
          this.setState({
            msgWarningCancel: true
          });
        }
      } catch (error) {
        
      }
    }
  }

  handleIncidentReasonChanged = (e) => {
    this.setState({
      reason: e.target.value,
    });
  };

  handleRefundFareChanged = (e) => {
    this.setState({
      refundFare: e.target.checked,
    });
  };

  closeModal = () => {
    this.setState({ visible: false });
  }

  showConfirm = () => {
    this.setState({ visible: true, reason: '', refundFare: false, hasNotifyCancelEmail: false });
  }

  handleSubmit = (e) => {
    this.props.onSubmit({
      reason: this.state.reason,
      refundFare: this.state.refundFare,
      hasNotifyCancelEmail: this.state.hasNotifyCancelEmail
    })
  }

  render() {
    const {
      bookInfo,
      selectedFleet
    } = this.props;

    const { reason, refundFare, visible, hasNotifyCancelEmail } = this.state;
    if (!bookInfo || !CancelStatus.includes(bookInfo.status)) {
      return null;
    }
    return (
      <Fragment>
        <Button onClick={this.showConfirm} className="btn-cancel mr mb">
          <Translate value="bookingdetail.Cancel" />
        </Button>
        <Modal
          show={visible}
          backdrop={true}
          dialogClassName="confirm-dialog"
          onHide={this.closeModal}
          className={'confirm'}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="intercityBooking.ConfirmCancel" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Form.Label>
                <Translate value="bookingdetail.Input_reason" />
              </Form.Label>
              <FormControl
                onChange={this.handleIncidentReasonChanged}
                type="text"
                value={reason}
                className="form-custom"
              />
            </FormGroup>
            {PM_SHOW_REFUND_FARE.includes(bookInfo?.request?.paymentType) && (
              <FormGroup>
                <CcCheckbox
                  checked={refundFare}
                  onChange={this.handleRefundFareChanged}
                  text={I18n.t('intercityBooking.RefundFare')}
                />
              </FormGroup> 
            )}
            {showNotifyCancelEmailCheckbox({
                auth: this.props.auth,
                bookInfo,
            }) && 
              <div style={{ display: 'block', marginTop: '1rem'}}>
                <CcCheckbox
                  checked={hasNotifyCancelEmail}
                  onChange={this.handleNotifyCustomerViaEmailChange}
                  text={I18n.t('newbooking.notify_via_email')}
                  style={{ marginBottom: '0px' }}
                  className='mr-b-0 mr-t-20'
                />
              </div>
            }
            <i style={{ color: '#FAC940', display: 'block' }} >
                {this.state.msgWarningCancel && I18n.t('bookingdetail.warnningCancelCorporate')}
            </i>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel" onClick={this.closeModal}>
              <Translate value="bookingdetail.Cancel" />
            </Button>
            <Button
              className="btn-save"
              disabled={!reason}
              onClick={this.handleSubmit}
            >
              <Translate value="bookingdetail.Ok" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default CancelButton;
