import "./confirm.scss";
import React, { Component } from "react";
import _ from "lodash";
import { Modal, Button } from "react-bootstrap";
import { I18n } from "react-redux-i18n";

class Confirm extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      className,
      confirm,
      handleConfirmButtonClick,
      handleConfirmCloseClick
    } = this.props;

    if (confirm) {
      let closeButtonClass = confirm.closeButtonClass
        ? confirm.closeButtonClass
        : "btn-reset";
      let closeButtonText = confirm.closeButtonText
        ? confirm.closeButtonText
        : I18n.t("cue.Close");
      return (
        <Modal
          show={true}
          backdrop="static"
          className={`${className ? className + " " : ""}confirm`}
        >
          {confirm.title ? (
            <Modal.Header>
              <Modal.Title>{confirm.title}</Modal.Title>
            </Modal.Header>
          ) : null}
          <Modal.Body className="text">
            {
              _.isString(confirm.body) 
              ? <div dangerouslySetInnerHTML={{ __html: confirm.body }}></div>
              : confirm.body
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={closeButtonClass}
              onClick={handleConfirmCloseClick}
            >
              {closeButtonText}
            </Button>
            {confirm.buttonTitle ? (
              <Button
                className={"btn-save ml-md"}
                disabled={confirm.disabledConfirm}
                onClick={() => {
                  if(confirm.hasConfirm && _.isFunction(confirm.handleConfirmButtonClick)) {
                    confirm.handleConfirmButtonClick()
                    return
                  }
                  handleConfirmButtonClick(confirm.id, confirm);
                }}
              >
                {confirm.buttonTitle}
              </Button>
            ) : null}
          </Modal.Footer>
        </Modal>
      );
    } else {
      return null;
    }
  }
}

export default Confirm;
