import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import moment from "moment";
import DateSelectBooking from "./DateSelectBooking";
import SelectTimeRecurring from "./SelectTimeRecurring";
import CcCheckbox from '../../components/ccCheckbox/CcCheckbox'
import _ from 'lodash'
import WeekDays from './Weekdays'
import { Validation, textboxNumberHelper  } from '../../utils/commonFunctions';
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button
} from 'react-bootstrap';
import { HourList, MinList, bookingStatusMapping } from '../../constants/commondata';
import { Validator, ValidCase } from '../validator';
import YearCalendarModal from './YearCalendarModal'
import DateTextRecurringUI from './DateTextRecurringUI';
import './dateModeBooking.scss';

const DateModeBooking = ({
  dateMode,
  format24Hour,
  pickupTime,
  cartypeSelected,
  language,
  fromDate,
  weekDays,
  hasRecurring,
  recurringTime,
  setRecurringTime,
  bookDetails,
  pickupTimeDisableBkDetail,
  batchId,
  isSubmited,
  jobType,
  ValidatorCallback,
  valid,
  minutesToArrive = {},
  setMinutesToArrive,
  showETA = false,
  isHydraBooking,
  recurringDays = [],
  isFinished = false,
  fleetId,
  loadingActions,
  getStatusRecurring,
  status,
  ...props
}) => {
  const timeModeRef = useRef(null);
  const [ isShowCalendarModal, setShowCalendarModal] = useState(false);
  const [ recurringBookingList, setRecurringBookingList] = useState([]);

  const handleSelectHour = (e) => {
    const hourValue = e.target.value;
    const timeMode = timeModeRef.current ? timeModeRef.current.value : '';
    handleChangeHour(e, hourValue, timeMode);
  };

  const handleSelectTimeMode = (e, time) => {
    const timeMode = e.target.value;
    const hourValue = timeMode === I18n.t('generalSetting.PM') ? time.format('HH') : time.format('hh');
    handleChangeHour(e, hourValue, timeMode);
  };

  const handleChangeHour = (e, hourValue, timeMode) => {
    // const format24Hour onChangePickUpHour } = this.props;
    if (!format24Hour) {
      if (parseInt(hourValue) === 12) {
        hourValue = timeMode === I18n.t('generalSetting.AM') ? '0' : '12';
      } else {
        hourValue = moment(`${hourValue}:00 ${timeMode}`, 'HH:mm A').format('HH');
      }
    }
    props.onChangePickUpHour({
      ...e,
      target: {
        ...e.target,
        value: hourValue
      }
    });
  };

  const handleChangeDateMode = (e) => {
    let check = e.target && e.target.checked
    if (check) {
      props.changeDateMode('recurring')
    } else {
      props.changeDateMode('single')
    }
  }

  let pickUpTimeDisabled =
    cartypeSelected && cartypeSelected.dispatchRideSharing || props.isSuperHelper;

  if (bookDetails) {
    pickUpTimeDisabled = pickupTimeDisableBkDetail;
  }

  const disablePickupDate = bookDetails && batchId;

  let pickupTimeValue = pickupTime;

  if (pickupTime === 'Now' && jobType === 'shuttle') {
    pickupTimeValue = moment();
  }

  const handleChangeMinutesArrialDefault = (e) => {
    var policy = e.target.value;
    let newValue = {
      expected: 'before',
      value: '0'
    }
    switch (policy) {
      case 'quarterBefore':
        newValue = {
          expected: 'before',
          value: '15'
        }
        break;
      case 'halfBefore':
        newValue = {
          expected: 'before',
          value: '30'
        }
        break;
      case 'quarterAfter':
        newValue = {
          expected: 'after',
          value: '15'
        }
        break;
      case 'halfAfter':
        newValue = {
          expected: 'after',
          value: '30'
        }
        break;
      case 'samePickup':
        newValue = {
          expected: 'samePickup',
          value: '0'
        }
        break
      default:
        newValue = {
          expected: 'before',
          value: '0'
        }
        break;
    }
    setMinutesToArrive(newValue)
  }

  const convertValueMinutesArrial = () => {
    const {expected = 'samePickup', value = '0' } = minutesToArrive
    if(expected === 'samePickup') return 'samePickup'
    if(expected === 'before') {
      if(value == '15') return 'quarterBefore'
      if(value == '30') return 'halfBefore'
      return 'custom'
    }
    if(expected === 'after') {
      if(value == '15') return 'quarterAfter'
      if(value == '30') return 'halfAfter'
      return 'custom'
    }
    return 'custom'
  }

  const isActiveBooking = (booking) => {
    return bookingStatusMapping.active[booking.status];
  };

  const handleMinutesToArriveChange = e => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if(!regrex.test(e.target.value)) return
    setMinutesToArrive({
      ...minutesToArrive,
      value: e.target.value
    })
  };

  const handleExpectedChange = e => {
    setMinutesToArrive({
      ...minutesToArrive,
      expected: e.target.value
    })
  };

  const openCalendarModal = e => {
    if(bookDetails && recurringBookingList && recurringBookingList.length === 0) {
      if(batchId) {
        loadingActions.showLoadingSpiner()
        getStatusRecurring({
          fleetId: fleetId,
          batchId: batchId,
        })
        .then((result) => {
          if (result.ok && result.res) {
            setRecurringBookingList(result.res.list)
          }  
          loadingActions.hideLoadingSpiner()
        });
      }
    }
    setShowCalendarModal(true)
  }

  return (
    <div className="dateMode">
      <YearCalendarModal
        isBookDetails={bookDetails}
        isShow={isShowCalendarModal}
        closeYearCalendarModal={() => setShowCalendarModal(false)}
        setRecurringDays={props.setRecurringDays}
        recurringDaysInit={recurringDays}
        recurringBookingList={recurringBookingList}
        oldDays={_.get(props.bookingDetailOld, 'recurring.selectedDates', [])}
      />
      {
        batchId
        && (
          <div className="idRecurring">
            <b>Recurring:</b>
            <span>
              {' '}
              #
              {batchId}
            </span>
          </div>
        )
      }
      {
        hasRecurring && // only show with shuttle booking
        !props.farmIn &&
        <CcCheckbox
          checked={dateMode == 'recurring' ? true : false}
          onChange={handleChangeDateMode}
          text={I18n.t(`newbooking.Recurring`)}
          disabled={bookDetails}
        />
      }


      {/* UI Single for transport and shuttle do not recurring */}
      {
        dateMode === 'single'
        && (
          <>
            <Form.Label>
              <Translate value="newbooking.Pickup_time" />
            </Form.Label>
            <FormGroup className="booking-date-picker">
              <DateSelectBooking
                pickUpTimeDisabled={pickUpTimeDisabled}
                value={pickupTimeValue}
                hasNowButton={jobType === 'shuttle' || !!bookDetails || isHydraBooking ? false : true}
                onChangeDate={props.handleDateChanged}
                disablePickupDate={!!disablePickupDate}
                bookDetails={bookDetails}
                status={props?.bookingDetailOld?.status}
                format24Hour={format24Hour}
                bookingDetailOld={props.bookingDetailOld}
              />
            </FormGroup>
            {pickupTimeValue != 'Now' && (
              <>
                <Row>
                  <Col sm={format24Hour ? 6 : 4} md={format24Hour ? 6 : 12} lg={format24Hour ? 6 : 4}>
                    <select
                      className="form-control form-custom"
                      placeholder={I18n.t('newbooking.hh')}
                      onChange={handleSelectHour}
                      value={pickupTimeValue ? pickupTimeValue.format(format24Hour ? 'HH' : 'hh') : ''}
                      disabled={pickUpTimeDisabled}
                    >
                      {HourList.map(obj => (((!format24Hour && parseInt(obj) <= 12 && parseInt(obj) > 0) || format24Hour) ? (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ) : (<React.Fragment key={obj} />)))}
                    </select>
                  </Col>
                  <Col sm={format24Hour ? 6 : 4} md={format24Hour ? 6 : 12} lg={format24Hour ? 6 : 4}>
                    <select
                      className="form-control form-custom"
                      placeholder={I18n.t('newbooking.mm')}
                      value={pickupTimeValue ? pickupTimeValue.format('mm') : ''}
                      onChange={props.onChangePickUpMin}
                      disabled={pickUpTimeDisabled}
                    >
                      {MinList.map(obj => (
                        <option key={obj} value={obj}>
                          {obj}
                        </option>
                      ))}
                    </select>
                  </Col>
                  {
                    !format24Hour
                    && (
                      <Col sm={4} md={12} lg={4}>
                        <select
                          className="form-control form-custom"
                          placeholder={I18n.t('newbooking.mm')}
                          ref={timeModeRef}
                          value={pickupTimeValue ? (pickupTimeValue.format('HH') >= 12 ? I18n.t('generalSetting.PM') : I18n.t('generalSetting.AM')) : ''}
                          onChange={(e) => handleSelectTimeMode(e, pickupTimeValue)}
                          disabled={pickUpTimeDisabled}
                        >
                          <option value={I18n.t('generalSetting.AM')}>{I18n.t('generalSetting.AM')}</option>
                          <option value={I18n.t('generalSetting.PM')}>{I18n.t('generalSetting.PM')}</option>
                        </select>
                      </Col>
                    )
                  }
                  {
                    showETA && jobType !== 'shuttle' &&
                    <Col xs={12}>
                      <FormGroup>
                        <Form.Label>
                          {/* <Translate value="newbooking.Pickup_time" /> */}
                          Expected driver arrival
                        </Form.Label>
                        <FormControl
                          as="select"
                          className="form-custom"
                          placeholder={I18n.t('newbooking.Company')}
                          value={convertValueMinutesArrial()}
                          onChange={handleChangeMinutesArrialDefault}
                          disabled={pickUpTimeDisabled}
                        >
                          <option key={'samePickup'} value={'samePickup'}>{I18n.t('generalSetting.same_original_pickup_time')}</option>
                          <option key={'quarterBefore'} value={'quarterBefore'}>{I18n.t('generalSetting.quarter_before')}</option>
                          <option key={'halfBefore'} value={'halfBefore'}>{I18n.t('generalSetting.half_before')}</option>
                          <option key={'quarterAfter'} value={'quarterAfter'}>{I18n.t('generalSetting.quarter_after')}</option>
                          <option key={'halfAfter'} value={'halfAfter'}>{I18n.t('generalSetting.half_after')}</option>
                          <option key={'custom'} value={'custom'}>{I18n.t('generalSetting.custom')}</option>
                        </FormControl>
                      </FormGroup>
                      {
                        (
                          (minutesToArrive.expected === 'before' || minutesToArrive.expected === 'after') &&
                          (minutesToArrive.value != 15 && minutesToArrive.value != 30)
                        ) && 
                        <FormGroup
                        >
                          <FormGroup className="qup-input-group">
                            <InputGroup className="single-addon-right">
                              <FormControl
                                type="text"
                                className="form-custom"
                                onChange={handleMinutesToArriveChange}
                                onBlur={e => {
                                    textboxNumberHelper.onBlurHandle(
                                    e,
                                    handleMinutesToArriveChange
                                    );
                                }}
                                onFocus={e => {
                                    textboxNumberHelper.onfocusHandle(
                                    e,
                                    handleMinutesToArriveChange
                                    );
                                }}
                                value={minutesToArrive.value}
                                disabled={pickUpTimeDisabled}
                              />
                              <FormControl
                                as="select"
                                className="input-group-addon-custom form-custom"
                                value={minutesToArrive.expected === 'custom' ? 'before' : minutesToArrive.expected}
                                onChange={handleExpectedChange}
                                disabled={pickUpTimeDisabled}
                                >
                                <option key={'before'} value={'before'}>{I18n.t('generalSetting.min_before')}</option>
                                <option key={'after'} value={'after'}>{I18n.t('generalSetting.min_after')}</option>
                              </FormControl>
                            </InputGroup>
                          </FormGroup>
                        </FormGroup>
                      }
                    </Col>
                  }
                </Row>
              </>
            )}
          </>
        )
      }

      {/* UI for recurring shuttle */}
      {
        dateMode === 'recurring'
        && (
          <>
            {
              !isFinished && (
                <>
                  <Form.Label>
                    <Translate value="newbooking.Date_range" />
                  </Form.Label>
                  { 
                    recurringDays && recurringDays.length > 0 
                    ? (
                      <div>
                        <div className='dateTextOut'>
                          <DateTextRecurringUI 
                            newDays={recurringDays}
                            oldDays={_.get(props.bookingDetailOld, 'recurring.selectedDates', [])}
                            isBookDetails={bookDetails}
                          />
                        </div>
                        <Button
                          className="btn-header text-add-header showYear"
                          onClick={openCalendarModal}
                        >
                          {I18n.t('bookingdetail.Edit')}
                        </Button>
                      </div>
                    ) : (
                      <p>
                        <Button
                          className="btn-header text-add-header showYear"
                          onClick={openCalendarModal}
                        >
                          + {I18n.t('bookingdetail.Add')}
                        </Button>
                      </p>
                    )
                  }
                </>
              )
            }
            
            <SelectTimeRecurring
              recurringTime={recurringTime}
              format24Hour={format24Hour}
              isSubmited={isSubmited}
              ValidatorCallback={ValidatorCallback}
              valid={valid}
              setRecurringTime={setRecurringTime}
              title={I18n.t('newbooking.Pickup_time')}
              isBookDetails={bookDetails}
              bookingDetailOld={props.bookingDetailOld}
              jobType
            />
          </>
        )
      }
    </div>
  );
};

DateModeBooking.propTypes = {
  dateMode: propTypes.string,
  weekDays: propTypes.object,
  changeDateMode: propTypes.func,
  cartypeSelected: propTypes.object,
  data: propTypes.object
};

DateModeBooking.defaultProps = {
  dateMode: 'single',
  recurringTime: '00:00',
  weekDats: {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  },
  cartypeSelected: {},
  isSubmited: false,
  language: 'en',
  format24Hour: true,
  isSuperHelper: false,
  data: {},
  bookDetails: false,
  pickupTimeDisableBkDetail: false,
  bookingDetailOld: {},

  // function
  changeDateMode: () => { },
  onChangePickUpHour: () => { },
  onChangePickUpMin: () => { },
  handleDateChanged: () => { },

  updateFromDate: () => { },
  updateToDate: () => { },
  setRecurringTime: () => { },
  ValidatorCallback: () => { },
  onChangeWeekDays: () => { }
};

export default DateModeBooking;
