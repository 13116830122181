import ReportUtils from './../base/utils'

export const fields = [{
  key: "date",
  label: "report.result.operatorLogs.date",
  summary: "date",
  mutate: ReportUtils.formatTime
}, {
  key: "operator",
  label: "report.result.operatorLogs.operator",
  summary: "operator"
}, {
  key: "username",
  label: "report.result.operatorLogs.username",
  summary: "username"
}, {
  key: "module",
  label: "report.result.operatorLogs.module",
  summary: "module",
  mutate: (value, doc) => value && value.replace("mDispatcher", "Partner")
}, {
  key: "description",
  label: "report.result.operatorLogs.description",
  summary: "description",
  mutate: (value, doc) => value && value.replace("mDispatcher", "Partner")
}]