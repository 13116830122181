import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import * as bookingDetailActions from '../../actions/bookingDetailAction';

class BookingDetailFromUrl extends React.Component {
  componentDidMount() {
    const bookId = _.get(this.props.router, 'location.query.bookId', '');
    const isFinished =
      _.get(this.props.router, 'location.query.isFinished', '') === 'TRUE'
        ? true
        : false;
    if (bookId) {
      this.props.bookingDetailActions
        .getBookingDetail(bookId, isFinished)
        .then((data) => {
          this.props.router.push("/cue");
          if (!data.res || data.error) {
            this.context.notification(
              'error',
              I18n.t('cue.Error_load_booking')
            );
          }
        });
    } else {
      this.context.notification('error', 'Please input bookId');
    }
  }

  render() {
    return <></>;
  }
}

BookingDetailFromUrl.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    bookingDetailActions: bindActionCreators(bookingDetailActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailFromUrl);
