import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import {
    Col,
    Row,
    FormGroup,
    Button,
    ButtonToolbar,
    Modal,
    OverlayTrigger,
    Tooltip,
    ButtonGroup,
} from 'react-bootstrap';
import * as deliveryRateActions from "../../../../actions/deliveryRateActions";
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import { Columns } from './tableHeaderData';
import TableActions from '../../../../components/table/tableAction/TableActions';
import AddEditDelivery from './AddEditDelivery';
import { BsSearch } from "react-icons/bs";

class DeliverySetting extends Component {
    constructor() {
        super();
        this.state = {
            dataList: null,
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            isSubmited: false,
            editable: false,
            tableHeight: 500,
            rowHeight: 50,
            footerData: {
                limit: 20,
                total: 0,
                page: 0,
            },
            str: ''
        };
    };

    componentDidMount() {
        this.updateDataList();
        const locationQuery = _.get(this.props.router, 'location.query', {});
        if (locationQuery.rateId) {
            this.setState({
                showDialog: true,
                dialogData: {
                    _id: locationQuery.rateId
                },
                editable: true
            })
        }
    };

    handleSearchChange = (e) => {
        let str = e.target.value;
        this.setState({ str: str });
    };

    handleSearchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.updateDataList();
        }
    };


    updateDataList = (activePage, limit) => {
        let params = {
            limit: limit || this.state.footerData.limit,
            page:
                activePage || activePage === 0
                    ? activePage
                    : this.state.footerData.page,
            fleetId: this.props.auth.selectedFleet.fleetId,
            search: this.state.str
        };

        this.props.deliveryRateActions.getAllDeliveryRates(params)
            .then(data => {
                if (data.ok && data.res) {
                    let { page, total, limit } = data.res;
                    this.setState({
                        dataList: data.res,
                        footerData: { page, total, limit },
                    });
                }
            });
    };

    handleAddButtonClick = () => {
        this.setState({
            showDialog: true,
            dialogData: null,
            editable: true
        });
    };

    handleUpdateRateSucess = (showDialog = false) => {
        this.setState({ showDialog, dialogData: null });
        this.updateDataList();
    };

    closeDialogForm = () => {
        this.setState({
            showDialog: false,
            dialogData: null,
            dialogChanged: false,
            showConfirm: false,
            editable: false,
            isSubmited: false
        });
    };

    handleMenuClick = (key, data) => {
        switch (key) {
            case "View": {
                this.setState({
                    showDialog: true,
                    dialogData: data,
                    editable: false
                });
                break;
            }
            case "Edit": {
                this.setState({
                    showDialog: true,
                    dialogData: data,
                    editable: true
                })
                break;
            }
            case "Status": {
                if (data.isActive) {
                    this.doDeactivate(data);
                } else {
                    this.doActivate(data);
                }
                break;
            }

            case "Delete": {
                this.setState({ showConfirm: true, dialogData: data });
                break;
            }

            default:
                break;
        }
    };

    doActivate = (data) => {
        this.props.deliveryRateActions
            .activeDeliveryRate({
                fleetId: this.props.auth.selectedFleet.fleetId,
                _id: data._id,
            })
            .then((data) => {
                if (data.ok) {
                    this.updateDataList();
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('deliveryRate.Update_delivery_rate_fail')
                        );
                    }
                }
            });
    };

    doDeactivate = (data) => {
        this.props.deliveryRateActions
            .deactiveDeliveryRate({
                fleetId: this.props.auth.selectedFleet.fleetId,
                _id: data._id,
            })
            .then((data) => {
                if (data.ok) {
                    this.updateDataList();
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('deliveryRate.Update_delivery_rate_fail')
                        );
                    }
                }
            });
    };

    handlePaginationSelect = (eventKey) => {
        if (this.state.showDialog && this.state.dialogData) {
        } else {
            this.updateDataList(eventKey);
        }
    };

    handleNumItemsPerPageChange = (e) => {
        let numItemsPerPage = parseInt(e);
        this.updateDataList(0, numItemsPerPage);
    };

    confirmDeleteFlat = () => {
        this.props.deliveryRateActions
            .deleteDeliveryRate({
                fleetId: this.props.auth.selectedFleet.fleetId,
                _id: this.state.dialogData._id,
            })
            .then((data) => {
                if (data.ok) {
                    this.closeDialogForm();
                    this.updateDataList();
                    this.context.notification(
                        'success',
                        I18n.t('deliveryRate.Delete_delivery_rate_success')
                    );
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('deliveryRate.Delete_delivery_rate_fail')
                        );
                    }
                }
            });
    };

    getTableColumns = () => {
        let tableColumns = Object.assign([], Columns);
        // custom data by column
        _.forEach(tableColumns, (col, index) => {
            switch (col.key) {
                case "name":
                    col.customCell = delivery => {
                        return (
                            <OverlayTrigger
                                overlay={<Tooltip id={"tooltip"}>{delivery.name}</Tooltip>}
                                placement="top"
                                delayShow={300}
                            >
                                <div
                                    className="clickable text-ellipsis"
                                    onClick={() => {
                                        this.handleMenuClick("View", delivery);
                                    }}
                                >
                                    {delivery.name}
                                </div>
                            </OverlayTrigger>
                        );
                    };
                    break;

                case "latestUpdate":
                    col.customCell = delivery => {
                        return (
                            <Moment
                                tz={this.props.auth.selectedFleet.timezone}
                                format="MMM/DD/YYYY"
                                date={delivery.latestUpdate}
                            />
                        );
                    };
                    break;

                case "deliveryStatus":
                    col.customCell = delivery => {
                        let statusStr, statusClass;
                        if (delivery && delivery.isActive) {
                            statusStr = <Translate value="delivery.Active" />;
                            statusClass = "Active";
                        } else {
                            if (delivery && !delivery.isActivate) {
                                statusStr = <Translate value="delivery.Inactive" />;
                                statusClass = "Inactive";
                            } else statusStr = null;
                        }
                        return (
                            <div className={statusClass}>
                                {!delivery.inUse &&
                                    (!this.props.permissions || this.props.permissions.actions) ? (
                                        <a
                                            onClick={() => {
                                                this.handleMenuClick("Status", delivery);
                                            }}
                                            href="javascript:void(0)"
                                        >
                                            {statusStr}
                                        </a>
                                    ) : (
                                        statusStr
                                    )}
                            </div>
                        );
                    };
                    break;

                case "actions":
                    col.customCell = (delivery, rowIndex) => {
                        if (!this.props.permissions || this.props.permissions.actions) {
                            let actionItems = [
                                {
                                    label: "delivery.Edit",
                                    eventKey: "Edit"
                                }
                            ];

                            if (!delivery.inUse) {
                                actionItems.push({
                                    label: delivery.isActive
                                        ? "delivery.Deactivate"
                                        : "delivery.Activate",
                                    eventKey: "Status"
                                });

                                if (!delivery.isActive) {
                                    actionItems.push({
                                        label: "delivery.Delete",
                                        eventKey: "Delete"
                                    });
                                }
                            }

                            return (
                                <TableActions
                                    rowIndex={rowIndex}
                                    rowData={delivery}
                                    onSelect={eventKey => {
                                        this.handleMenuClick(eventKey, delivery);
                                    }}
                                    totalRow={this.state.dataList.list.length}
                                    rowHeight={this.state.rowHeight}
                                    menuItems={actionItems}
                                    tableHeight={this.state.tableHeight}
                                />
                            );
                        } else {
                            return (
                                <a
                                    onClick={e => {
                                        this.handleMenuClick("View", delivery);
                                    }}
                                    href="javascript:void(0)"
                                    className="text-active"
                                >
                                    <Translate value="delivery.View" />
                                </a>
                            );
                        }
                    };
                    break;
            }
        });

        return tableColumns;
    };

    getTableHeight = () => {
        let verticalPadding = 10,
            toolbarMarginBottom = 10,
            toolbarheight = this.toobarContainer
                ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
                : 0,
            parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
        let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
        this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        return this.state.tableHeight;
    };


    render() {
        const bodyData =
            this.state.dataList && this.state.dataList.list
                ? this.state.dataList.list
                : [];

        const hasPermission =
            this.props.permissions && this.props.permissions.actions;

        return (
            <div className="content">
                {this.state.showConfirm ? (
                    <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <Translate value="deliveryRate.Delete_delivery_rate" />
                            </Modal.Title>
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={this.closeDialogForm}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Translate value="deliveryRate.Delete_confirm_delivery_rate" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className={'btn-save mr-md'}
                                onClick={this.confirmDeleteFlat}
                            >
                                <Translate value="deliveryRate.Yes" />
                            </Button>
                            <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                                <Translate value="deliveryRate.No" />
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) : (
                        ''
                    )}
                <ButtonToolbar
                    className="text-center header-button-group"
                    ref={(node) => (this.toobarContainer = node)}
                >
                    <ButtonGroup className="group-left">
                        <FormGroup className="search-format mb0">
                            <input
                                type="text"
                                className="form-control search-form"
                                value={this.state.str}
                                onKeyPress={this.handleSearchKeyPress}
                                onChange={this.handleSearchChange}
                                placeholder={I18n.t('General.search')}
                            />
                            <BsSearch className="search-icon" />
                        </FormGroup>
                        {hasPermission && (
                            <Button
                                className="btn-header text-add-header ml0"
                                onClick={this.handleAddButtonClick}
                            >
                                {' '}
                                <Translate value="deliveryRate.Add" />
                            </Button>
                        )}
                    </ButtonGroup>
                </ButtonToolbar>
                <div className="gridViewTable">
                    <StickyTable
                        columns={this.getTableColumns()}
                        bodyData={bodyData}
                        footerData={this.state.footerData}
                        handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.rowHeight}
                        getTableHeight={this.getTableHeight}
                    />
                </div>
                {this.state.showDialog ? (
                    <AddEditDelivery
                        closeDialogForm={this.closeDialogForm}
                        onSuccess={this.handleUpdateRateSucess}
                        rateInfo={this.state.dialogData}
                        editable={this.state.editable}
                    />
                ) : null}
            </div>
        );
    }
}

DeliverySetting.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        users: state.users,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deliveryRateActions: bindActionCreators(deliveryRateActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliverySetting);
