import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { FormGroup, FormControl, Form, InputGroup } from 'react-bootstrap';

import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import { Validator, ValidCase } from '../../../components/validator';
import { Validation } from '../../../utils/commonFunctions';
const MarketPlace = (props) => {
  const {
    formData = {},
    editable,
    valid,
    isSubmitted,
    ValidatorCallback,
    handleTextInputChange,
  } = props;
  return (
    <div>
      <FormGroupTitle>
        <Translate value="dispatchSetting.marketPlace" />
      </FormGroupTitle>
      <FormGroup
        className={
          !isSubmitted
            ? null
            : valid.marketplaceMaxDays === false
            ? 'error'
            : null
        }
      >
        <Form.Label>
          <Translate value="dispatchSetting.howFarDriverSeeBookingMarketplace" />
        </Form.Label>
        <FormGroup className={'qup-input-group'}>
          <InputGroup
            className={'single-addon-right' + (editable ? ' disabled' : '')}
          >
            <FormControl
              type="number"
              className={'form-custom number-no-spin'}
              value={formData.marketplaceMaxDays || ''}
              onChange={(e) => {
                handleTextInputChange('marketplaceMaxDays', e);
              }}
              disabled={editable}
              max={30}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <Translate value="dispatchSetting.Day" />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </FormGroup>
        <Validator id="marketplaceMaxDays" callback={ValidatorCallback}>
          <ValidCase
            hide={!isSubmitted}
            valid={Validation.isInRange(formData.marketplaceMaxDays, 1, 30)}
            message={I18n.t(
              'messages.commonMessages.value_must_between'
            ).format(1, 30)}
          />
        </Validator>
      </FormGroup>
    </div>
  );
};

export default MarketPlace;
