import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import copy from 'copy-to-clipboard';
import _ from 'lodash'
import {
  Col,
  Row,
  InputGroup,
  FormGroup,
  FormControl,
  Form,
  Button
} from 'react-bootstrap';
import {
  CCLiteCommonFunc,
  Validation
} from '../../../utils/commonFunctions';
import * as settingActions from "../../../actions/settingActions";
import { Validator, ValidCase } from '../../../components/validator';
import UploadFile from '../../../components/upload/upload';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import { ChromePicker } from 'react-color';
import '../settings.scss';

var _URL = window.URL || window.webkitURL;
class PWA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData || {
        showMainColor: false,
        hideWBInstallAppBtn: false,
        enableBookNow: true
      },
      valid: {},
      sizeValid: {
        icon192: true,
        icon512: true,
        logo: true,
        logoMenu: true,
        backgroundImage: true,
        backgroundImageDelivery: true,
      },
      displayColorPicker: {},
      files: {},
      isEnableParcelDelivery: false
    }
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleInputTextChange = this.handleInputTextChange.bind(this);
    this.ValidatorCallback = this.ValidatorCallback.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleOpenColorPicker = this.handleOpenColorPicker.bind(this);
    this.handleCloseColorPicker = this.handleCloseColorPicker.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  componentDidMount() {
    const { auth } = this.props
    this.props.settingActions
      .getPWASetting({ fleetId: auth.selectedFleet.fleetId })
      .then(data => {
        if (data.res && data.res.pwa && data.res.pwa && data.ok) {
          console.log('getPWASetting', data.res)
          const s3Host = _.get(this.props.auth, 'selectedFleet.s3Host')
          const { pwa } = data.res
          this.setState({
            formData: data.res.pwa,
            files: {
              icon192: pwa.icon192 ? s3Host + pwa.icon192 : '',
              icon512: pwa.icon512 ? s3Host + pwa.icon512 : '',
              logo: pwa.logo ? s3Host + pwa.logo : '',
              logoMenu: pwa.logoMenu ? s3Host + pwa.logoMenu : '',
              backgroundImage: pwa.backgroundImage ? s3Host + pwa.backgroundImage : '',
              backgroundImageDelivery: pwa.backgroundImageDelivery ? s3Host + pwa.backgroundImageDelivery : ''
            }
          });
        }
      });
    const enableDelivery = (auth.selectedFleet.delivery && auth.selectedFleet.delivery.enable)
    this.setState({
      isEnableParcelDelivery: enableDelivery
    })
  }

  handleOpenColorPicker = (key) => {
    this.state.displayColorPicker[key] = true;
    this.setState(this.state);
  };

  handleCloseColorPicker = (key) => {
    this.state.displayColorPicker[key] = false;
    this.setState(this.state);
  };

  handleImageChange(key, size, isRequiredExactFile, data, error) {
    let result = "",
      file = null, img, _self = this;

    if (data) {
      result = data.result;
      file = data.file;
      if (isRequiredExactFile) {
        img = new Image();
        img.onload = function () {
          if (this.width === size && this.height === size) {
            _self.state.sizeValid[key] = true;
          } else {
            _self.state.sizeValid[key] = false;
          }
          _self.setState(_self.state);
        };
        img.src = _URL.createObjectURL(file);
      } else {
        this.state.sizeValid[key] = true;
      }
    }
    this.state.formData[key] = result;
    this.state.files[key] = file;
    this.state.isChanged = true;
    this.setState(this.state);
  }

  handleInputTextChange(key, e) {
    this.state.formData[key] = e.target.value;
    this.state.isChanged = true;
    this.setState(this.state);
  }

  handleColorChange(key, color) {
    console.log('handleColorChange', color);
    this.state.formData[key] = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    this.state.isChanged = true;
    this.setState(this.state);
  }

  handleCheckBoxChange(key, e) {
    this.state.formData[key] = e.target.checked;
    this.state.isChanged = true;
    this.setState(this.state);
  }

  ValidatorCallback(id, valid) {
    if (this.state && this.state.valid[id] != valid) {
      this.state.valid[id] = valid;
      this.setState({ valid: this.state.valid });
    }
  }

  handleSaveClick() {
    if (!this.state.isSubmited) {
      this.setState({ isSubmited: true });
    }
    if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
      return;
    }
    let ob = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      data: this.state.formData,
    };
    this.props.settingActions.savePWASetting(ob).then(data => {
      if (data.ok) {
        this.setState({ isChanged: false });
        this.context.notification(
          "success",
          I18n.t("pwaSettings.success")
        );
      } else {
        if (data.error) {
          this.context.notification(
            "error",
            I18n.t("errors." + data.error.errorCode)
          );
        } else {
          this.context.notification(
            "error",
            I18n.t("pwaSettings.failed")
          );
        }
      }
    });
  }

  renderUploadFile(key, size, isRequiredExactFile) {
    const editable = this.props.permissions && !this.props.permissions.actions;
    let fileName = '';
    const { files } = this.state;
    if (
      files[key] &&
      files[key].length > 0
    ) {
      let splitUrl = files[key].split('/');
      fileName = splitUrl[splitUrl.length - 1];
    }

    return (
      <FormGroup
        className={
          !this.state.isSubmited
            ? null
            : this.state.valid[key] === false
              ? 'error'
              : null
        }
      >
        <Form.Label>
          <Translate value={`pwaSettings.${key}`} />
          <span className="require">*</span>
        </Form.Label>
        <div>
          {files[key] &&
            typeof files[key] === 'string' ? (
            <a
              href={files[key]}
              className="text-active"
              target="_blank"
            >
              <Translate value="driver.Click_here_to_view" />
            </a>
          ) : null}
        </div>
        <UploadFile
          id={key}
          name={key}
          disabled={editable}
          accepts="image/*"
          className="pwa-upload"
          onlyImage
          onChange={(data, error) => {
            this.handleImageChange(
              key,
              size,
              isRequiredExactFile,
              data,
              error
            );
          }}
          existFile={
            fileName
              ? {
                fileName,
                onRemove: () => { this.handleImageChange(key) }
              }
              : null
          }
        />
        <Validator
          id={key}
          callback={this.ValidatorCallback}
        >
          <ValidCase
            valid={
              !Validation.isStringEmpty(
                this.state.files[key]
              )
            }
            message={I18n.t(
              'messages.commonMessages.Required_field'
            )}
            hide={!this.state.isSubmited}
          />
          <ValidCase
            valid={this.state.sizeValid[key]}
            message={I18n.t('pwaSettings.errorUploadFileSize').format(
              size
            )}
            hide={!this.state.isSubmited}
          />
        </Validator>
      </FormGroup>
    )
  }

  renderInputText(key) {
    const editable = this.props.permissions && !this.props.permissions.actions;
    return (
      <FormGroup
        className={
          !this.state.isSubmited
            ? null
            : this.state.valid[key] === false
              ? 'error'
              : null
        }
      >
        <Form.Label>
          <Translate value={`pwaSettings.${key}`} />
          <span className="require">*</span>
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          onChange={(e) => this.handleInputTextChange(key, e)}
          value={
            this.state.formData
              ? this.state.formData[key]
              : ''
          }
          placeholder={I18n.t(`pwaSettings.${key}`)}
          disabled={editable}
        />
        <Validator
          id={key}
          callback={this.ValidatorCallback}
        >
          <ValidCase
            valid={
              !Validation.isStringEmpty(
                this.state.formData[key]
              )
            }
            message={I18n.t(
              'messages.commonMessages.Required_field'
            )}
            hide={!this.state.isSubmited}
          />
        </Validator>
      </FormGroup>)
  }
  renderLabelText(key) {
    let text_value = this.state?.formData?.[key] ? this.state?.formData?.[key] : ''
    if(key === 'pwaLink'
      && _.get(this.state.formData, 'customDomain', false)
    ) {
      text_value = `${_.get(this.state.formData, 'customUrl', '')}/?f=${_.get(
        this.props.auth,
        'selectedFleet.fleetToken',
        ''
      )}`;
    }
    return (
      <FormGroup>
        <Form.Label>
          <Translate value={`pwaSettings.${key}`} />
        </Form.Label>
        <FormGroup className="qup-input-group"> 
          <InputGroup className="single-addon-right">
              <FormControl
                  className="form-custom"
                  type="text"
                  value={text_value}
                  disabled={true}
              />
              <Button
                  className="copy-button"
                  onClick={() => { handleCopyClick(text_value) }}
              >
                  <i class="fa fa-copy" /> Copy
              </Button>
          </InputGroup>
        </FormGroup>
      </FormGroup>
    )
  }

  handleChangeTrackId = (key, e) => {
    this.setState({
      isChanged: true,
      formData: {
        ...this.state.formData,
        adsTracking: {
          ...(this.state?.formData?.adsTracking || {}),
          [key]: e.target.value
        }
      }
    })
  }

  renderAdsTracking = (key) => {
    const editable = this.props.permissions && !this.props.permissions.actions;
    return (
      <FormGroup>
        <Form.Label>
          <Translate value={`pwaSettings.${key}`} />
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          value={this.state.formData ? this.state?.formData?.adsTracking?.[key] : ''}
          placeholder={I18n.t(`pwaSettings.placeholder${key}`)}
          onChange={(e) => this.handleChangeTrackId(key, e)}
          disabled={editable}
        />
      </FormGroup>
    )
  }

  renderColorPicker(key) {
    const editable = this.props.permissions && !this.props.permissions.actions;
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }
    // let colorStr = '';
    // if (this.state.formData && this.state.formData[key] && this.state.formData[key].r) {
    //   colorStr = `rgba(${this.state.formData[key].r},${this.state.formData[key].g},${this.state.formData[key].b},${this.state.formData[key].a})`;
    // }

    return (
      <FormGroup
        className={
          !this.state.isSubmited
            ? null
            : this.state.valid[key] === false
              ? 'error'
              : null
        }
      >
        <Form.Label>
          <Translate value={`pwaSettings.${key}`} />
          <span className="require">*</span>
        </Form.Label>
        <FormControl
          type="text"
          className={'form-custom'}
          value={this.state.formData ? this.state.formData[key] : ''}
          onClick={() => { this.handleOpenColorPicker(key) }}
          placeholder={I18n.t(`pwaSettings.${key}`)}
          disabled={editable}
        />
        {this.state.displayColorPicker[key] ? <div style={popover}>
          <div style={cover} onClick={() => { this.handleCloseColorPicker(key) }} />
          <ChromePicker
            color={(this.state.formData && this.state.formData[key]) ? this.state.formData[key] : "#fff"}
            onChange={(color, event) => this.handleColorChange(key, color)}
          />
        </div> : null}
        <Validator
          id={key}
          callback={this.ValidatorCallback}
        >
          <ValidCase
            valid={
              !Validation.isStringEmpty(
                this.state.formData[key]
              )
            }
            message={I18n.t(
              'messages.commonMessages.Required_field'
            )}
            hide={!this.state.isSubmited}
          />
        </Validator>
      </FormGroup>)
  }

  renderCheckBox(key) {
    const editable = this.props.permissions && !this.props.permissions.actions;
    return (
      <FormGroup>
        <CcCheckbox
          disabled={editable}
          checked={this.state.formData[key] || false}
          onChange={(e) => this.handleCheckBoxChange(key, e)}
          text={I18n.t(`pwaSettings.${key}`)}
        />
      </FormGroup>)
  }

  handleServiceCBChange = (key, e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: e.target.checked,
      },
      isChanged: true,
    });
  };

  renderEnableService = () => {
    const editable = this.props.permissions && !this.props.permissions.actions;
    const {
      enableBookNow = true,
      enableBookLater = true,
      enableHideRecipient = false,
      enableGuestCheckout = false,
    } = this.state.formData;
    return (
      <div>
        <FormGroup>
          <CcCheckbox
            disabled={editable}
            checked={enableBookNow}
            onChange={(e) => this.handleServiceCBChange('enableBookNow', e)}
            text={I18n.t(`pwaSettings.${'enableBookNow'}`)}
          />
        </FormGroup>
        <FormGroup>
          <CcCheckbox
            disabled={editable}
            checked={enableBookLater}
            onChange={(e) => this.handleCheckBoxChange('enableBookLater', e)}
            text={I18n.t(`pwaSettings.${'enableBookLater'}`)}
          />
        </FormGroup>
        <FormGroup>
          <CcCheckbox
            disabled={editable}
            checked={enableGuestCheckout}
            onChange={(e) => this.handleServiceCBChange('enableGuestCheckout', e)}
            text={I18n.t(`pwaSettings.${'enableGuestCheckout'}`)}
          />
        </FormGroup>
        {
          this.state.isEnableParcelDelivery &&
          <FormGroup>
            <CcCheckbox
              disabled={editable}
              checked={enableHideRecipient}
              onChange={(e) => this.handleCheckBoxChange('enableHideRecipient', e)}
              text={I18n.t(`pwaSettings.enableHideRecipient`)}
            />
          </FormGroup>
        }
      </div>
    );
  };

  render() {
    const editable = this.props.permissions && !this.props.permissions.actions;
    return (
      <div
        className="content custom-form overflow-auto general-setting"
        ref={ref => (this.refTabs = ref)}
      >
        <Row>
          <Col md={6} xs={12}>
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="pwaSettings.iconName" />
              </FormGroupTitle>
              {this.renderInputText('longName')}
              {this.renderInputText('shortName')}
              {this.renderUploadFile('icon192', 192, true)}
              {this.renderUploadFile('icon512')}
              {/* {this.renderCheckBox('hideWBInstallAppBtn')} */}
              
            </div>
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="pwaSettings.pwa" />
              </FormGroupTitle>
              {this.renderLabelText('pwaLink')}
              {
                this.props.auth.selectedFleet?.moduleSettings?.mDispatcher
                && this.renderLabelText('partnerLink')
              }
              {
                this.props.auth.selectedFleet?.pwa?.enableDriverPWA
                && this.renderLabelText('pwaDriverLink')
              }
              {this.renderLabelText('providerSignUp')}
              {this.renderEnableService()}
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="group-general">
              <FormGroupTitle>
                <Translate value="pwaSettings.logoBackgroundColor" />
              </FormGroupTitle>
              {this.renderUploadFile('logo')}
              {this.renderUploadFile('logoMenu')}
              {this.renderUploadFile('backgroundImage')}
              {
                this.state.isEnableParcelDelivery &&
                this.renderUploadFile('backgroundImageDelivery')
              }
              {this.renderColorPicker('mainColor')}
              {this.renderColorPicker('secondaryColor')}
              {this.renderCheckBox('showMainColor')}
            </div>
            {
              this.state?.formData?.adsTracking?.enable && (
                <div className="group-general">
                  <FormGroupTitle>
                    <Translate value="pwaSettings.AdsTracking" />
                  </FormGroupTitle>
                  {this.renderAdsTracking('googleId')}
                  {this.renderAdsTracking('metaId')}
                </div>
              )
            }
          </Col>
        </Row>
        <FormGroup className="pv-md">
          {!editable ? (
            <Button
              className="btn-save"
              disabled={!this.state.isChanged}
              onClick={this.handleSaveClick}
            >
              <Translate value="appBanner.Save" />
            </Button>
          ) : (
            ""
          )}
        </FormGroup>
      </div>
    );
  }
}

const handleCopyClick = (contentId) => {
  copy(contentId)
}
PWA.contextTypes = {
  notification: PropTypes.func
};
function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PWA);
