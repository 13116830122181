import PropTypes from 'prop-types';
import React from 'react';

const IconvsTextBtn = ({ text, icon, heightIcon }) => {
  return (
    <span
      style={{
        color: '#04BE76',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'column',
      }}
    >
      <img src={icon} style={{ display: 'block', marginBottom: '4px', height: heightIcon?? '24px' }} />
      <span>{text}</span>
    </span>
  );
};

IconvsTextBtn.defaultProps = {
  text: '',
  icon: '',
};

IconvsTextBtn.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default IconvsTextBtn;
