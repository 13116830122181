import React, { useState } from 'react';
import Select from 'react-select';
import './SelectMulti.scss';

const styles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#24272E',
    borderColor: state.isFocused ? '#04BE76' : '#24272E',
    boxShadow: state.isFocused ? '0 0 1px #04BE76' : '',
    '&:hover': {
      borderColor: '#04BE76',
    },
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#2D3039',
    borderColor: '#2D3039',
    color: 'white',
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    color: 'white',
  }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: '#24272E',
    color: 'white',
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#2D3039' : '#24272E',
  }),
  multiValueRemove: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.hover ? '#2D3039' : '#2D3039',
    '&:hover': {
      backgroundColor: '#04BE76',
      color: 'white',
    },
  }),

  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: 'white',
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    color: 'white',
  }),
};
export default function SelectMulti(props) {
  const customFilterOption = (option, inputValue) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase());
  
  return (
    <Select
      {...props}
      styles={styles}
      closeMenuOnSelect={!props.isMulti}
      filterOption={customFilterOption}
    />
  );
}
