export const driversListColumn = [
    {
        key: 'order',
        label: 'queuingArea.drivers.order',
        fixed: true,
        width: 80
    },
    {
        key: 'fullName',
        label: 'queuingArea.drivers.Driver_name',
        fixed: true,
        textEllipsis: true
    },
    {
        key: 'phone',
        label: 'queuingArea.drivers.Phone_Number',
    },
    {
        key: 'vehicle.plateNumber',
        label: 'queuingArea.drivers.driverLicenseNumber',
    },
    {
        key: 'vehicle.vehicleType',
        label: 'queuingArea.drivers.Car_Type',
        textEllipsis: true,
        width: 150
    },
    {
        key: 'queueCheckInTime',
        label: 'queuingArea.drivers.checkInTime',
        width: 150
    },
    {
        key: 'queueScore',
        label: 'queuingArea.drivers.timestampScore',
        width: 150
    },
    {
        key: 'duration',
        label: 'queuingArea.drivers.duration',
        textEllipsis: true,
        width: 150
    },
    {
        key: "actions",
        label: "queuingArea.Actions",
        width: 100,
        align: "right",
        cellClass: "table-actions"
    }
]