export const Columns = [
    {
      key: "userName",
      label: "user.Username",
      headerClass: "fullNameSort",
      textEllipsis: true,
      cellClass: "cell-align-toolbar",
      headerClass: "header-align-toolbar",
      fixed: true
    },
    {
      key: "fullName",
      label: "user.Name",
      textEllipsis: true
    },
    {
      key: "agentId",
      label: "user.AgendId",
      textEllipsis: true,
    },
    {
      key: "phone",
      label: "user.Phone_number"
    },
    {
      key: "email",
      label: "user.Email",
      textEllipsis: true
    },
    {
      key: "roleName",
      label: "user.Permission",
      textEllipsis: true
    },
    {
      key: "userStatus",
      label: "user.Status",
      width: 100,
    },
    {
      key: "actions",
      label: "user.Actions",
      width: 100,
      align: "right",
      cellClass: "table-actions"
    }
  ];
