import React from 'react';
import styles from './styles/create.module.scss';
import FormVehicle from './components/formVehicle';
import HeaderGr from './components/headerGr';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

const DetailVehicle = (props) => {
  return (
    <div className={styles['layout']}>
      <HeaderGr
        grRoute={[I18n.t('carSetting.Vehicle'), I18n.t('carSetting.Details')]}
        title={I18n.t('carSetting.detailVehicle')}
      />

      <FormVehicle
        action={props?.location?.state?.edit ? 'edit' : 'view'}
        navigate={props.router}
        vehicleId={props.params.vehicleId}
      />
    </div>
  );
};

export default connect()(DetailVehicle);
