import React from 'react';
import Promise from 'bluebird';
import BaseReport from './../base';
import * as fieldMappings from './fields';
import { reportMDispatcherApi } from '../../../constants/ApiConfigs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingActions from './../../../actions/settingActions';
import _ from 'lodash';
class ReportMDispatcher extends React.Component {
  constructor() {
    super();
    this.state = {
      mDispatcherType: [],
      mDispatcher: [],
    };
  }

  UNSAFE_componentWillMount() {
    Promise.all([
      this.props.actions.fetchMDispatcherTypesForSelection(
        this.props.selectedFleet.fleetId
      ),
      this.props.actions.fetchMDispatcherNamesForSelection({
        fleetId: this.props.selectedFleet.fleetId,
        isActive: true,
      }),
    ]).spread((mDispatcherTypeRes, mDispatcherRes) => {
      this.setState({
        mDispatcherType: mDispatcherTypeRes.res,
        mDispatcher: mDispatcherRes.res,
      });
    });
  }

  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
    const report = _.find(modules, { name: 'Report' });
    let checkExport = _.findIndex(report.actions, {
      name: 'mDispatcher',
      Export: true,
    });
    let checkOwner =
      !this.props.user.isAdmin &&
      typeof this.props.user.roles.fleetId !== 'undefined'
        ? this.props.user.roles.fleetId.ownerId === this.props.user._id
          ? true
          : false
        : true;
    checkExport =
      this.props.user.isAdmin || checkOwner
        ? true
        : checkExport === -1
        ? false
        : true;
    if (
      !this.props.user.isAdmin &&
      this.props.selectedFleet.fleetId === 'applecabssouth'
    ) {
      checkExport = false;
    }
    return (
      <div className="content">
        <BaseReport
          tittle="Promotion"
          fieldMappings={fieldMappings}
          apiurl={reportMDispatcherApi}
          dateRange
          finishedStatus
          mDispatcherType={this.state.mDispatcherType}
          mDispatcher={this.state.mDispatcher}
          paymentMethod
          currency
          company
          search="report.query.search.mDispatcher"
          noexport={!checkExport}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(settingActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportMDispatcher);
