import moment from 'moment-timezone';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Chart} from "react-chartjs-2";
import {I18n} from "react-redux-i18n";

const theHelp = Chart.helpers;
export const doughnutOptions = {
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  maintainAspectRatio: false,
  title: {
    display: true,
    position: 'top',
    padding: 20,
  },
  legend: {
    display: true,
    position: 'right',
    labels: {
      generateLabels: function (chart) {
        var data = chart.data;
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function (label, i) {
            var meta = chart.getDatasetMeta(0);
            var ds = data.datasets[0];
            var arc = meta.data[i];
            var custom = (arc && arc.custom) || {};
            var getValueAtIndexOrDefault = theHelp.getValueAtIndexOrDefault;
            var arcOpts = chart.options.elements.arc;
            var fill = custom.backgroundColor
              ? custom.backgroundColor
              : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            var stroke = custom.borderColor
              ? custom.borderColor
              : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
            var bw = custom.borderWidth
              ? custom.borderWidth
              : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);
            var total = ds.data.reduce(function (previousValue, currentValue, currentIndex, array) {
              return previousValue + currentValue;
            });
            return {
              // And finally :
              text: I18n.t('dashboard.' + label) + ' (' + ds.data[i] + ')',
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i,
            };
          });
        }
        return [];
      },
      usePointStyle: true,
      fontSize: 12,
      boxWidth: 20,
      fontColor: '#e6e6e6',
    },
  },
  onAnimationProgress: (e) => {
    console.log('onAnimationProgress', e);
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        //get the concerned dataset
        var dataset = data.datasets[tooltipItem.datasetIndex];
        //calculate the total of this data set
        var total = dataset.data.reduce(
          function (previousValue, currentValue, currentIndex, array) {
            return previousValue + currentValue;
          },
        );
        //get the current items value
        var currentValue = dataset.data[tooltipItem.index];
        //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
        var percentage = Math.floor((currentValue / total) * 100 + 0.5);

        return currentValue + ' (' + percentage + '%)';
      },
    },
  },
};

export function validDate(date) {
  let current = moment();
  let startDate = moment('2018-08', 'YYYY-MM');
  return !!(date.isAfter(startDate) && current.isAfter(date));
}

export function renderHintLabel(label, hint, placement = 'top') {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={'tooltip'}>{hint}</Tooltip>}
      placement={placement}
      delayShow={300}
      delayHide={150}
    >
      <span>{label}</span>
    </OverlayTrigger>
  );
}
