import React, { useEffect, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { groupDaysToYear, checkOldNewRecurringDays } from '../../utils/commonFunctions'

const DateTextRecurringUI = ({ newDays = [], oldDays = [], isBookDetails = false }) => {
  const [daysText, setDayText] = useState([])
  useEffect(() => {
    const daysObject = checkOldNewRecurringDays(oldDays, newDays)
    setDayText(groupDaysToYear(daysObject))
  }, [newDays, oldDays])
  return (
    <div>
      {daysText.map(date => {
        return (
          <div key={date.year} className="blockYear">
            <p className="pYear">{date.year}</p>
            {
              date.month && date.month.map((monthObj) => (
                <p key={monthObj.index}>
                  <span className="spMonth">{moment().month(monthObj.index).format("MMMM")}: </span> 
                  {monthObj.days && monthObj.days.map((ob, id) => {
                    return <span key={`${id}${ob.type}`} className={`typeRecurring ${ isBookDetails ? ob.type : 'exist' }`}>
                      {` ${moment(ob.value).format('Do')}${id+1 != monthObj.days.length ? ',' : ''}`}
                    </span>
                  })}
                </p>
              ))
            }
          </div>
        )
      })}
    </div>
  )
}

DateTextRecurringUI.defaultProps = {
  newDays: [],
  oldDays: [], // chỉ cần khi là booking detail
  isBookDetails: false
}
export default DateTextRecurringUI