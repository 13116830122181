import React from 'react'

export default class ReportResult extends React.Component {
  render() {
    return (
      <div className='fill'>
        {this.props.exportButton}
        {this.props.buttons}
        <div className='header'>
          {this.props.paginationBar}
        </div>
        {this.props.table}
        <div className='footer'>
          {this.props.paginationBar}
        </div>
      </div>
    )
  }
}