import moment from 'moment';
import 'moment-timezone';
import React from 'react';
import _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import CCDropDown from '../../../components/dropdown/ccDropDown';

export const dateButtons = [
  {
    value: 'thisYear',
    key: 'thisYear',
    label: 'report.query.thisYear'
  },
  {
    value: 'thisMonth',
    key: 'thisMonth',
    label: 'report.query.thisMonth'
  },
  {
    value: 'lastMonth',
    key: 'lastMonth',
    label: 'report.query.lastMonth'
  },
  {
    value: 'past7Days',
    key: 'past7Days',
    label: 'report.query.past7Days'
  },
  // {
  //   value: 'lastWeek',
  //   key: 'lastWeek',
  //   label: 'report.query.lastWeek'
  // },
  {
    value: 'today',
    key: 'today',
    label: 'report.query.today'
  },
  {
    value: 'custom',
    key: 'custom',
    label: 'report.query.custom'
  }
];

export default class DateRange extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeDateButtons(props.selectedButton);
  }

  handleChangeDateButtons = buttonType => {
    let newState = {};
    /* const { locale: localeCode = 'en' } = this.props || {}; */
    const localeCode = 'vi';
    switch (buttonType) {
      case 'today':
        newState = {
          fromDate: moment()
            .startOf('day')
            .toDate(),
          toDate: moment()
            .endOf('day')
            .toDate()
        };
        break;
      case 'thisMonth':
        newState = {
          fromDate: moment()
            .startOf('month')
            .toDate(),
          toDate: moment()
            .endOf('month')
            .toDate()
        };
        break;
      case 'lastMonth':
        newState = {
          fromDate: moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          toDate: moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate()
        };
        break;
      case 'past30Days':
        newState = {
          fromDate: moment()
            .subtract(29, 'days')
            .startOf('day')
            .toDate(),
          toDate: moment()
            .endOf('day')
            .toDate()
        };
        break;
      case 'past7Days':
        newState = {
          fromDate: moment()
            .subtract(6, 'days')
            .startOf('day')
            .toDate(),
          toDate: moment()
            .endOf('day')
            .toDate()
        };
        break;
      case 'thisYear':
        newState = {
          fromDate: moment()
            .startOf('year')
            .toDate(),
          toDate: moment()
            .endOf('year')
            .toDate()
        };
        break;
      case 'thisWeek':
        newState = {
          fromDate: moment()
            .locale(localeCode)
            .startOf('week')
            .startOf('day')
            .toDate(),
          toDate: moment()
            .locale(localeCode)
            .endOf('week')
            .endOf('day')
            .toDate()
        };
        break;
      case 'lastWeek':
        newState = {
          fromDate: moment()
            .locale(localeCode)
            .subtract(1, 'week')
            .startOf('week')
            .startOf('day')
            .toDate(),
          toDate: moment()
            .locale(localeCode)
            .subtract(1, 'week')
            .endOf('week')
            .endOf('day')
            .toDate()
        };
        break;
      case 'custom':
        this.props.customRangeSelectCallback();
        break;
      default:
        break;
    }
    this.props.setDateRange({ ...newState, selectedButton: buttonType });
  };

  getTitle = () => {
    const selectedItem = _.find(
      dateButtons,
      item => item.value === this.props.selectedButton
    );
    return (
      <span className="dropdown-title">
        {selectedItem ? I18n.t(selectedItem.label) : '--:--'}
      </span>
    );
  };

  render() {
    return (
      <CCDropDown
        id="date-range-dropdown"
        items={dateButtons}
        title="Select"
        customTitle={this.getTitle()}
        selectedItems={
          this.props.selectedButton ? [this.props.selectedButton] : []
        }
        valueKey="value"
        labelKey="label"
        onSelect={this.handleChangeDateButtons}
        className="daterange-dropdown text-active"
        disabled={this.props.disabled}
      />
    );
  }
}
