import {
    USERS_REQUEST,
    USERS_SUCCESS,
    USERS_FAILURE,
    USER_DELETE_SUCCESS,
    USER_ROLE_SUCCESS
} from '../actions/userActions'

export default function users(state={},action={}){
    switch(action.type){
        case USERS_REQUEST:{
            return state;
        }
        case USERS_SUCCESS:{
            return Object.assign(state,action.data);
        }
        case USERS_FAILURE:{
            return state;
        }
        case USER_ROLE_SUCCESS:{
            state.roles = action.data;
            return {...state,roles:action.data}
        }
        default: return state;
    }
}