import styled from 'styled-components';

export const LocationItemFormWrapper = styled.div`
  background-color: #2d313a;
  padding: 10px;
  border-radius: 5px;

  .locationItem__btnCheckMap {
    color: #F2D45F;

    :focus, :hover {
      color: #F2D45F;
      opacity: 0.5; 
    }
  }
  .locationItem__btnRemove {
    color: #DE6C59;
    
    :focus, :hover {
      color: #DE6C59;
      opacity: 0.5; 
    }
  }

  .locationItem__footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const LocationListFormWrapper = styled.div`
  .locationList__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
`;
