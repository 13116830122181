import React, { useMemo } from 'react';
import { I18n } from 'react-redux-i18n';
import copy from 'copy-to-clipboard';
import trackLinkGreen from '../../../../../assets/images/icons/trackLinkGreen.svg';
import PropTypes from 'prop-types';
import CopyNewTabBtn from '../components/CopyNewTabBtn';

const TrackLink = ({ bookInfo, selectedFleet }) => {
  const getTrackUrl = () => {
    const trackLinkSetting = selectedFleet.trackLink;
    const token = bookInfo?.token;
    return trackLinkSetting.slice(-1) === '/'
      ? `${trackLinkSetting}${token}`
      : `${trackLinkSetting}/${token}`;
  };

  const trackUrlFull = useMemo(() => getTrackUrl(), []);

  return (
    <CopyNewTabBtn
      icon={trackLinkGreen}
      text={I18n.t('bookingdetail.ViewTrackLink')}
      link={trackUrlFull}
    />
  );
};

TrackLink.defaultProps = {
  bookInfo: {},
  selectedFleet: {},
  delivery: false,
};

TrackLink.propTypes = {
  bookInfo: PropTypes.object.isRequired,
  selectedFleet: PropTypes.object.isRequired,
};

export default TrackLink;
