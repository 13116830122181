import {
  SAVE_INTERVAL,
  REMOVE_INTERVAL
} from "../actions/reportActions"
const initState = {
  intervals: []
}
export default function report(state = initState, action = {}) {
  switch (action.type) {
    case SAVE_INTERVAL: {
      let intervals = (state.intervals || []).concat(action.interval)
      return Object.assign({}, state, { intervals })
    }
    case REMOVE_INTERVAL: {
      let index = (state.intervals || []).findIndex(interval => interval == action.interval)
      if (index > -1) {
        let intervals = (state.intervals || []).splice(index, 1)
        return Object.assign({}, state, { intervals })
      } else {
        return state
      }
    }
    default: return state;
  }
}