import ReportUtils from './../base/utils'

export const fields = [{
  key: "date",
  label: "report.result.revenueFleet.date",
  summary: "date",
  mutate: value => ReportUtils.formatTime(value, {}, 'ddd MM/DD/YYYY')
}, {
  key: "fareRevenue",
  label: "report.result.revenueFleet.fareRevenue",
  summary: "fareRevenue"
}, {
  key: "trip",
  label: "report.result.revenueFleet.trip",
  summary: "trip"
}, {
  key: "avgFareTrip",
  label: "report.result.revenueFleet.avgFareTrip",
  summary: "avgFareTrip"
}, {
  key: "drivers",
  label: "report.result.revenueFleet.drivers",
  summary: "drivers"
}, {
  key: "avgTripPerDriver",
  label: "report.result.revenueFleet.avgTripPerDriver",
  summary: "avgTripPerDriver"
}, {
  key: "dispatcher",
  label: "report.result.revenueFleet.dispatcher",
  summary: "dispatcher"
}]

export const fareFields = [
  "fareRevenue",
  "avgFareTrip"
]