import React, { PureComponent } from 'react';
import _ from 'lodash';
import currencyFormatter from 'currency-formatter';
import { roundOff } from '../../../utils/commonFunctions';

export default class EstimatedFareColumn extends PureComponent {
    render() {
        const { trip, auth } = this.props;
        let estimateFare = '';
        const etaFare = _.get(trip, 'estimate.fare', 0);
        const etaFareRoundOff = roundOff(etaFare, trip.currencyISO, auth.selectedFleet.rounding);
        estimateFare = trip.estimate.fare
            ? currencyFormatter.format(etaFareRoundOff, {
                code: trip.currencyISO
            })
            : '';
        return (
            <div>
                {estimateFare || parseInt(estimateFare) === 0 ? <p>{estimateFare}</p> : null}
            </div>
        );
    }
}
