import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { i18nReducer } from 'react-redux-i18n';
// our reducers
import { routerReducer } from 'react-router-redux';
import menuHandle from '../reducers/AppMenuReducer';
import auth from '../reducers/auth';
import cue from '../reducers/cueReducer';
import commonData from '../reducers/commonDataReducer';
import bookingDetail from '../reducers/bookingDetailReducer';
import newBooking from '../reducers/newbookingReducer';
import customerAutocomplete from '../reducers/customerAutoCompleteReducer';
import corporateCompany from '../reducers/corporateCompanyReducer';
import paymentMethod from '../reducers/paymentMethodReducer';
import driverAutocomplete from '../reducers/driverAutocompleteReducer';
import socket from '../reducers/socketReducer';
import etaFare from '../reducers/etaFareReducer';
import loadingBar from '../reducers/loadingBarReducer';
import map from '../reducers/mapReducer';
import settings from '../reducers/settingReducer';
import users from '../reducers/usersReducer';
import reservationReducer from '../reducers/reservationReducer';
import callcenterSupport from '../reducers/callCenterSupportReducer';
import report from '../reducers/reportReducer';
import notificationReducers from '../reducers/notificationReducers';
import intercityBooking from '../reducers/intercityBookingReducer';
import trips from '../reducers/tripReducer';
import shuttleBooking from '../reducers/shuttleReducer';
import importBookings from '../reducers/importBookingsReducer';
import deliveryBooking from '../reducers/deliveryReducer'
import serviceFee from '../reducers/serviceFeeReducer'

const logger = createLogger();
// list all reducers here for conbination
const rootReducer = combineReducers({
  menuHandle,
  auth,
  Intl,
  cue,
  commonData,
  bookingDetail,
  newBooking,
  customerAutocomplete,
  corporateCompany,
  paymentMethod,
  driverAutocomplete,
  etaFare,
  socket,
  loadingBar,
  map,
  settings,
  users,
  report,
  callcenterSupport,
  routing: routerReducer,
  i18n: i18nReducer,
  reservations: reservationReducer,
  notificationReducers,
  intercityBooking,
  trips,
  shuttleBooking,
  importBookings,
  deliveryBooking,
  serviceFee
});

const initialState = {};

export default function configureStore () {
  let store;
  const middlewares = [];
  middlewares.push(thunkMiddleware);
  (process.env.REACT_APP_DEV_MODE === 'true') && middlewares.push(logger);

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(...middlewares),
        window.devToolsExtension ? window.devToolsExtension() : f => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(...middlewares), f => f)
    );
  }
  return store;
}
