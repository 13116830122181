import React from 'react';
import CCDropDown from '../../../components/dropdown/ccDropDown';

export const statusItems = [
  {
    value: 'completed',
    key: 'completed',
    label: 'smartData.status.completed'
  },
  {
    value: 'noShow',
    key: 'noShow',
    label: 'smartData.status.noShow'
  },
  {
    value: 'incident',
    key: 'incident',
    label: 'smartData.status.incident'
  },
  {
    value: 'canceled',
    key: 'canceled',
    label: 'smartData.status.canceled'
  },
  {
    value: 'accepted',
    key: 'accepted',
    label: 'smartData.status.accepted'
  },
  {
    value: 'reject',
    key: 'reject',
    label: 'smartData.status.reject'
  },
  {
    value: 'ignore',
    key: 'ignore',
    label: 'smartData.status.ignore'
  },
  {
    value: 'earning',
    key: 'earning',
    label: 'smartData.status.earning'
  }
];

export default function (props) {
  return (
    <CCDropDown
      id="date-range-dropdown"
      items={statusItems}
      title=""
      selectedItems={props.selectedItem ? [props.selectedItem] : []}
      valueKey="value"
      labelKey="label"
      onSelect={props.onSelect}
      className="daterange-dropdown text-active"
      disabled={props.disabled}
    />
  );
}
