export const VoucherCodeColumns = [
    {
        key: "table-selectbox",
        label: "",
        width: 60,
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
        textEllipsis: true
    },
    {
        key: "voucherCode",
        label: "voucherSettings.Voucher_code",
        fixed: true,
        cellClass: "cell-align-toolbar",
        headerClass: "header-align-toolbar",
        textEllipsis: true
    },
    {
        key: "campaign.name",
        label: "voucherSettings.Campaign",
        textEllipsis: true
    },
    {
        key: "value",
        label: "voucherSettings.Value",
        textEllipsis: true
    },
    {
        key: "validFrom",
        label: "voucherSettings.Valid_from",
        textEllipsis: true
    },
    {
        key: "validTo",
        label: "voucherSettings.Valid_to",
        textEllipsis: true
    },
    // {
    //     key: "isUsed",
    //     label: "voucherSettings.isUsed",
    //     textEllipsis: true
    // },
    {
        key: "isActive",
        label: "voucherSettings.isActive",
        textEllipsis: true
    },
    {
        key: "actions",
        label: "voucherSettings.Actions",
        align: "right",
        cellClass: "table-actions",
        width: 100
    }
]