import {
  getBookingDetailApi,
  getStatusRecurringApi,
  getFleetFareSettingApi,
  getTechFeeApi,
  getComission,
  getCompletedBookingDetail,
  affiliateFareSettingApi,
  getRetryCompleteBookingApi,
  getCorporateDetailForBookingApi,
  getRejectInfoApi,
  getPromotionCodeInfoApi,
  overrideCustomerCancelApi,
  overrideDriverCancelApi,
  overrideCustomerNoShowApi,
  overrideCustomerWithoutServiceAPI,
  updateFinishedBookingApi,
  checkChargeCCCancelApi,
  updateBookingAPI,
  changePaymentTypeBookingAPI,
  completeBookingAPI,
  cancelBookingAPI,
  incidentBookingAPI,
  markFailedRecipientAPI,
  retryCompleteBookingAPI,
  rejectBookingAPI,
  checkChargeDrvCancelApi,
  acceptHydraBookingAPI,
  thirPartyBookingAPI,
  denytHydraBookingAPI,
  rejectHydraBookingAPI,
  getZoneWithLatLngApi,
  prePaidForBookingApi,
  getPaymentActivitiesApi,
  getPaymentLinkForPrepaidApi,
  deactivatePrepaidPaymentLinkAPI,
  postPaidForBookingAPI,
  prePayRefundAPI,
  getPaymentLinkForPostpaidAPI,
  deactivatePaymentLinkForBookingAPI,
  acceptOfferBookingApi
} from '../constants/ApiConfigs';
import { callApi, UrlBuilder, UrlBuilderWithParams } from '../utils/apiUtils';

export const BOOKING_DETAIL_GET_REQUEST = 'BOOKING_DETAIL_GET_REQUEST';
export const BOOKING_DETAIL_GET_SUCCESS = 'BOOKING_DETAIL_GET_SUCCESS';
export const BOOKING_DETAIL_GET_FAILURE = 'BOOKING_DETAIL_GET_FAILURE';
export const BOOKING_DETAIL_CLOSED = 'BOOKING_DETAIL_CLOSED';

function getBookingDetailRequest() {
  return {
    type: BOOKING_DETAIL_GET_REQUEST,
  };
}

function getBookingDetailSuccess(data) {
  return {
    type: BOOKING_DETAIL_GET_SUCCESS,
    data: data.res,
  };
}

export function getZoneWithLatLng (options = {}) {
  options = Object.assign({}, options);
  const config = {
    method: 'get'
  };
  return callApi(UrlBuilder(getZoneWithLatLngApi, options), config, null, null, null, true);
}

function getBookingDetailFailure(error) {
  return {
    type: BOOKING_DETAIL_GET_FAILURE,
    error,
  };
}
export function getBookingDetail(
  bookingId,
  isFinished = false,
  isShowDetail = true
) {
  const config = {
    method: 'GET',
  };
  let api = isFinished
    ? getCompletedBookingDetail + bookingId
    : getBookingDetailApi + bookingId;
  return callApi(
    api,
    config,
    isShowDetail ? getBookingDetailRequest() : null,
    isShowDetail ? getBookingDetailSuccess : null,
    isShowDetail ? getBookingDetailFailure : null,
    true
  );
}
export function bookingDetailClosed() {
  return {
    type: BOOKING_DETAIL_CLOSED,
  };
}

export function getFleetFareSetting(options, isAffilate) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    isAffilate ? affiliateFareSettingApi : getFleetFareSettingApi,
    config
  );
}

export function getTechFee(options) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getTechFeeApi, config);
}

export function getCommission(options) {
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getComission, config);
}

export function acceptOfferBooking(options) {
  const config = {
    method: 'GET',
  };
  return callApi(UrlBuilderWithParams(acceptOfferBookingApi, options), config);
}

export function getRetryCompleteBooking(options) {
  const config = {
    method: 'get',
  };
  return callApi(UrlBuilder(getRetryCompleteBookingApi, options), config);
}

export function getCorporateForBooking(options) {
  const config = {
    method: 'get',
  };
  return callApi(UrlBuilder(getCorporateDetailForBookingApi, options), config);
}

export function getRejectInfo(options) {
  const config = {
    method: 'get',
  };
  return callApi(UrlBuilder(getRejectInfoApi, options), config);
}

export function getPromotionCodeInfo(options) {
  const config = {
    method: 'GET',
  };
  return callApi(UrlBuilder(getPromotionCodeInfoApi, options), config);
}

export function getStatusRecurring(options) {
  const config = {
    method: 'GET',
  };
  return callApi(UrlBuilder(getStatusRecurringApi, options), config);
}

export function overrideCustomerCancel(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(overrideCustomerCancelApi, config, null, null, null, true);
}

export function overrideDriverCancel(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(overrideDriverCancelApi, config, null, null, null, true);
}

export function overrideCustomerNoShow(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(overrideCustomerNoShowApi, config, null, null, null, true);
}

export function updateBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(updateBookingAPI, config, null, null, null, true);
}

export function acceptHydraBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(acceptHydraBookingAPI, config, null, null, null, true);
}

export function thirPartyBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(thirPartyBookingAPI, config, null, null, null, true);
}

export function prePaidForBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(prePaidForBookingApi, config, null, null, null, true);
}

export function getPaymentLinkForPostpaid(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getPaymentLinkForPostpaidAPI, config, null, null, null, true);
}

export function deactivatePaymentLinkForBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(deactivatePaymentLinkForBookingAPI, config, null, null, null, true);
}

export function getPaymentActivities(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getPaymentActivitiesApi, config, null, null, null, true);
}

export function getPaymentLinkForPrepaid(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(getPaymentLinkForPrepaidApi, config, null, null, null, true);
}

export function deactivatePrepaidPaymentLink(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(deactivatePrepaidPaymentLinkAPI, config, null, null, null, true);
}

export function postPaidForBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(postPaidForBookingAPI, config, null, null, null, true);
}

export function prePayRefund(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(prePayRefundAPI, config, null, null, null, true);
}

export function denytHydraBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(denytHydraBookingAPI, config, null, null, null, true);
}

export function rejectHydraBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(rejectHydraBookingAPI, config, null, null, null, true);
}

export function completeBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(completeBookingAPI, config, null, null, null, true);
}

export function changePaymentTypeBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(changePaymentTypeBookingAPI, config, null, null, null, true);
}

export function incidentBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(incidentBookingAPI, config, null, null, null, true);
}

export function markFailedRecipient(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(markFailedRecipientAPI, config, null, null, null, true);
}

export function retryCompleteBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(retryCompleteBookingAPI, config, null, null, null, true);
}

export function rejectBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(rejectBookingAPI, config, null, null, null, true);
}

export function cancelBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(cancelBookingAPI, config, null, null, null, true);
}

export function overrideCustomerWithoutService(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    overrideCustomerWithoutServiceAPI,
    config,
    null,
    null,
    null,
    true
  );
}

export function checkChargeDrvCancel(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    checkChargeDrvCancelApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function checkChargeCCCancel(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(
    checkChargeCCCancelApi,
    config,
    null,
    null,
    null,
    true
  );
}

export function updateFinishedBooking(options) {
  options = Object.assign({}, options);
  const config = {
    method: 'post',
    body: JSON.stringify(options),
  };
  return callApi(updateFinishedBookingApi, config, null, null, null, true);
}
