import React, { Fragment } from 'react';
import { ALERT_NOTI_PROVIDER } from '../../../../constants/commondata';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { I18n, Translate } from 'react-redux-i18n';
import Select from '../../../../components/select/Select';

const AlertNotificationGroup = (props) => {
  const { fleet, handleUpdateAlertNotifications, editable } = props;
  const alertNotifications = fleet?.generalSetting?.alertNotifications || {};
  const emailList = alertNotifications?.emails?.map((i) => {
    return {
      id: i,
      value: i,
    };
  });

  const handleLoadOptionDebounce = (input, callback) => {
    const reEmail =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!reEmail.test(input))
      return callback({
        complete: false,
        options: [],
      });
    const result = {
      complete: true,
      options: [
        {
          value: input,
          id: input,
        },
      ],
    };
    callback(null, result);
  };

  return (
    <div className="group-general">
      <FormGroupTitle className="title">
        <Translate value="generalSetting.alertNotifications.title" />
      </FormGroupTitle>
      {ALERT_NOTI_PROVIDER.map((item, index) => {
        return (
          <FormGroup key={index}>
            <Form.Label>
              <Translate value={`generalSetting.alertNotifications.${item}`} />
            </Form.Label>
            {item === 'emails' ? (
              <Select.Async
                multi={true}
                valueKey="id"
                labelKey="value"
                cache={false}
                placeholder={I18n.t(
                  `generalSetting.alertNotifications.${item}Placeholder`
                )}
                searchPromptText={I18n.t('invoice.addRecipients')}
                loadingPlaceholder={I18n.t('message.Searching')}
                noResultsText={I18n.t('message.No_results')}
                className="receiver-list form-custom custom-select-control none-bg-arrow car-color-suggest email-notification general-select"
                value={emailList || []}
                loadOptions={handleLoadOptionDebounce}
                onChange={(emails) => {
                  handleUpdateAlertNotifications(
                    `${item}`,
                    emails.map((i) => i.value)
                  );
                }}
                // disabled={!editable}
              />
            ) : (
              <FormControl
                type="text"
                value={alertNotifications?.[item]}
                placeholder={I18n.t(
                  `generalSetting.alertNotifications.${item}Placeholder`
                )}
                className="form-custom"
                onChange={(event) =>
                  handleUpdateAlertNotifications(`${item}`, event.target.value)
                }
                // disabled={!editable}
              />
            )}
          </FormGroup>
        );
      })}
    </div>
  );
};

export default AlertNotificationGroup;
