import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup, Form, Modal, Button
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as settingActions from '../../../../../actions/settingActions';
import * as loadingBarActions from '../../../../../actions/loadingBarActions';

import './ModalCustomizedFaceMask.scss';
import FaceMaskZoneColumn from './FaceMaskZoneColumn';
import { CCLiteCommonFunc } from '../../../../../utils/commonFunctions';

class ModalCustomizedFaceMaskZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: {}
    };
  }

  componentDidMount() {
    const {
      settingActions: { getAllFaceMaskZone },
      auth: { selectedFleet }
    } = this.props;

    getAllFaceMaskZone({ fleetId: selectedFleet.fleetId }).then(({ res }) => {
      this.setState(
        { faceMaskZone: res },
        () => {
          this.initialData()
        });
    });
  }

  initialData = () => {
    const { faceMaskZone } = this.state;
    const { commonData } = this.props;
    let zones = [];

    if (faceMaskZone) {
      faceMaskZone.forEach(e => {
        if (e.operationZoneId.isActive) {
          zones.push({
            operationZoneId: e.operationZoneId._id,
            zoneName: e.operationZoneId.zoneName,
            isTurnOnVerify: e.isTurnOnVerify,
            isForce: e.isForce,
            timeRangeToVerify: e.timeRangeToVerify,
            skipVerifyTimes: e.skipVerifyTimes,
            needToVerify: {
              openApp: e.needToVerify.openApp,
              startReservation: e.needToVerify.startReservation
            }
          })
        };
      })
    };

    if (commonData.mapZone) {
      commonData.mapZone.forEach(e => {
        if ((_.findIndex(zones, o => o.operationZoneId === e._id)) === -1 && e.display === true) {
          zones.push({
            operationZoneId: e._id,
            zoneName: e.zoneName,
            isTurnOnVerify: false,
            isForce: false,
            timeRangeToVerify: 1440,
            skipVerifyTimes: 2,
            needToVerify: {
              openApp: false,
              startReservation: false
            }
          })
        };
      });
    };

    this.setState({
      zones
    });

  }

  onChangeFaceMaskZone = value => {
    const { zones } = this.state;
    _.each(zones, o => {
      if (o.operationZoneId === value.operationZoneId) {
        o = value;
      }
    });
    this.setState({ zones });
  };

  onValidate = (key, value) => {
    const { valid } = this.state;
    this.setState({
      valid: {
        ...valid,
        [key]: value
      }
    })
  };

  handleSubmit = () => {
    const {
      settingActions: { updateAllFaceMaskZone },
      auth: { selectedFleet },
      onCloseModal
    } = this.props;
    const { zones, valid } = this.state;
    const faceMaskZoneUpdate = zones || {};
    if (!CCLiteCommonFunc.isFormValid(valid)) {
      const zoneIds = Object.keys(valid);
      zoneIds.map(zoneId => {
        const result = zones.filter(zone => zone.operationZoneId === zoneId);
        if(!valid[zoneId]){
        this.context.notification(
          'error',
          I18n.t('generalSetting.face_mask_customized_fail').format(result[0].zoneName)
        );
        }
      });
      return;
    };

    updateAllFaceMaskZone({
      fleetId: selectedFleet.fleetId,
      faceMask: faceMaskZoneUpdate
    }).then(() => {
      onCloseModal();
    });
  };

  render() {
    const {
      show,
      onCloseModal,
      editable,
    } = this.props;
    const { zones } = this.state;

    return (
      <Modal onHide={this.props.onCloseModal} show={show} backdrop>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value="generalSetting.customizedFaceMaskZone" />
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.onCloseModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body className="faceMaskModal__body">
          <div className="faceMaskTable__header">
            <table className="table table-no-pagination">
              <thead>
                <tr>
                  <td className="tablefaceMask__faceMaskCol">
                    <Translate value="generalSetting.zoneOperation" />
                  </td>
                  <td className="tablefaceMask__checkbox">
                    <Translate value="generalSetting.turn_on_verify" />
                  </td>
                  <td className="tablefaceMask__checkbox">
                    <Translate value="generalSetting.force_to_wear_face_mask" />
                  </td>
                  <td className="tablefaceMask__timerange">
                    <Translate value="generalSetting.time_range_to_verify" />
                  </td>
                  <td className="tablefaceMask__timerange">
                    <Translate value="generalSetting.allow_driver_to_skip_verification_after" />
                  </td>
                  <td className="tablefaceMask__checkbox">
                    <Translate value="generalSetting.open_app" />
                  </td>
                  <td className="tablefaceMask__checkbox">
                    <Translate value="generalSetting.start_reservation" />
                  </td>
                </tr>
              </thead>
            </table>
          </div>
          <div className="faceMaskTable__tbody">
            <table className="table table-no-pagination">
              <thead>
                <tr>
                  <th className="tablefaceMask__faceMaskCol p0" />
                  <th className="tablefaceMask__checkbox  p0" />
                  <th className="tablefaceMask__checkbox p0" />
                  <th className="tablefaceMask__timerange p0" />
                  <th className="tablefaceMask__timerange p0" />
                  <th className="tablefaceMask__checkbox  p0" />
                  <th className="tablefaceMask__checkbox p0" />
                </tr>
              </thead>
              <tbody>
                {!zones || !zones.length ? (
                  <tr>
                    <td className="text-center" colSpan={5}>
                      <Translate value="reservation.noData" />
                    </td>
                  </tr>
                ) : null}
                {(zones || []).map((zone, index) => (
                  <FaceMaskZoneColumn
                    key={_.get(zone, 'operationZoneId', zone.operationZoneId)}
                    zone={zone}
                    index={index}
                    auth={this.props.auth}
                    permissions={this.props.auth}
                    onChange={this.onChangeFaceMaskZone}
                    editable={editable}
                    onValidate={this.onValidate}
                  />
                ))}
              </tbody>
            </table>
          </div>

        </Modal.Body>
        <Modal.Footer>
          {editable ? null : (
            <Button
              className="btn-save mr-md"
              type="button"
              onClick={this.handleSubmit}
              disabled={editable}
            >
              <Translate value="generalSetting.Save" />
            </Button>
          )}
          <Button className="btn-cancel" onClick={onCloseModal}>
            <Translate value="generalSetting.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ModalCustomizedFaceMaskZone.contextTypes = {
  notification: PropTypes.func
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    settings: state.settings,
    permissions: state.menuHandle.modulePermission
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCustomizedFaceMaskZone);
