import ReportUtils from './../base/utils';
import _ from 'lodash';

const genConversionLink = (key, keyInUSD) => {
  keyInUSD = keyInUSD || key + 'InUSD';
  return {
    name: 'showConversionModal',
    description: 'Show conversion info',
    isNotShow: (doc) => !doc[keyInUSD],
    props: (doc) => ({
      title: 'report.result.penaltyCompensation.' + key,
      bookId: doc.bookingId,
      original: doc[key],
      conversion: doc[keyInUSD],
      currencyISO: doc.currencyISO,
    }),
  };
};

export const fields = [
  {
    key: 'bookingId',
    label: 'report.result.affiliation.bookingId',
    summary: 'totalBookings',
    rowSpan: 2,
  },
  {
    key: 'bookingType',
    label: 'report.result.affiliation.bookingType',
    rowSpan: 2,
  },
  {
    key: 'date',
    label: 'report.result.affiliation.date',
    rowSpan: 2,
    // summary: "size",
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'bookingStatus',
    label: 'report.result.affiliation.bookingStatus',
    rowSpan: 2,
    width: 220,
  },
  {
    key: 'estimatedPriceInUSD',
    label: 'report.result.penaltyCompensation.estimatedPriceInUSD',
    rowSpan: 2,
    width: 220,
    mutate: ReportUtils.formatCurrencyUSD,
    link: genConversionLink('estimatedPrice'),
  },
  {
    key: 'penaltyAmountInUSD',
    label: 'report.result.penaltyCompensation.penaltyAmountInUSD',
    rowSpan: 2,
    width: 220,
    summary: 'penaltyAmountInUSD',
    mutate: ReportUtils.formatCurrencyUSD,
    summaryMutate: (v) => `$${(v || 0).toFixed(2)}`,
    link: genConversionLink('penaltyAmount'),
  },
  {
    key: 'compensationAmountInUSD',
    label: 'report.result.penaltyCompensation.compensationAmountInUSD',
    summary: 'compensationAmountInUSD',
    rowSpan: 2,
    width: 220,
    mutate: ReportUtils.formatCurrencyUSD,
    summary: 'compensationAmountInUSD',
    summaryMutate: (v) => `$${(v || 0).toFixed(2)}`,
    link: genConversionLink('compensationAmount'),
  },
  {
    key: 'penaltyReason',
    label: 'report.result.penaltyCompensation.penaltyReason',
    rowSpan: 2,
    width: 450,
  },
];

export const fareFields = [];
