import React, { Component } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { getDirectionsWithoutETAMinDistance } from '../../../utils/mapUtils';
import pickupIcon from './../../../assets/images/icons/delivery_pickup.svg';
import recipient_icon from './../../../assets/images/icons/delivery_recipient.svg';
import recipient1_icon from './../../../assets/images/icons/delivery_recipient_1.svg';
import recipient2_icon from './../../../assets/images/icons/delivery_recipient_2.svg';
import { CCLiteCommonFunc } from '../../../utils/commonFunctions';

class DeliveryGGMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      directions: null,
      markers: [],
      bounds: null
    };
  }

  componentDidMount() {
    this.updateBounds();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pickups !== this.props.pickups ||
      prevProps.destinations !== this.props.destinations ||
      prevProps.travelMode !== this.props.travelMode) {
      this.updateBounds();
      this.renderMarkers();
    }
  }

  componentWillUnmount() {
    this.state.markers.forEach(marker => marker.map = null);
  }

  updateBounds = () => {
    const { pickups, destinations, auth, travelMode } = this.props;
    const bounds = new window.google.maps.LatLngBounds();

    let pointDirections = [...pickups, ...destinations]
      .filter((point) => point.address.geo && point.address.geo[0] && point.address.geo[1])
      .map((point) => {
        bounds.extend(new window.google.maps.LatLng(point.address.geo[1], point.address.geo[0]));
        return {
          lat: point.address.geo[1],
          lng: point.address.geo[0]
        };
      });

    if (pointDirections.length < 2) {
      this.setState({ bounds, directions: null });
      return;
    }

    let destination = pointDirections.pop();
    let origin = pointDirections.shift();
    let waypoints = pointDirections.map((pointDirection) => ({
      location: pointDirection
    }));

    getDirectionsWithoutETAMinDistance({
      origin: [origin.lat, origin.lng],
      waypoints,
      destination: [destination.lat, destination.lng],
      optimizeWaypoints: false,
      travelMode: travelMode === 'driving' ? travelMode.toUpperCase() : 'BICYCLING',
      callback: (result) => {
        if (result) {
          this.setState({ directions: result });
          this.props.setDistanceDuration(result.routes[0]?.legs[0]);
        } else {
          this.setState({ directions: null });
        }
      },
      auth
    });
    this.setState({ bounds });
  };

  renderMarkers = () => {
    const { map } = this.state;
    if (!map) return;

    // Clear existing markers
    this.state.markers.forEach(marker => marker.map = null);
    const newMarkers = [];

    // Add pickup markers
    this.props.pickups.forEach((pickup) => {
      if (pickup.address.geo[1]) {
        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          {
            lat: pickup.address.geo[1],
            lng: pickup.address.geo[0]
          },
          pickupIcon, "", 100, 32, 32
        );
        marker.map = map;
        newMarkers.push(marker);
      }
    });

    // Add destination markers
    this.props.destinations.forEach((dest, index) => {
      if (dest.address.geo[1]) {
        const icon = index === 0 ?
          (this.props.destinations.length > 1 ? recipient1_icon : recipient_icon) :
          recipient2_icon;

        const marker = CCLiteCommonFunc.createAdvancedMarkerPoint(
          {
            lat: dest.address.geo[1],
            lng: dest.address.geo[0]
          },
          icon, "", 100, 32, 32
        );
        marker.map = map;
        newMarkers.push(marker);
      }
    });

    this.setState({ markers: newMarkers });
  };

  onMapLoad = (mapInstance) => {
    this.setState({ map: mapInstance }, () => {
      this.renderMarkers();
      if (this.props.onMapLoad) {
        this.props.onMapLoad(mapInstance);
      }
      if (this.state.bounds) {
        mapInstance.fitBounds(this.state.bounds);
      }
    });
  };

  render() {
    return (
      <div className="fill booking-map">
        <GoogleMap
          onLoad={this.onMapLoad}
          mapContainerStyle={{ height: '100%' }}
          options={{
            gestureHandling: 'greedy',
            mapId: process.env.REACT_APP_MAP_ID
          }}
          zoom={14}
        >
        </GoogleMap>
      </div>
    );
  }
}

export default DeliveryGGMap;
