export const UPDATE_BOOKING_IMPORT = 'UPDATE_BOOKING_IMPORT';
export const SET_BOOKINGS_IMPORT = 'SET_BOOKINGS_IMPORT';
export const SET_NUMBERS_STATUS = 'SET_NUMBERS_STATUS';
export const RESET_VALUES = 'RESET_BOOKINGS';
export const UNSET_VALUES = 'UNSET_BOOKINGS';
export const SHOW_VALIDATION_POPUP = 'SHOW_VALIDATION_POPUP';

export function unsetValues(data) {
  return {
    type: UNSET_VALUES,
    data,
  };
}

export function resetValues(data) {
  return {
    type: RESET_VALUES,
    data,
  };
}

export function setNumbersStatus(data) {
  return {
    type: SET_NUMBERS_STATUS,
    data,
  };
}

export function setBookings(bookings) {
  return {
    type: SET_BOOKINGS_IMPORT,
    bookings,
  };
}

export function updateBookings(booking) {
  return {
    type: UPDATE_BOOKING_IMPORT,
    booking,
  };
}

export function showHideValidationPopup(data) {
  return {
    type: SHOW_VALIDATION_POPUP,
    data,
  };
}
