import {
  DRIVER_AUTOCOMPLETE_REQUEST,
  DRIVER_AUTOCOMPLETE_SUCCESS,
  DRIVER_AUTOCOMPLETE_FAILURE,
} from "../actions/driverAutocompleteActions";

const initialState={
    data:[],
    error:null
}
export default function driverAutocomplete(state=initialState,action={}){
    switch(action.type){
        case DRIVER_AUTOCOMPLETE_REQUEST:{
            return state;
        }
        case DRIVER_AUTOCOMPLETE_SUCCESS:{
            return {...state, data:action.data};
        }
        case DRIVER_AUTOCOMPLETE_FAILURE:{
            return {...initialState,error:action.error}
        }
        default:
            return state
    }
}