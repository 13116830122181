import React from 'react';
import {
    FormGroup,
    FormControl,
    Form
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

function Other({ others, isActive, hasPermission, handleChangeInput }) {
    return (
        <div className="form-group-info">
            <FormGroupTitle className="default-font-size">
                <Translate value="pointConfig.others" />
            </FormGroupTitle>
            <FormGroup>
                <Form.Label>
                    <Translate value="pointConfig.instructionURL" />{" "}
                </Form.Label>
                <FormControl
                    className="form-custom"
                    type="text"
                    id={'instructionURL'}
                    maxLength={100}
                    value={others.instructionURL || ""}
                    onChange={e => {
                        handleChangeInput("instructionURL", e.target.value);
                    }}
                    disabled={!hasPermission}
                />
            </FormGroup>
            <FormGroup>
                <Form.Label>
                    <Translate value="pointConfig.displayName" />{" "}
                </Form.Label>
                <FormControl
                    className="form-custom"
                    type="text"
                    id={'displayName'}
                    maxLength={100}
                    value={others.displayName || ""}
                    onChange={e => {
                        handleChangeInput("displayName", e.target.value);
                    }}
                    disabled={!hasPermission}
                />
            </FormGroup>

            <FormGroup>
                <CcCheckbox
                    checked={isActive}
                    onChange={e => {
                        handleChangeInput("isActive", e.target.checked);
                    }}
                    disabled={!hasPermission}
                    text={I18n.t('pointConfig.isActive')}
                    className="mb0"
                />
            </FormGroup>

        </div>
    )
}

export default Other
