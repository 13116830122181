import React from 'react';
import Select from 'react-select';

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? 'linear-gradient(45deg, transparent 50%, #252830 50%), linear-gradient(135deg, #252830 50%, transparent 50%)'
      : '#27272A',
    borderColor: '#27272A',
    boxShadow: 'unset',
    color: 'white',
    ':active': {
      borderColor: '#04BE76',
    },
    ':hover': {
      borderColor: '#04BE76',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#27272A',
      color: 'white',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#27272A',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#2D3039',
      },
    };
  },
  menu: (styles) => ({ ...styles, backgroundColor: '#27272A' }),
  input: (styles) => ({ ...styles, color: 'white' }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#2D3039',
      color: 'white',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  theme: (styles) => {
    console.log(styles);
    return {
      ...styles,
      backgroundColor: '#27272A',
      borderColor: '#27272A',
      color: 'white',
    };
  },
};

export default (props) => {
  const { value, onChange, options, isMulti, menuIsOpen, disabled } = props;
  return (
    <Select
      closeMenuOnSelect={false}
      value={value}
      isMulti={isMulti || true}
      options={options}
      styles={colourStyles}
      onChange={onChange}
      menuIsOpen={menuIsOpen}
      isDisabled={disabled}
    />
  );
};
