import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import _ from 'lodash';
import DateTime from '../../../components/dateTime/DateTime';

import * as intercityBookingActions from '../../../actions/intercityBookingActions';
import { Validator, ValidCase } from '../../../components/validator';

function DepartureDatePicker(props) {
  const [show, setShow] = useState(false);
  const handleSelectDate = (date) => {
    props.onChange(date);
    props.intercityBookingActions.updateCommonData({
      pickupDate: date,
    });
    props.intercityBookingActions.etaFare();
  };

  const isFilteredDayValid = (current) => {
    const daysOfWeek = _.map(props.schedules, 'dayOfWeek');
    return (
      current.isSameOrAfter(moment().startOf('day')) &&
      daysOfWeek.includes(current.day())
    );
  };
  return (
    <FormGroup
      className={props.isSubmited && props.valid.departureDate === false ? 'error' : null}
    >
      <Form.Label>
        <Translate value="intercityBooking.departureDate" />
        &nbsp;
        <span className="require"> *</span>
      </Form.Label>
      <DateTime
        value={props.value ? moment(props.value) : null}
        timeFormat={false}
        onChange={handleSelectDate}
        inputProps={{
          readOnly: true,
          className: 'date-readonly form-control',
          placeholder: 'mm/dd/yyyy',
          value: props.value ? moment(props.value).format("MM/DD/YYYY") : '',
          disabled: props.disabled
        }}
        isValidDate={isFilteredDayValid}
        disabled={props.disabled}
        closeOnSelect
      />
      <Validator
        callback={props.validatorCallback}
        id="departureDate"
      >
        <ValidCase
          valid={!!props.value}
          hide
          message={I18n.t('messages.commonMessages.Required_field')}
        />
      </Validator>
    </FormGroup>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    schedules: state.intercityBooking.common.schedules,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // loadingActions: bindActionCreators(loadingActions, dispatch),
    intercityBookingActions: bindActionCreators(
      intercityBookingActions,
      dispatch
    ),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartureDatePicker);
