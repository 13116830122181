import React from "react";
import "./radio.scss";

class Radio extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {text, value, onChange, name, id, disabled, checked, borderColor, checkedColor} = this.props;
    let htmlId = id || Math.random().toString();
    return (
      <label className={"qup-radio-wrapper " + (checked ? "checked" : "")}>
        <span className="qup-radio">
          <input
            type="radio" 
            name={name}
            id={htmlId}
            onChange={onChange}
            checked={checked}
            value={value}
            disabled={disabled}
            className="qup-radio-input"
          />
          <span className="qup-radio-inner">
            <span className="qup-radio-inner-select"></span>
          </span>
        </span>
        <span className="qup-radio-text">{text}</span>
      </label>
    )
  }
}

export default Radio;