import React from 'react';
import { FormGroup, Col, Form } from 'react-bootstrap';
import UploadFile from '../../../../../components/upload/upload';
import { I18n, Translate } from 'react-redux-i18n';
import { Validation } from '../../../../../utils/commonFunctions';
import { ValidCase, Validator } from '../../../../../components/validator';

const HtmlForm = ({ item, handleHtmlChange, filesHtml, appType, disabled, valid, isSubmited, ValidatorCallback }) => {
  let fileName = '';
  const files = filesHtml;
  if (files && files[item] && files[item].length > 0) {
    let splitUrl = files[item].split('/');
    fileName = splitUrl[splitUrl.length - 1];
  }
  return (
    <div>
      <Col
        md={12}
        xs={12}
        className={'pl0'}
        style={{
          marginBottom: '20px',
        }}
      >
        <FormGroup
          className={
            !isSubmited
              ? null
              : valid[`${appType}_html`] === false
              ? 'error'
              : null
          }
        >
          <Col md={4} xs={12} className={'pl0'}>
            <Form.Label>
              <Translate value={`social.${item}`} />
            </Form.Label>
          </Col>

          <Col md={6} xs={12} className={'pl0'}>
            <UploadFile
              id={item}
              name={item}
              accepts=".html"
              className="pwa-upload"
              onChange={(data, error) => {
                handleHtmlChange(appType, item, data, error);
              }}
              disabled={disabled}
              existFile={
                fileName
                  ? {
                      fileName,
                      onRemove: () => {
                        handleHtmlChange(appType, item);
                      },
                    }
                  : null
              }
            />
            <Validator id={`${appType}_html`} callback={ValidatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(filesHtml[item])}
                message={I18n.t('messages.commonMessages.Required_field')}
                hide={!isSubmited}
              />
            </Validator>
          </Col>
        </FormGroup>
      </Col>
    </div>
  );
};

export default HtmlForm;
