import React from 'react';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Modal,
  Tab,
  Tabs,
  ButtonToolbar,
} from 'react-bootstrap';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import {
  repeatType,
  SURCHARGE_DEFAULT,
} from '../../../../constants/commondata';

import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  convertToDifferentSurchage,
} from '../../../../utils/commonFunctions';
import {
  Validator,
  ValidCase,
  WarningCase,
} from '../../../../components/validator';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import DateTime from '../../../../components/dateTime/DateTime';

import SurchargeDistanceTable from './SurchargeDistanceTable';
import SurchargeDistanceTableDifferent from './SurchargeDistanceTableDifferent';
import { BsPlus } from 'react-icons/bs';

const surchargeType = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
};

const hours = _.range(0, 24).map((s) => _.padStart(s, 2, '0'));
const minutes = _.range(0, 60).map((s) => _.padStart(s, 2, '0'));
const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

class ModalAddSurcharge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogData: { ...props.dialogData },
      rushHourValid: {},
      surchargeByCurrenciesInitial: props.surchargeByCurrenciesInitial,
      selectedTabCurrency: {},
    };
  }

  componentDidMount() {
    const { surchargeByCurrencies } = this.state.dialogData;
    let selectedTabCurrency = surchargeByCurrencies[0];
    this.setState({
      selectedTabCurrency,
    });
  }

  handleRushHourDateCheckChange = (key, value) => {
    const { dialogData } = this.state;
    dialogData.date[key] = value;
    this.setState({ dialogData });
  };

  handleRushHoursname = (e) => {
    const { dialogData } = this.state;
    dialogData.name = e.target.value;
    this.setState({ dialogData });
  };

  handleRushHourFromHHChange = (e) => {
    const { dialogData } = this.state;
    dialogData.From[0] = e.target.value;
    this.setState({ dialogData });
  };

  handleRushHourFromMMChange = (e) => {
    const { dialogData } = this.state;
    dialogData.From[1] = e.target.value;
    this.setState({ dialogData });
  };

  handleRushHourToHHChange = (e) => {
    const { dialogData } = this.state;
    dialogData.To[0] = e.target.value;
    this.setState({ dialogData });
  };

  handleRushHourToMMChange = (e) => {
    const { dialogData } = this.state;
    dialogData.To[1] = e.target.value;
    this.setState({ dialogData });
  };

  handleRushHourTimeCalhange = (e) => {
    const { dialogData } = this.state;
    dialogData.timeCal = e.target.value;
    this.setState({ dialogData });
  };

  handlePercentSurchargeChange = (e, iso) => {
    const { dialogData } = this.state;
    const iosValue = [{ currencyISO: '', value: 0 }];
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    let value = e.target.value;
    if (
      numberWithDotReg.test(value) ||
      numberWithCommaReg.test(value) ||
      value === ''
    ) {
      iosValue[0].value = value === '' ? '' : value;
      this.setState({
        dialogData: { ...dialogData, surchargeByCurrencies: iosValue },
      });
    }
    let check = this.state.surchargeByCurrenciesInitial;
  };

  handleSurchargeChange = (e, typeRate, bookType) => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (!regrex.test(e.target.value)) return;
    const { dialogData } = this.state;
    const { auth } = this.props;
    const surchargeChange = dialogData[bookType];
    let surchargeByServices =
      (surchargeChange[0] && surchargeChange[0].surchargeByServices) || [];
    surchargeByServices = surchargeByServices.map((surcharge) => {
      if (surcharge.typeRate === typeRate) {
        return {
          typeRate: surcharge.typeRate,
          value: e.target.value,
        };
      }
      return surcharge;
    });
    this.setState({
      dialogData: {
        ...dialogData,
        [bookType]: [
          {
            surchargeByServices: surchargeByServices,
            distanceRange: SURCHARGE_DEFAULT.distanceRange,
            currencyISO:
              auth.selectedFleet.currencies[0] &&
              auth.selectedFleet.currencies[0].iso,
          },
        ],
      },
    });
  };

  handleRepeatTypeChange = (e) => {
    //TODO set state parent
    this.state.rushHourValid = {};
    this.setState({
      dialogData: {
        ...this.state.dialogData,
        repeatType: e.target.value,
        date: [],
        dateSurcharge: '',
      },
    });
  };

  handleSurchargeTypeChange = (e) => {
    let value = e.target.value;
    const { dialogData } = this.state;
    if (value === surchargeType.AMOUNT) {
      dialogData.surchargeByCurrencies = dialogData.amountSurcharge;
    } else if (value === surchargeType.PERCENT) {
      dialogData.surchargeByCurrencies = dialogData.percentSurcharge;
    }
    dialogData.surchargeType = value;
    this.setState({
      dialogData,
    });
  };

  handleChangeSurchargeDistance = (e) => {
    const { dialogData } = this.state;
    this.setState({
      dialogData: {
        ...dialogData,
        distanceDiversity: e.target.checked,
      },
    });
  };

  handleSelect = (key) => {
    const { surchargeByCurrencies } = this.state.dialogData;
    let data = surchargeByCurrencies.find((item) => item.currencyISO === key);
    this.setState({
      selectedTabCurrency: data,
    });
  };

  handleAddRangeButton = (bookType) => {
    const { dialogData } = this.state;
    let listItem =
      _.cloneDeep(dialogData[bookType][0].distanceRange) ||
      SURCHARGE_DEFAULT.distanceRange;
    if (listItem.length > 0) {
      let itemLast = _.last(listItem);
      if (parseFloat(itemLast.to) === Number.MAX_SAFE_INTEGER) {
        // Re-set value to
        listItem[listItem.length - 1].to =
          listItem[listItem.length - 1].from + 1;
        // Set value Infinity for New item
        listItem.push({
          from: itemLast.from + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeByServices: SURCHARGE_DEFAULT.surchargeByServices,
        });
      } else {
        listItem.push({
          from: parseFloat(itemLast.to) + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeByServices: SURCHARGE_DEFAULT.surchargeByServices,
        });
      }
    } else {
      listItem.push({
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeByServices: SURCHARGE_DEFAULT.surchargeByServices,
      });
    }

    this.setState({
      dialogData: {
        ...dialogData,
        [bookType]: [
          {
            ...dialogData[bookType][0],
            distanceRange: listItem,
          },
        ],
      },
    });
  };

  handleAddRangeButtonDifferent = () => {
    const { dialogData } = this.state;
    let listItem = _.cloneDeep(
      dialogData.surchargeByCurrencies[0].distanceRange
    ) || [
      {
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeValue: 0,
      },
    ];
    if (listItem.length > 0) {
      let itemLast = _.last(listItem);
      if (parseFloat(itemLast.to) === Number.MAX_SAFE_INTEGER) {
        // Re-set value to
        listItem[listItem.length - 1].to =
          listItem[listItem.length - 1].from + 1;
        // Set value Infinity for New item
        listItem.push({
          from: itemLast.from + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      } else {
        listItem.push({
          from: parseFloat(itemLast.to) + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      }
    } else {
      listItem.push({
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeValue: 0,
      });
    }

    this.setState({
      dialogData: {
        ...dialogData,
        surchargeByCurrencies: [
          {
            ...dialogData.surchargeByCurrencies[0],
            distanceRange: listItem,
          },
        ],
      },
    });
  };

  handleAddRangeButtonByCurrency = (iso) => {
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData.surchargeByCurrencies);
    let listItem = _.cloneDeep(selectedTabCurrency.distanceRange) || [
      {
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeValue: 0,
      },
    ];
    if (listItem.length > 0) {
      let itemLast = _.last(listItem);
      if (parseFloat(itemLast.to) === Number.MAX_SAFE_INTEGER) {
        // Re-set value to
        listItem[listItem.length - 1].to =
          listItem[listItem.length - 1].from + 1;
        // Set value Infinity for New item
        listItem.push({
          from: itemLast.from + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      } else {
        listItem.push({
          from: parseFloat(itemLast.to) + 1,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      }
    } else {
      listItem.push({
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeValue: 0,
      });
    }

    surchargeByCurrencies = surchargeByCurrencies.map((item) => {
      if (item.currencyISO === iso) {
        item.distanceRange = listItem;
      }
      return item;
    });
    this.setState({
      dialogData: {
        ...dialogData,
        surchargeByCurrencies,
      },
      selectedTabCurrency: {
        ...selectedTabCurrency,
        distanceRange: listItem,
      },
    });
  };

  handleRemoveSurchargeItem = (item, index, bookType) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData[bookType]);
    let listItem = [];

    if (multiCurrencies && dialogData.surchargeType === 'amount') {
      listItem = _.cloneDeep(selectedTabCurrency.distanceRange);
    } else {
      listItem = _.cloneDeep(surchargeByCurrencies[0].distanceRange);
    }
    if (listItem[index + 1]) {
      listItem[index + 1].from = item.from;
    }
    listItem.splice(index, 1);
    surchargeByCurrencies = [
      {
        ...dialogData.surchargeByCurrencies[0],
        ...SURCHARGE_DEFAULT,
        currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
        distanceRange: listItem,
      },
    ];

    this.setState({
      dialogData: {
        ...dialogData,
        [bookType]: surchargeByCurrencies,
      },
    });
  };

  handleRemoveSurchargeItemDifferent = (item, index) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData.surchargeByCurrencies);
    let listItem = [];

    if (multiCurrencies && dialogData.surchargeType === 'amount') {
      listItem = _.cloneDeep(selectedTabCurrency.distanceRange);
    } else {
      listItem = _.cloneDeep(dialogData.surchargeByCurrencies[0].distanceRange);
    }
    if (listItem[index + 1]) {
      listItem[index + 1].from = item.from;
    }
    listItem.splice(index, 1);
    if (surchargeByCurrencies.length > 1) {
      surchargeByCurrencies = surchargeByCurrencies.map((item) => {
        if (item.currencyISO === selectedTabCurrency.currencyISO) {
          item.distanceRange = listItem;
        }
        return item;
      });
    } else {
      surchargeByCurrencies = [
        {
          ...dialogData.surchargeByCurrencies[0],
          distanceRange: listItem,
        },
      ];
    }

    this.setState({
      dialogData: {
        ...dialogData,
        surchargeByCurrencies,
      },
      selectedTabCurrency: {
        ...selectedTabCurrency,
        distanceRange: listItem,
      },
    });
  };

  handleFromInputChange = (index, e) => {
    // const { dialogData } = this.state;
    // let listItem = _.cloneDeep(dialogData.surchargeByCurrencies[0].distanceRange);
    // if (index >= 0) listItem[index].from = e.target.value;
    // this.setState({
    //   dialogData: {
    //     ...dialogData,
    //     surchargeByCurrencies: [
    //       {
    //         distanceRange: listItem
    //       }
    //     ]
    //   },
    // });
  };

  handleToInputChangeDifferent = (index, e) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData.surchargeByCurrencies);
    let listItem = [];
    let value =
      e.target.value.toLowerCase() === 'infinity'
        ? Number.MAX_SAFE_INTEGER
        : parseFloat(e.target.value);

    if (multiCurrencies && dialogData.surchargeType === 'amount') {
      listItem = _.cloneDeep(selectedTabCurrency.distanceRange) || [
        {
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        },
      ];
    } else {
      listItem = _.cloneDeep(
        dialogData.surchargeByCurrencies[0].distanceRange
      ) || [
        {
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        },
      ];
    }

    if (index >= 0) {
      listItem[index].to = value;
      if (listItem[index + 1]) {
        listItem[index + 1].from = parseInt(value);
      } else {
        listItem.push({
          from: parseInt(value),
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        });
      }
    }

    if (surchargeByCurrencies.length > 1) {
      surchargeByCurrencies = surchargeByCurrencies.map((item) => {
        if (item.currencyISO === selectedTabCurrency.currencyISO) {
          item.distanceRange = listItem;
        }
        return item;
      });
    } else {
      surchargeByCurrencies = [
        {
          ...dialogData.surchargeByCurrencies[0],
          distanceRange: listItem,
        },
      ];
    }
    this.setState({
      dialogData: {
        ...dialogData,
        surchargeByCurrencies,
      },
      selectedTabCurrency: {
        ...selectedTabCurrency,
        distanceRange: listItem,
      },
    });
  };

  handleToInputChange = (index, e, bookType) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData[bookType]);
    let listItem = [];
    let value =
      e.target.value.toLowerCase() === 'infinity'
        ? Number.MAX_SAFE_INTEGER
        : parseFloat(e.target.value);

    listItem = _.cloneDeep(surchargeByCurrencies[0].distanceRange) || [
      {
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeByServices: SURCHARGE_DEFAULT.surchargeByServices,
      },
    ];

    if (index >= 0) {
      listItem[index].to = value;
      if (listItem[index + 1]) {
        listItem[index + 1].from = parseInt(value);
      } else {
        listItem.push({
          from: parseInt(value),
          to: Number.MAX_SAFE_INTEGER,
          surchargeByServices: SURCHARGE_DEFAULT.surchargeByServices,
        });
      }
    }

    surchargeByCurrencies = [
      {
        ...surchargeByCurrencies[0],
        distanceRange: listItem,
      },
    ];

    this.setState({
      dialogData: {
        ...dialogData,
        [bookType]: surchargeByCurrencies,
      },
    });
  };

  handleChangeSurchargeValue = (index, e, typeRate, bookType) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (!regrex.test(e.target.value)) return;
    let surchargeByCurrencies = _.cloneDeep(dialogData[bookType]);
    let listItem = [];
    listItem =
      _.cloneDeep(surchargeByCurrencies[0].distanceRange) ||
      SURCHARGE_DEFAULT.distanceRange;
    if (index >= 0 && listItem[index]) {
      let surchargeByServices =
        listItem[index].surchargeByServices &&
        listItem[index].surchargeByServices.map((surcharge) => {
          if (surcharge.typeRate === typeRate) {
            return {
              typeRate: typeRate,
              value: e.target.value,
            };
          }
          return surcharge;
        });
      listItem[index].surchargeByServices = surchargeByServices;
    }
    this.setState({
      dialogData: {
        ...dialogData,
        [bookType]: [
          {
            ...SURCHARGE_DEFAULT,
            ...dialogData.surchargeByCurrencies[0],
            currencyISO: this.props.auth.selectedFleet.currencies[0].iso,
            distanceRange: listItem,
          },
        ],
      },
    });
  };

  handleChangeSurchargeValueDifferent = (index, e) => {
    const { multiCurrencies } = this.props.auth.selectedFleet;
    const { dialogData, selectedTabCurrency } = this.state;
    let surchargeByCurrencies = _.cloneDeep(dialogData.surchargeByCurrencies);
    let listItem = [];
    if (multiCurrencies && dialogData.surchargeType === 'amount') {
      listItem = _.cloneDeep(selectedTabCurrency.distanceRange) || [
        {
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        },
      ];
    } else {
      listItem = _.cloneDeep(
        dialogData.surchargeByCurrencies[0].distanceRange
      ) || [
        {
          from: 0,
          to: Number.MAX_SAFE_INTEGER,
          surchargeValue: 0,
        },
      ];
    }
    if (index >= 0) listItem[index].surchargeValue = e.target.value;
    if (surchargeByCurrencies.length > 1) {
      surchargeByCurrencies = surchargeByCurrencies.map((item) => {
        if (item.currencyISO === selectedTabCurrency.currencyISO) {
          item.distanceRange = listItem;
        }
        return item;
      });
    } else {
      surchargeByCurrencies = [
        {
          ...dialogData.surchargeByCurrencies[0],
          distanceRange: listItem,
        },
      ];
    }
    this.setState({
      dialogData: {
        ...dialogData,
        surchargeByCurrencies,
      },
      selectedTabCurrency: {
        ...selectedTabCurrency,
        distanceRange: listItem,
      },
    });
  };

  handleDateChanged = (e) => {
    if (e._d) {
      this.setState({
        dialogData: {
          ...this.state.dialogData,
          dateSurcharge: e.format('MM/DD/YYYY'),
        },
      });
    }
  };

  isValidSelectDate = () => {
    let date = [];
    Object.keys(this.state.dialogData.date).map((k) => {
      if (this.state.dialogData.date[k]) {
        date.push(k);
      }
    });
    return date.length > 0;
  };

  ValidatorRushHourCallback = (id, valid, messages) => {
    if (this.state.rushHourValid[id] != valid) {
      this.state.rushHourValid[id] = valid;
      this.setState({ rushHourValid: this.state.rushHourValid });
    }
  };

  handleCancelInputMultiCurrenciesChange = (currency, e) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    const { dialogData } = this.state;
    let numberWithDotReg = /^(\d*\.?\d+|\d+\.)$/;
    let numberWithCommaReg = /^(\d*\,?\d+|\d+\,)$/;
    if (
      numberWithDotReg.test(e.target.value) ||
      numberWithCommaReg.test(e.target.value) ||
      Validation.isStringEmpty(e.target.value)
    ) {
      dialogData.surchargeByCurrencies =
        dialogData.surchargeByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: 0,
            currencyISO: data.iso,
          };
        });
      dialogData.surchargeByCurrencies = dialogData.surchargeByCurrencies.map(
        (c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            return { ...c, value: e.target.value };
          }
          return c;
        }
      );
      if (isAdd) {
        dialogData.surchargeByCurrencies.push(cur);
      }
      this.setState({ dialogData });
    }
  };

  // set value cho setting mới khi add/edit từ setting cũ
  setValueBasedSetting = (surchage) => {
    const { dialogData } = this.state;
    const currencyISO = this.props.auth.selectedFleet.currencies[0].iso;
    const differentSurchargeForDifferentService = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.differentSurchargeForDifferentService',
      false
    );
    if (differentSurchargeForDifferentService)
      return [
        {
          ...surchage[0],
          currencyISO,
        },
      ];

    return convertToDifferentSurchage(
      dialogData.surchargeByCurrencies,
      dialogData.distanceDiversity,
      currencyISO
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { rushHourAddItemSaveClick, handleAlertNotification } = this.props;
    const { dialogData } = this.state;
    if (dialogData) {
      let rushHour = {
        fleetId: this.props.auth.selectedFleet.fleetId,
        name: dialogData.name,
        surchargeByCurrencies: dialogData.surchargeByCurrencies,
        surchargeNow: this.setValueBasedSetting(dialogData.surchargeNow),
        surchargeReservation: this.setValueBasedSetting(
          dialogData.surchargeReservation
        ),
        date: [],
        start: dialogData.From.join(':'),
        end: dialogData.To.join(':'),
        timeCal: dialogData.timeCal,
        surcharge: dialogData.surcharge,
        surchargeType: dialogData.surchargeType,
        repeat: dialogData.repeatType,
        distanceDiversity: dialogData.distanceDiversity || false,
      };
      if (dialogData.dateSurcharge) {
        rushHour.startDate = moment(dialogData.dateSurcharge).format(
          'YYYY-MM-DD'
        );
      }
      Object.keys(dialogData.date).map((k) => {
        if (dialogData.date[k]) {
          rushHour.date.push(k);
        }
      });
      this.setState({ isRushHourSubmitted: true });
      if (!CCLiteCommonFunc.isFormValid(this.state.rushHourValid)) {
        return;
      }

      let items = [];
      if (rushHour.distanceDiversity) {
        // rushHour.surchargeByCurrencies.forEach(element => {
        //   element.distanceRange.forEach(distance => {
        //     if (!distance.to || distance.from > distance.to) {
        //       items.push({
        //         distance,
        //         currencyISO: element.currencyISO,
        //       });
        //     }
        //     if (!Validation.isNumber(distance.surchargeValue)) {
        //       items.push({
        //         distance,
        //         currencyISO: element.currencyISO,
        //         errorMsg: "please_enter_surchargeValue_is_number"
        //       });
        //     }
        //   })
        // });
      }
      if (items.length > 0) {
        handleAlertNotification(items);
      } else {
        rushHourAddItemSaveClick(rushHour);
      }
    }
  };

  render() {
    const {
      editable,
      rushHourAddItemCloseClick,
      surchargeByCurrenciesInitial,
      surchargeNowInitial,
      auth,
      surchargeReservationInitial,
    } = this.props;
    const {
      dialogData,
      dialogData: { surchargeNow = [], surchargeReservation = [] },
    } = this.state;
    const { hasCarHailingActive = false, shuttle = {} } =
      auth && auth.selectedFleet;
    const differentSurchargeForDifferentService = _.get(
      auth,
      'selectedFleet.generalSetting.differentSurchargeForDifferentService',
      false
    );
    return (
      <Modal onHide={rushHourAddItemCloseClick} show={true} backdrop={true}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Translate value="generalSetting.RUSH_HOUR" />
            </Modal.Title>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={rushHourAddItemCloseClick}
            >
              <span aria-hidden="true">×</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              className={
                !this.state.isRushHourSubmitted
                  ? null
                  : this.state.rushHourValid.name === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="generalSetting.Name" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormControl
                type="text"
                className={'form-custom'}
                onChange={this.handleRushHoursname}
                value={this.state.dialogData ? this.state.dialogData.name : ''}
                placeholder={I18n.t('generalSetting.Name')}
                disabled={editable}
              />
              <Validator id="name" callback={this.ValidatorRushHourCallback}>
                <ValidCase
                  hide={!this.state.isRushHourSubmitted}
                  valid={!Validation.isStringEmpty(this.state.dialogData.name)}
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
              </Validator>
            </FormGroup>
            <FormGroup>
              <Form.Label>
                <Translate value="generalSetting.Repeat" />
              </Form.Label>
              <FormControl
                as="select"
                className={'form-control form-custom'}
                value={this.state.dialogData.repeatType}
                onChange={this.handleRepeatTypeChange}
                disabled={editable}
              >
                <option value={repeatType.DayOfWeek}>
                  {I18n.t('generalSetting.DayOfWeek')}
                </option>
                <option value={repeatType.SingleDay}>
                  {I18n.t('generalSetting.SingleDay')}
                </option>
                <option value={repeatType.Yearly}>
                  {I18n.t('generalSetting.Yearly')}
                </option>
              </FormControl>
            </FormGroup>
            {this.state.dialogData.repeatType &&
            this.state.dialogData.repeatType === repeatType.DayOfWeek ? (
              <FormGroup
                className={
                  !this.state.isRushHourSubmitted
                    ? null
                    : this.state.rushHourValid.date === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="generalSetting.Date" />{' '}
                  <span className="require">*</span>
                </Form.Label>
                <Col md={12}>
                  {daysOfWeek.map((dayOfWeek) => {
                    return (
                      <div className="rush-date" key={dayOfWeek}>
                        <CcCheckbox
                          disabled={editable}
                          checked={
                            this.state.dialogData
                              ? this.state.dialogData.date[dayOfWeek]
                              : false
                          }
                          onChange={(e) => {
                            this.handleRushHourDateCheckChange(
                              dayOfWeek,
                              e.target.checked
                            );
                          }}
                          text={I18n.t(`generalSetting.${dayOfWeek}`)}
                        />
                      </div>
                    );
                  })}
                </Col>
                <Validator id="date" callback={this.ValidatorRushHourCallback}>
                  <ValidCase
                    hide={!this.state.isRushHourSubmitted}
                    valid={
                      this.state.dialogData.repeatType ===
                        repeatType.SingleDay ||
                      this.state.dialogData.repeatType === repeatType.Yearly ||
                      this.isValidSelectDate()
                    }
                    message={I18n.t('messages.commonMessages.Required_field')}
                  />
                </Validator>
              </FormGroup>
            ) : (
              <FormGroup
                className={
                  !this.state.isRushHourSubmitted
                    ? null
                    : this.state.rushHourValid.dateSurcharge === false
                    ? 'error'
                    : null
                }
              >
                <Form.Label>
                  <Translate value="generalSetting.Date" />{' '}
                  <span className="require">*</span>
                </Form.Label>
                <FormGroup>
                  <DateTime
                    inputProps={{
                      disabled: editable,
                    }}
                    value={this.state.dialogData.dateSurcharge}
                    timeFormat={false}
                    onChange={this.handleDateChanged}
                    closeOnSelect
                  />

                  <Validator
                    id="dateSurcharge"
                    callback={this.ValidatorRushHourCallback}
                  >
                    <ValidCase
                      hide={!this.state.isRushHourSubmitted}
                      valid={
                        this.state.dialogData.repeatType ===
                          repeatType.DayOfWeek ||
                        !Validation.isStringEmpty(
                          this.state.dialogData.dateSurcharge
                        )
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                    />
                  </Validator>
                </FormGroup>
              </FormGroup>
            )}
            <FormGroup>
              <Form.Label>
                <Translate value="generalSetting.Time_range" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <Row className="sub-controls">
                <Col md={6}>
                  <Form inline>
                    <FormGroup>
                      <Form.Label className={'mr-md'}>
                        <Translate value="generalSetting.From" />
                      </Form.Label>
                      <FormControl
                        as="select"
                        className={'form-control form-custom time-select'}
                        value={this.state.dialogData.From[0]}
                        onChange={this.handleRushHourFromHHChange}
                        disabled={editable}
                      >
                        {hours.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </FormControl>
                      <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                      <FormControl
                        as="select"
                        className={' form-custom time-select'}
                        value={this.state.dialogData.From[1]}
                        onChange={this.handleRushHourFromMMChange}
                        disabled={editable}
                      >
                        {minutes.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  </Form>
                </Col>
                <Col md={6}>
                  <Form inline>
                    <FormGroup>
                      <Form.Label className={'mr-md'}>
                        <Translate value="generalSetting.To" />
                      </Form.Label>
                      <FormControl
                        as="select"
                        className={'form-custom time-select'}
                        value={this.state.dialogData.To[0]}
                        onChange={this.handleRushHourToHHChange}
                        disabled={editable}
                      >
                        {hours.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}
                          </option>
                        ))}
                      </FormControl>
                      <Form.Label className={'mr-sm ml-sm'}>:</Form.Label>
                      <FormControl
                        as="select"
                        className={'form-custom time-select'}
                        value={this.state.dialogData.To[1]}
                        onChange={this.handleRushHourToMMChange}
                        disabled={editable}
                      >
                        {minutes.map((minute) => (
                          <option key={minute} value={minute}>
                            {minute}
                          </option>
                        ))}
                      </FormControl>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup
              className={
                !this.state.isRushHourSubmitted
                  ? null
                  : this.state.rushHourValid.rushHourCal === false
                  ? 'error'
                  : null
              }
            >
              <Form.Label>
                <Translate value="generalSetting.Rush_hour_time_calculation" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormGroup className={'qup-input-group'}>
                <InputGroup
                  className={
                    'single-addon-right ' + (editable ? 'disabled' : '')
                  }
                >
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={this.handleRushHourTimeCalhange}
                    onBlur={(e) => {
                      textboxNumberHelper.onBlurHandle(
                        e,
                        this.handleRushHourTimeCalhange
                      );
                    }}
                    onFocus={(e) => {
                      textboxNumberHelper.onfocusHandle(
                        e,
                        this.handleRushHourTimeCalhange
                      );
                    }}
                    value={
                      this.state.dialogData ? this.state.dialogData.timeCal : 0
                    }
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>mins</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator
                id="rushHourCal"
                callback={this.ValidatorRushHourCallback}
              >
                <ValidCase
                  hide={!this.state.isRushHourSubmitted}
                  valid={
                    !Validation.isStringEmpty(this.state.dialogData.timeCal)
                  }
                  message={I18n.t('messages.commonMessages.Required_field')}
                />
                <ValidCase
                  hide={!this.state.isRushHourSubmitted}
                  valid={Validation.isInteger(this.state.dialogData.timeCal)}
                  message={I18n.t('messages.commonMessages.must_be_integer')}
                />
                <ValidCase
                  hide={!this.state.isRushHourSubmitted}
                  valid={Validation.isGreaterOrEqual(
                    this.state.dialogData.timeCal,
                    0
                  )}
                  message={I18n.t(
                    'messages.commonMessages.greater_or_equa'
                  ).format(0)}
                />
              </Validator>
            </FormGroup>
            <FormGroup
              className={
                !this.state.isRushHourSubmitted
                  ? null
                  : this.state.rushHourValid.surcharge === false
                  ? 'error'
                  : null
              }
            >
              <CcCheckbox
                checked={dialogData.distanceDiversity}
                disabled={editable}
                onChange={this.handleChangeSurchargeDistance}
                text={
                  <span>
                    <Translate value="generalSetting.distance_diversity" />
                  </span>
                }
              />
              <Form.Label>
                <Translate value="generalSetting.Rush_hour_surcharge" />{' '}
                <span className="require">*</span>
              </Form.Label>
              <FormGroup>
                <FormControl
                  as="select"
                  className={'form-control form-custom'}
                  value={this.state.dialogData.surchargeType}
                  onChange={this.handleSurchargeTypeChange}
                  disabled={editable}
                >
                  <option value={surchargeType.PERCENT}>
                    {I18n.t('driver.Percentage')}
                  </option>
                  <option value={surchargeType.AMOUNT}>
                    {I18n.t('driver.Amount')}
                  </option>
                </FormControl>
              </FormGroup>

              {differentSurchargeForDifferentService &&
                (dialogData.surchargeType === surchargeType.PERCENT ? (
                  dialogData.distanceDiversity ? (
                    <>
                      <FormGroupTitle>Now</FormGroupTitle>
                      <div className="gridViewTable">
                        <ButtonToolbar className="text-center header-button-group">
                          <Button
                            className={'btn-header text-add-header ml0'}
                            onClick={() =>
                              this.handleAddRangeButton('surchargeNow')
                            }
                          >
                            {' '}
                            <BsPlus />
                            <Translate value="generalSetting.Add_Surcharge_Distance" />
                          </Button>
                        </ButtonToolbar>
                        <SurchargeDistanceTable
                          selectedFleet={auth.selectedFleet}
                          surchargeType={dialogData.surchargeType}
                          hasCarHailingActive={hasCarHailingActive}
                          shuttle={shuttle}
                          bookType="now"
                          data={
                            dialogData.surchargeNow[0]
                              ? dialogData.surchargeNow[0]
                              : {}
                          }
                          currency={auth.selectedFleet.currencies[0]}
                          surchargeByCurrenciesInitial={surchargeNowInitial}
                          editable={editable}
                          handleToInputChange={(index, e) =>
                            this.handleToInputChange(index, e, 'surchargeNow')
                          }
                          handleChangeSurchargeValue={(index, e, typeRate) =>
                            this.handleChangeSurchargeValue(
                              index,
                              e,
                              typeRate,
                              'surchargeNow'
                            )
                          }
                          handleRemoveItemClick={(item, index) =>
                            this.handleRemoveSurchargeItem(
                              item,
                              index,
                              'surchargeNow'
                            )
                          }
                        />
                      </div>

                      <FormGroupTitle>Reservation</FormGroupTitle>
                      <div className="gridViewTable">
                        <ButtonToolbar className="text-center header-button-group">
                          <Button
                            className={'btn-header text-add-header ml0'}
                            onClick={(e) =>
                              this.handleAddRangeButton('surchargeReservation')
                            }
                          >
                            {' '}
                            <BsPlus />
                            <Translate value="generalSetting.Add_Surcharge_Distance" />
                          </Button>
                        </ButtonToolbar>
                        <SurchargeDistanceTable
                          selectedFleet={auth.selectedFleet}
                          surchargeType={dialogData.surchargeType}
                          hasCarHailingActive={hasCarHailingActive}
                          shuttle={shuttle}
                          bookType="reservation"
                          data={
                            dialogData.surchargeReservation[0]
                              ? dialogData.surchargeReservation[0]
                              : {}
                          }
                          currency={auth.selectedFleet.currencies[0]}
                          surchargeByCurrenciesInitial={
                            surchargeReservationInitial
                          }
                          editable={editable}
                          handleToInputChange={(index, e) =>
                            this.handleToInputChange(
                              index,
                              e,
                              'surchargeReservation'
                            )
                          }
                          handleChangeSurchargeValue={(index, e, typeRate) =>
                            this.handleChangeSurchargeValue(
                              index,
                              e,
                              typeRate,
                              'surchargeReservation'
                            )
                          }
                          handleRemoveItemClick={(item, index) =>
                            this.handleRemoveSurchargeItem(
                              item,
                              index,
                              'surchargeReservation'
                            )
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <div>
                      <>
                        <FormGroupTitle>Now</FormGroupTitle>
                        <Row>
                          {surchargeNow &&
                            surchargeNow[0] &&
                            surchargeNow[0].surchargeByServices &&
                            surchargeNow[0].surchargeByServices.map(
                              (surcharge) => {
                                if (
                                  surcharge.typeRate === 'shuttle' ||
                                  (surcharge.typeRate === 'carHailing' &&
                                    !hasCarHailingActive)
                                )
                                  return null;

                                return (
                                  <Col xs={6} md={4} key={surcharge.typeRate}>
                                    <FormGroup>
                                      <Form.Label>
                                        <Translate
                                          value={`generalSetting.${surcharge.typeRate}`}
                                        />
                                      </Form.Label>
                                      <FormGroup className={'qup-input-group'}>
                                        <InputGroup
                                          className={'single-addon-left'}
                                        >
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            value={surcharge.value}
                                            onChange={(e) =>
                                              this.handleSurchargeChange(
                                                e,
                                                surcharge.typeRate,
                                                'surchargeNow'
                                              )
                                            }
                                            disabled={editable}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) =>
                                                  this.handleSurchargeChange(
                                                    e,
                                                    surcharge.typeRate,
                                                    'surchargeNow'
                                                  )
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) =>
                                                  this.handleSurchargeChange(
                                                    e,
                                                    surcharge.typeRate,
                                                    'surchargeNow'
                                                  )
                                              );
                                            }}
                                          />
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>%</InputGroup.Text>
                                          </InputGroup.Prepend>
                                        </InputGroup>
                                        <WarningCase
                                          validator={
                                            this.state.rushHourValid
                                              .percentSurcharge
                                          }
                                          message={I18n.t(
                                            'message.warningInputChangeOver'
                                          )}
                                          initialValue={
                                            surchargeByCurrenciesInitial.length ==
                                              1 &&
                                            surchargeByCurrenciesInitial[0]
                                              .currencyISO == ''
                                              ? surchargeByCurrenciesInitial[0]
                                                  .value
                                              : 0
                                          }
                                          onChangeValue={
                                            dialogData.surchargeByCurrencies[0]
                                              ? dialogData
                                                  .surchargeByCurrencies[0]
                                                  .value
                                              : 0
                                          }
                                          range={20}
                                          typePercent={false}
                                        />
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>
                                );
                              }
                            )}
                        </Row>
                      </>
                      <>
                        <FormGroupTitle>Reservation</FormGroupTitle>
                        <Row>
                          {surchargeReservation &&
                            surchargeReservation[0] &&
                            surchargeReservation[0].surchargeByServices &&
                            surchargeReservation[0].surchargeByServices.map(
                              (surcharge) => {
                                if (
                                  surcharge.typeRate === 'carHailing' ||
                                  (surcharge.typeRate === 'shuttle' &&
                                    !shuttle.enable)
                                )
                                  return null;

                                return (
                                  <Col xs={6} md={4} key={surcharge.typeRate}>
                                    <FormGroup>
                                      <Form.Label>
                                        <Translate
                                          value={`generalSetting.${surcharge.typeRate}`}
                                        />
                                      </Form.Label>
                                      <FormGroup className={'qup-input-group'}>
                                        <InputGroup
                                          className={'single-addon-left'}
                                          >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>%</InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            value={surcharge.value}
                                            onChange={(e) =>
                                              this.handleSurchargeChange(
                                                e,
                                                surcharge.typeRate,
                                                'surchargeReservation'
                                              )
                                            }
                                            disabled={editable}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) =>
                                                  this.handleSurchargeChange(
                                                    e,
                                                    surcharge.typeRate,
                                                    'surchargeReservation'
                                                  )
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) =>
                                                  this.handleSurchargeChange(
                                                    e,
                                                    surcharge.typeRate,
                                                    'surchargeReservation'
                                                  )
                                              );
                                            }}
                                          />
                                        </InputGroup>
                                        <WarningCase
                                          validator={
                                            this.state.rushHourValid
                                              .percentSurcharge
                                          }
                                          message={I18n.t(
                                            'message.warningInputChangeOver'
                                          )}
                                          initialValue={
                                            surchargeByCurrenciesInitial.length ==
                                              1 &&
                                            surchargeByCurrenciesInitial[0]
                                              .currencyISO == ''
                                              ? surchargeByCurrenciesInitial[0]
                                                  .value
                                              : 0
                                          }
                                          onChangeValue={
                                            dialogData.surchargeByCurrencies[0]
                                              ? dialogData
                                                  .surchargeByCurrencies[0]
                                                  .value
                                              : 0
                                          }
                                          range={20}
                                          typePercent={false}
                                        />
                                      </FormGroup>
                                    </FormGroup>
                                  </Col>
                                );
                              }
                            )}
                        </Row>
                      </>
                    </div>
                  )
                ) : dialogData.distanceDiversity ? (
                  <>
                    <FormGroupTitle>Now</FormGroupTitle>
                    <div className="gridViewTable">
                      <ButtonToolbar className="text-center header-button-group">
                        <Button
                          className={'btn-header text-add-header ml0'}
                          onClick={() =>
                            this.handleAddRangeButton('surchargeNow')
                          }
                        >
                          {' '}
                          <BsPlus />
                          <Translate value="generalSetting.Add_Surcharge_Distance" />
                        </Button>
                      </ButtonToolbar>
                      <SurchargeDistanceTable
                        selectedFleet={auth.selectedFleet}
                        surchargeType={dialogData.surchargeType}
                        hasCarHailingActive={hasCarHailingActive}
                        shuttle={shuttle}
                        bookType="now"
                        data={
                          dialogData.surchargeNow[0]
                            ? dialogData.surchargeNow[0]
                            : {}
                        }
                        currency={auth.selectedFleet.currencies[0]}
                        surchargeByCurrenciesInitial={surchargeNowInitial}
                        editable={editable}
                        handleToInputChange={(index, e) =>
                          this.handleToInputChange(index, e, 'surchargeNow')
                        }
                        handleChangeSurchargeValue={(index, e, typeRate) =>
                          this.handleChangeSurchargeValue(
                            index,
                            e,
                            typeRate,
                            'surchargeNow'
                          )
                        }
                        handleRemoveItemClick={(item, index) =>
                          this.handleRemoveSurchargeItem(
                            item,
                            index,
                            'surchargeNow'
                          )
                        }
                      />
                    </div>

                    <FormGroupTitle>Reservation</FormGroupTitle>
                    <div className="gridViewTable">
                      <ButtonToolbar className="text-center header-button-group">
                        <Button
                          className={'btn-header text-add-header ml0'}
                          onClick={(e) =>
                            this.handleAddRangeButton('surchargeReservation')
                          }
                        >
                          {' '}
                          <BsPlus />
                          <Translate value="generalSetting.Add_Surcharge_Distance" />
                        </Button>
                      </ButtonToolbar>
                      <SurchargeDistanceTable
                        selectedFleet={auth.selectedFleet}
                        hasCarHailingActive={hasCarHailingActive}
                        shuttle={shuttle}
                        bookType="reservation"
                        surchargeType={dialogData.surchargeType}
                        data={
                          dialogData.surchargeReservation[0]
                            ? dialogData.surchargeReservation[0]
                            : {}
                        }
                        currency={auth.selectedFleet.currencies[0]}
                        surchargeByCurrenciesInitial={
                          surchargeReservationInitial
                        }
                        editable={editable}
                        handleToInputChange={(index, e) =>
                          this.handleToInputChange(
                            index,
                            e,
                            'surchargeReservation'
                          )
                        }
                        handleChangeSurchargeValue={(index, e, typeRate) =>
                          this.handleChangeSurchargeValue(
                            index,
                            e,
                            typeRate,
                            'surchargeReservation'
                          )
                        }
                        handleRemoveItemClick={(item, index) =>
                          this.handleRemoveSurchargeItem(
                            item,
                            index,
                            'surchargeReservation'
                          )
                        }
                      />
                    </div>
                  </>
                ) : (
                  <div>
                    <>
                      <FormGroupTitle>Now</FormGroupTitle>
                      <Row>
                        {surchargeNow &&
                          surchargeNow[0] &&
                          surchargeNow[0].surchargeByServices &&
                          surchargeNow[0].surchargeByServices.map(
                            (surcharge) => {
                              if (
                                surcharge.typeRate === 'shuttle' ||
                                (surcharge.typeRate === 'carHailing' &&
                                  !hasCarHailingActive)
                              )
                                return null;

                              return (
                                <Col xs={6} md={4} key={surcharge.typeRate}>
                                  <FormGroup>
                                    <Form.Label>
                                      <Translate
                                        value={`generalSetting.${surcharge.typeRate}`}
                                      />
                                    </Form.Label>
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={'single-addon-left'}
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {
                                              this.props.auth.selectedFleet
                                                .currencies[0].symbol
                                            }
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          value={surcharge.value}
                                          onChange={(e) =>
                                            this.handleSurchargeChange(
                                              e,
                                              surcharge.typeRate,
                                              'surchargeNow'
                                            )
                                          }
                                          disabled={editable}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) =>
                                                this.handleSurchargeChange(
                                                  e,
                                                  surcharge.typeRate,
                                                  'surchargeNow'
                                                )
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) =>
                                                this.handleSurchargeChange(
                                                  e,
                                                  surcharge.typeRate,
                                                  'surchargeNow'
                                                )
                                            );
                                          }}
                                        />
                                      </InputGroup>
                                      <WarningCase
                                        validator={
                                          this.state.rushHourValid
                                            .percentSurcharge
                                        }
                                        message={I18n.t(
                                          'message.warningInputChangeOver'
                                        )}
                                        initialValue={
                                          surchargeByCurrenciesInitial.length ==
                                            1 &&
                                          surchargeByCurrenciesInitial[0]
                                            .currencyISO == ''
                                            ? surchargeByCurrenciesInitial[0]
                                                .value
                                            : 0
                                        }
                                        onChangeValue={
                                          dialogData.surchargeByCurrencies[0]
                                            ? dialogData
                                                .surchargeByCurrencies[0].value
                                            : 0
                                        }
                                        range={20}
                                        typePercent={false}
                                      />
                                    </FormGroup>
                                  </FormGroup>
                                </Col>
                              );
                            }
                          )}
                      </Row>
                    </>
                    <>
                      <FormGroupTitle>Reservation</FormGroupTitle>
                      <Row>
                        {surchargeReservation &&
                          surchargeReservation[0] &&
                          surchargeReservation[0].surchargeByServices &&
                          surchargeReservation[0].surchargeByServices.map(
                            (surcharge) => {
                              if (
                                surcharge.typeRate === 'carHailing' ||
                                (surcharge.typeRate === 'shuttle' &&
                                  !shuttle.enable)
                              )
                                return null;

                              return (
                                <Col xs={6} md={4} key={surcharge.typeRate}>
                                  <FormGroup>
                                    <Form.Label>
                                      <Translate
                                        value={`generalSetting.${surcharge.typeRate}`}
                                      />
                                    </Form.Label>
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={'single-addon-left'}
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {
                                              this.props.auth.selectedFleet
                                                .currencies[0].symbol
                                            }
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          value={surcharge.value}
                                          onChange={(e) =>
                                            this.handleSurchargeChange(
                                              e,
                                              surcharge.typeRate,
                                              'surchargeReservation'
                                            )
                                          }
                                          disabled={editable}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) =>
                                                this.handleSurchargeChange(
                                                  e,
                                                  surcharge.typeRate,
                                                  'surchargeReservation'
                                                )
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) =>
                                                this.handleSurchargeChange(
                                                  e,
                                                  surcharge.typeRate,
                                                  'surchargeReservation'
                                                )
                                            );
                                          }}
                                        />
                                      </InputGroup>
                                      <WarningCase
                                        validator={
                                          this.state.rushHourValid
                                            .percentSurcharge
                                        }
                                        message={I18n.t(
                                          'message.warningInputChangeOver'
                                        )}
                                        initialValue={
                                          surchargeByCurrenciesInitial.length ==
                                            1 &&
                                          surchargeByCurrenciesInitial[0]
                                            .currencyISO == ''
                                            ? surchargeByCurrenciesInitial[0]
                                                .value
                                            : 0
                                        }
                                        onChangeValue={
                                          dialogData.surchargeByCurrencies[0]
                                            ? dialogData
                                                .surchargeByCurrencies[0].value
                                            : 0
                                        }
                                        range={20}
                                        typePercent={false}
                                      />
                                    </FormGroup>
                                  </FormGroup>
                                </Col>
                              );
                            }
                          )}
                      </Row>
                    </>
                  </div>
                ))}

              {!differentSurchargeForDifferentService &&
                (dialogData.surchargeType === surchargeType.PERCENT ? (
                  dialogData.distanceDiversity ? (
                    <div className="gridViewTable">
                      <ButtonToolbar className="text-center header-button-group">
                        <Button
                          className={'btn-header text-add-header ml0'}
                          onClick={this.handleAddRangeButtonDifferent}
                        >
                          {' '}
                          <BsPlus />
                          <Translate value="generalSetting.Add_Surcharge_Distance" />
                        </Button>
                      </ButtonToolbar>
                      <SurchargeDistanceTableDifferent
                        selectedFleet={auth.selectedFleet}
                        surchargeType={dialogData.surchargeType}
                        data={
                          dialogData.surchargeByCurrencies[0]
                            ? dialogData.surchargeByCurrencies[0]
                            : {}
                        }
                        currency={auth.selectedFleet.currencies[0]}
                        surchargeByCurrenciesInitial={
                          surchargeByCurrenciesInitial
                        }
                        editable={editable}
                        handleToInputChange={this.handleToInputChangeDifferent}
                        handleChangeSurchargeValue={
                          this.handleChangeSurchargeValueDifferent
                        }
                        handleRemoveItemClick={
                          this.handleRemoveSurchargeItemDifferent
                        }
                      />
                    </div>
                  ) : (
                    <FormGroup
                      className={
                        this.state.isRushHourSubmitted
                          ? this.state.rushHourValid.percentSurcharge
                            ? null
                            : 'error'
                          : null
                      }
                    >
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup className={'single-addon-left'}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>%</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            value={
                              dialogData.surchargeByCurrencies[0]
                                ? dialogData.surchargeByCurrencies[0].value
                                : 0
                            }
                            onChange={this.handlePercentSurchargeChange}
                            disabled={editable}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(
                                e,
                                this.handlePercentSurchargeChange
                              );
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(
                                e,
                                this.handlePercentSurchargeChange
                              );
                            }}
                          />
                        </InputGroup>
                        <WarningCase
                          validator={this.state.rushHourValid.percentSurcharge}
                          message={I18n.t('message.warningInputChangeOver')}
                          initialValue={
                            surchargeByCurrenciesInitial.length == 1 &&
                            surchargeByCurrenciesInitial[0].currencyISO == ''
                              ? surchargeByCurrenciesInitial[0].value
                              : 0
                          }
                          onChangeValue={
                            dialogData.surchargeByCurrencies[0]
                              ? dialogData.surchargeByCurrencies[0].value
                              : 0
                          }
                          range={20}
                          typePercent={false}
                        />
                      </FormGroup>
                    </FormGroup>
                  )
                ) : auth.selectedFleet.multiCurrencies ? (
                  <Tabs
                    id="currencies-tabs-Ondemand"
                    className="currencies-tabs"
                    animation={false}
                    defaultActiveKey={auth.selectedFleet.currencies[0].iso}
                    activeKey={this.state.selectedTabCurrency.currencyISO}
                    onSelect={this.handleSelect}
                  >
                    {auth.selectedFleet.currencies.map((c) => {
                      let currentValue =
                        this.state.dialogData.surchargeByCurrencies.filter(
                          (d) => {
                            return d.currencyISO === c.iso;
                          }
                        )[0];
                      return (
                        <Tab
                          className="currencies-tab-item"
                          eventKey={c.iso}
                          title={c.iso}
                          key={c.iso}
                        >
                          {dialogData.distanceDiversity ? (
                            <div className="gridViewTable">
                              <ButtonToolbar className="text-center header-button-group">
                                <Button
                                  className={'btn-header text-add-header ml0'}
                                  onClick={(e) =>
                                    this.handleAddRangeButtonByCurrency(c.iso)
                                  }
                                >
                                  {' '}
                                  <BsPlus />
                                  <Translate value="generalSetting.Add_Surcharge_Distance" />
                                </Button>
                              </ButtonToolbar>
                              <SurchargeDistanceTableDifferent
                                selectedFleet={auth.selectedFleet}
                                surchargeType={dialogData.surchargeType}
                                data={currentValue}
                                currency={c}
                                surchargeByCurrenciesInitial={
                                  surchargeByCurrenciesInitial
                                }
                                editable={editable}
                                handleToInputChange={
                                  this.handleToInputChangeDifferent
                                }
                                handleChangeSurchargeValue={
                                  this.handleChangeSurchargeValueDifferent
                                }
                                handleRemoveItemClick={
                                  this.handleRemoveSurchargeItemDifferent
                                }
                              />
                            </div>
                          ) : (
                            <FormGroup>
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup
                                  className={
                                    'single-addon-left ' +
                                    (editable ? 'disabled' : '')
                                  }
                                >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {c.symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        c.iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      currentValue ? currentValue.value : 0
                                    }
                                    disabled={editable}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </FormGroup>
                          )}
                          <WarningCase
                            validator={true}
                            message={I18n.t('message.warningInputChangeOver')}
                            initialValue={
                              surchargeByCurrenciesInitial[0] &&
                              surchargeByCurrenciesInitial[0].currencyISO == ''
                                ? []
                                : this.props.surchargeByCurrenciesInitial.map(
                                    (ob) => ob.value
                                  )
                            }
                            onChangeValue={this.state.dialogData.surchargeByCurrencies.map(
                              (ob) => ob.value
                            )}
                            range={20}
                            typePercent={false}
                          />
                        </Tab>
                      );
                    })}
                  </Tabs>
                ) : dialogData.distanceDiversity ? (
                  <div className="gridViewTable">
                    <ButtonToolbar className="text-center header-button-group">
                      <Button
                        className={'btn-header text-add-header ml0'}
                        onClick={this.handleAddRangeButtonDifferent}
                      >
                        {' '}
                        <BsPlus />
                        <Translate value="generalSetting.Add_Surcharge_Distance" />
                      </Button>
                    </ButtonToolbar>
                    <SurchargeDistanceTableDifferent
                      selectedFleet={auth.selectedFleet}
                      surchargeType={dialogData.surchargeType}
                      data={
                        dialogData.surchargeByCurrencies[0]
                          ? dialogData.surchargeByCurrencies[0]
                          : {}
                      }
                      currency={auth.selectedFleet.currencies[0]}
                      surchargeByCurrenciesInitial={
                        surchargeByCurrenciesInitial
                      }
                      editable={editable}
                      handleToInputChange={this.handleToInputChangeDifferent}
                      handleChangeSurchargeValue={
                        this.handleChangeSurchargeValueDifferent
                      }
                      handleRemoveItemClick={
                        this.handleRemoveSurchargeItemDifferent
                      }
                    />
                  </div>
                ) : (
                  <FormGroup className={'qup-input-group'}>
                    <InputGroup
                      className={
                        'single-addon-left ' + (editable ? 'disabled' : '')
                      }
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          {this.props.auth.selectedFleet.currencies[0].symbol}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        className={'form-custom'}
                        onChange={(e) => {
                          this.handleCancelInputMultiCurrenciesChange(
                            this.props.auth.selectedFleet.currencies[0].iso,
                            e
                          );
                        }}
                        onBlur={(e) => {
                          textboxNumberHelper.onBlurHandle(e, (e) => {
                            this.handleCancelInputMultiCurrenciesChange(
                              this.props.auth.selectedFleet.currencies[0].iso,
                              e
                            );
                          });
                        }}
                        onFocus={(e) => {
                          textboxNumberHelper.onfocusHandle(e, (e) => {
                            this.handleCancelInputMultiCurrenciesChange(
                              this.props.auth.selectedFleet.currencies[0].iso,
                              e
                            );
                          });
                        }}
                        value={
                          this.state.dialogData
                            ? this.state.dialogData.surchargeByCurrencies.filter(
                                (d) => {
                                  return (
                                    d.currencyISO ==
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso
                                  );
                                }
                              )[0]
                              ? this.state.dialogData.surchargeByCurrencies.filter(
                                  (d) => {
                                    return (
                                      d.currencyISO ==
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso
                                    );
                                  }
                                )[0].value
                              : 0
                            : 0
                        }
                        disabled={editable}
                      />
                    </InputGroup>
                    <WarningCase
                      validator={true}
                      message={I18n.t('message.warningInputChangeOver')}
                      initialValue={
                        surchargeByCurrenciesInitial[0] &&
                        surchargeByCurrenciesInitial[0].currencyISO == ''
                          ? []
                          : this.props.surchargeByCurrenciesInitial.map(
                              (ob) => ob.value
                            )
                      }
                      onChangeValue={this.state.dialogData.surchargeByCurrencies.map(
                        (ob) => ob.value
                      )}
                      range={20}
                      typePercent={false}
                    />
                  </FormGroup>
                ))}
              <Validator
                id="surcharge"
                callback={this.ValidatorRushHourCallback}
              >
                <ValidCase
                  valid={
                    this.state.dialogData.surchargeByCurrencies.filter((d) => {
                      return Validation.isStringEmpty(d.value);
                    }).length == 0
                  }
                  message={I18n.t('messages.commonMessages.Required_field')}
                  hide={!this.state.isRushHourSubmitted}
                />
                <ValidCase
                  valid={
                    this.state.dialogData.surchargeByCurrencies.filter((d) => {
                      return (
                        !Validation.isStringEmpty(d.value) && isNaN(d.value)
                      );
                    }).length == 0
                  }
                  message={I18n.t('messages.commonMessages.must_be_number')}
                  hide={!this.state.isRushHourSubmitted}
                />
                <ValidCase
                  valid={
                    this.state.dialogData.surchargeByCurrencies.filter((d) => {
                      return !Validation.isGreaterOrEqual(d.value, 0);
                    }).length == 0
                  }
                  message={I18n.t(
                    'messages.commonMessages.greater_or_equa'
                  ).format('0')}
                  hide={!this.state.isRushHourSubmitted}
                />
              </Validator>
            </FormGroup>
            <Form.Label>
              (<span className="require">*</span>
              ): <Translate value="generalSetting.Required_fields" />
            </Form.Label>
          </Modal.Body>
          <Modal.Footer>
            <Button className={'btn-save mr-md'} type="submit">
              <Translate value="generalSetting.Save" />
            </Button>
            <Button
              className={'btn-cancel'}
              onClick={rushHourAddItemCloseClick}
            >
              <Translate value="generalSetting.Cancel" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default ModalAddSurcharge;
