import * as _ from 'lodash';
import { I18n } from 'react-redux-i18n';
import { merchantCreditTransactionTypes } from '../../../constants/commondata';
import ReportUtils from './../base/utils';

export const fields = [
  {
    key: 'dateTime',
    label: 'report.result.merchantCashTransactionHistory.dateTime',
    mutate: ReportUtils.formatTime,
  },
  {
    key: 'companyName',
    label: 'report.result.merchantCashTransactionHistory.companyName',
  },
  {
    key: 'merchantUserName',
    label: 'report.result.merchantCashTransactionHistory.merchantUserName',
  },
  {
    key: 'merchantUserPhone',
    label: 'report.result.merchantCashTransactionHistory.merchantUserPhone',
  },
  {
    key: 'merchantUserEmail',
    label: 'report.result.merchantCashTransactionHistory.merchantUserEmail',
  },
  {
    key: 'merchantName',
    label: 'report.result.merchantCashTransactionHistory.merchantName',
  },
  {
    key: 'transactionType',
    label: 'report.result.merchantCashTransactionHistory.transactionType',
    mutate: (value) =>
      I18n.t(
        _.get(_.keyBy(merchantCreditTransactionTypes, 'value'), [
          value,
          'label',
        ]) || value
      ),
  },
  {
    key: 'bookId',
    label: 'report.result.merchantCashTransactionHistory.bookId',
  },
  {
    key: 'transactionId',
    label: 'report.result.merchantCashTransactionHistory.transactionId',
  },
  {
    key: 'operatorName',
    label: 'report.result.merchantCashTransactionHistory.operatorName',
  },
  {
    key: 'reason',
    label: 'report.result.merchantCashTransactionHistory.reason',
  },
  {
    key: 'amount',
    label: 'report.result.merchantCashTransactionHistory.amount',
    summary: 'amount',
    negativable: true,
  },
  {
    key: 'newBalance',
    label: 'report.result.merchantCashTransactionHistory.newBalance',
    negativable: true,
  },
  {
    key: 'currencyISO',
    label: 'report.result.merchantCashTransactionHistory.currencyISO',
  },
];

export const fareFields = ['amount', 'newBalance'];
