import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  Modal,
} from 'react-bootstrap';
import moment from 'moment-timezone';
import _ from 'lodash';

import Select from '../../../../components/select/Select';
import Datetime from '../../../../components/dateTime/DateTime';
import RadioButton from '../../../../components/radioButton/radio';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';

import { Validator, ValidCase } from '../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
} from '../../../../utils/commonFunctions';

class AddEditVoucher extends Component {
  constructor() {
    super();
    this.state = {
      valid: {},
      isSubmited: false,
      dialogData: {
        campaign: {
          _id: '',
        },
        promotionCode: '',
        zoneId: [],
      },
    };
  }

  componentDidMount() {
    const { data, valid, isSubmited } = this.props;
    this.setState({
      valid,
      isSubmited,
    });

    if (data) {
      this.setState({
        dialogData: data,
      });
    }
  }

  handleChange = (event, key, flag = false) => {
    switch (key) {
      case 'CustomerType':
        this.props.onChangeCustomerType(event);
        break;

      case 'budgetLimit':
        this.props.handleBudgetLimitChange(flag);
        break;

      default:
        break;
    }
  };

  validFromDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  render() {
    const {
      selectedFleet,
      showDialogModal,
      disable,
      isEditable,
      isDetailForm,
      phonesImportExcel,
      isCustomerImportedExcel,
      campaignList,
      currencies,
      zonesToSelect,
      receiverList,
      permissions,
      ValidatorCallback,
      valid,
      isSubmited,
    } = this.props;
    const { dialogData } = this.state;
    const { value: amountValue = 0 } = dialogData || {};
    const isEnabledVerify = selectedFleet.generalSetting.promoForVerifiedPax;
    let isDisableValidDate = dialogData._id ? !isEditable : disable;
    let isExpired = false;
    if (new Date() > Date.parse(dialogData.validTo)) {
      isExpired = true;
    }

    return (
      <Modal
        onHide={this.props.closeFormModal}
        show={showDialogModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {dialogData._id ? (
              <Translate value="voucherSettings.Voucher_code" />
            ) : (
              <Translate value="voucherSettings.Add_Voucher_code" />
            )}
          </Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeFormModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <FormGroup
            className={
              !isSubmited ? null : valid.voucherCode === true ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="voucherSettings.Voucher_code" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <FormControl
              type="text"
              className="form-custom"
              onChange={this.props.handleVoucherCodeChange}
              value={dialogData ? dialogData.voucherCode : ''}
              placeholder={I18n.t('voucherSettings.Voucher_code')}
              disabled={disable}
            />
            <Validator id="voucherCode" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={
                  !Validation.isStringEmpty(dialogData.voucherCode) &&
                  (dialogData.voucherCode || '').length > 3
                }
                message={
                  dialogData.voucherCode
                    ? I18n.t(
                        'voucherSettings.Please_input_voucher_code_at_least_4_characters'
                      )
                    : I18n.t('voucherSettings.Please_input_voucher_code')
                }
              />
            </Validator>
          </FormGroup>

          <FormGroup
            className={
              !isSubmited ? null : valid.campaign ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="voucherSettings.Campaign" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <select
              type="select"
              className="form-control form-custom"
              onChange={this.props.handleCampaignChange}
              value={dialogData.campaign ? dialogData.campaign._id : ''}
              placeholder={I18n.t('voucherSettings.Campaign')}
              disabled={disable}
            >
              <option value="">
                {I18n.t('voucherSettings.Select_campaign')}
              </option>
              {campaignList && campaignList.length > 0
                ? campaignList.map((obj, index) => {
                    return (
                      <option value={obj._id} key={index}>
                        {obj.name}
                      </option>
                    );
                  })
                : null}
            </select>
            <Validator id="campaign" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.campaign._id)}
                message={I18n.t('voucherSettings.Please_select_campaign')}
              />
            </Validator>
          </FormGroup>

          {currencies && currencies.length > 1 ? (
            <FormGroup>
              <Form.Label>
                <Translate value="voucherSettings.Currency" />
              </Form.Label>
              <select
                className="form-control form-custom"
                value={dialogData.currencyISO}
                onChange={this.props.onChangeCurrency}
                disabled={disable}
              >
                {currencies.map((c) => {
                  return (
                    <option value={c.iso} key={c.iso}>
                      {c.iso}
                    </option>
                  );
                })}
              </select>
            </FormGroup>
          ) : null}

          <FormGroup
            className={`qup-input-group ${
              !isSubmited ? null : valid.promotionAmount ? null : 'error'
            }`}
          >
            <Form.Label>
              <Translate value="voucherSettings.Value" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <InputGroup
              className={'single-addon-left ' + (disable ? 'disabled' : '')}
            >
              <InputGroup.Prepend>
                <InputGroup.Text>
                  {currencies &&
                  currencies.find((obj) => obj.iso == dialogData.currencyISO)
                    ? currencies.find(
                        (obj) => obj.iso == dialogData.currencyISO
                      ).symbol
                    : ''}
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                step={0}
                min={0}
                className="form-custom number-no-spin"
                disabled={disable}
                onChange={this.props.handleAmountValueChange}
                value={dialogData.value}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </InputGroup>
            <Validator id="promotionAmount" callback={ValidatorCallback}>
              <ValidCase
                valid={!Validation.isStringEmpty(dialogData.value)}
                message={I18n.t('voucherSettings.Please_input_value')}
                hide={!isSubmited}
              />
              <ValidCase
                valid={Validation.isNumber(dialogData.value)}
                message={I18n.t('messages.commonMessages.must_be_number')}
                hide={!isSubmited}
              />
            </Validator>
          </FormGroup>

          {/* Validate From */}
          <FormGroup
            className={
              !isSubmited ? null : valid.validFrom ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="voucherSettings.Valid_from" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <Datetime
              onChange={this.props.handleValidFromChanged}
              inputProps={{
                readOnly: true,
                disabled: isDisableValidDate,
              }}
              value={
                dialogData.validFrom
                  ? moment(dialogData.validFrom).format('l')
                  : ''
              }
              isValidDate={this.validFromDate}
              closeOnSelect
              checkShowOnTop
              parrentMarginBottom={500}
              timeFormat={false}
            />
            <Validator id="validFrom" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.validFrom)}
                message={I18n.t('voucherSettings.Please_select_date')}
              />
            </Validator>
          </FormGroup>

          {/* Validate To */}
          <FormGroup
            className={
              !isSubmited ? null : valid.validTo ? null : 'error'
            }
          >
            <Form.Label>
              <Translate value="voucherSettings.Valid_to" />{' '}
              <span className="require">*</span>
            </Form.Label>
            <Datetime
              onChange={this.props.handleValidToChanged}
              inputProps={{
                readOnly: true,
                disabled: isDisableValidDate,
              }}
              value={
                dialogData.validTo ? moment(dialogData.validTo).format('l') : ''
              }
              isValidDate={this.validFromDate}
              closeOnSelect
              checkShowOnTop
              parrentMarginBottom={500}
              timeFormat={false}
            />
            <Validator id="validTo" callback={ValidatorCallback}>
              <ValidCase
                hide={!isSubmited}
                valid={!Validation.isStringEmpty(dialogData.validTo)}
                message={I18n.t('voucherSettings.Please_select_date')}
              />
            </Validator>
          </FormGroup>

          <Form.Label>
            (<span className="require">*</span>):{' '}
            <Translate value="voucherSettings.Required_fields" />
          </Form.Label>
        </Modal.Body>

        <Modal.Footer>
          {!permissions || permissions.actions ? (
            disable ? (
              isEditable ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.props.handleUpdatePromoCode}
                >
                  <Translate value="voucherSettings.Save" />
                </Button>
              ) : dialogData.isUsed === '' && !isExpired ? (
                <Button
                  className="btn-save mr-md"
                  onClick={this.props.handleEditPromoCode}
                >
                  <Translate value="voucherSettings.Edit" />
                </Button>
              ) : null
            ) : (
              <Button
                className="btn-save mr-md"
                onClick={this.props.saveDialogForm}
              >
                <Translate value="voucherSettings.Save" />
              </Button>
            )
          ) : null}
          <Button className="btn-cancel" onClick={this.props.closeFormModal}>
            <Translate value="voucherSettings.Cancel" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddEditVoucher;
