import React, { Component } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    Col, Row, FormGroup, FormControl, Form, Button, Modal,  InputGroup,
} from 'react-bootstrap';

import { Validator, ValidCase } from '../../../components/validator';

import { CCLiteCommonFunc, Validation } from '../../../utils/commonFunctions';

import * as appBanerActions from '../../../actions/appBanerActions';
import * as uploadActions from '../../../actions/uploadActions';
import * as loadingBarActions from '../../../actions/loadingBarActions';
import UploadFile from '../../../components/upload/upload';
import UploadImageTemplate from '../../../components/upload/ImageUploadWrapper';
import CcCheckbox from '../../../components/ccCheckbox/CcCheckbox';
import '../settings.scss';
import * as settingActions from "../../../actions/settingActions";
class AddEditPlaybackSettingModal extends Component {
    constructor(props) {
        const playbackSetting = _.assign({}, props.playbackSetting);
        super(props);
        this.state = {
            valid: {},
            playbackSetting:playbackSetting,
            editable: props.editable
        };
    }

    handleInputChange = (key, event) => {
        const { playbackSetting } = this.state;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            playbackSetting:
            {
                ...playbackSetting,
                [key]: value
            }
        });
    };

    saveDialogForm = async e => {
        e.preventDefault();
        this.setState({ isSubmitted: true });
        if (!this.state.isSubmited) {
            this.setState({ isSubmited: true });
        }
        if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
            return;
        }
        let ob = {
            fleetId: this.props.auth.selectedFleet.fleetId,
            playbackSetting: this.state.playbackSetting
        };
        this.props.settingActions.savePlaybackSetting(ob).then(data => {
            if (data.ok) {
                this.props.onAddBannerSuccess(this.state.playbackSetting);
                this.context.notification(
                    "success",
                    I18n.t("appBanner.Update_playback_success")
                );
            } else {
                if (data.error) {
                    this.context.notification(
                        "error",
                        I18n.t("errors." + data.error.errorCode)
                    );
                } else {
                    this.context.notification(
                        "error",
                        I18n.t("appBanner.Update_playback_fail")
                    );
                }
            }
        });
    };

    onEditable = () => {
        this.setState({ editable: true });
    };

    closeDialogForm = () => {
        const { closeDialogForm } = this.props;
        closeDialogForm();
    };

    ValidatorCallback = (id, valid) => {
        if (this.state.valid[id] !== valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    };

    render() {
        const {
            playbackSetting, isSubmitted, valid, editable
            } = this.state;
        return (
            <Modal show backdrop bsSize="sm" onHide={this.closeDialogForm}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Translate value="appBanner.playback_settings" />
                    </Modal.Title>
                    <button type="button" className="close" aria-label="Close" onClick={this.closeDialogForm}>
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <CcCheckbox
                        checked={playbackSetting.enable}
                        disabled={!editable}
                        onChange={e => {
                          this.handleInputChange('enable', e);
                        }}
                                    text={(
                          <span>
                            <Translate value="appBanner.enable_playback" />
                          </span>
                        )}
                        labelClassName="normal"
                        />
                        {
                        this.state.playbackSetting.enable && (
                        <div>
                        <Col xs={6} md={8} >
                            <FormGroup as={Col}
                                className={`apply-to-control ${isSubmitted ? (valid.displayBannerAgainAfter === false ? 'error' : null) : null}`}
                                >
                                <Form.Label>
                                    <Translate value="appBanner.display_banner_again_after" />
                                    <span className="require">&nbsp;*</span>
                                </Form.Label>
                                <FormControl
                                    className={"form-custom number-no-spin"}
                                    type="text"
                                    onChange={e => {
                                      this.handleInputChange('displayBannerAgainAfter', e);
                                    }}
                                    disabled={!editable}
                                    value={playbackSetting.displayBannerAgainAfter}
                                    />
                                <Validator id="displayBannerAgainAfter" callback={this.ValidatorCallback}>
                                    <ValidCase
                                        hide={!isSubmitted}
                                        valid={Validation.isInteger(playbackSetting.displayBannerAgainAfter)}
                                        message={I18n.t("messages.commonMessages.must_be_integer")}
                                        />
                                    <ValidCase
                                        hide={!isSubmitted}
                                        valid={Validation.isGreaterThan(playbackSetting.displayBannerAgainAfter, 0)}
                                        message={I18n.t("messages.commonMessages.greater_than_0")}
                                        />
                                </Validator>

                            </FormGroup>
                        </Col>
                        <Col xs={6} md={4} disabled={!editable}>
                            <FormGroup as={Col}
                                       className={`apply-to-control ${isSubmitted ? (valid.displayBannerAgainAfterType === false ? 'error' : null) : null}`}
                                >
                                <FormControl
                                    as="select"
                                    className="form-custom"
                                    value={playbackSetting.displayBannerAgainAfterType}
                                    defaultValue={playbackSetting.displayBannerAgainAfterType}
                                    onChange={e => {
                                    this.handleInputChange('displayBannerAgainAfterType', e);
                                  }}
                                    disabled={!editable}
                                    >
                                    <option value="second">seconds</option>
                                    <option value="min">minutes</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={12} disabled={!editable}>
                            <FormGroup
                                className={`apply-to-control ${isSubmitted ? (valid.stopDisplayBannerAfter === false ? 'error' : null) : null}`}
                                >
                                <Form.Label>
                                    <Translate value="appBanner.stop_display_banner_after" />
                                    <span className="require">&nbsp;*</span>
                                </Form.Label>
                                <FormControl
                                    as="select"
                                    className="form-custom"
                                    value={playbackSetting.stopDisplayBannerAfter}
                                    defaultValue='1'
                                    onChange={e => {
                                    this.handleInputChange('stopDisplayBannerAfter', e);
                                  }}
                                    >
                                    <option value="1">1 time</option>
                                    <option value="2">2 times</option>
                                    <option value="3">3 times</option>
                                    <option value="4">4 times</option>
                                    <option value="5">5 times</option>
                                </FormControl>
                                <Validator id="stopDisplayBannerAfter" callback={this.ValidatorCallback}>
                                    <ValidCase
                                        hide={!isSubmitted}
                                        valid={!Validation.isStringEmpty(playbackSetting.stopDisplayBannerAfter)}
                                        message={I18n.t('messages.commonMessages.Required_field')}
                                        />
                                </Validator>

                            </FormGroup>

                        </Col>
                       </div>
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {!this.props.permissions || this.props.permissions.actions ? (
                        <Button className="btn-save mr-md" onClick={this.saveDialogForm}>
                            <Translate value="carSetting.Save" />
                        </Button>
                    ) : null}
                    <Button className="btn-cancel" onClick={this.closeDialogForm}>
                        <Translate value="carSetting.Cancel" />
                    </Button>

                </Modal.Footer>
            </Modal>
        );
    }
}

AddEditPlaybackSettingModal.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        settingActions: bindActionCreators(settingActions, dispatch),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPlaybackSettingModal);
