import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import {
    FormGroup,
    FormControl,
    Button,
    ButtonToolbar,
    ButtonGroup,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {
    autocompleteCustomer,
    autocompleteDriver
} from "../../../../actions/messageAction";

import {
    CCLiteCommonFunc,
    Validation,
    distanceConvert,
    pointsConvertByUnitDistance,
    MultiSelectCommonFunc,
    trimPhoneNumber
} from '../../../../utils/commonFunctions';
import {
    UserEventType,
    EventType,
    MetricType
} from '../../../../constants/commondata';

import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import * as uploadActions from '../../../../actions/uploadActions';
import { QuestColumns as Columns } from './tableHeaderColumn';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import TableActions from '../../../../components/table/tableAction/TableActions';
import AddEditEvent from './AddEditEvent';
import QuestsModal from './QuestsModal';
import CriteriaModal from './CriteriaModal';
import PrizesModal from './PrizesModal';
import { debounce } from "lodash";
import { BsSearch } from 'react-icons/bs';

let currentEndDate = new Date();
class Quest extends Component {
    constructor() {
        super();
        this.state = {
            valid: {},
            eventList: {
                data: [],
                page: 0,
                limit: 20,
                total: 1
            },
            dialogData: {},
            disable: false,
            editable: false,
            searchKey: '',
            showConfirm: false,
            tableHeight: 500,
            rowHeight: 50,
            isDetailForm: false,
            showDialogModal: false,
            isSubmited: false,
            imageUpload: {},
            formPrizeName: ''
        };


        this.getEventList = this.getEventList.bind(this);
        this.ValidatorCallback = this.ValidatorCallback.bind(this);
        this.searchStrChange = this.searchStrChange.bind(this);

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleClickEditButton = this.handleClickEditButton.bind(this);
        this.handleAddButtonClick = this.handleAddButtonClick.bind(this);

        this.openFormModal = this.openFormModal.bind(this);
        this.closeFormModal = this.closeFormModal.bind(this);
        this.closeSubFormModal = this.closeSubFormModal.bind(this);

        this.searchKeyPressHandle = this.searchKeyPressHandle.bind(this);
        this.handleNumItemsPerPageChange = this.handleNumItemsPerPageChange.bind(this);
        this.handlePaginationChose = this.handlePaginationChose.bind(this);
        this.handlePaginationSelect = this.handlePaginationSelect.bind(this);


        this.handleLoadOption = this.handleLoadOption.bind(this);
        this.handleCustomizedChange = this.handleCustomizedChange.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleImportCustomerClick = this.handleImportCustomerClick.bind(this);
        this.handleLoadOptionDebounce = debounce(this.handleLoadOption, 500);
    };


    componentDidMount() {
        this.getEventList();
    }

    componentWillUnmount() {
        this.setState({
            eventList: {
                data: [],
                page: 0,
                limit: 20,
                total: 1
            }
        })
    }


    getEventList(page = 0, limit) {
        const { searchKey } = this.state;
        const { auth, settingActions } = this.props;

        let fleetId = auth.selectedFleet.fleetId;
        if (!limit) limit = this.state.eventList.limit;
        var requestParams = {
            fleetId,
            limit,
            page: page + 1
        };
        if (searchKey !== '') {
            requestParams.search = searchKey
        };
        settingActions
            .findEvents(requestParams)
            .then(data => {
                if (data.ok && data.res) {
                    let response = data.res;
                    response.limit = data.res.per_page;
                    response.page = data.res.page - 1;
                    this.setState({
                        eventList: response
                    });
                }
            });
    };

    ValidatorCallback(id, valid, messages) {
        if (this.state.valid[id] != valid) {
            this.state.valid[id] = valid;
            this.setState({ valid: this.state.valid });
        }
    }

    handleMenuClick(e, obj) {
        switch (e) {
            case 'Active':
            case 'Deactive':
                this.handleUpdateStatusClick(obj, !obj.isActive);
                break;
            case 'Edit':
                this.setState({ editable: true });
                this.handleClickEditButton(obj.id);
                break;
        }
    };

    getTableHeight = () => {
        let verticalPadding = 10,
            toolbarMarginBottom = 10,
            toolbarheight = ReactDOM.findDOMNode(this.toobarContainer).clientHeight,
            parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
        let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
        this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
        return this.state.tableHeight;
    };

    getListCarTypeDefault = () => {
        const { carType } = this.props.commonData;
        let value = [];
        for (let i = 0; i < carType.length; i++) {
            value.push(carType[i]._id);
        };
        return value;
    };

    getListZoneDefault = () => {
        const { mapZone } = this.props.commonData;
        let value = [];
        for (let i = 0; i < mapZone.length; i++) {
            value.push(mapZone[i]._id);
        };
        return value;
    };


    handleClickEditButton(id) {
        const { auth, settingActions, loadingBarActions } = this.props;
        let unitDistance = auth.selectedFleet.unitDistance;
        let fleetId = auth.selectedFleet.fleetId;
        let timeZone = auth.selectedFleet.timezone;
        let currencies = auth.selectedFleet.currencies;
        if (currencies === undefined) currencies = [];
        loadingBarActions.showLoadingSpiner();
        this.setState({
            disable: true
        });
        settingActions.detailsEvent({ fleetId, id })
            .then(data => {
                loadingBarActions.hideLoadingSpiner();
                if (data.ok && data.res) {
                    let formData = data.res;
                    if (formData.quests.length > 0) {
                        formData.quests.map(q => {
                            let unit = MetricType[formData.userType].find(e => e.metricType === q.metricType).unit;
                            if (unit === 'meter') {
                                q.value = distanceConvert(q.value, unitDistance, false);
                                q.points = pointsConvertByUnitDistance(q.points, unitDistance, false);
                            };
                            if (unit === 'hours') {
                                q.value = q.value / 60;
                                q.points = q.points * 60;
                            };
                        })
                    };
                    this.setState({
                        dialogData: {
                            ...formData,
                            startDate: moment(formData.startDate).tz(timeZone),
                            endDate: moment(formData.endDate).tz(timeZone),
                        },
                        isDetailForm: true
                    });

                    this.openFormModal();
                } else {
                    if (data.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + data.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('shiftSettings.Loading_event_fail')
                        );
                    };
                };
            })
    };


    handleAddButtonClick() {
        const carTypeIds = this.getListCarTypeDefault();
        const zoneIds = this.getListZoneDefault();
        this.setState(
            {
                disable: false,
                isSubmited: false,
                editable: true,
                dialogData: {
                    name: '',
                    userType: 'driver',
                    carTypeIds: carTypeIds.length > 0 ? carTypeIds : [],
                    zoneIds: zoneIds.length > 0 ? zoneIds : [],
                    type: 'first_will_win',
                    isUnlimited: true,
                    isLuckyDraw: false,
                    startDate: '',
                    endDate: '',
                    eventImage: '',
                    qualifiedImage: '',
                    expiredImage: '',
                    luckyDrawImage: '',
                    rankImages: [],
                    quests: [],
                    qualifiedPrizeOrder: [],
                    luckyDrawPrizeOrder: [],
                    termsURL: '',
                    metadataTitle: '',
                    metadataDescription: '',
                    bannerText: '',
                    buttonText: 'Join now',
                    // bannerLink: '',
                    shortLink: '',
                    buttonLinkDesktop: '',
                    buttonLinkAndroid: '',
                    buttonLinkiOS: '',
                    isDarkMode: false,
                    isActive: false,
                }
            },
            this.openFormModal()
        );
    };

    handleNumItemsPerPageChange(e) {
        let value = parseInt(e);
        this.getEventList(0, value);
    }

    handlePaginationChose(e) {
        let value = parseInt(e);
        this.getEventList(value);
    }

    handlePaginationSelect(value) {
        this.getEventList(value);
    }

    searchStrChange(e) {
        this.setState({
            searchKey: e
        });
    }

    searchKeyPressHandle(e) {
        if (e.key == 'Enter') {
            this.getEventList();
        }
    }

    openFormModal() {
        this.setState({
            showDialogModal: true,
            imageUpload: {}
        });
    }

    closeFormModal() {
        currentEndDate = new Date();
        this.setState({
            showDialogModal: false,
            showDialogQuests: false,
            showDialogQualifiedPrizeOrder: false,
            showConfirm: false,
            isDetailForm: false,
            editable: false,
            imageUpload: {}
        });
    };

    closeSubFormModal() {
        this.setState({
            showDialogQuests: false,
            showDialogQualifiedPrizeOrder: false,
            showDialogCriterias: false,
        });
    };

    getTableColumns = () => {
        let tableColumns = Object.assign([], Columns);
        const { eventList } = this.state;
        const { auth } = this.props;
        let timeZone = auth.selectedFleet.timezone;
        _.forEach(tableColumns, col => {
            switch (col.key) {
                case 'name':
                    col.customCell = data => {
                        return (
                            <OverlayTrigger
                                placement="top"
                                delayShow={300}
                                overlay={
                                    <Tooltip id="tooltip">
                                        <span>{data.name}</span>
                                    </Tooltip>
                                }
                            >
                                <a
                                    className="clickable"
                                    onClick={e => {
                                        this.handleClickEditButton(data.id, e);
                                    }}
                                >
                                    {' '}
                                    {data.name}
                                </a>
                            </OverlayTrigger>
                        );
                    };
                    break;
                case 'userType':
                    col.customCell = data => {
                        return data.userType === UserEventType.passenger ? (
                            <Translate value="quest_event.passenger" />
                        ) : (
                            <Translate value="quest_event.driver" />
                        );
                    };
                    break;
                case 'type':
                    col.customCell = data => {
                        return data.type === EventType.first_will_win ? (
                            <Translate value="quest_event.first_will_win" />
                        ) : (
                            <Translate value="quest_event.largest_will_win" />
                        );
                    };
                    break;
                case 'isLuckyDraw':
                    col.customCell = item =>
                        item.isLuckyDraw ? (
                            <i className="fa fa-check fa-2x activateIcon" />
                        ) : (
                            ''
                        );
                    break;

                case 'startDate':
                    col.customCell = obj => {
                        return moment(obj.startDate)
                            .tz(timeZone)
                            .format('DD/MM/YYYY');
                    };
                    break;
                case 'endDate':
                    col.customCell = obj => {
                        return moment(obj.endDate)
                            .tz(timeZone)
                            .format('DD/MM/YYYY');
                    };
                    break;
                case 'numOfQualify':
                    col.customCell = data => {
                        return (!data.isUnlimited ? data.numOfQualify : '∞')
                    };
                    break;
                case 'status':
                    col.customCell = data => {
                        return (data.status ? <Translate value={`quest_event.${data.status}`} /> : '')
                    };
                    break;
                case 'isActive':
                    col.customCell = obj => {
                        return (
                            <div className={obj.isActive ? 'Active' : 'Inactive'}>
                                {(!this.props.permissions || this.props.permissions.actions) && obj.status !== 'closed' ? (
                                    <a
                                        onClick={() => {
                                            this.handleUpdateStatusClick(obj, !obj.isActive);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {obj.isActive ? (
                                            <Translate value="quest_event.Active" />
                                        ) : (
                                            <Translate value="quest_event.Inactive" />
                                        )}
                                    </a>
                                ) : obj.isActive ? (
                                    <a
                                        onClick={() => {
                                            this.handleUpdateStatusClick(obj, !obj.isActive);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Translate value="quest_event.Active" />
                                    </a>
                                ) : (
                                    <Translate value="quest_event.Inactive" />
                                )}
                            </div>
                        );
                    };
                    break;
                case 'actions':
                    col.customCell = (obj, rowIndex) => {
                        let actions = [];
                        if (obj.status !== 'closed') {
                            actions.push({
                                label: "quest_event.Edit",
                                eventKey: "Edit"
                            });
                        };
                        if (obj.isActive) {
                            actions.push({
                                label: 'quest_event.Deactivate',
                                eventKey: 'Deactive'
                            });
                        } else {
                            if (obj.status !== 'closed') {
                                actions.push({
                                    label: 'quest_event.Activate',
                                    eventKey: 'Active'
                                });
                            }
                        };

                        return (
                            <div className="driver-actions-container">
                                {(!this.props.permissions || this.props.permissions.actions) && (obj.status !== 'closed' || obj.isActive) ? (
                                    <TableActions
                                        rowIndex={rowIndex}
                                        rowData={obj}
                                        onSelect={this.handleMenuClick}
                                        totalRow={eventList.data.length}
                                        rowHeight={this.state.rowHeight}
                                        menuItems={actions}
                                        tableHeight={this.state.tableHeight}
                                    />
                                ) : (
                                    <a
                                        onClick={e => {
                                            this.handleClickEditButton(obj.id, e);
                                        }}
                                        href="#view"
                                    >
                                        <Translate value="customer.View" />
                                    </a>
                                )}
                            </div>
                        );
                    };
                default:
                    break;
            }
        });

        return tableColumns;
    };

    handleInputChange = (key, event) => {
        const { dialogData } = this.state;
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            dialogData:
            {
                ...dialogData,
                [key]: value
            }
        });
    };

    handleUserTypeChange = (e) => {
        const { dialogData } = this.state;
        let value = e.target.value;
        this.setState({
            dialogData:
            {
                ...dialogData,
                userType: value,
                quests: [],
                phoneSuspendList: [],
                fileNameSuspendList: "",
                suspendList: [],
            },
            valid: {},
            isSubmited: false
        });
    };

    handleCarTypeChange = (e) => {
        const { dialogData } = this.state;
        let value = MultiSelectCommonFunc.getSelectValues(e);
        this.setState({
            dialogData:
            {
                ...dialogData,
                carTypeIds: value,
            },
            // valid: {},
            // isSubmited: false
        });
    };

    handleZoneChange = (e) => {
        const { dialogData } = this.state;
        let value = MultiSelectCommonFunc.getSelectValues(e);
        this.setState({
            dialogData:
            {
                ...dialogData,
                zoneIds: value,
            },
            // valid: {},
            // isSubmited: false
        });
    }

    handleEventTypeChange = (e) => {
        const { dialogData } = this.state;
        let value = e.target.value;
        this.setState({
            dialogData:
            {
                ...dialogData,
                type: value,
                isUnlimited: true,
                isLuckyDraw: false,
                eventImage: '',
                qualifiedImage: '',
                expiredImage: '',
                luckyDrawImage: '',
                rankImages: [],
                luckyDrawPrizeOrder: [],
            },
            imageUpload: {},
            valid: {},
            isSubmited: false
        });
    };

    handleLoadOption = (input, callback) => {
        if (input) {
            let ids = [];
            if (this.state.dialogData.suspendList && this.state.dialogData.suspendList.length) {
                ids = this.state.dialogData.suspendList.map(item => item.id);
            }
            if (this.state.dialogData.userType == UserEventType.driver) {
                let params = {
                    fleetId: this.props.auth.selectedFleet.fleetId,
                    search: input,
                    // companyId: this.state.company,
                    // status: this.state.driverStatus
                };

                if (ids.length) params.ids = ids;
                this.props.autocompleteDriver(params).then(data => {
                    if (data.ok && data.res && data.res.length > 0) {
                        let result = { complete: true };
                        result.options = data.res.map(item => {
                            item.value = item.fullName + " - " + item.phone;
                            item.id = item._id;
                            return item;
                        });
                        callback(null, result);
                    } else {
                        callback(null, null);
                    }
                });
            } else {
                let filterOptions = {
                    fleetId: this.props.auth.selectedFleet.fleetId,
                    str: input,
                    // status: this.state.status
                },
                    trimPhone = trimPhoneNumber(input, "no plus");

                if (ids.length) filterOptions.ids = ids;

                if (!Validation.validateSearchingPhone(trimPhone)) {
                    filterOptions.name = 1;
                } else {
                    filterOptions.str = trimPhone;
                }
                this.props.autocompleteCustomer(filterOptions).then(data => {
                    if (data.ok && data.res && data.res.list.length > 0) {
                        let result = { complete: true };
                        result.options = data.res.list.map(item => {
                            let phone = item.phone !== item.userId ? item.phone : '';
                            item.value =
                                item.firstName + " " + item.lastName + " - " + phone;
                            item.id = item.userId;
                            return item;
                        });
                        callback(null, result);
                    } else {
                        callback(null, null);
                    }
                });
            }
        } else {
            callback(null, null);
        }
    }

    handleCustomizedChange = (e) => {
        const { dialogData } = this.state;
        this.setState({
            dialogData:
            {
                ...dialogData,
                suspendList: e,
            }
        });
    }

    handleRemoveClick = () => {
        const { dialogData } = this.state;
        this.setState({
            dialogData:
            {
                ...dialogData,
                phoneSuspendList: [],
                fileNameSuspendList: "",
                suspendList: [],
            }
        });
    }

    handleImportCustomerClick = (data) => {
        const { dialogData } = this.state;
        this.setState({
            dialogData:
            {
                ...dialogData,
                phoneSuspendList: data.phones,
                fileNameSuspendList: data.fileName,
                suspendList: [],
                suspendListDocument: data.file
            }
        });
    }

    handleImageChange = (changeFile, error, key) => {
        const { dialogData, imageUpload } = this.state;
        const data = changeFile || {};
        // validate file size 8MB
        if (error && error === 'INVALID_FILE_SIZE') {
            this.context.notification('error', I18n.t('upload_document.Limit_size_upload').format(8));
            return;
        };
        dialogData[key] = data.result || null;
        this.setState({
            dialogData,
            imageUpload: {
                ...imageUpload,
                [key]: data.file || null
            }
        });
    };

    onChangeLimit = (key, e, validKey) => {
        const { dialogData, valid } = this.state;
        let { value } = e.target;
        if (value === '0') value = true;
        if (value === '1') value = false;
        if (validKey && !value) {
            _.set(valid, validKey, true);
        }
        _.set(dialogData, key, value);
        this.setState({
            dialogData,
            valid: {}
        });
    };

    handleStartDateChanged = (e) => {
        let dialogData = this.state.dialogData || {};
        dialogData.startDate = e._d;
        this.setState({
            dialogData
        });
    };

    handleEndDateChanged = (e) => {
        let dialogData = this.state.dialogData || {};
        // let changeDate = new Date(moment(e._d, 'DD/MM/YYYY').format('YYYY-MM-DD'));
        // if (currentEndDate > changeDate) {
        //     this.context.notification(
        //         'error',
        //         I18n.t('quest_event.endDate_invalid')
        //     );
        // } else {
        dialogData.endDate = e._d;
        this.setState({
            dialogData
        });
        // }
    };

    handleClickOpenSubModal = (key) => {
        let dialogData = this.state.dialogData || {};
        if (key === 'quests') {
            this.setState({
                showDialogQuests: true,
                editable: true,
                dialogData,
            });
        };
        if (
            key === 'qualifiedPrizeOrder' ||
            key === 'luckyDrawPrizeOrder' ||
            key === 'rankImages'
        ) {
            this.setState({
                formPrizeName: key,
                showDialogQualifiedPrizeOrder: true,
                editable: true,
                dialogData,
            });
        };
        if (key === 'criterias') {
            this.setState({
                showDialogCriterias: true,
                editable: true,
                dialogData,
            });
        };
    };

    handleUpdateQuests = (quests) => {
        this.setState({
            dialogData: {
                ...this.state.dialogData,
                quests
            },
            showDialogQuests: false,
        })
    };

    handleUpdateCriterias = (criterias) => {
        this.setState({
            dialogData: {
                ...this.state.dialogData,
                criterias
            },
            showDialogCriterias: false,
        })
    }

    handleUpdatePrize = (key, data) => {
        this.setState({
            dialogData: {
                ...this.state.dialogData,
                [key]: data
            },
            showDialogQualifiedPrizeOrder: false,
        })
    };

    handleUploadFile = file => {
        const {
            uploadService,
            auth: { selectedFleet }
        } = this.props;

        if (file) {
            const form = new FormData();
            form.append('photo', file);
            form.append('fleetId', selectedFleet.fleetId);
            form.append('module', 'quest');
            return uploadService.uploadPhoto(form)
                .then(res => {
                    if (!res.error) {
                        return Promise.resolve(_.get(res, 'res.Location'));
                    }
                    this.context.notification('error', I18n.t('errors.upload_photo_fail'));
                    return Promise.resolve({ error: res.error });
                });
        }
        return Promise.resolve();
    };

    handleUploadDocument = file => {
        const {
            uploadService,
            auth: { selectedFleet }
        } = this.props;

        if (file) {
            const form = new FormData();
            form.append('photo', file);
            form.append('fleetId', selectedFleet.fleetId);
            form.append('module', 'quest');
            return uploadService.uploadDocument(form)
                .then(res => {
                    if (!res.error) {
                        return Promise.resolve(_.get(res, 'res.Location'));
                    }
                    this.context.notification('error', I18n.t('errors.upload_photo_fail'));
                    return Promise.resolve({ error: res.error });
                });
        }
        return Promise.resolve();
    };

    handleUpdateStatusClick = (item, status) => {
        const { auth, settingActions, loadingBarActions } = this.props;
        const fleetId = auth.selectedFleet.fleetId;
        loadingBarActions.showLoadingSpiner();
        let body = {
            fleetId,
            name: item.name,
            id: item.id,
            isActive: status
        };
        settingActions.updateEventStatus(body)
            .then(response => {
                loadingBarActions.hideLoadingSpiner();
                if (response.ok && !response.error) {
                    this.context.notification(
                        'success',
                        I18n.t('quest_event.Update_event_success')
                    );
                    this.getEventList();
                } else {
                    if (response.error) {
                        this.context.notification(
                            'error',
                            I18n.t('errors.' + response.error.errorCode)
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('quest_event.Update_event_fail')
                        );
                    }
                }
            });
    };

    saveDialogForm = (dialogData) => {
        const { auth, settingActions, loadingBarActions } = this.props;
        const { imageUpload } = this.state;
        const newDialogData = _.cloneDeep(dialogData);
        const fleetId = auth.selectedFleet.fleetId;
        const unitDistance = auth.selectedFleet.unitDistance;
        this.setState({ isSubmited: true });
        let promises = [];
        var validData = this.state.valid;
        if (!dialogData.isLuckyDraw) {
            delete validData.luckyDrawImage
            delete validData.luckyDrawPrizeOrder
        };
        if (dialogData.type === EventType.largest_will_win) {
            // delete validData.eventImage
            delete validData.qualifiedImage
            delete validData.expiredImage
        };

        if (moment(dialogData.startDate) > moment(dialogData.endDate)) {
            this.context.notification(
                'error',
                I18n.t('quest_event.startDate_greater_than_endDate')
            );
            return;
        };

        if (!CCLiteCommonFunc.isFormValid(validData)) {
            return;
        }

        if (newDialogData.quests.length > 0) {
            newDialogData.quests.map(q => {
                let unit = MetricType[dialogData.userType].find(e => e.metricType === q.metricType).unit;
                if (unit === 'meter') {
                    q.value = distanceConvert(q.value, unitDistance, true)
                    q.points = pointsConvertByUnitDistance(q.points, unitDistance, true);
                };
                if (unit === 'hours') {
                    q.value = q.value * 60;
                    q.points = q.points / 60;
                };
            })
        };
        loadingBarActions.showLoadingSpiner();
        var startDateString = moment(dialogData.startDate).format("DD-MM-YYYY h:mm:ss A");
        var startDate = moment.utc(moment.tz(startDateString, 'DD-MM-YYYY h:mm:ss A', this.props.auth.selectedFleet.timezone)).format();
        var endDateString = moment(dialogData.endDate).format("DD-MM-YYYY h:mm:ss A");
        var endDate = moment.utc(moment.tz(endDateString, 'DD-MM-YYYY h:mm:ss A', this.props.auth.selectedFleet.timezone)).format();
        let body = {
            fleetId,
            startDate: startDate,
            endDate: endDate,
            name: dialogData.name,
            type: dialogData.type,
            userType: dialogData.userType,
            isLuckyDraw: dialogData.isLuckyDraw,
            isUnlimited: dialogData.isUnlimited,
            numOfQualify: !dialogData.isUnlimited ? dialogData.numOfQualify : undefined,
            isActive: dialogData.isActive,
            isDarkMode: dialogData.isDarkMode,
            termsURL: dialogData.termsURL,
            metadataTitle: dialogData.metadataTitle,
            metadataDescription: dialogData.metadataDescription,
            rankImages: dialogData.rankImages,
            quests: newDialogData.quests,
            criterias: dialogData.criterias,
            luckyDrawPrizeOrder: dialogData.luckyDrawPrizeOrder,
            qualifiedPrizeOrder: dialogData.qualifiedPrizeOrder,
            bannerText: dialogData.bannerText,
            buttonText: dialogData.buttonText,
            // bannerLink: dialogData.bannerLink,
            shortLink: dialogData.shortLink,
            buttonLinkAndroid: dialogData.buttonLinkAndroid,
            buttonLinkiOS: dialogData.buttonLinkiOS,
            buttonLinkDesktop: dialogData.buttonLinkDesktop,
            fileNameSuspendList: dialogData.fileNameSuspendList,
            phoneSuspendList: dialogData.phoneSuspendList,
            suspendList: dialogData.suspendList
        };

        if (dialogData.userType === 'driver') {
            body.carTypeIds = dialogData.carTypeIds;
            body.zoneIds = dialogData.zoneIds;
        };

        if (imageUpload.eventImage) {
            promises.push(
                this.handleUploadFile(imageUpload.eventImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.eventImage = dataImageUpload;
                    })
            );
        };
        if (imageUpload.sharedImage) {
            promises.push(
                this.handleUploadFile(imageUpload.sharedImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.sharedImage = dataImageUpload;
                    })
            );
        };
        if (imageUpload.qualifiedImage) {
            promises.push(
                this.handleUploadFile(imageUpload.qualifiedImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.qualifiedImage = dataImageUpload;
                    })
            );
        };
        if (imageUpload.expiredImage) {
            promises.push(
                this.handleUploadFile(imageUpload.expiredImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.expiredImage = dataImageUpload;
                    })
            );
        };
        if (imageUpload.luckyDrawImage) {
            promises.push(
                this.handleUploadFile(imageUpload.luckyDrawImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.luckyDrawImage = dataImageUpload;
                    })
            );
        };
        console.log('suspendListDocument2', dialogData.suspendListDocument);
        if (dialogData.suspendListDocument) {
            promises.push(
                this.handleUploadDocument(dialogData.suspendListDocument)
                    .then(dataDocumentUpload => {
                        if (dataDocumentUpload && dataDocumentUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        console.log('dataDocumentUpload', dataDocumentUpload);
                        body.linkSuspendList = dataDocumentUpload;
                    })
            );
        }

        Promise.all(promises).then(() => {
            settingActions.createEvent(body)
                .then(response => {
                    loadingBarActions.hideLoadingSpiner();
                    if (response.ok && !response.error) {
                        this.context.notification(
                            'success',
                            I18n.t('quest_event.Create_event_success')
                        );
                        this.closeFormModal();
                        this.getEventList();
                        this.setState({ isSubmited: false, imageUpload: {} });
                    } else {
                        this.context.notification(
                            'error',
                            response?.error?.code
                            ? I18n.t('errors.' + response?.error?.code)
                            : I18n.t('quest_event.Update_event_fail')
                        );
                    }
                });
        });
    };

    updateDialogForm = (dialogData) => {
        const { auth, settingActions, loadingBarActions } = this.props;
        const { imageUpload } = this.state;
        const newDialogData = _.cloneDeep(dialogData);
        const fleetId = auth.selectedFleet.fleetId;
        const unitDistance = auth.selectedFleet.unitDistance;
        this.setState({ isSubmited: true });
        let promises = [];

        if (moment(dialogData.startDate) > moment(dialogData.endDate)) {
            this.context.notification(
                'error',
                I18n.t('quest_event.startDate_greater_than_endDate')
            );
            return;
        };

        if (!CCLiteCommonFunc.isFormValid(this.state.valid)) {
            return;
        };

        if (newDialogData.quests.length > 0) {
            newDialogData.quests.map(q => {
                let unit = MetricType[dialogData.userType].find(e => e.metricType === q.metricType).unit;
                if (unit === 'meter') {
                    q.value = distanceConvert(q.value, unitDistance, true);
                    q.points = pointsConvertByUnitDistance(q.points, unitDistance, true);
                };
                if (unit === 'hours') {
                    q.value = q.value * 60;
                    q.points = q.points / 60;
                };
            })
        };
        loadingBarActions.showLoadingSpiner();
        var startDateString = moment(dialogData.startDate).format("DD-MM-YYYY h:mm:ss A");
        var startDate = moment.utc(moment.tz(startDateString, 'DD-MM-YYYY h:mm:ss A', this.props.auth.selectedFleet.timezone)).format();
        var endDateString = moment(dialogData.endDate).format("DD-MM-YYYY h:mm:ss A");
        var endDate = moment.utc(moment.tz(endDateString, 'DD-MM-YYYY h:mm:ss A', this.props.auth.selectedFleet.timezone)).format();
        let body = {
            fleetId,
            id: dialogData.id,
            startDate: startDate,
            endDate: endDate,
            name: dialogData.name,
            type: dialogData.type,
            userType: dialogData.userType,
            isLuckyDraw: dialogData.isLuckyDraw,
            isUnlimited: dialogData.isUnlimited,
            numOfQualify: !dialogData.isUnlimited ? dialogData.numOfQualify : undefined,
            isActive: dialogData.isActive,
            isDarkMode: dialogData.isDarkMode,
            termsURL: dialogData.termsURL,
            metadataTitle: dialogData.metadataTitle,
            metadataDescription: dialogData.metadataDescription,
            rankImages: dialogData.rankImages,
            quests: newDialogData.quests,
            criterias: dialogData.criterias,
            luckyDrawPrizeOrder: dialogData.luckyDrawPrizeOrder,
            qualifiedPrizeOrder: dialogData.qualifiedPrizeOrder,
            bannerText: dialogData.bannerText,
            buttonText: dialogData.buttonText,
            // bannerLink: dialogData.bannerLink,
            shortLink: dialogData.shortLink,
            buttonLinkAndroid: dialogData.buttonLinkAndroid,
            buttonLinkiOS: dialogData.buttonLinkiOS,
            buttonLinkDesktop: dialogData.buttonLinkDesktop,
            fileNameSuspendList: dialogData.fileNameSuspendList,
            phoneSuspendList: dialogData.phoneSuspendList,
            suspendList: dialogData.suspendList
        };

        if (dialogData.userType === 'driver') {
            body.carTypeIds = dialogData.carTypeIds;
            body.zoneIds = dialogData.zoneIds;
        };

        if (imageUpload.eventImage) {
            promises.push(
                this.handleUploadFile(imageUpload.eventImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.eventImage = dataImageUpload;
                    })
            );
        } else {
            body.eventImage = dialogData.eventImage;
        };
        if (imageUpload.sharedImage) {
            promises.push(
                this.handleUploadFile(imageUpload.sharedImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.sharedImage = dataImageUpload;
                    })
            );
        } else {
            body.sharedImage = dialogData.sharedImage;
        };
        if (imageUpload.qualifiedImage) {
            promises.push(
                this.handleUploadFile(imageUpload.qualifiedImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.qualifiedImage = dataImageUpload;
                    })
            );
        } else {
            body.qualifiedImage = dialogData.qualifiedImage;
        };
        if (imageUpload.expiredImage) {
            promises.push(
                this.handleUploadFile(imageUpload.expiredImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.expiredImage = dataImageUpload;
                    })
            );
        } else {
            body.expiredImage = dialogData.expiredImage;
        };
        if (imageUpload.luckyDrawImage) {
            promises.push(
                this.handleUploadFile(imageUpload.luckyDrawImage)
                    .then(dataImageUpload => {
                        if (dataImageUpload && dataImageUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        body.luckyDrawImage = dataImageUpload;
                    })
            );
        } else {
            body.luckyDrawImage = dialogData.luckyDrawImage;
        };
        console.log('suspendListDocument2', dialogData.suspendListDocument);
        if (dialogData.suspendListDocument) {
            promises.push(
                this.handleUploadDocument(dialogData.suspendListDocument)
                    .then(dataDocumentUpload => {
                        if (dataDocumentUpload && dataDocumentUpload.error) {
                            loadingBarActions.hideLoadingSpiner();
                            return;
                        };
                        console.log('dataDocumentUpload', dataDocumentUpload);
                        body.linkSuspendList = dataDocumentUpload;
                    })
            );
        }

        Promise.all(promises).then(() => {
            settingActions.updateEvent(body)
                .then(response => {
                    loadingBarActions.hideLoadingSpiner();
                    if (response.ok && !response.error) {
                        this.context.notification(
                            'success',
                            I18n.t('quest_event.Update_event_success')
                        );
                        this.closeFormModal();
                        this.getEventList();
                        this.setState({ isSubmited: false, imageUpload: {} });
                    } else {
                        this.context.notification(
                            'error',
                            response.error?.code
                            ? I18n.t('errors.' + response.error?.code)
                            : I18n.t('quest_event.Update_event_fail')
                        );
                    }
                });
        });
    };

    handleAlertNotification = (key, params) => {
        if (Array.isArray(params)) {
            if (key === 'quests') {
                params.forEach(e => {
                    let name = I18n.t(`quest_event.${this.state.dialogData.userType === 'passenger' ? 'passengerMetric' : 'driverMetric'}.${e.metricType}`);
                    if (e.errorMsg) {
                        return this.context.notification(
                            'error',
                            I18n.t(`quest_event.${e.errorMsg}`, { name })
                        );
                    };
                });
            };
            if (key === 'criterias') {
                params.forEach(e => {
                    let name = I18n.t(`quest_event.${e.criteriaType}`);
                    if (!e.value) {
                        return this.context.notification(
                            'error',
                            I18n.t('quest_event.please_input_value', { name })
                        );
                    };
                    if (!Validation.isNumber(e.value)) {
                        return this.context.notification(
                            'error',
                            I18n.t('quest_event.value_is_invalid', { name })
                        );
                    };
                    if (e.errorMsg) {
                        return this.context.notification(
                            'error',
                            I18n.t(`quest_event.${e.errorMsg}`, { name })
                        );
                    };
                });
            };
            if (key === 'qualifiedPrizeOrder' || key === 'luckyDrawPrizeOrder') {
                params.forEach(e => {
                    this.context.notification(
                        'error',
                        I18n.t('quest_event.please_upload_image', { name: e.reward })
                    );
                });
            };
            if (key === 'rankImages') {
                params.forEach(e => {
                    if (e.from > e.to) {
                        this.context.notification(
                            'error',
                            I18n.t('quest_event.rank_invalid', { name: `from ${e.from} - to ${e.to === Number.MAX_SAFE_INTEGER ? Infinity : e.to}` })
                        );
                    } else if (!e.to) {
                        this.context.notification(
                            'error',
                            I18n.t('quest_event.rank_invalid_to')
                        );
                    } else {
                        this.context.notification(
                            'error',
                            I18n.t('quest_event.please_upload_image_rank', { name: `from ${e.from} - to ${e.to === Number.MAX_SAFE_INTEGER ? Infinity : e.to}` })
                        );
                    }
                });
            };
        };

        if (typeof params === 'string') {
            this.context.notification(
                'error',
                I18n.t(`${params}`)
            );
        };

    };

    render() {
        const { dialogData, eventList } = this.state;
        const { selectedFleet } = this.props.auth
        const bodyData =
            eventList && eventList.data ? eventList.data : [];

        let currency = [];
        if (selectedFleet.multiCurrencies) {
            currency = selectedFleet.currencies;
        } else {
            currency = selectedFleet.currencies;
        };

        return (
            <div className="content">
                <ButtonToolbar
                    className="text-center header-button-group form-cus"
                    ref={node => (this.toobarContainer = node)}
                >
                    <ButtonGroup className="group-left">
                        <FormGroup className="search-format mb0">
                            <FormControl
                                className="search-form"
                                type="text"
                                placeholder={I18n.t('General.search')}
                                onChange={e => this.searchStrChange(e.target.value)}
                                onKeyPress={this.searchKeyPressHandle}
                            />
                            <BsSearch className="search-icon" />
                        </FormGroup>
                        {!this.props.permissions || this.props.permissions.actions ? (
                            <React.Fragment>
                                <Button
                                    className="btn-header text-add-header"
                                    onClick={this.handleAddButtonClick}
                                >
                                    <Translate value="quest_event.Add" />
                                </Button>

                            </React.Fragment>
                        ) : (
                            ''
                        )}
                    </ButtonGroup>

                </ButtonToolbar>

                <div className="gridViewTable">
                    <StickyTable
                        columns={this.getTableColumns()}
                        bodyData={bodyData}
                        footerData={eventList}
                        handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.rowHeight}
                        getTableHeight={this.getTableHeight}
                    />
                </div>

                {this.state.showDialogModal &&
                    <AddEditEvent
                        showDialogModal={this.state.showDialogModal}
                        data={this.state.dialogData}
                        currency={currency}
                        isSubmited={this.state.isSubmited}
                        valid={this.state.valid}
                        disable={this.state.disable}
                        permissions={this.props.permissions}
                        carTypes={this.props.commonData.carType}
                        zones={this.props.commonData.mapZone}
                        editable={this.state.editable}
                        format24Hour={selectedFleet && selectedFleet.format24Hour}
                        ValidatorCallback={this.ValidatorCallback}
                        closeFormModal={this.closeFormModal}
                        handleInputChange={this.handleInputChange}
                        handleUserTypeChange={this.handleUserTypeChange}
                        handleCarTypeChange={this.handleCarTypeChange}
                        handleZoneChange={this.handleZoneChange}
                        handleEventTypeChange={this.handleEventTypeChange}
                        handleImageChange={this.handleImageChange}
                        onChangeLimit={this.onChangeLimit}
                        handleStartDateChanged={this.handleStartDateChanged}
                        handleEndDateChanged={this.handleEndDateChanged}
                        handleClickOpenSubModal={this.handleClickOpenSubModal}
                        saveDialogForm={this.saveDialogForm}
                        updateDialogForm={this.updateDialogForm}
                        handleLoadOptionDebounce={this.handleLoadOptionDebounce}
                        handleCustomizedChange={this.handleCustomizedChange}
                        handleRemoveClick={this.handleRemoveClick}
                        handleImportCustomerClick={this.handleImportCustomerClick}
                    />}

                {this.state.showDialogQuests &&
                    <QuestsModal
                        data={this.state.dialogData}
                        currency={currency}
                        disable={this.state.disable}
                        showDialogModal={this.state.showDialogQuests}
                        selectedFleet={this.props.auth.selectedFleet}
                        closeFormModal={this.closeSubFormModal}
                        handleUpdateQuests={this.handleUpdateQuests}
                        handleAlertNotification={this.handleAlertNotification}
                    />}
                {this.state.showDialogCriterias &&
                    <CriteriaModal
                        data={this.state.dialogData}
                        currency={currency}
                        disable={this.state.disable}
                        showDialogModal={this.state.showDialogCriterias}
                        closeFormModal={this.closeSubFormModal}
                        handleUpdateModal={this.handleUpdateCriterias}
                        handleAlertNotification={this.handleAlertNotification}
                    />
                }

                {this.state.showDialogQualifiedPrizeOrder &&
                    <PrizesModal
                        formName={this.state.formPrizeName}
                        data={this.state.dialogData}
                        disable={this.state.disable}
                        showDialogModal={this.state.showDialogQualifiedPrizeOrder}
                        closeFormModal={this.closeSubFormModal}
                        handleUpdateData={this.handleUpdatePrize}
                        uploadService={this.props.uploadService}
                        selectedFleet={this.props.auth.selectedFleet}
                        loadingBarActions={this.props.loadingBarActions}
                        handleAlertNotification={this.handleAlertNotification}
                    />}
            </div>
        )
    }

}

Quest.contextTypes = {
    notification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        commonData: state.commonData,
        auth: state.auth,
        settings: state.settings,
        permissions: state.menuHandle.modulePermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        autocompleteCustomer: options => {
            return dispatch(autocompleteCustomer(options));
        },
        autocompleteDriver: options => {
            return dispatch(autocompleteDriver(options));
        },
        settingActions: bindActionCreators(settingActions, dispatch),
        loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
        uploadService: bindActionCreators(uploadActions, dispatch),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quest);