import React from 'react';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import AddCard from '../../../../containers/customers/AddCard';

class QuickAddNewCardModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      gateWay, supportWeb, corpId, userId, isHydraBooking, fleetSupplierId, closeModal
    } = this.props;
    return (
      <Modal show backdrop dialogClassName="modal-max-600" onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('bookingdetail.Add_new_card')}</Modal.Title>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddCard
            userId={userId}
            corpId={corpId}
            gateWay={gateWay}
            isHydraBooking={isHydraBooking}
            fleetSupplierId={fleetSupplierId}
            addCardWebservice={this.props.onSubmitForm}
            handleAddCardSuccess={this.props.handleAddCardSuccess}
            currentUser={this.props.currentUser}
            handleCloseAddCard={this.handleCancelAddCardClick}
            supportWeb={supportWeb}
            isCrossZone={false}
            zoneId={gateWay && gateWay.zoneId}
            noPermission
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default QuickAddNewCardModal;
