import _ from 'lodash';

export const SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED';
export const SIDEBAR_LINK_CLICKED = 'SIDEBAR_LINK_CLICKED';
export const CHANGE_PAGE_TITLE = 'CHANGE_PAGE_TITLE';
export const REQUEST_NEWBOOKING_FORM = 'REQUEST_NEWBOOKING_FORM';
export const BUILD_MENU = 'BUILD_MENU';
export const UPDATE_MENU = 'UPDATE_MENU';
export function sidebarCollapsedAction(collapsed) {
  return {
    type: SIDEBAR_COLLAPSED,
    sidebarCollapsed: collapsed
  };
}
export function sidebarLinkClickAction(key, isMenuSidebar) {
  return {
    type: SIDEBAR_LINK_CLICKED,
    key,
    isMenuSidebar
  };
}
export function changePageTitle(title) {
  return {
    type: CHANGE_PAGE_TITLE,
    pagetitle: title
  };
}

export function buildMenu(isAdmin, roles, settings, moduleAdmin, auth, callback) {
  if (!isAdmin && roles == null) {
    roles = roles = roles || [];
  }
  const {selectedFleet} = auth

  /* SL-9152  Limit permission of some admin accounts to view on CC only */
  if (isAdmin) {
    const actionCC = _.get(moduleAdmin, 'actionCC', true);
    const viewCC = _.get(moduleAdmin, 'viewCC', true);

    roles = roles.map(r => {
      r.actions = r.actions.map(action => {
        if (['Actions', 'Export', 'TotalBalance'].indexOf(action.name) !== -1) {
          action.isActive = actionCC;
        } else {
          action.isActive = viewCC;
        }

        if (r.name === 'New booking') {
          action.isActive = actionCC;
        }
        return action;
      });
      return r;
    });
  }
  const referralCodeSetting = (roles || []).find(role => role.name === 'Referral code' && role.key === 'Setting');
  const report = (roles || []).find(role => role.name === 'Report' && role.key === 'Report');

  const referralCodeSettingActionView = !referralCodeSetting || !referralCodeSetting.actions
    ? {
      isActive: false
    }
    : referralCodeSetting.actions.find(action => action.name === 'View')

  const reportActionReferral = (report && report.actions || []).find(rp => rp.name === 'Driver refers Pax');

  if (reportActionReferral) reportActionReferral.isActive = !referralCodeSettingActionView || !referralCodeSettingActionView.isActive
    ? false
    : reportActionReferral.isActive
      ? true
      : false;

  const thirdParty = (roles || []).find(role => role.name === '3rd party location' && role.key === 'Setting');

  if (thirdParty) {
    const { actions = [
      {
        isActive: false,
        name: 'View'
      },
      {
        isActive: false,
        name: 'Actions'
      }
    ] } = thirdParty || {};

    const category = {
      name: 'category',
      key: 'Setting',
      actions
    }

    roles.push(category);
  }

  return {
    type: BUILD_MENU,
    data: { roles, settings, isAdmin, selectedFleet, auth },
    callback
  };
}

export function updateMenu(url) {
  return {
    type: UPDATE_MENU,
    data: url
  };
}

export function requestAddNewBookingForm() {
  return { type: REQUEST_NEWBOOKING_FORM };
}
