import React from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  Button,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';

import { Validator, ValidCase } from '../../../../components/validator';
import { Validation } from '../../../../utils/commonFunctions';

export default function ModalAddEditVoucher({
  data,
  editable,
  selectedFleet,
  hasPermission,
  campaignList,
  handleChangeInput,
  handleSaveForm,
  closeModalForm,
  valid,
  isSubmitted,
  ValidatorCallback,
}) {
  return (
    <Modal show={true} backdrop={true} dialogClassName={'modal-max-600'} onHide={closeModalForm}>
      <Modal.Header closeButton>
        <Modal.Title>
          {data._id ? (
            editable ? (
              <Translate value="pointConfig.EDIT_VOUCHER" />
            ) : (
              <Translate value="pointConfig.DETAIL_VOUCHER" />
            )
          ) : (
            <Translate value="pointConfig.CREATE_VOUCHER" />
          )}
        </Modal.Title>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeModalForm}
        >
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <FormGroup
          className={
            !isSubmitted ? null : valid['name'] === false ? 'error' : null
          }
        >
          <Form.Label>
            <Translate value="pointConfig.vourcherName" />{' '}
            <span className="require">*</span>
          </Form.Label>
          <FormControl
            className="form-custom"
            type="text"
            id={'name'}
            maxLength={20}
            value={data.name || ''}
            onChange={(e) => {
              handleChangeInput('name', e.target.value);
            }}
            disabled={!editable}
          />
          <Validator id="name" callback={ValidatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(data.name)}
              message={I18n.t('messages.commonMessages.Required_field')}
            />
          </Validator>
        </FormGroup>

        <FormGroup
          className={`qup-input-group ${
            !isSubmitted ? null : valid['points'] === false ? 'error' : null
          }`}
        >
          <Form.Label>
            <Translate value="pointConfig.pointsToRedeem" />{' '}
            <span className="require">*</span>
          </Form.Label>
          <InputGroup className="single-addon-right">
            <FormControl
              className="form-custom"
              type="text"
              id={'points'}
              maxLength={50}
              value={data.points}
              onChange={(e) => {
                handleChangeInput('points', e.target.value);
              }}
              disabled={!editable}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                <Translate value="pointConfig.point" />
              </InputGroup.Text>
            </InputGroup.Append>{' '}
          </InputGroup>
          <Validator id="points" callback={ValidatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(data.points)}
              message={I18n.t('messages.commonMessages.Required_field')}
            />
            <ValidCase
              hide={!isSubmitted}
              valid={Validation.isGreaterThan(data.points, 0)}
              message={I18n.t('messages.commonMessages.greater_than_0')}
            />
          </Validator>
        </FormGroup>

        <FormGroup
          className={`qup-input-group ${
            !isSubmitted ? null : valid['amount'] === false ? 'error' : null
          }`}
        >
          <Form.Label>
            <Translate value="pointConfig.vourcherValue" />{' '}
            <span className="require">*</span>
          </Form.Label>
          <InputGroup className="single-addon-left">
            <InputGroup.Prepend>
              <InputGroup.Text>
                {selectedFleet.currencies[0].symbol}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              className="form-custom"
              type="text"
              id={'amount'}
              maxLength={50}
              value={data.amount}
              onChange={(e) => {
                handleChangeInput('amount', e.target.value);
              }}
              disabled={!editable}
            />
          </InputGroup>
          <Validator id="amount" callback={ValidatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(data.amount)}
              message={I18n.t('messages.commonMessages.Required_field')}
            />
            <ValidCase
              hide={!isSubmitted}
              valid={Validation.isGreaterThan(data.amount, 0)}
              message={I18n.t('messages.commonMessages.greater_than_0')}
            />
          </Validator>
        </FormGroup>

        <FormGroup
          className={`qup-input-group ${
            !isSubmitted ? null : valid['validTime'] === false ? 'error' : null
          }`}
        >
          <Form.Label>
            <Translate value="pointConfig.validTime" />{' '}
            <span className="require">*</span>
          </Form.Label>
          <InputGroup className={'single-addon-right'}>
            <FormControl
              className="form-custom"
              type="text"
              id={'validTime'}
              maxLength={3}
              value={data.validTime}
              onChange={(e) => {
                handleChangeInput('validTime', e.target.value);
              }}
              disabled={!editable}
            />
            <FormControl
              as="select"
              className="input-group-addon-custom form-custom"
              value={data ? data.validTimeUnit : 'month'}
              onChange={(e) => {
                handleChangeInput('validTimeUnit', e.target.value);
              }}
              disabled={!editable}
            >
              <option value={'month'}>{I18n.t('pointConfig.month')}</option>
              <option value={'day'}>{I18n.t('pointConfig.day')}</option>
            </FormControl>
          </InputGroup>
          <Validator id="validTime" callback={ValidatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(data.validTime)}
              message={I18n.t('messages.commonMessages.Required_field')}
            />
            <ValidCase
              hide={!isSubmitted}
              valid={Validation.isGreaterThan(data.validTime, 0)}
              message={I18n.t('messages.commonMessages.greater_than_0')}
            />
            <ValidCase
              hide={!isSubmitted}
              valid={
                data.validTimeUnit === 'month'
                  ? Validation.isLessOrEqual(data.validTime, 24)
                  : Validation.isLessOrEqual(data.validTime, 730)
              }
              message={
                data.validTimeUnit === 'month'
                  ? I18n.t('messages.commonMessages.less_or_equal').format(
                      '24 months'
                    )
                  : I18n.t('messages.commonMessages.less_or_equal').format(
                      '730 days'
                    )
              }
            />
          </Validator>
        </FormGroup>

        <FormGroup
          className={
            !isSubmitted ? null : valid.campaignId ? null : 'error'
          }
        >
          <Form.Label>
            <Translate value="promotionSettings.Campaign" />{' '}
            <span className="require">*</span>
          </Form.Label>
          <select
            type="select"
            className="form-control form-custom"
            onChange={(e) => {
              handleChangeInput('campaignId', e.target.value);
            }}
            value={data.campaignId ? data.campaignId : ''}
            placeholder={I18n.t('promotionSettings.Campaign')}
            disabled={!editable}
          >
            <option value="">
              {I18n.t('promotionSettings.Select_campaign')}
            </option>
            {campaignList && campaignList.length > 0
              ? campaignList.map((obj, index) => {
                  return (
                    <option value={obj._id} key={index}>
                      {obj.name}
                    </option>
                  );
                })
              : null}
          </select>
          <Validator id="campaignId" callback={ValidatorCallback}>
            <ValidCase
              hide={!isSubmitted}
              valid={!Validation.isStringEmpty(data.campaignId)}
              message={I18n.t('promotionSettings.Please_select_campaign')}
            />
          </Validator>
        </FormGroup>

        <FormGroup>
          <Form.Label>
            <Translate value="pointConfig.termAndCondition" />
          </Form.Label>
          <FormControl
            as="textarea"
            className="form-custom"
            onChange={(e) => {
              handleChangeInput('tandc', e.target.value);
            }}
            value={data.tandc}
            disabled={!editable}
            maxLength={2000}
          />
        </FormGroup>

        <Form.Label>
          (<span className="require">*</span>):{' '}
          <Translate value="promotionSettings.Required_fields" />
        </Form.Label>
      </Modal.Body>
      <Modal.Footer>
        {hasPermission ? (
          editable ? (
            <Button className={'btn-save mr-md'} onClick={handleSaveForm}>
              <Translate value="referral.Save" />
            </Button>
          ) : null
        ) : null}
        <Button className={'btn-cancel'} onClick={closeModalForm}>
          <Translate value="city.Cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
