import React from "react";
import Promise from "bluebird";
import BaseReport from "../base";
import * as fieldMappings from "./fields";
import { findExport } from "../../../constants/ApiConfigs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingActions from "../../../actions/settingActions";
import _ from 'lodash';
class ExportReport extends React.Component {
    constructor() {
        super();
    }
  render() {
    const modules = _.get(this.props.selectedFleet, 'modules', []);
      
    return (
        <div className="content">
          <BaseReport
              tittle="Export"
              fieldMappings={fieldMappings}
              apiurl={findExport}
              dateRange
              noexport={true}
              />
        </div>
    );
  }
}
function mapStateToProps(state, ownProp) {
  return {
    selectedFleet: state.auth.selectedFleet,
    user: state.auth.user
  };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(settingActions, dispatch)
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(ExportReport);