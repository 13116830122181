import React, { Component } from 'react'
import { connect } from 'react-redux';
import { reportAcceptanceCancellationRateApi } from '../../../constants/ApiConfigs';
import * as fieldMappings from './fields';
import BaseReport from '../base';
import _ from 'lodash';
const dateRange = [
    {
        value: 'past7Days',
        key: 'past7Days',
        label: 'report.query.past7Days',
        default: true
    }, {
        value: 'weekly',
        key: 'weekly',
        label: 'report.query.weekly'
    }, {
        value: 'monthly',
        key: 'monthly',
        label: 'report.query.monthly'
    }, {
        value: 'custom',
        key: 'custom',
        label: 'report.query.custom'
    }
]

class ReportAcceptCancelRate extends Component {
    render() {
        const modules = _.get(this.props.selectedFleet, 'modules', []);
        const report = _.find(modules,{"name":'Report'});
        let checkExport = _.findIndex(report.actions,{"name":"Acceptance/Cancellation rate","Export":true});
        let checkOwner = !this.props.user.isAdmin && typeof this.props.user.roles.fleetId !== 'undefined' ? this.props.user.roles.fleetId.ownerId === this.props.user._id ? true : false : true ;
        checkExport = this.props.user.isAdmin || checkOwner ? true :checkExport === -1 ? false : true;
        if(!this.props.user.isAdmin && this.props.selectedFleet.fleetId === "applecabssouth"){
            checkExport = false;
        }
        return (
            <div className="content">
                <BaseReport
                    tittle="Driver performance"
                    fieldMappings={fieldMappings}
                    apiurl={reportAcceptanceCancellationRateApi}
                    dateRange={dateRange}
                    driver
                    search="report.query.search.acceptanceCancellationRate"
                    company
                    noexport={!checkExport}
                />
            </div>
        )
    }
}
function mapStateToProps(state, ownProp) {
    return {
        selectedFleet: state.auth.selectedFleet,
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(ReportAcceptCancelRate);