/* global google */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BeatLoader } from 'react-spinners';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import '../../settings.scss';
import Moment from 'react-moment';
import { GoogleMap, Polygon } from '@react-google-maps/api';
import {
  Col,
  Row,
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Button,
  ButtonToolbar,
  Modal,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import ExportComponent from '../../../../components/ExportComponent';
import * as settingActions from '../../../../actions/settingActions';
import * as loadingBarActions from '../../../../actions/loadingBarActions';
import * as reportActions from '../../../../actions/reportActions';
import { bindActionCreators } from 'redux';
import {
  Validator,
  ValidCase,
  WarningFeeInput,
} from '../../../../components/validator';
import {
  CCLiteCommonFunc,
  Validation,
  textboxNumberHelper,
  handleResultExport,
} from '../../../../utils/commonFunctions';
import {
  RouteType,
  CheckMode,
  DefaultCurenciesNumberInputField,
} from '../../../../constants/commondata';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';
import _ from 'lodash';
import StickyTable from '../../../../components/table/stickyTable/StickyTable';
import Confirm from '../../../../components/confirm/Confirm';
import { Columns, RouterTableColumns } from './tableHeaderData';
import TableActions from '../../../../components/table/tableAction/TableActions';
import RadioButton from '../../../../components/radioButton/radio';
import { QQMap, QQPolygon } from '../../../../components/qqMap';
import Import from './ImportFlat';
import ExtraWaitTime from '../components/extraWaitTime';
import { BsSearch } from 'react-icons/bs';

var currencyFormatter = require('currency-formatter');

const GettingStartedGoogleMap = (props) => {
  return (
    <GoogleMap
      onLoad={(map) => props.onMapLoad(props.map, map)}
      zoom={8}
      onClick={props.onMapClick}
      center={props.center || { lat: 16.059959, lng: 108.224258 }}
      options={{ 
        gestureHandling: 'greedy', 
        mapId: process.env.REACT_APP_MAP_ID,
      }}
      mapContainerClassName={props.containerElement}
    >
      {props.polygon ? (
        <Polygon
          paths={props.polygon}
          options={{
            strokeColor: 'yellow',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: 'red',
            fillOpacity: 0.4,
          }}
        />
      ) : (
        ''
      )}
    </GoogleMap>
  )
}

class FlatSetting extends Component {
  constructor() {
    super();
    this.state = {
      dataList: null,
      detailItem: null,
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      sortType: -1,
      editable: false,
      isEditRouteModel: false,
      isShowImport: false,
      routeModel: null,
      zoneList: [],
      footerRoutesListOptions: {
        limit: 5,
        total: 0,
        page: 0,
      },
      showConfirmRoute: false,
      rateValid: {},
      routeValid: {},
      rateSubmitted: false,
      routeSubmitted: false,
      tableHeight: 500,
      tableModalHeight: 300,
      rowHeight: 50,
      routeTableHeight: 100,
      footerData: {
        limit: 20,
        total: 0,
        page: 0,
      },
      str: '',
      flatRoutersStr: '',
      showPercent: false,
      exportPercent: 0,
    };
    this.handleResultExport = handleResultExport.bind(this);
  }
  async componentDidMount() {
    this.setState({
      routeTableHeight:
        this.props.auth.selectedFleet.currencies.length < 3 ? 50 : 100,
    });
    const locationQuery = _.get(this.props.router, 'location.query', {});
    if (locationQuery.rateId) {
      this.handleOpenFromDeeplink(locationQuery.rateId);
    }
    this.updateDataList();
  }

  handleOpenFromDeeplink = async (routeId) => {
    if (routeId) {
      try {
        const resultMapList = await this.props.settingActions.fetchAllMapZone(
          this.props.auth.selectedFleet.fleetId
        );
        if (resultMapList.ok && resultMapList.res) {
          this.setState({ zoneList: resultMapList.res }, async () => {
            const resultFlatDetail =
              await this.props.settingActions.getDetailFlatRateRoutes({
                fleetId: this.props.auth.selectedFleet.fleetId,
                id: routeId,
              });
            if (resultFlatDetail.ok && resultFlatDetail.res) {
              let routeModel = resultFlatDetail.res;
              const resultRateFlat =
                await this.props.settingActions.getRateDetailRateFlat({
                  _id: routeModel.fareFlatId,
                  fleetId: this.props.auth.selectedFleet.fleetId,
                });
              if (!routeModel.zone) {
                routeModel.zone = {
                  fromZone: {},
                  toZone: {},
                };
              }
              if (resultRateFlat?.res?._id) {
                this.setState(
                  {
                    dialogData: resultRateFlat.res,
                    dialogChanged: false,
                    editable: true,
                    routeValid: {},
                    isEditRouteModel: true,
                    showDialog: true,
                    routeModel: routeModel,
                    dialogChanged: false,
                  },
                  this.getFlatRoutesList
                );
              }
            }
          });
        }
      } catch (error) {}
    }
  };

  handleSearchChange = (e) => {
    let str = e.target.value;
    this.setState({ str: str });
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.updateDataList();
    }
  };

  updateDataList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerData.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerData.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      search: this.state.str,
    };

    this.props.settingActions.getAllRateFlats(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.setState({
          dataList: data.res,
          footerData: { page, total, limit },
        });
      }
    });
  };

  saveDialogForm = () => {
    this.state.dialogData.fleetId = this.props.auth.selectedFleet.fleetId;
    this.setState({ rateSubmitted: true });
    if (CCLiteCommonFunc.isFormValid(this.state.rateValid)) {
      this.props.loadingBarActions.showLoadingSpiner();
      this.state.dialogData.fareWaitTimeNow = this.state.dialogData
        .fareWaitTimeNow
        ? parseFloat(this.state.dialogData.fareWaitTimeNow)
        : 0;
      this.state.dialogData.fareWaitTimeLater = this.state.dialogData
        .fareWaitTimeLater
        ? parseFloat(this.state.dialogData.fareWaitTimeLater)
        : 0;
      if (this.state.dialogData._id) {
        let objectCompany = {
          fleetId: this.props.auth.selectedFleet.fleetId,
          name: this.state.dialogData.name,
          _id: this.state.dialogData._id,
        };
        this.props.settingActions
          .updateRateFlat(this.state.dialogData)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (!data.ok) {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Update_flat_rate_fail')
                );
              }
            } else {
              this.context.notification(
                'success',
                I18n.t('regularSetting.Update_flat_rate_success')
              );
              this.closeDialogForm();
              this.updateDataList();
            }
          });
      } else {
        this.props.settingActions
          .createRateFlat(this.state.dialogData)
          .then((data) => {
            this.props.loadingBarActions.hideLoadingSpiner();
            if (data.ok && data.res) {
              this.context.notification(
                'success',
                I18n.t('regularSetting.Created_flat_rate_success')
              );
              this.setState({ dialogData: data.res, editable: false });
              this.updateDataList();
              this.setState({ rateSubmitted: false });
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Created_flat_rate_fail')
                );
              }
            }
          });
      }
    }
  };
  handleInputChange = (key, e) => {
    this.state.dialogData[key] = e.target.value;
    if (key == 'onDemand_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.onDemand.payToDrv =
        e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver') {
      this.state.dialogData.cancellationPolicy.inAdvance.payToDrv =
        e.target.value;
    }
    if (key == 'onDemand_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.onDemand.type = e.target.value;
    }
    if (key == 'inAdvance_Pay_to_driver_type') {
      this.state.dialogData.cancellationPolicy.inAdvance.type = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver') {
      this.state.dialogData.noShow.payToDrv = e.target.value;
    }
    if (key == 'noShow_Pay_to_driver_type') {
      this.state.dialogData.noShow.type = e.target.value;
    }
    if (key == 'service_type') {
      this.state.dialogData.serviceType = e.target.value;
      this.state.dialogData['carType'] = '';
    }
    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleRouteInputChange = (key, key2, e, key3) => {
    this.setState({
      routeValid: {},
    });
    if (!!key2) {
      if (!!key3) {
      } else {
        this.state.routeModel[key][key2][key3] = e.target.value;
      }
    } else {
      this.state.routeModel[key] = e.target.value;
      if (key == 'routeType') {
        if (e.target.value == 'zone') {
          this.state.routeModel.zone.toZone = null;
          this.state.routeModel.zone.fromZone = null;
        } else {
          this.state.routeModel.zone.toZone = null;
          this.state.routeModel.zone.fromZone = null;
        }
        this.state.routeModel.singleTrip = {
          departureRoute: 0,
          enableReturnRoute: false,
          returnRoute: 0,
          limitation: {
            limited: false,
            coveredDistance: 0,
            coveredTime: 0,
            extraDistanceByCurrencies:
              this.props.auth.selectedFleet.currencies.map((data) => {
                return {
                  value: 0,
                  currencyISO: data.iso,
                };
              }),
            extraTimeByCurrencies: this.props.auth.selectedFleet.currencies.map(
              (data) => {
                return {
                  value: 0,
                  currencyISO: data.iso,
                };
              }
            ),
          },
          departureRouteByCurrencies:
            this.props.auth.selectedFleet.currencies.map((data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }),
          returnRouteByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        };
        this.state.routeModel.roundTrip = {
          enableRoundTrip: false,
          roundTripFee: 0,
          limitation: {
            limited: false,
            coveredDistance: 0,
            coveredTime: 0,
            extraDistanceByCurrencies:
              this.props.auth.selectedFleet.currencies.map((data) => {
                return {
                  value: 0,
                  currencyISO: data.iso,
                };
              }),
            extraTimeByCurrencies: this.props.auth.selectedFleet.currencies.map(
              (data) => {
                return {
                  value: 0,
                  currencyISO: data.iso,
                };
              }
            ),
          },
          roundTripFeeByCurrencies:
            this.props.auth.selectedFleet.currencies.map((data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }),
        };
        this.state.routeModel.strFrom = '';
        this.state.routeModel.strTo = '';
      }
    }

    this.setState(
      { routeModel: this.state.routeModel, dialogChanged: true },
      () => {
        const toZoneCoordinates = _.get(
          this.state.routeModel,
          'zone.toZone.coordinates',
          null
        );
        if (this.state.routeModel.zone && toZoneCoordinates) {
          if (
            this.props.commonData.location &&
            this.props.commonData.location.isChina
          ) {
            let latLngTo = new window.qq.maps.LatLngBounds();
            toZoneCoordinates
              ? _.isArray(toZoneCoordinates[0]) &&
                toZoneCoordinates[0].length > 0 &&
                toZoneCoordinates[0].map((pos) => {
                  latLngTo.extend(new window.qq.maps.LatLng(pos[1], pos[0]));
                })
              : latLngTo.extend(new window.qq.maps.LatLng(0, 0));
            if (this.toZone) {
              this.toZone.fitBounds(latLngTo);
            }

            const fromZoneCoordinates = _.get(
              this.state.routeModel,
              'zone.fromZone.coordinates',
              null
            );
            let latLngFrom = new window.qq.maps.LatLngBounds();
            fromZoneCoordinates
              ? _.isArray(fromZoneCoordinates[0]) &&
                fromZoneCoordinates[0].length > 0 &&
                fromZoneCoordinates[0].map((pos) => {
                  latLngFrom.extend(new window.qq.maps.LatLng(pos[1], pos[0]));
                })
              : latLngFrom.extend(new window.qq.maps.LatLng(0, 0));
            if (this.fromZone) {
              this.fromZone.fitBounds(latLngFrom);
            }
          } else {
            let latLngTo = new google.maps.LatLngBounds();
            toZoneCoordinates
              ? _.isArray(toZoneCoordinates[0]) &&
                toZoneCoordinates[0].length > 0 &&
                toZoneCoordinates[0].map((pos) => {
                  latLngTo.extend(new google.maps.LatLng(pos[1], pos[0]));
                })
              : latLngTo.extend(new google.maps.LatLng(0, 0));
            if (this.toZone) {
              this.toZone.fitBounds(latLngTo);
            }

            const fromZoneCoordinates = _.get(
              this.state.routeModel,
              'zone.fromZone.coordinates',
              null
            );
            let latLngFrom = new google.maps.LatLngBounds();
            fromZoneCoordinates
              ? _.isArray(fromZoneCoordinates[0]) &&
                fromZoneCoordinates[0].length > 0 &&
                fromZoneCoordinates[0].map((pos) => {
                  latLngFrom.extend(new google.maps.LatLng(pos[1], pos[0]));
                })
              : latLngFrom.extend(new google.maps.LatLng(0, 0));
            if (this.fromZone) {
              this.fromZone.fitBounds(latLngFrom);
            }
          }
        }
      }
    );
  };

  handleRouteZoneChange = (key, e) => {
    if (e.target.value == '') {
      this.state.routeModel.zone[key] = null;
      this.setState({ routeModel: this.state.routeModel, dialogChanged: true });
      return;
    }
    this.state.zoneList.map((item) => {
      if (item._id == e.target.value) {
        this.state.routeModel.zone[key] = {
          zoneId: item._id,
          name: item.zoneName,
          type: 'Polygon',
          coordinates: item.geo.coordinates,
        };
      }
    });

    if (
      this.state.routeModel.zone.fromZone &&
      this.state.routeModel.zone.toZone &&
      this.state.routeModel.zone.fromZone.zoneId ==
        this.state.routeModel.zone.toZone.zoneId
    ) {
      this.state.routeModel.singleTrip.enableReturnRoute = false;
    }
    if (
      this.props.commonData.location &&
      this.props.commonData.location.isChina
    ) {
      this.setState({ routeModel: this.state.routeModel, dialogChanged: true });
    } else {
      var latLng = new google.maps.LatLngBounds();
      this.state.routeModel.zone[key].coordinates[0].map((pos) => {
        latLng.extend(new google.maps.LatLng(pos[1], pos[0]));
      });
      if (this[key]) {
        this[key].fitBounds(latLng);
      }
      this.setState({ routeModel: this.state.routeModel, dialogChanged: true });
    }
  };

  handleCancelInputChange = (key, e) => {
    switch (key) {
      case 'onDemand_isActive': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_tax': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'onDemand_techFee': {
        if (!this.state.dialogData.cancellationPolicy.onDemand) {
          this.state.dialogData.cancellationPolicy.onDemand = {};
        }
        this.state.dialogData.cancellationPolicy.onDemand.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_isActive': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.isActive =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_tax': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTax =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'inAdvance_techFee': {
        if (!this.state.dialogData.cancellationPolicy.inAdvance) {
          this.state.dialogData.cancellationPolicy.inAdvance = {};
        }
        this.state.dialogData.cancellationPolicy.inAdvance.enableTechFee =
          e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_tax': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTax = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'noShow_techFee': {
        if (!this.state.dialogData.noShow) {
          this.state.dialogData.noShow = {};
        }
        this.state.dialogData.noShow.enableTechFee = e.target.checked;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };

  handleCancelInputMultiCurrenciesChange = (group, currency, e) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    if (group == 'noShow') {
      this.state.dialogData.noShow.valueByCurrencies =
        this.state.dialogData.noShow.valueByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            return Object.assign(c, cur);
          }
          return c;
        });
      if (isAdd) {
        this.state.dialogData.noShow.valueByCurrencies.push(cur);
      }
    } else if (group == 'noShowPayDrvAmt') {
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.noShow.drvGetAmtByCurrencies =
        this.state.dialogData.noShow.drvGetAmtByCurrencies.map((c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            c.value = e.target.value;
            return c;
          }
          return c;
        });
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.noShow.drvGetAmtByCurrencies.push(ob);
      }
    } else if (group == 'onDemandPayDrvAmt') {
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else if (group == 'inAdvancePayDrvAmt') {
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance
          .drvGetAmtByCurrencies ||
        this.props.auth.selectedFleet.currencies.map((data) => {
          return {
            value: DefaultCurenciesNumberInputField,
            currencyISO: data.iso,
          };
        });
      this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies =
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              c.value = e.target.value;
              return c;
            }
            return c;
          }
        );
      if (isAdd) {
        let ob = {
          currencyISO: currency,
          value: DefaultCurenciesNumberInputField,
        };
        ob[group] = e.target.value;
        this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.push(
          ob
        );
      }
    } else {
      this.state.dialogData.cancellationPolicy[group].valueByCurrencies =
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.map(
          (c) => {
            if (c.currencyISO == currency) {
              isAdd = false;
              return Object.assign(c, cur);
            }
            return c;
          }
        );
      if (isAdd) {
        this.state.dialogData.cancellationPolicy[group].valueByCurrencies.push(
          cur
        );
      }
    }

    this.setState({ dialogData: this.state.dialogData, dialogChanged: true });
  };

  handleRouteMultiCurrenciesChange = (group, key, currency, e, key2) => {
    let cur = {
      currencyISO: currency,
      value: e.target.value,
    };
    let isAdd = true;
    if (key2) {
      this.state.routeModel[group][key][key2] = this.state.routeModel[group][
        key
      ][key2].map((c) => {
        if (c.currencyISO == currency) {
          isAdd = false;
          return Object.assign(c, cur);
        }
        return c;
      });
      if (isAdd) {
        this.state.routeModel[group][key][key2].push(cur);
      }
    } else {
      this.state.routeModel[group][key] = (this.state.routeModel[group][key] || []).map(
        (c) => {
          if (c.currencyISO == currency) {
            isAdd = false;
            return Object.assign(c, cur);
          }
          return c;
        }
      );
      if (isAdd) {
        this.state.routeModel[group][key].push(cur);
      }
    }

    this.setState({ routeModel: this.state.routeModel });
  };

  getValueByCurrency = (ios, curencies) => {
    let value = 0;
    curencies.map((c) => {
      if (c.currencyISO == ios) {
        value = c.value;
      }
    });
    return value;
  };

  handlePerminuteInputChange = (key, e) => {
    switch (key) {
      case 'feePerMinute': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].feePerMinute = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minNow': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minNow = e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
      case 'minReservation': {
        if (!this.state.dialogData.feesByCurrencies) {
          this.state.dialogData.feesByCurrencies = [];
        }
        this.state.dialogData.feesByCurrencies[0].minReservation =
          e.target.value;
        this.setState({
          dialogData: this.state.dialogData,
          dialogChanged: true,
        });
        break;
      }
    }
  };
  checkboxChangeHandle = (group, key, e) => {
    this.state.routeModel[group][key] = e.target.checked;
    this.setState({ routeModel: this.state.routeModel });
    //'singleTrip', 'enableReturnRoute', e
    //'roundTrip', 'enableRoundTrip', e
    let { routeValid } = this.state;
    this.setState({ routeValid: {} });
    //'singleTrip', 'enableReturnRoute', e
    if (
      group == 'singleTrip' &&
      key == 'enableReturnRoute' &&
      !e.target.checked
    ) {
      const { routeModel } = this.state;
      let returnRouteByCurrencies =
        routeModel.singleTrip.returnRouteByCurrencies;
      let singleTrip = routeModel.singleTrip;
      if (returnRouteByCurrencies && returnRouteByCurrencies.length > 0) {
        returnRouteByCurrencies = returnRouteByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      this.setState({
        routeModel: {
          ...routeModel,
          singleTrip: {
            ...singleTrip,
            returnRouteByCurrencies,
          },
        },
      });
    }
    //'roundTrip', 'enableRoundTrip', e
    if (group == 'roundTrip' && key == 'enableRoundTrip' && !e.target.checked) {
      const { routeModel } = this.state;
      let roundTripFeeByCurrencies =
        routeModel.singleTrip.roundTripFeeByCurrencies;
      let roundTrip = routeModel.roundTrip;
      if (roundTripFeeByCurrencies && roundTripFeeByCurrencies.length > 0) {
        roundTripFeeByCurrencies = roundTripFeeByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      this.setState({
        routeModel: {
          ...routeModel,
          roundTrip: {
            ...roundTrip,
            roundTripFeeByCurrencies,
          },
        },
      });
    }
  };
  radioChangeHandle = (group, key, status) => {
    this.state.routeModel[group][key].limited = status;
    this.setState({ routeModel: this.state.routeModel });
    let { routeValid } = this.state;
    this.setState({ routeValid: {} });
    //update valid value for disable field if not we can not submit to server
    if (group == 'singleTrip' && key == 'limitation' && status == false) {
      const { routeModel } = this.state;
      let limitation = routeModel.singleTrip.limitation;
      let singleTrip = routeModel.singleTrip;
      let extraDistanceByCurrencies =
        routeModel.singleTrip.limitation.extraDistanceByCurrencies;
      let extraTimeByCurrencies =
        routeModel.singleTrip.limitation.extraTimeByCurrencies;
      if (extraDistanceByCurrencies && extraDistanceByCurrencies.length > 0) {
        extraDistanceByCurrencies = extraDistanceByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      if (extraTimeByCurrencies && extraTimeByCurrencies.length > 0) {
        extraTimeByCurrencies = extraTimeByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      this.setState({
        routeModel: {
          ...routeModel,
          singleTrip: {
            ...singleTrip,
            limitation: {
              limited: false,
              extraTimeByCurrencies,
              extraDistanceByCurrencies,
              coveredDistance: 0,
              coveredTime: 0,
            },
          },
        },
      });
    }

    if (group == 'roundTrip' && key == 'limitation' && status == false) {
      const { routeModel } = this.state;
      let limitation = routeModel.roundTrip.limitation;
      let roundTrip = routeModel.roundTrip;
      let extraDistanceByCurrencies =
        routeModel.roundTrip.limitation.extraDistanceByCurrencies;
      let extraTimeByCurrencies =
        routeModel.roundTrip.limitation.extraTimeByCurrencies;
      if (extraDistanceByCurrencies && extraDistanceByCurrencies.length > 0) {
        extraDistanceByCurrencies = extraDistanceByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      if (extraTimeByCurrencies && extraTimeByCurrencies.length > 0) {
        extraTimeByCurrencies = extraTimeByCurrencies.map((obj) => {
          return {
            ...obj,
            value: 0,
          };
        });
      }
      this.setState({
        routeModel: {
          ...routeModel,
          roundTrip: {
            ...roundTrip,
            limitation: {
              extraDistanceByCurrencies,
              extraTimeByCurrencies,
              limited: false,
              coveredDistance: 0,
              coveredTime: 0,
            },
          },
        },
      });
    }
  };

  limitationInputChangeHandle = (group, key, e) => {
    this.state.routeModel[group].limitation[key] = e.target.value;
    this.setState({ routeModel: this.state.routeModel });
  };

  handleAddButtonClick = () => {
    let object = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      name: '',

      noShow: {
        isActive: false,
        enableTax: false,
        enableTechFee: true,
        payToDrv: 'commission',
        valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
        drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
      },
      serviceType: 'transport',
      cancellationPolicy: {
        isActive: false,
        inAdvance: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
        onDemand: {
          isActive: false,
          enableTax: false,
          enableTechFee: true,
          payToDrv: 'commission',
          valueByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
          drvGetAmtByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
      },
    };
    this.setState({ showDialog: true, dialogData: object, editable: true });
  };
  handleAddRouteButtonClick = () => {
    this.setState({ routeValid: {} });
    let routesOb = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      fareFlatId: this.state.dialogData._id,
      routeName: '',
      routeType: RouteType.Zone,
      routeFrom: '',
      routeTo: '',
      singleTrip: {
        departureRoute: 0,
        enableReturnRoute: false,
        returnRoute: 0,
        limitation: {
          limited: false,
          coveredDistance: 0,
          coveredTime: 0,
          extraDistanceByCurrencies:
            this.props.auth.selectedFleet.currencies.map((data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }),
          extraTimeByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
        departureRouteByCurrencies:
          this.props.auth.selectedFleet.currencies.map((data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }),
        returnRouteByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
      },
      roundTrip: {
        enableRoundTrip: false,
        roundTripFee: 0,
        limitation: {
          limited: false,
          coveredDistance: 0,
          coveredTime: 0,
          extraDistanceByCurrencies:
            this.props.auth.selectedFleet.currencies.map((data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }),
          extraTimeByCurrencies: this.props.auth.selectedFleet.currencies.map(
            (data) => {
              return {
                value: 0,
                currencyISO: data.iso,
              };
            }
          ),
        },
        roundTripFeeByCurrencies: this.props.auth.selectedFleet.currencies.map(
          (data) => {
            return {
              value: 0,
              currencyISO: data.iso,
            };
          }
        ),
      },
      zone: {
        toZone: null,
        fromZone: null,
      },
    };
    this.props.settingActions
      .fetchAllMapZone(this.props.auth.selectedFleet.fleetId)
      .then((res) => {
        if (res.ok && res.res) {
          this.setState({ zoneList: res.res });
        }
      });
    this.setState({ isEditRouteModel: true, routeModel: routesOb });
  };
  saveRouteModelClick = (e) => {
    e.preventDefault();
    this.setState({ routeSubmitted: true });
    if (!CCLiteCommonFunc.isFormValid(this.state.routeValid)) {
      return;
    }
    let routesOb = {
      fleetId: this.props.auth.selectedFleet.fleetId,
      fareFlatId: this.state.dialogData._id,
      routeName: this.state.routeModel.routeName,
      routeType: this.state.routeModel.routeType,
      routeFrom:
        this.state.routeModel.routeType == 'zone'
          ? this.state.routeModel.zone.fromZone.zoneId
          : this.state.routeModel.strFrom,
      routeTo:
        this.state.routeModel.routeType == 'zone'
          ? this.state.routeModel.zone.toZone.zoneId
          : this.state.routeModel.strTo,
      singleTrip: this.state.routeModel.singleTrip,
      roundTrip: this.state.routeModel.roundTrip,
    };
    this.props.loadingBarActions.showLoadingSpiner();
    if (this.state.routeModel._id) {
      routesOb.id = this.state.routeModel._id;
      this.props.settingActions.updateFlatRateRoute(routesOb).then((res) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (res.ok && res.res) {
          this.getFlatRoutesList();
          this.setState({
            isEditRouteModel: false,
            routeModel: null,
            routeSubmitted: false,
          });
          this.context.notification(
            'success',
            I18n.t('regularSetting.Update_flat_rate_route_success')
          );
        } else {
          if (res.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Update_flat_rate_route_fail')
            );
          }
        }
      });
    } else {
      this.props.settingActions.createFlatRateRoute(routesOb).then((res) => {
        this.props.loadingBarActions.hideLoadingSpiner();
        if (res.ok && res.res) {
          this.getFlatRoutesList();
          this.setState({
            isEditRouteModel: false,
            routeModel: null,
            routeSubmitted: false,
          });
          this.context.notification(
            'success',
            I18n.t('regularSetting.Created_flat_rate_route_success')
          );
        } else {
          if (res.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Created_flat_rate_route_fail')
            );
          }
        }
      });
    }
  };

  closeDialogForm = () => {
    this.setState({
      showDialog: false,
      dialogData: null,
      dialogChanged: false,
      showConfirm: false,
      rateSubmitted: false,
      flatRoutersStr: '',
      footerRoutesListOptions: {
        limit: 5,
        total: 0,
        page: 0,
      },
    });
  };

  closeRouteModelForm = () => {
    this.setState({
      isEditRouteModel: false,
      routeModel: null,
      routeSubmitted: false,
      isDisableRoute: false,
    });
  };

  handleSearchInModalChange = (e) => {
    let str = e.target.value;
    this.setState({ flatRoutersStr: str });
  };

  handleSearchInModalKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getFlatRoutesList();
    }
  };

  getFlatRoutesList = (activePage, limit) => {
    let params = {
      limit: limit || this.state.footerRoutesListOptions.limit,
      page:
        activePage || activePage === 0
          ? activePage
          : this.state.footerRoutesListOptions.page,
      fleetId: this.props.auth.selectedFleet.fleetId,
      query: {
        fleetId: this.props.auth.selectedFleet.fleetId,
        fareFlatId: this.state.dialogData._id,
        search: this.state.flatRoutersStr,
      },
    };

    this.props.settingActions.getAllFlatRateRoutes(params).then((data) => {
      if (data.ok && data.res) {
        let { page, total, limit } = data.res;
        this.state.dialogData.dataList = data.res;
        this.setState({
          dialogData: this.state.dialogData,
          footerRoutesListOptions: { page, total, limit },
        });
      }
    });
  };

  handleMenuClick = (key, data) => {
    switch (key) {
      case 'View': {
        this.props.settingActions
          .getRateDetailRateFlat({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((res) => {
            if (res.ok) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: res.res,
                  dialogChanged: false,
                  editable: false,
                },
                this.getFlatRoutesList
              );
            } else {
              if (res.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + res.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Loading_flat_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Edit': {
        this.props.settingActions
          .getRateDetailRateFlat({
            _id: data._id,
            fleetId: this.props.auth.selectedFleet.fleetId,
          })
          .then((data) => {
            if (data.ok) {
              this.setState(
                {
                  showDialog: true,
                  dialogData: data.res,
                  dialogChanged: false,
                  editable: true,
                },
                this.getFlatRoutesList
              );
            } else {
              if (data.error) {
                this.context.notification(
                  'error',
                  I18n.t('errors.' + data.error.errorCode)
                );
              } else {
                this.context.notification(
                  'error',
                  I18n.t('regularSetting.Loading_flat_rate_fail')
                );
              }
            }
          });
        break;
      }
      case 'Status': {
        if (data.isActive) {
          this.props.settingActions
            .deactiveRateFlat({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_flat_rate_fail')
                  );
                }
              }
            });
        } else {
          this.props.settingActions
            .activeRateFlat({
              fleetId: this.props.auth.selectedFleet.fleetId,
              _id: data._id,
            })
            .then((data) => {
              if (data.ok) {
                this.updateDataList();
              } else {
                if (data.error) {
                  this.context.notification(
                    'error',
                    I18n.t('errors.' + data.error.errorCode)
                  );
                } else {
                  this.context.notification(
                    'error',
                    I18n.t('regularSetting.Update_flat_rate_fail')
                  );
                }
              }
            });
        }

        break;
      }
      case 'changeStatus': {
        this.setState({ editable: true });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirm: true, dialogData: data });
        break;
      }
    }
  };

  handleRouteMenuClick = (key, data) => {
    switch (key) {
      case 'Edit': {
        this.props.settingActions
          .getDetailFlatRateRoutes({
            fleetId: this.props.auth.selectedFleet.fleetId,
            id: data._id,
          })
          .then((res) => {
            if (res.ok && res.res) {
              this.setState({ routeValid: {} });
              let routeModel = res.res;
              if (!routeModel.zone) {
                routeModel.zone = {
                  fromZone: {},
                  toZone: {},
                };
              }
              this.setState({
                isEditRouteModel: true,
                routeModel: res.res,
                dialogChanged: false,
              });
            }
          });

        this.props.settingActions
          .fetchAllMapZone(this.props.auth.selectedFleet.fleetId)
          .then((res) => {
            if (res.ok && res.res) {
              this.setState({ zoneList: res.res });
            }
          });
        break;
      }
      case 'Delete': {
        this.setState({ showConfirmRoute: true, routeModel: data });
        break;
      }
      default:
        break;
    }
  };

  closeDialogRouteForm = () => {
    this.setState({
      showConfirmRoute: false,
      routeModel: null,
      routeSubmitted: false,
    });
  };

  confirmDeleteFlatRoute = () => {
    this.props.settingActions
      .deleteFlatRateRoute({
        fleetId: this.props.auth.selectedFleet.fleetId,
        id: this.state.routeModel._id,
      })
      .then((res) => {
        if (res.ok && res.res) {
          this.getFlatRoutesList();
          this.setState({ showConfirmRoute: false, routeModel: null });
        } else {
          if (res.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + res.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Delete_flat_rate_fail')
            );
          }
        }
      });
  };

  handleConfirmCloseClick = () => {
    this.setState({ confirm: null });
  };

  handleImportClick = () => {
    this.setState({ isShowImport: true });
  };

  rateValidatorCallback = (id, valid, messages) => {
    if (this.state.rateValid[id] != valid) {
      this.state.rateValid[id] = valid;
      this.setState({ valid: this.state.rateValid });
    }
  };
  routesValidatorCallback = (id, valid, messages) => {
    if (this.state.routeValid[id] != valid) {
      this.state.routeValid[id] = valid;
      this.setState({ routeValid: this.state.routeValid });
    }
  };

  handlePaginationSelect = (eventKey) => {
    if (this.state.showDialog && this.state.dialogData) {
      this.getFlatRoutesList(eventKey);
    } else {
      this.updateDataList(eventKey);
    }
  };

  handleNumItemsPerPageChange = (e) => {
    let numItemsPerPage = parseInt(e);
    if (this.state.showDialog && this.state.dialogData) {
      this.getFlatRoutesList(0, numItemsPerPage);
    } else {
      this.updateDataList(0, numItemsPerPage);
    }
  };

  confirmDeleteFlat = () => {
    this.props.settingActions
      .deleteRateFlat({
        fleetId: this.props.auth.selectedFleet.fleetId,
        _id: this.state.dialogData._id,
      })
      .then((data) => {
        if (data.ok) {
          this.closeDialogForm();
          this.updateDataList();
          this.context.notification(
            'success',
            I18n.t('regularSetting.Delete_flat_rate_success')
          );
        } else {
          if (data.error) {
            this.context.notification(
              'error',
              I18n.t('errors.' + data.error.errorCode)
            );
          } else {
            this.context.notification(
              'error',
              I18n.t('regularSetting.Delete_flat_rate_fail')
            );
          }
        }
      });
  };

  convertCoordinatesToPath(coor) {
    if (coor) {
      if (
        this.props.commonData.location &&
        this.props.commonData.location.isChina
      ) {
        return coor.map((pos) => {
          return new window.qq.maps.LatLng(pos[1], pos[0]);
        });
      } else {
        return coor.map((pos) => {
          return {
            lat: pos[1],
            lng: pos[0],
          };
        });
      }
    }
    return [];
  }

  getCenterPoint = (coor, isFromZone) => {
    if (coor) {
      if (
        this.props.commonData.location &&
        this.props.commonData.location.isChina
      ) {
        var latLng = new window.qq.maps.LatLngBounds();

        coor.map((pos) => {
          latLng.extend(new window.qq.maps.LatLng(pos[1], pos[0]));
        });

        if (isFromZone) {
          if (this.fromZone) this.fromZone.fitBounds(latLng);
        } else {
          if (this.toZone) this.toZone.fitBounds(latLng);
        }
        return latLng.getCenter();
      } else {
        var latLng = new google.maps.LatLngBounds();

        coor.map((pos) => {
          latLng.extend(new google.maps.LatLng(pos[1], pos[0]));
        });
        const centerLatLng = latLng.getCenter();
        if (isFromZone) {
          if (this.fromZone) this.fromZone.fitBounds(latLng);
        } else {
          if (this.toZone) this.toZone.fitBounds(latLng);
        }
        return {
          lat: centerLatLng.lat(),
          lng: centerLatLng.lng(),
        };
      }
    }
    return null;
  };

  handleMapLoad = (key, ref) => {
    if (ref) {
      this[key] = ref;
    }
  };

  checkValidFromZipCode = () => {
    const { routeModel } = this.state;
    if (routeModel.routeType == RouteType.Zone) return true;
    return !Validation.isStringEmpty(routeModel.strFrom);
  };

  checkValidToZipCode = () => {
    const { routeModel } = this.state;
    if (routeModel.routeType == RouteType.Zone) return true;
    return !Validation.isStringEmpty(routeModel.strTo);
  };

  checkValidRoute2Currencies = (checkMode) => {
    const { routeModel } = this.state;
    if (!routeModel.singleTrip.enableReturnRoute) return true;
    if (checkMode == CheckMode.checkInteger) {
      return (
        routeModel.singleTrip.returnRouteByCurrencies.filter((d) => {
          return d.value !== '' && isNaN(d.value);
        }).length == 0
      );
    }
    if (checkMode == CheckMode.checkGreaterThan0) {
      return (
        routeModel.singleTrip.returnRouteByCurrencies.filter((d) => {
          return d.value < 0;
        }).length == 0
      );
    }
  };

  checkRouteChipEnable = (valid) => {
    if (!this.state.routeModel.roundTrip.enableRoundTrip) return true;
    return valid;
  };

  checkSingleTripLimitted = (valid) => {
    const { routeModel } = this.state;
    if (!routeModel.singleTrip.limitation.limited) return true;
    return valid;
  };

  checkRoundTripLimitted = (valid) => {
    const { routeModel } = this.state;
    if (!routeModel.roundTrip.limitation.limited) return true;
    return valid;
  };

  getTableColumns = () => {
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    let tableColumns = Object.assign([], Columns);

    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'name':
          col.customCell = (regular) => {
            return (
              <OverlayTrigger
                overlay={<Tooltip id={'tooltip'}>{regular.name}</Tooltip>}
                placement="top"
                delayShow={300}
              >
                <div
                  className="clickable text-ellipsis"
                  onClick={() => {
                    this.handleMenuClick('View', regular);
                  }}
                >
                  {regular.name}
                </div>
              </OverlayTrigger>
            );
          };
          break;

        case 'serviceType':
          col.customCell = (regular) => {
            return (
              <Translate value={`regularSetting.${regular.serviceType}`} />
            );
          };
          break;

        case 'latestUpdate':
          col.customCell = (regular) => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={regular.latestUpdate}
              />
            );
          };
          break;

        case 'regularStatus':
          col.customCell = (regular) => {
            let statusStr, statusClass;
            if (regular && regular.isActive) {
              statusStr = <Translate value="regular.Active" />;
              statusClass = 'Active';
            } else {
              if (regular && !regular.isActivate) {
                statusStr = <Translate value="regular.Inactive" />;
                statusClass = 'Inactive';
              } else statusStr = null;
            }
            return (
              <div className={statusClass}>
                {!regular.inUse && hasPermission ? (
                  <a
                    onClick={() => {
                      this.handleMenuClick('Status', regular);
                    }}
                    href="javascript:void(0)"
                  >
                    {statusStr}
                  </a>
                ) : (
                  statusStr
                )}
              </div>
            );
          };
          break;

        case 'actions':
          col.customCell = (regular, rowIndex) => {
            if (hasPermission) {
              let actionItems = [
                {
                  label: 'regular.Edit',
                  eventKey: 'Edit',
                },
              ];

              if (!regular.inUse) {
                actionItems.push({
                  label: regular.isActive
                    ? 'regular.Deactivate'
                    : 'regular.Activate',
                  eventKey: 'Status',
                });

                if (!regular.isActive) {
                  actionItems.push({
                    label: 'regular.Delete',
                    eventKey: 'Delete',
                  });
                }
              }

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={regular}
                  onSelect={(eventKey) => {
                    this.handleMenuClick(eventKey, regular);
                  }}
                  totalRow={this.state.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={this.state.tableHeight}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleMenuClick('View', regular);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  getTableHeight = () => {
    let verticalPadding = 10,
      toolbarMarginBottom = 10,
      toolbarheight = this.toobarContainer
        ? ReactDOM.findDOMNode(this.toobarContainer).clientHeight
        : 0,
      parentHeight = this ? ReactDOM.findDOMNode(this).clientHeight : 0;
    let outerHeight = verticalPadding + toolbarMarginBottom + toolbarheight;
    this.state.tableHeight = parentHeight ? parentHeight - outerHeight : 0;
    return this.state.tableHeight;
  };

  getRouterTableColumns = () => {
    const hasPermission =
      !this.props.permissions || this.props.permissions.actions;
    let tableColumns = Object.assign([], RouterTableColumns);
    if (
      this.state.dialogData &&
      this.state.dialogData.serviceType === 'delivery'
    ) {
      tableColumns = tableColumns.filter(
        (o) => o.key != 'roundTrip' && o.key != 'roundTripFare'
      );
    }
    // custom data by column
    _.forEach(tableColumns, (col, index) => {
      switch (col.key) {
        case 'routeType':
          col.customCell = (flatRouter) => {
            return (
              <span>
                {flatRouter.routeType == RouteType.ZipCode
                  ? I18n.t('regularSetting.Zipcode_to_zipcode')
                  : I18n.t('regularSetting.Zone_to_zone')}
              </span>
            );
          };
          break;
        case 'route1Fare':
          col.customCell = (flatRouter) => {
            let renderList =
              flatRouter.singleTrip.departureRouteByCurrencies.length > 3
                ? flatRouter.singleTrip.departureRouteByCurrencies.slice(0, 3)
                : flatRouter.singleTrip.departureRouteByCurrencies;
            return (
              <div>
                {renderList.map((item) => (
                  <div>
                    {currencyFormatter.format(item.value, {
                      code: item.currencyISO,
                    })}
                  </div>
                ))}
                {flatRouter.singleTrip.departureRouteByCurrencies.length >
                  3 && <div>...</div>}
              </div>
            );
          };
          break;
        case 'route2Fare':
          col.customCell = (flatRouter) => {
            if (!flatRouter.singleTrip.enableReturnRoute) return <div />;
            let renderList =
              flatRouter.singleTrip.returnRouteByCurrencies.length > 3
                ? flatRouter.singleTrip.returnRouteByCurrencies.slice(0, 3)
                : flatRouter.singleTrip.returnRouteByCurrencies;
            return (
              <div>
                {renderList.map((item) => (
                  <div>
                    {currencyFormatter.format(item.value, {
                      code: item.currencyISO,
                    })}
                  </div>
                ))}
                {flatRouter.singleTrip.returnRouteByCurrencies.length > 3 && (
                  <div>...</div>
                )}
              </div>
            );
          };
          break;
        case 'roundTrip':
          col.customCell = (flatRouter) => {
            return (
              <span>
                {flatRouter.roundTrip.enableRoundTrip ? (
                  <i
                    className={'fa fa-check text-active'}
                    aria-hidden={'true'}
                  />
                ) : (
                  ''
                )}
              </span>
            );
          };
          break;
        case 'roundTripFare':
          col.customCell = (flatRouter) => {
            return (
              <span>
                {flatRouter.roundTrip.enableRoundTrip
                  ? flatRouter.roundTrip.roundTripFeeByCurrencies
                      .map((data) => {
                        return currencyFormatter.format(data.value, {
                          code: data.currencyISO,
                        });
                      })
                      .join(', ')
                  : ''}
              </span>
            );
          };
          break;
        case 'latestUpdate':
          col.customCell = (flatRouter) => {
            return (
              <Moment
                tz={this.props.auth.selectedFleet.timezone}
                format="MMM/DD/YYYY"
                date={flatRouter.latestUpdate}
              />
            );
          };
          break;
        case 'actions':
          col.customCell = (flatRouter, rowIndex) => {
            if (hasPermission) {
              let actionItems = [
                {
                  label: 'regularSetting.Edit',
                  eventKey: 'Edit',
                },
                {
                  label: 'regularSetting.Delete',
                  eventKey: 'Delete',
                },
              ];

              return (
                <TableActions
                  rowIndex={rowIndex}
                  rowData={flatRouter}
                  onSelect={(eventKey) => {
                    this.handleRouteMenuClick(eventKey, flatRouter);
                  }}
                  totalRow={this.state.dialogData.dataList.list.length}
                  rowHeight={this.state.rowHeight}
                  menuItems={actionItems}
                  tableHeight={400}
                  tableId={'flat-table-id'}
                />
              );
            } else {
              return (
                <a
                  onClick={(e) => {
                    this.handleRouteMenuClick('Edit', flatRouter);
                  }}
                  href="javascript:void(0)"
                  className="text-active"
                >
                  <Translate value="regular.View" />
                </a>
              );
            }
          };
          break;
      }
    });

    return tableColumns;
  };

  handleDriverCancelChange = (e, type, key) => {
    const dialogData = this.state.dialogData || {};
    let valueInput = 0;
    if (key === 'value') {
      valueInput = (e.target.value || '').toString().replace(/[^0-9\.]+/, '');
    }
    if (key === 'isActive') valueInput = e.target.checked;
    if (key === 'type') valueInput = e.target.value;
    this.setState({
      dialogData: {
        ...dialogData,
        driverCancelPolicy: {
          ..._.get(dialogData, `driverCancelPolicy`, {}),
          [type]: {
            type: _.get(
              dialogData,
              `driverCancelPolicy.${type}.type`,
              'amount'
            ),
            isActive: _.get(
              dialogData,
              `driverCancelPolicy.${type}.isActive`,
              false
            ),
            value: _.get(dialogData, `driverCancelPolicy.${type}.value`, 0),
            [key]: valueInput,
          },
        },
      },
      dialogChanged: true,
    });
  };

  handlePayDrvCashTypeChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              value: _.get(dialogData, `noShow.payDrvCash.value`, 0),
              type: e.target.value,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                value: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.value`,
                  0
                ),
                type: e.target.value,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  handlePayDrvCashValueChange = (typeBooking, e) => {
    const dialogData = this.state.dialogData;
    let valueInput = (e.target.value || '').toString().replace(/[^.0-9]+/, '');
    if (typeBooking === 'noShow') {
      this.setState({
        dialogData: {
          ...dialogData,
          noShow: {
            ...dialogData.noShow,
            payDrvCash: {
              type: _.get(dialogData, `noShow.payDrvCash.type`, 'commission'),
              value: valueInput,
            },
          },
        },
        dialogChanged: true,
      });
    } else {
      this.setState({
        dialogData: {
          ...dialogData,
          cancellationPolicy: {
            ...dialogData.cancellationPolicy,
            [typeBooking]: {
              ..._.get(dialogData, `cancellationPolicy.${typeBooking}`, {}),
              payDrvCash: {
                type: _.get(
                  dialogData,
                  `cancellationPolicy.${typeBooking}.payDrvCash.type`,
                  'commission'
                ),
                value: valueInput,
              },
            },
          },
        },
        dialogChanged: true,
      });
    }
  };

  prepareParamExport = () => {
    return {
      fleetId: this.props.auth.selectedFleet.fleetId,
      total: _.get(this.state.footerData, 'total', 0),
    };
  };

  render() {
    const { dialogData = {} } = this.state;
    const {
      commonData,
      auth: { selectedFleet },
    } = this.props;
    const bodyData =
      this.state.dataList && this.state.dataList.list
        ? this.state.dataList.list
        : [];
    const bodyRouterTableData =
      this.state.dialogData && this.state.dialogData.dataList
        ? this.state.dialogData.dataList.list
        : [];

    const hasPermission =
      this.props.permissions &&
      this.props.permissions.actions &&
      !this.state.isDisableRoute;
    let payToDrvOnDemand = _.get(
      this.state.dialogData,
      'cancellationPolicy.onDemand.payToDrv',
      'commission'
    );
    let payToDrvInAdvance = _.get(
      this.state.dialogData,
      'cancellationPolicy.inAdvance.payToDrv',
      'commission'
    );
    let payToDrvNoShow = _.get(
      this.state.dialogData,
      'noShow.payToDrv',
      'commission'
    );
    const enablePenalty = _.get(
      this.props.auth,
      'selectedFleet.drvApp.enablePenalty',
      false
    );
    const carTypes =
      dialogData && dialogData.serviceType === 'delivery'
        ? commonData.carTypeDelivery
        : commonData.carTypeTransport;
    let isShowDeliveryOption = false;
    if (
      (selectedFleet.food && selectedFleet.food.enable) ||
      (selectedFleet.delivery && selectedFleet.delivery.enable) ||
      (selectedFleet.mart && selectedFleet.mart.enable)
    )
      isShowDeliveryOption = true;
    const cancellationCashBooking = _.get(
      this.props.auth,
      'selectedFleet.generalSetting.cancellationCashBooking',
      false
    );
    const payDrvCashType = _.get(
      dialogData,
      'cancellationPolicy.onDemand.payDrvCash.type',
      'commission'
    );
    const payDrvCashInAdvanceType = _.get(
      dialogData,
      'cancellationPolicy.inAdvance.payDrvCash.type',
      'commission'
    );
    const payDrvCashNoShowType = _.get(
      dialogData,
      'noShow.payDrvCash.type',
      'commission'
    );

    return (
      <div className="content">
        {this.state.showConfirm ? (
          <Modal onHide={this.closeDialogForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="regularSetting.DELETE_FLAT_RATE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="regularSetting.Delete_confirm_flat_rate" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteFlat}
              >
                <Translate value="regularSetting.Yes" />
              </Button>
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showConfirmRoute ? (
          <Modal onHide={this.closeDialogRouteForm} show={true} backdrop={true} className={'confirm'}>
            <Modal.Header closeButton>
              <Modal.Title>
                <Translate value="regularSetting.DELETE_FLAT_ROUTE" />
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogRouteForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <Translate value="regularSetting.DELETE_ROUTE_CONFIRM" />
            </Modal.Body>
            <Modal.Footer>
              <Button
                className={'btn-save mr-md'}
                onClick={this.confirmDeleteFlatRoute}
              >
                <Translate value="regularSetting.Yes" />
              </Button>
              <Button
                className={'btn-cancel'}
                onClick={this.closeDialogRouteForm}
              >
                <Translate value="regularSetting.No" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.showDialog && this.state.dialogData ? (
          <Modal
            show={true}
            backdrop={true}
            dialogClassName={
              this.state.dialogData._id ? 'fit-content-modal' : 'width-700'
            }
            onHide={this.closeDialogForm}
          >
            <Modal.Header className={'pd-r-l-60'}>
              <Modal.Title>
                {this.state.dialogData._id ? (
                  this.state.editable ? (
                    <Translate value="regularSetting.EDIT_FLAT_RATE" />
                  ) : (
                    <Translate value="regularSetting.VIEW_FLAT_RATE" />
                  )
                ) : (
                  <Translate value="regularSetting.ADD_FLAT_RATE" />
                )}
              </Modal.Title>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={this.closeDialogForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </Modal.Header>
            <Modal.Body className={'pd-r-l-60'}>
              <Row>
                <Col
                  md={this.state.dialogData._id ? 4 : 12}
                  xs={this.state.dialogData._id ? 4 : 12}
                >
                  <FormGroup
                    className={
                      this.state.rateSubmitted
                        ? this.state.rateValid.name === false
                          ? 'error'
                          : null
                        : null
                    }
                  >
                    <Form.Label>
                      <Translate value="regularSetting.Name" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      type="text"
                      className={'form-custom'}
                      onChange={(e) => {
                        this.handleInputChange('name', e);
                      }}
                      value={
                        this.state.dialogData ? this.state.dialogData.name : ''
                      }
                      placeholder={I18n.t('regularSetting.Name')}
                      disabled={!this.state.editable}
                    />
                    <Validator
                      id="name"
                      callback={this.rateValidatorCallback}
                      disabled={!this.state.editable}
                    >
                      <ValidCase
                        hide={!this.state.rateSubmitted}
                        valid={this.state.dialogData.name.trim().length > 0}
                        message={I18n.t(
                          'messages.commonMessages.Required_field'
                        )}
                      />
                    </Validator>
                  </FormGroup>

                  {this.state.dialogData.serviceType !== 'delivery' && (
                    <ExtraWaitTime
                      handleInputChange={this.handleInputChange}
                      isSubmited={this.state.rateSubmitted}
                      valid={this.state.rateValid}
                      dialogData={this.state.dialogData}
                      auth={this.props.auth}
                      editable={this.state.editable}
                      ValidatorCallback={this.rateValidatorCallback}
                      disabled={!this.state.editable}
                    />
                  )}
                  <FormGroup>
                    <Form.Label>
                      <Translate value="report.query.serviceType" />{' '}
                      <span className="require">*</span>
                    </Form.Label>
                    <FormControl
                      as="select"
                      className={'form-custom'}
                      value={this.state.dialogData.serviceType}
                      onChange={(e) => {
                        this.handleInputChange('service_type', e);
                      }}
                      disabled={
                        !this.state.editable || this.state.dialogData._id
                      }
                    >
                      {isShowDeliveryOption && (
                        <option value="delivery" key="delivery">
                          {I18n.t('regularSetting.Delivery')}
                        </option>
                      )}
                      <option value="transport" key="transport">
                        {I18n.t('regularSetting.Transport')}
                      </option>
                    </FormControl>
                  </FormGroup>
                  {this.state.dialogData &&
                    this.state.dialogData.serviceType === 'transport' && (
                      <>
                        <FormGroup>
                          <CcCheckbox
                            disabled={!this.state.editable}
                            checked={
                              this.state.dialogData.cancellationPolicy
                                ? this.state.dialogData.cancellationPolicy
                                    .onDemand.isActive
                                : false
                            }
                            onChange={(e) => {
                              this.handleCancelInputChange(
                                'onDemand_isActive',
                                e
                              );
                            }}
                            text={I18n.t('regularSetting.On_demand')}
                            labelClassName="title"
                            className="form-group-title price_adjustment"
                          />
                        </FormGroup>
                        <FormGroup
                          className={
                            this.state.rateSubmitted
                              ? this.state.rateValid
                                  .cancellationOnDemandPenalty === false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Penalty" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Penalty_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormGroup>
                            <FormControl
                              as="select"
                              className={'form-custom'}
                              value={
                                this.state.dialogData.cancellationPolicy
                                  .onDemand.type
                              }
                              onChange={(e) => {
                                this.handleInputChange(
                                  'onDemand_Pay_to_driver_type',
                                  e
                                );
                              }}
                              disabled={!this.state.editable}
                            >
                              <option value="amount" key="amount">
                                {I18n.t('regularSetting.Amount')}
                              </option>
                              <option value="percent" key="percent">
                                {I18n.t('regularSetting.Percent')}
                              </option>
                            </FormControl>
                          </FormGroup>
                          {this.props.auth.selectedFleet.multiCurrencies ? (
                            <div className="input-with-validator">
                              <Tabs
                                id="currencies-tabs-Ondemand"
                                className="currencies-tabs"
                                defaultActiveKey={
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                }
                                animation={false}
                                onSelect={this.tabSelectHandle}
                              >
                                {this.props.auth.selectedFleet.currencies.map(
                                  (c) => {
                                    let currentValue = this.state.dialogData
                                      .cancellationPolicy.onDemand
                                      .valueByCurrencies
                                      ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                          (d) => {
                                            return d.currencyISO == c.iso;
                                          }
                                        )[0]
                                      : null;
                                    return (
                                      <Tab
                                        className="currencies-tab-item"
                                        eventKey={c.iso}
                                        title={c.iso}
                                      >
                                        <FormGroup
                                          className={'qup-input-group'}
                                        >
                                          <InputGroup
                                            className={
                                              'single-addon-left ' +
                                              (!this.state.editable
                                                ? 'disabled'
                                                : '')
                                            }
                                          >
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                {this.state.dialogData
                                                  .cancellationPolicy.onDemand
                                                  .type === 'percent'
                                                  ? '%'
                                                  : c.symbol}
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              type="text"
                                              className={'form-custom'}
                                              onChange={(e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'onDemand',
                                                  c.iso,
                                                  e
                                                );
                                              }}
                                              onBlur={(e) => {
                                                textboxNumberHelper.onBlurHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'onDemand',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              onFocus={(e) => {
                                                textboxNumberHelper.onfocusHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'onDemand',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              value={
                                                currentValue
                                                  ? currentValue.value
                                                  : DefaultCurenciesNumberInputField
                                              }
                                              disabled={!this.state.editable}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                      </Tab>
                                    );
                                  }
                                )}
                              </Tabs>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  !this.state.dialogData.cancellationPolicy ||
                                  !this.state.dialogData.cancellationPolicy
                                    .onDemand ||
                                  !this.state.dialogData.cancellationPolicy
                                    .onDemand.valueByCurrencies ||
                                  !this.state.dialogData.cancellationPolicy
                                    .onDemand.valueByCurrencies.length
                                    ? null
                                    : this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.map(
                                        (fees) => {
                                          const { currencyISO, value } = fees;
                                          return {
                                            [currencyISO]: value,
                                          };
                                        }
                                      )
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          ) : (
                            <div className="input-with-validator">
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup
                                  className={
                                    'single-addon-left ' +
                                    (!this.state.editable ? 'disabled' : '')
                                  }
                                >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {this.state.dialogData.cancellationPolicy
                                        .onDemand.type === 'percent'
                                        ? '%'
                                        : this.props.auth.selectedFleet
                                            .currencies[0].symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'onDemand',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'onDemand',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'onDemand',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      this.state.dialogData.cancellationPolicy
                                        .onDemand.valueByCurrencies &&
                                      this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0]
                                        ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!this.state.editable}
                                  />
                                </InputGroup>
                              </FormGroup>
                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  this.state.dialogData.cancellationPolicy
                                    .onDemand.valueByCurrencies &&
                                  this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0]
                                    ? this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          )}
                          <div className={'currency-error-message'}>
                            <Validator
                              id="cancellationOnDemandPenalty"
                              callback={this.rateValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                    (d) => {
                                      return !Validation.isNumber(d.value);
                                    }
                                  ).length <= 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.rateSubmitted}
                              />
                              <ValidCase
                                hide={!this.state.rateSubmitted}
                                valid={
                                  this.state.dialogData.cancellationPolicy.onDemand.valueByCurrencies.filter(
                                    (d) => {
                                      return d.value < 0;
                                    }
                                  ).length == 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.greater_than_0'
                                )}
                              />
                            </Validator>
                          </div>
                        </FormGroup>
                        <Row>
                          <Col md={6} sm={12}>
                            <CcCheckbox
                              disabled={!this.state.editable}
                              checked={
                                this.state.dialogData.cancellationPolicy
                                  .onDemand &&
                                this.state.dialogData.cancellationPolicy
                                  .onDemand.enableTax
                                  ? this.state.dialogData.cancellationPolicy
                                      .onDemand.enableTax
                                  : false
                              }
                              onChange={(e) => {
                                this.handleCancelInputChange('onDemand_tax', e);
                              }}
                              text={I18n.t('regularSetting.Tax')}
                            />
                          </Col>
                          {this.props.auth.selectedFleet.techFeeActive ? (
                            <Col md={6} sm={12}>
                              <CcCheckbox
                                disabled={!this.state.editable}
                                checked={
                                  this.state.dialogData.cancellationPolicy
                                    .onDemand
                                    ? this.state.dialogData.cancellationPolicy
                                        .onDemand.enableTechFee
                                    : true
                                }
                                onChange={(e) => {
                                  this.handleCancelInputChange(
                                    'onDemand_techFee',
                                    e
                                  );
                                }}
                                text={I18n.t('regularSetting.TechFee')}
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="regularSetting.Pay_to_driver" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Pay_to_driver_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={
                              this.state.dialogData.cancellationPolicy.onDemand
                                .payToDrv
                            }
                            onChange={(e) => {
                              this.handleInputChange(
                                'onDemand_Pay_to_driver',
                                e
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="commission" key="commission">
                              {I18n.t('regularSetting.Commission')}
                            </option>
                            <option value="fixAmount" key="fixAmount">
                              {I18n.t('regularSetting.Fix_amount')}
                            </option>
                          </FormControl>
                        </FormGroup>
                        {payToDrvOnDemand == 'fixAmount' ? (
                          <FormGroup
                            className={
                              !this.state.rateSubmitted
                                ? null
                                : this.state.rateValid.onDemandPayDrvAmt ===
                                  false
                                ? 'error'
                                : null
                            }
                          >
                            {this.props.auth.selectedFleet.multiCurrencies ? (
                              <div className="input-with-validator">
                                <Tabs
                                  id="currencies-tabs-onDemandPayDrvAmt"
                                  className="currencies-tabs"
                                  defaultActiveKey={
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso
                                  }
                                  animation={false}
                                  onSelect={this.tabSelectHandle}
                                >
                                  {this.props.auth.selectedFleet.currencies.map(
                                    (c) => {
                                      let currentValue = this.state.dialogData
                                        .cancellationPolicy.onDemand
                                        ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return d.currencyISO == c.iso;
                                            }
                                          )[0]
                                        : this;
                                      return (
                                        <Tab
                                          className="currencies-tab-item"
                                          eventKey={c.iso}
                                          title={c.iso}
                                        >
                                          <FormGroup
                                            className={'qup-input-group'}
                                          >
                                            <InputGroup
                                              className={
                                                'single-addon-left' +
                                                (!this.state.editable
                                                  ? ' disabled'
                                                  : '')
                                              }
                                            >
                                              <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                  {c.symbol}
                                                </InputGroup.Text>
                                              </InputGroup.Prepend>
                                              <FormControl
                                                type="text"
                                                className={'form-custom'}
                                                onChange={(e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'onDemandPayDrvAmt',
                                                    c.iso,
                                                    e
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  textboxNumberHelper.onBlurHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'onDemandPayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                onFocus={(e) => {
                                                  textboxNumberHelper.onfocusHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'onDemandPayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                value={
                                                  currentValue
                                                    ? currentValue.value
                                                    : DefaultCurenciesNumberInputField
                                                }
                                                disabled={!this.state.editable}
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Tab>
                                      );
                                    }
                                  )}
                                </Tabs>

                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    !this.state.dialogData.cancellationPolicy
                                      .onDemand ||
                                    !this.state.dialogData.cancellationPolicy
                                      .onDemand.drvGetAmtByCurrencies ||
                                    !this.state.dialogData.cancellationPolicy
                                      .onDemand.drvGetAmtByCurrencies.length
                                      ? null
                                      : this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.map(
                                          (fees) => {
                                            const { currencyISO, value } = fees;
                                            return {
                                              [currencyISO]: value,
                                            };
                                          }
                                        )
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            ) : (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'onDemandPayDrvAmt',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemandPayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'onDemandPayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        this.state.dialogData
                                          ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                              (d) => {
                                                return (
                                                  d.currencyISO ==
                                                  this.props.auth.selectedFleet
                                                    .currencies[0].iso
                                                );
                                              }
                                            ).length > 0
                                            ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                                (d) => {
                                                  return (
                                                    d.currencyISO ==
                                                    this.props.auth
                                                      .selectedFleet
                                                      .currencies[0].iso
                                                  );
                                                }
                                              )[0].value
                                            : DefaultCurenciesNumberInputField
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                            {this.state.dialogData.cancellationPolicy
                              .onDemand ? (
                              <Validator
                                id="onDemandPayDrvAmt"
                                callback={this.rateValidatorCallback}
                              >
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          !Validation.isStringEmpty(d.value) &&
                                          isNaN(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.must_be_number'
                                  )}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return !Validation.isGreaterOrEqual(
                                          d.value,
                                          0
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.greater_or_equa'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.onDemand.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          parseFloat(d.drvGet) >
                                          parseFloat(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'regularSetting.Driver_gets_greater_than_penalty_amount'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                              </Validator>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        ) : null}
                        {cancellationCashBooking && (
                          <>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="regularSetting.Pay_to_driver_Cash" />
                                <QuestionCircleTooltip
                                  text={
                                    <Translate value="regularSetting.Pay_to_driver_tooltip" />
                                  }
                                />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={'form-custom'}
                                value={payDrvCashType}
                                onChange={(e) => {
                                  this.handlePayDrvCashTypeChange(
                                    'onDemand',
                                    e
                                  );
                                }}
                                disabled={!this.state.editable}
                              >
                                <option value="commission" key="commission">
                                  {I18n.t('regularSetting.Commission')}
                                </option>
                                <option value="fixAmount" key="fixAmount">
                                  {I18n.t('regularSetting.Fix_amount')}
                                </option>
                              </FormControl>
                            </FormGroup>
                            {payDrvCashType === 'fixAmount' && (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handlePayDrvCashValueChange(
                                          'onDemand',
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'onDemand',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'onDemand',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={_.get(
                                        dialogData,
                                        `cancellationPolicy.onDemand.payDrvCash.value`,
                                        0
                                      )}
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={_.get(
                                    dialogData,
                                    `cancellationPolicy.onDemand.payDrvCash.value`,
                                    0
                                  )}
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                          </>
                        )}
                        <FormGroup>
                          <CcCheckbox
                            disabled={!this.state.editable}
                            checked={
                              this.state.dialogData.cancellationPolicy.inAdvance
                                .isActive
                            }
                            onChange={(e) => {
                              this.handleCancelInputChange(
                                'inAdvance_isActive',
                                e
                              );
                            }}
                            text={I18n.t('regularSetting.In_advance')}
                            labelClassName="title"
                            className="form-group-title price_adjustment"
                          />
                        </FormGroup>
                        <FormGroup
                          className={
                            this.state.rateSubmitted
                              ? this.state.rateValid
                                  .inAdvance_valueByCurrencies === false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Penalty" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Penalty_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormGroup>
                            <FormControl
                              as="select"
                              className={'form-custom'}
                              value={
                                this.state.dialogData.cancellationPolicy
                                  .inAdvance.type
                              }
                              onChange={(e) => {
                                this.handleInputChange(
                                  'inAdvance_Pay_to_driver_type',
                                  e
                                );
                              }}
                              disabled={!this.state.editable}
                            >
                              <option value="amount" key="amount">
                                {I18n.t('regularSetting.Amount')}
                              </option>
                              <option value="percent" key="percent">
                                {I18n.t('regularSetting.Percent')}
                              </option>
                            </FormControl>
                          </FormGroup>
                          {this.props.auth.selectedFleet.multiCurrencies ? (
                            <div className="input-with-validator">
                              <Tabs
                                id="currencies-tab-Inadvance"
                                className="currencies-tabs"
                                defaultActiveKey={
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                }
                                animation={false}
                                onSelect={this.tabSelectHandle}
                              >
                                {this.props.auth.selectedFleet.currencies.map(
                                  (c) => {
                                    let currentValue = this.state.dialogData
                                      .cancellationPolicy.inAdvance
                                      .valueByCurrencies
                                      ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                          (d) => {
                                            return d.currencyISO == c.iso;
                                          }
                                        )[0]
                                      : null;
                                    return (
                                      <Tab
                                        className="currencies-tab-item"
                                        eventKey={c.iso}
                                        title={c.iso}
                                      >
                                        <FormGroup
                                          className={'qup-input-group'}
                                        >
                                          <InputGroup
                                            className={
                                              'single-addon-left ' +
                                              (!this.state.editable
                                                ? 'disabled'
                                                : '')
                                            }
                                          >
                                              <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                {this.state.dialogData
                                                  .cancellationPolicy.inAdvance
                                                  .type === 'percent'
                                                  ? '%'
                                                  : c.symbol}
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              type="text"
                                              className={'form-custom'}
                                              onChange={(e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'inAdvance',
                                                  c.iso,
                                                  e
                                                );
                                              }}
                                              onBlur={(e) => {
                                                textboxNumberHelper.onBlurHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'inAdvance',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              onFocus={(e) => {
                                                textboxNumberHelper.onfocusHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'inAdvance',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              value={
                                                currentValue
                                                  ? currentValue.value
                                                  : DefaultCurenciesNumberInputField
                                              }
                                              disabled={!this.state.editable}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                      </Tab>
                                    );
                                  }
                                )}
                              </Tabs>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  !this.state.dialogData.cancellationPolicy ||
                                  !this.state.dialogData.cancellationPolicy
                                    .inAdvance ||
                                  !this.state.dialogData.cancellationPolicy
                                    .inAdvance.valueByCurrencies ||
                                  !this.state.dialogData.cancellationPolicy
                                    .inAdvance.valueByCurrencies.length
                                    ? null
                                    : this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.map(
                                        (fees) => {
                                          const { currencyISO, value } = fees;
                                          return {
                                            [currencyISO]: value,
                                          };
                                        }
                                      )
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          ) : (
                            <div className="input-with-validator">
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup
                                  className={
                                    'single-addon-left ' +
                                    (!this.state.editable ? 'disabled' : '')
                                  }
                                >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {this.state.dialogData.cancellationPolicy
                                        .inAdvance.type === 'percent'
                                        ? '%'
                                        : this.props.auth.selectedFleet
                                            .currencies[0].symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'inAdvance',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'inAdvance',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'inAdvance',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      this.state.dialogData.cancellationPolicy
                                        .inAdvance.valueByCurrencies &&
                                      this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0]
                                        ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!this.state.editable}
                                  />
                                </InputGroup>
                              </FormGroup>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  this.state.dialogData.cancellationPolicy
                                    .inAdvance.valueByCurrencies &&
                                  this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0]
                                    ? this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          )}
                          <div className={'currency-error-message'}>
                            <Validator
                              id="inAdvance_valueByCurrencies"
                              callback={this.rateValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                    (d) => {
                                      return !Validation.isNumber(d.value);
                                    }
                                  ).length == 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.rateSubmitted}
                              />
                              <ValidCase
                                valid={
                                  this.state.dialogData.cancellationPolicy.inAdvance.valueByCurrencies.filter(
                                    (d) => {
                                      return d.value < 0;
                                    }
                                  ).length == 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.equal_or_greater_than_0'
                                )}
                                hide={!this.state.rateSubmitted}
                              />
                            </Validator>
                          </div>
                        </FormGroup>
                        <Row>
                          <Col md={6} sm={12}>
                            <CcCheckbox
                              disabled={!this.state.editable}
                              checked={
                                this.state.dialogData.cancellationPolicy
                                  .inAdvance &&
                                this.state.dialogData.cancellationPolicy
                                  .inAdvance.enableTax
                                  ? this.state.dialogData.cancellationPolicy
                                      .inAdvance.enableTax
                                  : false
                              }
                              onChange={(e) => {
                                this.handleCancelInputChange(
                                  'inAdvance_tax',
                                  e
                                );
                              }}
                              text={I18n.t('regularSetting.Tax')}
                            />
                          </Col>
                          {this.props.auth.selectedFleet.techFeeActive ? (
                            <Col md={6} sm={12}>
                              <CcCheckbox
                                disabled={!this.state.editable}
                                checked={
                                  this.state.dialogData.cancellationPolicy
                                    .inAdvance
                                    ? this.state.dialogData.cancellationPolicy
                                        .inAdvance.enableTechFee
                                    : true
                                }
                                onChange={(e) => {
                                  this.handleCancelInputChange(
                                    'inAdvance_techFee',
                                    e
                                  );
                                }}
                                text={I18n.t('regularSetting.TechFee')}
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="regularSetting.Pay_to_driver" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Pay_to_driver_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={
                              this.state.dialogData.cancellationPolicy.inAdvance
                                .payToDrv
                            }
                            onChange={(e) => {
                              this.handleInputChange(
                                'inAdvance_Pay_to_driver',
                                e
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="commission" key="commission">
                              {I18n.t('regularSetting.Commission')}
                            </option>
                            <option value="fixAmount" key="fixAmount">
                              {I18n.t('regularSetting.Fix_amount')}
                            </option>
                          </FormControl>
                        </FormGroup>
                        {payToDrvInAdvance == 'fixAmount' ? (
                          <FormGroup
                            className={
                              !this.state.rateSubmitted
                                ? null
                                : this.state.rateValid.inAdvancePayDrvAmt ===
                                  false
                                ? 'error'
                                : null
                            }
                          >
                            {this.props.auth.selectedFleet.multiCurrencies ? (
                              <div className="input-with-validator">
                                <Tabs
                                  id="currencies-tabs-inAdvancePayDrvAmt"
                                  className="currencies-tabs"
                                  defaultActiveKey={
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso
                                  }
                                  animation={false}
                                  onSelect={this.tabSelectHandle}
                                >
                                  {this.props.auth.selectedFleet.currencies.map(
                                    (c) => {
                                      let currentValue = this.state.dialogData
                                        .cancellationPolicy.inAdvance
                                        ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return d.currencyISO == c.iso;
                                            }
                                          )[0]
                                        : this;
                                      return (
                                        <Tab
                                          className="currencies-tab-item"
                                          eventKey={c.iso}
                                          title={c.iso}
                                        >
                                          <FormGroup
                                            className={'qup-input-group'}
                                          >
                                            <InputGroup
                                              className={
                                                'single-addon-left' +
                                                (!this.state.editable
                                                  ? ' disabled'
                                                  : '')
                                              }
                                            >
                                              <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                  {c.symbol}
                                                </InputGroup.Text>
                                              </InputGroup.Prepend>
                                              <FormControl
                                                type="text"
                                                className={'form-custom'}
                                                onChange={(e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'inAdvancePayDrvAmt',
                                                    c.iso,
                                                    e
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  textboxNumberHelper.onBlurHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'inAdvancePayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                onFocus={(e) => {
                                                  textboxNumberHelper.onfocusHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'inAdvancePayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                value={
                                                  currentValue
                                                    ? currentValue.value
                                                    : DefaultCurenciesNumberInputField
                                                }
                                                disabled={!this.state.editable}
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Tab>
                                      );
                                    }
                                  )}
                                </Tabs>

                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    !this.state.dialogData.cancellationPolicy
                                      .inAdvance ||
                                    !this.state.dialogData.cancellationPolicy
                                      .inAdvance.drvGetAmtByCurrencies ||
                                    !this.state.dialogData.cancellationPolicy
                                      .inAdvance.drvGetAmtByCurrencies.length
                                      ? null
                                      : this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.map(
                                          (fees) => {
                                            const { currencyISO, value } = fees;
                                            return {
                                              [currencyISO]: value,
                                            };
                                          }
                                        )
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            ) : (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'inAdvancePayDrvAmt',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvancePayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'inAdvancePayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        this.state.dialogData
                                          ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                              (d) => {
                                                return (
                                                  d.currencyISO ==
                                                  this.props.auth.selectedFleet
                                                    .currencies[0].iso
                                                );
                                              }
                                            ).length > 0
                                            ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                                (d) => {
                                                  return (
                                                    d.currencyISO ==
                                                    this.props.auth
                                                      .selectedFleet
                                                      .currencies[0].iso
                                                  );
                                                }
                                              )[0].value
                                            : DefaultCurenciesNumberInputField
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                    <InputGroup.Append>
                                      <InputGroup.Text>
                                        {this.state.dialogData
                                          .cancellationPolicy.inAdvance.type ===
                                        'percent'
                                          ? '%'
                                          : this.props.auth.selectedFleet
                                              .currencies[0].symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Append>
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                            {this.state.dialogData.cancellationPolicy
                              .inAdvance ? (
                              <Validator
                                id="inAdvancePayDrvAmt"
                                callback={this.rateValidatorCallback}
                              >
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          !Validation.isStringEmpty(d.value) &&
                                          isNaN(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.must_be_number'
                                  )}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return !Validation.isGreaterOrEqual(
                                          d.value,
                                          0
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.greater_or_equa'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.cancellationPolicy.inAdvance.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          parseFloat(d.drvGet) >
                                          parseFloat(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'regularSetting.Driver_gets_greater_than_penalty_amount'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                              </Validator>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        ) : null}
                        {cancellationCashBooking && (
                          <>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="regularSetting.Pay_to_driver_Cash" />
                                <QuestionCircleTooltip
                                  text={
                                    <Translate value="regularSetting.Pay_to_driver_tooltip" />
                                  }
                                />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={'form-custom'}
                                value={payDrvCashInAdvanceType}
                                onChange={(e) => {
                                  this.handlePayDrvCashTypeChange(
                                    'inAdvance',
                                    e
                                  );
                                }}
                                disabled={!this.state.editable}
                              >
                                <option value="commission" key="commission">
                                  {I18n.t('regularSetting.Commission')}
                                </option>
                                <option value="fixAmount" key="fixAmount">
                                  {I18n.t('regularSetting.Fix_amount')}
                                </option>
                              </FormControl>
                            </FormGroup>
                            {payDrvCashInAdvanceType === 'fixAmount' && (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handlePayDrvCashValueChange(
                                          'inAdvance',
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'inAdvance',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'inAdvance',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={_.get(
                                        dialogData,
                                        `cancellationPolicy.inAdvance.payDrvCash.value`,
                                        0
                                      )}
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={_.get(
                                    dialogData,
                                    `cancellationPolicy.inAdvance.payDrvCash.value`,
                                    0
                                  )}
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                          </>
                        )}
                        <div className={'vs-20'} />
                        <FormGroupTitle>
                          <Translate value="regularSetting.PAX_MDISPATCHER_NO_SHOW" />
                          <QuestionCircleTooltip
                            text={
                              <Translate value="regularSetting.Pax_mDispatcher_no_show_tooltip" />
                            }
                          />
                        </FormGroupTitle>
                        <FormGroup
                          className={
                            this.state.rateSubmitted
                              ? this.state.rateValid
                                  .noShow_valueByCurrencies === false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Penalty" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Penalty_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormGroup>
                            <FormControl
                              as="select"
                              className={'form-custom'}
                              value={this.state.dialogData.noShow.type}
                              onChange={(e) => {
                                this.handleInputChange(
                                  'noShow_Pay_to_driver_type',
                                  e
                                );
                              }}
                              disabled={!this.state.editable}
                            >
                              <option value="amount" key="amount">
                                {I18n.t('regularSetting.Amount')}
                              </option>
                              <option value="percent" key="percent">
                                {I18n.t('regularSetting.Percent')}
                              </option>
                            </FormControl>
                          </FormGroup>
                          {this.props.auth.selectedFleet.multiCurrencies ? (
                            <div className="input-with-validator">
                              <Tabs
                                id="currencies-tabs-Ondemand"
                                className="currencies-tabs"
                                defaultActiveKey={
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                }
                                animation={false}
                                onSelect={this.tabSelectHandle}
                              >
                                {this.props.auth.selectedFleet.currencies.map(
                                  (c) => {
                                    let currentValue = this.state.dialogData
                                      .noShow.valueByCurrencies
                                      ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                          (d) => {
                                            return d.currencyISO == c.iso;
                                          }
                                        )[0]
                                      : null;
                                    return (
                                      <Tab
                                        className="currencies-tab-item"
                                        eventKey={c.iso}
                                        title={c.iso}
                                      >
                                        <FormGroup
                                          className={'qup-input-group'}
                                        >
                                          <InputGroup
                                            className={
                                              'single-addon-left ' +
                                              (!this.state.editable
                                                ? 'disabled'
                                                : '')
                                            }
                                          >
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                {this.state.dialogData.noShow
                                                  .type === 'percent'
                                                  ? '%'
                                                  : c.symbol}
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                              type="text"
                                              className={'form-custom'}
                                              onChange={(e) => {
                                                this.handleCancelInputMultiCurrenciesChange(
                                                  'noShow',
                                                  c.iso,
                                                  e
                                                );
                                              }}
                                              onBlur={(e) => {
                                                textboxNumberHelper.onBlurHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'noShow',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              onFocus={(e) => {
                                                textboxNumberHelper.onfocusHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleCancelInputMultiCurrenciesChange(
                                                      'noShow',
                                                      c.iso,
                                                      e
                                                    );
                                                  }
                                                );
                                              }}
                                              value={
                                                currentValue
                                                  ? currentValue.value
                                                  : DefaultCurenciesNumberInputField
                                              }
                                              disabled={!this.state.editable}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                      </Tab>
                                    );
                                  }
                                )}
                              </Tabs>
                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  !this.state.dialogData.noShow ||
                                  !this.state.dialogData.noShow
                                    .valueByCurrencies ||
                                  !this.state.dialogData.noShow
                                    .valueByCurrencies.length
                                    ? null
                                    : this.state.dialogData.noShow.valueByCurrencies.map(
                                        (fees) => {
                                          const { currencyISO, value } = fees;
                                          return {
                                            [currencyISO]: value,
                                          };
                                        }
                                      )
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          ) : (
                            <div className="input-with-validator">
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup
                                  className={
                                    'single-addon-left ' +
                                    (!this.state.editable ? 'disabled' : '')
                                  }
                                >
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {this.state.dialogData.noShow.type ===
                                      'percent'
                                        ? '%'
                                        : this.props.auth.selectedFleet
                                            .currencies[0].symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleCancelInputMultiCurrenciesChange(
                                        'noShow',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'noShow',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleCancelInputMultiCurrenciesChange(
                                            'noShow',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      this.state.dialogData.noShow
                                        .valueByCurrencies &&
                                      this.state.dialogData.noShow.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0]
                                        ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!this.state.editable}
                                  />
                                </InputGroup>
                              </FormGroup>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!this.state.editable}
                                value={
                                  this.state.dialogData.noShow
                                    .valueByCurrencies &&
                                  this.state.dialogData.noShow.valueByCurrencies.filter(
                                    (d) => {
                                      return (
                                        d.currencyISO ==
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso
                                      );
                                    }
                                  )[0]
                                    ? this.state.dialogData.noShow.valueByCurrencies.filter(
                                        (d) => {
                                          return (
                                            d.currencyISO ==
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso
                                          );
                                        }
                                      )[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          )}

                          <div className={'currency-error-message'}>
                            <Validator
                              id="noShow_valueByCurrencies"
                              callback={this.rateValidatorCallback}
                              disabled={!this.state.editable}
                            >
                              <ValidCase
                                valid={
                                  this.state.dialogData.noShow.valueByCurrencies.filter(
                                    (d) => {
                                      return !Validation.isNumber(d.value);
                                    }
                                  ).length == 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.must_be_number'
                                )}
                                hide={!this.state.rateSubmitted}
                              />
                              <ValidCase
                                valid={
                                  this.state.dialogData.noShow.valueByCurrencies.filter(
                                    (d) => {
                                      return d.value < 0;
                                    }
                                  ).length == 0
                                }
                                message={I18n.t(
                                  'messages.commonMessages.equal_or_greater_than_0'
                                )}
                                hide={!this.state.rateSubmitted}
                              />
                            </Validator>
                          </div>
                        </FormGroup>
                        <Row>
                          <Col md={6} sm={12}>
                            <CcCheckbox
                              disabled={!this.state.editable}
                              checked={
                                this.state.dialogData.noShow &&
                                this.state.dialogData.noShow.enableTax
                                  ? this.state.dialogData.noShow.enableTax
                                  : false
                              }
                              onChange={(e) => {
                                this.handleCancelInputChange('noShow_tax', e);
                              }}
                              text={I18n.t('regularSetting.Tax')}
                            />
                          </Col>
                          {this.props.auth.selectedFleet.techFeeActive ? (
                            <Col md={6} sm={12}>
                              <CcCheckbox
                                disabled={!this.state.editable}
                                checked={
                                  this.state.dialogData.noShow
                                    ? this.state.dialogData.noShow.enableTechFee
                                    : true
                                }
                                onChange={(e) => {
                                  this.handleCancelInputChange(
                                    'noShow_techFee',
                                    e
                                  );
                                }}
                                text={I18n.t('regularSetting.TechFee')}
                              />
                            </Col>
                          ) : null}
                        </Row>
                        <FormGroup>
                          <Form.Label>
                            <Translate value="regularSetting.Pay_to_driver" />
                            <QuestionCircleTooltip
                              text={
                                <Translate value="regularSetting.Pay_to_driver_tooltip" />
                              }
                            />
                          </Form.Label>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={this.state.dialogData.noShow.payToDrv}
                            onChange={(e) => {
                              this.handleInputChange('noShow_Pay_to_driver', e);
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="commission" key="commission">
                              {I18n.t('regularSetting.Commission')}
                            </option>
                            <option value="fixAmount" key="fixAmount">
                              {I18n.t('regularSetting.Fix_amount')}
                            </option>
                          </FormControl>
                        </FormGroup>
                        {payToDrvNoShow == 'fixAmount' ? (
                          <FormGroup
                            className={
                              !this.state.rateSubmitted
                                ? null
                                : this.state.rateValid.noShowPayDrvAmt === false
                                ? 'error'
                                : null
                            }
                          >
                            {this.props.auth.selectedFleet.multiCurrencies ? (
                              <div className="input-with-validator">
                                <Tabs
                                  id="currencies-tabs-noShowPayDrvAmt"
                                  className="currencies-tabs"
                                  defaultActiveKey={
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso
                                  }
                                  animation={false}
                                  onSelect={this.tabSelectHandle}
                                >
                                  {this.props.auth.selectedFleet.currencies.map(
                                    (c) => {
                                      let currentValue = this.state.dialogData
                                        .noShow
                                        ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return d.currencyISO == c.iso;
                                            }
                                          )[0]
                                        : this;
                                      return (
                                        <Tab
                                        className="currencies-tab-item"
                                          eventKey={c.iso}
                                          title={c.iso}
                                        >
                                          <FormGroup
                                            className={'qup-input-group'}
                                          >
                                            <InputGroup
                                              className={
                                                'single-addon-left' +
                                                (!this.state.editable
                                                  ? ' disabled'
                                                  : '')
                                              }
                                            >
                                              <InputGroup.Prepend>
                                                <InputGroup.Text>
                                                  {c.symbol}
                                                </InputGroup.Text>
                                              </InputGroup.Prepend>
                                              <FormControl
                                                type="text"
                                                className={'form-custom'}
                                                onChange={(e) => {
                                                  this.handleCancelInputMultiCurrenciesChange(
                                                    'noShowPayDrvAmt',
                                                    c.iso,
                                                    e
                                                  );
                                                }}
                                                onBlur={(e) => {
                                                  textboxNumberHelper.onBlurHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'noShowPayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                onFocus={(e) => {
                                                  textboxNumberHelper.onfocusHandle(
                                                    e,
                                                    (e) => {
                                                      this.handleCancelInputMultiCurrenciesChange(
                                                        'noShowPayDrvAmt',
                                                        c.iso,
                                                        e
                                                      );
                                                    }
                                                  );
                                                }}
                                                value={
                                                  currentValue
                                                    ? currentValue.value
                                                    : DefaultCurenciesNumberInputField
                                                }
                                                disabled={!this.state.editable}
                                              />
                                            </InputGroup>
                                          </FormGroup>
                                        </Tab>
                                      );
                                    }
                                  )}
                                </Tabs>

                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    !this.state.dialogData.noShow ||
                                    !this.state.dialogData.noShow
                                      .drvGetAmtByCurrencies ||
                                    !this.state.dialogData.noShow
                                      .drvGetAmtByCurrencies.length
                                      ? null
                                      : this.state.dialogData.noShow.drvGetAmtByCurrencies.map(
                                          (fees) => {
                                            const { currencyISO, value } = fees;
                                            return {
                                              [currencyISO]: value,
                                            };
                                          }
                                        )
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            ) : (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                    >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handleCancelInputMultiCurrenciesChange(
                                          'noShowPayDrvAmt',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShowPayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handleCancelInputMultiCurrenciesChange(
                                              'noShowPayDrvAmt',
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso,
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={
                                        this.state.dialogData
                                          ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                              (d) => {
                                                return (
                                                  d.currencyISO ==
                                                  this.props.auth.selectedFleet
                                                    .currencies[0].iso
                                                );
                                              }
                                            ).length > 0
                                            ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                                (d) => {
                                                  return (
                                                    d.currencyISO ==
                                                    this.props.auth
                                                      .selectedFleet
                                                      .currencies[0].iso
                                                  );
                                                }
                                              )[0].value
                                            : DefaultCurenciesNumberInputField
                                          : DefaultCurenciesNumberInputField
                                      }
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={
                                    this.state.dialogData
                                      ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                          (d) => {
                                            return (
                                              d.currencyISO ==
                                              this.props.auth.selectedFleet
                                                .currencies[0].iso
                                            );
                                          }
                                        ).length > 0
                                        ? this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                            (d) => {
                                              return (
                                                d.currencyISO ==
                                                this.props.auth.selectedFleet
                                                  .currencies[0].iso
                                              );
                                            }
                                          )[0].value
                                        : DefaultCurenciesNumberInputField
                                      : DefaultCurenciesNumberInputField
                                  }
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                            {this.state.dialogData.noShow ? (
                              <Validator
                                id="noShowPayDrvAmt"
                                callback={this.rateValidatorCallback}
                              >
                                <ValidCase
                                  valid={
                                    this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          !Validation.isStringEmpty(d.value) &&
                                          isNaN(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.must_be_number'
                                  )}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return !Validation.isGreaterOrEqual(
                                          d.value,
                                          0
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'messages.commonMessages.greater_or_equa'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                                <ValidCase
                                  valid={
                                    this.state.dialogData.noShow.drvGetAmtByCurrencies.filter(
                                      (d) => {
                                        return (
                                          parseFloat(d.drvGet) >
                                          parseFloat(d.value)
                                        );
                                      }
                                    ).length == 0
                                  }
                                  message={I18n.t(
                                    'regularSetting.Driver_gets_greater_than_penalty_amount'
                                  ).format(0)}
                                  hide={!this.state.rateSubmitted}
                                />
                              </Validator>
                            ) : (
                              ''
                            )}
                          </FormGroup>
                        ) : null}
                        {cancellationCashBooking && (
                          <>
                            <FormGroup>
                              <Form.Label>
                                <Translate value="regularSetting.Pay_to_driver_Cash" />
                                <QuestionCircleTooltip
                                  text={
                                    <Translate value="regularSetting.Pay_to_driver_tooltip" />
                                  }
                                />
                              </Form.Label>
                              <FormControl
                                as="select"
                                className={'form-custom'}
                                value={payDrvCashNoShowType}
                                onChange={(e) => {
                                  this.handlePayDrvCashTypeChange('noShow', e);
                                }}
                                disabled={!this.state.editable}
                              >
                                <option value="commission" key="commission">
                                  {I18n.t('regularSetting.Commission')}
                                </option>
                                <option value="fixAmount" key="fixAmount">
                                  {I18n.t('regularSetting.Fix_amount')}
                                </option>
                              </FormControl>
                            </FormGroup>
                            {payDrvCashNoShowType === 'fixAmount' && (
                              <div className="input-with-validator">
                                <FormGroup className={'qup-input-group'}>
                                  <InputGroup
                                    className={
                                      'single-addon-left' +
                                      (!this.state.editable ? ' disabled' : '')
                                    }
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {
                                          this.props.auth.selectedFleet
                                            .currencies[0].symbol
                                        }
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                      type="text"
                                      className={'form-custom'}
                                      onChange={(e) => {
                                        this.handlePayDrvCashValueChange(
                                          'noShow',
                                          e
                                        );
                                      }}
                                      onBlur={(e) => {
                                        textboxNumberHelper.onBlurHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'noShow',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      onFocus={(e) => {
                                        textboxNumberHelper.onfocusHandle(
                                          e,
                                          (e) => {
                                            this.handlePayDrvCashValueChange(
                                              'noShow',
                                              e
                                            );
                                          }
                                        );
                                      }}
                                      value={_.get(
                                        dialogData,
                                        `noShow.payDrvCash.value`,
                                        0
                                      )}
                                      disabled={!this.state.editable}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <WarningFeeInput
                                  message={I18n.t(
                                    'message.warningInputChangeOver'
                                  )}
                                  className="text-soft-warning"
                                  disabled={!this.state.editable}
                                  value={_.get(
                                    dialogData,
                                    `noShow.payDrvCash.value`,
                                    0
                                  )}
                                  timeDelay={500}
                                  typeOfCheck="higherOfLower"
                                />
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  {enablePenalty && (
                    <>
                      <div className={'vs-20'} />
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.onDemand.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'onDemand',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_demand')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.onDemand.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'onDemand',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                {_.get(
                                  dialogData,
                                  'driverCancelPolicy.onDemand.type',
                                  'amount'
                                ) === 'percent'
                                  ? '%'
                                  : this.props.auth.selectedFleet.currencies[0]
                                      .symbol}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'onDemand',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'onDemand',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.onDemand.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                      <div className={'vs-20'} />
                      <CcCheckbox
                        disabled={!this.state.editable}
                        checked={_.get(
                          dialogData,
                          'driverCancelPolicy.inAdvance.isActive',
                          false
                        )}
                        onChange={(e) => {
                          this.handleDriverCancelChange(
                            e,
                            'inAdvance',
                            'isActive'
                          );
                        }}
                        text={I18n.t('regularSetting.Driver_cancel_advance')}
                        labelClassName="title"
                        className="form-group-title price_adjustment"
                      />
                      <FormGroup>
                        <Form.Label>
                          <Translate value="regularSetting.Penalty" />
                        </Form.Label>
                        <FormGroup>
                          <FormControl
                            as="select"
                            className={'form-custom'}
                            value={_.get(
                              dialogData,
                              'driverCancelPolicy.inAdvance.type',
                              'amount'
                            )}
                            onChange={(e) => {
                              this.handleDriverCancelChange(
                                e,
                                'inAdvance',
                                'type'
                              );
                            }}
                            disabled={!this.state.editable}
                          >
                            <option value="amount" key="amount">
                              {I18n.t('regularSetting.Amount')}
                            </option>
                            <option value="percent" key="percent">
                              {I18n.t('regularSetting.Percent')}
                            </option>
                          </FormControl>
                        </FormGroup>
                      </FormGroup>
                      <div className="input-with-validator">
                        <FormGroup className={'qup-input-group'}>
                          <InputGroup
                            className={
                              'single-addon-left' +
                              (!this.state.editable ? ' disabled' : '')
                            }
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {_.get(
                                    dialogData,
                                    'driverCancelPolicy.inAdvance.type',
                                    'amount'
                                  ) === 'percent'
                                    ? '%'
                                    : this.props.auth.selectedFleet.currencies[0]
                                        .symbol}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            <FormControl
                              type="text"
                              className={'form-custom'}
                              onChange={(e) => {
                                this.handleDriverCancelChange(
                                  e,
                                  'inAdvance',
                                  'value'
                                );
                              }}
                              onBlur={(e) => {
                                textboxNumberHelper.onBlurHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              onFocus={(e) => {
                                textboxNumberHelper.onfocusHandle(e, (e) => {
                                  this.handleDriverCancelChange(
                                    e,
                                    'inAdvance',
                                    'value'
                                  );
                                });
                              }}
                              value={_.get(
                                dialogData,
                                'driverCancelPolicy.inAdvance.value',
                                0
                              )}
                              disabled={!this.state.editable}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </>
                  )}
                  {this.state.dialogData._id ? null : (
                    <FormGroup>
                      <Form.Label>
                        <Translate value="regularSetting.Assign_car_type" />
                      </Form.Label>
                      <select
                        className="form-control form-custom"
                        onChange={(e) => {
                          this.handleInputChange('carType', e);
                        }}
                      >
                        <option value="">
                          {I18n.t('regularSetting.Select_car_type')}
                        </option>
                        {carTypes &&
                          carTypes.map((item) => {
                            return (
                              <option value={item._id} key={item._id}>
                                {item.vehicleType}
                              </option>
                            );
                          })}
                      </select>
                    </FormGroup>
                  )}
                </Col>

                {this.state.dialogData._id ? (
                  <Col md={8} xs={8}>
                    <FormGroupTitle>
                      <Translate value="regularSetting.ROUTES" />
                    </FormGroupTitle>
                    <ButtonToolbar
                      className="text-center header-button-group"
                      ref={(node) => (this.toobarModalContainer = node)}
                    >
                      <ButtonGroup className="group-left">
                        <FormGroup className="search-format mb0">
                          <input
                            type="text"
                            className="form-control search-form"
                            value={this.state.flatRoutersStr}
                            onKeyPress={this.handleSearchInModalKeyPress}
                            onChange={this.handleSearchInModalChange}
                            placeholder={I18n.t('General.search')}
                          />
                          <BsSearch className="search-icon" />
                        </FormGroup>
                        {!this.props.permissions ||
                        this.props.permissions.actions ? (
                          <Button
                            className="btn-header text-add-header ml0"
                            onClick={this.handleAddRouteButtonClick}
                          >
                            {' '}
                            <Translate value="regularSetting.Add" />
                          </Button>
                        ) : (
                          ''
                        )}
                      </ButtonGroup>
                    </ButtonToolbar>
                    <div className="gridViewTable" id="flat-table-id">
                      <StickyTable
                        columns={this.getRouterTableColumns()}
                        bodyData={bodyRouterTableData}
                        footerData={this.state.footerRoutesListOptions}
                        handleNumItemsPerPageChange={
                          this.handleNumItemsPerPageChange
                        }
                        handlePaginationSelect={this.handlePaginationSelect}
                        rowHeight={this.state.routeTableHeight}
                        getTableHeight={() => 500}
                      />
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Row className="ml0">
                {this.state.dialogData._id ? (
                  <Form.Label className="mr-r-20">
                    (<span className="require">*</span>)
                    <Translate value="regularSetting.Please_go_to_Car_Type_settings" />
                  </Form.Label>
                ) : null}
              </Row>
              <Row className="ml0">
                <Form.Label>
                  (<span className="require">*</span>
                  ): <Translate value="regularSetting.Required_fields" />
                </Form.Label>
              </Row>
            </Modal.Body>
            <Modal.Footer className={'pd-r-l-60'}>
              {hasPermission ? (
                this.state.editable ? (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={this.saveDialogForm}
                    disabled={!this.state.editable}
                  >
                    <Translate value="regularSetting.Save" />
                  </Button>
                ) : (
                  <Button
                    className={'btn-save mr-md'}
                    onClick={(e) => {
                      this.handleMenuClick('changeStatus', e);
                    }}
                  >
                    <Translate value="regularSetting.Edit" />
                  </Button>
                )
              ) : (
                ''
              )}
              <Button className={'btn-cancel'} onClick={this.closeDialogForm}>
                <Translate value="regularSetting.Cancel" />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          ''
        )}
        {this.state.isEditRouteModel && this.state.routeModel && (
          <Modal onHide={this.closeRouteModelForm} show={true} backdrop={true} bsSize="lg">
            <form onSubmit={this.saveRouteModelClick}>
              <Modal.Header>
                <Modal.Title>
                  {this.state.routeModel._id ? (
                    <Translate value="regularSetting.EDIT_ROUTE" />
                  ) : (
                    <Translate value="regularSetting.ADD_ROUTE" />
                  )}
                </Modal.Title>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.closeRouteModelForm}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body className={'pd-r-l-60'}>
                <FormGroup
                  className={
                    this.state.routeSubmitted
                      ? this.state.routeValid.routeName === false
                        ? 'error'
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Route_name" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    type="text"
                    className={'form-custom'}
                    onChange={(e) => {
                      this.handleRouteInputChange('routeName', null, e);
                    }}
                    value={
                      this.state.routeModel
                        ? this.state.routeModel.routeName
                        : ''
                    }
                    placeholder={I18n.t('regularSetting.Route_name')}
                    disabled={!hasPermission}
                  />
                  <Validator
                    id="routeName"
                    callback={this.routesValidatorCallback}
                  >
                    <ValidCase
                      valid={
                        !Validation.isStringEmpty(
                          this.state.routeModel.routeName
                        )
                      }
                      message={I18n.t('messages.commonMessages.Required_field')}
                      hide={!this.state.routeSubmitted}
                    />
                  </Validator>
                </FormGroup>
                <FormGroup>
                  <Form.Label>
                    <Translate value="regularSetting.Route_type" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  <FormControl
                    as="select"
                    className={'form-custom'}
                    value={
                      this.state.routeModel
                        ? this.state.routeModel.routeType
                        : ''
                    }
                    onChange={(e) => {
                      this.handleRouteInputChange('routeType', null, e);
                    }}
                    disabled={!hasPermission}
                  >
                    <option value={RouteType.Zone}>
                      {I18n.t('regularSetting.Zone_to_zone')}
                    </option>
                    {dialogData && dialogData.serviceType !== 'delivery' && (
                      <option value={RouteType.ZipCode}>
                        {I18n.t('regularSetting.Zipcode_to_zipcode')}
                      </option>
                    )}
                  </FormControl>
                </FormGroup>
                {this.state.routeModel.routeType == RouteType.Zone ? (
                  <Row>
                    <Col xs={6} md={6}>
                      <FormGroup
                        className={
                          this.state.routeSubmitted
                            ? this.state.routeValid.fromZone === false
                              ? 'error'
                              : null
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.First_location" />{' '}
                          <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={
                            this.state.routeModel.zone.fromZone
                              ? this.state.routeModel.zone.fromZone.zoneId
                              : ''
                          }
                          onChange={(e) => {
                            this.handleRouteZoneChange('fromZone', e);
                          }}
                          disabled={!hasPermission}
                        >
                          <option value="">
                            {I18n.t('regularSetting.Select_a_zone')}
                          </option>
                          {this.state.zoneList
                            ? this.state.zoneList.map((item) => {
                                return (
                                  <option value={item._id} key={item._id}>
                                    {item.zoneName}
                                  </option>
                                );
                              })
                            : ''}
                        </FormControl>
                        <Validator
                          id="fromZone"
                          callback={this.routesValidatorCallback}
                        >
                          <ValidCase
                            valid={this.state.routeModel.zone.fromZone != null}
                            message={I18n.t(
                              'messages.commonMessages.Required_field'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                        </Validator>
                      </FormGroup>
                      <div className="route-map">
                        {this.props.commonData.location &&
                        this.props.commonData.location.isChina ? (
                          <QQMap
                            className="fill"
                            options={{
                              center: this.state.routeModel.zone.fromZone
                                ? this.getCenterPoint(
                                    this.state.routeModel.zone.fromZone
                                      .coordinates[0],
                                    true
                                  )
                                : null,
                            }}
                            onMapLoad={this.handleMapLoad}
                          >
                            {this.state.routeModel.zone.fromZone && (
                              <QQPolygon
                                path={this.convertCoordinatesToPath(
                                  this.state.routeModel.zone.fromZone
                                    .coordinates[0]
                                )}
                                options={{
                                  strokeColor: '#FFFF00',
                                  strokeOpacity: 1,
                                  strokeWeight: 2,
                                  fillColor: new window.qq.maps.Color.fromHex(
                                    '#FF0000',
                                    0.4
                                  ),
                                }}
                                mapkey="1"
                                fitBounds={true}
                              />
                            )}
                          </QQMap>
                        ) : (
                          <GettingStartedGoogleMap
                            containerElement='mapqup fill'
                            mapElement={<div style={{ height: `100%` }} />}
                            center={
                              this.state.routeModel.zone.fromZone
                                ? this.getCenterPoint(
                                    this.state.routeModel.zone.fromZone
                                      .coordinates[0],
                                    true
                                  )
                                : null
                            }
                            polygon={
                              this.state.routeModel.zone.fromZone
                                ? this.convertCoordinatesToPath(
                                    this.state.routeModel.zone.fromZone
                                      .coordinates[0]
                                  )
                                : null
                            }
                            map="fromZone"
                            onMapLoad={this.handleMapLoad}
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs={6} md={6}>
                      <FormGroup
                        className={
                          this.state.routeSubmitted
                            ? this.state.routeValid.toZone === false
                              ? 'error'
                              : null
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Second_location" />{' '}
                          <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                          as="select"
                          className={'form-custom'}
                          value={
                            this.state.routeModel.zone.toZone
                              ? this.state.routeModel.zone.toZone.zoneId
                              : ''
                          }
                          onChange={(e) => {
                            this.handleRouteZoneChange('toZone', e);
                          }}
                          disabled={!hasPermission}
                        >
                          <option value="">
                            {I18n.t('regularSetting.Select_a_zone')}
                          </option>
                          {this.state.zoneList
                            ? this.state.zoneList.map((item) => {
                                return (
                                  <option value={item._id} key={item._id}>
                                    {item.zoneName}
                                  </option>
                                );
                              })
                            : ''}
                        </FormControl>
                        <Validator
                          id="toZone"
                          callback={this.routesValidatorCallback}
                        >
                          <ValidCase
                            valid={this.state.routeModel.zone.toZone != null}
                            message={I18n.t(
                              'messages.commonMessages.Required_field'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                        </Validator>
                      </FormGroup>
                      <div className="route-map">
                        {this.props.commonData.location &&
                        this.props.commonData.location.isChina ? (
                          <QQMap
                            className="fill"
                            options={{
                              center: this.state.routeModel.zone.toZone
                                ? this.getCenterPoint(
                                    this.state.routeModel.zone.toZone
                                      .coordinates[0]
                                  )
                                : null,
                            }}
                            onMapLoad={this.handleMapLoad}
                            map="toZone"
                          >
                            {this.state.routeModel.zone.toZone && (
                              <QQPolygon
                                path={this.convertCoordinatesToPath(
                                  this.state.routeModel.zone.toZone
                                    .coordinates[0]
                                )}
                                options={{
                                  strokeColor: '#FFFF00',
                                  strokeOpacity: 1,
                                  strokeWeight: 2,
                                  fillColor: new window.qq.maps.Color.fromHex(
                                    '#FF0000',
                                    0.4
                                  ),
                                }}
                                mapkey="2"
                              />
                            )}
                          </QQMap>
                        ) : (
                          <GettingStartedGoogleMap
                            containerElement="mapqup fill"
                            center={
                              this.state.routeModel.zone.toZone
                                ? this.getCenterPoint(
                                    this.state.routeModel.zone.toZone
                                      .coordinates[0]
                                  )
                                : null
                            }
                            polygon={
                              this.state.routeModel.zone.toZone
                                ? this.convertCoordinatesToPath(
                                    this.state.routeModel.zone.toZone
                                      .coordinates[0]
                                  )
                                : null
                            }
                            map="toZone"
                            onMapLoad={this.handleMapLoad}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={6} md={6}>
                      <FormGroup
                        className={
                          this.state.routeSubmitted
                            ? this.state.routeValid.strFrom === false
                              ? 'error'
                              : null
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.First_location" />{' '}
                          <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                          type="text"
                          className={'form-custom'}
                          onChange={(e) => {
                            this.handleRouteInputChange('strFrom', null, e);
                          }}
                          value={
                            this.state.routeModel
                              ? this.state.routeModel.strFrom
                              : ''
                          }
                          disabled={!hasPermission}
                        />
                        <Validator
                          id="strFrom"
                          callback={this.routesValidatorCallback}
                        >
                          <ValidCase
                            valid={this.checkValidFromZipCode()}
                            message={I18n.t(
                              'messages.commonMessages.Required_field'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                        </Validator>
                      </FormGroup>
                      <FormGroup>
                        <Translate value="regularSetting.First_location_zip_note" />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={6}>
                      <FormGroup
                        className={
                          this.state.routeSubmitted
                            ? this.state.routeValid.strTo === false
                              ? 'error'
                              : null
                            : null
                        }
                      >
                        <Form.Label>
                          <Translate value="regularSetting.Second_location" />{' '}
                          <span className="require">*</span>
                        </Form.Label>
                        <FormControl
                          type="text"
                          className={'form-custom'}
                          onChange={(e) => {
                            this.handleRouteInputChange('strTo', null, e);
                          }}
                          value={
                            this.state.routeModel
                              ? this.state.routeModel.strTo
                              : ''
                          }
                        />
                        <Validator
                          id="strTo"
                          callback={this.routesValidatorCallback}
                        >
                          <ValidCase
                            valid={this.checkValidToZipCode()}
                            message={I18n.t(
                              'messages.commonMessages.Required_field'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                        </Validator>
                      </FormGroup>
                      <FormGroup>
                        <Translate value="regularSetting.Second_location_zip_note" />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <div className={'vs-20'} />
                <FormGroupTitle>
                  <Translate value="regularSetting.SINGLE_TRIP_SETUP" />
                </FormGroupTitle>
                <FormGroup
                  className={
                    this.state.routeSubmitted
                      ? this.state.routeValid
                          .singleTrip_departureRouteByCurrencies === false
                        ? 'error'
                        : null
                      : null
                  }
                >
                  <Form.Label>
                    <Translate value="regularSetting.Route_1" />{' '}
                    <span className="require">*</span>
                  </Form.Label>
                  {this.props.auth.selectedFleet.multiCurrencies ? (
                    <div className="input-with-validator">
                      <Tabs
                        id="currencies-tabs-departureRouteByCurrencies"
                        className="currencies-tabs"
                        defaultActiveKey={
                          this.props.auth.selectedFleet.currencies[0].iso
                        }
                        animation={false}
                        onSelect={this.tabSelectHandle}
                      >
                        {this.props.auth.selectedFleet.currencies.map((c) => {
                          let currentValue = this.state.routeModel.singleTrip
                            .departureRouteByCurrencies
                            ? this.state.routeModel.singleTrip.departureRouteByCurrencies.filter(
                                (d) => {
                                  return d.currencyISO == c.iso;
                                }
                              )[0]
                            : null;
                          return (
                            <Tab
                              className="currencies-tab-item"
                              eventKey={c.iso}
                              title={c.iso}
                            >
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup className={'single-addon-left'}>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {c.symbol}
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleRouteMultiCurrenciesChange(
                                        'singleTrip',
                                        'departureRouteByCurrencies',
                                        c.iso,
                                        e
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'singleTrip',
                                            'departureRouteByCurrencies',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'singleTrip',
                                            'departureRouteByCurrencies',
                                            c.iso,
                                            e
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      currentValue
                                        ? currentValue.value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!hasPermission}
                                  />
                                </InputGroup>
                              </FormGroup>
                            </Tab>
                          );
                        })}
                      </Tabs>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        disabled={!hasPermission}
                        value={
                          !this.state.routeModel ||
                          !this.state.routeModel.singleTrip ||
                          !this.state.routeModel.singleTrip
                            .departureRouteByCurrencies ||
                          !this.state.routeModel.singleTrip
                            .departureRouteByCurrencies.length
                            ? null
                            : this.state.routeModel.singleTrip.departureRouteByCurrencies.map(
                                (fees) => {
                                  const { currencyISO, value } = fees;
                                  return {
                                    [currencyISO]: value,
                                  };
                                }
                              )
                        }
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  ) : (
                    <div className="input-with-validator">
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup className={'single-addon-left'}>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              {
                                this.props.auth.selectedFleet.currencies[0]
                                  .symbol
                              }
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            onChange={(e) => {
                              this.handleRouteMultiCurrenciesChange(
                                'singleTrip',
                                'departureRouteByCurrencies',
                                this.props.auth.selectedFleet.currencies[0].iso,
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.handleRouteMultiCurrenciesChange(
                                  'singleTrip',
                                  'departureRouteByCurrencies',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.handleRouteMultiCurrenciesChange(
                                  'singleTrip',
                                  'departureRouteByCurrencies',
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso,
                                  e
                                );
                              });
                            }}
                            value={this.getValueByCurrency(
                              this.props.auth.selectedFleet.currencies[0].iso,
                              this.state.routeModel.singleTrip
                                .departureRouteByCurrencies
                            )}
                            disabled={!hasPermission}
                          />
                        </InputGroup>
                      </FormGroup>

                      <WarningFeeInput
                        message={I18n.t('message.warningInputChangeOver')}
                        className="text-soft-warning"
                        value={this.getValueByCurrency(
                          this.props.auth.selectedFleet.currencies[0].iso,
                          this.state.routeModel.singleTrip
                            .departureRouteByCurrencies
                        )}
                        disabled={!hasPermission}
                        timeDelay={500}
                        typeOfCheck="higherOfLower"
                      />
                    </div>
                  )}
                  {this.state.routeModel.singleTrip
                    .departureRouteByCurrencies ? (
                    <div className={'currency-error-message'}>
                      <Validator
                        id="singleTrip_departureRouteByCurrencies"
                        callback={this.routesValidatorCallback}
                      >
                        <ValidCase
                          valid={
                            this.state.routeModel.singleTrip.departureRouteByCurrencies.filter(
                              (d) => {
                                return Validation.isStringEmpty(d.value);
                              }
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={
                            this.state.routeModel.singleTrip.departureRouteByCurrencies.filter(
                              (d) => {
                                return d.value !== '' && isNaN(d.value);
                              }
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={
                            this.state.routeModel.singleTrip.departureRouteByCurrencies.filter(
                              (d) => {
                                return d.value < 0;
                              }
                            ).length == 0
                          }
                          message={I18n.t(
                            'messages.commonMessages.equal_or_greater_than_0'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                      </Validator>
                    </div>
                  ) : (
                    ''
                  )}
                </FormGroup>
                {(this.state.routeModel.routeType != RouteType.Zone ||
                  !this.state.routeModel.zone.fromZone ||
                  !this.state.routeModel.zone.toZone ||
                  this.state.routeModel.zone.fromZone.zoneId !=
                    this.state.routeModel.zone.toZone.zoneId) && (
                  <FormGroup
                    className={
                      this.state.routeSubmitted
                        ? this.state.routeValid
                            .singleTrip_returnRouteByCurrencies === false
                          ? 'error'
                          : null
                        : null
                    }
                  >
                    <CcCheckbox
                      checked={
                        this.state.routeModel.singleTrip.enableReturnRoute
                      }
                      onChange={(e) => {
                        this.checkboxChangeHandle(
                          'singleTrip',
                          'enableReturnRoute',
                          e
                        );
                      }}
                      text={I18n.t('regularSetting.Route_2')}
                      disabled={!hasPermission}
                    />
                    {this.state.routeModel.singleTrip.enableReturnRoute ? (
                      <div style={{ width: '100%' }}>
                        {this.props.auth.selectedFleet.multiCurrencies ? (
                          <div className="input-with-validator">
                            <Tabs
                              id="currencies-tabs-returnRouteByCurrencies"
                              className="currencies-tabs"
                              defaultActiveKey={
                                this.props.auth.selectedFleet.currencies[0].iso
                              }
                              animation={false}
                              onSelect={this.tabSelectHandle}
                            >
                              {this.props.auth.selectedFleet.currencies.map(
                                (c) => {
                                  let currentValue = this.state.routeModel
                                    .singleTrip.returnRouteByCurrencies
                                    ? this.state.routeModel.singleTrip.returnRouteByCurrencies.filter(
                                        (d) => {
                                          return d.currencyISO == c.iso;
                                        }
                                      )[0]
                                    : null;
                                  return (
                                    <Tab
                                      className="currencies-tab-item"
                                      eventKey={c.iso}
                                      title={c.iso}
                                    >
                                      <FormGroup className={'qup-input-group'}>
                                        <InputGroup
                                          className={'single-addon-left'}
                                        >
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>
                                              {c.symbol}
                                            </InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <FormControl
                                            type="text"
                                            className={'form-custom'}
                                            onChange={(e) => {
                                              this.handleRouteMultiCurrenciesChange(
                                                'singleTrip',
                                                'returnRouteByCurrencies',
                                                c.iso,
                                                e
                                              );
                                            }}
                                            onBlur={(e) => {
                                              textboxNumberHelper.onBlurHandle(
                                                e,
                                                (e) => {
                                                  this.handleRouteMultiCurrenciesChange(
                                                    'singleTrip',
                                                    'returnRouteByCurrencies',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            onFocus={(e) => {
                                              textboxNumberHelper.onfocusHandle(
                                                e,
                                                (e) => {
                                                  this.handleRouteMultiCurrenciesChange(
                                                    'singleTrip',
                                                    'returnRouteByCurrencies',
                                                    c.iso,
                                                    e
                                                  );
                                                }
                                              );
                                            }}
                                            value={
                                              currentValue
                                                ? currentValue.value
                                                : DefaultCurenciesNumberInputField
                                            }
                                            disabled={!hasPermission}
                                          />
                                        </InputGroup>
                                      </FormGroup>
                                    </Tab>
                                  );
                                }
                              )}
                            </Tabs>

                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!hasPermission}
                              value={
                                !this.state.routeModel ||
                                !this.state.routeModel.singleTrip ||
                                !this.state.routeModel.singleTrip
                                  .returnRouteByCurrencies ||
                                !this.state.routeModel.singleTrip
                                  .returnRouteByCurrencies.length
                                  ? null
                                  : this.state.routeModel.singleTrip.returnRouteByCurrencies.map(
                                      (fees) => {
                                        const { currencyISO, value } = fees;
                                        return {
                                          [currencyISO]: value,
                                        };
                                      }
                                    )
                              }
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        ) : (
                          <div className="input-with-validator">
                            <FormGroup className={'qup-input-group'}>
                              <InputGroup className={'single-addon-left'}>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {
                                      this.props.auth.selectedFleet
                                        .currencies[0].symbol
                                    }
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                  type="text"
                                  className={'form-custom'}
                                  onChange={(e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'singleTrip',
                                      'returnRouteByCurrencies',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  }}
                                  onBlur={(e) => {
                                    textboxNumberHelper.onBlurHandle(e, (e) => {
                                      this.handleRouteMultiCurrenciesChange(
                                        'singleTrip',
                                        'returnRouteByCurrencies',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e
                                      );
                                    });
                                  }}
                                  onFocus={(e) => {
                                    textboxNumberHelper.onfocusHandle(
                                      e,
                                      (e) => {
                                        this.handleRouteMultiCurrenciesChange(
                                          'singleTrip',
                                          'returnRouteByCurrencies',
                                          this.props.auth.selectedFleet
                                            .currencies[0].iso,
                                          e
                                        );
                                      }
                                    );
                                  }}
                                  value={this.getValueByCurrency(
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    this.state.routeModel.singleTrip
                                      .returnRouteByCurrencies
                                  )}
                                  disabled={!hasPermission}
                                />
                              </InputGroup>
                            </FormGroup>

                            <WarningFeeInput
                              message={I18n.t('message.warningInputChangeOver')}
                              className="text-soft-warning"
                              disabled={!hasPermission}
                              value={this.getValueByCurrency(
                                this.props.auth.selectedFleet.currencies[0].iso,
                                this.state.routeModel.singleTrip
                                  .returnRouteByCurrencies
                              )}
                              timeDelay={500}
                              typeOfCheck="higherOfLower"
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    {this.state.routeModel.singleTrip
                      .returnRouteByCurrencies ? (
                      <div className="currency-error-message">
                        <Validator
                          id="singleTrip_returnRouteByCurrencies"
                          callback={this.routesValidatorCallback}
                        >
                          <ValidCase
                            valid={this.checkValidRoute2Currencies(
                              CheckMode.checkInteger
                            )}
                            message={I18n.t(
                              'messages.commonMessages.must_be_number'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                          <ValidCase
                            valid={this.checkValidRoute2Currencies(
                              CheckMode.checkGreaterThan0
                            )}
                            message={I18n.t(
                              'messages.commonMessages.equal_or_greater_than_0'
                            )}
                            hide={!this.state.routeSubmitted}
                          />
                        </Validator>
                      </div>
                    ) : (
                      ''
                    )}
                  </FormGroup>
                )}

                <FormGroup>
                  <Form.Label inline className="mr-r-50">
                    <Translate value="regularSetting.Limitation" />
                  </Form.Label>
                  <RadioButton
                    text={I18n.t('regularSetting.Unlimited')}
                    inline
                    id="sgunlimited"
                    name="sgLimitation"
                    // value={0}
                    checked={
                      !this.state.routeModel.singleTrip.limitation.limited
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      this.radioChangeHandle('singleTrip', 'limitation', false);
                    }}
                    disabled={!hasPermission}
                  />
                  <RadioButton
                    text={I18n.t('regularSetting.Limited')}
                    inline
                    name="sgLimitation"
                    id="sglimited"
                    checked={
                      this.state.routeModel.singleTrip.limitation.limited
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      this.radioChangeHandle('singleTrip', 'limitation', true);
                    }}
                    disabled={!hasPermission}
                  />
                </FormGroup>
                {this.state.routeModel.singleTrip.limitation.limited ? (
                  <FormGroup>
                    <FormGroup
                      style={{ width: '100%' }}
                      className={
                        this.state.routeSubmitted
                          ? this.state.routeValid.limitation_coveredDistance ===
                            false
                            ? 'error'
                            : null
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="regularSetting.Distance_coverage" />{' '}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup className={'single-addon-right'}>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            value={
                              this.state.routeModel.singleTrip.limitation
                                ? this.state.routeModel.singleTrip.limitation
                                    .coveredDistance
                                : ''
                            }
                            onChange={(e) => {
                              this.limitationInputChangeHandle(
                                'singleTrip',
                                'coveredDistance',
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.limitationInputChangeHandle(
                                  'singleTrip',
                                  'coveredDistance',
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.limitationInputChangeHandle(
                                  'singleTrip',
                                  'coveredDistance',
                                  e
                                );
                              });
                            }}
                            disabled={!hasPermission}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              {this.props.auth.selectedFleet.unitDistance}
                            </InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </FormGroup>
                      <Validator
                        id="limitation_coveredDistance"
                        callback={this.routesValidatorCallback}
                      >
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            !Validation.isStringEmpty(
                              this.state.routeModel.singleTrip.limitation
                                .coveredDistance
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            Validation.isNumber(
                              this.state.routeModel.singleTrip.limitation
                                .coveredDistance
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            Validation.isGreaterOrEqual(
                              this.state.routeModel.singleTrip.limitation
                                .coveredDistance,
                              0
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.equal_or_greater_than_0'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup
                      style={{ width: '100%' }}
                      className={
                        this.state.routeSubmitted
                          ? this.state.routeValid
                              .limitation_extraDistanceByCurrencies === false
                            ? 'error'
                            : null
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="regularSetting.Extra_distance" />{' '}
                        <span className="require">*</span>
                      </Form.Label>
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-limitation"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.routeModel
                                  .singleTrip.limitation
                                  .extraDistanceByCurrencies
                                  ? this.state.routeModel.singleTrip.limitation.extraDistanceByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : null;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={'single-addon-left'}
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleRouteMultiCurrenciesChange(
                                              'singleTrip',
                                              'limitation',
                                              c.iso,
                                              e,
                                              'extraDistanceByCurrencies'
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'singleTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraDistanceByCurrencies'
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'singleTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraDistanceByCurrencies'
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!hasPermission}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text>
                                            /
                                            {
                                              this.props.auth.selectedFleet
                                                .unitDistance
                                            }
                                          </InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!hasPermission}
                            value={
                              !this.state.routeModel.singleTrip ||
                              !this.state.routeModel.singleTrip.limitation ||
                              !this.state.routeModel.singleTrip.limitation
                                .extraDistanceByCurrencies ||
                              !this.state.routeModel.singleTrip.limitation
                                .extraDistanceByCurrencies.length
                                ? null
                                : this.state.routeModel.singleTrip.limitation.extraDistanceByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup className={'single-addon-left'}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleRouteMultiCurrenciesChange(
                                    'singleTrip',
                                    'limitation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e,
                                    'extraDistanceByCurrencies'
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'singleTrip',
                                      'limitation',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e,
                                      'extraDistanceByCurrencies'
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'singleTrip',
                                      'limitation',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e,
                                      'extraDistanceByCurrencies'
                                    );
                                  });
                                }}
                                value={
                                  this.state.routeModel.singleTrip.limitation
                                    .extraDistanceByCurrencies &&
                                  this.state.routeModel.singleTrip.limitation
                                    .extraDistanceByCurrencies[0]
                                    ? this.state.routeModel.singleTrip
                                        .limitation.extraDistanceByCurrencies[0]
                                        .value
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!hasPermission}
                              />
                            </InputGroup>
                          </FormGroup>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            value={
                              this.state.routeModel.singleTrip.limitation
                                .extraDistanceByCurrencies &&
                              this.state.routeModel.singleTrip.limitation
                                .extraDistanceByCurrencies[0]
                                ? this.state.routeModel.singleTrip.limitation
                                    .extraDistanceByCurrencies[0].value
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.routeModel.singleTrip.limitation
                        .extraDistanceByCurrencies ? (
                        <div className="currency-error-message">
                          <Validator
                            id="limitation_extraDistanceByCurrencies"
                            callback={this.routesValidatorCallback}
                          >
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraDistanceByCurrencies.filter(
                                  (d) => {
                                    return Validation.isStringEmpty(d.value);
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraDistanceByCurrencies.filter(
                                  (d) => {
                                    return d.value !== '' && isNaN(d.value);
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraDistanceByCurrencies.filter(
                                  (d) => {
                                    return d.value < 0;
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.equal_or_greater_than_0'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                          </Validator>
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <FormGroup
                      style={{ width: '100%' }}
                      className={
                        this.state.routeSubmitted
                          ? this.state.routeValid.limitation_coveredTime ===
                            false
                            ? 'error'
                            : null
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="regularSetting.Time_coverage" />{' '}
                        <span className="require">*</span>
                      </Form.Label>
                      <FormGroup className={'qup-input-group'}>
                        <InputGroup className={'single-addon-right'}>
                          <FormControl
                            type="text"
                            className={'form-custom'}
                            value={
                              this.state.routeModel.singleTrip.limitation
                                ? this.state.routeModel.singleTrip.limitation
                                    .coveredTime
                                : ''
                            }
                            onChange={(e) => {
                              this.limitationInputChangeHandle(
                                'singleTrip',
                                'coveredTime',
                                e
                              );
                            }}
                            onBlur={(e) => {
                              textboxNumberHelper.onBlurHandle(e, (e) => {
                                this.limitationInputChangeHandle(
                                  'singleTrip',
                                  'coveredTime',
                                  e
                                );
                              });
                            }}
                            onFocus={(e) => {
                              textboxNumberHelper.onfocusHandle(e, (e) => {
                                this.limitationInputChangeHandle(
                                  'singleTrip',
                                  'coveredTime',
                                  e
                                );
                              });
                            }}
                            disabled={!hasPermission}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>
                              <Translate value="regularSetting.hour" />
                            </InputGroup.Text>
                          </InputGroup.Append>{' '}
                        </InputGroup>
                      </FormGroup>
                      <Validator
                        id="limitation_coveredTime"
                        callback={this.routesValidatorCallback}
                      >
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            !Validation.isStringEmpty(
                              this.state.routeModel.singleTrip.limitation
                                .coveredTime
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.Required_field'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            !isNaN(
                              this.state.routeModel.singleTrip.limitation
                                .coveredTime
                            )
                          )}
                          message={I18n.t(
                            'messages.commonMessages.must_be_number'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                        <ValidCase
                          valid={this.checkSingleTripLimitted(
                            this.state.routeModel.singleTrip.limitation
                              .coveredTime >= 0
                          )}
                          message={I18n.t(
                            'messages.commonMessages.equal_or_greater_than_0'
                          )}
                          hide={!this.state.routeSubmitted}
                        />
                      </Validator>
                    </FormGroup>
                    <FormGroup
                      style={{ width: '100%' }}
                      className={
                        this.state.routeSubmitted
                          ? this.state.routeValid
                              .limitation_extraTimeByCurrencies === false
                            ? 'error'
                            : null
                          : null
                      }
                    >
                      <Form.Label>
                        <Translate value="regularSetting.Extra_time" />{' '}
                        <span className="require">*</span>
                      </Form.Label>
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-limitation"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.routeModel
                                  .singleTrip.limitation.extraTimeByCurrencies
                                  ? this.state.routeModel.singleTrip.limitation.extraTimeByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : null;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <InputGroup.Prepend>
                                      <InputGroup.Text>
                                        {c.symbol}
                                      </InputGroup.Text>
                                    </InputGroup.Prepend>{' '}
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleRouteMultiCurrenciesChange(
                                              'singleTrip',
                                              'limitation',
                                              c.iso,
                                              e,
                                              'extraTimeByCurrencies'
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'singleTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraTimeByCurrencies'
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'singleTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraTimeByCurrencies'
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!hasPermission}
                                        />
                                        <InputGroup.Append>
                                          <InputGroup.Text>
                                            /
                                            <Translate value="regularSetting.hour" />
                                          </InputGroup.Text>
                                        </InputGroup.Append>
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!hasPermission}
                            value={
                              !this.state.routeModel.singleTrip ||
                              !this.state.routeModel.singleTrip.limitation ||
                              !this.state.routeModel.singleTrip.limitation
                                .extraTimeByCurrencies ||
                              !this.state.routeModel.singleTrip.limitation
                                .extraTimeByCurrencies.length
                                ? null
                                : this.state.routeModel.singleTrip.limitation.extraTimeByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup className={'single-addon-left'}>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleRouteMultiCurrenciesChange(
                                    'singleTrip',
                                    'limitation',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e,
                                    'extraTimeByCurrencies'
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'singleTrip',
                                      'limitation',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e,
                                      'extraTimeByCurrencies'
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'singleTrip',
                                      'limitation',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e,
                                      'extraTimeByCurrencies'
                                    );
                                  });
                                }}
                                value={
                                  this.state.routeModel.singleTrip.limitation
                                    .extraTimeByCurrencies &&
                                  this.state.routeModel.singleTrip.limitation
                                    .extraTimeByCurrencies[0]
                                    ? this.state.routeModel.singleTrip
                                        .limitation.extraTimeByCurrencies[0]
                                        .value
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!hasPermission}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>{' '}
                            </InputGroup>
                          </FormGroup>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            value={
                              this.state.routeModel.singleTrip.limitation
                                .extraTimeByCurrencies &&
                              this.state.routeModel.singleTrip.limitation
                                .extraTimeByCurrencies[0]
                                ? this.state.routeModel.singleTrip.limitation
                                    .extraTimeByCurrencies[0].value
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.routeModel.singleTrip.limitation
                        .extraTimeByCurrencies ? (
                        <div className="currency-error-message">
                          <Validator
                            id="limitation_extraTimeByCurrencies"
                            callback={this.routesValidatorCallback}
                          >
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraTimeByCurrencies.filter(
                                  (d) => {
                                    return Validation.isStringEmpty(d.value);
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraTimeByCurrencies.filter(
                                  (d) => {
                                    return d.value !== '' && isNaN(d.value);
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                            <ValidCase
                              valid={this.checkSingleTripLimitted(
                                this.state.routeModel.singleTrip.limitation.extraTimeByCurrencies.filter(
                                  (d) => {
                                    return d.value < 0;
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.equal_or_greater_than_0'
                              )}
                              hide={!this.state.routeSubmitted}
                            />
                          </Validator>
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </FormGroup>
                ) : (
                  ''
                )}
                <div className={'vs-20'} />
                {dialogData && dialogData.serviceType !== 'delivery' && (
                  <>
                    <FormGroupTitle>
                      <Translate value="regularSetting.ROUND_TRIP_SETUP" />
                    </FormGroupTitle>
                    <CcCheckbox
                      checked={this.state.routeModel.roundTrip.enableRoundTrip}
                      onChange={(e) => {
                        this.checkboxChangeHandle(
                          'roundTrip',
                          'enableRoundTrip',
                          e
                        );
                      }}
                      text={I18n.t('regularSetting.Round_trip')}
                      disabled={!hasPermission}
                    />
                  </>
                )}
                {this.state.routeModel.roundTrip.enableRoundTrip ? (
                  <div style={{ width: '100%' }}>
                    <FormGroup
                      className={
                        this.state.routeSubmitted
                          ? this.state.routeValid
                              .roundTrip_roundTripFeeByCurrencies === false
                            ? 'error'
                            : null
                          : null
                      }
                    >
                      {this.props.auth.selectedFleet.multiCurrencies ? (
                        <div className="input-with-validator">
                          <Tabs
                            id="currencies-tabs-returnRouteByCurrencies"
                            className="currencies-tabs"
                            defaultActiveKey={
                              this.props.auth.selectedFleet.currencies[0].iso
                            }
                            animation={false}
                            onSelect={this.tabSelectHandle}
                          >
                            {this.props.auth.selectedFleet.currencies.map(
                              (c) => {
                                let currentValue = this.state.routeModel
                                  .roundTrip.roundTripFeeByCurrencies
                                  ? this.state.routeModel.roundTrip.roundTripFeeByCurrencies.filter(
                                      (d) => {
                                        return d.currencyISO == c.iso;
                                      }
                                    )[0]
                                  : null;
                                return (
                                  <Tab
                                    className="currencies-tab-item"
                                    eventKey={c.iso}
                                    title={c.iso}
                                  >
                                    <FormGroup className={'qup-input-group'}>
                                      <InputGroup
                                        className={'single-addon-left'}
                                      >
                                        <InputGroup.Prepend>
                                          <InputGroup.Text>
                                            {c.symbol}
                                          </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                          type="text"
                                          className={'form-custom'}
                                          onChange={(e) => {
                                            this.handleRouteMultiCurrenciesChange(
                                              'roundTrip',
                                              'roundTripFeeByCurrencies',
                                              c.iso,
                                              e
                                            );
                                          }}
                                          onBlur={(e) => {
                                            textboxNumberHelper.onBlurHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'roundTrip',
                                                  'roundTripFeeByCurrencies',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          onFocus={(e) => {
                                            textboxNumberHelper.onfocusHandle(
                                              e,
                                              (e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'roundTrip',
                                                  'roundTripFeeByCurrencies',
                                                  c.iso,
                                                  e
                                                );
                                              }
                                            );
                                          }}
                                          value={
                                            currentValue
                                              ? currentValue.value
                                              : DefaultCurenciesNumberInputField
                                          }
                                          disabled={!hasPermission}
                                        />
                                      </InputGroup>
                                    </FormGroup>
                                  </Tab>
                                );
                              }
                            )}
                          </Tabs>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            disabled={!hasPermission}
                            value={
                              !this.state.routeModel.roundTrip ||
                              !this.state.routeModel.roundTrip
                                .roundTripFeeByCurrencies ||
                              !this.state.routeModel.roundTrip
                                .roundTripFeeByCurrencies.length
                                ? null
                                : this.state.routeModel.roundTrip.roundTripFeeByCurrencies.map(
                                    (fees) => {
                                      const { currencyISO, value } = fees;
                                      return {
                                        [currencyISO]: value,
                                      };
                                    }
                                  )
                            }
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      ) : (
                        <div className="input-with-validator">
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup className={'single-addon-left'}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {
                                    this.props.auth.selectedFleet.currencies[0]
                                      .symbol
                                  }
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                onChange={(e) => {
                                  this.handleRouteMultiCurrenciesChange(
                                    'roundTrip',
                                    'roundTripFeeByCurrencies',
                                    this.props.auth.selectedFleet.currencies[0]
                                      .iso,
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'roundTrip',
                                      'roundTripFeeByCurrencies',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.handleRouteMultiCurrenciesChange(
                                      'roundTrip',
                                      'roundTripFeeByCurrencies',
                                      this.props.auth.selectedFleet
                                        .currencies[0].iso,
                                      e
                                    );
                                  });
                                }}
                                value={
                                  this.state.routeModel.roundTrip
                                    .roundTripFeeByCurrencies &&
                                  this.state.routeModel.roundTrip
                                    .roundTripFeeByCurrencies[0]
                                    ? this.state.routeModel.roundTrip
                                        .roundTripFeeByCurrencies[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!hasPermission}
                              />
                            </InputGroup>
                          </FormGroup>

                          <WarningFeeInput
                            message={I18n.t('message.warningInputChangeOver')}
                            className="text-soft-warning"
                            value={
                              this.state.routeModel.roundTrip
                                .roundTripFeeByCurrencies &&
                              this.state.routeModel.roundTrip
                                .roundTripFeeByCurrencies[0]
                                ? this.state.routeModel.roundTrip
                                    .roundTripFeeByCurrencies[0].value
                                : DefaultCurenciesNumberInputField
                            }
                            disabled={!hasPermission}
                            timeDelay={500}
                            typeOfCheck="higherOfLower"
                          />
                        </div>
                      )}
                      {this.state.routeModel.roundTrip
                        .roundTripFeeByCurrencies ? (
                        <div className="currency-error-message">
                          <Validator
                            id="roundTrip_roundTripFeeByCurrencies"
                            callback={this.routesValidatorCallback}
                          >
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRouteChipEnable(
                                this.state.routeModel.roundTrip.roundTripFeeByCurrencies.filter(
                                  (d) => {
                                    return d.value !== '' && isNaN(d.value);
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                            />
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRouteChipEnable(
                                this.state.routeModel.roundTrip.roundTripFeeByCurrencies.filter(
                                  (d) => {
                                    return d.value < 0;
                                  }
                                ).length == 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.equal_or_greater_than_0'
                              )}
                            />
                          </Validator>
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Form.Label inline className="bold mr-r-50">
                        <Translate value="regularSetting.Limitation" />
                      </Form.Label>
                      <RadioButton
                        text={I18n.t('regularSetting.Unlimited')}
                        inline
                        name="rLimitation"
                        id="runlimited"
                        checked={
                          !this.state.routeModel.roundTrip.limitation.limited
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          this.radioChangeHandle(
                            'roundTrip',
                            'limitation',
                            false
                          );
                        }}
                        disabled={!hasPermission}
                      />
                      <RadioButton
                        text={I18n.t('regularSetting.Limited')}
                        inline
                        id="rlimited"
                        checked={
                          this.state.routeModel.roundTrip.limitation.limited
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          this.radioChangeHandle(
                            'roundTrip',
                            'limitation',
                            true
                          );
                        }}
                        disabled={!hasPermission}
                      />
                    </FormGroup>
                    {this.state.routeModel.roundTrip.limitation.limited ? (
                      <FormGroup>
                        <FormGroup
                          style={{ width: '100%' }}
                          className={
                            this.state.routeSubmitted
                              ? this.state.routeValid
                                  .roundTrip_limitation_coveredDistance ===
                                false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Distance_coverage" />{' '}
                            <span className="require">*</span>
                          </Form.Label>
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup className={'single-addon-right'}>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                value={
                                  this.state.routeModel.roundTrip.limitation
                                    ? this.state.routeModel.roundTrip.limitation
                                        .coveredDistance
                                    : ''
                                }
                                onChange={(e) => {
                                  this.limitationInputChangeHandle(
                                    'roundTrip',
                                    'coveredDistance',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.limitationInputChangeHandle(
                                      'roundTrip',
                                      'coveredDistance',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.limitationInputChangeHandle(
                                      'roundTrip',
                                      'coveredDistance',
                                      e
                                    );
                                  });
                                }}
                                disabled={!hasPermission}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {this.props.auth.selectedFleet.unitDistance}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          </FormGroup>
                          <Validator
                            id="roundTrip_limitation_coveredDistance"
                            callback={this.routesValidatorCallback}
                          >
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                !Validation.isStringEmpty(
                                  this.state.routeModel.roundTrip.limitation
                                    .coveredDistance
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                            />
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                !isNaN(
                                  this.state.routeModel.roundTrip.limitation
                                    .coveredDistance
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                            />
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                this.state.routeModel.roundTrip.limitation
                                  .coveredDistance >= 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.equal_or_greater_than_0'
                              )}
                            />
                          </Validator>
                        </FormGroup>
                        <FormGroup
                          style={{ width: '100%' }}
                          className={
                            this.state.routeSubmitted
                              ? this.state.routeValid
                                  .roundTrip_limitation_extraDistanceByCurrencies ===
                                false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Extra_distance" />{' '}
                            <span className="require">*</span>
                          </Form.Label>
                          {this.props.auth.selectedFleet.multiCurrencies ? (
                            <div className="input-with-validator">
                              <Tabs
                                id="currencies-tabs-limitation"
                                className="currencies-tabs"
                                defaultActiveKey={
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                }
                                animation={false}
                                onSelect={this.tabSelectHandle}
                              >
                                {this.props.auth.selectedFleet.currencies.map(
                                  (c) => {
                                    let currentValue = this.state.routeModel
                                      .roundTrip.limitation
                                      .extraDistanceByCurrencies
                                      ? this.state.routeModel.roundTrip.limitation.extraDistanceByCurrencies.filter(
                                          (d) => {
                                            return d.currencyISO == c.iso;
                                          }
                                        )[0]
                                      : null;
                                    return (
                                      <Tab
                                        className="currencies-tab-item"
                                        eventKey={c.iso}
                                        title={c.iso}
                                      >
                                        <FormGroup
                                          className={'qup-input-group'}
                                        >
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                {c.symbol}
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>{' '}
                                            <FormControl
                                              type="text"
                                              className={'form-custom'}
                                              onChange={(e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'roundTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraDistanceByCurrencies'
                                                );
                                              }}
                                              onBlur={(e) => {
                                                textboxNumberHelper.onBlurHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleRouteMultiCurrenciesChange(
                                                      'roundTrip',
                                                      'limitation',
                                                      c.iso,
                                                      e,
                                                      'extraDistanceByCurrencies'
                                                    );
                                                  }
                                                );
                                              }}
                                              onFocus={(e) => {
                                                textboxNumberHelper.onfocusHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleRouteMultiCurrenciesChange(
                                                      'roundTrip',
                                                      'limitation',
                                                      c.iso,
                                                      e,
                                                      'extraDistanceByCurrencies'
                                                    );
                                                  }
                                                );
                                              }}
                                              value={
                                                currentValue
                                                  ? currentValue.value
                                                  : DefaultCurenciesNumberInputField
                                              }
                                              disabled={!hasPermission}
                                            />
                                            <InputGroup.Append>
                                              <InputGroup.Text>
                                                /
                                                {
                                                  this.props.auth.selectedFleet
                                                    .unitDistance
                                                }
                                              </InputGroup.Text>
                                            </InputGroup.Append>{' '}
                                          </InputGroup>
                                        </FormGroup>
                                      </Tab>
                                    );
                                  }
                                )}
                              </Tabs>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!hasPermission}
                                value={
                                  !this.state.routeModel.roundTrip ||
                                  !this.state.routeModel.roundTrip.limitation ||
                                  !this.state.routeModel.roundTrip.limitation
                                    .extraDistanceByCurrencies ||
                                  !this.state.routeModel.roundTrip.limitation
                                    .extraDistanceByCurrencies.length
                                    ? null
                                    : this.state.routeModel.roundTrip.limitation.extraDistanceByCurrencies.map(
                                        (fees) => {
                                          const { currencyISO, value } = fees;
                                          return {
                                            [currencyISO]: value,
                                          };
                                        }
                                      )
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          ) : (
                            <div className="input-with-validator">
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup className={'single-addon-left'}>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {
                                        this.props.auth.selectedFleet
                                          .currencies[0].symbol
                                      }
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleRouteMultiCurrenciesChange(
                                        'roundTrip',
                                        'limitation',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e,
                                        'extraDistanceByCurrencies'
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'roundTrip',
                                            'limitation',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e,
                                            'extraDistanceByCurrencies'
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'roundTrip',
                                            'limitation',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e,
                                            'extraDistanceByCurrencies'
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      this.state.routeModel.roundTrip.limitation
                                        .extraDistanceByCurrencies &&
                                      this.state.routeModel.roundTrip.limitation
                                        .extraDistanceByCurrencies[0]
                                        ? this.state.routeModel.roundTrip
                                            .limitation
                                            .extraDistanceByCurrencies[0].value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!hasPermission}
                                  />
                                </InputGroup>
                              </FormGroup>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                value={
                                  this.state.routeModel.roundTrip.limitation
                                    .extraDistanceByCurrencies &&
                                  this.state.routeModel.roundTrip.limitation
                                    .extraDistanceByCurrencies[0]
                                    ? this.state.routeModel.roundTrip.limitation
                                        .extraDistanceByCurrencies[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!hasPermission}
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          )}
                          {this.state.routeModel.roundTrip.limitation
                            .extraDistanceByCurrencies ? (
                            <div className="currency-error-message">
                              <Validator
                                id="roundTrip_limitation_extraDistanceByCurrencies"
                                callback={this.routesValidatorCallback}
                              >
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraDistanceByCurrencies.filter(
                                      (d) => {
                                        return Validation.isStringEmpty(
                                          d.value
                                        );
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.Required_field'
                                  )}
                                />
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraDistanceByCurrencies.filter(
                                      (d) => {
                                        return d.value !== '' && isNaN(d.value);
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.must_be_number'
                                  )}
                                />
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraDistanceByCurrencies.filter(
                                      (d) => {
                                        return d.value < 0;
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.equal_or_greater_than_0'
                                  )}
                                />
                              </Validator>
                            </div>
                          ) : (
                            ''
                          )}
                        </FormGroup>
                        <FormGroup
                          style={{ width: '100%' }}
                          className={
                            this.state.routeSubmitted
                              ? this.state.routeValid
                                  .roundTrip_limitation_coveredTime === false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Time_coverage" />{' '}
                            <span className="require">*</span>
                          </Form.Label>
                          <FormGroup className={'qup-input-group'}>
                            <InputGroup className={'single-addon-right'}>
                              <FormControl
                                type="text"
                                className={'form-custom'}
                                value={
                                  this.state.routeModel.roundTrip.limitation
                                    ? this.state.routeModel.roundTrip.limitation
                                        .coveredTime
                                    : ''
                                }
                                onChange={(e) => {
                                  this.limitationInputChangeHandle(
                                    'roundTrip',
                                    'coveredTime',
                                    e
                                  );
                                }}
                                onBlur={(e) => {
                                  textboxNumberHelper.onBlurHandle(e, (e) => {
                                    this.limitationInputChangeHandle(
                                      'roundTrip',
                                      'coveredTime',
                                      e
                                    );
                                  });
                                }}
                                onFocus={(e) => {
                                  textboxNumberHelper.onfocusHandle(e, (e) => {
                                    this.limitationInputChangeHandle(
                                      'roundTrip',
                                      'coveredTime',
                                      e
                                    );
                                  });
                                }}
                                disabled={!hasPermission}
                              />
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  <Translate value="regularSetting.hour" />
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                            </InputGroup>
                          </FormGroup>
                          <Validator
                            id="roundTrip_limitation_coveredTime"
                            callback={this.routesValidatorCallback}
                          >
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                !Validation.isStringEmpty(
                                  this.state.routeModel.roundTrip.limitation
                                    .coveredTime
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.Required_field'
                              )}
                            />
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                !isNaN(
                                  this.state.routeModel.roundTrip.limitation
                                    .coveredTime
                                )
                              )}
                              message={I18n.t(
                                'messages.commonMessages.must_be_number'
                              )}
                            />
                            <ValidCase
                              hide={!this.state.routeSubmitted}
                              valid={this.checkRoundTripLimitted(
                                this.state.routeModel.roundTrip.limitation
                                  .coveredTime >= 0
                              )}
                              message={I18n.t(
                                'messages.commonMessages.equal_or_greater_than_0'
                              )}
                            />
                          </Validator>
                        </FormGroup>
                        <FormGroup
                          style={{ width: '100%' }}
                          className={
                            this.state.routeSubmitted
                              ? this.state.routeValid
                                  .roundTrip_limitation_extraTimeByCurrencies ===
                                false
                                ? 'error'
                                : null
                              : null
                          }
                        >
                          <Form.Label>
                            <Translate value="regularSetting.Extra_time" />{' '}
                            <span className="require">*</span>
                          </Form.Label>
                          {this.props.auth.selectedFleet.multiCurrencies ? (
                            <div className="input-with-validator">
                              <Tabs
                                id="currencies-tabs-limitation"
                                className="currencies-tabs"
                                defaultActiveKey={
                                  this.props.auth.selectedFleet.currencies[0]
                                    .iso
                                }
                                animation={false}
                                onSelect={this.tabSelectHandle}
                              >
                                {this.props.auth.selectedFleet.currencies.map(
                                  (c) => {
                                    let currentValue = this.state.routeModel
                                      .roundTrip.limitation
                                      .extraTimeByCurrencies
                                      ? this.state.routeModel.roundTrip.limitation.extraTimeByCurrencies.filter(
                                          (d) => {
                                            return d.currencyISO == c.iso;
                                          }
                                        )[0]
                                      : null;
                                    return (
                                      <Tab
                                        className="currencies-tab-item"
                                        eventKey={c.iso}
                                        title={c.iso}
                                      >
                                        <FormGroup
                                          className={'qup-input-group'}
                                        >
                                          <InputGroup>
                                            <InputGroup.Prepend>
                                              <InputGroup.Text>
                                                {c.symbol}
                                              </InputGroup.Text>
                                            </InputGroup.Prepend>{' '}
                                            <FormControl
                                              type="text"
                                              className={'form-custom'}
                                              onChange={(e) => {
                                                this.handleRouteMultiCurrenciesChange(
                                                  'roundTrip',
                                                  'limitation',
                                                  c.iso,
                                                  e,
                                                  'extraTimeByCurrencies'
                                                );
                                              }}
                                              onBlur={(e) => {
                                                textboxNumberHelper.onBlurHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleRouteMultiCurrenciesChange(
                                                      'roundTrip',
                                                      'limitation',
                                                      c.iso,
                                                      e,
                                                      'extraTimeByCurrencies'
                                                    );
                                                  }
                                                );
                                              }}
                                              onFocus={(e) => {
                                                textboxNumberHelper.onfocusHandle(
                                                  e,
                                                  (e) => {
                                                    this.handleRouteMultiCurrenciesChange(
                                                      'roundTrip',
                                                      'limitation',
                                                      c.iso,
                                                      e,
                                                      'extraTimeByCurrencies'
                                                    );
                                                  }
                                                );
                                              }}
                                              value={
                                                currentValue
                                                  ? currentValue.value
                                                  : DefaultCurenciesNumberInputField
                                              }
                                              disabled={!hasPermission}
                                            />
                                            <InputGroup.Append>
                                              <InputGroup.Text>
                                                /
                                                <Translate value="regularSetting.hour" />
                                              </InputGroup.Text>
                                            </InputGroup.Append>{' '}
                                          </InputGroup>
                                        </FormGroup>
                                      </Tab>
                                    );
                                  }
                                )}
                              </Tabs>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                disabled={!hasPermission}
                                value={
                                  !this.state.routeModel ||
                                  !this.state.routeModel.roundTrip ||
                                  !this.state.routeModel.roundTrip.limitation ||
                                  !this.state.routeModel.roundTrip.limitation
                                    .extraTimeByCurrencies ||
                                  !this.state.routeModel.roundTrip.limitation
                                    .extraTimeByCurrencies.length
                                    ? null
                                    : this.state.routeModel.roundTrip.limitation.extraTimeByCurrencies.map(
                                        (fees) => {
                                          const { currencyISO, value } = fees;
                                          return {
                                            [currencyISO]: value,
                                          };
                                        }
                                      )
                                }
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          ) : (
                            <div className="input-with-validator">
                              <FormGroup className={'qup-input-group'}>
                                <InputGroup className={'single-addon-left'}>
                                  <InputGroup.Prepend>
                                    <InputGroup.Text>
                                      {
                                        this.props.auth.selectedFleet
                                          .currencies[0].symbol
                                      }
                                    </InputGroup.Text>
                                  </InputGroup.Prepend>
                                  <FormControl
                                    type="text"
                                    className={'form-custom'}
                                    onChange={(e) => {
                                      this.handleRouteMultiCurrenciesChange(
                                        'roundTrip',
                                        'limitation',
                                        this.props.auth.selectedFleet
                                          .currencies[0].iso,
                                        e,
                                        'extraTimeByCurrencies'
                                      );
                                    }}
                                    onBlur={(e) => {
                                      textboxNumberHelper.onBlurHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'roundTrip',
                                            'limitation',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e,
                                            'extraTimeByCurrencies'
                                          );
                                        }
                                      );
                                    }}
                                    onFocus={(e) => {
                                      textboxNumberHelper.onfocusHandle(
                                        e,
                                        (e) => {
                                          this.handleRouteMultiCurrenciesChange(
                                            'roundTrip',
                                            'limitation',
                                            this.props.auth.selectedFleet
                                              .currencies[0].iso,
                                            e,
                                            'extraTimeByCurrencies'
                                          );
                                        }
                                      );
                                    }}
                                    value={
                                      this.state.routeModel.roundTrip.limitation
                                        .extraTimeByCurrencies &&
                                      this.state.routeModel.roundTrip.limitation
                                        .extraTimeByCurrencies[0]
                                        ? this.state.routeModel.roundTrip
                                            .limitation.extraTimeByCurrencies[0]
                                            .value
                                        : DefaultCurenciesNumberInputField
                                    }
                                    disabled={!hasPermission}
                                  />
                                </InputGroup>
                              </FormGroup>

                              <WarningFeeInput
                                message={I18n.t(
                                  'message.warningInputChangeOver'
                                )}
                                className="text-soft-warning"
                                value={
                                  this.state.routeModel.roundTrip.limitation
                                    .extraTimeByCurrencies &&
                                  this.state.routeModel.roundTrip.limitation
                                    .extraTimeByCurrencies[0]
                                    ? this.state.routeModel.roundTrip.limitation
                                        .extraTimeByCurrencies[0].value
                                    : DefaultCurenciesNumberInputField
                                }
                                disabled={!hasPermission}
                                timeDelay={500}
                                typeOfCheck="higherOfLower"
                              />
                            </div>
                          )}
                          {this.state.routeModel.roundTrip.limitation
                            .extraTimeByCurrencies ? (
                            <div className="currency-error-message">
                              <Validator
                                id="roundTrip_limitation_extraTimeByCurrencies"
                                callback={this.routesValidatorCallback}
                              >
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraTimeByCurrencies.filter(
                                      (d) => {
                                        return Validation.isStringEmpty(
                                          d.value
                                        );
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.Required_field'
                                  )}
                                />
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraTimeByCurrencies.filter(
                                      (d) => {
                                        return d.value !== '' && isNaN(d.value);
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.must_be_number'
                                  )}
                                />
                                <ValidCase
                                  hide={!this.state.routeSubmitted}
                                  valid={this.checkRoundTripLimitted(
                                    this.state.routeModel.roundTrip.limitation.extraTimeByCurrencies.filter(
                                      (d) => {
                                        return d.value < 0;
                                      }
                                    ).length == 0
                                  )}
                                  message={I18n.t(
                                    'messages.commonMessages.equal_or_greater_than_0'
                                  )}
                                />
                              </Validator>
                            </div>
                          ) : (
                            ''
                          )}
                        </FormGroup>
                      </FormGroup>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </Modal.Body>
              <Modal.Footer className={'pd-r-l-60'}>
                {hasPermission ? (
                  <Button className={'btn-save mr-md'} type="submit">
                    <Translate value="regularSetting.Save" />
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  className={'btn-cancel'}
                  onClick={this.closeRouteModelForm}
                >
                  <Translate value="regularSetting.Cancel" />
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        )}
        <Import
          isShow={this.state.isShowImport}
          import={this.props.settingActions.importRateFlat}
          closeDialog={() => {
            this.setState({ isShowImport: false });
          }}
          callback={this.updateDataList}
        />
        <ButtonToolbar
          className="text-center header-button-group"
          ref={(node) => (this.toobarContainer = node)}
        >
          <ButtonGroup className="group-left">
            <FormGroup className="search-format mb0">
              <input
                type="text"
                className="form-control search-form"
                value={this.state.str}
                onKeyPress={this.handleSearchKeyPress}
                onChange={this.handleSearchChange}
                placeholder={I18n.t('General.search')}
              />
              <BsSearch className="search-icon" />
            </FormGroup>
            {hasPermission && (
              <Button
                className="btn-header text-add-header ml0"
                onClick={this.handleAddButtonClick}
              >
                {' '}
                <Translate value="regularSetting.Add" />
              </Button>
            )}
          </ButtonGroup>
          <ButtonGroup className="group-right">
            {_.get(this.props.permissions, 'actions', false) && (
              <>
                <Button
                  className="btn-header text-add-header"
                  onClick={this.handleImportClick}
                >
                  <Translate value="customer.Import" />
                </Button>
                <ExportComponent
                  prepareParamExport={this.prepareParamExport}
                  urlExport={this.props.settingActions.rateFlatExport}
                  user={this.props.auth.user}
                />
              </>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <Confirm
          confirm={this.state.confirm}
          handleConfirmButtonClick={this.handleConfirmCloseClick}
          handleConfirmCloseClick={this.handleConfirmCloseClick}
        />
        <div className="gridViewTable">
          <StickyTable
            columns={this.getTableColumns()}
            bodyData={bodyData}
            footerData={this.state.footerData}
            handleNumItemsPerPageChange={this.handleNumItemsPerPageChange}
            handlePaginationSelect={this.handlePaginationSelect}
            rowHeight={this.state.rowHeight}
            getTableHeight={this.getTableHeight}
          />
        </div>
      </div>
    );
  }
}

FlatSetting.contextTypes = {
  notification: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    commonData: state.commonData,
    auth: state.auth,
    permissions: state.menuHandle.modulePermission,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingActions: bindActionCreators(settingActions, dispatch),
    loadingBarActions: bindActionCreators(loadingBarActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(FlatSetting);
