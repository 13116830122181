import React, { Fragment } from 'react';
import {
  FormGroup,
  FormControl,
  Form,
  InputGroup,
  Row,
  Col
} from 'react-bootstrap';
import { Translate, I18n } from 'react-redux-i18n';
import _ from 'lodash';
import CcCheckbox from '../../../../components/ccCheckbox/CcCheckbox';
import {
  Validation,
  textboxNumberHelper
} from '../../../../utils/commonFunctions';
import { Validator, ValidCase } from '../../../../components/validator';
import FormGroupTitle from '../../../../components/formGroupTitile/FormGroupTitle';
import { heatMapMode, defaultHeatMapMode } from '../../../../constants/commondata';
import QuestionCircleTooltip from '../../../../components/questionCircleTooltip/QuestionCircleTooltip';

class BookingValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLimitRadiusValueChange = e => {
    let regrex = /^(\d*\.?\d?|\d+\.)$/;
    if (regrex.test(e.target.value)) {
      this.props.handleUpdateFormData('limitRadiusDriverCanArriveAndPickup.value', e.target.value);
    }
  };

  handleIsLimitRadiusChange = e => {
    const { formData } = this.props;
    if (e.target.checked && _.isNil(formData.noShow.limitRadiusValue)) {
      this.props.handleUpdateFormData('limitRadiusDriverCanArriveAndPickup.value', 0);
    }
    this.props.handleUpdateFormData('limitRadiusDriverCanArriveAndPickup.enable', e.target.checked);
  };

k

  render() {
    let { formData: { limitRadiusDriverCanArriveAndPickup = {} }, valid, editable, ValidatorCallback, isSubmited, selectedFleet } = this.props
    const unitDistance = _.get(selectedFleet, 'unitDistance', 'km')
    return (
      <div className="group-general">
        <FormGroupTitle>
          <Translate value="generalSetting.Booking_validation" />
        </FormGroupTitle>
        <div>
          <CcCheckbox
            disabled={editable}
            checked={limitRadiusDriverCanArriveAndPickup.enable || false}
            onChange={this.handleIsLimitRadiusChange}
            text={<Fragment>
                {I18n.t('generalSetting.Limit_radius_arrive_pickup')}
              </Fragment>
            }
          />
          {limitRadiusDriverCanArriveAndPickup.enable ? (
            <FormGroup
              className={
                !isSubmited ? null : valid.limitRadiusPickup === false ? 'error' : null
              }
            >
              <FormGroup className="qup-input-group">
                <InputGroup className={`single-addon-right ${editable ? 'disabled' : ''}`}>
                  <FormControl
                    type="text"
                    className="form-custom"
                    onChange={this.handleLimitRadiusValueChange}
                    onBlur={e => {
                      textboxNumberHelper.onBlurHandle(
                        e,
                        this.handleLimitRadiusValueChange
                      );
                    }}
                    onFocus={e => {
                      textboxNumberHelper.onfocusHandle(
                        e,
                        this.handleLimitRadiusValueChange
                      );
                    }}
                    value={_.isNil(limitRadiusDriverCanArriveAndPickup.value) ? 0 : limitRadiusDriverCanArriveAndPickup.value}
                    placeholder={'Limit pickup-radius'}
                    disabled={editable}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      {unitDistance}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </FormGroup>
              <Validator id="limitRadiusPickup" callback={ValidatorCallback}>
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isNumber(limitRadiusDriverCanArriveAndPickup.value)}
                  message={I18n.t('messages.commonMessages.must_be_number')}
                />
                <ValidCase
                  hide={!isSubmited}
                  valid={Validation.isGreaterThan(limitRadiusDriverCanArriveAndPickup.value, 0)}
                  message={I18n.t('messages.commonMessages.greater_than_0')}
                />
              </Validator>
            </FormGroup>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default BookingValidation;
