import {
    SAVE_RESERVATION_TO_STORE,
    ADD_BOOKING_TO_RESERVATION_IN_REALTIME,
    REMOVE_BOOKING_FROM_RESERVATION_IN_REALTIME
} from '../actions/reservationActions';
import _ from 'lodash';

export default function map(state = [], action = {}) {
    switch (action.type) {
        case SAVE_RESERVATION_TO_STORE: {
            return action.data;
        }
        case ADD_BOOKING_TO_RESERVATION_IN_REALTIME: {
            let booking = action.data;
            _.map(state, reservation => {
                reservation.bookings = _.filter(reservation.bookings, originalBook => {
                    return originalBook.bookId !== booking.bookId;
                })
            })

            //parameters: drvInfo.userId, bookingId,
            let existingReservation = _.find(state, reservation => {
                return reservation.drvInfo && (reservation.drvInfo.userId === booking.drvInfo.userId);
            })

            // Case: Driver is not existed, and create new
            if(_.isUndefined(existingReservation)) {
                let bookings = [booking];
                let drvInfo = booking.drvInfo;
                state.push({
                    bookings,
                    drvInfo
                })
                return [...state];
            // Case: Driver is existing
            } else {
                let indexOfExistingBooking = _.findIndex(existingReservation.bookings, existingBooking => {
                    return booking.bookId === existingBooking.bookId;
                })
                // Case: Book is not existed, create new
                if(indexOfExistingBooking === -1) {
                    existingReservation.bookings.push(booking);
                // Case: Book is existing, need update
                } else {
                    existingReservation.bookings[indexOfExistingBooking] = booking;
                }
                return [...state];
            }
            return state;
        }

        case REMOVE_BOOKING_FROM_RESERVATION_IN_REALTIME: {
            let booking = action.data;
            let existingReservation = _.find(state, reservation => {
                return _.find(reservation.bookings, existingBooking => {
                    return booking.bookId === existingBooking.bookId;
                })
            });
            if(!_.isUndefined(existingReservation)) {
                existingReservation = _.remove(existingReservation.bookings, existingBooking => {
                    return existingBooking.bookId === booking.bookId;
                })
            }
            return [...state];
        }
        default: 
            return state;
    }
}